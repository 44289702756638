import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { Flex, Heading } from 'theme-ui';

import {
  organizationSettingsAvatarAction,
  organizationSettingsDeleteAvatarAction,
} from 'api/actions/settings/settingsActions';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { ManageAvatar } from 'components/ManageAvatar/ManageAvatar';
import { organizationDetailsSelector } from 'state/organizationSession';
import { settingsPageDataSelector } from 'state/settings';

export const OrganizationChangeAvatarForm = (): React.ReactElement => {
  useLingui();

  const { mutate: mutateAvatar } = useMutation(organizationSettingsAvatarAction);
  const { mutate: mutateDeleteAvatar } = useMutation(organizationSettingsDeleteAvatarAction);

  const settingsPageData = useRecoilValue(settingsPageDataSelector);
  const organizationDetails = useRecoilValue(organizationDetailsSelector);

  const clearAvatarFile = useCallback(async () => {
    const { error } = await mutateDeleteAvatar({});

    if (!error) {
      addSnackbar({
        message: t({
          id: 'settings.avatar.delete_success',
        }),
        variant: 'success',
      });
    }
    return true;
  }, [mutateDeleteAvatar]);

  const handleSubmitCallback = useCallback(
    async (url: string) => {
      let avatarBlob: Blob | null = null;

      if (url) {
        const avatarResp = await fetch(url);
        avatarBlob = await avatarResp.blob();
      }

      if (avatarBlob) {
        const { error } = await mutateAvatar({ avatar: avatarBlob });

        if (!error) {
          addSnackbar({
            message: t({
              id: 'settings.avatar.add_success',
            }),
            variant: 'success',
          });
        }
      }
      return true;
    },
    [mutateAvatar],
  );

  const onChangeAvatar = useCallback(
    async (url?: string) => {
      if (url) {
        return handleSubmitCallback(url);
      }
      return clearAvatarFile();
    },
    [clearAvatarFile, handleSubmitCallback],
  );

  return (
    <Flex sx={{ flexDirection: 'column', mr: 2 }}>
      <Heading variant="heading6" sx={{ pl: 2 }}>
        <Trans id="logo">Logo</Trans>
      </Heading>
      <ManageAvatar
        variant="lg"
        isSrcControlled
        url={organizationDetails?.avatarUrl}
        onChange={onChangeAvatar}
        name={
          settingsPageData
            ? {
                firstName: settingsPageData?.organization?.name?.[0] || ' ',
                surname: settingsPageData?.organization?.name?.[1] || ' ',
              }
            : undefined
        }
      />
    </Flex>
  );
};

export const MemoizedOrganizationChangeAvatarForm = React.memo(OrganizationChangeAvatarForm);
