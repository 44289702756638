/* eslint-disable no-underscore-dangle */
import { atom, RecoilState, selector, selectorFamily } from 'recoil';

import { PersonName } from 'api/actions/organizationSession/organizationSessionActions.types';
import {
  DateFormat,
  dateFormatAsString,
  NameDisplayOrder,
  TimeFormat,
  timeFormatAsString,
} from 'api/actions/userSession/userSessionActions.types';
import { HOSTNAME, REACT_APP_HOSTNAME, REAL_TIMEZONE } from 'constants/common';
import { SupportedLanguages } from 'constants/supportedLanguages';
import { windowSizeProps } from 'hooks/useWindowSize/useWindowSize';

import { organizationSessionAtom } from './organizationSession';
import { userSessionAtom } from './userSession';

export const timezoneSelector = selector<string | null>({
  key: 'timezone',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);

    const { timeZoneId } = userSession || {};

    if (timeZoneId) {
      return timeZoneId;
    }

    return REAL_TIMEZONE;
  },
  set: ({ get, set }, newValue) => {
    const userSession = get(userSessionAtom);
    if (typeof newValue !== 'string' || !userSession) return;

    set(userSessionAtom, { ...userSession, timeZoneId: newValue });
  },
});

export const dateFormatSelector = selector<string>({
  key: 'dateFormat',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);

    const { dateFormat } = userSession || {};

    if (dateFormat) {
      return dateFormatAsString[dateFormat];
    }

    switch (HOSTNAME) {
      case REACT_APP_HOSTNAME.inewi:
        return dateFormatAsString[DateFormat.DayAndMonth];
      case REACT_APP_HOSTNAME.unrubble:
      default:
        return dateFormatAsString[DateFormat.MonthAndDay];
    }
  },
  set: ({ get, set }, newValue) => {
    const userSession = get(userSessionAtom);

    if (typeof newValue !== 'number' || !userSession) return;

    set(userSessionAtom, { ...userSession, dateFormat: newValue });
  },
});

export const timeFormatSelector = selector<string>({
  key: 'timeFormat',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);

    const { timeFormat } = userSession || {};

    if (typeof timeFormat === 'number') {
      return timeFormatAsString[timeFormat];
    }

    switch (HOSTNAME) {
      case REACT_APP_HOSTNAME.inewi:
        return timeFormatAsString[TimeFormat._12h];
      case REACT_APP_HOSTNAME.unrubble:
      default:
        return timeFormatAsString[TimeFormat._24h];
    }
  },
  set: ({ get, set }, newValue) => {
    const userSession = get(userSessionAtom);
    if (typeof newValue !== 'number' || !userSession) return;

    set(userSessionAtom, { ...userSession, timeFormat: newValue });
  },
});
export const fullTimeFormatSelector = selector<string>({
  key: 'fullTimeFormat',
  get: ({ get }) => {
    const timeFormat = get(timeFormatSelector);
    switch (timeFormat) {
      case '24':
        return 'H:mm';
      case '12':
      default:
        return 'h:mm A';
    }
  },
});

export const isLoggedInAtom = atom<boolean>({
  key: 'isLoggedIn',
  default: false,
});

export const languageEnumSelector = selector<SupportedLanguages>({
  key: 'languageEnum',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);

    if (userSession) {
      const { preferredLanguage } = userSession;
      return preferredLanguage;
    }

    switch (HOSTNAME) {
      case REACT_APP_HOSTNAME.inewi:
        return SupportedLanguages.pl;
      case REACT_APP_HOSTNAME.unrubble:
      default:
        return SupportedLanguages.en;
    }
  },
  set: ({ get, set }, newValue) => {
    const userSession = get(userSessionAtom);

    if (typeof newValue !== 'number' || !userSession) return;

    set(userSessionAtom, { ...userSession, preferredLanguage: newValue });
  },
});

export const languageSelector = selector<keyof typeof SupportedLanguages>({
  key: 'language',
  get: ({ get }) => {
    const languageEnum = get(languageEnumSelector);

    return SupportedLanguages[languageEnum] as keyof typeof SupportedLanguages;
  },
  set: ({ set }, newValue) => {
    if (typeof newValue !== 'string') return;

    set(languageEnumSelector, SupportedLanguages[newValue]);
  },
});

export const windowSizeAtom = atom<windowSizeProps>({
  key: 'windowSize',
  default: {},
});

const publishedAtoms = {
  userSession: userSessionAtom,
  organizationSession: organizationSessionAtom,
  language: languageSelector,
};

export type PublishedAtomsKeys = keyof typeof publishedAtoms;

export const recoilState = selectorFamily<unknown, PublishedAtomsKeys | null>({
  key: 'recoilState',
  get:
    (atomKey: PublishedAtomsKeys | null) =>
    ({ get }) => {
      if (!atomKey) {
        return null;
      }
      const requestedState = get(publishedAtoms[atomKey] as RecoilState<unknown>);
      return requestedState;
    },

  set:
    (atomKey: PublishedAtomsKeys | null) =>
    ({ set }, newValue) => {
      if (atomKey) {
        set(publishedAtoms[atomKey] as RecoilState<unknown>, newValue);
      }
    },
});

export type ParsedPersonName = {
  name: PersonName;
  parsedName: string;
  initials: string;
};

export const nameDisplayOrderWithInitialsSelector = selectorFamily<ParsedPersonName, PersonName>({
  key: 'nameDisplayOrderWithInitials',
  get:
    (name) =>
    ({ get }) => {
      const userSession = get(userSessionAtom);

      const { nameDisplayOrder } = userSession || {};

      switch (nameDisplayOrder) {
        case NameDisplayOrder.SurnameFirst:
          return {
            parsedName: `${name.surname} ${name.firstName}`,
            initials: `${name.surname.charAt(0)}${name.firstName.charAt(0)}`,
            name,
          };
        case NameDisplayOrder.NameFirst:
        default:
          return {
            parsedName: `${name.firstName} ${name.surname}`,
            initials: `${name.firstName.charAt(0)}${name.surname.charAt(0)}`,
            name,
          };
      }
    },
});
