import { t } from '@lingui/macro';
import React from 'react';
import { Flex, FlexProps, Text } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { ReportDayStateFilters } from 'layouts/AuthorizedApp/AsideFilters/types';

export enum DayStateBadgeVariants {
  Annotation = 'ANNOTATION',
}

type Props = {
  type: ReportDayStateFilters | DayStateBadgeVariants;
  isLoading?: boolean;
} & FlexProps;

export type DayStateBadgeProps = Props;

export const DayStateBadge = ({ children, color, type, sx, isLoading, ...props }: Props): React.ReactElement => {
  const titlesMap: { [key in Props['type']]: string | undefined } = {
    PRESENT: t({ id: 'aside_filters.present' }),
    INCOMPLETE_SHIFT: t({ id: 'aside_filters.incomplete_shift' }),
    OVERTIME: t({ id: 'aside_filters.overtime' }),
    TIME_OFF: t({ id: 'aside_filters.time_off' }),
    ABSENT: t({ id: 'aside_filters.absent' }),
    CUSTOM_REQUEST: t({ id: 'aside_filters.custom_request' }),
    BUSINESS_TRIP: t({ id: 'aside_filters.business_trip' }),
    ERROR: t({ id: 'aside_filters.error' }),
    ANNOTATION: undefined,
  };

  return (
    <Flex
      as="span"
      variant="reports.dayStateBadge"
      data-type={type}
      {...props}
      sx={{
        ...(sx && sx),
        position: 'relative',
      }}
    >
      {type === ReportDayStateFilters.Error && <Icon type="warning" />}
      <Text as="span" data-text sx={{ opacity: isLoading ? 0.3 : 1 }}>
        {children || titlesMap[type]}
      </Text>
      {isLoading && (
        <LoadingSpinnerCss
          sx={{ position: 'absolute', height: '100%', left: '50%', transform: 'translate(-50%, 0)' }}
        />
      )}
    </Flex>
  );
};
