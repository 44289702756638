import React from 'react';
import { Box, Flex, FlexProps, ThemeUIStyleObject } from 'theme-ui';

interface Props extends FlexProps {
  baseAs?: 'flex' | 'box';
  size?: ('sm' | 'md' | 'lg' | 'xl') | number;
}

const defaultProps: Partial<Props> = {
  baseAs: 'flex',
  size: 'lg',
};

const containerTypeMap: { [index in 'flex' | 'box']: typeof Flex | typeof Box } = {
  flex: Flex,
  box: Box,
};

export const Container = React.forwardRef<HTMLDivElement, Props>(
  ({ baseAs = 'box', children, size = 'lg', ...props }: Props, ref) => {
    const ContainerType = containerTypeMap[baseAs];

    const prepareSize = () => {
      if (typeof size === 'number') {
        return `${size}px`;
      }

      return `container.${size}`;
    };

    const flexSx: ThemeUIStyleObject = {
      flexDirection: 'column',
      // flexGrow: 1,
    };

    return (
      <ContainerType
        ref={ref}
        {...props}
        sx={{
          maxWidth: prepareSize,
          width: '100%',
          margin: '0 auto',
          ...(baseAs === 'flex' && { ...flexSx }),
          ...props.sx,
        }}
      >
        {children}
      </ContainerType>
    );
  },
);

Container.defaultProps = defaultProps;
