import { t } from '@lingui/macro';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';
import { useLingui } from '@lingui/react';

import { ZusConnectActionProps } from 'api/actions/zus/zusActions.types';
import { TextInput } from 'components/ui/TextInput';
import { taxIdSelector } from 'state/organizationSession';
import { validationFactory, VALIDATION_RULES } from 'constants/validationRules';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

type Props = {
  onSubmit: (data: ZusConnectActionProps) => Promise<void>;
  setLoading: (loading: boolean) => void;
};

export const ConnectZusForm = React.forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, setLoading }: Props, ref): React.ReactElement => {
    useLingui();
    const taxId = useRecoilValue(taxIdSelector);

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<ZusConnectActionProps>({
      defaultValues: {
        taxId: taxId || undefined,
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

    const handleSubmitCallback = useCallback(
      (data: ZusConnectActionProps) => {
        void onSubmit(data);
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    return (
      <form ref={ref} onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <TextInput
            {...register('login', validationFactory({ ...VALIDATION_RULES.EMAIL, required: true }))}
            id="login"
            placeholder={t({ id: 'global.forms.login_email', message: 'Login / E-mail' })}
            size="sm"
            label={t({ id: 'global.forms.login_email' })}
            error={!!errors.login}
            errorMessage={errors.login?.message}
          />
          <TextInput
            {...register('password', validationFactory({ required: true }))}
            id="password"
            placeholder={t({ id: 'global.forms.inputs.password' })}
            type="password"
            size="sm"
            label={t({ id: 'global.forms.inputs.password' })}
            error={!!errors.password}
            errorMessage={errors.password?.message}
          />
          <TextInput
            {...register(
              'taxId',
              validationFactory({
                ...VALIDATION_RULES.TAX_ID,
                required: true,
              }),
            )}
            id="taxId"
            placeholder={t({ id: 'organization_settings.texts.tax_id' })}
            size="sm"
            label={t({ id: 'organization_settings.texts.tax_id' })}
            error={!!errors.taxId}
            errorMessage={errors.taxId?.message}
          />
        </Flex>
      </form>
    );
  },
);
