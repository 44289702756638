import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { scheduleSettingsAction } from 'api/actions/settings/settingsActions';
import { ScheduleSettingsActionProps } from 'api/actions/settings/settingsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { SCHEDULES_RESET } from 'constants/settings';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { blockTransitionAtom, resetFormButtonAtom } from 'state/settings';

import { useSchedules } from './useSchedules';

type Props = {
  handleFormReset: () => void;
};

export const useSubmitSchedules = ({ handleFormReset }: Props) => {
  useLingui();
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const [{ skipFetch, isRequestPending }, setBlockTransition] = useRecoilState(blockTransitionAtom);
  const { schedulesSettings, fetchSchedules, fetchAbort } = useSchedules();
  const { calendarInitialized, updateCalendar } = useRefreshCalendar();
  const { mutate } = useMutation(scheduleSettingsAction);
  const skipFetchRef = useCallbackRef(skipFetch);
  const isRequestPendingRef = useCallbackRef(isRequestPending);

  const handleSubmitCallback = useCallback(
    async (data: ScheduleSettingsActionProps) => {
      if (_.isEqual(data, schedulesSettings) && !isRequestPendingRef.current) {
        setBlockTransition((prevState) => ({ ...prevState, blockTransition: false }));
        return;
      }

      fetchAbort();
      setResetCallbacks(null);
      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: true }));
      const { error, status } = await mutate(data);

      if (!error) {
        if (!skipFetchRef.current) await fetchSchedules();
        if (calendarInitialized) updateCalendar();
        addSnackbar({
          message: t({
            id: 'settings.forms.submit_success',
          }),
          variant: 'success',
        });
      }

      if (error) {
        if (!status) {
          return;
        }
        const schedulesResetObject = { name: SCHEDULES_RESET, callback: handleFormReset };
        setResetCallbacks((prevState) => (!prevState ? [schedulesResetObject] : [...prevState, schedulesResetObject]));
        return;
      }

      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: false, blockTransition: false }));
    },
    [
      calendarInitialized,
      fetchAbort,
      fetchSchedules,
      handleFormReset,
      isRequestPendingRef,
      mutate,
      schedulesSettings,
      setBlockTransition,
      setResetCallbacks,
      skipFetchRef,
      updateCalendar,
    ],
  );

  return handleSubmitCallback;
};
