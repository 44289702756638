import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';
import key from 'weak-key';

import {
  RequestFormType,
  RequestHistory,
  RequestHistoryState,
  RequestState,
} from 'api/actions/requests/requestsActions.types';
import { Divider } from 'components/Divider/Divider';
import { Icon } from 'components/Icon/Icon';
import { RequestHistoryStatusBadge } from 'components/recipes/RequestHistoryStatusBadge ';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { fullTimeFormatSelector, languageSelector } from 'state/recoilState';
import { dateTime } from 'utils/dateTime';
import { useRequestTypeListPresenter } from '../../../../../RequestTypeListPresenter/hooks/useRequestTypeListPresenter';
import { RequestDetailsGroupedParam } from '../../../../../types';
import { useRequestDetails } from '../../../hooks/useRequestDetails';

type Props = {
  list: RequestHistory[];
};

const HistoryElement = ({
  person,
  state,
  dateTimeUnix,
  reason,
  number,
  id,
  actionType,
}: RequestHistory): React.ReactElement => {
  const timeFormat = useRecoilValue(fullTimeFormatSelector);
  const language = useRecoilValue(languageSelector);
  const navigate = useAppNavigate();
  const {
    parsedRequestDetails: requestDetails,
    instanceNumber,
    urlParams: { group },
  } = useRequestDetails();
  const { stateBadge } = useRequestTypeListPresenter();
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const handleRequestNumberClick = useCallback(() => {
    if (!requestDetails) return;

    const historyStateTransformed: RequestState = (() => {
      switch (state) {
        case RequestHistoryState.Accepted:
          return RequestState.Accepted;
        case RequestHistoryState.Rejected:
          return RequestState.Rejected;
        case RequestHistoryState.Created:
        case RequestHistoryState.FirstOutOfTwoAcceptance:
        case RequestHistoryState.Deleted:
        case RequestHistoryState.TimeEventDeleted:
        default:
          return RequestState.Pending;
      }
    })();

    navigate(`${TO_REL.REQUEST_DETAILS__GROUP__ID[language]}/${RequestDetailsGroupedParam.Ungrouped}/${id}`, {
      state: {
        requestDetails: {
          requestNumber: number,
          state: historyStateTransformed,
        },
        opener: {
          uniqueId: instanceNumber,
          requestId: requestDetails.id,
        },
      },
    });
  }, [id, instanceNumber, language, number, navigate, requestDetails, state]);

  return (
    <Flex
      sx={{
        alignItems: 'center',
        fontSize: 1,
      }}
    >
      <Flex sx={{ minWidth: '130px' }}>
        <RequestHistoryStatusBadge
          sx={{ height: '18px', ...(state === RequestHistoryState.Created && { bg: 'requests.history.bg.created' }) }}
          requestState={state}
        />
      </Flex>
      <Flex sx={{ flexDirection: 'column', ml: 2, flexGrow: 1 }}>
        <Flex sx={{ ...(isSmartphoneBreakpoint && { flexDirection: 'column' }) }}>
          <Flex sx={{ fontSize: '0.75rem' }}>
            <TextEllipsis sx={{ fontWeight: 'bold', width: 'auto' }} title={person}>
              {person}
            </TextEllipsis>
            ,&nbsp;
            <Text as="span" sx={{ color: 'requests.history.text', display: 'flex', flexShrink: 0, mr: 1 }}>
              {dateTime(dateTimeUnix).format(`ll ${timeFormat}`)}
            </Text>
          </Flex>
          {group === RequestDetailsGroupedParam.Grouped && requestDetails?.type !== RequestFormType.Schedule && (
            <Flex
              sx={{
                flexShrink: 0,
                gap: 1,
                alignItems: 'start',
                ...(isSmartphoneBreakpoint ? { flexWrap: 'wrap' } : { ml: 'auto' }),
              }}
            >
              <Flex sx={{ color: 'requests.history.text' }}>
                {stateBadge({ viewType: 'ungrouped', actionType, variant: 'lighter' })}
              </Flex>
              <Flex
                sx={{
                  ml: 1,
                  gap: '2px',
                  bg: 'requests.history.bg.ungrouped',
                  pr: 1,
                  pl: '2px',
                  color: 'requests.history.text',
                  borderRadius: 'xs',
                  '&:hover': {
                    bg: 'requests.history.bg.ungroupedHover',
                    cursor: 'pointer',
                  },
                }}
                onClick={handleRequestNumberClick}
              >
                <Icon type="requests" /> <Text>{number}</Text>
              </Flex>
            </Flex>
          )}
        </Flex>
        {reason && <Text sx={{ fontSize: '0.75rem', wordBreak: 'break-all' }}>{reason}</Text>}
      </Flex>
    </Flex>
  );
};

export const HistoryList = ({ list }: Props): React.ReactElement => (
  <>
    {list.map((element, index, array) => (
      <Flex key={key(element)} sx={{ flexDirection: 'column', gap: 2 }}>
        <HistoryElement {...element} />
        {index !== array.length - 1 && element.id !== array[index + 1]?.id && <Divider />}
      </Flex>
    ))}
  </>
);
