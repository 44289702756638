import React from 'react';
import { Card, Flex, Heading, Text, ThemeUIStyleObject } from 'theme-ui';

type Props = {
  sx?: ThemeUIStyleObject;
  title: string;
  message: string;
  appendWith?: React.ReactNode;
  active: boolean;
  onClick?: () => void;
};

const defaultProps: Partial<Props> = {
  appendWith: undefined,
  sx: undefined,
  onClick: undefined,
};

export const AccountTypeCard = ({ appendWith, sx, title, message, active, onClick }: Props): React.ReactElement => (
  <Card
    onClick={onClick}
    variant="accountType"
    sx={{
      ...(sx && sx),
    }}
    data-active={active}
  >
    {appendWith && (
      <Flex
        sx={{
          mb: 3,
          flexShrink: 0,
          alignItems: 'center',
          width: ['64px', null, '96px'],
          '> *': {
            width: '100%',
            height: 'auto',
          },
        }}
      >
        {appendWith}
      </Flex>
    )}
    <Flex sx={{ flexDirection: 'column' }}>
      <Heading
        as="h2"
        sx={{
          mb: '0 !important',
        }}
      >
        {title}
      </Heading>
      <Text as="p" variant="pLead" sx={{ mb: 0 }}>
        {message}
      </Text>
    </Flex>
  </Card>
);

AccountTypeCard.defaultProps = defaultProps;
