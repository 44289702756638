import { useMemo } from 'react';

import { useNonEditableEmployeesIds } from 'hooks/useNonEditableEmployeesIds/useNonEditableEmployeesIds';
import { EditablePermissionsKey } from 'api/actions/organizationSession/organizationSessionActions.types';

/**
 * Filters out non editable eployees ids.
 *
 * @param {string[]} employeesIds If true the dimensions will be retrieved only once and never updated.
 * @param {EditablePermissionsKey} module Module for which you are filtering.
 *
 * @return {string[]} Returns an array of editable emplyees ids.
 */

export const useFilterOutNonEditableEmployeesIds = (employeesIds: string[], module: EditablePermissionsKey) => {
  const nonEditableEmployeesIds = useNonEditableEmployeesIds(module);

  const editableEmployeesIds = useMemo(
    () => employeesIds.filter((id) => !nonEditableEmployeesIds?.includes(id)),
    [employeesIds, nonEditableEmployeesIds],
  );

  return editableEmployeesIds;
};
