import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { BusinessTrip } from './components/BusinessTrip';
import { Custom } from './components/Custom';
import { PersonalReplacement } from './components/PersonalReplacement';
import { Schedules } from './components/Schedules';
import { TimeOff } from './components/TimeOff';
import { TimeTracking } from './components/TimeTracking';

type Props = FlexProps;

type RequestsFormsContainerProps = Props;

type RequestsFormsContainerComponent = React.FC<RequestsFormsContainerProps> & {
  TimeOff: typeof TimeOff;
  TimeTracking: typeof TimeTracking;
  BusinessTrip: typeof BusinessTrip;
  Custom: typeof Custom;
  Schedules: typeof Schedules;
  PersonalReplacement: typeof PersonalReplacement;
};

export const RequestsFormsContainer: RequestsFormsContainerComponent = ({ children }: Props): React.ReactElement => (
  <Flex variant="settings.container.vertical.main">{children}</Flex>
);

RequestsFormsContainer.TimeOff = TimeOff;
RequestsFormsContainer.TimeTracking = TimeTracking;
RequestsFormsContainer.BusinessTrip = BusinessTrip;
RequestsFormsContainer.Custom = Custom;
RequestsFormsContainer.Schedules = Schedules;
RequestsFormsContainer.PersonalReplacement = PersonalReplacement;
