import { useCallback } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { fetchIndustryListAction } from 'api/actions/data/dataActions';
import { industryListAtom } from 'state/organizationSession';

export const useIndustry = () => {
  const { query } = useClient();
  const [industryList, setIndustryList] = useRecoilState(industryListAtom);

  const fetchIndustryList = useCallback(async () => {
    const { payload, error } = await query(fetchIndustryListAction());

    if (!error && payload) setIndustryList(payload);
  }, [query, setIndustryList]);

  return { industryList, fetchIndustryList };
};
