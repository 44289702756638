import { useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';

import { ChannelMessage } from 'broadcastChannel/channelReducer/channelReducer.types';
import { TAB_ID } from 'constants/common';
import { broadcastChannelReducer } from 'broadcastChannel/channelReducer/channelReducer';
import { delay } from 'utils/delay';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';

// eslint-disable-next-line import/no-mutable-exports
export let broadcastChannel: BroadcastChannel<ChannelMessage> | null = null;

export const BroadcastChannelObserver: React.ElementType = () => {
  const { isAuthorized } = useAuthState();

  const isAuthorizedRef = useCallbackRef(isAuthorized);

  useEffect(() => {
    const handleMessageReceived = ({ tabId, action }: ChannelMessage) => {
      if (tabId === TAB_ID) {
        return;
      }
      broadcastChannelReducer(action, isAuthorizedRef.current);
    };
    broadcastChannel = new BroadcastChannel<ChannelMessage>('INEWI_APP');
    broadcastChannel.addEventListener('message', handleMessageReceived);

    return () => {
      const cleanUp = async () => {
        await delay(500); // wait for the logic in SignOut to complete
        broadcastChannel?.removeEventListener('message', handleMessageReceived);
        broadcastChannel = null;
      };
      void cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
