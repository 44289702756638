type Routes = {
  [key: string]: string;
};

const routes: Routes = {
  CLOSED: '/',
  DRAWER: '/drawer',
  DRAWER__ATTENDANCE_OVERVIEW: '/drawer/attendance-overview',
  DRAWER__TIME_TRACKER: '/drawer/time-tracker',
  DRAWER__NOTIFICATION_CENTER: '/drawer/notification-center',
};

export const modalRoutes: Routes = {
  LOCATION_PERMISSION: '/location-permission',
  DRAWER__TIME_TRACKER__LOCATION_PERMISSION: '/drawer/time-tracker/location-permission',
};

export const DRAWER_ROUTES: Routes = {
  ...routes,
  ...modalRoutes,
};
