import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { SetterOrUpdater, useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { Divider } from 'components/Divider/Divider';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { ListNames } from 'components/StickyList/types';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { PickTeammates } from 'components/recipes/PickTeammates/PickTeammates';
import { PickTeammatesFilters } from 'components/recipes/PickTeammates/PickTeammatesFilters';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { usePickTeammates } from 'hooks/usePickTeammates/usePickTeammates';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { AsideWrapper } from 'pages/Requests/output/AsideWrapper';
import { SelectedPersonsList } from 'pages/Requests/output/SelectedPersonsList';
import { AddEventStep } from 'state/clockLog';
import { organizationSessionAtom } from 'state/organizationSession';
import { hashedEventTypeSelectorFamily } from 'state/selectOptions';
import { getEnumValues } from 'utils/getEnumValues';

import { ADD_EVENTS_FORM_ID, AddEventsStep2 } from './components/AddEventsStep2';
import { useAddEvents } from './hooks/useAddEvents';
import { useAddEventsCleanUp } from './hooks/useAddEventsCleanUp';
import { useMinimizeModalAddEvents } from './hooks/useMinimizeModalAddEvents';

type Props = {
  variant?: 'multi' | 'single';
  isMinimal?: boolean;
  isWizard?: boolean;
};

type AddEventsInnerProps = {
  step: AddEventStep;
  setStep: SetterOrUpdater<AddEventStep>;
  isLoadingSave: boolean;
  isSaveDisabled: boolean;
  personId?: string;
  isWizard?: boolean;
} & Props;

const AddEventsInner: FC<AddEventsInnerProps> = ({
  variant = 'multi',
  isMinimal,
  step,
  setStep,
  isLoadingSave,
  isSaveDisabled,
  personId,
  isWizard,
}) => {
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();
  const [selectedEmployeesIds] = usePickTeammates(ListNames.CLOCK_LOG_PICK_TEAMMATES, true);
  const stepRenderer = useMemo(() => {
    switch (step) {
      case AddEventStep.SelectTeammates:
        return (
          <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
            <PickTeammates
              forceIncludeCurrentUser={true}
              listName={ListNames.CLOCK_LOG_PICK_TEAMMATES}
              module="TimeTracking"
            />
          </Flex>
        );
      case AddEventStep.AddEvents:
        return (
          <Flex
            sx={{
              flexDirection: 'column',
              flexGrow: 1,
              ...(!isSmartphoneBreakpoint && step === AddEventStep.AddEvents && { overflowY: 'auto' }),
            }}
          >
            <AddEventsStep2 variant={variant} isWizard={isWizard} isMinimal={isMinimal} />
          </Flex>
        );

      default:
        return null;
    }
  }, [isMinimal, isSmartphoneBreakpoint, isWizard, step, variant]);

  const sideRenderer = useMemo(() => {
    switch (step) {
      case AddEventStep.SelectTeammates:
        return (
          <Flex
            sx={{
              flexDirection: 'column',
              gap: 3,
              flex: 1,
              ...(!isSmartphoneBreakpoint && { overflowY: 'auto', pr: 3 }),
              ...(!isSmartphoneBreakpoint && step === AddEventStep.SelectTeammates && { pl: 3 }),
            }}
          >
            <PickTeammatesFilters />
          </Flex>
        );
      case AddEventStep.AddEvents:
        return <SelectedPersonsList listName={ListNames.CLOCK_LOG_PICK_TEAMMATES} />;
      default:
        return null;
    }
  }, [isSmartphoneBreakpoint, step]);

  const nextStep = useCallback(() => setStep((prev) => prev + 1), [setStep]);
  const prevStep = useCallback(() => setStep((prev) => prev - 1), [setStep]);

  const modalTitle = useMemo(() => {
    if (isWizard) return t({ id: 'clock_log.wizard', message: 'Event Wizard' });

    if (variant === 'multi') {
      return t({ id: 'clock_log.add_time_events', message: 'Add events' });
    }

    return t({ id: 'clock_log.add_missing_entry' });
  }, [isWizard, variant]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body
        sx={{
          gap: 2,
          pt: 0,
          ...(!isSmartphoneBreakpoint && step === AddEventStep.SelectTeammates && { pl: 0 }),
        }}
      >
        {!isMinimal ? (
          <Flex sx={{ flex: 1, minHeight: 0 }}>
            <ConditionalWrapper condition={isSmartphoneBreakpoint} wrapper={AsideWrapper}>
              <Flex
                sx={{
                  maxWidth: '240px',
                  flex: 1,
                  ...(!isSmartphoneBreakpoint && { overflowY: 'hidden' }),
                  ...(!isSmartphoneBreakpoint && step !== AddEventStep.SelectTeammates && { mr: 3 }),
                }}
              >
                {sideRenderer}
              </Flex>
            </ConditionalWrapper>
            {!isSmartphoneBreakpoint && <Divider axis="vertical" />}
            <Flex sx={{ flex: 1, ml: 3 }}>{stepRenderer} </Flex>
          </Flex>
        ) : (
          <AddEventsStep2 variant={variant} isMinimal={isMinimal} />
        )}
      </Modal.Body>

      <BasicModalFooter
        backButtonOnClick={step === AddEventStep.AddEvents && (!personId || isWizard) ? prevStep : undefined}
        buttons={[
          {
            variant: 'primary',
            onClick: step === AddEventStep.SelectTeammates ? nextStep : undefined,
            type: step === AddEventStep.AddEvents ? 'submit' : undefined,
            form: step === AddEventStep.AddEvents ? ADD_EVENTS_FORM_ID : undefined,
            disabled:
              (!selectedEmployeesIds.length && !personId) || (isSaveDisabled && step === AddEventStep.AddEvents),
            children: (
              <>
                {step === AddEventStep.AddEvents ? (
                  <Trans id="save">Save</Trans>
                ) : (
                  <>
                    {t({ id: 'global.forms.buttons.next' })}
                    <Icon wrapperSx={{ ml: 3 }} type="arrowRight" />
                  </>
                )}
              </>
            ),
            sx: step === AddEventStep.AddEvents ? {} : { pr: 2 },
            isLoading: isLoadingSave,
          },
        ]}
        prependWith={
          personId && !isWizard ? undefined : (
            <Text sx={{ display: ['none', 'flex', null, null] }}>
              <Trans id="wizard.step_tracker" values={{ step, steps: getEnumValues(AddEventStep).length }}>
                Step {step} of {getEnumValues(AddEventStep).length}
              </Trans>
            </Text>
          )
        }
      />
    </>
  );
};
export const AddEventsModal: FC<Props> = ({ variant = 'multi', isMinimal, isWizard }) => {
  const { baseRoute } = useModal({ closeOnBackdrop: false });
  const organizationSession = useRecoilValue(organizationSessionAtom);
  const { modulesManagement } = useAppPermissions();
  const { state } = useLocation();
  const { id: typeId = '', user } = useParams<{ id: string; user: string }>();
  const predefinedHashedEventType = useRecoilValue(
    hashedEventTypeSelectorFamily({ typeId, isEnd: state?.addTimeEvent?.isEnd || false }),
  );
  const { id } = state || {};
  const personId = id || user;
  const { step, setStep, isLoadingSave, isSaveDisabled } = useAddEvents();

  useEffect(() => {
    if (personId && !isWizard) setStep(AddEventStep.AddEvents);
  }, [isWizard, personId, setStep, state]);

  useMinimizeModalAddEvents(isMinimal, isWizard);
  useAddEventsCleanUp();

  if (!organizationSession) return null;

  if (
    !modulesManagement.TimeTracking ||
    (isMinimal && !isWizard && !personId) ||
    (variant === 'single' && !predefinedHashedEventType)
  ) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <AddEventsInner
      variant={variant}
      isMinimal={isMinimal}
      step={step}
      setStep={setStep}
      isLoadingSave={isLoadingSave}
      isSaveDisabled={isSaveDisabled}
      personId={personId}
      isWizard={isWizard}
    />
  );
};
