import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { getSchedulesToSwapAction } from 'api/actions/requests/requestsActions';
import { GetSchedulesToSwapResponse } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { allEmployeesSelector } from 'state/employees';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { RequestSchedulesRenderer } from '../../../../../../renderers/RequestSchedulesRenderer';
import { useAddRequest } from '../../../../../hooks/useAddRequest';

export const CompareSchedules = (): React.ReactElement | null => {
  useLingui();
  const allEmployees = useRecoilValue(allEmployeesSelector);
  const [fetchedSchedules, setFetchedSchedules] = useState<GetSchedulesToSwapResponse | null>(null);
  const { selectedTeammatesIds } = useAddRequest();

  const { mutate, loading } = useMutation(getSchedulesToSwapAction);

  const { watch } = useFormContext<AddRequestExtendedFormContext>();

  const targetSwapPersonWatch = watch('targetSwapPersonId');
  const dateWatch = watch('dateTimeDetails.date');

  const getSchedules = useCallback(async () => {
    if (!targetSwapPersonWatch || !dateWatch) return;

    const employeeId = selectedTeammatesIds[0];
    setFetchedSchedules(null);

    const { error, payload } = await mutate({ peopleIds: [employeeId, targetSwapPersonWatch], date: dateWatch });

    if (!error && payload) {
      setFetchedSchedules(payload);
    }
  }, [dateWatch, mutate, selectedTeammatesIds, targetSwapPersonWatch]);

  useEffect(() => {
    void getSchedules();
  }, [getSchedules]);

  const schedulesRenderer = useMemo(() => {
    if (!fetchedSchedules || !targetSwapPersonWatch) return null;

    const employeeId = selectedTeammatesIds[0];
    const employee = allEmployees?.get(employeeId);
    const targetSwapEmployee = allEmployees?.get(targetSwapPersonWatch);

    if (!employee || !targetSwapEmployee) return null;

    const employeeSchedule = fetchedSchedules.filter(({ personId }) => personId === employeeId);
    const targetSwapEmployeeSchedule = fetchedSchedules.filter(({ personId }) => personId === targetSwapPersonWatch);

    return (
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text sx={{ fontWeight: 'bold' }} variant="heading4">
          <Trans id="add_request.swap_details">Swap details</Trans>
        </Text>
        <Flex sx={{ flex: '1', gap: 2 }}>
          <RequestSchedulesRenderer schedule={employeeSchedule} employee={employee} />
          <Flex sx={{ flexDirection: 'column' }}>
            <Icon type="arrowRight" size={24} iconSx={{ color: 'requests.arrow' }} wrapperSx={{ mb: '-0.75rem' }} />
            <Icon type="arrowLeft" size={24} iconSx={{ color: 'requests.arrow' }} />
          </Flex>
          <RequestSchedulesRenderer schedule={targetSwapEmployeeSchedule} employee={targetSwapEmployee} />
        </Flex>
      </Flex>
    );
  }, [allEmployees, fetchedSchedules, selectedTeammatesIds, targetSwapPersonWatch]);

  if (loading) return <CenteredLoadingSpinner size={4} />;

  if (!fetchedSchedules) return null;

  return schedulesRenderer;
};
