import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useMemo, useRef } from 'react';
import { Flex } from 'theme-ui';

import { RequestActionType, RequestState } from 'api/actions/requests/requestsActions.types';
import { Divider } from 'components/Divider/Divider';
import { Modal } from 'components/Modal/output/Modal';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { useModal } from 'components/Modal/output/useModal';
import { ListNames } from 'components/StickyList/types';
import { PickTeammates } from 'components/recipes/PickTeammates/PickTeammates';
import { PickTeammatesFilters } from 'components/recipes/PickTeammates/PickTeammatesFilters';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { ADD_REQUEST_DEFAULT_STEPS } from 'constants/requests';
import { useMount } from 'hooks/useMount/useMount';
import { usePickTeammates } from 'hooks/usePickTeammates/usePickTeammates';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { MinimizeModalAddRequest } from 'state/modal';
import { Step } from 'state/requests';
import { createEvent } from 'utils/createEvent';

import { AddRequestFooter } from './components/AddRequestFooter';
import { AddRequestStep2 } from './components/AddRequestStep2';
import { AddRequestStep3 } from './components/AddRequestStep3/AddRequestStep3';
import { AsideWrapper } from './components/AsideWrapper';
import { SelectedPersons } from './components/SelectedPersons';
import { SelectedRequestType } from './components/SelectedRequestType';
import { useAddRequest } from './hooks/useAddRequest';
import { useAddRequestCleanUp } from './hooks/useAddRequestCleanUp';
import { useMinimizeModalAddRequest } from './hooks/useMinimizeModalAddRequest';
import { useRequestLocation } from './hooks/useRequestLocation';

export const AddRequestModal = (): React.ReactElement => {
  useLingui();
  useModal({
    closeOnBackdrop: false,
  });
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();
  const [, setSelectedTeammatesIds] = usePickTeammates(ListNames.REQUESTS_PICK_TEAMMATES, true);
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddRequest>();

  const formRef = useRef<HTMLFormElement | null>(null);

  const {
    addRequestState: { step },
    setAddRequestState,
    requestConfig: { setRequestConfigState },
    setDefaultDateUnix,
  } = useAddRequest();

  const { isWizard, deleteGroupRequest, dateUnix, requestConfig } = useRequestLocation();

  useMinimizeModalAddRequest();
  useAddRequestCleanUp();

  useMount(() => {
    if (dateUnix && !minimizedModalDetails?.modalDetails?.formState) {
      setDefaultDateUnix(dateUnix);
    }

    if (requestConfig) {
      const { employeesIds, requestType, excludedSteps } = requestConfig;

      setRequestConfigState(requestConfig);
      if (employeesIds && !minimizedModalDetails) setSelectedTeammatesIds(employeesIds);
      if ((requestType || excludedSteps) && !minimizedModalDetails) {
        setAddRequestState((prevState) => ({
          ...prevState,
          ...(excludedSteps && { step: ADD_REQUEST_DEFAULT_STEPS.filter((s) => !excludedSteps.includes(s))[0] }),
          ...(requestType && { requestType }),
        }));
      }
    }
  });

  const submitForm = useCallback(() => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  }, []);

  const handleSave = useCallback(() => {
    setAddRequestState((prevState) => ({ ...prevState, loading: true }));
    submitForm();
  }, [setAddRequestState, submitForm]);

  const modalTitle = useMemo(() => {
    if (isWizard) {
      return t({ id: 'request.wizard', message: 'Request Wizard' });
    }

    if (requestConfig) {
      const { action } = requestConfig;

      if (action === RequestActionType.Edit) {
        return t({ id: 'requests.request_change', message: 'Request change' });
      }

      if (action === RequestActionType.Remove) {
        return !deleteGroupRequest?.latestInGroup || deleteGroupRequest?.state === RequestState.Pending
          ? t({ id: 'requests.request_remove' })
          : t({ id: 'requests.request_remove_group', message: 'Request group remove' });
      }
    }

    return t({ id: 'requests.add_request', message: 'Add request' });
  }, [deleteGroupRequest?.latestInGroup, deleteGroupRequest?.state, isWizard, requestConfig]);

  const stepRender = useMemo(() => {
    switch (step) {
      case Step.SelectTeammates:
        return (
          <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
            <PickTeammates
              forceIncludeCurrentUser={true}
              listName={ListNames.REQUESTS_PICK_TEAMMATES}
              module="Requests"
            />
          </Flex>
        );
      case Step.SelectType:
        return <AddRequestStep2 />;
      case Step.Forms:
        return <AddRequestStep3 ref={formRef} />;
      default:
        return null;
    }
  }, [step]);

  const sideRender = useMemo(() => {
    switch (step) {
      case Step.SelectTeammates:
        return (
          <Flex
            sx={{
              flexDirection: 'column',
              gap: 3,
              flex: '1',
              ...(!isSmartphoneBreakpoint && { overflowY: 'auto', pr: 3 }),
              ...(!isSmartphoneBreakpoint && step === Step.SelectTeammates && { pl: 3 }),
            }}
          >
            <PickTeammatesFilters />
          </Flex>
        );
      case Step.SelectType:
        return <SelectedPersons />;
      case Step.Forms:
        return (
          <Flex sx={{ flexDirection: 'column', gap: 3, flexGrow: 1 }}>
            <SelectedRequestType />
            <SelectedPersons />
          </Flex>
        );
      default:
        return null;
    }
  }, [isSmartphoneBreakpoint, step]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        sx={{
          pt: 0,
          ...(!isSmartphoneBreakpoint && step === Step.SelectTeammates && { pl: 0 }),
          ...(!isSmartphoneBreakpoint && step === Step.Forms && { pr: 0 }),
        }}
      >
        <Flex sx={{ flex: '1', minHeight: 0 }}>
          <ConditionalWrapper condition={isSmartphoneBreakpoint} wrapper={AsideWrapper}>
            <Flex
              sx={{
                maxWidth: '240px',
                flex: '1',
                ...(!isSmartphoneBreakpoint && { overflowY: 'hidden' }),
                ...(!isSmartphoneBreakpoint && step !== Step.SelectTeammates && { mr: 3 }),
              }}
            >
              {sideRender}
            </Flex>
          </ConditionalWrapper>
          {!isSmartphoneBreakpoint && <Divider axis="vertical" />}
          <Flex sx={{ flex: '1', ...(step !== Step.Forms && { ml: 3 }) }}>{stepRender}</Flex>
        </Flex>
      </Modal.Body>
      <AddRequestFooter handleSave={handleSave} />
    </>
  );
};
