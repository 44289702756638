import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useEffect } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { Helmet } from 'react-helmet';
import { useRecoilValue } from 'recoil';
import { Flex, Heading, Link, Text } from 'theme-ui';

import { disconnectQuickbooksAction } from 'api/actions/integrations/integrationActions';
import { Icon } from 'components/Icon/Icon';
import { APP_NAME } from 'constants/common';
import { QueryParams } from 'constants/queryParams';
import { TO } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';
import { useMount } from 'hooks/useMount/useMount';
import { useSearchParam } from 'hooks/useSearchParam/useSearchParam';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { AuthenticationLayout } from 'layouts/Authentication/output/AuthenticationLayout';
import { languageSelector } from 'state/recoilState';

// FIXME: Jotai fix needed
export const QuickbooksDisconnect: FC = () => {
  useLingui();
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();
  const quickBooksHelpLink = useHelpLink({
    unrubble: {
      en: '/article/quickbooks-with-unrubble-1bfir7e/',
    },
  });
  // Brak obsługi errora ze względu na wytyczne quickbooks
  const { query, loading } = useParameterizedQuery(disconnectQuickbooksAction);
  const realmId = useSearchParam(QueryParams.RealmId);
  const language = useRecoilValue(languageSelector);
  const navigate = useAppNavigate();

  useMount(() => {
    if (realmId) {
      void query({ realmId });
    }
  });

  useEffect(() => {
    if (loading) {
      navigate(TO.QUICKBOOKS_DISCONNECT[language], { replace: true });
    }
  }, [language, loading, navigate]);

  return (
    <>
      <Helmet>
        <title>
          {t({ id: 'quickbooks_disconnect' })} - {t({ id: 'quickbooks_disconnect.success', message: 'Success!' })} -{' '}
          {APP_NAME}
        </title>
      </Helmet>
      <AuthenticationLayout showFooter stretchHeight={isSmartphoneBreakpoint}>
        <Flex variant="quickbooksDisconnect.container">
          <Icon size={64} type="approve" iconSx={{ color: 'quickbooksDisconnect' }} />
          <Heading variant="heading1" sx={{ mb: 2 }}>
            <Trans id="quickbooks_disconnect.success.done">QuickBooks Disconnected</Trans>
          </Heading>
          <Text variant={'quickbooksDisconnect.pResolved'}>
            <Trans id="quickbooks_disconnect.disconnected">
              Your QuickBooks integration has been disconnected. You will no longer to be able to send invoices directly
              to your QuickBooks account.
            </Trans>
          </Text>

          <Text variant={'quickbooksDisconnect.pResolved'}>
            <Trans id="quickbooks_disconnect.reconnect">
              If you'd like to re-connect your QuickBooks account, visit our help guide{' '}
              <Link
                href={quickBooksHelpLink}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
              >
                here
              </Link>
              .
            </Trans>
          </Text>
        </Flex>
      </AuthenticationLayout>
    </>
  );
};
