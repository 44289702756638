import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { Divider } from 'components/Divider/Divider';
import { Container } from 'components/ui/Container';
import { ElementGroup } from 'components/ui/ElementGroup';
import { APP_NAME } from 'constants/common';
import { PATH, TO } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { AlertProvider } from 'layouts/AuthorizedApp/AlertContainer/AlertProvider';
import { languageSelector } from 'state/recoilState';
import { settingsPageDataSelector, settingsProfileFormDefaultValuesSelector } from 'state/settings';
import { userSessionAtom } from 'state/userSession';

import { NavLink } from './components/NavLink';
import { useFormReset } from './hooks/useFormReset';

export const SettingsPage: FC = () => {
  useLingui();
  const { pathname } = useLocation();
  const language = useRecoilValue(languageSelector);
  const userSession = useRecoilValue(userSessionAtom);
  const hasPassword = !!userSession?.hasPassword;
  const settingsPageData = useRecoilValue(settingsPageDataSelector);
  const employeeData = useRecoilValue(settingsProfileFormDefaultValuesSelector);

  useFormReset();
  const { isMobileBreakpoint } = useThemeBreakpoint();

  const { modules, systemManagement, isHeadAdmin } = useAppPermissions();

  if (!isMobileBreakpoint && pathname === PATH.SETTINGS[language]) return <Navigate to={TO.SETTINGS__USER[language]} />;

  return (
    <>
      <Helmet>
        <title>{t({ id: 'settings.page_title', message: `Settings - ${APP_NAME}` })}</title>
      </Helmet>

      <Flex variant="settings.container.page">
        <Routes>
          <Route
            path={isMobileBreakpoint ? PATH.SETTINGS[language] : '*'}
            element={
              <Flex as="aside" variant="settings.container.nav">
                <AlertProvider isVisible={isMobileBreakpoint} alertSx={{ mt: 0, mx: 0 }} />
                <NavLink
                  to={PATH.SETTINGS__USER[language]}
                  routeMatches={[
                    TO.YOUR_QR_CODE_MODAL[language],
                    TO.YOUR_BENEFITS_MODAL[language],
                    TO.ADD_AVATAR_MODAL[language],
                    TO.GET_MOBILE_APPS_MODAL[language],
                  ]}
                  label={settingsPageData?.user.parsedName}
                  additionalLabel={t({
                    id: 'settings.user.additional_label',
                    message: 'Your profile, photo, mail & phone',
                  })}
                  avatarProps={{ name: settingsPageData?.user.name, image: employeeData?.avatarUrl }}
                  shape="rounded"
                />

                <ElementGroup marginValue="1px" direction="column" showAsList>
                  <NavLink
                    to={PATH.SETTINGS__USER__PREFERENCES[language]}
                    label={t({
                      id: 'settings.user.preferences',
                      message: 'Preferences',
                    })}
                    iconType="preferences"
                    sx={{ borderTopLeftRadius: 'sm', borderTopRightRadius: 'sm' }}
                  />
                  <NavLink
                    to={PATH.SETTINGS__USER__NOTIFICATIONS[language]}
                    label={t({
                      id: 'settings.user.notifications',
                      message: 'Notifications',
                    })}
                    iconType="notifications"
                  />
                  <NavLink
                    to={PATH.SETTINGS__USER__MANAGE_PASSWORD[language]}
                    label={
                      hasPassword
                        ? t({
                            id: 'settings.user.change_password',
                            message: 'Change password',
                          })
                        : t({
                            id: 'settings.user.set_password',
                            message: 'Set password',
                          })
                    }
                    iconType="lock"
                    sx={{ borderBottomLeftRadius: 'sm', borderBottomRightRadius: 'sm' }}
                  />
                </ElementGroup>

                <NavLink
                  to={PATH.SETTINGS__INTEGRATIONS[language]}
                  label={t({
                    id: 'settings.integrations',
                    message: 'Integrations',
                  })}
                  shape="rounded"
                  iconType="integrations"
                />

                {systemManagement.CompanySettings && (
                  <>
                    <Divider borderColor="secondary" />
                    <NavLink
                      routeMatches={[
                        TO.YOUR_QR_CODE_MODAL[language],
                        TO.YOUR_BENEFITS_MODAL[language],
                        TO.GET_MOBILE_APPS_MODAL[language],
                        TO.ADD_HOLIDAY_MODAL[language],
                        TO.IMPORT_HOLIDAYS_MODAL[language],
                        TO.ADD_AVATAR_MODAL[language],
                        `${TO.IMPORT_HOLIDAYS_MODAL[language]}/*`,
                      ]}
                      to={PATH.SETTINGS__ORGANIZATION[language]}
                      label={settingsPageData?.organization.name}
                      additionalLabel={t({
                        id: 'settings.organization.additional_label',
                        message: 'Your organization',
                      })}
                      avatarProps={{
                        image: settingsPageData?.organization.avatarUrl,
                        name:
                          settingsPageData && settingsPageData.organization && settingsPageData.organization.name
                            ? {
                                firstName: settingsPageData.organization.name[0],
                                surname: settingsPageData.organization.name[1] || ' ',
                              }
                            : undefined,
                      }}
                      shape="rounded"
                    />

                    <ElementGroup
                      marginValue="1px"
                      direction="column"
                      showAsList
                      wrapperSx={{
                        '.element-group__li-element:first-of-type .nav-button': {
                          borderTopLeftRadius: 'sm',
                          borderTopRightRadius: 'sm',
                        },
                        '.element-group__li-element:last-of-type .nav-button': {
                          borderBottomLeftRadius: 'sm',
                          borderBottomRightRadius: 'sm',
                        },
                      }}
                    >
                      {modules.TimeTracking && (
                        <NavLink
                          to={PATH.SETTINGS__ORGANIZATION__TIME_TRACKING[language]}
                          label={t({
                            id: 'settings.organization.time_tracking',
                            message: 'Time tracking',
                          })}
                          iconType="timeClock"
                        />
                      )}
                      {modules.Schedule && (
                        <NavLink
                          to={PATH.SETTINGS__ORGANIZATION__SCHEDULES[language]}
                          label={t({
                            id: 'settings.organization.schedules',
                            message: 'Schedules',
                          })}
                          iconType="calendar"
                        />
                      )}

                      {modules.Requests && (
                        <NavLink
                          to={PATH.SETTINGS__ORGANIZATION__REQUESTS[language]}
                          label={t({
                            id: 'settings.organization.requests',
                            message: 'Requests',
                          })}
                          iconType="requests"
                        />
                      )}
                    </ElementGroup>

                    {modules.TimeTracking && (
                      <NavLink
                        to={PATH.SETTINGS__AUTOMATIONS[language]}
                        label={t({
                          id: 'settings.organization.automations',
                          message: 'Automations',
                        })}
                        shape="rounded"
                        iconType="rocket"
                      />
                    )}

                    <NavLink
                      to={PATH.SETTINGS__ORGANIZATION__NOTIFICATIONS[language]}
                      label={t({
                        id: 'notifications_settings.heading.notifications',
                      })}
                      shape="rounded"
                      iconType="notifications"
                    />

                    {isHeadAdmin && (
                      <NavLink
                        to={PATH.SETTINGS__ORGANIZATION__ADVANCED[language]}
                        label={t({
                          id: 'settings.organization.advanced',
                          message: 'Advanced',
                        })}
                        shape="rounded"
                        iconType="swissKnife"
                      />
                    )}
                  </>
                )}
              </Flex>
            }
          />
        </Routes>
        {/* FIXME: Refactor isVisible after changes in view layout */}
        <AlertProvider isVisible={pathname !== PATH.SETTINGS[language]}>
          <Flex
            as="main"
            variant="settings.container.main"
            data-content-not-visible={`${pathname === PATH.SETTINGS[language]}`}
          >
            <Container
              sx={{
                ml: 0,
                gap: 'inherit',
              }}
            >
              <Outlet />
            </Container>
          </Flex>
        </AlertProvider>
      </Flex>
    </>
  );
};
