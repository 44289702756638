import React, { useCallback, useEffect } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState, useRecoilValue } from 'recoil';

import { fetchHolidayImportLocationsAction } from 'api/actions/holidays/holidaysActions';
import { useFetchCountryDetailsManager } from 'hooks/useFetchCountryDetailsManager/useFetchCountryDetailsManager';
import { countryOptionsSelector, importHolidaysCountryOptionsSelector } from 'state/selectOptions';
import { holidayImportLocationsResponseAtom } from 'state/settings';
import { Select, SelectProps } from '../Select';

type Props = Omit<SelectProps, 'options'> & {
  listVariant?: 'default' | 'holidayImport';
};

export const CountrySelect = React.forwardRef<HTMLInputElement, Props>(
  ({ listVariant = 'default', ...props }: Props, ref): React.ReactElement => {
    const { query } = useClient();

    const [fetchHolidayImportLocationsResponse, setFetchHolidayImportLocationsResponse] = useRecoilState(
      holidayImportLocationsResponseAtom,
    );
    const { fetchCountryDetails, fetchCountryDetailsListResponse } = useFetchCountryDetailsManager();

    const isHolidayImport = listVariant === 'holidayImport';
    const fetchedData = isHolidayImport ? fetchHolidayImportLocationsResponse : fetchCountryDetailsListResponse;

    const countrySelectOptions = useRecoilValue(
      isHolidayImport ? importHolidaysCountryOptionsSelector : countryOptionsSelector,
    );

    const getCountryList = useCallback(async () => {
      if (!isHolidayImport) {
        void fetchCountryDetails();
        return;
      }
      const { payload, error } = await query(fetchHolidayImportLocationsAction());

      if (payload && !error) {
        setFetchHolidayImportLocationsResponse(payload);
      }
    }, [setFetchHolidayImportLocationsResponse, query, isHolidayImport, fetchCountryDetails]);

    useEffect(() => {
      if (!fetchedData) void getCountryList();
    }, [fetchedData, getCountryList]);

    return <Select ref={ref} updateViewOnOptionsChange {...props} options={countrySelectOptions} />;
  },
);
