import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Flex } from 'theme-ui';

import { TimeEventActionResponse, TimeEventHistoryEntry } from 'api/actions/timeEvent/timeEventActions.types';
import { Badge, BadgeProps } from 'components/Badge/Badge';

import { EventHistoryChunk } from './EventHistoryChunk';

type Props = {
  action: TimeEventActionResponse;
  modificationPersonId?: string;
  modificationDateTime?: number;
  previous?: TimeEventHistoryEntry;
};

export const EventHistoryRow = ({
  action,
  modificationPersonId,
  modificationDateTime,
  previous,
}: Props): React.ReactElement => {
  useLingui();

  const timeEventAction: { [key in TimeEventActionResponse]: { variant: BadgeProps['variant']; message: string } } = {
    [TimeEventActionResponse.Create]: {
      variant: 'default',
      message: t({ id: 'clock_log.created', message: 'Created' }),
    },
    [TimeEventActionResponse.Delete]: {
      variant: 'danger',
      message: t({ id: 'clock_log.deleted', message: 'Deleted' }),
    },
    [TimeEventActionResponse.Modified]: {
      variant: 'warning',
      message: t({ id: 'clock_log.modified', message: 'Modified' }),
    },
    [TimeEventActionResponse.Restore]: {
      variant: 'success',
      message: t({ id: 'clock_log.restored', message: 'Restored' }),
    },
  };

  return (
    <Flex
      sx={{
        flexDirection: 'row',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '&:last-of-type > .badge': {
          '&::after': {
            display: 'none',
          },
        },
      }}
    >
      <Flex
        className="badge"
        sx={{
          flex: '1 0',
          maxWidth: '120px',
          alignSelf: 'stretch',
          flexDirection: 'column',
          alignItems: 'flex-start',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 13,
            width: '2px',
            bg: 'clockLog.eventHistory.badge',
            zIndex: 0,
          },
        }}
      >
        <Badge variant={timeEventAction[action].variant} sx={{ zIndex: '1' }}>
          {timeEventAction[action].message}
        </Badge>
      </Flex>

      <EventHistoryChunk
        action={action}
        modificationPersonId={modificationPersonId}
        modificationDateTime={modificationDateTime}
        previous={previous}
      />
    </Flex>
  );
};
