import { useRecoilValue } from 'recoil';

import { PATH } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { isWorkdayAvailabilityAvailableSelector, schedulesSettingsSelector } from 'state/organizationSession';

export const useCalendarTabPermissions = () => {
  const schedulesSettings = useRecoilValue(schedulesSettingsSelector);
  const isWorkdayAvailabilityAvailable = useRecoilValue(isWorkdayAvailabilityAvailableSelector);
  const { modules, modulesManagement } = useAppPermissions();

  const showAISchedules = schedulesSettings?.useAiScheduling && modulesManagement.Schedules;
  const showTabSchedules = modules.Schedule && (modules.Requests || isWorkdayAvailabilityAvailable || showAISchedules);
  const showTabAvailability = isWorkdayAvailabilityAvailable && modules.Schedule;
  const showTabRequests = modules.Requests && modules.Schedule;

  const shouldAnyTabsBeVisible = showTabSchedules || showTabAvailability || showTabRequests;

  const hideTabAll = showTabSchedules && showAISchedules && !showTabAvailability && !showTabRequests;

  const calendarBaseRoute =
    shouldAnyTabsBeVisible && hideTabAll
      ? PATH.CALENDAR__SCHEDULES__START_DATE_UNIX__END_DATE_UNIX
      : PATH.CALENDAR__ALL__START_DATE_UNIX__END_DATE_UNIX;
  return {
    showAISchedules,
    showTabSchedules,
    showTabAvailability,
    showTabRequests,
    shouldAnyTabsBeVisible,
    hideTabAll,
    calendarBaseRoute,
  };
};
