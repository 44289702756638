import _ from 'lodash';
import React, { CSSProperties, useCallback, useContext, useMemo } from 'react';
import { Flex } from 'theme-ui';

import { useTheme } from 'styles/useTheme';

import { MemorizedContentPlaceholder } from './ContentPlaceholder';
import { MemorizedFrozenColumnContainer } from './FrozenColumnsContainer';
import { MemorizedHeader } from './Header';
import { MemorizedSummaryRow } from './SummaryRow';
import { GRID_DEFAULT_HEIGHT, ITEM_DEFAULT_HEIGHT, ITEM_DEFAULT_WIDTH } from './constants';
import { StickyListContext } from './context';
import { gridRenderer } from './renderers/gridRenderer';
import { GridRendererProps, ListRendererProps } from './types';

type InnerElementProps = {
  children: {
    props: GridRendererProps & ListRendererProps;
  }[];
  style: CSSProperties;
};

export const StickyListInnerElement = React.forwardRef<HTMLDivElement, InnerElementProps>(
  ({ children, style }: InnerElementProps, ref) => {
    const { theme } = useTheme();
    const {
      showHeader,
      showContentPlaceholder,
      columns,
      name: listName,
      select,
      variant,
      headerSx,
      type,
      frozenColumns,
      list,
      itemCount,
      isRowUncheckableValidator,
      withSummary,
      itemHeight,
      itemWidth,
      appendHeaderWith,
      listStyle,
    } = useContext(StickyListContext);

    const listItemHeight = useMemo(() => {
      if (type === 'list') {
        return itemHeight || ITEM_DEFAULT_HEIGHT;
      }

      return GRID_DEFAULT_HEIGHT;
    }, [itemHeight, type]);
    const listItemWidth = useMemo(() => itemWidth || ITEM_DEFAULT_WIDTH, [itemWidth]);

    const additionalRowsStyle = useCallback<(hasHeader?: boolean, hasSummary?: boolean) => InnerElementProps['style']>(
      (hasHeader, hasSummary) => {
        const additionalHeight = hasHeader && hasSummary ? 2 * listItemHeight : listItemHeight;
        return { ...style, height: (!_.isNaN(style.height) ? parseInt(`${style.height}`, 10) : 0) + additionalHeight };
      },
      [listItemHeight, style],
    );

    const visibleRows = useMemo(() => _.uniq(_.map(children, (child) => child.props.rowIndex)), [children]);

    const rowStyles = useMemo(
      () =>
        _.map(visibleRows, (rowIndex) => {
          const el = _.find(children, (child) => child.props.rowIndex === rowIndex);
          if (el) {
            return el.props.style;
          }

          return {};
        }),
      [children, visibleRows],
    );

    const columnsFrozen = useMemo(
      () => (frozenColumns ? _.take(columns, frozenColumns) : undefined),
      [columns, frozenColumns],
    );
    const columnsNonFrozen = useMemo(
      () => (frozenColumns ? _.slice(columns, frozenColumns) : undefined),
      [columns, frozenColumns],
    );

    const stickyContainerWidth = useMemo(
      () =>
        _.reduce(
          _.map(columnsFrozen, (col) => parseInt(`${col.width}`, 10) || listItemWidth),
          (sum, n) => +sum + +n,
        ),
      [columnsFrozen, listItemWidth],
    );

    const gridStyles: CSSProperties = useMemo(
      () => ({
        width: parseInt(`${style.width}`, 10),
        flexDirection: 'row',
        flexWrap: 'wrap',
      }),
      [style.width],
    );

    const headerStyles = useMemo(
      () => ({
        minHeight: listItemHeight,
      }),
      [listItemHeight],
    );
    const headerStylesWithFrozenColumns = useMemo(
      () => ({
        frozenColumns: {
          minHeight: listItemHeight,
          width: parseInt(`${stickyContainerWidth}`, 10),
          flex: 'initial',
          zIndex: 44,
          boxShadow: 'frozenContainer',
        },
        nonFrozenColumns: {
          minHeight: listItemHeight,
          width: `calc(100% - ${stickyContainerWidth}px)`,
          flex: 'initial',
          zIndex: 43,
        },
      }),
      [listItemHeight, stickyContainerWidth],
    );

    const showSummary = useMemo(() => !!(withSummary && itemCount && itemCount > 1), [itemCount, withSummary]);

    const innerElementStyle: InnerElementProps['style'] = useMemo(
      () => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        ...(showHeader || showSummary ? additionalRowsStyle(showHeader, showSummary) : style),
        ...(type === 'grid' && gridStyles),
      }),
      [additionalRowsStyle, gridStyles, showHeader, style, type, showSummary],
    );

    return (
      <div ref={ref} style={innerElementStyle}>
        <>
          {showHeader &&
            listName &&
            (columnsFrozen && columnsNonFrozen ? (
              <>
                <MemorizedHeader
                  sx={{ ...headerStylesWithFrozenColumns.frozenColumns, ...headerSx }}
                  key="FrozenHeaderColumns"
                  type={type}
                  columns={columnsFrozen}
                  listName={listName}
                  select={select}
                  variant={variant}
                  gridWidth={itemWidth}
                />
                <MemorizedHeader
                  sx={{ ...headerStylesWithFrozenColumns.nonFrozenColumns, ...headerSx }}
                  key="Header"
                  type={type}
                  columns={columnsNonFrozen}
                  listName={listName}
                  variant={variant}
                  gridWidth={itemWidth}
                  appendHeaderWith={appendHeaderWith}
                />
              </>
            ) : (
              columns && (
                <MemorizedHeader
                  style={headerStyles}
                  sx={{ ...headerSx, minWidth: '100%' }}
                  key="Header"
                  type={type}
                  columns={columns}
                  listName={listName}
                  select={select}
                  variant={variant}
                  gridWidth={itemWidth}
                  appendHeaderWith={appendHeaderWith}
                />
              )
            ))}
          {frozenColumns && columnsFrozen && itemCount && listName && list && style.height && (
            <MemorizedFrozenColumnContainer width={stickyContainerWidth} height={style.height}>
              {visibleRows.map((row, index) => {
                const getElementHeight = () => {
                  if (type === 'list') {
                    return ITEM_DEFAULT_HEIGHT;
                  }

                  if (!_.isNaN(rowStyles[index]?.height)) {
                    return rowStyles[index]?.height;
                  }

                  return undefined;
                };

                return (
                  <Flex
                    key={row}
                    style={{
                      position: 'absolute',
                      top: !_.isNaN(rowStyles[index]?.top) ? rowStyles[index]?.top : undefined,
                    }}
                  >
                    {columnsFrozen.map((column, columnIndex) =>
                      gridRenderer({
                        data: {
                          columns: columnsFrozen,
                          itemCount,
                          list,
                          name: listName,
                          showHeader,
                          frozenColumns,
                          variant,
                          select,
                          isRowUncheckableValidator,
                        },
                        columnIndex,
                        rowIndex: row,
                        style: {
                          height: getElementHeight(),
                          width: (column.width && parseInt(`${column.width}`, 10)) || listItemWidth,
                          outline: `1px solid ${theme.colors.stickyList.outline.frozen}`,
                        },
                      }),
                    )}
                  </Flex>
                );
              })}
              {showContentPlaceholder && <MemorizedContentPlaceholder type={type} sx={{ mr: 0 }} />}
            </MemorizedFrozenColumnContainer>
          )}
          {/* Desktop view */}
          {showSummary && columnsFrozen && columnsNonFrozen && (
            <Flex sx={{ position: 'sticky', bottom: `-${listItemHeight - 15}px`, left: 0, zIndex: 45 }}>
              <MemorizedSummaryRow
                sx={headerStylesWithFrozenColumns.frozenColumns}
                key="FrozenSummaryColumns"
                columns={columnsFrozen}
                list={list}
                variant={variant}
                gridWidth={itemWidth}
              />
              <MemorizedSummaryRow
                sx={headerStylesWithFrozenColumns.nonFrozenColumns}
                key="Summary"
                columns={columnsNonFrozen}
                stickyContainerWidth={stickyContainerWidth}
                list={list}
                variant={variant}
                gridWidth={itemWidth}
              />
            </Flex>
          )}
          {/* Mobile view */}
          {showSummary && columns && !columnsFrozen && !columnsNonFrozen && (
            <MemorizedSummaryRow
              sx={{
                top: `calc(100% - ${listItemHeight}px + ${listStyle?.paddingBottom}px)`,
                bottom: `-${listStyle?.paddingBottom}px`,
                height: `${listItemHeight}px`,
                zIndex: 45,
              }}
              key="MobileSummary"
              columns={columns}
              list={list}
              variant={variant}
              gridWidth={itemWidth}
            />
          )}
          {children}
          {showContentPlaceholder && <MemorizedContentPlaceholder type={type} />}
        </>
      </div>
    );
  },
);
