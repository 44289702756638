import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex, Text } from 'theme-ui';

import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { RadioButton } from 'components/ui/RadioButton/RadioButton';
import { RadioButtonGroup } from 'components/ui/RadioButton/RadioButtonGroup';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';

export const SelectType = React.memo((): React.ReactElement | null => {
  useLingui();
  const {
    addRequestState: { requestType },
    requestConfig: { requestConfigState },
  } = useAddRequest();
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();
  const { setValue, watch } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  if (requestType === RequestFormType.Schedule) return null;
  if (requestConfigState?.hideActionSelect) return null;

  return (
    <Flex sx={{ flexDirection: 'column', mb: 4 }}>
      <Text sx={{ mb: 2, fontWeight: 'bold' }}>
        <Trans id="requests.add_request.action_type">Action type</Trans>
      </Text>
      <RadioButtonGroup
        name="actionTypeGroup"
        onChange={(e) => {
          setValue('actionType', +e.target.value);
        }}
        orientation={isSmartphoneBreakpoint ? 'column' : 'row'}
        columns={!isSmartphoneBreakpoint ? 3 : undefined}
        defaultValue={actionTypeWatch}
        gap="4px"
      >
        {!requestConfigState?.requestManagement?.isEvent ? (
          <RadioButton
            label={t({
              id: 'requests.add_request.time_tracker_action.create_new',
              message: 'Create new',
            })}
            value={RequestActionType.Create}
            prependWidth={<Icon type="plus" wrapperSx={{ mr: 2 }} />}
          />
        ) : (
          <></>
        )}
        <RadioButton
          label={t({
            id: 'requests.add_request.time_tracker_action.edit',
            message: 'Edit',
          })}
          value={RequestActionType.Edit}
          prependWidth={<Icon type="requestEdit" wrapperSx={{ mr: 2 }} />}
        />
        <RadioButton
          label={t({
            id: 'requests.add_request.time_tracker_action.remove',
            message: 'Remove',
          })}
          value={RequestActionType.Remove}
          prependWidth={<Icon type="requestDelete" wrapperSx={{ mr: 2 }} />}
        />
      </RadioButtonGroup>
    </Flex>
  );
});
