import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';

import { Button } from 'components/ui/Buttons/Button';
import { expiredSx } from '../../../styles/styles';
import { IntegrationsExpiredButtonProps } from '../../../types';

type Props = IntegrationsExpiredButtonProps;

export const Expired = ({ isExpired, ...props }: Props): React.ReactElement => {
  useLingui();

  if (!isExpired) return <></>;

  return (
    <Button variant="naked" size="sm" sx={{ ...expiredSx }} {...props}>
      {t({ id: 'reconnect', message: 'Reconnect' })}
    </Button>
  );
};
