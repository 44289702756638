import React, { useCallback } from 'react';
import { Box, Text } from 'theme-ui';
import { Trans, t } from '@lingui/macro';

import { Icon } from 'components/Icon/Icon';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { Button } from '../Buttons';
import { TextEllipsis } from 'components/utils/TextEllipsis';

import {
  FileNameProps,
  ProgressBarProps,
  LoadingStatusProps,
  UploadingButtonsProps,
  FileDataProps,
  DeleteButtonProps,
} from './types';
import { bytesToMB, shortenFileName } from './helpers';
import { DEFAULT_MAX_NAME_DISPLAY_LENGTH } from './constants';

const TextWithTooltip = withTooltip(Text);

export const ProgressBar = ({ hasError, uploadPercent }: ProgressBarProps): React.ReactElement => (
  <Box variant="fileUpload.progressBar" data-has-error={hasError} style={{ width: `${uploadPercent}%` }} />
);

export const LoadingStatus = ({ hasError }: LoadingStatusProps): React.ReactElement => (
  <Text
    sx={{
      ...(hasError && {
        color: 'fileUpload.loadingStatus.error',
      }),
    }}
  >
    {!hasError ? (
      <Trans id="file_upload.uploading">Uploading...</Trans>
    ) : (
      <Trans id="file_upload.failure">Upload failed...</Trans>
    )}
  </Text>
);

export const UploadingButtons = ({ onCancel, onRetry, showRetry }: UploadingButtonsProps): React.ReactElement => {
  const handleOnClick = useCallback(() => {
    if (showRetry) onRetry();
    else if (onCancel) onCancel();
  }, [onCancel, onRetry, showRetry]);

  return (
    <Button
      onClick={handleOnClick}
      variant="minimal"
      size="sm"
      shape="rounded"
      bgOverwrite={{
        default: 'fileUpload.uploadingButtons.background.default',
        hover: 'fileUpload.uploadingButtons.background.hover',
        tap: 'fileUpload.uploadingButtons.background.tap',
        disabled: 'fileUpload.uploadingButtons.background.disabled',
      }}
    >
      {showRetry ? t({ id: 'file_upload.retry' }) : <Icon type="x" />}
    </Button>
  );
};

export const FileName = ({ name }: FileNameProps): React.ReactElement => (
  <ConditionalWrapper
    condition={name.length > DEFAULT_MAX_NAME_DISPLAY_LENGTH}
    wrapper={({ children }) => (
      <TextWithTooltip tooltipProps={{ content: name }}>
        <>{children}</>
      </TextWithTooltip>
    )}
  >
    <TextEllipsis title={name}>{shortenFileName(name)}</TextEllipsis>
  </ConditionalWrapper>
);

export const FileData = ({ size }: FileDataProps): React.ReactElement => (
  <Text variant="fileUpload.smallText">{`${bytesToMB(size)} MB`}</Text>
);

export const DeleteButton = ({ onClick }: DeleteButtonProps): React.ReactElement | null => (
  <Button
    variant="minimal"
    size="xs"
    shape="circle"
    aria-label="Delete"
    onClick={onClick}
    bgOverwrite={{
      default: 'notification.closeButton.background.default',
      hover: 'notification.closeButton.background.hover',
      tap: 'notification.closeButton.background.tap',
      disabled: 'notification.closeButton.background.disabled',
    }}
    sx={{
      position: 'absolute',
      top: -7,
      right: -7,
      width: '20px',
      height: '20px',
      border: `1px solid`,
      borderColor: 'fileUpload.deleteButton.border',
    }}
  >
    <Icon type="x" size={16} fill={'fileUpload.deleteButton.fill'} />
  </Button>
);
