import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { dateTime } from 'utils/dateTime';
import { getBrowserRegion, getNavigatorRegion } from 'utils/getBrowserRegion';
import { UUID } from 'utils/UUID';

import { API_ENDPOINTS } from './api';

dayjs.extend(timezone);
dayjs.extend(utc);

export const {
  APP_VERSION,
  REACT_APP_UNRUBBLE_SIGNAL_R_API_URL,
  REACT_APP_INEWI_SIGNAL_R_API_URL,
  REACT_APP_TEST_PRODUCTION, // used only for deploying test servers with production environment
} = process.env;

export const IS_ANDROID = /Android/i.test(navigator.userAgent);
export const IS_IOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
export const IS_MOBILE = IS_ANDROID || IS_IOS;
export const IS_TOUCH_DEVICE = window.matchMedia('(pointer: coarse)').matches;

export const ACCESS_TOKEN_KEY = 'accessToken';
export const TAB_ID = UUID();
export const REACT_APP_HOSTNAME = { inewi: 'inewi.pl', unrubble: 'unrubble.com' };
// export const FETCH_TIMEOUT_THRESHOLD = parseInt(process.env.REACT_APP_FETCH_TIMEOUT_THRESHOLD || '500', 10);
export const FETCH_TIMEOUT_THRESHOLD = parseInt(process.env.REACT_APP_FETCH_TIMEOUT_THRESHOLD || '600000', 10);
export const FETCH_TIMEOUT_REQUESTS_MAX = parseInt(process.env.REACT_APP_FETCH_TIMEOUT_REQUESTS_MAX || '5', 10);
export const FETCH_TIMEOUT_EXCEEDED_RESPONSE_STATUS = 512;
export const REAL_TIMEZONE = dayjs.tz.guess();

export const APP_LINK_BASENAME = process.env.PUBLIC_URL === '/' ? '' : process.env.PUBLIC_URL || '';
export const BASENAME = process.env.PUBLIC_URL || '/';
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const { search } = window.location;
const CHAT_PARAM = 'chat';
export const INITIAL_SEARCH_PARAMS = new URLSearchParams(search);
export const INITIALIZE_CHAT_WITH_OPEN_CRISP_CHAT = INITIAL_SEARCH_PARAMS.get(CHAT_PARAM) !== null;

let { hostname } = window.location;

let signalRApiUrl;

if (process.env.NODE_ENV === 'production') {
  if (REACT_APP_UNRUBBLE_SIGNAL_R_API_URL && hostname === REACT_APP_HOSTNAME.unrubble) {
    signalRApiUrl = REACT_APP_UNRUBBLE_SIGNAL_R_API_URL;
  }
  if (REACT_APP_INEWI_SIGNAL_R_API_URL && hostname === REACT_APP_HOSTNAME.inewi) {
    signalRApiUrl = REACT_APP_INEWI_SIGNAL_R_API_URL;
  }
}

if (process.env.NODE_ENV !== 'production' || REACT_APP_TEST_PRODUCTION) {
  hostname = hostname.includes('-en.bb.vpn') ? REACT_APP_HOSTNAME.unrubble : REACT_APP_HOSTNAME.inewi;
  hostname = process.env.REACT_APP_HOSTNAME || hostname;
}

let reactAppApiUrl = process.env.REACT_APP_API_URL;

if ((process.env.NODE_ENV !== 'production' || REACT_APP_TEST_PRODUCTION) && reactAppApiUrl) {
  const { url } = new Request(reactAppApiUrl);

  if (url !== reactAppApiUrl) {
    // remove port
    reactAppApiUrl = url.replace(/(:[0-9]+)(?=\/)/g, '');
  }
}

export const REACT_APP_API_URL = reactAppApiUrl || '/';
export const HOSTNAME = hostname;
export const APP_NAME = hostname === REACT_APP_HOSTNAME.inewi ? 'inEwi' : 'Unrubble';

export const NAVIGATOR_REGION = getNavigatorRegion();
export const BROWSER_REGION = getBrowserRegion();
export const HOST_REGION = hostname === REACT_APP_HOSTNAME.inewi ? 'pl' : 'us';

export const SIGNAL_R_API_URL = signalRApiUrl || REACT_APP_API_URL;

export const CRISP_WEBSITE_ID =
  (hostname === REACT_APP_HOSTNAME.inewi
    ? process.env.REACT_APP_CRISP_WEBSITE_ID_INEWI
    : process.env.REACT_APP_CRISP_WEBSITE_ID_GLOBAL) || '';

export const APPLE_CLIENT_ID =
  (hostname === REACT_APP_HOSTNAME.inewi
    ? process.env.REACT_APP_APPLE_CLIENT_ID_INEWI
    : process.env.REACT_APP_APPLE_CLIENT_ID_GLOBAL) || '';
export const GOOGLE_CLIENT_ID =
  (hostname === REACT_APP_HOSTNAME.inewi
    ? process.env.REACT_APP_GOOGLE_CLIENT_ID_INEWI
    : process.env.REACT_APP_GOOGLE_CLIENT_ID_GLOBAL) || '';

// export const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const DEFAULT_TIMEZONE = REAL_TIMEZONE;
export const DEFAULT_MIN_DATE = dateTime(1420070400, { utc: true }); // 01/01/2015
export const DEFAULT_MAX_DATE = dateTime(undefined, { utc: true }).startOf('day').add(10, 'year').endOf('day');

export const AVATAR_MAX_SIZE_IN_BYTES = 5242880; // 5MB

export const AVATAR_MIN_HEIGHT_WIDTH = 512;

export const NOTIFICATION_FETCH_INTERVAL = 60000;

export const UNRUBBLE_HELP = 'https://help.unrubble.com';
export const INEWI_HELP = 'https://pomoc.inewi.pl';

export const APPLE_LINK_URL =
  process.env.NODE_ENV === 'production'
    ? `https://${HOSTNAME}${REACT_APP_API_URL}${API_ENDPOINTS.appleLink}`
    : `${REACT_APP_API_URL}${API_ENDPOINTS.appleLink}`;
export const APPLE_LOGIN_URL =
  process.env.NODE_ENV === 'production'
    ? `https://${HOSTNAME}${REACT_APP_API_URL}${API_ENDPOINTS.appleLogin}`
    : `${REACT_APP_API_URL}${API_ENDPOINTS.appleLogin}`;

export const APP_STORE_DOWNLOAD_APP_LINK =
  hostname === REACT_APP_HOSTNAME.inewi
    ? 'https://apps.apple.com/pl/app/inewi-grafik-pracy/id1342633929'
    : 'https://apps.apple.com/us/app/tracktime24-staff-scheduling/id1376435052';

export const APP_STORE_DOWNLOAD_RCP_APP_LINK =
  hostname === REACT_APP_HOSTNAME.inewi
    ? 'https://apps.apple.com/pl/app/inewi-rcp/id1198108254'
    : 'https://apps.apple.com/us/app/tracktime24-timeclock/id1290678274';

export const GOOGLE_PLAY_DOWNLOAD_APP_LINK =
  hostname === REACT_APP_HOSTNAME.inewi
    ? 'https://play.google.com/store/apps/details?id=com.inewi.inewiemplo'
    : 'https://play.google.com/store/apps/details?id=com.tracktime24.employee';

export const GOOGLE_PLAY_DOWNLOAD_RCP_APP_LINK =
  hostname === REACT_APP_HOSTNAME.inewi
    ? 'https://play.google.com/store/apps/details?id=com.inewi.terminal'
    : 'https://play.google.com/store/apps/details?id=com.tracktime24.timetracker';

export const FLAG_CDN = 'https://flagcdn.com';

export const DOWNLOAD_EXCEL_TEMPLATE_LINK =
  hostname === REACT_APP_HOSTNAME.inewi
    ? 'https://inewistorage.blob.core.windows.net/files/import_Pl.xls'
    : 'https://inewistorage.blob.core.windows.net/files/import_En.xls';

export const LOGO_SRC_WORKER_APP =
  hostname === REACT_APP_HOSTNAME.inewi
    ? 'https://cdn.inewi.pl/images/_assets/inewi/pracownik.png'
    : 'https://cdn.unrubble.com/images/_assets/unrubble/Worker.png';

export const LOGO_SRC_TIME_CLOCK_APP =
  hostname === REACT_APP_HOSTNAME.inewi
    ? 'https://cdn.inewi.pl/images/_assets/inewi/rcp.png'
    : 'https://cdn.unrubble.com/images/_assets/unrubble/TimeClock.png';

export const WORKER_APP_NAME =
  hostname === REACT_APP_HOSTNAME.inewi ? 'inEwi - Grafik Pracy, Rejestracja czasu pracy' : 'Unrubble: your work life';

export const TIME_CLOCK_APP_NAME =
  hostname === REACT_APP_HOSTNAME.inewi ? 'inEwi RCP - Rejestrator Czasu' : 'Time Clock by Unrubble';

// this is used to validate roles names when adding new ones, array includes all default roles names with all the translations
// update whet new default role or language added
export const DEFAULT_ROLES_NAMES = [
  // en
  'Administrator',
  'Employee',
  'Manager',
  'Supervisor',
  // pl
  'Pracownik',
  'Menadżer',
  'Przełożony',
];

export const DEFAULT_WORK_STATUSES_NAMES = [
  // en
  'Break',
  'Break Unpaid',
  // pl
  'Przerwa',
  'Przerwa bezpłatna',
];

export const DEFAULT_WORK_STATUSES_IDS = {
  ENTER: '1840417b-98b8-483f-9c60-92eacaa30075',
  EXIT: '421db62e-4de7-450e-8841-095f197a0148',
  PAID_BREAK: '511db62e-4de7-450e-8841-095f197a0146',
  UNPAID_BREAK: '721db62e-4de7-450e-8841-095f197a0147',
  // custom id only on fe site
  LATE: 'late-id',
  ATTENDANCE_PILL: 'attendance-pill',
  // TODO: adjust to proper id from be
  TASK: 'custom-task-id',
};

export const DEFAULT_CALENDAR_VIEW_TYPES_IDS = {
  INEWI: 'b1241962-d9b4-4d62-a902-d6f6f9dd00f7',
  TIME_TRACKING: '12908b7c-eae3-473e-89c0-eefac9dbd754',
};

export const TIME_OFF_DEFAULT_VIEW_SETTINGS_TYPE_ID =
  APP_NAME === 'inEwi' ? DEFAULT_CALENDAR_VIEW_TYPES_IDS.INEWI : DEFAULT_CALENDAR_VIEW_TYPES_IDS.TIME_TRACKING;

export const isInewi = hostname === REACT_APP_HOSTNAME.inewi;
