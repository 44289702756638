import React from 'react';
import { ThemeUIStyleObject, Text } from 'theme-ui';

import { Button, ButtonProps } from 'components/ui/Buttons';
import { ButtonSharedProps } from 'components/ui/Buttons/types';

type Props = {
  onClick?: ButtonProps['onClick'];
  children: React.ReactNode;
  shape?: 'rounded' | 'flat' | 'roundedTop' | 'roundedBottom';
  variant?: 'default' | 'start' | 'stop';
  sx?: ThemeUIStyleObject;
  prependWith?: ButtonSharedProps['prependWith'];
};

export type SummaryButtonType = Props;

const defaultProps: Partial<Props> = {
  variant: 'default',
  shape: 'flat',
  sx: undefined,
  prependWith: undefined,
  onClick: undefined,
};

export const SummaryButton = ({
  children,
  variant = 'default',
  shape,
  onClick,
  sx,
  prependWith,
  ...props
}: Props): React.ReactElement => (
  <Button
    {...props}
    prependWith={prependWith}
    onClick={onClick}
    {...(variant === 'default' && {
      bgOverwrite: {
        default: 'kiosk.summaryButton.bg.default',
        hover: 'kiosk.summaryButton.bg.hover',
        tap: 'kiosk.summaryButton.bg.tap',
        disabled: 'kiosk.summaryButton.bg.disabled',
      },
    })}
    variant={(variant === 'start' && 'success') || (variant === 'stop' && 'danger') || 'naked'}
    shape={shape}
    size="lg"
    fullWidth
    sx={{
      color: 'kiosk.summaryButton.text',
      textTransform: 'uppercase',
      fontSize: [3, 4],
      px: [3, 4],
      gap: [2, 3],
      '> span': {
        minWidth: 0,
      },
      ...(sx && sx),
      ...(!onClick && { pointerEvents: 'none' }),
    }}
  >
    <Text
      as="span"
      sx={{
        width: 'calc(100%)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </Text>
  </Button>
);

SummaryButton.defaultProps = defaultProps;
