import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { ListOnItemsRenderedProps } from 'react-window';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import {
  requestGetTimeTrackingToUpdateAction,
  requestGetToUpdateAction,
  requestSingleGetToUpdateAction,
} from 'api/actions/requests/requestsActions';
import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { WorkStatusBadge } from 'components/recipes/WorkStatusBadge';
import { ListNames } from 'components/StickyList/types';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { useMount } from 'hooks/useMount/useMount';
import { usePickTeammates } from 'hooks/usePickTeammates/usePickTeammates';
import { displayDuration } from 'pages/Requests/output/displayDuration';
import { customRequestTypesDictionarySelector, timeOffTypesDictionarySelector } from 'state/organizationSession';
import { requestHistoryAtom, selectedRequestToEditRemoveAtom } from 'state/requests';
import { EventsToUpdateSelectOption, requestsToUpdateOptionsSelectorFamily } from 'state/selectOptions';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { OPTION_LIST_GAP } from '../constants';
import { OptionProps } from '../elements';
import { Select, SelectProps } from '../Select';

import { abbreviationRectRenderer, ContentWrapper, numberRectRenderer, Rectangle } from './elements';

const BATCH_MAX_LENGTH = 10;
const TIME_OFF_OPTION_HEIGHT = 62 + OPTION_LIST_GAP;

type RequestsToUpdateSelectProps = Omit<SelectProps<EventsToUpdateSelectOption>, 'options' | 'placeholder'> & {
  requestType: RequestFormType;
  requiredRequestId?: string; // this request will be fetched onmount and attached to the the first history batch (it will be available in select options)
};

export const RequestsToUpdateSelect = React.forwardRef<HTMLInputElement, RequestsToUpdateSelectProps>(
  ({ requestType, requiredRequestId, ...props }: RequestsToUpdateSelectProps, ref): React.ReactElement => {
    useLingui();
    const requestTypesDictionary = useRecoilValue(
      requestType === RequestFormType.TimeOff ? timeOffTypesDictionarySelector : customRequestTypesDictionarySelector,
    );
    const eventsToUpdateOptions = useRecoilValue(requestsToUpdateOptionsSelectorFamily(requestType));
    const setRequestHistory = useSetRecoilState(requestHistoryAtom);
    const resetSelectedRequestToEditRemove = useResetRecoilState(selectedRequestToEditRemoveAtom);
    const [selectedUsers] = usePickTeammates(ListNames.REQUESTS_PICK_TEAMMATES);

    const { query, loading } = useParameterizedQuery(requestGetToUpdateAction);
    const { query: getSingleRequestHistoryItem, loading: loadingGetSingleRequestHistoryItem } = useParameterizedQuery(
      requestType === RequestFormType.TimeTracking
        ? requestGetTimeTrackingToUpdateAction
        : requestSingleGetToUpdateAction,
    );

    const hiddenRef = useRef<HTMLInputElement | null>(null);

    const initializedRef = useRef(false);
    const fullyFetchedRef = useRef(false);

    const fetchedHistoryLengthRef = useRef<number>(0);

    const itemSize = (() => {
      switch (requestType) {
        case RequestFormType.TimeOff:
        case RequestFormType.Custom:
          return TIME_OFF_OPTION_HEIGHT;
        default:
          return undefined;
      }
    })();

    const getRequestHistory = useCallback(
      async (id?: string) => {
        const skip = fetchedHistoryLengthRef.current;
        const personId = selectedUsers[0];

        const { error, payload } = await query({ personId, skip, type: requestType });

        initializedRef.current = true;

        let shouldFetchRequiredRequest = false;

        if (!error && payload) {
          shouldFetchRequiredRequest = id
            ? !payload.filter(({ eventUpdateDetails }) => eventUpdateDetails.id === id).length
            : false;

          setRequestHistory((prevValue) =>
            prevValue
              ? _.chain([...prevValue, ...payload])
                  .uniqBy((el) => el.eventUpdateDetails.id)
                  .orderBy(({ dateTimeDetails: { dates } }) => (dates.length ? dates[0] : dates), 'desc')
                  .value()
              : payload,
          );
          fetchedHistoryLengthRef.current += payload.length;
        }

        if (id && shouldFetchRequiredRequest) {
          const { error: getSingleRequestHistoryItemError, payload: getSingleRequestHistoryItemPayload } =
            await getSingleRequestHistoryItem({ id, skipInnerCode: true });
          if (!getSingleRequestHistoryItemError && getSingleRequestHistoryItemPayload) {
            setRequestHistory((prevValue) =>
              prevValue
                ? [...prevValue.filter((el, i, arr) => i !== arr.length - 1), getSingleRequestHistoryItemPayload]
                : [getSingleRequestHistoryItemPayload],
            );
            fetchedHistoryLengthRef.current += -1;
          }
          if (getSingleRequestHistoryItemError) {
            setNativeValue(hiddenRef, '');
          }
        }

        if (payload?.length === BATCH_MAX_LENGTH) return;

        fullyFetchedRef.current = true;
      },
      [query, selectedUsers, setRequestHistory, requestType, getSingleRequestHistoryItem],
    );

    const onItemsRendered = useCallback(
      ({ visibleStopIndex }: ListOnItemsRenderedProps) => {
        if (!eventsToUpdateOptions) return;
        const optionsLength = eventsToUpdateOptions.length;
        const fetchTresholdIndex =
          (optionsLength > BATCH_MAX_LENGTH ? optionsLength - BATCH_MAX_LENGTH / 2 : BATCH_MAX_LENGTH) - 1;

        if (fullyFetchedRef.current || loading || visibleStopIndex < fetchTresholdIndex) {
          return;
        }

        void getRequestHistory();
      },
      [loading, getRequestHistory, eventsToUpdateOptions],
    );

    useMount(() => {
      void getRequestHistory(requiredRequestId);
    });

    useEffect(
      () => () => {
        setRequestHistory(null);
      },
      [setRequestHistory],
    );

    useEffect(
      () => () => {
        resetSelectedRequestToEditRemove();
      },
      [resetSelectedRequestToEditRemove],
    );

    const durationRenderer = useCallback((duration?: number, active?: boolean) => {
      if (!duration) return null;

      const dur = displayDuration(duration);

      if (!dur.length) return null;

      return (
        <Text
          as="span"
          sx={{ color: active ? 'select.duration.color.active' : 'select.duration.color.default', flexShrink: 0 }}
        >
          ({dur})
        </Text>
      );
    }, []);

    const durationToString = useCallback((duration?: number) => {
      if (!duration) return '';

      const dur = displayDuration(duration);

      if (!dur.length) return '';

      return `(${dur})`;
    }, []);

    const optionContentRendererFactory = useCallback(
      (forValue?: boolean) =>
        ({ name, isEnd, label, duration, number, typeId }: EventsToUpdateSelectOption, active?: boolean) => {
          const requestTypeDetails = typeId && requestTypesDictionary ? requestTypesDictionary[typeId] : undefined;

          switch (requestType) {
            case RequestFormType.TimeTracking:
              return (
                <ContentWrapper title={`${name ? t({ id: name }) : ''}, ${label}`}>
                  <WorkStatusBadge workStatus={{ id: typeId || '', name: name || '' }} isEnd={isEnd} active={active} />
                  {label}
                </ContentWrapper>
              );
            case RequestFormType.BusinessTrip:
              return (
                <ContentWrapper title={`${name}, ${label} ${durationToString(duration)}`}>
                  <Rectangle
                    sx={{
                      bg: active
                        ? 'select.rectangle.business.background.active'
                        : 'select.rectangle.business.background.default',
                      color: active
                        ? 'select.rectangle.business.color.active'
                        : 'select.rectangle.business.color.default',
                      fontWeight: 'bold',
                    }}
                  >
                    {name}
                  </Rectangle>
                  {label}
                  {durationRenderer(duration, active)}
                </ContentWrapper>
              );
            case RequestFormType.TimeOff:
            case RequestFormType.Custom:
              if (forValue) {
                return (
                  <ContentWrapper
                    title={`${
                      requestTypeDetails ? t({ id: requestTypeDetails?.name }) : ''
                    } ${label} ${durationToString(duration)}`}
                  >
                    {numberRectRenderer(number)}
                    {abbreviationRectRenderer(
                      requestTypeDetails ? t({ id: requestTypeDetails?.abbreviation }) : '',
                      active,
                    )}
                    <TextEllipsis>
                      {requestTypeDetails ? t({ id: requestTypeDetails?.name }) : ''}
                      &nbsp; &nbsp;
                      {label}
                      &nbsp; &nbsp;
                      {durationRenderer(duration, active)}
                    </TextEllipsis>
                  </ContentWrapper>
                );
              }
              return (
                <Flex
                  sx={{ gap: '5px', flexDirection: 'column' }}
                  title={`${requestTypeDetails ? t({ id: requestTypeDetails?.name }) : ''} ${label} ${durationToString(
                    duration,
                  )}`}
                >
                  <ContentWrapper>
                    {numberRectRenderer(number)}
                    {label}
                    {durationRenderer(duration, active)}
                  </ContentWrapper>
                  <ContentWrapper>
                    {abbreviationRectRenderer(
                      requestTypeDetails ? t({ id: requestTypeDetails?.abbreviation }) : '',
                      active,
                    )}
                    <TextEllipsis>{requestTypeDetails ? t({ id: requestTypeDetails?.name }) : ''}</TextEllipsis>
                  </ContentWrapper>
                </Flex>
              );
            case RequestFormType.Schedule:
              return (
                <ContentWrapper>
                  {name} ___ {label}
                </ContentWrapper>
              );
            default:
              return <></>;
          }
        },
      [requestTypesDictionary, requestType, durationToString, durationRenderer],
    );

    const customValueDisplayRenderer = useMemo(
      () => optionContentRendererFactory(true),
      [optionContentRendererFactory],
    );

    const optionPropsGenerator = useCallback(
      (option: EventsToUpdateSelectOption, active?: boolean): Partial<OptionProps> => ({
        contentRenderer: () => optionContentRendererFactory()(option, active),
      }),
      [optionContentRendererFactory],
    );

    const placeholder = (() => {
      switch (requestType) {
        case RequestFormType.TimeTracking:
          return t({
            id: 'events_to_update_select.time_tracking.placeholder',
            message: 'Select work event to edit...',
          });
        case RequestFormType.BusinessTrip:
          return t({
            id: 'events_to_update_select.business_trip.placeholder',
            message: 'Select business trip to edit...',
          });
        case RequestFormType.TimeOff:
        case RequestFormType.Custom:
          return t({ id: 'events_to_update_select.request.placeholder', message: 'Select request to edit...' });
        case RequestFormType.Schedule:
          return 'PICK SCHEDULE TO EDIT';
        default:
          return undefined;
      }
    })();

    return (
      <Select
        ref={mergeRefs([ref, hiddenRef])}
        size="sm"
        placeholder={placeholder}
        loadingOptions={loading || loadingGetSingleRequestHistoryItem}
        options={eventsToUpdateOptions}
        customValueDisplayRenderer={customValueDisplayRenderer}
        optionPropsGenerator={optionPropsGenerator}
        virtualizeOptions
        fixedSizeListProps={{
          onItemsRendered,
          ...(itemSize && { itemSize }),
        }}
        {...props}
      />
    );
  },
);
