import React, { useContext, useEffect, useRef } from 'react';
import { ClientContext } from 'react-fetching-library';

import { fetchNotifications } from 'api/actions/notification/notificationActions';
import { NotificationHub } from 'base/Notification/output/NotificationHub';
import { addNotification, removeNotification } from 'base/Notification/output/actions';
import { NOTIFICATION_FETCH_INTERVAL } from 'constants/common';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

export const NotificationProvider = (): React.ReactElement => {
  const { query } = useContext(ClientContext);
  const intervalSetRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const getNotifications = async () => {
      if (document.hidden) return;
      const { payload, error } = await query(fetchNotifications());
      if (!error && payload) {
        const { notifications, notificationsToRemove } = payload;
        notifications.forEach((n) => addNotification(n));
        notificationsToRemove.forEach((id) => removeNotification(id, { removeFromCenter: true }));
      }
    };

    if (!intervalSetRef.current) {
      void getNotifications();
      intervalSetRef.current = setInterval(floatingPromiseReturn(getNotifications), NOTIFICATION_FETCH_INTERVAL);
    }

    return () => {
      if (intervalSetRef.current) {
        clearInterval(intervalSetRef.current);
      }
    };
  }, [query]);
  return <NotificationHub />;
};
