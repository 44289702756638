import { FC } from 'react';
import { Flex, FlexProps, ThemeUIStyleObject } from 'theme-ui';

import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { TextEllipsis } from 'components/utils/TextEllipsis';

type Props = {
  children: string;
  type?: RequestFormType;
  actionType?: RequestActionType;
  bold?: boolean;
  withBorder?: boolean;
  variant?: 'default' | 'businessTrip' | 'delete';
} & FlexProps;

const REQUEST_TYPE_ICON: Record<RequestFormType, Icons> = {
  [RequestFormType.BusinessTrip]: 'businessTrip',
  [RequestFormType.TimeTracking]: 'timeClock',
  [RequestFormType.Custom]: 'customRequest',
  [RequestFormType.TimeOff]: 'timeOff',
  [RequestFormType.Schedule]: 'calendar',
};
const STATE_TYPE_ICON: { [key in RequestActionType]?: Icons } = {
  [RequestActionType.Create]: 'plus',
  [RequestActionType.Edit]: 'requestEdit',
  [RequestActionType.Remove]: 'requestDelete',
};
const VARIANT_SX: { [key in Icons | 'default']?: ThemeUIStyleObject } = {
  businessTrip: { bg: 'requests.badge.businessTrip.bg', color: 'requests.badge.businessTrip.text', fontWeight: 'bold' },
  delete: { bg: 'requests.badge.delete' },
  default: { bg: 'requests.badge.default' },
};

const ICONS_SX: ThemeUIStyleObject = {
  borderRight: '1px solid',
  borderColor: 'alphas.darker5',
};

export const RequestBadge: FC<Props> = ({
  children,
  type,
  actionType,
  withBorder,
  bold,
  variant = 'default',
  sx,
  ...props
}) => {
  const requestTypeIcon = type !== undefined && REQUEST_TYPE_ICON[type];
  const requestStateIcon = actionType !== undefined && STATE_TYPE_ICON[actionType];
  const variantSx = VARIANT_SX[variant];
  return (
    <Flex
      {...props}
      sx={{
        ...(withBorder && {
          border: '1px solid',
          borderColor: 'alphas.darker5',
        }),
        borderRadius: 'xs',
        px: 1,
        alignItems: 'center',
        ...(variantSx && variantSx),
        ...(sx && sx),
      }}
    >
      {!!requestTypeIcon && <Icon type={requestTypeIcon} size={18} {...(withBorder && { iconSx: ICONS_SX })} />}
      {!!requestStateIcon && <Icon type={requestStateIcon} size={18} {...(withBorder && { iconSx: ICONS_SX })} />}
      <TextEllipsis title={children} sx={{ pl: '2px', ...(bold && { fontWeight: 'bold' }) }}>
        {children}
      </TextEllipsis>
    </Flex>
  );
};
