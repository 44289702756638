import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import {
  RequestType,
  TimeEventType,
  TimeOffType,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Avatar } from 'components/Avatar/Avatar';
import { Icon } from 'components/Icon/Icon';
import { WorkStatusBadge } from 'components/recipes/WorkStatusBadge';
import { numberRectRenderer } from 'components/ui/Select/variants/elements';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { parsedEmployeesSelector } from 'state/employees';
import { RequestsListType } from 'state/requests';
import { isOfType } from 'utils/isOfType';
import { RequestBadge } from '../components/RequestBadge';

export const useRequestTypeListPresenter = () => {
  useLingui();
  const parsedEmployees = useRecoilValue(parsedEmployeesSelector);

  const typeBadge = useCallback((type: RequestFormType) => {
    switch (type) {
      case RequestFormType.BusinessTrip:
        return <RequestBadge type={type}>{t({ id: 'request.type.business_trip' })}</RequestBadge>;
      case RequestFormType.Custom:
        return <RequestBadge type={type}>{t({ id: 'request.type.custom' })}</RequestBadge>;
      case RequestFormType.TimeOff:
        return <RequestBadge type={type}>{t({ id: 'request.type.time_off' })}</RequestBadge>;
      case RequestFormType.TimeTracking:
        return <RequestBadge type={type}>{t({ id: 'request.type.time_tracking' })}</RequestBadge>;
      case RequestFormType.Schedule:
      default:
        return <RequestBadge type={type}>{t({ id: 'add_request.shift_swap' })}</RequestBadge>;
    }
  }, []);

  const stateBadge = useCallback(
    (requestStateBadge: {
      viewType: RequestsListType;
      actionType: RequestActionType;
      variant?: 'lighter' | 'darker';
    }) => {
      const { viewType, actionType, variant } = requestStateBadge;

      let badgeLabel: string | undefined;

      if (viewType === 'ungrouped' && actionType === RequestActionType.Create) {
        badgeLabel = t({ id: 'request.state.new', message: 'New' });
      }

      if (actionType === RequestActionType.Edit) {
        badgeLabel = t({ id: 'request.state.edit', message: 'Edit' });
      }

      if (actionType === RequestActionType.Remove) {
        badgeLabel = t({ id: 'request.state.delete', message: 'Delete' });
      }

      return badgeLabel ? (
        <RequestBadge
          actionType={actionType}
          {...(actionType === RequestActionType.Remove && { variant: 'delete' })}
          sx={{ flexShrink: 0, bg: variant === 'lighter' ? 'requests.badge.lighter' : 'requests.badge.default' }}
        >
          {badgeLabel}
        </RequestBadge>
      ) : null;
    },
    [],
  );

  const abbrBadge = useCallback((requestType?: RequestType | TimeOffType | TimeEventType | null) => {
    if (requestType && isOfType<RequestType>(requestType, 'abbreviation')) {
      return (
        <RequestBadge bold sx={{ flexShrink: 0 }}>
          {t({ id: requestType.abbreviation })}
        </RequestBadge>
      );
    }

    return null;
  }, []);

  const requestName = useCallback(
    ({
      type,
      businessTripName,
      requestType,
      eventIsEnd,
      noSpace,
      swapPersonId,
      employeeId,
    }: {
      type: RequestFormType;
      businessTripName?: string;
      requestType?: RequestType | TimeOffType | TimeEventType | null;
      eventIsEnd?: boolean;
      noSpace?: boolean;
      swapPersonId?: string;
      employeeId?: string;
    }) => {
      const parsedEmployee = parsedEmployees?.get(employeeId || '');
      const parsedSwapEmployee = parsedEmployees?.get(swapPersonId || '');

      switch (type) {
        case RequestFormType.BusinessTrip:
          return (
            <RequestBadge variant="businessTrip" sx={{ ...(!noSpace && { ml: 2 }) }}>
              {businessTripName || ''}
            </RequestBadge>
          );
        case RequestFormType.Custom:
        case RequestFormType.TimeOff:
          return requestType ? (
            <TextEllipsis
              title={t({ id: requestType?.name })}
              sx={{ ...(!noSpace && { ml: '0.625rem', width: 'auto' }) }}
            >
              {t({ id: requestType?.name })}
            </TextEllipsis>
          ) : null;
        case RequestFormType.TimeTracking:
          return requestType ? (
            <WorkStatusBadge
              workStatus={{ id: requestType.id, name: requestType.name }}
              isEnd={eventIsEnd}
              size="sm"
              sx={{ ...(!noSpace && { ml: 1 }) }}
            />
          ) : null;
        case RequestFormType.Schedule:
          return (
            <>
              <Flex sx={{ ...(!noSpace && { ml: 2 }), gap: 1 }}>
                {parsedEmployee ? (
                  <Flex sx={{ gap: 2 }}>
                    <Avatar size={21} image={parsedEmployee.avatarUrl} name={parsedEmployee.name} />
                    <TextEllipsis
                      title={`${parsedEmployee.name.firstName} ${parsedEmployee.name.surname}`}
                    >{`${parsedEmployee.name.firstName} ${parsedEmployee.name.surname}`}</TextEllipsis>
                  </Flex>
                ) : (
                  t({ id: 'request.hidden_employee', message: 'Hidden employee' })
                )}
                <Icon type="arrowRight" size={16} iconSx={{ color: 'requests.arrowRight' }} />
                {parsedSwapEmployee ? (
                  <Flex sx={{ gap: 2 }}>
                    <Avatar size={21} image={parsedSwapEmployee.avatarUrl} name={parsedSwapEmployee.name} />
                    <TextEllipsis
                      title={`${parsedSwapEmployee.name.firstName} ${parsedSwapEmployee.name.surname}`}
                    >{`${parsedSwapEmployee.name.firstName} ${parsedSwapEmployee.name.surname}`}</TextEllipsis>
                  </Flex>
                ) : (
                  t({ id: 'request.hidden_employee' })
                )}
              </Flex>
            </>
          );
        default:
          return null;
      }
    },
    [parsedEmployees],
  );

  return {
    typeBadge,
    stateBadge,
    abbrBadge,
    requestName,
    numberRectRenderer,
  };
};
