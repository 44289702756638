import React from 'react';
import { BoxOwnProps, BoxProps, Flex, Text } from 'theme-ui';
import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';

import { Divider } from 'components/Divider/Divider';
import { dateTime } from 'utils/dateTime';

import { TotalOf } from './TotalOf';

type Props = BoxOwnProps &
  BoxProps & {
    dateUnix: number;
    duration: number;
    forDay: number | null;
  };

type DateBoxProps = {
  label: string;
  dateUnix: number;
};

const DateBox = ({ label, dateUnix }: DateBoxProps): React.ReactElement => (
  <Flex sx={{ flexDirection: 'column', gap: 2 }}>
    <Text
      sx={{
        fontWeight: 'bold',
        fontSize: 3,
      }}
    >
      {label}
    </Text>
    <Text sx={{ fontSize: 2 }}>{dateTime(dateUnix, { utc: true }).format('dd, ll')}</Text>
  </Flex>
);

export const DateDisplay = ({ dateUnix, duration, forDay, ...props }: Props): React.ReactElement => {
  useLingui();

  return (
    <Flex {...props} sx={{ gap: 4, ...(props.sx && props.sx) }}>
      <DateBox label={t({ id: 'request.details.time_frame.on', message: 'On' })} dateUnix={dateUnix} />
      <Divider sx={{ height: 'auto' }} axis="vertical" />
      {forDay && (
        <>
          <DateBox label={t({ id: 'request.details.time_frame.for', message: 'For' })} dateUnix={forDay} />
          <Divider sx={{ height: 'auto' }} axis="vertical" />
        </>
      )}
      <TotalOf duration={duration} />
    </Flex>
  );
};
