import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Modal } from 'components/Modal/output/Modal';
import { PATH_REL } from 'constants/routes';
import { DELETE_AI_FACES_FORM_ID, DELETE_ORGANIZATION_FORM_ID, DELETE_PHOTOS_FORM_ID } from 'constants/settings';
import { languageSelector } from 'state/recoilState';

import { ConfirmDeleteAdvancedModal } from './components/ConfirmDeleteAdvancedModal';

export const AdvancedModalRoutes = (): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);

  return (
    <Routes>
      <Route
        path={PATH_REL.DELETE_ORGANIZATION_MODAL[language]}
        element={
          <Modal size="xs">
            <ConfirmDeleteAdvancedModal
              formId={DELETE_ORGANIZATION_FORM_ID}
              message={t({ id: 'settings.delete_organization', message: "You're about to delete your organization." })}
            />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.DELETE_AI_FACE_MODAL[language]}
        element={
          <Modal size="xs">
            <ConfirmDeleteAdvancedModal
              formId={DELETE_AI_FACES_FORM_ID}
              message={t({ id: 'settings.delete_ai_faces', message: "You're about to delete all ai faces." })}
            />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.DELETE_PHOTOS_MODAL[language]}
        element={
          <Modal size="xs">
            <ConfirmDeleteAdvancedModal
              formId={DELETE_PHOTOS_FORM_ID}
              message={t({ id: 'settings.delete_photos', message: "You're about to delete all photos." })}
            />
          </Modal>
        }
      />
    </Routes>
  );
};
