import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { PersonName } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Avatar } from 'components/Avatar/Avatar';
import { Tag } from 'components/Tag/Tag';
import { TagStack } from 'components/Tag/TagStack';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { employeeByIdSelector } from 'state/employees';

type ConditionalProps =
  | {
      employeeId: never;
      employee: PersonName;
      avatarUrl: string;
      showRoles?: never;
    }
  | {
      employeeId: string;
      employee: never;
      avatarUrl: never;
      showRoles?: boolean;
    };

type Props = ConditionalProps & {
  sx?: ThemeUIStyleObject;
  appendWith?: React.ReactNode;
};

const defaultProps: Partial<Props> = {
  sx: undefined,
  appendWith: undefined,
};

export const FancyPersonBox = ({
  employeeId,
  employee,
  avatarUrl,
  showRoles,
  sx,
  appendWith,
}: Props): React.ReactElement => {
  const person = useRecoilValue(employeeByIdSelector(employeeId));

  const renderFullName = useNameDisplayOrder();

  return (
    <Flex variant="fancyPersonBox.container" sx={{ ...(sx && sx) }}>
      <Avatar size={21} image={person?.avatarUrl || avatarUrl} name={person?.name || employee} circle />
      <TextEllipsis
        sx={{ fontSize: 2, width: 'auto', ml: 2 }}
        title={renderFullName(person?.name.firstName || employee.firstName, person?.name.surname || employee.surname)}
      >
        {renderFullName(person?.name.firstName || employee.firstName, person?.name.surname || employee.surname)}
      </TextEllipsis>

      {showRoles && person && (
        <Flex mx={2}>
          <Tag
            sx={{
              height: '18px',
              cursor: 'default',
            }}
            key={person.role.name}
            color={UserSelectableColor[person.role.color]}
            variant="outline"
          >
            {i18n._(
              t({
                id: person.role.name,
              }),
            )}
          </Tag>
          <TagStack tags={person.tags} />
        </Flex>
      )}
      {appendWith && appendWith}
    </Flex>
  );
};

FancyPersonBox.defaultProps = defaultProps;
