import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { RequestActionType, RequestFormType, RequestState } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { Button } from 'components/ui/Buttons';
import { organizationSessionAtom } from 'state/organizationSession';
import { useRequestDetails } from '../hooks/useRequestDetails';

type Props = {
  handleApprove: () => Promise<void>;
  handleReject: () => void;
  handleModificationRequestClick: (action: RequestActionType) => void;
  approveLoading: boolean;
};

export const RequestDetailsFooter = ({
  handleApprove,
  handleReject,
  handleModificationRequestClick,
  approveLoading,
}: Props): React.ReactElement => {
  useLingui();
  const {
    parsedRequestDetails,
    isModificationAllowed,
    isRejectionAllowed,
    isApprovalAllowed,
    actionsPermissions,
    locationState,
  } = useRequestDetails();

  const organizationSession = useRecoilValue(organizationSessionAtom);

  const handleApproveClick = useCallback(() => {
    void handleApprove();
  }, [handleApprove]);

  const renderFooterButtons = useMemo(() => {
    if (
      parsedRequestDetails?.state === RequestState.Accepted &&
      !parsedRequestDetails?.isDeleted &&
      actionsPermissions?.editAllowed
    ) {
      if (
        !organizationSession?.allowRequestForBusinessTrips &&
        locationState.requestDetailsData?.type === RequestFormType.BusinessTrip
      ) {
        return null;
      }

      return (
        <Modal.Footer>
          <Button
            variant="minimal"
            shape="rounded"
            onClick={() => {
              handleModificationRequestClick(RequestActionType.Remove);
            }}
            sx={{ minWidth: 0 }}
          >
            <Trans id="requests.request_remove">Request remove</Trans>
          </Button>

          <Button
            variant="primary"
            shape="rounded"
            onClick={() => {
              handleModificationRequestClick(RequestActionType.Edit);
            }}
          >
            {t({ id: 'requests.request_change' })}
          </Button>
        </Modal.Footer>
      );
    }

    return null;
  }, [
    actionsPermissions?.editAllowed,
    handleModificationRequestClick,
    locationState.requestDetailsData?.type,
    organizationSession?.allowRequestForBusinessTrips,
    parsedRequestDetails?.isDeleted,
    parsedRequestDetails?.state,
  ]);

  return (
    <>
      {isModificationAllowed && (
        <Modal.Footer>
          <Button
            disabled={approveLoading || !isRejectionAllowed}
            variant="danger"
            shape="rounded"
            prependWith={<Icon size={18} type="deny" />}
            onClick={handleReject}
          >
            <Trans id="requests.button.reject">Reject</Trans>
          </Button>
          <Button
            isLoading={approveLoading}
            variant="success"
            shape="rounded"
            prependWith={<Icon size={18} type="approve" />}
            onClick={handleApproveClick}
            disabled={!isApprovalAllowed}
          >
            {!parsedRequestDetails?.latestInGroup || parsedRequestDetails?.actionType !== RequestActionType.Remove ? (
              <Trans id="requests.button.approve">Approve</Trans>
            ) : (
              <Trans id="requests.button.approve_deletion_group">Approve group deletion</Trans>
            )}
          </Button>
        </Modal.Footer>
      )}
      {renderFooterButtons}
    </>
  );
};
