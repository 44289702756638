import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { omit } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { RequestTypeSelect } from 'components/ui/Select/variants/RequestTypeSelect';
import { activeTimeOffTypeOptionsSelector } from 'state/selectOptions';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';
import { MaxRequestLimit } from '../../MaxRequestLimit';
import { useTimeOffFormState } from '../hooks/useTimeOffFormState';

export const SelectType = (): React.ReactElement | null => {
  useLingui();
  const {
    modifyRequest: { modifyRequestData },
    requestToEdit: { selectedRequestToEdit },
  } = useAddRequest();
  const { isOvertimeFreeDayType, isDurationType } = useTimeOffFormState();
  const timeOffTypesOptions = useRecoilValue(activeTimeOffTypeOptionsSelector);

  const typeIdRef = useRef<HTMLInputElement | null>(null);

  const { register, watch } = useFormContext<AddRequestExtendedFormContext>();

  const [actionTypeWatch, typeIdWatch] = watch(['actionType', 'details.typeId']);

  const typeSelectVisible = useMemo(() => {
    if (actionTypeWatch === RequestActionType.Create) return true;

    if (actionTypeWatch === RequestActionType.Edit && (selectedRequestToEdit || modifyRequestData)) return true;

    return false;
  }, [actionTypeWatch, selectedRequestToEdit, modifyRequestData]);

  const typeIdRegister = useMemo(() => register('details.typeId'), [register]);

  if (!typeSelectVisible) return null;

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text sx={{ fontWeight: 'bold', mb: 2 }} variant="heading4">
        {t({ id: 'requests.time_off_type', message: 'Time off request type' })}
      </Text>
      <RequestTypeSelect
        ref={mergeRefs([typeIdRef, typeIdRegister.ref])}
        {...omit(typeIdRegister, 'ref')}
        id="typeId"
        options={timeOffTypesOptions}
        size="sm"
        searchable
        onUpdateFieldView={(value) => {
          if (!value.length) {
            // FIXME: Problem inside Select. It occurs during reset when it's mounted.
            // setNativeValue solves problem of selecting value -> resetting -> selecting same value again.
            // It allows to emit onChange event for selecting same value after reset.
            setNativeValue(typeIdRef, value);
          }
        }}
      />
      <MaxRequestLimit
        hide={actionTypeWatch === RequestActionType.Remove || !typeIdWatch}
        displayZeroLimitAsHours={isDurationType || isOvertimeFreeDayType}
      />
    </Flex>
  );
};
