import React from 'react';
import { Flex, Text, ThemeUICSSObject } from 'theme-ui';

import { Badge } from 'components/Badge/Badge';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';

type LabelWithBadgeProps = {
  label: string;
  badgeText: string;
  tooltipText?: string;
  badgeSx?: ThemeUICSSObject;
};

const TextWithTooltip = withTooltip(Text);

export const LabelWithBadge = ({ label, badgeText, tooltipText, badgeSx }: LabelWithBadgeProps): React.ReactElement => (
  <Flex sx={{ alignItems: 'center' }}>
    {label}
    <ConditionalWrapper
      condition={!!tooltipText}
      wrapper={({ children: wrapperChildren }) => (
        <TextWithTooltip tooltipProps={{ content: tooltipText }}>{wrapperChildren}</TextWithTooltip>
      )}
    >
      <Badge size="sm" sx={{ ml: 2, color: 'settings.badge.text', bg: 'settings.badge.bg', ...badgeSx }}>
        {badgeText}
      </Badge>
    </ConditionalWrapper>
  </Flex>
);
