import React from 'react';
import { Text, TextProps } from 'theme-ui';

type Props = TextProps;

export const TextEllipsis = React.forwardRef<HTMLDivElement, Props>(({ sx, children, ...props }: Props, ref) => (
  <Text
    ref={ref}
    as="span"
    {...props}
    sx={{
      width: 'calc(100%)',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      ...(sx && sx),
    }}
  >
    {children}
  </Text>
));
