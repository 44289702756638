import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useLocation } from 'react-router-dom';

import { zusConncectAction, zusCredentialsAction } from 'api/actions/zus/zusActions';
import { ZusConnectActionProps } from 'api/actions/zus/zusActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { createEvent } from 'utils/createEvent';
import { useIntegrations } from '../../../hooks/useIntegrations';

import { ConnectZusForm } from './components/ConnectZusForm';

export const ZusModal = (): React.ReactElement => {
  useLingui();
  const { handleClose } = useModal();
  const { mutate: mutateConnect } = useMutation(zusConncectAction);
  const { mutate: mutateUpdate } = useMutation(zusCredentialsAction);
  const { state } = useLocation();
  const { fetchIntegrations } = useIntegrations();

  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: ZusConnectActionProps): Promise<void> => {
      const { reconnect } = state || {};
      const { error: submitError } = reconnect ? await mutateUpdate(data) : await mutateConnect(data);

      if (!submitError) {
        if (handleClose) {
          await fetchIntegrations();
          handleClose();
        }
      }
      setLoading(false);
    },
    [state, mutateUpdate, mutateConnect, handleClose, fetchIntegrations],
  );

  const handleConnect = () => {
    setLoading(true);
    submitForm();
  };

  useEffect(() => {
    if (!state && handleClose) handleClose();
  }, [handleClose, state]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.integrations.zus_connect">Connect Zus e-ZLA</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ConnectZusForm ref={formRef} onSubmit={onSubmit} setLoading={setLoading} />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleConnect,
            variant: 'primary',
            children: t({ id: 'connect' }),
          },
        ]}
      />
    </>
  );
};
