import { CustomEvents, pubsub } from 'utils/customEvents';
import { factoryUseEventListener } from 'utils/pubsubFactory';

/**
 * Adds a custom event listener.
 *
 * @param {T | T[]} eventName Name of the event to subscribe to.
 * @param {Callback<D> | Options<D>} callback Callback function or subscribsion details object.
 */
export const useCustomEventListener = factoryUseEventListener<CustomEvents>(pubsub);
