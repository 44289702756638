import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';

export const SaveStateObserver = (): null => {
  const {
    requestToEdit: { selectedRequestToEdit },
    modifyRequest: { modifyRequestData },
    setAddRequestState,
  } = useAddRequest();

  const {
    formState: { errors },
    watch,
  } = useFormContext<AddRequestExtendedFormContext>();

  const [action, calendarDates] = watch(['actionType', 'dateTimeDetails.dates']);

  useEffect(() => {
    const datesFilledAndFormHasNoErrors =
      calendarDates && !errors.details?.name && !errors.details?.place && !errors?.note;
    const hasSelectedRequestToEditOrModifyData = selectedRequestToEdit || modifyRequestData;

    const isSaveDisabled = (() => {
      if (action === RequestActionType.Create) {
        return !datesFilledAndFormHasNoErrors;
      }
      if (action === RequestActionType.Remove) {
        return !(hasSelectedRequestToEditOrModifyData && !errors?.note);
      }
      if (action === RequestActionType.Edit) {
        return !(hasSelectedRequestToEditOrModifyData && datesFilledAndFormHasNoErrors);
      }
      return true;
    })();

    setAddRequestState((prevState) => ({ ...prevState, isSaveDisabled }));
  }, [
    action,
    calendarDates,
    errors.details?.name,
    errors.details?.place,
    errors?.note,
    modifyRequestData,
    selectedRequestToEdit,
    setAddRequestState,
  ]);

  return null;
};
