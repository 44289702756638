import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { TO } from 'constants/routes';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { languageSelector } from 'state/recoilState';

type Props = { children: React.ReactNode };

export const RequireAuth = ({ children }: Props) => {
  const language = useRecoilValue(languageSelector);
  const { isAuthorized } = useAuthState();
  const { pathname, search } = useLocation();

  const from = pathname && pathname + search;

  if (isAuthorized) {
    return children;
  }

  return <Navigate to={TO.SIGN_IN[language]} state={{ from }} />;
};
