import {
  FaceDetector,
  MediaPipeFaceDetectorMediaPipeModelConfig,
  MediaPipeFaceDetectorTfjsModelConfig,
  SupportedModels,
} from '@tensorflow-models/face-detection';
import { MediaPipeFaceDetectorModelConfig } from '@tensorflow-models/face-detection/dist/mediapipe/types';
import { load as loadMediaPipeFaceDetectorMediaPipe } from '@tensorflow-models/face-detection/dist/mediapipe/detector';

import { load as loadMediaPipeFaceDetectorTfjs } from '../tfjs/detector';

/**
 * Create a face detector instance.
 *
 * @param model The name of the pipeline to load.
 * @param modelConfig The configuration for the pipeline to load.
 */
export async function createDetector(
  model: SupportedModels,
  modelConfig?: MediaPipeFaceDetectorMediaPipeModelConfig | MediaPipeFaceDetectorTfjsModelConfig,
): Promise<FaceDetector> {
  switch (model) {
    case SupportedModels.MediaPipeFaceDetector: {
      const config = modelConfig as MediaPipeFaceDetectorModelConfig;
      let runtime;
      if (config != null) {
        if (config.runtime === 'tfjs') {
          return loadMediaPipeFaceDetectorTfjs(config as MediaPipeFaceDetectorTfjsModelConfig);
        }
        if (config.runtime === 'mediapipe') {
          return loadMediaPipeFaceDetectorMediaPipe(config as MediaPipeFaceDetectorMediaPipeModelConfig);
        }
        runtime = config.runtime;
      }
      // eslint-disable-next-line no-useless-concat
      throw new Error(`Expect modelConfig.runtime to be either 'tfjs' ` + `or 'mediapipe', but got ${runtime}`);
    }
    default:
      throw new Error(`${model} is not a supported model name.`);
  }
}

export { SupportedModels } from '@tensorflow-models/face-detection';
