import { Trans } from '@lingui/macro';
import { Flex, Heading, Text } from 'theme-ui';

import { Button } from 'components/ui/Buttons';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { onboardingStepsType } from '../../types';

const WarningMessage = () => {
  const { stopOnboarding, startOnboarding } = useInitialOnboarding();

  return (
    <Flex sx={{ flexDirection: 'column', alignContent: 'center', gap: 'inherit', textAlign: 'center' }}>
      <Flex variant="onboarding.modalHeaderIcon">🙈</Flex>
      <Heading variant="heading3" as="h3">
        <Trans id="onboarding.warning.title">
          It seems you've navigated away from the page the current guide belongs to.
        </Trans>
      </Heading>
      <Text as="p" variant="p">
        <Trans id="onboarding.warning.message">You can restart the guide or close it.</Trans>
      </Text>

      <Flex sx={{ gap: 2, justifyContent: 'center', pt: 3 }}>
        <Button shape="rounded" variant="primary" onClick={startOnboarding}>
          <Trans id="onboarding.action.start_over">Start over</Trans>
        </Button>
        <Button shape="rounded" variant="lightGrey" onClick={stopOnboarding}>
          <Trans id="onboarding.action.close_guide">Close guide</Trans>
        </Button>
      </Flex>
    </Flex>
  );
};

export const onboardingWarningMessage: onboardingStepsType = [
  {
    content: <WarningMessage />,
    mode: 'modal',
  },
];
