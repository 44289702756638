import { Route } from 'react-router-dom';

import { ExportDataType } from 'api/actions/export/exportActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { ListNames } from 'components/StickyList/types';
import { ADD_REQUEST_MODAL_WIDTH } from 'constants/requests';
import { PATH_REL } from 'constants/routes';
import { SupportedLanguages } from 'constants/supportedLanguages';
import { DownloadPrintModal } from 'pages/Reports/output/DownloadPrintModal';
import { EditTeammateModalWithDefaultModalWrapper } from 'pages/Team/output/EditTeammate';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { RequestsUsageOverviewViewSettingsModal } from '../../../modals/RequestsUsageOverviewViewSettings';
import { AddRequestModal } from '../AddRequest/AddRequestModal';
import { DeleteRequestModal } from '../DeleteRequest';
import { RejectRequestModal } from '../RejectRequestModal';
import { RequestDetailsNestedModalRoute } from '../RequestDetailsNestedModalRoute';
import { RequestsViewSettingsModal } from '../RequestsViewSettingsModal';

export const RequestsModalRoutesRenderer = (language: keyof typeof SupportedLanguages, isRequests: boolean) => [
  <Route
    path={PATH_REL.DELETE_REQUESTS_MODAL[language]}
    key={PATH_REL.DELETE_REQUESTS_MODAL[language]}
    element={
      <Modal size="xs">
        <DeleteRequestModal />
      </Modal>
    }
  />,
  <Route
    path={PATH_REL.REJECT_REQUESTS_MODAL[language]}
    key={PATH_REL.REJECT_REQUESTS_MODAL[language]}
    element={
      <Modal size="xs">
        <RejectRequestModal />
      </Modal>
    }
  />,
  <Route
    path={PATH_REL.REQUEST_DETAILS__GROUP__ID[language]}
    key={PATH_REL.REQUEST_DETAILS__GROUP__ID[language]}
    element={
      <Modal size="sm" fullHeight path={PATH_REL.REQUEST_DETAILS__GROUP__ID[language]}>
        <RequestDetailsNestedModalRoute />
      </Modal>
    }
  />,
  <Route
    path={PATH_REL.ADD_REQUEST_MODAL[language]}
    key={PATH_REL.ADD_REQUEST_MODAL[language]}
    element={
      <Modal fullHeight showMinimize sx={{ width: ADD_REQUEST_MODAL_WIDTH }}>
        <AddRequestModal />
      </Modal>
    }
  />,
  ...(isRequests
    ? [
        <Route
          path={PATH_REL.VIEW_SETTINGS_MODAL[language]}
          key={PATH_REL.VIEW_SETTINGS_MODAL[language]}
          element={
            <Modal size="xs">
              <RequestsViewSettingsModal />
            </Modal>
          }
        />,
        <Route
          path={PATH_REL.REQUESTS_USAGE_OVERVIEW_VIEW_SETTINGS_MODAL[language]}
          key={PATH_REL.REQUESTS_USAGE_OVERVIEW_VIEW_SETTINGS_MODAL[language]}
          element={
            <Modal path={PATH_REL.REQUESTS_USAGE_OVERVIEW_VIEW_SETTINGS_MODAL[language]}>
              <RequestsUsageOverviewViewSettingsModal />
            </Modal>
          }
        />,
        <Route
          path={PATH_REL.DOWNLOAD_MODAL__EXTENSION[language]}
          key={PATH_REL.DOWNLOAD_MODAL__EXTENSION[language]}
          element={
            <Modal size="sm" path={PATH_REL.DOWNLOAD_MODAL__EXTENSION[language]}>
              <DownloadPrintModal
                dataType={ExportDataType.RequestsUsageOverview}
                selectedIdsRecoilState={selectedRowsIdsSelectorFamily(ListNames.REQUESTS_OVERVIEW)}
                view="requestsOverview"
                variant="download"
              />
            </Modal>
          }
        />,
        <Route
          path={`${PATH_REL.PRINT_MODAL[language].replace('/*', '')}/${ExportDataType.RequestsUsageOverview}`}
          key={`${PATH_REL.PRINT_MODAL[language].replace('/*', '')}/${ExportDataType.RequestsUsageOverview}`}
          element={
            <Modal
              size="sm"
              path={`${PATH_REL.PRINT_MODAL[language].replace('/*', '')}/${ExportDataType.RequestsUsageOverview}`}
            >
              <DownloadPrintModal
                dataType={ExportDataType.RequestsUsageOverview}
                selectedIdsRecoilState={selectedRowsIdsSelectorFamily(ListNames.REQUESTS_OVERVIEW)}
                view="requestsOverview"
                variant="print"
              />
            </Modal>
          }
        />,
        <Route
          path={PATH_REL.EDIT_TEAMMATE_MODAL__ID[language]}
          key={PATH_REL.EDIT_TEAMMATE_MODAL__ID[language]}
          element={<EditTeammateModalWithDefaultModalWrapper path={PATH_REL.EDIT_TEAMMATE_MODAL__ID[language]} />}
        />,
      ]
    : []),
  <Route
    path={PATH_REL.REQUEST_MODIFICATION_MODAL[language]}
    key={PATH_REL.REQUEST_MODIFICATION_MODAL[language]}
    element={
      <Modal fullHeight sx={{ width: ADD_REQUEST_MODAL_WIDTH }}>
        <AddRequestModal />
      </Modal>
    }
  />,
  <Route
    path={PATH_REL.REQUEST_REMOVE_MODAL[language]}
    key={PATH_REL.REQUEST_REMOVE_MODAL[language]}
    element={
      <Modal fullHeight sx={{ width: ADD_REQUEST_MODAL_WIDTH }}>
        <AddRequestModal />
      </Modal>
    }
  />,
];
