import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { setRequestsUsageOverviewViewSettings } from 'api/actions/requests/requestsActions';
import { useModal } from 'components/Modal/hooks/useModal';
import { Modal } from 'components/Modal/output/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { SettingsSwitches } from 'pages/Reports/output/SettingsSwitches';
import { customRequestTypesDictionarySelector, timeOffTypesDictionarySelector } from 'state/organizationSession';
import {
  hiddenColumnKeysRequestsOverviewUsage,
  usageOverviewColumnsHiddenByViewSettingsSelector,
} from 'state/requests';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

type SingleColumnType = Record<
  string,
  {
    id: string;
    name: string;
  }
>;

const singleColumns: SingleColumnType = {
  [`${hiddenColumnKeysRequestsOverviewUsage.timeEventsCount}`]: {
    id: `${hiddenColumnKeysRequestsOverviewUsage.timeEventsCount}`,
    name: 'request_overview.time_track_full',
  },
  [`${hiddenColumnKeysRequestsOverviewUsage.businessTravelsCount[0]}`]: {
    id: `${hiddenColumnKeysRequestsOverviewUsage.businessTravelsCount[0]}`,
    name: 'request_overview.business_trip_full',
  },
  [`${hiddenColumnKeysRequestsOverviewUsage.swapRequestCount}`]: {
    id: `${hiddenColumnKeysRequestsOverviewUsage.swapRequestCount}`,
    name: 'request_overview.swap_request_full',
  },
};

export const RequestsUsageOverviewViewSettingsModal = () => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();
  const { mutate } = useMutation(setRequestsUsageOverviewViewSettings);
  const hiddenColumns = useRecoilValue(usageOverviewColumnsHiddenByViewSettingsSelector);
  const isColumnHidden = useCallback((column: { id: string }) => !hiddenColumns.includes(column.id), [hiddenColumns]);
  const timeOffTypes = useRecoilValue(timeOffTypesDictionarySelector);
  const customRequestTypes = useRecoilValue(customRequestTypesDictionarySelector);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm({
    defaultValues: {
      ..._.mapValues(singleColumns, isColumnHidden),
      ..._.mapValues(timeOffTypes, isColumnHidden),
      ..._.mapValues(customRequestTypes, isColumnHidden),
    },
  });

  const handleSave = handleSubmit(async (data) => {
    const { error: submitError } = await mutate({
      hiddenColumns: _.keys(_.pickBy(data, (val, key) => !val && key)),
    });
    if (!submitError) {
      addSnackbar({
        message: t({ id: 'team.view_settings.edited', message: 'Successfully edited!' }),
        variant: 'success',
      });
      if (handleClose) handleClose();
    }
  });

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.view_settings">View settings</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ gap: 5 }}>
        <SettingsSwitches mappedValues={timeOffTypes} register={register} groupName="reports.group_names.time_offs" />

        <SettingsSwitches
          mappedValues={customRequestTypes}
          register={register}
          groupName="reports.group_names.custom_requests"
        />

        <SettingsSwitches
          mappedValues={singleColumns}
          register={register}
          groupName="reports.usage_overview.other_columns"
        />
      </Modal.Body>

      <BasicModalFooter
        buttons={[
          {
            isLoading: isSubmitting,
            disabled: !isDirty,
            onClick: floatingPromiseReturn(handleSave),
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
