import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { Divider } from 'components/Divider/Divider';
import { RolesSelect } from 'components/ui/Select/variants/RolesSelect';
import { Switch } from 'components/ui/Switch';
import { rolesApproveRequestsSelectOptionsSelector } from 'state/settings';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { useFormContextCustomEvent } from '../../../../../../../hooks/useFormContextCustomEvent';
import { OptionLabel } from '../../../../../../OptionLabel';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';

export const BusinessTrip = (): React.ReactElement => {
  useLingui();
  const rolesSelectOptions = useRecoilValue(rolesApproveRequestsSelectOptionsSelector);

  const {
    registerOnChange,
    registerOnBlur,
    watch,
    formState: { errors },
  } = useFormContextCustomEvent<{
    allowRequestForBusinessTrips: boolean;
    businessTripsApprovalSettings: {
      twoStepApprovalFlow: boolean;
      firstStepRoleId: string;
      secondStepRoleId: string;
    };
  }>();

  const twoStepApprovalWatch = watch('businessTripsApprovalSettings.twoStepApprovalFlow');
  const firstStepRoleIdWatch = watch('businessTripsApprovalSettings.firstStepRoleId');
  const secondStepRoleIdWatch = watch('businessTripsApprovalSettings.secondStepRoleId');

  return (
    <SettingsGroup variant="sm">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="requests_settings.heading.business_trip">Business trip requests</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <SettingsGroup.Body>
        <SettingsGroup.Body.Visible
          variant="md"
          pb={twoStepApprovalWatch ? 2 : '0.75rem'}
          withoutBorderBottom={twoStepApprovalWatch}
        >
          <Switch
            {...registerOnChange('allowRequestForBusinessTrips')}
            label={t({
              id: 'requests_settings.allow_request_for_business_trips.label',
              message: 'Allow request for business trips',
            })}
            size="sm"
            bold
            sx={{ py: SETTINGS_SPACE.switchWithLabel }}
          />
          <Divider />
          {/* <Switch
          {...registerOnChange('disableLocationRestrictionOnBusinessTrip')}
          label={t({
            id: 'requests_settings.disable_location_restriction_on_business_trip.label',
            message: 'Turn off location limits during business trips',
          })}
          size="sm"
          bold
          withDivider
        /> 
        Hidden until the backend is ready */}
          <Switch
            {...registerOnChange('businessTripsApprovalSettings.twoStepApprovalFlow')}
            label={t({
              id: 'requests_settings.two_step_approval_flow.label',
            })}
            additionalInfo={
              <Trans id="requests_settings.two_step_approval_flow.additionalInfo">
                <Text as="span" sx={{ textDecoration: 'underline' }}>
                  Changing this setting will affect all pending requests
                </Text>
                .
              </Trans>
            }
            size="sm"
            bold
          />
        </SettingsGroup.Body.Visible>

        {twoStepApprovalWatch && (
          <SettingsGroup.Body.Hidden variant="md" pt={2} pb={'0.75rem'} withBorderBottom>
            <OptionLabel
              label={t({
                id: 'requests_settings.first_step_role_id.label',
              })}
              apendWith={
                <>
                  {rolesSelectOptions && (
                    <RolesSelect
                      {...registerOnBlur('businessTripsApprovalSettings.firstStepRoleId', {
                        required: t({ id: 'global.forms.required.short' }),
                      })}
                      id="businessTripsApprovalSettings.firstStepRoleId"
                      placeholder={t({
                        id: 'requests_settings.first_step_role_id.label',
                      })}
                      options={_.filter(rolesSelectOptions, (item) => item.id !== secondStepRoleIdWatch)}
                      size="sm"
                      error={!!errors.businessTripsApprovalSettings?.firstStepRoleId}
                      errorMessage={errors.businessTripsApprovalSettings?.firstStepRoleId?.message}
                      sx={{ maxWidth: '188px' }}
                    />
                  )}
                </>
              }
              withDivider
            />
            <OptionLabel
              label={t({
                id: 'requests_settings.second_step_role_id.label',
              })}
              apendWith={
                <>
                  {rolesSelectOptions && (
                    <RolesSelect
                      {...registerOnBlur('businessTripsApprovalSettings.secondStepRoleId', {
                        required: t({ id: 'global.forms.required.short' }),
                      })}
                      id="businessTripsApprovalSettings.secondStepRoleId"
                      placeholder={t({
                        id: 'requests_settings.second_step_role_id.label',
                      })}
                      options={_.filter(rolesSelectOptions, (item) => item.id !== firstStepRoleIdWatch)}
                      error={!!errors.businessTripsApprovalSettings?.secondStepRoleId}
                      errorMessage={errors.businessTripsApprovalSettings?.secondStepRoleId?.message}
                      size="sm"
                      sx={{ maxWidth: '188px' }}
                    />
                  )}
                </>
              }
            />
          </SettingsGroup.Body.Hidden>
        )}
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
