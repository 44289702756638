import React from 'react';
import { Flex, Text, FlexProps } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';

type Props = {
  variant?: 'default' | 'lighter' | 'info' | 'warning' | 'danger' | 'success' | 'pending';
  size?: 'default' | 'lg' | 'sm';
  icon?: Icons;
  children: React.ReactNode;
} & FlexProps;

export type BadgeProps = Props;

const defaultProps: Partial<Props> = {
  variant: 'default',
  size: 'default',
  icon: undefined,
};

export const Badge = React.forwardRef<HTMLDivElement, Props>(
  ({ children, variant, icon, size, sx, ...props }: Props, ref): React.ReactElement => (
    <Flex
      ref={ref}
      as="span"
      variant={`badge.${variant}`}
      {...props}
      sx={{
        cursor: !props.onClick ? 'default' : 'pointer',
        ...(props.onClick && {
          '&:hover': {
            opacity: 0.7,
          },
        }),
        variant: `badge.size.${size}`,
        color: `badge.text.${variant}`,
        ...(sx && sx),
      }}
    >
      {icon && <Icon type={icon} lineHeight={1.2} />}

      {children && (
        <Text as="span" data-text>
          {children}
        </Text>
      )}
    </Flex>
  ),
);

Badge.defaultProps = defaultProps;
