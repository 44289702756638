import { t } from '@lingui/macro';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { DefaultRole, Role, Tag } from 'api/actions/organizationSession/organizationSessionActions.types';
import { TO_REL } from 'constants/routes';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { languageSelector } from 'state/recoilState';
import { PassedFilter } from '../types';

export const useTagOrRoleFilters = () => {
  const language = useRecoilValue(languageSelector);
  const { systemManagement } = useAppPermissions();
  const getTagOrRoleFilter = useCallback(
    (isRole?: boolean, includesCustomRoles?: boolean, withoutDropdown?: boolean) =>
      ({ name, color, isInformational, ...rest }: Tag | Role): PassedFilter => {
        const dropdownLinks = [
          ...(systemManagement.Permissions && isRole
            ? [
                ...(DefaultRole[+rest.id]
                  ? [
                      {
                        to: `${TO_REL.PREVIEW_ROLE_MODAL__ID[language]}/${rest.id}`,
                        relative: 'path' as const,
                        label: t({ id: 'aside_filters.preview', message: 'Preview' }),
                      },
                    ]
                  : [
                      {
                        to: `${TO_REL.EDIT_ROLE_MODAL__ID[language]}/${rest.id}`,
                        relative: 'path' as const,
                        label: t({ id: 'aside_filters.edit', message: 'Edit' }),
                      },
                      {
                        to: TO_REL.DELETE_ROLES_MODAL[language],
                        state: { ids: [rest.id] },
                        relative: 'path' as const,
                        label: t({ id: 'aside_filters.delete', message: 'Delete' }),
                      },
                    ]),
              ]
            : []),
          ...(systemManagement.Permissions && !isRole
            ? [
                {
                  to: `${TO_REL.EDIT_TAG_MODAL__ID[language]}/${rest.id}`,
                  relative: 'path' as const,
                  label: t({ id: 'aside_filters.edit', message: 'Edit' }),
                },
                {
                  to: TO_REL.DELETE_TAGS_MODAL[language],
                  state: { ids: [rest.id] },
                  relative: 'path' as const,
                  label: t({ id: 'aside_filters.delete', message: 'Delete' }),
                },
              ]
            : []),
        ];

        const typeSpecificProps: Pick<PassedFilter, 'prependWith' | 'isOutlined'> = (() => {
          if (isRole) {
            return {
              prependWith: 'roundedSquareFilled',
              isOutlined: true,
              withDivider: includesCustomRoles && (+rest.id as DefaultRole) === DefaultRole.Admin,
            };
          }

          if (isInformational) {
            return { prependWith: 'square', isOutlined: true };
          }

          return { prependWith: 'dot', isOutlined: false };
        })();

        return {
          ...typeSpecificProps,
          label: t({
            id: name,
          }),
          isInformational,
          color: UserSelectableColor[color],
          ...rest,
          ...(!withoutDropdown &&
            dropdownLinks.length > 0 && {
              dropdownProps: {
                links: dropdownLinks,
              },
            }),
        };
      },
    [language, systemManagement.Permissions],
  );

  return getTagOrRoleFilter;
};
