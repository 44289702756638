import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { SilentFetch, useSilentFetch } from 'hooks/useSilentFetch/useSilentFetch';
import { locationsAtom } from 'state/locations';
import { fetchLocationsAction } from 'api/actions/location/locationActions';
import { selectAllAtomFamily, selectedRowsIdsSelectorFamily } from 'state/list';
import { FetchLocationsResponse, Location } from 'api/actions/location/locationActions.types';
import { ListNames } from 'components/StickyList/types';

export const useFetchLocations = (): SilentFetch<FetchLocationsResponse> => {
  const [selectedLocationsIds, setSelectedLocationsIds] = useRecoilState(
    selectedRowsIdsSelectorFamily(ListNames.LOCATIONS),
  );
  const resetSelectAll = useResetRecoilState(selectAllAtomFamily(ListNames.LOCATIONS));

  const selectedLocationsIdsRef = useCallbackRef(selectedLocationsIds);

  const filterOutNonExistentIds = useCallback(
    (locations?: Location[]) => {
      const selectedIds = selectedLocationsIdsRef.current;

      if (!locations || !selectedIds.length) return;

      const filteredSelectedIds = selectedIds.filter(
        (id) => !!locations.find(({ id: locationId }) => locationId === id),
      );
      if (filteredSelectedIds.length !== locations.length) {
        resetSelectAll();
      }
      if (filteredSelectedIds.length === selectedIds.length) return;

      setSelectedLocationsIds(filteredSelectedIds);
    },
    [selectedLocationsIdsRef, setSelectedLocationsIds, resetSelectAll],
  );

  const filterOutNonExistentIdsRef = useCallbackRef(filterOutNonExistentIds);

  const { silentFetch } = useSilentFetch(locationsAtom, fetchLocationsAction());

  const fetchLocations: SilentFetch<FetchLocationsResponse> = useCallback(
    async (options) => {
      const { error } = await silentFetch({
        ...options,
        onResponseCallback: filterOutNonExistentIdsRef.current,
      });
      return { error };
    },
    [filterOutNonExistentIdsRef, silentFetch],
  );

  return fetchLocations;
};
