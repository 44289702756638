import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { addSnackbar } from 'base/Snackbar/output/actions';
import { useDialogModal } from 'context/dialogModal/DialogModalProvider';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { parsedEmployeesSelector } from 'state/employees';
import {
  MinimizedModal,
  MinimizedModalDetails,
  ViewWithMinimizedModals,
  allMinimizedModalsDetailsAtom,
  minimizedModalsDetailsSelectorFamily,
} from 'state/modal';
import { languageSelector } from 'state/recoilState';
import { delay } from 'utils/delay';

import { useMinimizeLocationState } from './useMinimizeLocationState';

// Delay required for mobiles to ensure of setting values in TimePicker and DurationPicker
export const WAIT_FOR_FORM_LOGIC = 200;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMinimizedModals = <T extends Record<string, any>>(forView: ViewWithMinimizedModals) => {
  useLingui();
  const parsedEmployees = useRecoilValue(parsedEmployeesSelector);
  const minimizedModalsDetails = useRecoilValue(minimizedModalsDetailsSelectorFamily(forView));
  const [allMinimizedModalsDetails, setAllMinimizedModalsDetails] = useRecoilState(allMinimizedModalsDetailsAtom);
  const language = useRecoilValue(languageSelector);
  const minimizedModalDetails = useMinimizeLocationState();
  const navigate = useAppNavigate();

  const validateEmployeesGotRemoved = useCallback(
    (employees: string[]) => {
      if (!parsedEmployees) return null;

      const filteredEmployees = employees.filter((id: string) => parsedEmployees.has(id));

      if (employees.length !== filteredEmployees.length) {
        if (filteredEmployees.length === 0) {
          addSnackbar({
            message: t({
              id: 'snackbar.all_employees_got_removed.content',
              message: 'Draft deleted. All employees used in this draft got removed from the organization.',
            }),
            variant: 'warning',
            duration: 5000,
          });
          return false;
        }

        addSnackbar({
          message: t({
            id: 'snackbar.employees_got_removed.content',
            message: 'Some employees used in this draft got removed from the organization.',
          }),
          variant: 'warning',
          duration: 5000,
        });
        return true;
      }

      return true;
    },
    [parsedEmployees],
  );

  const openKeepEditingModalDialog = useDialogModal({
    title: t({ id: 'modal.draft_progress', message: 'Draft in progress' }),
    body: t({
      id: 'modal.keep_edit.content',
      message:
        "You already have a draft in progress. Creating a new instance will discard your draft and any changes you've made won't be saved.",
    }),
    actions: [
      {
        resolveWith: false,
        buttonProps: {
          children: t({ id: 'modal.discard_draft', message: 'Discard draft' }),
          variant: 'naked',
        },
      },
      {
        resolveWith: true,
        buttonProps: {
          children: t({ id: 'modal.keep_edit', message: 'Keep editing' }),
          variant: 'warning',
        },
      },
    ],
  });

  const openDiscardDraftModalDialog = useDialogModal({
    title: t({ id: 'modal.draft_progress' }),
    body: t({
      id: 'modal.discard_draft.content',
      message: 'We won’t be able to save your data if you move away from this page.',
    }),
    actions: [
      {
        resolveWith: false,
        buttonProps: {
          children: t({ id: 'modal.go_back', message: 'Go back' }),
          variant: 'naked',
        },
      },
      {
        resolveWith: true,
        buttonProps: {
          children: t({ id: 'modal.discard_draft' }),
          variant: 'warning',
        },
      },
    ],
  });

  const handleSetModalDetails = useCallback(
    (details: MinimizedModalDetails<T>) => {
      const { minimizedModal: key } = details;

      const newArr = [...allMinimizedModalsDetails];

      const storedState = newArr.find(({ minimizedModal }) => minimizedModal === key);

      if (storedState) {
        _.pull(newArr, storedState);
      }

      newArr.push(details);

      setAllMinimizedModalsDetails(newArr);
    },
    [allMinimizedModalsDetails, setAllMinimizedModalsDetails],
  );

  const onHandleCloseModalWithMinimizedDetails = useCallback(
    async (closeModal: () => void, preventOpeningModalDialog?: boolean) => {
      if (minimizedModalDetails && !preventOpeningModalDialog) {
        const confirm = await openDiscardDraftModalDialog();

        if (!confirm) return;
      }

      closeModal();
    },
    [minimizedModalDetails, openDiscardDraftModalDialog],
  );

  const removeMinimizedModalDetails = useCallback(
    (minimizedModal: MinimizedModal) => {
      const newArr = [...allMinimizedModalsDetails];

      const storedState = newArr.find(
        ({ minimizedModal: storedMinimizedModal }) => storedMinimizedModal === minimizedModal,
      );

      if (storedState) {
        _.pull(newArr, storedState);
      }

      setAllMinimizedModalsDetails(newArr);
    },
    [allMinimizedModalsDetails, setAllMinimizedModalsDetails],
  );

  const restoreMinimizedModal = useCallback(
    async (minimizedModal: MinimizedModal) => {
      const modalDetails = allMinimizedModalsDetails.find(
        ({ minimizedModal: storedMinimizedModal }) => storedMinimizedModal === minimizedModal,
      );

      if (!modalDetails) return;

      const additionalPath = modalDetails?.additionalPath || '';
      const state = _.omit(modalDetails.routeState, 'minimizedModalState');

      if (modalDetails.employeesToValidate) {
        const employees = modalDetails.employeesToValidate;

        const employeesExists = validateEmployeesGotRemoved(employees);

        if (!employeesExists) {
          removeMinimizedModalDetails(minimizedModal);
          return;
        }
      }

      navigate(`${modalDetails.route[language]}${additionalPath}`, {
        relative: 'path',
        state: {
          minimizedModalState: modalDetails,
          ...(state && state),
        },
      });

      const newArr = [...allMinimizedModalsDetails];
      _.pull(newArr, modalDetails);

      // Delay rerender of MinimizedModalsProvider.tsx to assure that modal opens first and dissapering bar won't be seen before modal opening.
      await delay(200);

      setAllMinimizedModalsDetails(newArr);
    },
    [
      allMinimizedModalsDetails,
      language,
      navigate,
      removeMinimizedModalDetails,
      setAllMinimizedModalsDetails,
      validateEmployeesGotRemoved,
    ],
  );

  const minimizeModalActionValidation = useCallback(
    async (minimizedModal: MinimizedModal) => {
      const modalsDetails = allMinimizedModalsDetails.find(
        ({ minimizedModal: storedMinimizedModal }) => storedMinimizedModal === minimizedModal,
      );

      if (!modalsDetails) {
        return true;
      }

      const keepEditing = await openKeepEditingModalDialog();

      if (_.isNull(keepEditing)) {
        return false;
      }

      if (!keepEditing) {
        removeMinimizedModalDetails(minimizedModal);
        return true;
      }

      void restoreMinimizedModal(minimizedModal);
      return false;
    },
    [allMinimizedModalsDetails, openKeepEditingModalDialog, removeMinimizedModalDetails, restoreMinimizedModal],
  );

  return {
    handleSetModalDetails,
    minimizedModalsDetails,
    onHandleCloseModalWithMinimizedDetails,
    removeMinimizedModalDetails,
    restoreMinimizedModal,
    minimizeModalActionValidation,
    validateEmployeesGotRemoved,
  };
};
