import { useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';

import { OPEN_CONTEXT_MENU } from 'hooks/useContextMenu/useContextMenu';
import { wish } from 'utils/wish';
import { useMount } from 'hooks/useMount/useMount';
import { useUnmount } from 'hooks/useUnmount/useUnmount';

import { getCloseOnScrollModifier } from './modifiers';

type Props = {
  contextMenuId?: string;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  referenceElement: HTMLElement | null;
  popperElement: HTMLElement | null;
  isVisible: boolean;
};

type CapturedCoordinates = {
  x: number;
  y: number;
};

type DisplayContextMenu = {
  isReferenceVirtual: boolean;
  virtualReference: {
    getBoundingClientRect(): {
      top: number;
      left: number;
      bottom: number;
      right: number;
      width: number;
      height: number;
      x: number;
      y: number;
      toJSON: () => null;
    };
  } | null;
  onReferenceElementClick: () => void;
  contextMenuOffset: [number, number] | undefined;
};

export const usePopperAsContextMenu = ({
  contextMenuId,
  setIsVisible,
  referenceElement,
  popperElement,
  isVisible,
}: Props): DisplayContextMenu | null => {
  const [x, setX] = useState<CapturedCoordinates['x']>(-1);
  const [y, setY] = useState<CapturedCoordinates['y']>(-1);
  const isReferenceVirtual = useMemo(() => x !== -1 && y !== -1, [x, y]);

  const virtualReference = useMemo(() => {
    if (x && y)
      return {
        getBoundingClientRect() {
          return {
            top: y,
            left: x,
            bottom: 0,
            right: 0,
            width: 0,
            height: 0,
            // props to avoid compilation error:
            x,
            y,
            toJSON: () => null,
          };
        },
      };
    return null;
  }, [x, y]);

  const contextMenuOffset = useMemo(
    () => (isReferenceVirtual ? [0, 0] : undefined) as [number, number] | undefined,
    [isReferenceVirtual],
  );

  const onReferenceElementClick = useCallback(() => {
    setX(-1);
    setY(-1);
  }, []);

  // OPENING
  const openContextMenuFulfiller = useCallback(
    (data: unknown) => {
      const { x: capturedX, y: capturedY } = data as CapturedCoordinates;
      if (capturedX && capturedY) {
        setX(capturedX);
        setY(capturedY);
        setIsVisible(true);
      }
      return Promise.resolve(null);
    },
    [setIsVisible],
  );

  useMount(() => {
    if (!contextMenuId) return;
    wish.fulfill(`${OPEN_CONTEXT_MENU}${contextMenuId}`, openContextMenuFulfiller);
  });

  useUnmount(() => {
    if (!contextMenuId) return;
    wish.deleteFulfiller(`${OPEN_CONTEXT_MENU}${contextMenuId}`);
  });

  // CLOSING ON SCROLL
  const close: () => void = useCallback(() => {
    setIsVisible(false);
    setX(-1);
    setY(-1);
  }, [setIsVisible]);

  const closeOnScroll = useMemo(() => getCloseOnScrollModifier(close, isVisible), [close, isVisible]);
  usePopper(isReferenceVirtual ? referenceElement : null, popperElement, { modifiers: [closeOnScroll] });

  if (!contextMenuId) return null;

  return {
    isReferenceVirtual,
    virtualReference,
    onReferenceElementClick,
    contextMenuOffset,
  };
};
