import { ResponseInterceptor } from 'react-fetching-library';

import { Action } from 'api/types';
import { emitCustomEvent } from 'utils/customEvents';

export const responseEmitCustomEventsInterceptor: () => ResponseInterceptor =
  // eslint-disable-next-line @typescript-eslint/require-await
  () => () => async (action: Action<unknown>, response) => {
    if (action?.config?.skipAllResponseInterceptors) return response;
    action?.config?.onResponseCustomEvents?.forEach(({ event, data, restrictToStatuses }) => {
      if (restrictToStatuses) {
        if (response.status && restrictToStatuses.includes(response.status)) emitCustomEvent(event, data);

        return;
      }

      emitCustomEvent(event, data);
    });

    return response;
  };
