/* eslint-disable no-plusplus */
import { Face } from '@tensorflow-models/face-detection';

import {
  FACE_BOUNDING_THRESHOLD_AXIS_X,
  FACE_BOUNDING_THRESHOLD_AXIS_Y,
  INITIAL_FACE_SCAN_POINTS_NUMBER,
} from './constants/constants';

export const getBoundingBoxForIsFaceInPlace = (
  video: HTMLVideoElement,
): {
  boundingBox: {
    topLeft: number[];
    bottomRight: number[];
  };
  boundingBoxInvert: {
    topLeft: number[];
    bottomRight: number[];
  };
  x: number;
  y: number;
  width: number;
  height: number;
} => {
  const { videoWidth } = video;
  const { videoHeight } = video;
  const isLandscape = video.videoHeight <= video.videoWidth;

  const column = videoWidth / 12;
  const row = videoHeight / 12;

  const width = isLandscape ? column * 4 : column * 8;
  const height = isLandscape ? row * 6 : row * 7;
  const x = isLandscape ? column * 4 : column * 2;
  const y = isLandscape ? row * 3 : row * 2.5;

  const topLeftX = x;
  const topLeftY = y;
  const bottomRightX = x + width;
  const bottomRightY = y + height;

  return {
    boundingBox: {
      topLeft: [topLeftX, topLeftY],
      bottomRight: [bottomRightX, bottomRightY],
    },
    boundingBoxInvert: {
      topLeft: [bottomRightX, topLeftY],
      bottomRight: [topLeftX, bottomRightY],
    },
    x,
    y,
    width,
    height,
  };
};

const generateMinMax = (number: number, threshold: number) => [number - threshold, number + threshold];

export const isFaceInPlace = (faces: Face[], source: HTMLVideoElement): boolean => {
  let isInPosition = false;

  if (faces.length > 0) {
    faces.forEach((face) => {
      const {
        box: { xMin, xMax, yMin, yMax },
      } = face;
      const boundingBoxForFace = getBoundingBoxForIsFaceInPlace(source);

      const xBoundingMin = boundingBoxForFace.boundingBox.topLeft[0];
      const yBoundingMin = boundingBoxForFace.boundingBox.topLeft[1];
      const xBoundingMax = boundingBoxForFace.boundingBox.bottomRight[0];
      const yBoundingMax = boundingBoxForFace.boundingBox.bottomRight[1];

      isInPosition = xMin > xBoundingMin && xMax < xBoundingMax && yMin > yBoundingMin && yMax < yBoundingMax;
    });
  }

  return isInPosition;
};

type getPointsToScanProps = {
  source: HTMLVideoElement;
  pointsQuantity?: number;
  pointsDistance?: number;
};

const getPointsToScan = ({
  source,
  pointsDistance = 0.18,
  pointsQuantity = INITIAL_FACE_SCAN_POINTS_NUMBER,
}: getPointsToScanProps) => {
  const boundingBoxForFace = getBoundingBoxForIsFaceInPlace(source);
  const { x, y, width, height } = boundingBoxForFace;

  const centerX = x + width / 2;
  const centerY = y + height / 2;

  const pointsAngle = ((360 / pointsQuantity) * Math.PI) / 180;
  const isLandscape = height <= width;
  const radius = !isLandscape ? height / 2 : width / 2;

  const pointsToScan = [...Array(pointsQuantity).keys()].map((value, index) => {
    const realX = centerX + radius * Math.sin(pointsAngle * index) * pointsDistance;
    const realY = centerY + radius * Math.cos(pointsAngle * index) * pointsDistance;

    const drawX = centerX + radius * Math.sin(pointsAngle * index);
    const drawY = centerY + radius * Math.cos(pointsAngle * index);

    return { key: index, point: [realX, realY], drawPoint: [drawX, drawY] };
  });

  return {
    pointsToScan,
    centerX,
    centerY,
  };
};

export const getAxisPointsToScan = (
  source: HTMLVideoElement,
): {
  points: {
    point: number[];
    drawPoint: number[];
    axis: number[];
    axisBoundary: {
      min: number[];
      max: number[];
    };
    errorBoundary: number;
  }[];
  centerX: number;
  centerY: number;
} => {
  const { centerX, centerY, pointsToScan } = getPointsToScan({ source });

  const points = pointsToScan.map(({ key, point, drawPoint }) => {
    const errorBoundary = 10;
    const axisX = centerY - point[1];
    const axisY = point[0] - centerX;

    return {
      key,
      point,
      drawPoint,
      axis: [axisX, axisY],
      axisBoundary: {
        min: [axisX - errorBoundary, axisY - errorBoundary],
        max: [axisX + errorBoundary, axisY + errorBoundary],
      },
      errorBoundary,
    };
  });

  return { points, centerX, centerY };
};

export const getAxisXY = (faces: Face[]): { degreeX: number; degreeY: number; tilt: number } => {
  let degreeX = 0;
  let degreeY = 0;
  let tilt = 0;

  if (faces.length > 0) {
    faces.forEach((face) => {
      const { keypoints } = face;

      const noseTip = keypoints.find(({ name }) => name === 'noseTip');
      const leftEarTragion = keypoints.find(({ name }) => name === 'leftEarTragion');
      const rightEarTragion = keypoints.find(({ name }) => name === 'rightEarTragion');

      const { x: topX, y: topY } = noseTip || { x: 0, y: 0 };
      const { x: rightX, y: rightY } = rightEarTragion || { x: 0, y: 0 };
      const { x: leftX, y: leftY } = leftEarTragion || { x: 0, y: 0 };

      const bottomX = (rightX + leftX) / 2;
      const bottomY = (rightY + leftY) / 2;

      tilt = Math.atan((leftY - rightY) / (leftX - rightX)) * (180 / Math.PI);
      degreeX = topX - bottomX;
      degreeY = bottomY - topY;
    });
  }

  return {
    degreeX,
    degreeY,
    tilt,
  };
};

export const getFaceKeyPoints = (face: Face | null) => {
  if (!face) {
    return undefined;
  }
  const { keypoints } = face;

  const noseTip = keypoints.find(({ name }) => name === 'noseTip');
  const leftEarTragion = keypoints.find(({ name }) => name === 'leftEarTragion');
  const rightEarTragion = keypoints.find(({ name }) => name === 'rightEarTragion');
  const mouthCenter = keypoints.find(({ name }) => name === 'mouthCenter');
  const rightEye = keypoints.find(({ name }) => name === 'rightEye');

  if (!noseTip || !leftEarTragion || !rightEarTragion || !mouthCenter || !rightEye) return undefined;

  const { x: topX, y: topY } = noseTip;
  const { x: rightX, y: rightY } = rightEarTragion;
  const { x: leftX, y: leftY } = leftEarTragion;

  const bottomX = (rightX + leftX) / 2;
  const bottomY = (rightY + leftY) / 2;

  const deltaZ = topY - bottomY;
  const deltaX = topX - bottomX;
  const deltaY = topY - bottomY;

  const angleX = Math.atan2(deltaZ, deltaX);
  const angleDegreesX = (angleX * 180) / Math.PI;

  const angleY = Math.atan2(deltaX, deltaY);
  const angleDegreesY = (angleY * 180) / Math.PI;

  return {
    axisXRotation: Math.ceil(angleDegreesX),
    axisYRotation: Math.ceil(angleDegreesY),
    tilt: Math.atan((leftY - rightY) / (leftX - rightX)) * (180 / Math.PI),
    noseTip,
    leftEarTragion,
    rightEarTragion,
    diffNoseTipLeftEarTragionX: Math.abs(topX - leftX),
    diffNoseTipLeftEarTragionY: Math.abs(topY - leftY),
    diffNoseTipRightEarTragionX: Math.abs(topX - rightX),
    diffNoseTipRightEarTragionY: Math.abs(topY - rightY),
  };
};

//
//
//
// DRAW FUNCTIONS
// __FOR DEBUGGING
//
//
//
export const drawBoxForCheckIfFaceIsInPlace = (
  ctx: CanvasRenderingContext2D,
  source: HTMLVideoElement,
  drawMinMaxBoundingBox = false,
): void => {
  const { x, y, width, height, boundingBox } = getBoundingBoxForIsFaceInPlace(source);

  ctx.beginPath();
  ctx.rect(x, y, width, height);
  ctx.strokeStyle = 'red';
  ctx.stroke();

  ctx.fillStyle = 'red';
  ctx.beginPath();
  ctx.arc(boundingBox.topLeft[0], boundingBox.topLeft[1], 5, 0, 2 * Math.PI); // Start point
  ctx.fill();
  ctx.beginPath();
  ctx.arc(boundingBox.bottomRight[0], boundingBox.bottomRight[1], 5, 0, 2 * Math.PI); // Start point
  ctx.fill();

  if (drawMinMaxBoundingBox) {
    const minMaxTopLeft = {
      x: generateMinMax(boundingBox.topLeft[0], FACE_BOUNDING_THRESHOLD_AXIS_X),
      y: generateMinMax(boundingBox.topLeft[1], FACE_BOUNDING_THRESHOLD_AXIS_Y),
    };
    const minMaxBottomRight = {
      x: generateMinMax(boundingBox.bottomRight[0], FACE_BOUNDING_THRESHOLD_AXIS_X),
      y: generateMinMax(boundingBox.bottomRight[1], FACE_BOUNDING_THRESHOLD_AXIS_Y),
    };

    ctx.beginPath();
    ctx.rect(
      minMaxTopLeft.x[1],
      minMaxTopLeft.y[1],
      minMaxBottomRight.x[0] - minMaxTopLeft.x[1],
      minMaxBottomRight.y[0] - minMaxTopLeft.y[1],
    );
    ctx.rect(
      minMaxTopLeft.x[0],
      minMaxTopLeft.y[0],
      minMaxBottomRight.x[1] - minMaxTopLeft.x[0],
      minMaxBottomRight.y[1] - minMaxTopLeft.y[0],
    );
    ctx.strokeStyle = 'pink';
    ctx.stroke();
  }
};

// Triangle drawing method
const drawPath = (ctx: CanvasRenderingContext2D, points: number[][], closePath: boolean, color?: string) => {
  const region = new Path2D();
  region.moveTo(points[0][0], points[0][1]);
  for (let i = 1; i < points.length; i++) {
    const point = points[i];
    region.lineTo(point[0], point[1]);
  }

  if (closePath) {
    region.closePath();
  }
  ctx.strokeStyle = color || 'aqua';
  ctx.stroke(region);
};

export const drawPointsForAxisXY = (faces: Face[] | null, ctx: CanvasRenderingContext2D): void => {
  if (faces && faces.length > 0) {
    faces.forEach((face) => {
      const { keypoints } = face;

      const noseTip = keypoints.find(({ name }) => name === 'noseTip');
      const leftEarTragion = keypoints.find(({ name }) => name === 'leftEarTragion');
      const rightEarTragion = keypoints.find(({ name }) => name === 'rightEarTragion');

      if (noseTip && leftEarTragion && rightEarTragion) {
        const { x: topX, y: topY } = noseTip;

        const { x: rightX } = rightEarTragion;
        const { x: leftX } = leftEarTragion;
        const bottomX = (rightX + leftX) / 2;
        // const bottomY = (rightY + leftY) / 2;

        drawPath(
          ctx,
          [
            [bottomX, topY],
            [topX, topY],
          ],
          true,
          'blue',
        );
      }
    });
  }
};

export const drawBoundingBox = (faces: Face[] | null, ctx: CanvasRenderingContext2D): void => {
  if (faces && faces.length > 0) {
    faces.forEach((prediction) => {
      const { box } = prediction;
      const { xMax, xMin, yMax, yMin } = box;

      drawPath(
        ctx,
        [
          [xMin, yMin],
          [xMax, yMin],
          [xMax, yMax],
          [xMin, yMax],
        ],
        true,
      );
    });
  }
};

export const drawPoints = (faces: Face[] | null, ctx: CanvasRenderingContext2D): void => {
  if (faces && faces.length > 0) {
    faces.forEach((prediction) => {
      const keypoints = prediction.keypoints.map((keypoint) => [keypoint.x, keypoint.y]);

      for (let i = 0; i < keypoints.length; i++) {
        const x = keypoints[i][0];
        const y = keypoints[i][1];

        ctx.beginPath();
        ctx.arc(x, y, 2 /* radius */, 0, 2 * Math.PI);
        ctx.fillStyle = 'green';
        ctx.fill();
      }
    });
  }
};
