import React from 'react';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

const lineSx: ThemeUIStyleObject = {
  content: '""',
  display: 'block',
  position: 'absolute',
  top: 'calc(50% - 1px)',
  width: '300px',
  borderBottom: '1px solid',
  borderColor: 'authentication.line.border',
};

export const LineDivider = ({ children }: { children: React.ReactElement }): React.ReactElement => (
  <Flex sx={{ overflow: 'hidden', my: 4, alignItems: 'center', justifyContent: 'center' }}>
    <Text
      sx={{
        position: 'relative',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: 1,
        color: 'authentication.line.text',
        mx: 4,
        whiteSpace: 'nowrap',
        '&::before': {
          ...lineSx,
          left: -4,
          transform: 'translateX(-100%)',
        },
        '&::after': {
          ...lineSx,
          right: -4,
          transform: 'translateX(100%)',
        },
      }}
    >
      {children}
    </Text>
  </Flex>
);
