import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { LanguageRoute, REDIRECT_ROUTES, RedirectKeys } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { useRedirectPath } from 'hooks/useRedirectPath/useRedirectPath';
import { languageSelector } from 'state/recoilState';
import { CustomEvents } from 'utils/customEvents';

// TODO: check if using Recoil Sync URL Persistence is not a better option here
export const RedirectObserver: React.ElementType = () => {
  const language = useRecoilValue(languageSelector);
  const navigate = useAppNavigate();
  const { redirectPath, redirectQuery } = useRedirectPath();

  useEffect(() => {
    if (redirectPath) {
      const state =
        redirectPath === REDIRECT_ROUTES(RedirectKeys.SIGN_UP__ACCOUNT_TYPE)[language]
          ? { signUp: { inProgress: true } }
          : undefined;
      navigate(`${redirectPath}${redirectQuery}`, { replace: true, state });
    }
  }, [language, navigate, redirectPath, redirectQuery]);

  useCustomEventListener(CustomEvents.REDIRECT, (redirectTo) => {
    if ((redirectTo as LanguageRoute)[language]) {
      navigate((redirectTo as LanguageRoute)[language]);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { route, state, id } = redirectTo as { route: LanguageRoute; state: any; id?: string };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    navigate(`${route[language]}${!_.isUndefined(id) ? `/${id}` : ''}`, { state });
  });

  return null;
};
