import { FC, useCallback, useContext, useState } from 'react';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import { FixedSizeList } from 'react-window';

import { Icon } from 'components/Icon/Icon';
import { ScrollController } from '../../../../components/utils/ScrollController';
import { PILL_PERSON_HEIGHT } from 'styles/theme/attendancePill';
import { DrawerContext } from '../DrawerContext';

import { AttendancePillPersonContentProps } from './AttendancePillPersonContent';
import { MemoizedAttendancePillPerson } from './AttendancePillPerson';

type Props = {
  title: string;
  variant?: 'default' | 'success' | 'warning' | 'danger';
  persons: AttendancePillPersonContentProps[];
  sx?: ThemeUIStyleObject;
  hasError?: boolean;
  presenceCount?: number;
};

export const AttendancePill: FC<Props> = ({
  title,
  variant = 'default',
  persons,
  sx,
  hasError,
  presenceCount = -1,
}) => {
  const drawerContext = useContext(DrawerContext);

  if (drawerContext === undefined) {
    throw new Error('AttendancePill must be used within a Drawer component');
  }

  const { scrollParentRef } = drawerContext;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenList = useCallback(() => {
    if (persons && persons.length > 0) {
      setIsOpen(!isOpen);
    }
  }, [persons, setIsOpen, isOpen]);

  const returnScrollElement = useCallback(() => {
    if (scrollParentRef && scrollParentRef.current !== null) {
      return scrollParentRef.current;
    }
    return undefined;
  }, [scrollParentRef]);

  return (
    // change with caution, element with vertical scroll must remain in Drawer, see: scrollParentRef
    <Flex
      variant="attendancePill.container"
      data-is-empty={persons?.length === 0 && true}
      data-is-open={isOpen}
      data-has-error={hasError}
      sx={sx}
    >
      <Flex onClick={handleOpenList} variant="attendancePill.containerHeader">
        <Flex
          variant="attendancePill.containerCounter"
          sx={{
            bg: `attendancePill.variants.${variant}`,
          }}
        >
          {presenceCount >= 0 && `${presenceCount}/`}
          {persons ? persons?.length : 0}
        </Flex>

        <Text variant="attendancePill.containerTitle">{title}</Text>

        {persons?.length > 0 && <Icon type={isOpen ? 'chevronUp' : 'chevronDown'} />}
      </Flex>

      {isOpen && persons?.length > 0 && (
        <ScrollController element={returnScrollElement()}>
          {({ ref, outerRef, style: injectedStyle, onScroll }) => (
            <FixedSizeList
              ref={ref}
              height={scrollParentRef?.current?.clientHeight || 0}
              width="100%"
              itemCount={persons?.length}
              itemSize={PILL_PERSON_HEIGHT}
              outerRef={outerRef}
              overscanCount={5}
              style={{
                ...injectedStyle,
                overflowX: 'hidden',
                borderRadius: '12px',
              }}
              onScroll={onScroll}
            >
              {({ index, style }) => (
                <MemoizedAttendancePillPerson person={persons?.[index]} key={index} style={style} />
              )}
            </FixedSizeList>
          )}
        </ScrollController>
      )}
    </Flex>
  );
};
