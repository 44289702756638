import { FC } from 'react';

import { Icon } from 'components/Icon/Icon';
import { LinkButton, LinkButtonProps } from 'components/ui/Buttons';

export const LocationButton: FC<LinkButtonProps> = ({ children, sx, ...props }) => (
  <LinkButton
    prependWith={<Icon type="location" size={24} />}
    shape="rounded"
    size="xs"
    sx={{
      fontWeight: '400',
      maxWidth: '50%',
      fontSize: 2,
      py: 0,
      pl: 0,
      pr: '4px',
      color: 'texts.default',
      background: 'alphas.darker2',
      border: '1px solid',
      borderColor: 'alphas.darker5',
      position: 'relative',
      '&:hover, &:focus': {
        background: 'alphas.primarish4',
        borderColor: 'alphas.primarish6',
        boxShadow: 'dropShadow.levelZero',
      },
      '&:active': {
        background: 'alphas.primarish4',
      },
      ...sx,
    }}
    {...props}
  >
    {children}
  </LinkButton>
);
