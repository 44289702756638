import React, { CSSProperties } from 'react';
import { Flex } from 'theme-ui';

type Props = {
  height: CSSProperties['height'];
  width: CSSProperties['width'];
  children: React.ReactNode;
};

export const FrozenColumnContainer = ({ height, width, children }: Props): React.ReactElement => (
  <Flex
    style={{
      height,
      width,
    }}
    sx={{
      position: 'sticky',
      left: 0,
      zIndex: 42,
      flexDirection: 'column',
      boxShadow: 'frozenContainer',
    }}
  >
    {children}
  </Flex>
);

export const MemorizedFrozenColumnContainer = React.memo(FrozenColumnContainer);
