import { useEffect, useRef } from 'react';
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';

import { useKeyboardEvent } from 'hooks/useKeyboardEvent/useKeyboardEvent';

type CallbackQueueMapType = { callbackId: string; callbackPriority: number; depth: number }[];

const callbackQueuesMap: Map<string, CallbackQueueMapType> = new Map();
const queueDepthMap: Map<string, number> = new Map();

/**
 * Problem: in some cases the hook managed to call one callback,
 * delete it from the callbackQueuesMap and after that the next callback was called because it was the last one in the callbackQueuesMap
 *
 * When multiple callback for the same key are registered, on key press all callback ale called.
 * To make sure the call condition in each of the callbacks is using the same value of callbackQueuesMap,
 * this function will return the cached value for all calls that occurs in the span of CACHE_DURATION.
 */
const getCachedCallbackQueuesMap = (() => {
  let callbackQueuesCache: Map<string, CallbackQueueMapType> | null = null;
  let lastCacheTime: number = 0;
  const CACHE_DURATION = 100; // milliseconds
  return () => {
    const currentTime = Date.now();

    if (callbackQueuesCache !== null && currentTime - lastCacheTime < CACHE_DURATION) {
      return callbackQueuesCache;
    }

    callbackQueuesCache = new Map(callbackQueuesMap);
    lastCacheTime = currentTime;

    return callbackQueuesCache;
  };
})();

const registerCallbackDepth = (key: string) => {
  const callbackQueue = callbackQueuesMap.get(key) || [];
  const lastCallbackQueueElement = callbackQueue[callbackQueue.length - 1];
  const currentDepth = queueDepthMap.get(key);
  const isCurrentDepthBiggerThanLastElementDepth = currentDepth && currentDepth >= lastCallbackQueueElement?.depth;
  // make sure that depth value is +1 from previous depth in the list
  // after refresh if there are couple of modals on first rerender lastCallbackQueueElement is undefined
  // then we have to count on queueDepthMap
  const depth =
    _.isNumber(lastCallbackQueueElement?.depth) && !isCurrentDepthBiggerThanLastElementDepth
      ? lastCallbackQueueElement.depth + 1
      : (queueDepthMap.get(key) || 0) + 1;
  queueDepthMap.set(key, depth);
  return depth;
};

/**
 * Adds the provided callback to a callback queue, when the specified key is pressed, only the callback thats id is first in its queue (added last) will be executed.
 *
 * @param {string} key Key name, determines the key of the queue that the callbacks id will be stored in.
 * @param {() => void} callback Will run when the key is pressed and if its id is first in the queue (last in array).
 * @param {boolean | undefined} active Lets you add or remove callback from the queue after component mounts (if undefined callback will be added to the queue on component mount).
 * @param {number | undefined} priority Callbacks with the higher priority will be called first.
 */

export const useOnKeyboardEventQueue = (
  key: string,
  callback: () => void,
  active?: true | false | undefined,
  priority: number = 1,
) => {
  const wasDepthInitializedRef = useRef(false);
  if (!_.isNumber(queueDepthMap.get(key))) {
    queueDepthMap.set(key, 0);
  }
  const listenerDepthRef = useRef(queueDepthMap.get(key) || 0);

  if (!_.isBoolean(active) && !wasDepthInitializedRef.current) {
    wasDepthInitializedRef.current = true;
    const depth = registerCallbackDepth(key);
    listenerDepthRef.current = depth;
  }

  const callbackIdRef = useRef(uuidv1());
  const activeRef = useRef(active);
  const callbackPriorityRef = useRef(priority);

  useEffect(() => {
    if (_.isBoolean(activeRef.current)) return undefined;

    const depth = listenerDepthRef.current;
    const callbackPriority = callbackPriorityRef.current;
    const callbackId = callbackIdRef.current;
    const callbackQueue = callbackQueuesMap.get(key) || [];

    callbackQueuesMap.set(key, [...callbackQueue, { callbackId, callbackPriority, depth }]);

    return () => {
      const filteredCallbackQueue = callbackQueuesMap.get(key)?.filter(({ callbackId: id }) => id !== callbackId) || [];
      callbackQueuesMap.set(key, filteredCallbackQueue);
      // on onmount we need to substract 1 from current depth to avoid infinite increasing
      const newDepth = !filteredCallbackQueue.length ? 0 : (queueDepthMap.get(key) || 1) - 1;
      queueDepthMap.set(key, newDepth);
    };
  }, [key]);

  useEffect(() => {
    if (!_.isBoolean(active)) return undefined;

    const callbackPriority = callbackPriorityRef.current;
    const callbackId = callbackIdRef.current;
    const callbackQueue = callbackQueuesMap.get(key) || [];

    if (active) {
      const depth = registerCallbackDepth(key);
      listenerDepthRef.current = depth;
      callbackQueuesMap.set(key, [...callbackQueue, { callbackId, callbackPriority, depth }]);
    }

    return () => {
      const filteredCallbackQueue = callbackQueuesMap.get(key)?.filter(({ callbackId: id }) => id !== callbackId) || [];
      callbackQueuesMap.set(key, filteredCallbackQueue);
      // on onmount we need to substract 1 from current depth to avoid infinite increasing
      const newDepth = !filteredCallbackQueue.length ? 0 : (queueDepthMap.get(key) || 1) - 1;
      queueDepthMap.set(key, newDepth);
    };
  }, [active, key]);

  useKeyboardEvent(
    key,
    () => {
      const callbackQueue = _.orderBy(getCachedCallbackQueuesMap().get(key) || [], ['callbackPriority', 'depth']);

      if (callbackQueue[callbackQueue.length - 1]?.callbackId === callbackIdRef.current) {
        callback();
      }
    },
    _.isBoolean(active) ? !active : undefined,
  );
};
