/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';

import { PayRate, WorkdayDuration } from 'api/actions/employees/employeesActions.types';
import { TimeOffTypes, periodsMax } from 'api/actions/organizationSession/organizationSessionActions.types';
import { VALIDATION_RULES, validationFactory, validationResolver } from 'constants/validationRules';
import { ParsedCustomRequestLimitGroup, ParsedTimeOffLimitsGroup } from 'state/team';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';

//
// Validators used in resolvers to get errors from field arrays
//

const getRequiredMessage = () =>
  t({
    id: 'global.forms.required',
  });

export const workdayDurationsValidator = (workdayDurations?: WorkdayDuration[]) =>
  workdayDurations?.reduce(
    (acc, { workdayDurationSeconds }, index) =>
      !workdayDurationSeconds ||
      isNaN(+workdayDurationSeconds) ||
      +workdayDurationSeconds < VALIDATION_RULES.WORKDAY_DURATION_SECONDS.min ||
      +workdayDurationSeconds > VALIDATION_RULES.WORKDAY_DURATION_SECONDS.max
        ? {
            ...acc,
            [index]: {
              workdayDurationSeconds: {
                message: getRequiredMessage(),
              },
            },
          }
        : acc,
    {},
  );

export const customRequestLimitsValidator = (customRequestsLimits?: ParsedCustomRequestLimitGroup[]) =>
  customRequestsLimits?.reduce((acc, { limits }, index) => {
    const requestTypesErrors = limits.reduce((requestTypeErrors, requestType, nestedIndex) => {
      if (!isNil(requestType.period) && requestType.days && +requestType.days > periodsMax[+requestType.period]) {
        return {
          ...requestTypeErrors,
          [nestedIndex]: {
            days: {
              message: t({
                id: 'team.user.employment.req_limit',
                message: `max: ${periodsMax[+requestType.period]}`,
              }),
            },
          },
        };
      }

      if (!requestType.days && requestType.days !== 0 && !requestType.unlimited) {
        return {
          ...requestTypeErrors,
          [nestedIndex]: {
            days: {
              message: t({ id: 'global.forms.required.short' }),
            },
          },
        };
      }

      return requestTypeErrors;
    }, {});
    return !isEmpty(requestTypesErrors) ? { ...acc, [index]: { limits: requestTypesErrors } } : acc;
  }, {});

export const timeOffLimitsValidator = (timeOffLimits?: ParsedTimeOffLimitsGroup[]) => {
  let selectedYear: number;
  emitCustomEvent(CustomEvents.GET_TIME_OFF_SELECTED_YEAR, {
    callback: (year: number) => {
      selectedYear = year;
    },
  });

  return timeOffLimits?.reduce((acc, { limits, fromYear }, index) => {
    const timeOffLimitsErrors = limits.reduce((timeOffLimitErrors, limit, nestedIndex) => {
      if (!limit.days && limit.days !== 0 && !limit.unlimited) {
        return {
          ...timeOffLimitErrors,
          [nestedIndex]: {
            days: {
              message: t({ id: 'global.forms.required.short' }),
            },
          },
        };
      }

      if (
        (!selectedYear || selectedYear === fromYear) &&
        limit.timeOffTypeId === TimeOffTypes.LeaveOnRequest &&
        limit.days
      ) {
        const annualLeaveLimit = limits.find((item) => item.timeOffTypeId === TimeOffTypes.AnnualLeave);
        if (
          annualLeaveLimit &&
          annualLeaveLimit.days &&
          !annualLeaveLimit.unlimited &&
          +limit.days > +annualLeaveLimit.days
        ) {
          return {
            ...timeOffLimitErrors,
            [nestedIndex]: {
              days: {
                message: t({
                  id: 'team.user.employment.req_limit',
                  message: `max: ${+annualLeaveLimit.days}`,
                }),
              },
            },
          };
        }
      }

      return timeOffLimitErrors;
    }, {});
    return !isEmpty(timeOffLimitsErrors) ? { ...acc, [index]: { limits: timeOffLimitsErrors } } : acc;
  }, {});
};

export const payRatesValidator = (rates?: PayRate[]) =>
  rates?.reduce(
    (acc, { normal, overtime }, index) => {
      const normalRateError = validationResolver(normal, validationFactory(VALIDATION_RULES.PAY_RATE_NORMAL));
      const overtimeRateError = validationResolver(overtime, validationFactory(VALIDATION_RULES.PAY_RATE_OVERTIME));
      const newErrors =
        normalRateError || overtimeRateError
          ? {
              [index]: {
                ...(normalRateError && {
                  normal: {
                    message: normalRateError,
                  },
                }),
                ...(overtimeRateError && {
                  overtime: {
                    message: overtimeRateError,
                  },
                }),
              },
            }
          : null;
      return {
        ...acc,
        ...(newErrors && newErrors),
      };
    },

    {},
  );
