/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { ChangeEvent, FC, Fragment, memo, useCallback } from 'react';
import { Control, UseFormRegister, useFormState, UseFormTrigger, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { TimeOffTypes } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Checkbox } from 'components/ui/Checkbox';
import { MaskedTextInput } from 'components/ui/MaskedTextInput';
import { useMount } from 'hooks/useMount/useMount';
import { timeOffTypesDictionarySelector } from 'state/organizationSession';
import { EmployeeRequestLimitsFormState, ParsedTimeOffLimit } from 'state/team';
import { delay } from 'utils/delay';
import { TimeOffLimitsBasicBasicRegister, TimeOffLimitsBasicControl, TimeOffLimitsBasicFormState } from '../../types';

type BasicControl = TimeOffLimitsBasicControl;
type BasicRegister = TimeOffLimitsBasicBasicRegister;

type Props = {
  nestIndex: number;
  control: Control<EmployeeRequestLimitsFormState> | Control<TimeOffLimitsBasicFormState>;
  trigger: UseFormTrigger<EmployeeRequestLimitsFormState> | UseFormTrigger<TimeOffLimitsBasicFormState>;
  register: UseFormRegister<EmployeeRequestLimitsFormState> | UseFormRegister<TimeOffLimitsBasicFormState>;
  update: (index: number, value: ParsedTimeOffLimit) => void;
};

export const NestedTimeOffLimits: FC<Props> = ({ nestIndex, control, trigger, register, update }) => {
  useLingui();
  const { errors } = useFormState({ control: control as BasicControl });

  const watchNestedLimits = useWatch({
    control: control as BasicControl,
    name: `timeOffLimits.${nestIndex}.limits`,
  }) as ParsedTimeOffLimit[];

  const leaveOnRequestField = watchNestedLimits.find(
    (limit: ParsedTimeOffLimit) => limit.timeOffTypeId === TimeOffTypes.LeaveOnRequest,
  );

  const annualLeaveField = watchNestedLimits.find(
    (limit: ParsedTimeOffLimit) => limit.timeOffTypeId === TimeOffTypes.AnnualLeave,
  );

  const leaveOnRequestIndex = leaveOnRequestField ? watchNestedLimits.indexOf(leaveOnRequestField) : NaN;

  const getIsUnlimitedDisabled = (field: ParsedTimeOffLimit) => {
    if (field.timeOffTypeId !== TimeOffTypes.LeaveOnRequest) return false;

    return !annualLeaveField?.unlimited;
  };

  const disableUnlimitedLeaveOnRequest = useCallback(() => {
    if (leaveOnRequestIndex && leaveOnRequestField) {
      update(leaveOnRequestIndex, {
        ...leaveOnRequestField,
        unlimited: false,
        days: leaveOnRequestField.days,
        carryOverUnusedLimit: false,
      });
    }
  }, [leaveOnRequestIndex, leaveOnRequestField, update]);

  const triggerSuspendedDaysValidation = async (fieldIndex: number) => {
    await delay(100);
    void trigger(`timeOffLimits.${nestIndex}.limits.${fieldIndex}.days`);
  };

  const timeOffTypesDictionary = useRecoilValue(timeOffTypesDictionarySelector);

  useMount(() => {
    if (!annualLeaveField?.unlimited) disableUnlimitedLeaveOnRequest();
    void trigger();
  });

  if (!timeOffTypesDictionary) return null;

  return (
    <Flex sx={{ flexDirection: 'column', gap: 2, mt: 2 }}>
      {watchNestedLimits.map((field, index) => {
        const { name, abbreviation } = timeOffTypesDictionary[field.timeOffTypeId] || {};

        if (!name || !abbreviation) return <Fragment key={`time-off-type-${field.timeOffTypeId}`} />;

        const isLeaveOnRequestType = field.timeOffTypeId === TimeOffTypes.LeaveOnRequest;
        const isAnnualLeaveType = field.timeOffTypeId === TimeOffTypes.AnnualLeave;

        const unlimitedRegisterPropsDefault = (register as BasicRegister)(
          `timeOffLimits.${nestIndex}.limits.${index}.unlimited`,
        );

        const daysRegisterPropsDefault = (register as BasicRegister)(`timeOffLimits.${nestIndex}.limits.${index}.days`);

        const isUnlimitedDisabled = getIsUnlimitedDisabled(field);

        const unlimitedRegisterProps = {
          ...unlimitedRegisterPropsDefault,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const shouldUpdateLeaveOnRequest = isAnnualLeaveType && leaveOnRequestField;
            if (!e.target.checked) {
              if (shouldUpdateLeaveOnRequest) {
                disableUnlimitedLeaveOnRequest();
                void triggerSuspendedDaysValidation(leaveOnRequestIndex);
              }

              void unlimitedRegisterPropsDefault.onChange(e);
              void triggerSuspendedDaysValidation(index);
              return;
            }

            update(index, {
              ...field,
              unlimited: true,
              days: '' as unknown as undefined, // update requires value
              ...(!isLeaveOnRequestType && {
                carryOverUnusedLimit: false,
              }),
            });

            if (shouldUpdateLeaveOnRequest) void triggerSuspendedDaysValidation(leaveOnRequestIndex);
            void triggerSuspendedDaysValidation(index);
          },
        };

        const daysRegisterProps = {
          ...daysRegisterPropsDefault,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            void daysRegisterPropsDefault.onChange(e);
            if (isAnnualLeaveType && leaveOnRequestIndex) {
              void triggerSuspendedDaysValidation(leaveOnRequestIndex);
            }
            void triggerSuspendedDaysValidation(index);
          },
        };

        return (
          <Flex
            key={`time-off-type--${name}-${field.timeOffTypeId}`}
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Flex sx={{ flexDirection: 'column', pr: '0.4rem' }}>
              <Text sx={{ fontSize: 2, fontWeight: 'bold', ...(isLeaveOnRequestType && { whiteSpace: 'nowrap' }) }}>
                {t({
                  id: name,
                })}
              </Text>
              <Text sx={{ fontSize: 1, color: 'team.nestedTimeOffLimits', lineHeight: 1 }}>
                {t({
                  id: abbreviation,
                })}
              </Text>
            </Flex>

            <Flex sx={{ alignItems: 'center', flexShrink: 0 }}>
              <Checkbox
                key={`timeOffLimits.${nestIndex}.limits.${index}.carryOverUnusedLimit.${!!field.unlimited}`}
                sx={{ ml: 2, ...(isLeaveOnRequestType && { pointerEvents: 'none', userSelect: 'none', opacity: 0 }) }}
                label={
                  <Text
                    sx={{
                      flexShrink: 0,
                      fontSize: 1,
                      lineHeight: 1,
                      display: 'block',
                      ml: -2,
                      whiteSpace: 'nowrap',
                      mr: 4,
                    }}
                  >
                    <Trans id="team.time_off_limits.carry">
                      Carry-over <br />
                      unused limit
                    </Trans>
                  </Text>
                }
                defaultChecked={!!field.carryOverUnusedLimit}
                disabled={!!field.unlimited}
                size="sm"
                {...(!isLeaveOnRequestType
                  ? (register as BasicRegister)(`timeOffLimits.${nestIndex}.limits.${index}.carryOverUnusedLimit`)
                  : { name: '' })}
              />
              <Checkbox
                sx={{ ml: 2 }}
                key={`timeOffLimits.${nestIndex}.limits.${index}.unlimited.${!isUnlimitedDisabled}.${field.unlimited}`}
                disabled={isUnlimitedDisabled}
                label={
                  <Text sx={{ flexShrink: 0, fontSize: 1, lineHeight: 1, display: 'block', ml: -2 }}>
                    <Trans id="team.limits.unlimited">Unlimited</Trans>
                  </Text>
                }
                defaultChecked={!!field.unlimited && !isUnlimitedDisabled}
                size="sm"
                {...unlimitedRegisterProps}
              />
              <Flex sx={{ width: '80px', flexShrink: 0 }}>
                <MaskedTextInput
                  key={`timeOffLimits.${nestIndex}.limits.${index}.days${!!field.unlimited}`}
                  disabled={!!field.unlimited}
                  mask={/^(?:36[0-6]|3[0-5][0-9]|[12][0-9][0-9]|[1-9][0-9]|[0-9])$/}
                  size="sm"
                  id={`timeOffLimits.${nestIndex}.limits.${index}.days`}
                  placeholder=""
                  variant="rounded"
                  error={!!errors.timeOffLimits?.[nestIndex]?.limits?.[index]?.days}
                  errorMessage={errors.timeOffLimits?.[nestIndex]?.limits?.[index]?.days?.message}
                  defaultValue={field.days}
                  apendWith={t({ id: 'team.time_off_limits.append_days', message: 'days' })}
                  {...daysRegisterProps}
                />
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export const NestedTimeOffLimitsFieldArray = memo(NestedTimeOffLimits);
