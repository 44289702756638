import { useCallback, useEffect, useRef, useState } from 'react';

import { TabName } from '../types';

type TabChangeValidator = (currentTabName?: TabName, nextTabName?: TabName) => Promise<boolean>;
const initialTabName = TabName.Profile;

export const useNavigation = (tabChangeValidator: TabChangeValidator, isMobileBreakpoint: boolean) => {
  const [currentTab, setCurrentTab] = useState<TabName | null>(isMobileBreakpoint ? null : initialTabName);
  const currentTabRef = useRef(currentTab);

  useEffect(() => {
    if (!isMobileBreakpoint && !currentTabRef.current) {
      currentTabRef.current = initialTabName;
      setCurrentTab(initialTabName);
    }
  }, [isMobileBreakpoint]);

  const handleTabChange = useCallback(
    async (tabName: TabName | null) => {
      if (currentTabRef.current === tabName) return;

      const isValid = await tabChangeValidator(currentTabRef.current || undefined, tabName || undefined);
      if (!isValid) return;

      currentTabRef.current = tabName;
      setCurrentTab(tabName);
    },
    [tabChangeValidator],
  );

  return [currentTab, handleTabChange] as const;
};
