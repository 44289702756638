import React from 'react';

/**
 * Sets provided value on the input element, does not dispatch 'input' event so 'onChange' handlers will not fire.
 * If you want to listen to changes made this way @see: 'useOnOwnValueChange.ts'
 *
 * @param {React.MutableRefObject<HTMLInputElement | null>} inputRef
 * @param {string} value
 */

export const silentSetValue = (inputRef: React.MutableRefObject<HTMLInputElement | null>, value: string): void => {
  const input = inputRef?.current;

  if (!input) return;

  input.value = value;
};
