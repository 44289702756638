import _ from 'lodash';

import { Employee, PersonName, Role, Tag } from 'api/actions/organizationSession/organizationSessionActions.types';
import { RequestPerson } from 'api/actions/drawer/drawerActions.types';

type ExtendedType = Pick<RequestPerson, 'isWorkTimeDuringTimeOff' | 'personId' | 'clockInTimeUnix'>;

type MappedPeopleType<D extends ExtendedType> = D & {
  name: PersonName;
  tags: (Tag | undefined)[];
  role: Role | undefined;
  avatarUrl: string | undefined;
};

type MapAttendancePeopleArgs<D extends ExtendedType> = {
  people: D[];
  employeesMap: Map<string, Employee>;
  tagsMap: Map<string, Tag>;
  rolesMap: Map<string, Role>;
  checkWorkingDuringAbsence?: boolean;
  getWorkingPeopleCount?: boolean;
  filterFunc: (arg: PersonName) => boolean;
  orderBy: string[];
};

export const mapAttendancePeople = <D extends ExtendedType>({
  people,
  employeesMap,
  tagsMap,
  rolesMap,
  filterFunc,
  orderBy,
  checkWorkingDuringAbsence,
  getWorkingPeopleCount,
}: MapAttendancePeopleArgs<D>) => {
  let isSomeoneWorkingDuringAbsence = false;
  let workingPeopleCount = 0;
  const mappedPeople = _(
    _.reduce<D, MappedPeopleType<D>[] | null>(
      people,
      (acc, p) => {
        const employee = employeesMap.get(p?.personId);
        if (!employee) return null;
        const addPersonToList = filterFunc(employee.name);
        if (
          addPersonToList &&
          checkWorkingDuringAbsence &&
          !isSomeoneWorkingDuringAbsence &&
          p?.isWorkTimeDuringTimeOff
        ) {
          isSomeoneWorkingDuringAbsence = p.isWorkTimeDuringTimeOff;
        }
        if (addPersonToList && getWorkingPeopleCount) {
          workingPeopleCount += p?.clockInTimeUnix ? 1 : 0;
        }
        if (addPersonToList)
          acc?.push({
            ...p,
            name: employee.name,
            tags: employee.tagsIds.flatMap((tagId) => tagsMap.get(tagId)),
            role: rolesMap.get(employee.roleId),
            avatarUrl: employee.avatarUrl,
          });
        return acc;
      },
      [],
    ),
  )
    .orderBy(orderBy)
    .value();

  return {
    ...(checkWorkingDuringAbsence && { isSomeoneWorkingDuringAbsence }),
    ...(getWorkingPeopleCount && { workingPeopleCount }),
    mappedPeople,
  };
};
