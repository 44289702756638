import { Trans } from '@lingui/macro';
import { motion } from 'framer-motion';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { Button } from 'components/ui/Buttons';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { useOnKeyboardEventQueue } from 'hooks/useOnKeyboardEventQueue/useOnKeyboardEventQueue';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import {
  anyChatWindowIsVisibleSelector,
  anyWindowIsVisibleSelector,
  mainWindowIsVisibleAtom,
  notOpenedUserHasUnreadMessageSelector,
  sortedChatUsersIdsSelector,
} from 'state/chat';
import { languageSelector } from 'state/recoilState';
import { useUpdateChatUsersSubGroups } from '../../../../hooks/useUpdateChatUsersSubGroups';

const AnimatedFlex = motion(Flex);
const LazyMainWindowContent = React.lazy(() =>
  import('./components/MainWindowContent').then(({ MainWindowContent }) => ({
    default: MainWindowContent,
  })),
);

const MainWindow = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const { isMobileChat } = useAppRouting(['isMobileChat']);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useRecoilState(mainWindowIsVisibleAtom);
  const hasUnreadMessages = useRecoilValue(notOpenedUserHasUnreadMessageSelector);
  const sortedChatUsersIds = useRecoilValue(sortedChatUsersIdsSelector);
  const anyWindowIsVisible = useRecoilValue(anyWindowIsVisibleSelector);
  const anyChatWindowIsVisible = useRecoilValue(anyChatWindowIsVisibleSelector);
  const navigate = useAppNavigate();
  const updateChatUsersSubGroups = useUpdateChatUsersSubGroups();
  const { isMobileBreakpoint } = useThemeBreakpoint();

  const variants = useMemo(
    () => ({
      collapsed: {
        height: '0vh',
        y: 'calc(0)',
      },
      open: {
        height: isMobileBreakpoint ? '100vh' : '70vh',
        y: 'calc(0)',
      },
    }),
    [isMobileBreakpoint],
  );

  const closeChat = useCallback(() => {
    if (isMobileBreakpoint) {
      navigate('..', { relative: 'path' });
      return;
    }

    setIsVisible(false);
  }, [isMobileBreakpoint, navigate, setIsVisible]);

  useOnKeyboardEventQueue('Escape', closeChat, !anyChatWindowIsVisible && anyWindowIsVisible);

  const handleIsVisible = useCallback(() => {
    if (!loading) {
      const newIsVisible = !isVisible;

      if (isMobileBreakpoint) {
        navigate(newIsVisible ? TO_REL.MOBILE_CHAT[language] : '..', { relative: 'path', replace: !newIsVisible });
        return;
      }
      setIsVisible(newIsVisible);
    }
  }, [loading, isVisible, setIsVisible, navigate, language, isMobileBreakpoint]);

  const renderLoader = () => (
    <Flex variant="chat.window.spinnerContainer">
      <LoadingSpinnerCss size={3} />
    </Flex>
  );

  useEffect(() => {
    if (isMobileBreakpoint) {
      setIsVisible(isMobileChat);
    }
  }, [isMobileChat, isMobileBreakpoint, setIsVisible, anyChatWindowIsVisible]);

  useEffect(() => {
    const getChatUsersSubGroups = async () => {
      await updateChatUsersSubGroups();
      setLoading(false);
    };

    void getChatUsersSubGroups();
  }, [updateChatUsersSubGroups, setLoading]);

  return (
    <AnimatedFlex
      key="chat.mainWindow"
      variant="chat.window.mainContainer"
      data-is-visible={isVisible}
      variants={variants}
      initial="collapsed"
      animate={isVisible ? 'open' : 'collapsed'}
      transition={{
        type: 'spring',
        duration: isMobileBreakpoint ? 0 : 0.25,
      }}
    >
      <Flex as="header" variant="chat.window.header" onClick={handleIsVisible} data-unread-message={hasUnreadMessages}>
        <Icon type="chat" wrapperSx={{ ...(hasUnreadMessages && { color: 'chat.text.unread' }) }} />

        <Flex variant="chat.window.header.texts">
          <Text>
            <Trans id="mainWindows.title">Messages</Trans>
          </Text>
        </Flex>

        <Flex variant="chat.window.header.buttons">
          <Button size="sm" variant="minimal" shape="rounded" sx={{ px: 1 }}>
            <Icon
              type={isVisible ? 'chevronDown' : 'chevronUp'}
              wrapperSx={{ ...(hasUnreadMessages && { color: 'chat.text.unread' }) }}
            />
          </Button>
        </Flex>
      </Flex>

      <Flex variant="chat.window.list">
        {isVisible &&
          sortedChatUsersIds &&
          (!loading ? (
            <Suspense fallback={renderLoader()}>
              <LazyMainWindowContent sortedChatUsersIds={sortedChatUsersIds} />
            </Suspense>
          ) : (
            renderLoader()
          ))}
      </Flex>
    </AnimatedFlex>
  );
};

export const MemoizedMainWindow = React.memo(MainWindow);
