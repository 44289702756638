import { Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { ReportDayDataType } from 'api/actions/reports/reportsActions.types';
import { Icon } from 'components/Icon/Icon';
import { useMinimizedModals } from 'components/Modal/output/useMinimizedModals';
import { Button } from 'components/ui/Buttons';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { useHideTimeOffTypes } from 'hooks/useHideTimeOffTypes/useHideTimeOffTypes';
import { useIsEmployeeNonEditable } from 'hooks/useIsEmployeeNonEditable/useIsEmployeeNonEditable';
import { AsideDetails } from 'layouts/AuthorizedApp/AsideDetails/AsideDetails';
import { RequestFancyListItem } from 'pages/Requests/output/RequestFancyListItem';
import { RequestDetailsGroupedParam } from 'pages/Requests/output/types';
import { ParsedEmployee } from 'state/employees';
import { MinimizedModal, ViewWithMinimizedModals } from 'state/modal';
import {
  customRequestTypesDictionarySelector,
  timeEventTypesDictionarySelector,
  timeOffTypesDictionarySelector,
} from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';
import { Step } from 'state/requests';
import { userSessionPersonIdSelector } from 'state/userSession';
import { FancyList } from '../components/FancyList';

import { RequestItem } from './components/RequestItem';

type Props = {
  requests: ReportDayDataType['requests'];
  userId: ParsedEmployee['id'];
  dateUnix: number;
  hideActions?: boolean;
};

export const RequestsRenderer = ({ requests, userId, dateUnix, hideActions }: Props): React.ReactElement | null => {
  const navigate = useAppNavigate();

  const timeOffTypes = useRecoilValue(timeOffTypesDictionarySelector);
  const customRequestTypes = useRecoilValue(customRequestTypesDictionarySelector);
  const timeEventTypes = useRecoilValue(timeEventTypesDictionarySelector);
  const language = useRecoilValue(languageSelector);
  const currentUserId = useRecoilValue(userSessionPersonIdSelector);
  const { hideTimeOffTypes } = useHideTimeOffTypes(userId);

  const isCurrentUserRequest = useMemo(() => currentUserId === userId, [currentUserId, userId]);

  const isEmployeeNonEditable = useIsEmployeeNonEditable('Requests', userId);

  const { modulesManagement } = useAppPermissions();

  const { isCalendar, isReportsAttendanceList } = useAppRouting(['isCalendar', 'isReportsAttendanceList']);

  const minimizedView = useMemo(() => {
    if (isCalendar) {
      return ViewWithMinimizedModals.CALENDAR;
    }

    if (isReportsAttendanceList) {
      return ViewWithMinimizedModals.ATTENDANCE;
    }

    return ViewWithMinimizedModals.TIMESHEETS;
  }, [isCalendar, isReportsAttendanceList]);

  const { minimizeModalActionValidation } = useMinimizedModals(minimizedView);

  const renderRequestList = () => {
    if (!requests || requests.length < 1) return null;

    if (!timeOffTypes || !customRequestTypes || !timeEventTypes) return null;

    const sortedRequests = _.sortBy(requests, [(x) => x.isDeleted]);

    return (
      <FancyList>
        {sortedRequests.map(
          ({
            actionType,
            id,
            number,
            state,
            type,
            typeDescription,
            typeId,
            isDeleted,
            isEnd,
            actions,
            swapPersonId,
            personId,
            externalId,
          }) => {
            const handleOnClick = () => {
              navigate(`${TO_REL.REQUEST_DETAILS__GROUP__ID[language]}/${RequestDetailsGroupedParam.Grouped}/${id}`);
            };

            const timeOffType = hideTimeOffTypes ? undefined : timeOffTypes[typeId];

            return (
              <RequestFancyListItem
                key={id}
                actions={actions}
                employeeId={swapPersonId ? personId : userId}
                id={id}
                isCurrentUserRequest={isCurrentUserRequest}
                isEmployeeNonEditable={isEmployeeNonEditable}
                isDeleted={isDeleted}
                number={number}
                onClick={handleOnClick}
                state={state}
                swapPersonId={swapPersonId}
                type={type}
                {...(isDeleted && {
                  sx: {
                    opacity: 0.5,
                  },
                })}
              >
                <RequestItem
                  type={type}
                  number={number}
                  state={state}
                  isDeleted={isDeleted}
                  actionType={actionType}
                  businessTripName={typeDescription}
                  requestType={typeId ? timeOffType || customRequestTypes[typeId] || timeEventTypes[typeId] : undefined}
                  isEnd={isEnd}
                  swapPersonId={swapPersonId}
                  employeeId={swapPersonId ? personId : userId}
                  isZus={!!externalId}
                />
              </RequestFancyListItem>
            );
          },
        )}
      </FancyList>
    );
  };

  const handleOpenAddRequestModal = async () => {
    const validation = await minimizeModalActionValidation(MinimizedModal.ADD_REQUESTS);

    if (!validation) return;

    navigate(TO_REL.ADD_REQUEST_MODAL[language], {
      state: {
        dateUnix,
        requestConfig: { employeesIds: [userId], excludedSteps: [Step.SelectTeammates] },
      },
    });
  };

  return (requests && requests.length > 0) ||
    (modulesManagement.Requests && !isEmployeeNonEditable) ||
    isCurrentUserRequest ? (
    <AsideDetails.Content.Container>
      <AsideDetails.Content.Title>
        <Trans id="reports.Requests">Requests</Trans>
      </AsideDetails.Content.Title>

      {renderRequestList()}

      {!hideActions && ((modulesManagement.Requests && !isEmployeeNonEditable) || isCurrentUserRequest) && (
        <Flex>
          <Button
            shape="rounded"
            variant="minimal"
            prependWith={<Icon type="plus" iconSx={{ bg: 'reports.requests.add.tap', borderRadius: 'xs' }} />}
            onClick={() => void handleOpenAddRequestModal()}
            sx={{
              px: 2,
            }}
            bgOverwrite={{
              default: 'reports.requests.add.default',
              hover: 'reports.requests.add.hover',
              tap: 'reports.requests.add.tap',
              disabled: 'reports.requests.add.disabled',
            }}
          >
            <Trans id="reports.add_request">Add request</Trans>
          </Button>
        </Flex>
      )}
    </AsideDetails.Content.Container>
  ) : null;
};
