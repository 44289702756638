import { useCallback, useContext } from 'react';
import { FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';

import { CustomEventsContext } from '../components/FormProviderCustomEvents/components/CustomEventsContext';

export const useFormContextCustomEvent = <TFieldValues extends FieldValues>() => {
  const customEventsContext = useContext(CustomEventsContext);

  if (customEventsContext === undefined) {
    throw new Error('useFormContextCustomEvent must be used within a FormProviderCustomEvents component');
  }

  const { onChange: onChangeCustom, onBlur: onBlurCustom } = customEventsContext;

  const { register, ...methods } = useFormContext<TFieldValues>();

  const registerOnChange = useCallback(
    (name: Path<TFieldValues>, options?: RegisterOptions) => {
      const { onChange, ...restRegister } = register(name, options);

      return {
        ...restRegister,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          void onChange(e);
          if (onChangeCustom) onChangeCustom();
        },
      };
    },
    [onChangeCustom, register],
  );

  const registerOnBlur = useCallback(
    (registerName: Path<TFieldValues>, registerOptions?: RegisterOptions) => {
      const { onBlur, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
          void onBlur(e);
          if (onBlurCustom) onBlurCustom();
        },
      };
    },
    [onBlurCustom, register],
  );

  return { register, registerOnChange, registerOnBlur, ...methods };
};
