import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { userPreferencesAction } from 'api/actions/settings/settingsActions';
import { UserPreferencesActionProps } from 'api/actions/settings/settingsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { PREFERENCES_RESET } from 'constants/settings';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { blockTransitionAtom, resetFormButtonAtom } from 'state/settings';

type Props = {
  handleFormReset: () => void;
  getCurrentFormState: () => UserPreferencesActionProps;
  preferencesSettings: UserPreferencesActionProps;
};

export const useSubmitPreferences = ({ handleFormReset, getCurrentFormState, preferencesSettings }: Props) => {
  useLingui();
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const [{ isRequestPending }, setBlockTransition] = useRecoilState(blockTransitionAtom);
  const { mutate } = useMutation(userPreferencesAction);
  const isRequestPendingRef = useCallbackRef(isRequestPending);
  const getCurrentFormStateRef = useCallbackRef(getCurrentFormState);
  const pendingRequestBodyRef = useRef<Partial<UserPreferencesActionProps> | null>(null);
  const prevFormStateRef = useRef<UserPreferencesActionProps | null>(preferencesSettings);

  const handleOnSubmitCallback = useCallback(
    async (data: UserPreferencesActionProps) => {
      const submittedFormState = data;

      if (
        _.isEqual(submittedFormState, preferencesSettings) &&
        !pendingRequestBodyRef.current &&
        !isRequestPendingRef.current
      ) {
        setBlockTransition((prevState) => ({ ...prevState, blockTransition: false }));
        return;
      }

      const currentChanges: UserPreferencesActionProps = _.omitBy(
        submittedFormState,
        (value, key) =>
          prevFormStateRef.current && value === prevFormStateRef.current[key as keyof UserPreferencesActionProps],
      );

      setResetCallbacks(null);
      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: true }));

      prevFormStateRef.current = submittedFormState;
      pendingRequestBodyRef.current = { ...pendingRequestBodyRef.current, ...currentChanges };

      const formStateBeforeRequest = getCurrentFormStateRef.current();

      const { error, status } = await mutate(pendingRequestBodyRef.current);

      const formStateAfterRequest = getCurrentFormStateRef.current();

      if (!_.isEqual(formStateBeforeRequest, formStateAfterRequest)) {
        return;
      }

      if (!error) {
        pendingRequestBodyRef.current = null;
        addSnackbar({
          message: t({
            id: 'settings.forms.submit_success',
          }),
          variant: 'success',
        });
      }

      if (error) {
        if (!status) {
          return;
        }
        const preferencesResetObject = { name: PREFERENCES_RESET, callback: handleFormReset };
        setResetCallbacks((prevState) =>
          !prevState ? [preferencesResetObject] : [...prevState, preferencesResetObject],
        );
        return;
      }

      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: false, blockTransition: false }));
    },
    [
      getCurrentFormStateRef,
      handleFormReset,
      isRequestPendingRef,
      mutate,
      preferencesSettings,
      setBlockTransition,
      setResetCallbacks,
    ],
  );

  return handleOnSubmitCallback;
};
