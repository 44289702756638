/** @jsxImportSource theme-ui */

import { keyframes } from '@emotion/react';
import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

type Props = {
  sx?: ThemeUIStyleObject;
  size?: number;
  color?: string;
} & React.ComponentPropsWithRef<'div'>;

export type LoadingSpinnerCssProps = Props;

export enum LoadingSpinnerSize {
  imageXS = 1,
  imageSM = 2,
  imageMD = 3,
  imageXL = 4,
  modal = 5,
}

const defaultProps: Partial<Props> = {
  sx: undefined,
  size: 2,
  color: 'loading.loadingSpinnerCSS.color',
};

const dotPulseAnimation = keyframes({
  '0%': {
    opacity: 0.3,
    transform: 'translate3d(0, -50%, 0) scale(0.95)',
  },
  '100%': {
    opacity: 1,
    transform: 'translate3d(0, 50%, 0) scale(1.05)',
  },
});

export const LoadingSpinnerCss = React.forwardRef<HTMLDivElement, Props>(
  ({ size = 2, color = 'loading.loadingSpinnerCSS.color', sx }: Props, ref) => {
    const containerSx: ThemeUIStyleObject = {
      width: `${size}rem`,
      minWidth: `${size}rem`,
      height: `${(size / 2) * 1.5}rem`,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    };

    const dotSx: ThemeUIStyleObject = {
      display: 'block',
      position: 'relative',
      width: `${size * 0.25}rem`,
      height: `${size * 0.25}rem`,
      backgroundColor: color,
      color,
      borderRadius: '50%',
      opacity: '0.3',
      transform: 'translate3d(0, -50%, 0) scale(0.95)',
      animation: `${dotPulseAnimation} 0.5s ease-in-out infinite alternate`,
    };

    return (
      <div ref={ref} sx={{ ...containerSx, ...(sx && sx) }}>
        <span sx={{ ...dotSx, animationDelay: '0' }} />
        <span sx={{ ...dotSx, animationDelay: '0.25s' }} />
        <span sx={{ ...dotSx, animationDelay: '0.50s' }} />
      </div>
    );
  },
);

LoadingSpinnerCss.defaultProps = defaultProps;
