import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useRef } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { ElementGroup } from 'components/ui/ElementGroup';
import { PhoneInput } from 'components/ui/PhoneInput';
import { TextInput } from 'components/ui/TextInput';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { blockTransitionAtom } from 'state/settings';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useFormCustomEvent } from '../../../../hooks/useFormCustomEvent';
import { useFormSubmit } from '../../../../hooks/useFormSubmit';
import { useSettingsBlockRouteTransition } from '../../../../hooks/useSettingsBlockRouteTransition';
import { SettingsGroup } from '../../../SettingsGroup/SettingsGroup';
import { useProfileSettings } from '../../hooks/useProfileSettings';
import { AvatarForm } from '../AvatarForm';

import { useSubmitProfileSettings } from './hooks/useSubmitProfileSettings';

export const ProfileForm = (): React.ReactElement => {
  useLingui();
  const { profileSettings, setProfileSettings } = useProfileSettings();
  const setBlockTransition = useSetRecoilState(blockTransitionAtom);
  const resetBlockTransition = useResetRecoilState(blockTransitionAtom);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { dispatchSubmitEvent, throttledDispatchSubmitEvent } = useFormSubmit(formRef);

  const handleOnFocus = useCallback(() => {
    throttledDispatchSubmitEvent.cancel();
  }, [throttledDispatchSubmitEvent]);

  const handleOnBlur = useCallback(() => {
    setBlockTransition((prevState) => ({
      ...prevState,
      blockLocationPathname: null,
      blockTransition: true,
    }));
    throttledDispatchSubmitEvent.cancel();
    throttledDispatchSubmitEvent();
  }, [setBlockTransition, throttledDispatchSubmitEvent]);

  const {
    setError,
    clearErrors,
    registerOnBlur,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useFormCustomEvent({
    onBlurCustom: handleOnBlur,
    formProps: {
      defaultValues: profileSettings || undefined,
      mode: 'onTouched',
      reValidateMode: 'onChange',
      shouldFocusError: false,
    },
  });

  const handleFormReset = useCallback(() => {
    resetBlockTransition();
    setProfileSettings(null);
  }, [resetBlockTransition, setProfileSettings]);

  const { handleSubmitCallback, handleErrorCallback } = useSubmitProfileSettings({
    handleFormReset,
    getCurrentFormState: getValues,
  });

  const setPhoneErrorCallback = useCallback(() => {
    setError('phoneNumberInternalError' as 'phoneNumber', {
      message: 'only visible in code',
    });
  }, [setError]);

  const clearPhoneErrorCallback = useCallback(() => {
    clearErrors('phoneNumberInternalError' as 'phoneNumber');
  }, [clearErrors]);

  useSettingsBlockRouteTransition(dispatchSubmitEvent, throttledDispatchSubmitEvent);

  return (
    <form ref={formRef} onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleErrorCallback))}>
      <Flex variant="settings.container.vertical.main">
        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title>
              <Trans id="settings.user.profile.personal_information">Personal information</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="sm">
              <Flex sx={{ justifyContent: 'space-between' }}>
                <ElementGroup marginValue="0" showAsList direction="column" wrapperSx={{ flexGrow: 1 }}>
                  <TextInput
                    size="sm"
                    id="firstName"
                    placeholder={t({
                      id: 'settings.user.profile.first_name',
                      message: 'First name',
                    })}
                    label={t({
                      id: 'settings.user.profile.full_name',
                      message: 'Full name',
                    })}
                    variant="roundedTop"
                    error={!!errors.firstName}
                    errorMessage={errors?.firstName?.message}
                    {...registerOnBlur(
                      'firstName',
                      validationFactory({ ...VALIDATION_RULES.FIRST_NAME, required: true }),
                    )}
                    onFocus={handleOnFocus}
                  />
                  <TextInput
                    size="sm"
                    id="surname"
                    placeholder={t({
                      id: 'settings.user.profile.surname',
                      message: 'Surname',
                    })}
                    variant="roundedBottom"
                    error={!!errors.surname}
                    errorMessage={errors?.surname?.message}
                    {...registerOnBlur('surname', validationFactory({ ...VALIDATION_RULES.SURNAME, required: true }))}
                    onFocus={handleOnFocus}
                  />
                </ElementGroup>
                <Flex sx={{ alignSelf: 'end', flexGrow: 0 }}>
                  <AvatarForm />
                </Flex>
              </Flex>
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>

        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title>
              <Trans id="settings.user.profile.contact_information">Contact information</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="sm">
              <ElementGroup marginValue={3} wrapperSx={{ width: '100%' }}>
                <TextInput
                  size="sm"
                  id="email"
                  placeholder={t({
                    id: 'global.forms.inputs.email',
                    message: 'E-mail address',
                  })}
                  label="E-mail"
                  variant="rounded"
                  type="email"
                  autoComplete="email"
                  error={!!errors.email}
                  errorMessage={errors?.email?.message}
                  {...registerOnBlur('email', validationFactory({ ...VALIDATION_RULES.EMAIL, required: true }))}
                  onFocus={handleOnFocus}
                />
                <PhoneInput
                  size="sm"
                  id="phoneNumber"
                  placeholder={t({
                    id: 'global.forms.inputs.phone_number',
                    message: 'Phone number',
                  })}
                  label={t({
                    id: 'global.forms.inputs.phone',
                    message: 'Phone',
                  })}
                  variant="rounded"
                  onValidError={setPhoneErrorCallback}
                  onClearError={clearPhoneErrorCallback}
                  error={!!errors.phoneNumber || !!errors['phoneNumberInternalError' as 'phoneNumber']}
                  errorMessage={errors?.phoneNumber?.message}
                  clearable
                  {...registerOnBlur('phoneNumber')}
                  onFocus={handleOnFocus}
                />
              </ElementGroup>
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>

        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title>
              <Trans id="settings.user.profile.address">Address</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="sm">
              <TextInput
                size="sm"
                id="street"
                placeholder={t({
                  id: 'global.forms.inputs.street',
                  message: 'Street',
                })}
                type="text"
                error={!!errors.address?.street}
                errorMessage={errors?.address?.street?.message}
                clearable
                {...registerOnBlur('address.street', validationFactory(VALIDATION_RULES.STREET))}
                label={t({
                  id: 'global.forms.inputs.street',
                })}
                onFocus={handleOnFocus}
              />
              <Flex>
                <TextInput
                  size="sm"
                  id="postalCode"
                  placeholder={t({
                    id: 'global.forms.inputs.zip',
                    message: 'ZIP',
                  })}
                  type="text"
                  error={!!errors.address?.postalCode}
                  errorMessage={errors?.address?.postalCode?.message}
                  clearable
                  {...registerOnBlur('address.postalCode', validationFactory(VALIDATION_RULES.POSTAL_CODE))}
                  sx={{ maxWidth: '120px', mr: 1 }}
                  label={t({
                    id: 'global.forms.inputs.postal_code',
                  })}
                  onFocus={handleOnFocus}
                />
                <TextInput
                  size="sm"
                  id="city"
                  placeholder={t({
                    id: 'global.forms.inputs.city',
                    message: 'City',
                  })}
                  type="text"
                  error={!!errors.address?.city}
                  errorMessage={errors?.address?.city?.message}
                  clearable
                  {...registerOnBlur('address.city', validationFactory(VALIDATION_RULES.CITY))}
                  label={t({
                    id: 'global.forms.inputs.city',
                  })}
                  onFocus={handleOnFocus}
                />
              </Flex>
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>
      </Flex>
    </form>
  );
};
