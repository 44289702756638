import { isEqual } from 'lodash';
import { useClient } from 'react-fetching-library';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { fetchEventWithDetailsAction } from 'api/actions/timeEvent/timeEventActions';
import { ExtendedTimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { useModal } from 'components/Modal/output/useModal';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useMemoCompare } from 'hooks/useMemoCompare/useMemoCompare';
import { useMount } from 'hooks/useMount/useMount';
import { extTimeEventSelector, outOfRangeTimeEventDetailsAtom } from 'state/clockLog';

export const useFindExtEvent = (eventId: ExtendedTimeEvent['id']) => {
  const setTimeEvent = useSetRecoilState(outOfRangeTimeEventDetailsAtom);
  const extTimeEvent = useRecoilValue(extTimeEventSelector(eventId));

  const initializedWithExtTimeEvent = useMemoCompare(!!extTimeEvent, (prev, next) => {
    if (prev) return true;
    if (next) return false;
    return isEqual(prev, next);
  });
  const initializedWithExtTimeEventRef = useCallbackRef(initializedWithExtTimeEvent);

  const { query } = useClient();
  const { handleClose } = useModal();

  useMount(() => {
    const fetchMissingEvent = async () => {
      if (!extTimeEvent && eventId.length) {
        const { error, payload } = await query(fetchEventWithDetailsAction(eventId));

        if (error) handleClose();

        if (payload) {
          setTimeEvent(payload);
        }
      }
    };

    if (!initializedWithExtTimeEventRef.current) {
      void fetchMissingEvent();
    }
  });

  return extTimeEvent;
};
