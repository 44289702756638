/** @jsxImportSource theme-ui */
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { forwardRef } from 'react';
import { useForm } from 'react-hook-form';

import { Textarea } from 'components/ui/Textarea';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { PrivateNotesFormState } from 'state/team';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

type Props = {
  defaultValues: PrivateNotesFormState;
  onSubmit: (props: PrivateNotesFormState) => Promise<boolean>;
  onSubmitError: () => void;
};

export const UserPrivateNotes = forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, onSubmitError, defaultValues }, ref) => {
    useLingui();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues,
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

    return (
      <form ref={ref} onSubmit={floatingPromiseReturn(handleSubmit(onSubmit, () => onSubmitError()))} noValidate>
        <Textarea
          label={t({ id: 'team.user.private.desc', message: 'Private note - visible to Managers & Administrators' })}
          labelProps={{
            sx: {
              width: '100%',
              whiteSpace: 'normal',
              pr: 2,
            },
          }}
          size="sm"
          resize="vertical"
          id="note"
          placeholder={t({
            id: 'team.user.private.desc.more',
            message: 'Private notes, logs and informations',
          })}
          error={!!errors.note}
          errorMessage={errors?.note?.message}
          inputProps={{ sx: { height: '142px' } }}
          {...register('note', validationFactory(VALIDATION_RULES.NOTE))}
        />
      </form>
    );
  },
);
