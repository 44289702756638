/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { useCallback } from 'react';
import { useClient } from 'react-fetching-library';
import { useSetRecoilState } from 'recoil';

import { fetchOrganizationSessionAction } from 'api/actions/organizationSession/organizationSessionActions';
import { refreshUserSessionAction } from 'api/actions/userSession/userSessionActions';
import { DefaultErrorMessageEnum } from 'api/types';
import { useUpdateChatUsersSubGroups } from 'base/Chat/output/useUpdateChatUsersSubGroups';
import {
  refreshOrganizationSession,
  refreshUserSession,
} from 'broadcastChannel/channelActionCreators/channelActionCreators';
import { getParsedOrganizationSession } from 'context/session/organizationSessionInitializer/getParsedOrganizationSession';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { organizationSessionAtom } from 'state/organizationSession';
import { userSessionAtom } from 'state/userSession';
import { CustomEvents } from 'utils/customEvents';

import { broadcastChannel } from './BroadcastChannelObserver';

export enum RefreshSessionCode {
  USER_AND_ORGANIZATION = 0,
  USER,
  ORGANIZATION,
}

export const RefreshSessionObserver: React.ElementType = () => {
  const setUserSession = useSetRecoilState(userSessionAtom);
  const setOrganizationSession = useSetRecoilState(organizationSessionAtom);
  const updateChatUsersSubGroups = useUpdateChatUsersSubGroups();
  const { query } = useClient();

  const getUserSession = useCallback(async () => {
    const { payload, error: submitError } = await query(refreshUserSessionAction());
    if (!submitError && payload) {
      void broadcastChannel?.postMessage(refreshUserSession(payload));
      setUserSession(payload);
    }
  }, [query, setUserSession]);

  const getOrganizationSession = useCallback(async () => {
    const { payload, error: submitError } = await query(
      fetchOrganizationSessionAction({
        customDefaultErrorMessage: DefaultErrorMessageEnum.None,
      }),
    );
    if (!submitError && payload) {
      const parsedOrganizationSession = getParsedOrganizationSession(payload);

      return parsedOrganizationSession;
    }

    return null;
  }, [query]);

  const getOrganizationSessionHandler = useCallback(async () => {
    const parsedOrganizationSession = await getOrganizationSession();

    if (!parsedOrganizationSession) return;

    void updateChatUsersSubGroups({ skipRefreshSession: true });
    void broadcastChannel?.postMessage(refreshOrganizationSession(parsedOrganizationSession));
    setOrganizationSession(parsedOrganizationSession);
  }, [getOrganizationSession, setOrganizationSession, updateChatUsersSubGroups]);

  useCustomEventListener(CustomEvents.REFRESH_SESSION, (refreshSessionCode) => {
    switch (refreshSessionCode as number) {
      case RefreshSessionCode.USER:
        void getUserSession();
        break;
      case RefreshSessionCode.ORGANIZATION:
        void getOrganizationSessionHandler();
        break;
      case RefreshSessionCode.USER_AND_ORGANIZATION:
        void getUserSession();
        void getOrganizationSessionHandler();
        break;
      default:
        break;
    }
  });

  useCustomEventListener(CustomEvents.UPDATE_CHAT_USERS_SUB_GROUPS, () => {
    void updateChatUsersSubGroups({ skipRefreshSession: true });
  });

  return null;
};
