import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { ListNames, ListVariant, StickyListProps } from 'components/StickyList/types';
import { LinkButton } from 'components/ui/Buttons/LinkButton';
import { Select } from 'components/ui/Select/Select';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { useRequestsLogic } from 'pages/Requests/output/useRequestsLogic';
import { languageSelector } from 'state/recoilState';
import { holidaysYearsOptionsSelector, ParsedHoliday } from 'state/settings';
import { LazyComponentType } from 'utils/custom.types';
import { dateTime } from 'utils/dateTime';
import { SettingsGroup } from '../../SettingsGroup/SettingsGroup';
import { useHolidays } from '../hooks/useHolidays';

const LazyStickyList = React.lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<ParsedHoliday>>;

export const Holidays = (): React.ReactElement => {
  useLingui();
  const holidaysYearsOptions = useRecoilValue(holidaysYearsOptionsSelector);
  const language = useRecoilValue(languageSelector);
  const navigate = useAppNavigate();
  const { parsedHolidays, pickedHolidayYear, setPickedHolidayYear } = useHolidays();
  const {
    displayer: { displayDate },
  } = useRequestsLogic();
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const selectYearRef = useRef<HTMLInputElement | null>(null);

  const handleDelete = useCallback(
    (id: string) => {
      navigate(`${TO_REL.DELETE_HOLIDAY_MODAL[language]}/${id}`);
    },
    [language, navigate],
  );

  const handleOnRowClick = useCallback(
    (id: string) => {
      navigate(`${TO_REL.EDIT_HOLIDAY_MODAL[language]}/${id}`);
    },
    [navigate, language],
  );

  const handleOnBlur = useCallback(() => {
    if (selectYearRef.current && selectYearRef.current.value) {
      setPickedHolidayYear(selectYearRef.current.value);
    }
  }, [setPickedHolidayYear]);

  const importGoogleDisabled = useMemo(() => {
    const currentYear = dateTime(undefined, { utc: true }).get('year');

    if (+pickedHolidayYear === currentYear || +pickedHolidayYear === currentYear + 1) {
      return false;
    }

    return true;
  }, [pickedHolidayYear]);

  const columns: StickyListProps<ParsedHoliday>['columns'] = useMemo(
    () => [
      {
        key: 'dateUnix',
        title: t({ id: 'global.date', message: 'Date' }),
        sortableValue: (date: ParsedHoliday['dateUnix']) => `${date}`,
        customCellRenderer: (dateUnix: ParsedHoliday['dateUnix']) => {
          const date = displayDate({ dateUnix, isDateBound: true });

          return <TextEllipsis title={date}>{date}</TextEllipsis>;
        },
        columnGrow: 1,
      },
      {
        key: 'name',
        title: t({ id: 'global.forms.name', message: 'Name' }),
        sortableValue: (name: ParsedHoliday['name']) => `${name}`,
        customCellRenderer: (name: ParsedHoliday['name']) => <TextEllipsis title={name}>{name}</TextEllipsis>,
        columnGrow: 2,
      },
      {
        key: ['id', 'isNonEditable'],
        width: '40px',
        sx: { overflow: 'visible' },
        customCellRenderer: ([id, isDefault]: [ParsedHoliday['id'], ParsedHoliday['isNonEditable']]) =>
          !isDefault && (
            <Icon
              type="delete"
              wrapperSx={{ '&:hover': { cursor: 'pointer' } }}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(id);
              }}
            />
          ),
      },
    ],
    [displayDate, handleDelete],
  );

  return (
    <SettingsGroup variant="noGap" sx={{ bg: 'settings.holidays', borderRadius: 'sm' }}>
      <SettingsGroup.Header
        headerWithList
        sx={{
          gap: 2,
          alignItems: 'center',
          ...(isSmartphoneBreakpoint && { flexWrap: 'wrap' }),
        }}
      >
        <Select
          ref={selectYearRef}
          onBlur={handleOnBlur}
          id="year"
          placeholder={t({ id: 'global.year', message: 'Year' })}
          size="xs"
          options={holidaysYearsOptions}
          defaultValue={pickedHolidayYear}
          sx={{ maxWidth: '80px' }}
        />

        <Flex sx={{ gap: 2, ...(!isSmartphoneBreakpoint && { ml: 'auto' }) }}>
          <LinkButton
            size="sm"
            shape="rounded"
            variant="grey"
            replace
            prependWith={<Icon type="download" />}
            to={`${TO_REL.IMPORT_HOLIDAYS_MODAL[language]}/${pickedHolidayYear}`}
            disabled={importGoogleDisabled}
          >
            <Trans id="settings.holidays.import_from_google">Import from Google</Trans>
          </LinkButton>
          <LinkButton
            size="sm"
            shape="rounded"
            variant="primary"
            prependWith={<Icon type="plus" />}
            to={`${TO_REL.ADD_HOLIDAY_MODAL[language]}`}
          >
            {t({ id: 'add' })}
          </LinkButton>
        </Flex>
      </SettingsGroup.Header>

      <SettingsGroup.Body withHeaderList sx={{ minHeight: '360px' }}>
        <React.Suspense
          fallback={
            <Flex sx={{ justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <LoadingSpinnerCss size={4} />
            </Flex>
          }
        >
          <LazyStickyList
            name={ListNames.SETTINGS_HOLIDAYS}
            variant={ListVariant.darkerOne}
            list={parsedHolidays || []}
            columns={columns}
            style={{ paddingRight: '0.75rem', paddingBottom: '0.75rem' }}
            mobileWidth={626}
            showHeader
            onRowClick={handleOnRowClick}
          />
        </React.Suspense>
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
