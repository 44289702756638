// FIXME: Add hostname identification for inewi and tt24

export const images = {
  camera: {
    '1x': 'https://cdn.inewi.pl/images/_assets/camera.png',
    '2x': 'https://cdn.inewi.pl/images/_assets/camera@2x.png 2x',
    '3x': 'https://cdn.inewi.pl/images/_assets/camera@3x.png 3x',
  },
  location: {
    '1x': 'https://cdn.inewi.pl/images/_assets/location.png',
    '2x': 'https://cdn.inewi.pl/images/_assets/location@2x.png 2x',
    '3x': 'https://cdn.inewi.pl/images/_assets/location@3x.png 3x',
  },
};
