import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useFormContext } from 'react-hook-form';

import { DateTimeKind, RequestActionType } from 'api/actions/requests/requestsActions.types';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { EDIT_REQUEST_DEBOUNCE_TIME } from 'constants/requests';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useMemoCompare } from 'hooks/useMemoCompare/useMemoCompare';
import { MinimizeModalAddRequest } from 'state/modal';
import { ParsedEventToUpdate } from 'state/requests';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';
import { useMainFormEdit } from '../../../../../hooks/useMainFormEdit';
import { useTimeOffFormState } from '../hooks/useTimeOffFormState';

export const EditionObserver = (): React.ReactElement => {
  const {
    modifyRequest: { modifyRequestData },
    requestToEdit: { selectedRequestToEdit },
    requestFormRestored: { editRequestRestored },
    setRequestFormRestored,
  } = useAddRequest();
  const { isForDayType, isDurationType } = useTimeOffFormState();
  const { handleMainFormValuesForRequestToEdit, updatePrevEvent } = useMainFormEdit();

  const [isInTransition, startTransition] = useTransition();
  const [editionFirstPartCompleted, setEditionFirstPartCompleted] = useState<boolean>(false);
  const selectedTimeOffDataRef = useRef<ParsedEventToUpdate | null>(null);
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddRequest>();
  const storedMinimizedModalDetails = useRef(!!minimizedModalDetails?.modalDetails.requestToEdit);
  const editRequestRestoredRef = useCallbackRef(editRequestRestored);

  const { watch, setValue } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  const handleSetHookFormValueFirstPart = useMemo(
    () =>
      _.debounce((event: ParsedEventToUpdate) => {
        if (storedMinimizedModalDetails.current && !editRequestRestoredRef.current) {
          setRequestFormRestored((prev) => ({ ...prev, editRequestRestored: true }));
          updatePrevEvent(event);
          return;
        }

        startTransition(() => {
          if (event && actionTypeWatch !== RequestActionType.Create) {
            const {
              dateTimeDetails: { kind },
              id,
              eventUpdateDetails: { typeId },
            } = event;

            handleMainFormValuesForRequestToEdit.current(event);

            setValue('targetEventId', id);
            setValue('details.typeId', typeId);
            if (actionTypeWatch !== RequestActionType.Remove) {
              setValue('isDateBound', kind === DateTimeKind.Local);
              setEditionFirstPartCompleted(true);
            }
          }
        });
      }, EDIT_REQUEST_DEBOUNCE_TIME),
    [
      editRequestRestoredRef,
      setRequestFormRestored,
      updatePrevEvent,
      actionTypeWatch,
      handleMainFormValuesForRequestToEdit,
      setValue,
    ],
  );

  /**
   *   Values are set in two parts becouse calendar needs to have time to upade timers and the way unixes are set.
   *   To do it isDateBound responsible for unixes is set first, after it dual calendar updates timers, and after that it is safe to update dates and make sure it returns correct unix.
   */
  const handleSetHookFormValueSecondPart = useCallback(() => {
    startTransition(() => {
      if (editionFirstPartCompleted && selectedTimeOffDataRef.current) {
        const {
          dateTimeDetails: { dates, forDay, duration },
          eventUpdateDetails: { ignoreWeekends, ignoreHolidays },
        } = selectedTimeOffDataRef.current;

        setValue('details.ignoreWeekends', ignoreWeekends);
        setValue('details.ignoreHolidays', ignoreHolidays);
        if (dates) {
          const [startUnix, endUnix] = dates;

          if (isForDayType || isDurationType) {
            setValue('dateTimeDetails.dates', [
              startUnix,
            ] as unknown as AddRequestExtendedFormContext['dateTimeDetails']['dates']); // backend required exception
          } else if (!endUnix) {
            setValue('dateTimeDetails.dates', [startUnix, startUnix]);
          } else {
            setValue('dateTimeDetails.dates', dates);
          }
        }

        if (isDurationType) {
          setValue('dateTimeDetails.duration', duration);
        }

        if (isForDayType) {
          setValue('dateTimeDetails.forDay', forDay);
        }
        setEditionFirstPartCompleted(false);
      }
    });
  }, [isDurationType, editionFirstPartCompleted, isForDayType, setValue]);

  const selectedTimeOffDataMemo = useMemoCompare(selectedRequestToEdit);

  useEffect(() => {
    if (selectedTimeOffDataMemo) {
      handleSetHookFormValueFirstPart(selectedTimeOffDataMemo);
    }
  }, [handleSetHookFormValueFirstPart, selectedTimeOffDataMemo]);

  useEffect(() => {
    if (modifyRequestData) {
      handleSetHookFormValueFirstPart(modifyRequestData);
    }
  }, [handleSetHookFormValueFirstPart, modifyRequestData]);

  useEffect(() => {
    handleSetHookFormValueSecondPart();
  }, [handleSetHookFormValueSecondPart]);

  useEffect(() => {
    selectedTimeOffDataRef.current = selectedRequestToEdit || modifyRequestData;
    setEditionFirstPartCompleted(false);
  }, [modifyRequestData, selectedRequestToEdit]);

  return <>{isInTransition && <LoadingOverlay sx={{ zIndex: 'base' }} />}</>;
};
