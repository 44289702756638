import { DefaultRole } from 'api/actions/organizationSession/organizationSessionActions.types';
import { FetchUserSessionResponse, PermissionsFeaturesTypes } from 'api/actions/userSession/userSessionActions.types';
import { getDefaultFeatures } from 'utils/getDefaultFeatures';

import { SupportedLanguages } from './supportedLanguages';

export const LANGUAGE_DEFAULT_VALUE = SupportedLanguages.en;

export const USER_SESSION_DEFAULT_VALUE: FetchUserSessionResponse = {
  dateFormat: 0,
  hasPassword: true,
  hideDetailedRequestTypes: true,
  marketingAgreement: false,
  nameDisplayOrder: 0,
  permissions: {
    features: [
      { type: 0, inheritFromTag: false, inheritedValue: false, value: true },
      { type: 1, inheritFromTag: false, inheritedValue: false, value: true },
      { type: 2, inheritFromTag: false, inheritedValue: false, value: true },
      { type: 3, inheritFromTag: false, inheritedValue: false, value: true },
      { type: 5, inheritFromTag: false, inheritedValue: false, value: false },
      { type: 7, inheritFromTag: false, inheritedValue: false, value: false },
      { type: 8, inheritFromTag: false, inheritedValue: false, value: false },
      { type: 9, inheritFromTag: false, inheritedValue: false, value: false },
      { type: 10, inheritFromTag: false, inheritedValue: false, value: false },
      { type: 11, inheritFromTag: false, inheritedValue: false, value: false },
    ],
    isHeadAdmin: true,
    modules: [
      { type: 0, value: true },
      { type: 1, value: true },
      { type: 2, value: true },
      { type: 3, value: true },
    ],
    systemManagement: [
      { type: 0, value: true },
      { type: 1, value: true },
      { type: 2, value: true },
      { type: 3, value: true },
      { type: 4, value: true },
      { type: 5, value: true },
    ],
  },
  personId: '',
  preferredLanguage: LANGUAGE_DEFAULT_VALUE,
  qrCodeUrl: '',
  showTutorial: true,
  timeFormat: 0,
  timeZoneId: '',
  viewsSettings: {},
};

//
// Tag
//
export const FEATURES_DEFAULT_VALUE = getDefaultFeatures(PermissionsFeaturesTypes, true, [
  PermissionsFeaturesTypes.CalendarTagsWideView,
  PermissionsFeaturesTypes.TimeTrackingTagsWideView,
]);

export const DEFAULT_ROLES_IDS = Object.keys(DefaultRole).filter((role) => +role);
