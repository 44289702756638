/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { useCallback, useRef } from 'react';

type BufferItem<T> = { promise: Promise<T>; resolve: () => void; reject: () => void; id: string };

export const usePromiseBuffer = <T>() => {
  const promisesBufferRef = useRef<BufferItem<T>[]>([]);

  const removeFromBuffer = useCallback((bufferItem: BufferItem<T>) => {
    _.pull(promisesBufferRef.current, bufferItem);
  }, []);

  const addPromiseToBuffer = useCallback(
    (promise: Promise<T>) => {
      let resolveFn;
      let rejectFn;

      const promiseController = new Promise<T>((resolve, reject) => {
        resolveFn = resolve;
        rejectFn = reject;
      });

      const bufferItem = { promise: promiseController, resolve: resolveFn!, reject: rejectFn!, id: _.uniqueId() };

      promisesBufferRef.current.push(bufferItem);
      // console.log('promisesBufferRef.current: ', promisesBufferRef.current.length);

      // Wrap original promise with controller so we gain control over original one
      const wrappedPromise = Promise.race([promise, promiseController]);

      // Remove promise from buffer once the race is settled
      wrappedPromise
        .then(() => {
          removeFromBuffer(bufferItem);
        })
        .catch(() => {
          removeFromBuffer(bufferItem);
        });

      return wrappedPromise;
    },
    [removeFromBuffer],
  );

  const cancelAllPromises = useCallback(() => {
    promisesBufferRef.current.forEach((promise) => {
      promise.reject();
    });
  }, []);

  return {
    addPromiseToBuffer,
    cancelAllPromises,
    bufferSize: promisesBufferRef.current.length,
  };
};
