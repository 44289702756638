import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import _ from 'lodash';
import { Suspense, lazy, useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { ImportState, ParsedImportedEmployee } from 'api/actions/employees/employeesActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Badge } from 'components/Badge/Badge';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { ControlledModal, openModal } from 'components/Modal/Modal';
import {
  CustomRowVariant,
  CustomRowVariantGenerator,
  ListNames,
  ListVariant,
  RowTooltipGenerator,
  SortingOrder,
  StickyListProps,
} from 'components/StickyList/types';
import { Tag } from 'components/Tag/Tag';
import { CopyToClipboardText } from 'components/ui/CopyToClipboardText';
import { FullName } from 'components/utils/FullName';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { useMount } from 'hooks/useMount/useMount';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { importedEmployeesInfoAtom, importedEmployeesMapSelector, tagsMapSelector } from 'state/employees';
import { sortingStateAtomFamily } from 'state/list';
import { LazyComponentType } from 'utils/custom.types';
import { getTagByTagName } from '../utils/getTagByTagName';

import { EditEmployeePhoneNumberModal } from './modals/EditEmployeePhoneNumberModal';

const LazyStickyList = lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<ParsedImportedEmployee>>;

export const IMPORT_EDIT_EMPLOYEE_PHONE_NUMBER = 'IMPORT_EDIT_EMPLOYEE_PHONE_NUMBER';

type Props = {
  isLoading: boolean;
};

export const ImportStep2Modal = ({ isLoading }: Props): React.ReactElement => {
  useLingui();
  const employeesMap = useRecoilValue(importedEmployeesMapSelector);
  const setSortingState = useSetRecoilState(sortingStateAtomFamily(ListNames.TEAM_IMPORT_EMPLOYEES));
  const [importedEmployees, setImportedEmployees] = useRecoilState(importedEmployeesInfoAtom);
  const tags = useRecoilValue(tagsMapSelector);
  const getFullName = useNameDisplayOrder();

  const isEmployeeUncheckableValidator = useMemo(
    () => (id: string) => {
      let isInvalid = false;
      employeesMap.forEach((employee) => {
        if (employee.id === id && !_.isEmpty(employee.phoneNumber) && !isValidPhoneNumber(employee.phoneNumber || '')) {
          isInvalid = true;
        }
      });
      return isInvalid;
    },
    [employeesMap],
  );

  const employeePhoneNumberValidator = useMemo(
    () => (id: string) =>
      Array.from(employeesMap.values()).find(
        (employee) =>
          employee.id === id && !_.isEmpty(employee.phoneNumber) && !isValidPhoneNumber(employee.phoneNumber || ''),
      ),
    [employeesMap],
  );

  const invalidEmployeePresent = useMemo(
    () => Array.from(employeesMap).find(([id]) => employeePhoneNumberValidator(id)),
    [employeePhoneNumberValidator, employeesMap],
  );

  useMount(() => {
    if (invalidEmployeePresent) {
      addSnackbar({
        message: t({
          id: 'team.import.invalid_phone_numbers',
          message:
            'Invalid phone numbers detected. To allow import click on highlighted rows and enter valid phone number.',
        }),
        variant: 'warning',
        duration: 5000,
      });
    }
    setSortingState(null); //  #jotai -> think through how to avoid saving to localStorage in PersistenceObserver
  });

  const columns: StickyListProps<ParsedImportedEmployee>['columns'] = [
    {
      title: t({ id: 'team.list.employee_name' }),
      key: ['firstName', 'surname', 'state', 'id'],

      sortableValue: ([firstName, surname]: [ParsedImportedEmployee['firstName'], ParsedImportedEmployee['surname']]) =>
        getFullName(firstName, surname),
      customCellRenderer: (
        item: [ParsedImportedEmployee['firstName'], ParsedImportedEmployee['surname'], ParsedImportedEmployee['state']],
      ) => (
        <TextEllipsis title={getFullName(item[0], item[1])}>
          <Flex>
            <FullName firstName={item[0]} surname={item[1]} />
            {item[2] === ImportState.Merged ? (
              <Badge variant="warning" size="sm" sx={{ marginLeft: 2 }}>
                <Trans id="team.list.merged">Merged</Trans>
              </Badge>
            ) : null}
          </Flex>
        </TextEllipsis>
      ),
      columnGrow: 2,
    },
    {
      title: t({ id: 'team.list.role_tags' }),
      key: 'tagName',
      sortableValue: (tagNames: ParsedImportedEmployee['tagName']) => `${_.map(tagNames, (item) => item).join(' ')}`,
      customCellRenderer: (tagName: ParsedImportedEmployee['tagName']) => {
        if (tagName) {
          const tag = getTagByTagName(tagName, tags);
          return (
            <Tag
              key={tagName}
              color={UserSelectableColor[tag.color]}
              variant={tag.isInformational ? 'informational' : 'default'}
            >
              {tagName}
            </Tag>
          );
        }
        return <></>;
      },
      columnGrow: 1,
    },
    {
      title: t({ id: 'team.list.email' }),
      key: 'email',
      sortableValue: (email: ParsedImportedEmployee['email']) => `${email}`,
      customCellRenderer: (email: ParsedImportedEmployee['email']) => (
        <CopyToClipboardText ellipsis>{email}</CopyToClipboardText>
      ),
      columnGrow: 2,
    },
    {
      title: t({ id: 'team.list.phone' }),
      key: ['id', 'phoneNumber'],
      sortableValue: ([id, phoneNumber]: [ParsedImportedEmployee['id'], ParsedImportedEmployee['phoneNumber']]) =>
        `${employeePhoneNumberValidator(id)}${phoneNumber}`,
      customCellRenderer: ([, phoneNumber]: [ParsedImportedEmployee['id'], ParsedImportedEmployee['phoneNumber']]) => (
        <CopyToClipboardText ellipsis>{phoneNumber}</CopyToClipboardText>
      ),
      columnGrow: 1,
    },
    {
      title: t({ id: 'team.import_modal.address', message: `Address` }),
      key: 'address',
      sortableValue: (address: ParsedImportedEmployee['address']) =>
        `${address?.street}${address?.city}${address?.postalCode}`,
      customCellRenderer: (address: ParsedImportedEmployee['address']) => (
        <TextEllipsis>
          {address?.street && `${address?.street},`}&nbsp;{address?.city && `${address?.city},`}&nbsp;
          {address?.postalCode && `${address?.postalCode}`}
        </TextEllipsis>
      ),
      columnGrow: 2,
    },
    {
      title: t({ id: 'team.list.employee_id' }),
      key: 'customEmployeeId',
      sortableValue: (employeeId: ParsedImportedEmployee['customEmployeeId']) => `${employeeId}`,
      customCellRenderer: (employeeId: ParsedImportedEmployee['customEmployeeId']) => (
        <TextEllipsis>{`${employeeId}`}</TextEllipsis>
      ),
      columnGrow: 1,
    },
  ];

  const invalidTeammateRowGenerator: CustomRowVariantGenerator = useCallback(
    (id: string) => (employeePhoneNumberValidator(id) ? CustomRowVariant.error : undefined),
    [employeePhoneNumberValidator],
  );

  const rowTooltipGenerator: RowTooltipGenerator = useCallback(
    (id: string) =>
      employeePhoneNumberValidator(id) ? t({ id: 'phone_input.invalid', message: 'Invalid phone number' }) : undefined,
    [employeePhoneNumberValidator],
  );

  const handleOpenModal = async (id: string) => {
    const employeeToEdit = importedEmployees?.importedEmployees.find((employee) => employee.id === id);
    const resolved = await openModal<string | undefined>(IMPORT_EDIT_EMPLOYEE_PHONE_NUMBER, {
      phoneNumber: employeeToEdit?.employeeInfo.phoneNumber,
      firstName: employeeToEdit?.employeeInfo.firstName,
      surname: employeeToEdit?.employeeInfo.surname,
      tagName: employeeToEdit?.tagName,
    });

    if (!resolved) return;

    if (importedEmployees) {
      const updatedEmployees = importedEmployees.importedEmployees.map((employee) => {
        if (employee.id === id) {
          return { ...employee, employeeInfo: { ...employee.employeeInfo, phoneNumber: resolved } };
        }
        return employee;
      });

      setImportedEmployees((prev) => ({
        ...prev,
        importedEmployees: updatedEmployees,
      }));
    }
  };

  return (
    <>
      <Text>
        <Trans id="team.import_modal.select_import_employees">Select which employees do you wish to import.</Trans>
      </Text>
      <Flex sx={{ flexGrow: 1, mx: -4, overflow: ['auto', null, null, 'unset'], position: 'relative' }}>
        {isLoading && <LoadingOverlay sx={{ zIndex: 'base' }} />}
        <Suspense
          fallback={
            <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
              <LoadingSpinnerCss size={4} />
            </Flex>
          }
        >
          <LazyStickyList
            name={ListNames.TEAM_IMPORT_EMPLOYEES}
            list={employeesMap}
            columns={columns}
            showHeader
            select="checkbox"
            variant={ListVariant.inverted}
            style={{
              paddingLeft: 24,
              paddingRight: 24,
            }}
            mobileWidth={1024}
            rowTooltipGenerator={rowTooltipGenerator}
            onRowClick={(id) => void handleOpenModal(id)}
            isRowUncheckableValidator={isEmployeeUncheckableValidator}
            customRowVariantGenerator={invalidTeammateRowGenerator}
            {...(invalidEmployeePresent && {
              defaultSortingState: { columnKey: ['id', 'phoneNumber'], order: SortingOrder.ASC },
            })}
          />
        </Suspense>
      </Flex>
      <ControlledModal id={IMPORT_EDIT_EMPLOYEE_PHONE_NUMBER} size="xs" sx={{ minHeight: 'auto' }}>
        <EditEmployeePhoneNumberModal />
      </ControlledModal>
    </>
  );
};
