import { useRecoilState, useResetRecoilState } from 'recoil';

import {
  addEventsDisabledSaveAtom,
  addEventsFormRestoredAtom,
  addEventsGetFormValuesAtom,
  addEventsLoadingSaveAtom,
  addEventsStepAtom,
} from 'state/clockLog';

export const useAddEvents = () => {
  const [step, setStep] = useRecoilState(addEventsStepAtom);
  const [getFormValues, setGetFormValues] = useRecoilState(addEventsGetFormValuesAtom);
  const [isLoadingSave, setIsLoadingSave] = useRecoilState(addEventsLoadingSaveAtom);
  const [isSaveDisabled, setIsSaveDisabled] = useRecoilState(addEventsDisabledSaveAtom);
  const [addEventsFormRestored, setAddEventsFormRestored] = useRecoilState(addEventsFormRestoredAtom);
  const resetAddEventsFormRestored = useResetRecoilState(addEventsFormRestoredAtom);
  const resetStep = useResetRecoilState(addEventsStepAtom);
  const resetGetFormValues = useResetRecoilState(addEventsGetFormValuesAtom);

  return {
    step,
    setStep,
    getFormValues,
    setGetFormValues,
    isLoadingSave,
    setIsLoadingSave,
    isSaveDisabled,
    setIsSaveDisabled,
    resetStep,
    addEventsFormRestored,
    setAddEventsFormRestored,
    resetAddEventsFormRestored,
    resetGetFormValues,
  };
};
