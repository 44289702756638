import React from 'react';
import { Routes } from 'react-router-dom';

import { Modal } from 'components/Modal/output/Modal';
import { DRAWER_ROUTES } from 'constants/memoryRoutes';
import { renderSameElementRoutes } from 'routing/utils/renderSameElementRoutes';

import { LocationRequiredErrorModal } from './modals/LocationRequiredErrorModal';

export const LocationPermissionRoutes = (): React.ReactElement => (
  <Routes>
    {renderSameElementRoutes(
      {
        element: (
          <Modal size="sm" className="onboarding-worktimetracker-2">
            <LocationRequiredErrorModal />
          </Modal>
        ),
      },
      [DRAWER_ROUTES.LOCATION_PERMISSION, DRAWER_ROUTES.DRAWER__TIME_TRACKER__LOCATION_PERMISSION],
    )}
  </Routes>
);
