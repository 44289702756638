import _ from 'lodash';
import { MutableRefObject } from 'react';
import { atom, selector } from 'recoil';

import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';

export type CameraImageBufferState = {
  imageBufferCanvasCallback: () => MutableRefObject<HTMLCanvasElement | null> | null;
  imageBufferReady: boolean;
  getImageData?: () => Promise<{ imageData: ImageData; imageUrl: string; removedSnapshot?: string } | undefined>;
  getImageBlob?: () => Promise<Blob | undefined>;
  getBlob?: () => string[];
  blob?: string[];
};

export const cameraImageBufferAtom = atom<CameraImageBufferState>({
  key: 'kiosk__cameraImageBuffer',
  default: {
    imageBufferReady: false,
    imageBufferCanvasCallback: () => null,
  },
});

type CameraImageBufferReadyState = Pick<CameraImageBufferState, 'imageBufferReady' | 'imageBufferCanvasCallback'>;

export const cameraImageBufferReadySelector = selector<CameraImageBufferReadyState>({
  key: 'kiosk__cameraImageBufferReady',
  get: ({ get }) => {
    const { imageBufferReady, imageBufferCanvasCallback } = get(cameraImageBufferAtom);

    return { imageBufferReady, imageBufferCanvasCallback };
  },
  set: ({ get, set }, newState) => {
    const { imageBufferReady, imageBufferCanvasCallback } = get(cameraImageBufferAtom);

    if (!isRecoilDefaultValue(newState) && !_.isEqual({ imageBufferReady, imageBufferCanvasCallback }, newState)) {
      set(cameraImageBufferAtom, (prevState) => ({ ...prevState, ...newState }));
    }
  },
});
