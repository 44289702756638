import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { MemoizedDualCalendar } from 'components/ui/DualCalendar/DualCalendar';
import { Switch } from 'components/ui/Switch';
import { REQUEST_DEFAULT_END_TIME, REQUEST_DEFAULT_START_TIME } from 'constants/requests';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { minCreateDateUnixSelector } from 'state/userSession';
import { dateTime, getDateWithTimeInSecondsUnix } from 'utils/dateTime';
import { dualCalendarRegisterOptionsFactory } from 'utils/dualCalendarRegisterOptionsFactory';
import { useAddRequest } from '../../../../../hooks/useAddRequest';

export const Calendar = (): React.ReactElement => {
  useLingui();
  const minCreateDateUnix = useRecoilValue(minCreateDateUnixSelector);
  const [forDayChangedToFalse, setForDayChangedToFalse] = useState<boolean>(false);

  const {
    requestLimits: { resetRequestLimits },
  } = useAddRequest();

  const {
    register,
    watch,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<{ dateTimeDetails: { dates: number[] }; isDateBound: boolean; calendarInternalError: string }>();

  const forWholeDayWatch = watch('isDateBound');
  const calendarWatch = watch('dateTimeDetails.dates');
  const calendarWatchRef = useCallbackRef(calendarWatch);

  const setCalendarErrorCallback = useCallback(() => {
    setError('calendarInternalError', {
      type: 'required',
    });
  }, [setError]);

  const clearCalendarErrorCallback = useCallback(() => {
    clearErrors('calendarInternalError');
  }, [clearErrors]);

  const handleDateBoundSwitchOnClick = useCallback(() => {
    if (calendarWatch) {
      if (forWholeDayWatch) {
        setForDayChangedToFalse(true);
      } else {
        setValue('dateTimeDetails.dates', calendarWatch);
      }
    }
    clearErrors('dateTimeDetails.dates');
  }, [calendarWatch, clearErrors, forWholeDayWatch, setValue]);

  const handleForDaySwitchChangedToFalse = useCallback(() => {
    if (forDayChangedToFalse && calendarWatchRef.current) {
      const [startDateUnix] = calendarWatchRef.current;
      const newStartDateUnix = getDateWithTimeInSecondsUnix(startDateUnix, REQUEST_DEFAULT_START_TIME, true);
      const newEndDateUnix = getDateWithTimeInSecondsUnix(startDateUnix, REQUEST_DEFAULT_END_TIME, true);

      setValue('dateTimeDetails.dates', [newStartDateUnix, newEndDateUnix]);
      setForDayChangedToFalse(false);
    }
  }, [calendarWatchRef, forDayChangedToFalse, setValue]);

  useEffect(() => {
    handleForDaySwitchChangedToFalse();
  }, [handleForDaySwitchChangedToFalse]);

  return (
    <MemoizedDualCalendar
      {...register('dateTimeDetails.dates', dualCalendarRegisterOptionsFactory({ isRange: true }))}
      showStartTime={!forWholeDayWatch}
      showEndTime={!forWholeDayWatch}
      error={!!errors?.dateTimeDetails?.dates}
      errorMessage={errors.dateTimeDetails?.dates?.message}
      onValidError={setCalendarErrorCallback}
      onClearError={clearCalendarErrorCallback}
      range={forWholeDayWatch}
      breakthroughDay
      resetDays
      onResetDays={resetRequestLimits}
      {...(minCreateDateUnix && { minDate: dateTime(minCreateDateUnix) })}
      defaultTimesOnDayPick={[REQUEST_DEFAULT_START_TIME, REQUEST_DEFAULT_END_TIME]}
    >
      <Switch
        {...register('isDateBound')}
        label={t({
          id: 'requests.add_request.whole_day',
          message: 'For a whole day',
        })}
        additionalInfo={
          <Text>
            <Trans id="requests.add_request.whole_day_description">
              Based on users schedule or a default workday duration
            </Trans>
          </Text>
        }
        size="sm"
        bold
        onClick={handleDateBoundSwitchOnClick}
      />
    </MemoizedDualCalendar>
  );
};
