import { Trans } from '@lingui/macro';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-fetching-library';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, FlexProps } from 'theme-ui';

import {
  NormalPayrollPeriod,
  OvertimePayrollPeriod,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { fetchReportForIdsAction } from 'api/actions/reports/reportsActions';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { YourStatsPeriod, yourStatsAtom, yourStatsPeriodAtom } from 'state/home';
import { organizationOvertimePeriodSelector, organizationPayrollPeriodSelector } from 'state/organizationSession';
import { CustomEvents } from 'utils/customEvents';
import { dateTime } from 'utils/dateTime';
import { HomeBox } from '../HomeBox/HomeBox';

import { YourStatsPeriodSelect } from './components/YourStatsPeriodSelect';
import { YourStatsTimers } from './components/YourStatsTimers';

type Props = {
  userId: string;
} & FlexProps;

export const YourStats: FC<Props> = ({ userId, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);

  const yourStatsPeriod = useRecoilValue(yourStatsPeriodAtom);
  const payrollPeriod = useRecoilValue(organizationPayrollPeriodSelector);
  const overtimePeriod = useRecoilValue(organizationOvertimePeriodSelector);
  const setYourStats = useSetRecoilState(yourStatsAtom);

  const date = useMemo(() => dateTime(undefined, { utc: true }), []);

  const getDatesPeriod = useCallback(
    (period: OvertimePayrollPeriod) => {
      const currentMonth = date.get('month') + 1;
      const currentPeriod = Math.ceil(currentMonth / period);

      const monthsToAdd = (currentPeriod - 1) * period;
      const startDate = date.startOf('year').startOf('day').add(monthsToAdd, 'month');

      const startDateUnix = startDate.unix();
      const endDateUnix = startDate
        .add(period - 1, 'month')
        .endOf('month')
        .startOf('day')
        .unix();

      return { startDateUnix, endDateUnix };
    },
    [date],
  );

  const getDatesMonth = useCallback(
    (addMonths?: number) => {
      const startDateUnix = date.startOf('month').startOf('day').unix();
      const endDateUnix = date
        .add(addMonths || 0, 'month')
        .endOf('month')
        .startOf('day')
        .unix();

      return { startDateUnix, endDateUnix };
    },
    [date],
  );

  const getDatesWeek = useCallback(
    (addWeeks?: number) => {
      const startDateUnix = date.startOf('week').startOf('day').unix();
      const endDateUnix = date
        .add(addWeeks || 0, 'week')
        .endOf('week')
        .startOf('day')
        .unix();

      return { startDateUnix, endDateUnix };
    },
    [date],
  );

  const getDatePayrollPeriod = useCallback(() => {
    if (payrollPeriod === NormalPayrollPeriod.Weeks1) {
      return getDatesWeek();
    }

    if (payrollPeriod === NormalPayrollPeriod.Weeks2) {
      return getDatesWeek(1);
    }

    return getDatesMonth();
  }, [getDatesMonth, getDatesWeek, payrollPeriod]);

  const getDateOvertimePeriod = useCallback(() => {
    switch (overtimePeriod) {
      case OvertimePayrollPeriod.Weeks1:
        return getDatesWeek();
      case OvertimePayrollPeriod.Weeks2:
        return getDatesWeek(1);
      case OvertimePayrollPeriod.Months1:
        return getDatesMonth();
      case OvertimePayrollPeriod.Months2:
      case OvertimePayrollPeriod.Months3:
      case OvertimePayrollPeriod.Months4:
      case OvertimePayrollPeriod.Months6:
      case OvertimePayrollPeriod.Months12:
        return getDatesPeriod(overtimePeriod);
      default:
        return getDatePayrollPeriod();
    }
  }, [getDatePayrollPeriod, getDatesMonth, getDatesPeriod, getDatesWeek, overtimePeriod]);

  const dates = useMemo(() => {
    if (yourStatsPeriod === YourStatsPeriod.PayrollPeriod) {
      return getDatePayrollPeriod();
    }
    if (yourStatsPeriod === YourStatsPeriod.OvertimePeriod) {
      return getDateOvertimePeriod();
    }

    return getDatesMonth();
  }, [getDateOvertimePeriod, getDatePayrollPeriod, getDatesMonth, yourStatsPeriod]);

  const { query } = useQuery(fetchReportForIdsAction({ employeeIds: [userId], ...dates }), false);

  const fetchStats = useCallback(async () => {
    const { payload, error } = await query();

    if (!error && payload) {
      const { employeesData } = payload;

      setYourStats(employeesData);
      setIsLoading(false);
    }
  }, [query, setYourStats]);

  useEffect(() => {
    void fetchStats();
  }, [fetchStats]);

  useCustomEventListener(
    [
      CustomEvents.ACCEPT_REQUEST_MANAGEMENT_SUCCESS_RESPONSE_RECEIVED,
      CustomEvents.ADD_REQUEST_SUCCESS_RESPONSE_RECEIVED,
      CustomEvents.DELETE_REQUESTS_SUCCESS_RESPONSE_RECEIVED,
      CustomEvents.POST_TIME_EVENT_SUCCESS_RESPONSE_RECEIVED,
    ],
    () => {
      void fetchStats();
    },
  );

  return (
    <HomeBox {...props}>
      <HomeBox.Header>
        <HomeBox.Title>
          <Trans id="home.your_stats">Your stats</Trans>
        </HomeBox.Title>
        <YourStatsPeriodSelect />
      </HomeBox.Header>
      <Flex sx={{ minHeight: '200px', position: 'relative', flexDirection: 'column', gap: '0.125rem' }}>
        {isLoading && <LoadingOverlay />}
        <YourStatsTimers />
      </Flex>
    </HomeBox>
  );
};
