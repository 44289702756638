import { useEffect, useRef } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { ListNames } from 'components/StickyList/types';
import { selectAllAtomFamily, selectedRowsAtomFamily, selectedRowsIdsSelectorFamily } from 'state/list';
import {
  pickTeammatesFilterGroupAtom,
  pickTeammatesHiddenEmployeesIdsAtom,
  pickTeammatesSearchFilterValueAtom,
} from 'state/pickTeammates';

export const usePickTeammates = (listName: ListNames, resetOnUnmount?: boolean) => {
  const [selectedTeammatesIds, setSelectedTeammatesIds] = useRecoilState(selectedRowsIdsSelectorFamily(listName));

  const resetSelectedRowsAtomSate = useResetRecoilState(selectedRowsAtomFamily(listName));
  const resetSelectAllAtomState = useResetRecoilState(selectAllAtomFamily(listName));
  const resetPickTeammatesSearchFilterValueAtomSate = useResetRecoilState(pickTeammatesSearchFilterValueAtom);
  const resetPickTeammatesFilterGroupAtomSate = useResetRecoilState(pickTeammatesFilterGroupAtom);
  const resetPickTeammatesHiddenEmployeesIdsAtomSate = useResetRecoilState(pickTeammatesHiddenEmployeesIdsAtom);

  const resetOnUnmountRef = useRef(resetOnUnmount);

  useEffect(
    () => () => {
      if (resetOnUnmountRef.current) {
        resetSelectedRowsAtomSate();
        resetSelectAllAtomState();
        resetPickTeammatesSearchFilterValueAtomSate();
        resetPickTeammatesFilterGroupAtomSate();
        resetPickTeammatesHiddenEmployeesIdsAtomSate();
      }
    },
    [
      resetSelectedRowsAtomSate,
      resetSelectAllAtomState,
      resetPickTeammatesSearchFilterValueAtomSate,
      resetPickTeammatesFilterGroupAtomSate,
      resetPickTeammatesHiddenEmployeesIdsAtomSate,
    ],
  );

  return [selectedTeammatesIds, setSelectedTeammatesIds] as const;
};
