import React from 'react';
import { useRecoilValue } from 'recoil';

import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { hasPermissionToUseCrispSelector } from 'state/chat';

// eslint-disable-next-line no-restricted-imports
import { Chat } from './components/Chat/Chat';
import { ChatCloseObserver } from './components/ChatCloseObserver';
import { ChatWidthObserver } from './components/ChatWidthObserver';
import { CrispProvider } from './components/CrispProvider';

export const ChatProvider = (): React.ReactElement | null => {
  const hasPermissionToUseCrisp = useRecoilValue(hasPermissionToUseCrispSelector);
  const { isInitialized } = useAppPermissions();

  if (!isInitialized) return null;

  return (
    <>
      {hasPermissionToUseCrisp && <CrispProvider />}
      <ChatWidthObserver />
      <Chat />
      <ChatCloseObserver />
    </>
  );
};
