import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { invitationResendAction } from 'api/actions/auth/authActions';
import { InvitationEmailDetails } from 'api/actions/auth/authActions.types';
import { FormCard } from 'components/FormCard/FormCard';
import { Button, LinkButton } from 'components/ui/Buttons';
import { APP_NAME } from 'constants/common';
import { TO } from 'constants/routes';
import { Main } from 'layouts/Authentication/output/Main';
import { languageSelector } from 'state/recoilState';
import { dateTime } from 'utils/dateTime';

import { EmployeeInvitedForm, EmployeeInvitedFormProps } from './components/EmployeeInvitedForm';

const ReminderSent = (): React.ReactElement => (
  <>
    <FormCard.Header>
      <FormCard.Title>
        <Trans id="sign_up.employee.reminder_sent.lead.header">The reminder has been sent! 👍</Trans>
      </FormCard.Title>
    </FormCard.Header>

    <FormCard.Lead>
      <Trans id="sign_up.employee.reminder_sent.lead.text">
        You should soon receive an invitation from your company's <span>{APP_NAME}</span> administrator.
      </Trans>
    </FormCard.Lead>
  </>
);

const Invited = ({ sentAt, emailTitle }: Omit<InvitationEmailDetails, 'email'>): React.ReactElement => (
  <>
    <FormCard.Header>
      <FormCard.Title>
        <Trans id="sign_up.employee.invited.lead.header">This e-mail address has already been invited.</Trans>
      </FormCard.Title>
    </FormCard.Header>

    <Flex sx={{ gap: 4, flexDirection: 'column', mb: 4 }}>
      <Trans id="sign_up.employee.invited.lead.text">
        <FormCard.Lead>
          Look for a message in your mailbox titled <strong>{emailTitle}</strong> from{' '}
          <strong>{dateTime(sentAt).format('LLL')}</strong>.
        </FormCard.Lead>

        <FormCard.Lead>Don't forget to check SPAM, as sometimes messages can land in there.</FormCard.Lead>
      </Trans>
    </Flex>
  </>
);

export const EmployeeInvited = (): React.ReactElement => {
  const { state } = useLocation();

  const [isSent, setIsSent] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const language = useRecoilValue(languageSelector);

  const { mutate } = useMutation(invitationResendAction);

  const onInvitedSubmit: EmployeeInvitedFormProps['onSubmit'] = useCallback(
    async (body) => {
      if (!state?.signUp?.invitedEmployee?.email) {
        return false;
      }

      const { error: submitError } = await mutate({ ...body, email: state.signUp.invitedEmployee.email });

      if (!submitError) {
        setIsSent(true);
        return true;
      }

      return false;
    },
    [state?.signUp?.invitedEmployee?.email, mutate],
  );

  if (!state?.signUp?.inProgress) {
    return <Navigate to={TO.SIGN_UP[language]} />;
  }

  if (!state?.signUp?.invitedEmployee) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'sign_up.page_title', message: `Sign up - ${APP_NAME}` }))}</title>
      </Helmet>

      <Main>
        <FormCard size="md">
          {isSent && (
            <>
              <ReminderSent />
              <FormCard.Footer sx={{ justifyContent: 'center' }}>
                <LinkButton to={TO.SIGN_IN[language]} variant="grey" shape="rounded" size="lg">
                  {t({ id: 'password_recovery.form.back_button' })}
                </LinkButton>
              </FormCard.Footer>
            </>
          )}
          {!isSent &&
            (showForm ? (
              <>
                <EmployeeInvitedForm onSubmit={onInvitedSubmit} onBack={() => setShowForm(false)} />
              </>
            ) : (
              <>
                <Invited
                  sentAt={state.signUp?.invitedEmployee?.sentAt}
                  emailTitle={state.signUp?.invitedEmployee?.emailTitle}
                />
                <FormCard.Footer>
                  <LinkButton to={TO.SIGN_IN[language]} variant="minimal" shape="rounded" size="lg">
                    {t({ id: 'password_recovery.form.back_button' })}
                  </LinkButton>

                  <Button variant="primary" shape="rounded" size="lg" onClick={() => setShowForm(true)}>
                    <Trans id="sign_up.employee.invited.form.submit_button">Send a re-invite request</Trans>
                  </Button>
                </FormCard.Footer>
              </>
            ))}
        </FormCard>
      </Main>
    </>
  );
};
