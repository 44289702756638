import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex } from 'theme-ui';
import _ from 'lodash';

import { Select, SelectProps } from 'components/ui/Select/Select';
import { dateTime } from 'utils/dateTime';
import { mergeRefs } from 'utils/mergeRefs';
import { emitUpdateFieldView } from 'utils/emitUpdateFieldView';
import { delay } from 'utils/delay';

const currentYear = dateTime().year();

const getOptionsFromYears = (years: number[]) => years.map((y) => ({ id: `${y}`, label: `${y}` }));

type Props = Omit<SelectProps, 'options' | 'placeholder'> &
  Partial<Pick<SelectProps, 'placeholder'>> & {
    range?: [number, number];
    excludedYears?: number[];
    customYears?: number[];
    fromBaseYearConfig?: {
      prependWith: number; // number of years to add before base year
      appendWith: number; // number of years to add after base year
      customBaseYear?: number; // if not provided defaults to current year
    };
  };

const defaultProps = {
  range: undefined,
  excludedYears: undefined,
  customYears: undefined,
  fromBaseYearConfig: {
    prependWith: 5,
    appendWith: 5,
    customBaseYear: currentYear,
  },
};

export const YearSelect = React.forwardRef<HTMLInputElement, Props>(
  (
    { placeholder, range, excludedYears, customYears, fromBaseYearConfig, sx, ...props }: Props,
    ref,
  ): React.ReactElement | null => {
    const [options, setOptions] = useState<SelectProps['options'] | null>(null);

    const selectRef = useRef<HTMLInputElement | null>(null);

    const getBaseYears = useCallback(() => {
      if (customYears) return customYears;
      if (range) {
        const [firstYear, lastYear] = range;
        return _.range(firstYear, lastYear + 1);
      }
      if (fromBaseYearConfig) {
        const { prependWith, appendWith, customBaseYear } = fromBaseYearConfig;
        return [
          ...[..._.times(prependWith, (i) => currentYear - (i + 1))].reverse(),
          customBaseYear || currentYear,
          ..._.times(appendWith, (i) => currentYear + i + 1),
        ];
      }
      return [];
    }, [customYears, range, fromBaseYearConfig]);

    useEffect(() => {
      const updateSelectView = async () => {
        await delay(100);
        emitUpdateFieldView(selectRef);
      };
      const years = excludedYears ? getBaseYears().filter((year) => !excludedYears.includes(year)) : getBaseYears();
      const yearsOptions = getOptionsFromYears(years);

      setOptions(yearsOptions);
      void updateSelectView();
    }, [excludedYears, getBaseYears]);

    return (
      <Flex
        sx={{
          width: '80px',
          flexShrink: 0,
          '& li[role=option]': {
            overflowWrap: 'normal',
            justifyContent: 'center',
          },
          ...(sx && sx),
        }}
      >
        <Select
          ref={mergeRefs([ref, selectRef])}
          size="sm"
          placeholder={placeholder || `${currentYear}`}
          options={options || []}
          {...props}
        />
      </Flex>
    );
  },
);

YearSelect.defaultProps = defaultProps;
