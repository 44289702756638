import React from 'react';
import { FlexProps, ThemeUIStyleObject } from 'theme-ui';

import { Column } from 'components/ui/FlexGrid';

import { Header } from './Header/Header';

type MainComponent = React.FC<Props> & {
  Header: typeof Header;
};

type Props = FlexProps & Required<Pick<FlexProps, 'children'>>;

export const mainPx = 4;

const columnSx: ThemeUIStyleObject = {
  flexDirection: 'column',
  px: mainPx,
  gap: 4,
};

export const Main: MainComponent = ({ sx, children, ...props }: Props): React.ReactElement => (
  <Column
    as="main"
    {...props}
    sx={{
      ...columnSx,
      ...(sx && sx),
    }}
  >
    {children}
  </Column>
);

Main.Header = Header;
