import { useCallback, useEffect, useState } from 'react';
import Bowser from 'bowser';

import { PermissionState } from 'Kiosk/state/permissionState';

import { useGeoLocation } from './useGeoLocation';

type MediaPermissionsError = {
  type?: MediaPermissionsErrorType;
  name: string;
  message?: string;
};

enum MediaPermissionsErrorType {
  /** (macOS) browser does not have permission to access cam/mic */
  SystemPermissionDenied = 'SystemPermissionDenied',
  /** user denied permission for site to access cam/mic */
  UserPermissionDenied = 'UserPermissionDenied',
  /** (Windows) browser does not have permission to access cam/mic OR camera is in use by another application or browser tab */
  CouldNotStartVideoSource = 'CouldNotStartVideoSource',
  /** all other errors */
  Generic = 'Generic',
}

const checkMediaPermissions = (constraints?: MediaStreamConstraints) =>
  new Promise<boolean>((resolve, reject: (reason?: MediaPermissionsError) => void) => {
    navigator.mediaDevices
      .getUserMedia(constraints ?? { audio: false, video: true })
      .then((stream: MediaStream) => {
        stream.getTracks().forEach((t) => {
          t.stop();
        });
        resolve(true);
      })
      .catch((err: MediaPermissionsError) => {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const browserName = browser.getBrowserName();

        const errName = err.name;
        const errMessage = err.message;
        let errorType: MediaPermissionsErrorType = MediaPermissionsErrorType.Generic;
        if (browserName === 'Chrome') {
          if (errName === 'NotAllowedError') {
            if (errMessage === 'Permission denied by system') {
              errorType = MediaPermissionsErrorType.SystemPermissionDenied;
            } else if (errMessage === 'Permission denied') {
              errorType = MediaPermissionsErrorType.UserPermissionDenied;
            }
          } else if (errName === 'NotReadableError') {
            errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
          }
        } else if (browserName === 'Safari') {
          if (errName === 'NotAllowedError') {
            errorType = MediaPermissionsErrorType.UserPermissionDenied;
          }
        } else if (browserName === 'Microsoft Edge') {
          if (errName === 'NotAllowedError') {
            errorType = MediaPermissionsErrorType.UserPermissionDenied;
          } else if (errName === 'NotReadableError') {
            errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
          }
        } else if (browserName === 'Firefox') {
          if (errName === 'NotFoundError') {
            errorType = MediaPermissionsErrorType.SystemPermissionDenied;
          } else if (errName === 'NotReadableError') {
            errorType = MediaPermissionsErrorType.SystemPermissionDenied;
          } else if (errName === 'NotAllowedError') {
            errorType = MediaPermissionsErrorType.UserPermissionDenied;
          } else if (errName === 'AbortError') {
            errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
          }
        }

        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: errorType,
          name: err.name,
          message: err.message,
        });
      });
  });

const checkCameraPermissions = () =>
  checkMediaPermissions({ audio: false, video: true })
    .then(() => PermissionState.allow)
    .catch(() => PermissionState.deny);

type Props = {
  intervalTime?: number;
  skipCamera?: boolean;
};

type UsePermissionsReturn = {
  camera: PermissionState;
  location: PermissionState;
};

export const useCheckPermissions = ({ intervalTime = 1000, skipCamera = false }: Props): UsePermissionsReturn => {
  const [permissionState, setPermissionState] = useState<UsePermissionsReturn>({
    camera: PermissionState.checking,
    location: PermissionState.checking,
  });

  const { getGeoLocation } = useGeoLocation();

  const checkLocationPermission = useCallback(
    () =>
      new Promise<PermissionState>((resolve, reject) => {
        if (getGeoLocation)
          getGeoLocation(
            () => resolve(PermissionState.allow),
            () => reject(PermissionState.deny),
          );
      }),
    [getGeoLocation],
  );

  const permissionsToCheck = useCallback(async () => {
    if (!skipCamera) {
      await checkCameraPermissions().then((res) => {
        if (permissionState.camera !== res) {
          setPermissionState((prevState) => ({ ...prevState, camera: res }));
        }
      });
    }

    checkLocationPermission()
      .then((res) => {
        if (permissionState.location !== res) {
          setPermissionState((prevState) => ({ ...prevState, location: PermissionState.allow }));
        }
      })
      .catch(() => {
        if (permissionState.location !== PermissionState.deny) {
          setPermissionState((prevState) => ({ ...prevState, location: PermissionState.deny }));
        }
      });
  }, [checkLocationPermission, permissionState.camera, permissionState.location, skipCamera]);

  useEffect(() => {
    const permissionCheckInterval = setInterval(() => {
      void permissionsToCheck();
    }, intervalTime);

    return () => {
      if (permissionCheckInterval) {
        clearInterval(permissionCheckInterval);
      }
    };
  }, [intervalTime, permissionsToCheck]);

  return permissionState;
};
