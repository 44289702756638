import _ from 'lodash';

import { Action, ActionConfig, DefaultErrorMessageEnum } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  DeleteTimeClocksActionProps,
  DeleteTimeClocksResponse,
  EditTimeClockActionProps,
  EditTimeClockResponse,
  ErrorCodeTimeclock,
  GetInfoErrorData,
  GetInfoProps,
  GetInfoResponse,
  GetSettingsResponse,
  GetTimeClocksResponse,
  InitPersonModelProps,
  InitPersonModelResponse,
  SubmitTimeEventProps,
  SubmitTimeEventResponse,
} from './timeclockActions.types';

export function getSettings(): Action<GetSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.timeclock.getSettings,
  };
}

export function getInfo(
  { qrCode, images }: GetInfoProps,
  config?: ActionConfig,
): Action<GetInfoResponse, GetInfoErrorData, ErrorCodeTimeclock> {
  const formData = new FormData();

  formData.append('qrCode', qrCode);

  if (images) {
    _.forEach(images, (img) => formData.append('Images', img));
  }

  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeclock.getInfo,
    body: formData,
    config,
  };
}

export function initPersonModel({
  qrCode,
  images,
  useFaceDetection,
}: InitPersonModelProps): Action<InitPersonModelResponse> {
  const formData = new FormData();

  formData.append('qrCode', qrCode);

  if (useFaceDetection) formData.append('useFaceDetection', JSON.stringify(useFaceDetection));

  if (images) {
    _.forEach(images, (img) => formData.append('Images', img));
  }

  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeclock.initPersonModel,
    body: formData,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.UnexpectedErrorContactSupport,
    },
  };
}

export function submitTimeEventAction(props: SubmitTimeEventProps): Action<SubmitTimeEventResponse> {
  const formData = new FormData();

  const { photo, ...body } = props;

  formData.append('photo', photo);
  formData.append('timeClockSubmitRequest', JSON.stringify(body));

  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeclock.submitTimeEvent,
    body: formData,
  };
}

export function getTimeClocksAction(): Action<GetTimeClocksResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.device,
  };
}

export function deleteTimeClocksAction(body: DeleteTimeClocksActionProps): Action<DeleteTimeClocksResponse> {
  return {
    method: 'DELETE',
    endpoint: API_ENDPOINTS.device,
    body,
  };
}

export function editTimeClockAction(body: EditTimeClockActionProps): Action<EditTimeClockResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.device}/${body.id}`,
    body,
  };
}
