import React, { useCallback, useEffect, useState } from 'react';

import { OPEN_CONTEXT_MENU, POPPER_ACTIVATED } from 'hooks/useContextMenu/useContextMenu';
import { useMount } from 'hooks/useMount/useMount';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { wish } from 'utils/wish';

import { PopperProvider, PopperProviderProps } from './PopperProvider';

export type PopperGuardProps = {
  popperGuardValue?: boolean;
};
type WithAdditionalProps = {
  tabIndex?: number;
  popperProps: Omit<PopperProviderProps, 'children'> & PopperGuardProps;
  autoInitialize?: boolean;
};

export function withPopper<T>(Component: React.ComponentType<T>) {
  return React.forwardRef<HTMLElement, T & WithAdditionalProps>(
    ({ popperProps, autoInitialize, ...props }: T & WithAdditionalProps, ref): React.ReactElement => {
      const [isActivated, setIsActivated] = useState<boolean>(popperProps.popperGuardValue || false);

      const { contextMenuId } = popperProps;

      const setActivated = useCallback(() => {
        setIsActivated(true);
      }, []);

      useEffect(() => {
        if (autoInitialize && !isActivated) {
          setIsActivated(true);
        }
      }, [autoInitialize, isActivated]);

      const initializationFulfiller = useCallback(() => {
        setActivated();
        return Promise.resolve(POPPER_ACTIVATED);
      }, [setActivated]);

      useMount(() => {
        if (!contextMenuId) return;
        wish.fulfill(`${OPEN_CONTEXT_MENU}${contextMenuId}`, initializationFulfiller);
      });

      useUnmount(() => {
        if (!contextMenuId) return;
        wish.deleteFulfiller(`${OPEN_CONTEXT_MENU}${contextMenuId}`);
      });

      return (
        <>
          {!isActivated ? (
            <Component
              {...(props as unknown as T)}
              onMouseOver={setActivated}
              onTouchStart={setActivated}
              onFocus={setActivated}
              ref={ref}
            />
          ) : (
            <PopperProvider {...popperProps} withMobileInit={!popperProps.popperGuardValue && !autoInitialize}>
              <Component {...(props as unknown as T)} ref={ref} />
            </PopperProvider>
          )}
        </>
      );
    },
  );
}
