import { DefaultValue } from 'recoil';

/**
 * Checks if passed value is of type DefaultValue.
 *
 * @param {unknown} candidate Value to check.
 *
 * @return {boolean} Return true if passed value is of type DefaultValue
 */

export const isRecoilDefaultValue = (candidate: unknown): candidate is DefaultValue => {
  if (candidate instanceof DefaultValue) return true;
  return false;
};
