import { useCallback } from 'react';
import { useQuery } from 'react-fetching-library';
import { useRecoilState } from 'recoil';
import _ from 'lodash';

import { requestsSettingsAtom } from 'state/settings';
import { fetchRequestsSettingsAction } from 'api/actions/settings/settingsActions';
import { FetchRequestsSettingsResponse } from 'api/actions/settings/settingsActions.types';

export const useRequestsSettings = () => {
  const { query, abort } = useQuery(fetchRequestsSettingsAction(), false);
  const [requestsSettings, setRequestsSettings] = useRecoilState(requestsSettingsAtom);

  const fetchRequestsSettings = useCallback(async () => {
    const response = await query();
    const { error, payload } = response;

    if (!error && payload) {
      setRequestsSettings(payload);
    }

    return response;
  }, [query, setRequestsSettings]);

  const getParsedRequestsSettings = useCallback((data: FetchRequestsSettingsResponse | null) => {
    if (!data) return null;

    const {
      timeOffApprovalSettings,
      timeTrackingApprovalSettings,
      businessTripsApprovalSettings,
      customRequestsApprovalSettings,
      carriedOverLimitExpiresAfter,
      allowCarryOverUnUsedLimits,
      ...rest
    } = data;

    return {
      ...rest,
      timeOffApprovalSettings: timeOffApprovalSettings.twoStepApprovalFlow
        ? { ...timeOffApprovalSettings }
        : _.omit(timeOffApprovalSettings, ['firstStepRoleId', 'secondStepRoleId']),
      timeTrackingApprovalSettings: timeTrackingApprovalSettings.twoStepApprovalFlow
        ? { ...timeTrackingApprovalSettings }
        : _.omit(timeTrackingApprovalSettings, ['firstStepRoleId', 'secondStepRoleId']),
      businessTripsApprovalSettings: businessTripsApprovalSettings.twoStepApprovalFlow
        ? { ...businessTripsApprovalSettings }
        : _.omit(businessTripsApprovalSettings, ['firstStepRoleId', 'secondStepRoleId']),
      customRequestsApprovalSettings: customRequestsApprovalSettings.twoStepApprovalFlow
        ? { ...customRequestsApprovalSettings }
        : _.omit(customRequestsApprovalSettings, ['firstStepRoleId', 'secondStepRoleId']),
      ...(allowCarryOverUnUsedLimits ? { carriedOverLimitExpiresAfter } : {}),
      allowCarryOverUnUsedLimits,
    };
  }, []);

  return {
    requestsSettings,
    setRequestsSettings,
    fetchRequestsSettings,
    getParsedRequestsSettings,
    fetchAbort: abort,
  };
};
