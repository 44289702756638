/* eslint-disable no-useless-escape */
import _ from 'lodash';

const POINTS_FOR_OCCURRENCE = 1;
const IN_ROW_MULTIPLAYER = 100;
const FULL_WORD_MULTIPLAYER = 10000;
const PARTIAL_WORD_MULTIPLAYER = 500;

const HIGHLY_ACCURATE_MATCH_LENGTH = 3;

const HIGHLY_ACCURATE_SCORE_THRESHOLD = HIGHLY_ACCURATE_MATCH_LENGTH * IN_ROW_MULTIPLAYER;

/**
 * Calculates the score of the given text.
 * Higher the score, the more alike two strings are.
 *
 * @param {string} text String to be compared to the searched string.
 * @param {string} search String that is searched.
 *
 * @return {number[]} Returns the score.
 */

export const getFuzzySearchScore = (text: string, search: string) => {
  let score = 0;

  if (!text.replace(/ /g, '').length) {
    return score;
  }

  const parsedText = text.replace(/ /g, '').toLowerCase();
  const parsedSearch = search.replace(/ /g, '').toLowerCase();

  const searchChars = parsedSearch.split('');

  let prevCharMatchesInRow: Map<number, number> = new Map();

  const matches: string[] = [];

  searchChars.forEach((char) => {
    if (!parsedText.includes(char)) {
      prevCharMatchesInRow = new Map();
      return;
    }

    const currentCharMatchesInRow = new Map();

    for (let i = 0; i < parsedText.length; i += 1) {
      if (parsedText[i] === char) {
        let matchesInRow = prevCharMatchesInRow.get(i - 1);
        if (!matchesInRow) {
          score += POINTS_FOR_OCCURRENCE;
          currentCharMatchesInRow.set(i, 1);
        }

        if (matchesInRow) {
          matchesInRow += 1;
          currentCharMatchesInRow.set(i, matchesInRow);

          const match = _.times(matchesInRow, (num) => parsedText[i - num])
            .reverse()
            .join('');

          if (!matches.includes(match)) {
            matches.push(match);
            score += matchesInRow * IN_ROW_MULTIPLAYER;
          }
        }
      }
    }
    prevCharMatchesInRow = currentCharMatchesInRow;
  });

  return score;
};

type SearchOption = {
  id: string;
  label: string;
  combinedSearchScore?: number;
  wordMapScore?: number;
  fuzzyScore?: number;
};

function nakedFuzzySearch<Option extends SearchOption = SearchOption>(
  options: Option[],
  search: string,
  config?: {
    omitResultLengthReduction: boolean;
  },
): Option[] {
  const { omitResultLengthReduction } = config || {};

  const parsedSearch = search.replace(/ /g, '');
  const acceptanceThreshold = parsedSearch.length || 1;

  let highlyAccurateAnswersCount = 0;
  let highestScore = 0;

  const optionsWithFuzzyScore: Option[] = [];

  options.forEach((option) => {
    const fuzzyScore = getFuzzySearchScore(option.label, search);
    const score = option.combinedSearchScore ? option.combinedSearchScore + fuzzyScore : fuzzyScore;

    highestScore = Math.max(highestScore, fuzzyScore);

    if (fuzzyScore < acceptanceThreshold) return;

    optionsWithFuzzyScore.push({ ...option, combinedSearchScore: score, fuzzyScore });

    if (fuzzyScore > HIGHLY_ACCURATE_SCORE_THRESHOLD) {
      highlyAccurateAnswersCount += 1;
    }
  });

  const highAccuracyMinResults = 4;

  const sortedAnswers = [...optionsWithFuzzyScore].sort(
    (a, b) => (b?.combinedSearchScore || 0) - (a?.combinedSearchScore || 0),
  );

  // if needed rewrite as resultOptionParser passed in config
  const cleanOptions = (searchOptions: Option[]): Option[] =>
    searchOptions.map(({ combinedSearchScore, wordMapScore, fuzzyScore, ...option }) => ({
      ...(option as unknown as Option),
    }));

  if (omitResultLengthReduction || highestScore < HIGHLY_ACCURATE_SCORE_THRESHOLD) {
    return cleanOptions(sortedAnswers);
  }

  const roundedHighestScore = Math.floor(highestScore / IN_ROW_MULTIPLAYER) * IN_ROW_MULTIPLAYER;
  const shortageTolerance = roundedHighestScore / 5;
  const sliceIndex = sortedAnswers.findIndex(
    ({ fuzzyScore }) =>
      Math.floor(fuzzyScore || 0 / IN_ROW_MULTIPLAYER) * IN_ROW_MULTIPLAYER + shortageTolerance < roundedHighestScore,
  );

  return cleanOptions(
    sortedAnswers.slice(0, sliceIndex > 0 ? sliceIndex : Math.min(highAccuracyMinResults, highlyAccurateAnswersCount)),
  );
}

export type OptionsSearchFactoryConfig = {
  wordProxies?: [string[], string[]][]; // [wordProxies[], wordsTheProxiesShouldBeTreatedLike[]][]
};

/**
 * Creates a search method that will first filter provided options by wordMap then bu fuzzySearch
 *
 * @param {Option[]} options Array of options to filter.
 * @param {OptionsSearchFactoryConfig} config Configuration object.
 *
 * @return {(search:string)=>Option[]} Returns a searching function that will have access to wordMap that was created when optionsSearchFactory was called.
 */

export function optionsSearchFactory<Option extends SearchOption = SearchOption>(
  options: Option[],
  config?: OptionsSearchFactoryConfig,
): (search: string) => Option[] {
  const { wordProxies } = config || {};
  const allOptions = [...options];

  const optionsMap = new Map(allOptions.map((option) => [option.id, option]));
  const fullWordsMap = new Map<string, string[]>();
  const partialWordsMap = new Map<string, string[]>();

  const replaceSlashesWithWhiteSpace = (text: string) => text.replace(/[\(\)\/]/g, ' ');

  allOptions.forEach(({ label, id }) => {
    const parsedLabel = replaceSlashesWithWhiteSpace(label);

    _.words(parsedLabel).forEach((word) => {
      const parsedWord = word.toLowerCase();

      if (parsedWord.length <= 1) return;

      const oldIds = fullWordsMap.get(parsedWord);
      fullWordsMap.set(parsedWord, [...(oldIds?.length ? oldIds : []), id]);

      if (parsedWord.length <= 2) return;
      const partialWords = parsedWord
        .split('')
        .map((letter, index, arr) => [...arr].splice(0, arr.length - index).join(''));

      partialWords.shift();
      partialWords.splice(-2);

      partialWords.forEach((partialWord) => {
        const oldPartialWordIds = partialWordsMap.get(partialWord);

        partialWordsMap.set(partialWord, [...(oldPartialWordIds?.length ? oldPartialWordIds : []), id]);
      });
    });
  });

  if (wordProxies) {
    const wordProxiesMap = new Map(wordProxies);

    wordProxiesMap.forEach((words, proxyWords) => {
      const wordIds: string[][] = [];
      words.forEach((word) => {
        const ids = fullWordsMap.get(word);
        if (!ids?.length) return;
        wordIds.push(ids);
      });

      if (!wordIds.length) return;

      proxyWords.forEach((proxyWord) => {
        fullWordsMap.set(proxyWord, _.intersection(...wordIds));
      });
    });
  }

  const filterByWordsMap = (search: string) => {
    const parsedSearch = replaceSlashesWithWhiteSpace(search).toLowerCase();
    const searchWords = _.words(parsedSearch);

    const fullWordMatches: string[][] = [];
    const partialWordMatches: string[][] = [];

    searchWords.forEach((searchWord) => {
      const fullWordMatch = fullWordsMap.get(searchWord);
      const partialWordMatch = partialWordsMap.get(searchWord);

      if (fullWordMatch?.length) {
        fullWordMatches.push(fullWordMatch);
        return;
      }

      if (!partialWordMatch?.length) return;

      partialWordMatches.push(partialWordMatch);
    });

    const fullWordMatchesOptionsIds = fullWordMatches.length ? _.flatten(_.intersection(...fullWordMatches)) : null;
    const partialWordMatchesOptionsIds = partialWordMatches.length
      ? _.flatten(_.intersection(...partialWordMatches))
      : null;

    const allMatchedOptionsIds = _.uniq([
      ...(fullWordMatchesOptionsIds || []),
      ...(partialWordMatchesOptionsIds || []),
    ]);

    if (!allMatchedOptionsIds.length) {
      return allOptions;
    }

    return allMatchedOptionsIds.map((id) => {
      let score = 0;
      if (fullWordMatchesOptionsIds?.includes(id)) {
        const fullWordMatchesCount = fullWordMatchesOptionsIds?.filter((optionId) => optionId === id).length;
        score += fullWordMatchesCount * FULL_WORD_MULTIPLAYER;
      }
      if (partialWordMatchesOptionsIds?.includes(id)) {
        const partialWordMatchesCount = partialWordMatchesOptionsIds?.filter((optionId) => optionId === id).length;
        score += partialWordMatchesCount * PARTIAL_WORD_MULTIPLAYER;
      }

      return { ...(optionsMap.get(id) as Option), combinedSearchScore: score, wordMapScore: score };
    });
  };

  const optionsSearch = (search: string) => {
    const optionsFilteredByWordsMap = filterByWordsMap(search);
    return nakedFuzzySearch(optionsFilteredByWordsMap, search, {
      omitResultLengthReduction: optionsFilteredByWordsMap.length < allOptions.length,
    });
  };

  return _.throttle(optionsSearch, 100, { trailing: false }) as typeof optionsSearch;
}

/**
 * Sorts and filters the provided array based on each items FuzzySearchScore.
 *
 * @See getFuzzySearchScore
 *
 * @param {Option[]} inputArray Array to be sorted.
 * @param {string} search String that is searched.
 * @param {omitResultLengthReduction?:boolean} config Configuration obj.
 *
 * @return {Option[]} Returns the filtered and sorted array.
 */

export const fuzzySearch = _.throttle(nakedFuzzySearch, 100, { trailing: false }) as typeof nakedFuzzySearch;
