import { t } from '@lingui/macro';
import React, { useCallback } from 'react';

import { WORK_STATUS_VARIANT, WorkStatusBadge } from 'components/recipes/WorkStatusBadge';
import { WorkStatusesSelectOption } from 'state/selectOptions';
import { Select, SelectProps } from '../Select';
import { OptionProps } from '../elements';

type WorkStatusSelectProps = SelectProps<WorkStatusesSelectOption>;

export const WorkStatusSelect = React.forwardRef<HTMLInputElement, WorkStatusSelectProps>(
  ({ ...props }: WorkStatusSelectProps, ref): React.ReactElement => {
    const optionContentRenderer = useCallback(
      ({ workStatusId, name, isEnd }: WorkStatusesSelectOption, active?: boolean, isOption?: boolean) => (
        <WorkStatusBadge
          workStatus={{ id: workStatusId, name }}
          isEnd={isEnd}
          active={active}
          {...(!isOption && { sx: { marginRight: '0.25rem' } })}
        />
      ),
      [],
    );

    const optionPropsGenerator = useCallback(
      (option: WorkStatusesSelectOption, active?: boolean): Partial<OptionProps> => {
        const variant = WORK_STATUS_VARIANT?.[option.workStatusId.toLocaleLowerCase()]?.variant || 'custom';
        const isCustomVariant = variant !== 'clockOut' && variant !== 'clockIn';
        const defaultActiveBg = option.isEnd
          ? 'workStatus.selectOptionBackground.active.end'
          : 'workStatus.selectOptionBackground.active.start';
        const defaultHoverBg = option.isEnd
          ? 'workStatus.selectOptionBackground.hover.end'
          : 'workStatus.selectOptionBackground.hover.start';
        const activeBg = isCustomVariant ? 'workStatus.selectOptionBackground.active.custom' : defaultActiveBg;
        const hoverBg = isCustomVariant ? 'workStatus.selectOptionBackground.hover.custom' : defaultHoverBg;
        return {
          contentRenderer: () => optionContentRenderer(option, active, true),
          sx: {
            color: `workStatus.text.${variant}`,
            '&:hover, &:focus, &[aria-selected="true"]': {
              bg: hoverBg,
            },
            ...(active && {
              bg: activeBg,
              '&:hover, &:focus, &[aria-selected="true"]': {
                bg: activeBg,
              },
            }),
          },
        };
      },
      [optionContentRenderer],
    );

    return (
      <Select
        ref={ref}
        placeholder={t({ id: 'requests.select_work_status', message: 'Select work status...' })}
        {...props}
        sxOverwrite={{
          ...props?.sxOverwrite,
          maxHeight: '37px',
          maxWidth: '100%',
        }}
        customValueDisplayRenderer={optionContentRenderer}
        optionPropsGenerator={optionPropsGenerator}
      />
    );
  },
);
