import { pubsubFactory } from './pubsubFactory';

export enum CustomEvents {
  SET_RECOIL_STATE = 'SET_RECOIL_STATE',
  RESET_IS_IDLE_COUNTDOWN = 'RESET_IS_IDLE_COUNTDOWN',
  REDIRECT = 'REDIRECT',
  REFRESH_SESSION = 'REFRESH_SESSION',
  MODAL_CLOSE = 'MODAL_CLOSE',
  MODAL_OPEN = 'MODAL_OPEN',
  LOCATION_CHANGE = 'LOCATION_CHANGE',
  UPDATE_CALENDAR = 'UPDATE_CALENDAR',
  GET_MAIL = 'GET_MAIL',
  GET_TIME_OFF_SELECTED_YEAR = 'GET_TIME_OFF_SELECTED_YEAR',
  SIGNAL_R_GET_PAYMENTS = 'SIGNAL_R_GET_PAYMENTS',
  GET_TIME_TRACKER_AFTER_VISIBILITY_CHANGE = 'GET_TIME_TRACKER_AFTER_VISIBILITY_CHANGE',
  HANDLE_BROADCAST_CHANNEL_TIME_TRACKER = 'HANDLE_BROADCAST_CHANNEL_TIME_TRACKER',
  UPDATE_CHAT_USERS_SUB_GROUPS = 'UPDATE_CHAT_USERS_SUB_GROUPS',
  EDIT_EMPLOYEE_DETAILS_SUCCESS_RESPONSE_RECEIVED = 'EDIT_EMPLOYEE_DETAILS_SUCCESS_RESPONSE_RECEIVED',
  DELETE_REQUESTS_SUCCESS_RESPONSE_RECEIVED = 'DELETE_REQUESTS_SUCCESS_RESPONSE_RECEIVED',
  ADD_REQUEST_SUCCESS_RESPONSE_RECEIVED = 'ADD_REQUEST_SUCCESS_RESPONSE_RECEIVED',
  ACCEPT_REQUEST_MANAGEMENT_SUCCESS_RESPONSE_RECEIVED = 'ACCEPT_REQUEST_MANAGEMENT_SUCCESS_RESPONSE_RECEIVED',
  POST_TIME_EVENT_SUCCESS_RESPONSE_RECEIVED = 'POST_TIME_EVENT_SUCCESS_RESPONSE_RECEIVED',
  REJECT_REQUEST_SUCCESS_RESPONSE_RECEIVED = 'REJECT_REQUEST_SUCCESS_RESPONSE_RECEIVED',
}

/**
 * Pubsub communication interface for custom events.
 *
 * @see pubsubFactory
 */
export const pubsub = pubsubFactory<CustomEvents>();
/**
 * Adds a event listener.
 * @see pubsub.on
 */
export const onCustomEvent = pubsub.on;
/**
 * Emits a global event.
 * @see pubsub.emit
 */
export const emitCustomEvent = pubsub.emit;
/**
 * Removes the event listener.
 * @see pubsub.off
 */
export const offCustomEvent = pubsub.off;
