import { Trans } from '@lingui/macro';
import React, { useCallback, useState } from 'react';
import { Box, BoxOwnProps, BoxProps } from 'theme-ui';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';

import { Icon } from 'components/Icon/Icon';
import { withTooltip } from '../Tooltip/withTooltip';

import { OPTION_LIST_MAX_HEIGHT, OPTION_LIST_PADDING } from './constants';

type Props = Omit<BoxOwnProps, 'color'> &
  React.ComponentPropsWithoutRef<'div'> & {
    onClick?: () => void;
    active?: boolean;
    multi?: boolean;
    contentRenderer?: (active: boolean, hover: boolean) => React.ReactElement | string;
    disabled?: boolean;
    tooltip?: string;
    ariaSelectedAtom: RecoilValueReadOnly<boolean>;
  };

export type OptionProps = Props;

const defaultProps: Partial<Props> = {
  active: false,
  multi: false,
  contentRenderer: undefined,
};

const BoxWithTooltip = withTooltip(Box);

export const Option = ({
  ariaSelectedAtom,
  onClick,
  multi,
  active,
  contentRenderer,
  disabled,
  tooltip,
  ...props
}: Props): React.ReactElement => {
  const selected = useRecoilValue(ariaSelectedAtom);
  const [hover, setHover] = useState(false);

  const handleOnMouseOver = useCallback(() => {
    setHover(true);
  }, []);

  const handleOnMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  const variant = (() => {
    if (active) return 'forms.select.option.active';

    if (disabled) return 'forms.select.option.disabled';

    return 'forms.select.option';
  })();

  const optionProps = {
    variant,
    onMouseOver: handleOnMouseOver,
    onMouseLeave: handleOnMouseLeave,
    onClick: !disabled ? onClick : undefined,
    'aria-selected': selected,
    className: 'option',
    ...props,
  };

  const optionContent = (() => (
    <>
      {!!contentRenderer && contentRenderer(!!active, hover)}
      {multi && active && <Icon wrapperSx={{ flexShrink: 0 }} type="x" />}
      {multi && (selected || hover) && !active && (
        <Icon wrapperSx={{ flexShrink: 0 }} type="selected" fill="texts.lighter" />
      )}
    </>
  ))();

  if (tooltip) {
    return (
      <BoxWithTooltip
        tooltipProps={{
          content: tooltip,
        }}
        {...optionProps}
      >
        {optionContent}
      </BoxWithTooltip>
    );
  }

  return <Box {...optionProps}>{optionContent}</Box>;
};

Option.defaultProps = defaultProps;

const EmptyList = () => (
  <Box as="li" sx={{ px: 3, py: 4, textAlign: 'center', cursor: 'not-allowed', listStyle: 'none' }}>
    <Trans id="select.empty_list">No more results</Trans>
  </Box>
);

type OptionListProps = BoxOwnProps &
  React.ComponentPropsWithRef<'div'> & {
    children: React.ReactNode[] | React.ReactNode;
  };

export const OptionList = React.forwardRef<HTMLDivElement, OptionListProps>(
  ({ children, sx, ...props }: OptionListProps, ref): React.ReactElement => {
    const renderChildren = () => {
      if (children === null || (Array.isArray(children) && children.every((child) => child === null))) {
        return <EmptyList />;
      }
      return children;
    };

    return (
      <Box
        ref={ref}
        sx={{
          textAlign: 'left',
          overflowX: 'hidden',
          overflowY: 'auto',
          width: '100%',
          maxHeight: OPTION_LIST_MAX_HEIGHT,
          bg: 'dropdown.background',
          boxShadow: 'dropdown',
          borderRadius: 'sm',
          p: `${OPTION_LIST_PADDING}px`,
          ...(sx && sx),
        }}
        {...props}
      >
        {renderChildren()}
      </Box>
    );
  },
);

type SelectedMultiOptionProps = BoxProps & {
  children: string;
};

export const SelectedMultiOption = ({ children, ...props }: SelectedMultiOptionProps): React.ReactElement => (
  <Box as="span" variant="forms.select.selectedOption" {...props}>
    <span>{children}</span>
  </Box>
);
