import { ThemeUIStyleObject } from 'theme-ui';

export const newComparisonDifferenceSx: ThemeUIStyleObject = {
  color: 'requests.newComparisonDifference',
  fontWeight: 'bold',
};

export const originalComparisonDifferenceSx: ThemeUIStyleObject = {
  color: 'requests.originalComparisonDifference',
};

export const requestEditionConstainerSx: ThemeUIStyleObject = {
  flexDirection: 'column',
  width: '228px',
  gap: 2,
  fontSize: 2,
};

export const noOriginalNoteSx: ThemeUIStyleObject = {
  color: 'requests.noOriginalNote',
  opacity: '0.55',
};

export const requestChangeDetailsContainerSx: ThemeUIStyleObject = {
  gap: '0.375rem',
  flexDirection: 'column',
  fontSize: 2,
};

export const requestChangeWrapperSx: ThemeUIStyleObject = {
  gap: 3,
};

export const requestChangeTextTileSx: ThemeUIStyleObject = { fontWeight: 'bold', width: '20%' };

export const requestChangeTextEventSx: ThemeUIStyleObject = { width: '40%', wordBreak: 'break-all' };
