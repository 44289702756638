import { t } from '@lingui/macro';
import { isEqual } from 'lodash';
import { FC } from 'react';
import { Flex, Text } from 'theme-ui';

import { ParsedRequest } from 'state/requests';
import { useRequestDetails } from '../../../../../hooks/useRequestDetails';
import {
  newComparisonDifferenceSx,
  noOriginalNoteSx,
  originalComparisonDifferenceSx,
  requestChangeTextEventSx,
  requestChangeTextTileSx,
  requestChangeWrapperSx,
} from '../styles/styles';

type Props = {
  requestDetails: ParsedRequest | null;
};

const OriginalEventNote: FC<Props> = ({ requestDetails }) => {
  const reason = requestDetails?.originalEvent?.eventDetails.reason;

  return (
    <Text
      sx={{
        ...requestChangeTextEventSx,
        ...(!reason && noOriginalNoteSx),
        ...(requestDetails?.newEvent &&
          !isEqual(requestDetails?.newEvent?.eventDetails.reason, requestDetails?.originalEvent?.eventDetails.reason) &&
          reason &&
          originalComparisonDifferenceSx),
      }}
    >
      {reason || '—'}
    </Text>
  );
};

const NewEventNote: FC<Props> = ({ requestDetails }) => {
  const reason = requestDetails?.newEvent?.eventDetails.reason;

  return (
    <Text
      sx={{
        ...requestChangeTextEventSx,
        ...(requestDetails?.originalEvent &&
          !isEqual(requestDetails?.newEvent?.eventDetails.reason, requestDetails?.originalEvent?.eventDetails.reason) &&
          reason &&
          newComparisonDifferenceSx),
        ...(!reason && { opacity: '0.55' }),
      }}
    >
      {reason || '—'}
    </Text>
  );
};

export const RequestChangeEventNote: FC = () => {
  const { parsedRequestDetails: requestDetails } = useRequestDetails();

  if (!requestDetails?.originalEvent?.eventDetails.reason && !requestDetails?.newEvent?.eventDetails.reason)
    return null;

  return (
    <Flex sx={requestChangeWrapperSx}>
      <Text sx={requestChangeTextTileSx}>{t({ id: 'requests.event_note' })}</Text>
      <OriginalEventNote requestDetails={requestDetails} />
      <NewEventNote requestDetails={requestDetails} />
    </Flex>
  );
};
