import { useCallback } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { fetchUserInfoAction } from 'api/actions/settings/settingsActions';
import { UserInfoActionProps } from 'api/actions/settings/settingsActions.types';
import { profileSettingsAtom } from 'state/settings';

export const useProfileSettings = () => {
  const { query } = useClient();
  const [profileSettings, setProfileSettings] = useRecoilState(profileSettingsAtom);

  const fetchUserInfo = useCallback(async () => {
    const { error, payload } = await query(fetchUserInfoAction());

    if (!error && payload) {
      setProfileSettings(payload);
    }
  }, [query, setProfileSettings]);

  const getParsedProfileSettings = useCallback((data: UserInfoActionProps) => {
    const { address, phoneNumber, ...rest } = data;

    const postalCode = address && address.postalCode?.length ? address.postalCode : undefined;
    const city = address && address.city?.length ? address.city : undefined;
    const street = address && address.street?.length ? address.street : undefined;

    return {
      ...rest,
      address:
        address && (address.postalCode?.length || address.city?.length || address.street?.length)
          ? {
              postalCode,
              city,
              street,
            }
          : undefined,
      // TODO: add a demasking util method exported from PhoneInput
      phoneNumber: phoneNumber && phoneNumber.replaceAll(' ', '').length ? phoneNumber.replaceAll(' ', '') : undefined,
    };
  }, []);

  return {
    profileSettings,
    setProfileSettings,
    fetchUserInfo,
    getParsedProfileSettings,
  };
};
