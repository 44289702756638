import React from 'react';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { useRequestDetails } from '../../hooks/useRequestDetails';

import { BusinessRequestChange } from './components/BusinessRequestChange';
import { CustomRequestChange } from './components/CustomRequestChange';
import { TimeOffRequestChange } from './components/TimeOffRequestChange';
import { TimeTrackingRequestChange } from './components/TimeTrackingRequestChange';

export const RequestChange = (): React.ReactElement | null => {
  const { parsedRequestDetails: requestDetails } = useRequestDetails();

  if (!requestDetails) return null;

  const { type, originalEvent } = requestDetails;

  if (originalEvent) {
    if (type === RequestFormType.TimeOff) return <TimeOffRequestChange />;
    if (type === RequestFormType.Custom) return <CustomRequestChange />;
    if (type === RequestFormType.BusinessTrip) return <BusinessRequestChange />;
  }

  if (type === RequestFormType.TimeTracking) return <TimeTrackingRequestChange />;

  return null;
};
