import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useMemo } from 'react';
import { RecoilValueReadOnly, useRecoilValue, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { RequestState } from 'api/actions/requests/requestsActions.types';
import {
  CustomRowVariant,
  CustomRowVariantGenerator,
  ListNames,
  SortingOrder,
  StickyListProps,
} from 'components/StickyList/types';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { languageSelector } from 'state/recoilState';
import {
  ParsedRequest,
  ParsedRequestsMap,
  RequestsListType,
  filteredParsedRequestsUsageOverviewSelector,
  filteredRequestsSelectorFamily,
  requestsColumns,
  requestsUsageOverviewColumns,
} from 'state/requests';
import { userSessionPersonIdSelector } from 'state/userSession';
import { LazyComponentType } from 'utils/custom.types';
import { RequestDetailsGroupedParam } from '../../../types';

const LazyStickyList = React.lazy(
  () =>
    import('components/StickyList/StickyList').then(({ StickyList }) => ({
      default: StickyList,
    })),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) as unknown as LazyComponentType<StickyListProps<any>>;

type Props<P> = {
  selectable?: boolean;
  columnsAtom?: typeof requestsColumns;
  requestListAtom?: P;
  listType: RequestsListType;
};

export const RequestStickyList = <
  P extends RecoilValueReadOnly<ParsedRequestsMap | null> = RecoilValueReadOnly<ParsedRequestsMap>,
>({
  selectable = true,
  columnsAtom = requestsColumns,
  requestListAtom,
  listType,
}: Props<P>): React.ReactElement => {
  useLingui();
  const columns = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(columnsAtom);
  const filteredRequests = useRecoilValue(requestListAtom || filteredRequestsSelectorFamily(listType));
  const columnsUsageOverview = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(requestsUsageOverviewColumns);
  const filteredRequestUsageOverview = useRecoilValue(filteredParsedRequestsUsageOverviewSelector);

  const language = useRecoilValue(languageSelector);
  const currentUserId = useRecoilValue(userSessionPersonIdSelector);

  const navigate = useAppNavigate();

  const { modulesManagement } = useAppPermissions();
  const { isMobileBreakpoint } = useThemeBreakpoint();

  const handleOnRowClick: NonNullable<StickyListProps['onRowClick']> = useCallback(
    (id, data: ParsedRequest) => {
      const { employee, number, groupNumber, state, isDeleted, swapPersonId } = data;

      const isCurrentUserRequest = currentUserId === employee.id;
      const isCurrentUserSwapTarget = swapPersonId === currentUserId;

      if (modulesManagement.Requests || isCurrentUserRequest || isCurrentUserSwapTarget) {
        navigate(
          `${TO_REL.REQUEST_DETAILS__GROUP__ID[language]}/${listType === 'grouped' ? RequestDetailsGroupedParam.Grouped : RequestDetailsGroupedParam.Ungrouped}/${id}`,
          {
            state: {
              requestDetails: {
                requestNumber: listType === 'ungrouped' ? number : groupNumber,
                state,
                isDeleted,
              },
              requestDetailsData: data,
            },
          },
        );
      }
    },
    [currentUserId, language, modulesManagement.Requests, navigate, listType],
  );

  const checkIfSelectable = useMemo(() => {
    if (modulesManagement.Requests) {
      if (selectable) {
        return 'checkbox';
      }
    }

    return undefined;
  }, [modulesManagement.Requests, selectable]);

  const pendingRequestRowGenerator: CustomRowVariantGenerator = useCallback(
    (id) => {
      if (!filteredRequests) return undefined;

      const request = filteredRequests.get(id);

      if (request && request.state === RequestState.Pending) return CustomRowVariant.pending;

      return undefined;
    },
    [filteredRequests],
  );

  const generatedRequestsList = useMemo(() => {
    if (listType === 'usageOverview') {
      if (!columnsUsageOverview || !filteredRequestUsageOverview) return <></>;

      return (
        <LazyStickyList
          name={ListNames.REQUESTS_OVERVIEW}
          type="grid"
          list={filteredRequestUsageOverview}
          columns={columnsUsageOverview}
          frozenColumns={!isMobileBreakpoint ? 1 : undefined}
          showHeader
          disableNativeKeyboardScrolling
          style={{
            paddingRight: 24,
            paddingBottom: 24,
          }}
          select="checkbox"
          // if there is only first column we should display hidden columns message
          {...(columnsUsageOverview.length === 1 && {
            appendHeaderWith: (
              <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', pl: 3 }}>
                <Text
                  as="span"
                  sx={{
                    fontWeight: '600',
                    color: 'texts.lighter',
                    fontSize: 2,
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t({ id: 'table_hidden_columns', message: 'Empty columns have been hidden.' })}
                </Text>
              </Flex>
            ),
          })}
        />
      );
    }

    if (columns && filteredRequests) {
      return (
        <LazyStickyList
          name={ListNames.REQUESTS}
          select={checkIfSelectable}
          list={filteredRequests}
          columns={columns}
          onRowClick={handleOnRowClick}
          showHeader
          // showContentPlaceholder
          mobileWidth={1024}
          style={{
            paddingRight: 24,
            paddingBottom: 48,
          }}
          defaultSortingState={{
            columnKey: ['newEvent', 'originalEvent', 'actionType', 'type', 'state', 'swapPersonId', 'employee'],
            order: SortingOrder.DESC,
          }}
          customRowVariantGenerator={pendingRequestRowGenerator}
          withRowContextMenu
        />
      );
    }

    return <></>;
  }, [
    checkIfSelectable,
    columns,
    columnsUsageOverview,
    filteredRequestUsageOverview,
    filteredRequests,
    handleOnRowClick,
    isMobileBreakpoint,
    listType,
    pendingRequestRowGenerator,
  ]);

  return generatedRequestsList;
};
