import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ListNames } from 'components/StickyList/types';
import { clockLogResponseAtom } from 'state/clockLog';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { allParsedRequestsMapSelector } from 'state/requests';

export const useDeselectDeletedEmployees = () => {
  const [selectedEmployeesCalendar, setSelectedEmployeesCalendar] = useRecoilState(
    selectedRowsIdsSelectorFamily(ListNames.CALENDAR),
  );
  const [selectedEmployeesAttendanceList, setSelectedEmployeesAttendanceList] = useRecoilState(
    selectedRowsIdsSelectorFamily(ListNames.ATTENDANCE_LIST),
  );
  const [selectedEmployeesTimesheets, setSelectedEmployeesTimesheets] = useRecoilState(
    selectedRowsIdsSelectorFamily(ListNames.TIMESHEETS),
  );
  const [selectedEmployeesRequestsOverview, setSelectedEmployeesRequestsOverview] = useRecoilState(
    selectedRowsIdsSelectorFamily(ListNames.REQUESTS_OVERVIEW),
  );
  const [selectedEmployeesClockLogPhotoLog, setSelectedEmployeesClockLogPhotoLog] = useRecoilState(
    selectedRowsIdsSelectorFamily(ListNames.CLOCK_LOG_PHOTO_LOG),
  );

  const deselectUsersLists = useMemo(
    () =>
      [
        [selectedEmployeesCalendar, setSelectedEmployeesCalendar],
        [selectedEmployeesAttendanceList, setSelectedEmployeesAttendanceList],
        [selectedEmployeesTimesheets, setSelectedEmployeesTimesheets],
        [selectedEmployeesRequestsOverview, setSelectedEmployeesRequestsOverview],
        [selectedEmployeesClockLogPhotoLog, setSelectedEmployeesClockLogPhotoLog],
      ] as const,
    [
      selectedEmployeesAttendanceList,
      selectedEmployeesCalendar,
      selectedEmployeesClockLogPhotoLog,
      selectedEmployeesRequestsOverview,
      selectedEmployeesTimesheets,
      setSelectedEmployeesAttendanceList,
      setSelectedEmployeesCalendar,
      setSelectedEmployeesClockLogPhotoLog,
      setSelectedEmployeesRequestsOverview,
      setSelectedEmployeesTimesheets,
    ],
  );

  const allRequestsMap = useRecoilValue(allParsedRequestsMapSelector);
  const [selectedRequestsIds, setSelectedRequestsIds] = useRecoilState(
    selectedRowsIdsSelectorFamily(ListNames.REQUESTS),
  );

  const clockLogResponse = useRecoilValue(clockLogResponseAtom);
  const [selectedEventsClockLog, setSelectedEventsClockLog] = useRecoilState(
    selectedRowsIdsSelectorFamily(ListNames.CLOCK_LOG),
  );

  const deselectDeletedEmployees = useCallback(
    (deletedEmployeeIds?: string[]) => {
      if (!deletedEmployeeIds) return;

      deselectUsersLists.forEach(([selectedIds, setSelectedIds]) => {
        const shouldUpdateIds = deletedEmployeeIds.some((employee) => selectedIds.includes(employee));
        if (shouldUpdateIds) {
          const newSelectedEmployees = selectedIds.filter((id) => !deletedEmployeeIds.includes(id));
          setSelectedIds(newSelectedEmployees);
        }
      });

      const filteredSelectedRequests: string[] = [];
      allRequestsMap?.forEach((request) => {
        if (!deletedEmployeeIds.includes(request.employee.id) && selectedRequestsIds.includes(request.id)) {
          filteredSelectedRequests.push(request.id);
        }
      });
      if (!_.isEqual(filteredSelectedRequests, selectedRequestsIds)) setSelectedRequestsIds(filteredSelectedRequests);

      if (clockLogResponse && 'employeesData' in clockLogResponse) {
        const { employeesData } = clockLogResponse;
        const employeesDataMap = new Map(employeesData);
        let filteredSelectedEvents: string[] = [...selectedEventsClockLog];

        deletedEmployeeIds.forEach((employeeId) => {
          const deletedEmployeeData = employeesDataMap.get(employeeId);
          if (deletedEmployeeData) {
            const employeeEventIds = _.flatten(deletedEmployeeData.days.map((day) => day[1].timeEventsIds));
            filteredSelectedEvents = filteredSelectedEvents.filter((id) => !employeeEventIds.includes(id));
          }
        });
        if (!_.isEqual(filteredSelectedEvents, selectedEventsClockLog))
          setSelectedEventsClockLog(filteredSelectedEvents);
      }
    },
    [
      allRequestsMap,
      clockLogResponse,
      deselectUsersLists,
      selectedEventsClockLog,
      selectedRequestsIds,
      setSelectedEventsClockLog,
      setSelectedRequestsIds,
    ],
  );

  return deselectDeletedEmployees;
};
