import { HOSTNAME, REACT_APP_HOSTNAME, isInewi } from 'constants/common';
import { makeTheme } from 'styles/makeTheme';
import { hexToRgba } from 'utils/colors';

const BASE_COLORS_INNER = {
  backgrounds: [
    '#FFFFFF', // 0
    '#F5F6F8',
    '#EFF2F4',
    '#EAEDF1',
    '#E5E9ED',
    '#E0E4EA',
    '#DAE0E6',
    '#D5DBE3',
    '#D0D7DF', // 8
  ],
  grays: [
    '#FAFBFC', // 0
    '#E1E2E4',
    '#C1C4C9',
    '#A1A6AD',
    '#818791',
    '#616975',
    '#414B59',
    '#232830',
    '#050607', // 8
  ],
  grayBlues: [
    '#F6F7F9', // 0
    '#EAECF1',
    '#CAD0DB',
    '#AAB3C5',
    '#8A97B0',
    '#6A7A9A',
    '#4A5E85',
    '#2A416F',
    '#04060B', // 8
  ],
  whiteBlues: [
    '#f4f8fd', // 0
    '#e5effa',
    '#d5e5f6',
    '#c6dbf3',
    '#b6d2f0',
    '#a7c8ed',
    '#97bfe9',
    '#88b5e6',
    '#769dc8', // 8
  ],
  whites: [
    '#FFFFFF', // 0
    '#F9FAFC',
    '#F4F6F8',
    '#EEF1F5',
    '#E8EDF2',
    '#E2E8EE',
    '#DDE3EB',
    '#D7DFE7',
    '#D1DAE4', // 8
  ],
  softBlues: [
    '#E0F7FE', // 0
    '#C2ECFE',
    '#A3DDFD',
    '#8CCDFB',
    '#66B5F9',
    '#4A8ED6',
    '#336AB3',
    '#204A90',
    '#133377', // 8
  ],
  skyBlues: [
    '#D6FCFD', // 0
    '#AEF4FB',
    '#84E2F4',
    '#63CBEA',
    '#34AADC',
    '#2686BD',
    '#1A659E',
    '#10487F',
    '#093369', // 8
  ],
  darkBlues: [
    '#E5F5FF', // 0
    '#D0ECFF',
    '#AADAFF',
    '#77BEFF',
    '#4390FF',
    '#1863FF',
    '#0046FF',
    '#0049FF',
    '#003FDF', // 8
  ],
  reds: [
    '#FFE4D9', // 0
    '#FFC3B3',
    '#FF9C8D',
    '#FF7771',
    '#FF424B',
    '#DB3047',
    '#B72143',
    '#93153C',
    '#7A0C38', // 8
  ],
  greens: [
    '#E2FCD8', // 0
    '#C0F9B3',
    '#93ED89',
    '#69DC68',
    '#3BC647',
    '#2BAA41',
    '#1D8E3B',
    '#127235',
    '#0B5F30',
  ],
  limes: [
    '#f4fcdb', // 0
    '#e8fab8',
    '#D2F192',
    '#bbe373',
    '#9AD148',
    '#7bb334',
    '#5F9624',
    '#457916',
    '#33640d', // 8
  ],
  yellows: [
    '#FFF8D1', // 0
    '#FFEFA4',
    '#FFE376',
    '#FFD854',
    '#FFC61C',
    '#DBA414',
    '#B7840E',
    '#936608',
    '#7A5005',
  ],
  oranges: [
    '#fff1d4', // 0
    '#ffe0aa',
    '#FFCA7F',
    '#ffb560',
    '#ff922b',
    '#db711f',
    '#B75315',
    '#933a0d',
    '#7a2808', // 8
  ],
  browns: [
    '#f9efdf', // 0
    '#f3dec0',
    '#DDBC97',
    '#bb9371',
    '#8E6243',
    '#7a4a30',
    '#663621',
    '#522415',
    '#44160c', // 8
  ],
  pinks: [
    '#FEE2E0', // 0
    '#FDC2C5',
    '#FAA3B0',
    '#F68BA5',
    '#F06595',
    '#CE4984',
    '#AC3275',
    '#8B2064',
    '#73135A',
  ],
  purples: [
    '#EFEAFF', // 0
    '#DFD5FF',
    '#CEC0FF',
    '#C0B1FF',
    '#AA97FF',
    '#806EDB',
    '#5B4CB7',
    '#3C3093',
    '#261C7A',
  ],
  unPurples: [
    '#f4edfe', // 0
    '#dfc8fc',
    '#c9a3fa',
    '#b37df7',
    '#9e58f5', // 4
    '#9346f4', // 5 <- DEFAULT
    '#710eed',
    '#550bb2',
    '#380777', // 8
  ],
};

export const BASE_COLORS = {
  ...BASE_COLORS_INNER,
  primaries: HOSTNAME === REACT_APP_HOSTNAME.inewi ? BASE_COLORS_INNER.skyBlues : BASE_COLORS_INNER.unPurples,
};

const COLOR_ALPHAS_INNER = {
  // DARKER
  darker0: hexToRgba(BASE_COLORS.grayBlues[7], 0.0),
  darker1: hexToRgba(BASE_COLORS.grayBlues[7], 0.06),
  darker2: hexToRgba(BASE_COLORS.grayBlues[7], 0.08),
  darker3: hexToRgba(BASE_COLORS.grayBlues[7], 0.1),
  darker4: hexToRgba(BASE_COLORS.grayBlues[7], 0.12),
  darker5: hexToRgba(BASE_COLORS.grayBlues[7], 0.2),
  darker6: hexToRgba(BASE_COLORS.grayBlues[7], 0.28),
  darker7: hexToRgba(BASE_COLORS.grayBlues[7], 0.36),
  darker8: hexToRgba(BASE_COLORS.grayBlues[7], 0.42),
  // LIGHTER
  lighter0: hexToRgba(BASE_COLORS.whites[1], 0.0),
  lighter1: hexToRgba(BASE_COLORS.whites[1], 0.3),
  lighter2: hexToRgba(BASE_COLORS.whites[1], 0.5),
  lighter3: hexToRgba(BASE_COLORS.whites[1], 0.7),
  lighter4: hexToRgba(BASE_COLORS.whites[1], 0.75),
  lighter5: hexToRgba(BASE_COLORS.whites[1], 0.8),
  lighter6: hexToRgba(BASE_COLORS.whites[1], 0.85),
  lighter7: hexToRgba(BASE_COLORS.whites[1], 0.9),
  lighter8: hexToRgba(BASE_COLORS.whites[1], 0.95),
  // BLUISH
  bluish0: hexToRgba(BASE_COLORS.softBlues[5], 0.0),
  bluish1: hexToRgba(BASE_COLORS.softBlues[5], 0.08),
  bluish2: hexToRgba(BASE_COLORS.softBlues[5], 0.12),
  bluish3: hexToRgba(BASE_COLORS.softBlues[5], 0.16),
  bluish4: hexToRgba(BASE_COLORS.softBlues[5], 0.2),
  bluish5: hexToRgba(BASE_COLORS.softBlues[5], 0.36),
  bluish6: hexToRgba(BASE_COLORS.softBlues[5], 0.52),
  bluish7: hexToRgba(BASE_COLORS.softBlues[5], 0.68),
  bluish8: hexToRgba(BASE_COLORS.softBlues[5], 0.84),
  // REDISH
  redish0: hexToRgba(BASE_COLORS.reds[5], 0.0),
  redish1: hexToRgba(BASE_COLORS.reds[5], 0.08),
  redish2: hexToRgba(BASE_COLORS.reds[5], 0.12),
  redish3: hexToRgba(BASE_COLORS.reds[5], 0.16),
  redish4: hexToRgba(BASE_COLORS.reds[5], 0.2),
  redish5: hexToRgba(BASE_COLORS.reds[5], 0.36),
  redish6: hexToRgba(BASE_COLORS.reds[5], 0.52),
  redish7: hexToRgba(BASE_COLORS.reds[5], 0.68),
  redish8: hexToRgba(BASE_COLORS.reds[5], 0.84),
  // YELLOWISH
  yellowish0: hexToRgba(BASE_COLORS.yellows[5], 0.0),
  yellowish1: hexToRgba(BASE_COLORS.yellows[5], 0.08),
  yellowish2: hexToRgba(BASE_COLORS.yellows[5], 0.12),
  yellowish3: hexToRgba(BASE_COLORS.yellows[5], 0.16),
  yellowish4: hexToRgba(BASE_COLORS.yellows[5], 0.2),
  yellowish5: hexToRgba(BASE_COLORS.yellows[5], 0.36),
  yellowish6: hexToRgba(BASE_COLORS.yellows[5], 0.52),
  yellowish7: hexToRgba(BASE_COLORS.yellows[5], 0.68),
  yellowish8: hexToRgba(BASE_COLORS.yellows[5], 0.84),
  // GREENISH
  greenish0: hexToRgba(BASE_COLORS.greens[5], 0.0),
  greenish1: hexToRgba(BASE_COLORS.greens[5], 0.08),
  greenish2: hexToRgba(BASE_COLORS.greens[5], 0.12),
  greenish3: hexToRgba(BASE_COLORS.greens[5], 0.16),
  greenish4: hexToRgba(BASE_COLORS.greens[5], 0.2),
  greenish5: hexToRgba(BASE_COLORS.greens[5], 0.36),
  greenish6: hexToRgba(BASE_COLORS.greens[5], 0.52),
  greenish7: hexToRgba(BASE_COLORS.greens[5], 0.68),
  greenish8: hexToRgba(BASE_COLORS.greens[5], 0.84),
  // UNPURPLISH
  unPurplish0: hexToRgba(BASE_COLORS.unPurples[5], 0.0),
  unPurplish1: hexToRgba(BASE_COLORS.unPurples[5], 0.08),
  unPurplish2: hexToRgba(BASE_COLORS.unPurples[5], 0.12),
  unPurplish3: hexToRgba(BASE_COLORS.unPurples[5], 0.16),
  unPurplish4: hexToRgba(BASE_COLORS.unPurples[5], 0.2),
  unPurplish5: hexToRgba(BASE_COLORS.unPurples[5], 0.36),
  unPurplish6: hexToRgba(BASE_COLORS.unPurples[5], 0.52),
  unPurplish7: hexToRgba(BASE_COLORS.unPurples[5], 0.68),
  unPurplish8: hexToRgba(BASE_COLORS.unPurples[5], 0.84),
};

export const COLOR_ALPHAS = {
  ...COLOR_ALPHAS_INNER,
  primarish0: HOSTNAME === REACT_APP_HOSTNAME.inewi ? COLOR_ALPHAS_INNER.bluish0 : COLOR_ALPHAS_INNER.unPurplish0,
  primarish1: HOSTNAME === REACT_APP_HOSTNAME.inewi ? COLOR_ALPHAS_INNER.bluish1 : COLOR_ALPHAS_INNER.unPurplish1,
  primarish2: HOSTNAME === REACT_APP_HOSTNAME.inewi ? COLOR_ALPHAS_INNER.bluish2 : COLOR_ALPHAS_INNER.unPurplish2,
  primarish3: HOSTNAME === REACT_APP_HOSTNAME.inewi ? COLOR_ALPHAS_INNER.bluish3 : COLOR_ALPHAS_INNER.unPurplish3,
  primarish4: HOSTNAME === REACT_APP_HOSTNAME.inewi ? COLOR_ALPHAS_INNER.bluish4 : COLOR_ALPHAS_INNER.unPurplish4,
  primarish5: HOSTNAME === REACT_APP_HOSTNAME.inewi ? COLOR_ALPHAS_INNER.bluish5 : COLOR_ALPHAS_INNER.unPurplish5,
  primarish6: HOSTNAME === REACT_APP_HOSTNAME.inewi ? COLOR_ALPHAS_INNER.bluish6 : COLOR_ALPHAS_INNER.unPurplish6,
  primarish7: HOSTNAME === REACT_APP_HOSTNAME.inewi ? COLOR_ALPHAS_INNER.bluish7 : COLOR_ALPHAS_INNER.unPurplish7,
  primarish8: HOSTNAME === REACT_APP_HOSTNAME.inewi ? COLOR_ALPHAS_INNER.bluish8 : COLOR_ALPHAS_INNER.unPurplish8,
};

const colorAliases = {
  black: BASE_COLORS.grays[8],
  white: BASE_COLORS.whites[0],
  grayBlue: BASE_COLORS.grayBlues[4],
  skyBlue: BASE_COLORS.skyBlues[4],
  red: BASE_COLORS.reds[4],
  green: BASE_COLORS.greens[4],
  yellow: BASE_COLORS.yellows[4],
  pink: BASE_COLORS.pinks[4],
  purples: BASE_COLORS.purples[4],
};

const baseColorsParser = (): Record<string, unknown> => {
  let newObj = {};

  Object.entries(BASE_COLORS).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      Object.keys({ ...value }).forEach((oldKey: unknown) => {
        newObj = { ...newObj, ...{ [`${key}${oldKey}`]: { ...value }[oldKey as number] } };
      });
    } else {
      newObj = { ...newObj, ...{ [`${key}`]: value } };
    }
  });

  return newObj;
};

const baseColorsParsed = baseColorsParser();

export const TEXT_COLORS = {
  darker: '#0E1737',
  default: '#253353',
  lighter: '#7D90AB',
  active: BASE_COLORS.primaries[6],
  reversed: BASE_COLORS.whites[1],
  error: BASE_COLORS.reds[5],
  positive: BASE_COLORS.greens[5],
};

const BACKGROUNDS = {
  levelFour: BASE_COLORS.backgrounds[0],
  levelThree: BASE_COLORS.backgrounds[1],
  levelTwo: BASE_COLORS.backgrounds[2],
  levelOne: BASE_COLORS.backgrounds[3],
  levelZero: BASE_COLORS.backgrounds[4],
  levelMinusOne: BASE_COLORS.backgrounds[5],
  levelMinusTwo: BASE_COLORS.backgrounds[6],
  levelMinusThree: BASE_COLORS.backgrounds[7],
  levelMinusFour: BASE_COLORS.backgrounds[8],
  //
  default: BASE_COLORS.whites[1],
  navbar: BASE_COLORS.whites[0],
  white: BASE_COLORS.whites[0],
  error: BASE_COLORS.reds[0],
  positive: BASE_COLORS.greens[0],
  //
  authorizedApp: BASE_COLORS.backgrounds[1],
};

const BADGE_COLORS = {
  pending: BASE_COLORS.skyBlues[5],
  default: BASE_COLORS.grays[1],
  lighter: COLOR_ALPHAS.lighter8,
  info: BASE_COLORS.primaries[4],
  success: BASE_COLORS.greens[4],
  danger: BASE_COLORS.reds[4],
  warning: BASE_COLORS.yellows[4],
};

const AI_GRADIENT_COLORS = {
  purple: BASE_COLORS.unPurples[5],
  red: '#EC4E20',
  orange: '#FF7D00',
  yellow: '#FFCB47',
};

const PRIMARY = BASE_COLORS.primaries[5];

const LOGO = HOSTNAME === REACT_APP_HOSTNAME.inewi ? TEXT_COLORS.default : BASE_COLORS.unPurples[5];

const INEWI_GREEN = '#65C823';

const REPORTS_COLORS = {
  present: BASE_COLORS.greens[5],
  incompleteShift: BASE_COLORS.yellows[4],
  overtime: BASE_COLORS.purples[4],
  absent: BASE_COLORS.reds[3],
  timeOff: BASE_COLORS.grays[3],
  customRequest: BASE_COLORS.grays[5],
  businessTrip: BASE_COLORS.greens[8],
  error: BASE_COLORS.reds[5],
  annotation: COLOR_ALPHAS.darker6,
  alphas: {
    present: hexToRgba(BASE_COLORS.greens[5], 0.35),
    incompleteShift: hexToRgba(BASE_COLORS.yellows[4], 0.35),
    overtime: hexToRgba(BASE_COLORS.purples[4], 0.35),
    absent: hexToRgba(BASE_COLORS.reds[3], 0.35),
    timeOff: hexToRgba(BASE_COLORS.grays[8], 0.5),
    error: hexToRgba(BASE_COLORS.reds[5], 0.2),
  },
  bg: {
    default: {
      cell: 'transparent',
      header: BASE_COLORS.whites[1],
      bookmark: COLOR_ALPHAS.darker7,
      details: hexToRgba(BASE_COLORS.grayBlues[7], 0.02),
    },
    secondary: {
      cell: hexToRgba(BASE_COLORS.grayBlues[7], 0.02),
      header: BASE_COLORS.whites[3],
      details: hexToRgba(BASE_COLORS.grayBlues[7], 0.07),
    },
    weekend: {
      cell: COLOR_ALPHAS.darker2,
      header: BASE_COLORS.whites[5],
      details: COLOR_ALPHAS.darker2,
    },
    holiday: {
      cell: COLOR_ALPHAS.bluish4,
      header: BASE_COLORS.whiteBlues[4],
      bookmark: BASE_COLORS.softBlues[3],
    },
    today: BASE_COLORS.reds[4],
    balanced: COLOR_ALPHAS.darker4,
  },
  text: {
    default: TEXT_COLORS.default,
    secondary: hexToRgba(BASE_COLORS.grays[7], 0.6),
    holiday: TEXT_COLORS.lighter,
    weekend: TEXT_COLORS.lighter,
    dayDetailsState: 'rgba(0,0,0,.8)',
    error: BASE_COLORS.reds[5],
    balanced: TEXT_COLORS.lighter,
    fancy: TEXT_COLORS.default,
    lighter: TEXT_COLORS.lighter,
  },
  cells: {
    customRequest: hexToRgba(BASE_COLORS.grays[8], 0.35),
    present: hexToRgba(BASE_COLORS.greens[5], 0.35),
    incompleteShift: hexToRgba(BASE_COLORS.yellows[4], 0.35),
    overtime: hexToRgba(BASE_COLORS.purples[4], 0.35),
    absent: hexToRgba(BASE_COLORS.reds[3], 0.35),
    timeOff: hexToRgba(BASE_COLORS.grays[3], 0.35),
    error: hexToRgba(BASE_COLORS.reds[5], 0.2),
    warning: COLOR_ALPHAS.yellowish4,
    pending: COLOR_ALPHAS.bluish7,
  },
  bookmark: {
    text: TEXT_COLORS.default,
  },
  cellDiver: COLOR_ALPHAS.darker4,
  clockLog: {
    addMissing: COLOR_ALPHAS.darker2,
    btn: {
      default: COLOR_ALPHAS.darker0,
      hover: COLOR_ALPHAS.darker2,
      tap: COLOR_ALPHAS.darker4,
      disabled: COLOR_ALPHAS.darker0,
      isIncomplete: COLOR_ALPHAS.redish3,
    },
    date: TEXT_COLORS.lighter,
  },
  correlatedDay: COLOR_ALPHAS.primarish4,
  dayCell: {
    detailsOpen: {
      bg: COLOR_ALPHAS.lighter1,
      border: 'rgba(0,0,0,.65)',
    },
    unpublishedChanges: COLOR_ALPHAS.bluish7,
  },
  dayCellState: {
    outline: 'transparent',
  },
  dayStateBadge: {
    bg: 'transparent',
    border: 'transparent',
  },
  dayStateRibbon: {
    text: colorAliases.white,
  },
  detailedSchedule: {
    background: {
      hover: HOSTNAME === REACT_APP_HOSTNAME.inewi ? BASE_COLORS.whiteBlues[1] : BASE_COLORS.unPurples[0],
    },
  },
  errors: {
    default: COLOR_ALPHAS.redish2,
    today: COLOR_ALPHAS.greenish4,
  },
  fancyDataBox: {
    bg: 'transparent',
    default: {
      bg: COLOR_ALPHAS.darker2,
      value: COLOR_ALPHAS.lighter7,
    },
    danger: {
      bg: COLOR_ALPHAS.redish1,
      bgHover: COLOR_ALPHAS.redish2,
      outline: COLOR_ALPHAS.redish5,
      outlineHover: COLOR_ALPHAS.redish7,
    },
  },
  fancyListItem: {
    bg: COLOR_ALPHAS.lighter7,
    outline: 'transparent',
    hover: {
      bg: isInewi ? BASE_COLORS.whiteBlues[0] : BASE_COLORS.unPurples[0],
      outline: COLOR_ALPHAS.primarish5,
    },
  },
  requestLimits: {
    limits: COLOR_ALPHAS.darker1,
    noLimits: TEXT_COLORS.lighter,
  },
  radio: {
    text: TEXT_COLORS.default,
    uncheckedDate: TEXT_COLORS.lighter,
  },
  requests: {
    add: {
      default: COLOR_ALPHAS.darker0,
      hover: COLOR_ALPHAS.darker2,
      tap: COLOR_ALPHAS.darker4,
      disabled: COLOR_ALPHAS.darker0,
    },
  },
  ribbon: {
    timeOff: hexToRgba(BASE_COLORS.grays[8], 0.5),
  },
  summaryDayCell: {
    detailsOpen: {
      bg: COLOR_ALPHAS.lighter1,
      border: 'rgba(0,0,0,.65)',
    },
    unpublishedChanges: COLOR_ALPHAS.bluish7,
  },
};

const CALENDAR_BACKGROUNDS = {
  default: {
    cell: 'transparent',
    header: BASE_COLORS.whites[1],
    bookmark: COLOR_ALPHAS.darker7,
    details: hexToRgba(BASE_COLORS.grayBlues[7], 0.02),
  },
  secondary: {
    cell: hexToRgba(BASE_COLORS.grayBlues[7], 0.02),
    header: BASE_COLORS.whites[3],
    details: hexToRgba(BASE_COLORS.grayBlues[7], 0.07),
  },
  weekend: {
    cell: COLOR_ALPHAS.darker2,
    header: BASE_COLORS.whites[5],
    details: COLOR_ALPHAS.darker2,
  },
  holiday: {
    cell: COLOR_ALPHAS.bluish4,
    header: BASE_COLORS.whiteBlues[4],
    bookmark: BASE_COLORS.softBlues[3],
  },
  today: BASE_COLORS.reds[4],
  error: hexToRgba(BASE_COLORS.reds[5], 0.2),
  warnings: COLOR_ALPHAS.yellowish4,
};

const CALENDAR_COLORS = {
  availability: {
    bg: {
      available: COLOR_ALPHAS.greenish3,
      notAvailable: COLOR_ALPHAS.redish3,
    },
    border: COLOR_ALPHAS.darker4,
  },
  dayCell: {
    detailsOpen: {
      bg: COLOR_ALPHAS.lighter1,
      border: 'rgba(0,0,0,.65)',
    },
    timeOff: REPORTS_COLORS.timeOff,
    weekend: CALENDAR_BACKGROUNDS.weekend.cell,
    holiday: CALENDAR_BACKGROUNDS.holiday.cell,
    warnings: CALENDAR_BACKGROUNDS.warnings,
    errors: CALENDAR_BACKGROUNDS.error,
    unpublishedChanges: COLOR_ALPHAS.bluish7,
    isAvailable: COLOR_ALPHAS.greenish2,
    isUnavailable: COLOR_ALPHAS.redish1,
  },
  dayCellLinkButtonContainer: COLOR_ALPHAS.darker5,
  detailedSchedule: {
    colorBox: {
      border: COLOR_ALPHAS.darker5,
      outline: COLOR_ALPHAS.bluish8,
    },
    container: {
      bg: {
        default: COLOR_ALPHAS.darker2,
        hover: REPORTS_COLORS.detailedSchedule.background.hover,
      },
      isAi: BASE_COLORS.darkBlues[8],
      pending: {
        default: COLOR_ALPHAS.bluish8,
        hover: COLOR_ALPHAS.primarish8,
      },
      withHover: COLOR_ALPHAS.primarish5,
    },
    iconBox: COLOR_ALPHAS.lighter7,
    ribbonElement: {
      bg: REPORTS_COLORS.alphas.timeOff,
      text: TEXT_COLORS.reversed,
    },
  },
  divider: TEXT_COLORS.lighter,
  dropZone: {
    container: COLOR_ALPHAS.bluish5,
    element: {
      default: {
        bg: COLOR_ALPHAS.lighter2,
        border: 'transparent',
      },
      hover: {
        bg: COLOR_ALPHAS.lighter5,
        text: BASE_COLORS.skyBlues[5],
        border: COLOR_ALPHAS.bluish7,
      },
    },
  },
  forms: {
    bg: {
      asideFilter: COLOR_ALPHAS.darker1,
      controlsSelectInput: {
        bg: 'transparent',
      },
      dropdown: BASE_COLORS.whites[0],
    },
  },
  elementLinkButtonContainer: COLOR_ALPHAS.darker5,
  request: {
    border: COLOR_ALPHAS.darker5,
    businessTrip: {
      bg: REPORTS_COLORS.businessTrip,
      text: TEXT_COLORS.reversed,
    },
    custom: {
      bg: COLOR_ALPHAS.darker1,
      border: REPORTS_COLORS.customRequest,
    },
    pending: COLOR_ALPHAS.bluish8,
    timeOff: REPORTS_COLORS.timeOff,
  },
  requestElement: {
    text: {
      default: TEXT_COLORS.lighter,
      hover: TEXT_COLORS.default,
    },
    note: COLOR_ALPHAS.lighter5,
  },
  ribbonElement: {
    text: TEXT_COLORS.reversed,
    timeOff: REPORTS_COLORS.alphas.timeOff,
    businessTrip: REPORTS_COLORS.businessTrip,
    customRequest: REPORTS_COLORS.customRequest,
  },
  schedule: {
    bg: {
      ai: {
        default: `linear-gradient(90deg, ${AI_GRADIENT_COLORS.purple}, ${AI_GRADIENT_COLORS.red}, ${AI_GRADIENT_COLORS.orange})`,
        defaultMirrored: `linear-gradient(90deg, ${AI_GRADIENT_COLORS.orange}, ${AI_GRADIENT_COLORS.red}, ${AI_GRADIENT_COLORS.purple})`,
        middle: `linear-gradient(90deg, ${AI_GRADIENT_COLORS.orange}, ${AI_GRADIENT_COLORS.yellow}, ${AI_GRADIENT_COLORS.orange})`,
        details: BACKGROUNDS.levelMinusTwo,
      },
    },
    isNotAi: COLOR_ALPHAS.darker5,
    pending: COLOR_ALPHAS.bluish8,
  },
  scheduleElement: {
    text: TEXT_COLORS.lighter,
    icon: COLOR_ALPHAS.lighter3,
  },
  schedules: {
    bg: {
      default: COLOR_ALPHAS.darker0,
      hover: COLOR_ALPHAS.darker2,
      tap: COLOR_ALPHAS.darker4,
      disabled: COLOR_ALPHAS.darker0,
      icon: COLOR_ALPHAS.darker4,
    },
  },
  setAvailability: {
    available: {
      bg: COLOR_ALPHAS.greenish5,
      outline: COLOR_ALPHAS.greenish8,
    },
    unavailable: {
      bg: COLOR_ALPHAS.redish5,
      outline: COLOR_ALPHAS.redish8,
    },
  },
  viewSettings: TEXT_COLORS.lighter,
  weekdaysPicker: {
    bg: COLOR_ALPHAS.primarish2,
    outline: COLOR_ALPHAS.primarish7,
  },
  workdayAvailability: {
    available: BASE_COLORS.greens[8],
    notAvailable: BASE_COLORS.reds[6],
  },
  warnings: {
    bg: COLOR_ALPHAS.yellowish4,
    text: BASE_COLORS.yellows[5],
  },
};

export const colors = makeTheme({
  colors: {
    ...colorAliases,
    ...BASE_COLORS,
    ...baseColorsParsed,
    alphas: COLOR_ALPHAS,
    primary: PRIMARY,
    logo: LOGO,
    inewiGreen: INEWI_GREEN,
    //
    texts: TEXT_COLORS,
    text: TEXT_COLORS.default,
    //
    backgrounds: BACKGROUNDS,
    background: BACKGROUNDS.default,
    //
    alerts: {
      background: {
        info: BASE_COLORS.skyBlues[5],
        danger: BASE_COLORS.reds[5],
        warning: BASE_COLORS.yellows[4],
      },
      text: {
        info: BASE_COLORS.whites[0],
        danger: BASE_COLORS.whites[0],
      },
      border: COLOR_ALPHAS.darker5,
    },
    asideFilters: {
      bg: {
        hover: COLOR_ALPHAS.darker1,
        active: COLOR_ALPHAS.primarish2,
      },
      btn: {
        common: {
          default: COLOR_ALPHAS.darker0,
          hover: COLOR_ALPHAS.darker1,
          tap: COLOR_ALPHAS.darker2,
          disabled: COLOR_ALPHAS.darker0,
        },
        filter: {
          default: COLOR_ALPHAS.darker1,
          hover: COLOR_ALPHAS.darker3,
          tap: COLOR_ALPHAS.darker4,
          disabled: COLOR_ALPHAS.darker0,
        },
        group: {
          default: COLOR_ALPHAS.primarish2,
          hover: COLOR_ALPHAS.primarish4,
          tap: COLOR_ALPHAS.primarish5,
          disabled: COLOR_ALPHAS.primarish1,
        },
      },
      calendar: BASE_COLORS.grays[4],
      checkbox: COLOR_ALPHAS.darker1,
      filterGroup: COLOR_ALPHAS.darker1,
      prependElement: 'currentColor',
      request: {
        pending: BASE_COLORS.whiteBlues[8],
        approve: BASE_COLORS.greens[5],
        deny: BASE_COLORS.reds[5],
        delete: BASE_COLORS.yellows[5],
      },
      search: {
        bg: {
          default: COLOR_ALPHAS.lighter4,
          hover: COLOR_ALPHAS.lighter2,
        },
      },
      statuses: {
        bg: {
          active: COLOR_ALPHAS.primarish2,
          hover: COLOR_ALPHAS.primarish4,
        },
        text: {
          enter: BASE_COLORS.greens[6],
          exit: BASE_COLORS.reds[5],
        },
      },
      statusTypes: {
        active: BASE_COLORS.greens[4],
        deleted: BASE_COLORS.yellows[4],
      },
    },
    attendancePill: {
      bg: {
        default: BACKGROUNDS.levelTwo,
        hover: BACKGROUNDS.levelZero,
        active: COLOR_ALPHAS.primarish2,
        activeHover: COLOR_ALPHAS.primarish4,
        withError: COLOR_ALPHAS.redish1,
        withErrorHover: COLOR_ALPHAS.redish2,
      },
      counterText: hexToRgba(TEXT_COLORS.darker, 0.55),
      errorOutline: COLOR_ALPHAS.redish5,
      person: {
        default: 'rgba(255,255,255,.75)',
        hover: 'rgba(255,255,255,.45)',
        withError: COLOR_ALPHAS.redish1,
        withErrorHover: COLOR_ALPHAS.redish2,
        late: COLOR_ALPHAS.yellowish2,
        lateHover: COLOR_ALPHAS.yellowish1,
      },
      variants: {
        default: COLOR_ALPHAS.darker4,
        success: BASE_COLORS.greens[4],
        warning: BASE_COLORS.yellows[4],
        danger: BASE_COLORS.reds[4],
      },
    },
    authentication: {
      bg: colorAliases.white,
      text: TEXT_COLORS.lighter,
      line: {
        border: BASE_COLORS.whites[4],
        text: TEXT_COLORS.lighter,
      },
      progress: COLOR_ALPHAS.primarish5,
    },
    authorized: {
      bg: {
        aside: BACKGROUNDS.levelOne,
      },
    },
    avatar: {
      background: COLOR_ALPHAS.darker3,
      text: COLOR_ALPHAS.darker7,
    },
    badge: {
      ...BADGE_COLORS,
      bg: {
        danger: BASE_COLORS.reds[5],
      },
      text: {
        default: 'rgba(14, 23, 55, 0.5)',
        info: TEXT_COLORS.reversed,
        lighter: TEXT_COLORS.default,
      },
    },
    button: {
      background: BACKGROUNDS.default,
    },
    calendar: CALENDAR_COLORS,
    calendarControlsSelectInputPlaceholder: 'inherit', // not in calendar bc of TS error in respective file
    calendarHideSelectArrowPlaceholder: 'inherit',
    cards: {
      default: {
        bg: BACKGROUNDS.levelThree,
        border: 'transparent',
      },
      hover: {
        bg: BACKGROUNDS.levelFour,
      },
      active: {
        border: PRIMARY,
      },
    },
    chat: {
      bg: {
        divider: COLOR_ALPHAS.darker4,
        userChatWindow: 'transparent',
        support: COLOR_ALPHAS.bluish4,
        supportUnread: COLOR_ALPHAS.lighter4,
      },
      text: {
        date: TEXT_COLORS.lighter,
        messagesList: TEXT_COLORS.lighter,
        sendButtonContainer: TEXT_COLORS.default,
        unread: BASE_COLORS.whites[0],
        support: BASE_COLORS.softBlues[5],
        resend: BASE_COLORS.reds[5],
        delete: BASE_COLORS.reds[5],
        error: TEXT_COLORS.error,
      },
      dot: {
        borderColor: BASE_COLORS.whites[0],
        red: 'red',
        green: BASE_COLORS.greens[4],
        unread: BASE_COLORS.softBlues[5],
      },
      hoverMenuContainer: {
        bg: BASE_COLORS.whites[0],
        text: BASE_COLORS.grayBlues[3],
        border: BASE_COLORS.whites[4],
      },
      mainWindow: {
        bg: {
          default: BASE_COLORS.backgrounds[0],
          hover: COLOR_ALPHAS.primarish1,
          hoverDarker: COLOR_ALPHAS.darker1,
          unreadMessage: BASE_COLORS.softBlues[5],
          hoverUnreadMessage: BASE_COLORS.softBlues[6],
        },
      },
    },
    chatSendButton: 'transparent', // not in chat bc of TS error in respective file
    checkbox: {
      background: {
        default: COLOR_ALPHAS.darker3,
        hover: COLOR_ALPHAS.darker5,
        checked: BASE_COLORS.primaries[5],
        disabled: BASE_COLORS.grays[2],
      },
      text: 'transparent',
      label: {
        default: TEXT_COLORS.default,
        disabled: BASE_COLORS.grays[3],
        additional: TEXT_COLORS.lighter,
      },
      checkMark: {
        default: BASE_COLORS.whites[0],
        hover: BASE_COLORS.whites[1],
      },
    },
    checkboxBgChecked: BASE_COLORS.primaries[5], // not in chat bc of TS error in respective file
    checkboxBgDisabled: BASE_COLORS.grays[2],
    clockLog: {
      arrow: {
        bg: {
          default: BASE_COLORS.grays[5],
          hover: BASE_COLORS.grays[3],
        },
        text: BASE_COLORS.whites[0],
        border: COLOR_ALPHAS.lighter2,
      },
      badge: {
        bg: BACKGROUNDS.default,
      },
      cell: {
        bg: {
          default: COLOR_ALPHAS.lighter7,
        },
      },
      edit: {
        btn: {
          markRejected: BASE_COLORS.reds[5],
        },
        history: {
          bg: COLOR_ALPHAS.darker1,
          border: COLOR_ALPHAS.darker3,
        },
        location: {
          bg: COLOR_ALPHAS.darker1,
          border: COLOR_ALPHAS.darker3,
        },
      },
      eventHistory: {
        badge: COLOR_ALPHAS.darker3,
        btn: {
          isOpen: COLOR_ALPHAS.darker3,
          notOpen: COLOR_ALPHAS.darker2,
        },
        show: COLOR_ALPHAS.darker3,
        versionContainer: COLOR_ALPHAS.darker2,
      },
      photoInfoIcon: {
        bg: {
          fraud: COLOR_ALPHAS.redish7,
          request: COLOR_ALPHAS.bluish7,
        },
        text: BASE_COLORS.whites[0],
      },
      photoLog: {
        arrow: {
          bg: {
            hover: BASE_COLORS.grays[3],
          },
          text: BASE_COLORS.whites[0],
          border: COLOR_ALPHAS.lighter2,
        },
        btn: {
          antiSpoof: {
            bg: COLOR_ALPHAS.redish2,
            border: COLOR_ALPHAS.redish5,
          },
          requestPending: {
            bg: COLOR_ALPHAS.bluish2,
            border: COLOR_ALPHAS.bluish5,
          },
        },
        photo: {
          border: COLOR_ALPHAS.darker2,
        },
        switcher: {
          bg: {
            default: COLOR_ALPHAS.darker1,
            hover: COLOR_ALPHAS.darker3,
            active: COLOR_ALPHAS.bluish2,
            hasAction: COLOR_ALPHAS.redish1,
          },
          border: {
            active: BASE_COLORS.skyBlues[5],
            hover: COLOR_ALPHAS.darker7,
          },
        },
      },
      photoStack: {
        bg1: BASE_COLORS.grayBlues[5],
        bg2: BASE_COLORS.grayBlues[7],
      },
      photoSelect: {
        border: {
          default: COLOR_ALPHAS.darker4,
          hover: COLOR_ALPHAS.darker5,
          selected: BASE_COLORS.skyBlues[5],
        },
        checkbox: {
          bg: BASE_COLORS.grayBlues[1],
          border: BASE_COLORS.whites[0],
        },
      },
      statusIcon: {
        default: TEXT_COLORS.lighter,
        error: BASE_COLORS.reds[5],
      },
    },
    colorPicker: 'rgba(252,252,252,.7)',
    datePicker: {
      calendar: {
        dayInteractive: {
          border: {
            default: 'transparent',
            inRange: COLOR_ALPHAS.darker4,
          },
          background: {
            inRange: COLOR_ALPHAS.primarish2,
            notDisabledHoverSelected: COLOR_ALPHAS.primarish6,
            notDisabledHoverNotSelected: COLOR_ALPHAS.darker2,
            today: BASE_COLORS.reds[4],
          },
          text: TEXT_COLORS.default,
        },
        dayInner: {
          border: {
            default: 'transparent',
            selected: COLOR_ALPHAS.darker6,
          },
          background: COLOR_ALPHAS.primarish8,
          text: BASE_COLORS.whites[0],
        },
        weekday: TEXT_COLORS.lighter,
      },
      popper: BASE_COLORS.whites[0],
    },
    drawer: {
      background: BASE_COLORS.backgrounds[0],
      warning: BASE_COLORS.reds[4],
    },
    dropdown: {
      background: BASE_COLORS.whites[0],
      dropdownLink: {
        background: {
          default: BASE_COLORS.whites[0],
          hover: BASE_COLORS.whites[2],
          tap: BASE_COLORS.whites[3],
          disabled: BASE_COLORS.grays[0],
        },
        color: {
          active: BASE_COLORS.primaries[5],
        },
        additionalInfo: {
          default: TEXT_COLORS.lighter,
          active: BASE_COLORS.primaries[5],
        },
      },
      dividerColors: {
        primary: COLOR_ALPHAS.darker3,
        secondary: COLOR_ALPHAS.darker4,
        darker: 'rgba(42, 65, 111, 0.2)',
      },
    },
    fancyDataBox: {
      bg: {
        container: BASE_COLORS.whites[2],
        time: BASE_COLORS.whites[4],
      },
    },
    fancyPersonBox: COLOR_ALPHAS.darker4,
    fileUpload: {
      bg: {
        icon: {
          danger: COLOR_ALPHAS.redish2,
          default: COLOR_ALPHAS.darker2,
          hover: COLOR_ALPHAS.primarish4,
          success: COLOR_ALPHAS.greenish2,
        },
        uploader: {
          default: COLOR_ALPHAS.darker2,
          hover: COLOR_ALPHAS.primarish2,
        },
        file: {
          danger: COLOR_ALPHAS.redish2,
          default: COLOR_ALPHAS.darker2,
          error: BASE_COLORS.reds[0],
          hover: COLOR_ALPHAS.primarish2,
          success: COLOR_ALPHAS.greenish2,
        },
        progressBar: {
          default: COLOR_ALPHAS.primarish7,
          error: BASE_COLORS.reds[1],
        },
      },
      text: {
        default: TEXT_COLORS.default,
        error: TEXT_COLORS.error,
        lighter: TEXT_COLORS.lighter,
      },
      deleteButton: {
        border: BACKGROUNDS.levelMinusOne,
        fill: TEXT_COLORS.lighter,
      },
      file: {
        outline: {
          danger: COLOR_ALPHAS.redish5,
          default: COLOR_ALPHAS.darker5,
          error: BASE_COLORS.reds[2],
          uploading: COLOR_ALPHAS.primarish7,
          notFound: BASE_COLORS.reds[1],
          success: COLOR_ALPHAS.greenish5,
          uploadedHover: COLOR_ALPHAS.primarish7,
        },
      },
      loadingStatus: {
        error: BASE_COLORS.reds[5],
      },
      uploader: COLOR_ALPHAS.primarish7,
      uploadingButtons: {
        background: {
          default: COLOR_ALPHAS.darker0,
          hover: COLOR_ALPHAS.darker2,
          tap: COLOR_ALPHAS.darker3,
          disabled: '',
        },
      },
      snackbar: BASE_COLORS.yellows[7],
    },
    formErrorMessage: {
      color: 'red',
    },
    headerMenuButton: {
      background: COLOR_ALPHAS.darker2,
      color: TEXT_COLORS.lighter,
    },
    home: {
      text: TEXT_COLORS.lighter,
      dateBox: {
        bg: {
          default: BASE_COLORS.whites[0],
          hover: HOSTNAME === REACT_APP_HOSTNAME.inewi ? BASE_COLORS.whiteBlues[0] : BASE_COLORS.unPurples[0],
          active: HOSTNAME === REACT_APP_HOSTNAME.inewi ? BASE_COLORS.whiteBlues[1] : BASE_COLORS.unPurples[1],
          activeHover: HOSTNAME === REACT_APP_HOSTNAME.inewi ? BASE_COLORS.whiteBlues[2] : BASE_COLORS.unPurples[2],
        },
        outline: {
          active: COLOR_ALPHAS.primarish5,
          default: 'transparent',
          hover: COLOR_ALPHAS.primarish5,
        },
        underline: BASE_COLORS.reds[4],
      },
      requestLimits: {
        bg: COLOR_ALPHAS.darker1,
        text: TEXT_COLORS.lighter,
      },
      yourStatsTimers: COLOR_ALPHAS.darker1,
    },
    input: {
      text: {
        default: TEXT_COLORS.default,
        placeholder: COLOR_ALPHAS.darker7,
        disabled: TEXT_COLORS.lighter,
      },
      background: {
        default: BASE_COLORS.whites[0],
        hover: BASE_COLORS.whites[2],
        focus: BASE_COLORS.whites[0],
        disabled: BASE_COLORS.grays[1],
      },
      border: {
        default: COLOR_ALPHAS.darker5,
        focus: COLOR_ALPHAS.primarish7,
      },
    },
    inputDefaultInputBg: 'transparent', // not in input bc of TS error in respective file
    inputNakedBg: 'transparent',
    kiosk: {
      text: {
        default: colorAliases.white,
        night: TEXT_COLORS.default,
      },
      camera: {
        bg: {
          exclamation: colorAliases.red,
        },
        text: {
          active: PRIMARY,
          exclamation: BASE_COLORS.whites[1],
        },
        btn: {
          bg: {
            default: 'rgba(74,90,116, 0.2)',
            hover: 'rgba(125,144,171, 0.5)',
            tap: 'rgba(74,90,116, 0.35)',
            disabled: 'rgba(206,233,241, 0.1)',
          },
          text: {
            default: BASE_COLORS.whites[1],
            night: TEXT_COLORS.default,
          },
        },
      },
      debug: {
        background: 'rgba(0,0,0,.35)',
        text: BASE_COLORS.whites[0],
        green: colorAliases.green,
        red: colorAliases.red,
        radioButtonGroup: {
          checked: {
            background: BASE_COLORS.whites[2],
            text: colorAliases.green,
          },
        },
      },
      faceScan: {
        text: colorAliases.white,
        btn: {
          bg: {
            default: 'rgba(255,255,255,0)',
            hover: 'rgba(255,255,255, 0.2)',
            tap: 'rgba(255,255,255, 0.1)',
            disabled: 'rgba(255,255,255,0)',
          },
        },
      },
      help: {
        border: PRIMARY,
        bg: {
          default: 'transparent',
          active: PRIMARY,
        },
      },
      loading: {
        bg: {
          default: 'rgba(0,0,0,.75)',
          noBackdrop: 'rgba(0,0,0,.55)',
        },
        text: colorAliases.white,
      },
      location: {
        bg: BASE_COLORS.whites[3],
      },
      mask: {
        border: BASE_COLORS.whites[0],
      },
      overlay: {
        bg: {
          darken: 'rgba(0,0,0,.25)',
          default: 'rgba(0,0,0,.75)',
          noBackdrop: 'rgba(0,0,0,.55)',
        },
      },
      point: 'rgba(255,255,255,.35)',
      progressBar: {
        bg: 'rgba(255,255,255, 0.2)',
        progress: BASE_COLORS.whites[1],
      },
      settings: {
        border: BASE_COLORS.whites[3],
        text: TEXT_COLORS.lighter,
      },
      sleepMode: {
        bg: {
          default: 'rgba(0,0,0,.98)',
          noBackdrop: 'rgba(0,0,0,.85)',
        },
        text: BASE_COLORS.whites[1],
      },
      summary: {
        text: colorAliases.white,
        btn: {
          text: colorAliases.white,
          cancel: {
            bg: {
              default: 'rgba(255,255,255, 0)',
              hover: 'rgba(255,255,255, 0.2)',
              tap: 'rgba(255,255,255, 0.1)',
              disabled: 'rgba(255,255,255, 0)',
            },
          },
          change: {
            bg: {
              default: 'rgba(255,255,255, 0.2)',
              hover: 'rgba(255,255,255, 0.3)',
              tap: 'rgba(255,255,255, 0.2)',
              disabled: 'rgba(255,255,255, 0.3)',
            },
          },
        },
      },
      summaryButton: {
        bg: {
          default: 'rgba(255,255,255, 0.2)',
          hover: 'rgba(255,255,255, 0.3)',
          tap: 'rgba(255,255,255, 0.2)',
          disabled: 'rgba(255,255,255, 0.2)',
        },
        text: BASE_COLORS.whites[1],
      },
    },
    kioskCameraViewButtonActive: COLOR_ALPHAS.primarish5, // not in kiosk prop bc of TS error in respective file
    kioskCameraViewButtonActiveHover: COLOR_ALPHAS.primarish6,
    kioskCameraViewButtonWarning: 'rgba(255, 198, 28, 0.45)',
    kioskCameraViewButtonWarningHover: 'rgba(255, 198, 28, 0.55)',
    link: {
      default: 'inherit',
      standOut: PRIMARY,
      hover: BASE_COLORS.primaries[5],
      hoverStandOut: BASE_COLORS.primaries[3],
      active: BASE_COLORS.primaries[5],
      activeStandOut: BASE_COLORS.primaries[4],
    },
    list: {
      rowBackground: {
        default: BASE_COLORS.whites[0],
        inverted: BASE_COLORS.whites[2],
        hover: BASE_COLORS.whiteBlues[0],
        hoverFancy: HOSTNAME === REACT_APP_HOSTNAME.inewi ? BASE_COLORS.whiteBlues[0] : BASE_COLORS.unPurples[0],
        selected: BASE_COLORS.whiteBlues[1],
        selectedHover: BASE_COLORS.whiteBlues[2],
        nonEditable: BASE_COLORS.whites[2],
        nonEditableHover: BASE_COLORS.whites[4],
        error: COLOR_ALPHAS.redish1,
        errorHover: COLOR_ALPHAS.redish2,
        pending: `repeating-linear-gradient(-45deg, ${COLOR_ALPHAS.bluish3}, ${COLOR_ALPHAS.bluish3} 10px, ${BASE_COLORS.whites[0]} 10px, ${BASE_COLORS.whites[0]} 20px)`,
        pendingHover: `repeating-linear-gradient(-45deg, ${COLOR_ALPHAS.bluish5}, ${COLOR_ALPHAS.bluish5} 10px, ${BASE_COLORS.whites[0]} 10px, ${BASE_COLORS.whites[0]} 20px)`,
        warning: COLOR_ALPHAS.yellowish1,
        warningHover: COLOR_ALPHAS.yellowish2,
        errorSelected: COLOR_ALPHAS.redish4,
      },
      rowOutline: {
        error: COLOR_ALPHAS.redish5,
        errorHover: COLOR_ALPHAS.redish7,
        pending: COLOR_ALPHAS.bluish3,
        pendingHover: COLOR_ALPHAS.bluish5,
        warning: COLOR_ALPHAS.yellowish5,
        warningHover: COLOR_ALPHAS.yellowish7,
      },
    },
    loading: {
      loadingOverlay: {
        background: 'rgba(255, 255, 255, 0.8)',
        color: '#465F86',
      },
      loadingSpinner: {
        color: 'black',
      },
      loadingSpinnerCSS: {
        color: '#465F86',
      },
      loadingSplash: {
        background: 'white',
        color: '#465F86',
      },
    },
    location: {
      bg: COLOR_ALPHAS.darker1,
      text: TEXT_COLORS.lighter,
      border: COLOR_ALPHAS.darker3,
    },
    main: {
      dateRangeBadge: {
        bg: COLOR_ALPHAS.primarish3,
        outline: COLOR_ALPHAS.primarish5,
      },
      deselectMenu: TEXT_COLORS.lighter,
      fetchingIndicator: {
        bg: COLOR_ALPHAS.primarish3,
        outline: COLOR_ALPHAS.primarish5,
      },
      variantsLink: {
        badge: BASE_COLORS.whites[0],
        btn: TEXT_COLORS.lighter,
        hover: TEXT_COLORS.default,
        active: TEXT_COLORS.default,
        shadow: BASE_COLORS.primaries[5],
      },
      variantsMenu: COLOR_ALPHAS.darker4,
    },
    manageAvatar: {
      cropper: {
        background: PRIMARY,
        outlineColor: COLOR_ALPHAS.primarish5,
      },
      picker: {
        borderColor: COLOR_ALPHAS.darker2,
      },
      deleteButton: {
        background: {
          default: COLOR_ALPHAS.lighter7,
          hover: BASE_COLORS.whites[0],
          tap: BASE_COLORS.whites[1],
          disabled: '',
        },
      },
      addButton: {
        background: {
          default: COLOR_ALPHAS.lighter5,
          hover: COLOR_ALPHAS.primarish5,
          tap: COLOR_ALPHAS.primarish6,
          disabled: '',
        },
      },
    },
    map: {
      circle: {
        stroke: '#4679BD',
        fill: '#4679BD',
      },
    },
    modal: {
      backdrop: COLOR_ALPHAS.darker6,
      background: BASE_COLORS.backgrounds[0],
    },
    navbar: {
      background: BASE_COLORS.backgrounds[0],
      color: {
        active: TEXT_COLORS.active,
        lighter: BASE_COLORS.grayBlues[3],
      },
      getMobileApps: {
        btn: {
          bg: {
            default: BASE_COLORS.grays[8],
            hover: BASE_COLORS.grays[6],
            tap: BASE_COLORS.primaries[7],
            disabled: '',
          },
          text: BASE_COLORS.whites[0],
        },
      },
      iconButton: {
        background: {
          default: COLOR_ALPHAS.darker2,
          hover: COLOR_ALPHAS.primarish3,
          tap: COLOR_ALPHAS.primarish4,
          active: COLOR_ALPHAS.primarish4,
          disabled: BASE_COLORS.grays[3],
        },
        color: {
          active: TEXT_COLORS.active,
          lighter: BASE_COLORS.grayBlues[3],
        },
      },
      menu: {
        icon: {
          bg: {
            hover: BASE_COLORS.whites[4],
          },
          text: {
            default: TEXT_COLORS.lighter,
            hover: TEXT_COLORS.default,
          },
        },
      },
      menuButton: {
        background: {
          default: COLOR_ALPHAS.primarish0,
          hover: COLOR_ALPHAS.primarish2,
          tap: COLOR_ALPHAS.primarish4,
          disabled: COLOR_ALPHAS.primarish0,
        },
        color: {
          active: TEXT_COLORS.active,
        },
        icon: TEXT_COLORS.lighter,
      },
    },
    notification: {
      background: {
        hub: hexToRgba(BACKGROUNDS.white, 0.84),
        hover: BACKGROUNDS.levelFour,
        center: BACKGROUNDS.levelTwo,
        centerHover: BACKGROUNDS.levelZero,
      },
      text: {
        date: TEXT_COLORS.lighter,
        default: TEXT_COLORS.default,
        message: TEXT_COLORS.lighter,
      },
      closeButton: {
        fill: BASE_COLORS.grayBlues[3],
        background: {
          default: COLOR_ALPHAS.lighter7,
          hover: BASE_COLORS.whites[0],
          tap: BASE_COLORS.whites[1],
          disabled: '',
        },
        borderColor: COLOR_ALPHAS.darker2,
      },

      type: {
        success: BASE_COLORS.greens[4],
        warning: BASE_COLORS.yellows[4],
        info: BASE_COLORS.softBlues[4],
        danger: BASE_COLORS.reds[4],
        love: BASE_COLORS.pinks[4],
      },
      icon: {
        color: COLOR_ALPHAS.darker8,
        borderColor: 'rgba(0,0,0,.075)',
      },
    },
    onboarding: {
      bg: {
        default: BASE_COLORS.whites[0],
      },
      text: {
        default: TEXT_COLORS.lighter,
      },
      backdrop: COLOR_ALPHAS.darker6,
      focusBackdrop: 'transparent',
      modalHeaderIcon: {
        bg: BASE_COLORS.whiteBlues[0],
        border: COLOR_ALPHAS.darker2,
      },
    },
    payments: {
      bg: {
        error: hexToRgba(BASE_COLORS.reds[2], 0.25),
        gradients: {
          success: `linear-gradient(270deg, ${hexToRgba(BASE_COLORS.greens[5], 0.24)} 0%, ${hexToRgba(
            BASE_COLORS.greens[2],
            0.16,
          )} 100%)`,
          successHover: `linear-gradient(270deg, ${hexToRgba(BASE_COLORS.greens[4], 0.24)} 0%, ${hexToRgba(
            BASE_COLORS.greens[1],
            0.16,
          )} 100%)`,
        },
        info: COLOR_ALPHAS.primarish1,
        paymentContainer: BACKGROUNDS.levelFour,
        success: hexToRgba(BASE_COLORS.greens[2], 0.05),
      },
      border: {
        default: 'transparent',
        error: BASE_COLORS.reds[4],
        success: BASE_COLORS.greens[4],
        warning: BASE_COLORS.yellows[4],
      },
      text: TEXT_COLORS.lighter,
      annualSwitch: {
        border: COLOR_ALPHAS.darker5,
        borderHover: COLOR_ALPHAS.bluish5,
        base: BASE_COLORS.greens[5],
      },
      cancel: BASE_COLORS.reds[4],
      discountContainer: COLOR_ALPHAS.darker5,
      error: BASE_COLORS.reds[5],
      infoContainer: COLOR_ALPHAS.primarish3,
      loadingOverlay: 'transparent',
      moduleSwitch: {
        bg: BASE_COLORS.whites[2],
        border: 'transparent',
        hover: {
          bg: COLOR_ALPHAS.primarish1,
          border: COLOR_ALPHAS.primarish5,
        },
      },
      paymentMethod: BASE_COLORS.whites[2],
    },
    paymentTicker: {
      default: {
        background: BASE_COLORS.grayBlues[1],
        counter: COLOR_ALPHAS.darker4,
        text: TEXT_COLORS.lighter,
      },
      warning: {
        background: BASE_COLORS.yellows[0],
        counter: BASE_COLORS.yellows[2],
        text: BASE_COLORS.yellows[7],
      },
      danger: {
        background: BASE_COLORS.reds[0],
        counter: BASE_COLORS.reds[2],
        text: BASE_COLORS.reds[7],
      },
    },
    preparingFile: {
      ready: BASE_COLORS.greens[5],
      error: BASE_COLORS.reds[5],
    },
    progressBar: {
      background: COLOR_ALPHAS.primarish4,
      progress: PRIMARY,
    },
    quickbooksDisconnect: BASE_COLORS.greens[5],
    radio: {
      bg: {
        checked: BASE_COLORS.whites[1],
        checkedDisabled: BASE_COLORS.whites[1],
        default: COLOR_ALPHAS.darker3,
        error: BACKGROUNDS.error,
        hover: BASE_COLORS.whites[1],
      },
      border: {
        checked: BASE_COLORS.primaries[5],
        default: COLOR_ALPHAS.darker3,
        hover: COLOR_ALPHAS.darker5,
      },
      text: {
        error: TEXT_COLORS.error,
      },
    },
    radioBorderChecked: BASE_COLORS.primaries[5],
    radioBorderHover: COLOR_ALPHAS.darker5,
    reports: REPORTS_COLORS,
    requests: {
      arrow: COLOR_ALPHAS.darker5,
      arrowRight: TEXT_COLORS.lighter,
      asideWrapper: {
        bg: {
          default: 'transparent',
          expanded: BASE_COLORS.backgrounds[3],
        },
      },
      badge: {
        default: COLOR_ALPHAS.darker4,
        lighter: COLOR_ALPHAS.darker1,
        businessTrip: {
          bg: BASE_COLORS.greens[8],
          text: BASE_COLORS.whites[0],
        },
        delete: COLOR_ALPHAS.redish4,
      },
      dateRangeDisplay: COLOR_ALPHAS.darker5,
      detailsHeader: {
        bg: {
          deleted: COLOR_ALPHAS.yellowish1,
          zus: COLOR_ALPHAS.darker2,
          accepted: BASE_COLORS.greens[0],
          rejected: COLOR_ALPHAS.redish1,
          pending: COLOR_ALPHAS.bluish1,
        },
      },
      duration: BASE_COLORS.grayBlues[3],
      error: BASE_COLORS.reds[5],
      fancySelectedType: COLOR_ALPHAS.darker4,
      history: {
        bg: {
          created: BASE_COLORS.whites[6],
          ungrouped: COLOR_ALPHAS.bluish1,
          ungroupedHover: COLOR_ALPHAS.bluish3,
        },
        text: TEXT_COLORS.lighter,
      },
      message: {
        blue: {
          bg: COLOR_ALPHAS.bluish2,
          border: COLOR_ALPHAS.bluish5,
        },
        red: {
          bg: COLOR_ALPHAS.redish2,
          border: COLOR_ALPHAS.redish5,
        },
      },
      newComparisonDifference: BASE_COLORS.greens[5],
      noOriginalNote: TEXT_COLORS.lighter,
      noSchedule: TEXT_COLORS.lighter,
      originalComparisonDifference: BASE_COLORS.reds[5],
      rejectReason: TEXT_COLORS.lighter,
      selectedPersonsList: COLOR_ALPHAS.darker4,
      status: {
        deleted: BASE_COLORS.yellows[5],
        approved: BASE_COLORS.greens[5],
        deny: BASE_COLORS.reds[5],
        pending: BASE_COLORS.whiteBlues[8],
      },
    },
    requestTypePresenter: {
      color: TEXT_COLORS.lighter,
    },
    select: {
      colorSquare: {
        outline: BASE_COLORS.whites[0],
        background: BASE_COLORS.whites[0],
      },
      duration: {
        color: {
          active: BASE_COLORS.whites[8],
          default: BASE_COLORS.grayBlues[3],
        },
      },
      loading: COLOR_ALPHAS.darker1,
      option: {
        text: {
          active: BASE_COLORS.whites[0],
          disabled: TEXT_COLORS.lighter,
          selected: TEXT_COLORS.default,
        },
        bg: {
          hover: COLOR_ALPHAS.primarish1,
          active: BASE_COLORS.primaries[5],
          selectedCreatable: BASE_COLORS.primaries[5],
        },
      },
      optionList: {
        background: {
          default: BASE_COLORS.whites[0],
        },
      },
      person: {
        color: BASE_COLORS.whites[0],
      },
      rectangle: {
        abbreviation: {
          background: {
            active: COLOR_ALPHAS.darker8,
            default: COLOR_ALPHAS.darker4,
          },
        },
        business: {
          background: {
            active: BASE_COLORS.whites[0],
            default: BASE_COLORS.greens[8],
          },
          color: {
            active: BASE_COLORS.greens[8],
            default: BASE_COLORS.whites[0],
          },
        },
        number: {
          background: TEXT_COLORS.default,
          color: BASE_COLORS.whites[0],
        },
      },
      selectOption: {
        text: {
          default: BASE_COLORS.whites[0],
          multi: hexToRgba(TEXT_COLORS.darker, 0.4),
        },
        background: {
          informational: BASE_COLORS.whites[0],
          selected: BASE_COLORS.grays[8],
          multi: 'transparent',
        },
      },
      statusBadge: {
        background: {
          clockIn: BASE_COLORS.whites[0],
          clockOut: COLOR_ALPHAS.darker8,
        },
        color: BASE_COLORS.whites[0],
      },
    },
    settings: {
      border: 'rgba(42, 65, 111, 0.1)',
      text: 'rgba(14, 23, 55, 0.6)',
      additionalInfo: TEXT_COLORS.lighter,
      additionalLabel: TEXT_COLORS.lighter,
      advancedBetaOptIn: {
        bg: COLOR_ALPHAS.primarish1,
        border: COLOR_ALPHAS.primarish2,
      },
      badge: {
        bg: COLOR_ALPHAS.darker4,
        text: 'rgba(14, 23, 55, 0.6)',
      },
      badgeSpecial: {
        bg: BASE_COLORS.softBlues[4],
        text: hexToRgba(TEXT_COLORS.darker, 0.6),
      },
      connected: {
        green: BASE_COLORS.greens[4],
      },
      customRequestList: COLOR_ALPHAS.darker1,
      disconnect: {
        default: BASE_COLORS.reds[5],
        hover: BASE_COLORS.reds[4],
      },
      expired: {
        border: BASE_COLORS.yellows[4],
        default: BASE_COLORS.yellows[5],
        hover: BASE_COLORS.yellows[4],
        tag: BASE_COLORS.yellows[4],
      },
      googleButton: COLOR_ALPHAS.darker2,
      headerWithList: COLOR_ALPHAS.lighter1,
      holidays: COLOR_ALPHAS.darker1,
      integrationBox: {
        bg: BASE_COLORS.whites[0],
        border: 'transparent',
      },
      navContainer: BACKGROUNDS.levelOne,
      settingsGroup: COLOR_ALPHAS.darker3,
      timeOffList: COLOR_ALPHAS.darker1,
      timeRounding: TEXT_COLORS.lighter,
      timeSampleBox: {
        bg: COLOR_ALPHAS.lighter1,
        text: TEXT_COLORS.lighter,
      },
      timezone: COLOR_ALPHAS.darker1,
    },
    settingsNavLink: {
      background: {
        default: COLOR_ALPHAS.darker2,
        hover: COLOR_ALPHAS.darker1,
        active: BASE_COLORS.primaries[5],
      },
      text: {
        default: TEXT_COLORS.default,
        active: TEXT_COLORS.reversed,
        additionalLabel: TEXT_COLORS.lighter,
      },
    },
    showFieldsButton: {
      background: {
        hover: COLOR_ALPHAS.darker1,
      },
    },
    signIn: {
      bg: {
        default: BASE_COLORS.whites[0],
        disabled: '',
        hover: BASE_COLORS.whites[1],
        tap: BASE_COLORS.whiteBlues[1],
      },
      border: {
        default: '#dadce0',
        hover: '#d2e3fc',
      },
      error: TEXT_COLORS.error,
      loading: COLOR_ALPHAS.darker2,
      text: TEXT_COLORS.default,
    },
    signUp: {
      bg: {
        default: COLOR_ALPHAS.darker0,
        hover: COLOR_ALPHAS.darker1,
      },
      privacy: PRIMARY,
      text: {
        primary: TEXT_COLORS.default,
        secondary: TEXT_COLORS.lighter,
      },
    },
    snackbar: {
      borderColor: COLOR_ALPHAS.darker2,
      default: {
        background: BASE_COLORS.grays[7],
        text: TEXT_COLORS.reversed,
      },
      success: {
        background: BASE_COLORS.greens[6],
        text: TEXT_COLORS.reversed,
      },
      warning: {
        background: BASE_COLORS.yellows[4],
        text: TEXT_COLORS.default,
      },
      danger: {
        background: BASE_COLORS.reds[5],
        text: TEXT_COLORS.reversed,
      },
      button: {
        background: {
          default: COLOR_ALPHAS.lighter4,
          hover: COLOR_ALPHAS.lighter2,
          tap: COLOR_ALPHAS.lighter5,
          disabled: BASE_COLORS.grays[3],
        },
        text: TEXT_COLORS.default,
      },
    },
    stickyList: {
      bg: {
        cell: {
          error: 'transparent',
        },
        grid: {
          weekend: COLOR_ALPHAS.darker2,
          holiday: COLOR_ALPHAS.bluish4,
        },
        header: {
          default: {
            list: `linear-gradient(180deg, ${BASE_COLORS.backgrounds[1]} 0%, ${BASE_COLORS.backgrounds[1]} 85%, ${hexToRgba(
              BASE_COLORS.backgrounds[1],
              0,
            )} 100%)`,
            grid: BACKGROUNDS.levelThree,
          },
          inverted: {
            list: `linear-gradient(180deg, ${BASE_COLORS.backgrounds[0]} 0%, ${BASE_COLORS.backgrounds[0]} 85%, ${hexToRgba(
              BASE_COLORS.backgrounds[0],
              0,
            )} 100%)`,
            grid: BACKGROUNDS.levelFour,
          },
          darkerOne: {
            list: `linear-gradient(180deg, ${BASE_COLORS.backgrounds[3]} 0%, ${BASE_COLORS.backgrounds[3]} 85%, ${hexToRgba(
              BASE_COLORS.backgrounds[1],
              0,
            )} 100%)`,
          },
        },
        row: {
          hover: BASE_COLORS.whiteBlues[0],
          selected: 'none',
        },
      },
      text: {
        grid: {
          default: TEXT_COLORS.default,
        },
        header: {
          default: TEXT_COLORS.default,
        },
        row: {
          default: TEXT_COLORS.default,
        },
      },
      outline: {
        frozen: BASE_COLORS.backgrounds[1],
        grid: {
          default: 'transparent',
          hover: COLOR_ALPHAS.bluish5,
          selected: COLOR_ALPHAS.bluish5,
        },
        gridCell: {
          default: BACKGROUNDS.authorizedApp,
          inverted: BACKGROUNDS.levelZero,
        },
        row: {
          default: 'transparent',
          hover: COLOR_ALPHAS.bluish5,
          nonEditableHover: COLOR_ALPHAS.darker5,
          selected: COLOR_ALPHAS.bluish5,
        },
      },
      outlineColor: BACKGROUNDS.levelZero,
      empty: TEXT_COLORS.lighter,
      secondaryText: TEXT_COLORS.lighter,
      uncheckable: BACKGROUNDS.levelMinusOne,
      primaryText: TEXT_COLORS.darker,
      checkbox: 'transparent',
      radio: 'transparent',
    },
    switch: {
      background: {
        default: COLOR_ALPHAS.darker3,
        hover: COLOR_ALPHAS.darker5,
        checked: BASE_COLORS.primaries[5],
        disabled: COLOR_ALPHAS.darker1,
        checkedAndDisabled: hexToRgba(BASE_COLORS.primaries[5], 0.5),
      },
      indicator: {
        default: BASE_COLORS.whites[0],
        disabled: BASE_COLORS.whites[0],
      },
    },
    switchBgChecked: BASE_COLORS.primaries[5],
    switchBgCheckedAndDisabled: hexToRgba(BASE_COLORS.primaries[5], 0.5),
    switchBgDisabled: COLOR_ALPHAS.darker1,
    team: {
      addEditRole: COLOR_ALPHAS.primarish3,
      addEditTag: COLOR_ALPHAS.primarish3,
      advancedFeatures: TEXT_COLORS.lighter,
      employeeStates: TEXT_COLORS.lighter,
      getEmployeeStates: {
        default: {
          text: COLOR_ALPHAS.darker6,
          hover: COLOR_ALPHAS.darker8,
        },
        warning: {
          text: BASE_COLORS.yellows[5],
          hover: BASE_COLORS.yellows[6],
        },
      },
      import: {
        firstStep: {
          bg: COLOR_ALPHAS.darker3,
          outline: COLOR_ALPHAS.darker5,
        },
        secondStep: {
          bg: COLOR_ALPHAS.primarish3,
          outline: COLOR_ALPHAS.primarish5,
          text: PRIMARY,
        },
      },
      navigation: {
        bg: {
          active: PRIMARY,
          default: BASE_COLORS.whites[2],
          hoverNotActive: BASE_COLORS.whites[3],
        },
        text: {
          active: BASE_COLORS.whites[0],
          default: TEXT_COLORS.default,
        },
      },
      nestedRequestLimits: TEXT_COLORS.lighter,
      nestedTimeOffLimits: TEXT_COLORS.lighter,
      qrCard: BASE_COLORS.whites[5],
      selectedRoleDescription: TEXT_COLORS.lighter,
      userEmploymentDetails: TEXT_COLORS.lighter,
    },
    textArea: {
      counter: TEXT_COLORS.lighter,
    },
    textInput: {
      icon: {
        default: TEXT_COLORS.lighter,
        hover: TEXT_COLORS.default,
        error: {
          default: TEXT_COLORS.error,
          noValue: BASE_COLORS.reds[5],
        },
      },
      error: {
        text: {
          default: TEXT_COLORS.error,
          placeholder: BASE_COLORS.reds[3],
        },
        background: BACKGROUNDS.error,
      },
    },
    timeEventTimer: {
      bg: {
        default: BACKGROUNDS.levelTwo,
        hover: BACKGROUNDS.levelZero,
      },
      text: {
        maxDuration: TEXT_COLORS.lighter,
        startTime: TEXT_COLORS.lighter,
      },
    },
    tooltip: {
      background: BASE_COLORS.grays[7],
      text: TEXT_COLORS.reversed,
      textDecoration: BASE_COLORS.grayBlues[7],
    },
    twoDigitInput: {
      background: {
        default: COLOR_ALPHAS.darker3,
        hover: COLOR_ALPHAS.darker4,
        focus: COLOR_ALPHAS.darker2,
      },
      bg: {
        default: COLOR_ALPHAS.darker4,
        focus: COLOR_ALPHAS.primarish4,
        hover: COLOR_ALPHAS.darker2,
      },
    },
    workStatus: {
      background: {
        clockIn: COLOR_ALPHAS.greenish2,
        clockOut: COLOR_ALPHAS.redish2,
        custom: COLOR_ALPHAS.darker2,
        break: COLOR_ALPHAS.yellowish2,
        task: COLOR_ALPHAS.bluish4,
        late: COLOR_ALPHAS.yellowish3,
      },
      selectOptionBackground: {
        active: {
          start: BASE_COLORS.greens[5],
          end: BASE_COLORS.reds[5],
          custom: BASE_COLORS.skyBlues[5],
        },
        hover: {
          start: COLOR_ALPHAS.greenish1,
          end: COLOR_ALPHAS.redish1,
          custom: COLOR_ALPHAS.bluish1,
        },
      },
      border: {
        clockIn: COLOR_ALPHAS.greenish6,
        clockOut: COLOR_ALPHAS.redish5,
        custom: COLOR_ALPHAS.darker5,
        break: COLOR_ALPHAS.yellowish5,
        task: COLOR_ALPHAS.bluish6,
        late: COLOR_ALPHAS.yellowish6,
      },
      text: {
        clockIn: BASE_COLORS.greens[6],
        clockOut: BASE_COLORS.reds[5],
        custom: TEXT_COLORS.default,
        break: TEXT_COLORS.default,
        task: TEXT_COLORS.default,
        late: TEXT_COLORS.default,
      },
      icon: {
        circle: {
          start: BASE_COLORS.greens[6],
          end: BASE_COLORS.reds[5],
          late: BASE_COLORS.yellows[6],
        },
      },
    },
    workTimer: {
      background: {
        container: {
          default: COLOR_ALPHAS.darker2,
          hover: BASE_COLORS.greens[0],
          activeHover: BASE_COLORS.reds[0],
        },
        button: {
          default: BASE_COLORS.greens[6],
          hover: BASE_COLORS.greens[8],
          tap: BASE_COLORS.greens[6],
          disabled: BASE_COLORS.greens[5],
        },
      },
      icon: {
        fill: {
          default: BASE_COLORS.greens[6],
          hover: BASE_COLORS.greens[8],
          active: BASE_COLORS.reds[6],
          activeHover: BASE_COLORS.reds[8],
          custom: BASE_COLORS.grayBlues[6],
        },
      },
      text: {
        default: TEXT_COLORS.lighter,
        hover: BASE_COLORS.greens[8],
        active: TEXT_COLORS.lighter,
        activeHover: BASE_COLORS.reds[8],
        custom: TEXT_COLORS.default,
      },
    },
    //
    modes: {
      // dark: {
      // in order to replace styles in dark mode, copy "colors" props, e.g.:
      // text: 'white',
      // formErrorMessage: {
      // color: 'white',
      // },
      // In your components, using e.g. sx={{ color: 'text' }} will automatically pick up the current color mode, with no adaptation necessary, even if you add more color modes later.
      //   datePicker: {
      //     calendar: {
      //       dayInteractive: {
      //         borders: {
      //           default: 'red',
      //           inRange: 'red',
      //           selected: 'red',
      //         },
      //       },
      //     },
      //   },
      // },
    },
  },
});
