import React from 'react';

import { RequestChangeDetailsContainer } from './RequestChangeDetails/RequestChangeDetailsContainer';

export const TimeOffRequestChange = (): React.ReactElement => (
  <RequestChangeDetailsContainer>
    <RequestChangeDetailsContainer.Type />
    <RequestChangeDetailsContainer.Date />
    <RequestChangeDetailsContainer.Note />
    <RequestChangeDetailsContainer.Duration />
    <RequestChangeDetailsContainer.ForDay />
    <RequestChangeDetailsContainer.Attachments />
  </RequestChangeDetailsContainer>
);
