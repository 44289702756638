import React from 'react';

import { CurrentError, ErrorInfo } from './ErrorBoundary.types';

interface Props {
  currentError: CurrentError;
  errorInfo: ErrorInfo;
  debugMode: boolean;
}

function refreshPage(): void {
  window.location.reload();
}

export const ErrorPanel: React.ElementType = ({ currentError, errorInfo, debugMode }: Props) => {
  let debugMessage: React.ReactNode;

  if (errorInfo && currentError && debugMode) {
    debugMessage = (
      <div>
        <h5>Error message:</h5>
        <pre>
          <code>{currentError.message}</code>
        </pre>
        <h5>Error stack:</h5>
        <pre>
          <code>{currentError.stack}</code>
        </pre>
        <h5>Component stack:</h5>
        <pre>
          <code>{errorInfo.componentStack}</code>
        </pre>
      </div>
    );
  }

  return (
    <div>
      <h4> Oops! 😳</h4>
      <p>Something unexpected has occured. Please wait a few moments, then reload the application.</p>

      <button type="button" onClick={refreshPage}>
        Refresh app
      </button>

      {debugMessage}
    </div>
  );
};
