import { API_ENDPOINTS } from 'constants/api';
import { Action, DefaultErrorMessageEnum } from 'api/types';
import { actionWithPrintPopupWindow } from '../helpers';

import { BillingHistoryResponse, FileActionProps, FileResponse } from './invoiceActions.types';

export function fetchInvoiceBillingHistoryAction(): Action<BillingHistoryResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.invoiceBillingHistory,
  };
}

function invoiceFilePrintActionCreator({ id, ...body }: FileActionProps): Action<FileResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.invoiceFile}/${id}`,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.DownloadFile,
    },
  };
}

export const invoiceFilePrintAction = actionWithPrintPopupWindow(invoiceFilePrintActionCreator);

export function invoiceFileDownloadAction({ id, ...body }: FileActionProps): Action<FileResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.invoiceFile}/${id}`,
    body,
    headers: {
      'X-Download': 'true',
    },
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.DownloadFile,
    },
  };
}
