import { makeTheme } from '../makeTheme';

export const cards = makeTheme({
  cards: {
    accountType: {
      p: 4,
      display: 'flex',
      flexDirection: ['row', null, 'column'],
      alignItems: 'center',
      cursor: 'pointer',
      bg: 'cards.default.bg',
      borderRadius: 'default',
      transition: 'all 0.15s ease-in-out',
      border: '4px solid',
      borderColor: 'cards.default.border',
      textAlign: ['left', null, 'center'],
      gap: [3, null, 0],
      'svg, img': {
        transformOrigin: ['75% 50%', null, '50% 75%'],
        transition: 'all 0.15s ease-in-out',
        filter: 'grayscale(100%)',
      },
      '&:hover, &:focus': {
        bg: 'cards.hover.bg',
        boxShadow: 'cards.accountType',
        'svg, img': {
          filter: 'grayscale(0)',
          transform: 'scale(1.15)',
        },
      },
      '&[data-active="true"]': {
        borderColor: 'cards.active.border',
        'svg, img': {
          filter: 'grayscale(0)',
          transform: 'scale(1.35)',
        },
      },
    },
  },
});
