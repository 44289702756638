import { selector } from 'recoil';

import {
  BetaFeature,
  BetaFeatureState,
  DefaultRole,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { OrganizationAccountState, PaymentModule } from 'api/actions/payments/paymentsActions.types';
import {
  PermissionsFeaturesTypes,
  PermissionsModulesTypes,
  PermissionsSystemManagementTypes,
} from 'api/actions/userSession/userSessionActions.types';

import { organizationSessionAtom } from './organizationSession';
import { userDetailsSelector, userSessionAtom } from './userSession';

export type AppPermissionSelector = {
  allowChangeRequestForLoggedClockInsAndOuts: boolean;
  allowRequestForBusinessTrips: boolean;
  allowRequestForSchedules: boolean;
  betaAccessOptIn: {
    faceRecognition: boolean;
    fraudDetection: boolean;
    aiSchedules: boolean;
  };
  canAddMoreThanOneUser: boolean;
  canAddMoreUsers: boolean;
  features: { [key in keyof typeof PermissionsFeaturesTypes]?: boolean } & {
    PhotoLog: boolean;
    WorkdayAvailability: boolean;
    AttendanceOverview: boolean;
  };
  hideTimeOffTypes: boolean;
  isAccountBlocked: boolean;
  isHeadAdmin: boolean;
  isInitialized: boolean;
  isTestPeriod: boolean;
  isTooManyUsersBlocked: boolean;
  modules: {
    Requests: boolean;
    Schedule: boolean;
    TimeTracking: boolean;
  };
  modulesManagement: { [key in keyof typeof PermissionsModulesTypes]: boolean };
  systemManagement: { [key in keyof typeof PermissionsSystemManagementTypes]: boolean };
  timeTrackingSettings: {
    allowTimeRounding: boolean;
    isFaceRecognitionRequired: boolean;
    isBasicFraudDetectionRequired: boolean;
    isLocationRequired: boolean;
    isPhotoRecognitionRequired: boolean;
    showPaidBreak: boolean;
    showUnpaidBreak: boolean;
  };
};

export const appPermissionsSelector = selector<AppPermissionSelector>({
  key: 'appPermissions',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    const organizationSession = get(organizationSessionAtom);
    const userDetails = get(userDetailsSelector);

    if (!userSession || !organizationSession)
      return {
        allowChangeRequestForLoggedClockInsAndOuts: false,
        allowRequestForBusinessTrips: false,
        allowRequestForSchedules: false,
        betaAccessOptIn: {
          aiSchedules: false,
          faceRecognition: false,
          fraudDetection: false,
        },
        canAddMoreThanOneUser: false,
        canAddMoreUsers: false,
        features: {
          AddEventsByKiosk: false,
          AddEventsByMobileTimeClock: false,
          AddEventsByVrcp: false,
          AddEventsByWorkerApp: false,
          PhotoLog: false,
          WorkdayAvailability: false,
          AttendanceOverview: false,
        },
        hideTimeOffTypes: true,
        isAccountBlocked: false,
        isHeadAdmin: false,
        isInitialized: false,
        isTestPeriod: false,
        isTooManyUsersBlocked: false,
        modules: {
          Requests: false,
          Schedule: false,
          TimeTracking: false,
        },
        modulesManagement: {
          Employees: false,
          Requests: false,
          Schedules: false,
          TimeTracking: false,
        },
        timeTrackingSettings: {
          allowTimeRounding: false,
          isFaceRecognitionRequired: false,
          isBasicFraudDetectionRequired: false,
          isLocationRequired: false,
          isPhotoRecognitionRequired: false,
          showPaidBreak: false,
          showUnpaidBreak: false,
        },
        systemManagement: {
          AccessKioskMode: false,
          AccessMobileTimeClocks: false,
          CompanySettings: false,
          Payments: false,
          Payroll: false,
          Permissions: false,
        },
      };

    const { permissions, hideDetailedRequestTypes } = userSession;
    const {
      activeSubscription,
      allowChangeRequestForLoggedClockInsAndOuts,
      allowRequestForBusinessTrips,
      allowRequestForSchedules,
      betaSettings,
      currentEmployeesCount,
      timeTrackingSettings,
      schedulesSettings: { isWorkdayAvailabilityAvailable },
    } = organizationSession;

    const { state, paidEmployeesCount, modules: subscriptionModules } = activeSubscription;
    const { modules, systemManagement, features, isHeadAdmin } = permissions;

    const faceRecognition = !!betaSettings.find(
      ({ state: featureState, feature }) =>
        feature === BetaFeature.FaceRecognition && featureState === BetaFeatureState.Enabled,
    );
    const fraudDetection = !!betaSettings.find(
      ({ state: featureState, feature }) =>
        feature === BetaFeature.FraudDetection && featureState === BetaFeatureState.Enabled,
    );
    const aiSchedules = !!betaSettings.find(
      ({ state: featureState, feature }) =>
        feature === BetaFeature.AiSchedules && featureState === BetaFeatureState.Enabled,
    );
    const isTestPeriod = state === OrganizationAccountState.Testing;
    const isAccountBlocked = state === OrganizationAccountState.Expired;
    const isTooManyUsersBlocked = currentEmployeesCount > paidEmployeesCount && !isTestPeriod;
    const canAddMoreUsers = isTestPeriod || currentEmployeesCount < paidEmployeesCount;
    const canAddMoreThanOneUser = isTestPeriod || currentEmployeesCount + 1 < paidEmployeesCount;

    const parsedSubscriptionModules: AppPermissionSelector['modules'] = {
      Requests: subscriptionModules.some((x) => x === PaymentModule.TimeOff),
      Schedule: subscriptionModules.some((x) => x === PaymentModule.Schedule),
      TimeTracking: subscriptionModules.some((x) => x === PaymentModule.TimeTracking),
    };

    const parsedModulesManagement = modules.reduce<AppPermissionSelector['modulesManagement']>(
      (acc, { type, value }) => ({
        ...acc,
        [PermissionsModulesTypes[type]]: value,
      }),
      {} as AppPermissionSelector['modulesManagement'],
    );

    const parsedSystemManagement = systemManagement.reduce<AppPermissionSelector['systemManagement']>(
      (acc, { type, value }) => ({
        ...acc,
        [PermissionsSystemManagementTypes[type]]: value,
      }),
      {} as AppPermissionSelector['systemManagement'],
    );

    const permissionsFeaturesValues = Object.values(PermissionsFeaturesTypes);

    const parsedFeatures = features.reduce<Partial<AppPermissionSelector['features']>>((acc, feature) => {
      const { inheritFromTag, inheritedValue, value, type } = feature;
      if (!permissionsFeaturesValues.includes(type)) return acc;
      const parsedValue = inheritFromTag ? inheritedValue : value;
      const isAttendanceOverviewType =
        type === PermissionsFeaturesTypes.AttendanceOverviewCompanyWideView ||
        type === PermissionsFeaturesTypes.AttendanceOverviewTagsWideView;
      return {
        ...acc,
        ...(isAttendanceOverviewType
          ? { AttendanceOverview: acc?.AttendanceOverview || parsedValue }
          : { [PermissionsFeaturesTypes[type]]: parsedValue }),
        WorkdayAvailability: isWorkdayAvailabilityAvailable,
      };
    }, {}) as AppPermissionSelector['features'];

    const hideTimeOffTypes = hideDetailedRequestTypes && userDetails?.roleId === `${DefaultRole.Employee}`;
    return {
      allowChangeRequestForLoggedClockInsAndOuts,
      allowRequestForBusinessTrips,
      allowRequestForSchedules,
      betaAccessOptIn: {
        aiSchedules,
        faceRecognition,
        fraudDetection,
      },
      canAddMoreThanOneUser,
      canAddMoreUsers,
      features: {
        ...parsedFeatures,
        PhotoLog: parsedModulesManagement.TimeTracking,
      },
      hideTimeOffTypes,
      isAccountBlocked,
      isHeadAdmin: !!isHeadAdmin,
      isInitialized: true,
      isTestPeriod,
      isTooManyUsersBlocked,
      modules: parsedSubscriptionModules,
      modulesManagement: parsedModulesManagement,
      systemManagement: parsedSystemManagement,
      timeTrackingSettings,
    };
  },
});
