import React from 'react';
import { Box, Flex, FlexProps } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';

import { FilterProps } from './types';

type Props = Pick<FilterProps, 'color' | 'isOutlined' | 'iconType'> &
  Pick<FlexProps, 'sx'> & {
    type?: FilterProps['prependWith'];
  };

const defaultProps: Partial<Props> = {
  type: undefined,
};

export const FilterPrependElement = ({
  color,
  isOutlined,
  type,
  iconType,
  sx,
  ...props
}: Props): React.ReactElement | null => {
  if (!type) return null;

  return (
    <Flex
      sx={{
        size: 18,
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
        color: color || 'asideFilters.prependElement',
      }}
      {...props}
    >
      {type === 'icon' && iconType ? (
        <Icon type={iconType} size={18} />
      ) : (
        <Box
          sx={{
            size: '0.75rem',
            ...(type === 'dot' && {
              borderRadius: 'circle',
            }),
            ...((type === 'roundedSquare' || type === 'roundedSquareFilled') && {
              borderRadius: 'xs',
              ...(type === 'roundedSquareFilled' && { bg: 'whites0' }),
            }),
            ...(isOutlined
              ? {
                  borderStyle: 'solid',
                  borderColor: color,
                  borderWidth: '0.125rem',
                }
              : { bg: color }),
            ...sx,
          }}
        />
      )}
    </Flex>
  );
};

FilterPrependElement.defaultProps = defaultProps;
