/** @jsxImportSource theme-ui */

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { setWorkPositionsAction } from 'api/actions/employees/employeesActions';
import { SetWorkPositionsActionProps } from 'api/actions/employees/employeesActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { Checkbox } from 'components/ui/Checkbox';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { Select } from 'components/ui/Select/Select';
import { PATH_REL, TO_REL } from 'constants/routes';
import { useLocationOnce } from 'hooks/useLocationOnce/useLocationOnce';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { languageSelector } from 'state/recoilState';
import { workPositionsSelectOptionsSelector } from 'state/team';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { ManageWorkPositionsModal } from '../../workPositions/ManageWorkPositions';

export const SetPosition: FC = () => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose, baseRoute } = useModal();
  const language = useRecoilValue(languageSelector);
  const [loading, setLoading] = useState(false);
  const workPositionsSelectOptions = useRecoilValue(workPositionsSelectOptionsSelector);
  const { state } = useLocationOnce();
  const { ids: stateIds } = state || {};
  const idsRef = useRef(stateIds);
  const formRef = useRef<HTMLFormElement | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Omit<SetWorkPositionsActionProps, 'employeesIds'>>({
    defaultValues: {
      workPositionId: undefined,
      overwrite: false,
    },
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const { mutate } = useMutation(setWorkPositionsAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit: SubmitHandler<Omit<SetWorkPositionsActionProps, 'employeesIds'>> = useCallback(
    async (body) => {
      const ids = idsRef.current;
      if (!ids || !ids.length) return false;
      const { error: submitError } = await mutate({
        employeesIds: ids || [],
        ...body,
      });
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.set_position_bulk.set', message: 'Successfully set!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, addSnackbar],
  );
  const onSubmitError = () => {
    setLoading(false);
  };

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  if (!workPositionsSelectOptions) return <Navigate to={baseRoute} relative="path" />;

  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.set_position_bulk">Set position</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          sx={{ width: '340px', maxWidth: '100%' }}
          ref={formRef}
          onSubmit={floatingPromiseReturn(handleSubmit(onSubmit, onSubmitError))}
          noValidate
        >
          <Text sx={{ ml: 2, mt: 2, fontSize: 1, fontWeight: 'bold' }}>
            <Trans id="team.set_position_bulk.default">Default position</Trans> |{' '}
            <LinkAnchor
              replace
              standOut
              sx={{ textDecoration: 'underline' }}
              to={TO_REL.MANAGE_WORK_POSITIONS_MODAL[language]}
            >
              <Trans id="team.set_position_bulk.manage">Manage</Trans>
            </LinkAnchor>
          </Text>

          <Select
            size="sm"
            id="workPositionId"
            // creatable backend not ready
            placeholder={t({ id: 'team.set_position_bulk.select_add', message: 'Select or add a new one' })}
            error={!!errors.workPositionId}
            errorMessage={errors?.workPositionId?.message}
            searchable
            options={workPositionsSelectOptions}
            clearable
            {...register('workPositionId', {
              required: t({ id: 'global.forms.required' }),
            })}
          />
          <br />
          <Checkbox
            labelWrapperSx={{ ml: -2 }}
            label={t({ id: 'team.set_position_bulk.overwrite', message: 'Overwrite existing position settings' })}
            size="sm"
            {...register('overwrite')}
          />
        </form>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'team.set_position_bulk.next', message: 'Save' }),
          },
        ]}
      />

      <Routes>
        <Route
          path={PATH_REL.MANAGE_WORK_POSITIONS_MODAL[language]}
          element={
            <Modal replaceMode fullHeight>
              <ManageWorkPositionsModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
