import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import dayjs from 'dayjs';
import _, { DebouncedFunc } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Input, InputProps, Text } from 'theme-ui';
import { v1 as uuidv1 } from 'uuid';

import { postHasBeenReadAction, postMessageAction } from 'api/actions/chat/chatActions';
import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { MAX_MESSAGE_LENGTH } from 'constants/chat';
import { useEvent } from 'hooks/useEvent/useEvent';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import {
  MessageSendStatus,
  addOwnMessageSelector,
  chatUserWithUnreadMessagesSelectorFamily,
  chatUsersMapSelector,
  updateOwnMessageSelector,
} from 'state/chat';
import { userSessionAtom } from 'state/userSession';
import { createEvent } from 'utils/createEvent';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { mergeRefs } from 'utils/mergeRefs';

type Props = {
  receiver: string;
  messagesListRef: React.MutableRefObject<HTMLInputElement | null>;
  inputProps?: InputProps;
};

export const MessageComposer = React.forwardRef<HTMLInputElement, Props>(
  ({ receiver, messagesListRef, inputProps }: Props, ref) => {
    useLingui();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const messageComposerContainerRef = useRef<HTMLDivElement | null>(null);

    const [message, setMessage] = useState('');
    const [lengthError, setLengthError] = useState(0);
    const [mobileMarginBottom, setMobileMarginBottom] = useState<number | undefined>(0);

    const addOwnMessage = useSetRecoilState(addOwnMessageSelector);
    const updateOwnMessage = useSetRecoilState(updateOwnMessageSelector);
    const [hasUnreadMessages, setHasUnreadMessages] = useRecoilState(
      chatUserWithUnreadMessagesSelectorFamily(receiver),
    );

    const userSession = useRecoilValue(userSessionAtom);
    const chatUsersMap = useRecoilValue(chatUsersMapSelector);
    const chatUser = useMemo(() => chatUsersMap.get(receiver), [chatUsersMap, receiver]);

    const originalWindowHeightRef = useRef(window?.visualViewport?.height);

    const { isMobileBreakpoint } = useThemeBreakpoint();

    const fullName = useNameDisplayOrder();

    const displayName = useMemo(() => {
      if (!chatUser?.name) return '';
      const { firstName, surname } = chatUser.name;
      return fullName(firstName, surname);
    }, [chatUser?.name, fullName]);

    const { mutate } = useMutation(postMessageAction);
    const { mutate: hasBeenReadMutate } = useMutation(postHasBeenReadAction);

    const hasBeenRead = useCallback(
      async (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (inputProps && inputProps.onFocus) {
          inputProps.onFocus(e);
        }
        if (hasUnreadMessages) {
          const { error } = await hasBeenReadMutate({ id: receiver });

          if (!error) {
            setHasUnreadMessages(false);
          }
        }
      },
      [inputProps, hasUnreadMessages, hasBeenReadMutate, receiver, setHasUnreadMessages],
    );

    const handleMessageInputChange: InputProps['onInput'] = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { innerText } = e.target;

        e.target.scrollTo({ top: e.target.scrollHeight });

        setMessage(innerText);

        if (innerText.length > MAX_MESSAGE_LENGTH) {
          setLengthError(MAX_MESSAGE_LENGTH - innerText.length);
          return;
        }

        if (lengthError) setLengthError(0);
      },
      [lengthError],
    );

    const debounceUpdateOwnMessage: DebouncedFunc<typeof updateOwnMessage> = _.debounce((msg) => {
      updateOwnMessage(msg);
    }, 1000);

    const submitMessage = useCallback(async () => {
      if (message.length > 0 && message.trim() && receiver && userSession?.personId && lengthError === 0) {
        const parsedMessage = message.replace(/^\s*/, '').replace(/\s*$/, '');

        const postMessagePayload = {
          message: parsedMessage,
          receiver,
          id: uuidv1(),
          createdUnix: dayjs().unix(),
          sender: userSession.personId,
        };

        addOwnMessage(postMessagePayload);
        debounceUpdateOwnMessage({ ...postMessagePayload, status: MessageSendStatus.SENDING });
        setMessage('');
        if (inputRef.current) inputRef.current.innerText = '';
        messagesListRef?.current?.scrollTo(0, 0);

        const { error: submitError, payload } = await mutate(_.omit(postMessagePayload, ['createdUnix', 'sender']));

        if (!submitError && payload) {
          debounceUpdateOwnMessage({ ...payload, status: undefined });
        }

        if (submitError) {
          debounceUpdateOwnMessage({ ...postMessagePayload, status: MessageSendStatus.UNSENT });
        }
      }
    }, [message, receiver, userSession, lengthError, addOwnMessage, debounceUpdateOwnMessage, messagesListRef, mutate]);

    const submitMessageByEnter: NonNullable<InputProps['onKeyDown']> = useCallback(
      (e) => {
        if (!e.shiftKey && e.keyCode === 13) {
          e.preventDefault();
          void submitMessage();
        }
      },
      [submitMessage],
    );

    const onPaste: NonNullable<InputProps['onPaste']> = useCallback((e) => {
      e.preventDefault();

      const clipboard = e.clipboardData.getData('text/plain');
      const selection = window.getSelection();

      if (selection) {
        if (!selection.rangeCount) return false;

        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(clipboard));
        selection.collapseToEnd();
      }

      const blurEvent = createEvent('input');
      if (inputRef.current) inputRef.current.dispatchEvent(blurEvent);

      return true;
    }, []);

    useEffect(() => {
      if (inputRef.current) inputRef.current.focus();
    }, []);

    const handleResize = useCallback(() => {
      const resizedWindowHeight = window?.visualViewport?.height;

      if (resizedWindowHeight !== originalWindowHeightRef.current) {
        setMobileMarginBottom(messageComposerContainerRef.current?.getBoundingClientRect().height);
      }

      if (resizedWindowHeight === originalWindowHeightRef.current) {
        setMobileMarginBottom(0);
      }
    }, []);

    useEvent('resize', handleResize, isMobileBreakpoint ? window : null);

    return (
      <Flex
        variant="chat.messageComposer.container"
        ref={messageComposerContainerRef}
        sx={{ mb: `${mobileMarginBottom}px` }}
      >
        <Input
          {...inputProps}
          autoComplete="off"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          as="div"
          contentEditable
          suppressContentEditableWarning
          ref={mergeRefs([inputRef, ref])}
          id="message"
          type="text"
          variant="chat.messageComposer.input"
          value={message}
          onKeyDown={submitMessageByEnter}
          onFocus={floatingPromiseReturn(hasBeenRead)}
          onInput={handleMessageInputChange}
          onPaste={onPaste}
          data-placeholder={`${t({
            id: 'messageComposer.input.placeholder',
            message: 'Message',
          })} ${displayName}`}
        />
        <Flex variant="chat.messageComposer.sendButtonContainer">
          <Button
            disabled={!(message.length > 0 && lengthError === 0)}
            variant="success"
            shape="rounded"
            size="sm"
            type="submit"
            onClick={floatingPromiseReturn(submitMessage)}
          >
            <Icon type="send" />
          </Button>
        </Flex>
        {lengthError < 0 && (
          <Text
            as="span"
            sx={{
              position: 'absolute',
              bottom: 2,
              right: 3,
              fontSize: 1,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'chat.text.error',
              userSelect: 'none',
            }}
          >
            {lengthError}
          </Text>
        )}
      </Flex>
    );
  },
);
