/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dayjs } from 'dayjs';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { AppNavigateOptions, To } from 'react-router-dom';

import { Holiday, HolidayImportLocationsResponse } from 'api/actions/holidays/holidaysActions.types';
import {
  ExternalLoginProvider,
  ExternalProvider,
  FetchExternalProviderResponse,
  Integration,
  ServiceIntegration,
} from 'api/actions/integrations/integrationActions.types';
import { Employee, PersonName } from 'api/actions/organizationSession/organizationSessionActions.types';
import {
  FetchAutomationsSettingsResponse,
  FetchNotificationSettingsResponse,
  FetchOrganizationSettingsResponse,
  FetchRequestsSettingsResponse,
  FetchScheduleSettingsResponse,
  FetchTimeTrackingSettingsResponse,
  FetchUserInfoResponse,
  FetchUserNotificationsSettingsResponse,
  UserPreferences,
  UserProfileSettingsActionProps,
} from 'api/actions/settings/settingsActions.types';
import { TimeFormat } from 'api/actions/userSession/userSessionActions.types';
import { FetchWebhooksResponse } from 'api/actions/webhook/webhooksActions.types';
import { InputOption } from 'components/ui/Select/Select';
import { REAL_TIMEZONE } from 'constants/common';
import { dateTime } from 'utils/dateTime';

import { organizationCreationDateUnixSelector, organizationSessionAtom } from './organizationSession';
import { nameDisplayOrderWithInitialsSelector } from './recoilState';
import { userSessionAtom } from './userSession';
import { RolesSelectOption, rolesSelectOptionsSelector } from './team';

export const settingsPageDataSelector = selector({
  key: 'settingsPageData',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    const organizationSession = get(organizationSessionAtom);

    if (!userSession || !organizationSession) return null;

    const { details, employeesMap } = organizationSession;
    const user = employeesMap.get(userSession.personId);

    if (!user) return null;
    const name = get(nameDisplayOrderWithInitialsSelector(user.name));

    return {
      organization: _.omit(details, ['createDateUtcUnix']),
      user: name,
    };
  },
});

type UserProfileDefaultValues = Omit<UserProfileSettingsActionProps, 'address'> &
  Pick<Employee, 'avatarUrl' | 'id'> &
  PersonName;

export const settingsProfileFormDefaultValuesSelector = selector<UserProfileDefaultValues | null>({
  key: 'settingsProfileFormDefaultValues',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    const organizationSession = get(organizationSessionAtom);

    if (!userSession || !organizationSession) return null;

    const { employeesMap } = organizationSession;
    const user = employeesMap.get(userSession.personId);
    if (!user) return null;
    const { avatarUrl, name, id, email, phoneNumber } = user;
    return {
      ...name,
      avatarUrl,
      id,
      email,
      phoneNumber,
    };
  },
});

export const resetFormButtonAtom = atom<{ name: string; callback: () => void }[] | null>({
  key: 'resetFormButton',
  default: null,
});

export const resetFormButtonCallbacksSelector = selector<(() => void)[] | null>({
  key: 'resetFormButtonCallbacks',
  get: ({ get }) => {
    const resetFormAtom = get(resetFormButtonAtom);

    if (!resetFormAtom) return null;

    return resetFormAtom.map((item) => item.callback);
  },
});

export const holidayImportLocationsResponseAtom = atom<HolidayImportLocationsResponse | null>({
  key: 'holidayImportLocationsResponse',
  default: null,
});

/**
 * TRANSITION BLOCK
 */
export type BlockTransition = {
  blockLocationPathname?: To | number | null;
  navigateOptions?: AppNavigateOptions;
  blockTransition: boolean;
  isRequestPending: boolean;
  skipFetch: boolean;
};

export const blockTransitionAtom = atom<BlockTransition>({
  key: 'blockTransition',
  default: {
    blockLocationPathname: null,
    blockTransition: false,
    isRequestPending: false,
    skipFetch: false,
  },
});
// ***************************************************************************

/**
 * PROFILE
 */
export const profileSettingsAtom = atom<FetchUserInfoResponse | null>({
  key: 'profileSettings',
  default: null,
});
// ***************************************************************************

/**
 * PREFERENCES
 */
export const preferencesSettingsAtom = selector<UserPreferences | null>({
  key: 'preferencesSettings',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);

    if (!userSession) return null;

    const { preferredLanguage, nameDisplayOrder, dateFormat, timeFormat, timeZoneId } = userSession;

    // eslint-disable-next-line no-underscore-dangle
    const is24HourFormat = _.isEqual(timeFormat, TimeFormat._24h);

    return {
      language: preferredLanguage,
      nameDisplayOrder,
      dateFormat,
      is24HourFormat,
      timeZoneId: timeZoneId || REAL_TIMEZONE,
    };
  },
});
// ***************************************************************************

/**
 * NOTIFICATIONS
 */
export const notificationsSettingsAtom = atom<FetchUserNotificationsSettingsResponse | null>({
  key: 'notificationsSettings',
  default: null,
});
// ***************************************************************************

/**
 * ORGANIZATION
 */
export const organizationSettingsAtom = atom<FetchOrganizationSettingsResponse | null>({
  key: 'organizationSettings',
  default: null,
});

/**
 * HOLIDAYS
 */
export type ParsedHoliday = Omit<Holiday, 'isDefault'> & { isNonEditable: boolean };

export const holidaysAtom = atom<Holiday[] | null>({
  key: 'holidays',
  default: null,
});

export const parsedHolidaysSelector = selector<ParsedHoliday[] | null>({
  key: 'parsedHolidays',
  get: ({ get }) => {
    const holidays = get(holidaysAtom);

    if (!holidays) return null;

    return holidays.map(({ isDefault, ...rest }) => ({
      isNonEditable: isDefault,
      ...rest,
    }));
  },
});

export const holidaysYearsSelector = selector<string[]>({
  key: 'holidaysYears',
  get: ({ get }) => {
    const organizationCreationDateUnix = get(organizationCreationDateUnixSelector);

    if (!organizationCreationDateUnix) return [];

    const creationYear = dateTime(organizationCreationDateUnix, { utc: true }).get('year');
    const currentYear = dateTime(undefined, { utc: true }).get('year');

    const holidaysYears: string[] = [];

    for (let i = creationYear; i <= currentYear + 1; i += 1) {
      holidaysYears.push(String(i));
    }

    return holidaysYears;
  },
});

export const holidaysYearsOptionsSelector = selector<InputOption[]>({
  key: 'holidaysYearsOptions',
  get: ({ get }) => {
    const holidaysYears = get(holidaysYearsSelector);

    return holidaysYears.map((year) => ({ id: year, label: year }));
  },
});

export const pickedHolidayYearAtom = atom<string>({
  key: 'pickedHolidayYear',
  default: `${dateTime(undefined, { utc: true }).get('year')}`,
});

export const addHolidayDatesSelector = selector<{
  minDate: Dayjs;
  maxDate: Dayjs;
} | null>({
  key: 'addHolidayDates',
  get: ({ get }) => {
    const organizationCreationDateUnix = get(organizationCreationDateUnixSelector);

    if (!organizationCreationDateUnix) return null;

    const minDate = dateTime(organizationCreationDateUnix, { utc: true }).startOf('year').startOf('day');
    const maxDate = dateTime(undefined, { utc: true }).add(1, 'year').endOf('year').startOf('day');

    return {
      minDate,
      maxDate,
    };
  },
});
// ***************************************************************************

/**
 * TIME TRACKING
 */
export const timeTrackingSettingsAtom = atom<FetchTimeTrackingSettingsResponse | null>({
  key: 'timeTrackingSettings',
  default: null,
});
// ***************************************************************************

/**
 * SCHEDULES
 */
export const schedulesSettingsAtom = atom<FetchScheduleSettingsResponse | null>({
  key: 'schedulesSettings',
  default: null,
});
// ***************************************************************************

/**
 * AUTOMATIONS
 */
export const automationsSettingsAtom = atom<FetchAutomationsSettingsResponse | null>({
  key: 'automationsSettings',
  default: null,
});

export const clockInMagnetTimeSamplesSelector = selector({
  key: 'clockInMagnetTimeSamples',
  get: ({ get }) => {
    const automationsSettings = get(automationsSettingsAtom);

    if (!automationsSettings) return null;

    return automationsSettings.clockInMagnet.timeRoundingSamples;
  },
});

export const clockOutMagnetTimeSamplesSelector = selector({
  key: 'clockOutMagnetTimeSamples',
  get: ({ get }) => {
    const automationsSettings = get(automationsSettingsAtom);

    if (!automationsSettings) return null;

    return automationsSettings.clockOutMagnet.timeRoundingSamples;
  },
});

export const timeRoundingTimeSamplesSelector = selector({
  key: 'timeRoundingTimeSamples',
  get: ({ get }) => {
    const automationsSettings = get(automationsSettingsAtom);

    if (!automationsSettings) return null;

    return automationsSettings.timeRounding.timeRoundingSamples;
  },
});
// ***************************************************************************

/**
 * REQUESTS
 */
export const requestsSettingsAtom = atom<FetchRequestsSettingsResponse | null>({
  key: 'requestsSettings',
  default: null,
});

export const rolesApproveRequestsSelectOptionsSelector = selector<RolesSelectOption[] | null>({
  key: 'rolesApproveRequestsSelectOptions',
  get: ({ get }) => {
    const roles = get(rolesSelectOptionsSelector);
    if (!roles) return null;

    return roles.filter(({ canApproveRequests }) => canApproveRequests);
  },
});
// ***************************************************************************

/**
 * DEFAULT NOTIFICATIONS
 */
export const defaultNotificationsSettingsAtom = atom<FetchNotificationSettingsResponse | null>({
  key: 'defaultNotificationsSettings',
  default: null,
});
// ***************************************************************************

/**
 * INTEGRATIONS
 */
export const integrationsAtom = atom<Integration[] | null>({
  key: 'integrations',
  default: null,
});

export const integrationsMapSelector = selector<Map<ServiceIntegration, Integration>>({
  key: 'integrationsMap',
  get: ({ get }) => {
    const integrations = get(integrationsAtom);

    const newMap = new Map<ServiceIntegration, Integration>();

    if (!integrations) return newMap;

    integrations.forEach((integration) => {
      newMap.set(integration.type, integration);
    });

    return newMap;
  },
});

export const externalIntegrationsAtom = atom<FetchExternalProviderResponse | null>({
  key: 'externalIntegrations',
  default: null,
});

export const externalIntegrationsMapSelector = selector<Map<ExternalLoginProvider, ExternalProvider>>({
  key: 'externalIntegrationsMap',
  get: ({ get }) => {
    const externalIntegrations = get(externalIntegrationsAtom);

    const newMap = new Map<ExternalLoginProvider, ExternalProvider>();

    if (!externalIntegrations) return newMap;

    externalIntegrations.forEach((integration) => {
      newMap.set(integration.provider, integration);
    });

    return newMap;
  },
});
// ***************************************************************************

/**
 * WEBHOOKS
 */
export const webhooksAtom = atom<FetchWebhooksResponse | null>({
  key: 'webhooks',
  default: null,
});
// ***************************************************************************
