import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { addWebhookAction } from 'api/actions/webhook/webhookActions';
import { AddWebhookActionProps, Webhook } from 'api/actions/webhook/webhooksActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { createEvent } from 'utils/createEvent';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useWebhooks } from '../../hooks/useWebhooks';
import { AddEditWebhookForm } from '../AddEditWebhookForm';

export const AddWebhookModal = (): React.ReactElement => {
  useLingui();
  const { handleClose } = useModal();
  const { mutate } = useMutation(addWebhookAction);
  const { fetchWebhooks } = useWebhooks();

  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: AddWebhookActionProps | Webhook): Promise<void> => {
      const { error: submitError } = await mutate(data);

      if (!submitError) {
        if (handleClose) {
          addSnackbar({
            message: t({
              id: 'settings.webhook.add_webhook_success',
              message: 'Added new webhook',
            }),
            variant: 'success',
          });
          await fetchWebhooks();
          handleClose();
        }
      }

      setLoading(false);
    },
    [handleClose, mutate, fetchWebhooks],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.webhook.add_webhook">Add Webhook</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddEditWebhookForm ref={formRef} onSubmit={floatingPromiseReturn(onSubmit)} setLoading={setLoading} />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
