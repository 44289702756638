import { atom, selector, selectorFamily } from 'recoil';
import { t } from '@lingui/macro';
import _ from 'lodash';

import {
  FilterGroupNames,
  TagsFilters,
  TeammatesFilters,
  WorkPosiotionsFilters,
} from 'layouts/AuthorizedApp/AsideFilters/types';
import {
  Employee,
  Tag,
  Role,
  WorkPosition,
  EditablePermissionsKey,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { NameDisplayOrder } from 'api/actions/userSession/userSessionActions.types';
import { EmployeesImportPreviewResponse, ParsedImportedEmployee } from 'api/actions/employees/employeesActions.types';
import { ExportTimeActivityPreviewResponse, ParsedExportedEmployees } from 'api/actions/export/exportActions.types';
import { ListNames } from 'components/StickyList/types';

import { filterGroupStateAtomFamily, parsedSearchFilterValueSelectorFamily, SEARCH_FILTER_TYPE } from './filters';
import { organizationSessionAtom, OrganizationSessionAtomType } from './organizationSession';
import { selectedRowsIdsSelectorFamily } from './list';
import { nameDisplayOrderSelector, userSessionAtom } from './userSession';
import { languageSelector } from './recoilState';

export type ParsedEmployee = Employee & {
  workPosition?: string;
  tags: (Tag | undefined)[];
  role: Role;
  isNonEditable?: boolean;
};

export const importedEmployeesInfoAtom = atom<EmployeesImportPreviewResponse | null>({
  key: 'importedEmployeesInfo',
  default: null,
});

export const exportedEmployeesInfoAtom = atom<ExportTimeActivityPreviewResponse | null>({
  key: 'exportedEmployeesInfo',
  default: null,
});

// 1.
export const parsedEmployeesSelector = selector({
  key: 'parsedEmployees',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);

    if (!organizationSession) return null;

    const { employeesMap, workPositionsMap, tagsMap, rolesMap } = organizationSession as Omit<
      typeof organizationSession,
      'employeesMap'
    > & { employeesMap: Map<ParsedEmployee['id'], ParsedEmployee> };

    employeesMap.forEach((employee, employeeId) => {
      const employeeTags = employee.tagsIds.flatMap((tagId) => tagsMap.get(tagId));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const employeeRole = rolesMap.get(employee.roleId)!;
      const employeeWorkPosition =
        employee.defaultWorkPositionId && workPositionsMap.get(employee.defaultWorkPositionId)?.name;

      employeesMap.set(employeeId, {
        ...employee,
        tags: employeeTags,
        role: employeeRole,
        workPosition: employeeWorkPosition,
      });
    });

    return employeesMap;
  },
});

export type BasicEmployee = Pick<Employee, 'id' | 'name' | 'avatarUrl'> &
  Partial<Pick<ParsedEmployee, 'role' | 'tags'>>;

export const allEmployeesSelector = selector<Map<string, BasicEmployee> | null>({
  key: 'allEmployees',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);

    if (!organizationSession) return null;

    const { employeesMap, chatUsersWithoutEmployeesMap } = organizationSession;

    const allEmployeesMap = chatUsersWithoutEmployeesMap
      ? new Map([...chatUsersWithoutEmployeesMap, ...employeesMap])
      : new Map(employeesMap);

    return allEmployeesMap;
  },
});

export const parsedEmployeesForTeamSelector = selector({
  key: 'parsedEmployeesForTeam',
  get: ({ get }) => {
    const parsedEmployees = get(parsedEmployeesSelector);

    if (!parsedEmployees) return null;
    const newMap = new Map(parsedEmployees);
    newMap.forEach((employee, employeeId) => {
      newMap.set(employeeId, {
        ...employee,
        isNonEditable: !employee.editablePermissions.Employees,
      });
    });

    return newMap;
  },
});

export type ForceIncludeCurrentUser = boolean | undefined;
export type ParsedEditableEmployeesForModulesSelectorFamilyConfig = {
  forceIncludeCurrentUser?: boolean;
  module: EditablePermissionsKey;
};

export const parsedEditableEmployeesForModulesSelectorFamily = selectorFamily<
  Map<string, ParsedEmployee> | null,
  ParsedEditableEmployeesForModulesSelectorFamilyConfig
>({
  key: 'parsedEditableEmployeesForModulesSelector',
  get:
    ({ forceIncludeCurrentUser, module }) =>
    ({ get }) => {
      const parsedEmployeesMap = get(parsedEmployeesSelector);
      const userSession = get(userSessionAtom);

      if (!parsedEmployeesMap || !userSession) {
        return null;
      }

      const { personId } = userSession;

      const newMap = new Map(parsedEmployeesMap);

      newMap.forEach(({ editablePermissions }, employeeId) => {
        if (forceIncludeCurrentUser && employeeId === personId) return;
        if (!editablePermissions[module]) {
          newMap.delete(employeeId);
        }
      });

      return newMap;
    },
});

export const nonEditableEmployeesForModulesIdsSelectorFamily = selectorFamily<string[] | null, EditablePermissionsKey>({
  key: 'nonEditableEmployeesForModulesIdsSelector',
  get:
    (module) =>
    ({ get }) => {
      const organizationSession = get(organizationSessionAtom);
      if (!organizationSession) return null;
      const { employeesMap } = organizationSession;

      const nonEditableEmployeesIds: string[] = [];
      employeesMap.forEach(({ editablePermissions }, employeeId) => {
        if (editablePermissions[module]) return;

        nonEditableEmployeesIds.push(employeeId);
      });
      return nonEditableEmployeesIds;
    },
});

type EmployeeSelectorConfig = Pick<ParsedEmployee, 'id'>;

export const employeeSelectorFamily = selectorFamily<ParsedEmployee | null, EmployeeSelectorConfig>({
  key: 'employee',
  get:
    ({ id }) =>
    ({ get }) => {
      const employees = get(parsedEmployeesSelector);
      if (!employees) return null;

      return employees.get(id) || null;
    },
});

export const tagsSelector = selector<Tag[] | null>({
  key: 'tags',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { tags } = organizationSession;
    return tags;
  },
});

export const tagsMapSelector = selector<OrganizationSessionAtomType['tagsMap'] | null>({
  key: 'tagsMap',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { tagsMap } = organizationSession;
    return tagsMap;
  },
});

// MANAGE TAGS MODAL
export const filteredTagsMapSelector = selector<OrganizationSessionAtomType['tagsMap'] | null>({
  key: 'filteredTagsMap',
  get: ({ get }) => {
    const tagsMap = get(tagsMapSelector);
    if (!tagsMap) return null;
    const searchFilterState = get(parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.MANAGE_TAGS));

    const filterBySearchInput = (tag: Tag) => {
      let valid = false;

      if (_.isEmpty(searchFilterState)) {
        valid = true;
        return valid;
      }

      const { name, additionalInfo } = tag;

      const searchableTag = _.flatMapDeep([name, additionalInfo]);

      valid = _.some(
        searchFilterState.map((searchQuery) => {
          let subValid = false;

          _.forEach(searchableTag, (searchableValue) => {
            subValid = subValid || _.includes(searchableValue?.toLocaleLowerCase(), searchQuery.toLocaleLowerCase());
          });

          return subValid;
        }),
      );
      return valid;
    };

    const newMap = new Map(tagsMap);

    newMap.forEach((tag, tagId) => {
      let valid = false;

      valid = filterBySearchInput(tag);

      if (!valid) newMap.delete(tagId);
    });

    return newMap;
  },
});

export const rolesSelector = selector<Role[] | null>({
  key: 'roles',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { roles } = organizationSession;
    return roles;
  },
});

export const rolesMapSelector = selector<OrganizationSessionAtomType['rolesMap'] | null>({
  key: 'rolesMap',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { rolesMap } = organizationSession;
    return rolesMap;
  },
});

export const visibleRolesSelector = selector<Role[] | null>({
  key: 'visibleRoles',
  get: ({ get }) => {
    const roles = get(rolesSelector);
    if (!roles) return null;

    return roles.filter(({ isVisible }) => !!isVisible);
  },
});

// MANAGE ROLES MODAL
export const filteredRolesMapSelector = selector<OrganizationSessionAtomType['rolesMap'] | null>({
  key: 'filteredRolesMap',
  get: ({ get }) => {
    // this will trigger the selector recomputation on language changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    const rolesMap = get(rolesMapSelector);
    if (!rolesMap) return null;
    const searchFilterState = get(parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.MANAGE_ROLES));

    const filterBySearchInput = (role: Role) => {
      let valid = false;

      if (_.isEmpty(searchFilterState)) {
        valid = true;
        return valid;
      }

      const { name, description } = role;
      const nameTranslation = t({ id: name });
      const descriptionTranslation = t({ id: description });
      const searchableRole = _.flatMapDeep([nameTranslation, descriptionTranslation]);

      valid = _.some(
        searchFilterState.map((searchQuery) => {
          let subValid = false;

          _.forEach(searchableRole, (searchableValue) => {
            subValid = subValid || _.includes(searchableValue?.toLocaleLowerCase(), searchQuery.toLocaleLowerCase());
          });

          return subValid;
        }),
      );
      return valid;
    };

    const newMap = new Map(rolesMap);

    newMap.forEach((role, roleId) => {
      let valid = false;

      valid = filterBySearchInput(role);

      if (!valid) newMap.delete(roleId);
    });

    return newMap;
  },
});

export const workPositionsSelector = selector<WorkPosition[] | null>({
  key: 'workPositions',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { workPositions } = organizationSession;
    return workPositions;
  },
});

export const activeWorkPositionsSelector = selector<WorkPosition[] | null>({
  key: 'workPositions_active',
  get: ({ get }) => {
    const workPositions = get(workPositionsSelector);
    if (!workPositions) return null;
    const filteredWorkPositions = _.filter(workPositions, ({ isActive }) => isActive);

    return filteredWorkPositions.length > 0 ? filteredWorkPositions : null;
  },
});

export const workPositionsMapSelector = selector<OrganizationSessionAtomType['workPositionsMap'] | null>({
  key: 'workPositionsMap',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { workPositionsMap } = organizationSession;
    return workPositionsMap;
  },
});
// MANAGE WORK POSITIONS MODAL
export const filteredWorkPositionsMapSelector = selector<OrganizationSessionAtomType['workPositionsMap'] | null>({
  key: 'filteredWorkPositionsMap',
  get: ({ get }) => {
    const workPositionsMap = get(workPositionsMapSelector);
    if (!workPositionsMap) return null;
    const searchFilterState = get(parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.MANAGE_WORK_POSITIONS));

    const filterBySearchInput = (workPosition: WorkPosition) => {
      let valid = false;

      if (_.isEmpty(searchFilterState)) {
        valid = true;
        return valid;
      }

      const { name } = workPosition;

      valid = _.some(
        searchFilterState.map((searchQuery) => {
          const subValid = _.includes(name?.toLocaleLowerCase(), searchQuery.toLocaleLowerCase());

          return subValid;
        }),
      );
      return valid;
    };

    const newMap = new Map(workPositionsMap);

    newMap.forEach((workPosition, workPositionId) => {
      let valid = false;

      valid = filterBySearchInput(workPosition);

      if (!valid) newMap.delete(workPositionId);
    });

    return newMap;
  },
});

// 2
export const filteredEmployeesSelector = selector<Map<ParsedEmployee['id'], ParsedEmployee>>({
  key: 'filteredEmployees',
  get: ({ get }) => {
    const employeesMap = get(parsedEmployeesForTeamSelector);
    if (!employeesMap) return new Map();

    const teammatesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES));
    const rolesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.ROLES));
    const tagsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TAGS));
    const workPositionsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.WORK_POSITION_TEAM));
    const searchFilterState = get(parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.TEAMMATES));
    const nameDisplayOrder = get(nameDisplayOrderSelector);

    const filterBySearchInput = (employee: ParsedEmployee) => {
      let valid = false;

      if (_.isEmpty(searchFilterState)) {
        valid = true;
        return valid;
      }

      const {
        email,
        name,
        phoneNumber,
        role: { name: roleName },
        tags,
        customEmployeeId,
        note,
        workPosition,
      } = employee;
      const searchableEmployee = _.flatMapDeep([
        email,
        nameDisplayOrder === NameDisplayOrder.SurnameFirst
          ? `${name.surname} ${name.firstName}`
          : `${name.firstName} ${name.surname}`,
        phoneNumber,
        roleName,
        _.map(tags, (tag) => tag?.name),
        customEmployeeId,
        note,
        workPosition,
      ]);

      valid = _.some(
        searchFilterState.map((searchQuery) => {
          let subValid = false;

          _.forEach(searchableEmployee, (searchableValue) => {
            subValid = subValid || _.includes(searchableValue?.toLocaleLowerCase(), searchQuery.toLocaleLowerCase());
          });

          return subValid;
        }),
      );

      return valid;
    };

    const filterByTags = (employee: ParsedEmployee) => {
      let valid = false;

      if (tagsFilterState === null) {
        valid = true;
        return valid;
      }

      if (_.isArray(tagsFilterState)) {
        tagsFilterState.forEach((tagId) => {
          valid = valid || !!employee.tagsIds.includes(tagId);

          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          if (tagId === TagsFilters.NO_TAGS && _.isEmpty(employee.tagsIds)) {
            valid = true;
          }
        });
      }

      return valid;
    };

    const filterByRole = (employee: ParsedEmployee) => {
      let valid = false;

      if (rolesFilterState === null) {
        valid = true;
        return valid;
      }

      if (_.isArray(rolesFilterState)) {
        rolesFilterState.forEach((roleId) => {
          valid = valid || !!(employee.roleId === roleId);
        });
      }

      return valid;
    };

    const filterByTeammates = (employee: ParsedEmployee) => {
      let valid = false;

      if (teammatesFilterState === null) {
        valid = true;
        return valid;
      }
      if (teammatesFilterState.includes(TeammatesFilters.ACTIVE)) {
        valid = valid || !!employee.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.HIDDEN)) {
        valid = valid || !!employee.isHidden;
      }
      if (teammatesFilterState.includes(TeammatesFilters.DEACTIVATED)) {
        valid = valid || !employee.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.INVITED)) {
        valid = valid || !!employee.invitationState;
      }

      return valid;
    };

    const filterByWorkPositions = (employee: ParsedEmployee) => {
      let valid = false;

      if (workPositionsFilterState === null) {
        valid = true;
        return valid;
      }

      if (_.isArray(workPositionsFilterState)) {
        workPositionsFilterState.forEach((workPosition) => {
          valid = valid || employee.defaultWorkPositionId === workPosition;

          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          if (workPosition === WorkPosiotionsFilters.NO_POSITION && !employee.defaultWorkPositionId) {
            valid = true;
          }
        });
      }

      return valid;
    };

    const newMap = new Map(employeesMap);

    newMap.forEach((employee, employeeId) => {
      let valid = false;

      valid =
        filterByTeammates(employee) &&
        filterByRole(employee) &&
        filterByTags(employee) &&
        filterByWorkPositions(employee) &&
        filterBySearchInput(employee);

      if (!valid) newMap.delete(employeeId);
    });

    return newMap;
  },
});

// 3
export const selectedEmployeesIdsSelector = selector<ParsedEmployee['id'][]>({
  key: 'selectedEmployeesIds',
  get: ({ get }) => {
    const selectedRowsIds = get(selectedRowsIdsSelectorFamily(ListNames.TEAM));

    return selectedRowsIds;
  },
});

export const employeeByIdSelector = selectorFamily<ParsedEmployee | null, Employee['id'] | undefined>({
  key: 'employeeById',
  get:
    (id) =>
    ({ get }) => {
      const parsedEmployees = get(parsedEmployeesSelector);
      if (!parsedEmployees || !id) return null;

      const employee = parsedEmployees.get(id);

      if (!employee) return null;

      return employee;
    },
});

// Employees on import/export modal

export const importedEmployeesMapSelector = selector({
  key: 'importedEmployeesMap',
  get: ({ get }) => {
    const employeesInfo = get(importedEmployeesInfoAtom);

    if (!employeesInfo || !employeesInfo.importedEmployees || _.isEmpty(employeesInfo.importedEmployees)) return [];

    const mapEmployees = _.map(employeesInfo.importedEmployees, (employeeData) => {
      const { id, tagName, customEmployeeId, integrationType, integrationIdentity, state, roleId } = employeeData;
      const { firstName, surname, email, phoneNumber, address, note } = employeeData.employeeInfo;

      return [
        id,
        {
          id,
          firstName,
          surname,
          email,
          phoneNumber,
          address,
          note,
          tagName,
          customEmployeeId,
          integrationType,
          integrationIdentity,
          state,
          roleId,
        },
      ] as const;
    });

    const employeesMap: Map<string, ParsedImportedEmployee> = new Map(mapEmployees);

    return employeesMap;
  },
});

export const exportedEmployeesMapSelector = selector({
  key: 'exportedEmployeesMap',
  get: ({ get }) => {
    const employeesInfo = get(exportedEmployeesInfoAtom);
    const nameDisplayOrder = get(nameDisplayOrderSelector);
    const employeesMap = get(parsedEmployeesForTeamSelector);

    if (!employeesInfo || !employeesInfo.employees || _.isEmpty(employeesInfo.employees)) return [];

    const mapEmployees = _.map(employeesInfo.employees, (employeeData) => {
      const { personId, displayName: displayNameEmployeeData, integrationIdentity, locationId } = employeeData;

      let displayName = displayNameEmployeeData;

      if (!displayName && employeesMap) {
        const employee = employeesMap.get(personId);

        if (employee) {
          const { name } = employee;

          displayName =
            nameDisplayOrder === NameDisplayOrder.SurnameFirst
              ? `${name.surname} ${name.firstName}`
              : `${name.firstName} ${name.surname}`;
        }
      }

      return [
        personId,
        {
          id: personId,
          displayName,
          integrationIdentity,
          locationId,
        },
      ] as const;
    });

    const employeesInfoMap: Map<string, ParsedExportedEmployees> = new Map(mapEmployees);

    return employeesInfoMap;
  },
});
