import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import { requestGetForPersonForRangeAndType } from 'api/actions/requests/requestsActions';
import { useSilentFetch } from 'hooks/useSilentFetch/useSilentFetch';
import { RequestsTypeDetailsParams, requestsTypeDetailsAtomFamily } from 'state/requests';
import { RequestFormType } from 'api/actions/requests/requestsActions.types';

const REQUESTS_TYPES_WITHOUT_TYPE_ID = [
  `${RequestFormType.BusinessTrip}`,
  `${RequestFormType.Schedule}`,
  `${RequestFormType.TimeTracking}`,
];

type Props = RequestsTypeDetailsParams & {
  dates: { startDateUnix: number; endDateUnix: number } | null;
};

export const useRequestsTypeDetails = ({ type, typeId, personId, dates }: Props) => {
  const [fetchInProgress, setFetchInProgress] = useState(false);

  const { silentFetch, silentFetchAbort, fetchInTransition, initialFetchDone } = useSilentFetch(
    requestsTypeDetailsAtomFamily({ type, typeId, personId }),
    requestGetForPersonForRangeAndType({
      type,
      typeId: typeId && REQUESTS_TYPES_WITHOUT_TYPE_ID.includes(typeId) ? undefined : typeId,
      personId,
      startDateUnix: dates?.startDateUnix || 0,
      endDateUnix: dates?.endDateUnix || 0,
    }),
  );

  const internalFetchRequestsTypeDetails = useMemo(
    () => async (unAbortable?: boolean) => {
      if (dates) {
        const { error } = await silentFetch({
          forceUpdate: true,
          unAbortable,
        });

        if (!error) {
          setFetchInProgress(false);
        }
      }
    },
    [dates, silentFetch],
  );

  const throttleFetchRequestsTypeDetails = useMemo(
    () =>
      _.throttle(
        async () => {
          await internalFetchRequestsTypeDetails();
        },
        1000,
        { leading: false },
      ),
    [internalFetchRequestsTypeDetails],
  );

  const fetchRequestsTypeDetails = useMemo(
    () =>
      _.throttle(() => {
        setFetchInProgress(true);
      }, 1000),
    [],
  );

  const fetchRequestsTypeDetailsNoThrottle = useCallback(
    async (unAbortable?: boolean) => {
      await internalFetchRequestsTypeDetails(unAbortable);
    },
    [internalFetchRequestsTypeDetails],
  );

  useEffect(() => {
    if (fetchInProgress) {
      void throttleFetchRequestsTypeDetails();
    }

    return () => {
      fetchRequestsTypeDetails.cancel();
      throttleFetchRequestsTypeDetails.cancel();
    };
  }, [
    dates,
    fetchInProgress,
    fetchRequestsTypeDetails,
    internalFetchRequestsTypeDetails,
    silentFetchAbort,
    throttleFetchRequestsTypeDetails,
  ]);

  return {
    fetchRequestsTypeDetails,
    fetchRequestsTypeDetailsNoThrottle,
    fetchInTransition,
    fetchInProgress,
    initialFetchDone,
  };
};
