import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { ListColumn, ListVariant } from 'components/StickyList/types';

type Props = Pick<ListColumn, 'width' | 'columnGrow'> & FlexProps;

const defaultProps: Partial<Props> = {
  width: undefined,
  columnGrow: 1,
};

export const Cell = React.forwardRef<HTMLDivElement, Props>(
  ({ children, variant = ListVariant.default, sx, width, columnGrow, ...props }: Props, ref): React.ReactElement => (
    <Flex
      ref={ref}
      variant={`stickyList.cell.${variant}`}
      sx={{
        ...(width ? { flex: '0 0 auto', width } : { flexGrow: columnGrow, flexShrink: 0, flexBasis: '0%' }),
        ...sx,
      }}
      {...props}
    >
      {children}
    </Flex>
  ),
);

Cell.defaultProps = defaultProps;
