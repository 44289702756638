import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Heading, Text } from 'theme-ui';

import { Divider } from 'components/Divider/Divider';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { timeRoundingTimeSamplesSelector } from 'state/settings';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { getDateFromSeconds } from 'utils/dateTime';
import { UUID } from 'utils/UUID';

export const TimeRoundingSamples = (): React.ReactElement => {
  useLingui();

  const { isSmartphoneBreakpoint, breakpoint } = useThemeBreakpoint();
  const isViewXS = breakpoint < MEDIA_BREAKPOINTS.SM;

  const timeRoundingTimeSamples = useRecoilValue(timeRoundingTimeSamplesSelector);

  return (
    <Flex
      variant="settings.elements.timeRounding"
      sx={{
        ...(isViewXS && {
          flexDirection: 'column',
        }),
      }}
    >
      <Heading as="h5" variant="heading5.withMargins">
        <Trans id="automations_settings.how_it_work">How will it work?</Trans>
      </Heading>
      <Flex
        sx={{
          minHeight: SETTINGS_SPACE.timeRoundingSamplesHeight,
          ...(isSmartphoneBreakpoint && {
            flexDirection: 'column',
            rowGap: 2,
          }),
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            ...(isSmartphoneBreakpoint && {
              minHeight: SETTINGS_SPACE.timeRoundingSamplesHeight,
            }),
          }}
        >
          <Text sx={{ fontSize: 2 }}>
            <Trans id="automations_settings.employee_clocks_in">
              An employee that{' '}
              <Text as="span" sx={{ fontWeight: 'bold' }}>
                clocks-in
              </Text>{' '}
              at...
            </Trans>
          </Text>
          {timeRoundingTimeSamples ? (
            <>
              {timeRoundingTimeSamples.map((sample) => {
                const { isEnter, timeRealSeconds, timeRoundedSeconds } = sample;

                if (!isEnter) return null;

                return (
                  <Text key={UUID()} sx={{ fontSize: 2 }}>
                    {getDateFromSeconds(timeRealSeconds).format('LT')}{' '}
                    {t({ id: 'automations_settings.will_be_rounded', message: 'will be rounded to' })}{' '}
                    {getDateFromSeconds(timeRoundedSeconds).format('LT')}
                  </Text>
                );
              })}
            </>
          ) : (
            <Flex
              sx={{
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingSpinnerCss size={2} />
            </Flex>
          )}
        </Flex>

        <Divider
          axis={isSmartphoneBreakpoint ? 'horizontal' : 'vertical'}
          sx={{ ...(isSmartphoneBreakpoint ? { mx: 0 } : { mx: '0.75rem' }) }}
        />

        <Flex
          sx={{
            flexDirection: 'column',
            ...(isSmartphoneBreakpoint && {
              minHeight: SETTINGS_SPACE.timeRoundingSamplesHeight,
            }),
          }}
        >
          <Text sx={{ fontSize: 2 }}>
            <Trans id="automations_settings.employee_clocks_out">
              An employee that{' '}
              <Text as="span" sx={{ fontWeight: 'bold' }}>
                clocks-out
              </Text>{' '}
              at...
            </Trans>
          </Text>
          {timeRoundingTimeSamples ? (
            <>
              {timeRoundingTimeSamples.map((sample) => {
                const { isEnter, timeRealSeconds, timeRoundedSeconds } = sample;

                if (isEnter) return null;

                return (
                  <Text key={UUID()} sx={{ fontSize: 2 }}>
                    {getDateFromSeconds(timeRealSeconds).format('LT')}{' '}
                    {t({ id: 'automations_settings.will_be_rounded' })}{' '}
                    {getDateFromSeconds(timeRoundedSeconds).format('LT')}
                  </Text>
                );
              })}
            </>
          ) : (
            <Flex
              sx={{
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingSpinnerCss size={2} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
