import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useClient, useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';

import {
  acceptRequestManagementAction,
  acceptSwapAction,
  fetchRequestDetailsAction,
} from 'api/actions/requests/requestsActions';
import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { useModal } from 'components/Modal/output/useModal';
import { CALENDAR_TYPE, REPORTS_TYPE, REQUESTS_TYPE, TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { useRefreshClockLog } from 'pages/ClockLog/output/useRefreshClockLog';
import { useRequestsToAccept } from 'pages/Home/output/useRequestsToAccept';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { languageSelector } from 'state/recoilState';
import { Step } from 'state/requests';
import { useRefreshRequests } from '../../../../hooks/useRefreshRequests';
import { useRefreshRequestsTypeDetails } from '../../../../hooks/useRefreshRequestsTypeDetails';
import { RequestDetailsGroupedParam } from '../../../types';
import { useRefreshRequestsUsageOverview } from '../../hooks/useRefreshRequestsUsageOverview';

import { useRequestDetails } from './useRequestDetails';

export const useRequestDetailsActions = () => {
  useLingui();
  const { handleClose, baseRoute, instanceNumber } = useModal({
    wrapperSx: { maxHeight: [null, null, null, '768px !important'] },
  });

  const {
    parsedRequestDetails,
    setRequestDetails,
    setParsedRequestDetails,
    setOpenerRequestDetails,
    setOpenerParsedRequestDetails,
    locationState: { opener },
    urlParams: { id, group: groupParam, requestsType },
  } = useRequestDetails();

  const openerRef = useRef(opener);

  const navigate = useAppNavigate();
  const language = useRecoilValue(languageSelector);

  const employeesIds = useMemo(() => {
    if (!parsedRequestDetails) return undefined;

    const {
      employee: { id: employeeId },
      swapPersonId,
    } = parsedRequestDetails;

    if (swapPersonId) return [employeeId, swapPersonId];

    return [employeeId];
  }, [parsedRequestDetails]);

  const { updateRequestsNoThrottle, requestsInitialized } = useRefreshRequests();
  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar(employeesIds);
  const { reportInitialized, updateReportForIds } = useRefreshReport(employeesIds);
  const { refreshClockLogForPeopleIds, clockLogInitialized } = useRefreshClockLog(employeesIds);
  const { fetchRequestsToAccept, isInitialized: requestsToAcceptInitialized } = useRequestsToAccept();
  const { updateRequestsTypeDetailsNoThrottle, requestsTypeDetailsInitialized } = useRefreshRequestsTypeDetails();
  const { updateRequestsUsageOverviewNoThrottle, requestsUsageOverviewInitialized } = useRefreshRequestsUsageOverview();

  const [approveLoading, setApproveLoading] = useState(false);
  const requestIdRef = useRef(id);

  const { query } = useClient();
  const { mutate: approveRequest } = useMutation(acceptRequestManagementAction);
  const { mutate: approveSwapRequest } = useMutation(acceptSwapAction);

  const refreshUrl = useCallback(
    (payloadId: string) => {
      const allowDoubleRequestModals =
        Object.values(CALENDAR_TYPE).some((value) => value[language] === requestsType) ||
        Object.values(REPORTS_TYPE).some((value) => value[language] === requestsType) ||
        [REQUESTS_TYPE.GROUPED[language], REQUESTS_TYPE.USAGE_OVERVIEW[language]].includes(requestsType) ||
        (!requestsType && payloadId !== id);

      if (groupParam === RequestDetailsGroupedParam.Ungrouped && allowDoubleRequestModals) {
        navigate(
          `../../${TO_REL.REQUEST_DETAILS__GROUP__ID[language]}/${RequestDetailsGroupedParam.Grouped}/${payloadId}/${TO_REL.REQUEST_DETAILS__GROUP__ID[language]}/${RequestDetailsGroupedParam.Ungrouped}/${id}`,
        );
      }
      if (
        (payloadId !== id && groupParam === RequestDetailsGroupedParam.Grouped) ||
        (groupParam === RequestDetailsGroupedParam.Ungrouped && !allowDoubleRequestModals)
      ) {
        navigate(`../${TO_REL.REQUEST_DETAILS__GROUP__ID[language]}/${groupParam}/${payloadId}`);
      }
    },
    [groupParam, id, language, navigate, requestsType],
  );

  const getRequestDetails = useCallback(
    async (requestId?: string, grouped?: boolean) => {
      const { error, payload } = await query(
        fetchRequestDetailsAction(
          requestId || requestIdRef.current,
          grouped || groupParam === RequestDetailsGroupedParam.Grouped,
        ),
      );

      if (error) {
        handleClose();
      }

      if (!error && payload) {
        if (!requestId) {
          setRequestDetails(payload);
          setParsedRequestDetails(null);
        } else {
          setOpenerRequestDetails(payload);
          setOpenerParsedRequestDetails(null);
        }
      }
      return payload;
    },
    [
      query,
      groupParam,
      handleClose,
      setRequestDetails,
      setParsedRequestDetails,
      setOpenerRequestDetails,
      setOpenerParsedRequestDetails,
    ],
  );

  const refreshRequestDetails = useCallback(async () => {
    let payload = null;
    payload = await getRequestDetails(undefined, undefined);
    if (openerRef.current) {
      payload = await getRequestDetails(openerRef.current.requestId, true);
    }
    return payload;
  }, [getRequestDetails]);

  const onSubmitRejectDeleteAction = useCallback(async () => {
    if (!parsedRequestDetails) return;
    const { type } = parsedRequestDetails;
    if (type === RequestFormType.TimeTracking && clockLogInitialized) {
      void refreshClockLogForPeopleIds();
    }
    const payload = await refreshRequestDetails();
    if (payload?.id) refreshUrl(payload.id);
  }, [parsedRequestDetails, clockLogInitialized, refreshRequestDetails, refreshUrl, refreshClockLogForPeopleIds]);

  const handleApprove = useCallback(async () => {
    setApproveLoading(true);
    let submitError: boolean;

    if (parsedRequestDetails?.type === RequestFormType.Schedule) {
      const { error } = await approveSwapRequest({ id });
      submitError = error;
    } else {
      const { error } = await approveRequest([id]);
      submitError = error;
    }

    if (!submitError) {
      addSnackbar({
        message: t({
          id: 'request.snackbar_approved',
          message: 'Request approved!',
        }),
        variant: 'success',
      });
      if (requestsInitialized) void updateRequestsNoThrottle(true);
      if (calendarInitialized) void updateCalendarForIds();
      if (reportInitialized) void updateReportForIds();
      if (requestsToAcceptInitialized) void fetchRequestsToAccept(true);
      if (clockLogInitialized) void refreshClockLogForPeopleIds();
      if (requestsTypeDetailsInitialized) void updateRequestsTypeDetailsNoThrottle(true);
      if (requestsUsageOverviewInitialized) void updateRequestsUsageOverviewNoThrottle(true);
      await refreshRequestDetails();
    }

    setApproveLoading(false);
  }, [
    parsedRequestDetails?.type,
    approveSwapRequest,
    id,
    approveRequest,
    requestsInitialized,
    updateRequestsNoThrottle,
    calendarInitialized,
    updateCalendarForIds,
    reportInitialized,
    updateReportForIds,
    requestsToAcceptInitialized,
    fetchRequestsToAccept,
    clockLogInitialized,
    refreshClockLogForPeopleIds,
    requestsTypeDetailsInitialized,
    updateRequestsTypeDetailsNoThrottle,
    requestsUsageOverviewInitialized,
    updateRequestsUsageOverviewNoThrottle,
    refreshRequestDetails,
  ]);

  const handleReject = useCallback(() => {
    navigate(TO_REL.REJECT_REQUESTS_MODAL[language], {
      state: {
        id,
        requestNumber: parsedRequestDetails?.number,
        instanceNumber,
        isSchedule: parsedRequestDetails?.type === RequestFormType.Schedule,
      },
    });
  }, [navigate, language, id, parsedRequestDetails?.number, parsedRequestDetails?.type, instanceNumber]);

  const handleModificationRequestClick = useCallback(
    (action: RequestActionType) => {
      if (parsedRequestDetails) {
        const route =
          action === RequestActionType.Remove
            ? TO_REL.REQUEST_REMOVE_MODAL[language]
            : TO_REL.REQUEST_MODIFICATION_MODAL[language];
        navigate(`${openerRef.current ? baseRoute : ''}${baseRoute}${route}`, {
          replace: true,
          relative: 'path',
          state: {
            deleteGroupRequest: {
              latestInGroup: parsedRequestDetails.latestInGroup,
              state: parsedRequestDetails.state,
            },
            requestConfig: {
              action,
              hideActionSelect: true,
              employeesIds: [parsedRequestDetails.employee.id],
              excludedSteps: [Step.SelectTeammates, Step.SelectType],
              requestManagement: {
                id: parsedRequestDetails.id,
              },
              requestType: parsedRequestDetails.type,
            },
          },
        });
      }
    },
    [baseRoute, language, navigate, parsedRequestDetails],
  );

  return {
    getRequestDetails,
    refreshRequestDetails,
    onSubmitRejectDeleteAction,
    handleApprove,
    handleReject,
    handleModificationRequestClick,
    approveLoading,
  };
};
