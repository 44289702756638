/**
 * Creates a promise that will resolve after the specified time.
 *
 * @param {number} ms Time in milliseconds.
 * @return {Promise<number>} Return the created promise.
 */

export const delay = (ms: number): Promise<number> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
