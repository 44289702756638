import React from 'react';
import { useRecoilValue } from 'recoil';

import { useFetchCountryDetailsManager } from 'hooks/useFetchCountryDetailsManager/useFetchCountryDetailsManager';
import { currencyOptionsSelector } from 'state/selectOptions';
import { Select, SelectProps } from '../Select';

type Props = Omit<SelectProps, 'options'>;

export const CurrencySelect = React.forwardRef<HTMLInputElement, Props>(
  ({ ...props }: Props, ref): React.ReactElement => {
    useFetchCountryDetailsManager(true);
    const currencySelectOptions = useRecoilValue(currencyOptionsSelector);

    return <Select ref={ref} {...props} updateViewOnOptionsChange options={currencySelectOptions} />;
  },
);
