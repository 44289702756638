import { t } from '@lingui/macro';
import _ from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { addSnackbar } from 'base/Snackbar/output/actions';
import { PATH, TO } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { languageSelector } from 'state/recoilState';

const checkIfLocationAllowed = (pathname: string, allowedPaths: string[]) => {
  const isAllowed = !!_.find(allowedPaths, (x) => {
    const match = matchPath(x, pathname);

    return !!match;
  });
  return isAllowed;
};

export const AppPermissionRedirectObserver: React.ElementType = () => {
  const language = useRecoilValue(languageSelector);

  const navigate = useAppNavigate();
  const { pathname, ...location } = useLocation();
  const { isAccountBlocked, isTooManyUsersBlocked, systemManagement, modulesManagement } = useAppPermissions();

  const previousAllowedPathname = useRef<string | undefined>();

  const allowedPathsAppBlocked = useMemo(
    () => [
      PATH.PAYMENTS[language],
      `${PATH.PAYMENTS[language]}${PATH.YOUR_BENEFITS_MODAL[language]}`,
      `${PATH.PAYMENTS[language]}${PATH.REMOVE_PAYMENT_METHOD_MODAL[language]}`,
      `${PATH.PAYMENTS[language]}${PATH.PAYMENT_BILLING_DETAILS_MODAL[language]}`,
      `${PATH.PAYMENTS[language]}${PATH.PAYMENT_UNPAID_BALANCE_MODAL[language]}`,
      `${PATH.PAYMENTS[language]}${PATH.PAYMENT_BILLING_HISTORY_MODAL[language]}`,
      `${PATH.PAYMENTS[language]}${PATH.PAYMENT_METHOD_MODAL[language]}`,
      `${PATH.PAYMENTS[language]}${PATH.CANCEL_PENDING_PAYMENT[language]}`,
      `${PATH.PAYMENTS[language]}${PATH.CANCEL_PAYMENT_SUBSCRIPTION_MODAL[language]}`,
      PATH.SIGN_OUT[language],
      PATH.SETTINGS__ORGANIZATION__ADVANCED[language],
      `${PATH.SETTINGS__ORGANIZATION__ADVANCED[language]}${PATH.YOUR_BENEFITS_MODAL[language]}`,
      `${PATH.SETTINGS__ORGANIZATION__ADVANCED[language]}${PATH.DELETE_ORGANIZATION_MODAL[language]}`,
      `${PATH.SETTINGS__ORGANIZATION__ADVANCED[language]}${PATH.DELETE_AI_FACE_MODAL[language]}`,
      `${PATH.SETTINGS__ORGANIZATION__ADVANCED[language]}${PATH.DELETE_PHOTOS_MODAL[language]}`,
      PATH.SETTINGS__USER__MANAGE_PASSWORD[language],
      PATH.TEAM[language],
      `${PATH.TEAM[language]}${PATH.DELETE_TEAMMATES_MODAL[language]}`,
      `${PATH.TEAM[language]}${PATH.VIEW_SETTINGS_MODAL[language]}`,
    ],
    [language],
  );

  useEffect(() => {
    if (isAccountBlocked || isTooManyUsersBlocked) {
      if (!systemManagement.Payments) {
        navigate(TO.BLOCKED_ACCOUNT[language]);
        return;
      }

      const isAllowed = checkIfLocationAllowed(pathname, allowedPathsAppBlocked);
      let navigateToPreviousCondition = false;

      if (!isAllowed) {
        if (isAccountBlocked) {
          addSnackbar({
            message: t({
              id: 'app_permissions_redirect.errors.account_blocked',
              message: 'Account is blocked, because your subscription is inactive. Resume subscription.',
            }),
            variant: 'danger',
            duration: 8000,
          });

          navigateToPreviousCondition = !!(
            previousAllowedPathname.current &&
            pathname.includes(PATH.TEAM[language]) &&
            modulesManagement.Employees
          );
        } else {
          addSnackbar({
            message: t({
              id: 'app_permissions_redirect.errors.too_many_users_blocked',
              message:
                "You've reached the maximum number of users allowed for this account. Please buy more slots, or delete some users.",
            }),
            variant: 'danger',
            duration: 8000,
          });

          navigateToPreviousCondition = !!(previousAllowedPathname.current && modulesManagement.Employees);
        }

        if (navigateToPreviousCondition) {
          navigate(previousAllowedPathname.current);
        } else {
          navigate(TO.PAYMENTS[language]);
        }
      }

      if (isAllowed) {
        previousAllowedPathname.current = pathname;
      }
    } else {
      previousAllowedPathname.current = undefined;
    }
  }, [
    allowedPathsAppBlocked,
    isAccountBlocked,
    isTooManyUsersBlocked,
    language,
    location.state,
    modulesManagement.Employees,
    pathname,
    navigate,
    systemManagement.Payments,
    location,
  ]);

  return null;
};
