import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { scheduleWizardAction } from 'api/actions/calendar/calendarActions';
import { ScheduleWizardOptionsProps, ScheduleWizardSelectedDays } from 'api/actions/calendar/calendarActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { useModal } from 'components/Modal/output/useModal';
import { ListNames } from 'components/StickyList/types';
import { DualCalendar } from 'components/ui/DualCalendar/DualCalendar';
import { Switch } from 'components/ui/Switch';
import { useMount } from 'hooks/useMount/useMount';
import { usePickTeammates } from 'hooks/usePickTeammates/usePickTeammates';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { getScheduleWizardOptionsFormValuesAtom } from 'state/calendar';
import { MinimizeModalScheduleWizard } from 'state/modal';
import { minCreateDateUnixSelector } from 'state/userSession';
import { createEvent } from 'utils/createEvent';
import { dateTime } from 'utils/dateTime';
import { dualCalendarRegisterOptionsFactory } from 'utils/dualCalendarRegisterOptionsFactory';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useRefreshCalendar } from '../../../../hooks/useRefreshCalendar';
import { useScheduleWizard } from '../hooks/useScheduleWizard';

import { WeekdaysPicker } from './WeekdaysPicker';

const getDefaultValues = (): Partial<ScheduleWizardOptionsProps> => ({
  ignoreHolidays: true,
  selectedDays: [
    ScheduleWizardSelectedDays.Monday,
    ScheduleWizardSelectedDays.Tuesday,
    ScheduleWizardSelectedDays.Wednesday,
    ScheduleWizardSelectedDays.Thursday,
    ScheduleWizardSelectedDays.Friday,
  ],
});

export const ScheduleWizardModalStep3 = (): React.ReactElement => {
  const [selectedTeammatesIds] = usePickTeammates(ListNames.REQUESTS_PICK_TEAMMATES);
  const setGetScheduleWizardOptionsFormValues = useSetRecoilState(getScheduleWizardOptionsFormValuesAtom);
  const minCreateDateUnix = useRecoilValue(minCreateDateUnixSelector);

  const formRef = useRef<HTMLFormElement | null>(null);

  const memoDefVal = useMemo(() => getDefaultValues(), []);

  useLingui();
  const { schedule, setOnClickCallback, setDisabledButton, setLoading } = useScheduleWizard();
  const { handleClose } = useModal();
  const { mutate } = useMutation<unknown, unknown, ScheduleWizardOptionsProps>((wizardOptions) =>
    scheduleWizardAction(schedule, selectedTeammatesIds, wizardOptions),
  );

  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar(selectedTeammatesIds);
  const { reportInitialized, updateReportForIds } = useRefreshReport(selectedTeammatesIds);
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalScheduleWizard>();

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: ScheduleWizardOptionsProps) => {
      setLoading(true);
      const { error: submitError } = await mutate(body);

      if (!submitError) {
        if (calendarInitialized) await updateCalendarForIds();
        if (reportInitialized) await updateReportForIds();
        handleClose(true);
        return;
      }

      setLoading(false);
    },
    [calendarInitialized, handleClose, mutate, reportInitialized, setLoading, updateCalendarForIds, updateReportForIds],
  );

  const { watch, register, handleSubmit, formState, setError, clearErrors, getValues } =
    useForm<ScheduleWizardOptionsProps>({
      mode: 'onTouched',
      reValidateMode: 'onChange',
      defaultValues: minimizedModalDetails?.modalDetails.scheduleWizardOptions || memoDefVal,
    });

  const { errors } = formState;
  const currentFormValues = watch();

  useMount(() => {
    if (setOnClickCallback) {
      setOnClickCallback(() => submitForm);
    }
  });

  useEffect(() => {
    setDisabledButton(!currentFormValues.dateRanges || !currentFormValues.selectedDays);
  }, [currentFormValues.dateRanges, currentFormValues.selectedDays, setDisabledButton]);

  const handleSubmitCallback: SubmitHandler<ScheduleWizardOptionsProps> = useCallback(
    (body) => {
      const formValues = body;
      void onSubmit(formValues);
    },
    [onSubmit],
  );

  const handleSubmitErrorCallback = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const calendarRegister = useMemo(
    () =>
      register(
        'dateRanges',
        dualCalendarRegisterOptionsFactory({ isRange: true, additionalRegisterOptions: { required: true } }),
      ),
    [register],
  );

  const setCalendarErrorCallback = useCallback(() => {
    setError('dateRanges', {
      type: 'required',
    });
  }, [setError]);

  const clearCalendarErrorCallback = useCallback(() => {
    clearErrors('dateRanges');
  }, [clearErrors]);

  useMount(() => {
    setGetScheduleWizardOptionsFormValues(() => getValues);
  });

  return (
    <>
      <form
        ref={formRef}
        onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))}
      ></form>
      <Flex sx={{ flexDirection: 'column' }}>
        <Modal.SubTitle>
          <Trans id="schedule.wizard.select_weekdays">Select weekdays</Trans>
        </Modal.SubTitle>
        <WeekdaysPicker
          {...register('selectedDays', {
            setValueAs: (weekdays: string) => {
              if (!weekdays || _.isEmpty(weekdays) || _.isArray(weekdays)) return undefined;

              const parsedWeekdays = weekdays.split(',').map((weekday) => parseInt(weekday, 10));
              return parsedWeekdays;
            },
          })}
        />
      </Flex>

      <Flex sx={{ flexDirection: 'column', mt: 4 }}>
        <Modal.SubTitle>
          <Trans id="schedule.wizard.select_date_range">Select date range</Trans>
        </Modal.SubTitle>
        <DualCalendar
          {...calendarRegister}
          range={true}
          error={!!errors?.dateRanges}
          onValidError={setCalendarErrorCallback}
          onClearError={clearCalendarErrorCallback}
          {...(minCreateDateUnix && { minDate: dateTime(minCreateDateUnix) })}
        />
        <Flex sx={{ flexDirection: 'column', gap: 2, mt: 2 }}>
          <Switch
            label={t({
              id: 'requests.add_request.skip_holidays',
            })}
            {...register('ignoreHolidays')}
          />
          <Switch
            label={t({ id: 'schedule.wizard.overwrite', message: 'Overwrite existing schedules' })}
            {...register('overrideExistingSchedules')}
          />
        </Flex>
      </Flex>
    </>
  );
};
