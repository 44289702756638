/** @jsxImportSource theme-ui */

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { FC, useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { DeepPartial, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { setTagsAction } from 'api/actions/employees/employeesActions';
import { SetTagsActionProps } from 'api/actions/employees/employeesActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { Checkbox } from 'components/ui/Checkbox';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { TagsSelect } from 'components/ui/Select/variants/TagsSelect';
import { PATH_REL, TO_REL } from 'constants/routes';
import { useLocationOnce } from 'hooks/useLocationOnce/useLocationOnce';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { languageSelector } from 'state/recoilState';
import { tagsSelectOptionsSelector } from 'state/team';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { AddTagModal } from '../../tag/AddTag';
import { ManageTagsModal } from '../../tag/ManageTags';

export const SetTags: FC = () => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose, baseRoute } = useModal();
  const language = useRecoilValue(languageSelector);
  const [loading, setLoading] = useState(false);
  const tagsSelectOptions = useRecoilValue(tagsSelectOptionsSelector);
  const {
    state: { ids },
  } = useLocationOnce();
  const [selectedEmployeesIds] = useState(ids || []);
  const formRef = useRef<HTMLFormElement | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tagsIds: undefined,
      overwrite: false,
    },
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const { mutate } = useMutation(setTagsAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit: SubmitHandler<DeepPartial<Omit<SetTagsActionProps, 'employeesIds'>>> = useCallback(
    async (body) => {
      if (!selectedEmployeesIds || !selectedEmployeesIds.length) return false;

      const tagsId = (!isEmpty(body.tagsIds) && isString(body.tagsIds) && body.tagsIds.split(',')) || undefined;

      const { error: submitError } = await mutate({
        employeesIds: selectedEmployeesIds || [],
        ...body,
        tagsIds: tagsId || [],
        overwrite: body.overwrite || false,
      });
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.set_tags_bulk.set', message: 'Successfully set!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, selectedEmployeesIds, addSnackbar],
  );
  const onSubmitError = () => {
    setLoading(false);
  };

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  if (!tagsSelectOptions) return <Navigate to={baseRoute} relative="path" />;

  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.set_tags_bulk">Set tags</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          sx={{ width: '340px', maxWidth: '100%' }}
          ref={formRef}
          onSubmit={floatingPromiseReturn(handleSubmit(onSubmit, onSubmitError))}
          noValidate
        >
          <Text sx={{ ml: 2, mt: 3, fontSize: 1, fontWeight: 'bold' }}>
            <Trans id="team.set_tags_bulk.tags">Tags</Trans> |{' '}
            <LinkAnchor replace standOut sx={{ textDecoration: 'underline' }} to={TO_REL.ADD_TAG_MODAL[language]}>
              <Trans id="team.set_tags_bulk.add">Add</Trans>
            </LinkAnchor>{' '}
            <Trans id="team.set_tags_bulk.or">or</Trans>{' '}
            <LinkAnchor replace standOut sx={{ textDecoration: 'underline' }} to={TO_REL.MANAGE_TAGS_MODAL[language]}>
              <Trans id="team.set_tags_bulk.manage">Manage</Trans>
            </LinkAnchor>
          </Text>

          <TagsSelect
            size="sm"
            id="tagsIds"
            multi
            placeholder={t({ id: 'team.set_tags_bulk.select_add', message: 'Select or add a new one' })}
            error={!!errors.tagsIds}
            errorMessage={errors?.tagsIds?.message}
            searchable
            options={tagsSelectOptions}
            clearable
            checkValueForNonexistentIds
            {...register('tagsIds', {
              required: t({ id: 'global.forms.required' }),
            })}
          />
          <br />
          <Checkbox
            labelWrapperSx={{ ml: -2 }}
            label={t({ id: 'team.set_tags_bulk.overwrite', message: 'Overwrite existing tags settings' })}
            size="sm"
            {...register('overwrite')}
          />
        </form>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'team.set_tags_bulk.next', message: 'Save' }),
          },
        ]}
      />
      <Routes>
        <Route
          path={PATH_REL.ADD_TAG_MODAL[language]}
          element={
            <Modal replaceMode>
              <AddTagModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.MANAGE_TAGS_MODAL[language]}
          element={
            <Modal replaceMode size="lg" fullHeight>
              <ManageTagsModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
