import React from 'react';
import { Flex, Text } from 'theme-ui';

type Props = {
  title?: string | React.ReactNode;
  errors: {
    itemDescription: string;
    message: string | React.ReactNode;
    secondaryMessage?: string;
  }[];
  footer?: string;
};

export const ErrorGroupMessage = ({ title, errors, footer }: Props): React.ReactElement => (
  <Flex sx={{ textAlign: 'left', flexDirection: 'column', p: 2 }}>
    {!!title && <Text sx={{ fontWeight: 'bold', mb: 2 }}>{title}:</Text>}

    {errors.map(({ itemDescription, message, secondaryMessage }, index) => (
      <Flex key={index} sx={{ flexDirection: 'column', mb: 2 }}>
        <Text>
          <Text as="span" sx={{ fontWeight: 'bold' }}>
            {index + 1}.
          </Text>
          {` ${itemDescription}: ${message}`}
        </Text>
        {secondaryMessage && (
          <Text sx={{ fontSize: 2 }}>
            {'•'} {secondaryMessage}
          </Text>
        )}
      </Flex>
    ))}
    {footer && <Text sx={{ fontSize: 2, mt: 1 }}>{footer}</Text>}
  </Flex>
);
