import React from 'react';
import { Flex, Heading, Text } from 'theme-ui';

import { LoadingSpinnerSize } from 'components/Loading/LoadingSpinnerCSS';
import { Image } from 'components/ui/Image';
import { getSrcSet } from 'utils/getSrcSet';
import { IntegrationTileElementsProps } from '../types';

type Props = Pick<IntegrationTileElementsProps, 'description' | 'heading' | 'image' | 'connectedAccount'>;

export const Body = ({ image, heading, description, connectedAccount }: Props): React.ReactElement => (
  <Flex sx={{ flexDirection: 'column', gap: 2 }}>
    <Image
      sx={{ alignSelf: 'flex-start', minHeight: '60px' }}
      src={image}
      srcSet={getSrcSet(image)}
      alt={heading}
      loadingOverlaySize={LoadingSpinnerSize.imageSM}
    />
    <Heading variant="heading4">{heading}</Heading>
    <Text sx={{ fontSize: 2 }}>{description}</Text>
    {connectedAccount && <Text sx={{ fontSize: 2, color: 'settings.connected' }}>{connectedAccount}</Text>}
  </Flex>
);
