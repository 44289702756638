import React, { useEffect, useRef } from 'react';
import { t } from '@lingui/macro';
import { useRecoilState } from 'recoil';
import { Flex } from 'theme-ui';
import { useLingui } from '@lingui/react';
import _ from 'lodash';

import { searchFilterValueSelectorFamily, SEARCH_FILTER_TYPE } from 'state/filters';
import { TextInput, TextInputProps } from 'components/ui/TextInput';
import { mergeRefs } from 'utils/mergeRefs';
import { useMount } from 'hooks/useMount/useMount';

type Props = Partial<Omit<TextInputProps, 'type'>> & {
  type: SEARCH_FILTER_TYPE;
  defaultSearchInputValue?: string;
};

export const FilterSearchInput = React.memo(
  React.forwardRef<HTMLInputElement, Props>(
    ({ type, defaultSearchInputValue, sx, ...textInputProps }: Props, ref): React.ReactElement => {
      const searchInputRef = useRef<HTMLInputElement | null>(null);

      const [searchFilterValue, setSearchFilterValue] = useRecoilState(searchFilterValueSelectorFamily(type));
      useLingui();

      const saveInputValueToRecoil = _.debounce((value: string) => {
        setSearchFilterValue(value);
      }, 300);

      const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        saveInputValueToRecoil(value);
      };

      useEffect(() => {
        if (searchFilterValue !== '' && searchInputRef.current && searchInputRef.current.value !== searchFilterValue) {
          searchInputRef.current.value = searchFilterValue;
        }
      }, [searchFilterValue]);

      useMount(() => {
        if (!_.isUndefined(defaultSearchInputValue) && searchInputRef.current) {
          setSearchFilterValue(defaultSearchInputValue);
          searchInputRef.current.value = defaultSearchInputValue;
        }
      });

      return (
        <Flex sx={sx}>
          <TextInput
            ref={mergeRefs([ref, searchInputRef])}
            onChange={handleSearchInputChange}
            sx={{
              bg: 'asideFilters.search.bg.default',
              '&:hover:not([data-disabled="true"])': {
                bg: 'asideFilters.search.bg.hover',
              },
            }}
            size="sm"
            id="searchInput"
            placeholder={t({ id: 'aside_filters.search', message: 'Search' })}
            icon="search"
            clearable
            {...textInputProps}
          />
        </Flex>
      );
    },
  ),
);
