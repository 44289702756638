import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useRef } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { Switch } from 'components/ui/Switch';
import { blockTransitionAtom } from 'state/settings';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useFormCustomEvent } from '../../../../hooks/useFormCustomEvent';
import { useFormSubmit } from '../../../../hooks/useFormSubmit';
import { useSettingsBlockRouteTransition } from '../../../../hooks/useSettingsBlockRouteTransition';
import { SettingsGroup } from '../../../SettingsGroup/SettingsGroup';

import { useDefaultNotifications } from './hooks/useDefaultNotifications';
import { useSubmitDefaultNotifications } from './hooks/useSubmitDefaultNotifications';

export const DefaultNotificationsForm = (): React.ReactElement => {
  useLingui();
  const resetBlockTransition = useResetRecoilState(blockTransitionAtom);
  const setBlockTransition = useSetRecoilState(blockTransitionAtom);
  const { defaultNotificationsSettings, setDefaultNotificationsSettings } = useDefaultNotifications();
  // currently not used, but can be needed again
  // const smsLink = useHelpLink({
  //   inEwi: { pl: '/article/wiadomosci-sms-v3-szu8u2/' },
  // });

  const formRef = useRef<HTMLFormElement | null>(null);

  const { dispatchSubmitEvent, throttledDispatchSubmitEvent } = useFormSubmit(formRef);

  const handleOnChange = useCallback(() => {
    setBlockTransition((prevState) => ({
      ...prevState,
      blockLocationPathname: null,
      blockTransition: true,
    }));
    throttledDispatchSubmitEvent.cancel();
    throttledDispatchSubmitEvent();
  }, [setBlockTransition, throttledDispatchSubmitEvent]);

  const { registerOnChange, handleSubmit, getValues } = useFormCustomEvent({
    onChangeCustom: handleOnChange,
    formProps: {
      defaultValues: defaultNotificationsSettings || undefined,
      mode: 'onTouched',
      reValidateMode: 'onChange',
    },
  });

  const handleFormReset = useCallback(() => {
    setDefaultNotificationsSettings(null);
    resetBlockTransition();
  }, [resetBlockTransition, setDefaultNotificationsSettings]);

  const handleSubmitCallback = useSubmitDefaultNotifications({
    handleFormReset,
    getCurrentFormState: getValues,
  });

  useSettingsBlockRouteTransition(dispatchSubmitEvent, throttledDispatchSubmitEvent);

  return (
    <form ref={formRef} onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}>
      <Flex variant="settings.container.vertical.main">
        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title
              additionalInfo={t({
                id: 'notifications_settings.heading.schedules.additional_info',
                message: 'Receive updates when there is a new version of your schedule published.',
              })}
            >
              <Trans id="notifications_settings.heading.schedules">Schedules</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="md">
              <Switch
                {...registerOnChange('schedulesEmails')}
                label={t({ id: 'notifications_settings.email' })}
                bold
                size="sm"
                sx={{ py: SETTINGS_SPACE.switchWithLabel }}
              />
              {/* currently not used, but can be needed again */}
              {/* <Divider />
              <Switch
                {...registerOnChange('schedulesTextMessages')}
                label={t({
                  id: 'notifications_settings.text_messages',
                  message: 'Text messages',
                })}
                additionalInfo={
                  <Text>
                    <Trans id="settings.currently_in_preview">Currently in preview.</Trans>
                    <span> </span>
                    <Link href={smsLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                      {t({id: 'settings.learn_more' })}
                    </Link>
                    .
                  </Text>
                }
                bold
                size="sm"
              /> */}
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>

        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title
              additionalInfo={t({
                id: 'notifications_settings.heading.requests.additional_info',
                message:
                  'Receive updates when there is a new request (Supervisors, Managers, and Administrators) or when your request has been updated.',
              })}
            >
              <Trans id="notifications_settings.heading.requests">Requests</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="md">
              <Switch
                {...registerOnChange('requestsEmails')}
                label={t({ id: 'notifications_settings.email' })}
                bold
                size="sm"
                sx={{ py: SETTINGS_SPACE.switchWithLabel }}
              />
              {/* currently not used, but can be needed again */}
              {/* <Divider />
              <Switch
                {...registerOnChange('requestsTextMessages')}
                label={t({
                  id: 'notifications_settings.text_messages',
                })}
                additionalInfo={
                  <Text>
                    {t({id: 'settings.currently_in_preview' })}
                    <span> </span>
                    <Link href={smsLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                      {t({id: 'settings.learn_more' })}
                    </Link>
                    .
                  </Text>
                }
                bold
                size="sm"
              /> */}
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>

        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title
              additionalInfo={t({
                id: 'notifications_settings.heading.messages.additional_info',
                message: 'Receive updates about messages from your teammates when you are not in the app.',
              })}
            >
              <Trans id="notifications_settings.heading.messages">Messages</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="md">
              <Switch
                {...registerOnChange('messagesEmails')}
                label={t({ id: 'notifications_settings.email' })}
                bold
                size="sm"
                sx={{ py: SETTINGS_SPACE.switchWithLabel }}
              />
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>
      </Flex>
    </form>
  );
};
