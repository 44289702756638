import { useEffect, useState } from 'react';
import { useQuery } from 'react-fetching-library';

import { fetchEventsPhotosAction } from 'api/actions/timeEvent/timeEventActions';
import { EventPhotos, TimeEvent } from 'api/actions/timeEvent/timeEventActions.types';

export const useFetchEventsPhotos = (ids?: TimeEvent['id'][]) => {
  const [eventsPhotos, setEventsPhotos] = useState<EventPhotos[] | null>(null);

  const { query, loading } = useQuery(fetchEventsPhotosAction(ids || []), false);

  useEffect(() => {
    const fetchEventsPhotos = async () => {
      const { payload } = await query();
      if (payload) {
        setEventsPhotos(payload);
      }
    };

    if (ids) void fetchEventsPhotos();
  }, [ids, query]);

  return { eventsPhotos, eventsPhotosLoading: loading };
};
