import { useLingui } from '@lingui/react';
import React, { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { FetchAutomationsSettingsResponse } from 'api/actions/settings/settingsActions.types';
import { blockTransitionAtom } from 'state/settings';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useFormSubmit } from '../../../../hooks/useFormSubmit';
import { useSettingsBlockRouteTransition } from '../../../../hooks/useSettingsBlockRouteTransition';
import { FormProviderCustomEvents } from '../../../FormProviderCustomEvents/FormProviderCustomEvents';
import { useAutomationsSettings } from '../../hooks/useAutomationsSettings';

import { Reports } from './components/Reports';
import { TimeSnapping } from './components/TimeSnapping/TimeSnapping';
import { useSubmitAutomationsSettings } from './hooks/useSubmitAutomationsSettings';

export const AutomationsForm = (): React.ReactElement => {
  useLingui();

  const resetBlockTransition = useResetRecoilState(blockTransitionAtom);
  const setBlockTransition = useSetRecoilState(blockTransitionAtom);
  const { getDefaultValuesAutomationsSettings, setAutomationsSettings } = useAutomationsSettings();

  const formRef = useRef<HTMLFormElement | null>(null);

  const { dispatchSubmitEvent, throttledDispatchSubmitEvent } = useFormSubmit(formRef);

  const methods = useForm<FetchAutomationsSettingsResponse>({
    defaultValues: getDefaultValuesAutomationsSettings() || undefined,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const onChangeAndOnBlur = useCallback(() => {
    setBlockTransition((prevState) => ({
      ...prevState,
      blockLocationPathname: null,
      blockTransition: true,
    }));
    throttledDispatchSubmitEvent.cancel();
    throttledDispatchSubmitEvent();
  }, [setBlockTransition, throttledDispatchSubmitEvent]);

  const handleFormReset = useCallback(() => {
    setAutomationsSettings(null);
    resetBlockTransition();
  }, [resetBlockTransition, setAutomationsSettings]);

  const { handleSubmitCallback, handleErrorCallback } = useSubmitAutomationsSettings({
    handleFormReset,
    getCurrentFormState: methods.getValues,
  });

  useSettingsBlockRouteTransition(dispatchSubmitEvent, throttledDispatchSubmitEvent);

  return (
    <FormProviderCustomEvents {...methods} onChange={onChangeAndOnBlur} onBlur={onChangeAndOnBlur}>
      <form
        ref={formRef}
        onSubmit={floatingPromiseReturn(methods.handleSubmit(handleSubmitCallback, handleErrorCallback))}
      >
        <Flex variant="settings.container.vertical.main">
          <Reports />
          <TimeSnapping />
        </Flex>
      </form>
    </FormProviderCustomEvents>
  );
};
