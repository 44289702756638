/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { DefaultRole, Role } from 'api/actions/organizationSession/organizationSessionActions.types';
import { DropdownProps } from 'components/Dropdown/Dropdown';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { ListNames, StickyListProps } from 'components/StickyList/types';
import { Tag } from 'components/Tag/Tag';
import { ManageModal, ManageModalProps } from 'components/recipes/ManageModal';
import { Button } from 'components/ui/Buttons/Button';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { DEFAULT_ROLES_IDS } from 'constants/defaults';
import { PATH_REL, TO_REL } from 'constants/routes';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { filteredRolesMapSelector } from 'state/employees';
import { SEARCH_FILTER_TYPE } from 'state/filters';
import { languageSelector } from 'state/recoilState';

import { AddRoleModal } from './AddRole';
import { DeleteRolesModal } from './DeleteRoles';
import { EditRoleModal } from './EditRole';

const ButtonWithDropdown = withDropdown(Button);

export const ManageRolesModal: FC = () => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const roles = useRecoilValue(filteredRolesMapSelector);
  const navigate = useAppNavigate();
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const listPropsGenerator: NonNullable<ManageModalProps['listPropsGenerator']> = useCallback(
    (replace): StickyListProps<Role> => ({
      name: ListNames.MANAGE_ROLES,
      select: 'checkbox',
      showHeader: true,
      list: roles || new Map(),
      withRowContextMenu: true,
      columns: [
        {
          key: 'color',
          width: '40px',
          customCellRenderer: (color: Role['color']) => (
            <Flex
              sx={{
                width: '24px',
                height: '24px',
                borderRadius: '6px',
                border: '4px solid',
                bg: 'whites0',
                borderColor: UserSelectableColor[color],
              }}
            />
          ),
        },
        {
          title: t({ id: 'team.manage_roles.name', message: 'Name' }),
          key: ['name', 'id', 'defaultRole'],
          sortableValue: ([name, id]: [Role['name'], Role['id']]) =>
            `${(() => {
              const sortCheat = '______________________AAAAAAAAAAAAAAAAAAAAA';
              switch (+id) {
                case DefaultRole.Employee:
                  return sortCheat + sortCheat + sortCheat + sortCheat;
                case DefaultRole.Supervisor:
                  return sortCheat + sortCheat + sortCheat;
                case DefaultRole.Manager:
                  return sortCheat + sortCheat;
                case DefaultRole.Admin:
                  return sortCheat;
                default:
                  return '';
              }
            })()}${t({
              id: name,
            })}`,

          customCellRenderer: ([name, id, defaultRole]: [Role['name'], Role['id'], Role['defaultRole']]) => {
            const { color: defaultRoleColor, name: defaultRoleName } = roles?.get(`${defaultRole}`) || {};

            return (
              <Flex sx={{ gap: 2 }}>
                <TextEllipsis
                  title={t({
                    id: name,
                  })}
                  sx={{ width: 'max-content' }}
                >
                  {t({
                    id: name,
                  })}
                </TextEllipsis>

                {!DEFAULT_ROLES_IDS.includes(id) && defaultRoleColor !== undefined && defaultRoleName && (
                  <Tag
                    variant="outline"
                    color={UserSelectableColor[defaultRoleColor]}
                    sx={{ ...(!isSmartphoneBreakpoint && { flexShrink: 0 }) }}
                  >
                    {t({ id: defaultRoleName })}
                  </Tag>
                )}
              </Flex>
            );
          },
        },
        {
          title: t({ id: 'team.manage_roles.description', message: 'Description' }),
          key: ['description', 'id'],
          sortableValue: ([description, id]: [Role['description'], Role['id']]) =>
            description
              ? `${DEFAULT_ROLES_IDS.includes(id) && '_'}${t({
                  id: description,
                })}`
              : '',
          customCellRenderer: ([description]: [Role['description']]) => (
            <TextEllipsis
              title={t({
                id: description,
              })}
            >
              {t({
                id: description,
              })}
            </TextEllipsis>
          ),
        },
        {
          key: 'id',
          width: '40px',
          sx: {
            overflow: 'visible',
          },
          customCellRenderer: (item, itemId, rowRef) => {
            const restrictedLinks: DropdownProps['links'] = [
              {
                disabled: !!DefaultRole[+item],
                replace,
                prependWithDivider: true,
                label: t({ id: 'team.manage_roles.delete', message: 'Delete' }),
                icon: 'delete',
                to: TO_REL.DELETE_ROLES_MODAL[language],
                state: { ids: [item] },
              },
            ];
            return (
              <ButtonWithDropdown
                key={itemId}
                dropdownProps={{
                  links: [
                    {
                      replace,
                      label: t({ id: 'team.manage_roles.duplicate', message: 'Duplicate' }),
                      icon: 'copy',
                      to: TO_REL.ADD_ROLE_MODAL[language],
                      state: { id: item },
                    },
                    ...(!DefaultRole[+item] ? restrictedLinks : []),
                  ],
                }}
                popperProps={{
                  onOutsideClick: () => {
                    rowRef?.current?.setAttribute('data-dropdown', 'false');
                  },
                  contextMenuId: itemId,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  rowRef?.current?.setAttribute('data-dropdown', 'true');
                }}
                shape="rounded"
                size="sm"
                variant="minimal"
              >
                <Icon type="more" />
              </ButtonWithDropdown>
            );
          },
        },
      ],
      onRowClick: (listItemId) => {
        navigate(
          `${
            TO_REL[DefaultRole[+listItemId] ? 'PREVIEW_ROLE_MODAL__ID' : 'EDIT_ROLE_MODAL__ID'][language]
          }/${listItemId}`,
          { replace },
        );
      },
      isRowUncheckableValidator: (listItemId) => !!DefaultRole[+listItemId],
    }),
    [roles, isSmartphoneBreakpoint, language, navigate],
  );

  const modalLinksPaths = useMemo(
    () => ({
      add: TO_REL.ADD_ROLE_MODAL[language],
      delete: TO_REL.DELETE_ROLES_MODAL[language],
    }),
    [language],
  );

  return (
    <>
      <ManageModal
        allResources={roles}
        restrictedIds={DEFAULT_ROLES_IDS}
        title={t({ id: 'team.manage_roles', message: 'Manage roles' })}
        listPropsGenerator={listPropsGenerator}
        modalLinksPaths={modalLinksPaths}
        searchFilterType={SEARCH_FILTER_TYPE.MANAGE_ROLES}
      />
      <Routes>
        <Route
          path={PATH_REL.ADD_ROLE_MODAL[language]}
          element={
            <Modal replaceMode>
              <AddRoleModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.EDIT_ROLE_MODAL__ID[language]}
          element={
            <Modal replaceMode path={PATH_REL.EDIT_ROLE_MODAL__ID[language]}>
              <EditRoleModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.PREVIEW_ROLE_MODAL__ID[language]}
          element={
            <Modal replaceMode path={PATH_REL.PREVIEW_ROLE_MODAL__ID[language]}>
              <EditRoleModal isPreview />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.DELETE_ROLES_MODAL[language]}
          element={
            <Modal size="xs" replaceMode>
              <DeleteRolesModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
