import { dateTime, getDateWithTimeInSecondsUnix } from 'utils/dateTime';

//  eslint-disable-next-line no-restricted-imports
import {
  DAY_PLACEHOLDER,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_STATIC_VALUE,
  MONTH_PLACEHOLDER,
  RANGE_DIVIDER,
  YEAR_PLACEHOLDER,
} from './Calendar/constants';

export const getMaskedValue = (
  unixDates: number[],
  isStatic: boolean,
  currentMaskedValue?: string,
  utc?: boolean,
): string => {
  const partialString = currentMaskedValue && currentMaskedValue.split(RANGE_DIVIDER);

  const stringFromUnix = unixDates
    .map((date) =>
      isStatic
        ? dateTime(date, { utc }).format(DEFAULT_DATE_STATIC_VALUE)
        : dateTime(date, { utc }).format(DEFAULT_DATE_FORMAT),
    )
    .join(RANGE_DIVIDER);

  const maskedString =
    unixDates.length < 2 && !isStatic && partialString && partialString[0].match(/[DMY]/g)?.length
      ? [stringFromUnix, partialString && partialString[1]].join(RANGE_DIVIDER)
      : stringFromUnix;

  return maskedString;
};

export const getDateStringsFromMaskedValue = (maskedInputValue: string): string[] =>
  maskedInputValue
    .split(RANGE_DIVIDER)
    .map((date) =>
      date.replaceAll(YEAR_PLACEHOLDER, '').replaceAll(MONTH_PLACEHOLDER, '').replaceAll(DAY_PLACEHOLDER, ''),
    )
    .filter((date) => date.length === DEFAULT_DATE_FORMAT.length);

export const getDatesWithTime = (dates: number[], times: number[], utc?: boolean): number[] =>
  dates.map((date, index) => getDateWithTimeInSecondsUnix(date, times[index] || 0, utc));

export const parsedHiddenInputValue = (value: string | undefined): number[] => {
  let initialValue;
  if (typeof value === 'string') {
    initialValue = value ? value.split(',').map((date) => parseInt(date, 10)) : [];
  } else if (typeof value === 'number') {
    initialValue = [value];
  }
  return initialValue || [];
};
