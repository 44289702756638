import _ from 'lodash';
import { FC, forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { Flex, Text, TextProps } from 'theme-ui';

import { PopperState } from 'components/ui/PopperProvider/PopperProvider';
import { withPopper } from 'components/ui/PopperProvider/withPopper';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { useMount } from 'hooks/useMount/useMount';
import { useOnResize } from 'hooks/useOnResize/useOnResize';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';

// Wrap text ellipsis to avoid passing popperState to html tag
const TextEllipsisWrapper = forwardRef<HTMLDivElement, TextProps & { popperState?: PopperState }>(
  ({ popperState, ...rest }, ref) => <TextEllipsis {...rest} ref={ref} />,
);
const TextEllipsisWithPopper = withPopper(TextEllipsisWrapper);

type Props = {
  note: string;
};

export const NotePopper: FC<Props> = ({ note }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [width, setWidth] = useState(0);
  const ref = useRef<{
    hoverTimeoutRef: NodeJS.Timeout | null;
    constantlyHoveringNote: string;
    noteWrapperRef: HTMLDivElement | null;
    containerRef: HTMLElement | null;
  }>({
    hoverTimeoutRef: null,
    constantlyHoveringNote: '',
    containerRef: null,
    noteWrapperRef: null,
  });
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();
  const noteWrapperWidth = ref.current.noteWrapperRef?.getBoundingClientRect()?.width;
  const isEllipsis = noteWrapperWidth ? width - noteWrapperWidth <= 0 : false;
  const allowPopper = isEllipsis && !isSmartphoneBreakpoint;
  const handleMouseOver = useCallback(() => {
    ref.current.constantlyHoveringNote = note;
    ref.current.hoverTimeoutRef = setTimeout(() => {
      if (ref.current.constantlyHoveringNote === note) {
        setIsHovering(true);
      }
    }, 300);
  }, [note]);

  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  const content = useMemo(
    () => (
      <Text
        as="span"
        sx={{
          borderRadius: 'xs',
          width: '100%',
          wordBreak: 'break-all',
          fontSize: 1,
          color: 'texts.lighter',
          lineHeight: '14px',
        }}
      >
        {note}
      </Text>
    ),
    [note],
  );

  const handleResizeDebounce = useMemo(
    () =>
      _.debounce(() => {
        if (ref.current.containerRef) {
          setWidth(ref.current.containerRef.getBoundingClientRect().width);
        }
      }, 500),
    [],
  );

  useMount(() => {
    if (ref.current.containerRef) {
      setWidth(ref.current.containerRef.getBoundingClientRect().width);
    }
  });
  useOnResize(handleResizeDebounce);

  return (
    <Flex
      ref={(node) => {
        ref.current.containerRef = node;
      }}
      sx={{ maxWidth: '100%', width: '100%' }}
    >
      <TextEllipsisWithPopper
        {...(allowPopper && {
          onMouseOver: handleMouseOver,
          onMouseLeave: () => {
            if (ref.current.hoverTimeoutRef) {
              clearTimeout(ref.current.hoverTimeoutRef);
            }
            ref.current.constantlyHoveringNote = '';
          },
        })}
        popperProps={{
          withPopperState: true,
          content,
          placement: 'top-start',
          trigger: 'manual',
          visible: isHovering,
          customModifiers: [
            {
              name: 'offset',
              options: {
                // minus padding and border
                offset: ({ popper }) => [-5, -(popper.height - 4)],
              },
            },
          ],
          popperContainerSx: {
            borderRadius: 'xs',
            border: '1px solid',
            borderColor: 'alphas.darker4',
            bg: 'white',
            boxShadow: 'dropShadow.levelOne',
            px: 1,
            py: '3px',
            // add padding left and padding right to container width
            ...(width && { width: `${width + 6}px` }),
          },
          ...(allowPopper && { popperContainerProps: { onMouseLeave: handleMouseOut } }),
        }}
        sx={{ color: 'texts.lighter', fontSize: 1, lineHeight: '14px' }}
      >
        <Text
          as="span"
          ref={(node) => {
            ref.current.noteWrapperRef = node;
          }}
        >
          {note}
        </Text>
      </TextEllipsisWithPopper>
    </Flex>
  );
};
