import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { requestsSettingsAction } from 'api/actions/settings/settingsActions';
import { FetchRequestsSettingsResponse, RequestsSettingsActionProps } from 'api/actions/settings/settingsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { SnackbarProps } from 'components/Snackbar/types';
import { REQUESTS_SETTINGS_RESET } from 'constants/settings';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { blockTransitionAtom, resetFormButtonAtom } from 'state/settings';
import { getRefreshSnackbarProps } from '../../../../../utils/getRefreshSnackbarProps';
import { useRequestsSettings } from '../../../hooks/useRequestsSettings';

type Props = {
  handleFormReset: () => void;
  getCurrentFormState: () => FetchRequestsSettingsResponse;
};

export const useSubmitRequestsSettings = ({ handleFormReset, getCurrentFormState }: Props) => {
  useLingui();
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const [{ skipFetch, isRequestPending }, setBlockTransition] = useRecoilState(blockTransitionAtom);
  const { updateCalendar, calendarInitialized } = useRefreshCalendar();
  const { requestsSettings, fetchRequestsSettings, getParsedRequestsSettings, fetchAbort } = useRequestsSettings();
  const { mutate } = useMutation(requestsSettingsAction);
  const skipFetchRef = useCallbackRef(skipFetch);
  const isRequestPendingRef = useCallbackRef(isRequestPending);
  const getCurrentFormStateRef = useCallbackRef(getCurrentFormState);
  const prevFormStateRef = useRef<FetchRequestsSettingsResponse | null>(requestsSettings);
  const pendingRequestBodyRef = useRef<Partial<FetchRequestsSettingsResponse> | null>(null);

  const requestsSettingsResetObject = useMemo(
    () => ({ name: REQUESTS_SETTINGS_RESET, callback: handleFormReset }),
    [handleFormReset],
  );

  const handleSubmitCallback = useCallback(
    async (data: FetchRequestsSettingsResponse) => {
      const submittedFormState = data;
      const parsedData = getParsedRequestsSettings(submittedFormState);
      const parsedRequestsSettings = getParsedRequestsSettings(requestsSettings);

      if (
        _.isEqual(parsedData, parsedRequestsSettings) &&
        !pendingRequestBodyRef.current &&
        !isRequestPendingRef.current
      ) {
        setBlockTransition((prevState) => ({ ...prevState, blockTransition: false }));
        setResetCallbacks(null);
        return;
      }

      const parsedPrevFormState = getParsedRequestsSettings(prevFormStateRef.current);
      const currentChanges: Partial<RequestsSettingsActionProps> = _.omitBy(
        parsedData,
        (value, key) =>
          parsedPrevFormState && _.isEqual(value, parsedPrevFormState[key as keyof RequestsSettingsActionProps]),
      );

      fetchAbort();
      setResetCallbacks(null);
      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: true }));

      pendingRequestBodyRef.current = { ...pendingRequestBodyRef.current, ...currentChanges };
      prevFormStateRef.current = submittedFormState;

      const { error, status } = await mutate(pendingRequestBodyRef.current);

      const formStateAfterRequest = getCurrentFormStateRef.current();

      if (!_.isEqual(submittedFormState, formStateAfterRequest)) {
        return;
      }

      if (!error) {
        if (
          calendarInitialized &&
          (!_.isUndefined(pendingRequestBodyRef.current.carriedOverLimitExpiresAfter) ||
            !_.isUndefined(pendingRequestBodyRef.current.allowCarryOverUnUsedLimits))
        ) {
          updateCalendar();
        }

        let showRefreshSnackbar = false;
        pendingRequestBodyRef.current = null;

        if (!skipFetchRef.current) {
          const { error: fetchError, payload: fetchPayload } = await fetchRequestsSettings();

          const formStateAfterFetch = getCurrentFormStateRef.current();

          if (!_.isEqual(submittedFormState, formStateAfterFetch)) {
            return;
          }

          if (!fetchError && fetchPayload) {
            const parsedFormStateAfterFetch = getParsedRequestsSettings(formStateAfterFetch);
            const parsedFetchPayload = getParsedRequestsSettings(fetchPayload);
            showRefreshSnackbar = !_.isEqual(parsedFormStateAfterFetch, parsedFetchPayload);
          }
        }

        const snackbarProps: SnackbarProps = showRefreshSnackbar
          ? getRefreshSnackbarProps(handleFormReset)
          : {
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            };

        addSnackbar(snackbarProps);
      }

      if (error) {
        if (!status) {
          return;
        }
        setResetCallbacks((prevState) =>
          !prevState ? [requestsSettingsResetObject] : [...prevState, requestsSettingsResetObject],
        );
        return;
      }

      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: false, blockTransition: false }));
    },
    [
      getParsedRequestsSettings,
      requestsSettings,
      isRequestPendingRef,
      fetchAbort,
      setResetCallbacks,
      setBlockTransition,
      getCurrentFormStateRef,
      mutate,
      skipFetchRef,
      handleFormReset,
      fetchRequestsSettings,
      requestsSettingsResetObject,
      updateCalendar,
      calendarInitialized,
    ],
  );

  const handleErrorCallback = useCallback(() => {
    setResetCallbacks((prevState) => {
      if (_.includes(prevState, requestsSettingsResetObject)) return prevState;
      return !prevState ? [requestsSettingsResetObject] : [...prevState, requestsSettingsResetObject];
    });
  }, [setResetCallbacks, requestsSettingsResetObject]);

  return { handleSubmitCallback, handleErrorCallback };
};
