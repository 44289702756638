import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import debounce from 'lodash/debounce';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { WorkPosition } from 'api/actions/organizationSession/organizationSessionActions.types';
import { setWorkPositionsStatesAction } from 'api/actions/workPositions/workPositionsActions';
import { WorkPositionStateObject } from 'api/actions/workPositions/workPositionsActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { ListNames } from 'components/StickyList/types';
import { ManageModal, ManageModalProps } from 'components/recipes/ManageModal';
import { LinkButton } from 'components/ui/Buttons/LinkButton';
import { Switch } from 'components/ui/Switch';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { PATH_REL, TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { filteredWorkPositionsMapSelector } from 'state/employees';
import { SEARCH_FILTER_TYPE } from 'state/filters';
import { languageSelector } from 'state/recoilState';

import { AddWorkPositionModal } from './AddWorkPosition';
import { DeleteWorkPositionsModal } from './DeleteWorkPositions';
import { EditWorkPositionModal } from './EditWorkPosition';

export const ManageWorkPositionsModal: FC = () => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const workPositions = useRecoilValue(filteredWorkPositionsMapSelector);
  const navigate = useAppNavigate();

  const [workPositionsWithChangedStatuses, setWorkPositionsWithChangedStatuses] = useState<WorkPositionStateObject[]>(
    [],
  );
  const workPositionsWithChangedStatusesRef = useRef<WorkPositionStateObject[]>([]);

  const { query } = useClient();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setWorkPositionsStates = useCallback(
    debounce(() => {
      const workPositionsStates = workPositionsWithChangedStatusesRef.current;
      if (workPositionsStates.length) {
        workPositionsWithChangedStatusesRef.current = [];
        void query(setWorkPositionsStatesAction({ workPositionsStates }));
      }
    }, 500),
    [],
  );

  useEffect(() => {
    setWorkPositionsStates();
  }, [workPositionsWithChangedStatuses, setWorkPositionsStates]);

  const handleSwitchChange = useCallback((e: ChangeEvent<HTMLInputElement>, id: string) => {
    const workPositionState = {
      state: e.target.checked,
      id,
    };
    const filteredWorkPositionsWithChangedStatuses = workPositionsWithChangedStatusesRef.current.filter(
      ({ id: currentId }) => currentId !== id,
    );
    const newWorkPositionsWithChangedStatuses = [...filteredWorkPositionsWithChangedStatuses, workPositionState];
    workPositionsWithChangedStatusesRef.current = newWorkPositionsWithChangedStatuses;

    setWorkPositionsWithChangedStatuses(newWorkPositionsWithChangedStatuses);
  }, []);

  const listPropsGenerator: ManageModalProps['listPropsGenerator'] = useCallback(
    (replace) => ({
      name: ListNames.MANAGE_WORK_POSITIONS,
      select: 'checkbox',
      showHeader: true,
      // showContentPlaceholder: true,
      list: workPositions || new Map(),
      columns: [
        {
          title: t({ id: 'team.manage_work_pos.active', message: 'Active' }),
          key: 'isActive',
          width: '60px',
          customCellRenderer: (isActive: WorkPosition['isActive'], itemId) => (
            <Flex onClick={(e) => e.stopPropagation()}>
              <Switch
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleSwitchChange(e, itemId);
                }}
                wrapperSx={{ display: 'block' }}
                size="sm"
                name="isActive"
                defaultChecked={isActive}
              />
            </Flex>
          ),
        },
        {
          title: t({ id: 'team.manage_work_pos.name', message: 'Name' }),
          key: 'name',
          sortableValue: (name: WorkPosition['name']) => `${name}`,
          customCellRenderer: (name: WorkPosition['name']) => <TextEllipsis title={name}>{name}</TextEllipsis>,
        },
        {
          key: 'id',
          width: '34px',
          customCellRenderer: (item) => (
            <Flex onClick={(e) => e.stopPropagation()}>
              <LinkButton
                replace={replace}
                to={TO_REL.DELETE_WORK_POSITIONS_MODAL[language]}
                state={{ ids: [item] }}
                variant="minimal"
                size="sm"
                shape="rounded"
              >
                <Icon size={18} type="delete" />
              </LinkButton>
            </Flex>
          ),
        },
      ],
      onRowClick: (listItemId) => {
        navigate(`${TO_REL.EDIT_WORK_POSITION_MODAL__ID[language]}/${listItemId}`, { replace });
      },
    }),
    [language, workPositions, handleSwitchChange, navigate],
  );

  const modalLinksPaths = useMemo(
    () => ({
      add: TO_REL.ADD_WORK_POSITION_MODAL[language],
      delete: TO_REL.DELETE_WORK_POSITIONS_MODAL[language],
    }),
    [language],
  );

  return (
    <>
      <ManageModal
        allResources={workPositions}
        title={t({ id: 'team.manage_work_pos', message: 'Manage positions' })}
        listPropsGenerator={listPropsGenerator}
        modalLinksPaths={modalLinksPaths}
        searchFilterType={SEARCH_FILTER_TYPE.MANAGE_WORK_POSITIONS}
      />
      <Routes>
        <Route
          path={PATH_REL.ADD_WORK_POSITION_MODAL[language]}
          element={
            <Modal replaceMode size="sm">
              <AddWorkPositionModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.EDIT_WORK_POSITION_MODAL__ID[language]}
          element={
            <Modal replaceMode size="sm" path={PATH_REL.EDIT_WORK_POSITION_MODAL__ID[language]}>
              <EditWorkPositionModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.DELETE_WORK_POSITIONS_MODAL[language]}
          element={
            <Modal replaceMode size="xs">
              <DeleteWorkPositionsModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
