import React from 'react';
import { Text, ThemeUIStyleObject } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';

type Props = {
  done: boolean;
  children: React.ReactElement | React.ReactNode;
};

export const TextMessage = ({ done, children }: Props): React.ReactElement => {
  const sx: ThemeUIStyleObject = {
    display: 'flex',
    '> *': {
      display: 'inline-flex',
    },
    ...(done && {
      fontWeight: '700',
    }),
  };

  return (
    <Text as="span" sx={sx}>
      {children}
      {done && <Icon type="approve" wrapperSx={{ ml: 2 }} />}
    </Text>
  );
};
