import { Trans } from '@lingui/macro';
import { FC } from 'react';
import { Flex, Text } from 'theme-ui';

import { Modal } from 'components/Modal/Modal';
import { useModal } from 'components/Modal/hooks/useModal';
import { AnchorButton } from 'components/ui/Buttons';

import { PZUFormType } from './PZUFormModal';

const PZU_LOGIN_PAGE_HREF = 'https://benefity.pzu.pl';

export const PZUActivatedAccountModal: FC = () => {
  const {
    state: { email },
    handleClose,
  } = useModal<null, Pick<PZUFormType, 'email'>>();
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="your_benefits.pzu_sport.success_activation_title">Account activated</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body sx={{ pb: 5 }}>
        <Flex sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 4 }}>
          <Text sx={{ textAlign: 'center' }}>
            <Trans id="your_benefits.pzu_sport.success_activation_description">
              Your account has been successfully activated. You can proceed to the login page now or later using the
              link we sent to your e-mail address <strong>{email}</strong>.
            </Trans>
          </Text>
          <AnchorButton
            variant="primary"
            href={PZU_LOGIN_PAGE_HREF}
            target="_blank"
            shape="rounded"
            onClick={handleClose}
          >
            <Trans id="your_benefits.pzu_sport.success_activation_redirect_to_login">Go to login page</Trans>
          </AnchorButton>
        </Flex>
      </Modal.Body>
    </>
  );
};
