import { useLingui } from '@lingui/react';
import React, { useCallback, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Modal } from 'components/Modal/output/Modal';
import { PATH_REL } from 'constants/routes';
import { useMount } from 'hooks/useMount/useMount';
import { ScheduleWizardStep, calendarRecentSchedulesSelector } from 'state/calendar';
import { languageSelector } from 'state/recoilState';
import { createEvent } from 'utils/createEvent';
import { ScheduleForm, ScheduleFormProps } from '../../forms/ScheduleForm';
import { RecentSchedulePickerModal } from '../../RecentSchedulePickerModal';
import { useScheduleWizard } from '../hooks/useScheduleWizard';

export const ScheduleWizardModalStep2 = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const recentSchedules = useRecoilValue(calendarRecentSchedulesSelector);

  const formRef = useRef<HTMLFormElement | null>(null);

  useLingui();
  const { scheduleTemplate, schedule, setSchedule, setScheduleTemplate, setStep, setOnClickCallback } =
    useScheduleWizard();

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  useMount(() => {
    if (setOnClickCallback) {
      setOnClickCallback(() => submitForm);
    }
  });

  const onSubmit: ScheduleFormProps['onSubmit'] = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (body) => {
      setSchedule(body);
      setStep(ScheduleWizardStep.SetDates);
      return true;
    },
    [setSchedule, setStep],
  );

  return (
    <>
      <ScheduleForm
        key={JSON.stringify(scheduleTemplate)} // ugly as fck, think of better solution
        ref={formRef}
        defaultValues={scheduleTemplate || schedule || undefined}
        onSubmit={onSubmit}
      />

      {recentSchedules && (
        <Routes>
          <Route
            path={PATH_REL.RECENT_SCHEDULE_PICKER[language]}
            element={
              <Modal size="xs" fullHeight>
                <RecentSchedulePickerModal
                  onScheduleClick={(scheduleIndex) => {
                    setScheduleTemplate(recentSchedules[scheduleIndex]);
                  }}
                  recentSchedules={recentSchedules}
                />
              </Modal>
            }
          />
        </Routes>
      )}
    </>
  );
};
