import _ from 'lodash';
import React from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';
import weakKey from 'weak-key';

import { Cell } from './Cell';
import { GUTTER_DEFAULT_SIZE, ITEM_DEFAULT_WIDTH } from './constants';
import { ListColumn, ListItem, RowProps, StickyListProps } from './types';

type Props = Pick<StickyListProps, 'columns'> &
  Pick<RowProps, 'variant'> & {
    stickyContainerWidth?: number;
    hasFrozenColumns?: boolean;
    listHeight?: number;
    list?: StickyListProps['list'];
    sx?: ThemeUIStyleObject;
    gridWidth?: number;
  };

const SummaryRow = ({ columns, gridWidth, list, sx: wrapperSx }: Props) => {
  const defaultGridWidth = gridWidth || ITEM_DEFAULT_WIDTH;

  const summaryRowCellRenderer = (listColumn: ListColumn) => {
    const { columnGrow, summary, sx, width } = listColumn;
    const uniqueKey = weakKey(listColumn);

    const summaryRender = (() => {
      if (!list || !summary) return null;

      const { key, renderer } = summary;
      const listValues = _.isArray(list) ? list : [...list.values()];

      if (key) {
        const data = _.transform(
          listValues,
          (result, listValue) => {
            const listKeyValue: ListItem | ListItem[] = _.isArray(key)
              ? // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                _.map(key, (k) => listValue[k])
              : listValue[key];

            result.push(listKeyValue);
          },
          [] as (ListItem | ListItem[])[],
        );

        return renderer(data);
      }

      return renderer([]);
    })();

    return (
      <Cell
        key={`${uniqueKey}-summary`}
        sx={{
          ...sx,
        }}
        width={width || defaultGridWidth}
        columnGrow={columnGrow}
      >
        <Flex
          sx={{
            flex: '1 0',
            px: `${GUTTER_DEFAULT_SIZE / 2}px`,
            alignSelf: 'stretch',
          }}
        >
          <Flex sx={{ flex: '1 0', bg: 'list.rowBackground.default' }}>{summaryRender}</Flex>
        </Flex>
      </Cell>
    );
  };

  return (
    <Flex
      sx={{
        bg: 'stickyList.bg.header.default.grid',
        fontSize: 2,
        textTransform: 'initial',
        position: 'sticky',
        bottom: 0,
        left: 0,
        flexDirection: 'row',
        willChange: 'transform',
        color: 'stickyList.text.grid.default',
        ...(wrapperSx && wrapperSx),
      }}
    >
      {columns.map(summaryRowCellRenderer)}
    </Flex>
  );
};

export const MemorizedSummaryRow = React.memo(SummaryRow);
