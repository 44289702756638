import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback, useMemo, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { invoiceFileDownloadAction } from 'api/actions/invoice/invoiceActions';
import { AppErrorResponse, AppErrorVariant } from 'api/actions/organizationSession/organizationSessionActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { AlertProps } from 'components/ui/Alert/Alert';
import { PATH, TO } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { appErrorSelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';
import { dateTime } from 'utils/dateTime';

export const useAlertProvider = () => {
  useLingui();
  const appError = useRecoilValue(appErrorSelector);
  const navigate = useAppNavigate();
  const language = useRecoilValue(languageSelector);
  const { pathname } = useLocation();
  const { query } = useClient();
  const {
    systemManagement: { Payments: paymentsPermission },
  } = useAppPermissions();
  const [isLoading, setIsLoading] = useState(false);

  const isPaymentOpen = useMemo(() => {
    if (pathname.includes(PATH.PAYMENTS[language])) return true;

    return false;
  }, [language, pathname]);

  const getVariantAlertProps = useCallback(
    (priorityError: AppErrorResponse): AlertProps | null => {
      const { variant } = priorityError;

      if (variant === AppErrorVariant.ConfirmPayment && paymentsPermission) {
        return {
          variant: 'warning',
          ...(!isPaymentOpen && {
            action: {
              title: t({ id: 'global.alert.subscription_expiration_button', message: 'Verify your card' }),
              onClick: () => {
                navigate(TO.PAYMENTS[language]);
              },
            },
          }),
          children: (
            <Text>
              <Trans id="global.alert.subscription_expiration">
                Your bank requires a new 3D Secure verification to proceed with the payment.
              </Trans>{' '}
              {!isPaymentOpen && (
                <Trans id="global.alert.subscription_expiration_verify_card">Click here to verify your card.</Trans>
              )}
            </Text>
          ),
        };
      }

      if (variant === AppErrorVariant.ExpiredPaymentWarning && !isPaymentOpen && paymentsPermission) {
        const { endDate, expirationDate, proformaId } = priorityError;

        const localizedEndDate = dateTime(endDate).format('ll');
        const localizedExpirationDate = dateTime(expirationDate).format('ll');
        const isPastPaymentDate = dateTime().startOf('day').unix() > endDate;

        if (!isPastPaymentDate) return null;

        const getProforma = async () => {
          setIsLoading(true);
          const { error } = await query(invoiceFileDownloadAction({ id: proformaId, isProforma: true }));
          setIsLoading(false);

          if (error) {
            addSnackbar({
              message: t({ id: 'payment.alert.proforma_error', message: "Couldn't download last pro-forma" }),
            });
          }
        };

        return {
          variant: 'danger',
          action: {
            title: t({ id: 'payments.download_last_proforma', message: 'Download last pro-forma' }),
            onClick: () => void getProforma(),
            isLoading,
          },
          children: (
            <Text as="p">
              <Trans id="global_alert.download_last_proforma.message_warning">
                <Text as="span" sx={{ textDecoration: 'underline' }}>
                  Your payment is past due time - {localizedEndDate}!
                </Text>{' '}
                If it will not be processed before - {localizedExpirationDate} we will automatically cancel your
                subscription.
              </Trans>
            </Text>
          ),
        };
      }

      return null;
    },
    [navigate, isLoading, isPaymentOpen, language, paymentsPermission, query],
  );

  const alertProps = useMemo(() => {
    if (!appError) return null;

    return getVariantAlertProps(appError);
  }, [appError, getVariantAlertProps]);

  return {
    alertProps,
    isPaymentOpen,
  };
};
