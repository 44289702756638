import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { PublishedAtomsKeys, recoilState } from 'state/recoilState';
import {
  SetRecoilStateEventAction,
  SetRecoilStateEventActionPayload,
} from 'broadcastChannel/channelReducer/channelReducer.types';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { CustomEvents } from 'utils/customEvents';

export const SetRecoilStateEventObserver: React.ElementType = () => {
  const [atomKey, setAtomKey] = useState<PublishedAtomsKeys | null>(null);
  const [newState, setNewState] = useState<SetRecoilStateEventActionPayload | null>(null);
  const setRecoilState = useSetRecoilState(recoilState(atomKey));

  useCustomEventListener(CustomEvents.SET_RECOIL_STATE, (action) => {
    const { recoilKey, payload } = action as SetRecoilStateEventAction;
    setAtomKey(recoilKey);
    setNewState(payload);
  });

  useEffect(() => {
    if (newState) {
      setRecoilState(newState);
    }
  }, [setRecoilState, newState]);

  return null;
};
