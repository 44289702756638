import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';

import { HeadingTip } from '../HeadingTip';
import { SettingsLoadingSpinnerCss } from '../SettingsLoadingSpinnerCss';

import { SchedulesForm } from './components/SchedulesForm/SchedulesForm';
import { useSchedules } from './components/SchedulesForm/hooks/useSchedules';
import { useSchedulesCleanUp } from './hooks/useSchedulesCleanUp';

export const Schedules = (): React.ReactElement => {
  useLingui();
  const { schedulesSettings, fetchSchedules } = useSchedules();

  useEffect(() => {
    if (!schedulesSettings) {
      void fetchSchedules();
    }
  }, [fetchSchedules, schedulesSettings]);

  useSchedulesCleanUp();

  return (
    <>
      <HeadingTip
        label={t({
          id: 'schedules_settings.heading.schedules',
          message: 'Schedules',
        })}
      />
      {schedulesSettings ? <SchedulesForm /> : <SettingsLoadingSpinnerCss />}
    </>
  );
};
