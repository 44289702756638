import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';

import { Tag } from 'components/Tag/Tag';
import { connectedTagSx, expiredTagSx } from '../styles/styles';
import { IntegrationTileElementsProps } from '../types';

type Props = Pick<IntegrationTileElementsProps, 'isActive' | 'isExpired'>;

export const Tags = ({ isActive, isExpired = false }: Props): React.ReactElement => {
  useLingui();

  return (
    <>
      {isActive && !isExpired && (
        <Tag variant="solid" color="settings.connected.green" sx={connectedTagSx}>
          {t({ id: 'enabled', message: 'Enabled' })}
        </Tag>
      )}
      {isExpired && (
        <Tag variant="solid" color="settings.expired.tag" sx={expiredTagSx}>
          {t({ id: 'connection_expired', message: 'Connection Expired' })}
        </Tag>
      )}
    </>
  );
};
