import React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { CheckboxLogicWrapper, CheckboxLogicWrapperProps } from 'components/utils/CheckboxLogicWrapper';

type Props = Omit<CheckboxLogicWrapperProps, 'children'> & {
  sx?: ThemeUIStyleObject;
};

export type CheckboxProps = Props;

const defaultProps: Partial<Props> = {
  sx: undefined,
};

export const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  ({ size = 'default', sx, placement = 'left', ...props }: Props, ref) => (
    <CheckboxLogicWrapper
      {...props}
      ref={ref}
      size={size}
      placement={placement}
      wrapperSx={{
        '&:hover': {
          '> .checkbox': {
            bg: 'checkbox.background.hover',
            color: 'checkbox.checkMark.hover',
          },
        },
        ...sx,
      }}
    >
      <Box as="span" className="checkbox" variant={`forms.checkbox.${size}`}>
        <Icon type="selected" />
      </Box>
    </CheckboxLogicWrapper>
  ),
);

Checkbox.defaultProps = defaultProps;
