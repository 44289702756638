import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Modal } from 'components/Modal/output/Modal';
import { PATH_REL } from 'constants/routes';
import { languageSelector } from 'state/recoilState';

import { RequestDetailsModal } from './RequestDetails/RequestDetailsModal';

export const RequestDetailsNestedModalRoute = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <>
      <RequestDetailsModal />
      <Routes>
        <Route
          path={PATH_REL.REQUEST_DETAILS__GROUP__ID[language]}
          element={
            <Modal size="sm" fullHeight path={PATH_REL.REQUEST_DETAILS__GROUP__ID[language]}>
              <RequestDetailsModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
