import _, { orderBy } from 'lodash';
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Flex, ThemeUICSSObject } from 'theme-ui';

import { withPopper } from 'components/ui/PopperProvider/withPopper';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { ParsedEmployee } from 'state/employees';

import { Tag } from './Tag';

const getMonospaceStringWidth = (text: string) => text.length * 6.5 + 12 + 2 + 6 + 2;

const FlexWithPopper = withPopper(Flex);

type Props = {
  tags: ParsedEmployee['tags'];
  popperWidth?: number;
  sx?: ThemeUICSSObject;
};

export const TagStack: FC<Props> = ({ tags, popperWidth, sx }) => {
  const containerRef = useRef<HTMLElement | null>(null);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [containerBounding, setContainerBounding] = useState<DOMRect | null>(null);
  const containerBoundingRef = useCallbackRef(containerBounding);
  const [isHovering, setIsHovering] = useState(false);
  const isConstantlyHovering = useRef(false);
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const { orderedTags, tagsWidth } = useMemo(
    () => ({
      orderedTags: orderBy(
        tags,
        [(tagDetails) => !!tagDetails?.isInformational, (tagDetails) => tagDetails?.name],
        ['asc', 'asc'],
      ),
      tagsWidth: tags.reduce((acc, item) => acc + getMonospaceStringWidth(item?.name || ''), 0),
    }),
    [tags],
  );

  const popperContainerWidth = useMemo(() => {
    if (popperWidth && tagsWidth > popperWidth) return `${popperWidth}px`;
    const minWidth = 500;
    if ((containerBounding?.width || 0) < minWidth) return tagsWidth > minWidth ? `${minWidth}px` : 'fit-content';
    return `${(containerBounding?.width || 0) + 7}px`;
  }, [containerBounding?.width, popperWidth, tagsWidth]);

  const handleMouseOver = useCallback(() => {
    if (tagsWidth < (containerBounding?.width || 0) || isSmartphoneBreakpoint) return;
    isConstantlyHovering.current = true;
    hoverTimeoutRef.current = setTimeout(() => {
      if (isConstantlyHovering.current) {
        setIsHovering(true);
      }
    }, 300);
  }, [containerBounding?.width, isSmartphoneBreakpoint, tagsWidth]);

  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  const renderedHoverTags = useMemo(
    () => (
      <Flex
        sx={{
          borderRadius: 'xs',
          border: '1px solid',
          borderColor: 'alphas.darker4',
          boxShadow: 'dropShadow.levelOne',
          bg: 'whites0',
          flexWrap: 'wrap',
          gap: 1,
          overflowX: 'auto',
          fontWeight: 'normal',
          p: '3px',
        }}
      >
        {orderedTags.map(
          (tag) =>
            tag && (
              <Tag
                key={tag.name}
                variant={tag.isInformational ? 'informational' : 'default'}
                color={UserSelectableColor[tag.color]}
                sx={{ flexShrink: 0 }}
              >
                {tag.name}
              </Tag>
            ),
        )}
      </Flex>
    ),
    [orderedTags],
  );

  useLayoutEffect(() => {
    if (containerRef.current) {
      setContainerBounding(containerRef.current.getBoundingClientRect());
    }
  }, []);

  const debouncedHandleResize = useMemo(
    () =>
      _.debounce((boundingClientRect: DOMRectReadOnly) => {
        const isBothWiderThanTags =
          boundingClientRect.width > tagsWidth && (containerBoundingRef.current?.width || 0) > tagsWidth;
        const isBothNarrowerThanTags =
          boundingClientRect.width < tagsWidth && (containerBoundingRef.current?.width || 0) < tagsWidth;

        if (!isBothWiderThanTags || !isBothNarrowerThanTags) {
          setContainerBounding(boundingClientRect);
        }
      }, 1000),
    [containerBoundingRef, tagsWidth],
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        debouncedHandleResize(entry.contentRect);
      });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [debouncedHandleResize]);

  return (
    <Flex ref={containerRef} sx={{ flexGrow: 1 }}>
      <FlexWithPopper
        onMouseOver={handleMouseOver}
        onMouseLeave={() => {
          if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current);
          }
          isConstantlyHovering.current = false;
        }}
        sx={{
          flexGrow: 1,
          fontWeight: 'normal',
          ...(tagsWidth < (containerBounding?.width || 0) && { gap: '0.125rem' }),
          ...(sx && sx),
        }}
        popperProps={{
          content: renderedHoverTags,
          trigger: 'manual',
          visible: isHovering,
          placement: 'top-start',
          popperMargin: 0,
          withPortal: true,
          customModifiers: [
            {
              name: 'offset',
              options: {
                offset: ({ popper, placement }) => [placement === 'top-end' ? 4 : -4, -popper.height + 4],
              },
            },
          ],
          popperContainerProps: {
            onMouseLeave: handleMouseOut,
          },
          popperContainerSx: {
            width: popperContainerWidth,
          },
        }}
      >
        {orderedTags.map((tag, index) => {
          const isLast = index === tags.length - 1;

          return (
            tag && (
              <Tag
                key={tag.name}
                variant={tag.isInformational ? 'informational' : 'default'}
                color={UserSelectableColor[tag.color]}
                overlapping={!isLast && tagsWidth > (containerBounding?.width || 0)}
                sx={{ zIndex: index }}
              >
                {tag.name}
              </Tag>
            )
          );
        })}
      </FlexWithPopper>
    </Flex>
  );
};
