/* eslint-disable no-bitwise */

/**
 * !!DON'T USE!! use 'uuid' package to generate guids and _.uniqueId() for simple ids.
 * Creates a unique id.
 *
 * @return {string} Returns the created id.
 */

export const UUID = (): string => {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now();
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};
