import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { LoadingSplash } from 'components/Loading/LoadingSplash';
import { APP_NAME } from 'constants/common';
import { QueryParams } from 'constants/queryParams';
import { TO } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { languageSelector } from 'state/recoilState';

import { KioskSettingsInitializer } from './components/KioskSettingsInitializer';
import { GeoLocationProvider } from './context/GeoLocationProvider';
import { IdleObserver } from './observers/IdleObserver';
import { PermissionObserver } from './observers/PermissionObserver';
import { KioskSettingsProps, settingsAtom } from './state/settingsState';

type OutletContext = {
  settings: KioskSettingsProps;
};

export const useKioskOutletContext = () => useOutletContext<OutletContext>();

export const Kiosk = (): React.ReactElement => {
  const settings = useRecoilValue(settingsAtom);
  const language = useRecoilValue(languageSelector);

  const { addSnackbar } = useSnackbar();
  const navigate = useAppNavigate();

  const urlParams = useQuery();
  const alert = urlParams.get(QueryParams.Alert);

  useEffect(() => {
    if (alert === 'alert_kiosk_session_refresh') {
      addSnackbar({
        message: i18n._(
          t({
            id: 'kiosk.camera_view.kiosk_refresh',
            message: "Your organization's settings have changed, we needed to refresh your session.",
          }),
        ),
        variant: 'warning',
        duration: 8000,
      });

      navigate(TO.KIOSK[language], { replace: true });
    }
  }, [addSnackbar, alert, language, urlParams, navigate]);

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'kiosk.page_title', message: `Kiosk - ${APP_NAME}` }))}</title>
      </Helmet>

      <KioskSettingsInitializer>
        <GeoLocationProvider>
          {!settings ? (
            <LoadingSplash message={t({ id: 'global.loading' })} />
          ) : (
            <>
              <Outlet context={{ settings } satisfies OutletContext} />
              <PermissionObserver requireLocation={settings.isLocationRequired} />
              <IdleObserver load={settings.isSleepModeEnabled} timerInMinutes={settings.sleepModeTimeOutMinutes} />
            </>
          )}
        </GeoLocationProvider>
      </KioskSettingsInitializer>
    </>
  );
};
