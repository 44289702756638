import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useClient } from 'react-fetching-library';

import { dateRangeFilterAtom } from 'state/filters';
import { ParsedEmployee } from 'state/employees';
import { fetchReportForIdsAction } from 'api/actions/reports/reportsActions';
import { reportResponseAtom } from 'state/reports';

export const useRefreshReport = (ids?: ParsedEmployee['id'][]) => {
  const dates = useRecoilValue(dateRangeFilterAtom);
  const [report, setReport] = useRecoilState(reportResponseAtom);

  const fetchParams = useMemo(
    () =>
      dates && ids && ids.length > 0
        ? {
            employeeIds: ids,
            startDateUnix: dates.startDateUnix,
            endDateUnix: dates.endDateUnix,
          }
        : null,
    [dates, ids],
  );

  const { query } = useClient();

  const updateReportForIds = useCallback(
    async (employeeIds?: string[]) => {
      const getInsideParams = () => {
        if (!employeeIds) return fetchParams;

        if (employeeIds && dates) {
          return {
            employeeIds: [...(ids || []), ...employeeIds],
            startDateUnix: dates.startDateUnix,
            endDateUnix: dates.endDateUnix,
          };
        }

        return null;
      };

      const { payload, error } = await query(fetchReportForIdsAction(getInsideParams()));

      if (!error && payload) {
        setReport((prevState) => {
          if (prevState) {
            const { employeesData } = prevState;

            const employeesDataMap = new Map(employeesData);
            const payloadEmployeesDataMap = new Map(payload.employeesData);

            payloadEmployeesDataMap.forEach((val, key) => {
              employeesDataMap.set(key, val);
            });

            return {
              ...prevState,
              employeesData: [...employeesDataMap],
            };
          }

          return null;
        });
      }
    },
    [dates, fetchParams, ids, query, setReport],
  );

  return {
    updateReportForIds,
    reportInitialized: !!report,
  };
};
