import { ThemeUICSSObject } from 'theme-ui';

import { makeTheme } from '../makeTheme';

const paymentContainer: ThemeUICSSObject = {
  bg: 'payments.bg.paymentContainer',
  px: 4,
  py: 5,
  borderRadius: 'default',
  flexDirection: 'column',
  gap: 4,
  border: '2px solid',
  borderColor: 'payments.border.default',
  '&[data-shadow="true"]': {
    boxShadow: 'payments',
  },
  '&[data-sticky="true"]': {
    position: 'sticky',
    top: 4,
    mt: -4,
  },
  '&[data-fancy="success"]': {
    borderColor: 'payments.border.success',
  },
  '&[data-fancy="error"]': {
    borderColor: 'payments.border.error',
  },
  '&[data-fancy="warning"]': {
    borderColor: 'payments.border.warning',
  },
};

export const paymentStyles = makeTheme({
  payments: {
    paymentContainer: {
      ...paymentContainer,
    },
    moduleSwitch: {
      borderRadius: 'sm',
      p: 3,
      bg: 'payments.moduleSwitch.bg',
      border: '1px solid',
      borderColor: 'payments.moduleSwitch.border',
      ':hover': {
        borderColor: 'payments.moduleSwitch.hover.border',
        bg: 'payments.moduleSwitch.hover.bg',
      },
    },
    annualSwitch: {
      background: 'payments.bg.gradients.success',
      borderRadius: 'sm',
      p: 3,
      border: '1px solid',
      borderColor: 'payments.annualSwitch.border',
      ':hover': {
        borderColor: 'payments.annualSwitch.borderHover',
        background: 'payments.bg.gradients.successHover',
      },
    },
    discountContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 'sm',
      gap: 3,
      p: 3,
      background: 'payments.bg.gradients.success',
      border: '1px solid',
      borderColor: 'payments.discountContainer',
    },
    infoContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 'sm',
      gap: 3,
      p: 3,
      background: 'payments.bg.info',
      border: '1px solid',
      borderColor: 'payments.infoContainer',
    },
    paymentMethod: {
      default: {
        bg: 'payments.paymentMethod',
        px: 3,
        py: 4,
        borderRadius: 'sm',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      naked: {
        flex: '1 0',
        px: 1,
        py: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'right',
      },
    },
  },
});
