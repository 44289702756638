import { Flex, FlexProps, Text, ThemeUICSSObject } from 'theme-ui';

import { dateTime } from 'utils/dateTime';

type Props = FlexProps & {
  dateUnix: number;
  isToday: boolean;
  isActive: boolean;
};

const styles: ThemeUICSSObject = {
  position: 'relative',
  flex: '1 0',
  flexDirection: 'column',
  gap: 1,
  py: 2,
  borderRadius: 'xs',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: [2, null, null, 1, 2],
  lineHeight: 1,
  userSelect: 'none',
  cursor: 'pointer',
  bg: 'home.dateBox.bg.default',
  outline: '1px solid',
  outlineColor: 'home.dateBox.outline.default',
  '&:hover': {
    bg: 'home.dateBox.bg.hover',
    outlineColor: 'home.dateBox.outline.hover',
    boxShadow: 'dateBox',
  },
  '> .date': {},
  '> .weekday': {
    textTransform: 'uppercase',
    fontWeight: '700',
  },
  '&[data-today="true"]': {
    '&::after': {
      content: '""',
      display: 'block',
      height: '2px',
      position: 'absolute',
      bottom: 1,
      right: 3,
      left: 3,
      bg: 'home.dateBox.underline',
    },
  },
  '&[data-weekend="true"]': {
    color: 'texts.lighter',
  },
  '&[data-active="true"]': {
    fontWeight: '700',
    bg: 'home.dateBox.bg.active',
    outlineColor: 'home.dateBox.outline.active',
    '&:hover': {
      bg: 'home.dateBox.bg.activeHover',
    },
  },
};

export const DateBox = ({ children, dateUnix, isToday, isActive, sx, ...props }: Props) => {
  const date = dateTime(dateUnix, { utc: true }).startOf('day');
  const year = date.format('YYYY');
  const weekday = date.format('dd');
  const weekdayNumber = date.format('d');
  const localDate = date.format('ll').split(` ${year}`)[0].split(',')[0];

  const isWeekend = weekdayNumber === '6' || weekdayNumber === '0';

  return (
    <Flex {...props} sx={{ ...styles, ...sx }} data-active={isActive} data-today={isToday} data-weekend={isWeekend}>
      <Text className="weekday">{weekday}</Text>
      <Text className="date">{localDate}</Text>
    </Flex>
  );
};
