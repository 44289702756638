import React from 'react';
import { Flex, Text } from 'theme-ui';

import { Icon, IconProps } from 'components/Icon/Icon';

type Props = {
  title: string | React.ReactElement;
  content: string | React.ReactElement;
  iconType: IconProps['type'];
};

export const AsideCard = ({ content, iconType, title }: Props): React.ReactElement => (
  <Flex
    sx={{
      gap: 4,
      bg: 'signUp.bg.default',
      backdropFilter: 'blur(8px)',
      borderRadius: 'default',
      p: 3,
      transition: 'background 0.15s ease-in-out',
      '&:hover': {
        bg: 'signUp.bg.hover',
      },
    }}
  >
    <Icon type={iconType} size={32} />
    <Flex sx={{ flexDirection: 'column' }}>
      <Text as="h3" variant="pLead" sx={{ fontSize: '1.125rem', mb: '0 !important' }}>
        {title}
      </Text>
      <Text as="p" variant="p">
        {content}
      </Text>
    </Flex>
  </Flex>
);
