import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTimer } from 'use-timer';

import { Notification } from 'components/Notification/Notification';
import { PATH } from 'constants/routes';
import { addNotificationCenterSelector, removeNotificationFromCenterSelector } from 'state/notification';
import { languageSelector } from 'state/recoilState';

import { notificationPublisher } from './publisher';
import { NotificationForHubProps, NotificationWithMotionProps, RemoveNotification } from './types';

let screenNotificationsRoot = document.getElementById('notification-root');

if (!screenNotificationsRoot) {
  screenNotificationsRoot = document.createElement('div');
  screenNotificationsRoot.setAttribute('id', 'notification-root');
}

const NotificationWithMotion = motion(Notification);

const MemorizedNotificationWithMotion = React.memo((props: NotificationWithMotionProps) => (
  <NotificationWithMotion
    sx={{ opacity: 0 }}
    initial={{ opacity: 0, scale: 0.7, x: 800 }}
    animate={{ opacity: 1, scale: 1, x: 0 }}
    exit={{ opacity: 0, scale: 0, x: 600, transition: { duration: 0.1 } }}
    {...props}
  />
));

const NotificationForHub = ({
  duration,
  isStatic,
  onRemove,
  ...props
}: NotificationForHubProps & { onRemove: () => void }) => {
  const { start, pause } = useTimer({
    endTime: duration && duration / 1000,
    autostart: !isStatic && true,
    interval: 1000,
    onTimeOver: () => {
      onRemove();
    },
  });

  const onMouseEnter = () => {
    if (!isStatic && duration) {
      pause();
    }
  };

  const onMouseLeave = () => {
    if (!isStatic && duration) {
      start();
    }
  };

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <MemorizedNotificationWithMotion {...props} />
    </div>
  );
};

export const NotificationHub = (): React.ReactElement | null => {
  const [notificationsForHub, setNotificationsForHub] = useState<NotificationForHubProps[]>([]);
  const addNotificationForCenter = useSetRecoilState(addNotificationCenterSelector);
  const removeNotificationFromCenter = useSetRecoilState(removeNotificationFromCenterSelector);
  const { pathname } = useLocation();
  const language = useRecoilValue(languageSelector);

  const addNotification = (notification: NotificationForHubProps): string => {
    setNotificationsForHub(
      notification.insert === 'top' ? [notification, ...notificationsForHub] : [...notificationsForHub, notification],
    );
    addNotificationForCenter(notification);

    return notification.id ? notification.id : '';
  };
  const removeNotification: RemoveNotification = (id, { removeFromHub, removeFromCenter }) => {
    if (!id) return;
    if (removeFromHub) {
      setNotificationsForHub((prevState) => prevState.filter((n) => n.id !== id));
    }
    if (removeFromCenter) {
      removeNotificationFromCenter(id);
    }
  };
  const removeAllNotifications = () => {
    setNotificationsForHub([]);
  };
  const renderNotifications = (notificationArray: NotificationForHubProps[]) =>
    notificationArray.map(({ id, ...props }) => (
      <NotificationForHub
        sx={{ mb: 2 }}
        key={id}
        id={id}
        {...props}
        onRemove={() => removeNotification(id, { removeFromHub: true })}
      />
    ));

  const renderAllScreenNotifications = () => {
    if (!pathname.includes(PATH.KIOSK[language])) {
      return (
        <>
          <AnimatePresence>{renderNotifications(notificationsForHub)}</AnimatePresence>
        </>
      );
    }

    return null;
  };

  useEffect(() => () => removeAllNotifications(), []);

  notificationPublisher.register({
    addNotification,
    removeNotification,
    removeAllNotifications,
  });

  return screenNotificationsRoot && createPortal(renderAllScreenNotifications(), screenNotificationsRoot);
};
