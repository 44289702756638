import { Action, ActionConfig } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  AddTimeEventNoteActionProps,
  AddTimeEventNoteResponse,
  AddTimeEventsActionProps,
  AddTimeEventsResponse,
  DeleteTimeEventsActionProps,
  DeleteTimeEventsResponse,
  EditClockLogListViewSettingAction,
  EditClockLogViewSettingResponse,
  EditTimeEventActionProps,
  EditTimeEventResponse,
  FetchEventWithDetailsActionProps,
  FetchEventWithDetailsResponse,
  FetchTimeEventHistoryActionProps,
  FetchTimeEventHistoryResponse,
  SetFraudResolutionStateActionProps,
  SetFraudResolutionStateResponse,
  GetTimeEventResponse,
  RemoveTimeEventsActionProps,
  RemoveTimeEventsResponse,
  FetchEventsPhotosActionProps,
  FetchEventsPhotosResponse,
  FetchClockLogActionProps,
  FetchClockLogResponse,
  RestoreTimeEventsActionProps,
  RestoreTimeEventsResponse,
} from './timeEventActions.types';

export const fetchClockLogAction = (body: FetchClockLogActionProps | null): Action<FetchClockLogResponse> => ({
  method: 'POST',
  endpoint: API_ENDPOINTS.getTimeEventsCalendar,
  body,
});

export const deleteTimeEventsAction = (
  body: DeleteTimeEventsActionProps,
  config?: ActionConfig,
): Action<DeleteTimeEventsResponse> => ({
  method: 'DELETE',
  endpoint: API_ENDPOINTS.deleteTimeEvents,
  body,
  config,
});

export const removeTimeEventsAction = (body: RemoveTimeEventsActionProps): Action<RemoveTimeEventsResponse> => ({
  method: 'DELETE',
  endpoint: API_ENDPOINTS.removeTimeEvents,
  body,
});

export const restoreTimeEventsAction = (body: RestoreTimeEventsActionProps): Action<RestoreTimeEventsResponse> => ({
  method: 'POST',
  endpoint: API_ENDPOINTS.restoreTimeEvents,
  body,
});

export const fetchTimeEventHistoryAction = (
  id: FetchTimeEventHistoryActionProps,
): Action<FetchTimeEventHistoryResponse> => ({
  method: 'GET',
  endpoint: `${API_ENDPOINTS.timeEventHistory}/${id}`,
});

export const editTimeEventAction = (
  body: EditTimeEventActionProps,
  config?: ActionConfig,
): Action<EditTimeEventResponse> => ({
  method: 'POST',
  endpoint: API_ENDPOINTS.editTimeEvent,
  body,
  config,
});

export const addTimeEventNoteAction = (body: AddTimeEventNoteActionProps): Action<AddTimeEventNoteResponse> => ({
  method: 'POST',
  endpoint: API_ENDPOINTS.addTimeEventNote,
  body,
});

export const editClockLogListViewSettingAction = (
  viewSettings: EditClockLogListViewSettingAction,
): Action<EditClockLogViewSettingResponse> => ({
  method: 'POST',
  endpoint: API_ENDPOINTS.viewsSettings,
  body: {
    clockLog: viewSettings,
  },
});

export const addTimeEventsAction = (body: AddTimeEventsActionProps): Action<AddTimeEventsResponse> => ({
  method: 'POST',
  endpoint: API_ENDPOINTS.addTimeEvents,
  body,
});

export function getFraudResolutionPendingDataAction(): Action<GetTimeEventResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.getFraudResolutionPendingData,
  };
}

export const fetchEventWithDetailsAction = (
  id: FetchEventWithDetailsActionProps,
): Action<FetchEventWithDetailsResponse> => ({
  method: 'GET',
  endpoint: `${API_ENDPOINTS.getTimeEventDetails}/${id}`,
});

export const setFraudResolutionStateAction = (
  body: SetFraudResolutionStateActionProps,
): Action<SetFraudResolutionStateResponse> => ({
  method: 'POST',
  endpoint: `${API_ENDPOINTS.setFraudResolutionState}/${body.id}`,
  body,
});

export const fetchEventsPhotosAction = (body: FetchEventsPhotosActionProps): Action<FetchEventsPhotosResponse> => ({
  method: 'POST',
  endpoint: API_ENDPOINTS.getPhotos,
  body,
});
