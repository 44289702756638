/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  FixedSizeGridProps,
  FixedSizeListProps,
  GridChildComponentProps,
  ListChildComponentProps,
  ListOnItemsRenderedProps,
} from 'react-window';
import { ThemeUIStyleObject } from 'theme-ui';

import { SortingState } from 'state/list';
import { KeyType } from 'utils/custom.types';

export type ListItem = {
  id: string;
  isNonEditable?: boolean;
  [key: string | number]: any;
};
type Renderer<T = any> = (
  item: T,
  itemId: string,
  rowRef?: React.MutableRefObject<HTMLDivElement | null>,
  isSortedBy?: boolean,
  isRowHovered?: boolean,
) => React.ReactNode;

type SortableValue<Value = any> = (item: Value | Value[]) => string | number;
type FallbackSortableValue<Value = any> = (item: Value) => string | number;
type OnRowClick = (id: ListItem['id'], data: any) => void;
export type IsRowUncheckableValidator = (id: ListItem['id']) => boolean;

export enum ListVariant {
  default = 'default',
  inverted = 'inverted',
  photosGrid = 'photosGrid',
  darkerOne = 'darkerOne',
}

export enum CustomRowVariant {
  error = 'error',
  pending = 'pending',
  warning = 'warning',
}

export type CustomRowVariantGenerator = (id: ListItem['id']) => ListVariant | CustomRowVariant | undefined;
export type RowTooltipGenerator = (id: ListItem['id']) => string | undefined;

export type StickyListProps<
  // ListObj extends Record<string | number, any> = any,
  ListObj extends ListItem = ListItem,
  ColumnId = string | number,
  // K = MergedProperties<ListObj, { [key: string | number]: any }>,
  // Q = UnionToType<ListItem | ListObj>,
  // Q extends ListObj, ListItem  = any,
> = {
  name: ListNames;
  columns: ListColumn<ListObj, ColumnId>[];
  // list: (ListItem[] & T[]) | (Map<string, ListItem> & Map<string, T>);
  // list: (ListItem & T)[] | Map<string, ListItem & T>;
  // list: (ListItem & ListObj)[] | Map<string, ListItem & ListObj>;
  // list: UnionToType<ListItem | ListObj>[] | Map<string, UnionToType<ListItem | ListObj>>;
  list: ListObj[] | Map<string, ListObj>;
  showContentPlaceholder?: boolean;
  select?: 'checkbox' | 'radio';
  emptyListMessage?: React.ReactNode;
  headerSx?: ThemeUIStyleObject;
  variant?: ListVariant;
  customRowVariantGenerator?: CustomRowVariantGenerator;
  rowTooltipGenerator?: RowTooltipGenerator;
  showHeader?: boolean;
  disableNativeKeyboardScrolling?: boolean;
  onRowClick?: OnRowClick;
  isRowUncheckableValidator?: IsRowUncheckableValidator;
  // fallbackSortableValueGetter?: SortableValue<ValueType<ListObj>>;
  fallbackSortableValueGetter?: FallbackSortableValue<ListObj>;
  onItemsRendered?: (props: ListOnItemsRenderedProps) => any;
  rowHeightGenerator?: (list: Map<string, ListItem>) => number[];
  mobileWidth?: number;
  defaultSortingState?: Partial<SortingState>;
  withRowContextMenu?: boolean;
  dimensions?: {
    height?: number;
    width?: number;
  };
} & (
  | {
      type?: 'list';
      style?: FixedSizeListProps['style'];
      frozenColumns?: never; // column index
      onRowClick?: OnRowClick;
      rerenderOnColumnCountChange?: never;
      dimensions?: {
        height?: number;
        width?: never;
      };
      withSummary?: never;
      appendHeaderWith?: never;
    }
  | {
      type?: 'grid';
      frozenColumns?: number; // no of columns to froze
      style?: FixedSizeGridProps['style'];
      onRowClick?: never;
      rerenderOnColumnCountChange?: boolean; // force rerender to avoid layout shifts when changing columns count in viewSettings
      withSummary?: boolean;
      appendHeaderWith?: React.ReactNode;
    }
);

type OnCellClick = () => void;
type OnCellClickGenerator<T = any> = (item: T) => OnCellClick | undefined;

export type ListColumn<ListObj extends ListItem = ListItem, ColumnId = string | number> = {
  id?: ColumnId;
  key: KeyType<ListObj> | KeyType<ListObj>[];
  // sortableValue?: SortableValue<ValueType<ListObj>>; // TODO: check why this is not working
  sortableValue?: SortableValue;
  title?: string;
  customCellRenderer?: Renderer;
  customHeaderRenderer?: (title?: string) => React.ReactNode;
  sx?: ThemeUIStyleObject;
  width?: number | string | (number | string | null)[];
  columnGrow?: number | (number | null)[];
  onCellClickGenerator?: OnCellClickGenerator;
  summary?: {
    key?: KeyType<ListObj> | KeyType<ListObj>[];
    renderer: (items: any[]) => React.ReactNode;
  };
};

export type RowProps = {
  id: ListItem['id'];
  data: ListItem;
  listName: StickyListProps['name'];
  onRowClick?: OnRowClick;
  uncheckable?: boolean;
  tooltip?: string;
  variant?: ListVariant | CustomRowVariant;
  rowHover?: boolean;
} & Pick<StickyListProps, 'columns' | 'select' | 'frozenColumns' | 'withRowContextMenu'>;

export type HeaderProps = Omit<RowProps, 'data' | 'onRowClick' | 'id'> &
  Pick<StickyListProps, 'type' | 'appendHeaderWith'> & {
    gridWidth?: number;
    style?: FixedSizeListProps['style'];
    sx?: ThemeUIStyleObject;
  };

export type GridCellProps = Omit<RowProps, 'columns'> & {
  isSticky?: boolean;
  columnData: ListColumn;
};

type StickyListDataProps<ListObj extends ListItem = ListItem, ColumnId = string | number> = Pick<
  StickyListProps<ListObj, ColumnId>,
  | 'name'
  | 'columns'
  | 'select'
  | 'showHeader'
  | 'list'
  | 'onRowClick'
  | 'variant'
  | 'customRowVariantGenerator'
  | 'rowTooltipGenerator'
  | 'isRowUncheckableValidator'
  | 'frozenColumns'
  | 'withRowContextMenu'
  | 'withSummary'
> & {
  itemCount: number;
};

export type StickyListContextProps<ListObj extends ListItem = ListItem, ColumnId = string | number> = {
  showHeader?: boolean;
  showContentPlaceholder?: boolean;
  listStyle?: StickyListProps<ListObj, ColumnId>['style'];
  itemHeight?: number;
  itemWidth?: number;
} & Partial<StickyListDataProps<ListObj, ColumnId>> &
  Partial<
    Pick<
      StickyListProps<ListObj, ColumnId>,
      'variant' | 'headerSx' | 'frozenColumns' | 'name' | 'type' | 'disableNativeKeyboardScrolling' | 'appendHeaderWith'
    >
  >;

export type ListRendererProps = ListChildComponentProps<StickyListDataProps>;
export type GridRendererProps = GridChildComponentProps<Omit<StickyListDataProps, 'onRowClick'>>;

export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type ColumnHeaderProps = {
  columnKey: ListColumn['key'];
  isSortable: boolean;
  children?: ListColumn['title'] | React.ReactNode;
  hasCustomRenderer?: boolean;
} & Pick<ListColumn, 'title'> &
  Pick<HeaderProps, 'listName'>;

export enum ListNames {
  TEAM = 'TEAM',
  TEAM_IMPORT_EMPLOYEES = 'TEAM_IMPORT_EMPLOYEES',
  MANAGE_ROLES = 'MANAGE_ROLES',
  MANAGE_TAGS = 'MANAGE_TAGS',
  MANAGE_WORK_POSITIONS = 'MANAGE_WORK_POSITIONS',

  CALENDAR = 'CALENDAR',
  EXPORT_EMPLOYEES = 'EXPORT_EMPLOYEES',
  COPY_SCHEDULES = 'COPY_SCHEDULES',

  REQUESTS = 'REQUESTS',
  REQUESTS_PICK_TEAMMATES = 'REQUESTS_PICK_TEAMMATES',
  REQUESTS_TIME_OFF = 'REQUESTS_TIME_OFF',
  REQUESTS_CUSTOM = 'REQUESTS_CUSTOM',
  REQUESTS_TIME_LIMITS = 'REQUESTS_TIME_LIMITS',
  REQUESTS_BUSINESS_HISTORY = 'REQUESTS_BUSINESS_HISTORY',
  REQUESTS_CUSTOM_TIME_OFF_HISTORY = 'REQUESTS_CUSTOM_TIME_OFF_HISTORY',
  REQUESTS_TIME_EVENTS_HISTORY = 'REQUESTS_TIME_EVENTS_HISTORY',
  REQUESTS_HISTORY = 'REQUESTS_HISTORY',
  REQUESTS_OVERVIEW = 'REQUESTS_OVERVIEW',

  CLOCK_LOG = 'CLOCK_LOG',
  CLOCK_LOG_PICK_TEAMMATES = 'CLOCK_LOG_PICK_TEAMMATES',
  CLOCK_LOG_PHOTO_LOG = 'CLOCK_LOG_PHOTO_LOG',
  MANAGE_WORK_STATUSES = 'MANAGE_WORK_STATUSES',
  MANAGE_LOCATIONS = 'MANAGE_LOCATIONS',

  WORK_STATUSES = 'WORK_STATUSES',
  LOCATIONS = 'LOCATIONS',
  TIME_CLOCKS = 'TIME_CLOCKS',

  ATTENDANCE_LIST = 'ATTENDANCE_LIST',
  TIMESHEETS = 'TIMESHEETS',

  SETTINGS_WEBHOOK = 'SETTINGS_WEBHOOK',
  SETTINGS_HOLIDAYS = 'SETTINGS_HOLIDAYS',
  SETTINGS_IMPORT_HOLIDAYS = 'SETTINGS_IMPORT_HOLIDAYS',

  PAYMENTS_BILLING_HISTORY = 'PAYMENTS_BILLING_HISTORY',
  PAYMENTS_UNPAID_BALANCE = 'PAYMENTS_UNPAID_BALANCE',
}
