import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useTransition } from 'react';
import { useFormContext } from 'react-hook-form';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { EDIT_REQUEST_DEBOUNCE_TIME } from 'constants/requests';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useMemoCompare } from 'hooks/useMemoCompare/useMemoCompare';
import { MinimizeModalAddRequest } from 'state/modal';
import { ParsedEventToUpdate } from 'state/requests';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';
import { useMainFormEdit } from '../../../../../hooks/useMainFormEdit';

export const EditionObserver = (): React.ReactElement => {
  const {
    modifyRequest: { modifyRequestData },
    requestToEdit: { selectedRequestToEdit },
    requestFormRestored: { editRequestRestored },
    setRequestFormRestored,
  } = useAddRequest();
  const { handleMainFormValuesForRequestToEdit, updatePrevEvent } = useMainFormEdit();
  const [isInTransition, startTransition] = useTransition();
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddRequest>();
  const storedMinimizedModalDetails = useRef(!!minimizedModalDetails?.modalDetails.requestToEdit);
  const editRequestRestoredRef = useCallbackRef(editRequestRestored);

  const { setValue, watch } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  const handleSetHookFormValue = useMemo(
    () =>
      _.debounce((event: ParsedEventToUpdate) => {
        if (storedMinimizedModalDetails.current && !editRequestRestoredRef.current) {
          setRequestFormRestored((prev) => ({ ...prev, editRequestRestored: true }));
          updatePrevEvent(event);
          return;
        }

        startTransition(() => {
          if (event && actionTypeWatch !== RequestActionType.Create) {
            const {
              id,
              eventUpdateDetails: { name, place },
              dateTimeDetails: { dates },
            } = event;

            handleMainFormValuesForRequestToEdit.current(event);

            setValue('targetEventId', id);

            if (actionTypeWatch !== RequestActionType.Remove) {
              setValue('details.name', name);
              setValue('details.place', place);
              if (dates) {
                const [startUnix, endUnix] = dates;

                if (!endUnix) {
                  setValue('dateTimeDetails.dates', [startUnix, startUnix]);
                } else {
                  setValue('dateTimeDetails.dates', dates);
                }
              }
            }
          }
        });
      }, EDIT_REQUEST_DEBOUNCE_TIME),
    [
      editRequestRestoredRef,
      setRequestFormRestored,
      updatePrevEvent,
      actionTypeWatch,
      handleMainFormValuesForRequestToEdit,
      setValue,
    ],
  );

  const selectedBusinessDataMemo = useMemoCompare(selectedRequestToEdit);

  useEffect(() => {
    if (selectedBusinessDataMemo) {
      handleSetHookFormValue(selectedBusinessDataMemo);
    }
  }, [handleSetHookFormValue, selectedBusinessDataMemo]);

  useEffect(() => {
    if (modifyRequestData) {
      handleSetHookFormValue(modifyRequestData);
    }
  }, [handleSetHookFormValue, modifyRequestData]);

  return <>{isInTransition && <LoadingOverlay sx={{ zIndex: 'base' }} />}</>;
};
