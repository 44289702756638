import { t } from '@lingui/macro';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { NavButton } from 'components/recipes/NavButton';
import { ElementGroup } from 'components/ui/ElementGroup';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { ParsedEmployee } from 'state/employees';
import { languageSelector } from 'state/recoilState';
import { getEmployeeStates } from '../../../../utils/getEmployeeStates';
import { TabName } from '../types';

const useStyles = (() => {
  const navSx: ThemeUIStyleObject = {
    flexDirection: 'column',
    width: ['auto', null, null, '224px'],
    mr: [0, null, null, 3],
  };

  const firstButtonSx: ThemeUIStyleObject = { borderTopLeftRadius: 'sm', borderTopRightRadius: 'sm' };
  const lastButtonSx: ThemeUIStyleObject = { borderBottomLeftRadius: 'sm', borderBottomRightRadius: 'sm' };
  const groupSx: ThemeUIStyleObject = { mt: 2 };
  const employeeStatesWrapperSx = { mt: 4, ml: 2 };

  return () => ({
    navSx,
    firstButtonSx,
    lastButtonSx,
    groupSx,
    employeeStatesWrapperSx,
  });
})();

type Props = {
  activeTab: TabName | null;
  onTabClick: (tabName: TabName) => void;
  employeeDetails: Pick<
    ParsedEmployee,
    'avatarUrl' | 'email' | 'name' | 'invitationState' | 'isActive' | 'isHidden' | 'id'
  > & { initialEmail?: string };
};

export const Navigation: FC<Props> = ({
  activeTab,
  onTabClick,
  employeeDetails: { avatarUrl, name, email, invitationState, isHidden, isActive, id, initialEmail = '' },
}) => {
  const { isHeadAdmin } = useAppPermissions();
  const language = useRecoilValue(languageSelector);
  const navigate = useNavigate();
  const { navSx, firstButtonSx, lastButtonSx, groupSx, employeeStatesWrapperSx } = useStyles();
  const fullName = useNameDisplayOrder();
  const employeeStates = getEmployeeStates(
    { invitationState, isActive, isHidden, email: initialEmail },
    { color: 'team.employeeStates', colorHover: 'team.employeeStates' },
  );
  return (
    <Flex sx={navSx}>
      <NavButton
        onClick={() => onTabClick(TabName.Profile)}
        isActive={activeTab === TabName.Profile}
        label={fullName(name.firstName, name.surname)}
        shape="rounded"
        additionalLabel={email}
        avatarProps={{ name, image: avatarUrl }}
      />
      <ElementGroup marginValue="1px" direction="column" showAsList wrapperSx={groupSx}>
        <NavButton
          onClick={() => onTabClick(TabName.Employment)}
          isActive={activeTab === TabName.Employment}
          label={t({ id: 'team.edit_teammate.employment_det', message: 'Employment' })}
          iconType="account"
          sx={firstButtonSx}
        />
        <NavButton
          onClick={() => onTabClick(TabName.RequestsLimits)}
          isActive={activeTab === TabName.RequestsLimits}
          label={t({ id: 'team.edit_teammate.requests_limits', message: 'Requests Limits' })}
          iconType="requests"
        />
        <NavButton
          onClick={() => onTabClick(TabName.Advanced)}
          isActive={activeTab === TabName.Advanced}
          label={t({ id: 'team.edit_teammate.advanced', message: 'Advanced' })}
          iconType="preferences"
          sx={lastButtonSx}
        />
      </ElementGroup>

      <NavButton
        onClick={() => onTabClick(TabName.Notes)}
        isActive={activeTab === TabName.Notes}
        label={t({ id: 'team.edit_teammate.notes', message: 'Private notes' })}
        shape="rounded"
        iconType="note"
        sx={groupSx}
      />
      {isHeadAdmin && (
        <NavButton
          onClick={() => onTabClick(TabName.Other)}
          isActive={activeTab === TabName.Other}
          label={t({ id: 'team.edit_teammate.other', message: 'Other' })}
          shape="rounded"
          iconType="swissKnife"
          sx={groupSx}
        />
      )}
      <ElementGroup marginValue={2} direction="column" wrapperSx={employeeStatesWrapperSx}>
        {employeeStates?.map(({ iconType, message, color, colorHover, route }) => (
          <Flex
            key={iconType}
            sx={{
              color,
              '& :hover': {
                color: colorHover,
                ...(route && { cursor: 'pointer' }),
              },
            }}
            {...(route &&
              language && {
                onClick: () => {
                  navigate(route[language], { state: { ids: [id] } });
                },
              })}
          >
            <Icon type={iconType} size={21} />
            <Text sx={{ fontSize: 2 }} ml={2}>
              {message}
            </Text>
          </Flex>
        ))}
      </ElementGroup>
    </Flex>
  );
};
