import { Trans } from '@lingui/macro';
import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { Modal } from 'components/Modal/output/Modal';
import { DeselectMenu } from 'layouts/AuthorizedApp/Main/Header/DeselectMenu';
import { pickTeammatesFilteredEmployeesSelectorFamily, pickTeammatesHiddenEmployeesIdsAtom } from 'state/pickTeammates';

import { PickTeammatesProps } from './types';

type Props = PickTeammatesProps;

const LazyPickTeammatesList = React.lazy(() =>
  import('./List').then(({ PickTeammatesList }) => ({
    default: PickTeammatesList,
  })),
);

export const PickTeammates = ({ hideIds, listName, module, forceIncludeCurrentUser }: Props): React.ReactElement => {
  const filteredEmployee = useRecoilValue(
    pickTeammatesFilteredEmployeesSelectorFamily({ module, forceIncludeCurrentUser }),
  );
  const setHiddenIds = useSetRecoilState(pickTeammatesHiddenEmployeesIdsAtom);

  useEffect(() => {
    if (hideIds) {
      setHiddenIds(hideIds);
      return;
    }

    setHiddenIds(null);
  }, [hideIds, setHiddenIds]);

  return (
    <>
      <Flex>
        <Modal.SubTitle>
          <Trans id="requests.add_request.pick_teammates">Pick teammate(s)</Trans>
        </Modal.SubTitle>
        <DeselectMenu selectorFamilyID={listName} sx={{ ml: 'auto' }} />
      </Flex>
      <Flex
        sx={{
          flexGrow: 1,
          position: 'relative',
        }}
      >
        <React.Suspense fallback={<LoadingOverlay sx={{ zIndex: 'base' }} />}>
          {filteredEmployee && <LazyPickTeammatesList employees={filteredEmployee} listName={listName} />}
        </React.Suspense>
      </Flex>
    </>
  );
};
