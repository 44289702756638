import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { deleteCustomRequestTypeAction } from 'api/actions/customRequest.ts/customRequestActions';
import { RequestType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { customRequestTypesSelector } from 'state/organizationSession';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';

export const DeleteCustomRequestModal = (): React.ReactElement => {
  useLingui();
  const customRequestTypes: RequestType[] | null = useRecoilValue(customRequestTypesSelector);

  const titleRenderer = useCallback(
    () => t({ id: 'settings.custom_request.delete', message: 'Delete custom request type' }),
    [],
  );

  const contentRenderer = useCallback(
    () => (
      <Trans id="settings.custom_request.delete_message">
        <Text as="p">
          You're about to permanently delete this custom request type and its settings.{' '}
          <strong>This action cannot be reversed.</strong>
        </Text>
        <Text as="p" mt={4}>
          All users that have requested this request type will NOT be affected, all data related to those requests will
          NOT be affected.
        </Text>
      </Trans>
    ),
    [],
  );

  return (
    <ConfirmModal
      list={customRequestTypes}
      action={deleteCustomRequestTypeAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
    />
  );
};
