import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { MinimizedModalsProvider } from 'components/Modal/output/MinimizedModalsProvider';
import { CALENDAR_TYPE, TO } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { ViewWithMinimizedModals } from 'state/modal';
import { isWorkdayAvailabilityAvailableSelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';

import { ModalRoutes } from './ModalRoutes';

const LazyCalendar = React.lazy(() =>
  import('./components/Calendar').then(({ Calendar }) => ({
    default: Calendar,
  })),
);

export const CalendarRenderer = React.memo((): React.ReactElement => {
  const isWorkdayAvailabilityAvailable = useRecoilValue(isWorkdayAvailabilityAvailableSelector);
  const { modules, isInitialized } = useAppPermissions();
  const language = useRecoilValue(languageSelector);
  const { type: calendarType } = useParams();

  const redirectRoute = useMemo(
    () =>
      (() => {
        switch (calendarType) {
          case CALENDAR_TYPE.ALL[language]:
            if (!modules.Schedule && modules.Requests && isInitialized) {
              return TO.CALENDAR__REQUESTS[language];
            }
            if (modules.Schedule && !modules.Requests && !isWorkdayAvailabilityAvailable && isInitialized) {
              return TO.CALENDAR__SCHEDULES[language];
            }
            return undefined;
          case CALENDAR_TYPE.SCHEDULES[language]:
            if (!modules.Schedule && modules.Requests && isInitialized) {
              return TO.CALENDAR__REQUESTS[language];
            }
            return undefined;
          case CALENDAR_TYPE.AVAILABILITY[language]:
            if (!modules.Schedule && modules.Requests && isInitialized) {
              return TO.CALENDAR__REQUESTS[language];
            }
            if (!isWorkdayAvailabilityAvailable && isInitialized) {
              return TO.CALENDAR__SCHEDULES[language];
            }
            return undefined;
          case CALENDAR_TYPE.REQUESTS[language]:
            if (modules.Schedule && !modules.Requests && isInitialized) {
              return TO.CALENDAR__SCHEDULES[language];
            }
            return undefined;
          default:
            return TO.START[language];
        }
      })(),
    [isInitialized, isWorkdayAvailabilityAvailable, language, modules.Requests, modules.Schedule, calendarType],
  );

  if (redirectRoute) return <Navigate to={redirectRoute} relative="path" />;

  return (
    <>
      <React.Suspense fallback={<LoadingOverlay sx={{ zIndex: 'base' }} />}>
        <LazyCalendar />
      </React.Suspense>
      <MinimizedModalsProvider forView={ViewWithMinimizedModals.CALENDAR} />
      <ModalRoutes />
    </>
  );
});
