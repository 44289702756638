import { useCallback, useMemo, useState } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';

import { fetchEventsPhotosAction } from 'api/actions/timeEvent/timeEventActions';
import { EventPhoto, TimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { useModal } from 'components/Modal/output/useModal';
import { useMount } from 'hooks/useMount/useMount';

export const useFetchFraudVerificationPhotosDetails = (id?: TimeEvent['id']) => {
  const [verificationPhotosDetails, setVerificationPhotosDetails] = useState<EventPhoto[] | null>(null);

  const { query, loading } = useParameterizedQuery(fetchEventsPhotosAction);
  const { handleClose } = useModal();

  useMount(() => {
    if (id) {
      const getTimeEventDetails = async (eventId: TimeEvent['id']) => {
        const { error, payload } = await query([eventId]);
        if (error) handleClose();
        if (!error && payload) {
          const eventPhotos = payload.find(({ timeEventId }) => timeEventId === eventId);
          if (eventPhotos) {
            const { photos } = eventPhotos;
            setVerificationPhotosDetails(photos);
          }
        }
      };
      void getTimeEventDetails(id);
    }
  });

  const selectedPhotosIds = useMemo(() => {
    const autoSelectedPhotos = verificationPhotosDetails?.filter(({ isSelected }) => isSelected);
    const photosIds = autoSelectedPhotos?.map(({ id: photoId }) => photoId);

    return photosIds;
  }, [verificationPhotosDetails]);

  const toggleIsSelected = useCallback((src: string) => {
    setVerificationPhotosDetails((prevState) => {
      if (!prevState) return null;

      const updatedPhotos = prevState.map((photo) =>
        photo.url === src ? { ...photo, isSelected: !photo.isSelected } : photo,
      );

      return updatedPhotos;
    });
  }, []);

  return { verificationPhotosDetails, selectedPhotosIds, toggleIsSelected, isLoading: loading };
};
