/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useClient } from 'react-fetching-library';
import { Navigate } from 'react-router-dom';

import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter, BasicModalFooterProps } from 'components/recipes/BasicModalFooter';
import { Checkbox } from 'components/ui/Checkbox';
import { useSelectedIdsModalLogic } from 'hooks/useSelectedIdsModalLogic/useSelectedIdsModalLogic';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { delay } from 'utils/delay';

import { ConfirmModalProps } from './types';

type Props = ConfirmModalProps;

export const ConfirmModal = ({
  list,
  action,
  titleRenderer,
  contentRenderer,
  withConfirmation,
  variant = 'DELETE',
  namePath,
  nameParser,
  onResponseCallback,
  onSuccessCallback,
  footerRenderer,
  actionHandler,
  actionButtonProps,
  customSnackbar,
}: Props): React.ReactElement => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { baseRoute, handleClose, resolver } = useModal<boolean | null>();
  const { selectedIds, selectedNames, idsCount, loading, setLoading, confirmed, setConfirmed } =
    useSelectedIdsModalLogic(list, namePath, nameParser);

  const { query } = useClient();

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmed(e.target.checked);
  };

  const handleClick = useCallback(async () => {
    if (!action && !actionHandler && !resolver) return;
    if (resolver) {
      resolver(true);
    }
    if (actionHandler) {
      await actionHandler({ setLoading, handleClose });
      return;
    }
    if (!action) {
      handleClose();
      return;
    }

    setLoading(true);

    const { error: submitError } = await query(action(selectedIds));

    if (onResponseCallback) await onResponseCallback(submitError, selectedIds);

    setLoading(false);

    if (!submitError) {
      if (onSuccessCallback) onSuccessCallback(selectedIds);
      handleClose();

      await delay(100);
      addSnackbar(
        customSnackbar || {
          message: (() => {
            switch (variant) {
              case 'DELETE':
                return t({ id: 'team.delete_deactivate.deleted', message: 'Successfully deleted!' });
              case 'DEACTIVATE':
                return t({ id: 'team.delete_deactivate.deactivated', message: 'Successfully deactivated!' });
              case 'PUBLISH':
                return t({ id: 'schedule.published_successfully', message: 'Successfully published!' });
              case 'REJECT':
                return t({ id: 'clock_log.anti_spoofing.rejected', message: 'Successfully rejected!' });
              case 'APPROVE':
                return t({ id: 'clock_log.anti_spoofing.approved', message: 'Successfully approved!' });
              default:
                return '';
            }
          })(),
          variant: 'success',
        },
      );
    }
  }, [
    action,
    actionHandler,
    resolver,
    setLoading,
    query,
    selectedIds,
    onResponseCallback,
    handleClose,
    onSuccessCallback,
    addSnackbar,
    customSnackbar,
    variant,
  ]);

  const buttons: BasicModalFooterProps['buttons'] = useMemo(() => {
    const { variant: buttonVariant, buttonText, ...buttonRest } = actionButtonProps || {};

    return [
      {
        ...(withConfirmation ? { disabled: !confirmed } : {}),
        isLoading: loading,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick: handleClick,
        variant:
          buttonVariant ||
          (() => {
            switch (variant) {
              case 'DELETE':
              case 'REJECT':
                return 'danger';
              case 'DEACTIVATE':
                return 'warning';
              case 'APPROVE':
                return 'success';
              default:
                return 'primary';
            }
          })(),
        children:
          buttonText ||
          (() => {
            switch (variant) {
              case 'DELETE':
                return t({ id: 'team.delete_deactivate.delete', message: 'Delete' });
              case 'REJECT':
                return t({ id: 'clock_log.anti_spoofing.reject', message: 'Reject' });
              case 'APPROVE':
                return t({ id: 'clock_log.anti_spoofing.approve', message: 'Approve' });
              case 'DEACTIVATE':
                return t({ id: 'team.delete_deactivate.deactivate', message: 'Deactivate' });
              case 'PUBLISH':
                return t({ id: 'calendar.publish' });
              default:
                return '';
            }
          })(),
        ...(variant === 'PUBLISH' ? { className: 'onboarding-schedule-7' } : {}),
        ...buttonRest,
      },
    ];
  }, [actionButtonProps, withConfirmation, confirmed, loading, handleClick, variant]);

  if (
    !_.isUndefined(list) &&
    action &&
    !selectedIds.length &&
    variant !== 'PUBLISH' &&
    variant !== 'REJECT' &&
    baseRoute
  ) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{titleRenderer(idsCount, selectedNames[0])}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        sx={{
          '>span': {
            display: 'block',
            '+span': {
              mt: 4,
            },
          },
        }}
      >
        {contentRenderer(idsCount, selectedNames, selectedIds, baseRoute)}
        {!!withConfirmation && (
          <Checkbox
            size="sm"
            onChange={handleCheckboxChange}
            checked={confirmed}
            name="confirm"
            label={t({ id: 'team.delete_deactivate.confirm', message: "I understand, let's proceed." })}
            sx={{ mt: 4 }}
          />
        )}
      </Modal.Body>
      {footerRenderer ? (
        footerRenderer({ isLoading: loading, confirmed, handleAction: handleClick })
      ) : (
        <BasicModalFooter buttons={buttons} />
      )}
    </>
  );
};
