import { FC } from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { RequestsHistorySelect } from '../../RequestsHistorySelect';

import { Calendar } from './Calendar';
import { EditionObserver } from './EditionObserver';
import { LimitsObserver } from './LimitsObserver';
import { ResetFormObserver } from './ResetFormObserver';
import { SaveStateObserver } from './SaveStateObserver';
import { SelectType } from './SelectType';
import { SkipSwitches } from './SkipSwitches';

const RequestsHistorySelectWrapper: FC = () => <RequestsHistorySelect type={RequestFormType.Custom} />;

type Props = FlexProps;

type CustomFormContainerProps = Props;

type CustomFormContainerComponent = FC<CustomFormContainerProps> & {
  SelectType: typeof SelectType;
  Calendar: typeof Calendar;
  SkipSwitches: typeof SkipSwitches;
  LimitsObserver: typeof LimitsObserver;
  EditionObserver: typeof EditionObserver;
  SaveStateObserver: typeof SaveStateObserver;
  ResetFormObserver: typeof ResetFormObserver;
  RequestsHistorySelect: typeof RequestsHistorySelectWrapper;
};

export const CustomFormContainer: CustomFormContainerComponent = ({ children }: Props) => (
  <Flex sx={{ flexDirection: 'column', gap: 4, position: 'relative' }}>{children}</Flex>
);

CustomFormContainer.SelectType = SelectType;
CustomFormContainer.Calendar = Calendar;
CustomFormContainer.SkipSwitches = SkipSwitches;
CustomFormContainer.EditionObserver = EditionObserver;
CustomFormContainer.LimitsObserver = LimitsObserver;
CustomFormContainer.SaveStateObserver = SaveStateObserver;
CustomFormContainer.ResetFormObserver = ResetFormObserver;
CustomFormContainer.RequestsHistorySelect = RequestsHistorySelectWrapper;
