import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Flex } from 'theme-ui';

import { SettingsGroup } from '../../../../../SettingsGroup/SettingsGroup';

import { PlannedEndTime } from './components/PlannedEndTime';
import { PlannedStartTime } from './components/PlannedStartTime';
import { TimeRounding } from './components/TimeRounding/TimeRounding';

export const TimeSnapping = (): React.ReactElement => {
  useLingui();

  return (
    <SettingsGroup variant="sm">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="automations_settings.heading.time_snapping">Time snapping</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <PlannedStartTime />
        <PlannedEndTime />
        <TimeRounding />
      </Flex>
    </SettingsGroup>
  );
};
