import type { ResponseInterceptor } from 'react-fetching-library';

import { Action } from 'api/types';
import { FETCH_TIMEOUT_EXCEEDED_RESPONSE_STATUS, FETCH_TIMEOUT_REQUESTS_MAX } from 'constants/common';
import { TO } from 'constants/routes';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';

export const responseTimeoutExceededInterceptor: () => ResponseInterceptor =
  () => (client) => async (action: Action<unknown>, response) => {
    if (action.config && (action.config.skipResend || action.config.skipAllResponseInterceptors)) {
      return response;
    }

    if (response.status !== FETCH_TIMEOUT_EXCEEDED_RESPONSE_STATUS) {
      return response;
    }

    let sentCounter = action.config?.resentTimes || 0;

    if (action.config?.resentTimes && action.config.resentTimes >= FETCH_TIMEOUT_REQUESTS_MAX) {
      emitCustomEvent(CustomEvents.REDIRECT, TO.SERVICE_UNAVAILABLE);
      return response;
    }

    sentCounter += 1;

    return client.query({
      ...action,
      config: {
        ...(action.config && action.config),
        resentTimes: sentCounter,
        skipHost: true,
      },
    });
  };
