import { useCallback, useState } from 'react';
import { useClient, useMutation } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import {
  fetchIntegrationsAction,
  integrationRefreshTokenAction,
  integrationsDisconnectAction,
} from 'api/actions/integrations/integrationActions';
import { integrationsAtom } from 'state/settings';
import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';

export const useIntegrations = () => {
  const { query } = useClient();
  const { mutate: reconnectMutate } = useMutation(integrationRefreshTokenAction);
  const { mutate: disconnectMutate } = useMutation(integrationsDisconnectAction);
  const [integrations, setIntegrations] = useRecoilState(integrationsAtom);
  const [isInitialized, setIsInitialized] = useState(false);

  const fetchIntegrations = useCallback(async () => {
    const { error, payload } = await query(fetchIntegrationsAction());

    if (!error) {
      setIsInitialized(true);

      if (payload) {
        setIntegrations(payload);
      }
    }
  }, [query, setIntegrations]);

  const reconnectIntegration = useCallback(
    async (integration: ServiceIntegration) => {
      const { error } = await reconnectMutate({ integration });

      if (!error) {
        await fetchIntegrations();
      }
    },
    [fetchIntegrations, reconnectMutate],
  );

  const disconnectIntegration = useCallback(
    async (integration: ServiceIntegration) => {
      const { error } = await disconnectMutate({ integration });

      if (!error) {
        await fetchIntegrations();
      }
    },
    [disconnectMutate, fetchIntegrations],
  );

  return {
    integrations,
    setIntegrations,
    fetchIntegrations,
    isInitialized,
    reconnectIntegration,
    disconnectIntegration,
  };
};
