import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { Select } from 'components/ui/Select/Select';
import { Switch } from 'components/ui/Switch';
import { TimePicker } from 'components/ui/TimePicker/TimePicker';
import { VALIDATION_RULES } from 'constants/validationRules';
import { worktimeWithoutScheduleOptionsSelector } from 'state/selectOptions';
import { useFormContextCustomEvent } from '../../../../../../../hooks/useFormContextCustomEvent';
import { LabelWithBadge } from '../../../../../../LabelWithBadge';
import { OptionLabel } from '../../../../../../OptionLabel';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';

export const Other = (): React.ReactElement => {
  useLingui();
  const worktimeWithoutScheduleOptions = useRecoilValue(worktimeWithoutScheduleOptionsSelector);

  const {
    registerOnChange,
    registerOnBlur,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContextCustomEvent();

  const countNightHoursWatch = watch('countNightHours');

  const setNightHoursErrorCallback = useCallback(() => {
    setError('nightHoursInternalError' as 'nightHoursDurationSeconds', { type: 'max' });
  }, [setError]);

  const clearNightHoursErrorCallback = useCallback(() => {
    clearErrors('nightHoursInternalError' as 'nightHoursDurationSeconds');
  }, [clearErrors]);

  return (
    <SettingsGroup variant="sm">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="time_tracking_settings.heading.other">Other</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <SettingsGroup.Body>
        <SettingsGroup.Body.Visible
          variant="md"
          pb={countNightHoursWatch ? 2 : '0.75rem'}
          withoutBorderBottom={countNightHoursWatch}
        >
          <OptionLabel
            label={
              <LabelWithBadge
                label={t({
                  id: 'time_tracking_settings.type_of_worktime_without_schedule.label',
                  message: 'Count work time without schedule as',
                })}
                badgeText={t({ id: 'settings.scheduling' })}
                tooltipText={t({ id: 'settings.tooltip_scheduling' })}
              />
            }
            apendWith={
              <Select
                {...registerOnBlur('typeOfWorktimeWithoutSchedule', {
                  required: true,
                  valueAsNumber: true,
                })}
                id="typeOfWorktimeWithoutSchedule"
                placeholder={t({
                  id: 'time_tracking_settings.type_of_worktime_without_schedule.label',
                  message: 'Count work time without schedule as',
                })}
                options={worktimeWithoutScheduleOptions}
                size="sm"
                error={!!errors.typeOfWorktimeWithoutSchedule}
                sx={{ maxWidth: '188px' }}
              />
            }
            withDivider
          />
          <Switch
            {...registerOnChange('countNightHours')}
            label={t({
              id: 'time_tracking_settings.count_night_hours.label',
              message: 'Count night period',
            })}
            additionalInfo={t({
              id: 'time_tracking_settings.count_night_hours.additional_info',
              message: 'All reports will have additional column with the sum of night hours for each employee.',
            })}
            size="sm"
            bold
          />
        </SettingsGroup.Body.Visible>

        {countNightHoursWatch && (
          <SettingsGroup.Body.Hidden variant="md" pt={2} pb={'0.75rem'} withBorderBottom>
            <OptionLabel
              label={t({
                id: 'time_tracking_settings.texts.night_hours',
                message: 'Night period begins at',
              })}
              apendWith={
                <TimePicker
                  {...registerOnBlur('nightHoursStartSeconds', {
                    valueAsNumber: true,
                    required: t({ id: 'global.forms.required.short' }),
                  })}
                  error={!!errors.nightHoursStartSeconds}
                  errorMessage={errors.nightHoursStartSeconds?.message}
                  id="nightHoursStartSeconds"
                  size="sm"
                />
              }
              withDivider
            />
            <OptionLabel
              label={t({
                id: 'time_tracking_settings.texts.last_for',
                message: 'Night period lasts for',
              })}
              apendWith={
                <DurationPicker
                  {...registerOnBlur('nightHoursDurationSeconds', {
                    valueAsNumber: true,
                    required: t({ id: 'global.forms.required.short' }),
                  })}
                  id="nightHoursDurationSeconds"
                  size="sm"
                  hours
                  minutes
                  seconds={false}
                  onValidError={setNightHoursErrorCallback}
                  onClearError={clearNightHoursErrorCallback}
                  error={!!errors.nightHoursDurationSeconds}
                  errorMessage={errors.nightHoursDurationSeconds?.message}
                  minDuration={VALIDATION_RULES.NIGHT_HOURS_DURATIONS_SECONDS.min}
                  maxDuration={VALIDATION_RULES.NIGHT_HOURS_DURATIONS_SECONDS.max}
                />
              }
            />
          </SettingsGroup.Body.Hidden>
        )}
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
