import { Trans, useLingui } from '@lingui/react';
import _ from 'lodash';
import { FC, useMemo } from 'react';
import { Flex, Text, ThemeUICSSObject } from 'theme-ui';

import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { getDurationObj } from '../../../../../../utils/getDurationObj';
import { useAddRequest } from '../../../hooks/useAddRequest';
import { useAddRequestLogic } from '../../../hooks/useAddRequestLogic';

import { useTimeOffFormState } from './TimeOffForm/hooks/useTimeOffFormState';

type Props = {
  hide?: boolean;
  displayZeroLimitAsHours?: boolean;
};

export const MaxRequestLimit: FC<Props> = ({ hide, displayZeroLimitAsHours }) => {
  useLingui();
  const { preparePeriod } = useAddRequestLogic();
  const {
    requestLimits: { requestLimits, requestLimitsLoading },
  } = useAddRequest();
  const { isDurationType, isForDayType, forWholeDayWatch } = useTimeOffFormState();

  const textRender = useMemo(() => {
    const textCommonSx: ThemeUICSSObject = {
      alignSelf: 'center',
      ...(requestLimitsLoading && { opacity: 0.6 }),
    };

    if (!requestLimits) {
      if (!isDurationType && !isForDayType && !forWholeDayWatch) {
        return (
          <Text sx={textCommonSx}>
            <Trans id="requests.add_request.select_starting_date_and_time">
              Select a starting date and time to see limits
            </Trans>
          </Text>
        );
      }

      return (
        <Text sx={textCommonSx}>
          <Trans id="requests.add_request.select_starting_date">Select a starting date to see limits</Trans>
        </Text>
      );
    }

    const { maxLength, period, peopleLimits } = requestLimits;

    if (!peopleLimits) {
      return (
        <Text sx={textCommonSx}>
          <Trans id="requests.add_request.unlimited">Unlimited</Trans>
        </Text>
      );
    }

    const max = (() => {
      const { hours, minutes, days } = getDurationObj(maxLength);

      if (!days && !hours && !minutes) return <Text>{displayZeroLimitAsHours ? '0h' : '0d'}</Text>;

      return (
        <>
          {days > 0 && <Text>{days}d</Text>} {hours > 0 && <Text>{hours}h</Text>}{' '}
          {minutes > 0 && <Text>{minutes}m</Text>}
        </>
      );
    })();

    return (
      <>
        <Text sx={textCommonSx}>
          <Trans id="requests.add_request.limit_length">Max length</Trans>
        </Text>
        <Flex variant="fancyDataBox.time" sx={{ py: 0, ...(requestLimitsLoading && { opacity: 0.6 }) }}>
          {max}
        </Flex>
        {_.isNumber(period) && <Text sx={textCommonSx}>{preparePeriod(period)}</Text>}
      </>
    );
  }, [
    displayZeroLimitAsHours,
    forWholeDayWatch,
    isDurationType,
    isForDayType,
    preparePeriod,
    requestLimits,
    requestLimitsLoading,
  ]);

  if (hide) return null;

  return (
    <Flex mt={2}>
      <Flex variant="fancyDataBox.container" sx={{ fontSize: 2, gap: '0.375rem', position: 'relative' }}>
        {textRender}
        {requestLimitsLoading && (
          <Flex
            sx={{
              position: 'absolute',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <LoadingSpinnerCss />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
