import { useState } from 'react';

import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { AvatarInfo } from '../../../forms/UserProfile/UserProfile';

export const useAvatarInfo = () => {
  const [savedAvatarInfo, setSavedAvatarInfo] = useState<AvatarInfo | null>(null);
  const savedAvatarInfoRef = useCallbackRef(savedAvatarInfo);

  return {
    savedAvatarInfo,
    setSavedAvatarInfo,
    savedAvatarInfoRef,
  };
};
