import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { RolesSelect } from 'components/ui/Select/variants/RolesSelect';
import { Switch } from 'components/ui/Switch';
import { rolesApproveRequestsSelectOptionsSelector } from 'state/settings';
import { useFormContextCustomEvent } from '../../../../../../../hooks/useFormContextCustomEvent';
import { OptionLabel } from '../../../../../../OptionLabel';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';

export const Custom = (): React.ReactElement => {
  useLingui();
  const rolesSelectOptions = useRecoilValue(rolesApproveRequestsSelectOptionsSelector);

  const {
    registerOnChange,
    registerOnBlur,
    watch,
    formState: { errors },
  } = useFormContextCustomEvent<{
    customRequestsApprovalSettings: {
      twoStepApprovalFlow: boolean;
      firstStepRoleId: string;
      secondStepRoleId: string;
    };
  }>();

  const twoStepApprovalWatch = watch('customRequestsApprovalSettings.twoStepApprovalFlow');
  const firstStepRoleIdWatch = watch('customRequestsApprovalSettings.firstStepRoleId');
  const secondStepRoleIdWatch = watch('customRequestsApprovalSettings.secondStepRoleId');

  return (
    <SettingsGroup variant="sm">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="requests_settings.heading.custom_request">Custom requests</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <SettingsGroup.Body>
        <SettingsGroup.Body.Visible
          variant="md"
          pb={twoStepApprovalWatch ? 2 : '0.75rem'}
          withoutBorderBottom={twoStepApprovalWatch}
        >
          <Switch
            {...registerOnChange('customRequestsApprovalSettings.twoStepApprovalFlow')}
            label={t({
              id: 'requests_settings.two_step_approval_flow.label',
            })}
            additionalInfo={
              <Text as="span" sx={{ textDecoration: 'underline' }}>
                <Trans id="requests_settings.two_step_approval_flow.additionalInfo">
                  Changing this setting will affect all pending requests.
                </Trans>
              </Text>
            }
            size="sm"
            bold
          />
        </SettingsGroup.Body.Visible>

        {twoStepApprovalWatch && (
          <SettingsGroup.Body.Hidden variant="md" pt={2} pb={'0.75rem'} withBorderBottom>
            <OptionLabel
              label={t({
                id: 'requests_settings.first_step_role_id.label',
              })}
              apendWith={
                <>
                  {rolesSelectOptions && (
                    <RolesSelect
                      {...registerOnBlur('customRequestsApprovalSettings.firstStepRoleId', {
                        required: t({ id: 'global.forms.required.short' }),
                      })}
                      id="customRequestsApprovalSettings.firstStepRoleId"
                      placeholder={t({
                        id: 'requests_settings.first_step_role_id.label',
                      })}
                      options={_.filter(rolesSelectOptions, (item) => item.id !== secondStepRoleIdWatch)}
                      size="sm"
                      error={!!errors.customRequestsApprovalSettings?.firstStepRoleId}
                      errorMessage={errors.customRequestsApprovalSettings?.firstStepRoleId?.message}
                      sx={{ maxWidth: '188px' }}
                    />
                  )}
                </>
              }
              withDivider
            />
            <OptionLabel
              label={t({
                id: 'requests_settings.second_step_role_id.label',
              })}
              apendWith={
                <>
                  {rolesSelectOptions && (
                    <RolesSelect
                      {...registerOnBlur('customRequestsApprovalSettings.secondStepRoleId', {
                        required: t({ id: 'global.forms.required.short' }),
                      })}
                      id="customRequestsApprovalSettings.secondStepRoleId"
                      placeholder={t({
                        id: 'requests_settings.second_step_role_id.label',
                      })}
                      options={_.filter(rolesSelectOptions, (item) => item.id !== firstStepRoleIdWatch)}
                      size="sm"
                      error={!!errors.customRequestsApprovalSettings?.secondStepRoleId}
                      errorMessage={errors.customRequestsApprovalSettings?.secondStepRoleId?.message}
                      sx={{ maxWidth: '188px' }}
                    />
                  )}
                </>
              }
            />
          </SettingsGroup.Body.Hidden>
        )}
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
