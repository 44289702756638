import { Face } from '@tensorflow-models/face-detection';
import { useEffect, useState } from 'react';

import { isFaceInPlace } from 'Kiosk/helpers';
import { CameraState } from 'Kiosk/state/cameraState';
import { Awaited } from 'utils/custom.types';

let scannedPoints: number[][] = [];

export const resetScannedPoints = (): void => {
  scannedPoints = [];
};

type DetectProps = Required<Props>;

type DetectReturnValue = Awaited<ReturnType<typeof detect>>;

const detect = ({ source, faces }: DetectProps) => {
  // check if face is in PLACE
  const faceInPlace = isFaceInPlace(faces, source.video);

  return { faceInPlace };
};

type Props = Pick<CameraState, 'source'> & {
  faces?: Face[];
};

export type UseInitialFaceScanProps = Props;

type ReturnValue = DetectReturnValue & { scannedPoints: number[][] };

export const useInitialFaceScan = ({ source, faces }: Props): ReturnValue => {
  const [status, setStatus] = useState<DetectReturnValue>({
    faceInPlace: false,
  });

  useEffect(() => {
    scannedPoints = [];

    return () => {
      scannedPoints = [];
    };
  }, []);

  useEffect(() => {
    const getDetect = (props: Required<Props>) => {
      const { faceInPlace } = detect({
        source: props.source,
        faces: props.faces,
      });

      setStatus(() => ({
        faceInPlace,
      }));
    };

    if (source && faces) {
      getDetect({
        source,
        faces,
      });
    }
  }, [source, faces]);

  return { ...status, scannedPoints };
};
