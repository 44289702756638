import { t } from '@lingui/macro';
import _ from 'lodash';
import { UseFormGetValues } from 'react-hook-form';
import { atom, atomFamily, RecoilState, selector, selectorFamily } from 'recoil';

import {
  RequestType,
  TimeEventType,
  TimeOffType,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import {
  DateTimeDetails,
  FetchApprovalListsResponse,
  FetchRequestDetailsResponse,
  FetchRequestsUsageOverviewResponse,
  GetAllRequestsToAcceptResponse,
  RemainingLimit,
  Request,
  RequestActionType,
  RequestEventType,
  RequestFormType,
  RequestGetForPersonForRangeAndTypeResponse,
  RequestGetToUpdateResponse,
  RequestsListResponse,
  RequestState,
  RequestsUsageOverviewElement,
  RequestsUsageOverviewRequests,
  RequestToUpdate,
} from 'api/actions/requests/requestsActions.types';
import { ListColumn, ListNames, StickyListProps } from 'components/StickyList/types';
import {
  DateRangeFilters,
  FilterGroupNames,
  HideEmptyColumnsFilter,
  HideTypesWithoutLimitsFilter,
  HideUsersWithoutDataFilter,
  TagsFilters,
  TeammatesFilters,
} from 'layouts/AuthorizedApp/AsideFilters/types';
import { AddRequestFormState } from 'pages/Requests/output/types';
import { isOfType } from 'utils/isOfType';
import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';
import { getStringWithReducedWhiteSpaces } from 'utils/whiteSpaceReducer';

import { filteredEmployeesSelector, ParsedEmployee, parsedEmployeesSelector } from './employees';
import {
  dateRangeRequestsUsageOverviewFilterAtom,
  FilterGroupState,
  filterGroupStateAtomFamily,
  GroupName,
  IsActiveFilterConfig,
  parsedSearchFilterValueSelectorFamily,
  RequestFilterState,
  SEARCH_FILTER_TYPE,
} from './filters';
import { selectedRowsIdsSelectorFamily } from './list';
import {
  customRequestTypesDictionarySelector,
  organizationSessionAtom,
  timeOffTypesDictionarySelector,
} from './organizationSession';
import { languageSelector } from './recoilState';
import { userSessionAtom } from './userSession';

/* 
***********************************************

      SUPERVISORS

***********************************************
*/

export type SentToPersonsType = {
  selectedFirstStepPerson?: ParsedEmployee | null;
  selectedSecondStepPerson?: ParsedEmployee | null;
};
type SentToPersonsSelectorArgsType = {
  selectedFirstStepPersonId?: string;
  selectedSecondStepPersonId?: string;
};

export const sentToPersonsSelector = selectorFamily<SentToPersonsType, SentToPersonsSelectorArgsType>({
  key: 'sentToPersons',
  get:
    ({ selectedFirstStepPersonId, selectedSecondStepPersonId }) =>
    ({ get }) => {
      const employees = get(parsedEmployeesSelector);
      return {
        selectedFirstStepPerson: selectedFirstStepPersonId ? employees?.get(selectedFirstStepPersonId) : null,
        selectedSecondStepPerson: selectedSecondStepPersonId ? employees?.get(selectedSecondStepPersonId) : null,
      };
    },
});

/* 
***********************************************

      REQUEST ATOM, PARSED REQUEST OBJECTS

***********************************************
*/
export type ParsedRequestType = RequestType | TimeOffType | TimeEventType;

export type ParsedRequestEventType = RequestEventType & {
  requestType?: ParsedRequestType | null;
};

export type ParsedRequest = Omit<Request, 'personId' | 'newEvent' | 'originalEvent'> & {
  originalEvent?: ParsedRequestEventType;
  newEvent: ParsedRequestEventType;
  employee: Omit<
    ParsedEmployee,
    'employeeId' | 'note' | 'email' | 'phoneNumber' | 'workPosition' | 'defaultWorkPosition'
  >;
};

export type ParsedRequestsMap = Map<Request['id'], ParsedRequest>;

export const requestsToAcceptAtom = atom<GetAllRequestsToAcceptResponse | null>({
  key: 'requests_to_accept',
  default: null,
});

export const requestsAtom = atom<RequestsListResponse | null>({
  key: 'requestsList',
  default: null,
});

const requestsGroupSelectorFamily = selectorFamily<Request[] | null, RequestsListType>({
  key: 'requestsGroup',
  get:
    (listType) =>
    ({ get }) => {
      const requests = get(requestsAtom);
      const requestsToAccept = get(requestsToAcceptAtom);

      if (listType === 'grouped') return requests?.grouped || null;
      if (listType === 'ungrouped') return requests?.ungrouped || null;

      return requestsToAccept;
    },
});

export const getParsedRequestTypeSelectorFamily = selectorFamily<
  ParsedRequestType | null,
  Request['newEvent']['eventDetails']['typeId'] | string
>({
  key: 'getParsedRequestType',
  get:
    (typeId) =>
    ({ get }) => {
      const organizationSession = get(organizationSessionAtom);

      if (!organizationSession) {
        return null;
      }

      const { customRequestTypes, timeOffTypes, timeEventTypes } = organizationSession;

      const requestType = _.find(customRequestTypes, (type) => type.id === typeId);
      const timeOffType = _.find(timeOffTypes, (type) => type.id === typeId);
      const timeEventType = _.find(timeEventTypes, (type) => type.id === typeId);

      return requestType ?? timeOffType ?? timeEventType ?? null;
    },
});

export const parsedRequestsMapSelectorFamily = selectorFamily<ParsedRequestsMap | null, RequestsListType>({
  key: 'parsedRequestsMap',
  get:
    (listType) =>
    ({ get }) => {
      const requests = get(requestsGroupSelectorFamily(listType));
      const employees = get(parsedEmployeesSelector);

      if (!requests || !employees) {
        return null;
      }

      const parsedRequestsMap: ParsedRequestsMap = new Map();

      requests.forEach((request) => {
        const newTypeId = request.newEvent?.eventDetails.typeId;
        const originalTypeId = request.originalEvent?.eventDetails.typeId;
        const { personId, newEvent, originalEvent } = request;

        const employee = employees.get(personId);
        const newRequestType = get(getParsedRequestTypeSelectorFamily(newTypeId));
        const originalRequestType = get(getParsedRequestTypeSelectorFamily(originalTypeId));

        if (!employee) {
          return;
        }

        const parsedOriginalEvent = originalEvent && { ...originalEvent, requestType: originalRequestType };
        const parsedNewEvent = newEvent && { ...newEvent, requestType: newRequestType };

        const parsedRequest = {
          ..._.omit(request, ['personId', 'newEvent', 'originalEvent']),
          employee,
          ...(parsedOriginalEvent && parsedNewEvent && { originalEvent: parsedOriginalEvent }),
          ...(parsedOriginalEvent && !parsedNewEvent
            ? { newEvent: parsedOriginalEvent }
            : { newEvent: parsedNewEvent }),
        };

        parsedRequestsMap.set(request.id, parsedRequest);
      });

      return parsedRequestsMap;
    },
});

export const allParsedRequestsMapSelector = selector<ParsedRequestsMap | null>({
  key: 'allParsedRequestsMap',
  get: ({ get }) => {
    const pendingRequests = get(parsedRequestsMapSelectorFamily('pending'));
    const groupedRequests = get(parsedRequestsMapSelectorFamily('grouped'));
    const ungroupedRequests = get(parsedRequestsMapSelectorFamily('ungrouped'));

    if (!pendingRequests && !groupedRequests && !ungroupedRequests) return null;

    return new Map([...(pendingRequests || []), ...(groupedRequests || []), ...(ungroupedRequests || [])]);
  },
});

/* 
***********************************************

      REQUEST LIST AND LIST FUNCTIONALITY

***********************************************
*/

export type RequestsListType = 'grouped' | 'ungrouped' | 'pending' | 'usageOverview';

// FILTERED REQUEST LIST
export const filteredRequestsSelectorFamily = selectorFamily<ParsedRequestsMap, RequestsListType>({
  key: 'filteredRequests',
  get:
    (listType) =>
    ({ get }) => {
      const requests = get(parsedRequestsMapSelectorFamily(listType));

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const language = get(languageSelector);
      const teammatesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES));
      const rolesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.ROLES));
      const tagsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TAGS));
      const requestTypeFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.REQUEST_TYPES));
      const requestStateFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.REQUEST_STATE));
      const searchFilterState = get(parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.TEAMMATES));

      const filterBySearchInput = (request: ParsedRequest) => {
        let valid = false;

        if (_.isEmpty(searchFilterState)) {
          valid = true;
          return valid;
        }

        const { id, number, groupNumber, originalEvent, newEvent } = request;

        const {
          name,
          role: { name: roleName },
          tags,
          id: employeeId,
        } = request.employee;

        const searchableEmployee = _.flatMapDeep([
          `${name.firstName} ${name.surname}`,
          roleName,
          _.map(tags, (tag) => tag?.name),
          employeeId,
          id,
          originalEvent?.requestType &&
            `${t({
              id: originalEvent.requestType.name,
            })}  ${
              isOfType<RequestType>(originalEvent.requestType, 'abbreviation') &&
              t({
                id: originalEvent.requestType.abbreviation,
              })
            }`,
          originalEvent?.eventDetails.name,
          newEvent?.requestType &&
            `${t({
              id: newEvent.requestType.name,
            })}  ${
              isOfType<RequestType>(newEvent.requestType, 'abbreviation') &&
              t({
                id: newEvent.requestType.abbreviation,
              })
            }`,
          newEvent?.eventDetails.name,
          number,
          groupNumber,
        ]);

        valid = _.some(
          searchFilterState.map((searchQuery) => {
            let subValid = false;

            _.forEach(searchableEmployee, (searchableValue) => {
              subValid = subValid || _.includes(searchableValue?.toLocaleLowerCase(), searchQuery.toLocaleLowerCase());
            });

            return subValid;
          }),
        );

        return valid;
      };

      const filterByTags = (employee: ParsedRequest['employee']) => {
        let valid = false;

        if (tagsFilterState === null) {
          valid = true;
          return valid;
        }

        if (_.isArray(tagsFilterState)) {
          tagsFilterState.forEach((tagId) => {
            valid = valid || !!employee.tagsIds.includes(tagId);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            if (tagId === TagsFilters.NO_TAGS && _.isEmpty(employee.tagsIds)) {
              valid = true;
            }
          });
        }

        return valid;
      };

      const filterByRole = (employee: ParsedRequest['employee']) => {
        let valid = false;

        if (rolesFilterState === null) {
          valid = true;
          return valid;
        }

        if (_.isArray(rolesFilterState)) {
          rolesFilterState.forEach((roleId) => {
            valid = valid || !!(employee.roleId === roleId);
          });
        }

        return valid;
      };

      const filterByTeammates = (employee: ParsedRequest['employee']) => {
        let valid = false;

        if (teammatesFilterState === null) {
          valid = true;
          return valid;
        }
        if (teammatesFilterState.includes(TeammatesFilters.ACTIVE)) {
          valid = valid || !!employee.isActive;
        }
        if (teammatesFilterState.includes(TeammatesFilters.HIDDEN)) {
          valid = valid || !!employee.isHidden;
        }
        if (teammatesFilterState.includes(TeammatesFilters.DEACTIVATED)) {
          valid = valid || !employee.isActive;
        }
        if (teammatesFilterState.includes(TeammatesFilters.INVITED)) {
          valid = valid || !!employee.invitationState;
        }

        return valid;
      };

      const filterByState = (state: ParsedRequest['state'], isDeleted: ParsedRequest['isDeleted']) => {
        if (
          listType === 'pending' ||
          requestStateFilterState === null ||
          (requestStateFilterState.includes(`${state}`) && !isDeleted) ||
          (requestStateFilterState.includes(RequestFilterState.Deleted.toString()) && isDeleted)
        ) {
          return true;
        }

        return false;
      };

      const filterByType = (type: ParsedRequest['type']) => {
        if (requestTypeFilterState === null || requestTypeFilterState.includes(`${type}`)) {
          return true;
        }

        return false;
      };

      if (!requests) {
        return new Map();
      }

      const filteredMap = new Map(requests);

      filteredMap.forEach((request, requestId) => {
        let valid = false;
        const { employee, type, state, isDeleted } = request;

        valid =
          filterByTeammates(employee) &&
          filterByRole(employee) &&
          filterByTags(employee) &&
          filterBySearchInput(request) &&
          filterByType(type) &&
          filterByState(state, isDeleted);

        if (!valid) filteredMap.delete(requestId);
      });

      return filteredMap;
    },
});

export const selectedRequestsIdsSelector = selector<ParsedRequest['id'][]>({
  key: 'selectedRequestsIds',
  get: ({ get }) => get(selectedRowsIdsSelectorFamily(ListNames.REQUESTS)),
});

/* 
***********************************************

      REQUEST OVERVIEW LIST AND LIST FUNCTIONALITY

***********************************************
*/
type ParsedRequestsUsageOverviewRequests = RequestsUsageOverviewRequests & { isPending: boolean };

export type ParsedRequestOverviewElement = Omit<RequestsUsageOverviewElement, 'personId' | 'requests'> & {
  timeOffTypeRequests: ParsedRequestsUsageOverviewRequests[];
  customTypeRequests: ParsedRequestsUsageOverviewRequests[];
  avatarUrl: ParsedEmployee['avatarUrl'];
  role: ParsedEmployee['role'];
  tags: ParsedEmployee['tags'];
  name: ParsedEmployee['name'];
  isActive: ParsedEmployee['isActive'];
  isHidden: ParsedEmployee['isHidden'];
  invitationState: ParsedEmployee['invitationState'];
  id: ParsedRequest['employee']['id'];
  isBusinessTravelsPending: boolean;
  isSwapRequestPending: boolean;
  isTimeEventsPending: boolean;
};

export type ParsedRequestsUsageOverviewMap = Map<
  RequestsUsageOverviewElement['personId'],
  ParsedRequestOverviewElement
>;

export const requestsUsageOverviewAtom = atom<FetchRequestsUsageOverviewResponse | null>({
  key: 'requestsUsageOverview',
  default: null,
});

const filteredRequestsToAcceptByRequestsUsageOverviewDatesSelector = selector<ParsedRequest[] | null>({
  key: 'filteredRequestsToAcceptByRequestsUsageOverviewDates',
  get: ({ get }) => {
    const requestsToAccept = get(parsedRequestsMapSelectorFamily('pending'));
    const dateRangeRequestsUsageOverviewFilter = get(dateRangeRequestsUsageOverviewFilterAtom);

    if (!dateRangeRequestsUsageOverviewFilter || !requestsToAccept) return null;

    const { startDateUnix, endDateUnix } = dateRangeRequestsUsageOverviewFilter;

    return [...requestsToAccept.values()].filter(
      ({ newEvent: { requestDateTimeDetails } }) =>
        requestDateTimeDetails.dates[0] >= startDateUnix && requestDateTimeDetails.dates[0] <= endDateUnix,
    );
  },
});

export const parsedRequestsUsageOverviewSelector = selector<ParsedRequestsUsageOverviewMap | null>({
  key: 'parsedRequestsUsageOverview',
  get: ({ get }) => {
    const requestOverview = get(requestsUsageOverviewAtom);
    const employees = get(parsedEmployeesSelector);
    const timeOffTypes = get(timeOffTypesDictionarySelector);
    const customRequestTypes = get(customRequestTypesDictionarySelector);
    const requestsToAccept = get(filteredRequestsToAcceptByRequestsUsageOverviewDatesSelector);

    if (!requestOverview || !employees || !timeOffTypes || !customRequestTypes || !requestsToAccept) return null;

    const parsedRequestOverview: ParsedRequestsUsageOverviewMap = new Map();

    requestOverview.forEach((overviewElement) => {
      const employee = employees.get(overviewElement.personId);

      if (employee) {
        const { id, avatarUrl, name, isActive, role, tags, isHidden, invitationState, editablePermissions } = employee;
        const { personId, requests, ...rest } = overviewElement;
        const timeOffTypeRequests = requests
          .filter(({ typeId }) => timeOffTypes[typeId])
          .map((request) => ({
            ...request,
            isPending: requestsToAccept.some(
              (req) =>
                req.newEvent.eventDetails.typeId === request.typeId &&
                req.state === RequestState.Pending &&
                req.employee.id === overviewElement.personId,
            ),
          }));
        const customTypeRequests = requests
          .filter(({ typeId }) => customRequestTypes[typeId])
          .map((request) => ({
            ...request,
            isPending: requestsToAccept.some(
              (req) =>
                req.newEvent.eventDetails.typeId === request.typeId &&
                req.state === RequestState.Pending &&
                req.employee.id === overviewElement.personId,
            ),
          }));
        const isBusinessTravelsPending = requestsToAccept.some(
          (req) => req.type === RequestFormType.BusinessTrip && req.employee.id === overviewElement.personId,
        );
        const isSwapRequestPending = requestsToAccept.some(
          (req) => req.type === RequestFormType.Schedule && req.employee.id === overviewElement.personId,
        );
        const isTimeEventsPending = requestsToAccept.some(
          (req) => req.type === RequestFormType.TimeTracking && req.employee.id === overviewElement.personId,
        );

        const parsedElement = {
          ...rest,
          avatarUrl,
          name,
          isActive,
          isHidden,
          invitationState,
          role,
          tags,
          id,
          timeOffTypeRequests,
          customTypeRequests,
          editablePermissions,
          isBusinessTravelsPending,
          isSwapRequestPending,
          isTimeEventsPending,
        };

        parsedRequestOverview.set(personId, parsedElement);
      }
    });

    return parsedRequestOverview;
  },
});

const requestOverviewSearchFilterValueAtom = atom<string>({
  key: 'requestOverviewSearchFilterValue',
  default: '',
});

export const requestOverviewSearchFilterValueSelector = selector<string>({
  key: 'requestOverviewSearchFilterValueSelector',
  get: ({ get }) => get(requestOverviewSearchFilterValueAtom),
  set: ({ set, get }, newValue) => {
    if (isRecoilDefaultValue(newValue)) {
      return;
    }
    const searchFilterValue = get(requestOverviewSearchFilterValueAtom);

    if (
      getStringWithReducedWhiteSpaces(newValue.replaceAll(',', '')) !==
      getStringWithReducedWhiteSpaces(searchFilterValue.replaceAll(',', ''))
    ) {
      set(requestOverviewSearchFilterValueAtom, newValue);
    }
  },
});

export const parsedRequestOverviewSearchFilterValueSelector = selector<string[]>({
  key: 'parsedRequestOverviewSearchFilterValue',
  get: ({ get }) =>
    getStringWithReducedWhiteSpaces(get(requestOverviewSearchFilterValueAtom))
      .trim()
      .split(',')
      .map((v) => v.trim())
      .filter((v) => !!v),
});

export const requestOverviewLimitedRequestsFilterAtom = atom<boolean>({
  key: 'requestOverviewLimitedRequestsFilter',
  default: false,
});

const getRequestOverviewFilterGroupStateAtomDefaultValue = (
  groupName: GroupName,
): RecoilState<FilterGroupState> | [DateRangeFilters.PAY_PERIOD] | null => {
  if (groupName === FilterGroupNames.TEAMMATES) {
    return filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES);
  }

  return null;
};

export const requestOverviewFilterGroupStateAtomFamily = atomFamily<FilterGroupState, GroupName>({
  key: 'requestOverviewFilterGroupState',
  default: getRequestOverviewFilterGroupStateAtomDefaultValue,
});

export const requestOverviewIsActiveFilterSelectorFamily = selectorFamily<boolean, IsActiveFilterConfig>({
  key: 'requestOverviewIsActiveFilter',
  get:
    ({ groupName, filterId }) =>
    ({ get }) => {
      const filterGroupState = get(requestOverviewFilterGroupStateAtomFamily(groupName));

      if (filterGroupState === null) {
        return filterGroupState === filterId;
      }
      if (filterId === null) return false;

      return filterGroupState.includes(filterId);
    },
  set:
    ({ groupName, filterId }) =>
    ({ set, get }, newValue) => {
      const filterGroupStateAtom = requestOverviewFilterGroupStateAtomFamily(groupName);
      const filterGroupState = get(filterGroupStateAtom);

      if (!newValue) {
        if (filterId === null) {
          return;
        }

        if (filterGroupState === null) {
          set(filterGroupStateAtom, [filterId]);
          return;
        }

        const newFilterGroupState = filterGroupState.filter((id) => id !== filterId);
        set(filterGroupStateAtom, newFilterGroupState.length ? newFilterGroupState : null);
        return;
      }

      if (filterId === null) {
        set(filterGroupStateAtom, filterId);
        return;
      }

      if (typeof filterGroupState === 'string' || filterGroupState === null) {
        set(filterGroupStateAtom, [filterId]);
        return;
      }

      set(filterGroupStateAtom, [...filterGroupState, filterId]);
    },
});

export const usageOverviewColumnsHiddenByViewSettingsSelector = selector({
  key: 'usageOverviewColumnsHiddenByViewSettings',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    if (!userSession?.viewsSettings?.requestsUsageOverview) return [];
    return userSession.viewsSettings.requestsUsageOverview.hiddenColumns;
  },
});

export const filteredParsedRequestsUsageOverviewSelector = selector<ParsedRequestsUsageOverviewMap | null>({
  key: 'filteredParsedRequestsUsageOverview',
  get: ({ get }) => {
    const requestOverview = get(parsedRequestsUsageOverviewSelector);
    const filteredEmployees = get(filteredEmployeesSelector);
    const hideUsersWithoutDataFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.HIDE_USERS_WITHOUT_DATA));
    const hideTypesWithoutLimitsFilterState = get(
      filterGroupStateAtomFamily(FilterGroupNames.HIDE_TYPES_WITHOUT_LIMITS),
    );
    const hideEmptyColumnsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.HIDE_EMPTY_COLUMNS));
    const requestTypesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.REQUEST_TYPES));
    const timeOffTypesDictionary = get(timeOffTypesDictionarySelector);
    const customRequestTypesDictionary = get(customRequestTypesDictionarySelector);

    const columnsHiddenByViewSettings = get(usageOverviewColumnsHiddenByViewSettingsSelector);

    if (!requestOverview) {
      return null;
    }

    const filterByTeammates = (employeeId: string) => {
      let valid = false;

      if (filteredEmployees.has(employeeId)) {
        valid = true;
        return valid;
      }

      return valid;
    };

    const filterByUsersWithoutData = (employeeId: string) => {
      let valid = false;

      if (hideUsersWithoutDataFilterState === null) {
        valid = true;
        return valid;
      }
      if (hideUsersWithoutDataFilterState.includes(HideUsersWithoutDataFilter.IsActive)) {
        const usageOverview = requestOverview.get(employeeId);

        if (!usageOverview) {
          valid = false;
          return valid;
        }

        const { timeOffTypeRequests, customTypeRequests, swapRequestCount, businessTravelsCount, timeEventsCount } =
          usageOverview;

        let filteredTimeOffTypeRequests = timeOffTypeRequests;
        let filteredCustomTypeRequests = customTypeRequests;

        if (columnsHiddenByViewSettings.length) {
          filteredTimeOffTypeRequests = filteredTimeOffTypeRequests.filter(
            (timeOff) => !columnsHiddenByViewSettings.includes(timeOff.typeId),
          );
          filteredCustomTypeRequests = filteredCustomTypeRequests.filter(
            (customRequest) => !columnsHiddenByViewSettings.includes(customRequest.typeId),
          );
        }

        const hideColumnsWithoutData = hideEmptyColumnsFilterState?.includes(HideEmptyColumnsFilter.IsActive);
        const hideColumnsWithoutLimits = hideTypesWithoutLimitsFilterState?.includes(
          HideTypesWithoutLimitsFilter.IsActive,
        );
        if (hideColumnsWithoutLimits || hideColumnsWithoutData) {
          filteredTimeOffTypeRequests = filteredTimeOffTypeRequests.filter(
            ({ typeId, usageTimeDuration, totalTimeDuration, limit }) => {
              const isLimitable = timeOffTypesDictionary?.[typeId]?.isLimitable && limit;
              const hasTimeData = usageTimeDuration || totalTimeDuration;
              if (hideColumnsWithoutLimits && hideColumnsWithoutData) return isLimitable && hasTimeData;
              if (hideColumnsWithoutLimits) return isLimitable;
              if (hideColumnsWithoutData) return hasTimeData;
              return false;
            },
          );
          filteredCustomTypeRequests = filteredCustomTypeRequests.filter(
            ({ typeId, limit, usageTimeDuration, totalTimeDuration }) => {
              const isLimitable = customRequestTypesDictionary?.[typeId]?.isLimitable && limit;
              const hasTimeData = usageTimeDuration || totalTimeDuration;
              if (hideColumnsWithoutLimits && hideColumnsWithoutData) return isLimitable && hasTimeData;
              if (hideColumnsWithoutLimits) return isLimitable;
              if (hideColumnsWithoutData) return hasTimeData;
              return false;
            },
          );
        }
        const isTimeOffsEmpty =
          !requestTypesFilterState || requestTypesFilterState?.includes(`${RequestFormType.TimeOff}`)
            ? filteredTimeOffTypeRequests.every((timeOff) => !timeOff.usageTimeDuration && !timeOff.totalTimeDuration)
            : true;
        const isCustomsEmpty =
          !requestTypesFilterState || requestTypesFilterState?.includes(`${RequestFormType.Custom}`)
            ? filteredCustomTypeRequests.every((custom) => !custom.usageTimeDuration && !custom.totalTimeDuration)
            : true;
        const isSwapRequestCountEmpty =
          !requestTypesFilterState || requestTypesFilterState?.includes(`${RequestFormType.Schedule}`)
            ? !swapRequestCount
            : true;
        const isBusinessTravelsCountEmpty =
          !requestTypesFilterState || requestTypesFilterState?.includes(`${RequestFormType.BusinessTrip}`)
            ? !businessTravelsCount
            : true;
        const isTimeEventsCountEmpty =
          !requestTypesFilterState || requestTypesFilterState?.includes(`${RequestFormType.TimeTracking}`)
            ? !timeEventsCount
            : true;

        const isEmployeeDataEmpty =
          isTimeOffsEmpty &&
          isCustomsEmpty &&
          isTimeEventsCountEmpty &&
          isBusinessTravelsCountEmpty &&
          isSwapRequestCountEmpty;

        valid = !isEmployeeDataEmpty;
      }

      return valid;
    };

    const filteredMap = new Map(requestOverview);

    requestOverview.forEach((_element, employeeId) => {
      let valid = false;

      valid = filterByTeammates(employeeId) && filterByUsersWithoutData(employeeId);

      if (!valid) filteredMap.delete(employeeId);
    });

    return filteredMap;
  },
});

export const filteredTimeOffCustomTypesArraySelector = selector<{
  filteredTimeOffs: TimeOffType[];
  filteredCustomRequests: RequestType[];
}>({
  key: 'filteredTimeOffCustomTypesArray',
  get: ({ get }) => {
    const requestsUsageOverview = get(filteredParsedRequestsUsageOverviewSelector);
    const timeOffTypesDictionary = get(timeOffTypesDictionarySelector);
    const customRequestTypesDictionary = get(customRequestTypesDictionarySelector);
    const hideTypesWithoutLimitsFilterState = get(
      filterGroupStateAtomFamily(FilterGroupNames.HIDE_TYPES_WITHOUT_LIMITS),
    );
    const hideEmptyColumnsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.HIDE_EMPTY_COLUMNS));
    const columnsHiddenByViewSettings = get(usageOverviewColumnsHiddenByViewSettingsSelector);

    if (!timeOffTypesDictionary || !requestsUsageOverview) return { filteredTimeOffs: [], filteredCustomRequests: [] };

    let timeOffTypesDictionaryArray = _.toArray(timeOffTypesDictionary);
    let customRequestTypesDictionaryArray = _.toArray(customRequestTypesDictionary);

    if (columnsHiddenByViewSettings.length) {
      timeOffTypesDictionaryArray = timeOffTypesDictionaryArray.filter(
        (timeOff) => !columnsHiddenByViewSettings.includes(timeOff.id),
      );
      customRequestTypesDictionaryArray = customRequestTypesDictionaryArray.filter(
        (customRequest) => !columnsHiddenByViewSettings.includes(customRequest.id),
      );
    }

    if (!hideTypesWithoutLimitsFilterState && !hideEmptyColumnsFilterState) {
      return {
        filteredTimeOffs: timeOffTypesDictionaryArray,
        filteredCustomRequests: customRequestTypesDictionaryArray,
      };
    }

    if (
      hideTypesWithoutLimitsFilterState &&
      hideTypesWithoutLimitsFilterState.includes(HideTypesWithoutLimitsFilter.IsActive)
    ) {
      timeOffTypesDictionaryArray = timeOffTypesDictionaryArray.filter((timeOff) => {
        if (!timeOff.isLimitable) return false;

        const isLimitSetForAnyEmployee = [...requestsUsageOverview.values()].find((req) => {
          const { timeOffTypeRequests } = req;

          const request = timeOffTypeRequests.find((usage) => usage.typeId === timeOff.id);

          return !!request?.totalTimeDuration;
        });

        return isLimitSetForAnyEmployee;
      });
      customRequestTypesDictionaryArray = customRequestTypesDictionaryArray.filter((customRequest) => {
        if (!customRequest.isLimitable) return false;

        const isLimitSetForAnyEmployee = [...requestsUsageOverview.values()].find((req) => {
          const { customTypeRequests } = req;

          const request = customTypeRequests.find((usage) => usage.typeId === customRequest.id);

          return !!request?.totalTimeDuration;
        });

        return isLimitSetForAnyEmployee;
      });
    }

    if (hideEmptyColumnsFilterState) {
      timeOffTypesDictionaryArray = timeOffTypesDictionaryArray.filter((timeOff) => {
        const isTypeEmptyForEveryEmployee = [...requestsUsageOverview.values()].every((req) => {
          const { timeOffTypeRequests } = req;

          const request = timeOffTypeRequests.find((usage) => usage.typeId === timeOff.id);

          return !request?.usageTimeDuration && !request?.totalTimeDuration;
        });

        return !isTypeEmptyForEveryEmployee;
      });
      customRequestTypesDictionaryArray = customRequestTypesDictionaryArray.filter((customRequest) => {
        const isTypeEmptyForEveryEmployee = [...requestsUsageOverview.values()].every((req) => {
          const { customTypeRequests } = req;

          const request = customTypeRequests.find((usage) => usage.typeId === customRequest.id);

          return !request?.usageTimeDuration && !request?.totalTimeDuration;
        });

        return !isTypeEmptyForEveryEmployee;
      });
    }

    return { filteredTimeOffs: timeOffTypesDictionaryArray, filteredCustomRequests: customRequestTypesDictionaryArray };
  },
});

export const hiddenColumnKeysRequestsOverviewUsage: Record<
  'timeOffTypeRequests' | 'customTypeRequests' | 'businessTravelsCount' | 'timeEventsCount' | 'swapRequestCount',
  ListColumn<ParsedRequestOverviewElement>['key']
> = {
  timeOffTypeRequests: 'timeOffTypeRequests',
  customTypeRequests: 'customTypeRequests',
  businessTravelsCount: ['businessTravelsCount', 'isBusinessTravelsPending'],
  timeEventsCount: 'timeEventsCount',
  swapRequestCount: 'swapRequestCount',
};

export const requestsUsageOverviewHiddenColumnsSelector = selector<ListColumn<ParsedRequestOverviewElement>['key'][]>({
  key: 'requestsUsageOverviewHiddenColumns',
  get: ({ get }) => {
    const requestTypesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.REQUEST_TYPES));
    const hiddenColumns: ListColumn<ParsedRequestOverviewElement>['key'][] = [];
    const hideEmptyColumnsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.HIDE_EMPTY_COLUMNS));
    const requestsUsageOverview = get(filteredParsedRequestsUsageOverviewSelector);
    const columnsHiddenByViewSettings = get(usageOverviewColumnsHiddenByViewSettingsSelector);

    if (columnsHiddenByViewSettings.includes(`${hiddenColumnKeysRequestsOverviewUsage.timeEventsCount}`)) {
      hiddenColumns.push('timeEventsCount');
    }
    if (columnsHiddenByViewSettings.includes(`${hiddenColumnKeysRequestsOverviewUsage.businessTravelsCount[0]}`)) {
      hiddenColumns.push(hiddenColumnKeysRequestsOverviewUsage.businessTravelsCount);
    }
    if (columnsHiddenByViewSettings.includes(`${hiddenColumnKeysRequestsOverviewUsage.swapRequestCount}`)) {
      hiddenColumns.push('swapRequestCount');
    }

    if (requestTypesFilterState) {
      const pickedTypes: string[] = [];
      const allTypes = [
        `${RequestFormType.TimeOff}`,
        `${RequestFormType.Custom}`,
        `${RequestFormType.BusinessTrip}`,
        `${RequestFormType.TimeTracking}`,
        `${RequestFormType.Schedule}`,
      ];
      requestTypesFilterState.forEach((requestType) => {
        pickedTypes.push(requestType);
      });

      const typesToHide = allTypes.filter((requestType) => !pickedTypes.includes(requestType));

      if (typesToHide.includes(`${RequestFormType.TimeOff}`)) {
        hiddenColumns.push(hiddenColumnKeysRequestsOverviewUsage.timeOffTypeRequests);
      }
      if (typesToHide.includes(`${RequestFormType.Custom}`)) {
        hiddenColumns.push(hiddenColumnKeysRequestsOverviewUsage.customTypeRequests);
      }
      if (typesToHide.includes(`${RequestFormType.BusinessTrip}`)) {
        hiddenColumns.push(hiddenColumnKeysRequestsOverviewUsage.businessTravelsCount);
      }
      if (typesToHide.includes(`${RequestFormType.TimeTracking}`)) {
        hiddenColumns.push(hiddenColumnKeysRequestsOverviewUsage.timeEventsCount);
      }
      if (typesToHide.includes(`${RequestFormType.Schedule}`)) {
        hiddenColumns.push(hiddenColumnKeysRequestsOverviewUsage.swapRequestCount);
      }
    }

    if (hideEmptyColumnsFilterState && requestsUsageOverview) {
      const requests = [...requestsUsageOverview.values()];

      const isEmpty = {
        businessTravelsCount: requests.every((req) => req.businessTravelsCount < 1),
        swapRequestCount: requests.every((req) => req.swapRequestCount < 1),
        timeEventsCount: requests.every((req) => req.timeEventsCount < 1),
      };

      if (
        isEmpty.businessTravelsCount &&
        !hiddenColumns.includes(hiddenColumnKeysRequestsOverviewUsage.businessTravelsCount)
      ) {
        hiddenColumns.push(hiddenColumnKeysRequestsOverviewUsage.businessTravelsCount);
      }
      if (isEmpty.swapRequestCount && !hiddenColumns.includes(hiddenColumnKeysRequestsOverviewUsage.swapRequestCount)) {
        hiddenColumns.push(hiddenColumnKeysRequestsOverviewUsage.swapRequestCount);
      }
      if (isEmpty.timeEventsCount && !hiddenColumns.includes(hiddenColumnKeysRequestsOverviewUsage.timeEventsCount)) {
        hiddenColumns.push(hiddenColumnKeysRequestsOverviewUsage.timeEventsCount);
      }
    }

    return hiddenColumns;
  },
});

// Type Details
export type RequestsTypeDetailsParams = {
  type: Request['type'];
  personId: ParsedRequest['employee']['id'];
  typeId?: Request['newEvent']['eventDetails']['typeId'];
};

export const requestsTypeDetailsAtomFamily = atomFamily<
  RequestGetForPersonForRangeAndTypeResponse | null,
  RequestsTypeDetailsParams
>({
  key: 'requestsTypeDetails',
  default: null,
});

export const parsedRequestsTypeDetailsSelectorFamily = selectorFamily<
  ParsedRequest[] | null,
  RequestsTypeDetailsParams
>({
  key: 'parsedRequestsTypeDetails',
  get:
    ({ type, typeId, personId }) =>
    ({ get }) => {
      const requestsTypeDetails = get(requestsTypeDetailsAtomFamily({ type, typeId, personId }));
      const employees = get(parsedEmployeesSelector);

      if (!requestsTypeDetails || !employees) {
        return null;
      }

      const parsedRequestsTypeDetails: ParsedRequest[] = [];

      requestsTypeDetails.forEach((request) => {
        const newTypeId = request.newEvent?.eventDetails.typeId;
        const originalTypeId = request.originalEvent?.eventDetails.typeId;
        const { personId: requestPersonId, newEvent, originalEvent } = request;

        const employee = employees.get(requestPersonId);
        const newRequestType = get(getParsedRequestTypeSelectorFamily(newTypeId));
        const originalRequestType = get(getParsedRequestTypeSelectorFamily(originalTypeId));

        if (!employee) {
          return;
        }

        const parsedOriginalEvent = originalEvent && { ...originalEvent, requestType: originalRequestType };
        const parsedNewEvent = newEvent && { ...newEvent, requestType: newRequestType };

        const parsedRequest = {
          ..._.omit(request, ['personId', 'newEvent', 'originalEvent']),
          employee,
          ...(parsedOriginalEvent && parsedNewEvent && { originalEvent: parsedOriginalEvent }),
          ...(parsedOriginalEvent && !parsedNewEvent
            ? { newEvent: parsedOriginalEvent }
            : { newEvent: parsedNewEvent }),
        };

        parsedRequestsTypeDetails.push(parsedRequest);
      });

      const sortedParsedRequestsTypeDetails = _.orderBy(parsedRequestsTypeDetails, 'createDateTimeUnix', 'desc');

      return sortedParsedRequestsTypeDetails;
    },
});

/* 
***********************************************

      REQUEST DETAILS

***********************************************
*/
export const requestDetailsAtom = atomFamily<FetchRequestDetailsResponse | null, number | string>({
  key: 'requestDetails',
  default: null,
});

export const parsedRequestDetailsAtom = atomFamily<ParsedRequest | null, number | string>({
  key: 'parsedRequestDetails__atom',
  default: null,
});

export const parsedRequestDetailsSelector = selectorFamily<ParsedRequest | null, number>({
  key: 'parsedRequestDetails__selector',
  get:
    (id) =>
    ({ get }) => {
      const requestDetails = get(requestDetailsAtom(id));
      const parsedRequestDetails = get(parsedRequestDetailsAtom(id));
      const employees = get(parsedEmployeesSelector);

      if (parsedRequestDetails) return parsedRequestDetails;

      if (!requestDetails || !employees) return null;

      const { newEvent, originalEvent, personId } = requestDetails;

      const employee = employees.get(personId);

      if (!employee) return null;

      const originalRequestType = get(getParsedRequestTypeSelectorFamily(originalEvent?.eventDetails.typeId));
      const newRequestType = get(getParsedRequestTypeSelectorFamily(newEvent?.eventDetails.typeId));
      const parsedOriginalEvent = originalEvent && { ...originalEvent, requestType: originalRequestType };
      const parsedNewEvent = newEvent && { ...newEvent, requestType: newRequestType };

      // We do this event swap because of event remove request. When we want to remove event by request we only get originalEvent.
      const parsedDetails = {
        ..._.omit(requestDetails, ['personId', 'newEvent', 'originalEvent']),
        ...(parsedOriginalEvent && parsedNewEvent && { originalEvent: parsedOriginalEvent }),
        ...(parsedOriginalEvent && !parsedNewEvent ? { newEvent: parsedOriginalEvent } : { newEvent: parsedNewEvent }),
      };

      return {
        ...parsedDetails,
        employee,
      };
    },
});

export const requestDetailsDateDetailsSelector = selectorFamily<DateTimeDetails | null, number>({
  key: 'requestDetailsDateDetails',
  get:
    (id) =>
    ({ get }) => {
      const requestDetails = get(parsedRequestDetailsSelector(id));

      if (!requestDetails) return null;

      const { newEvent } = requestDetails;

      if (!newEvent) {
        return null;
      }

      const { requestDateTimeDetails } = newEvent;

      return requestDateTimeDetails;
    },
});

export type RequestDetailsState = Partial<{
  requestNumber: string;
  state: RequestState;
  isDeleted: boolean;
}>;

export const requestDetailsStateAtom = atomFamily<RequestDetailsState | null, number>({
  key: 'requestDetailsState',
  default: null,
});

/* 
***********************************************

      HISTORY ATOM, SELECTORS

***********************************************
*/

// History Atom
export const requestHistoryAtom = atom<RequestGetToUpdateResponse | null>({
  key: 'requestHistoryAtom',
  default: null,
});

// Maps with parsed data selectors
export type ParsedEventToUpdate = RequestToUpdate & { id: string; requestType?: ParsedRequestType };

export const parsedRequestsHistoryMapSelector = selector<Map<string, ParsedEventToUpdate>>({
  key: 'parsedRequestsHistoryMapSelector',
  get: ({ get }) => {
    const history = get(requestHistoryAtom);

    const parsedMap = new Map<string, ParsedEventToUpdate>();

    if (!history) return parsedMap;

    history.forEach(({ eventUpdateDetails, ...rest }) => {
      const { typeId, id } = eventUpdateDetails;
      const requestType = get(getParsedRequestTypeSelectorFamily(typeId));

      parsedMap.set(id, {
        ...rest,
        id,
        eventUpdateDetails,
        requestType: requestType || undefined,
      });
    });

    return parsedMap;
  },
});

export const selectedRequestToEditRemoveAtom = atom<string | null>({
  key: 'selectedRequestToEditRemove',
  default: null,
});

export const selectedParsedRequestToEditSelector = selector<ParsedEventToUpdate | null>({
  key: 'selectedParsedRequestsHistoryDataSelector',
  get: ({ get }) => {
    const requestsHistory = get(parsedRequestsHistoryMapSelector);

    const selectedId = get(selectedRequestToEditRemoveAtom);

    if (!selectedId) return null;

    return requestsHistory.get(selectedId) || null;
  },
});

/* 
***********************************************

      ADD REQUEST

***********************************************
*/
export enum Step {
  SelectTeammates = 1,
  SelectType = 2,
  Forms = 3,
}

export type AddRequestState = {
  requestType: RequestFormType;
  step: Step;
  loading: boolean;
  isSaveDisabled: boolean;
};

export const addRequestStateAtom = atom<AddRequestState>({
  key: 'addRequestState',
  default: {
    requestType: RequestFormType.TimeOff,
    step: Step.SelectTeammates,
    loading: false,
    isSaveDisabled: true,
  },
});

export type AddRequestFormStepState = {
  mainFormVisible: boolean;
  noteVisible: boolean;
  attachmentsVisible: boolean;
  requestAcceptationSelect: FetchApprovalListsResponse;
};

export const addRequestFormStepStateAtom = atom<AddRequestFormStepState>({
  key: 'addRequestFormState',
  default: {
    mainFormVisible: false,
    noteVisible: false,
    attachmentsVisible: false,
    requestAcceptationSelect: {
      firstStepPeopleIds: [],
      secondStepPeopleIds: undefined,
    },
  },
});

export const defaultDateUnixAtom = atom<number | null>({
  key: 'defaultDateUnix',
  default: null,
});

export type RequestConfig = {
  action?: RequestActionType;
  hideActionSelect?: boolean;
  employeesIds?: string[];
  excludedSteps?: Step[];
  defaultValues?: Partial<AddRequestFormState>;
  requestManagement?: {
    id: string;
    isEvent?: boolean;
  };
  requestType?: RequestFormType;
};

export const requestConfigStateAtom = atom<RequestConfig | null>({
  key: 'requestConfigState',
  default: null,
});

export const modifyRequestDataAtom = atom<RequestToUpdate | null>({
  key: 'modifyRequestData',
  default: null,
});

export const parsedModifyRequestDataSelector = selector<ParsedEventToUpdate | null>({
  key: 'parsedModifyRequestData',
  get: ({ get }) => {
    const modifyRequestData = get(modifyRequestDataAtom);

    if (!modifyRequestData) {
      return null;
    }

    const {
      eventUpdateDetails: { id, typeId },
    } = modifyRequestData;

    const requestType = get(getParsedRequestTypeSelectorFamily(typeId)) || undefined;

    return {
      ...modifyRequestData,
      id,
      requestType,
    };
  },
});

export const getRequestFormValuesAtom = atom<UseFormGetValues<AddRequestFormState> | null>({
  key: 'getRequestFormValues',
  default: null,
});

export const requestFormRestoredAtom = atom<{
  defaultValuesRestored: boolean;
  editRequestIdRestored: boolean;
  editRequestRestored: boolean;
}>({
  key: 'requestFormRestored',
  default: {
    defaultValuesRestored: false,
    editRequestIdRestored: false,
    editRequestRestored: false,
  },
});

// ********************************************
/* 
***********************************************

      REQUEST LIMITS

***********************************************
*/
export const requestLimitsAtom = atom<RemainingLimit | null>({
  key: 'requestLimits',
  default: null,
});

export const requestLimitsLoadingAtom = atom<boolean>({
  key: 'requestLimitsLoading',
  default: false,
});
// ********************************************
export const requestsColumns = atom<StickyListProps<ParsedRequest>['columns'] | null>({
  key: 'requests_columns',
  default: null,
});
export const requestsUsageOverviewColumns = atom<StickyListProps<ParsedRequestOverviewElement>['columns'] | null>({
  key: 'requests_usage_overview_columns',
  default: null,
});

// REQUESTS VIEW SETTINGS
export const requestsViewSettingsSelector = selector({
  key: 'requests_view_settings',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);

    if (!userSession || !userSession.viewsSettings || !userSession.viewsSettings.requests) {
      return [];
    }

    return userSession.viewsSettings.requests.hiddenColumns;
  },
});
