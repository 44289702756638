import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  AddTagActionProps,
  AddTagResponse,
  EditTagDetailsResponse,
  EditTagDetailsActionProps,
  FetchTagDetailsActionProps,
  FetchTagDetailsResponse,
  DeleteTagsActionProps,
  DeleteTagsResponse,
} from './tagsActions.types';

export function fetchTagDetailsAction({ tagId }: FetchTagDetailsActionProps): Action<FetchTagDetailsResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.tag}/${tagId}`,
  };
}

export function editTagDetailsAction({ tagId, tag }: EditTagDetailsActionProps): Action<EditTagDetailsResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.tagManagement}/${tagId}`,
    body: tag,
  };
}

export function addTagAction(body: AddTagActionProps): Action<AddTagResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.tagManagement,
    body,
  };
}

export function deleteTagsAction(body: DeleteTagsActionProps): Action<DeleteTagsResponse> {
  return {
    method: 'DELETE',
    endpoint: API_ENDPOINTS.tagManagement,
    body,
  };
}
