import React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

type Props = {
  children: React.ReactElement | React.ReactNode;
  sx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  sx: undefined,
};

export const DurationFancyContainer = ({ sx, children }: Props): React.ReactElement => (
  <Box
    as="span"
    sx={{
      bg: 'rgba(255,255,255, 0.2)',
      borderRadius: 'pill',
      px: 2,
      py: 1,
      fontWeight: '700',
      ...(sx && sx),
    }}
  >
    {children}
  </Box>
);

DurationFancyContainer.defaultProps = defaultProps;
