import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex } from 'theme-ui';

import { DatePicker } from 'components/ui/DatePicker/DatePicker';
import { dualCalendarRegisterOptionsFactory } from 'utils/dualCalendarRegisterOptionsFactory';
import { labelProps } from '../../../commons';
import { useTimeOffFormState } from '../hooks/useTimeOffFormState';

export const ForDayPicker = (): React.ReactElement | null => {
  useLingui();

  const { isForDayType } = useTimeOffFormState();

  const {
    register,
    formState: { errors },
  } = useFormContext<{ dateTimeDetails: { forDay: string } }>();

  if (!isForDayType) return null;

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <DatePicker
        {...register(
          'dateTimeDetails.forDay',
          dualCalendarRegisterOptionsFactory({
            additionalRegisterOptions: { required: t({ id: 'global.forms.required' }) },
            isRange: false,
          }),
        )}
        isStatic
        id="forDay"
        placeholder={t({ id: 'requests.for_day', message: 'For day' })}
        size="sm"
        error={!!errors?.dateTimeDetails?.forDay}
        errorMessage={errors.dateTimeDetails?.forDay?.message}
        label={t({ id: 'requests.for_day.title', message: 'For day' })}
        labelProps={labelProps}
      />
    </Flex>
  );
};
