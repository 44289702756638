import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { appPermissionsSelector } from 'state/appPermissions';
import { userSessionPersonIdSelector } from 'state/userSession';

export const useHideTimeOffTypes = (employeeId?: string) => {
  const currentUserId = useRecoilValue(userSessionPersonIdSelector);
  const { hideTimeOffTypes } = useRecoilValue(appPermissionsSelector);

  const shouldHideTimeOffTypes = useCallback(
    (id: string) => hideTimeOffTypes && currentUserId !== id,
    [hideTimeOffTypes, currentUserId],
  );

  return {
    shouldHideTimeOffTypes,
    hideTimeOffTypes: employeeId ? shouldHideTimeOffTypes(employeeId) : true,
  };
};
