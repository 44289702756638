import { ElementType, useMemo } from 'react';
import { ClientContextProvider, RequestInterceptor, ResponseInterceptor, createClient } from 'react-fetching-library';

import { buildCustomFetch } from 'api/buildCustomFetch';
import { requestAddTimestampUnixInterceptor } from 'api/interceptors/requestAddTimestampUnixInterceptor';
import { requestAuthInterceptor } from 'api/interceptors/requestAuthInterceptor';
import { requestHostInterceptor } from 'api/interceptors/requestHostInterceptor';
import { responseEmitCustomEventsInterceptor } from 'api/interceptors/responseEmitCustomEventsInterceptor';
import { responseErrorInnerCodeInterceptor } from 'api/interceptors/responseErrorInnerCodeInterceptor';
import { responseFileUrlInterceptor } from 'api/interceptors/responseFileUrlInterceptor';
import { responseRefreshAccessTokenInterceptor } from 'api/interceptors/responseRefreshAccessTokenInterceptor';
import { responseRefreshSessionInterceptor } from 'api/interceptors/responseRefreshSessionInterceptor';
import { responseTimeoutExceededInterceptor } from 'api/interceptors/responseTimeoutExceededInterceptor';
import { REACT_APP_API_URL } from 'constants/common';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';

import { ClientProviderProps } from './ClientContextController.types';

const requestInterceptors: RequestInterceptor[] = [];
const responseInterceptors: ResponseInterceptor[] = [];

export const ClientContextController: ElementType = ({ children }: ClientProviderProps) => {
  const dispatch = useAuthDispatch();

  const client = useMemo(
    () =>
      createClient({
        requestInterceptors: [
          ...requestInterceptors,
          requestHostInterceptor(String(REACT_APP_API_URL)),
          requestAuthInterceptor(),
          requestAddTimestampUnixInterceptor(), // this should always be the last interceptor
        ],
        responseInterceptors: [
          ...responseInterceptors,
          responseRefreshAccessTokenInterceptor(dispatch),
          responseRefreshSessionInterceptor(),
          responseErrorInnerCodeInterceptor(),
          responseTimeoutExceededInterceptor(),
          responseFileUrlInterceptor(),
          responseEmitCustomEventsInterceptor(),
        ],
        fetch: buildCustomFetch(),
      }),
    [dispatch],
  );

  return <ClientContextProvider client={client}>{children}</ClientContextProvider>;
};
