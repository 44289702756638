import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import { holidaysAtom, organizationSettingsAtom, pickedHolidayYearAtom, resetFormButtonAtom } from 'state/settings';

export const useOrganizationSettingsCleanUp = () => {
  const resetFormButton = useResetRecoilState(resetFormButtonAtom);
  const resetOrganizationSettings = useResetRecoilState(organizationSettingsAtom);
  const resetHolidays = useResetRecoilState(holidaysAtom);
  const resetPickedHolidayYear = useResetRecoilState(pickedHolidayYearAtom);

  useEffect(
    () => () => {
      resetFormButton();
      resetOrganizationSettings();
      resetHolidays();
      resetPickedHolidayYear();
    },
    [resetFormButton, resetHolidays, resetOrganizationSettings, resetPickedHolidayYear],
  );
};
