import { atom } from 'recoil';

export enum InitialFaceScanSteps {
  START = 'START',
  SCAN = 'SCAN',
  SUMMARY = 'SUMMARY',
}

type InitialFaceScanState = InitialFaceScanSteps;

export const initialFaceScanStepAtom = atom<InitialFaceScanState>({
  key: 'kiosk__initialFaceScanStepState',
  default: InitialFaceScanSteps.START,
});

export const initialFaceScanInPlaceAtom = atom<boolean>({
  key: 'kiosk__initialFaceScanInPlace',
  default: false,
});
