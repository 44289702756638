import { useContext } from 'react';

import { AuthStateContextProps } from '../../context/auth/authContext/AuthContext.types';
import { AuthStateContext } from '../../context/auth/authContext/AuthContext';

/**
 * Retrieves the AuthState from AuthStateContext.
 *
 * @return {AuthStateContextProps} Returns the auth state.
 *
 * @see authReducer
 * @see authActionCreators.ts
 * @see AuthContextController
 */

export const useAuthState: () => AuthStateContextProps = () => {
  const context = useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAuthState must be used within an AuthContextController');
  }

  return context;
};
