/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { t } from '@lingui/macro';
import _ from 'lodash';
import { UseFormGetValues } from 'react-hook-form';
import { atom, selector } from 'recoil';
import { v1 as uuidv1 } from 'uuid';

import {
  AdvancedEmployeeInfo,
  EmployeeInfo,
  FetchEmployeeDetailsResponse,
  FetchTagInheritedFeaturesResponse,
  PayRate,
  TimeOffLimitsGroup,
} from 'api/actions/employees/employeesActions.types';
import {
  DefaultRole,
  RequestLimit,
  Role,
  Tag,
  TimeOffLimit,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { TeamHideableColumns } from 'api/actions/userSession/userSessionActions.types';
import { InputOption } from 'components/ui/Select/Select';
import { DEFAULT_ROLES_IDS } from 'constants/defaults';
import { CURRENT_YEAR } from 'constants/team';
import { dateTime } from 'utils/dateTime';
import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';

import { rolesSelector, tagsSelector, workPositionsSelector } from './employees';
import { organizationDetailsSelector, organizationSessionAtom } from './organizationSession';
import { languageSelector } from './recoilState';
import { userSessionAtom } from './userSession';

export const hiddenColumnsTeamViewSelector = selector({
  key: 'hiddenColumnsTeamView',
  get: ({ get }) => {
    const defaultValue = [
      TeamHideableColumns.customEmployeeId,
      TeamHideableColumns.email,
      TeamHideableColumns.phoneNumber,
      TeamHideableColumns.identity,
    ];
    const userSession = get(userSessionAtom);
    const hiddenColumns = userSession?.viewsSettings?.team?.hiddenColumns;

    return hiddenColumns || defaultValue;
  },
});

type EditedEmployeeDetails = Omit<
  FetchEmployeeDetailsResponse,
  'defaultWorkPositionId' | 'additionalWorkPositionsIds'
> & {
  defaultWorkPosition: FetchEmployeeDetailsResponse['defaultWorkPositionId'];
  additionalWorkPositions?: FetchEmployeeDetailsResponse['additionalWorkPositionsIds'];
};

export const editedEmployeeDetailsAtom = atom<EditedEmployeeDetails | null>({
  key: 'editedEmployeeDetailsState',
  default: null,
});
export const editEmployeeFormStateAtom = atom<FetchEmployeeDetailsResponse | null>({
  key: 'editEmployeeFormState',
  default: null,
});

export type ProfileFormState = Pick<EmployeeInfo, 'phoneNumber' | 'email' | 'firstName' | 'surname'> &
  Pick<FetchEmployeeDetailsResponse, 'tagsIds' | 'roleId'> & {
    defaultWorkPosition: FetchEmployeeDetailsResponse['defaultWorkPositionId'];
    additionalWorkPositions?: FetchEmployeeDetailsResponse['additionalWorkPositionsIds'];
  };

export const profileFormStateSelector = selector<ProfileFormState | null>({
  key: 'profileFormState',
  get: ({ get }) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails) return null;

    const {
      employeeInfo: { firstName, surname, phoneNumber, email, note },
      tagsIds,
      roleId,
      defaultWorkPosition,
      additionalWorkPositions,
    } = editedEmployeeDetails;

    return {
      firstName,
      surname,
      phoneNumber,
      email,
      note,
      tagsIds,
      roleId,
      defaultWorkPosition,
      additionalWorkPositions,
    };
  },
  set: ({ set, get }, newValue) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails || !newValue || isRecoilDefaultValue(newValue)) return;
    const { firstName, surname, phoneNumber, email, tagsIds, roleId, defaultWorkPosition, additionalWorkPositions } =
      newValue;
    set(editedEmployeeDetailsAtom, {
      ...editedEmployeeDetails,
      employeeInfo: {
        ...editedEmployeeDetails.employeeInfo,
        firstName,
        surname,
        phoneNumber,
        email,
      },
      tagsIds,
      roleId,
      defaultWorkPosition,
      additionalWorkPositions,
    });
  },
});

export type EmployeeAdvancedDetailsFormState = Pick<
  AdvancedEmployeeInfo,
  'features' | 'hideOnList' | 'visibilityLevel' | 'visibilityLevelInheritedFromTag'
>;
export const employeeAdvancedDetailsFormStateSelector = selector<EmployeeAdvancedDetailsFormState | null>({
  key: 'employeeAdvancedDetailsFormState',
  get: ({ get }) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails) return null;

    const {
      advancedEmployeeInfo: { features, hideOnList, visibilityLevel, visibilityLevelInheritedFromTag },
    } = editedEmployeeDetails;

    return {
      hideOnList,
      features,
      visibilityLevel,
      visibilityLevelInheritedFromTag,
    };
  },
  set: ({ set, get }, newValue) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails || !newValue || isRecoilDefaultValue(newValue)) return;
    const { advancedEmployeeInfo, ...restEditedEmployeeDetailsAtom } = editedEmployeeDetails;

    set(editedEmployeeDetailsAtom, {
      ...restEditedEmployeeDetailsAtom,
      advancedEmployeeInfo: {
        ...advancedEmployeeInfo,
        ...newValue,
      },
    });
  },
});

export const defaultPayRate: PayRate = {
  startDateUnix: dateTime().unix(),
  id: uuidv1(),
};

export enum EmployeeEmploymentDetailsFieldNames {
  Rates = 'rates',
  WorkdayDurations = 'workdayDurations',
  CustomEmployeeId = 'customEmployeeId',
  Address = 'address',
  Identity = 'identity',
}

export type EmployeeEmploymentDetailsFormState = {
  [EmployeeEmploymentDetailsFieldNames.Rates]: FetchEmployeeDetailsResponse['rates'];
  [EmployeeEmploymentDetailsFieldNames.WorkdayDurations]: FetchEmployeeDetailsResponse['workdayDurations'];
  [EmployeeEmploymentDetailsFieldNames.CustomEmployeeId]: AdvancedEmployeeInfo['customEmployeeId'];
  [EmployeeEmploymentDetailsFieldNames.Address]?: EmployeeInfo['address'];
  [EmployeeEmploymentDetailsFieldNames.Identity]?: AdvancedEmployeeInfo['identity'];
};

export const employeeEmploymentDetailsFormStateSelector = selector<EmployeeEmploymentDetailsFormState | null>({
  key: 'employeeEmploymentDetailsFormState',
  get: ({ get }) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails) return null;

    const {
      workdayDurations,
      rates,
      advancedEmployeeInfo: { customEmployeeId, identity },
      employeeInfo: { address },
    } = editedEmployeeDetails;

    return {
      workdayDurations: _.orderBy(workdayDurations, ({ fromYear }) => fromYear),
      rates: rates?.length ? _.orderBy(rates, ({ startDateUnix }) => startDateUnix) : [defaultPayRate],
      customEmployeeId,
      identity,
      address,
    };
  },
  set: ({ set, get }, newValue) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails || !newValue || isRecoilDefaultValue(newValue)) return;

    const { customEmployeeId, address, identity, ...restNewValue } = newValue;
    const { advancedEmployeeInfo, employeeInfo, ...restEditedEmployeeDetails } = editedEmployeeDetails;
    set(editedEmployeeDetailsAtom, {
      ...restEditedEmployeeDetails,
      ...restNewValue,
      advancedEmployeeInfo: {
        ...advancedEmployeeInfo,
        customEmployeeId,
        identity,
      },
      employeeInfo: {
        ...employeeInfo,
        address,
      },
    });
  },
});

export type ParsedTimeOffLimit = TimeOffLimit & { unlimited?: boolean };
export type ParsedTimeOffLimitsGroup = Omit<TimeOffLimitsGroup, 'limits'> & { limits: ParsedTimeOffLimit[] };

export const defaultTimeOffLimitGroupSelector = selector<ParsedTimeOffLimitsGroup | null>({
  key: 'defaultTimeOffLimitGroup',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { timeOffDefaultLimits } = organizationSession;

    return {
      fromYear: CURRENT_YEAR,
      limits:
        timeOffDefaultLimits.map((limit) => ({
          days: '' as unknown as undefined, // RHF append requires value, this imitates undefined
          ...limit,
          unlimited: _.isNil(limit.days),
        })) || [],
      id: uuidv1(),
    };
  },
});

export type ParsedCustomRequestLimit = RequestLimit & { unlimited?: boolean };
export type ParsedCustomRequestLimitGroup = Omit<TimeOffLimitsGroup, 'limits'> & { limits: ParsedCustomRequestLimit[] };

export const defaultCustomRequestLimitGroupSelector = selector<ParsedCustomRequestLimitGroup | null>({
  key: 'defaultCustomRequestLimitGroup',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { customRequestDefaultLimits } = organizationSession;

    return {
      fromYear: CURRENT_YEAR,
      limits:
        customRequestDefaultLimits.map((limit) => ({
          days: '' as unknown as undefined, // RHF append requires value, this imitates undefined
          ...limit,
          unlimited: _.isNil(limit.days),
        })) || [],
      id: uuidv1(),
    };
  },
});

export enum EmployeeRequestLimitsFieldNames {
  CustomRequestsLimits = 'customRequestsLimits',
  TimeOffLimits = 'timeOffLimits',
}

export type EmployeeRequestLimitsFormState = {
  [EmployeeRequestLimitsFieldNames.CustomRequestsLimits]: ParsedCustomRequestLimitGroup[];
  [EmployeeRequestLimitsFieldNames.TimeOffLimits]: ParsedTimeOffLimitsGroup[];
};

export const employeeRequestLimitsFormStateSelector = selector<EmployeeRequestLimitsFormState | null>({
  key: 'employeeRequestLimitsFormState',
  get: ({ get }) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails) return null;

    const { customRequestsLimits, timeOffLimits } = editedEmployeeDetails;

    const parsedCustomRequestsLimits: ParsedCustomRequestLimitGroup[] = !customRequestsLimits?.length
      ? []
      : customRequestsLimits.map(({ limits, ...rest }) => ({
          ...rest,
          limits: limits.map((limit) => ({ ...limit, unlimited: _.isNil(limit.days) })),
        }));

    const parsedTimeOffLimits: ParsedTimeOffLimitsGroup[] = !timeOffLimits?.length
      ? []
      : timeOffLimits.map(({ limits, ...rest }) => ({
          ...rest,
          limits: limits.map((limit) => ({ ...limit, unlimited: _.isNil(limit.days) })),
        }));

    return {
      customRequestsLimits: _.orderBy(parsedCustomRequestsLimits, ({ fromYear }) => fromYear),
      timeOffLimits: _.orderBy(parsedTimeOffLimits, ({ fromYear }) => fromYear),
    };
  },
  set: ({ set, get }, newValue) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails || !newValue || isRecoilDefaultValue(newValue)) return;

    set(editedEmployeeDetailsAtom, {
      ...editedEmployeeDetails,
      ...newValue,
    });
  },
});

export type PrivateNotesFormState = Pick<EmployeeInfo, 'note'>;
export const privateNotesFormStateSelector = selector<PrivateNotesFormState | null>({
  key: 'privateNotesFormState',
  get: ({ get }) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails) return null;

    const {
      employeeInfo: { note },
    } = editedEmployeeDetails;

    return { note };
  },
  set: ({ set, get }, newValue) => {
    const editedEmployeeDetails = get(editedEmployeeDetailsAtom);
    if (!editedEmployeeDetails || !newValue || isRecoilDefaultValue(newValue)) return;

    const { employeeInfo, ...restEditedEmployeeDetails } = editedEmployeeDetails;
    set(editedEmployeeDetailsAtom, {
      ...restEditedEmployeeDetails,
      employeeInfo: {
        ...employeeInfo,
        ...newValue,
      },
    });
  },
});

export const minSelectableDateUnixSelector = selector<number>({
  key: 'minSelectableDateUnix',
  get: ({ get }) => {
    const organizationDetails = get(organizationDetailsSelector);
    if (!organizationDetails) {
      return 0;
    }
    const { createDateUnix } = organizationDetails;

    const allowedYearsBeforeCreateYearCount = 2;

    const oneYearInSeconds = 31556926;

    const allowedYearsBeforeCreateYearInSeconds = oneYearInSeconds * allowedYearsBeforeCreateYearCount;

    return createDateUnix - allowedYearsBeforeCreateYearInSeconds;
  },
});

export const minSelectableYearSelector = selector<number>({
  key: 'minSelectableYear',
  get: ({ get }) => {
    const minSelectableDateUnix = get(minSelectableDateUnixSelector);
    if (!minSelectableDateUnix) {
      return 2000;
    }

    return dateTime(minSelectableDateUnix).year();
  },
});

export type TagsSelectOption = Pick<Tag, 'color' | 'isInformational'> & InputOption;
export const tagsSelectOptionsSelector = selector<TagsSelectOption[] | null>({
  key: 'tagsSelectOptions',
  get: ({ get }) => {
    const tags = get(tagsSelector);
    if (!tags) return null;

    return _.orderBy(
      tags.map(({ name, id, color, isInformational }) => ({ label: name, id, color, isInformational })),
      ({ label }) => label,
    );
  },
});

export type RolesSelectOption = Pick<Role, 'color' | 'canApproveRequests' | 'canBeAssigned'> & {
  defaultRole?: Role;
} & InputOption;
export const rolesSelectOptionsSelector = selector<RolesSelectOption[] | null>({
  key: 'rolesSelectOptions',
  get: ({ get }) => {
    // this will trigger the selector recomputation on language changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    const roles = get(rolesSelector);
    if (!roles) return null;

    return _.orderBy(
      roles.map(({ name, id, color, defaultRole, canApproveRequests, canBeAssigned }) => ({
        label: t({
          id: name,
        }),
        id,
        color,
        defaultRole: DEFAULT_ROLES_IDS.includes(id) ? undefined : roles.find((role) => role.id === `${defaultRole}`),
        canApproveRequests,
        canBeAssigned,
      })),
      [
        ({ defaultRole }) => !!defaultRole,
        ({ id }) => +id !== DefaultRole.Employee,
        ({ id }) => +id !== DefaultRole.Supervisor,
        ({ id }) => +id !== DefaultRole.Manager,
        ({ id }) => +id !== DefaultRole.Admin,
        ({ label }) => label,
      ],
    );
  },
});

export const assignableRolesSelectOptionsSelector = selector<RolesSelectOption[] | null>({
  key: 'assignableRolesSelectOptions',
  get: ({ get }) => {
    const roles = get(rolesSelectOptionsSelector);

    if (!roles) return null;

    return roles.filter(({ canBeAssigned }) => !!canBeAssigned);
  },
});

export const workPositionsSelectOptionsSelector = selector<InputOption[] | null>({
  key: 'workPositionsSelectOptions',
  get: ({ get }) => {
    const workPositions = get(workPositionsSelector);
    if (!workPositions) return null;

    return _.orderBy(
      workPositions.filter(({ isActive }) => isActive).map(({ name, id }) => ({ label: name, id })),
      ({ label }) => label,
    );
  },
});

export const defaultTagsFeaturesAtom = atom<FetchTagInheritedFeaturesResponse | null>({
  key: 'defaultTagsFeatures',
  default: null,
});

export const getAddTeammateFormValuesAtom = atom<UseFormGetValues<ProfileFormState> | null>({
  key: 'getAddTeammateFormValues',
  default: null,
});

export const getAvatarDetailsAtom = atom<(() => { avatarUrl: string; avatarChanged: boolean }) | null>({
  key: 'getAvatarDetails',
  default: null,
});
