import React, { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { FetchRequestsSettingsResponse } from 'api/actions/settings/settingsActions.types';
import { blockTransitionAtom } from 'state/settings';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useFormSubmit } from '../../../../hooks/useFormSubmit';
import { useSettingsBlockRouteTransition } from '../../../../hooks/useSettingsBlockRouteTransition';
import { FormProviderCustomEvents } from '../../../FormProviderCustomEvents/FormProviderCustomEvents';
import { useRequestsSettings } from '../../hooks/useRequestsSettings';

import { MemoizedCustomRequestList } from './components/RequestsFormsContainer/components/CustomRequestList';
import { MemoizedTimeOffList } from './components/RequestsFormsContainer/components/TimeOffList';
import { RequestsFormsContainer } from './components/RequestsFormsContainer/RequestsFormsContainer';
import { useSubmitRequestsSettings } from './hooks/useSubmitRequestsSettings';

export const RequestsForms = (): React.ReactElement => {
  const resetBlockTransition = useResetRecoilState(blockTransitionAtom);
  const setBlockTransition = useSetRecoilState(blockTransitionAtom);
  const { requestsSettings, setRequestsSettings } = useRequestsSettings();

  const formRef = useRef<HTMLFormElement | null>(null);

  const { dispatchSubmitEvent, throttledDispatchSubmitEvent } = useFormSubmit(formRef);

  const methods = useForm<FetchRequestsSettingsResponse>({
    defaultValues: requestsSettings || undefined,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    // FIXME: Error focus doesn't allow to scroll or close the select witn an error.
    shouldFocusError: false,
    // shouldUnregister: true,
  });

  const onChangeAndOnBlur = useCallback(() => {
    setBlockTransition((prevState) => ({
      ...prevState,
      blockLocationPathname: null,
      blockTransition: true,
    }));
    throttledDispatchSubmitEvent.cancel();
    throttledDispatchSubmitEvent();
  }, [setBlockTransition, throttledDispatchSubmitEvent]);

  const handleFormReset = useCallback(() => {
    setRequestsSettings(null);
    resetBlockTransition();
  }, [resetBlockTransition, setRequestsSettings]);

  const { handleSubmitCallback, handleErrorCallback } = useSubmitRequestsSettings({
    handleFormReset,
    getCurrentFormState: methods.getValues,
  });

  useSettingsBlockRouteTransition(dispatchSubmitEvent, throttledDispatchSubmitEvent);

  return (
    <FormProviderCustomEvents {...methods} onChange={onChangeAndOnBlur} onBlur={onChangeAndOnBlur}>
      <form
        ref={formRef}
        onSubmit={floatingPromiseReturn(methods.handleSubmit(handleSubmitCallback, handleErrorCallback))}
      >
        <RequestsFormsContainer>
          <RequestsFormsContainer.PersonalReplacement />
          <Flex sx={{ flexDirection: 'column', gap: 2 }}>
            <RequestsFormsContainer.TimeOff />
            <MemoizedTimeOffList />
          </Flex>
          <RequestsFormsContainer.TimeTracking />
          <RequestsFormsContainer.BusinessTrip />
          <RequestsFormsContainer.Schedules />
          <Flex sx={{ flexDirection: 'column', gap: 2 }}>
            <RequestsFormsContainer.Custom />
            <MemoizedCustomRequestList />
          </Flex>
        </RequestsFormsContainer>
      </form>
    </FormProviderCustomEvents>
  );
};
