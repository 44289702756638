/* eslint-disable no-console */
/** @jsxImportSource theme-ui */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Box, Text } from 'theme-ui';

import {
  PITCH_DEVIATION_THRESHOLD,
  PITCH_MEAN_THRESHOLD,
  PITCH_THRESHOLD,
  YAW_DEVIATION_THRESHOLD,
  YAW_MEAN_THRESHOLD,
  YAW_THRESHOLD,
} from 'Kiosk/constants/constants';
import { getFaceKeyPoints } from 'Kiosk/helpers';
import { useCameraImageBuffer } from 'Kiosk/hooks/useCameraImageBuffer';
import { useFaceDetection } from 'Kiosk/hooks/useFaceLandmarkModel';
import { Liveliness, useFaceLiveness } from 'Kiosk/hooks/useFaceLiveness';
import { useGeoLocation } from 'Kiosk/hooks/useGeoLocation';
import { usePermissions } from 'Kiosk/hooks/usePermissions';
import { useQrScanner } from 'Kiosk/hooks/useQrScanner';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import {
  DebugFaceLivelinessMode,
  debugModeFacePredictionsAtom,
  debugModeSelector,
  debugModeSettingsAtom,
  debugScanningTimeFrameOpenAtom,
} from 'Kiosk/state/debugState';
import { KioskOverlay, overlayStateAtom } from 'Kiosk/state/overlayState';
import { settingsAtom } from 'Kiosk/state/settingsState';
import { Button } from 'components/ui/Buttons';
import { RadioButton } from 'components/ui/RadioButton/RadioButton';
import { RadioButtonGroup } from 'components/ui/RadioButton/RadioButtonGroup';
import { useIdle } from 'hooks/useIdle/useIdle';
import { windowSizeAtom } from 'state/recoilState';

type LivelinessProps = {
  pitchMean: number;
  pitchDiff: number;
  pitchMeanDeviation: number;
  yawDiff: number;
  yawMean: number;
  yawMeanDeviation: number;
  rollDiff: number;
  rollMean: number;
  rollMeanDeviation: number;
};

let myQr = '';

export const DebugControlPanel = (): React.ReactElement => {
  const settings = useRecoilValue(settingsAtom);
  const { isLandscape, width, height, aspectRatio, isMobile } = useRecoilValue(windowSizeAtom);

  const cameraState = useRecoilValue(cameraStateAtom);
  const { isCameraReady, isCameraStreaming, toggleVideoPlayback } = cameraState;
  const setOverlay = useSetRecoilState(overlayStateAtom);

  const setDebugMode = useSetRecoilState(debugModeSelector);
  const facePredictions = useRecoilValue(debugModeFacePredictionsAtom);
  const [debugModeSettings, setDebugModeSettings] = useRecoilState(debugModeSettingsAtom);
  const debugScanningTimeFrameOpen = useRecoilValue(debugScanningTimeFrameOpenAtom);

  const { getBlob, getImageData } = useCameraImageBuffer();
  const { isQrScannerRunning, toggleQrScanner, pingWorker, decode } = useQrScanner({
    // eslint-disable-next-line @typescript-eslint/require-await
    onDecode: async (qr) => {
      console.log(`Your Qr code - ${qr}`);
      myQr = qr;
    },
  });

  const { isModelLoaded, faceBounding, getPrediction, pointsToScan } = useFaceDetection();
  const { getGeoLocation, location } = useGeoLocation();
  const { camera: cameraPermission, location: locationPermission } = usePermissions();
  const { getLivelinessScores } = useFaceLiveness();

  const { status, stop, start } = useIdle();

  const faceKeyPoints = useMemo(
    () => (facePredictions && facePredictions.length > 0 ? getFaceKeyPoints(facePredictions[0]) : undefined),
    [facePredictions],
  );

  const [logLivelinessScoreCount, setLogLivelinessScoreCount] = useState(0);
  const [logLivelinessScoreValues, setLogLivelinessScoreValues] = useState<LivelinessProps>();
  const logLivelinessScoreRef = useRef<Liveliness[]>([]);
  const livelinessScoreFramesRef = useRef<Liveliness[]>([]);
  const livelinessScoreTenFramesRef = useRef<Liveliness[]>([]);
  const livelinessScoreFiftyFramesRef = useRef<Liveliness[]>([]);
  const livelinessScoreHundredFramesRef = useRef<Liveliness[]>([]);
  const inactiveFrameCollection = useRef(0);

  const renderLivelinessScores = (refCurrent: Liveliness[], maxLength?: number) => {
    const {
      arrLength,
      pitchDiff,
      pitchMean,
      pitchMeanDeviation,
      yawDiff,
      yawMean,
      yawMeanDeviation,
      rollDiff,
      rollMean,
      rollMeanDeviation,
    } = getLivelinessScores(undefined, refCurrent);

    return (
      <>
        <hr sx={{ width: '100%' }} />
        <Text color="kiosk.debug.text" as="p">
          Length: {arrLength} {maxLength && `/ ${maxLength}`}
          <br />
          <span
            sx={{
              fontWeight: '700',
              color: pitchDiff > PITCH_THRESHOLD ? 'kiosk.debug.green' : 'kiosk.debug.red',
            }}
          >
            Pitch Diff Score: {pitchDiff}
          </span>
          <br />
          <span
            sx={{
              fontWeight: '700',
              color: pitchMean > PITCH_MEAN_THRESHOLD ? 'kiosk.debug.green' : 'kiosk.debug.red',
            }}
          >
            Pitch Mean: {pitchMean}
          </span>
          <br />
          <span
            sx={{
              fontWeight: '700',
              color: pitchMeanDeviation > PITCH_DEVIATION_THRESHOLD ? 'kiosk.debug.green' : 'kiosk.debug.red',
            }}
          >
            Pitch Mean Deviation: {pitchMeanDeviation}
          </span>
          <br />
          <span
            sx={{
              fontWeight: '700',
              color: yawDiff > YAW_THRESHOLD ? 'kiosk.debug.green' : 'kiosk.debug.red',
            }}
          >
            Yaw Diff Score: {yawDiff}
          </span>
          <br />
          <span
            sx={{
              fontWeight: '700',
              color: yawMean > YAW_MEAN_THRESHOLD ? 'kiosk.debug.green' : 'kiosk.debug.red',
            }}
          >
            Yaw Mean: {yawMean}
          </span>
          <br />
          <span
            sx={{
              fontWeight: '700',
              color: yawMeanDeviation > YAW_DEVIATION_THRESHOLD ? 'kiosk.debug.green' : 'kiosk.debug.red',
            }}
          >
            Yaw Mean Deviation: {yawMeanDeviation}
          </span>
          <br />
          <span
            sx={{
              fontWeight: '700',
            }}
          >
            Roll Diff Score: {rollDiff}
          </span>
          <br />
          <span
            sx={{
              fontWeight: '700',
            }}
          >
            Roll Mean: {rollMean}
          </span>
          <br />
          <span
            sx={{
              fontWeight: '700',
            }}
          >
            Roll Mean Deviation: {rollMeanDeviation}
          </span>
          <br />
        </Text>
      </>
    );
  };

  useEffect(() => {
    if (faceKeyPoints) {
      const { axisXRotation, axisYRotation, tilt } = faceKeyPoints;

      livelinessScoreFramesRef.current.push({ axisXRotation, axisYRotation, tilt, imageUrl: '' });
      livelinessScoreTenFramesRef.current.push({ axisXRotation, axisYRotation, tilt, imageUrl: '' });
      livelinessScoreFiftyFramesRef.current.push({ axisXRotation, axisYRotation, tilt, imageUrl: '' });
      livelinessScoreHundredFramesRef.current.push({ axisXRotation, axisYRotation, tilt, imageUrl: '' });
      inactiveFrameCollection.current = 0;
    }

    if (livelinessScoreFramesRef.current.length > 30) {
      livelinessScoreFramesRef.current.shift();
    }
    if (livelinessScoreTenFramesRef.current.length > 10) {
      livelinessScoreTenFramesRef.current.shift();
    }
    if (livelinessScoreFiftyFramesRef.current.length > 50) {
      livelinessScoreFiftyFramesRef.current.shift();
    }
    if (livelinessScoreHundredFramesRef.current.length > 100) {
      livelinessScoreHundredFramesRef.current.shift();
    }

    if (inactiveFrameCollection.current > 10) {
      livelinessScoreFramesRef.current.length = 0;
      livelinessScoreTenFramesRef.current.length = 0;
      livelinessScoreFiftyFramesRef.current.length = 0;
      livelinessScoreHundredFramesRef.current.length = 0;
    }
  }, [faceKeyPoints]);

  useEffect(() => {
    const resetInterval = setInterval(() => {
      if (!faceKeyPoints) {
        inactiveFrameCollection.current += 1;
      }
      if (inactiveFrameCollection.current > 10) {
        livelinessScoreFramesRef.current.length = 0;
        livelinessScoreTenFramesRef.current.length = 0;
        livelinessScoreFiftyFramesRef.current.length = 0;
        livelinessScoreHundredFramesRef.current.length = 0;
      }
    }, 100);

    return () => clearInterval(resetInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logLivelinessScoreRef.current.length > logLivelinessScoreCount) {
      const {
        pitchDiff,
        pitchMean,
        pitchMeanDeviation,
        yawDiff,
        yawMean,
        yawMeanDeviation,
        rollDiff,
        rollMean,
        rollMeanDeviation,
      } = getLivelinessScores(undefined, logLivelinessScoreRef.current);

      console.log({
        scoreArray: logLivelinessScoreRef.current,
        pitchDiff,
        pitchMean,
        pitchMeanDeviation,
        yawDiff,
        yawMean,
        yawMeanDeviation,
        rollDiff,
        rollMean,
        rollMeanDeviation,
      });

      setLogLivelinessScoreValues({
        pitchDiff,
        pitchMean,
        pitchMeanDeviation,
        yawDiff,
        yawMean,
        yawMeanDeviation,
        rollDiff,
        rollMean,
        rollMeanDeviation,
      });
      logLivelinessScoreRef.current = [];
    }

    if (logLivelinessScoreRef.current.length === logLivelinessScoreCount) {
      setLogLivelinessScoreCount(0);
    }

    if (logLivelinessScoreCount > 0 && faceKeyPoints) {
      const { axisXRotation, axisYRotation, tilt } = faceKeyPoints;

      logLivelinessScoreRef.current.push({ axisXRotation, axisYRotation, tilt, imageUrl: '' });
    }
  }, [faceKeyPoints, getLivelinessScores, logLivelinessScoreCount]);

  const logLivelinessDetectionScore = (count = 1000) => {
    setLogLivelinessScoreCount(count);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        maxWidth: '300px',
        width: '100%',
        hight: '100%',
        overflow: 'auto',
        zIndex: 9999,
        right: 0,
        top: 0,
        bottom: 0,
        p: 3,
        bg: 'kiosk.debug.background',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!settings ? (
        <Text color="kiosk.debug.text" as="p">
          Loading...
        </Text>
      ) : (
        <>
          <Text color="kiosk.debug.text" as="p" sx={{ fontWeight: 'bold' }}>
            Liveliness Detection (Active)
          </Text>

          <Text color="kiosk.debug.text" as="p">
            AxisX Rotation: {faceKeyPoints && faceKeyPoints.axisXRotation}
          </Text>
          <Text color="kiosk.debug.text" as="p">
            AxisY Rotation: {faceKeyPoints && faceKeyPoints.axisYRotation}
          </Text>
          <Text color="kiosk.debug.text" as="p">
            Tilt: {faceKeyPoints && faceKeyPoints.tilt}
          </Text>
          <Text color="kiosk.debug.text" as="p">
            Scanning Frames Open:{' '}
            <span sx={{ color: debugScanningTimeFrameOpen ? 'kiosk.debug.green' : 'kiosk.debug.red' }}>
              {debugScanningTimeFrameOpen ? 'OPEN' : 'CLOSED'}
            </span>
            <br />
          </Text>
          {renderLivelinessScores(livelinessScoreFramesRef.current, 30)}
          {renderLivelinessScores(livelinessScoreTenFramesRef.current, 10)}
          {renderLivelinessScores(livelinessScoreFiftyFramesRef.current, 50)}
          {renderLivelinessScores(livelinessScoreHundredFramesRef.current, 100)}
          {logLivelinessScoreValues && (
            <>
              <hr sx={{ width: '100%' }} />
              <Text color="kiosk.debug.text" as="p">
                <span
                  sx={{
                    fontWeight: '700',
                    color:
                      logLivelinessScoreValues.pitchDiff > PITCH_THRESHOLD ? 'kiosk.debug.green' : 'kiosk.debug.red',
                  }}
                >
                  Pitch Diff Score: {logLivelinessScoreValues.pitchDiff}
                </span>
                <br />
                <span
                  sx={{
                    fontWeight: '700',
                    color:
                      logLivelinessScoreValues.pitchMean > PITCH_MEAN_THRESHOLD
                        ? 'kiosk.debug.green'
                        : 'kiosk.debug.red',
                  }}
                >
                  Pitch Mean: {logLivelinessScoreValues.pitchMean}
                </span>
                <br />
                <span
                  sx={{
                    fontWeight: '700',
                    color:
                      logLivelinessScoreValues.pitchMeanDeviation > PITCH_DEVIATION_THRESHOLD
                        ? 'kiosk.debug.green'
                        : 'kiosk.debug.red',
                  }}
                >
                  Pitch Deviation Mean: {logLivelinessScoreValues.pitchMeanDeviation}
                </span>
                <br />
                <span
                  sx={{
                    fontWeight: '700',
                    color: logLivelinessScoreValues.yawDiff > YAW_THRESHOLD ? 'kiosk.debug.green' : 'kiosk.debug.red',
                  }}
                >
                  Yaw Diff Score: {logLivelinessScoreValues.yawDiff}
                </span>
                <br />
                <span
                  sx={{
                    fontWeight: '700',
                    color:
                      logLivelinessScoreValues.yawMean > YAW_MEAN_THRESHOLD ? 'kiosk.debug.green' : 'kiosk.debug.red',
                  }}
                >
                  Yaw Mean: {logLivelinessScoreValues.yawMean}
                </span>
                <br />
                <span
                  sx={{
                    fontWeight: '700',
                    color:
                      logLivelinessScoreValues.yawMeanDeviation > YAW_DEVIATION_THRESHOLD
                        ? 'kiosk.debug.green'
                        : 'kiosk.debug.red',
                  }}
                >
                  Yaw Deviation Mean: {logLivelinessScoreValues.yawMeanDeviation}
                </span>
                <br />
                <span
                  sx={{
                    fontWeight: '700',
                  }}
                >
                  Roll Diff Score: {logLivelinessScoreValues.rollDiff}
                </span>
                <br />
                <span
                  sx={{
                    fontWeight: '700',
                  }}
                >
                  Roll Mean: {logLivelinessScoreValues.rollMean}
                </span>
                <br />
                <span
                  sx={{
                    fontWeight: '700',
                  }}
                >
                  Roll Deviation Mean: {logLivelinessScoreValues.rollMeanDeviation}
                </span>
                <br />
              </Text>
            </>
          )}
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1, pointerEvents: logLivelinessScoreCount ? 'none' : 'all' }}
            onClick={() => logLivelinessDetectionScore(10)}
          >
            {logLivelinessScoreCount ? 'Collecting' : 'Log a 10 scores (1s)'}
          </Button>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1, pointerEvents: logLivelinessScoreCount ? 'none' : 'all' }}
            onClick={() => logLivelinessDetectionScore(50)}
          >
            {logLivelinessScoreCount ? 'Collecting' : 'Log a 50 scores (5s)'}
          </Button>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1, pointerEvents: logLivelinessScoreCount ? 'none' : 'all' }}
            onClick={() => logLivelinessDetectionScore(100)}
          >
            {logLivelinessScoreCount ? 'Collecting' : 'Log a 100 scores (10s)'}
          </Button>

          <hr sx={{ width: '100%' }} />

          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            onClick={() => setDebugModeSettings((prev) => ({ ...prev, drawAxisPoints: !prev.drawAxisPoints }))}
          >
            Draw axis points
          </Button>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => setDebugModeSettings((prev) => ({ ...prev, drawBoundingBox: !prev.drawBoundingBox }))}
          >
            Draw bounding box
          </Button>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() =>
              setDebugModeSettings((prev) => ({ ...prev, drawFaceInPlaceBounding: !prev.drawFaceInPlaceBounding }))
            }
          >
            Draw faceInPlace bounding
          </Button>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() =>
              setDebugModeSettings((prev) => ({ ...prev, drawPredictionPoints: !prev.drawPredictionPoints }))
            }
          >
            Draw prediction points
          </Button>
          <hr sx={{ width: '100%' }} />
          <RadioButtonGroup
            name="debugFaceLivelinessMode"
            orientation="row"
            defaultValue={debugModeSettings.faceLivelinessMode}
            childrenSx={{
              'input:checked + &': {
                background: 'kiosk.debug.radioButtonGroup.checked.background',
                color: 'kiosk.debug.radioButtonGroup.checked.text',
              },
            }}
          >
            <RadioButton
              value={DebugFaceLivelinessMode.Default}
              label="Default"
              onClick={() =>
                setDebugModeSettings((prev) => ({ ...prev, faceLivelinessMode: DebugFaceLivelinessMode.Default }))
              }
            />
            <RadioButton
              value={DebugFaceLivelinessMode.ThirtyFrames}
              label="30"
              onClick={() =>
                setDebugModeSettings((prev) => ({ ...prev, faceLivelinessMode: DebugFaceLivelinessMode.ThirtyFrames }))
              }
            />
            <RadioButton
              value={DebugFaceLivelinessMode.FiftyFrames}
              label="50"
              onClick={() =>
                setDebugModeSettings((prev) => ({ ...prev, faceLivelinessMode: DebugFaceLivelinessMode.FiftyFrames }))
              }
            />
            <RadioButton
              value={DebugFaceLivelinessMode.OneHundredFrames}
              label="100"
              onClick={() =>
                setDebugModeSettings((prev) => ({
                  ...prev,
                  faceLivelinessMode: DebugFaceLivelinessMode.OneHundredFrames,
                }))
              }
            />
          </RadioButtonGroup>
          <hr sx={{ width: '100%' }} />
          <Text color="kiosk.debug.text" as="p">
            isMobile = {`${isMobile}`}
            <br />
            isLandscape = {`${isLandscape}`}
            <br />
            aspectRatio = {`${aspectRatio}`}
            <br />
            size = {`${width} x ${height}`}
          </Text>
          <hr sx={{ width: '100%' }} />
          <Text color="kiosk.debug.text" as="p" sx={{ fontWeight: 'bold' }}>
            Kiosk Settings
          </Text>
          <Text color="kiosk.debug.text" as="p">
            isFaceRecognitionRequired = {`${settings.isFaceRecognitionRequired}`}
            <br />
            isBasicFraudDetectionRequired = {`${settings.isBasicFraudDetectionRequired}`}
            <br />
            isAdvancedFraudDetectionRequired = {`${settings.isAdvancedFraudDetectionRequired}`}
            <br />
            isLocationRequired = {`${settings.isLocationRequired}`}
            <br />
            isSleepModeEnabled = {`${settings.isSleepModeEnabled}`}
            <br />
            sleepModeTimeOutMinutes = {`${settings.sleepModeTimeOutMinutes}`} min
          </Text>
          <hr sx={{ width: '100%' }} />
          <Text color="kiosk.debug.text" as="p" sx={{ fontWeight: 'bold' }}>
            Permissions
          </Text>
          <Text color="kiosk.debug.text" as="p">
            camera = {`${cameraPermission}`}
            <br />
            location = {`${locationPermission}`}
          </Text>
          <hr sx={{ width: '100%' }} />
          <Text color="kiosk.debug.text" as="p" sx={{ fontWeight: 'bold' }}>
            IdleObserver (SleepMode)
          </Text>
          <Text color="kiosk.debug.text" as="p">
            status = {status ? 'RUNNING' : 'STOPPED'}
          </Text>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              if (start) start();
            }}
          >
            Start
          </Button>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              if (stop) stop();
            }}
          >
            Stop
          </Button>
          <hr sx={{ width: '100%' }} />
          <Text color="kiosk.debug.text" as="p" sx={{ fontWeight: 'bold' }}>
            Camera
          </Text>
          <Text color="kiosk.debug.text" as="p">
            isCameraReady = {`${isCameraReady}`}
            <br />
            isCameraStreaming = {`${isCameraStreaming}`}
          </Text>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              if (toggleVideoPlayback) void toggleVideoPlayback();
            }}
          >
            Toggle Video Playback
          </Button>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              console.log(cameraState);
            }}
          >
            Log Camera State
          </Button>
          <hr sx={{ width: '100%' }} />
          <Text color="kiosk.debug.text" as="p" sx={{ fontWeight: 'bold' }}>
            QrScanner
          </Text>
          <Text color="kiosk.debug.text" as="p">
            isQrScannerRunning = {`${isQrScannerRunning}`}
          </Text>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              if (toggleQrScanner) toggleQrScanner();
            }}
          >
            Toggle QR Scanner
          </Button>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              if (pingWorker) pingWorker();
            }}
          >
            Ping Worker
          </Button>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              const testFrame = getImageData && void getImageData();
              if (testFrame && decode) decode(testFrame);
            }}
          >
            Scan for QrCode
          </Button>
          <hr sx={{ width: '100%' }} />
          <Text color="kiosk.debug.text" as="p" sx={{ fontWeight: 'bold' }}>
            FaceScanner
          </Text>
          <Text color="kiosk.debug.text" as="p">
            isModelLoaded = {`${isModelLoaded}`}
          </Text>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              const { imageData: testFrame } = (getImageData && (await getImageData())) || {};
              if (getPrediction && testFrame) {
                const test = await getPrediction(testFrame);
                console.log(test);
              }
            }}
          >
            getPredictions
          </Button>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              console.log({
                faceBounding,
                pointsToScan,
              });
            }}
          >
            Get Additional State
          </Button>
          <hr sx={{ width: '100%' }} />
          <Text color="kiosk.debug.text" as="p" sx={{ fontWeight: 'bold' }}>
            GeoLocation
          </Text>
          <Text color="kiosk.debug.text" as="p">
            location = {`${location?.coords.latitude} ${location?.coords.longitude}`}
          </Text>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              console.log(
                getGeoLocation &&
                  getGeoLocation(
                    (position) => console.log(position),
                    () => console.log('error'),
                  ),
              );
            }}
          >
            GetGeoLocation
          </Button>
          <hr sx={{ width: '100%' }} />
          <Text color="kiosk.debug.text" as="p">
            useCameraImageBuffer
          </Text>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              console.log(getImageData && getImageData());
            }}
          >
            getImageData
          </Button>
          <Button
            variant="danger"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              console.log(getBlob && getBlob());
            }}
          >
            Log Blob (ref)
          </Button>
          <hr sx={{ width: '100%' }} />
          <Text color="kiosk.debug.text" as="p">
            Other
          </Text>
          <Button
            variant="primary"
            shape="rounded"
            size="sm"
            sx={{ mt: 1 }}
            onClick={() => {
              // setIsInitialFaceScan(!isInitialFaceScan);
              console.log('Setting initialFaceScan overlay');
              const testFrame = getImageData && void getImageData();
              if (testFrame && decode) decode(testFrame);
              setOverlay({
                type: KioskOverlay.initialFaceScan,
                state: {
                  name: 'DEBUG PERSON',
                  qrCode: myQr,
                },
              });
            }}
          >
            Toggle InitialFaceScan
          </Button>
        </>
      )}

      <hr sx={{ width: '100%' }} />
      <Button shape="rounded" size="sm" sx={{ mt: 1 }} onClick={() => setDebugMode(false)}>
        Exit DebugMode
      </Button>
    </Box>
  );
};
