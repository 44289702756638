import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isUndefined } from 'lodash';
import React, { useCallback } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { TimeEventState } from 'api/actions/timeEvent/timeEventActions.types';
import { Modal } from 'components/Modal/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { Button } from 'components/ui/Buttons';
import { EDIT_EVENT_FORM_ID } from 'constants/clockLog';
import { PATH_REL, TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useIsEmployeeNonEditable } from 'hooks/useIsEmployeeNonEditable/useIsEmployeeNonEditable';
import { useMemoCompare } from 'hooks/useMemoCompare/useMemoCompare';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { editEventDisabledSaveAtom, editEventLoadingSaveAtom } from 'state/clockLog';
import { languageSelector } from 'state/recoilState';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';
import { RejectEventModal } from '../RejectEvent';
import { useFindExtEvent } from '../hooks/useFindExtEvent';

import { EditEventBody } from './components/EditEventBody';

export const EditEventModal = (): React.ReactElement | null => {
  useLingui();
  const { id } = useParams<{ id: string }>();

  const navigate = useAppNavigate();
  const { baseRoute } = useModal();

  const language = useRecoilValue(languageSelector);
  const { breakpoint } = useThemeBreakpoint();
  const isViewXS = breakpoint === MEDIA_BREAKPOINTS.XS;

  const extTimeEvent = useFindExtEvent(id || '');
  const memoizedExtTimeEvent = useMemoCompare(extTimeEvent, (prev, next) => !!prev && !next);

  const isEmployeeNonEditable = useIsEmployeeNonEditable('TimeTracking', extTimeEvent?.personId);

  const isLoadingSave = useRecoilValue(editEventLoadingSaveAtom);
  const isDisabledSave = useRecoilValue(editEventDisabledSaveAtom);

  const handleReject = useCallback(() => {
    navigate(TO_REL.REJECT_EVENT_MODAL[language], {
      state: {
        modifyTimeEvent: { id, personId: extTimeEvent?.personId || '' },
        baseRoute,
      },
    });
  }, [baseRoute, extTimeEvent?.personId, id, language, navigate]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {isEmployeeNonEditable || extTimeEvent?.state === TimeEventState.Deleted ? (
            <Trans id="clock_log.preview_event">Event preview</Trans>
          ) : (
            <Trans id="clock_log.edit_event">Edit event</Trans>
          )}
        </Modal.Title>
      </Modal.Header>

      {memoizedExtTimeEvent ? (
        <EditEventBody extTimeEvent={memoizedExtTimeEvent} />
      ) : (
        <CenteredLoadingSpinner size={5} />
      )}

      {extTimeEvent?.state !== TimeEventState.Deleted && (
        <BasicModalFooter
          {...(!isUndefined(memoizedExtTimeEvent?.fraudDetectionState) && {
            prependWith: (
              <Button
                sx={{ fontWeight: 'normal', color: 'clockLog.edit.btn.markRejected' }}
                shape="rounded"
                variant="minimal"
                size={isViewXS ? 'xs' : 'default'}
                onClick={handleReject}
              >
                <Trans id="clock_log.mark_as_rejected">Mark as Rejected</Trans>
              </Button>
            ),
          })}
          buttons={
            !isEmployeeNonEditable
              ? [
                  {
                    variant: 'primary',
                    children: t({ id: 'save' }),
                    type: 'submit',
                    form: EDIT_EVENT_FORM_ID,
                    isLoading: isLoadingSave,
                    disabled: isDisabledSave,
                  },
                ]
              : undefined
          }
        />
      )}

      <Routes>
        <Route
          path={PATH_REL.REJECT_EVENT_MODAL[language]}
          element={
            <Modal size="xs" path={PATH_REL.REJECT_EVENT_MODAL[language]}>
              <RejectEventModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
