import { API_ENDPOINTS } from 'constants/api';
import { Action, DefaultErrorMessageEnum } from 'api/types';

import {
  FetchZusCredentialsResponse,
  ZusConnectActionProps,
  ZusConnectResponse,
  ZusCredentialsActionProps,
  ZusCredentialsResponse,
  ZusManualImportResponse,
  ZusManualImportResponseErrorData,
} from './zusActions.types';

export function fetchZusCredentialsAction(): Action<FetchZusCredentialsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.zusCredentials,
  };
}

export function zusManualImportAction(): Action<ZusManualImportResponse, ZusManualImportResponseErrorData> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.zusManualImport,
    config: { customDefaultErrorMessage: DefaultErrorMessageEnum.None },
  };
}

export function zusConncectAction(body: ZusConnectActionProps): Action<ZusConnectResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.zusConnect,
    body,
  };
}

export function zusCredentialsAction(body: ZusCredentialsActionProps): Action<ZusCredentialsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.zusCredentials,
    body,
  };
}
