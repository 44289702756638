import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { invitationJoinRequestAction } from 'api/actions/auth/authActions';
import { InvitationJoinRequestPayload } from 'api/actions/auth/authActions.types';
import { FormCard } from 'components/FormCard/FormCard';
import { LinkButton } from 'components/ui/Buttons';
import { APP_NAME } from 'constants/common';
import { TO } from 'constants/routes';
import { setUnauthorized } from 'context/auth/authActionCreators/authActionCreators';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { Main } from 'layouts/Authentication/output/Main';
import { languageSelector } from 'state/recoilState';

import { EmployeeNotInvitedForm } from './components/EmployeeNotInvitedForm';

const RequestSent = (): React.ReactElement => (
  <>
    <FormCard.Header>
      <FormCard.Title>
        <Trans id="sign_up.employee.request_sent.lead.header">The message has been sent! 👍</Trans>
      </FormCard.Title>
    </FormCard.Header>

    <FormCard.Lead>
      <Trans id="sign_up.employee.request_sent.lead.text">
        You should soon receive an invitation from your company's <span>{APP_NAME}</span> administrator.
      </Trans>
    </FormCard.Lead>

    <FormCard.Lead>
      <Trans id="sign_up.employee.request_sent.lead.text.not_invited">
        As long as the provided administrator e-mail address has an account in <span>{APP_NAME}</span>.
      </Trans>
    </FormCard.Lead>
  </>
);

const NotInvitedHeader = (): React.ReactElement => (
  <FormCard.Header>
    <FormCard.Title>
      <Trans id="sign_up.employee.not_invited.lead.header">
        This e-mail address hasn't been invited to any company account yet. 😔
      </Trans>
    </FormCard.Title>

    <FormCard.Lead>
      <Trans id="sign_up.employee.not_invited.lead.text">
        If your company already uses {APP_NAME}, send a message to the administrator asking them for access to your
        account.
      </Trans>
    </FormCard.Lead>
  </FormCard.Header>
);

export const EmployeeNotInvited = (): React.ReactElement => {
  useLingui();
  const { state } = useLocation();
  const dispatch = useAuthDispatch();

  const [isSent, setIsSent] = useState(false);

  const language = useRecoilValue(languageSelector);
  const { mutate, loading } = useMutation(invitationJoinRequestAction);

  const onJoinRequestSubmit = useCallback(
    async (body: InvitationJoinRequestPayload): Promise<boolean> => {
      const { error } = await mutate(body);

      if (!error) {
        setIsSent(true);
        dispatch(setUnauthorized());
        return true;
      }

      return false;
    },
    [dispatch, mutate],
  );

  if (!state?.signUp?.inProgress) {
    return <Navigate to={TO.SIGN_UP[language]} />;
  }

  return (
    <>
      <Helmet>
        <title>{t({ id: 'sign_up.page_title', message: `Sign up - ${APP_NAME}` })}</title>
      </Helmet>

      <Main>
        <FormCard size="md">
          {isSent ? (
            <>
              <RequestSent />
              <FormCard.Footer sx={{ justifyContent: 'center' }}>
                <LinkButton to={TO.SIGN_IN[language]} variant="grey" shape="rounded" size="lg">
                  {t({ id: 'password_recovery.form.back_button' })}
                </LinkButton>
              </FormCard.Footer>
            </>
          ) : (
            <>
              <NotInvitedHeader />
              <EmployeeNotInvitedForm onSubmit={onJoinRequestSubmit} submitButtonLoading={loading} />
            </>
          )}
        </FormCard>
      </Main>
    </>
  );
};
