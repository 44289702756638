import { Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { MouseEventHandler } from 'react';
import { Flex } from 'theme-ui';

import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { Button, ButtonProps } from 'components/ui/Buttons';

type Props = {
  buttons?: Omit<ButtonProps, 'shape' | 'size'>[];
  prependWith?: React.ReactElement | React.ReactElement[];
  backButtonOnClick?: MouseEventHandler<HTMLButtonElement>;
  cancelButtonProps?: ButtonProps;
};

export type BasicModalFooterProps = Props;

const defaultProps: Partial<Props> = {
  prependWith: undefined,
  buttons: undefined,
  cancelButtonProps: undefined,
};

export const BasicModalFooter = ({
  buttons,
  prependWith,
  backButtonOnClick,
  cancelButtonProps,
}: Props): React.ReactElement => {
  const { handleClose } = useModal();

  return (
    <Modal.Footer>
      <>{prependWith && <Flex sx={{ flexGrow: 1, alignItems: 'center' }}>{prependWith}</Flex>}</>
      <Button
        sx={{ fontWeight: 'normal', minWidth: '100px' }}
        shape="rounded"
        variant="minimal"
        onClick={() => handleClose()}
        {...(cancelButtonProps && cancelButtonProps)}
      >
        <Trans id="cancel">Cancel</Trans>
      </Button>
      {!!backButtonOnClick && <Modal.BackButton type="footer" onClick={backButtonOnClick} />}
      <>
        {buttons?.map(({ sx, ...rest }) => (
          <Button key={_.uniqueId()} sx={{ minWidth: '100px', ...sx }} {...rest} shape="rounded" />
        ))}
      </>
    </Modal.Footer>
  );
};

BasicModalFooter.defaultProps = defaultProps;
