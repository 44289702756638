import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-fetching-library';
import { Navigate, useParams } from 'react-router-dom';

import { DefaultRole } from 'api/actions/organizationSession/organizationSessionActions.types';
import { editRoleDetailsAction, fetchRoleDetailsAction } from 'api/actions/roles/rolesActions';
import { EditRoleDetailsActionProps, FetchRoleDetailsResponse } from 'api/actions/roles/rolesActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { AddEditRole } from '../../forms/AddEditRole';

type Props = {
  isPreview?: boolean;
};

const ModalContent: FC<Props> = ({ isPreview = false }) => {
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();
  const handleCloseRef = useCallbackRef(handleClose);
  const [loading, setLoading] = useState(false);
  const [editedRoleDetails, setEditedRoleDetails] = useState<FetchRoleDetailsResponse | null>(null);
  const [oldRoleDetails, setOldRoleDetails] = useState<FetchRoleDetailsResponse | null>(null);

  const { id } = useParams() as { id: string };

  const formRef = useRef<HTMLFormElement | null>(null);

  const { payload, error } = useQuery(fetchRoleDetailsAction({ roleId: id }));
  const { mutate } = useMutation(editRoleDetailsAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: EditRoleDetailsActionProps['role']): Promise<boolean> => {
      if (oldRoleDetails && isEqual(body, oldRoleDetails)) {
        setLoading(false);
        addSnackbar({
          message: t({ id: 'team.edit_role.no_changes', message: 'No changes to save!' }),
          variant: 'default',
        });
        return false;
      }
      const { error: submitError } = await mutate({
        role: body,
        roleId: id,
      });
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.edit_role.edited', message: 'Role successfully edited!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, id, oldRoleDetails, addSnackbar],
  );

  useEffect(() => {
    if (error) {
      handleCloseRef.current();
    }
    if (!error && payload) {
      setEditedRoleDetails(payload);
      setOldRoleDetails(clone(payload));
    }
  }, [error, payload, handleCloseRef]);

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {isPreview ? (
            <Trans id="team.edit_role.preview">Preview</Trans>
          ) : (
            <Trans id="team.edit_role">Edit role</Trans>
          )}
        </Modal.Title>
      </Modal.Header>
      {!editedRoleDetails ? (
        <CenteredLoadingSpinner />
      ) : (
        <>
          <Modal.Body
            sx={{
              fontSize: 2,
              my: 3,
            }}
          >
            <AddEditRole
              disable={isPreview}
              defaultValues={
                isPreview
                  ? {
                      ...editedRoleDetails,
                      name: t({
                        id: editedRoleDetails.name,
                      }),
                      description:
                        editedRoleDetails.description &&
                        t({
                          id: editedRoleDetails.description,
                        }),
                    }
                  : editedRoleDetails
              }
              ref={formRef}
              onSubmit={onSubmit}
              setLoading={setLoading}
            />
          </Modal.Body>
          <BasicModalFooter
            buttons={
              isPreview
                ? undefined
                : [
                    {
                      isLoading: loading,
                      onClick: handleSave,
                      variant: 'primary',
                      children: t({ id: 'save', message: 'Save' }),
                    },
                  ]
            }
          />
        </>
      )}
    </>
  );
};

export const EditRoleModal: FC<Props> = ({ isPreview = false }) => {
  useLingui();
  const { id } = useParams() as { id: string };
  const { baseRoute } = useModal();

  if (DefaultRole[+id] && !isPreview) return <Navigate to={baseRoute} relative="path" />;

  return <ModalContent isPreview={isPreview} />;
};
