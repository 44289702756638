import React, { useCallback, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { createPortal } from 'react-dom';

import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { cameraImageBufferReadySelector } from 'Kiosk/state/cameraImageBufferState';

const portalRoot = document.getElementById('app');

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const CameraImageBufferProvider = ({ children }: Props): React.ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { source } = useRecoilValue(cameraStateAtom);
  const [, setState] = useRecoilState(cameraImageBufferReadySelector);

  useEffect(() => {
    if (source) {
      if (canvasRef.current) {
        canvasRef.current.width = source.width;
        canvasRef.current.height = source.height;

        const ctx = canvasRef.current.getContext('2d');

        if (ctx) {
          ctx.canvas.width = source.width;
          ctx.canvas.height = source.height;
        }
      }
    }
  }, [source]);

  const canvasCallback = useCallback(() => canvasRef, []);

  const Component = useCallback(() => {
    if (portalRoot && source) {
      return createPortal(
        <canvas
          ref={canvasRef}
          width={source.width}
          height={source.height}
          style={{ position: 'absolute', zIndex: -4, opacity: 0, width: 1, height: 1 }}
        />,
        portalRoot,
        'CameraImageBuffer',
      );
    }

    return null;
  }, [source]);

  useEffect(() => {
    if (source) {
      setState({
        imageBufferReady: true,
        imageBufferCanvasCallback: canvasCallback,
      });
    }
  }, [canvasCallback, setState, source]);

  return (
    <>
      {children}
      <Component />
    </>
  );
};
