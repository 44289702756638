import React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { CheckboxLogicWrapper, CheckboxLogicWrapperProps } from 'components/utils/CheckboxLogicWrapper';
import { hexToRgba } from 'utils/colors';

type Props = Omit<CheckboxLogicWrapperProps, 'children'> & {
  prependSwitchWith?: React.ReactNode;
  baseColor?: string; // color in hex format
  sx?: ThemeUIStyleObject;
};

export type SwitchProps = Props;

const defaultProps: Partial<Props> = {
  prependSwitchWith: undefined,
  baseColor: undefined,
  sx: undefined,
};

export const Switch = React.forwardRef<HTMLInputElement, Props>(
  ({ baseColor, size = 'default', placement = 'right', prependSwitchWith, wrapperSx, sx, ...props }: Props, ref) => (
    <CheckboxLogicWrapper
      {...props}
      ref={ref}
      size={size}
      placement={placement || 'right'}
      wrapperSx={{
        '&:hover': {
          '> .switch': {
            bg: 'switch.background.hover',
            '> span': {
              boxShadow: 'switchIndicator',
            },
          },
        },
        ...(wrapperSx && wrapperSx),
        ...(sx && sx),
      }}
    >
      <Box
        as="span"
        className="switch"
        variant={`forms.switch.${size}`}
        {...(baseColor && {
          sx: {
            'input:checked ~ &': {
              bg: `${baseColor} !important`,
            },
            'input:checked:disabled ~ &, input:checked[data-disabled-but-readable="true"] ~ &': {
              bg: `${hexToRgba(baseColor, 0.5)} !important`,
            },
          },
        })}
      >
        <Box as="span" />
      </Box>
      {prependSwitchWith && prependSwitchWith}
    </CheckboxLogicWrapper>
  ),
);

Switch.defaultProps = defaultProps;
