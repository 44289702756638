import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';

export const useIntegrationHelpLinks = (serviceType: ServiceIntegration) => {
  const gratyfikantGTHelpLink = useHelpLink({
    inEwi: {
      pl: '/article/integracja-aplikacji-inewi-z-programem-gratyfikant-gt-e9vdw5/',
    },
  });
  const gratyfikantNexoHelpLink = useHelpLink({
    inEwi: {
      pl: '/article/integracja-aplikacji-inewi-z-programem-gratyfikant-nexo-crawzi/',
    },
  });
  const zusHelpLink = useHelpLink({
    inEwi: {
      pl: '/article/laczenie-inewi-z-zus-e-zla-z9yx37/',
    },
  });
  const optimaHelpLink = useHelpLink({
    inEwi: {
      pl: '/article/integracja-aplikacji-inewi-z-programem-comarch-optima-m2d3uw/',
    },
  });
  const symfoniaHelpLink = useHelpLink({
    inEwi: {
      pl: '/article/integracja-aplikacji-inewi-z-programem-symfonia-cfag4i/',
    },
  });
  const r2PlatnikHelpLink = useHelpLink({
    inEwi: {
      pl: '/article/integracja-aplikacji-inewi-z-programem-r2platnik-8dpfvl/',
    },
  });
  const webhookHelpLink = useHelpLink({
    inEwi: {
      pl: '/article/webhooks-1b2dchr/',
    },
    unrubble: {
      en: '/article/webhooks-7m65qu/',
    },
  });
  const quickBooksHelpLink = useHelpLink({
    unrubble: {
      en: '/article/quickbooks-with-unrubble-1bfir7e/',
    },
  });
  const enovaHelpLink = useHelpLink({
    inEwi: {
      pl: '/article/integracja-aplikacji-inewi-z-programem-enova-365-15cczhg/',
    },
  });
  const defaultHelpLink = useHelpLink({});

  switch (serviceType) {
    case ServiceIntegration.GratyfikantGT:
      return gratyfikantGTHelpLink;
    case ServiceIntegration.GratyfikantNexo:
      return gratyfikantNexoHelpLink;
    case ServiceIntegration.ZusEzla:
      return zusHelpLink;
    case ServiceIntegration.Optima:
      return optimaHelpLink;
    case ServiceIntegration.Symfonia:
      return symfoniaHelpLink;
    case ServiceIntegration.R2Platnik:
      return r2PlatnikHelpLink;
    case ServiceIntegration.Webhook:
      return webhookHelpLink;
    case ServiceIntegration.Quickbooks:
      return quickBooksHelpLink;
    case ServiceIntegration.Enova:
      return enovaHelpLink;
    default:
      return defaultHelpLink;
  }
};
