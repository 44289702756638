import { Trans } from '@lingui/macro';
import { isEqual } from 'lodash';
import { FC } from 'react';
import { Flex, Text } from 'theme-ui';

import { DateTimeKind } from 'api/actions/requests/requestsActions.types';
import { ParsedRequest } from 'state/requests';
import { useRequestsLogic } from '../../../../../../../../hooks/useRequestsLogic';
import { DisplayDate } from '../../../../../../../../utils/displayDate';
import { useRequestDetails } from '../../../../../hooks/useRequestDetails';
import {
  newComparisonDifferenceSx,
  originalComparisonDifferenceSx,
  requestChangeTextEventSx,
  requestChangeTextTileSx,
  requestChangeWrapperSx,
} from '../styles/styles';

type Props = {
  requestDetails: ParsedRequest | null;
  displayDate: (dateDetails: DisplayDate) => string;
};

const OriginalDate: FC<Props> = ({ requestDetails, displayDate }) => {
  if (!requestDetails?.originalEvent) return null;

  return (
    <Text
      sx={{
        ...requestChangeTextEventSx,
        ...(requestDetails?.newEvent &&
          !isEqual(
            requestDetails?.newEvent?.requestDateTimeDetails.dates,
            requestDetails?.originalEvent?.requestDateTimeDetails.dates,
          ) &&
          originalComparisonDifferenceSx),
      }}
    >
      {displayDate({
        type: requestDetails?.type,
        isDateBound: requestDetails?.originalEvent?.requestDateTimeDetails.kind === DateTimeKind.Local,
        dateRange: requestDetails?.originalEvent?.requestDateTimeDetails.dates,
        dateUnix: requestDetails?.originalEvent?.requestDateTimeDetails.dates[0],
      })}
    </Text>
  );
};

const NewDate: FC<Props> = ({ requestDetails, displayDate }) => (
  <Text
    sx={{
      ...requestChangeTextEventSx,
      ...(requestDetails?.originalEvent &&
        !isEqual(
          requestDetails?.newEvent?.requestDateTimeDetails.dates,
          requestDetails?.originalEvent?.requestDateTimeDetails.dates,
        ) &&
        newComparisonDifferenceSx),
    }}
  >
    {displayDate({
      type: requestDetails?.type,
      isDateBound: requestDetails?.newEvent?.requestDateTimeDetails.kind === DateTimeKind.Local,
      dateRange: requestDetails?.newEvent?.requestDateTimeDetails.dates,
      dateUnix: requestDetails?.newEvent?.requestDateTimeDetails.dates[0],
    })}
  </Text>
);

export const RequestChangeDate: FC = () => {
  const { parsedRequestDetails: requestDetails } = useRequestDetails();
  const {
    displayer: { displayDate },
  } = useRequestsLogic();

  return (
    <Flex sx={requestChangeWrapperSx}>
      <Text sx={requestChangeTextTileSx}>
        <Trans id="requests.details.date_time">Date & time</Trans>
      </Text>
      <OriginalDate requestDetails={requestDetails} displayDate={displayDate} />
      <NewDate requestDetails={requestDetails} displayDate={displayDate} />
    </Flex>
  );
};
