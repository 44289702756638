import { useRecoilState, useRecoilValue } from 'recoil';
import { useClient } from 'react-fetching-library';
import { useCallback, useEffect, useRef } from 'react';
import { Text } from 'theme-ui';
import _ from 'lodash';
import { Trans } from '@lingui/macro';

import { setOnboardingAsShown } from 'api/actions/onboarding/onboardingActions';
import { isNewAccountOnboardingAtom, onboardingAtom, showInitialOnboardingSelector } from 'state/onboarding';
import { useDialogModal } from 'context/dialogModal/DialogModalProvider';

export const useInitialOnboarding = (initialize = false) => {
  const [onboardingState, setOnboardingState] = useRecoilState(onboardingAtom);
  const [isNewAccountOnboarding, setIsNewAccountOnboarding] = useRecoilState(isNewAccountOnboardingAtom);
  const showInitialOnboarding = useRecoilValue(showInitialOnboardingSelector);

  const initializedRef = useRef(false);

  const { isVisible, activeStep, stepsLength } = onboardingState;

  const { query } = useClient();

  const startOnboarding = useCallback(() => {
    if (!isVisible) {
      setOnboardingState(() => ({
        ...onboardingState,
        isVisible: true,
      }));
    }

    if (isVisible) {
      setOnboardingState(() => ({
        ...onboardingState,
        activeStep: 1,
      }));
    }
  }, [isVisible, onboardingState, setOnboardingState]);

  const openContinueOnboardingModalDialog = useDialogModal(
    {
      title: <Trans id="continue_onboarding_modal.title">Are you sure?</Trans>,
      body: (
        <Text>
          <Trans id="onboarding.hint.where_to_start">
            You can always restart onboarding, just click on your profile in the navbar and select{' '}
            <b>Show onboarding</b>.
          </Trans>
        </Text>
      ),
      actions: [
        {
          resolveWith: false,
          buttonProps: {
            children: <Trans id="continue_onboarding_modal.close">Close onboarding</Trans>,
            variant: 'naked',
          },
        },
        {
          resolveWith: true,
          buttonProps: {
            children: <Trans id="modal.continue">Continue</Trans>,
            variant: 'primary',
          },
        },
      ],
    },
    true,
  );

  const internalStopOnboarding = useCallback(async () => {
    if (!isVisible) return;
    if (onboardingState.activeStep === 1 && isNewAccountOnboarding) {
      const continueOnboarding = await openContinueOnboardingModalDialog();
      if (continueOnboarding || _.isNull(continueOnboarding)) {
        return;
      }
    }
    setIsNewAccountOnboarding(false);
    setOnboardingState(() => ({
      ...onboardingState,
      activeStep: 1,
      isVisible: false,
    }));
  }, [
    isVisible,
    onboardingState,
    setOnboardingState,
    openContinueOnboardingModalDialog,
    setIsNewAccountOnboarding,
    isNewAccountOnboarding,
  ]);

  const stopOnboarding = useCallback(() => {
    void internalStopOnboarding();
  }, [internalStopOnboarding]);

  const playNextStep = useCallback(() => {
    if (!!stepsLength && activeStep + 1 < stepsLength) {
      setOnboardingState(() => ({
        ...onboardingState,
        activeStep: activeStep + 1,
      }));
    }
  }, [activeStep, onboardingState, setOnboardingState, stepsLength]);

  const playCustomNextStep = useCallback(
    (steps: number) => {
      if (!!stepsLength && activeStep + steps < stepsLength) {
        setOnboardingState(() => ({
          ...onboardingState,
          activeStep: activeStep + steps,
        }));
      }
    },
    [activeStep, onboardingState, setOnboardingState, stepsLength],
  );

  const playPrevStep = useCallback(() => {
    if (!!stepsLength && activeStep - 1 > -1) {
      setOnboardingState(() => ({
        ...onboardingState,
        activeStep: activeStep - 1,
      }));
    }
  }, [activeStep, onboardingState, setOnboardingState, stepsLength]);

  const showWarningStep = useCallback(() => {
    if (isVisible) {
      setOnboardingState(() => ({
        ...onboardingState,
        activeStep: 0,
      }));
    }
  }, [isVisible, onboardingState, setOnboardingState]);

  const setStepsLength = useCallback(
    (newStepsLength: number) => {
      setOnboardingState(() => ({
        ...onboardingState,
        stepsLength: newStepsLength,
      }));
    },
    [onboardingState, setOnboardingState],
  );

  const setInitialOnboardingAsShown = useCallback(async () => {
    await query(setOnboardingAsShown());
    startOnboarding();
  }, [query, startOnboarding]);

  useEffect(() => {
    if (initialize && showInitialOnboarding && !initializedRef.current) {
      setIsNewAccountOnboarding(true);
      initializedRef.current = true;
      void setInitialOnboardingAsShown();
    }
  }, [initialize, isVisible, setInitialOnboardingAsShown, setIsNewAccountOnboarding, showInitialOnboarding]);

  return {
    isVisible,
    stepsLength,
    activeStep,
    startOnboarding,
    stopOnboarding,
    playNextStep,
    playCustomNextStep,
    playPrevStep,
    setStepsLength,
    showWarningStep,
  };
};
