import { Trans } from '@lingui/macro';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { TimeEventActionResponse } from 'api/actions/timeEvent/timeEventActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { extTimeEventsSelectorFamily, ExtTimeEventsSelectorListType } from 'state/clockLog';

import { EventDetails } from './components/EventDetails';
import { EventHistory } from './components/EventHistory/EventHistory';

type Props = {
  eventListType?: ExtTimeEventsSelectorListType;
};

export const ShowEventHistoryModal = ({ eventListType }: Props): React.ReactElement | null => {
  const { state } = useLocation();
  const { id } = state || {};
  const { baseRoute } = useModal({ wrapperSx: { minHeight: '400px' } });
  const extTimeEvents = useRecoilValue(extTimeEventsSelectorFamily(eventListType || 'all'));

  if (!id) return <Navigate to={baseRoute} relative="path" />;

  const extTimeEvent = extTimeEvents?.get(id);

  if (!extTimeEvent) return <Navigate to={baseRoute} relative="path" />;

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="clock_log.modification_timeline">Modification Timeline</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        sx={{
          p: '0 24px 24px',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <EventDetails extTimeEvent={extTimeEvent} />

        <EventHistory
          timeEventId={id}
          sx={{
            flex: '1 0',
            bg: 'alphas.darker1',
            borderRadius: 'sm',
            border: '1px solid',
            borderColor: 'clockLog.eventHistory.show',
            px: 3,
            pt: 3,
            pb: 0,
          }}
          extTimeEvent={extTimeEvent.action !== TimeEventActionResponse.Create ? undefined : extTimeEvent}
        />
      </Modal.Body>
    </>
  );
};
