import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import _ from 'lodash';

import { Footer, Header, Section, SummaryButton, TimeEventHistoryCard } from 'Kiosk/Layout';
import { EXIT_TIME_EVENT_ID } from 'Kiosk/constants/constants';
import { GetInfoResponse, TimeEvent, TimeEventType } from 'api/actions/timeclock/timeclockActions.types';
import { UUID } from 'utils/UUID';
import { Button } from 'components/ui/Buttons';
import { timeTz } from 'utils/dateTime';
import { selectedTimeEventSelector, showChangeTimeEventSelector } from 'Kiosk/state/summaryState';
import { ElementGroup } from 'components/ui/ElementGroup';
import { Container } from 'components/ui/Container';
import { SummaryButtonType } from 'Kiosk/components/ui/SummaryButton';

type Props = {
  getInfoPayload: GetInfoResponse;
};

export const ChangeTimeEvent = ({ getInfoPayload }: Props): React.ReactElement => {
  const { recentTimeEvents, workTimeSeconds, lastEnterUnix, timeEventTypes } = getInfoPayload;

  const setSelectedTimeEvent = useSetRecoilState(selectedTimeEventSelector);
  const setShowChangeStatus = useSetRecoilState(showChangeTimeEventSelector);

  const getTimeEventButtonShape = (index: number, arrayLength: number): SummaryButtonType['shape'] => {
    if (arrayLength === 1) {
      return 'rounded';
    }
    if (index === 0) {
      return 'roundedTop';
    }
    if (index + 1 === arrayLength) {
      return 'roundedBottom';
    }
    return 'flat';
  };

  const handleSetSelectedTimeEvent = useCallback(
    (timeEvent: TimeEvent) => {
      setSelectedTimeEvent(timeEvent);
      setShowChangeStatus(false);
    },
    [setSelectedTimeEvent, setShowChangeStatus],
  );

  const handleWorkTimeTimeEventSet = () => {
    handleSetSelectedTimeEvent({
      type: {
        id: EXIT_TIME_EVENT_ID,
        name: i18n
          ._(
            t({
              id: 'kiosk.change_status.exit_status_name',
              message: 'End of work!',
            }),
          )
          .toUpperCase(),
        isEnd: true,
      },
      durationSeconds: workTimeSeconds,
    });
  };

  const availableTimeEvents = _.reject(timeEventTypes, (timeEvent) =>
    _.find(recentTimeEvents, {
      type: {
        id: timeEvent.id,
      },
    }),
  ) as TimeEventType[];

  return (
    <Container
      size="sm"
      sx={{
        color: 'kiosk.summary.text',
        textAlign: 'center',
        flex: '1 0',
        justifyContent: 'center',
      }}
    >
      <Header>
        <Header.Title sx={{ mb: 0 }}>
          <Trans id="kiosk.change_status.lead.header">Change status</Trans>
        </Header.Title>
      </Header>

      <Section sx={{ flex: '1 0 0', overflowY: 'auto' }}>
        <ElementGroup marginAt="end" direction="column" marginValue="8px">
          <TimeEventHistoryCard
            isWorkTimeEnd
            onClick={handleWorkTimeTimeEventSet}
            timeEventName={i18n._(
              t({
                id: 'kiosk.change_status.exit_status_title',
                message: 'Your work time',
              }),
            )}
            duration={workTimeSeconds}
            workStartTime={lastEnterUnix ? `${timeTz(lastEnterUnix).format('HH:mm')}` : ''}
          />
          <>
            {recentTimeEvents.map((timeEvent) => (
              <TimeEventHistoryCard
                key={UUID()}
                onClick={() => {
                  handleSetSelectedTimeEvent({
                    ...timeEvent,
                    type: { ...timeEvent.type, isEnd: false },
                  });
                }}
                timeEventName={t({
                  id: `${timeEvent.type.name}`,
                })}
                duration={timeEvent.durationSeconds}
                allowedDuration={timeEvent.maxDurationAllowedSeconds}
              />
            ))}
          </>
        </ElementGroup>

        {availableTimeEvents.length > 0 && (
          <ElementGroup marginAt="end" direction="column" wrapperSx={{ mt: 5, width: '100%' }}>
            {availableTimeEvents.map(({ id, name }, index) => (
              <SummaryButton
                key={UUID()}
                onClick={() => {
                  handleSetSelectedTimeEvent({
                    type: { id, name, isEnd: false },
                  });
                }}
                shape={getTimeEventButtonShape(index, availableTimeEvents.length)}
              >
                {t({
                  id: `${name}`,
                })}
              </SummaryButton>
            ))}
          </ElementGroup>
        )}
      </Section>

      <Footer sx={{ mt: 5 }}>
        <ElementGroup marginAt="end" direction="column">
          <Button
            onClick={() => {
              setShowChangeStatus(false);
            }}
            bgOverwrite={{
              default: 'kiosk.summary.btn.cancel.bg.default',
              hover: 'kiosk.summary.btn.cancel.bg.hover',
              tap: 'kiosk.summary.btn.cancel.bg.tap',
              disabled: 'kiosk.summary.btn.cancel.bg.disabled',
            }}
            sx={{
              color: 'kiosk.summary.btn.text',
              fontWeight: '400',
              fontSize: 4,
            }}
            variant="naked"
            size="lg"
            type="button"
          >
            <Trans id="kiosk.change_status.cancel_button">Cancel change</Trans>
          </Button>
        </ElementGroup>
      </Footer>
    </Container>
  );
};
