import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TextInput, TextInputProps } from 'components/ui/TextInput';
import { INPUT_DEBOUNCE_TIME } from 'constants/requests';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';

export const Place = (): React.ReactElement => {
  useLingui();

  const {
    register,
    formState: { errors },
  } = useFormContext<{ details: { place: string } }>();

  const placeRegister = register(
    'details.place',
    validationFactory({ ...VALIDATION_RULES.BUSINESS_TRIP_PLACE, required: true }),
  );

  const handleOnChange: NonNullable<TextInputProps['onChange']> = _.debounce<NonNullable<TextInputProps['onChange']>>(
    (e) => void placeRegister.onChange(e),
    INPUT_DEBOUNCE_TIME,
  );

  return (
    <TextInput
      id="place"
      type="text"
      {..._.omit(placeRegister, 'onChange')}
      onChange={handleOnChange}
      label={t({ id: 'add_request.business_trip.place', message: 'Place' })}
      labelProps={{ sx: { fontSize: 3, ml: -2, mb: 2 } }}
      placeholder={t({
        id: 'add_request.business_trip.street_city',
        message: 'Street, City',
      })}
      variant="rounded"
      size="sm"
      error={!!errors.details?.place}
      errorMessage={errors.details?.place?.message}
    />
  );
};
