import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import { FetchOrganizationSessionResponse } from './organizationSessionActions.types';

export function fetchOrganizationSessionAction(
  config?: Action<FetchOrganizationSessionResponse>['config'],
): Action<FetchOrganizationSessionResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.organizationSession,
    config,
  };
}
