import { Provider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';
import React from 'react';
import Rollbar from 'rollbar';

import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { ErrorPage } from 'components/ErrorBoundary/ErrorPage';
import { APP_VERSION } from 'constants/common';
import { AppRoutingObserver } from 'hooks/useAppRouting/useAppRouting';
import { BroadcastChannelObserver } from 'observers/BroadcastChannelObserver';
import { LocationObserver } from 'observers/LocationObserver';
import { PersistenceObserver } from 'observers/PersistanceObserver';
import { RedirectObserver } from 'observers/RedirectObserver';
import { SetRecoilStateEventObserver } from 'observers/SetRecoilStateEventObserver';

import { App } from './components/App/App';
import { AppContext } from './components/App/AppContext';

const debugMode = process.env.NODE_ENV !== 'production';

const rollbarConfig: Rollbar.Configuration = {
  enabled: !debugMode,
  accessToken: 'ecb790c598f24d779b7eeb118ae5d697',
  environment: !debugMode ? 'production' : 'debug',
  captureUncaught: true,
  captureUnhandledRejections: true,
  logLevel: 'error',
  scrubFields: [
    'Card[OwnerName]',
    'Card[Cvc]',
    'Card[Number]',
    'Card[MonthAndYear]',
    'OwnerName',
    'Cvc',
    'Number',
    'MonthAndYear',
  ],
  payload: {
    environment: !debugMode ? 'production' : 'debug',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: APP_VERSION,
        guess_uncaught_frames: true,
      },
    },
  },
};

export const Root = (): React.ReactElement => (
  <Provider config={rollbarConfig}>
    <RollbarErrorBoundary>
      <ErrorBoundary fallback={<ErrorPage />}>
        <AppContext>
          <App />
          <AppRoutingObserver />
          <RedirectObserver />
          <PersistenceObserver />
          <SetRecoilStateEventObserver />
          <BroadcastChannelObserver />
          <LocationObserver />
        </AppContext>
      </ErrorBoundary>
    </RollbarErrorBoundary>
  </Provider>
);
