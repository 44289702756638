import React, { useEffect } from 'react';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';

import { languageSelector, timeFormatSelector } from 'state/recoilState';
import { startingWeekDaySelector } from 'state/organizationSession';
import { StartingWeekDay } from 'api/actions/organizationSession/organizationSessionActions.types';

const initialLocale = 'en';

i18n.load(initialLocale, {});
i18n.activate(initialLocale);

export async function setLanguage(
  locale: string,
  weekStart: StartingWeekDay | null,
  timeFormat: '12' | '24',
): Promise<void> {
  let catalog = { messages: {} };

  if (process.env.NODE_ENV === 'test') {
    catalog = await import(`locales/${locale}/messages.js`);
  } else {
    catalog = await import(`@lingui/loader!locales/${locale}/messages.po`);
  }

  const { messages } = catalog;

  i18n.load(locale, messages);
  i18n.activate(locale);

  if (locale !== 'en') {
    await import(`dayjs/locale/${locale}.js`);
  }

  // EXAMPLES:
  // formats: {
  //   LT: 'HH:mm',
  //   LTS: 'HH:mm:ss',
  //   L: 'DD.MM.YYYY',
  //   LL: 'D MMMM YYYY',
  //   LLL: 'D MMMM YYYY HH:mm',
  //   LLLL: 'dddd, D MMMM YYYY HH:mm'
  // }

  // formats: {
  //   LT: 'h:mm A',
  //   LTS: 'h:mm:ss A',
  //   L: 'YYYY-MM-DD',
  //   LL: 'MMMM D, YYYY',
  //   LLL: 'MMMM D, YYYY h:mm A',
  //   LLLL: 'dddd, MMMM D, YYYY h:mm A'
  // },

  dayjs.locale(locale);
  const getLocaleData = dayjs.localeData();

  const defaultLocale = {
    LT: getLocaleData.longDateFormat('LT'),
    LTS: getLocaleData.longDateFormat('LTS'),
    L: getLocaleData.longDateFormat('L'),
    LL: getLocaleData.longDateFormat('LL'),
    LLL: getLocaleData.longDateFormat('LLL'),
    LLLL: getLocaleData.longDateFormat('LLLL'),
    l: getLocaleData.longDateFormat('l'),
    ll: getLocaleData.longDateFormat('ll'),
    lll: getLocaleData.longDateFormat('lll'),
    llll: getLocaleData.longDateFormat('llll'),
  };

  const getTimeFormat = () => {
    const isDoubleDigitHourFormat = defaultLocale.LT.includes('HH') || defaultLocale.LT.includes('hh');

    if (timeFormat === '12') {
      return isDoubleDigitHourFormat
        ? {
            LT: 'hh:mm A',
            LTS: 'hh:mm:ss A',
          }
        : {
            LT: 'h:mm A',
            LTS: 'h:mm:ss A',
          };
    }

    return isDoubleDigitHourFormat
      ? {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
        }
      : {
          LT: 'H:mm',
          LTS: 'H:mm:ss',
        };
  };

  const newTimeFormat = getTimeFormat();

  const parsedLocaleFormats = {
    LT: newTimeFormat.LT,
    LTS: newTimeFormat.LTS,
    LLL: defaultLocale.LLL.split(defaultLocale.LT)[0] + newTimeFormat.LT,
    LLLL: defaultLocale.LLLL.split(defaultLocale.LT)[0] + newTimeFormat.LT,
    lll: defaultLocale.lll.split(defaultLocale.LT)[0] + newTimeFormat.LT,
    llll: defaultLocale.llll.split(defaultLocale.LT)[0] + newTimeFormat.LT,
  };

  // Experimental
  // 2022-05-17 - Tested by @jpytlik, did not found any unexpected behavior
  dayjs.updateLocale(locale, {
    weekStart,
    formats: {
      ...defaultLocale,
      ...parsedLocaleFormats,
    },
  });
}

type Props = {
  children: React.ReactElement[] | React.ReactElement;
};

export const LanguageProvider = ({ children }: Props): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const startingWeekDay = useRecoilValue(startingWeekDaySelector);
  const timeFormat = useRecoilValue(timeFormatSelector);

  useEffect(() => {
    // if (language && startingWeekDay && timeFormat) {
    //   void setLanguage(language, startingWeekDay, timeFormat as '12' | '24');
    // }
    void setLanguage(language, startingWeekDay, timeFormat as '12' | '24');
  }, [language, startingWeekDay, timeFormat]);

  return (
    <>
      <Helmet>
        <html lang={language} />
      </Helmet>
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </>
  );
};
