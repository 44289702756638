import { t } from '@lingui/macro';
import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';

import { EmployeeImportType } from 'api/actions/employees/employeesActions.types';
import { integrationRefreshTokenAction } from 'api/actions/integrations/integrationActions';
import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import { DropdownProps } from 'components/Dropdown/Dropdown';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { activeIntegrationsSelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';

export const useActiveIntegrationsLinks = (
  toValidator: () => boolean,
  integrationSelector: RecoilValueReadOnly<ServiceIntegration[] | null> = activeIntegrationsSelector,
  actionType: 'import' | 'export' = 'export',
) => {
  const navigate = useAppNavigate();
  const activeIntegrations = useRecoilValue(integrationSelector);
  const language = useRecoilValue(languageSelector);
  const [isLoading, setIsLoading] = useState(false);
  const actionPath = useMemo(() => {
    if (actionType === 'import') {
      return TO_REL.IMPORT_MODAL__TYPE[language];
    }

    return TO_REL.EXPORT_TO_MODAL__TYPE[language];
  }, [actionType, language]);

  const { mutate: mutateRefreshIntegrationToken } = useMutation(integrationRefreshTokenAction);

  const handleExternalIntegration: (serviceIntegration: ServiceIntegration) => Promise<void> = useCallback(
    async (serviceIntegration) => {
      setIsLoading(true);
      const { error } = await mutateRefreshIntegrationToken({ integration: serviceIntegration });
      const importIntegration: EmployeeImportType | null = (() => {
        switch (serviceIntegration) {
          case ServiceIntegration.Optima:
            return EmployeeImportType.Optima;
          case ServiceIntegration.Symfonia:
            return EmployeeImportType.Symfonia;
          case ServiceIntegration.Quickbooks:
            return EmployeeImportType.Quickbooks;
          case ServiceIntegration.Xero:
            return EmployeeImportType.Xero;
          case ServiceIntegration.SquarePayroll:
            return EmployeeImportType.Square;
          default:
            return null;
        }
      })();
      if (!error) {
        navigate(`${actionPath}/${actionType === 'export' ? serviceIntegration : importIntegration}`, {
          relative: 'path',
        });
      }
      setIsLoading(false);
    },
    [actionPath, actionType, mutateRefreshIntegrationToken, navigate],
  );

  const activeIntegrationsLinks = useMemo(() => {
    if (!activeIntegrations?.length) return [];

    const mapIntegrationsActiveLinks = activeIntegrations.map((integration) => {
      switch (integration) {
        case ServiceIntegration.GratyfikantGT:
          return {
            label: t({ id: 'reports.export.gratyfikant', message: 'To Gratyfikant GT' }),
            toValidation: toValidator,
            to: `${actionPath}/${ServiceIntegration.GratyfikantGT}`,
            relative: 'path' as const,
          };
        case ServiceIntegration.GratyfikantNexo:
          return {
            label: t({ id: 'reports.export.gratyfikant_nexo', message: 'To Gratyfikant Nexo' }),
            toValidation: toValidator,
            to: `${actionPath}/${ServiceIntegration.GratyfikantNexo}`,
            relative: 'path' as const,
          };
        case ServiceIntegration.R2Platnik:
          return {
            label: t({ id: 'reports.export.r2', message: 'To R2Płatnik' }),
            toValidation: toValidator,
            to: `${actionPath}/${ServiceIntegration.R2Platnik}`,
            relative: 'path' as const,
          };
        case ServiceIntegration.Optima:
          return {
            label:
              actionType === 'export'
                ? t({ id: 'reports.export.optima', message: 'To Comarch Optima' })
                : t({ id: 'team.header.import_comarch_optima', message: 'From Comarch Optima' }),
            toValidation: toValidator,
            to:
              actionType === 'export'
                ? `${actionPath}/${ServiceIntegration.Optima}`
                : `${actionPath}/${EmployeeImportType.Optima}`,
          };
        case ServiceIntegration.Symfonia:
          return {
            label:
              actionType === 'export'
                ? t({ id: 'reports.export.symfonia', message: 'To Symfonia' })
                : t({ id: 'team.header.import_symfonia', message: 'From Symfonia' }),
            toValidation: toValidator,
            to:
              actionType === 'export'
                ? `${actionPath}/${ServiceIntegration.Symfonia}`
                : `${actionPath}/${EmployeeImportType.Symfonia}`,
          };
        case ServiceIntegration.Quickbooks:
          return {
            label:
              actionType === 'export'
                ? t({ id: 'reports.export.quickbooks', message: 'To QuickBooks' })
                : t({ id: 'team.header.import_quickbooks', message: 'From QuickBooks' }),
            toValidation: toValidator,
            onClick: () => handleExternalIntegration(ServiceIntegration.Quickbooks),
            isButton: true,
          };
        case ServiceIntegration.Enova:
          return {
            label:
              actionType === 'export'
                ? t({ id: 'reports.export.enova', message: 'To Enova' })
                : t({ id: 'reports.export.import_enova', message: 'From Enova' }),
            toValidation: toValidator,
            to:
              actionType === 'export'
                ? `${actionPath}/${ServiceIntegration.Enova}`
                : `${actionPath}/${EmployeeImportType.Enova}`,
          };
        case ServiceIntegration.Xero:
          return {
            label:
              actionType === 'export'
                ? t({ id: 'reports.export.xero', message: 'To Xero' })
                : t({ id: 'team.header.import_xero', message: 'From Xero' }),
            toValidation: toValidator,
            onClick: () => handleExternalIntegration(ServiceIntegration.Xero),
            isButton: true,
          };
        case ServiceIntegration.SquarePayroll:
          return {
            label:
              actionType === 'export'
                ? t({ id: 'reports.export.square', message: 'To Square' })
                : t({ id: 'team.header.import_square', message: 'From Square' }),
            toValidation: toValidator,
            onClick: () => handleExternalIntegration(ServiceIntegration.SquarePayroll),
            isButton: true,
          };
        default:
          return null;
      }
    });

    return mapIntegrationsActiveLinks.filter((link) => link) as DropdownProps['links'];
  }, [activeIntegrations, toValidator, actionPath, actionType, handleExternalIntegration]);

  return { activeIntegrationsLinks, isLoading };
};
