import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { cameraImageBufferAtom, CameraImageBufferState } from 'Kiosk/state/cameraImageBufferState';

type ReturnValue = CameraImageBufferState;
export const MIN_REQUIRED_SNAPSHOTS_LENGTH = 13;
export const SNAPSHOTS_COLLECTION_BUFFER = 200;

export const useCameraImageBuffer = (): ReturnValue => {
  const snapshotsBlobUrlsRef = useRef<string[]>([]);

  const getSnapshotsBlobUrlsCall = useCallback(() => snapshotsBlobUrlsRef.current, []);

  const { source, isCameraStreaming } = useRecoilValue(cameraStateAtom);

  const [
    {
      imageBufferCanvasCallback,
      imageBufferReady,
      getImageData: getImageDataFromAtom,
      getImageBlob: getImageBlobFromAtom,
      getBlob,
    },
    setCameraImageBufferState,
  ] = useRecoilState(cameraImageBufferAtom);

  const webpSupport = useMemo(
    () => imageBufferCanvasCallback()?.current?.toDataURL('image/webp').indexOf('data:image/webp') === 0,
    [imageBufferCanvasCallback],
  );

  const getCanvasImage = useCallback(async () => {
    if (imageBufferReady && imageBufferCanvasCallback && isCameraStreaming && source && source.video) {
      const canvas = imageBufferCanvasCallback();

      if (canvas && canvas.current) {
        const ctx = canvas.current.getContext('2d');
        if (ctx && ctx.canvas.width > 0) {
          ctx.drawImage(source.video, 0, 0, ctx.canvas.width, ctx.canvas.height);
          const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);

          return new Promise<{ imageData: ImageData; imageBlob: Blob; imageUrl: string; removedSnapshot?: string }>(
            (resolve) => {
              if (canvas && canvas.current) {
                canvas.current.toBlob(
                  (snap) => {
                    if (!snap) {
                      return;
                    }
                    const url = URL.createObjectURL(snap);
                    const snapshots = snapshotsBlobUrlsRef.current;
                    let removedSnapshot: string | undefined;

                    if (snapshots.length >= SNAPSHOTS_COLLECTION_BUFFER) {
                      URL.revokeObjectURL(snapshots[0]);
                      removedSnapshot = snapshots.shift();
                    }

                    snapshots.push(url);

                    resolve({
                      imageData,
                      imageUrl: url,
                      imageBlob: snap,
                      removedSnapshot,
                    });
                  },
                  webpSupport ? 'image/webp' : 'image/jpeg',
                  webpSupport ? 0.5 : 0.7,
                );
              }
            },
          );
        }
      }
    }

    return undefined;
  }, [imageBufferCanvasCallback, imageBufferReady, isCameraStreaming, source, webpSupport]);

  const getImageData = useCallback(async () => {
    const canvasImage = await getCanvasImage();
    if (canvasImage) {
      const { imageData, imageUrl, removedSnapshot } = canvasImage;

      return { imageData, imageUrl, removedSnapshot };
    }

    return undefined;
  }, [getCanvasImage]);

  const getImageBlob = useCallback(async () => {
    const canvasImage = await getCanvasImage();
    if (canvasImage) {
      const { imageBlob } = canvasImage;

      return imageBlob;
    }

    return undefined;
  }, [getCanvasImage]);

  useEffect(() => {
    setCameraImageBufferState((prevState) => ({
      ...prevState,
      getBlob: getSnapshotsBlobUrlsCall,
      getImageData,
      getImageBlob,
    }));
  }, [getCanvasImage, getImageBlob, getImageData, getSnapshotsBlobUrlsCall, setCameraImageBufferState]);

  return {
    imageBufferReady,
    imageBufferCanvasCallback,
    getBlob,
    getImageData: getImageDataFromAtom,
    getImageBlob: getImageBlobFromAtom,
  };
};
