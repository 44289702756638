/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/require-await */
import { RequestInterceptor } from 'react-fetching-library';

import { Action } from 'api/types';

export const requestHostInterceptor: (host: string) => RequestInterceptor =
  (host) => () => async (action: Action<unknown>) => {
    if (action.config && action.config.skipHost) {
      return action;
    }

    if (action.endpoint.startsWith('http') || action.endpoint.startsWith('//')) {
      return action;
    }

    return {
      ...action,
      endpoint: `${host}${action.endpoint}`,
    };
  };
