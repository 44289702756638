import React from 'react';
import { css, Global } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { useRecoilValue } from 'recoil';

import { useWindowSize } from 'hooks/useWindowSize/useWindowSize';
import { APP_NAME } from 'constants/common';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { boostModalZIndexAtom } from 'state/modal';

type Props = {
  children: React.ReactNode;
};

export const Layout = ({ children }: Props): React.ReactElement => {
  const boostModalZIndex = useRecoilValue(boostModalZIndexAtom);
  const { width, height } = useWindowSize({ useRecoil: true });
  const { isMobileBreakpoint } = useThemeBreakpoint();

  return (
    <>
      <Helmet>
        <meta name="author" content={APP_NAME} />
        {isMobileBreakpoint && (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no"
          />
        )}
        <link
          rel="icon"
          href={APP_NAME === 'inEwi' ? '/app/favicon-inewi.svg' : '/app/favicon-unrubble.svg'}
          sizes="any"
          type="image/svg+xml"
        />
        <link
          rel="apple-touch-icon"
          href={APP_NAME === 'inEwi' ? '/app/apple-touch-icon-inewi.png' : '/app/apple-touch-icon-unrubble.png'}
        />
        <link
          rel="manifest"
          href={APP_NAME === 'inEwi' ? '/app/site-inewi.webmanifest' : '/app/site-unrubble.webmanifest'}
        />
      </Helmet>

      <Global
        styles={(theme) => ({
          ':root': {
            '--app-height': `${height}px`,
            '--app-width': `${width}px`,
          },
          // Only for testing focus for accessibility
          // '*': {
          //   '&:focus-visible': {
          //     outline: '2px auto red !important',
          //   },
          // },
          'html, body': {
            display: 'flex',
            fontFamily: theme.fonts.body,
            lineHeight: theme.lineHeights.body,
            fontWeight: theme.fontWeights.body,
          },
          html: {
            justifyContent: 'stretch',
            alignItems: 'stretch',
            width: '100%',
            height: '100%',
          },
          body: {
            flexGrow: 1,
            maxHeight: '100%',
            overflow: 'hidden',
          },
          ul: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
          },
          a: {
            color: 'inherit',
            textDecoration: 'inherit',
          },
          '#app': {
            display: 'flex',
            flexGrow: 1,
            overflow: 'auto',
            overflowX: 'hidden',
            '@media screen and (max-width: 991px) ': {
              flexWrap: 'wrap',
            },
          },
          '#modal-root': {
            position: 'relative',
            zIndex: boostModalZIndex ? theme.zIndices.max : theme.zIndices.modal,
          },
          '#snackbar-root': {
            position: 'relative',
            zIndex: theme.zIndices.snackbar,
          },
          '#notification-root': {
            position: 'fixed',
            top: '100px',
            right: '30px',
            zIndex: theme.zIndices.notifications,
            '@media screen and (max-width: 500px) ': {
              right: '50%',
              transform: 'translateX(50%)',
            },
          },
          '#popper-root': {
            position: 'relative',
            zIndex: theme.zIndices.popper,
          },
          '#chat-root': {
            position: 'absolute',
            bottom: 0,
            right: 0,
            zIndex: theme.zIndices.chat,
            pointerEvents: 'none',
            height: 0,
            overflow: 'visible',
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'flex-end',
            '@media screen and (max-width: 991px) ': {
              zIndex: theme.zIndices.navBar + 1,
            },
          },
          '#minimize-modal-root': {
            position: 'relative',
            zIndex: theme.zIndices.minimizeModalBar,
          },
        })}
      />
      <Global
        styles={css`
          @font-face {
            font-family: 'Proxima Nova Soft';
            src:
              url('https://cdn.inewi.pl/fonts/ProximaNovaSoftRegularWebfont.woff2') format('woff2'),
              url('https://cdn.inewi.pl/fonts/ProximaNovaSoftRegularWebfont.woff') format('woff'),
              url('https://cdn.inewi.pl/fonts/ProximaNovaSoftRegularWebfont.ttf') format('truetype');
            font-weight: 400;
            font-style: normal;
            font-display: fallback;
          }
          @font-face {
            font-family: 'Proxima Nova Soft';
            src:
              url('https://cdn.inewi.pl/fonts/ProximaNovaSoftBoldWebfont.woff2') format('woff2'),
              url('https://cdn.inewi.pl/fonts/ProximaNovaSoftBoldWebfont.woff') format('woff'),
              url('https://cdn.inewi.pl/fonts/ProximaNovaSoftBoldWebfont.ttf') format('truetype');
            font-weight: 700;
            font-style: normal;
            font-display: fallback;
          }
        `}
      />
      {children}
    </>
  );
};
