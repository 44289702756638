import { getDurationObj } from 'pages/Requests/output/getDurationObj';

export const formattedUsageAndLimits = (
  usage: number,
  limit?: number,
  displayZeroDays?: boolean,
  formatter?: ({ usage, limit, available }: { usage: string; limit: string; available: string }) => string,
): string => {
  let usageString = '';
  let limitString = '';
  let availableString = '';

  const usageObj = getDurationObj(usage);

  if (usageObj.days > 0) {
    usageString += `${usageObj.days}d`;
  }
  if (usageObj.hours > 0) {
    usageString += ` ${usageObj.hours}h`;
  }
  if (usageObj.minutes > 0) {
    usageString += ` ${usageObj.minutes}m`;
  }

  if (limit) {
    const limitObj = getDurationObj(limit);

    if (!formatter) {
      limitString += ` / `;
    } else {
      const available = limit - usage;

      if (available > 0) {
        const availableObj = getDurationObj(available);

        if (availableObj.days > 0) {
          availableString += `${availableObj.days}d`;
        }
        if (availableObj.hours > 0) {
          availableString += ` ${availableObj.hours}h`;
        }
        if (availableObj.minutes > 0) {
          availableString += ` ${availableObj.minutes}m`;
        }
      }
    }

    if (limitObj.days > 0) {
      limitString += `${limitObj.days}d`;
    }
    if (limitObj.hours > 0) {
      limitString += ` ${limitObj.hours}h`;
    }
    if (limitObj.minutes > 0) {
      limitString += ` ${limitObj.minutes}m`;
    }
  }

  if (usageString === '' && (displayZeroDays || limitString !== '')) {
    usageString = '0d';
  }

  if (formatter) {
    return formatter({ usage: usageString, limit: limitString, available: availableString });
  }

  return `${usageString}${limitString}`;
};

export const genericFormatterUsageAndLimits = <T extends Record<string, number>>(
  limits: T,
  displayZeroDays?: boolean,
): Record<keyof T, string> => {
  const transformationResult = {} as Record<keyof T, string>;

  Object.entries(limits).forEach(([key, value]) => {
    const valueDurationObj = getDurationObj(value);
    let valueString = '';
    if (valueDurationObj.days > 0) {
      valueString += `${valueDurationObj.days}d`;
    }
    if (valueDurationObj.hours > 0) {
      valueString += ` ${valueDurationObj.hours}h`;
    }
    if (valueDurationObj.minutes > 0) {
      valueString += ` ${valueDurationObj.minutes}m`;
    }

    if (valueString === '' && displayZeroDays) {
      valueString = '0d';
    }

    transformationResult[key as keyof T] = valueString;
  });

  return transformationResult;
};
