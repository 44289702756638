import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import 'cropperjs/dist/cropper.css';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { AVATAR_MIN_HEIGHT_WIDTH } from 'constants/common';
import { useEvent } from 'hooks/useEvent/useEvent';
import { useMount } from 'hooks/useMount/useMount';
import { windowSizeAtom } from 'state/recoilState';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { setIntervalX } from 'utils/setIntervalX';

const DEFAULT_MODAL_WIDTH = 576;
const DEFAULT_MODAL_HEIGHT = 600;

type Props = {
  onDone: (croppedAvatarUrl: string) => void | Promise<boolean>;
};

export const CropAvatarModal = ({ onDone }: Props): React.ReactElement => {
  useLingui();
  const [loading, setLoading] = useState(false);
  const { isMobile } = useRecoilValue(windowSizeAtom);

  const [cropper, setCropper] = useState<Cropper>();
  const [minCropBoxWidthHeight, setMinCropBoxWidthHeight] = useState(0);
  const [resizeCount, setResizeCount] = useState(0);
  const [modalReady, setModalReady] = useState(isMobile);

  const { state } = useLocation();
  const { avatarUrl, avatarType, avatarHeight, avatarWidth } = state || {};

  const imgHeight = avatarHeight || 0;
  const imgWidth = avatarWidth || 0;

  const isPortrait = imgHeight > imgWidth;
  const isLandscape = imgWidth > imgHeight;

  const cropperRef = useRef<ReactCropperElement>(null);

  const { baseRoute, handleClose } = useModal({
    wrapperSx: {
      width: DEFAULT_MODAL_WIDTH,
      height: DEFAULT_MODAL_HEIGHT,
      '@media screen and (min-height: 767px)': {
        ...(isPortrait &&
          !isMobile &&
          imgHeight > DEFAULT_MODAL_HEIGHT && {
            height: 767,
          }),
      },
      '@media screen and (min-width: 767px)': {
        ...(isLandscape &&
          imgWidth > DEFAULT_MODAL_WIDTH && {
            width: 767,
          }),
      },
      maxWidth: '100%',
      maxHeight: '100%',
    },
    closeOnBackdrop: false,
  });

  const handleResize = useMemo(
    () =>
      _.debounce(() => {
        setResizeCount((prevCount) => prevCount + 1);
      }, 100),
    [],
  );

  useEvent('resize', handleResize);

  useEffect(() => {
    window.history.replaceState({ avatarUrl, avatarHeight }, '');
  }, [avatarHeight, avatarUrl]);

  const onClick = async () => {
    if (!cropper) {
      return;
    }

    const croppedAvatarUrl = cropper
      .getCroppedCanvas({ height: AVATAR_MIN_HEIGHT_WIDTH, width: AVATAR_MIN_HEIGHT_WIDTH })
      .toDataURL(avatarType);

    setLoading(true);
    await onDone(croppedAvatarUrl);
    setLoading(false);
    handleClose();
  };

  const initializeCropBoxDimensions = (instance: Cropper) => {
    const imageData = instance.getImageData();
    if (!imageData.height) {
      return false;
    }
    const { naturalHeight, height } = instance.getImageData();

    const heightRatio = naturalHeight / height;
    const cropBoxMinHeight = AVATAR_MIN_HEIGHT_WIDTH / heightRatio;

    setMinCropBoxWidthHeight(cropBoxMinHeight);
    return true;
  };

  const onInitialized = (instance: Cropper) => {
    setCropper(instance);

    const isCropBoxInitialized = initializeCropBoxDimensions(instance);
    if (isCropBoxInitialized) {
      return;
    }
    // wait till cropper element is rendered
    setIntervalX(() => initializeCropBoxDimensions(instance), 100, 4);
  };

  useMount(() => {
    if (isMobile) {
      return;
    }
    // wait for modal to load its final styles
    setTimeout(() => setModalReady(true), 100);
  });

  if (!avatarUrl) return <Navigate to={baseRoute} relative="path" />;

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="avatar_picker.change">Crop avatar</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex
          id="cropper-wrapper"
          sx={{
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            '&#cropper-wrapper .cropper-line,&#cropper-wrapper .cropper-point': {
              bg: 'manageAvatar.cropper.background',
            },
            '&#cropper-wrapper .cropper-view-box': {
              outlineColor: 'manageAvatar.cropper.outlineColor',
            },
          }}
        >
          {modalReady && (
            <Cropper
              key={`${minCropBoxWidthHeight}-${resizeCount}`}
              style={{
                height: '100%',
                ...(!minCropBoxWidthHeight && {
                  visibility: 'hidden',
                }),
              }}
              src={avatarUrl}
              ref={cropperRef}
              initialAspectRatio={1}
              aspectRatio={1}
              viewMode={2}
              background={false}
              guides={false}
              minCropBoxHeight={minCropBoxWidthHeight}
              minCropBoxWidth={minCropBoxWidthHeight}
              zoomable={false}
              onInitialized={onInitialized}
            />
          )}
        </Flex>
      </Modal.Body>

      <BasicModalFooter
        cancelButtonProps={{
          disabled: loading,
        }}
        buttons={[
          {
            isLoading: loading,
            variant: 'primary',
            onClick: floatingPromiseReturn(onClick),
            children: t({ id: 'avatar_picker.done', message: 'Done' }),
          },
        ]}
      />
    </>
  );
};
