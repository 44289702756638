import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useClient, useMutation } from 'react-fetching-library';
import { Helmet } from 'react-helmet';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { fetchEmployeeAccountRegisterAction, postEmployeeAccountRegisterAction } from 'api/actions/user/userActions';
import { PostEmployeeAccountRegisterPayload } from 'api/actions/user/userActions.types';
import { fetchUserSessionAction } from 'api/actions/userSession/userSessionActions';
import { FormCard } from 'components/FormCard/FormCard';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { APP_NAME } from 'constants/common';
import { QueryParams } from 'constants/queryParams';
import { TO } from 'constants/routes';
import { setAuthorized, setToken } from 'context/auth/authActionCreators/authActionCreators';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { useQuery as useParams } from 'hooks/useQuery/useQuery';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { Main } from 'layouts/Authentication/output/Main';
import { languageSelector } from 'state/recoilState';
import { userSessionAtom } from 'state/userSession';

import { EmployeeForm } from './components/EmployeeForm';

// after logging in, the language can be changed due to the one received in userSession and it couses the Employee component to remount
// in that case thouse booleans prevents a unnecessary fetch of fetchEmployeeAccountRegisterAction see: getName
let userNameSuccessfullyFetched = false;
let formSuccessfullySubmitted = false;

export const Employee = (): React.ReactElement => {
  useLingui();
  const [name, setName] = useState<string | undefined>();
  const language = useRecoilValue(languageSelector);
  const setUserSession = useSetRecoilState(userSessionAtom);

  const dispatch = useAuthDispatch();

  const navigate = useAppNavigate();
  const { addSnackbar } = useSnackbar();
  const { mutate } = useMutation(postEmployeeAccountRegisterAction);

  const urlParams = useParams();
  const token = urlParams.get(QueryParams.Token);
  const userId = urlParams.get(QueryParams.UserId);

  const { query } = useClient();

  const goToSignIn = useCallback(() => {
    navigate(TO.SIGN_IN[language], { replace: true });
  }, [language, navigate]);

  useEffect(() => {
    if (!token || !userId) {
      addSnackbar({
        variant: 'danger',
        message: t({
          id: 'sign_up.employee.error.missing_query',
          message: 'Missing data, check the activation link.',
        }),
      });

      goToSignIn();
    }
  }, [addSnackbar, query, token, userId, goToSignIn]);

  const getName = useCallback(async () => {
    if (token && userId) {
      if (userNameSuccessfullyFetched && formSuccessfullySubmitted) {
        goToSignIn();
        return;
      }
      const { error, payload } = await query(
        fetchEmployeeAccountRegisterAction({
          token,
          userId,
        }),
      );

      if (!error && payload) {
        userNameSuccessfullyFetched = true;
        setName(payload.fullName);
      }

      if (payload && error) {
        goToSignIn();
      }
    }
  }, [goToSignIn, query, token, userId]);

  useEffect(() => {
    void getName();
  }, [getName]);

  const onSubmit = useCallback(
    async (body: Pick<PostEmployeeAccountRegisterPayload, 'password' | 'newsletterSubscription'>): Promise<boolean> => {
      if (!token || !userId) return false;

      const { payload, error } = await mutate({
        ...body,
        token,
        userId,
      });

      if (!error && payload) {
        const { payload: userSession, error: fetchError } = await query(fetchUserSessionAction(payload));

        if (!fetchError && userSession) {
          formSuccessfullySubmitted = true;
          addSnackbar({
            variant: 'success',
            message: t({
              id: 'sign_up.employee.success',
              message: 'Welcome! Your account is fully activated.',
            }),
          });
          dispatch(setToken(payload));
          dispatch(setAuthorized());
          setUserSession(userSession);
          navigate(TO.START[language]);
          return true;
        }
      }

      if (payload && error) {
        goToSignIn();

        return false;
      }

      return false;
    },
    [addSnackbar, dispatch, goToSignIn, language, mutate, navigate, query, setUserSession, token, userId],
  );

  return (
    <>
      <Helmet>
        <title>{t({ id: 'sign_up.page_title', message: `Sign up - ${APP_NAME}` })}</title>
      </Helmet>

      <Main>
        {name ? (
          <FormCard size="default">
            <FormCard.Header sx={{ gap: 3 }}>
              <FormCard.Title>
                <Trans id="sign_up.employee.title_welcome">{name}, it's so nice to meet you! 👋</Trans>
              </FormCard.Title>

              <FormCard.Lead>
                <Trans id="sign_up.employee.lead_password">Create a password for your account.</Trans>
              </FormCard.Lead>

              <FormCard.Lead>{t({ id: 'password_reset_wizard.lead' })}</FormCard.Lead>
            </FormCard.Header>

            <EmployeeForm onSubmit={onSubmit} />
          </FormCard>
        ) : (
          <Flex sx={{ flexGrow: 1, alignItems: 'center' }}>
            <LoadingSpinnerCss size={5} />
          </Flex>
        )}
      </Main>
    </>
  );
};
