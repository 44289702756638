import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { crispIsVisibleAtom } from 'state/chat';
import { CRISP } from '../components/CrispProvider';

export const useCrisp = () => {
  const setCrispIsVisible = useSetRecoilState(crispIsVisibleAtom);

  const openCrisp = useCallback(() => {
    CRISP.push(['do', 'chat:open']);
    CRISP.push(['do', 'chat:show']);
  }, []);

  const closeCrisp = useCallback(() => {
    CRISP.push(['do', 'chat:close']);
    CRISP.push(['do', 'chat:hide']);
    setCrispIsVisible(false);
  }, [setCrispIsVisible]);

  return { openCrisp, closeCrisp };
};
