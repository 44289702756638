import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useClient } from 'react-fetching-library';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { postTimeEventAction } from 'api/actions/drawer/drawerActions';
import { addSnackbar, removeSnackbar } from 'base/Snackbar/output/actions';
import { DRAWER_ROUTES } from 'constants/memoryRoutes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useEvent } from 'hooks/useEvent/useEvent';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { TimeEventFunc } from 'layouts/AuthorizedApp/Drawer/TimeTracker/TimeEventTimer';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { useRefreshClockLog } from 'pages/ClockLog/output/useRefreshClockLog';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { actionHasBeenSentAtom, postTimeEventAtom } from 'state/drawer';
import { isLocationRequiredSelector } from 'state/organizationSession';
import { userSessionAtom } from 'state/userSession';
import { UUID } from 'utils/UUID';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';

import { GeoLocationObserver, useGeoLocation } from './GeoLocationObserver';
import { useTimeTracker } from './useTimeTracker';

type Props = {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
};

const GET_TIME_TRACKER_THROTTLE_LIMIT = 300000; // 5min
const GET_TIME_TRACKER_INTERVAL = 1200000; // 20min

export const WorkTimeTrackerProvider = ({ children }: Props): React.ReactElement => {
  const isLocationRequired = useRecoilValue(isLocationRequiredSelector);
  const setPostTimeEvent = useSetRecoilState(postTimeEventAtom);
  const setActionHasBeenSent = useSetRecoilState(actionHasBeenSentAtom);
  const userSession = useRecoilValue(userSessionAtom);

  const personId = userSession?.personId ? [userSession.personId] : undefined;

  useLingui();
  const { lastFetchTime, timeTracker, getTimeTracker } = useTimeTracker(true);
  const { clockLogInitialized, refreshClockLogForPeopleIds } = useRefreshClockLog(personId);
  const { reportInitialized, updateReportForIds } = useRefreshReport(personId);
  const { calendarInitialized, updateCalendarForIds } = useRefreshCalendar(personId);
  const { location, getGeoLocation } = useGeoLocation();
  const { query } = useClient();
  const navigate = useAppNavigate();
  const routerLocation = useLocation();
  const { isVisible: isOnboardingVisible, playNextStep, playCustomNextStep } = useInitialOnboarding();

  const shouldPostTimeEvent = useRef<boolean>(true);
  const intervalSetRef = useRef<NodeJS.Timeout>();

  const postTimeEvent = useCallback(
    async (body: TimeEventFunc) => {
      if (!shouldPostTimeEvent.current) return;

      const longitude = location?.coords.longitude ?? undefined;
      const latitude = location?.coords.latitude ?? undefined;

      let withLocation = isLocationRequired;

      let isLocationPermissionSnackbarVisible = false;

      let position =
        _.isNumber(longitude) && _.isNumber(latitude)
          ? {
              longitude,
              latitude,
            }
          : null;

      if ((isLocationRequired && !position) || !isLocationRequired) {
        let geoResponseReceived = false;

        setTimeout(() => {
          if (geoResponseReceived) return;
          isLocationPermissionSnackbarVisible = true;
          addSnackbar({
            message: t({
              id: 'waiting_for_location_permission',
              message: "Location: waiting for browser's response...",
            }),
            placement: 'bottom',
            isStatic: true,
          });
        }, 1500);

        const geoResponse = await getGeoLocation();
        geoResponseReceived = true;

        if (isLocationPermissionSnackbarVisible) {
          removeSnackbar();
        }
        position = null;

        if (geoResponse) {
          const lng = geoResponse.coords.longitude;
          const lat = geoResponse.coords.latitude;

          if (_.isNumber(lng) && _.isNumber(lat)) {
            withLocation = true;
            position = {
              longitude: lng,
              latitude: lat,
            };
          }
        }
      }

      if (isLocationRequired && !position) {
        if (routerLocation.pathname.includes(DRAWER_ROUTES.DRAWER)) {
          navigate(DRAWER_ROUTES.DRAWER__TIME_TRACKER__LOCATION_PERMISSION);
        } else {
          navigate(DRAWER_ROUTES.LOCATION_PERMISSION);
        }

        // IMPORTANT: Onboarding - next step if permission needed and not granted
        if (isOnboardingVisible) {
          playNextStep();
        }
      } else if (body) {
        // IMPORTANT: Onboarding - next step if status successfully sent
        if (isOnboardingVisible) {
          if (isLocationRequired) {
            playCustomNextStep(2);
          } else {
            playNextStep();
          }
        }

        if (body?.initialTime && body.initialTime < 0) {
          addSnackbar({
            message: t({
              id: 'time_tracker.wait_on_start',
              message: 'You can send next event after start working time!',
            }),
            variant: 'danger',
          });

          return;
        }

        shouldPostTimeEvent.current = false;

        const { error } = await query(
          postTimeEventAction({
            ...body,
            ...(position && withLocation && position),
            timeEventId: UUID(),
          }),
        );

        if (!error) {
          setActionHasBeenSent(true);
          addSnackbar({
            message: t({
              id: 'time_tracker.submit_success',
              message: 'Successfully submitted!',
            }),
            variant: 'success',
          });

          if (clockLogInitialized) await refreshClockLogForPeopleIds();
          if (reportInitialized) await updateReportForIds();
          if (calendarInitialized) await updateCalendarForIds();

          await getTimeTracker(true);
        }
      }
      setActionHasBeenSent(false);

      shouldPostTimeEvent.current = true;
    },
    [
      location?.coords.longitude,
      location?.coords.latitude,
      isLocationRequired,
      setActionHasBeenSent,
      getGeoLocation,
      routerLocation.pathname,
      isOnboardingVisible,
      query,
      navigate,
      playNextStep,
      getTimeTracker,
      playCustomNextStep,
      clockLogInitialized,
      refreshClockLogForPeopleIds,
      reportInitialized,
      updateReportForIds,
      calendarInitialized,
      updateCalendarForIds,
    ],
  );

  const throttledGetTimeTracker = useMemo(
    () => _.throttle(getTimeTracker, GET_TIME_TRACKER_THROTTLE_LIMIT, { trailing: false }),
    [getTimeTracker],
  );

  const getTimeTrackerRef = useCallbackRef(getTimeTracker);
  const throttledGetTimeTrackerRef = useCallbackRef(throttledGetTimeTracker);

  useEffect(() => {
    const update = () => {
      if (document.hidden) return;
      void throttledGetTimeTrackerRef.current();
    };

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    intervalSetRef.current = setInterval(update, GET_TIME_TRACKER_INTERVAL);

    return () => {
      if (intervalSetRef.current) {
        clearInterval(intervalSetRef.current);
      }
    };
  }, [throttledGetTimeTrackerRef, lastFetchTime]);
  // pamiętać ze odpala się równiez po własnych zmianach ( za kazda zmiana lastFetchTime ),

  const debouncedHandleVisibilityChangeToVisible = useMemo(
    () =>
      _.debounce(
        () => {
          const getTimeTrackerPromise = getTimeTracker();
          emitCustomEvent(CustomEvents.GET_TIME_TRACKER_AFTER_VISIBILITY_CHANGE, getTimeTrackerPromise);
        },
        GET_TIME_TRACKER_THROTTLE_LIMIT,
        { leading: true, trailing: false },
      ),
    [getTimeTracker],
  );

  useEvent(
    'visibilitychange',
    () => {
      if (document.hidden) {
        const throttleHandle = throttledGetTimeTrackerRef.current;
        throttleHandle.cancel();
        return;
      }
      const currentTime = Date.now();
      const timeSinceLastFetch = currentTime - (lastFetchTime || currentTime);

      if (timeSinceLastFetch < GET_TIME_TRACKER_THROTTLE_LIMIT) return;
      debouncedHandleVisibilityChangeToVisible();
    },
    document,
  );

  useEffect(() => {
    if (!timeTracker) {
      void getTimeTrackerRef.current();
    }
  }, [getTimeTrackerRef, timeTracker]);

  useEffect(() => {
    setPostTimeEvent({ postTimeEvent });
  }, [postTimeEvent, setPostTimeEvent]);

  return (
    <>
      {isLocationRequired && location && <GeoLocationObserver />}
      {children}
    </>
  );
};
