import { useState } from 'react';

import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { CustomEvents } from 'utils/customEvents';

export const useIsFetchingTimeTrackerAfterVisibilityChange = () => {
  const [isFetchingTimeTrackerAfterVisibilityChange, setIsFetchingTimeTrackerAfterVisibilityChange] = useState(false);

  useCustomEventListener(
    CustomEvents.GET_TIME_TRACKER_AFTER_VISIBILITY_CHANGE,
    (getTimeTrackerPromise?: Promise<void>) => {
      if (!getTimeTrackerPromise) return;

      setIsFetchingTimeTrackerAfterVisibilityChange(true);
      void getTimeTrackerPromise.finally(() => {
        setIsFetchingTimeTrackerAfterVisibilityChange(false);
      });
    },
  );

  return isFetchingTimeTrackerAfterVisibilityChange;
};
