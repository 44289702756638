import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEqual } from 'lodash';
import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { DEFAULT_WORK_STATUSES_IDS } from 'constants/common';
import {
  customRequestTypesDictionarySelector,
  timeEventTypesDictionarySelector,
  timeOffTypesDictionarySelector,
} from 'state/organizationSession';
import { ParsedRequest } from 'state/requests';
import { useRequestDetails } from '../../../../../hooks/useRequestDetails';
import {
  newComparisonDifferenceSx,
  originalComparisonDifferenceSx,
  requestChangeTextEventSx,
  requestChangeTextTileSx,
  requestChangeWrapperSx,
} from '../styles/styles';

type Props = {
  requestDetails: ParsedRequest | null;
};

const OriginalType: FC<Props> = ({ requestDetails }) => {
  useLingui();
  const timeOffDictionary = useRecoilValue(timeOffTypesDictionarySelector);
  const customDictionary = useRecoilValue(customRequestTypesDictionarySelector);
  const timeEventsDictionary = useRecoilValue(timeEventTypesDictionarySelector);

  const typeName = useMemo(() => {
    if (!requestDetails || !timeOffDictionary || !customDictionary || !timeEventsDictionary) return '';

    const { type, originalEvent } = requestDetails;

    switch (type) {
      case RequestFormType.TimeOff:
        return t({ id: timeOffDictionary[originalEvent?.eventDetails.typeId || ''].name });
      case RequestFormType.Custom:
        return t({ id: customDictionary[originalEvent?.eventDetails.typeId || ''].name });
      case RequestFormType.TimeTracking:
        return `${t({ id: timeEventsDictionary[originalEvent?.eventDetails.typeId || '']?.name })} ${
          originalEvent?.eventDetails.isEnd && originalEvent.eventDetails.typeId !== DEFAULT_WORK_STATUSES_IDS.EXIT
            ? t({ id: 'requests.is_end' })
            : ''
        }`;
      default:
        return '';
    }
  }, [customDictionary, requestDetails, timeEventsDictionary, timeOffDictionary]);

  if (!requestDetails?.originalEvent) return null;

  return (
    <Text
      sx={{
        ...requestChangeTextEventSx,
        ...(requestDetails?.newEvent &&
          !isEqual(requestDetails.newEvent?.eventDetails.typeId, requestDetails?.originalEvent?.eventDetails.typeId) &&
          originalComparisonDifferenceSx),
      }}
    >
      {typeName}
    </Text>
  );
};

const NewType: FC<Props> = ({ requestDetails }) => {
  useLingui();
  const timeOffDictionary = useRecoilValue(timeOffTypesDictionarySelector);
  const customDictionary = useRecoilValue(customRequestTypesDictionarySelector);
  const timeEventsDictionary = useRecoilValue(timeEventTypesDictionarySelector);

  const originalTypeName = useMemo(() => {
    if (!requestDetails || !timeOffDictionary || !customDictionary || !timeEventsDictionary) return '';

    const { type, originalEvent } = requestDetails;

    switch (type) {
      case RequestFormType.TimeOff:
        return t({ id: timeOffDictionary[originalEvent?.eventDetails.typeId || ''].name });
      case RequestFormType.Custom:
        return t({ id: customDictionary[originalEvent?.eventDetails.typeId || ''].name });
      case RequestFormType.TimeTracking:
        return `${t({ id: timeEventsDictionary[originalEvent?.eventDetails.typeId || '']?.name })} ${
          originalEvent?.eventDetails.isEnd && originalEvent.eventDetails.typeId !== DEFAULT_WORK_STATUSES_IDS.EXIT
            ? t({ id: 'requests.is_end' })
            : ''
        }`;
      default:
        return '';
    }
  }, [customDictionary, requestDetails, timeEventsDictionary, timeOffDictionary]);

  const newTypeName = useMemo(() => {
    if (!requestDetails || !timeOffDictionary || !customDictionary || !timeEventsDictionary) return '';

    const { type, newEvent } = requestDetails;

    switch (type) {
      case RequestFormType.TimeOff:
        return t({ id: timeOffDictionary[newEvent?.eventDetails.typeId || ''].name });
      case RequestFormType.Custom:
        return t({ id: customDictionary[newEvent?.eventDetails.typeId || ''].name });
      case RequestFormType.TimeTracking:
        return `${t({ id: timeEventsDictionary[newEvent?.eventDetails.typeId || ''].name })} ${
          newEvent?.eventDetails.isEnd && newEvent.eventDetails.typeId !== DEFAULT_WORK_STATUSES_IDS.EXIT
            ? t({ id: 'requests.is_end' })
            : ''
        }`;
      default:
        return 'DEFAULT';
    }
  }, [customDictionary, requestDetails, timeEventsDictionary, timeOffDictionary]);

  return (
    <Text
      sx={{
        ...requestChangeTextEventSx,
        ...(requestDetails?.originalEvent && !isEqual(newTypeName, originalTypeName) && newComparisonDifferenceSx),
      }}
    >
      {newTypeName}
    </Text>
  );
};

export const RequestChangeType: FC = () => {
  const { parsedRequestDetails: requestDetails } = useRequestDetails();

  return (
    <Flex sx={requestChangeWrapperSx}>
      <Text sx={requestChangeTextTileSx}>
        <Trans id="type">Type</Trans>
      </Text>
      <OriginalType requestDetails={requestDetails} />
      <NewType requestDetails={requestDetails} />
    </Flex>
  );
};
