/** @jsxImportSource theme-ui */
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';
import { FocusEvent, forwardRef, useCallback, useMemo, useRef } from 'react';
import { UseFormGetValues, UseFormSetValue, useForm } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Box, Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { ManageAvatar } from 'components/ManageAvatar/ManageAvatar';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { PhoneInput } from 'components/ui/PhoneInput';
import { Select } from 'components/ui/Select/Select';
import { RolesSelect } from 'components/ui/Select/variants/RolesSelect';
import { TagsSelect } from 'components/ui/Select/variants/TagsSelect';
import { TextInput } from 'components/ui/TextInput';
import { TO_REL } from 'constants/routes';
import { PHONE_NUMBER_INTERNAL_ERROR } from 'constants/team';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { useErrorHandlersFactory } from 'hooks/useErrorHandlersFactory/useErrorHandlersFactory';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { useMount } from 'hooks/useMount/useMount';
import { languageSelector } from 'state/recoilState';
import { ProfileFormState, getAddTeammateFormValuesAtom } from 'state/team';
import { CustomEvents } from 'utils/customEvents';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { getTextField } from 'utils/getTextField';

import { useAvatar } from './hooks/useAvatar';
import { useRoleDescription } from './hooks/useRoleDescription';
import { useSelectOptions } from './hooks/useSelectOptions';

const labelSx: ThemeUIStyleObject = {
  ml: 2,
  fontSize: 1,
  fontWeight: 'bold',
  mt: 3,
};

export type AvatarInfo = {
  avatarUrl?: string;
  avatarBlob?: Blob | null;
  changed?: boolean;
};

type Props = {
  defaultValues: Partial<ProfileFormState>;
  avatarHasChanged?: boolean;
  onSubmit: (props: ProfileFormState, avatarInfo?: AvatarInfo) => Promise<boolean>;
  onSubmitError?: () => void;
  onTagsIdsBlur?: (tagsIds: string[]) => void;
  avatarUrl?: string;
};

export type UserProfileRefExtendedType = {
  getValues?: UseFormGetValues<ProfileFormState>;
  setValue?: UseFormSetValue<ProfileFormState>;
};

export const UserProfile = forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, onSubmitError, defaultValues, avatarUrl, avatarHasChanged, onTagsIdsBlur }, ref) => {
    useLingui();
    const language = useRecoilValue(languageSelector);
    const setGetAddTeammateFormValues = useSetRecoilState(getAddTeammateFormValuesAtom);

    const { avatarProps, getAvatarInfo } = useAvatar(avatarUrl, avatarHasChanged);
    const { systemManagement, modulesManagement } = useAppPermissions();
    const { isVisible: isOnboardingVisible } = useInitialOnboarding();

    const {
      watch,
      setError,
      clearErrors,
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
    } = useForm<ProfileFormState>({
      defaultValues,
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

    const defaultWorkPositionId = watch('defaultWorkPosition');
    const additionalWorkPositionIds = watch('additionalWorkPositions');

    useCustomEventListener(CustomEvents.GET_MAIL, {
      callback: ({ callback, email }: { email?: string; callback?: (email?: string) => void }) => {
        callback?.(watch('email'));
        if (email) setValue('email', email, { shouldValidate: true });
      },
      priority: 2,
    });

    const { tagsSelectOptions, rolesSelectOptions, workPositionsSelectOptions, additionalWorkPositionsSelectOptions } =
      useSelectOptions(defaultWorkPositionId, additionalWorkPositionIds);

    const [setPhoneErrorCallback, clearPhoneErrorCallback] = useErrorHandlersFactory(
      setError,
      clearErrors,
      PHONE_NUMBER_INTERNAL_ERROR,
    );

    const tagsIdsRef = useRef(
      (() => {
        if (!defaultValues.tagsIds) return undefined;

        if (isArray(defaultValues.tagsIds)) return defaultValues.tagsIds.join();

        return defaultValues.tagsIds;
      })(),
    );
    const selectedRoleId = watch('roleId');

    const selectedRoleDescription = useRoleDescription(selectedRoleId);

    const handleSubmitCallback = useCallback(
      async (body: ProfileFormState) => {
        const avatarInfo = await getAvatarInfo();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        void onSubmit(forceCorrectTypes(body), avatarInfo);
      },
      [onSubmit, getAvatarInfo],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      if (!onSubmitError) return;
      onSubmitError();
    }, [onSubmitError]);

    const tagsIdsRegister = useMemo(() => {
      const { onBlur, ...restRegister } = register('tagsIds');

      return {
        ...restRegister,
        onBlur: (e: FocusEvent<HTMLInputElement>) => {
          void onBlur(e);
          if (!onTagsIdsBlur) return;

          const newValue = e.target.value;

          if (isEqual(newValue, tagsIdsRef.current)) return;

          tagsIdsRef.current = newValue;
          const tagsIds = e.target.value.split(',').filter((id) => id !== '');
          onTagsIdsBlur(tagsIds);
        },
      };
    }, [onTagsIdsBlur, register]);

    useMount(() => {
      setGetAddTeammateFormValues(() => getValues);
    });

    if (
      !tagsSelectOptions ||
      !rolesSelectOptions ||
      !workPositionsSelectOptions ||
      !additionalWorkPositionsSelectOptions
    )
      return <></>;

    return (
      <form
        ref={ref}
        onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))}
        noValidate
      >
        <Flex sx={{ flexDirection: 'column' }}>
          <Text sx={{ ...labelSx, mt: 2 }}>
            <Trans id="team.user.name">Name</Trans>
          </Text>
          <Flex sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Flex sx={{ flexDirection: 'column', width: '100%' }}>
              <TextInput
                size="sm"
                id="firstName"
                placeholder={t({ id: 'team.user.first_name', message: 'First name' })}
                variant="roundedTop"
                error={!!errors.firstName}
                errorMessage={errors?.firstName?.message}
                {...register('firstName', validationFactory({ ...VALIDATION_RULES.FIRST_NAME, required: true }))}
              />
              <TextInput
                size="sm"
                id="surname"
                placeholder={t({ id: 'team.user.last_name', message: 'Last name' })}
                variant="roundedBottom"
                sx={{ borderTop: '0px' }}
                error={!!errors.surname}
                errorMessage={errors?.surname?.message}
                {...register('surname', validationFactory({ ...VALIDATION_RULES.SURNAME, required: true }))}
              />
            </Flex>
            {!isOnboardingVisible && <ManageAvatar {...avatarProps} />}
          </Flex>
          <Flex sx={{ width: '100%', alignContent: 'space-between', gap: 3 }}>
            <Box sx={{ width: '50%', flexDirection: 'column', flexGrow: 1 }}>
              <Text as="div" sx={{ ...labelSx, mt: 0 }}>
                <Trans id="team.user.email">E-mail</Trans>
              </Text>
              <TextInput
                size="sm"
                id="email"
                placeholder={t({
                  id: 'global.forms.inputs.email_example',
                  message: 'sam@example.com',
                })}
                type="email"
                autoComplete="email"
                error={!!errors.email}
                errorMessage={errors?.email?.message}
                {...register('email', validationFactory(VALIDATION_RULES.EMAIL))}
              />
            </Box>
            <Box sx={{ width: '50%', flexGrow: 1 }}>
              <Text as="div" sx={{ ...labelSx, mt: 0 }}>
                <Trans id="team.user.phone">Phone</Trans>
              </Text>
              <PhoneInput
                size="sm"
                id="phoneNumber"
                placeholder={t({
                  id: 'global.forms.inputs.phone_number_example',
                  message: '+1 (123) 555-0123',
                })}
                onValidError={setPhoneErrorCallback}
                onClearError={clearPhoneErrorCallback}
                error={!!errors.phoneNumber || !!errors[PHONE_NUMBER_INTERNAL_ERROR as 'phoneNumber']}
                errorMessage={errors?.phoneNumber?.message}
                clearable
                {...register('phoneNumber')}
              />
            </Box>
          </Flex>

          {(!isOnboardingVisible || (isOnboardingVisible && workPositionsSelectOptions.length > 0)) && (
            <>
              <Text sx={labelSx}>
                <Trans id="team.user.position.default_manage">Default position</Trans>
                {modulesManagement.Employees && !isOnboardingVisible && (
                  <>
                    {' | '}
                    <LinkAnchor
                      standOut
                      sx={{ textDecoration: 'underline' }}
                      to={TO_REL.MANAGE_WORK_POSITIONS_MODAL[language]}
                    >
                      <Trans id="team.user.manage">Manage</Trans>
                    </LinkAnchor>
                  </>
                )}
              </Text>
              <Select
                size="sm"
                id="defaultWorkPosition"
                // creatable TODO: 2021-12-06 - disabled for CORE version
                placeholder={t({ id: 'team.user.position.select_add', message: 'Select or add a new one' })}
                error={!!errors.defaultWorkPosition}
                errorMessage={errors?.defaultWorkPosition?.message}
                searchable
                options={workPositionsSelectOptions}
                checkValueForNonexistentIds
                clearable
                {...register(
                  'defaultWorkPosition',
                  // validationFactory(VALIDATION_RULES.WORK_POSITION_NAME) TODO: add when creatable
                )}
              />
            </>
          )}

          {((!isOnboardingVisible &&
            additionalWorkPositionsSelectOptions.length > 0 &&
            (!!defaultWorkPositionId || (!!additionalWorkPositionIds && additionalWorkPositionIds.length > 0))) ||
            (isOnboardingVisible && additionalWorkPositionsSelectOptions.length > 0)) && (
            <>
              <Text sx={labelSx}>
                <Trans id="team.user.additional_positions">Additional positions</Trans>
              </Text>
              <Select
                size="sm"
                id="additionalWorkPositions"
                multi
                placeholder={t({ id: 'team.user.additional_positions.select', message: 'Select' })}
                error={!!errors.additionalWorkPositions}
                errorMessage={errors?.additionalWorkPositions?.message}
                searchable
                options={additionalWorkPositionsSelectOptions}
                checkValueForNonexistentIds
                clearable
                {...register('additionalWorkPositions')}
              />
            </>
          )}

          {(!isOnboardingVisible || (isOnboardingVisible && tagsSelectOptions.length > 0)) && (
            <>
              <Text sx={labelSx}>
                <Trans id="team.user.tags.add_manage">Tags</Trans>
                {systemManagement.Permissions && !isOnboardingVisible && (
                  <>
                    {' | '}
                    <LinkAnchor standOut sx={{ textDecoration: 'underline' }} to={TO_REL.ADD_TAG_MODAL[language]}>
                      {t({ id: 'add' })}
                    </LinkAnchor>{' '}
                    {t({ id: 'sign_in.or' })}{' '}
                    <LinkAnchor standOut sx={{ textDecoration: 'underline' }} to={TO_REL.MANAGE_TAGS_MODAL[language]}>
                      <Trans id="team.user.manage">Manage</Trans>
                    </LinkAnchor>
                  </>
                )}
              </Text>
              <TagsSelect
                size="sm"
                id="tagsIds"
                multi
                placeholder={t({ id: 'team.user.select_position', message: 'Select or add a new one' })}
                error={!!errors.tagsIds}
                errorMessage={errors?.tagsIds?.message}
                searchable
                options={tagsSelectOptions}
                clearable
                checkValueForNonexistentIds
                {...tagsIdsRegister}
              />
            </>
          )}

          <Text sx={labelSx}>
            <Trans id="team.user.role.role_manage">Role</Trans>
            {systemManagement.Permissions && !isOnboardingVisible && (
              <>
                {' | '}
                <LinkAnchor standOut sx={{ textDecoration: 'underline' }} to={TO_REL.MANAGE_ROLES_MODAL[language]}>
                  <Trans id="team.user.manage">Manage</Trans>
                </LinkAnchor>
              </>
            )}
          </Text>
          <RolesSelect
            size="sm"
            id="roleId"
            placeholder={t({ id: 'team.user.role', message: 'Role' })}
            error={!!errors.roleId}
            errorMessage={errors?.roleId?.message}
            searchable
            options={rolesSelectOptions}
            checkValueForNonexistentIds
            {...register('roleId', { required: t({ id: 'global.forms.required' }) })}
          />
          {selectedRoleDescription && (
            <Text sx={{ ml: 2, fontSize: 2, color: 'team.selectedRoleDescription' }}>
              {t({
                id: selectedRoleDescription,
              })}
            </Text>
          )}
        </Flex>
      </form>
    );
  },
);

const forceCorrectTypes = ({
  tagsIds,
  firstName,
  surname,
  roleId,
  additionalWorkPositions,
  ...values
}: ProfileFormState): ProfileFormState => ({
  tagsIds: !isEmpty(tagsIds) && isString(tagsIds) ? tagsIds.split(',') : tagsIds || [],
  additionalWorkPositions:
    !isEmpty(additionalWorkPositions) && isString(additionalWorkPositions)
      ? additionalWorkPositions.split(',')
      : additionalWorkPositions || [],
  roleId,
  firstName,
  surname,
  ...mapValues(values, (value) => getTextField(value)),
});
