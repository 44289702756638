import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { copySchedulesToAction } from 'api/actions/calendar/calendarActions';
import { PublishScheduleActionProps } from 'api/actions/calendar/calendarActions.types';
import { Divider } from 'components/Divider/Divider';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { ListNames } from 'components/StickyList/types';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { PickTeammates } from 'components/recipes/PickTeammates/PickTeammates';
import { PickTeammatesFilters } from 'components/recipes/PickTeammates/PickTeammatesFilters';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { usePickTeammates } from 'hooks/usePickTeammates/usePickTeammates';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { AsideWrapper } from 'pages/Requests/output/AsideWrapper';
import { aiSchedulesTrendScaleSelector } from 'state/calendar';
import { schedulesSettingsSelector } from 'state/organizationSession';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useRefreshCalendar } from '../../hooks/useRefreshCalendar';

export const CopySchedulesToModal = (): React.ReactElement => {
  const schedulesSettings = useRecoilValue(schedulesSettingsSelector);
  const trendScaleFilter = useRecoilValue(aiSchedulesTrendScaleSelector);

  const [loading, setLoading] = useState(false);
  const [requestState, setRequestState] = useState<
    | {
        ids: string[];
        dates: {
          startDateUnix: number;
          endDateUnix: number;
        };
      }
    | undefined
  >(undefined);
  useLingui();

  const { handleClose, baseRoute } = useModal();
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const { state } = useLocation();
  const { ids, dates } = state || {};

  const [selectedTeammatesIds] = usePickTeammates(ListNames.COPY_SCHEDULES, true);

  const { updateCalendarForIds, calendarInitialized, updateCalendar } = useRefreshCalendar(selectedTeammatesIds);
  const { reportInitialized, updateReportForIds } = useRefreshReport(selectedTeammatesIds);

  const { mutate } = useMutation<unknown, unknown, PublishScheduleActionProps>((body) =>
    copySchedulesToAction(body, requestState ? requestState.ids[0] : undefined),
  );

  useEffect(() => {
    if (ids && dates) {
      setRequestState({ ids, dates });
    } else {
      handleClose();
    }
  }, [dates, handleClose, ids]);

  useEffect(() => {
    if (!calendarInitialized) {
      void updateCalendar();
    }
  }, [calendarInitialized, updateCalendar]);

  const handleOnSave = useCallback(async () => {
    setLoading(true);
    const { error: submitError } = await mutate({
      peopleIds: selectedTeammatesIds,
      timeRange: dates,
      ...(!!schedulesSettings?.useAiScheduling && {
        aiVariant: trendScaleFilter,
      }),
    });

    if (!submitError) {
      if (calendarInitialized) await updateCalendarForIds();
      if (reportInitialized) await updateReportForIds();
      handleClose();
      return;
    }

    setLoading(false);
  }, [
    calendarInitialized,
    dates,
    handleClose,
    mutate,
    reportInitialized,
    selectedTeammatesIds,
    updateCalendarForIds,
    updateReportForIds,
    trendScaleFilter,
    schedulesSettings?.useAiScheduling,
  ]);

  if (!ids?.length || !dates) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="schedule.copy_to_wizard">Copy schedule to...</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ pt: 0, gap: 2, ...(!isSmartphoneBreakpoint && { pl: 0 }) }}>
        <Flex sx={{ flex: '1', minHeight: 0 }}>
          <ConditionalWrapper condition={isSmartphoneBreakpoint} wrapper={AsideWrapper}>
            <Flex
              sx={{
                maxWidth: '240px',
                flex: '1',
                ...(!isSmartphoneBreakpoint && { overflowY: 'hidden' }),
              }}
            >
              <Flex
                sx={{
                  flexDirection: 'column',
                  gap: 3,
                  flex: '1',
                  ...(!isSmartphoneBreakpoint && { overflowY: 'auto', pr: 3, pl: 3 }),
                }}
              >
                <PickTeammatesFilters />
              </Flex>
            </Flex>
          </ConditionalWrapper>
          {!isSmartphoneBreakpoint && <Divider axis="vertical" />}
          <Flex sx={{ flex: '1', flexDirection: 'column', ml: 3 }}>
            <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
              <PickTeammates
                forceIncludeCurrentUser={true}
                listName={ListNames.COPY_SCHEDULES}
                module="Schedules"
                hideIds={requestState?.ids}
              />
            </Flex>
          </Flex>
        </Flex>
      </Modal.Body>

      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            disabled: !selectedTeammatesIds[0],
            variant: 'primary',
            onClick: floatingPromiseReturn(handleOnSave),
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
