import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, ReactElement, useMemo, useState } from 'react';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { ControlledModal, openModal } from 'components/Modal/output/Modal';
import { ButtonPair } from 'components/recipes/ButtonPair';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { ShowFieldsButton } from 'components/recipes/ShowFieldsButton';
import { YearSelect } from 'components/recipes/YearSelect';
import { Button } from 'components/ui/Buttons/Button';
import { Select } from 'components/ui/Select/Select';
import { CURRENT_YEAR } from 'constants/team';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useDateSelectOptions } from 'hooks/useDateSelectOptions/useDateSelectOptions';
import { dateTime } from 'utils/dateTime';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { setIntervalX } from 'utils/setIntervalX';

import { AddEditFromModal, AddEditFromModalState } from './components/AddEditFromModal';

type Props = {
  variant: 'date' | 'year';
  isDisabledGenerator?: (from: string) => boolean;
  onAdd: (from: string) => void;
  onEdit: (oldFrom: string, newFrom: string) => void;
  onRemove: (from: string) => void;
  fieldRenderer: (from: string) => ReactElement | null;
  froms: number[];
  clearable?: boolean;
  sx?: ThemeUIStyleObject;
};

export type FieldArrayManagerProps = Props;

const ADD_EDIT_FROM_MODAL = 'ADD_EDIT_FROM_MODAL';
const REMOVE_FROM_MODAL = 'REMOVE_FROM_MODAL';

const getInstanceNumber = (() => {
  let counter = 0;
  return () => {
    counter += 1;
    return counter;
  };
})();

export const FieldArrayManager: FC<Props> = ({
  variant,
  sx,
  froms,
  clearable = false,
  isDisabledGenerator,
  onAdd,
  onEdit,
  onRemove,
  fieldRenderer,
}) => {
  useLingui();

  const instanceNumber = useMemo(getInstanceNumber, []);

  const today = useMemo(() => dateTime().unix(), []);

  const fromsRef = useCallbackRef(froms);

  const defaultFrom = useMemo(() => {
    if ((variant === 'year' && froms.includes(CURRENT_YEAR)) || !froms.length) return CURRENT_YEAR;
    if (variant === 'date' && !froms.length) return today;

    const todaysFrom = variant === 'year' ? CURRENT_YEAR : today;

    const sortedFroms = froms.sort((a, b) => b - a);

    const currentFrom = sortedFroms.find((from) => from < todaysFrom);

    return currentFrom || sortedFroms.reverse().find((from) => from > todaysFrom) || todaysFrom;
  }, [froms, today, variant]);

  const [selectedFrom, setSelectedFrom] = useState<string>(`${defaultFrom}`);

  const disabled = isDisabledGenerator ? isDisabledGenerator(selectedFrom) : false;

  const addEditModalId = `${ADD_EDIT_FROM_MODAL}_${instanceNumber}`;
  const removeModalId = `${REMOVE_FROM_MODAL}_${instanceNumber}`;

  const handleAddEditFactory = (type: 'add' | 'edit') => async () => {
    const editedFrom = type === 'edit' ? +selectedFrom : undefined;

    const state: AddEditFromModalState = { excludedFroms: froms, editedFrom };

    const oldFrom = selectedFrom;
    const newFrom = await openModal<number | null>(addEditModalId, state);

    if (!newFrom) return;

    const newFromString = `${newFrom}`;

    if (type === 'add') {
      onAdd(newFromString);
    }
    if (type === 'edit') {
      onEdit(oldFrom, newFromString);
    }

    const fromsUpdate = new Promise((resolve) => {
      setIntervalX(
        () => {
          if (!fromsRef.current.includes(newFrom)) return false;
          resolve(true);
          return true;
        },
        0,
        10,
      );
    });

    await Promise.race([fromsUpdate, delay(300)]);

    setSelectedFrom(`${newFrom}`);
  };

  const handleRemove = async () => {
    const confirmation = await openModal<boolean | null>(removeModalId);
    if (!confirmation) return;

    const sortedFroms = froms.sort((a, b) => +a - +b);

    const nextFrom = sortedFroms.find((from) => from > +selectedFrom);
    const prevFrom = sortedFroms.reverse().find((from) => from < +selectedFrom);

    onRemove(selectedFrom);

    setSelectedFrom(`${nextFrom || prevFrom}`);
  };

  const dates = variant === 'date' && froms.length ? froms : null;
  const dateSelectOptions = useDateSelectOptions(dates || [today]);

  return (
    <>
      {clearable && !froms.length ? (
        <ShowFieldsButton onClick={floatingPromiseReturn(handleAddEditFactory('add'))} label={t({ id: 'add' })} />
      ) : (
        <Flex sx={{ flexDirection: 'column', ...(sx && sx) }}>
          <Flex mb={2}>
            {variant === 'year' && (
              <YearSelect
                disabled={disabled}
                customYears={froms.sort((a, b) => +a - +b)}
                label={t({ id: 'team.pay_details.from', message: 'From' })}
                id="selectedYear"
                inputProps={{
                  value: selectedFrom,
                }}
                defaultValue={selectedFrom}
                onChange={(e) => setSelectedFrom(e.target.value)}
              />
            )}
            {variant === 'date' && (
              <Select
                size="sm"
                disabled={disabled}
                options={dateSelectOptions}
                label={t({ id: 'team.pay_details.from', message: 'From' })}
                id="selectedDate"
                sx={{ width: '137px' }}
                inputProps={{
                  value: selectedFrom,
                }}
                defaultValue={selectedFrom}
                onChange={(e) => setSelectedFrom(e.target.value)}
              />
            )}
            <Flex sx={{ alignItems: 'center', ml: 3, mt: '1.2rem', gap: 2 }}>
              <ButtonPair
                leftButtonProps={{
                  onClick: floatingPromiseReturn(handleAddEditFactory('add')),
                  disabled,
                }}
                rightButtonProps={
                  froms.length > 1 || clearable
                    ? {
                        onClick: floatingPromiseReturn(handleRemove),
                      }
                    : undefined
                }
              />
              <Button
                size="xs"
                shape="rounded"
                variant="grey"
                onClick={floatingPromiseReturn(handleAddEditFactory('edit'))}
              >
                {variant === 'year' && <Trans id="field_array_manager.edit_button.year"> Edit year</Trans>}
                {variant === 'date' && <Trans id="field_array_manager.edit_button.date"> Edit date</Trans>}
              </Button>
            </Flex>
          </Flex>
          {fieldRenderer(selectedFrom)}
        </Flex>
      )}

      <ControlledModal size="sm" id={addEditModalId}>
        <AddEditFromModal variant={variant} />
      </ControlledModal>
      <ControlledModal size="xs" id={removeModalId}>
        <ConfirmModal
          titleRenderer={() => <Trans id="field_array_manager.remove_modal.title">Are you sure?</Trans>}
          contentRenderer={() => (
            <>
              <Trans id="field_array_manager.remove_modal.desc">
                <Text>You're about to permanently delete this entry and its settings.</Text>{' '}
                <strong> If you click "save", this action cannot be reversed.</strong>
                <Text mt={4}>This will affect all related data.</Text>
              </Trans>
            </>
          )}
          withConfirmation
          variant="DELETE"
        />
      </ControlledModal>
    </>
  );
};
