import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';

import {
  CalculationInfoActionProps,
  CalculationInfoResponse,
  Currency,
  FetchPaymentInfoResponse,
  HostnameEnum,
  PaymentMethodEnum,
  PayUPayNowPaymentMethod,
} from 'api/actions/payments/paymentsActions.types';
import { BasicInformationSettingsActionProps } from 'api/actions/settings/settingsActions.types';

import { countryDetailsListResponseAtom } from './signUp';

type PaymentRecoilObject = Omit<FetchPaymentInfoResponse, 'billingDetails'> & {
  freePlan: boolean;
  minimalPlan: boolean;
  paymentMethodError: boolean;
  billingDetailsError: boolean;
  parsedBillingDetails: ParsedBillingDetails;
  getPayments: () => Promise<void>;
};

export type ParsedPayment = Omit<FetchPaymentInfoResponse, 'billingDetails'> & {
  billingDetails: ParsedBillingDetails;
};

export type PaymentLackInformationErrors = {
  paymentMethodError: boolean;
  billingDetailsError: boolean;
};

export type ParsedBillingDetails = BasicInformationSettingsActionProps & { isCompleted: boolean };

export type YourPlanData = Pick<
  PaymentRecoilObject,
  'usersCount' | 'timeTracking' | 'scheduling' | 'requests' | 'state' | 'isActive' | 'proformaDetails'
>;

export type AnnualBillingData = Pick<ParsedPayment, 'annualBilling' | 'currency'>;

export type PaymentMethodData = Pick<
  ParsedPayment,
  'paymentMethod' | 'payUPaymentMethods' | 'bankTransferData' | 'payNowPaymentMethods'
>;

export type BillingDetailsData = Pick<ParsedPayment, 'billingDetails'>;

export type AlertRecoilObject = {
  variant: 'warning' | 'info' | 'danger';
  children: React.ReactNode;
  action?: {
    title: string;
    onClick: () => void;
  };
};

export const paymentsAtom = atom<FetchPaymentInfoResponse | null>({
  key: 'payments',
  default: null,
});

export const parsedPaymentsSelector = selector<ParsedPayment | null>({
  key: 'parsedPayments',
  get: ({ get }) => {
    const payments = get(paymentsAtom);

    if (!payments) return null;

    const { billingDetails, ...rest } = payments;

    return {
      ...rest,
      billingDetails: {
        isCompleted: billingDetails.isCompleted,
        name: billingDetails.companyName,
        address: {
          city: billingDetails.city,
          street: billingDetails.street,
          postalCode: billingDetails.postalCode,
        },
        countryCode: billingDetails.countryCode,
        vatId: billingDetails.vatNumber,
      },
    };
  },
});

export const paymentLackInformationErrorsAtom = atom<PaymentLackInformationErrors>({
  key: 'paymentLackInformationErrors',
  default: {
    paymentMethodError: false,
    billingDetailsError: false,
  },
});

export const yourPlanDataSelector = selector<YourPlanData | null>({
  key: 'yourPlanData',
  get: ({ get }) => {
    const payments = get(paymentsAtom);

    if (!payments) return null;

    return _.pick(payments, [
      'usersCount',
      'timeTracking',
      'scheduling',
      'requests',
      'state',
      'isActive',
      'proformaDetails',
    ]);
  },
});

export const parsedBillingDetailsDataSelector = selector<BillingDetailsData | null>({
  key: 'billingDetailsData',
  get: ({ get }) => {
    const payments = get(parsedPaymentsSelector);

    if (!payments) return null;

    return _.pick(payments, 'billingDetails');
  },
});

export const paymentMethodDataSelector = selector<PaymentMethodData | null>({
  key: 'paymentMethodData',
  get: ({ get }) => {
    const payments = get(paymentsAtom);

    if (!payments) return null;

    return _.pick(payments, ['paymentMethod', 'payUPaymentMethods', 'bankTransferData', 'payNowPaymentMethods']);
  },
});

export const annualPlanDataSelector = selector<AnnualBillingData | null>({
  key: 'annualPayloadData',
  get: ({ get }) => {
    const payments = get(paymentsAtom);

    if (!payments) return null;

    return _.pick(payments, ['annualBilling', 'currency']);
  },
});

export const idPaymentsSelector = selector<string | null>({
  key: 'idPayments',
  get: ({ get }) => {
    const payments = get(paymentsAtom);

    if (!payments) return null;

    const { id } = payments;

    return id;
  },
});

export const hostnameSelector = selector<HostnameEnum | null>({
  key: 'hostname',
  get: ({ get }) => {
    const payments = get(paymentsAtom);

    if (!payments) return null;

    const { hostname } = payments;

    return hostname;
  },
});

export const processPaymentCalculationPriceDataAtom = atom<CalculationInfoActionProps | null>({
  key: 'processPaymentCalculationPriceData',
  default: null,
});

export const processPaymentCalculationPriceResultAtom = atom<CalculationInfoResponse | null>({
  key: 'processPaymentCalculationPriceResult',
  default: null,
});

export const payUPayNowPaymentMethodsSelector = selectorFamily<
  PayUPayNowPaymentMethod[] | null,
  PaymentMethodEnum.PayU | PaymentMethodEnum.PayNow
>({
  key: 'payUPaymentMethods',
  get:
    (paymentMethod) =>
    ({ get }) => {
      const payments = get(paymentsAtom);

      if (!payments) return null;

      const { payUPaymentMethods, payNowPaymentMethods } = payments;

      if (paymentMethod === PaymentMethodEnum.PayU) return payUPaymentMethods;

      return payNowPaymentMethods;
    },
});

export const parsedBillingDetailsSelector = selector<ParsedBillingDetails | null>({
  key: 'parsedBillingDetails',
  get: ({ get }) => {
    const payments = get(parsedPaymentsSelector);

    if (!payments) return null;

    const { billingDetails } = payments;

    return billingDetails;
  },
});

export const getPaymentCurrencySelector = selector<Currency | null>({
  key: 'payments_currency',
  get: ({ get }) => {
    const payments = get(paymentsAtom);

    if (!payments) return null;

    const { currency } = payments;

    return currency;
  },
});

export const getPaymentCountryCodeSelector = selector<string | null>({
  key: 'payments_countryCode',
  get: ({ get }) => {
    const payments = get(parsedPaymentsSelector);

    if (!payments) return null;

    const { billingDetails } = payments;

    return billingDetails.countryCode;
  },
});

export const alertAtom = atom<AlertRecoilObject | null>({
  key: 'alert',
  default: null,
});

export const pendingCalculationAtom = atom<boolean>({
  key: 'pendingCalculation',
  default: false,
});

export const stripeClientSecretAtom = atom<string | null>({
  key: 'stripeClientSecret',
  default: null,
});

export const isPaymentButtonLoadingAtom = atom<boolean>({
  key: 'isPaymentButtonLoading',
  default: false,
});

export const stripeQuickCardSetupAtom = atom<
  | (() => Promise<{
      error: boolean;
    }>)
  | null
>({
  key: 'stripeQuickCardSetup',
  default: null,
});

export const stripeFormFilledQuickCardSetupAtom = atom({
  key: 'stripeFormFilledQuickCardSetup',
  default: false,
});

export const euCountryCodesSelector = selector<string[] | null>({
  key: 'euCountryCodes',
  get: ({ get }) => {
    const countryDetailsList = get(countryDetailsListResponseAtom);

    if (!countryDetailsList) return null;

    return countryDetailsList.filter(({ isInEu }) => isInEu).map(({ countryCode }) => countryCode);
  },
});

export const isLoadingWaitingForWebhookAtom = atom<boolean>({
  key: 'paymentMethodIsLoadingWaitingForWebhook',
  default: false,
});

export const cardConfirmInProgressAtom = atom<boolean>({
  key: 'cardConfirmInProgress',
  default: false,
});
