import { useMemo } from 'react';
import { To, matchPath, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';

import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';

/**
 * Checks if one or more of the provided pathnames is currently active.
 *
 * @param {To[]} pathnames Pathnames to check.
 * @param {string[] | undefined} modalRoutes Modal routes that should be ignored.
 *
 * @return {boolean} Return true if one of the provided pathnames is active.
 */
export const usePathnameMatch = (pathnames: To[], modalRoutes?: string[]): boolean => {
  const internalPathnames = pathnames.map((v) => {
    if (typeof v === 'object' && _.isObject(v) && v.pathname) {
      return `${v.pathname}`;
    }
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    return `${v}`;
  });

  const language = useRecoilValue(languageSelector);
  const { pathname } = useLocation();

  const pathnameMatch = internalPathnames.some((path) => matchPath(path, pathname));

  const modalRouteMatch = modalRoutes
    ? internalPathnames
        .map((path) => modalRoutes.map((modalRoute) => `${path}${modalRoute}`))
        .flat()
        .some((path) => matchPath(path, pathname))
    : false;

  const isLocationStart = pathname === TO.START[language];

  const pathnamesIsStartPathOnly = internalPathnames.every((path) => path === TO.START[language]);

  const isActive = useMemo(() => {
    if (!isLocationStart && !pathnamesIsStartPathOnly) {
      return pathnameMatch || modalRouteMatch;
    }

    if (isLocationStart && pathnamesIsStartPathOnly) {
      return pathnameMatch;
    }
    return pathnameMatch || modalRouteMatch;
  }, [isLocationStart, pathnamesIsStartPathOnly, pathnameMatch, modalRouteMatch]);

  return isActive;
};
