import { Action } from 'react-fetching-library';

import { API_ENDPOINTS } from 'constants/api';
import { actionWithPrintDownloadManager, actionWithPrintPopupWindow } from '../helpers';

import {
  ExportActionProps,
  ExportForIntegrationActionProps,
  ExportForIntegrationResponse,
  ExportIntegrationOptionsActionProps,
  ExportIntegrationOptionsResponse,
  ExportResponse,
  ExportSelectedItemsActionProps,
  ExportSelectedItemsResponse,
  ExportTimeActivityActionProps,
  ExportTimeActivityPreviewActionProps,
  ExportTimeActivityPreviewResponse,
  ExportTimeActivityResponse,
  ExportToICalUrlActionProps,
  ExportToICalUrlResponse,
  GetExportOptionsActionProps,
  GetExportOptionsResponse,
} from './exportActions.types';

// const EXPORT_DEFAULT_FETCH_TIMEOUT = 60000;

export function getExportOptionsAction(body: GetExportOptionsActionProps): Action<GetExportOptionsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.getExportOptions,
    body,
  };
}

export function exportIntegrationOptionsAction(
  body: ExportIntegrationOptionsActionProps,
): Action<ExportIntegrationOptionsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.getExportForIntegrationOptions,
    body,
  };
}

function exportActionCreator(body: ExportActionProps): Action<ExportResponse> {
  return {
    method: 'POST' as const,
    endpoint: API_ENDPOINTS.export,
    body,
  };
}

function exportSelectedItemsActionCreator(body: ExportSelectedItemsActionProps): Action<ExportSelectedItemsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.exportSelectedItems,
    body,
  };
}

function exportForIntegrationActionCreator(
  body: ExportForIntegrationActionProps,
): Action<ExportForIntegrationResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.exportForIntegration,
    body,
  };
}

export function exportTimeActivityPreviewAction(
  body: ExportTimeActivityPreviewActionProps,
): Action<ExportTimeActivityPreviewResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.exportTimeActivityPreview,
    body,
  };
}

export function exportToICalUrl({ resetUrl }: ExportToICalUrlActionProps): Action<ExportToICalUrlResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.exportToICalUrl}/${resetUrl}`,
  };
}

export function exportTimeActivityActionCreator(
  body: ExportTimeActivityActionProps,
): Action<ExportTimeActivityResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.exportTimeActivity,
    body,
  };
}

export const exportAction = actionWithPrintDownloadManager(exportActionCreator);
export const exportSelectedItemsAction = actionWithPrintDownloadManager(exportSelectedItemsActionCreator);
export const exportForIntegrationAction = actionWithPrintDownloadManager(exportForIntegrationActionCreator);
export const exportTimeActivityAction = actionWithPrintPopupWindow(exportTimeActivityActionCreator);
