import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { TimeEventActionResponse, TimeEventHistoryEntry } from 'api/actions/timeEvent/timeEventActions.types';
import { FullName } from 'components/utils/FullName';
import { organizationSessionAtom } from 'state/organizationSession';
import { dateTime } from 'utils/dateTime';

import { VersionContainer } from './VersionContainer';

type Props = {
  action: TimeEventActionResponse;
  modificationPersonId?: string;
  modificationDateTime?: number;
  previous?: TimeEventHistoryEntry;
};

export const EventHistoryChunk = ({
  action,
  modificationPersonId,
  modificationDateTime,
  previous,
}: Props): React.ReactElement | null => {
  const organizationSession = useRecoilValue(organizationSessionAtom);
  const modificationPerson = useMemo(
    () => organizationSession?.employees.find(({ id }) => id === modificationPersonId),
    [modificationPersonId, organizationSession?.employees],
  );

  if (!modificationPerson) return null;

  const {
    name: { firstName, surname },
  } = modificationPerson;

  return (
    <Flex sx={{ flexDirection: 'column', flex: '1 0', pb: 3 }}>
      <Text sx={{ pb: '0.25rem' }}>
        <Text sx={{ fontWeight: 'bold' }}>
          <FullName firstName={firstName} surname={surname} />,{' '}
        </Text>

        {modificationDateTime && (
          <Text sx={{ color: 'texts.lighter' }}>{dateTime(modificationDateTime).format('lll')}</Text>
        )}
      </Text>

      {action !== TimeEventActionResponse.Create && previous && <VersionContainer previous={previous} />}
    </Flex>
  );
};
