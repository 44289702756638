import React from 'react';
import { Text } from 'theme-ui';

type Props = {
  title: string;
};

export const GroupTitle = ({ title }: Props): React.ReactElement => (
  <Text
    onClick={(e) => e.stopPropagation()}
    className="group-title"
    as="span"
    sx={{
      px: '0.75rem',
      textAlign: 'left',
      color: 'texts.lighter',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 0,
      width: '100%',
      display: 'block',
    }}
  >
    {title}
  </Text>
);
