import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { fetchTagInheritedFeaturesAction } from 'api/actions/employees/employeesActions';
import { FetchTagInheritedFeaturesResponse } from 'api/actions/employees/employeesActions.types';
import { ProfileFormState } from 'state/team';

export const useTagInheritedFeatures = () => {
  const [isFetchingTagInheritedFeatures, setIsFetchingTagInheritedFeatures] = useState(false);
  const [tagInheritedFeaturesResponse, setTagInheritedFeaturesResponse] =
    useState<FetchTagInheritedFeaturesResponse | null>(null);

  const { mutate: fetchTagInheritedFeatures } = useMutation(fetchTagInheritedFeaturesAction);

  const getTagInheritedFeatures = useCallback(
    async (tagsIds: ProfileFormState['tagsIds']) => {
      const { payload: fetchTagInheritedFeaturesPayload, error: fetchTagInheritedFeaturesError } =
        await fetchTagInheritedFeatures({ tagsIds });

      if (!fetchTagInheritedFeaturesError && fetchTagInheritedFeaturesPayload) {
        setTagInheritedFeaturesResponse(fetchTagInheritedFeaturesPayload);
      }
      setIsFetchingTagInheritedFeatures(false);
    },
    [fetchTagInheritedFeatures],
  );

  const debouncedGetTagInheritedFeatures = useMemo(
    () => debounce(getTagInheritedFeatures, 500),
    [getTagInheritedFeatures],
  );

  const handleGetTagInheritedFeatures = useCallback(
    async (tagsIds: ProfileFormState['tagsIds']) => {
      setIsFetchingTagInheritedFeatures(true);
      await debouncedGetTagInheritedFeatures(tagsIds);
    },
    [debouncedGetTagInheritedFeatures],
  );

  return {
    isFetchingTagInheritedFeatures,
    tagInheritedFeaturesResponse,
    getTagInheritedFeatures: handleGetTagInheritedFeatures,
  };
};
