import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-fetching-library';
import { UseFormRegister, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { timesheetsReportViewSettings } from 'api/actions/reports/reportsActions';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { Switch } from 'components/ui/Switch';
import { DEFAULT_WORK_STATUSES_IDS } from 'constants/common';
import {
  dayStatesArray,
  getDayStateHeaderNamesMap,
  getOtherHeaderNamesMap,
  getPayrollHeaderNamesMap,
  getTimersHeaderNamesMap,
  otherArray,
  payrollArray,
  timersArray,
} from 'constants/reports';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { parsedEmployeesSelector } from 'state/employees';
import {
  customRequestTypesDictionarySelector,
  timeEventTypesDictionarySelector,
  timeOffTypesDictionarySelector,
} from 'state/organizationSession';
import { timesheetsReportHiddenColumns, timesheetsReportShowSummaryRowSelector } from 'state/reports';

type ViewSettingsFormState = Record<string, boolean>;

type SettingsSwitchesProps = {
  mappedValues: Record<string, { id: string; name: string; abbreviation?: string }> | null;
  register: UseFormRegister<ViewSettingsFormState>;
  groupName: string;
};

export const SettingsSwitches = ({ mappedValues, register, groupName }: SettingsSwitchesProps) => (
  <Flex sx={{ flexDirection: 'column' }}>
    <Modal.SubTitle>{t({ id: groupName })}</Modal.SubTitle>
    <Flex sx={{ gap: 2, flexDirection: 'column', alignItems: 'flex-start' }}>
      {_.map(mappedValues, (v) => (
        <Switch
          size="sm"
          sx={{ fontWeight: '400' }}
          key={`${v.id}`}
          {...register(v.id)}
          label={`${t({ id: v.name })}${v.abbreviation ? ` (${t({ id: v.abbreviation })})` : ''}`}
        />
      ))}
    </Flex>
  </Flex>
);

export const TimesheetsViewSettingsModal = (): React.ReactElement => {
  useLingui();

  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();
  const hiddenColumns = useRecoilValue(timesheetsReportHiddenColumns);
  const isTimeOffCustomRequestColumnShown = useCallback(
    (column: { id: string }) => !hiddenColumns.includes(column.id),
    [hiddenColumns],
  );
  const showSummaryRow = useRecoilValue(timesheetsReportShowSummaryRowSelector);

  const timeEventTypes = useRecoilValue(timeEventTypesDictionarySelector);
  const filteredTimeEventTypes = _.filter(
    timeEventTypes,
    (type) => type.id !== DEFAULT_WORK_STATUSES_IDS.ENTER && type.id !== DEFAULT_WORK_STATUSES_IDS.EXIT,
  );
  const timeOffTypes = useRecoilValue(timeOffTypesDictionarySelector);
  const customRequestTypes = useRecoilValue(customRequestTypesDictionarySelector);
  const employeesMap = useRecoilValue(parsedEmployeesSelector);
  const shouldDisplaySummaryRowSetting = useMemo(() => employeesMap && employeesMap.size > 1, [employeesMap]);

  const { systemManagement } = useAppPermissions();

  const defaultValues = {
    ..._.mapValues(timeOffTypes, isTimeOffCustomRequestColumnShown),
    ..._.mapValues(customRequestTypes, isTimeOffCustomRequestColumnShown),
  };

  const {
    register,
    handleSubmit,
    formState: { isLoading, isDirty },
  } = useForm({ defaultValues });

  const generateTimeEventSwitches = useMemo(
    () => (
      <Flex sx={{ gap: 2, flexDirection: 'column', alignItems: 'flex-start' }}>
        {_.map(filteredTimeEventTypes, (timeEventType) => (
          <Switch
            size="sm"
            sx={{ fontWeight: '400' }}
            key={timeEventType.id}
            defaultChecked={!_.includes(hiddenColumns, timeEventType.id)}
            {...register(timeEventType.id)}
            label={`${t({ id: timeEventType.name })}${
              timeEventType.isDeleted ? ` (${t({ id: 'report.archive' })})` : ''
            }`}
          />
        ))}
      </Flex>
    ),
    [filteredTimeEventTypes, register, hiddenColumns],
  );

  const generateSwitches = useCallback(
    (nameArray: string[], labelArray: Record<string, string>) => (
      <Flex sx={{ gap: 2, flexDirection: 'column', alignItems: 'flex-start' }}>
        {_.map(nameArray, (name) => (
          <Switch
            size="sm"
            sx={{ fontWeight: '400' }}
            key={name}
            defaultChecked={!_.includes(hiddenColumns, name)}
            {...register(name)}
            label={labelArray[name]}
          />
        ))}
      </Flex>
    ),
    [register, hiddenColumns],
  );

  const { mutate } = useMutation(timesheetsReportViewSettings);

  const handleSave = handleSubmit(async (data) => {
    const { error: submitError } = await mutate({
      hiddenColumns: _.keys(_.pickBy(data, (val, key) => !val && key)),
      showSummaryRow: !!data.summaryRow,
    });
    if (!submitError) {
      addSnackbar({
        message: t({ id: 'team.view_settings.edited', message: 'Successfully edited!' }),
        variant: 'success',
      });

      if (handleClose) {
        handleClose();
      }
    }
  });

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.view_settings">View settings</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ gap: 5 }}>
        {shouldDisplaySummaryRowSetting && (
          <Flex sx={{ flexDirection: 'column' }}>
            <Modal.SubTitle>
              <Trans id="reports.rows">Rows</Trans>
            </Modal.SubTitle>
            <Switch
              size="sm"
              sx={{ fontWeight: '400' }}
              key="summaryRow"
              defaultChecked={showSummaryRow || undefined}
              {...register('summaryRow')}
              label={t({ id: 'reports.summary_row', message: 'Summary' })}
            />
          </Flex>
        )}
        <Flex sx={{ flexDirection: 'column' }}>
          <Modal.SubTitle>{t({ id: 'global.columns' })}</Modal.SubTitle>
          {generateSwitches(otherArray, getOtherHeaderNamesMap())}
        </Flex>
        <Flex sx={{ flexDirection: 'column' }}>
          <Modal.SubTitle>{t({ id: 'reports.group_names.timers' })}</Modal.SubTitle>
          {generateSwitches(timersArray, getTimersHeaderNamesMap())}
        </Flex>
        {systemManagement.Payroll && (
          <Flex sx={{ flexDirection: 'column' }}>
            <Modal.SubTitle>{t({ id: 'reports.group_names.payroll' })}</Modal.SubTitle>
            {generateSwitches(payrollArray, getPayrollHeaderNamesMap())}
          </Flex>
        )}
        <Flex sx={{ flexDirection: 'column' }}>
          <Modal.SubTitle>{t({ id: 'reports.group_names.day_states' })}</Modal.SubTitle>
          {generateSwitches(dayStatesArray, getDayStateHeaderNamesMap())}
        </Flex>

        <Flex sx={{ flexDirection: 'column' }}>
          <Modal.SubTitle>{t({ id: 'reports.group_names.work_statuses' })}</Modal.SubTitle>
          {generateTimeEventSwitches}
        </Flex>

        <SettingsSwitches mappedValues={timeOffTypes} register={register} groupName="reports.group_names.time_offs" />

        <SettingsSwitches
          mappedValues={customRequestTypes}
          register={register}
          groupName="reports.group_names.custom_requests"
        />
      </Modal.Body>

      <BasicModalFooter
        buttons={[
          {
            isLoading,
            disabled: !isDirty,
            onClick: () => void handleSave(),
            variant: 'primary',
            children: t({ id: 'save', message: 'Save' }),
          },
        ]}
      />
    </>
  );
};
