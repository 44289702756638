/** @jsxImportSource theme-ui */
import React from 'react';
import { t, Plural } from '@lingui/macro';
import { Box } from 'theme-ui';

import { ElementGroup } from 'components/ui//ElementGroup';
import { messageSx } from 'components/Snackbar/Snackbar';

import {
  DEFAULT_MAX_NAME_DISPLAY_LENGTH,
  FILE_TYPES,
  DEFAULT_MAX_NAME_LENGTH,
  DEFAULT_MAX_FILES,
  DEFAULT_MAX_UPLOAD_SIZE,
  DEFAULT_MIN_UPLOAD_SIZE,
} from './constants';
import { ValidateBlobType } from './types';

export const bytesToMB = (bytes: number): number => Math.round(bytes * 0.000001 * 100) / 100;

export const shortenFileName = (name: string): string =>
  name.length > DEFAULT_MAX_NAME_DISPLAY_LENGTH ? `${name.slice(0, 13)}...${name.slice(-13)}` : name;

export const getExtensionFromType = (type: FILE_TYPES): string => FILE_TYPES[type];

export const getExtension = (filename: string): string | undefined => {
  const ext = filename?.split('.').pop();
  return ext === filename ? '' : ext;
};

const removeExtension = (filename: string): string => filename.slice(0, filename.lastIndexOf('.')) || filename;

export const validateBlobFiles = ({
  pickedFiles,
  existingFiles,
  serverFiles,
  addSnackbar,
  maxSize,
  minSize,
  acceptExt,
  maxFiles,
}: ValidateBlobType): File[] => {
  const maxSizeInMB = bytesToMB(maxSize || DEFAULT_MAX_UPLOAD_SIZE);
  const minSizeInMB = bytesToMB(minSize || DEFAULT_MIN_UPLOAD_SIZE);

  const errors: Record<string, string> = {};

  const checkDuplicates = (files: File[]) => {
    if (!files?.length) return files;

    const { noDuplicateFiles, duplicateFiles } = files.reduce(
      (obj, file) => {
        const isBlobDuplicate = existingFiles.some(
          (existingFile) => existingFile.name === file.name && existingFile.size === file.size,
        );
        const isServerDuplicate = serverFiles.some(
          (serverFile) => serverFile.name === removeExtension(file.name) && serverFile.size === file.size,
        );
        if (isBlobDuplicate || isServerDuplicate) obj.duplicateFiles.push(file);
        else obj.noDuplicateFiles.push(file);
        return obj;
      },
      { noDuplicateFiles: [] as File[], duplicateFiles: [] as File[] },
    );

    if (duplicateFiles?.length) {
      duplicateFiles.forEach((file) => {
        errors[shortenFileName(file.name)] = t({
          id: 'file_upload.duplicates_err',
          message: `already uploaded`,
        });
      });
    }

    return noDuplicateFiles;
  };

  const checkAcceptExt = (files: File[]) => {
    if (!files?.length) return files;

    const { validExtFiles, wrongExtFiles } = files.reduce(
      (obj, file) => {
        const isExtAccepted = acceptExt?.some(
          (acceptedExtension) => acceptedExtension === getExtension(file.name)?.toLowerCase(),
        );
        if (isExtAccepted) obj.validExtFiles.push(file);
        else obj.wrongExtFiles.push(file);
        return obj;
      },
      { validExtFiles: [] as File[], wrongExtFiles: [] as File[] },
    );

    if (wrongExtFiles?.length) {
      wrongExtFiles.forEach((file) => {
        errors[shortenFileName(file.name)] = t({
          id: 'file_upload.acceptExt_err',
          message: `incorrect extension`,
        });
      });
    }

    return validExtFiles;
  };

  const checkMaxSize = (files: File[]) => {
    if (!files?.length) return files;

    const { validMaxSizeFiles, tooLargeFiles } = files.reduce(
      (obj, file) => {
        if (file.size > (maxSize || DEFAULT_MAX_UPLOAD_SIZE)) obj.tooLargeFiles.push(file);
        else obj.validMaxSizeFiles.push(file);
        return obj;
      },
      { validMaxSizeFiles: [] as File[], tooLargeFiles: [] as File[] },
    );

    if (tooLargeFiles?.length) {
      tooLargeFiles.forEach((file) => {
        errors[shortenFileName(file.name)] = t({
          id: 'file_upload.max_size_err',
          message: `maximum file size exceeded (max. ${maxSizeInMB} MB)`,
        });
      });
    }

    return validMaxSizeFiles;
  };

  const checkMinSize = (files: File[]) => {
    if (!files?.length) return files;

    const { validMinSizeFiles, tooSmallFiles } = files.reduce(
      (obj, file) => {
        if (file.size < (minSize || DEFAULT_MIN_UPLOAD_SIZE)) obj.tooSmallFiles.push(file);
        else obj.validMinSizeFiles.push(file);
        return obj;
      },
      { validMinSizeFiles: [] as File[], tooSmallFiles: [] as File[] },
    );

    if (tooSmallFiles?.length) {
      tooSmallFiles.forEach((file) => {
        errors[shortenFileName(file.name)] = t({
          id: 'file_upload.min_size_err',
          message: `below minimum file size (${minSizeInMB} MB)`,
        });
      });
    }

    return validMinSizeFiles;
  };

  const checkNameLength = (files: File[]) => {
    if (!files?.length) return files;
    const { validNameLengthFiles, tooLongNameLengthFiles } = files.reduce(
      (obj, file) => {
        if (file.name.length > DEFAULT_MAX_NAME_LENGTH) obj.tooLongNameLengthFiles.push(file);
        else obj.validNameLengthFiles.push(file);
        return obj;
      },
      { validNameLengthFiles: [] as File[], tooLongNameLengthFiles: [] as File[] },
    );

    if (tooLongNameLengthFiles?.length) {
      tooLongNameLengthFiles.forEach((file) => {
        errors[shortenFileName(file.name)] = t({
          id: 'file_upload.name_length_err',
          message: `too long file name (max. ${DEFAULT_MAX_NAME_LENGTH} characters)`,
        });
      });
    }

    return validNameLengthFiles;
  };

  const checkComma = (files: File[]) => {
    if (!files?.length) return files;

    const { noCommaFiles, commaFiles } = files.reduce(
      (obj, file) => {
        if (file.name.includes(',')) obj.commaFiles.push(file);
        else obj.noCommaFiles.push(file);
        return obj;
      },
      { noCommaFiles: [] as File[], commaFiles: [] as File[] },
    );

    if (commaFiles?.length) {
      commaFiles.forEach((file) => {
        errors[shortenFileName(file.name)] = t({
          id: 'file_upload.comma_err',
          message: `comma not allowed in file name`,
        });
      });
    }

    return noCommaFiles;
  };

  const checkMaxFiles = (files: File[]) => {
    if (!files?.length) return files;
    const totalFiles = files.length + existingFiles.length + serverFiles.length;
    if (totalFiles > (maxFiles || DEFAULT_MAX_FILES)) {
      errors.maxFiles = t({
        id: 'file_upload.max_file_no_err',
        message: `Max. file number exceeded (${maxFiles || DEFAULT_MAX_FILES}).`,
      });
      return files.slice(0, (maxFiles || DEFAULT_MAX_FILES) - existingFiles.length - serverFiles.length);
    }
    return files;
  };

  // Run validation fns:
  // the order of fns run is important (display to the user one, most important rejection reason)
  const noDuplicateFiles = checkDuplicates(pickedFiles);
  const onlyAcceptedExtFiles = checkAcceptExt(noDuplicateFiles);
  const belowMaxSizeFiles = checkMaxSize(onlyAcceptedExtFiles);
  const aboveMinSizeFiles = checkMinSize(belowMaxSizeFiles);
  const validNameLengthFiles = checkNameLength(aboveMinSizeFiles);
  const noCommaFiles = checkComma(validNameLengthFiles);
  const validFiles = checkMaxFiles(noCommaFiles);

  // Display message about rejected files:
  if (errors.maxFiles) {
    addSnackbar({
      variant: 'warning',
      message: errors.maxFiles,
      duration: 5000,
    });
  }

  if (!errors.maxFiles && Object.keys(errors).length) {
    addSnackbar({
      variant: 'warning',
      message: (
        <Box sx={{ textAlign: 'left', my: 2, mx: 5, ...messageSx }}>
          <Plural
            id="file_upload.err_heading"
            value={Object.keys(errors).length}
            one="File has been rejected:"
            other="Some files have been rejected:"
          />
          <ElementGroup showAsList direction="column">
            {Object.entries(errors).map(([name, errMessage]) => (
              <span key={name}>
                <span sx={{ color: 'fileUpload.snackbar' }}>- {name}</span>: {errMessage}
              </span>
            ))}
          </ElementGroup>
        </Box>
      ),
      duration: 5000,
    });
  }

  return validFiles;
};
