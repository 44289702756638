/* eslint-disable func-names */
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { ControlledModal, Modal, openModal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { Button, ButtonProps } from 'components/ui/Buttons';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { boostModalZIndexAtom } from 'state/modal';

type DefaultResolveWith = boolean | null;
type DialogModalRenderer = (modalDetails: ReturnType<typeof useModal>) => React.ReactElement;
type DialogModalAction<T = DefaultResolveWith> = {
  resolveWith: T;
  buttonProps: Omit<ButtonProps, 'shape' | 'size'>;
};

type SimpleDialogModalOptions<T = DefaultResolveWith> = {
  title: string | React.ReactElement;
  body: string | React.ReactElement;
  actions: DialogModalAction<T>[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DialogModalContent<T = any> = SimpleDialogModalOptions<T> | DialogModalRenderer;

export const dialogModalContentAtom = atom<DialogModalContent | null>({
  key: 'dialogModalContent',
  default: null,
});

export const DIALOG_MODAL_ID = 'DIALOG_MODAL_ID';

const DialogModal = () => {
  useLingui();
  const dialogModalContent = useRecoilValue(dialogModalContentAtom);
  const resetDialogModalContent = useResetRecoilState(dialogModalContentAtom);
  const resetBoostModalZIndex = useResetRecoilState(boostModalZIndexAtom);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modalDetails = useModal<any>();

  useUnmount(() => {
    resetDialogModalContent();
    resetBoostModalZIndex();
  });

  if (!dialogModalContent) return null;

  if (_.isFunction(dialogModalContent)) {
    return dialogModalContent(modalDetails);
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{dialogModalContent.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{dialogModalContent.body}</Modal.Body>
      <Modal.Footer>
        <>
          {dialogModalContent.actions?.map(({ buttonProps, resolveWith }) => (
            <Button
              key={_.uniqueId()}
              {...buttonProps}
              onClick={() => {
                const { resolver, handleClose } = modalDetails;
                if (!resolver) return;
                resolver(resolveWith);
                handleClose();
              }}
              shape="rounded"
            />
          ))}
        </>
      </Modal.Footer>
    </>
  );
};

export const DialogModalProvider = () => (
  <ControlledModal size="xs" id={DIALOG_MODAL_ID}>
    <DialogModal />
  </ControlledModal>
);

export function useDialogModal<ResolveWith = boolean | null>(
  modalContent?: DialogModalContent<ResolveWith>,
  boostZIndex?: boolean, // used to temporaily boost #modal-root z-index
) {
  const setDialogModalContent = useSetRecoilState(dialogModalContentAtom);
  const setBoostModalZIndex = useSetRecoilState(boostModalZIndexAtom);

  const openDialogModal = useCallback(
    // eslint-disable-next-line prefer-arrow-callback
    function <CustomResolveWith = ResolveWith>(content?: DialogModalContent<CustomResolveWith>) {
      if (!modalContent && !content) return null;

      if (boostZIndex) {
        setBoostModalZIndex(true);
      }

      setDialogModalContent(() => content || modalContent || null);

      return openModal<CustomResolveWith>(DIALOG_MODAL_ID);
    },
    [modalContent, setDialogModalContent, setBoostModalZIndex, boostZIndex],
  );

  return openDialogModal;
}
