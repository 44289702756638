import React from 'react';
import { ThemeUIStyleObject, Text, Flex } from 'theme-ui';

export const decorationSx: ThemeUIStyleObject = {
  textDecorationStyle: 'dotted',
  textDecorationLine: 'underline',
  textDecorationColor: 'tooltip.textDecoration',
};

export type TooltipProps = {
  sx?: ThemeUIStyleObject;
  content: React.ReactNode;
};

const defaultProps: Partial<TooltipProps> = {
  sx: undefined,
};

export const Tooltip = ({ sx, content }: TooltipProps): React.ReactElement => (
  <Flex variant="tooltip.container" sx={{ ...(sx && sx) }}>
    <Text variant="tooltip.content">{content}</Text>
  </Flex>
);

Tooltip.defaultProps = defaultProps;
