import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CHAT_BOT } from 'constants/chat';
import { APP_NAME, CRISP_WEBSITE_ID, INITIALIZE_CHAT_WITH_OPEN_CRISP_CHAT } from 'constants/common';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useMemoCompare } from 'hooks/useMemoCompare/useMemoCompare';
import { chatUserWithUnreadMessagesSelectorFamily, crispIsVisibleAtom, crispSessionDataSelector } from 'state/chat';
import { organizationDetailsSelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';
import { userDetailsSelector } from 'state/userSession';
import { setIntervalX } from 'utils/setIntervalX';

type PushProps = (string | (() => void) | string[][] | [[[string, string | number | undefined]]])[];
type CrispMethods = {
  push: (props: PushProps) => void;
  is: (query: string) => boolean;
  get: (query: string) => number;
};

declare global {
  interface Window {
    $crisp: PushProps[] | CrispMethods;
    CRISP_TOKEN_ID: string;
    CRISP_WEBSITE_ID: string;
    CRISP_RUNTIME_CONFIG: { locale: string };
    CRISP_READY_TRIGGER: () => void;
  }
}

type Crisp = CrispMethods & {
  isReady: () => boolean;
};
export const CRISP: Crisp = {
  push: (props) => window.$crisp?.push(props),
  is: (query) => (!_.isArray(window.$crisp) && window.$crisp?.is ? window.$crisp?.is(query) : false),
  get: (query) => (!_.isArray(window.$crisp) && window.$crisp?.get ? window.$crisp?.get(query) : 0),
  isReady: () => !_.isArray(window.$crisp) && window.$crisp?.is && _.isBoolean(window.$crisp?.is('chat:opened')),
};

let chatWasInitializedWithOpenCrispChat = false;

export const CrispProvider = (): null => {
  useLingui();
  const setCrispIsVisible = useSetRecoilState(crispIsVisibleAtom);
  const setHasUnreadMessages = useSetRecoilState(chatUserWithUnreadMessagesSelectorFamily(CHAT_BOT));
  const organizationDetails = useRecoilValue(organizationDetailsSelector);
  const userDetails = useRecoilValue(userDetailsSelector);
  const crispSessionData = useRecoilValue(crispSessionDataSelector);
  const userDetailsRef = useCallbackRef(userDetails);

  const crispBasicData = {
    'user:nickname': `${userDetails?.name.firstName} ${userDetails?.name.surname}`,
    ...(organizationDetails?.name
      ? {
          'user:company': organizationDetails?.name,
        }
      : {}),
    ...(userDetails?.email
      ? {
          'user:email': userDetails?.email,
        }
      : {}),
    ...(userDetails?.phoneNumber
      ? {
          'user:phone': userDetails?.phoneNumber,
        }
      : {}),
    'session:segments': [[APP_NAME]],
  };

  const memoizedCrispBasicData = useMemoCompare(crispBasicData);
  const memoizedCrispSessionData = useMemoCompare(crispSessionData);

  const language = useRecoilValue(languageSelector);

  useEffect(() => {
    window.CRISP_READY_TRIGGER = () => {
      const checkUnreadMessagesCount = () => {
        const unreadMessageCount = CRISP.get('chat:unread:count');
        if (!unreadMessageCount) return false;
        setHasUnreadMessages(true);
        return true;
      };
      if (!CRISP.isReady()) return;

      if (INITIALIZE_CHAT_WITH_OPEN_CRISP_CHAT && !chatWasInitializedWithOpenCrispChat) {
        CRISP.push(['do', 'chat:open']);
        CRISP.push(['do', 'chat:show']);
        chatWasInitializedWithOpenCrispChat = true;
      }

      if (checkUnreadMessagesCount()) return;

      setIntervalX(checkUnreadMessagesCount, 300, 6);
    };
    const initCrisp = () => {
      const d = document;
      const crispScript = d.querySelector(`script[src='${process.env.REACT_APP_CRISP_URL}']`);

      if (!crispScript && userDetailsRef.current) {
        window.$crisp = [];
        window.CRISP_TOKEN_ID = userDetailsRef.current.id;
        window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;
        window.CRISP_RUNTIME_CONFIG = { locale: language };

        const s = document.createElement('script');
        s.src = `${process.env.REACT_APP_CRISP_URL}`;
        s.async = true;
        d.getElementsByTagName('head')[0].appendChild(s);
      }
    };

    const crispConfig = () => {
      CRISP.push(['do', 'chat:hide']);
      CRISP.push([
        'on',
        'chat:opened',
        () => {
          CRISP.push(['do', 'chat:show']);
          setCrispIsVisible(true);
          setHasUnreadMessages(false);
        },
      ]);
      CRISP.push([
        'on',
        'chat:closed',
        () => {
          CRISP.push(['do', 'chat:hide']);
          setCrispIsVisible(false);
          setHasUnreadMessages(false);
        },
      ]);
      CRISP.push([
        'on',
        'message:received',
        () => {
          setHasUnreadMessages(true);
        },
      ]);
    };

    initCrisp();
    crispConfig();
  }, [language, setCrispIsVisible, setHasUnreadMessages, userDetailsRef]);

  useEffect(() => {
    if (window.$crisp) {
      Object.entries(memoizedCrispBasicData).forEach(([key, value]) => {
        CRISP.push(['set', key, value || '']);
      });
    }
  }, [memoizedCrispBasicData]);

  useEffect(() => {
    if (window.$crisp && memoizedCrispSessionData) {
      Object.entries(memoizedCrispSessionData).forEach(([key, value]) => {
        CRISP.push(['set', 'session:data', [[[key, value]]]]);
      });
    }
  }, [memoizedCrispSessionData]);

  useEffect(
    () => () => {
      if (window.$crisp) {
        CRISP.push(['do', 'session:reset']);
        CRISP.push(['do', 'chat:close']);
        CRISP.push(['do', 'chat:hide']);
      }
    },
    [],
  );

  return null;
};
