import { Trans } from '@lingui/macro';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, FlexProps, Text } from 'theme-ui';

import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { parsedAntiSpoofingChecksSelector } from 'state/home';
import { requestsToAcceptAtom } from 'state/requests';
import { HomeBox } from '../HomeBox/HomeBox';

import { AntiSpoofingList } from './components/AntiSpoofingList/AntiSpoofingList';
import { RequestsList } from './components/RequestsList/RequestsList';

type Props = FlexProps;

export const ThingsToDo = (props: Props) => {
  const [requestsInitialized, setRequestsInitialized] = useState(false);
  const [antiSpoofingInitialized, setAntiSpoofingInitialized] = useState(false);
  const requestsToAccept = useRecoilValue(requestsToAcceptAtom);
  const antiSpoofingChecks = useRecoilValue(parsedAntiSpoofingChecksSelector);

  const {
    modulesManagement,
    timeTrackingSettings: { isFaceRecognitionRequired, isBasicFraudDetectionRequired },
  } = useAppPermissions();

  const noThingsToDo = useMemo(
    () => !!(!requestsToAccept?.length && !antiSpoofingChecks?.size),
    [antiSpoofingChecks?.size, requestsToAccept?.length],
  );

  const allInitialized = (() => {
    let initialized = false;
    if (modulesManagement.Requests) {
      initialized = requestsInitialized;
    }
    if (modulesManagement.TimeTracking && (isFaceRecognitionRequired || isBasicFraudDetectionRequired)) {
      initialized = modulesManagement.Requests ? initialized && antiSpoofingInitialized : antiSpoofingInitialized;
    }
    return initialized;
  })();

  const onFetchResponse = useCallback(
    (setter: Dispatch<SetStateAction<boolean>>) => () => {
      setter(true);
    },
    [],
  );

  return (
    <HomeBox {...props}>
      <HomeBox.Header sx={{ mb: 3 }}>
        <HomeBox.Title>
          <Trans id="home.things_to_do">Things to do</Trans>
        </HomeBox.Title>
      </HomeBox.Header>

      {allInitialized && noThingsToDo && (
        <Text variant="pLead" sx={{ color: 'home.text' }}>
          <Trans id="home.things_to_do.no_new_tasks">Seems like there are no new tasks for you at the moment. 🎊</Trans>
        </Text>
      )}
      <Flex sx={{ flexDirection: 'column', gap: 6 }}>
        {modulesManagement.Requests && <RequestsList onFetchResponse={onFetchResponse(setRequestsInitialized)} />}
        {modulesManagement.TimeTracking && (isFaceRecognitionRequired || isBasicFraudDetectionRequired) && (
          <AntiSpoofingList onFetchResponse={onFetchResponse(setAntiSpoofingInitialized)} />
        )}
      </Flex>
    </HomeBox>
  );
};
