import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { ListNames } from 'components/StickyList/types';
import { usePickTeammates } from 'hooks/usePickTeammates/usePickTeammates';
import {
  addRequestFormStepStateAtom,
  addRequestStateAtom,
  defaultDateUnixAtom,
  modifyRequestDataAtom,
  parsedModifyRequestDataSelector,
  requestConfigStateAtom,
  requestFormRestoredAtom,
  requestLimitsAtom,
  requestLimitsLoadingAtom,
  selectedParsedRequestToEditSelector,
  selectedRequestToEditRemoveAtom,
} from 'state/requests';

export const useAddRequest = () => {
  const [formState, setFormState] = useRecoilState(addRequestFormStepStateAtom);
  const [addRequestState, setAddRequestState] = useRecoilState(addRequestStateAtom);
  const [defaultDateUnix, setDefaultDateUnix] = useRecoilState(defaultDateUnixAtom);
  const setModifyRequestData = useSetRecoilState(modifyRequestDataAtom);
  const parsedModifyRequestData = useRecoilValue(parsedModifyRequestDataSelector);
  const [selectedRequestToEditId, setSelectedRequestToEditId] = useRecoilState(selectedRequestToEditRemoveAtom);
  const selectedRequestToEdit = useRecoilValue(selectedParsedRequestToEditSelector);
  const resetSelectedRequestToEditId = useResetRecoilState(selectedRequestToEditRemoveAtom);
  const [selectedTeammatesIds, setSelectedTeammatesIds] = usePickTeammates(ListNames.REQUESTS_PICK_TEAMMATES);
  const [requestLimits, setRequestLimits] = useRecoilState(requestLimitsAtom);
  const resetRequestLimits = useResetRecoilState(requestLimitsAtom);
  const [requestLimitsLoading, setRequestLimitsLoading] = useRecoilState(requestLimitsLoadingAtom);
  const [requestFormRestored, setRequestFormRestored] = useRecoilState(requestFormRestoredAtom);
  const [requestConfigState, setRequestConfigState] = useRecoilState(requestConfigStateAtom);

  return {
    formState,
    setFormState,
    addRequestState,
    setAddRequestState,
    requestFormRestored,
    setRequestFormRestored,
    modifyRequest: {
      modifyRequestData: parsedModifyRequestData,
      setModifyRequestData,
    },
    requestToEdit: {
      selectedRequestToEditId,
      selectedRequestToEdit,
      setSelectedRequestToEditId,
      resetSelectedRequestToEditId,
    },
    selectedTeammatesIds,
    setSelectedTeammatesIds,
    defaultDateUnix,
    setDefaultDateUnix,
    requestLimits: {
      requestLimits,
      setRequestLimits,
      resetRequestLimits,
      requestLimitsLoading,
      setRequestLimitsLoading,
    },
    requestConfig: {
      requestConfigState,
      setRequestConfigState,
    },
  };
};
