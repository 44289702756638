import React from 'react';

const LazyPayments = React.lazy(() =>
  import('./components/Payments/Payments').then(({ Payments }) => ({
    default: Payments,
  })),
);

export const PaymentsRenderer = React.memo(
  (): React.ReactElement => (
    <React.Suspense fallback={<></>}>
      <LazyPayments />
    </React.Suspense>
  ),
);
