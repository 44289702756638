import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { TO } from 'constants/routes';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { languageSelector } from 'state/recoilState';

type Props = { children: React.ReactNode };

export const RequireSignUpInProgress = ({ children }: Props) => {
  const language = useRecoilValue(languageSelector);
  const { isSignUpInProgress } = useAuthState();

  if (isSignUpInProgress) {
    return children;
  }

  return <Navigate to={TO.SIGN_UP[language]} />;
};
