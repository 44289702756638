import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { ChangeEvent, FC, Fragment, memo } from 'react';
import { Control, UseFormRegister, useFormState, UseFormTrigger, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { Checkbox } from 'components/ui/Checkbox';
import { MaskedTextInput } from 'components/ui/MaskedTextInput';
import { Select } from 'components/ui/Select/Select';
import { customRequestTypesDictionarySelector } from 'state/organizationSession';
import { periodSelectOptionsSelector } from 'state/selectOptions';
import { EmployeeRequestLimitsFormState, ParsedCustomRequestLimit } from 'state/team';
import { delay } from 'utils/delay';
import { RequestLimitsBasicControl, RequestLimitsBasicFormState, RequestLimitsBasicRegister } from '../../types';

type BasicControl = RequestLimitsBasicControl;
type BasicRegister = RequestLimitsBasicRegister;

type Props = {
  nestIndex: number;
  trigger: UseFormTrigger<EmployeeRequestLimitsFormState> | UseFormTrigger<RequestLimitsBasicFormState>;
  control: Control<EmployeeRequestLimitsFormState> | Control<RequestLimitsBasicFormState>;
  register: UseFormRegister<EmployeeRequestLimitsFormState> | UseFormRegister<RequestLimitsBasicFormState>;
  update: (index: number, value: ParsedCustomRequestLimit) => void;
};

export const NestedRequestLimits: FC<Props> = ({ trigger, nestIndex, control, register, update }) => {
  useLingui();
  const { errors } = useFormState({ control: control as BasicControl });

  const watchNestedLimits = useWatch({
    control: control as BasicControl,
    name: `customRequestsLimits.${nestIndex}.limits`,
  }) as ParsedCustomRequestLimit[];

  const periodSelectOptions = useRecoilValue(periodSelectOptionsSelector);

  const customRequestTypesDictionary = useRecoilValue(customRequestTypesDictionarySelector);

  if (!customRequestTypesDictionary) return null;

  return (
    <Flex sx={{ flexDirection: 'column', gap: 2, mt: 2 }}>
      {watchNestedLimits.map((field, index) => {
        const { name, abbreviation } = customRequestTypesDictionary[field.customRequestTypeId] || {};
        if (!name || !abbreviation) return <Fragment key={`custom-request-type-${field.customRequestTypeId}`} />;

        const periodRegisterPropsDefault = (register as BasicRegister)(
          `customRequestsLimits.${nestIndex}.limits.${index}.period`,
        );
        const periodRegisterProps = {
          ...periodRegisterPropsDefault,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            void periodRegisterPropsDefault.onChange(e);
            void trigger(`customRequestsLimits.${nestIndex}.limits.${index}.days`);
          },
        };
        const daysRegisterPropsDefault = {
          ...(register as BasicRegister)(`customRequestsLimits.${nestIndex}.limits.${index}.days`),
        };
        const daysRegisterProps = {
          ...daysRegisterPropsDefault,
          onChange: async (e: ChangeEvent<HTMLInputElement>) => {
            await delay(200);
            void daysRegisterPropsDefault.onChange(e);
          },
        };

        const unlimitedRegisterPropsDefault = (register as BasicRegister)(
          `customRequestsLimits.${nestIndex}.limits.${index}.unlimited`,
        );

        const triggerSuspendedDaysValidation = async () => {
          await delay(100);
          void trigger(`customRequestsLimits.${nestIndex}.limits.${index}.days`);
        };

        const unlimitedRegisterProps = {
          ...unlimitedRegisterPropsDefault,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target.checked) {
              void unlimitedRegisterPropsDefault.onChange(e);
              void triggerSuspendedDaysValidation();
              return;
            }
            update(index, {
              ...field,
              unlimited: true,
              days: '' as unknown as undefined, // update requires value
            });

            void triggerSuspendedDaysValidation();
          },
        };

        return (
          <Flex
            key={`custom-request-type--${name}-${field.customRequestTypeId}`}
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Flex sx={{ flexDirection: 'column' }}>
              <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>
                {t({
                  id: name,
                })}
              </Text>
              <Text sx={{ fontSize: 1, color: 'team.nestedRequestLimits', lineHeight: 1 }}>
                {t({
                  id: abbreviation,
                })}
              </Text>
            </Flex>

            <Flex sx={{ alignItems: 'center', flexShrink: 0 }}>
              <Checkbox
                sx={{ mx: 4 }}
                key={`customRequestsLimits.${nestIndex}.limits.${index}.unlimited`}
                label={
                  <Text sx={{ flexShrink: 0, fontSize: 1, lineHeight: 1, display: 'block', ml: -2 }}>
                    <Trans id="team.limits.unlimited">Unlimited</Trans>
                  </Text>
                }
                defaultChecked={!!field.unlimited}
                size="sm"
                {...unlimitedRegisterProps}
              />
              <Flex sx={{ width: '80px', flexShrink: 0 }}>
                <MaskedTextInput
                  key={`customRequestsLimits.${nestIndex}.limits.${index}.days.${!!field.unlimited}`}
                  disabled={!!field.unlimited}
                  mask={/^(?:36[0-6]|3[0-5][0-9]|[12][0-9][0-9]|[1-9][0-9]|[0-9])$/}
                  size="sm"
                  id={`customRequestsLimits.${nestIndex}.limits.${index}.days`}
                  placeholder=""
                  variant="rounded"
                  error={!!errors.customRequestsLimits?.[nestIndex]?.limits?.[index]?.days}
                  errorMessage={errors.customRequestsLimits?.[nestIndex]?.limits?.[index]?.days?.message}
                  defaultValue={field.days}
                  apendWith={t({ id: 'team.req_limits.append_days', message: 'days' })}
                  {...daysRegisterProps}
                />
              </Flex>
              <Text as="span" sx={{ px: 2 }}>
                /
              </Text>
              <Flex sx={{ width: '95px', flexShrink: 0 }}>
                <Select
                  disabled={!!field.unlimited}
                  ignoreHiddenInputFocus // ugly fix; RHF is forcing focus
                  size="sm"
                  id={`customRequestsLimits.${nestIndex}.limits.${index}.period`}
                  placeholder={t({ id: 'team.req_limits.period', message: 'Period' })}
                  options={periodSelectOptions}
                  defaultValue={`${field.period}`}
                  {...periodRegisterProps}
                />
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export const NestedRequestLimitsFieldArray = memo(NestedRequestLimits);
