import React, { useCallback, useEffect, useRef } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { InputOption, Select, SelectProps } from '../Select';
import { mergeRefs } from 'utils/mergeRefs';
import { timeZoneListAtom } from 'state/signUp';
import { fetchTimeZoneListAction } from 'api/actions/data/dataActions';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { OPTION_LIST_GAP } from '../constants';

type Props = Omit<SelectProps, 'options'>;

export const TimeZoneSelect = React.forwardRef<HTMLInputElement, Props>(
  ({ ...props }: Props, ref): React.ReactElement => {
    const [timeZoneList, setTimeZoneList] = useRecoilState(timeZoneListAtom);

    const selectRef = useRef<HTMLInputElement | null>(null);

    const { query } = useClient();

    useEffect(() => {
      const getTimeZoneList = async () => {
        const { error, payload } = await query(fetchTimeZoneListAction());

        if (!error && payload) {
          setTimeZoneList(payload);
        }
      };

      if (!timeZoneList) void getTimeZoneList();
    }, [query, setTimeZoneList, timeZoneList]);

    const optionContentRenderer = useCallback(({ label }: InputOption) => {
      const utcValueRegex = /\((.*?)\)/g; // should match '(UTC +06:00)'

      const matches = label.match(utcValueRegex);
      const firstLine = matches && matches.length ? matches[0] : undefined;
      const secondLine = label.replace(firstLine || '', '');
      return (
        <Flex sx={{ flexDirection: 'column' }} title={label}>
          {firstLine ? (
            <>
              {firstLine}
              <br />
            </>
          ) : null}
          <TextEllipsis>{secondLine}</TextEllipsis>
        </Flex>
      );
    }, []);

    const optionPropsGenerator = useCallback(
      (option: InputOption) => ({
        contentRenderer: () => optionContentRenderer(option),
      }),
      [optionContentRenderer],
    );

    return (
      <Select
        ref={mergeRefs([ref, selectRef])}
        optionPropsGenerator={optionPropsGenerator}
        {...props}
        options={timeZoneList}
        optionsSearchFactoryConfig={{
          wordProxies: [
            [['usa'], ['united', 'states']],
            [['polska', 'polsk', 'pols'], ['poland']],
            [['warszawa', 'warszaw', 'warsza', 'warsz'], ['warsaw']],
          ],
        }}
        virtualizeOptions
        showSelectedOptionsFirst
        fixedSizeListProps={{ itemSize: 52 + OPTION_LIST_GAP }}
      />
    );
  },
);
