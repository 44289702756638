import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _, { isNaN } from 'lodash';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { MemoizedDualCalendar } from 'components/ui/DualCalendar/DualCalendar';
import { Switch } from 'components/ui/Switch';
import { REQUEST_DEFAULT_END_TIME, REQUEST_DEFAULT_START_TIME } from 'constants/requests';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { minCreateDateUnixSelector } from 'state/userSession';
import { dateTime } from 'utils/dateTime';
import { dualCalendarRegisterOptionsFactory } from 'utils/dualCalendarRegisterOptionsFactory';
import { useAddRequest } from '../../../../../hooks/useAddRequest';
import { useTimeOffFormState } from '../hooks/useTimeOffFormState';

type Props = {
  setForWholeDayChangedToFalse: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Calendar = ({ setForWholeDayChangedToFalse }: Props): React.ReactElement => {
  useLingui();
  const minCreateDateUnix = useRecoilValue(minCreateDateUnixSelector);

  const {
    requestLimits: { resetRequestLimits },
  } = useAddRequest();

  const { isDurationType, isForDayType, isOvertimeFreeDayType } = useTimeOffFormState();

  const {
    register,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue,
  } = useFormContext<{ dateTimeDetails: { dates: number[] }; isDateBound: boolean; calendarInternalError: string }>();

  const calendarWatch = watch('dateTimeDetails.dates');
  const forWholeDayWatch = watch('isDateBound');

  const calendarWatchRef = useCallbackRef(calendarWatch);
  const forWholeDayWatchRef = useCallbackRef(forWholeDayWatch);

  const setCalendarErrorCallback = useCallback(() => {
    setError('calendarInternalError', {
      type: 'required',
    });
  }, [setError]);

  const clearCalendarErrorCallback = useCallback(() => {
    clearErrors('calendarInternalError');
  }, [clearErrors]);

  const handleDateBoundSwitchOnClick = useCallback(() => {
    if (calendarWatchRef.current && _.isArray(calendarWatchRef.current)) {
      if (forWholeDayWatchRef.current) {
        setForWholeDayChangedToFalse(true);
      } else {
        setValue('dateTimeDetails.dates', calendarWatchRef.current);
      }
    }
    clearErrors('dateTimeDetails.dates');
  }, [calendarWatchRef, clearErrors, forWholeDayWatchRef, setForWholeDayChangedToFalse, setValue]);

  return (
    <MemoizedDualCalendar
      {...register(
        'dateTimeDetails.dates',
        dualCalendarRegisterOptionsFactory({
          isRange: true,
          customDatesCheck: (startDateUnix, endDateUnix) => {
            if (!startDateUnix || isNaN(startDateUnix)) return undefined;
            if (isDurationType || isForDayType) return [startDateUnix, startDateUnix];
            if (!endDateUnix || isNaN(endDateUnix)) return undefined;

            return [startDateUnix, endDateUnix];
          },
        }),
      )}
      range={!isDurationType && !isForDayType && forWholeDayWatch}
      showStartTime={!forWholeDayWatch}
      showEndTime={!forWholeDayWatch}
      error={!!errors?.dateTimeDetails?.dates}
      errorMessage={errors.dateTimeDetails?.dates?.message}
      onValidError={setCalendarErrorCallback}
      onClearError={clearCalendarErrorCallback}
      breakthroughDay
      resetDays
      onResetDays={resetRequestLimits}
      {...(minCreateDateUnix && { minDate: dateTime(minCreateDateUnix) })}
      defaultTimesOnDayPick={[REQUEST_DEFAULT_START_TIME, REQUEST_DEFAULT_END_TIME]}
    >
      {isDurationType || isForDayType || isOvertimeFreeDayType ? null : (
        <Switch
          {...register('isDateBound')}
          label={t({
            id: 'requests.add_request.whole_day',
            message: 'For a whole day',
          })}
          additionalInfo={
            <Text>
              <Trans id="requests.add_request.whole_day_description">
                Based on users schedule or a default workday duration
              </Trans>
            </Text>
          }
          size="sm"
          bold
          onClick={handleDateBoundSwitchOnClick}
        />
      )}
    </MemoizedDualCalendar>
  );
};
