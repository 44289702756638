import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Modal } from 'components/Modal/output/Modal';
import { ADD_REQUEST_MODAL_WIDTH } from 'constants/requests';
import { PATH_REL } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { AddRequestModal } from '../AddRequest/AddRequestModal';
import { DeleteRequestModal } from '../DeleteRequest';
import { RejectRequestModal } from '../RejectRequestModal';

type Props = {
  onSubmitRejectDeleteAction: () => Promise<void>;
};

export const RequestDetailsRoutes = ({ onSubmitRejectDeleteAction }: Props): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <Routes>
      <Route
        path={PATH_REL.REJECT_REQUESTS_MODAL[language]}
        element={
          <Modal size="xs">
            <RejectRequestModal onSubmitCallback={onSubmitRejectDeleteAction} />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.DELETE_REQUESTS_MODAL[language]}
        element={
          <Modal size="xs">
            <DeleteRequestModal onSubmitCallback={onSubmitRejectDeleteAction} />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.REQUEST_MODIFICATION_MODAL[language]}
        element={
          <Modal fullHeight sx={{ width: ADD_REQUEST_MODAL_WIDTH }}>
            <AddRequestModal />
          </Modal>
        }
      />
    </Routes>
  );
};
