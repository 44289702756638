import { lazy, useCallback } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Divider } from 'components/Divider/Divider';
import { CALENDAR_TYPE, CLOCK_LOG_TYPE, DATE_RANGE_PARAMS, PATH, REQUESTS_TYPE } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { renderSameElementRoutes } from 'routing/utils/renderSameElementRoutes';
import { SEARCH_FILTER_TYPE, dateRangeRequestsUsageOverviewFilterAtom } from 'state/filters';
import { languageSelector } from 'state/recoilState';

import { AsideFiltersLayout } from './AsideFiltersLayout';
import { FilterSearchInput } from './FilterSearchInput';
import { RequestTypeFilterObserver } from './RequestTypeFilterObserver';
import {
  HideEmptyColumnsFilterCheckbox,
  HideTypesWithoutLimitsFilterCheckbox,
  HideUsersWithoutDataFilterCheckbox,
  ShowOnlyOfflineEvents,
} from './filters';
import { DateRangeFilters, FilterGroupNames } from './types';

const CalendarFilterGroup = lazy(() =>
  import('./filters').then(({ CalendarFilterGroup: Component }) => ({
    default: Component,
  })),
);
const HideAcceptedRequestsFilterCheckbox = lazy(() =>
  import('./filters').then(({ HideAcceptedRequestsFilterCheckbox: Component }) => ({
    default: Component,
  })),
);
const HideEmptyFilterCheckbox = lazy(() =>
  import('./filters').then(({ HideEmptyFilterCheckbox: Component }) => ({
    default: Component,
  })),
);
const HidePublishedFilterCheckbox = lazy(() =>
  import('./filters').then(({ HidePublishedFilterCheckbox: Component }) => ({
    default: Component,
  })),
);
const ReportsAttendanceStateFilterGroup = lazy(() =>
  import('./filters').then(({ ReportsAttendanceStateFilterGroup: Component }) => ({
    default: Component,
  })),
);
const RequestStateFilterGroup = lazy(() =>
  import('./filters').then(({ RequestStateFilterGroup: Component }) => ({
    default: Component,
  })),
);
const RequestTypesCalendarFilterGroup = lazy(() =>
  import('./filters').then(({ RequestTypesCalendarFilterGroup: Component }) => ({
    default: Component,
  })),
);
const RequestTypesFilterGroup = lazy(() =>
  import('./filters').then(({ RequestTypesFilterGroup: Component }) => ({
    default: Component,
  })),
);
const RolesFilterGroup = lazy(() =>
  import('./filters').then(({ RolesFilterGroup: Component }) => ({
    default: Component,
  })),
);
const TagsFilterGroup = lazy(() =>
  import('./filters').then(({ TagsFilterGroup: Component }) => ({
    default: Component,
  })),
);
const TeammatesStateFilterGroup = lazy(() =>
  import('./filters').then(({ TeammatesStateFilterGroup: Component }) => ({
    default: Component,
  })),
);
const TimeClocksStateFilterGroup = lazy(() =>
  import('./filters').then(({ TimeClocksStateFilterGroup: Component }) => ({
    default: Component,
  })),
);
const TimeClocksSystemFilterGroup = lazy(() =>
  import('./filters').then(({ TimeClocksSystemFilterGroup: Component }) => ({
    default: Component,
  })),
);
const WorkPositionFilterGroup = lazy(() =>
  import('./filters').then(({ WorkPositionFilterGroup: Component }) => ({
    default: Component,
  })),
);
const WorkPositionTeamFilterGroup = lazy(() =>
  import('./filters').then(({ WorkPositionTeamFilterGroup: Component }) => ({
    default: Component,
  })),
);
const ClockLogFilterSearchInput = lazy(() =>
  import('./filters').then(({ ClockLogFilterSearchInput: Component }) => ({ default: Component })),
);
const WorkStatusesFilterGroup = lazy(() =>
  import('./filters').then(({ WorkStatusesFilterGroup: Component }) => ({
    default: Component,
  })),
);
const WorkTypeFilterGroup = lazy(() =>
  import('./filters').then(({ WorkTypeFilterGroup: Component }) => ({
    default: Component,
  })),
);
const SendViaFilterGroup = lazy(() =>
  import('./filters').then(({ SendViaFilterGroup: Component }) => ({
    default: Component,
  })),
);
const WorkStatusesStateFilterGroup = lazy(() =>
  import('./filters').then(({ WorkStatusesStateFilterGroup: Component }) => ({
    default: Component,
  })),
);
const LocationsFilterGroup = lazy(() =>
  import('./filters').then(({ LocationsFilterGroup: Component }) => ({
    default: Component,
  })),
);
const DateRangeFilter = lazy(() =>
  import('./DateRange/DateRangeFilter').then(({ DateRangeFilter: Component }) => ({
    default: Component,
  })),
);
const HideUsersWithoutPhotosFilterCheckbox = lazy(() =>
  import('./filters').then(({ HideUsersWithoutPhotosFilterCheckbox: Component }) => ({
    default: Component,
  })),
);
const ModalRoutes = lazy(() =>
  import('./ModalRoutes').then(({ ModalRoutes: Component }) => ({
    default: Component,
  })),
);

export const AsideFilters = () => {
  const language = useRecoilValue(languageSelector);
  const { modulesManagement, modules } = useAppPermissions();

  const renderCommonEmployeeFilterGroups = useCallback(
    (openByDefault?: boolean) => (
      <>
        <TeammatesStateFilterGroup openByDefault={openByDefault} />
        <RolesFilterGroup openByDefault={openByDefault} />
        <TagsFilterGroup openByDefault={openByDefault} />
      </>
    ),
    [],
  );

  const renderCalendarRequestsFilterGroups = useCallback(
    () =>
      modules.Requests ? (
        <>
          <RequestTypesCalendarFilterGroup />
          <HideAcceptedRequestsFilterCheckbox />
          <RequestTypeFilterObserver />
        </>
      ) : null,
    [modules.Requests],
  );

  const renderCalendarSchedulesFilterGroups = useCallback(
    () =>
      modules.Schedule ? (
        <>
          <CalendarFilterGroup />
          {modulesManagement.Schedules && <HidePublishedFilterCheckbox />}
          <WorkPositionFilterGroup />
        </>
      ) : null,
    [modules.Schedule, modulesManagement.Schedules],
  );

  return (
    <>
      <Routes>
        <Route element={<AsideFiltersLayout />}>
          <Route
            path={`${PATH.TEAM[language]}/*`}
            element={
              <>
                <FilterSearchInput key={SEARCH_FILTER_TYPE.TEAMMATES} type={SEARCH_FILTER_TYPE.TEAMMATES} />
                {renderCommonEmployeeFilterGroups()}
                <WorkPositionTeamFilterGroup />
                <ModalRoutes />
              </>
            }
          />
          <Route
            path={`${PATH.REQUESTS[language]}/*`}
            element={
              <>
                <FilterSearchInput key={SEARCH_FILTER_TYPE.TEAMMATES} type={SEARCH_FILTER_TYPE.TEAMMATES} />
                <Outlet />
                <Divider borderColor="secondary" />
                {renderCommonEmployeeFilterGroups(false)}
              </>
            }
          >
            {renderSameElementRoutes(
              {
                element: (
                  <>
                    <DateRangeFilter />
                    <RequestStateFilterGroup />
                    <RequestTypesFilterGroup />
                    <ModalRoutes />
                  </>
                ),
              },
              [
                `${REQUESTS_TYPE.GROUPED[language]}${DATE_RANGE_PARAMS}/*`,
                `${REQUESTS_TYPE.UNGROUPED[language]}${DATE_RANGE_PARAMS}/*`,
              ],
            )}
            <Route
              path={`${REQUESTS_TYPE.PENDING[language]}/*`}
              element={
                <>
                  <RequestTypesFilterGroup />
                  <ModalRoutes />
                </>
              }
            />
            <Route
              path={`${REQUESTS_TYPE.USAGE_OVERVIEW[language]}${DATE_RANGE_PARAMS}/*`}
              element={
                <>
                  <DateRangeFilter
                    name={FilterGroupNames.REQUESTS_USAGE_OVERVIEW_DATE_RANGE}
                    dateRangeAtom={dateRangeRequestsUsageOverviewFilterAtom}
                    filters={[DateRangeFilters.YEAR]}
                  />
                  <RequestTypesFilterGroup />
                  <HideEmptyColumnsFilterCheckbox />
                  <HideTypesWithoutLimitsFilterCheckbox />
                  <HideUsersWithoutDataFilterCheckbox />
                  <ModalRoutes />
                </>
              }
            />
          </Route>

          <Route
            path={`${PATH.CALENDAR[language]}/*`}
            element={
              <>
                <FilterSearchInput key={SEARCH_FILTER_TYPE.TEAMMATES} type={SEARCH_FILTER_TYPE.TEAMMATES} />
                <DateRangeFilter />
                <Outlet />
                <Divider borderColor="secondary" />
                {renderCommonEmployeeFilterGroups(false)}
              </>
            }
          >
            <Route
              path={`${CALENDAR_TYPE.ALL[language]}${DATE_RANGE_PARAMS}/*`}
              element={
                <>
                  {renderCalendarSchedulesFilterGroups()}
                  {renderCalendarRequestsFilterGroups()}
                  <HideEmptyFilterCheckbox />
                  <ModalRoutes />
                </>
              }
            />
            <Route
              path={`${CALENDAR_TYPE.SCHEDULES[language]}${DATE_RANGE_PARAMS}/*`}
              element={
                <>
                  {renderCalendarSchedulesFilterGroups()}
                  <HideEmptyFilterCheckbox />
                  <ModalRoutes />
                </>
              }
            />
            <Route
              path={`${CALENDAR_TYPE.REQUESTS[language]}${DATE_RANGE_PARAMS}/*`}
              element={
                <>
                  {renderCalendarRequestsFilterGroups()}
                  <HideEmptyFilterCheckbox />
                  <ModalRoutes />
                </>
              }
            />
            <Route path={`${CALENDAR_TYPE.AVAILABILITY[language]}${DATE_RANGE_PARAMS}/*`} element={<ModalRoutes />} />
          </Route>

          {renderSameElementRoutes(
            {
              element: (
                <>
                  <FilterSearchInput key={SEARCH_FILTER_TYPE.TEAMMATES} type={SEARCH_FILTER_TYPE.TEAMMATES} />
                  <DateRangeFilter />
                  <ReportsAttendanceStateFilterGroup />
                  <HideEmptyFilterCheckbox />
                  <Divider borderColor="secondary" />
                  {renderCommonEmployeeFilterGroups(false)}
                  <ModalRoutes />
                </>
              ),
            },
            [
              `${PATH.REPORTS__ATTENDANCE_LIST__START_DATE_UNIX__END_DATE_UNIX[language]}/*`,
              `${PATH.REPORTS__TIMESHEETS__START_DATE_UNIX__END_DATE_UNIX[language]}/*`,
            ],
          )}

          <Route
            path={`${PATH.TIME_TRACKING__CLOCK_LOG[language]}/*`}
            element={
              <>
                <ClockLogFilterSearchInput />
                <DateRangeFilter />
                <WorkTypeFilterGroup />
                <WorkStatusesFilterGroup />
                <SendViaFilterGroup />
                <ShowOnlyOfflineEvents />
                <LocationsFilterGroup />
                <Outlet />
                <Divider borderColor="secondary" />
                {renderCommonEmployeeFilterGroups(false)}
                <ModalRoutes />
              </>
            }
          >
            <Route
              path={`${CLOCK_LOG_TYPE.PHOTO_LOG[language]}${DATE_RANGE_PARAMS}/*`}
              element={
                <>
                  <ModalRoutes />
                  <HideUsersWithoutPhotosFilterCheckbox />
                </>
              }
            />
            <Route path={`${CLOCK_LOG_TYPE.ALL[language]}${DATE_RANGE_PARAMS}/*`} element={<ModalRoutes />} />
            <Route path={`${CLOCK_LOG_TYPE.MODIFIED[language]}${DATE_RANGE_PARAMS}/*`} element={<ModalRoutes />} />
            <Route path={`${CLOCK_LOG_TYPE.PENDING[language]}${DATE_RANGE_PARAMS}/*`} element={<ModalRoutes />} />
            <Route
              path={`${CLOCK_LOG_TYPE.AI_ANTI_SPOOFING[language]}${DATE_RANGE_PARAMS}/*`}
              element={<ModalRoutes />}
            />
            <Route path={`${CLOCK_LOG_TYPE.AI_REJECTED[language]}${DATE_RANGE_PARAMS}/*`} element={<ModalRoutes />} />
            <Route path={`${CLOCK_LOG_TYPE.DELETED[language]}${DATE_RANGE_PARAMS}/*`} element={<ModalRoutes />} />
          </Route>

          <Route
            path={`${PATH.TIME_TRACKING__WORK_STATUSES[language]}/*`}
            element={
              <>
                <FilterSearchInput key={SEARCH_FILTER_TYPE.WORK_STATUSES} type={SEARCH_FILTER_TYPE.WORK_STATUSES} />
                <WorkStatusesStateFilterGroup />
              </>
            }
          />
          <Route
            path={`${PATH.TIME_TRACKING__TIME_CLOCKS[language]}/*`}
            element={
              <>
                <FilterSearchInput key={SEARCH_FILTER_TYPE.TIME_CLOCKS} type={SEARCH_FILTER_TYPE.TIME_CLOCKS} />
                <TimeClocksStateFilterGroup />
                <TimeClocksSystemFilterGroup />
                <ModalRoutes />
              </>
            }
          />
          <Route
            path={`${PATH.TIME_TRACKING__LOCATIONS[language]}/*`}
            element={<FilterSearchInput key={SEARCH_FILTER_TYPE.LOCATIONS} type={SEARCH_FILTER_TYPE.LOCATIONS} />}
          />
        </Route>
      </Routes>

      {/* PAGES WITH DAY_DETAILS */}
      <Routes>
        {renderSameElementRoutes(
          {
            element: <ModalRoutes />,
          },
          [
            `${PATH.PAGE__USAGE_OVERVIEW__START_DATE_UNIX__END_DATE_UNIX__DETAILS__USER_ID__REQUEST_TYPE__TYPE_ID[language]}/*`,
            `${PATH.PAGE__TYPE__START_DATE_UNIX__END_DATE_UNIX__DETAILS__USER_ID__DATE_UNIX[language]}/*`,
          ],
        )}
      </Routes>
    </>
  );
};
