import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import { ModalContext } from '../components/ModalContext';
import { ModalCustomHandleClose, ModalResolver } from '../types';

type Options = {
  wrapperSx?: ThemeUIStyleObject;
  fullHeight?: boolean;
  closeOnBackdrop?: boolean;
  onHandleClose?: ModalCustomHandleClose;
  onHandleMinimize?: (minimizeModal: () => void) => void | Promise<void>;
};
/**
 * Lets you interact with the context of a modal this hook is being invoked in.
 *
 * @param {Object | undefined} options
 * @param {ThemeUIStyleObject | undefined} options.wrapperSx Styles that will be set on the modal wrapper.
 * @param {boolean | undefined} options.fullHeight If true the modal will take max height.
 * @param {boolean | undefined} options.closeOnBackdrop If true, clicking on the backdrop will close modal.
 * @param {((closeModal: () => void) => void) | undefined} options.onHandleClose
 * @param {((minimizeModal: () => Promise<void>) => void) | undefined} options.onHandleMinimize
 *
 * @return {Object} Utils.
 * @return {string} Utils.baseRoute Route from witch the modal was called.
 * @return {boolean} Utils.replaceMode If true modal will use replace instead of push.
 * @return {Record<string, unknown>} Utils.state Modal state with wich modal was opened. @see: openModal in Modal.tsx
 * @return {((value: T | PromiseLike<T>) => void) | undefined} Utils.resolver Resolves the openModal promise. @see: openModal in Modal.tsx
 * @return {number} Utils.instanceNumber Modal instance number created during opening. Unique for every opened modal.
 * @return {() => void} Utils.handleMinimize minimize modal method. @see showMinimize in Modal.tsx @see MinimizedModalsProvider.tsx
 * @return {boolean | undefined} Utils.showMinimize Passed property to Modal.tsx @see showMinimize in Modal.tsx
 */
export function useModal<ResolverValue = null, State = Record<string, unknown>>(options?: Options) {
  const modalContext = useContext(ModalContext);

  if (modalContext === undefined) {
    throw new Error('useModal must be used within a Modal component');
  }
  const {
    handleClose,
    baseRoute,
    replaceMode,
    setWrapperSx,
    setIsFullHeight,
    setCustomHandleClose,
    setCloseOnBackdrop,
    setCustomHandleMinimize,
    state,
    resolver,
    instanceNumber,
    handleMinimize,
    showMinimize,
  } = modalContext;

  useEffect(() => {
    if (options) {
      const { closeOnBackdrop, fullHeight, onHandleClose, wrapperSx, onHandleMinimize } = options;

      setCustomHandleClose(() => onHandleClose);
      setWrapperSx(wrapperSx);
      setIsFullHeight(fullHeight);
      setCustomHandleMinimize(() => onHandleMinimize);
      if (_.isBoolean(closeOnBackdrop)) {
        setCloseOnBackdrop(closeOnBackdrop);
      }
    }
  }, [setWrapperSx, setIsFullHeight, setCustomHandleClose, setCloseOnBackdrop, options, setCustomHandleMinimize]);

  return {
    handleClose,
    baseRoute,
    replaceMode,
    state: (state || {}) as Partial<State>,
    resolver: resolver as unknown as ModalResolver<ResolverValue | null> | undefined,
    instanceNumber,
    handleMinimize,
    showMinimize,
  };
}
