import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { MemoizedDualCalendar } from 'components/ui/DualCalendar/DualCalendar';
import { minCreateDateUnixSelector } from 'state/userSession';
import { dateTime } from 'utils/dateTime';
import { dualCalendarRegisterOptionsFactory } from 'utils/dualCalendarRegisterOptionsFactory';
import { AddRequestExtendedFormContext } from '../../../../../../../types';

export const Calendar = (): React.ReactElement => {
  const { register } = useFormContext<AddRequestExtendedFormContext>();
  const minCreateDateUnix = useRecoilValue(minCreateDateUnixSelector);

  return (
    <MemoizedDualCalendar
      {...register('dateTimeDetails.dates', dualCalendarRegisterOptionsFactory({ isRange: true }))}
      range
      resetDays
      {...(minCreateDateUnix && { minDate: dateTime(minCreateDateUnix) })}
    />
  );
};
