import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { GetScheduleToSwap } from 'api/actions/requests/requestsActions.types';
import { DetailedScheduleElement } from 'pages/Calendar/output/DetailedScheduleElement';
import { PersonCell } from 'pages/Reports/output/PersonCell';
import { BasicEmployee } from 'state/employees';
import { workPositionDictionarySelector } from 'state/organizationSession';

type Props = {
  schedule: GetScheduleToSwap[];
  employee: BasicEmployee;
};

export const RequestSchedulesRenderer = ({ schedule, employee }: Props): React.ReactElement | null => {
  useLingui();
  const workPositionTypes = useRecoilValue(workPositionDictionarySelector);

  const { name, role, tags, avatarUrl } = employee;

  if (!role) return null;

  return (
    <Flex sx={{ flex: '1 0', flexDirection: 'column', gap: 2, p: 2 }}>
      <PersonCell name={name} role={role} tags={tags || []} avatarUrl={avatarUrl} avatarSize={21} />
      {!schedule.length ? (
        <Text sx={{ color: 'requests.noSchedule', fontSize: 2, fontWeight: 'bold' }}>
          <Trans id="request.no_schedules">No schedules</Trans>
        </Text>
      ) : (
        schedule.map(({ id, isPublished, isDeleted, workPositionId, note, details }) => {
          const {
            isOvertimeSchedule,
            color,
            type,
            addAutomaticBreak,
            automaticBreakDurationUnix,
            countOvertimeBeforeWork,
            countOvertimeAfterWork,
            endDateTimeUnix,
            startDateTimeUnix,
            workTimeUnix,
          } = details;

          return (
            <DetailedScheduleElement
              key={id}
              scheduleId={id}
              isOvertimeSchedule={isOvertimeSchedule}
              isPending={!isPublished}
              scheduleColor={color}
              type={type}
              withAutoBreak={addAutomaticBreak}
              autoBreakDuration={automaticBreakDurationUnix}
              countOvertimeAfterWork={countOvertimeAfterWork}
              countOvertimeBeforeWork={countOvertimeBeforeWork}
              endDateTimeUnix={endDateTimeUnix}
              startDateTimeUnix={startDateTimeUnix}
              workTimeUnix={workTimeUnix}
              isDeleted={isDeleted}
              workPosition={workPositionId && workPositionTypes ? workPositionTypes[workPositionId].name : undefined}
              note={note}
              hideButtons
              hoverable={false}
              sx={{
                cursor: 'auto',
              }}
            />
          );
        })
      )}
    </Flex>
  );
};
