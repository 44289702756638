import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Flex, Text } from 'theme-ui';

import { DeleteHolidayActionProps } from 'api/actions/holidays/holidaysActions.types';
import { Checkbox } from 'components/ui/Checkbox';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

type Props = DeleteHolidayActionProps & {
  onSubmit: (data: DeleteHolidayActionProps) => Promise<void>;
  setLoading: (loading: boolean) => void;
  setDisabled: (loading: boolean) => void;
};

export const DeleteHolidayForm = React.forwardRef<HTMLFormElement, Props>(
  ({ id, onSubmit, setLoading, setDisabled }: Props, ref): React.ReactElement => {
    useLingui();
    const { handleSubmit } = useForm<DeleteHolidayActionProps>({ defaultValues: { id } });

    const checkBoxRef = useRef<HTMLInputElement | null>(null);

    const handleSubmitCallback = useCallback(
      (data: DeleteHolidayActionProps) => {
        void onSubmit(data);
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    const handleCheckboxOnChange = () => {
      if (checkBoxRef.current) {
        setDisabled(!checkBoxRef.current.checked);
      }
    };

    return (
      <form ref={ref} onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))}>
        <Text as="p" sx={{ fontSize: 2 }}>
          <Trans id="settings.holidays.delete_message">
            You're about to permanently delete this holiday.{' '}
            <Text as="span" sx={{ fontWeight: 'bold' }}>
              This action cannot be reversed.
            </Text>
          </Trans>
        </Text>
        <Flex mt={4}>
          <Checkbox
            ref={checkBoxRef}
            name="confirmation"
            size="sm"
            label={t({ id: 'settings.holidays.delete_confirmation', message: "I understand, let's proceed" })}
            onChange={handleCheckboxOnChange}
          />
        </Flex>
      </form>
    );
  },
);
