import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { IS_MOBILE } from 'constants/common';
import { windowSizeAtom } from 'state/recoilState';
import { MEDIA_BREAKPOINTS, THEME_BREAKPOINTS } from 'styles/theme/base';

export type windowSizeProps = {
  width?: number;
  height?: number;
  aspectRatio?: number;
  isLandscape?: boolean;
  isMobile?: boolean;
  breakpoint?: MEDIA_BREAKPOINTS;
};

type ReturnProps = windowSizeProps;

export const useWindowSize = ({ useRecoil = false }: { useRecoil?: boolean }): ReturnProps => {
  const [windowSize, setWindowSize] = useState<windowSizeProps>({
    width: undefined,
    height: undefined,
    aspectRatio: undefined,
    isLandscape: undefined,
    isMobile: undefined,
    breakpoint: undefined,
  });

  const setWindowSizeState = useSetRecoilState(windowSizeAtom);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isLandscape = height <= width;
      const aspectRatio = width / height;

      const isMobile = IS_MOBILE;

      const breakpoint =
        _.find(THEME_BREAKPOINTS, (point, index, points) => {
          if (width && width >= point && (width < points[index + 1] || index + 1 === points.length)) {
            return true;
          }

          return false;
        }) || (0 as unknown as MEDIA_BREAKPOINTS);

      if (useRecoil) {
        setWindowSizeState({
          width,
          height,
          isLandscape,
          aspectRatio,
          isMobile,
          breakpoint,
        });
      }

      setWindowSize({
        width,
        height,
        isLandscape,
        aspectRatio,
        isMobile,
        breakpoint,
      });
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWindowSizeState, useRecoil]);

  return windowSize;
};
