import { IS_TOUCH_DEVICE } from 'constants/common';
import { makeTheme } from 'styles/makeTheme';
import { Z_INDEX_BASE } from '../base';

const SPACING = {
  padding: {
    x: 4,
    y: 3,
  },
};

const Z_INDEX_ASIDE_CONTAINER = Z_INDEX_BASE - 1;

export const authorizedStyles = makeTheme({
  layouts: {
    authorized: {
      aside: {
        collapseButtonContainer: {
          position: 'absolute',
          right: 0,
          top: [16, null, null, 64],
          zIndex: 10,
          transform: ['translate(75%, 0)', null, null, 'translate(50%, 0)'],
          opacity: [1, null, null, IS_TOUCH_DEVICE ? null : 0],
          transition: 'opacity 0.5s ease-in-out',
          '&[data-collapsed="true"]': {
            opacity: 1,
          },
        },
        collapseButton: {
          p: 1,
          gap: '0.125rem',
          boxShadow: 'collapseButton',
          fontWeight: '400',
        },
        container: {
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'stretch',
          // bg: 'authorized.bg.aside',
          maxWidth: '260px',
          width: '100%',
          height: '100%',
          zIndex: Z_INDEX_ASIDE_CONTAINER,
        },
        insideContainer: {
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'stretch',
          bg: 'authorized.bg.aside',
          maxWidth: '260px',
          minWidth: '260px',
          width: '100%',
          height: '100%',
          zIndex: 1,
          boxShadow: 'insideContainer',
          '&:hover': {
            '> .collapseButton': {
              opacity: 1,
            },
          },
        },
        filters: {
          bg: 'authorized.bg.aside',
          flexDirection: 'column',
          py: 4,
          px: 3,
          gap: 3,
          alignItems: 'stretch',
          userSelect: 'none',
          overflowY: 'auto',
          maxWidth: '260px',
          width: '100%',
        },
        details: {
          container: {
            flexDirection: 'column',
            bg: ['backgrounds.authorizedApp', null, null, 'authorized.bg.aside'],
            ml: [0, null, null, 4],
            alignItems: 'stretch',
            overflowY: 'auto',
            maxWidth: ['auto', null, null, '320px', '364px'],
            minWidth: ['auto', null, null, '320px', '364px'],
            width: '100%',
            flex: '1 0',
            height: ['50%', null, null, 'auto'],
          },
          header: {
            bg: ['backgrounds.authorizedApp', null, null, 'authorized.bg.aside'],
            position: 'sticky',
            top: 0,
            alignItems: 'center',
            p: SPACING.padding.x,
            gap: 2,
            // because of tags stack zindex should be huge to make sure that header is on the top of tagStack and at the same time it should be lower than index of aside filters to not cause header overlapping the filters in mobile view
            zIndex: Z_INDEX_ASIDE_CONTAINER - 1,
          },
          headerMenu: {
            flexGrow: 1,
            justifyContent: 'flex-end',
            gap: 2,
            alignItems: 'center',
          },
          content: {
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            flexGrow: 1,
            gap: 5,
            px: SPACING.padding.x,
            pb: '3rem',
          },
          contentContainer: {
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            gap: 2,
          },
        },
      },
    },
  },
});
