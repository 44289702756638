import { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ExportDataType } from 'api/actions/export/exportActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { ListNames } from 'components/StickyList/types';
import { BASE_ROUTES, PATH_REL, TO } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { DownloadPrintModal } from 'pages/Reports/output/DownloadPrintModal';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { languageSelector } from 'state/recoilState';

import { AddTeammateModal } from './components/modals/AddTeammate/AddTeammate';
import { BulkActionsModal } from './components/modals/BulkAction/BulkActions';
import { DeactivateTeammatesModal } from './components/modals/DeactivateTeammates';
import { DeleteTeammatesModal } from './components/modals/DeleteTeammates/DeleteTeammates';
import { EditTeammateModalWithDefaultModalWrapper } from './components/modals/EditTeammate/EditTeammate';
import { ImportModal } from './components/modals/Import/Import';
import { PrintQrCardsModal } from './components/modals/PrintQrCards';
import { ReInviteTeammatesModal } from './components/modals/ReInviteTeammates';
import { ViewSettingsModal } from './components/modals/ViewSettings';

export const ModalRoutes: FC = () => {
  const language = useRecoilValue(languageSelector);
  const { isVisible: isOnboardingVisible } = useInitialOnboarding();
  const { isInitialized, modulesManagement } = useAppPermissions();

  return (
    <Routes>
      <Route
        element={
          isInitialized && !modulesManagement.Employees ? (
            <Navigate to={BASE_ROUTES.PAGE_NOT_FOUND[language]} />
          ) : (
            <Outlet />
          )
        }
      >
        {/* TEAMMATES */}
        <Route
          path={PATH_REL.ADD_TEAMMATE_MODAL[language]}
          element={
            <Modal showMinimize={!isOnboardingVisible} size="sm">
              <AddTeammateModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.EDIT_TEAMMATE_MODAL__ID[language]}
          element={<EditTeammateModalWithDefaultModalWrapper path={PATH_REL.EDIT_TEAMMATE_MODAL__ID[language]} />}
        />

        <Route
          path={PATH_REL.DELETE_TEAMMATES_MODAL[language]}
          element={
            <Modal size="xs">
              <DeleteTeammatesModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.DEACTIVATE_TEAMMATES_MODAL[language]}
          element={
            <Modal size="xs">
              <DeactivateTeammatesModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.INVITE_TEAMMATES_MODAL[language]}
          element={
            <Modal size="xs">
              <ReInviteTeammatesModal isInvite />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.RE_INVITE_TEAMMATES_MODAL[language]}
          element={
            <Modal size="xs">
              <ReInviteTeammatesModal />
            </Modal>
          }
        />
        {/* IMPORTS */}
        <Route
          path={PATH_REL.IMPORT_MODAL__TYPE[language]}
          element={
            <Modal path={PATH_REL.IMPORT_MODAL__TYPE[language]}>
              <ImportModal />
            </Modal>
          }
        />

        {/* BULK_ACTIONS */}
        <Route
          path={PATH_REL.BULK_ACTIONS_MODAL[language]}
          element={
            // !!!!!! refactor after router6 part 2??
            <Modal size="sm" onCloseRoute={TO.TEAM[language]}>
              <BulkActionsModal />
            </Modal>
          }
        />

        {/* DOWNLOADS */}
        <Route
          path={PATH_REL.DOWNLOAD_MODAL__EXTENSION[language]}
          element={
            <Modal size="sm" path={PATH_REL.DOWNLOAD_MODAL__EXTENSION[language]}>
              <DownloadPrintModal
                requireIdsSelection
                dataType={ExportDataType.Person}
                selectedIdsRecoilState={selectedRowsIdsSelectorFamily(ListNames.TEAM)}
                view="team"
                variant="download"
              />
            </Modal>
          }
        />
      </Route>
      {/* VIEW_SETTINGS */}
      <Route
        path={PATH_REL.PRINT_QR_CARDS_MODAL[language]}
        element={
          <Modal size="sm">
            <PrintQrCardsModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.VIEW_SETTINGS_MODAL[language]}
        element={
          <Modal size="xs" fullHeight>
            <ViewSettingsModal />
          </Modal>
        }
      />
    </Routes>
  );
};
