import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { setCustomRequestTypesStateAction } from 'api/actions/customRequest.ts/customRequestActions';
import {
  CustomRequestTypeState,
  SetCustomRequestTypesStateActionProps,
} from 'api/actions/customRequest.ts/customRequestActions.types';
import { RequestType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Icon } from 'components/Icon/Icon';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { ITEM_DEFAULT_HEIGHT } from 'components/StickyList/constants';
import { ListItem, ListNames, ListVariant, StickyListProps } from 'components/StickyList/types';
import { LinkButton } from 'components/ui/Buttons/LinkButton';
import { Checkbox } from 'components/ui/Checkbox';
import { Switch } from 'components/ui/Switch';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { manageableCustomRequestTypesSelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';
import { LazyComponentType } from 'utils/custom.types';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';

const LazyStickyList = React.lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<RequestType>>;

export const CustomRequestList = (): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const navigate = useAppNavigate();
  const manageableCustomRequestTypes = useRecoilValue(manageableCustomRequestTypesSelector);
  const { mutate } = useMutation(setCustomRequestTypesStateAction);

  const [customRequestTypesStates, setCustomRequestTypesStates] = useState<CustomRequestTypeState[]>([]);
  const customRequestTypesStatesRef = useRef<CustomRequestTypeState[]>([]);

  const handlePropagationOnClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  const handleDeleteOnClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
      handlePropagationOnClick(e);
      navigate(TO_REL.DELETE_CUSTOM_REQUEST_TYPE_MODAL[language], { state: { ids: [id] } });
    },
    [handlePropagationOnClick, language, navigate],
  );

  const handleSwitchOnChange = useMemo(
    () =>
      _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const timeOffTypeState: CustomRequestTypeState = {
          id: e.target.name,
          state: e.target.checked,
        };
        const filteredTimeOffTypesStates = customRequestTypesStatesRef.current.filter(
          ({ id: currentId }) => currentId !== timeOffTypeState.id,
        );
        const newTimeOffTypesStates = [...filteredTimeOffTypesStates, timeOffTypeState];

        setCustomRequestTypesStates(newTimeOffTypesStates);
        customRequestTypesStatesRef.current = newTimeOffTypesStates;
      }, 200),
    [],
  );

  const debounceMutateCustomRequestTypesStates = useMemo(
    () =>
      _.debounce(async () => {
        const mutateData: SetCustomRequestTypesStateActionProps = {
          customRequestTypesStates: customRequestTypesStatesRef.current,
        };

        const { error } = await mutate(mutateData);

        if (!error) {
          addSnackbar({
            message: t({
              id: 'settings.forms.submit_success',
            }),
            variant: 'success',
          });
        }
      }, 500),
    [mutate],
  );

  useEffect(() => {
    if (customRequestTypesStates.length) {
      void debounceMutateCustomRequestTypesStates();
    }
  }, [debounceMutateCustomRequestTypesStates, customRequestTypesStates]);

  const columns: StickyListProps<RequestType>['columns'] = useMemo(
    () => [
      {
        key: ['isActive', 'isManageable'],
        title: t({ id: 'requests_settings.request_type.active' }),
        width: '40px',
        customCellRenderer: (
          [isActive, isManageable]: [RequestType['isActive'], RequestType['isManageable']],
          id: RequestType['id'],
        ) => (
          <Flex onClick={handlePropagationOnClick}>
            <Switch
              name={id}
              defaultChecked={isActive}
              size="sm"
              onChange={handleSwitchOnChange}
              disabled={!isManageable}
            />
          </Flex>
        ),
      },
      {
        key: 'abbreviation',
        title: t({ id: 'requests_settings.request_type.abbrev' }),
        width: '60px',
        sortableValue: (abbreviation: RequestType['abbreviation']) =>
          t({
            id: abbreviation,
          }),
        customCellRenderer: (abbreviation: RequestType['abbreviation']) => (
          <TextEllipsis
            title={t({
              id: abbreviation,
            })}
          >
            {t({
              id: abbreviation,
            })}
          </TextEllipsis>
        ),
      },
      {
        key: 'name',
        title: t({ id: 'global.forms.name' }),
        sortableValue: (name: RequestType['name']) =>
          t({
            id: name,
          }),
        customCellRenderer: (name: RequestType['name']) => (
          <TextEllipsis
            title={t({
              id: name,
            })}
          >
            {t({
              id: name,
            })}
          </TextEllipsis>
        ),
      },
      {
        key: 'isLimitable',
        title: t({ id: 'requests_settings.request_type.limitable', message: 'Limitable' }),
        width: '40px',
        customCellRenderer: (isLimitable: RequestType['isLimitable']) => (
          <Checkbox name="placeholder" checked={isLimitable} size="sm" disabled />
        ),
      },
      {
        key: ['id', 'isDefault'],
        width: '40px',
        customCellRenderer: ([id, isDefault]: [RequestType['id'], RequestType['isDefault']]) =>
          !isDefault ? (
            <Flex
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleDeleteOnClick(e, id)}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              <Icon type="delete" />
            </Flex>
          ) : null,
      },
    ],
    [handleDeleteOnClick, handlePropagationOnClick, handleSwitchOnChange],
  );

  const listHeight = useMemo(
    () =>
      manageableCustomRequestTypes &&
      `${manageableCustomRequestTypes.length * ITEM_DEFAULT_HEIGHT + ITEM_DEFAULT_HEIGHT + 12 + 1}px`,
    [manageableCustomRequestTypes],
  );

  const handleOnRowClick = (id: ListItem['id']) => {
    navigate(`${TO_REL.EDIT_CUSTOM_REQUEST_TYPE_MODAL[language]}/${id}`);
  };

  return (
    <SettingsGroup variant="noGap" sx={{ bg: 'settings.customRequestList', borderRadius: 'sm' }}>
      <SettingsGroup.Header headerWithList>
        <SettingsGroup.Title>
          <Trans id="requests_settings.texts.custom_request_types">Custom request types</Trans>
        </SettingsGroup.Title>

        <LinkButton
          size="sm"
          shape="rounded"
          variant="primary"
          prependWith={<Icon type="plus" />}
          to={`${TO_REL.ADD_CUSTOM_REQUEST_TYPE_MODAL[language]}`}
        >
          {t({ id: 'add' })}
        </LinkButton>
      </SettingsGroup.Header>

      <SettingsGroup.Body withHeaderList sx={{ height: listHeight, maxHeight: '400px' }}>
        <React.Suspense
          fallback={
            <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
              <LoadingSpinnerCss size={4} />
            </Flex>
          }
        >
          {manageableCustomRequestTypes && (
            <LazyStickyList
              name={ListNames.REQUESTS_CUSTOM}
              variant={ListVariant.darkerOne}
              list={manageableCustomRequestTypes}
              columns={columns}
              style={{ paddingRight: '0.75rem', paddingBottom: '0.75rem' }}
              mobileWidth={626}
              showHeader
              onRowClick={handleOnRowClick}
            />
          )}
        </React.Suspense>
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};

export const MemoizedCustomRequestList = React.memo(CustomRequestList);
