import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { Checkbox } from 'components/ui/Checkbox';
import { Radio } from 'components/ui/Radio';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { useContextMenu } from 'hooks/useContextMenu/useContextMenu';
import { rowSelectSelectorFamily, selectedRowsAtomFamily } from 'state/list';

import { Cell } from './Cell';
import { CustomRowVariant, RowProps } from './types';

const LazyRowContent = React.lazy(() =>
  import('./RowContent').then(({ RowContent }) => ({
    default: RowContent,
  })),
);

const FlexWithTooltip = withTooltip(Flex);

export const Row = ({
  onRowClick,
  select,
  columns,
  id,
  listName,
  data,
  variant,
  tooltip,
  uncheckable,
  withRowContextMenu,
  ...props
}: RowProps): React.ReactElement => {
  const [selected, setSelected] = useRecoilState(rowSelectSelectorFamily({ listName, itemId: id }));
  const selectSingleRow = useSetRecoilState(selectedRowsAtomFamily(listName));
  const rowRef = useRef<HTMLDivElement | null>(null);
  const [rowHover, setRowHover] = useState(false);

  const selectSingleRowCallback = useCallback(() => {
    const newMap = new Map();
    if (!selected) {
      newMap.set(id, !selected);
    }
    selectSingleRow({ value: newMap });
  }, [id, selectSingleRow, selected]);

  const preventParentOnClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelected(e.target.checked);
    },
    [setSelected],
  );

  const onRowClickCallback = useCallback(() => {
    if (onRowClick) {
      onRowClick(id, data);
    } else if (select === 'checkbox' && !uncheckable) {
      setSelected((prev) => !prev);
    } else if (select === 'radio' && !uncheckable) {
      selectSingleRowCallback();
    }
  }, [data, id, onRowClick, select, selectSingleRowCallback, setSelected, uncheckable]);

  const { openContextMenu } = useContextMenu(withRowContextMenu ? id : undefined);

  const rowProps = useMemo(
    () => ({
      onClick: onRowClickCallback,
      onContextMenu: openContextMenu,
      'data-selected': uncheckable ? false : selected,
      'data-non-editable': data.isNonEditable,
      'data-non-checkable': uncheckable && variant !== CustomRowVariant.error,
      variant: `stickyList.row.${variant}`,
      sx: {
        cursor: onRowClick || (!onRowClick && select) ? 'pointer' : 'default',
        ...(uncheckable && variant !== CustomRowVariant.error && { bg: 'stickyList.uncheckable' }),
      },
      onMouseEnter: () => setRowHover(true),
      onMouseLeave: () => setRowHover(false),
      ...props,
    }),
    [
      data.isNonEditable,
      onRowClick,
      onRowClickCallback,
      openContextMenu,
      props,
      select,
      selected,
      uncheckable,
      variant,
    ],
  );

  const cells = useMemo(
    () => (
      <>
        {select && (
          <Cell width="30px" onClick={preventParentOnClick} variant={variant}>
            {select === 'checkbox' ? (
              <Checkbox
                onChange={handleCheckboxChange}
                checked={uncheckable ? false : selected}
                name="rowCheckbox"
                size="sm"
                disabled={uncheckable}
              />
            ) : (
              <Radio
                onChange={selectSingleRowCallback}
                checked={uncheckable ? false : selected}
                name="rowRadio"
                size="sm"
                disabled={uncheckable}
              />
            )}
          </Cell>
        )}
        <Suspense fallback={<></>}>
          <LazyRowContent
            columns={columns}
            data={data}
            id={id}
            rowRef={rowRef}
            listName={listName}
            variant={variant}
            rowHover={rowHover}
          />
        </Suspense>
      </>
    ),
    [
      columns,
      data,
      handleCheckboxChange,
      id,
      listName,
      rowHover,
      select,
      selectSingleRowCallback,
      selected,
      uncheckable,
      variant,
    ],
  );

  return !tooltip ? (
    <Flex {...rowProps} ref={rowRef}>
      {cells}
    </Flex>
  ) : (
    <FlexWithTooltip tooltipProps={{ content: tooltip }} {...rowProps} ref={rowRef}>
      {cells}
    </FlexWithTooltip>
  );
};

export const MemorizedRow = React.memo(Row);
