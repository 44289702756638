import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { ThemeUICSSObject } from 'theme-ui';

import { RequestState } from 'api/actions/requests/requestsActions.types';
import { Icon, IconProps } from 'components/Icon/Icon';

export type RequestStatusIconProps = {
  state: RequestState;
  isDeleted: boolean;
  isZus?: boolean;
  hide?: boolean;
  iconSx?: ThemeUICSSObject;
  iconProps?: Partial<IconProps>;
};

export const RequestStatusIcon = ({
  state,
  isDeleted,
  isZus,
  hide,
  iconProps,
  iconSx,
}: RequestStatusIconProps): React.ReactElement | null => {
  useLingui();

  const props: IconProps = (() => {
    if (isDeleted) {
      return { type: 'delete', fill: 'requests.status.deleted', tooltip: t({ id: 'request.state.deleted' }) };
    }

    if (isZus) {
      return { type: 'zus', tooltip: t({ id: 'request.zus', message: 'Imported with ZUS eZLA' }) };
    }

    switch (state) {
      case RequestState.Accepted:
        return { type: 'approve', fill: 'requests.status.approved', tooltip: t({ id: 'request.state.approved' }) };
      case RequestState.Rejected:
        return { type: 'deny', fill: 'requests.status.deny', tooltip: t({ id: 'request.state.rejected' }) };
      case RequestState.Pending:
      default:
        return { type: 'pending', fill: 'requests.status.pending', tooltip: t({ id: 'request.state.pending' }) };
    }
  })();

  if (hide) return null;

  return <Icon {...props} {...iconProps} {...(iconSx && { wrapperSx: iconSx })} />;
};
