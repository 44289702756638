import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import { notificationsSettingsAtom, resetFormButtonAtom } from 'state/settings';

export const useNotificationsCleanUp = () => {
  const resetFormButton = useResetRecoilState(resetFormButtonAtom);
  const notificationsSettings = useResetRecoilState(notificationsSettingsAtom);

  useEffect(
    () => () => {
      resetFormButton();
      notificationsSettings();
    },
    [notificationsSettings, resetFormButton],
  );
};
