/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsxImportSource theme-ui */

import React, { useMemo } from 'react';
import { Link as RouterLink, AppLinkProps } from 'react-router-dom';
import { ThemeUIStyleObject, Text } from 'theme-ui';

import { useValidateNavigation } from 'hooks/useBlockAppNavigation/useBlockAppNavigation';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
  standOut?: boolean;
  sx?: ThemeUIStyleObject;
} & Partial<AppLinkProps>;

const defaultProps: Partial<Props> = {
  onClick: undefined,
  standOut: undefined,
  sx: undefined,
  to: undefined,
};

export const LinkAnchor = ({ children, onClick, to, standOut, sx, ...props }: Props): React.ReactElement => {
  const validateNavigation = useValidateNavigation();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }
    const isValidNavigation = validateNavigation(to || props.href, {
      state: props.state,
      relative: props.relative,
      replace: props.replace,
    });
    if (isValidNavigation) return;

    e.preventDefault();
  };

  const linkSx: ThemeUIStyleObject = useMemo(
    () => ({
      transition: 'color ease-in-out .05s',
      color: standOut ? 'link.standOut' : 'link.default',
      '&:hover': {
        color: standOut ? 'link.hoverStandOut' : 'link.hover',
        cursor: 'pointer',
      },
      '&:active': {
        color: standOut ? 'link.activeStandOut' : 'link.active',
      },
    }),
    [standOut],
  );

  if (props.href) {
    return (
      <a sx={{ ...linkSx, ...(sx && sx) }} onClick={handleClick} {...(props && props)}>
        {children}
      </a>
    );
  }
  if (to) {
    return (
      <RouterLink sx={{ ...linkSx, ...(sx && sx) }} to={to || ''} onClick={handleClick} {...(props && props)}>
        {children}
      </RouterLink>
    );
  }
  return (
    <Text sx={{ ...linkSx, ...(sx && sx) }} {...(onClick && { onClick })}>
      {children}
    </Text>
  );
};

LinkAnchor.defaultProps = defaultProps;
