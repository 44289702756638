import { ErrorInnerCodes } from 'constants/errorInnerCodes';

export type PayUPayNowPaymentMethod = {
  type: string;
  name: string;
  img: string;
};

export type BillingDetails = {
  isCompleted: boolean;
  companyName: string;
  vatNumber: string;
  countryCode: string;
  postalCode: string;
  street: string;
  city: string;
  country: string;
};

export type FetchPaymentInfoResponse = {
  id: string;
  hostname: HostnameEnum;
  usersCount: number;
  timeTracking: boolean;
  scheduling: boolean;
  requests: boolean;
  annualBilling: boolean;
  state: OrganizationAccountState;
  unpaidBalance: number;
  vat: number;
  currency: Currency;
  endDate: number;
  proformaDetails?: {
    id: string;
    payBefore?: number;
    nextProformaAvailableOn?: number;
    pendingPayment?: boolean;
    showWarning?: boolean;
    dueIn?: number;
  };
  confirmPaymentData: {
    processedPaymentId: string;
    paymentMethodId: string;
    clientSecret: string;
  };
  paymentMethod: {
    method: PaymentMethodEnum;
    methodDetails: string;
    paymentMethodId: string;
    card?: {
      confirmed: boolean;
      lastFour: string;
      brand: CardBrandEnum;
      expirationDate: string;
    };
  };
  billingDetails: BillingDetails;
  payNowPaymentMethods: PayUPayNowPaymentMethod[];
  payUPaymentMethods: PayUPayNowPaymentMethod[];
  processAsExtension: boolean;
  bankTransferData: {
    accountHolder: string;
    address: string;
    iban: string;
    swiftbic: string;
  };
  isActive: boolean;
  unpaidPendingPaymentId?: string;
};

export enum HostnameEnum {
  InewiPl = 0,
  UnrubbleCom = 1,
}

export enum OrganizationAccountState {
  Testing = 0,
  Paid = 1,
  Expired = 2,
}

export enum Currency {
  EUR = 0,
  PLN = 1,
  USD = 2,
  GBP = 3,
}

export enum PaymentMethodEnum {
  Card = 0,
  GooglePay = 1,
  ApplePay = 2,
  Proforma = 3,
  PayU = 4,
  PayPal = 5,
  WireTransfer = 6,
  PayPalSubscription = 7,
  PayNow = 8,
  None = -1,
}

export enum CardBrandEnum {
  AmericanExpress = 0,
  DinersClub = 1,
  Discover = 2,
  JCB = 3,
  MasterCard = 4,
  UnionPay = 5,
  Visa = 6,
  Unknown = 7,
}

export enum PaymentButtonState {
  PayNow = 0,
  RetryPayment = 1,
  ResumeSubscription = 2,
  GenerateProforma = 3,
  UpdateProforma = 4,
  Downgrade = 5,
  DowngradeAndPayNow = 6,
  Update = 7,
  Save = 8,
  DowngradeAndGenerateProforma = 9,
  VerifyCard = 10,
  // Only for front use
  SaveCard = 100,
}

export type CalculationInfoActionProps = {
  usersCount: number;
  timeTracking: boolean;
  scheduling: boolean;
  requests: boolean;
  annualBilling: boolean;
};

export type CalculationInfoResponse = {
  totalCostVatValue: number;
  payNowVatValue: number;
  totalDiscountValue: number;
  annualBillingDiscount: number;
  costPerUser: number;
  payNow: number;
  totalCostNetValue: number;
  netPrice: number;
  couponDiscount?: number;
  couponExpiredDate?: number;
  vat: number;
  unpaidBalance: number;
  paymentButtonState: PaymentButtonState;
  process: boolean;
  showDueIn: boolean;
};

export type UnpaidBalance = {
  dateRangeUnix: number[];
  amount: number;
  usersCount: number;
  modules: PaymentModule[];
  balanceType: PaymentBalanceType;
  currency: Currency;
};

export type FetchUnpaidBalanceResponse = UnpaidBalance[];

export enum PaymentModule {
  TimeTracking = 0,
  Schedule = 1,
  TimeOff = 2,
  BusinessTravel = 3,
}

export enum PaymentBalanceType {
  UnpaidSubscription = 0,
  UnpaidSurcharge = 1,
}

export type ProcessPaymentActionProps = {
  usersCount: number;
  timeTracking: boolean;
  scheduling: boolean;
  requests: boolean;
  annualBilling: boolean;
  paymentMethodNonce?: string;
};

export type ProcessPaymentResponse = {
  paymentEdition: true;
  processedPaymentId: string;
  processPayUResponse: {
    continueUrl: string;
  };
  processStripeResponse: {
    paymentMethodId: string;
    clientSecret: string;
  };
};

export type NotifyPaymentActionProps = {
  order: {
    orderId: string;
    extOrderId: string;
    orderCreateDate: string;
    notifyUrl: string;
    customerIp: string;
    merchantPosId: string;
    description: string;
    currencyCode: string;
    totalAmount: string;
    buyer: {
      email: string;
      phone: string;
      firstName: string;
      lastName: string;
    };
    products: [
      {
        name: string;
        unitPrice: string;
        quantity: string;
      },
    ];
    status: string;
  };
};

export type NotifyPaymentResponse = null;

export type ActivateActionProps = {
  id: string;
};

export type ActivateResponse = null;

export type CancelActionProps = {
  id: string;
};

export type CancelResponse = null;

export type CancelSubscriptionActionProps = {
  id: string;
};

export type CancelSubscriptionResponse = null;

export type ResumeSubscriptionActionProps = {
  id: string;
};

export type ResumeSubscriptionResponse = null;

export type UpdatePaymentMethodActionProps = {
  id: string;
  paymentMethodNonce?: string;
  methodDetails?: string;
  method: PaymentMethodEnum;
  paymentMethodId?: string;
};

export type UpdatePaymentMethodResponse = null;

export type RemovePaymentMethodActionProps = {
  id: string;
};

export type RemovePaymentMethodResponse = null;

export type CancelPendingPaymentActionProps = {
  id: string;
};

export type CancelPendingPaymentResponse = null;

export enum SnackbarVariant {
  Success = 0,
  Info = 1,
  Warning = 2,
  Danger = 3,
}

export enum PaymentWebhookAction {
  UpdatePaymentMethod = 0,
  CancelPayment = 1,
  ActivatePayment = 2,
  AuthenticationRequired = 3,
  SetupFailed = 4,
}

export type PaymentEventResponse = {
  snackbarVariant: SnackbarVariant;
  code: ErrorInnerCodes;
  action: PaymentWebhookAction;
};
