/** @jsxImportSource theme-ui */

import React, { InputHTMLAttributes } from 'react';
import { Label, Flex, ThemeUICSSObject } from 'theme-ui';

export type RadioProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  size?: 'xs' | 'sm' | 'default';
  label?: string | React.ReactElement;
  sx?: ThemeUICSSObject;
};

const defaultProps: Partial<RadioProps> = {
  size: 'default',
  label: undefined,
};

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ size, label, sx, ...props }: RadioProps, ref) => (
    <Label
      sx={{
        cursor: props.disabled ? 'not-allowed' : 'pointer',
        userSelect: 'none',
        alignItems: 'center',
        ...(sx && sx),
      }}
    >
      <input
        ref={ref}
        type="radio"
        sx={{
          left: '0',
          top: '0',
          opacity: '0',
          position: 'absolute',
        }}
        {...props}
      />
      <Flex variant={`forms.radio.${size}`} />
      {label}
    </Label>
  ),
);

Radio.defaultProps = defaultProps;
