import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { addScheduleAction } from 'api/actions/calendar/calendarActions';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { LinkButton } from 'components/ui/Buttons';
import { PATH_REL, TO_REL } from 'constants/routes';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { calendarRecentSchedulesSelector } from 'state/calendar';
import { languageSelector } from 'state/recoilState';
import { createEvent } from 'utils/createEvent';
import { dateTime } from 'utils/dateTime';
import { isDayInAllowedRange } from 'utils/isDayInAllowedRange';
import { useRefreshCalendar } from '../../../hooks/useRefreshCalendar';
import { ScheduleForm, ScheduleFormProps } from '../forms/ScheduleForm';
import { RecentSchedulePickerModal } from '../RecentSchedulePickerModal';
import { useScheduleWizard } from '../ScheduleWizardModal/hooks/useScheduleWizard';

import { useMinimizeModalAddSchedule } from './hooks/useMinimizeModalAddSchedule';

type OnHandleCloseOptions = {
  preventOpeningModalDialog: boolean;
  successfullyAddedSchedule: boolean;
};

export const AddScheduleModal = (): React.ReactElement => {
  useLingui();
  const [loading, setLoading] = useState(false);
  const [templateSchedule, setTemplateSchedule] = useState<ScheduleFormProps['defaultValues']>(undefined);

  const language = useRecoilValue(languageSelector);
  const recentSchedules = useRecoilValue(calendarRecentSchedulesSelector);

  const formRef = useRef<HTMLFormElement | null>(null);

  const currentDateUnix = useMemo(() => dateTime(undefined).startOf('day').utc(true).unix(), []);

  const { addSnackbar } = useSnackbar();
  const { user, date } = useParams() as { user: string; date: string };
  const { mutate } = useMutation(addScheduleAction);
  const { baseRoute } = useModal();

  const { onHandleMinimize, onHandleCloseModalWithMinimizedDetails } = useMinimizeModalAddSchedule(user, +date);

  const { isVisible: isOnboardingVisible, playNextStep, showWarningStep } = useInitialOnboarding();
  const onHandleCloseDuringOnboarding = useCallback(
    (closeModal: () => void, successfullyAddedSchedule?: boolean) => {
      closeModal();
      if (successfullyAddedSchedule) {
        playNextStep();
        return;
      }
      showWarningStep();
    },
    [playNextStep, showWarningStep],
  );

  const onHandleClose = useCallback(
    (closeModal: () => void, options?: OnHandleCloseOptions) => {
      const { preventOpeningModalDialog, successfullyAddedSchedule } = options || {};

      if (isOnboardingVisible) {
        onHandleCloseDuringOnboarding(closeModal, successfullyAddedSchedule);
        return;
      }

      void onHandleCloseModalWithMinimizedDetails(closeModal, preventOpeningModalDialog);
    },
    [isOnboardingVisible, onHandleCloseDuringOnboarding, onHandleCloseModalWithMinimizedDetails],
  );

  const { handleClose } = useModal({
    onHandleMinimize,
    onHandleClose,
    closeOnBackdrop: false,
  });

  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar([user]);
  const { reportInitialized, updateReportForIds } = useRefreshReport([user]);
  const { schedule: scheduleForm, resetWizard } = useScheduleWizard();

  const memoParsedRecentSchedules = useMemo(() => {
    if (!recentSchedules) return recentSchedules;

    return recentSchedules.map((schedule) => ({
      ...schedule,
      isAi: isNumber(schedule.aiVariant),
      details: {
        ...schedule.details,
        startDateTimeUnix: !isUndefined(schedule.details.startDateTimeUnix)
          ? schedule.details.startDateTimeUnix - currentDateUnix + +date
          : undefined,
        endDateTimeUnix: !isUndefined(schedule.details.endDateTimeUnix)
          ? schedule.details.endDateTimeUnix - currentDateUnix + +date
          : undefined,
      },
    }));
  }, [currentDateUnix, date, recentSchedules]);

  const setParsedTemplateSchedule = useCallback(
    (schedule: ScheduleFormProps['defaultValues']) => {
      if (!schedule || !schedule.details) return;

      setTemplateSchedule({
        ...schedule,
        details: {
          ...schedule.details,
          startDateTimeUnix: !isUndefined(schedule.details.startDateTimeUnix)
            ? schedule.details.startDateTimeUnix - +date + dateTime(date).utcOffset() * 60
            : undefined,
          endDateTimeUnix: !isUndefined(schedule.details.endDateTimeUnix)
            ? schedule.details.endDateTimeUnix - +date + dateTime(date).utcOffset() * 60
            : undefined,
        },
      });
    },
    [date],
  );

  const submitForm = () => {
    const form = formRef.current;

    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit: ScheduleFormProps['onSubmit'] = useCallback(
    async (body) => {
      const { error: submitError } = await mutate({ ...body, personId: user, dateUnix: +date });

      if (!submitError) {
        addSnackbar({
          message: t({ id: 'schedule.add_successful', message: 'Schedule added!' }),
          variant: 'success',
        });

        if (calendarInitialized) await updateCalendarForIds();
        if (reportInitialized) await updateReportForIds();

        handleClose({
          preventOpeningModalDialog: true,
          successfullyAddedSchedule: true,
        });

        return true;
      }

      setLoading(false);
      return false;
    },
    [
      mutate,
      user,
      date,
      addSnackbar,
      calendarInitialized,
      updateCalendarForIds,
      reportInitialized,
      updateReportForIds,
      handleClose,
    ],
  );

  const handleOnSave = () => {
    setLoading(true);
    submitForm();
  };

  useUnmount(() => {
    resetWizard();
  });

  const localDate = dateTime(date, { utc: true }).format('ll');

  return !isDayInAllowedRange(date) ? (
    <Navigate to={baseRoute} relative="path" />
  ) : (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="schedule.add_schedule">Add schedule</Trans>
        </Modal.Title>

        {recentSchedules && !isOnboardingVisible && (
          <LinkButton variant="lightGrey" shape="rounded" to={TO_REL.RECENT_SCHEDULE_PICKER[language]}>
            {t({ id: 'schedule.pick_schedule' })}
          </LinkButton>
        )}
      </Modal.Header>

      <Modal.Body sx={{ gap: 4 }}>
        <Flex sx={{ flexDirection: 'column' }}>
          <Modal.SubTitle>{t({ id: 'requests.for_day' })}</Modal.SubTitle>
          {localDate}
        </Flex>

        <ScheduleForm
          key={`${JSON.stringify(templateSchedule)}${JSON.stringify(scheduleForm)}`} // ugly as fck, think of better solution
          ref={formRef}
          defaultValues={templateSchedule || scheduleForm || undefined}
          onSubmit={onSubmit}
          setLoading={setLoading}
        />
      </Modal.Body>

      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleOnSave,
            variant: 'primary',
            children: t({ id: 'save' }),
            className: 'onboarding-schedule-4',
          },
        ]}
      />

      {memoParsedRecentSchedules && (
        <Routes>
          <Route
            path={PATH_REL.RECENT_SCHEDULE_PICKER[language]}
            element={
              <Modal size="xs" fullHeight>
                <RecentSchedulePickerModal
                  onScheduleClick={(scheduleIndex) => {
                    setParsedTemplateSchedule(memoParsedRecentSchedules[scheduleIndex]);
                  }}
                  recentSchedules={memoParsedRecentSchedules}
                />
              </Modal>
            }
          />
        </Routes>
      )}
    </>
  );
};
