import { t } from '@lingui/macro';
import _ from 'lodash';
import hash from 'object-hash';
import { selector, selectorFamily } from 'recoil';

import { ENTRY_TIME_EVENT_ID, EXIT_TIME_EVENT_ID } from 'Kiosk/constants/constants';
import {
  AiVariant,
  LimitPeriods,
  NormalPayrollPeriod,
  OvertimePayrollPeriod,
  StartingWeekDay,
  TimeEventType,
  VisibilityLevels,
  WorkdayAvailabilityState,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { DateTimeKind, RequestFormType } from 'api/actions/requests/requestsActions.types';
import {
  CarriedOverLimitExpiration,
  MagnetInterval,
  WorktimeWithoutSchedule,
} from 'api/actions/settings/settingsActions.types';
import { ExtendedTimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import {
  DateFormat,
  Languages,
  NameDisplayOrder,
  dateFormatAsString,
  languageAsString,
} from 'api/actions/userSession/userSessionActions.types';
import { InputOption } from 'components/ui/Select/Select';
import { DEFAULT_WORK_STATUSES_IDS } from 'constants/common';
import { ToleranceThresholds } from 'constants/locations';
import { displayDate } from 'pages/Requests/output/displayDate';

import { organizationSessionAtom, timeEventTypesDictionarySelector } from './organizationSession';
import { fullTimeFormatSelector, languageSelector } from './recoilState';
import { requestHistoryAtom } from './requests';
import { holidayImportLocationsResponseAtom } from './settings';
import { countryDetailsListResponseAtom } from './signUp';

// FIXME: te opcje powinny mieć jeden klucz tłumaczenia z plural i przekazywaną zmienną
export const visibilityLevelOptionsSelector = selector<InputOption[]>({
  key: 'visibilityLevelOptions',
  get: ({ get }) => {
    // this will trigger the selector recomputation on language changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      {
        label: t({ id: 'team.advanced.visibility.unlimited', message: 'Unlimited' }),
        id: `${VisibilityLevels.WithoutRestrictions}`,
      },
      { label: t({ id: 'team.advanced.visibility.1mo', message: '1 month' }), id: `${VisibilityLevels.OneMonth}` },
      { label: t({ id: 'team.advanced.visibility.2mo', message: '2 months' }), id: `${VisibilityLevels.TwoMonths}` },
      { label: t({ id: 'team.advanced.visibility.3mo', message: '3 months' }), id: `${VisibilityLevels.ThreeMonths}` },
      { label: t({ id: 'team.advanced.visibility.6mo', message: '6 months' }), id: `${VisibilityLevels.SixMonths}` },
      { label: t({ id: 'team.advanced.visibility.12mo', message: '12 months' }), id: `${VisibilityLevels.Year}` },
    ];
  },
});

export const periodSelectOptionsSelector = selector<InputOption[]>({
  key: 'periodSelectOptions',
  get: ({ get }) => {
    // this will trigger the selector recomputation on language changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      { id: `${LimitPeriods.Weekly}`, label: t({ id: 'team.req_limits.week', message: 'Week' }) },
      { id: `${LimitPeriods.Monthly}`, label: t({ id: 'team.req_limits.month', message: 'Month' }) },
      { id: `${LimitPeriods.Annually}`, label: t({ id: 'team.req_limits.year', message: 'Year' }) },
    ];
  },
});

export const toleranceThresholdOptionsSelector = selector<InputOption[]>({
  key: 'toleranceThresholdOptions',
  get: ({ get }) => {
    // this will trigger the selector recomputation on language changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    const values = Object.values(ToleranceThresholds)
      .map((key) => ToleranceThresholds[key as ToleranceThresholds] as unknown as number)
      .filter((k) => !Number.isNaN(Number(k)));

    return values.map((threshold) => ({
      label: `${threshold} ${t({ id: 'locations.tolerance.meters', message: 'meters' })}`,
      id: `${threshold}`,
    }));
  },
});

export type HashedEventType = string;
export type EventType = { id: TimeEventType['id']; isEndStatus: boolean };

type TypeToBeIncludedIfDeleted = string | null;

export const statusesMapSelectorFamily = selectorFamily<
  Map<HashedEventType, EventType> | null,
  TypeToBeIncludedIfDeleted
>({
  key: 'statusesMap',
  get:
    (typeId) =>
    ({ get }) => {
      const organizationSession = get(organizationSessionAtom);

      if (!organizationSession) return null;

      const { timeEventTypes } = organizationSession;
      const statuses: Map<HashedEventType, EventType> = new Map();

      timeEventTypes.forEach((timeEvent) => {
        const { id, isDeleted } = timeEvent;
        if (isDeleted && id !== typeId) return;

        const statusStart: EventType = { id, isEndStatus: false };
        const statusEnd: EventType = { id, isEndStatus: true };
        const statusStartHash = hash(statusStart);
        const statusEndHash = hash(statusEnd);

        if (id === ENTRY_TIME_EVENT_ID) {
          statuses.set(statusStartHash, statusStart);
          return;
        }
        if (id === EXIT_TIME_EVENT_ID) {
          statuses.set(statusEndHash, statusEnd);
          return;
        }

        statuses.set(statusStartHash, statusStart);
        statuses.set(statusEndHash, statusEnd);
      });

      return statuses;
    },
});

export const hashedEventTypeSelectorFamily = selectorFamily<
  HashedEventType,
  Pick<ExtendedTimeEvent, 'typeId' | 'isEnd'>
>({
  key: 'hashedEventType',
  get:
    ({ typeId, isEnd }) =>
    ({ get }) => {
      const statuses = get(statusesMapSelectorFamily(typeId));

      if (!statuses) return '';

      return (
        [...statuses]?.find(
          ([, status]) =>
            status.id === typeId && (status.isEndStatus === isEnd || typeId === DEFAULT_WORK_STATUSES_IDS.EXIT),
        )?.[0] || ''
      );
    },
});

export type WorkStatusesSelectOption = InputOption &
  Pick<TimeEventType, 'name'> & {
    isEnd: boolean;
    isClockIn: boolean;
    isClockOut: boolean;
    workStatusId: string;
  };
export const statusesOptionsSelectorFamily = selectorFamily<WorkStatusesSelectOption[], TypeToBeIncludedIfDeleted>({
  key: 'statusesOptions',
  get:
    (typeId) =>
    ({ get }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const language = get(languageSelector);
      const statusesMap = get(statusesMapSelectorFamily(typeId));
      const timeEventsDictionary = get(timeEventTypesDictionarySelector);

      if (!statusesMap || !timeEventsDictionary) return [];

      const options: WorkStatusesSelectOption[] = [];

      statusesMap.forEach((status, key) => {
        const { isEndStatus, id } = status;
        const { name } = timeEventsDictionary[id];

        const isClockIn = id === DEFAULT_WORK_STATUSES_IDS.ENTER;
        const isClockOut = id === DEFAULT_WORK_STATUSES_IDS.EXIT;

        const option = {
          label: t({ id: name }),
          id: key,
          name,
          isEnd: isEndStatus,
          workStatusId: id,
          isClockIn,
          isClockOut,
          appendWithDivider: isClockOut,
        };

        if (isClockIn || isClockOut) {
          if (isClockIn) options.unshift(option);

          const hasStartOption = options.some((o) => o.workStatusId === DEFAULT_WORK_STATUSES_IDS.ENTER);
          if (isClockOut && hasStartOption) options.splice(1, 0, option);
          if (isClockOut && !hasStartOption) options.unshift(option);
        } else options.push(option);
      });

      return options;
    },
});

export type RequestTypeSelectOption = InputOption & { abbreviation: string };
export const activeTimeOffTypeOptionsSelector = selector<RequestTypeSelectOption[]>({
  key: 'prepareTimeOffTypeOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);
    const organizationSession = get(organizationSessionAtom);

    if (!organizationSession) {
      return [];
    }

    const { timeOffTypes } = organizationSession;
    const filteredTimeOffTypes = timeOffTypes.filter(({ isActive }) => isActive);

    return _.orderBy(
      filteredTimeOffTypes.map(({ abbreviation, name, id }) => ({
        label: t({ id: name }),
        id,
        abbreviation: t({ id: abbreviation }),
      })),
      ({ label }) => label,
    );
  },
});

export const activeCustomRequestTypeOptionsSelector = selector<RequestTypeSelectOption[]>({
  key: 'prepareCustomRequestTypeOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);
    const organizationSession = get(organizationSessionAtom);

    if (!organizationSession) return [];

    const { customRequestTypes } = organizationSession;
    const filteredCustomRequestTypes = customRequestTypes.filter(({ isActive }) => isActive);

    return _.orderBy(
      filteredCustomRequestTypes.map(({ abbreviation, name, id }) => ({
        label: t({ id: name }),
        id,
        abbreviation: t({ id: abbreviation }),
      })),
      ({ label }) => label,
    );
  },
});

export const carriedOverLimitOptionsSelector = selector<InputOption[]>({
  key: 'carriedOverLimitOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      {
        label: t({ id: 'settings.select.never', message: 'Never' }),
        id: CarriedOverLimitExpiration.Never.toString(),
      },
      {
        label: t({ id: 'settings.select.one_year', message: '1 Year' }),
        id: CarriedOverLimitExpiration.Year1.toString(),
      },
      {
        label: t({ id: 'settings.select.two_years', message: '2 Years' }),
        id: CarriedOverLimitExpiration.Years2.toString(),
      },
      {
        label: t({ id: 'settings.select.three_years', message: '3 Years' }),
        id: CarriedOverLimitExpiration.Years3.toString(),
      },
      {
        label: t({ id: 'settings.select.four_years', message: '4 Years' }),
        id: CarriedOverLimitExpiration.Years4.toString(),
      },
      {
        label: t({ id: 'settings.select.five_years', message: '5 Years' }),
        id: CarriedOverLimitExpiration.Years5.toString(),
      },
    ];
  },
});

export const roundToMinutesSelectOptionsSelector = selector<InputOption[]>({
  key: 'roundToMinutesSelectOptions',
  get: () => [
    {
      label: '5min',
      id: MagnetInterval.Minutes5.toString(),
    },
    {
      label: '10min',
      id: MagnetInterval.Minutes10.toString(),
    },
    {
      label: '15min',
      id: MagnetInterval.Minutes15.toString(),
    },
    {
      label: '30min',
      id: MagnetInterval.Minutes30.toString(),
    },
    {
      label: '60min',
      id: MagnetInterval.Minutes60.toString(),
    },
  ],
});

export const baseOvertimePayrollPeriodOptionsSelector = selector<InputOption[]>({
  key: 'baseOvertimePayrollPeriodOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      // FIXME: Waiting for the backend to support this
      // {
      //   label: t({ id: 'settings.select.every_week', message: 'Every week' }),
      //   id: OvertimePayrollPeriod.Weeks1.toString(),
      // },
      // {
      //   label: t({ id: 'settings.select.every_two_week', message: 'Every 2 weeks' }),
      //   id: OvertimePayrollPeriod.Weeks2.toString(),
      // },
      {
        label: t({ id: 'settings.select.every_month', message: 'Every month' }),
        id: OvertimePayrollPeriod.Months1.toString(),
      },
      {
        label: t({ id: 'settings.select.every_two_month', message: 'Every 2 months' }),
        id: OvertimePayrollPeriod.Months2.toString(),
      },
      {
        label: t({ id: 'settings.select.every_three_month', message: 'Every 3 months' }),
        id: OvertimePayrollPeriod.Months3.toString(),
      },
      {
        label: t({ id: 'settings.select.every_four_month', message: 'Every 4 months' }),
        id: OvertimePayrollPeriod.Months4.toString(),
      },
      {
        label: t({ id: 'settings.select.every_six_month', message: 'Every 6 months' }),
        id: OvertimePayrollPeriod.Months6.toString(),
      },
      {
        label: t({ id: 'settings.select.every_twelve_month', message: 'Every 12 months' }),
        id: OvertimePayrollPeriod.Months12.toString(),
      },
    ];
  },
});

export const payrollPeriodOptionsSelector = selector<InputOption[]>({
  key: 'payrollPeriodOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      {
        label: t({ id: 'settings.select.every_week' }),
        id: NormalPayrollPeriod.Weeks1.toString(),
      },
      {
        label: t({ id: 'settings.select.every_two_week' }),
        id: NormalPayrollPeriod.Weeks2.toString(),
      },
      {
        label: t({ id: 'settings.select.every_month' }),
        id: NormalPayrollPeriod.Months1.toString(),
      },
    ];
  },
});

export const worktimeWithoutScheduleOptionsSelector = selector<InputOption[]>({
  key: 'worktimeWithoutScheduleOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      {
        label: t({ id: 'settings.select.work_time', message: 'Work time' }),
        id: WorktimeWithoutSchedule.CountAsWorkTime.toString(),
      },
      {
        label: t({ id: 'settings.select.overtime', message: 'Overtime' }),
        id: WorktimeWithoutSchedule.CountAsOvertime.toString(),
      },
      {
        label: t({ id: 'settings.select.count_separately', message: 'Count separately' }),
        id: WorktimeWithoutSchedule.CountSeparately.toString(),
      },
      {
        label: t({ id: 'settings.select.dont_count', message: "Don't count" }),
        id: WorktimeWithoutSchedule.DontCount.toString(),
      },
    ];
  },
});

export const dateFormatOptionsSelector = selector<InputOption[]>({
  key: 'dateFormatOptions',
  get: () => [
    {
      label: dateFormatAsString[DateFormat.DayAndMonth],
      id: `${DateFormat.DayAndMonth}`,
    },
    {
      label: dateFormatAsString[DateFormat.MonthAndDay],
      id: `${DateFormat.MonthAndDay}`,
    },
    {
      label: dateFormatAsString[DateFormat.YearAndMonth],
      id: `${DateFormat.YearAndMonth}`,
    },
    {
      label: dateFormatAsString[DateFormat.YearAndDay],
      id: `${DateFormat.YearAndDay}`,
    },
  ],
});

export const languageListOptionsSelector = selector<InputOption[]>({
  key: 'languageListOptions',
  get: () => [
    {
      label: languageAsString.en,
      id: `${Languages.en}`,
    },
    {
      label: languageAsString.pl,
      id: `${Languages.pl}`,
    },
    // {
    //   label: languageString.De,
    //   id: `${Language.De}`,
    // },
    // {
    //   label: languageString.Es,
    //   id: `${Language.Es}`,
    // },
    // {
    //   label: languageString.Fr,
    //   id: `${Language.Fr}`,
    // },
  ],
});

export const nameDisplayOrderOptionsSelector = selector<InputOption[]>({
  key: 'nameDisplayOrderOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      {
        label: t({
          id: 'name_display_order.name_first',
          message: 'Name and surname',
        }),
        id: `${NameDisplayOrder.NameFirst}`,
      },
      {
        label: t({
          id: 'name_display_order.surname_first',
          message: 'Surname and name',
        }),

        id: `${NameDisplayOrder.SurnameFirst}`,
      },
    ];
  },
});

export const startingWeekDayOptionsSelector = selector<InputOption[]>({
  key: 'startingWeekDayOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      {
        label: t({ id: 'global.day_of_week.monday', message: 'Monday' }),
        id: StartingWeekDay.Monday.toString(),
      },
      {
        label: t({ id: 'global.day_of_week.sunday', message: 'Sunday' }),
        id: StartingWeekDay.Sunday.toString(),
      },
    ];
  },
});

export const defaultAiSchedulingTrendScaleOptionsSelector = selector<InputOption[]>({
  key: 'defaultAiSchedulingTrendScaleOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      {
        label: t({ id: 'trend_scale.strict', message: 'Strict' }),
        id: AiVariant.Strict.toString(),
      },
      {
        label: t({ id: 'trend_scale.maximized', message: 'Maximized' }),
        id: AiVariant.Maximized.toString(),
      },
      {
        label: t({ id: 'trend_scale.optimized', message: 'Optimized' }),
        id: AiVariant.Optimized.toString(),
      },
    ];
  },
});

export const workdayAvailabilityOptionsSelector = selector<InputOption[]>({
  key: 'workdayAvailabilityOptions',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);

    return [
      {
        label: t({ id: 'availability.available' }),
        id: WorkdayAvailabilityState.Available.toString(),
      },
      {
        label: t({ id: 'availability.unavailable' }),
        id: WorkdayAvailabilityState.Unavailable.toString(),
      },
    ];
  },
});

export type EventsToUpdateSelectOption = InputOption & {
  name?: string;
  isEnd: boolean;
  isClockIn: boolean;
  isClockOut: boolean;
  duration?: number;
  number?: string;
  typeId?: string;
};

export const requestsToUpdateOptionsSelectorFamily = selectorFamily<
  EventsToUpdateSelectOption[] | null,
  RequestFormType
>({
  key: 'eventsToUpdateOptionsSelector',
  get:
    (requestType) =>
    ({ get }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const language = get(languageSelector);
      const requestHistory = get(requestHistoryAtom);
      const timeFormat = get(fullTimeFormatSelector);
      const timeEventsDictionary = get(timeEventTypesDictionarySelector);

      if (!requestHistory || !timeEventsDictionary) return null;

      const eventsToUpdateOptions = requestHistory.map(
        ({
          eventUpdateDetails: { id, typeId, isEndStatus, name, number },
          dateTimeDetails: { dates, kind, duration },
        }) => {
          const label = displayDate(
            {
              dateRange: dates,
              dateUnix: dates[0],
              isDateBound: kind === DateTimeKind.Local,
              type: requestType,
            },
            timeFormat,
          );

          const timeEventType = typeId ? timeEventsDictionary[typeId] : undefined;

          const isClockIn = typeId === DEFAULT_WORK_STATUSES_IDS.ENTER;
          const isClockOut = typeId === DEFAULT_WORK_STATUSES_IDS.EXIT;

          return {
            id,
            label,
            isEnd: isEndStatus,
            name: timeEventType?.name || name,
            isClockIn,
            isClockOut,
            duration,
            number,
            typeId,
          };
        },
      );

      return _.uniqBy(eventsToUpdateOptions, ({ id }) => id);
    },
});

export const importHolidaysCountryOptionsSelector = selector<InputOption[] | null>({
  key: 'importHolidaysCountryOptions',
  get: ({ get }) => {
    const countryList = get(holidayImportLocationsResponseAtom);
    const language = get(languageSelector);
    const regionNames = new Intl.DisplayNames([language], { type: 'region' });

    if (!countryList || !language) return null;

    const options: InputOption[] = [];

    countryList.forEach((item) => {
      const label = regionNames.of(item.toUpperCase());

      if (!label) return;

      // eslint-disable-next-line consistent-return
      return options.push({ label, id: item });
    });

    options.sort((a, b) => a.label.localeCompare(b.label));

    return options;
  },
});

export const countryOptionsSelector = selector<InputOption[] | null>({
  key: 'countryOptions',
  get: ({ get }) => {
    const countryDetailsList = get(countryDetailsListResponseAtom);
    const language = get(languageSelector);

    if (!countryDetailsList || !language) return null;

    const options: InputOption[] = countryDetailsList.map(({ localizedName, countryCode }) => ({
      label: localizedName,
      id: countryCode,
    }));

    options.sort((a, b) => a.label.localeCompare(b.label));

    return options;
  },
});

export const currencyOptionsSelector = selector<InputOption[] | null>({
  key: 'currencyOptions',
  get: ({ get }) => {
    const countryDetailsList = get(countryDetailsListResponseAtom);
    const language = get(languageSelector);
    if (!countryDetailsList || !language) return null;

    const currencyDisplayNames = new Intl.DisplayNames([language], { type: 'currency' });

    const options: InputOption[] = [];

    countryDetailsList.forEach(({ currencySymbol }) => {
      if (!currencySymbol) return;
      const displayName = currencyDisplayNames.of(currencySymbol);
      if (!displayName || !!options.find(({ id }) => id === currencySymbol)) return;
      const label = displayName
        .split(' ')
        .map((name) => _.capitalize(name))
        .join(' ');

      options.push({
        label,
        id: currencySymbol,
      });
    });

    return options
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ id, label }) => ({ id, label: `${id} - ${label}` }));
  },
});
