import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import { requestsSettingsAtom, resetFormButtonAtom } from 'state/settings';

export const useRequestsSettingsCleanUp = () => {
  const resetFormButton = useResetRecoilState(resetFormButtonAtom);
  const resetRequestsSettings = useResetRecoilState(requestsSettingsAtom);

  useEffect(
    () => () => {
      resetFormButton();
      resetRequestsSettings();
    },
    [resetFormButton, resetRequestsSettings],
  );
};
