import React from 'react';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';

const LazyLocations = React.lazy(() =>
  import('./components/Locations/Locations').then(({ Locations }) => ({
    default: Locations,
  })),
);

export const LocationsRenderer = React.memo(
  (): React.ReactElement => (
    <React.Suspense fallback={<LoadingOverlay sx={{ zIndex: 'base' }} />}>
      <LazyLocations />
    </React.Suspense>
  ),
);
