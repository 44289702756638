import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { FC, useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Resolver, useForm } from 'react-hook-form';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { setPayDetailsAction } from 'api/actions/employees/employeesActions';
import { SetPayDetailsActionProps } from 'api/actions/employees/employeesActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { Checkbox } from 'components/ui/Checkbox';
import { PATH_REL } from 'constants/routes';
import { useLocationOnce } from 'hooks/useLocationOnce/useLocationOnce';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { languageSelector } from 'state/recoilState';
import { defaultPayRate, EmployeeEmploymentDetailsFieldNames } from 'state/team';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { RatesFieldArray } from '../../../forms/formsElements/RatesFieldArray';
import { PayRatesBasicFormState } from '../../../forms/formsElements/types';
import { payRatesTypeEnforcer } from '../../../forms/utils/fieldArrayTypeEnforcers';
import { payRatesValidator } from '../../../forms/utils/fieldArrayValidators';
import { ManageRolesModal } from '../../role/ManageRoles';

const forceCorrectTypes = ({
  rates,
  overwrite,
}: PayRatesBasicFormState): Omit<SetPayDetailsActionProps, 'employeesIds'> => ({
  overwrite,
  rates: payRatesTypeEnforcer(rates) || [],
});

export const SetPayDetails: FC = () => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();
  const language = useRecoilValue(languageSelector);
  const [loading, setLoading] = useState(false);
  const { state } = useLocationOnce();
  const { ids } = state || {};
  const formRef = useRef<HTMLFormElement | null>(null);

  const defaultValues: PayRatesBasicFormState = {
    [EmployeeEmploymentDetailsFieldNames.Rates]: [defaultPayRate],
    overwrite: false,
  };

  const { control, register, handleSubmit } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    resolver,
  });

  const { mutate } = useMutation(setPayDetailsAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: PayRatesBasicFormState): Promise<boolean> => {
      if (!ids || !ids.length) return false;
      const { error: submitError } = await mutate({
        employeesIds: ids || [],
        ...forceCorrectTypes(body),
      });
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.set_pay_bulk.set', message: 'Successfully set!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, ids, addSnackbar],
  );
  const onSubmitError = () => {
    setLoading(false);
  };

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.set_pay_bulk.set_pay_details">Set pay details</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body sx={{ mt: 3 }}>
        <form ref={formRef} onSubmit={floatingPromiseReturn(handleSubmit(onSubmit, onSubmitError))} noValidate>
          <RatesFieldArray register={register} control={control} />
          <br />
          <Checkbox
            labelWrapperSx={{ ml: -2 }}
            label={t({ id: 'team.set_pay_bulk.overwrite', message: 'Overwrite existing pay details' })}
            size="sm"
            {...register('overwrite')}
          />
        </form>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'team.set_pay_bulk.next', message: 'Save' }),
          },
        ]}
      />
      <Routes>
        <Route
          path={PATH_REL.MANAGE_ROLES_MODAL[language]}
          element={
            <Modal size="lg" fullHeight>
              <ManageRolesModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};

const resolver: Resolver<PayRatesBasicFormState> = (values) => {
  const { rates } = values;

  const ratesErrors = payRatesValidator(rates);

  return {
    errors: {
      ...(!isEmpty(ratesErrors) && { rates: ratesErrors }),
    },
    values,
  };
};
