import React, { useRef, useState } from 'react';
import { Flex } from 'theme-ui';
import { flushSync } from 'react-dom';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useResetRecoilState } from 'recoil';

import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { FilterSearchInput } from 'layouts/AuthorizedApp/AsideFilters/FilterSearchInput';
import { searchFilterValueAtomFamily, SEARCH_FILTER_TYPE } from 'state/filters';
import { useUnmount } from 'hooks/useUnmount/useUnmount';

export const FilterSearchStatuses = (): React.ReactElement => {
  useLingui();
  const resetSearchFilterValueState = useResetRecoilState(
    searchFilterValueAtomFamily(SEARCH_FILTER_TYPE.DRAWER__TIME_TRACKING),
  );
  const [showSearchInput, setShowSearchInput] = useState(false);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useUnmount(resetSearchFilterValueState);

  return (
    <Flex sx={{ ml: 'auto' }}>
      {showSearchInput ? (
        <FilterSearchInput
          placeholder={t({ id: 'drawer.time_tracking.search_input.placeholder', message: 'Filter' })}
          size="xs"
          type={SEARCH_FILTER_TYPE.DRAWER__TIME_TRACKING}
          ref={searchInputRef}
          id="drawerTimeTrackingSearchInput"
          sx={{
            '& .text-input__wrapper': {
              m: '1.5px',
              maxWidth: '160px',
            },
          }}
        />
      ) : (
        <Button
          size="sm"
          variant="minimal"
          shape="rounded"
          onClick={() => {
            flushSync(() => {
              setShowSearchInput(true);
            });
            searchInputRef.current?.focus();
          }}
        >
          <Icon type="search" />
        </Button>
      )}
    </Flex>
  );
};
