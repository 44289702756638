import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { Content } from './Content';
import { Header } from './Header';

type AsideDetailsComponent = React.FC<Props> & {
  Header: typeof Header;
  Content: typeof Content;
};

type Props = FlexProps & Required<Pick<FlexProps, 'children'>>;

export const AsideDetails: AsideDetailsComponent = ({ sx, children, ...props }: Props): React.ReactElement => (
  <Flex
    as="aside"
    variant="layouts.authorized.aside.details.container"
    {...props}
    sx={{
      ...(sx && sx),
    }}
  >
    {children}
  </Flex>
);

AsideDetails.Header = Header;
AsideDetails.Content = Content;
