import { Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Align, FixedSizeList, VariableSizeGrid, VariableSizeGridProps } from 'react-window';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import {
  isRowUncheckableValidatorAtomFamily,
  sortedListMapSelectorFamily,
  sortingStateAtomFamily,
  stickyListActionsAtomFamily,
} from 'state/list';
import { languageSelector } from 'state/recoilState';

import { StickyListInnerElement } from './StickyListInnerElement';
import { StickyListOuterElement } from './StickyListOuterElement';
import { GRID_DEFAULT_HEIGHT, GUTTER_DEFAULT_SIZE, ITEM_DEFAULT_HEIGHT, ITEM_DEFAULT_WIDTH } from './constants';
import { StickyListContextProvider } from './context';
import { gridRenderer } from './renderers/gridRenderer';
import { rowRenderer } from './renderers/rowRenderer';
import { ListColumn, ListItem, ListVariant, SortingOrder, StickyListProps } from './types';

// TODO:
// 1. Add StickyListActions for type = list

export const StickyList = <ListObj extends ListItem = ListItem, ColumnId extends string | number = string | number>({
  showHeader,
  columns,
  list,
  name,
  emptyListMessage,
  select,
  style,
  showContentPlaceholder,
  onRowClick,
  variant = ListVariant.default,
  customRowVariantGenerator,
  rowTooltipGenerator,
  onItemsRendered,
  type = 'list',
  frozenColumns,
  disableNativeKeyboardScrolling,
  isRowUncheckableValidator,
  fallbackSortableValueGetter,
  rowHeightGenerator,
  headerSx,
  mobileWidth,
  defaultSortingState,
  rerenderOnColumnCountChange,
  withRowContextMenu = false,
  withSummary,
  dimensions,
  appendHeaderWith,
}: StickyListProps<ListObj, ColumnId>): React.ReactElement => {
  const [rowHeights, setRowHeights] = useState<number[] | undefined>(undefined);

  const [sortingState, setSortingState] = useRecoilState(sortingStateAtomFamily(name));
  const [sortedList, setSortedList] = useRecoilState(sortedListMapSelectorFamily(name));
  const setIsRowUncheckableValidator = useSetRecoilState(isRowUncheckableValidatorAtomFamily(name));
  const setStickyListActions = useSetRecoilState(stickyListActionsAtomFamily(name));
  const language = useRecoilValue(languageSelector);

  const { isMobileBreakpoint } = useThemeBreakpoint();

  const gridRef = useRef<VariableSizeGrid | null>(null);
  const defaultSortingStateRef = useRef(defaultSortingState);

  const defaultItemHeight = useMemo(() => {
    if (dimensions?.height) return dimensions.height + GUTTER_DEFAULT_SIZE;

    return type === 'list' ? ITEM_DEFAULT_HEIGHT : GRID_DEFAULT_HEIGHT;
  }, [dimensions?.height, type]);

  const defaultItemWidth = useMemo(() => {
    if (dimensions?.width) return dimensions.width + GUTTER_DEFAULT_SIZE;

    return ITEM_DEFAULT_WIDTH;
  }, [dimensions?.width]);

  const gridListCallback = useCallback((columnIndex?: number, rowIndex?: number, align?: Align) => {
    gridRef?.current?.scrollToItem({
      align: align || 'smart',
      columnIndex,
      rowIndex,
    });
  }, []);

  const isListEmpty = useMemo(() => (_.isArray(list) ? !list.length : !list.size), [list]);

  useEffect(() => {
    if (isRowUncheckableValidator) {
      setIsRowUncheckableValidator({ value: isRowUncheckableValidator });
    }
  }, [isRowUncheckableValidator, setIsRowUncheckableValidator]);

  useEffect(() => {
    const shouldSetDefaultSorting = sortingState
      ? !columns.find(({ key, sortableValue }) => _.isEqual(key, sortingState.columnKey) && !!sortableValue)
      : true;

    if (shouldSetDefaultSorting) {
      const firstSortableColumn = _.find(columns, (column) => column.sortableValue) as unknown as ListColumn;
      const defaultSortableColumn = _.find(
        columns,
        (column) => _.isEqual(column.key, defaultSortingStateRef.current?.columnKey) && column.sortableValue,
      ) as unknown as ListColumn;
      const sortableColumn = defaultSortableColumn || firstSortableColumn;

      if (sortableColumn && sortableColumn.key) {
        setSortingState({
          columnKey: sortableColumn.key,
          order: defaultSortingStateRef.current?.order || SortingOrder.ASC,
        });
      }
    }
  }, [columns, setSortingState, sortingState]);

  useEffect(() => {
    let sortedMap: Map<string, ListObj> = new Map();

    if (sortingState) {
      const sortingColumn = _.find(columns, (column) => _.isEqual(column.key, sortingState.columnKey));
      const newMap: Map<ListObj, string | number> = new Map();

      if (sortingColumn && sortingColumn.sortableValue) {
        const { sortableValue, key } = sortingColumn;

        if (_.isMap(list)) {
          list.forEach((item) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            const content = _.isArray(key) ? _.map(key, (k) => item[k]) : item[key];
            const sortableVal = sortableValue(content);

            newMap.set(item, _.isString(sortableVal) ? _.trim(`${sortableValue(content)}`) : sortableVal);
          });
        }

        if (_.isArray(list)) {
          list.forEach((item) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            const content = _.isArray(key) ? _.map(key, (k) => item[k]) : item[key];
            const sortableVal = sortableValue(content);

            newMap.set(item, _.isString(sortableVal) ? _.trim(`${sortableValue(content)}`) : sortableVal);
          });
        }
      }

      const listOrderBy: [ListObj, string | number][] = [...newMap].sort((item1, item2) => {
        let comparison = 0;

        if (_.isString(item1[1]) && _.isString(item2[1])) {
          comparison = item1[1].localeCompare(item2[1], language, { caseFirst: 'upper' });
        }

        if (_.isNumber(item1[1]) && _.isNumber(item2[1])) {
          comparison = item1[1] - item2[1];
        }

        if (comparison === 0 && fallbackSortableValueGetter) {
          const fallbackSortableValuePrev = fallbackSortableValueGetter(item1[0]);
          const fallbackSortableValue = fallbackSortableValueGetter(item2[0]);

          if (_.isString(fallbackSortableValuePrev) && _.isString(fallbackSortableValue)) {
            const fallbackComparison = fallbackSortableValuePrev.localeCompare(fallbackSortableValue, language, {
              caseFirst: 'upper',
            });
            return fallbackComparison;
          }

          if (_.isNumber(fallbackSortableValuePrev) && _.isNumber(fallbackSortableValue)) {
            const diff = fallbackSortableValuePrev - fallbackSortableValue;
            return diff;
          }
        }

        return sortingState.order === SortingOrder.ASC ? comparison : -comparison;
      });

      sortedMap = new Map(_.map(listOrderBy, (item) => [item[0].id, item[0]]));
    }

    setSortedList(sortedMap);
    setStickyListActions({ scrollTo: gridListCallback, list: sortedMap, columns: columns.map((c) => c.title || '') });

    if (rowHeightGenerator) {
      const newHeights = rowHeightGenerator(sortedMap);

      if (!rowHeights || (!!rowHeights && newHeights.join('') !== rowHeights.join(''))) {
        setRowHeights(newHeights);
      }
    }
  }, [
    columns,
    list,
    setSortedList,
    sortingState,
    fallbackSortableValueGetter,
    language,
    setStickyListActions,
    gridListCallback,
    rowHeightGenerator,
    rowHeights,
  ]);

  useEffect(() => {
    if (rowHeights) {
      gridRef?.current?.resetAfterRowIndex(0);
    }
  }, [rowHeights]);

  const rowCount = useMemo(() => (_.isArray(list) ? list.length : list.size), [list]);
  const columnCount = useMemo(() => columns.length, [columns]);
  const createItemData = useMemo(
    () => ({
      name,
      columns,
      select,
      showHeader,
      disableNativeKeyboardScrolling,
      list: sortedList ? new Map(_.toPairs([...sortedList.values()])) : new Map(),
      itemCount: rowCount,
      frozenColumns,
      onRowClick,
      variant,
      headerSx,
      customRowVariantGenerator,
      rowTooltipGenerator,
      isRowUncheckableValidator,
      withRowContextMenu,
      withSummary,
      gridDefaultHeight: defaultItemHeight,
    }),
    [
      name,
      columns,
      select,
      showHeader,
      disableNativeKeyboardScrolling,
      sortedList,
      rowCount,
      frozenColumns,
      onRowClick,
      variant,
      headerSx,
      customRowVariantGenerator,
      rowTooltipGenerator,
      isRowUncheckableValidator,
      withRowContextMenu,
      withSummary,
      defaultItemHeight,
    ],
  );
  const createContextData = useMemo(
    () => ({
      ...createItemData,
      listStyle: style,
      type,
      showContentPlaceholder,
      itemHeight: defaultItemHeight,
      itemWidth: defaultItemWidth,
      appendHeaderWith,
    }),
    [createItemData, defaultItemHeight, defaultItemWidth, appendHeaderWith, showContentPlaceholder, style, type],
  );

  const columnWidths = useMemo(
    () => (type === 'grid' ? columns.map((column) => parseInt(`${column.width}`, 10) || defaultItemWidth) : []),
    [columns, defaultItemWidth, type],
  );

  const getRowHeight: VariableSizeGridProps['rowHeight'] = useCallback(
    (index) => {
      if (rowHeights && rowHeights[index]) {
        return rowHeights[index];
      }
      return defaultItemHeight;
    },
    [defaultItemHeight, rowHeights],
  );

  useEffect(() => {
    if (gridRef.current && rerenderOnColumnCountChange) {
      gridRef.current?.resetAfterColumnIndex(0); // force rerender to avoid layout shifts when changing columns count in viewSettings
    }
  }, [columnCount, rerenderOnColumnCountChange]);

  return (
    <StickyListContextProvider<ListObj, ColumnId> context={createContextData}>
      {sortedList && sortedList.size > 0 ? (
        <AutoSizer>
          {({ height, width }) =>
            type === 'list' ? (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <FixedSizeList<any>
                itemData={createItemData}
                innerElementType={StickyListInnerElement}
                outerElementType={StickyListOuterElement}
                itemCount={rowCount}
                itemSize={defaultItemHeight}
                overscanCount={5}
                width={width}
                height={height}
                style={{
                  paddingBottom: '0.75rem',
                  paddingLeft: '1px',
                  paddingRight: '1px',
                  ...(isMobileBreakpoint &&
                    mobileWidth && {
                      width: mobileWidth,
                    }),
                  ...style,
                }}
                onItemsRendered={onItemsRendered}
              >
                {rowRenderer}
              </FixedSizeList>
            ) : (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <VariableSizeGrid<any>
                ref={gridRef}
                itemData={createItemData}
                innerElementType={StickyListInnerElement}
                outerElementType={StickyListOuterElement}
                columnCount={columnCount}
                columnWidth={(index) => columnWidths[index]}
                rowCount={rowCount}
                rowHeight={getRowHeight}
                overscanColumnCount={5}
                overscanRowCount={5}
                width={width}
                height={height}
                style={{
                  paddingBottom: '0.75rem',
                  ...style,
                }}
              >
                {gridRenderer}
              </VariableSizeGrid>
            )
          }
        </AutoSizer>
      ) : (
        isListEmpty && (
          <Flex sx={{ flexDirection: 'column', width: '100%', p: 4 }}>
            <Flex sx={{ width: '100%', p: 4, justifyContent: 'center' }}>
              <Text as="span" sx={{ display: 'inline-flex', fontWeight: '600', color: 'stickyList.empty' }}>
                {emptyListMessage || <Trans id="empty_list">This list is empty.</Trans>}
              </Text>
            </Flex>
          </Flex>
        )
      )}
    </StickyListContextProvider>
  );
};
