import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { mergeRefs } from 'utils/mergeRefs';
import { Icon } from 'components/Icon/Icon';
import { ScheduleUserSelectableColor, UserSelectableColor } from 'constants/userSelectableColors';
import { typedKeys } from 'utils/typedKeys';
import { setNativeValue } from 'utils/setNativeValue';
import { InputOwnValue, useOnOwnValueChange } from 'hooks/useOnOwnValueChange/useOnOwnValueChange';

import { ElementGroup } from './ElementGroup';

export type InputOption = { label: string; id: string; isCreatable?: boolean };

type Props = Pick<FlexProps, 'sx'> &
  React.HTMLAttributes<HTMLInputElement> & {
    disabled?: boolean;
    colorScheme?: 'default' | 'schedules';
  };

export const ColorPicker = React.forwardRef<HTMLInputElement, Props>(
  ({ sx, disabled = false, colorScheme = 'default', ...props }: Props, ref) => {
    const colors = useMemo(
      () => (colorScheme === 'default' ? UserSelectableColor : ScheduleUserSelectableColor),
      [colorScheme],
    );

    const [selectedColor, setSelectedColor] = useState<string>(colors[0]);

    const hiddenRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      if (hiddenRef.current) {
        const initialValue = hiddenRef.current.value;

        if (initialValue && colors[+initialValue]) {
          setSelectedColor(colors[+initialValue]);
        }
      }
    }, [colors]);

    const handleColorChange = (color: string) => {
      setSelectedColor(color);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
      setNativeValue(hiddenRef, colors[color as unknown as UserSelectableColor | ScheduleUserSelectableColor]);
    };

    const onOwnValueChange = useCallback(
      (newValue: InputOwnValue) => {
        if (newValue) {
          setSelectedColor(colors[+newValue]);
        }
      },
      [colors],
    );

    useOnOwnValueChange(hiddenRef, onOwnValueChange);

    return (
      <Flex sx={{ flexWrap: 'wrap', ...(sx && sx), ...(disabled && { pointerEvents: 'none' }) }}>
        <input
          {...props}
          ref={mergeRefs([ref, hiddenRef])}
          style={{ width: 0, opacity: 0, position: 'absolute' }}
          readOnly
        />
        <ElementGroup marginAt="end" marginValue="2px" direction="row">
          {typedKeys(colors)
            // eslint-disable-next-line no-restricted-globals
            .filter((key) => !isNaN(Number(colors[key])))
            .map((color) => (
              <Flex
                key={color}
                sx={{
                  mt: '2px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '24px',
                  height: '24px',
                  borderRadius: '6px',
                  cursor: 'pointer',
                  bg: color,
                  '&:hover': {
                    opacity: 0.8,
                  },
                }}
                onClick={() => handleColorChange(color)}
              >
                {selectedColor === color && (
                  <Flex
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: 0,
                      width: '16px',
                      height: '16px',
                      bg: 'colorPicker',
                      borderRadius: 'circle',
                    }}
                  >
                    <Icon type="selected" />
                  </Flex>
                )}
              </Flex>
            ))}
        </ElementGroup>
      </Flex>
    );
  },
);
