import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-fetching-library';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { getPeopleToSwapAction } from 'api/actions/requests/requestsActions';
import { AddRequestActionProps, GetPeopleToSwapResponse } from 'api/actions/requests/requestsActions.types';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { useMount } from 'hooks/useMount/useMount';
import { appPermissionsSelector } from 'state/appPermissions';
import { dateTime } from 'utils/dateTime';
import { useAddRequest } from '../../../../hooks/useAddRequest';

import { NoEmployeesAlert } from './components/NoEmployeesAlert';
import { ScheduleContainer } from './components/ScheduleContainer';

export const ScheduleForm = (): React.ReactElement => {
  useLingui();
  const [employeeIdsToSwap, setEmployeeIds] = useState<GetPeopleToSwapResponse | null>(null);
  const { modulesManagement } = useRecoilValue(appPermissionsSelector); // FIXME: JOTAI MIGRATION
  const shouldShowAlert = useMemo(
    () => modulesManagement.Schedules && employeeIdsToSwap?.length === 0,
    [modulesManagement.Schedules, employeeIdsToSwap],
  );

  const {
    requestToEdit: { resetSelectedRequestToEditId },
    setFormState,
    setAddRequestState,
    defaultDateUnix,
    selectedTeammatesIds,
  } = useAddRequest();

  const personId = useMemo(() => selectedTeammatesIds[0], [selectedTeammatesIds]);
  const { query, loading } = useQuery(getPeopleToSwapAction({ personId }));

  const { setValue } = useFormContext<AddRequestActionProps>();

  const getEmployeesIds = useCallback(async () => {
    const { error, payload } = await query();

    if (!error && payload) {
      setEmployeeIds(payload);
    }
  }, [query, setEmployeeIds]);

  useMount(() => {
    void getEmployeesIds();
  });

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      mainFormVisible: !loading && !shouldShowAlert,
    }));
  }, [setFormState, shouldShowAlert, loading]);

  useEffect(
    () => () => {
      resetSelectedRequestToEditId();
    },
    [resetSelectedRequestToEditId],
  );

  useEffect(
    () => setAddRequestState((prevState) => ({ ...prevState, isSaveDisabled: loading || shouldShowAlert })),
    [setAddRequestState, shouldShowAlert, loading],
  );

  useEffect(() => {
    const minDateUnix = dateTime(undefined).add(1, 'day').startOf('day').unix();

    if (defaultDateUnix && minDateUnix <= defaultDateUnix) {
      setValue('dateTimeDetails.date', defaultDateUnix);
    }
  }, [defaultDateUnix, setValue]);

  const renderScheduleForm = useCallback(() => {
    if (loading) return <CenteredLoadingSpinner size={5} />;
    if (shouldShowAlert) return <NoEmployeesAlert />;

    return (
      <ScheduleContainer>
        <ScheduleContainer.Calendar />
        <ScheduleContainer.ForPersonSelect ids={employeeIdsToSwap} />
        <ScheduleContainer.Schedules />
      </ScheduleContainer>
    );
  }, [loading, shouldShowAlert, employeeIdsToSwap]);

  return renderScheduleForm();
};
