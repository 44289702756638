import React, { useState, useEffect, useCallback } from 'react';
import { Flex } from 'theme-ui';

import { API_ENDPOINTS } from 'constants/api';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { REACT_APP_API_URL } from 'constants/common';
import { ButtonProps } from '../Buttons';

import { FileName, ProgressBar, LoadingStatus, UploadingButtons, DeleteButton } from './fileUploadElements';
import { DEFAULT_UPLOAD_TIMEOUT_THRESHOLD } from './constants';
import { BlobCompProps, StyleVariant, ServerFileDetails } from './types';

export const BlobFile = ({
  blobFile,
  setBlobFiles,
  setServerFiles,
  setFilesIds,
  setFilesErrors,
  dispatchBlurEvent,
  timeout,
}: BlobCompProps): React.ReactElement => {
  const [variant, setVariant] = useState<StyleVariant>('default');
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const [cancelBtnCallback, setCancelBtnCallback] = useState<(() => void) | null>(null);

  const { accessToken } = useAuthState();

  const updateProgress = useCallback((e: ProgressEvent) => {
    if (e.lengthComputable) {
      const percentComplete = (e.loaded / e.total) * 100;
      setUploadPercent(percentComplete);
    }
  }, []);

  const transferComplete = useCallback(
    (xhr: XMLHttpRequest) => {
      setCancelBtnCallback(null);
      if (xhr.status !== 200) {
        setVariant('withError');
        setFilesErrors((prevState) => {
          let newState = prevState.filter((e) => e !== `uploadError-${blobFile.name}-${blobFile.size}`);
          newState = [...newState, `fileError-${blobFile.name}-${blobFile.size}`];
          return newState;
        });
      } else {
        const payload: ServerFileDetails = JSON.parse(xhr.response as string);
        setServerFiles((prevState) => [...prevState, payload]);
        setFilesIds((prevIds) => [...prevIds, payload.id]);

        setBlobFiles((prevState) => prevState.filter((b) => b.name !== blobFile.name));
        setFilesErrors((prevState) => {
          const newState = prevState.filter((err) => err !== `uploadError-${blobFile.name}-${blobFile.size}`);
          newState.filter((err) => err !== `fileError-${blobFile.name}-${blobFile.size}`);
          return newState;
        });
      }
    },
    [blobFile.name, blobFile.size, setBlobFiles, setFilesIds, setServerFiles, setFilesErrors],
  );

  const transferFailed = useCallback(() => {
    setVariant('withError');
    setFilesErrors((prevState) => {
      let newState = prevState.filter((e) => e !== `uploadError-${blobFile.name}-${blobFile.size}`);
      newState = [...newState, `fileError-${blobFile.name}-${blobFile.size}`];
      return newState;
    });
  }, [blobFile.name, blobFile.size, setFilesErrors]);

  const requestTimedOut = useCallback(() => {
    setVariant('withError');
    setFilesErrors((prevState) => {
      let newState = prevState.filter((e) => e !== `uploadError-${blobFile.name}-${blobFile.size}`);
      newState = [...newState, `fileError-${blobFile.name}-${blobFile.size}`];
      return newState;
    });
  }, [blobFile.name, blobFile.size, setFilesErrors]);

  const uploadFile = useCallback(() => {
    setVariant('uploading');
    setFilesErrors((prevState) => [...prevState, `uploadError-${blobFile.name}-${blobFile.size}`]);

    const formData = new FormData();
    formData.append('file', blobFile);

    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.upload.addEventListener('progress', updateProgress);
    xhr.addEventListener('load', () => transferComplete(xhr));
    xhr.addEventListener('error', transferFailed);
    xhr.addEventListener('timeout', requestTimedOut);
    xhr.timeout = timeout || DEFAULT_UPLOAD_TIMEOUT_THRESHOLD;

    const appAddress = REACT_APP_API_URL;
    xhr.open('POST', appAddress + API_ENDPOINTS.fileUpload);
    xhr.setRequestHeader('accept', 'application/json');
    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    xhr.send(formData);

    setCancelBtnCallback(() => () => {
      xhr.abort();
      setBlobFiles((prevState) => prevState.filter((b) => b.name !== blobFile.name));
    });
  }, [
    setFilesErrors,
    blobFile,
    updateProgress,
    transferFailed,
    requestTimedOut,
    timeout,
    accessToken,
    transferComplete,
    setBlobFiles,
  ]);

  useEffect(() => uploadFile(), [uploadFile]);

  const handleOnRetry = useCallback(() => {
    setUploadPercent(0);
    setVariant('uploading');
    uploadFile();
  }, [uploadFile]);

  const handleOnDelete: ButtonProps['onClick'] = useCallback(() => {
    setBlobFiles((prevState) => prevState.filter((f) => `${f.name}${f.size}` !== `${blobFile.name}${blobFile.size}`));
    setFilesErrors((prevState) => prevState.filter((err) => err !== `fileError-${blobFile.name}-${blobFile.size}`));
    dispatchBlurEvent();
  }, [blobFile.name, blobFile.size, dispatchBlurEvent, setBlobFiles, setFilesErrors]);

  return (
    <Flex variant="fileUpload.file" data-state={variant}>
      <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
        <FileName name={blobFile.name} />
        <LoadingStatus hasError={variant === 'withError'} />
      </Flex>

      <UploadingButtons showRetry={variant === 'withError'} onCancel={cancelBtnCallback} onRetry={handleOnRetry} />

      <ProgressBar hasError={variant === 'withError'} uploadPercent={uploadPercent} />

      {variant === 'withError' && <DeleteButton onClick={handleOnDelete} />}
    </Flex>
  );
};
