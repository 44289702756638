import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import { resetFormButtonAtom, schedulesSettingsAtom } from 'state/settings';

export const useSchedulesCleanUp = () => {
  const resetFormButton = useResetRecoilState(resetFormButtonAtom);
  const resetSchedulesSettings = useResetRecoilState(schedulesSettingsAtom);

  useEffect(() => {
    resetFormButton();
    resetSchedulesSettings();
  }, [resetFormButton, resetSchedulesSettings]);
};
