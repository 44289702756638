import React from 'react';
import { Flex, FlexProps, Text } from 'theme-ui';
import { RecoilState, useRecoilValue } from 'recoil';

import { DateRangeFilterAtom, filterGroupStateAtomFamily } from 'state/filters';
import { DateRangeFilters, FilterGroupNames } from 'layouts/AuthorizedApp/AsideFilters/types';
import { dateTime } from 'utils/dateTime';
import { useDateRangeFilter } from 'layouts/AuthorizedApp/AsideFilters/hooks/useDateRangeFilter';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';

type Props = Omit<FlexProps, 'variant'> & {
  dateRangeAtom?: RecoilState<DateRangeFilterAtom | null>;
  filterGroupNames?: FilterGroupNames;
};

export const DateRangeBadge = ({
  sx,
  dateRangeAtom,
  filterGroupNames = FilterGroupNames.DATE_RANGE,
  ...props
}: Props): React.ReactElement | null => {
  const dateRangeType = useRecoilValue(filterGroupStateAtomFamily(filterGroupNames));
  const { dates, multipleYearsInRangeFilter } = useDateRangeFilter(dateRangeAtom);
  const { isMobileBreakpoint } = useThemeBreakpoint();

  if (!dateRangeType || !dates) return null;

  const { endDateUnix, startDateUnix } = dates;

  const generateTitle = () => {
    if (dateRangeType.includes(DateRangeFilters.MONTH)) {
      const date = dateTime(startDateUnix, { utc: true });
      return (
        <>
          <strong>{date.format(isMobileBreakpoint ? 'MMM' : 'MMMM')} </strong>
          {date.format(isMobileBreakpoint ? 'YY' : 'YYYY')}
        </>
      );
    }

    const startDate = dateTime(startDateUnix, { utc: true });
    const endDate = dateTime(endDateUnix, {
      utc: true,
    });
    const startDateYear = startDate.format('YYYY');
    const endDateYear = endDate.format('YYYY');

    if (dateRangeType.includes(DateRangeFilters.YEAR)) {
      return startDate.format(isMobileBreakpoint ? 'YY' : 'YYYY');
    }

    const formattedStartDate = () => {
      if (multipleYearsInRangeFilter) return isMobileBreakpoint ? startDate.format('L') : startDate.format('ll');
      return isMobileBreakpoint
        ? startDate.format('l').split(startDateYear)[0].slice(0, -1)
        : startDate.format('ll').split(` ${startDateYear}`)[0].split(',')[0];
    };

    const formattedEndDate = () => {
      if (multipleYearsInRangeFilter) return isMobileBreakpoint ? endDate.format('L') : endDate.format('ll');
      return isMobileBreakpoint ? endDate.format('l').split(endDateYear)[0].slice(0, -1) : endDate.format('ll');
    };

    return `${formattedStartDate()}${isMobileBreakpoint ? '-' : ' - '}${formattedEndDate()}`;
  };

  return (
    <Flex
      sx={{
        bg: 'main.dateRangeBadge.bg',
        outline: '1px solid',
        outlineColor: 'main.dateRangeBadge.outline',
        px: 1,
        borderRadius: 'xs',
        ...sx,
      }}
      {...props}
    >
      <Text sx={{ textTransform: 'capitalize', fontSize: [2, null, null, 3], fontWeight: 'normal' }}>
        {generateTitle()}
      </Text>
    </Flex>
  );
};
