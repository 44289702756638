import { atom } from 'recoil';

type IdleState = {
  status: boolean;
  start?: () => void;
  stop?: () => void;
  reset?: () => void;
  pause?: () => void;
};

export const idleStateAtom = atom<IdleState>({
  key: 'kiosk__idleState',
  default: {
    status: false,
  },
});
