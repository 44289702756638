import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { SchedulesRenderer } from 'pages/Calendar/output/SchedulesRenderer';
import { DayStateBadge, DayStateBadgeVariants } from 'pages/Reports/output/DayStateBadge';
import { RequestsRenderer } from 'pages/Reports/output/RequestsRenderer';
import { defaultAiVariantSelector, filterByAiSchedulesTrendScale } from 'state/calendar';
import { yourCalendarSelector } from 'state/home';
import { userSessionPersonIdSelector } from 'state/userSession';

import { useCalendarDatePicker } from './CalendarDatePicker/hooks/useCalendarDatePicker';

type YourCalendarContentsProps = {
  fetchInProgress: boolean;
};

export const YourCalendarContents = ({ fetchInProgress }: YourCalendarContentsProps): React.ReactElement | null => {
  useLingui();
  const calendar = useRecoilValue(yourCalendarSelector);
  const userId = useRecoilValue(userSessionPersonIdSelector);
  const defaultAiVariant = useRecoilValue(defaultAiVariantSelector);
  const { activeDate } = useCalendarDatePicker();

  const permissions = useAppPermissions();
  const { modules } = permissions;

  if (!calendar || !userId || !activeDate) return null;

  const userCalendar = calendar.get(userId);

  if (!userCalendar) return null;

  const { days } = userCalendar;

  const dayCalendar = days.get(activeDate);

  const renderDetails = () => {
    if (dayCalendar && (dayCalendar.requests || dayCalendar.schedules)) {
      const { schedules, requests } = dayCalendar;
      const filteredSchedules = filterByAiSchedulesTrendScale(schedules, defaultAiVariant);

      return (
        <>
          {modules.Schedule && filteredSchedules && filteredSchedules.length > 0 && (
            <SchedulesRenderer schedules={filteredSchedules} userId={userId} dateUnix={+activeDate} hideActions />
          )}
          {modules.Requests && requests && requests.length > 0 && (
            <RequestsRenderer requests={requests} dateUnix={+activeDate} userId={userId} hideActions />
          )}
        </>
      );
    }

    return (
      <Flex>
        <DayStateBadge type={DayStateBadgeVariants.Annotation} isLoading={fetchInProgress}>
          {t({ id: 'reports.day_has_no_data' })}
        </DayStateBadge>
      </Flex>
    );
  };

  return <>{renderDetails()}</>;
};
