import React from 'react';
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';
import { motion, MotionProps } from 'framer-motion';

type Props = {
  size: number;
  faceInPlace: boolean;
};

const AnimatedBox = motion(Box);

export const FaceBoundingMask = ({ size, faceInPlace }: Props): React.ReactElement => {
  const faceBoundingSx: ThemeUIStyleObject = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    height: `${size}px`,
    width: `${size}px`,
    zIndex: 3,
    display: 'flex',
    flexWrap: 'wrap',
  };

  const quarterSx: ThemeUIStyleObject = {
    position: 'relative',
    flex: '1 0 50%',
  };

  const quarterAfterSx: ThemeUIStyleObject = {
    content: '""',
    display: 'block',
    position: 'absolute',
    size: faceInPlace ? '100%' : '33.33%',
    borderColor: 'kiosk.mask.border',
    borderStyle: 'solid',
    borderWidth: 0,
    margin: '-0.5rem',
    transition: 'all ease-in-out 0.25s',
    transform: 'translate3d(0, 0, 0)',
  };

  const topLeftSx: ThemeUIStyleObject = {
    ...quarterSx,
    '&::after': {
      ...quarterAfterSx,
      borderTopWidth: '0.5rem',
      borderLeftWidth: '0.5rem',
      borderTopLeftRadius: faceInPlace ? '100%' : '50%',
    },
  };
  const topRightSx: ThemeUIStyleObject = {
    ...quarterSx,
    '&::after': {
      ...quarterAfterSx,
      right: 0,
      borderTopWidth: '0.5rem',
      borderRightWidth: '0.5rem',
      borderTopRightRadius: faceInPlace ? '100%' : '50%',
    },
  };
  const bottomLeftSx: ThemeUIStyleObject = {
    ...quarterSx,
    '&::after': {
      ...quarterAfterSx,
      left: 0,
      bottom: 0,
      borderBottomWidth: '0.5rem',
      borderLeftWidth: '0.5rem',
      borderBottomLeftRadius: faceInPlace ? '100%' : '50%',
    },
  };
  const bottomRightSx: ThemeUIStyleObject = {
    ...quarterSx,
    '&::after': {
      ...quarterAfterSx,
      right: 0,
      bottom: 0,
      borderBottomWidth: '0.5rem',
      borderRightWidth: '0.5rem',
      borderBottomRightRadius: faceInPlace ? '100%' : '50%',
    },
  };

  const animationVariants: MotionProps['variants'] = {
    bounce: {
      scale: [1, 1.025],
      transition: {
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    none: {
      scale: 1,
    },
  };

  return (
    <AnimatedBox sx={faceBoundingSx} layout variants={animationVariants} animate={faceInPlace ? 'none' : 'bounce'}>
      <Flex sx={topLeftSx} />
      <Flex sx={topRightSx} />
      <Flex sx={bottomLeftSx} />
      <Flex sx={bottomRightSx} />
    </AnimatedBox>
  );
};
