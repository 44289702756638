import { useCallback, useRef, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useMount } from 'hooks/useMount/useMount';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { getAvatarDetailsAtom } from 'state/team';

export const useAvatar = (initialUrl?: string, hasChanged?: boolean) => {
  const [url, setUrl] = useState<string | undefined>(initialUrl || undefined);
  const avatarHasChangedRef = useRef<boolean>(!!hasChanged);
  const urlRef = useCallbackRef(url);
  const setGetAvatarDetails = useSetRecoilState(getAvatarDetailsAtom);
  const resetGetAvatarDetails = useResetRecoilState(getAvatarDetailsAtom);

  const clearAvatarFile = useCallback(() => {
    setUrl(undefined);
    avatarHasChangedRef.current = true;
  }, []);

  const onChange = useCallback(
    (newUrl?: string) => {
      if (!newUrl) {
        clearAvatarFile();
        return;
      }
      avatarHasChangedRef.current = true;
      setUrl(newUrl);
    },
    [clearAvatarFile],
  );

  const getAvatarInfo = useCallback(async () => {
    let avatarBlob: Blob | null = null;
    if (url && avatarHasChangedRef.current) {
      const avatarResp = await fetch(url);
      avatarBlob = await avatarResp.blob();
    }
    return {
      avatarUrl: url || undefined,
      avatarBlob,
      changed: avatarHasChangedRef.current,
    };
  }, [url]);

  const getAvatarDetails = useCallback(
    () => ({ avatarUrl: urlRef.current || '', avatarChanged: avatarHasChangedRef.current }),
    [urlRef],
  );

  useMount(() => {
    setGetAvatarDetails(() => getAvatarDetails);
  });

  useUnmount(() => {
    resetGetAvatarDetails();
  });

  return {
    avatarProps: {
      onChange,
      url,
    },
    getAvatarInfo,
  };
};
