import { useRecoilState } from 'recoil';

import { useMount } from 'hooks/useMount/useMount';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { currentlyOpenModalInstancesAtom } from 'state/modal';

export const useOpenInstanceTracker = (instanceNumber: number) => {
  const [currentlyOpenModalInstances, setCurrentlyOpenModalInstances] = useRecoilState(currentlyOpenModalInstancesAtom);

  useMount(() => setCurrentlyOpenModalInstances([...currentlyOpenModalInstances, instanceNumber]));

  useUnmount(() => {
    const filteredInstances = [...currentlyOpenModalInstances].filter((instance) => instance !== instanceNumber);
    setCurrentlyOpenModalInstances(filteredInstances);
  });
};
