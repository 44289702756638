export const API_ENDPOINTS = {
  // AUTHENTICATION
  authorize: '/connect/token',
  refreshAccessToken: '/connect/refresh',
  signOut: '/connect/revoke',
  signUp: '/user',
  signUpEmployee: '/User/InvitationRegister',
  invitationResend: '/User/RenewInvitationRequest',
  invitationJoinRequest: '/User/InviteRequest',
  accountInfo: '/organization',
  // PASSWORD RECOVERY
  forgotPassword: '/user/forgotPassword',
  resetPassword: '/user/resetPassword',
  // INTEGRATIONS
  integrations: '/integrations',
  integrationsConnectPayroll: '/integrations/connectPayroll',
  integrationsDisconnect: '/integrations/disconnect',
  integrationsRefreshToken: '/integrations/refreshToken',
  externalProvider: '/externalProvider',
  externalProviderDisconnect: '/externalProvider/disconnect',
  googleLink: '/ExternalProvider/google/link',
  googleLogin: '/ExternalProvider/google/login',
  appleLink: '/ExternalProvider/apple/link',
  appleLogin: '/ExternalProvider/apple/login',
  quickbooks: '/quickbooks',
  quickbooksCallback: '/quickbooks/callback',
  quickbooksDisconnect: '/quickbooks/disconnect',
  googleCallback: '/google/callback',
  xero: '/xero',
  xeroCallback: '/xero/callback',
  square: '/square',
  squareCallback: '/square/callback',
  // WEBHOOK
  webhook: '/webhook',
  webhookGetAvailable: '/webhook/getAvailable',
  webhookAdd: '/webhook/add',
  webhookEdit: '/webhook/edit',
  webhookDelete: '/webhook/delete',
  // USER_SESSION
  userSession: '/session/userSession',
  viewsSettings: '/viewsSettings',
  // ORGANIZATION_SESSION
  organizationSession: '/session/organizationSession',
  // EMPLOYEES
  employee: '/employee',
  employeeManagement: '/employeeManagement',
  employeeActivate: '/employeeManagement/activate',
  employeeDeactivate: '/employeeManagement/deactivate',
  employeeInvite: '/employeeManagement/invite',
  employeeImportPreview: '/EmployeeManagement/ImportPreview',
  employeeImport: '/EmployeeManagement/Import',
  printQr: '/employee/printQr',
  setWorkPosition: '/workPosition/setWorkPositions',
  clearWorkPositions: '/workPosition/clearWorkPositions',
  setTags: '/tagManagement/setTags',
  clearTags: '/tagManagement/clearTags',
  setPermissions: '/permissions/updatePeople',
  setRole: '/role/updateUsersRole',
  setPayDetails: '/employeeManagement/setPayDetails',
  setTimeOffLimits: '/employeeManagement/setTimeOffLimits',
  setRequestsLimits: '/employeeManagement/setRequestsLimits',
  // TAGS
  tag: '/tag',
  tagManagement: '/tagManagement',
  tagInheritedFeatures: '/employeeManagement/getDefaultFeatures',
  // ROLES
  role: '/role',
  updateUsersRole: '/role/updateUsersRole',
  // WORK_POSITIONS
  workPosition: '/workPosition',
  setWorkPositionsStates: '/workPosition/setWorkPositionState',
  // TEAM
  timeZoneList: '/timeZone',
  industryList: '/organization/industries',
  usersResource: '/users/resource',
  // PAYMENTS
  paymentInfo: '/payment/paymentInfo',
  paymentCalculationInfo: '/payment/calculationInfo',
  paymentUnpaidBalance: '/payment/unpaidBalance',
  paymentProcess: '/payment/process',
  notifyPayment: '/payment/notifyPayment',
  paymentActivate: '/payment/activate',
  paymentCancel: '/payment/cancel',
  paymentCancelSubscription: '/payment/cancelSubscription',
  paymentResumeSubscription: '/payment/resumeSubscription',
  updatePaymentMethod: '/payment/updatePaymentMethod',
  removePaymentMethod: '/payment/removePaymentMethod',
  cancelPendingPayment: '/payment/cancelPendingPayment',
  // PAYMENT_INTEGRATION
  stripeClientData: '/paymentIntegration/stripeClientData',
  braintreeClientData: '/paymentIntegration/braintreeClientData',
  // INVOICE
  invoiceBillingHistory: '/invoice/billingHistory',
  invoiceFile: '/invoice/file',
  // ORGANIZATION_SETTINGS
  schedulesSettings: '/schedulesSettings',
  notificationSettings: '/notificationSettings',
  timeTrackingSettings: '/timeTrackingSettings',
  organizationSettings: '/organizationSettings',
  organizationSettingsChangeAvatar: '/organizationSettings/changeAvatar',
  organizationSettingsDeleteAvatar: '/organizationSettings/deleteAvatar',
  // HOLIDAYS
  holiday: '/holiday',
  importLocations: '/holiday/importLocations',
  importPreviewHolidays: '/holiday/importPreview',
  importHolidays: '/holiday/import',
  // TIME_OFF_TYPE
  timeOffType: '/timeOffType',
  setTimeOffTypesState: '/timeOffType/setTimeOffTypesState',
  // CUSTOM_REQUEST_TYPE
  customRequestType: '/customRequestType',
  setCustomRequestTypesState: '/customRequestType/setCustomRequestTypesState',
  // REQUESTS_SETTINGS
  requestSettings: '/requestsSettings',
  // AUTOMATIONS_SETTINGS
  automationsSettings: '/automationSettings',
  // ADVANCED_SETTINGS
  advancedOrganizationSettingsSaveBetaSettings: '/advancedOrganizationSettings/SaveBetaSettings',
  advancedOrganizationSettingsDeleteOrganization: '/advancedOrganizationSettings/deleteOrganization',
  advancedOrganizationSettingsDeletePhotos: '/advancedOrganizationSettings/deletePhotos',
  advancedOrganizationSettingsDeleteFaceModels: '/advancedOrganizationSettings/deleteFaceModels',
  deleteEmployeesPhotos: '/advancedOrganizationSettings/deleteEmployeesPhotos',
  deleteEmployeesFaceModels: '/advancedOrganizationSettings/deleteEmployeesFaceModels',
  // USER_SETTINGS
  userInfo: '/userInfo',
  userInfoChangeAvatar: '/userInfo/changeAvatar',
  userInfoDeleteAvatar: '/userInfo/deleteAvatar',
  userPreferences: '/userPreferences',
  userNotifications: '/userNotificationSettings',
  userChangePassword: '/user/changePassword',
  userSetPassword: '/user/setPassword',
  // ZUS
  zusCredentials: '/zus/credentials',
  zusManualImport: '/zus/manualImport',
  zusConnect: '/zus/connect',
  // KIOSK
  timeclock: {
    getSettings: '/settings/kiosk',
    getInfo: '/WebTimeClock/verification',
    submitTimeEvent: '/WebTimeClock/submit',
    initPersonModel: '/user/face',
  },
  // CHAT
  signalR: '/hubs/chat',
  chat: {
    chatUsers: '/Chat/Users',
    messages: '/Chat/UserChats',
    message: '/Chat',
    deleteMessage: '/Chat/DeleteMessage',
    hasBeenRead: '/Chat/MarkAsRead',
  },
  // FILE UPLOAD
  file: '/file',
  fileUpload: '/file/upload',
  // DRAWER
  attendancePill: '/attendanceOverview',
  timeTracker: '/OnlineTimeClock/verification',
  timeEventSubmit: '/OnlineTimeClock/submit',
  // NOTIFICATIONS
  notification: '/notification',
  // REQUESTS
  request: '/request',
  requestsList: '/request/list',
  requestsGetByIds: '/request/getByIds',
  timeOffRemainingLimits: '/request/getTimeOffRemainingLimits',
  customRemainingLimits: '/request/getCustomRemainingLimits',
  requestOverview: '/request/getUsageForTimeRange',
  availableOvertime: '/request/getAvailableOvertime',
  addRequest: '/request/add',
  addWithAcceptation: '/requestManagement/addWithAcceptation',
  getApprovalLists: '/request/getApprovalLists',
  deleteRequest: '/request/delete',
  rejectRequestManagement: '/requestManagement/reject',
  acceptRequestManagement: '/requestManagement/accept',
  requestGetToUpdate: '/request/getToUpdate',
  printRequests: '/request/print',
  getTimeTrackingToUpdate: '/request/getTimeTrackingToUpdate',
  getCurrentUserRequestLimits: '/Request/LimitsForPerson',
  getAllToAccept: '/Request/GetAllToAccept',
  limitDetails: '/request/limitDetails',
  getSchedulesToSwap: '/request/getSchedulesToSwap',
  acceptSwap: '/request/acceptSwap',
  rejectSwap: '/request/rejectSwap',
  addSwapRequest: '/request/addSwapRequest',
  getPeopleToSwap: '/request/getPeopleToSwap',
  requestsGetForPersonForRangeAndType: '/request/getForPersonForRangeAndType',
  // WORK STATUSES
  workStatuses: '/timeEventType',
  // LOCATION
  location: '/location',
  addLocation: '/locationManagement/add',
  editLocation: '/locationManagement/edit',
  deleteLocation: '/locationManagement',
  singleLocation: '/location/show/',
  // TIME EVENTS
  getAntiSpoofingChecks: '/timeEvent/antiSpoofingChecks',
  getTimeEventsCalendar: '/timeEvent/calendar',
  getTimeEventDetails: '/timeEvent/getDetails',
  addTimeEventNote: '/timeEvent/addNote',
  deleteTimeEvents: '/timeEventManagement',
  addTimeEvents: '/timeEventManagement/add',
  editTimeEvent: '/timeEventManagement/edit',
  removeTimeEvents: '/timeEventManagement/remove',
  restoreTimeEvents: '/timeEventManagement/restore',
  timeEventHistory: '/timeEvent/history',
  setFraudResolutionState: '/FraudResolution/SetState',
  getFraudResolutionPendingData: '/FraudResolution/GetPendingTimeEvents',
  getPhotos: '/timeEvent/getPhotos',
  // EXPORT
  getExportOptions: '/export/getExportOptions',
  export: '/export/export',
  getExportForIntegrationOptions: '/export/getExportForIntegrationOptions',
  exportForIntegration: '/export/exportForIntegration',
  exportTimeActivityPreview: '/export/exportTimeActivityPreview',
  exportTimeActivity: '/export/exportTimeActivity',
  exportSelectedItems: '/export/exportSelectedItems',
  exportToICalUrl: '/export/exportToICalUrl',
  // REPORT
  report: {
    getAll: '/report',
    getForIds: '/report/ForIds',
  },
  // TIME CLOCKS
  device: '/device',
  // CALENDAR
  calendar: {
    getAll: '/Calendar',
    getForIds: '/Calendar/ForIds',
    publish: '/Schedule/Publish',
    revertPublish: '/Schedule/Revert',
    deleteSchedule: '/Schedule',
    deleteAllSchedules: '/Schedule/DeleteAll',
    deleteAllAiSchedules: '/Schedule/DeleteAi',
    addSchedule: '/Schedule',
    editSchedule: '/Schedule',
    wizard: '/Schedule/AddRange',
    copyToOther: '/Schedule/CopyToOther',
    dragSchedule: '/Schedule/Drag',
    generateAiSchedules: '/Schedule/PlanAiSchedules',
  },
  // WORKDAY AVAILABILITY
  workdayAvailabilityAdd: '/workdayAvailability/add',
  workdayAvailabilityResetAvailability: '/workdayAvailability/resetAvailability',
  // ONBOARDING
  onboarding: {
    setAsShown: '/Onboarding/SetAsShowed',
  },
  // DATA
  countryDetailsList: '/country',
  // PZU SPORT
  pzuSportRegistration: '/benefits/registerPzuSport',
};
