import { matchPath, useLocation } from 'react-router-dom';
import { SetterOrUpdater, atomFamily, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import React, { useEffect } from 'react';

import { LanguageRoute, MATCH_ALL, PATH } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { typedKeys } from 'utils/typedKeys';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';

const MAIN_PAGES = {
  // MAIN_PAGES
  isHome: PATH.START,
  isTeam: PATH.TEAM,
  isCalendar: PATH.CALENDAR,
  isRequests: PATH.REQUESTS,
  isClockLog: PATH.TIME_TRACKING__CLOCK_LOG,
  isWorkStatuses: PATH.TIME_TRACKING__WORK_STATUSES,
  isLocations: PATH.TIME_TRACKING__LOCATIONS,
  isTimeClocks: PATH.TIME_TRACKING__TIME_CLOCKS,
  isReports: PATH.REPORTS__TYPE,
  isSettings: PATH.SETTINGS,
  isPayments: PATH.PAYMENTS,
  isPopup: PATH.POPUP__TYPE,
};
const DEPENDENT_PAGES = {
  isCalendar: {
    isCalendarAll: PATH.CALENDAR__ALL,
    isCalendarSchedules: PATH.CALENDAR__SCHEDULES,
    isCalendarAvailability: PATH.CALENDAR__AVAILABILITY,
    isCalendarRequests: PATH.CALENDAR__REQUESTS,
    isDayDetails: PATH.PAGE__TYPE__START_DATE_UNIX__END_DATE_UNIX__DETAILS__USER_ID__DATE_UNIX,
  },
  isRequests: {
    isRequestsGrouped: PATH.REQUESTS__GROUPED,
    isRequestsUngrouped: PATH.REQUESTS__UNGROUPED,
    isRequestsPending: PATH.REQUESTS__PENDING,
    isRequestsUsageOverview: PATH.REQUESTS__USAGE_OVERVIEW,
    isTypeDetails: PATH.PAGE__USAGE_OVERVIEW__START_DATE_UNIX__END_DATE_UNIX__DETAILS__USER_ID__REQUEST_TYPE__TYPE_ID,
  },
  isClockLog: {
    isClockLogAll: PATH.CLOCK_LOG__ALL,
    isClockLogPhotoLog: PATH.CLOCK_LOG__PHOTO_LOG,
    isClockLogModified: PATH.CLOCK_LOG__MODIFIED,
    isClockLogPending: PATH.CLOCK_LOG__PENDING,
    isClockLogAntiSpoofing: PATH.CLOCK_LOG__AI_ANTI_SPOOFING,
    isClockLogAiRejected: PATH.CLOCK_LOG__AI_REJECTED,
    isClockLogDeleted: PATH.CLOCK_LOG__DELETED,
  },
  isReports: {
    isReportsAttendanceList: PATH.REPORTS__ATTENDANCE_LIST,
    isReportsTimesheets: PATH.REPORTS__TIMESHEETS,
    isDayDetails: PATH.PAGE__TYPE__START_DATE_UNIX__END_DATE_UNIX__DETAILS__USER_ID__DATE_UNIX,
  },
};

const MOBILE_PAGES = {
  isMobileChat: {
    en: `${MATCH_ALL}${PATH.MOBILE_CHAT.en}`,
    pl: `${MATCH_ALL}${PATH.MOBILE_CHAT.pl}`,
  } as LanguageRoute,
};

const ALL_PAGES = {
  ...MAIN_PAGES,
  ...DEPENDENT_PAGES.isCalendar,
  ...DEPENDENT_PAGES.isClockLog,
  ...DEPENDENT_PAGES.isReports,
  ...DEPENDENT_PAGES.isRequests,
  ...MOBILE_PAGES,
};

type AllAppRouting = typeof ALL_PAGES;
export type AppRouting = {
  [K in keyof AllAppRouting]: boolean;
};

export const appRoutingFlagAtomFamily = atomFamily<boolean, keyof AppRouting>({
  key: 'appRoutingFlagAtom',
  default: false,
});

export const appRoutingSelectorFamily = selectorFamily<Partial<AppRouting>, (keyof AppRouting)[]>({
  key: 'appRoutingSelector',
  get:
    (requestedAppRoutingFlags) =>
    ({ get }) =>
      _.transform(
        requestedAppRoutingFlags,
        (result, flag) => {
          result[flag] = get(appRoutingFlagAtomFamily(flag));
        },
        {} as Partial<AppRouting>,
      ),
});

let lastMainPageMatchKey: keyof typeof ALL_PAGES;

export const AppRoutingObserver = React.memo(() => {
  const language = useRecoilValue(languageSelector);
  const { isMobileBreakpoint } = useThemeBreakpoint();
  const { pathname } = useLocation();

  const appRoutingFlagAtomSetters = (() => {
    const setters: { [K in keyof typeof ALL_PAGES]: SetterOrUpdater<boolean> } = {} as {
      [K in keyof typeof ALL_PAGES]: SetterOrUpdater<boolean>;
    };
    typedKeys(ALL_PAGES).forEach((key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setters[key] = useSetRecoilState(appRoutingFlagAtomFamily(key));
    });
    return setters;
  })();

  const appRoutingFlagAtomSettersRef = useCallbackRef(appRoutingFlagAtomSetters);
  const isMobileBreakpointRef = useCallbackRef(isMobileBreakpoint);

  useEffect(() => {
    let mainPagesMatchKey: keyof typeof ALL_PAGES | null = null;

    let dependentPages: typeof ALL_PAGES | null = null;
    typedKeys(MAIN_PAGES).forEach((key) => {
      const setter = appRoutingFlagAtomSettersRef.current[key];

      if (key === 'isHome') {
        return;
      }

      if (mainPagesMatchKey) {
        if (lastMainPageMatchKey === key) {
          setter(false);
        }

        return;
      }
      const match = matchPath(`${MAIN_PAGES[key][language]}/*`, pathname);

      if (match) {
        mainPagesMatchKey = key;

        if (key !== lastMainPageMatchKey) {
          setter(true);
        }

        dependentPages = DEPENDENT_PAGES[key as keyof typeof DEPENDENT_PAGES] as AllAppRouting;

        return;
      }

      if (lastMainPageMatchKey !== key) return;

      setter(false);
    });

    const isHome = !mainPagesMatchKey;
    appRoutingFlagAtomSettersRef.current.isHome(isHome);

    const lastDependentPage =
      lastMainPageMatchKey && lastMainPageMatchKey !== mainPagesMatchKey
        ? (DEPENDENT_PAGES[lastMainPageMatchKey as keyof typeof DEPENDENT_PAGES] as AllAppRouting)
        : null;

    typedKeys({
      ...((dependentPages as unknown as AllAppRouting) && (dependentPages as unknown as AllAppRouting)),
      ...(lastDependentPage && lastDependentPage),
      ...(isMobileBreakpointRef.current && MOBILE_PAGES),
    }).forEach((key) => {
      const setter = appRoutingFlagAtomSettersRef.current[key];

      const dependentMatch = !!matchPath(`${ALL_PAGES[key][language]}/*`, pathname);

      setter(dependentMatch);
    });

    lastMainPageMatchKey = isHome ? 'isHome' : (mainPagesMatchKey as unknown as keyof typeof ALL_PAGES);
  }, [pathname, language, appRoutingFlagAtomSettersRef, isMobileBreakpointRef]);

  return null;
});

const ALL = typedKeys(ALL_PAGES);

export const useAppRouting = (subscribeTo?: (keyof AppRouting)[]) => {
  const subscriptions = useRecoilValue(appRoutingSelectorFamily(subscribeTo || ALL));

  return {
    ..._.mapValues(ALL_PAGES, () => false),
    ...subscriptions,
  };
};
