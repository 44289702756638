import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useMemo } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { restoreTimeEventsAction } from 'api/actions/timeEvent/timeEventActions';
import {
  ExtendedTimeEvent,
  FraudDetectionState,
  TimeEventActionResponse,
  TimeEventState,
} from 'api/actions/timeEvent/timeEventActions.types';
import { ClockLogHideableColumns } from 'api/actions/userSession/userSessionActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Avatar } from 'components/Avatar/Avatar';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { LoadingSpinnerSize } from 'components/Loading/LoadingSpinnerCSS';
import { ListNames, StickyListProps } from 'components/StickyList/types';
import { LocationButton } from 'components/recipes/LocationButton';
import { ModifiedIconButton } from 'components/recipes/ModifiedIconButton';
import { SendViaBadge, SendViaBadgeProps, generateSendViaBadgeLabel } from 'components/recipes/SendViaBadge';
import { WorkStatusBadge } from 'components/recipes/WorkStatusBadge';
import { Button, LinkButton } from 'components/ui/Buttons';
import { Image } from 'components/ui/Image';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { WORK_STATUS_TYPE_ID } from 'constants/clockLog';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { useDateRangeFilter } from 'layouts/AuthorizedApp/AsideFilters/hooks/useDateRangeFilter';
import { useRequestsLogic } from 'pages/Requests/output/useRequestsLogic';
import { nonEditableEmployeesForModulesIdsSelectorFamily } from 'state/employees';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { languageSelector, windowSizeAtom } from 'state/recoilState';
import { Step } from 'state/requests';
import { userSessionPersonIdSelector } from 'state/userSession';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';
import { getEventFormattedDateTime } from '../../../../utils/getEventFormattedDateTime';
import { useRefreshClockLog } from '../../../modals/hooks/useRefreshClockLog';
import { BusinessCard } from '../components/BusinessCard';
import { dateTimeKey } from '../components/ClockLogList';
import { ClockLogStatusIcon } from '../components/ClockLogStatusIcon';
import { NotePopper } from '../components/NotePopper';

const ButtonWithDropdown = withDropdown(Button);
const IconWithTooltip = withTooltip(Icon);

export const WORK_STATUS_SEND_VIA_ICON_TYPE: Record<string, SendViaBadgeProps['icon']> = {
  [WORK_STATUS_TYPE_ID.MOBILE_APP]: 'mobileApp',
  [WORK_STATUS_TYPE_ID.WEB_APP]: 'webApp',
  [WORK_STATUS_TYPE_ID.WEB_KIOSK]: 'kiosk',
  [WORK_STATUS_TYPE_ID.MANUALLY_ADDED]: 'system',
};

export const useGenerateClockLogColumns = () => {
  const navigate = useAppNavigate();
  useLingui();

  const nonEditableEmployeesIds = useRecoilValue(nonEditableEmployeesForModulesIdsSelectorFamily('TimeTracking'));

  const language = useRecoilValue(languageSelector);
  const currentUserId = useRecoilValue(userSessionPersonIdSelector);
  const setSelectedEventIds = useSetRecoilState(selectedRowsIdsSelectorFamily(ListNames.CLOCK_LOG));

  const getFullName = useNameDisplayOrder();

  const { inTransition: multipleYearsInRangeFilter } = useDateRangeFilter();

  const { modulesManagement, modules, allowChangeRequestForLoggedClockInsAndOuts } = useAppPermissions();

  const { isMobileBreakpoint, breakpoint } = useThemeBreakpoint();
  const windowSize = useRecoilValue(windowSizeAtom);
  const {
    actions: { approveRequest },
  } = useRequestsLogic();
  const { refreshClockLogForPeopleIds } = useRefreshClockLog();

  const { mutate: mutateRestoreEvents } = useMutation(restoreTimeEventsAction);

  const columns = useMemo(() => {
    const clockLogColumns: StickyListProps<ExtendedTimeEvent, ClockLogHideableColumns>['columns'] = [
      {
        key: ['id', 'photoUrl', 'employeeName', 'avatarUrl', 'fraudDetectionState'],
        width: '40px',
        sx: {
          justifyContent: 'center',
        },
        customCellRenderer: ([id, photoUrl, employeeName, avatarUrl, fraudDetectionState]: [
          ExtendedTimeEvent['id'],
          ExtendedTimeEvent['photoUrl'],
          ExtendedTimeEvent['employeeName'],
          ExtendedTimeEvent['avatarUrl'],
          ExtendedTimeEvent['fraudDetectionState'],
        ]) => (
          <>
            {photoUrl ? (
              <LinkButton
                to={`${TO_REL.SHOW_EVENT_PHOTO_MODAL__ID[language]}/${id}`}
                onClick={(e) => e.stopPropagation()}
                variant="naked"
                sx={{
                  p: 0,
                  position: 'relative',
                  '&:hover::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3))`,
                  },
                }}
              >
                <Image
                  src={photoUrl}
                  loadingOverlaySize={LoadingSpinnerSize.imageXS}
                  sx={{
                    height: '40px',
                    width: '40px',
                    objectFit: 'cover',
                    borderRadius: 'xs',
                    ...(!_.isUndefined(fraudDetectionState) && {
                      background: 'calendar.schedule.bg.ai.default',
                      padding: '2px',
                    }),
                  }}
                  alt={t({ id: 'clock_log.time_event', message: 'Time Event' })}
                />
              </LinkButton>
            ) : (
              <Avatar image={avatarUrl} name={employeeName} size={32} />
            )}
          </>
        ),
      },

      {
        title: t({ id: 'clock_log.name_role_tags', message: 'Name, Role & Tags' }),
        key: ['employeeName', 'role', 'tags'],
        sx:
          breakpoint === MEDIA_BREAKPOINTS.LG
            ? { maxWidth: '200px', width: '200px', minWidth: '200px' }
            : { maxWidth: '360px', width: '360px', minWidth: '360px' },
        sortableValue: ([{ firstName, surname }]: [
          ExtendedTimeEvent['employeeName'],
          ExtendedTimeEvent['role'],
          ExtendedTimeEvent['tags'],
        ]) => getFullName(firstName, surname),
        customCellRenderer: ([employeeName, role, tags]: [
          ExtendedTimeEvent['employeeName'],
          ExtendedTimeEvent['role'],
          ExtendedTimeEvent['tags'],
        ]) => <BusinessCard employeeName={employeeName} personRole={role} tags={tags} />,
      },

      {
        title: t({ id: 'clock_log.date_time', message: 'Date & Time' }),
        key: dateTimeKey,
        sortableValue: ([timeUtc, timeRealUtc]: [ExtendedTimeEvent['timeUtc'], ExtendedTimeEvent['timeRealUtc']]) =>
          timeRealUtc ? Number(`${timeUtc}.${timeRealUtc}`) : timeUtc,
        customCellRenderer: ([timeUtc, timeRealUtc]: [
          ExtendedTimeEvent['timeUtc'],
          ExtendedTimeEvent['timeRealUtc'],
        ]) => {
          const { eventDate, eventTime } = getEventFormattedDateTime(timeUtc, multipleYearsInRangeFilter);
          const date = `${eventDate}, ${eventTime}`;
          const { eventTime: eventRealTime } = getEventFormattedDateTime(timeRealUtc);

          return (
            <Flex sx={{ flexDirection: 'column', lineHeight: 1.1, ml: 'auto' }}>
              <TextEllipsis title={date}>{date}</TextEllipsis>

              {timeRealUtc && (
                <TextEllipsis title={eventRealTime} sx={{ textAlign: 'end', fontWeight: 'normal', lineHeight: 1 }}>
                  {eventRealTime}
                </TextEllipsis>
              )}
            </Flex>
          );
        },
        customHeaderRenderer: (title) => <Text sx={{ alignSelf: 'center', ml: 'auto' }}>{title}</Text>,
        sx: { maxWidth: '120px', width: '120px', minWidth: '120px' },
      },
      {
        title: t({ id: 'clock_log.type', message: 'Type' }),
        key: ['typeName', 'typeId', 'isEnd', 'location', 'coordinates', 'id', 'note'],
        sortableValue: ([typeName]: [ExtendedTimeEvent['typeName']]) => t({ id: `${typeName}` }),
        customCellRenderer: ([typeName, typeId, isEnd, location, coordinates, id, note]: [
          ExtendedTimeEvent['typeName'],
          ExtendedTimeEvent['typeId'],
          ExtendedTimeEvent['isEnd'],
          ExtendedTimeEvent['location'],
          ExtendedTimeEvent['coordinates'],
          ExtendedTimeEvent['id'],
          ExtendedTimeEvent['note'],
        ]) => {
          const hasCoordinates = !!coordinates?.latitude;
          const hasLocation = !!location?.id;

          const textLength = (() => {
            if (windowSize && windowSize?.width && windowSize.width > 1400) return 32;
            if (isMobileBreakpoint) return 19;
            if (breakpoint === MEDIA_BREAKPOINTS.LG) return 16;
            if (breakpoint === MEDIA_BREAKPOINTS.XL) return 25;
            return 16;
          })();

          return (
            <Flex sx={{ flexDirection: 'column', flexGrow: 1, ...(note && { gap: 1 }) }}>
              <Flex sx={{ gap: 1 }}>
                <WorkStatusBadge workStatus={{ id: typeId, name: typeName }} isEnd={isEnd} sx={{ minWidth: '55px' }} />
                {(hasCoordinates || hasLocation) && (
                  <LocationButton
                    to={`${TO_REL.SHOW_LOCATION_MODAL__ID[language]}/${hasCoordinates ? id : location?.id}`}
                    onClick={(e) => e.stopPropagation()}
                    title={location?.name || t({ id: 'clock_log.map' })}
                    {...(!location?.name && { sx: { pr: 0 } })}
                  >
                    {location?.name && (
                      <TextEllipsis>{_.truncate(location?.name, { length: textLength })}</TextEllipsis>
                    )}
                  </LocationButton>
                )}
              </Flex>
              {note && <NotePopper note={note} />}
            </Flex>
          );
        },
      },
      {
        title: t({ id: 'aside_filters.send_via' }),
        customHeaderRenderer: (value) => <Flex sx={{ justifyContent: 'flex-end', flexGrow: 1 }}>{value}</Flex>,
        key: ['device', 'fraudDetectionState', 'faceModelState', 'state', 'action', 'id', 'isOffline'],
        sortableValue: ([device]: [ExtendedTimeEvent['device']]) =>
          generateSendViaBadgeLabel(
            (device?.id && WORK_STATUS_SEND_VIA_ICON_TYPE[device.id]) || 'timeClockMobile',
            device?.name,
          ),
        customCellRenderer: ([device, fraudDetectionState, faceModelState, state, action, id, isOffline]: [
          ExtendedTimeEvent['device'],
          ExtendedTimeEvent['fraudDetectionState'],
          ExtendedTimeEvent['faceModelState'],
          ExtendedTimeEvent['state'],
          ExtendedTimeEvent['action'],
          ExtendedTimeEvent['id'],
          ExtendedTimeEvent['isOffline'],
        ]) => {
          const isDeleted = state === TimeEventState.Deleted;
          const isSystem = device?.id === WORK_STATUS_TYPE_ID.MANUALLY_ADDED;
          const isModified = action === TimeEventActionResponse.Modified;

          const handleOnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            e.stopPropagation();
            navigate(TO_REL.SHOW_EVENT_HISTORY_MODAL[language], {
              state: {
                id,
                ...(isSystem && !isModified && { system: true }),
              },
            });
          };

          return (
            <Flex sx={{ gap: '2px' }}>
              {isOffline && (
                <IconWithTooltip
                  tooltip={t({ id: 'clock_log.offline_mode', message: 'Offline event' })}
                  size={24}
                  type="cloudOff"
                  fill="alphas.darker8"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bg: 'whites0',
                    border: '1px solid',
                    borderColor: 'alphas.darker4',
                    borderRadius: 'xs',
                  }}
                />
              )}
              {(!_.isUndefined(fraudDetectionState) || !_.isUndefined(faceModelState)) && (
                <ClockLogStatusIcon fraudDetectionState={fraudDetectionState} faceModelState={faceModelState} />
              )}
              <SendViaBadge
                icon={(device?.id && WORK_STATUS_SEND_VIA_ICON_TYPE[device.id]) || 'timeClockMobile'}
                deviceName={device?.name}
              />
              {isModified && !isDeleted && <ModifiedIconButton onClick={handleOnClick} />}
              {isDeleted && <ClockLogStatusIcon isDeleted />}
            </Flex>
          );
        },
        sx: { justifyContent: 'flex-end', maxWidth: 'fit-content' },
      },
      {
        key: ['id', 'note', 'personId', 'fraudDetectionState', 'request', 'state'],
        width: '37px',
        customCellRenderer: ([id, note, personId, fraudDetectionState, request, state]: [
          ExtendedTimeEvent['id'],
          ExtendedTimeEvent['note'],
          ExtendedTimeEvent['personId'],
          ExtendedTimeEvent['fraudDetectionState'],
          ExtendedTimeEvent['request'],
          ExtendedTimeEvent['state'],
        ]) => {
          const isCurrentUserRequest = currentUserId === personId;

          const displayNote = !note && (isCurrentUserRequest || modulesManagement.TimeTracking);
          const displayRequest =
            ((modules.Requests && (isCurrentUserRequest || !nonEditableEmployeesIds)) || modulesManagement.Requests) &&
            allowChangeRequestForLoggedClockInsAndOuts;

          const displayDivider = displayNote || displayRequest;

          const disableEventActions = (() => !!request)();

          const dropdownLinks = [
            ...(request && modulesManagement.Requests
              ? [
                  {
                    label: t({ id: 'requests.button.approve' }),
                    icon: 'approve' as Icons,
                    isButton: true,
                    onClick: () => approveRequest(request.id, personId, false),
                  },
                  {
                    to: TO_REL.REJECT_REQUESTS_MODAL[language],
                    state: {
                      id: request.id,
                      ids: [personId],
                      requestNumber: request.number,
                    },
                    label: t({ id: 'requests.button.reject' }),
                    icon: 'deny' as Icons,
                  },
                ]
              : []),
            ...(displayNote &&
            fraudDetectionState !== FraudDetectionState.PotentialFraud &&
            displayNote &&
            fraudDetectionState !== FraudDetectionState.ResolvedFraud &&
            state !== TimeEventState.Deleted
              ? [
                  {
                    to: `${TO_REL.ADD_EVENT_NOTE_MODAL__ID[language]}/${id}`,
                    label: t({ id: 'clock_log.add_note', message: 'Add note' }),
                    icon: 'note' as Icons,
                    prependWithDivider: disableEventActions && modulesManagement.Requests,
                    disabled: disableEventActions,
                  },
                ]
              : []),
            ...(displayRequest &&
            fraudDetectionState !== FraudDetectionState.PotentialFraud &&
            fraudDetectionState !== FraudDetectionState.DetectedFraud &&
            fraudDetectionState !== FraudDetectionState.ResolvedFraud &&
            state !== TimeEventState.Deleted
              ? [
                  {
                    to: TO_REL.ADD_REQUEST_MODAL[language],
                    state: {
                      requestConfig: {
                        action: RequestActionType.Edit,
                        employeesIds: [personId],
                        excludedSteps: [Step.SelectTeammates, Step.SelectType],
                        requestManagement: {
                          id,
                          isEvent: true,
                        },
                        requestType: RequestFormType.TimeTracking,
                      },
                    },
                    label: t({ id: 'clock_log.request_change', message: 'Request change' }),
                    icon: 'requests' as Icons,
                    prependWithDivider: disableEventActions && !displayNote,
                    disabled: disableEventActions,
                  },
                ]
              : []),
            ...(modulesManagement.TimeTracking && state !== TimeEventState.Deleted
              ? [
                  {
                    to: TO_REL.DELETE_EVENTS_MODAL[language],
                    state: { ids: [id] },
                    label: t({ id: 'clock_log.delete', message: 'Delete' }),
                    icon: 'delete' as Icons,
                    prependWithDivider:
                      displayDivider &&
                      fraudDetectionState !== FraudDetectionState.PotentialFraud &&
                      displayDivider &&
                      fraudDetectionState !== FraudDetectionState.ResolvedFraud,
                    disabled: disableEventActions,
                  },
                ]
              : []),
            ...(modulesManagement.TimeTracking && state === TimeEventState.Deleted
              ? [
                  {
                    to: TO_REL.DELETE_PERMANENTLY_EVENT_MODAL[language],
                    state: { ids: [id] },
                    label: t({ id: 'clock_log.delete_permanently', message: 'Delete permanently' }),
                    icon: 'delete' as Icons,
                  },
                  {
                    label: t({ id: 'clock_log.restore' }),
                    icon: 'undo' as Icons,
                    isButton: true,
                    onClick: async () => {
                      const { error } = await mutateRestoreEvents([id]);

                      if (!error) {
                        addSnackbar({
                          message: t({ id: 'clock_log.deleted_events.restored' }),
                          variant: 'success',
                        });
                        void refreshClockLogForPeopleIds([personId]);
                        setSelectedEventIds((prev) => prev.filter((eventId) => eventId !== id));
                      }
                    },
                  },
                ]
              : []),
          ];

          return dropdownLinks.length > 0 ? (
            <Flex sx={{ gap: 2 }}>
              <ButtonWithDropdown
                key={id}
                dropdownProps={{
                  links: dropdownLinks,
                }}
                size="sm"
                variant="minimal"
                shape="rounded"
                onClick={(e) => e.stopPropagation()}
                popperProps={{
                  contextMenuId: id,
                }}
              >
                <Icon type="more" />
              </ButtonWithDropdown>
            </Flex>
          ) : null;
        },
      },
    ];

    return clockLogColumns;
  }, [
    language,
    getFullName,
    multipleYearsInRangeFilter,
    windowSize,
    isMobileBreakpoint,
    breakpoint,
    navigate,
    currentUserId,
    modulesManagement.TimeTracking,
    modulesManagement.Requests,
    modules.Requests,
    nonEditableEmployeesIds,
    allowChangeRequestForLoggedClockInsAndOuts,
    approveRequest,
    mutateRestoreEvents,
    refreshClockLogForPeopleIds,
    setSelectedEventIds,
  ]);

  return columns;
};
