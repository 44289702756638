import { Trans, t } from '@lingui/macro';
import { isUndefined } from 'lodash';
import { FC, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Grid, Spinner, Text } from 'theme-ui';

import {
  ExtendedTimeEvent,
  TimeEventActionResponse,
  TimeEventState,
} from 'api/actions/timeEvent/timeEventActions.types';
import { LoadingSpinnerCss, LoadingSpinnerSize } from 'components/Loading/LoadingSpinnerCSS';
import { Map } from 'components/Map/Map';
import { Modal } from 'components/Modal/Modal';
import { Image } from 'components/ui/Image';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { WORK_STATUS_TYPE_ID } from 'constants/clockLog';
import { useIsEmployeeNonEditable } from 'hooks/useIsEmployeeNonEditable/useIsEmployeeNonEditable';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { useLocations } from 'pages/Locations/output/useLocations';
import { windowSizeAtom } from 'state/recoilState';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';
import { EventHistory } from '../../components/EventHistory/EventHistory';
import { useFetchFraudVerificationPhotosDetails } from '../../hooks/useFetchFraudVerificationPhotosDetails';

import { EditEventForm } from './EditEventForm';

const IMAGE_MIN_HEIGHT = '200px';

type Props = {
  extTimeEvent: ExtendedTimeEvent;
};

export const EditEventBody: FC<Props> = ({ extTimeEvent }) => {
  const extTimeEventRef = useRef(extTimeEvent);
  const {
    action,
    id: extTimeEventId,
    coordinates,
    photoUrl,
    device,
    personId,
    location: rawLocation,
    state,
    photos,
  } = extTimeEventRef.current;

  const isEmployeeNonEditable = useIsEmployeeNonEditable('TimeTracking', personId);

  const { locations } = useLocations();
  const location = useMemo(() => locations?.find((l) => l?.id === rawLocation?.id), [locations, rawLocation?.id]);
  const { state: locationState } = useLocation();
  const { eventDetailsPhotosUrls }: { eventDetailsPhotosUrls?: string[] } = locationState || {};

  const { isLandscape } = useRecoilValue(windowSizeAtom);
  const { breakpoint } = useThemeBreakpoint();
  const isViewXS = breakpoint === MEDIA_BREAKPOINTS.XS;

  const isSystem = device?.id === WORK_STATUS_TYPE_ID.MANUALLY_ADDED;
  const isModified = action !== TimeEventActionResponse.Create;

  const lat = location?.id ? location?.latitude : coordinates?.latitude;
  const lng = location?.id ? location?.longitude : coordinates?.longitude;
  const range = location?.id ? location?.range : null;

  const fraudDetectionState = extTimeEvent?.fraudDetectionState;

  const shouldFetchEvents = !isUndefined(fraudDetectionState) && !eventDetailsPhotosUrls && !photos?.length;

  const { verificationPhotosDetails, isLoading: isLoadingFraudPhotos } = useFetchFraudVerificationPhotosDetails(
    shouldFetchEvents ? extTimeEventId : undefined,
  );

  const photosUrls = useMemo(() => {
    if (photos) return photos?.map(({ url }) => url);
    if (eventDetailsPhotosUrls) return eventDetailsPhotosUrls;
    if (!isUndefined(fraudDetectionState) && verificationPhotosDetails) {
      return verificationPhotosDetails?.map(({ url }) => url);
    }
    if (isUndefined(fraudDetectionState) && photoUrl) {
      return [photoUrl];
    }

    return undefined;
  }, [eventDetailsPhotosUrls, fraudDetectionState, photoUrl, photos, verificationPhotosDetails]);

  return (
    <>
      <Modal.Body sx={{ display: 'flex', flexDirection: 'column', pt: 0 }}>
        <Flex
          sx={{
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Flex sx={{ justifyContent: 'space-between', flexDirection: ['column', null, 'row'] }}>
            <EditEventForm
              isPreview={isEmployeeNonEditable || state === TimeEventState.Deleted}
              extTimeEvent={extTimeEvent}
            />
          </Flex>

          {(isModified || isSystem) && (
            <Flex sx={{ flexDirection: 'column', mb: '1.5rem' }}>
              <EventHistory
                timeEventId={extTimeEventId}
                extTimeEvent={!isModified && isSystem ? extTimeEvent : undefined}
                sx={{
                  flex: '1 0',
                  bg: 'clockLog.edit.history.bg',
                  borderRadius: 'sm',
                  border: '1px solid',
                  borderColor: 'clockLog.edit.history.border',
                  px: 3,
                  pt: 3,
                  mt: 2,
                }}
              />
            </Flex>
          )}
        </Flex>

        <Flex sx={{ flexDirection: 'column' }}>
          {(location || coordinates) && (
            <Flex
              sx={{
                flexShrink: 0,
                flexDirection: 'column',
                wordBreak: 'break-all',
                bg: 'clockLog.edit.location.bg',
                borderRadius: '8px',
                overflow: 'hidden',
                border: '1px solid',
                borderColor: 'clockLog.edit.location.border',
                justifyContent: 'center',
              }}
            >
              <Text sx={{ my: 2, mx: 3, fontSize: 2 }}>
                {lat && lng && (
                  <Flex sx={{ flexDirection: 'column' }}>
                    {location?.name && <Text sx={{ fontWeight: '700' }}>{location.name}</Text>}
                    <Trans id="locations.coordinates">Coordinates</Trans> - {lat}, {lng}
                  </Flex>
                )}
              </Text>
              <Flex
                sx={{
                  height: 142,
                  width: '100%',
                  borderRadius: 'default',
                  overflow: 'hidden',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {lat && lng ? (
                  <Map
                    center={{ lat, lng }}
                    options={{
                      controlSize: 33,
                      draggable: false,
                      zoomControl: true,
                    }}
                  >
                    <Map.Marker position={{ lat, lng }} />
                    {range && <Map.Circle center={{ lat, lng }} radius={range} />}
                  </Map>
                ) : (
                  <Spinner />
                )}
              </Flex>
            </Flex>
          )}
        </Flex>

        <ConditionalWrapper
          condition={photosUrls && photosUrls.length > 1}
          wrapper={({ children }) => (
            <Grid
              sx={{
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                justifyItems: 'center',
                gap: '.5rem',
                ...((isViewXS || location || coordinates) && {
                  mt: 4,
                }),
              }}
            >
              {children}
            </Grid>
          )}
        >
          {!isLoadingFraudPhotos ? (
            <>
              {photosUrls?.map((url) => (
                <Flex
                  key={url}
                  sx={{
                    ...(photosUrls?.length === 1 && {
                      flexDirection: [isLandscape ? 'row' : 'column', null, 'row'],
                      justifyContent: [isLandscape ? 'center' : 'flex-start'],
                    }),
                  }}
                >
                  <Image
                    key={url}
                    src={url}
                    sx={{
                      borderRadius: 'default',
                      ...(photosUrls?.length === 1 && {
                        overflow: 'hidden',
                        mt: isViewXS || location || coordinates ? 4 : undefined,
                      }),
                      minHeight: IMAGE_MIN_HEIGHT,
                    }}
                    alt={t({ id: 'clock_log.time_event' })}
                  />
                </Flex>
              ))}
            </>
          ) : (
            <LoadingSpinnerCss
              size={LoadingSpinnerSize.imageMD}
              sx={{ justifySelf: 'center', alignSelf: 'center', gridColumnEnd: 'span 2' }}
            />
          )}
        </ConditionalWrapper>
      </Modal.Body>
    </>
  );
};
