import React, { useCallback } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useOnPopstateQueue } from 'hooks/useOnPopstateQueue/useOnPopstateQueue';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import {
  ChatWindowAction,
  anyChatWindowIsVisibleSelector,
  chatWindowIdsSelector,
  mainWindowIsVisibleAtom,
  setStateChatWindowSelector,
} from 'state/chat';
import { useCrisp } from '../hooks/useCrisp';

const CloseObserver = (): null => {
  const setIsMainWindowVisible = useSetRecoilState(mainWindowIsVisibleAtom);
  const chatWindowIds = useRecoilValue(chatWindowIdsSelector);

  const chatIds = useCallbackRef(chatWindowIds);

  const { closeCrisp } = useCrisp();

  const closeChatWindow = useRecoilCallback(
    ({ set }) =>
      (id: string) => {
        set(setStateChatWindowSelector(id), {
          type: 'isVisible',
          value: false,
        } as ChatWindowAction);
      },
    [],
  );

  const onPopstateCallback = useCallback(() => {
    setIsMainWindowVisible(false);
    chatIds.current.forEach(closeChatWindow);
    closeCrisp();

    return false;
  }, [chatIds, closeChatWindow, closeCrisp, setIsMainWindowVisible]);

  useOnPopstateQueue(onPopstateCallback);

  return null;
};

const Renderer = (): React.ReactElement | null => {
  const isMainWindowVisible = useRecoilValue(mainWindowIsVisibleAtom);
  const anyChatWindowIsVisible = useRecoilValue(anyChatWindowIsVisibleSelector);
  const { isMobileBreakpoint } = useThemeBreakpoint();

  if (!isMobileBreakpoint || (isMobileBreakpoint && !isMainWindowVisible && !anyChatWindowIsVisible)) {
    return null;
  }

  return <CloseObserver />;
};

export const ChatCloseObserver = Renderer;
