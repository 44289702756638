import { Action } from 'react-fetching-library';

import { API_ENDPOINTS } from 'constants/api';

import {
  DeleteWebhookActionProps,
  FetchAvailableWebhooksResponse,
  FetchWebhookActionProps,
  FetchWebhookResponse,
  FetchWebhooksResponse,
  EditWebhookActionProps,
  AddWebhookActionProps,
  AddWebhookResponse,
  EditWebhookResponse,
} from './webhooksActions.types';

export function fetchWebhooksAction(): Action<FetchWebhooksResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.webhook,
  };
}

export function fetchWebhookAction({ id }: FetchWebhookActionProps): Action<FetchWebhookResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.webhook}/${id}`,
  };
}

export function fetchAvailableWebhooksAction(): Action<FetchAvailableWebhooksResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.webhookGetAvailable,
  };
}

export function addWebhookAction(body: AddWebhookActionProps): Action<AddWebhookResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.webhookAdd,
    body,
  };
}

export function editWebhookAction({ id, ...body }: EditWebhookActionProps): Action<EditWebhookResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.webhookEdit}/${id}`,
    body,
  };
}

export function deleteWebhookAction(body: DeleteWebhookActionProps): Action<AddWebhookResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.webhookDelete}`,
    body,
  };
}
