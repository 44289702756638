import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import flatMap from 'lodash/flatMap';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import { lazy, memo, Suspense, useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { Helmet } from 'react-helmet';
import { useRecoilValue } from 'recoil';
import { Flex, ThemeUICSSObject } from 'theme-ui';

import { activateEmployeesAction } from 'api/actions/employees/employeesActions';
import { InvitationStates } from 'api/actions/organizationSession/organizationSessionActions.types';
import { TeamHideableColumns } from 'api/actions/userSession/userSessionActions.types';
import { Avatar } from 'components/Avatar/Avatar';
import { DropdownProps } from 'components/Dropdown/Dropdown';
import { DropdownLinkItem } from 'components/Dropdown/types';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { MinimizedModalsProvider } from 'components/Modal/output/MinimizedModalsProvider';
import { CustomRowVariant, ListNames, StickyListProps } from 'components/StickyList/types';
import { Tag } from 'components/Tag/Tag';
import { TagStack } from 'components/Tag/TagStack';
import { Button } from 'components/ui/Buttons/Button';
import { CopyToClipboardText } from 'components/ui/CopyToClipboardText';
import { ElementGroup } from 'components/ui/ElementGroup';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { FullName } from 'components/utils/FullName';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { APP_NAME, HOSTNAME, REACT_APP_HOSTNAME } from 'constants/common';
import { TO_REL } from 'constants/routes';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { Main } from 'layouts/AuthorizedApp';
import { filteredEmployeesSelector, ParsedEmployee } from 'state/employees';
import { ViewWithMinimizedModals } from 'state/modal';
import { languageSelector } from 'state/recoilState';
import { hiddenColumnsTeamViewSelector } from 'state/team';
import { userSessionPersonIdSelector } from 'state/userSession';
import { useTheme } from 'styles/useTheme';
import { LazyComponentType } from 'utils/custom.types';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

import { HeaderMenu } from './components/HeaderMenu';
import { ModalRoutes } from './ModalRoutes';
import { decodeWithAESToUTF8 } from './utils/decodeWithAESToUTF8';
import { getEmployeeStates } from './utils/getEmployeeStates';

const LazyStickyList = lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<ParsedEmployee, TeamHideableColumns>>;

const ButtonWithDropdown = withDropdown(Button);
const IconWithTooltip = withTooltip(Icon);

const rowCommonSx: ThemeUICSSObject = {
  pointerEvents: 'all',
  userSelect: 'text',
};

export const Team = memo(() => {
  const userId = useRecoilValue(userSessionPersonIdSelector);
  const language = useRecoilValue(languageSelector);
  const hiddenColumns = useRecoilValue(hiddenColumnsTeamViewSelector);
  const filteredEmployees = useRecoilValue(filteredEmployeesSelector);

  useLingui();
  const { addSnackbar } = useSnackbar();
  const getFullName = useNameDisplayOrder();
  const navigate = useAppNavigate();
  const { theme } = useTheme();

  const { modulesManagement, isTooManyUsersBlocked, isAccountBlocked } = useAppPermissions();
  const isAppBlocked = isTooManyUsersBlocked || isAccountBlocked;

  const { mutate: activateEmployee } = useMutation(activateEmployeesAction);

  const columns: StickyListProps<ParsedEmployee, TeamHideableColumns>['columns'] = [
    {
      key: ['avatarUrl', 'name'],
      customCellRenderer: (items: [ParsedEmployee['avatarUrl'], ParsedEmployee['name']]) => (
        <Avatar size={24} image={items[0]} name={items[1]} />
      ),
      width: '24px',
    },
    {
      title: t({ id: 'team.list.employee_name', message: `Name` }),
      key: 'name',
      sx: rowCommonSx,
      sortableValue: ({ firstName, surname }: ParsedEmployee['name']) => getFullName(firstName, surname),
      customCellRenderer: ({ firstName, surname }: ParsedEmployee['name']) => (
        <TextEllipsis title={getFullName(firstName, surname)}>
          <FullName firstName={firstName} surname={surname} />
        </TextEllipsis>
      ),
      columnGrow: 2,
    },
    {
      id: TeamHideableColumns.roleAndTags,
      title: t({ id: 'team.list.role_tags', message: `Role & Tags` }),
      key: ['role', 'tags'],
      sx: rowCommonSx,
      sortableValue: (items: [ParsedEmployee['role'], ParsedEmployee['tags']]) =>
        map(items, (item) => {
          if (isArray(item)) {
            return flatMap(
              orderBy(
                item,
                [(tagDetails) => !!tagDetails?.isInformational, (tagDetails) => tagDetails?.name],
                ['asc', 'asc'],
              ),
              (tag) => tag && tag.name,
            );
          }
          return item.name;
        }).join(' '),

      customCellRenderer: (items: [ParsedEmployee['role'], ParsedEmployee['tags']]) =>
        map(items, (item) => {
          if (isArray(item)) {
            return <TagStack key="tags" tags={item} />;
          }

          if (!item) return null;

          return (
            <Tag
              key={item.name}
              variant="outline"
              tooltip={t({
                id: item.name,
              })}
              sx={{
                flex: '0 0 auto',
                maxWidth: '150px',
              }}
              color={UserSelectableColor[item.color]}
            >
              {t({
                id: item.name,
              })}
            </Tag>
          );
        }),
      columnGrow: [3, null, null, 5],
    },
    {
      id: TeamHideableColumns.workPosition,
      title: t({ id: 'team.list.position', message: `Position` }),
      key: 'workPosition',
      sx: rowCommonSx,
      sortableValue: (item: ParsedEmployee['workPosition']) => `${item}`,
      customCellRenderer: (workPosition: ParsedEmployee['workPosition']) => (
        <TextEllipsis title={workPosition}>{workPosition}</TextEllipsis>
      ),
    },
    {
      id: TeamHideableColumns.email,
      title: t({ id: 'team.list.email', message: `E-mail` }),
      key: 'email',
      sx: rowCommonSx,
      sortableValue: (item: ParsedEmployee['email']) => `${item}`,
      customCellRenderer: (email: ParsedEmployee['email']) => (
        <CopyToClipboardText title={email} ellipsis>
          {email}
        </CopyToClipboardText>
      ),
    },
    {
      id: TeamHideableColumns.phoneNumber,
      title: t({ id: 'team.list.phone', message: `Phone` }),
      key: 'phoneNumber',
      sx: rowCommonSx,
      sortableValue: (item: ParsedEmployee['phoneNumber']) => `${item}`,
      customCellRenderer: (phone: ParsedEmployee['phoneNumber']) => (
        <CopyToClipboardText title={phone} ellipsis>
          {phone}
        </CopyToClipboardText>
      ),
    },
    {
      id: TeamHideableColumns.customEmployeeId,
      title: t({ id: 'team.list.employee_id', message: `Employee ID` }),
      key: 'customEmployeeId',
      sx: rowCommonSx,
      sortableValue: (item) => `${item}`,
      customCellRenderer: (customEmployeeId: ParsedEmployee['customEmployeeId']) => (
        <CopyToClipboardText title={customEmployeeId} ellipsis>
          {customEmployeeId}
        </CopyToClipboardText>
      ),
    },
    ...(HOSTNAME === REACT_APP_HOSTNAME.inewi
      ? [
          {
            id: TeamHideableColumns.identity,
            title: t({ id: 'team.list.identity', message: 'Personal identity number' }),
            key: ['zusIdentity' as const, 'isNonEditable' as const, 'id' as const],
            sortableValue: ([encodedIdentity, isNonEditable, employeeId]: [
              ParsedEmployee['zusIdentity'],
              ParsedEmployee['isNonEditable'],
              ParsedEmployee['id'],
            ]) => {
              const hasPermissionToViewZusIdentity = !isNonEditable || userId === employeeId;
              if (!hasPermissionToViewZusIdentity) return '';
              return decodeWithAESToUTF8(encodedIdentity) || `${encodedIdentity}`;
            },
            customCellRenderer: ([encodedIdentity, isNonEditable, employeeId]: [
              ParsedEmployee['zusIdentity'],
              ParsedEmployee['isNonEditable'],
              ParsedEmployee['id'],
            ]) => {
              const hasPermissionToViewZusIdentity = !isNonEditable || userId === employeeId;
              if (!hasPermissionToViewZusIdentity) return null;
              const decodedIdentity = decodeWithAESToUTF8(encodedIdentity);
              return (
                <CopyToClipboardText title={decodedIdentity} ellipsis>
                  {decodedIdentity}
                </CopyToClipboardText>
              );
            },
          },
        ]
      : []),
    {
      key: ['invitationState', 'isActive', 'isHidden', 'email'],
      customCellRenderer: ([invitationState, isActive, isHidden, email]: [
        ParsedEmployee['invitationState'],
        ParsedEmployee['isActive'],
        ParsedEmployee['isHidden'],
        ParsedEmployee['email'],
      ]) => {
        const employeeStates = getEmployeeStates({ invitationState, isActive, isHidden, email });
        if (!employeeStates) return null;
        return (
          <ElementGroup showAsList marginValue={1}>
            {employeeStates.map(({ iconType, message, color, colorHover }) => (
              <IconWithTooltip
                key={iconType}
                size={24}
                type={iconType}
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                  color,
                  '& :hover': {
                    color: colorHover,
                  },
                }}
                tooltip={message}
              />
            ))}
          </ElementGroup>
        );
      },
      width: `${3 * 30}px`,
      sx: {
        flexDirection: 'row-reverse',
        pointerEvents: 'all',
      },
    },
    {
      key: ['isActive', 'email', 'invitationState', 'isNonEditable'],
      width: '40px',
      sx: {
        overflow: 'visible',
      },
      customCellRenderer: (
        [isActive, email, invitationState, isNonEditable]: [
          ParsedEmployee['isActive'],
          ParsedEmployee['email'],
          ParsedEmployee['invitationState'],
          ParsedEmployee['isNonEditable'],
        ],
        itemId,
      ) => {
        const shouldShowInviteButton = (() => {
          if (!isActive) return false;
          if (invitationState) return true;
          if (email) return false;
          return true;
        })();
        const deleteLink: DropdownLinkItem = {
          disabled: userId === itemId,
          prependWithDivider: true,
          label: t({ id: 'team.list.delete_employee', message: 'Delete' }),
          icon: 'delete',
          to: TO_REL.DELETE_TEAMMATES_MODAL[language],
          state: { ids: [itemId] },
        };
        const restrictedLinks: DropdownProps['links'] = isAppBlocked
          ? [{ ...deleteLink, prependWithDivider: false }]
          : [
              deleteLink,
              isActive
                ? {
                    disabled: userId === itemId,
                    label: t({ id: 'team.list.deactivate_employee', message: 'Deactivate' }),
                    icon: 'lock',
                    to: TO_REL.DEACTIVATE_TEAMMATES_MODAL[language],
                    state: { ids: [itemId] },
                  }
                : {
                    disabled: userId === itemId,
                    label: t({ id: 'team.list.activate_employee', message: 'Activate' }),
                    icon: 'unLock',
                    onClick: floatingPromiseReturn(async () => {
                      const { error: submitError } = await activateEmployee([itemId]);
                      if (!submitError) {
                        addSnackbar({
                          message: t({ id: 'team.list.activated_success', message: 'Successfully activated!' }),
                          variant: 'success',
                        });
                      }
                    }),
                  },
              ...(!shouldShowInviteButton
                ? []
                : [
                    {
                      disabled: userId === itemId,
                      prependWithDivider: true,
                      label: email
                        ? t({ id: 'team.list.reinvite', message: 'Re-invite' })
                        : t({ id: 'team.list.invite', message: 'Invite' }),
                      icon: 'send' as Icons,
                      to: `${TO_REL[email ? 'RE_INVITE_TEAMMATES_MODAL' : 'INVITE_TEAMMATES_MODAL'][language]}`,
                      state: { ids: [itemId] },
                    },
                  ]),
            ];
        const links: DropdownProps['links'] = [
          ...(!isAppBlocked
            ? [
                {
                  label: t({ id: 'team.list.print_qr', message: 'Print QR card' }),
                  icon: 'print' as const,
                  to: TO_REL.PRINT_QR_CARDS_MODAL[language],
                  state: { ids: [itemId] },
                },
              ]
            : []),
          ...(userId !== itemId && !isNonEditable ? restrictedLinks : []),
        ];
        return modulesManagement.Employees && !!links.length ? (
          <ButtonWithDropdown
            key={itemId}
            dropdownProps={{ links }}
            shape="rounded"
            size="sm"
            variant="minimal"
            onClick={(e) => e.stopPropagation()}
            popperProps={{
              contextMenuId: itemId,
            }}
          >
            <Icon type="more" />
          </ButtonWithDropdown>
        ) : null;
      },
    },
  ];

  const visibleColumns = columns.filter((column) => !column.id || !includes(hiddenColumns, column.id));

  const fallbackSortableValueGetter: NonNullable<StickyListProps<ParsedEmployee>['fallbackSortableValueGetter']> =
    useCallback(({ name: { firstName, surname } }) => getFullName(firstName, surname), [getFullName]);

  const handleOnRowClick: StickyListProps['onRowClick'] = (id) => {
    navigate(`${TO_REL.EDIT_TEAMMATE_MODAL__ID[language]}/${id}`);
  };

  const customRowVariantGenerator = useCallback(
    (id: string) => {
      if (!filteredEmployees) return undefined;

      const employee = filteredEmployees.get(id);
      if (!employee) return undefined;

      const { invitationState } = employee;
      return invitationState === InvitationStates.EXPIRED ? CustomRowVariant.warning : undefined;
    },
    [filteredEmployees],
  );

  return (
    <>
      <Helmet>
        <title>{t({ id: 'team.page_title', message: `Team - ${APP_NAME}` })}</title>
      </Helmet>

      <Main>
        <Main.Header>
          <Flex sx={{ flexDirection: 'column' }}>
            <Main.Header.Title>
              <Trans id="team.heading">Your team</Trans>
              <span> ({filteredEmployees.size})</span>
            </Main.Header.Title>
            <Main.Header.Deselect selectorFamilyID={ListNames.TEAM} />
          </Flex>

          <HeaderMenu selectorFamilyID={ListNames.TEAM} />
        </Main.Header>

        <Flex sx={{ flexGrow: 1, mx: -4, overflow: ['auto', null, null, 'unset'] }}>
          <Suspense
            fallback={
              <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <LoadingSpinnerCss size={4} />
              </Flex>
            }
          >
            <LazyStickyList
              name={ListNames.TEAM}
              select={modulesManagement.Employees ? 'checkbox' : undefined}
              list={filteredEmployees}
              columns={visibleColumns}
              onRowClick={modulesManagement.Employees ? handleOnRowClick : undefined}
              customRowVariantGenerator={customRowVariantGenerator}
              emptyListMessage={t({ id: 'no_matches_found' })}
              showHeader
              fallbackSortableValueGetter={fallbackSortableValueGetter}
              mobileWidth={1024}
              style={{
                paddingLeft: theme.space[4],
                paddingRight: theme.space[4],
                paddingBottom: 48,
              }}
              withRowContextMenu={modulesManagement.Employees}
            />
          </Suspense>
        </Flex>

        <MinimizedModalsProvider forView={ViewWithMinimizedModals.TEAM} />
        <ModalRoutes />
      </Main>
    </>
  );
});
