import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import omit from 'lodash/omit';
import { ChangeEvent, FC, MouseEventHandler, RefCallback, useMemo, useRef } from 'react';
import { Control, UseFormRegister, UseFormSetValue, UseFormWatch, useFieldArray } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, Heading, Link, Text, ThemeUIStyleObject } from 'theme-ui';

import { Feature, VisibilityLevels } from 'api/actions/organizationSession/organizationSessionActions.types';
import { TagDetails } from 'api/actions/tags/tagsActions.types';
import { PermissionsFeaturesTypes } from 'api/actions/userSession/userSessionActions.types';
import { Checkbox } from 'components/ui/Checkbox';
import { ElementGroup } from 'components/ui/ElementGroup';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { Select, SelectProps } from 'components/ui/Select/Select';
import { Switch } from 'components/ui/Switch';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import {
  APP_NAME,
  APP_STORE_DOWNLOAD_APP_LINK,
  APP_STORE_DOWNLOAD_RCP_APP_LINK,
  GOOGLE_PLAY_DOWNLOAD_APP_LINK,
  GOOGLE_PLAY_DOWNLOAD_RCP_APP_LINK,
} from 'constants/common';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';
import { visibilityLevelOptionsSelector } from 'state/selectOptions';
import { EmployeeAdvancedDetailsFormState } from 'state/team';
import { MergedProperties } from 'utils/custom.types';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';

const checkboxWrapperSx: ThemeUIStyleObject = {
  fontWeight: '400',
  flexBasis: '88px',
  minWidth: '88px',
};

const wrapperLinkSx: ThemeUIStyleObject = {
  mt: -2,
  mb: 1,
  display: 'block',
  color: 'team.advancedFeatures',
  textDecoration: 'underline',
  fontWeight: 'normal',
  '&:hover': {
    opacity: 0.7,
  },
};

type BasicSetValue = UseFormSetValue<Partial<Pick<EmployeeAdvancedDetailsFormState, 'features'>>>;
type BasicWatch = UseFormWatch<Partial<EmployeeAdvancedDetailsFormState>>;
type BasicRegister = UseFormRegister<Partial<EmployeeAdvancedDetailsFormState>>;
type MergedControl = MergedProperties<Control<EmployeeAdvancedDetailsFormState>, Control<Partial<TagDetails>>>;

type ParsedFeature = Feature & { RFH_INDEX: number; label?: string; additionalInfo?: string | JSX.Element };

type Props = {
  control: Control<EmployeeAdvancedDetailsFormState> | Control<Partial<TagDetails>>;
  register: MergedProperties<
    UseFormRegister<Partial<EmployeeAdvancedDetailsFormState>>,
    UseFormRegister<Partial<TagDetails>>
  >;
  watch?: UseFormWatch<EmployeeAdvancedDetailsFormState> | UseFormWatch<Partial<TagDetails>>;
  setValue?: UseFormSetValue<EmployeeAdvancedDetailsFormState> | UseFormSetValue<Partial<TagDetails>>;
  inheritFromTagsMode?: boolean;
};

export type AdvancedFeaturesFieldArrayProps = Props;

enum FeatureGroupsTypes {
  clockIns,
  calendar,
  clockLog,
  attendanceOverview,
  other,
}

const FEATURES_GROUPS = {
  [FeatureGroupsTypes.clockIns]: [
    PermissionsFeaturesTypes.AddEventsByWorkerApp,
    PermissionsFeaturesTypes.AddEventsByMobileTimeClock,
    PermissionsFeaturesTypes.AddEventsByKiosk,
    PermissionsFeaturesTypes.AddEventsByVrcp,
  ],
  [FeatureGroupsTypes.calendar]: [
    PermissionsFeaturesTypes.CalendarTagsWideView,
    PermissionsFeaturesTypes.CalendarCompanyWideView,
  ],
  [FeatureGroupsTypes.clockLog]: [
    PermissionsFeaturesTypes.TimeTrackingTagsWideView,
    PermissionsFeaturesTypes.TimeTrackingCompanyWideView,
  ],
  [FeatureGroupsTypes.attendanceOverview]: [
    PermissionsFeaturesTypes.AttendanceOverviewTagsWideView,
    PermissionsFeaturesTypes.AttendanceOverviewCompanyWideView,
  ],
  [FeatureGroupsTypes.other]: [
    PermissionsFeaturesTypes.DisableLocationLimitation,
    PermissionsFeaturesTypes.LimitDataVisibility,
  ],
};

export const AdvancedFeaturesFieldArray: FC<Props> = ({
  control,
  register,
  watch,
  setValue,
  inheritFromTagsMode = false,
}) => {
  useLingui();

  const { fields } = useFieldArray({
    control: control as MergedControl,
    name: 'features',
  });

  const watchFieldArray = watch ? (watch as BasicWatch)('features') : fields;

  const controlledFieldsMap = useMemo(() => {
    const controlledFields = fields.map((field, index) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const { label, additionalInfo } = getFeatureLabel(field.type);
      const watchedField = find(watchFieldArray, { type: field.type });
      return {
        RFH_INDEX: index,
        ...field,
        ...(watchedField && watchedField),
        label,
        additionalInfo,
      };
    });

    const fieldsMap = new Map<PermissionsFeaturesTypes, ParsedFeature>();

    controlledFields.forEach((feature) => {
      fieldsMap.set(feature.type, feature);
    });

    return fieldsMap;
  }, [fields, watchFieldArray]);

  const visibilityLevelOptions = useRecoilValue(visibilityLevelOptionsSelector);

  const visibilityLevelRef = useRef<HTMLInputElement | null>(null);

  const calendarTagsWideViewRef = useRef<HTMLInputElement | null>(null);
  const calendarTagsWideViewInheritRef = useRef<HTMLInputElement | null>(null);
  const timeTrackingTagsWideViewRef = useRef<HTMLInputElement | null>(null);
  const timeTrackingTagsWideViewInheritRef = useRef<HTMLInputElement | null>(null);
  const attendanceOverviewTagsWideViewRef = useRef<HTMLInputElement | null>(null);
  const attendanceOverviewTagsWideViewInheritRef = useRef<HTMLInputElement | null>(null);

  const handleCheckboxWrapperClick: MouseEventHandler<HTMLDivElement> = (e) => e.stopPropagation();

  const featureRenderer = ({ RFH_INDEX: index, label, additionalInfo, ...field }: ParsedFeature) => {
    const getVisibilityLevelRegisterProps = ():
      | (Pick<SelectProps, 'onChange' | 'name' | 'onBlur'> & {
          ref: RefCallback<HTMLInputElement | null>;
        })
      | null => {
      if (field.type === PermissionsFeaturesTypes.LimitDataVisibility) {
        const { ref, ...registerProps } = (register as BasicRegister)('visibilityLevel');
        return {
          ref: mergeRefs([ref, visibilityLevelRef]),
          ...registerProps,
        };
      }
      return null;
    };

    const linkedFeatureTypesMap = new Map([
      [PermissionsFeaturesTypes.CalendarCompanyWideView, PermissionsFeaturesTypes.CalendarTagsWideView],
      [PermissionsFeaturesTypes.TimeTrackingCompanyWideView, PermissionsFeaturesTypes.TimeTrackingTagsWideView],
      [
        PermissionsFeaturesTypes.AttendanceOverviewCompanyWideView,
        PermissionsFeaturesTypes.AttendanceOverviewTagsWideView,
      ],
    ]);

    const featuresRefsMap = new Map([
      [PermissionsFeaturesTypes.CalendarTagsWideView, calendarTagsWideViewRef],
      [PermissionsFeaturesTypes.TimeTrackingTagsWideView, timeTrackingTagsWideViewRef],
      [PermissionsFeaturesTypes.AttendanceOverviewTagsWideView, attendanceOverviewTagsWideViewRef],
    ]);

    const featuresInheritRefsMap = new Map([
      [PermissionsFeaturesTypes.CalendarTagsWideView, calendarTagsWideViewInheritRef],
      [PermissionsFeaturesTypes.TimeTrackingTagsWideView, timeTrackingTagsWideViewInheritRef],
      [PermissionsFeaturesTypes.AttendanceOverviewTagsWideView, attendanceOverviewTagsWideViewInheritRef],
    ]);

    const linkedFeatureType = linkedFeatureTypesMap.get(field.type);

    const linkedFeatureRef = linkedFeatureType ? featuresRefsMap.get(linkedFeatureType) : null;
    const linkedFeatureInheritRef = linkedFeatureType ? featuresInheritRefsMap.get(linkedFeatureType) : null;

    const linkedFeature = linkedFeatureType ? controlledFieldsMap.get(linkedFeatureType) : null;

    const linkedFeatureIndex = linkedFeature?.RFH_INDEX;

    const getInheritFromTagRegisterProps = () => {
      if (inheritFromTagsMode) {
        const checkboxRef = featuresInheritRefsMap.get(field.type);

        const { ref, ...registerProps } = (register as BasicRegister)(`features.${index}.inheritFromTag`);

        return {
          ref: checkboxRef ? mergeRefs([ref, checkboxRef]) : ref,
          ...registerProps,
        };
      }
      return null;
    };

    const inheritFromTagRegisterProps = getInheritFromTagRegisterProps();
    const visibilityLevelRegisterProps = getVisibilityLevelRegisterProps();

    const resetLinkedCheckbox = () => {
      if (
        watch &&
        (watch as BasicWatch)(`features.${linkedFeatureIndex}.value` as 'features') &&
        linkedFeatureInheritRef?.current
      ) {
        linkedFeatureInheritRef.current.checked = false;
        if (!setValue || !isNumber(linkedFeatureIndex)) return;
        (setValue as BasicSetValue)(`features.${linkedFeatureIndex}.inheritFromTag`, false);
      }
    };

    const resetLinkedFeature = () => {
      if (linkedFeatureRef?.current) {
        linkedFeatureRef.current.checked = false;
        if (!setValue || !isNumber(linkedFeatureIndex)) return;
        (setValue as BasicSetValue)(`features.${linkedFeatureIndex}.value`, false);
      }
    };

    const getFeatureRegisterProps = (featureType: PermissionsFeaturesTypes) => {
      const featureRef = featuresRefsMap.get(featureType);

      const { ref, ...registerProps } = (register as BasicRegister)(`features.${index}.value`);

      const basicRegisterProps = {
        ref: featureRef ? mergeRefs([ref, featureRef]) : ref,
        ...registerProps,
      };

      if (!linkedFeatureType || !linkedFeature) return basicRegisterProps;

      const onLinkedFeatureChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
          resetLinkedCheckbox();
          resetLinkedFeature();
        }

        void registerProps.onChange(e);
      };

      return {
        ...basicRegisterProps,
        onChange: onLinkedFeatureChange,
      };
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked && setValue) {
        (setValue as BasicSetValue)(`features.${index}.value`, field.inheritedValue);
        if (watch) {
          const visibilityLevelInheritedFromTag = (watch as BasicWatch)('visibilityLevelInheritedFromTag');
          setNativeValue(visibilityLevelRef, visibilityLevelInheritedFromTag);
        }

        if (field.inheritedValue && linkedFeatureIndex) {
          resetLinkedCheckbox();
          resetLinkedFeature();
        }
      }
      if (inheritFromTagRegisterProps?.onChange) {
        void inheritFromTagRegisterProps.onChange(e);
      }
    };

    const providingFeaturesTypesMap = new Map([
      [PermissionsFeaturesTypes.CalendarTagsWideView, PermissionsFeaturesTypes.CalendarCompanyWideView],
      [PermissionsFeaturesTypes.TimeTrackingTagsWideView, PermissionsFeaturesTypes.TimeTrackingCompanyWideView],
      [
        PermissionsFeaturesTypes.AttendanceOverviewTagsWideView,
        PermissionsFeaturesTypes.AttendanceOverviewCompanyWideView,
      ],
    ]);

    const providingFeatureType = providingFeaturesTypesMap.get(field.type);

    const disableProvidingFeature = providingFeatureType ? controlledFieldsMap.get(providingFeatureType) : null;

    const disableProvidingFeatureIndex = disableProvidingFeature?.RFH_INDEX;

    const disabledByLinkedFeature = () =>
      (disableProvidingFeatureIndex &&
        watch &&
        (watch as BasicWatch)(`features.${disableProvidingFeatureIndex}.value`)) ||
      false;

    const disabledButReadable = () => {
      if (!watch) return false;

      if (disabledByLinkedFeature()) return true;

      return (watch as BasicWatch)(`features.${index}.inheritFromTag`);
    };

    return (
      <ConditionalWrapper
        key={`advanced-feature--${field.type}`}
        condition={field.type === PermissionsFeaturesTypes.LimitDataVisibility}
        wrapper={({ children, sx }) => (
          <Flex sx={{ flexDirection: 'column', ...(sx && sx) }}>
            {children}
            {watch && (watch as BasicWatch)(`features.${index}.value`) && (
              <Flex
                sx={{
                  pl: 3,
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 4,
                  mt: '0.75rem',
                }}
              >
                <Text sx={{ fontWeight: 'bold', fontSize: 2 }}>
                  <Trans id="team.advanced.only_show_last">Only show last</Trans>
                </Text>
                <Select
                  sx={{ width: '160px' }}
                  defaultValue={VisibilityLevels.Year}
                  disabled={(watch as BasicWatch)(`features.${index}.inheritFromTag`)}
                  size="sm"
                  id="visibilityLevel"
                  placeholder=""
                  options={visibilityLevelOptions}
                  {...(visibilityLevelRegisterProps && visibilityLevelRegisterProps)}
                />
              </Flex>
            )}
          </Flex>
        )}
      >
        <Switch
          defaultChecked={field.value}
          disabledButReadable={disabledButReadable()}
          placement="right"
          size="sm"
          label={label}
          additionalInfo={additionalInfo}
          prependSwitchWith={
            inheritFromTagsMode && (
              <Flex onClick={handleCheckboxWrapperClick} sx={checkboxWrapperSx}>
                <Checkbox
                  disabledButReadable={disabledByLinkedFeature()}
                  defaultChecked={field.inheritFromTag}
                  label={t({ id: 'team.advanced.inherit_from_tags', message: 'Inherit from tags' })}
                  size="sm"
                  {...omit(inheritFromTagRegisterProps, ['onChange'])}
                  onChange={handleChange}
                  sx={{ gap: 2 }}
                />
              </Flex>
            )
          }
          {...getFeatureRegisterProps(field.type)}
        />
      </ConditionalWrapper>
    );
  };

  const attendanceOverviewLink = useHelpLink({
    inEwi: {
      pl: '/article/przeglad-obecnosci-1skshht/',
    },
    unrubble: {
      en: '/article/attendance-overview-ka7uzd/',
    },
  });

  const getFeatureGroupAdditionalInfo = (groupType: FeatureGroupsTypes) => {
    switch (groupType) {
      case FeatureGroupsTypes.attendanceOverview:
        return (
          <Link href={attendanceOverviewLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
            {t({ id: 'settings.learn_more' })}
          </Link>
        );
      default:
        return '';
    }
  };

  const featureGroupRenderer = (groupType: FeatureGroupsTypes) => (
    <>
      <Heading variant="heading4" mb={2}>
        {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
        {getFeatureGroupTitle(groupType)}
      </Heading>
      {getFeatureGroupAdditionalInfo(groupType)}
      <ElementGroup wrapperSx={{ mb: 4 }} showAsList marginValue="0.75rem" direction="column" withDividers>
        {FEATURES_GROUPS[groupType]
          .map((type) => {
            const feature = controlledFieldsMap.get(type);
            return feature ? featureRenderer(feature) : null;
          })
          .filter((f) => !!f)}
      </ElementGroup>
    </>
  );
  const groupRenderCondition = (groupType: FeatureGroupsTypes) =>
    FEATURES_GROUPS[groupType].some((type) => controlledFieldsMap.get(type));

  return (
    <>
      {groupRenderCondition(FeatureGroupsTypes.clockIns) && featureGroupRenderer(FeatureGroupsTypes.clockIns)}
      {groupRenderCondition(FeatureGroupsTypes.calendar) && featureGroupRenderer(FeatureGroupsTypes.calendar)}
      {groupRenderCondition(FeatureGroupsTypes.clockLog) && featureGroupRenderer(FeatureGroupsTypes.clockLog)}
      {groupRenderCondition(FeatureGroupsTypes.attendanceOverview) &&
        featureGroupRenderer(FeatureGroupsTypes.attendanceOverview)}
      {groupRenderCondition(FeatureGroupsTypes.other) && featureGroupRenderer(FeatureGroupsTypes.other)}
    </>
  );
};

//
// HELPERS
//
const getFeatureGroupTitle = (groupType: FeatureGroupsTypes) => {
  switch (groupType) {
    case FeatureGroupsTypes.clockIns:
      return t({ id: 'team.advanced.clock_ins', message: 'Allow Clock-Ins from' });
    case FeatureGroupsTypes.calendar:
      return t({ id: 'team.advanced.calendar', message: 'Show teammates details in Calendar' });
    case FeatureGroupsTypes.clockLog:
      return t({ id: 'team.advanced.clock_log', message: 'Show teammates details in Clock Log & Reports' });
    case FeatureGroupsTypes.attendanceOverview:
      return t({ id: 'team.advanced.attendance_overview', message: 'Show Attendance Overview' });
    case FeatureGroupsTypes.other:
      return t({ id: 'team.advanced.other', message: 'Other' });
    default:
      return '';
  }
};

const getFeatureLabel = (featureType: PermissionsFeaturesTypes) => {
  switch (featureType) {
    //
    // CLOCKINS
    //
    case PermissionsFeaturesTypes.AddEventsByWorkerApp:
      return {
        label: t({
          id: 'team.advanced.mobile_clockins',
          message: `Mobile App - ${APP_NAME} Staff Scheduling`,
        }),
        additionalInfo: (
          <Trans id="team.advanced.mobile_clockins.desc">
            Download app from &nbsp;
            <LinkAnchor sx={{ pointerEvents: 'auto', mx: '5px' }} href={GOOGLE_PLAY_DOWNLOAD_APP_LINK} target="_blank">
              Google Play
            </LinkAnchor>
            &nbsp; or &nbsp;
            <LinkAnchor sx={{ pointerEvents: 'auto' }} href={APP_STORE_DOWNLOAD_APP_LINK} target="_blank">
              App Store
            </LinkAnchor>
          </Trans>
        ),
      };
    case PermissionsFeaturesTypes.AddEventsByMobileTimeClock:
      return {
        label: t({
          id: 'team.advanced.kiosk_clockins.allow',
          message: `Mobile App - ${APP_NAME} TimeClock`,
        }),
        additionalInfo: (
          <Trans id="team.advanced.kiosk_clockins.desc">
            Download app from&nbsp;
            <LinkAnchor
              sx={{ pointerEvents: 'auto', mx: '5px' }}
              href={GOOGLE_PLAY_DOWNLOAD_RCP_APP_LINK}
              target="_blank"
            >
              Google Play
            </LinkAnchor>
            &nbsp;or&nbsp;
            <LinkAnchor sx={{ pointerEvents: 'auto' }} href={APP_STORE_DOWNLOAD_RCP_APP_LINK} target="_blank">
              App Store
            </LinkAnchor>
          </Trans>
        ),
      };
    case PermissionsFeaturesTypes.AddEventsByKiosk:
      return {
        label: t({
          id: 'team.advanced.kiosk_clockins',
          message: 'Kiosk Mode',
        }),
      };
    case PermissionsFeaturesTypes.AddEventsByVrcp:
      return {
        label: t({ id: 'team.advanced.web_app_clockins', message: 'Web App' }),
      };
    //
    // CALENDAR && CLOCK_LOG && ATTENDANCE_OVERVIEW
    //
    case PermissionsFeaturesTypes.CalendarTagsWideView:
    case PermissionsFeaturesTypes.TimeTrackingTagsWideView:
    case PermissionsFeaturesTypes.AttendanceOverviewTagsWideView:
      return {
        label: t({
          id: 'team.advanced.within_tags',
          message: 'Within assigned tags',
        }),
      };
    case PermissionsFeaturesTypes.CalendarCompanyWideView:
    case PermissionsFeaturesTypes.TimeTrackingCompanyWideView:
    case PermissionsFeaturesTypes.AttendanceOverviewCompanyWideView:
      return {
        label: t({
          id: 'team.advanced.company_wide',
          message: 'Company-wide',
        }),
      };
    //
    // OTHER
    //
    case PermissionsFeaturesTypes.DisableLocationLimitation:
      return {
        label: t({
          id: 'team.advanced.location_limits',
          message: 'Turn off all location limits for time tracking',
        }),
        additionalInfo: t({
          id: 'team.advanced.location_limits.desc',
          message: 'User will be able to Clock-In & Out from any location',
        }),
      };
    case PermissionsFeaturesTypes.LimitDataVisibility:
      return {
        label: t({
          id: 'team.advanced.data_visibility',
          message: 'Limit data visibility',
        }),
        additionalInfo: t({
          id: 'team.advanced.data_visibility.desc',
          message: 'Defines how long into the past user will be able to see their data',
        }),
      };
    default:
      return {
        label: '',
        additionalInfo: '',
      };
  }
};
