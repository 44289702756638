import React from 'react';
import { Flex } from 'theme-ui';

import { Checkbox } from 'components/ui/Checkbox';
import { Image } from 'components/ui/Image';

type Props = {
  url: string;
  onSelect: () => void;
  isSelected?: boolean;
};

const IMAGE_HEIGHT = 300;
const MIN_IMAGE_HEIGHT = 200;

export const AiPhotoSelect = ({ isSelected, onSelect, url }: Props): React.ReactElement => (
  <Flex
    sx={{
      position: 'relative',
      borderRadius: '0.875rem',
      width: '100%',
      minHeight: MIN_IMAGE_HEIGHT,
      border: '2px solid',
      borderColor: 'clockLog.photoSelect.border.default',
      cursor: 'pointer',
      ...(!isSelected && {
        '&:hover': {
          borderColor: 'clockLog.photoSelect.border.hover',
        },
      }),
      ...(isSelected && { borderColor: 'clockLog.photoSelect.border.selected' }),
    }}
  >
    <Checkbox
      onChange={onSelect}
      checked={isSelected}
      appendWith={
        <Image src={url} height={IMAGE_HEIGHT} sx={{ borderRadius: 'default' }} wrapperSx={{ position: 'static' }} />
      }
      name="selectedPhoto"
      sx={{
        gap: 0,
        '> .checkbox': {
          bg: 'clockLog.photoSelect.checkbox.bg',
          border: '2px solid',
          borderColor: 'clockLog.photoSelect.checkbox.border',
          position: 'absolute',
          top: '0.6rem',
          left: '0.6rem',
        },
      }}
    />
  </Flex>
);
