import isString from 'lodash/isString';

// FIXME: do wywalenia przy zmianach w team?

/**
 * Ensures the correct typying of the provided value.
 *
 * @param {string | undefined} value
 *
 * @return {string | undefined} Returns the parsed value.
 */

export const getTextField = (value?: string): string | undefined => {
  if (isString(value) && value !== '') {
    return value;
  }
  return undefined;
};
