import { Trans } from '@lingui/react';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { LinkAnchor } from 'components/ui/LinkAnchor';
import { isInewi } from 'constants/common';

const alertSx: ThemeUIStyleObject = {
  flexDirection: 'column',
  borderRadius: 'xs',
  px: 2,
  py: 1,
  fontSize: 2,
  fontWeight: 'bold',
  bg: 'requests.message.blue.bg',
  border: '1px solid',
  borderColor: 'requests.message.blue.border',
};

export const NoEmployeesAlert = () => (
  <Flex sx={alertSx}>
    <Text>
      <Trans id="requests.swap.no_employees.add_tag">
        1. Add a tag to connect employees who will be swapping shifts.
      </Trans>
    </Text>
    <Text>
      <Trans id="requests.swap.no_employees.pick_tag_settings">
        2. In the tag settings, select "Show teammates details in Calendar" and set it to "Within assigned tags."
      </Trans>
    </Text>
    <Text sx={{ mt: '0.5rem' }}>
      <Trans id="requests.swap.no_employees.success">That's it! 😊 Now you can submit a shift swap request.</Trans>
      <Text sx={{ fontWeight: 'normal' }}>
        {' '}
        –{' '}
        <LinkAnchor
          href={
            isInewi
              ? 'https://pomoc.inewi.pl/pl/article/tagi-szczegolowe-informacje-1bvex32/'
              : 'https://help.unrubble.com/en/article/tags-formerly-departments-b7ii75/'
          }
          target="_blank"
          sx={{ textDecoration: 'underline' }}
        >
          <Trans id="requests.swap.no_employees.learn-more">Learn more</Trans>
        </LinkAnchor>
      </Text>
    </Text>
  </Flex>
);
