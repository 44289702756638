import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';
import { RecoilValue, useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { exportAction, exportSelectedItemsAction, getExportOptionsAction } from 'api/actions/export/exportActions';
import {
  ExportActionProps,
  ExportDataType,
  ExportOptionsHeadersGroups,
  ExportResponse,
  ExportSelectedItemsActionProps,
  ExportSelectedItemsResponse,
  ExportType,
  GetExportOptionsResponse,
} from 'api/actions/export/exportActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { Checkbox } from 'components/ui/Checkbox';
import { Switch } from 'components/ui/Switch';
import { useFilterOutNonEditableEmployeesIds } from 'hooks/useFilterOutNonEditableEmployeesIds/useFilterOutNonEditableEmployeesIds';
import { parsedEmployeesSelector } from 'state/employees';
import { dateRangeRequestsUsageOverviewFilterAtom } from 'state/filters';
import { createEvent } from 'utils/createEvent';
import { dateTime } from 'utils/dateTime';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { DownloadExtensions } from '../renderers/DayDetailsRenderer/types';

import { DateRangeRadioPicker } from './components/DateRangeRadioPicker/DateRangeRadioPicker';
import { DateRange, useDateRangeRadioPicker } from './components/DateRangeRadioPicker/hooks/useDateRangeRadioPicker';

type Props = {
  dataType: ExportDataType;
  selectedIdsRecoilState: RecoilValue<string[]>;
  view: 'clockLog' | 'photoLog' | 'reports' | 'calendar' | 'requestsOverview' | 'team';
  variant: 'download' | 'print';
  requireIdsSelection?: boolean; // can be removed after back optimalization is done
};

export const DownloadPrintModal = ({
  dataType,
  selectedIdsRecoilState,
  view,
  variant,
  requireIdsSelection,
}: Props): React.ReactElement | null => {
  useLingui();
  const selectedIds = useRecoilValue(selectedIdsRecoilState);
  const parsedEmployees = useRecoilValue(parsedEmployeesSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGetExportOptions, setIsLoadingGetExportOptions] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [exportOptions, setExportOptions] = useState<GetExportOptionsResponse | null>(null);
  const [groupByMonths, setGroupByMonths] = useState<boolean>(false);
  const [dateRangeValue, setDateRangeValue] = useState(DateRange.CUSTOM);
  const { translateKeyToDate } = useDateRangeRadioPicker(
    view === 'requestsOverview' ? dateRangeRequestsUsageOverviewFilterAtom : undefined,
  );

  const hasSelectedIds = selectedIds.length > 0;

  const employeesIds = useMemo(() => {
    if (!parsedEmployees) return [];

    return [...parsedEmployees.keys()];
  }, [parsedEmployees]);
  const allEditableEmployeesIds = useFilterOutNonEditableEmployeesIds(employeesIds, 'Schedules');

  const formRef = useRef<HTMLFormElement | null>(null);

  const { register, handleSubmit, watch, formState } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const currentFormValues = watch();

  const params = useParams() as { extension: DownloadExtensions };
  const { extension } = params;
  const { handleClose, baseRoute } = useModal();

  const isDownloadable = variant === 'download';

  const { mutate: mutateExportOptions } = useMutation(getExportOptionsAction);
  const { mutate: mutateExport } = useMutation<ExportResponse, unknown, ExportActionProps>((body) =>
    exportAction(body, isDownloadable),
  );
  const { mutate: mutateExportSelectedItems } = useMutation<
    ExportSelectedItemsResponse,
    unknown,
    ExportSelectedItemsActionProps
  >((body) => exportSelectedItemsAction(body, isDownloadable));

  const exportTypeMap: Record<DownloadExtensions, ExportType> = useMemo(
    () => ({
      [DownloadExtensions.Csv]: ExportType.Csv,
      [DownloadExtensions.Pdf]: ExportType.Pdf,
      [DownloadExtensions.Xlsx]: ExportType.Xlsx,
    }),
    [],
  );

  const exportType = useMemo(() => exportTypeMap[extension], [exportTypeMap, extension]);

  useEffect(() => {
    const redirect = async () => {
      await delay(100);
      handleClose();
    };

    const getExportOptions = async () => {
      setIsLoadingGetExportOptions(true);

      const { error, payload } = await mutateExportOptions({
        exportDataType: dataType,
        exportType: variant === 'print' ? ExportType.Pdf : exportType,
      });

      if (error) {
        handleClose();
      }

      if (!error && payload) {
        setExportOptions(payload);

        if (payload?.groupByMonths && variant === 'download') setGroupByMonths(payload.groupByMonths);
      }

      setIsLoadingGetExportOptions(false);
    };

    if (!_.includes(DownloadExtensions, extension) && variant === 'download') {
      void redirect();
    } else {
      void getExportOptions();
    }
  }, [dataType, exportType, extension, handleClose, mutateExportOptions, variant]);

  const handleExportForEmployees = useCallback(
    async (selectedColumns: string[], selectedDateRange: [number, number]) => {
      // can be removed after back optimalization is done
      if (!hasSelectedIds && requireIdsSelection) return true;

      const { error } = await mutateExport({
        exportType: variant === 'print' ? ExportType.Pdf : exportType,
        exportDataType: dataType,
        peopleIds: hasSelectedIds ? selectedIds : allEditableEmployeesIds,
        selectedColumns,
        datesUnix: selectedDateRange,
        groupByMonth: variant === 'download' && groupByMonths,
      });

      return error;
    },
    [
      hasSelectedIds,
      requireIdsSelection,
      mutateExport,
      variant,
      exportType,
      dataType,
      selectedIds,
      allEditableEmployeesIds,
      groupByMonths,
    ],
  );

  const handleExportForSelectedItems = useCallback(
    async (selectedColumns: string[]) => {
      const { error } = await mutateExportSelectedItems({
        exportType,
        exportDataType: dataType,
        selectedColumns,
        selectedIds,
      });

      return error;
    },
    [dataType, exportType, mutateExportSelectedItems, selectedIds],
  );

  const handleSubmitCallback: SubmitHandler<Record<number, boolean>> = async (data) => {
    let selectedDateRange: [number, number] | undefined | null = null;
    selectedDateRange = translateKeyToDate(dateRangeValue);
    if (view === 'team' && !selectedDateRange) {
      // add default today date range for exporting people when we do not have any dateRangeValue in store
      const todayUnix = dateTime().unix();
      selectedDateRange = [todayUnix, todayUnix];
    }
    if (!selectedDateRange) return;
    setIsLoading(true);

    const selectedColumns = _.chain(data)
      .omitBy((val) => val === false)
      .keys()
      .value();

    let error: boolean;

    if (view === 'clockLog' && selectedIds.length > 0) {
      error = await handleExportForSelectedItems(selectedColumns);
    } else {
      error = await handleExportForEmployees(selectedColumns, selectedDateRange);
    }

    if (!error) {
      handleClose();
    } else {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = () => {
    const form = formRef.current;

    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onDateRangeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) setDateRangeValue(+value);
  }, []);

  useEffect(() => {
    if (
      _.chain(currentFormValues)
        .values()
        .find((v) => v === true)
        .value()
    ) {
      setIsDisabled(false);
    } else if (!isDisabled && formState.isDirty) {
      setIsDisabled(true);
    }
  }, [currentFormValues, formState.isDirty, isDisabled]);

  const modalTitle = useMemo(() => {
    if (variant === 'download' && dataType === ExportDataType.DetailsReport) {
      return `${t({ id: 'reports.download_detailed_report_as', message: 'Download detailed report as' })} ${extension}`;
    }

    if (variant === 'download') {
      return `${t({ id: 'reports.download_as', message: 'Download as' })} ${extension}`;
    }

    if (variant === 'print' && dataType === ExportDataType.TimeOffYearly) {
      return t({ id: 'export.print_yearly_time_offs', message: 'Print yearly time offs' });
    }

    return t({ id: 'export.print', message: 'Print' });
  }, [dataType, extension, variant]);

  const exportInfo = useMemo(() => {
    if ((view === 'calendar' || view === 'team') && (exportType === ExportType.Csv || exportType === ExportType.Xlsx)) {
      return t({ id: 'export.warning.empty_cells', message: "Empty cells won't be exported." });
    }

    return null;
  }, [exportType, view]);

  const showDateRangePicker = useMemo(() => {
    if (variant === 'print' && dataType === ExportDataType.TimeOffYearly) return false;

    if (view === 'clockLog' && selectedIds.length > 0) return false;

    if (view === 'requestsOverview') return false;

    if (view === 'team') return false;

    return true;
  }, [dataType, selectedIds.length, variant, view]);

  if (requireIdsSelection && !hasSelectedIds) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body sx={{ gap: 3 }}>
        {exportInfo && (
          <Text as="p" variant="p" sx={{ fontSize: 2, mt: '-1rem' }}>
            {exportInfo}
          </Text>
        )}
        {dataType === ExportDataType.TimeOffYearly && (
          <Text>
            <Trans id="calendar.print_yearly_time_offs">
              It can take up to 30s to generate yearly time offs. Please wait.
            </Trans>
          </Text>
        )}
        {showDateRangePicker && <DateRangeRadioPicker value={dateRangeValue} onChange={onDateRangeChange} />}
        {!isLoadingGetExportOptions ? (
          <>
            {!_.isUndefined(exportOptions?.groupByMonths) && (
              <Flex sx={{ flexDirection: 'column' }}>
                <Text as="h4" variant="heading4" sx={{ mb: 2 }}>
                  <Trans id="reports.download_export_other">Other:</Trans>
                </Text>

                <Switch
                  name="groupByMonths"
                  size="sm"
                  label={t({
                    id: 'download_modal.group_by_months',
                    message: 'Group days by monthly sheets',
                  })}
                  checked={groupByMonths}
                  onChange={(e) => {
                    setGroupByMonths(e.currentTarget.checked);
                  }}
                />
              </Flex>
            )}
            {exportOptions && (
              <form
                ref={formRef}
                id="export_form"
                onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}
                noValidate
              >
                <Flex sx={{ gap: 5, flexDirection: 'column' }}>
                  {_.map(exportOptions.headers, (group, groupKey: ExportOptionsHeadersGroups) => {
                    if (group.length < 1) return null;

                    return (
                      <Flex sx={{ flexDirection: 'column' }} key={groupKey}>
                        <Modal.SubTitle>
                          {groupKey === ExportOptionsHeadersGroups.Base && <Trans id="global.columns">Columns</Trans>}
                          {groupKey === ExportOptionsHeadersGroups.Additional && (
                            <Trans id="global.print_header.additional">Additional</Trans>
                          )}
                          {groupKey === ExportOptionsHeadersGroups.TimeEventType &&
                            t({ id: 'navbar.menu.work_statuses' })}
                          {groupKey === ExportOptionsHeadersGroups.TimeOffType && (
                            <Trans id="global.requests_types">Request types</Trans>
                          )}
                          {groupKey === ExportOptionsHeadersGroups.CustomRequestType && (
                            <Trans id="global.custom_requests_types">Custom request types</Trans>
                          )}
                          :
                        </Modal.SubTitle>
                        <Flex key={groupKey} sx={{ gap: 2, flexDirection: 'column', alignItems: 'flex-start' }}>
                          {_.map(group, (header) => (
                            <Checkbox
                              size="sm"
                              key={header.id}
                              defaultChecked={header.selected}
                              label={
                                <>
                                  {t({ id: header.name })}
                                  {header.archived && ` (${t({ id: 'report.archived', message: 'Archived' })})`}
                                </>
                              }
                              {...register(`${header.id}`)}
                            />
                          ))}
                        </Flex>
                      </Flex>
                    );
                  })}
                </Flex>
              </form>
            )}
          </>
        ) : (
          <CenteredLoadingSpinner size={4} />
        )}
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            ...(isDisabled && { disabled: isDisabled }),
            isLoading,
            onClick: handleFormSubmit,
            variant: 'primary',
            children: isDownloadable ? t({ id: 'global.download' }) : t({ id: 'global.print' }),
          },
        ]}
      />
    </>
  );
};
