import { t, Trans } from '@lingui/macro';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { Alert } from 'components/ui/Alert/Alert';
import { TO } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { activeSubscriptionSelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';

import { PzuSportBenefitDetails } from './components/PzuSportBenefitDetails';

export const YourBenefits: FC = () => {
  const language = useRecoilValue(languageSelector);
  const navigate = useAppNavigate();
  const { systemManagement } = useAppPermissions();
  const { canUseBenefits } = useRecoilValue(activeSubscriptionSelector) || {};

  useModal({ wrapperSx: { height: '680px' } });

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="your_benefits.title">Your benefits</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ fontSize: 2, gap: 4 }}>
        {!canUseBenefits && (
          <Alert
            variant="warning"
            {...(systemManagement.Payments && {
              action: {
                title: t({ id: 'your_benefits.alert.open_payments', message: 'Open Plan & Billing' }),
                onClick: () => navigate(TO.PAYMENTS[language]),
              },
            })}
          >
            {systemManagement.Payments ? (
              <Trans id="your_benefits.alert.inactive_subscription.admin">
                To access the benefits, make sure your subscription is active.
              </Trans>
            ) : (
              <Trans id="your_benefits.alert.inactive_subscription">
                To access the benefits, make sure your subscription is active. If needed, contact your organization's
                administrator for assistance.
              </Trans>
            )}
          </Alert>
        )}
        <PzuSportBenefitDetails disableActivation={!canUseBenefits} />
      </Modal.Body>
    </>
  );
};
