import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { useMinimizedModals } from 'components/Modal/output/useMinimizedModals';
import { TO_REL } from 'constants/routes';
import { MinimizeModalAddTeammate, MinimizedModal, ViewWithMinimizedModals } from 'state/modal';
import { getAddTeammateFormValuesAtom, getAvatarDetailsAtom } from 'state/team';

export const useMinimizeModalAddTeammate = () => {
  useLingui();
  const getAddTeammateFormValues = useRecoilValue(getAddTeammateFormValuesAtom);
  const { handleSetModalDetails, onHandleCloseModalWithMinimizedDetails } =
    useMinimizedModals<MinimizeModalAddTeammate>(ViewWithMinimizedModals.TEAM);
  const getAvatarDetails = useRecoilValue(getAvatarDetailsAtom);

  const onHandleMinimize = useCallback(
    (minimizeModal: () => void) => {
      const avatarDetails = getAvatarDetails ? getAvatarDetails() : undefined;

      handleSetModalDetails({
        minimizedModal: MinimizedModal.ADD_TEAMMATE,
        route: TO_REL.ADD_TEAMMATE_MODAL,
        title: t({ id: 'team.add_teammate' }),
        modalDetails: {
          addTeammate: getAddTeammateFormValues ? getAddTeammateFormValues() : undefined,
          avatarUrl: avatarDetails?.avatarUrl,
          avatarChanged: avatarDetails?.avatarChanged,
        },
      });

      minimizeModal();
    },
    [getAddTeammateFormValues, getAvatarDetails, handleSetModalDetails],
  );

  return {
    onHandleMinimize,
    onHandleCloseModalWithMinimizedDetails,
  };
};
