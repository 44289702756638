import { APP_VERSION } from 'constants/common';

/**
 * Adds a directory (folder named after current appVersion) to the path if in production enviroment.
 *
 * @param {string} basePath String that will be modified if in production enviroment.
 *
 * @return {string} Returns the string, if not in production enviroment the string will not be modified.
 */

export const getAssetsPath = (basePath: string) => {
  if (process.env.NODE_ENV !== 'production') return basePath;

  return basePath.replaceAll('assets/', `assets/${APP_VERSION}/`);
};
