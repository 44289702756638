import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { CloseButton } from './CloseButton';
import { Menu } from './Menu';
import { Title } from './Title';

type HeaderProps = FlexProps & {
  onCloseRoute?: string;
};

type HeaderComponent = React.FC<HeaderProps> & {
  Title: typeof Title;
  Menu: typeof Menu;
  CloseButton: typeof CloseButton;
};

export const Header: HeaderComponent = ({ children, sx, onCloseRoute, ...props }: HeaderProps): React.ReactElement => (
  <Flex {...props} as="header" variant="layouts.authorized.aside.details.header">
    {children}
    {onCloseRoute && <CloseButton onCloseRoute={onCloseRoute} />}
  </Flex>
);

Header.Title = Title;
Header.Menu = Menu;
Header.CloseButton = CloseButton;
