import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { Flex, Heading, Text } from 'theme-ui';

import {
  fetchGoogleCallbackAction,
  fetchQuickbooksCallbackAction,
  fetchSquareCallbackAction,
  fetchXeroCallbackAction,
} from 'api/actions/integrations/integrationActions';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { REFRESH_GOOGLE_INTEGRATIONS_MESSAGE, REFRESH_INTEGRATIONS_MESSAGE } from 'constants/settings';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useQuery } from 'hooks/useQuery/useQuery';

export type IntegrationTypes = 'quickbooks' | 'xero' | 'square' | 'google';

const actionMap: { [index in IntegrationTypes]: typeof fetchQuickbooksCallbackAction } = {
  quickbooks: fetchQuickbooksCallbackAction,
  xero: fetchXeroCallbackAction,
  square: fetchSquareCallbackAction,
  google: fetchGoogleCallbackAction,
};

type Props = {
  integration: IntegrationTypes;
};

export const PopUpIntegration = ({ integration }: Props): React.ReactElement => {
  useLingui();
  const { query } = useClient();
  const queryParams = useQuery();
  const queryParamsString = queryParams.toString();
  const [shouldShowMessage, setShouldShowMessage] = useState(false);

  const [success, setSuccess] = useState(false);

  const fetchIntegration = useCallback(async () => {
    const action = actionMap[integration];
    const data = await query(action({ query: queryParamsString }));
    return data;
  }, [integration, query, queryParamsString]);

  const handleIntegration = useCallback(async () => {
    if (queryParamsString.length) {
      const { error } = await fetchIntegration();

      if (!error) {
        setSuccess(true);
        const openerWindow: Window | undefined = window.opener;
        if (openerWindow) {
          const message = (() => {
            switch (integration) {
              case 'google':
                return REFRESH_GOOGLE_INTEGRATIONS_MESSAGE;
              default:
                return REFRESH_INTEGRATIONS_MESSAGE;
            }
          })();

          openerWindow.postMessage(message);
          window.self.close();
        }
      }

      if (error) {
        setSuccess(false);
      }

      setShouldShowMessage(true);
    }
  }, [queryParamsString, fetchIntegration, integration]);

  const handleIntegrationRef = useCallbackRef(handleIntegration);

  useEffect(() => {
    void handleIntegrationRef.current();
  }, [handleIntegrationRef]);

  return (
    <Flex
      sx={{
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <Flex
        sx={{
          alignSelf: 'center',
        }}
      >
        {shouldShowMessage ? (
          <Flex
            variant="payments.infoContainer"
            sx={{ gap: 5, p: 5, flexDirection: 'column', maxWidth: '500px', textAlign: 'center' }}
          >
            {success ? (
              <>
                <Heading variant="heading3">
                  <Trans id="settings.popup_success">Successfully connected your account</Trans>
                </Heading>

                <Text variant="pLead">
                  <Trans id="settings.popup_success.message">
                    Now you can close the window if it didn't close automatically.
                  </Trans>
                </Text>
              </>
            ) : (
              <>
                <Heading variant="heading3">
                  <Trans id="settings.popup_fail">Couldn't connect your account</Trans>
                </Heading>

                <Text variant="pLead">
                  <Trans id="settings.popup_fail.message">You can close this window and try again.</Trans>
                </Text>
              </>
            )}
          </Flex>
        ) : (
          <LoadingOverlay background="transparent" />
        )}
      </Flex>
    </Flex>
  );
};
