import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { InputOption, Select } from 'components/ui/Select/Select';
import { YourStatsPeriod, yourStatsPeriodAtom } from 'state/home';

export const YourStatsPeriodSelect = (): React.ReactElement => {
  useLingui();
  const [yourStatsPeriod, setYourStatsPeriod] = useRecoilState(yourStatsPeriodAtom);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setYourStatsPeriod(+e.target.value);
    },
    [setYourStatsPeriod],
  );

  const periodOptions: InputOption[] = useMemo(
    () => [
      { id: `${YourStatsPeriod.Month}`, label: t({ id: 'your_stats.month', message: 'Month' }) },
      { id: `${YourStatsPeriod.PayrollPeriod}`, label: t({ id: 'your_stats.pay_period', message: 'Pay Period' }) },
      {
        id: `${YourStatsPeriod.OvertimePeriod}`,
        label: t({ id: 'your_stats.overtime_period', message: 'Overtime pay period' }),
      },
    ],
    [],
  );

  return (
    <Select
      id="period"
      options={periodOptions}
      size="sm"
      sx={{ ml: 'auto', maxWidth: '240px' }}
      onChange={handleOnChange}
      defaultValue={yourStatsPeriod}
    />
  );
};
