import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { useRecoilValue } from 'recoil';

import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { Button, ButtonProps, LinkButton, LinkButtonProps } from 'components/ui/Buttons';
import { ButtonSharedProps } from 'components/ui/Buttons/types';
import { nightModeSelector } from 'Kiosk/state/kioskState';

const activeSx: ThemeUIStyleObject = {
  background: (t) => `${t?.colors?.kioskCameraViewButtonActive} !important`,
  color: 'kiosk.camera.text.active',
  '&:hover': {
    background: (t) => `${t?.colors?.kioskCameraViewButtonActiveHover} !important`,
  },
};

const warningSx: ThemeUIStyleObject = {
  background: (t) => `${t.colors?.kioskCameraViewButtonWarning} !important`,
  '&:hover': {
    background: (t) => `${t.colors?.kioskCameraViewButtonWarningHover} !important`,
  },
  '&::after': {
    content: '"!"',
    fontSize: 1,
    color: 'kiosk.camera.text.exclamation',
    display: 'block',
    position: 'absolute',
    width: '17px',
    height: '17px',
    lineHeight: '17px',
    background: 'kiosk.camera.bg.exclamation',
    top: '-4px',
    right: '-4px',
    borderRadius: 'circle',
  },
};

type Props = Omit<ButtonSharedProps, 'prependWith' | 'bgOverwrite' | 'size' | 'shape'> & {
  icon: Icons;
  to?: LinkButtonProps['to'];
  onClick?: ButtonProps['onClick'];
  active?: boolean;
  warning?: boolean;
};

const defaultProps: Partial<Props> = {
  onClick: undefined,
  to: undefined,
  active: false,
  warning: false,
};

export const CameraViewButton = ({ to, onClick, icon, active, warning, sx, ...props }: Props): React.ReactElement => {
  const nightMode = useRecoilValue(nightModeSelector);

  const buttonProps: ButtonSharedProps = {
    ...props,
    prependWith: <Icon type={icon} />,
    bgOverwrite: {
      default: 'kiosk.camera.btn.bg.default',
      hover: 'kiosk.camera.btn.bg.hover',
      tap: 'kiosk.camera.btn.bg.tap',
      disabled: 'kiosk.camera.btn.bg.disabled',
    },
    sx: {
      position: 'relative',
      fontSize: '1.25rem',
      color: nightMode ? 'kiosk.camera.btn.text.night' : 'kiosk.camera.btn.text.default',
      p: '6px',
      borderRadius: '14px',
      '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
        backdropFilter: 'blur(16px)',
      },
      ...(active && activeSx),
      ...(warning && warningSx),
      ...(sx && sx),
    },
  };
  if (to) {
    return <LinkButton to={to} {...buttonProps} />;
  }
  return <Button onClick={onClick} {...buttonProps} />;
};

CameraViewButton.defaultProps = defaultProps;
