import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import { withPopper } from '../PopperProvider/withPopper';
import { decorationSx, PopperProviderProps } from '../PopperProvider/PopperProvider';

import { Tooltip, TooltipProps } from './Tooltip';

type WithAdditionalProps = {
  tooltipProps?: TooltipProps;
  popperProps?: Omit<PopperProviderProps, 'children' | 'content'>;
  decorateChildren?: boolean;
  sx?: ThemeUIStyleObject;
};

export function withTooltip<T>(Component: React.ComponentType<T & WithAdditionalProps>) {
  const ComponentWithPopper = withPopper(Component);

  return React.forwardRef<HTMLElement, T & WithAdditionalProps>(
    ({ tooltipProps, popperProps, decorateChildren, sx, ...props }: T & WithAdditionalProps, ref): React.ReactElement =>
      tooltipProps ? (
        <ComponentWithPopper
          sx={{
            ...(sx && sx),
            ...(decorateChildren && decorationSx),
          }}
          // FIXME: didn't find a better way..
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(props as any)}
          ref={ref}
          popperProps={
            {
              placement: 'auto',
              withArrow: true,
              withAnimation: true,
              withPortal: true,
              delayShow: 100,
              ...popperProps,
              content: <Tooltip {...tooltipProps} />,
            } as PopperProviderProps
          }
        />
      ) : (
        <Component sx={sx} {...(props as unknown as T)} ref={ref} />
      ),
  );
}
