import { useCallback, useMemo } from 'react';
import { useClient, useQuery } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { getFraudResolutionPendingDataAction } from 'api/actions/timeEvent/timeEventActions';
import { antiSpoofingChecksAtom } from 'state/clockLog';

export const useAntiSpoofingChecks = () => {
  const [antiSpoofingChecks, setAntiSpoofingChecks] = useRecoilState(antiSpoofingChecksAtom);

  const isInitialized = useMemo(() => !!antiSpoofingChecks, [antiSpoofingChecks]);

  const { query: unabortableQuery } = useClient();
  const { query } = useQuery(getFraudResolutionPendingDataAction(), false);

  const fetchAntiSpoofingChecks = useCallback(
    async (unAbortable?: boolean) => {
      const { payload, error } = unAbortable
        ? await unabortableQuery(getFraudResolutionPendingDataAction())
        : await query();

      if (!error && payload) {
        setAntiSpoofingChecks(payload);
      }
    },
    [query, setAntiSpoofingChecks, unabortableQuery],
  );

  return {
    isInitialized,
    fetchAntiSpoofingChecks,
  };
};
