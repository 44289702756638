import { useRecoilValue } from 'recoil';

import { PermissionState, permissionStateAtom } from 'Kiosk/state/permissionState';
import { languageSelector } from 'state/recoilState';
import { TO } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';

type ReturnValue = {
  camera: PermissionState;
  location: PermissionState;
  showCameraRequiredSplash: () => void;
};

export const usePermissions = (): ReturnValue => {
  const { camera, location } = useRecoilValue(permissionStateAtom);
  const language = useRecoilValue(languageSelector);

  const navigate = useAppNavigate();

  const showCameraRequiredSplash = () => {
    navigate(TO.KIOSK__CAMERA_PERMISSION[language]);
  };

  return {
    camera: camera || PermissionState.checking,
    location,
    showCameraRequiredSplash,
  };
};
