import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import { resetFormButtonAtom, timeTrackingSettingsAtom } from 'state/settings';

export const useTimeTrackingSettingsCleanUp = () => {
  const resetFormButton = useResetRecoilState(resetFormButtonAtom);
  const resetTimeTrackingSettings = useResetRecoilState(timeTrackingSettingsAtom);

  useEffect(
    () => () => {
      resetFormButton();
      resetTimeTrackingSettings();
    },
    [resetFormButton, resetTimeTrackingSettings],
  );
};
