/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal, flushSync } from 'react-dom';
import { Flex, Heading, ThemeUIStyleObject, Container as ThemeUiContainer } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { useOnKeyboardEventQueue } from 'hooks/useOnKeyboardEventQueue/useOnKeyboardEventQueue';
import { useTheme } from 'styles/useTheme';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

import { ModalBackdrop } from './components/ModalBackdrop';
import { ModalContainer } from './components/ModalContainer';
import { ModalContext } from './components/ModalContext';
import { useModal } from './hooks/useModal';
import { useOpenInstanceTracker } from './hooks/useOpenInstancesTracker';
import {
  BackButtonComponent,
  BackButtonProps,
  BodyComponent,
  BodyProps,
  FooterComponent,
  FooterProps,
  HeaderComponent,
  HeaderProps,
  ModalComponent,
  ModalCustomHandleClose,
  ModalProps,
  SubTitleComponent,
  TitleComponent,
  TitleProps,
} from './types';

const CLOSING_ANIMATION_DURATION = 200;

const getInstanceNumber = (() => {
  let counter = 0;
  return () => {
    counter += 1;
    return counter;
  };
})();

const modalRoot = document.getElementById('modal-root');

export const Modal: ModalComponent = ({
  children,
  size = 'default',
  unClosable,
  onCloseRoute: onCloseRouteProp,
  path,
  sx,
  replaceMode,
  fullHeight,
  state,
  resolver,
  onClose,
  className,
  showMinimize,
  inRoute = true,
}: ModalProps): React.ReactElement | null => {
  const [isClosing, setIsClosing] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isExitCompleted, setIsExitCompleted] = useState(false);

  const [wrapperSx, setWrapperSx] = useState<ThemeUIStyleObject | undefined>(undefined);
  const [isFullHeight, setIsFullHeight] = useState<boolean | undefined>(undefined);
  const [customHandleClose, setCustomHandleClose] = useState<ModalCustomHandleClose | undefined>(undefined);
  const [closeOnBackdrop, setCloseOnBackdrop] = useState<boolean | undefined>(true);
  const [customHandleMinimize, setCustomHandleMinimize] = useState<
    ((minimizeModal: () => void) => void | Promise<void>) | undefined
  >(undefined);

  const navigate = useAppNavigate();

  const { theme } = useTheme();

  const instanceNumber = useMemo(getInstanceNumber, []);

  useOpenInstanceTracker(instanceNumber);

  const onCloseRoute = useMemo(() => {
    if (onCloseRouteProp) return onCloseRouteProp;

    if (path)
      return path
        .split('/')
        .filter((s) => !s.includes('?') && !s.includes('*'))
        .map(() => '../')
        .join('');

    return '../';
  }, [onCloseRouteProp, path]);

  const handleExitComplete = async (): Promise<void> => {
    await delay(50); // Didn't found a better way to do this.
    setIsExitCompleted(true);
  };

  const handleClose = useCallback(
    (customData?: any) => {
      if (!unClosable) {
        emitCustomEvent(CustomEvents.MODAL_CLOSE, { instance: instanceNumber, customData });
      }
    },
    [instanceNumber, unClosable],
  );

  const closeAnimation = useCallback(async () => {
    setIsClosing(true);
    await delay(CLOSING_ANIMATION_DURATION);
  }, []);

  const innerHandleClose = useCallback(async () => {
    await closeAnimation();
    setIsOpen(false);
    void handleExitComplete();
  }, [closeAnimation]);

  const innerHandleMinimize = useCallback(() => {
    flushSync(() => {
      setIsMinimized(true);
    });
    void innerHandleClose();
  }, [innerHandleClose]);

  const innerOnClose = useCallback(
    (customData?: any) => {
      if (customHandleClose) {
        void customHandleClose(floatingPromiseReturn(innerHandleClose), customData);
      } else {
        void innerHandleClose();
      }
    },
    [customHandleClose, innerHandleClose],
  );

  useCustomEventListener(CustomEvents.MODAL_CLOSE, ({ instance, customData }) => {
    if (instance === instanceNumber) {
      innerOnClose(customData);
    }
  });

  const handleMinimize = useCallback(() => {
    if (!showMinimize) return;

    if (customHandleMinimize) {
      void customHandleMinimize(innerHandleMinimize);
    } else {
      void innerHandleMinimize();
    }
  }, [customHandleMinimize, innerHandleMinimize, showMinimize]);

  useOnKeyboardEventQueue('Escape', handleClose);

  useEffect(() => {
    if (isExitCompleted) {
      if (onClose) {
        onClose();
      }
      if (resolver) {
        resolver(null);
      }
      if (!inRoute) return;

      navigate(onCloseRoute, { replace: replaceMode, relative: 'path' });
    }
  }, [isExitCompleted, replaceMode, onClose, resolver, onCloseRoute, inRoute, navigate]);

  const modalContextValue = useMemo(
    () => ({
      setWrapperSx,
      setIsFullHeight,
      setCustomHandleClose,
      setCloseOnBackdrop,
      setCustomHandleMinimize,
      handleClose,
      replaceMode: !!replaceMode,
      baseRoute: onCloseRoute,
      state,
      resolver,
      instanceNumber,
      handleMinimize,
      showMinimize,
    }),
    [handleClose, replaceMode, onCloseRoute, state, resolver, instanceNumber, handleMinimize, showMinimize],
  );

  return (
    modalRoot &&
    createPortal(
      <>
        {isOpen && (
          <ModalContext.Provider value={modalContextValue}>
            <ModalBackdrop
              sx={{ zIndex: theme.zIndices.modal + instanceNumber }}
              handleClose={closeOnBackdrop ? handleClose : undefined}
              isClosing={isClosing}
              isMinimized={isMinimized}
              closingAnimationDuration={CLOSING_ANIMATION_DURATION}
            >
              <ModalContainer
                key="modal"
                size={size}
                fullHeight={isFullHeight || fullHeight}
                sx={{
                  position: 'relative',
                  transition: 'width linear .075s, height linear .075s, max-height linear .075s ',
                  ...theme.modal[size],
                  ...(sx && sx),
                  ...(wrapperSx && wrapperSx),
                }}
                className={className}
              >
                {children}
              </ModalContainer>
            </ModalBackdrop>
          </ModalContext.Provider>
        )}
      </>,
      modalRoot,
    )
  );
};

export async function openModal<T = null>(id: string, state?: ModalProps['state']) {
  return new Promise<T>((resolve) => {
    emitCustomEvent(CustomEvents.MODAL_OPEN, { id, state, resolver: resolve });
  });
}

type ControlledModalProps = Omit<ModalProps, 'onCloseRoute'> & {
  id: string;
};

type ModalOptions = {
  id?: string;
  state?: ModalProps['state'];
  resolver?: ModalProps['resolver'];
};

export const ControlledModal = ({ id, ...restProps }: ControlledModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState<ModalProps['state']>();
  const [resolver, setResolver] = useState<ModalProps['resolver']>();

  useCustomEventListener(CustomEvents.MODAL_OPEN, (options) => {
    const modalOptions = options as ModalOptions;
    if (modalOptions?.id !== id) return;
    setIsOpen(true);
    if (!modalOptions) return;
    if (modalOptions.resolver) {
      setResolver(() => modalOptions.resolver);
    }

    if (!modalOptions.state) return;
    setState(modalOptions.state);
  });
  if (!isOpen) return null;
  return (
    <Modal
      state={state}
      resolver={resolver}
      onCloseRoute=""
      onClose={() => setIsOpen(false)}
      inRoute={false}
      {...restProps}
    />
  );
};

const Header: HeaderComponent = ({ children, ...props }: HeaderProps): React.ReactElement => {
  const { handleClose, handleMinimize, showMinimize } = useModal();

  return (
    <Flex sx={{ px: 4, pt: 1, gap: 2, minHeight: '60px', alignItems: 'center' }} {...props}>
      {children}
      {showMinimize && (
        <Button variant="minimal" shape="circle">
          <Icon size={28} type="minimize" onClick={handleMinimize} />
        </Button>
      )}
      <Button
        onClick={handleClose}
        variant="minimal"
        shape="circle"
        sx={{
          mr: '-0.75rem',
        }}
      >
        <Icon size={28} type="x" />
      </Button>
    </Flex>
  );
};

const Title: TitleComponent = ({ children, sx }: TitleProps): React.ReactElement => (
  <Heading
    as="h3"
    variant="heading3"
    sx={{
      mb: `0 !important`,
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      ...(sx && sx),
    }}
  >
    {children}
  </Heading>
);

const SubTitle: SubTitleComponent = ({ children, sx }: TitleProps): React.ReactElement => (
  <Heading
    as="h4"
    variant="heading4"
    sx={{
      mb: 2,
      ...(sx && sx),
    }}
  >
    {children}
  </Heading>
);

const BackButton: BackButtonComponent = ({ onClick, type = 'header' }: BackButtonProps): React.ReactElement => {
  const navigate = useAppNavigate();
  const handleBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (onClick) {
      onClick(e);
      return;
    }

    navigate(-1);
  };

  return type === 'header' ? (
    <Button
      onClick={handleBack}
      variant="minimal"
      size="sm"
      shape="circle"
      type="button"
      sx={{ ml: '-0.75rem' }}
      aria-label={i18n._(
        t({
          id: 'global.forms.buttons.back',
          message: 'Back',
        }),
      )}
    >
      <Icon size={28} type="chevronLeft" />
    </Button>
  ) : (
    <Button
      onClick={handleBack}
      variant="lightGrey"
      size="default"
      shape="rounded"
      type="button"
      aria-label={i18n._(
        t({
          id: 'global.forms.buttons.back',
          message: 'Back',
        }),
      )}
      sx={{
        pl: 2,
        fontWeight: '400',
      }}
    >
      <Icon wrapperSx={{ mr: 2 }} type="arrowLeft" /> {t({ id: 'global.forms.buttons.back' })}
    </Button>
  );
};

const Body: BodyComponent = React.forwardRef<HTMLDivElement, BodyProps>(({ children, sx, ...rest }: BodyProps, ref) => (
  <ThemeUiContainer
    sx={{
      fontSize: 2,
      display: 'flex',
      flexDirection: 'column',
      m: 0,
      flexGrow: 1,
      px: 4,
      py: 3,
      overflowY: 'auto',
      ...(sx && sx),
    }}
    {...rest}
    ref={ref}
  >
    {children}
  </ThemeUiContainer>
));

const Footer: FooterComponent = ({ children, sx, ...rest }: FooterProps): React.ReactElement | null => (
  <Flex
    sx={{
      px: 4,
      py: 3,
      gap: 2,
      justifyContent: 'flex-end',
      ...(sx && sx),
    }}
    {...rest}
  >
    {children}
  </Flex>
);

Footer.BackButton = BackButton;

Modal.Header = Header;
Modal.Title = Title;
Modal.SubTitle = SubTitle;
Modal.BackButton = BackButton;
Modal.Body = Body;
Modal.Footer = Footer;
