import { useRecoilValue } from 'recoil';

import { CameraState, cameraStateAtom } from 'Kiosk/state/cameraState';

type ReturnValue = CameraState;

export const useCamera = (): ReturnValue => {
  const state = useRecoilValue(cameraStateAtom);

  return state;
};
