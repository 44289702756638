import { makeTheme } from '../makeTheme';

export const PILL_PERSON_HEIGHT = 57;
const PILL_PERSON_MARGIN = 1;

export const attendancePillStyles = makeTheme({
  attendancePill: {
    container: {
      gap: 2,
      p: 2,
      flexDirection: 'column',
      bg: 'attendancePill.bg.default',
      borderRadius: 'default',
      transition: 'background 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
      '&:hover': {
        bg: 'attendancePill.bg.hover',
        boxShadow: 'attendancePill',
      },
      '&[data-is-empty="true"]': {
        pointerEvents: 'none',
        opacity: 0.35,
      },
      '&[data-is-open="true"]': {
        bg: 'attendancePill.bg.active',
        '&:hover': {
          bg: 'attendancePill.bg.activeHover',
        },
      },
      '&[data-has-error="true"]': {
        bg: 'attendancePill.bg.withError',
        outline: '1px solid',
        outlineColor: 'attendancePill.errorOutline',
        '&:hover': {
          bg: 'attendancePill.bg.withErrorHover',
        },
      },
    },
    containerHeader: {
      cursor: 'pointer',
      fontSize: 2,
      alignItems: 'center',
      gap: '0.75rem',
      m: -2,
      p: '0.75rem',
    },
    containerCounter: {
      color: 'attendancePill.counterText',
      fontWeight: 'bold',
      borderRadius: 'sm',
      width: '2rem',
      height: '2rem',
      alignItems: 'center',
      justifyContent: 'center',
      bg: 'attendancePill.bg.default',
    },
    containerTitle: {
      flex: '1 0',
      fontWeight: 'bold',
    },
  },
  attendancePillPerson: {
    container: {
      bg: 'attendancePill.person.default',
      maxHeight: PILL_PERSON_HEIGHT - PILL_PERSON_MARGIN,
      width: '100%',
      px: 2,
      py: 1,
      mb: `${PILL_PERSON_MARGIN}px`,
      alignItems: 'center',
      gap: 2,
      fontSize: 2,
      transition: 'background 0.2s ease-in-out',
      ':hover': {
        bg: 'attendancePill.person.hover',
      },
      // should be before &[data-has-error="true"]
      // because &[data-has-error="true"] should overwrites style from &[data-late="true"]
      '&[data-late="true"]': {
        bg: 'attendancePill.person.late',
        ':hover': {
          bg: 'attendancePill.person.lateHover',
        },
      },
      '&[data-has-error="true"]': {
        bg: 'attendancePill.person.withError',
        ':hover': {
          bg: 'attendancePill.person.withErrorHover',
        },
      },
    },
    additionalInfo: {
      fontSize: 1,
      gap: 1,
      alignItems: 'flex-end',
      flexDirection: 'column',
      minWidth: 'auto',
    },
  },
});
