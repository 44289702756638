import dayjs from 'dayjs';

import { getDateFromSeconds } from 'utils/dateTime';

/**
 * Returns time in seconds.
 * ex. 01:00:00 will always return 3600 (1x6x60)
 * Timezone has no effect on the result.
 *
 * @param {number} hours Hours.
 * @param {number} minutes Minutes.
 * @param {number | undefined} seconds Seconds - Optional.
 * @return {number} time in seconds.
 */
export const getTimeInSeconds = (hours: number, minutes: number, seconds = 0): number => {
  const timeInSeconds = dayjs
    .duration({
      hours,
      minutes,
      seconds,
    })
    .asSeconds();

  return timeInSeconds;
};

/**
 * Returns a Time Object {hours, minutes, seconds, am} ready to display.
 *
 * @param {string | number} timeInSeconds time.
 * @param {'12' | '24'} timeFormat 12 or 24.
 * @return {} Object with hours, minutes and am/pm?.
 */
export const getTimeObjectFromSeconds = (
  timeInSeconds: string | number,
  timeFormat: string,
): { hours: string; minutes: string; seconds: string; am: 'AM' | 'PM' | undefined } => {
  const internalDate = getDateFromSeconds(timeInSeconds);

  const timeObject = {
    hours: internalDate.format(timeFormat === '12' ? 'hh' : 'HH'),
    minutes: internalDate.format('mm'),
    seconds: internalDate.format('ss'),
    am: timeFormat === '12' ? (internalDate.format('A') as 'AM' | 'PM') : undefined,
  };

  return timeObject;
};
