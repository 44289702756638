import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { CHAT_BOT } from 'constants/chat';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useOnKeyboardEventQueue } from 'hooks/useOnKeyboardEventQueue/useOnKeyboardEventQueue';
import { useOnOutsideClick } from 'hooks/useOnOutsideClick/useOnOutsideClick';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import {
  anyChatWindowIsVisibleSelector,
  anyWindowIsVisibleSelector,
  chatWindowIdsSelector,
  crispIsVisibleAtom,
  hasPermissionToUseCrispSelector,
} from 'state/chat';
import { userDetailsSelector } from 'state/userSession';
import { useCrisp } from '../../hooks/useCrisp';

import { ChatWindow } from './components/ChatWindow/ChatWindow';
import { MemoizedMainWindow } from './components/MainWindow/MainWindow';

const AnimatedFlex = motion(Flex);

let screenChatRoot = document.getElementById('chat-root');

if (!screenChatRoot) {
  screenChatRoot = document.createElement('div');
  screenChatRoot.setAttribute('id', 'chat-root');
}

export const Chat = (): React.ReactElement | null => {
  const [isHovered, setIsHovered] = useState(false);
  const chatRef = useRef<HTMLDivElement | null>(null);

  const crispIsVisible = useRecoilValue(crispIsVisibleAtom);
  const hasPermissionToUseCrisp = useRecoilValue(hasPermissionToUseCrispSelector);
  const userDetails = useRecoilValue(userDetailsSelector);
  const chatWindowIds = useRecoilValue(chatWindowIdsSelector);
  const anyWindowIsVisible = useRecoilValue(anyWindowIsVisibleSelector);
  const anyChatWindowIsVisible = useRecoilValue(anyChatWindowIsVisibleSelector);
  const { isAccountBlocked, isTooManyUsersBlocked } = useAppPermissions();
  const isAppActive = !isAccountBlocked && !isTooManyUsersBlocked;

  const { closeCrisp } = useCrisp();
  const { isMobileBreakpoint } = useThemeBreakpoint();

  const isMobileChat = useMemo(() => isMobileBreakpoint, [isMobileBreakpoint]);

  const handleIsHovered = useCallback(
    (value: boolean) => {
      if (isHovered !== value) {
        setIsHovered(value);
      }
    },
    [isHovered],
  );

  const handleOnClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (isMobileChat) {
        e.preventDefault();
        e.stopPropagation();
        setIsHovered(true);
      }
    },
    [isMobileChat],
  );

  useOnKeyboardEventQueue('Escape', () => closeCrisp(), crispIsVisible);

  useOnOutsideClick(chatRef, () => {
    if (isMobileChat && isHovered) setIsHovered(false);
  });

  useEffect(() => {
    if (!anyWindowIsVisible) setIsHovered(false);
  }, [anyWindowIsVisible]);

  const rootVariants = useMemo(
    () => ({
      initial: {
        x: 0,
        transition: {
          when: 'afterChildren',
          delayChildren: 0.4,
        },
      },
      visible: {
        x: 0,
        transition: {
          when: 'beforeChildren',
          staggerChildren: 0.04,
          ...(!isMobileChat && { delayChildren: anyWindowIsVisible ? 0 : 0.2 }),
        },
      },
    }),
    [anyWindowIsVisible, isMobileChat],
  );

  const chatWindows = useMemo(
    () => (isAppActive ? chatWindowIds : chatWindowIds?.filter((id) => id === CHAT_BOT)),
    [chatWindowIds, isAppActive],
  );
  const renderAllChatWindows = useCallback(
    () => (
      <AnimatedFlex
        ref={chatRef}
        key="chat.root"
        variant="chat.root"
        variants={rootVariants}
        initial="initial"
        animate={isHovered || anyWindowIsVisible ? 'visible' : 'initial'}
        {...(!isMobileChat && {
          onHoverStart: () => handleIsHovered(true),
          onHoverEnd: () => handleIsHovered(false),
        })}
        onClick={handleOnClick}
        sx={{
          // wait for userDetails to avoid chat flashing (hasPermissionToUseCrispSelector uses userDetailsSelector)
          ...(crispIsVisible && (hasPermissionToUseCrisp || !userDetails) && { visibility: 'hidden' }),
          ...(isMobileChat &&
            !isHovered &&
            chatWindows.length > 0 &&
            isAppActive && {
              '> div *': {
                pointerEvents: 'none',
              },
            }),
        }}
      >
        <Flex
          key={`${isAppActive}`}
          variant="chat.chatWindowsContainer"
          {...(anyChatWindowIsVisible &&
            isMobileChat && {
              sx: {
                zIndex: 2,
              },
            })}
        >
          {chatWindows.length > 0 &&
            chatWindows.map((id, index) => (
              <ChatWindow id={id} index={index} key={id} {...(!isAppActive && { variants: { initial: { x: 0 } } })} />
            ))}
        </Flex>

        {isAppActive && <MemoizedMainWindow />}
      </AnimatedFlex>
    ),
    [
      anyChatWindowIsVisible,
      anyWindowIsVisible,
      chatWindows,
      crispIsVisible,
      handleIsHovered,
      handleOnClick,
      hasPermissionToUseCrisp,
      isHovered,
      isMobileChat,
      rootVariants,
      isAppActive,
      userDetails,
    ],
  );

  return screenChatRoot && createPortal(renderAllChatWindows(), screenChatRoot);
};
