import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { DefaultRole } from 'api/actions/organizationSession/organizationSessionActions.types';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { useMount } from 'hooks/useMount/useMount';
import { FilterGroup } from 'layouts/AuthorizedApp/AsideFilters/FilterGroup';
import { FilterSearchInput } from 'layouts/AuthorizedApp/AsideFilters/FilterSearchInput';
import { useTagOrRoleFilters } from 'layouts/AuthorizedApp/AsideFilters/hooks/useTagOrRoleFilters';
import {
  FilterGroupNames,
  FilterGroupProps,
  TagsFilters,
  TeammatesFilters,
} from 'layouts/AuthorizedApp/AsideFilters/types';
import { rolesSelector, tagsSelector } from 'state/employees';
import {
  SEARCH_FILTER_TYPE,
  filterGroupStateAtomFamily,
  pickTeammatesFiltersRestoredAtom,
  searchFilterValueSelectorFamily,
} from 'state/filters';

export const PickTeammatesFilters = (): React.ReactElement => {
  useLingui();
  const tags = useRecoilValue(tagsSelector);
  const roles = useRecoilValue(rolesSelector);
  const [pickTeammatesFiltersRestored, setPickTeammatesFiltersRestored] = useRecoilState(
    pickTeammatesFiltersRestoredAtom,
  );
  const teammatesFilterState = useRecoilValue(
    filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES_PICK_TEAMMATES_FILTERS),
  );
  const tagsFilterState = useRecoilValue(filterGroupStateAtomFamily(FilterGroupNames.TAGS_PICK_TEAMMATES_FILTERS));
  const rolesFilterState = useRecoilValue(filterGroupStateAtomFamily(FilterGroupNames.ROLES_PICK_TEAMMATES_FILTERS));
  const searchInputState = useRecoilValue(
    searchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.TEAMMATES_PICK_TEAMMATES_FILTERS),
  );
  const defaultTeammatesFilterState = useRecoilValue(filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES));
  const defaultTagsFilterState = useRecoilValue(filterGroupStateAtomFamily(FilterGroupNames.TAGS));
  const defaultRolesFilterState = useRecoilValue(filterGroupStateAtomFamily(FilterGroupNames.ROLES));
  const defaultSearchInputState = useRecoilValue(searchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.TEAMMATES));
  const minimizedDetails = useMinimizeLocationState();
  const getTagOrRoleFilter = useTagOrRoleFilters();

  const filtersDefaultState = useRef({
    teammatesFilterState:
      !pickTeammatesFiltersRestored && !minimizedDetails ? defaultTeammatesFilterState : teammatesFilterState,
    tagsFilterState: !pickTeammatesFiltersRestored && !minimizedDetails ? defaultTagsFilterState : tagsFilterState,
    rolesFilterState: !pickTeammatesFiltersRestored && !minimizedDetails ? defaultRolesFilterState : rolesFilterState,
    searchInputState: !pickTeammatesFiltersRestored && !minimizedDetails ? defaultSearchInputState : searchInputState,
  });

  // TEAMMATES
  const teammatesStateFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: TeammatesFilters.ACTIVE,
        label: t({ id: 'aside_filters.teammates.active', message: 'Active' }),
      },
      {
        id: TeammatesFilters.INVITED,
        label: t({ id: 'aside_filters.teammates.invited', message: 'Invited' }),
      },
      {
        id: TeammatesFilters.HIDDEN,
        label: t({ id: 'aside_filters.teammates.hidden', message: 'Hidden' }),
      },
      {
        id: TeammatesFilters.DEACTIVATED,
        label: t({ id: 'aside_filters.teammates.deactivated', message: 'Deactivated' }),
      },
    ],
    [],
  );

  const rolesFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      ...(roles
        ? _.chain(roles)
            .map(getTagOrRoleFilter(true, roles.length > 4, true))
            .sortBy([
              ({ id }) => id && (+id as DefaultRole) !== DefaultRole.Employee,
              ({ id }) => id && (+id as DefaultRole) !== DefaultRole.Supervisor,
              ({ id }) => id && (+id as DefaultRole) !== DefaultRole.Manager,
              ({ id }) => id && (+id as DefaultRole) !== DefaultRole.Admin,
              ({ label }) => label,
            ])
            .value()
        : []),
    ],
    [getTagOrRoleFilter, roles],
  );

  const tagsFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: TagsFilters.NO_TAGS,
        label: t({ id: 'aside_filters.no_tags', message: 'No tags' }),
        isUnfiltrable: true,
      },
      ...(tags
        ? _.chain(tags)
            .map(getTagOrRoleFilter(false, false, true))
            .orderBy([({ isInformational }) => !!isInformational, ({ label }) => label], ['asc', 'asc'])
            .value()
        : []),
    ],
    [getTagOrRoleFilter, tags],
  );

  useMount(() => {
    setPickTeammatesFiltersRestored(true);
  });

  return (
    <>
      <FilterSearchInput
        type={SEARCH_FILTER_TYPE.TEAMMATES_PICK_TEAMMATES_FILTERS}
        defaultSearchInputValue={filtersDefaultState.current.searchInputState}
      />
      <FilterGroup
        name={FilterGroupNames.TEAMMATES_PICK_TEAMMATES_FILTERS}
        title={t({ id: 'aside_filters.teammates', message: 'Teammates' })}
        filters={teammatesStateFilters}
        defaultFilterState={filtersDefaultState.current.teammatesFilterState}
        openByDefault={false}
      />
      <FilterGroup
        name={FilterGroupNames.ROLES_PICK_TEAMMATES_FILTERS}
        searchFilterType={SEARCH_FILTER_TYPE.ROLES__FILTER_GROUP}
        title={t({ id: 'aside_filters.roles', message: 'Roles' })}
        filters={rolesFilters}
        defaultFilterState={filtersDefaultState.current.rolesFilterState}
      />
      <FilterGroup
        name={FilterGroupNames.TAGS_PICK_TEAMMATES_FILTERS}
        searchFilterType={SEARCH_FILTER_TYPE.TAGS__FILTER_GROUP}
        title={t({ id: 'aside_filters.tags', message: 'Tags' })}
        filters={tagsFilters}
        defaultFilterState={filtersDefaultState.current.tagsFilterState}
      />
    </>
  );
};
