/** @jsxImportSource theme-ui */

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { PATH_REL, TO_REL } from 'constants/routes';
import { DELETE_PHOTOS_FORM_ID } from 'constants/settings';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useLocationOnce } from 'hooks/useLocationOnce/useLocationOnce';
import { AdvancedDeletePhotosForm } from 'pages/Settings/output/AdvancedDeletePhotosForm';
import { ConfirmDeleteAdvancedModal } from 'pages/Settings/output/ConfirmDeleteAdvancedModal';
import { languageSelector } from 'state/recoilState';

export const DeletePhotos: FC = () => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const { baseRoute, handleClose } = useModal();
  const { state } = useLocationOnce();
  const { ids } = state || {};
  const [selectedEmployeesIds] = useState(ids || []);
  const navigate = useAppNavigate();

  const handlePush = () => {
    navigate(TO_REL.BULK_ACTIONS_MODAL__CONFIRM_DELETION_MODAL[language], {
      state: {
        preventClose: true,
      },
    });
  };

  const onSubmitSuccess = useCallback(() => handleClose(), [handleClose]);

  if (!selectedEmployeesIds.length) return <Navigate to={baseRoute} relative="path" />;

  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.delete_photos_bulk">Delete all photos</Trans>
        </Modal.Title>
      </Modal.Header>
      <AdvancedDeletePhotosForm
        employeesIds={selectedEmployeesIds}
        onSubmitSuccess={onSubmitSuccess}
        renderer={(formRenderer, loading, passwordValue) => (
          <>
            <Modal.Body>{formRenderer()}</Modal.Body>
            <BasicModalFooter
              buttons={[
                {
                  disabled: !passwordValue || loading,
                  isLoading: loading,
                  onClick: handlePush,
                  variant: 'danger',
                  children: t({ id: 'team.delete_photos_bulk.delete', message: 'Delete' }),
                },
              ]}
            />
          </>
        )}
      />
      <Routes>
        <Route
          path={PATH_REL.BULK_ACTIONS_MODAL__CONFIRM_DELETION_MODAL[language]}
          element={
            <Modal size="xs">
              <ConfirmDeleteAdvancedModal
                formId={DELETE_PHOTOS_FORM_ID}
                message={t({
                  id: 'team.bulk_actions.delete_photos',
                  message: "You're about to delete all photos.",
                })}
              />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
