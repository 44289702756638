import React from 'react';
import { Flex } from 'theme-ui';
import _ from 'lodash';

import { GridRendererProps } from '../types';
import { MemorizedGridCell } from '../GridCell';
import { GRID_DEFAULT_HEIGHT, GUTTER_DEFAULT_SIZE } from '../constants';

export const gridRenderer = ({
  data,
  style,
  rowIndex,
  columnIndex,
  isScrolling,
  ...props
}: GridRendererProps): JSX.Element | null => {
  const { showHeader, columns, name, select, list, variant, frozenColumns, isRowUncheckableValidator } = data;
  const rowData = _.isArray(list) ? list[rowIndex] : list.get(`${rowIndex}`);
  const columnData = columns[columnIndex];

  if (rowData) {
    let innerStyle = style;

    if (showHeader) {
      innerStyle = {
        ...innerStyle,
        top: (parseInt(`${innerStyle.top}`, 10) || 0) + GRID_DEFAULT_HEIGHT,
      };
    }

    innerStyle = {
      ...innerStyle,
      height: parseInt(`${innerStyle.height}`, 10) - GUTTER_DEFAULT_SIZE,
      width: parseInt(`${innerStyle.width}`, 10) - GUTTER_DEFAULT_SIZE,
      margin: GUTTER_DEFAULT_SIZE / 2,
    };

    const showCheckbox = select && columnIndex === 0 ? select : undefined;
    const isFrozen = frozenColumns && frozenColumns < columns.length && columnIndex <= frozenColumns - 1;

    if (isFrozen) return null;

    const isUncheckable = () => isRowUncheckableValidator && isRowUncheckableValidator(rowData.id);

    return (
      <Flex key={`${rowIndex}${columnIndex}`} style={innerStyle}>
        <MemorizedGridCell
          {...props}
          id={rowData.id}
          data={rowData}
          columnData={columnData}
          listName={name}
          select={showCheckbox}
          variant={variant}
          uncheckable={isUncheckable()}
        />
      </Flex>
    );
  }

  return null;
};
