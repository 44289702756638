import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { drawBoundingBox, drawBoxForCheckIfFaceIsInPlace, drawPoints, drawPointsForAxisXY } from 'Kiosk/helpers';
import { windowSizeAtom } from 'state/recoilState';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { debugModeFacePredictionsAtom, debugModeSettingsAtom } from 'Kiosk/state/debugState';

// TODO:
// 1. Add FaceInPlace boolean;

export const DebugFacePredictionModel = (): React.ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { isLandscape } = useRecoilValue(windowSizeAtom);
  const { source } = useRecoilValue(cameraStateAtom);
  const prediction = useRecoilValue(debugModeFacePredictionsAtom);
  const debugModeSettings = useRecoilValue(debugModeSettingsAtom);

  useEffect(() => {
    if (canvasRef.current && source) {
      canvasRef.current.width = source.video.videoWidth;
      canvasRef.current.height = source.video.videoHeight;
    }
  }, [source]);

  useEffect(() => {
    let requestId: number;

    const ctx = canvasRef?.current?.getContext('2d');

    if (ctx && source) {
      const renderDraw = () => {
        const {
          drawBoundingBox: drawBoundingBoxSetting,
          drawAxisPoints,
          drawPredictionPoints,
          drawFaceInPlaceBounding,
        } = debugModeSettings;

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        if (drawFaceInPlaceBounding) {
          drawBoxForCheckIfFaceIsInPlace(ctx, source.video, true);
        }
        if (drawPredictionPoints) {
          drawPoints(prediction, ctx);
        }
        if (drawAxisPoints) {
          drawPointsForAxisXY(prediction, ctx);
        }
        if (drawBoundingBoxSetting) {
          drawBoundingBox(prediction, ctx);
        }

        requestId = requestAnimationFrame(renderDraw);
      };

      renderDraw();
    }

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [debugModeSettings, prediction, source]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) scaleX(-1) translateZ(0)',
        height: isLandscape ? '100%' : 'auto',
        width: isLandscape ? 'auto' : '100%',
        zIndex: -3,
      }}
    />
  );
};
