import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';

import { IndustryListItem } from 'api/actions/data/dataActions.types';
import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import {
  ActiveSubscription,
  ApprovalSettings,
  BetaFeature,
  BetaFeatureDetails,
  ChatUserDetails,
  DefaultRolePermissions,
  Employee,
  FetchOrganizationSessionResponse,
  NormalPayrollPeriod,
  OvertimePayrollPeriod,
  RequestType,
  Role,
  StartingWeekDay,
  Tag,
  TimeEventType,
  TimeOffType,
  TimeTrackingSettings,
  WorkPosition,
  WorkdayAvailabilityState,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { OrganizationAccountState } from 'api/actions/payments/paymentsActions.types';
import { dateTime } from 'utils/dateTime';

export type OrganizationSessionAtomType = FetchOrganizationSessionResponse & {
  employeesMap: Map<string, Employee>;
  chatUsersMap: Map<string, ChatUserDetails>;
  tagsMap: Map<string, Tag>;
  rolesMap: Map<string, Role>;
  workPositionsMap: Map<string, WorkPosition>;
  timeEventTypeMap: Map<string, TimeEventType>;
  chatUsersWithoutEmployeesMap: Map<string, Pick<Employee, 'id' | 'name' | 'avatarUrl'>> | null;
};

export const organizationSessionAtom = atom<OrganizationSessionAtomType | null>({
  key: 'organizationSession',
  default: null,
});

export const industryListAtom = atom<IndustryListItem[] | undefined>({
  key: 'industryList',
  default: undefined,
});

// use with type assertion
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const organizationSessionPropertySelectorFamily = selectorFamily<unknown | null, string>({
  key: 'organizationSessionPropertySelector',
  get:
    (propertyName) =>
    ({ get }) => {
      const organizationSession = get(organizationSessionAtom);
      if (!organizationSession) return null;
      const selectedPropertyValue = _.get(organizationSession, propertyName);
      if (!selectedPropertyValue) return null;
      return selectedPropertyValue as unknown;
    },
});

export const organizationPayrollPeriodSelector = selector<NormalPayrollPeriod | null>({
  key: 'organizationSession__payrollPeriod',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;

    const { payrollPeriod } = organizationSession;
    return payrollPeriod;
  },
});

export const organizationOvertimePeriodSelector = selector<OvertimePayrollPeriod | null>({
  key: 'organizationSession__overtimePeriod',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;

    const { overtimePeriod } = organizationSession;
    return overtimePeriod;
  },
});

export const organizationDisplayCurrencySelector = selector<string | null>({
  key: 'organizationSession__displayCurrency',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;

    const { displayCurrency } = organizationSession;
    return displayCurrency;
  },
});

export const timeOffTypesSelector = selector<TimeOffType[] | null>({
  key: 'timeOffType',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { timeOffTypes } = organizationSession;

    return timeOffTypes;
  },
});

type ManageableTimeOffType = TimeOffType & {
  isNonEditable: boolean;
};

export const betaFeatureDetailsSelectorFamily = selectorFamily<BetaFeatureDetails | null, BetaFeature>({
  key: 'betaFeatureDetailsSelector',
  get:
    (betaFeature) =>
    ({ get }) => {
      const organizationSession = get(organizationSessionAtom);
      if (!organizationSession) return null;
      const { betaSettings } = organizationSession;

      const betaFeatureDetails = betaSettings.find(({ feature }) => feature === betaFeature);

      return betaFeatureDetails || null;
    },
});

export const manageableTimeOffTypesSelector = selector<ManageableTimeOffType[] | null>({
  key: 'manageableTimeOffTypes',
  get: ({ get }) => {
    const timeOffTypes = get(timeOffTypesSelector);
    if (!timeOffTypes) return null;

    const manageableTimeOffTypes = timeOffTypes
      .filter(({ isManageable }) => isManageable)
      .map((timeOffType) => ({ ...timeOffType, isNonEditable: timeOffType.isDefault }));

    return manageableTimeOffTypes;
  },
});

export const customRequestTypesSelector = selector<RequestType[] | null>({
  key: 'customRequestType',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { customRequestTypes } = organizationSession;

    return customRequestTypes;
  },
});

type ManageableCustomRequestType = RequestType & {
  isNonEditable: boolean;
};

export const manageableCustomRequestTypesSelector = selector<ManageableCustomRequestType[] | null>({
  key: 'manageableCustomRequestTypes',
  get: ({ get }) => {
    const customRequestTypes = get(customRequestTypesSelector);
    if (!customRequestTypes) return null;

    const manageableCustomRequestTypes = customRequestTypes
      .filter(({ isManageable }) => isManageable)
      .map((customRequestType) => ({ ...customRequestType, isNonEditable: customRequestType.isDefault }));

    return manageableCustomRequestTypes;
  },
});

export const startingWeekDaySelector = selector<StartingWeekDay | null>({
  key: 'startingWeekDay',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { startingWeekDay } = organizationSession;

    return startingWeekDay;
  },
});

export const taxIdSelector = selector<string | null>({
  key: 'taxId',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { taxId } = organizationSession;

    return taxId;
  },
});

export type WorkPositionDictionarySelectorType = Record<string, WorkPosition> | null;

export const workPositionDictionarySelector = selector<WorkPositionDictionarySelectorType>({
  key: 'organizationSession__dictionary__workPositions',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { workPositions } = organizationSession;

    return _.keyBy(workPositions, 'id');
  },
});

export type TimeOffTypesDictionarySelectorType = Record<string, TimeOffType> | null;

export const timeOffTypesDictionarySelector = selector<TimeOffTypesDictionarySelectorType>({
  key: 'timeOffTypesDictionary',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { timeOffTypes } = organizationSession;

    return _.keyBy(timeOffTypes, 'id');
  },
});

export type CustomRequestTypesDictionarySelectorType = Record<string, RequestType> | null;

export const customRequestTypesDictionarySelector = selector<CustomRequestTypesDictionarySelectorType>({
  key: 'customRequestTypesDictionary',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { customRequestTypes } = organizationSession;

    return _.keyBy(customRequestTypes, 'id');
  },
});

export type TimeEventTypesDictionarySelectorType = Record<string, TimeEventType> | null;

export const timeEventTypesDictionarySelector = selector<TimeEventTypesDictionarySelectorType>({
  key: 'timeEventTypesDictionary',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { timeEventTypes } = organizationSession;

    return _.keyBy(timeEventTypes, 'id');
  },
});

export const timeTrackingSettingsSelector = selector<TimeTrackingSettings | null>({
  key: 'timeTrackingSettings__organizationSession',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);

    return organizationSession?.timeTrackingSettings ?? null;
  },
});

export const isLocationRequiredSelector = selector<boolean>({
  key: 'timeTrackingSettings__organizationSession__isLocationRequired',
  get: ({ get }) => {
    const timeTrackerSettings = get(timeTrackingSettingsSelector);
    const isLocationRequired = !!timeTrackerSettings?.isLocationRequired;

    return isLocationRequired;
  },
});

export const defaultTagFeaturesSelector = selector<OrganizationSessionAtomType['defaultTagFeatures'] | null>({
  key: 'defaultTagFeatures',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { defaultTagFeatures } = organizationSession;

    return defaultTagFeatures;
  },
});

export const defaultRolePermissionsSelector = selector<DefaultRolePermissions | null>({
  key: 'defaultRolePermissions',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { defaultRolePermissions } = organizationSession;

    return defaultRolePermissions;
  },
});

export const activeSubscriptionSelector = selector<ActiveSubscription | null>({
  key: 'activeSubscription',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { activeSubscription } = organizationSession;

    return activeSubscription;
  },
});

export const nextPaymentDateSelector = selector<string | null>({
  key: 'nextPaymentDate',
  get: ({ get }) => {
    const activeSubscription = get(activeSubscriptionSelector);

    if (!activeSubscription) return null;

    const { state, endDate } = activeSubscription;

    if (state === OrganizationAccountState.Expired) return null;

    return dateTime(endDate, { utc: true }).format('ll');
  },
});

export const timeOffApprovalSettingsSelector = selector<ApprovalSettings | null>({
  key: 'timeOffApprovalSettings',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { timeOffApprovalSettings } = organizationSession;

    return timeOffApprovalSettings;
  },
});

export const timeTrackingApprovalSettingsSelector = selector<ApprovalSettings | null>({
  key: 'timeTrackingApprovalSettings',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { timeTrackingApprovalSettings } = organizationSession;

    return timeTrackingApprovalSettings;
  },
});

export const businessTripsApprovalSettingsSelector = selector<ApprovalSettings | null>({
  key: 'businessTripsApprovalSettings',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { businessTripsApprovalSettings } = organizationSession;

    return businessTripsApprovalSettings;
  },
});

export const customRequestsApprovalSettingsSelector = selector<ApprovalSettings | null>({
  key: 'customRequestsApprovalSettings',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { customRequestsApprovalSettings } = organizationSession;

    return customRequestsApprovalSettings;
  },
});

export const organizationDetailsSelector = selector<OrganizationSessionAtomType['details'] | null>({
  key: 'organizationDetails',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { details } = organizationSession;

    return details;
  },
});

export const activeIntegrationsSelector = selector<ServiceIntegration[] | null>({
  key: 'activeIntegrations',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { activeIntegrations } = organizationSession;

    return activeIntegrations;
  },
});

export const importActiveIntegrationsSelector = selector<ServiceIntegration[] | null>({
  key: 'importActiveIntegrations',
  get: ({ get }) => {
    const activeIntegrations = get(activeIntegrationsSelector);
    if (!activeIntegrations) return null;

    const filteredIntegrations = _.filter(
      activeIntegrations,
      (integration) =>
        integration === ServiceIntegration.Optima ||
        integration === ServiceIntegration.Quickbooks ||
        integration === ServiceIntegration.Symfonia ||
        integration === ServiceIntegration.SquarePayroll ||
        integration === ServiceIntegration.Xero ||
        integration === ServiceIntegration.Enova,
    );

    return filteredIntegrations;
  },
});

export const organizationCreationDateUnixSelector = selector<number | null>({
  key: 'organizationCreationDateUnix',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);

    if (!organizationSession) return null;

    const {
      details: { createDateUnix },
    } = organizationSession;

    return createDateUnix;
  },
});

export const schedulesSettingsSelector = selector<FetchOrganizationSessionResponse['schedulesSettings'] | null>({
  key: 'organizationSession_schedulesSettings',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);

    return organizationSession?.schedulesSettings || null;
  },
});

export const isWorkdayAvailabilityAvailableSelector = selector<boolean | null>({
  key: 'isWorkdayAvailabilityAvailable',
  get: ({ get }) => {
    const schedulesSetting = get(schedulesSettingsSelector);

    if (!schedulesSetting) return null;

    return schedulesSetting.isWorkdayAvailabilityAvailable;
  },
});

export const workdayAvailabilityDefaultStateSelector = selector<WorkdayAvailabilityState | null>({
  key: 'workdayAvailabilityDefaultState',
  get: ({ get }) => {
    const schedulesSetting = get(schedulesSettingsSelector);

    if (!schedulesSetting) return null;

    return schedulesSetting.workdayAvailabilityDefaultState;
  },
});

export const appErrorSelector = selector<OrganizationSessionAtomType['appError'] | null>({
  key: 'appError',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;
    const { appError } = organizationSession;

    return appError;
  },
});
