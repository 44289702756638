import React, { useMemo } from 'react';
import { Flex, ThemeUICSSObject, Text } from 'theme-ui';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { RequestActionType, RequestState } from 'api/actions/requests/requestsActions.types';

type Props = {
  actionType: RequestActionType;
  latestInGroup: boolean;
  state: RequestState;
  isDeleted: boolean;
  isApprovalAllowed: boolean;
  isModificationAllowed: boolean;
  reason?: string;
};

export const MessageBox = ({
  actionType,
  latestInGroup,
  state,
  reason,
  isDeleted,
  isApprovalAllowed,
  isModificationAllowed,
}: Props): React.ReactElement | null => {
  useLingui();

  const message = useMemo(() => {
    if (actionType === RequestActionType.Remove) {
      return (
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <Text>
            {!latestInGroup
              ? t({ id: 'request.deletion_is_pending', message: 'Request deletion is pending...' })
              : t({ id: 'request.deletion_group_is_pending', message: 'Request entire group deletion is pending...' })}
          </Text>
          {reason && reason.length && (
            <Flex sx={{ gap: 2 }}>
              <Text sx={{ flexShrink: 0 }}>{t({ id: 'request.reason', message: 'Reason:' })}</Text>
              <Text sx={{ fontWeight: 'normal', wordBreak: 'break-all' }}>{reason}</Text>
            </Flex>
          )}
        </Flex>
      );
    }

    if (actionType === RequestActionType.Swap && !isApprovalAllowed && isModificationAllowed) {
      return (
        <Text>
          {t({
            id: 'request.swap_is_pending',
            message: 'You need to wait for the first step in the approval flow, before taking action.',
          })}
        </Text>
      );
    }

    return <Text>{t({ id: 'request.change_is_pending', message: 'Request change is pending...' })}</Text>;
  }, [actionType, isApprovalAllowed, isModificationAllowed, latestInGroup, reason]);

  const stateSx: ThemeUICSSObject = useMemo(() => {
    if (actionType === RequestActionType.Remove) {
      return {
        bg: 'requests.message.red.bg',
        border: '1px solid',
        borderColor: 'requests.message.red.border',
      };
    }

    return {
      bg: 'requests.message.blue.bg',
      border: '1px solid',
      borderColor: 'requests.message.blue.border',
    };
  }, [actionType]);

  if (actionType === RequestActionType.Create || state !== RequestState.Pending || isDeleted) return null;

  return <Flex sx={{ borderRadius: 'xs', px: 2, py: 1, fontSize: 2, fontWeight: 'bold', ...stateSx }}>{message}</Flex>;
};
