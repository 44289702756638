import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { dateRangeRequestsUsageOverviewFilterAtom } from 'state/filters';
import { requestsTypeDetailsAtomFamily } from 'state/requests';
import { useRequestsTypeDetails } from '../components/RequestsUsageOverview/hooks/useRequestsTypeDetails';

export const useRefreshRequestsTypeDetails = () => {
  const {
    user: userId,
    request: requestTypeParam,
    typeid: requestTypeId,
  } = useParams() as { request: string | undefined; user: string | undefined; typeid: string | undefined };
  const dates = useRecoilValue(dateRangeRequestsUsageOverviewFilterAtom);
  const requestFormType = useMemo(
    (): RequestFormType | undefined => (requestTypeParam ? +requestTypeParam : undefined),
    [requestTypeParam],
  );
  const requestsTypeDetails = requestsTypeDetailsAtomFamily({
    type: requestFormType || 0,
    typeId: requestTypeId,
    personId: userId || '',
  });
  const { fetchRequestsTypeDetails, fetchRequestsTypeDetailsNoThrottle } = useRequestsTypeDetails({
    type: requestFormType || 0,
    typeId: requestTypeId,
    personId: userId || '',
    dates,
  });

  const updateRequestsTypeDetails = useCallback(() => {
    if (userId && requestTypeParam) {
      fetchRequestsTypeDetails();
    }
  }, [fetchRequestsTypeDetails, requestTypeParam, userId]);

  const updateRequestsTypeDetailsNoThrottle = useCallback(
    async (unAbortable?: boolean) => {
      if (userId && requestTypeParam) {
        await fetchRequestsTypeDetailsNoThrottle(unAbortable);
      }
    },
    [fetchRequestsTypeDetailsNoThrottle, requestTypeParam, userId],
  );

  return {
    updateRequestsTypeDetails,
    updateRequestsTypeDetailsNoThrottle,
    requestsTypeDetailsInitialized: !!requestsTypeDetails,
  };
};
