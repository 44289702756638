import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { Flex, FlexProps, Text, ThemeUIStyleObject } from 'theme-ui';

import { Avatar, AvatarProps } from 'components/Avatar/Avatar';
import { Icon, IconProps } from 'components/Icon/Icon';
import { Tag } from 'components/Tag/Tag';
import { TagStack } from 'components/Tag/TagStack';
import { Button } from 'components/ui/Buttons';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { ParsedEmployee } from 'state/employees';
import { timesheetsDetailsVisible } from 'state/reports';
import { SCHEDULES_BOX_SHADOWS, SCHEDULES_PATTERNS } from 'styles/theme/calendar_patterns';
import { formattedDurationFromSeconds } from 'utils/dateTime';

export type CellPersonType = Partial<Pick<ParsedEmployee, 'role'>> &
  Pick<ParsedEmployee, 'name' | 'avatarUrl' | 'tags'>;

type Props = CellPersonType &
  Pick<FlexProps, 'sx'> &
  Partial<
    {
      avatarSize: number;
      hasErrors: boolean;
      hasPendingRequests: boolean;
      hasUnpublishedChanges: boolean;
      hasWarnings: boolean;
      showDetails: boolean;
      showTagsInSeparateLine?: boolean;
      topContentRenderer: () => React.ReactNode | React.ReactNode[];
      totalScheduledWorkTimeDuration?: number;
      totalScheduledDays?: number;
    } & Pick<ParsedEmployee, 'id'>
  > & {
    avatarProps?: AvatarProps;
    tagsWrapperSx?: ThemeUIStyleObject;
    roleTagSx?: ThemeUIStyleObject;
  };

export const PersonCell = ({
  avatarProps,
  avatarSize = 24,
  avatarUrl,
  hasErrors,
  hasPendingRequests,
  hasUnpublishedChanges,
  hasWarnings,
  id,
  name,
  role,
  roleTagSx,
  showDetails,
  showTagsInSeparateLine = false,
  sx,
  tags,
  tagsWrapperSx,
  topContentRenderer,
  totalScheduledDays,
  totalScheduledWorkTimeDuration,
  ...props
}: Props): React.ReactElement => {
  const getFullName = useNameDisplayOrder();
  const { firstName, surname } = name;

  const [isDetailsVisible, setIsDetailsVisible] = useRecoilState(timesheetsDetailsVisible(id));

  const handleShowDetails = useCallback(() => {
    if (id && showDetails) {
      setIsDetailsVisible(true);
    }
  }, [id, setIsDetailsVisible, showDetails]);

  const chevronType: IconProps['type'] = useMemo(
    () => (isDetailsVisible ? 'chevronUp' : 'chevronDown'),
    [isDetailsVisible],
  );

  const getTagElements = () => <TagStack tags={tags || []} popperWidth={600} />;

  return (
    <Flex
      {...props}
      className="person-cell"
      sx={{
        width: '100%',
        alignItems: 'center',
        gap: '0.75rem',
        ...(sx && sx),
        ...(hasWarnings && {
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            right: '0',
            zIndex: -2,
            bg: 'reports.cells.warning',
          },
        }),
        ...(hasErrors && {
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            right: '0',
            zIndex: -2,
            bg: 'reports.cells.error',
          },
        }),
        ...(hasPendingRequests && {
          '&::after': {
            content: '""',
            position: 'absolute',
            inset: -1,
            border: '2px solid',
            borderColor: 'reports.cells.pending',
            zIndex: -1,
            boxShadow: SCHEDULES_BOX_SHADOWS.unpublishedChanges,
          },
        }),
        ...(hasUnpublishedChanges && {
          '&::after': {
            content: '""',
            position: 'absolute',
            inset: 0,
            outline: '1px solid',
            outlineColor: 'reports.cells.pending',
            zIndex: -1,
            ...SCHEDULES_PATTERNS.pending,
          },
        }),
      }}
    >
      {hasErrors ? (
        <Icon type="warning" fill="reports.error" size={avatarSize} wrapperSx={avatarProps?.sx} />
      ) : (
        <Avatar size={avatarSize} image={avatarUrl} name={name} {...avatarProps} />
      )}

      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '0.125rem',
          flex: 1,
        }}
      >
        <TextEllipsis title={getFullName(firstName, surname)}>{getFullName(firstName, surname)}</TextEllipsis>
        <Flex
          className="person_cell__tags_wrapper"
          sx={{
            gap: '0.125rem',
            overflow: 'hidden',
            width: '100%',
            position: 'relative',
            ...tagsWrapperSx,
          }}
        >
          {role && (
            <Tag
              key={role.name}
              variant="outline"
              sx={{
                flex: '0 0 auto',
                maxWidth: showTagsInSeparateLine ? '100%' : '120px',
                ...roleTagSx,
              }}
              color={UserSelectableColor[role.color]}
            >
              {i18n._(
                t({
                  id: role.name,
                }),
              )}
            </Tag>
          )}

          {!showTagsInSeparateLine && tags.length > 0 && getTagElements()}
        </Flex>
        {showTagsInSeparateLine && (
          <Flex
            className="person_cell__tags_wrapper"
            sx={{
              gap: '0.125rem',
              overflow: 'hidden',
              position: 'relative',
              width: '100%',
            }}
          >
            {getTagElements()}
          </Flex>
        )}
        {(totalScheduledDays || totalScheduledWorkTimeDuration) && (
          <Text
            sx={{
              fontWeight: 'normal',
              fontSize: 1,
              position: 'absolute',
              textShadow: 'reports',
              bottom: -0.5,
              right: 1,
              // TODO fix it in other way, temporary solution
              zIndex: 999,
            }}
          >
            {totalScheduledWorkTimeDuration && `${formattedDurationFromSeconds(totalScheduledWorkTimeDuration)}`}
            {totalScheduledDays && totalScheduledWorkTimeDuration && ' / '}
            {`${totalScheduledDays}d`}
          </Text>
        )}
        {topContentRenderer && (
          <Flex
            sx={{
              fontWeight: 'normal',
              fontSize: 1,
              position: 'absolute',
              textShadow: 'reports',
              top: -0.5,
              right: 1,
              gap: 1,
            }}
          >
            {topContentRenderer()}
          </Flex>
        )}
      </Flex>

      {hasPendingRequests && (
        <Icon
          type="info"
          fill="reports.cells.pending"
          size={avatarSize}
          tooltip={<Trans id="person_cell.has_pending_requests">User has pending request/s.</Trans>}
        />
      )}

      {showDetails && (
        <Button
          shape="rounded"
          variant="lightGrey"
          size="xs"
          prependWith={<Icon type={chevronType} />}
          onClick={(e) => {
            e.stopPropagation();
            handleShowDetails();
          }}
          sx={{
            px: 1,
          }}
        />
      )}
    </Flex>
  );
};
