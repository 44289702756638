import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { EventPhoto, TimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { useKeyboardEvent } from 'hooks/useKeyboardEvent/useKeyboardEvent';
import { personDayEventsSelectorFamily } from 'state/clockLog';

import { useFetchEventsPhotos } from './useFetchEventsPhotos';

/** Get rid of part of url string that dynamically changes, e.g.:
 * "https://inewifacerecognition.blob.core.windows.net/localhost/fd3b3edd-8d49-4cc2-8951-e796fe952c6f/c1dd4d56-69d5-4cf0-9fb1-e7a6ee32b363/verifications/e0d5c81a-782a-4372-bb26-db7d9a75b5f1/full/0.jpeg?sv=2023-11-03&se=2024-04-09T19%3A40%3A58Z&sr=b&sp=r&sig=Rnmphk4oj4TdJl%2F%2FJ2nwn03uXhhi5%2BOttq9uLrtNXJ0%3D"
 * Part after question mark "?" is security feature that grants temporary file access. It changes on every file request.
 * Url proper remains unchanged, even after e.g. event edit.
 */

const EVENT_SCROLL_MARGIN = 28; //  1.75rem
const PHOTO_SCROLL_MARGIN = 36; //  2.25rem

const scrollIntoViewHorizontally = (
  container: HTMLElement | null,
  child: HTMLElement | null | undefined,
  modal: HTMLDivElement | null,
  margin: number,
) => {
  if (!container || !child || !modal) return;

  const childOffsetRight = child.offsetLeft - modal.offsetLeft + child.offsetWidth;
  const containerScrollRight = container.scrollLeft + container.offsetWidth;

  if (container.scrollLeft > child.offsetLeft - modal.offsetLeft) {
    container.scrollLeft = child.offsetLeft - modal.offsetLeft - margin;
  } else if (containerScrollRight < childOffsetRight) {
    container.scrollLeft += childOffsetRight - containerScrollRight + margin;
  }
};

export const usePhotoLogDetails = (userId: string, date: number, keyboardEventsEnabled: boolean) => {
  const [currentEventIndex, setCurrentEventIndex] = useState<number>(0);
  const [currentEventPhotoIndex, setCurrentEventPhotoIndex] = useState<number>(0);

  //  Fetching events
  const personDayEvents = useRecoilValue(personDayEventsSelectorFamily({ personId: userId || '', date: +(date || 0) }));

  const eventIdsWithPhotos = useMemo(
    () =>
      personDayEvents?.events.filter(({ fraudDetectionState }) => !_.isNil(fraudDetectionState)).map(({ id }) => id),
    [personDayEvents?.events],
  );

  const { eventsPhotos, eventsPhotosLoading } = useFetchEventsPhotos(eventIdsWithPhotos);

  const eventPhotosRef = useRef<Map<EventPhoto['id'], HTMLElement> | null>(null);
  const eventPhotosBoxesRef = useRef<Map<TimeEvent['id'], HTMLElement> | null>(null);

  const eventsWithEventsPhotos = useMemo(() => {
    const mergedData = personDayEvents?.events.map((e) => {
      const photos = eventsPhotos?.find(({ timeEventId }) => timeEventId === e.id);
      return {
        ...e,
        ...photos,
      };
    });

    return {
      ...personDayEvents,
      events: mergedData,
    };
  }, [personDayEvents, eventsPhotos]);

  //  Prevent photo gallery indexes from exceeding gallery length after event rejection
  useEffect(() => {
    if ((eventsWithEventsPhotos.events?.length || 0) < currentEventIndex + 1) setCurrentEventIndex(0);
  }, [currentEventIndex, setCurrentEventIndex, eventsWithEventsPhotos]);

  useEffect(() => {
    if ((eventsWithEventsPhotos.events?.[currentEventIndex]?.photos?.length || 0) < currentEventPhotoIndex + 1)
      setCurrentEventPhotoIndex(0);
  }, [currentEventIndex, currentEventPhotoIndex, setCurrentEventPhotoIndex, eventsWithEventsPhotos]);

  //  Loading photos
  const hasCurrentEventPhotos = useMemo(
    () => !_.isNil(eventsWithEventsPhotos.events?.[currentEventIndex]?.fraudDetectionState),
    [eventsWithEventsPhotos, currentEventIndex],
  );
  const currentPhotoUrl = useMemo(
    () => eventsWithEventsPhotos.events?.[currentEventIndex]?.photoUrl,
    [eventsWithEventsPhotos, currentEventIndex],
  );
  const currentPhotosPhotoUrl = useMemo(
    () => eventsWithEventsPhotos.events?.[currentEventIndex]?.photos?.[currentEventPhotoIndex]?.url,
    [eventsWithEventsPhotos, currentEventIndex, currentEventPhotoIndex],
  );

  //  Setting photo nodes
  const getPhotosMap = useCallback(() => {
    if (!eventPhotosRef.current) eventPhotosRef.current = new Map();
    return eventPhotosRef.current;
  }, []);

  const getPhotosBoxesMap = useCallback(() => {
    if (!eventPhotosBoxesRef.current) eventPhotosBoxesRef.current = new Map();
    return eventPhotosBoxesRef.current;
  }, []);

  const setPhotosBoxesNodes = (id: string, node: HTMLElement | null) => {
    const map = getPhotosBoxesMap();
    if (node) map.set(id, node);
    else map.delete(id);
  };

  const setPhotosNodes = (id: string, node: HTMLElement | null, photoId: string) => {
    const map = getPhotosMap();
    const nodeId = `${id}_${photoId}`;
    if (node) map.set(nodeId, node);
    else map.delete(photoId);
  };

  //  Photo navigation and scrolling
  const photosLength = eventsWithEventsPhotos.events?.[currentEventIndex]?.photos?.length;
  const isCurrentPhotoFirst = currentEventIndex === 0 && currentEventPhotoIndex === 0;
  const isCurrentEventLast = currentEventIndex + 1 === eventsWithEventsPhotos.events?.length;
  const isCurrentPhotoOfPhotosLast = currentEventPhotoIndex + 1 === photosLength;
  const isCurrentPhotoLast = isCurrentEventLast && (!hasCurrentEventPhotos || isCurrentPhotoOfPhotosLast);

  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const eventPhotosContainerRef = useRef<HTMLElement | null>(null);

  const scrollToPhoto = useCallback(
    (nodeId: string) => {
      const map = getPhotosMap();
      const node = map.get(nodeId);
      scrollIntoViewHorizontally(eventPhotosContainerRef.current, node, modalContainerRef.current, PHOTO_SCROLL_MARGIN);
    },
    [getPhotosMap],
  );

  const scrollToEvent = useCallback(
    (eventId: TimeEvent['id']) => {
      const map = getPhotosBoxesMap();
      const node = map.get(eventId);
      const margin = isCurrentPhotoOfPhotosLast ? -PHOTO_SCROLL_MARGIN : EVENT_SCROLL_MARGIN;
      scrollIntoViewHorizontally(eventPhotosContainerRef.current, node, modalContainerRef.current, margin);
    },
    [getPhotosBoxesMap, isCurrentPhotoOfPhotosLast],
  );

  const handlePhotoClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, photoId: EventPhoto['id'], eventId: TimeEvent['id']) => {
      setCurrentEventIndex(eventsWithEventsPhotos.events?.findIndex(({ timeEventId }) => timeEventId === eventId) || 0);
      setCurrentEventPhotoIndex(+photoId);
      const nodeId = `${eventId}_${photoId}`;
      scrollToPhoto(nodeId);
      e.stopPropagation();
    },
    [setCurrentEventIndex, eventsWithEventsPhotos, setCurrentEventPhotoIndex, scrollToPhoto],
  );

  const handleEventPhotosBoxClick = useCallback(
    (eventId: TimeEvent['id']) => {
      setCurrentEventIndex(eventsWithEventsPhotos.events?.findIndex(({ id }) => id === eventId) || 0);
      setCurrentEventPhotoIndex(0);
      scrollToEvent(eventId);
    },
    [eventsWithEventsPhotos, setCurrentEventIndex, setCurrentEventPhotoIndex, scrollToEvent],
  );

  const navigateToNextPhoto = useCallback(() => {
    if (eventsPhotosLoading) return;
    const { events } = eventsWithEventsPhotos;
    const newEventIndex = currentEventIndex + 1;
    if (hasCurrentEventPhotos && !isCurrentPhotoOfPhotosLast) {
      setCurrentEventPhotoIndex((prev) => {
        const newPhotoIndex = prev + 1;
        const newPhotoId = events?.[currentEventIndex]?.photos?.[newPhotoIndex].id;
        const eventId = events?.[currentEventIndex]?.id;
        scrollToPhoto(`${eventId}_${newPhotoId}`);
        return newPhotoIndex;
      });
    }
    if (hasCurrentEventPhotos && isCurrentPhotoOfPhotosLast) {
      setCurrentEventPhotoIndex(0);
      scrollToEvent(events?.[newEventIndex].id || '');
      setCurrentEventIndex(newEventIndex);
    }
    if (!hasCurrentEventPhotos) {
      scrollToEvent(events?.[newEventIndex].id || '');
      setCurrentEventIndex(newEventIndex);
    }
  }, [
    currentEventIndex,
    eventsPhotosLoading,
    eventsWithEventsPhotos,
    hasCurrentEventPhotos,
    isCurrentPhotoOfPhotosLast,
    scrollToEvent,
    scrollToPhoto,
    setCurrentEventIndex,
    setCurrentEventPhotoIndex,
  ]);

  const navigateToPreviousPhoto = useCallback(() => {
    if (eventsPhotosLoading) return;
    const { events } = eventsWithEventsPhotos;
    const isEventFirst = currentEventIndex === 0;
    const isPhotoOfPhotosFirst = currentEventPhotoIndex === 0;
    if ((hasCurrentEventPhotos && isPhotoOfPhotosFirst && !isEventFirst) || (!hasCurrentEventPhotos && !isEventFirst)) {
      setCurrentEventIndex((prev) => {
        const newEventIndex = prev - 1;
        const hasNewEventPhotos = !_.isNil(events?.[newEventIndex]?.fraudDetectionState);
        const newPhotosLength = hasNewEventPhotos ? events?.[newEventIndex]?.photos?.length || 1 : 1;
        const newEventId = events?.[newEventIndex]?.id;
        const newPhotoId = events?.[newEventIndex]?.photos?.[newPhotosLength - 1]?.id;
        if (hasNewEventPhotos) scrollToPhoto(`${newEventId}_${newPhotoId}`);
        else scrollToEvent(newEventId || '');
        setCurrentEventPhotoIndex(newPhotosLength - 1);
        return newEventIndex;
      });
    }
    if (hasCurrentEventPhotos && !isPhotoOfPhotosFirst) {
      const currentEventId = events?.[currentEventIndex]?.id;
      const newPhotoId = events?.[currentEventIndex]?.photos?.[currentEventPhotoIndex - 1]?.id;
      scrollToPhoto(`${currentEventId}_${newPhotoId}`);
      setCurrentEventPhotoIndex((prev) => prev - 1);
    }
  }, [
    currentEventIndex,
    currentEventPhotoIndex,
    eventsPhotosLoading,
    eventsWithEventsPhotos,
    hasCurrentEventPhotos,
    scrollToEvent,
    scrollToPhoto,
    setCurrentEventIndex,
    setCurrentEventPhotoIndex,
  ]);

  useKeyboardEvent(
    'ArrowRight',
    () => {
      if (!isCurrentPhotoLast) navigateToNextPhoto();
    },
    !keyboardEventsEnabled,
  );
  useKeyboardEvent(
    'ArrowLeft',
    () => {
      if (!isCurrentPhotoFirst) navigateToPreviousPhoto();
    },
    !keyboardEventsEnabled,
  );

  return {
    currentEventIndex,
    currentEventPhotoIndex,
    currentPhotosPhotoUrl,
    currentPhotoUrl,
    events: eventsWithEventsPhotos.events,
    eventPhotosContainerRef,
    eventsPhotosLoading,
    handleEventPhotosBoxClick,
    handlePhotoClick,
    hasCurrentEventPhotos,
    isCurrentPhotoFirst,
    isCurrentPhotoLast,
    modalContainerRef,
    navigateToNextPhoto,
    navigateToPreviousPhoto,
    personDayEvents,
    setPhotosBoxesNodes,
    setPhotosNodes,
  };
};
