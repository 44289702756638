import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { KioskOverlay, overlayStateAtom } from 'Kiosk/state/overlayState';
import { Summary } from 'Kiosk/pages/Summary/Summary';
import { Start } from 'Kiosk/pages/Start/Start';
import { InitialFaceScan } from 'Kiosk/pages/FaceScan/InitialFaceScan';

type Props = {
  children: React.ReactElement | React.ReactElement[];
};

export const OverlayController = ({ children }: Props): React.ReactElement => {
  const overlay = useRecoilValue(overlayStateAtom);

  const renderOverlay = useCallback(() => {
    if (overlay.type === KioskOverlay.start) {
      return <Start />;
    }

    if (overlay.type === KioskOverlay.initialFaceScan) {
      const { name, qrCode } = overlay.state;
      return <InitialFaceScan name={name} qrCode={qrCode} />;
    }

    if (overlay.type === KioskOverlay.summary) {
      const { eventImage, getInfoPayload, qrCode } = overlay.state;
      return <Summary qrCode={qrCode} getInfoPayload={getInfoPayload} eventImage={eventImage} />;
    }

    return null;
  }, [overlay]);

  return (
    <>
      {renderOverlay()}
      {children}
    </>
  );
};
