import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { rejectRequestAction, rejectSwapAction } from 'api/actions/requests/requestsActions';
import { RejectRequestManagementActionProps } from 'api/actions/requests/requestsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { Textarea } from 'components/ui/Textarea';
import { VALIDATION_RULES } from 'constants/validationRules';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { useRefreshClockLog } from 'pages/ClockLog/output/useRefreshClockLog';
import { useRequestsToAccept } from 'pages/Home/output/useRequestsToAccept';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { allParsedRequestsMapSelector } from 'state/requests';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useRefreshRequests } from '../../hooks/useRefreshRequests';
import { useRefreshRequestsTypeDetails } from '../../hooks/useRefreshRequestsTypeDetails';

import { useRefreshRequestsUsageOverview } from './hooks/useRefreshRequestsUsageOverview';
import { useRequestDetails } from './RequestDetails/hooks/useRequestDetails';

type Props = {
  onSubmitCallback?: () => Promise<void>;
};

const defaultProps = {
  onSubmitCallback: undefined,
};

export const RejectRequestModal = ({ onSubmitCallback }: Props): React.ReactElement => {
  useLingui();
  const { state } = useLocation();
  const { id, requestNumber, ids: personIds, instanceNumber, isSchedule } = state || {};
  const { handleClose, baseRoute } = useModal();

  const requests = useRecoilValue(allParsedRequestsMapSelector);
  const { parsedRequestDetails: requestDetails } = useRequestDetails(instanceNumber);

  const [isLoading, setIsLoading] = useState(false);

  const getPersonId = useCallback(() => {
    if (requestDetails && requestDetails.employee.id) {
      const {
        employee: { id: employeeId },
        swapPersonId,
      } = requestDetails;

      if (swapPersonId) {
        return [employeeId, swapPersonId];
      }

      return [employeeId];
    }

    if (personIds) return personIds;

    return undefined;
  }, [personIds, requestDetails]);

  const { mutate: rejectRequest } = useMutation(rejectRequestAction);
  const { mutate: rejectSwap } = useMutation(rejectSwapAction);
  const { updateRequestsNoThrottle, requestsInitialized } = useRefreshRequests();
  const { isInitialized: requestsToAcceptInitialized, fetchRequestsToAccept } = useRequestsToAccept();
  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar(getPersonId());
  const { reportInitialized, updateReportForIds } = useRefreshReport(getPersonId());
  const { refreshClockLogForPeopleIds, clockLogInitialized } = useRefreshClockLog(getPersonId());
  const { updateRequestsUsageOverviewNoThrottle, requestsUsageOverviewInitialized } = useRefreshRequestsUsageOverview();
  const { updateRequestsTypeDetailsNoThrottle, requestsTypeDetailsInitialized } = useRefreshRequestsTypeDetails();

  const { register, handleSubmit, watch } = useForm<RejectRequestManagementActionProps>();

  const reasonWatch: string = watch('reason');
  const request = useMemo(() => {
    if (requests && id) {
      return requests.get(id);
    }

    return null;
  }, [id, requests]);

  const handleSubmitCallback = useCallback(
    async ({ reason }: RejectRequestManagementActionProps) => {
      if (!id) return;
      setIsLoading(true);
      let submitError: boolean;

      if (isSchedule) {
        const { error } = await rejectSwap({ id, reason });
        submitError = error;
      } else {
        const { error } = await rejectRequest({ id, reason });
        submitError = error;
      }

      if (!submitError) {
        addSnackbar({
          message: t({
            id: 'request.snackbar_rejected',
            message: 'Request rejected!',
          }),
          variant: 'success',
        });
        if (requestsInitialized) await updateRequestsNoThrottle();
        if (calendarInitialized) await updateCalendarForIds();
        if (reportInitialized) await updateReportForIds();
        if (requestsToAcceptInitialized) await fetchRequestsToAccept();
        if (clockLogInitialized) await refreshClockLogForPeopleIds();
        if (requestsUsageOverviewInitialized) await updateRequestsUsageOverviewNoThrottle();
        if (requestsTypeDetailsInitialized) await updateRequestsTypeDetailsNoThrottle();
        if (onSubmitCallback) await onSubmitCallback();
        handleClose();
      }

      setIsLoading(false);
    },
    [
      id,
      isSchedule,
      rejectSwap,
      rejectRequest,
      requestsInitialized,
      updateRequestsNoThrottle,
      calendarInitialized,
      updateCalendarForIds,
      reportInitialized,
      updateReportForIds,
      requestsToAcceptInitialized,
      fetchRequestsToAccept,
      clockLogInitialized,
      refreshClockLogForPeopleIds,
      requestsUsageOverviewInitialized,
      updateRequestsUsageOverviewNoThrottle,
      requestsTypeDetailsInitialized,
      updateRequestsTypeDetailsNoThrottle,
      onSubmitCallback,
      handleClose,
    ],
  );

  useEffect(() => {
    if (!id) handleClose();
  }, [handleClose, id]);

  if (!id) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Text>
            {t({ id: 'requests.request' })} {requestNumber || request?.number || '-'}
          </Text>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))} id="rejectForm">
          <Flex sx={{ flexDirection: 'column' }}>
            <Text
              sx={{
                fontWeight: 'bold',
                fontSize: 3,
              }}
            >
              <Trans id="request.reject.reason">Reason (optional)</Trans>
            </Text>
            <Textarea
              {...register('reason')}
              id="reason"
              placeholder={t({ id: 'request.reject.placeholder', message: 'Enter your reason...' })}
              maxLength={VALIDATION_RULES.REJECT_REQUEST_REASON.maxLength}
              sx={{ mt: 2 }}
              inputProps={{ sx: { fontSize: 2 } }}
            />
            <Text sx={{ ml: 'auto', color: 'requests.rejectReason', fontSize: 0, fontWeight: 'bold' }}>
              {(reasonWatch && reasonWatch.length) || 0} / {VALIDATION_RULES.REJECT_REQUEST_REASON.maxLength}
            </Text>
          </Flex>
        </form>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading,
            variant: 'danger',
            children: t({ id: 'requests.button.reject' }),
            type: 'submit',
            form: 'rejectForm',
          },
        ]}
      />
    </>
  );
};

RejectRequestModal.defaultProps = defaultProps;
