import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { fetchOrganizationSettingsAction } from 'api/actions/settings/settingsActions';
import { organizationSettingsAtom } from 'state/settings';

export const useOrganizationSettings = () => {
  const { query, abort } = useQuery(fetchOrganizationSettingsAction(), false);
  const [organizationSettings, setOrganizationSettings] = useRecoilState(organizationSettingsAtom);

  const fetchOrganizationSettings = useCallback(async () => {
    const response = await query();
    const { error, payload } = response;

    if (!error && payload) {
      setOrganizationSettings(payload);
    }

    return response;
  }, [query, setOrganizationSettings]);

  const omittedOrganizationSettings = useMemo(() => {
    if (!organizationSettings) return null;

    const { address, vatId, ...rest } = _.omit(organizationSettings, ['holidays']);

    return {
      address: {
        street: address.street ? address.street : '',
        postalCode: address.postalCode ? address.postalCode : '',
        city: address.city ? address.city : '',
      },
      vatId: vatId || '',
      ...rest,
    };
  }, [organizationSettings]);

  return {
    organizationSettings,
    setOrganizationSettings,
    fetchOrganizationSettings,
    omittedOrganizationSettings,
    fetchAbort: abort,
  };
};
