import { useContext, useEffect, useMemo, useRef, forwardRef, useCallback } from 'react';
import _ from 'lodash';

import { mergeRefs } from 'utils/mergeRefs';

import { useMapEvent } from './hooks/useMapEvent';
import { MapContext } from './MapContext';
import { DefaultMarkerProps } from './types';

const defaultProps: Partial<DefaultMarkerProps> = {
  clickable: false,
  draggable: false,
  cursor: 'auto',
  infoWindowProps: undefined,
};

const DEFAULT_INFO_WINDOW_OPEN_TRIGGER = 'mouseover';

export const DefaultMarker = forwardRef<google.maps.Marker, DefaultMarkerProps>(
  ({ position, infoWindowProps, onDragStart, onDragEnd, draggable, ...props }: DefaultMarkerProps, ref): null => {
    const mapContext = useContext(MapContext);

    const markerRef = useRef<google.maps.Marker | null>(null);

    useEffect(() => {
      const initializeMarker = () => {
        if (!mapContext) return;

        const { googleMaps, map } = mapContext;

        if (!googleMaps || !map) return;

        const marker = new googleMaps.Marker({
          map,
          position,
          draggable,
          ...props,
        });
        mergeRefs([markerRef, ref])(marker);
      };

      if (!markerRef.current && mapContext) {
        initializeMarker();
      }
    }, [mapContext, position, draggable, props, ref]);

    useEffect(() => {
      if (markerRef.current && !_.isUndefined(draggable)) {
        markerRef.current.setDraggable(draggable);
      }
    }, [draggable]);

    const infoWindow = useMemo(() => {
      if (!mapContext || !infoWindowProps) return null;
      const { googleMaps } = mapContext;

      if (!googleMaps) return null;

      return new googleMaps.InfoWindow(infoWindowProps);
    }, [infoWindowProps, mapContext]);

    const infoWindowTriggers = useMemo(() => {
      if (!infoWindowProps) return null;
      const open = infoWindowProps ? infoWindowProps?.trigger || DEFAULT_INFO_WINDOW_OPEN_TRIGGER : null;
      const close = open === 'mouseover' ? 'mouseout' : null;

      return {
        open,
        close,
      };
    }, [infoWindowProps]);

    const openInfoWindow = useCallback(() => {
      const map = mapContext?.map;
      if (!infoWindow || !map) return;
      infoWindow.open(map, markerRef.current);
    }, [mapContext, infoWindow]);

    const closeInfoWindow = useCallback(() => {
      if (!infoWindow) return;
      infoWindow.close();
    }, [infoWindow]);

    useMapEvent(infoWindowTriggers?.open, openInfoWindow, markerRef);
    useMapEvent(infoWindowTriggers?.close, closeInfoWindow, markerRef);
    useMapEvent('position_changed', closeInfoWindow, infoWindowTriggers?.close === 'mouseout' ? markerRef : null);

    useMapEvent('dragstart', onDragStart, markerRef);
    useMapEvent('dragend', onDragEnd, markerRef);

    useEffect(() => {
      if (markerRef.current) {
        markerRef.current.setPosition(position);
      }
    }, [position, markerRef]);

    useEffect(
      () => () => {
        if (markerRef.current) {
          markerRef.current.setMap(null);
        }
      },
      [],
    );

    return null;
  },
);

DefaultMarker.defaultProps = defaultProps;
