import React, { useMemo } from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { Body } from './components/Body/Body';
import { Header } from './components/Header';
import { Title } from './components/Title';
import { Variants } from './types';

type Props = Omit<FlexProps, 'variant'> & { variant?: Variants };

type SettingsGroupProps = Props;

type SettingsGroupComponent = React.FC<SettingsGroupProps> & {
  Header: typeof Header;
  Title: typeof Title;
  Body: typeof Body;
};

export const SettingsGroup: SettingsGroupComponent = ({
  children,
  variant,
  sx,
  ...props
}: Props): React.ReactElement => {
  const flexVariant = useMemo(() => {
    const container = 'settings.container.vertical';

    switch (variant) {
      case 'noGap':
        return `${container}.noGap`;
      case 'sm':
        return `${container}.sm`;
      case 'md':
        return `${container}.md`;
      case 'lg':
        return `${container}.lg`;
      case 'xlg':
        return `${container}.xlg`;
      default:
        return '';
    }
  }, [variant]);

  return (
    <Flex
      variant={flexVariant}
      sx={{
        ...(sx && sx),
        maxWidth: '640px',
      }}
      {...props}
    >
      {children}
    </Flex>
  );
};

SettingsGroup.Header = Header;
SettingsGroup.Title = Title;
SettingsGroup.Body = Body;
