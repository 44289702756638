import React from 'react';

import { Icon } from 'components/Icon/Icon';
import { Button, ButtonProps } from 'components/ui/Buttons';
import { PopperState } from 'components/ui/PopperProvider/PopperProvider';
import { DropdownProps } from 'components/Dropdown/Dropdown';

type Props = ButtonProps & {
  popperState?: PopperState;
  dropdownProps?: DropdownProps;
  withDropdownIndicator?: boolean;
};

// mainly used for ButtonWithDropdown apendWith prop in HeaderMenu section of each view

export const HeaderMenuButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ popperState, withDropdownIndicator, ...props }: Props, ref) => (
    <Button
      ref={ref}
      size="sm"
      variant="grey"
      shape="rounded"
      {...(withDropdownIndicator && {
        apendWith: (
          <Icon
            wrapperSx={{
              bg: 'headerMenuButton.background',
              color: 'headerMenuButton.color',
              borderRadius: '2px',
              ml: '4px',
              mr: '-4px',
            }}
            type={popperState?.isVisible ? 'chevronUp' : 'chevronDown'}
          />
        ),
      })}
      {...props}
    />
  ),
);
