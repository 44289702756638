import isUndefined from 'lodash/isUndefined';

import { PayRate, WorkdayDuration } from 'api/actions/employees/employeesActions.types';
import { Feature, LimitPeriods } from 'api/actions/organizationSession/organizationSessionActions.types';
import { ParsedCustomRequestLimitGroup, ParsedTimeOffLimitsGroup } from 'state/team';
import { getNumberField } from 'utils/getNumberField';

export const payRatesTypeEnforcer = (rates?: PayRate[]) =>
  rates?.map(({ normal, overtime, startDateUnix, ...rate }) => ({
    ...rate,
    startDateUnix: +startDateUnix,
    normal: getNumberField(normal),
    overtime: getNumberField(overtime),
  }));

export const timeOffLimitsTypeEnforcer = (timeOffLimits?: ParsedTimeOffLimitsGroup[]) =>
  timeOffLimits?.map(({ limits, fromYear, ...limitGroup }) => ({
    ...limitGroup,
    fromYear: +fromYear,
    limits: limits.map(({ days, ...limit }) => {
      const parsedDays = getNumberField(days);
      return {
        ...limit,
        ...(!isUndefined(parsedDays) && { days: parsedDays }),
      };
    }),
  })) || [];

export const customRequestsLimitsTypeEnforcer = (customRequestsLimits?: ParsedCustomRequestLimitGroup[]) =>
  customRequestsLimits?.map(({ limits, fromYear, ...limitGroup }) => ({
    ...limitGroup,
    fromYear: +fromYear,
    limits: limits.map(({ days, period, ...limit }) => {
      const parsedDays = getNumberField(days);
      return {
        ...limit,
        period: getNumberField(period) as LimitPeriods,
        ...(!isUndefined(parsedDays) && { days: parsedDays }),
      };
    }),
  })) || [];

export const workdayDurationsTypeEnforcer = (workdayDurations?: WorkdayDuration[]) =>
  workdayDurations?.map(({ workdayDurationSeconds, fromYear, ...rest }) => ({
    workdayDurationSeconds: +workdayDurationSeconds,
    fromYear: +fromYear,
    ...rest,
  }));

export const featuresTypeEnforcer = (features: Feature[]) => features.map((f: Feature) => ({ ...f, value: !!f.value }));
