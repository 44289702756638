import { Trans } from '@lingui/macro';
import React from 'react';
import { Flex, Text } from 'theme-ui';
import { useRecoilValue } from 'recoil';

import { Logo } from 'components/Logo/Logo';
import { windowSizeAtom } from 'state/recoilState';
import { Container } from 'components/ui/Container';
import { useIdle } from 'hooks/useIdle/useIdle';

export const SleepModeSplash = (): React.ReactElement => {
  const { isMobile } = useRecoilValue(windowSizeAtom);
  const { reset } = useIdle();

  const TouchOrClick = () =>
    isMobile ? (
      <Trans id="sleep_mode_splash.header.touch">Touch</Trans>
    ) : (
      <Trans id="sleep_mode_splash.header.click">Click</Trans>
    );

  return (
    <Flex
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        bg: 'kiosk.sleepMode.bg.default',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        zIndex: 'max',
        '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
          bg: 'kiosk.sleepMode.bg.noBackdrop',
          backdropFilter: 'blur(30px)',
        },
      }}
      onClick={() => {
        if (reset) reset();
      }}
    >
      <Container size="md">
        <Text as="h1" sx={{ fontSize: '3.5rem', lineHeight: 'heading', color: 'kiosk.sleepMode.text' }}>
          <Trans id="kiosk.sleep_mode_splash.header">{TouchOrClick()} here to wake up the app.</Trans>
        </Text>
      </Container>

      <Logo
        sx={{ position: 'absolute', bottom: 5, left: 0, right: 0, margin: 'auto' }}
        fill="kiosk.sleepMode.text"
        width={150}
      />
    </Flex>
  );
};
