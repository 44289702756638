import { FetchOrganizationSessionResponse } from 'api/actions/organizationSession/organizationSessionActions.types';
import { OrganizationSessionAtomType } from 'state/organizationSession';

export const getParsedOrganizationSession = (
  organizationSession: FetchOrganizationSessionResponse,
): OrganizationSessionAtomType => {
  const { employees, workPositions, tags, roles, chatUsersWithoutEmployees, timeEventTypes } = organizationSession;

  const employeesMap = new Map(employees.map((employee) => [employee.id, employee]));
  const tagsMap = new Map(tags.map((tag) => [tag.id, tag]));
  const rolesMap = new Map(roles.map((role) => [role.id, role]));
  const workPositionsMap = new Map(workPositions.map((workPosition) => [workPosition.id, workPosition]));

  const activeEmployeesWithEmailsMap = new Map(employeesMap);

  activeEmployeesWithEmailsMap.forEach(({ email, isActive }, employeeId) => {
    if (!email || !isActive) {
      activeEmployeesWithEmailsMap.delete(employeeId);
    }
  });

  const chatUsersWithoutEmployeesMap = chatUsersWithoutEmployees.length
    ? new Map(chatUsersWithoutEmployees.map((chatUser) => [chatUser.id, chatUser]))
    : null;

  const chatUsersMap = chatUsersWithoutEmployeesMap
    ? new Map([...chatUsersWithoutEmployeesMap, ...activeEmployeesWithEmailsMap])
    : new Map(activeEmployeesWithEmailsMap);

  // FIXME: temporary solution, remove after solved on backend!!
  // If both pauseWorkTime & limitMaximumDuration are true, limitMaximumDuration is ignored.
  // In such case we show the user limitMaximumDuration as false and maximumTimeAllowedSeconds as undefined.
  const parsedTimeEventTypes = timeEventTypes.map((tET) => ({
    ...tET,
    ...(tET?.pauseWorkTime &&
      tET?.limitMaximumDuration && { limitMaximumDuration: false, maximumTimeAllowedSeconds: undefined }),
  }));

  const timeEventTypeMap = new Map(parsedTimeEventTypes.map((timeEvent) => [timeEvent.id, timeEvent]));

  return {
    ...organizationSession,
    timeEventTypes: parsedTimeEventTypes,
    timeEventTypeMap,
    employeesMap,
    tagsMap,
    rolesMap,
    workPositionsMap,
    chatUsersMap,
    chatUsersWithoutEmployeesMap,
  };
};
