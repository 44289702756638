import { t, Trans } from '@lingui/macro';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Route, Routes, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Grid, Text } from 'theme-ui';

import { setFraudResolutionStateAction } from 'api/actions/timeEvent/timeEventActions';
import { ExtendedTimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { Button } from 'components/ui/Buttons';
import { PATH_REL, TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { useAntiSpoofingChecks } from 'pages/Home/output/useAntiSpoofingChecks';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { filteredExtTimeEventsSelector } from 'state/clockLog';
import { languageSelector } from 'state/recoilState';
import { reportsExtTimeEventsSelector } from 'state/reports';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

import { AiPhotoSelect } from './components/AiPhotoSelect';
import { EventDetails } from './components/EventDetails';
import { useFetchFraudVerificationPhotosDetails } from './hooks/useFetchFraudVerificationPhotosDetails';
import { useRefreshClockLog } from './hooks/useRefreshClockLog';
import { RejectEventModal } from './RejectEvent';

type Props = {
  updateAntiSpoofingChecks?: boolean;
};

export const AntiSpoofingCheckModal = ({ updateAntiSpoofingChecks }: Props): React.ReactElement => {
  const [isApproveLoading, setIsApproveLoading] = useState(false);

  const navigate = useAppNavigate();
  const { baseRoute, handleClose } = useModal({ fullHeight: true });

  const { id } = useParams() as { id: ExtendedTimeEvent['id'] };
  const extTimeEvents = useRecoilValue(filteredExtTimeEventsSelector);
  const reportsExtTimeEvents = useRecoilValue(reportsExtTimeEventsSelector);
  const events = useMemo(
    () => new Map([...(reportsExtTimeEvents || []), ...(extTimeEvents || [])]),
    [reportsExtTimeEvents, extTimeEvents],
  );
  const extTimeEvent = events.get(id);

  const { verificationPhotosDetails, selectedPhotosIds, toggleIsSelected } = useFetchFraudVerificationPhotosDetails(id);

  const language = useRecoilValue(languageSelector);

  const { mutate } = useMutation(setFraudResolutionStateAction);
  const { clockLogInitialized, refreshClockLogForPeopleIds } = useRefreshClockLog(
    extTimeEvent?.personId ? [extTimeEvent?.personId] : [],
  );
  const { reportInitialized, updateReportForIds } = useRefreshReport(
    extTimeEvent?.personId ? [extTimeEvent?.personId] : [],
  );

  const { breakpoint } = useThemeBreakpoint();
  const isViewXS = breakpoint === MEDIA_BREAKPOINTS.XS;

  const { fetchAntiSpoofingChecks } = useAntiSpoofingChecks();

  const handleReject = useCallback(
    () =>
      navigate(TO_REL.REJECT_EVENT_MODAL[language], {
        state: {
          modifyTimeEvent: { id, personId: extTimeEvent?.personId || '' },
          baseRoute: baseRoute || '/',
        },
      }),
    [baseRoute, extTimeEvent?.personId, id, language, navigate],
  );

  const handleApprove = useCallback(async () => {
    setIsApproveLoading(true);
    const { error } = await mutate({ id, isFraud: false, selectedPhotos: selectedPhotosIds });
    if (!error) {
      handleClose();
      addSnackbar({
        variant: 'success',
        message: t({ id: 'clock_log.anti_spoofing.success', message: 'Event accepted!' }),
      });
      if (updateAntiSpoofingChecks) {
        await fetchAntiSpoofingChecks(true);
      }
      if (clockLogInitialized) await refreshClockLogForPeopleIds();
      if (reportInitialized) await updateReportForIds();
    }
    setIsApproveLoading(false);
  }, [
    mutate,
    id,
    selectedPhotosIds,
    handleClose,
    updateAntiSpoofingChecks,
    clockLogInitialized,
    refreshClockLogForPeopleIds,
    reportInitialized,
    updateReportForIds,
    fetchAntiSpoofingChecks,
  ]);

  return (
    <>
      <Modal.Header>
        <Modal.Title sx={{ fontSize: [null, '1.5rem'] }}>
          <Trans id="clock_log.anti_spoofing">Anti-spoofing check</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Text sx={{ mb: '1.5rem' }}>
          <Trans id="clock_log.anti_spoofing.description">
            We're uncertain if this event should be rejected or accepted. Please take a look at photos below and help us
            make a correct choice. To approve, select all correct photos below. By rejecting, you reject all photos.
          </Trans>
        </Text>

        {extTimeEvent && (
          <EventDetails
            extTimeEvent={extTimeEvent}
            sx={{
              mb: '1.5rem',
              '& .person-cell': { width: isViewXS ? '100%' : 'auto', flexGrow: 2 },
              '& .event-details__badge': { flexGrow: 1 },
              '& .event-details__date': { flexGrow: 1 },
            }}
          />
        )}

        {verificationPhotosDetails ? (
          <Grid sx={{ gridTemplateColumns: ['1fr', '1fr 1fr'], justifyItems: 'center', gap: '.5rem' }}>
            {verificationPhotosDetails.map(({ url, id: photoId }) => (
              <AiPhotoSelect
                key={url}
                url={url}
                isSelected={selectedPhotosIds?.includes(photoId)}
                onSelect={() => toggleIsSelected(url)}
              />
            ))}
          </Grid>
        ) : (
          <CenteredLoadingSpinner size={5} />
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" shape="rounded" prependWith={<Icon size={18} type="deny" />} onClick={handleReject}>
          {t({ id: 'requests.button.reject' })}
        </Button>
        <Button
          isLoading={isApproveLoading}
          disabled={!selectedPhotosIds?.length}
          variant="success"
          shape="rounded"
          prependWith={<Icon size={18} type="approve" />}
          onClick={floatingPromiseReturn(handleApprove)}
        >
          {t({ id: 'requests.button.approve' })}
        </Button>
      </Modal.Footer>

      <Routes>
        <Route
          path={PATH_REL.REJECT_EVENT_MODAL[language]}
          element={
            <Modal size="xs" path={PATH_REL.REJECT_EVENT_MODAL[language]}>
              <RejectEventModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
