import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { ShowFieldsButton } from 'components/recipes/ShowFieldsButton';
import { Textarea } from 'components/ui/Textarea';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';
import { labelProps } from '../../../commons';

export const Note = React.memo((): React.ReactElement | null => {
  useLingui();
  const {
    formState: { noteVisible },
    setFormState,
    addRequestState: { requestType },
  } = useAddRequest();

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  const handleShowNoteOnClick = useCallback(
    () => setFormState((prevState) => ({ ...prevState, noteVisible: true })),
    [setFormState],
  );

  if (
    actionTypeWatch === RequestActionType.Remove ||
    requestType === RequestFormType.TimeTracking ||
    requestType === RequestFormType.Schedule
  )
    return null;

  if (!noteVisible)
    return (
      <ShowFieldsButton
        onClick={handleShowNoteOnClick}
        label={t({
          id: 'add_request.add_note',
          message: 'Add note (optional)',
        })}
      />
    );

  return (
    <Textarea
      id="note"
      {...register('note', validationFactory({ ...VALIDATION_RULES.ADD_REQUEST_NOTE }))}
      errorMessage={errors.note?.message}
      error={!!errors.note}
      placeholder={t({
        id: 'add_request.note.placeholder',
        message: 'A reason why are you making this request',
      })}
      label={t({
        id: 'add_request.note',
        message: 'Note',
      })}
      variant="rounded"
      size="sm"
      inputProps={{ sx: { height: '80px' } }}
      labelProps={labelProps}
    />
  );
});
