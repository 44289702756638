import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { useFetchCountryDetailsManager } from 'hooks/useFetchCountryDetailsManager/useFetchCountryDetailsManager';
import { euCountryCodesSelector } from 'state/payments';

export const useIsEuCountry = () => {
  const euCountryCodes = useRecoilValue(euCountryCodesSelector);

  useFetchCountryDetailsManager(true);

  const isEuCountry = useCallback((countryCode: string) => !!euCountryCodes?.includes(countryCode), [euCountryCodes]);

  return isEuCountry;
};
