import { FC, useMemo } from 'react';
import { Navigate, Route, RouteState, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ModalContextProps } from 'components/Modal/output/types';
import { useModal } from 'components/Modal/output/useModal';
import { PATH_REL } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useLocationOnce } from 'hooks/useLocationOnce/useLocationOnce';
import { languageSelector } from 'state/recoilState';
import { userSessionPersonIdSelector } from 'state/userSession';

import { ClearPosition } from './components/ClearPosition';
import { ClearTags } from './components/ClearTags';
import { DeleteFacesModels } from './components/DeleteFacesModels';
import { DeletePhotos } from './components/DeletePhotos';
import { Navigation } from './components/Navigation';
import { ResendInvitation } from './components/ResendInvitation';
import { SetAdvancedDetails } from './components/SetAdvancedDetails';
import { SetPayDetails } from './components/SetPayDetails';
import { SetPosition } from './components/SetPosition';
import { SetRequestLimits } from './components/SetRequestLimits';
import { SetRole } from './components/SetRole';
import { SetTags } from './components/SetTags';
import { SetTimeOffLimits } from './components/SetTimeOffLimits';
import { useDefaultTagsFeatures } from './hooks/useDefaultTagsFeatures';

type Props = Pick<ModalContextProps, 'baseRoute'> & Pick<RouteState, 'ids'>;

const BulkActionsInnerModal: FC<Props> = ({ ids: selectedEmployeesIds, baseRoute }) => {
  const language = useRecoilValue(languageSelector);

  const userId = useRecoilValue(userSessionPersonIdSelector);

  const { defaultTagsFeatures } = useDefaultTagsFeatures();
  const { betaAccessOptIn, isHeadAdmin } = useAppPermissions();

  const selectedEmployeesIdsWithoutCurrentUserId = useMemo(
    () => selectedEmployeesIds.filter((id) => id !== userId),
    [userId, selectedEmployeesIds],
  );

  const isCurrentUser =
    !!userId && !selectedEmployeesIdsWithoutCurrentUserId.length && !!selectedEmployeesIds.includes(userId);

  return (
    <Routes>
      <Route index element={<Navigation isCurrentUser={isCurrentUser} />} />
      {!isCurrentUser && (
        <Route path={PATH_REL.BULK_ACTIONS_MODAL__RESEND_INVITATION[language]} element={<ResendInvitation />} />
      )}

      <Route path={PATH_REL.BULK_ACTIONS_MODAL__SET_POSITION[language]} element={<SetPosition />} />
      <Route path={PATH_REL.BULK_ACTIONS_MODAL__SET_PAY_DETAILS[language]} element={<SetPayDetails />} />
      {!isCurrentUser && <Route path={PATH_REL.BULK_ACTIONS_MODAL__SET_ROLE[language]} element={<SetRole />} />}
      <Route path={PATH_REL.BULK_ACTIONS_MODAL__SET_TAGS[language]} element={<SetTags />} />
      <Route path={PATH_REL.BULK_ACTIONS_MODAL__SET_TIME_OFF_LIMITS[language]} element={<SetTimeOffLimits />} />
      <Route path={PATH_REL.BULK_ACTIONS_MODAL__SET_REQUEST_LIMITS[language]} element={<SetRequestLimits />} />
      {!isCurrentUser && (
        <Route
          path={PATH_REL.BULK_ACTIONS_MODAL__SET_ADVANCED_DETAILS[language]}
          element={
            defaultTagsFeatures ? (
              <SetAdvancedDetails defaultValues={defaultTagsFeatures} />
            ) : (
              <Navigate to={baseRoute} relative="path" />
            )
          }
        />
      )}
      <Route path={PATH_REL.BULK_ACTIONS_MODAL__CLEAR_POSITION[language]} element={<ClearPosition />} />
      <Route path={PATH_REL.BULK_ACTIONS_MODAL__CLEAR_TAGS[language]} element={<ClearTags />} />
      {isHeadAdmin && (
        <>
          {betaAccessOptIn.faceRecognition && (
            <Route
              path={PATH_REL.BULK_ACTIONS_MODAL__DELETE_AI_FACES_MODELS[language]}
              element={<DeleteFacesModels />}
            />
          )}
          <Route path={PATH_REL.BULK_ACTIONS_MODAL__DELETE_PHOTOS[language]} element={<DeletePhotos />} />
        </>
      )}
    </Routes>
  );
};

export const BulkActionsModal: FC = () => {
  const { state } = useLocationOnce();
  const { ids } = state || {};

  const { baseRoute } = useModal({ wrapperSx: { minHeight: '500px' } });

  if (!ids?.length) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return <BulkActionsInnerModal baseRoute={baseRoute} ids={ids} />;
};
