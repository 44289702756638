import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-fetching-library';
import { useRecoilState, useRecoilValue } from 'recoil';

import { fetchCalendarForIdsAction } from 'api/actions/calendar/calendarActions';
import { yourCalendarAtom, yourCalendarDateRangeAtom } from 'state/home';
import { userSessionPersonIdSelector } from 'state/userSession';

export const useYourCalendar = () => {
  const [yourCalendar, setYourCalendar] = useRecoilState(yourCalendarAtom);
  const userId = useRecoilValue(userSessionPersonIdSelector);
  const dates = useRecoilValue(yourCalendarDateRangeAtom);

  const fetchParams = useMemo(
    () =>
      dates && userId
        ? {
            employeeIds: [userId],
            startDateUnix: dates.startDateUnix,
            endDateUnix: dates.endDateUnix,
          }
        : null,
    [dates, userId],
  );

  const isInitialized = useMemo(() => !!yourCalendar, [yourCalendar]);

  const { query, loading } = useQuery(fetchCalendarForIdsAction(fetchParams), false);

  const fetchYourCalendar = useCallback(async () => {
    const { payload, error } = await query();

    if (!error && payload) {
      setYourCalendar(payload);
    }
  }, [query, setYourCalendar]);

  return {
    isInitialized,
    fetchYourCalendar,
    fetchInProgress: loading,
  };
};
