import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { publishSingleScheduleAction } from 'api/actions/calendar/calendarActions';
import { useModal } from 'components/Modal/output/useModal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'components/recipes/ConfirmModal/types';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { calendarSchedulesSelector } from 'state/calendar';
import { reportSchedulesSelector } from 'state/reports';
import { useRefreshCalendar } from '../../hooks/useRefreshCalendar';

export const PublishSingleScheduleModal = (): React.ReactElement => {
  const [isSchedulePublished, setIsSchedulePublished] = useState(false); // IMPORTANT: Only for onboarding

  const schedules = useRecoilValue(calendarSchedulesSelector);
  const reportSchedules = useRecoilValue(reportSchedulesSelector);

  const { id } = useParams() as { id: string };
  useLingui();
  const { isVisible: isOnboardingVisible, playNextStep, showWarningStep } = useInitialOnboarding();

  const getEmployeeId = useCallback(() => {
    if ((schedules || reportSchedules) && id) {
      const pickedSchedules = schedules || reportSchedules;

      if (!pickedSchedules) return undefined;

      return pickedSchedules.get(id)?.employeeId;
    }

    return undefined;
  }, [id, reportSchedules, schedules]);

  const employeeId = useMemo(() => getEmployeeId(), [getEmployeeId]);

  const isAi = useMemo(() => {
    if (!schedules || !id) return false;

    return _.isNumber(schedules.get(id)?.aiVariant);
  }, [schedules, id]);

  const { updateCalendarForIds, calendarInitialized, updateCalendar } = useRefreshCalendar(
    employeeId ? [employeeId] : undefined,
  );
  const { reportInitialized, updateReportForIds } = useRefreshReport(employeeId ? [employeeId] : undefined);

  const updateData = useCallback(async () => {
    if (calendarInitialized) updateCalendar();
    if (reportInitialized) await updateReportForIds();
  }, [calendarInitialized, reportInitialized, updateCalendar, updateReportForIds]);

  useEffect(() => {
    if (!schedules && !reportSchedules) {
      void updateData();
    }
  }, [reportSchedules, schedules, updateCalendar, updateData]);

  const titleRenderer = useCallback(() => t({ id: 'schedule.publish_schedule', message: 'Publish schedule?' }), []);

  const contentRenderer = useCallback(
    () => (
      <Trans id="schedule.publish_single_schedules_info">
        <Text>You're about to publish this schedule.</Text>
        <Text>
          <strong>User will be notified about this.</strong>
        </Text>
      </Trans>
    ),
    [],
  );

  const onResponseCallback: NonNullable<ConfirmModalProps['onResponseCallback']> = useCallback(
    async (error) => {
      if (!error) {
        setIsSchedulePublished(true); // IMPORTANT: Only for onboarding

        if (calendarInitialized) await updateCalendarForIds();
        if (reportInitialized) await updateReportForIds();
      }
    },
    [calendarInitialized, reportInitialized, updateCalendarForIds, updateReportForIds],
  );

  const onHandleClose = useCallback(
    (closeModal: () => void) => {
      closeModal();

      // IMPORTANT: Only for onboarding
      if (isOnboardingVisible) {
        if (isSchedulePublished) playNextStep();
        else showWarningStep();
      }
    },
    [isOnboardingVisible, playNextStep, isSchedulePublished, showWarningStep],
  );

  useModal({ onHandleClose });

  return (
    <ConfirmModal
      action={() => publishSingleScheduleAction(id, isAi)}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      variant="PUBLISH"
      onResponseCallback={onResponseCallback}
    />
  );
};
