import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { getAxisPointsToScan, getBoundingBoxForIsFaceInPlace } from 'Kiosk/helpers';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { FaceDetectionModel, faceDetectionModelAtom } from 'Kiosk/state/faceDetectionState';

type ReturnValue = FaceDetectionModel & {
  faceBounding?: ReturnType<typeof getBoundingBoxForIsFaceInPlace>;
  pointsToScan?: ReturnType<typeof getAxisPointsToScan>;
};

export type UseFaceDetectionReturnValue = ReturnValue;

export const useFaceDetection = (): ReturnValue => {
  const [state, setState] = useState<Pick<ReturnValue, 'faceBounding' | 'pointsToScan'>>();

  const faceDetectionState = useRecoilValue(faceDetectionModelAtom);
  const { isCameraReady, isCameraStreaming, source } = useRecoilValue(cameraStateAtom);

  useEffect(() => {
    if (source && source.video) {
      const faceBounding = getBoundingBoxForIsFaceInPlace(source.video);
      const pointsToScan = getAxisPointsToScan(source.video);

      setState({
        faceBounding,
        pointsToScan,
      });
    }
  }, [isCameraReady, isCameraStreaming, source]);

  return {
    ...state,
    ...faceDetectionState,
  };
};
