import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { Calendar } from './Calendar';
import { CompareSchedules } from './CompareSchedules';
import { ForPersonSelect } from './ForPersonSelect';

type Props = FlexProps;

type ScheduleContainerProps = Props;

type ScheduleContainerComponent = React.FC<ScheduleContainerProps> & {
  Calendar: typeof Calendar;
  ForPersonSelect: typeof ForPersonSelect;
  Schedules: typeof CompareSchedules;
};

export const ScheduleContainer: ScheduleContainerComponent = ({ children }: Props): React.ReactElement => (
  <Flex sx={{ flexDirection: 'column', gap: 4, position: 'relative' }}>{children}</Flex>
);

ScheduleContainer.Calendar = Calendar;
ScheduleContainer.ForPersonSelect = ForPersonSelect;
ScheduleContainer.Schedules = CompareSchedules;
