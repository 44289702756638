import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';
import { useTimeOffFormState } from '../hooks/useTimeOffFormState';

export const SaveStateObserver = (): null => {
  const {
    requestToEdit: { selectedRequestToEdit },
    modifyRequest: { modifyRequestData },
    setAddRequestState,
  } = useAddRequest();
  const { isDurationType, isForDayType } = useTimeOffFormState();

  const {
    formState: { errors },
    watch,
  } = useFormContext<AddRequestExtendedFormContext>();

  const [typeId, calendarDates, action] = watch(['details.typeId', 'dateTimeDetails.dates', 'actionType']);

  useEffect(() => {
    const datesFilledAndFormHasNoErrors =
      calendarDates &&
      calendarDates.length === 2 &&
      typeId &&
      typeId.length &&
      !errors.dateTimeDetails?.dates &&
      !errors.dateTimeDetails?.forDay &&
      !errors.dateTimeDetails?.duration &&
      !errors.calendarInternalError &&
      !errors?.note;
    const hasSelectedRequestToEditOrModifyData = selectedRequestToEdit || modifyRequestData;

    const isSaveDisabled = (() => {
      if (action === RequestActionType.Create) {
        return !datesFilledAndFormHasNoErrors;
      }
      if (action === RequestActionType.Remove) {
        return !(hasSelectedRequestToEditOrModifyData && !errors?.note);
      }
      if (action === RequestActionType.Edit) {
        return !(hasSelectedRequestToEditOrModifyData && datesFilledAndFormHasNoErrors);
      }
      return true;
    })();

    setAddRequestState((prevState) => ({ ...prevState, isSaveDisabled }));
  }, [
    errors.dateTimeDetails?.dates,
    errors.calendarInternalError,
    action,
    isForDayType,
    isDurationType,
    calendarDates,
    typeId,
    errors?.note,
    setAddRequestState,
    errors.dateTimeDetails?.forDay,
    errors.dateTimeDetails?.duration,
    selectedRequestToEdit,
    modifyRequestData,
  ]);

  return null;
};
