import { t } from '@lingui/macro';
import { FC } from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { TextEllipsis } from 'components/utils/TextEllipsis';

export type SendViaBadgeProps = {
  icon?: 'timeClockMobile' | 'mobileApp' | 'webApp' | 'kiosk' | 'system';
  size?: 'default' | 'sm' | 'xs';
  deviceName?: string;
} & FlexProps;

const SIZE: Record<Required<SendViaBadgeProps>['size'], number> = {
  default: 24,
  sm: 20,
  xs: 15,
};

export const generateSendViaBadgeLabel = (icon: Required<SendViaBadgeProps>['icon'], deviceName: string = ''): string =>
  ({
    kiosk: t({ id: 'time_clocks.web_kiosk', message: 'Web Kiosk' }),
    mobileApp: t({ id: 'time_clocks.mobile_app', message: 'Mobile app' }),
    system: t({ id: 'time_clocks.manually_added', message: 'Manually added' }),
    timeClockMobile: deviceName || t({ id: 'time_clocks.mobile_time_clock', message: 'Mobile Time Clock' }),
    webApp: t({ id: 'time_clocks.web_app', message: 'Web App' }),
  })[icon];

export const SendViaBadge: FC<SendViaBadgeProps> = ({ size = 'default', icon, deviceName, children, ...props }) => {
  const label = icon && generateSendViaBadgeLabel(icon, deviceName);
  return (
    <Flex
      {...props}
      sx={{
        borderRadius: 'xs',
        bg: 'whites0',
        fontWeight: '400',
        color: 'text',
        border: '1px solid',
        borderColor: 'alphas.darker4',
        pr: '4px',
        gap: '2px',
        alignItems: 'center',
        ...props?.sx,
      }}
    >
      {icon ? (
        <>
          <Icon type={icon} size={SIZE[size]} />
          <TextEllipsis title={label}>{label}</TextEllipsis>
        </>
      ) : (
        children
      )}
    </Flex>
  );
};
