import { useLocation } from 'react-router-dom';

/**
 * Creates a new URLSearchParams obj based on current or provided url.
 * Change with caution (see: useRedirectPath).
 *
 * @param {string | undefined} url custom url from which URLSearchParams param will be created, if not provided current url will be used.
 * @return {URLSearchParams} URLSearchParams created from current or provided url.
 */
export const useQuery = (url?: string): URLSearchParams => {
  const location = useLocation();

  if (url) return new URLSearchParams(new URL(url).search);
  return new URLSearchParams(location.search);
};
