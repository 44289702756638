import { useCallback } from 'react';
import { useQuery } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { fetchSchedulesAction } from 'api/actions/settings/settingsActions';
import { schedulesSettingsAtom } from 'state/settings';

export const useSchedules = () => {
  const { query, abort } = useQuery(fetchSchedulesAction(), false);
  const [schedulesSettings, setSchedulesSettings] = useRecoilState(schedulesSettingsAtom);

  const fetchSchedules = useCallback(async () => {
    const { error, payload } = await query();

    if (!error && payload) {
      setSchedulesSettings(payload);
    }
  }, [query, setSchedulesSettings]);

  return {
    fetchSchedules,
    schedulesSettings,
    setSchedulesSettings,
    fetchAbort: abort,
  };
};
