import React from 'react';
import { BoxOwnProps, BoxProps, Flex } from 'theme-ui';

import { Button } from '../Buttons';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';

type Props = BoxProps &
  BoxOwnProps & {
    variant: 'warning' | 'info' | 'danger';
    action?: { title: string; onClick: () => void; isLoading?: boolean };
  };

export type AlertProps = Props;

const defaultProps: Partial<Props> = { action: undefined };

export const Alert = ({ variant, action, children, sx, ...props }: Props): React.ReactElement => {
  const { isMobileBreakpoint } = useThemeBreakpoint();
  return (
    <Flex
      variant={`alerts.${variant}`}
      sx={{ ...(sx && sx), ...(isMobileBreakpoint && { textAlign: 'center' }) }}
      {...props}
    >
      {children}
      {action && (
        <Button
          onClick={action.onClick}
          isLoading={action.isLoading}
          shape="rounded"
          variant="grey"
          {...(!isMobileBreakpoint && { sx: { ml: 'auto' } })}
        >
          {action.title}
        </Button>
      )}
    </Flex>
  );
};
Alert.defaultProps = defaultProps;
