import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';

import { FilterGroupNames, TagsFilters, TeammatesFilters } from 'layouts/AuthorizedApp/AsideFilters/types';
import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';
import { getStringWithReducedWhiteSpaces } from 'utils/whiteSpaceReducer';

import {
  parsedEditableEmployeesForModulesSelectorFamily,
  ParsedEditableEmployeesForModulesSelectorFamilyConfig,
  ParsedEmployee,
} from './employees';
import {
  FilterGroupState,
  filterGroupStateAtomFamily,
  parsedSearchFilterValueSelectorFamily,
  SEARCH_FILTER_TYPE,
} from './filters';

export const pickTeammatesSearchFilterValueAtom = atom<string>({
  key: 'pickTeammatesSearchFilterValue',
  default: '',
});

export const pickTeammatesSearchFilterValueSelector = selector<string>({
  key: 'addRequestSearchFilterValueSelector',
  get: ({ get }) => get(pickTeammatesSearchFilterValueAtom),
  set: ({ set, get }, newValue) => {
    if (isRecoilDefaultValue(newValue)) return;
    const searchFilterValue = get(pickTeammatesSearchFilterValueAtom);

    if (
      getStringWithReducedWhiteSpaces(newValue.replaceAll(',', '')) !==
      getStringWithReducedWhiteSpaces(searchFilterValue.replaceAll(',', ''))
    ) {
      set(pickTeammatesSearchFilterValueAtom, newValue);
    }
  },
});

export const parsedPickTeammatesSearchFilterValueSelector = selector<string[]>({
  key: 'parsedPickTeammatesSearchFilterValue',
  get: ({ get }) =>
    getStringWithReducedWhiteSpaces(get(pickTeammatesSearchFilterValueAtom))
      .trim()
      .split(',')
      .map((v) => v.trim())
      .filter((v) => !!v),
});

// TEAMMATES filter state for pickTeammates list. At the start it takes value of aside filters teammates filter state.
export const pickTeammatesFilterGroupAtom = atom<FilterGroupState>({
  key: 'pickTeammatesFilterGroupAtom',
  default: filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES),
});

export const isActiveTeammatesFilterSelectorFamily = selectorFamily<boolean, TeammatesFilters>({
  key: 'isActiveTeammatesFilter',
  get:
    (filterId) =>
    ({ get }) => {
      const filterGroupState = get(pickTeammatesFilterGroupAtom);

      if (filterGroupState === null) {
        return filterGroupState === filterId;
      }
      if (filterId === null) return false;

      return filterGroupState.includes(filterId);
    },
  set:
    (filterId) =>
    ({ set, get }, newValue) => {
      const filterGroupState = get(pickTeammatesFilterGroupAtom);

      if (!newValue) {
        if (filterId === null) {
          return;
        }

        if (filterGroupState === null) {
          set(pickTeammatesFilterGroupAtom, [filterId]);
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        const newFilterGroupState = filterGroupState.filter((id) => id !== filterId);
        set(pickTeammatesFilterGroupAtom, newFilterGroupState.length ? newFilterGroupState : null);
        return;
      }

      if (filterId === null) {
        set(pickTeammatesFilterGroupAtom, filterId);
        return;
      }

      if (typeof filterGroupState === 'string' || filterGroupState === null) {
        set(pickTeammatesFilterGroupAtom, [filterId]);
        return;
      }

      set(pickTeammatesFilterGroupAtom, [...filterGroupState, filterId]);
    },
});

export const pickTeammatesHiddenEmployeesIdsAtom = atom<null | string[]>({
  key: 'pickTeammatesHiddenEmployeesIds',
  default: null,
});

export const pickTeammatesFilteredEmployeesSelectorFamily = selectorFamily<
  Map<string, ParsedEmployee>,
  ParsedEditableEmployeesForModulesSelectorFamilyConfig
>({
  key: 'pickTeammatesFilteredEmployeesSelectorNewVer',
  get:
    (config) =>
    ({ get }) => {
      const employeesMap = get(parsedEditableEmployeesForModulesSelectorFamily(config));

      // requests:
      if (!employeesMap) return new Map();

      const teammatesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES_PICK_TEAMMATES_FILTERS));
      const rolesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.ROLES_PICK_TEAMMATES_FILTERS));
      const tagsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TAGS_PICK_TEAMMATES_FILTERS));
      const searchFilterState = get(
        parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.TEAMMATES_PICK_TEAMMATES_FILTERS),
      );

      const hiddenIds = get(pickTeammatesHiddenEmployeesIdsAtom);

      const filterBySearchInput = (employee: ParsedEmployee) => {
        let valid = false;

        if (_.isEmpty(searchFilterState)) {
          valid = true;
          return valid;
        }

        const {
          name,
          role: { name: roleName },
          tags,
          customEmployeeId,
        } = employee;
        const searchableEmployee = _.flatMapDeep([
          `${name.firstName} ${name.surname}`,
          roleName,
          _.map(tags, (tag) => tag?.name),
          customEmployeeId,
        ]);

        valid = _.every(
          searchFilterState.map((searchQuery) => {
            let subValid = false;

            _.forEach(searchableEmployee, (searchableValue) => {
              subValid = subValid || _.includes(searchableValue?.toLocaleLowerCase(), searchQuery.toLocaleLowerCase());
            });

            return subValid;
          }),
        );

        return valid;
      };

      const filterByTeammates = (employee: ParsedEmployee) => {
        let valid = false;

        if (teammatesFilterState === null) {
          valid = true;
          return valid;
        }
        if (teammatesFilterState.includes(TeammatesFilters.ACTIVE)) {
          valid = valid || !!employee.isActive;
        }
        if (teammatesFilterState.includes(TeammatesFilters.HIDDEN)) {
          valid = valid || !!employee.isHidden;
        }
        if (teammatesFilterState.includes(TeammatesFilters.DEACTIVATED)) {
          valid = valid || !employee.isActive;
        }
        if (teammatesFilterState.includes(TeammatesFilters.INVITED)) {
          valid = valid || !!employee.invitationState;
        }

        return valid;
      };

      const filterByTags = (employee: ParsedEmployee) => {
        let valid = false;

        if (tagsFilterState === null) {
          valid = true;
          return valid;
        }

        if (_.isArray(tagsFilterState)) {
          tagsFilterState.forEach((tagId) => {
            valid = valid || !!employee.tagsIds.includes(tagId);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            if (tagId === TagsFilters.NO_TAGS && _.isEmpty(employee.tagsIds)) {
              valid = true;
            }
          });
        }

        return valid;
      };

      const filterByRole = (employee: ParsedEmployee) => {
        let valid = false;

        if (rolesFilterState === null) {
          valid = true;
          return valid;
        }

        if (_.isArray(rolesFilterState)) {
          rolesFilterState.forEach((roleId) => {
            valid = valid || !!(employee.roleId === roleId);
          });
        }

        return valid;
      };

      const newMap = new Map(employeesMap);

      newMap.forEach((employee, employeeId) => {
        let valid = false;

        valid =
          filterByTeammates(employee) &&
          filterBySearchInput(employee) &&
          filterByTags(employee) &&
          filterByRole(employee);

        if (!valid) newMap.delete(employeeId);
      });

      // FIXME: po co to? musi być tutaj, czy powinno być wyzej?
      // dele hidden employees
      if (hiddenIds) {
        hiddenIds.forEach((employeeId) => {
          newMap.delete(employeeId);
        });
      }

      return newMap;
    },
});
