import React, { forwardRef, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Flex, Text } from 'theme-ui';
import { plural, t } from '@lingui/macro';
import _ from 'lodash';

import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { createEvent } from 'utils/createEvent';
import { useOnOwnValueChange, UseOnOwnValueChangeProps } from 'hooks/useOnOwnValueChange/useOnOwnValueChange';
import { TextInputProps } from '../TextInput';

import { Uploader } from './Uploader';
import { BlobFile } from './BlobFile';
import { ServerFile } from './ServerFile';
import {
  DEFAULT_ACCEPTED_EXTENSIONS,
  DEFAULT_MAX_FILES,
  DEFAULT_MIN_FILES,
  DEFAULT_MAX_UPLOAD_SIZE,
  DEFAULT_UPLOAD_TIMEOUT_THRESHOLD,
} from './constants';
import { FileUploadProps, ServerFileDetails } from './types';

const defaultProps: Partial<FileUploadProps> = {
  onBlur: undefined,
  onValidError: undefined,
  onClearError: undefined,
  error: false,
  errorMessage: undefined,
  isStatic: false,
  timeout: DEFAULT_UPLOAD_TIMEOUT_THRESHOLD,
  maxSize: DEFAULT_MAX_UPLOAD_SIZE,
  acceptExt: DEFAULT_ACCEPTED_EXTENSIONS as FileUploadProps['acceptExt'],
  maxFiles: DEFAULT_MAX_FILES,
  minFiles: DEFAULT_MIN_FILES,
  defaultValue: undefined,
};

/**
 * File Upload component.
 *
 * @prop {number} minFiles This prop will NOT prevent mama form submit when no files are added. To avoid it use React-Hook-Form "required" prop.
 * @prop {number} maxFiles 0 - unlimited.
 */
export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  (
    {
      onChange,
      name: registeredName,
      onBlur,
      onValidError,
      onClearError,
      error,
      errorMessage,
      isStatic,
      timeout = DEFAULT_UPLOAD_TIMEOUT_THRESHOLD,
      maxSize,
      minSize,
      acceptExt,
      maxFiles = DEFAULT_MAX_FILES,
      minFiles,
      defaultValue,
      attachmentVariant,
    }: FileUploadProps,
    ref,
  ): React.ReactElement => {
    const [filesIds, setFilesIds] = useState<string[]>([]);
    const [blobFiles, setBlobFiles] = useState<File[]>([]);
    const [serverFiles, setServerFiles] = useState<ServerFileDetails[]>([]);
    const [filesErrors, setFilesErrors] = useState<string[]>([]);
    const [hasMinFilesError, setHasMinFilesError] = useState<boolean>(false);

    const hiddenRef = useRef<HTMLInputElement | null>(null);
    const afterFirstBlurRef = useRef(false);

    const currentError = useMemo(
      () =>
        (hasMinFilesError &&
          !filesErrors.length &&
          t({
            id: 'file_upload.min_files_error',
            message: plural(minFiles || DEFAULT_MIN_FILES, {
              other: `Attach at least ${minFiles || DEFAULT_MIN_FILES} files.`,
            }),
          })) ||
        (error && !hasMinFilesError && !filesErrors.length && errorMessage) ||
        null,
      [error, errorMessage, filesErrors.length, hasMinFilesError, minFiles],
    );

    // initialize component with filesIds passed from react-hook-form useForm default values
    useEffect(() => {
      if (hiddenRef?.current?.value) {
        const initialFilesIds = hiddenRef.current.value.split(',');
        setFilesIds(initialFilesIds);

        const initialFiles = initialFilesIds.map((id) => ({ id }));
        setServerFiles(initialFiles);
      }
    }, []);

    // handle filesIds passed to component by react-hook-form setValue or reset functions
    const onOwnValueChange: UseOnOwnValueChangeProps['onChange'] = useCallback((newFilesIds) => {
      const internalFilesIds = _.isArray(newFilesIds) ? newFilesIds.map((v) => `${v}`) : [];
      setFilesIds(internalFilesIds);

      const newFiles = internalFilesIds.map((id) => ({ id }));
      setServerFiles(newFiles);
    }, []);

    useOnOwnValueChange(hiddenRef, onOwnValueChange);

    // update file ids hidden storage
    useEffect(() => {
      setNativeValue(hiddenRef, filesIds);
    }, [filesIds]);

    // handle required prop (create and dispatch blur event to display react-hook-form "required" error **on files change**)
    const dispatchBlurEvent = useCallback(() => {
      afterFirstBlurRef.current = true;
      const blurEvent = createEvent('focusout');
      if (hiddenRef.current) hiddenRef.current.dispatchEvent(blurEvent);
    }, []);

    const handleBlur: NonNullable<TextInputProps['onBlur']> = useCallback(
      (e) => {
        if (afterFirstBlurRef.current && onBlur) onBlur(e);
      },
      [onBlur],
    );

    // update minFiles error
    useEffect(() => {
      if (minFiles && filesIds.length < minFiles && filesIds.length !== 0) setHasMinFilesError(true);
      else setHasMinFilesError(false);
    }, [filesIds, minFiles, setHasMinFilesError]);

    // update internal errors
    const hasInternalError = useMemo(() => filesErrors.length || hasMinFilesError, [filesErrors, hasMinFilesError]);

    // handle internal component errors (set mama form to error state while 1. file is uploading 2. minFiles not reached 3. file is in "withError" state (file tile is red))
    useEffect(() => {
      if (hasInternalError && onValidError) onValidError();
      if (!hasInternalError && onClearError) onClearError();
    }, [hasInternalError, onValidError, onClearError]);

    return (
      <>
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          {serverFiles.length > 0 &&
            serverFiles.map((serverFile) => (
              <ServerFile
                key={serverFile.id}
                serverFile={serverFile}
                setServerFiles={setServerFiles}
                setFilesIds={setFilesIds}
                isStatic={isStatic}
                dispatchBlurEvent={dispatchBlurEvent}
                attachmentVariant={attachmentVariant}
              />
            ))}

          {!isStatic && (
            <>
              {blobFiles.length > 0 &&
                blobFiles.map((blobFile) => (
                  <BlobFile
                    key={`${blobFile.name}${blobFile.size}`}
                    blobFile={blobFile}
                    setBlobFiles={setBlobFiles}
                    setServerFiles={setServerFiles}
                    setFilesIds={setFilesIds}
                    setFilesErrors={setFilesErrors}
                    dispatchBlurEvent={dispatchBlurEvent}
                    timeout={timeout}
                  />
                ))}
              {maxFiles && (maxFiles === 0 || blobFiles.length + serverFiles.length < maxFiles) && (
                <Uploader
                  blobFiles={blobFiles}
                  serverFiles={serverFiles}
                  setBlobFiles={setBlobFiles}
                  maxSize={maxSize}
                  minSize={minSize}
                  acceptExt={acceptExt}
                  maxFiles={maxFiles}
                />
              )}
            </>
          )}

          {/* file ids storage, for mama form && backend communication */}
          <input
            ref={mergeRefs([ref, hiddenRef])}
            name={registeredName}
            onChange={onChange} // update mama form state
            onBlur={handleBlur}
            id={registeredName}
            style={{ width: 0, opacity: 0, position: 'absolute' }}
            defaultValue={defaultValue}
          />
        </Flex>
        {currentError && <Text variant="fileUpload.errorMessage">{currentError}</Text>}
      </>
    );
  },
);

FileUpload.defaultProps = defaultProps;
