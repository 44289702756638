import { useCallback, useState } from 'react';
import { flushSync } from 'react-dom';
import { useClient } from 'react-fetching-library';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { fetchTimeTrackerAction } from 'api/actions/drawer/drawerActions';
import { FetchTimeTrackerResponse } from 'api/actions/drawer/drawerActions.types';
import { getTimeTracker as channelGetTimeTracker } from 'broadcastChannel/channelActionCreators/channelActionCreators';
import { GetTimeTrackerChannelActionProps } from 'broadcastChannel/channelReducer/channelReducer.types';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { useMount } from 'hooks/useMount/useMount';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { broadcastChannel } from 'observers/BroadcastChannelObserver';
import { actionHasBeenSentAtom, timeTrackerAtom } from 'state/drawer';
import { CustomEvents } from 'utils/customEvents';

let internalLastFetchTime: null | number = null;
let lastHandledVerificationId: null | string = null;

let isActiveBroadcastChannelSync = false; // ensure there is no more then one active listener between all instances

export const useTimeTracker = (withBroadcastChannelSync: boolean = false) => {
  const [activateBroadcastChannelSync] = useState(withBroadcastChannelSync && !isActiveBroadcastChannelSync);
  const setPostActionHasBeenSent = useSetRecoilState(actionHasBeenSentAtom);
  const [lastFetchTime, setLastFetchTime] = useState<number | null>(internalLastFetchTime);
  const { query } = useClient();
  const [timeTracker, setTimeTracker] = useRecoilState(timeTrackerAtom);
  const resetTimeTracker = useResetRecoilState(timeTrackerAtom);

  const updateFetchTime = useCallback(() => {
    const currentTime = Date.now();
    internalLastFetchTime = currentTime;
    setLastFetchTime(currentTime);
  }, [setLastFetchTime]);

  const handleFetchTimeTrackerPayload = useCallback(
    (payload: FetchTimeTrackerResponse) => {
      lastHandledVerificationId = payload.verificationId;
      setTimeTracker(payload);
    },
    [setTimeTracker],
  );

  const getTimeTracker = useCallback(
    async (wasTriggeredByNewTimeEventAction?: boolean) => {
      updateFetchTime();

      const { error, payload } = await query(fetchTimeTrackerAction());
      if (!error && payload) {
        handleFetchTimeTrackerPayload(payload);
        // share new timeTracker with other browser tabs to reduce requests
        void broadcastChannel?.postMessage(channelGetTimeTracker({ ...payload, wasTriggeredByNewTimeEventAction }));
      }
    },
    [query, updateFetchTime, handleFetchTimeTrackerPayload],
  );

  useCustomEventListener(
    activateBroadcastChannelSync ? CustomEvents.HANDLE_BROADCAST_CHANNEL_TIME_TRACKER : null,
    (newTimeTracker?: GetTimeTrackerChannelActionProps) => {
      if (!newTimeTracker || lastHandledVerificationId === newTimeTracker.verificationId) return;
      const { wasTriggeredByNewTimeEventAction, ...rest } = newTimeTracker;

      updateFetchTime();
      handleFetchTimeTrackerPayload(rest);

      if (!wasTriggeredByNewTimeEventAction) return;
      // to support closing drawer after postTimeEvent logic
      const simulatePostTimeEvent = () => {
        flushSync(() => {
          setPostActionHasBeenSent(true);
        });
        setPostActionHasBeenSent(false);
      };
      simulatePostTimeEvent();
    },
  );

  useMount(() => {
    if (!withBroadcastChannelSync) return;
    isActiveBroadcastChannelSync = true;
  });

  useUnmount(() => {
    if (!withBroadcastChannelSync) return;
    isActiveBroadcastChannelSync = false;
  });

  return {
    timeTracker,
    setTimeTracker,
    getTimeTracker,
    resetTimeTracker,
    lastFetchTime,
  };
};
