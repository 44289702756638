import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { withTooltip } from 'components/ui/Tooltip/withTooltip';

const FlexWithTooltip = withTooltip(Flex);

type FancyListItemProps = Omit<FlexProps, 'variant'> & {
  tooltip?: string;
  variant?: 'default' | 'danger';
};

export const FancyListItem = ({
  tooltip,
  sx,
  onClick,
  variant = 'default',
  ...props
}: FancyListItemProps): React.ReactElement => {
  const flexProps = {
    sx,
    onClick,
    'data-interactive': !!onClick,
    variant: `reports.fancyListItem.${variant}`,
    ...props,
  };
  return tooltip ? (
    <FlexWithTooltip
      tooltipProps={{
        content: tooltip,
      }}
      {...flexProps}
    />
  ) : (
    <Flex {...flexProps} />
  );
};

type FancyListProps = Omit<FlexProps, 'variant'>;

type FancyListComponent = React.FC<FancyListProps> & {
  Item: typeof FancyListItem;
};

export const FancyList: FancyListComponent = ({ sx, ...props }: FancyListProps): React.ReactElement => (
  <Flex variant="reports.fancyList" {...props} sx={sx} />
);

FancyList.Item = FancyListItem;
