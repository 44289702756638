import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { dateRangeRequestsUsageOverviewFilterAtom } from 'state/filters';
import { requestsUsageOverviewAtom } from 'state/requests';
import { useRequestsUsageOverview } from '../../../hooks/useRequestsUsageOverview';

export const useRefreshRequestsUsageOverview = () => {
  const dates = useRecoilValue(dateRangeRequestsUsageOverviewFilterAtom);
  const requestsUsageOverview = useRecoilValue(requestsUsageOverviewAtom);

  const { fetchRequestsUsageOverview, fetchRequestsUsageNoThrottle } = useRequestsUsageOverview(dates);

  const updateRequestsUsageOverview = useCallback(() => {
    fetchRequestsUsageOverview();
  }, [fetchRequestsUsageOverview]);

  const updateRequestsUsageOverviewNoThrottle = useCallback(
    async (unAbortable?: boolean) => {
      await fetchRequestsUsageNoThrottle(unAbortable);
    },
    [fetchRequestsUsageNoThrottle],
  );

  return {
    updateRequestsUsageOverview,
    updateRequestsUsageOverviewNoThrottle,
    requestsUsageOverviewInitialized: !!requestsUsageOverview,
  };
};
