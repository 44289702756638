import { useEffect } from 'react';

/**
 * Fires a callback on window resize event.
 */
export const useOnResize = (callback: () => void): void => {
  useEffect(() => {
    function handleResize() {
      callback();
    }
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [callback]);
};
