import { Plural, t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Text } from 'theme-ui';

import { deleteWebhookAction } from 'api/actions/webhook/webhookActions';
import { useModal } from 'components/Modal/output/useModal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { useWebhooks } from '../../hooks/useWebhooks';
import { webhookTypeTranslationId } from '../../webhookTypeTranslationId';

export const DeleteWebhooksModal = (): React.ReactElement => {
  useLingui();
  const { state } = useLocation();
  const { handleClose } = useModal();
  const { fetchWebhooks, webhooks } = useWebhooks();

  const titleRenderer = useCallback(
    (idsCount: number) =>
      idsCount > 1
        ? t({ id: 'settings.webhook.delete_webhooks', message: 'Delete Webhooks?' })
        : t({ id: 'settings.webhook.delete_webhook', message: `Delete Webhook?` }),
    [],
  );

  const contentRenderer = useCallback(
    (count: number, selectedNames: string[]) => (
      <Text>
        <Trans id="settings.webhook.delete_description">
          You're about to permanently delete{' '}
          <Plural
            value={count}
            one="this webhook."
            other={
              <Text>
                webhooks: <strong>{selectedNames.join(', ')}.</strong>
              </Text>
            }
          />{' '}
          <strong>This action cannot be reversed.</strong>
        </Trans>
      </Text>
    ),
    [],
  );

  const nameParser = useCallback(
    (id: string) => {
      if (!webhooks) return '';

      const webhook = webhooks.find(({ id: itemId }) => itemId === id);
      return webhook ? t({ id: webhookTypeTranslationId[webhook.type] }) : '';
    },
    [webhooks],
  );

  const onResponseCallback = (error: boolean) => {
    if (!error) {
      void fetchWebhooks();
    }
  };

  useEffect(() => {
    if (!state && handleClose) {
      handleClose();
    }
  }, [handleClose, state]);

  return (
    <ConfirmModal
      list={webhooks}
      action={deleteWebhookAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
      nameParser={nameParser}
      onResponseCallback={onResponseCallback}
    />
  );
};
