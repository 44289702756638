import _ from 'lodash';

import { makeTheme } from 'styles/makeTheme';

import { attendancePillStyles } from './attendancePill';
import { base } from './base';
import { buttons } from './buttons';
import { calendarStyles } from './calendar';
import { cards } from './cards';
import { chatStyles } from './chat';
import { colors } from './colors';
import { customComponents } from './customComponents';
import { fileUploadStyles } from './fileUpload';
import { forms } from './forms';
import { layouts } from './layouts';
import { onboardingStyles } from './onboarding';
import { paymentStyles } from './payments';
import { reportStyles } from './reports';
import { settingsStyles } from './settings';
import { stickyListStyles } from './stickyList';
import { text } from './text';

const mergedTheme = _.merge(
  {
    ...colors,
  },
  {
    ...base,
    ...buttons,
    ...cards,
    ...customComponents,
    ...layouts,
    ...chatStyles,
    ...stickyListStyles,
    ...attendancePillStyles,
    ...fileUploadStyles,
    ...reportStyles,
    ...calendarStyles,
    ...paymentStyles,
    ...settingsStyles,
    ...forms,
    ...text,
    ...onboardingStyles,
  },
);

export const theme = makeTheme(mergedTheme);
