import { atomFamily, selectorFamily } from 'recoil';

type SelectId = string;
type OptionAriaSelectedSelectorFamilyConfig = {
  selectId: SelectId;
  itemIndex: number;
};

export const optionAriaSelectedIndexAtomFamily = atomFamily<number | null, string>({
  key: 'optionAriaSelectedIndex',
  default: null,
});

export const optionAriaSelectedSelectorFamily = selectorFamily<boolean, OptionAriaSelectedSelectorFamilyConfig>({
  key: 'optionAriaSelectedSelector',
  get:
    ({ itemIndex, selectId }) =>
    ({ get }) =>
      get(optionAriaSelectedIndexAtomFamily(selectId)) === itemIndex || false,
});
