import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';

import { Button } from 'components/ui/Buttons/Button';
import { disconnectSx } from '../../../styles/styles';
import { IntegrationsDisconnectButtonProps } from '../../../types';

type Props = IntegrationsDisconnectButtonProps;

export const Disconnect = ({ isActive, isExpired, textVariant, ...props }: Props): React.ReactElement => {
  useLingui();

  const buttonText = useMemo(() => {
    switch (textVariant) {
      case 'disconnect':
        return t({ id: 'disconnect', message: 'Disconnect' });
      case 'disable':
        return t({ id: 'disable', message: 'Disable' });
      default:
        return '';
    }
  }, [textVariant]);

  if (!isActive && !isExpired) return <></>;

  return (
    <Button variant="naked" size="sm" sx={{ ...disconnectSx }} {...props}>
      {buttonText}
    </Button>
  );
};
