import React, { useEffect, useMemo } from 'react';
import { Flex } from 'theme-ui';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { Modal } from 'components/Modal/output/Modal';
import { useMount } from 'hooks/useMount/useMount';

import { RequestDetailsRoutes } from './RequestDetailsRoutes';
import { BusinessCustomTimeOffDetails } from './components/BusinessCustomTimeOffDetails/BusinessCustomTimeOffDetails';
import { TimeTrackingRequestChange } from './components/RequestChange/components/TimeTrackingRequestChange';
import { RequestDetailsFooter } from './components/RequestDetailsFooter';
import { RequestDetailsHeader } from './components/RequestDetailsHeader';
import { RequestDetailsMain } from './components/RequestDetailsMain/RequestDetailsMain';
import { ScheduleDetails } from './components/ScheduleDetails';
import { useRequestDetails } from './hooks/useRequestDetails';
import { useRequestDetailsActions } from './hooks/useRequestDetailsActions';

export const RequestDetailsModal = (): React.ReactElement => {
  const {
    parsedRequestDetails,
    setParsedRequestDetails,
    setRequestDetails,
    setRequestDetailsState,
    locationState: { requestDetails, requestDetailsData },
  } = useRequestDetails();

  const {
    getRequestDetails,
    onSubmitRejectDeleteAction,
    handleApprove,
    handleReject,
    handleModificationRequestClick,
    approveLoading,
  } = useRequestDetailsActions();

  useMount(() => {
    if (!requestDetailsData) {
      void getRequestDetails();
    }

    if (requestDetailsData) {
      setParsedRequestDetails(requestDetailsData);
    }

    if (requestDetails) {
      setRequestDetailsState(requestDetails);
    }
  });

  useEffect(
    () => () => {
      setRequestDetails(null);
      setRequestDetailsState(null);
      setParsedRequestDetails(null);
    },
    [setRequestDetails, setRequestDetailsState, setParsedRequestDetails],
  );

  const renderTypeDetails = useMemo(() => {
    if (parsedRequestDetails) {
      const { type } = parsedRequestDetails;

      switch (type) {
        case RequestFormType.TimeOff:
        case RequestFormType.Custom:
        case RequestFormType.BusinessTrip:
          return <BusinessCustomTimeOffDetails />;
        case RequestFormType.TimeTracking:
          return <TimeTrackingRequestChange />;
        case RequestFormType.Schedule:
          return <ScheduleDetails />;
        default:
          return null;
      }
    }

    return null;
  }, [parsedRequestDetails]);

  return (
    <>
      <RequestDetailsHeader />
      <Modal.Body>
        {parsedRequestDetails ? (
          <RequestDetailsMain>{renderTypeDetails}</RequestDetailsMain>
        ) : (
          <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
            <LoadingSpinnerCss size={4} />
          </Flex>
        )}
      </Modal.Body>
      <RequestDetailsFooter
        handleApprove={handleApprove}
        handleReject={handleReject}
        handleModificationRequestClick={handleModificationRequestClick}
        approveLoading={approveLoading}
      />
      <RequestDetailsRoutes onSubmitRejectDeleteAction={onSubmitRejectDeleteAction} />
    </>
  );
};
