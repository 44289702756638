import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Modal } from 'components/Modal/output/Modal';
import { PATH_REL } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { ManageRolesModal } from '../role/ManageRoles';
import { AddTagModal } from '../tag/AddTag';
import { ManageTagsModal } from '../tag/ManageTags';
import { ManageWorkPositionsModal } from '../workPositions/ManageWorkPositions';

export const ModalRoutes: FC = () => {
  const language = useRecoilValue(languageSelector);
  return (
    <Routes>
      <Route
        path={PATH_REL.ADD_TAG_MODAL[language]}
        element={
          <Modal fullHeight>
            <AddTagModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.MANAGE_TAGS_MODAL[language]}
        element={
          <Modal size="lg" fullHeight>
            <ManageTagsModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.MANAGE_ROLES_MODAL[language]}
        element={
          <Modal size="lg" fullHeight>
            <ManageRolesModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.MANAGE_WORK_POSITIONS_MODAL[language]}
        element={
          <Modal fullHeight>
            <ManageWorkPositionsModal />
          </Modal>
        }
      />
    </Routes>
  );
};
