import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState, useRecoilValue } from 'recoil';

import { settingsAtom } from 'Kiosk/state/settingsState';
import { getSettings } from 'api/actions/timeclock/timeclockActions';
import { LoadingSplash } from 'components/Loading/LoadingSplash';
import { organizationSessionAtom } from 'state/organizationSession';

type Props = {
  children: React.ReactElement;
};

export const KioskSettingsInitializer = ({ children }: Props): React.ReactElement => {
  const [isInitialized, setIsInitialized] = useState(false);

  const [kioskSettings, setKioskSettings] = useRecoilState(settingsAtom);
  const organizationSession = useRecoilValue(organizationSessionAtom);

  const { query } = useClient();

  const fetchKioskSettings = useCallback(async () => {
    const { payload, error } = await query(getSettings());

    if (!error && payload && organizationSession) {
      const {
        timeTrackingSettings: {
          isLocationRequired,
          isBasicFraudDetectionRequired,
          isAdvancedFraudDetectionRequired,
          isFaceRecognitionRequired,
          isPhotoRecognitionRequired,
        },
        details: { name },
      } = organizationSession;

      let mutableSettings;

      if (kioskSettings) {
        const { sleepModeTimeOutMinutes, summaryTimeSeconds, isSleepModeEnabled } = kioskSettings;
        mutableSettings = {
          ...(!_.isUndefined(sleepModeTimeOutMinutes) && { sleepModeTimeOutMinutes }),
          ...(!_.isUndefined(summaryTimeSeconds) && { summaryTimeSeconds }),
          ...(!_.isUndefined(isSleepModeEnabled) && { isSleepModeEnabled }),
        };
      }

      setKioskSettings({
        ...payload,
        ...mutableSettings,
        isLocationRequired,
        isBasicFraudDetectionRequired,
        isAdvancedFraudDetectionRequired,
        isFaceRecognitionRequired,
        isCameraRequired: isPhotoRecognitionRequired,
        organizationName: name,
      });
    }

    setIsInitialized(true);
  }, [kioskSettings, organizationSession, query, setKioskSettings]);

  useEffect(() => {
    if (!isInitialized) {
      void fetchKioskSettings();
    }

    if (kioskSettings && !isInitialized) {
      setIsInitialized(true);
    }
  }, [fetchKioskSettings, isInitialized, kioskSettings]);

  if (!isInitialized) {
    return <LoadingSplash sx={{ zIndex: 'max' }} />;
  }

  return <>{children}</>;
};
