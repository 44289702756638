import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { Text } from 'theme-ui';

import { Divider } from 'components/Divider/Divider';
import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { Switch } from 'components/ui/Switch';
import { VALIDATION_RULES } from 'constants/validationRules';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { useFormContextCustomEvent } from '../../../../../../../hooks/useFormContextCustomEvent';
import { OptionLabel } from '../../../../../../OptionLabel';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';

export const Breaks = (): React.ReactElement => {
  useLingui();
  const {
    registerOnChange,
    registerOnBlur,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContextCustomEvent();

  const showPaidBreakWatch = watch('showPaidBreak');
  const limitPaidBreakWatch = watch('limitPaidBreak');

  const setPaidBreakErrorCallback = useCallback(() => {
    setError('paidBreakInternalError' as 'paidBreakLimitSeconds', { type: 'max' });
  }, [setError]);

  const clearPaidBreakErrorCallback = useCallback(() => {
    clearErrors('paidBreakInternalError' as 'paidBreakLimitSeconds');
  }, [clearErrors]);

  return (
    <SettingsGroup variant="sm">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="time_tracking_settings.heading.breaks">Breaks</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <SettingsGroup.Body>
        <SettingsGroup.Body.Visible variant="md" pb={2} withoutBorderBottom>
          <Switch
            {...registerOnChange('showPaidBreak')}
            label={t({
              id: 'time_tracking_settings.show_paid_break.label',
              message: 'Show Paid Break',
            })}
            additionalInfo={
              <Text>
                <Trans id="time_tracking_settings.show_paid_break.additionalInfo">
                  <Text as="span" sx={{ textDecoration: 'underline' }}>
                    DOESN'T
                  </Text>
                  pause work timer.
                </Trans>
              </Text>
            }
            size="sm"
            bold
          />
          {!showPaidBreakWatch && <Divider />}
        </SettingsGroup.Body.Visible>

        {showPaidBreakWatch && (
          <SettingsGroup.Body.Hidden variant="md" sx={{ pt: 2 }}>
            <Switch
              {...registerOnChange('limitPaidBreak')}
              label={t({
                id: 'time_tracking_settings.limit_paid_break.label',
                message: 'Limit the maximum allowed duration of Paid Break',
              })}
              size="sm"
              bold
              sx={{ py: SETTINGS_SPACE.switchWithLabel }}
            />
            <Divider />
            {limitPaidBreakWatch && (
              <OptionLabel
                label={t({
                  id: 'time_tracking_settings.paid_break_limit_minutes.label',
                  message: 'Maximum allowed duration of Paid Break',
                })}
                apendWith={
                  <DurationPicker
                    {...registerOnBlur('paidBreakLimitSeconds', {
                      valueAsNumber: true,
                      required: t({ id: 'global.forms.required.short' }),
                    })}
                    id="paidBreakLimitSeconds"
                    size="sm"
                    minutes
                    hours={false}
                    seconds={false}
                    onValidError={setPaidBreakErrorCallback}
                    onClearError={clearPaidBreakErrorCallback}
                    error={!!errors.paidBreakLimitSeconds}
                    errorMessage={errors.paidBreakLimitSeconds?.message}
                    minDuration={VALIDATION_RULES.PAID_BREAK_DURATION_SECONDS.min}
                    maxDuration={VALIDATION_RULES.PAID_BREAK_DURATION_SECONDS.max}
                    inputVariant="minutes"
                    extended
                  />
                }
                withDivider
              />
            )}
          </SettingsGroup.Body.Hidden>
        )}

        <SettingsGroup.Body.Visible variant="md" pt={showPaidBreakWatch ? 2 : '0rem'} withoutBorderTop>
          <Switch
            {...registerOnChange('showUnPaidBreak')}
            label={t({
              id: 'time_tracking_settings.show_un_paid_break.label',
              message: 'Show Unpaid Break',
            })}
            additionalInfo={t({
              id: 'time_tracking_settings.show_un_paid_break.additionalInfo',
              message: 'Pauses work timer.',
            })}
            size="sm"
            bold
          />
        </SettingsGroup.Body.Visible>
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
