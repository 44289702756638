import type { QueryResponse, ResponseInterceptor } from 'react-fetching-library';

import { refreshAccessTokenAction } from 'api/actions/auth/authActions';
import { RefreshAccessTokenAccountTypeCodes, RefreshAccessTokenResponse } from 'api/actions/auth/authActions.types';
import { REDIRECT_INITIATORS } from 'broadcastChannel/channelReducer/channelReducer';
import { API_ENDPOINTS } from 'constants/api';
import { TO } from 'constants/routes';
import { setEmail, setIsSignUpInProgress, setToken } from 'context/auth/authActionCreators/authActionCreators';
import { AuthDispatchContextProps } from 'context/auth/authContext/AuthContext.types';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';
import { getRequestUrl } from 'utils/getRequestUrl';
import { Action } from '../types';

let refreshAccessTokenPromise: Promise<QueryResponse<RefreshAccessTokenResponse>> | null = null;

export const responseRefreshAccessTokenInterceptor: (dispatch: AuthDispatchContextProps) => ResponseInterceptor =
  (dispatch) => (client) => async (action: Action<unknown>, response) => {
    if (action.config && (action.config.skipAuthorization || action.config.skipAllResponseInterceptors)) {
      return response;
    }

    const refreshAccessTokenRequestUrl = getRequestUrl(API_ENDPOINTS.refreshAccessToken);

    if (response.status === 401 && action.endpoint !== refreshAccessTokenRequestUrl) {
      if (!refreshAccessTokenPromise) {
        refreshAccessTokenPromise = client.query(refreshAccessTokenAction()).then((refreshAccessTokenResponse) => {
          if (refreshAccessTokenResponse.error || !refreshAccessTokenResponse.payload) {
            emitCustomEvent(CustomEvents.REDIRECT, {
              route: TO.SIGN_OUT,
              state: { redirectInitiator: REDIRECT_INITIATORS.REFRESH_ACCESS_TOKEN_INTERCEPTOR },
            });
            refreshAccessTokenPromise = null;
            return refreshAccessTokenResponse;
          }

          dispatch(setToken(refreshAccessTokenResponse.payload.accessToken));

          refreshAccessTokenPromise = null;

          return refreshAccessTokenResponse;
        });
      }
      const refreshAccessTokenResponse = await refreshAccessTokenPromise;
      const { error, payload } = refreshAccessTokenResponse;

      if ((refreshAccessTokenResponse && error) || !payload) {
        return response;
      }
      if (payload && payload.accountType === RefreshAccessTokenAccountTypeCodes.SIGN_UP_IN_PROGRESS) {
        dispatch(setIsSignUpInProgress(true));

        if (payload.email) {
          dispatch(setEmail(payload.email));
        }

        if (!action.config || (action.config && !action.config.resendFailedSignUpQuery)) {
          return response;
        }
      }

      const originalActionConfig = action.config ? { ...action.config } : null;

      action.config = {
        ...(originalActionConfig && originalActionConfig),
        skipAllResponseInterceptors: true,
      };

      return client.query({
        ...action,
        headers: {
          ...action.headers,
          Authorization: payload && `Bearer ${payload.accessToken}`,
        },
        config: {
          ...(originalActionConfig && originalActionConfig),
          skipAuthorization: true,
          skipHost: true,
          resentTimes: 0,
        },
      });
    }
    return response;
  };
