import { useEffect } from 'react';

export const UnmountObserver = (): null => {
  useEffect(
    () => () => {
      window.location.reload();
    },
    [],
  );

  return null;
};
