import { FC, useCallback, useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { Text, Flex } from 'theme-ui';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useQuery } from 'react-fetching-library';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { Navigate } from 'react-router-dom';
import { useLingui } from '@lingui/react';

import { attendancePillAtom, drawerSearchInputAtom, parsedAndFilteredAttendancePillSelector } from 'state/drawer';
import { fetchAttendancePillAction } from 'api/actions/drawer/drawerActions';
import { TextInput, TextInputProps } from 'components/ui/TextInput';
import { Drawer } from '../Drawer';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';

import { AttendancePill } from './AttendancePill';

export const AttendanceOverview: FC = () => {
  useLingui();
  const filteredAttendancePill = useRecoilValue(parsedAndFilteredAttendancePillSelector);
  const [attendancePill, setAttendancePill] = useRecoilState(attendancePillAtom);
  const setInputSearch = useSetRecoilState(drawerSearchInputAtom);
  const resetInputSearch = useResetRecoilState(drawerSearchInputAtom);
  const {
    features: { AttendanceOverview: AttendanceOverviewFeature },
  } = useAppPermissions();

  const { payload, error, loading } = useQuery(fetchAttendancePillAction());

  const { isMobileBreakpoint } = useThemeBreakpoint();

  const setQuery = debounce((value: string) => {
    setInputSearch(value);
  }, 600);

  const handleInputChange: NonNullable<TextInputProps['onChange']> = useCallback(
    (e) => setQuery(e.target.value),
    [setQuery],
  );

  useEffect(() => {
    if (!error && payload && !isEqual(attendancePill, payload)) {
      setAttendancePill(payload);
    }
  }, [error, payload, setAttendancePill, attendancePill]);

  useEffect(() => resetInputSearch(), [resetInputSearch]);

  const overviewRenderer = useCallback(() => {
    if (filteredAttendancePill && filteredAttendancePill.working) {
      const { working, locations, late, absent, timeOff, notWorking, workPositions } = filteredAttendancePill;

      return (
        <>
          {/* change with caution, element with vertical scroll must remain in Drawer, see: scrollParentRef */}
          {/* IMPORTANT: classname is used for ONBOARDING */}
          <Flex
            sx={{ flexDirection: 'column', gap: 4 }}
            className={isMobileBreakpoint ? 'onboarding-attendanceoverview-2' : undefined}
          >
            <TextInput
              id="filter"
              size="sm"
              icon="search"
              type="text"
              onChange={handleInputChange}
              placeholder={t({
                id: 'drawer.input.filter.placeholder',
                message: 'Filter',
              })}
            />

            <Flex
              sx={{
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <AttendancePill
                variant="success"
                persons={working.people}
                hasError={working.isSomeoneWorkingDuringAbsence}
                title={t({
                  id: 'attendance_pill.working',
                  message: 'Working',
                })}
              />
              <AttendancePill
                variant="warning"
                persons={late}
                title={t({
                  id: 'attendance_pill.late',
                  message: 'Late',
                })}
              />
              <AttendancePill
                variant="danger"
                persons={absent}
                title={t({
                  id: 'attendance_pill.absent',
                  message: 'Absent',
                })}
              />
              <AttendancePill
                persons={timeOff}
                title={t({
                  id: 'attendance_pill.absence_excused',
                  message: 'Absence excused',
                })}
              />
              <AttendancePill
                persons={notWorking}
                title={t({
                  id: 'attendance_pill.not_working',
                  message: 'Not working',
                })}
              />
            </Flex>

            {locations?.length !== 0 && (
              <Flex sx={{ flexDirection: 'column', gap: 1 }}>
                <Drawer.SubTitle>
                  <Trans id="attendance_pill.locations">In locations</Trans>
                </Drawer.SubTitle>

                {locations.map((l) => (
                  <AttendancePill key={l.locationName} title={l.locationName} persons={l.people} />
                ))}
              </Flex>
            )}

            {workPositions?.length !== 0 && (
              <Flex
                sx={{
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Drawer.SubTitle>
                  <Trans id="attendance_pill.positions">Positions</Trans>
                </Drawer.SubTitle>

                {workPositions.map((wP) => (
                  <AttendancePill
                    key={wP.name}
                    title={wP.name}
                    persons={wP.people}
                    presenceCount={wP?.workingPeopleCount}
                    hasError={
                      wP?.isSomeoneWorkingDuringAbsence ||
                      (wP?.workingPeopleCount >= 0 && wP?.workingPeopleCount !== wP.people?.length)
                    }
                  />
                ))}
              </Flex>
            )}
          </Flex>
        </>
      );
    }

    return (
      <Text>
        <Trans id="attendance_overview.empty.message">Currently there is nothing to show here.</Trans>
      </Text>
    );
  }, [filteredAttendancePill, handleInputChange, isMobileBreakpoint]);

  if (!AttendanceOverviewFeature) return <Navigate to=".." />;
  return (
    <>
      <Drawer.Header>
        <Drawer.Title>
          <Trans id="drawer.header.attendance_overview">Attendance Overview</Trans>
        </Drawer.Title>
      </Drawer.Header>

      {/* change with caution, element with vertical scroll must remain in Drawer, see: scrollParentRef */}
      <Drawer.Container>
        {loading || (!filteredAttendancePill && !error) ? (
          <Flex sx={{ flex: '1 0 ', alignItems: 'center', justifyContent: 'center' }}>
            <LoadingSpinnerCss size={3} />
          </Flex>
        ) : (
          overviewRenderer()
        )}
      </Drawer.Container>
    </>
  );
};
