import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import {
  FIVE_DAY_WORKING_WEEK_ID,
  FOR_SATURDAY_FREE_DAY_ID,
  HOLIDAY_FREE_DAY_ID,
  OVERTIME_FREE_DAY_ID,
  OVER_TIME_PAYMENT_ID,
} from 'constants/requests';
import { AddRequestExtendedFormContext } from '../../../../../../../types';

export const useTimeOffFormState = () => {
  const { watch } = useFormContext<AddRequestExtendedFormContext>();

  const [typeIdWatch, actionTypeWatch, forWholeDayWatch] = watch(['details.typeId', 'actionType', 'isDateBound']);

  const isOvertimeFreeDayType = useMemo(() => {
    if (typeIdWatch === OVERTIME_FREE_DAY_ID) return true;

    return false;
  }, [typeIdWatch]);

  const isDurationType = useMemo(() => {
    if (typeIdWatch === OVER_TIME_PAYMENT_ID) return true;

    return false;
  }, [typeIdWatch]);

  const isForDayType = useMemo(() => {
    if (actionTypeWatch === RequestActionType.Remove) return false;

    if (
      typeIdWatch === HOLIDAY_FREE_DAY_ID ||
      typeIdWatch === FOR_SATURDAY_FREE_DAY_ID ||
      typeIdWatch === FIVE_DAY_WORKING_WEEK_ID
    ) {
      return true;
    }

    return false;
  }, [actionTypeWatch, typeIdWatch]);

  return {
    isOvertimeFreeDayType,
    isDurationType,
    isForDayType,
    forWholeDayWatch,
  };
};
