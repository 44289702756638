import { useRecoilValue } from 'recoil';

import { fetchTagInheritedFeaturesAction } from 'api/actions/employees/employeesActions';
import { useSilentFetch } from 'hooks/useSilentFetch/useSilentFetch';
import { defaultTagsFeaturesAtom } from 'state/team';

export const useDefaultTagsFeatures = () => {
  const defaultTagsFeatures = useRecoilValue(defaultTagsFeaturesAtom);

  const { silentFetch } = useSilentFetch(defaultTagsFeaturesAtom, fetchTagInheritedFeaturesAction({ tagsIds: [] }));

  return {
    fetchDefaultTagFeatures: silentFetch,
    defaultTagsFeatures,
  };
};
