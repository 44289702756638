import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { MessageForFetch } from 'api/actions/chat/chatActions.types';
import { PaymentEventResponse } from 'api/actions/payments/paymentsActions.types';
import { API_ENDPOINTS } from 'constants/api';
import { ACCESS_TOKEN_KEY, SIGNAL_R_API_URL } from 'constants/common';
import { addMessageSelector, ChatUserStatus, setChatUserIsOnlineSelector } from 'state/chat';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';

// FIXME: remove LogLevel on prod
// TODO: connect watch
export const SignalRProvider = () => {
  const addMessage = useSetRecoilState(addMessageSelector);
  const setIsOnline = useSetRecoilState(setChatUserIsOnlineSelector);

  useEffect(() => {
    const connect = async () => {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      if (accessToken) {
        try {
          const connection = new HubConnectionBuilder()
            .withUrl(SIGNAL_R_API_URL + API_ENDPOINTS.signalR, {
              accessTokenFactory: () => accessToken,
              transport: HttpTransportType.WebSockets,
            })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect([1000, 3000, 5000, 10000])
            .build();

          connection.on('ReceiveMessage', (m: MessageForFetch) => {
            addMessage(m);
          });

          connection.on('UserStatus', (u: ChatUserStatus) => {
            setIsOnline(u);
          });

          connection.on('RefreshPaymentData', (s: PaymentEventResponse) => {
            emitCustomEvent(CustomEvents.SIGNAL_R_GET_PAYMENTS, s);
          });

          await connection.start();
          // eslint-disable-next-line no-empty
        } catch {}
      }
    };

    void connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMessage, setIsOnline]);

  return null;
};
