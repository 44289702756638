import { Trans, t } from '@lingui/macro';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Flex } from 'theme-ui';

import { PostEmployeeAccountRegisterPayload } from 'api/actions/user/userActions.types';
import { FormCard } from 'components/FormCard/FormCard';
import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { Checkbox } from 'components/ui/Checkbox';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { PasswordInput } from 'components/ui/PasswordInput';
import { TextInput, TextInputProps } from 'components/ui/TextInput';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { mergeRefs } from 'utils/mergeRefs';

const EMPLOYEE_REGISTER_FORM_ID = 'employee-register-form-id';

type SubmitPropsType = Omit<PostEmployeeAccountRegisterPayload, 'token' | 'userId'>;

type Props = {
  onSubmit: (props: SubmitPropsType) => Promise<boolean>;
};

export const EmployeeForm = ({ onSubmit }: Props): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const confirmPasswordAfterBlurRef = useRef<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    trigger,
  } = useForm({ mode: 'onTouched', reValidateMode: 'onChange' });

  const handleSubmitCallback: SubmitHandler<Partial<SubmitPropsType>> = useCallback(
    async (props) => {
      const { newsletterSubscription, password } = props;
      if (newsletterSubscription !== undefined && password) {
        setIsLoading(true);
        const response = await onSubmit({
          newsletterSubscription,
          password,
        });

        if (!response) {
          setIsLoading(false);
        }
      }
    },
    [onSubmit],
  );

  const passwordRegister = useMemo(
    () => register('password', { required: t({ id: 'global.forms.required' }) }),
    [register],
  );
  const confirmPasswordRegister = useMemo(
    () =>
      register('confirmPassword', {
        required: t({ id: 'global.forms.required' }),
        validate: (value) =>
          value === passwordRef.current?.value || t({ id: 'settings.user.change_password.not_match' }),
      }),
    [register],
  );

  const setPasswordErrorCallback = useCallback(() => {
    setError('passwordInternalError' as 'password', {
      message: 'only visible in code',
    });
  }, [setError]);

  const clearPasswordErrorCallback = useCallback(() => {
    clearErrors('passwordInternalError' as 'password');
  }, [clearErrors]);

  const passwordOnChange: NonNullable<TextInputProps['onChange']> = useCallback(
    (e) => {
      void passwordRegister.onChange(e);
      if (passwordRef.current && passwordRef.current.value.length > 0 && confirmPasswordAfterBlurRef.current) {
        void trigger('confirmPassword');
      }
    },
    [trigger, passwordRegister],
  );

  const confirmPasswordOnBlur: NonNullable<TextInputProps['onBlur']> = useCallback(
    (e) => {
      confirmPasswordAfterBlurRef.current = true;
      void confirmPasswordRegister.onBlur(e);
    },
    [confirmPasswordRegister],
  );

  return (
    <form
      id={EMPLOYEE_REGISTER_FORM_ID}
      onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}
      noValidate
    >
      <Flex sx={{ gap: 4, flexDirection: 'column' }}>
        <PasswordInput
          id="password"
          autoComplete="new-password"
          {...passwordRegister}
          ref={mergeRefs([passwordRegister.ref, passwordRef])}
          label={t({
            id: 'settings.user.change_password.new_password',
            message: 'New password',
          })}
          placeholder="••••••"
          icon="lock"
          error={!!errors.password}
          errorMessage={errors?.password?.message}
          onValidError={setPasswordErrorCallback}
          onClearError={clearPasswordErrorCallback}
          onChange={passwordOnChange}
        />

        <TextInput
          id="confirmPassword"
          type="password"
          autoComplete="new-password"
          {...confirmPasswordRegister}
          label={t({
            id: 'settings.user.change_password.confirm_password',
            message: 'Confirm password',
          })}
          placeholder="••••••"
          icon="lock"
          error={!!errors.confirmPassword}
          errorMessage={errors?.confirmPassword?.message}
          onBlur={confirmPasswordOnBlur}
        />
      </Flex>

      <FormCard.Footer sx={{ flexDirection: 'column', gap: 1 }}>
        <Button
          isLoading={isLoading}
          variant="primary"
          size="lg"
          fullWidth
          type="submit"
          shape="rounded"
          form={EMPLOYEE_REGISTER_FORM_ID}
          apendWith={<Icon type="arrowRight" />}
        >
          {t({ id: 'global.forms.buttons.next' })}
        </Button>

        <Checkbox
          sx={{ mt: 4 }}
          {...register('newsletterSubscription')}
          label={
            <Trans id="sign_up.join_newsletter">
              I want to receive newsletters and special offer notices.{' '}
              <LinkAnchor sx={{ textDecoration: 'underline' }} href={EXTERNAL_LINKS.NEWSLETTER_TERMS} target="_blank">
                Terms of newsletter
              </LinkAnchor>
              .
            </Trans>
          }
        />
      </FormCard.Footer>
    </form>
  );
};
