import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';

import { advancedSettingsDeletePhotosAction, deleteEmployeesPhotosAction } from 'api/actions/settings/settingsActions';
import { AdvancedSettingsDeletePhotosActionProps } from 'api/actions/settings/settingsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { LinkButtonProps } from 'components/ui/Buttons';
import { TextInput } from 'components/ui/TextInput';
import { DELETE_PHOTOS_FORM_ID } from 'constants/settings';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

import { AdvancedDeleteMain } from './AdvancedDeleteMain/AdvancedDeleteMain';

type Props = {
  employeesIds?: string[];
  linkButtonWithLoadingProps?: LinkButtonProps;
  onSubmitSuccess?: () => void;
  renderer?: (formRenderer: () => JSX.Element, loading: boolean, passwordValue: string) => React.ReactElement;
};

export const AdvancedDeletePhotosForm = ({
  employeesIds,
  onSubmitSuccess,
  linkButtonWithLoadingProps,
  renderer,
}: Props): React.ReactElement => {
  useLingui();

  const { mutate, loading } = useMutation(
    employeesIds ? deleteEmployeesPhotosAction : advancedSettingsDeletePhotosAction,
  );

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AdvancedSettingsDeletePhotosActionProps>();

  const passwordValue = watch('password');

  const handleSubmitCallback = useCallback(
    async (data: AdvancedSettingsDeletePhotosActionProps) => {
      const { error } = await mutate({
        ...data,
        employeesIds,
      });

      if (!error) {
        if (onSubmitSuccess && employeesIds) onSubmitSuccess();

        addSnackbar({
          message: t({
            id: 'settings.organization.advanced.delete_photos_success',
            message: 'Deleted all photos from Clock Log',
          }),
          variant: 'success',
        });
        setValue('password', '');
      }
    },
    [mutate, employeesIds, onSubmitSuccess, setValue],
  );

  const formRenderer = () => (
    <AdvancedDeleteMain
      formId={DELETE_PHOTOS_FORM_ID}
      label={t({
        id: 'advanced_settings.heading.delete_all_photos',
        message: 'Delete all photos from Clock Log',
      })}
      additionalLabel={`${t({
        id: 'advanced_settings.heading.additional_info',
        message: 'This operation cannot be reversed!',
      })} ${t({
        id: 'advanced_settings.heading.can_take_up',
        message: 'It can take up to few minutes to complete this request.',
      })}`}
      onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}
      {...(linkButtonWithLoadingProps &&
        Object.keys(linkButtonWithLoadingProps).length > 0 && {
          linkButtonWithLoadingProps: {
            disabled: !passwordValue || loading,
            isLoading: loading,
            ...linkButtonWithLoadingProps,
          },
        })}
    >
      <TextInput
        {...register('password', {
          required: t({
            id: 'global.forms.required',
          }),
        })}
        id="deletePhotosPassword"
        placeholder={t({
          id: 'global.forms.inputs.password',
        })}
        size="sm"
        type="password"
        icon="lock"
        label={t({
          id: 'advanced_settings.type_your_password',
          message: 'Type your Password',
        })}
        error={!!errors.password}
        errorMessage={errors.password?.message}
        autoComplete="new-password"
        sx={{ maxWidth: '340px' }}
      />
    </AdvancedDeleteMain>
  );

  if (renderer) {
    return renderer(formRenderer, loading, passwordValue);
  }

  return formRenderer();
};
