import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { updateCustomRequestTypeAction } from 'api/actions/customRequest.ts/customRequestActions';
import {
  AddCustomRequestTypeActionProps,
  UpdateCustomRequestTypeActionProps,
} from 'api/actions/customRequest.ts/customRequestActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { customRequestTypesDictionarySelector } from 'state/organizationSession';
import { createEvent } from 'utils/createEvent';

import { AddEditCustomRequestForm } from './AddEditCustomRequestForm';

export const EditCustomRequestModal = (): React.ReactElement => {
  useLingui();
  const { id } = useParams() as { id: string };
  const { handleClose } = useModal();
  const { mutate } = useMutation(updateCustomRequestTypeAction);
  const customRequestTypesDictionary = useRecoilValue(customRequestTypesDictionarySelector);
  const requestType = useMemo(
    () => customRequestTypesDictionary && customRequestTypesDictionary[id],
    [customRequestTypesDictionary, id],
  );

  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: AddCustomRequestTypeActionProps | UpdateCustomRequestTypeActionProps): Promise<void> => {
      if (_.isEqual(_.omit(data, 'customRequestId'), _.omit(requestType, 'id'))) {
        addSnackbar({
          message: t({
            id: 'settings.custom_request.edit_default',
            message: 'No changes to save!',
          }),
          variant: 'default',
        });
        return;
      }

      const { error: submitError } = await mutate({ ...data, customRequestId: id });

      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
        addSnackbar({
          message: t({
            id: 'settings.custom_request.edit_success',
            message: 'Edited custom request',
          }),
          variant: 'success',
        });
      }

      setLoading(false);
    },
    [handleClose, id, mutate, requestType],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.custom_request.edit">Edit custom request type</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {requestType ? (
          <AddEditCustomRequestForm
            ref={formRef}
            onSubmit={onSubmit}
            setLoading={setLoading}
            defaultValues={{
              name: requestType.name,
              abbreviation: requestType.abbreviation,
              isLimitable: requestType.isLimitable,
              isActive: requestType.isActive,
              customRequestId: requestType.id,
            }}
          />
        ) : (
          <LoadingSpinnerCss size={4} />
        )}
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
