import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Switch } from 'components/ui/Switch';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { organizationSessionAtom } from 'state/organizationSession';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';

export const AcceptationCheckbox = React.memo((): React.ReactElement | null => {
  useLingui();
  const {
    addRequestState: { requestType },
  } = useAddRequest();
  const organizationSession = useRecoilValue(organizationSessionAtom);
  const { modulesManagement } = useAppPermissions();

  const { state } = useLocation();
  const { deleteGroupRequest } = state || {};

  const { register } = useFormContext<AddRequestExtendedFormContext>();

  const shouldDisplaySwitch = useMemo(() => {
    if (!organizationSession) return false;

    const {
      timeOffApprovalSettings,
      customRequestsApprovalSettings,
      businessTripsApprovalSettings,
      timeTrackingApprovalSettings,
    } = organizationSession;

    if (!modulesManagement.Requests) return false;

    if (requestType === RequestFormType.TimeOff && timeOffApprovalSettings.twoStepApprovalFlow) {
      return false;
    }

    if (requestType === RequestFormType.Custom && customRequestsApprovalSettings.twoStepApprovalFlow) {
      return false;
    }

    if (requestType === RequestFormType.BusinessTrip && businessTripsApprovalSettings.twoStepApprovalFlow) {
      return false;
    }

    if (requestType === RequestFormType.TimeTracking && timeTrackingApprovalSettings.twoStepApprovalFlow) {
      return false;
    }

    if (requestType === RequestFormType.Schedule) {
      return false;
    }

    return true;
  }, [modulesManagement.Requests, organizationSession, requestType]);

  if (!shouldDisplaySwitch) return null;

  return (
    <Switch
      {...register('autoAcceptRequest')}
      label={t({ id: 'add_request.auto_accept', message: 'Auto-approve this request' })}
      additionalInfo={
        deleteGroupRequest?.latestInGroup
          ? t({
              id: 'add_request.auto_accept.additional_group_deletion',
              message:
                'This request wont’t go through accept flow process and will delete the entire group of requests',
            })
          : t({
              id: 'add_request.auto_accept.additional',
              message: 'This request wont’t go through accept flow process',
            })
      }
      size="sm"
      bold
    />
  );
});
