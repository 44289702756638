import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { TimeOffType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { setTimeOffTypesStateAction } from 'api/actions/timeoff/timeOffActions';
import { SetTimeOffTypesStateActionProps, TimeOffTypeState } from 'api/actions/timeoff/timeOffActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Icon } from 'components/Icon/Icon';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { ListItem, ListNames, ListVariant, StickyListProps } from 'components/StickyList/types';
import { LinkButton } from 'components/ui/Buttons/LinkButton';
import { Checkbox } from 'components/ui/Checkbox';
import { Switch } from 'components/ui/Switch';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { manageableTimeOffTypesSelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';
import { LazyComponentType } from 'utils/custom.types';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';

const LazyStickyList = React.lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<TimeOffType>>;

export const TimeOffList = (): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const navigate = useAppNavigate();
  const manageableTimeOffTypes = useRecoilValue(manageableTimeOffTypesSelector);
  const { mutate } = useMutation(setTimeOffTypesStateAction);

  const [timeOffTypesStates, setTimeOffTypesStates] = useState<TimeOffTypeState[]>([]);
  const timeOffTypesStatesRef = useRef<TimeOffTypeState[]>([]);

  const handlePropagationOnClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  const handleDeleteOnClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
      handlePropagationOnClick(e);
      navigate(TO_REL.DELETE_TIME_OFF_TYPE_MODAL[language], { state: { ids: [id] } });
    },
    [handlePropagationOnClick, language, navigate],
  );

  const handleSwitchOnChange = useMemo(
    () =>
      _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const timeOffTypeState: TimeOffTypeState = {
          id: e.target.name,
          state: e.target.checked,
        };
        const filteredTimeOffTypesStates = timeOffTypesStatesRef.current.filter(
          ({ id: currentId }) => currentId !== timeOffTypeState.id,
        );
        const newTimeOffTypesStates = [...filteredTimeOffTypesStates, timeOffTypeState];

        setTimeOffTypesStates(newTimeOffTypesStates);
        timeOffTypesStatesRef.current = newTimeOffTypesStates;
      }, 200),
    [],
  );

  const debounceMutateTimeOffTypesStates = useMemo(
    () =>
      _.debounce(async () => {
        const mutateData: SetTimeOffTypesStateActionProps = {
          timeOffTypesStates: timeOffTypesStatesRef.current,
        };

        const { error } = await mutate(mutateData);

        if (!error) {
          addSnackbar({
            message: t({
              id: 'settings.forms.submit_success',
            }),
            variant: 'success',
          });
        }
      }, 500),
    [mutate],
  );

  useEffect(() => {
    if (timeOffTypesStates.length) {
      void debounceMutateTimeOffTypesStates();
    }
  }, [debounceMutateTimeOffTypesStates, timeOffTypesStates]);

  const columns: StickyListProps<TimeOffType>['columns'] = useMemo(
    () => [
      {
        key: ['isActive', 'isManageable'],
        title: t({ id: 'requests_settings.request_type.active', message: 'Active' }),
        width: '40px',
        customCellRenderer: (
          [isActive, isManageable]: [TimeOffType['isActive'], TimeOffType['isManageable']],
          id: TimeOffType['id'],
        ) => (
          <Flex onClick={handlePropagationOnClick}>
            <Switch
              name={id}
              defaultChecked={isActive}
              size="sm"
              onChange={handleSwitchOnChange}
              disabled={!isManageable}
            />
          </Flex>
        ),
      },
      {
        key: 'abbreviation',
        title: t({ id: 'requests_settings.request_type.abbrev', message: 'Abbrev.' }),
        width: '60px',
        sortableValue: (abbreviation: TimeOffType['abbreviation']) =>
          t({
            id: abbreviation,
          }),
        customCellRenderer: (abbreviation: TimeOffType['abbreviation']) => (
          <TextEllipsis
            title={t({
              id: abbreviation,
            })}
          >
            {t({
              id: abbreviation,
            })}
          </TextEllipsis>
        ),
      },
      {
        key: 'name',
        title: t({ id: 'global.forms.name' }),
        sortableValue: (name: TimeOffType['name']) =>
          t({
            id: name,
          }),
        customCellRenderer: (name: TimeOffType['name']) => (
          <TextEllipsis
            title={t({
              id: name,
            })}
          >
            {t({
              id: name,
            })}
          </TextEllipsis>
        ),
      },
      {
        key: 'isPaid',
        title: t({ id: 'requests_settings.request_type.paid', message: 'Paid' }),
        width: '40px',
        customCellRenderer: (isPaid: TimeOffType['isPaid']) => (
          <Checkbox name="placeholder" checked={isPaid} size="sm" disabled />
        ),
      },
      {
        key: 'isLimitable',
        title: t({ id: 'requests_settings.request_type.limitable', message: 'Limitable' }),
        width: '40px',
        customCellRenderer: (isLimitable: TimeOffType['isLimitable']) => (
          <Checkbox name="placeholder" checked={isLimitable} size="sm" disabled />
        ),
      },
      {
        key: ['id', 'isDefault'],
        width: '40px',
        customCellRenderer: ([id, isDefault]: [TimeOffType['id'], TimeOffType['isDefault']]) =>
          !isDefault ? (
            <Flex
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleDeleteOnClick(e, id)}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              <Icon type="delete" />
            </Flex>
          ) : null,
      },
    ],
    [handleDeleteOnClick, handleSwitchOnChange, handlePropagationOnClick],
  );

  const handleOnRowClick = useCallback(
    (id: ListItem['id']) => {
      navigate(`${TO_REL.EDIT_TIME_OFF_TYPE_MODAL[language]}/${id}`);
    },
    [navigate, language],
  );

  return (
    <SettingsGroup variant="noGap" sx={{ bg: 'settings.timeOffList', borderRadius: 'sm' }}>
      <SettingsGroup.Header headerWithList>
        <SettingsGroup.Title>
          <Trans id="requests_settings.texts.time_off">Time off request types</Trans>
        </SettingsGroup.Title>

        <LinkButton
          size="sm"
          shape="rounded"
          variant="primary"
          prependWith={<Icon type="plus" />}
          to={TO_REL.ADD_TIME_OFF_TYPE_MODAL[language]}
        >
          {t({ id: 'add' })}
        </LinkButton>
      </SettingsGroup.Header>

      {manageableTimeOffTypes && (
        <SettingsGroup.Body withHeaderList sx={{ minHeight: '400px' }}>
          <React.Suspense
            fallback={
              <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <LoadingSpinnerCss size={4} />
              </Flex>
            }
          >
            <LazyStickyList
              name={ListNames.REQUESTS_TIME_OFF}
              variant={ListVariant.darkerOne}
              list={manageableTimeOffTypes}
              columns={columns}
              style={{ paddingRight: '0.75rem', paddingBottom: '0.75rem' }}
              showHeader
              mobileWidth={626}
              onRowClick={handleOnRowClick}
            />
          </React.Suspense>
        </SettingsGroup.Body>
      )}
    </SettingsGroup>
  );
};

export const MemoizedTimeOffList = React.memo(TimeOffList);
