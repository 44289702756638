import { useState, useCallback } from 'react';

/**
 * @return {() => void} Returns a function that will rerender the component when called.
 */

export const useForceUpdate = (): (() => void) => {
  const [, updateState] = useState<unknown>();
  return useCallback(() => updateState({}), []);
};
