import { Trans, t } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { RequestState } from 'api/actions/requests/requestsActions.types';
import { ExtendedTimeEvent, FraudDetectionState } from 'api/actions/timeEvent/timeEventActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { WorkStatusBadge } from 'components/recipes/WorkStatusBadge';
import { Button } from 'components/ui/Buttons';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { PATH_REL, TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { ShowLocationModal } from 'pages/Locations/output/ShowLocationModal';
import { RequestDetailsModal } from 'pages/Requests/output/RequestDetailsModal';
import { RequestDetailsGroupedParam } from 'pages/Requests/output/types';
import { languageSelector, windowSizeAtom } from 'state/recoilState';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';
import { getEventFormattedDateTime } from '../../../../utils/getEventFormattedDateTime';
import { AntiSpoofingCheckModal } from '../../AntiSpoofingCheck';
import { EditEventModal } from '../../EditEvent/EditEvent';

type Props = {
  event: ExtendedTimeEvent;
};

export const AsidePhotoLogDetails = ({ event }: Props): React.ReactElement | null => {
  const language = useRecoilValue(languageSelector);
  const {
    coordinates,
    fraudDetectionState,
    id,
    isEnd,
    location,
    note,
    photos,
    photoUrl,
    request,
    requestState,
    timeRealUtc,
    timeUtc,
    typeId,
    typeName,
  } = event;

  const eventPhotosUrls = useMemo(() => photos?.map(({ url }) => url) || [photoUrl], [photos, photoUrl]);

  const { eventDate, eventTime } = getEventFormattedDateTime(timeUtc);
  const { eventTime: eventRealTime } = getEventFormattedDateTime(timeRealUtc);

  const hasPotentialFraud = useMemo(
    () => fraudDetectionState === FraudDetectionState.PotentialFraud,
    [fraudDetectionState],
  );
  const hasRequestPending = useMemo(() => requestState === RequestState.Pending, [requestState]);

  const { breakpoint } = useThemeBreakpoint();
  const windowSize = useRecoilValue(windowSizeAtom);

  const navigate = useAppNavigate();

  const handleGoToEditEventClick = useCallback(
    () =>
      navigate(`${TO_REL.EDIT_EVENT_MODAL__ID[language]}/${id}`, {
        state: { eventDetailsPhotosUrls: eventPhotosUrls },
      }),
    [navigate, language, id, eventPhotosUrls],
  );

  const hasCoordinates = !!coordinates?.latitude;

  const textLength = (() => {
    if (windowSize && windowSize?.width && windowSize.width > 1400) return 32;
    if (breakpoint === MEDIA_BREAKPOINTS.LG) return 16;
    if (breakpoint === MEDIA_BREAKPOINTS.XL) return 25;
    return 16;
  })();

  return (
    <>
      <Flex sx={{ gap: '1rem', ml: '1rem', flexDirection: 'column', width: '216px', alignItems: 'flex-start' }}>
        {hasPotentialFraud && (
          <Flex
            sx={{
              borderRadius: '0.25rem',
              border: '1px solid',
              borderColor: 'clockLog.photoLog.btn.antiSpoof.border',
              backgroundColor: 'clockLog.photoLog.btn.antiSpoof.bg',
              py: '0.25rem',
              px: '0.5rem',
              alignItems: 'center',
            }}
          >
            <Text sx={{ fontWeight: 'bold', fontSize: '14px', display: 'flex', flexWrap: 'wrap' }}>
              <Trans id="clock_log.photo_log_details.anti_spoof_check_required">Anti-spoof check required.</Trans>
            </Text>

            <Button
              onClick={() => navigate(`${TO_REL.ANTI_SPOOFING_CHECK_MODAL__ID[language]}/${id}`)}
              variant="danger"
              size="sm"
              sx={{ borderRadius: '0.25rem' }}
            >
              <Trans id="clock_log.photo_log_details.resolve">Resolve</Trans>
            </Button>
          </Flex>
        )}

        {hasRequestPending && request?.id && (
          <Flex
            sx={{
              borderRadius: '0.25rem',
              border: '1px solid',
              borderColor: 'clockLog.photoLog.btn.requestPending.border',
              backgroundColor: 'clockLog.photoLog.btn.requestPending.bg',
              py: '0.25rem',
              px: '0.5rem',
              alignItems: 'center',
            }}
          >
            <Text sx={{ fontWeight: 'bold', fontSize: '14px', display: 'flex', flexWrap: 'wrap' }}>
              <Trans id="clock_log.photo_log_details.pending_request">This event has a pending request.</Trans>
            </Text>

            <Button
              onClick={() =>
                navigate(
                  `${TO_REL.REQUEST_DETAILS__GROUP__ID[language]}/${RequestDetailsGroupedParam.Ungrouped}/${request.id}`,
                )
              }
              variant="primary"
              size="sm"
              sx={{ borderRadius: '0.25rem' }}
            >
              <Trans id="clock_log.photo_log_details.resolve">Resolve</Trans>
            </Button>
          </Flex>
        )}

        <Flex sx={{ flexDirection: 'column', gap: '0.12rem' }} className="event-details__date">
          <Text sx={{ fontWeight: 'bold' }} variant="heading4">
            <Trans id="clock_log.date_time">Date & Time</Trans>
          </Text>
          <Text>
            <span>{`${eventDate}, ${eventTime}`}</span>
            {timeRealUtc && <span>{` (${eventRealTime})`}</span>}
          </Text>
        </Flex>

        <Flex sx={{ flexDirection: 'column', gap: '0.12rem', maxWidth: '100%' }} className="event-details__badge">
          <Text sx={{ fontWeight: 'bold' }} variant="heading4">
            <Trans id="clock_log.type">Type</Trans>
          </Text>
          <WorkStatusBadge workStatus={{ id: typeId, name: typeName }} isEnd={isEnd} />
        </Flex>

        {(hasCoordinates || location?.id) && (
          <Flex sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <Text sx={{ fontWeight: 'bold' }} variant="heading4">
              <Trans id="clock_log.location">Location</Trans>
            </Text>
            <Button
              onClick={() =>
                navigate(`${TO_REL.SHOW_LOCATION_MODAL__ID[language]}/${hasCoordinates ? id : location?.id}`)
              }
              title={location?.name || t({ id: 'clock_log.photo_log_details.map', message: 'Show on map' })}
              prependWith={<Icon type="location" size={18} />}
              shape="rounded"
              variant="lightGrey"
              size="xs"
              sx={{ fontWeight: '400', fontSize: 2, height: '24px' }}
            >
              {_.truncate(location?.name, { length: textLength }) ||
                t({ id: 'clock_log.photo_log_details.map', message: 'Show on map' })}
            </Button>
          </Flex>
        )}

        {note && (
          <Flex sx={{ flexDirection: 'column', maxWidth: '100%' }}>
            <Text sx={{ fontWeight: 'bold' }} variant="heading4">
              <Trans id="clock_log.note">Note</Trans>
            </Text>
            <TextEllipsis>{note}</TextEllipsis>
          </Flex>
        )}

        {!hasPotentialFraud && !hasRequestPending && (
          <Button
            onClick={handleGoToEditEventClick}
            variant="lightGrey"
            sx={{ borderRadius: '0.5rem', marginTop: 'auto', alignSelf: 'center', mb: '1.5rem' }}
          >
            <Trans id="clock_log.photo_log_details.edit_event">Edit event</Trans>
          </Button>
        )}
      </Flex>
      <Routes>
        <Route
          path={PATH_REL.ANTI_SPOOFING_CHECK_MODAL__ID[language]}
          element={
            <Modal path={PATH_REL.ANTI_SPOOFING_CHECK_MODAL__ID[language]}>
              <AntiSpoofingCheckModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.REQUEST_DETAILS__GROUP__ID[language]}
          element={
            <Modal size="sm" fullHeight path={PATH_REL.REQUEST_DETAILS__GROUP__ID[language]}>
              <RequestDetailsModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.SHOW_LOCATION_MODAL__ID[language]}
          element={
            <Modal path={PATH_REL.SHOW_LOCATION_MODAL__ID[language]}>
              <ShowLocationModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.EDIT_EVENT_MODAL__ID[language]}
          element={
            <Modal path={PATH_REL.EDIT_EVENT_MODAL__ID[language]}>
              <EditEventModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
