import { t } from '@lingui/macro';
import { isEqual } from 'lodash';
import { FC } from 'react';
import { Flex, Text } from 'theme-ui';

import { ParsedRequest } from 'state/requests';
import { dateTime } from 'utils/dateTime';
import { useRequestDetails } from '../../../../../hooks/useRequestDetails';
import {
  newComparisonDifferenceSx,
  originalComparisonDifferenceSx,
  requestChangeTextEventSx,
  requestChangeTextTileSx,
  requestChangeWrapperSx,
} from '../styles/styles';

type Props = {
  requestDetails: ParsedRequest | null;
};

const OriginalForDay: FC<Props> = ({ requestDetails }) => (
  <Text
    sx={{
      ...requestChangeTextEventSx,
      ...(requestDetails?.newEvent &&
        !isEqual(
          requestDetails?.newEvent?.requestDateTimeDetails.forDay,
          requestDetails?.originalEvent?.requestDateTimeDetails.forDay,
        ) &&
        originalComparisonDifferenceSx),
    }}
  >
    {dateTime(requestDetails?.originalEvent?.requestDateTimeDetails.forDay, { utc: true }).format('ll')}
  </Text>
);

const NewForDay: FC<Props> = ({ requestDetails }) => (
  <Text
    sx={{
      ...requestChangeTextEventSx,
      ...(requestDetails?.originalEvent &&
        !isEqual(
          requestDetails?.newEvent?.requestDateTimeDetails.forDay,
          requestDetails?.originalEvent?.requestDateTimeDetails.forDay,
        ) &&
        newComparisonDifferenceSx),
    }}
  >
    {dateTime(requestDetails?.newEvent?.requestDateTimeDetails.forDay, { utc: true }).format('ll')}
  </Text>
);

export const RequestChangeForDay: FC = () => {
  const { parsedRequestDetails: requestDetails } = useRequestDetails();

  if (
    !requestDetails?.newEvent?.requestDateTimeDetails.forDay &&
    !requestDetails?.originalEvent?.requestDateTimeDetails.forDay
  )
    return null;

  return (
    <Flex sx={requestChangeWrapperSx}>
      <Text sx={requestChangeTextTileSx}>{t({ id: 'requests.for_day.title' })}</Text>
      <OriginalForDay requestDetails={requestDetails} />
      <NewForDay requestDetails={requestDetails} />
    </Flex>
  );
};
