/** @jsxImportSource theme-ui */
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { ExtendedTimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { Textarea } from 'components/ui/Textarea';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { addNoteDisabledSaveAtom } from 'state/clockLog';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { EventDetails } from '../../components/EventDetails';
import { useAddEventNote } from '../hooks/useAddEventNote';

type Props = {
  extTimeEvent: ExtendedTimeEvent;
};

export const ADD_EVENT_NOTE_FORM_ID = 'addEventNote';

export const AddEventNoteBody = ({ extTimeEvent }: Props): React.ReactElement => {
  useLingui();

  const { register, handleSubmit, control, watch } = useForm({ defaultValues: { note: '' } });

  const { addEventNote, addEventNoteError } = useAddEventNote({ prevTimeEvent: extTimeEvent });

  const noteWatch = watch('note');
  const setIsDisabledSave = useSetRecoilState(addNoteDisabledSaveAtom);

  useEffect(() => setIsDisabledSave(!noteWatch?.length), [noteWatch?.length, setIsDisabledSave]);

  return (
    <Modal.Body sx={{ p: '0 24px 24px' }}>
      <Flex sx={{ justifyContent: 'space-between', flexDirection: 'column', gap: '1.5rem' }}>
        <EventDetails extTimeEvent={extTimeEvent} />

        <form
          id={ADD_EVENT_NOTE_FORM_ID}
          onSubmit={floatingPromiseReturn(handleSubmit(addEventNote, addEventNoteError))}
          noValidate
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Textarea
            placeholder={t({ id: 'clock_log.enter_note', message: 'Enter your note...' })}
            maxLength={VALIDATION_RULES.TIME_EVENT_NOTE.maxLength}
            size="sm"
            id="note"
            variant="rounded"
            inputProps={{ sx: { height: '90px', width: '252px !important' } }}
            {...register('note', validationFactory({ required: true }))}
            counterProps={{
              control,
              name: 'note',
            }}
          />
        </form>
      </Flex>
    </Modal.Body>
  );
};
