/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxImportSource theme-ui */

import React, { CSSProperties, useMemo } from 'react';
import { Text, ThemeUIStyleObject } from 'theme-ui';

import { Icons } from 'components/Icon/Icon.types';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';

import { ReactComponent as AccountIcon } from './svg/account.svg';
import { ReactComponent as AIRejected } from './svg/ai-rejected.svg';
import { ReactComponent as AIVerifiedGradient } from './svg/ai-verified-gradient.svg';
import { ReactComponent as AIVerified } from './svg/ai-verified.svg';
import { ReactComponent as AIWarning } from './svg/ai-warning.svg';
import { ReactComponent as AndroidIcon } from './svg/android.svg';
import { ReactComponent as AppleIcon } from './svg/apple.svg';
import { ReactComponent as ApproveIcon } from './svg/approve.svg';
import { ReactComponent as ArrowDownIcon } from './svg/arrow-down.svg';
import { ReactComponent as ArrowLeftIcon } from './svg/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from './svg/arrow-right.svg';
import { ReactComponent as ArrowUpIcon } from './svg/arrow-up.svg';
import { ReactComponent as AtlassianIcon } from './svg/atlassian.svg';
import { ReactComponent as AttendanceIcon } from './svg/attendance.svg';
import { ReactComponent as BlankOpenIcon } from './svg/blankOpen.svg';
import { ReactComponent as Break } from './svg/break_square_double_color.svg';
import { ReactComponent as BuildingBig } from './svg/building-big.svg';
import { ReactComponent as BuildingMedium } from './svg/building-medium.svg';
import { ReactComponent as BuildingSmall } from './svg/building-small.svg';
import { ReactComponent as BusinessTrip } from './svg/businessTrip.svg';
import { ReactComponent as CalendarIcon } from './svg/calendar.svg';
import { ReactComponent as CardIcon } from './svg/card.svg';
import { ReactComponent as ChatIcon } from './svg/chat.svg';
import { ReactComponent as ChevronDownIcon } from './svg/chevron-down.svg';
import { ReactComponent as ChevronLeftIcon } from './svg/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from './svg/chevron-right.svg';
import { ReactComponent as ChevronUpIcon } from './svg/chevron-up.svg';
import { ReactComponent as CloseCircleIcon } from './svg/close-circle.svg';
import { ReactComponent as CloudOffIcon } from './svg/cloud-off.svg';
import { ReactComponent as CloudIcon } from './svg/cloud.svg';
import { ReactComponent as CoffeeIcon } from './svg/coffee.svg';
import { ReactComponent as CollapseIcon } from './svg/collapse.svg';
import { ReactComponent as CopyIcon } from './svg/copy.svg';
import { ReactComponent as CustomRequest } from './svg/customRequest.svg';
import { ReactComponent as DeleteIcon } from './svg/delete.svg';
import { ReactComponent as DenyIcon } from './svg/deny.svg';
import { ReactComponent as DownloadIcon } from './svg/download.svg';
import { ReactComponent as EditIcon } from './svg/edit.svg';
import { ReactComponent as ExpandIcon } from './svg/expand.svg';
import { ReactComponent as ExportIcon } from './svg/export.svg';
import { ReactComponent as EyeOffIcon } from './svg/eye-off.svg';
import { ReactComponent as EyeIcon } from './svg/eye.svg';
import { ReactComponent as FilterIcon } from './svg/filter.svg';
import { ReactComponent as GoogleIcon } from './svg/google.svg';
import { ReactComponent as HeartIcon } from './svg/heart.svg';
import { ReactComponent as HeartOutlineIcon } from './svg/heartOutline.svg';
import { ReactComponent as HelpOnboardingIcon } from './svg/help-onboarding.svg';
import { ReactComponent as HelpIcon } from './svg/help.svg';
import { ReactComponent as HomeIcon } from './svg/home.svg';
import { ReactComponent as ImportIcon } from './svg/import.svg';
import { ReactComponent as InfoIcon } from './svg/info.svg';
import { ReactComponent as IntegrationsIcon } from './svg/integrations.svg';
import { ReactComponent as InvitationPendingIcon } from './svg/invitation-pending.svg';
import { ReactComponent as Kiosk } from './svg/kiosk.svg';
import { ReactComponent as LightningFull } from './svg/lightning-full.svg';
import { ReactComponent as Lightning } from './svg/lightning.svg';
import { ReactComponent as LocationOffIcon } from './svg/location--off.svg';
import { ReactComponent as LocationIcon } from './svg/location.svg';
import { ReactComponent as LockIcon } from './svg/lock.svg';
import { ReactComponent as MailIcon } from './svg/mail.svg';
import { ReactComponent as MapIcon } from './svg/map.svg';
import { ReactComponent as MenuIcon } from './svg/menu.svg';
import { ReactComponent as Minimize } from './svg/minimize.svg';
import { ReactComponent as MinusIcon } from './svg/minus.svg';
import { ReactComponent as MobileApp } from './svg/mobile_app.svg';
import { ReactComponent as MoreIcon } from './svg/more.svg';
import { ReactComponent as NarrowIcon } from './svg/narrow.svg';
import { ReactComponent as NightModeFullIcon } from './svg/night-mode--full.svg';
import { ReactComponent as NightModeIcon } from './svg/night-mode.svg';
import { ReactComponent as NotInvitedIcon } from './svg/not-invited.svg';
import { ReactComponent as NoteIcon } from './svg/note.svg';
import { ReactComponent as NotificationsIcon } from './svg/notifications.svg';
import { ReactComponent as Pending } from './svg/pending.svg';
import { ReactComponent as PlayCircleDoubleColorIcon } from './svg/play-circle-double-color.svg';
import { ReactComponent as PlayCircleIcon } from './svg/play-circle.svg';
import { ReactComponent as PlayOutline } from './svg/play-outline.svg';
import { ReactComponent as PlayIcon } from './svg/play.svg';
import { ReactComponent as PlusIcon } from './svg/plus.svg';
import { ReactComponent as PreferencesIcon } from './svg/preferences.svg';
import { ReactComponent as PresentIcon } from './svg/present.svg';
import { ReactComponent as PrintIcon } from './svg/print.svg';
import { ReactComponent as QrCodeLandscapeIcon } from './svg/qr-code-lanscape.svg';
import { ReactComponent as QuotationMarkIcon } from './svg/quotation-mark.svg';
import { ReactComponent as ReportsIcon } from './svg/reports.svg';
import { ReactComponent as RequestDelete } from './svg/request-delete.svg';
import { ReactComponent as RequestEdit } from './svg/request-edit.svg';
import { ReactComponent as RequestsIcon } from './svg/requests.svg';
import { ReactComponent as RocketIcon } from './svg/rocket.svg';
import { ReactComponent as SearchIcon } from './svg/search.svg';
import { ReactComponent as SelectedIcon } from './svg/selected.svg';
import { ReactComponent as SendIcon } from './svg/send.svg';
import { ReactComponent as SettingsIcon } from './svg/settings.svg';
import { ReactComponent as ShieldOpaque } from './svg/shield-opaque.svg';
import { ReactComponent as SignOutIcon } from './svg/sign-out.svg';
import { ReactComponent as SlackIcon } from './svg/slack.svg';
import { ReactComponent as SparksFull } from './svg/sparks-full.svg';
import { ReactComponent as Sparks } from './svg/sparks.svg';
import { ReactComponent as StarIcon } from './svg/star.svg';
import { ReactComponent as StopCircleDoubleColorIcon } from './svg/stop-circle-double-color.svg';
import { ReactComponent as StopCircleIcon } from './svg/stop-circle.svg';
import { ReactComponent as StopOutline } from './svg/stop-outline.svg';
import { ReactComponent as StopIcon } from './svg/stop.svg';
import { ReactComponent as SupportIcon } from './svg/support.svg';
import { ReactComponent as SwissKnifeIcon } from './svg/swissKnife.svg';
import { ReactComponent as System } from './svg/system.svg';
import { ReactComponent as Task } from './svg/task_square_double_color.svg';
import { ReactComponent as TeamIcon } from './svg/team.svg';
import { ReactComponent as TimeClockIcon } from './svg/time-clock.svg';
import { ReactComponent as TimeOff } from './svg/timeOff.svg';
import { ReactComponent as TimeClockMobile } from './svg/time_clock_mobile.svg';
import { ReactComponent as TimerIcon } from './svg/timer.svg';
import { ReactComponent as UnLockIcon } from './svg/un-lock.svg';
import { ReactComponent as Undo } from './svg/undo.svg';
import { ReactComponent as UserIcon } from './svg/user.svg';
import { ReactComponent as WandIcon } from './svg/wand.svg';
import { ReactComponent as WarningIcon } from './svg/warning.svg';
import { ReactComponent as WebApp } from './svg/webapp.svg';
import { ReactComponent as WorkStatus } from './svg/work_status_square_double_color.svg';
import { ReactComponent as XIcon } from './svg/x.svg';
import { ReactComponent as Zus } from './svg/zus-logo.svg';

const TextWithTooltip = withTooltip(Text);

const iconMap: { [index in Icons]: React.ElementType } = {
  aiWarning: AIWarning,
  aiVerifiedGradient: AIVerifiedGradient,
  system: System,
  kiosk: Kiosk,
  webApp: WebApp,
  attendance: AttendanceIcon,
  account: AccountIcon,
  card: CardIcon,
  notifications: NotificationsIcon,
  preferences: PreferencesIcon,
  signOut: SignOutIcon,
  star: StarIcon,
  mail: MailIcon,
  lock: LockIcon,
  closeCircle: CloseCircleIcon,
  eye: EyeIcon,
  eyeOff: EyeOffIcon,
  x: XIcon,
  approve: ApproveIcon,
  deny: DenyIcon,
  info: InfoIcon,
  heart: HeartIcon,
  help: HelpIcon,
  settings: SettingsIcon,
  nightMode: NightModeIcon,
  nightModeFull: NightModeFullIcon,
  location: LocationIcon,
  locationOff: LocationOffIcon,
  google: GoogleIcon,
  apple: AppleIcon,
  arrowRight: ArrowRightIcon,
  arrowLeft: ArrowLeftIcon,
  arrowUp: ArrowUpIcon,
  arrowDown: ArrowDownIcon,
  home: HomeIcon,
  team: TeamIcon,
  timeClock: TimeClockIcon,
  calendar: CalendarIcon,
  selected: SelectedIcon,
  chevronUp: ChevronUpIcon,
  chevronRight: ChevronRightIcon,
  chevronDown: ChevronDownIcon,
  chevronLeft: ChevronLeftIcon,
  quotationMark: QuotationMarkIcon,
  stop: StopIcon,
  play: PlayIcon,
  reports: ReportsIcon,
  requests: RequestsIcon,
  user: UserIcon,
  menu: MenuIcon,
  integrations: IntegrationsIcon,
  import: ImportIcon,
  chat: ChatIcon,
  expand: ExpandIcon,
  narrow: NarrowIcon,
  send: SendIcon,
  support: SupportIcon,
  more: MoreIcon,
  plus: PlusIcon,
  search: SearchIcon,
  download: DownloadIcon,
  print: PrintIcon,
  delete: DeleteIcon,
  rocket: RocketIcon,
  edit: EditIcon,
  copy: CopyIcon,
  export: ExportIcon,
  unLock: UnLockIcon,
  filter: FilterIcon,
  map: MapIcon,
  playCircle: PlayCircleIcon,
  stopCircle: StopCircleIcon,
  blankOpen: BlankOpenIcon,
  swissKnife: SwissKnifeIcon,
  heartOutline: HeartOutlineIcon,
  timer: TimerIcon,
  cloud: CloudIcon,
  minus: MinusIcon,
  warning: WarningIcon,
  note: NoteIcon,
  stopCircleDoubleColor: StopCircleDoubleColorIcon,
  playCircleDoubleColor: PlayCircleDoubleColorIcon,
  android: AndroidIcon,
  collapse: CollapseIcon,
  wand: WandIcon,
  coffee: CoffeeIcon,
  qrCodeLandscape: QrCodeLandscapeIcon,
  invitationPending: InvitationPendingIcon,
  notInvited: NotInvitedIcon,
  requestEdit: RequestEdit,
  requestDelete: RequestDelete,
  pending: Pending,
  businessTrip: BusinessTrip,
  customRequest: CustomRequest,
  timeOff: TimeOff,
  undo: Undo,
  aiVerified: AIVerified,
  aiRejected: AIRejected,
  minimize: Minimize,
  buildingBig: BuildingBig,
  buildingMedium: BuildingMedium,
  buildingSmall: BuildingSmall,
  lightning: Lightning,
  lightningFull: LightningFull,
  zus: Zus,
  shieldOpaque: ShieldOpaque,
  sparksFull: SparksFull,
  sparks: Sparks,
  break: Break,
  workStatus: WorkStatus,
  task: Task,
  playOutline: PlayOutline,
  stopOutline: StopOutline,
  timeClockMobile: TimeClockMobile,
  mobileApp: MobileApp,
  cloudOff: CloudOffIcon,
  present: PresentIcon,
  helpOnboarding: HelpOnboardingIcon,
  atlassian: AtlassianIcon,
  slack: SlackIcon,
};

export type IconProps = {
  fill?: CSSProperties['fill'] | CSSProperties['fill'][];
  size?: number;
  lineHeight?: number;
  type: Icons;
  wrapperSx?: ThemeUIStyleObject;
  iconSx?: ThemeUIStyleObject;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  tooltip?: React.ReactNode;
  className?: string;
  tabIndex?: number;
};

const defaultProps = {
  fill: undefined,
  size: undefined,
  lineHeight: 1.5,
  wrapperSx: undefined,
  iconSx: undefined,
  onClick: undefined,
  tooltip: undefined,
  className: undefined,
};

export const Icon = ({
  size = defaultProps.size,
  lineHeight = defaultProps.lineHeight,
  type,
  iconSx,
  wrapperSx,
  onClick,
  fill,
  tooltip,
  className,
  ...props
}: IconProps): React.ReactElement => {
  const IconWrap = type ? iconMap[type] : 'span';

  const preparedSize = useMemo(() => size || `${lineHeight}em`, [lineHeight, size]);

  const onClickProps = onClick && {
    onClick,
    role: 'button',
    tabIndex: -1,
  };

  const renderIcon = () => (
    <IconWrap
      width={preparedSize}
      height={preparedSize}
      viewBox="0 0 32 32"
      fill="currentColor"
      sx={{
        color: fill || 'currentColor',
        ...(iconSx && iconSx),
      }}
    />
  );

  return (
    <span
      {...onClickProps}
      sx={{ display: 'flex', alignItems: 'center', ...wrapperSx }}
      className={className}
      {...props}
    >
      {!tooltip ? (
        renderIcon()
      ) : (
        <TextWithTooltip sx={{ display: 'inherit' }} tooltipProps={{ content: tooltip }}>
          {renderIcon()}
        </TextWithTooltip>
      )}
    </span>
  );
};

Icon.defaultProps = defaultProps;
