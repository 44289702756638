import clone from 'lodash/clone';
import omit from 'lodash/omit';
import { useEffect, useRef } from 'react';
import { useQuery } from 'react-fetching-library';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { v1 as uuidv1 } from 'uuid';

import { fetchEmployeeDetailsAction } from 'api/actions/employees/employeesActions';
import { FetchEmployeeDetailsResponse } from 'api/actions/employees/employeesActions.types';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import {
  editedEmployeeDetailsAtom,
  employeeAdvancedDetailsFormStateSelector,
  employeeEmploymentDetailsFormStateSelector,
  employeeRequestLimitsFormStateSelector,
  privateNotesFormStateSelector,
  profileFormStateSelector,
} from 'state/team';

type OnError = () => void;

export const useEmployeeDetails = (id: string, onError: OnError) => {
  const [editedEmployeeDetails, setEditedEmployeeDetails] = useRecoilState(editedEmployeeDetailsAtom);
  const [profileFormState, setProfileFormState] = useRecoilState(profileFormStateSelector);
  const [advancedDetailsFormState, setAdvancedDetailsFormState] = useRecoilState(
    employeeAdvancedDetailsFormStateSelector,
  );
  const [employmentDetailsFormState, setEmploymentDetailsFormState] = useRecoilState(
    employeeEmploymentDetailsFormStateSelector,
  );
  const [requestLimitsFormState, setRequestLimitsFormState] = useRecoilState(employeeRequestLimitsFormStateSelector);
  const [privateNotesFormState, setPrivateNotesFormState] = useRecoilState(privateNotesFormStateSelector);

  const fetchEmployeeDetailsResponseRef = useRef<FetchEmployeeDetailsResponse | null>(null);

  const resetEditedEmployeeDetails = useResetRecoilState(editedEmployeeDetailsAtom);

  const { payload, error } = useQuery(fetchEmployeeDetailsAction({ employeeId: id }));
  const initialRequestLimitsFormStateRef = useRef(requestLimitsFormState);
  const initialEmploymentDetailsFormStateRef = useRef(employmentDetailsFormState);

  useEffect(() => {
    if (!initialRequestLimitsFormStateRef.current && requestLimitsFormState) {
      initialRequestLimitsFormStateRef.current = requestLimitsFormState;
    }

    if (!initialEmploymentDetailsFormStateRef.current && employmentDetailsFormState) {
      initialEmploymentDetailsFormStateRef.current = employmentDetailsFormState;
    }
  }, [editedEmployeeDetails, employmentDetailsFormState, requestLimitsFormState]);

  const onErrorRef = useCallbackRef(onError);

  useEffect(() => {
    if (error && onErrorRef.current) {
      onErrorRef.current();
    }
    if (!error && payload) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const parsedResponse = responseParser(payload);
      setEditedEmployeeDetails(parsedResponse);
      fetchEmployeeDetailsResponseRef.current = clone(payload);
    }
  }, [error, payload, setEditedEmployeeDetails, onErrorRef]);

  useUnmount(resetEditedEmployeeDetails);

  return {
    fetchEmployeeDetailsResponseRef,
    /**
     * Edited details to submit
     */
    editedEmployeeDetails,
    // initial states
    initialRequestLimitsFormStateRef,
    initialEmploymentDetailsFormStateRef,
    // Tab form states:
    profileFormState,
    setProfileFormState,
    advancedDetailsFormState,
    setAdvancedDetailsFormState,
    employmentDetailsFormState,
    setEmploymentDetailsFormState,
    requestLimitsFormState,
    setRequestLimitsFormState,
    privateNotesFormState,
    setPrivateNotesFormState,
  };
};

const responseParser = (employeeDetails: FetchEmployeeDetailsResponse) => ({
  ...omit(employeeDetails, 'defaultWorkPositionId', 'timeOffLimits', 'customRequestsLimits'),
  // Add ids (missing in the fetchEmployeeDetails response)
  ...(employeeDetails.timeOffLimits
    ? { timeOffLimits: employeeDetails.timeOffLimits.map((limit) => ({ ...limit, id: uuidv1() })) }
    : {}),
  ...(employeeDetails.customRequestsLimits
    ? { customRequestsLimits: employeeDetails.customRequestsLimits.map((limit) => ({ ...limit, id: uuidv1() })) }
    : {}),
  defaultWorkPosition: employeeDetails.defaultWorkPositionId,
});
