import { AddRequestActionProps } from 'api/actions/requests/requestsActions.types';

export type AddRequestFormState = AddRequestActionProps & {
  isDateBound: boolean;
};

export type AddRequestExtendedFormContext = AddRequestFormState & {
  calendarInternalError: boolean; // only for typing purposes
};

export enum RequestDetailsGroupedParam {
  Grouped = 0,
  Ungrouped = 1,
}
