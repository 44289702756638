import { t } from '@lingui/macro';
import React, { useCallback } from 'react';

import { RequestTypeSelectOption } from 'state/selectOptions';
import { OptionProps } from '../elements';
import { Select, SelectProps } from '../Select';

import { abbreviationRectRenderer, ContentWrapper } from './elements';

type RequestTypeSelectProps = SelectProps<RequestTypeSelectOption>;

export const RequestTypeSelect = React.forwardRef<HTMLInputElement, RequestTypeSelectProps>(
  ({ ...props }: RequestTypeSelectProps, ref): React.ReactElement => {
    const optionContentRenderer = useCallback(
      ({ label, abbreviation }: RequestTypeSelectOption, active?: boolean) => (
        <ContentWrapper>
          {abbreviationRectRenderer(abbreviation, active)}
          {label}
        </ContentWrapper>
      ),
      [],
    );

    const optionPropsGenerator = useCallback(
      (option: RequestTypeSelectOption, active?: boolean): Partial<OptionProps> => ({
        contentRenderer: () => optionContentRenderer(option, active),
      }),
      [optionContentRenderer],
    );

    return (
      <Select
        ref={ref}
        placeholder={t({ id: 'requests.custom.select_type', message: 'Select type...' })}
        {...props}
        customValueDisplayRenderer={optionContentRenderer}
        optionPropsGenerator={optionPropsGenerator}
      />
    );
  },
);
