import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { importPreviewHolidaysAction } from 'api/actions/holidays/holidaysActions';
import {
  ImportHoliday,
  ImportHolidayPreview,
  ImportHolidaysProps,
  ImportPreviewHolidaysResponse,
} from 'api/actions/holidays/holidaysActions.types';
import { Holiday } from 'api/actions/settings/settingsActions.types';
import { Languages } from 'api/actions/userSession/userSessionActions.types';
import { StickyList } from 'components/StickyList/StickyList';
import { ListNames, ListVariant, StickyListProps } from 'components/StickyList/types';
import { CountrySelect } from 'components/ui/Select/variants/CountrySelect';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { useRequestsLogic } from 'pages/Requests/output/useRequestsLogic';
import { selectAllAtomFamily, selectedRowsAtomFamily } from 'state/list';
import { languageSelector } from 'state/recoilState';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { setNativeValue } from 'utils/setNativeValue';
import { useOrganizationSettings } from '../../../../hooks/useOrganizationSettings';

type Props = {
  onSubmit: (data: ImportHolidaysProps) => Promise<void>;
  setLoading: (loading: boolean) => void;
  selectedHolidays: string[];
};

export const ImportHolidaysForm = React.forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, setLoading, selectedHolidays }: Props, ref): React.ReactElement => {
    useLingui();
    const language = useRecoilValue(languageSelector);
    const resetSelectedHolidays = useResetRecoilState(selectedRowsAtomFamily(ListNames.SETTINGS_IMPORT_HOLIDAYS));
    const resetAllSelectedHolidays = useResetRecoilState(selectAllAtomFamily(ListNames.SETTINGS_IMPORT_HOLIDAYS));
    const { year } = useParams() as { year: string };
    const { mutate: holidayImportPreviewMutate } = useMutation(importPreviewHolidaysAction);
    const { handleSubmit } = useForm();
    const { organizationSettings } = useOrganizationSettings();
    const {
      displayer: { displayDate },
    } = useRequestsLogic();

    const [importedHolidays, setImportedHolidays] = useState<ImportPreviewHolidaysResponse | null>(null);
    const [pickedLocation, setPickedLocation] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const languageRef = useRef<Languages>(Languages[language]);
    const selectedLocationRef = useRef<HTMLInputElement | null>(null);

    const columns: StickyListProps<ImportHolidayPreview>['columns'] = useMemo(
      () => [
        {
          key: 'dateUnix',
          title: t({ id: 'global.date' }),
          sortableValue: (date: Holiday['dateUnix']) => `${date}`,
          customCellRenderer: (dateUnix: Holiday['dateUnix']) => {
            const date = displayDate({ dateUnix, isDateBound: true });

            return <TextEllipsis title={date}>{date}</TextEllipsis>;
          },
          columnGrow: 1,
        },
        {
          key: 'name',
          title: t({ id: 'global.forms.name' }),
          sortableValue: (name: Holiday['name']) => `${name}`,
          customCellRenderer: (name: Holiday['name']) => <TextEllipsis title={name}>{name}</TextEllipsis>,
          columnGrow: 2,
        },
      ],
      [displayDate],
    );

    const handleSubmitCallback = useCallback(() => {
      if (!importedHolidays) return;

      const parsedImportedHolidays: ImportHoliday[] = _.transform(
        importedHolidays,
        (result, { id, ...rest }) => {
          if (_.includes(selectedHolidays, id)) {
            result.push({ holidayId: id, ...rest });
          }
        },
        [] as ImportHoliday[],
      );

      void onSubmit(parsedImportedHolidays);
    }, [importedHolidays, onSubmit, selectedHolidays]);

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    const handleOnBlur = () => {
      if (selectedLocationRef.current && selectedLocationRef.current.value) {
        setPickedLocation(selectedLocationRef.current.value);
        resetSelectedHolidays();
        resetAllSelectedHolidays();
      }
    };

    const handleDataImport = useCallback(async () => {
      if (!pickedLocation) return;

      setIsLoading(true);
      const { error, payload } = await holidayImportPreviewMutate({
        year: +year,
        language: languageRef.current,
        calendarCountryCode: pickedLocation,
      });

      if (!error && payload) setImportedHolidays(payload);

      setIsLoading(false);
    }, [holidayImportPreviewMutate, pickedLocation, year]);

    useEffect(() => {
      void handleDataImport();
    }, [handleDataImport]);

    useEffect(() => {
      resetSelectedHolidays();
      resetAllSelectedHolidays();
    }, [resetAllSelectedHolidays, resetSelectedHolidays]);

    useEffect(() => {
      if (organizationSettings) {
        setPickedLocation(organizationSettings.countryCode);
        setNativeValue(selectedLocationRef, organizationSettings.countryCode);
      }
    }, [organizationSettings]);

    return (
      <form ref={ref} onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))}>
        <CountrySelect
          ref={selectedLocationRef}
          id="holidays"
          listVariant="holidayImport"
          label={t({ id: 'settings.holidays.holidays_for', message: 'Holidays for' })}
          placeholder={t({ id: 'settings.holidays.holidays_for' })}
          size="sm"
          onBlur={handleOnBlur}
          searchable
        />
        <Flex sx={{ minHeight: '300px', mt: 2 }}>
          <StickyList
            name={ListNames.SETTINGS_IMPORT_HOLIDAYS}
            emptyListMessage={isLoading && t({ id: 'global.loading' })}
            columns={columns}
            list={importedHolidays || []}
            select="checkbox"
            showHeader
            variant={ListVariant.inverted}
          />
        </Flex>
      </form>
    );
  },
);
