import { useRecoilValue } from 'recoil';

import { geoLocationPositionAtom, GeoLocationProviderState } from 'Kiosk/state/geoLocationState';
import { getGeoLocation } from 'utils/getGeoLocation';

type ReturnValue = GeoLocationProviderState & {
  location?: GeolocationPosition;
};

export const useGeoLocation = (): ReturnValue => {
  const location = useRecoilValue(geoLocationPositionAtom);

  return {
    location,
    getGeoLocation,
  };
};
