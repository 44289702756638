import { FC } from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { RequestsHistorySelect } from '../../RequestsHistorySelect';

import { Calendar } from './Calendar';
import { EditionObserver } from './EditionObserver';
import { EventNote } from './EventNote';
import { ResetFormObserver } from './ResetFormObserver';
import { SaveStateObserver } from './SaveStateObserver';
import { WorkStatus } from './WorkStatus';

const RequestsHistorySelectWrapper: FC = () => <RequestsHistorySelect type={RequestFormType.TimeTracking} />;

type Props = FlexProps;

type TimeTrackingContainerProps = Props;

type TimeTrackingContainerComponent = FC<TimeTrackingContainerProps> & {
  WorkStatus: typeof WorkStatus;
  Calendar: typeof Calendar;
  EventNote: typeof EventNote;
  EditionObserver: typeof EditionObserver;
  SaveStateObserver: typeof SaveStateObserver;
  ResetFormObserver: typeof ResetFormObserver;
  RequestsHistorySelect: typeof RequestsHistorySelectWrapper;
};

export const TimeTrackingContainer: TimeTrackingContainerComponent = ({ children }: Props) => (
  <Flex sx={{ flexDirection: 'column', gap: 4, position: 'relative' }}>{children}</Flex>
);

TimeTrackingContainer.WorkStatus = WorkStatus;
TimeTrackingContainer.Calendar = Calendar;
TimeTrackingContainer.EventNote = EventNote;
TimeTrackingContainer.EditionObserver = EditionObserver;
TimeTrackingContainer.SaveStateObserver = SaveStateObserver;
TimeTrackingContainer.ResetFormObserver = ResetFormObserver;
TimeTrackingContainer.RequestsHistorySelect = RequestsHistorySelectWrapper;
