import { useRecoilValue } from 'recoil';

import { idleStateAtom } from 'Kiosk/state/idleState';

type ReturnValue = {
  status: boolean;
  start?: () => void;
  stop?: () => void;
  reset?: () => void;
  pause?: () => void;
};

export const useIdle = (): ReturnValue => {
  const { status, pause, reset, start, stop } = useRecoilValue(idleStateAtom);

  return { pause, reset, stop, start, status };
};
