import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';

import { HeadingTip } from '../HeadingTip';
import { SettingsLoadingSpinnerCss } from '../SettingsLoadingSpinnerCss';

import { RequestModalRoutes } from './components/RequestModalRoutes/RequestModalRoutes';
import { RequestsForms } from './components/RequestsForms/RequestsForms';
import { useRequestsSettings } from './hooks/useRequestsSettings';
import { useRequestsSettingsCleanUp } from './hooks/useRequestsSettingsCleanUp';

export const Requests = (): React.ReactElement => {
  useLingui();
  const { requestsSettings, fetchRequestsSettings } = useRequestsSettings();

  useEffect(() => {
    if (!requestsSettings) {
      void fetchRequestsSettings();
    }
  }, [fetchRequestsSettings, requestsSettings]);

  useRequestsSettingsCleanUp();

  return (
    <>
      <HeadingTip
        label={t({
          id: 'requests_settings.heading.requests',
          message: 'Requests',
        })}
      />
      {requestsSettings ? <RequestsForms /> : <SettingsLoadingSpinnerCss />}
      <RequestModalRoutes />
    </>
  );
};
