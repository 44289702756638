import { SnackbarProps } from 'components/Snackbar/types';

import { snackbarPublisher } from './publisher';

const DEFAULT_SNACKBAR_DURATION = 3000;

export function addSnackbar(snackbar: SnackbarProps): void {
  const defaultSnackbarProps = {
    duration: DEFAULT_SNACKBAR_DURATION,
    isStatic: false,
    isUnreplacable: false,
  };

  snackbarPublisher.addSnackbar({
    ...defaultSnackbarProps,
    ...snackbar,
  });
}

export function removeSnackbar(): void {
  if (snackbarPublisher.removeSnackbar) {
    snackbarPublisher.removeSnackbar();
  }
}

export function removeAllSnackbars(): void {
  if (snackbarPublisher.removeAllSnackbars) {
    snackbarPublisher.removeAllSnackbars();
  } else {
    throw new Error('removeAllSnackbars must be used within SnackbarProvider');
  }
}
