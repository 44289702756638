import React from 'react';
import { RecoilState } from 'recoil';
import { FlexProps } from 'theme-ui';

import { DropdownProps } from 'components/Dropdown/Dropdown';
import { Icons } from 'components/Icon/Icon.types';
import { LinkButtonProps } from 'components/ui/Buttons';
import { DateRangeFilterAtom, FilterGroupState, SEARCH_FILTER_TYPE } from 'state/filters';

export type DateRangeFilterProps = {
  name?: FilterGroupNames;
  filters?: DateRangeFilters[];
  dateRangeAtom?: RecoilState<DateRangeFilterAtom | null>;
};

export type FilterGroupProps = Pick<FlexProps, 'sx'> & {
  name: FilterGroupNames;
  title: string;
  filters?: PassedFilter[];
  dropdownProps?: DropdownProps;
  additionalLinks?: LinkButtonProps[];
  searchFilterType?: SEARCH_FILTER_TYPE;
  hideOptionAll?: boolean;
  openByDefault?: boolean;
  hideResetButton?: boolean;
  hideActiveFiltersBadge?: boolean;
  customContent?: React.ReactElement;
  selectType?: 'multi' | 'single';
  defaultFilterState?: FilterGroupState;
};

export type PassedFilter = Omit<FilterProps, 'groupName'> & { isUnfiltrable?: boolean; withDivider?: boolean };

export type FilterProps = Pick<FlexProps, 'sx'> & {
  groupName: FilterGroupProps['name'];
  id: string | TeammatesFilters | TagsFilters | WorkStatusesFilters | DateRangeFilters | null;
  label: string;
  dropdownProps?: DropdownProps;
  color?: string;
  isInformational?: boolean;
  appendWith?: React.ReactNode;
  prependWith?: 'icon' | 'dot' | 'square' | 'roundedSquare' | 'roundedSquareFilled';
  prependSx?: FlexProps['sx'];
  isOutlined?: boolean;
  iconType?: Icons;
  selectType?: FilterGroupProps['selectType'];
};

export enum FilterGroupNames {
  DATE_RANGE = 'DATE_RANGE',
  REQUEST_STATE = 'REQUEST_STATE',
  REQUEST_TYPES = 'REQUEST_TYPES',
  REQUESTS_USAGE_OVERVIEW_DATE_RANGE = 'REQUESTS_USAGE_OVERVIEW_DATE_RANGE',
  TEAMMATES = 'TEAMMATES',
  TAGS = 'TAGS',
  ROLES = 'ROLES',
  REPORTS_ATTENDANCE_STATE = 'REPORTS_ATTENDANCE_STATE',
  HIDE_EMPTY = 'HIDE_EMPTY',
  HIDE_PUBLISHED = 'HIDE_PUBLISHED',
  HIDE_ACCEPTED_REQUESTS = 'HIDE_ACCEPTED_REQUESTS',
  HIDE_EMPTY_COLUMNS = 'HIDE_EMPTY_COLUMNS',
  HIDE_TYPES_WITHOUT_LIMITS = 'HIDE_TYPES_WITHOUT_LIMITS',
  HIDE_USERS_WITHOUT_DATA = 'HIDE_USERS_WITHOUT_DATA',
  WORK_STATUSES = 'WORK_STATUSES',
  LOCATIONS = 'LOCATIONS',
  WORK_STATUSES_STATE = 'WORK_STATUSES_STATE',
  TIME_CLOCKS__STATE = 'TIME_CLOCKS__IS_ONLINE__STATE',
  TIME_CLOCKS__SYSTEM = 'TIME_CLOCKS__SYSTEM',
  HIDE_USERS_WITHOUT_PHOTOS = 'HIDE_USERS_WITHOUT_PHOTOS',
  CALENDAR = 'CALENDAR',
  WORK_POSITION = 'WORK_POSITION',
  WORK_POSITION_TEAM = 'WORK_POSITION_TEAM',
  TEAMMATES_PICK_TEAMMATES_FILTERS = 'TEAMMATES_PICK_TEAMMATES_FILTERS',
  TAGS_PICK_TEAMMATES_FILTERS = 'TAGS_PICK_TEAMMATES_FILTERS',
  ROLES_PICK_TEAMMATES_FILTERS = 'ROLES_PICK_TEAMMATES_FILTERS',
  WORK_STATUS_TYPE = 'WORK_STATUS_TYPE',
  WORK_STATUS_SEND_VIA = 'WORK_STATUS_SEND_VIA',
  SHOW_ONLY_OFFLINE_EVENTS = 'SHOW_ONLY_OFFLINE_EVENTS',
}

//
// CALENDAR
//
export enum CalendarSchedulesFilters {
  Fixed = 'FIXED',
  Flexible = 'FLEXIBLE',
  Overtime = 'OVERTIME',
  WithOvertime = 'WITH_OVERTIME',
  WithAutoBreak = 'WITH_AUTO_BREAK',
  Error = 'ERROR',
}

export enum HidePublishedFilter {
  IsActive = 'isActive',
}

export enum HideAcceptedRequestsFilter {
  IsActive = 'isActive',
}

//
// LOCATION
//
export enum LocationDefaultFilters {
  Without = 'WITHOUT',
  Any = 'ANY',
}
//
// REPORTS
//
export enum ReportDayStateFilters {
  Present = 'PRESENT',
  IncompleteShift = 'INCOMPLETE_SHIFT',
  Overtime = 'OVERTIME',
  TimeOff = 'TIME_OFF',
  Absent = 'ABSENT',
  CustomRequest = 'CUSTOM_REQUEST',
  BusinessTrip = 'BUSINESS_TRIP',
  Error = 'ERROR',
}

export enum HideEmptyFilter {
  IsActive = 'isActive',
}

//
// TEAMMATES TYPES
//
export enum TeammatesFilters {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  HIDDEN = 'HIDDEN',
  DEACTIVATED = 'DEACTIVATED',
}

export enum DateRangeFilters {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  PAY_PERIOD = 'PAY_PERIOD',
  OVERTIME_PERIOD = 'OVERTIME_PERIOD',
  CUSTOM = 'CUSTOM',
  YEAR = 'YEAR', // REQUESTS USAGE OVERVIEW
}

export enum TagsFilters {
  NO_TAGS = 'NO_TAGS',
}

export enum WorkPosiotionsFilters {
  NO_POSITION = 'NO_POSITION',
}

//
// CLOCK_LOG
//
export enum ShowOnlyModifiedFilter {
  Modified = 'MODIFIED',
}

export enum ShowOnlyOfflineEventsFilter {
  Offline = 'OFFLINE',
}

export enum ShowOnlyPendingEventsFilter {
  EVENTS = 'EVENTS',
}

export enum HideUsersWithoutPhotosFilter {
  PHOTOS_ONLY = 'PHOTOS_ONLY',
}

//
// WORK STATUSES
//
export enum WorkStatusesFilters {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}
export enum WorkStatusSendVia {
  MobileTimeClock = 'MOBILE_TIME_CLOCK',
  MobileApp = 'MOBILE_APP',
  WebApp = 'WEB_APP',
  WebKiosk = 'WEB_KIOSK',
  ManuallyAdded = 'MANUALLY_ADDED',
}

export enum WorkStatusType {
  Tasks = 'TASKS',
  Statuses = 'STATUSES',
  Breaks = 'BREAKS',
}

//
// TIME CLOCKS
//
export enum TimeClocksStateFilters {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum TimeClocksSystemFilters {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

//
// REQUESTS
//
export enum HideEmptyColumnsFilter {
  IsActive = 'isActive',
}

export enum HideTypesWithoutLimitsFilter {
  IsActive = 'isActive',
}

export enum HideUsersWithoutDataFilter {
  IsActive = 'isActive',
}
