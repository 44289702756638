import { t, Trans } from '@lingui/macro';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Flex, Link, Text } from 'theme-ui';

import { exportToICalUrl } from 'api/actions/export/exportActions';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Divider } from 'components/Divider/Divider';
import { Icon } from 'components/Icon/Icon';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { ControlledModal, Modal, openModal } from 'components/Modal/Modal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { Button } from 'components/ui/Buttons';
import { TextInput } from 'components/ui/TextInput';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { setNativeValue } from 'utils/setNativeValue';

const ButtonWithTooltip = withTooltip(Button);

const SECURITY_WARNING = 'SECURITY_WARNING';
const RESET_URL_MODAL = 'RESET_URL_MODAL';

export const ShareCalendarModal: FC = () => {
  const [ICalUrl, setICalUrl] = useState<string | null>(null);
  const textInputRef = useRef<HTMLInputElement | null>(null);
  const securityOnceConfirmed = useRef(false);

  const { mutate } = useMutation(exportToICalUrl);

  const shareHelpLink = useHelpLink({
    inEwi: {
      pl: '/article/udostepnij-kalendarz-1dbjfed/',
    },
    unrubble: {
      en: '/article/share-calendar-h6436w/',
    },
  });

  const fetchICalUrl = useCallback(
    async (resetUrl: boolean) => {
      const { error, payload } = await mutate({ resetUrl });

      if (!error && payload) {
        setICalUrl(payload);
      }

      return payload;
    },
    [mutate],
  );

  const resetAction = useCallback(async () => {
    const confirmation = await openModal<boolean | null>(RESET_URL_MODAL);

    if (!confirmation) return;

    const url = await fetchICalUrl(true);
    setNativeValue(textInputRef, url);
  }, [fetchICalUrl]);

  const onCustomSetIsPasswordMaskedClick = useCallback(
    async (setIsPasswordMasked: React.Dispatch<React.SetStateAction<boolean>>) => {
      if (!securityOnceConfirmed.current) {
        const confirmation = await openModal<boolean | null>(SECURITY_WARNING);

        if (confirmation) {
          setIsPasswordMasked((prevState) => !prevState);
          securityOnceConfirmed.current = true;
        }
      } else {
        setIsPasswordMasked((prevState) => !prevState);
      }
    },
    [],
  );

  const copyICalUrlIntoClipboard = useCallback(async () => {
    if (!securityOnceConfirmed.current) {
      const confirmation = await openModal<boolean | null>(SECURITY_WARNING);

      if (!confirmation) return;
      securityOnceConfirmed.current = true;
    }

    void navigator.clipboard.writeText(ICalUrl || '');

    addSnackbar({
      message: t({
        id: 'global.copied',
      }),
      prependWith: <Icon type="approve" />,
    });
  }, [ICalUrl]);

  useEffect(() => {
    void fetchICalUrl(false);
  }, [fetchICalUrl]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>{t({ id: 'calendar.share_calendar.title', message: 'Share your calendar' })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {ICalUrl ? (
          <Flex sx={{ flexDirection: 'column', gap: 3 }}>
            <Flex sx={{ gap: 1 }}>
              <TextInput
                ref={textInputRef}
                id="password"
                type="password"
                defaultValue={ICalUrl}
                readOnly
                customSetIsPasswordManager={floatingPromiseReturn(onCustomSetIsPasswordMaskedClick)}
                size="sm"
              />
              <ButtonWithTooltip
                variant="minimal"
                shape="rounded"
                onClick={floatingPromiseReturn(copyICalUrlIntoClipboard)}
                popperProps={{ placement: 'top' }}
                tooltipProps={{
                  content: t({
                    id: 'global.copy_to_clipboard',
                  }),
                }}
              >
                {t({ id: 'calendar.drop_zone.copy' })}
              </ButtonWithTooltip>
            </Flex>
            <Flex sx={{ flexDirection: 'column', gap: 2 }}>
              <Trans id="calendar.share_calendar.content">
                <Text>Use this address to access this calendar from other applications without making it public.</Text>
                <Text sx={{ fontWeight: 'bold' }}>
                  Warning: You should not give the secret address to other people.{' '}
                </Text>
                <Text>
                  <Link
                    href={shareHelpLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      textDecoration: 'underline',
                      fontWeight: 'normal',
                      '&:hover': {
                        opacity: 0.7,
                      },
                    }}
                  >
                    Instructions for importing/adding the calendar
                  </Link>
                  .
                </Text>
                <Divider sx={{ my: 2 }} />
                <Text>You can reset this address and make the current one invalid.</Text>
              </Trans>
            </Flex>
            <Button
              variant="lightGrey"
              shape="rounded"
              onClick={floatingPromiseReturn(resetAction)}
              sx={{ mr: 'auto' }}
            >
              <Trans id="calendar.reset">Reset</Trans>
            </Button>
          </Flex>
        ) : (
          <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
            <LoadingSpinnerCss size={4} />
          </Flex>
        )}
      </Modal.Body>
      <ControlledModal size="xs" id={SECURITY_WARNING}>
        <ConfirmModal
          contentRenderer={() => (
            <Text>
              <Trans id="calendar.security_warning.content">
                You should not give the secret address to other people.
              </Trans>
            </Text>
          )}
          titleRenderer={() => <Trans id="calendar.security_warning.title">Security warning</Trans>}
          actionButtonProps={{
            variant: 'primary',
            buttonText: 'OK',
          }}
        />
      </ControlledModal>
      <ControlledModal size="xs" id={RESET_URL_MODAL}>
        <ConfirmModal
          contentRenderer={() => (
            <Text>
              <Trans id="calendar.reset_url.content">
                This will invalidate the existing secret address. Are you sure you want to reset the secret address?
              </Trans>
            </Text>
          )}
          titleRenderer={() => <Trans id="calendar.reset_url.title">Reset secret address?</Trans>}
          actionButtonProps={{
            variant: 'warning',
            buttonText: t({ id: 'calendar.reset' }),
          }}
        />
      </ControlledModal>
    </>
  );
};
