import { MutableRefObject, useEffect } from 'react';

export const useMapEvent = (
  eventName: string | undefined | null,
  callback: ((e?: google.maps.MapMouseEvent) => void) | undefined,
  ref: MutableRefObject<google.maps.Map | google.maps.Marker | null> | null,
): void => {
  useEffect(() => {
    let callbackEvent: google.maps.MapsEventListener | null = null;

    if (eventName && callback && ref?.current) {
      callbackEvent = google.maps.event.addListener(ref.current, eventName, callback);
    }

    return () => {
      if (callbackEvent) {
        google.maps.event.removeListener(callbackEvent);
      }
    };
  }, [eventName, callback, ref]);
};
