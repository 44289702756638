import React, { useCallback, useMemo } from 'react';
import { Text, ThemeUIStyleObject } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { Button, ButtonProps, LinkButton, LinkButtonProps } from 'components/ui/Buttons';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { usePathnameMatch } from 'hooks/usePathnameMatch/usePathnameMatch';
import { useTheme } from 'styles/useTheme';

import { DropdownLinkObject } from './types';

const linkButtonSx: ThemeUIStyleObject = {
  justifyContent: 'flex-start',
  textAlign: 'left',
  flexGrow: 1,
};

const additionalInfoSx: ThemeUIStyleObject = {
  fontWeight: 'normal',
};

type Props = Omit<LinkButtonProps, 'to' | 'prependWith' | 'shape'> & Omit<DropdownLinkObject, 'tooltipText'>;

export type DropdownLinkProps = Props;

const defaultProps: Partial<Props> = {
  additionalInfo: undefined,
  icon: undefined,
  prependWith: undefined,
  isButton: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DropdownLink = React.forwardRef<any, Props>(
  (
    {
      to,
      label,
      additionalInfo,
      icon,
      prependWith,
      matchPattern,
      isButton,
      toValidation,
      onClick,
      type,
      variant,
      isLoading,
      activeRadioOption,
      ...props
    }: Props,
    ref,
  ): React.ReactElement | null => {
    const getPathnames = useCallback(() => {
      if (matchPattern) {
        return [matchPattern];
      }
      if (to) {
        return [`${typeof to === 'string' ? to : to.pathname}/*`];
      }

      return [];
    }, [matchPattern, to]);

    const pathnameMatch = usePathnameMatch(getPathnames());
    const active = activeRadioOption || pathnameMatch;
    const { theme } = useTheme();

    const getPrependWithComponent = useCallback(() => {
      if (prependWith) {
        return prependWith;
      }
      if (icon) {
        return <Icon type={icon} />;
      }
      return undefined;
    }, [icon, prependWith]);

    const buttonsInternalOnClick: NonNullable<ButtonProps['onClick']> & NonNullable<LinkButtonProps['onClick']> = (
      e,
    ) => {
      e.preventDefault();

      if (toValidation && !toValidation()) {
        return;
      }

      if (onClick) onClick(e as unknown as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
    };

    const contentRenderer = () => (
      <Text as="span" sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextEllipsis>{label}</TextEllipsis>
        {additionalInfo && (
          <TextEllipsis
            as="span"
            className="additional-info"
            sx={{
              ...additionalInfoSx,
              color: active
                ? 'dropdown.dropdownLink.additionalInfo.active'
                : 'dropdown.dropdownLink.additionalInfo.default',
            }}
          >
            {additionalInfo}
          </TextEllipsis>
        )}
      </Text>
    );

    const bgOverwrite = useMemo(() => {
      if (variant) return undefined;

      if (activeRadioOption) {
        return {
          default: 'alphas.primarish2',
          hover: 'alphas.primarish4',
          tap: 'alphas.primarish2',
          disabled: 'alphas.primarish2',
        };
      }

      return {
        default: theme.colors.dropdown.dropdownLink.background.default,
        hover: theme.colors.dropdown.dropdownLink.background.hover,
        tap: theme.colors.dropdown.dropdownLink.background.tap,
        disabled: theme.colors.dropdown.dropdownLink.background.disabled,
      };
    }, [
      variant,
      activeRadioOption,
      theme.colors.dropdown.dropdownLink.background.default,
      theme.colors.dropdown.dropdownLink.background.hover,
      theme.colors.dropdown.dropdownLink.background.tap,
      theme.colors.dropdown.dropdownLink.background.disabled,
    ]);

    const commonProps = useMemo(
      () => ({
        ref,
        fullWidth: true,
        shape: 'rounded' as const,
        variant: variant || ('minimal' as const),
        prependWith: icon || prependWith ? getPrependWithComponent() : undefined,
        bgOverwrite,
        disabled: props.disabled,
        sx: {
          ...linkButtonSx,
          ...(!props.disabled && { cursor: 'pointer' }),
          ...(active && { color: 'dropdown.dropdownLink.color.active' }),
          ...(props.sx && props.sx),
        },
      }),
      [bgOverwrite, active, getPrependWithComponent, icon, prependWith, props.disabled, props.sx, ref, variant],
    );

    if (isButton || !to) {
      return (
        <Button {...commonProps} isLoading={isLoading} onClick={buttonsInternalOnClick} omitSpanWrapper>
          {contentRenderer()}
        </Button>
      );
    }

    return (
      <LinkButton onClick={onClick} toValidation={toValidation} to={to} {...props} {...commonProps}>
        {contentRenderer()}
      </LinkButton>
    );
  },
);

DropdownLink.defaultProps = defaultProps;
