/** @jsxImportSource theme-ui */
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { forwardRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Heading } from 'theme-ui';

import { WorkPositionDetails } from 'api/actions/workPositions/workPositionsActions.types';
import { TextInput } from 'components/ui/TextInput';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

type Props = {
  defaultValues?: WorkPositionDetails;
  onSubmit: (props: WorkPositionDetails) => Promise<boolean>;
  setLoading: (loading: boolean) => void;
};

export const AddEditWorkPosition = forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, defaultValues, setLoading }, ref) => {
    useLingui();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: 'onTouched',
      reValidateMode: 'onChange',
      defaultValues,
    });

    const handleSubmitCallback = useCallback(
      (body: WorkPositionDetails) => {
        void onSubmit(body);
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    return (
      <form
        ref={ref}
        onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))}
        noValidate
      >
        <Heading variant="heading4.withMargins">
          <Trans id="team.add_edit_work_pos.basic_details">Basic details</Trans>
        </Heading>

        <TextInput
          clearable
          size="sm"
          id="name"
          placeholder={t({ id: 'team.add_edit_work_pos.name', message: 'Name' })}
          variant="rounded"
          error={!!errors.name}
          errorMessage={errors?.name?.message}
          {...register('name', validationFactory({ ...VALIDATION_RULES.WORK_POSITION_NAME, required: true }))}
        />
      </form>
    );
  },
);
