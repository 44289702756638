import React from 'react';
import { Flex, Text } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';

type Props = {
  iconType: Icons;
  label: string;
};

export const FancySelectedType = ({ iconType, label }: Props): React.ReactElement => (
  <Flex
    sx={{
      border: '1px solid',
      borderColor: 'requests.fancySelectedType',
      borderRadius: 'sm',
      gap: 2,
      p: '0.375rem 0.5rem',
    }}
  >
    <Icon type={iconType} size={21} />
    <Text sx={{ fontSize: 2 }}>{label}</Text>
  </Flex>
);
