import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  FetchTimeZoneListResponse,
  FetchIndustryListResponse,
  FetchFileActionProps,
  FetchFileResponse,
  FetchCountryDetailsListResponse,
} from './dataActions.types';

export function fetchTimeZoneListAction(): Action<FetchTimeZoneListResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.timeZoneList,
  };
}
export function fetchIndustryListAction(): Action<FetchIndustryListResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.industryList,
  };
}

export function fetchFileAction({ id }: FetchFileActionProps): Action<FetchFileResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.file}/${id}`,
  };
}

export function fetchCountryDetailsListAction(): Action<FetchCountryDetailsListResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.countryDetailsList,
  };
}
