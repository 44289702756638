import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Text } from 'theme-ui';

import { deleteRequestsAction } from 'api/actions/requests/requestsActions';
import { RequestState } from 'api/actions/requests/requestsActions.types';
import { useModal } from 'components/Modal/output/useModal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'components/recipes/ConfirmModal/types';
import { ListNames } from 'components/StickyList/types';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { useRequestsToAccept } from 'pages/Home/output/useRequestsToAccept';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { allParsedRequestsMapSelector } from 'state/requests';
import { useRefreshRequests } from '../../hooks/useRefreshRequests';
import { useRefreshRequestsTypeDetails } from '../../hooks/useRefreshRequestsTypeDetails';

import { useRefreshRequestsUsageOverview } from './hooks/useRefreshRequestsUsageOverview';

type Props = {
  onSubmitCallback?: () => Promise<void>;
};

export const DeleteRequestModal = ({ onSubmitCallback }: Props): React.ReactElement => {
  useLingui();
  const requests = useRecoilValue(allParsedRequestsMapSelector);
  const setSelectedRequests = useSetRecoilState(selectedRowsIdsSelectorFamily(ListNames.REQUESTS));
  const { state } = useLocation();
  const { ids, requestNumber, employeesIds, requestDetailsData: requestDetails, deleteGroupRequest } = state || {};
  const { baseRoute } = useModal();

  const getEmployeeIds = useCallback(() => {
    if (requestDetails && requestDetails.employee.id) {
      const { employee, swapPersonId } = requestDetails;

      return swapPersonId ? [employee.id, swapPersonId] : [employee.id];
    }

    if (employeesIds) {
      return employeesIds;
    }

    if (requests && ids) {
      return _.map(ids, (reqId) => requests.get(reqId)?.employee.id).filter((id) => id !== undefined) as string[];
    }

    return undefined;
  }, [ids, requestDetails, requests, employeesIds]);

  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar(getEmployeeIds());
  const { reportInitialized, updateReportForIds } = useRefreshReport(getEmployeeIds());
  const { updateRequestsNoThrottle, requestsInitialized } = useRefreshRequests();
  const { updateRequestsUsageOverviewNoThrottle, requestsUsageOverviewInitialized } = useRefreshRequestsUsageOverview();
  const { isInitialized: requestsToAcceptInitialized, fetchRequestsToAccept } = useRequestsToAccept();
  const { updateRequestsTypeDetailsNoThrottle, requestsTypeDetailsInitialized } = useRefreshRequestsTypeDetails();

  const deleteGroupConfirmationMessage = useMemo(() => {
    if (!deleteGroupRequest) return null;
    const { latestInGroup, state: requestState } = deleteGroupRequest;

    if (!latestInGroup || requestState === RequestState.Pending) return null;

    return `${t({
      id: 'requests.delete_request_content_latest_in_group',
      message: 'This action affects the request group and will delete the entire group.',
    })} `;
  }, [deleteGroupRequest]);

  const titleRenderer = useCallback(
    (idsCount: number, number: string) => {
      const requestNum = requestNumber || number;

      return t({ id: 'requests.delete_request_title', message: `Delete request - ${requestNum}?` });
    },
    [requestNumber],
  );

  const contentRenderer = useCallback(
    () => (
      <>
        <Trans id="requests.delete_request_content">
          <Text>
            You're about to permanently delete this request with all related data.{' '}
            <strong>This action cannot be reversed.</strong>
          </Text>
          <Text>All deleted requests can still be reviewed.</Text>
        </Trans>
        <Text>{deleteGroupConfirmationMessage}</Text>
      </>
    ),
    [deleteGroupConfirmationMessage],
  );

  const onResponseCallback: NonNullable<ConfirmModalProps['onResponseCallback']> = useCallback(
    async (error: boolean) => {
      if (!error) {
        if (onSubmitCallback) await onSubmitCallback();
        if (requestsInitialized) await updateRequestsNoThrottle(true);
        if (calendarInitialized) await updateCalendarForIds();
        if (reportInitialized) await updateReportForIds();
        if (requestsToAcceptInitialized) await fetchRequestsToAccept(true);
        if (requestsUsageOverviewInitialized) await updateRequestsUsageOverviewNoThrottle(true);
        if (requestsTypeDetailsInitialized) await updateRequestsTypeDetailsNoThrottle(true);
      }
    },
    [
      onSubmitCallback,
      requestsInitialized,
      updateRequestsNoThrottle,
      calendarInitialized,
      updateCalendarForIds,
      reportInitialized,
      updateReportForIds,
      requestsToAcceptInitialized,
      fetchRequestsToAccept,
      requestsUsageOverviewInitialized,
      updateRequestsUsageOverviewNoThrottle,
      requestsTypeDetailsInitialized,
      updateRequestsTypeDetailsNoThrottle,
    ],
  );

  const deselectDeletedRequest = useCallback(() => {
    if (ids) {
      setSelectedRequests((selectedRequests) => selectedRequests.filter((requestId) => requestId !== ids[0]));
    }
  }, [ids, setSelectedRequests]);

  if (!ids?.length || !requestNumber) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <ConfirmModal
      list={requests}
      action={deleteRequestsAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
      onResponseCallback={onResponseCallback}
      onSuccessCallback={deselectDeletedRequest}
      namePath="number"
    />
  );
};
