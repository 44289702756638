import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import React, { useCallback, useMemo } from 'react';
import { Text } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { ADD_REQUEST_DEFAULT_STEPS } from 'constants/requests';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { Step } from 'state/requests';
import { useAddRequest } from '../hooks/useAddRequest';

type Props = {
  handleSave: () => void;
};

export const AddRequestFooter = ({ handleSave }: Props): React.ReactElement => {
  const {
    addRequestState: { loading, step, isSaveDisabled, requestType },
    setAddRequestState,
    selectedTeammatesIds,
    requestConfig: { requestConfigState },
  } = useAddRequest();
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const steps = useMemo(() => {
    const { excludedSteps } = requestConfigState || {};

    if (!excludedSteps) return ADD_REQUEST_DEFAULT_STEPS;

    return ADD_REQUEST_DEFAULT_STEPS.filter((s) => !excludedSteps.includes(s));
  }, [requestConfigState]);

  const currentStepToDisplay = useMemo(() => steps.indexOf(step) + 1, [step, steps]);

  const nextStep = useCallback(
    () =>
      setAddRequestState((prevState) => {
        const index = steps.indexOf(prevState.step);
        const currentStep = index !== -1 && index < steps.length - 1 ? steps[index + 1] : prevState.step;

        return {
          ...prevState,
          step: currentStep,
          isSaveDisabled: currentStep === Step.Forms ? true : prevState.isSaveDisabled,
        };
      }),
    [setAddRequestState, steps],
  );

  const prevStep = useCallback(
    () =>
      setAddRequestState((prevState) => {
        const index = steps.indexOf(prevState.step);
        const currentStep = index > 0 ? steps[index - 1] : prevState.step;

        return { ...prevState, step: currentStep };
      }),
    [setAddRequestState, steps],
  );

  const isButtonDisabled = useMemo(
    () =>
      !selectedTeammatesIds[0] ||
      (step === Step.SelectType && requestType === undefined) ||
      (isSaveDisabled && step === Step.Forms),
    [isSaveDisabled, requestType, selectedTeammatesIds, step],
  );

  const buttonRenderer = useMemo(() => {
    if (step !== Step.Forms) {
      return (
        <>
          {t({ id: 'global.forms.buttons.next' })}
          <Icon wrapperSx={{ ml: 3 }} type="arrowRight" />
        </>
      );
    }

    return <Trans id="save">Save</Trans>;
  }, [step]);

  const backButtonAction = useMemo(() => {
    if (currentStepToDisplay === 1) return undefined;

    return prevStep;
  }, [currentStepToDisplay, prevStep]);

  return (
    <BasicModalFooter
      {...(steps.length > 1 &&
        !isSmartphoneBreakpoint && {
          prependWith: (
            <Text sx={{ fontSize: 2 }}>
              <Trans id="wizard.step_tracker" values={{ step: currentStepToDisplay, steps: steps.length }}>
                Step {currentStepToDisplay} of {steps.length}
              </Trans>
            </Text>
          ),
        })}
      backButtonOnClick={backButtonAction}
      buttons={[
        {
          isLoading: loading,
          disabled: isButtonDisabled,
          variant: 'primary',
          onClick: step === Step.Forms ? handleSave : nextStep,
          sx: step === Step.Forms ? {} : { pr: 2 },
          children: buttonRenderer,
        },
      ]}
    />
  );
};
