import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { Breaks } from './components/Breaks';
import { Other } from './components/Other';
import { Overtime } from './components/Overtime';
import { PayPeriod } from './components/PayPeriod';
import { TimeClocks } from './components/TimeClocks';

type Props = FlexProps;

type TimeTrackingFormsContainerProps = Props;

type TimeTrackingFormsContainerComponent = React.FC<TimeTrackingFormsContainerProps> & {
  TimeClocks: typeof TimeClocks;
  Overtime: typeof Overtime;
  PayPeriod: typeof PayPeriod;
  Breaks: typeof Breaks;
  Other: typeof Other;
};

export const TimeTrackingFormsContainer: TimeTrackingFormsContainerComponent = ({ children }): React.ReactElement => (
  <Flex variant="settings.container.vertical.main">{children}</Flex>
);

TimeTrackingFormsContainer.TimeClocks = TimeClocks;
TimeTrackingFormsContainer.Overtime = Overtime;
TimeTrackingFormsContainer.PayPeriod = PayPeriod;
TimeTrackingFormsContainer.Breaks = Breaks;
TimeTrackingFormsContainer.Other = Other;
