import { motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { Button } from 'components/ui/Buttons';
import { Icon } from 'components/Icon/Icon';

const AnimatedFlex = motion(Flex);

const containerAnimationStates = {
  expanded: {
    maxWidth: '256px',
  },
  collapsed: {
    maxWidth: '10px',
  },
  hover: {},
};

const filtersAnimationStates = {
  expanded: {
    x: 0,
  },
  collapsed: {
    x: -266,
  },
  hover: {
    x: 0,
  },
};

type Props = { children?: React.ReactNode; sx?: ThemeUIStyleObject };

export const AsideWrapper = ({ children }: Props): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOnClick = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, [setIsExpanded]);

  return (
    <AnimatedFlex
      as="aside"
      variants={containerAnimationStates}
      initial="collapsed"
      animate={isExpanded ? 'hover' : 'collapsed'}
      sx={{
        position: 'absolute',
        flexDirection: 'column',
        alignItems: 'stretch',
        maxWidth: '256px',
        width: '100%',
        height: '100vh',
        zIndex: 1010,
        top: 0,
        left: 3,
      }}
    >
      <AnimatedFlex
        variants={filtersAnimationStates}
        sx={{
          bg: 'transparent',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'stretch',
          maxWidth: '256px',
          minWidth: '256px',
          width: '100%',
          height: '100%',
          zIndex: 1,
          '&:hover': {
            '> .collapseButton': {
              opacity: 1,
            },
          },
        }}
      >
        <Flex
          variant="layouts.authorized.aside.collapseButtonContainer"
          className="collapseButton"
          data-collapsed={!isExpanded}
        >
          <Button
            sx={{ variant: 'layouts.authorized.aside.collapseButton' }}
            size="lg"
            variant="lightGrey"
            onClick={handleOnClick}
            prependWith={<Icon type={isExpanded ? 'chevronLeft' : 'chevronRight'} size={18} />}
          ></Button>
        </Flex>

        <Flex
          sx={{
            bg: 'requests.asideWrapper.bg.default',
            flexDirection: 'column',
            py: 4,
            pr: 3,
            pl: 3,
            justifyContent: 'right',
            alignItems: 'stretch',
            userSelect: 'none',
            overflowY: 'auto',
            width: '100%',
            flex: '1',
            ...(isExpanded && {
              bg: 'requests.asideWrapper.bg.expanded',
              boxShadow: 'requests',
            }),
            ...(!isExpanded && {
              transition: 'background 0.15s ease-in-out',
            }),
          }}
        >
          <React.Suspense fallback={<></>}>{children}</React.Suspense>
        </Flex>
      </AnimatedFlex>
    </AnimatedFlex>
  );
};
