import { t, Trans } from '@lingui/macro';
import React, { useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Text } from 'theme-ui';

import { setFraudResolutionStateAction } from 'api/actions/timeEvent/timeEventActions';
import { FraudDetectionState } from 'api/actions/timeEvent/timeEventActions.types';
import { useModal } from 'components/Modal/output/useModal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'components/recipes/ConfirmModal/types';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { antiSpoofingChecksAtom, clockLogResponseAtom } from 'state/clockLog';

export const RejectEventModal = (): React.ReactElement | null => {
  const { baseRoute } = useModal();
  const navigate = useAppNavigate();
  const { state } = useLocation();
  const { modifyTimeEvent, baseRoute: fromBaseRoute } = state;
  const { id, personId } = modifyTimeEvent || {};

  const setClockLogResponse = useSetRecoilState(clockLogResponseAtom);
  const setAntiSpoofingChecks = useSetRecoilState(antiSpoofingChecksAtom);
  const { reportInitialized, updateReportForIds } = useRefreshReport([personId || '']);

  const onResponseCallbackFactory: NonNullable<ConfirmModalProps['onResponseCallback']> = useCallback(
    async (submitError) => {
      if (!submitError) {
        setClockLogResponse((prev) => {
          if (prev === null || !id) return null;

          if ('timeEvents' in prev) {
            const { timeEvents } = prev;
            const timeEventsMap = new Map(timeEvents);
            const updatedEvent = timeEventsMap.get(id);

            if (updatedEvent) {
              timeEventsMap.set(id, { ...updatedEvent, fraudDetectionState: FraudDetectionState.ResolvedFraud });
            }

            return {
              ...prev,
              timeEvents: [...timeEventsMap],
            };
          }

          return prev;
        });

        setAntiSpoofingChecks((prev) => prev?.filter((event) => id !== event.id) || null);
        if (reportInitialized) await updateReportForIds();

        navigate(`${fromBaseRoute || ''}${baseRoute}`, { relative: 'path' });
      }
    },
    [
      baseRoute,
      fromBaseRoute,
      id,
      navigate,
      reportInitialized,
      setClockLogResponse,
      updateReportForIds,
      setAntiSpoofingChecks,
    ],
  );

  const titleRenderer = useCallback(
    () => t({ id: 'clock_log.anti_spoofing.reject.confirm', message: 'Are you sure?' }),
    [],
  );

  const contentRenderer = useCallback(
    () => (
      <Text>
        <Trans id="clock_log.anti_spoofing.reject.consequences">
          By rejecting, this event will be hidden in the clock log and won't be used in any reports.
        </Trans>
      </Text>
    ),
    [],
  );

  if (!id) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <ConfirmModal
      action={() => setFraudResolutionStateAction({ id, isFraud: true })}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="REJECT"
      onResponseCallback={onResponseCallbackFactory}
    />
  );
};
