import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { AccountInfoCompany } from 'api/actions/user/userActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { FormCard } from 'components/FormCard/FormCard';
import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons/Button';
import { ElementGroup } from 'components/ui/ElementGroup';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { Select } from 'components/ui/Select/Select';
import { CountrySelect } from 'components/ui/Select/variants/CountrySelect';
import { TimeZoneSelect } from 'components/ui/Select/variants/TimeZoneSelect';
import { TextInput } from 'components/ui/TextInput';
import { BROWSER_REGION, HOST_REGION, REAL_TIMEZONE } from 'constants/common';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useIndustrySelectsOptions } from 'pages/Settings/output/useIndustrySelectsOptions';
import { signUpFormAtom, timeZoneListAtom } from 'state/signUp';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { getBrowserRegion } from 'utils/getBrowserRegion';
import { typedKeys } from 'utils/typedKeys';

type Props = {
  onSubmit: (props: AccountInfoCompany) => Promise<boolean>;
};

const getTimezone = () => dayjs.tz.guess() || Intl.DateTimeFormat().resolvedOptions().timeZone;

export const EmployerStep2Form = React.forwardRef<HTMLFormElement, Props>(({ onSubmit }: Props, ref) => {
  useLingui();
  const defaultCountryCode = useMemo(() => BROWSER_REGION || getBrowserRegion() || HOST_REGION, []);
  const defaultTimezoneId = useMemo(() => REAL_TIMEZONE || getTimezone(), []);
  const [signUpFormState, setSignUpFormState] = useRecoilState(signUpFormAtom);
  const timeZoneList = useRecoilValue(timeZoneListAtom);

  const navigate = useAppNavigate();

  const {
    getValues,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: signUpFormState?.companyName,
      ...(signUpFormState?.industry && { industry: signUpFormState?.industry }),
      industrySubcategory: signUpFormState?.industrySubcategory,
      timeZoneId: signUpFormState?.timeZoneId || timeZoneList?.find((tz) => tz.id === defaultTimezoneId)?.id,
      countryCode: signUpFormState?.countryCode || defaultCountryCode || '',
    },
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const watchIndustry = watch('industry');

  const { industryOptions, industrySubcategoryOptions } = useIndustrySelectsOptions(watchIndustry);

  const saveToRecoilCallback = useCallback(() => {
    let formValues = getValues();
    if (formValues.industry !== watchIndustry) {
      setValue('industrySubcategory', undefined);
      formValues = getValues();
    }
    let validFormValues = {};

    typedKeys(formValues).forEach((name) => {
      if (errors[name] && formValues[name] !== '') {
        return;
      }
      if (name === 'timeZoneId' && timeZoneList) {
        const timeZoneObject = timeZoneList.find((tz) => tz.id === formValues.timeZoneId);
        validFormValues = {
          ...validFormValues,
          timeZoneId: timeZoneObject ? timeZoneObject.id : formValues.timeZoneId,
        };
        return;
      }

      validFormValues = {
        ...validFormValues,
        [name]: formValues[name],
      };
    });

    setSignUpFormState({
      ...signUpFormState,
      ...validFormValues,
    });
  }, [getValues, watchIndustry, setValue, errors, timeZoneList, setSignUpFormState, signUpFormState]);

  const handleSubmitCallback: SubmitHandler<Partial<AccountInfoCompany>> = useCallback(
    async (formProps) => {
      const { companyName, countryCode, timeZoneId, industry, industrySubcategory } = formProps;
      if (!!companyName && !!countryCode && !!timeZoneId && !!industry) {
        await onSubmit({
          companyName,
          countryCode,
          industry,
          timeZoneId,
          industrySubcategory,
        });
      }
    },
    [onSubmit],
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  const checkCountryFromBrowser = useMemo(
    () =>
      _.debounce(
        () => {
          const browserRegion = getBrowserRegion();

          if (!browserRegion) {
            addSnackbar({
              message: t({
                id: 'sign_up.employer.form.device_check.snackbar_error',
                message: 'Your device was unable to provide a country.',
              }),
              variant: 'warning',
            });
            return;
          }
          setValue('countryCode', browserRegion);
        },
        500,
        { leading: true },
      ),
    [setValue],
  );
  const checkTimezoneFromBrowser = useMemo(
    () =>
      _.debounce(
        () => {
          const timezone = getTimezone();

          const timezoneId = timeZoneList?.find((tz) => tz.id === timezone)?.id;

          if (!timezoneId) {
            addSnackbar({
              message: t({
                id: 'sign_up.employer.form.device_check.timezone.snackbar_error',
                message: 'Your device was unable to provide a timezone.',
              }),
              variant: 'warning',
            });
            return;
          }
          setValue('timeZoneId', timezoneId);
        },
        500,
        { leading: true },
      ),
    [setValue, timeZoneList],
  );

  return (
    <form
      ref={ref}
      onChange={saveToRecoilCallback}
      onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}
      noValidate
    >
      <ElementGroup marginValue="4" direction="column">
        <TextInput
          id="companyName"
          placeholder={t({
            id: 'sign_up.employer.form.company_name',
            message: 'Name of your company',
          })}
          type="text"
          error={!!errors.companyName}
          errorMessage={errors?.companyName?.message}
          {...register('companyName', validationFactory({ ...VALIDATION_RULES.COMPANY_NAME, required: true }))}
        />

        <CountrySelect
          id="countryCode"
          label={
            <Text>
              <Trans id="sign_up.employer.form.country">Country</Trans>
              <>
                {' | '}
                <LinkAnchor
                  standOut
                  sx={{ textDecoration: 'underline' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    checkCountryFromBrowser();
                  }}
                >
                  <Trans id="sign_up.employer.form.device_check">Check from device</Trans>
                </LinkAnchor>
              </>
            </Text>
          }
          placeholder={t({
            id: 'sign_up.employer.form.country',
            message: 'Country',
          })}
          searchable
          error={!!errors.countryCode}
          errorMessage={errors?.countryCode?.message}
          {...register('countryCode', { required: t({ id: 'global.forms.required' }) })}
        />

        <TimeZoneSelect
          id="timeZoneId"
          label={
            <Text>
              <Trans id="organization_settings.time_zone_id.label">Timezone</Trans>
              <>
                {' | '}
                <LinkAnchor
                  standOut
                  sx={{ textDecoration: 'underline' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    checkTimezoneFromBrowser();
                  }}
                >
                  <Trans id="sign_up.employer.form.device_check">Check from device</Trans>
                </LinkAnchor>
              </>
            </Text>
          }
          placeholder={t({
            id: 'organization_settings.time_zone_id.label',
          })}
          searchable
          error={!!errors.timeZoneId}
          errorMessage={errors?.timeZoneId?.message}
          {...register('timeZoneId', { required: t({ id: 'global.forms.required' }) })}
        />

        <Flex sx={{ flexDirection: 'column', gap: '2px' }}>
          <Select
            id="industry"
            label={t({
              id: 'sign_up.employer.form.industry',
              message: 'Industry',
            })}
            placeholder={t({
              id: 'global.forms.pick',
              message: 'Pick one',
            })}
            variant={industrySubcategoryOptions.length > 0 ? 'roundedTop' : 'rounded'}
            error={!!errors.industry}
            errorMessage={errors?.industry?.message}
            options={industryOptions}
            {...register('industry', { required: t({ id: 'global.forms.required' }), valueAsNumber: true })}
          />

          {!!watchIndustry && industrySubcategoryOptions.length > 0 && (
            <Select
              id="industrySubcategory"
              placeholder={t({
                id: 'sign_up.employer.form.industry_sub',
                message: 'Additional industry category (optional)',
              })}
              variant="roundedBottom"
              error={!!errors.industrySubcategory}
              errorMessage={errors?.industrySubcategory?.message}
              clearable
              options={industrySubcategoryOptions}
              {...register('industrySubcategory', { valueAsNumber: true })}
            />
          )}
        </Flex>
      </ElementGroup>

      <FormCard.Footer>
        <Button variant="minimal" shape="rounded" size="lg" type="button" onClick={handleGoBack}>
          {t({ id: 'global.forms.buttons.back' })}
        </Button>

        <Button variant="primary" apendWith={<Icon type="arrowRight" />} size="lg" type="submit" shape="rounded">
          {t({ id: 'global.forms.buttons.next' })}
        </Button>
      </FormCard.Footer>
    </form>
  );
});
