import { Snapshot, useRecoilTransactionObserver_UNSTABLE } from 'recoil';

import { settingsAtom as kioskSettingsAtom } from 'Kiosk/state/settingsState';
import { ListNames } from 'components/StickyList/types';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { chatWindowIdsAtom } from 'state/chat';
import { dateRangeFilterAtom, dateRangeRequestsUsageOverviewFilterAtom } from 'state/filters';
import { sortingStateAtomFamily } from 'state/list';
import { notificationCenterAtom } from 'state/notification';
import { userSessionAtom } from 'state/userSession';
import { languageSelector } from '../state/recoilState';

export const LOCAL_STORAGE_VALUE_CARRIER_KEY = 'value';

export const PersistenceObserver: React.ElementType = () => {
  const { isAuthorized } = useAuthState();
  const callback: (opts: { snapshot: Snapshot; previousSnapshot: Snapshot }) => Promise<void> = async ({
    snapshot,
  }) => {
    if (!isAuthorized) return; // this condition was added to avoid saving state to localstorage when signing out (PersistenceObserver saved state after signOut cleanup)
    const userSession = await snapshot.getPromise(userSessionAtom);
    const language = await snapshot.getPromise(languageSelector);
    const chatWindowIds = await snapshot.getPromise(chatWindowIdsAtom);
    const notificationCenter = await snapshot.getPromise(notificationCenterAtom);
    const dateRangeFilter = await snapshot.getPromise(dateRangeFilterAtom);
    const dateRangeRequestsUsageOverviewFilter = await snapshot.getPromise(dateRangeRequestsUsageOverviewFilterAtom);

    const stateNodes = snapshot.getNodes_UNSTABLE();
    const sortingStateNodes: string[] = [];

    [...stateNodes].forEach((node) => {
      if (node.key.includes('sortingState__') && node.key !== 'sortingState__') sortingStateNodes.push(node.key);
    });

    sortingStateNodes.forEach((node) => {
      const splittedKey = node.split('"');
      const atomName = splittedKey[splittedKey.length - 2];
      const updateLocalStorage = async () => {
        const listState = await snapshot.getPromise(sortingStateAtomFamily(atomName as ListNames));
        localStorage.setItem(node, JSON.stringify(listState));
      };

      void updateLocalStorage();
    });

    localStorage.setItem(userSessionAtom.key, JSON.stringify({ [LOCAL_STORAGE_VALUE_CARRIER_KEY]: userSession }));
    localStorage.setItem(languageSelector.key, JSON.stringify({ [LOCAL_STORAGE_VALUE_CARRIER_KEY]: language }));
    localStorage.setItem(chatWindowIdsAtom.key, JSON.stringify({ [LOCAL_STORAGE_VALUE_CARRIER_KEY]: chatWindowIds }));
    localStorage.setItem(
      notificationCenterAtom.key,
      JSON.stringify({ [LOCAL_STORAGE_VALUE_CARRIER_KEY]: notificationCenter }),
    );
    localStorage.setItem(
      dateRangeFilterAtom.key,
      JSON.stringify({ [LOCAL_STORAGE_VALUE_CARRIER_KEY]: dateRangeFilter }),
    );
    localStorage.setItem(
      dateRangeRequestsUsageOverviewFilterAtom.key,
      JSON.stringify({ [LOCAL_STORAGE_VALUE_CARRIER_KEY]: dateRangeRequestsUsageOverviewFilter }),
    );

    // FOR KIOSK MODE
    const kioskSettings = await snapshot.getPromise(kioskSettingsAtom); // FIXME: JOTAI MIGRATION
    localStorage.setItem(kioskSettingsAtom.key, JSON.stringify({ [LOCAL_STORAGE_VALUE_CARRIER_KEY]: kioskSettings }));
  };

  useRecoilTransactionObserver_UNSTABLE((snaps) => void callback(snaps));

  return null;
};
