import { HOSTNAME, REACT_APP_HOSTNAME } from './common';

export const EXTERNAL_LINKS = {
  TERMS:
    HOSTNAME === REACT_APP_HOSTNAME.inewi ? 'https://inewi.pl/Regulamin' : 'https://unrubble.com/Terms-And-Conditions',
  PRIVACY_POLICY:
    HOSTNAME === REACT_APP_HOSTNAME.inewi
      ? 'https://inewi.pl/Polityka-Prywatnosci'
      : 'https://unrubble.com/Privacy-Policy',
  NEWSLETTER_TERMS:
    HOSTNAME === REACT_APP_HOSTNAME.inewi
      ? 'https://inewi.pl/Regulamin-Newsletter'
      : 'https://unrubble.com/Terms-Of-Newsletter',
  PERSONAL_DATA:
    HOSTNAME === REACT_APP_HOSTNAME.inewi
      ? 'https://inewi.pl/Obowiazek-Informacyjny'
      : 'https://unrubble.com/Personal-Data-From-Internet',
  ADD_TIME_CLOCK: HOSTNAME === REACT_APP_HOSTNAME.inewi ? 'https://inewi.pl/Rcp' : 'https://unrubble.com/Rcp',
  CAMERA_HELP:
    HOSTNAME === REACT_APP_HOSTNAME.inewi
      ? 'https://support.google.com/chrome/answer/2693767?hl=pl&co=GENIE.Platform%3DDesktop&oco=0'
      : 'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop&oco=0',
  LOCATION_HELP:
    HOSTNAME === REACT_APP_HOSTNAME.inewi
      ? 'https://support.google.com/chrome/answer/142065?hl=pl&co=GENIE.Platform%3DDesktop&oco=0'
      : 'https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop&oco=0',
};
