import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Text } from 'theme-ui';

import { Switch } from 'components/ui/Switch';
import { useFormContextCustomEvent } from '../../../../../hooks/useFormContextCustomEvent';
import { LabelWithBadge } from '../../../../LabelWithBadge';
import { SettingsGroup } from '../../../../SettingsGroup/SettingsGroup';

export const Reports = (): React.ReactElement => {
  useLingui();

  const { registerOnChange } = useFormContextCustomEvent();

  return (
    <SettingsGroup variant="sm">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="automations_settings.heading.reports">Reports</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <SettingsGroup.Body>
        <SettingsGroup.Body.Visible variant="md">
          <Switch
            {...registerOnChange('autoBalanceOvertime')}
            label={
              <LabelWithBadge
                label={t({ id: 'automations_settings.auto_balance_overtime.label', message: 'Auto-balance overtime' })}
                badgeText={t({ id: 'settings.scheduling' })}
                tooltipText={t({ id: 'settings.tooltip_scheduling' })}
              />
            }
            additionalInfo={
              <Text>
                <Trans id="automations_settings.auto_balance_overtime.additional_info">
                  Overtime will be automatically used to fill up missing scheduled work hours.
                </Trans>
              </Text>
            }
            size="sm"
            bold
          />
        </SettingsGroup.Body.Visible>
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
