import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { windowSizeAtom } from 'state/recoilState';

/**
 * Callback will be called every time a touchstart event fires outside of the provided element.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOnOutsideClick = <E extends (e: any) => void = (e: TouchEvent | MouseEvent) => void>(
  ref: React.RefObject<HTMLInputElement | HTMLDivElement | HTMLButtonElement | HTMLElement> | null,
  callback: E,
): void => {
  const { isMobile } = useRecoilValue(windowSizeAtom);

  useEffect(() => {
    function handleClickOutside(event: TouchEvent | MouseEvent) {
      if (!ref?.current) return;
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    }

    if (isMobile) {
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (isMobile) {
        document.removeEventListener('touchstart', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [ref, callback, isMobile]);
};
