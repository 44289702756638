import { useEffect, useState } from 'react';

import { useQuery } from 'hooks/useQuery/useQuery';

type UseSearchParam = (param: string, once?: boolean, pathname?: string) => string | null;

/**
 * Extracts a query param from the current url or provided pathname.
 * Change with caution (see: useRedirectPath).
 *
 * @param {string} param name of the param which value will be extracted end returned.
 * @param {boolean | undefined} once if true the value will not update after url/pathname changes.
 * @param {string | undefined} pathname custom pathname to use instead of current url.
 * @return {string | null} value of the provided param.
 */
export const useSearchParam: UseSearchParam = (param, once?, pathname?) => {
  const query = useQuery(pathname);
  const [value, setValue] = useState<string | null>(query.get(param));

  useEffect(() => {
    if (!once) {
      setValue(query.get(param));
    }
  }, [once, param, query]);

  return value;
};
