import { useCallback, useEffect } from 'react';

import { REFRESH_INTEGRATIONS_MESSAGE } from 'constants/settings';

import { useIntegrations } from './useIntegrations';

export const useIntegrationsBroadcast = () => {
  const { fetchIntegrations } = useIntegrations();

  const handleIntegrationsRefresh = useCallback(
    (e: MessageEvent<string>) => {
      if (e.data === REFRESH_INTEGRATIONS_MESSAGE) {
        void fetchIntegrations();
      }
    },
    [fetchIntegrations],
  );

  useEffect(() => {
    window.addEventListener('message', handleIntegrationsRefresh);

    return () => {
      window.removeEventListener('message', handleIntegrationsRefresh);
    };
  }, [handleIntegrationsRefresh]);
};
