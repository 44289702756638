/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { ListItem, StickyListContextProps } from './types';

export const StickyListContext = React.createContext<StickyListContextProps<any, any>>({});
StickyListContext.displayName = 'StickyListContext';

export const StickyListContextProvider = <ListObj extends ListItem, ColumnId extends string | number>({
  children,
  context,
}: React.PropsWithChildren<{ context: StickyListContextProps<ListObj, ColumnId> }>) => (
  <StickyListContext.Provider value={context}>{children}</StickyListContext.Provider>
);
