import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Link } from 'theme-ui';

import { Button } from 'components/ui/Buttons/Button';
import { IntegrationsLearnMoreButtonProps } from '../../../types';

type Props = IntegrationsLearnMoreButtonProps;

export const LearnMore = ({ to, isQuickbooks, ...props }: Props): React.ReactElement => {
  useLingui();

  return (
    <Button variant="grey" size="sm" shape="rounded" {...(isQuickbooks && { sx: { py: 0 } })} {...props}>
      <Link href={to} target="_blank" rel="noreferrer">
        {t({ id: 'settings.learn_more' })}
      </Link>
    </Button>
  );
};
