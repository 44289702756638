import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { withDot } from 'components/Dot/withDot';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icons } from 'components/Icon/Icon.types';
import { ElementGroup } from 'components/ui/ElementGroup';
import { INEWI_HELP, UNRUBBLE_HELP, isInewi } from 'constants/common';
import { DRAWER_ROUTES } from 'constants/memoryRoutes';
import { TO, TO_REL } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { DrawerMemoryRouter } from 'layouts/AuthorizedApp/Drawer/DrawerMemoryRouter';
import { notificationCenterAtom } from 'state/notification';
import { isOnboardingAvailableSelector } from 'state/onboarding';
import { nextPaymentDateSelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';
import { settingsPageDataSelector } from 'state/settings';
import { userDetailsSelector } from 'state/userSession';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';

import { AvatarButton } from './AvatarButton';
import { IconLink } from './IconLink';
import { NavbarTimeEventTimer } from './NavbarTimeEventTimer';
import { PaymentTicker } from './PaymentTicker';

const AvatarButtonWithDropdown = withDropdown(AvatarButton);
const IconLinkWithDot = withDot(IconLink);

export const SideMenu = React.memo(() => {
  useLingui();
  const userDetails = useRecoilValue(userDetailsSelector);
  const settingsPageData = useRecoilValue(settingsPageDataSelector);
  const language = useRecoilValue(languageSelector);
  const nextPaymentDate = useRecoilValue(nextPaymentDateSelector);
  const notifications = useRecoilValue(notificationCenterAtom);
  const isOnboardingAvailable = useRecoilValue(isOnboardingAvailableSelector);

  const { startOnboarding } = useInitialOnboarding();

  const {
    isAccountBlocked,
    isTooManyUsersBlocked,
    features: { AddEventsByVrcp, AddEventsByMobileTimeClock, AddEventsByWorkerApp, AttendanceOverview },
    systemManagement,
    isHeadAdmin,
  } = useAppPermissions();

  const { isMobileBreakpoint, breakpoint } = useThemeBreakpoint();
  const getFullName = useNameDisplayOrder();

  return (
    <Flex
      sx={{
        px: [2, null, null, 3],
        alignItems: 'center',
        flexDirection: ['row-reverse', null, null, 'row'],
        justifyContent: ['space-between', null, null, 'initial'],
        flexGrow: [1, null, null, 0],
        flexShrink: 0,
      }}
    >
      <Flex
        sx={{
          justifyContent: ['space-between', null, null, 'initial'],
          mr: [null, null, null, 2],
        }}
      >
        {systemManagement.Payments && breakpoint === MEDIA_BREAKPOINTS.XL && (
          <PaymentTicker sx={{ mr: [null, null, null, 2] }} />
        )}

        <DrawerMemoryRouter>
          {AddEventsByVrcp && !isAccountBlocked && (
            <NavbarTimeEventTimer
              className="onboarding-worktimetracker-1"
              to={DRAWER_ROUTES.DRAWER__TIME_TRACKER}
              sx={{ mr: 2 }}
            />
          )}
          <ElementGroup direction="row" showAsList marginValue={0} wrapperSx={{ gap: '2px' }}>
            {AttendanceOverview && (
              <IconLink
                to={DRAWER_ROUTES.DRAWER__ATTENDANCE_OVERVIEW}
                className="onboarding-attendanceoverview-1"
                icon="attendance"
                variant="grey"
                shape="circle"
                iconProps={{ size: 26 }}
              />
            )}
            <IconLinkWithDot
              dotProps={{ color: 'red', placement: 'TOP_RIGHT' }}
              to={DRAWER_ROUTES.DRAWER__NOTIFICATION_CENTER}
              icon="notifications"
              variant="grey"
              shape="circle"
              withDot
              showDot={notifications.length > 0}
              iconProps={{ size: 26 }}
            />
          </ElementGroup>
        </DrawerMemoryRouter>
      </Flex>

      <AvatarButtonWithDropdown
        autoInitialize
        image={userDetails?.avatarUrl}
        name={userDetails?.name}
        popperProps={{
          withPopperState: true,
          hideOnReferenceHidden: false,
        }}
        dropdownProps={{
          sx: {
            maxWidth: '240px',
          },
          links: [
            ...(!isMobileBreakpoint
              ? [
                  {
                    label:
                      (userDetails && getFullName(userDetails.name.firstName, userDetails.name.surname)) ||
                      t({
                        id: 'navbar.side_menu.user.edit.label',
                        message: 'Profile',
                      }),
                    additionalInfo: userDetails ? userDetails.email : undefined,
                    icon: 'account' as Icons,
                    to: TO.SETTINGS__USER[language],
                  },
                ]
              : []),
            {
              label: t({ id: 'navbar.side_menu.your_qr', message: 'Your QR code' }),
              icon: 'qrCodeLandscape' as Icons,
              to: TO_REL.YOUR_QR_CODE_MODAL[language],
              relative: 'path' as const,
            },
            ...(AddEventsByMobileTimeClock || AddEventsByWorkerApp
              ? [
                  {
                    label:
                      AddEventsByMobileTimeClock && AddEventsByWorkerApp
                        ? t({ id: 'navbar.side_menu.get_mobile_apps', message: 'Get mobile apps' })
                        : t({ id: 'navbar.side_menu.get_mobile_app', message: 'Get mobile app' }),
                    icon: 'download' as Icons,
                    to: TO_REL.GET_MOBILE_APPS_MODAL[language],
                    relative: 'path' as const,
                  },
                ]
              : []),
            ...(isInewi
              ? [
                  {
                    label: t({ id: 'navbar.side_menu.your_benefits', message: 'Your benefits' }),

                    icon: 'present' as Icons,
                    to: TO_REL.YOUR_BENEFITS_MODAL[language],
                    relative: 'path' as const,
                  },
                ]
              : []),
            ...(!isMobileBreakpoint
              ? [
                  ...(systemManagement.CompanySettings
                    ? [
                        {
                          prependWithDivider: true,
                          label: t({
                            id: 'navbar.side_menu.organization.settings.label',
                            message: 'Settings',
                          }),
                          additionalInfo: settingsPageData?.organization.name,
                          icon: 'settings' as Icons,
                          to:
                            (isAccountBlocked || isTooManyUsersBlocked) && isHeadAdmin
                              ? TO.SETTINGS__ORGANIZATION__ADVANCED[language]
                              : TO.SETTINGS__ORGANIZATION[language],
                        },
                      ]
                    : []),
                ]
              : [
                  {
                    label: t({
                      id: 'navbar.side_menu.settings',
                      message: 'Settings',
                    }),
                    icon: 'settings' as Icons,
                    to:
                      (isAccountBlocked || isTooManyUsersBlocked) && systemManagement.CompanySettings && isHeadAdmin
                        ? TO.SETTINGS__ORGANIZATION__ADVANCED[language]
                        : TO.SETTINGS[language],
                    prependWithDivider: true,
                  },
                ]),
            ...(systemManagement.Payments
              ? [
                  {
                    prependWithDivider: !systemManagement.CompanySettings || isMobileBreakpoint,
                    label: t({
                      id: 'navbar.side_menu.organization.billing.label',
                      message: 'Plan and billing',
                    }),
                    additionalInfo: nextPaymentDate
                      ? t({
                          id: 'navbar.side_menu.organization.billing.additional_info',
                          message: `Next payment on ${nextPaymentDate}`,
                        })
                      : undefined,
                    icon: 'card' as Icons,
                    to: TO.PAYMENTS[language],
                  },
                ]
              : []),
            ...(isOnboardingAvailable && !isAccountBlocked && !isTooManyUsersBlocked
              ? [
                  {
                    prependWithDivider: true,
                    label: t({ id: 'onboarding.show_onboarding', message: 'Show onboarding' }),
                    icon: 'helpOnboarding' as Icons,
                    onClick: startOnboarding,
                  },
                ]
              : []),
            {
              label: t({
                id: 'navbar.side_menu.help_center',
                message: 'Help Center',
              }),
              icon: 'help' as Icons,
              onClick: () => {
                const helpUrl = isInewi ? INEWI_HELP : UNRUBBLE_HELP;
                window.open(helpUrl, '_blank');
              },
            },
            {
              prependWithDivider: true,
              label: t({
                id: 'navbar.side_menu.user.sign_out',
                message: 'Sign out',
              }),
              icon: 'signOut',
              to: TO.SIGN_OUT[language],
            },
          ],
        }}
      />
    </Flex>
  );
});
