import { FC, useState } from 'react';
import { Flex, Image as ThemeUIImage, ImageProps as ThemeUIImageProps, ThemeUIStyleObject } from 'theme-ui';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { LoadingSpinnerSize } from 'components/Loading/LoadingSpinnerCSS';

export type ImageProps = ThemeUIImageProps & {
  loadingOverlaySize?: number;
  loadingOverlayBackground?: string;
  wrapperSx?: ThemeUIStyleObject;
};

const ImageInner: FC<ImageProps> = ({
  sx,
  wrapperSx,
  loadingOverlaySize = LoadingSpinnerSize.imageMD,
  loadingOverlayBackground = 'none',
  onLoad,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Flex sx={{ justifyContent: 'center', alignItems: 'center', position: 'relative', ...wrapperSx }}>
      {isLoading && (
        <LoadingOverlay sx={{ zIndex: 'base' }} size={loadingOverlaySize} background={loadingOverlayBackground} />
      )}
      <ThemeUIImage
        key={props.src}
        onLoad={(e) => {
          onLoad?.(e);
          setIsLoading(false);
        }}
        {...props}
        sx={{ height: '100%', ...sx }}
      />
    </Flex>
  );
};

//  forcing key change allows us to reset loading state
export const Image: FC<ImageProps> = (props) => <ImageInner key={props.src} {...props} />;
