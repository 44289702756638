import React from 'react';
import { t } from '@lingui/macro';
import { Text } from 'theme-ui';

import { SnackbarProps } from 'components/Snackbar/types';

export const getRefreshSnackbarProps = (handleFormReset: () => void): SnackbarProps => ({
  message: (
    <Text sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: 2 }}>
      {t({
        id: 'settings.error_form_changed',
        message: 'Somebody else made changes to this form. Refresh the page to download the changes.',
      })}
    </Text>
  ),
  duration: 60000,
  variant: 'warning',
  isRouteBound: true,
  action: {
    title: t({
      id: 'settings.error_form_changed.refresh',
      message: 'Refresh',
    }),
    onClickCallback: () => {
      handleFormReset();
    },
  },
});
