import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEqual } from 'lodash';
import { FC, useMemo } from 'react';
import { Flex, Text } from 'theme-ui';

import { ParsedRequest } from 'state/requests';
import { getDurationObj } from '../../../../../../../../utils/getDurationObj';
import { useRequestDetails } from '../../../../../hooks/useRequestDetails';
import {
  newComparisonDifferenceSx,
  originalComparisonDifferenceSx,
  requestChangeTextEventSx,
  requestChangeTextTileSx,
  requestChangeWrapperSx,
} from '../styles/styles';

type Props = {
  requestDetails: ParsedRequest | null;
};

const OriginalDuration: FC<Props> = ({ requestDetails }) => {
  useLingui();

  const { days, hours, minutes } = useMemo(
    () => getDurationObj(requestDetails?.originalEvent?.requestDateTimeDetails.duration || 0),
    [requestDetails?.originalEvent?.requestDateTimeDetails.duration],
  );

  return (
    <Text
      sx={{
        ...requestChangeTextEventSx,
        ...(requestDetails?.newEvent &&
          !isEqual(
            requestDetails?.newEvent?.requestDateTimeDetails.duration,
            requestDetails?.originalEvent?.requestDateTimeDetails.duration,
          ) &&
          originalComparisonDifferenceSx),
      }}
    >
      {days ? `${days} ${t({ id: days > 1 ? 'days' : 'day' })} ` : ''}
      {hours ? `${hours} ${t({ id: hours > 1 ? 'hours' : 'hour' })} ` : ''}
      {minutes ? `${minutes} ${t({ id: minutes > 1 ? 'minutes' : 'minute' })}` : ''}
    </Text>
  );
};

const NewDuration: FC<Props> = ({ requestDetails }) => {
  useLingui();

  const { days, hours, minutes } = useMemo(
    () => getDurationObj(requestDetails?.newEvent?.requestDateTimeDetails.duration || 0),
    [requestDetails?.newEvent?.requestDateTimeDetails.duration],
  );

  return (
    <Text
      sx={{
        ...requestChangeTextEventSx,
        ...(requestDetails?.originalEvent &&
          !isEqual(
            requestDetails?.newEvent?.requestDateTimeDetails.duration,
            requestDetails?.originalEvent?.requestDateTimeDetails.duration,
          ) &&
          newComparisonDifferenceSx),
      }}
    >
      {days ? `${days} ${t({ id: days > 1 ? 'days' : 'day' })} ` : ''}
      {hours ? `${hours} ${t({ id: hours > 1 ? 'hours' : 'hour' })} ` : ''}
      {minutes ? `${minutes} ${t({ id: minutes > 1 ? 'minutes' : 'minute' })}` : ''}
    </Text>
  );
};

export const RequestChangeDuration: FC = () => {
  const { parsedRequestDetails: requestDetails } = useRequestDetails();

  if (
    !requestDetails?.originalEvent?.requestDateTimeDetails.duration &&
    !requestDetails?.newEvent?.requestDateTimeDetails.duration
  )
    return null;

  return (
    <Flex sx={requestChangeWrapperSx}>
      <Text sx={requestChangeTextTileSx}>{t({ id: 'requests.duration' })}</Text>
      <OriginalDuration requestDetails={requestDetails} />
      <NewDuration requestDetails={requestDetails} />
    </Flex>
  );
};
