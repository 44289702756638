import { Trans } from '@lingui/macro';
import { isValidNumber } from 'libphonenumber-js';
import React, { useCallback, useEffect } from 'react';
import { useClient, useMutation } from 'react-fetching-library';
import TagManager from 'react-gtm-module';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { postEmployerAccountInfoAction } from 'api/actions/user/userActions';
import { PostEmployerAccountInfoErrors, PostEmployerAccountInfoPayload } from 'api/actions/user/userActions.types';
import { fetchUserSessionAction } from 'api/actions/userSession/userSessionActions';
import { signIn } from 'broadcastChannel/channelActionCreators/channelActionCreators';
import { FormCard } from 'components/FormCard/FormCard';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { TO } from 'constants/routes';
import { setAuthorized, setEmail, setIsSignUpInProgress } from 'context/auth/authActionCreators/authActionCreators';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { broadcastChannel } from 'observers/BroadcastChannelObserver';
import { languageSelector } from 'state/recoilState';
import { signUpFormAtom } from 'state/signUp';
import { userSessionAtom } from 'state/userSession';

export const EmployerStep3 = (): React.ReactElement => {
  const navigate = useAppNavigate();
  const language = useRecoilValue(languageSelector);
  const setUserSession = useSetRecoilState(userSessionAtom);
  const signUpFormState = useRecoilValue(signUpFormAtom);
  const resetSignUpForm = useResetRecoilState(signUpFormAtom);
  const authState = useAuthState();

  const { mutate } = useMutation(postEmployerAccountInfoAction);
  const { query } = useClient();

  const dispatch = useAuthDispatch();

  const handleSubmit = useCallback(async () => {
    if (
      signUpFormState &&
      signUpFormState.firstName &&
      signUpFormState.lastName &&
      signUpFormState.companyName &&
      signUpFormState.countryCode &&
      signUpFormState.industry &&
      signUpFormState.timeZoneId
    ) {
      const postData: PostEmployerAccountInfoPayload = {
        ...signUpFormState,
        phoneNumber:
          !!signUpFormState.phoneNumber && isValidNumber(signUpFormState.phoneNumber)
            ? signUpFormState.phoneNumber
            : undefined,
        industrySubcategory: signUpFormState.industrySubcategory ? signUpFormState.industrySubcategory : undefined,
        industry: signUpFormState.industry,
        countryCode: signUpFormState.countryCode,
        timeZoneId: signUpFormState.timeZoneId,
        firstName: signUpFormState.firstName,
        lastName: signUpFormState.lastName,
        companyName: signUpFormState.companyName,
      };

      const { payload, error } = await mutate(postData);

      if (error) {
        // GTM DATA LAYER (SIGNUP-FAIL)
        TagManager.dataLayer({
          dataLayer: {
            event: 'signup-fail',
            method: 'email',
          },
        });
      }

      if (!error) {
        const { payload: userinfo, error: fetchError } = await query(fetchUserSessionAction());

        // GTM DATA LAYER (SIGNUP-SUCCESS)
        TagManager.dataLayer({
          dataLayer: {
            event: 'signup-success',
            method: 'email',
            ...(userinfo &&
              !fetchError && {
                user_id: userinfo.personId,
              }),
            company_name: postData.companyName,
            email: authState.email,
          },
        });

        if (userinfo && !fetchError) {
          setUserSession(userinfo);
        }

        resetSignUpForm();
        dispatch(setEmail(null));
        dispatch(setIsSignUpInProgress(false));
        dispatch(setAuthorized());

        return true;
      }

      if (payload?.innerCode === PostEmployerAccountInfoErrors.InvalidTimeZoneId) {
        navigate(`${TO.SIGN_UP__EMPLOYER__STEP[language]}/2`, {
          state: {
            signUp: {
              inProgress: true,
            },
          },
        });
      }
    }

    return false;
  }, [authState.email, dispatch, language, mutate, navigate, query, resetSignUpForm, setUserSession, signUpFormState]);

  useEffect(() => {
    void handleSubmit().then(() => {
      void broadcastChannel?.postMessage(signIn());
      navigate(TO.START[language]);
    });
  }, [handleSubmit, language, navigate]);

  return (
    <>
      <Flex
        sx={{
          flexGrow: 1,
          minHeight: '400px',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <LoadingSpinnerCss size={5} />
        <Trans id="sign_up.employer.preparing_app">
          <FormCard.Lead>We're preparing your app.</FormCard.Lead>
          <FormCard.Lead>Please bear with us for a while.</FormCard.Lead>
        </Trans>
      </Flex>
    </>
  );
};
