import { atom, selector } from 'recoil';

import { MEDIA_BREAKPOINTS } from 'styles/theme/base';

import { windowSizeAtom } from './recoilState';
import { userSessionAtom } from './userSession';

type onboardingAtomType = {
  isVisible: boolean;
  stepsLength?: number;
  activeStep: number;
};

export const onboardingAtom = atom<onboardingAtomType>({
  key: 'onboarding',
  default: {
    isVisible: false,
    activeStep: 1, // 0 is reserved for a warning message
  },
});

export const isFirstOnboardingAtom = atom<boolean>({
  key: 'isFirstOnboardingAtom',
  default: false,
});

// info from userSessionAtom
export const showInitialOnboardingSelector = selector<boolean>({
  key: 'onboarding__showInitialOnboarding',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    return userSession?.showTutorial ?? false;
  },
});

export const isNewAccountOnboardingAtom = atom<boolean>({
  key: 'isNewAccountOnboarding',
  default: false,
});

export const isOnboardingAvailableSelector = selector<boolean>({
  key: 'isOnboardingAvailable',
  get: ({ get }) => {
    const { isMobile, breakpoint } = get(windowSizeAtom);
    const isMobileBreakpoint =
      breakpoint === MEDIA_BREAKPOINTS.XS || breakpoint === MEDIA_BREAKPOINTS.SM || breakpoint === MEDIA_BREAKPOINTS.MD;

    return !isMobile && !isMobileBreakpoint;
  },
});
