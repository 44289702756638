import { Plural, Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { printQrCardsAction } from 'api/actions/employees/employeesActions';
import { Avatar } from 'components/Avatar/Avatar';
import { Badge } from 'components/Badge/Badge';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { ElementGroup } from 'components/ui/ElementGroup';
import { Switch } from 'components/ui/Switch';
import { useSelectedIdsModalLogic } from 'hooks/useSelectedIdsModalLogic/useSelectedIdsModalLogic';
import { parsedEmployeesSelector } from 'state/employees';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { ReactComponent as QrCodeExampleSvg } from '../../svg/QrCodeExample.svg';

const badgeSx: ThemeUIStyleObject = { mt: 1, flexShrink: 0, textTransform: 'none', fontWeight: 'normal' };

export const PrintQrCardsModal: FC = () => {
  useLingui();
  const { baseRoute, handleClose } = useModal();
  const employees = useRecoilValue(parsedEmployeesSelector);
  const {
    selectedIds,
    selectedNames,
    idsCount: count,
    isMulti,
    loading,
    setLoading,
  } = useSelectedIdsModalLogic(employees, ['name.firstName', 'name.surname']);

  const [showFirstName, setShowFirstName] = useState(true);
  const [showLastName, setShowLastName] = useState(true);
  const [showTags, setShowTags] = useState(true);
  const [showPhoto, setShowPhoto] = useState(true);

  const { mutate } = useMutation(printQrCardsAction);

  const handlePrint = async () => {
    setLoading(true);

    const { error: submitError } = await mutate({
      employeesIds: selectedIds,
      showFirstName,
      showLastName,
      showTags,
      showPhoto,
    });

    if (!submitError) {
      if (handleClose) {
        handleClose();
      }
    }
    setLoading(false);
  };

  if (!selectedIds.length) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Plural id="team.print_qr" value={count} one={`Print QR card - ${selectedNames[0]}`} other="Print QR cards" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body sx={{ flexDirection: ['column', 'column', 'row'], gap: 4 }}>
        <Flex sx={{ flex: '1 1 0', justifyContent: 'center', alignItems: 'center' }}>
          <Flex
            sx={{
              boxShadow: 'qrCard',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 4,
              px: 3,
              borderRadius: '16px',
              color: 'team.qrCard',
              height: '162px',
            }}
          >
            <Flex sx={{ flexDirection: 'column', width: '100px', alignItems: 'center' }}>
              {showPhoto && <Avatar size={65} />}
              {(showTags || showLastName || showFirstName) && (
                <ElementGroup
                  marginValue="2px"
                  direction="row"
                  showAsList
                  wrapperSx={{ mt: 1, width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}
                >
                  {[
                    ...(showFirstName
                      ? [
                          <Badge key="first-name" sx={badgeSx}>
                            <Trans id="team.qr.first_name_name">First{showLastName ? '' : ' name'}</Trans>
                          </Badge>,
                        ]
                      : []),
                    ...(showLastName
                      ? [
                          <Badge key="last-name" sx={badgeSx}>
                            <Trans id="team.qr.last_name">Last name</Trans>
                          </Badge>,
                        ]
                      : []),
                    ...(showTags
                      ? [
                          <Badge key="tags" sx={badgeSx}>
                            <Trans id="team.qr.tags">Tags</Trans>
                          </Badge>,
                        ]
                      : []),
                  ]}
                </ElementGroup>
              )}
            </Flex>

            <QrCodeExampleSvg />
          </Flex>
        </Flex>

        <Flex sx={{ flex: '1 1 0', justifyContent: 'center', flexDirection: 'column' }}>
          {isMulti && (
            <>
              <Flex sx={{ justifyContent: 'space-between' }}>
                <Text>
                  <Trans id="team.qr.user_no">No. of selected users</Trans>
                </Text>
                <Text>{selectedIds.length}</Text>
              </Flex>
              <Flex sx={{ justifyContent: 'space-between', mt: 2, mb: 4 }}>
                <Text>
                  <Trans id="team.qr.pages_no">No. of pages</Trans>
                </Text>
                <Text>{Math.ceil(selectedIds.length / 10)}</Text>
              </Flex>
            </>
          )}
          <Switch
            checked={showFirstName}
            onChange={(e) => setShowFirstName(e.target.checked)}
            bold
            placement="right"
            size="sm"
            name="2"
            label={t({ id: 'team.qr.show_first_name', message: 'Show first name' })}
          />
          <Switch
            checked={showLastName}
            onChange={(e) => setShowLastName(e.target.checked)}
            bold
            wrapperSx={{ mt: 2 }}
            placement="right"
            size="sm"
            name="2"
            label={t({ id: 'team.qr.show_last_name', message: 'Show last name' })}
          />
          <Switch
            checked={showTags}
            onChange={(e) => setShowTags(e.target.checked)}
            bold
            wrapperSx={{ mt: 2 }}
            placement="right"
            size="sm"
            name="2"
            label={t({ id: 'team.qr.show_tags', message: 'Show tags' })}
          />
          <Switch
            checked={showPhoto}
            onChange={(e) => setShowPhoto(e.target.checked)}
            bold
            wrapperSx={{ mt: 2 }}
            placement="right"
            size="sm"
            name="2"
            label={t({ id: 'team.qr.show_photo', message: 'Show photo' })}
          />
        </Flex>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: floatingPromiseReturn(handlePrint),
            variant: 'primary',
            children: t({ id: 'global.print', message: 'Print' }),
          },
        ]}
      />
    </>
  );
};
