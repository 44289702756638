import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { dateRangeFilterAtom } from 'state/filters';
import { requestsAtom } from 'state/requests';

import { useRequests } from './useRequests';

export const useRefreshRequests = () => {
  const dates = useRecoilValue(dateRangeFilterAtom);
  const requests = useRecoilValue(requestsAtom);

  const { fetchRequests, fetchRequestsNoThrottle } = useRequests(dates);

  const updateRequests = useCallback(() => {
    fetchRequests();
  }, [fetchRequests]);

  const updateRequestsNoThrottle = useCallback(
    async (unAbortable?: boolean) => {
      await fetchRequestsNoThrottle(unAbortable);
    },
    [fetchRequestsNoThrottle],
  );

  return {
    updateRequests,
    updateRequestsNoThrottle,
    requestsInitialized: !!requests,
  };
};
