import { t } from '@lingui/macro';
import _ from 'lodash';
import { UseFormGetValues } from 'react-hook-form';
import { atom, selector, selectorFamily } from 'recoil';

import { Location } from 'api/actions/location/locationActions.types';
import { Employee, TimeEventType } from 'api/actions/organizationSession/organizationSessionActions.types';
import {
  ClockLog,
  EventDayNumber,
  ExtendedTimeEvent,
  FetchEventWithDetailsResponse,
  FraudDetectionState,
  HolidayDayNumber,
  HolidayName,
  TimeEvent,
  TimeEventActionResponse,
  TimeEventState,
} from 'api/actions/timeEvent/timeEventActions.types';
import { ClockLogHideableColumns } from 'api/actions/userSession/userSessionActions.types';
import { ErrorResponse } from 'api/types';
import { ListNames, StickyListProps } from 'components/StickyList/types';
import { WORK_STATUS_TYPE_ID } from 'constants/clockLog';
import { DEFAULT_WORK_STATUSES_IDS } from 'constants/common';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import {
  FilterGroupNames,
  LocationDefaultFilters,
  TagsFilters,
  TeammatesFilters,
  WorkStatusSendVia,
  WorkStatusType,
} from 'layouts/AuthorizedApp/AsideFilters/types';
import { AddTimeEventsInput } from 'pages/ClockLog/output/types';

import { filteredEmployeesSelector, ParsedEmployee, parsedEmployeesSelector } from './employees';
import { filterGroupStateAtomFamily, parsedSearchFilterValueSelectorFamily, SEARCH_FILTER_TYPE } from './filters';
import { selectedRowsIdsSelectorFamily } from './list';
import { locationsAtom } from './locations';
import { organizationSessionAtom } from './organizationSession';
import { userSessionAtom } from './userSession';
import { workStatusesSelector } from './workStatuses';

export const clockLogResponseAtom = atom<ClockLog | ErrorResponse | null>({
  key: 'clockLogResponse',
  default: null,
});

const clockLogSelector = selector<ClockLog | null>({
  key: 'clockLog',
  get: ({ get }) => {
    const clockLogResponse = get(clockLogResponseAtom);

    if (!clockLogResponse || !('timeEvents' in clockLogResponse)) return null;

    return clockLogResponse;
  },
});

const clockLogErrorSelector = selector<ErrorResponse | null>({
  key: 'clockLogError',
  get: ({ get }) => {
    const clockLogResponse = get(clockLogResponseAtom);

    if (!clockLogResponse || !('innerCode' in clockLogResponse)) return null;

    return clockLogResponse;
  },
});

export const antiSpoofingChecksAtom = atom<TimeEvent[] | null>({
  key: 'antiSpoofingChecks',
  default: null,
});

export const hiddenColumnsClockLogViewSelector = selector<ClockLogHideableColumns[]>({
  key: 'hiddenColumnsClockLog',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    const hiddenColumns = userSession?.viewsSettings?.clockLog?.hiddenColumns;

    return hiddenColumns || [];
  },
});

export const allTimeEventsSelector = selector<TimeEvent[] | null>({
  key: 'allTimeEvents',
  get: ({ get }) => {
    const clockLog = get(clockLogSelector);
    const clockLogError = get(clockLogErrorSelector);

    if (!clockLog && clockLogError && clockLogError.innerCode === ErrorInnerCodes.DateRangeAccessDenied) {
      return [];
    }

    if (!clockLog) return null;

    const allTimeEvents = clockLog.timeEvents?.map((eventArray) => eventArray[1]);

    if (!allTimeEvents) return null;

    return allTimeEvents;
  },
});

export const allTimeEventsMapSelector = selector<Map<TimeEvent['id'], TimeEvent> | null>({
  key: 'allTimeEventsMap',
  get: ({ get }) => {
    const clockLog = get(clockLogSelector);
    const clockLogError = get(clockLogErrorSelector);

    if (!clockLog && clockLogError && clockLogError.innerCode === ErrorInnerCodes.DateRangeAccessDenied) {
      return new Map();
    }

    if (!clockLog) return null;

    const allTimeEvents = clockLog.timeEvents?.map((eventArray) => eventArray[1]);

    if (!allTimeEvents) return null;

    return new Map(allTimeEvents.map((event) => [event.id, event]));
  },
});

export const photoLogHolidaysSelector = selector<Map<HolidayDayNumber, HolidayName> | null>({
  key: 'photoLogHolidays',
  get: ({ get }) => {
    const timeEvents = get(clockLogSelector);

    if (!timeEvents) return null;

    const { holidays } = timeEvents;
    const holidaysMap = new Map(holidays);

    return holidaysMap;
  },
});

export const photoLogDaysSelector = selector<ClockLog['days'] | null>({
  key: 'photoLogDays',
  get: ({ get }) => {
    const timeEvents = get(clockLogSelector);

    if (!timeEvents) return null;

    const { days } = timeEvents;

    return days;
  },
});

export type ClockLogListType =
  | 'all'
  | 'photoLog'
  | 'modified'
  | 'pending'
  | 'AIAntiSpoofing'
  | 'AIRejected'
  | 'deleted';

export type ClockLogMap = Map<ExtendedTimeEvent['id'], ExtendedTimeEvent>;

export type ExtTimeEventsSelectorListType = 'all' | 'antiSpoofingChecks' | 'photoLog';

type ClockLogEmployee = Pick<ParsedEmployee, 'id' | 'avatarUrl' | 'name' | 'tags' | 'role' | 'editablePermissions'>;

export type ExtendedEventWithPhotoUrl = ExtendedTimeEvent & Required<Pick<ExtendedTimeEvent, 'photoUrl'>>;

export type PhotoLogEmployeeData = {
  days: Map<EventDayNumber, ExtendedEventWithPhotoUrl[]>;
} & ClockLogEmployee;

export type PhotoLogMap = Map<Employee['id'], PhotoLogEmployeeData>;

export const extTimeEventsSelectorFamily = selectorFamily<
  Map<ExtendedTimeEvent['id'], ExtendedTimeEvent> | null,
  ExtTimeEventsSelectorListType
>({
  key: 'extendedTimeEvents',
  get:
    (listType) =>
    ({ get }) => {
      const timeEvents = get(listType === 'antiSpoofingChecks' ? antiSpoofingChecksAtom : allTimeEventsSelector);
      const organizationSession = get(organizationSessionAtom);
      const employees = get(parsedEmployeesSelector);

      if (!organizationSession || !employees || !timeEvents) return null;

      const { timeEventTypes } = organizationSession;

      const extendedTimeEvents = new Map();

      timeEvents.forEach((timeEvent) => {
        const { personId, typeId } = timeEvent;
        const employeeDetails = employees.get(personId);
        const typeName = timeEventTypes.find((type) => type.id === typeId)?.name;

        if (!employeeDetails || !typeName) return;

        const { isActive, isHidden, role, tags, avatarUrl, invitationState, name } = employeeDetails;

        extendedTimeEvents.set(timeEvent.id, {
          isActive,
          isHidden,
          role,
          tags,
          ...(avatarUrl && { avatarUrl }),
          ...(invitationState && { invitationState }),
          employeeName: name,
          typeName,
          ...timeEvent,
        });
      });

      return extendedTimeEvents;
    },
});
const defaultBreakIds = [DEFAULT_WORK_STATUSES_IDS.PAID_BREAK, DEFAULT_WORK_STATUSES_IDS.UNPAID_BREAK];

// FIXME: JOTAI MIGRATION
export const filteredExtTimeEventsSelector = selector<Map<ExtendedTimeEvent['id'], ExtendedTimeEvent> | null>({
  key: 'filteredExtendedTimeEvents',
  get: ({ get }) => {
    const extTimeEvents = get(extTimeEventsSelectorFamily('all'));
    const organizationSession = get(organizationSessionAtom);
    if (!extTimeEvents) return null;

    const searchFilterState = get(parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.CLOCK_LOG__TIME_EVENTS));
    const workStatusesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.WORK_STATUSES));
    const locationFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.LOCATIONS));
    const teammatesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES));
    const rolesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.ROLES));
    const tagsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TAGS));
    const workStatusesSendVia = get(filterGroupStateAtomFamily(FilterGroupNames.WORK_STATUS_SEND_VIA));
    const workStatusTypes = get(filterGroupStateAtomFamily(FilterGroupNames.WORK_STATUS_TYPE));
    const showOnlyOfflineEvents = get(filterGroupStateAtomFamily(FilterGroupNames.SHOW_ONLY_OFFLINE_EVENTS));

    const filterByWorkStatusType = (extTimeEvent: ExtendedTimeEvent) => {
      if (!workStatusTypes?.length) return true;
      const timeEventTypeMap = organizationSession?.timeEventTypeMap;
      const workStatus = timeEventTypeMap?.get(extTimeEvent.typeId);

      let valid = false;
      if (workStatus) {
        workStatusTypes.forEach((workStatusType) => {
          if (!valid) {
            switch (workStatusType as WorkStatusType) {
              case WorkStatusType.Breaks:
                valid = defaultBreakIds.includes(workStatus.id);
                break;
              // TODO uncomment when tasks will be done
              // case WorkStatusType.Tasks:
              //   valid = !!workStatus.isTask;
              //   break;
              case WorkStatusType.Statuses:
                // valid = !workStatus.isDefault && !workStatus.isTask;
                valid = !workStatus.isDefault;
                break;
              default:
                valid = true;
                break;
            }
          }
        });
      }
      return valid;
    };
    const filterBySearchInput = (extTimeEvent: ExtendedTimeEvent): boolean => {
      if (_.isEmpty(searchFilterState)) return true;

      const {
        note,
        device,
        employeeName: { firstName, surname },
        role: { name: roleName },
        typeName,
        location,
        tags,
      } = extTimeEvent;
      const tagNames = tags?.map((tag) => tag?.name) || [];

      const searchableTimeEvent: (string | undefined)[] = [
        note,
        device?.name,
        location?.name,
        firstName,
        surname,
        `${firstName} ${surname}`,
        `${surname} ${firstName}`,
        t({ id: roleName }),
        t({ id: typeName }),
        ...tagNames,
      ];

      const valid = searchFilterState.some((searchableQuery) =>
        searchableTimeEvent.some((searchableValue) => {
          if (!searchableValue) return false;
          return searchableValue.toLocaleLowerCase().includes(searchableQuery.toLocaleLowerCase());
        }),
      );

      return valid;
    };

    const filterByTeammates = (extTimeEvent: ExtendedTimeEvent) => {
      if (teammatesFilterState === null) return true;

      let valid = false;

      if (teammatesFilterState.includes(TeammatesFilters.ACTIVE)) {
        valid = valid || !!extTimeEvent.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.HIDDEN)) {
        valid = valid || !!extTimeEvent.isHidden;
      }
      if (teammatesFilterState.includes(TeammatesFilters.DEACTIVATED)) {
        valid = valid || !extTimeEvent.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.INVITED)) {
        valid = valid || !!extTimeEvent.invitationState;
      }

      return valid;
    };

    const filterByWorkStatus = (extTimeEvent: ExtendedTimeEvent) => {
      if (!workStatusesFilterState) return true;
      if (workStatusesFilterState.map((wS) => wS.toLocaleLowerCase()).includes(extTimeEvent.typeId.toLocaleLowerCase()))
        return true;
      return false;
    };
    const filterBySendVia = (extTimeEvent: ExtendedTimeEvent) => {
      if (!workStatusesSendVia) return true;
      if (
        extTimeEvent.device?.id &&
        (workStatusesSendVia
          .map((id) => WORK_STATUS_TYPE_ID[id as WorkStatusSendVia])
          .filter(Boolean)
          .includes(extTimeEvent.device.id.toLocaleLowerCase()) ||
          (workStatusesSendVia.includes(WorkStatusSendVia.MobileTimeClock) &&
            !Object.values(WORK_STATUS_TYPE_ID).includes(extTimeEvent.device.id.toLocaleLowerCase())))
      )
        return true;
      return false;
    };

    const filterByLocation = (extTimeEvent: ExtendedTimeEvent) => {
      if (!locationFilterState) return true;
      if (locationFilterState.includes(LocationDefaultFilters.Without) && !extTimeEvent.location) return true;
      if (locationFilterState.includes(LocationDefaultFilters.Any) && extTimeEvent.location) return true;
      if (
        locationFilterState
          .map((l) => l.toLocaleLowerCase())
          .includes(extTimeEvent?.location?.id?.toLocaleLowerCase() || '')
      )
        return true;
      return false;
    };

    const filterByRole = (extTimeEvent: ExtendedTimeEvent) => {
      if (!rolesFilterState) return true;
      if (rolesFilterState.map((r) => r.toLocaleLowerCase()).includes(`${extTimeEvent?.role?.id.toLocaleLowerCase()}`))
        return true;
      return false;
    };

    const filterByTags = (extTimeEvent: ExtendedTimeEvent) => {
      if (!tagsFilterState) return true;

      if (tagsFilterState.includes(TagsFilters.NO_TAGS) && extTimeEvent?.tags?.length === 0) return true;

      for (let i = 0; i < (extTimeEvent?.tags?.length || 0); i += 1) {
        if (
          tagsFilterState
            .map((tag) => tag.toLocaleLowerCase())
            .includes(extTimeEvent?.tags?.[i]?.id.toLocaleLowerCase() || '')
        )
          return true;
      }

      return false;
    };
    const filterByOfflineMode = (extTimeEvent: ExtendedTimeEvent) => {
      if (!showOnlyOfflineEvents) return true;
      if (extTimeEvent?.isOffline) return true;
      return false;
    };

    const filteredExtTimeEvents: ClockLogMap = new Map();

    extTimeEvents.forEach((eTE) => {
      if (!filterBySearchInput(eTE)) return;
      if (!filterByTeammates(eTE)) return;
      if (!filterByWorkStatus(eTE)) return;
      if (!filterByLocation(eTE)) return;
      if (!filterByRole(eTE)) return;
      if (!filterByTags(eTE)) return;
      if (!filterBySendVia(eTE)) return;
      if (!filterByWorkStatusType(eTE)) return;
      if (!filterByOfflineMode(eTE)) return;

      filteredExtTimeEvents.set(eTE.id, eTE);
    });

    return filteredExtTimeEvents;
  },
});

export const photoLogEmployeesSelector = selector<PhotoLogMap | null>({
  key: 'photo_log_employees',
  get: ({ get }) => {
    const clockLog = get(clockLogSelector);
    const clockLogError = get(clockLogErrorSelector);

    if (!clockLog && clockLogError && clockLogError.innerCode === ErrorInnerCodes.DateRangeAccessDenied) {
      return new Map();
    }

    if (!clockLog) return null;

    const { employeesData: employeesDaysWithEventIds } = clockLog;
    const extendedTimeEvents = get(extTimeEventsSelectorFamily('all'));

    const employeesMap = get(filteredEmployeesSelector);

    if (!employeesDaysWithEventIds || !extendedTimeEvents || !employeesMap) return null;

    const employeesDaysWithEventIdsMap = new Map(employeesDaysWithEventIds);
    const photoLogMap = new Map();

    employeesMap.forEach((data, employeeId) => {
      const { name, role, tags, avatarUrl, editablePermissions } = data;
      const employeeDaysWithEventIds = employeesDaysWithEventIdsMap.get(employeeId);

      if (!employeeDaysWithEventIds) return;

      const daysWithIdsMap = new Map(employeeDaysWithEventIds.days);
      const daysWithEventsMap = new Map();

      daysWithIdsMap.forEach((day, dayUnix) => {
        const extendedTimeEventsForDay = day?.timeEventsIds
          .map((eventId) => extendedTimeEvents.get(eventId))
          .filter(
            (event): event is ExtendedEventWithPhotoUrl =>
              !!event && !!event.photoUrl && event.fraudDetectionState !== FraudDetectionState.ResolvedFraud,
          );
        daysWithEventsMap.set(dayUnix, extendedTimeEventsForDay);
      });

      photoLogMap.set(employeeId, {
        days: daysWithEventsMap,
        id: employeeId,
        name,
        role,
        tags,
        editablePermissions,
        ...(avatarUrl && { avatarUrl }),
      });
    });

    return photoLogMap;
  },
});

// Employees have already been filtered in filteredEmployeeSelector by search input, name, role, tags
export const filteredPhotoLogEmployeesSelector = selector<PhotoLogMap | null>({
  key: 'filtered_photo_log_employees',
  get: ({ get }) => {
    const photoLogMap = get(photoLogEmployeesSelector);
    if (!photoLogMap) return null;

    const workStatusesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.WORK_STATUSES));
    const locationsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.LOCATIONS));
    const hideUsersWithoutPhotosFilterState = get(
      filterGroupStateAtomFamily(FilterGroupNames.HIDE_USERS_WITHOUT_PHOTOS),
    );

    const filterByLocations = (event: ExtendedEventWithPhotoUrl): boolean => {
      let valid = false;

      if (_.isArray(locationsFilterState)) {
        locationsFilterState.forEach((location) => {
          valid = valid || event.location?.id === location;
        });
      }

      return valid;
    };

    const filterByWorkStatuses = (event: ExtendedEventWithPhotoUrl): boolean => {
      let valid = false;

      if (_.isArray(workStatusesFilterState)) {
        workStatusesFilterState.forEach((workStatus) => {
          valid = valid || event.typeId === workStatus;
        });
      }

      return valid;
    };

    const filteredPhotoLogMap: PhotoLogMap = new Map(photoLogMap);

    filteredPhotoLogMap.forEach((employeeData, employeeId) => {
      const { days } = employeeData;

      let passedByHasPhotoFilter = !hideUsersWithoutPhotosFilterState;
      let passedByLocationsFilter = !locationsFilterState;
      let passedByWorkStatusesFilter = !workStatusesFilterState;

      days.forEach((day) => {
        day.forEach((event) => {
          if (hideUsersWithoutPhotosFilterState) passedByHasPhotoFilter = true;
          if (locationsFilterState) passedByLocationsFilter = filterByLocations(event);
          if (workStatusesFilterState) passedByWorkStatusesFilter = filterByWorkStatuses(event);
        });
      });

      if (!passedByHasPhotoFilter || !passedByLocationsFilter || !passedByWorkStatusesFilter) {
        filteredPhotoLogMap.delete(employeeId);
      }
    });

    return filteredPhotoLogMap;
  },
});

type PersonDayEventsWithPersonData = {
  events: ExtendedEventWithPhotoUrl[];
} & Pick<ParsedEmployee, 'id' | 'name' | 'role' | 'tags' | 'avatarUrl'>;

export const personDayEventsSelectorFamily = selectorFamily<
  PersonDayEventsWithPersonData | null,
  { personId: Employee['id']; date: number }
>({
  key: 'photoLogPersonDayEvents',
  get:
    ({ personId, date }) =>
    ({ get }) => {
      const photoLogMap = get(photoLogEmployeesSelector);

      if (!photoLogMap) return null;

      const user = photoLogMap.get(personId);

      if (!user) return null;

      const { days, avatarUrl, id, name, role, tags } = user;
      const events = days.get(date);

      if (!events) return null;

      return {
        events,
        id,
        name,
        role,
        tags,
        ...(avatarUrl && { avatarUrl }),
      };
    },
});

export const filteredExtTimeEventsListTypeSelectorFamily = selectorFamily<ClockLogMap | null, ClockLogListType>({
  key: 'filteredExtTimeEventsListType',
  get:
    (listType) =>
    ({ get }) => {
      const extTimeEvents = get(filteredExtTimeEventsSelector);
      if (!extTimeEvents) return null;

      const filteredExtTimeEvents: ClockLogMap = new Map();

      extTimeEvents.forEach((eTE) => {
        if (eTE.state !== TimeEventState.Deleted) {
          if (listType === 'all' && eTE.fraudDetectionState !== FraudDetectionState.ResolvedFraud) {
            filteredExtTimeEvents.set(eTE.id, eTE);
          }

          if (listType === 'AIAntiSpoofing' && eTE.fraudDetectionState === FraudDetectionState.PotentialFraud) {
            filteredExtTimeEvents.set(eTE.id, eTE);
          }
          if (listType === 'AIRejected' && eTE.fraudDetectionState === FraudDetectionState.ResolvedFraud) {
            filteredExtTimeEvents.set(eTE.id, eTE);
          }
        }

        if (listType === 'pending' && eTE.state === TimeEventState.Pending) {
          filteredExtTimeEvents.set(eTE.id, eTE);
        }

        if (
          listType === 'modified' &&
          eTE.state === TimeEventState.Active &&
          eTE.action === TimeEventActionResponse.Modified
        ) {
          filteredExtTimeEvents.set(eTE.id, eTE);
        }

        if (listType === 'deleted' && eTE.state === TimeEventState.Deleted) {
          filteredExtTimeEvents.set(eTE.id, eTE);
        }
      });

      return filteredExtTimeEvents;
    },
});

export const outOfRangeTimeEventDetailsAtom = atom<FetchEventWithDetailsResponse | null>({
  key: 'outOfRangeTimeEventDetails',
  default: null,
});

export const extTimeEventSelector = selectorFamily<
  (ExtendedTimeEvent & { photos?: FetchEventWithDetailsResponse['photos'] }) | null,
  ExtendedTimeEvent['id']
>({
  key: 'extendedTimeEvent',
  get:
    (id) =>
    ({ get }) => {
      const extTimeEvents = get(extTimeEventsSelectorFamily('all'));
      const extTimeEvent = extTimeEvents?.get(id);

      if (extTimeEvent) return extTimeEvent;

      const outOfRangeTimeEventDetails = get(outOfRangeTimeEventDetailsAtom);
      const employees = get(parsedEmployeesSelector);
      const organizationSession = get(organizationSessionAtom);

      if (!outOfRangeTimeEventDetails || !employees || !organizationSession) return null;

      const { timeEventTypes } = organizationSession;

      const { personId, typeId, id: outOfRangeTimeEventId } = outOfRangeTimeEventDetails;

      if (outOfRangeTimeEventId !== id) return null;

      const employeeDetails = employees.get(personId);
      const typeName = timeEventTypes.find((type) => type.id === typeId)?.name;

      if (!employeeDetails || !typeName) return null;

      const { isActive, isHidden, role, tags, avatarUrl, invitationState, name } = employeeDetails;

      return {
        isActive,
        isHidden,
        role,
        tags,
        ...(avatarUrl && { avatarUrl }),
        ...(invitationState && { invitationState }),
        employeeName: name,
        typeName,
        ...outOfRangeTimeEventDetails,
      };
    },
});

export const selectedClockLogIdsSelector = selector<ExtendedTimeEvent['id'][]>({
  key: 'clock_log__selectedTimeEvents',
  get: ({ get }) => get(selectedRowsIdsSelectorFamily(ListNames.CLOCK_LOG)),
});
export const selectedPhotoLogIdsSelector = selector<ExtendedTimeEvent['id'][]>({
  key: 'photoLog__selectedPeopleIds',
  get: ({ get }) => get(selectedRowsIdsSelectorFamily(ListNames.CLOCK_LOG_PHOTO_LOG)),
});

export const hiddenSelectedEventsAtom = atom<ExtendedTimeEvent[]>({
  key: 'hiddenSelectedEvents',
  default: [],
});

export const clockLogColumnsAtom = atom<StickyListProps<ExtendedTimeEvent>['columns'] | null>({
  key: 'clockLog__columns',
  default: null,
});

export const clockLogPhotoLogColumnsAtom = atom<StickyListProps<PhotoLogEmployeeData>['columns'] | null>({
  key: 'clockLogPhotoLog__columns',
  default: null,
});

// ADD / EDIT EVENTS MODALS - save button states

export const addEventsLoadingSaveAtom = atom<boolean>({
  key: 'addEventsLoadingSave',
  default: false,
});

export const addEventsDisabledSaveAtom = atom<boolean>({
  key: 'addEventsDisabledSave',
  default: true,
});

export const editEventLoadingSaveAtom = atom<boolean>({
  key: 'editEventLoadingSave',
  default: false,
});

export const editEventDisabledSaveAtom = atom<boolean>({
  key: 'editEventDisabledSave',
  default: true,
});

export const addNoteLoadingSaveAtom = atom<boolean>({
  key: 'addNoteLoadingSave',
  default: false,
});

export const addNoteDisabledSaveAtom = atom<boolean>({
  key: 'addNoteDisabledSaveAtom',
  default: true,
});

export enum AddEventStep {
  SelectTeammates = 1,
  AddEvents = 2,
}

export const addEventsStepAtom = atom<AddEventStep>({
  key: 'addEventStep',
  default: AddEventStep.SelectTeammates,
});

export const addEventsGetFormValuesAtom = atom<UseFormGetValues<AddTimeEventsInput> | null>({
  key: 'addEventsGetFormValues',
  default: null,
});

export const addEventsFormRestoredAtom = atom<boolean>({
  key: 'addEventsFormRestored',
  default: false,
});

// MANAGE MODALS

// Manage modals: Manage work statuses

// Show deleted work statuses filter

export const showDeletedWorkStatusesFilterAtom = atom<boolean>({
  key: 'showDeletedWorkStatusesFilterAtom',
  default: false,
});

// Filtered work statuses

export const filteredWorkStatusesManageModalSelector = selector<TimeEventType[] | null>({
  key: 'filteredWorkStatusesManageModal',
  get: ({ get }) => {
    const workStatuses = get(workStatusesSelector);
    if (!workStatuses) return null;

    const filterDefaultStatuses = ({ isDefault }: TimeEventType) => !isDefault;

    const filterByShowDeleted = ({ isDeleted }: TimeEventType) => {
      const showDeleted = get(showDeletedWorkStatusesFilterAtom);

      if (isDeleted) return showDeleted;
      return true;
    };

    const filterBySearchBar = (workStatus: TimeEventType) => {
      const searchFilterAtom = parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.MANAGE_WORK_STATUSES);
      const searchFilterState = get(searchFilterAtom);
      if (_.isEmpty(searchFilterState)) return true;

      const { name } = workStatus;
      return searchFilterState.every((query) => name.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
    };

    const filteredWorkStatuses = workStatuses
      ?.filter((workStatus) => filterDefaultStatuses(workStatus))
      .filter((status) => filterByShowDeleted(status))
      .filter((status) => filterBySearchBar(status));

    return filteredWorkStatuses;
  },
});

// Manage modals: Manage locations

// Filtered locations

export const filteredLocationsManageModalSelector = selector<Location[] | undefined>({
  key: 'filteredLocationsManageModal',
  get: ({ get }) => {
    const locations = get(locationsAtom);

    const filterBySeachBar = (location: Location) => {
      const searchFilterAtom = parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.MANAGE_LOCATIONS);
      const searchFilterState = get(searchFilterAtom);
      if (_.isEmpty(searchFilterState)) return true;

      const { name, address, range } = location;
      const searchableLocation = [
        ...(name ? [name] : []),
        ...(address ? [address.postalCode, address.city, address.street] : []),
        String(range),
      ];

      let valid = false;
      valid = searchFilterState.every((searchableQuery) =>
        searchableLocation.some((searchableValue) => {
          if (!searchableValue) return false;
          return searchableValue.toLocaleLowerCase().includes(searchableQuery.toLocaleLowerCase());
        }),
      );
      return valid;
    };

    const filteredLocations = locations?.filter((location) => filterBySeachBar(location));

    return filteredLocations;
  },
});
