import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React from 'react';

import { Button, ButtonProps } from 'components/ui/Buttons';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';

type Props = ButtonProps & {
  onClickCallback?: () => void;
};

const defaultProps: Partial<Props> = {
  onClickCallback: undefined,
};

export const BackButton = ({ onClickCallback, ...props }: Props): React.ReactElement => {
  const navigate = useAppNavigate();

  const handleOnClick = () => {
    if (onClickCallback) onClickCallback();
    navigate(-1);
  };

  return (
    <Button
      onClick={handleOnClick}
      variant="minimal"
      size="lg"
      shape="rounded"
      type="button"
      aria-label={i18n._(
        t({
          id: 'global.forms.buttons.back',
          message: 'Back',
        }),
      )}
      {...props}
    >
      {t({ id: 'global.forms.buttons.back' })}
    </Button>
  );
};

BackButton.defaultProps = defaultProps;
