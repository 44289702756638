/**
 * Converts hex colors to RGBA.
 * An RGBA color value is specified with: rgba(red, green, blue, alpha).
 *
 * @param {ThemeUICSSObject['color'] | string} hex The color represended in a hex model.
 * @param {number} alpha An number that will be passed as alpha parameter to the resulting RGBA model. The alpha parameter is a number between 0.0 (fully transparent) and 1.0 (fully opaque).
 * @return {string} Returns a color represented in the RGBA model.
 */

export function hexToRgba(hex: string, alpha: number): string {
  if (!hex) return '';

  const r = parseInt(`${hex}`.slice(1, 3), 16);
  const g = parseInt(`${hex}`.slice(3, 5), 16);
  const b = parseInt(`${hex}`.slice(5, 7), 16);

  if (alpha || alpha === 0) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgba(${r}, ${g}, ${b})`;
}
