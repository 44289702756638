import React from 'react';
import { Flex } from 'theme-ui';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';

const LazyWorkStatuses = React.lazy(() =>
  import('./components/WorkStatuses/WorkStatuses').then(({ WorkStatuses }) => ({
    default: WorkStatuses,
  })),
);

export const WorkStatusesRenderer = React.memo(
  (): React.ReactElement => (
    <React.Suspense
      fallback={
        <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
          <LoadingOverlay sx={{ zIndex: 'base' }} />
        </Flex>
      }
    >
      <LazyWorkStatuses />
    </React.Suspense>
  ),
);
