import { useCallback, useMemo } from 'react';
import { useClient, useQuery } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { getAllRequestToAcceptAction } from 'api/actions/requests/requestsActions';
import { requestsToAcceptAtom } from 'state/requests';

export const useRequestsToAccept = () => {
  const [requestsToAccept, setRequestsToAccept] = useRecoilState(requestsToAcceptAtom);

  const isInitialized = useMemo(() => !!requestsToAccept, [requestsToAccept]);

  const { query: unabortableQuery } = useClient();
  const { query } = useQuery(getAllRequestToAcceptAction(), false);

  const fetchRequestsToAccept = useCallback(
    async (unAbortable?: boolean) => {
      const { payload, error } = unAbortable ? await unabortableQuery(getAllRequestToAcceptAction()) : await query();

      if (!error && payload) {
        setRequestsToAccept(payload);
      }
    },
    [query, setRequestsToAccept, unabortableQuery],
  );

  return {
    isInitialized,
    fetchRequestsToAccept,
  };
};
