import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { QueryResponse, useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';

import { userChangePasswordAction, userSetPasswordAction } from 'api/actions/settings/settingsActions';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { userSessionAtom } from 'state/userSession';
import { HeadingTip } from '../HeadingTip';

import { ChangePasswordForm } from './components/ChangePasswordForm';
import { SetPasswordForm } from './components/SetPasswordForm';

export const ManagePassword = (): React.ReactElement => {
  useLingui();
  const userSession = useRecoilValue(userSessionAtom);
  const hasPassword = !!userSession?.hasPassword;
  const { mutate: changePasswordMutate } = useMutation(userChangePasswordAction);
  const { mutate: setPasswordMutate } = useMutation(userSetPasswordAction);

  const { addSnackbar } = useSnackbar();

  const onPasswordSubmit = useCallback(
    async <D extends object>(body: D, mutation: (action: D) => Promise<QueryResponse<unknown>>) => {
      const { error: submitError } = await mutation(body);

      if (!submitError) {
        addSnackbar({
          message: t({
            id: 'settings.forms.submit_success',
          }),
          variant: 'success',
        });
        return true;
      }

      return false;
    },
    [addSnackbar],
  );

  return (
    <>
      <HeadingTip
        label={
          hasPassword
            ? t({
                id: 'settings.user.change_password',
                message: 'Change password',
              })
            : t({
                id: 'settings.user.set_password',
                message: 'Set password',
              })
        }
      />
      {hasPassword ? (
        <ChangePasswordForm onSubmit={(body) => onPasswordSubmit(body, changePasswordMutate)} />
      ) : (
        <SetPasswordForm onSubmit={(body) => onPasswordSubmit(body, setPasswordMutate)} />
      )}
    </>
  );
};
