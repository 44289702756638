import { ThemeUIStyleObject } from 'theme-ui';

import { makeTheme } from '../makeTheme';

export const SETTINGS_SPACE = {
  switchWithLabel: 2,
  preferencesTimeFormat: '0.594rem',
  automationsSwitchWithLabel: '0.656rem',
  plannedTimeSamplesHeight: '63px',
  plannedTimeSamplesMobileHeight: '93px',
  timeRoundingSamplesHeight: '105px',
};

export const settingsBorder = {
  border: '1px solid',
  borderColor: 'settings.border',
};

const verticalContainer: ThemeUIStyleObject = {
  flexDirection: 'column',
  flexGrow: 1,
};

export const settingsStyles = makeTheme({
  settings: {
    container: {
      page: {
        flexDirection: 'row',
        flexGrow: 1,
        overflow: 'hidden',
      },
      main: {
        flexGrow: 1,
        p: 4,
        flexDirection: 'column',
        gap: 4,
        overflow: 'auto',
        '&[data-content-not-visible="true"]': {
          display: 'none !important',
        },
      },
      nav: {
        gap: 3,
        p: 4,
        flexDirection: 'column',
        maxWidth: ['100%', null, null, '360px'],
        width: '100%',
        flex: '0 0 100%',
        overflow: 'auto',
        bg: 'settings.navContainer',
      },
      vertical: {
        main: {
          ...verticalContainer,
          gap: '2.25rem',
        },
        xlg: {
          ...verticalContainer,
          gap: 3,
        },
        lg: {
          ...verticalContainer,
          gap: '0.75rem',
        },
        md: {
          ...verticalContainer,
          gap: 2,
        },
        sm: {
          ...verticalContainer,
          gap: 1,
        },
        noGap: {
          ...verticalContainer,
        },
      },
    },
    elements: {
      timeSampleBoxContainer: {
        bg: 'settings.timeSampleBox.bg',
        borderBottomLeftRadius: 'sm',
        borderBottomRightRadius: 'sm',
        ...settingsBorder,
      },
      timeSampleBox: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'settings.timeSampleBox.text',
        px: '0.75rem',
        py: 3,
        fontSize: 2,
      },
      timeRounding: {
        color: 'settings.timeRounding',
        justifyContent: 'space-between',
      },
      timeSample: {
        '@media (min-width: 768px)': {
          ':first-of-type': {
            pr: '0.75rem',
          },
          ':nth-of-type(2)': {
            px: '0.75rem',
          },
          ':last-of-type': {
            pl: '0.75rem',
          },
        },
      },
      integrationBox: {
        bg: 'settings.integrationBox.bg',
        flexDirection: 'column',
        flexShrink: 0,
        width: ['100%', null, null, '300px'],
        minHeight: '180px',
        height: 'auto',
        borderRadius: 'default',
        p: 3,
        border: '2px solid',
        borderColor: 'settings.integrationBox.border',
        boxShadow: 'integrationBox',
        gap: 2,
      },
      headerWithList: {
        bg: 'settings.headerWithList',
        borderRadius: 'sm',
        padding: '0.75rem',
        ...settingsBorder,
        borderBottom: 'none',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      },
    },
  },
});
