import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { addCustomRequestTypeAction } from 'api/actions/customRequest.ts/customRequestActions';
import { AddCustomRequestTypeActionProps } from 'api/actions/customRequest.ts/customRequestActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { createEvent } from 'utils/createEvent';

import { AddEditCustomRequestForm } from './AddEditCustomRequestForm';

export const AddCustomRequestModal = (): React.ReactElement => {
  useLingui();
  const { handleClose } = useModal();
  const { mutate } = useMutation(addCustomRequestTypeAction);

  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: AddCustomRequestTypeActionProps): Promise<void> => {
      const { error: submitError } = await mutate(data);

      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
        addSnackbar({
          message: t({
            id: 'settings.custom_request.add_success',
            message: 'Added custom request',
          }),
          variant: 'success',
        });
      }

      setLoading(false);
    },
    [handleClose, mutate],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.custom_request.add">Add custom request type</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddEditCustomRequestForm ref={formRef} onSubmit={onSubmit} setLoading={setLoading} />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
