import React, { useRef } from 'react';
import { Button, Text, ThemeUIStyleObject, Flex } from 'theme-ui';

import { useTheme } from 'styles/useTheme';
import { mergeRefs } from 'utils/mergeRefs';
import { Radio } from '../Radio';

type Props = Omit<React.ComponentPropsWithoutRef<'input'>, 'size'> & {
  value: string | number;
  children?: React.ReactNode | React.ReactNode[];
  label?: string | React.ReactElement;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: 'default' | 'sm';
  additionalLabel?: string | React.ReactElement;
  disabled?: boolean;
  sx?: ThemeUIStyleObject;
  labelSx?: ThemeUIStyleObject;
  additionalLabelSx?: ThemeUIStyleObject;
  prependWidth?: React.ReactNode | React.ReactNode[];
  withRadio?: boolean;
  wrapperSx?: ThemeUIStyleObject;
  commonSx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  children: undefined,
  label: undefined,
  onClick: undefined,
  size: 'default',
  additionalLabel: undefined,
  disabled: false,
  sx: undefined,
  labelSx: undefined,
  additionalLabelSx: undefined,
  prependWidth: undefined,
  withRadio: undefined,
  wrapperSx: undefined,
  commonSx: undefined,
};

export const RadioButton = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      children,
      label,
      onClick,
      size = 'default',
      value,
      disabled,
      additionalLabel,
      sx,
      labelSx,
      additionalLabelSx,
      prependWidth,
      withRadio,
      wrapperSx,
      commonSx,
      ...props
    }: Props,
    ref,
  ): React.ReactElement => {
    const hiddenInputRef = useRef<HTMLInputElement | null>(null);
    const radioButtonRef = useRef<HTMLInputElement | null>(null);

    const { theme } = useTheme();

    const internalOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      hiddenInputRef.current?.click();
      if (withRadio) radioButtonRef.current?.click();
      if (onClick) onClick(event);
    };

    return (
      <>
        <input
          {...props}
          ref={mergeRefs([ref, hiddenInputRef])}
          type="radio"
          value={value}
          style={{ width: 0, opacity: 0, position: 'absolute' }}
          readOnly
        />
        <Button
          onClick={internalOnClick}
          variant="buttons.radioDefault"
          type="button"
          value={value}
          disabled={disabled}
          sx={{
            px: theme.buttons.sizes[size].px,
            py: theme.buttons.sizes[size].py,
            flexWrap: 'wrap',
            fontSize: `${theme.buttons.sizes[size].fontSize}`,
            cursor: 'pointer',
            ...commonSx,
            ...sx,
          }}
        >
          <Flex sx={{ flexGrow: 1, ...(wrapperSx && wrapperSx) }}>
            {prependWidth && prependWidth}
            <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
              {children || (
                <>
                  {label && (
                    <Text as="span" sx={{ ...labelSx, flexBasis: '100%' }}>
                      {label}
                    </Text>
                  )}
                  {additionalLabel && (
                    <Text as="span" sx={{ ...additionalLabelSx, flexBasis: '100%', fontWeight: '400', fontSize: 1 }}>
                      {additionalLabel}
                    </Text>
                  )}
                </>
              )}
            </Flex>
            {withRadio && (
              <Radio
                ref={radioButtonRef}
                name={`${props.name}-radio`}
                sx={{ ml: 'auto', width: 'auto', flexShrink: 0 }}
                defaultChecked={props.defaultChecked}
              />
            )}
          </Flex>
        </Button>
      </>
    );
  },
);

RadioButton.defaultProps = defaultProps;
