import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { addTimeOffTypeAction } from 'api/actions/timeoff/timeOffActions';
import { AddTimeOffTypeActionProps, UpdateTimeOffTypeActionProps } from 'api/actions/timeoff/timeOffActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { createEvent } from 'utils/createEvent';

import { AddEditTimeOffTypeForm } from './AddEditTimeOffTypeForm';

export const AddTimeOffTypeModal = (): React.ReactElement => {
  useLingui();
  const { handleClose } = useModal();
  const { mutate } = useMutation(addTimeOffTypeAction);

  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: AddTimeOffTypeActionProps | UpdateTimeOffTypeActionProps): Promise<void> => {
      const { error: submitError } = await mutate(data);

      if (!submitError) {
        if (handleClose) {
          handleClose();
        }

        addSnackbar({
          message: t({
            id: 'settings.time_off_type.add_success',
            message: 'Added time off type',
          }),
          variant: 'success',
        });
      }

      setLoading(false);
    },
    [handleClose, mutate],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.time_off_type.add">Add time off request type</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddEditTimeOffTypeForm ref={formRef} onSubmit={onSubmit} setLoading={setLoading} />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
