import _ from 'lodash';

type Feature = {
  value: boolean;
  type: number;
};

/**
 * Creates array of features based on the provided enum.
 *
 * @param {Record<string, unknown>} featureEnum Enum on the base of witch the features will be created.
 * @param {boolean} defaultValue Value that the features will be initialized with.
 * @param {number[]|undefined} valueExceptions: Enum keys of the features with value exceptions.
 *
 * @return {Feature[]} Returns an array of features.
 */

export const getDefaultFeatures = (
  featureEnum: Record<string, unknown>,
  defaultValue: boolean,
  valueExceptions?: number[],
): Feature[] =>
  Object.keys(featureEnum)
    .filter((k) => _.isNumber(+k) && !_.isNaN(+k))
    .map((k) => ({
      type: +k,
      value: defaultValue,
      ...(valueExceptions &&
        valueExceptions.find((n) => +k === +n) && {
          value: !defaultValue,
        }),
    }));
