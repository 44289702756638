import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import zxcvbnTypes from 'zxcvbn';

import { mergeRefs } from 'utils/mergeRefs';

import { TextInput, TextInputProps } from './TextInput';

let zxcvbn: (value: string, userInputs: string[]) => zxcvbnTypes.ZXCVBNResult;

const user_inputs = ['inewi', 'tracktime', 'unrubble'];

type Props = Omit<TextInputProps, 'type'> & {
  onValidError?: () => void;
  onClearError?: () => void;
  valuesToExclude?: string[];
  oldPassword?: string;
};

const defaultProps: Partial<Props> = {
  onValidError: undefined,
  onClearError: undefined,
  valuesToExclude: undefined,
  oldPassword: undefined,
};

export const PasswordInput = React.forwardRef<HTMLInputElement, Props>(
  ({ id, valuesToExclude, onValidError, onClearError, onChange, oldPassword, ...props }: Props, ref) => {
    const [zxcvbnLoading, setZxcvbnLoading] = useState(true);
    const [prevValuesToExclude, setPrevValuesToExclude] = useState(valuesToExclude || []);
    const [passwordInputError, setPasswordInputError] = useState(false);
    const [passwordInputErrorMessage, setPasswordInputErrorMessage] = useState<string | undefined>(undefined);
    const [passwordInputPositive, setPasswordInputPositive] = useState(false);
    const [passwordInputPositiveMessage, setPasswordInputPositiveMessage] = useState<string | undefined>(undefined);
    const [excludedValues, setExcludedValues] = useState<string[]>([]);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const getExcludedValues = (email: string): string[] =>
      ['.', '-', '_', '@'].reduce((acc, s) => acc.split(s).join(','), email).split(',');

    const clearValidationMessages = () => {
      setPasswordInputError(false);
      setPasswordInputErrorMessage(undefined);
      setPasswordInputPositive(false);
      setPasswordInputPositiveMessage(undefined);
    };

    const validatePassword = useCallback(
      (value: string) => {
        const validationResult = zxcvbn(value, [...user_inputs, ...excludedValues]);

        if (oldPassword && oldPassword === value) {
          setPasswordInputError(true);
          setPasswordInputErrorMessage(
            i18n._(
              t({
                id: 'password_input.same_as_current',
                message: 'Password is the same as current',
              }),
            ),
          );
          return false;
        }

        if (validationResult.score < 3) {
          setPasswordInputError(true);
          setPasswordInputErrorMessage(
            i18n._(
              t({
                id: 'password_input.weak_password',
                message: 'password too weak',
              }),
            ),
          );
          return false;
        }

        if (validationResult.score === 3) {
          setPasswordInputError(false);
          setPasswordInputErrorMessage(undefined);
          setPasswordInputPositive(true);
          setPasswordInputPositiveMessage(
            i18n._(
              t({
                id: 'password_input.ok_password',
                message: 'Ok password! Could be even stronger',
              }),
            ),
          );
        }
        if (validationResult.score === 4) {
          setPasswordInputError(false);
          setPasswordInputErrorMessage(undefined);
          setPasswordInputPositive(true);
          setPasswordInputPositiveMessage(
            i18n._(
              t({
                id: 'password_input.great_password',
                message: 'Great password!',
              }),
            ),
          );
        }
        return true;
      },
      [excludedValues, oldPassword],
    );

    const handleValidationChange = useCallback(
      (valid: boolean) => {
        if (!onValidError || !onClearError) {
          return;
        }
        if (!valid) {
          onValidError();
        } else {
          onClearError();
        }
      },
      [onValidError, onClearError],
    );

    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (!zxcvbn) {
          return;
        }

        if (value === '' && onClearError) {
          onClearError();
          clearValidationMessages();
          return;
        }

        const valid = validatePassword(value);
        handleValidationChange(valid);
        if (onChange) onChange(e);
      },
      [handleValidationChange, onClearError, validatePassword, onChange],
    );

    const debouncedHandleInputChange = useMemo(() => _.debounce(handleInputChange, 100), [handleInputChange]);

    useEffect(() => {
      const updateExcludedValues = () => {
        if (valuesToExclude) {
          setPrevValuesToExclude(valuesToExclude);
          setExcludedValues([
            ...valuesToExclude.reduce<string[]>((acc, v) => [...acc, ...getExcludedValues(v)], []),
            ...valuesToExclude,
          ]);
        } else {
          setPrevValuesToExclude([]);
          setExcludedValues([]);
        }
      };
      if (
        (!valuesToExclude && prevValuesToExclude.length) ||
        (valuesToExclude && valuesToExclude.length !== prevValuesToExclude.length) ||
        (valuesToExclude && !valuesToExclude.every((v, i) => v === prevValuesToExclude[i]))
      ) {
        updateExcludedValues();
      }
    }, [valuesToExclude, prevValuesToExclude]);

    useEffect(() => {
      if (inputRef.current && inputRef.current.value !== '') {
        clearValidationMessages();
        const valid = validatePassword(inputRef.current.value);
        handleValidationChange(valid);
      }
    }, [excludedValues, validatePassword, handleValidationChange]);

    useEffect(() => {
      const loadZxcvbn = async () => {
        const library = await import('zxcvbn');

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        zxcvbn = library.default;

        setZxcvbnLoading(false);
      };
      if (!zxcvbn) {
        void loadZxcvbn();
      } else {
        setZxcvbnLoading(false);
      }
    }, []);

    return (
      <TextInput
        autoComplete="current-password"
        {...props}
        id={id}
        error={props.error || passwordInputError}
        errorMessage={passwordInputErrorMessage || props.errorMessage}
        positive={passwordInputPositive}
        positiveMessage={passwordInputPositiveMessage}
        type="password"
        ref={mergeRefs([ref, inputRef])}
        onChange={debouncedHandleInputChange}
        inputProps={{
          readOnly: zxcvbnLoading,
        }}
      />
    );
  },
);

PasswordInput.defaultProps = defaultProps;
