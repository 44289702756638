import React, { useMemo } from 'react';
import { Link, To } from 'react-router-dom';

import { usePathnameMatch } from 'hooks/usePathnameMatch/usePathnameMatch';
import { NavButton, NavButtonProps } from 'components/recipes/NavButton';
import { useValidateNavigation } from 'hooks/useBlockAppNavigation/useBlockAppNavigation';

type Props = NavButtonProps & {
  to: To;
  routeMatches?: string[];
};

const defaultProps: Partial<Props> = {
  shape: 'square',
  additionalLabel: undefined,
  disabled: false,
  avatarProps: undefined,
  routeMatches: undefined,
};

export const NavLink = ({ to, disabled, routeMatches, ...props }: Props): React.ReactElement => {
  const paths = routeMatches ? [to] : [`${typeof to === 'string' ? to : to.pathname}/*`];
  const isPathnameActive = usePathnameMatch(paths, routeMatches);
  const validateNavigation = useValidateNavigation();

  const isActive = useMemo(() => !disabled && isPathnameActive, [disabled, isPathnameActive]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const isValidNavigation = validateNavigation(to);
    if (isValidNavigation && !isActive && !disabled) return;

    e.preventDefault();
  };
  return (
    <Link to={to} style={{ cursor: 'default', display: 'block' }} onClick={handleClick}>
      <NavButton {...props} isActive={isActive} disabled={disabled} />
    </Link>
  );
};

NavLink.defaultProps = defaultProps;
