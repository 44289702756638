import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { forwardRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Flex, Heading, Text } from 'theme-ui';

import { EmployeeImportType } from 'api/actions/employees/employeesActions.types';
import { AnchorButton } from 'components/ui/Buttons/AnchorButton';
import { FileUpload } from 'components/ui/FileUpload/FileUpload';
import { DOWNLOAD_EXCEL_TEMPLATE_LINK } from 'constants/common';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

export type SubmitProps = {
  uploadId: string;
};

type Props = {
  handleSubmitImportPreview: ({ uploadId }: SubmitProps) => Promise<void>;
};

export const ImportStep1Modal = forwardRef<HTMLFormElement, Props>(({ handleSubmitImportPreview }, ref) => {
  useLingui();
  const { type } = useParams() as { type: string };

  const methods = useForm<SubmitProps>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const handleSubmitCallback = useCallback(
    ({ uploadId }: SubmitProps) => handleSubmitImportPreview({ uploadId }),
    [handleSubmitImportPreview],
  );

  const employeeImportTypeName: Record<string, string> = {
    [EmployeeImportType.Excel]: t({ id: 'team.import_modal.xls' }),
    [EmployeeImportType.GSuite]: t({ id: 'team.import_modal.google_workspace' }),
    [EmployeeImportType.Quickbooks]: t({ id: 'team.import_modal.quickbooks' }),
    [EmployeeImportType.Optima]: t({ id: 'team.import_modal.comarch_optima' }),
    [EmployeeImportType.Symfonia]: t({ id: 'team.import_modal.symfonia' }),
    [EmployeeImportType.Enova]: t({ id: 'team.import_modal.enova' }),
  };

  return (
    <>
      <Flex
        sx={{
          bg: 'team.import.firstStep.bg',
          alignItems: 'center',
          justifyContent: 'space-between',
          outline: '1px solid',
          outlineColor: 'team.import.firstStep.outline',
          p: 3,
          mb: 4,
          borderRadius: 'xs',
        }}
      >
        <Flex sx={{ width: '100%' }}>
          {(Number(type) as EmployeeImportType) === EmployeeImportType.Excel ? (
            <Flex
              sx={{
                flexDirection: ['column', null, null, 'row'],
                gap: 3,
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Flex
                sx={{
                  flexDirection: 'column',
                }}
              >
                <Heading as="h2" variant="heading3">
                  1.&nbsp;<Trans id="team.import_modal.get_template">Get our template in Excel</Trans>
                </Heading>

                <Text as="p" sx={{ fontSize: 2, ml: 3 }}>
                  <Trans id="team.import_modal.download_and_fill">Download and fill it out with your employees</Trans>
                </Text>
              </Flex>

              <Flex>
                <AnchorButton href={DOWNLOAD_EXCEL_TEMPLATE_LINK} variant="grey" outline={true} shape="rounded">
                  <Trans id="team.import_modal.download_template">Download template</Trans>
                </AnchorButton>
              </Flex>
            </Flex>
          ) : (
            <Heading as="h2" variant="heading3">
              1.&nbsp;
              {(Number(type) as EmployeeImportType) === EmployeeImportType.Symfonia ? (
                <>
                  <Trans id="team.import_modal.export_from_xml">Export a .xml file from</Trans>
                  <> {employeeImportTypeName[type]}</>
                </>
              ) : (
                <>
                  <Trans id="team.import_modal.export_from">Export a .xls file from</Trans>
                  <> {employeeImportTypeName[type]}</>
                </>
              )}
            </Heading>
          )}
        </Flex>
      </Flex>

      <Flex
        sx={{
          bg: 'team.import.secondStep.bg',
          alignItems: 'center',
          justifyContent: 'space-between',
          outline: '1px solid',
          outlineColor: 'team.import.secondStep.outline',
          p: 3,
          borderRadius: 'xs',
        }}
      >
        <form
          ref={ref}
          onSubmit={floatingPromiseReturn(methods.handleSubmit(handleSubmitCallback))}
          style={{ width: '100%' }}
        >
          <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Heading as="h2" variant="heading3" sx={{ width: '80%' }}>
              2.&nbsp;
              <Trans id="team.import_modal.upload_file">
                And then <Text sx={{ color: 'team.import.secondStep.text' }}>upload your file</Text>
              </Trans>
            </Heading>
            <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <Flex sx={{ flexDirection: 'column' }}>
                <FileUpload
                  {...methods.register('uploadId', {
                    required: t({ id: 'team.import_modal.file_required', message: 'File required.' }),
                  })}
                  maxFiles={1}
                  acceptExt={['xls', 'xlsx', 'xml']}
                  errorMessage={methods.formState.errors.uploadId?.message}
                  error={!!methods.formState.errors.uploadId}
                />
              </Flex>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </>
  );
});
