import { useCallback } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { fetchUserNotificationsSettingsAction } from 'api/actions/settings/settingsActions';
import { notificationsSettingsAtom } from 'state/settings';

export const useNotificationsSettings = () => {
  const { query } = useClient();
  const [notificationsSettings, setNotificationsSettings] = useRecoilState(notificationsSettingsAtom);

  const fetchNotifications = useCallback(async () => {
    const { error, payload } = await query(fetchUserNotificationsSettingsAction());

    if (!error && payload) {
      setNotificationsSettings(payload);
    }
  }, [query, setNotificationsSettings]);

  return {
    notificationsSettings,
    setNotificationsSettings,
    fetchNotifications,
  };
};
