import React from 'react';
import { useTimer } from 'use-timer';
import { Plural, Trans } from '@lingui/macro';
import { useRecoilState } from 'recoil';

import { Footer, Header, LeadText, Section, OverlayContainer, ProgressBar } from 'Kiosk/Layout';
import { Button } from 'components/ui/Buttons';
import { initialFaceScanStepAtom, InitialFaceScanSteps } from 'Kiosk/state/initialFaceScanState';
import { KioskOverlay, overlayStateAtom } from 'Kiosk/state/overlayState';
import { Container } from 'components/ui/Container';
import { ElementGroup } from 'components/ui/ElementGroup';

type Props = {
  name: string;
};
export const StepStart = ({ name }: Props): React.ReactElement => {
  const DEFAULT_DURATION = 10;
  const [, setStep] = useRecoilState(initialFaceScanStepAtom);
  const [, setOverlay] = useRecoilState(overlayStateAtom);

  const toNextStep = () => {
    setStep(InitialFaceScanSteps.SCAN);
  };

  const cancelScan = () => {
    setOverlay({ type: KioskOverlay.start });
    setStep(InitialFaceScanSteps.START);
  };

  const { time } = useTimer({
    initialTime: DEFAULT_DURATION,
    timerType: 'DECREMENTAL',
    endTime: 0,
    autostart: true,
    interval: 1000,
    onTimeOver: () => {
      toNextStep();
    },
  });

  return (
    <OverlayContainer blurBackground>
      <Container
        size="sm"
        sx={{
          color: 'kiosk.faceScan.text',
          textAlign: 'center',
          flex: '1 0',
          justifyContent: 'center',
        }}
      >
        <Header>
          <Header.Title>
            {name}
            <Trans id="kiosk.before_initial_scan.lead.header">, welcome on board! 🤗</Trans>
          </Header.Title>
          <Header.Lead>
            <Trans id="kiosk.before_initial_scan.lead.text_1">Your organization requires facial verification.</Trans>
          </Header.Lead>
        </Header>

        <Section>
          <LeadText>
            <Trans id="kiosk.before_initial_scan.lead.text_2">
              We need to perform an initial face scan so you could use our app.
            </Trans>
          </LeadText>
          <LeadText>
            <Trans id="kiosk.before_initial_scan.lead.text_3">Please follow the instructions on the next screen.</Trans>
          </LeadText>
        </Section>

        <Footer sx={{ mt: 3 }}>
          <LeadText>
            <Trans id="kiosk.before_initial_scan.progress_header">
              Starts in <strong>{Math.ceil(time)}</strong>
            </Trans>{' '}
            <Plural
              id="global.plurals.seconds"
              value={Math.ceil(time)}
              one="second"
              two="seconds"
              few="seconds"
              other="seconds"
            />
            .
          </LeadText>

          <ProgressBar duration={DEFAULT_DURATION} durationProgress={time} wrapperSx={{ mb: 4 }} />

          <ElementGroup marginAt="end" direction="column">
            <Button onClick={toNextStep} variant="primary" size="lg" type="button">
              <Trans id="kiosk.before_initial_scan.start_button">Let's start!</Trans>
            </Button>

            <Button
              onClick={cancelScan}
              bgOverwrite={{
                default: 'kiosk.faceScan.btn.bg.default',
                hover: 'kiosk.faceScan.btn.bg.hover',
                tap: 'kiosk.faceScan.btn.bg.tap',
                disabled: 'kiosk.faceScan.btn.bg.disabled',
              }}
              variant="primary"
              size="lg"
              type="button"
            >
              <Trans id="global.forms.buttons.cancel">Cancel</Trans>
            </Button>
          </ElementGroup>
        </Footer>
      </Container>
    </OverlayContainer>
  );
};
