import { atom } from 'recoil';

import { FetchCountryDetailsListResponse, TimeZoneListItem } from 'api/actions/data/dataActions.types';
import { AccountInfoAbout, AccountInfoCompany } from 'api/actions/user/userActions.types';

export const timeZoneListAtom = atom<TimeZoneListItem[] | null>({
  key: 'timeZoneList',
  default: null,
});

export type SignUpFormAtom = Partial<AccountInfoAbout & AccountInfoCompany>;

export const signUpFormAtom = atom<SignUpFormAtom | null>({
  key: 'signUpForm',
  default: null,
});

export const countryDetailsListResponseAtom = atom<FetchCountryDetailsListResponse | null>({
  key: 'countryDetailsListResponse',
  default: null,
});
