import React from 'react';
import { Flex, FlexProps, ThemeUIStyleObject } from 'theme-ui';

type Props = FlexProps;

const mainSx: ThemeUIStyleObject = {
  flexGrow: 1,
  gap: 4,
  position: 'relative',
  flexDirection: 'column',
  p: 4,
  alignItems: 'center',
};

export const Main = React.forwardRef<HTMLDivElement, Props>(({ children, ...props }: Props, ref) => (
  <Flex
    ref={ref}
    as="main"
    {...props}
    sx={{
      ...mainSx,
      ...props.sx,
    }}
  >
    {children}
  </Flex>
));
