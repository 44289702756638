import { Action, DefaultErrorMessageEnum } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  ConnectIntegrationsPayrollActionProps,
  ConnectIntegrationsPayrollResponse,
  DisconnectExternalProviderActionProps,
  DisconnectExternalProviderResponse,
  DisconnectIntegrationsPayrollActionProps,
  DisconnectIntegrationsPayrollResponse,
  DisconnectQuickbooksActionProps,
  DisconnectQuickbooksResponse,
  FetchExternalProviderActionProps,
  FetchExternalProviderResponse,
  FetchGoogleCallbackActionProps,
  FetchGoogleCallbackResponse,
  FetchIntegrationsResponse,
  FetchQuickbooksCallbackActionProps,
  FetchQuickbooksCallbackResponse,
  FetchQuickbooksResponse,
  FetchSquareCallbackActionProps,
  FetchSquareCallbackResponse,
  FetchSquareResponse,
  FetchXeroCallbackActionProps,
  FetchXeroCallbackResponse,
  FetchXeroResponse,
  GoogleLinkActionProps,
  GoogleLinkResponse,
  GoogleLoginActionProps,
  GoogleLoginResponse,
  RefreshTokenIntegrationActionProps,
  RefreshTokenIntegrationReponse,
} from './integrationActions.types';

export function fetchIntegrationsAction(): Action<FetchIntegrationsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.integrations,
  };
}

export function connectIntegrationsPayrollAction({
  integration,
}: ConnectIntegrationsPayrollActionProps): Action<ConnectIntegrationsPayrollResponse> {
  const queryParams = new URLSearchParams({
    integration: integration.toString(),
  });

  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.integrationsConnectPayroll}?${queryParams}`,
  };
}

export function integrationsDisconnectAction({
  integration,
}: DisconnectIntegrationsPayrollActionProps): Action<DisconnectIntegrationsPayrollResponse> {
  const queryParams = new URLSearchParams({
    integration: integration.toString(),
  });

  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.integrationsDisconnect}?${queryParams}`,
  };
}

export function integrationRefreshTokenAction({
  integration,
}: RefreshTokenIntegrationActionProps): Action<RefreshTokenIntegrationReponse> {
  const queryParams = new URLSearchParams({
    integration: integration.toString(),
  });

  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.integrationsRefreshToken}?${queryParams}`,
  };
}

export function fetchExternalProviderAction({
  actionSource,
}: FetchExternalProviderActionProps): Action<FetchExternalProviderResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.externalProvider}/${actionSource}`,
  };
}

export function disconnectExternalProviderAction({
  provider,
}: DisconnectExternalProviderActionProps): Action<DisconnectExternalProviderResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.externalProviderDisconnect}/${provider}`,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.DisconnectIntegration,
    },
  };
}

export function googleLinkAction({ googleToken }: GoogleLinkActionProps): Action<GoogleLinkResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.googleLink,
    body: { googleToken },
  };
}

export function googleLoginAction({ googleToken }: GoogleLoginActionProps): Action<GoogleLoginResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.googleLogin,
    body: { googleToken },
  };
}

export function fetchQuickbooksAction(): Action<FetchQuickbooksResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.quickbooks,
  };
}

export function fetchQuickbooksCallbackAction({
  query,
}: FetchQuickbooksCallbackActionProps): Action<FetchQuickbooksCallbackResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.quickbooksCallback}?${query}`,
  };
}

export function disconnectQuickbooksAction({
  realmId,
}: DisconnectQuickbooksActionProps): Action<DisconnectQuickbooksResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.quickbooksDisconnect,
    body: { realmId },
    config: { skipAuthorization: true },
  };
}

export function fetchGoogleCallbackAction({
  query,
}: FetchGoogleCallbackActionProps): Action<FetchGoogleCallbackResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.googleCallback}?${query}`,
  };
}

export function fetchXeroAction(): Action<FetchXeroResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.xero,
  };
}

export function fetchXeroCallbackAction({ query }: FetchXeroCallbackActionProps): Action<FetchXeroCallbackResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.xeroCallback}?${query}`,
  };
}

export function fetchSquareAction(): Action<FetchSquareResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.square,
  };
}

export function fetchSquareCallbackAction({
  query,
}: FetchSquareCallbackActionProps): Action<FetchSquareCallbackResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.squareCallback}?${query}`,
  };
}
