import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback } from 'react';

import {
  RequestType,
  TimeEventType,
  TimeOffType,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { DEFAULT_WORK_STATUSES_IDS } from 'constants/common';
import { isOfType } from 'utils/isOfType';

export const useRequestTypeTranslations = () => {
  useLingui();

  const actionDescription = useCallback((actionType: RequestActionType | undefined): string => {
    switch (actionType) {
      case RequestActionType.Create:
        return t({
          id: 'request.action.create',
          message: 'Create',
        });

      case RequestActionType.Edit:
        return t({
          id: 'request.action.edit',
          message: 'Edit',
        });

      case RequestActionType.Remove:
        return t({
          id: 'request.action.remove',
          message: 'Remove',
        });

      default:
        return '';
    }
  }, []);

  const displayType = useCallback((type: RequestFormType): string => {
    switch (type) {
      case RequestFormType.BusinessTrip:
        return t({ id: 'request.type.business_trip' });
      case RequestFormType.Custom:
        return t({ id: 'request.type.custom' });
      case RequestFormType.TimeOff:
        return t({ id: 'request.type.time_off' });
      case RequestFormType.TimeTracking:
        return t({ id: 'request.type.time_tracking' });
      default:
        return '';
    }
  }, []);

  const displayRequestTypeName = useCallback(
    (
      requestType: RequestType | TimeOffType | TimeEventType | undefined,
      businessTripName: string | undefined,
      isEnd?: boolean,
    ): string => {
      if (requestType && isOfType<RequestType>(requestType, 'abbreviation')) {
        return `${t({ id: requestType.abbreviation })} - ${t({ id: requestType.name })}`;
      }

      if (requestType) {
        return `${t({ id: requestType.name })} ${
          isEnd && requestType.id !== DEFAULT_WORK_STATUSES_IDS.EXIT ? t({ id: 'requests.is_end' }) : ''
        }`;
      }

      if (businessTripName) {
        return businessTripName;
      }

      return '';
    },
    [],
  );

  return { actionDescription, displayType, displayRequestTypeName };
};
