import { GRID_DEFAULT_HEIGHT, GUTTER_DEFAULT_SIZE } from 'components/StickyList/constants';
import { makeTheme } from '../makeTheme';

import { SCHEDULES_PATTERNS } from './calendar_patterns';

const SIZES = {
  gutter: GUTTER_DEFAULT_SIZE,
  innerCellElement: GRID_DEFAULT_HEIGHT - GUTTER_DEFAULT_SIZE - 6 * 2,
};

const AI_BORDER_WIDTH = '0.125rem';

export const calendarStyles = makeTheme({
  calendar: {
    dropZone: {
      container: {
        visibility: 'hidden',
        position: 'absolute',
        inset: 0,
        bg: 'calendar.dropZone.container',
        zIndex: 10,
        px: 1,
        py: 2,
        gap: 1,
      },
      element: {
        flex: '1 0',
        alignItems: 'center',
        justifyContent: 'center',
        bg: 'calendar.dropZone.element.default.bg',
        borderRadius: 'sm',
        fontSize: 0,
        fontWeight: '700',
        border: '2px solid',
        borderColor: 'calendar.dropZone.element.default.border',
        transition: 'all 0.15s ease-in-out',
        transform: 'scale(0.95)',
        '&:hover': {
          transform: 'scale(1.05)',
          color: 'calendar.dropZone.element.hover.text',
          bg: 'calendar.dropZone.element.hover.bg',
          borderColor: 'calendar.dropZone.element.hover.border',
        },
      },
    },
    dayCell: {
      position: 'relative',
      // overflow: 'hidden',
      mx: '-0.75rem',
      flex: 1,
      flexDirection: 'column',
      gap: 2,
      py: '6px',
      alignSelf: 'stretch',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      '&[data-dragover="true"]': {
        '> .drop-zone': {
          visibility: 'visible',
        },
      },
      '&[data-details-open="true"]': {
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          border: '3px solid',
          borderColor: 'calendar.dayCell.detailsOpen.border',
          bg: 'calendar.dayCell.detailsOpen.bg',
          zIndex: 0,
        },
      },
      '&[data-time-off="true"]': {
        bg: 'calendar.dayCell.timeOff',
      },
      '&[data-weekend="true"]:not([data-holiday="true"]):not([data-errors="true"])': {
        bg: 'calendar.dayCell.weekend',
      },
      '&[data-holiday="true"]:not([data-errors="true"])': {
        bg: 'calendar.dayCell.holiday',
      },
      '&[data-warnings="true"]': {
        bg: 'calendar.dayCell.warnings',
      },
      '&[data-errors="true"]': {
        bg: 'calendar.dayCell.errors',
      },
      '&[data-unpublished-changes="true"]': {
        ...SCHEDULES_PATTERNS.pending,
        outline: '1px solid',
        outlineColor: 'calendar.dayCell.unpublishedChanges',
      },
      '&[data-pending-request="true"]': {
        '&::before': {
          ...SCHEDULES_PATTERNS.pendingRequest,
          content: '""',
          position: 'absolute',
          inset: -1,
          zIndex: 0,
        },
      },
      '&[data-is-available="true"]:not([data-errors="true"]):not([data-warnings="true"])': {
        '&::before': {
          bg: 'calendar.dayCell.isAvailable',
          content: '""',
          position: 'absolute',
          inset: 0,
          zIndex: 0,
        },
      },
      '&[data-is-available="false"]:not([data-errors="true"]):not([data-warnings="true"])': {
        '&::before': {
          bg: 'calendar.dayCell.isUnavailable',
          content: '""',
          position: 'absolute',
          inset: 0,
          zIndex: 0,
        },
      },
      '&:hover': {
        '> div:not(.link-button)': {
          opacity: 0.8,
        },
      },
      '&:not([data-dragover="true"]):hover': {
        '> .link-button': {
          opacity: 1,
          pointerEvents: 'auto',
        },
      },
    },
    dayCellRibbon: {
      pointerEvents: 'none',
      position: 'absolute',
      left: 0,
      right: 0,
      p: '1px',
      gap: '2px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontSize: '10px',
      fontWeight: '700',
      lineHeight: 1,
      zIndex: 10,
      '&[data-position="top"]': {
        top: 0,
        py: 1,
        overflow: 'hidden',
      },
      '&[data-position="bottom"]': {
        bottom: 0,
      },
    },
    ribbonElement: {
      pointerEvents: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      py: '1px',
      px: '2px',
      color: 'calendar.ribbonElement.text',
      minWidth: '0.75rem',
      borderRadius: '2px',
      '&[data-type="timeOff"]': {
        justifyContent: 'flex-start',
        bg: 'calendar.ribbonElement.timeOff',
        borderRadius: 0,
        transform: 'rotate(-12deg)',
        transformOrigin: 'top left',
        width: '100%',
        ml: -1,
        px: 1,
      },
      '&[data-type="businessTrip"]': {
        bg: 'calendar.ribbonElement.businessTrip',
      },
      '&[data-type="customRequest"]': {
        bg: 'calendar.ribbonElement.customRequest',
      },
    },
    schedule: {
      zIndex: 2,
      position: 'relative',
      // overflow: 'hidden',
      flex: '1 0',
      minHeight: SIZES.innerCellElement,
      height: SIZES.innerCellElement,
      maxHeight: SIZES.innerCellElement,
      py: 1,
      px: '0.75rem',
      mx: '-1px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      '&[data-dragging="false"]:hover': {
        opacity: '1 !important',
        '> .link-button': {
          opacity: 1,
          pointerEvents: 'auto',
        },
      },
      '&[data-is-ai="true"]': {
        '&::before': {
          content: '""',
          position: 'absolute',
          zIndex: -1,
          inset: AI_BORDER_WIDTH,
          bg: 'inherit',
          backgroundImage: 'inherit',
          borderRadius: '7px',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          zIndex: -2,
          inset: 0,
          background: 'calendar.schedule.bg.ai.default',
          borderRadius: 'inherit',
        },
        '&[data-start="true"]:not([data-end="true"])': {
          '&::before': {
            right: 0,
            borderTopRightRadius: 'inherit',
            borderBottomRightRadius: 'inherit',
          },
        },
        '&[data-start="false"]': {
          '&::before': {
            left: 0,
            borderTopLeftRadius: 'inherit',
            borderBottomLeftRadius: 'inherit',
          },
          '&::after': {
            background: 'calendar.schedule.bg.ai.defaultMirrored',
          },
          '&[data-end="false"]': {
            '&::before': {
              left: 0,
              right: 0,
              borderRadius: 'inherit',
            },
            '&::after': {
              background: 'calendar.schedule.bg.ai.middle',
            },
          },
        },
      },
      '&[data-type]:not([data-is-ai="true"])': {
        border: '2px solid',
        borderColor: 'calendar.schedule.isNotAi',
      },
      '&[data-start="true"]': {
        ml: 2,
        pl: 1,
        '&[data-is-ai="true"]': {
          paddingLeft: '0.375rem',
          paddingTop: '0.375rem',
        },
        '&[data-type="FixedStartTime"], &[data-is-ai="true"]': {
          borderTopLeftRadius: 'sm',
          borderBottomLeftRadius: 'sm',
        },
      },
      '&[data-start="false"]': {
        '> .schedule-name': {
          opacity: 0,
        },
      },
      '&[data-end="true"]': {
        mr: 2,
        pr: 1,
        '&[data-type="FixedStartTime"], &[data-is-ai="true"]': {
          borderTopRightRadius: 'sm',
          borderBottomRightRadius: 'sm',
        },
      },
      '> *': {
        zIndex: 1,
        alignItems: 'flex-start',
      },
      '&[data-pending="true"]': {
        ...SCHEDULES_PATTERNS.pending,
        outline: '1px solid',
        outlineColor: 'calendar.schedule.pending',
      },
      '&[data-overtime-schedule="true"]': {
        '&::before': {
          content: '""',
          position: 'absolute',
          zIndex: 0,
          ...SCHEDULES_PATTERNS.overtimeSchedule,
        },
        '&[data-is-ai="false"]': {
          '&::before': {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
        },
      },
    },
    iconContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      gap: '1px',
      borderRadius: 'xs',
      overflow: 'hidden',
      color: 'calendar.scheduleElement.text',
      zIndex: '3 !important',

      '&[data-is-ai="true"]': {
        bottom: AI_BORDER_WIDTH,
      },
    },
    scheduleName: {
      fontSize: 1,
      lineHeight: 1,
      fontWeight: '700',
      height: '14px',
    },
    scheduleWorkPosition: {
      width: '100%',
      fontSize: 0,
      lineHeight: 1,
      fontWeight: '700',
    },
    detailedSchedule: {
      ribbonElement: {
        position: 'absolute',
        alignItems: 'center',
        width: '100%',
        py: '1px',
        px: 2,
        ml: -3,
        top: '8px',
        fontSize: 0,
        fontWeight: '700',
        textTransform: 'uppercase',
        color: 'calendar.detailedSchedule.ribbonElement.text',
        justifyContent: 'flex-start',
        bg: 'calendar.detailedSchedule.ribbonElement.bg',
        transform: 'rotate(-12deg)',
        transformOrigin: 'top left',
        zIndex: 2,
      },
      container: {
        position: 'relative',
        bg: 'calendar.detailedSchedule.container.bg.default',
        borderRadius: 'sm',
        flexDirection: 'row',
        overflow: 'hidden',
        flex: '0 0 auto',
        p: 2,
        gap: 2,
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        '&[data-with-hover="true"]': {
          '&:hover': {
            bg: 'calendar.detailedSchedule.container.bg.hover',
            outline: '1px solid',
            outlineColor: 'calendar.detailedSchedule.container.withHover',
            boxShadow: 'calendar.detailedSchedule',
          },
        },
        '&[data-deleted="true"]': {
          cursor: 'default',
          opacity: 0.5,
        },
        '&[data-is-ai="true"]': {
          zIndex: 1,
          bg: 'calendar.schedule.bg.ai.details',
          padding: '0.625rem',
          '&::before': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            inset: AI_BORDER_WIDTH,
            bg: 'inherit',
            backgroundImage: 'inherit',
            borderRadius: `7px`,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            zIndex: -2,
            inset: 0,
            background: 'calendar.schedule.bg.ai.default',
            borderRadius: 'inherit',
          },
        },
        '&[data-pending="true"]': {
          ...SCHEDULES_PATTERNS.pending,
          outline: '1px solid',
          outlineColor: 'calendar.detailedSchedule.container.pending.default',
          '&:hover': {
            outlineColor: 'calendar.detailedSchedule.container.pending.hover',
          },
        },
      },
      colorBox: {
        position: 'relative',
        overflow: 'hidden',
        alignSelf: 'flex-start',
        boxShadow: 'calendar.colorBox',
        size: '28px',
        border: '2px solid',
        borderColor: 'calendar.detailedSchedule.colorBox.border',
        '&[data-type="FixedStartTime"]': {
          borderRadius: 'sm',
        },
        '&[data-overtime-schedule="true"]': {
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 0,
            ...SCHEDULES_PATTERNS.overtimeSchedule,
          },
        },
        '&[data-pending="true"]': {
          ...SCHEDULES_PATTERNS.pending,
          outline: '1px solid',
          outlineColor: 'calendar.detailedSchedule.colorBox.outline',
        },
      },
      iconBox: {
        display: 'inline-flex',
        fontSize: 2,
        flexDirection: 'row',
        justifyContent: 'center',
        bg: 'calendar.detailedSchedule.iconBox',
        size: '28px',
        borderRadius: 'xs',
        gap: 1,
      },
    },
    request: {
      zIndex: 2,
      position: 'relative',
      // overflow: 'hidden',
      flex: '1 0',
      minHeight: SIZES.innerCellElement,
      height: SIZES.innerCellElement,
      maxHeight: SIZES.innerCellElement,
      py: 1,
      mx: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      '&:hover': {
        opacity: '1 !important',
        '> .link-button': {
          opacity: 1,
          pointerEvents: 'auto',
        },
      },
      '&[data-type]': {
        border: '2px solid',
        borderColor: 'calendar.request.border',
      },
      '&[data-type="TimeOff"]': {
        borderRadius: 'pill',
        bg: 'calendar.request.timeOff',
      },
      '&[data-type="Custom"]': {
        borderRadius: 'xs',
        bg: 'calendar.request.custom.bg',
        borderColor: 'calendar.request.custom.border',
      },
      '&[data-type="BusinessTrip"]': {
        borderRadius: 0,
        bg: 'calendar.request.businessTrip.bg',
        color: 'calendar.request.businessTrip.text',
      },
      '> *': {
        zIndex: 1,
        alignItems: 'flex-start',
      },
      '&[data-pending="true"]': {
        ...SCHEDULES_PATTERNS.pending,
        outline: '1px solid',
        outlineColor: 'calendar.request.pending',
      },
    },
    requestName: {
      px: 1,
      textAlign: 'center',
      fontSize: 1,
      lineHeight: 1,
      fontWeight: '700',
    },
    dayCellLinkButtonContainer: {
      zIndex: 20,
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      alignItems: 'center',
      justifyContent: 'center',
      transform: 'translateY(-2px)',
      opacity: 0,
      pointerEvents: 'none',
      '> a': {
        pl: 0,
        pr: '0.25rem',
        border: '1px solid',
        borderColor: 'calendar.dayCellLinkButtonContainer',
        fontSize: 0,
        boxShadow: 'calendar.dayCellLinkButtonContainer',
      },
    },
    elementLinkButtonContainer: {
      zIndex: 20,
      flexDirection: 'column',
      position: 'absolute',
      top: '-2px',
      left: '100%',
      transform: 'translate(-6px)',
      opacity: 0,
      pointerEvents: 'none',
      '> a': {
        border: '1px solid',
        borderColor: 'calendar.elementLinkButtonContainer',
        fontSize: 0,
        boxShadow: 'calendar.elementLinkButtonContainer',
      },
    },
  },
});
