import { Plural, Trans, plural, t } from '@lingui/macro';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Text } from 'theme-ui';

import { removeTimeEventsAction } from 'api/actions/timeEvent/timeEventActions';
import { RemoveTimeEventsActionProps } from 'api/actions/timeEvent/timeEventActions.types';
import { ListNames } from 'components/StickyList/types';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'components/recipes/ConfirmModal/types';
import { filteredExtTimeEventsSelector } from 'state/clockLog';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { ReportsExtendedTimeEvent } from 'state/reports';

import { useRefreshClockLog } from './hooks/useRefreshClockLog';

export const RemoveEvents = () => {
  const setSelectedEventIds = useSetRecoilState(selectedRowsIdsSelectorFamily(ListNames.CLOCK_LOG));
  const extTimeEvents = useRecoilValue(filteredExtTimeEventsSelector);
  const events = useMemo(() => new Map([...(extTimeEvents || [])]), [extTimeEvents]);

  const { state } = useLocation();
  const { ids: eventIds } = state || {};

  const getEmployeeIds = useCallback(() => {
    if (events && eventIds) {
      const employeeIds = _.map(eventIds, (id) => events.get(id)?.personId).filter(
        (pId): pId is ReportsExtendedTimeEvent['personId'] => pId !== undefined,
      );
      if (employeeIds.length > 0) {
        const uniqueEmployeeIds = [...new Set(employeeIds)];
        return uniqueEmployeeIds;
      }
    }

    return undefined;
  }, [eventIds, events]);

  const employeeIds = useMemo(() => getEmployeeIds(), [getEmployeeIds]);

  const { clockLogInitialized, refreshClockLogForPeopleIds } = useRefreshClockLog(employeeIds);

  const titleRenderer: ConfirmModalProps['titleRenderer'] = useCallback(
    (timeEventsCount) =>
      t({
        id: 'clock_log.delete_clock_logs.heading',
        message: plural(timeEventsCount, { one: `Delete this event?`, other: 'Delete these events?' }),
      }),
    [],
  );

  const contentRenderer: ConfirmModalProps['contentRenderer'] = useCallback(
    (timeEventsCount) => (
      <Trans id="clock_log.delete_permanently_events.contents">
        <Text>
          You're about to permanently delete {timeEventsCount}{' '}
          <Plural id="clock_log.delete.plurals.event" value={timeEventsCount} one={'event'} other={'events'} />. This
          will affect all related data.
        </Text>
        <Text>
          <strong>This action can't be reversed.</strong>
        </Text>
      </Trans>
    ),
    [],
  );

  const onResponseCallback: NonNullable<ConfirmModalProps['onResponseCallback']> = useCallback(
    async (submitError) => {
      if (!submitError) {
        setSelectedEventIds((prev) => prev.filter((eventId) => !eventIds?.includes(eventId)));

        if (clockLogInitialized) await refreshClockLogForPeopleIds();
      }
    },
    [clockLogInitialized, eventIds, refreshClockLogForPeopleIds, setSelectedEventIds],
  );

  return (
    <ConfirmModal
      list={events}
      action={(body: RemoveTimeEventsActionProps) => removeTimeEventsAction(body)}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      onResponseCallback={onResponseCallback}
      withConfirmation
      variant="DELETE"
    />
  );
};
