import React, { useCallback, useEffect, useRef } from 'react';
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui';
import { Trans } from '@lingui/macro';
import _ from 'lodash';

import { TimePicker } from 'components/ui/TimePicker/TimePicker';
import { setNativeValue } from 'utils/setNativeValue';

const pickerWrapperSx: ThemeUIStyleObject = {
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export type TimeObject = {
  startTimeUnix: number;
  endTimeUnix: number;
};

type Props = {
  showStartTime: boolean;
  showEndTime: boolean;
  selectedTimes: number[];
  onTimeChange: (value: TimeObject) => void;
  onTimeBlur?: (value: TimeObject) => void;
  startTimeErrorMessage?: string;
  endTimeErrorMessage?: string;
};

export const TimePickers = ({
  showStartTime,
  showEndTime,
  onTimeChange,
  selectedTimes,
  onTimeBlur,
  startTimeErrorMessage,
  endTimeErrorMessage,
}: Props): React.ReactElement => {
  const startTimePickerRef = useRef<HTMLInputElement | null>(null);
  const endTimePickerRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (selectedTimes) {
      if (
        startTimePickerRef.current &&
        _.parseInt(startTimePickerRef.current.value, 10) !== selectedTimes[0] &&
        !_.isNaN(selectedTimes[0])
      ) {
        setNativeValue(startTimePickerRef, `${selectedTimes[0]}`);
      }

      if (
        endTimePickerRef.current &&
        _.parseInt(endTimePickerRef.current.value, 10) !== selectedTimes[1] &&
        !_.isNaN(selectedTimes[1])
      ) {
        setNativeValue(endTimePickerRef, `${selectedTimes[1]}`);
      }
    }
  }, [selectedTimes]);

  const handleStartTimePickerChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const parsedStartTimeValue = _.parseInt(value);
      const parsedEndTimeValue = endTimePickerRef.current ? _.parseInt(endTimePickerRef.current.value, 10) : NaN;

      if (parsedStartTimeValue !== selectedTimes[0]) {
        onTimeChange({ startTimeUnix: parsedStartTimeValue, endTimeUnix: parsedEndTimeValue });
      }
    },
    [onTimeChange, selectedTimes],
  );

  const handleEndTimePickerChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const parsedEndTimeValue = _.parseInt(value);
      const parsedStartTimeValue = startTimePickerRef.current ? _.parseInt(startTimePickerRef.current.value, 10) : NaN;

      if (parsedEndTimeValue !== selectedTimes[1]) {
        onTimeChange({ startTimeUnix: parsedStartTimeValue, endTimeUnix: parsedEndTimeValue });
      }
    },
    [onTimeChange, selectedTimes],
  );

  const handleStartTimePickerBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!onTimeBlur) return;

      const { value } = e.target;
      const parsedStartTimeValue = _.parseInt(value);
      const parsedEndTimeValue = endTimePickerRef.current ? _.parseInt(endTimePickerRef.current.value, 10) : NaN;

      onTimeBlur({ startTimeUnix: parsedStartTimeValue, endTimeUnix: parsedEndTimeValue });
    },
    [onTimeBlur],
  );

  const handleEndTimePickerBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!onTimeBlur) return;

      const { value } = e.target;
      const parsedEndTimeValue = _.parseInt(value);
      const parsedStartTimeValue = startTimePickerRef.current ? _.parseInt(startTimePickerRef.current.value, 10) : NaN;

      onTimeBlur({ startTimeUnix: parsedStartTimeValue, endTimeUnix: parsedEndTimeValue });
    },
    [onTimeBlur],
  );

  return (
    <Flex sx={{ flexDirection: 'column', gap: 1 }}>
      {showStartTime && (
        <Flex sx={pickerWrapperSx}>
          <Text sx={{ fontSize: 2, fontWeight: 'bold', flexGrow: 1 }} as="span">
            <Trans id="calendar.start_time">Start time</Trans>
          </Text>
          <TimePicker
            onChange={handleStartTimePickerChange}
            onBlur={handleStartTimePickerBlur}
            size="sm"
            id="calendarStartTime"
            ref={startTimePickerRef}
            error={!!startTimeErrorMessage}
            errorMessage={startTimeErrorMessage}
          />
        </Flex>
      )}
      {showEndTime && (
        <Flex sx={pickerWrapperSx}>
          <Text sx={{ fontSize: 2, fontWeight: 'bold', flexGrow: 1 }} as="span">
            <Trans id="calendar.end_time">End time</Trans>
          </Text>
          <TimePicker
            onChange={handleEndTimePickerChange}
            onBlur={handleEndTimePickerBlur}
            size="sm"
            id="calendarEndTime"
            ref={endTimePickerRef}
            error={!!endTimeErrorMessage}
            errorMessage={endTimeErrorMessage}
          />
        </Flex>
      )}
    </Flex>
  );
};
