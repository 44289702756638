import React, { CSSProperties, useMemo } from 'react';
import { FlexProps, Text, ThemeUIStyleObject } from 'theme-ui';

import { APP_NAME, HOSTNAME } from 'constants/common';
import { LinkAnchor } from 'components/ui/LinkAnchor';

import { ReactComponent as LogoInewi } from './svg/inewi.svg';
import { ReactComponent as LogoUnrubble } from './svg/unrubble.svg';

type LogoWrapperProps = {
  children: React.ReactNode;
  clickable: boolean | undefined;
} & FlexProps;

const LogoWrapper = React.memo(
  ({ children, clickable, sx, ...props }: LogoWrapperProps): React.ReactElement =>
    clickable ? (
      <LinkAnchor to="/" aria-label={APP_NAME} sx={sx}>
        {children}
      </LinkAnchor>
    ) : (
      <Text sx={sx} {...props}>
        {children}
      </Text>
    ),
);

type Props = Partial<{
  fill?: CSSProperties['fill'];
  width?: number | string | (number | string | null)[];
  sx?: ThemeUIStyleObject;
  sxOverwrite?: ThemeUIStyleObject;
  clickable?: boolean;
}>;

export const Logo = React.memo(({ clickable = false, fill = 'logo', width = 120, sx }: Props): React.ReactElement => {
  const logoMap: { [index in string]: React.FunctionComponent<React.SVGAttributes<SVGElement>> } = useMemo(
    () => ({
      'inewi.pl': LogoInewi,
      'unrubble.com': LogoUnrubble,
    }),
    [],
  );

  const LogoSvg = useMemo(() => logoMap[HOSTNAME] || LogoInewi, [logoMap]);

  return (
    <LogoWrapper
      clickable={clickable}
      sx={{
        fontSize: '0rem',
        '&:hover': {
          color: 'logo',
        },
        svg: {
          fill,
          transition: 'fill ease-in-out 0.2s',
          width,
          ...(sx && sx),
        },
      }}
    >
      <LogoSvg preserveAspectRatio="xMidYMid meet" />
    </LogoWrapper>
  );
});
