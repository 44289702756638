import { t } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { Request } from 'api/actions/requests/requestsActions.types';
import { AvatarProps } from 'components/Avatar/Avatar';
import { Divider } from 'components/Divider/Divider';
import { CellPersonType, PersonCell } from 'pages/Reports/output/PersonCell';
import { organizationSessionAtom } from 'state/organizationSession';
import { sentToPersonsSelector } from 'state/requests';

const personCellSx: ThemeUIStyleObject = {
  gap: 2,
  alignItems: 'flex-start',
};
const personCellWithoutRoleSx: ThemeUIStyleObject = {
  alignItems: 'center',
  my: 1,
};
const iconWrapperSx: ThemeUIStyleObject = { mt: 1 };
const tagsWrapperSx: ThemeUIStyleObject = { flexWrap: 'wrap' };
const roleTagSx: ThemeUIStyleObject = { maxWidth: '100%' };

type SentToDetailsProps = Pick<Request, 'selectedFirstStep' | 'selectedSecondStep'>;

export const SentToDetails: FC<SentToDetailsProps> = ({ selectedFirstStep, selectedSecondStep }) => {
  useLingui();
  const { selectedFirstStepPerson, selectedSecondStepPerson } = useRecoilValue(
    sentToPersonsSelector({
      selectedFirstStepPersonId: selectedFirstStep?.personId,
      selectedSecondStepPersonId: selectedSecondStep?.personId,
    }),
  );
  const organizationSession = useRecoilValue(organizationSessionAtom);

  const person = useMemo<Record<'anonymous' | 'deleted' | 'hidden', CellPersonType>>(() => {
    const sharedProps = { tags: [], avatarUrl: '' };
    return {
      anonymous: { ...sharedProps, name: { firstName: t({ id: 'request.any', message: 'Any' }), surname: '' } },
      deleted: {
        ...sharedProps,
        name: { firstName: t({ id: 'request.deleted_person', message: 'Deleted user' }), surname: '' },
      },
      hidden: {
        ...sharedProps,
        name: { firstName: t({ id: 'request.hidden_person', message: 'Hidden user' }), surname: '' },
      },
    };
  }, []);

  const firstPerson = useMemo<CellPersonType>(() => {
    const firstStepRole = organizationSession?.rolesMap?.get(selectedFirstStep?.roleId || '');
    const isPersonHiddenOrDeleted = selectedFirstStep?.personId && !selectedFirstStepPerson;
    const processingPerson = selectedFirstStepPerson || {
      ...person.anonymous,
      role: firstStepRole,
    };
    return isPersonHiddenOrDeleted
      ? {
          ...(selectedFirstStep?.isPersonHidden ? person.hidden : person.deleted),
          role: firstStepRole,
        }
      : processingPerson;
  }, [
    organizationSession?.rolesMap,
    person,
    selectedFirstStep?.isPersonHidden,
    selectedFirstStep?.personId,
    selectedFirstStep?.roleId,
    selectedFirstStepPerson,
  ]);

  const secondPerson = useMemo<CellPersonType | null>(() => {
    const secondStepRole = organizationSession?.rolesMap?.get(selectedSecondStep?.roleId || '');
    const secondAnonymousPerson: CellPersonType | null = selectedSecondStep?.roleId
      ? { ...person.anonymous, role: secondStepRole }
      : null;

    const isPersonHiddenOrDeleted = selectedSecondStep?.personId && !selectedSecondStepPerson;
    const processingPerson = selectedSecondStepPerson || secondAnonymousPerson;
    return isPersonHiddenOrDeleted
      ? {
          ...(selectedSecondStep?.isPersonHidden ? person.hidden : person.deleted),
          role: secondStepRole,
        }
      : processingPerson;
  }, [
    organizationSession?.rolesMap,
    person,
    selectedSecondStep?.isPersonHidden,
    selectedSecondStep?.personId,
    selectedSecondStep?.roleId,
    selectedSecondStepPerson,
  ]);

  const avatarProps = useMemo<Record<string, AvatarProps>>(
    () => ({
      [person.anonymous.name.firstName]: {
        iconType: 'team',
        name: undefined,
      },
      [person.deleted.name.firstName]: {
        iconType: 'delete',
        name: undefined,
      },
      [person.hidden.name.firstName]: {
        iconType: 'eyeOff',
        name: undefined,
      },
    }),
    [person.anonymous.name.firstName, person.deleted.name.firstName, person.hidden.name.firstName],
  );

  return (
    <Flex sx={{ flexDirection: 'column', gap: 2 }}>
      <Text variant="heading4" sx={{ fontWeight: 'bold' }}>
        <Trans id="request.sent_to">Sent to</Trans>
      </Text>
      <Flex sx={{ gap: 3 }}>
        <PersonCell
          name={firstPerson.name}
          role={firstPerson.role}
          tags={firstPerson.tags}
          avatarUrl={firstPerson.avatarUrl}
          avatarSize={34}
          sx={{ ...personCellSx, ...(!firstPerson.role && personCellWithoutRoleSx) }}
          tagsWrapperSx={tagsWrapperSx}
          roleTagSx={roleTagSx}
          avatarProps={{
            ...(firstPerson.role && { sx: iconWrapperSx }),
            ...avatarProps?.[firstPerson.name.firstName],
          }}
        />
        {secondPerson && (
          <>
            <Divider sx={{ height: 'auto' }} borderColor="darker" axis="vertical" />
            <PersonCell
              name={secondPerson.name}
              role={secondPerson.role}
              tags={secondPerson.tags}
              avatarUrl={secondPerson.avatarUrl}
              avatarSize={34}
              sx={{ ...personCellSx, ...(!secondPerson.role && personCellWithoutRoleSx) }}
              tagsWrapperSx={tagsWrapperSx}
              roleTagSx={roleTagSx}
              avatarProps={{
                ...(secondPerson.role && { sx: iconWrapperSx }),
                ...avatarProps?.[secondPerson.name.firstName],
              }}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
