export type Integration = {
  connectedAccount: string;
  type: ServiceIntegration;
  isExpired: boolean;
  isActive: boolean;
  imageUrl: string;
};

export type FetchIntegrationsResponse = Integration[];

export type ConnectDisconnectActionCommonProps = {
  integration: ServiceIntegration;
};

export type ConnectIntegrationsPayrollActionProps = ConnectDisconnectActionCommonProps;

export type ConnectIntegrationsPayrollResponse = null;

export type DisconnectIntegrationsPayrollActionProps = ConnectDisconnectActionCommonProps;

export type DisconnectIntegrationsPayrollResponse = null;

export type RefreshTokenIntegrationActionProps = ConnectDisconnectActionCommonProps;

export type RefreshTokenIntegrationReponse = null;

export type ExternalProvider = {
  provider: ExternalLoginProvider;
  isActive: boolean;
  connectedName: string;
  imageUrl: string;
  actionUrl?: string;
};

export enum ExternalLoginActionSource {
  Link = 0,
  Login = 1,
}

export type FetchExternalProviderActionProps = {
  actionSource: ExternalLoginActionSource;
};

export type FetchExternalProviderResponse = ExternalProvider[];

export type DisconnectExternalProviderActionProps = {
  provider: ExternalLoginProvider;
};

export type DisconnectExternalProviderResponse = null;

export type GoogleCommonActionProps = {
  googleToken: string;
};

export type GoogleLinkActionProps = GoogleCommonActionProps;

export type GoogleLoginActionProps = GoogleCommonActionProps;

export type GoogleLinkResponse = null;

export type GoogleLoginResponse = {
  accessToken: string;
  accountType: number;
  redirectToAccountType: boolean;
  user: {
    firstName: string;
    lastName: string;
  };
};

type FetchCommonCallbackActionProps = {
  query: string;
};

export type FetchQuickbooksResponse = string;

export type FetchQuickbooksCallbackActionProps = FetchCommonCallbackActionProps;

export type FetchQuickbooksCallbackResponse = null;

export type DisconnectQuickbooksActionProps = {
  realmId?: string | null;
};

export type DisconnectQuickbooksResponse = null;

export type FetchGoogleCallbackActionProps = FetchCommonCallbackActionProps;

export type FetchGoogleCallbackResponse = null;

export type FetchXeroResponse = string;

export type FetchXeroCallbackActionProps = FetchCommonCallbackActionProps;

export type FetchXeroCallbackResponse = null;

export type FetchSquareResponse = string;

export type FetchSquareCallbackActionProps = FetchCommonCallbackActionProps;

export type FetchSquareCallbackResponse = null;

export enum ServiceIntegration {
  Quickbooks = 0,
  SquarePayroll = 1,
  Optima = 2,
  Symfonia = 3,
  R2Platnik = 4,
  GratyfikantGT = 5,
  Xero = 6,
  ZusEzla = 8,
  Webhook = 9,
  Enova = 10,
  GratyfikantNexo = 11,
}

export enum ExternalLoginProvider {
  Google = 0,
  GoogleInewi = 1,
  GoogleTrackTime = 2,
  AppleLinkInewi = 3,
  AppleLinkTrackTime = 4,
  AppleLoginInewi = 5,
  AppleLoginTrackTime = 6,
  AtlassianInewi = 7,
  AtlassianTrackTime = 8,
  SlackLoginInewi = 9,
  SlackLoginTrackTime = 10,
}
