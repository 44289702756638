import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-fetching-library';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { editTagDetailsAction, fetchTagDetailsAction } from 'api/actions/tags/tagsActions';
import { EditTagDetailsActionProps, FetchTagDetailsResponse } from 'api/actions/tags/tagsActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { defaultTagFeaturesSelector } from 'state/organizationSession';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { AddEditTag } from '../../forms/AddEditTag';

export const EditTagModal: FC = () => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal({ wrapperSx: { height: '800px', maxHeight: '100%' } });
  const handleCloseRef = useCallbackRef(handleClose);
  const defaultTagFeatures = useRecoilValue(defaultTagFeaturesSelector);
  const [loading, setLoading] = useState(false);
  const [editedTagDetails, setEditedTagDetails] = useState<FetchTagDetailsResponse | null>(null);
  const [oldTagDetails, setOldTagDetails] = useState<FetchTagDetailsResponse | null>(null);

  const { id } = useParams() as { id: string };

  const formRef = useRef<HTMLFormElement | null>(null);

  const { payload, error } = useQuery(fetchTagDetailsAction({ tagId: id }));
  const { mutate } = useMutation(editTagDetailsAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: EditTagDetailsActionProps['tag']): Promise<boolean> => {
      if (oldTagDetails && isEqual(body, oldTagDetails)) {
        setLoading(false);
        addSnackbar({
          message: t({ id: 'team.edit_tag.no_changes', message: 'No changes to save!' }),
          variant: 'default',
        });
        return false;
      }
      const { error: submitError } = await mutate({
        tag: body,
        tagId: id,
      });
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.edit_tag.edited', message: 'Tag successfully edited!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, id, oldTagDetails, addSnackbar],
  );

  useEffect(() => {
    if (error) {
      handleCloseRef.current();
    }
    if (!error && payload) {
      if (payload.isInformational) {
        setEditedTagDetails({ ...payload, features: defaultTagFeatures?.features });
        setOldTagDetails(clone({ ...payload, features: defaultTagFeatures?.features }));
        return;
      }
      setEditedTagDetails(payload);
      setOldTagDetails(clone(payload));
    }
  }, [error, payload, handleCloseRef, defaultTagFeatures]);

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.edit_tag">Edit tag</Trans>
        </Modal.Title>
      </Modal.Header>
      {!editedTagDetails ? (
        <CenteredLoadingSpinner />
      ) : (
        <>
          <Modal.Body>
            <AddEditTag defaultValues={editedTagDetails} ref={formRef} onSubmit={onSubmit} setLoading={setLoading} />
          </Modal.Body>
          <BasicModalFooter
            buttons={[
              {
                isLoading: loading,
                onClick: handleSave,
                variant: 'primary',
                children: t({ id: 'save', message: 'Save' }),
              },
            ]}
          />
        </>
      )}
    </>
  );
};
