import React, { useCallback, useEffect } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { useRecoilValue, useRecoilState, atom, useSetRecoilState } from 'recoil';
import { useHotkeys } from 'react-hotkeys-hook';
import { v1 as uuidv1 } from 'uuid';

import { languageSelector, windowSizeAtom } from 'state/recoilState';
import { TO } from 'constants/routes';
import { nightModeSelector } from '../state/kioskState';
import { CameraImageBufferProvider } from '../context/CameraImageBufferProvider';
import { QrScannerProvider } from '../context/QrScannerProvider';
import { FaceDetectionProvider } from '../context/FaceDetectionProvider';
import { useKioskOutletContext } from 'Kiosk/Kiosk';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { debugModeSelector } from 'Kiosk/state/debugState';

import { Camera } from './Camera';
import { DebugFacePredictionModel } from './__debug/DebugFacePredictionModel';
import { OverlayController } from './OverlayController';
import { DebugCameraImageBuffer } from './__debug/DebugCameraImageBuffer';
import { DebugControlPanel } from './__debug/DebugControlPanel';
import { KioskLoadingSplash } from './KioskLoadingSplash/KioskLoadingSplash';

const kioskWrapperSx: ThemeUIStyleObject = {
  position: 'relative',
  width: '100%',
  height: 'var(--app-height)',
  overflow: 'hidden',
  color: 'kiosk.text.default',
  '&[data-night-mode="true"]': {
    color: 'kiosk.text.night',
  },
};

export const cameraKeyAtom = atom({
  key: 'cameraKey',
  default: 'cameraKey',
});

// bug fix: on mobile orientation changes width and height changes updates are ommited which results in squashed images being produced
const RefreshCameraObserver = () => {
  const { isLandscape, isMobile } = useRecoilValue(windowSizeAtom);
  const setCameraKey = useSetRecoilState(cameraKeyAtom);

  useEffect(() => {
    if (isMobile) {
      setCameraKey(uuidv1());
    }
  }, [isLandscape, isMobile, setCameraKey]);

  return null;
};

export const KioskApp = (): React.ReactElement => {
  const cameraKey = useRecoilValue(cameraKeyAtom);
  const { settings } = useKioskOutletContext();
  const navigate = useAppNavigate();

  const isNightMode = useRecoilValue(nightModeSelector);
  const language = useRecoilValue(languageSelector);
  const [debugMode, setDebugMode] = useRecoilState(debugModeSelector);

  const onUserMediaErrorCallback = useCallback(() => {
    navigate(TO.KIOSK__CAMERA_PERMISSION[language]);
  }, [language, navigate]);

  // __FOR_DEBUG_MODE
  useHotkeys('ctrl+shift+k,cmd+shift+k', () => {
    setDebugMode((prevState) => {
      // eslint-disable-next-line no-console
      console.log(`%c[DEBUG_MODE ${!prevState ? 'ON' : 'OFF'}]`, 'background: black; color: red');
      return !prevState;
    });
  });

  return (
    <>
      <RefreshCameraObserver />
      <KioskLoadingSplash />

      <Box sx={kioskWrapperSx} data-night-mode={isNightMode}>
        <OverlayController>
          <QrScannerProvider>
            <FaceDetectionProvider
              load={
                settings.isFaceRecognitionRequired ||
                settings.isBasicFraudDetectionRequired ||
                settings.isAdvancedFraudDetectionRequired
              }
            >
              <CameraImageBufferProvider>
                <Camera onUserMediaErrorCallback={onUserMediaErrorCallback} key={cameraKey} />
              </CameraImageBufferProvider>
            </FaceDetectionProvider>
          </QrScannerProvider>
        </OverlayController>
      </Box>

      {debugMode && <DebugFacePredictionModel />}
      {debugMode && <DebugCameraImageBuffer />}
      {debugMode && <DebugControlPanel />}
    </>
  );
};
