import { keyframes } from '@emotion/react';
import React, { useCallback, useState } from 'react';

import { Container } from 'components/ui/Container';
import { ModalProps } from '../types';

const openModalKeyframes = keyframes({
  '0%': {
    transform: 'scale(0.4)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

type Props = {
  sx?: ModalProps['sx'];
  children: ModalProps['children'];
  size: NonNullable<ModalProps['size']>;
  fullHeight: boolean;
  className?: string;
};

const defaultProps: Partial<Props> = {
  sx: undefined,
};

export const ModalContainer = ({ children, sx, size, fullHeight, ...props }: Props): React.ReactElement => {
  const [isAnimationComplete, setAnimationComplete] = useState(false);

  const getMaxHeight = useCallback(() => {
    switch (size) {
      case 'xs':
        return '48%';
      case 'sm':
        return '86%';
      case 'lg':
        return '92%';
      case 'xl':
        return '96%';
      case 'fullscreen':
        return '96%';
      case 'default':
      default:
        return '86%';
    }
  }, [size]);

  return (
    <Container
      {...props}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
      onAnimationEnd={() => {
        setAnimationComplete(true);
      }}
      sx={{
        ...(!isAnimationComplete && {
          transform: 'scale(0.4)', // Matches the start of the animation
          animation: `${openModalKeyframes} 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.045s forwards`,
        }),
        maxHeight: getMaxHeight(),
        maxWidth: '100%',
        bg: 'modal.background',
        borderRadius: 'default',
        boxShadow: 'modal.normal',
        justifyContent: 'space-between',
        overflow: 'hidden',
        minHeight: '25%',
        ...(fullHeight && { height: '100%' }),
        ...(size === 'fullscreen' && {
          height: '100%',
          alignSelf: 'flex-end',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }),
        '@media screen and (max-width: 767px)': {
          ...(size !== 'xs' && {
            alignSelf: 'flex-end',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxHeight: '97%',
            height: '100%',
            boxShadow: 'modal.reversed',
          }),
        },
        '@media screen and (min-width: 1600px)': {
          ...(size === 'sm' && { maxHeight: '72%' }),
        },
        ...(sx && sx),
      }}
    >
      {children}
    </Container>
  );
};

ModalContainer.defaultProps = defaultProps;
