import { Trans } from '@lingui/macro';
import React from 'react';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { ExtendedTimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { Divider } from 'components/Divider/Divider';
import { WorkStatusBadge } from 'components/recipes/WorkStatusBadge';
import { PersonCell } from 'pages/Reports/output/PersonCell';
import { ReportsExtendedTimeEvent } from 'state/reports';
import { getEventFormattedDateTime } from '../../../utils/getEventFormattedDateTime';

type Props = {
  extTimeEvent: ExtendedTimeEvent | ReportsExtendedTimeEvent;
  hidePersonCell?: boolean;
  sx?: ThemeUIStyleObject;
};

const defaultProps = {
  sx: undefined,
};

export const EventDetails = ({ extTimeEvent, hidePersonCell, sx }: Props): React.ReactElement | null => {
  if (!extTimeEvent) return null;

  const { employeeName, avatarUrl, role, tags, timeUtc, timeRealUtc, typeId, typeName, isEnd } = extTimeEvent;

  const { eventDate, eventTime } = getEventFormattedDateTime(timeUtc);
  const { eventTime: eventRealTime } = getEventFormattedDateTime(timeRealUtc);

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: 3,
        ...(sx && sx),
      }}
    >
      {!hidePersonCell && (
        <Flex sx={{ flex: 1, minWidth: '50%', maxWidth: '100%' }}>
          <PersonCell
            name={employeeName}
            role={role}
            tags={tags}
            avatarUrl={avatarUrl}
            avatarSize={34}
            sx={{ '.person_cell__tags_wrapper': { flexWrap: 'wrap' } }}
            roleTagSx={{ maxWidth: '100%' }}
          />
        </Flex>
      )}
      <Flex sx={{ maxWidth: '100%' }}>
        <Flex sx={{ flexDirection: 'column', gap: '0.12rem', flexShrink: 0 }} className="event-details__date">
          <Text sx={{ fontWeight: 'bold' }} variant="heading4">
            <Trans id="clock_log.date_time">Date & Time</Trans>
          </Text>
          <Text sx={{ lineHeight: '1.625rem' }}>
            <span>{`${eventDate}, ${eventTime}`}</span>
            {timeRealUtc && <span>{` (${eventRealTime})`}</span>}
          </Text>
        </Flex>

        <Divider axis="vertical" sx={{ mx: '1.5rem', height: 'auto' }} />

        <Flex sx={{ flexDirection: 'column', gap: '0.12rem' }} className="event-details__badge">
          <Text sx={{ fontWeight: 'bold' }} variant="heading4">
            <Trans id="clock_log.type">Type</Trans>
          </Text>
          <WorkStatusBadge workStatus={{ id: typeId, name: typeName }} isEnd={isEnd} />
        </Flex>
      </Flex>
    </Flex>
  );
};

EventDetails.defaultProps = defaultProps;
