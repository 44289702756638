// export const DEFAULT_UPLOAD_TIMEOUT_THRESHOLD = 15000;
export const DEFAULT_UPLOAD_TIMEOUT_THRESHOLD = Number(process.env.REACT_APP_FETCH_TIMEOUT_THRESHOLD) || 600000;

export const DEFAULT_MAX_UPLOAD_SIZE = 2097152;
export const DEFAULT_MIN_UPLOAD_SIZE = 0;

export const DEFAULT_MAX_FILES = 10;
export const DEFAULT_MIN_FILES = 0;

export const DEFAULT_MAX_NAME_LENGTH = 150;

export const DEFAULT_MAX_NAME_DISPLAY_LENGTH = 26;

export const DEFAULT_ACCEPTED_EXTENSIONS = [
  'jpg',
  'jpeg',
  'gif',
  'bmp',
  'png',
  'pdf',
  'xls',
  'xlsx',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'txt',
];

export enum FILE_TYPES {
  'pdf' = 0,
  'zip' = 1,
  'csv' = 2,
  'ics' = 3,
  'jpg' = 4,
  'png' = 5,
  'jpeg' = 6,
  'gif' = 7,
  'bmp' = 8,
  'xls' = 9,
  'doc' = 10,
  'docx' = 11,
  'ppt' = 12,
  'pptx' = 13,
  'txt' = 14,
  'xlsx' = 15,
  'xml' = 17,
  'unknown' = 100,
}
