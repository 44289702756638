import React, { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { FetchTimeTrackingSettingsResponse } from 'api/actions/settings/settingsActions.types';
import { blockTransitionAtom } from 'state/settings';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useFormSubmit } from '../../../../hooks/useFormSubmit';
import { useSettingsBlockRouteTransition } from '../../../../hooks/useSettingsBlockRouteTransition';
import { FormProviderCustomEvents } from '../../../FormProviderCustomEvents/FormProviderCustomEvents';
import { useTimeTrackingSettings } from '../../hooks/useTimeTrackingSettings';

import { TimeTrackingFormsContainer } from './components/TimeTrackingFormsContainer/TimeTrackingFormsContainer';
import { useSubmitTimeTrackingSettings } from './hooks/useSubmitTimeTrackingSettings';

export const TimeTrackingForms = (): React.ReactElement => {
  const resetBlockTransition = useResetRecoilState(blockTransitionAtom);
  const setBlockTransition = useSetRecoilState(blockTransitionAtom);
  const { timeTrackingSettings, setTimeTrackingSettings } = useTimeTrackingSettings();

  const formRef = useRef<HTMLFormElement | null>(null);

  const { dispatchSubmitEvent, throttledDispatchSubmitEvent } = useFormSubmit(formRef);

  const methods = useForm<FetchTimeTrackingSettingsResponse>({
    defaultValues: timeTrackingSettings || undefined,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    // FIXME: Error focus doesn't allow to scroll or close the select witn an error.
    shouldFocusError: false,
  });

  const onChangeAndOnBlur = useCallback(() => {
    setBlockTransition((prevState) => ({
      ...prevState,
      blockLocationPathname: null,
      blockTransition: true,
    }));
    throttledDispatchSubmitEvent.cancel();
    throttledDispatchSubmitEvent();
  }, [setBlockTransition, throttledDispatchSubmitEvent]);

  const handleFormReset = useCallback(() => {
    setTimeTrackingSettings(null);
    resetBlockTransition();
  }, [resetBlockTransition, setTimeTrackingSettings]);

  const { handleSubmitCallback, handleErrorCallback } = useSubmitTimeTrackingSettings({
    handleFormReset,
    getCurrentFormState: methods.getValues,
  });

  useSettingsBlockRouteTransition(dispatchSubmitEvent, throttledDispatchSubmitEvent);

  return (
    <FormProviderCustomEvents {...methods} onChange={onChangeAndOnBlur} onBlur={onChangeAndOnBlur}>
      <form
        ref={formRef}
        onSubmit={floatingPromiseReturn(methods.handleSubmit(handleSubmitCallback, handleErrorCallback))}
      >
        <TimeTrackingFormsContainer>
          <TimeTrackingFormsContainer.Overtime />
          <TimeTrackingFormsContainer.PayPeriod />
          <TimeTrackingFormsContainer.Breaks />
          <TimeTrackingFormsContainer.TimeClocks />
          <TimeTrackingFormsContainer.Other />
        </TimeTrackingFormsContainer>
      </form>
    </FormProviderCustomEvents>
  );
};
