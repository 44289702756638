import { ThemeUIStyleObject } from 'theme-ui';

import { makeTheme } from '../makeTheme';

const DEFAULT_TRANSITION = '0.15s ease-in-out';

const baseContainerStyles: ThemeUIStyleObject = {
  px: 2,
  alignItems: 'center',
  justifyContent: 'stretch',
  gap: 3,
  maxWidth: '260px',
  width: '100%',
  height: '48px',
  borderRadius: 'sm',
  bg: 'fileUpload.bg.uploader.default',
  cursor: 'pointer',
  fontSize: 2,
  fontWeight: 'bold',
  color: 'fileUpload.text.default',
  transition: `all ${DEFAULT_TRANSITION}`,
};

export const fileUploadStyles = makeTheme({
  fileUpload: {
    errorMessage: {
      display: 'flex',
      width: '100%',
      color: 'fileUpload.text.error',
      fontWeight: 'bold',
      fontSize: 2,
    },
    icon: {
      borderRadius: 'sm',
      bg: 'fileUpload.bg.icon.default',
      height: '1.875rem',
      width: '1.875rem',
      justifyContent: 'center',
      transition: `all ${DEFAULT_TRANSITION}`,
    },
    smallText: {
      fontSize: 0,
      fontWeight: '400',
      color: 'fileUpload.text.lighter',
      opacity: 0.7,
    },
    progressBar: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      bg: 'fileUpload.bg.progressBar.default',
      borderRadius: 'inherit',
      zIndex: -1,
      '&[data-has-error="true"]': {
        bg: 'fileUpload.bg.progressBar.error',
      },
    },

    uploader: {
      ...baseContainerStyles,
      outline: '1px dashed',
      outlineColor: 'alphas.darker5',
      '> .heading': {
        '> span': {
          transition: `all ${DEFAULT_TRANSITION}`,
        },
      },
      '&:hover, &[data-state="droppable"]': {
        bg: 'fileUpload.bg.uploader.hover',
        outlineColor: 'fileUpload.uploader',
        '> .icon': {
          bg: 'fileUpload.bg.icon.hover',
        },
      },
      '&:hover': {
        '> .heading': {
          '> span': {
            opacity: 0.24,
            '&:first-of-type': {
              opacity: 1,
            },
          },
        },
      },
      '&[data-state="droppable"]': {
        '> .heading': {
          '> span': {
            opacity: 0.24,
            '&:last-of-type': {
              opacity: 1,
            },
          },
        },
      },
    },

    file: {
      ...baseContainerStyles,
      bg: 'fileUpload.bg.file.default',
      alignItems: 'center',
      outline: '1px solid',
      outlineColor: 'fileUpload.file.outline.default',
      display: 'flex',
      position: 'relative',
      cursor: 'default',
      '&[data-state="uploading"], &[data-state="withError"]': {
        zIndex: 1,
        gap: 1,
      },
      '&[data-state="withError"]': {
        outlineColor: 'fileUpload.file.outline.withError',
      },
      '&[data-state="uploading"]': {
        outlineColor: 'fileUpload.file.outline.uploading',
      },
      '&[data-state="notFound"]': {
        outlineColor: 'fileUpload.file.outline.notFound',
        bg: 'fileUpload.bg.file.error',
      },
      '&[data-state="uploaded"]': {
        cursor: 'pointer',
        '&:hover': {
          bg: 'fileUpload.bg.file.hover',
          outlineColor: 'fileUpload.file.outline.uploadedHover',
          boxShadow: 'fileUpload',
          '> .icon': {
            bg: 'fileUpload.bg.icon.hover',
          },
        },
      },
      '&[data-state="defaultAttachment"]': {
        cursor: 'pointer',
      },
      '&[data-state="success"]': {
        cursor: 'pointer',
        bg: 'fileUpload.bg.file.success',
        outlineColor: 'fileUpload.file.outline.success',
        '> .icon': {
          bg: 'fileUpload.bg.icon.success',
        },
      },
      '&[data-state="danger"]': {
        cursor: 'pointer',
        bg: 'fileUpload.bg.file.danger',
        outlineColor: 'fileUpload.file.outline.danger',
        '> .icon': {
          bg: 'fileUpload.bg.icon.danger',
        },
      },
    },
  },
});
