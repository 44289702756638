import React, { useMemo } from 'react';
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui';
import { Plural, Trans } from '@lingui/macro';
import { useRecoilValue } from 'recoil';

import { PieProgressBar } from 'components/PieProgressBar/PieProgressBar';
import { useTheme } from 'styles/useTheme';
import { activeSubscriptionSelector } from 'state/organizationSession';
import { OrganizationAccountState, PaymentMethodEnum } from 'api/actions/payments/paymentsActions.types';
import { dateTime } from 'utils/dateTime';

const DAYS_BEFORE_PAYMENT = 14;
const DAYS_MAX = 30;

type Props = {
  sx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  sx: undefined,
};

export const PaymentTicker = ({ sx }: Props): React.ReactElement => {
  const { theme } = useTheme();
  const activeSubscription = useRecoilValue(activeSubscriptionSelector);

  const daysLeft = useMemo(() => {
    if (!activeSubscription) return null;

    const { endDate } = activeSubscription;
    const endDateAbsoluteTime = dateTime(endDate, { utc: true });
    const presentDateLocalTime = dateTime();

    const dateDifference = endDateAbsoluteTime.diff(presentDateLocalTime, 'day') + 1;

    return dateDifference <= 0 ? 0 : dateDifference;
  }, [activeSubscription]);

  const type = useMemo(() => {
    if (daysLeft && daysLeft <= 3) {
      return 'danger';
    }

    if (daysLeft && daysLeft <= 7) {
      return 'warning';
    }

    return 'default';
  }, [daysLeft]);

  const text = useMemo(() => {
    if (!activeSubscription) return <></>;

    const { state } = activeSubscription;

    if (state === OrganizationAccountState.Testing) {
      return (
        <Trans id="navbar.payment_ticker.label">
          <Plural value={daysLeft || 0} one="day until " other="days until " />
          <Text as="span" sx={{ fontSize: 'inherit', display: 'block' }}>
            trial ends
          </Text>
        </Trans>
      );
    }

    return (
      <Trans id="navbar.payment_ticker.label_paid">
        <Plural value={daysLeft || 0} one="day until " other="days until " />
        <Text as="span" sx={{ fontSize: 'inherit', display: 'block' }}>
          subscription ends
        </Text>
      </Trans>
    );
  }, [activeSubscription, daysLeft]);

  const shouldDisplayTicker = useMemo(() => {
    if (!activeSubscription || (daysLeft && daysLeft > DAYS_MAX)) return false;

    const { isActive, state, method, daysLeft: activeSubscriptionDaysLeft } = activeSubscription;

    if (state === OrganizationAccountState.Testing && daysLeft && activeSubscriptionDaysLeft) return true;

    if (daysLeft && daysLeft > DAYS_BEFORE_PAYMENT) return false;

    if (
      [PaymentMethodEnum.Proforma, PaymentMethodEnum.PayU, PaymentMethodEnum.WireTransfer].includes(method) ||
      (state === OrganizationAccountState.Paid && !isActive) ||
      state === OrganizationAccountState.Testing
    )
      return true;

    return false;
  }, [activeSubscription, daysLeft]);

  const pieMax = useMemo(() => {
    if (!activeSubscription) return DAYS_BEFORE_PAYMENT;
    const { state, daysLeft: activeSubscriptionDaysLeft } = activeSubscription;
    if (
      state === OrganizationAccountState.Testing &&
      activeSubscriptionDaysLeft &&
      activeSubscriptionDaysLeft > DAYS_BEFORE_PAYMENT
    )
      return activeSubscriptionDaysLeft;
    return DAYS_BEFORE_PAYMENT;
  }, [activeSubscription]);

  if (!activeSubscription || !daysLeft || !shouldDisplayTicker) return <></>;

  return (
    <Flex
      sx={{
        bg: theme.colors.paymentTicker[type].background,
        userSelect: 'none',
        borderRadius: 'pill',
        p: '2px',
        pr: 2,
        alignContent: 'space-between',
        justifyContent: 'center',
        flexShrink: 0,
        gap: 1,
        ...sx,
      }}
    >
      <PieProgressBar
        size="36px"
        borderWidth={3}
        background={theme.colors.paymentTicker[type].counter}
        fill={theme.colors.paymentTicker[type].background}
        value={daysLeft}
        valueMax={pieMax}
        reverse
      >
        <Text sx={{ fontSize: 3, fontWeight: 'bold', color: theme.colors.paymentTicker[type].text }}>{daysLeft}</Text>
      </PieProgressBar>
      <Flex sx={{ alignItems: 'center', ml: '3px' }}>
        <Text
          sx={{
            fontSize: 0,
            color: theme.colors.paymentTicker[type].text,
            lineHeight: '10px',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  );
};

PaymentTicker.defaultProps = defaultProps;
