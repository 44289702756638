/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/require-await */
import { RequestInterceptor } from 'react-fetching-library';

import { Action } from '../types';

export const requestAddTimestampUnixInterceptor: () => RequestInterceptor =
  () => () => async (action: Action<unknown>) => ({
    ...action,
    config: {
      ...(action.config && action.config),
      sentTimestampUnix: Date.now(),
    },
  });
