/** @jsxImportSource theme-ui */

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { DeepPartial, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { setRoleAction } from 'api/actions/employees/employeesActions';
import { SetRoleActionProps } from 'api/actions/employees/employeesActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { Button } from 'components/ui/Buttons/Button';
import { Checkbox } from 'components/ui/Checkbox';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { RolesSelect } from 'components/ui/Select/variants/RolesSelect';
import { PATH_REL, TO_REL } from 'constants/routes';
import { useLocationOnce } from 'hooks/useLocationOnce/useLocationOnce';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { languageSelector } from 'state/recoilState';
import { assignableRolesSelectOptionsSelector } from 'state/team';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { ManageRolesModal } from '../../role/ManageRoles';

export const SetRole: FC = () => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const language = useRecoilValue(languageSelector);
  const [loading, setLoading] = useState(false);
  const rolesSelectOptions = useRecoilValue(assignableRolesSelectOptionsSelector);
  const {
    state: { ids },
  } = useLocationOnce();
  const [selectedEmployeesIds] = useState(ids || []);
  const formRef = useRef<HTMLFormElement | null>(null);

  const { handleClose, baseRoute } = useModal();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      roleId: undefined,
      overwrite: false,
    },
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const { mutate } = useMutation(setRoleAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit: SubmitHandler<DeepPartial<Omit<SetRoleActionProps, 'employeesIds'>>> = useCallback(
    async (body) => {
      const { roleId, overwrite } = body;

      if (!selectedEmployeesIds || !selectedEmployeesIds.length || !roleId) return false;

      const { error: submitError } = await mutate({
        employeesIds: selectedEmployeesIds,
        ...body,
        roleId,
        overwrite: overwrite || false,
      });

      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }

      setLoading(false);

      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.set_role_bulk.set', message: 'Successfully set!' }),
          variant: 'success',
        });
      }

      return true;
    },
    [handleClose, mutate, selectedEmployeesIds, addSnackbar],
  );
  const onSubmitError = () => {
    setLoading(false);
  };

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  if (!rolesSelectOptions) return <Navigate to={baseRoute} relative="path" />;

  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.set_role_bulk">Set role</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          sx={{ width: '340px', maxWidth: '100%' }}
          ref={formRef}
          onSubmit={floatingPromiseReturn(handleSubmit(onSubmit, onSubmitError))}
          noValidate
        >
          <Text sx={{ ml: 2, mt: 2, fontSize: 1, fontWeight: 'bold' }}>
            <Trans id="team.set_role_bulk.role">Role</Trans> |{' '}
            <LinkAnchor replace standOut sx={{ textDecoration: 'underline' }} to={TO_REL.MANAGE_ROLES_MODAL[language]}>
              <Trans id="team.set_role_bulk.manage">Manage</Trans>
            </LinkAnchor>
          </Text>

          <RolesSelect
            size="sm"
            id="roleId"
            placeholder={t({ id: 'team.set_role_bulk.role', message: 'Role' })}
            error={!!errors.roleId}
            errorMessage={errors?.roleId?.message}
            searchable
            options={rolesSelectOptions}
            {...register('roleId', { required: t({ id: 'global.forms.required' }) })}
          />
          <br />
          <Checkbox
            labelWrapperSx={{ ml: -2 }}
            label={t({ id: 'team.set_role_bulk.overwrite', message: 'Overwrite existing role settings' })}
            size="sm"
            {...register('overwrite')}
          />
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button sx={{ fontWeight: 'normal' }} shape="rounded" variant="minimal" onClick={() => handleClose()}>
          <Trans id="cancel">Cancel</Trans>
        </Button>

        <Button isLoading={loading} onClick={handleSave} variant="primary" shape="rounded">
          <Trans id="save">Save</Trans>
        </Button>
      </Modal.Footer>

      <Routes>
        <Route
          path={PATH_REL.MANAGE_ROLES_MODAL[language]}
          element={
            <Modal replaceMode size="lg" fullHeight>
              <ManageRolesModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
