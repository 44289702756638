import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { MemoizedDualCalendar } from 'components/ui/DualCalendar/DualCalendar';
import { dateTime } from 'utils/dateTime';
import { dualCalendarRegisterOptionsFactory } from 'utils/dualCalendarRegisterOptionsFactory';
import { AddRequestExtendedFormContext } from '../../../../../../../types';

export const Calendar = (): React.ReactElement | null => {
  const { register, setValue } = useFormContext<AddRequestExtendedFormContext>();

  useEffect(
    () => () => {
      setValue('dateTimeDetails.date', undefined);
    },
    [setValue],
  );

  return (
    <MemoizedDualCalendar
      {...register('dateTimeDetails.date', dualCalendarRegisterOptionsFactory({ isRange: false }))}
      minDate={dateTime(undefined).add(1, 'day').startOf('day').utc(true)}
    />
  );
};
