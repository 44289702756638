import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { HelpCenterModal } from 'Kiosk/modals/HelpCenterModal';
import { LocationModal } from 'Kiosk/modals/LocationModal';
import { SettingsModal } from 'Kiosk/modals/SettingsModal';
import { Modal } from 'components/Modal/output/Modal';
import { PATH_REL } from 'constants/routes';
import { languageSelector } from 'state/recoilState';

export const ModalRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <Routes>
      <Route
        path={PATH_REL.KIOSK_SETTINGS_MODAL[language]}
        element={
          <Modal size="sm" fullHeight>
            <SettingsModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.KIOSK_LOCATION_MODAL[language]}
        element={
          <Modal>
            <LocationModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.KIOSK_HELP_CENTER_MODAL__STEP[language]}
        element={
          <Modal size="sm" fullHeight path={PATH_REL.KIOSK_HELP_CENTER_MODAL__STEP[language]}>
            <HelpCenterModal />
          </Modal>
        }
      />
    </Routes>
  );
};
