import { t } from '@lingui/macro';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, FlexProps, Text, ThemeUIStyleObject } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { Timer } from 'components/utils/Timer';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { activeTimeEventSelector } from 'state/drawer';
import { isLocationRequiredSelector } from 'state/organizationSession';
import { useIsFetchingTimeTrackerAfterVisibilityChange } from '../Drawer/TimeTracker/hooks/useIsFetchingTimeTrackerAfterVisibilityChange';

export type NavbarWorkProps = {
  to?: string;
  sx?: ThemeUIStyleObject;
  className?: string;
};

const defaultProps: Partial<NavbarWorkProps> = {
  sx: undefined,
  to: undefined,
};

export const NavbarTimeEventTimer = ({ sx, to, ...props }: NavbarWorkProps): React.ReactElement => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isFetchingTimeTrackerAfterVisibilityChange = useIsFetchingTimeTrackerAfterVisibilityChange();

  const activeTimeEvent = useRecoilValue(activeTimeEventSelector);
  const isLocationRequired = useRecoilValue(isLocationRequiredSelector);

  const navigate = useAppNavigate();
  const { pathname } = useLocation();
  const { isVisible: isOnboardingVisible, playNextStep, playCustomNextStep } = useInitialOnboarding();

  const onMouseOver = useCallback(() => setIsHovered(true), []);
  const onMouseOut = useCallback(() => setIsHovered(false), []);

  const handleActive = useCallback(() => {
    if (activeTimeEvent) {
      const { postTimeEvent, id, isActive, variant, initialTime } = activeTimeEvent;

      if (isActive && variant === 'work' && to) {
        navigate(to, { state: { from: pathname } });

        // IMPORTANT: Onboarding - next step if drawer opens
        if (isOnboardingVisible) {
          if (isLocationRequired) {
            playCustomNextStep(2);
          } else {
            playNextStep();
          }
        }
      } else {
        setIsLoading(true);
        void postTimeEvent({
          timeEventTypeId: id,
          isEnd: isActive,
          initialTime,
        }).finally(() => setIsLoading(false));
      }
    }
  }, [
    activeTimeEvent,
    isLocationRequired,
    isOnboardingVisible,
    pathname,
    playCustomNextStep,
    playNextStep,
    navigate,
    to,
  ]);

  const handleOnKeyDown: NonNullable<FlexProps['onKeyDown']> = useCallback(
    (e) => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        handleActive();
      }
    },
    [handleActive],
  );

  const returnIconFill = useCallback(() => {
    if (!activeTimeEvent) return 'workTimer.icon.fill.default';

    const { variant, isActive } = activeTimeEvent;

    if (variant === 'work' && isHovered && isActive) return 'workTimer.icon.fill.activeHover';
    if (variant === 'work' && !isHovered && isActive) return 'workTimer.icon.fill.active';
    if (variant === 'work' && !isHovered) return 'workTimer.icon.fill.default';
    if (variant === 'work' && isHovered) return 'workTimer.icon.fill.hover';
    return 'workTimer.icon.fill.custom';
  }, [activeTimeEvent, isHovered]);

  if (!activeTimeEvent) return <></>;

  const { isActive, initialTime, variant, name } = activeTimeEvent;

  return (
    <Flex
      {...props}
      aria-label={`${t({ id: 'navbar_time_tracker.title', message: 'Time tracker' })} - ${t({ id: name })} - ${
        isActive ? 'stop' : 'start'
      }`}
      role="button"
      tabIndex={0}
      data-hover={isHovered}
      data-active={isActive}
      variant={`navbarTimeEventTimer.container.${variant}`}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={handleActive}
      onKeyDown={handleOnKeyDown}
      sx={{
        ...(sx && sx),
      }}
    >
      {(isLoading || isFetchingTimeTrackerAfterVisibilityChange) && <LoadingOverlay size={2} />}
      <Icon size={36} type={isActive ? 'stopCircle' : 'playCircle'} fill={returnIconFill()} />
      <Flex variant={`navbarTimeEventTimer.content.${variant}`} data-hover={isHovered} data-active={isActive}>
        <TextEllipsis sx={{ p: 0, m: 0, fontSize: 0, lineHeight: 'workTimer' }}>
          {t({
            id: name,
          })}
        </TextEllipsis>
        <Text variant="navbarTimeEventTimer.timer">
          <Timer key={`${initialTime}${isActive}`} initialTime={initialTime} active={isActive} />
        </Text>
      </Flex>
    </Flex>
  );
};

NavbarTimeEventTimer.defaultProps = defaultProps;
