import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { filterGroupStateAtomFamily } from 'state/filters';
import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';

import { FilterGroupNames } from './types';

export const RequestTypeFilterObserver: React.ElementType = () => {
  const [filterGroupState, setFilterGroupState] = useRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.REQUEST_TYPES),
  );

  const filterGroupStateRef = useCallbackRef(filterGroupState);
  const setFilterGroupStateRef = useCallbackRef(setFilterGroupState);

  useEffect(() => {
    let hadTimeTrackingFilter = false;

    const filterGroupStateRefCurrent = filterGroupStateRef.current;
    const setFilterGroupStateRefCurrent = setFilterGroupStateRef.current;

    if (filterGroupStateRefCurrent && filterGroupStateRefCurrent.includes(`${RequestFormType.TimeTracking}`)) {
      const filteredTimeTrackingFilter = filterGroupStateRefCurrent.filter(
        (id) => id !== `${RequestFormType.TimeTracking}`,
      );
      setFilterGroupStateRefCurrent(filteredTimeTrackingFilter.length > 0 ? filteredTimeTrackingFilter : null);

      hadTimeTrackingFilter = true;
    }

    return () => {
      if (hadTimeTrackingFilter) {
        setFilterGroupStateRefCurrent((prevState) =>
          prevState ? [...prevState, `${RequestFormType.TimeTracking}`] : [`${RequestFormType.TimeTracking}`],
        );
      }
    };
  }, [filterGroupStateRef, setFilterGroupStateRef]);

  return null;
};
