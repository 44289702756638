import { atom } from 'recoil';

import { GetInfoResponse } from 'api/actions/timeclock/timeclockActions.types';

export enum KioskOverlay {
  start = 'START',
  summary = 'SUMMARY',
  initialFaceScan = 'INITIAL_FACE_SCAN',
}

type OverlayState =
  | {
      type: KioskOverlay.start;
    }
  | {
      type: KioskOverlay.initialFaceScan;
      state: {
        name: string;
        qrCode: string;
      };
    }
  | SummaryOverlyState;

export type SummaryOverlyState = {
  type: KioskOverlay.summary;
  state: {
    getInfoPayload: GetInfoResponse;
    qrCode: string;
    eventImage: Blob;
  };
};

export const overlayStateAtom = atom<OverlayState>({
  key: 'kiosk__overlayState',
  default: {
    type: KioskOverlay.start,
  },
});
