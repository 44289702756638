import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from 'theme-ui';

import { Switch } from 'components/ui/Switch';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useTimeOffFormState } from '../hooks/useTimeOffFormState';

export const SkipSwitches = (): React.ReactElement | null => {
  useLingui();

  const { isDurationType, isForDayType } = useTimeOffFormState();

  const { register, watch } = useFormContext<AddRequestExtendedFormContext>();

  const forWholeDayWatch = watch('isDateBound');

  const skipSwitchesVisible = useMemo(() => {
    if (isDurationType || !forWholeDayWatch || isForDayType) return false;

    return true;
  }, [forWholeDayWatch, isDurationType, isForDayType]);

  if (!skipSwitchesVisible) return null;

  return (
    <>
      <Switch
        label={t({
          id: 'requests.add_request.skip_weekends',
          message: 'Skip weekends',
        })}
        additionalInfo={
          <Text>
            <Trans id="requests.add_request.skip_weekends_description">
              All weekends will be reduced from the requests duration
            </Trans>
          </Text>
        }
        size="sm"
        bold
        {...register('details.ignoreWeekends')}
      />
      <Switch
        label={t({
          id: 'requests.add_request.skip_holidays',
          message: 'Skip holidays',
        })}
        additionalInfo={
          <Text>
            <Trans id="requests.add_request.skip_holidays_description">
              All holidays will be reduced from the requests duration
            </Trans>
          </Text>
        }
        size="sm"
        bold
        {...register('details.ignoreHolidays')}
      />
    </>
  );
};
