import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

type ColumnsProps = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type Props = FlexProps & {
  children?: React.ReactElement[] | React.ReactElement | React.ReactNode;
  columns?: ColumnsProps | (ColumnsProps | null)[];
  maxColumns?: number;
};

const defaultProps: Partial<Omit<Props, 'maxColumns'>> & Required<Pick<Props, 'maxColumns'>> = {
  columns: undefined,
  maxColumns: 12,
  children: undefined,
};

export type ColumnProps = Props;

export const Column = React.forwardRef<HTMLDivElement, Props>(
  ({ children, columns, maxColumns = defaultProps.maxColumns, ...props }: Props, ref) => {
    const prepareWidth = () => {
      if (Array.isArray(columns)) {
        const cols = columns.flatMap((column) => (column ? `${(100 / maxColumns) * column}%` : null));
        return { width: cols };
      }

      return columns ? { width: ['100%', `${(100 / maxColumns) * columns}%`] } : {};
    };
    return (
      <Flex ref={ref} {...props} sx={{ ...(columns ? prepareWidth() : { flexGrow: 1 }), ...props.sx }}>
        {children}
      </Flex>
    );
  },
);

Column.defaultProps = defaultProps;
