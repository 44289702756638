import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Modal } from 'components/Modal/output/Modal';
import { PATH_REL } from 'constants/routes';
import { languageSelector } from 'state/recoilState';

import { AddHolidayModal } from './components/modals/AddHolidayModal';
import { DeleteHolidayModal } from './components/modals/DeleteHolidayModal';
import { EditHolidayModal } from './components/modals/EditHolidayModal';
import { ImportHolidaysModal } from './components/modals/ImportHolidaysModal';

export const OrganizationModalRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <Routes>
      <Route
        path={PATH_REL.ADD_HOLIDAY_MODAL[language]}
        element={
          <Modal size="sm">
            <AddHolidayModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.EDIT_HOLIDAY_MODAL[language]}
        element={
          <Modal size="sm" path={PATH_REL.EDIT_HOLIDAY_MODAL[language]}>
            <EditHolidayModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.IMPORT_HOLIDAYS_MODAL[language]}
        element={
          <Modal size="sm" path={PATH_REL.IMPORT_HOLIDAYS_MODAL[language]}>
            <ImportHolidaysModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.DELETE_HOLIDAY_MODAL[language]}
        element={
          <Modal size="xs" path={PATH_REL.DELETE_HOLIDAY_MODAL[language]}>
            <DeleteHolidayModal />
          </Modal>
        }
      />
    </Routes>
  );
};
