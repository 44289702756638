import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { fetchApprovalListsAction } from 'api/actions/requests/requestsActions';
import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { PersonSelect } from 'components/ui/Select/variants/PersonSelect';
import { ALL_SUPERVISORS_ID } from 'constants/requests';
import { useMount } from 'hooks/useMount/useMount';
import { organizationSessionAtom } from 'state/organizationSession';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';

export const ProcessedSelects = React.memo((): React.ReactElement | null => {
  useLingui();
  const { query, loading } = useParameterizedQuery(fetchApprovalListsAction);
  const {
    formState: {
      requestAcceptationSelect: { firstStepPeopleIds, secondStepPeopleIds },
    },
    setFormState,
    addRequestState: { requestType },
    selectedTeammatesIds,
  } = useAddRequest();
  const organizationSession = useRecoilValue(organizationSessionAtom);

  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<AddRequestExtendedFormContext>();

  const [processedByIdWatch, optionalStepProcessedByIdWatch, autoAcceptRequestWatch] = watch([
    'processedById',
    'optionalStepProcessedById',
    'autoAcceptRequest',
  ]);

  useMount(() => {
    const getApprovalList = async () => {
      if (selectedTeammatesIds.length === 1) {
        const { error, payload } = await query({ personId: selectedTeammatesIds[0], requestType });

        if (!error && payload) {
          setFormState((prevState) => ({
            ...prevState,
            requestAcceptationSelect: {
              firstStepPeopleIds: payload.firstStepPeopleIds,
              secondStepPeopleIds: payload.secondStepPeopleIds,
            },
          }));

          // To jest zabezpieczenie jeżeli ktoś utraci możliwość akceptacji wniosku a dodawanie było zminimalizowane
          if (
            processedByIdWatch &&
            processedByIdWatch !== ALL_SUPERVISORS_ID &&
            !payload.firstStepPeopleIds.includes(processedByIdWatch)
          ) {
            setValue('processedById', null as unknown as undefined);
          }

          if (
            optionalStepProcessedByIdWatch &&
            optionalStepProcessedByIdWatch !== ALL_SUPERVISORS_ID &&
            !payload.secondStepPeopleIds?.includes(optionalStepProcessedByIdWatch)
          ) {
            setValue('optionalStepProcessedById', null as unknown as undefined);
          }
        }
      }
    };

    void getApprovalList();
  });

  const shouldDisplayOptionalProcessed = useMemo(() => {
    if (!organizationSession) return false;

    const {
      timeOffApprovalSettings,
      customRequestsApprovalSettings,
      businessTripsApprovalSettings,
      timeTrackingApprovalSettings,
      schedulesApprovalSettings,
    } = organizationSession;

    if (requestType === RequestFormType.TimeOff && !timeOffApprovalSettings.twoStepApprovalFlow) {
      return false;
    }

    if (requestType === RequestFormType.Custom && !customRequestsApprovalSettings.twoStepApprovalFlow) {
      return false;
    }

    if (requestType === RequestFormType.BusinessTrip && !businessTripsApprovalSettings.twoStepApprovalFlow) {
      return false;
    }

    if (requestType === RequestFormType.TimeTracking && !timeTrackingApprovalSettings.twoStepApprovalFlow) {
      return false;
    }

    if (requestType === RequestFormType.Schedule && !schedulesApprovalSettings.twoStepApprovalFlow) {
      return false;
    }

    return true;
  }, [organizationSession, requestType]);

  const firstStepEmployees = useMemo(
    () => _.filter(firstStepPeopleIds, (id) => id !== optionalStepProcessedByIdWatch),
    [firstStepPeopleIds, optionalStepProcessedByIdWatch],
  );

  const secondStepEmployees = useMemo(
    () => _.filter(secondStepPeopleIds, (id) => id !== processedByIdWatch),
    [processedByIdWatch, secondStepPeopleIds],
  );

  if (selectedTeammatesIds.length !== 1 || autoAcceptRequestWatch) return null;

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text sx={{ mb: 2, fontWeight: 'bold' }}>
        <Trans id="add_request.send_to">Send request to</Trans>
      </Text>
      <Flex sx={{ gap: 4 }}>
        <PersonSelect
          {...register('processedById', { required: t({ id: 'global.forms.required' }) })}
          id="processedById"
          placeholder={t({ id: 'requests.select_supervisor' })}
          employeesIds={firstStepEmployees}
          size="sm"
          loadingOptions={loading}
          errorMessage={errors.processedById?.message}
          error={!!errors.processedById}
        />
        {shouldDisplayOptionalProcessed && (
          <PersonSelect
            {...register('optionalStepProcessedById', { required: t({ id: 'global.forms.required' }) })}
            id="optionalStepProcessedById"
            placeholder={t({ id: 'requests.select_supervisor' })}
            employeesIds={secondStepEmployees}
            size="sm"
            loadingOptions={loading}
            errorMessage={errors.optionalStepProcessedById?.message}
            error={!!errors.optionalStepProcessedById}
          />
        )}
      </Flex>
    </Flex>
  );
});
