import { FC, Suspense, lazy, CSSProperties, memo } from 'react';
import { Flex } from 'theme-ui';
import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';
import { useRecoilValue } from 'recoil';

import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { fullTimeFormatSelector } from 'state/recoilState';
import { dateTime } from 'utils/dateTime';

import { AttendancePillPersonContentProps } from './AttendancePillPersonContent';

const LazyAttendancePillPersonContent = lazy(() =>
  import('./AttendancePillPersonContent').then(({ AttendancePillPersonContent: AttendancePillPerson }) => ({
    default: AttendancePillPerson,
  })),
);

type Props = {
  style: CSSProperties;
  person: AttendancePillPersonContentProps;
};
const FlexWithTooltip = withTooltip(Flex);

export const AttendancePillPerson: FC<Props> = ({ style, person, ...props }) => {
  useLingui();
  const timeFormat = useRecoilValue(fullTimeFormatSelector);

  return (
    <FlexWithTooltip
      variant="attendancePillPerson.container"
      style={style}
      {...props}
      data-late={person?.isLate}
      data-has-error={person?.isPresent === false || person?.isWorkTimeDuringTimeOff}
      {...(person?.isLate && {
        tooltipProps: {
          content: person?.workStartTimeUnix
            ? `${t({ id: 'attendance_pill_person.work_start_time', message: 'Scheduled start time -' })} ${dateTime(person.workStartTimeUnix).format(timeFormat)}`
            : t({ id: 'attendance_pill_person.employee_late', message: 'The employee was late to work.' }),
        },
      })}
      {...(person?.isWorkTimeDuringTimeOff && {
        tooltipProps: {
          content: t({
            id: 'attendance_pill_person.employee_working_during_absence',
            message: 'The employee is working during his/her absence day.',
          }),
        },
      })}
    >
      <Suspense fallback={<></>}>
        <LazyAttendancePillPersonContent {...person} />
      </Suspense>
    </FlexWithTooltip>
  );
};

export const MemoizedAttendancePillPerson = memo(AttendancePillPerson);
