import React, { useRef, useState } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { useIsMountedRef } from 'hooks/useIsMountedRef/useIsMountedRef';
import { useMount } from 'hooks/useMount/useMount';

type Props = {
  duration: number; // in seconds
  durationProgress: number; // in seconds
  uninterupted?: boolean; // if true, changes in durationProgress will be ignored and progress will run till the end taking the time specified in duration prop
  barSx?: ThemeUIStyleObject;
  wrapperSx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  barSx: undefined,
  wrapperSx: undefined,
};

const ProgressBar = ({
  duration,
  durationProgress,
  uninterupted = true,
  barSx,
  wrapperSx,
}: Props): React.ReactElement => {
  const [progress, setProgress] = useState(durationProgress);

  const isDecrementalRef = useRef(duration === durationProgress);

  const transitionDuration = uninterupted ? duration : 1;

  const isMountedRef = useIsMountedRef();

  const getCurrentFill = () => {
    const currentProgress = uninterupted ? progress : durationProgress;
    const percentage = Math.floor((currentProgress * 100) / duration);
    return `${Math.max(percentage, 0)}%`;
  };

  useMount(() => {
    if (!uninterupted) return;
    setTimeout(() => {
      if (!isMountedRef.current) return;
      const endProgress = isDecrementalRef.current ? 0 : duration;
      setProgress(endProgress);
    }, 50);
  });

  return (
    <Box
      sx={{
        width: '90%',
        mx: '5%',
        bg: 'kiosk.progressBar.bg',
        borderRadius: 'pill',
        ...(wrapperSx && wrapperSx),
      }}
    >
      <Box
        sx={{
          height: '5px',
          bg: 'kiosk.progressBar.progress',
          borderRadius: 'pill',
          width: getCurrentFill(),
          transition: `width linear ${transitionDuration}s`,
          ...(barSx && barSx),
        }}
      />
    </Box>
  );
};

ProgressBar.defaultProps = defaultProps;

export const MemorizedProgressBar = React.memo(ProgressBar);
