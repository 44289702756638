import { UUID } from 'utils/UUID';

import { notificationPublisher } from './publisher';
import { AddNotificationProps, NotificationInsertTypes, RemoveNotification } from './types';

export const addNotification = (notification: AddNotificationProps): void => {
  const defaultNotificationProps = {
    id: UUID(),
    insert: 'top' as NotificationInsertTypes,
    duration: 7000,
    isStatic: false,
  };

  notificationPublisher.addNotification({
    ...defaultNotificationProps,
    ...notification,
  });
};

export const removeNotification: RemoveNotification = (id, options) => {
  if (notificationPublisher.removeNotification) {
    notificationPublisher.removeNotification(id, options);
  }
};

export const removeAllNotifications = (): void => {
  if (notificationPublisher.removeAllNotifications) {
    notificationPublisher.removeAllNotifications();
  } else {
    throw new Error('removeAllNotifications must be used within an NotificationProvider');
  }
};
