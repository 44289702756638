import { t } from '@lingui/macro';
import { isEqual } from 'lodash';
import { FC } from 'react';
import { Flex, Text } from 'theme-ui';

import { ParsedRequest } from 'state/requests';
import { useRequestDetails } from '../../../../../hooks/useRequestDetails';
import {
  newComparisonDifferenceSx,
  originalComparisonDifferenceSx,
  requestChangeTextEventSx,
  requestChangeTextTileSx,
  requestChangeWrapperSx,
} from '../styles/styles';

type Props = {
  requestDetails: ParsedRequest | null;
};

const OriginalPlace: FC<Props> = ({ requestDetails }) => (
  <Text
    sx={{
      ...requestChangeTextEventSx,
      ...(requestDetails?.newEvent &&
        !isEqual(requestDetails?.newEvent?.eventDetails.place, requestDetails?.originalEvent?.eventDetails.place) &&
        originalComparisonDifferenceSx),
    }}
  >
    {requestDetails?.originalEvent?.eventDetails.place}
  </Text>
);

const NewPlace: FC<Props> = ({ requestDetails }) => (
  <Text
    sx={{
      ...requestChangeTextEventSx,
      ...(requestDetails?.originalEvent &&
        !isEqual(requestDetails?.newEvent?.eventDetails.place, requestDetails?.originalEvent?.eventDetails.place) &&
        newComparisonDifferenceSx),
    }}
  >
    {requestDetails?.newEvent?.eventDetails.place}
  </Text>
);

export const RequestChangePlace: FC = () => {
  const { parsedRequestDetails: requestDetails } = useRequestDetails();

  return (
    <Flex sx={requestChangeWrapperSx}>
      <Text sx={requestChangeTextTileSx}>{t({ id: 'add_request.business_trip.place' })}</Text>
      <OriginalPlace requestDetails={requestDetails} />
      <NewPlace requestDetails={requestDetails} />
    </Flex>
  );
};
