import React from 'react';

import { RequestChangeDetailsContainer } from './RequestChangeDetails/RequestChangeDetailsContainer';

export const BusinessRequestChange = (): React.ReactElement => (
  <RequestChangeDetailsContainer>
    <RequestChangeDetailsContainer.Name />
    <RequestChangeDetailsContainer.Date />
    <RequestChangeDetailsContainer.Place />
    <RequestChangeDetailsContainer.Note />
    <RequestChangeDetailsContainer.Duration />
    <RequestChangeDetailsContainer.Attachments />
  </RequestChangeDetailsContainer>
);
