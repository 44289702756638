import React from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { UUID } from 'utils/UUID';

type Props = {
  sx?: ThemeUIStyleObject;
  children: React.ReactNode | string;
  appendElements?: React.ReactNode[] | string[];
};

const defaultProps: Partial<Props> = {
  sx: undefined,
  appendElements: undefined,
};

export const FancyDataBox = ({ appendElements, children, sx }: Props): React.ReactElement => (
  <Flex variant="fancyDataBox.container" sx={{ alignItems: 'center', ...(sx && sx) }}>
    {children}
    {appendElements &&
      appendElements[0] &&
      appendElements.map((element) => (
        <Flex key={UUID()} variant="fancyDataBox.time">
          {element}
        </Flex>
      ))}
  </Flex>
);

FancyDataBox.defaultProps = defaultProps;
