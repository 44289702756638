/**
 * Creates an event that can be then dispatched from a DOM element.
 * Mainly used for dispatching events from inputs in low level UI components.
 *
 * @param {string} eventName The name of the event that will be created.
 * @return {Event} Returns an event object.
 */

export function createEvent(eventName: string): Event {
  let event;
  if (typeof window.Event === 'function') {
    event = new window.Event(eventName, { bubbles: true, cancelable: true });
  } else {
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }
  return event;
}
