import React from 'react';
import { ThemeUIStyleObject, Flex } from 'theme-ui';

type Props = {
  children: React.ReactNode;
  sxOuter?: ThemeUIStyleObject;
  sxInner?: ThemeUIStyleObject;
  blurBackground?: boolean;
  darkenBackground?: boolean;
};

const defaultProps: Partial<Props> = {
  sxOuter: undefined,
  sxInner: undefined,
  blurBackground: false,
  darkenBackground: false,
};

export const OverlayContainer = ({
  children,
  sxOuter,
  sxInner,
  blurBackground,
  darkenBackground,
  ...props
}: Props): React.ReactElement => (
  <Flex
    {...props}
    as="main"
    sx={{
      justifyContent: 'center',
      alignItems: 'stretch',
      position: 'relative',
      height: '100%',
      zIndex: 1,
      ...(sxOuter && sxOuter),
      ...(blurBackground && {
        pt: [null, null, '5rem'],
        backgroundColor: 'kiosk.overlay.bg.default',
        ...(blurBackground && {
          '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
            backgroundColor: 'kiosk.overlay.bg.noBackdrop',
            backdropFilter: 'blur(14px)',
          },
        }),
      }),
      ...(darkenBackground &&
        !blurBackground && {
          backgroundColor: 'kiosk.overlay.bg.darken',
        }),
    }}
  >
    <Flex
      {...props}
      sx={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        overflowY: 'auto',
        ...(sxInner && sxInner),
      }}
    >
      {children}
    </Flex>
  </Flex>
);

OverlayContainer.defaultProps = defaultProps;
