import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Flex, Text } from 'theme-ui';

import { OvertimeDetails } from 'api/actions/requests/requestsActions.types';
import { FancyDataBox } from 'components/ui/FancyDataBox';
import { UUID } from 'utils/UUID';
import { useRequestsLogic } from '../../../../../../hooks/useRequestsLogic';
import { getDurationObj } from '../../../../../../utils/getDurationObj';

const prepareDuration = (hours: number, minutes: number): string => {
  let s = '';

  if (hours) s = `${hours}h`;
  if (minutes) s += ` ${minutes}m`;

  return s;
};

type Props = {
  overtime: OvertimeDetails[];
};

export const Overtime = ({ overtime }: Props): React.ReactElement => {
  useLingui();
  const {
    displayer: { displayDate },
  } = useRequestsLogic();

  return (
    <Flex sx={{ flexDirection: 'column', gap: 2 }}>
      <Text
        sx={{
          fontWeight: 'bold',
          fontSize: 3,
        }}
      >
        <Trans id="request.details.section.overtime">Overtime from</Trans>
      </Text>
      <Flex sx={{ flexWrap: 'wrap', gap: 1 }}>
        {overtime.map((element) => {
          const { dateUnix, duration } = element;
          const { minutes, hours } = getDurationObj(duration);

          return (
            <FancyDataBox key={UUID()} appendElements={[prepareDuration(hours, minutes)]} sx={{ fontSize: 2 }}>
              {displayDate({ dateUnix, isDateBound: false })}
            </FancyDataBox>
          );
        })}
      </Flex>
    </Flex>
  );
};
