import { useLingui } from '@lingui/react';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { APPLE_CLIENT_ID, APPLE_LOGIN_URL } from 'constants/common';
import { useAppleScript } from 'hooks/useAppleScript/useAppleScript';

const appleLoginParams = {
  clientId: APPLE_CLIENT_ID,
  redirectURI: APPLE_LOGIN_URL,
  scope: 'openid email name',
};

export const useAppleIntegration = () => {
  useLingui();
  const { loaded: appleScriptLoaded, AppleID } = useAppleScript();

  useEffect(() => {
    if (appleScriptLoaded && AppleID) {
      AppleID.auth.init(appleLoginParams);
    }
  }, [AppleID, appleScriptLoaded]);

  const handleAppleOnClick = () => {
    if (appleScriptLoaded && AppleID) {
      // GTM DATA LAYER (LOGIN-SUBMIT / APPLE)
      TagManager.dataLayer({
        dataLayer: {
          event: 'login-submit',
          method: 'apple',
        },
      });

      AppleID.auth.signIn();
    }
  };

  return { handleAppleOnClick };
};
