import React from 'react';

import { Button, ButtonProps, LinkButton, LinkButtonProps } from 'components/ui/Buttons';
import { ButtonSharedProps } from 'components/ui/Buttons/types';

type FilterButtonProps = Omit<ButtonProps, 'bgOverwrite' | 'shape' | 'size' | 'variant'>;
type FilterGroupButtonProps = FilterButtonProps & { isActive?: boolean };

type FilterLinkButtonProps = Omit<LinkButtonProps, 'bgOverwrite' | 'shape' | 'size' | 'variant'>;

const commonProps: Partial<ButtonSharedProps> = {
  size: 'sm',
  shape: 'rounded',
  variant: 'naked',
  bgOverwrite: {
    default: 'asideFilters.btn.common.default',
    hover: 'asideFilters.btn.common.hover',
    tap: 'asideFilters.btn.common.tap',
    disabled: 'asideFilters.btn.common.disabled',
  },
};

export const FilterGroupButton = React.forwardRef<HTMLButtonElement, FilterGroupButtonProps>(
  ({ children, isActive, ...props }: FilterGroupButtonProps, ref): React.ReactElement => (
    <Button
      key={`${isActive}`}
      ref={ref}
      {...props}
      {...commonProps}
      sx={{
        px: 1,
        ...(isActive && { color: 'primary' }),
        ...props.sx,
      }}
      {...(isActive && {
        bgOverwrite: {
          default: 'asideFilters.btn.group.default',
          hover: 'asideFilters.btn.group.hover',
          tap: 'asideFilters.btn.group.tap',
          disabled: 'asideFilters.btn.group.disabled',
        },
      })}
    >
      {children}
    </Button>
  ),
);

export const FilterLinkButton = ({ children, ...props }: FilterLinkButtonProps): React.ReactElement => (
  <LinkButton {...props} {...commonProps} sx={{ justifyContent: 'flex-start', px: 1 }} fullWidth>
    {children}
  </LinkButton>
);

export const FilterButton = React.forwardRef<HTMLButtonElement, FilterButtonProps>(
  ({ children, ...props }: FilterButtonProps, ref) => (
    <Button
      ref={ref}
      {...props}
      {...commonProps}
      bgOverwrite={{
        default: 'asideFilters.btn.filter.default',
        hover: 'asideFilters.btn.filter.hover',
        tap: 'asideFilters.btn.filter.tap',
        disabled: 'asideFilters.btn.filter.disabled',
      }}
      sx={{
        px: 1,
        ...props.sx,
        cursor: props.disabled ? 'default' : 'pointer',
      }}
    >
      {children}
    </Button>
  ),
);
