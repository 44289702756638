import { FC } from 'react';
import { Flex } from 'theme-ui';

import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';

import { useGoogleIntegration } from './hooks/useGoogleIntegration';

export const GoogleButton: FC = () => {
  const { googleButtonRef } = useGoogleIntegration();
  return (
    <Flex ref={googleButtonRef} sx={{ maxHeight: '40px', width: '40px' }} key="google">
      <Flex
        sx={{
          flex: '1 0',
          bg: 'signIn.loading',
          borderRadius: 'xs',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingSpinnerCss size={1.5} />
      </Flex>
    </Flex>
  );
};
