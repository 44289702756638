import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';

import { nameDisplayOrderSelector } from 'state/userSession';
import { NameDisplayOrder } from 'api/actions/userSession/userSessionActions.types';

type GetFullName = (firstName: string, surname: string) => string;

/**
 * Returns a function that will generate full name taking into acount the nameDisplayOrder.
 */
export const useNameDisplayOrder = (): GetFullName => {
  const nameDisplayOrder = useRecoilValue(nameDisplayOrderSelector);

  const getFullName: GetFullName = useCallback(
    (firstName, surname) =>
      nameDisplayOrder === NameDisplayOrder.SurnameFirst ? `${surname} ${firstName}` : `${firstName} ${surname}`,
    [nameDisplayOrder],
  );

  return getFullName;
};
