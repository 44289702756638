/** @jsxImportSource theme-ui */
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useMount } from 'hooks/useMount/useMount';
import { addNoteDisabledSaveAtom, addNoteLoadingSaveAtom } from 'state/clockLog';
import { userSessionAtom } from 'state/userSession';
import { useFindExtEvent } from '../hooks/useFindExtEvent';

import { ADD_EVENT_NOTE_FORM_ID, AddEventNoteBody } from './components/AddEventNoteBody';

export const AddEventNoteModal = (): React.ReactElement => {
  useLingui();

  const { id } = useParams<{ id: string }>();
  const extTimeEvent = useFindExtEvent(id || '');
  const userSession = useRecoilValue(userSessionAtom);
  const navigate = useAppNavigate();
  const { baseRoute } = useModal();
  const { modulesManagement } = useAppPermissions();

  const isLoadingSave = useRecoilValue(addNoteLoadingSaveAtom);
  const isDisabledSave = useRecoilValue(addNoteDisabledSaveAtom);

  useMount(() => {
    if (!(extTimeEvent?.personId === userSession?.personId) && !modulesManagement.TimeTracking)
      navigate(baseRoute, { relative: 'path' });
  });

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="clock_log.add_note">Add note</Trans>
        </Modal.Title>
      </Modal.Header>

      {extTimeEvent ? <AddEventNoteBody extTimeEvent={extTimeEvent} /> : <CenteredLoadingSpinner size={5} />}

      <BasicModalFooter
        buttons={[
          {
            variant: 'primary',
            children: t({ id: 'clock_log.add', message: 'Add' }),
            type: 'submit',
            form: ADD_EVENT_NOTE_FORM_ID,
            isLoading: isLoadingSave,
            disabled: isDisabledSave,
          },
        ]}
      />
    </>
  );
};
