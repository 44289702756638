import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { setRequestsViewSettings } from 'api/actions/requests/requestsActions';
import { RequestsHideableColumns } from 'api/actions/userSession/userSessionActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { Switch } from 'components/ui/Switch';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { requestsViewSettingsSelector } from 'state/requests';

export const RequestsViewSettingsModal = (): React.ReactElement => {
  const [loading, setLoading] = useState(false);

  const hiddenColumns = useRecoilValue(requestsViewSettingsSelector);

  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();
  useLingui();
  const { watch, register } = useForm({
    defaultValues: {
      [RequestsHideableColumns.number]: !hiddenColumns.includes(RequestsHideableColumns.number),
      [RequestsHideableColumns.createDateTimeUnix]: !hiddenColumns.includes(RequestsHideableColumns.createDateTimeUnix),
    },
  });
  const formState = watch();

  const newHiddenColumns = useMemo(() => _.keys(_.pickBy(formState, (val, key) => !val && key)), [formState]);

  const { mutate } = useMutation(setRequestsViewSettings);

  const handleSave = async () => {
    setLoading(true);

    const { error } = await mutate({
      hiddenColumns: newHiddenColumns,
    });

    if (!error) {
      addSnackbar({
        message: t({ id: 'team.view_settings.edited' }),
        variant: 'success',
      });

      if (handleClose) {
        handleClose();
      }
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>{t({ id: 'team.view_settings' })}</Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ gap: 3 }}>
        <Switch
          size="sm"
          sx={{ fontWeight: '400' }}
          {...register(RequestsHideableColumns.number)}
          label={t({ id: 'requests.show_request_number', message: 'Show request number' })}
        />
        <Switch
          size="sm"
          sx={{ fontWeight: '400' }}
          {...register(RequestsHideableColumns.createDateTimeUnix)}
          label={t({ id: 'requests.show_create_date', message: 'Show create date' })}
        />
      </Modal.Body>

      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            disabled: _.isEqual(hiddenColumns, newHiddenColumns),
            onClick: () => void handleSave(),
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
