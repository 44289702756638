import { useCallback } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState, useRecoilValue } from 'recoil';

import { fetchExternalProviderAction } from 'api/actions/integrations/integrationActions';
import {
  ExternalLoginActionSource,
  ExternalLoginProvider,
  ExternalProvider,
} from 'api/actions/integrations/integrationActions.types';
import { APPLE_CLIENT_ID, APPLE_LINK_URL } from 'constants/common';
import { TO_REL } from 'constants/routes';
import { useAppleScript } from 'hooks/useAppleScript/useAppleScript';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { languageSelector } from 'state/recoilState';
import { externalIntegrationsAtom } from 'state/settings';

const params = {
  clientId: APPLE_CLIENT_ID,
  redirectURI: APPLE_LINK_URL,
  scope: 'openid',
};

export const useExternalIntegrations = () => {
  const { query } = useClient();
  const [externalIntegrations, setExternalIntegrations] = useRecoilState(externalIntegrationsAtom);
  const language = useRecoilValue(languageSelector);
  const navigate = useAppNavigate();
  const { loaded, error: appleError, AppleID } = useAppleScript();

  const fetchExternalIntegrations = useCallback(
    async (actionSource: ExternalLoginActionSource) => {
      const { error, payload } = await query(fetchExternalProviderAction({ actionSource }));

      if (!error) {
        if (payload) {
          setExternalIntegrations(payload);
        }
      }
    },
    [query, setExternalIntegrations],
  );

  const connectExternalProvider = useCallback(
    (integration: ExternalProvider) => {
      switch (integration.provider) {
        case ExternalLoginProvider.Google:
        case ExternalLoginProvider.GoogleInewi:
        case ExternalLoginProvider.GoogleTrackTime: {
          navigate(TO_REL.GOOGLE_LINK[language]);
          break;
        }
        case ExternalLoginProvider.AppleLinkInewi:
        case ExternalLoginProvider.AppleLinkTrackTime:
        case ExternalLoginProvider.AppleLoginInewi:
        case ExternalLoginProvider.AppleLoginTrackTime: {
          if (loaded && AppleID && !appleError) {
            AppleID.auth.init(params);
            AppleID.auth.signIn();
          }
          break;
        }
        default: {
          if (integration.actionUrl) {
            window.open(integration.actionUrl, '_self');
          }
          break;
        }
      }
    },
    [AppleID, appleError, language, loaded, navigate],
  );

  return {
    externalIntegrations,
    setExternalIntegrations,
    fetchExternalIntegrations,
    connectExternalProvider,
    isInitialized: !!externalIntegrations?.length,
  };
};
