import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TextInput, TextInputProps } from 'components/ui/TextInput';
import { INPUT_DEBOUNCE_TIME } from 'constants/requests';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';

export const Name = (): React.ReactElement => {
  useLingui();

  const {
    register,
    formState: { errors },
  } = useFormContext<{ details: { name: string } }>();

  const nameRegister = register(
    'details.name',
    validationFactory({
      ...VALIDATION_RULES.BUSINESS_TRIP_NAME,
      required: true,
    }),
  );

  const handleOnChange: NonNullable<TextInputProps['onChange']> = _.debounce<NonNullable<TextInputProps['onChange']>>(
    (e) => {
      void nameRegister.onChange(e);
    },
    INPUT_DEBOUNCE_TIME,
  );

  return (
    <TextInput
      id="name"
      type="text"
      {..._.omit(nameRegister, 'onChange')}
      onChange={handleOnChange}
      label={t({ id: 'global.forms.name' })}
      labelProps={{ sx: { fontSize: 3, ml: -2, mb: 2 } }}
      placeholder={t({
        id: 'add_request.business_trip.name.placeholder',
        message: 'Business trip fancy name',
      })}
      variant="rounded"
      size="sm"
      error={!!errors.details?.name}
      errorMessage={errors.details?.name?.message}
    />
  );
};
