import { Trans } from '@lingui/macro';
import React, { useCallback, useContext, useEffect } from 'react';
import { ClientContext } from 'react-fetching-library';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { fetchTimeZoneListAction } from 'api/actions/data/dataActions';
import { AccountInfoCompany } from 'api/actions/user/userActions.types';
import { FormCard } from 'components/FormCard/FormCard';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { TO } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useFetchCountryDetailsManager } from 'hooks/useFetchCountryDetailsManager/useFetchCountryDetailsManager';
import { useIndustry } from 'pages/Settings/output/useIndustry';
import { languageSelector } from 'state/recoilState';
import { signUpFormAtom, timeZoneListAtom } from 'state/signUp';

import { EmployerStep2Form } from './components/EmployerStep2Form';

export const EmployerStep2 = React.forwardRef<HTMLFormElement>((props, ref) => {
  const navigate = useAppNavigate();

  const language = useRecoilValue(languageSelector);
  const [timeZoneList, setTimeZoneList] = useRecoilState(timeZoneListAtom);
  const [signUpFormState, setSignUpFormState] = useRecoilState(signUpFormAtom);
  useFetchCountryDetailsManager(true);

  const { industryList, fetchIndustryList } = useIndustry();

  const { query } = useContext(ClientContext);

  useEffect(() => {
    const getTimeZoneList = async () => {
      const { payload, error: fetchError } = await query(fetchTimeZoneListAction());
      if (!fetchError && payload) {
        setTimeZoneList(payload);
      }
    };
    if (!timeZoneList) {
      void getTimeZoneList();
    }
  }, [query, timeZoneList, setTimeZoneList]);

  useEffect(() => {
    if (!industryList) {
      void fetchIndustryList();
    }
  }, [fetchIndustryList, industryList]);

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (body: AccountInfoCompany): Promise<boolean> => {
      setSignUpFormState({ ...signUpFormState, ...body });
      navigate(`${TO.SIGN_UP__EMPLOYER__STEP[language]}/3`, {
        state: {
          signUp: {
            inProgress: true,
          },
        },
      });
      return true;
    },
    [setSignUpFormState, signUpFormState, navigate, language],
  );

  return (
    <>
      <FormCard.Header>
        <FormCard.Title>
          <Trans id="sign_up.employer.step_3.lead.header">
            Nice to meet you{' '}
            <Text as="span" sx={{ textTransform: 'capitalize' }}>
              {signUpFormState?.firstName}
            </Text>
            ! 😊
          </Trans>
        </FormCard.Title>
        <FormCard.Lead>
          <Trans id="sign_up.employer.step_3.lead.text">Tell us about your company</Trans>
        </FormCard.Lead>
      </FormCard.Header>

      {timeZoneList && industryList ? (
        <EmployerStep2Form ref={ref} onSubmit={onSubmit} />
      ) : (
        <Flex sx={{ alignItems: 'center', justifyContent: 'center', flexGrow: 1, minHeight: '200px' }}>
          <LoadingSpinnerCss size={3} />
        </Flex>
      )}
    </>
  );
});
