import { detectOverflow, Options, ModifierArguments } from '@popperjs/core';
import { Modifier } from 'react-popper';

export const maxSizeModifier: Modifier<'maxSize', Options> = {
  name: 'maxSize',
  enabled: true,
  phase: 'beforeWrite',
  requiresIfExists: ['offset', 'preventOverflow', 'flip'],
  fn({ state, name, options }) {
    const overflow = detectOverflow(state, options);

    const { y } = state.modifiersData.preventOverflow || { x: 0, y: 0 };
    const { height } = state.rects.popper;
    const [basePlacement] = state.placement.split('-');

    const heightProp = basePlacement === 'top' ? 'top' : 'bottom';

    state.modifiersData[name] = {
      maxHeight: height - overflow[heightProp] - y,
    };

    const { maxHeight } = state.modifiersData.maxSize;

    state.styles.popper = {
      ...state.styles.popper,
      maxHeight: `${maxHeight}px`,
    };
  },
};

export const getCloseOnScrollModifier = (close: () => void, isVisible: boolean) => {
  let fired = false;
  let scrollY: number | null = null;
  let scrollX: number | null = null;

  return {
    name: 'getCloseOnScroll',
    enabled: true,
    phase: 'main' as const,
    requiresIfExists: ['offset'],
    fn: ({ state }: ModifierArguments<Options>) => {
      const overflow = detectOverflow(state);

      if (!fired) {
        scrollY = overflow.bottom;
        scrollX = overflow.right;
        fired = true;
        return;
      }

      const hasBeenScrolled = scrollY !== overflow.bottom || scrollX !== overflow.right;
      if (hasBeenScrolled && isVisible) {
        scrollY = null;
        scrollX = null;
        fired = false;
        close();
      }
    },
  };
};
