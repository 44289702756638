import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, Heading, Text } from 'theme-ui';

import { calendarViewSettings } from 'api/actions/calendar/calendarActions';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { ElementGroup } from 'components/ui/ElementGroup';
import { Switch } from 'components/ui/Switch';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { calendarViewSettingsSelector } from 'state/calendar';
import { timeOffTypesSelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

export const CalendarViewSettingsModal = (): React.ReactElement => {
  useLingui();

  const { addSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { handleClose } = useModal();
  const viewSettings = useRecoilValue(calendarViewSettingsSelector);
  const timeOffs = useRecoilValue(timeOffTypesSelector);
  const language = useRecoilValue(languageSelector);
  const [visibleLimits, setVisibleLimits] = useState(viewSettings.personCellVisibleLimits);

  const { watch, register, setValue } = useForm({ defaultValues: viewSettings });

  const formState = watch();

  const newViewSettings = useMemo(
    () => ({
      ...formState,
    }),
    [formState],
  );

  const { mutate } = useMutation(calendarViewSettings);

  const handleSave = async () => {
    setLoading(true);

    const { error } = await mutate(newViewSettings);

    if (!error) {
      addSnackbar({
        message: t({ id: 'team.view_settings.edited', message: 'Successfully edited!' }),
        variant: 'success',
      });

      if (handleClose) {
        handleClose();
      }
    }

    setLoading(false);
  };

  const handleVisibleLimits = useMemo(
    () =>
      _.debounce((id: string) => {
        setVisibleLimits((prevLimits) =>
          prevLimits.includes(id) ? prevLimits.filter((limitId) => limitId !== id) : [...prevLimits, id],
        );
      }, 50),
    [],
  );

  useEffect(() => {
    setValue('personCellVisibleLimits', visibleLimits);
  }, [setValue, visibleLimits]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.view_settings">View settings</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Switch
          sx={{ fontWeight: '400' }}
          {...register('groupSchedulesByHours')}
          label={t({ id: 'calendar.group_schedules_by_hours', message: 'Group schedules by hours' })}
          size="sm"
        />
        <Flex sx={{ flexDirection: 'column', mt: 4, mb: 3 }}>
          <Heading variant="heading5">
            <Trans id="calendar.show_time_off_limits_and_usage">Show time off limits and usage on person cell:</Trans>
          </Heading>
          <Text sx={{ color: 'calendar.viewSettings', fontSize: 2 }}>
            <Trans id="calendar.max_two_types">Maximum 2 types</Trans>
          </Text>
        </Flex>
        <ElementGroup showAsList marginValue="0.5rem" direction="column">
          {timeOffs
            ?.slice()
            .sort((a, b) => t({ id: a.abbreviation }).localeCompare(t({ id: b.abbreviation }), language))
            .map((timeOff) => {
              const { id, name, abbreviation, isActive } = timeOff;

              if (!isActive) return null;

              const includedInLimits = visibleLimits.includes(id);

              return (
                <Switch
                  key={id}
                  name={id}
                  label={`${t({ id: name })} (${t({ id: abbreviation })})`}
                  defaultChecked={includedInLimits}
                  disabled={!includedInLimits && visibleLimits.length === 2}
                  onChange={() => handleVisibleLimits(id)}
                  size="sm"
                />
              );
            })}
        </ElementGroup>
      </Modal.Body>

      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            disabled: _.isEqual(viewSettings, newViewSettings),
            onClick: floatingPromiseReturn(handleSave),
            variant: 'primary',
            children: t({ id: 'save', message: 'Save' }),
          },
        ]}
      />
    </>
  );
};
