import { t } from '@lingui/macro';
import React, { useEffect } from 'react';
import { useQuery } from 'react-fetching-library';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { fetchTimeEventHistoryAction } from 'api/actions/timeEvent/timeEventActions';
import { ExtendedTimeEvent, TimeEventActionResponse } from 'api/actions/timeEvent/timeEventActions.types';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';

import { EventHistoryRow } from './components/EventHistoryRow';

type Props = {
  timeEventId: ExtendedTimeEvent['id'];
  extTimeEvent?: ExtendedTimeEvent;
  sx?: ThemeUIStyleObject;
};

export const EventHistory = ({ timeEventId, extTimeEvent, sx }: Props): React.ReactElement => {
  const { loading, payload, error, abort } = useQuery(fetchTimeEventHistoryAction(timeEventId));

  useEffect(() => {
    if (extTimeEvent) abort();
  }, [abort, extTimeEvent]);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyItems: 'flexStart',
        alignItems: 'flexStart',
        fontSize: 2,
        position: 'relative',
        minHeight: '3rem',
        ...(sx && sx),
      }}
    >
      {extTimeEvent ? (
        <EventHistoryRow
          action={TimeEventActionResponse.Create}
          modificationPersonId={extTimeEvent.creatorId}
          modificationDateTime={extTimeEvent.createDateTimeUtc}
        />
      ) : (
        <>
          {loading && <LoadingOverlay size={3} sx={{ zIndex: 'base' }} />}

          {error && <Text sx={{ textAlign: 'center' }}>{t({ id: 'error.something_went_wrong' })}</Text>}

          {payload && !error && (
            <>
              {payload
                .slice(0)
                .reverse()
                .map((historyChunk) => {
                  const { current, action } = historyChunk;
                  return (
                    <EventHistoryRow
                      key={current?.id}
                      action={action}
                      modificationPersonId={historyChunk.current?.modificationPersonId}
                      modificationDateTime={historyChunk.current?.modificationDateTime}
                      previous={historyChunk.previous}
                    />
                  );
                })}
            </>
          )}
        </>
      )}
    </Flex>
  );
};
