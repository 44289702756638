import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';

import { HeadingTip } from '../HeadingTip';
import { SettingsLoadingSpinnerCss } from '../SettingsLoadingSpinnerCss';

import { NotificationsForm } from './components/NotificationsForm';
import { useNotificationsCleanUp } from './hooks/useNotificationsCleanUp';
import { useNotificationsSettings } from './hooks/useNotificationsSettings';

export const Notifications = (): React.ReactElement => {
  useLingui();
  const { notificationsSettings, fetchNotifications } = useNotificationsSettings();

  useEffect(() => {
    if (!notificationsSettings) {
      void fetchNotifications();
    }
  }, [fetchNotifications, notificationsSettings]);

  useNotificationsCleanUp();

  return (
    <>
      <HeadingTip
        label={t({
          id: 'settings.user.notifications.header',
          message: 'Notifications',
        })}
      />
      {notificationsSettings ? <NotificationsForm /> : <SettingsLoadingSpinnerCss />}
    </>
  );
};
