import { t } from '@lingui/macro';
import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, FlexProps, Text } from 'theme-ui';

import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { Button } from 'components/ui/Buttons';
import { ScheduleType } from 'constants/calendar';
import { TO_REL } from 'constants/routes';
import { ScheduleUserSelectableColor } from 'constants/userSelectableColors';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useContextMenu } from 'hooks/useContextMenu/useContextMenu';
import { FancyDataBox } from 'pages/Reports/output/FancyDataBox';
import { languageSelector } from 'state/recoilState';
import { formattedDurationFromSeconds } from 'utils/dateTime';

const ButtonWithDropdown = withDropdown(Button);

type Props = {
  scheduleId: string;
  type?: ScheduleType;
  isDeleted?: boolean;
  isAi?: boolean;
  scheduleColor?: ScheduleUserSelectableColor;
  isPending?: boolean;
  isOvertimeSchedule?: boolean;
  countOvertimeBeforeWork?: boolean;
  countOvertimeAfterWork?: boolean;
  withAutoBreak?: boolean;
  autoBreakDuration?: number;
  note?: string;
  workPosition?: string;
  endDateTimeUnix?: number;
  startDateTimeUnix?: number;
  workTimeUnix?: number;
  hideButtons?: boolean;
  hoverable?: boolean;
} & Pick<FlexProps, 'sx' | 'onClick'>;

export type DetailedScheduleElementProps = Props;

export const DetailedScheduleElement = ({
  sx,
  type,
  isOvertimeSchedule,
  countOvertimeAfterWork,
  countOvertimeBeforeWork,
  autoBreakDuration,
  scheduleColor,
  withAutoBreak,
  isPending,
  isDeleted,
  isAi,
  note,
  endDateTimeUnix,
  startDateTimeUnix,
  workPosition,
  workTimeUnix,
  scheduleId,
  hideButtons,
  hoverable = true,
  onClick,
  ...props
}: Props): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const { modulesManagement } = useAppPermissions();

  const withOvertime = useMemo(
    () => countOvertimeAfterWork || countOvertimeBeforeWork,
    [countOvertimeAfterWork, countOvertimeBeforeWork],
  );

  const dropdownLinks = useCallback(() => {
    const arr = [];

    if (isPending) {
      arr.push({
        label: t({ id: 'calendar.publish' }),
        to: `${TO_REL.PUBLISH_SCHEDULE__ID[language]}/${scheduleId}`,
        icon: 'send' as Icons,
        className: 'onboarding-schedule-6',
      });
    }

    if (!isDeleted) {
      arr.push({
        label: t({ id: 'global.delete', message: 'Delete' }),
        to: TO_REL.DELETE_SCHEDULE[language],
        state: { ids: [scheduleId] },
        icon: 'delete' as Icons,
      });
    }

    return arr;
  }, [isDeleted, isPending, language, scheduleId]);

  const contextMenuId = useMemo(
    () => (!hideButtons && dropdownLinks().length > 0 ? scheduleId : undefined),
    [dropdownLinks, hideButtons, scheduleId],
  );
  const { openContextMenu } = useContextMenu(contextMenuId);

  return (
    <Flex
      {...props}
      variant="calendar.detailedSchedule.container"
      data-pending={isPending}
      sx={{ ...sx }}
      onClick={!isDeleted ? onClick : undefined}
      data-deleted={isDeleted}
      data-is-ai={isAi && modulesManagement.Schedules}
      data-with-hover={hoverable}
      onContextMenu={openContextMenu}
    >
      {/* IS DELETED RIBBON */}
      {isDeleted && (
        <Flex variant="calendar.detailedSchedule.ribbonElement">{t({ id: 'work_statuses.status.deleted' })}</Flex>
      )}
      {/* COLOR */}
      <Flex
        variant="calendar.detailedSchedule.colorBox"
        data-overtime-schedule={isOvertimeSchedule}
        data-pending={isPending}
        data-type={type !== undefined && ScheduleType[+type]}
        sx={{
          bg: scheduleColor !== undefined ? ScheduleUserSelectableColor[scheduleColor] : undefined,
        }}
      />

      {/* DETAILS */}
      <Flex sx={{ flex: '1 0 ', flexDirection: 'column', gap: 1 }}>
        <Flex sx={{ width: '100%', flexDirection: 'row', gap: '0.125rem' }}>
          <FancyDataBox
            sx={{ flex: '1 0' }}
            bold
            variant="naked"
            title={
              type === ScheduleType.NotStandardized
                ? t({ id: 'schedule.flexible_type', message: 'Flexible' })
                : undefined
            }
            range={
              startDateTimeUnix && endDateTimeUnix
                ? { fromUnix: startDateTimeUnix, toUnix: endDateTimeUnix }
                : undefined
            }
            value={workTimeUnix && formattedDurationFromSeconds(workTimeUnix)}
          >
            <>
              {withAutoBreak && (
                <Text variant="calendar.detailedSchedule.iconBox">
                  <Icon
                    type="coffee"
                    tooltip={
                      <>
                        {t({ id: 'aside_filters.calendar.with_auto_break' })}{' '}
                        {autoBreakDuration && formattedDurationFromSeconds(autoBreakDuration)}
                      </>
                    }
                  />
                </Text>
              )}

              {withOvertime && (
                <Text variant="calendar.detailedSchedule.iconBox">
                  <Icon
                    type="timer"
                    tooltip={
                      <Text>
                        {countOvertimeBeforeWork && (
                          <>
                            - {t({ id: 'schedule.count_overtime_before' })}
                            <br />
                          </>
                        )}
                        {countOvertimeAfterWork && <>- {t({ id: 'schedule.count_overtime_after' })}</>}
                      </Text>
                    }
                  />
                </Text>
              )}
            </>
          </FancyDataBox>
        </Flex>

        {workPosition && (
          <Flex sx={{ flexDirection: 'row' }}>
            <Text sx={{ pl: 1, fontSize: 2, fontWeight: '700' }}>{workPosition}</Text>
          </Flex>
        )}

        {note && (
          <Flex sx={{ flexDirection: 'row' }}>
            <Text sx={{ pl: 1, fontSize: 2 }}>{note}</Text>
          </Flex>
        )}
      </Flex>

      {/* BUTTONS */}
      {!hideButtons && dropdownLinks().length > 0 && (
        <Flex className={isPending ? 'onboarding-schedule-5' : undefined}>
          <ButtonWithDropdown
            dropdownProps={{
              links: dropdownLinks(),
            }}
            size="sm"
            variant="minimal"
            shape="rounded"
            prependWith={<Icon type="more" />}
            onClick={(e) => e.stopPropagation()}
            popperProps={{
              contextMenuId,
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};
