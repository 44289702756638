import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Modal } from 'components/Modal/output/Modal';
import { PATH_REL } from 'constants/routes';
import { languageSelector } from 'state/recoilState';

import { AddCustomRequestModal } from './components/customRequest/AddCustomRequestModal';
import { DeleteCustomRequestModal } from './components/customRequest/DeleteCustomRequestModal';
import { EditCustomRequestModal } from './components/customRequest/EditCustomRequestModal';
import { AddTimeOffTypeModal } from './components/timeOffType/AddTimeOffTypeModal';
import { DeleteTimeOffTypeModal } from './components/timeOffType/DeleteTimeOffTypeModal';
import { EditTimeOffTypeModal } from './components/timeOffType/EditTimeOffTypeModal';

export const RequestModalRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <Routes>
      <Route
        path={PATH_REL.ADD_TIME_OFF_TYPE_MODAL[language]}
        element={
          <Modal size="sm">
            <AddTimeOffTypeModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.EDIT_TIME_OFF_TYPE_MODAL[language]}
        element={
          <Modal size="sm" path={PATH_REL.EDIT_TIME_OFF_TYPE_MODAL[language]}>
            <EditTimeOffTypeModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.DELETE_TIME_OFF_TYPE_MODAL[language]}
        element={
          <Modal size="xs" path={PATH_REL.DELETE_TIME_OFF_TYPE_MODAL[language]}>
            <DeleteTimeOffTypeModal />
          </Modal>
        }
      />

      <Route
        path={PATH_REL.ADD_CUSTOM_REQUEST_TYPE_MODAL[language]}
        element={
          <Modal size="sm">
            <AddCustomRequestModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.EDIT_CUSTOM_REQUEST_TYPE_MODAL[language]}
        element={
          <Modal size="sm" path={PATH_REL.EDIT_CUSTOM_REQUEST_TYPE_MODAL[language]}>
            <EditCustomRequestModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.DELETE_CUSTOM_REQUEST_TYPE_MODAL[language]}
        element={
          <Modal size="xs" path={PATH_REL.DELETE_CUSTOM_REQUEST_TYPE_MODAL[language]}>
            <DeleteCustomRequestModal />
          </Modal>
        }
      />
    </Routes>
  );
};
