import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { addHolidayAction } from 'api/actions/holidays/holidaysActions';
import { AddHolidayActionProps } from 'api/actions/holidays/holidaysActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { createEvent } from 'utils/createEvent';
import { useHolidays } from '../../../../hooks/useHolidays';
import { AddEditHolidayForm } from '../forms/AddEditHolidayForm';

export const AddHolidayModal = (): React.ReactElement => {
  useLingui();
  const { handleClose } = useModal();
  const { mutate } = useMutation(addHolidayAction);
  const { fetchHolidays } = useHolidays();

  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: AddHolidayActionProps): Promise<void> => {
      const { error: submitError } = await mutate(data);

      if (!submitError) {
        if (handleClose) {
          handleClose();
          await fetchHolidays();
        }
        addSnackbar({
          message: t({
            id: 'settings.holiday.add_success',
            message: 'Added holiday',
          }),
          variant: 'success',
        });
      }

      setLoading(false);
    },
    [fetchHolidays, handleClose, mutate],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.holidays.add_holiday">Add Holiday</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddEditHolidayForm ref={formRef} onSubmit={onSubmit} setLoading={setLoading} />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
