import { useCallback } from 'react';
import { useQuery } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { fetchTimeTrackingSettingsAction } from 'api/actions/settings/settingsActions';
import { FetchTimeTrackingSettingsResponse } from 'api/actions/settings/settingsActions.types';
import { timeTrackingSettingsAtom } from 'state/settings';
import { VALIDATION_RULES } from 'constants/validationRules';

export type ParsedTimeTrackingSettings = Omit<
  FetchTimeTrackingSettingsResponse,
  | 'limitPaidBreak'
  | 'paidBreakLimitSeconds'
  | 'countNightHours'
  | 'nightHoursStartSeconds'
  | 'nightHoursDurationSeconds'
> & {
  isLimitPaidBreak: Pick<FetchTimeTrackingSettingsResponse, 'limitPaidBreak' | 'paidBreakLimitSeconds'>;
  nightHours: Pick<
    FetchTimeTrackingSettingsResponse,
    'countNightHours' | 'nightHoursStartSeconds' | 'nightHoursDurationSeconds'
  >;
};

export const useTimeTrackingSettings = () => {
  const { query, abort } = useQuery(fetchTimeTrackingSettingsAction(), false);
  const [timeTrackingSettings, setTimeTrackingSettings] = useRecoilState(timeTrackingSettingsAtom);

  const fetchTimeTrackingSettings = useCallback(async () => {
    const response = await query();
    const { error, payload } = response;

    if (!error && payload) {
      const { limitPaidBreak, paidBreakLimitSeconds, ...rest } = payload;

      setTimeTrackingSettings({
        limitPaidBreak,
        paidBreakLimitSeconds: limitPaidBreak
          ? paidBreakLimitSeconds
          : VALIDATION_RULES.PAID_BREAK_DURATION_SECONDS.min,
        ...rest,
      });
    }

    return response;
  }, [query, setTimeTrackingSettings]);

  const getParsedTimeTrackingSettings = useCallback(
    (data: FetchTimeTrackingSettingsResponse | null): ParsedTimeTrackingSettings | null => {
      if (!data) return null;

      const {
        countNightHours,
        nightHoursStartSeconds,
        nightHoursDurationSeconds,
        limitPaidBreak,
        paidBreakLimitSeconds,
        ...rest
      } = data;

      return {
        ...rest,
        isLimitPaidBreak: {
          limitPaidBreak,
          ...(limitPaidBreak ? { paidBreakLimitSeconds } : {}),
        },
        nightHours: {
          countNightHours,
          ...(countNightHours ? { nightHoursStartSeconds, nightHoursDurationSeconds } : {}),
        },
      };
    },
    [],
  );

  const getFlattenedTimeTrackingSettings = useCallback(
    ({
      isLimitPaidBreak,
      nightHours,
      ...rest
    }: Partial<ParsedTimeTrackingSettings>): Partial<FetchTimeTrackingSettingsResponse> => ({
      ...rest,
      ...isLimitPaidBreak,
      ...nightHours,
    }),
    [],
  );
  // const getFlattenedTimeTrackingSettings = useCallback(
  //   (data: Partial<ParsedTimeTrackingSettings>): Partial<FetchTimeTrackingSettingsResponse> =>
  //     _.transform(
  //       data,
  //       (result, value, key) => {
  //         if (_.isObject(value)) {
  //           _.mapKeys(value, (nestedValue, nestedKey) => {
  //             result[nestedKey as keyof FetchTimeTrackingSettingsResponse] = nestedValue;
  //           });
  //         } else {
  //           result[key as keyof FetchTimeTrackingSettingsResponse] = value;
  //         }
  //       },
  //       {} as Partial<FetchTimeTrackingSettingsResponse>,
  //     ),
  //   [],
  // );

  return {
    timeTrackingSettings,
    setTimeTrackingSettings,
    fetchTimeTrackingSettings,
    getParsedTimeTrackingSettings,
    getFlattenedTimeTrackingSettings,
    fetchAbort: abort,
  };
};
