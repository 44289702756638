import { ThemeUIStyleObject, Flex, BoxProps } from 'theme-ui';
import React from 'react';

import { Icon } from 'components/Icon/Icon';
import { Button, ButtonProps } from 'components/ui/Buttons';

type Props = {
  leftButtonProps?: ButtonProps;
  rightButtonProps?: ButtonProps;
} & BoxProps;

const buttonSx: ThemeUIStyleObject = {
  p: '.34rem',
  fontSize: 1,
};

const defaultProps: Partial<Props> = {
  leftButtonProps: undefined,
};

export const ButtonPair = ({ leftButtonProps, rightButtonProps, sx }: Props): React.ReactElement => (
  <Flex sx={sx}>
    {leftButtonProps && (
      <Button
        sx={{
          ...buttonSx,
        }}
        size="sm"
        shape="rounded"
        variant="grey"
        {...leftButtonProps}
      >
        <Icon type="plus" />
      </Button>
    )}
    {rightButtonProps && (
      <Button
        sx={{
          ...(leftButtonProps && { ml: '2px' }),
          ...buttonSx,
        }}
        size="sm"
        shape="rounded"
        variant="grey"
        {...rightButtonProps}
      >
        <Icon type="delete" />
      </Button>
    )}
  </Flex>
);

ButtonPair.defaultProps = defaultProps;
