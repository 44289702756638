import React from 'react';
import { useTimer } from 'use-timer';
import { Plural, Trans } from '@lingui/macro';
import { useRecoilState } from 'recoil';
import { Text } from 'theme-ui';

import { Footer, Header, LeadText, OverlayContainer, ProgressBar } from 'Kiosk/Layout';
import { Button } from 'components/ui/Buttons';
import { Container } from 'components/ui/Container';
import { KioskOverlay, overlayStateAtom } from 'Kiosk/state/overlayState';
import { initialFaceScanStepAtom, InitialFaceScanSteps } from 'Kiosk/state/initialFaceScanState';
import { ElementGroup } from 'components/ui/ElementGroup';

export const StepSummary = (): React.ReactElement => {
  const DEFAULT_DURATION = 10;

  const [, setStep] = useRecoilState(initialFaceScanStepAtom);
  const [, setOverlay] = useRecoilState(overlayStateAtom);

  const onFinish = () => {
    setOverlay({ type: KioskOverlay.start });
    setStep(InitialFaceScanSteps.START);
  };

  const { time } = useTimer({
    initialTime: DEFAULT_DURATION,
    timerType: 'DECREMENTAL',
    endTime: 0,
    autostart: true,
    interval: 1000,
    onTimeOver: () => {
      onFinish();
    },
  });

  return (
    <OverlayContainer blurBackground>
      <Container
        size="sm"
        sx={{
          color: 'kiosk.faceScan.text',
          textAlign: 'center',
          flex: '1 0',
          justifyContent: 'center',
        }}
      >
        <Header>
          <Text as="p" sx={{ fontSize: '6rem', mb: 5 }}>
            👍
          </Text>
          <Header.Title>
            <Trans id="kiosk.after_initial_scan.header.title1">That's all!</Trans>
          </Header.Title>
          <Header.Title>
            <Trans id="kiosk.after_initial_scan.header.title2">
              Now you can track your time with no further hustle.
            </Trans>
          </Header.Title>
        </Header>

        <Footer sx={{ mt: 3 }}>
          <LeadText>
            <Trans id="kiosk.after_initial_scan.progress_header">
              The screen will disappear in <strong>{Math.ceil(time)}</strong>
            </Trans>{' '}
            <Plural
              id="global.plurals.seconds"
              value={Math.ceil(time)}
              one="second"
              two="seconds"
              few="seconds"
              other="seconds"
            />
            .
          </LeadText>

          <ProgressBar duration={DEFAULT_DURATION} durationProgress={time} wrapperSx={{ mb: 4 }} />

          <ElementGroup marginAt="end" direction="column">
            <Button onClick={onFinish} variant="primary" size="lg" type="button">
              <Trans id="global.forms.buttons.end">Finish</Trans>
            </Button>
          </ElementGroup>
        </Footer>
      </Container>
    </OverlayContainer>
  );
};
