import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import React, { useEffect, useMemo, useState } from 'react';
import Geocode from 'react-geocode';
import { useRecoilValue } from 'recoil';
import { Flex, Spinner, Text } from 'theme-ui';

import { useGeoLocation } from 'Kiosk/hooks/useGeoLocation';
import { usePermissions } from 'Kiosk/hooks/usePermissions';
import { PermissionState } from 'Kiosk/state/permissionState';
import { Map } from 'components/Map/Map';
import { Modal } from 'components/Modal/output/Modal';
import { languageSelector } from 'state/recoilState';

export const LocationModal = (): React.ReactElement => {
  const [address, setAddress] = useState<string | null>(null);
  const language = useRecoilValue(languageSelector);

  const { location } = useGeoLocation();
  const permissions = usePermissions();

  const modalTitle = useMemo(() => {
    if (permissions.location === PermissionState.deny) {
      return i18n._(
        t({
          id: 'kiosk.home.location_modal.header.no_permission',
          message: 'Location services are turned off',
        }),
      );
    }
    if (!location) {
      return i18n._(
        t({
          id: 'kiosk.home.location_modal.header.checking',
          message: 'Searching for your current position',
        }),
      );
    }

    return i18n._(
      t({
        id: 'kiosk.home.location_modal.header.default',
        message: 'Your location',
      }),
    );
  }, [location, permissions.location]);

  useEffect(() => {
    const getAddress = async () => {
      if (!location) {
        return;
      }

      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY || '');
      Geocode.setLanguage(language);

      const geocodeResponse = await Geocode.fromLatLng(`${location.coords.latitude}`, `${location.coords.longitude}`);

      if (geocodeResponse) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        setAddress(geocodeResponse.results[0].formatted_address);
      }
    };
    if (location) {
      void getAddress();
    }
  }, [location, language]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {permissions.location === PermissionState.allow ? (
          <>
            <Text variant="pLead" as="p">
              {address || <Trans id="kiosk.location_modal.searching">Searching...</Trans>}
            </Text>
            <Flex
              sx={{
                bg: 'kiosk.location.bg',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                mt: 5,
                width: '100%',
                height: '400px',
                maxHeight: '90%',
                borderRadius: 'lg',
              }}
            >
              {address && location ? (
                <Map center={{ lat: location.coords.latitude, lng: location.coords.longitude }} defaultZoom={16}>
                  <Map.Marker position={{ lat: location.coords.latitude, lng: location.coords.longitude }} />
                </Map>
              ) : (
                <Spinner />
              )}
            </Flex>
          </>
        ) : (
          <Text sx={{ fontSize: 5 }}>
            <Trans id="kiosk.home.location_modal.text.no_permission">
              You have turned off all location services on your device.
            </Trans>
          </Text>
        )}
      </Modal.Body>
    </>
  );
};
