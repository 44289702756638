/** @jsxImportSource theme-ui */
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { Modal } from 'components/Modal/output/Modal';
import { PATH_REL, TO_REL } from 'constants/routes';
import { DELETE_AI_FACES_FORM_ID, DELETE_PHOTOS_FORM_ID } from 'constants/settings';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { AdvancedDeleteFaceModels } from 'pages/Settings/output/AdvancedDeleteFaceModels';
import { AdvancedDeletePhotosForm } from 'pages/Settings/output/AdvancedDeletePhotosForm';
import { ConfirmDeleteAdvancedModal } from 'pages/Settings/output/ConfirmDeleteAdvancedModal';
import { languageSelector } from 'state/recoilState';

export const ModalRoutes: FC = () => {
  useLingui();
  const language = useRecoilValue(languageSelector);

  return (
    <Routes>
      <Route
        path={PATH_REL.DELETE_AI_FACE_MODAL[language]}
        element={
          <Modal size="xs">
            <ConfirmDeleteAdvancedModal
              formId={DELETE_AI_FACES_FORM_ID}
              message={t({
                id: 'team.edit_teammate.delete_ai_face',
                message: "You're about to delete ai face recognition model.",
              })}
            />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.DELETE_PHOTOS_MODAL[language]}
        element={
          <Modal size="xs">
            <ConfirmDeleteAdvancedModal
              formId={DELETE_PHOTOS_FORM_ID}
              message={t({ id: 'settings.delete_photos', message: "You're about to delete all photos." })}
            />
          </Modal>
        }
      />
    </Routes>
  );
};

type Props = {
  employeeId: string;
};

export const UserOther: FC<Props> = ({ employeeId }) => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const { betaAccessOptIn } = useAppPermissions();

  return (
    <Flex sx={{ gap: 4, flexDirection: 'column' }}>
      <AdvancedDeletePhotosForm
        employeesIds={[employeeId]}
        linkButtonWithLoadingProps={{
          to: TO_REL.DELETE_PHOTOS_MODAL[language],
          state: { preventClose: true },
        }}
      />
      {betaAccessOptIn.faceRecognition && (
        <AdvancedDeleteFaceModels
          employeesIds={[employeeId]}
          linkButtonWithLoadingProps={{
            to: TO_REL.DELETE_AI_FACE_MODAL[language],
            state: { preventClose: true },
          }}
        />
      )}
      <ModalRoutes />
    </Flex>
  );
};
