import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { useCallback, useMemo } from 'react';
import { Text } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { ListNames } from 'components/StickyList/types';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { usePickTeammates } from 'hooks/usePickTeammates/usePickTeammates';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { ScheduleWizardStep } from 'state/calendar';
import { getEnumValues } from 'utils/getEnumValues';
import { useScheduleWizard } from '../hooks/useScheduleWizard';

export const AddScheduleWizardFooter = () => {
  const { step, setStep, onClickCallback, disabledButton, loading } = useScheduleWizard();
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const [selectedTeammatesIds] = usePickTeammates(ListNames.REQUESTS_PICK_TEAMMATES, true);

  const backButtonAction = useCallback(() => {
    if (step === ScheduleWizardStep.SetSchedule) setStep(ScheduleWizardStep.SelectUsers);
    if (step === ScheduleWizardStep.SetDates) setStep(ScheduleWizardStep.SetSchedule);

    return undefined;
  }, [setStep, step]);

  const buttonRenderer = useMemo(() => {
    if (step !== ScheduleWizardStep.SetDates) {
      return (
        <>
          {t({ id: 'global.forms.buttons.next' })}
          <Icon wrapperSx={{ ml: 3 }} type="arrowRight" />
        </>
      );
    }

    return <Trans id="save">Save</Trans>;
  }, [step]);

  const buttonOnClick = useCallback(() => {
    if (step === ScheduleWizardStep.SelectUsers) setStep(ScheduleWizardStep.SetSchedule);
    if (step === ScheduleWizardStep.SetSchedule && onClickCallback) {
      onClickCallback();
    }
    if (step === ScheduleWizardStep.SetDates && onClickCallback) onClickCallback();
  }, [setStep, step, onClickCallback]);

  const isButtonDisabled = useMemo(
    () =>
      (step === ScheduleWizardStep.SelectUsers && !selectedTeammatesIds[0]) ||
      (step === ScheduleWizardStep.SetDates && disabledButton),
    [disabledButton, selectedTeammatesIds, step],
  );

  return (
    <BasicModalFooter
      {...(!isSmartphoneBreakpoint && {
        prependWith: (
          <Text sx={{ fontSize: 2 }}>
            <Trans id="wizard.step_tracker" values={{ step, steps: getEnumValues(ScheduleWizardStep).length }}>
              Step {step} of {getEnumValues(ScheduleWizardStep).length}
            </Trans>
          </Text>
        ),
      })}
      backButtonOnClick={step === ScheduleWizardStep.SelectUsers ? undefined : backButtonAction}
      buttons={[
        {
          isLoading: loading,
          disabled: isButtonDisabled,
          variant: 'primary',
          onClick: () => buttonOnClick(),
          children: buttonRenderer,
        },
      ]}
    />
  );
};
