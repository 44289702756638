/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form';

type ReturnValue = [() => void, () => void];

/**
 * Creates two callbacks that will be passed to a custom field and called on its internal error changes.
 */
export const useErrorHandlersFactory = (
  setError: UseFormSetError<any>,
  clearErrors: UseFormClearErrors<any>,
  errorName: string,
): ReturnValue => {
  const errorSetter = useCallback(() => {
    setError(errorName, {
      message: 'only visible in code',
    });
  }, [setError, errorName]);

  const errorClearer = useCallback(() => {
    clearErrors(errorName);
  }, [clearErrors, errorName]);

  return [errorSetter, errorClearer];
};
