import React from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { PieChartSvg, PieChartSvgProps } from './PieChartSvg';

type Props = PieChartSvgProps & {
  size?: number | string | (number | string | null)[];
  borderWidth?: number;
  sx?: ThemeUIStyleObject;
  children?: React.ReactNode;
};

const defaultProps: Partial<Props> = {
  size: '100%',
  children: undefined,
  sx: undefined,
  borderWidth: 0,
};

export const PieProgressBar = ({
  children,
  size = defaultProps.size,
  borderWidth = 0,
  sx,
  ...props
}: Props): React.ReactElement => (
  <Flex
    sx={{
      width: size,
      height: size,
      position: 'relative',
      borderRadius: 'circle',
      borderWidth: `${borderWidth}px`,
      borderStyle: 'solid',
      borderColor: props.background,
      ...(sx && sx),
    }}
  >
    <PieChartSvg {...props} />
    {children && (
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        {children}
      </Flex>
    )}
  </Flex>
);

PieProgressBar.defaultProps = defaultProps;
