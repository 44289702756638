import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { deleteScheduleAction } from 'api/actions/calendar/calendarActions';
import { useModal } from 'components/Modal/output/useModal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'components/recipes/ConfirmModal/types';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { calendarSchedulesSelector } from 'state/calendar';
import { reportSchedulesSelector } from 'state/reports';
import { useRefreshCalendar } from '../../hooks/useRefreshCalendar';

export const DeleteScheduleModal = (): React.ReactElement => {
  useLingui();
  const schedules = useRecoilValue(calendarSchedulesSelector);
  const reportSchedules = useRecoilValue(reportSchedulesSelector);

  const { state } = useLocation();
  const { ids } = state || {};
  const { baseRoute } = useModal();

  const getEmployeeIds = useCallback(() => {
    if ((schedules || reportSchedules) && ids) {
      if (schedules) {
        const employeeIds = _.map(ids, (id) => schedules.get(id)?.employeeId).filter(
          (id) => id !== undefined,
        ) as string[];
        if (employeeIds.length > 0) return employeeIds;
      }

      if (reportSchedules) {
        const employeeIds = _.map(ids, (id) => reportSchedules.get(id)?.employeeId).filter(
          (id) => id !== undefined,
        ) as string[];
        if (employeeIds.length > 0) return employeeIds;
      }
    }

    return undefined;
  }, [ids, reportSchedules, schedules]);

  const isAi = useMemo(() => {
    if (!schedules) return false;

    const id = ids?.length ? ids[0] : null;
    if (!id) return false;

    return _.isNumber(schedules.get(id)?.aiVariant);
  }, [schedules, ids]);

  const employeeIds = useMemo(() => getEmployeeIds(), [getEmployeeIds]);

  const { updateCalendarForIds, calendarInitialized, updateCalendar } = useRefreshCalendar(employeeIds);
  const { reportInitialized, updateReportForIds } = useRefreshReport(employeeIds);

  const updateData = useCallback(async () => {
    if (calendarInitialized) updateCalendar();
    if (reportInitialized) await updateReportForIds();
  }, [calendarInitialized, reportInitialized, updateCalendar, updateReportForIds]);

  useEffect(() => {
    if (!schedules && !reportSchedules) {
      void updateData();
    }
  }, [reportSchedules, schedules, updateData]);

  const titleRenderer = useCallback(() => t({ id: 'schedule.delete_title', message: 'Delete schedule?' }), []);

  const contentRenderer = useCallback(
    () => (
      <Trans id="schedule.delete_content">
        <Text>You're about to delete this schedule.</Text>
        <Text>
          <strong>This action can be reversed only before publishing.</strong>
        </Text>
      </Trans>
    ),
    [],
  );

  const onResponseCallback: NonNullable<ConfirmModalProps['onResponseCallback']> = useCallback(
    async (error) => {
      if (!error) {
        if (calendarInitialized) await updateCalendarForIds();
        if (reportInitialized) await updateReportForIds();
      }
    },
    [calendarInitialized, reportInitialized, updateCalendarForIds, updateReportForIds],
  );

  if (!ids?.length) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <ConfirmModal
      list={schedules}
      action={(selectedIds) => deleteScheduleAction(selectedIds, isAi)}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      variant="DELETE"
      onResponseCallback={onResponseCallback}
    />
  );
};
