export const ADD_SCHEDULE_WIZARD_MODAL_WIDTH = '890px';

export enum ScheduleType {
  FixedStartTime = 0,
  NotStandardized = 1,
}

export enum DownloadExtensions {
  Pdf = 'pdf',
  Xlsx = 'xlsx',
  Csv = 'csv',
}

export enum CalendarScheduleWarnings {
  // przekroczona liczba godzin na dobę (8h)
  DayLimitExceeded = 0,

  // brak 11h przerwy pomiędzy dobami pracowniczymi
  DayBreakAbused = 1,

  // przekroczona liczba godzin na tydzień (40h)
  WeekLimitExceeded = 2,

  // brak 35h odpoczynku tygodniowego
  WeekBreakAbused = 3,

  // zmiana czasu na zimowy / letni
  DstChange = 4,

  // przekroczenie dostępności przez grafik
  ScheduleExceedsWorkdayAvailability = 5,
}
