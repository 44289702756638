import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Navigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { employeesImportAction, employeesImportPreviewAction } from 'api/actions/employees/employeesActions';
import { EmployeeImportType } from 'api/actions/employees/employeesActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { ListNames } from 'components/StickyList/types';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { REFRESH_GOOGLE_INTEGRATIONS_MESSAGE } from 'constants/settings';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { importedEmployeesInfoAtom, importedEmployeesMapSelector } from 'state/employees';
import { selectAllAtomFamily, selectedRowsAtomFamily, selectedRowsIdsSelectorFamily } from 'state/list';
import { useTheme } from 'styles/useTheme';
import { createEvent } from 'utils/createEvent';

import { ImportStep1Modal, SubmitProps } from './components/ImportStep1';
import { ImportStep2Modal } from './components/ImportStep2';

type redirectUriType = string | URL | undefined;

const POPUP_FEATURES = 'width=800,height=800';

enum ImportModalStep {
  ImportEmployees = 1,
  SelectEmployees = 2,
}

const INTEGRATION_TYPES_WITH_PREVIEW = [
  EmployeeImportType.Quickbooks,
  EmployeeImportType.GSuite,
  EmployeeImportType.Xero,
  EmployeeImportType.Square,
];

export const ImportModal: FC = () => {
  useLingui();
  const [step, setStep] = useState<ImportModalStep>(ImportModalStep.ImportEmployees);

  const [importedEmployeesInfo, setImportedEmployeesInfo] = useRecoilState(importedEmployeesInfoAtom);
  const employeesMap = useRecoilValue(importedEmployeesMapSelector);
  const selectedEmployeesIds = useRecoilValue(selectedRowsIdsSelectorFamily(ListNames.TEAM_IMPORT_EMPLOYEES));
  const resetSelectAll = useResetRecoilState(selectAllAtomFamily(ListNames.TEAM_IMPORT_EMPLOYEES));
  const resetSelectedIds = useResetRecoilState(selectedRowsAtomFamily(ListNames.TEAM_IMPORT_EMPLOYEES));
  const resetImportedEmployees = useResetRecoilState(importedEmployeesInfoAtom);
  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar();

  const formRef = useRef<HTMLFormElement | null>(null);

  const { type } = useParams() as { type: string };
  const importType = Number(type) as EmployeeImportType;
  const importTypeRef = useRef(importType);

  const { theme } = useTheme();

  const { handleClose, baseRoute } = useModal({
    ...{ wrapperSx: { ...theme.modal.default, height: '600px' } },
    ...(step === ImportModalStep.SelectEmployees && { wrapperSx: { ...theme.modal.xl, height: '650px' } }),
  });

  const { mutate: mutateEmployeeImportPreview, loading: importPreviewLoading } =
    useMutation(employeesImportPreviewAction);
  const { mutate: mutateEmployeeImport, loading: importLoading } = useMutation(employeesImportAction);

  const nextStep = useCallback(() => setStep((prev) => prev + 1), []);
  const prevStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => setStep((prev) => prev - 1), []);

  const filteredSelectedEmployees = filter(importedEmployeesInfo?.importedEmployees, (employee) =>
    selectedEmployeesIds.includes(employee.id),
  );

  useUnmount(() => {
    resetSelectAll();
    resetSelectedIds();
    resetImportedEmployees();
  });

  const fetchIntegrationImport = useCallback(async () => {
    setStep(ImportModalStep.SelectEmployees);

    const { error, payload: integrationsImportPayload } = await mutateEmployeeImportPreview({
      importType,
    });

    if (!error && integrationsImportPayload) {
      setImportedEmployeesInfo(integrationsImportPayload);
    }

    // Popup should only open for google integration
    if (error && integrationsImportPayload?.data?.redirectUri && importType === EmployeeImportType.GSuite) {
      const redirectUri = integrationsImportPayload?.data.redirectUri as redirectUriType;
      window.open(redirectUri, '', POPUP_FEATURES);
    } else if (error) {
      handleClose();
    }
  }, [handleClose, importType, mutateEmployeeImportPreview, setImportedEmployeesInfo]);

  useEffect(() => {
    if (includes(INTEGRATION_TYPES_WITH_PREVIEW, importType)) {
      void fetchIntegrationImport();
    }
  }, [fetchIntegrationImport, importType]);

  useEffect(() => {
    const handleWindowMessage = (e: MessageEvent) => {
      if (e.data !== REFRESH_GOOGLE_INTEGRATIONS_MESSAGE) return;

      void fetchIntegrationImport();
    };

    const impType = importTypeRef.current;

    if (impType === EmployeeImportType.GSuite) {
      return window.addEventListener('message', handleWindowMessage);
    }

    return () => {
      window.removeEventListener('message', handleWindowMessage);
    };
  }, [fetchIntegrationImport]);

  const submitForm = useCallback(() => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  }, []);

  const handleSubmitEmployees = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const handleSubmitImportPreview = useCallback(
    async ({ uploadId }: SubmitProps): Promise<void> => {
      if (!uploadId) return;

      const { error, payload: importPreviewPayload } = await mutateEmployeeImportPreview({
        importType,
        fileId: uploadId,
      });

      if (!error && importPreviewPayload) {
        setImportedEmployeesInfo(importPreviewPayload);
        nextStep();
      }

      if (error) {
        handleClose();
      }
    },
    [handleClose, importType, mutateEmployeeImportPreview, nextStep, setImportedEmployeesInfo],
  );

  const handleSubmitImport = async () => {
    if (!filteredSelectedEmployees) return;

    const { error, payload: importedUsersIds } = await mutateEmployeeImport({
      importedEmployees: filteredSelectedEmployees,
      importedTags: importedEmployeesInfo?.importedTags || [],
    });

    if (!error) {
      handleClose();
      addSnackbar({
        message: t({ id: 'team.import_modal.imported', message: 'Employees successfully imported' }),
        variant: 'success',
      });
    }
    if (importedUsersIds && calendarInitialized) void updateCalendarForIds(importedUsersIds);
    if (error) {
      handleClose();
    }
  };

  const employeeImportNameMap: Record<string, string> = {
    [EmployeeImportType.Excel]: t({ id: 'team.import_modal.xls', message: '.xls file' }),
    [EmployeeImportType.GSuite]: t({ id: 'team.import_modal.google_workspace', message: 'Google Workspace' }),
    [EmployeeImportType.Quickbooks]: t({ id: 'team.import_modal.quickbooks', message: 'QuickBooks' }),
    [EmployeeImportType.Optima]: t({ id: 'team.import_modal.comarch_optima', message: 'Comarch Optima' }),
    [EmployeeImportType.Symfonia]: t({ id: 'team.import_modal.symfonia', message: 'Symfonia' }),
    [EmployeeImportType.Enova]: t({ id: 'team.import_modal.enova', message: 'Enova365' }),
  };

  if (!includes(EmployeeImportType, importType)) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.import_modal.import_employees">Import employees from</Trans>
          <> {employeeImportNameMap[type]}</>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ overflowY: 'hidden' }}>
        {step === ImportModalStep.ImportEmployees && (
          <ImportStep1Modal ref={formRef} handleSubmitImportPreview={handleSubmitImportPreview} />
        )}

        {step === ImportModalStep.SelectEmployees && employeesMap && (
          <ImportStep2Modal isLoading={importPreviewLoading} />
        )}
      </Modal.Body>

      <BasicModalFooter
        backButtonOnClick={
          importType !== EmployeeImportType.Quickbooks &&
          importType !== EmployeeImportType.GSuite &&
          step === ImportModalStep.SelectEmployees
            ? prevStep
            : undefined
        }
        buttons={[
          {
            isLoading: importLoading || (importPreviewLoading && step === ImportModalStep.ImportEmployees),
            variant: 'primary',
            disabled: !selectedEmployeesIds.length && step === ImportModalStep.SelectEmployees,
            onClick: step === ImportModalStep.ImportEmployees ? handleSubmitEmployees : handleSubmitImport,
            children: (
              <>
                {step === ImportModalStep.SelectEmployees ? (
                  <Trans id="team.import_modal.import">Import</Trans>
                ) : (
                  t({ id: 'global.forms.buttons.next' })
                )}
              </>
            ),
          },
        ]}
      />
    </>
  );
};
