import { atom, selector } from 'recoil';

import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';

type KioskStateProps = {
  nightMode: boolean;
  debugMode: boolean;
};

export const kioskStateAtom = atom<KioskStateProps>({
  key: 'kiosk__stateAtom',
  default: {
    nightMode: false,
    debugMode: false,
  },
});

export const nightModeSelector = selector<boolean>({
  key: 'kiosk__nightMode',
  get: ({ get }) => {
    const { nightMode } = get(kioskStateAtom);

    return nightMode;
  },
  set: ({ get, set }, newState) => {
    const { nightMode } = get(kioskStateAtom);

    if (newState !== nightMode && !isRecoilDefaultValue(newState)) {
      set(kioskStateAtom, (prevState) => ({ ...prevState, nightMode: newState }));
    }
  },
});
