import React, { useCallback, useMemo } from 'react';

import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { IS_TOUCH_DEVICE } from 'constants/common';
import { wish } from 'utils/wish';

export const OPEN_CONTEXT_MENU = 'OPEN_CONTEXT_MENU';
export const POPPER_ACTIVATED = 'POPPER_ACTIVATED';

export const useContextMenu = (contextMenuId?: string) => {
  const { isMobileBreakpoint } = useThemeBreakpoint();
  const isMobileOrTouch = useMemo(() => isMobileBreakpoint || IS_TOUCH_DEVICE, [isMobileBreakpoint]);

  const openContextMenuFactory = useCallback(
    (id: string) => async (e: React.MouseEvent<HTMLElement>) => {
      if (isMobileOrTouch) return;

      e.preventDefault();
      e.stopPropagation();

      const openContextMenuPromise = async () => {
        try {
          return await wish(`${OPEN_CONTEXT_MENU}${id}`, { x: e.clientX, y: e.clientY });
        } catch (err) {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log(err);
          }
          return Promise.resolve(null);
        }
      };

      const response = await openContextMenuPromise();
      if (response !== POPPER_ACTIVATED) return;
      // PopperProvider was not yet render during the last openContextMenuPromise call
      // withPopper fulfilled the last promise and activated PopperProvider, now its rendered so our promise can be fulfilled by it
      void openContextMenuPromise();
    },
    [isMobileOrTouch],
  );

  return {
    openContextMenuFactory,
    openContextMenu: contextMenuId ? openContextMenuFactory(contextMenuId) : undefined,
  };
};
