import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useContext, useEffect } from 'react';
import { ClientContext, useMutation } from 'react-fetching-library';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { signInAction } from 'api/actions/auth/authActions';
import { SignInPayload } from 'api/actions/auth/authActions.types';
import { fetchUserSessionAction } from 'api/actions/userSession/userSessionActions';
import { Languages } from 'api/actions/userSession/userSessionActions.types';
import { signIn } from 'broadcastChannel/channelActionCreators/channelActionCreators';
import { FormCard } from 'components/FormCard/FormCard';
import { IntegrationsAuthenticationFooter } from 'components/IntegrationsAuthenticationFooter/IntegrationsAuthenticationFooter';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { APP_NAME } from 'constants/common';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { TO } from 'constants/routes';
import { SupportedLanguages } from 'constants/supportedLanguages';
import {
  setAuthorized,
  setToken,
  setUnauthorized,
  startAuthorizing,
} from 'context/auth/authActionCreators/authActionCreators';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { Main } from 'layouts/Authentication/output/Main';
import { broadcastChannel } from 'observers/BroadcastChannelObserver';
import { languageSelector } from 'state/recoilState';
import { signUpFormAtom } from 'state/signUp';
import { userSessionAtom } from 'state/userSession';
import { delay } from 'utils/delay';

import { SignInForm } from './components/SignInForm/SignInForm';

export const SignIn = (): React.ReactElement => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const setUserSession = useSetRecoilState(userSessionAtom);
  const resetSignUpForm = useResetRecoilState(signUpFormAtom);

  const { state } = useLocation();

  const fromPath =
    state?.from && (state.from.includes(TO.SIGN_UP[language]) || state.from.includes(TO.PASS_RESET[language]))
      ? TO.START[language]
      : state?.from || TO.START[language];

  const { query } = useContext(ClientContext);

  const { mutate } = useMutation(signInAction);
  const dispatch = useAuthDispatch();

  const { isAuthorized, errorMessage } = useAuthState();

  useEffect(() => {
    resetSignUpForm();
  }, [resetSignUpForm]);

  const onSubmit = useCallback(
    async (body: SignInPayload): Promise<boolean> => {
      dispatch(startAuthorizing());

      // GTM DATA LAYER (LOGIN-SUBMIT / EMAIL)
      TagManager.dataLayer({
        dataLayer: {
          event: 'login-submit',
          method: 'email',
        },
      });

      const { payload, error: submitError } = await mutate(body);

      if (!submitError && payload) {
        localStorage.clear();
        const { accessToken } = payload;

        dispatch(setToken(accessToken));

        const { payload: userSession, error: fetchError } = await query(fetchUserSessionAction(accessToken));

        if (!fetchError && userSession) {
          const { preferredLanguage, personId } = userSession;

          dispatch(setAuthorized());
          if ((Languages[language] as unknown as SupportedLanguages) !== preferredLanguage) {
            // when preferredLanguage from userSession is different from current language,
            // LocalizedRouteGate will unmount and mount SingIn component loosing fromPath
            // this gives time for the component to react to isAuthorized change and redirect to the correct fromPath
            await delay(0);
          }
          setUserSession(userSession);

          // GTM DATA LAYER (LOGIN-SUCCESS / EMAIL)
          TagManager.dataLayer({
            dataLayer: {
              event: 'login-success',
              method: 'email',
              user_id: personId,
            },
          });

          return true;
        }
      }

      dispatch(setUnauthorized());

      // GTM DATA LAYER (LOGIN-FAIL / EMAIL)
      TagManager.dataLayer({
        dataLayer: {
          event: 'login-fail',
          method: 'email',
        },
      });

      return false;
    },
    [dispatch, mutate, query, setUserSession, language],
  );

  if (isAuthorized) {
    void broadcastChannel?.postMessage(signIn());
    return <Navigate to={fromPath} />;
  }

  return (
    <>
      <Helmet>
        <title>{t({ id: 'sign_in.page_title', message: `Sign in - ${APP_NAME}` })}</title>
      </Helmet>

      <Main>
        <Flex
          sx={{
            width: '100%',
            maxWidth: '480px',
            flexGrow: 1,
            gap: 5,
            flexDirection: 'column',
            alignItems: 'stretch',
            alignSelf: 'center',
            textAlign: 'center',
          }}
        >
          <FormCard>
            <FormCard.Header>
              <FormCard.Title>
                <Trans id="sign_in.lead.header">Welcome back! 👋</Trans>
              </FormCard.Title>
              <FormCard.Lead>
                <Trans id="sign_in.lead.text">Type in your e-mail address and password.</Trans>
              </FormCard.Lead>
            </FormCard.Header>

            {errorMessage && (
              <Text sx={{ color: 'signIn.error' }}>
                <Trans id="sign_in.redirect_error_title">Something went wrong 😥</Trans>
                <br />
                {errorMessage}
              </Text>
            )}

            <SignInForm onSubmit={onSubmit} />
            <IntegrationsAuthenticationFooter />
          </FormCard>

          <Text as="p" sx={{ alignSelf: 'center', color: 'signIn.text' }}>
            <Trans id="sign_in.top_right">Don't have an account?</Trans>{' '}
            <LinkAnchor to={TO.SIGN_UP[language]} sx={{ fontWeight: '700' }}>
              <Trans id="sign_in.top_right.button_title">Create a FREE account</Trans>
            </LinkAnchor>
          </Text>

          <Text as="p" sx={{ alignSelf: 'center', color: 'signIn.text' }}>
            <Trans id="sign_in.terms_acceptance">
              Signing in, you agree to the{' '}
              <LinkAnchor sx={{ textDecoration: 'underline' }} href={EXTERNAL_LINKS.TERMS} target="_blank">
                Terms and Conditions
              </LinkAnchor>{' '}
              as they stand.
            </Trans>
          </Text>
        </Flex>
      </Main>
    </>
  );
};
