import React from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';
import _ from 'lodash';

import { Radio, RadioProps } from './Radio';

type Props = Pick<RadioProps, 'value' | 'onChange' | 'size' | 'name'> & {
  fields: RadioProps[];
  sx?: ThemeUIStyleObject;
};

export const RadioGroup = React.forwardRef<HTMLInputElement, Props>(
  ({ onChange, value, fields, size, name, sx, ...props }: Props, ref) => (
    <Flex sx={{ flexDirection: 'column', gap: 2, ...(sx && sx) }} {...props}>
      {_.map(_.uniqBy(fields, 'value'), ({ value: innerValue, label }, index) => {
        const key = _.isArray(innerValue) ? `${label?.toString() || index.toString()}` : `${innerValue}`;

        return (
          <Radio
            name={name}
            key={key}
            onChange={onChange}
            {...(!_.isUndefined(value) && {
              checked: _.isEqual(innerValue, value),
            })}
            value={innerValue}
            ref={ref}
            size={size}
            id={String(innerValue)}
            label={label}
          />
        );
      })}
    </Flex>
  ),
);
