import { useLingui } from '@lingui/react';
import React, { useCallback, useState } from 'react';
import { useClient } from 'react-fetching-library';

import { fetchQuickbooksAction, fetchSquareAction, fetchXeroAction } from 'api/actions/integrations/integrationActions';
import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useIntegrationTileTexts } from '../../../../../hooks/useIntegrationTileTexts';
import { useIntegrations } from '../../../../../hooks/useIntegrations';
import { useIntegrationHelpLinks } from '../../../hooks/useIntegrationHelpLinks';
import { IntegrationTile } from '../../IntegrationTile/IntegrationTile';
import { IntegrationProps } from '../../IntegrationTile/types';

const POPUP_FEATURES = 'width=800,height=800';

enum PopUpIntegrations {
  Quickbooks = ServiceIntegration.Quickbooks,
  Xero = ServiceIntegration.Xero,
  Square = ServiceIntegration.SquarePayroll,
}

const actionMap: { [index in PopUpIntegrations]: typeof fetchQuickbooksAction } = {
  [ServiceIntegration.Quickbooks]: fetchQuickbooksAction,
  [ServiceIntegration.Xero]: fetchXeroAction,
  [ServiceIntegration.SquarePayroll]: fetchSquareAction,
};

type Props = IntegrationProps;

export const QuickbooksXeroTile = ({ integration }: Props): React.ReactElement => {
  useLingui();
  const { type, isActive, isExpired, imageUrl, connectedAccount } = integration;
  const { query } = useClient();
  const { reconnectIntegration, disconnectIntegration } = useIntegrations();
  const integrationTexts = useIntegrationTileTexts(type);
  const [isLoading, setIsLoading] = useState(false);
  const helpLink = useIntegrationHelpLinks(type);

  const handleConnect = useCallback(async () => {
    setIsLoading(true);
    const action = actionMap[type as unknown as PopUpIntegrations];
    const { payload: actionUrl, error: fetchError } = await query(action());
    if (actionUrl && !fetchError) {
      window.open(actionUrl, integrationTexts.heading, POPUP_FEATURES);
    }
    setIsLoading(false);
  }, [integrationTexts.heading, query, type]);

  const handleDisconnect = useCallback(async () => {
    setIsLoading(true);
    await disconnectIntegration(type);
    setIsLoading(false);
  }, [disconnectIntegration, type]);

  const handleReconnect = useCallback(async () => {
    setIsLoading(true);
    await reconnectIntegration(type);
    setIsLoading(false);
  }, [reconnectIntegration, type]);

  return (
    <IntegrationTile isActive={isActive} isExpired={isExpired}>
      <IntegrationTile.Tags isActive={isActive} isExpired={isExpired} />
      <IntegrationTile.Body
        {...integrationTexts}
        image={imageUrl}
        connectedAccount={isActive ? connectedAccount : undefined}
      />
      <IntegrationTile.Buttons>
        <IntegrationTile.Buttons.Connect
          isActive={isActive}
          isExpired={isExpired}
          textVariant={type === ServiceIntegration.Quickbooks ? 'quickbooks' : 'connect'}
          onClick={floatingPromiseReturn(handleConnect)}
          isLoading={isLoading}
        />
        <IntegrationTile.Buttons.Expired
          isExpired={isExpired}
          onClick={floatingPromiseReturn(handleReconnect)}
          isLoading={isLoading}
        />
        <IntegrationTile.Buttons.Disconnect
          isActive={isActive}
          isExpired={isExpired}
          textVariant="disconnect"
          onClick={floatingPromiseReturn(handleDisconnect)}
          isLoading={isLoading}
        />
        <IntegrationTile.Buttons.LearnMore to={helpLink} isQuickbooks={type === ServiceIntegration.Quickbooks} />
      </IntegrationTile.Buttons>
    </IntegrationTile>
  );
};
