import { Global, keyframes } from '@emotion/react';
import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { Flex } from 'theme-ui';

import { Logo } from 'components/Logo/Logo';

import { Footer } from './components/Footer';

type Props = {
  children: ReactElement;
  showFooter?: boolean;
  showLogo?: boolean;
  stretchHeight?: boolean;
};

const defaultProps: Partial<Props> = {
  showFooter: false,
  showLogo: true,
  stretchHeight: true,
};

const positionAnimation = keyframes({
  '0%': {
    backgroundPosition: '25% 50%',
  },
  '100%': {
    backgroundPosition: '75% 50%',
  },
});

const sizeAnimation = keyframes({
  '0%': {
    backgroundSize: '180% 180%',
  },
  '100%': {
    backgroundSize: '120% 180%',
  },
});

export const AuthenticationLayout = ({ children, showFooter, showLogo, stretchHeight }: Props): React.ReactElement => (
  <>
    <Global
      styles={(theme) => ({
        'html, body': {
          backgroundAttachment: 'fixed',
          background: 'white',
          '@media screen and (min-width: 768px)': {
            background: `linear-gradient(300deg, ${theme.colors.grayBlues[4]}, ${theme.colors.whites[4]}, ${theme.colors.whites[1]}, ${theme.colors.whites[4]}, ${theme.colors.grayBlues[4]})`,
            animation: `${positionAnimation} 18s ease-in-out infinite alternate, ${sizeAnimation} 9s ease infinite alternate`,
          },
        },
      })}
    />
    <Flex
      sx={{
        minHeight: '100%',
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      {showLogo && (
        <Flex sx={{ width: '100% ', pt: [4, null, '3.25rem'], pb: [null, null, 3], justifyContent: 'center' }}>
          <Logo
            sx={{
              height: 48,
              width: 'auto',
            }}
          />
        </Flex>
      )}

      <Flex sx={{ flexDirection: 'row', justifyContent: 'center', ...(stretchHeight && { flexGrow: 1 }) }}>
        {children}
        <Outlet />
      </Flex>

      {showFooter && <Footer />}
    </Flex>
  </>
);

AuthenticationLayout.defaultProps = defaultProps;
