import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex } from 'theme-ui';

import { AddRequestActionProps, RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Textarea } from 'components/ui/Textarea';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { useAddRequest } from '../../../../../hooks/useAddRequest';
import { labelProps } from '../../../commons';

export const Reason = (): React.ReactElement | null => {
  useLingui();
  const {
    addRequestState: { requestType },
  } = useAddRequest();

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<AddRequestActionProps>();

  const actionTypeWatch = watch('actionType');

  if (actionTypeWatch !== RequestActionType.Remove && requestType !== RequestFormType.Schedule) return null;

  return (
    <Flex>
      <Textarea
        id="note"
        {...register('note', validationFactory({ ...VALIDATION_RULES.ADD_REQUEST_NOTE }))}
        errorMessage={errors.note?.message}
        error={!!errors.note}
        placeholder={t({
          id: 'add_request.reason.placeholder',
          message: 'A reason why are you making this request',
        })}
        label={t({
          id: 'add_request.reason.label',
          message: 'Reason (optional)',
        })}
        variant="rounded"
        size="sm"
        inputProps={{ sx: { height: '80px' } }}
        labelProps={labelProps}
      />
    </Flex>
  );
};
