import { dateTime } from 'utils/dateTime';

export const getEventFormattedDateTime = (timeUtc: number | undefined, multipleYearsInRangeFilter?: boolean | null) => {
  const year = dateTime(timeUtc).format('YYYY');
  const eventDate = multipleYearsInRangeFilter
    ? dateTime(timeUtc).format('ll')
    : dateTime(timeUtc).format('ll').split(` ${year}`)[0].split(',')[0];
  const eventTime = dateTime(timeUtc).format('LT');

  return { eventDate, eventTime };
};
