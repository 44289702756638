import { ThemeUICSSObject } from 'theme-ui';

import { IS_IOS } from 'constants/common';
import { makeTheme } from '../makeTheme';

import { THEME_NAVBAR_HEIGHT } from './base';
import { COLOR_ALPHAS } from './colors';

const NAVBAR_HEIGHT = parseInt(THEME_NAVBAR_HEIGHT, 10);

const workTimerContainerSx: ThemeUICSSObject = {
  width: '120px',
  height: '40px',
  borderRadius: 'pill',
  p: '0.125rem',
  pr: 2,
  flexShrink: 0,
  gap: 1,
  alignItems: 'center',
  bg: 'workTimer.background.container.default',
  userSelect: 'none',
  position: 'relative',
  transition: 'all .2s ease-in-out',
};

const workTimerContentSx: ThemeUICSSObject = {
  alignItems: 'flex-start',
  flexDirection: 'column',
  justifyContent: 'center',
  color: 'workTimer.text.default',
};

const notificationContainerSx: ThemeUICSSObject = {
  p: 3,
  maxWidth: '100%',
  width: '320px',
  minWidth: '100%',
  borderRadius: 'default',
  transition: 'background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  color: 'notification.text.default',
};

const alertSx: ThemeUICSSObject = {
  flexDirection: ['column', null, null, 'row'],
  alignItems: 'center',
  borderRadius: 'default',
  fontWeight: 'bold',
  px: 4,
  py: '0.75rem',
  border: '1px solid',
  borderColor: 'alerts.border',
  gap: 2,
};

const badgeBaseSx: ThemeUICSSObject = {
  alignItems: 'center',
  gap: '0.125rem',
  borderRadius: 'pill',
  color: 'badge.text.default',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  lineHeight: 1,
  px: 1,
  py: '0.125rem',
  '> [data-text]': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    pt: '0.0625rem',
  },
};

const badge = {
  size: {
    sm: {
      fontSize: 0,
    },
    default: {
      fontSize: 1,
    },
    lg: {
      px: 2,
      py: 1,
      fontSize: 2,
    },
  },
  default: {
    ...badgeBaseSx,
    bg: 'badge.default',
  },
  lighter: {
    ...badgeBaseSx,
    bg: 'badge.lighter',
    color: 'badge.text.lighter',
    borderRadius: 'sm',
  },
  info: {
    ...badgeBaseSx,
    bg: 'badge.info',
  },
  success: {
    ...badgeBaseSx,
    bg: 'badge.success',
  },
  danger: {
    ...badgeBaseSx,
    bg: 'badge.bg.danger',
  },
  warning: {
    ...badgeBaseSx,
    bg: 'badge.warning',
  },
  pending: {
    ...badgeBaseSx,
    bg: 'badge.pending',
  },
};

type WorkStatusType = 'task' | 'custom' | 'break' | 'clockIn' | 'clockOut' | 'late';
const sharedWorkStatuses: WorkStatusType[] = ['custom', 'break', 'task'];
const clockStatuses: WorkStatusType[] = ['clockIn', 'clockOut'];
const generateWorkStatusShared = (type: WorkStatusType, start: boolean = false, active: boolean = false) => ({
  alignItems: 'center',
  borderRadius: 'xs',
  border: '1px solid',
  fontWeight: 'bold',
  lineHeight: '21px',
  maxWidth: '100%',
  fontSize: 2,
  '.icon__path--sign': {
    color: 'white',
  },
  ...(start || type === 'clockIn'
    ? {
        '.icon__path--circle': {
          color: 'workStatus.icon.circle.start',
        },
      }
    : {
        '.icon__path--circle': {
          color: 'workStatus.icon.circle.end',
        },
      }),
  ...(type === 'late' && {
    '.icon__path--circle': {
      fill: 'workStatus.icon.circle.late',
    },
  }),
  bg: active ? COLOR_ALPHAS.lighter4 : `workStatus.background.${type}`,
  color: `workStatus.text.${type}`,
  borderColor: active ? 'white' : `workStatus.border.${type}`,
});

const workStatus = {
  size: {
    xs: {
      fontSize: 0,
      lineHeight: '15px',
      fontWeight: 'bold',
      margin: '0 2px',
    },
    attendance: {
      fontSize: 1,
      lineHeight: '18px',
      fontWeight: 'bold',
      margin: '0 3px',
    },
    sm: {
      fontSize: 2,
      lineHeight: '21px',
      fontWeight: 'bold',
      margin: '0 3px',
    },
    default: {
      fontSize: 2,
      lineHeight: '21px',
      fontWeight: 'bold',
      margin: '0 4px',
    },
  },
  late: {
    default: generateWorkStatusShared('late'),
  },
  ...clockStatuses.reduce(
    (acc, type) => ({
      ...acc,
      [type]: {
        default: generateWorkStatusShared(type),
        active: generateWorkStatusShared(type, false, true),
      },
    }),
    {},
  ),
  ...sharedWorkStatuses.reduce(
    (acc, type) => ({
      ...acc,
      [type]: {
        start: {
          default: generateWorkStatusShared(type, true),
          active: generateWorkStatusShared(type, true, true),
        },
        end: {
          default: generateWorkStatusShared(type),
          active: generateWorkStatusShared(type, false, true),
        },
      },
    }),
    {},
  ),
};

const tagBaseSx: ThemeUICSSObject = {
  alignItems: 'center',
  color: 'texts.lighter',
  fontSize: 1,
  lineHeight: '1.125rem',
  px: '3px',
  bg: 'whites0',
  borderColor: 'whites4',
  borderRadius: 'xs',
  borderStyle: 'solid',
  borderWidth: '0.0625rem',
  gap: '2px',
  maxWidth: 'fit-content',
  '> [data-text]': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
  },
};

const tag = {
  default: {
    ...tagBaseSx,
  },
  outline: {
    ...tagBaseSx,
  },
  informational: {
    ...tagBaseSx,
  },
  solid: {
    ...tagBaseSx,
  },
};

export const cardSx: ThemeUICSSObject = {
  mt: 6,
  mb: 6,
  width: '480px',
  bg: 'white',
  boxShadow: 'cards.quickbooksDisconnect',
  borderRadius: 'default',
  flexDirection: 'column',
  gap: 3,
  pt: 6,
  pb: '30px',
  px: '40px',
  textAlign: 'center',
  alignItems: 'center',
};

const quickbooksDisconnect = {
  container: cardSx,
};

export const customComponents = makeTheme({
  badge,
  tag,
  workStatus,
  notifications: {
    container: {
      notificationHub: {
        ...notificationContainerSx,
        backdropFilter: 'blur(8px)',
        bg: 'notification.background.hub',
        boxShadow: 'notification.hub',
        '&:hover, &:focus': {
          bg: 'notification.background.hover',
          boxShadow: 'notification.hubHover',
        },
      },
      notificationCenter: {
        ...notificationContainerSx,
        bg: 'notification.background.center',
        width: '100%',
        '&:hover, &:focus': {
          bg: 'notification.background.centerHover',
          boxShadow: 'notification.center',
        },
      },
    },
    title: {
      fontSize: 2,
      color: 'notification.text.default',
      lineHeight: 'notification.title',
    },
    date: {
      minWidth: 'auto',
      fontSize: 0,
      pl: 2,
      textAlign: 'right',
      lineHeight: 'notification.date',
      whiteSpace: 'nowrap',
      color: 'notification.text.date',
    },
    message: {
      color: 'notification.text.default',
      fontSize: 2,
      lineHeight: 'notification.message',
    },
  },
  snackbars: {
    default: {
      bg: 'snackbar.default.background',
      color: 'snackbar.default.text',
    },
    success: {
      bg: 'snackbar.success.background',
      color: 'snackbar.success.text',
    },
    warning: {
      bg: 'snackbar.warning.background',
      color: 'snackbar.warning.text',
    },
    danger: {
      bg: 'snackbar.danger.background',
      color: 'snackbar.danger.text',
    },
  },
  modal: {
    xs: {
      width: '576px',
    },
    sm: {
      width: '576px',
    },
    default: {
      width: '768px',
    },
    lg: {
      width: '992px',
    },
    xl: {
      width: '1200px',
    },
    fullscreen: {
      width: '100%',
    },
  },
  dropdown: {
    sm: {
      linkSx: {
        py: '0.3rem',
        px: '0.65rem',
        fontSize: 1,
        '& .additional-info': {
          fontSize: 0,
          lineHeight: '12px',
        },
      },
    },
    default: {
      linkSx: {
        py: '0.5rem',
        px: '0.75rem',
        fontSize: 2,
        '& .additional-info': {
          fontSize: 1,
          lineHeight: '14px',
        },
      },
    },
  },
  popper: {
    container: {
      zIndex: 'popper',
      '&[data-popper-placement="bottom"]': {
        '& .arrow': {
          left: '0',
          top: '-4px',
        },
      },
      '&[data-popper-placement="top"]': {
        '& .arrow': {
          bottom: '-4px',
          left: '0',
        },
      },
      '&[data-popper-placement="right"]': {
        '& .arrow': {
          left: '-4px',
          top: '0',
        },
      },
      '&[data-popper-placement="left"]': {
        '& .arrow': {
          right: '-4px',
          top: '0',
        },
      },
      '&[data-popper-placement="bottom-start"]': {
        '& .arrow': {
          left: '-4px',
          top: '-2px',
        },
      },
      '&[data-popper-placement="bottom-end"]': {
        '& .arrow': {
          left: '2.5px',
          top: '-1px',
        },
      },
      '&[data-popper-placement="top-end"]': {
        '& .arrow': {
          left: '3.5px',
          bottom: '-2px',
        },
      },
      '&[data-popper-placement="top-start"]': {
        '& .arrow': {
          left: '-3.3px',
          bottom: '-1px',
        },
      },
    },
    arrow: {
      width: '8px',
      height: '8px',
      bg: 'tooltip.background',
      borderBottomRightRadius: '3px',
      borderTopLeftRadius: '3px',
    },
  },

  tooltip: {
    container: {
      bg: 'tooltip.background',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 'xs',
      boxShadow: 'tooltip',
      maxWidth: '192px',
      py: '0.125rem',
      px: 1,
      height: 'auto',
    },
    content: {
      color: 'tooltip.text',
      overflowWrap: 'anywhere',
      fontSize: 1,
      lineHeight: 'tooltip',
      textAlign: 'center',
      zIndex: 1,
    },
  },

  alerts: {
    warning: {
      ...alertSx,
      bg: 'alerts.background.warning',
    },
    info: {
      ...alertSx,
      bg: 'alerts.background.info',
      color: 'alerts.text.info',
    },
    danger: {
      ...alertSx,
      bg: 'alerts.background.danger',
      color: 'alerts.text.danger',
    },
  },

  drawer: {
    container: {
      flexDirection: 'column',
      position: 'absolute',
      top: [NAVBAR_HEIGHT, null, NAVBAR_HEIGHT + 16],
      right: [0, null, 16],
      boxShadow: [null, null, 'dropShadow.levelTwo'],
      borderRadius: [null, null, 'default'],
      width: ['100%', null, '352px'],
      height: [
        // target iOS devices to fix scrolling bug on drawer (scrolling the page not the drawer)
        `calc(${IS_IOS ? '100vh' : 'var(--app-height)'} - ${NAVBAR_HEIGHT}px)`,
        null,
        `calc(var(--app-height) - ${NAVBAR_HEIGHT + 32}px)`,
      ],
      zIndex: 'drawer',
      py: 3,
      overflow: 'auto',
      bg: 'drawer.background',
      // TODO: Check in the future if we want the frosty glass effect
      // '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
      //   bg: 'transparent',
      //   backgroundImage: `linear-gradient(130deg, ${hexToRgba(baseColors.backgrounds[4], 0.36)}, ${hexToRgba(
      //     baseColors.backgrounds[8],
      //     0.36,
      //   )} 100%)`,
      //   backdropFilter: 'blur(12px)',
      //   border: '1px solid rgba(0,0,0,.08)',
      // },
    },
  },
  notificationCenter: {
    message: {
      color: 'notification.text.message',
      fontSize: 2,
    },
  },
  fancyPersonBox: {
    container: {
      height: '33px',
      alignItems: 'center',
      px: 0,
      py: '0.375rem',
      borderRadius: 'sm',
      border: '1px solid',
      borderColor: 'fancyPersonBox',
    },
  },
  fancyDataBox: {
    container: {
      py: '0.125rem',
      px: 1,
      bg: 'fancyDataBox.bg.container',
      borderRadius: 'sm',
    },
    time: {
      px: 1,
      py: '0.125rem',
      bg: 'fancyDataBox.bg.time',
      borderRadius: 'sm',
    },
  },
  navbarTimeEventTimer: {
    container: {
      work: {
        ...workTimerContainerSx,
        '&[data-hover="true"]': {
          bg: 'workTimer.background.container.hover',
          cursor: 'pointer',
          '&[data-active="true"]': {
            bg: 'workTimer.background.container.activeHover',
          },
        },
      },
      custom: {
        ...workTimerContainerSx,
        '&[data-hover="true"]': {
          cursor: 'pointer',
        },
      },
    },
    content: {
      work: {
        ...workTimerContentSx,
        '&[data-hover="true"]': {
          color: 'workTimer.text.hover',
        },
        '&[data-active="true"]': {
          color: 'workTimer.text.activeHover',
          fontWeight: 'bold',
        },
      },
      custom: {
        ...workTimerContentSx,
        color: 'workTimer.text.custom',
        fontWeight: 'bold',
      },
    },
    timer: {
      fontSize: 3,
      lineHeight: 'workTimer',
    },
  },
  timeEventTimer: {
    container: {
      p: '0.75rem',
      gap: 2,
      alignItems: 'center',
      justifyContent: 'space-between',
      bg: 'timeEventTimer.bg.default',
      borderRadius: 'default',
      transition: 'background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      ':hover': {
        bg: 'timeEventTimer.bg.hover',
        boxShadow: 'timeEventTimer',
      },
    },
    content: {
      flexDirection: 'column',
      lineHeight: 1,
      '&[data-active="true"]': {
        fontWeight: 'bold',
      },
    },

    timer: {
      mt: '0.125rem',
      fontSize: '1.3125rem',
    },
    startTime: {
      fontWeight: 'body',
      color: 'timeEventTimer.text.startTime',
    },
    maxDuration: {
      fontWeight: 'body',
      color: 'timeEventTimer.text.maxDuration',
      fontSize: 2,
    },
  },
  quickbooksDisconnect: {
    container: {
      ...quickbooksDisconnect.container,
    },
    p: {
      mb: 3,
      fontSize: 4,
    },
    pResolved: {
      mb: 2,
      fontSize: 4,
    },
  },
});
