import React, { useMemo } from 'react';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { useAddRequest } from '../../../hooks/useAddRequest';

import { AddRequestFormMain } from './AddRequestFormMain';
import { BusinessTripForm } from './BusinessTripForm/BusinessTripForm';
import { CustomForm } from './CustomForm/CustomForm';
import { ScheduleForm } from './ScheduleForm/ScheduleForm';
import { TimeOffForm } from './TimeOffForm/TimeOffForm';
import { TimeTrackingForm } from './TimeTrackingForm/TimeTrackingForm';

export const AddRequestForms = React.memo((): React.ReactElement => {
  const {
    addRequestState: { requestType },
  } = useAddRequest();

  const requestTypeFormRenderer = useMemo((): React.ReactElement => {
    switch (requestType) {
      case RequestFormType.Schedule:
        return <ScheduleForm />;
      case RequestFormType.TimeTracking:
        return <TimeTrackingForm />;
      case RequestFormType.BusinessTrip:
        return <BusinessTripForm />;
      case RequestFormType.Custom:
        return <CustomForm />;
      case RequestFormType.TimeOff:
      default:
        return <TimeOffForm />;
    }
  }, [requestType]);

  return <AddRequestFormMain>{requestTypeFormRenderer}</AddRequestFormMain>;
});
