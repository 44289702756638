import React from 'react';
import { FlexProps, Flex, Heading, Text, ThemeUIStyleObject } from 'theme-ui';
import { useRecoilValue } from 'recoil';

import { Button } from 'components/ui/Buttons/Button';
import { languageSelector } from 'state/recoilState';
import { LinkButton } from 'components/ui/Buttons';
import { Icon } from 'components/Icon/Icon';
import { TO } from 'constants/routes';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';

type Props = FlexProps & {
  label: string;
  additionalInfo?: React.ReactNode;
  additionalInfoSx?: ThemeUIStyleObject;
  displayReset?: boolean;
  displayOnClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const defaultProps = {
  additionalInfo: undefined,
  additionalInfoSx: undefined,
  displayReset: false,
  displayOnClick: undefined,
};

export const HeadingTip = ({
  label,
  additionalInfo,
  variant = 'heading3',
  sx,
  additionalInfoSx,
  displayReset,
  displayOnClick,
  ...props
}: Props): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  const { isMobileBreakpoint } = useThemeBreakpoint();

  return (
    <Flex sx={{ ...(sx && sx), flexGrow: 1, gap: 2, alignItems: 'flex-start' }} {...props}>
      <Flex sx={{ flexGrow: 1, flexDirection: 'column' }}>
        <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
          {isMobileBreakpoint && variant === 'heading3' && (
            <LinkButton
              to={TO.SETTINGS[language]}
              shape="rounded"
              variant="minimal"
              size="lg"
              sx={{
                p: 1,
              }}
            >
              <Icon type="chevronLeft" />
            </LinkButton>
          )}
          <Heading variant={variant} sx={{ flexGrow: 1 }}>
            {label}
          </Heading>
        </Flex>
        {additionalInfo && (
          <Text
            sx={{
              display: 'block',
              color: 'settings.additionalInfo',
              ...(additionalInfoSx && additionalInfoSx),
            }}
          >
            {additionalInfo}
          </Text>
        )}
      </Flex>
      {displayReset && (
        <Button variant="grey" size="sm" shape="rounded" onClick={displayOnClick}>
          Reset
        </Button>
      )}
    </Flex>
  );
};

HeadingTip.defaultProps = defaultProps;
