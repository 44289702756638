import { Trans } from '@lingui/macro';
import { Text } from 'theme-ui';

import { PATH, TO } from 'constants/routes';
import { SupportedLanguages } from 'constants/supportedLanguages';
import { onboardingStepsType } from '../../types';

export function onboardingReports(
  playNextStep: () => void,
  playPrevStep: () => void,
  language: keyof typeof SupportedLanguages,
): onboardingStepsType {
  return [
    {
      content: (
        <Text>
          <Trans id="onboarding.reports.step1">
            Let's go and see your <b>report</b>, click here.
          </Trans>
        </Text>
      ),
      targetElement: 'onboarding-reports-1',
      onClickElement: playNextStep,
    },
    {
      content: (
        <Text>
          <Trans id="onboarding.reports.step2">
            Navigate to the <b>Attendance List</b>.
          </Trans>
        </Text>
      ),
      targetElement: 'onboarding-reports-2',
      onClickElement: playNextStep,
      onOutsideClick: playPrevStep,
    },
    {
      content: (
        <>
          <Text>
            <Trans id="onboarding.reports.step3.part1">
              This is an <b>attendance list</b> for you and the teammates that you are allowed to see.
            </Trans>
          </Text>
          <Text>
            <Trans id="onboarding.reports.step3.part2">
              Click on <b>any cell</b> to continue.
            </Trans>
          </Text>
        </>
      ),
      targetElement: 'onboarding-reports-3',
      onClickElement: (e) => {
        const node = e.target as HTMLElement;
        const isGridCell = !!node.closest('.grid-cell');
        const isPersonCell = !!node.closest('.person-cell');

        if (!!node && isGridCell && !isPersonCell) {
          playNextStep();
        }
      },
      showOnUrl: PATH.REPORTS__ATTENDANCE_LIST[language],
      urlCompareMode: 'exact',
    },
    {
      content: (
        <>
          <Text>
            <Trans id="onboarding.reports.step4.part1">
              Here are <b>all work-related details</b>. Feel free to munch through it.
            </Trans>
          </Text>
          <Text>
            <Trans id="onboarding.reports.step4.part2">
              Once you are done <b>click anywhere to continue</b>.
            </Trans>
          </Text>
        </>
      ),
      targetElement: 'onboarding-reports-4',
      onClickElement: (e) => {
        e.stopPropagation();
        playNextStep();
      },
      onOutsideClick: playNextStep,
      showOnUrl: TO.REPORTS__ATTENDANCE_LIST__START_DATE_UNIX__END_DATE_UNIX__DETAILS__USER_ID__DATE_UNIX[language],
      urlCompareMode: 'include',
    },
  ];
}
