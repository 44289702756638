import { useEffect } from 'react';
import { AppNavigateOptions, To } from 'react-router-dom';

import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';

export type NavigationValidator = (to?: To | number, options?: AppNavigateOptions) => boolean;

let currentNavigationValidator: NavigationValidator | null;

export const useBlockAppNavigation = (navigationValidator: NavigationValidator) => {
  const navigationValidatorRef = useCallbackRef(navigationValidator);
  useEffect(() => {
    const prevNavigationValidator = currentNavigationValidator;
    currentNavigationValidator = navigationValidatorRef.current;
    return () => {
      currentNavigationValidator = prevNavigationValidator;
    };
  }, [navigationValidatorRef]);
};

export const useValidateNavigation = () => (to?: To | number, options?: AppNavigateOptions) =>
  currentNavigationValidator ? currentNavigationValidator(to, options) : true;
