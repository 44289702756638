import { t } from '@lingui/macro';

import { InputOption } from '../Select/Select';

type GetInputsValuesFromSecondsReturnValue = {
  hours: string;
  minutes: string;
  seconds: string;
};

export const getInputsValuesFromSeconds = (
  s: number,
  extended?: 'hours' | 'minutes' | 'seconds',
): GetInputsValuesFromSecondsReturnValue => {
  const getSeconds = `${s % 60}`.slice(-2);
  const getMinutes = `${Math.floor(s / 60) % 60}`.slice(-2);
  const getHours = `${Math.floor(s / 3600)}`.slice(extended === 'hours' ? -3 : -2);
  const getExtendedMinutes = `${Math.floor(s / 60)}`.slice(-3);
  const getExtendedSeconds = `${s}`.slice(-3);

  return {
    hours: getHours,
    minutes: extended === 'minutes' ? getExtendedMinutes : getMinutes,
    seconds: extended === 'seconds' ? getExtendedSeconds : getSeconds,
  };
};

export const getSelectOptions = (secondsArray: undefined | number[], sortingOrder: 'asc' | 'desc'): InputOption[] => {
  if (!secondsArray) {
    return [];
  }

  return secondsArray
    .sort((a, b) => (sortingOrder === 'desc' ? +b - +a : +a - +b))
    .map((sec) => {
      const newValues = getInputsValuesFromSeconds(sec);

      const h = +newValues.hours > 0 ? `${+newValues.hours}h ` : '';
      const m = +newValues.minutes > 0 ? `${+newValues.minutes}min ` : '';
      const s =
        +newValues.seconds > 0
          ? `${+newValues.seconds}${t({
              id: 'duration_picker.sec',
            })}`
          : '';

      return {
        id: `${sec}`,
        label: sec ? h + m + s : '0min',
      };
    });
};

export const getMinutesSelectOptions = (
  secondsArray: undefined | number[],
  sortingOrder: 'asc' | 'desc',
): InputOption[] => {
  if (!secondsArray) {
    return [];
  }

  return secondsArray
    .sort((a, b) => (sortingOrder === 'desc' ? +b - +a : +a - +b))
    .map((sec) => {
      const newValues = getInputsValuesFromSeconds(sec, 'minutes');

      const m = +newValues.minutes > 0 ? `${+newValues.minutes}min ` : '0min';

      return {
        id: `${sec}`,
        label: m,
      };
    });
};

export const getSecondsFromHours = (h: number): number => h * 3600;
export const getSecondsFromMinutes = (m: number): number => m * 60;
