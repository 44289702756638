import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { DEFAULT_DATE_STATIC_VALUE } from 'components/ui/DatePicker/Calendar/constants';
import { InputOption } from 'components/ui/Select/Select';
import { dateTime } from 'utils/dateTime';

export const useDateSelectOptions = (dates?: number[]): InputOption[] =>
  useMemo(
    () =>
      dates
        ? [...new Set(dates)]
            .sort((a, b) => a - b)
            .map((date) => ({
              id: `${date}`,
              label: dateTime(date).format(DEFAULT_DATE_STATIC_VALUE),
            }))
        : [],
    [dates],
  );
