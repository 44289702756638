import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Input, Link, Text } from 'theme-ui';

import { MagnetInterval } from 'api/actions/settings/settingsActions.types';
import { Divider } from 'components/Divider/Divider';
import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { Select } from 'components/ui/Select/Select';
import { Switch } from 'components/ui/Switch';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';
import { roundToMinutesSelectOptionsSelector } from 'state/selectOptions';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { useTheme } from 'styles/useTheme';
import { useFormContextCustomEvent } from '../../../../../../../../hooks/useFormContextCustomEvent';
import { OptionLabel } from '../../../../../../../OptionLabel';
import { SettingsGroup } from '../../../../../../../SettingsGroup/SettingsGroup';
import { wrapperLinkSx } from '../../../../../../../styles/wrappers';
import { useAutomationsSettings } from '../../../../../../hooks/useAutomationsSettings';

import { TimeRoundingSamples } from './components/TimeRoundingSamples';

export const TimeRounding = (): React.ReactElement => {
  useLingui();

  const { optionsMinutes } = useAutomationsSettings();
  const roundToMinutesSelectOptions = useRecoilValue(roundToMinutesSelectOptionsSelector);
  const { theme } = useTheme();

  const timeRoundingLink = useHelpLink({
    inEwi: {
      pl: '/article/przyciaganie-zdarzen-oraz-interwaly-1kqo0de/',
    },
    unrubble: {
      en: '/article/time-snapping-138entx/',
    },
  });

  const {
    registerOnBlur,
    registerOnChange,
    watch,
    formState: { errors },
    setValue,
  } = useFormContextCustomEvent<{
    timeRounding: {
      roundingValue: number;
      enableTimeRounding: boolean;
      hasHalfTheRoundingValueTolerance: boolean;
      clockInRoundingToleranceMinutes: number;
      clockOutRoundingToleranceMinutes: number;
    };
  }>();

  const timeRoundingEnableWatch = watch('timeRounding.enableTimeRounding');
  const roundToMinutesWatch = watch('timeRounding.roundingValue') as MagnetInterval;
  const halfRoundingWatch = watch('timeRounding.hasHalfTheRoundingValueTolerance');
  const clockInToleranceWatch = watch('timeRounding.clockInRoundingToleranceMinutes');
  const clockOutToleranceWatch = watch('timeRounding.clockOutRoundingToleranceMinutes');

  const validateMagnetsValues = useCallback(
    (roundingMinutes: number, clockIn?: number, clockOut?: number) => {
      const roundingSeconds = roundingMinutes * 60;
      const newValidationValue = roundingSeconds;

      if (clockIn && clockIn > roundingSeconds) {
        setValue('timeRounding.clockInRoundingToleranceMinutes', newValidationValue);
      }

      if (clockOut && clockOut > roundingSeconds) {
        setValue('timeRounding.clockOutRoundingToleranceMinutes', newValidationValue);
      }
    },
    [setValue],
  );

  useEffect(() => {
    validateMagnetsValues(roundToMinutesWatch, clockInToleranceWatch, clockOutToleranceWatch);
  }, [clockInToleranceWatch, clockOutToleranceWatch, roundToMinutesWatch, validateMagnetsValues]);

  const clockInOutQuickSelectOptions = useMemo(() => {
    const filteredOptionsMinutes = optionsMinutes.filter((item) => item <= roundToMinutesWatch);

    return filteredOptionsMinutes.map((item) => item * 60);
  }, [optionsMinutes, roundToMinutesWatch]);

  return (
    <SettingsGroup.Body>
      <SettingsGroup.Body.Visible
        variant="md"
        pb={timeRoundingEnableWatch ? 2 : '0.75rem'}
        withoutBorderBottom={timeRoundingEnableWatch}
      >
        <Switch
          {...registerOnChange('timeRounding.enableTimeRounding')}
          label={t({
            id: 'automations_settings.allow_time_rounding.label',
            message: 'Time rounding',
          })}
          additionalInfo={
            <Text>
              <Trans id="automations_settings.allow_time_rounding.additionalInfo">
                Whenever employees clock in or out the time will be rounded to a selected value.
              </Trans>{' '}
              <Link href={timeRoundingLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                {t({ id: 'settings.learn_more' })}
              </Link>
              .
            </Text>
          }
          size="sm"
          bold
        />
      </SettingsGroup.Body.Visible>

      {timeRoundingEnableWatch && (
        <>
          <SettingsGroup.Body.Hidden variant="md" sx={{ py: 2 }}>
            <OptionLabel
              label={t({
                id: 'automations_settings.round_to_minutes.label',
                message: 'Rounding value',
              })}
              apendWith={
                <Select
                  {...registerOnBlur('timeRounding.roundingValue', {
                    valueAsNumber: true,
                    required: t({ id: 'global.forms.required.short' }),
                  })}
                  id="snapTime"
                  size="sm"
                  options={roundToMinutesSelectOptions}
                  error={!!errors?.timeRounding?.roundingValue}
                  errorMessage={errors?.timeRounding?.roundingValue?.message}
                  sx={{ minWidth: '125px' }}
                  customContent={
                    <>
                      <Input
                        name="minutes"
                        value={roundToMinutesWatch}
                        sx={{
                          ...theme.forms.timePicker.twoDigitInput.sm,
                          pointerEvents: 'none',
                        }}
                        readOnly
                      />
                      <Text sx={{ ml: 1, mr: 2 }}>m</Text>
                    </>
                  }
                />
              }
              withDivider
            />
            <Switch
              {...registerOnChange('timeRounding.hasHalfTheRoundingValueTolerance')}
              label={t({
                id: 'automations_settings.half_rounding_value',
                message: 'Half the rounding value',
              })}
              size="sm"
              bold
              sx={{ py: SETTINGS_SPACE.automationsSwitchWithLabel }}
            />

            {!halfRoundingWatch && (
              <>
                <Divider />
                <OptionLabel
                  label={t({
                    id: 'automations_settings.late_clock_ins',
                    message: 'Clock-in tolerance',
                  })}
                  apendWith={
                    <DurationPicker
                      {...registerOnBlur('timeRounding.clockInRoundingToleranceMinutes', {
                        valueAsNumber: true,
                        required: t({ id: 'global.forms.required.short' }),
                      })}
                      id="clockInTolerance"
                      size="sm"
                      quickSelectOptions={clockInOutQuickSelectOptions}
                      inputVariant="minutes"
                      hours={false}
                      seconds={false}
                      error={!!errors?.timeRounding?.clockInRoundingToleranceMinutes}
                      errorMessage={errors?.timeRounding?.clockInRoundingToleranceMinutes?.message}
                      sortingOrder="asc"
                      sx={{ minWidth: '125px' }}
                    />
                  }
                />
                <OptionLabel
                  label={t({
                    id: 'automations_settings.early_clock_outs',
                    message: 'Clock-out tolerance',
                  })}
                  apendWith={
                    <DurationPicker
                      {...registerOnBlur('timeRounding.clockOutRoundingToleranceMinutes', {
                        valueAsNumber: true,
                        required: t({ id: 'global.forms.required.short' }),
                      })}
                      id="clockOutTolerance"
                      size="sm"
                      quickSelectOptions={clockInOutQuickSelectOptions}
                      inputVariant="minutes"
                      hours={false}
                      seconds={false}
                      error={!!errors?.timeRounding?.clockOutRoundingToleranceMinutes}
                      errorMessage={errors?.timeRounding?.clockOutRoundingToleranceMinutes?.message}
                      sortingOrder="asc"
                      sx={{ minWidth: '125px' }}
                    />
                  }
                />
              </>
            )}
          </SettingsGroup.Body.Hidden>

          <SettingsGroup.Body.Visible
            variant="md"
            pt={3}
            pb={3}
            sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          >
            <TimeRoundingSamples />
          </SettingsGroup.Body.Visible>
        </>
      )}
    </SettingsGroup.Body>
  );
};
