import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { editTimeEventAction } from 'api/actions/timeEvent/timeEventActions';
import { EditTimeEventActionProps, ExtendedTimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { useModal } from 'components/Modal/output/useModal';
import { ListNames } from 'components/StickyList/types';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { editEventLoadingSaveAtom } from 'state/clockLog';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { languageSelector } from 'state/recoilState';
import { statusesMapSelectorFamily } from 'state/selectOptions';
import { getDateWithTimeInSecondsUnix } from 'utils/dateTime';
import { useRefreshClockLog } from '../../hooks/useRefreshClockLog';
import { EditInputValues } from '../types';

type Props = {
  prevTimeEvent: ExtendedTimeEvent;
  isPreview?: boolean;
};

export const useEditEvent = ({ prevTimeEvent, isPreview }: Props) => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const { addSnackbar } = useSnackbar();
  const { handleClose, baseRoute } = useModal();
  const { id, personId, typeId } = prevTimeEvent;

  const statuses = useRecoilValue(statusesMapSelectorFamily(typeId));
  const setIsSaveLoading = useSetRecoilState(editEventLoadingSaveAtom);
  const setSelectedEventIds = useSetRecoilState(selectedRowsIdsSelectorFamily(ListNames.CLOCK_LOG));
  const { mutate } = useMutation((body: EditTimeEventActionProps) =>
    editTimeEventAction(body, {
      baseRoute: {
        [language]: baseRoute,
      },
    }),
  );

  const { clockLogInitialized, refreshClockLogForPeopleIds } = useRefreshClockLog([personId]);
  const { reportInitialized, updateReportForIds } = useRefreshReport([personId]);
  const editTimeEvent = useCallback(
    async (inputData: EditInputValues) => {
      if (isPreview) return;
      setIsSaveLoading(true);

      const dehashedType = statuses?.get(inputData.hashedEventType);
      if (!dehashedType) return;

      const newTimeEvent = {
        id,
        dateTimeUnix: getDateWithTimeInSecondsUnix(Number(inputData.eventDate), Number(inputData.eventTime), true),
        timeEventTypeId: dehashedType?.id.toLocaleLowerCase(),
        note: inputData.note || undefined,
        isEnd: dehashedType?.isEndStatus,
      };

      const { error } = await mutate(newTimeEvent);

      if (!error) {
        addSnackbar({
          message: t({ id: 'clock_log.edit_event.success', message: 'Event modified successfully!' }),
          variant: 'success',
        });

        if (clockLogInitialized) await refreshClockLogForPeopleIds();
        if (reportInitialized) await updateReportForIds();

        setSelectedEventIds((prev) => prev.filter((eventId) => eventId !== id));
        handleClose();
      }

      setIsSaveLoading(false);
    },
    [
      addSnackbar,
      clockLogInitialized,
      handleClose,
      id,
      isPreview,
      mutate,
      reportInitialized,
      setIsSaveLoading,
      setSelectedEventIds,
      statuses,
      updateReportForIds,
      refreshClockLogForPeopleIds,
    ],
  );

  const editTimeEventError = useCallback(() => setIsSaveLoading(false), [setIsSaveLoading]);

  return { editTimeEvent, editTimeEventError };
};
