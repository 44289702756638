import { Action, DefaultErrorMessageEnum } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  FetchChatUsersActionProps,
  FetchChatUsersResponse,
  FetchMessagesActionProps,
  FetchMessagesResponse,
  PostHasBeenReadActionProps,
  PostHasBeenReadResponse,
  PostMessageActionProps,
  PostMessageResponse,
  RemoveMessageActionProps,
  RemoveMessageResponse,
} from './chatActions.types';

export function fetchMessagesAction({ id, part }: FetchMessagesActionProps): Action<FetchMessagesResponse> {
  const queryParams = new URLSearchParams({
    id,
    part: `${part}`,
  });
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.chat.messages}?${queryParams}`,
  };
}

export function fetchChatUsersAction(config?: FetchChatUsersActionProps): Action<FetchChatUsersResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.chat.chatUsers,
    config: {
      ...config,
      customDefaultErrorMessage: DefaultErrorMessageEnum.None,
    },
  };
}

export function postMessageAction(body: PostMessageActionProps): Action<PostMessageResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.chat.message,
    body,
  };
}

export function removeMessageAction(props: RemoveMessageActionProps): Action<RemoveMessageResponse> {
  const queryParams = new URLSearchParams(props);
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.chat.deleteMessage}?${queryParams}`,
  };
}

export function postHasBeenReadAction(props: PostHasBeenReadActionProps): Action<PostHasBeenReadResponse> {
  const queryParams = new URLSearchParams(props);
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.chat.hasBeenRead}?${queryParams}`,
  };
}
