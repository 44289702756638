import { useCallback } from 'react';
import { FieldValues, Path, RegisterOptions, useForm, UseFormProps } from 'react-hook-form';

type Props<TFieldValues extends FieldValues, TContext> = {
  onChangeCustom?: () => void;
  onBlurCustom?: () => void;
  formProps?: UseFormProps<TFieldValues, TContext>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFormCustomEvent = <TFieldValues extends FieldValues = FieldValues, TContext = any>({
  onChangeCustom,
  onBlurCustom,
  formProps,
}: Props<TFieldValues, TContext>) => {
  const { register, ...methods } = useForm<TFieldValues, TContext>(formProps);

  const registerOnChange = useCallback(
    (name: Path<TFieldValues>, options?: RegisterOptions) => {
      const { onChange, ...restRegister } = register(name, options);

      return {
        ...restRegister,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          void onChange(e);
          if (onChangeCustom) onChangeCustom();
        },
      };
    },
    [onChangeCustom, register],
  );

  const registerOnBlur = useCallback(
    (registerName: Path<TFieldValues>, registerOptions?: RegisterOptions) => {
      const { onBlur, ...restRegister } = register(registerName, registerOptions);

      return {
        ...restRegister,
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
          void onBlur(e);
          if (onBlurCustom) onBlurCustom();
        },
      };
    },
    [onBlurCustom, register],
  );

  return { ...methods, register, registerOnChange, registerOnBlur };
};
