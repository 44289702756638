import { useRecoilValue } from 'recoil';

import { EditablePermissionsKey } from 'api/actions/organizationSession/organizationSessionActions.types';
import { nonEditableEmployeesForModulesIdsSelectorFamily } from 'state/employees';

/**
 * @param {EditablePermissionsKey} module
 *
 * @return {string[]} Returns array of ids.
 */
export const useNonEditableEmployeesIds = (module: EditablePermissionsKey) => {
  const nonEditableEmployeesIds = useRecoilValue(nonEditableEmployeesForModulesIdsSelectorFamily(module));

  return nonEditableEmployeesIds;
};
