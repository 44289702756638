import React from 'react';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';

const LazyTimesheets = React.lazy(() =>
  import('./components/Timesheets/Timesheets').then(({ Timesheets }) => ({
    default: Timesheets,
  })),
);

export const TimesheetsRenderer = React.memo(
  (): React.ReactElement => (
    <React.Suspense fallback={<LoadingOverlay sx={{ zIndex: 'base' }} />}>
      <LazyTimesheets />
    </React.Suspense>
  ),
);
