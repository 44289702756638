import React from 'react';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';

const LazyAttendanceList = React.lazy(() =>
  import('./components/AttendanceList/AttendanceList').then(({ AttendanceList }) => ({
    default: AttendanceList,
  })),
);

export const AttendanceListRenderer = React.memo(
  (): React.ReactElement => (
    <React.Suspense fallback={<LoadingOverlay sx={{ zIndex: 'base' }} />}>
      <LazyAttendanceList />
    </React.Suspense>
  ),
);
