import { useEffect, useRef } from 'react';
import { RecoilValueReadOnly, useRecoilState, useRecoilValue } from 'recoil';

import { Location } from 'api/actions/location/locationActions.types';
import { ListNames } from 'components/StickyList/types';
import { FilterGroupNames } from 'layouts/AuthorizedApp/AsideFilters/types';
import { rolesMapSelector, tagsMapSelector } from 'state/employees';
import { filterGroupStateAtomFamily } from 'state/filters';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { locationsAtom } from 'state/locations';
import { OrganizationSessionAtomType, organizationSessionPropertySelectorFamily } from 'state/organizationSession';

export const DataSideEffectsObserver: React.ElementType = () => {
  const employeesMap = useRecoilValue(
    organizationSessionPropertySelectorFamily('employeesMap') as RecoilValueReadOnly<
      OrganizationSessionAtomType['employeesMap'] | null
    >,
  );
  const [selectedEmployeesIds, setSelectedEmployeesIds] = useRecoilState(selectedRowsIdsSelectorFamily(ListNames.TEAM));

  const selectedEmployeesIdsRef = useRef(selectedEmployeesIds);
  selectedEmployeesIdsRef.current = selectedEmployeesIds;

  const workStatuses = useRecoilValue(
    organizationSessionPropertySelectorFamily('timeEventTypes') as RecoilValueReadOnly<
      OrganizationSessionAtomType['timeEventTypes'] | null
    >,
  );
  const [selectedWorkStatusesIds, setSelectedWorkStatusesIds] = useRecoilState(
    selectedRowsIdsSelectorFamily(ListNames.WORK_STATUSES),
  );
  const selectedWorkStatusesIdsRef = useRef(selectedWorkStatusesIds);
  selectedWorkStatusesIdsRef.current = selectedWorkStatusesIds;

  const [tagsFilterGroupState, setTagsFilterGroupState] = useRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.TAGS),
  );
  const [rolesFilterGroupState, setRolesFilterGroupState] = useRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.ROLES),
  );
  const [locationsFilterGroupState, setLocationsFilterGroupState] = useRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.LOCATIONS),
  );

  const rolesMap = useRecoilValue(rolesMapSelector);
  const tagsMap = useRecoilValue(tagsMapSelector);
  const locations = useRecoilValue(locationsAtom);

  const tagsFilterGroupStateRef = useRef(tagsFilterGroupState);
  tagsFilterGroupStateRef.current = tagsFilterGroupState;

  const rolesFilterGroupStateRef = useRef(rolesFilterGroupState);
  rolesFilterGroupStateRef.current = rolesFilterGroupState;

  const locationsFilterGroupStateRef = useRef(locationsFilterGroupState);
  locationsFilterGroupStateRef.current = locationsFilterGroupState;

  // check if selectedEmployeesIds contains ids that are no longer in organizationSession
  useEffect(() => {
    const selectedIds = selectedEmployeesIdsRef.current;
    if (employeesMap && selectedIds.length) {
      const filteredSelectedIds = selectedIds.filter((id) => employeesMap.get(id));
      if (filteredSelectedIds.length !== selectedIds.length) {
        setSelectedEmployeesIds(filteredSelectedIds);
      }
    }
  }, [employeesMap, setSelectedEmployeesIds]);

  // check if selectedWorkStatusesIds contains ids that are no longer in organizationSession
  useEffect(() => {
    const selectedIds = selectedWorkStatusesIdsRef.current;
    if (workStatuses && selectedIds.length) {
      const filteredSelectedIds = selectedIds.filter((id) =>
        workStatuses.find(({ id: workStatusId, isDeleted }) => workStatusId === id && !isDeleted),
      );
      if (filteredSelectedIds.length !== selectedIds.length) {
        setSelectedWorkStatusesIds(filteredSelectedIds);
      }
    }
  }, [setSelectedWorkStatusesIds, workStatuses]);

  // check if current filter states contains tags or roles ids that were deleted
  useEffect(() => {
    if (tagsFilterGroupStateRef.current && tagsMap) {
      const filteredTagsFilterGroupState = tagsFilterGroupStateRef.current.filter((tagId) => tagsMap.get(tagId));

      if (filteredTagsFilterGroupState.length !== tagsFilterGroupStateRef.current.length) {
        setTagsFilterGroupState(!filteredTagsFilterGroupState.length ? null : filteredTagsFilterGroupState);
      }
    }
  }, [tagsMap, setTagsFilterGroupState]);

  useEffect(() => {
    if (rolesFilterGroupStateRef.current && rolesMap) {
      const filteredRolesFilterGroupState = rolesFilterGroupStateRef.current.filter((tagId) => rolesMap.get(tagId));
      if (filteredRolesFilterGroupState.length !== rolesFilterGroupStateRef.current.length) {
        setRolesFilterGroupState(!filteredRolesFilterGroupState.length ? null : filteredRolesFilterGroupState);
      }
    }
  }, [rolesMap, setRolesFilterGroupState]);

  // check if filter state contains deleted location ids
  useEffect(() => {
    if (locationsFilterGroupStateRef.current && locations) {
      const filteredLocationsFilterGroupState = locationsFilterGroupStateRef.current.filter(
        (filterLocationId: string) => locations.find(({ id }: Location) => id === filterLocationId),
      );
      if (filteredLocationsFilterGroupState.length !== locationsFilterGroupStateRef.current.length) {
        setLocationsFilterGroupState(
          !filteredLocationsFilterGroupState.length ? null : filteredLocationsFilterGroupState,
        );
      }
    }
  }, [locations, setLocationsFilterGroupState]);

  return null;
};
