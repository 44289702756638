import { Face } from '@tensorflow-models/face-detection';
import { PixelInput } from '@tensorflow-models/face-detection/dist/shared/calculators/interfaces/common_interfaces';
import { atom } from 'recoil';

export type FaceDetectionModel = {
  isModelLoaded: boolean;
  getPrediction?: (source: PixelInput) => Promise<Face[]>;
};

export const faceDetectionModelAtom = atom<FaceDetectionModel>({
  key: 'kiosk__faceDetectionModel',
  default: {
    isModelLoaded: false,
  },
});
