import React from 'react';
import { Global } from '@emotion/react';

import { Navbar } from 'layouts/AuthorizedApp/Navbar';
import { THEME_NAVBAR_HEIGHT } from 'styles/theme/base';

import { AsideFilters } from './AsideFilters';

type Props = {
  children: React.ReactElement[] | React.ReactElement;
};

export const AuthorizedAppLayout = ({ children }: Props): React.ReactElement => (
  <>
    <Global
      styles={(theme) => ({
        body: {
          background: theme.colors.backgrounds.authorizedApp,
          paddingTop: THEME_NAVBAR_HEIGHT,
          '@media screen and (max-width: 991px)': {
            paddingBottom: THEME_NAVBAR_HEIGHT,
          },
        },
      })}
    />

    <Navbar />

    <AsideFilters />

    {children}
  </>
);

export * from './Main/Main';
