import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { FetchCalendarResponse } from 'api/actions/calendar/calendarActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { workPositionDictionarySelector } from 'state/organizationSession';
import { DetailedScheduleElement } from '../renderers/components/CalendarDayDetails/components/DetailedScheduleElement';

type Props = {
  onScheduleClick: (scheduleIndex: number) => void;
  recentSchedules: NonNullable<FetchCalendarResponse['recentSchedules']>;
};

export const RecentSchedulePickerModal = ({ onScheduleClick, recentSchedules }: Props): React.ReactElement => {
  const workPositionTypes = useRecoilValue(workPositionDictionarySelector);

  useLingui();

  const { handleClose } = useModal();

  const handleOnClick = useCallback(
    (scheduleIndex: number) => {
      onScheduleClick(scheduleIndex);
      handleClose();
    },
    [handleClose, onScheduleClick],
  );

  const renderRecentSchedules = useCallback(
    () =>
      recentSchedules.map(({ details, workPositionId }, index) => {
        const {
          color,
          type,
          addAutomaticBreak,
          countOvertimeAfterWork,
          countOvertimeBeforeWork,
          endDateTimeUnix,
          startDateTimeUnix,
          isOvertimeSchedule,
          workTimeUnix,
          automaticBreakDurationUnix,
        } = details;

        return (
          <DetailedScheduleElement
            key={index}
            scheduleId={`${index}`}
            isOvertimeSchedule={isOvertimeSchedule}
            scheduleColor={color}
            type={type}
            withAutoBreak={addAutomaticBreak}
            autoBreakDuration={automaticBreakDurationUnix}
            countOvertimeAfterWork={countOvertimeAfterWork}
            countOvertimeBeforeWork={countOvertimeBeforeWork}
            endDateTimeUnix={endDateTimeUnix}
            startDateTimeUnix={startDateTimeUnix}
            workTimeUnix={workTimeUnix}
            workPosition={workPositionId && workPositionTypes ? workPositionTypes[workPositionId].name : undefined}
            onClick={() => handleOnClick(index)}
            hideButtons
          />
        );
      }),
    [handleOnClick, recentSchedules, workPositionTypes],
  );

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="schedule.pick_schedule">Pick a recent schedule</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '0.125rem',
          }}
        >
          {renderRecentSchedules()}
        </Flex>
      </Modal.Body>
    </>
  );
};
