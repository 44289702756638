import { Trans } from '@lingui/macro';
import React, { startTransition, useEffect, useMemo } from 'react';
import { useRecoilState_TRANSITION_SUPPORT_UNSTABLE, useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { GUTTER_DEFAULT_SIZE } from 'components/StickyList/constants';
import { CLOCK_LOG_LIST_ITEM_HEIGHT } from 'constants/clockLog';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { ClockLogList } from 'pages/ClockLog/output/ClockLogList';
import { useGenerateClockLogColumns } from 'pages/ClockLog/output/useGenerateClockLogColumns';
import { clockLogColumnsAtom } from 'state/clockLog';
import { parsedAntiSpoofingChecksSelector } from 'state/home';
import { languageSelector } from 'state/recoilState';

import { useAntiSpoofingChecks } from './hooks/useAntiSpoofingChecks';

type Props = {
  onFetchResponse: () => void;
};

export const AntiSpoofingList = ({ onFetchResponse }: Props) => {
  const [clockLogColumns, setClockLogColumns] = useRecoilState_TRANSITION_SUPPORT_UNSTABLE(clockLogColumnsAtom);
  const antiSpoofingChecks = useRecoilValue(parsedAntiSpoofingChecksSelector);

  const language = useRecoilValue(languageSelector);

  const clockLogGeneratedColumns = useGenerateClockLogColumns();

  const { isInitialized: isInitializedAntiSpoofing, fetchAntiSpoofingChecks } = useAntiSpoofingChecks();

  const isEmptyAntiSpoofingChecks = useMemo(
    () => antiSpoofingChecks && antiSpoofingChecks.size === 0,
    [antiSpoofingChecks],
  );

  const antiSpoofingChecksListHeight = useMemo(() => {
    if (!antiSpoofingChecks || isEmptyAntiSpoofingChecks) return undefined;
    if (antiSpoofingChecks.size > 9) return `${(CLOCK_LOG_LIST_ITEM_HEIGHT + GUTTER_DEFAULT_SIZE) * 10 + 48}px`;
    return `${(CLOCK_LOG_LIST_ITEM_HEIGHT + GUTTER_DEFAULT_SIZE) * (antiSpoofingChecks.size + 1) + 48}px`;
  }, [antiSpoofingChecks, isEmptyAntiSpoofingChecks]);

  // FIXME: this rerenders always, find a better way to support changes in header titles
  useEffect(() => {
    if ((!clockLogColumns && clockLogGeneratedColumns) || language) {
      startTransition(() => setClockLogColumns(clockLogGeneratedColumns));
    }
  }, [clockLogColumns, clockLogGeneratedColumns, language, setClockLogColumns]);

  const onFetchResponseRef = useCallbackRef(onFetchResponse);

  useEffect(() => {
    const fetchChecks = async () => {
      await fetchAntiSpoofingChecks();
      onFetchResponseRef.current();
    };
    void fetchChecks();
  }, [fetchAntiSpoofingChecks, onFetchResponseRef]);

  if (!antiSpoofingChecks?.size && isInitializedAntiSpoofing) {
    return null;
  }

  return (
    <Flex
      sx={{
        minHeight: antiSpoofingChecksListHeight || '2.5rem',
        position: 'relative',
        flexDirection: 'column',
        gap: '0.125rem',
        mr: -4,
        overflow: ['auto', null, null, 'unset'],
      }}
    >
      {!isInitializedAntiSpoofing && <LoadingOverlay size={3} sx={{ zIndex: 'base' }} />}

      {antiSpoofingChecks?.size ? (
        <React.Suspense fallback={<LoadingOverlay size={3} sx={{ zIndex: 'base' }} />}>
          {clockLogColumns && !isEmptyAntiSpoofingChecks && (
            <>
              <Text sx={{ fontWeight: '700' }}>
                <Trans id="home.things_to_do.anti_spoofing_checks">Anti-spoofing checks</Trans>
              </Text>
              <ClockLogList
                selectable={false}
                columnsAtom={clockLogColumnsAtom}
                clockLogListAtom={parsedAntiSpoofingChecksSelector}
                listType="AIAntiSpoofing"
              />
            </>
          )}
        </React.Suspense>
      ) : null}
    </Flex>
  );
};
