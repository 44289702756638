import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeUIProvider } from 'theme-ui';

import { SnackbarProvider } from 'base/Snackbar/output/SnackbarProvider';
import { BASENAME } from 'constants/common';
import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { ClientContextController } from 'context/client/clientContextController/ClientContextController';
import { DateTimeZoneProvider } from 'context/dateTimeZone/DateTimeZoneProvider';
import { LanguageProvider } from 'context/language/LanguageProvider';
import { UserSessionInitializer } from 'context/session/userSessionInitializer/UserSessionInitializer';
import { stateInitializer } from 'state/stateInitializer';
import { theme } from 'styles/theme/index';

type Props = {
  children: React.ReactElement[];
};

export const AppContext = ({ children }: Props): React.ReactElement => (
  <ThemeUIProvider theme={theme}>
    <RecoilRoot initializeState={stateInitializer}>
      <LanguageProvider>
        <DateTimeZoneProvider />
        <SnackbarProvider />
        <AuthContextController>
          <ClientContextController>
            <UserSessionInitializer>
              <Router basename={BASENAME}>{children}</Router>
            </UserSessionInitializer>
          </ClientContextController>
        </AuthContextController>
      </LanguageProvider>
    </RecoilRoot>
  </ThemeUIProvider>
);
