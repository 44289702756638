import React from 'react';
import { Flex } from 'theme-ui';

import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';

export const SettingsLoadingSpinnerCss = (): React.ReactElement => (
  <Flex
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '400px',
    }}
  >
    <LoadingSpinnerCss size={4} />
  </Flex>
);
