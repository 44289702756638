import { keyframes } from '@emotion/react';
import { ThemeUICSSObject } from 'theme-ui';

import { makeTheme } from '../makeTheme';

import { zIndices } from './base';

const SIZES = {
  arrow: 12,
  modalMaxWidth: '500px',
  tooltipMaxWidth: '360px',
};

const scaleAnimation = keyframes({
  '0%': {
    opacity: '0',
  },
  '100%': {
    opacity: '1',
  },
});

const containerCommonStyles: ThemeUICSSObject = {
  zIndex: 'onboarding',
  bg: 'onboarding.bg.default',
  borderRadius: 'sm',
  boxShadow: 'onboarding',
  flexDirection: 'column',
  '> .closeBtn': {
    position: 'absolute',
    right: 1,
    top: 1,
    p: 0,
  },
};

export const onboardingStyles = makeTheme({
  onboarding: {
    modalHeaderIcon: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -55%)',
      fontSize: 7,
      lineHeight: 1,
      p: 3,
      borderRadius: 'pill',
      bg: 'onboarding.modalHeaderIcon.bg',
      border: '3px solid',
      borderColor: 'onboarding.modalHeaderIcon.border',
    },
    backdrop: {
      position: 'fixed',
      inset: 0,
      bg: 'onboarding.backdrop',
      zIndex: zIndices.onboarding - 1,
    },
    container: {
      tooltip: {
        ...containerCommonStyles,
        animation: `${scaleAnimation} 0.25s ease-in-out`,
        maxWidth: SIZES.tooltipMaxWidth,
        '&[data-popper-placement="top"], &[data-popper-placement="top-end"], &[data-popper-placement="top-start"]': {
          '& .arrow': {
            bottom: `${-SIZES.arrow / 2}px`,
            left: 0,
          },
        },
        '&[data-popper-placement="right"]': {
          '& .arrow': {
            left: `${-SIZES.arrow / 2}px`,
          },
        },
        '&[data-popper-placement="left"]': {
          '& .arrow': {
            right: `${-SIZES.arrow / 2}px`,
          },
        },
        '&[data-popper-placement="bottom"], &[data-popper-placement="bottom-start"], &[data-popper-placement="bottom-end"]':
          {
            '& .arrow': {
              top: `${-SIZES.arrow / 2}px`,
            },
          },
        '> section': {
          bg: 'onboarding.bg.default',
          borderRadius: 'sm',
          position: 'relative',
          flexDirection: 'column',
          gap: 3,
          px: 4,
          py: 3,
        },
      },
      modal: {
        ...containerCommonStyles,
        maxWidth: SIZES.modalMaxWidth,
        '@media (max-width: 576px)': {
          maxWidth: '90%',
          width: '100%',
        },
        '> section': {
          position: 'relative',
          flexDirection: 'column',
          gap: 3,
          py: 6,
          px: '2.5rem',
        },
      },
    },
    arrow: {
      width: `${SIZES.arrow}px`,
      height: `${SIZES.arrow}px`,
      bg: 'onboarding.bg.default',
      borderBottomRightRadius: `${SIZES.arrow / 4}px`,
      borderTopLeftRadius: `${SIZES.arrow / 4}px`,
      boxShadow: 'onboarding',
      zIndex: -1,
    },
    focusBackdrop: {
      element: {
        zIndex: zIndices.onboarding - 1,
        position: 'fixed',
        bg: 'onboarding.focusBackdrop',
        borderRadius: 'xs',
        pointerEvents: 'none',
        boxShadow: `focusBackdrop`,
      },
      blocker: {
        zIndex: 'onboarding',
        position: 'fixed',
      },
    },
  },
});
