import React from 'react';
import { Trans } from '@lingui/macro';
import { Flex } from 'theme-ui';

import { LinkButton, LinkButtonProps } from 'components/ui/Buttons/LinkButton';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { useTheme } from 'styles/useTheme';

export type LinkButtonWithLoadingProps = LinkButtonProps & {
  isLoading: boolean;
};

const defaultProps: Partial<LinkButtonWithLoadingProps> = {
  variant: 'default',
  size: 'default',
};

export const LinkButtonWithLoading = ({
  isLoading,
  size = 'default',
  variant = 'default',
  ...props
}: LinkButtonWithLoadingProps): React.ReactElement => {
  const { theme } = useTheme();

  return (
    <LinkButton {...props} size={size} variant={variant}>
      <ConditionalWrapper
        condition={!!isLoading}
        wrapper={({ children: wrapperChildren }) => <Flex sx={{ opacity: 0 }}>{wrapperChildren}</Flex>}
      >
        <Trans id="advanced_settings.delete">Delete</Trans>
      </ConditionalWrapper>
      {isLoading && (
        <LoadingSpinnerCss
          sx={{ position: 'absolute' }}
          size={theme.buttons.sizes[size].fontSize || 2}
          color={theme.buttons[variant].color}
        />
      )}
    </LinkButton>
  );
};

LinkButtonWithLoading.defaultProps = defaultProps;
