export enum ErrorsDayReport {
  /// Status oznaczająy brakujący TimeEvent
  UncompleteStatus = 0,
  /// Błąd, wybrano wiecej nadgodzin niz jest dostępne
  OvertimeError = 1,
  /// Przypisany zły rozkład normowany do czasu nienormowanego
  WrongSchedule = 2,
  /// Czas pracy w trakcie urlopu
  WorkTimeDuringAbsence = 3,
  /// Urlop wykracza poza rozkład
  AbsenceExceedsSchedule = 4,
  /// Błąd, wybrano wiecej czasu pracy niz jest dostępne
  WorkTimeError = 5,
  /// Więcej niż jedna powiązana nieobecność w5/wś
  CorrelatedAbsenceDuplicate = 6,
  /// Więcej niż jeden urlop na dzień
  MultipleAbsencesError = 7,
  /// Automatyczna przerwa dłuższa niż czas pracy
  AutomaticBreakError = 8,
  /// Nadgodziny w dniu z urlopem
  OvertimeOnLeave = 9,
  /// Prośba o zmianę zdarzenia oczekuje na akcję
  PendingEventChangeRequest = 10,
  /// Urlop na dzień z grafikiem nadgodzinowym
  AbsenceWithOvertimeSchedule = 11,
  // Wiele grafików na dzień, jak na siebie nachodzą
  MultipleSchedulesError = 12,
  // Wiele businessTripów na jeden dzień
  MultipleBusinessTravels = 13,
  // Zdarzenie podejrzane o nadużycie
  TimeEventSpoofingAlert = 14,
  // Grafik nachodzi na dzień z brakiem dostępności
  ScheduleExceedsWorkdayAvailability = 15,
}

export enum DownloadExtensions {
  Pdf = 'pdf',
  Xlsx = 'xlsx',
  Csv = 'csv',
}

export type DayDetailsErrorDetails = {
  error: ErrorsDayReport;
  details?: {
    day: number;
    firstRequestId: string;
    firstRequestNumber: string;
    secondRequestId: string;
    secondRequestNumber: number;
  }[];
};
