import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UnitType } from 'dayjs';
import _ from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { ExtendedTimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { ShowFieldsButton } from 'components/recipes/ShowFieldsButton';
// eslint-disable-next-line no-restricted-imports
import { getSelectedDates, getSelectedTimes } from 'components/ui/DatePicker/Calendar/Calendar';
import { DatePicker } from 'components/ui/DatePicker/DatePicker';
import { WorkStatusSelect } from 'components/ui/Select/variants/WorkStatusSelect';
import { Textarea } from 'components/ui/Textarea';
import { TimePicker } from 'components/ui/TimePicker/TimePicker';
import { EDIT_EVENT_FORM_ID } from 'constants/clockLog';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { PersonCell } from 'pages/Reports/output/PersonCell';
import { editEventDisabledSaveAtom } from 'state/clockLog';
import { hashedEventTypeSelectorFamily, statusesOptionsSelectorFamily } from 'state/selectOptions';
import { getDateFromSeconds, getDateWithTimeInSecondsUnix } from 'utils/dateTime';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useEditEvent } from '../hooks/useEditEvent';
import { EditInputValues } from '../types';

type Props = {
  extTimeEvent: ExtendedTimeEvent;
  isPreview?: boolean;
};

const units: UnitType[] = ['hour', 'minutes', 'date', 'months', 'year'];

export const EditEventForm: FC<Props> = ({ extTimeEvent, isPreview = false }) => {
  useLingui();
  const setIsSaveDisabled = useSetRecoilState(editEventDisabledSaveAtom);

  const { timeUtc, note = '', typeId, isEnd, employeeName, role, tags, avatarUrl } = extTimeEvent;
  const [showAddNote, setShowAddNote] = useState(!!note);
  const { editTimeEvent, editTimeEventError } = useEditEvent({ prevTimeEvent: extTimeEvent, isPreview });

  const eventTypesOptions = useRecoilValue(statusesOptionsSelectorFamily(typeId));
  const hashedEventType = useRecoilValue(hashedEventTypeSelectorFamily({ typeId, isEnd }));
  const eventDate = `${getSelectedDates([timeUtc])?.[0]}` || '';
  const eventTime = `${getSelectedTimes([timeUtc])?.[0]}` || '';
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<EditInputValues>({
    defaultValues: {
      hashedEventType,
      eventDate,
      eventTime,
      note,
    },
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const [hashedTypeWatch, dateWatch, timeWatch, noteWatch] = watch([
    'hashedEventType',
    'eventDate',
    'eventTime',
    'note',
  ]);

  const isTimeEventUnchanged = useMemo(() => {
    const previousDate = getDateFromSeconds(getDateWithTimeInSecondsUnix(Number(eventDate), Number(eventTime), true));
    const currentDate = getDateFromSeconds(getDateWithTimeInSecondsUnix(Number(dateWatch), Number(timeWatch), true));
    return (
      _.isEqual(hashedEventType, hashedTypeWatch) &&
      units.every((unit) => _.isEqual(previousDate.get(unit), currentDate.get(unit))) &&
      _.isEqual(note, noteWatch)
    );
  }, [dateWatch, eventDate, eventTime, hashedEventType, hashedTypeWatch, note, noteWatch, timeWatch]);

  useEffect(() => setIsSaveDisabled(isTimeEventUnchanged), [isTimeEventUnchanged, setIsSaveDisabled]);

  return (
    <form
      id={EDIT_EVENT_FORM_ID}
      onSubmit={floatingPromiseReturn(handleSubmit(editTimeEvent, editTimeEventError))}
      noValidate
      style={{ width: '100%' }}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          flexDirection: ['column', null, 'row'],
          gap: '1.5rem',
        }}
      >
        <PersonCell
          name={employeeName}
          role={role}
          tags={tags}
          avatarUrl={avatarUrl}
          avatarSize={34}
          sx={{ width: 'auto' }}
        />

        <Flex sx={{ mt: [2, 0], flexWrap: ['wrap', 'nowrap'], flexShrink: 0 }}>
          <DatePicker
            label={t({ id: 'clock_log.date', message: 'Date' })}
            disabled={isPreview}
            {...register('eventDate', {
              validate: (curVal) => !!curVal || t({ id: 'date_picker.time_required' }),
            })}
            id="eventDate"
            size="sm"
            error={!!errors.eventDate}
            errorMessage={errors?.eventDate?.message}
          />
          <TimePicker
            label={t({ id: 'clock_log.time', message: 'Time' })}
            disabled={isPreview}
            {...register('eventTime', {
              validate: (curVal) => !!curVal || t({ id: 'date_picker.time_required' }),
            })}
            sx={{ mx: 2 }}
            id="eventTime"
            size="sm"
            error={!!errors.eventTime}
            errorMessage={errors?.eventTime?.message}
          />
          <WorkStatusSelect
            searchable
            label={t({ id: 'clock_log.event_type', message: 'Event Type' })}
            disabled={isPreview}
            {...register('hashedEventType', {
              validate: { valueExists: (value) => !!value },
            })}
            options={eventTypesOptions || []}
            id="hashedEventType"
            size="sm"
            sx={{ width: '10rem' }}
          />
        </Flex>
      </Flex>

      {!showAddNote && !isPreview && (
        <ShowFieldsButton
          label={t({ id: 'clock_log.add_note_optional', message: 'Add note (optional)' })}
          onClick={() => setShowAddNote(true)}
          sx={{ my: '0.75rem' }}
        />
      )}

      {showAddNote && (
        <Textarea
          label={t({ id: 'clock_log.note_optional', message: 'Note (optional)' })}
          disabled={isPreview}
          placeholder={t({ id: 'clock_log.enter_note', message: 'Enter your note...' })}
          maxLength={VALIDATION_RULES.TIME_EVENT_NOTE.maxLength}
          size="sm"
          id="note"
          variant="rounded"
          sx={{ mt: '0.75rem' }}
          {...register('note', validationFactory(VALIDATION_RULES.TIME_EVENT_NOTE))}
          counterProps={{
            control,
            name: 'note',
          }}
        />
      )}
    </form>
  );
};
