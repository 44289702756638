import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { Flex, Text } from 'theme-ui';

import { DateTimeKind } from 'api/actions/requests/requestsActions.types';
import { useRequestDetails } from '../../hooks/useRequestDetails';
import { RequestChange } from '../RequestChange/RequestChange';

import { DateDisplay } from './components/DateDisplay';
import { DateRangeDisplay } from './components/DateRangeDisplay';
import { Overtime } from './components/Overtime';
import { Participants } from './components/Participants';

export const BusinessCustomTimeOffDetails = (): React.ReactElement | null => {
  useLingui();
  const { requestDetailsDateDetails, parsedRequestDetails } = useRequestDetails();

  const isSameDay = useMemo(() => {
    if (!requestDetailsDateDetails) return false;

    const { dates, kind } = requestDetailsDateDetails;

    if (!dates) return true;

    const [startUnix, endUnix] = dates;

    if (!endUnix) return true;
    if (startUnix === endUnix && kind === DateTimeKind.Local) return true;

    return false;
  }, [requestDetailsDateDetails]);

  const requestDateRange = useMemo(() => {
    if (!requestDetailsDateDetails) return null;

    const { dates, kind } = requestDetailsDateDetails;

    if (!dates) return null;

    return { dates, isDateBound: kind === DateTimeKind.Local };
  }, [requestDetailsDateDetails]);

  const requestDateUnix = useMemo(() => {
    if (!requestDetailsDateDetails) return null;

    const { dates } = requestDetailsDateDetails;

    if (!dates) return null;

    const [startUnix] = dates;

    return startUnix;
  }, [requestDetailsDateDetails]);

  const requestForDay = useMemo(() => {
    if (!requestDetailsDateDetails) return null;

    const { forDay } = requestDetailsDateDetails;

    if (!forDay) return null;

    return forDay;
  }, [requestDetailsDateDetails]);

  const requestDuration = useMemo(() => {
    if (!requestDetailsDateDetails) return null;

    const { duration } = requestDetailsDateDetails;

    if (!duration) return null;

    return duration;
  }, [requestDetailsDateDetails]);

  const overtime = useMemo(() => {
    if (!requestDetailsDateDetails) return null;

    const { overtimeTaken } = requestDetailsDateDetails;

    if (_.isEmpty(overtimeTaken)) return null;

    return overtimeTaken;
  }, [requestDetailsDateDetails]);

  const requestPlace = useMemo(() => {
    if (!parsedRequestDetails?.newEvent) return null;

    const { eventDetails } = parsedRequestDetails.newEvent;

    if (!eventDetails.place) return null;

    return eventDetails.place;
  }, [parsedRequestDetails?.newEvent]);

  const requestParticipants = useMemo(() => {
    if (!parsedRequestDetails) return null;

    const participants = parsedRequestDetails.newEvent?.eventDetails?.participants;

    if (_.isEmpty(participants)) return null;

    return participants;
  }, [parsedRequestDetails]);

  const isEditedRequest = useMemo(() => {
    if (parsedRequestDetails?.originalEvent) return true;

    return false;
  }, [parsedRequestDetails?.originalEvent]);

  if (!requestDuration || !requestDateRange || !requestDateUnix) return null;

  return (
    <Flex sx={{ flexDirection: 'column', gap: 4 }}>
      <RequestChange />
      {!isEditedRequest && (
        <>
          {!isSameDay ? (
            <DateRangeDisplay dateDetails={requestDateRange} duration={requestDuration} />
          ) : (
            <DateDisplay dateUnix={requestDateUnix} duration={requestDuration} forDay={requestForDay} />
          )}
        </>
      )}
      {overtime && <Overtime overtime={overtime} />}
      {!isEditedRequest && requestPlace && (
        <Flex sx={{ flexDirection: 'column' }}>
          <Text
            sx={{
              fontWeight: 'bold',
              fontSize: 3,
            }}
          >
            <Trans id="request.details.section.place">Place</Trans>
          </Text>
          <Text sx={{ fontSize: 2 }}>{requestPlace}</Text>
        </Flex>
      )}
      {requestParticipants && <Participants participants={requestParticipants} />}
    </Flex>
  );
};
