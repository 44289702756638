import React from 'react';
import { Box, Heading, ThemeUIStyleObject, Text } from 'theme-ui';

type HeaderProps = {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  sx?: ThemeUIStyleObject;
};

type HeaderComponent = React.FunctionComponent<HeaderProps> & {
  Title: TitleComponent;
  Lead: LeadComponent;
};

export const Header: HeaderComponent = ({ children, sx, ...props }: HeaderProps): React.ReactElement => (
  <Box as="header" {...props} sx={{ p: 3, ...(sx && sx) }}>
    {children}
  </Box>
);

Header.defaultProps = {
  sx: undefined,
};

type TitleProps = {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  sx?: ThemeUIStyleObject;
};

type TitleComponent = React.FunctionComponent<TitleProps>;

const Title: TitleComponent = ({ children, sx, ...props }: TitleProps): React.ReactElement => (
  <Heading
    as="h1"
    variant="heading1"
    {...props}
    sx={{
      mb: 5,
      ...(sx && sx),
    }}
  >
    {children}
  </Heading>
);

Title.defaultProps = {
  sx: undefined,
};

type LeadProps = {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  sx?: ThemeUIStyleObject;
};

type LeadComponent = React.FunctionComponent<LeadProps>;

const Lead: LeadComponent = ({ children, sx, ...props }: LeadProps): React.ReactElement => (
  <Text
    as="p"
    variant="pLead"
    {...props}
    sx={{
      mb: 3,
      fontWeight: 'bold',
      ...(sx && sx),
    }}
  >
    {children}
  </Text>
);

Lead.defaultProps = {
  sx: undefined,
};

Header.Title = Title;
Header.Lead = Lead;
