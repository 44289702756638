import _ from 'lodash';
import React, { useCallback } from 'react';
import { Flex, Text } from 'theme-ui';

import { ListNames } from 'components/StickyList/types';
import { OVERTIME_FREE_DAY_ID, OVER_TIME_PAYMENT_ID } from 'constants/requests';
import { getDurationObj } from '../../../../utils/getDurationObj';
import { useAddRequest } from '../hooks/useAddRequest';
import { useAddRequestLogic } from '../hooks/useAddRequestLogic';

import { SelectedPersonsList } from './SelectedPersonsList';

export const SelectedPersons = (): React.ReactElement => {
  const {
    requestLimits: { requestLimits },
  } = useAddRequest();

  const { preparePeriod } = useAddRequestLogic();

  const limitsRenderer = useCallback(
    (employeeId: string) => {
      if (!requestLimits || !requestLimits.peopleLimits) return null;

      const employeeLimit = requestLimits.peopleLimits.find(({ personId }) => personId === employeeId);

      if (!employeeLimit) return null;

      const { duration, period } = employeeLimit;
      const { hours, minutes, days } = getDurationObj(duration);

      if (!_.isNumber(duration)) return null;

      const durationRenderer = (() => {
        if (!days && !hours && !minutes) {
          const displayZeroLimitAsHours =
            requestLimits.typeId === OVERTIME_FREE_DAY_ID || requestLimits.typeId === OVER_TIME_PAYMENT_ID;
          return <Text>{displayZeroLimitAsHours ? '0h' : '0d'}</Text>;
        }

        return (
          <>
            {days > 0 && <Text>{days}d</Text>} {hours > 0 && <Text>{hours}h</Text>}{' '}
            {minutes > 0 && <Text>{minutes}m</Text>}
          </>
        );
      })();

      return (
        <Flex variant="fancyDataBox.container" sx={{ fontSize: 1, gap: '0.375rem' }}>
          <Flex variant="fancyDataBox.time">{durationRenderer}</Flex>
          {_.isNumber(period) && <Text sx={{ alignSelf: 'center' }}>{preparePeriod(period)}</Text>}
        </Flex>
      );
    },
    [preparePeriod, requestLimits],
  );

  return <SelectedPersonsList listName={ListNames.REQUESTS_PICK_TEAMMATES} appendWithRenderer={limitsRenderer} />;
};
