import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Heading } from 'theme-ui';

import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import { integrationsMapSelector } from 'state/settings';

import { WebhooksTile } from './components/WebhooksTile';

export const IntegrateWithUs = (): React.ReactElement => {
  useLingui();
  const integrationsMap = useRecoilValue(integrationsMapSelector);

  const webhooks = useMemo(() => integrationsMap.get(ServiceIntegration.Webhook), [integrationsMap]);

  if (!webhooks) return <></>;

  return (
    <>
      <Heading variant="heading4" mb={2}>
        <Trans id="settings.integrations.integrate_with_us">Integrate with us</Trans>
      </Heading>
      <Flex sx={{ flexWrap: 'wrap', gap: 2 }}>
        <WebhooksTile integration={webhooks} />
      </Flex>
    </>
  );
};
