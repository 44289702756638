import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { OverlayContainer } from 'Kiosk/Layout';
import { SummaryOverlyState } from 'Kiosk/state/overlayState';
import { selectedTimeEventSelector, showChangeTimeEventSelector } from 'Kiosk/state/summaryState';
import { settingsAtom } from 'Kiosk/state/settingsState';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';

import { SummaryContainer } from './SummaryContainer';
import { ChangeTimeEvent } from './ChangeTimeEvent';

type Props = SummaryOverlyState['state'];

export const Summary = ({ getInfoPayload, qrCode, eventImage }: Props): React.ReactElement => {
  const showChangeStatus = useRecoilValue(showChangeTimeEventSelector);
  const settings = useRecoilValue(settingsAtom);
  const [, setSelectedTimeEvent] = useRecoilState(selectedTimeEventSelector);

  useEffect(
    () => () => {
      setSelectedTimeEvent(undefined);
    },
    [setSelectedTimeEvent],
  );

  return (
    <OverlayContainer blurBackground>
      {!settings && <LoadingSpinnerCss />}
      {!showChangeStatus && settings ? (
        <SummaryContainer qrCode={qrCode} getInfoPayload={getInfoPayload} eventImage={eventImage} settings={settings} />
      ) : (
        <ChangeTimeEvent getInfoPayload={getInfoPayload} />
      )}
    </OverlayContainer>
  );
};
