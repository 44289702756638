import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { MenuButton } from './MenuButton';

type MenuProps = FlexProps;

type MenuComponent = React.FC<MenuProps> & {
  Button: typeof MenuButton;
};

export const Menu: MenuComponent = ({ children, sx, ...props }: MenuProps): React.ReactElement => (
  <Flex as="nav" {...props} variant="layouts.authorized.aside.details.headerMenu" sx={{ ...(sx && sx) }}>
    {children}
  </Flex>
);

Menu.Button = MenuButton;
