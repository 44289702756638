export const ENTRY_TIME_EVENT_ID = '1840417b-98b8-483f-9c60-92eacaa30075';
export const EXIT_TIME_EVENT_ID = '421db62e-4de7-450e-8841-095f197a0148';

export const FACE_BOUNDING_THRESHOLD = 90;
export const FACE_BOUNDING_THRESHOLD_AXIS_X = FACE_BOUNDING_THRESHOLD;
export const FACE_BOUNDING_THRESHOLD_AXIS_Y = FACE_BOUNDING_THRESHOLD_AXIS_X * 1.6;

export const INITIAL_FACE_SCAN_POINTS_NUMBER = 12;

export const GEOLOCATION_INTERVAL = 5000;
export const PERMISSION_OBSERVER_INTERVAL = 2500;

export const DEFAULT_FPS = 10;

export const FACE_NUM_KEYPOINTS = 6;
export const FACE_NUM_SNAPSHOTS = 5;
export const FACE_IN_POSITION_REQ_FRAMES = 10;
export const FACE_SNAPSHOT_DELAY = 1000;
export const FACE_CONFIDENCE_SCORE = 0.8;

export const LIVELINESS_POSITIVE_FRAME_FOR_SCAN = 30;
export const LIVELINESS_FRAME_COLLECT = 10;

export const PITCH_DEVIATION_THRESHOLD = 40;
export const PITCH_THRESHOLD = 120;
export const PITCH_MEAN_THRESHOLD = 100;
export const YAW_DEVIATION_THRESHOLD = 40;
export const YAW_THRESHOLD = 120;
export const YAW_MEAN_THRESHOLD = 60;
export const ROLL_DIFF_THRESHOLD = 20;
export const ROLL_DEVIATION_THRESHOLD = 12;
export const ROLL_MEAN_THRESHOLD = 15;
