import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  FetchRoleDetailsActionProps,
  FetchRoleDetailsResponse,
  EditRoleDetailsActionProps,
  EditRoleDetailsResponse,
  AddRoleResponse,
  AddRoleActionProps,
  DeleteRolesActionProps,
  DeleteRolesResponse,
} from './rolesActions.types';

export function fetchRoleDetailsAction({ roleId }: FetchRoleDetailsActionProps): Action<FetchRoleDetailsResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.role}/${roleId}`,
  };
}

export function editRoleDetailsAction({ roleId, role }: EditRoleDetailsActionProps): Action<EditRoleDetailsResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.role}/${roleId}`,
    body: role,
  };
}

export function addRoleAction(body: AddRoleActionProps): Action<AddRoleResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.role,
    body,
  };
}

export function deleteRolesAction(body: DeleteRolesActionProps): Action<DeleteRolesResponse> {
  return {
    method: 'DELETE',
    endpoint: API_ENDPOINTS.role,
    body,
  };
}
