import _ from 'lodash';
import { RegisterOptions } from 'react-hook-form/dist/types';

type Options = {
  additionalRegisterOptions?: RegisterOptions;
  customDatesCheck?: (startDateUnix: number, endDateUnix: number) => number[] | undefined;
  isRange: boolean;
};

const singleDaySetValueAs = (date: string | number) => {
  if (!date || _.isNaN(+date)) return undefined;

  return +date;
};

const rangeSetValueAs = (customDatesCheck: Options['customDatesCheck']) => (dates: string | number[]) => {
  if (!dates || _.isEmpty(dates)) return undefined;

  let datesUnix: number[] = [];

  if (_.isArray(dates)) {
    datesUnix = dates;
  } else {
    datesUnix = dates.split(',').map((date) => parseInt(date, 10));
  }

  const [startDateUnix, endDateUnix] = datesUnix;
  if (customDatesCheck) {
    return customDatesCheck(startDateUnix, endDateUnix);
  }

  if (!startDateUnix || !endDateUnix || _.isNaN(startDateUnix) || _.isNaN(endDateUnix)) return undefined;

  return datesUnix;
};

export const dualCalendarRegisterOptionsFactory = ({
  additionalRegisterOptions,
  customDatesCheck,
  isRange,
}: Options): RegisterOptions => ({
  setValueAs: isRange ? rangeSetValueAs(customDatesCheck) : singleDaySetValueAs,
  ...additionalRegisterOptions,
});
