/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNan from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import toNumber from 'lodash/toNumber';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex, Label } from 'theme-ui';

import { ScheduleActionData } from 'api/actions/calendar/calendarActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { ColorPicker } from 'components/ui/ColorPicker';
import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { RadioButton } from 'components/ui/RadioButton/RadioButton';
import { RadioButtonGroup } from 'components/ui/RadioButton/RadioButtonGroup';
import { Select } from 'components/ui/Select/Select';
import { Switch } from 'components/ui/Switch';
import { TextInput } from 'components/ui/TextInput';
import { TimePicker } from 'components/ui/TimePicker/TimePicker';
import { ScheduleType } from 'constants/calendar';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { useMount } from 'hooks/useMount/useMount';
import { getScheduleFormValuesAtom } from 'state/calendar';
import { workPositionsSelectOptionsSelector } from 'state/team';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

import { DefaultScheduleFormValues } from './types';

type Props = {
  defaultValues?: DefaultScheduleFormValues;
  onSubmit: (props: ScheduleActionData) => Promise<boolean>;
  setLoading?: (loading: boolean) => void;
};

export type ScheduleFormProps = Props;

const getDefaultValues = (defaultValues?: Props['defaultValues']): Props['defaultValues'] => ({
  workPositionId: defaultValues?.workPositionId || undefined,
  note: defaultValues?.note || undefined,
  ...(isNumber(defaultValues?.aiVariant) && {
    isAi: true,
  }),
  details: {
    ...defaultValues?.details,
    startDateTimeUnix: !isUndefined(defaultValues?.details?.startDateTimeUnix)
      ? defaultValues?.details?.startDateTimeUnix
      : undefined,
    endDateTimeUnix: !isUndefined(defaultValues?.details?.endDateTimeUnix)
      ? defaultValues?.details?.endDateTimeUnix
      : undefined,
    color: defaultValues?.details?.color || 0,
    type: defaultValues?.details?.type || ScheduleType.FixedStartTime,
  },
  addDefaultWorkPosition: defaultValues?.addDefaultWorkPosition || undefined,
});

const guardFormValues = (formValues: ScheduleActionData): ScheduleActionData => {
  const { details } = formValues;
  const { type, addAutomaticBreak, isOvertimeSchedule, endDateTimeUnix, startDateTimeUnix } = details;

  return {
    ...formValues,
    workPositionId: formValues.workPositionId || undefined,
    details: {
      ...formValues.details,
      ...(+type === ScheduleType.NotStandardized && {
        type: ScheduleType.NotStandardized,
        countOvertimeBeforeWork: undefined,
        endDateTimeUnix: undefined,
        startDateTimeUnix: undefined,
      }),
      ...(+type === ScheduleType.FixedStartTime && {
        type: ScheduleType.FixedStartTime,
        workTimeUnix: undefined,
        endDateTimeUnix: !isUndefined(endDateTimeUnix) ? +endDateTimeUnix : undefined,
        startDateTimeUnix: !isUndefined(startDateTimeUnix) ? +startDateTimeUnix : undefined,
      }),
      ...(isOvertimeSchedule && {
        countOvertimeAfterWork: undefined,
        countOvertimeBeforeWork: undefined,
      }),
      ...(!addAutomaticBreak && {
        automaticBreakDurationUnix: undefined,
      }),
    },
  };
};

export const ScheduleForm = forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, setLoading, defaultValues }: Props, ref) => {
    const workPositionsSelectOptions = useRecoilValue(workPositionsSelectOptionsSelector);
    const setGetScheduleFormValues = useSetRecoilState(getScheduleFormValuesAtom);
    const resetGetScheduleFormValues = useResetRecoilState(getScheduleFormValuesAtom);

    const memoDefValues = useMemo(() => getDefaultValues(defaultValues), [defaultValues]);

    useLingui();

    const { watch, register, handleSubmit, formState, getValues } = useForm<ScheduleActionData>({
      mode: 'onTouched',
      reValidateMode: 'onChange',
      defaultValues: memoDefValues,
    });

    const { errors } = formState;
    const currentFormValues = watch();

    const handleSubmitCallback: SubmitHandler<ScheduleActionData> = useCallback(
      async (body) => {
        const formValues = guardFormValues(body);
        await onSubmit(formValues);
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      if (setLoading) setLoading(false);
    }, [setLoading]);

    useMount(() => {
      setGetScheduleFormValues(() => getValues);
    });

    useEffect(
      () => () => {
        resetGetScheduleFormValues();
      },
      [resetGetScheduleFormValues],
    );

    return (
      <form
        ref={ref}
        onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))}
        noValidate
      >
        <Flex
          sx={{
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <Flex sx={{ flexDirection: 'column' }}>
            <Modal.SubTitle>
              <Trans id="schedule.type">Schedule type</Trans>
            </Modal.SubTitle>
            <RadioButtonGroup
              {...register('details.type', { valueAsNumber: true, setValueAs: (v: string) => parseInt(v, 10) })}
              orientation="row"
              gap="4px"
              columns={2}
            >
              <RadioButton
                value={ScheduleType.FixedStartTime}
                defaultChecked={toNumber(currentFormValues.details?.type) === ScheduleType.FixedStartTime}
              >
                <Trans id="schedule.type.fixed">Fixed</Trans>
              </RadioButton>
              <RadioButton
                value={ScheduleType.NotStandardized}
                defaultChecked={toNumber(currentFormValues.details?.type) === ScheduleType.NotStandardized}
              >
                <Trans id="schedule.type.flexible">Flexible</Trans>
              </RadioButton>
            </RadioButtonGroup>
          </Flex>

          <Flex sx={{ flexDirection: 'column' }}>
            <Modal.SubTitle>
              <Trans id="schedule.duration">Duration</Trans>
              {toNumber(currentFormValues.details?.type) === ScheduleType.NotStandardized && (
                <>
                  {' '}
                  <Trans id="schedule.optional">(optional)</Trans>
                </>
              )}
            </Modal.SubTitle>
            {toNumber(currentFormValues.details?.type) === ScheduleType.FixedStartTime ? (
              <Flex sx={{ flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                <TimePicker
                  id="details.startDateTimeUnix"
                  size="sm"
                  variant="rounded"
                  error={!!errors.details?.startDateTimeUnix}
                  errorMessage={errors?.details?.startDateTimeUnix?.message}
                  {...register('details.startDateTimeUnix', {
                    required: t({ id: 'date_picker.time_required' }),
                    setValueAs: (v) => {
                      if (!v || isNan(v)) return undefined;

                      return +v;
                    },
                  })}
                />
                <Icon type="arrowRight" size={16} fill="texts.lighter" tabIndex={-1} />
                <TimePicker
                  id="details.endDateTimeUnix"
                  size="sm"
                  variant="rounded"
                  error={!!errors.details?.endDateTimeUnix}
                  errorMessage={errors?.details?.endDateTimeUnix?.message}
                  {...register('details.endDateTimeUnix', {
                    required: t({ id: 'date_picker.time_required' }),
                    setValueAs: (v) => {
                      if (!v || isNan(v)) return undefined;

                      return +v;
                    },
                  })}
                />
              </Flex>
            ) : (
              <DurationPicker
                id="details.workTimeUnix"
                seconds={false}
                size="sm"
                variant="rounded"
                error={!!errors.details?.workTimeUnix}
                errorMessage={errors?.details?.workTimeUnix?.message}
                minDuration={1 * 60}
                maxDuration={24 * 60 * 60 - 1}
                {...register('details.workTimeUnix', {
                  valueAsNumber: true,
                })}
              />
            )}
          </Flex>

          <Flex sx={{ flexDirection: 'column' }}>
            <Modal.SubTitle>{t({ id: 'team.add_edit_tag.color' })}</Modal.SubTitle>
            <ColorPicker colorScheme="schedules" {...register('details.color', { valueAsNumber: true })} />
          </Flex>

          <Flex sx={{ flexDirection: 'column' }}>
            <Modal.SubTitle>
              <Trans id="schedule.additional_options">Additional options</Trans>
            </Modal.SubTitle>
            <Flex sx={{ gap: 2, flexDirection: 'column' }}>
              {workPositionsSelectOptions && workPositionsSelectOptions.length > 0 && (
                <Switch
                  bold={false}
                  label={t({
                    id: 'schedule.add_default_work_position',
                    message: "Add employee's default work position",
                  })}
                  {...register('addDefaultWorkPosition')}
                />
              )}

              <Switch
                label={t({ id: 'schedule.overtime_schedule', message: 'Overtime schedule' })}
                additionalInfo={t({
                  id: 'schedule.overtime_schedule_info',
                  message: 'Work time will be counted as overtime',
                })}
                {...register('details.isOvertimeSchedule')}
              />
              {!currentFormValues.details?.isOvertimeSchedule &&
                (currentFormValues.details?.workTimeUnix ||
                  toNumber(currentFormValues.details?.type) === ScheduleType.FixedStartTime) && (
                  <>
                    {toNumber(currentFormValues.details?.type) === ScheduleType.FixedStartTime && (
                      <Switch
                        bold={false}
                        label={t({ id: 'schedule.count_overtime_before', message: 'Count overtime before schedule' })}
                        {...register('details.countOvertimeBeforeWork')}
                      />
                    )}
                    <Switch
                      label={t({ id: 'schedule.count_overtime_after', message: 'Count overtime after schedule' })}
                      {...register('details.countOvertimeAfterWork')}
                    />
                  </>
                )}
              <Switch
                label={t({ id: 'schedule.automatic_break', message: 'Add automatic break' })}
                {...register('details.addAutomaticBreak')}
              />
              {currentFormValues.details?.addAutomaticBreak && (
                <Flex sx={{ pl: 2, gap: 2, flexDirection: 'column' }}>
                  <Switch
                    label={t({ id: 'schedule.automatic_break_unpaid', message: 'Count as unpaid break' })}
                    additionalInfo={t({
                      id: 'schedule.automatic_break_unpaid_info',
                      message: 'Will be subtracted from the work time',
                    })}
                    {...register('details.subtractAutomaticBreak')}
                  />
                  <Flex sx={{ alignItems: 'center' }}>
                    <Label htmlFor="details.automaticBreakDurationUnix">
                      <Trans id="schedule.automatic_break_duration">Automatic break duration</Trans>
                    </Label>
                    <DurationPicker
                      id="details.automaticBreakDurationUnix"
                      seconds={false}
                      hours={false}
                      size="sm"
                      variant="rounded"
                      inputVariant="minutes"
                      extended
                      error={!!errors.details?.automaticBreakDurationUnix}
                      errorMessage={errors?.details?.automaticBreakDurationUnix?.message}
                      {...register('details.automaticBreakDurationUnix', {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>

          {workPositionsSelectOptions &&
            workPositionsSelectOptions.length > 0 &&
            !currentFormValues.addDefaultWorkPosition && (
              <Flex sx={{ flexDirection: 'column' }}>
                <Modal.SubTitle>
                  <Trans id="schedule.work_position">Work position</Trans>
                </Modal.SubTitle>
                <Select
                  size="sm"
                  id="workPositionId"
                  placeholder={t({ id: 'schedule.select_work_position', message: 'Select a work position' })}
                  error={!!errors.workPositionId}
                  errorMessage={errors?.workPositionId?.message}
                  searchable
                  options={workPositionsSelectOptions || []}
                  clearable
                  {...register('workPositionId')}
                />
              </Flex>
            )}

          <Flex sx={{ flexDirection: 'column' }}>
            <Modal.SubTitle>{t({ id: 'add_request.note' })}</Modal.SubTitle>
            <TextInput
              inputProps={{
                as: 'textarea',
              }}
              size="sm"
              id="note"
              variant="rounded"
              error={!!errors.note}
              errorMessage={errors?.note?.message}
              {...register('note', validationFactory({ ...VALIDATION_RULES.SCHEDULE_NOTE }))}
            />
          </Flex>
        </Flex>
      </form>
    );
  },
);
