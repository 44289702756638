import { useRecoilState, useRecoilValue } from 'recoil';
import { useState } from 'react';

import { filteredLocationsManageModalSelector } from 'state/clockLog';
import { filteredLocationsSelector, locationsAtom } from 'state/locations';
import { useMount } from 'hooks/useMount/useMount';

import { useFetchLocations } from './useFetchLocations';

let locationsInitialized = false;

export const useLocations = (alwaysInitializeOnMount?: boolean) => {
  const [fetchingInProgress, setFetchingInProgress] = useState(false);
  const [locations, setLocations] = useRecoilState(locationsAtom);
  const filteredLocations = useRecoilValue(filteredLocationsSelector);
  const filteredLocationsManageModal = useRecoilValue(filteredLocationsManageModalSelector);

  const fetchLocations = useFetchLocations();

  useMount(async () => {
    if ((!locations && !locationsInitialized) || alwaysInitializeOnMount) {
      setFetchingInProgress(true);
      await fetchLocations();
      setFetchingInProgress(false);
      locationsInitialized = true;
    }
  });

  return {
    locations,
    setLocations,
    filteredLocations,
    filteredLocationsManageModal,
    fetchLocations,
    fetchingInProgress,
  };
};
