import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Face } from '@tensorflow-models/face-detection';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ClientContext } from 'react-fetching-library';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Box, Text, ThemeUIStyleObject } from 'theme-ui';

import { initPersonModel } from 'api/actions/timeclock/timeclockActions';
import { Button } from 'components/ui/Buttons';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { FACE_BOUNDING_THRESHOLD, FACE_NUM_SNAPSHOTS, FACE_SNAPSHOT_DELAY } from 'Kiosk/constants/constants';
import { useAnimationFrame } from 'Kiosk/hooks/useAnimationFrame';
import { useCameraImageBuffer } from 'Kiosk/hooks/useCameraImageBuffer';
import { Footer } from 'Kiosk/Layout';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import {
  initialFaceScanInPlaceAtom,
  initialFaceScanStepAtom,
  InitialFaceScanSteps,
} from 'Kiosk/state/initialFaceScanState';
import { KioskOverlay, overlayStateAtom } from 'Kiosk/state/overlayState';
import { windowSizeAtom } from 'state/recoilState';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { FaceBoundingBackground } from '../../../components/FaceBoundingBackground';
import { FaceBoundingMask } from '../../../components/FaceBoundingMask';
import { useFaceDetection } from '../../../hooks/useFaceLandmarkModel';
import { useInitialFaceScan } from '../../../hooks/useInitialFaceScan';

const MemorizedFaceBoundingBackground = React.memo(FaceBoundingBackground);
const MemorizedFaceBoundingMask = React.memo(FaceBoundingMask);

const elementsBoxSx: ThemeUIStyleObject = {
  position: 'relative',
  width: '100%',
  height: '100%',
  zIndex: 1,
};

const footerSx: ThemeUIStyleObject = {
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 2,
  textAlign: 'center',
  textShadow: 'kiosk',
};

type Props = {
  qrCode: string;
};

export const StepScan = ({ qrCode }: Props): React.ReactElement => {
  useLingui();
  const [snapshots, setSnapshots] = useState<Blob[]>([]);
  const [predictionWithFrame, setPredictionWithFrame] = useState<{
    frameBlob: Blob;
    faces: Face[];
  }>();

  const { query } = useContext(ClientContext);

  const { isMobile, isLandscape, height, width } = useRecoilValue(windowSizeAtom);

  const { source } = useRecoilValue(cameraStateAtom);
  const setFaceInPlace = useSetRecoilState(initialFaceScanInPlaceAtom);
  const setStep = useSetRecoilState(initialFaceScanStepAtom);
  const setOverlay = useSetRecoilState(overlayStateAtom);

  const { getPrediction, faceBounding } = useFaceDetection();
  const { getImageData, getImageBlob } = useCameraImageBuffer();

  const snapshotsIntervalIdRef = useRef<number | undefined>(undefined);

  // 1. Scan for face and set predictions
  const scanForFace = async () => {
    const { imageData: frame } = (getImageData && (await getImageData())) || {};
    const frameBlob = getImageBlob && (await getImageBlob());

    if (frame && frameBlob && getPrediction) {
      const modelPrediction = await getPrediction(frame);
      setPredictionWithFrame({
        frameBlob,
        faces: modelPrediction,
      });
    }
  };

  const { isAnimationRunning, stopAnimation } = useAnimationFrame({
    callback: floatingPromiseReturn(scanForFace),
    autostart: true,
  });

  const { faceInPlace } = useInitialFaceScan({
    source,
    faces: predictionWithFrame?.faces,
  });

  useEffect(() => {
    setFaceInPlace(faceInPlace);

    return () => {
      setFaceInPlace(false);
    };
  }, [faceInPlace, setFaceInPlace]);

  const sendImagesToServer = useCallback(async () => {
    if (isAnimationRunning) {
      stopAnimation();
    }

    const { payload, error } = await query(initPersonModel({ qrCode, images: snapshots, useFaceDetection: true }));

    if (!error && payload) {
      await delay(500);
      setStep(InitialFaceScanSteps.SUMMARY);
    }

    if (error && payload) {
      setOverlay({ type: KioskOverlay.start });
      setStep(InitialFaceScanSteps.START);
      setSnapshots([]);
    }
  }, [isAnimationRunning, qrCode, query, setOverlay, setStep, snapshots, stopAnimation]);

  const sendImagesToServerRef = useCallbackRef(sendImagesToServer);
  // Ref od faceInPlace używany do poprawnego określenia czy po delay nadal mamy twarz w polu.
  const faceInPlaceRef = useCallbackRef(faceInPlace);
  const snapshotsRef = useCallbackRef(snapshots);

  const startIntervalSnapshots = useCallback(() => {
    const takeSnapshot = async () => {
      if (snapshotsRef.current.length >= FACE_NUM_SNAPSHOTS) {
        clearInterval(snapshotsIntervalIdRef.current);
        void sendImagesToServerRef.current();
        return;
      }
      if (faceInPlaceRef.current) {
        const frameBlob = getImageBlob && (await getImageBlob());
        if (frameBlob) {
          setSnapshots((snaps) => [...snaps, frameBlob]);
        }
      }
    };

    snapshotsIntervalIdRef.current = window.setInterval(() => {
      void takeSnapshot();
    }, FACE_SNAPSHOT_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (faceInPlace) {
      if (snapshotsRef.current.length < FACE_NUM_SNAPSHOTS) {
        startIntervalSnapshots();
      }
    } else {
      clearInterval(snapshotsIntervalIdRef.current);
      setSnapshots([]);
    }
  }, [faceInPlace, sendImagesToServerRef, snapshotsRef, startIntervalSnapshots]);

  useEffect(
    () => () => {
      clearInterval(snapshotsIntervalIdRef.current);
    },
    [],
  );

  const avoidTxtAndFaceBoundingMaskOverlap = isLandscape && height && height < 700;

  const webcamDisplayAspectRatio = useMemo(
    () => (source && (isLandscape ? height && height / source.height : width && width / source.width)) || 0,
    [height, isLandscape, source, width],
  );

  const getFaceBoundingData = useCallback(() => {
    if (faceBounding) {
      const mobileThresholdMultiplier = avoidTxtAndFaceBoundingMaskOverlap ? 0.1 : 0.5;
      const size = isLandscape
        ? faceBounding.height * webcamDisplayAspectRatio
        : faceBounding.width * webcamDisplayAspectRatio;
      const sizeThreshold =
        (isMobile ? FACE_BOUNDING_THRESHOLD * mobileThresholdMultiplier : FACE_BOUNDING_THRESHOLD) *
        webcamDisplayAspectRatio;
      const thresholdAspectRatio =
        (FACE_BOUNDING_THRESHOLD / (isLandscape ? faceBounding.height : faceBounding.width)) * webcamDisplayAspectRatio;

      return {
        size,
        sizeThreshold,
        thresholdAspectRatio,
      };
    }

    return null;
  }, [faceBounding, isLandscape, isMobile, webcamDisplayAspectRatio, avoidTxtAndFaceBoundingMaskOverlap]);
  const faceBoundingData = getFaceBoundingData();

  const defaultMinSize = avoidTxtAndFaceBoundingMaskOverlap ? 200 : 280;

  const faceBoundingCircleSize = useMemo(
    () => (faceBoundingData ? Math.max(faceBoundingData.size + faceBoundingData.sizeThreshold, defaultMinSize) : 0),
    [faceBoundingData, defaultMinSize],
  );

  const faceBoundingMaskSize = useMemo(
    () => (faceBoundingData ? Math.max(faceBoundingData.size + faceBoundingData.sizeThreshold, defaultMinSize) : 0),
    [faceBoundingData, defaultMinSize],
  );

  const cancelScan = () => {
    setOverlay({ type: KioskOverlay.start });
    setStep(InitialFaceScanSteps.START);
  };

  const footerTextRender = useMemo(() => {
    const snapshotsLenght = snapshots.length;

    if (!faceInPlace || snapshotsLenght === 0) {
      return (
        <Trans id="kiosk.initial_face_scan.face_not_in_place">
          Position yourself so that your face is fully visible in the circle
        </Trans>
      );
    }

    if (snapshotsLenght === FACE_NUM_SNAPSHOTS)
      return <Trans id="kiosk.initial_face_scan.waiting_for_model">Waiting for model to be created...</Trans>;

    const stayStillCount = (() => {
      if (snapshotsLenght === 1) return 3;
      if (snapshotsLenght === 2) return 2;
      if (snapshotsLenght === 3) return 1;
      return 0;
    })();

    return <Trans id="kiosk.initial_face_scan.stay_still">Stay still {stayStillCount}</Trans>;
  }, [faceInPlace, snapshots]);

  return (
    <>
      <Box sx={elementsBoxSx}>
        <MemorizedFaceBoundingBackground
          size={faceBoundingCircleSize}
          faceInPlace={faceInPlace}
          whiteBg={snapshots.length === 4}
        />
        <MemorizedFaceBoundingMask size={faceBoundingMaskSize} faceInPlace={faceInPlace} />
      </Box>

      <Footer
        sx={{
          ...footerSx,
          ...(avoidTxtAndFaceBoundingMaskOverlap && {
            pb: [1, 2],
            '& h2': {
              fontSize: [4, null, 5],
            },
          }),
        }}
      >
        <Text as="h2" color="white" sx={{ fontSize: [4, null, 6] }}>
          {footerTextRender}
        </Text>

        <Button
          onClick={cancelScan}
          bgOverwrite={{
            default: 'kiosk.faceScan.btn.bg.default',
            hover: 'kiosk.faceScan.btn.bg.hover',
            tap: 'kiosk.faceScan.btn.bg.tap',
            disabled: 'kiosk.faceScan.btn.bg.disabled',
          }}
          variant="primary"
          size="lg"
          {...(avoidTxtAndFaceBoundingMaskOverlap && {
            sx: { p: 2 },
          })}
          type="button"
        >
          {t({ id: 'global.forms.buttons.cancel' })}
        </Button>
      </Footer>
    </>
  );
};
