import React from 'react';
import { Flex, FlexOwnProps } from 'theme-ui';

type Props = Omit<FlexOwnProps, 'color'> & { children: React.ReactNode };

export const Footer = React.forwardRef<HTMLDivElement, Props>(({ sx, children, ...props }: Props, ref) => (
  <Flex
    as="footer"
    ref={ref}
    {...props}
    sx={{
      flexDirection: 'column',
      p: 3,
      ...(sx && sx),
    }}
  >
    {children}
  </Flex>
));
