import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, FlexProps, Heading, HeadingProps } from 'theme-ui';

import { DRAWER_ROUTES, modalRoutes } from 'constants/memoryRoutes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useIsMountedRef } from 'hooks/useIsMountedRef/useIsMountedRef';
import { useOnOutsideClick } from 'hooks/useOnOutsideClick/useOnOutsideClick';
import { usePromisify } from 'hooks/usePromisify/usePromisify';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { actionHasBeenSentAtom } from 'state/drawer';

import { DrawerContext, DrawerContextProps } from './DrawerContext';

const AnimatedFlex = motion(Flex);

type Props = {
  drawerRef: React.RefObject<HTMLDivElement>;
  globalNavigate: DrawerContextProps['globalNavigate'];
  children: React.ReactElement | React.ReactElement[];
};

type DrawerComponent = React.FC<Props> & {
  Header: typeof Header;
  Title: typeof Title;
  SubTitle: typeof SubTitle;
  Container: typeof Container;
};

export const Drawer: DrawerComponent = ({ drawerRef, globalNavigate, children }: Props): React.ReactElement => {
  const timeEventActionHasBeenSent = useRecoilValue(actionHasBeenSentAtom);
  const [promisifyCloseDrawer, resolveCloseDrawer] = usePromisify<null>();
  const isMountedRef = useIsMountedRef();

  const [isVisible, setIsVisible] = useState(true);

  const navigate = useAppNavigate();
  const location = useLocation();
  const { addSnackbar } = useSnackbar();
  const { isMobileBreakpoint } = useThemeBreakpoint();

  const scrollParentRef = useRef<HTMLDivElement | null>(null);

  const locationPathnameRef = useCallbackRef(location.pathname);

  const closeDrawer = useCallback(async () => {
    setIsVisible(false);
    await promisifyCloseDrawer(200);
    if (!isMountedRef.current) return;
    navigate(DRAWER_ROUTES.CLOSED);
  }, [promisifyCloseDrawer, navigate, isMountedRef]);

  const onOutsideClick = useCallback(() => {
    if (!_.find(modalRoutes, (r) => _.isEqual(r, location.pathname))) void closeDrawer();
    if (!timeEventActionHasBeenSent && location.pathname === DRAWER_ROUTES.DRAWER__TIME_TRACKER)
      addSnackbar({
        message: i18n._(
          t({
            id: 'time_tracker.no_event',
          }),
        ),
      });
  }, [timeEventActionHasBeenSent, addSnackbar, closeDrawer, location.pathname]);

  useOnOutsideClick(drawerRef, onOutsideClick);

  const closeDrawerRef = useCallbackRef(closeDrawer);

  useEffect(() => {
    if (timeEventActionHasBeenSent && locationPathnameRef.current === DRAWER_ROUTES.DRAWER__TIME_TRACKER) {
      void closeDrawerRef.current();
    }
  }, [timeEventActionHasBeenSent, closeDrawerRef, locationPathnameRef]);

  useEffect(() => {
    if (location.pathname === location.state?.from) {
      void closeDrawerRef.current();
    }
  }, [location, closeDrawerRef]);

  const drawerContextValue = useMemo(
    () => ({
      scrollParentRef,
      globalNavigate,
    }),
    [scrollParentRef, globalNavigate],
  );

  return (
    <AnimatePresence
      onExitComplete={() => {
        resolveCloseDrawer(null);
      }}
    >
      {isVisible && (
        <AnimatedFlex
          // IMPORTANT: classname is only used for ONBOARDING
          className={
            location.pathname === DRAWER_ROUTES.DRAWER__ATTENDANCE_OVERVIEW && !isMobileBreakpoint
              ? 'onboarding-attendanceoverview-2'
              : undefined
          }
          ref={scrollParentRef}
          as="section"
          variant="drawer.container"
          key="drawer.container"
          initial={{ x: '125%' }}
          animate={{ x: 0 }}
          exit={{ x: '125%' }}
          transition={{
            type: 'spring',
            duration: 0.4,
          }}
        >
          <DrawerContext.Provider value={drawerContextValue}>{children}</DrawerContext.Provider>
        </AnimatedFlex>
      )}
    </AnimatePresence>
  );
};

const Header = ({ children, sx, ...props }: FlexProps): React.ReactElement => (
  <Flex
    {...props}
    as="header"
    sx={{ minHeight: '26px', mb: 3, px: 3, alignItems: 'center', flexDirection: 'row', gap: 2, ...(sx && sx) }}
  >
    {children}
  </Flex>
);

const Title = ({ children, sx, ...props }: HeadingProps): React.ReactElement => (
  <Heading {...props} as="h1" variant="heading4" sx={{ flex: '1 0', ...(sx && sx) }}>
    {children}
  </Heading>
);

const SubTitle = ({ children, sx, ...props }: HeadingProps): React.ReactElement => (
  <Heading {...props} as="h2" variant="heading5" sx={{ mb: 1, ...(sx && sx) }}>
    {children}
  </Heading>
);

const Container = ({ children, sx, ...props }: FlexProps): React.ReactElement => (
  <Flex
    {...props}
    sx={{
      flex: '1 0',
      px: 3,
      gap: 2,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      ...(sx && sx),
    }}
  >
    {children}
  </Flex>
);

Drawer.Container = Container;
Drawer.Header = Header;
Drawer.Title = Title;
Drawer.SubTitle = SubTitle;
