import React from 'react';
import { Flex } from 'theme-ui';

import { Dot, DotProps } from 'components/Dot/Dot';

type WithDotProps = {
  showDot?: boolean;
  dotProps?: DotProps;
};

export function withDot<T>(
  Component: React.ComponentType<T>,
): (props: T & WithDotProps) => React.ReactElement<T & WithDotProps> {
  const ComponentWithDot = ({
    showDot,
    dotProps,
    ...props
  }: T & WithDotProps): React.ReactElement<T & WithDotProps> => {
    if (showDot) {
      return (
        <Flex sx={{ position: 'relative' }}>
          {/* this make no sens but it keeps eslint satisfied.. */}
          {/* should work without assertion as ...props is typed correctly */}
          <Component {...(props as T & WithDotProps)} />
          <Dot {...dotProps} />
        </Flex>
      );
    }
    // should work without assertion as ...props is typed correctly
    return <Component {...(props as T & WithDotProps)} />;
  };

  ComponentWithDot.defaultProps = {
    ...Component.defaultProps,
    showDot: true,
    dotProps: undefined,
  };

  return ComponentWithDot;
}
