import { useRecoilValue } from 'recoil';

import { NameDisplayOrder } from 'api/actions/userSession/userSessionActions.types';
import { nameDisplayOrderSelector } from 'state/userSession';

type GenerateInitials = (firstName: string, surname: string) => string;

/**
 * Returns a generateInitials function that based on nameDisplayOrder will generate initials.
 */
export const useGenerateInitials = (): GenerateInitials => {
  const nameDisplayOrder = useRecoilValue(nameDisplayOrderSelector);

  const generateInitials: GenerateInitials = (firstName: string, surname: string) => {
    const firstNameFirstLetter = firstName[0];
    const surnameFirstLetter = surname[0];

    if (nameDisplayOrder === NameDisplayOrder.SurnameFirst) return surnameFirstLetter + firstNameFirstLetter;

    return firstNameFirstLetter + surnameFirstLetter;
  };

  return generateInitials;
};
