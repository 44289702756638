import React, { useMemo } from 'react';
import { Flex, FlexOwnProps, ThemeUIStyleObject } from 'theme-ui';

import { settingsBorder } from 'styles/theme/settings';
import { Variants } from '../../../types';

type Props = {
  children: React.ReactElement<FlexOwnProps> | React.ReactElement<FlexOwnProps>[] | React.ReactNode;
  variant?: Variants;
  sx?: ThemeUIStyleObject;
  pt?: string | number;
  pb?: string | number;
  withBorderBottom?: boolean;
};

export const BodyHidden = ({ variant, sx, pt, pb, withBorderBottom, children }: Props): React.ReactElement => {
  const childCount = React.Children.toArray(children).length;

  const flexVariant = useMemo(() => {
    const container = 'settings.container.vertical';
    let currentVariant = '';

    switch (variant) {
      case 'noGap':
        return `${container}.noGap`;
      case 'sm':
        currentVariant = `${container}.sm`;
        break;
      case 'md':
        currentVariant = `${container}.md`;
        break;
      case 'lg':
        currentVariant = `${container}.lg`;
        break;
      case 'xlg':
        currentVariant = `${container}.xlg`;
        break;
      default:
        currentVariant = '';
    }

    return currentVariant;
  }, [variant]);

  const { border, borderColor } = settingsBorder;

  return (
    <Flex
      variant={flexVariant}
      sx={{
        bg: 'settings.settingsGroup',
        px: '0.75rem',
        borderLeft: border,
        borderRight: border,
        ...(withBorderBottom && {
          borderBottom: border,
          borderBottomLeftRadius: 'sm',
          borderBottomRightRadius: 'sm',
        }),
        borderColor,
        ...(sx && sx),
      }}
    >
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement<FlexOwnProps>(child)) {
          const isOneChild = childCount === 1;
          const isFirstChild = index === 0;
          const isLastChild = index === childCount - 1;

          if (isOneChild) {
            return React.cloneElement(child, { sx: { pb, pt, ...child.props.sx } });
          }
          if (isFirstChild) {
            return React.cloneElement(child, { sx: { pt, ...child.props.sx } });
          }
          if (isLastChild) {
            return React.cloneElement(child, { sx: { pb, ...child.props.sx } });
          }
        }
        return <>{child}</>;
      })}
    </Flex>
  );
};
