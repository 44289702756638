import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { useQuery } from 'react-fetching-library';

import { fetchNotificationSettingsAction } from 'api/actions/settings/settingsActions';
import { defaultNotificationsSettingsAtom } from 'state/settings';

export const useDefaultNotifications = () => {
  const { query, abort } = useQuery(fetchNotificationSettingsAction(), false);
  const [defaultNotificationsSettings, setDefaultNotificationsSettings] = useRecoilState(
    defaultNotificationsSettingsAtom,
  );

  const fetchDefaultNotifications = useCallback(async () => {
    const response = await query();
    const { error, payload } = response;

    if (!error && payload) {
      setDefaultNotificationsSettings(payload);
    }

    return response;
  }, [query, setDefaultNotificationsSettings]);

  return {
    fetchDefaultNotifications,
    defaultNotificationsSettings,
    setDefaultNotificationsSettings,
    fetchAbort: abort,
  };
};
