import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { ChangeHandler } from 'react-hook-form';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { RadioGroup } from 'components/ui/RadioGroup';

import { DateRange, useDateRangeRadioPicker } from './hooks/useDateRangeRadioPicker';

const labelWrapperSx: ThemeUIStyleObject = {
  flex: 1,
  justifyContent: 'space-between',
};

const labelSx: ThemeUIStyleObject = {
  ml: 2,
};

const uncheckedDateSx: ThemeUIStyleObject = {
  color: 'reports.radio.uncheckedDate',
};

type Props = {
  value?: number;
  onChange: ChangeHandler | ((e: React.ChangeEvent<HTMLInputElement>) => void);
  sx?: ThemeUIStyleObject;
};

export const DateRangeRadioPicker = React.forwardRef<HTMLInputElement, Props>(
  ({ onChange, sx, ...props }: Props, ref): React.ReactElement | null => {
    useLingui();

    const { getAdditionalLabel } = useDateRangeRadioPicker();

    const uniqueRadioElements = useMemo(
      () => [
        {
          value: DateRange.CUSTOM,
          label: t({ id: 'date_range.custom', message: 'Custom' }),
          additionalLabel: getAdditionalLabel(DateRange.CUSTOM),
        },
        {
          value: DateRange.CURRENT_WEEK,
          label: t({ id: 'date_range.current_week', message: 'Current week' }),
          additionalLabel: getAdditionalLabel(DateRange.CURRENT_WEEK),
        },
        {
          value: DateRange.CURRENT_MONTH,
          label: t({ id: 'date_range.current_month', message: 'Current month' }),
          additionalLabel: getAdditionalLabel(DateRange.CURRENT_MONTH),
        },
        {
          value: DateRange.LAST_SEVEN_DAYS,
          label: t({ id: 'date_range.last_seven_days', message: 'Last 7 days' }),
          additionalLabel: getAdditionalLabel(DateRange.LAST_SEVEN_DAYS),
        },
        {
          value: DateRange.LAST_WEEK,
          label: t({ id: 'date_range.last_week', message: 'Last week' }),
          additionalLabel: getAdditionalLabel(DateRange.LAST_WEEK),
        },
        {
          value: DateRange.LAST_MONTH,
          label: t({ id: 'date_range.last_month', message: 'Last month' }),
          additionalLabel: getAdditionalLabel(DateRange.LAST_MONTH),
        },
      ],
      [getAdditionalLabel],
    );

    const mappedUniqueRadioElements = useMemo(
      () =>
        uniqueRadioElements.map((item) => ({
          value: item.value,
          label: (
            <Flex sx={labelWrapperSx}>
              <Text sx={labelSx}>{item.label}</Text>
              <Text as="p" variant="heading5" className="date-range__additional-label" sx={uncheckedDateSx}>
                {item.additionalLabel}
              </Text>
            </Flex>
          ),
        })),
      [uniqueRadioElements],
    );

    return (
      <Flex sx={{ flexDirection: 'column', ...sx }}>
        <Text as="h4" variant="heading4" sx={{ mb: 2 }}>
          <Trans id="date_range.header">Date range:</Trans>
        </Text>

        <RadioGroup
          ref={ref}
          onChange={(e) => void onChange(e)}
          fields={mappedUniqueRadioElements}
          size="sm"
          sx={{
            'input:checked ~ div > .date-range__additional-label': {
              color: 'reports.radio.text',
              fontWeight: 'bold',
            },
          }}
          {...props}
        />
      </Flex>
    );
  },
);
