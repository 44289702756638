import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { WAIT_FOR_FORM_LOGIC, useMinimizedModals } from 'components/Modal/output/useMinimizedModals';
import { TO_REL } from 'constants/routes';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { getScheduleFormValuesAtom } from 'state/calendar';
import { MinimizeModalAddSchedule, MinimizedModal, ViewWithMinimizedModals } from 'state/modal';
import { delay } from 'utils/delay';
import { useScheduleWizard } from '../../ScheduleWizardModal/hooks/useScheduleWizard';

export const useMinimizeModalAddSchedule = (personId: string, dateUnix: number) => {
  useLingui();
  const getScheduleFormValues = useRecoilValue(getScheduleFormValuesAtom);
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddSchedule>();

  const { schedule, setSchedule } = useScheduleWizard();
  const { isCalendar, isReportsAttendanceList } = useAppRouting(['isCalendar', 'isReportsAttendanceList']);
  const minimizedView = useMemo(() => {
    if (isCalendar) {
      return ViewWithMinimizedModals.CALENDAR;
    }

    if (isReportsAttendanceList) {
      return ViewWithMinimizedModals.ATTENDANCE;
    }

    return ViewWithMinimizedModals.TIMESHEETS;
  }, [isCalendar, isReportsAttendanceList]);
  const { handleSetModalDetails, onHandleCloseModalWithMinimizedDetails } =
    useMinimizedModals<MinimizeModalAddSchedule>(minimizedView);

  const onHandleMinimize = useCallback(
    async (minimizeModal: () => void) => {
      // @see useMinimizedModals.ts
      await delay(WAIT_FOR_FORM_LOGIC);

      handleSetModalDetails({
        minimizedModal: MinimizedModal.ADD_SCHEDULES,
        route: TO_REL.ADD_SCHEDULE__USER__DATE,
        title: t({ id: 'schedule.add_schedule' }),
        modalDetails: {
          schedule: getScheduleFormValues ? _.cloneDeep(getScheduleFormValues()) : schedule,
        },
        additionalPath: `/${personId}/${dateUnix}`,
        employeesToValidate: [personId],
      });

      minimizeModal();
    },
    [handleSetModalDetails, getScheduleFormValues, schedule, personId, dateUnix],
  );

  const restoreMinimizedState = useCallbackRef(() => {
    if (!minimizedModalDetails) return;

    const { schedule: storedSchedule } = minimizedModalDetails.modalDetails;

    setSchedule(storedSchedule);
  });

  useEffect(() => {
    restoreMinimizedState.current();
  }, [restoreMinimizedState]);

  return {
    onHandleMinimize,
    onHandleCloseModalWithMinimizedDetails,
  };
};
