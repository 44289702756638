import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { WorkStatusSelect } from 'components/ui/Select/variants/WorkStatusSelect';
import { statusesMapSelectorFamily, statusesOptionsSelectorFamily } from 'state/selectOptions';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';

export const WorkStatus = (): React.ReactElement | null => {
  useLingui();
  const {
    requestToEdit: { selectedRequestToEdit },
    modifyRequest: { modifyRequestData },
  } = useAddRequest();
  const statuses = useRecoilValue(
    statusesMapSelectorFamily(modifyRequestData ? modifyRequestData.eventUpdateDetails.typeId || '' : null),
  );
  const statusesOptions = useRecoilValue(
    statusesOptionsSelectorFamily(
      modifyRequestData?.eventUpdateDetails.typeId || selectedRequestToEdit?.eventUpdateDetails.typeId || null,
    ),
  );

  const workStatusRef = useRef<HTMLInputElement | null>(null);

  const { watch, setValue, register } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  const handleWorkStatusSetValueAs = useCallback(
    (statusId: string) => {
      if (!statuses) return undefined;

      const status = statuses.get(statusId);

      if (!status) return undefined;

      const { id, isEndStatus } = status;

      setValue('details.isEnd', isEndStatus);
      setNativeValue(workStatusRef, statusId);

      return id;
    },
    [setValue, statuses],
  );

  const workStatusRegister = useMemo(
    () => register('details.typeId', { setValueAs: handleWorkStatusSetValueAs }),
    [handleWorkStatusSetValueAs, register],
  );

  const typeSelectVisible = useMemo(() => {
    if (actionTypeWatch === RequestActionType.Create) return true;

    if (actionTypeWatch === RequestActionType.Edit && (selectedRequestToEdit || modifyRequestData)) return true;

    return false;
  }, [actionTypeWatch, selectedRequestToEdit, modifyRequestData]);

  if (!typeSelectVisible) return null;

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text sx={{ fontWeight: 'bold', mb: 2 }} variant="heading4">
        {t({ id: 'requests.add_request.work_status', message: 'Work status' })}
      </Text>
      <WorkStatusSelect
        ref={mergeRefs([workStatusRef, workStatusRegister.ref])}
        {..._.omit(workStatusRegister, 'ref')}
        id="workStatus"
        options={statusesOptions}
        size="sm"
        searchable
        onUpdateFieldView={(value) => {
          if (!value.length) {
            // FIXME: Problem inside Select. It occurs during reset when it's mounted.
            // setNativeValue solves problem of selecting value -> resetting -> selecting same value again.
            // It allows to emit onChange event for selecting same value after reset.
            setNativeValue(workStatusRef, value);
          }
        }}
      />
    </Flex>
  );
};
