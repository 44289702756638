import { t } from '@lingui/macro';
import { NonUndefined } from 'react-hook-form';

import { ReportSummaryType } from 'api/actions/reports/reportsActions.types';

export type ReportSummaryTypeKeys = keyof ReportSummaryType;
export const summaryColumnsKeys: ReportSummaryTypeKeys[] = [
  'scheduledTimeDuration',
  'startStopEvents',
  'timers',
  'payroll',
  'dayStates',
  'workStatuses',
  'timeOffs',
  'customRequests',
];

export const otherArray: ReportSummaryTypeKeys[] = ['scheduledTimeDuration', 'startStopEvents'];
export const getOtherHeaderNamesMap = (): Partial<Record<ReportSummaryTypeKeys, string>> => ({
  scheduledTimeDuration: t({ id: 'reports.scheduled_time' }),
  startStopEvents: t({ id: 'reports.start_stop_events', message: 'Clock ins/outs' }),
});

export type TimersTypeKeys = keyof NonNullable<ReportSummaryType['timers']>;
export const timersArray: NonUndefined<TimersTypeKeys>[] = [
  'workTimeDuration',
  'overtimeDuration',
  'missingWorkTimeDuration',
  'nightTimeDuration',
  'withoutScheduleDuration',
  'autoBreakDuration',
  'autoUnpaidBreakDuration',
];
export const getTimersHeaderNamesMap = (): Record<TimersTypeKeys, string> => ({
  autoBreakDuration: t({ id: 'reports.auto_break' }),
  autoUnpaidBreakDuration: t({ id: 'reports.auto_break_unpaid' }),
  missingWorkTimeDuration: t({ id: 'reports.missing_work_time' }),
  nightTimeDuration: t({ id: 'reports.night_time' }),
  overtimeDuration: t({ id: 'reports.overtime' }),
  withoutScheduleDuration: t({ id: 'reports.without_schedule' }),
  workTimeDuration: t({ id: 'reports.work_time' }),
});

export type PayrollTypeKeys = keyof NonNullable<ReportSummaryType['payroll']>;
export const payrollArray: NonUndefined<PayrollTypeKeys>[] = ['regular', 'overtime', 'total'];
export const getPayrollHeaderNamesMap = (): Record<PayrollTypeKeys, string> => ({
  regular: t({ id: 'reports.payroll.regular' }),
  overtime: t({ id: 'reports.payroll.overtime' }),
  total: t({ id: 'reports.payroll.total' }),
});

export type DayStatesTypeKeys = keyof NonNullable<ReportSummaryType['dayStates']>;
export const dayStatesArray: DayStatesTypeKeys[] = [
  'presentCount',
  'overtimeCount',
  'incompleteShiftCount',
  'absentCount',
  'timeOffCount',
  'customRequestCount',
  'businessTripCount',
];
export const getDayStateHeaderNamesMap = (): Record<DayStatesTypeKeys, string> => ({
  presentCount: t({ id: 'aside_filters.present' }),
  absentCount: t({ id: 'aside_filters.absent' }),
  businessTripCount: t({ id: 'aside_filters.business_trip' }),
  customRequestCount: t({ id: 'aside_filters.custom_request' }),
  incompleteShiftCount: t({ id: 'aside_filters.incomplete_shift' }),
  overtimeCount: t({ id: 'aside_filters.overtime' }),
  timeOffCount: t({ id: 'aside_filters.time_off' }),
});
