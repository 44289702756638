import _ from 'lodash';
import { useEffect, useRef } from 'react';

/**
 * This hook is similar to useMemo, but instead of passing an array of dependencies we pass a
 * custom compare function that receives the previous and new value.
 * The compare function can then compare nested properties, call object methods,
 * or anything else to determine equality.
 * If the compare function returns true then the hook returns the old object reference.
 * If no compare function is provided, lodash isEqual will be used.
 */
export function useMemoCompare<T>(next: T, compare?: (prev: T | undefined, next: T) => boolean): T {
  const previousRef = useRef<T | undefined>();
  const previous = previousRef.current;

  const isEqual = compare ? compare(previous, next) : _.isEqual(previous, next);

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  return isEqual && previous ? previous : next;
}
