import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { addSnackbar } from 'base/Snackbar/output/actions';
import { ErrorsExternalProviders } from 'constants/errors';
import { QueryParams } from 'constants/queryParams';
import { useQuery } from 'hooks/useQuery/useQuery';

import { useExternalIntegrations } from './useExternalIntegrations';

export const useParamError = () => {
  useLingui();
  const { externalIntegrations } = useExternalIntegrations();
  const urlParams = useQuery();
  const paramError = urlParams.get(QueryParams.Error);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (paramError !== null && externalIntegrations) {
      const errorMessagesMap: { [key in ErrorsExternalProviders]: string } = {
        [ErrorsExternalProviders.LINKING_FAILED]: t({
          id: 'errors.external_provider.linking_failed',
          message: "We couldn't link your account with the external provider. Please try again.",
        }),
        [ErrorsExternalProviders.ACCOUNT_ALREADY_CONNECTED]: t({
          id: 'errors.external_provider.account_already_connected',
          message: 'This external account is already connected with other account.',
        }),
        [ErrorsExternalProviders.ACCOUNT_NOT_LINKED]: t({
          id: 'errors.external_provider.sign_in_failed',
          message: 'There is no linked account in our platform.',
        }),
        [ErrorsExternalProviders.SIGN_IN_ERROR]: t({
          id: 'errors.external_provider.sign_in_error',
          message: 'There was an error during sign in. Please try again later.',
        }),
      };

      addSnackbar({
        variant: 'danger',
        message: errorMessagesMap[+paramError as ErrorsExternalProviders],
        duration: 5000,
      });

      setSearchParams(''); //  clears search params ex. 'error=1' not to show them after page refresh
    }
  }, [externalIntegrations, paramError, setSearchParams]);
};
