import { useResetRecoilState } from 'recoil';

import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { FilterGroupNames } from 'layouts/AuthorizedApp/AsideFilters/types';
import {
  SEARCH_FILTER_TYPE,
  filterGroupStateAtomFamily,
  pickTeammatesFiltersRestoredAtom,
  searchFilterValueAtomFamily,
} from 'state/filters';

import { useAddEvents } from './useAddEvents';

export const useAddEventsCleanUp = () => {
  const { resetStep, resetAddEventsFormRestored } = useAddEvents();
  const resetTeammatesFilterState = useResetRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES_PICK_TEAMMATES_FILTERS),
  );
  const resetTagsFilterState = useResetRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.TAGS_PICK_TEAMMATES_FILTERS),
  );
  const resetRolesFilterState = useResetRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.ROLES_PICK_TEAMMATES_FILTERS),
  );
  const resetSearchInputState = useResetRecoilState(
    searchFilterValueAtomFamily(SEARCH_FILTER_TYPE.TEAMMATES_PICK_TEAMMATES_FILTERS),
  );
  const resetPickTeammatesFiltersRestored = useResetRecoilState(pickTeammatesFiltersRestoredAtom);

  useUnmount(() => {
    resetStep();
    resetAddEventsFormRestored();
    resetTeammatesFilterState();
    resetTagsFilterState();
    resetRolesFilterState();
    resetSearchInputState();
    resetPickTeammatesFiltersRestored();
  });
};
