import { Tag } from 'api/actions/organizationSession/organizationSessionActions.types';
import { UserSelectableColor } from 'constants/userSelectableColors';

export const getTagByTagName: (tagName: string, tags: Map<string, Tag> | null) => Tag = (tagName, tags) => {
  const defaultTag: Tag = { id: tagName, name: tagName, isInformational: true, color: UserSelectableColor.grays2 };
  if (tags && tagName) {
    const tagArray = [...tags.values()];
    const foundTag = tagArray.find((tagg) => tagg.name === tagName);
    return foundTag || defaultTag;
  }
  return defaultTag;
};
