import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { connectIntegrationsPayrollAction } from 'api/actions/integrations/integrationActions';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useIntegrationTileTexts } from '../../../../../hooks/useIntegrationTileTexts';
import { useIntegrations } from '../../../../../hooks/useIntegrations';
import { useIntegrationHelpLinks } from '../../../hooks/useIntegrationHelpLinks';
import { IntegrationTile } from '../../IntegrationTile/IntegrationTile';
import { IntegrationProps } from '../../IntegrationTile/types';

type Props = IntegrationProps;

export const CommonIntegrationTile = ({ integration }: Props): React.ReactElement => {
  const { type, isActive, isExpired, imageUrl } = integration;
  const { mutate: connectPayrollMutate } = useMutation(connectIntegrationsPayrollAction);
  const { fetchIntegrations, disconnectIntegration, reconnectIntegration } = useIntegrations();
  const integrationTexts = useIntegrationTileTexts(type);
  const [isLoading, setIsLoading] = useState(false);

  const helpLink = useIntegrationHelpLinks(type);

  const handleConnect = useCallback(async () => {
    setIsLoading(true);
    const { error } = await connectPayrollMutate({ integration: type });

    if (!error) {
      await fetchIntegrations();
    }
    setIsLoading(false);
  }, [connectPayrollMutate, fetchIntegrations, type]);

  const handleDisconnect = useCallback(async () => {
    setIsLoading(true);
    await disconnectIntegration(type);
    setIsLoading(false);
  }, [disconnectIntegration, type]);

  const handleReconnect = useCallback(async () => {
    setIsLoading(true);
    await reconnectIntegration(type);
    setIsLoading(false);
  }, [reconnectIntegration, type]);

  return (
    <IntegrationTile isActive={isActive} isExpired={isExpired}>
      <IntegrationTile.Tags isActive={isActive} isExpired={isExpired} />
      <IntegrationTile.Body {...integrationTexts} image={imageUrl} />
      <IntegrationTile.Buttons>
        <IntegrationTile.Buttons.Connect
          isActive={isActive}
          isExpired={isExpired}
          textVariant="enable"
          onClick={floatingPromiseReturn(handleConnect)}
          isLoading={isLoading}
        />
        <IntegrationTile.Buttons.Expired
          isExpired={isExpired}
          onClick={floatingPromiseReturn(handleReconnect)}
          isLoading={isLoading}
        />
        <IntegrationTile.Buttons.Disconnect
          isActive={isActive}
          isExpired={isExpired}
          textVariant="disable"
          onClick={floatingPromiseReturn(handleDisconnect)}
          isLoading={isLoading}
        />
        <IntegrationTile.Buttons.LearnMore to={helpLink} />
      </IntegrationTile.Buttons>
    </IntegrationTile>
  );
};
