import React, { useContext, useEffect } from 'react';
import { ClientContext } from 'react-fetching-library';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { signOutAction } from 'api/actions/auth/authActions';
import { signOut as channelSignOut } from 'broadcastChannel/channelActionCreators/channelActionCreators';
import { REDIRECT_INITIATORS } from 'broadcastChannel/channelReducer/channelReducer';
import { LoadingSplash } from 'components/Loading/LoadingSplash';
import { APP_LINK_BASENAME } from 'constants/common';
import { TO } from 'constants/routes';
import { signOut } from 'context/auth/authActionCreators/authActionCreators';
import { authStorage } from 'context/auth/authStorage/AuthStorage';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { broadcastChannel } from 'observers/BroadcastChannelObserver';
import { languageSelector } from 'state/recoilState';

export const SignOut = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const dispatch = useAuthDispatch();
  const { query } = useContext(ClientContext);
  const { state } = useLocation();
  const { redirectInitiator } = state || {};
  const wasInitiatedByBroadcastChannel = redirectInitiator === REDIRECT_INITIATORS.BROADCAST_CHANNEL;
  const wasInitiatedByRefreshAccessTokenInterceptor =
    redirectInitiator === REDIRECT_INITIATORS.REFRESH_ACCESS_TOKEN_INTERCEPTOR;

  const ommitSignOutRequest = wasInitiatedByBroadcastChannel || wasInitiatedByRefreshAccessTokenInterceptor;

  const signOutCleanUp = async () => {
    authStorage.accessToken = null;
    setTimeout(() => {
      localStorage.clear();
    }, 0);

    let signOutError = false;

    // when initiated by broadcastChannel signOutAction returns error as it has no token (it was removed on another tab)
    // the same goes for RefreshAccessTokenInterceptor, we are already unauthorised for the server, so we cant request signout
    if (!ommitSignOutRequest) {
      const { error } = await query(signOutAction());
      signOutError = error;
    }

    if (!signOutError) {
      if (!wasInitiatedByBroadcastChannel) {
        void broadcastChannel?.postMessage(channelSignOut());
      }
      dispatch(signOut());
    }
    window.location.replace(`${APP_LINK_BASENAME}${TO.SIGN_IN[language]}`);
  };

  const signOutCleanUpRef = useCallbackRef(signOutCleanUp);

  useEffect(() => {
    void signOutCleanUpRef.current();
  }, [signOutCleanUpRef]);

  return <LoadingSplash />;
};
