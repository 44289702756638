/**
 * Checks if passed value is of type by checking if the passed property exists on it.
 *
 * @param {unknown} varToBeChecked Object to check.
 * @param {string} propertyToCheckFor Key to check for in the passed object.
 *
 * @return {boolean} Return true if key exists in the object.
 */
export const isOfType = <T>(varToBeChecked: unknown, propertyToCheckFor: keyof T): varToBeChecked is T =>
  (varToBeChecked as T)[propertyToCheckFor] !== undefined;
