import { Trans, t } from '@lingui/macro';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useRecoilValue } from 'recoil';
import { Flex, Heading } from 'theme-ui';

import { LinkButton } from 'components/ui/Buttons';
import { APP_NAME } from 'constants/common';
import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';

export const ServiceUnavailable = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <>
      <Helmet>
        <title>
          {t({ id: 'service_unavailable' })} - {APP_NAME}
        </title>
      </Helmet>

      <Flex sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: '1 0' }}>
        <Heading as="h1" variant="heading1">
          503
        </Heading>
        <Heading as="h2" my={1} variant="heading2">
          <Trans id="service_unavailable">Service unavailable 😥</Trans>
        </Heading>
        <Heading as="h3" mt={1} mb={3} variant="heading4">
          <Trans id="service_unavailable.try_again">Try again in a while</Trans>
        </Heading>
        <LinkButton sx={{ fontSize: 3 }} variant="primary" shape="rounded" to={TO.START[language]}>
          {t({ id: 'error_page.return_button' })}
        </LinkButton>
      </Flex>
    </>
  );
};
