import React, { useCallback, useEffect, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { useSetRecoilState } from 'recoil';
import TagManager from 'react-gtm-module';

import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { setAuthorized, setUnauthorized, startAuthorizing } from 'context/auth/authActionCreators/authActionCreators';
import { LoadingSplash } from 'components/Loading/LoadingSplash';
import { fetchUserSessionAction } from 'api/actions/userSession/userSessionActions';
import { userSessionAtom } from 'state/userSession';

import { UserSessionInitializerProps } from './UserSessionInitializer.types';

export const UserSessionInitializer = ({ children }: UserSessionInitializerProps): React.ReactElement => {
  const [isInitialized, setIsInitialized] = useState(false);
  const setUserSession = useSetRecoilState(userSessionAtom);

  const dispatch = useAuthDispatch();
  const { query } = useClient();

  useEffect(() => {
    dispatch(startAuthorizing());
  }, [dispatch]);

  const fetchUserSession = useCallback(async () => {
    const { payload, error } = await query(fetchUserSessionAction());

    if (!error && payload) {
      setUserSession(payload);
      dispatch(setAuthorized());

      // GTM INITIAL USER_ID SET
      TagManager.dataLayer({
        dataLayer: {
          user_id: payload.personId,
        },
      });
    } else {
      dispatch(setUnauthorized());
    }

    setIsInitialized(true);
  }, [dispatch, query, setUserSession]);

  useEffect(() => {
    if (!isInitialized) {
      void fetchUserSession();
    }
  }, [fetchUserSession, isInitialized]);

  if (!isInitialized) {
    return <LoadingSplash />;
  }

  return <>{children}</>;
};
