import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';

import { HeadingTip } from '../HeadingTip';
import { SettingsLoadingSpinnerCss } from '../SettingsLoadingSpinnerCss';

import { DefaultNotificationsForm } from './components/DefaultNotificationsForm/DefaultNotificationsForm';
import { useDefaultNotifications } from './components/DefaultNotificationsForm/hooks/useDefaultNotifications';
import { useDefaultNotificationsCleanUp } from './hooks/useDefaultNotificationsCleanUp';

export const DefaultNotifications = (): React.ReactElement => {
  useLingui();
  const { fetchDefaultNotifications, defaultNotificationsSettings } = useDefaultNotifications();

  useEffect(() => {
    if (!defaultNotificationsSettings) {
      void fetchDefaultNotifications();
    }
  }, [defaultNotificationsSettings, fetchDefaultNotifications]);

  useDefaultNotificationsCleanUp();

  return (
    <>
      <HeadingTip
        label={t({
          id: 'notifications_settings.heading.notifications',
          message: 'Default notifications',
        })}
        additionalInfo={t({
          id: 'notifications_settings.heading.notifications.additional_info',
          message:
            'Organization-wide default notifications settings, can be overwritten by the profiles notification preferences.',
        })}
        additionalInfoSx={{
          fontSize: 3,
          lineHeight: '1.5rem',
        }}
      />
      {defaultNotificationsSettings ? <DefaultNotificationsForm /> : <SettingsLoadingSpinnerCss />}
    </>
  );
};
