import { SelectProps } from '../Select/Select';

export type DurationPickerOwnProps = {
  onValidError?: () => void;
  onClearError?: () => void;
  hours?: boolean;
  minutes?: boolean;
  seconds?: boolean;
  quickSelectOptions?: number[];
  minDuration?: number;
  maxDuration?: number;
  isStatic?: boolean;
  inputVariant?: 'default' | 'minutes';
  extended?: boolean;
  sortingOrder?: 'asc' | 'desc';
};

export type DurationPickerProps = Omit<SelectProps, 'placeholder' | 'options'> & DurationPickerOwnProps;

export enum InputNames {
  hours = 'hours',
  minutes = 'minutes',
  seconds = 'seconds',
}
