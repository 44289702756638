import React, { useState } from 'react';
import { Box, Image } from 'theme-ui';
import { useTimer } from 'use-timer';

import { useCameraImageBuffer } from 'Kiosk/hooks/useCameraImageBuffer';

export const DebugCameraImageBuffer = (): React.ReactElement => {
  const [localBlob, setLocalBlob] = useState<string[]>([]);
  const { getBlob } = useCameraImageBuffer();

  useTimer({
    autostart: true,
    interval: 1000,
    onTimeUpdate: () => {
      if (getBlob) {
        setLocalBlob(getBlob());
      }
    },
  });

  return (
    <Box
      sx={{
        display: ['none', 'block'],
        position: 'absolute',
        top: '50%',
        left: 0,
        maxWidth: 48 * 5,
        lineHeight: 1,
        zIndex: 9999999,
      }}
    >
      {localBlob.map((snap) => (
        <a key={snap} href={snap} target="_blank" rel="noreferrer">
          <Image src={snap} sx={{ width: '48px' }} />
        </a>
      ))}
    </Box>
  );
};
