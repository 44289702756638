import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { VALIDATION_MAX_TIME_IN_SECONDS } from 'constants/requests';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { MinimizeModalAddRequest } from 'state/modal';
import { AddRequestExtendedFormContext } from '../../../../../../types';
import { useAddRequest } from '../../../../hooks/useAddRequest';

import { CustomFormContainer } from './components/CustomFormContainer';

export const CustomForm = (): React.ReactElement => {
  useLingui();
  const {
    setFormState,
    modifyRequest: { modifyRequestData },
    requestToEdit: { selectedRequestToEdit, resetSelectedRequestToEditId },
    defaultDateUnix,
    requestFormRestored: { defaultValuesRestored },
  } = useAddRequest();

  const selectedCustomDataRef = useCallbackRef(selectedRequestToEdit);

  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddRequest>();

  const firstRenderMinimizedModal = useRef(
    (() => {
      if (defaultValuesRestored) return false;
      return !!minimizedModalDetails?.modalDetails?.formState;
    })(),
  );

  const { unregister, watch, setValue, setError, clearErrors } = useFormContext<AddRequestExtendedFormContext>();

  const forWholeDayWatch = watch('isDateBound');
  const typeIdWatch = watch('details.typeId');
  const calendarWatch = watch('dateTimeDetails.dates');
  const actionTypeWatch = watch('actionType');

  const formVisible = useMemo(() => {
    if (actionTypeWatch === RequestActionType.Edit && !selectedRequestToEdit && !modifyRequestData) return false;

    if (actionTypeWatch === RequestActionType.Create && !typeIdWatch?.length) return false;

    if (actionTypeWatch === RequestActionType.Remove) return false;

    return true;
  }, [actionTypeWatch, modifyRequestData, selectedRequestToEdit, typeIdWatch?.length]);

  const handleDayExceededValidation = useCallback(() => {
    if (!forWholeDayWatch && calendarWatch && !_.isEmpty(calendarWatch)) {
      const [startUnix, endUnix] = calendarWatch;

      if (endUnix) {
        const differenceInSeconds = endUnix - startUnix;

        if (differenceInSeconds > VALIDATION_MAX_TIME_IN_SECONDS) {
          setError('dateTimeDetails.dates', {
            type: 'validate',
            message: t({ id: 'add_request.error.day_exceeded' }),
          });
        } else {
          clearErrors('dateTimeDetails.dates');
        }
      }
    }
  }, [calendarWatch, clearErrors, forWholeDayWatch, setError]);

  const handleFormVisibility = useCallback(() => {
    if (!formVisible) {
      setFormState((prevState) => ({ ...prevState, mainFormVisible: false }));

      if (actionTypeWatch === RequestActionType.Remove) {
        unregister('dateTimeDetails.dates');
        unregister('details.ignoreWeekends');
        unregister('details.ignoreHolidays');
        unregister('isDateBound');
      }
    }

    if (formVisible) {
      setFormState((prevState) => ({ ...prevState, mainFormVisible: true }));
    }
  }, [actionTypeWatch, formVisible, setFormState, unregister]);

  useEffect(() => {
    handleDayExceededValidation();
  }, [handleDayExceededValidation]);

  useEffect(() => {
    handleFormVisibility();
  }, [handleFormVisibility]);

  useEffect(() => {
    if (!forWholeDayWatch) {
      unregister('details.ignoreWeekends');
      unregister('details.ignoreHolidays');
    }
  }, [forWholeDayWatch, unregister]);

  useEffect(() => {
    if (forWholeDayWatch && !selectedCustomDataRef.current && !firstRenderMinimizedModal.current) {
      setValue('details.ignoreWeekends', true);
      setValue('details.ignoreHolidays', true);
    }
  }, [forWholeDayWatch, selectedCustomDataRef, setValue]);

  useEffect(() => {
    if (!firstRenderMinimizedModal.current) {
      setValue('isDateBound', true);
    } else {
      firstRenderMinimizedModal.current = false;
    }

    if (defaultDateUnix) {
      setValue('dateTimeDetails.dates', [defaultDateUnix, defaultDateUnix]);
    }
  }, [defaultDateUnix, setValue]);

  useEffect(
    () => () => {
      resetSelectedRequestToEditId();
    },
    [resetSelectedRequestToEditId],
  );

  return (
    <CustomFormContainer>
      <CustomFormContainer.EditionObserver />
      <CustomFormContainer.LimitsObserver />
      <CustomFormContainer.SaveStateObserver />
      <CustomFormContainer.ResetFormObserver />
      <CustomFormContainer.RequestsHistorySelect />
      <CustomFormContainer.SelectType />
      {formVisible && (
        <>
          <CustomFormContainer.Calendar />
          <CustomFormContainer.SkipSwitches />
        </>
      )}
    </CustomFormContainer>
  );
};
