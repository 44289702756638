import { t } from '@lingui/macro';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { TO_REL } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { languageSelector } from 'state/recoilState';
import { HeadingTip } from '../HeadingTip';

import { AdvancedDeleteFaceModels } from './components/AdvancedDeleteFaceModels';
import { AdvancedDeleteOrganization } from './components/AdvancedDeleteOrganization';
import { AdvancedDeletePhotosForm } from './components/AdvancedDeletePhotosForm';
import { AdvancedModalRoutes } from './components/AdvancedModalRoutes/AdvancedModalRoutes';

export const Advanced = (): React.ReactElement => {
  const { betaAccessOptIn } = useAppPermissions();
  const language = useRecoilValue(languageSelector);

  return (
    <>
      <HeadingTip
        label={t({
          id: 'advanced_settings.heading.advanced',
          message: 'Advanced',
        })}
      />

      <Flex variant="settings.container.vertical.main">
        {/* FIXME: this is commented out but may be used in feature in case new beta features are introduced */}
        {/* <AdvancedBetaOptIn /> */}
        <AdvancedDeletePhotosForm
          linkButtonWithLoadingProps={{
            to: TO_REL.DELETE_PHOTOS_MODAL[language],
            state: { preventClose: true },
          }}
        />

        {betaAccessOptIn.faceRecognition && (
          <AdvancedDeleteFaceModels
            linkButtonWithLoadingProps={{
              to: TO_REL.DELETE_AI_FACE_MODAL[language],
              state: { preventClose: true },
            }}
          />
        )}
        <AdvancedDeleteOrganization />
        <AdvancedModalRoutes />
      </Flex>
    </>
  );
};
