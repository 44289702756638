import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Container, ThemeUIStyleObject } from 'theme-ui';
import { useTimer } from 'use-timer';

import { SnackbarProps } from 'components/Snackbar/types';
import { THEME_NAVBAR_HEIGHT } from 'styles/theme/base';
// eslint-disable-next-line no-restricted-imports
import { MemoizedSnackbar } from '../../../components/Snackbar/Snackbar';

import { snackbarPublisher } from './publisher';

let snackbarRoot = document.getElementById('snackbar-root');

if (!snackbarRoot) {
  snackbarRoot = document.createElement('div');
  snackbarRoot.setAttribute('id', 'snackbar-root');
}

const AnimatedContainer = motion(Container);

const snackbarPosition = `${parseInt(THEME_NAVBAR_HEIGHT, 10) + 10}px`;

const containerSx: ThemeUIStyleObject = {
  position: 'fixed',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
};

export const SNACKBAR_DEFAULT_DURATION = 3000;

export const SnackbarHub = (): React.ReactElement | null => {
  const [snackbars, setSnackbars] = useState<SnackbarProps[]>([]);

  const visibleSnackbar = snackbars[0];
  const displayTime = (visibleSnackbar?.duration || SNACKBAR_DEFAULT_DURATION) / 1000;

  const removeSnackbar = useCallback(() => {
    setSnackbars((prevState) => prevState.slice(1));
  }, []);

  const { start, pause, reset, advanceTime } = useTimer({
    endTime: displayTime,
    onTimeOver: removeSnackbar,
  });

  const addSnackbar = useCallback(
    (snackbar: SnackbarProps): void => {
      const lastSnackbar = snackbars[snackbars.length - 1];

      if (_.isEqual(snackbar, visibleSnackbar) && !visibleSnackbar.isStatic) {
        reset();
        start();
        return;
      }

      if (_.isEqual(snackbar, lastSnackbar)) {
        return;
      }

      setSnackbars([...snackbars, snackbar]);
    },
    [snackbars, visibleSnackbar, start, reset],
  );

  const removeAllSnackbars = useCallback(() => {
    setSnackbars([]);
  }, []);

  snackbarPublisher.register({ addSnackbar, removeSnackbar, removeAllSnackbars });

  useEffect(() => {
    if (!snackbars.length) return;

    if (!visibleSnackbar?.isStatic) {
      reset();
      start();
      if (snackbars.length > 1 && !visibleSnackbar?.isUnreplacable) {
        advanceTime(displayTime - 1);
      }
    }
  }, [
    snackbars.length,
    start,
    reset,
    advanceTime,
    displayTime,
    visibleSnackbar?.isStatic,
    visibleSnackbar?.isUnreplacable,
  ]);

  const onMouseEnter = () => {
    if (!visibleSnackbar.isStatic && visibleSnackbar.duration) pause();
  };
  const onMouseLeave = () => {
    if (!visibleSnackbar.isStatic && visibleSnackbar.duration) start();
  };

  const placementSx =
    visibleSnackbar?.placement === 'bottom' ? { bottom: snackbarPosition } : { top: snackbarPosition };
  const animationStartingPosition = visibleSnackbar?.placement === 'bottom' ? 400 : -400;

  return (
    snackbarRoot &&
    createPortal(
      <AnimatePresence>
        {visibleSnackbar && (
          <AnimatedContainer
            key={`${visibleSnackbar.message}`}
            initial={{ opacity: 0, y: animationStartingPosition }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: animationStartingPosition, transition: { duration: 0.15 } }}
            layout
            sx={{ ...containerSx, ...placementSx }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <MemoizedSnackbar {...visibleSnackbar} removeSnackbar={removeSnackbar} />
          </AnimatedContainer>
        )}
      </AnimatePresence>,
      snackbarRoot,
    )
  );
};
