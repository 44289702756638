import isEmpty from 'lodash/isEmpty';

import {
  DayDetailsRequestType,
  DurationType,
  EmployeeWorkdayAvailabilityData,
} from 'api/actions/calendar/calendarActions.types';
import { ScheduleData } from 'api/actions/requests/requestsActions.types';

type ExtendIsDaysEmptyType = {
  requests?: Array<{ duration: DurationType } & DayDetailsRequestType>;
  schedules?: ScheduleData[];
  workdayAvailability?: EmployeeWorkdayAvailabilityData;
};

/**
 * Checking deleted events in schedules and requests. Omit workdayAvailability if exists.
 */

export const isDaysEmpty = <D extends ExtendIsDaysEmptyType>(days: Map<number, D | null>) =>
  [...days.values()].every((day) => {
    if (day && (day.schedules || day.requests)) {
      const { workdayAvailability, schedules, requests, ...restDayDetails } = day || {};
      const notDeletedSchedules = schedules?.filter(
        ({ isDeleted, isPublished }) => !isDeleted || (isDeleted && !isPublished),
      );
      const notDeletedRequests = requests?.filter(({ isDeleted }) => !isDeleted);
      const newDay = {
        ...restDayDetails,
        ...(!!notDeletedSchedules?.length && { notDeletedSchedules }),
        ...(!!notDeletedRequests?.length && { notDeletedRequests }),
      };
      return isEmpty(newDay);
    }
    const { workdayAvailability, ...newDay } = day || {};
    return isEmpty(newDay);
  });
