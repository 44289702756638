import React from 'react';
import { useRecoilValue } from 'recoil';

import { initialFaceScanStepAtom, InitialFaceScanSteps } from 'Kiosk/state/initialFaceScanState';

import { StepStart } from './steps/StepStart';
import { StepScan } from './steps/StepScan';
import { StepSummary } from './steps/StepSummary';

type Props = {
  name: string;
  qrCode: string;
};

export const InitialFaceScan = ({ name, qrCode }: Props): React.ReactElement => {
  const step = useRecoilValue(initialFaceScanStepAtom);

  return (
    <>
      {step === InitialFaceScanSteps.START && <StepStart name={name} />}
      {step === InitialFaceScanSteps.SCAN && <StepScan qrCode={qrCode} />}
      {step === InitialFaceScanSteps.SUMMARY && <StepSummary />}
    </>
  );
};
