/** @jsxImportSource theme-ui */
import { useLingui } from '@lingui/react';
import hash from 'object-hash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { FormProvider, useForm } from 'react-hook-form';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  requestGetTimeTrackingToUpdateAction,
  requestSingleGetToUpdateAction,
} from 'api/actions/requests/requestsActions';
import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { useModal } from 'components/Modal/output/useModal';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { useMount } from 'hooks/useMount/useMount';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { MinimizeModalAddRequest } from 'state/modal';
import { addRequestFormStepStateAtom, getRequestFormValuesAtom } from 'state/requests';
import { EventType } from 'state/selectOptions';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { AddRequestFormState } from '../../../../types';
import { useAddRequest } from '../../hooks/useAddRequest';

import { AddRequestForms } from './components/AddRequestForms';
import { useSubmitAddRequest } from './hooks/useSubmitAddRequest';

export const AddRequestStep3 = React.forwardRef<HTMLFormElement>((props, ref): React.ReactElement => {
  useLingui();

  const { query } = useClient();
  const { handleClose } = useModal();
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();
  const {
    addRequestState: { requestType },
    modifyRequest: { setModifyRequestData },
    requestConfig: { requestConfigState },
    requestLimits: { resetRequestLimits },
    requestFormRestored: { defaultValuesRestored },
    setRequestFormRestored,
  } = useAddRequest();
  const resetAddRequestFormsState = useResetRecoilState(addRequestFormStepStateAtom);
  const setGetRequestFormValues = useSetRecoilState(getRequestFormValuesAtom);
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddRequest>();

  const [requestToEditIsFetching, setRequestToEditIsFetching] = useState(false);

  const { handleSubmitCallback, handleFormErrorCallback } = useSubmitAddRequest();

  const formDefaultValues = useMemo(() => {
    const formState = minimizedModalDetails?.modalDetails?.formState;

    if (!formState || defaultValuesRestored) {
      return {
        actionType: (() => {
          if (requestConfigState?.action) return requestConfigState.action;
          if (requestType === RequestFormType.Schedule) return RequestActionType.Swap;
          return RequestActionType.Create;
        })(),
        ...(requestConfigState?.defaultValues && requestConfigState.defaultValues),
      };
    }

    const { details, ...rest } = formState;

    const { typeId, isEnd, ...restDetails } = details || {};

    if (requestType !== RequestFormType.TimeTracking) {
      return formState;
    }

    const status: EventType = { id: typeId || '', isEndStatus: isEnd || false };
    const statusOptionId = hash(status);

    return {
      details: {
        typeId: statusOptionId,
        isEnd,
        ...restDetails,
      },
      ...rest,
    };
  }, [minimizedModalDetails?.modalDetails?.formState, requestConfigState, defaultValuesRestored, requestType]);

  const methods = useForm<AddRequestFormState>({
    defaultValues: formDefaultValues,
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const getRequestToModify = useCallback(async () => {
    if (!requestConfigState?.requestManagement) return;

    setRequestToEditIsFetching(true);
    const { id, isEvent } = requestConfigState.requestManagement;

    const { payload, error } = await query(
      isEvent ? requestGetTimeTrackingToUpdateAction({ id }) : requestSingleGetToUpdateAction({ id }),
    );

    if (!error && payload) {
      setModifyRequestData(payload);
    }

    if (error) {
      handleClose(true);
    }

    setRequestToEditIsFetching(false);
  }, [handleClose, query, requestConfigState?.requestManagement, setModifyRequestData]);

  useEffect(() => {
    void getRequestToModify();
  }, [getRequestToModify]);

  useEffect(
    () => () => {
      resetAddRequestFormsState();
      resetRequestLimits();
    },
    [resetAddRequestFormsState, resetRequestLimits],
  );

  useEffect(() => {
    setGetRequestFormValues(() => methods.getValues);
  }, [methods.getValues, setGetRequestFormValues]);

  useMount(() => {
    if (!defaultValuesRestored) {
      setRequestFormRestored((prev) => ({ ...prev, defaultValuesRestored: true }));
    }
  });

  return (
    <FormProvider {...methods}>
      <form
        ref={ref}
        onSubmit={floatingPromiseReturn(methods.handleSubmit(handleSubmitCallback, handleFormErrorCallback))}
        sx={{ display: 'flex', flex: '1', maxWidth: '100%', ...(!isSmartphoneBreakpoint && { overflowY: 'hidden' }) }}
      >
        {!requestToEditIsFetching ? <AddRequestForms /> : <CenteredLoadingSpinner size={4} />}
      </form>
    </FormProvider>
  );
});
