/** @jsxImportSource theme-ui */
import { Trans } from '@lingui/macro';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Label, Text } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';

import { validateBlobFiles } from './helpers';
import { StyleVariant, UploaderProps } from './types';

// TODO: fn shared with Modal.tsx, can be abstracted to helper fn
const getInstanceNumber = (() => {
  let counter = 0;
  return () => {
    counter += 1;
    return counter;
  };
})();

export const Uploader = ({
  blobFiles,
  serverFiles,
  setBlobFiles,
  maxSize,
  minSize,
  acceptExt,
  maxFiles,
}: UploaderProps): React.ReactElement => {
  const [variant, setVariant] = useState<StyleVariant>('default');

  // remove bug: component flickers on dragover
  const dragOnLabelRef = useRef<EventTarget | null>(null);

  const { addSnackbar } = useSnackbar();

  const instanceNumber = useMemo(getInstanceNumber, []);

  const uploadFlow = useCallback(
    (pickedBlobFilesFileList: FileList) => {
      const pickedBlobFiles = Array.from(pickedBlobFilesFileList);
      const validatedBlobFiles = validateBlobFiles({
        pickedFiles: pickedBlobFiles,
        existingFiles: blobFiles,
        serverFiles,
        addSnackbar,
        maxSize,
        minSize,
        acceptExt,
        maxFiles,
      });
      if (!validatedBlobFiles?.length) return;
      setBlobFiles((prevBlobFiles) => (prevBlobFiles ? [...prevBlobFiles, ...validatedBlobFiles] : validatedBlobFiles));
    },
    [acceptExt, addSnackbar, blobFiles, maxFiles, maxSize, minSize, serverFiles, setBlobFiles],
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setVariant('default');
      const { target } = e;
      if (target.files) uploadFlow(target.files);
      // fix: onChange doesn't fire on same file re-upload attempt
      e.target.value = '';
    },
    [uploadFlow],
  );

  const handleDragEnter = useCallback((e: React.DragEvent) => {
    dragOnLabelRef.current = e.target;
  }, []);

  const handleFilesDragover = useCallback((e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setVariant('droppable');
  }, []);

  const handleFilesDrop = useCallback(
    (e: React.DragEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (e.dataTransfer === null) return;
      setVariant('default');
      uploadFlow(e.dataTransfer.files);
    },
    [uploadFlow],
  );

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    if (dragOnLabelRef.current !== e.target) return;
    setVariant('default');
  }, []);

  const iconType = useMemo(() => (variant === 'default' ? 'plus' : 'import'), [variant]);

  return (
    <Label
      htmlFor={`uploader-${instanceNumber}`}
      variant="fileUpload.uploader"
      data-state={variant}
      onDragEnter={handleDragEnter}
      onDragOver={handleFilesDragover}
      onDrop={handleFilesDrop}
      onDragLeave={handleDragLeave}
    >
      <input
        type="file"
        multiple={maxFiles !== 1}
        onChange={handleInputChange}
        id={`uploader-${instanceNumber}`}
        style={{ width: 0, opacity: 0, position: 'absolute' }}
      />

      <Icon size={22} type={iconType} className="icon" wrapperSx={{ variant: 'fileUpload.icon' }} />
      <Text className="heading">
        <Trans id="file_upload.heading">
          <Text>Choose a file</Text>
          <Text> or </Text>
          <Text>drop it here</Text>
        </Trans>
      </Text>
    </Label>
  );
};
