import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { WAIT_FOR_FORM_LOGIC, useMinimizedModals } from 'components/Modal/output/useMinimizedModals';
import { useModal } from 'components/Modal/output/useModal';
import { ListNames } from 'components/StickyList/types';
import { TO_REL } from 'constants/routes';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { usePickTeammates } from 'hooks/usePickTeammates/usePickTeammates';
import { FilterGroupNames } from 'layouts/AuthorizedApp/AsideFilters/types';
import { parsedEmployeesSelector } from 'state/employees';
import { SEARCH_FILTER_TYPE, filterGroupStateAtomFamily, searchFilterValueSelectorFamily } from 'state/filters';
import { MinimizeModalAddEvents, MinimizedModal, ViewWithMinimizedModals } from 'state/modal';
import { delay } from 'utils/delay';

import { useAddEvents } from './useAddEvents';

export const useMinimizeModalAddEvents = (isMinimal?: boolean, isWizard?: boolean) => {
  useLingui();
  const [selectedTeammatesIds, setSelectedTeammatesIds] = usePickTeammates(ListNames.CLOCK_LOG_PICK_TEAMMATES);
  const [teammatesFilterState, setTeammatesFilterState] = useRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES_PICK_TEAMMATES_FILTERS),
  );
  const [tagsFilterState, setTagsFilterState] = useRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.TAGS_PICK_TEAMMATES_FILTERS),
  );
  const [rolesFilterState, setRolesFilterState] = useRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.ROLES_PICK_TEAMMATES_FILTERS),
  );
  const [searchInputState, setSearchInputState] = useRecoilState(
    searchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.TEAMMATES_PICK_TEAMMATES_FILTERS),
  );
  const parsedEmployees = useRecoilValue(parsedEmployeesSelector);
  const { state } = useLocation();
  const { user, unix } = useParams<{ user: string; unix: string }>();

  const { isReportsAttendanceList, isReportsTimesheets } = useAppRouting([
    'isReportsAttendanceList',
    'isReportsTimesheets',
  ]);
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddEvents>();
  const minimizedView = useMemo(() => {
    if (isReportsTimesheets) {
      return ViewWithMinimizedModals.TIMESHEETS;
    }

    if (isReportsAttendanceList) {
      return ViewWithMinimizedModals.ATTENDANCE;
    }

    return ViewWithMinimizedModals.CLOCK_LOG;
  }, [isReportsAttendanceList, isReportsTimesheets]);
  const { getFormValues, step, setStep } = useAddEvents();
  const { handleSetModalDetails, onHandleCloseModalWithMinimizedDetails } =
    useMinimizedModals<MinimizeModalAddEvents>(minimizedView);

  const onHandleMinimize = useCallback(
    async (minimizeModal: () => void) => {
      // @see useMinimizedModals.ts
      await delay(WAIT_FOR_FORM_LOGIC);
      const addEvents = getFormValues ? cloneDeep(getFormValues()) : undefined;

      const minimizedModal = isWizard
        ? MinimizedModal.EVENT_WIZARD
        : state?.minimizedModalType || MinimizedModal.ADD_EVENTS;

      handleSetModalDetails({
        minimizedModal: isMinimal ? MinimizedModal.ADD_EVENTS_FOR : minimizedModal,
        route: isWizard ? TO_REL.EVENT_WIZARD_MODAL : TO_REL.ADD_EVENTS_MODAL,
        title: isWizard ? t({ id: 'clock_log.wizard' }) : t({ id: 'clock_log.add_time_events' }),
        modalDetails: {
          employees: selectedTeammatesIds,
          step,
          addEvents,
          filterState: {
            teammatesFilterState,
            rolesFilterState,
            tagsFilterState,
            searchInputState,
          },
        },
        routeState: { id: user, ...(unix && { addTimeEvent: { dateUnix: +unix } }), ...state },
        employeesToValidate: selectedTeammatesIds,
      });

      minimizeModal();
    },
    [
      getFormValues,
      isWizard,
      state,
      handleSetModalDetails,
      isMinimal,
      selectedTeammatesIds,
      step,
      teammatesFilterState,
      rolesFilterState,
      tagsFilterState,
      searchInputState,
      user,
      unix,
    ],
  );

  useModal({
    onHandleMinimize,
    onHandleClose: onHandleCloseModalWithMinimizedDetails,
  });

  const restoreMinimizedState = useCallbackRef(() => {
    if (!minimizedModalDetails || !parsedEmployees) return;

    const { employees, step: storedStep, filterState } = minimizedModalDetails.modalDetails;

    const filteredEmployees = employees.filter((id) => parsedEmployees.has(id));

    setSelectedTeammatesIds(filteredEmployees);
    setStep(storedStep);
    setTeammatesFilterState(filterState.teammatesFilterState);
    setRolesFilterState(filterState.rolesFilterState);
    setTagsFilterState(filterState.tagsFilterState);
    setSearchInputState(filterState.searchInputState);
  });

  useEffect(() => {
    restoreMinimizedState.current();
  }, [restoreMinimizedState]);
};
