import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { userInfoAction } from 'api/actions/settings/settingsActions';
import { UserInfoActionProps } from 'api/actions/settings/settingsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { PROFILE_RESET } from 'constants/settings';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { blockTransitionAtom, resetFormButtonAtom } from 'state/settings';
import { useProfileSettings } from '../../../hooks/useProfileSettings';

type Props = {
  handleFormReset: () => void;
  getCurrentFormState: () => UserInfoActionProps;
};

export const useSubmitProfileSettings = ({ handleFormReset, getCurrentFormState }: Props) => {
  useLingui();
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const [{ isRequestPending }, setBlockTransition] = useRecoilState(blockTransitionAtom);
  const { profileSettings, setProfileSettings, getParsedProfileSettings } = useProfileSettings();
  const { mutate } = useMutation(userInfoAction);
  const isRequestPendingRef = useCallbackRef(isRequestPending);
  const getCurrentFormStateRef = useCallbackRef(getCurrentFormState);

  const userProfileResetObject = useMemo(() => ({ name: PROFILE_RESET, callback: handleFormReset }), [handleFormReset]);

  const handleSubmitCallback = useCallback(
    async (data: UserInfoActionProps) => {
      if (!profileSettings) return;

      const submittedFormState = data;

      const parsedData = getParsedProfileSettings(submittedFormState);
      const parsedProfileSettings = getParsedProfileSettings(profileSettings);

      if (_.isEqual(parsedData, parsedProfileSettings) && !isRequestPendingRef.current) {
        setBlockTransition((prevState) => ({ ...prevState, blockTransition: false }));
        setResetCallbacks(null);
        return;
      }

      setResetCallbacks(null);
      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: true }));

      const formStateBeforeRequest = getCurrentFormStateRef.current();

      const { error, status } = await mutate(parsedData);

      const formStateAfterRequest = getCurrentFormStateRef.current();

      if (!_.isEqual(formStateBeforeRequest, formStateAfterRequest)) {
        return;
      }

      if (!error) {
        setProfileSettings(parsedData);
        addSnackbar({
          message: t({
            id: 'settings.forms.submit_success',
            message: 'Changes saved',
          }),
          variant: 'success',
        });
      }

      if (error) {
        if (!status) {
          return;
        }
        setResetCallbacks((prevState) =>
          !prevState ? [userProfileResetObject] : [...prevState, userProfileResetObject],
        );
        return;
      }

      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: false, blockTransition: false }));
    },
    [
      getCurrentFormStateRef,
      getParsedProfileSettings,
      isRequestPendingRef,
      mutate,
      profileSettings,
      setBlockTransition,
      setProfileSettings,
      setResetCallbacks,
      userProfileResetObject,
    ],
  );

  const handleErrorCallback = useCallback(() => {
    setResetCallbacks((prevState) => (!prevState ? [userProfileResetObject] : [...prevState, userProfileResetObject]));
  }, [setResetCallbacks, userProfileResetObject]);

  return {
    handleSubmitCallback,
    handleErrorCallback,
  };
};
