import { ServiceIntegration } from '../integrations/integrationActions.types';

export type ExportIntegrationOptionsActionProps = {
  integration: number;
};

export type ExportIntegrationOptionsResponse = {
  headers: Record<
    ExportOptionsHeadersGroups,
    {
      id: string;
      name: string;
      description?: string;
      selected: boolean;
      archived?: boolean;
    }[]
  >;
  integrationType: ServiceIntegration;
};

export type GetExportOptionsActionProps = {
  exportDataType: ExportDataType;
  exportType: ExportType;
};

export type GetExportOptionsResponse = {
  headers: Record<
    ExportOptionsHeadersGroups,
    {
      id: string;
      name: string;
      selected: boolean;
      archived?: boolean;
    }[]
  >;
  exportDataType: ExportDataType;
  exportType: ExportType;
  groupByMonths: boolean;
};

export type ExportActionProps = {
  datesUnix: number[];
  peopleIds: string[];
  selectedColumns: string[];
  exportDataType: ExportDataType;
  exportType: ExportType;
  groupByMonth: boolean;
};

export type ExportResponse = {
  url: string;
};

export type ExportSelectedItemsActionProps = {
  selectedIds: string[];
  selectedColumns: string[];
  exportDataType: ExportDataType;
  exportType: ExportType;
};

export type ExportSelectedItemsResponse = {
  url: string;
};

export type ExportForIntegrationActionProps = {
  datesUnix: number[];
  peopleIds: string[];
  serviceIntegration: ServiceIntegration;
  selectedColumns?: string[];
};

export type ExportForIntegrationResponse = {
  url: string;
};

export type ExportTimeActivityPreviewActionProps = {
  range: number[];
  peopleIds: string[];
  type: ServiceIntegration;
};

export type EmployeesTimeActivity = {
  personId: string;
  integrationIdentity: string;
  displayName?: string;
  locationId?: string;
};

export type ExportTimeActivityPreviewResponse = {
  range: number[];
  employees: EmployeesTimeActivity[];
  type: ServiceIntegration;
  earningRates?: [
    {
      name: string;
      id: string;
    },
  ];
  canOverwriteExistingData: boolean;
};

export type ParsedExportedEmployees = Omit<EmployeesTimeActivity, 'personId'> & { id: string };

export type ExportTimeActivityActionProps = {
  range: number[];
  employees: EmployeesTimeActivity[];
  type: ServiceIntegration;
  overwriteExistingData: boolean;
  ordinaryTimeEarningsId?: string; // XERO
  overTimeEarningsId?: string; // XERO
};

export type ExportTimeActivityResponse = null;

export type ExportToICalUrlActionProps = {
  resetUrl: boolean;
};

export type ExportToICalUrlResponse = string;

export enum ExportDataType {
  Person = 0,
  TimeEvent = 1,
  TimeOff = 2,
  Schedule = 3,
  PresenceReport = 4,
  WorkReport = 5,
  DetailsReport = 6,
  TimeRegistrationReport = 7,
  Ncbr = 8,
  Requests = 9,
  CustomRequest = 10,
  BusinessTravels = 11,
  TimeOffYearly = 12,
  RequestsUsageOverview = 13,
}

export enum ExportType {
  Csv = 0,
  Xlsx = 1,
  Pdf = 2,
}

export enum ExportOptionsHeadersGroups {
  Base = 'base',
  TimeOffType = 'timeOffTypes',
  TimeEventType = 'timeEventTypes',
  Additional = 'additionalPreference',
  CustomRequestType = 'customRequestTypes',
}
