import { SnackbarProps } from 'components/Snackbar/types';

interface PublisherStore {
  addSnackbar: (snackbar: SnackbarProps) => void | null;
  removeSnackbar: (() => void) | undefined;
  register: (param: RegisterParams) => void;
}

interface RegisterParams {
  addSnackbar: (snackbar: SnackbarProps) => void;
  removeSnackbar: () => void;
  removeAllSnackbars: () => void;
}

class Publisher implements PublisherStore {
  constructor() {
    this.add = null;
  }

  private add: ((snackbar: SnackbarProps) => void) | null;

  public addSnackbar(snackbar: SnackbarProps): void | null {
    if (!this.add) return null;
    return this.add(snackbar);
  }

  public removeSnackbar: (() => void) | undefined;

  public removeAllSnackbars: (() => void) | undefined;

  public register({ addSnackbar, removeSnackbar, removeAllSnackbars }: RegisterParams): void {
    this.add = addSnackbar;
    this.removeSnackbar = removeSnackbar;
    this.removeAllSnackbars = removeAllSnackbars;
  }
}

export const snackbarPublisher = new Publisher();
