import React from 'react';

import { Layout } from 'layouts/BaseLayout';
import { AppRoutes } from 'routing/AppRoutes';

export const App = (): React.ReactElement => (
  <Layout>
    <AppRoutes />
  </Layout>
);
