import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';

import { FancyDataBox } from 'pages/Reports/output/FancyDataBox';
import { getTimersTitleMap } from 'pages/Reports/output/TimersRenderer';
import { parsedYourStatsSelector } from 'state/home';
import { formattedDurationFromSeconds } from 'utils/dateTime';

export const YourStatsTimers = () => {
  const stats = useRecoilValue(parsedYourStatsSelector);

  const statsTitleMap = {
    ...getTimersTitleMap(),
    schedulesTime: t({ id: 'reports.scheduled_time' }),
  };

  useLingui();

  const renderTimers = () =>
    _.map(stats, (prop, index) => (
      <FancyDataBox
        key={`${index}${prop}`}
        title={statsTitleMap[index as keyof typeof stats]}
        value={prop ? formattedDurationFromSeconds(prop) : '0min'}
        sx={{
          fontSize: 3,
          bg: 'home.yourStatsTimers',
        }}
      />
    ));

  return <>{renderTimers()}</>;
};
