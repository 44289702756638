import React, { FC, useCallback } from 'react';

import { Icon } from 'components/Icon/Icon';
import { Button, ButtonProps } from 'components/ui/Buttons';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';

type Props = ButtonProps & { onCloseRoute?: string };

export const CloseButton: FC<Props> = React.memo(({ onCloseRoute, sx, ...props }) => {
  const navigate = useAppNavigate();

  const handleClose = useCallback(() => {
    if (onCloseRoute) {
      navigate(onCloseRoute);
    }
  }, [onCloseRoute, navigate]);

  return (
    <Button
      onClick={handleClose}
      variant="grey"
      shape="rounded"
      size="sm"
      sx={{
        ...(sx && sx),
      }}
      {...props}
    >
      <Icon type="x" />
    </Button>
  );
});
