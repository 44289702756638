export const GUTTER_DEFAULT_SIZE = 2;

export const ROW_DEFAULT_HEIGHT = 46;
export const COLUMN_DEFAULT_WIDTH = 98;
export const ITEM_DEFAULT_HEIGHT = GUTTER_DEFAULT_SIZE + ROW_DEFAULT_HEIGHT;
export const ITEM_DEFAULT_WIDTH = GUTTER_DEFAULT_SIZE + COLUMN_DEFAULT_WIDTH;

export const DROPDOWN_BUTTON_CELL_WIDTH = 37;

const GRID_ROW_DEFAULT_HEIGHT = 62;
export const GRID_DEFAULT_HEIGHT = GUTTER_DEFAULT_SIZE + GRID_ROW_DEFAULT_HEIGHT;
