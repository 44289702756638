import { Trans } from '@lingui/macro';
import React, { CSSProperties } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { Avatar } from 'components/Avatar/Avatar';
import { Modal } from 'components/Modal/output/Modal';
import { ListNames } from 'components/StickyList/types';
import { FullName } from 'components/utils/FullName';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { usePickTeammates } from 'hooks/usePickTeammates/usePickTeammates';
import { parsedEmployeesSelector } from 'state/employees';

const ITEM_SIZE_DEFAULT = 33;

type InnerElementProps = {
  style: CSSProperties;
};

type Props = {
  listName: ListNames;
  appendWithRenderer?: (employeeId: string) => React.ReactNode | React.ReactNode[];
};

export const SelectedPersonsList = React.memo(({ listName, appendWithRenderer }: Props): React.ReactElement => {
  const parsedEmployees = useRecoilValue(parsedEmployeesSelector);
  const [selectedTeammatesIds] = usePickTeammates(listName);
  const getFullName = useNameDisplayOrder();

  const rowRenderer = ({ index, style }: ListChildComponentProps) => {
    if (!parsedEmployees) return null;

    const employeeId = selectedTeammatesIds[index];
    const employee = parsedEmployees.get(employeeId);

    if (!employee) return null;

    const { avatarUrl, name } = employee;

    const isFirst = index === 0;
    const isLast = index + 1 === selectedTeammatesIds.length;

    return (
      <Flex style={style}>
        <Flex
          sx={{
            gap: '0.375rem',
            border: `1px solid`,
            borderColor: 'requests.selectedPersonsList',
            flexGrow: 1,
            alignItems: 'center',
            p: '0.25rem',
            ...(!isFirst && { borderTop: 'none' }),
            ...(isFirst && { borderTopLeftRadius: 'sm', borderTopRightRadius: 'sm' }),
            ...(isLast && { borderBottomLeftRadius: 'sm', borderBottomRightRadius: 'sm' }),
          }}
        >
          <Flex sx={{ gap: '0.375rem' }}>
            <Avatar size={21} image={avatarUrl} name={name} />
            <TextEllipsis title={getFullName(name.firstName, name.surname)} sx={{ fontSize: 2 }}>
              <FullName firstName={name.firstName} surname={name.surname} />
            </TextEllipsis>
          </Flex>
          <Flex sx={{ ml: 'auto', flexShrink: 0 }}>{appendWithRenderer && appendWithRenderer(employeeId)}</Flex>
        </Flex>
      </Flex>
    );
  };

  const innerElementType = React.forwardRef<HTMLDivElement, InnerElementProps>(
    ({ style, ...rest }: InnerElementProps, ref) => (
      <div
        ref={ref}
        style={{
          position: 'relative',
          ...style,
        }}
        {...rest}
      />
    ),
  );

  return (
    <Flex sx={{ flexDirection: 'column', width: '100%', flexGrow: 1 }}>
      <Modal.SubTitle>
        <Trans id="selected_person_list.for_teammates">For teammates</Trans>
      </Modal.SubTitle>
      <Flex
        sx={{
          flexGrow: 1,
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              itemSize={ITEM_SIZE_DEFAULT}
              innerElementType={innerElementType}
              height={height}
              itemCount={selectedTeammatesIds.length}
              width={width}
              overscanCount={Math.floor(height / ITEM_SIZE_DEFAULT)}
              style={{
                paddingBottom: '0.75rem',
                paddingLeft: '1px',
                paddingRight: '1px',
              }}
            >
              {rowRenderer}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Flex>
    </Flex>
  );
});
