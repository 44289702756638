import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Flex } from 'theme-ui';

import { DropdownLinks } from 'components/Dropdown/types';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { MinimizedModal, ViewWithMinimizedModals } from 'state/modal';
import { useMinimizedModals } from '../hooks/useMinimizedModals';

const minimizeRoot = document.getElementById('minimize-modal-root');

const ButtonWithPopper = withDropdown(Button);

type Props = {
  forView: ViewWithMinimizedModals;
};

export const MinimizedModalsProvider = ({ forView }: Props): React.ReactElement | null => {
  const { isMobileBreakpoint } = useThemeBreakpoint();
  const { restoreMinimizedModal, minimizedModalsDetails } = useMinimizedModals(forView);

  const minimizeBarRenderer = useCallback(
    (title: string, minimizedModal: MinimizedModal, index: number) => (
      <Button
        key={`${title}${index}`}
        variant="grayBlues"
        shape="rounded"
        onClick={() => void restoreMinimizedModal(minimizedModal)}
        omitSpanWrapper
        sx={{
          px: '0.75rem',
          py: '0.3125rem',
          width: '220px',
          justifyContent: 'flex-start',
          textAlign: 'left',
          flexGrow: 1,
        }}
      >
        <TextEllipsis>{title}</TextEllipsis>
      </Button>
    ),
    [restoreMinimizedModal],
  );

  const mobileView = useMemo(() => {
    const links: DropdownLinks = minimizedModalsDetails.map(({ minimizedModal, title }) => ({
      label: title,
      onClick: () => restoreMinimizedModal(minimizedModal),
      isButton: true,
      variant: 'grayBlues',
      sx: {
        px: '0.75rem',
        py: '0.3125rem',
      },
    }));

    return (
      <ButtonWithPopper
        dropdownProps={{
          links,
          sx: { bg: 'none', p: 0, borderRadius: '0', boxShadow: 'none', width: '220px', ml: 0, gap: 1 },
        }}
        shape="pill"
        variant="grayBlues"
        popperProps={{ placement: 'top', popperMargin: 0.325 }}
        sx={{ p: 1, mt: '0.625rem' }}
      >
        <Icon type="minimize" size={40} wrapperSx={{ color: 'whites0' }} />
      </ButtonWithPopper>
    );
  }, [minimizedModalsDetails, restoreMinimizedModal]);

  if (_.isEmpty(minimizedModalsDetails)) return null;

  return (
    minimizeRoot &&
    createPortal(
      <Flex
        sx={{
          position: 'fixed',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        {isMobileBreakpoint && minimizedModalsDetails.length > 1 ? (
          <Flex sx={{ position: 'fixed', bottom: 76, flexDirection: 'column', gap: 3 }}>{mobileView}</Flex>
        ) : (
          <Flex sx={{ position: 'fixed', bottom: isMobileBreakpoint ? 76 : 50, gap: 3, maxWidth: '100%', px: 3 }}>
            {minimizedModalsDetails.map(({ minimizedModal, title }, index) =>
              minimizeBarRenderer(title, minimizedModal, index),
            )}
          </Flex>
        )}
      </Flex>,
      minimizeRoot,
    )
  );
};
