import { useLingui } from '@lingui/react';
import { FC, memo, useMemo } from 'react';
import { Control, UseFormRegister, UseFormTrigger, useFieldArray, useFormState, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { FlexProps } from 'theme-ui';
import { v1 as uuidv1 } from 'uuid';

import {
  EmployeeRequestLimitsFieldNames,
  EmployeeRequestLimitsFormState,
  ParsedCustomRequestLimit,
  defaultCustomRequestLimitGroupSelector,
} from 'state/team';
import { FieldArrayManager, FieldArrayManagerProps } from '../FieldArrayManager/FieldArrayManager';
import { RequestLimitsBasicControl, RequestLimitsBasicFormState } from '../types';

import { NestedRequestLimitsFieldArray } from './components/NestedRequestLimitsFieldArray';

type BasicControl = RequestLimitsBasicControl;

type Props = {
  trigger: UseFormTrigger<EmployeeRequestLimitsFormState> | UseFormTrigger<RequestLimitsBasicFormState>;
  control: Control<EmployeeRequestLimitsFormState> | Control<RequestLimitsBasicFormState>;
  register: UseFormRegister<EmployeeRequestLimitsFormState> | UseFormRegister<RequestLimitsBasicFormState>;
  fieldArrayManagerProps?: Partial<FieldArrayManagerProps>;
} & FlexProps;

export const RequestLimits: FC<Props> = ({ trigger, control, register, fieldArrayManagerProps, sx }) => {
  useLingui();

  const defaultCustomRequestLimitGroup = useRecoilValue(defaultCustomRequestLimitGroupSelector);

  const { fields, remove, append, update } = useFieldArray<
    EmployeeRequestLimitsFormState,
    EmployeeRequestLimitsFieldNames.CustomRequestsLimits,
    'RHF_ID'
  >({
    control: control as BasicControl,
    name: EmployeeRequestLimitsFieldNames.CustomRequestsLimits,
    keyName: 'RHF_ID',
  });

  const { errors } = useFormState({ control: control as BasicControl });

  const watchRequestLimits = useWatch({
    control: control as BasicControl,
    name: EmployeeRequestLimitsFieldNames.CustomRequestsLimits,
  });

  const controlledFields = fields
    .map((field) => {
      const currentLimit = watchRequestLimits?.find(({ id }) => id === field.id);
      return {
        ...field,
        ...(currentLimit && currentLimit),
      };
    })
    .map(({ fromYear, ...rest }) => ({ fromYear: +fromYear, ...rest }));

  const getIndexByYear = (year: string) => controlledFields.findIndex(({ fromYear }) => `${fromYear}` === year);

  const requestsYears = useMemo(() => controlledFields.map(({ fromYear }) => fromYear), [controlledFields]);

  const updateNestedLimitFactory = (fieldIndex: number) => (index: number, value: ParsedCustomRequestLimit) => {
    const nestedLimits = controlledFields[fieldIndex].limits || [];
    update(fieldIndex, {
      ...controlledFields[fieldIndex],
      limits: nestedLimits.map((limit, i) => ({
        ...limit,
        ...(i === index && value),
      })),
    });
  };

  return (
    <FieldArrayManager
      sx={sx}
      variant="year"
      isDisabledGenerator={(year) => !!errors?.customRequestsLimits?.[getIndexByYear(year)]}
      onAdd={(year) => {
        if (!defaultCustomRequestLimitGroup) return;
        append({
          ...defaultCustomRequestLimitGroup,
          fromYear: +year,
          id: uuidv1(),
        });
      }}
      onEdit={(oldYear, year) => {
        const index = getIndexByYear(oldYear);
        update(index, { ...controlledFields[index], fromYear: +year });
      }}
      onRemove={(year) => {
        const index = getIndexByYear(year);
        remove(index);
      }}
      froms={requestsYears}
      fieldRenderer={(year) => {
        const index = getIndexByYear(year);
        if (!controlledFields[index]) return null;
        return (
          <NestedRequestLimitsFieldArray
            trigger={trigger}
            key={index}
            register={register}
            nestIndex={index}
            control={control}
            update={updateNestedLimitFactory(index)}
          />
        );
      }}
      {...(fieldArrayManagerProps && fieldArrayManagerProps)}
    />
  );
};

export const RequestLimitsGroupFieldArray = memo(RequestLimits);
