import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { ContentContainer } from './ContentContainer';
import { ContentSubTitle } from './ContentSubTitle';
import { ContentTitle } from './ContentTitle';

type ContentProps = FlexProps;

type ContentComponent = React.FC<ContentProps> & {
  Title: typeof ContentTitle;
  SubTitle: typeof ContentSubTitle;
  Container: typeof ContentContainer;
};

export const Content: ContentComponent = ({ children, sx, ...props }: ContentProps): React.ReactElement => (
  <Flex {...props} variant="layouts.authorized.aside.details.content" sx={{ ...(sx && sx) }}>
    {children}
  </Flex>
);

Content.Title = ContentTitle;
Content.SubTitle = ContentSubTitle;
Content.Container = ContentContainer;
