export const SUBMIT_THROTTLE = 750;
export const CHECK_BLOCKING_DELAY = 400;
export const DELETE_ORGANIZATION_FORM_ID = 'FORM_DELETE_ORGANIZATION';
export const DELETE_AI_FACES_FORM_ID = 'FORM_AI_FACES_ORGANIZATION';
export const DELETE_PHOTOS_FORM_ID = 'FORM_PHOTOS_ORGANIZATION';
export const DEFAULT_NOTIFICATIONS_RESET = 'DEFAULT_NOTIFICATIONS_RESET';
export const SCHEDULES_RESET = 'SCHEDULES_RESET';
export const REQUESTS_SETTINGS_RESET = 'REQUESTS_SETTINGS_RESET';
export const TIME_TRACKING_SETTINGS_RESET = 'TIME_TRACKING_SETTINGS_RESET';
export const AUTOMATIONS_SETTINGS_RESET = 'AUTOMATIONS_SETTINGS_RESET';
export const NOTIFICATIONS_RESET = 'NOTIFICATIONS_RESET';
export const PREFERENCES_RESET = 'PREFERENCES_RESET';
export const ORGANIZATION_RESET = 'ORGANIZATION_RESET';
export const PROFILE_RESET = 'PROFILE_RESET';
export const REFRESH_INTEGRATIONS_MESSAGE = 'REFRESH_INTEGRATIONS';
export const REFRESH_GOOGLE_INTEGRATIONS_MESSAGE = 'REFRESH_GOOGLE_INTEGRATIONS';
