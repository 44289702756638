import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

type ButtonsGroupProps = FlexProps;

const ButtonsGroup = ({ children, sx, ...props }: ButtonsGroupProps): React.ReactElement => (
  <Flex {...props} sx={{ gap: 1, alignItems: 'center', ...(sx && sx) }}>
    {children}
  </Flex>
);

type MenuComponent = React.FC<MenuProps> & {
  ButtonsGroup: typeof ButtonsGroup;
};

type MenuProps = FlexProps;

export const Menu: MenuComponent = ({ children, sx, ...props }: MenuProps): React.ReactElement => (
  <Flex
    as="nav"
    {...props}
    sx={{ flexGrow: 1, justifyContent: 'flex-end', gap: 2, alignItems: 'center', ...(sx && sx) }}
  >
    {children}
  </Flex>
);

Menu.ButtonsGroup = ButtonsGroup;
