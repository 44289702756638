import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { TO } from 'constants/routes';
import { IntegrationTypes, PopUpIntegration } from 'pages/Settings/output/PopUpIntegration';
import { languageSelector } from 'state/recoilState';

export const AuthorizedPopUpRenderer = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const params = useParams();

  const { type } = params;

  const isTypeValid = useMemo(() => {
    switch (type) {
      case 'quickbooks':
      case 'xero':
      case 'google':
      case 'square':
        return true;
      default:
        return false;
    }
  }, [type]);

  if (!isTypeValid) {
    return <Navigate to={TO.PAGE_NOT_FOUND[language]} />;
  }

  return <PopUpIntegration integration={type as IntegrationTypes} />;
};
