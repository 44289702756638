import { Trans } from '@lingui/macro';
import React, { useCallback, useEffect, useRef } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { googleLinkAction } from 'api/actions/integrations/integrationActions';
import { ExternalLoginActionSource } from 'api/actions/integrations/integrationActions.types';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { CredentialResponse } from 'hooks/useGoogleScript/google.types';
import { useGoogleScript } from 'hooks/useGoogleScript/useGoogleScript';
import { languageSelector } from 'state/recoilState';
import { useExternalIntegrations } from '../IntegrationsTiles/hooks/useExternalIntegrations';

export const GoogleLinkModal = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const googleButtonRef = useRef<HTMLDivElement>(null);

  const { handleClose } = useModal();
  const { query } = useClient();
  const { fetchExternalIntegrations } = useExternalIntegrations();

  const googleResponseCallback = useCallback(
    async (credentialResponse: CredentialResponse) => {
      if (credentialResponse.credential) {
        const { error } = await query(googleLinkAction({ googleToken: credentialResponse.credential }));

        if (!error) {
          await fetchExternalIntegrations(ExternalLoginActionSource.Link);
          handleClose();
        }
      }
    },
    [fetchExternalIntegrations, handleClose, query],
  );

  const google = useGoogleScript(googleResponseCallback);

  useEffect(() => {
    if (google && googleButtonRef.current) {
      google.accounts.id.renderButton(googleButtonRef.current, {
        type: 'standard',
        theme: 'filled_blue',
        size: 'large',
        shape: 'rectangular',
        text: 'continue_with',
        locale: `${language}_en`,
      });
    }
  }, [google, language]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="integrations.google_link.title">Link Google account</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body sx={{ gap: 4, alignItems: 'center' }}>
        <Text variant="p" as="p" sx={{ textAlign: 'center' }}>
          <Trans id="integrations.google_link.content">
            Connect your Google account to log in into the app using Google.
          </Trans>
        </Text>
        <Flex ref={googleButtonRef}>
          <Flex
            sx={{
              width: '200px',
              height: '40px',
              bg: 'settings.googleButton',
              borderRadius: 'xs',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingSpinnerCss size={1.5} />
          </Flex>
        </Flex>
      </Modal.Body>
    </>
  );
};
