import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { FancyDataBox } from 'pages/Reports/output/FancyDataBox';
import { parsedCurrentUserRequestLimitsSelector } from 'state/home';
import { formattedUsageAndLimits } from 'utils/formattedUsageAndLimits';
import { HomeBox } from '../../HomeBox/HomeBox';

export const RequestLimitsList = () => {
  const limits = useRecoilValue(parsedCurrentUserRequestLimitsSelector);

  useLingui();

  const renderTimeOffs = () =>
    _.map(limits?.timeOffs, ({ name, abbr, usageTimeDuration, totalTimeDuration }, index) => (
      <FancyDataBox
        key={`${index}${name}`}
        title={`${t({ id: name })} (${t({ id: abbr })})`}
        value={formattedUsageAndLimits(usageTimeDuration, totalTimeDuration)}
        sx={{
          fontSize: 3,
          bg: 'alphas.darker1',
          gap: 2,
          '>.content-wrapper': {
            flexShrink: 0,
          },
        }}
      />
    ));

  const renderCustoms = () =>
    _.map(limits?.customRequests, ({ name, abbr, usageTimeDuration, totalTimeDuration }, index) => (
      <FancyDataBox
        key={`${index}${name}`}
        title={`${t({ id: name })} (${t({ id: abbr })})`}
        value={formattedUsageAndLimits(usageTimeDuration, totalTimeDuration)}
        sx={{
          fontSize: 3,
          bg: 'home.requestLimits.bg',
          gap: 2,
          '>.content-wrapper': {
            flexShrink: 0,
          },
        }}
      />
    ));

  const isEmpty = useMemo(() => !(limits?.timeOffs.length || limits?.customRequests.length), [limits]);

  return (
    <Flex sx={{ flexDirection: 'column', gap: 4 }}>
      {isEmpty ? (
        <Text variant="pLead" sx={{ color: 'home.requestLimits.text' }}>
          <Trans id="home.request_limits.empty">Click "Show all" to see the full list.</Trans>
        </Text>
      ) : (
        <>
          {!!limits?.timeOffs.length && (
            <Flex sx={{ flexDirection: 'column', gap: '0.125rem' }}>
              <HomeBox.Subtitle>{t({ id: 'reports.group_names.time_offs' })}</HomeBox.Subtitle>
              {renderTimeOffs()}
            </Flex>
          )}
          {!!limits?.customRequests.length && (
            <Flex sx={{ flexDirection: 'column', gap: '0.125rem' }}>
              <HomeBox.Subtitle>{t({ id: 'reports.group_names.custom_requests' })}</HomeBox.Subtitle>
              {renderCustoms()}
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};
