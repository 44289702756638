import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { advancedSettingsDeleteOrganizationAction } from 'api/actions/settings/settingsActions';
import { AdvancedSettingsDeleteOrganizationActionProps } from 'api/actions/settings/settingsActions.types';
import { InputOption, Select } from 'components/ui/Select/Select';
import { TextInput } from 'components/ui/TextInput';
import { TO_REL } from 'constants/routes';
import { DELETE_ORGANIZATION_FORM_ID } from 'constants/settings';
import { languageSelector } from 'state/recoilState';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

import { AdvancedDeleteMain } from './AdvancedDeleteMain/AdvancedDeleteMain';

export const AdvancedDeleteOrganization = (): React.ReactElement => {
  useLingui();
  const { mutate, loading } = useMutation(advancedSettingsDeleteOrganizationAction);
  const language = useRecoilValue(languageSelector);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AdvancedSettingsDeleteOrganizationActionProps>();

  const passwordValue = watch('password');
  const reasonWatch = watch('reason');

  const optionsList: InputOption[] = useMemo(
    () => [
      {
        label: t({
          id: 'advanced_settings.select_options.lack_of_functionality',
          message: 'Lack of function',
        }),
        id: 'Brak funkcjonalności',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.application_price',
          message: 'Application price too expensive',
        }),
        id: 'Zbyt wysoka cena aplikacji',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.competing_application',
          message: 'We took advantage of the offer of a competing application',
        }),
        id: 'Skorzystaliśmy z oferty konkurencyjnej aplikacji',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.no_integration',
          message: 'No integration',
        }),
        id: 'Brak integracji',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.no_decision',
          message: 'No decision yet',
        }),
        id: 'Nie podjęliśmy jeszcze decyzji',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.account_incorrectly',
          message: 'I am an employee, I set up my account incorrectly',
        }),
        id: 'Jestem pracownikiem, błędnie założyłem konto',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.no_company',
          message: "I don't have a company",
        }),
        id: 'Nie mam firmy',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.end_project',
          message: 'End of the project',
        }),
        id: 'Zakończenie projektu',
      },
      {
        label: t({
          id: 'advanced_settings.select_options.seasonal_premises',
          message: 'Seasonal premises',
        }),
        id: 'Lokal sezonowy',
      },
    ],
    [],
  );

  const handleSubmitCallback = useCallback(
    async (data: AdvancedSettingsDeleteOrganizationActionProps) => {
      await mutate(data);
    },
    [mutate],
  );

  return (
    <AdvancedDeleteMain
      formId={DELETE_ORGANIZATION_FORM_ID}
      label={t({
        id: 'advanced_settings.heading.delete_organization',
        message: 'Delete whole organization',
      })}
      additionalLabel={t({
        id: 'advanced_settings.heading.additional_info',
      })}
      onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}
      linkButtonWithLoadingProps={{
        to: TO_REL.DELETE_ORGANIZATION_MODAL[language],
        state: { preventClose: true },
        disabled: !passwordValue || !reasonWatch.length || loading,
        isLoading: loading,
      }}
    >
      <Select
        {...register('reason', { required: true })}
        id="reason"
        placeholder={t({
          id: 'advanced_settings.delete_organization_reason.placeholder',
          message: 'Reason',
        })}
        error={!!errors.reason}
        errorMessage={errors.reason?.message}
        options={optionsList}
        searchable
        creatable
        autoComplete="new-password"
        size="sm"
        label={t({
          id: 'advanced_settings.delete_organization_reason.upper_text',
          message: 'Why do you want to delete your organization?',
        })}
        sx={{ maxWidth: '340px' }}
      />
      <TextInput
        {...register('password', {
          required: t({
            id: 'global.forms.required',
          }),
        })}
        id="deleteOrganizationPassword"
        placeholder={t({
          id: 'global.forms.inputs.password',
        })}
        size="sm"
        type="password"
        icon="lock"
        label={t({
          id: 'advanced_settings.type_your_password',
        })}
        error={!!errors.password}
        errorMessage={errors.password?.message}
        autoComplete="new-password"
        sx={{ maxWidth: '340px' }}
      />
    </AdvancedDeleteMain>
  );
};
