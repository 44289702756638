import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect } from 'react';
import { Link, Text } from 'theme-ui';

import { TimeTrackingSettingsActionProps } from 'api/actions/settings/settingsActions.types';
import { Switch } from 'components/ui/Switch';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';
import { useFormContextCustomEvent } from '../../../../../../../hooks/useFormContextCustomEvent';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';
import { wrapperLinkSx } from '../../../../../../styles/wrappers';

const TextWithTooltip = withTooltip(Text);

export const TimeClocks = (): React.ReactElement => {
  useLingui();
  const { betaAccessOptIn } = useAppPermissions();

  const enableOfflineModeLink = useHelpLink({
    inEwi: {
      pl: '/article/wymagania-techniczne-urzadzen-mobilnych-do-rejestracji-czasu-pracy-v3-bamw9d/',
    },
  });
  const faceRecognitionLink = useHelpLink({
    inEwi: {
      pl: '/article/uzyj-rozpoznawania-twarzy-1ossuaa/',
    },
    unrubble: {
      en: '/article/use-face-recognition-ai-smpf2n/',
    },
  });
  const basicFraudDetectionLink = useHelpLink({
    inEwi: {
      pl: '/article/uzyj-rozpoznawania-twarzy-1ossuaa/#3-uzyj-podstawowego-wykrywania-naduzyc',
    },
    unrubble: {
      en: '/article/use-face-recognition-ai-smpf2n/#3-use-basic-fraud-detection',
    },
  });
  const advancedFraudDetectionLink = useHelpLink({
    inEwi: {
      pl: '/article/uzyj-rozpoznawania-twarzy-1ossuaa/#3-uzyj-zaawansowanego-wykrywania-naduzyc',
    },
    unrubble: {
      en: '/article/use-face-recognition-ai-smpf2n/#3-use-advanced-fraud-detection',
    },
  });

  const { registerOnChange, watch, setValue } = useFormContextCustomEvent<TimeTrackingSettingsActionProps>();

  const [faceRecognitionWatch, basicFraudDetectionWatch, advancedFraudDetectionWatch, restrictLocations] = watch([
    'enableFaceVerification',
    'enableBasicFraudDetection',
    'enableAdvancedFraudDetection',
    'restrictLocations',
  ]);

  const onChangeBetaSetting = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setValue('sendEventPhotos', true);
        setValue('enableOfflineMode', false);
      }
    },
    [setValue],
  );

  useEffect(() => {
    if (restrictLocations) {
      setValue('forceLocation', true);
    }
  }, [restrictLocations, setValue]);

  return (
    <SettingsGroup variant="sm">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="time_tracking_settings.heading.time_clocks">Time Clocks</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <SettingsGroup.Body>
        <SettingsGroup.Body.Visible variant="md">
          {betaAccessOptIn.faceRecognition && (
            <Switch
              {...registerOnChange('enableFaceVerification', {
                onChange: onChangeBetaSetting,
              })}
              label={t({
                id: 'time_tracking_settings.face_recognition.label',
                message: 'Use Face Recognition',
              })}
              additionalInfo={
                <>
                  <Link href={faceRecognitionLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                    {t({ id: 'settings.learn_more' })}
                  </Link>
                  .
                </>
              }
              size="sm"
              bold
              withDivider
            />
          )}
          {betaAccessOptIn.fraudDetection && (
            <>
              <Switch
                {...registerOnChange('enableBasicFraudDetection', {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeBetaSetting(e);
                    setValue('enableAdvancedFraudDetection', false);
                  },
                })}
                label={t({
                  id: 'time_tracking_settings.basic_fraud_detection.label',
                  message: 'Use Basic Fraud Detection',
                })}
                additionalInfo={
                  <>
                    <Link
                      href={basicFraudDetectionLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ ...wrapperLinkSx }}
                    >
                      {t({ id: 'settings.learn_more' })}
                    </Link>
                    .
                  </>
                }
                size="sm"
                bold
                withDivider
              />
              <Switch
                {...registerOnChange('enableAdvancedFraudDetection', {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeBetaSetting(e);
                    setValue('enableBasicFraudDetection', true);
                  },
                })}
                label={t({
                  id: 'time_tracking_settings.advanced_fraud_detection.label',
                  message: 'Use Advanced Fraud Detection',
                })}
                additionalInfo={
                  <>
                    <Link
                      href={advancedFraudDetectionLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ ...wrapperLinkSx }}
                    >
                      {t({ id: 'settings.learn_more' })}
                    </Link>
                    .
                  </>
                }
                size="sm"
                bold
                withDivider
              />
            </>
          )}

          <ConditionalWrapper
            condition={faceRecognitionWatch || basicFraudDetectionWatch || advancedFraudDetectionWatch}
            wrapper={({ children }) => (
              <TextWithTooltip
                tooltipProps={{
                  content: betaAccessOptIn.fraudDetection
                    ? t({
                        id: 'time_tracking_settings.send_event_photos.disabled',
                        message:
                          'Photo Log is needed for Face Recognition and Fraud Detection so to turn it off you need to turn off these functions.',
                      })
                    : t({
                        id: 'time_tracking_settings.send_event_photos.disabled.no_fraud',
                        message:
                          'Photo Log is needed for Face Recognition so to turn it off you need to turn off that function.',
                      }),
                }}
              >
                {children}
              </TextWithTooltip>
            )}
          >
            <Switch
              {...registerOnChange('sendEventPhotos')}
              label={t({
                id: 'time_tracking_settings.send_event_photos.label',
                message: 'Save photos of all clock-ins and outs',
              })}
              additionalInfo={t({
                id: 'time_tracking_settings.send_event_photos.additional_info',
                message: 'All clock-ins in clock log will have a photo from the moment of the event.',
              })}
              size="sm"
              bold
              disabledButReadable={faceRecognitionWatch || basicFraudDetectionWatch || advancedFraudDetectionWatch}
              withDivider
            />
          </ConditionalWrapper>

          <Switch
            {...registerOnChange('enableOfflineMode')}
            label={t({
              id: 'time_tracking_settings.enable_offline_mode.label',
              message: 'Allow offline mode on TimeClock Mobile App',
            })}
            additionalInfo={
              <Text>
                <Trans id="time_tracking_settings.enable_offline_mode.additional_info">
                  Clock-ins from offline mode will be allowed.
                </Trans>
                <span> </span>
                <Link href={enableOfflineModeLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                  {t({ id: 'settings.learn_more' })}
                </Link>
                .
              </Text>
            }
            size="sm"
            disabledButReadable={faceRecognitionWatch || basicFraudDetectionWatch || advancedFraudDetectionWatch}
            bold
            withDivider
          />

          <Switch
            {...registerOnChange('forceLocation')}
            label={t({
              id: 'time_tracking_settings.force_locations.label',
              message: 'Force sending locations',
            })}
            additionalInfo={t({
              id: 'time_tracking_settings.force_locations.additional_info',
              message: 'All clock-ins and outs without shared location will be rejected.',
            })}
            size="sm"
            bold
            withDivider
            disabledButReadable={restrictLocations}
          />
          <Switch
            {...registerOnChange('restrictLocations')}
            label={t({
              id: 'time_tracking_settings.restrict_locations.label',
              message: 'Limit only to allowed locations',
            })}
            additionalInfo={t({
              id: 'time_tracking_settings.restrict_locations.additional_info',
              message: 'All clock-ins and outs not from the allowed locations will be rejected.',
            })}
            size="sm"
            bold
          />
        </SettingsGroup.Body.Visible>
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
