import React, { useEffect } from 'react';

import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { UPDATE_FIELD_VIEW } from 'utils/emitUpdateFieldView';

type Props = {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  callback: (value: string) => void;
};

/**
 * This hook will listen to a custom 'UPDATE_FIELD_VIEW' event dispatched from provided element and call the callback function.
 * Main use for this hook is to force an update in a component like 'Select' when the display is behind real value.
 * See: emitUpdateFieldView.ts
 */
export const useOnUpdateFieldView = (inputRef: Props['inputRef'], callback: Props['callback']): void => {
  const callbackRef = useCallbackRef(callback);
  useEffect(() => {
    const element = inputRef.current;
    const handleUpdateView = (event: CustomEvent<string> | Event) => {
      const elementValue = (event as CustomEvent<string>).detail;
      callbackRef.current(elementValue);
    };
    if (element) {
      element.addEventListener(UPDATE_FIELD_VIEW, handleUpdateView, false);
    }
    return () => {
      if (element) {
        element.removeEventListener(UPDATE_FIELD_VIEW, handleUpdateView, false);
      }
    };
  }, [inputRef, callbackRef]);
};
