import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useRef } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { UserPreferencesActionProps } from 'api/actions/settings/settingsActions.types';
import { Select } from 'components/ui/Select/Select';
import { TimeZoneSelect } from 'components/ui/Select/variants/TimeZoneSelect';
import { Switch } from 'components/ui/Switch';
import {
  dateFormatOptionsSelector,
  languageListOptionsSelector,
  nameDisplayOrderOptionsSelector,
} from 'state/selectOptions';
import { blockTransitionAtom } from 'state/settings';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useFormCustomEvent } from '../../../../hooks/useFormCustomEvent';
import { useFormSubmit } from '../../../../hooks/useFormSubmit';
import { useSettingsBlockRouteTransition } from '../../../../hooks/useSettingsBlockRouteTransition';
import { OptionLabel } from '../../../OptionLabel';
import { SettingsGroup } from '../../../SettingsGroup/SettingsGroup';

import { TimeSampleBox } from './components/TimeSampleBox';
import { useSubmitPreferences } from './hooks/useSubmitPreferences';

type Props = {
  preferencesSettings: UserPreferencesActionProps;
};

export const PreferencesForm = ({ preferencesSettings }: Props): React.ReactElement => {
  useLingui();
  const setBlockTransition = useSetRecoilState(blockTransitionAtom);
  const resetBlockTransition = useResetRecoilState(blockTransitionAtom);
  const dateFormatOptions = useRecoilValue(dateFormatOptionsSelector);
  const languageListOptions = useRecoilValue(languageListOptionsSelector);
  const nameDisplayOrderOptions = useRecoilValue(nameDisplayOrderOptionsSelector);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { dispatchSubmitEvent, throttledDispatchSubmitEvent } = useFormSubmit(formRef);

  const handleOnChange = useCallback(() => {
    setBlockTransition((prevState) => ({
      ...prevState,
      blockLocationPathname: null,
      blockTransition: true,
    }));
    throttledDispatchSubmitEvent.cancel();
    throttledDispatchSubmitEvent();
  }, [setBlockTransition, throttledDispatchSubmitEvent]);

  const {
    registerOnChange,
    registerOnBlur,
    handleSubmit,
    getValues,
    reset: resetForm,
    formState: { errors },
  } = useFormCustomEvent({
    onChangeCustom: handleOnChange,
    onBlurCustom: handleOnChange,
    formProps: { defaultValues: preferencesSettings, mode: 'onTouched', reValidateMode: 'onChange' },
  });

  const handleFormReset = useCallback(() => {
    resetForm({
      ...preferencesSettings,
    });
    resetBlockTransition();
  }, [preferencesSettings, resetBlockTransition, resetForm]);

  const handleOnSubmitCallback = useSubmitPreferences({
    handleFormReset,
    getCurrentFormState: getValues,
    preferencesSettings,
  });

  useSettingsBlockRouteTransition(dispatchSubmitEvent, throttledDispatchSubmitEvent);

  return (
    <form ref={formRef} onSubmit={floatingPromiseReturn(handleSubmit(handleOnSubmitCallback))}>
      <SettingsGroup.Body sx={{ mb: 2 }}>
        <SettingsGroup.Body.Visible variant="md">
          <OptionLabel
            label={t({
              id: 'settings.user.preferences.app_language',
              message: 'App language',
            })}
            apendWith={
              <Select
                sx={{
                  minWidth: '300px',
                }}
                id="language"
                placeholder={t({
                  id: 'settings.user.preferences.app_language',
                  message: 'App language',
                })}
                error={!!errors.language}
                errorMessage={errors?.language?.message}
                options={languageListOptions || []}
                size="sm"
                {...registerOnBlur('language', {
                  setValueAs: (v: string) => parseInt(v, 10),
                })}
              />
            }
            withDivider
          />
          <OptionLabel
            label={t({
              id: 'settings.user.preferences.name_display_order',
              message: 'Names displayed as',
            })}
            apendWith={
              <Select
                sx={{
                  ml: 'auto',
                  minWidth: '300px',
                }}
                id="nameDisplayOrder"
                placeholder={t({ id: 'settings.user.preferences.name_display_order' })}
                error={!!errors.timeZoneId}
                errorMessage={errors?.timeZoneId?.message}
                options={nameDisplayOrderOptions}
                size="sm"
                {...registerOnBlur('nameDisplayOrder', {
                  setValueAs: (v: string) => parseInt(v, 10),
                })}
              />
            }
          />
        </SettingsGroup.Body.Visible>
      </SettingsGroup.Body>

      <SettingsGroup.Body sx={{ bg: 'settings.timezone', borderRadius: 'sm' }}>
        <SettingsGroup.Body.Visible variant="md" withoutBorderBottom sx={{ bg: 'none' }}>
          <OptionLabel
            label={t({
              id: 'sign_up.employer.form.timezone',
              message: 'Your timezone',
            })}
            apendWith={
              <TimeZoneSelect
                {...registerOnBlur('timeZoneId')}
                id="timeZoneId"
                placeholder={t({
                  id: 'sign_up.employer.form.timezone',
                  message: 'Your timezone',
                })}
                searchable
                size="sm"
                error={!!errors.timeZoneId}
                errorMessage={errors?.timeZoneId?.message}
                sx={{
                  minWidth: '300px',
                }}
              />
            }
            withDivider
          />
          <OptionLabel
            label={t({
              id: 'settings.user.preferences.date_format',
              message: 'Date format',
            })}
            apendWith={
              <Select
                sx={{
                  ml: 'auto',
                  minWidth: '300px',
                }}
                id="dateFormat"
                placeholder={t({
                  id: 'settings.user.preferences.date_format',
                  message: 'Date format',
                })}
                error={!!errors.timeZoneId}
                errorMessage={errors?.timeZoneId?.message}
                options={dateFormatOptions || []}
                size="sm"
                {...registerOnBlur('dateFormat', {
                  setValueAs: (v: string) => parseInt(v, 10),
                })}
              />
            }
            withDivider
          />

          <Switch
            {...registerOnChange('is24HourFormat')}
            label={t({
              id: 'settings.user.preferences.time_format',
              message: '24-hours time format',
            })}
            sx={{ py: SETTINGS_SPACE.preferencesTimeFormat }}
            bold
            size="sm"
          />
        </SettingsGroup.Body.Visible>

        <TimeSampleBox />
      </SettingsGroup.Body>
    </form>
  );
};
