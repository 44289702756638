import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import { PZUSportRegistrationActionProps } from './PZUSportActions.types';

export function registerPZUSportAction(body: PZUSportRegistrationActionProps): Action<unknown> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.pzuSportRegistration,
    body,
  };
}
