import { Trans, t } from '@lingui/macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';
import key from 'weak-key';

import { fetchMessagesAction } from 'api/actions/chat/chatActions';
import { MessageForFetch } from 'api/actions/chat/chatActions.types';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { MESSAGES_PART_SIZE } from 'constants/chat';
import {
  parsedMessagesWithPersonalDataSelector,
  removeExcessMessagesSelector,
  sortedMessagesSelector,
} from 'state/chat';
import { dateFormatSelector } from 'state/recoilState';
import { dateTime } from 'utils/dateTime';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { mergeRefs } from 'utils/mergeRefs';

import { MemoizedMessage } from './components/Message';

type Props = {
  chatWindowId: string;
};

export const MessagesList = React.forwardRef<HTMLInputElement, Props>(
  ({ chatWindowId }: Props, ref): React.ReactElement | null => {
    const [partMessagesCounter, setPartMessagesCounter] = useState<number>(1);
    const messagesRef = useRef<HTMLDivElement | null>(null);

    const shouldFetchMessages = useRef<boolean>(true);

    const setFetchedMessages = useSetRecoilState(parsedMessagesWithPersonalDataSelector(chatWindowId));
    const removeExcessMessages = useSetRecoilState(removeExcessMessagesSelector);
    const messages = useRecoilValue(sortedMessagesSelector(chatWindowId));
    const dateFormat = useRecoilValue(dateFormatSelector);

    const { mutate: fetchMessagesMutate, loading } = useMutation(fetchMessagesAction);

    const fetchMessages = useCallback(async () => {
      if (shouldFetchMessages.current) {
        const { error, payload } = await fetchMessagesMutate({ id: chatWindowId, part: partMessagesCounter });
        if (!error && payload) {
          setFetchedMessages(payload);
          setPartMessagesCounter(partMessagesCounter + 1);
          if (payload.length < MESSAGES_PART_SIZE) {
            shouldFetchMessages.current = false;
          }
        }
      }
    }, [fetchMessagesMutate, partMessagesCounter, setFetchedMessages, chatWindowId]);

    useEffect(() => {
      if (!messages) {
        void fetchMessages();
      }

      return () => setPartMessagesCounter((v) => v);
    }, [fetchMessages, messages]);

    useEffect(
      () => () => {
        removeExcessMessages(chatWindowId);
      },
      [chatWindowId, removeExcessMessages],
    );

    const fetchMessagesWithScroll = useCallback(async () => {
      if (messagesRef.current && !loading) {
        const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;

        if (scrollTop < 0 && (scrollTop - clientHeight) * -1 === scrollHeight - 1) {
          await fetchMessages().then(() => messagesRef?.current?.scrollTo(0, scrollTop));
        }
      }
    }, [fetchMessages, loading]);

    const prepareDate = useCallback(
      (date: MessageForFetch['createdUnix']) => {
        if (dateTime(date).format(dateFormat) === dateTime().format(dateFormat)) {
          return t({ id: 'calendar.today' });
        }

        if (dateTime(date).format(dateFormat) === dateTime().add(-1, 'day').format(dateFormat)) {
          return <Trans id="chat.messageList.divider.yesterday">Yesterday</Trans>;
        }

        if (dateTime(date).year() === dateTime().year()) {
          return dateTime(date).format('D MMMM hh:mm');
        }

        return dateTime(date).format(dateFormat);
      },
      [dateFormat],
    );

    const displayDivider = useCallback(
      (date: MessageForFetch['createdUnix'], nextDate: MessageForFetch['createdUnix']) => {
        if (!nextDate || dateTime(date).format(dateFormat) === dateTime(nextDate).format(dateFormat)) return <></>;

        return (
          <Flex as="time" variant="chat.messagesList.dayDivider.container">
            <Text variant="chat.messagesList.dayDivider.text">{prepareDate(date)}</Text>
          </Flex>
        );
      },
      [dateFormat, prepareDate],
    );

    return (
      <Flex
        ref={mergeRefs([ref, messagesRef])}
        variant="chat.messagesList.container"
        onScroll={floatingPromiseReturn(fetchMessagesWithScroll)}
      >
        {messages?.[0] && (
          <>
            {messages.map((m, i, a) => (
              <React.Fragment key={key(m)}>
                <MemoizedMessage {...m} chatWindowId={chatWindowId} />
                {displayDivider(m.createdUnix, a[i + 1]?.createdUnix)}
              </React.Fragment>
            ))}

            {!loading && (
              <Flex as="time" variant="chat.messagesList.dayDivider.container">
                <Text variant="chat.messagesList.dayDivider.text">
                  {prepareDate(messages[messages.length - 1].createdUnix)}
                </Text>
              </Flex>
            )}
          </>
        )}
        {loading && (
          <Flex variant="chat.window.spinnerContainer">
            <LoadingSpinnerCss />
          </Flex>
        )}
      </Flex>
    );
  },
);
