import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React from 'react';
import { Flex } from 'theme-ui';

import { ReportDayDataType } from 'api/actions/reports/reportsActions.types';
import { AsideDetails } from 'layouts/AuthorizedApp/AsideDetails/AsideDetails';
import { formattedDurationFromSeconds } from 'utils/dateTime';

import { FancyDataBox } from './components/FancyDataBox';

type Props = Pick<
  ReportDayDataType,
  'workTime' | 'overtime' | 'nightTime' | 'missingWorkTime' | 'withoutSchedule' | 'autoBreak' | 'autoUnpaidBreak'
>;

export const getTimersTitleMap = (): { [key in keyof Props]: string } => ({
  workTime: t({ id: 'reports.work_time', message: 'Work time' }),
  autoBreak: t({ id: 'reports.auto_break', message: 'Auto-break' }),
  autoUnpaidBreak: t({ id: 'reports.auto_break_unpaid', message: 'Auto-break (unpaid)' }),
  missingWorkTime: t({ id: 'reports.missing_work_time', message: 'Missing work time' }),
  nightTime: t({ id: 'reports.night_time', message: 'Night time' }),
  overtime: t({ id: 'reports.overtime', message: 'Overtime' }),
  withoutSchedule: t({ id: 'reports.without_schedule', message: 'Without schedule' }),
});

export const TimersRenderer = ({ ...props }: Props): React.ReactElement | null => {
  useLingui();

  if (_.every(props, (prop) => prop === 0 || !prop)) return null;

  const renderTimers = () =>
    _.map(props, (prop, index) => {
      if (prop) {
        return (
          <FancyDataBox
            key={`${index}${prop}`}
            title={getTimersTitleMap()[index as keyof typeof props]}
            value={prop && formattedDurationFromSeconds(prop)}
          />
        );
      }
      return null;
    });

  return (
    <AsideDetails.Content.Container>
      <AsideDetails.Content.Title>
        <Trans id="reports.timers">Timers</Trans>
      </AsideDetails.Content.Title>

      <Flex sx={{ flexDirection: 'column', gap: '0.125rem' }}>{renderTimers()}</Flex>
    </AsideDetails.Content.Container>
  );
};
