import { t } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Link, Text } from 'theme-ui';

import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import { integrationsMapSelector } from 'state/settings';
import { HeadingTip } from '../../../../../HeadingTip';
import { wrapperLinkSx } from '../../../../../styles/wrappers';
import { useIntegrationHelpLinks } from '../../hooks/useIntegrationHelpLinks';

import { ZusTile } from './components/ZusTile';

export const Zus = (): React.ReactElement => {
  useLingui();
  const integrationsMap = useRecoilValue(integrationsMapSelector);
  const helpLink = useIntegrationHelpLinks(ServiceIntegration.ZusEzla);

  const zusEzla = useMemo(() => integrationsMap.get(ServiceIntegration.ZusEzla), [integrationsMap]);

  if (!zusEzla) return <></>;

  return (
    <>
      <HeadingTip
        label={t({ id: 'settings.integrations.eurzad', message: 'E-Urząd' })}
        variant="heading4"
        additionalInfo={
          <>
            <Text as="span" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
              {t({ id: 'settings.integrations.visible_managers_administrators' })}
            </Text>{' '}
            <Text>{t({ id: 'settings.integrations.organization_wide_integration_info' })}</Text>{' '}
            <Link href={helpLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
              <Trans id="settings.learn_more">Learn more</Trans>.
            </Link>
          </>
        }
        additionalInfoSx={{ fontSize: 2 }}
        sx={{ mt: 4, mb: 3 }}
      />
      <ZusTile integration={zusEzla} />
    </>
  );
};
