import { Action, DefaultErrorMessageEnum } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  AuthorizeResponse,
  InvitationJoinRequestPayload,
  InvitationResendPayload,
  RefreshAccessTokenResponse,
  SignInPayload,
  SignOutResponse,
  SignUpErrorCodes,
  SignUpPayload,
  SignUpResponse,
  SignUpResponseErrorData,
} from './authActions.types';

export function signInAction(requestBody: SignInPayload): Action<AuthorizeResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.authorize,

    body: {
      ...requestBody,
    },
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.SignIn,
    },
  };
}

export function signOutAction(accessToken?: string | null): Action<SignOutResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.signOut,

    body: {
      accessToken,
    },
  };
}

export function refreshAccessTokenAction(): Action<RefreshAccessTokenResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.refreshAccessToken,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.None,
      skipAuthorization: true,
    },
  };
}

export function signUpAction(
  requestBody: SignUpPayload,
): Action<SignUpResponse, SignUpResponseErrorData, SignUpErrorCodes> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.signUp,
    body: {
      ...requestBody,
    },
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.None,
    },
  };
}

export function invitationResendAction(requestBody: InvitationResendPayload): Action<unknown> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.invitationResend,
    body: {
      ...requestBody,
    },
  };
}

export function invitationJoinRequestAction(requestBody: InvitationJoinRequestPayload): Action<unknown> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.invitationJoinRequest,
    body: {
      ...requestBody,
    },
    config: {
      resendFailedSignUpQuery: true,
    },
  };
}
