import { Trans, t } from '@lingui/macro';
import { Flex, Heading, Text } from 'theme-ui';

import { Button } from 'components/ui/Buttons';
import { PATH } from 'constants/routes';
import { SupportedLanguages } from 'constants/supportedLanguages';
import { onboardingStepsType } from '../../types';

export function onboardingTeam(
  playNextStep: () => void,
  playCustomNextStep: (steps: number) => void,
  language: keyof typeof SupportedLanguages,
  canAddMoreUsers: boolean,
  isHeaderMenuSM: boolean,
): onboardingStepsType {
  return [
    {
      content: (
        <>
          <Text>
            <Trans id="onboarding.team.step1">
              Start by adding a teammate, navigate to <b>Team</b>.
            </Trans>
          </Text>
          <Flex sx={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={() => playCustomNextStep(isHeaderMenuSM ? 6 : 5)}
              size="xs"
              shape="rounded"
              variant="lightGrey"
            >
              {t({ id: 'onboarding.actions.skip' })}
            </Button>
          </Flex>
        </>
      ),
      targetElement: 'onboarding-teammates-1',
      onClickElement: playNextStep,
    },
    ...(isHeaderMenuSM
      ? [
          {
            content: (
              <Text>
                <Trans id="onboarding.team.step2.mobile_prestep">
                  Open this <b>menu</b>.
                </Trans>
              </Text>
            ),
            targetElement: 'onboarding-teammates-2--mobile-prestep',
            onClickElement: playNextStep,
            showOnUrl: PATH.TEAM[language],
          },
        ]
      : []),
    {
      content: (
        <Text>
          <Trans id="onboarding.team.step2">
            Click on this button <b>Add teammate</b>.
          </Trans>
        </Text>
      ),
      targetElement: 'onboarding-teammates-2',
      onClickElement: canAddMoreUsers ? () => playCustomNextStep(2) : playNextStep,
      showOnUrl: PATH.TEAM[language],
    },
    {
      content: (
        <Flex sx={{ flexDirection: 'column', alignContent: 'center', gap: 'inherit', textAlign: 'center' }}>
          <Flex variant="onboarding.modalHeaderIcon">👬</Flex>
          <Heading variant="heading3" as="h3">
            <Trans id="onboarding.team.step3.title">You've reached teammates limit.</Trans>
          </Heading>
          <Text as="p" variant="p">
            <Trans id="onboarding.team.step3.message">
              In order to add more <b>upgrade your plan</b>. And in the meantime let's move on with the onboarding!
            </Trans>
          </Text>
          <Flex sx={{ gap: 2, justifyContent: 'center', pt: 3 }}>
            <Button shape="rounded" variant="primary" onClick={() => playCustomNextStep(3)}>
              <Trans id="onboarding.action.next_step">Next step</Trans>
            </Button>
          </Flex>
        </Flex>
      ),
      mode: 'modal',
    },
    {
      content: (
        <Text>
          <Trans id="onboarding.team.step4">
            Fill out the form and click <b>Save</b>.
          </Trans>
        </Text>
      ),
      targetElement: 'onboarding-teammates-4',
      showOnUrl: `${PATH.TEAM[language]}${PATH.ADD_TEAMMATE_MODAL[language]}`,
      focusOnElement: false,
      placement: 'bottom',
    },
    {
      content: (
        <Flex sx={{ flexDirection: 'column', alignContent: 'center', gap: 'inherit', textAlign: 'center' }}>
          <Flex variant="onboarding.modalHeaderIcon">👬</Flex>
          <Heading variant="heading3" as="h3">
            <Trans id="onboarding.team.step5.title">Nicely done!</Trans>
          </Heading>
          <Text as="p" variant="p">
            <Trans id="onboarding.team.step5.message">Now you are not alone. Let's keep rolling.</Trans>
          </Text>
          <Flex sx={{ gap: 2, justifyContent: 'center', pt: 3 }}>
            <Button shape="rounded" variant="primary" onClick={playNextStep}>
              <Trans id="onboarding.action.next_step">Next step</Trans>
            </Button>
          </Flex>
        </Flex>
      ),
      mode: 'modal',
    },
  ];
}
