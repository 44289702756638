import React from 'react';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Badge, BadgeProps } from 'components/Badge/Badge';
import { RequestHistoryState } from 'api/actions/requests/requestsActions.types';

type Props = {
  requestState: RequestHistoryState;
} & Omit<BadgeProps, 'variant' | 'icon' | 'title' | 'children'>;

export const RequestHistoryStatusBadge = ({ requestState, ...props }: Props): React.ReactElement => {
  useLingui();

  const variant = ((): BadgeProps['variant'] => {
    switch (requestState) {
      case RequestHistoryState.Accepted:
        return 'success';
      case RequestHistoryState.Rejected:
        return 'danger';
      case RequestHistoryState.Deleted:
      case RequestHistoryState.TimeEventDeleted:
        return 'warning';
      case RequestHistoryState.FirstOutOfTwoAcceptance:
      case RequestHistoryState.Created:
      default:
        return 'default';
    }
  })();

  const stateMessage = (() => {
    switch (requestState) {
      case RequestHistoryState.Accepted:
        return t({
          id: 'request.history_state.accepted',
          message: 'Accepted',
        });
      case RequestHistoryState.Rejected:
        return t({
          id: 'request.history_state.rejected',
          message: 'Rejected',
        });
      case RequestHistoryState.Deleted:
        return t({
          id: 'request.history_state.deleted',
          message: 'Deleted',
        });
      case RequestHistoryState.TimeEventDeleted:
        return t({
          id: 'request.history_state.deleted_event',
          message: 'Event deleted',
        });
      case RequestHistoryState.FirstOutOfTwoAcceptance:
        return t({
          id: 'request.history_state.firstOutOfTwoAcceptance',
          message: '1/2 APPROVED',
        });
      case RequestHistoryState.Created:
        return t({
          id: 'request.history_state.created',
          message: 'Created',
        });
      case RequestHistoryState.PersonAcceptSwap:
        return t({
          id: 'request.history_state.person_accept_swap',
          message: 'Approved',
        });
      case RequestHistoryState.SwapProcessed:
        return t({
          id: 'request.history_state.swap_processed',
          message: 'Processed',
        });
      default:
        return '';
    }
  })();

  return (
    <Badge variant={variant} title={stateMessage} {...props}>
      {stateMessage}
    </Badge>
  );
};
