import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { ChangeEvent, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Flex } from 'theme-ui';

import { UserSetPasswordSettingsActionProps } from 'api/actions/settings/settingsActions.types';
import { Button } from 'components/ui/Buttons';
import { PasswordInput } from 'components/ui/PasswordInput';
import { TextInput } from 'components/ui/TextInput';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { SettingsGroup } from '../../SettingsGroup/SettingsGroup';

type Props = {
  onSubmit: (body: UserSetPasswordSettingsActionProps) => Promise<boolean>;
};

export const SetPasswordForm = ({ onSubmit }: Props): React.ReactElement => {
  useLingui();
  const confirmPasswordAfterBlurRef = useRef<boolean>(false);

  const {
    handleSubmit,
    register,
    reset,
    trigger,
    formState: { errors, isValid, isSubmitting },
  } = useForm<{
    password: string;
    confirmPassword: string;
  }>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const handleSubmitCallback = async ({ password }: UserSetPasswordSettingsActionProps) => {
    const shouldResetForm = await onSubmit({
      password,
    });

    if (shouldResetForm) {
      confirmPasswordAfterBlurRef.current = false;
      reset();
    }
  };
  const passwordRegister = useMemo(
    () =>
      register('password', {
        ...validationFactory({ ...VALIDATION_RULES.PASSWORD, required: true }),
        onChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
          if (!!value && confirmPasswordAfterBlurRef.current) {
            void trigger('confirmPassword');
          }
        },
      }),
    [register, trigger],
  );

  const confirmPasswordRegister = useMemo(
    () =>
      register('confirmPassword', {
        required: t({ id: 'global.forms.required' }),
        validate: (value, formValues) =>
          value === formValues.password ||
          t({
            id: 'settings.user.change_password.not_match',
            message: 'Passwords do not match',
          }),
        onBlur: () => {
          confirmPasswordAfterBlurRef.current = true;
        },
      }),
    [register],
  );

  return (
    <form onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}>
      <SettingsGroup>
        <SettingsGroup.Body>
          <SettingsGroup.Body.Visible variant="xlg">
            <Flex sx={{ flexDirection: 'column' }}>
              <PasswordInput
                id="newPassword"
                {...passwordRegister}
                autoComplete="new-password"
                label={t({
                  id: 'settings.user.password',
                  message: 'Password',
                })}
                variant="rounded"
                placeholder={t({ id: 'settings.user.password' })}
                error={!!errors.password}
                errorMessage={errors?.password?.message}
                size="sm"
                sx={{ mb: 2 }}
              />

              <TextInput
                id="confirmPassword"
                type="password"
                autoComplete="new-password"
                {...confirmPasswordRegister}
                label={t({
                  id: 'settings.user.change_password.confirm_password',
                  message: 'Confirm password',
                })}
                variant="rounded"
                placeholder={t({
                  id: 'settings.user.change_password.confirm_password',
                  message: 'Confirm password',
                })}
                error={!!errors.confirmPassword}
                errorMessage={errors?.confirmPassword?.message}
                size="sm"
              />
            </Flex>

            <Button
              variant="primary"
              type="submit"
              shape="rounded"
              disabled={!isValid}
              isLoading={isSubmitting}
              sx={{ alignSelf: 'flex-start' }}
            >
              <Trans id="settings.user.set_password">Set password</Trans>
            </Button>
          </SettingsGroup.Body.Visible>
        </SettingsGroup.Body>
      </SettingsGroup>
    </form>
  );
};
