import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex, Text } from 'theme-ui';

import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { RequestsToUpdateSelect } from 'components/ui/Select/variants/RequestsToUpdateSelect';
import { useMount } from 'hooks/useMount/useMount';
import { MinimizeModalAddRequest } from 'state/modal';
import { setNativeValue } from 'utils/setNativeValue';
import { AddRequestExtendedFormContext } from '../../../../../types';
import { useAddRequest } from '../../../hooks/useAddRequest';

type Props = {
  type: Exclude<RequestFormType, RequestFormType.Schedule>;
};

export const RequestsHistorySelect: FC<Props> = ({ type }: Props) => {
  useLingui();

  const requestsToUpdateRef = useRef<HTMLInputElement | null>(null);

  const {
    requestConfig: { requestConfigState },
    requestToEdit: { setSelectedRequestToEditId },
    requestFormRestored: { editRequestIdRestored },
    setRequestFormRestored,
  } = useAddRequest();
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddRequest>();
  const { watch } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  const isStoredRequestOnceRestored = useMemo(
    () => minimizedModalDetails?.modalDetails.requestToEdit && editRequestIdRestored,
    [minimizedModalDetails?.modalDetails.requestToEdit, editRequestIdRestored],
  );

  const historyVisible = useMemo(() => {
    if (requestConfigState?.requestManagement) return false;

    if ([RequestActionType.Edit, RequestActionType.Remove].includes(actionTypeWatch)) return true;

    return false;
  }, [actionTypeWatch, requestConfigState?.requestManagement]);

  useMount(() => {
    if (!isStoredRequestOnceRestored) {
      setNativeValue(requestsToUpdateRef, minimizedModalDetails?.modalDetails.requestToEdit);
      setRequestFormRestored((prev) => ({ ...prev, editRequestIdRestored: true }));
    }
  });

  if (!historyVisible) return null;

  const label: { [key in Props['type']]: { edit: string; remove: string } } = {
    [RequestFormType.BusinessTrip]: {
      edit: t({ id: 'add_request.select_business_request', message: 'Select a business request to edit' }),
      remove: t({
        id: 'add_request.select_business_request.remove',
        message: 'Select a business request to remove',
      }),
    },
    [RequestFormType.Custom]: {
      edit: t({ id: 'add_request.select_custom_request', message: 'Select a custom request to edit' }),
      remove: t({ id: 'add_request.select_custom_request.remove', message: 'Select a custom request to remove' }),
    },
    [RequestFormType.TimeOff]: {
      edit: t({ id: 'add_request.select_time_off_request', message: 'Select a time off request to edit' }),
      remove: t({ id: 'add_request.select_time_off_request.remove', message: 'Select a time off request to remove' }),
    },
    [RequestFormType.TimeTracking]: {
      edit: t({ id: 'requests.add_request.select_event.edit' }),
      remove: t({ id: 'requests.add_request.select_event.delete' }),
    },
  };

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text sx={{ fontWeight: 'bold', mb: 2 }} variant="heading4">
        {actionTypeWatch === RequestActionType.Edit ? label[type].edit : label[type].remove}
      </Text>
      <RequestsToUpdateSelect
        ref={requestsToUpdateRef}
        id="eventsToUpdateSelect"
        requestType={type}
        onChange={(e) => {
          setSelectedRequestToEditId(e.target.value);
        }}
        requiredRequestId={!isStoredRequestOnceRestored ? minimizedModalDetails?.modalDetails.requestToEdit : undefined}
      />
    </Flex>
  );
};
