import { Action } from 'react-fetching-library';

import { API_ENDPOINTS } from 'constants/api';

import {
  DeleteTimeOffTypesActionProps,
  SetTimeOffTypesStateActionProps,
  SetTimeOffTypesResponse,
  AddTimeOffTypeActionProps,
  AddTimeOffTypeResponse,
  UpdateTimeOffTypeActionProps,
  UpdateTimeOffTypeResponse,
  DeleteTimeOffTypesResponse,
} from './timeOffActions.types';

export function addTimeOffTypeAction(body: AddTimeOffTypeActionProps): Action<AddTimeOffTypeResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeOffType,
    body,
  };
}

export function updateTimeOffTypeAction({
  timeOffId,
  ...body
}: UpdateTimeOffTypeActionProps): Action<UpdateTimeOffTypeResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.timeOffType}/${timeOffId}`,
    body,
  };
}

export function deleteTimeOffTypeAction(body: DeleteTimeOffTypesActionProps): Action<DeleteTimeOffTypesResponse> {
  return {
    method: 'DELETE',
    endpoint: API_ENDPOINTS.timeOffType,
    body,
  };
}

export function setTimeOffTypesStateAction(body: SetTimeOffTypesStateActionProps): Action<SetTimeOffTypesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setTimeOffTypesState,
    body,
  };
}
