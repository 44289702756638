import { useEffect, useMemo } from 'react';

import { IndustryCategory } from 'api/actions/data/dataActions.types';
import { InputOption } from 'components/ui/Select/Select';

import { useIndustry } from './useIndustry';

export const useIndustrySelectsOptions = (selectedIndustryId?: IndustryCategory) => {
  const { fetchIndustryList, industryList } = useIndustry();

  useEffect(() => {
    if (!industryList) {
      void fetchIndustryList();
    }
  }, [fetchIndustryList, industryList]);

  const industryOptions: InputOption[] = useMemo(
    () => (industryList ? industryList.map(({ label, id }) => ({ label, id: `${id}` })) : []),
    [industryList],
  );

  const industrySubcategoryOptions: InputOption[] = useMemo(() => {
    if (selectedIndustryId && industryList) {
      const selectedIndustry = industryList.find(({ id }) => id === selectedIndustryId);
      return selectedIndustry?.subcategories?.map(({ id, label }) => ({ label, id: `${id}` })) || [];
    }

    return [];
  }, [selectedIndustryId, industryList]);

  return { industryOptions, industrySubcategoryOptions };
};
