import { Action } from 'react-fetching-library';

import { API_ENDPOINTS } from 'constants/api';

import {
  AddHolidayActionProps,
  AddHolidayResponse,
  DeleteHolidayActionProps,
  DeleteHolidayResponse,
  EditHolidayActionProps,
  EditHolidayResponse,
  HolidayImportLocationsResponse,
  HolidaysYearActionProps,
  HolidaysYearResponse,
  ImportHolidaysProps,
  ImportHolidayResponse,
  ImportPreviewHolidaysActionProps,
  ImportPreviewHolidaysResponse,
} from './holidaysActions.types';

export function fetchHolidayImportLocationsAction(): Action<HolidayImportLocationsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.importLocations,
  };
}

export function deleteHolidayAction({ id }: DeleteHolidayActionProps): Action<DeleteHolidayResponse> {
  return {
    method: 'DELETE',
    endpoint: `${API_ENDPOINTS.holiday}/${id}`,
  };
}

export function fetchHolidaysYearAction({ year }: HolidaysYearActionProps): Action<HolidaysYearResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.holiday}/${year}`,
  };
}

export function addHolidayAction(body: AddHolidayActionProps): Action<AddHolidayResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.holiday,
    body,
  };
}

export function editHolidayAction({ id, ...body }: EditHolidayActionProps): Action<EditHolidayResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.holiday}/${id}`,
    body,
  };
}

export function importPreviewHolidaysAction(
  body: ImportPreviewHolidaysActionProps,
): Action<ImportPreviewHolidaysResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.importPreviewHolidays,
    body,
  };
}

export function importHolidaysAction(body: ImportHolidaysProps): Action<ImportHolidayResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.importHolidays,
    body,
  };
}
