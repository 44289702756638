import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC } from 'react';
import { Text } from 'theme-ui';

import { FetchRequestsSettingsResponse } from 'api/actions/settings/settingsActions.types';
import { Switch } from 'components/ui/Switch';
import { useFormContextCustomEvent } from '../../../../../../../hooks/useFormContextCustomEvent';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';

export const PersonalReplacement: FC = () => {
  useLingui();
  const { registerOnChange } = useFormContextCustomEvent<FetchRequestsSettingsResponse>();

  return (
    <SettingsGroup variant="sm">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="requests_settings.heading.automatic_personal_replacements">Automatic personal replacements</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <SettingsGroup.Body>
        <SettingsGroup.Body.Visible variant="md">
          <Switch
            {...registerOnChange('allowAutomaticApprovalReplacement')}
            label={t({
              id: 'requests_settings.automatic_personal_replacements.label',
              message: 'Automatic replacements during Supervisor or Manager absence',
            })}
            additionalInfo={
              <Text>
                <Trans id="requests_settings.automatic_personal_replacements.additionalInfoExtended">
                  When Supervisor or Manager is absent all requests send to her/him will be forwarded to all available
                  users with her/his role.
                </Trans>
              </Text>
            }
            size="sm"
            bold
          />
        </SettingsGroup.Body.Visible>
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
