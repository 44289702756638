import { t, Trans } from '@lingui/macro';
import React, { useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Text } from 'theme-ui';

import { setFraudResolutionStateAction } from 'api/actions/timeEvent/timeEventActions';
import { FraudDetectionState } from 'api/actions/timeEvent/timeEventActions.types';
import { useModal } from 'components/Modal/output/useModal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'components/recipes/ConfirmModal/types';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { clockLogResponseAtom } from 'state/clockLog';

export const ConfirmRecoverEventModal = (): React.ReactElement | null => {
  const { baseRoute } = useModal();
  const navigate = useAppNavigate();
  const { state } = useLocation();
  const { modifyTimeEvent, baseRoute: fromBaseRoute, selectedEventPhotos } = state;
  const { id } = modifyTimeEvent || {};

  const setClockLogResponse = useSetRecoilState(clockLogResponseAtom);

  const onResponseCallback: NonNullable<ConfirmModalProps['onResponseCallback']> = useCallback(
    (submitError) => {
      if (id && !submitError) {
        setClockLogResponse((prev) => {
          if (prev === null) return null;

          if ('timeEvents' in prev) {
            const { timeEvents } = prev;
            const timeEventsMap = new Map(timeEvents);
            const updatedEvent = timeEventsMap.get(id);

            if (updatedEvent) {
              timeEventsMap.set(updatedEvent?.id, {
                ...updatedEvent,
                fraudDetectionState: FraudDetectionState.ResolvedNotFraud,
              });
            }

            return {
              ...prev,
              timeEvents: [...timeEventsMap],
            };
          }

          return prev;
        });

        navigate(`${fromBaseRoute || ''}${baseRoute}`, { relative: 'path' });
      }
    },
    [baseRoute, fromBaseRoute, id, navigate, setClockLogResponse],
  );

  const titleRenderer = useCallback(() => t({ id: 'clock_log.anti_spoofing.reject.confirm' }), []);

  const contentRenderer = useCallback(
    () => (
      <Text>
        <Trans id="clock_log.recover_rejected_event.consequences">
          By approving, this event will be shown in the clock log and will be used in any reports.
        </Trans>
      </Text>
    ),
    [],
  );

  if (!id) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <ConfirmModal
      action={() => setFraudResolutionStateAction({ id, isFraud: false, selectedPhotos: selectedEventPhotos })}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="APPROVE"
      onResponseCallback={onResponseCallback}
    />
  );
};
