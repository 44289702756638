import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Heading, Text } from 'theme-ui';

import { dateFormatSelector } from 'state/recoilState';
import { dateTime } from 'utils/dateTime';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { settingsBorder } from 'styles/theme/settings';

export const TimeSampleBox = (): React.ReactElement => {
  useLingui();
  const dateFormat = useRecoilValue(dateFormatSelector);

  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const { border, borderColor } = settingsBorder;

  return (
    <Flex variant="settings.elements.timeSampleBoxContainer">
      <Flex variant="settings.elements.timeSampleBox">
        <Heading variant="heading5">
          <Trans id="settings.user.preferences.format_example">Format examples</Trans>
        </Heading>
        <Flex
          sx={{
            ...(isSmartphoneBreakpoint && {
              flexDirection: 'column',
              alignItems: 'flex-end',
              rowGap: 1,
            }),
          }}
        >
          <Text
            variant="settings.elements.timeSample"
            sx={{
              textAlign: 'right',
              ...(!isSmartphoneBreakpoint && {
                borderRight: border,
                borderColor,
              }),
            }}
          >
            {dateTime().format('llll')}
          </Text>
          <Text
            variant="settings.elements.timeSample"
            sx={{
              ...(!isSmartphoneBreakpoint && {
                borderRight: border,
                borderColor,
              }),
            }}
          >
            {dateTime().format(dateFormat)}
          </Text>
          <Text variant="settings.elements.timeSample">{dateTime().format('LTS')}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
