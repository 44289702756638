import { Trans } from '@lingui/macro';
import React, { useCallback } from 'react';
import { BoxOwnProps, BoxProps, Flex, Text } from 'theme-ui';
import { useRecoilValue } from 'recoil';

import { Icon } from 'components/Icon/Icon';
import { dateTime } from 'utils/dateTime';
import { Divider } from 'components/Divider/Divider';
import { fullTimeFormatSelector } from 'state/recoilState';

import { TotalOf } from './TotalOf';

type Props = BoxOwnProps &
  BoxProps & {
    dateDetails: {
      dates: number[];
      isDateBound: boolean;
    };
    duration: number;
  };

export const DateRangeDisplay = ({ dateDetails, duration, ...props }: Props): React.ReactElement => {
  const timeFormat = useRecoilValue(fullTimeFormatSelector);
  const { dates, isDateBound } = dateDetails;
  const [startUnix, endUnix] = dates;

  const dateFormatDisplay = useCallback(
    (unix: number, absoluteUnix: boolean) => {
      if (absoluteUnix) return <Text sx={{ fontSize: 2 }}>{dateTime(unix, { utc: true }).format('dd, ll')}</Text>;

      return (
        <Text>
          {dateTime(unix).format('dd, ll ')}
          <Text as="span" sx={{ fontWeight: 'bold' }}>
            {dateTime(unix).format(timeFormat)}
          </Text>
        </Text>
      );
    },
    [timeFormat],
  );

  return (
    <Flex {...props} sx={{ gap: 4, ...(props.sx && props.sx) }}>
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 3,
          }}
        >
          <Trans id="request.details.time_frame.starts">Starts</Trans>
        </Text>
        <Text sx={{ fontSize: 2 }}>{dateFormatDisplay(startUnix, isDateBound)}</Text>
      </Flex>
      <Icon type="arrowRight" fill="requests.dateRangeDisplay" />
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 3,
          }}
        >
          <Trans id="request.details.time_frame.ends">Ends</Trans>
        </Text>
        <Text sx={{ fontSize: 2 }}>{dateFormatDisplay(endUnix, isDateBound)}</Text>
      </Flex>
      <Divider sx={{ height: 'auto' }} borderColor="darker" axis="vertical" />
      <TotalOf duration={duration} />
    </Flex>
  );
};
