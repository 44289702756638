import _ from 'lodash';
import hash from 'object-hash';
import React, { useEffect, useMemo, useRef, useTransition } from 'react';
import { useFormContext } from 'react-hook-form';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { EDIT_REQUEST_DEBOUNCE_TIME } from 'constants/requests';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useMemoCompare } from 'hooks/useMemoCompare/useMemoCompare';
import { MinimizeModalAddRequest } from 'state/modal';
import { ParsedEventToUpdate } from 'state/requests';
import { EventType } from 'state/selectOptions';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';
import { useMainFormEdit } from '../../../../../hooks/useMainFormEdit';

export const EditionObserver = (): React.ReactElement => {
  const {
    modifyRequest: { modifyRequestData },
    requestToEdit: { selectedRequestToEdit },
    requestFormRestored: { editRequestRestored },
    setRequestFormRestored,
  } = useAddRequest();
  const { handleMainFormValuesForRequestToEdit, updatePrevEvent } = useMainFormEdit();
  const [isInTransition, startTransition] = useTransition();
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddRequest>();
  const storedMinimizedModalDetails = useRef(!!minimizedModalDetails?.modalDetails.requestToEdit);
  const editRequestRestoredRef = useCallbackRef(editRequestRestored);

  const { setValue, watch } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  const handleSetHookFormValue = useMemo(
    () =>
      _.debounce((event: ParsedEventToUpdate) => {
        if (storedMinimizedModalDetails.current && !editRequestRestoredRef.current) {
          setRequestFormRestored((prev) => ({ ...prev, editRequestRestored: true }));
          updatePrevEvent(event);
          return;
        }

        startTransition(() => {
          if (event && actionTypeWatch !== RequestActionType.Create) {
            const {
              id,
              eventUpdateDetails: { typeId, isEndStatus, note, reason },
              dateTimeDetails: { dates },
            } = event;

            handleMainFormValuesForRequestToEdit.current(event);

            setValue('targetEventId', id);
            setValue('details.typeId', typeId);

            if (actionTypeWatch !== RequestActionType.Remove) {
              const status: EventType = { id: typeId || '', isEndStatus };
              const statusOptionId = hash(status);

              setValue('details.note', reason);
              setValue('details.isEnd', isEndStatus);
              setValue('dateTimeDetails.date', dates[0]);
              setValue('note', note);
              setValue('details.typeId', statusOptionId);
            } else {
              setValue('details.typeId', typeId);
            }
          }
        });
      }, EDIT_REQUEST_DEBOUNCE_TIME),
    [
      editRequestRestoredRef,
      setRequestFormRestored,
      updatePrevEvent,
      actionTypeWatch,
      handleMainFormValuesForRequestToEdit,
      setValue,
    ],
  );

  const selectedTimeEventDataMemo = useMemoCompare(selectedRequestToEdit);

  useEffect(() => {
    if (selectedTimeEventDataMemo) {
      handleSetHookFormValue(selectedTimeEventDataMemo);
    }
  }, [handleSetHookFormValue, selectedTimeEventDataMemo]);

  useEffect(() => {
    if (modifyRequestData) {
      handleSetHookFormValue(modifyRequestData);
    }
  }, [handleSetHookFormValue, modifyRequestData]);

  return <>{isInTransition && <LoadingOverlay sx={{ zIndex: 'base' }} />}</>;
};
