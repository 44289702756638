/** @jsxImportSource theme-ui */

import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

type Props = {
  value: number;
  fill: string;
  background?: string;
  valueMax?: number;
  reverse?: boolean;
};

export type PieChartSvgProps = Props;

const defaultProps: Partial<Props> = {
  background: 'transparent',
  valueMax: 100,
  reverse: false,
};

export const PieChartSvg = ({
  value,
  valueMax = 100,
  fill,
  background,
  reverse,
  ...props
}: Props): React.ReactElement => {
  const FULL_RADIUS = 50.26; /* 2π * 8 ≈ 50.2654 */

  const calculateProgress = () => (value * FULL_RADIUS) / valueMax;

  const svgSx: ThemeUIStyleObject = {
    transform: reverse ? 'rotate(-90deg)' : 'rotate(90deg)',
    background,
    borderRadius: '50%',
    width: '100%',
    height: '100%',
  };

  const circleSx: ThemeUIStyleObject = {
    fill: background,
    stroke: fill,
    strokeWidth: '16',
    strokeDasharray: `${calculateProgress()} ${FULL_RADIUS}`,
  };
  return (
    <svg width="32" height="32" sx={svgSx} viewBox="0 0 32 32" preserveAspectRatio="xMidYMid" {...props}>
      <circle r="8" cx="16" cy="16" sx={circleSx} />
    </svg>
  );
};

PieChartSvg.defaultProps = defaultProps;
