import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex, Text } from 'theme-ui';

import { GetPeopleToSwapResponse } from 'api/actions/requests/requestsActions.types';
import { PersonSelect } from 'components/ui/Select/variants/PersonSelect';
import { AddRequestExtendedFormContext } from '../../../../../../../types';

type Props = {
  ids: GetPeopleToSwapResponse | null;
};

export const ForPersonSelect = ({ ids }: Props): React.ReactElement | null => {
  useLingui();

  const {
    register,
    unregister,
    formState: { errors },
  } = useFormContext<AddRequestExtendedFormContext>();

  useEffect(
    () => () => {
      unregister('targetSwapPersonId');
    },
    [unregister],
  );

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text sx={{ mb: 2, fontWeight: 'bold' }} variant="heading4">
        <Trans id="add_request.swap_with">Swap with</Trans>
      </Text>
      <PersonSelect
        {...register('targetSwapPersonId', { required: t({ id: 'global.forms.required' }) })}
        id="targetSwapPersonId"
        placeholder={t({ id: 'add_request.swap_with' })}
        employeesIds={ids || []}
        size="sm"
        errorMessage={errors.targetSwapPersonId?.message}
        error={!!errors.targetSwapPersonId}
        withAllEmployeesOption={false}
        withDeactivatedEmployeesTooltip
      />
    </Flex>
  );
};
