import { t } from '@lingui/macro';
import _ from 'lodash';

import { ReportDayDataType } from 'api/actions/reports/reportsActions.types';
import { RequestFormType, RequestState } from 'api/actions/requests/requestsActions.types';
import {
  CustomRequestTypesDictionarySelectorType,
  TimeEventTypesDictionarySelectorType,
  TimeOffTypesDictionarySelectorType,
} from 'state/organizationSession';
import { DayDetailsErrorDetails } from '../components/renderers/DayDetailsRenderer/types';

type getAnnotationsReturnType =
  | {
      timeOffNames?: string[];
      timeOffAbbrs?: string[];
      customRequestNames?: string[];
      customRequestAbbrs?: string[];
      annotation?: string;
      businessTripName?: string;
      isPresent?: boolean;
      isIncompleteShift?: boolean;
      isOvertime?: boolean;
      isAbsent?: boolean;
      errors?: DayDetailsErrorDetails[];
    }
  | undefined;

export const getDayStateWithAnnotations = (
  currentDayReport: ReportDayDataType | undefined | null,
  timeOffTypes: TimeOffTypesDictionarySelectorType,
  customRequestTypes: CustomRequestTypesDictionarySelectorType,
  timeEventTypes: TimeEventTypesDictionarySelectorType,
  isBeforeToday?: boolean,
): getAnnotationsReturnType => {
  let returnVal: getAnnotationsReturnType;

  if (currentDayReport && timeOffTypes && customRequestTypes && timeEventTypes) {
    const {
      missingWorkTime,
      overtime,
      autoBalance,
      withoutSchedule,
      schedules: unfilteredSchedules,
      workTime,
      requests,
      errors,
      correlatedDay,
    } = currentDayReport;

    const schedules = unfilteredSchedules
      ? unfilteredSchedules.filter((schedule) => schedule.isPublished && !schedule.isDeleted)
      : undefined;

    if (autoBalance && (autoBalance.given || autoBalance.taken)) {
      returnVal = {
        annotation: t({
          id: 'reports.day_state.annotation',
          message: 'Balanced',
        }),
      };
    }

    if (isBeforeToday) {
      if (
        (workTime && workTime > 0) ||
        (overtime && overtime > 0) ||
        (withoutSchedule && withoutSchedule > 0) ||
        (autoBalance && autoBalance.available && autoBalance.available > 0)
      ) {
        returnVal = { ...returnVal, isPresent: true };
      }
      if (missingWorkTime && missingWorkTime > 0) {
        returnVal = { ...returnVal, isIncompleteShift: true };
      }
      if (overtime && overtime > 0) {
        returnVal = { ...returnVal, isOvertime: true };
      }
      if (
        (!workTime || workTime === 0) &&
        (!overtime || overtime === 0) &&
        (!withoutSchedule || withoutSchedule === 0) &&
        schedules &&
        schedules.length > 0 &&
        isBeforeToday &&
        !autoBalance
      ) {
        returnVal = { ...returnVal, isAbsent: true };
      }
      if (errors && errors.length > 0) {
        returnVal = { ...returnVal, errors };
      }
    }

    if (requests && requests.length > 0) {
      const timeOffs = _.filter(
        currentDayReport.requests,
        (req) => req.type === RequestFormType.TimeOff && req.state === RequestState.Accepted,
      );
      const customRequests = _.filter(
        currentDayReport.requests,
        (req) => req.type === RequestFormType.Custom && req.state === RequestState.Accepted,
      );
      const businessTrips = _.filter(
        currentDayReport.requests,
        (req) => req.type === RequestFormType.BusinessTrip && req.state === RequestState.Accepted,
      );

      const timeOffNames =
        timeOffs.length > 0 &&
        _.uniq(
          _.map(timeOffs, ({ typeId, state }) =>
            typeId && state === RequestState.Accepted ? timeOffTypes[typeId].name : '',
          ),
        );
      const timeOffAbbrs =
        timeOffs.length > 0 &&
        _.uniq(
          _.map(timeOffs, ({ typeId, state }) =>
            typeId && state === RequestState.Accepted ? timeOffTypes[typeId].abbreviation : '',
          ),
        );
      const customRequestNames =
        customRequests.length > 0 &&
        _.uniq(
          _.map(customRequests, ({ typeId, state }) =>
            typeId && state === RequestState.Accepted ? customRequestTypes[typeId].name : '',
          ),
        );
      const customRequestAbbrs =
        customRequests.length > 0 &&
        _.uniq(
          _.map(customRequests, ({ typeId, state }) =>
            typeId && state === RequestState.Accepted ? customRequestTypes[typeId].abbreviation : '',
          ),
        );
      const businessTripName =
        businessTrips.length > 0 && _.uniq(_.map(businessTrips, ({ typeDescription }) => typeDescription)).join(', ');

      if (timeOffNames) {
        returnVal = {
          ...returnVal,
          ...(returnVal && returnVal.isAbsent && { isAbsent: false }),
        };
      }

      returnVal = {
        ...returnVal,
        ...(!!timeOffNames && { timeOffNames }),
        ...(!!timeOffAbbrs && { timeOffAbbrs }),
        ...(!!customRequestNames && { customRequestNames }),
        ...(!!customRequestAbbrs && { customRequestAbbrs }),
        ...(!!businessTripName && { businessTripName }),
      };
    }

    if (correlatedDay && correlatedDay.dateUnix && !errors && !correlatedDay.hidePresent) {
      if (missingWorkTime && missingWorkTime > 0) {
        returnVal = {
          ...returnVal,
          isIncompleteShift: true,
          isAbsent: false,
        };
      } else if (!missingWorkTime) {
        returnVal = {
          ...returnVal,
          isPresent: true,
          isAbsent: false,
        };
      }
    }
  }

  if (returnVal && returnVal.isAbsent) {
    returnVal = {
      ...returnVal,
      isIncompleteShift: false,
    };
  }

  return returnVal;
};
