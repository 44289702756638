import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, ThemeUICSSObject } from 'theme-ui';

import { Divider } from 'components/Divider/Divider';
import { Modal } from 'components/Modal/output/Modal';
import { ListNames } from 'components/StickyList/types';
import { PickTeammates } from 'components/recipes/PickTeammates/PickTeammates';
import { PickTeammatesFilters } from 'components/recipes/PickTeammates/PickTeammatesFilters';
import { LinkButton } from 'components/ui/Buttons';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { TO_REL } from 'constants/routes';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { AsideWrapper } from 'pages/Requests/output/AsideWrapper';
import { SelectedPersons } from 'pages/Requests/output/SelectedPersons';
import { ScheduleWizardStep, calendarRecentSchedulesSelector } from 'state/calendar';
import { languageSelector } from 'state/recoilState';

import { AddScheduleWizardFooter } from './components/AddScheduleWizardFooter';
import { ScheduleWizardModalStep2 } from './components/ScheduleWizardModalStep2';
import { ScheduleWizardModalStep3 } from './components/ScheduleWizardModalStep3';
import { useMinimizeModalScheduleWizard } from './hooks/useMinimizeModalScheduleWizard';
import { useScheduleWizard } from './hooks/useScheduleWizard';

const scheduleWizardModalStepWrapper: ThemeUICSSObject = {
  flexDirection: 'column',
  flexGrow: 1,
  overflowY: 'auto',
  px: 3,
  pb: 3,
};

export const ScheduleWizardModal = (): React.ReactElement => {
  useLingui();

  const { step, resetWizard } = useScheduleWizard();
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const language = useRecoilValue(languageSelector);
  const recentSchedules = useRecoilValue(calendarRecentSchedulesSelector);

  const stepRenderer = useMemo(() => {
    switch (step) {
      case ScheduleWizardStep.SelectUsers:
        return (
          <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
            <PickTeammates
              forceIncludeCurrentUser={true}
              listName={ListNames.REQUESTS_PICK_TEAMMATES}
              module="Schedules"
            />
          </Flex>
        );
      case ScheduleWizardStep.SetSchedule:
        return (
          <Flex
            sx={{
              ...scheduleWizardModalStepWrapper,
            }}
          >
            <ScheduleWizardModalStep2 />
          </Flex>
        );
      case ScheduleWizardStep.SetDates:
        return (
          <Flex
            sx={{
              ...scheduleWizardModalStepWrapper,
            }}
          >
            <ScheduleWizardModalStep3 />
          </Flex>
        );
      default:
        return null;
    }
  }, [step]);

  const sideRenderer = useMemo(() => {
    switch (step) {
      case ScheduleWizardStep.SelectUsers:
        return (
          <Flex
            sx={{
              flexDirection: 'column',
              gap: 3,
              flex: 1,
              ...(!isSmartphoneBreakpoint && { overflowY: 'auto', pr: 3 }),
              ...(!isSmartphoneBreakpoint && step === ScheduleWizardStep.SelectUsers && { pl: 3 }),
            }}
          >
            <PickTeammatesFilters />
          </Flex>
        );
      case ScheduleWizardStep.SetSchedule:
        return <SelectedPersons />;
      case ScheduleWizardStep.SetDates:
        return <SelectedPersons />;
      default:
        return null;
    }
  }, [isSmartphoneBreakpoint, step]);

  useMinimizeModalScheduleWizard();

  useEffect(
    () => () => {
      resetWizard();
    },
    [resetWizard],
  );

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="schedule.wizard">Schedule Wizard</Trans>
        </Modal.Title>

        {recentSchedules && step === ScheduleWizardStep.SetSchedule && (
          <LinkButton variant="lightGrey" shape="rounded" to={TO_REL.RECENT_SCHEDULE_PICKER[language]}>
            {t({ id: 'schedule.pick_schedule' })}
          </LinkButton>
        )}
      </Modal.Header>

      <Modal.Body
        sx={{
          pt: 0,
          gap: 2,
          ...(!isSmartphoneBreakpoint && step === ScheduleWizardStep.SelectUsers && { pl: 0 }),
          ...(!isSmartphoneBreakpoint && step !== ScheduleWizardStep.SelectUsers && { pr: 0 }),
        }}
      >
        <Flex sx={{ flex: 1, minHeight: 0 }}>
          <ConditionalWrapper condition={isSmartphoneBreakpoint} wrapper={AsideWrapper}>
            <Flex
              sx={{
                maxWidth: '240px',
                flex: 1,
                ...(!isSmartphoneBreakpoint && { overflowY: 'hidden' }),
                ...(!isSmartphoneBreakpoint && step !== ScheduleWizardStep.SelectUsers && { mr: 3 }),
              }}
            >
              {sideRenderer}
            </Flex>
          </ConditionalWrapper>
          {!isSmartphoneBreakpoint && <Divider axis="vertical" />}
          <Flex
            sx={{
              flex: 1,
              flexDirection: 'column',
              ...(!isSmartphoneBreakpoint && step === ScheduleWizardStep.SelectUsers && { ml: 3 }),
            }}
          >
            {stepRenderer}
          </Flex>
        </Flex>
      </Modal.Body>

      <AddScheduleWizardFooter />
    </>
  );
};
