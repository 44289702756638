import React, { useLayoutEffect, useRef } from 'react';

/**
 * Saves the provided value to a ref, updates the ref on value change and returns that ref.
 *
 * @param {T} value Value that wil be saved to the ref.
 *
 * @return {React.MutableRefObject<T>} Returns the created ref.
 */

export function useCallbackRef<T>(value: T): React.MutableRefObject<T> {
  const callbackRef = useRef(value);
  useLayoutEffect(() => {
    callbackRef.current = value;
  }, [value]);
  return callbackRef;
}
