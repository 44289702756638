import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { DateRangeBadge } from './DateRangeBadge';
import { DeselectMenu } from './DeselectMenu';
import { FetchingIndicator } from './FetchingIndicator';
import { Menu } from './Menu';
import { Title } from './Title';

type HeaderComponent = React.FC<HeaderProps> & {
  Title: typeof Title;
  Menu: typeof Menu;
  Deselect: typeof DeselectMenu;
  DateRangeBadge: typeof DateRangeBadge;
};

type HeaderProps = FlexProps & {
  showFetchingIndicator?: boolean;
};

export const Header: HeaderComponent = ({
  showFetchingIndicator,
  children,
  sx,
  ...props
}: HeaderProps): React.ReactElement => (
  <Flex
    {...props}
    as="header"
    sx={{
      alignItems: 'flex-start',
      pt: 4,
      gap: 2,
      ...(showFetchingIndicator && { position: 'relative' }),
      ...(sx && sx),
    }}
  >
    <>
      {showFetchingIndicator && <FetchingIndicator sx={{ position: 'absolute', top: 0 }} />}
      {children}
    </>
  </Flex>
);

Header.Title = Title;
Header.Menu = Menu;
Header.Deselect = DeselectMenu;
Header.DateRangeBadge = DateRangeBadge;
