import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { DefaultRole } from 'api/actions/organizationSession/organizationSessionActions.types';
import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { DEFAULT_WORK_STATUSES_IDS } from 'constants/common';
import { TO_REL } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { useLocations } from 'pages/Locations/output/useLocations';
import { activeWorkPositionsSelector, tagsSelector, visibleRolesSelector } from 'state/employees';
import { RequestFilterState, SEARCH_FILTER_TYPE } from 'state/filters';
import { languageSelector } from 'state/recoilState';
import { workStatusesSelector } from 'state/workStatuses';
import { SCHEDULES_PATTERNS_FOR_FILTER } from 'styles/theme/calendar_patterns';

import { FilterCheckbox } from './FilterCheckbox';
import { FilterGroup } from './FilterGroup';
import { FilterSearchInput } from './FilterSearchInput';
import { useTagOrRoleFilters } from './hooks/useTagOrRoleFilters';
import {
  CalendarSchedulesFilters,
  FilterGroupNames,
  FilterGroupProps,
  HideAcceptedRequestsFilter,
  HideEmptyColumnsFilter,
  HideEmptyFilter,
  HidePublishedFilter,
  HideTypesWithoutLimitsFilter,
  HideUsersWithoutDataFilter,
  HideUsersWithoutPhotosFilter,
  LocationDefaultFilters,
  PassedFilter,
  ReportDayStateFilters,
  ShowOnlyOfflineEventsFilter,
  TagsFilters,
  TeammatesFilters,
  TimeClocksStateFilters,
  TimeClocksSystemFilters,
  WorkPosiotionsFilters,
  WorkStatusSendVia,
  WorkStatusType,
  WorkStatusesFilters,
} from './types';

export const LocationsFilterGroup = () => {
  useLingui();
  const { locations } = useLocations();
  return (
    <FilterGroup
      openByDefault={false}
      name={FilterGroupNames.LOCATIONS}
      searchFilterType={SEARCH_FILTER_TYPE.LOCATIONS__FILTER_GROUP}
      title={t({ id: 'aside_filters.locations', message: 'Locations' })}
      filters={useMemo<FilterGroupProps['filters']>(
        () => [
          {
            id: LocationDefaultFilters.Any,
            label: t({ id: 'aside_filters.locations.any_location', message: 'Any location' }),
          },
          {
            id: LocationDefaultFilters.Without,
            label: t({ id: 'aside_filters.locations.without_location', message: 'Without location' }),
            withDivider: true,
          },
          ...(locations
            ? _.chain(locations)
                .map(({ id, name }) => ({ id, label: `${name}` }))
                .sortBy(({ label }) => label)
                .value()
            : []),
        ],
        [locations],
      )}
    />
  );
};

export const WorkPositionFilterGroup = () => {
  const workPositions = useRecoilValue(activeWorkPositionsSelector);
  const filters = useMemo(
    () =>
      _.chain(workPositions)
        .filter(({ isActive }) => isActive)
        .map(({ id, name }) => ({ id, label: `${name}` }))
        .sortBy(({ label }) => label)
        .value(),
    [workPositions],
  );

  if (!workPositions) return null;
  return (
    <FilterGroup
      openByDefault={false}
      name={FilterGroupNames.WORK_POSITION}
      searchFilterType={SEARCH_FILTER_TYPE.WORK_STATUSES__FILTER_GROUP}
      title={t({ id: 'schedule.work_position' })}
      filters={filters}
    />
  );
};

export const RequestStateFilterGroup = () => {
  const requestsStateFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: `${RequestFilterState.Pending}`,
        label: t({
          id: 'request.filters.pending',
          message: 'Pending',
        }),
        prependWith: 'icon',
        iconType: 'pending',
        color: 'asideFilters.request.pending',
      },
      {
        id: `${RequestFilterState.Accepted}`,
        label: t({
          id: 'request.filters.accepted',
          message: 'Accepted',
        }),
        prependWith: 'icon',
        iconType: 'approve',
        color: 'asideFilters.request.approve',
      },
      {
        id: `${RequestFilterState.Rejected}`,
        label: t({
          id: 'request.filters.rejected',
          message: 'Rejected',
        }),
        prependWith: 'icon',
        iconType: 'deny',
        color: 'asideFilters.request.deny',
      },
      {
        id: `${RequestFilterState.Deleted}`,
        label: t({
          id: 'request.filters.deleted',
          message: 'Deleted',
        }),
        prependWith: 'icon',
        iconType: 'delete',
        color: 'asideFilters.request.delete',
      },
    ],
    [],
  );
  return (
    <FilterGroup
      name={FilterGroupNames.REQUEST_STATE}
      title={t({
        id: 'request.filters.state',
        message: 'State',
      })}
      filters={requestsStateFilters}
    />
  );
};

export const RequestTypesFilterGroup = () => {
  const requestsTypeFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: `${RequestFormType.TimeOff}`,
        label: t({ id: 'request.type.time_off' }),
        prependWith: 'icon',
        iconType: 'timeOff',
      },
      {
        id: `${RequestFormType.Custom}`,
        label: t({ id: 'request.type.custom' }),
        prependWith: 'icon',
        iconType: 'customRequest',
      },
      {
        id: `${RequestFormType.TimeTracking}`,
        label: t({ id: 'request.type.time_tracking' }),
        prependWith: 'icon',
        iconType: 'timeClock',
      },
      {
        id: `${RequestFormType.BusinessTrip}`,
        label: t({ id: 'request.type.business_trip' }),
        prependWith: 'icon',
        iconType: 'businessTrip',
      },
      {
        id: `${RequestFormType.Schedule}`,
        label: t({ id: 'add_request.shift_swap' }),
        prependWith: 'icon',
        iconType: 'calendar',
      },
    ],
    [],
  );
  return (
    <FilterGroup
      name={FilterGroupNames.REQUEST_TYPES}
      title={t({ id: 'aside_filters.types', message: 'Types' })}
      filters={requestsTypeFilters}
    />
  );
};

export const RequestTypesCalendarFilterGroup = () => {
  const requestTypeFiltersForCalendar: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: `${RequestFormType.TimeOff}`,
        label: t({ id: 'request.type.time_off' }),
        prependWith: 'dot',
        color: 'reports.timeOff',
      },
      {
        id: `${RequestFormType.Custom}`,
        label: t({ id: 'request.type.custom' }),
        prependWith: 'roundedSquare',
        color: 'reports.customRequest',
        isOutlined: true,
      },
      {
        id: `${RequestFormType.BusinessTrip}`,
        label: t({ id: 'request.type.business_trip' }),
        prependWith: 'square',
        color: 'reports.businessTrip',
      },
    ],
    [],
  );
  return (
    <FilterGroup
      name={FilterGroupNames.REQUEST_TYPES}
      title={t({ id: 'aside_filters.request_types', message: 'Request types' })}
      filters={requestTypeFiltersForCalendar}
    />
  );
};

export const CalendarFilterGroup = () => {
  const calendarFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: CalendarSchedulesFilters.Fixed,
        label: t({ id: 'aside_filters.calendar.fixed', message: 'Fixed hours' }),
        prependWith: 'roundedSquare',
        color: 'asideFilters.calendar',
      },
      {
        id: CalendarSchedulesFilters.Flexible,
        label: t({ id: 'aside_filters.calendar.flexible', message: 'Flexible hours' }),
        prependWith: 'square',
        color: 'asideFilters.calendar',
      },
      {
        id: CalendarSchedulesFilters.Overtime,
        label: t({ id: 'aside_filters.calendar.overtime', message: 'Overtime schedules' }),
        prependWith: 'square',
        prependSx: {
          ...SCHEDULES_PATTERNS_FOR_FILTER.overtimeSchedule,
        },
        color: 'asideFilters.calendar',
      },
      {
        id: CalendarSchedulesFilters.WithOvertime,
        label: t({ id: 'aside_filters.calendar.with_overtime', message: 'With overtime' }),
        prependWith: 'icon',
        iconType: 'timer',
        color: 'asideFilters.calendar',
      },
      {
        id: CalendarSchedulesFilters.WithAutoBreak,
        label: t({ id: 'aside_filters.calendar.with_auto_break', message: 'With auto-break' }),
        prependWith: 'icon',
        iconType: 'coffee',
        color: 'asideFilters.calendar',
      },
      {
        id: CalendarSchedulesFilters.Error,
        label: t({ id: 'aside_filters.calendar.error', message: 'Error' }),
        prependWith: 'icon',
        iconType: 'warning',
        color: 'reports.error',
      },
    ],
    [],
  );
  return (
    <FilterGroup
      name={FilterGroupNames.CALENDAR}
      title={t({ id: 'aside_filters.calendar.schedules', message: 'Schedules' })}
      filters={calendarFilters}
    />
  );
};

export const ClockLogFilterSearchInput = () => {
  const { isClockLogPhotoLog } = useAppRouting(['isClockLogPhotoLog']);

  return (
    <>
      {isClockLogPhotoLog ? (
        <FilterSearchInput key={SEARCH_FILTER_TYPE.TEAMMATES} type={SEARCH_FILTER_TYPE.TEAMMATES} />
      ) : (
        <FilterSearchInput
          key={SEARCH_FILTER_TYPE.CLOCK_LOG__TIME_EVENTS}
          type={SEARCH_FILTER_TYPE.CLOCK_LOG__TIME_EVENTS}
        />
      )}
    </>
  );
};
const WORKSTATUS_ICON_TYPE: Record<string, Icons> = {
  [DEFAULT_WORK_STATUSES_IDS.ENTER]: 'playOutline',
  [DEFAULT_WORK_STATUSES_IDS.EXIT]: 'stopOutline',
  [DEFAULT_WORK_STATUSES_IDS.PAID_BREAK]: 'break',
  [DEFAULT_WORK_STATUSES_IDS.UNPAID_BREAK]: 'break',
  [DEFAULT_WORK_STATUSES_IDS.TASK]: 'task',
};

const FIRST_CUSTOM_WORK_STATUS_INDEX = 4;
export const WorkStatusesFilterGroup = () => {
  const workStatuses = useRecoilValue(workStatusesSelector);
  const clockLogWorkStatusesFilters = useMemo<FilterGroupProps['filters']>(
    () =>
      workStatuses
        ? _.chain(workStatuses)
            .map<PassedFilter>(({ id, name }, _i, arr) => ({
              id,
              label: t({ id: name, message: name }),
              prependWith: 'icon',
              iconType: WORKSTATUS_ICON_TYPE[id] || 'workStatus',
              // after unpaid break we should add divider when there are some other custom statuses
              ...(id === DEFAULT_WORK_STATUSES_IDS.UNPAID_BREAK &&
                arr.length > FIRST_CUSTOM_WORK_STATUS_INDEX && { withDivider: true }),
            }))
            .sortBy(({ id, label }) => {
              if (id === DEFAULT_WORK_STATUSES_IDS.ENTER) return -4;
              if (id === DEFAULT_WORK_STATUSES_IDS.EXIT) return -3;
              if (id === DEFAULT_WORK_STATUSES_IDS.PAID_BREAK) return -2;
              if (id === DEFAULT_WORK_STATUSES_IDS.UNPAID_BREAK) return -1;

              return label;
            })
            .value()
        : [],
    [workStatuses],
  );

  return (
    <FilterGroup
      openByDefault={false}
      name={FilterGroupNames.WORK_STATUSES}
      searchFilterType={SEARCH_FILTER_TYPE.WORK_POSITION__FILTER_GROUP}
      title={t({ id: 'aside_filters.work_statuses.type', message: 'Status' })}
      filters={clockLogWorkStatusesFilters}
    />
  );
};
export const WorkTypeFilterGroup: FC = () => {
  const filters = useMemo<FilterGroupProps['filters']>(
    () => [
      // TODO uncomment when tasks will be done
      // {
      //   id: WorkStatusType.Tasks,
      //   label: t({ id: 'aside_filters.work_statuses_type.tasks', message: 'Tasks' }),
      //   prependWith: 'icon',
      //   iconType: 'task',
      // },
      {
        id: WorkStatusType.Statuses,
        label: t({ id: 'aside_filters.work_statuses_type.work_statuses', message: 'Work statuses' }),
        prependWith: 'icon',
        iconType: 'workStatus',
      },
      {
        id: WorkStatusType.Breaks,
        label: t({ id: 'aside_filters.work_statuses_type.breaks', message: 'Breaks' }),
        prependWith: 'icon',
        iconType: 'break',
      },
    ],
    [],
  );

  return (
    <FilterGroup
      name={FilterGroupNames.WORK_STATUS_TYPE}
      title={t({ id: 'aside_filters.work_statuses_type', message: 'Type' })}
      filters={filters}
    />
  );
};
export const SendViaFilterGroup: FC = () => {
  const filters = useMemo<FilterGroupProps['filters']>(
    () => [
      {
        id: WorkStatusSendVia.MobileTimeClock,
        label: t({ id: 'time_clocks.mobile_time_clock', message: 'Mobile Time Clock' }),
        prependWith: 'icon',
        iconType: 'timeClockMobile',
      },
      {
        id: WorkStatusSendVia.MobileApp,
        label: t({ id: 'time_clocks.mobile_app', message: 'Mobile app' }),
        prependWith: 'icon',
        iconType: 'mobileApp',
      },
      {
        id: WorkStatusSendVia.WebApp,
        label: t({ id: 'time_clocks.web_app', message: 'Web App' }),
        prependWith: 'icon',
        iconType: 'webApp',
      },
      {
        id: WorkStatusSendVia.WebKiosk,
        label: t({ id: 'time_clocks.web_kiosk', message: 'Web Kiosk' }),
        prependWith: 'icon',
        iconType: 'kiosk',
      },
      {
        id: WorkStatusSendVia.ManuallyAdded,
        label: t({ id: 'time_clocks.manually_added', message: 'Manually added' }),
        prependWith: 'icon',
        iconType: 'system',
      },
    ],
    [],
  );

  return (
    <FilterGroup
      name={FilterGroupNames.WORK_STATUS_SEND_VIA}
      title={t({ id: 'aside_filters.send_via', message: 'Send via' })}
      filters={filters}
    />
  );
};

export const ShowOnlyOfflineEvents: FC = () => (
  <FilterCheckbox
    name={FilterGroupNames.SHOW_ONLY_OFFLINE_EVENTS}
    filterId={ShowOnlyOfflineEventsFilter.Offline}
    title={t({ id: 'aside_filters.work_statuses_type.offline_events', message: 'Show only events from Offline Mode' })}
  />
);
export const HideUsersWithoutPhotosFilterCheckbox = () => (
  <FilterCheckbox
    name={FilterGroupNames.HIDE_USERS_WITHOUT_PHOTOS}
    filterId={HideUsersWithoutPhotosFilter.PHOTOS_ONLY}
    title={t({ id: 'aside_filters.photo_log.hide_users_without_photos', message: 'Hide users without photos' })}
  />
);

export const TeammatesStateFilterGroup = (props: Partial<FilterGroupProps>) => {
  const teammatesStateFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: TeammatesFilters.ACTIVE,
        label: t({ id: 'aside_filters.teammates.active', message: 'Active' }),
      },
      {
        id: TeammatesFilters.INVITED,
        label: t({ id: 'aside_filters.teammates.invited', message: 'Invited' }),
      },
      {
        id: TeammatesFilters.HIDDEN,
        label: t({ id: 'aside_filters.teammates.hidden', message: 'Hidden' }),
      },
      {
        id: TeammatesFilters.DEACTIVATED,
        label: t({ id: 'aside_filters.teammates.deactivated', message: 'Deactivated' }),
      },
    ],
    [],
  );

  return (
    <FilterGroup
      name={FilterGroupNames.TEAMMATES}
      title={t({ id: 'aside_filters.teammates', message: 'Teammates' })}
      filters={teammatesStateFilters}
      {...props}
    />
  );
};

export const RolesFilterGroup = (props: Partial<FilterGroupProps>) => {
  const language = useRecoilValue(languageSelector);
  const roles = useRecoilValue(visibleRolesSelector);

  const { systemManagement } = useAppPermissions();

  const getTagOrRoleFilter = useTagOrRoleFilters();
  const rolesDropdownProps: FilterGroupProps['dropdownProps'] = useMemo(
    () =>
      systemManagement.Permissions
        ? {
            links: [
              {
                to: TO_REL.ADD_ROLE_MODAL[language],
                label: t({ id: 'aside_filters.add', message: 'Add' }),
                relative: 'path' as const,
              },
              {
                to: TO_REL.MANAGE_ROLES_MODAL[language],
                label: t({ id: 'aside_filters.manage', message: 'Manage' }),
                relative: 'path' as const,
              },
            ],
          }
        : undefined,
    [language, systemManagement.Permissions],
  );

  const rolesFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      ...(roles
        ? _.chain(roles)
            .map(getTagOrRoleFilter(true, roles.length > 4))
            .sortBy([
              ({ id }) => id && (+id as DefaultRole) !== DefaultRole.Employee,
              ({ id }) => id && (+id as DefaultRole) !== DefaultRole.Supervisor,
              ({ id }) => id && (+id as DefaultRole) !== DefaultRole.Manager,
              ({ id }) => id && (+id as DefaultRole) !== DefaultRole.Admin,
              ({ label }) => label,
            ])
            .value()
        : []),
    ],
    [getTagOrRoleFilter, roles],
  );
  return (
    <FilterGroup
      name={FilterGroupNames.ROLES}
      searchFilterType={SEARCH_FILTER_TYPE.ROLES__FILTER_GROUP}
      title={t({ id: 'aside_filters.roles', message: 'Roles' })}
      filters={rolesFilters}
      dropdownProps={rolesDropdownProps}
      {...props}
    />
  );
};

export const TagsFilterGroup = (props: Partial<FilterGroupProps>) => {
  const language = useRecoilValue(languageSelector);
  const tags = useRecoilValue(tagsSelector);

  const { systemManagement } = useAppPermissions();

  const getTagOrRoleFilter = useTagOrRoleFilters();
  const tagsDropdownProps: FilterGroupProps['dropdownProps'] = useMemo(
    () =>
      systemManagement.Permissions
        ? {
            links: [
              {
                to: TO_REL.ADD_TAG_MODAL[language],
                relative: 'path' as const,
                label: t({ id: 'aside_filters.add', message: 'Add' }),
              },
              {
                to: TO_REL.MANAGE_TAGS_MODAL[language],
                relative: 'path' as const,
                label: t({ id: 'aside_filters.manage', message: 'Manage' }),
              },
            ],
          }
        : undefined,
    [language, systemManagement.Permissions],
  );

  const tagsFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: TagsFilters.NO_TAGS,
        label: t({ id: 'aside_filters.no_tags', message: 'No tags' }),
        isUnfiltrable: true,
      },
      ...(tags
        ? _.chain(tags)
            .map(getTagOrRoleFilter())
            .orderBy([({ isInformational }) => !!isInformational, ({ label }) => label], ['asc', 'asc'])
            .value()
        : []),
    ],
    [getTagOrRoleFilter, tags],
  );

  const tagsAdditionalLinks: FilterGroupProps['additionalLinks'] = useMemo(
    () =>
      systemManagement.Permissions
        ? [
            {
              to: TO_REL.ADD_TAG_MODAL[language],
              relative: 'path' as const,
              children: t({ id: 'team.add_tag' }),
              prependWith: <Icon type="plus" size={18} />,
            },
          ]
        : undefined,
    [language, systemManagement.Permissions],
  );
  return (
    <FilterGroup
      name={FilterGroupNames.TAGS}
      searchFilterType={SEARCH_FILTER_TYPE.TAGS__FILTER_GROUP}
      title={t({ id: 'aside_filters.tags', message: 'Tags' })}
      filters={tagsFilters}
      dropdownProps={tagsDropdownProps}
      additionalLinks={tagsAdditionalLinks}
      {...props}
    />
  );
};

export const WorkStatusesStateFilterGroup = () => {
  const workStatusesFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: WorkStatusesFilters.ACTIVE,
        label: t({ id: 'work_statuses.filters.active', message: 'Active' }),
        prependWith: 'dot',
        color: 'asideFilters.statusTypes.active',
      },
      {
        id: WorkStatusesFilters.DELETED,
        label: t({ id: 'work_statuses.filters.deleted', message: 'Deleted' }),
        prependWith: 'dot',
        color: 'asideFilters.statusTypes.deleted',
      },
    ],
    [],
  );
  return (
    <FilterGroup
      name={FilterGroupNames.WORK_STATUSES_STATE}
      title={t({ id: 'work_statuses.state', message: 'State' })}
      filters={workStatusesFilters}
    />
  );
};

export const TimeClocksStateFilterGroup = () => {
  const timeClocksStateFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: TimeClocksStateFilters.ONLINE,
        label: t({ id: 'time_clocks.online' }),
      },
      {
        id: TimeClocksStateFilters.OFFLINE,
        label: t({ id: 'time_clocks.offline' }),
      },
    ],
    [],
  );
  return (
    <FilterGroup
      name={FilterGroupNames.TIME_CLOCKS__STATE}
      title={t({ id: 'time_clocks.state' })}
      filters={timeClocksStateFilters}
    />
  );
};
export const TimeClocksSystemFilterGroup = () => {
  const timeClocksSystemFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: TimeClocksSystemFilters.IOS,
        label: 'iOS',
        prependWith: 'icon',
        iconType: 'apple',
      },
      {
        id: TimeClocksSystemFilters.ANDROID,
        label: 'Android',
        prependWith: 'icon',
        iconType: 'android',
      },
    ],
    [],
  );
  return (
    <FilterGroup
      name={FilterGroupNames.TIME_CLOCKS__SYSTEM}
      title={t({ id: 'time_clocks.system', message: 'System' })}
      filters={timeClocksSystemFilters}
    />
  );
};

export const WorkPositionTeamFilterGroup = () => {
  const language = useRecoilValue(languageSelector);
  const workPositions = useRecoilValue(activeWorkPositionsSelector);
  const { modulesManagement, systemManagement } = useAppPermissions();

  const workPositionsDropdownProps: FilterGroupProps['dropdownProps'] = useMemo(
    () =>
      modulesManagement.Employees
        ? {
            links: [
              {
                to: TO_REL.ADD_WORK_POSITION_MODAL[language],
                relative: 'path' as const,
                label: t({ id: 'aside_filters.add', message: 'Add' }),
              },
              {
                to: TO_REL.MANAGE_WORK_POSITIONS_MODAL[language],
                relative: 'path' as const,
                label: t({ id: 'aside_filters.manage', message: 'Manage' }),
              },
            ],
          }
        : undefined,
    [language, modulesManagement.Employees],
  );

  const workPositionsFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: WorkPosiotionsFilters.NO_POSITION,
        label: t({ id: 'aside_filters.no_position', message: 'No position' }),
        isUnfiltrable: true,
      },
      ...(workPositions
        ? _.chain(workPositions)
            .filter(({ isActive }) => isActive)
            .map(({ id, name }) => ({ id, label: `${name}` }))
            .sortBy(({ label }) => label)
            .value()
        : []),
    ],
    [workPositions],
  );

  const workPositionsAdditionalLinks: FilterGroupProps['additionalLinks'] = useMemo(
    () =>
      systemManagement.Permissions
        ? [
            {
              to: TO_REL.ADD_WORK_POSITION_MODAL[language],
              relative: 'path' as const,
              children: t({ id: 'team.add_position' }),
              prependWith: <Icon type="plus" size={18} />,
            },
          ]
        : undefined,
    [language, systemManagement.Permissions],
  );
  return (
    <FilterGroup
      name={FilterGroupNames.WORK_POSITION_TEAM}
      searchFilterType={SEARCH_FILTER_TYPE.WORK_POSITION_TEAM__FILTER_GROUP}
      title={t({ id: 'aside_filters.positions', message: 'Positions' })}
      dropdownProps={workPositionsDropdownProps}
      filters={workPositionsFilters}
      additionalLinks={workPositionsAdditionalLinks}
    />
  );
};

export const ReportsAttendanceStateFilterGroup = () => {
  const reportsStateFilters: FilterGroupProps['filters'] = useMemo(
    () => [
      {
        id: ReportDayStateFilters.Present,
        label: t({ id: 'aside_filters.present', message: 'Present' }),
        prependWith: 'roundedSquare',
        color: 'reports.present',
      },
      {
        id: ReportDayStateFilters.IncompleteShift,
        label: t({ id: 'aside_filters.incomplete_shift', message: 'Incomplete shift' }),
        prependWith: 'roundedSquare',
        color: 'reports.incompleteShift',
      },
      {
        id: ReportDayStateFilters.Overtime,
        label: t({ id: 'aside_filters.overtime', message: 'Overtime' }),
        prependWith: 'roundedSquare',
        color: 'reports.overtime',
      },
      {
        id: ReportDayStateFilters.TimeOff,
        label: t({ id: 'aside_filters.time_off', message: 'Time Off' }),
        prependWith: 'dot' as const,
        color: 'reports.timeOff',
      },
      {
        id: ReportDayStateFilters.Absent,
        label: t({ id: 'aside_filters.absent', message: 'Absent' }),
        prependWith: 'dot',
        color: 'reports.absent',
      },
      {
        id: ReportDayStateFilters.CustomRequest,
        label: t({ id: 'aside_filters.custom_request', message: 'Custom request' }),
        prependWith: 'dot' as const,
        isOutlined: true,
        color: 'reports.customRequest',
      },
      {
        id: ReportDayStateFilters.BusinessTrip,
        label: t({ id: 'aside_filters.business_trip', message: 'Business trip' }),
        prependWith: 'dot' as const,
        isOutlined: true,
        color: 'reports.businessTrip',
      },
      {
        id: ReportDayStateFilters.Error,
        label: t({ id: 'aside_filters.error', message: 'Error' }),
        prependWith: 'icon',
        iconType: 'warning',
        color: 'reports.error',
      },
    ],
    [],
  );
  return (
    <FilterGroup
      name={FilterGroupNames.REPORTS_ATTENDANCE_STATE}
      title={t({ id: 'aside_filters.state', message: 'State' })}
      filters={reportsStateFilters}
    />
  );
};

export const HidePublishedFilterCheckbox = () => (
  <FilterCheckbox
    name={FilterGroupNames.HIDE_PUBLISHED}
    filterId={HidePublishedFilter.IsActive}
    title={t({ id: 'aside_filters.calendar.hide_published', message: 'Hide published schedules' })}
  />
);

export const HideAcceptedRequestsFilterCheckbox = () => (
  <FilterCheckbox
    name={FilterGroupNames.HIDE_ACCEPTED_REQUESTS}
    filterId={HideAcceptedRequestsFilter.IsActive}
    title={t({ id: 'aside_filters.calendar.hide_accepted_requests', message: 'Hide accepted requests' })}
  />
);

export const HideEmptyFilterCheckbox = () => (
  <FilterCheckbox
    name={FilterGroupNames.HIDE_EMPTY}
    filterId={HideEmptyFilter.IsActive}
    title={t({ id: 'aside_filters.reports.hide_empty', message: 'Hide empty teammates' })}
  />
);

export const HideEmptyColumnsFilterCheckbox = () => (
  <FilterCheckbox
    name={FilterGroupNames.HIDE_EMPTY_COLUMNS}
    filterId={HideEmptyColumnsFilter.IsActive}
    title={t({ id: 'aside_filters.reports.hide_empty_columns', message: 'Hide empty columns' })}
  />
);

export const HideTypesWithoutLimitsFilterCheckbox = () => (
  <FilterCheckbox
    name={FilterGroupNames.HIDE_TYPES_WITHOUT_LIMITS}
    filterId={HideTypesWithoutLimitsFilter.IsActive}
    title={t({ id: 'aside_filters.reports.hide_types_no_limits', message: 'Hide types without limits' })}
  />
);

export const HideUsersWithoutDataFilterCheckbox = () => (
  <FilterCheckbox
    name={FilterGroupNames.HIDE_USERS_WITHOUT_DATA}
    filterId={HideUsersWithoutDataFilter.IsActive}
    title={t({ id: 'aside_filters.reports.hide_users_no_data', message: 'Hide users without data' })}
  />
);
