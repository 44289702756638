import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback, useState } from 'react';
import { useQuery } from 'react-fetching-library';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, FlexProps } from 'theme-ui';

import { getCurrentUserRequestLimitsAction } from 'api/actions/requests/requestsActions';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { Button } from 'components/ui/Buttons';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { useMount } from 'hooks/useMount/useMount';
import { currentUserRequestLimitsAtom, showAllCurrentUserRequestLimitsAtom } from 'state/home';
import { languageSelector } from 'state/recoilState';
import { Step } from 'state/requests';
import { CustomEvents } from 'utils/customEvents';
import { HomeBox } from '../HomeBox/HomeBox';

import { RequestLimitsList } from './components/RequestLimitsList';

type Props = FlexProps & {
  userId: string;
};

export const RequestLimits: FC<Props> = ({ userId, ...props }) => {
  useLingui();
  const [isLoading, setIsLoading] = useState(true);

  const setCurrentUserRequestLimits = useSetRecoilState(currentUserRequestLimitsAtom);
  const [showAllCurrentUserRequestLimits, setShowAllCurrentUserRequestLimits] = useRecoilState(
    showAllCurrentUserRequestLimitsAtom,
  );
  const language = useRecoilValue(languageSelector);

  const navigate = useAppNavigate();
  const { query } = useQuery(getCurrentUserRequestLimitsAction(), false);

  const fetchCurrentLimits = useCallback(async () => {
    const { payload, error } = await query();

    if (!error && payload) {
      const { requests } = payload;

      setCurrentUserRequestLimits(requests);
      setIsLoading(false);
    }
  }, [query, setCurrentUserRequestLimits]);

  useMount(() => {
    void fetchCurrentLimits();
  });

  useCustomEventListener(
    [
      CustomEvents.ACCEPT_REQUEST_MANAGEMENT_SUCCESS_RESPONSE_RECEIVED,
      CustomEvents.ADD_REQUEST_SUCCESS_RESPONSE_RECEIVED,
      CustomEvents.DELETE_REQUESTS_SUCCESS_RESPONSE_RECEIVED,
      CustomEvents.REJECT_REQUEST_SUCCESS_RESPONSE_RECEIVED,
    ],
    () => {
      void fetchCurrentLimits();
    },
  );

  const handleOnAddRequestClick = useCallback(() => {
    navigate(TO_REL.ADD_REQUEST_MODAL[language], {
      state: {
        requestConfig: { employeesIds: [userId], excludedSteps: [Step.SelectTeammates] },
      },
    });
  }, [language, navigate, userId]);

  return (
    <HomeBox {...props}>
      <HomeBox.Header>
        <HomeBox.Title>
          <Trans id="home.your_current_limits">Request limits and usage</Trans>
        </HomeBox.Title>
        <Flex sx={{ flexWrap: 'wrap-reverse', justifyContent: 'flex-end', maxWidth: '50%' }}>
          <Button
            sx={{ fontWeight: 'normal' }}
            shape="rounded"
            variant="naked"
            onClick={() => setShowAllCurrentUserRequestLimits((prev) => !prev)}
          >
            {showAllCurrentUserRequestLimits
              ? t({ id: 'home.show_less', message: 'Show less' })
              : t({ id: 'home.show_all', message: 'Show all' })}
          </Button>
          <Button shape="rounded" variant="grey" onClick={handleOnAddRequestClick}>
            {t({ id: 'reports.add_request' })}
          </Button>
        </Flex>
      </HomeBox.Header>
      <Flex sx={{ position: 'relative', flexDirection: 'column', gap: '0.125rem' }}>
        {isLoading && <LoadingOverlay />}
        <RequestLimitsList />
      </Flex>
    </HomeBox>
  );
};
