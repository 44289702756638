import { useRecoilValue } from 'recoil';
import { Navigate } from 'react-router-dom';

import { OrganizationSessionInitializer } from 'context/session/organizationSessionInitializer/OrganizationSessionInitializer';
import { RefreshSessionObserver } from 'observers/RefreshSessionObserver';
import { DataSideEffectsObserver } from 'observers/DataSideEffectsObserver';
import { AppPermissionRedirectObserver } from 'observers/AppPermissionRedirectObserver';
import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';

import { Kiosk } from './Kiosk';
import { UnmountObserver } from './observers/UnmountObserver';

export const KioskContextApp = () => {
  const language = useRecoilValue(languageSelector);
  const { systemManagement, isInitialized } = useAppPermissions();

  return (
    <OrganizationSessionInitializer>
      <RefreshSessionObserver />
      <DataSideEffectsObserver />
      <AppPermissionRedirectObserver />
      <UnmountObserver />
      {!systemManagement.AccessKioskMode && isInitialized && <Navigate to={TO.START[language]} />}
      <Kiosk />
    </OrganizationSessionInitializer>
  );
};
