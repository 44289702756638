import { ThemeUICSSObject, ThemeUIStyleObject } from 'theme-ui';

import { makeTheme } from '../makeTheme';

import { SCHEDULES_PATTERNS } from './calendar_patterns';

const bookmarkStyles: ThemeUIStyleObject = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  height: '1rem',
  transform: 'translateY(-100%)',
  px: 2,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 1,
  fontWeight: '700',
  textAlign: 'center',
  color: 'reports.bookmark.text',
  borderTopLeftRadius: 'xs',
  borderTopRightRadius: 'xs',
  boxShadow: 'bookmark',
  '> span': {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    opacity: 0.8,
  },
};

const cellDiverPseudoStyles: ThemeUICSSObject = {
  '&[data-divider="true"]': {
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-1px',
      top: '-1px',
      bottom: '-1px',
      bg: 'reports.cellDiver',
      width: '1px',
    },

    '> .bookmark': {
      left: '-1px',
    },
  },
};

const timesheetsStyles: Record<string, ThemeUICSSObject> = {
  summaryPersonHeaderCell: {
    textAlign: 'left',
    alignItems: 'center',
    gap: 1,
  },
  summaryHeaderCell: {
    bg: 'reports.bg.default.header',
    height: '100%',
    position: 'relative',
    alignSelf: 'center',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    px: 2,
    color: 'reports.text.default',
    fontSize: 0,
    fontWeight: 'bold',
    lineHeight: 'heading',
    textAlign: 'center',
    wordBreak: 'break-word',
    '> .bookmark': {
      ...bookmarkStyles,
      fontSize: 0,
      bg: 'reports.bg.default.bookmark',
    },
    ...cellDiverPseudoStyles,
    '&[data-type="secondary"]': {
      bg: 'reports.bg.secondary.header',
      color: 'reports.text.secondary',
    },
    '&[data-type="present"]': {
      bg: 'reports.present',
    },
    '&[data-type="absent"]': {
      bg: 'reports.absent',
    },
    '&[data-type="businessTrip"]': {
      color: 'reports.businessTrip',
      bg: 'reports.bg.secondary.header',
    },
    '&[data-type="customRequest"]': {
      color: 'reports.customRequest',
      bg: 'reports.bg.secondary.header',
    },
    '&[data-type="incompleteShift"]': {
      bg: 'reports.incompleteShift',
    },
    '&[data-type="overtime"]': {
      bg: 'reports.overtime',
    },
    '&[data-type="timeOff"]': {
      bg: 'reports.timeOff',
    },
  },
  summaryCell: {
    bg: 'reports.bg.default.cell',
    height: '100%',
    position: 'relative',
    alignSelf: 'center',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    p: '0.75rem',
    mx: '-0.75rem',
    textAlign: 'center',
    ...cellDiverPseudoStyles,
    '&[data-type="secondary"]': {
      bg: 'reports.bg.secondary.cell',
      color: 'reports.text.secondary',
    },
    '&[data-type="present"]': {
      bg: 'reports.alphas.present',
    },
    '&[data-type="absent"]': {
      bg: 'reports.alphas.absent',
    },
    '&[data-type="businessTrip"]': {
      color: 'reports.businessTrip',
      bg: 'reports.bg.secondary.cell',
    },
    '&[data-type="customRequest"]': {
      color: 'reports.customRequest',
      bg: 'reports.bg.secondary.cell',
    },
    '&[data-type="incompleteShift"]': {
      bg: 'reports.alphas.incompleteShift',
    },
    '&[data-type="overtime"]': {
      bg: 'reports.alphas.overtime',
    },
    '&[data-type="timeOff"]': {
      bg: 'reports.alphas.timeOff',
    },
    // FOR DETAILS CELLS
    '&[data-details="true"]': {
      bg: 'reports.bg.default.details',
      '&[data-type="secondary"]': {
        bg: 'reports.bg.secondary.details',
        color: 'reports.text.secondary',
      },
      '&[data-type="present"]': {
        bg: 'reports.present',
      },
      '&[data-type="absent"]': {
        bg: 'reports.absent',
      },
      '&[data-type="businessTrip"]': {
        bg: 'reports.businessTrip',
      },
      '&[data-type="customRequest"]': {
        bg: 'reports.customRequest',
      },
      '&[data-type="incompleteShift"]': {
        bg: 'reports.incompleteShift',
      },
      '&[data-type="overtime"]': {
        bg: 'reports.overtime',
      },
      '&[data-type="timeOff"]': {
        bg: 'reports.timeOff',
      },
      // Special cases
      '&[data-weekend="true"]:not([data-holiday="true"]):not([data-errors="true"])': {
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          left: '0',
          top: '0',
          bottom: '0',
          right: '0',
          bg: 'reports.bg.weekend.details',
          zIndex: -1,
        },
      },
      '&[data-holiday="true"]:not([data-errors="true"])': {
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          left: '0',
          top: '0',
          bottom: '0',
          right: '0',
          bg: 'reports.bg.holiday.cell',
          zIndex: -1,
        },
      },
    },
    '&[data-errors="true"]': {
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '0',
        top: '0',
        bottom: '0',
        right: '0',
        bg: 'reports.alphas.error',
        zIndex: -1,
      },
    },
  },
  summaryCellValue: {
    '&[data-fancy="true"]': {
      color: 'reports.text.fancy',
    },
  },
  summaryDayCell: {
    bg: 'reports.bg.default.details',
    height: '100%',
    position: 'relative',
    alignSelf: 'center',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    px: '1.5rem',
    mx: '-0.75rem',
    color: 'reports.text.default',
    textTransform: 'none',
    fontSize: 2,
    lineHeight: 'heading',
    cursor: 'pointer',
    '> .weekday': {
      fontWeight: '700',
    },
    '> .badges': {
      width: '60%',
      position: 'absolute',
      left: 2,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 1,
      fontWeight: '700',
      textAlign: 'center',
      gap: 2,
      flexWrap: 'wrap',
      '> .badges__badge': {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 'pill',
        maxWidth: '160px',
        px: 2,
      },
      '> .badges__badge--holiday': {
        color: 'reports.text.default',
        bg: 'reports.bg.holiday.bookmark',
        '> span': {
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          opacity: 0.8,
        },
      },
      '> .badges__badge--balanced': {
        color: 'reports.text.balanced',
        bg: 'reports.bg.balanced',
      },
    },
    '&[data-errors="true"]': {
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '0',
        top: '0',
        bottom: '0',
        right: '0',
        bg: 'reports.alphas.error',
        zIndex: -1,
      },
    },
    '&[data-weekend="true"]:not([data-holiday="true"]):not([data-errors="true"])': {
      bg: 'reports.bg.weekend.details',
      color: 'reports.text.weekend',
      '> span': {
        color: 'inherit',
      },
    },
    '&[data-holiday="true"]': {
      '&:not([data-errors="true"])': {
        bg: 'reports.bg.holiday.cell',
      },
      '> .bookmark': {
        position: 'absolute',
        left: 2,
        top: '50%',
        transform: 'translateY(-50%)',
        px: 2,
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 1,
        fontWeight: '700',
        textAlign: 'center',
        color: 'reports.text.default',
        borderRadius: 'pill',
        bg: 'reports.bg.holiday.bookmark',
        maxWidth: '160px',
        '> span': {
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          opacity: 0.8,
        },
      },
    },
    '&[data-today="true"]:not([data-pending-request="true"])': {
      '&::after': {
        content: '""',
        display: 'block',
        width: '2px',
        position: 'absolute',
        top: 16,
        bottom: 16,
        right: '4px',
        bg: 'reports.bg.today',
      },
    },
    '&[data-unpublished-changes="true"]': {
      ...SCHEDULES_PATTERNS.pending,
      outline: '1px solid',
      outlineColor: 'reports.summaryDayCell.unpublishedChanges',
    },
    '&[data-pending-request="true"]': {
      '&::after': {
        ...SCHEDULES_PATTERNS.pendingRequest,
        content: '""',
        position: 'absolute',
        inset: -1,
        zIndex: 0,
      },
    },
    '&[data-details-open="true"]': {
      '> .selected': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        border: '3px solid',
        borderColor: 'reports.summaryDayCell.detailsOpen.border',
        bg: 'reports.summaryDayCell.detailsOpen.bg',
      },
    },
  },
};

const fancyListItemSx = {
  bg: 'reports.fancyListItem.bg',
  px: 2,
  py: 2,
  minHeight: '2.5rem',
  alignItems: 'center',
  fontSize: 2,
  outline: '1px solid',
  outlineColor: 'reports.fancyListItem.outline',
  gap: 2,
  // '&[data-dropdown="true"]': {},
  '&[data-interactive="true"]': {
    cursor: 'pointer',
    '&:hover': {
      outlineColor: 'reports.fancyListItem.hover.outline',
      bg: 'reports.fancyListItem.hover.bg',
      boxShadow: 'fancyListItem.default',
    },
  },
};

export const reportStyles = makeTheme({
  reports: {
    ...timesheetsStyles,
    dayHeaderCell: {
      bg: 'reports.bg.default.header',
      height: '100%',
      position: 'relative',
      alignSelf: 'center',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'flex-end',
      px: '0.75rem',
      color: 'reports.text.default',
      textTransform: 'none',
      fontSize: 2,
      lineHeight: 'heading',
      '> .weekday': {
        fontWeight: '700',
      },
      '&[data-weekend="true"]:not([data-holiday="true"])': {
        bg: 'reports.bg.weekend.header',
        color: 'reports.text.weekend',
        '> span': {
          color: 'inherit',
        },
      },
      '&[data-holiday="true"]': {
        bg: 'reports.bg.holiday.header',
        '> .bookmark': {
          ...bookmarkStyles,
          bg: 'reports.bg.holiday.bookmark',
        },
      },
      '&[data-today="true"]': {
        '&::after': {
          content: '""',
          display: 'block',
          height: '2px',
          position: 'absolute',
          left: 20,
          right: 20,
          bottom: 0,
          bg: 'reports.bg.today',
        },
      },
    },
    dayCell: {
      position: 'relative',
      mx: '-0.75rem',
      flex: 1,
      alignSelf: 'stretch',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&[data-details-open="true"]': {
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          border: '3px solid',
          borderColor: 'reports.dayCell.detailsOpen.border',
          bg: 'reports.dayCell.detailsOpen.bg',
        },
      },
      '&[data-weekend="true"]': {
        bg: 'reports.bg.weekend.cell',
      },
      '&[data-holiday="true"]': {
        bg: 'reports.bg.holiday.cell',
      },
      '&[data-unpublished-changes="true"]': {
        ...SCHEDULES_PATTERNS.pending,
        outline: '1px solid',
        outlineColor: 'reports.dayCell.unpublishedChanges',
      },
      '&[data-pending-request="true"]': {
        '&::before': {
          ...SCHEDULES_PATTERNS.pendingRequest,
          content: '""',
          position: 'absolute',
          inset: -1,
          zIndex: 0,
        },
      },
      '&:hover': {
        '> div': {
          opacity: 0.8,
        },
      },
    },
    dayCellState: {
      overflow: 'hidden',
      flexDirection: 'column',
      flex: 1,
      alignSelf: 'stretch',
      my: 1,
      outline: '1px solid',
      outlineColor: 'reports.dayCellState.outline',
      '&[data-start="true"]': {
        ml: 1,
        '&[data-type="present"], &[data-type="incompleteShift"], &[data-type="overtime"]': {
          borderTopLeftRadius: 'sm',
          borderBottomLeftRadius: 'sm',
        },
        '&[data-type="absent"], &[data-type="timeOff"]': {
          borderTopLeftRadius: 'pill',
          borderBottomLeftRadius: 'pill',
        },
      },
      '&[data-end="true"]': {
        mr: 1,
        '&[data-type="present"], &[data-type="incompleteShift"], &[data-type="overtime"]': {
          borderTopRightRadius: 'sm',
          borderBottomRightRadius: 'sm',
        },
        '&[data-type="absent"], &[data-type="timeOff"]': {
          borderTopRightRadius: 'pill',
          borderBottomRightRadius: 'pill',
        },
      },
      '&[data-today="true"]': {},
      '&[data-type="present"]': {
        bg: 'reports.present',
        outlineColor: 'reports.present',
      },
      '&[data-type="incompleteShift"]': {
        bg: 'reports.incompleteShift',
        outlineColor: 'reports.incompleteShift',
      },
      '&[data-type="overtime"]': {
        bg: 'reports.overtime',
        outlineColor: 'reports.overtime',
      },
      '&[data-type="timeOff"]': {
        bg: 'reports.timeOff',
        outlineColor: 'reports.timeOff',
      },
      '&[data-type="absent"]': {
        bg: 'reports.absent',
        outlineColor: 'reports.absent',
      },
    },
    dayStateRibbon: {
      textAlign: 'center',
      fontSize: 0,
      fontWeight: '700',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      flex: '1 0 25%',
      '&[data-title]': {
        bg: 'reports.annotation',
        color: 'reports.dayStateRibbon.text',
        '&[data-type="timeOff"]': {
          bg: 'reports.ribbon.timeOff',
        },
        '&[data-type="businessTrip"]': {
          bg: 'reports.businessTrip',
        },
        '&[data-type="customRequest"]': {
          bg: 'reports.alphas.customRequest',
        },
      },
    },
    dayStateBadge: {
      px: 1,
      borderRadius: 'xs',
      textTransform: 'uppercase',
      fontSize: 2,
      fontWeight: 'bold',
      border: '1px solid',
      borderColor: 'reports.dayStateBadge.border',
      bg: 'reports.dayStateBadge.bg',
      color: 'reports.text.dayDetailsState',
      alignItems: 'center',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      '> span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },

      '&[data-type="PRESENT"]': {
        bg: 'reports.present',
      },
      '&[data-type="INCOMPLETE_SHIFT"]': {
        bg: 'reports.incompleteShift',
      },
      '&[data-type="OVERTIME"]': {
        bg: 'reports.overtime',
      },
      '&[data-type="TIME_OFF"]': {
        bg: 'reports.timeOff',
        borderRadius: 'pill',
      },
      '&[data-type="ABSENT"]': {
        bg: 'reports.absent',
        borderRadius: 'pill',
      },
      '&[data-type="CUSTOM_REQUEST"]': {
        borderColor: 'reports.customRequest',
        color: 'reports.customRequest',
      },
      '&[data-type="BUSINESS_TRIP"]': {
        borderColor: 'reports.businessTrip',
        color: 'reports.businessTrip',
      },
      '&[data-type="ERROR"]': {
        borderColor: 'reports.error',
        color: 'reports.text.error',
      },
      '&[data-type="ANNOTATION"]': {
        bg: 'reports.annotation',
        borderColor: 'reports.annotation',
      },
    },
    fancyDataBox: {
      flexDirection: 'row',
      bg: 'reports.fancyDataBox.default.bg',
      borderRadius: 'sm',
      p: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 2,
      '&[data-bold="true"]': {
        fontWeight: 'bold',
      },
      '.value': {
        justifySelf: 'flex-end',
        bg: 'reports.fancyDataBox.default.value',
        py: 1,
        px: '0.375rem',
        borderRadius: 'xs',
      },
      '.arrow': {
        color: 'reports.text.lighter',
      },
      '.title': {
        pl: 1,
        py: 1,
      },
    },
    fancyList: {
      flexDirection: 'column',
      gap: '1px',
      '> :first-of-type': {
        borderTopLeftRadius: 'sm',
        borderTopRightRadius: 'sm',
      },
      '> :last-of-type': {
        borderBottomLeftRadius: 'sm',
        borderBottomRightRadius: 'sm',
      },
    },
    fancyListItem: {
      default: {
        ...fancyListItemSx,
      },
      danger: {
        ...fancyListItemSx,
        bg: 'reports.fancyDataBox.danger.bg',
        outlineColor: 'reports.fancyDataBox.danger.outline',
        '&[data-interactive="true"]': {
          cursor: 'pointer',
          '&:hover': {
            bg: 'reports.fancyDataBox.danger.bgHover',
            outlineColor: 'reports.fancyDataBox.danger.outlineHover',
            boxShadow: 'fancyListItem.danger',
          },
        },
      },
    },
    clockLogPhoto: {
      display: 'flex',
      flex: '0 0 auto',
      borderRadius: 'sm',
      cursor: 'pointer',
      transition: 'all 0.15s ease-in-out',
      '&:hover': {
        boxShadow: 'clockLogPhoto',
        opacity: 0.7,
        transform: 'scale(1.05)',
      },
    },
  },
});
