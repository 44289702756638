import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Navigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { editScheduleAction } from 'api/actions/calendar/calendarActions';
import { EditScheduleActionData } from 'api/actions/calendar/calendarActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { calendarSchedulesSelector } from 'state/calendar';
import { reportSchedulesSelector } from 'state/reports';
import { createEvent } from 'utils/createEvent';
import { dateTime } from 'utils/dateTime';
import { isDayInAllowedRange } from 'utils/isDayInAllowedRange';
import { useRefreshCalendar } from '../../hooks/useRefreshCalendar';

import { ScheduleForm, ScheduleFormProps } from './forms/ScheduleForm';

export const EditScheduleModal = (): React.ReactElement => {
  useLingui();
  const [loading, setLoading] = useState(false);

  const schedules = useRecoilValue(calendarSchedulesSelector);
  const reportSchedules = useRecoilValue(reportSchedulesSelector);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { addSnackbar } = useSnackbar();
  const { handleClose, baseRoute } = useModal();
  const { id, date, user } = useParams() as { id: string; date: string; user: string };
  const { mutate } = useMutation<unknown, unknown, EditScheduleActionData>((body) => editScheduleAction(body, id));

  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar([user]);
  const { reportInitialized, updateReportForIds } = useRefreshReport([user]);

  const updateData = useCallback(async () => {
    if (calendarInitialized) await updateCalendarForIds();
    if (reportInitialized) await updateReportForIds();
  }, [calendarInitialized, reportInitialized, updateCalendarForIds, updateReportForIds]);

  useEffect(() => {
    if (!schedules && !reportSchedules) {
      void updateData();
    }
  }, [id, reportSchedules, schedules, updateData]);

  const parsedScheduleData = useMemo(() => {
    const schedule = schedules ? schedules.get(id) : undefined;
    const reportSchedule = reportSchedules ? reportSchedules.get(id) : undefined;
    const pickedSchedule = schedule || reportSchedule;

    if (!pickedSchedule) {
      return undefined;
    }

    return {
      ...pickedSchedule,
      isAi: isNumber(pickedSchedule.aiVariant),
      details: {
        ...pickedSchedule.details,
        startDateTimeUnix: !isUndefined(pickedSchedule.details.startDateTimeUnix)
          ? pickedSchedule.details.startDateTimeUnix -
            +date +
            dateTime(pickedSchedule.details.startDateTimeUnix).utcOffset() * 60
          : undefined,
        endDateTimeUnix: !isUndefined(pickedSchedule.details.endDateTimeUnix)
          ? pickedSchedule.details.endDateTimeUnix -
            +date +
            dateTime(pickedSchedule.details.endDateTimeUnix).utcOffset() * 60
          : undefined,
      },
    };
  }, [date, id, reportSchedules, schedules]);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit: ScheduleFormProps['onSubmit'] = useCallback(
    async (body) => {
      const { error: submitError } = await mutate({ ...body, dateUnix: Number(date) });

      if (!submitError) {
        addSnackbar({
          message: t({ id: 'schedule.edit_successful', message: 'Schedule edited!' }),
          variant: 'success',
        });

        if (calendarInitialized) await updateCalendarForIds();
        if (reportInitialized) await updateReportForIds();
        handleClose();

        return true;
      }

      setLoading(false);
      return false;
    },
    [
      mutate,
      date,
      addSnackbar,
      calendarInitialized,
      updateCalendarForIds,
      reportInitialized,
      updateReportForIds,
      handleClose,
    ],
  );

  const handleOnSave = () => {
    setLoading(true);
    submitForm();
  };

  const localDate = dateTime(date, { utc: true }).format('ll');

  return !isDayInAllowedRange(date) ? (
    <Navigate to={baseRoute} relative="path" />
  ) : (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="schedule.edit_schedule">Edit schedule</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ gap: 4 }}>
        <Flex sx={{ flexDirection: 'column' }}>
          <Modal.SubTitle>{t({ id: 'requests.for_day' })}</Modal.SubTitle>
          {localDate}
        </Flex>

        <ScheduleForm
          ref={formRef}
          defaultValues={parsedScheduleData || undefined}
          onSubmit={onSubmit}
          setLoading={setLoading}
        />
      </Modal.Body>

      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleOnSave,
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
