import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RecoilValue, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import {
  exportForIntegrationAction,
  exportIntegrationOptionsAction,
  exportTimeActivityAction,
  exportTimeActivityActionCreator,
  exportTimeActivityPreviewAction,
} from 'api/actions/export/exportActions';
import {
  EmployeesTimeActivity,
  ExportForIntegrationActionProps,
  ExportForIntegrationResponse,
  ExportIntegrationOptionsActionProps,
  ExportIntegrationOptionsResponse,
  ExportOptionsHeadersGroups,
  ExportTimeActivityActionProps,
  ExportTimeActivityPreviewActionProps,
  ExportTimeActivityPreviewResponse,
  ParsedExportedEmployees,
} from 'api/actions/export/exportActions.types';
import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Divider } from 'components/Divider/Divider';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { ListNames, ListVariant, StickyListProps } from 'components/StickyList/types';
import { Checkbox } from 'components/ui/Checkbox';
import { InputOption, Select } from 'components/ui/Select/Select';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { exportedEmployeesInfoAtom, exportedEmployeesMapSelector } from 'state/employees';
import { selectAllAtomFamily, selectedRowsAtomFamily, selectedRowsIdsSelectorFamily } from 'state/list';
import { createEvent } from 'utils/createEvent';
import { LazyComponentType } from 'utils/custom.types';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

import { DateRangeRadioPicker } from './components/DateRangeRadioPicker/DateRangeRadioPicker';
import { DateRange, useDateRangeRadioPicker } from './components/DateRangeRadioPicker/hooks/useDateRangeRadioPicker';

type Props = {
  serviceIntegration: ServiceIntegration;
  selectedIdsRecoilState: RecoilValue<string[]>;
};

const decapitalizeFirstLetter: (stringToDecapitalize: string) => string = (stringToDecapitalize) => {
  const firstLetter = stringToDecapitalize.charAt(0).toLowerCase();
  return firstLetter + stringToDecapitalize.slice(1);
};

const LazyStickyList = React.lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<ParsedExportedEmployees>>;

const INTEGRATIONS_WITH_EXPORT = [
  ServiceIntegration.GratyfikantGT,
  ServiceIntegration.R2Platnik,
  ServiceIntegration.Optima,
  ServiceIntegration.Symfonia,
  ServiceIntegration.Enova,
  ServiceIntegration.GratyfikantNexo,
];

export const ExportModal = ({ selectedIdsRecoilState, serviceIntegration }: Props): React.ReactElement | null => {
  useLingui();

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [integrationOptions, setIntegrationOptions] = useState<ExportIntegrationOptionsResponse | undefined>(undefined);
  const [dateRangeValue, setDateRangeValue] = useState(DateRange.CUSTOM);
  const [xeroWorkTime, setXeroWorkTime] = useState<string>();
  const [xeroOvertime, setXeroOvertime] = useState<string>();

  const [exportedEmployeesInfo, setExportedEmployeesInfo] = useRecoilState(exportedEmployeesInfoAtom);
  const employeesMap = useRecoilValue(exportedEmployeesMapSelector);
  const selectedEmployeesIds = useRecoilValue(selectedRowsIdsSelectorFamily(ListNames.EXPORT_EMPLOYEES));
  const resetAllSelectedIds = useResetRecoilState(selectAllAtomFamily(ListNames.EXPORT_EMPLOYEES));
  const resetSelectedIds = useResetRecoilState(selectedRowsAtomFamily(ListNames.EXPORT_EMPLOYEES));
  const selectedIds = useRecoilValue(selectedIdsRecoilState);

  const { handleClose } = useModal();

  const { translateKeyToDate } = useDateRangeRadioPicker();

  const isExportForIntegration = INTEGRATIONS_WITH_EXPORT.includes(serviceIntegration);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { register, handleSubmit, watch, formState } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const currentFormValues = watch();

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setExportedEmployeesInfo((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          canOverwriteExistingData: checked,
        };
      }
      return null;
    });
  };

  useUnmount(() => {
    resetAllSelectedIds();
    resetSelectedIds();
  });

  const { mutate: mutateIntegrationOptions } = useMutation<
    ExportIntegrationOptionsResponse,
    unknown,
    ExportIntegrationOptionsActionProps
  >((body) => exportIntegrationOptionsAction(body));
  const { mutate: mutateExportIntegration } = useMutation<
    ExportForIntegrationResponse,
    unknown,
    ExportForIntegrationActionProps
  >((body) => exportForIntegrationAction(body, true));
  const { mutate: mutateExportPreview } = useMutation<
    ExportTimeActivityPreviewResponse,
    unknown,
    ExportTimeActivityPreviewActionProps
  >((body) => exportTimeActivityPreviewAction(body));
  const { mutate: mutateExport } = useMutation<null, unknown, ExportTimeActivityActionProps>((body) =>
    exportTimeActivityAction(body),
  );
  const { mutate: mutateExportApiIntegration } = useMutation(exportTimeActivityActionCreator);

  const parsedDateRangeValue = useMemo(() => translateKeyToDate(dateRangeValue), [dateRangeValue, translateKeyToDate]);

  const handleIntegrationOptions = useCallback(async () => {
    setIsLoading(true);

    const { payload, error } = await mutateIntegrationOptions({
      integration: serviceIntegration,
    });

    if (!error && payload) {
      setIntegrationOptions(payload);
    } else if (error) {
      handleClose();
      return;
    }

    setIsLoading(false);
  }, [handleClose, mutateIntegrationOptions, serviceIntegration]);

  const handleExportPreview = useCallback(async () => {
    if (parsedDateRangeValue) {
      const { error, payload: exportPreviewPayload } = await mutateExportPreview({
        range: parsedDateRangeValue,
        peopleIds: selectedIds,
        type: serviceIntegration,
      });

      if (!error && exportPreviewPayload) {
        setExportedEmployeesInfo(exportPreviewPayload);

        if (
          serviceIntegration === ServiceIntegration.Xero &&
          exportPreviewPayload.earningRates &&
          !_.isEmpty(exportPreviewPayload.earningRates)
        ) {
          const firstEarningOptionId = exportPreviewPayload.earningRates[0].id;
          setXeroOvertime(firstEarningOptionId);
          setXeroWorkTime(firstEarningOptionId);
        }
      } else if (error) {
        handleClose();
      }
    }
  }, [
    parsedDateRangeValue,
    handleClose,
    mutateExportPreview,
    selectedIds,
    serviceIntegration,
    setExportedEmployeesInfo,
  ]);

  const integrationOptionsRef = useCallbackRef(handleIntegrationOptions);
  const exportPreviewRef = useCallbackRef(handleExportPreview);

  useEffect(() => {
    if (isExportForIntegration) {
      void integrationOptionsRef.current();
    } else {
      void exportPreviewRef.current();
    }
  }, [isExportForIntegration, exportPreviewRef, integrationOptionsRef]);

  useEffect(() => {
    if (
      serviceIntegration === ServiceIntegration.GratyfikantGT ||
      serviceIntegration === ServiceIntegration.GratyfikantNexo
    ) {
      if (
        _.chain(currentFormValues)
          .values()
          .find((v) => v === true)
          .value()
      ) {
        setIsDisabled(false);
      } else if (!isDisabled && formState.isDirty) {
        setIsDisabled(true);
      }
    }
  }, [currentFormValues, formState.isDirty, isDisabled, serviceIntegration]);

  const handleSubmitCallback: SubmitHandler<Record<number, boolean>> = async (data) => {
    setIsLoading(true);

    const selectedColumns = _.chain(data)
      .omitBy((val) => val === false)
      .keys()
      .value();

    if (parsedDateRangeValue) {
      const options = {
        datesUnix: parsedDateRangeValue,
        peopleIds: selectedIds,
        selectedColumns,
        serviceIntegration,
      };
      const { error } = await mutateExportIntegration(options);
      if (!error) {
        if (handleClose) {
          handleClose();
          return;
        }
      }
    }

    setIsLoading(false);
  };

  const filteredSelectedEmployees = _.filter(exportedEmployeesInfo?.employees, (employee) =>
    selectedEmployeesIds.includes(employee.personId),
  );

  const handleExport = async () => {
    if (!parsedDateRangeValue) return;

    setIsLoading(true);

    const mutate = serviceIntegration === ServiceIntegration.Quickbooks ? mutateExportApiIntegration : mutateExport;

    const { error } = await mutate({
      range: parsedDateRangeValue,
      employees: filteredSelectedEmployees,
      type: serviceIntegration,
      overwriteExistingData: exportedEmployeesInfo?.canOverwriteExistingData || false,
      ordinaryTimeEarningsId: xeroWorkTime,
      overTimeEarningsId: xeroOvertime,
    });

    if (!error) {
      if (serviceIntegration === ServiceIntegration.Quickbooks) {
        addSnackbar({
          variant: 'success',
          message: t({ id: 'export.quickbooks.success', message: 'Exported data to QuickBooks' }),
        });
      }
      handleClose();
      return;
    }

    setIsLoading(false);
  };

  const handleExportForIntegration = () => {
    const form = formRef.current;

    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onDateRangeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) setDateRangeValue(+value);
  }, []);

  const columns: StickyListProps<ParsedExportedEmployees>['columns'] = [
    {
      title: t({ id: 'team.list.employee_name' }),
      key: 'displayName',
      sortableValue: (displayName: EmployeesTimeActivity['displayName']) => `${displayName}`,
      customCellRenderer: (displayName: EmployeesTimeActivity['displayName']) => (
        <TextEllipsis>{displayName}</TextEllipsis>
      ),
      columnGrow: 2,
    },
  ];

  const serviceIntegrationName: Record<ServiceIntegration, string> = {
    [ServiceIntegration.GratyfikantGT]: t({ id: 'reports.export.gratyfikant' }),
    [ServiceIntegration.Optima]: t({ id: 'reports.export.optima' }),
    [ServiceIntegration.Quickbooks]: t({ id: 'reports.export.quickbooks' }),
    [ServiceIntegration.R2Platnik]: t({ id: 'reports.export.r2' }),
    [ServiceIntegration.Symfonia]: t({ id: 'reports.export.symfonia' }),
    [ServiceIntegration.SquarePayroll]: t({ id: 'reports.export.square' }),
    [ServiceIntegration.Xero]: t({ id: 'reports.export.xero' }),
    [ServiceIntegration.Enova]: t({ id: 'reports.export.enova' }),
    [ServiceIntegration.GratyfikantNexo]: t({ id: 'reports.export.gratyfikant_nexo' }),
    [ServiceIntegration.Webhook]: '',
    [ServiceIntegration.ZusEzla]: '',
  };

  const earingRatesOptions: InputOption[] = useMemo(() => {
    if (
      !exportedEmployeesInfo ||
      !exportedEmployeesInfo.earningRates ||
      _.isEmpty(exportedEmployeesInfo.earningRates)
    ) {
      return [];
    }

    return exportedEmployeesInfo.earningRates.map(({ id, name }) => ({ label: name, id }));
  }, [exportedEmployeesInfo]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {t({ id: 'global.export' })}
          <> {decapitalizeFirstLetter(serviceIntegrationName[serviceIntegration])}</>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ gap: 3 }}>
        {isExportForIntegration && (
          <>
            {integrationOptions ? (
              <>
                <Text as="p" variant="p" sx={{ fontSize: 2 }}>
                  <Trans id="reports.download_export_warning">Errors and empty cells won't be exported.</Trans>
                </Text>

                <DateRangeRadioPicker value={dateRangeValue} onChange={onDateRangeChange} />

                <form
                  ref={formRef}
                  id="export_form"
                  onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}
                  noValidate
                >
                  <Flex sx={{ gap: 5, flexDirection: 'column' }}>
                    {_.map(integrationOptions.headers, (group, groupKey: ExportOptionsHeadersGroups) => {
                      if (group.length < 1) return null;

                      return (
                        <Flex sx={{ flexDirection: 'column' }} key={groupKey}>
                          <Modal.SubTitle>
                            {serviceIntegration === ServiceIntegration.R2Platnik ? (
                              <Trans id="global.options">Options</Trans>
                            ) : (
                              <Trans id="global.columns">Columns</Trans>
                            )}
                            :
                          </Modal.SubTitle>
                          <Flex key={groupKey} sx={{ gap: 2, flexDirection: 'column', alignItems: 'flex-start' }}>
                            {_.map(group, (header) => (
                              <Checkbox
                                size="sm"
                                key={header.id}
                                defaultChecked={header.selected}
                                label={
                                  <>
                                    {t({ id: header.name })}
                                    {header.archived && ` (${t({ id: 'report.archived', message: 'Archived' })})`}
                                  </>
                                }
                                {...register(`${header.id}`)}
                              />
                            ))}
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Flex>
                </form>
              </>
            ) : (
              <>
                <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <LoadingSpinnerCss size={4} />
                </Flex>
              </>
            )}
          </>
        )}

        {!isExportForIntegration && (
          <>
            {serviceIntegration !== ServiceIntegration.Xero && (
              <>
                <Text as="p" variant="p" sx={{ marginBottom: 2, fontSize: 2 }}>
                  <Trans id="reports.modal_export.info_1">
                    The default data export completes empty work time values in{' '}
                    {serviceIntegrationName[serviceIntegration]}. By selecting the "overwrite" option, we force a
                    complete update of all values.
                  </Trans>
                </Text>
                <Text as="p" variant="p" sx={{ fontSize: 2 }}>
                  <Trans id="reports.modal_export.info_2">
                    Only employees work time data will be exported to {serviceIntegrationName[serviceIntegration]}
                  </Trans>
                </Text>

                <Checkbox
                  name="Overwrite"
                  size="sm"
                  checked={exportedEmployeesInfo?.canOverwriteExistingData || false}
                  onChange={handleCheckboxChange}
                  label={`${t({ id: 'reports.modal_export.overwrite', message: 'Overwrite' })}`}
                />
              </>
            )}

            {serviceIntegration === ServiceIntegration.Xero && earingRatesOptions && !_.isEmpty(earingRatesOptions) && (
              <>
                <Text as="p" variant="p" sx={{ fontSize: 2 }}>
                  <Trans id="reports.modal_export.xero_info">
                    Your dates for export need to match the exact start and end date of your payroll calendar in Xero.
                    If an employee has approved timesheets for this pay period in Xero please revert them to draft, as
                    otherwise, an error will appear.
                  </Trans>
                </Text>
                <Select
                  id="ordinaryTimeEarningsId"
                  label={t({
                    id: 'reports.modal_export.xero_work_time',
                    message: 'Work time',
                  })}
                  placeholder={t({
                    id: 'reports.modal_export.xero_work_time',
                  })}
                  options={earingRatesOptions}
                  value={xeroWorkTime}
                  size="sm"
                  onChange={(e) => setXeroWorkTime(e.target.value)}
                />
                <Select
                  id="overTimeEarningsId"
                  label={t({
                    id: 'reports.modal_export.xero_overtime',
                    message: 'Overtime',
                  })}
                  placeholder={t({
                    id: 'reports.modal_export.xero_overtime',
                  })}
                  options={earingRatesOptions}
                  value={xeroOvertime}
                  size="sm"
                  onChange={(e) => setXeroOvertime(e.target.value)}
                />
              </>
            )}

            <DateRangeRadioPicker value={dateRangeValue} onChange={onDateRangeChange} />

            <Divider borderColor="darker" />

            <React.Suspense
              fallback={
                <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <LoadingSpinnerCss size={4} />
                </Flex>
              }
            >
              <LazyStickyList
                name={ListNames.EXPORT_EMPLOYEES}
                list={employeesMap}
                columns={columns}
                showHeader
                showContentPlaceholder
                select="checkbox"
                variant={ListVariant.inverted}
              />
            </React.Suspense>
          </>
        )}
      </Modal.Body>

      <BasicModalFooter
        buttons={[
          {
            isLoading,
            disabled: isExportForIntegration ? isDisabled : !selectedEmployeesIds.length,
            onClick: isExportForIntegration ? handleExportForIntegration : () => void handleExport(),
            variant: 'primary',
            children: t({ id: 'global.export' }),
          },
        ]}
      />
    </>
  );
};
