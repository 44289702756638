import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { PersonName } from 'api/actions/organizationSession/organizationSessionActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Avatar } from 'components/Avatar/Avatar';
import { Icon } from 'components/Icon/Icon';
import { LoadingSpinnerSize } from 'components/Loading/LoadingSpinnerCSS';
import { Button } from 'components/ui/Buttons';
import { AVATAR_MAX_SIZE_IN_BYTES, AVATAR_MIN_HEIGHT_WIDTH } from 'constants/common';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { languageSelector } from 'state/recoilState';

type Props = {
  variant?: 'lg' | 'default';
  name?: PersonName;
  avatarUrl?: string;
  onDelete: () => void | Promise<boolean>;
};

const defaultProps: Partial<Props> = {
  avatarUrl: undefined,
  variant: 'default',
  name: undefined,
};

export const Picker = ({ variant, name, avatarUrl, onDelete }: Props): React.ReactElement => {
  useLingui();
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);

  const avatarInputRef = useRef<HTMLInputElement | null>(null);

  const navigate = useAppNavigate();

  const language = useRecoilValue(languageSelector);

  const triggerAvatarInputClick = () => {
    const avatarInput = avatarInputRef.current;

    if (avatarInput) {
      avatarInput.click();
    }
  };

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;

      if (files && files[0]) {
        if (files[0].size > AVATAR_MAX_SIZE_IN_BYTES) {
          addSnackbar({
            message: t({
              id: 'max_avatar_size',
              message: 'Max avatar size: 5MB',
            }),
          });
          e.target.value = '';
          return;
        }

        let avatarType = files[0].type;

        if (avatarType === 'image/gif') {
          avatarType = 'image/jpeg';
        }

        const reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = ({ target }) => {
          const image = new Image();
          image.src = target?.result as string;

          image.onload = ({ target: imageTarget }) => {
            const { height: avatarHeight, width: avatarWidth } = imageTarget as HTMLImageElement;

            if (avatarHeight < AVATAR_MIN_HEIGHT_WIDTH || avatarWidth < AVATAR_MIN_HEIGHT_WIDTH) {
              addSnackbar({
                message: t({
                  id: 'min_avatar_size',
                  message: `Minimum avatar size is ${AVATAR_MIN_HEIGHT_WIDTH}px x ${AVATAR_MIN_HEIGHT_WIDTH}px`,
                }),
              });
              return;
            }

            const url = URL.createObjectURL(files[0] || null);
            navigate(TO_REL.ADD_AVATAR_MODAL[language], {
              state: { avatarUrl: url, avatarType, avatarHeight, avatarWidth },
            });
          };
        };
      }
    },
    [navigate, language],
  );

  useEffect(() => {
    if (avatarUrl) {
      setDeleteButtonLoading(false);
    }
  }, [avatarUrl]);

  const onDeleteButtonClick = useCallback(async () => {
    setDeleteButtonLoading(true);
    await onDelete();
  }, [onDelete]);

  return (
    <>
      <input
        tabIndex={-1}
        type="file"
        accept="image/png, image/gif, image/jpeg"
        onChange={handleInputChange}
        ref={avatarInputRef}
        onClick={(e) => {
          (e.target as HTMLInputElement).value = '';
        }}
        style={{ width: 0, opacity: 0, position: 'absolute' }}
      />
      <Flex
        sx={{
          position: 'relative',
          flexShrink: 0,
          alignItems: 'center',
          ...(variant === 'lg' ? { ml: 0 } : { ml: 3 }),
        }}
      >
        <Flex sx={{ position: 'relative' }}>
          <Avatar
            size={variant === 'lg' ? 100 : 74}
            image={avatarUrl}
            name={name}
            imageProps={{ loadingOverlaySize: LoadingSpinnerSize.imageSM, loadingOverlayBackground: 'inherit' }}
          />
          {avatarUrl ? (
            <Flex
              sx={{
                position: 'absolute',
                top: '0',
                ...(variant === 'default'
                  ? {
                      left: 0,
                      transform: 'translate(-50%, -50%)',
                    }
                  : {
                      right: 0,
                      transform: 'translate(50%, -50%)',
                    }),
              }}
            >
              <Button
                variant="minimal"
                size="xs"
                shape="circle"
                aria-label="Delete"
                isLoading={deleteButtonLoading}
                onClick={() => void onDeleteButtonClick()}
                bgOverwrite={{
                  default: 'manageAvatar.deleteButton.background.default',
                  hover: 'manageAvatar.deleteButton.background.hover',
                  tap: 'manageAvatar.deleteButton.background.tap',
                  disabled: 'manageAvatar.deleteButton.background.disabled',
                }}
                sx={{
                  border: `1px solid`,
                  borderColor: 'manageAvatar.picker.borderColor',
                  backdropFilter: 'blur(2px)',
                  boxShadow: 'manageAvatar.picker',
                }}
              >
                <Icon type="x" />
              </Button>
            </Flex>
          ) : (
            <Button
              onClick={triggerAvatarInputClick}
              sx={{
                position: 'absolute',
                width: '100%',
                bottom: 0,
                left: 0,
                right: 0,
                borderBottomLeftRadius: 'sm',
                borderBottomRightRadius: 'sm',
              }}
              size="xs"
              shape="square"
              variant="minimal"
              bgOverwrite={{
                default: 'manageAvatar.addButton.background.default',
                hover: 'manageAvatar.addButton.background.hover',
                tap: 'manageAvatar.addButton.background.tap',
                disabled: 'manageAvatar.addButton.background.disabled',
              }}
            >
              {t({ id: 'add' })}
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

Picker.defaultProps = defaultProps;
