/* eslint-disable no-useless-escape */
import { plural, t } from '@lingui/macro';
import _ from 'lodash';

/**
 * Compatible with backend validation.
 * Do not change without consulting with the backend team!
 */
const VALIDATION_PATTERN = {
  noSpecialChars: /^[^$^><`~=]+$/g,
  noSpecialCharsLessRestricting: /^[^><=]+$/g,
  onlyLetters: /^[A-Ża-ż- ]*$/g,
  email: /^([a-zA-Z0-9_\.-]\+?)+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,20}$/i,
  url: /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  // only on frontend, backend does not have validation rules for pesel
  pesel: /^[0-9]{11}$/,
  PZUpassword: /^(?=.*\p{Ll})(?=.*\p{Lu})(?=.*\d)(?=.*[^\p{L}\d])\S+$/u,
};

const MAX_LENGTH = {
  S: 10,
  M: 50,
  L: 128,
  XL: 200,
  XXL: 5000,
  EMAIL: 256,
};

const MIN_LENGTH = {
  S: 2,
  M: 6,
};

/**
 * Rules defined here should be compatible with server validation.
 * Do not change them without consulting with the backend team!
 */
export const VALIDATION_RULES = {
  // TEAMMATE
  FIRST_NAME: {
    minLength: MIN_LENGTH.S,
    maxLength: MAX_LENGTH.M,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  SURNAME: {
    minLength: MIN_LENGTH.S,
    maxLength: MAX_LENGTH.M,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  NOTE: {
    minLength: MIN_LENGTH.S,
    maxLength: MAX_LENGTH.XXL,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  CUSTOM_EMPLOYEE_ID: {
    maxLength: MAX_LENGTH.L,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  PESEL: {
    pattern: VALIDATION_PATTERN.pesel,
  },
  WORKDAY_DURATION_SECONDS: {
    defaultValue: 28800, // 8h
    min: 60, // 1min
    max: 86400, // 24h
  },
  EMAIL: {
    minLength: MIN_LENGTH.S,
    maxLength: MAX_LENGTH.EMAIL,
    pattern: VALIDATION_PATTERN.email,
  },
  PASSWORD: {
    minLength: MIN_LENGTH.M,
    maxLength: MAX_LENGTH.L,
  },
  // ADDRESS
  POSTAL_CODE: {
    minLength: MIN_LENGTH.S,
    maxLength: MAX_LENGTH.S,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  CITY: {
    minLength: MIN_LENGTH.S,
    maxLength: MAX_LENGTH.XL,
    pattern: VALIDATION_PATTERN.onlyLetters,
  },
  STREET: {
    minLength: MIN_LENGTH.S,
    maxLength: MAX_LENGTH.XL,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  TAX_ID: {
    maxLength: 20,
  },
  // ROLE
  ROLE_NAME: {
    maxLength: 100,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  ROLE_DESCRIPTION: {
    minLength: MIN_LENGTH.S,
    maxLength: 255,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  // TAG
  TAG_NAME: {
    maxLength: MAX_LENGTH.M,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  TAG_ADDITIONAL_INFO: {
    minLength: MIN_LENGTH.S,
    maxLength: MAX_LENGTH.XXL,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  // WORK_POSITION
  WORK_POSITION_NAME: {
    maxLength: MAX_LENGTH.M,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  // PAY RATE NORMAL
  PAY_RATE_NORMAL: {
    min: 1,
  },
  // PAY RATE OVERTIME
  PAY_RATE_OVERTIME: {
    min: 1,
  },
  // TIME_EVENT_TYPE
  TIME_EVENT_TYPE_NAME: {
    maxLength: MAX_LENGTH.XL,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  // DEVICE
  DEVICE_NAME: {
    minLength: MIN_LENGTH.S,
    maxLength: MAX_LENGTH.L,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  // LOCATION
  LOCATION_NAME: {
    minLength: MIN_LENGTH.S,
    maxLength: MAX_LENGTH.M,
    pattern: VALIDATION_PATTERN.noSpecialChars,
  },
  // COMPANY
  COMPANY_NAME: {
    maxLength: MAX_LENGTH.M,
    pattern: VALIDATION_PATTERN.noSpecialCharsLessRestricting,
  },
  // URL
  URL: {
    pattern: VALIDATION_PATTERN.url,
  },
  // SETTINGS
  PAID_BREAK_DURATION_SECONDS: {
    min: 60, // 1min
    max: 28800, // 8h
  },
  OVERTIME_DURATION_SECONDS: {
    max: 28800, // 8h
  },
  NIGHT_HOURS_DURATIONS_SECONDS: {
    min: 60, // 1min
    max: 86400, // 24h
  },
  // TIME_EVENT
  TIME_EVENT_NOTE: {
    // maxLength: 360, // decreased bc of different encoding of special chars on backend
    maxLength: 350,
  },
  // SCHEDULES
  SCHEDULE_NOTE: {
    maxLength: 80,
  },
  // REQUESTS
  ADD_REQUEST_NOTE: {
    maxLength: 1000,
  },
  BUSINESS_TRIP_NAME: {
    maxLength: 50,
  },
  BUSINESS_TRIP_PLACE: {
    maxLength: 250,
  },
  REJECT_REQUEST_REASON: {
    maxLength: 160,
  },
  // HOLIDAY
  HOLIDAY_NAME: {
    maxLength: 200,
  },
  // CUSTOM TIME OFF TYPE
  TIME_OFF_TYPE_NAME: {
    maxLength: 50,
  },
  TIME_OFF_TYPE_ABBR: {
    maxLength: 3,
  },
  TIME_OFF_TYPE_DURATION_SECONDS: {
    max: 82800, // 23h - max time range between matching START and STOP time events
  },
  // CUSTOM REQUEST TYPE
  REQUEST_TYPE_NAME: {
    maxLength: 50,
  },
  REQUEST_TYPE_ABBR: {
    maxLength: 3,
  },
  PZU_PASSWORD: {
    minLength: 8,
    pattern: VALIDATION_PATTERN.PZUpassword,
  },
};

type Rules = {
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  pattern?: RegExp;
  required?: boolean;
  restrictedValues?: string[];
};

/**
 * Mainly if not solely for text inputs as other inputs such as number, duration, phone etc. are covered by custom pickers, select or masked inputs.
 * Do not memoize the result of this method, translations defined inside it must respond to language changes.
 */
export function validationFactory<T = string>({
  minLength,
  maxLength,
  min,
  max,
  pattern,
  required,
  restrictedValues,
}: Rules) {
  const minLengthObj = minLength
    ? {
        value: minLength,
        message: t({
          id: 'global.forms.min_chars',
          message: plural(minLength, {
            one: `min. ${minLength} char`,
            two: `min. ${minLength} chars`,
            few: `min. ${minLength} chars`,
            other: `min. ${minLength} chars`,
          }),
        }),
      }
    : undefined;

  const maxLengthObj = maxLength
    ? {
        value: maxLength,
        message: t({
          id: 'global.forms.max_chars',
          message: plural(maxLength, {
            one: `max. ${maxLength} char`,
            two: `max. ${maxLength} chars`,
            few: `max. ${maxLength} chars`,
            other: `max. ${maxLength} chars`,
          }),
        }),
      }
    : undefined;

  const minObj = _.isNumber(min)
    ? {
        value: min,
        message: `min: ${min}`,
      }
    : undefined;

  const maxObj = _.isNumber(max)
    ? {
        value: max,
        message: `max: ${max}`,
      }
    : undefined;

  const patternObj = pattern
    ? {
        value: pattern,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        message: patternErrorMessageReducer(pattern),
      }
    : undefined;

  const validate = restrictedValues
    ? {
        restrictedValue: (v?: T) =>
          !restrictedValues.includes((_.isString(v) && v?.trim()) || '') ||
          t({ id: 'global.forms.name_taken', message: 'This name is taken' }),
      }
    : undefined;

  return {
    setValueAs: (v?: T) => (_.isString(v) ? v?.trim() : v),
    pattern: patternObj,
    minLength: minLengthObj,
    maxLength: maxLengthObj,
    min: minObj,
    max: maxObj,
    required: required ? t({ id: 'global.forms.required' }) : undefined,
    validate,
  };
}

type ValidationConfig<T> = {
  setValueAs: (v?: T) => T | string | undefined;
  pattern?: {
    value: RegExp;
    message?: string;
  };
  minLength?: {
    value: number;
    message?: string;
  };
  maxLength?: {
    value: number;
    message?: string;
  };
  min?: {
    value: number;
    message?: string;
  };
  max?: {
    value: number;
    message?: string;
  };
  required?: string;
};

/**
 * Returns a error message string if provided value does not match the validation criteria defined in provided validationConfig, else returns undefined.
 * Use this only in custom resolvers or when you need to validate outside of RHF.
 */
export function validationResolver<T = string>(value?: T, validationConfig?: ValidationConfig<T>) {
  if (!validationConfig) return undefined;

  const { maxLength, minLength, max, min, pattern, required, setValueAs } = validationConfig;

  const parsedValue = setValueAs(value);

  if (required && !parsedValue) return required;

  if (!parsedValue || !_.isString(parsedValue)) return undefined;

  const maxL = maxLength?.value;
  const maxLengthMessage = maxLength?.message;

  if (maxL && parsedValue.length > maxL) {
    return maxLengthMessage;
  }

  const minL = minLength?.value;
  const minLengthMessage = minLength?.message;

  if (minL && parsedValue.length < minL) {
    return minLengthMessage;
  }

  const minValue = min?.value;
  const minMessage = min?.message;

  if (minValue && +parsedValue < minValue) {
    return minMessage;
  }

  const maxValue = max?.value;
  const maxMessage = max?.message;

  if (maxValue && +parsedValue > maxValue) {
    return maxMessage;
  }

  const patternValue = pattern?.value;
  const patternMessage = pattern?.message;

  if (patternValue && !new RegExp(patternValue).test(parsedValue)) {
    return patternMessage;
  }

  return undefined;
}
/**
 * Resolver helper.
 * Use this only in custom resolvers.
 */
export function fieldResolver(value: string | undefined, rules: Rules) {
  const validationConfig = validationFactory(rules);
  const { setValueAs } = validationConfig;
  const newValue = value ? setValueAs(value) : value;
  const errorMessage = validationResolver(value, validationConfig);

  return [newValue, errorMessage];
}

/**
 * Returns translated error message based on provided RegExp pattern.
 */
const patternErrorMessageReducer = (pattern: RegExp) =>
  (() => {
    switch (pattern) {
      case VALIDATION_PATTERN.email:
        return t({
          id: 'global.forms.email_format',
          message: 'Invalid e-mail format',
        });
      case VALIDATION_PATTERN.url:
        return t({
          id: 'global.forms.url_format',
          message: 'Invalid url format',
        });
      case VALIDATION_PATTERN.pesel:
        return t({
          id: 'global.forms.pesel_format',
          message: 'Invalid pesel',
        });
      case VALIDATION_PATTERN.PZUpassword:
        return t({
          id: 'global.forms.pzu_password',
          message: 'Use capital & small letter, number & symbol',
        });
      default:
        return t({
          id: 'global.forms.disallowed_characters',
          message: 'Contains disallowed characters',
        });
    }
  })();
