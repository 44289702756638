import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { addWorkPositionAction } from 'api/actions/workPositions/workPositionsActions';
import { WorkPositionDetails } from 'api/actions/workPositions/workPositionsActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { AddEditWorkPosition } from '../../forms/AddEditWorkPosition';

export const AddWorkPositionModal: FC = () => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();
  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { mutate } = useMutation(addWorkPositionAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: WorkPositionDetails): Promise<boolean> => {
      const { error: submitError } = await mutate(body);
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);

      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.add_position.added', message: 'Position successfully added!' }),
          variant: 'success',
        });
      }

      return true;
    },
    [handleClose, mutate, addSnackbar],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.add_position">Add position</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body sx={{ pb: 4 }}>
        <AddEditWorkPosition ref={formRef} onSubmit={onSubmit} setLoading={setLoading} />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save', message: 'Save' }),
          },
        ]}
      />
    </>
  );
};
