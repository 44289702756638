import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';

import { HeadingTip } from '../HeadingTip';
import { SettingsLoadingSpinnerCss } from '../SettingsLoadingSpinnerCss';

import { AutomationsForm } from './components/AutomationsForm/AutomationsForm';
import { useAutomationsSettings } from './hooks/useAutomationsSettings';
import { useAutomationsSettingsCleanUp } from './hooks/useAutomationsSettingsCleanUp';

export const Automations = (): React.ReactElement => {
  useLingui();
  const { automationsSettings, fetchAutomationsSettings } = useAutomationsSettings();

  useEffect(() => {
    if (!automationsSettings) {
      void fetchAutomationsSettings();
    }
  }, [fetchAutomationsSettings, automationsSettings]);

  useAutomationsSettingsCleanUp();

  return (
    <>
      <HeadingTip
        label={t({
          id: 'automations_settings.heading.automations',
          message: 'Automations',
        })}
      />
      {automationsSettings ? <AutomationsForm /> : <SettingsLoadingSpinnerCss />}
    </>
  );
};
