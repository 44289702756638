import { MessageDescriptor } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import dayjs from 'dayjs';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNan from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import uniqueId from 'lodash/uniqueId';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from 'theme-ui';

import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from 'constants/common';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useFieldErrorDispatcher } from 'hooks/useFieldErrorDipatcher/useFieldErrorDispatcher';
import { InputOwnValue, useOnOwnValueChange } from 'hooks/useOnOwnValueChange/useOnOwnValueChange';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { dateTime } from 'utils/dateTime';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { Button } from '../Buttons';
import { Calendar, getSelectedDates, getSelectedTimes } from '../DatePicker/Calendar/Calendar'; //  eslint-disable-line no-restricted-imports
import { CALENDAR_DATE_CHANGE, emitCalendarEvent } from '../DatePicker/Calendar/calendarPubsub';
import { TimeObject, TimePickers } from '../DatePicker/Calendar/TimePickers';
import { CalendarProps } from '../DatePicker/Calendar/types';
import { getDatesWithTime, parsedHiddenInputValue } from '../DatePicker/helpers';

import { SingleTimePicker } from './SingleTimePicker';

type ErrorDetails = [boolean | undefined, string | undefined, string | undefined];

type Props = Pick<CalendarProps, 'showStartTime' | 'showEndTime' | 'range' | 'minDate' | 'maxDate'> &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'size'> & {
    maxRange?: number;
    showTime?: boolean;
    error?: boolean;
    errorMessage?: string;
    onValidError?: () => void;
    onClearError?: () => void;
    children?: React.ReactNode | React.ReactNode[];
    breakthroughDay?: boolean;
    resetDays?: boolean;
    onResetDays?: () => void;
    defaultTimesOnDayPick?: number[];
  };

const defaultProps: Partial<Props> = {
  maxDate: DEFAULT_MAX_DATE,
  minDate: DEFAULT_MIN_DATE,
  range: false,
  showStartTime: false,
  showEndTime: false,
  maxRange: undefined,
  showTime: false,
  error: undefined,
  errorMessage: undefined,
  children: undefined,
  breakthroughDay: undefined,
  resetDays: undefined,
  onResetDays: undefined,
  defaultTimesOnDayPick: undefined,
};

const prepareMonthYear = (date: dayjs.Dayjs) => ({
  month: date.month(),
  year: date.year(),
});

export const DualCalendar = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      showStartTime,
      showEndTime,
      range,
      minDate = DEFAULT_MIN_DATE,
      maxDate = DEFAULT_MAX_DATE,
      maxRange,
      showTime,
      error,
      errorMessage,
      onValidError,
      onClearError,
      children,
      breakthroughDay,
      resetDays,
      onResetDays,
      defaultTimesOnDayPick,
      ...props
    }: Props,
    ref,
  ): React.ReactElement => {
    useLingui();
    const withTimeRef = useRef(!!showStartTime || !!showEndTime);
    const TODAY_DATE = useMemo(() => dateTime(undefined, { utc: !withTimeRef.current }).startOf('day'), []);

    const hiddenRef = useRef<HTMLInputElement | null>(null);
    const [selectedDates, setSelectedDates] = useState<number[] | undefined>(undefined);
    const [selectedTimes, setSelectedTimes] = useState<number[]>([NaN, NaN]);
    const [startTimePickerErrorMessage, setStartTimePickerErrorMessage] = useState<string | undefined>(undefined);
    const [endTimePickerErrorMessage, setEndTimePickerErrorMessage] = useState<string | undefined>(undefined);
    const [selectedCurrentMonthYear, setSelectedCurrentMonthYear] = useState<{
      month: number;
      year: number;
    }>(prepareMonthYear(dateTime(TODAY_DATE, { utc: true }).isBefore(minDate) ? minDate : TODAY_DATE));

    const { isMobileBreakpoint } = useThemeBreakpoint();

    const currentMinDate = useMemo(() => {
      if (!maxRange || !selectedDates) return minDate;

      const [startDate, endDate] = selectedDates;

      if (startDate && !endDate) {
        const subtractedDate = dateTime(startDate)
          .startOf('day')
          .subtract(maxRange - 1, 'day');

        if (minDate.isAfter(subtractedDate)) return minDate;

        return subtractedDate;
      }

      return minDate;
    }, [maxRange, minDate, selectedDates]);
    const bondedMinDate = useMemo(() => {
      const minimum = currentMinDate.add(1, 'month').startOf('month');

      if (!maxRange || !selectedDates) return minimum;

      const [startDate, endDate] = selectedDates;

      if (startDate && !endDate) return currentMinDate;

      return minimum;
    }, [currentMinDate, maxRange, selectedDates]);
    const currentMinDateUnix = useMemo(() => currentMinDate.utc(true).unix(), [currentMinDate]);
    const bondedMinDateUnix = useMemo(() => bondedMinDate.utc(true).unix(), [bondedMinDate]);
    const bondedMaxDate = useMemo(() => {
      if (!maxRange || !selectedDates) return maxDate;

      const [startDate, endDate] = selectedDates;

      if (startDate && !endDate) {
        const addedDate = dateTime(startDate)
          .endOf('day')
          .add(maxRange - 1, 'day');

        if (addedDate.isAfter(maxDate)) return maxDate;

        return addedDate;
      }

      return maxDate;
    }, [maxDate, maxRange, selectedDates]);
    const currentMaxDate = useMemo(() => {
      const max = bondedMaxDate.subtract(1, 'month').endOf('month');

      if (!maxRange || !selectedDates) return max;

      const [startDate, endDate] = selectedDates;

      if (startDate && !endDate) return bondedMaxDate;

      return max;
    }, [bondedMaxDate, maxRange, selectedDates]);
    const bondedMaxDateUnix = useMemo(() => bondedMaxDate.utc(true).unix(), [bondedMaxDate]);
    const currentMaxDateUnix = useMemo(() => currentMaxDate.utc(true).unix(), [currentMaxDate]);
    const currentMinDateControl = useMemo(() => minDate, [minDate]);
    const bondedMinDateControl = useMemo(
      () => currentMinDateControl.add(1, 'month').startOf('month'),
      [currentMinDateControl],
    );
    const bondedMaxDateControl = useMemo(() => maxDate, [maxDate]);
    const currentMaxDateControl = useMemo(() => bondedMaxDateControl.subtract(1, 'month'), [bondedMaxDateControl]);
    const bondedSelectedCurrentMonthYear = useMemo(() => {
      switch (selectedCurrentMonthYear.month) {
        case 11:
          return {
            month: 0,
            year: selectedCurrentMonthYear.year + 1,
          };
        default:
          return {
            month: selectedCurrentMonthYear.month + 1,
            year: selectedCurrentMonthYear.year,
          };
      }
    }, [selectedCurrentMonthYear]);

    const [computedError, computedStartTimeErrorMessage, computedEndTimeErrorMessage] = useMemo(() => {
      const formError: ErrorDetails = [error, errorMessage, undefined];
      if (!startTimePickerErrorMessage && !endTimePickerErrorMessage) return formError;

      const timePickerError: ErrorDetails = [true, startTimePickerErrorMessage, endTimePickerErrorMessage];

      return timePickerError;
    }, [error, errorMessage, startTimePickerErrorMessage, endTimePickerErrorMessage]);

    const clearTimePickerErrors = useCallback(() => {
      setStartTimePickerErrorMessage(undefined);
      setEndTimePickerErrorMessage(undefined);
    }, []);

    const eventListenerId = useMemo(() => uniqueId(), []);

    const setStartTimePickerErrorCallback = useCallback(({ id, message }: MessageDescriptor, appendWith?: string) => {
      setStartTimePickerErrorMessage(
        `${t({
          id,
          message,
        })}${appendWith || ''}`,
      );
    }, []);

    const setEndTimePickerErrorCallback = useCallback(({ id, message }: MessageDescriptor, appendWith?: string) => {
      setEndTimePickerErrorMessage(
        `${t({
          id,
          message,
        })}${appendWith || ''}`,
      );
    }, []);

    const validate = useCallback(
      (times: number[], sameDay?: boolean) => {
        const [startTime, endTime] = times;
        const isStartTimeDefined = !isNil(startTime) && !isNan(startTime);
        const isEndTimeDefined = !isNil(endTime) && !isNan(endTime);
        clearTimePickerErrors();

        if ((showStartTime || showTime) && !isStartTimeDefined) {
          setStartTimePickerErrorCallback({
            id: 'date_picker.time_required',
          });
        }

        if (showEndTime && !isEndTimeDefined) {
          setEndTimePickerErrorCallback({
            id: 'date_picker.time_required',
          });
        }

        if (showStartTime && showEndTime && isStartTimeDefined && isEndTimeDefined) {
          if (sameDay && endTime < startTime) {
            setStartTimePickerErrorCallback({
              id: 'global.forms.too_low',
              message: 'Too low',
            });
          }

          if (startTime === endTime) {
            setEndTimePickerErrorCallback({
              id: 'clock_log.same_min_ev_not_allowed',
              message: 'No same min. ev.',
            });
          }
        }
      },
      [
        clearTimePickerErrors,
        setEndTimePickerErrorCallback,
        setStartTimePickerErrorCallback,
        showEndTime,
        showStartTime,
        showTime,
      ],
    );

    const setHiddenInputValue = useCallback((value: string | number[] | number) => {
      setNativeValue(hiddenRef, value);
    }, []);

    const onTimeChangeCallback = useCallback(({ startTimeUnix, endTimeUnix }: TimeObject) => {
      setSelectedTimes([startTimeUnix, endTimeUnix]);
    }, []);

    const onSingleTimeChangeCallback = useCallback((timeUnix: number) => {
      setSelectedTimes([timeUnix, NaN]);
    }, []);

    const handleMonthChangeCallback = useCallback((month: number) => {
      setSelectedCurrentMonthYear((prevState) => ({
        ...prevState,
        month,
      }));
    }, []);

    const handleBondedMonthChangeCallback = useCallback(
      (month: number) => {
        setSelectedCurrentMonthYear((prevState) => ({
          ...prevState,
          month: month === 0 ? 11 : month - 1,
          year: month === 0 ? prevState.year - 1 : bondedSelectedCurrentMonthYear.year,
        }));
      },
      [bondedSelectedCurrentMonthYear.year],
    );

    const handleYearChangeCallback = useCallback(
      (year: number) => {
        const minYear = currentMinDate.year();
        const isMinYear = minYear === year;

        const minMonth = currentMinDate.month();

        const maxYear = currentMaxDate.year();
        const isMaxYear = maxYear === year;

        const maxMonth = currentMaxDate.month();

        setSelectedCurrentMonthYear((prevState) => {
          let { month } = prevState;

          if (isMinYear && prevState.month < minMonth) {
            month = minMonth;
          }
          if (isMaxYear && prevState.month > maxMonth) {
            month = maxMonth;
          }

          return {
            month,
            year,
          };
        });
      },
      [currentMaxDate, currentMinDate],
    );

    const handleBondedYearChangeCallback = useCallback(
      (year: number) => {
        const minYear = bondedMinDate.year();
        const isMinYear = minYear === year;

        const maxYear = bondedMaxDate.year();
        const isMaxYear = maxYear === year;

        const minMonth = currentMinDate.month();
        const maxMonth = currentMaxDate.month();

        setSelectedCurrentMonthYear((prevState) => {
          const currentYear = prevState.month === 11 ? year - 1 : year;
          const isCurrentYearBeforeMinYear = currentYear < currentMinDate.year();

          let { month } = prevState;

          if ((isMinYear && prevState.month < minMonth) || isCurrentYearBeforeMinYear) {
            month = minMonth;
          }
          if (isMaxYear && prevState.month > maxMonth) {
            month = maxMonth;
          }

          return {
            month,
            year: isCurrentYearBeforeMinYear ? currentMinDate.year() : currentYear,
          };
        });
      },
      [bondedMaxDate, bondedMinDate, currentMaxDate, currentMinDate],
    );

    const onNextMonth = useCallback(() => {
      setSelectedCurrentMonthYear((prevState) => {
        const newMonth = prevState.month + 1;

        return {
          ...prevState,
          month: newMonth > 11 ? 0 : newMonth,
          year: newMonth > 11 ? prevState.year + 1 : prevState.year,
        };
      });
    }, []);

    const onPreviousMonth = useCallback(() => {
      setSelectedCurrentMonthYear((prevState) => {
        const newMonth = prevState.month - 1;

        return {
          ...prevState,
          month: newMonth < 0 ? 11 : newMonth,
          year: newMonth < 0 ? prevState.year - 1 : prevState.year,
        };
      });
    }, []);

    const onDayClickCallback = useCallback(
      (dates: number[]) => {
        const [startTime, endTime] = selectedTimes;

        if (!isNan(startTime) || !isNan(endTime)) {
          const [startDateUnix, endDateUnix] = dates;
          const isSameDay = startDateUnix === endDateUnix;
          validate(selectedTimes, isSameDay);
        }

        if (defaultTimesOnDayPick && isNan(startTime) && isNan(endTime) && showStartTime && showEndTime) {
          const [startTimeUnix, endTimeUnix] = defaultTimesOnDayPick;
          validate(defaultTimesOnDayPick, false);
          onTimeChangeCallback({ startTimeUnix, endTimeUnix });
        }

        setSelectedDates((prevDates) => (isEqual(dates, prevDates) ? prevDates : dates));
      },
      [defaultTimesOnDayPick, onTimeChangeCallback, selectedTimes, showEndTime, showStartTime, validate],
    );

    const onTimeBlurCallback = useCallback(
      ({ startTimeUnix, endTimeUnix }: TimeObject) => {
        const isSameDay = (() => {
          if (!selectedDates || isEmpty(selectedDates)) return false;

          if (selectedDates.length === 1) return true;

          return selectedDates[0] === selectedDates[1];
        })();
        validate([startTimeUnix, endTimeUnix], !breakthroughDay ? isSameDay : false);
      },
      [breakthroughDay, selectedDates, validate],
    );

    const onSingleTimeBlurCallback = useCallback(
      (timeUnix: number) => {
        validate([timeUnix, NaN], false);
      },
      [validate],
    );

    const updateCalendarView = useCallback(
      (value?: string, skipTimesReset?: boolean) => {
        const calendarEventName = `${CALENDAR_DATE_CHANGE}${eventListenerId}`;

        if (!value) {
          emitCalendarEvent(calendarEventName, { dates: [] });
          setSelectedDates(undefined);
          if (!skipTimesReset) setSelectedTimes([NaN, NaN]);
          setSelectedCurrentMonthYear(prepareMonthYear(TODAY_DATE));
          clearTimePickerErrors();
          return;
        }

        const timeDisplayed = !!showEndTime || !!showStartTime || !!showTime;
        const unixDates = parsedHiddenInputValue(value);
        const startDate = dateTime(unixDates[0], { utc: !timeDisplayed });
        const newTimes = getSelectedTimes(unixDates).filter((time) => !isNan(time));
        const newDates = (() => {
          if (breakthroughDay && !range) {
            return getSelectedDates([unixDates[0], unixDates[0]], !timeDisplayed);
          }

          return getSelectedDates(unixDates, !timeDisplayed);
        })();

        emitCalendarEvent(calendarEventName, { dates: newDates });
        setSelectedDates(newDates);
        setSelectedTimes((prevTimes) => {
          if (newTimes.length === 2 || !prevTimes[1]) {
            return newTimes;
          }

          return [newTimes[0], prevTimes[1]];
        });
        if (startDate.unix() > currentMaxDate.unix()) {
          setSelectedCurrentMonthYear({ month: currentMaxDate.month(), year: currentMaxDate.year() });
        } else {
          setSelectedCurrentMonthYear(prepareMonthYear(startDate));
        }
        clearTimePickerErrors();
      },
      [
        TODAY_DATE,
        breakthroughDay,
        clearTimePickerErrors,
        currentMaxDate,
        eventListenerId,
        range,
        showEndTime,
        showStartTime,
        showTime,
      ],
    );

    const handleDaysReset = useCallback(() => {
      setNativeValue(hiddenRef, undefined);
      updateCalendarView(undefined, true);
      if (onResetDays) onResetDays();
    }, [updateCalendarView, onResetDays]);

    const onOwnValueChange = useCallback(
      (newValue: InputOwnValue) => {
        let parsedNewValue = newValue;
        if (isNil(newValue)) {
          parsedNewValue = '';
        }
        if (isArray(newValue)) {
          parsedNewValue = newValue.join(',');
        }
        updateCalendarView(`${parsedNewValue}`);
      },
      [updateCalendarView],
    );

    useOnOwnValueChange(hiddenRef, onOwnValueChange);

    useFieldErrorDispatcher(!!computedError, onValidError, onClearError);

    const updateViewRef = useCallbackRef(updateCalendarView);

    // INITIALIZE DUAL_CALENDAR
    useEffect(() => {
      const hiddenInputValue = hiddenRef?.current?.value;
      if (hiddenInputValue) {
        updateViewRef.current(hiddenInputValue);
      }
    }, [updateViewRef]);

    useEffect(() => {
      if (selectedDates && selectedTimes && showStartTime && showEndTime) {
        const [startTime, endTime] = selectedTimes;
        let parsedSelectedDates = selectedDates.length === 1 ? [selectedDates[0], selectedDates[0]] : selectedDates;

        if (!isNan(startTime) && !isNan(endTime)) {
          if (breakthroughDay && startTime > endTime) {
            const dayInSeconds = 86400;
            parsedSelectedDates = [parsedSelectedDates[0], parsedSelectedDates[1] + dayInSeconds];
          }

          setHiddenInputValue(getDatesWithTime(parsedSelectedDates, selectedTimes, true));
        }
      }
    }, [breakthroughDay, selectedDates, selectedTimes, setHiddenInputValue, showEndTime, showStartTime]);

    useEffect(() => {
      if (selectedDates && !showStartTime && !showEndTime && !showTime) {
        setHiddenInputValue(selectedDates);
      }
    }, [selectedDates, setHiddenInputValue, showEndTime, showStartTime, showTime]);

    useEffect(() => {
      if (selectedDates && selectedTimes && !showStartTime && !showEndTime && showTime) {
        const [startTime] = selectedTimes;

        if (!(isNil(startTime) || isNan(startTime))) {
          setHiddenInputValue(getDatesWithTime(selectedDates, selectedTimes, true));
        }
      }
    }, [selectedDates, selectedTimes, setHiddenInputValue, showEndTime, showStartTime, showTime]);

    return (
      <Flex sx={{ flexDirection: 'column', gap: 3 }}>
        <input
          {...props}
          ref={mergeRefs([ref, hiddenRef])}
          style={{ width: 0, opacity: 0, position: 'absolute' }}
          readOnly
        />
        <Flex sx={{ mx: -3, justifyContent: 'center' }}>
          <Calendar
            variant="dualCalendar"
            range={range}
            selectedMonthYear={selectedCurrentMonthYear}
            controlProps={{
              onMonthChange: handleMonthChangeCallback,
              onYearChange: handleYearChangeCallback,
              onPrev: onPreviousMonth,
              ...(isMobileBreakpoint ? { onNext: onNextMonth } : { hideArrow: 'right' }),
              minDate: currentMinDateControl,
              maxDate: currentMaxDateControl,
            }}
            daysGridProps={{
              minDateUnix: currentMinDateUnix,
              maxDateUnix: currentMaxDateUnix,
            }}
            onDayClickCallback={onDayClickCallback}
            eventListenerId={eventListenerId}
          />
          {!isMobileBreakpoint && (
            <Calendar
              variant="dualCalendar"
              range={range}
              selectedMonthYear={bondedSelectedCurrentMonthYear}
              controlProps={{
                onMonthChange: handleBondedMonthChangeCallback,
                onYearChange: handleBondedYearChangeCallback,
                onNext: onNextMonth,
                minDate: bondedMinDateControl,
                maxDate: bondedMaxDateControl,
                hideArrow: 'left',
              }}
              daysGridProps={{
                minDateUnix: bondedMinDateUnix,
                maxDateUnix: bondedMaxDateUnix,
              }}
              onDayClickCallback={onDayClickCallback}
              eventListenerId={eventListenerId}
            />
          )}
        </Flex>
        {resetDays && (
          <Button
            variant="naked"
            size="sm"
            onClick={handleDaysReset}
            disabled={isEmpty(selectedDates)}
            sx={{ alignSelf: 'flex-end', p: 0 }}
          >
            <Trans id="dual_calendar.reset_selected_days">Reset selected days</Trans>
          </Button>
        )}
        {children && children}
        {(showStartTime || showEndTime) && (
          <TimePickers
            selectedTimes={selectedTimes}
            showStartTime={showStartTime || false}
            showEndTime={showEndTime || false}
            onTimeChange={onTimeChangeCallback}
            onTimeBlur={onTimeBlurCallback}
            startTimeErrorMessage={computedStartTimeErrorMessage}
            endTimeErrorMessage={computedEndTimeErrorMessage}
          />
        )}
        {!range && showTime && (
          <SingleTimePicker
            selectedTime={selectedTimes[0]}
            onTimeChange={onSingleTimeChangeCallback}
            onTimeBlur={onSingleTimeBlurCallback}
            error={error || computedError}
            errorMessage={computedStartTimeErrorMessage}
          />
        )}
      </Flex>
    );
  },
);

DualCalendar.defaultProps = defaultProps;

export const MemoizedDualCalendar = React.memo(DualCalendar);
