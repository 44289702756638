import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import { fetchRequestForTimeRangeAction } from 'api/actions/requests/requestsActions';
import { useSilentFetch } from 'hooks/useSilentFetch/useSilentFetch';
import { requestsUsageOverviewAtom } from 'state/requests';
import { dateTime } from 'utils/dateTime';

export const useRequestsUsageOverview = (dates: { startDateUnix: number; endDateUnix: number } | null) => {
  const [fetchInProgress, setFetchInProgress] = useState(false);

  const { silentFetch, silentFetchAbort, fetchInTransition, initialFetchDone } = useSilentFetch(
    requestsUsageOverviewAtom,
    fetchRequestForTimeRangeAction(`${dateTime(dates?.startDateUnix || 0).year()}`),
  );

  const internalFetchRequestsUsage = useMemo(
    () => async (unAbortable?: boolean) => {
      if (dates) {
        const { error } = await silentFetch({
          forceUpdate: true,
          unAbortable,
        });

        if (!error) {
          setFetchInProgress(false);
        }
      }
    },
    [dates, silentFetch],
  );

  const throttleFetchRequestsUsage = useMemo(
    () =>
      _.throttle(
        async () => {
          await internalFetchRequestsUsage();
        },
        1000,
        { leading: false },
      ),
    [internalFetchRequestsUsage],
  );

  const fetchRequestsUsageOverview = useMemo(
    () =>
      _.throttle(() => {
        setFetchInProgress(true);
      }, 1000),
    [],
  );

  const fetchRequestsUsageNoThrottle = useCallback(
    async (unAbortable?: boolean) => {
      await internalFetchRequestsUsage(unAbortable);
    },
    [internalFetchRequestsUsage],
  );

  useEffect(() => {
    if (fetchInProgress) {
      void throttleFetchRequestsUsage();
    }

    return () => {
      fetchRequestsUsageOverview.cancel();
      throttleFetchRequestsUsage.cancel();
    };
  }, [
    dates,
    fetchInProgress,
    fetchRequestsUsageOverview,
    internalFetchRequestsUsage,
    silentFetchAbort,
    throttleFetchRequestsUsage,
  ]);

  return {
    fetchRequestsUsageOverview,
    fetchRequestsUsageNoThrottle,
    fetchInTransition,
    fetchInProgress,
    initialFetchDone,
  };
};
