import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { CalendarViewSettings } from '../userSession/userSessionActions.types';

import {
  AddScheduleActionProps,
  AddWorkdayAvailabilityActionProps,
  DragScheduleToActionProps,
  EditScheduleActionData,
  FetchCalendarActionProps,
  FetchCalendarForIdsActionProps,
  FetchCalendarResponse,
  GenerateAiSchedulesActionProps,
  GenerateAiSchedulesResponse,
  PublishScheduleActionProps,
  ResetAvailabilityActionProps,
  ScheduleActionData,
  ScheduleWizardOptionsProps,
} from './calendarActions.types';

export function fetchCalendarAction(props: FetchCalendarActionProps | null): Action<FetchCalendarResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.calendar.getAll,
    body: props,
    config: {
      // customFetchTimeout: 60000,
    },
  };
}
export function fetchCalendarForIdsAction(props: FetchCalendarForIdsActionProps | null): Action<FetchCalendarResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.calendar.getForIds,
    body: props,
    config: {
      // customFetchTimeout: 60000,
    },
  };
}

export function deleteScheduleAction(ids: string[], isAi: boolean): Action<FetchCalendarResponse> {
  return {
    method: 'DELETE',
    endpoint: `${API_ENDPOINTS.calendar.deleteSchedule}/${ids[0]}/${isAi}`,
  };
}

export function addScheduleAction(props: AddScheduleActionProps): Action<Record<string, unknown>> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.calendar.addSchedule}`,
    body: props,
  };
}

export function editScheduleAction(props: EditScheduleActionData, scheduleID: string): Action<Record<string, unknown>> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.calendar.editSchedule}/${scheduleID}/${!!props.isAi}`,
    body: props,
  };
}

export function scheduleWizardAction(
  schedule: ScheduleActionData | null,
  peopleIds: string[],
  wizardOptions: ScheduleWizardOptionsProps | null,
): Action<null> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.calendar.wizard}`,
    body: {
      schedule,
      people: peopleIds,
      ...wizardOptions,
    },
  };
}

export function calendarViewSettings(viewSettings: CalendarViewSettings): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.viewsSettings,
    body: {
      calendar: viewSettings,
    },
  };
}

export function generateAiSchedulesActions(props: GenerateAiSchedulesActionProps): Action<GenerateAiSchedulesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.calendar.generateAiSchedules,
    body: props,
    config: {
      innerCodesThatOmmitsSnackbar: [ErrorInnerCodes.NoSchedulesStatistics],
    },
  };
}
export function publishSchedulesAction(props: PublishScheduleActionProps): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.calendar.publish,
    body: props,
  };
}

export function publishSingleScheduleAction(scheduleId: string, isAi: boolean): Action<null> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.calendar.publish}/${scheduleId}/${isAi}`,
  };
}

export function revertScheduleAction(props: PublishScheduleActionProps): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.calendar.revertPublish,
    body: props,
  };
}

export function deleteAllSchedulesAction(props: PublishScheduleActionProps): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.calendar.deleteAllSchedules,
    body: props,
  };
}

export function deleteAllAiSchedulesAction(props: PublishScheduleActionProps): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.calendar.deleteAllAiSchedules,
    body: props,
  };
}

export function copySchedulesToAction(props: PublishScheduleActionProps, copyFromPersonId?: string): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.calendar.copyToOther,
    body: {
      ...props,
      copyFromPersonId,
    },
  };
}

export function dragScheduleToAction(props: DragScheduleToActionProps): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.calendar.dragSchedule,
    body: props,
  };
}

export function addWorkdayAvailabilityAction(body: AddWorkdayAvailabilityActionProps): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.workdayAvailabilityAdd,
    body,
  };
}

export function resetAvailabilityAction(body: ResetAvailabilityActionProps): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.workdayAvailabilityResetAvailability,
    body,
  };
}
