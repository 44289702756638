import { t } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { useModal } from 'components/Modal/hooks/useModal';
import { Modal } from 'components/Modal/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { PhoneInput } from 'components/ui/PhoneInput';
import { PHONE_NUMBER_INTERNAL_ERROR } from 'constants/team';
import { useErrorHandlersFactory } from 'hooks/useErrorHandlersFactory/useErrorHandlersFactory';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { PersonCell } from 'pages/Reports/output/PersonCell';
import { tagsMapSelector } from 'state/employees';
import { ProfileFormState } from 'state/team';
import { getTagByTagName } from '../../utils/getTagByTagName';

const labelSx: ThemeUIStyleObject = {
  ml: 2,
  fontSize: 1,
  fontWeight: 'bold',
  mt: 3,
};

export type EditEmployeePhoneModalState = {
  phoneNumber: string;
  firstName: string;
  surname: string;
  tagName: string;
};

export const EditEmployeePhoneNumberModal = () => {
  useLingui();
  const { handleClose, state, resolver } = useModal<string | undefined, EditEmployeePhoneModalState>();
  const { phoneNumber, firstName, surname, tagName } = state;
  const tags = useRecoilValue(tagsMapSelector);
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const {
    watch,
    setError,
    clearErrors,
    register,
    formState: { errors },
  } = useForm<Pick<ProfileFormState, 'phoneNumber'>>({
    defaultValues: { phoneNumber },
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const hasErrors = !!errors.phoneNumber || !!errors[PHONE_NUMBER_INTERNAL_ERROR as 'phoneNumber'];

  const [setPhoneErrorCallback, clearPhoneErrorCallback] = useErrorHandlersFactory(
    setError,
    clearErrors,
    PHONE_NUMBER_INTERNAL_ERROR,
  );

  const handleSave = () => {
    const newNumber = watch('phoneNumber');
    if (resolver && newNumber) void resolver(newNumber);
    handleClose();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.edit_phone_number">Edit phone number</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex
          sx={{
            flexDirection: isSmartphoneBreakpoint ? 'column' : 'row',
            gap: '1rem',
            justifyContent: 'space-between',
          }}
        >
          <PersonCell
            name={{ firstName: firstName || '', surname: surname || '' }}
            tags={tagName ? [getTagByTagName(tagName, tags)] : []}
            sx={{ width: 'auto' }}
          />
          <Flex sx={{ flexDirection: 'column', width: isSmartphoneBreakpoint ? '100%' : '50%' }}>
            <Text as="div" sx={{ ...labelSx, mt: 0 }}>
              <Trans id="team.user.phone">Phone</Trans>
            </Text>
            <PhoneInput
              size="sm"
              id="phoneNumber"
              placeholder={t({
                id: 'global.forms.inputs.phone_number_example',
                message: '+1 (123) 555-0123',
              })}
              onValidError={setPhoneErrorCallback}
              onClearError={clearPhoneErrorCallback}
              error={hasErrors}
              errorMessage={errors?.phoneNumber?.message}
              clearable
              {...register('phoneNumber')}
            />
          </Flex>
        </Flex>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            disabled: hasErrors,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
