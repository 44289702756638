import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, FlexProps, Text } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { fullTimeFormatSelector } from 'state/recoilState';
import { dateTime } from 'utils/dateTime';

type Props = Omit<FlexProps, 'children'> & {
  variant?: 'naked';
  title?: React.ReactNode;
  children?: React.ReactNode;
  bold?: boolean;
  value?: number | string;
  range?: {
    fromUnix: number;
    toUnix: number;
  };
  showZeros?: boolean;
  showTitleOnHover?: boolean;
};

export type FancyDataBoxProps = Props;

export const FancyDataBox = ({
  title,
  value,
  range,
  bold,
  sx,
  variant,
  showZeros,
  children,
  showTitleOnHover,
  ...props
}: Props): React.ReactElement => {
  const timeFormat = useRecoilValue(fullTimeFormatSelector);

  return (
    <Flex
      variant="reports.fancyDataBox"
      data-bold={bold}
      {...props}
      {...(showTitleOnHover && { title })}
      sx={{
        ...(sx && sx),
        ...(variant === 'naked' && {
          p: 0,
          bg: 'reports.fancyDataBox.bg',
          flexWrap: 'wrap',
          gap: '0.3rem',
        }),
      }}
    >
      {title && <TextEllipsis className="title">{title}</TextEllipsis>}
      {range && (
        <Flex sx={{ gap: '0.125rem' }}>
          <Text className="value">{dateTime(range.fromUnix).format(timeFormat)}</Text>
          <Icon className="arrow" type="arrowRight" size={16} />
          <Text className="value">{dateTime(range.toUnix).format(timeFormat)}</Text>
        </Flex>
      )}
      <Flex className="content-wrapper" sx={{ gap: '0.3rem', flexShrink: 0 }}>
        {(!!value || showZeros) && <Text className="value">{value}</Text>}
        {children}
      </Flex>
    </Flex>
  );
};
