import { SupportedLanguages } from 'constants/supportedLanguages';

export enum DateFormat {
  DayAndMonth,
  MonthAndDay,
  YearAndMonth,
  YearAndDay,
}

export const dateFormatAsString = {
  0: 'DD.MM.YYYY',
  1: 'MM.DD.YYYY',
  2: 'YYYY.MM.DD',
  3: 'YYYY.DD.MM',
};

export enum TimeFormat {
  _24h,
  _12h,
}

export const timeFormatAsString = {
  0: '24',
  1: '12',
};

export enum PermissionsFeaturesTypes {
  AddEventsByWorkerApp = 0, // clockInsMobileApp
  AddEventsByMobileTimeClock = 1, // clockInsMobileTimeClock
  AddEventsByKiosk = 2, // clockInsKioskMode
  AddEventsByVrcp = 3, // clockInsWebApp
  // ShowAllTeammatesInfo = 4, // deleted from backend
  DisableLocationLimitation = 5, // turnOffLocationLimits
  // HideOnList = 6, // exists on back but not used by front
  LimitDataVisibility = 7, // LimitDataVisibility // this is not a mistake, for backend compatibility
  CalendarTagsWideView = 8, // calendarTagsWideView
  CalendarCompanyWideView = 9, // calendarCompanyWideView
  TimeTrackingTagsWideView = 10, // timeTrackingTagsWideView
  TimeTrackingCompanyWideView = 11, // timeTrackingCompanyWideView
  AttendanceOverviewTagsWideView = 12,
  AttendanceOverviewCompanyWideView = 13,
}

export enum PermissionsModulesTypes {
  Employees = 0,
  TimeTracking = 1,
  Schedules = 2,
  Requests = 3,
}

export enum PermissionsSystemManagementTypes {
  CompanySettings = 0,
  Permissions = 1,
  Payments = 2,
  AccessMobileTimeClocks = 3,
  AccessKioskMode = 4,
  Payroll = 5,
  // SuperAdmin
  // SuperAdmin = 1000,
  // SuperAdminMarketing = 1001,
  // SuperAdminStatistics = 1002,
  // SuperAdminPerformanceMonitor = 1003,
}

export interface FetchUserSessionResponse {
  dateFormat: DateFormat;
  hasPassword: boolean;
  hideDetailedRequestTypes: boolean;
  marketingAgreement: boolean;
  minCreateDateUnix?: number;
  nameDisplayOrder: NameDisplayOrder;
  permissions: {
    features: {
      type: PermissionsFeaturesTypes;
      value: boolean;
      inheritFromTag: boolean;
      inheritedValue: boolean;
    }[];
    isHeadAdmin?: boolean;
    modules: {
      type: PermissionsModulesTypes;
      value: boolean;
    }[];
    systemManagement: {
      type: PermissionsSystemManagementTypes;
      value: boolean;
    }[];
  };
  personId: string;
  preferredLanguage: SupportedLanguages;
  qrCodeUrl: string;
  showTutorial: boolean;
  timeFormat: TimeFormat;
  timeZoneId?: string;
  viewsSettings?: ViewsSetting;
}

export enum TeamHideableColumns {
  roleAndTags = 'roleAndTags',
  workPosition = 'workPosition',
  email = 'email',
  phoneNumber = 'phoneNumber',
  customEmployeeId = 'customEmployeeId',
  identity = 'identity',
}

export enum ClockLogHideableColumns {
  location = 'location',
  sendBy = 'sendBy',
  note = 'note',
}

export enum RequestsHideableColumns {
  number = 'number',
  createDateTimeUnix = 'createDateTimeUnix',
}

export type ViewSettings<HC = string> = {
  hiddenColumns: HC[];
  showSummaryRow?: boolean;
};

export type CalendarViewSettings = {
  groupSchedulesByHours?: boolean;
  personCellVisibleLimits?: string[];
};

type ViewsSetting = {
  team?: ViewSettings<TeamHideableColumns>;
  clockLog?: ViewSettings<ClockLogHideableColumns>;
  requests?: ViewSettings<RequestsHideableColumns>;
  requestsUsageOverview?: ViewSettings<string>;
  timesheetsReport?: ViewSettings<string>;
  calendar?: CalendarViewSettings;
};
export type UpdateUserSessionResponse = Record<string, unknown>;
export type RefreshUserSessionResponse = FetchUserSessionResponse;

export type EditViewsSettingActionProps = ViewsSetting;
export type EditViewsSettingResponse = null;

export enum NameDisplayOrder {
  NameFirst = 0,
  SurnameFirst = 1,
}
export enum Languages {
  'pl' = 0,
  'de',
  'en',
  'es',
  'fr',
}

export const languageAsString = {
  pl: 'Polski',
  en: 'English',
  de: 'Deustch',
  es: 'Español',
  fr: 'Français',
};
