import { useCallback } from 'react';
import { useClient } from 'react-fetching-library';
import { useSetRecoilState } from 'recoil';

import { fetchChatUsersAction } from 'api/actions/chat/chatActions';
import { FetchChatUsersActionProps } from 'api/actions/chat/chatActions.types';
import { chatUsersWithMessageHistoryAtom, chatUsersWithUnreadMessagesAtom, onlineChatUsersAtom } from 'state/chat';
import { getChatUsersSubGroupsMaps } from '../utils/getChatUsersSubGroupsMaps';

type UpdateChatUsersSubGroups = (config?: FetchChatUsersActionProps) => Promise<void>;

export const useUpdateChatUsersSubGroups = (): UpdateChatUsersSubGroups => {
  const setChatUsersWithMessageHistory = useSetRecoilState(chatUsersWithMessageHistoryAtom);
  const setChatUsersWithUnreadMessages = useSetRecoilState(chatUsersWithUnreadMessagesAtom);
  const setOnlineChatUsers = useSetRecoilState(onlineChatUsersAtom);
  const { query } = useClient();

  const updateChatUsersSubGroups: UpdateChatUsersSubGroups = useCallback(
    async (config) => {
      const { payload, error } = await query(fetchChatUsersAction(config));
      if (!error && payload) {
        const { withMessageHistoryMap, withUnreadMessagesMap, onlineMap } = getChatUsersSubGroupsMaps(payload);

        setChatUsersWithMessageHistory({ value: withMessageHistoryMap });
        setChatUsersWithUnreadMessages({ value: withUnreadMessagesMap });
        setOnlineChatUsers({ value: onlineMap });
      }
    },
    [query, setChatUsersWithMessageHistory, setChatUsersWithUnreadMessages, setOnlineChatUsers],
  );

  return updateChatUsersSubGroups;
};
