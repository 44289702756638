import { atom, selector } from 'recoil';
import _ from 'lodash';

import { Location } from 'api/actions/location/locationActions.types';
import { FetchOrganizationSettingsResponse } from 'api/actions/settings/settingsActions.types';

import { parsedSearchFilterValueSelectorFamily, SEARCH_FILTER_TYPE } from './filters';

export const organizationSettingsAtom = atom<FetchOrganizationSettingsResponse | null>({
  key: 'organizationSettings__locations',
  default: null,
});

export const locationsAtom = atom<Location[] | null>({
  key: 'locations',
  default: null,
});

export const filteredLocationsSelector = selector<Location[] | null>({
  key: 'filteredLocations',
  get: ({ get }) => {
    const locations = get(locationsAtom);
    const searchFilterState = get(parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.LOCATIONS));

    const filterBySearchInput = (location: Location) => {
      let valid = false;

      if (_.isEmpty(searchFilterState)) {
        valid = true;
        return valid;
      }

      // comment out when backend stops sending locations without address (domyślne)
      const defaultLocation = {
        postalCode: '',
        city: '',
        street: '',
      };
      const { postalCode, city, street } = location.address || defaultLocation;
      //

      const {
        name,
        // address: { postalCode, city, street }, uncomment when backend stops sending locations without address (domyślne)
        range,
      } = location;
      const searchableLocation: (string | undefined)[] = [name, postalCode, city, street, String(range)];

      valid = searchFilterState.some((searchableQuery) =>
        searchableLocation.some((searchableValue) => {
          if (!searchableValue) return false;
          return searchableValue.toLocaleLowerCase().includes(searchableQuery.toLocaleLowerCase());
        }),
      );

      return valid;
    };

    if (!locations) return null;

    return locations?.filter((location) => filterBySearchInput(location));
  },
});
