import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Icons } from 'components/Icon/Icon.types';
import { Modal } from 'components/Modal/output/Modal';
import { addRequestStateAtom } from 'state/requests';

import { FancySelectedType } from './FancySelectedType';

export const SelectedRequestType = (): React.ReactElement => {
  useLingui();
  const { requestType } = useRecoilValue(addRequestStateAtom);

  const iconType: Icons = useMemo(() => {
    switch (requestType) {
      case RequestFormType.BusinessTrip:
        return 'businessTrip';
      case RequestFormType.Custom:
        return 'customRequest';
      case RequestFormType.TimeTracking:
        return 'timeClock';
      case RequestFormType.TimeOff:
        return 'timeOff';
      case RequestFormType.Schedule:
      default:
        return 'calendar';
    }
  }, [requestType]);

  const label = useMemo(() => {
    switch (requestType) {
      case RequestFormType.BusinessTrip:
        return t({
          id: 'request.type.business_trip',
        });
      case RequestFormType.Custom:
        return t({
          id: 'request.type.custom',
        });
      case RequestFormType.TimeTracking:
        return t({
          id: 'request.type.time_tracking',
        });
      case RequestFormType.TimeOff:
        return t({
          id: 'request.type.time_off',
        });
      case RequestFormType.Schedule:
      default:
        return t({ id: 'add_request.shift_swap' });
    }
  }, [requestType]);

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Modal.SubTitle>
        <Trans id="requests.add_request.request_type">Request type</Trans>
      </Modal.SubTitle>
      <FancySelectedType iconType={iconType} label={label} />
    </Flex>
  );
};
