import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Flex } from 'theme-ui';

import { UserChangePasswordSettingsActionProps } from 'api/actions/settings/settingsActions.types';
import { Button } from 'components/ui/Buttons';
import { PasswordInput } from 'components/ui/PasswordInput';
import { TextInput, TextInputProps } from 'components/ui/TextInput';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { SettingsGroup } from '../../SettingsGroup/SettingsGroup';

type Props = {
  onSubmit: (body: UserChangePasswordSettingsActionProps) => Promise<boolean>;
};

export const ChangePasswordForm = ({ onSubmit }: Props): React.ReactElement => {
  useLingui();
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const newPasswordRef = useRef<HTMLInputElement | null>(null);
  const confirmPasswordAfterBlurRef = useRef<boolean>(false);

  const {
    handleSubmit,
    setError,
    clearErrors,
    register,
    reset,
    trigger,
    formState: { errors, isValid, isSubmitting },
  } = useForm<{
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const handleSubmitCallback = async (body: UserChangePasswordSettingsActionProps) => {
    const shouldResetForm = await onSubmit({
      ..._.omit(body, 'confirmPassword'),
    });

    if (shouldResetForm) {
      setNativeValue(newPasswordRef, '');
      confirmPasswordAfterBlurRef.current = false;
      reset();
    }
  };

  const currentPasswordRegister = useMemo(
    () => register('currentPassword', { required: t({ id: 'global.forms.required' }) }),
    [register],
  );

  const newPasswordRegister = useMemo(
    () => register('newPassword', validationFactory({ ...VALIDATION_RULES.PASSWORD, required: true })),
    [register],
  );

  const confirmPasswordRegister = useMemo(
    () =>
      register('confirmPassword', {
        required: t({ id: 'global.forms.required' }),
        validate: (value, formValues) =>
          value === formValues.newPassword ||
          t({
            id: 'settings.user.change_password.not_match',
            message: 'Passwords do not match',
          }),
      }),
    [register],
  );

  const setPasswordErrorCallback = useCallback(() => {
    setError('passwordInternalError' as 'newPassword', {
      message: 'only visible in code',
    });
  }, [setError]);

  const clearPasswordErrorCallback = useCallback(() => {
    clearErrors('passwordInternalError' as 'newPassword');
  }, [clearErrors]);

  const newPasswordOnChange: NonNullable<TextInputProps['onChange']> = useCallback(
    (e) => {
      void newPasswordRegister.onChange(e);
      if (newPasswordRef.current && newPasswordRef.current.value.length > 0 && confirmPasswordAfterBlurRef.current) {
        void trigger('confirmPassword');
      }
    },
    [newPasswordRegister, trigger],
  );

  const confirmPasswordOnBlur: NonNullable<TextInputProps['onBlur']> = useCallback(
    (e) => {
      confirmPasswordAfterBlurRef.current = true;
      void confirmPasswordRegister.onBlur(e);
    },
    [confirmPasswordRegister],
  );

  return (
    <form onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}>
      <SettingsGroup>
        <SettingsGroup.Body>
          <SettingsGroup.Body.Visible variant="xlg">
            <TextInput
              id="password"
              type="password"
              {...currentPasswordRegister}
              placeholder={t({
                id: 'settings.user.change_password.current_password',
                message: 'Current password',
              })}
              label={t({
                id: 'settings.user.change_password.current_password',
                message: 'Current password',
              })}
              variant="rounded"
              error={!!errors.currentPassword}
              errorMessage={errors?.currentPassword?.message}
              autoComplete="current-password"
              size="sm"
              onChange={_.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentPassword(e.target.value);
                void currentPasswordRegister.onChange(e);
              }, 100)}
            />

            <Flex sx={{ flexDirection: 'column' }}>
              <PasswordInput
                id="newPassword"
                {...newPasswordRegister}
                autoComplete="new-password"
                ref={mergeRefs([newPasswordRegister.ref, newPasswordRef])}
                label={t({
                  id: 'settings.user.change_password.new_password',
                  message: 'New password',
                })}
                variant="rounded"
                placeholder={t({ id: 'settings.user.change_password.new_password' })}
                error={!!errors.newPassword}
                errorMessage={errors?.newPassword?.message}
                size="sm"
                sx={{ mb: 2 }}
                onValidError={setPasswordErrorCallback}
                onClearError={clearPasswordErrorCallback}
                onChange={newPasswordOnChange}
                oldPassword={currentPassword}
              />

              <TextInput
                id="confirmPassword"
                type="password"
                autoComplete="new-password"
                {...confirmPasswordRegister}
                label={t({
                  id: 'settings.user.change_password.confirm_password',
                  message: 'Confirm password',
                })}
                variant="rounded"
                placeholder={t({
                  id: 'settings.user.change_password.confirm_password',
                  message: 'Confirm password',
                })}
                error={!!errors.confirmPassword}
                errorMessage={errors?.confirmPassword?.message}
                onBlur={confirmPasswordOnBlur}
                onChange={_.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
                  void confirmPasswordRegister.onChange(e);
                }, 100)}
                size="sm"
              />
            </Flex>

            <Button
              variant="primary"
              type="submit"
              shape="rounded"
              disabled={!isValid || !!Object.keys(errors)?.length}
              isLoading={isSubmitting}
              sx={{ alignSelf: 'flex-start' }}
            >
              <Trans id="settings.user.change_password.submit_button">Update password</Trans>
            </Button>
          </SettingsGroup.Body.Visible>
        </SettingsGroup.Body>
      </SettingsGroup>
    </form>
  );
};
