import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex, Text } from 'theme-ui';

import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { useTimeOffFormState } from '../hooks/useTimeOffFormState';

export const Duration = (): React.ReactElement | null => {
  useLingui();

  const { isDurationType } = useTimeOffFormState();

  const {
    register,
    formState: { errors },
  } = useFormContext<{ dateTimeDetails: { duration: number } }>();

  if (!isDurationType) return null;

  return (
    <Flex>
      <Text sx={{ mb: 2, fontWeight: 'bold', alignSelf: 'center' }}>
        <Trans id="requests.duration">Duration</Trans>
      </Text>
      <DurationPicker
        {...register('dateTimeDetails.duration', {
          valueAsNumber: true,
          required: t({ id: 'global.forms.required' }),
        })}
        id="duration"
        size="sm"
        error={!!errors?.dateTimeDetails?.duration}
        errorMessage={errors.dateTimeDetails?.duration?.message}
        hours
        minutes
        seconds={false}
        extended
        sx={{ ml: 'auto' }}
      />
    </Flex>
  );
};
