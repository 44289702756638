import { Trans } from '@lingui/macro';
import React from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { ElementGroup } from 'components/ui/ElementGroup';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { APP_NAME, HOSTNAME } from 'constants/common';
import { EXTERNAL_LINKS } from 'constants/externalLinks';

const listSx: ThemeUIStyleObject = {
  fontSize: 3,
  // fontWeight: '700',
  color: 'authentication.text',
  flexWrap: 'wrap',
  justifyContent: 'center',
  '> li': {
    px: 1,
    '+ li::before': {
      content: '"·"',
      mr: 2,
    },
  },
};

export const Footer = (): React.ReactElement => (
  <Flex
    as="footer"
    sx={{
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'flex-end',
      pb: 4,
    }}
  >
    <ElementGroup showAsList wrapperSx={listSx} direction="row" marginValue={0}>
      <LinkAnchor href={`https://${HOSTNAME}`} target="_blank">
        {APP_NAME}™
      </LinkAnchor>
      <LinkAnchor href={EXTERNAL_LINKS.TERMS} target="_blank">
        <Trans id="sign_in.footer.link.terms">Terms of use</Trans>
      </LinkAnchor>
      <LinkAnchor href={EXTERNAL_LINKS.PRIVACY_POLICY} target="_blank">
        <Trans id="sign_in.footer.link.privacy_policy">Privacy policy</Trans>
      </LinkAnchor>
    </ElementGroup>
  </Flex>
);
