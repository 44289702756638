import { t, Trans } from '@lingui/macro';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Text, ThemeUICSSObject } from 'theme-ui';

import { Divider } from 'components/Divider/Divider';
import { Icon } from 'components/Icon/Icon';
import { LoadingSpinnerSize } from 'components/Loading/LoadingSpinnerCSS';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { AnchorButton } from 'components/ui/Buttons';
import { Image } from 'components/ui/Image';
import {
  APP_STORE_DOWNLOAD_APP_LINK,
  APP_STORE_DOWNLOAD_RCP_APP_LINK,
  GOOGLE_PLAY_DOWNLOAD_APP_LINK,
  GOOGLE_PLAY_DOWNLOAD_RCP_APP_LINK,
  LOGO_SRC_TIME_CLOCK_APP,
  LOGO_SRC_WORKER_APP,
  TIME_CLOCK_APP_NAME,
  WORKER_APP_NAME,
} from 'constants/common';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { windowSizeAtom } from 'state/recoilState';
import { getSrcSet } from 'utils/getSrcSet';

type MobileAppDetailsProps = {
  logo: string;
  name: string;
  callToAction: string;
  description: string;
  googleLink: string;
  appleLink: string;
};

const LOGO_SIZE = '64px';
const LOGO_SIZE_MOBILE = '100px';

const buttonBg = {
  default: 'navbar.getMobileApps.btn.bg.default',
  hover: 'navbar.getMobileApps.btn.bg.hover',
  tap: 'navbar.getMobileApps.btn.bg.tap',
  disabled: 'navbar.getMobileApps.btn.bg.disabled',
};
const buttonSx: ThemeUICSSObject = {
  color: 'navbar.getMobileApps.btn.text',
  width: '204px',
  height: '40px',
  textAlign: 'center',
  '> span': {
    width: 'auto',
  },
};

const MobileAppDetails = ({
  logo,
  name,
  callToAction,
  description,
  googleLink,
  appleLink,
}: MobileAppDetailsProps): React.ReactElement => {
  const { isLandscape } = useRecoilValue(windowSizeAtom);
  const logoSizes = [isLandscape ? LOGO_SIZE : LOGO_SIZE_MOBILE, LOGO_SIZE];

  return (
    <Flex
      sx={{
        gap: 4,
        flexDirection: [isLandscape ? 'row' : 'column', 'row'],
        alignItems: [isLandscape ? null : 'center', 'flex-start'],
      }}
    >
      <Image
        src={logo}
        srcSet={getSrcSet(logo)}
        alt={name}
        loadingOverlaySize={LoadingSpinnerSize.imageSM}
        wrapperSx={{ minWidth: 'fit-content' }}
        sx={{ minWidth: logoSizes, height: logoSizes, width: logoSizes, borderRadius: 'default' }}
      />

      <Flex sx={{ flexDirection: 'column' }}>
        <Text
          sx={{
            fontWeight: 'bold',
            mb: 2,
            fontSize: 3,
            alignSelf: [isLandscape ? null : 'center', 'flex-start'],
          }}
        >
          {name}
        </Text>
        <Text sx={{ mb: 1 }}>{callToAction}</Text>
        <Text>{description}</Text>
      </Flex>

      <Flex sx={{ flexDirection: 'column', gap: 2, flexShrink: 0 }}>
        <AnchorButton
          href={googleLink}
          target="_blank"
          prependWith={<Icon type="android" size={24} />}
          shape="rounded"
          bgOverwrite={buttonBg}
          sx={buttonSx}
        >
          <Trans id="get_mobile_apps.download_for_android">Download for Android</Trans>
        </AnchorButton>
        <AnchorButton
          href={appleLink}
          target="_blank"
          prependWith={<Icon type="apple" size={24} />}
          shape="rounded"
          bgOverwrite={buttonBg}
          sx={buttonSx}
        >
          <Trans id="get_mobile_apps.download_for_ios">Download for iOS</Trans>
        </AnchorButton>
      </Flex>
    </Flex>
  );
};

export const GetMobileAppsModal = (): React.ReactElement => {
  const {
    features: { AddEventsByWorkerApp, AddEventsByMobileTimeClock },
  } = useAppPermissions();

  const { baseRoute } = useModal({ wrapperSx: { height: '680px' } });

  if (!AddEventsByWorkerApp || !AddEventsByMobileTimeClock) return <Navigate to={baseRoute} relative="path" />;

  return (
    <>
      <Modal.Header>
        <Modal.Title sx={{ fontSize: [null, '1.5rem !important'] }}>
          {AddEventsByWorkerApp && AddEventsByMobileTimeClock
            ? t({ id: 'navbar.side_menu.get_mobile_apps' })
            : t({ id: 'navbar.side_menu.get_mobile_app' })}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ fontSize: 2, gap: 4 }}>
        {AddEventsByWorkerApp && (
          <MobileAppDetails
            logo={LOGO_SRC_WORKER_APP}
            name={WORKER_APP_NAME}
            callToAction={t({
              id: 'get_mobile_apps.staff_scheduling.call_to_action',
              message: 'Empower your staff with Employee Self-Service (ESS)!',
            })}
            description={t({
              id: 'get_mobile_apps.staff_scheduling.description',
              message:
                'FREE mobile app for all employees! Designed to work perfectly on both Android and iOS phones. You and your team deserves the convenience and transparency of HR automation.',
            })}
            googleLink={GOOGLE_PLAY_DOWNLOAD_APP_LINK}
            appleLink={APP_STORE_DOWNLOAD_APP_LINK}
          />
        )}

        {AddEventsByWorkerApp && AddEventsByMobileTimeClock && <Divider />}

        {AddEventsByMobileTimeClock && (
          <MobileAppDetails
            logo={LOGO_SRC_TIME_CLOCK_APP}
            name={TIME_CLOCK_APP_NAME}
            callToAction={t({
              id: 'get_mobile_apps.timeclock.call_to_action',
              message: 'Turn your Smartphone or Tablet into a Mobile Work Time Clock!',
            })}
            description={t({
              id: 'get_mobile_apps.timeclock.description',
              message:
                "Free mobile app for any device running on Android or iOS. You do not have to buy an expensive time clock in order to fully automate your company's business processes and workflow.",
            })}
            googleLink={GOOGLE_PLAY_DOWNLOAD_RCP_APP_LINK}
            appleLink={APP_STORE_DOWNLOAD_RCP_APP_LINK}
          />
        )}
      </Modal.Body>
    </>
  );
};
