import { t } from '@lingui/macro';

import { InvitationStates } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Icons } from 'components/Icon/Icon.types';
import { TO_REL } from 'constants/routes';
import { SupportedLanguages } from 'constants/supportedLanguages';
import { ParsedEmployee } from 'state/employees';

type CystomDefaultColors = {
  color?: string;
  colorHover?: string;
};
type GetEmployeeStatesReturnType = {
  color: string;
  colorHover: string;
  iconType: Icons;
  message: string;
  route?: Record<keyof typeof SupportedLanguages, string>;
}[];

export const getEmployeeStates = (
  {
    invitationState,
    isHidden,
    isActive,
    email,
  }: Pick<ParsedEmployee, 'invitationState' | 'isActive' | 'isHidden' | 'email'>,
  customDefaultColors?: CystomDefaultColors,
): GetEmployeeStatesReturnType | null => {
  if (!invitationState && !isHidden && isActive && email) return null;
  const defaultColors = {
    color: 'team.getEmployeeStates.default.text',
    colorHover: 'team.getEmployeeStates.default.hover',
    ...(customDefaultColors && customDefaultColors),
  };

  const invitationStateProps = (() => {
    switch (invitationState) {
      case InvitationStates.EXPIRED:
        return {
          color: 'team.getEmployeeStates.warning.text',
          colorHover: 'team.getEmployeeStates.warning.hover',
          iconType: 'warning' as const,
          message: t({ id: 'team.state.invitation_expired', message: 'Invitation expired' }),
          route: TO_REL.RE_INVITE_TEAMMATES_MODAL,
        };
      case InvitationStates.PENDING:
        return {
          ...defaultColors,
          iconType: 'invitationPending' as const,
          message: t({ id: 'team.state.invitation_pending', message: 'Invitation pending' }),
        };
      default:
        if (email) return undefined;
        return {
          ...defaultColors,
          iconType: 'notInvited' as const,
          message: t({ id: 'team.state.not_invited', message: 'Account not invited' }),
          route: TO_REL.INVITE_TEAMMATES_MODAL,
        };
    }
  })();

  return [
    ...(!isActive
      ? [
          {
            iconType: 'lock' as const,
            message: t({ id: 'team.state.deactivated', message: 'Account deactivated' }),
            ...defaultColors,
          },
        ]
      : []),
    ...(isHidden
      ? [
          {
            iconType: 'eyeOff' as const,
            message: t({ id: 'team.state.hidden', message: 'Account hidden' }),
            ...defaultColors,
          },
        ]
      : []),
    ...(invitationStateProps ? [invitationStateProps] : []),
  ];
};
