import { Trans } from '@lingui/macro';
import { useRecoilValue } from 'recoil';
import { Flex, Heading, Text } from 'theme-ui';

import { Button, LinkButton } from 'components/ui/Buttons';
import { TO_REL } from 'constants/routes';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { languageSelector } from 'state/recoilState';
import { onboardingStepsType } from '../../types';

const FinishMessage = () => {
  const language = useRecoilValue(languageSelector);

  const { stopOnboarding } = useInitialOnboarding();

  return (
    <Flex sx={{ flexDirection: 'column', alignContent: 'center', gap: 'inherit', textAlign: 'center' }}>
      <Flex variant="onboarding.modalHeaderIcon">🎉</Flex>
      <Heading variant="heading3" as="h3">
        <Trans id="onboarding.finish.title">
          And that's it, from this point onwards, you can go on exploring all the cool stuff that we've created.
        </Trans>
      </Heading>
      <Text as="p" variant="p">
        <Trans id="onboarding.finish.message">
          Remember that we also have <b>awesome mobile apps</b>, check them out! We promise, you won't regret.
        </Trans>
      </Text>

      <Flex sx={{ gap: 2, justifyContent: 'center', pt: 3 }}>
        <LinkButton
          shape="rounded"
          variant="primary"
          onClick={stopOnboarding}
          to={TO_REL.GET_MOBILE_APPS_MODAL[language]}
          relative="path"
        >
          <Trans id="onboarding.action.check_mobile_apps">Check mobile apps</Trans>
        </LinkButton>
        <Button shape="rounded" variant="lightGrey" onClick={stopOnboarding}>
          <Trans id="onboarding.action.finish">Finish</Trans>
        </Button>
      </Flex>
      <Text as="p" sx={{ fontSize: 2, color: 'onboarding.text.default', mt: 3 }}>
        <Trans id="onboarding.hint.where_to_start">
          You can always restart onboarding, just click on your profile in the navbar and select <b>Show onboarding</b>.
        </Trans>
      </Text>
    </Flex>
  );
};

export const onboardingFinishMessage: onboardingStepsType = [
  {
    content: <FinishMessage />,
    mode: 'modal',
  },
];
