import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';

import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { VALIDATION_RULES } from 'constants/validationRules';
import { useFormContextCustomEvent } from '../../../../../../../hooks/useFormContextCustomEvent';
import { LabelWithBadge } from '../../../../../../LabelWithBadge';
import { OptionLabel } from '../../../../../../OptionLabel';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';

export const Overtime = (): React.ReactElement => {
  useLingui();

  const {
    registerOnBlur,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContextCustomEvent();

  const setOvertimeErrorCallback = useCallback(() => {
    setError('overtimeInternalError' as 'minOvertimeSeconds', { type: 'max' });
  }, [setError]);

  const clearOvertimeErrorCallback = useCallback(() => {
    clearErrors('overtimeInternalError' as 'minOvertimeSeconds');
  }, [clearErrors]);

  return (
    <SettingsGroup variant="md">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="time_tracking_settings.heading.overtime">Overtime</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <SettingsGroup.Body>
        <SettingsGroup.Body.Visible variant="sm">
          <OptionLabel
            label={
              <LabelWithBadge
                label={t({ id: 'time_tracking_settings.min_overtime_minutes.label' })}
                badgeText={t({ id: 'settings.scheduling' })}
                tooltipText={t({ id: 'settings.tooltip_scheduling', message: 'Requires Schedule module' })}
              />
            }
            apendWith={
              <DurationPicker
                {...registerOnBlur('minOvertimeSeconds', {
                  valueAsNumber: true,
                  required: t({ id: 'global.forms.required.short' }),
                })}
                id="minOvertimeSeconds"
                size="sm"
                minutes
                hours={false}
                seconds={false}
                inputVariant="minutes"
                extended
                onValidError={setOvertimeErrorCallback}
                onClearError={clearOvertimeErrorCallback}
                error={!!errors.minOvertimeSeconds}
                errorMessage={errors.minOvertimeSeconds?.message}
                maxDuration={VALIDATION_RULES.OVERTIME_DURATION_SECONDS.max}
              />
            }
          />
        </SettingsGroup.Body.Visible>
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
