/**
 * Creates a image srcset for divices with different pixel densities (2x, 3x).
 *
 * @param {string} baseSrc Image url.
 *
 * @return {React.RefCallback<T>} Return a string to be passed to the srcset atribute of a image element.
 * @example
 *
 * getSrcSet('https://cdn.com/image.png')
 * // => 'https://cdn.com/image@2x.png 2x,https://cdn.com/image@3x.png 3x'
 */

export const getSrcSet = (baseSrc: string): string | undefined => {
  const baseSrcArr = [...baseSrc.split('.')];
  if (baseSrcArr.length < 2) {
    return undefined;
  }
  const extension = baseSrcArr.pop();

  const srcSetArr: string[] = [];
  const descriptors = ['2x', '3x'];

  descriptors.forEach((d) => {
    const srcWithDescriptor = `${baseSrcArr.join('.')}@${d}.${extension} ${d}`;
    srcSetArr.push(srcWithDescriptor);
  });

  return srcSetArr.join();
};
