import React from 'react';

/**
 * Creates a function that assigns a given value to each ref from the refs param.
 *
 * @param {Array<React.MutableRefObject<T> | React.LegacyRef<T>>} refs Array of refs.
 * @return {React.RefCallback<T>} Returns a function that when invoked will assign a given value to each ref from the refs param. The function is invoked with one argument: (value).
 */

export function mergeRefs<T = HTMLAllCollection>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        // eslint-disable-next-line no-param-reassign
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
