import React from 'react';

import { PopperGuardProps, withPopper } from 'components/ui/PopperProvider/withPopper';
import { PopperProviderProps } from 'components/ui/PopperProvider/PopperProvider';

import { Dropdown, DropdownProps } from './Dropdown';

type WithAdditionalProps = {
  dropdownProps?: DropdownProps;
  popperProps?: Omit<PopperProviderProps, 'children' | 'content'> & PopperGuardProps;
  autoInitialize?: boolean;
};

const defaultProps: Partial<WithAdditionalProps> = {
  popperProps: undefined,
  dropdownProps: undefined,
};

export function withDropdown<T>(
  Component: React.ComponentType<T & WithAdditionalProps>,
): (props: T & WithAdditionalProps) => React.ReactElement {
  const ComponentWithPopper = withPopper(Component);

  const ComponentWithDropdown = ({ dropdownProps, popperProps, ...props }: T & WithAdditionalProps) => (
    <ComponentWithPopper
      // FIXME: didn't find a better way..
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(props as any)}
      popperProps={
        {
          trigger: 'click',
          placement: 'bottom-end',
          hideAfterPopperClick: true,
          withPortal: true,
          withMaxSizeModifier: true,
          popperMargin: 0,
          ...popperProps,
          content: <Dropdown {...dropdownProps} />,
        } as PopperProviderProps
      }
    />
  );
  ComponentWithDropdown.defaultProps = defaultProps;

  return ComponentWithDropdown;
}
