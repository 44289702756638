import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, FlexProps } from 'theme-ui';

import { Modal } from 'components/Modal/output/Modal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { ElementGroup } from 'components/ui/ElementGroup';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useLocationOnce } from 'hooks/useLocationOnce/useLocationOnce';
import { languageSelector } from 'state/recoilState';
import { userSessionPersonIdSelector } from 'state/userSession';
import { useDefaultTagsFeatures } from '../hooks/useDefaultTagsFeatures';

enum actionTypes {
  RESEND_INVITATION = 'RESEND_INVITATION',
  SET_POSITION = 'SET_POSITION',
  SET_PAY_DETAILS = 'SET_PAY_DETAILS',
  SET_ROLE = 'SET_ROLE',
  SET_TAGS = 'SET_TAGS',
  SET_TIME_OFF_LIMITS = 'SET_TIME_OFF_LIMITS',
  SET_REQUEST_LIMITS = 'SET_REQUEST_LIMITS',
  SET_ADVANCED_DETAILS = 'SET_ADVANCED_DETAILS',
  CLEAR_POSITION = 'CLEAR_POSITION',
  CLEAR_TAGS = 'CLEAR_TAGS',
  DELETE_AI_FACES_MODELS = 'DELETE_AI_FACES_MODELS',
  DELETE_PHOTOS = 'DELETE_PHOTOS',
}

type ActionButtonProps = {
  isActive: boolean;
  disabled?: boolean;
} & FlexProps;

const ActionButton: FC<ActionButtonProps> = ({ disabled, isActive, sx, children, ...restProps }) => (
  <Flex
    {...restProps}
    sx={{
      transition: 'all 0.2s ease-in-out',
      cursor: 'pointer',
      width: '100%',
      fontSize: 2,
      fontWeight: 'bold',
      py: '12px',
      px: 3,
      bg: isActive ? 'team.navigation.bg.active' : 'team.navigation.bg.default',
      color: !isActive ? 'team.navigation.text.default' : 'team.navigation.text.active',
      '&:hover': {
        ...(!isActive && {
          bg: 'team.navigation.bg.hoverNotActive',
        }),
      },
      ...(disabled && { pointerEvents: 'none' }),
      ...(sx && sx),
    }}
  >
    {children}
  </Flex>
);

type Props = {
  isCurrentUser: boolean;
};

export const Navigation: FC<Props> = ({ isCurrentUser }) => {
  useLingui();
  const userId = useRecoilValue(userSessionPersonIdSelector);
  const navigate = useAppNavigate();
  const language = useRecoilValue(languageSelector);
  const [selectedAction, setSelectedAction] = useState<actionTypes | null>(null);
  const {
    state: { ids },
  } = useLocationOnce();

  const [loading, setLoading] = useState(false);

  const { defaultTagsFeatures, fetchDefaultTagFeatures } = useDefaultTagsFeatures();
  const { systemManagement, modules, betaAccessOptIn, isHeadAdmin } = useAppPermissions();

  const selectedIdsWithoutCurrentUserId = useMemo(() => ids?.filter((id) => id !== userId), [userId, ids]);

  const handleAdvancedDetails = async () => {
    if (!defaultTagsFeatures) {
      setLoading(true);
      const { error } = await fetchDefaultTagFeatures({ unAbortable: true });
      setLoading(false);
      if (error) {
        return;
      }
    }

    navigate(TO_REL.BULK_ACTIONS_MODAL__SET_ADVANCED_DETAILS[language], {
      state: {
        ids: selectedIdsWithoutCurrentUserId,
      },
    });
  };

  const handleNext = () => {
    if (selectedAction) {
      switch (selectedAction) {
        case actionTypes.RESEND_INVITATION:
          navigate(`${TO_REL.BULK_ACTIONS_MODAL__RESEND_INVITATION[language]}`, {
            state: {
              ids: selectedIdsWithoutCurrentUserId,
            },
          });
          break;
        case actionTypes.SET_POSITION:
          navigate(`${TO_REL.BULK_ACTIONS_MODAL__SET_POSITION[language]}`, { state: { ids } });
          break;
        case actionTypes.SET_PAY_DETAILS:
          navigate(`${TO_REL.BULK_ACTIONS_MODAL__SET_PAY_DETAILS[language]}`, { state: { ids } });
          break;
        case actionTypes.SET_ROLE:
          navigate(`${TO_REL.BULK_ACTIONS_MODAL__SET_ROLE[language]}`, {
            state: { ids: selectedIdsWithoutCurrentUserId },
          });
          break;
        case actionTypes.SET_TAGS:
          navigate(`${TO_REL.BULK_ACTIONS_MODAL__SET_TAGS[language]}`, { state: { ids } });
          break;
        case actionTypes.SET_TIME_OFF_LIMITS:
          navigate(`${TO_REL.BULK_ACTIONS_MODAL__SET_TIME_OFF_LIMITS[language]}`, { state: { ids } });
          break;
        case actionTypes.SET_REQUEST_LIMITS:
          navigate(`${TO_REL.BULK_ACTIONS_MODAL__SET_REQUEST_LIMITS[language]}`, { state: { ids } });
          break;
        case actionTypes.SET_ADVANCED_DETAILS:
          void handleAdvancedDetails();
          break;
        case actionTypes.CLEAR_POSITION:
          navigate(`${TO_REL.BULK_ACTIONS_MODAL__CLEAR_POSITION[language]}`, { state: { ids } });
          break;
        case actionTypes.CLEAR_TAGS:
          navigate(`${TO_REL.BULK_ACTIONS_MODAL__CLEAR_TAGS[language]}`, { state: { ids } });
          break;
        case actionTypes.DELETE_AI_FACES_MODELS:
          navigate(`${TO_REL.BULK_ACTIONS_MODAL__DELETE_AI_FACES_MODELS[language]}`, { state: { ids } });
          break;
        case actionTypes.DELETE_PHOTOS:
          navigate(`${TO_REL.BULK_ACTIONS_MODAL__DELETE_PHOTOS[language]}`, { state: { ids } });
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.navigation_bulk.bulk_actions">Bulk actions</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isCurrentUser && (
          <ActionButton
            disabled={loading}
            isActive={selectedAction === actionTypes.RESEND_INVITATION}
            sx={{ borderRadius: 'sm' }}
            onClick={() => setSelectedAction(actionTypes.RESEND_INVITATION)}
          >
            <Trans id="team.navigation_bulk.reinvite">Resend invitation</Trans>
          </ActionButton>
        )}
        <ElementGroup showAsList wrapperSx={{ mt: 2 }} marginValue="1.5px" direction="column">
          <ActionButton
            disabled={loading}
            sx={{ borderTopLeftRadius: 'sm', borderTopRightRadius: 'sm' }}
            isActive={selectedAction === actionTypes.SET_POSITION}
            onClick={() => setSelectedAction(actionTypes.SET_POSITION)}
          >
            <Trans id="team.navigation_bulk.set_position">Set position</Trans>
          </ActionButton>
          {systemManagement.Payroll && (
            <ActionButton
              disabled={loading}
              isActive={selectedAction === actionTypes.SET_PAY_DETAILS}
              onClick={() => setSelectedAction(actionTypes.SET_PAY_DETAILS)}
            >
              <Trans id="team.navigation_bulk.set_pay_details">Set pay details</Trans>
            </ActionButton>
          )}
          {!isCurrentUser && systemManagement.Permissions && (
            <ActionButton
              disabled={loading}
              isActive={selectedAction === actionTypes.SET_ROLE}
              onClick={() => setSelectedAction(actionTypes.SET_ROLE)}
            >
              <Trans id="team.navigation_bulk.set_role">Set role</Trans>
            </ActionButton>
          )}
          {systemManagement.Permissions && (
            <ActionButton
              disabled={loading}
              isActive={selectedAction === actionTypes.SET_TAGS}
              onClick={() => setSelectedAction(actionTypes.SET_TAGS)}
            >
              <Trans id="team.navigation_bulk.set_tags">Set tags</Trans>
            </ActionButton>
          )}
          {modules.Requests && (
            <ActionButton
              disabled={loading}
              isActive={selectedAction === actionTypes.SET_TIME_OFF_LIMITS}
              onClick={() => setSelectedAction(actionTypes.SET_TIME_OFF_LIMITS)}
            >
              <Trans id="team.navigation_bulk.set_time_off_limits">Set time off limits</Trans>
            </ActionButton>
          )}
          {modules.Requests && (
            <ActionButton
              disabled={loading}
              isActive={selectedAction === actionTypes.SET_REQUEST_LIMITS}
              onClick={() => setSelectedAction(actionTypes.SET_REQUEST_LIMITS)}
            >
              <Trans id="team.navigation_bulk.set_custom_requests_limits">Set custom requests limits</Trans>
            </ActionButton>
          )}
          {!isCurrentUser && (
            <ActionButton
              disabled={loading}
              isActive={selectedAction === actionTypes.SET_ADVANCED_DETAILS}
              onClick={() => setSelectedAction(actionTypes.SET_ADVANCED_DETAILS)}
              sx={{ borderBottomLeftRadius: 'sm', borderBottomRightRadius: 'sm' }}
            >
              <Trans id="team.navigation_bulk.set_advanced_details">Set advanced details</Trans>
            </ActionButton>
          )}
        </ElementGroup>
        <ElementGroup showAsList wrapperSx={{ mt: 2 }} marginValue="1.5px" direction="column">
          <ActionButton
            disabled={loading}
            sx={{ borderTopLeftRadius: 'sm', borderTopRightRadius: 'sm' }}
            isActive={selectedAction === actionTypes.CLEAR_POSITION}
            onClick={() => setSelectedAction(actionTypes.CLEAR_POSITION)}
          >
            <Trans id="team.navigation_bulk.clear_position">Clear position</Trans>
          </ActionButton>
          {systemManagement.Permissions && (
            <ActionButton
              disabled={loading}
              isActive={selectedAction === actionTypes.CLEAR_TAGS}
              onClick={() => setSelectedAction(actionTypes.CLEAR_TAGS)}
              sx={{ borderBottomLeftRadius: 'sm', borderBottomRightRadius: 'sm' }}
            >
              <Trans id="team.navigation_bulk.clear_tags">Clear tags</Trans>
            </ActionButton>
          )}
        </ElementGroup>
        {isHeadAdmin && (
          <ElementGroup showAsList wrapperSx={{ mt: 2 }} marginValue="1.5px" direction="column">
            {betaAccessOptIn.faceRecognition && (
              <ActionButton
                disabled={loading}
                sx={{ borderTopLeftRadius: 'sm', borderTopRightRadius: 'sm' }}
                isActive={selectedAction === actionTypes.DELETE_AI_FACES_MODELS}
                onClick={() => setSelectedAction(actionTypes.DELETE_AI_FACES_MODELS)}
              >
                <Trans id="team.navigation_bulk.delete_ai_faces">Delete all AI faces recognition models</Trans>
              </ActionButton>
            )}
            <ActionButton
              disabled={loading}
              isActive={selectedAction === actionTypes.DELETE_PHOTOS}
              onClick={() => setSelectedAction(actionTypes.DELETE_PHOTOS)}
              sx={{
                borderBottomLeftRadius: 'sm',
                borderBottomRightRadius: 'sm',
                ...(!betaAccessOptIn.faceRecognition && {
                  borderTopLeftRadius: 'sm',
                  borderTopRightRadius: 'sm',
                }),
              }}
            >
              <Trans id="team.navigation_bulk.delete_photos">Delete all photos</Trans>
            </ActionButton>
          </ElementGroup>
        )}
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            disabled: !selectedAction,
            onClick: handleNext,
            variant: 'primary',
            children: t({ id: 'team.navigation_bulk.next', message: 'Next' }),
          },
        ]}
      />
    </>
  );
};
