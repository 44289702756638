/** @jsxImportSource theme-ui */

import React from 'react';
import { Text, ThemeUIStyleObject } from 'theme-ui';

import { LoadingSpinnerProps } from './LoadingSpinner';
import { LoadingSpinnerCss } from './LoadingSpinnerCSS';

const wrapperSx: ThemeUIStyleObject = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const containerSx: ThemeUIStyleObject = {
  position: 'relative',
  width: '100%',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
};

const messageSx: ThemeUIStyleObject = {
  color: 'texts.lighter',
  position: 'absolute',
  top: '100%',
  mt: 3,
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
};

type Props = {
  background?: string;
  color?: string;
  spinnerProps?: Omit<LoadingSpinnerProps, 'color'>;
  sx?: ThemeUIStyleObject;
  message?: string | React.ReactElement;
};

const defaultProps: Partial<Props> = {
  background: 'loading.loadingSplash.background',
  sx: undefined,
  color: 'loading.loadingSplash.color',
  spinnerProps: undefined,
  message: undefined,
};

export const LoadingSplash = ({
  background,
  spinnerProps,
  color,
  message,
  sx,
  ...props
}: Props): React.ReactElement => (
  <div {...props} sx={{ ...wrapperSx, ...sx, background }} title="Loading">
    <div sx={containerSx}>
      <LoadingSpinnerCss size={5} color={color} {...spinnerProps} />
      {message && (
        <Text as="p" variant="pLead" sx={messageSx}>
          {message}
        </Text>
      )}
    </div>
  </div>
);

LoadingSplash.defaultProps = defaultProps;
