import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  EditWorkPositionDetailsActionProps,
  EditWorkPositionDetailsResponse,
  AddWorkPositionActionProps,
  AddWorkPositionResponse,
  DeleteWorkPositionResponse,
  DeleteWorkPositionActionProps,
  SetWorkPositionsStatesResponse,
  SetWorkPositionsStatesActionProps,
} from './workPositionsActions.types';

export function editWorkPositionDetailsAction({
  workPositionId,
  workPosition,
}: EditWorkPositionDetailsActionProps): Action<EditWorkPositionDetailsResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.workPosition}/${workPositionId}`,
    body: workPosition,
  };
}

export function addWorkPositionAction(body: AddWorkPositionActionProps): Action<AddWorkPositionResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.workPosition,
    body,
  };
}

export function deleteWorkPositionsAction(body: DeleteWorkPositionActionProps): Action<DeleteWorkPositionResponse> {
  return {
    method: 'DELETE',
    endpoint: API_ENDPOINTS.workPosition,
    body,
  };
}

export function setWorkPositionsStatesAction(
  body: SetWorkPositionsStatesActionProps,
): Action<SetWorkPositionsStatesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setWorkPositionsStates,
    body,
  };
}
