import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import React from 'react';

import { Divider } from 'components/Divider/Divider';

type Props = {
  label: React.ReactNode;
  additionalLabel?: React.ReactNode;
  secondAdditionalLabel?: React.ReactNode;
  apendWith?: React.ReactNode;
  sx?: ThemeUIStyleObject;
  labelSx?: ThemeUIStyleObject;
  additionalLabelSx?: ThemeUIStyleObject;
  withDivider?: boolean;
};

const defaultProps = {
  additionalLabel: undefined,
  secondAdditionalLabel: undefined,
  apendWith: undefined,
  sx: undefined,
  labelSx: undefined,
  additionalLabelSx: undefined,
  withDivider: false,
};

export const OptionLabel = ({
  label,
  additionalLabel,
  secondAdditionalLabel,
  apendWith,
  sx,
  labelSx,
  additionalLabelSx,
  withDivider,
  ...props
}: Props): React.ReactElement => (
  <>
    <Flex sx={{ ...sx, flexDirection: ['column', null, null, 'row'], gap: 2 }} {...props}>
      <Flex
        sx={{
          flexWrap: 'wrap',
          alignSelf: ['flex-start', null, null, 'center'],
          cursor: 'default',
          userSelect: 'none',
          flexDirection: 'column',
        }}
      >
        <Text as="label" sx={{ fontSize: 2, fontWeight: 'bold', ...labelSx }}>
          {label}
        </Text>

        {additionalLabel && (
          <Text sx={{ lineHeight: 1, fontSize: 2, color: 'settings.additionalLabel', ...additionalLabelSx }}>
            {additionalLabel}
          </Text>
        )}
        {secondAdditionalLabel && (
          <Text sx={{ lineHeight: 1, fontSize: 2, color: 'settings.additionalLabel', ...additionalLabelSx }}>
            {secondAdditionalLabel}
          </Text>
        )}
      </Flex>
      <Flex sx={{ ml: 'auto', alignSelf: 'center', width: ['100%', null, null, 'unset'] }}>
        {apendWith && apendWith}
      </Flex>
    </Flex>
    {withDivider && <Divider />}
  </>
);

OptionLabel.defaultProps = defaultProps;
