/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/require-await */
import { t } from '@lingui/macro';
import type { ResponseInterceptor } from 'react-fetching-library';

import { Action, ActionConfig } from 'api/types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { PrintFileWindow } from 'pages/PreparingFile/PreparingFile';

export const responseFileUrlInterceptor: () => ResponseInterceptor<ActionConfig, { url: string }> =
  () => () => async (action: Action<unknown>, response) => {
    if (action?.config?.skipAllResponseInterceptors) return response;

    const xFileHeader = response.headers?.get('X-File');

    if (!xFileHeader) {
      return response;
    }

    const printFileWindow = action.config?.popupWindow as PrintFileWindow | null;

    const resolvePrintFileWindow = (fileUrl?: string) => {
      if (!printFileWindow) return;
      const { handle_resolve_print_file, closed } = printFileWindow;

      if (closed && fileUrl) {
        addSnackbar({
          message: t({ id: 'file.ready', message: 'Your file is ready!' }),
          variant: 'success',
          action: {
            title: t({ id: 'global.open', message: 'Open' }),
            onClickCallback: () => window.open(fileUrl),
          },
          duration: 8000,
        });
        return;
      }

      if (!handle_resolve_print_file) {
        printFileWindow.close();
        return;
      }
      handle_resolve_print_file(fileUrl);
    };

    const { payload } = response;
    const fileUrl: string | undefined = payload?.url;

    if (!fileUrl) {
      resolvePrintFileWindow();
      return response;
    }

    if (printFileWindow) {
      resolvePrintFileWindow(fileUrl);
      return response;
    }

    const { headers } = action;
    const isDownload = headers ? !!headers['X-Download'] : false;

    if (isDownload) {
      const link = document.createElement('a');
      link.href = fileUrl;
      document.body.appendChild(link);
      link.click();
      link.remove();
      return response;
    }

    // this should be treated only as a fallback, most browsers will block window.open when in async function
    // printFileWindow logic is more reliable
    window.open(fileUrl);

    return response;
  };
