import { t, Trans } from '@lingui/macro';
import React, { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';
import key from 'weak-key';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { LoadingSpinner } from 'components/Loading/LoadingSpinner';
import { Button } from 'components/ui/Buttons';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { useTimeTracker } from 'context/workTimeTracker/useTimeTracker';
import { parsedTimeTrackerSelector } from 'state/drawer';
import { delay } from 'utils/delay';
import { Drawer } from '../Drawer';

import { FilterSearchStatuses } from './FilterSearchStatuses';
import { useIsFetchingTimeTrackerAfterVisibilityChange } from './hooks/useIsFetchingTimeTrackerAfterVisibilityChange';
import { TimeEventTimer } from './TimeEventTimer';

export const TimeTracker = (): React.ReactElement => {
  const isFetchingTimeTrackerAfterVisibilityChange = useIsFetchingTimeTrackerAfterVisibilityChange();
  const shouldFetchTimeTracker = useRef<boolean>(true);

  const { getTimeTracker } = useTimeTracker();
  const parsedTimeTracker = useRecoilValue(parsedTimeTrackerSelector);

  const firstElement = useRef<HTMLDivElement | null>(null);

  const focusTrap = useCallback(async () => {
    if (firstElement.current) {
      await delay(100);

      if (!firstElement.current) return;
      firstElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (shouldFetchTimeTracker.current) {
      shouldFetchTimeTracker.current = false;
      void getTimeTracker();
      void focusTrap();
    }
  }, [focusTrap, getTimeTracker]);

  if (!parsedTimeTracker) {
    return (
      <LoadingSpinner
        size={3}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      />
    );
  }

  const { workTime, recentTimeEventsList, availableTimeEvents, sortedAvailableTimeEvents, postTimeEvent } =
    parsedTimeTracker;

  return (
    <>
      {isFetchingTimeTrackerAfterVisibilityChange && <LoadingOverlay size={3} sx={{ zIndex: 'base' }} />}
      <Drawer.Header>
        <Drawer.Title>
          <Trans id="drawer.header.time_tracker">Select what you want to do</Trans>
        </Drawer.Title>
      </Drawer.Header>

      <Drawer.Container sx={{ gap: 4 }}>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <TimeEventTimer ref={firstElement} withTimer {...workTime} />

          {recentTimeEventsList.map((timeEvent) => (
            <TimeEventTimer key={key(timeEvent)} {...timeEvent} />
          ))}
        </Flex>

        {availableTimeEvents.length > 0 && (
          <>
            <Flex sx={{ flexDirection: 'column', gap: 2 }}>
              <Flex sx={{ alignItems: 'center' }}>
                <Drawer.SubTitle sx={{ flexShrink: 0, mr: 2, mb: 0 }}>
                  <Trans id="drawer.header.time_tracker.other_status">Start other status</Trans>
                </Drawer.SubTitle>
                <FilterSearchStatuses />
              </Flex>

              <Flex sx={{ flexDirection: 'column', gap: '0.125rem' }}>
                {sortedAvailableTimeEvents.map((tE, i, a) => (
                  <Button
                    key={tE.id}
                    sx={{
                      minHeight: '2.625rem',
                      ...(i === 0 && {
                        borderTopLeftRadius: 'default',
                        borderTopRightRadius: 'default',
                      }),
                      ...(i === a.length - 1 && {
                        borderBottomLeftRadius: 'default',
                        borderBottomRightRadius: 'default',
                      }),
                    }}
                    variant="lightGrey"
                    shape="square"
                    fullWidth
                    omitSpanWrapper
                    onClick={() =>
                      void postTimeEvent({
                        timeEventTypeId: tE.id,
                        isEnd: false,
                      })
                    }
                  >
                    <TextEllipsis title={t({ id: tE.name })}>{t({ id: tE.name })}</TextEllipsis>
                  </Button>
                ))}
              </Flex>
            </Flex>
          </>
        )}
      </Drawer.Container>
    </>
  );
};
