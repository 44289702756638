import { useCallback, useMemo } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState, useRecoilValue } from 'recoil';

import { fetchCalendarForIdsAction } from 'api/actions/calendar/calendarActions';
import { calendarResponseAtom } from 'state/calendar';
import { ParsedEmployee } from 'state/employees';
import { dateRangeFilterAtom } from 'state/filters';

import { useCalendar } from './useCalendar';

export const useRefreshCalendar = (ids?: ParsedEmployee['id'][]) => {
  const dates = useRecoilValue(dateRangeFilterAtom);
  const [calendar, setCalendar] = useRecoilState(calendarResponseAtom);

  const fetchUpdateParams = useMemo(
    () =>
      dates && ids && ids.length > 0
        ? {
            employeeIds: ids,
            startDateUnix: dates.startDateUnix,
            endDateUnix: dates.endDateUnix,
          }
        : null,
    [dates, ids],
  );

  const { query: updateQuery } = useClient();
  const { fetch: fetchAll } = useCalendar(dates);

  const updateCalendarForIds = useCallback(
    async (employeeIds?: string[]) => {
      const getInsideParams = () => {
        if (!employeeIds) return fetchUpdateParams;

        if (employeeIds && dates) {
          return {
            employeeIds: [...(ids || []), ...employeeIds],
            startDateUnix: dates.startDateUnix,
            endDateUnix: dates.endDateUnix,
          };
        }

        return null;
      };

      const { payload, error } = await updateQuery(fetchCalendarForIdsAction(getInsideParams()));

      if (!error && payload) {
        setCalendar((prevState) => {
          if (prevState) {
            const { employeesData } = prevState;

            const employeesDataMap = new Map(employeesData);
            const payloadEmployeesDataMap = new Map(payload.employeesData);

            payloadEmployeesDataMap.forEach((val, key) => {
              employeesDataMap.set(key, val);
            });

            return {
              ...prevState,
              recentSchedules: payload.recentSchedules,
              hasPendingRequests: payload.hasPendingRequests,
              hasUnpublishedChanges: payload.hasUnpublishedChanges,
              employeesData: [...employeesDataMap],
            };
          }

          return null;
        });
      }
    },
    [dates, fetchUpdateParams, ids, setCalendar, updateQuery],
  );

  const updateCalendar = useMemo(
    () => () => {
      void fetchAll();
    },
    [fetchAll],
  );

  return {
    updateCalendarForIds,
    updateCalendar,
    calendarInitialized: !!calendar,
  };
};
