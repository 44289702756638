import _ from 'lodash';
import { generatePath, matchPath } from 'react-router-dom';

import { APP_LINK_BASENAME } from './common';
import { SupportedLanguages } from './supportedLanguages';

export const DATE_RANGE_PARAMS = '/:startDateUnix?/:endDateUnix?';

export const BASE_ROUTES = {
  // AUTHENTICATION
  SIGN_IN: {
    pl: '/zaloguj',
    en: '/sign-in',
  },
  SIGN_IN__APPLE_REDIRECT: {
    pl: '/zaloguj/apple',
    en: '/sign-in/apple',
  },
  SIGN_OUT: {
    pl: '/wyloguj',
    en: '/sign-out',
  },
  SIGN_UP: {
    pl: '/zaloz-konto',
    en: '/sign-up',
  },
  SIGN_UP__ACCOUNT_TYPE: {
    pl: '/zaloz-konto/rodzaj-konta',
    en: '/sign-up/account-type',
  },
  SIGN_UP__EMPLOYEE__INVITED: {
    pl: '/zaloz-konto/pracownik/zaproszony',
    en: '/sign-up/employee/invited',
  },
  SIGN_UP__EMPLOYEE: {
    pl: '/zaloz-konto/pracownik',
    en: '/sign-up/employee',
  },
  SIGN_UP__EMPLOYEE__NOT_INVITED: {
    pl: '/zaloz-konto/pracownik/brak-zaproszenia',
    en: '/sign-up/employee/not-invited',
  },
  SIGN_UP__EMPLOYER: {
    pl: '/zaloz-konto/organizacja',
    en: '/sign-up/company',
  },
  SIGN_UP__EMPLOYER__STEP: {
    pl: '/zaloz-konto/organizacja/:step',
    en: '/sign-up/company/:step',
  },
  PASS_RESET: {
    pl: '/resetowanie-hasla',
    en: '/password-reset',
  },
  PASS_RESET__CONFIRMED: {
    pl: '/resetowanie-hasla/nowe-haslo',
    en: '/password-reset/new-password',
  },
  QUICKBOOKS_DISCONNECT: {
    pl: '/quickbooks-disconnect',
    en: '/quickbooks-disconnect',
  },
  PREPARING_FILE: {
    pl: '/przygotowanie-pliku',
    en: '/preparing-file',
  },
  // HOME
  START: {
    pl: '/',
    en: '/',
  },
  // TEAM
  TEAM: {
    pl: '/zespol',
    en: '/team',
  },
  // PAYMENTS
  PAYMENTS: {
    pl: '/plan-i-platnosci',
    en: '/plan-and-billing',
  },
  // WORK STATUSES
  TIME_TRACKING__WORK_STATUSES: {
    pl: '/statusy-pracy',
    en: '/work-statuses',
  },
  // TIME TRACKING
  TIME_TRACKING__CLOCK_LOG: {
    pl: '/zdarzenia',
    en: '/clock-log',
  },
  CLOCK_LOG__TYPE: {
    pl: '/zdarzenia/:type',
    en: '/clock-log/:type',
  },
  CLOCK_LOG__ALL: {
    pl: '/zdarzenia/_wszystkie',
    en: '/clock-log/_all',
  },
  CLOCK_LOG__ALL__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/zdarzenia/_wszystkie${DATE_RANGE_PARAMS}`,
    en: `/clock-log/_all${DATE_RANGE_PARAMS}`,
  },
  CLOCK_LOG__PHOTO_LOG: {
    pl: '/zdarzenia/_foto-rejestracja',
    en: '/clock-log/_photo-log',
  },
  CLOCK_LOG__PHOTO_LOG__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/zdarzenia/_foto-rejestracja${DATE_RANGE_PARAMS}`,
    en: `/clock-log/_photo-log${DATE_RANGE_PARAMS}`,
  },
  CLOCK_LOG__MODIFIED: {
    pl: '/zdarzenia/_edytowane',
    en: '/clock-log/_modified',
  },
  CLOCK_LOG__MODIFIED__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/zdarzenia/_edytowane${DATE_RANGE_PARAMS}`,
    en: `/clock-log/_modified${DATE_RANGE_PARAMS}`,
  },
  CLOCK_LOG__PENDING: {
    pl: '/zdarzenia/_do-rozpatrzenia',
    en: '/clock-log/_pending',
  },
  CLOCK_LOG__PENDING__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/zdarzenia/_do-rozpatrzenia${DATE_RANGE_PARAMS}`,
    en: `/clock-log/_pending${DATE_RANGE_PARAMS}`,
  },
  CLOCK_LOG__AI_ANTI_SPOOFING: {
    pl: '/zdarzenia/_wykrywanie_naduzyc',
    en: '/clock-log/_ai_anti_spoofing',
  },
  CLOCK_LOG__AI_ANTI_SPOOFING__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/zdarzenia/_wykrywanie_naduzyc${DATE_RANGE_PARAMS}`,
    en: `/clock-log/_ai_anti_spoofing${DATE_RANGE_PARAMS}`,
  },
  CLOCK_LOG__AI_REJECTED: {
    pl: '/zdarzenia/_odrzucone',
    en: '/clock-log/_ai_rejected',
  },
  CLOCK_LOG__DELETED: {
    pl: '/zdarzenia/_usuniete',
    en: '/clock-log/_deleted',
  },
  CLOCK_LOG__DELETED__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/zdarzenia/_usuniete${DATE_RANGE_PARAMS}`,
    en: `/clock-log/_deleted${DATE_RANGE_PARAMS}`,
  },
  CLOCK_LOG__AI_REJECTED__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/zdarzenia/_odrzucone${DATE_RANGE_PARAMS}`,
    en: `/clock-log/_ai_rejected${DATE_RANGE_PARAMS}`,
  },
  TIME_TRACKING__LOCATIONS: {
    pl: '/lokalizacje',
    en: '/locations',
  },
  TIME_TRACKING__TIME_CLOCKS: {
    pl: '/urzadzenia',
    en: '/time-clocks',
  },
  // REPORTS
  REPORTS__TYPE: {
    pl: '/raporty/:type',
    en: '/reports/:type',
  },
  REPORTS__TYPE__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/raporty/:type${DATE_RANGE_PARAMS}`,
    en: `/reports/:type${DATE_RANGE_PARAMS}`,
  },
  REPORTS__ATTENDANCE_LIST: {
    pl: '/raporty/_lista-obecnosci',
    en: '/reports/_attendance-list',
  },
  REPORTS__ATTENDANCE_LIST__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/raporty/_lista-obecnosci${DATE_RANGE_PARAMS}`,
    en: `/reports/_attendance-list${DATE_RANGE_PARAMS}`,
  },
  REPORTS__ATTENDANCE_LIST__START_DATE_UNIX__END_DATE_UNIX__DETAILS__USER_ID__DATE_UNIX: {
    pl: `/raporty/_lista-obecnosci${DATE_RANGE_PARAMS}/szczegoly/:user/:unix`,
    en: `/reports/_attendance-list${DATE_RANGE_PARAMS}/details/:user/:unix`,
  },
  REPORTS__TIMESHEETS: {
    pl: '/raporty/_ewidencja-czasu-pracy',
    en: '/reports/_timesheets',
  },
  REPORTS__TIMESHEETS__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/raporty/_ewidencja-czasu-pracy${DATE_RANGE_PARAMS}`,
    en: `/reports/_timesheets${DATE_RANGE_PARAMS}`,
  },
  REPORTS__TIMESHEETS__DETAILS__USER_ID__DATE_UNIX: {
    pl: '/szczegoly/:user/:unix',
    en: '/details/:user/:unix',
  },
  // CALENDAR
  CALENDAR: {
    pl: '/kalendarz',
    en: '/calendar',
  },
  CALENDAR__TYPE: {
    pl: '/kalendarz/:type',
    en: '/calendar/:type',
  },
  CALENDAR__ALL: {
    pl: '/kalendarz/_wszystko',
    en: '/calendar/_all',
  },
  CALENDAR__ALL__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/kalendarz/_wszystko${DATE_RANGE_PARAMS}`,
    en: `/calendar/_all${DATE_RANGE_PARAMS}`,
  },
  CALENDAR__SCHEDULES: {
    pl: '/kalendarz/_grafiki',
    en: '/calendar/_schedules',
  },
  CALENDAR__SCHEDULES__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/kalendarz/_grafiki${DATE_RANGE_PARAMS}`,
    en: `/calendar/_schedules${DATE_RANGE_PARAMS}`,
  },
  CALENDAR__AVAILABILITY: {
    pl: '/kalendarz/_dostepnosc',
    en: '/calendar/_availability',
  },
  CALENDAR__AVAILABILITY__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/kalendarz/_dostepnosc${DATE_RANGE_PARAMS}`,
    en: `/calendar/_availability${DATE_RANGE_PARAMS}`,
  },
  CALENDAR__REQUESTS: {
    pl: '/kalendarz/_wnioski',
    en: '/calendar/_requests',
  },
  CALENDAR__REQUESTS__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/kalendarz/_wnioski${DATE_RANGE_PARAMS}`,
    en: `/calendar/_requests${DATE_RANGE_PARAMS}`,
  },
  DETAILS__USER_ID__DATE_UNIX: {
    pl: '/szczegoly/:user/:unix',
    en: '/details/:user/:unix',
  },
  CALENDAR__DETAILS__USER_ID__DATE_UNIX: {
    pl: '/kalendarz/:type/szczegoly/:user/:unix',
    en: '/calendar/:type/details/:user/:unix',
  },
  PAGE__TYPE__START_DATE_UNIX__END_DATE_UNIX__DETAILS__USER_ID__DATE_UNIX: {
    pl: `/:page/:type${DATE_RANGE_PARAMS}/szczegoly/:user/:unix`,
    en: `/:page/:type${DATE_RANGE_PARAMS}/details/:user/:unix`,
  },
  // SETTINGS
  SETTINGS: {
    pl: '/ustawienia',
    en: '/settings',
  },
  SETTINGS__USER: {
    pl: '/ustawienia/uzytkownik',
    en: '/settings/user',
  },
  SETTINGS__USER__PREFERENCES: {
    pl: '/ustawienia/uzytkownik/preferencje',
    en: '/settings/user/preferences',
  },
  SETTINGS__USER__NOTIFICATIONS: {
    pl: '/ustawienia/uzytkownik/powiadomienia',
    en: '/settings/user/notifications',
  },
  SETTINGS__USER__MANAGE_PASSWORD: {
    pl: '/ustawienia/uzytkownik/zarzadzanie-haslem',
    en: '/settings/user/manage-password',
  },
  SETTINGS__INTEGRATIONS: {
    pl: '/ustawienia/integracje',
    en: '/settings/integrations',
  },
  SETTINGS__ORGANIZATION: {
    pl: '/ustawienia/organizacja',
    en: '/settings/organization',
  },
  SETTINGS__ORGANIZATION__TIME_TRACKING: {
    pl: '/ustawienia/organizacja/rejestracja-czasu-pracy',
    en: '/settings/organization/time-tracking',
  },
  SETTINGS__ORGANIZATION__SCHEDULES: {
    pl: '/ustawienia/organizacja/grafiki',
    en: '/settings/organization/schedules',
  },
  SETTINGS__ORGANIZATION__REQUESTS: {
    pl: '/ustawienia/organizacja/wnioski',
    en: '/settings/organization/requests',
  },
  SETTINGS__AUTOMATIONS: {
    pl: '/ustawienia/organizacja/automatyzacja',
    en: '/settings/organization/automations',
  },
  SETTINGS__ORGANIZATION__NOTIFICATIONS: {
    pl: '/ustawienia/organizacja/powiadomienia',
    en: '/settings/organization/notifications',
  },
  SETTINGS__ORGANIZATION__ADVANCED: {
    pl: '/ustawienia/organizacja/zaawansowane',
    en: '/settings/organization/advanced',
  },
  // POPUP
  POPUP__TYPE: {
    pl: '/popup/:type',
    en: '/popup/:type',
  },
  POPUP__QUICKBOOKS: {
    pl: '/popup/quickbooks',
    en: '/popup/quickbooks',
  },
  POPUP__GOOGLE: {
    pl: '/popup/google',
    en: '/popup/google',
  },
  POPUP__XERO: {
    pl: '/popup/xero',
    en: '/popup/xero',
  },
  POPUP__SQUARE: {
    pl: '/popup/square',
    en: '/popup/square',
  },
  // REQUESTS
  REQUESTS: {
    pl: '/wnioski',
    en: '/requests',
  },
  REQUESTS__TYPE: {
    pl: '/wnioski/:type',
    en: '/requests/:type',
  },
  REQUESTS__GROUPED: {
    pl: '/wnioski/_zgrupowane',
    en: '/requests/_grouped',
  },
  REQUESTS__GROUPED__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/wnioski/_zgrupowane${DATE_RANGE_PARAMS}`,
    en: `/requests/_grouped${DATE_RANGE_PARAMS}`,
  },
  REQUESTS__UNGROUPED: {
    pl: '/wnioski/_niezgrupowane',
    en: '/requests/_ungrouped',
  },
  REQUESTS__UNGROUPED__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/wnioski/_niezgrupowane${DATE_RANGE_PARAMS}`,
    en: `/requests/_ungrouped${DATE_RANGE_PARAMS}`,
  },
  REQUESTS__PENDING: {
    pl: '/wnioski/_do-rozpatrzenia',
    en: '/requests/_pending',
  },
  REQUESTS__USAGE_OVERVIEW: {
    pl: '/wnioski/_uzycie-i-limity',
    en: '/requests/_usage-overview',
  },
  REQUESTS__USAGE_OVERVIEW__START_DATE_UNIX__END_DATE_UNIX: {
    pl: `/wnioski/_uzycie-i-limity${DATE_RANGE_PARAMS}`,
    en: `/requests/_usage-overview${DATE_RANGE_PARAMS}`,
  },
  DETAILS__USER_ID__REQUEST_TYPE__TYPE_ID: {
    pl: '/szczegoly/:user/:request/:typeid',
    en: '/details/:user/:request/:typeid',
  },
  PAGE__USAGE_OVERVIEW__START_DATE_UNIX__END_DATE_UNIX__DETAILS__USER_ID__REQUEST_TYPE__TYPE_ID: {
    pl: `/:page/_uzycie-i-limity${DATE_RANGE_PARAMS}/szczegoly/:user/:request/:typeid`,
    en: `/:page/_usage-overview${DATE_RANGE_PARAMS}/details/:user/:request/:typeid`,
  },
  // KIOSK
  KIOSK: {
    pl: '/kiosk',
    en: '/kiosk',
  },
  KIOSK_SUMMARY: {
    pl: '/kiosk/podsumowanie',
    en: '/kiosk/summary',
  },
  KIOSK__CAMERA_PERMISSION: {
    pl: '/kiosk/kamera-wymagana',
    en: '/kiosk/camera-permission',
  },
  KIOSK__LOCATION_PERMISSION: {
    pl: '/kiosk/lokalizacja-wymagana',
    en: '/kiosk/location-permission',
  },
  // ERRORS
  SERVICE_UNAVAILABLE: {
    pl: '/503',
    en: '/503',
  },
  PAGE_NOT_FOUND: {
    pl: '/404',
    en: '/404',
  },
  BLOCKED_ACCOUNT: {
    pl: '/blokada-konta',
    en: '/blocked-account',
  },
};

export const CLOCK_LOG_TYPE = {
  ALL: {
    pl: '_wszystkie',
    en: '_all',
  },
  PHOTO_LOG: {
    pl: '_foto-rejestracja',
    en: '_photo-log',
  },
  MODIFIED: {
    pl: '_edytowane',
    en: '_modified',
  },
  PENDING: {
    pl: '_do-rozpatrzenia',
    en: '_pending',
  },
  AI_ANTI_SPOOFING: {
    pl: '_wykrywanie_naduzyc',
    en: '_ai_anti_spoofing',
  },
  AI_REJECTED: {
    pl: '_odrzucone',
    en: '_ai_rejected',
  },
  DELETED: {
    pl: '_usuniete',
    en: '_deleted',
  },
};
export const REQUESTS_TYPE = {
  GROUPED: {
    pl: '_zgrupowane',
    en: '_grouped',
  },
  UNGROUPED: {
    pl: '_niezgrupowane',
    en: '_ungrouped',
  },
  PENDING: {
    pl: '_do-rozpatrzenia',
    en: '_pending',
  },
  USAGE_OVERVIEW: {
    pl: '_uzycie-i-limity',
    en: '_usage-overview',
  },
};
export const CALENDAR_TYPE = {
  ALL: {
    pl: '_wszystko',
    en: '_all',
  },
  SCHEDULES: {
    pl: '_grafiki',
    en: '_schedules',
  },
  AVAILABILITY: {
    pl: '_dostepnosc',
    en: '_availability',
  },
  REQUESTS: {
    pl: '_wnioski',
    en: '_requests',
  },
};
export const REPORTS_TYPE = {
  ATTENDANCE_LIST: {
    pl: '_lista-obecnosci',
    en: '_attendance-list',
  },
  TIMESHEETS: {
    pl: '_ewidencja-czasu-pracy',
    en: '_timesheets',
  },
};

const modalRoutes = {
  EDIT_MODAL__STEP: {
    pl: '/edytuj/:step',
    en: '/edit/:step',
  },
  ADD_AVATAR_MODAL: {
    pl: '/dodaj-awatar',
    en: '/add-avatar',
  },
  // KIOSK
  KIOSK_LOCATION_MODAL: {
    pl: '/lokalizacja',
    en: '/location',
  },
  KIOSK_SETTINGS_MODAL: {
    pl: '/ustawienia',
    en: '/settings',
  },
  KIOSK_HELP_CENTER_MODAL: {
    pl: '/centrum-pomocy',
    en: '/help-center',
  },
  KIOSK_HELP_CENTER_MODAL__STEP: {
    pl: '/centrum-pomocy/:step',
    en: '/help-center/:step',
  },
  // TEAMMATES
  ADD_TEAMMATE_MODAL: {
    pl: '/dodaj-pracownika',
    en: '/add-teammate',
  },
  DELETE_TEAMMATES_MODAL: {
    pl: '/usun-pracownika',
    en: '/delete-teammate',
  },
  DEACTIVATE_TEAMMATES_MODAL: {
    pl: '/dezaktywuj-pracownika',
    en: '/deactivate-teammate',
  },
  INVITE_TEAMMATES_MODAL: {
    pl: '/zapros-pracownikow',
    en: '/invite-teammates',
  },
  RE_INVITE_TEAMMATES_MODAL: {
    pl: '/ponownie-zapros-pracownikow',
    en: '/re-invite-teammates',
  },
  PRINT_QR_CARDS_MODAL: {
    pl: '/drukuj-karty-qr',
    en: '/print-qr-cards',
  },
  EDIT_TEAMMATE_MODAL__ID: {
    pl: '/edytuj-pracownika/:id',
    en: '/edit-teammate/:id',
  },
  VIEW_SETTINGS_MODAL: {
    pl: '/ustawienia-widoku',
    en: '/view-settings',
  },
  // IMPORTS
  IMPORT_MODAL__TYPE: {
    pl: '/importuj/:type',
    en: '/import/:type',
  },
  // TAGS
  MANAGE_TAGS_MODAL: {
    pl: '/zarzadzaj-tagami',
    en: '/manage-tags',
  },
  ADD_TAG_MODAL: {
    pl: '/dodaj-tag',
    en: '/add-tag',
  },
  EDIT_TAG_MODAL__ID: {
    pl: '/edytuj-tag/:id',
    en: '/edit-tag/:id',
  },
  DELETE_TAGS_MODAL: {
    pl: '/usun-tagi',
    en: '/delete-tags',
  },
  // ROLES
  MANAGE_ROLES_MODAL: {
    pl: '/zarzadzaj-rolami',
    en: '/manage-roles',
  },
  ADD_ROLE_MODAL: {
    pl: '/dodaj-role',
    en: '/add-role',
  },
  EDIT_ROLE_MODAL__ID: {
    pl: '/edytuj-role/:id',
    en: '/edit-role/:id',
  },
  PREVIEW_ROLE_MODAL__ID: {
    pl: '/podglad-roli/:id',
    en: '/preview-role/:id',
  },
  DELETE_ROLES_MODAL: {
    pl: '/usun-role',
    en: '/delete-roles',
  },
  // WORK_POSITIONS
  MANAGE_WORK_POSITIONS_MODAL: {
    pl: '/zarzadzaj-pozycjami',
    en: '/manage-positions',
  },
  ADD_WORK_POSITION_MODAL: {
    pl: '/dodaj-pozycje',
    en: '/add-position',
  },
  EDIT_WORK_POSITION_MODAL__ID: {
    pl: '/edytuj-pozycje/:id',
    en: '/edit-position/:id',
  },
  DELETE_WORK_POSITIONS_MODAL: {
    pl: '/usun-pozycje',
    en: '/delete-positions',
  },
  // PAYMENTS
  PAYMENT_METHOD_MODAL: {
    pl: '/metoda-platnosci',
    en: '/payment-method',
  },
  CANCEL_PAYMENT_SUBSCRIPTION_MODAL: {
    pl: '/anuluj-subskrypcje',
    en: '/cancel-subscription',
  },
  CANCEL_PENDING_PAYMENT: {
    pl: '/anuluj-platnosc',
    en: '/cancel-payment',
  },
  REMOVE_PAYMENT_METHOD_MODAL: {
    pl: '/usun-metode-platnosci',
    en: '/remove-payment-method',
  },
  PAYMENT_BILLING_DETAILS_MODAL: {
    pl: '/szczegoly-platnosci',
    en: '/billing-details',
  },
  PAYMENT_UNPAID_BALANCE_MODAL: {
    pl: '/niezaplacony-balans',
    en: '/unpaid-balance',
  },
  PAYMENT_BILLING_HISTORY_MODAL: {
    pl: '/historia-platnosci',
    en: '/billing-history',
  },
  // BULK_ACTIONS
  BULK_ACTIONS_MODAL: {
    pl: '/akcje-zbiorowe',
    en: '/bulk-actions',
  },
  BULK_ACTIONS_MODAL__RESEND_INVITATION: {
    pl: '/akcje-zbiorowe/ponownie-wyslij-zaproszenie',
    en: '/bulk-actions/resend-invitation',
  },
  BULK_ACTIONS_MODAL__SET_POSITION: {
    pl: '/ustaw-pozycje',
    en: '/set-position',
  },
  BULK_ACTIONS_MODAL__CLEAR_POSITION: {
    pl: '/wyczysc-pozycje',
    en: '/clear-position',
  },
  BULK_ACTIONS_MODAL__SET_PAY_DETAILS: {
    pl: '/ustaw-szczegoly-wyplaty',
    en: '/set-pay-details',
  },
  BULK_ACTIONS_MODAL__SET_ROLE: {
    pl: '/ustaw-role',
    en: '/set-role',
  },
  BULK_ACTIONS_MODAL__SET_TAGS: {
    pl: '/ustaw-tagi',
    en: '/set-tags',
  },
  BULK_ACTIONS_MODAL__CLEAR_TAGS: {
    pl: '/wyczysc-tagi',
    en: '/clear-tags',
  },
  BULK_ACTIONS_MODAL__SET_TIME_OFF_LIMITS: {
    pl: '/ustaw-limity-urlopow',
    en: '/set-time-off-limits',
  },
  BULK_ACTIONS_MODAL__SET_REQUEST_LIMITS: {
    pl: '/ustaw-limity-wnioskow',
    en: '/set-requests-limits',
  },
  BULK_ACTIONS_MODAL__SET_ADVANCED_DETAILS: {
    pl: '/ustaw-zaawansowane-szczegoly',
    en: '/set-advanced-details',
  },
  BULK_ACTIONS_MODAL__DELETE_AI_FACES_MODELS: {
    pl: '/usun-twarze',
    en: '/delete-faces',
  },
  BULK_ACTIONS_MODAL__DELETE_PHOTOS: {
    pl: '/usun-zdjecia',
    en: '/delete-photos',
  },
  BULK_ACTIONS_MODAL__CONFIRM_DELETION_MODAL: {
    pl: '/potwierdz-usuniecie',
    en: '/confirm-deletion',
  },
  // REQUESTS
  DELETE_REQUESTS_MODAL: {
    pl: '/usun-wniosek',
    en: '/delete-request',
  },
  REJECT_REQUESTS_MODAL: {
    pl: '/odrzuc-wniosek',
    en: '/reject-request',
  },
  REQUEST_DETAILS__GROUP__ID: {
    pl: '/szczegoly-wniosku/:group/:id',
    en: '/request-details/:group/:id',
  },
  ADD_REQUEST_MODAL: {
    pl: '/dodaj-wniosek',
    en: '/add-request',
  },
  REQUEST_MODIFICATION_MODAL: {
    pl: '/wniosek-o-zmiane',
    en: '/request-modification',
  },
  REQUEST_REMOVE_MODAL: {
    pl: '/wniosek-o-usuniecie',
    en: '/request-remove',
  },
  REQUESTS_USAGE_OVERVIEW_VIEW_SETTINGS_MODAL: {
    pl: '/uzycie-i-limity-ustawienia-widoku',
    en: '/usage-overview-view-settings',
  },
  // LOCATIONS
  ADD_LOCATION_MODAL: {
    pl: '/dodaj-lokalizacje',
    en: '/add-location',
  },
  EDIT_LOCATION_MODAL__ID: {
    pl: '/edytuj-lokalizacje/:id',
    en: '/edit-location/:id',
  },
  DELETE_LOCATIONS_MODAL: {
    pl: '/usun-lokalizacje',
    en: '/delete-locations',
  },
  SHOW_LOCATION_MODAL__ID: {
    pl: '/pokaz-lokalizacje/:id',
    en: '/show-location/:id',
  },
  // HOLIDAYS
  ADD_HOLIDAY_MODAL: {
    pl: '/dodaj-swieto',
    en: '/add-holiday',
  },
  EDIT_HOLIDAY_MODAL: {
    pl: '/edytuj-swieto/:id',
    en: '/edit-holiday/:id',
  },
  IMPORT_HOLIDAYS_MODAL: {
    pl: '/import-swieta/:year',
    en: '/import-holidays/:year',
  },
  DELETE_HOLIDAY_MODAL: {
    pl: '/usun-swieto/:id',
    en: '/delete-holiday/:id',
  },
  // TIME OFF TYPES
  ADD_TIME_OFF_TYPE_MODAL: {
    pl: '/dodaj-czas-wolny-rodzaje',
    en: '/add-time-off-type',
  },
  EDIT_TIME_OFF_TYPE_MODAL: {
    pl: '/edytuj-czas-wolny-rodzaje/:id',
    en: '/edit-time-off-type/:id',
  },
  DELETE_TIME_OFF_TYPE_MODAL: {
    pl: '/usun-czas-wolny-rodzaje/usun',
    en: '/delete-time-off-type/delete',
  },
  // CUSTON REQUEST TYPES
  ADD_CUSTOM_REQUEST_TYPE_MODAL: {
    pl: '/dodaj-niestandardowe-typy-zadan',
    en: '/add-custom-request-type',
  },
  EDIT_CUSTOM_REQUEST_TYPE_MODAL: {
    pl: '/edytuj-niestandardowe-typy-zadan/:id',
    en: '/edit-custom-request-type/:id',
  },
  DELETE_CUSTOM_REQUEST_TYPE_MODAL: {
    pl: '/usun-niestandardowe-typy-zadan/usun',
    en: '/delete-custom-request-type/delete',
  },
  // WEBHOOK
  WEBHOOK_MODAL: {
    pl: '/webhook',
    en: '/webhook',
  },
  ADD_WEBHOOK_MODAL: {
    pl: '/dodaj',
    en: '/add',
  },
  EDIT_WEBHOOK_MODAL: {
    pl: '/edytuj/:id',
    en: '/edit/:id',
  },
  DELETE_WEBHOOK_MODAL: {
    pl: '/usun',
    en: '/delete',
  },
  // ZUS
  ZUS_MODAL: {
    pl: '/zus',
    en: '/zus',
  },
  // GOOGLE LINK ACCOUNT
  GOOGLE_LINK: {
    pl: '/google-laczenie',
    en: '/google-link',
  },
  // CLOCK LOG
  ADD_EVENTS_MODAL: {
    pl: '/dodaj-zdarzenia',
    en: '/add-time-events',
  },
  ADD_EVENT_MODAL: {
    pl: '/dodaj-zdarzenie/:id',
    en: '/add-time-event/:id',
  },
  DELETE_EVENTS_MODAL: {
    pl: '/usun-zdarzenia',
    en: '/delete-events',
  },
  SHOW_EVENT_PHOTO_MODAL__ID: {
    pl: '/pokaz-zdjecie/:id',
    en: '/show-photo/:id',
  },
  SHOW_EVENT_HISTORY_MODAL: {
    pl: '/pokaz-historie-zmian',
    en: '/show-modification-timeline',
  },
  PHOTO_LOG_DETAILS_MODAL__USER__DATE: {
    pl: '/szczegoly-foto-rejestracji/:user/:date',
    en: '/photo-log-details/:user/:date',
  },
  EDIT_EVENT_MODAL__ID: {
    pl: '/edytuj-zdarzenie/:id',
    en: '/edit-event/:id',
  },
  ADD_EVENT_NOTE_MODAL__ID: {
    pl: '/dodaj-notatke/:id',
    en: '/add-note/:id',
  },
  MANAGE_WORK_STATUSES_MODAL: {
    pl: '/zarzadzaj-statusami-pracy',
    en: '/manage-work-statuses',
  },
  MANAGE_LOCATIONS_MODAL: {
    pl: '/zarzadzaj-lokalizacjami',
    en: '/manage-locations',
  },
  ANTI_SPOOFING_CHECK_MODAL__ID: {
    pl: '/wykrywanie-naduzyc/:id',
    en: '/anti-spoofing-check/:id',
  },
  REJECT_EVENT_MODAL: {
    pl: '/odrzuc-zdarzenie',
    en: '/reject-event',
  },
  RECOVER_REJECTED_EVENT_MODAL__ID: {
    pl: '/przywracanie-odrzuconych-zdarzen/:id',
    en: '/recover-rejected-event/:id',
  },
  RECOVER_EVENT_MODAL: {
    pl: '/przywroc-zdarzenie',
    en: '/recover-event',
  },
  DELETE_PERMANENTLY_EVENT_MODAL: {
    pl: '/usun-trwale',
    en: '/delete-permanently',
  },
  // WORK STATUSES
  EDIT_WORK_STATUSES_SETTINGS_MODAL: {
    pl: '/ustawienia-statusow-pracy',
    en: '/work-statuses-settings',
  },
  ADD_WORK_STATUS_MODAL: {
    pl: '/dodaj-status-pracy',
    en: '/add-work-status',
  },
  EDIT_WORK_STATUS_MODAL__ID: {
    pl: '/edytuj-status-pracy/:id',
    en: '/edit-work-status/:id',
  },
  DELETE_WORK_STATUSES_MODAL: {
    pl: '/usun-statusy-pracy',
    en: '/delete-work-statuses',
  },
  PREVIEW_WORK_STATUS_MODAL__ID: {
    pl: '/szczegoly-statusu-pracy/:id',
    en: '/work-status-details/:id',
  },
  // TIME CLOCKS
  DELETE_TIME_CLOCKS_MODAL: {
    pl: '/usun',
    en: '/delete',
  },
  EDIT_TIME_CLOCK_MODAL__ID: {
    pl: '/edytuj/:id',
    en: '/edit/:id',
  },
  ADD_TIME_CLOCK_NOTE_MODAL__ID: {
    pl: '/dodaj-notatke/:id',
    en: '/add-note/:id',
  },
  // REPORTS
  EVENT_WIZARD_MODAL: {
    pl: '/kreator-zdarzen',
    en: '/events-wizard',
  },
  // CALENDAR
  DELETE_SCHEDULE: {
    pl: '/usun-grafik',
    en: '/delete-schedule',
  },
  EDIT_SCHEDULE__ID__DATE__USER: {
    pl: '/edytuj-grafik/:id/:date/:user',
    en: '/edit-schedule/:id/:date/:user',
  },
  ADD_SCHEDULE__USER__DATE: {
    pl: '/dodaj-grafik/:user/:date',
    en: '/add-schedule/:user/:date',
  },
  SCHEDULE_WIZARD: {
    pl: '/grafik-kreator',
    en: '/schedule-wizard',
  },
  RECENT_SCHEDULE_PICKER: {
    pl: '/wybierz-grafik',
    en: '/recent-schedule-picker',
  },
  PUBLISH_SCHEDULE: {
    pl: '/opublikuj-grafik',
    en: '/publish-schedule',
  },
  PUBLISH_SCHEDULE__ID: {
    pl: '/opublikuj-grafik/:id',
    en: '/publish-schedule/:id',
  },
  DELETE_UNPUBLISHED_CHANGES: {
    pl: '/usun-nieopublikowane-zmiany',
    en: '/delete-unpublished-changes',
  },
  DELETE_ALL_SCHEDULES: {
    pl: '/usun-grafiki',
    en: '/delete-schedules',
  },
  COPY_SCHEDULES_TO: {
    pl: '/skopiuj-grafiki-do',
    en: '/copy-schedules-to',
  },
  SET_AVAILABILITY: {
    pl: '/ustaw-dostepnosc',
    en: '/set-availability',
  },
  RESET_AVAILABILITY: {
    pl: '/resetuj-dostepnosc',
    en: '/reset-availability',
  },
  SHARE_CALENDAR: {
    pl: '/udostepnij-kalendarz',
    en: '/share-calendar',
  },
  // DOWNLOAD/EXPORT/PRINT UNIVERSAL MODALS
  // ex. REPORTS & CALENDAR
  DOWNLOAD_MODAL__EXTENSION: {
    pl: '/pobierz/:extension',
    en: '/download/:extension',
  },
  DOWNLOAD_MODAL__EXTENSION__TYPE: {
    pl: '/pobierz/:extension/:type',
    en: '/download/:extension/:type',
  },
  EXPORT_TO_MODAL: {
    pl: '/eksport',
    en: '/export',
  },
  EXPORT_TO_MODAL__TYPE: {
    pl: '/eksport/:type',
    en: '/export/:type',
  },
  PRINT_MODAL: {
    pl: '/drukuj',
    en: '/print',
  },
  PRINT_MODAL__TYPE: {
    pl: '/drukuj/:type',
    en: '/print/:type',
  },
  PRINT_MODAL__USER__UNIX: {
    pl: '/drukuj/:user/:unix',
    en: '/print/:user/:unix',
  },
  // ADVANCE SETTINGS DELETE
  DELETE_ORGANIZATION_MODAL: {
    pl: '/potwierdz-usuniecie',
    en: '/confirm-deletion',
  },
  DELETE_AI_FACE_MODAL: {
    pl: '/usun-twarze',
    en: '/delete-faces',
  },
  DELETE_PHOTOS_MODAL: {
    pl: '/usun-zdjecia',
    en: '/delete-photos',
  },
  // YOUR QR CODE
  YOUR_QR_CODE_MODAL: {
    pl: '/kod-qr',
    en: '/qr-code',
  },
  // YOUR BENEFITS
  YOUR_BENEFITS_MODAL: {
    pl: '/benefity',
    en: '/benefits',
  },
  // GET MOBILE APPS
  GET_MOBILE_APPS_MODAL: {
    pl: '/pobierz-aplikacje-mobilne',
    en: '/get-mobile-apps',
  },
  // MOBILE CHAT
  MOBILE_CHAT: {
    pl: '/czat',
    en: '/chat',
  },
};

export const MATCH_ALL = `/:page?/:type?/:startDateUnix?/:endDateUnix?/:details?/:user?/:unix?`;

export type LanguageRoute = {
  [K in keyof typeof SupportedLanguages]: string;
};

type AllRoutes = typeof BASE_ROUTES & typeof modalRoutes;

export type Routes = {
  [K in keyof AllRoutes]: LanguageRoute;
};

const ROUTES: Routes = {
  ...BASE_ROUTES,
  ...modalRoutes,
};

// function removeLeadingSlashFromRoutes<T extends object>(routes:T): T{
//   return  _.mapValues(routes, (route) =>
//   _.mapValues(route, (translatedRoute) => translatedRoute.slice(1)),
// ) as T }

const modalRoutesWithoutLeadingSlash = _.mapValues(modalRoutes, (route) =>
  _.mapValues(route, (translatedRoute) => translatedRoute.slice(1)),
);
const parsedModalRoutes = _.mapValues(modalRoutesWithoutLeadingSlash, (route) =>
  _.mapValues(route, (translatedRoute) => `${translatedRoute}/*`),
);
const baseRoutesWithoutLeadingSlash = _.mapValues(BASE_ROUTES, (route) =>
  _.mapValues(route, (translatedRoute) => translatedRoute.slice(1)),
);

export const PATH = ROUTES;

export const TO = _.mapValues(ROUTES, (route) =>
  _.mapValues(route, (translatedRoute) => _.replace(translatedRoute, /(\/:).*/, '')),
);

export const PATH_REL: Routes = {
  ...baseRoutesWithoutLeadingSlash,
  ...parsedModalRoutes, // all parsedModalRoutes ends with /*
};

export const TO_REL = _.mapValues({ ...baseRoutesWithoutLeadingSlash, ...modalRoutesWithoutLeadingSlash }, (route) =>
  _.mapValues(route, (translatedRoute) => _.replace(translatedRoute, /(\/:).*/, '')),
);

// HELPERS
export const getRoutesRegExp = (language?: keyof typeof SupportedLanguages): RegExp =>
  new RegExp(
    `^((${_.flatMap(ROUTES, (pathLanguages) => {
      if (!language) {
        return _.flatMap(pathLanguages, (path) => path.replace(/(\/:[a-zA-Z0-9-]+).*?/g, '/[a-zA-Z0-9-]+'));
      }

      return pathLanguages[language].replace(/(\/:[a-zA-Z0-9-]+).*?/g, '/[a-zA-Z0-9-]+');
    }).join('|\\')})*)$`,
    'g',
  );

// REDIRECTS
const getRedirectRoute = (routes: LanguageRoute[]): LanguageRoute =>
  // eslint-disable-next-line @typescript-eslint/unbound-method
  _.reduce(routes, (acc, r) => _.mergeWith(acc, r, _.add), {}) as LanguageRoute;

export enum RedirectKeys {
  REQUESTS = 0,
  REPORTS__TIMESHEETS = 1,
  PASS_RESET__CONFIRMED = 2,
  SIGN_UP__EMPLOYEE = 3,
  PAYMENTS = 4,
  QUICKBOOKS = 5,
  XERO = 6,
  GOOGLE = 7,
  SIGN_IN = 8,
  SETTINGS_INTEGRATIONS = 9,
  REQUEST_DETAILS = 10,
  SQUARE = 11,
  QUICKBOOKS_DISCONNECT = 12, // FIXME: Nieużywane
  // TODO: GoogleWorkspace
  CALENDAR__SCHEDULES = 13,
  SIGN_UP__ACCOUNT_TYPE = 14,
  PREPARING_FILE = 1000, // ONLY FOR FRONT END USAGE
}

// to avoid changing the baseRoute (_pending, _grouped, _ungrouped, start, calendar etc. ) when the user is already on one of them
const getCurrentRequestDetailsBaseRoute = () => {
  const { pathname } = window.location;
  // TODO add reducer to map in TO.REQUESTS__GROUPED object
  const defaultRequestRoute = {
    pl: `${TO.REQUESTS__GROUPED.pl}/startdate/enddate`,
    en: `${TO.REQUESTS__GROUPED.en}/startdate/enddate`,
  };
  const allowedBaseRoutes = [
    TO.REQUESTS__GROUPED,
    TO.REQUESTS__UNGROUPED,

    PATH.REQUESTS__UNGROUPED__START_DATE_UNIX__END_DATE_UNIX,
    PATH.REQUESTS__GROUPED__START_DATE_UNIX__END_DATE_UNIX,

    TO.REQUESTS__PENDING,
    // Probably could be removed, redirect wont be work without all needed params e.g.
    // kalendarz/_wszystko/szegoly-wniosku(or any value) -> /404
    // kalendarz/_wszystko/some-value/some-value -> kalendarz/_wszystko/:startDateUnix/:endDateUnix
    TO.CALENDAR__ALL,
    TO.CALENDAR__SCHEDULES,
    TO.CALENDAR__REQUESTS,
    //-------------------------
    PATH.CALENDAR__ALL__START_DATE_UNIX__END_DATE_UNIX,
    PATH.CALENDAR__SCHEDULES__START_DATE_UNIX__END_DATE_UNIX,
    PATH.CALENDAR__REQUESTS__START_DATE_UNIX__END_DATE_UNIX,
  ];

  let baseRoute: (typeof allowedBaseRoutes)[number] | null = null;

  allowedBaseRoutes.forEach((route) => {
    Object.values(route).forEach((path) => {
      const match = matchPath(`${APP_LINK_BASENAME}${path}`, pathname);
      if (match) {
        baseRoute = Object.entries(route).reduce(
          (acc, [lang, routePath]) => ({
            ...acc,
            [lang]: generatePath(routePath, match.params),
          }),
          route,
        );
      }
    });
  });

  if (baseRoute) return baseRoute;

  const startPathnames = Object.values(TO.START);

  const isStart =
    pathname === process.env.PUBLIC_URL ||
    pathname === `${process.env.PUBLIC_URL}/` ||
    startPathnames.some((path) => pathname === path);

  if (isStart) {
    return _.mapValues(TO.START, () => '');
  }

  return defaultRequestRoute;
};

export const REDIRECT_ROUTES = (redirectKey: RedirectKeys, baseRoute?: Partial<LanguageRoute>): LanguageRoute => {
  switch (redirectKey) {
    case RedirectKeys.REQUESTS:
      return getRedirectRoute([TO.REQUESTS]);
    case RedirectKeys.REPORTS__TIMESHEETS:
      return getRedirectRoute([TO.REPORTS__TIMESHEETS]);
    case RedirectKeys.PASS_RESET__CONFIRMED:
      return getRedirectRoute([TO.PASS_RESET__CONFIRMED]);
    case RedirectKeys.SIGN_UP__EMPLOYEE:
      return getRedirectRoute([TO.SIGN_UP__EMPLOYEE]);
    case RedirectKeys.PAYMENTS:
      return getRedirectRoute([TO.PAYMENTS]);
    case RedirectKeys.QUICKBOOKS:
      return getRedirectRoute([TO.POPUP__QUICKBOOKS]);
    case RedirectKeys.XERO:
      return getRedirectRoute([TO.POPUP__XERO]);
    case RedirectKeys.SQUARE:
      return getRedirectRoute([TO.POPUP__SQUARE]);
    case RedirectKeys.GOOGLE:
      return getRedirectRoute([TO.POPUP__GOOGLE]);
    case RedirectKeys.SIGN_IN:
      return getRedirectRoute([TO.SIGN_IN]);
    case RedirectKeys.SETTINGS_INTEGRATIONS:
      return getRedirectRoute([TO.SETTINGS__INTEGRATIONS]);
    case RedirectKeys.REQUEST_DETAILS:
      return getRedirectRoute([
        { ...getCurrentRequestDetailsBaseRoute(), ...(baseRoute && (baseRoute as LanguageRoute)) },
        TO.REQUEST_DETAILS__GROUP__ID,
      ]);
    case RedirectKeys.CALENDAR__SCHEDULES:
      return getRedirectRoute([TO.CALENDAR__SCHEDULES]);
    case RedirectKeys.PREPARING_FILE:
      return getRedirectRoute([TO.PREPARING_FILE]);
    case RedirectKeys.SIGN_UP__ACCOUNT_TYPE:
      return getRedirectRoute([TO.SIGN_UP__ACCOUNT_TYPE]);
    default:
      return getRedirectRoute([TO.PAGE_NOT_FOUND]);
  }
};
