import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { dayDetailsVisibleAtom } from 'state/calendar';

const LazyDayDetails = React.lazy(() =>
  import('./components/DayDetails/DayDetails').then(({ DayDetails }) => ({
    default: DayDetails,
  })),
);

export const DayDetailsRenderer = React.memo((): React.ReactElement => {
  const setVisible = useSetRecoilState(dayDetailsVisibleAtom);

  useEffect(() => {
    setVisible(true);

    return () => {
      setVisible(false);
    };
  }, [setVisible]);

  return (
    <React.Suspense fallback={<></>}>
      <LazyDayDetails />
    </React.Suspense>
  );
});
