import { Action, ActionConfig, DefaultErrorMessageEnum } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';
import { CustomEvents } from 'utils/customEvents';
import { actionWithPrintPopupWindow } from '../helpers';
import { ViewSettings } from '../userSession/userSessionActions.types';

import {
  AcceptRequestManagementActionProps,
  AcceptRequestManagementResponse,
  AcceptSwapProps,
  AcceptSwapResponse,
  AddRequestActionProps,
  AddRequestResponse,
  AddSwapRequestProps,
  AddSwapRequestResponse,
  DeleteRequestActionProps,
  DeleteRequestResponse,
  FetchApprovalListsProps,
  FetchApprovalListsResponse,
  FetchRequestDetailsResponse,
  FetchRequestsUsageOverviewResponse,
  GetAllRequestsToAcceptResponse,
  GetAvailableOvertimeActionProps,
  GetAvailableOvertimeResponse,
  GetCustomRemainingLimitsActionProps,
  GetCustomRemainingLimitsResponse,
  GetPeopleToSwapProps,
  GetPeopleToSwapResponse,
  GetSchedulesToSwapProps,
  GetSchedulesToSwapResponse,
  GetTimeOffRemainingLimitsActionProps,
  GetTimeOffRemainingLimitsResponse,
  LimitDetailsActionProps,
  LimitDetailsResponse,
  RejectRequestManagementActionProps,
  RejectRequestManagementResponse,
  RejectSwapProps,
  RejectSwapResponse,
  RequestGetForPersonForRangeAndTypeActionProps,
  RequestGetForPersonForRangeAndTypeResponse,
  RequestGetTimeTrackingToUpdateActionProps,
  RequestGetTimeTrackingToUpdateResponse,
  RequestGetToUpdateActionProps,
  RequestGetToUpdateResponse,
  RequestPrintActionProps,
  RequestPrintResponse,
  RequestSingleGetToUpdateActionProps,
  RequestSingleGetToUpdateResponse,
  RequestsGetByIdsActionProps,
  RequestsGetByIdsResponse,
  RequestsListActionProps,
  RequestsListResponse,
  RequestsUsageOverviewElement,
} from './requestsActions.types';

export function requestsListAction(body: RequestsListActionProps | null): Action<RequestsListResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestsList,
    body,
  };
}

export function requestsGetByIdsAction(body: RequestsGetByIdsActionProps): Action<RequestsGetByIdsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestsGetByIds,
    body,
  };
}

export function acceptRequestManagementAction(
  ids: AcceptRequestManagementActionProps,
): Action<AcceptRequestManagementResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.acceptRequestManagement}/${ids[0]}`,
    config: {
      onResponseCustomEvents: [
        { event: CustomEvents.ACCEPT_REQUEST_MANAGEMENT_SUCCESS_RESPONSE_RECEIVED, restrictToStatuses: [200, 204] },
      ],
    },
  };
}

export function rejectRequestAction({
  id,
  ...body
}: RejectRequestManagementActionProps): Action<RejectRequestManagementResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.rejectRequestManagement}/${id}`,
    body,
    config: {
      onResponseCustomEvents: [
        { event: CustomEvents.REJECT_REQUEST_SUCCESS_RESPONSE_RECEIVED, restrictToStatuses: [200, 204] },
      ],
    },
  };
}

export function deleteRequestsAction(ids: DeleteRequestActionProps): Action<DeleteRequestResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.deleteRequest}/${ids[0]}`,
    config: {
      onResponseCustomEvents: [
        { event: CustomEvents.DELETE_REQUESTS_SUCCESS_RESPONSE_RECEIVED, restrictToStatuses: [200, 204] },
      ],
    },
  };
}

export function addRequestAction(body: AddRequestActionProps, config?: ActionConfig): Action<AddRequestResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.addRequest,
    body,
    config: {
      onResponseCustomEvents: [
        {
          event: CustomEvents.ADD_REQUEST_SUCCESS_RESPONSE_RECEIVED,
          data: body.peopleIds,
          restrictToStatuses: [200, 204],
        },
      ],
      skipResend: true,
      customDefaultErrorMessage: DefaultErrorMessageEnum.None,
      ...(config && config),
    },
  };
}

export function fetchRequestForTimeRangeAction(year: string): Action<FetchRequestsUsageOverviewResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.requestOverview}/${year}`,
  };
}

export function fetchRequestDetailsAction(id: string, grouped: boolean): Action<FetchRequestDetailsResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.request}/${id}/${grouped}`,
  };
}

export function requestTimeOffRemainingLimitsAction(
  body: GetTimeOffRemainingLimitsActionProps,
): Action<GetTimeOffRemainingLimitsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeOffRemainingLimits,
    body,
  };
}

export function requestCustomRemainingLimitsAction(
  body: GetCustomRemainingLimitsActionProps,
): Action<GetCustomRemainingLimitsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.customRemainingLimits,
    body,
  };
}

export function requestAvailableOvertime(body: GetAvailableOvertimeActionProps): Action<GetAvailableOvertimeResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.availableOvertime,
    body,
  };
}

export function fetchApprovalListsAction({
  personId,
  requestType,
}: FetchApprovalListsProps): Action<FetchApprovalListsResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.getApprovalLists}/${personId}/${requestType}`,
  };
}

export function requestGetToUpdateAction(body: RequestGetToUpdateActionProps): Action<RequestGetToUpdateResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestGetToUpdate,
    body,
  };
}

export function requestSingleGetToUpdateAction({
  id,
  skipInnerCode,
}: RequestSingleGetToUpdateActionProps): Action<RequestSingleGetToUpdateResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.requestGetToUpdate}/${id}`,
    config: {
      skipInnerCode,
    },
  };
}

function requestPrintActionCreator(body: RequestPrintActionProps): Action<RequestPrintResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.printRequests,
    body,
  };
}
export const requestPrintAction = actionWithPrintPopupWindow(requestPrintActionCreator);

export function requestDownloadAction(body: RequestPrintActionProps): Action<RequestPrintResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.printRequests,
    body,
    headers: {
      'X-Download': 'true',
    },
  };
}

export function requestGetTimeTrackingToUpdateAction({
  id,
  skipInnerCode,
}: RequestGetTimeTrackingToUpdateActionProps): Action<RequestGetTimeTrackingToUpdateResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.getTimeTrackingToUpdate}/${id}`,
    config: {
      skipInnerCode,
    },
  };
}

export function getCurrentUserRequestLimitsAction(): Action<RequestsUsageOverviewElement> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.getCurrentUserRequestLimits,
  };
}

export function getAllRequestToAcceptAction(): Action<GetAllRequestsToAcceptResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.getAllToAccept,
  };
}

export function setRequestsViewSettings(viewSettings: ViewSettings): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.viewsSettings,
    body: {
      requests: viewSettings,
    },
  };
}

export function setRequestsUsageOverviewViewSettings(viewSettings: ViewSettings): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.viewsSettings,
    body: {
      requestsUsageOverview: viewSettings,
    },
  };
}

export function getLimitDetailsAction(body: LimitDetailsActionProps): Action<LimitDetailsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.limitDetails,
    body,
  };
}

export function getSchedulesToSwapAction(body: GetSchedulesToSwapProps): Action<GetSchedulesToSwapResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.getSchedulesToSwap,
    body,
  };
}

export function acceptSwapAction({ id }: AcceptSwapProps): Action<AcceptSwapResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.acceptSwap}/${id}`,
  };
}

export function rejectSwapAction({ id, ...body }: RejectSwapProps): Action<RejectSwapResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.rejectSwap}/${id}`,
    body,
  };
}

export function addSwapRequestAction(body: AddSwapRequestProps, config?: ActionConfig): Action<AddSwapRequestResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.addSwapRequest,
    body,
    config: {
      skipResend: true,
      customDefaultErrorMessage: DefaultErrorMessageEnum.None,
      ...(config && config),
    },
  };
}

export function getPeopleToSwapAction({ personId }: GetPeopleToSwapProps): Action<GetPeopleToSwapResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.getPeopleToSwap}/${personId}`,
  };
}

export function requestGetForPersonForRangeAndType(
  body: RequestGetForPersonForRangeAndTypeActionProps,
): Action<RequestGetForPersonForRangeAndTypeResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestsGetForPersonForRangeAndType,
    body,
  };
}
