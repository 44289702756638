export enum QueryParams {
  Id = 'id',
  Redirect = 'redirect',
  Token = 'token',
  UserId = 'userId',
  Alert = 'alert',
  Code = 'code', // FIXME: is it used? What is this?
  Error = 'error',
  RealmId = 'realmId',
}
