import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import uniqueId from 'lodash/uniqueId';
import { FC, useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';

import { addEmployeeAction } from 'api/actions/employees/employeesActions';
import { DefaultRole } from 'api/actions/organizationSession/organizationSessionActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Modal } from 'components/Modal/output/Modal';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { ButtonProps } from 'components/ui/Buttons/Button';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { usePromisify } from 'hooks/usePromisify/usePromisify';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { MinimizeModalAddTeammate } from 'state/modal';
import { languageSelector } from 'state/recoilState';
import { ProfileFormState } from 'state/team';
import { createEvent } from 'utils/createEvent';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { AvatarInfo, UserProfile } from '../../forms/UserProfile/UserProfile';

import { ModalRoutes } from './ModalRoutes';
import { useMinimizeModalAddTeammate } from './hooks/useMinimizeModalAddTeammate';

type OnHandleCloseOptions = {
  preventOpeningModalDialog: boolean;
  successfullyAddedTeammate: boolean;
};

export const AddTeammateModal: FC = () => {
  const language = useRecoilValue(languageSelector);
  const [loading, setLoading] = useState(false);
  const [addNextTeammate, setAddNextTeammate] = useState(false);
  const [userProfileKey, setUserProfileKey] = useState('userProfileKey');
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddTeammate>();
  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar();

  useLingui();
  const { canAddMoreThanOneUser } = useAppPermissions();
  const navigate = useAppNavigate();

  const { onHandleMinimize, onHandleCloseModalWithMinimizedDetails } = useMinimizeModalAddTeammate();

  const { isVisible: isOnboardingVisible, playNextStep, showWarningStep } = useInitialOnboarding();
  const onHandleCloseDuringOnboarding = useCallback(
    (closeModal: () => void, successfullyAddedTeammate?: boolean) => {
      closeModal();

      if (successfullyAddedTeammate) {
        playNextStep();
        return;
      }

      showWarningStep();
    },
    [playNextStep, showWarningStep],
  );
  const onHandleClose = useCallback(
    (closeModal: () => void, options?: OnHandleCloseOptions) => {
      const { preventOpeningModalDialog, successfullyAddedTeammate } = options || {};

      if (isOnboardingVisible) {
        onHandleCloseDuringOnboarding(closeModal, successfullyAddedTeammate);
        return;
      }

      void onHandleCloseModalWithMinimizedDetails(closeModal, preventOpeningModalDialog);
    },
    [isOnboardingVisible, onHandleCloseDuringOnboarding, onHandleCloseModalWithMinimizedDetails],
  );

  const { handleClose } = useModal({
    onHandleClose,
    onHandleMinimize,
    closeOnBackdrop: false,
  });

  const profileFormRef = useRef<HTMLFormElement>(null);

  const [promisifySubmitForm, resolveSubmitForm] = usePromisify<{
    body: ProfileFormState;
    avatarInfo?: AvatarInfo;
  } | null>();

  const { mutate } = useMutation(addEmployeeAction);

  const submitForm = async () => {
    const form = profileFormRef.current;
    if (!form) return null;
    const event = createEvent('submit');
    form.dispatchEvent(event);

    return promisifySubmitForm();
  };

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (body: ProfileFormState, avatarInfo?: AvatarInfo): Promise<boolean> => {
      resolveSubmitForm({
        body,
        avatarInfo,
      });
      return true;
    },
    [resolveSubmitForm],
  );

  const handleSaveFactory = (addNext?: boolean) => async () => {
    setLoading(true);

    const payload = await submitForm();
    if (!payload) {
      setLoading(false);
      return;
    }
    const { body, avatarInfo } = payload;
    const { phoneNumber, email, firstName, surname, ...rest } = body;

    const { error: submitError, payload: addedUserId } = await mutate({
      employeeInfo: {
        phoneNumber,
        email,
        firstName,
        surname,
      },
      ...rest,
      avatar: avatarInfo?.avatarBlob || undefined,
    });

    setLoading(false);

    if (submitError) return;
    if (addedUserId && calendarInitialized) void updateCalendarForIds([addedUserId]);

    addSnackbar({
      message: t({ id: 'team.add_teammate.added', message: 'Employee successfully added!' }),
      variant: 'success',
    });

    if (!addNext) {
      handleClose({
        preventOpeningModalDialog: true,
        successfullyAddedTeammate: true,
      });
      return;
    }

    setAddNextTeammate(true);
    setUserProfileKey(uniqueId());
    // remove minimized modal route state
    navigate(`../${TO_REL.ADD_TEAMMATE_MODAL[language]}`, { relative: 'path', replace: true });
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.add_teammate">Add teammate</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserProfile
          key={userProfileKey}
          ref={profileFormRef}
          defaultValues={
            (!addNextTeammate && minimizedModalDetails?.modalDetails?.addTeammate) || {
              roleId: `${DefaultRole.Employee}`,
            }
          }
          onSubmit={onSubmit}
          onSubmitError={() => resolveSubmitForm(null)}
          avatarUrl={minimizedModalDetails?.modalDetails?.avatarUrl}
          avatarHasChanged={minimizedModalDetails?.modalDetails?.avatarChanged}
        />
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          ...(canAddMoreThanOneUser && !isOnboardingVisible
            ? [
                {
                  disabled: loading,
                  onClick: handleSaveFactory(true),
                  variant: 'minimal' as ButtonProps['variant'],
                  children: t({ id: 'team.add_teammate.save_add', message: 'Save & add next' }),
                },
              ]
            : []),
          {
            isLoading: loading,
            onClick: floatingPromiseReturn(handleSaveFactory()),
            variant: 'primary',
            children: t({ id: 'save', message: 'Save' }),
            className: 'onboarding-teammates-4',
          },
        ]}
      />

      <ModalRoutes />
    </>
  );
};
