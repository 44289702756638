import { t } from '@lingui/macro';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import {
  ActionOnRequest,
  ActionPermission,
  RequestFormType,
  RequestState,
} from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { Button } from 'components/ui/Buttons';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useIsEmployeeNonEditable } from 'hooks/useIsEmployeeNonEditable/useIsEmployeeNonEditable';
import { languageSelector } from 'state/recoilState';
import { userSessionPersonIdSelector } from 'state/userSession';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useRequestsLogic } from '../../../../hooks/useRequestsLogic';
import { RequestStatusIcon, RequestStatusIconProps } from '../../../RequestStatusIcon';
import { RequestDetailsGroupedParam } from '../../../types';
import { useRequestDetails } from '../hooks/useRequestDetails';

export const RequestDetailsHeader = (): React.ReactElement => {
  const { id } = useParams() as { id: string };
  const language = useRecoilValue(languageSelector);
  const currentUserId = useRecoilValue(userSessionPersonIdSelector);
  const navigate = useAppNavigate();
  const {
    parsedRequestDetails: requestDetails,
    requestDetailsState,
    urlParams: { group },
  } = useRequestDetails();
  const {
    actions: { printRequest, downloadRequest },
  } = useRequestsLogic();
  const isEmployeeNonEditable = useIsEmployeeNonEditable('Requests', requestDetails?.employee.id);

  const [printRequestPending, setPrintRequestPending] = useState(false);
  const [downloadRequestPending, setDownloadRequestPending] = useState(false);

  const handlePrintOnClick = useCallback(async () => {
    setPrintRequestPending(true);
    await printRequest([id]);
    setPrintRequestPending(false);
  }, [id, printRequest]);

  const handleDownloadOnClick = useCallback(async () => {
    setDownloadRequestPending(true);
    await downloadRequest([id]);
    setDownloadRequestPending(false);
  }, [downloadRequest, id]);

  const handleDeleteOnClick = useCallback(() => {
    if (!requestDetails) return;

    navigate(TO_REL.DELETE_REQUESTS_MODAL[language], {
      state: {
        deleteGroupRequest: {
          latestInGroup: requestDetails.latestInGroup,
          state: requestDetails.state,
        },
        ids: [id],
        requestNumber: requestDetails?.number,
        requestDetailsData: requestDetails,
      },
    });
  }, [id, language, navigate, requestDetails]);

  const isDeleteAllowed = useMemo(() => {
    if (!requestDetails) return false;

    const { employee, state, isDeleted, actions } = requestDetails;

    if (isDeleted) return false;

    const isCurrentUser = employee.id === currentUserId;

    if (state === RequestState.Pending && isCurrentUser) {
      return true;
    }

    if (isEmployeeNonEditable && !isCurrentUser) return false;

    return actions[ActionOnRequest.Delete] === ActionPermission.Allowed;
  }, [requestDetails, isEmployeeNonEditable, currentUserId]);

  const headerRenderer = useMemo(() => {
    const iconStatusProps: RequestStatusIconProps = (() => {
      const state = requestDetailsState?.state || requestDetails?.state || RequestState.Pending;
      const isDeleted = requestDetailsState?.isDeleted || requestDetails?.isDeleted || false;
      const isZus = !!requestDetails?.newEvent.eventDetails.externalId;
      const iconProps = {
        size: 24,
      };
      const statusProps = {
        state,
        isDeleted,
      };
      const commonStyles = {
        p: 1,
        borderRadius: 'xs',
      };

      if (isDeleted) {
        return {
          iconProps: {
            wrapperSx: { bg: 'requests.detailsHeader.bg.deleted', ...commonStyles },
            ...iconProps,
          },
          ...statusProps,
        };
      }

      if (isZus) {
        return {
          iconProps: {
            wrapperSx: { bg: 'requests.detailsHeader.bg.zus', ...commonStyles },
            ...iconProps,
          },
          ...statusProps,
          isZus,
        };
      }

      switch (state) {
        case RequestState.Accepted:
          return {
            iconProps: { wrapperSx: { bg: 'requests.detailsHeader.bg.accepted', ...commonStyles }, ...iconProps },
            ...statusProps,
          };
        case RequestState.Rejected:
          return {
            iconProps: { wrapperSx: { bg: 'requests.detailsHeader.bg.rejected', ...commonStyles }, ...iconProps },
            ...statusProps,
          };
        case RequestState.Pending:
        default:
          return {
            iconProps: { wrapperSx: { bg: 'requests.detailsHeader.bg.pending', ...commonStyles }, ...iconProps },
            ...statusProps,
          };
      }
    })();

    const headerTextRenderer = (() => {
      if (group === RequestDetailsGroupedParam.Grouped && requestDetails?.type !== RequestFormType.Schedule) {
        return t({ id: 'request_details.group_no.short', message: 'Req. group no.' });
      }

      return t({ id: 'requests.request', message: 'Request no.' });
    })();

    const requestNumberRenderer = (() => {
      if (group === RequestDetailsGroupedParam.Grouped && requestDetails?.type !== RequestFormType.Schedule) {
        return requestDetails?.groupNumber;
      }

      return requestDetails?.number || requestDetailsState?.requestNumber || '-';
    })();

    return (
      <Flex sx={{ alignItems: 'center', gap: 2 }}>
        <RequestStatusIcon {...iconStatusProps} />
        <Text>
          {headerTextRenderer} {requestNumberRenderer}
        </Text>
      </Flex>
    );
  }, [
    group,
    requestDetails?.groupNumber,
    requestDetails?.isDeleted,
    requestDetails?.newEvent.eventDetails.externalId,
    requestDetails?.number,
    requestDetails?.state,
    requestDetails?.type,
    requestDetailsState?.isDeleted,
    requestDetailsState?.requestNumber,
    requestDetailsState?.state,
  ]);

  return (
    <Modal.Header>
      <Modal.Title sx={{ flexShrink: 3 }}>{headerRenderer}</Modal.Title>

      <Flex sx={{ alignItems: 'center', flexShrink: 0 }}>
        <Button
          variant="minimal"
          shape="circle"
          onClick={floatingPromiseReturn(handleDownloadOnClick)}
          isLoading={downloadRequestPending}
        >
          <Icon size={24} type="download" />
        </Button>
        <Button
          variant="minimal"
          shape="circle"
          onClick={floatingPromiseReturn(handlePrintOnClick)}
          isLoading={printRequestPending}
        >
          <Icon size={24} type="print" />
        </Button>
        <Button variant="minimal" shape="circle" onClick={handleDeleteOnClick} disabled={!isDeleteAllowed}>
          <Icon size={24} type="delete" />
        </Button>
      </Flex>
    </Modal.Header>
  );
};
