import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { Divider } from 'components/Divider/Divider';
import { RolesSelect } from 'components/ui/Select/variants/RolesSelect';
import { Switch } from 'components/ui/Switch';
import { rolesApproveRequestsSelectOptionsSelector } from 'state/settings';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { useFormContextCustomEvent } from '../../../../../../../hooks/useFormContextCustomEvent';
import { LabelWithBadge } from '../../../../../../LabelWithBadge';
import { OptionLabel } from '../../../../../../OptionLabel';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';

export const TimeTracking = (): React.ReactElement => {
  useLingui();
  const rolesSelectOptions = useRecoilValue(rolesApproveRequestsSelectOptionsSelector);

  const {
    registerOnChange,
    registerOnBlur,
    watch,
    formState: { errors },
  } = useFormContextCustomEvent<{
    allowChangeRequestForLoggedClockInsAndOuts: boolean;
    timeTrackingApprovalSettings: {
      twoStepApprovalFlow: boolean;
      firstStepRoleId: string;
      secondStepRoleId: string;
    };
  }>();

  const twoStepApprovalWatch = watch('timeTrackingApprovalSettings.twoStepApprovalFlow');
  const firstStepRoleIdWatch = watch('timeTrackingApprovalSettings.firstStepRoleId');
  const secondStepRoleIdWatch = watch('timeTrackingApprovalSettings.secondStepRoleId');

  return (
    <SettingsGroup variant="sm">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="requests_settings.heading.time_tracking">Time tracking requests</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <SettingsGroup.Body>
        <SettingsGroup.Body.Visible
          variant="md"
          pb={twoStepApprovalWatch ? 2 : '0.75rem'}
          withoutBorderBottom={twoStepApprovalWatch}
        >
          <Switch
            {...registerOnChange('allowChangeRequestForLoggedClockInsAndOuts')}
            label={
              <LabelWithBadge
                label={t({ id: 'requests_settings.allow_change_request_for_logged.label' })}
                badgeText={t({ id: 'settings.time_tracking' })}
                tooltipText={t({ id: 'settings.tooltip_time_tracking', message: 'Requires Time Tracking module' })}
              />
            }
            size="sm"
            bold
            sx={{ py: SETTINGS_SPACE.switchWithLabel }}
          />
          <Divider />
          <Switch
            {...registerOnChange('timeTrackingApprovalSettings.twoStepApprovalFlow')}
            label={t({ id: 'requests_settings.two_step_approval_flow.label' })}
            additionalInfo={
              <Trans id="requests_settings.two_step_approval_flow.additionalInfo">
                <Text as="span" sx={{ textDecoration: 'underline' }}>
                  Changing this setting will affect all pending requests
                </Text>
                .
              </Trans>
            }
            size="sm"
            bold
          />
        </SettingsGroup.Body.Visible>

        {twoStepApprovalWatch && (
          <SettingsGroup.Body.Hidden variant="md" pt={2} pb={'0.75rem'} withBorderBottom>
            <OptionLabel
              label={t({
                id: 'requests_settings.first_step_role_id.label',
              })}
              apendWith={
                <>
                  {rolesSelectOptions && (
                    <RolesSelect
                      {...registerOnBlur('timeTrackingApprovalSettings.firstStepRoleId', {
                        required: t({ id: 'global.forms.required.short' }),
                      })}
                      id="timeTrackingApprovalSettings.firstStepRoleId"
                      placeholder={t({
                        id: 'requests_settings.first_step_role_id.label',
                      })}
                      options={_.filter(rolesSelectOptions, (item) => item.id !== secondStepRoleIdWatch)}
                      size="sm"
                      error={!!errors.timeTrackingApprovalSettings?.firstStepRoleId}
                      errorMessage={errors.timeTrackingApprovalSettings?.firstStepRoleId?.message}
                      sx={{ maxWidth: '188px' }}
                    />
                  )}
                </>
              }
              withDivider
            />
            <OptionLabel
              label={t({
                id: 'requests_settings.second_step_role_id.label',
              })}
              apendWith={
                <>
                  {rolesSelectOptions && (
                    <RolesSelect
                      {...registerOnBlur('timeTrackingApprovalSettings.secondStepRoleId', {
                        required: t({ id: 'global.forms.required.short' }),
                      })}
                      id="timeTrackingApprovalSettings.secondStepRoleId"
                      placeholder={t({
                        id: 'requests_settings.second_step_role_id.label',
                      })}
                      options={_.filter(rolesSelectOptions, (item) => item.id !== firstStepRoleIdWatch)}
                      size="sm"
                      error={!!errors.timeTrackingApprovalSettings?.secondStepRoleId}
                      errorMessage={errors.timeTrackingApprovalSettings?.secondStepRoleId?.message}
                      sx={{ maxWidth: '188px' }}
                    />
                  )}
                </>
              }
            />
          </SettingsGroup.Body.Hidden>
        )}
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
