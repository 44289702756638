import _ from 'lodash';
import { useCallback, useMemo } from 'react';

import { SUBMIT_THROTTLE } from 'constants/settings';
import { createEvent } from 'utils/createEvent';

export const useFormSubmit = (
  formRef: React.MutableRefObject<HTMLFormElement | null>,
  dispatchCallback?: () => void,
) => {
  const dispatchSubmitEvent = useCallback(() => {
    if (dispatchCallback) dispatchCallback();
    const submitEvent = createEvent('submit');
    formRef.current?.dispatchEvent(submitEvent);
  }, [dispatchCallback, formRef]);

  const throttledDispatchSubmitEvent = useMemo(
    () =>
      _.throttle(
        () => {
          dispatchSubmitEvent();
        },
        SUBMIT_THROTTLE,
        { leading: false },
      ),
    [dispatchSubmitEvent],
  );

  const debouncedDispatchSubmitEvent = useMemo(
    () =>
      _.debounce(
        () => {
          dispatchSubmitEvent();
        },
        SUBMIT_THROTTLE,
        { leading: false },
      ),
    [dispatchSubmitEvent],
  );

  return {
    dispatchSubmitEvent,
    throttledDispatchSubmitEvent,
    debouncedDispatchSubmitEvent,
  };
};
