import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { PersonName } from 'api/actions/organizationSession/organizationSessionActions.types';
import { CropAvatarModal } from 'components/ManageAvatar/CropAvatarModal';
import { Modal } from 'components/Modal/output/Modal';
import { PATH_REL } from 'constants/routes';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { languageSelector } from 'state/recoilState';

import { Picker } from './Picker';

type Props = {
  url?: string;
  onChange: (url?: string) => void | Promise<boolean>;
  variant?: 'default' | 'lg';
  name?: PersonName;
  isSrcControlled?: boolean /* If tue, setAvatarUrl in addAvatar and onDelete will be omitted, onChange 
  will be called instead and the avatar will be only showed after we receive new url in the ‘url’ prop */;
};

const defaultProps: Partial<Props> = {
  url: undefined,
  variant: 'default',
  name: undefined,
  isSrcControlled: false,
};

export const ManageAvatar = ({ url, onChange, variant, name, isSrcControlled }: Props): React.ReactElement => {
  useLingui();
  const [avatarUrl, setAvatarUrl] = useState(url);
  const language = useRecoilValue(languageSelector);

  const onChangeRef = useCallbackRef(onChange);
  const avatarUrlRef = useCallbackRef(avatarUrl);
  const urlRef = useCallbackRef(url);

  useEffect(() => {
    if (!_.isEqual(avatarUrlRef.current, url)) {
      setAvatarUrl(url);
    }
  }, [avatarUrlRef, url]);

  useEffect(() => {
    if (!_.isEqual(avatarUrl, urlRef.current)) {
      void onChangeRef.current(avatarUrl);
    }
  }, [avatarUrl, onChangeRef, urlRef]);

  const addAvatar = async (croppedAvatarUrl: string) => {
    if (isSrcControlled) {
      await onChangeRef.current(croppedAvatarUrl);
      return true;
    }
    setAvatarUrl(croppedAvatarUrl);
    return true;
  };

  const onDelete = async () => {
    if (isSrcControlled) {
      await onChangeRef.current();
      return true;
    }
    setAvatarUrl(undefined);
    return true;
  };

  return (
    <>
      <Picker variant={variant} onDelete={onDelete} avatarUrl={avatarUrl} name={name} />
      <Routes>
        <Route
          path={PATH_REL.ADD_AVATAR_MODAL[language]}
          element={
            <Modal size="sm">
              <CropAvatarModal onDone={addAvatar} />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};

ManageAvatar.defaultProps = defaultProps;
