import { Trans } from '@lingui/macro';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { userSessionAtom } from 'state/userSession';
import { SettingsGroup } from '../../../SettingsGroup/SettingsGroup';
import { wrapperLinkSx } from '../../../styles/wrappers';

import { LinkButtonWithLoading, LinkButtonWithLoadingProps } from './components/LinkButtonWithLoading';

type Props = {
  formId: string;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  children: ReactElement | ReactElement[];
  disabled?: boolean;
  isLoading?: boolean;
  label: string;
  additionalLabel: string;
  linkButtonWithLoadingProps?: LinkButtonWithLoadingProps;
};

export const AdvancedDeleteMain = ({
  formId,
  onSubmit,
  children,
  label,
  additionalLabel,
  linkButtonWithLoadingProps,
}: Props): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };
  const userSession = useRecoilValue(userSessionAtom);

  const hasPassword = !!userSession?.hasPassword;

  return (
    <form id={formId} onSubmit={onSubmit} autoComplete="off">
      <SettingsGroup variant="sm" onKeyDown={handleKeyDown}>
        <SettingsGroup.Header>
          <SettingsGroup.Title
            additionalInfo={
              <Text sx={{ fontSize: '0.875rem', lineHeight: '1.3125rem' }}>
                {hasPassword ? (
                  additionalLabel
                ) : (
                  <Trans id="settings.user.set_password_to_use_setting">
                    <Text as="span" sx={{ ...wrapperLinkSx }}>
                      <Link to={TO.SETTINGS__USER__MANAGE_PASSWORD[language]}>Set password</Link>
                    </Text>{' '}
                    to use this setting.
                  </Trans>
                )}
              </Text>
            }
          >
            {label}
          </SettingsGroup.Title>
        </SettingsGroup.Header>

        {hasPassword && (
          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="lg">
              {children}
              {linkButtonWithLoadingProps && (
                <LinkButtonWithLoading
                  variant="danger"
                  shape="rounded"
                  sx={{ alignSelf: 'flex-start' }}
                  {...linkButtonWithLoadingProps}
                />
              )}
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        )}
      </SettingsGroup>
    </form>
  );
};
