import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { WAIT_FOR_FORM_LOGIC, useMinimizedModals } from 'components/Modal/output/useMinimizedModals';
import { useModal } from 'components/Modal/output/useModal';
import { ListNames } from 'components/StickyList/types';
import { TO_REL } from 'constants/routes';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { usePickTeammates } from 'hooks/usePickTeammates/usePickTeammates';
import { TeammatesFilters } from 'layouts/AuthorizedApp/AsideFilters/types';
import { getScheduleFormValuesAtom, getScheduleWizardOptionsFormValuesAtom } from 'state/calendar';
import { parsedEmployeesSelector } from 'state/employees';
import { MinimizeModalScheduleWizard, MinimizedModal, ViewWithMinimizedModals } from 'state/modal';
import { isActiveTeammatesFilterSelectorFamily, pickTeammatesSearchFilterValueSelector } from 'state/pickTeammates';
import { delay } from 'utils/delay';

import { useScheduleWizard } from './useScheduleWizard';

export const useMinimizeModalScheduleWizard = () => {
  useLingui();
  const [selectedTeammatesIds, setSelectedTeammatesIds] = usePickTeammates(ListNames.REQUESTS_PICK_TEAMMATES, true);
  const getScheduleFormValues = useRecoilValue(getScheduleFormValuesAtom);
  const getScheduleWizardOptionsFormValues = useRecoilValue(getScheduleWizardOptionsFormValuesAtom);
  const [isActiveTeammatesHidden, setIsActiveTeammatesHidden] = useRecoilState(
    isActiveTeammatesFilterSelectorFamily(TeammatesFilters.HIDDEN),
  );
  const [isActiveTeammatesDeactivated, setIsActiveTeammatesDeactivated] = useRecoilState(
    isActiveTeammatesFilterSelectorFamily(TeammatesFilters.DEACTIVATED),
  );
  const [searchFilterValue, setSearchFilterValue] = useRecoilState(pickTeammatesSearchFilterValueSelector);
  const { isReportsAttendanceList, isReportsTimesheets } = useAppRouting([
    'isReportsAttendanceList',
    'isReportsTimesheets',
  ]);
  const parsedEmployees = useRecoilValue(parsedEmployeesSelector);
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalScheduleWizard>();
  const { schedule, setSchedule, step, setStep } = useScheduleWizard();
  const minimizedView = useMemo(() => {
    if (isReportsTimesheets) {
      return ViewWithMinimizedModals.TIMESHEETS;
    }

    if (isReportsAttendanceList) {
      return ViewWithMinimizedModals.ATTENDANCE;
    }

    return ViewWithMinimizedModals.CALENDAR;
  }, [isReportsAttendanceList, isReportsTimesheets]);
  const { handleSetModalDetails, onHandleCloseModalWithMinimizedDetails } =
    useMinimizedModals<MinimizeModalScheduleWizard>(minimizedView);

  const onHandleMinimize = useCallback(
    async (minimizeModal: () => void) => {
      // @see useMinimizedModals.ts
      await delay(WAIT_FOR_FORM_LOGIC);
      const scheduleWizardOptions = getScheduleWizardOptionsFormValues
        ? _.cloneDeep(getScheduleWizardOptionsFormValues())
        : undefined;

      handleSetModalDetails({
        minimizedModal: MinimizedModal.SCHEDULE_WIZARD,
        route: TO_REL.SCHEDULE_WIZARD,
        title: t({ id: 'schedule.wizard' }),
        modalDetails: {
          employees: selectedTeammatesIds,
          schedule: getScheduleFormValues ? getScheduleFormValues() : schedule,
          step,
          scheduleWizardOptions,
          hiddenTeammatesFilter: isActiveTeammatesHidden,
          deactivatedTeammatesFilter: isActiveTeammatesDeactivated,
          searchFilterValue,
        },
        employeesToValidate: selectedTeammatesIds,
      });

      minimizeModal();
    },
    [
      handleSetModalDetails,
      selectedTeammatesIds,
      getScheduleFormValues,
      schedule,
      step,
      getScheduleWizardOptionsFormValues,
      isActiveTeammatesHidden,
      isActiveTeammatesDeactivated,
      searchFilterValue,
    ],
  );

  useModal({ onHandleMinimize, onHandleClose: onHandleCloseModalWithMinimizedDetails });

  const restoreMinimizedState = useCallbackRef(() => {
    if (!minimizedModalDetails || !parsedEmployees) return;

    const {
      employees,
      schedule: storedSchedule,
      step: storedStep,
      hiddenTeammatesFilter,
      deactivatedTeammatesFilter,
      searchFilterValue: search,
    } = minimizedModalDetails.modalDetails;

    const filteredEmployees = employees.filter((id) => parsedEmployees.has(id));

    setSelectedTeammatesIds(filteredEmployees);
    setSchedule(storedSchedule);
    setStep(storedStep);
    setIsActiveTeammatesHidden(hiddenTeammatesFilter);
    setIsActiveTeammatesDeactivated(deactivatedTeammatesFilter);
    setSearchFilterValue(search);
  });

  useEffect(() => {
    restoreMinimizedState.current();
  }, [restoreMinimizedState]);
};
