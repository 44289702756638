/* eslint-disable no-restricted-syntax */

import dayjs from 'dayjs';
import _ from 'lodash';
import { MutableSnapshot, RecoilState } from 'recoil';

import { AccountInfoAbout, AccountInfoCompany } from 'api/actions/user/userActions.types';
import { FetchUserSessionResponse } from 'api/actions/userSession/userSessionActions.types';
import { NotificationOwnProps } from 'components/Notification/types';
import { ListNames } from 'components/StickyList/types';
import { SupportedLanguages } from 'constants/supportedLanguages';
import { settingsAtom as kioskSettingsAtom } from 'Kiosk/state/settingsState';
import { LOCAL_STORAGE_VALUE_CARRIER_KEY } from 'observers/PersistanceObserver';

import { chatWindowIdsAtom } from './chat';
import {
  DATE_RANGE_ATOM_INITIALIZATION_THRESHOLD,
  DateRangeAtomLocalStorageMetadata,
  getAtomsLocalStorageMetadataKey,
} from './common';
import { DateRangeFilterAtom, dateRangeFilterAtom, dateRangeRequestsUsageOverviewFilterAtom } from './filters';
import { sortingStateAtomFamily } from './list';
import { notificationCenterAtom } from './notification';
import { isLoggedInAtom, languageSelector } from './recoilState';
import { signUpFormAtom } from './signUp';
import { userSessionAtom } from './userSession';

type LookUpAtomWithKey =
  | RecoilState<FetchUserSessionResponse | null>
  | RecoilState<boolean>
  | RecoilState<keyof typeof SupportedLanguages>
  | RecoilState<NotificationOwnProps[]>
  | RecoilState<Partial<AccountInfoAbout & AccountInfoCompany> | null>
  | RecoilState<string[]>
  | typeof kioskSettingsAtom
  | typeof dateRangeFilterAtom
  | undefined;

function lookupAtomWithKey(keyProp: RecoilState<unknown>['key']): LookUpAtomWithKey {
  const atomsList = [
    userSessionAtom,
    isLoggedInAtom,
    signUpFormAtom,
    // TODO: do initializing a selector with value have any use case?
    languageSelector,
    notificationCenterAtom,
    chatWindowIdsAtom,
    dateRangeFilterAtom,
    kioskSettingsAtom,
    dateRangeRequestsUsageOverviewFilterAtom,
  ];

  for (const atom of atomsList) {
    if (atom.key === keyProp) {
      return atom;
    }
  }

  return undefined;
}

enum StateInitializerValueValidatorError {
  OmitInitialization = 'OMIT_INITIALIZATION',
  // NotValid = 'NOT_VALID'
}

type LocalStorageValueValidator<T> = (value: T) => T | StateInitializerValueValidatorError;

const dateRangeFilterAtomValidator: (
  dateRangeAtom?: RecoilState<DateRangeFilterAtom | null>,
) => LocalStorageValueValidator<DateRangeFilterAtom | null> =
  (dateRangeAtom: RecoilState<DateRangeFilterAtom | null> = dateRangeFilterAtom) =>
  (value) => {
    const now = dayjs().unix();
    const atomsMetadataString = localStorage.getItem(getAtomsLocalStorageMetadataKey(dateRangeAtom.key));

    if (!atomsMetadataString) return StateInitializerValueValidatorError.OmitInitialization;

    const atomsMetadata = JSON.parse(atomsMetadataString) as DateRangeAtomLocalStorageMetadata;

    if (!atomsMetadata) return StateInitializerValueValidatorError.OmitInitialization;

    const lastChangeUnix = atomsMetadata?.lastChangeUnix;

    if (!lastChangeUnix || now - lastChangeUnix > DATE_RANGE_ATOM_INITIALIZATION_THRESHOLD) {
      return StateInitializerValueValidatorError.OmitInitialization;
    }

    return value;
  };

const valueValidators: Record<string, ReturnType<typeof dateRangeFilterAtomValidator> | undefined> = {
  [dateRangeFilterAtom.key]: dateRangeFilterAtomValidator(),
  [dateRangeRequestsUsageOverviewFilterAtom.key]: dateRangeFilterAtomValidator(
    dateRangeRequestsUsageOverviewFilterAtom,
  ),
};

function lookupAtomFamilyWithKey(keyProp: string) {
  const atomFamilyList = [{ atomFamily: sortingStateAtomFamily, prefix: 'sortingState__' }];

  for (const atomFamilyMeta of atomFamilyList) {
    if (keyProp.includes(atomFamilyMeta.prefix)) {
      return atomFamilyMeta.atomFamily;
    }
  }

  return undefined;
}

// TODO: every value from localStorage should be validated before atom initialization?
export const stateInitializer: (mutableSnapshot: MutableSnapshot) => void = ({ set }: MutableSnapshot) => {
  for (const [key, value] of Object.entries<string>(localStorage)) {
    const currentAtom = lookupAtomWithKey(key);

    if (currentAtom) {
      const valueValidator = valueValidators[key];
      const localStorageValueCarrier = JSON.parse(value);
      const validLocalStorageValue = _.has(localStorageValueCarrier, LOCAL_STORAGE_VALUE_CARRIER_KEY);

      const localStorageValue = validLocalStorageValue
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (localStorageValueCarrier as { [LOCAL_STORAGE_VALUE_CARRIER_KEY]: any })[LOCAL_STORAGE_VALUE_CARRIER_KEY]
        : null;
      const validationResponse =
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        valueValidator && validLocalStorageValue ? valueValidator(localStorageValue) : undefined;

      if (!valueValidator && validLocalStorageValue) {
        set(currentAtom as RecoilState<unknown>, localStorageValue);
      } else if (
        validationResponse !== StateInitializerValueValidatorError.OmitInitialization &&
        validLocalStorageValue
      ) {
        set(currentAtom as RecoilState<unknown>, validationResponse);
      }
    }

    const currentAtomFamily = lookupAtomFamilyWithKey(key);
    if (currentAtomFamily) {
      const splittedKey = key.split('"');
      const atomName = splittedKey[splittedKey.length - 2];

      set(currentAtomFamily(atomName as ListNames) as RecoilState<unknown>, JSON.parse(value));
    }
  }
};
