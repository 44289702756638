const WishErrors = {
  NO_FULFILLER: 'NO_FULFILLER',
  TIMEOUT: 'TIMEOUT',
} as const;

type WishInterface = {
  /**
   * Registers a fulfiller method that can be used to fulfill promises with the same wishName.
   *
   * @param {string} wishName Name of the wish the fulfiller will be attached to.
   * @param {(data?:unknown) => Promise<unknown>} fulfiller
   *
   * @return {() => void} Return a function that when called, will unregister the fulfiller.
   */
  fulfill: (wishName: string, fulfiller: (data?: unknown) => Promise<unknown>) => () => void;
  /**
   * Deletes the fulfiller atteched to privided wishName.
   *
   * @param {string} wishName Name of the wish the fulfiller is attached to.
   *
   * @return {() => void} Return a function that when called, will delete the fulfiller.
   */
  deleteFulfiller: (wishName: string) => void;
  errors: typeof WishErrors;
};

type WishFactory = {
  (wishName: string, data?: unknown, timeout?: number): Promise<unknown>;
  fulfill: (wishName: string, fulfiller: (data?: unknown) => Promise<unknown>) => () => void;
  deleteFulfiller: (wishName: string) => void;
};

/**
 * Api for promise based sagas.
 * When invoked it will return a promise that will be pending till the wish is picked by a fulfiller.
 * One wish, one fulfiller.
 * Fulfiller will be invoked with data defined in the coresponding wish.
 * Fulfiller resolves or rejects the promise created with new wish.
 * Wish have now acces to the rosolved value in .then method.
 *
 * Use for manging side effects.
 *
 * @param {string} wishName Identifier used to pair wishes with fulfillers.
 * @param {unknown | undefined} data Data that will be passed to the fulfiller.
 * @param {number | undefined } timeout In the case where there are no registered fulfiller, this specifies the time in ms the Wish promise will wait for fulfiller registration, after that it will be rejected with 'NO_FULFILLER' error.
 *
 * When called:
 * @return Promise<unknown>
 *
 * @example
 *
 * wish('GET_USERS', userIds)
 * .then((users) => // do something)
 *
 * wish.fulfill('GET_USERS', fetchUsers)
 * // fetchUsers will be called with 'userIds' and returns a promise that resolves with 'users'
 *
 */

export const wish: WishFactory & WishInterface = (() => {
  const resolveTriggers = new Map<string, () => void>();
  const fulfillers = new Map<string, (data?: unknown) => Promise<unknown>>();

  function factory(wishName: string, data?: unknown, timeout?: number) {
    const promiseFulfiller = fulfillers.get(wishName);

    // if (process.env.NODE_ENV !== 'production') {
    //   console.table({
    //     ACTION: 'wish',
    //     wishName,
    //     data,
    //     timeout,
    //     fulfillers,
    //     hasFulfiller: !!promiseFulfiller
    //   });
    // }

    if (promiseFulfiller) {
      return new Promise((resolve) => {
        resolve(data);
      }).then(() => promiseFulfiller(data));
    }

    if (!timeout) {
      return new Promise(() => {
        throw new Error(WishErrors.NO_FULFILLER);
      });
    }

    return new Promise((resolve, reject) => {
      let isResolved = false;

      resolveTriggers.set(wishName, () => {
        isResolved = true;
        resolve(data);
        resolveTriggers.delete(wishName);
      });

      setTimeout(() => {
        if (isResolved) return;
        reject(new Error(WishErrors.TIMEOUT));
      }, timeout);
    }).then(() => {
      const fulfiller = fulfillers.get(wishName);
      if (!fulfiller) {
        throw new Error(WishErrors.NO_FULFILLER);
      }

      return fulfiller(data);
    });
  }

  factory.fulfill = (wishName: string, fulfiller: (data?: unknown) => Promise<unknown>) => {
    fulfillers.set(wishName, fulfiller);

    const resolveTrigger = resolveTriggers.get(wishName);

    if (resolveTrigger) {
      resolveTrigger();
    }

    return () => fulfillers.delete(wishName);
  };
  factory.deleteFulfiller = (wishName: string) => fulfillers.delete(wishName);

  factory.errors = WishErrors;

  return factory;
})();
