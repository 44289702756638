import { Flex, Text } from 'theme-ui';
import React from 'react';
import { useRecoilState } from 'recoil';

import { Icon } from 'components/Icon/Icon';
import { sortingOrderSelectorFamily } from 'state/list';

import { ColumnHeaderProps, SortingOrder } from './types';
import { GUTTER_DEFAULT_SIZE } from './constants';

export const ColumnHeader = ({
  columnKey,
  isSortable,
  listName,
  children,
  hasCustomRenderer,
}: ColumnHeaderProps): React.ReactElement => {
  const [sortingOrder, setSortingOrder] = useRecoilState(
    sortingOrderSelectorFamily({
      listName,
      columnKey,
    }),
  );

  const onClick = () => {
    switch (sortingOrder) {
      case SortingOrder.ASC:
        setSortingOrder(SortingOrder.DESC);
        break;
      case SortingOrder.DESC:
        setSortingOrder(SortingOrder.ASC);
        break;
      default:
        setSortingOrder(SortingOrder.ASC);
    }
  };

  return (
    <Flex
      {...(isSortable && { onClick })}
      sx={{
        ...((!isSortable || !sortingOrder) && { color: 'stickyList.secondaryText' }),
        ...(isSortable ? { fontWeight: 'bold', cursor: 'pointer' } : { cursor: 'default' }),
        flex: '1 0',
        px: `${GUTTER_DEFAULT_SIZE / 2}px`,
        ...(hasCustomRenderer && {
          alignSelf: 'stretch',
        }),
      }}
    >
      {typeof children === 'string' ? (
        <Text
          as="span"
          sx={{
            maxWidth: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {children}
        </Text>
      ) : (
        children
      )}

      {isSortable && sortingOrder && (
        <Icon wrapperSx={{ ml: 1 }} type={sortingOrder === SortingOrder.ASC ? 'chevronUp' : 'chevronDown'} />
      )}
    </Flex>
  );
};
