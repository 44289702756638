import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { AddRequestActionProps, RequestActionType } from 'api/actions/requests/requestsActions.types';
import { dateTime } from 'utils/dateTime';
import { useAddRequest } from '../../../../hooks/useAddRequest';

import { TimeTrackingContainer } from './components/TimeTrackingContainer';

export const TimeTrackingForm = (): React.ReactElement => {
  useLingui();
  const {
    setFormState,
    modifyRequest: { modifyRequestData },
    requestToEdit: { selectedRequestToEdit, resetSelectedRequestToEditId },
    defaultDateUnix,
  } = useAddRequest();

  const { unregister, watch, setValue } = useFormContext<AddRequestActionProps>();

  const typeIdWatch: string | undefined = watch('details.typeId');
  const actionTypeWatch: RequestActionType = watch('actionType');

  const formVisible = useMemo(() => {
    if (actionTypeWatch === RequestActionType.Edit && !selectedRequestToEdit && !modifyRequestData) return false;

    if (actionTypeWatch === RequestActionType.Create && !typeIdWatch) return false;

    if (actionTypeWatch === RequestActionType.Remove) return false;

    return true;
  }, [actionTypeWatch, selectedRequestToEdit, modifyRequestData, typeIdWatch]);

  const handleFormVisibility = useCallback(() => {
    if (formVisible) {
      setFormState((prevState) => ({ ...prevState, mainFormVisible: true }));
    }

    if (!formVisible) {
      if (actionTypeWatch === RequestActionType.Remove) {
        unregister('dateTimeDetails.date');
        unregister('note');
      }
      setFormState((prevState) => ({ ...prevState, mainFormVisible: false }));
    }
  }, [actionTypeWatch, formVisible, setFormState, unregister]);

  useEffect(() => {
    handleFormVisibility();
  }, [handleFormVisibility]);

  useEffect(() => {
    if (defaultDateUnix) {
      setValue('dateTimeDetails.date', dateTime(defaultDateUnix, { utc: false }).startOf('day').unix());
    }
  }, [defaultDateUnix, setValue]);

  useEffect(
    () => () => {
      resetSelectedRequestToEditId();
    },
    [resetSelectedRequestToEditId],
  );

  return (
    <TimeTrackingContainer>
      <TimeTrackingContainer.EditionObserver />
      <TimeTrackingContainer.SaveStateObserver />
      <TimeTrackingContainer.ResetFormObserver />
      <TimeTrackingContainer.RequestsHistorySelect />
      <TimeTrackingContainer.WorkStatus />
      {formVisible && (
        <>
          <TimeTrackingContainer.EventNote />
          <TimeTrackingContainer.Calendar />
        </>
      )}
    </TimeTrackingContainer>
  );
};
