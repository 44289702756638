import { ScheduleType } from 'constants/calendar';
import { ScheduleUserSelectableColor } from 'constants/userSelectableColors';
import { AiVariant, Employee, LimitPeriods, PersonName } from '../organizationSession/organizationSessionActions.types';

export enum DateTimeKind {
  Unspecified = 0,
  Utc = 1,
  Local = 2,
}

export enum RequestFormType {
  TimeOff = 0,
  TimeTracking = 1,
  BusinessTrip = 2,
  Custom = 3,
  Schedule = 4,
}

export enum RequestState {
  Pending = 0,
  Accepted = 1,
  Rejected = 2,
}

export enum RequestHistoryState {
  Created = 0,
  Accepted = 1,
  Rejected = 2,
  FirstOutOfTwoAcceptance = 3,
  Deleted = 4,
  TimeEventDeleted = 5,
  PersonAcceptSwap = 6,
  SwapProcessed = 7,
  TimeEventModified = 8,
}

export enum RequestActionType {
  Create = 0,
  Edit = 1,
  Remove = 2,
  Swap = 3,
}

export enum Period {
  Weekly = 0,
  Monthly = 1,
  Annually = 2,
}

export enum ActionOnRequest {
  Accept = 'Accept',
  Reject = 'Reject',
  AcceptSwap = 'AcceptSwap',
  RejectSwap = 'RejectSwap',
  Delete = 'Delete',
  Edit = 'Edit',
}

export enum ActionPermission {
  Allowed = 0,
  Disallowed = 1,
  Blocked = 2,
  BlockedBySwap = 3,
}

type EventDetails = {
  place?: string;
  name?: string;
  typeId?: string;
  participants?: Participant[];
  isEnd?: boolean;
  note?: string;
  reason?: string;
  attachments?: string[];
  schedulesEventDetails?: GetScheduleToSwap[];
  externalId?: string;
};

export type RequestEventType = {
  eventDetails: EventDetails;
  requestDateTimeDetails: DateTimeDetails;
};

export type SelectedPersonType = {
  personId: string;
  roleId?: string;
  isPersonHidden: boolean;
};

export type Request = {
  id: string;
  personId: Employee['id'];
  number: string;
  groupNumber: string;
  type: RequestFormType;
  state: RequestState;
  isDeleted: boolean;
  latestInGroup: boolean;
  actionType: RequestActionType;
  modificationAllowed: boolean;
  deleteAllowed: boolean;
  createDateTimeUnix: number;
  history: RequestHistory[];
  originalEvent?: RequestEventType;
  newEvent: RequestEventType;
  editAllowed: boolean;
  modificationAllowedForSwap: boolean;
  swapPersonId?: string;
  selectedFirstStep?: SelectedPersonType;
  selectedSecondStep?: SelectedPersonType;
  actions: {
    [key in ActionOnRequest]: ActionPermission;
  };
};

export type RequestsListActionProps = {
  startDateUnix: number;
  endDateUnix: number;
};

export type RequestsListResponse = {
  grouped: Request[];
  ungrouped: Request[];
};

export type RequestsGetByIdsActionProps = Request['id'][];

export type RequestsGetByIdsResponse = RequestsListResponse;

export type CommonRequestManagementActionProps = Request['id'][];

export type AcceptRequestManagementActionProps = CommonRequestManagementActionProps;

export type AcceptRequestManagementResponse = null;

export type RejectRequestManagementActionProps = {
  id: string;
  reason: string;
};

export type RejectRequestManagementResponse = null;

export type DeleteRequestActionProps = CommonRequestManagementActionProps;

export type DeleteRequestResponse = null;

export type RequestsUsageOverviewRequests = {
  type: RequestFormType;
  typeId: string;
  usageTimeDuration: number;
  limit?: number;
  totalTimeDuration?: number;
  remainingTimeDuration?: number;
  carryOverTimeDuration?: number;
  givenTimeDuration?: number;
};

export type RequestsUsageOverviewElement = {
  personId: Employee['id'];
  businessTravelsCount: number;
  timeEventsCount: number;
  swapRequestCount: number;
  requests: RequestsUsageOverviewRequests[];
};

export type FetchRequestsUsageOverviewResponse = RequestsUsageOverviewElement[];

export type RequestHistory = {
  person: string;
  dateTimeUnix: number;
  reason: string;
  state: RequestHistoryState;
  id: string;
  number: string;
  actionType: RequestActionType;
};

export type OvertimeDetails = {
  dateUnix: number;
  duration: number;
};

export type DateTimeDetails = {
  dates: number[];
  duration?: number;
  kind: DateTimeKind;
  forDay?: number;
  overtimeTaken?: OvertimeDetails[];
};

export type Participant = {
  person: PersonName;
  avatarUrl: string;
};

export type FetchRequestDetailsResponse = Request;

export type PeopleLimit = {
  personId: Employee['id'];
  duration: number;
  period?: Period;
};

export type RemainingLimit = {
  maxLength: number; // duration
  period?: Period;
  datesUnix?: number[];
  type: RequestFormType;
  typeId: string;
  peopleLimits?: PeopleLimit[];
};

type CommonTimeOffRemainingLimitsActionProps = {
  peopleIds: Employee['id'][];
  typeId: string;
  actionType: RequestActionType;
  datesUnix?: number[];
  requestId?: string;
};

export type GetTimeOffRemainingLimitsActionProps = CommonTimeOffRemainingLimitsActionProps;

export type GetTimeOffRemainingLimitsResponse = RemainingLimit;

export type GetCustomRemainingLimitsActionProps = CommonTimeOffRemainingLimitsActionProps;

export type GetCustomRemainingLimitsResponse = RemainingLimit;

export type GetAvailableOvertimeActionProps = CommonTimeOffRemainingLimitsActionProps;

export type GetAvailableOvertimeResponse = RemainingLimit;

type UpdateEventDetails = Pick<EventDetails, 'place' | 'isEnd' | 'note' | 'typeId' | 'name'> & {
  ignoreHolidays?: boolean;
  ignoreWeekends?: boolean;
};

export type AddRequestActionProps = {
  peopleIds: Employee['id'][];
  type: RequestFormType;
  actionType: RequestActionType;
  note?: string;
  files?: string[];
  processedById?: Employee['id'];
  optionalStepProcessedById?: Employee['id'];
  targetEventId?: string;
  targetSwapPersonId?: string;
  details: UpdateEventDetails;
  dateTimeDetails: Omit<DateTimeDetails, 'overtimeTaken'> & { date?: number };
  autoAcceptRequest: boolean;
};

export type AddRequestResponse = null;

export type FetchApprovalListsProps = {
  personId: string;
  requestType: RequestFormType;
};

export type FetchApprovalListsResponse = {
  firstStepPeopleIds: string[];
  secondStepPeopleIds?: string[];
};

type EventUpdateDetails = {
  id: string;
  typeId?: string;
  note?: string;
  fileIds?: string[];
  ignoreHolidays: boolean;
  ignoreWeekends: boolean;
  processedById?: string;
  optionalStepProcessedById?: string;
  number?: 'string';
  isEndStatus: boolean;
  reason?: 'string';
  personId?: 'string';
  name?: 'string';
  place?: 'string';
};

export type RequestGetToUpdateActionProps = {
  personId: string;
  skip: number;
  type: RequestFormType;
};

export type RequestToUpdate = {
  eventUpdateDetails: EventUpdateDetails;
  dateTimeDetails: DateTimeDetails;
};

export type RequestGetToUpdateResponse = RequestToUpdate[];

export type RequestSingleGetToUpdateActionProps = {
  id: Request['id'];
  skipInnerCode?: boolean;
};

export type RequestSingleGetToUpdateResponse = RequestToUpdate;

export type RequestPrintActionProps = { ids: string[]; separateFiles?: boolean };

export type RequestPrintResponse = {
  url: string;
};

export type RequestGetTimeTrackingToUpdateActionProps = {
  id: string;
  skipInnerCode?: boolean;
};

export type RequestGetTimeTrackingToUpdateResponse = RequestToUpdate;

export type GetAllRequestsToAcceptResponse = Request[];

export type LimitDetailsActionProps = {
  personId: string;
  dayUnix: number;
};

export type LimitDetailsResponse = [
  {
    typeId: string;
    limitPeriod: LimitPeriods;
    remaining: number;
    used: number;
  },
];

type ScheduleDetails = {
  type: ScheduleType;
  color: ScheduleUserSelectableColor;
  isDateBound: boolean;
  startDateTimeUnix?: number;
  endDateTimeUnix?: number;
  workTimeUnix?: number;
  isOvertimeSchedule?: boolean;
  countOvertimeBeforeWork?: boolean;
  countOvertimeAfterWork?: boolean;
  addAutomaticBreak?: boolean;
  subtractAutomaticBreak?: boolean;
  automaticBreakDurationUnix?: number;
};

export type ScheduleData = {
  id: string;
  group: {
    id: string;
  };
  isStart: boolean;
  isEnd: boolean;
  isPublished: boolean;
  details: ScheduleDetails;
  workPositionId?: string;
  addDefaultWorkPosition?: boolean;
  note?: string;
  isDeleted?: boolean;
  aiVariant?: AiVariant;
};

export type GetSchedulesToSwapProps = {
  peopleIds: string[];
  date: number;
};

export type GetScheduleToSwap = Omit<ScheduleData, 'group'> & {
  personId: string;
};

export type GetSchedulesToSwapResponse = GetScheduleToSwap[];

type CommonAcceptRejectSwapProps = {
  id: string;
};

type CommonAcceptRejectSwapResponse = null;

export type AcceptSwapProps = CommonAcceptRejectSwapProps;

export type RejectSwapProps = CommonAcceptRejectSwapProps & { reason: string };

export type AcceptSwapResponse = CommonAcceptRejectSwapResponse;

export type RejectSwapResponse = CommonAcceptRejectSwapResponse;

export type AddSwapRequestProps = Pick<
  AddRequestActionProps,
  'processedById' | 'optionalStepProcessedById' | 'note' | 'autoAcceptRequest' | 'peopleIds'
> & {
  date: number;
};

export type AddSwapRequestResponse = null;

export type GetPeopleToSwapProps = {
  personId: string;
};

export type GetPeopleToSwapResponse = string[];

export type RequestGetForPersonForRangeAndTypeActionProps = {
  startDateUnix: number;
  endDateUnix: number;
  typeId?: Request['newEvent']['eventDetails']['typeId'];
  type: Request['type'];
  personId: Employee['id'];
};

export type RequestGetForPersonForRangeAndTypeResponse = Request[];
