import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';
import { CustomEvents } from 'utils/customEvents';

import {
  FetchAttendancePillResponse,
  FetchTimeTrackerResponse,
  PostTimeEventActionProps,
  PostTimeEventResponse,
} from './drawerActions.types';

export function fetchAttendancePillAction(): Action<FetchAttendancePillResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.attendancePill,
  };
}

export function fetchTimeTrackerAction(): Action<FetchTimeTrackerResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.timeTracker,
  };
}

export function postTimeEventAction(body: PostTimeEventActionProps): Action<PostTimeEventResponse> {
  const formData = new FormData();

  formData.append('submitRequest', JSON.stringify(body));

  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeEventSubmit,
    body: formData,
    config: {
      onResponseCustomEvents: [
        { event: CustomEvents.POST_TIME_EVENT_SUCCESS_RESPONSE_RECEIVED, restrictToStatuses: [200, 204] },
      ],
    },
  };
}
