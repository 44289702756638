import React from 'react';
import { Flex } from 'theme-ui';

import { RequestActionType, RequestFormType, RequestState } from 'api/actions/requests/requestsActions.types';
import { RequestStatusIcon } from 'pages/Requests/output/RequestStatusIcon';
import { useRequestTypeListPresenter } from 'pages/Requests/output/useRequestTypeListPresenter';
import { ParsedRequestType } from 'state/requests';

type Props = {
  type: RequestFormType;
  number: string;
  state: RequestState;
  isDeleted: boolean;
  actionType: RequestActionType;
  businessTripName?: string;
  requestType?: ParsedRequestType | null;
  isEnd?: boolean;
  swapPersonId?: string;
  employeeId?: string;
  isZus?: boolean;
};

export const RequestItem = ({
  type,
  number,
  state,
  isDeleted,
  actionType,
  businessTripName,
  requestType,
  isEnd,
  swapPersonId,
  employeeId,
  isZus,
}: Props): React.ReactElement => {
  const { typeBadge, stateBadge, requestName, abbrBadge, numberRectRenderer } = useRequestTypeListPresenter();

  return (
    <Flex sx={{ flex: '1 0', alignItems: 'center' }}>
      <Flex sx={{ flex: '1 0', flexDirection: 'column', gap: 1 }}>
        <Flex sx={{ flex: '1 0', alignItems: 'center', gap: 1 }}>
          {numberRectRenderer(number)}
          {typeBadge(type)}
        </Flex>
        <Flex sx={{ flex: '1 0', overflow: 'hidden', whiteSpace: 'nowrap', gap: 1, flexShrink: 0 }}>
          {stateBadge({ viewType: 'grouped', actionType })}
          {abbrBadge(requestType)}
          {requestName({
            type,
            businessTripName,
            requestType,
            eventIsEnd: isEnd,
            noSpace: true,
            swapPersonId,
            employeeId,
          })}
        </Flex>
      </Flex>
      <RequestStatusIcon
        state={state}
        isDeleted={isDeleted}
        isZus={isZus}
        iconProps={{ wrapperSx: { alignItems: 'center' } }}
      />
    </Flex>
  );
};
