import { Action as BaseAction } from 'react-fetching-library';

import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { LanguageRoute } from 'constants/routes';
import { CustomEvents } from 'utils/customEvents';

import { Currency } from './actions/payments/paymentsActions.types';

export enum DefaultErrorMessageEnum {
  Default = 1,
  UnexpectedErrorContactSupport,
  SignIn,
  DisconnectIntegration,
  DownloadFile,
  PaymentProcess,
  UpdatePaymentMethod,
  RemovePaymentMethod,
  PaymentActivation,
  CancelPayment,
  CancelSubscription,
  FormSubmit,
  BillingDetails,
  None,
}

export type ActionConfig = {
  sentTimestampUnix?: number;
  skipAllResponseInterceptors?: boolean;
  skipAuthorization?: boolean;
  skipRefreshSession?: boolean;
  skipResend?: boolean;
  skipHost?: boolean;
  skipInnerCode?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onResponseCustomEvents?: { event: CustomEvents; data?: any; restrictToStatuses?: number[] }[];
  innerCodesThatOmmitsSnackbar?: ErrorInnerCodes[];
  resentTimes?: number;
  customFetchTimeout?: number;
  customDefaultErrorMessage?: DefaultErrorMessageEnum;
  baseRoute?: Partial<LanguageRoute>;
  popupWindow?: Window;
  errorMessageContext?: {
    currency?: Currency;
  };
  resendFailedSignUpQuery?: boolean;
};

export type ErrorResponse<ErrorData = Record<string, unknown>, InnerCodes = undefined> = {
  innerCode?: ErrorInnerCodes | InnerCodes;
  message?: string;
  data?: ErrorData;
};

export type Action<
  Response,
  ErrorData = Record<string, unknown>,
  InnerCodes = undefined,
  MR = ErrorResponse<ErrorData, InnerCodes> & Response,
> = BaseAction<MR, ActionConfig>;
