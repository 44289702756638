import { ThemeUIStyleObject } from 'theme-ui';

import { CHAT_WINDOW_WIDTH, MAIN_WINDOW_WIDTH } from 'constants/chat';
import { makeTheme } from 'styles/makeTheme';

import { THEME_NAVBAR_HEIGHT } from './base';

const WINDOW_BORDER_RADIUS = 'sm';

export const DEFAULT_CHAT_TRANSITION = '.15s ease-in-out';

export const MOBILE_CHAT_AVATAR_SIZE = 48;

const chatUserContainerSx: ThemeUIStyleObject = {
  gap: 2,
  alignItems: 'center',
  fontSize: 2,
  px: 2,
  cursor: 'pointer',
  transition: `background ${DEFAULT_CHAT_TRANSITION}`,
  bg: 'chat.mainWindow.bg.default',
  '&:hover': {
    bg: 'chat.mainWindow.bg.hoverDarker',
  },
};

const windowContainerSx: ThemeUIStyleObject = {
  pointerEvents: 'auto',
  flexDirection: 'column',
  boxShadow: 'chat.window',
  borderRadius: 0,
  borderTopLeftRadius: WINDOW_BORDER_RADIUS,
  borderTopRightRadius: WINDOW_BORDER_RADIUS,
  bg: 'chat.mainWindow.bg.default',
  overflow: 'hidden',
  maxHeight: '100vh',
  minHeight: ['100vh', null, null, '40px'],
  maxWidth: '100vw',
  minWidth: ['100vw', null, null, 'auto'],
  zIndex: 1,
  '@media (max-width: 991px)': {
    '&[data-is-visible="false"]': {
      borderRadius: 'circle',
      minWidth: MOBILE_CHAT_AVATAR_SIZE,
      width: MOBILE_CHAT_AVATAR_SIZE,
      minHeight: MOBILE_CHAT_AVATAR_SIZE,
      height: MOBILE_CHAT_AVATAR_SIZE,
      maxHeight: MOBILE_CHAT_AVATAR_SIZE,

      '> header': {
        '.chat-user, button': {
          opacity: 0,
        },
        '.chat-avatar': {
          transform: 'scale(2)',
        },
      },
    },
    '&[data-is-visible="true"]': {
      position: 'fixed',
      top: 0,
      right: 0,
    },
  },
};

export const chatStyles = makeTheme({
  chat: {
    root: {
      mr: 3,
      mb: [parseInt(THEME_NAVBAR_HEIGHT, 10) + 16, null, null, 0],
      pointerEvents: 'none',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexWrap: 'nowrap',
      overflow: 'visible',
    },
    chatWindowsContainer: {
      flex: '0 0 auto',
      flexDirection: 'row-reverse',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      zIndex: 1,
      '> div::after': {
        content: '""',
        width: '0.25rem',
        pointerEvents: 'auto',
      },
    },
    window: {
      mainContainer: {
        ...windowContainerSx,
        flex: '0 0 auto',
        maxWidth: ['100vw', null, null, `${MAIN_WINDOW_WIDTH}px`],
        minWidth: MAIN_WINDOW_WIDTH,
        width: '100%',
        height: '70vh',
      },
      chatContainer: {
        ...windowContainerSx,
        minHeight: '100%',
      },
      header: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: [MOBILE_CHAT_AVATAR_SIZE, null, null, '40px'],
        minHeight: [MOBILE_CHAT_AVATAR_SIZE, null, null, '40px'],
        height: '100%',
        minWidth: CHAT_WINDOW_WIDTH,
        userSelect: 'none',
        cursor: 'pointer',
        gap: ['0.75rem', null, null, 2],
        boxShadow: 'chat.windowHeader',
        fontSize: [3, null, null, 2],
        fontWeight: 'bold',
        px: ['0.75rem', null, null, 2],
        transition: `all ${DEFAULT_CHAT_TRANSITION}`,
        transitionProperty: 'background, color',
        '&[data-unread-message="true"]': {
          bg: 'chat.mainWindow.bg.unreadMessage',
          color: 'chat.text.unread',
        },
        '&:hover': {
          bg: 'chat.mainWindow.bg.hover',
          '&[data-unread-message="true"]': {
            bg: 'chat.mainWindow.bg.hoverUnreadMessage',
          },
        },
        texts: {
          flexGrow: 1,
          flexDirection: 'column',
          '> span': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
          small: {
            lineHeight: 1,
            fontSize: 0,
            color: 'chat.text.support',
          },
        },
        buttons: {
          gap: 1,
          alignItems: 'center',
          '> button': {
            px: 1,
          },
        },
      },
      list: {
        flexDirection: 'column',
        height: '100%',
        // overflow: 'auto',
        alignItems: 'stretch',
      },
      spinnerContainer: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    user: {
      mainWindow: {
        ...chatUserContainerSx,
        flex: '0 0 auto',
        '&::after': {
          content: '""',
          display: 'block',
          height: 1,
          position: 'absolute',
          bg: 'chat.bg.divider',
          bottom: 0,
          left: 24,
          right: 24,
        },
      },
      chatWindow: {
        ...chatUserContainerSx,
        p: 0,
        flex: '1 0',
        bg: 'chat.bg.userChatWindow',
        '&:hover': {},
      },
    },
    messageComposer: {
      container: {
        p: 2,
        position: 'relative',
        mb: 0,
      },
      input: {
        pl: 2,
        pr: 6,
        py: 2,
        fontSize: 2,
        borderRadius: WINDOW_BORDER_RADIUS,
        border: '1px solid',
        borderColor: 'input.border.default',
        backgroundColor: 'input.background.default',
        resize: 'none',
        maxHeight: '160px',
        fontFamily: 'inherit',
        cursor: 'text',
        color: 'input.text.default',
        overflowX: 'hidden',
        overflowY: 'auto',
        transition: `background ${DEFAULT_CHAT_TRANSITION}`,
        '&:hover': {
          backgroundColor: 'input.background.hover',
        },
        '&:focus': {
          outline: 0,
          backgroundColor: 'input.background.focus',
          borderColor: 'input.border.focus',
        },
        '&&[contentEditable=true]:empty:before': {
          content: 'attr(data-placeholder)',
          color: 'input.text.placeholder',
        },
      },
      sendButtonContainer: {
        position: 'absolute',
        top: 8,
        right: 8,
        bottom: 8,
        width: '3rem',
        alignItems: 'center',
        justifyContent: 'center',
        '> button': {
          '&[disabled]': {
            color: 'chat.text.sendButtonContainer',
            background: (t) => `${t.colors?.chatSendButton} !important`,
          },
        },
      },
    },
    messagesList: {
      container: {
        flexDirection: 'column-reverse',
        overflowY: 'auto',
        mt: 'auto',
        height: '100%',
      },
      dayDivider: {
        container: {
          mt: 2,
          mb: 1,
          alignItems: 'center',
          '&:before, &:after': {
            content: '""',
            bg: 'chat.bg.divider',
            height: '1px',
            flex: 1,
          },
        },
        text: {
          userSelect: 'none',
          textAlign: 'center',
          border: '1px solid',
          borderColor: 'chat.bg.divider',
          fontSize: 1,
          px: 2,
          py: 1,
          borderRadius: '1.875rem',
          fontWeight: 'bold',
          color: 'chat.text.messagesList',
        },
      },
    },
    message: {
      container: {
        position: 'relative',
        fontSize: 2,
        py: 1,
        px: 2,
        gap: 2,
        transition: `background ${DEFAULT_CHAT_TRANSITION}`,
        '&:hover': {
          bg: 'chat.mainWindow.bg.hoverDarker',
        },
      },
      unsentContainer: {
        gap: 1,
        pl: '2.5rem',
        pr: 2,
        py: 1,
      },
      hoverMenuContainer: {
        borderRadius: 'xs',
        p: '0.125rem',
        position: 'absolute',
        right: 0,
        top: 0,
        bg: 'chat.hoverMenuContainer.bg',
        border: '1px solid',
        borderColor: 'chat.hoverMenuContainer.border',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
        '> button': {
          color: 'chat.hoverMenuContainer.text',
        },
      },
      content: {
        flexDirection: 'column',
      },
      header: {
        alignItems: 'center',
        flexDirection: 'row',
        gap: 1,
      },
      name: {
        fontWeight: 'bold',
        fontSize: 2,
        gap: 2,
      },
      date: {
        color: 'chat.text.date',
        fontWeight: 'normal',
        fontSize: 1,
      },
      text: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
      },
    },
  },
});
