import { Trans, t } from '@lingui/macro';
import { Flex, Heading, Text } from 'theme-ui';

import { Button } from 'components/ui/Buttons';
import { PATH, TO } from 'constants/routes';
import { SupportedLanguages } from 'constants/supportedLanguages';
import { onboardingStepsType } from '../../types';

export function onboardingSchedulesManager(
  playNextStep: () => void,
  playCustomNextStep: (steps: number) => void,
  playPrevStep: () => void,
  language: keyof typeof SupportedLanguages,
): onboardingStepsType {
  return [
    {
      content: (
        <>
          <Text>
            <Trans id="onboarding.schedules_manager.step1">
              Now let's add a schedule, navigate to <b>Calendar</b>.
            </Trans>
          </Text>
          <Flex sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={() => playCustomNextStep(8)} size="xs" shape="rounded" variant="lightGrey">
              {t({ id: 'onboarding.actions.skip' })}
            </Button>
          </Flex>
        </>
      ),
      targetElement: 'onboarding-schedule-1',
      onClickElement: playNextStep,
    },
    {
      content: (
        <>
          <Text>
            <Trans id="onboarding.schedules_manager.step2.part1">
              This is a <b>work calendar</b> for you and the teammates that you are allowed to see.
            </Trans>
          </Text>
          <Text>
            <Trans id="onboarding.schedules_manager.step2.part2">
              Click on <b>any cell</b> to continue.
            </Trans>
          </Text>
        </>
      ),
      targetElement: 'onboarding-schedule-2',
      onClickElement: (e) => {
        const node = e.target as HTMLElement;
        const isGridCell = !!node.closest('.grid-cell');
        const isPersonCell = !!node.closest('.person-cell');

        if (!!node && isGridCell && !isPersonCell) {
          playNextStep();
        } else {
          e.stopPropagation();
        }
      },
      showOnUrl: PATH.CALENDAR[language],
      urlCompareMode: 'include',
    },
    {
      content: (
        <Text>
          <Trans id="onboarding.schedules_manager.step3">
            Click here to <b>Add a schedule</b>.
          </Trans>
        </Text>
      ),
      targetElement: 'onboarding-schedule-3',
      onClickElement: playNextStep,
      showOnUrl: TO.DETAILS__USER_ID__DATE_UNIX[language],
      urlCompareMode: 'include',
    },
    {
      content: (
        <Text>
          <Trans id="onboarding.schedules_manager.step4">
            Fill out the form and click <b>Save</b>.
          </Trans>
        </Text>
      ),
      targetElement: 'onboarding-schedule-4',
      showOnUrl: TO.ADD_SCHEDULE__USER__DATE[language],
      urlCompareMode: 'include',
      focusOnElement: false,
    },
    {
      content: (
        <>
          <Text>
            <Trans id="onboarding.schedules_manager.step5.part1">
              Now we have a schedule, but it's in a <b>draft mode</b>.
            </Trans>
          </Text>
          <Text>
            <Trans id="onboarding.schedules_manager.step5.part2">
              We need to <b>publish</b> it, click here on the schedule menu.
            </Trans>
          </Text>
        </>
      ),
      targetElement: 'onboarding-schedule-5',
      onClickElement: playNextStep,
      showOnUrl: TO.DETAILS__USER_ID__DATE_UNIX[language],
      urlCompareMode: 'include',
    },
    {
      content: (
        <Text>
          <Trans id="onboarding.schedules_manager.step6">
            Click here on <b>Publish</b> to make it visible to your team.
          </Trans>
        </Text>
      ),
      targetElement: 'onboarding-schedule-6',
      onClickElement: playNextStep,
      onOutsideClick: playPrevStep,
      showOnUrl: PATH.CALENDAR[language],
      urlCompareMode: 'include',
    },
    {
      content: (
        <Text>
          <Trans id="onboarding.schedules_manager.step7">
            Yep, we need to confirm such actions, by clicking here on <b>Publish</b>.
          </Trans>
        </Text>
      ),
      targetElement: 'onboarding-schedule-7',
      showOnUrl: TO.PUBLISH_SCHEDULE[language],
      urlCompareMode: 'include',
      focusOnElement: false,
      placement: 'bottom',
    },
    {
      content: (
        <Flex sx={{ flexDirection: 'column', alignContent: 'center', gap: 'inherit', textAlign: 'center' }}>
          <Flex variant="onboarding.modalHeaderIcon">💪</Flex>
          <Heading variant="heading3" as="h3">
            <Trans id="onboarding.schedules_manager.step8.title">We have added and published a new schedule.</Trans>
          </Heading>
          <Text as="p" variant="p">
            <Trans id="onboarding.schedules_manager.step8.message">Simple as !@#$, right?</Trans>
          </Text>
          <Flex sx={{ gap: 2, justifyContent: 'center', pt: 3 }}>
            <Button shape="rounded" variant="primary" onClick={playNextStep}>
              {t({ id: 'onboarding.action.next_step' })}
            </Button>
          </Flex>
        </Flex>
      ),
      mode: 'modal',
    },
  ];
}

export function onboardingSchedules(
  playNextStep: () => void,
  language: keyof typeof SupportedLanguages,
): onboardingStepsType {
  return [
    {
      content: (
        <Text>
          <Trans id="onboarding.schedules.step1">
            Now, let's see where you can find your schedules. Navigate to <b>Calendar</b>.
          </Trans>
        </Text>
      ),
      targetElement: 'onboarding-schedule-1',
      onClickElement: playNextStep,
    },
    {
      content: (
        <>
          <Text>
            <Trans id="onboarding.schedules.step2.part1">
              This is a <b>work calendar</b> for you and the teammates that you are allowed to see.
            </Trans>
          </Text>
          <Text>
            <Trans id="onboarding.schedules.step2.part2">
              Have a look around. <b>Click anywhere</b> to open next step.
            </Trans>
          </Text>
        </>
      ),
      targetElement: 'onboarding-schedule-2',
      onClickElement: playNextStep,
      showOnUrl: PATH.CALENDAR[language],
      urlCompareMode: 'include',
    },
  ];
}
