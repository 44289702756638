/* eslint-disable no-console */
import { useRecoilState, useRecoilValue } from 'recoil';
import { useCallback, useEffect } from 'react';
import _ from 'lodash';

import { QrScannerState, qrScannerStateAtom, qrScannerWorkerAtom } from 'Kiosk/state/qrScannerState';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { cameraImageBufferAtom } from 'Kiosk/state/cameraImageBufferState';

type Props = {
  onDecode?: (qr: string, imageUrl: string, startQrScanner: () => void, stopQrScanner: () => void) => Promise<void>;
};

export type UseQrScannerProps = Props;

type ReturnProps = QrScannerState;

export const useQrScanner = ({ onDecode }: Props): ReturnProps => {
  const webWorker = useRecoilValue(qrScannerWorkerAtom);
  const [state, setState] = useRecoilState(qrScannerStateAtom);
  const { isQrScannerRunning } = state;

  const { isCameraReady, isCameraStreaming } = useRecoilValue(cameraStateAtom);
  const prevCameraState = usePrevious({ isCameraReady, isCameraStreaming });

  const { imageBufferCanvasCallback } = useRecoilValue(cameraImageBufferAtom);

  const pingWorker = useCallback(() => {
    if (webWorker) webWorker.postMessage('PING');
  }, [webWorker]);

  const decode = useCallback(
    (frameData: { imageData: ImageData; imageUrl: string }) => {
      if (webWorker) webWorker.postMessage(frameData);
    },
    [webWorker],
  );

  const startQrScanner = useCallback(() => {
    const canvas = imageBufferCanvasCallback();
    if (canvas && canvas.current) {
      canvas.current.style.display = '';
    }
    console.log('useQrScanner', '[ACTION - STARTED]');
    setState((prevState) => ({ ...prevState, isQrScannerRunning: true }));
  }, [imageBufferCanvasCallback, setState]);

  const stopQrScanner = useCallback(() => {
    const canvas = imageBufferCanvasCallback();
    if (canvas && canvas.current) {
      canvas.current.style.display = 'none';
    }
    console.log('useQrScanner', '[ACTION - STOPPED]');
    setState((prevState) => ({ ...prevState, isQrScannerRunning: false }));
  }, [imageBufferCanvasCallback, setState]);

  const toggleQrScanner = useCallback(() => {
    setState((prevState) => {
      console.log('useQrScanner', '[ACTION - TOGGLED]', !prevState.isQrScannerRunning);
      return { ...prevState, isQrScannerRunning: !prevState.isQrScannerRunning };
    });
  }, [setState]);

  const onDecodeCallback = useCallback(
    (event: MessageEvent<string | { qr: string; imageUrl: string }>) => {
      if (event.data === 'PONG') {
        console.log('Pong caught on event, so isQrScannerWorking = true');
        return;
      }

      if (!_.isString(event.data)) {
        const { qr, imageUrl } = event.data;

        if (onDecode) {
          void onDecode(qr, imageUrl, startQrScanner, stopQrScanner);
        }
      }
    },
    [onDecode, startQrScanner, stopQrScanner],
  );

  useEffect(() => {
    if (webWorker) {
      setState({
        isQrScannerRunning: true,
        startQrScanner,
        stopQrScanner,
        toggleQrScanner,
        decode,
        pingWorker,
      });
    }
  }, [decode, pingWorker, setState, startQrScanner, stopQrScanner, toggleQrScanner, webWorker]);

  useEffect(() => {
    if (
      (prevCameraState && prevCameraState.isCameraReady !== isCameraReady) ||
      (prevCameraState && prevCameraState.isCameraStreaming !== isCameraStreaming)
    ) {
      if (!isCameraReady || !isCameraStreaming) {
        stopQrScanner();
      }
      if (!isQrScannerRunning) {
        startQrScanner();
      }
    }
  }, [isCameraReady, isCameraStreaming, isQrScannerRunning, prevCameraState, startQrScanner, stopQrScanner]);

  useEffect(() => {
    if (webWorker) {
      if (isQrScannerRunning) {
        webWorker.addEventListener('message', onDecodeCallback);
      }

      if (!isQrScannerRunning) {
        webWorker.removeEventListener('message', onDecodeCallback);
      }
    }

    return () => {
      if (webWorker) {
        webWorker.removeEventListener('message', onDecodeCallback);
      }
    };
  }, [onDecodeCallback, isQrScannerRunning, webWorker]);

  return state;
};
