import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Grid } from 'theme-ui';

import { LoadingSpinnerSize } from 'components/Loading/LoadingSpinnerCSS';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { Image } from 'components/ui/Image';
import { ScalableImage } from 'components/ui/ScalableImage';
import { PersonCell } from 'pages/Reports/output/PersonCell';
import { extTimeEventsSelectorFamily, ExtTimeEventsSelectorListType } from 'state/clockLog';
import { windowSizeAtom } from 'state/recoilState';
import { reportsExtTimeEventsSelector } from 'state/reports';

import { EventDetails } from './components/EventDetails';
import { useFetchFraudVerificationPhotosDetails } from './hooks/useFetchFraudVerificationPhotosDetails';

const MIN_IMAGE_HEIGHT = 200;

type Props = {
  eventListType?: ExtTimeEventsSelectorListType;
};

export const ShowEventPhotoModal = ({ eventListType }: Props): React.ReactElement | null => {
  useLingui();
  const { baseRoute } = useModal({ wrapperSx: { height: ['600px', null, null, '700px'], maxHeight: '100%' } });
  const { id } = useParams() as { id: string };

  const { isLandscape } = useRecoilValue(windowSizeAtom);

  const extTimeEvents = useRecoilValue(extTimeEventsSelectorFamily(eventListType || 'all'));

  const reportsExtTimeEvents = useRecoilValue(reportsExtTimeEventsSelector);

  const extTimeEvent = useMemo(() => extTimeEvents?.get(id), [extTimeEvents, id]);

  const selectedEvent = useMemo(
    () => (!extTimeEvent ? reportsExtTimeEvents?.get(id) : extTimeEvent),
    [extTimeEvent, id, reportsExtTimeEvents],
  );

  const fraudDetectionState = extTimeEvent?.fraudDetectionState;

  const { verificationPhotosDetails } = useFetchFraudVerificationPhotosDetails(
    !_.isUndefined(fraudDetectionState) ? id : undefined,
  );

  const photosRenderer = useCallback(
    (photosUrls?: string[]) => {
      if (!photosUrls) return <CenteredLoadingSpinner size={5} />;
      if (!photosUrls.length) return null;

      if (photosUrls.length > 1) {
        return (
          <Grid sx={{ gridTemplateColumns: ['1fr', '1fr 1fr'], justifyItems: 'center', gap: '.5rem' }}>
            {photosUrls.map((picSrc) => (
              <Image
                key={picSrc}
                src={picSrc}
                sx={{ borderRadius: 'default', minHeight: MIN_IMAGE_HEIGHT }}
                alt={t({ id: 'clock_log.time_event' })}
                loadingOverlaySize={LoadingSpinnerSize.imageXL}
              />
            ))}
          </Grid>
        );
      }

      return (
        <Flex sx={{ flexDirection: [isLandscape ? 'row' : 'column', null, 'row'], gap: 5, flexGrow: 1 }}>
          <ScalableImage
            src={photosUrls[0]}
            alt={t({ id: 'clock_log.time_event' })}
            sx={{ borderRadius: 'default' }}
            loadingSpinnerProps={{ size: 5 }}
          />
        </Flex>
      );
    },
    [isLandscape],
  );

  if (!selectedEvent) return <Navigate to={baseRoute} relative="path" />;

  const { employeeName, role, tags, avatarUrl, photoUrl } = selectedEvent;

  const photosUrls = (() => {
    if (_.isUndefined(fraudDetectionState) && photoUrl) {
      return [photoUrl];
    }
    if (!_.isUndefined(fraudDetectionState) && verificationPhotosDetails) {
      return verificationPhotosDetails.map(({ url }) => url);
    }
    return undefined;
  })();

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="clock_log.photo">Photo</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body sx={{ flexGrow: 1, pt: 0, gap: 4 }}>
        <Flex sx={{ flexDirection: ['column', 'row'], justifyContent: 'space-between', gap: 3 }}>
          <Flex>
            <PersonCell name={employeeName} role={role} tags={tags} avatarUrl={avatarUrl} avatarSize={34} />
          </Flex>
          <EventDetails extTimeEvent={selectedEvent} hidePersonCell />
        </Flex>
        {photosRenderer(photosUrls)}
      </Modal.Body>
    </>
  );
};
