import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { languageSelector } from 'state/recoilState';
import { useIntegrationTileTexts } from '../../../../../hooks/useIntegrationTileTexts';
import { useIntegrations } from '../../../../../hooks/useIntegrations';
import { IntegrationTile } from '../../IntegrationTile/IntegrationTile';
import { IntegrationProps } from '../../IntegrationTile/types';

type Props = IntegrationProps;

export const ZusTile = ({ integration }: Props): React.ReactElement => {
  const { isActive, imageUrl, type } = integration;
  const language = useRecoilValue(languageSelector);
  const navigate = useAppNavigate();
  const { disconnectIntegration } = useIntegrations();
  const integrationTexts = useIntegrationTileTexts(type);

  const handleConnectButtonCallback = useCallback(
    (reconnect: boolean) => {
      navigate(TO_REL.ZUS_MODAL[language], { state: { reconnect } });
    },
    [language, navigate],
  );

  const handleDisconnectButtonCallback = useCallback(() => {
    void disconnectIntegration(ServiceIntegration.ZusEzla);
  }, [disconnectIntegration]);

  return (
    <IntegrationTile isActive={isActive}>
      <IntegrationTile.Tags isActive={isActive} />
      <IntegrationTile.Body {...integrationTexts} image={imageUrl} />
      <IntegrationTile.Buttons>
        <IntegrationTile.Buttons.Connect
          isActive={isActive}
          textVariant="connect"
          onClick={() => handleConnectButtonCallback(false)}
        />
        <IntegrationTile.Buttons.Expired isExpired={isActive} onClick={() => handleConnectButtonCallback(true)} />
        <IntegrationTile.Buttons.Disconnect
          isActive={isActive}
          textVariant="disconnect"
          onClick={handleDisconnectButtonCallback}
        />
      </IntegrationTile.Buttons>
    </IntegrationTile>
  );
};
