import React, { useCallback, useState } from 'react';
import GoogleMapReact from 'google-map-react';

import { GOOGLE_MAP_API_KEY } from 'constants/common';

import { MapComponent, MapContextProps, MapProps, OnGoogleApiLoaded } from './types';
import { MapContext } from './MapContext';
import { DefaultMarker } from './Marker';
import { Circle } from './Circle';

const defaultProps: Partial<MapProps> = {
  defaultZoom: 16,
};

export const Map: MapComponent = ({ children, onGoogleApiLoaded, ...props }: MapProps) => {
  const [contextValue, setContextValue] = useState<MapContextProps | undefined>();

  const handleGoogleApiLoaded: OnGoogleApiLoaded = useCallback(
    ({ map, maps, ref }) => {
      setContextValue({ googleMaps: maps, map });

      if (onGoogleApiLoaded) {
        onGoogleApiLoaded({ map, maps, ref });
      }
    },
    [onGoogleApiLoaded],
  );

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY || '' }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleGoogleApiLoaded}
        {...props}
      />

      <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
    </>
  );
};

Map.defaultProps = defaultProps;

Map.Marker = DefaultMarker;
Map.Circle = Circle;
