import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';
import { ViewSettings } from '../userSession/userSessionActions.types';

import { FetchReportActionProps, FetchReportForIdsActionProps, FetchReportResponse } from './reportsActions.types';

export function fetchReportAction(props: FetchReportActionProps | null): Action<FetchReportResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.report.getAll,
    body: props,
    config: {
      // customFetchTimeout: 60000,
    },
  };
}

export function fetchReportForIdsAction(props: FetchReportForIdsActionProps | null): Action<FetchReportResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.report.getForIds,
    body: props,
    config: {
      // customFetchTimeout: 60000,
    },
  };
}

export function timesheetsReportViewSettings(viewSettings: ViewSettings): Action<null> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.viewsSettings,
    body: {
      timesheetsReport: viewSettings,
    },
  };
}
