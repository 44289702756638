import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import { automationsSettingsAtom, resetFormButtonAtom } from 'state/settings';

export const useAutomationsSettingsCleanUp = () => {
  const resetFormButton = useResetRecoilState(resetFormButtonAtom);
  const resetAutomationsSettings = useResetRecoilState(automationsSettingsAtom);

  useEffect(
    () => () => {
      resetFormButton();
      resetAutomationsSettings();
    },
    [resetFormButton, resetAutomationsSettings],
  );
};
