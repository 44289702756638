import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import { integrationsMapSelector } from 'state/settings';
import { HeadingTip } from '../../../../../HeadingTip';
import { useIntegrations } from '../../../../hooks/useIntegrations';

import { CommonIntegrationTile } from './components/CommonIntegrationTile';
import { QuickbooksXeroTile } from './components/QuickbooksXeroTile';

export const Payrolls = (): React.ReactElement => {
  useLingui();
  const integrationsMap = useRecoilValue(integrationsMapSelector);
  const { integrations } = useIntegrations();

  if (_.isEmpty(integrations) || !integrations) return <></>;

  return (
    <>
      <HeadingTip
        label={t({ id: 'settings.integrations.payrolls', message: 'Payrolls' })}
        variant="heading4"
        additionalInfo={
          <>
            <Text as="span" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
              <Trans id="settings.integrations.visible_managers_administrators">
                Only visible to specific roles ex. Managers and Administrators.
              </Trans>
            </Text>{' '}
            <Text>
              <Trans id="settings.integrations.organization_wide_integration_info">
                Organization-wide integrations, when enabled/connected will be available for all your teammates
                according to theirs roles etc.
              </Trans>
            </Text>
          </>
        }
        additionalInfoSx={{ fontSize: 2 }}
        sx={{ mt: 4, mb: 3 }}
      />
      <Flex sx={{ flexWrap: 'wrap', gap: 2 }}>
        {integrations.map((integration) => {
          const { type } = integration;

          const mapIntegration = integrationsMap.get(type);

          if (mapIntegration) {
            switch (type) {
              case ServiceIntegration.Xero:
              case ServiceIntegration.Quickbooks:
              case ServiceIntegration.SquarePayroll:
                return <QuickbooksXeroTile key={type} integration={mapIntegration} />;
              case ServiceIntegration.Optima:
              case ServiceIntegration.Symfonia:
              case ServiceIntegration.R2Platnik:
              case ServiceIntegration.GratyfikantGT:
              case ServiceIntegration.Enova:
              case ServiceIntegration.GratyfikantNexo:
                return <CommonIntegrationTile key={type} integration={mapIntegration} />;
              default:
                return null;
            }
          }

          return null;
        })}
      </Flex>
    </>
  );
};
