import { Action, DefaultErrorMessageEnum } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  AdvancedSettingsDeleteFaceModelsActionProps,
  AdvancedSettingsDeleteFaceModelsResponse,
  AdvancedSettingsDeleteOrganizationActionProps,
  AdvancedSettingsDeleteOrganizationResponse,
  AdvancedSettingsDeletePhotosActionProps,
  AdvancedSettingsDeletePhotosResponse,
  AdvancedSettingsSaveBetaSettingsProps,
  AdvancedSettingsSaveBetaSettingsResponse,
  AutomationsSettingsActionProps,
  AutomationsSettingsResponse,
  BasicInformationSettingsActionProps,
  DeleteEmployeesFaceModelsActionProps,
  DeleteEmployeesFaceModelsResponse,
  DeleteEmployeesPhotosActionActionProps,
  DeleteEmployeesPhotosResponse,
  FetchAutomationsSettingsResponse,
  FetchNotificationSettingsResponse,
  FetchOrganizationSettingsResponse,
  FetchRequestsSettingsResponse,
  FetchScheduleSettingsResponse,
  FetchTimeTrackingSettingsResponse,
  FetchUserInfoResponse,
  FetchUserNotificationsSettingsResponse,
  NotificationSettingsActionProps,
  NotificationSettingsResponse,
  OrganizationHolidaysSettingsActionProps,
  OrganizationSettingsActionProps,
  OrganizationSettingsAddChangeAvatarActionProps,
  OrganizationSettingsAddChangeAvatarResponse,
  OrganizationSettingsDeleteAvatarResponse,
  OrganizationSettingsResponse,
  PreferencesSettingsActionProps,
  RequestsBusinessTripSettings,
  RequestsCustomSettings,
  RequestsSettingsActionProps,
  RequestsSettingsResponse,
  RequestsTimeOffSettings,
  RequestsTimeTrackingSettings,
  ScheduleSettingsActionProps,
  ScheduleSettingsResponse,
  TimeTrackingSettingsActionProps,
  TimeTrackingSettingsResponse,
  UserChangePasswordSettingsActionProps,
  UserChangePasswordSettingsResponse,
  UserInfoActionProps,
  UserInfoChangeAvatarActionProps,
  UserInfoChangeAvatarResponse,
  UserInfoDeleteAvatarResponse,
  UserInfoResponse,
  UserNotificationsSettingsActionProps,
  UserNotificationsSettingsResponse,
  UserPreferencesActionProps,
  UserPreferencesResponse,
  UserSetPasswordSettingsActionProps,
  UserSetPasswordSettingsResponse,
} from './settingsActions.types';

export function fetchSchedulesAction(): Action<FetchScheduleSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.schedulesSettings,
  };
}

export function scheduleSettingsAction(body: ScheduleSettingsActionProps): Action<ScheduleSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.schedulesSettings,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function userNotificationsSettingsAction(
  body: UserNotificationsSettingsActionProps,
): Action<UserNotificationsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userNotifications,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function fetchNotificationSettingsAction(): Action<FetchNotificationSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.notificationSettings,
  };
}

export function notificationSettingsAction(
  body: Partial<NotificationSettingsActionProps>,
): Action<NotificationSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.notificationSettings,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function fetchUserNotificationsSettingsAction(): Action<FetchUserNotificationsSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.userNotifications,
  };
}

export function userChangePasswordAction(
  body: UserChangePasswordSettingsActionProps,
): Action<UserChangePasswordSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userChangePassword,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function userSetPasswordAction(
  body: UserSetPasswordSettingsActionProps,
): Action<UserSetPasswordSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userSetPassword,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function advancedSettingsSaveBetaSettings(
  body: AdvancedSettingsSaveBetaSettingsProps,
): Action<AdvancedSettingsSaveBetaSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.advancedOrganizationSettingsSaveBetaSettings,
    body,
  };
}

export function deleteEmployeesPhotosAction({
  ...body
}: DeleteEmployeesPhotosActionActionProps): Action<DeleteEmployeesPhotosResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.deleteEmployeesPhotos,
    body,
  };
}

export function deleteEmployeesFaceModelsAction({
  ...body
}: DeleteEmployeesFaceModelsActionProps): Action<DeleteEmployeesFaceModelsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.deleteEmployeesFaceModels,
    body,
  };
}

export function advancedSettingsDeletePhotosAction({
  ...body
}: AdvancedSettingsDeletePhotosActionProps): Action<AdvancedSettingsDeletePhotosResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.advancedOrganizationSettingsDeletePhotos,
    body,
  };
}

export function advancedSettingsDeleteFaceModelsAction({
  ...body
}: AdvancedSettingsDeleteFaceModelsActionProps): Action<AdvancedSettingsDeleteFaceModelsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.advancedOrganizationSettingsDeleteFaceModels,
    body,
  };
}

export function advancedSettingsDeleteOrganizationAction(
  body: AdvancedSettingsDeleteOrganizationActionProps,
): Action<AdvancedSettingsDeleteOrganizationResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.advancedOrganizationSettingsDeleteOrganization,
    body,
  };
}

export function fetchTimeTrackingSettingsAction(): Action<FetchTimeTrackingSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.timeTrackingSettings,
  };
}

export function timeTrackingSettingsAction(
  body: TimeTrackingSettingsActionProps,
): Action<TimeTrackingSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.timeTrackingSettings,
    body,
  };
}

export function userPreferencesAction(body: UserPreferencesActionProps): Action<UserPreferencesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userPreferences,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function fetchOrganizationSettingsAction(): Action<FetchOrganizationSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.organizationSettings,
  };
}

export function organizationSettingsAction(
  body: OrganizationSettingsActionProps,
): Action<OrganizationSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettings,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function basicInformationSettingsAction(
  body: BasicInformationSettingsActionProps,
): Action<OrganizationSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettings,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.BillingDetails,
    },
  };
}

export function preferencesSettingsAction(body: PreferencesSettingsActionProps): Action<OrganizationSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettings,
    body,
  };
}

export function organizationHolidaysSettingsAction(
  body: OrganizationHolidaysSettingsActionProps,
): Action<OrganizationSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettings,
    body,
  };
}

export function organizationSettingsAvatarAction({
  avatar,
}: OrganizationSettingsAddChangeAvatarActionProps): Action<OrganizationSettingsAddChangeAvatarResponse> {
  const formData = new FormData();

  formData.append('avatar', avatar);

  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettingsChangeAvatar,
    body: formData,
  };
}

export function organizationSettingsDeleteAvatarAction(): Action<OrganizationSettingsDeleteAvatarResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.organizationSettingsDeleteAvatar,
  };
}

export function fetchAutomationsSettingsAction(): Action<FetchAutomationsSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.automationsSettings,
  };
}

export function automationsSettingsAction(
  body: Partial<AutomationsSettingsActionProps>,
): Action<AutomationsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.automationsSettings,
    body,
  };
}

export function fetchRequestsSettingsAction(): Action<FetchRequestsSettingsResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.requestSettings,
  };
}

export function requestsSettingsAction(body: RequestsSettingsActionProps): Action<RequestsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestSettings,
    body,
  };
}

export function timeOffSettingsAction(body: RequestsTimeOffSettings): Action<RequestsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestSettings,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function requestsTimeTrackingSettingsAction(
  body: RequestsTimeTrackingSettings,
): Action<RequestsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestSettings,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function businessTripSettingsAction(body: RequestsBusinessTripSettings): Action<RequestsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestSettings,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function customSettingsAction(body: RequestsCustomSettings): Action<RequestsSettingsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.requestSettings,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function fetchUserInfoAction(): Action<FetchUserInfoResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.userInfo,
  };
}

export function userInfoAction(body: UserInfoActionProps): Action<UserInfoResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userInfo,
    body,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.FormSubmit,
    },
  };
}

export function userInfoChangeAvatarAction({
  avatar,
}: UserInfoChangeAvatarActionProps): Action<UserInfoChangeAvatarResponse> {
  const formData = new FormData();

  formData.append('avatar', avatar);

  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userInfoChangeAvatar,
    body: formData,
  };
}

export function userInfoDeleteAvatarAction(): Action<UserInfoDeleteAvatarResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.userInfoDeleteAvatar,
  };
}
