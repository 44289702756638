import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { resetAvailabilityAction } from 'api/actions/calendar/calendarActions';
import { useModal } from 'components/Modal/output/useModal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { isWorkdayAvailabilityAvailableSelector } from 'state/organizationSession';
import { userSessionPersonIdSelector } from 'state/userSession';
import { useRefreshCalendar } from '../../hooks/useRefreshCalendar';

export const ResetAvailabilityModal = (): React.ReactElement => {
  useLingui();

  const currentUserId = useRecoilValue(userSessionPersonIdSelector);
  const isWorkdayAvailabilityAvailable = useRecoilValue(isWorkdayAvailabilityAvailableSelector);
  const { baseRoute } = useModal();
  const { state } = useLocation();
  const { dates } = state || {};
  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar([currentUserId || '']);

  const titleRenderer = useCallback(() => t({ id: 'availability.reset_title', message: 'Reset availability' }), []);

  const contentRenderer = useCallback(
    () => (
      <Trans id="availability.reset_content">
        <Text>You're about to reset all your availability.</Text>
        <Text>
          <strong>This action can not be reverted!</strong>
        </Text>
      </Trans>
    ),
    [],
  );

  const onResponseCallback = useCallback(
    (error: boolean) => {
      if (!error) {
        if (calendarInitialized) void updateCalendarForIds();
      }
    },
    [calendarInitialized, updateCalendarForIds],
  );

  if (!dates || !currentUserId || !isWorkdayAvailabilityAvailable) return <Navigate to={baseRoute} relative="path" />;

  return (
    <ConfirmModal
      action={() => resetAvailabilityAction({ personId: currentUserId, timeRange: dates })}
      onResponseCallback={onResponseCallback}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
    />
  );
};
