import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useMemo } from 'react';
import { RecoilValueReadOnly, useRecoilValue, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';

import { RequestState } from 'api/actions/requests/requestsActions.types';
import { ExtendedTimeEvent, FraudDetectionState, TimeEventState } from 'api/actions/timeEvent/timeEventActions.types';
import { CustomRowVariant, ListColumn, ListNames, SortingOrder, StickyListProps } from 'components/StickyList/types';
import { CLOCK_LOG_LIST_HEADER_HEIGHT, CLOCK_LOG_LIST_ITEM_HEIGHT } from 'constants/clockLog';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { RequestDetailsGroupedParam } from 'pages/Requests/output/types';
import {
  ClockLogListType,
  ClockLogMap,
  clockLogColumnsAtom,
  filteredExtTimeEventsListTypeSelectorFamily,
} from 'state/clockLog';
import { nonEditableEmployeesForModulesIdsSelectorFamily } from 'state/employees';
import { languageSelector } from 'state/recoilState';
import { LazyComponentType } from 'utils/custom.types';

const LazyStickyList = React.lazy(() =>
  import('components/StickyList/StickyList').then(({ StickyList }) => ({
    default: StickyList,
  })),
) as unknown as LazyComponentType<StickyListProps<ExtendedTimeEvent>>;

export const dateTimeKey: ListColumn<ExtendedTimeEvent>['key'] = ['timeUtc', 'timeRealUtc'];

type Props<P> = {
  selectable?: boolean;
  clockLogListAtom?: P;
  columnsAtom?: typeof clockLogColumnsAtom;
  listType: ClockLogListType;
};

export const ClockLogList = <P extends RecoilValueReadOnly<ClockLogMap | null> = RecoilValueReadOnly<ClockLogMap>>({
  selectable,
  clockLogListAtom,
  columnsAtom = clockLogColumnsAtom,
  listType,
}: Props<P>): React.ReactElement | null => {
  const columns = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(columnsAtom);
  const extTimeEvents = useRecoilValue(clockLogListAtom || filteredExtTimeEventsListTypeSelectorFamily(listType));

  const nonEditableEmployeesIds = useRecoilValue(nonEditableEmployeesForModulesIdsSelectorFamily('TimeTracking'));

  const getFullName = useNameDisplayOrder();
  const language = useRecoilValue(languageSelector);
  const navigate = useAppNavigate();
  useLingui();

  const { modulesManagement } = useAppPermissions();

  const fallbackSortableValueGetter = useCallback(
    ({ employeeName: { firstName, surname } }: ExtendedTimeEvent) => getFullName(firstName, surname),
    [getFullName],
  );

  const handleOnRowClick: NonNullable<StickyListProps['onRowClick']> = useCallback(
    (id, { fraudDetectionState, personId, request, state }: ExtendedTimeEvent) => {
      if (!modulesManagement.TimeTracking) return;

      if (fraudDetectionState === FraudDetectionState.PotentialFraud && state !== TimeEventState.Deleted) {
        navigate(`${TO_REL.ANTI_SPOOFING_CHECK_MODAL__ID[language]}/${id}`, {
          state: { modifyTimeEvent: { personId } },
        });
        return;
      }

      if (request && modulesManagement.Requests) {
        navigate(
          `${TO_REL.REQUEST_DETAILS__GROUP__ID[language]}/${RequestDetailsGroupedParam.Ungrouped}/${request.id}`,
        );
        return;
      }

      if (fraudDetectionState === FraudDetectionState.ResolvedFraud && state !== TimeEventState.Deleted) {
        navigate(`${TO_REL.RECOVER_REJECTED_EVENT_MODAL__ID[language]}/${id}`, {
          state: { modifyTimeEvent: { personId } },
        });
        return;
      }

      navigate(`${TO_REL.EDIT_EVENT_MODAL__ID[language]}/${id}`);
    },
    [modulesManagement.TimeTracking, modulesManagement.Requests, navigate, language],
  );

  const isRowUncheckableValidator = useCallback(
    (id: string) => {
      if (!extTimeEvents) return false;

      const extTimeEvent = extTimeEvents.get(id);
      if (!extTimeEvent) return false;

      const { personId } = extTimeEvent;
      return !!nonEditableEmployeesIds?.includes(personId);
    },
    [extTimeEvents, nonEditableEmployeesIds],
  );

  const customRowVariantGenerator = useCallback(
    (id: string) => {
      if (!extTimeEvents) return undefined;

      const extTimeEvent = extTimeEvents.get(id);

      if (!extTimeEvent) return undefined;

      const { requestState, fraudDetectionState } = extTimeEvent;

      if (requestState === RequestState.Pending) return CustomRowVariant.pending;

      if (fraudDetectionState === FraudDetectionState.PotentialFraud) return CustomRowVariant.error;

      return undefined;
    },
    [extTimeEvents],
  );

  const checkIfSelectable = useMemo(() => {
    if (selectable) {
      return 'checkbox';
    }

    return undefined;
  }, [selectable]);

  return columns && extTimeEvents ? (
    <LazyStickyList
      name={ListNames.CLOCK_LOG}
      list={extTimeEvents || new Map()}
      columns={columns}
      showHeader
      select={checkIfSelectable}
      fallbackSortableValueGetter={fallbackSortableValueGetter}
      onRowClick={handleOnRowClick}
      isRowUncheckableValidator={isRowUncheckableValidator}
      emptyListMessage={t({ id: 'empty_list' })}
      style={{
        paddingRight: 24,
        paddingBottom: 48,
      }}
      mobileWidth={1024}
      customRowVariantGenerator={customRowVariantGenerator}
      withRowContextMenu
      defaultSortingState={{
        order: SortingOrder.DESC,
        columnKey: dateTimeKey,
      }}
      dimensions={{
        height: CLOCK_LOG_LIST_ITEM_HEIGHT,
      }}
      headerSx={{
        maxHeight: CLOCK_LOG_LIST_HEADER_HEIGHT,
        alignItems: 'flex-end',
        pb: 3,
      }}
    />
  ) : (
    <></>
  );
};
