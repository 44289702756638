import React, { useEffect } from 'react';

import { Onboarding } from 'base/Onboarding/output/Onboarding';
import { removeAllSnackbars } from 'base/Snackbar/output/actions';
import { AppPermissionRedirectObserver } from 'observers/AppPermissionRedirectObserver';
import { DataSideEffectsObserver } from 'observers/DataSideEffectsObserver';
import { RefreshSessionObserver } from 'observers/RefreshSessionObserver';
import { UpdateCaledarObserver } from 'observers/UpdateCalendarObserver';
import { AuthorizedAppRoutes } from 'routing/AuthorizedAppRoutes';

import { AuthorizedAppContext } from './AuthorizedAppContext';

export const AuthorizedApp = (): React.ReactElement => {
  useEffect(() => () => removeAllSnackbars(), []);

  return (
    <AuthorizedAppContext>
      <RefreshSessionObserver />
      <DataSideEffectsObserver />
      <AppPermissionRedirectObserver />
      <AuthorizedAppRoutes />
      <Onboarding />
      <UpdateCaledarObserver />
    </AuthorizedAppContext>
  );
};
