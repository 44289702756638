import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, Fragment } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { editTeamListViewSettingAction } from 'api/actions/employees/employeesActions';
import { TeamHideableColumns } from 'api/actions/userSession/userSessionActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { ElementGroup } from 'components/ui/ElementGroup';
import { Switch, SwitchProps } from 'components/ui/Switch';
import { HOSTNAME, REACT_APP_HOSTNAME } from 'constants/common';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { hiddenColumnsTeamViewSelector } from 'state/team';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

type SettingSwitchType = {
  id: TeamHideableColumns;
} & Partial<SwitchProps>;

export const ViewSettingsModal: FC = () => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();
  const hiddenColumns = useRecoilValue(hiddenColumnsTeamViewSelector);
  const isInewiHostname = HOSTNAME === REACT_APP_HOSTNAME.inewi;

  const {
    register,
    formState: { isSubmitting, isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: {
      [TeamHideableColumns.roleAndTags]: !hiddenColumns.includes(TeamHideableColumns.roleAndTags),
      [TeamHideableColumns.workPosition]: !hiddenColumns.includes(TeamHideableColumns.workPosition),
      [TeamHideableColumns.email]: !hiddenColumns.includes(TeamHideableColumns.email),
      [TeamHideableColumns.phoneNumber]: !hiddenColumns.includes(TeamHideableColumns.phoneNumber),
      [TeamHideableColumns.customEmployeeId]: !hiddenColumns.includes(TeamHideableColumns.customEmployeeId),
      ...(isInewiHostname
        ? {
            [TeamHideableColumns.identity]: !hiddenColumns.includes(TeamHideableColumns.identity),
          }
        : {}),
    },
  });

  const { mutate } = useMutation(editTeamListViewSettingAction);

  const handleSave = handleSubmit(async (data) => {
    const newHiddenColumns = Object.entries(data).reduce<string[]>(
      (acc, [key, value]) => (!value ? [...acc, key] : acc),
      [],
    );
    const { error: submitError } = await mutate({
      hiddenColumns: newHiddenColumns,
    });
    if (!submitError) {
      handleClose?.();
      addSnackbar({
        message: t({ id: 'team.view_settings.edited', message: 'Successfully edited!' }),
        variant: 'success',
      });
    }
  });

  const settingSwitches: SettingSwitchType[] = [
    {
      id: TeamHideableColumns.roleAndTags,
      label: t({ id: 'team.view_settings.show_role_tags', message: 'Show role & tags' }),
    },
    {
      id: TeamHideableColumns.workPosition,
      label: t({ id: 'team.view_settings.show_position', message: 'Show position' }),
    },
    {
      id: TeamHideableColumns.email,
      label: t({ id: 'team.view_settings.show_email', message: 'Show e-mail' }),
    },
    {
      id: TeamHideableColumns.phoneNumber,
      label: t({ id: 'team.view_settings.phone_no', message: 'Show phone number' }),
    },
    {
      id: TeamHideableColumns.customEmployeeId,
      label: t({ id: 'team.view_settings.empl_id', message: 'Show employee ID' }),
    },
    ...(isInewiHostname
      ? [
          {
            id: TeamHideableColumns.identity,
            label: t({ id: 'team.view_settings.identity', message: 'Show employee personal identity number' }),
          },
        ]
      : []),
  ];

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.view_settings">View settings</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ElementGroup showAsList marginValue="0.5rem" direction="column">
          {settingSwitches.map(({ id, ...switchProps }) => (
            <Fragment key={id}>
              <Switch size="sm" bold {...switchProps} {...register(id)} />
            </Fragment>
          ))}
        </ElementGroup>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: isSubmitting,
            disabled: !isDirty,
            onClick: floatingPromiseReturn(handleSave),
            variant: 'primary',
            children: t({ id: 'save', message: 'Save' }),
          },
        ]}
      />
    </>
  );
};
