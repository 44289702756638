import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { StickyList } from 'components/StickyList/StickyList';
import { ListVariant, StickyListProps } from 'components/StickyList/types';
import { Button, LinkButton, LinkButtonProps } from 'components/ui/Buttons';
import { ElementGroup } from 'components/ui/ElementGroup';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { FilterSearchInput } from 'layouts/AuthorizedApp/AsideFilters/FilterSearchInput';
import { SEARCH_FILTER_TYPE, searchFilterValueAtomFamily } from 'state/filters';
import { selectAllAtomFamily, selectedRowsAtomFamily, selectedRowsIdsSelectorFamily } from 'state/list';
import { useTheme } from 'styles/useTheme';

type Props = {
  title: string;
  modalLinksPaths: {
    add: string;
    delete: string;
  };
  listPropsGenerator: (
    replace: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => StickyListProps<any>;
  // modalRoutesRenderer: (match: string) => React.ReactNode;
  restrictedIds?: string[];
  allResources: Map<string, { id: string }> | unknown[] | null;
  searchFilterType?: SEARCH_FILTER_TYPE;
  addButtonProps?: Partial<LinkButtonProps>;
  headerLayout?: 'left' | 'right';
  headerSlot?: React.ReactElement;
};

export type ManageModalProps = Props;

const defaultProps = {
  restrictedIds: undefined,
  searchFilterType: undefined,
  addButtonProps: undefined,
  headerLayout: 'left',
  headerSlot: undefined,
};

export const ManageModal = ({
  title,
  restrictedIds,
  listPropsGenerator,
  modalLinksPaths,
  allResources,
  searchFilterType,
  addButtonProps,
  headerLayout,
  headerSlot,
}: Props): React.ReactElement => {
  useLingui();
  const { replaceMode: replace, baseRoute } = useModal();
  const { theme } = useTheme();
  const navigate = useAppNavigate();

  const resetSearchFilterValueState = useResetRecoilState(searchFilterValueAtomFamily(searchFilterType));

  const listProps = useMemo(() => listPropsGenerator(replace), [listPropsGenerator, replace]);

  const selectedIds = useRecoilValue(selectedRowsIdsSelectorFamily(listProps.name));
  const resetSelectedIds = useResetRecoilState(selectedRowsAtomFamily(listProps.name));
  const resetGlobalSelect = useResetRecoilState(selectAllAtomFamily(listProps.name));

  useUnmount(() => {
    resetSelectedIds();
    resetGlobalSelect();
    resetSearchFilterValueState();
  });

  const filteredSelectedIds = useMemo(
    () =>
      _.difference(selectedIds, restrictedIds || []).filter((id) => {
        if (Array.isArray(allResources)) return !allResources?.includes(id);
        return !!allResources?.get(id);
      }),
    [selectedIds, restrictedIds, allResources],
  );

  const handleDelete = () => {
    navigate(modalLinksPaths.delete, {
      replace,
      state: {
        ids: filteredSelectedIds,
      },
    });
  };

  if (!listProps.list) return <Navigate to={baseRoute} relative="path" />;

  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body sx={{ pt: 0, display: 'flex', flexDirection: 'column' }}>
        <ElementGroup
          showAsList
          marginValue={headerLayout === 'left' ? 1 : '0.125rem'}
          wrapperSx={{
            ...(searchFilterType && {
              '& li:last-of-type': { ml: 'auto', pl: 2 },
            }),
            ...(headerSlot && {
              '& li:nth-last-of-type(2)': { ml: 2 },
            }),
            ...(headerLayout === 'right' && { ml: 'auto' }),
          }}
        >
          <LinkButton
            replace={replace}
            size="sm"
            shape="rounded"
            prependWith={<Icon type="plus" />}
            to={modalLinksPaths.add}
            {...(addButtonProps && addButtonProps)}
          >
            <Trans id="team.manage_modal.add">Add</Trans>
          </LinkButton>

          <Button
            disabled={!filteredSelectedIds.length}
            size="sm"
            variant="grey"
            shape="rounded"
            prependWith={<Icon type="delete" />}
            onClick={handleDelete}
          >
            <Trans id="team.manage_modal.delete">Delete</Trans>
          </Button>

          {headerSlot}

          {searchFilterType && (
            <FilterSearchInput
              size="xs"
              placeholder={t({ id: 'search_bar', message: 'Search' })}
              type={searchFilterType}
              id={searchFilterType}
            />
          )}
        </ElementGroup>

        <Flex sx={{ flex: '1 0', mx: -4, mb: '-.9rem' }}>
          <StickyList
            {...listProps}
            variant={listProps.variant || ListVariant.inverted}
            style={{ ...listProps.style, padding: theme.space[4], paddingTop: 0 }}
          />
        </Flex>
      </Modal.Body>
    </>
  );
};

ManageModal.defaultProps = defaultProps;
