import React, { useMemo } from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';
import dayjs from 'dayjs';

import { WeekdaysProps } from './types';

const weekdaySx: ThemeUIStyleObject = {
  color: 'datePicker.calendar.weekday',
};

export const Weekdays = React.memo(({ variant }: WeekdaysProps): React.ReactElement => {
  const weekdays = useMemo(() => dayjs.weekdaysShort(true), []);

  return (
    <Flex
      sx={{
        // flex: '1 0 0 ',
        justifyContent: 'center',
      }}
    >
      {weekdays.map((weekday) => (
        <Flex key={weekday} variant={`forms.calendar.${variant}.day`} sx={weekdaySx}>
          {weekday}
        </Flex>
      ))}
    </Flex>
  );
});
