import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

type ProgressBarProps = Omit<FlexProps, 'children'> & {
  steps: number;
  completed: number;
};

export const ProgressBar = ({ completed, steps, sx, ...props }: ProgressBarProps): React.ReactElement => {
  const progressWidth = `${(completed * 100) / steps}%`;

  return (
    <Flex
      {...props}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '4px',
        overflow: 'hidden',
        bg: 'progressBar.background',
        ...(sx && sx),
      }}
    >
      <Flex
        style={{
          width: progressWidth,
        }}
        sx={{
          transition: 'width 0.25s ease-in-out',
          height: '100%',
          width: 0,
          bg: 'progressBar.progress',
        }}
      />
    </Flex>
  );
};
