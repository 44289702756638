import { Trans } from '@lingui/macro';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { AnchorButton, LinkButton } from 'components/ui/Buttons';
import { Container } from 'components/ui/Container';
import { Image } from 'components/ui/Image';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { TO } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { images } from 'Kiosk/constants/images';
import { useCheckPermissions } from 'Kiosk/hooks/useCheckPermissions';
import { PermissionState, permissionStateAtom } from 'Kiosk/state/permissionState';
import { languageSelector } from 'state/recoilState';

const IMAGE_WIDTH_HEIGHT = '256px';

export const CameraRequiredErrorPage = (): React.ReactElement => {
  const { camera } = useCheckPermissions({ intervalTime: 1000 });

  const navigate = useAppNavigate();

  const language = useRecoilValue(languageSelector);
  const [, setPermissions] = useRecoilState(permissionStateAtom);

  useEffect(() => {
    if (camera === PermissionState.allow) {
      setPermissions((prevState) => ({ ...prevState, camera }));
      navigate(TO.KIOSK[language]);
    }
  }, [camera, language, navigate, setPermissions]);

  return (
    <Container size="sm" sx={{ textAlign: 'center', alignItems: 'center', alignSelf: 'center' }}>
      <Image
        width={IMAGE_WIDTH_HEIGHT}
        alt="Camera"
        src={images.camera['1x']}
        srcSet={`${images.camera['2x']}, ${images.camera['3x']}`}
        sx={{ minHeight: IMAGE_WIDTH_HEIGHT }}
      />
      <Text variant="heading2" as="h2">
        <Trans id="kiosk.home.camera_permission_modal.title">Camera access is required</Trans>
      </Text>
      <Text variant="pLead">
        <Trans id="kiosk.home.camera_permission_modal.message">
          You need to allow app the use of camera in order to identify QR codes, face-recognition (optional) and make
          photo-logs.
        </Trans>
      </Text>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center', mt: 3, gap: 2 }}>
        <AnchorButton
          href={EXTERNAL_LINKS.CAMERA_HELP}
          target="_blank"
          variant="primary"
          size="lg"
          shape="pill"
          fullWidth
        >
          <Trans id="kiosk.home.camera_permission_modal.how_to_enable">Check how to enable camera</Trans>
        </AnchorButton>
        <LinkButton to="/" variant="minimal" shape="pill" size="lg">
          <Trans id="global.go_back_to_app">Go back to the app</Trans>
        </LinkButton>
      </Flex>
    </Container>
  );
};
