import { useRecoilValue } from 'recoil';

import { appPermissionsSelector } from 'state/appPermissions';

/**
 * Retrieves an object with app permissions from global state.
 *
 * @return {AppPermissionSelector} Returns a object with app permissions.
 *
 * @see appPermissionsSelector
 */

export const useAppPermissions = () => {
  const permissions = useRecoilValue(appPermissionsSelector);

  return permissions;
};
