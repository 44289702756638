import _ from 'lodash';
import { Flex } from 'theme-ui';

import { MemorizedRow } from '../Row';
import { GUTTER_DEFAULT_SIZE } from '../constants';
import { ListRendererProps } from '../types';

export const rowRenderer = ({ data, style, index, isScrolling, ...props }: ListRendererProps): JSX.Element | null => {
  const {
    showHeader,
    columns,
    name,
    select,
    list,
    itemCount,
    onRowClick,
    variant,
    customRowVariantGenerator,
    rowTooltipGenerator,
    isRowUncheckableValidator,
    withRowContextMenu,
  } = data;
  const rowData = _.isArray(list) ? list[index] : list.get(`${index}`);

  if (!rowData) return null;

  let innerStyle = style;

  if (showHeader) {
    innerStyle = {
      ...innerStyle,
      top: parseInt(`${innerStyle.top}`, 10) + parseInt(`${innerStyle.height}`, 10),
    };
  }

  innerStyle = {
    ...innerStyle,
    height: parseInt(`${innerStyle.height}`, 10) - GUTTER_DEFAULT_SIZE,
    marginBottom: GUTTER_DEFAULT_SIZE,
  };

  const isFirst = index + 1 === 1;
  const isLast = index + 1 === itemCount;

  const customRowVariant = customRowVariantGenerator?.(rowData.id);

  return (
    <Flex style={innerStyle}>
      <MemorizedRow
        {...props}
        id={rowData.id}
        data={rowData}
        listName={name}
        columns={columns}
        select={select}
        data-first={isFirst}
        data-last={isLast}
        onRowClick={onRowClick}
        variant={customRowVariant || variant}
        {...(rowTooltipGenerator && { tooltip: rowTooltipGenerator(rowData.id) })}
        {...(isRowUncheckableValidator && { uncheckable: isRowUncheckableValidator(rowData.id) })}
        withRowContextMenu={withRowContextMenu}
      />
    </Flex>
  );
};
