import { useEffect, useState } from 'react';

import { GOOGLE_CLIENT_ID } from 'constants/common';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useIsMountedRef } from 'hooks/useIsMountedRef/useIsMountedRef';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { setIntervalX } from 'utils/setIntervalX';

import { CredentialResponse, Google } from './google.types';

const cachedScripts: unknown[] = [];

export const useGoogleScript = (
  responseCallback: (credentialResponse: CredentialResponse) => Promise<void>,
): Google | undefined => {
  const [googleScript, setGoogleScript] = useState<Google | undefined>();
  const mountedRef = useIsMountedRef();
  const responseCallbackRef = useCallbackRef(responseCallback);

  useEffect(() => {
    const src = process.env.REACT_APP_GOOGLE_SCRIPT || '';
    cachedScripts.push(src);

    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    const onScriptLoad = () => {
      if (mountedRef.current) {
        const setScript = () => {
          if (typeof window !== 'undefined' && window.google) {
            setGoogleScript(window.google as unknown as Google);

            return true;
          }

          return false;
        };

        const scriptState = setScript();

        if (scriptState) return;

        setIntervalX(setScript, 100, 15);
      }
    };

    const onScriptError = () => {
      const index = cachedScripts.indexOf(src);

      if (index >= 0) {
        cachedScripts.splice(index, 1);
      }

      script.remove();
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener('load', onScriptError);
      script.removeEventListener('error', onScriptError);
      document.body.removeChild(script);
    };
  }, [googleScript, mountedRef, responseCallbackRef]);

  useEffect(() => {
    if (googleScript) {
      googleScript.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        cancel_on_tap_outside: false,
        callback: floatingPromiseReturn(responseCallbackRef.current),
      });
    }
  }, [googleScript, responseCallbackRef]);

  return googleScript;
};
