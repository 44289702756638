import { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ExportDataType } from 'api/actions/export/exportActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { ADD_EVENT_MODAL_WIDTH } from 'constants/clockLog';
import { ADD_REQUEST_MODAL_WIDTH } from 'constants/requests';
import { BASE_ROUTES, PATH_REL } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { ShowLocationModal } from 'pages/Locations/output/ShowLocationModal';
import { DownloadPrintModal } from 'pages/Reports/output/DownloadPrintModal';
import { AddRequestModal } from 'pages/Requests/output/AddRequestModal';
import { RejectRequestModal } from 'pages/Requests/output/RejectRequestModal';
import { RequestDetailsNestedModalRoute } from 'pages/Requests/output/RequestDetailsNestedModalRoute';
import { EditTeammateModalWithDefaultModalWrapper } from 'pages/Team/output/EditTeammate';
import {
  ExtTimeEventsSelectorListType,
  selectedClockLogIdsSelector,
  selectedPhotoLogIdsSelector,
} from 'state/clockLog';
import { languageSelector } from 'state/recoilState';

import { AddEventNoteModal } from './components/modals/AddEventNote/AddEventNote';
import { AddEventsModal } from './components/modals/AddEvents/AddEvents';
import { AntiSpoofingCheckModal } from './components/modals/AntiSpoofingCheck';
import { DeleteEventsModal } from './components/modals/DeleteEvents';
import { EditEventModal } from './components/modals/EditEvent/EditEvent';
import { PhotoLogDetailsModal } from './components/modals/PhotoLogDetails/PhotoLogDetails';
import { RecoverRejectedEventModal } from './components/modals/RecoverRejectedEvent/RecoverRejectedEvent';
import { RemoveEvents } from './components/modals/RemoveEvents';
import { ShowEventHistoryModal } from './components/modals/ShowEventHistory';
import { ShowEventPhotoModal } from './components/modals/ShowEventPhoto';

const PHOTO_LOG_MODAL_WIDTH = '890px';
const PHOTO_LOG_MODAL_HEIGHT = '700px';

type Props = {
  excludeRequestRoutes?: boolean;
  eventListType?: ExtTimeEventsSelectorListType;
};

export const ModalRoutes: FC<Props> = ({ eventListType, excludeRequestRoutes }) => {
  const language = useRecoilValue(languageSelector);

  const { isClockLogPhotoLog } = useAppRouting(['isClockLogPhotoLog']);
  const { isInitialized, modulesManagement } = useAppPermissions();

  return (
    <Routes>
      <Route
        element={
          isInitialized && !modulesManagement.TimeTracking ? (
            <Navigate to={BASE_ROUTES.PAGE_NOT_FOUND[language]} />
          ) : (
            <Outlet />
          )
        }
      >
        <Route
          path={PATH_REL.ADD_EVENTS_MODAL[language]}
          element={
            <Modal fullHeight showMinimize sx={{ width: ADD_EVENT_MODAL_WIDTH }}>
              <AddEventsModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.DELETE_EVENTS_MODAL[language]}
          element={
            <Modal size="xs">
              <DeleteEventsModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.EDIT_EVENT_MODAL__ID[language]}
          element={
            <Modal size="default" path={PATH_REL.EDIT_EVENT_MODAL__ID[language]}>
              <EditEventModal />
            </Modal>
          }
        />
      </Route>

      <Route
        path={PATH_REL.SHOW_EVENT_PHOTO_MODAL__ID[language]}
        element={
          <Modal size="default" path={PATH_REL.SHOW_EVENT_PHOTO_MODAL__ID[language]}>
            <ShowEventPhotoModal {...(!!eventListType && { eventListType })} />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.SHOW_LOCATION_MODAL__ID[language]}
        element={
          <Modal size="default" path={PATH_REL.SHOW_LOCATION_MODAL__ID[language]}>
            <ShowLocationModal {...(!!eventListType && { eventListType })} />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.SHOW_EVENT_HISTORY_MODAL[language]}
        element={
          <Modal size="sm">
            <ShowEventHistoryModal {...(!!eventListType && { eventListType })} />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.ADD_EVENT_NOTE_MODAL__ID[language]}
        element={
          <Modal size="sm" path={PATH_REL.ADD_EVENT_NOTE_MODAL__ID[language]}>
            <AddEventNoteModal />
          </Modal>
        }
      />

      <Route
        path={PATH_REL.PHOTO_LOG_DETAILS_MODAL__USER__DATE[language]}
        element={
          <Modal
            sx={{
              width: PHOTO_LOG_MODAL_WIDTH,
              height: PHOTO_LOG_MODAL_HEIGHT,
            }}
            path={PATH_REL.PHOTO_LOG_DETAILS_MODAL__USER__DATE[language]}
          >
            <PhotoLogDetailsModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.DOWNLOAD_MODAL__EXTENSION[language]}
        element={
          <Modal size="sm" path={PATH_REL.DOWNLOAD_MODAL__EXTENSION[language]}>
            <DownloadPrintModal
              requireIdsSelection
              dataType={ExportDataType.TimeEvent}
              selectedIdsRecoilState={isClockLogPhotoLog ? selectedPhotoLogIdsSelector : selectedClockLogIdsSelector}
              view={isClockLogPhotoLog ? 'photoLog' : 'clockLog'}
              variant="download"
            />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.ANTI_SPOOFING_CHECK_MODAL__ID[language]}
        element={
          <Modal path={PATH_REL.ANTI_SPOOFING_CHECK_MODAL__ID[language]}>
            <AntiSpoofingCheckModal updateAntiSpoofingChecks={eventListType === 'antiSpoofingChecks'} />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.RECOVER_REJECTED_EVENT_MODAL__ID[language]}
        element={
          <Modal path={PATH_REL.RECOVER_REJECTED_EVENT_MODAL__ID[language]}>
            <RecoverRejectedEventModal />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.DELETE_PERMANENTLY_EVENT_MODAL[language]}
        element={
          <Modal size="xs">
            <RemoveEvents />
          </Modal>
        }
      />
      <Route
        path={PATH_REL.EDIT_TEAMMATE_MODAL__ID[language]}
        element={<EditTeammateModalWithDefaultModalWrapper path={PATH_REL.EDIT_TEAMMATE_MODAL__ID[language]} />}
      />

      {!excludeRequestRoutes && (
        <>
          <Route
            path={PATH_REL.ADD_REQUEST_MODAL[language]}
            element={
              <Modal fullHeight sx={{ width: ADD_REQUEST_MODAL_WIDTH }}>
                <AddRequestModal />
              </Modal>
            }
          />
          <Route
            path={PATH_REL.REJECT_REQUESTS_MODAL[language]}
            element={
              <Modal size="xs">
                <RejectRequestModal />
              </Modal>
            }
          />
          <Route
            path={PATH_REL.REQUEST_DETAILS__GROUP__ID[language]}
            element={
              <Modal size="sm" fullHeight path={PATH_REL.REQUEST_DETAILS__GROUP__ID[language]}>
                <RequestDetailsNestedModalRoute />
              </Modal>
            }
          />
        </>
      )}
    </Routes>
  );
};
