import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useParams } from 'react-router-dom';

import { editWebhookAction } from 'api/actions/webhook/webhookActions';
import { AddWebhookActionProps, Webhook } from 'api/actions/webhook/webhooksActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { createEvent } from 'utils/createEvent';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useWebhooks } from '../../hooks/useWebhooks';
import { AddEditWebhookForm } from '../AddEditWebhookForm';

export const EditWebhookModal = (): React.ReactElement => {
  useLingui();
  const { id } = useParams() as { id: string };
  const { handleClose } = useModal();
  const { mutate } = useMutation(editWebhookAction);
  const { fetchWebhooks, webhooks } = useWebhooks();
  const webhook: Webhook | undefined = _.find(webhooks, ['id', id]);

  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (data: AddWebhookActionProps | Webhook) => {
      if (_.isEqual(data, _.omit(webhook, 'id'))) {
        addSnackbar({
          message: t({
            id: 'settings.webhook.edit_default',
            message: 'No changes to save!',
          }),
          variant: 'default',
        });
        return;
      }

      const { error: submitError } = await mutate({ ...data, id });

      if (!submitError) {
        if (handleClose) {
          addSnackbar({
            message: t({
              id: 'settings.webhook.edit_success',
              message: 'Edited webhook',
            }),
            variant: 'success',
          });
          await fetchWebhooks();
          handleClose();
        }
      }

      setLoading(false);
    },
    [handleClose, id, mutate, fetchWebhooks, webhook],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="settings.webhook.edit">Edit Webhook</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {id && !webhook ? (
          <LoadingSpinnerCss size={4} />
        ) : (
          <AddEditWebhookForm
            ref={formRef}
            onSubmit={floatingPromiseReturn(onSubmit)}
            setLoading={setLoading}
            defaultValues={
              webhook && {
                type: webhook.type,
                url: webhook.url,
                token: webhook.token,
              }
            }
          />
        )}
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};
