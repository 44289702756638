import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Flex } from 'theme-ui';
import _ from 'lodash';

import { isActiveFilterSelectorFamily } from 'state/filters';
import { Checkbox } from 'components/ui/Checkbox';

import { FilterGroupNames } from './types';

type Props = {
  name: FilterGroupNames;
  title: string;
  filterId: string;
};

export const FilterCheckbox = React.forwardRef<HTMLInputElement, Props>(
  ({ name, title, filterId, ...props }: Props, ref): React.ReactElement => {
    const [isActive, setIsActive] = useState<boolean | null>(null);
    const [isFilterActive, setFilterIsActive] = useRecoilState(
      isActiveFilterSelectorFamily({
        groupName: name,
        filterId,
      }),
    );

    const throttledSetter = useMemo(
      () =>
        _.throttle(
          () => {
            if (isActive !== null) {
              setFilterIsActive(isActive);
            }
          },
          700,
          { leading: false },
        ),
      [isActive, setFilterIsActive],
    );

    useEffect(() => {
      if (isActive === null) {
        setIsActive(isFilterActive);
      } else if (isActive !== isFilterActive) {
        throttledSetter();
      }
    }, [isActive, isFilterActive, throttledSetter]);

    const handleClick = useCallback(() => {
      setIsActive(!isActive);
    }, [isActive]);

    useEffect(
      () => () => {
        throttledSetter.cancel();
      },
      [throttledSetter],
    );

    return (
      <Flex
        sx={{
          fontSize: 2,
          flexDirection: 'column',
          borderRadius: 'sm',
          p: 2,
          transition: 'background 0.1s ease-in-out',
          '&:hover': {
            bg: 'asideFilters.checkbox',
          },
        }}
        {...props}
      >
        <Checkbox
          ref={ref}
          name={name}
          label={title}
          sx={{
            gap: 2,
          }}
          labelWrapperSx={{
            fontSize: 'inherit',
          }}
          onChange={handleClick}
          checked={!!isActive}
        ></Checkbox>
      </Flex>
    );
  },
);
