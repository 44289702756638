import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { ComponentType, FC, ReactElement, Suspense, lazy, useCallback, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useMutation } from 'react-fetching-library';
import { Navigate, useParams } from 'react-router-dom';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { editEmployeeDetailsAction } from 'api/actions/employees/employeesActions';
import { FetchEmployeeDetailsResponse } from 'api/actions/employees/employeesActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Modal } from 'components/Modal/output/Modal';
import { ModalProps } from 'components/Modal/output/types';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { usePromisify } from 'hooks/usePromisify/usePromisify';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { useRefreshRequestsUsageOverview } from 'pages/Requests/output/useRefreshRequestsUsageOverview';
import {
  EmployeeAdvancedDetailsFormState,
  EmployeeEmploymentDetailsFormState,
  EmployeeRequestLimitsFormState,
  PrivateNotesFormState,
  ProfileFormState,
} from 'state/team';
import { createEvent } from 'utils/createEvent';
import { CustomEvents } from 'utils/customEvents';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { UserAdvancedDetails } from '../../forms/UserAdvancedDetails';
import { UserOther } from '../../forms/UserOther';
import { UserPrivateNotes } from '../../forms/UserPrivateNotes';
import { AvatarInfo } from '../../forms/UserProfile/UserProfile';
import { UserRequestLimits } from '../../forms/UserRequestLimits';

import { ModalRoutes } from './ModalRoutes';
import { Navigation } from './components/Navigation';
import { useAppState } from './hooks/useAppState';
import { useAvatarInfo } from './hooks/useAvatarInfo';
import { useEmployeeDetails } from './hooks/useEmployeeDetails';
import { useNavigation } from './hooks/useNavigation';
import { useTagInheritedFeatures } from './hooks/useTagInheritedFeatures';
import { TabName } from './types';

const LazyUserProfile = lazy(() =>
  import('../../forms/UserProfile/UserProfile').then(({ UserProfile }) => ({
    default: UserProfile,
  })),
);
const LazyUserEmploymentDetails = lazy(() =>
  import('../../forms/UserEmploymentDetails').then(({ UserEmploymentDetails }) => ({
    default: UserEmploymentDetails,
  })),
);

export const EditTeammateModal: FC = () => {
  useLingui();

  const { isHeadAdmin } = useAppPermissions();

  const [loading, setLoading] = useState(false);

  const { handleClose, baseRoute } = useModal({
    closeOnBackdrop: false,
  });
  const { id } = useParams() as { id: string };

  const { calendarInitialized, updateCalendarForIds } = useRefreshCalendar([id]);
  const { reportInitialized, updateReportForIds } = useRefreshReport([id]);
  const { updateRequestsUsageOverviewNoThrottle, requestsUsageOverviewInitialized } = useRefreshRequestsUsageOverview();
  const { isAppReady, isEmployeeEditable, appStateEmployeeDetails } = useAppState(id);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const { modalBodySx, bodyLayoutSx, wrapperSx, isMobileBreakpoint } = useStyles();

  const {
    fetchEmployeeDetailsResponseRef,
    editedEmployeeDetails,
    initialRequestLimitsFormStateRef,
    initialEmploymentDetailsFormStateRef,
    profileFormState,
    setProfileFormState,
    advancedDetailsFormState,
    setAdvancedDetailsFormState,
    employmentDetailsFormState,
    setEmploymentDetailsFormState,
    requestLimitsFormState,
    setRequestLimitsFormState,
    privateNotesFormState,
    setPrivateNotesFormState,
  } = useEmployeeDetails(id, handleClose);

  const { savedAvatarInfo, setSavedAvatarInfo, savedAvatarInfoRef } = useAvatarInfo();

  const { isFetchingTagInheritedFeatures, tagInheritedFeaturesResponse, getTagInheritedFeatures } =
    useTagInheritedFeatures();

  const modalContentRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useCustomEventListener(CustomEvents.GET_MAIL, {
    callback: ({ callback, email }: { callback?: (email?: string) => void; email?: string }) => {
      callback?.(profileFormState?.email);
      if (email) setProfileFormState((prevState) => (prevState ? { ...prevState, email } : null));
    },
    priority: 1,
  });

  const [promisifySubmitForm, resolveSubmitForm] = usePromisify<boolean>();

  const submitCurrentForm = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }

    return promisifySubmitForm();
  }, [promisifySubmitForm]);

  const tabChangeValidator = useCallback(
    async (currentTabName?: TabName, nextTabName?: TabName) => {
      const validChange = formRef.current ? await submitCurrentForm() : true;

      if (!validChange) {
        addSnackbar({
          message: t({
            id: 'team.edit_teammate.resolve_errors',
            message: 'Correct any errors before switching tabs.',
          }),
          variant: 'warning',
        });
      } else if (nextTabName === TabName.Advanced) {
        if (!tagInheritedFeaturesResponse && !isFetchingTagInheritedFeatures && profileFormState?.tagsIds.length) {
          await getTagInheritedFeatures(profileFormState.tagsIds);
        }
      }
      return !!validChange;
    },
    [
      submitCurrentForm,
      profileFormState,
      getTagInheritedFeatures,
      isFetchingTagInheritedFeatures,
      tagInheritedFeaturesResponse,
    ],
  );

  const [currentTab, setCurrentTab] = useNavigation(tabChangeValidator, isMobileBreakpoint);

  useEffect(() => modalContentRef.current?.scrollTo(0, 0), [currentTab]);

  const { mutate } = useMutation(editEmployeeDetailsAction);

  function onSubmitFactory<T>(setter: (v: T) => void) {
    // eslint-disable-next-line @typescript-eslint/require-await
    return async (body: T) => {
      setter(body);
      resolveSubmitForm(true);
      return true;
    };
  }

  const onProfileSubmit = async (body: ProfileFormState, avatarInfo?: AvatarInfo): Promise<boolean> => {
    if (avatarInfo) {
      const alreadyChanged = !!savedAvatarInfo?.changed;
      flushSync(() => {
        setSavedAvatarInfo({ ...avatarInfo, changed: alreadyChanged || avatarInfo.changed });
      });
    }

    return onSubmitFactory<ProfileFormState>(setProfileFormState)(body);
  };

  const submitEmployeeDetails = async (employeeDetails: FetchEmployeeDetailsResponse) => {
    // DO NOT REMOVE BELOW COMMENT
    // console.log({
    //   employeeId: id,
    //   employee: { ...employeeDetails, avatarChanged: !!savedAvatarInfoRef.current?.changed },
    //   avatar: savedAvatarInfoRef.current?.avatarBlob || undefined,
    // });
    const { error: submitError } = await mutate({
      employeeId: id,
      employee: { ...employeeDetails, avatarChanged: !!savedAvatarInfoRef.current?.changed },
      avatar: savedAvatarInfoRef.current?.avatarBlob || undefined,
    });

    return !submitError;
  };

  const editedEmployeeDetailsRef = useCallbackRef(editedEmployeeDetails);

  const handleSubmit = async () => {
    const employeeDetails = editedEmployeeDetailsRef.current;
    if (!employeeDetails) return false;

    const oldEmployeeDetails = fetchEmployeeDetailsResponseRef.current;
    if (
      oldEmployeeDetails &&
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      detailsHaveChanges(employeeDetails, oldEmployeeDetails) &&
      !savedAvatarInfoRef.current?.changed
    ) {
      return false;
    }

    const successfullySubmitted = await submitEmployeeDetails(employeeDetails);

    if (successfullySubmitted) {
      handleClose();
      addSnackbar({
        message: t({ id: 'team.edit_teammate.edited', message: 'Employee successfully edited!' }),
        variant: 'success',
      });
    }

    return successfullySubmitted;
  };

  const handleSave = async () => {
    setLoading(true);

    if (formRef.current) {
      const successfullySubmittedCurrentForm = await submitCurrentForm();

      if (!successfullySubmittedCurrentForm) {
        setLoading(false);
        return;
      }
    }

    const successfullySubmitted = await handleSubmit();

    if (successfullySubmitted && editedEmployeeDetailsRef.current && initialEmploymentDetailsFormStateRef.current) {
      const { customRequestsLimits, timeOffLimits, rates, workdayDurations } = editedEmployeeDetailsRef.current;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!_.isEqual(initialRequestLimitsFormStateRef.current, { customRequestsLimits, timeOffLimits })) {
        if (calendarInitialized) void updateCalendarForIds();
        if (requestsUsageOverviewInitialized) void updateRequestsUsageOverviewNoThrottle(true);
      }

      const initialRatesOrdered = _.orderBy(
        initialEmploymentDetailsFormStateRef.current.rates,
        ({ startDateUnix }) => startDateUnix,
      );
      const ratesOrdered = _.orderBy(rates, ({ startDateUnix }) => startDateUnix).map((rate) =>
        _.omitBy(rate, _.isUndefined.bind(rate)),
      );

      const initialWorkdayDurationsOrdered = _.orderBy(
        initialEmploymentDetailsFormStateRef.current.workdayDurations,
        ({ fromYear }) => fromYear,
      );
      const workdayDurationsOrdered = _.orderBy(workdayDurations, ({ fromYear }) => fromYear);

      if (
        (!_.isEqual(initialRatesOrdered, ratesOrdered) ||
          !_.isEqual(initialWorkdayDurationsOrdered, workdayDurationsOrdered)) &&
        reportInitialized
      ) {
        void updateReportForIds();
      }
    }

    setLoading(false);
  };

  const onSubmitError = () => resolveSubmitForm(false);

  if (!isAppReady || !appStateEmployeeDetails) return <></>;

  if (!isEmployeeEditable) return <Navigate to={baseRoute} relative="path" />;

  return (
    <>
      <Modal.Header>
        {isMobileBreakpoint && currentTab && <Modal.BackButton onClick={() => void setCurrentTab(null)} />}
        <Modal.Title>
          <Trans id="team.edit_teammate">Edit teammate</Trans>
        </Modal.Title>
      </Modal.Header>
      {!profileFormState ? (
        <CenteredLoadingSpinner size={3} />
      ) : (
        <>
          <Modal.Body sx={modalBodySx}>
            <Flex sx={bodyLayoutSx}>
              {((isMobileBreakpoint && !currentTab) || !isMobileBreakpoint) && (
                <Navigation
                  employeeDetails={{
                    name: { firstName: profileFormState.firstName, surname: profileFormState.surname },
                    avatarUrl: savedAvatarInfo ? savedAvatarInfo.avatarUrl : appStateEmployeeDetails.avatarUrl,
                    email: profileFormState.email || '',
                    invitationState: appStateEmployeeDetails.invitationState,
                    isHidden: appStateEmployeeDetails.isHidden,
                    isActive: appStateEmployeeDetails.isActive,
                    id: appStateEmployeeDetails.id,
                    initialEmail: fetchEmployeeDetailsResponseRef?.current?.employeeInfo?.email,
                  }}
                  activeTab={currentTab}
                  onTabClick={floatingPromiseReturn(setCurrentTab)}
                />
              )}
              {currentTab && (
                <Flex sx={wrapperSx} ref={modalContentRef}>
                  {currentTab === TabName.Profile && (
                    <Suspense fallback={<></>}>
                      <LazyUserProfile
                        avatarHasChanged={!!(savedAvatarInfo && savedAvatarInfo.changed)}
                        avatarUrl={savedAvatarInfo ? savedAvatarInfo.avatarUrl : appStateEmployeeDetails.avatarUrl}
                        defaultValues={profileFormState}
                        onSubmit={onProfileSubmit}
                        onSubmitError={onSubmitError}
                        onTagsIdsBlur={floatingPromiseReturn(getTagInheritedFeatures)}
                        ref={formRef}
                      />
                    </Suspense>
                  )}

                  {currentTab === TabName.Employment && employmentDetailsFormState && (
                    <Suspense fallback={<></>}>
                      <LazyUserEmploymentDetails
                        onSubmit={onSubmitFactory<EmployeeEmploymentDetailsFormState>(setEmploymentDetailsFormState)}
                        onSubmitError={onSubmitError}
                        defaultValues={employmentDetailsFormState}
                        ref={formRef}
                      />
                    </Suspense>
                  )}
                  {currentTab === TabName.RequestsLimits && requestLimitsFormState && (
                    <UserRequestLimits
                      onSubmit={onSubmitFactory<EmployeeRequestLimitsFormState>(setRequestLimitsFormState)}
                      onSubmitError={onSubmitError}
                      defaultValues={requestLimitsFormState}
                      ref={formRef}
                    />
                  )}
                  {currentTab === TabName.Advanced &&
                    advancedDetailsFormState &&
                    ((profileFormState?.tagsIds.length && !tagInheritedFeaturesResponse) ||
                    isFetchingTagInheritedFeatures ? (
                      <CenteredLoadingSpinner />
                    ) : (
                      <UserAdvancedDetails
                        onSubmit={onSubmitFactory<EmployeeAdvancedDetailsFormState>(setAdvancedDetailsFormState)}
                        onSubmitError={onSubmitError}
                        tagInheritedFeatures={tagInheritedFeaturesResponse || undefined}
                        defaultValues={advancedDetailsFormState}
                        advancedFeaturesFieldArrayProps={{
                          inheritFromTagsMode: true,
                        }}
                        ref={formRef}
                      />
                    ))}
                  {currentTab === TabName.Notes && privateNotesFormState && (
                    <UserPrivateNotes
                      onSubmit={onSubmitFactory<PrivateNotesFormState>(setPrivateNotesFormState)}
                      onSubmitError={onSubmitError}
                      defaultValues={privateNotesFormState}
                      ref={formRef}
                    />
                  )}
                  {currentTab === TabName.Other && isHeadAdmin && <UserOther employeeId={id} />}
                </Flex>
              )}
            </Flex>
          </Modal.Body>
        </>
      )}
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading || !profileFormState,
            onClick: floatingPromiseReturn(handleSave),
            type: 'submit',
            variant: 'primary',
            children: t({ id: 'save', message: 'Save' }),
          },
        ]}
      />
      <ModalRoutes />
    </>
  );
};

const useStyles = (() => {
  const modalBodySx: ThemeUIStyleObject = { py: 0, pr: [4, null, null, 0], overflowY: ['auto', null, null, 'hidden'] };
  const bodyLayoutSx: ThemeUIStyleObject = {
    flexDirection: ['column', null, null, 'row'],
    flex: '1',
    alignItems: 'stretch',
    overflowY: ['initial', null, null, 'hidden'],
  };
  const wrapperSx: ThemeUIStyleObject = {
    flex: '1',
    pb: 3,
    overflowY: ['initial', null, null, 'auto'],
    pr: [0, null, null, 4],
    pl: [0, null, null, 3],
    flexDirection: 'column',
    borderLeft: ['none', null, null, 'solid 1px'],
    borderColor: ['none', null, null, 'whites6'],
  };

  return () => {
    const { isMobileBreakpoint } = useThemeBreakpoint();
    return {
      modalBodySx,
      bodyLayoutSx,
      wrapperSx,
      isMobileBreakpoint,
    };
  };
})();

const getParsedPhoneNumber = (phoneNumber: string) => +phoneNumber.replace('+', '').split(' ').join('');

type EditedEmployeeDetails = Omit<FetchEmployeeDetailsResponse, 'defaultWorkPositionId'> & {
  defaultWorkPosition: string | undefined;
};
type OldEmployeeDetails = FetchEmployeeDetailsResponse;

const detailsHaveChanges = (editedEmployeeDetails: EditedEmployeeDetails, oldEmployeeDetails: OldEmployeeDetails) =>
  _.isEqual(
    omit(editedEmployeeDetails, ['employeeInfo.phoneNumber']),
    omit(oldEmployeeDetails, ['employeeInfo.phoneNumber']),
  ) &&
  _.isEqual(
    getParsedPhoneNumber(get(editedEmployeeDetails, 'employeeInfo.phoneNumber') || ''),
    getParsedPhoneNumber(get(oldEmployeeDetails, 'employeeInfo.phoneNumber') || ''),
  );

type ModalPropsWithoutChildren = Omit<ModalProps, 'children'>;

function withDefaultModalWrapper(Component: ComponentType): (props: ModalPropsWithoutChildren) => ReactElement {
  const ComponentWithDefaultModalWrapper = (props: ModalPropsWithoutChildren) => (
    <Modal
      fullHeight
      sx={{
        '@media (min-height: 1080px)': {
          maxHeight: '900px',
        },
      }}
      {...props}
    >
      <Component />
    </Modal>
  );

  return ComponentWithDefaultModalWrapper;
}

export const EditTeammateModalWithDefaultModalWrapper = withDefaultModalWrapper(EditTeammateModal);
