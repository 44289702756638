import { Variants, motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import {
  CHAT_BOT,
  CHAT_WINDOW_HEIGHT,
  CHAT_WINDOW_IS_EXPANDED_HEIGHT,
  CHAT_WINDOW_IS_EXPANDED_WIDTH,
  CHAT_WINDOW_IS_VISIBLE_HEIGHT,
  CHAT_WINDOW_IS_VISIBLE_WIDTH,
  CHAT_WINDOW_WIDTH,
} from 'constants/chat';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useOnKeyboardEventQueue } from 'hooks/useOnKeyboardEventQueue/useOnKeyboardEventQueue';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import {
  chatUserWithUnreadMessagesSelectorFamily,
  chatWindowAtomFamily,
  removeChatWindowSelector,
  setStateChatWindowSelector,
} from 'state/chat';
import { languageSelector } from 'state/recoilState';
import { DEFAULT_CHAT_TRANSITION, MOBILE_CHAT_AVATAR_SIZE } from 'styles/theme/chat';
import { useCrisp } from '../../../../hooks/useCrisp';
import { MemoizedChatUser } from '../ChatUser';

import { MessageComposer } from './components/MessageComposer';
import { MessagesList } from './components/MessagesList/MessagesList';

type Props = {
  id: string;
  index: number;
  variants?: Variants;
};

const AnimatedFlex = motion(Flex);

export const ChatWindow = ({ id, index, variants: propsVariants }: Props): React.ReactElement | null => {
  const language = useRecoilValue(languageSelector);
  const messageComposerRef = useRef<HTMLInputElement | null>(null);
  const messageListRef = useRef<HTMLInputElement | null>(null);

  const hasUnreadMessages = useRecoilValue(chatUserWithUnreadMessagesSelectorFamily(id));
  const setChatWindowState = useSetRecoilState(setStateChatWindowSelector(id));
  const removeChatWindowFromRecoil = useSetRecoilState(removeChatWindowSelector(id));
  const { isVisible, isExpanded } = useRecoilValue(chatWindowAtomFamily(id));
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useAppNavigate();

  const { openCrisp } = useCrisp();
  const { isMobileBreakpoint } = useThemeBreakpoint();
  const isMobileChat = useMemo(() => isMobileBreakpoint, [isMobileBreakpoint]);

  const removeChatWindow = useCallback(() => {
    removeChatWindowFromRecoil(null);
  }, [removeChatWindowFromRecoil]);

  const handleIsVisible = useCallback(() => {
    const newIsVisible = !isVisible;
    setChatWindowState({
      type: 'isVisible',
      value: newIsVisible,
    });
    if (!isMobileBreakpoint) return;

    navigate(newIsVisible ? TO_REL.MOBILE_CHAT[language] : '..', { relative: 'path', replace: !newIsVisible });
  }, [isMobileBreakpoint, isVisible, language, navigate, setChatWindowState]);

  useOnKeyboardEventQueue('Escape', () => removeChatWindow(), isVisible && isFocused);

  const chatWindowSize = useCallback(() => {
    if (isMobileChat) {
      if (isVisible) {
        return {
          height: 'var(--app-height)',
          width: 'var(--app-width)',
        };
      }

      return {
        height: MOBILE_CHAT_AVATAR_SIZE,
        width: MOBILE_CHAT_AVATAR_SIZE,
      };
    }
    if (isExpanded && isVisible)
      return {
        width: CHAT_WINDOW_IS_EXPANDED_WIDTH,
        height: CHAT_WINDOW_IS_EXPANDED_HEIGHT,
      };
    if (isVisible)
      return {
        width: CHAT_WINDOW_IS_VISIBLE_WIDTH,
        height: CHAT_WINDOW_IS_VISIBLE_HEIGHT,
      };

    return {
      width: CHAT_WINDOW_WIDTH,
      height: CHAT_WINDOW_HEIGHT,
    };
  }, [isExpanded, isMobileChat, isVisible]);

  const hasMessageToRead = useMemo(() => hasUnreadMessages && !isFocused, [hasUnreadMessages, isFocused]);

  const returnChatUser = useCallback(
    () => (
      <MemoizedChatUser
        variant="chatWindow"
        id={id}
        chatWindowVisible={isVisible}
        chatWindowExpanded={isExpanded}
        onClick={id === CHAT_BOT ? openCrisp : handleIsVisible}
        asSupport={id === CHAT_BOT}
        hasMessageToRead={hasMessageToRead}
      />
    ),
    [handleIsVisible, openCrisp, hasMessageToRead, id, isExpanded, isVisible],
  );

  useEffect(() => {
    messageComposerRef.current?.focus();
  }, [isExpanded, isVisible]);

  const renderAdditionalButtons = useCallback(() => {
    if (isVisible) {
      return (
        <>
          {!isMobileChat && (
            <Button
              variant="minimal"
              shape="rounded"
              size="sm"
              onClick={() =>
                setChatWindowState({
                  type: 'isExpanded',
                  value: !isExpanded,
                })
              }
            >
              <Icon
                type={isExpanded ? 'narrow' : 'expand'}
                wrapperSx={{
                  ...(hasMessageToRead && { color: 'chat.text.unread' }),
                  transition: `color ${DEFAULT_CHAT_TRANSITION}`,
                }}
              />
            </Button>
          )}
          <Button variant="minimal" shape="rounded" size="sm" onClick={handleIsVisible}>
            <Icon
              type="chevronDown"
              wrapperSx={{
                ...(hasMessageToRead && { color: 'chat.text.unread' }),
                transition: `color ${DEFAULT_CHAT_TRANSITION}`,
              }}
            />
          </Button>
        </>
      );
    }
    return null;
  }, [handleIsVisible, hasMessageToRead, isExpanded, isMobileChat, isVisible, setChatWindowState]);

  const variants = useMemo(
    () => ({
      initial: {
        x: `${index * 100 + 100 - index * 20 - 20}%`,
      },
      visible: {
        x: '0%',
      },
      ...propsVariants,
    }),
    [index, propsVariants],
  );

  const zIndex = useMemo(() => 100 - index, [index]);

  return (
    <motion.div
      variants={variants}
      style={{
        display: 'flex',
        zIndex,
        ...(isVisible &&
          isMobileChat && {
            position: 'fixed',
            top: 0,
            right: 0,
            zIndex: 100 + zIndex,
          }),
      }}
      transition={{
        type: 'spring',
        duration: 0.2,
      }}
    >
      <AnimatedFlex
        data-is-visible={isVisible}
        key={`chat.window.${id}`}
        variant="chat.window.chatContainer"
        animate={chatWindowSize()}
        transition={{
          type: 'spring',
          duration: isMobileChat ? 0 : 0.15,
        }}
      >
        <Flex variant="chat.window.header" as="header" data-unread-message={hasMessageToRead}>
          {returnChatUser()}
          {id !== CHAT_BOT && (
            <Flex variant="chat.window.header.buttons">
              {renderAdditionalButtons()}
              <Button variant="minimal" shape="rounded" size="sm" onClick={removeChatWindow}>
                <Icon
                  type="x"
                  wrapperSx={{
                    ...(hasMessageToRead && { color: 'chat.text.unread' }),
                    transition: `color ${DEFAULT_CHAT_TRANSITION}`,
                  }}
                />
              </Button>
            </Flex>
          )}
        </Flex>

        {isVisible && (
          <>
            <MessagesList ref={messageListRef} chatWindowId={id} />
            <MessageComposer
              messagesListRef={messageListRef}
              ref={messageComposerRef}
              receiver={id}
              inputProps={{
                onFocus: () => {
                  setIsFocused(true);
                },
                onBlur: () => {
                  setIsFocused(false);
                },
              }}
            />
          </>
        )}
      </AnimatedFlex>
    </motion.div>
  );
};
