import { atom } from 'recoil';

export enum PermissionState {
  deny = 'Denied',
  allow = 'Allowed',
  checking = 'Checking',
}

type PermissionStateProps = {
  camera: PermissionState;
  location: PermissionState;
};

export const permissionStateAtom = atom<PermissionStateProps>({
  key: 'kiosk__permissions',
  default: {
    camera: PermissionState.checking,
    location: PermissionState.checking,
  },
});
