import { useRecoilValue } from 'recoil';

import { employeeSelectorFamily } from 'state/employees';
import { organizationSessionAtom } from 'state/organizationSession';

export const useAppState = (employeeId: string) => {
  const organizationSession = useRecoilValue(organizationSessionAtom);
  const selectedEmployee = useRecoilValue(employeeSelectorFamily({ id: employeeId }));

  return {
    isEmployeeEditable: !!selectedEmployee && selectedEmployee.editablePermissions.Employees,
    appStateEmployeeDetails: selectedEmployee,
    isAppReady: !!organizationSession,
  };
};
