type Success = (position: GeolocationPosition) => void;
type Error = () => void;

/**
 * Ask the navigator api for position and calls the provided callbacks on success/error.
 *
 * @param {Success} success Function that will be called when the navigator returns position. This function will be invoked with one argument (position: GeolocationPosition).
 * @param {Error} error Function to call on error.
 */

export const getGeoLocation = (success: Success, error: Error) => {
  if (navigator.geolocation && typeof navigator.geolocation.getCurrentPosition === 'function') {
    navigator.geolocation.getCurrentPosition(
      (position) => success(position),
      () => error(),
      {
        enableHighAccuracy: true,
      },
    );
  }
};
