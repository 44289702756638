import { Face } from '@tensorflow-models/face-detection';
import _ from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  LIVELINESS_FRAME_COLLECT,
  LIVELINESS_POSITIVE_FRAME_FOR_SCAN,
  PITCH_DEVIATION_THRESHOLD,
  PITCH_MEAN_THRESHOLD,
  PITCH_THRESHOLD,
  ROLL_DEVIATION_THRESHOLD,
  ROLL_DIFF_THRESHOLD,
  ROLL_MEAN_THRESHOLD,
  YAW_DEVIATION_THRESHOLD,
  YAW_MEAN_THRESHOLD,
  YAW_THRESHOLD,
} from 'Kiosk/constants/constants';
import { getFaceKeyPoints } from 'Kiosk/helpers';
import { DebugFaceLivelinessMode, debugModeSelector, debugScanningTimeFrameOpenAtom } from 'Kiosk/state/debugState';

export type Liveliness = { axisXRotation: number; axisYRotation: number; tilt: number; imageUrl: string };

export const useFaceLiveness = (debugFaceLivelinessMode?: DebugFaceLivelinessMode) => {
  const latestLivelinessRef = useRef<Liveliness[]>([]);
  const negativeScoreFrameCollection = useRef(0);
  const scanningWindowIsOpen = useRef(false);

  // __DEBUG MODE
  const debugMode = useRecoilValue(debugModeSelector);
  const setDebugScanningTimeFrameOpen = useSetRecoilState(debugScanningTimeFrameOpenAtom);
  // __END DEBUG MODE

  const faceLivelinessFrameCollect = useMemo(() => {
    if (!debugFaceLivelinessMode) return LIVELINESS_FRAME_COLLECT;

    // eslint-disable-next-line no-console
    console.log('debugFaceLivelinessMode: ', debugFaceLivelinessMode);

    switch (debugFaceLivelinessMode) {
      case DebugFaceLivelinessMode.ThirtyFrames:
        return 30;
      case DebugFaceLivelinessMode.FiftyFrames:
        return 50;
      case DebugFaceLivelinessMode.OneHundredFrames:
        return 100;
      default:
        return LIVELINESS_FRAME_COLLECT;
    }
  }, [debugFaceLivelinessMode]);

  const getLivelinessScores = useCallback(
    (imageUrl?: string, livelinessArr?: Liveliness[]) => {
      const arr = (() => {
        // PASSED ARRAY FOR DEBUG PURPOSES
        if (livelinessArr) {
          return livelinessArr;
        }

        if (!imageUrl && latestLivelinessRef.current.length <= faceLivelinessFrameCollect) {
          return latestLivelinessRef.current;
        }

        if (!imageUrl) {
          const latestLiveliness = latestLivelinessRef.current.slice(-faceLivelinessFrameCollect);

          return latestLiveliness;
        }

        const currentLivelinessIndex = _.findIndex(latestLivelinessRef.current, { imageUrl });
        const startLivelinessIndex = Math.max(0, currentLivelinessIndex - faceLivelinessFrameCollect);

        return latestLivelinessRef.current.slice(startLivelinessIndex, currentLivelinessIndex);
      })();
      const arrLength = arr.length;

      // PITCH
      const pitchAbsArr = arr.map(({ axisXRotation }) => Math.abs(axisXRotation));
      const minPitch = Math.min(...pitchAbsArr);
      const maxPitch = Math.max(...pitchAbsArr);
      const pitchDiff = maxPitch - minPitch;
      const pitchMean = Math.ceil(pitchAbsArr.reduce((sum, value) => sum + value, 0) / arrLength);
      const pitchDeviations = pitchAbsArr.map((value) => Math.abs(value - pitchMean));
      const pitchMeanDeviation = Math.ceil(pitchDeviations.reduce((sum, value) => sum + value, 0) / arrLength);

      // YAW
      const yawAbsArr = arr.map(({ axisYRotation }) => Math.abs(axisYRotation));
      const minYaw = Math.min(...yawAbsArr);
      const maxYaw = Math.max(...yawAbsArr);
      const yawDiff = maxYaw - minYaw;
      const yawMean = Math.ceil(yawAbsArr.reduce((sum, value) => sum + value, 0) / arrLength);
      const yawDeviations = yawAbsArr.map((value) => Math.abs(value - yawMean));
      const yawMeanDeviation = Math.ceil(yawDeviations.reduce((sum, value) => sum + value, 0) / arrLength);

      // ROLL
      const rollAbsArr = arr.map(({ tilt }) => Math.abs(tilt));
      const minRoll = Math.min(...rollAbsArr);
      const maxRoll = Math.max(...rollAbsArr);
      const rollDiff = Math.ceil(maxRoll - minRoll);
      const rollMean = Math.ceil(rollAbsArr.reduce((sum, value) => sum + value, 0) / arrLength);
      const rollDeviations = rollAbsArr.map((value) => Math.abs(value - rollMean));
      const rollMeanDeviation = Math.ceil(rollDeviations.reduce((sum, value) => sum + value, 0) / arrLength);

      return {
        arrLength,
        pitchMean,
        pitchDiff,
        pitchMeanDeviation,
        yawDiff,
        yawMean,
        yawMeanDeviation,
        rollDiff,
        rollMean,
        rollMeanDeviation,
      };
    },
    [faceLivelinessFrameCollect],
  );

  const getLivelinessDetectionConditions = useCallback(
    (imageUrl?: string) => {
      const {
        pitchDiff,
        yawDiff,
        pitchMean,
        yawMean,
        pitchMeanDeviation,
        yawMeanDeviation,
        rollDiff,
        rollMean,
        rollMeanDeviation,
      } = getLivelinessScores(imageUrl);

      const pitchDiffConditionMet = pitchDiff > PITCH_THRESHOLD;
      const pitchMeanConditionMet = pitchMean > PITCH_MEAN_THRESHOLD;
      const pitchDeviationConditionMet = pitchMeanDeviation > PITCH_DEVIATION_THRESHOLD;

      const yawDiffConditionMet = yawDiff > YAW_THRESHOLD;
      const yawMeanConditionMet = yawMean > YAW_MEAN_THRESHOLD;
      const yawDeviationConditionMet = yawMeanDeviation > YAW_DEVIATION_THRESHOLD;

      const axisesDiffConditionMet = pitchDiffConditionMet || yawDiffConditionMet;
      const axisesMeanConditionMet = pitchMeanConditionMet || yawMeanConditionMet;
      const axisesDeviationConditionMet = pitchDeviationConditionMet || yawDeviationConditionMet;
      const rollConditionMet =
        rollDiff < ROLL_DIFF_THRESHOLD &&
        rollMean < ROLL_MEAN_THRESHOLD &&
        rollMeanDeviation < ROLL_DEVIATION_THRESHOLD;

      return {
        axisesDiffConditionMet,
        axisesMeanConditionMet,
        axisesDeviationConditionMet,
        rollConditionMet,
      };
    },
    [getLivelinessScores],
  );

  const resetFaceLivelinessCollecting = useCallback(() => {
    latestLivelinessRef.current.length = 0;
    scanningWindowIsOpen.current = false;
    if (debugMode) {
      setDebugScanningTimeFrameOpen(false);
    }
  }, [debugMode, setDebugScanningTimeFrameOpen]);

  const checkScanningWindowIsOpen = useCallback(
    (isFacePresent: boolean) => {
      const { axisesDiffConditionMet, axisesMeanConditionMet, axisesDeviationConditionMet, rollConditionMet } =
        getLivelinessDetectionConditions();

      if (!isFacePresent) {
        return false;
      }

      if (!axisesDiffConditionMet || !axisesMeanConditionMet || !axisesDeviationConditionMet || !rollConditionMet) {
        negativeScoreFrameCollection.current += 1;
        if (negativeScoreFrameCollection.current > LIVELINESS_POSITIVE_FRAME_FOR_SCAN) {
          return false;
        }
      } else {
        negativeScoreFrameCollection.current = 0;

        if (latestLivelinessRef.current.length >= faceLivelinessFrameCollect) {
          return true;
        }
      }

      return scanningWindowIsOpen.current;
    },
    [getLivelinessDetectionConditions, faceLivelinessFrameCollect],
  );

  const checkLivelinessConditionsMet = useCallback(
    (imageUrl?: string) => {
      const { axisesDiffConditionMet, axisesMeanConditionMet, axisesDeviationConditionMet, rollConditionMet } =
        getLivelinessDetectionConditions(imageUrl);

      const isScanningWindowOpen = scanningWindowIsOpen.current;

      if (
        !isScanningWindowOpen &&
        (!axisesDiffConditionMet || !axisesMeanConditionMet || !axisesDeviationConditionMet || !rollConditionMet)
      ) {
        return false;
      }

      return true;
    },
    [getLivelinessDetectionConditions],
  );

  const collectFaceLiveliness = useCallback(
    (face: Face | null, imageUrl: string) => {
      const faceKeypoints = getFaceKeyPoints(face);
      const isScanningWindowOpen = checkScanningWindowIsOpen(!!faceKeypoints);
      const liveliness = checkLivelinessConditionsMet(imageUrl);

      if (faceKeypoints) {
        const { axisXRotation, axisYRotation, tilt } = faceKeypoints;

        latestLivelinessRef.current.push({
          axisXRotation,
          axisYRotation,
          tilt,
          imageUrl,
        });
      } else if (!isScanningWindowOpen && latestLivelinessRef.current.length) {
        resetFaceLivelinessCollecting();
      }

      if (latestLivelinessRef.current.length > faceLivelinessFrameCollect) {
        latestLivelinessRef.current.shift();
      }

      if (isScanningWindowOpen) {
        scanningWindowIsOpen.current = true;
        if (debugMode) {
          setDebugScanningTimeFrameOpen(true);
        }
      } else {
        scanningWindowIsOpen.current = false;
        if (debugMode) {
          setDebugScanningTimeFrameOpen(false);
        }
      }

      return liveliness;
    },
    [
      checkScanningWindowIsOpen,
      checkLivelinessConditionsMet,
      faceLivelinessFrameCollect,
      resetFaceLivelinessCollecting,
      debugMode,
      setDebugScanningTimeFrameOpen,
    ],
  );

  return {
    collectFaceLiveliness,
    getLivelinessScores,
    resetFaceLivelinessCollecting,
    checkLivelinessConditionsMet,
  };
};
