import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import React, { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { SignInPayload } from 'api/actions/auth/authActions.types';
import { Button } from 'components/ui/Buttons';
import { ElementGroup } from 'components/ui/ElementGroup';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { TextInput } from 'components/ui/TextInput';
import { TO } from 'constants/routes';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { languageSelector } from 'state/recoilState';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

type Props = {
  onSubmit: (props: SignInPayload) => Promise<boolean>;
};

export const SignInForm = ({ onSubmit }: Props): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const { isAuthorizing } = useAuthState();

  const handleSubmitCallback: SubmitHandler<SignInPayload> = useCallback(
    async (props) => {
      await onSubmit(props);
    },
    [onSubmit],
  );

  return (
    <form onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))} noValidate>
      <ElementGroup direction="column" marginValue={0}>
        <TextInput
          id="email"
          placeholder={i18n._(
            t({
              id: 'global.forms.inputs.email',
              message: 'E-mail address',
            }),
          )}
          variant="roundedTop"
          type="email"
          autoComplete="email"
          error={!!errors.email}
          errorMessage={errors?.email?.message}
          icon="mail"
          {...register('email', validationFactory({ ...VALIDATION_RULES.EMAIL, required: true }))}
        />
        <TextInput
          id="password"
          placeholder={i18n._(
            t({
              id: 'global.forms.inputs.password',
              message: 'Password',
            }),
          )}
          variant="roundedBottom"
          type="password"
          error={!!errors.password}
          errorMessage={errors?.password?.message}
          icon="lock"
          autoComplete="current-password"
          {...register('password', validationFactory({ required: true }))}
        />
      </ElementGroup>

      <Flex sx={{ justifyContent: 'flex-end', mt: 2, pr: 3 }}>
        <LinkAnchor to={TO.PASS_RESET[language]} sx={{ fontSize: 3 }}>
          <Trans id="sign_in.password_recovery_link">Forgot your password?</Trans>
        </LinkAnchor>
      </Flex>

      <Button mt={4} isLoading={isAuthorizing} variant="primary" size="lg" fullWidth type="submit" shape="rounded">
        <Trans id="sign_in.form.submit_button">Sign in</Trans>
      </Button>
    </form>
  );
};
