import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Flex } from 'theme-ui';

import { UUID } from 'utils/UUID';
import { dateTime } from 'utils/dateTime';

import { Day, DayEmpty } from './Day';
import { DayProps, DaysGridProps } from './types';

type Props = DaysGridProps;

const defaultProps: Partial<Props> = {
  selectedDates: undefined,
  excludedDates: undefined,
};

export const DaysGrid = ({
  selectedDates,
  maxDateUnix,
  minDateUnix,
  todayUnix,
  currentMonth,
  currentYear,
  range,
  excludedDates,
  onDayClick,
  variant,
}: Props): React.ReactElement => {
  const visibleDays = useMemo(
    () =>
      dayjs().utc().set({
        year: currentYear,
        month: currentMonth,
        date: 1,
      }),
    [currentMonth, currentYear],
  );

  const totalDays = useMemo(() => visibleDays.daysInMonth(), [visibleDays]);
  const firstWeekday = useMemo(() => visibleDays.weekday(), [visibleDays]);

  const internalOnClick = useCallback(
    (day: number) => {
      onDayClick(
        dayjs()
          .utc()
          .set({
            year: currentYear,
            month: currentMonth,
            date: day,
            hour: 0,
            minute: 0,
            second: 0,
          })
          .unix(),
      );
    },
    [currentMonth, currentYear, onDayClick],
  );

  const columns = [0, 1, 2, 3, 4, 5, 6];
  const rows = [0, 1, 2, 3, 4, 5];

  const days = useMemo(() => Array.from({ length: totalDays }).map((value, index) => index + 1), [totalDays]);
  const daysClone = [...days];

  const startIndex = firstWeekday;

  let startDateUnix: number;
  let endDateUnix: number;

  if (selectedDates) {
    [startDateUnix, endDateUnix] = selectedDates;
  }

  return (
    <>
      {rows.map((rowIndex) => (
        <Flex key={rowIndex} sx={{ justifyContent: 'center' }}>
          {columns.map((columnIndex) => {
            if (rowIndex === 0 && columnIndex < startIndex) {
              return <DayEmpty variant={variant} key={UUID()} />;
            }
            const dayNumber = daysClone.shift();

            if (dayNumber) {
              const date = dateTime(undefined, { utc: true })
                .set({
                  year: currentYear,
                  month: currentMonth,
                  date: dayNumber,
                  hour: 0,
                  minute: 0,
                  second: 0,
                })
                .unix();

              let propsDay: Partial<DayProps> = {};

              if (selectedDates) {
                propsDay = {
                  ...(range && {
                    ...(endDateUnix && date > startDateUnix && date < endDateUnix && { inRange: true }),
                    ...(startDateUnix && date === startDateUnix && { rangeStart: true, inRange: true }),
                    ...(endDateUnix && date === endDateUnix && { rangeStop: true, inRange: true }),
                  }),
                  ...((date === startDateUnix || date === endDateUnix) && {
                    selected: true,
                  }),
                };
              }

              propsDay = {
                ...propsDay,
                ...((date < minDateUnix || date > maxDateUnix || _.find(excludedDates, (d) => d === date)) && {
                  disabled: true,
                }),
                ...(date === todayUnix && {
                  today: true,
                }),
              };

              return (
                <Day variant={variant} key={dayNumber} dayNumber={dayNumber} {...propsDay} onClick={internalOnClick} />
              );
            }

            return <DayEmpty variant={variant} key={UUID()} />;
          })}
        </Flex>
      ))}
    </>
  );
};

DaysGrid.defaultProps = defaultProps;
