import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { publishSchedulesAction } from 'api/actions/calendar/calendarActions';
import { useModal } from 'components/Modal/output/useModal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'components/recipes/ConfirmModal/types';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { aiSchedulesTrendScaleSelector } from 'state/calendar';
import { schedulesSettingsSelector } from 'state/organizationSession';
import { useRefreshCalendar } from '../../hooks/useRefreshCalendar';

export const PublishScheduleModal = (): React.ReactElement => {
  useLingui();
  const { state } = useLocation();
  const { ids, dates } = state || {};
  const { baseRoute } = useModal();

  const schedulesSettings = useRecoilValue(schedulesSettingsSelector);
  const trendScaleFilter = useRecoilValue(aiSchedulesTrendScaleSelector);

  const { updateCalendarForIds, calendarInitialized, updateCalendar } = useRefreshCalendar(ids);
  const { reportInitialized, updateReportForIds } = useRefreshReport(ids);

  useEffect(() => {
    if (!calendarInitialized) {
      void updateCalendar();
    }
  }, [calendarInitialized, updateCalendar]);

  const titleRenderer = useCallback(() => t({ id: 'schedule.publish_schedule', message: 'Publish schedule?' }), []);

  const contentRenderer = useCallback(
    () => (
      <Trans id="schedule.publish_schedules_info">
        <Text>You're about to publish this schedule for the selected teammates.</Text>
        <Text>
          <strong>All selected teammates will be notified about this.</strong>
        </Text>
      </Trans>
    ),
    [],
  );

  const onResponseCallback: NonNullable<ConfirmModalProps['onResponseCallback']> = useCallback(
    async (error) => {
      if (!error) {
        if (calendarInitialized) await updateCalendarForIds();
        if (reportInitialized) await updateReportForIds();
      }
    },
    [calendarInitialized, reportInitialized, updateCalendarForIds, updateReportForIds],
  );

  if (!ids?.length || !dates) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <ConfirmModal
      action={(body) =>
        publishSchedulesAction({
          peopleIds: body,
          timeRange: dates,
          ...(!!schedulesSettings?.useAiScheduling && {
            aiVariant: trendScaleFilter,
          }),
        })
      }
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      variant="PUBLISH"
      onResponseCallback={onResponseCallback}
    />
  );
};
