import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import {
  REQUEST_DEFAULT_END_TIME,
  REQUEST_DEFAULT_START_TIME,
  VALIDATION_MAX_TIME_IN_SECONDS,
} from 'constants/requests';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { MinimizeModalAddRequest } from 'state/modal';
import { getDateWithTimeInSecondsUnix } from 'utils/dateTime';
import { AddRequestExtendedFormContext } from '../../../../../../types';
import { useAddRequest } from '../../../../hooks/useAddRequest';

import { TimeOffContainer } from './components/TimeOffContainer';
import { useTimeOffFormState } from './hooks/useTimeOffFormState';

export const TimeOffForm = (): React.ReactElement => {
  useLingui();
  const {
    setFormState,
    modifyRequest: { modifyRequestData },
    requestToEdit: { selectedRequestToEdit, resetSelectedRequestToEditId },
    defaultDateUnix,
    requestFormRestored: { defaultValuesRestored },
  } = useAddRequest();

  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddRequest>();

  const selectedTimeOffDataRef = useCallbackRef(selectedRequestToEdit);
  const [forWholeDayChangedToFalse, setForWholeDayChangedToFalse] = useState<boolean>(false);
  const firstRenderMinimizedModal = useRef(
    (() => {
      if (defaultValuesRestored) return false;
      return !!minimizedModalDetails?.modalDetails?.formState;
    })(),
  );

  const { isDurationType, isForDayType, isOvertimeFreeDayType } = useTimeOffFormState();

  const { watch, setValue, setError, clearErrors, unregister } = useFormContext<AddRequestExtendedFormContext>();

  const [forWholeDayWatch, typeIdWatch, calendarWatch, actionTypeWatch] = watch([
    'isDateBound',
    'details.typeId',
    'dateTimeDetails.dates',
    'actionType',
  ]);

  const calendarWatchRef = useCallbackRef(calendarWatch);
  const forWholeDayWatchRef = useCallbackRef(forWholeDayWatch);
  const actionRef = useCallbackRef(actionTypeWatch);

  const formVisible = useMemo(() => {
    if (actionTypeWatch === RequestActionType.Edit && !selectedRequestToEdit && !modifyRequestData) return false;

    if (actionTypeWatch === RequestActionType.Create && !typeIdWatch) return false;

    if (actionTypeWatch === RequestActionType.Remove) return false;

    return true;
  }, [actionTypeWatch, selectedRequestToEdit, modifyRequestData, typeIdWatch]);

  const handleDateAfterSpecialTypeChange = useCallback(() => {
    if (calendarWatchRef.current && _.isArray(calendarWatchRef.current)) {
      if (isDurationType || isForDayType) {
        setValue('dateTimeDetails.dates', [calendarWatchRef.current[0], calendarWatchRef.current[0]]);
      } else if (isOvertimeFreeDayType && forWholeDayWatchRef.current) {
        setForWholeDayChangedToFalse(true);
      } else {
        setValue('dateTimeDetails.dates', calendarWatchRef.current);
      }
    }
  }, [calendarWatchRef, isDurationType, isForDayType, forWholeDayWatchRef, isOvertimeFreeDayType, setValue]);

  const handleForWholeDaySwitchChangedToFalse = useCallback(() => {
    if (forWholeDayChangedToFalse && calendarWatchRef.current && _.isArray(calendarWatchRef.current)) {
      const [startDateUnix] = calendarWatchRef.current;
      const newStartDateUnix = getDateWithTimeInSecondsUnix(startDateUnix, REQUEST_DEFAULT_START_TIME, true);
      const newEndDateUnix = getDateWithTimeInSecondsUnix(startDateUnix, REQUEST_DEFAULT_END_TIME, true);

      setValue('dateTimeDetails.dates', [newStartDateUnix, newEndDateUnix]);
      setForWholeDayChangedToFalse(false);
    }
  }, [calendarWatchRef, forWholeDayChangedToFalse, setValue]);

  const handleDayExceededValidation = useCallback(() => {
    if (!forWholeDayWatch && calendarWatch && !_.isEmpty(calendarWatch)) {
      const [startUnix, endUnix] = calendarWatch;

      if (endUnix) {
        const differenceInSeconds = endUnix - startUnix;

        if (differenceInSeconds > VALIDATION_MAX_TIME_IN_SECONDS) {
          setError('dateTimeDetails.dates', {
            type: 'validate',
            message: t({ id: 'add_request.error.day_exceeded', message: 'day exceeded' }),
          });
        } else {
          clearErrors('dateTimeDetails.dates');
        }
      }
    }
  }, [calendarWatch, clearErrors, forWholeDayWatch, setError]);

  const handleFormVisibility = useCallback(() => {
    if (formVisible) {
      setFormState((prevState) => ({ ...prevState, mainFormVisible: true }));
    }

    if (!formVisible) {
      setFormState((prevState) => ({ ...prevState, mainFormVisible: false }));

      if (actionTypeWatch === RequestActionType.Remove) {
        unregister('dateTimeDetails.dates');
        unregister('details.ignoreWeekends');
        unregister('details.ignoreHolidays');
        unregister('isDateBound');
      }
    }
  }, [actionTypeWatch, formVisible, setFormState, unregister]);

  const handleSpecialTypeChange = useCallback(() => {
    if (firstRenderMinimizedModal.current) return;

    if (isForDayType || isDurationType || isOvertimeFreeDayType) {
      setValue('details.ignoreWeekends', false);
      setValue('details.ignoreHolidays', false);
    } else {
      setValue('details.ignoreWeekends', true);
      setValue('details.ignoreHolidays', true);
    }

    if (!isForDayType) {
      unregister('dateTimeDetails.forDay');
    }

    if (!isDurationType) {
      unregister('dateTimeDetails.duration');
    }

    if (isOvertimeFreeDayType) {
      setValue('isDateBound', false);
    }

    if (!isOvertimeFreeDayType) {
      setValue('isDateBound', true);
    }
  }, [isDurationType, isForDayType, isOvertimeFreeDayType, setValue, unregister]);

  useEffect(() => {
    handleDateAfterSpecialTypeChange();
  }, [handleDateAfterSpecialTypeChange]);

  useEffect(() => {
    handleForWholeDaySwitchChangedToFalse();
  }, [handleForWholeDaySwitchChangedToFalse]);

  useEffect(() => {
    handleDayExceededValidation();
  }, [handleDayExceededValidation]);

  useEffect(() => {
    handleFormVisibility();
  }, [handleFormVisibility]);

  useEffect(() => {
    handleSpecialTypeChange();
  }, [handleSpecialTypeChange]);

  useEffect(() => {
    if (
      forWholeDayWatch &&
      !selectedTimeOffDataRef.current &&
      !isForDayType &&
      !isDurationType &&
      !isOvertimeFreeDayType &&
      !firstRenderMinimizedModal.current
    ) {
      setValue('details.ignoreWeekends', true);
      setValue('details.ignoreHolidays', true);
    }
  }, [isDurationType, isForDayType, forWholeDayWatch, isOvertimeFreeDayType, selectedTimeOffDataRef, setValue]);

  useEffect(() => {
    if (!firstRenderMinimizedModal.current) {
      setValue('isDateBound', true);
    } else {
      firstRenderMinimizedModal.current = false;
    }

    if (defaultDateUnix && actionRef.current === RequestActionType.Create) {
      setValue('dateTimeDetails.dates', [defaultDateUnix, defaultDateUnix]);
    }
  }, [actionRef, defaultDateUnix, setValue]);

  useEffect(() => {
    if (!forWholeDayWatch && !isOvertimeFreeDayType) {
      unregister('details.ignoreWeekends');
      unregister('details.ignoreHolidays');
    }
  }, [forWholeDayWatch, isOvertimeFreeDayType, unregister]);

  useEffect(
    () => () => {
      resetSelectedRequestToEditId();
    },
    [resetSelectedRequestToEditId],
  );

  return (
    <TimeOffContainer>
      <TimeOffContainer.EditionObserver />
      <TimeOffContainer.LimitsObserver />
      <TimeOffContainer.SaveStateObserver />
      <TimeOffContainer.ResetFormObserver />
      <TimeOffContainer.RequestsHistorySelect />
      {actionTypeWatch !== RequestActionType.Remove && (
        <TimeOffContainer.InnerContainer>
          <TimeOffContainer.SelectType />
          <TimeOffContainer.ForDayPicker />
        </TimeOffContainer.InnerContainer>
      )}
      {formVisible && (
        <>
          <TimeOffContainer.Calendar setForWholeDayChangedToFalse={setForWholeDayChangedToFalse} />
          <TimeOffContainer.Duration />
          <TimeOffContainer.SkipSwitches />
        </>
      )}
    </TimeOffContainer>
  );
};
