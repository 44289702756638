/**
 * Focuses the provided input element and optionaly sets the start and end positions of its selection.
 *
 * @param {React.MutableRefObject<HTMLInputElement | null>} refToFocus Name of the custom event.
 * @param {number | undefined} selectionStart The offset into the text field for the start of the selection.
 * @param {number | undefined} selectionEnd The offset into the text field for the end of the selection.
 */

export const focusInput = (
  refToFocus: React.MutableRefObject<HTMLInputElement | null>,
  selectionStart?: number,
  selectionEnd?: number,
): void => {
  if (selectionStart !== undefined && selectionEnd !== undefined) {
    refToFocus?.current?.setSelectionRange(selectionStart, selectionEnd);
  }
  refToFocus?.current?.focus();
};
