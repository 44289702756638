import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';

import {
  advancedSettingsDeleteFaceModelsAction,
  deleteEmployeesFaceModelsAction,
} from 'api/actions/settings/settingsActions';
import { AdvancedSettingsDeleteFaceModelsActionProps } from 'api/actions/settings/settingsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { LinkButtonProps } from 'components/ui/Buttons';
import { TextInput } from 'components/ui/TextInput';
import { DELETE_AI_FACES_FORM_ID } from 'constants/settings';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

import { AdvancedDeleteMain } from './AdvancedDeleteMain/AdvancedDeleteMain';

type Props = {
  employeesIds?: string[];
  linkButtonWithLoadingProps?: LinkButtonProps;
  onSubmitSuccess?: () => void;
  renderer?: (formRenderer: () => JSX.Element, loading: boolean, passwordValue: string) => React.ReactElement;
};

export const AdvancedDeleteFaceModels = ({
  employeesIds,
  onSubmitSuccess,
  linkButtonWithLoadingProps,
  renderer,
}: Props): React.ReactElement => {
  useLingui();
  const { mutate, loading } = useMutation(
    employeesIds ? deleteEmployeesFaceModelsAction : advancedSettingsDeleteFaceModelsAction,
  );

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AdvancedSettingsDeleteFaceModelsActionProps>();

  const passwordValue = watch('password');

  const handleSubmitCallback = useCallback(
    async (data: AdvancedSettingsDeleteFaceModelsActionProps) => {
      const { error } = await mutate({
        ...data,
        employeesIds,
      });

      if (!error) {
        if (onSubmitSuccess && employeesIds) onSubmitSuccess();

        addSnackbar({
          message: employeesIds
            ? t({
                id: 'team.edit_teamate.other.delete_face_model_success',
                message: 'Deleted face recognition model',
              })
            : t({
                id: 'settings.organization.advanced.delete_face_models_success',
                message: 'Deleted all face recognition models',
              }),
          variant: 'success',
        });
        setValue('password', '');
      }
    },
    [mutate, employeesIds, onSubmitSuccess, setValue],
  );

  const formRenderer = () => (
    <AdvancedDeleteMain
      formId={DELETE_AI_FACES_FORM_ID}
      label={
        employeesIds
          ? t({
              id: 'team.edit_teamate.other.delete_ai',
              message: 'Delete AI face recognition model',
            })
          : t({
              id: 'advanced_settings.heading.delete_all_ai',
              message: 'Delete all AI face recognition models',
            })
      }
      additionalLabel={t({
        id: 'advanced_settings.heading.additional_info',
      })}
      {...(linkButtonWithLoadingProps &&
        Object.keys(linkButtonWithLoadingProps).length > 0 && {
          linkButtonWithLoadingProps: {
            disabled: !passwordValue || loading,
            isLoading: loading,
            ...linkButtonWithLoadingProps,
          },
        })}
      onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}
    >
      <TextInput
        {...register('password', {
          required: t({
            id: 'global.forms.required',
          }),
        })}
        id="deleteFaceModels"
        placeholder={t({
          id: 'global.forms.inputs.password',
        })}
        size="sm"
        type="password"
        icon="lock"
        label={t({
          id: 'advanced_settings.type_your_password',
        })}
        error={!!errors.password}
        errorMessage={errors.password?.message}
        autoComplete="new-password"
        sx={{ maxWidth: '340px' }}
      />
    </AdvancedDeleteMain>
  );

  if (renderer) {
    return renderer(formRenderer, loading, passwordValue);
  }

  return formRenderer();
};
