/** @jsxImportSource theme-ui */
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import { forwardRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FlexProps } from 'theme-ui';

import { FetchTagInheritedFeaturesResponse } from 'api/actions/employees/employeesActions.types';
import { PermissionsFeaturesTypes } from 'api/actions/userSession/userSessionActions.types';
import { Switch } from 'components/ui/Switch';
import { EmployeeAdvancedDetailsFormState } from 'state/team';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { getNumberField } from 'utils/getNumberField';

import {
  AdvancedFeaturesFieldArray,
  AdvancedFeaturesFieldArrayProps,
} from './formsElements/AdvancedFeaturesFieldArray';
import { featuresTypeEnforcer } from './utils/fieldArrayTypeEnforcers';

const forceCorrectTypes = ({
  features,
  visibilityLevel,
  ...rest
}: EmployeeAdvancedDetailsFormState): EmployeeAdvancedDetailsFormState => ({
  ...rest,
  features: featuresTypeEnforcer(features),
  visibilityLevel: getNumberField(visibilityLevel),
});

type Props = {
  advancedFeaturesFieldArrayProps?: Partial<AdvancedFeaturesFieldArrayProps>;
  tagInheritedFeatures?: FetchTagInheritedFeaturesResponse;
  defaultValues?: Partial<EmployeeAdvancedDetailsFormState>;
  onSubmit: (props: EmployeeAdvancedDetailsFormState) => Promise<boolean>;
  onSubmitError?: () => void;
} & Pick<FlexProps, 'sx'>;

export const UserAdvancedDetails = forwardRef<HTMLFormElement, Props>(
  (
    { onSubmit, onSubmitError, defaultValues, tagInheritedFeatures, advancedFeaturesFieldArrayProps, sx, ...restProps },
    ref,
  ) => {
    useLingui();
    const getDefaultValues = useCallback(() => {
      if (!defaultValues) {
        if (!tagInheritedFeatures) return undefined;
        return (
          {
            ...tagInheritedFeatures,
            hideOnList: false,
            features: tagInheritedFeatures.features || [],
          } || undefined
        );
      }
      if (!tagInheritedFeatures) {
        return (
          {
            ...defaultValues,
            hideOnList: !!defaultValues.hideOnList,
            features: defaultValues.features || [],
          } || undefined
        );
      }
      const { features: newFeatures, visibilityLevelInheritedFromTag: newVisibilityLevelInheritedFromTag } =
        tagInheritedFeatures;

      const { features, visibilityLevelInheritedFromTag, visibilityLevel, hideOnList, ...restDefaultValues } =
        defaultValues;

      const limitDataVisibilityFeature = find(features, { type: PermissionsFeaturesTypes.LimitDataVisibility });
      return {
        ...restDefaultValues,
        hideOnList: !!hideOnList,
        ...(!isNil(newVisibilityLevelInheritedFromTag) && {
          visibilityLevelInheritedFromTag: newVisibilityLevelInheritedFromTag,
        }),
        visibilityLevel: limitDataVisibilityFeature?.inheritFromTag
          ? newVisibilityLevelInheritedFromTag
          : visibilityLevel,
        features:
          features?.map(({ value, inheritFromTag, type, inheritedValue }) => {
            const tagInheritedFeature = find(newFeatures, { type });

            return {
              inheritFromTag,
              type,
              value: inheritFromTag ? !!tagInheritedFeature?.inheritedValue : value,
              inheritedValue: tagInheritedFeature?.inheritedValue || inheritedValue,
            };
          }) || [],
      };
    }, [defaultValues, tagInheritedFeatures]);

    const { control, register, handleSubmit, watch, setValue } = useForm<EmployeeAdvancedDetailsFormState>({
      defaultValues: getDefaultValues(),
      mode: 'onTouched',
      reValidateMode: 'onChange',
    });

    const handleSubmitCallback = useCallback(
      (props: EmployeeAdvancedDetailsFormState) => {
        void onSubmit(forceCorrectTypes(props));
      },
      [onSubmit],
    );

    return (
      <form
        {...restProps}
        sx={{ flexGrow: 2, ...(sx && sx) }}
        ref={ref}
        onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, onSubmitError))}
        noValidate
      >
        <Switch
          wrapperSx={{ mb: 4 }}
          size="sm"
          label={t({ id: 'team.user.hide_on_all', message: 'Hide on all lists' })}
          additionalInfo={t({
            id: 'team.user.hide_on_all.info',
            message: 'User will be visible only by Administrators',
          })}
          {...register('hideOnList')}
        />

        <AdvancedFeaturesFieldArray
          register={register}
          control={control}
          watch={watch}
          setValue={setValue}
          {...(advancedFeaturesFieldArrayProps && advancedFeaturesFieldArrayProps)}
        />
      </form>
    );
  },
);
