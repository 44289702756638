import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { locationChangeRegisteredListenersAtom } from 'state/locationObserver';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';

const Observer = (): null => {
  const registeredListeners = useRecoilValue(locationChangeRegisteredListenersAtom);
  const location = useLocation();

  const isFirstRenderRef = useRef(true);
  const registeredListenersRef = useCallbackRef(registeredListeners);

  useEffect(() => {
    if (!isFirstRenderRef.current && location && !_.isEmpty(registeredListenersRef.current)) {
      emitCustomEvent(CustomEvents.LOCATION_CHANGE, location);
    }
    isFirstRenderRef.current = false;
  }, [location, registeredListenersRef]);

  return null;
};

const Renderer = (): React.ReactElement | null => {
  const registeredListeners = useRecoilValue(locationChangeRegisteredListenersAtom);

  if (_.isEmpty(registeredListeners)) {
    return null;
  }

  return <Observer />;
};

export const LocationObserver = Renderer;
