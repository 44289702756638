import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useMemo, useRef } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex } from 'theme-ui';

import { OrganizationAccountState } from 'api/actions/payments/paymentsActions.types';
import { Select } from 'components/ui/Select/Select';
import { CountrySelect } from 'components/ui/Select/variants/CountrySelect';
import { CurrencySelect } from 'components/ui/Select/variants/CurrencySelect';
import { TimeZoneSelect } from 'components/ui/Select/variants/TimeZoneSelect';
import { Switch } from 'components/ui/Switch';
import { TextInput } from 'components/ui/TextInput';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { useIsEuCountry } from 'pages/Payments/output/useIsEuCountry';
import { activeSubscriptionSelector } from 'state/organizationSession';
import { startingWeekDayOptionsSelector } from 'state/selectOptions';
import { blockTransitionAtom } from 'state/settings';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useFormCustomEvent } from '../../../../hooks/useFormCustomEvent';
import { useFormSubmit } from '../../../../hooks/useFormSubmit';
import { useSettingsBlockRouteTransition } from '../../../../hooks/useSettingsBlockRouteTransition';
import { OptionLabel } from '../../../OptionLabel';
import { SettingsGroup } from '../../../SettingsGroup/SettingsGroup';
import { useIndustrySelectsOptions } from '../../hooks/useIndustrySelectsOptions';
import { useOrganizationSettings } from '../../hooks/useOrganizationSettings';

import { MemoizedOrganizationChangeAvatarForm } from './components/OrganizationChangeAvatarForm';
import { useSubmitOrganizationSettings } from './hooks/useSubmitOrganizationSettings';

export const OrganizationForm = React.memo((): React.ReactElement => {
  useLingui();

  const resetBlockTransition = useResetRecoilState(blockTransitionAtom);
  const setBlockTransition = useSetRecoilState(blockTransitionAtom);
  const activeSubscription = useRecoilValue(activeSubscriptionSelector);
  const startingWeekDayOptions = useRecoilValue(startingWeekDayOptionsSelector);
  const { omittedOrganizationSettings, setOrganizationSettings } = useOrganizationSettings();
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const formRef = useRef<HTMLFormElement | null>(null);

  const { dispatchSubmitEvent, throttledDispatchSubmitEvent } = useFormSubmit(formRef);

  const handleOnFocus = useCallback(() => {
    throttledDispatchSubmitEvent.cancel();
  }, [throttledDispatchSubmitEvent]);

  const handleOnChangeAndOnBlur = useCallback(() => {
    setBlockTransition((prevState) => ({
      ...prevState,
      blockLocationPathname: null,
      blockTransition: true,
    }));
    throttledDispatchSubmitEvent.cancel();
    throttledDispatchSubmitEvent();
  }, [setBlockTransition, throttledDispatchSubmitEvent]);

  const {
    registerOnChange,
    registerOnBlur,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormCustomEvent({
    onChangeCustom: handleOnChangeAndOnBlur,
    onBlurCustom: handleOnChangeAndOnBlur,
    formProps: {
      defaultValues: omittedOrganizationSettings || undefined,
      mode: 'onTouched',
      reValidateMode: 'onChange',
      shouldFocusError: false,
    },
  });

  const watchIndustry = watch('industry');
  const watchIndustrySubcategory = watch('industrySubcategory');
  const watchCountryCode = watch('countryCode');

  const { industryOptions, industrySubcategoryOptions } = useIndustrySelectsOptions(watchIndustry);

  const handleChangeIndustryList = useCallback(() => {
    let formValues = getValues();
    if (formValues.industry !== watchIndustry) {
      setValue('industrySubcategory', undefined);
      formValues = getValues();
    }
  }, [getValues, setValue, watchIndustry]);

  const handleFormReset = useCallback(() => {
    setOrganizationSettings(null);
    resetBlockTransition();
  }, [resetBlockTransition, setOrganizationSettings]);

  const { handleSubmitCallback, handleErrorCallback } = useSubmitOrganizationSettings({
    handleFormReset,
    getCurrentFormState: getValues,
  });

  useSettingsBlockRouteTransition(dispatchSubmitEvent, throttledDispatchSubmitEvent);

  const isEuCountry = useIsEuCountry();

  const isEuCompany = useMemo(() => isEuCountry(watchCountryCode || ''), [watchCountryCode, isEuCountry]);

  const isActive = useMemo(
    () =>
      activeSubscription?.state === OrganizationAccountState.Paid ||
      activeSubscription?.state === OrganizationAccountState.Testing,
    [activeSubscription?.state],
  );

  return (
    <form
      ref={formRef}
      onChange={handleChangeIndustryList}
      onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleErrorCallback))}
    >
      <Flex variant="settings.container.vertical.main">
        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title>
              <Trans id="organization_settings.heading.basic_information">Basic information</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible
              sx={{
                gap: 3,
                flexDirection: 'row',
                ...(isSmartphoneBreakpoint && { flexDirection: 'column-reverse', gap: 2 }),
              }}
            >
              <Flex sx={{ flexDirection: 'column', flexGrow: 1, gap: '0.25rem' }}>
                <TextInput
                  {...registerOnBlur('name', validationFactory({ ...VALIDATION_RULES.COMPANY_NAME, required: true }))}
                  error={!!errors.name}
                  errorMessage={errors.name?.message}
                  id="company_name"
                  placeholder={t({
                    id: 'organization_settings.texts.organization_name',
                    message: 'Organization name',
                  })}
                  size="sm"
                  label={t({
                    id: 'organization_settings.texts.organization_name',
                  })}
                  onFocus={handleOnFocus}
                />
                <Flex sx={{ flexDirection: 'column', gap: 1 }}>
                  <TextInput
                    {...registerOnBlur(
                      'address.street',
                      validationFactory({
                        ...VALIDATION_RULES.STREET,
                        required: isActive && isEuCompany,
                      }),
                    )}
                    error={!!errors.address?.street}
                    errorMessage={errors.address?.street?.message}
                    id="street"
                    placeholder={t({
                      id: 'organization_settings.address.street',
                      message: 'Street',
                    })}
                    size="sm"
                    label={`${t({
                      id: 'organization_settings.texts.address_details',
                      message: 'Address details',
                    })}${!isEuCompany ? ` ${t({ id: 'settings.optional', message: '(optional)' })}` : ''}`}
                    clearable
                    onFocus={handleOnFocus}
                  />
                  <Flex sx={{ gap: 1 }}>
                    <TextInput
                      {...registerOnBlur(
                        'address.postalCode',
                        validationFactory({
                          ...VALIDATION_RULES.POSTAL_CODE,
                          required: isActive && isEuCompany,
                        }),
                      )}
                      error={!!errors.address?.postalCode}
                      errorMessage={errors.address?.postalCode?.message}
                      id="zip"
                      placeholder={t({
                        id: 'organization_settings.address.postal_code',
                        message: 'ZIP',
                      })}
                      size="sm"
                      clearable
                      onFocus={handleOnFocus}
                      sx={{
                        maxWidth: '120px',
                      }}
                    />
                    <TextInput
                      {...registerOnBlur(
                        'address.city',
                        validationFactory({
                          ...VALIDATION_RULES.CITY,
                          required: isActive && isEuCompany,
                        }),
                      )}
                      error={!!errors.address?.city}
                      errorMessage={errors.address?.city?.message}
                      id="city"
                      placeholder={t({
                        id: 'organization_settings.address.city',
                        message: 'City',
                      })}
                      size="sm"
                      clearable
                      onFocus={handleOnFocus}
                    />
                  </Flex>
                </Flex>
                <CountrySelect
                  {...registerOnBlur('countryCode', {
                    required:
                      isActive &&
                      t({
                        id: 'global.forms.required',
                      }),
                  })}
                  id="countryCode"
                  label={t({
                    id: 'organization_settings.texts.country',
                  })}
                  placeholder={t({
                    id: 'organization_settings.texts.country',
                  })}
                  size="sm"
                  searchable
                  onFocus={handleOnFocus}
                />
                <TextInput
                  {...registerOnBlur(
                    'vatId',
                    validationFactory({
                      ...VALIDATION_RULES.TAX_ID,
                      required: isActive && isEuCompany,
                    }),
                  )}
                  id="tax_id"
                  placeholder={t({
                    id: 'organization_settings.texts.tax_id',
                  })}
                  error={!!errors.vatId}
                  errorMessage={errors.vatId?.message}
                  size="sm"
                  label={`${t({
                    id: 'organization_settings.texts.tax_id',
                    message: 'Tax ID',
                  })}${!isEuCompany ? ` ${t({ id: 'settings.optional' })}` : ''}`}
                  clearable
                  onFocus={handleOnFocus}
                />
              </Flex>
              <MemoizedOrganizationChangeAvatarForm />
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>

        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title>
              <Trans id="organization_settings.heading.industry">Industry</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="noGap" sx={{ gap: '2px' }}>
              <Select
                id="industry"
                placeholder={t({
                  id: 'global.forms.pick',
                  message: 'Pick one',
                })}
                size="sm"
                variant={industrySubcategoryOptions.length > 0 ? 'roundedTop' : 'rounded'}
                error={!!errors.industry}
                errorMessage={errors?.industry?.message}
                options={industryOptions}
                {...registerOnBlur('industry', {
                  valueAsNumber: true,
                  required: t({ id: 'global.forms.required' }),
                })}
              />

              {!!watchIndustry && industrySubcategoryOptions.length > 0 && (
                <Select
                  id="industrySubcategory"
                  placeholder={t({
                    id: 'organization_settings.form.industry_sub',
                    message: 'Additional industry category (optional)',
                  })}
                  size="sm"
                  variant="roundedBottom"
                  error={!!errors.industrySubcategory}
                  errorMessage={errors?.industrySubcategory?.message}
                  clearable={!!watchIndustrySubcategory}
                  options={industrySubcategoryOptions}
                  {...registerOnBlur('industrySubcategory', {
                    valueAsNumber: true,
                  })}
                />
              )}
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>

        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title>
              <Trans id="organization_settings.heading.preferences">Preferences</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="md">
              <OptionLabel
                withDivider
                label={t({
                  id: 'organization_settings.time_zone_id.label',
                  message: 'Timezone',
                })}
                apendWith={
                  <TimeZoneSelect
                    {...registerOnBlur('timeZoneId')}
                    id="timeZone"
                    placeholder={t({
                      id: 'organization_settings.time_zone_id.label',
                    })}
                    size="sm"
                    searchable
                    sx={{ minWidth: '300px' }}
                    onFocus={handleOnFocus}
                  />
                }
              />
              <OptionLabel
                withDivider
                label={t({
                  id: 'organization_settings.starting_week_day.label',
                  message: 'Start of the work week',
                })}
                apendWith={
                  <Select
                    {...registerOnBlur('startingWeekDay', {
                      valueAsNumber: true,
                    })}
                    id="startingWeekDay"
                    placeholder={t({
                      id: 'organization_settings.starting_week_day.label',
                    })}
                    size="sm"
                    options={startingWeekDayOptions}
                    sx={{ minWidth: '300px' }}
                    onFocus={handleOnFocus}
                  />
                }
              />
              <OptionLabel
                label={t({
                  id: 'organization_settings.display_currency.label',
                  message: 'Currency',
                })}
                apendWith={
                  <CurrencySelect
                    {...registerOnBlur('displayCurrency')}
                    id="displayCurrency"
                    placeholder={t({
                      id: 'organization_settings.display_currency.label',
                    })}
                    size="sm"
                    searchable
                    sx={{ minWidth: '300px' }}
                    onFocus={handleOnFocus}
                  />
                }
              />
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>

        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title>
              <Trans id="organization_settings.heading.holidays">Holidays</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="md">
              <Switch
                {...registerOnChange('autoImportHolidaysEachYear')}
                label={t({
                  id: 'organization_settings.auto_import_holidays_each_year.label',
                  message: 'Auto-import the same holidays for each year',
                })}
                size="sm"
                bold
                sx={{ py: SETTINGS_SPACE.switchWithLabel }}
              />
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>
      </Flex>
    </form>
  );
});
