import _ from 'lodash';

import { FetchChatUsersResponse } from 'api/actions/chat/chatActions.types';

type ChatUsersSubGroupsMaps = {
  withMessageHistoryMap: Map<string, boolean>;
  withUnreadMessagesMap: Map<string, boolean>;
  onlineMap: Map<string, boolean>;
};

export const getChatUsersSubGroupsMaps = (
  chatUsersSubGroups: Partial<FetchChatUsersResponse>,
): ChatUsersSubGroupsMaps => {
  const { withMessageHistory, withUnreadMessages, online } = chatUsersSubGroups;

  const withMessageHistoryMap = new Map(_.map(withMessageHistory || [], (id) => [id, true]));
  const withUnreadMessagesMap = new Map(_.map(withUnreadMessages || [], (id) => [id, true]));
  const onlineMap = new Map(_.map(online || [], (id) => [id, true]));

  return {
    withMessageHistoryMap,
    withUnreadMessagesMap,
    onlineMap,
  };
};
