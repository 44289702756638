import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useRecoilValue } from 'recoil';
import { Flex, Heading, Text } from 'theme-ui';

import { MinimizedModalsProvider } from 'components/Modal/output/MinimizedModalsProvider';
import { APP_NAME } from 'constants/common';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { Main } from 'layouts/AuthorizedApp';
import { AlertProvider } from 'layouts/AuthorizedApp/AlertContainer/AlertProvider';
import { ModalRoutes as ClockLogModalRoutes } from 'pages/ClockLog/output/ModalRoutes';
import { ModalRoutes as RequestsModalRoutes } from 'pages/Requests/output/ModalRoutes';
import { filteredEmployeesSelector } from 'state/employees';
import { ViewWithMinimizedModals } from 'state/modal';
import { userSessionPersonIdSelector } from 'state/userSession';
import { dateTime } from 'utils/dateTime';

import { RequestLimits } from './components/RequestLimits/RequestLimits';
import { ThingsToDo } from './components/ThingsToDo/ThingsToDo';
import { YourCalendar } from './components/YourCalendar/YourCalendar';
import { YourStats } from './components/YourStats/YourStats';

export const Home = () => {
  useLingui();
  const userId = useRecoilValue(userSessionPersonIdSelector);
  const filteredEmployees = useRecoilValue(filteredEmployeesSelector);

  const {
    modulesManagement,
    modules,
    timeTrackingSettings: { isFaceRecognitionRequired, isBasicFraudDetectionRequired },
  } = useAppPermissions();
  const { isMobileBreakpoint } = useThemeBreakpoint();

  const currentDateUnix = useMemo(() => dateTime(undefined).startOf('day').utc(true).unix(), []);
  const currentDate = dateTime(undefined).format('dddd, LL');

  const getUserData = useCallback(() => {
    const data = userId && filteredEmployees.get(userId);
    if (!data) return undefined;

    const { name } = data;

    return {
      ...name,
    };
  }, [filteredEmployees, userId]);

  const userName = useMemo(() => getUserData(), [getUserData]);

  return (
    <>
      <Helmet>
        <title>{t({ id: 'home.page_title', message: `Dashboard - ${APP_NAME}` })}</title>
      </Helmet>
      <AlertProvider>
        <Main
          sx={{
            overflow: 'auto',
          }}
        >
          <Main.Header sx={{ flexDirection: 'column', alignItems: 'flex-start', gap: 0 }}>
            <Heading as="h1" variant="heading1">
              <Trans id="home.hello">Hello, {userName?.firstName}!</Trans> 👋
            </Heading>
            <Text variant="pLead">
              <Trans id="home.today_is">Today is {currentDate}.</Trans>
            </Text>
          </Main.Header>

          <Flex sx={{ flexDirection: 'column', flex: '1 0', gap: 6, pb: 6 }}>
            {(modulesManagement.Requests ||
              (modulesManagement.TimeTracking && (isFaceRecognitionRequired || isBasicFraudDetectionRequired))) && (
              <ThingsToDo />
            )}

            <Flex sx={{ flexDirection: ['column', null, null, 'row'], gap: 6 }}>
              {userId && modules.Requests && <RequestLimits sx={{ flex: '1 0' }} userId={userId} />}
              {userId && modules.TimeTracking && <YourStats userId={userId} sx={{ flex: '1 0' }} />}
            </Flex>
          </Flex>
        </Main>
      </AlertProvider>

      {(modules.Schedule || modules.Requests) && !isMobileBreakpoint && (
        <YourCalendar currentDateUnix={currentDateUnix} />
      )}

      <MinimizedModalsProvider forView={ViewWithMinimizedModals.HOME} />
      <RequestsModalRoutes />
      <ClockLogModalRoutes excludeRequestRoutes eventListType="antiSpoofingChecks" />
    </>
  );
};
