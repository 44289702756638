import { useLingui } from '@lingui/react';
import { FC, memo, useMemo } from 'react';
import {
  Control,
  FieldArrayWithId,
  useFieldArray,
  UseFormRegister,
  useFormState,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { FlexProps } from 'theme-ui';
import { v1 as uuidv1 } from 'uuid';

import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import {
  defaultTimeOffLimitGroupSelector,
  EmployeeRequestLimitsFieldNames,
  EmployeeRequestLimitsFormState,
  ParsedTimeOffLimit,
} from 'state/team';
import { CustomEvents } from 'utils/customEvents';
import { FieldArrayManager, FieldArrayManagerProps } from '../FieldArrayManager/FieldArrayManager';
import { TimeOffLimitsBasicControl, TimeOffLimitsBasicFormState } from '../types';

import { NestedTimeOffLimitsFieldArray } from './components/NestedTimeOffLimitsFieldArray';

const GetTimeOffSelectedYearCustomEventObserver = ({ selectedYear }: { selectedYear: number }) => {
  useCustomEventListener(CustomEvents.GET_TIME_OFF_SELECTED_YEAR, {
    callback: ({ callback }: { callback: (year: number) => number }) => {
      callback(selectedYear);
    },
  });
  return null;
};

type BasicControl = TimeOffLimitsBasicControl;

type Props = {
  trigger: UseFormTrigger<EmployeeRequestLimitsFormState> | UseFormTrigger<TimeOffLimitsBasicFormState>;
  control: Control<EmployeeRequestLimitsFormState> | Control<TimeOffLimitsBasicFormState>;
  register: UseFormRegister<EmployeeRequestLimitsFormState> | UseFormRegister<TimeOffLimitsBasicFormState>;
  fieldArrayManagerProps?: Partial<FieldArrayManagerProps>;
} & FlexProps;

export const TimeOffLimits: FC<Props> = ({ control, register, trigger, fieldArrayManagerProps, sx }) => {
  useLingui();

  const defaultTimeOffLimitGroup = useRecoilValue(defaultTimeOffLimitGroupSelector);

  const { fields, remove, append, update } = useFieldArray<
    EmployeeRequestLimitsFormState,
    EmployeeRequestLimitsFieldNames.TimeOffLimits,
    'RHF_ID'
  >({
    control: control as BasicControl,
    name: EmployeeRequestLimitsFieldNames.TimeOffLimits,
    keyName: 'RHF_ID',
  });

  const { errors } = useFormState({ control: control as BasicControl });

  const watchTimeOffLimits = useWatch({
    control: control as BasicControl,
    name: EmployeeRequestLimitsFieldNames.TimeOffLimits,
  });

  const controlledFields = fields
    .map((field) => {
      const currentLimit = watchTimeOffLimits?.find(({ id }: { id: string }) => id === field.id);
      return {
        ...field,
        ...(currentLimit && currentLimit),
      };
    })
    .map(({ fromYear, ...rest }) => ({ fromYear: +fromYear, ...rest })) as FieldArrayWithId<
    EmployeeRequestLimitsFormState,
    EmployeeRequestLimitsFieldNames.TimeOffLimits,
    'RHF_ID'
  >[];

  const getIndexByYear = (year: string) => controlledFields.findIndex(({ fromYear }) => `${fromYear}` === year);

  const timeOffYears = useMemo(() => controlledFields.map(({ fromYear }) => fromYear), [controlledFields]);

  const updateNestedLimitFactory = (fieldIndex: number) => (index: number, value: ParsedTimeOffLimit) => {
    const nestedLimits = controlledFields[fieldIndex].limits || [];
    update(fieldIndex, {
      ...controlledFields[fieldIndex],
      limits: nestedLimits.map((limit, i) => ({
        ...limit,
        ...(i === index && value),
      })),
    });
  };

  return (
    <FieldArrayManager
      sx={sx}
      variant="year"
      isDisabledGenerator={(year) => !!errors?.timeOffLimits?.[getIndexByYear(year)]}
      onAdd={(year) => {
        if (!defaultTimeOffLimitGroup) return;
        append({
          ...defaultTimeOffLimitGroup,
          fromYear: +year,
          id: uuidv1(),
        });
      }}
      onEdit={(oldYear, year) => {
        const index = getIndexByYear(oldYear);
        update(index, { ...controlledFields[index], fromYear: +year });
      }}
      onRemove={(year) => {
        const index = getIndexByYear(year);
        remove(index);
      }}
      froms={timeOffYears}
      fieldRenderer={(year) => {
        const yearIndex = getIndexByYear(year);
        const index = (() => {
          if (controlledFields[yearIndex]) return yearIndex;
          if (controlledFields[yearIndex + 1]) return yearIndex + 1;
          if (controlledFields[yearIndex - 1]) return yearIndex - 1;
          return yearIndex;
        })();
        if (!controlledFields[index]) return null;
        return (
          <>
            <NestedTimeOffLimitsFieldArray
              trigger={trigger}
              key={index}
              register={register}
              nestIndex={index}
              control={control}
              update={updateNestedLimitFactory(index)}
            />
            <GetTimeOffSelectedYearCustomEventObserver selectedYear={+year} />
          </>
        );
      }}
      {...(fieldArrayManagerProps && fieldArrayManagerProps)}
    />
  );
};

export const TimeOffLimitsGroupFieldArray = memo(TimeOffLimits);
