import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useFormContext } from 'react-hook-form';

import { DateTimeKind, RequestActionType } from 'api/actions/requests/requestsActions.types';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { EDIT_REQUEST_DEBOUNCE_TIME } from 'constants/requests';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useMemoCompare } from 'hooks/useMemoCompare/useMemoCompare';
import { MinimizeModalAddRequest } from 'state/modal';
import { ParsedEventToUpdate } from 'state/requests';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';
import { useMainFormEdit } from '../../../../../hooks/useMainFormEdit';

export const EditionObserver = (): React.ReactElement => {
  const {
    modifyRequest: { modifyRequestData },
    requestToEdit: { selectedRequestToEdit },
    requestFormRestored: { editRequestRestored },
    setRequestFormRestored,
  } = useAddRequest();
  const { handleMainFormValuesForRequestToEdit, updatePrevEvent } = useMainFormEdit();
  const [isInTransition, startTransition] = useTransition();
  const [editionFirstPartCompleted, setEditionFirstPartCompleted] = useState<boolean>(false);
  const selectedCustomDataRef = useRef<ParsedEventToUpdate | null>(null);
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddRequest>();
  const storedMinimizedModalDetails = useRef(!!minimizedModalDetails?.modalDetails.requestToEdit);
  const editRequestRestoredRef = useCallbackRef(editRequestRestored);

  const { setValue, watch } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  const handleSetHookFormValueFirstPart = useMemo(
    () =>
      _.debounce((event: ParsedEventToUpdate) => {
        if (storedMinimizedModalDetails.current && !editRequestRestoredRef.current) {
          setRequestFormRestored((prev) => ({ ...prev, editRequestRestored: true }));
          updatePrevEvent(event);
          return;
        }

        startTransition(() => {
          if (event && actionTypeWatch !== RequestActionType.Create) {
            const {
              dateTimeDetails: { kind },
              id,
              eventUpdateDetails: { typeId },
            } = event;

            handleMainFormValuesForRequestToEdit.current(event);

            setValue('targetEventId', id);
            setValue('details.typeId', typeId);
            if (actionTypeWatch !== RequestActionType.Remove) {
              setValue('isDateBound', kind === DateTimeKind.Local);
              setEditionFirstPartCompleted(true);
            }
          }
        });
      }, EDIT_REQUEST_DEBOUNCE_TIME),
    [
      editRequestRestoredRef,
      setRequestFormRestored,
      updatePrevEvent,
      actionTypeWatch,
      handleMainFormValuesForRequestToEdit,
      setValue,
    ],
  );

  /**
   *   Values are set in two parts because calendar needs to have time to update timers and the way unixes are set.
   *   To do it isDateBound responsible for unixes is set first, after it dual calendar updates timers, and after that it is safe to update dates and make sure it returns correct unix.
   */
  const handleSetHookFormValueSecondPart = useCallback(() => {
    startTransition(() => {
      if (editionFirstPartCompleted && selectedCustomDataRef.current) {
        const {
          dateTimeDetails: { dates },
          eventUpdateDetails: { ignoreHolidays, ignoreWeekends },
        } = selectedCustomDataRef.current;

        setValue('details.ignoreWeekends', ignoreWeekends);
        setValue('details.ignoreHolidays', ignoreHolidays);
        if (dates) {
          const [startUnix, endUnix] = dates;

          if (!endUnix) {
            setValue('dateTimeDetails.dates', [startUnix, startUnix]);
          } else {
            setValue('dateTimeDetails.dates', dates);
          }
        }
        setEditionFirstPartCompleted(false);
      }
    });
  }, [editionFirstPartCompleted, setValue]);

  const selectedCustomDataMemo = useMemoCompare(selectedRequestToEdit);

  useEffect(() => {
    if (selectedCustomDataMemo) {
      handleSetHookFormValueFirstPart(selectedCustomDataMemo);
    }
  }, [handleSetHookFormValueFirstPart, selectedCustomDataMemo]);

  useEffect(() => {
    if (modifyRequestData) {
      handleSetHookFormValueFirstPart(modifyRequestData);
    }
  }, [handleSetHookFormValueFirstPart, modifyRequestData]);

  useEffect(() => {
    handleSetHookFormValueSecondPart();
  }, [handleSetHookFormValueSecondPart]);

  useEffect(() => {
    selectedCustomDataRef.current = selectedRequestToEdit || modifyRequestData;
    setEditionFirstPartCompleted(false);
  }, [modifyRequestData, selectedRequestToEdit]);

  return <>{(isInTransition || editionFirstPartCompleted) && <LoadingOverlay sx={{ zIndex: 'base' }} />}</>;
};
