import { FC, useMemo } from 'react';
import { Flex, FlexOwnProps, Text, ThemeUICSSObject } from 'theme-ui';

import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { useTheme } from 'styles/useTheme';

type ColorSquareProps = {
  color: string;
  fillType?: 'full' | 'border';
};

const ColorSquare: FC<ColorSquareProps> = ({ color, fillType }: ColorSquareProps) => (
  <Flex
    sx={{
      width: '12px',
      height: '12px',
      flexShrink: 0,
      alignSelf: 'center',
      outline: '1px solid',
      outlineColor: 'select.colorSquare.outline',
      ...(fillType === 'border'
        ? {
            bg: 'select.colorSquare.background',
            borderRadius: 0,
            border: '2px solid',
            borderColor: color,
          }
        : { bg: color, borderRadius: 'pill' }),
    }}
  />
);

const FlexWithTooltip = withTooltip(Flex);

type Props = {
  variant?: 'default' | 'outline' | 'informational' | 'solid';
  color: string;
  children: React.ReactNode;
  tooltip?: string;
  overlapping?: boolean;
} & FlexOwnProps;

export type TagProps = Props;

export const Tag: FC<Props> = ({ children, color, variant, tooltip, overlapping, sx, ...props }: Props) => {
  const { theme } = useTheme();

  const variantSpecificSx: ThemeUICSSObject = useMemo(() => {
    switch (variant) {
      case 'outline':
        return { borderColor: color, fontWeight: 'bold', color };
      case 'solid':
        return {
          borderRadius: 'pill',
        };
      default:
        return {};
    }
  }, [color, variant]);

  const overlappingSx: ThemeUICSSObject = useMemo(
    () => ({
      flex: '1 1 0px',
      borderRightStyle: 'none',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      position: 'relative',
      '> [data-text]': {
        textOverflow: 'initial',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
      },
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        right: '-5px',
        top: 0,
        boxShadow: `0 -0.0625rem 0 ${theme?.colors?.whites[4]}`,
        width: '5px',
        height: '100%',
      },
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        right: '-5px',
        bottom: 0,
        boxShadow: `0 0.0625rem 0 ${theme?.colors?.whites[4]}`,
        width: '5px',
        height: '100%',
      },
    }),
    [theme?.colors?.whites],
  );

  return (
    <FlexWithTooltip
      as="span"
      variant={`tag.${variant}`}
      {...props}
      {...(tooltip && { tooltipProps: { content: tooltip } })}
      sx={{
        ...variantSpecificSx,
        ...(sx && sx),
        ...(overlapping && overlappingSx),
      }}
    >
      {variant !== 'outline' && variant !== 'solid' && (
        <ColorSquare color={color} fillType={variant === 'informational' ? 'border' : 'full'} />
      )}
      <Text as="span" data-text>
        {children}
      </Text>
    </FlexWithTooltip>
  );
};
