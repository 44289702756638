import { t } from '@lingui/macro';
import _ from 'lodash';
import { FC } from 'react';
import { Flex, Text, ThemeUICSSObject } from 'theme-ui';

import { Attachment } from 'components/ui/FileUpload/Attachment';
import { ParsedRequest } from 'state/requests';
import { useRequestDetails } from '../../../../../hooks/useRequestDetails';
import { requestChangeTextTileSx, requestChangeWrapperSx } from '../styles/styles';

const attachmentsWrapperSx: ThemeUICSSObject = { flexDirection: 'column', gap: 2, width: '40%' };

type Props = {
  requestDetails: ParsedRequest | null;
};

const OriginalAttachment: FC<Props> = ({ requestDetails }) => (
  <Flex sx={attachmentsWrapperSx}>
    {requestDetails?.originalEvent?.eventDetails.attachments?.map((fileId) => {
      const isRemoved = !requestDetails.newEvent.eventDetails.attachments?.includes(fileId);

      return <Attachment key={fileId} name={fileId} defaultValue={fileId} variant={isRemoved ? 'danger' : undefined} />;
    })}
  </Flex>
);

const NewAttachment: FC<Props> = ({ requestDetails }) => (
  <Flex sx={attachmentsWrapperSx}>
    {requestDetails?.newEvent?.eventDetails.attachments?.map((fileId) => {
      const isAdded = !requestDetails.originalEvent?.eventDetails.attachments?.includes(fileId);

      return <Attachment key={fileId} name={fileId} defaultValue={fileId} variant={isAdded ? 'success' : undefined} />;
    })}
  </Flex>
);

export const RequestChangeAttachment: FC = () => {
  const { parsedRequestDetails: requestDetails } = useRequestDetails();

  if (
    _.isEmpty(requestDetails?.originalEvent?.eventDetails.attachments) &&
    _.isEmpty(requestDetails?.newEvent.eventDetails.attachments)
  ) {
    return null;
  }

  return (
    <Flex sx={requestChangeWrapperSx}>
      <Text sx={requestChangeTextTileSx}>{t({ id: 'add_request.attachments' })}</Text>
      <OriginalAttachment requestDetails={requestDetails} />
      <NewAttachment requestDetails={requestDetails} />
    </Flex>
  );
};
