import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback, useEffect } from 'react';
import { Navigate, RouteState, useLocation } from 'react-router-dom';
import { Text } from 'theme-ui';

import { revertScheduleAction } from 'api/actions/calendar/calendarActions';
import { useModal } from 'components/Modal/output/useModal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'components/recipes/ConfirmModal/types';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { useRefreshCalendar } from '../../hooks/useRefreshCalendar';

type Props = Pick<RouteState, 'ids' | 'dates'>;

const DeleteAllUnpublishedChangesInnerModal: FC<Props> = ({ ids, dates }) => {
  useLingui();

  const { updateCalendarForIds, calendarInitialized, updateCalendar } = useRefreshCalendar(ids);
  const { reportInitialized, updateReportForIds } = useRefreshReport(ids);

  useEffect(() => {
    if (!calendarInitialized) {
      void updateCalendar();
    }
  }, [calendarInitialized, updateCalendar]);

  const titleRenderer = useCallback(
    () => t({ id: 'schedule.revert_unpublished_changes', message: 'Revert all unpublished changes?' }),
    [],
  );

  const contentRenderer = useCallback(
    () => (
      <Trans id="schedule.revert_unpublished_changes_info">
        <Text>You're about to delete all unpublished changes to the schedules for the selected teammates.</Text>
        <Text>
          <strong>This action can't be reversed!</strong>
        </Text>
      </Trans>
    ),
    [],
  );

  const onResponseCallback: NonNullable<ConfirmModalProps['onResponseCallback']> = useCallback(
    async (error) => {
      if (!error) {
        if (calendarInitialized) await updateCalendarForIds();
        if (reportInitialized) await updateReportForIds();
      }
    },
    [calendarInitialized, reportInitialized, updateCalendarForIds, updateReportForIds],
  );

  return (
    <ConfirmModal
      action={(body) => revertScheduleAction({ peopleIds: body, timeRange: dates })}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
      onResponseCallback={onResponseCallback}
    />
  );
};

export const DeleteAllUnpublishedChangesModal: FC = () => {
  const { state } = useLocation();
  const { ids, dates } = state || {};
  const { baseRoute } = useModal();

  if (!ids?.length || !dates) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return <DeleteAllUnpublishedChangesInnerModal ids={ids} dates={dates} />;
};
