import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, memo, useMemo } from 'react';
import { Control, useFieldArray, UseFormRegister, useFormState, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex, FlexProps } from 'theme-ui';
import { v1 as uuidv1 } from 'uuid';

import { MaskedTextInput } from 'components/ui/MaskedTextInput';
import { organizationDisplayCurrencySelector } from 'state/organizationSession';
import { defaultPayRate, EmployeeEmploymentDetailsFieldNames, EmployeeEmploymentDetailsFormState } from 'state/team';

import { FieldArrayManager } from './FieldArrayManager/FieldArrayManager';
import { PayRatesBasicControl, PayRatesBasicFormState, PayRatesBasicRegister } from './types';

type BasicControl = PayRatesBasicControl;
type BasicRegister = PayRatesBasicRegister;

type Props = {
  control: Control<EmployeeEmploymentDetailsFormState> | Control<PayRatesBasicFormState>;
  register: UseFormRegister<EmployeeEmploymentDetailsFormState> | UseFormRegister<PayRatesBasicFormState>;
} & FlexProps;

export const Rates: FC<Props> = ({ control, register, sx }) => {
  useLingui();

  const displayCurrency = useRecoilValue(organizationDisplayCurrencySelector);

  const { fields, remove, append, update } = useFieldArray({
    name: EmployeeEmploymentDetailsFieldNames.Rates,
    control: control as BasicControl,
    keyName: 'RHF_ID',
  });
  const { errors } = useFormState({ control: control as BasicControl });

  const watchRates = useWatch({ control: control as BasicControl, name: EmployeeEmploymentDetailsFieldNames.Rates });

  const controlledFields = useMemo(
    () =>
      fields
        .map((field) => {
          const currentRate = watchRates?.find(({ id }) => id === field.id);
          return {
            ...field,
            ...(currentRate && currentRate),
          };
        })
        .map(({ startDateUnix, ...rest }) => ({ startDateUnix: +startDateUnix, ...rest })),
    [fields, watchRates],
  );

  const getIndexByDate = (date: string) =>
    controlledFields.findIndex(({ startDateUnix }) => `${startDateUnix}` === date);

  const ratesDates = useMemo(() => controlledFields.map(({ startDateUnix }) => startDateUnix), [controlledFields]);

  return (
    <FieldArrayManager
      sx={sx}
      variant="date"
      onAdd={(date) => {
        append({
          ...defaultPayRate,
          startDateUnix: +date,
          id: uuidv1(),
        });
      }}
      onEdit={(oldDate, newDate) => {
        const index = getIndexByDate(oldDate);
        update(index, { ...controlledFields[index], startDateUnix: +newDate });
      }}
      onRemove={(date) => {
        const index = getIndexByDate(date);
        remove(index);
      }}
      froms={ratesDates}
      isDisabledGenerator={(date) => !!errors?.rates?.[getIndexByDate(date)]}
      fieldRenderer={(date) => {
        const dateIndex = getIndexByDate(date);
        const index = (() => {
          if (controlledFields[dateIndex]) return dateIndex;
          if (controlledFields[dateIndex + 1]) return dateIndex + 1;
          if (controlledFields[dateIndex - 1]) return dateIndex - 1;
          return dateIndex;
        })();
        if (!controlledFields[index]) return null;
        return (
          <Flex sx={{ mt: 2, justifyContent: 'space-between', width: '296px' }}>
            <MaskedTextInput
              key={`rates.${index}.normal`}
              mask={Number}
              radix="."
              mapToRadix={['.', ',']}
              showMask
              label={t({ id: 'team.pay_details.regular', message: 'Regular' })}
              size="sm"
              id={`rates.${index}.normal`}
              placeholder=""
              variant="rounded"
              error={!!errors.rates?.[index]?.normal}
              errorMessage={errors.rates?.[index]?.normal?.message}
              defaultValue={controlledFields[index].normal}
              apendWith={`${displayCurrency || ''}/${t({
                id: 'global.forms.pay_rate.hour',
                message: 'hour',
              })}`}
              type="number"
              max={999}
              min={0}
              {...(register as BasicRegister)(`rates.${index}.normal`)}
            />
            <MaskedTextInput
              key={`rates.${index}.overtime`}
              sx={{ ml: 1 }}
              labelProps={{ sx: { left: '.75rem' } }}
              mask={Number}
              radix="."
              mapToRadix={['.', ',']}
              showMask
              label={t({ id: 'team.pay_details.overtime', message: 'Overtime' })}
              size="sm"
              id={`rates.${index}.overtime`}
              placeholder=""
              variant="rounded"
              error={!!errors.rates?.[index]?.overtime}
              errorMessage={errors.rates?.[index]?.overtime?.message}
              defaultValue={controlledFields[index].overtime}
              apendWith={`${displayCurrency || ''}/${t({
                id: 'global.forms.pay_rate.hour',
                message: 'hour',
              })}`}
              type="number"
              max={999}
              min={0}
              {...(register as BasicRegister)(`rates.${index}.overtime`)}
            />
          </Flex>
        );
      }}
    />
  );
};

export const RatesFieldArray = memo(Rates);
