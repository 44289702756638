import { atom, atomFamily, selectorFamily } from 'recoil';
import dayjs from 'dayjs';

import {
  DateRangeFilters,
  FilterGroupNames,
  FilterProps,
  TeammatesFilters,
  WorkStatusesFilters,
} from 'layouts/AuthorizedApp/AsideFilters/types';
import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';
import { getStringWithReducedWhiteSpaces } from 'utils/whiteSpaceReducer';

import { DateRangeAtomLocalStorageMetadata, getAtomsLocalStorageMetadataKey } from './common';

//
// SEARCH FILTER
//
export enum SEARCH_FILTER_TYPE {
  TEAMMATES = 'TEAMMATES',
  TIME_CLOCKS = 'TIME_CLOCKS',
  WORK_STATUSES = 'WORK_STATUSES',
  LOCATIONS = 'LOCATIONS',
  DRAWER__TIME_TRACKING = 'DRAWER__TIME_TRACKING',
  TAGS__FILTER_GROUP = 'TAGS__FILTER_GROUP',
  ROLES__FILTER_GROUP = 'ROLES__FILTER_GROUP',
  LOCATIONS__FILTER_GROUP = 'LOCATIONS__FILTER_GROUP',
  WORK_STATUSES__FILTER_GROUP = 'WORK_STATUSES__FILTER_GROUP',
  WORK_POSITION__FILTER_GROUP = 'WORK_POSITION__FILTER_GROUP',
  TEAMMATES_PICK_TEAMMATES_FILTERS = 'TEAMMATES_PICK_TEAMMATES_FILTERS',
  WORK_POSITION_TEAM__FILTER_GROUP = 'WORK_POSITION_TEAM__FILTER_GROUP',
  CLOCK_LOG__TIME_EVENTS = 'CLOCK_LOG__TIME_EVENTS',
  MANAGE_LOCATIONS = 'MANAGE_LOCATIONS',
  MANAGE_WORK_STATUSES = 'MANAGE_WORK_STATUSES',
  MANAGE_TAGS = 'MANAGE_TAGS',
  MANAGE_ROLES = 'MANAGE_ROLES',
  MANAGE_WORK_POSITIONS = 'MANAGE_WORK_POSITIONS',
}

export const searchFilterValueAtomFamily = atomFamily<string, SEARCH_FILTER_TYPE | undefined>({
  key: 'searchFilterValue',
  default: '',
});

export const searchFilterValueSelectorFamily = selectorFamily<string, SEARCH_FILTER_TYPE>({
  key: 'searchFilterValueSelector',
  get:
    (searchFilterType) =>
    ({ get }) =>
      get(searchFilterValueAtomFamily(searchFilterType)),
  set:
    (searchFilterType) =>
    ({ set, get }, newValue) => {
      if (isRecoilDefaultValue(newValue)) return;

      const searchFilterValueAtom = searchFilterValueAtomFamily(searchFilterType);
      const searchFilterValue = get(searchFilterValueAtom);

      if (
        getStringWithReducedWhiteSpaces(newValue.replaceAll(',', '')) !==
        getStringWithReducedWhiteSpaces(searchFilterValue.replaceAll(',', ''))
      ) {
        set(searchFilterValueAtom, newValue);
      }
    },
});

export const parsedSearchFilterValueSelectorFamily = selectorFamily<string[], SEARCH_FILTER_TYPE | undefined>({
  key: 'parsedSearchFilterValueSelector',
  get:
    (searchFilterType) =>
    ({ get }) =>
      searchFilterType
        ? getStringWithReducedWhiteSpaces(get(searchFilterValueAtomFamily(searchFilterType)))
            .trim()
            .split(',')
            .map((v) => v.trim())
            .filter((v) => !!v)
        : [],
});

//
// UNIVERSAL FILTER
// GROUP FILTER
//

export type GroupName = FilterProps['groupName'];

export type IsActiveFilterConfig = {
  filterId: FilterProps['id'];
  groupName: GroupName;
};

export type FilterGroupState = string[] | null;

export enum RequestFilterState {
  Pending = 0,
  Accepted = 1,
  Rejected = 2,
  Deleted = 3,
}

export const getFilterGroupStateAtomDefaultValue = (groupName: GroupName) => {
  if (groupName === FilterGroupNames.TEAMMATES || groupName === FilterGroupNames.TEAMMATES_PICK_TEAMMATES_FILTERS)
    return [TeammatesFilters.ACTIVE];
  if (groupName === FilterGroupNames.WORK_STATUSES_STATE) return [WorkStatusesFilters.ACTIVE];
  if (groupName === FilterGroupNames.DATE_RANGE) return [DateRangeFilters.MONTH];
  if (groupName === FilterGroupNames.REQUESTS_USAGE_OVERVIEW_DATE_RANGE) return [DateRangeFilters.YEAR];
  if (groupName === FilterGroupNames.REQUEST_STATE)
    return [`${RequestFilterState.Accepted}`, `${RequestFilterState.Pending}`, `${RequestFilterState.Rejected}`];
  return null;
};

export const filterGroupStateAtomFamily = atomFamily<FilterGroupState, GroupName>({
  key: 'filterGroupState',
  default: getFilterGroupStateAtomDefaultValue,
  effects: [
    ({ node, setSelf, onSet }) => {
      const { key } = node;
      if (key.includes(FilterGroupNames.DATE_RANGE)) {
        const savedValue = localStorage.getItem(key);

        if (savedValue != null) {
          setSelf(JSON.parse(savedValue) as FilterGroupState);
        }

        onSet((newValue, _, isReset) =>
          isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue)),
        );
      }
    },
  ],
});

export const isActiveFilterSelectorFamily = selectorFamily<boolean, IsActiveFilterConfig>({
  key: 'isActiveFilter',
  get:
    ({ groupName, filterId }) =>
    ({ get }) => {
      const filterGroupState = get(filterGroupStateAtomFamily(groupName));

      if (filterGroupState === null) {
        return filterGroupState === filterId;
      }
      if (filterId === null) return false;

      return filterGroupState.includes(filterId);
    },
  set:
    ({ groupName, filterId }) =>
    ({ set, get }, newValue) => {
      const filterGroupStateAtom = filterGroupStateAtomFamily(groupName);
      const filterGroupState = get(filterGroupStateAtom);

      if (!newValue) {
        if (filterId === null) {
          return;
        }

        if (filterGroupState === null) {
          set(filterGroupStateAtom, [filterId]);
          return;
        }

        const newFilterGroupState = filterGroupState.filter((id) => id !== filterId);
        set(filterGroupStateAtom, newFilterGroupState.length ? newFilterGroupState : null);
        return;
      }

      if (filterId === null) {
        set(filterGroupStateAtom, filterId);
        return;
      }

      if (
        typeof filterGroupState === 'string' ||
        filterGroupState === null ||
        groupName === FilterGroupNames.DATE_RANGE
      ) {
        set(filterGroupStateAtom, [filterId]);
        return;
      }

      set(filterGroupStateAtom, [...filterGroupState, filterId]);
    },
});

//
// DATE RANGE FILTER
//

export type DateRangeFilterAtom = {
  startDateUnix: number;
  endDateUnix: number;
};

export const dateRangeFilterAtom = atom<DateRangeFilterAtom | null>({
  key: 'dateRangeFilterAtom',
  default: null,
  effects: [
    ({ node, onSet }) => {
      const { key } = node;
      const metadataLocalStorageKey = getAtomsLocalStorageMetadataKey(key);

      onSet((newValue, _, isReset) => {
        if (isReset) {
          localStorage.removeItem(metadataLocalStorageKey);
          return;
        }

        const metadata: DateRangeAtomLocalStorageMetadata = {
          lastChangeUnix: dayjs().unix(),
        };

        localStorage.setItem(metadataLocalStorageKey, JSON.stringify(metadata));
      });
    },
  ],
});

export const dateRangeRequestsUsageOverviewFilterAtom = atom<DateRangeFilterAtom | null>({
  key: 'dateRangeRequestsUsageOverviewFilterAtom',
  default: null,
  effects: [
    ({ node, onSet }) => {
      const { key } = node;
      const metadataLocalStorageKey = getAtomsLocalStorageMetadataKey(key);

      onSet((newValue, _, isReset) => {
        if (isReset) {
          localStorage.removeItem(metadataLocalStorageKey);
          return;
        }

        const metadata: DateRangeAtomLocalStorageMetadata = {
          lastChangeUnix: dayjs().unix(),
        };

        localStorage.setItem(metadataLocalStorageKey, JSON.stringify(metadata));
      });
    },
  ],
});

//
//
//
// FILTERS COLLAPSE STATE
//
//
//

export const asideFilterExpandedAtom = atom<boolean>({
  key: 'asideFilterExpanded',
  default: true,
  effects: [
    ({ node, setSelf, onSet }) => {
      const { key } = node;
      if (key.includes('asideFilterExpanded')) {
        const savedValue = localStorage.getItem(key);

        if (savedValue != null) {
          setSelf(JSON.parse(savedValue) as boolean);
        }

        onSet((newValue, _, isReset) =>
          isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue)),
        );
      }
    },
  ],
});

//
//
//
// PickTeammatesFilters.tsx - FILTERS FROM MAIN VIEW RESTORED FLAG
//
//
//
export const pickTeammatesFiltersRestoredAtom = atom<boolean>({
  key: 'pickTeammatesFiltersRestored',
  default: false,
});
