import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { ClipboardEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { Flex, Input, Text, ThemeUIStyleObject } from 'theme-ui';

import { useTheme } from 'styles/useTheme';
import { delay } from 'utils/delay';
import { focusInput } from 'utils/focusInput';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { silentSetValue } from 'utils/silentSetValue';
import { Select, SelectProps } from '../Select/Select';

import { DurationPickerProps, InputNames } from './types';
import { useDurationPickerLogic } from './useDurationPickerLogic';
import { getMinutesSelectOptions, getSelectOptions } from './utils';

const intervalTypeTextSx: ThemeUIStyleObject = {
  ml: 1,
  mr: 2,
};

const defaultProps: Partial<DurationPickerProps> = {
  onValidError: undefined,
  onClearError: undefined,
  hours: true,
  minutes: true,
  seconds: true,
  quickSelectOptions: undefined,
  minDuration: undefined,
  maxDuration: undefined,
  isStatic: false,
  inputVariant: 'default',
  extended: false,
  sortingOrder: 'desc',
};

export const DurationPicker = React.forwardRef<HTMLInputElement, DurationPickerProps>(
  (
    {
      isStatic,
      minDuration,
      maxDuration,
      quickSelectOptions,
      size = 'default',
      hours,
      minutes,
      seconds,
      onChange,
      onBlur,
      onValidError,
      onClearError,
      inputVariant = 'default',
      extended,
      sortingOrder = 'desc',
      ...props
    }: DurationPickerProps,
    ref,
  ) => {
    useLingui();
    const [afterFirstBlur, setAfterFirstBlur] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);

    const isFocusRef = useRef<boolean>(false);
    const isBackspaceModeRef = useRef(false);

    const { theme } = useTheme();

    const {
      durationPickerError,
      durationPickerErrorMessage,
      hiddenRef,
      hoursRef,
      minutesRef,
      secondsRef,
      dispatchBlurEvent,
      isPickerEmpty,
      getFirstUnfilledInput,
      getSecondsFromUserInput,
      getNextInput,
      getPreviousInput,
      getInputRefByName,
      setInputsValues,
      formatUserInput,
      validate,
      updateHiddenInputValue,
      extendedInput,
    } = useDurationPickerLogic({
      minDuration,
      maxDuration,
      hours,
      minutes,
      seconds,
      onValidError,
      onClearError,
      inputVariant,
      extended,
    });

    const handleUserChange = useCallback(
      ({ target: { value, name: inputName, selectionStart: cursorPosition } }: React.ChangeEvent<HTMLInputElement>) => {
        const inputsUpdates: {
          inputRef: React.MutableRefObject<HTMLInputElement | null>;
          newValue?: string;
          newCursorPosition?: number;
        }[] = [];

        const isInputExtended =
          ((inputName as InputNames) === InputNames.hours && extendedInput === 'hours') ||
          ((inputName as InputNames) === InputNames.minutes && extendedInput === 'minutes') ||
          ((inputName as InputNames) === InputNames.seconds && extendedInput === 'seconds');

        const currentInputRef = getInputRefByName(inputName);
        const prevInputRef = getPreviousInput(inputName);
        const nextInputRef = getNextInput(inputName);

        const minutesSecondsValueParser = (newValue: string, ext?: boolean) => {
          if (newValue.length === 3 && ext) {
            return `${Math.min(+newValue[0], 9)}${newValue[1]}${newValue[2]}`;
          }
          if (newValue.length === 2 && !ext) {
            return `${Math.min(+newValue[0], 5)}${newValue[1]}`;
          }
          return newValue;
        };

        switch (value.length) {
          case 0:
            if (prevInputRef) {
              const isPrevInputExtended =
                ((inputName as InputNames) === InputNames.minutes && extendedInput === 'hours') ||
                ((inputName as InputNames) === InputNames.seconds && extendedInput === 'minutes');

              inputsUpdates.push({
                inputRef: prevInputRef,
                newCursorPosition: isPrevInputExtended ? 3 : 2,
              });
            }
            break;
          case 1:
            break;
          case 2:
            if (isInputExtended) break;

            if ((inputName as InputNames) !== InputNames.hours && +value > 59) {
              inputsUpdates.push({
                inputRef: currentInputRef,
                newValue: '59',
              });
            }

            if (cursorPosition === 2 && nextInputRef) {
              inputsUpdates.push({
                inputRef: nextInputRef,
                newCursorPosition: 0,
              });
            }
            break;
          default:
            if (cursorPosition === 3 && isInputExtended) {
              if (nextInputRef) {
                inputsUpdates.push({
                  inputRef: nextInputRef,
                  newCursorPosition: 0,
                });
              }
            }

            if (
              (cursorPosition && cursorPosition > 2 && !isInputExtended) ||
              (cursorPosition && cursorPosition > 3 && isInputExtended)
            ) {
              if (isBackspaceModeRef.current && nextInputRef) {
                const currentInputNewValue = value.substring(0, isInputExtended ? 3 : 2);
                const nextInputFirstChar = value[2];

                const nextInputValue = nextInputRef?.current?.value;

                const nextInputNewValue = nextInputValue
                  ? `${nextInputFirstChar}${nextInputValue.substring(1)}`
                  : nextInputFirstChar;

                inputsUpdates.push({
                  inputRef: currentInputRef,
                  newValue: currentInputNewValue,
                });

                inputsUpdates.push({
                  inputRef: nextInputRef,
                  newValue: isInputExtended
                    ? minutesSecondsValueParser(nextInputNewValue, true)
                    : minutesSecondsValueParser(nextInputNewValue),
                  newCursorPosition: 1,
                });

                break;
              }

              const newValue = (() => {
                if ((inputName as InputNames) === InputNames.hours) return value.slice(isInputExtended ? -3 : -2);

                if ((inputName as InputNames) === InputNames.minutes) {
                  return minutesSecondsValueParser(value.slice(isInputExtended ? -3 : -2), isInputExtended);
                }

                return minutesSecondsValueParser(value.slice(isInputExtended ? -3 : -2), isInputExtended);
              })();

              inputsUpdates.push({
                inputRef: currentInputRef,
                newValue,
              });

              break;
            } else if (cursorPosition) {
              const newValue = (() => {
                const filteredValue = [...value].filter((v, i) => i !== cursorPosition).join('');

                if ((inputName as InputNames) === InputNames.hours) return filteredValue;

                if (isInputExtended) return minutesSecondsValueParser(filteredValue, true);

                return minutesSecondsValueParser(filteredValue);
              })();

              const isCursorInEndPosition = isInputExtended ? cursorPosition === 3 : cursorPosition === 2;

              inputsUpdates.push({
                inputRef: currentInputRef,
                newValue,
                newCursorPosition: !isCursorInEndPosition ? cursorPosition : undefined,
              });

              if (isCursorInEndPosition && nextInputRef) {
                inputsUpdates.push({
                  inputRef: nextInputRef,
                  newCursorPosition: 0,
                });
              }

              break;
            }
        }

        inputsUpdates.forEach(({ inputRef, newValue, newCursorPosition }) => {
          if (!_.isNil(newValue)) {
            silentSetValue(inputRef, newValue);
          }

          if (!_.isNil(newCursorPosition)) {
            focusInput(inputRef, newCursorPosition, newCursorPosition);
          }
        });

        updateHiddenInputValue();

        isBackspaceModeRef.current = false;
      },
      [getInputRefByName, getPreviousInput, getNextInput, updateHiddenInputValue, extendedInput],
    );

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (props.disabled) return;
        const { key, target, ctrlKey } = e;
        const { name: inputName, value, selectionStart: cursorPosition } = target as HTMLInputElement;

        const prevInput = getPreviousInput(inputName);
        const nextInput = getNextInput(inputName);
        const selectedText = window?.getSelection()?.toString() || '';

        if (
          !/[0-9]/.test(key) &&
          key !== 'Tab' &&
          key !== 'ArrowLeft' &&
          key !== 'Backspace' &&
          key !== 'Ctrl' &&
          key !== 'ArrowRight' &&
          key !== 'ArrowUp' &&
          key !== 'ArrowDown' &&
          !ctrlKey
        ) {
          e.preventDefault();
        }

        switch (key) {
          case 'ArrowRight':
            if (cursorPosition === value.length && nextInput) {
              e.preventDefault();
              focusInput(nextInput);
            }
            break;

          case 'ArrowLeft':
            if (cursorPosition === 0 && prevInput) {
              e.preventDefault();
              focusInput(prevInput);
            }
            break;

          case 'Backspace':
            if (cursorPosition === 0 && selectedText.length === 0 && prevInput) {
              e.preventDefault();
              const isPrevInputExtended =
                ((inputName as InputNames) === InputNames.minutes && extendedInput === 'hours') ||
                ((inputName as InputNames) === InputNames.seconds && extendedInput === 'minutes');
              const position = isPrevInputExtended ? 3 : 2;
              focusInput(prevInput, position, position);
            }
            break;

          default:
        }
      },
      [extendedInput, getNextInput, getPreviousInput, props.disabled],
    );

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Backspace':
          isBackspaceModeRef.current = true;
          e.preventDefault();
          return;
        case 'ArrowRight':
          e.preventDefault();
          return;
        case 'ArrowLeft':
          e.preventDefault();
          break;
        default:
          break;
      }
    };

    const handleFocus = () => {
      setHasFocus(true);
      isFocusRef.current = true;
    };

    const handleHiddenInputBlur: NonNullable<SelectProps['onBlur']> = useCallback(
      (e) => {
        isBackspaceModeRef.current = false;
        setAfterFirstBlur(true);
        if (onBlur) {
          onBlur(e);
        }
      },
      [onBlur],
    );

    const handleBlur = useCallback(async () => {
      setHasFocus(false);
      isFocusRef.current = false;
      await delay(0);

      if (!isFocusRef.current) {
        isBackspaceModeRef.current = false;
        if (!isPickerEmpty()) {
          formatUserInput();
          const newHiddenInputValue = getSecondsFromUserInput();
          if (`${newHiddenInputValue}` !== `${hiddenRef.current?.value}`) {
            setNativeValue(hiddenRef, newHiddenInputValue);
          }
          // workaround for what is probably a useOnOwnValueChange bug:
          // this is needed when after reset, user fills out the value that was filled before
          // and the browser does not trigger the input event as it sees no change
          validate(`${newHiddenInputValue}`);
        }

        setAfterFirstBlur(true);
        dispatchBlurEvent();
      }
    }, [formatUserInput, getSecondsFromUserInput, dispatchBlurEvent, isPickerEmpty, hiddenRef, validate]);

    const handleHiddenInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (onChange) {
          onChange(e);
        }

        if (value.length) {
          const secondsFormUserInput = getSecondsFromUserInput();
          if (value !== `${secondsFormUserInput})`) {
            setInputsValues(value);
          }
        }

        validate(value);
      },
      [setInputsValues, onChange, validate, getSecondsFromUserInput],
    );

    const updateView = useCallback(
      (value: string) => {
        setInputsValues(value);
        const valid = validate(value);
        setAfterFirstBlur(!valid);
      },
      [setInputsValues, validate],
    );

    const handleOnPaste = useCallback<ClipboardEventHandler>((e) => {
      const textWithoutWhiteSpaces = e.clipboardData.getData('text').replaceAll(/\s+/g, '');
      if (!textWithoutWhiteSpaces || _.isNaN(+textWithoutWhiteSpaces)) e.preventDefault();
    }, []);

    const inputProps = useMemo(
      () => ({
        sx: theme.forms.timePicker.twoDigitInput[size],
        autoComplete: 'off',
        onKeyUp: handleKeyUp,
        onKeyDown: handleKeyDown,
        onChange: handleUserChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
        onPaste: handleOnPaste,
        readOnly: isStatic,
      }),
      [
        theme.forms.timePicker.twoDigitInput,
        size,
        handleKeyDown,
        handleUserChange,
        handleBlur,
        handleOnPaste,
        isStatic,
      ],
    );

    const options = useMemo(
      () =>
        inputVariant === 'minutes'
          ? getMinutesSelectOptions(quickSelectOptions, sortingOrder)
          : getSelectOptions(quickSelectOptions, sortingOrder),
      [inputVariant, quickSelectOptions, sortingOrder],
    );

    return (
      <Select
        {...props}
        ref={mergeRefs([ref, hiddenRef])}
        placeholder=""
        size={size}
        focusThiefElement={!hasFocus ? getFirstUnfilledInput() || undefined : undefined}
        alwaysHideOptions={!quickSelectOptions}
        ignoreOptions={!quickSelectOptions}
        error={props.error || (durationPickerError && afterFirstBlur)}
        errorMessage={durationPickerErrorMessage || props.errorMessage}
        options={options}
        onBlur={handleHiddenInputBlur}
        onChange={handleHiddenInputChange}
        onUpdateFieldView={updateView}
        customContent={
          <Flex sx={{ alignItems: 'center', borderRadius: 'inherit' }}>
            {hours && (
              <>
                <Input {...inputProps} name={InputNames.hours} ref={hoursRef} />
                <Text sx={intervalTypeTextSx}>
                  <Trans id="duration_picker.h">h</Trans>
                </Text>
              </>
            )}
            {minutes && (
              <>
                <Input {...inputProps} name={InputNames.minutes} ref={minutesRef} />
                <Text sx={intervalTypeTextSx}>
                  <Trans id="duration_picker.minutes">min</Trans>
                </Text>
              </>
            )}
            {seconds && (
              <>
                <Input {...inputProps} name={InputNames.seconds} ref={secondsRef} />
                <Text sx={intervalTypeTextSx}>
                  <Trans id="duration_picker.sec">sec</Trans>
                </Text>
              </>
            )}
          </Flex>
        }
      />
    );
  },
);

DurationPicker.defaultProps = defaultProps;
