import _ from 'lodash';
import { UseFormGetValues } from 'react-hook-form';
import { atom, selector, selectorFamily } from 'recoil';

import {
  CalendarDayDataType,
  CalendarDaysType,
  FetchCalendarResponse,
  RequestLimitsAndUsagePerYear,
  ScheduleActionData,
  ScheduleWizardOptionsProps,
} from 'api/actions/calendar/calendarActions.types';
import { AiVariant, Employee, TimeOffType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { DateTimeKind, RequestFormType, RequestState, ScheduleData } from 'api/actions/requests/requestsActions.types';
import { ErrorResponse } from 'api/types';
import { ListNames, StickyListProps } from 'components/StickyList/types';
import { ScheduleType } from 'constants/calendar';
import { TIME_OFF_DEFAULT_VIEW_SETTINGS_TYPE_ID } from 'constants/common';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { appRoutingFlagAtomFamily } from 'hooks/useAppRouting/useAppRouting';
import {
  CalendarSchedulesFilters,
  FilterGroupNames,
  HideAcceptedRequestsFilter,
  HideEmptyFilter,
} from 'layouts/AuthorizedApp/AsideFilters/types';
import { DefaultScheduleFormValues } from 'pages/Calendar/output/types';
import { isDaysEmpty } from 'utils/isDaysEmpty/isDaysEmpty';
import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';

import { filteredEmployeesSelector, ParsedEmployee, parsedEmployeesSelector } from './employees';
import { DateRangeFilterAtom, filterGroupStateAtomFamily, isActiveFilterSelectorFamily } from './filters';
import { selectedRowsIdsSelectorFamily } from './list';
import { schedulesSettingsSelector, timeOffTypesDictionarySelector } from './organizationSession';
import { userSessionAtom } from './userSession';

export enum ScheduleWizardStep {
  SelectUsers = 1,
  SetSchedule = 2,
  SetDates = 3,
}

//
// CALENDAR VIEW SETTINGS
//
export type CalendarViewSettings = {
  groupSchedulesByHours: boolean;
  personCellVisibleLimits: string[];
};
export const calendarViewSettingsSelector = selector<CalendarViewSettings>({
  key: 'calendar_view_settings',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    const timeOffs = get(timeOffTypesDictionarySelector);

    const defaultTimeOff = timeOffs && timeOffs[TIME_OFF_DEFAULT_VIEW_SETTINGS_TYPE_ID];

    if (!userSession || !userSession.viewsSettings || !userSession.viewsSettings.calendar) {
      return {
        groupSchedulesByHours: true,
        personCellVisibleLimits: defaultTimeOff?.isActive ? [TIME_OFF_DEFAULT_VIEW_SETTINGS_TYPE_ID] : [],
      };
    }

    if (!userSession.viewsSettings.calendar.personCellVisibleLimits) {
      return {
        groupSchedulesByHours: !!userSession.viewsSettings.calendar.groupSchedulesByHours,
        personCellVisibleLimits: defaultTimeOff?.isActive ? [TIME_OFF_DEFAULT_VIEW_SETTINGS_TYPE_ID] : [],
      };
    }

    return {
      groupSchedulesByHours: !!userSession.viewsSettings.calendar.groupSchedulesByHours,
      personCellVisibleLimits: userSession.viewsSettings.calendar.personCellVisibleLimits.filter((typeId) => {
        const typeOff = timeOffs && timeOffs[typeId];

        return typeOff?.isActive;
      }),
    };
  },
});

export const calendarViewSettingsVisibleLimitsSelector = selector<TimeOffType[] | null>({
  key: 'calendarViewSettingsVisibleLimits',
  get: ({ get }) => {
    const timeOffs = get(timeOffTypesDictionarySelector);
    const calendarViewSettings = get(calendarViewSettingsSelector);
    const { personCellVisibleLimits } = calendarViewSettings;
    const viewOnlySchedules = get(appRoutingFlagAtomFamily('isCalendarSchedules'));
    const viewOnlyAvailable = get(appRoutingFlagAtomFamily('isCalendarAvailability'));

    if (!timeOffs || !personCellVisibleLimits.length || viewOnlySchedules || viewOnlyAvailable) return null;

    return personCellVisibleLimits.map((id) => timeOffs[id]);
  },
});

//
// DAY DETAILS
//
export const dayDetailsVisibleAtom = atom<boolean>({
  key: 'dayDetailsVisible',
  default: false,
});

//
// GENERATE AI SHEDULES
//
export const awaitingAiSchedulesGenerationAtom = atom<boolean>({
  key: 'awaitingAiSchedulesGeneration',
  default: false,
});

//
// AI SCHEDULES TREND SCALE
//
const selectedAiSchedulesTrendScaleAtom = atom<AiVariant | null>({
  key: 'selectedAiSchedulesTrendScale',
  default: null,
});

export const defaultAiVariantSelector = selector({
  key: 'defaultAiVariantSelector',
  get: ({ get }) => {
    const schedulesSettings = get(schedulesSettingsSelector);

    if (!schedulesSettings || !_.isNumber(schedulesSettings?.defaultAiVariant)) {
      return AiVariant.Strict;
    }

    return schedulesSettings.defaultAiVariant;
  },
});

export const aiSchedulesTrendScaleSelector = selector<AiVariant>({
  key: 'aiSchedulesTrendScale',
  get: ({ get }) => {
    const defaultAiVariant = get(defaultAiVariantSelector);
    const selectedAiSchedulesTrendScale = get(selectedAiSchedulesTrendScaleAtom);

    if (!_.isNumber(selectedAiSchedulesTrendScale)) {
      return defaultAiVariant;
    }

    return selectedAiSchedulesTrendScale;
  },
  set: ({ set }, newState) => {
    if (isRecoilDefaultValue(newState)) return;

    set(selectedAiSchedulesTrendScaleAtom, newState);
  },
});

//
// CALENDAR
//
type CurrentlyFetchedCalendarDateRange = {
  currentlyFetchedDates: DateRangeFilterAtom;
  error: boolean;
};
export const currentlyFetchedCalendarDateRangeAtom = atom<CurrentlyFetchedCalendarDateRange | null>({
  key: 'currentlyFetchedCalendarDateRange',
  default: null,
});

export const calendarResponseAtom = atom<(FetchCalendarResponse & ErrorResponse) | null>({
  key: 'calendarResponse',
  default: null,
});

export const calendarResponseSelector = selector({
  key: 'calendarResponse_selector',
  get: ({ get }) => {
    const calendarResponse = get(calendarResponseAtom);

    if (!calendarResponse || calendarResponse.innerCode) return null;

    return calendarResponse;
  },
});

export const calendarResponseErrorSelector = selector<ErrorResponse | null>({
  key: 'calendarResponseError',
  get: ({ get }) => {
    const calendarResponse = get(calendarResponseAtom);

    if (!calendarResponse) return null;

    const { innerCode, message, data } = calendarResponse;

    if (!innerCode) return null;

    return { innerCode, message, data };
  },
});

export const calendarHolidaysSelector = selector({
  key: 'calendar_holidays',
  get: ({ get }) => {
    const calendar = get(calendarResponseSelector);

    if (!calendar) return null;

    const { holidays } = calendar;
    const holidaysMap = new Map(holidays);

    return holidaysMap;
  },
});

export const calendarDaysSelector = selector({
  key: 'calendar_days',
  get: ({ get }) => {
    const calendar = get(calendarResponseSelector);

    if (!calendar) return null;

    const { days } = calendar;

    return days;
  },
});

type CalendarEmployeeData = Pick<ParsedEmployee, 'id' | 'avatarUrl' | 'name' | 'tags' | 'role' | 'editablePermissions'>;

export type CalendarData = {
  days: Map<number, CalendarDayDataType | null>;
  hasAiSchedules?: boolean;
  hasErrors?: boolean;
  hasPendingRequests?: boolean;
  hasStandardSchedules?: boolean;
  hasUnpublishedChanges?: boolean;
  hasWarnings?: boolean;
  requestLimitsAndUsagePerYear?: RequestLimitsAndUsagePerYear[];
  requestPositionMap?: number[];
  schedulePositionMap?: number[];
  totalScheduledDays?: number;
  totalScheduledWorkTimeDuration?: number;
} & CalendarEmployeeData;

export type CalendarMap = Map<Employee['id'], CalendarData>;

export const calendarSelector = selector({
  key: 'calendar_selector',
  get: ({ get }) => {
    const calendar = get(calendarResponseSelector);
    const employeesMap = get(parsedEmployeesSelector);
    const aiSchedulesTrendScaleSelectorValue = get(aiSchedulesTrendScaleSelector);

    if (!calendar || !employeesMap) return null;

    const { employeesData } = calendar;
    const employeesDataMap = new Map(employeesData);
    const calendarMap: CalendarMap = new Map();

    employeesMap.forEach((data, employeeId) => {
      const { name, role, tags, avatarUrl, editablePermissions } = data;
      const employeeData = employeesDataMap.get(`${employeeId}`);

      const aiSummaries = employeeData?.aiSummaries
        ? new Map(employeeData?.aiSummaries).get(aiSchedulesTrendScaleSelectorValue)
        : undefined;
      const summaryBase = aiSummaries || employeeData;

      calendarMap.set(employeeId, {
        avatarUrl,
        days: new Map(employeeData?.days),
        editablePermissions,
        hasAiSchedules: summaryBase?.hasAiSchedules,
        hasErrors: summaryBase?.hasErrors,
        hasPendingRequests: summaryBase?.hasPendingRequests,
        hasStandardSchedules: summaryBase?.hasStandardSchedules,
        hasUnpublishedChanges: summaryBase?.hasUnpublishedChanges,
        hasWarnings: summaryBase?.hasWarnings,
        id: employeeId,
        name,
        requestLimitsAndUsagePerYear: employeeData?.requestLimitsAndUsagePerYear,
        role,
        tags,
        totalScheduledDays: summaryBase?.totalScheduledDays,
        totalScheduledWorkTimeDuration: summaryBase?.totalScheduledWorkTimeDuration,
      });
    });

    return calendarMap;
  },
});

export type SchedulesMap = Map<ScheduleData['id'], ScheduleData & { employeeId: string }>;

export const calendarSchedulesSelector = selector({
  key: 'calendar_schedules',
  get: ({ get }) => {
    const calendar = get(calendarResponseSelector);

    if (!calendar) return null;

    const { employeesData } = calendar;
    const employeesDataMap = new Map(employeesData);

    const schedulesMap: SchedulesMap = new Map();

    employeesDataMap.forEach((data, employeeId) => {
      const { days } = data;
      if (!days) return;

      const daysMap = new Map(days);

      daysMap.forEach((dayData) => {
        if (!dayData) return;

        const { schedules } = dayData;
        if (!schedules) return;

        schedules.forEach((schedule) => {
          schedulesMap.set(schedule.id, {
            ...schedule,
            employeeId,
          });
        });
      });
    });

    return schedulesMap;
  },
});

export const calendarRecentSchedulesSelector = selector({
  key: 'calendar_recent_schedules',
  get: ({ get }) => {
    const calendar = get(calendarResponseSelector);

    if (!calendar) return null;

    const { recentSchedules } = calendar;

    if (!recentSchedules) return null;

    return recentSchedules;
  },
});

export const selectedCalendarIdsSelector = selector<CalendarEmployeeData['id'][]>({
  key: 'calendar_selector__selected_ids',
  get: ({ get }) => get(selectedRowsIdsSelectorFamily(ListNames.CALENDAR)),
});

export const filterByAiSchedulesTrendScale = (
  schedules: CalendarDayDataType['schedules'] | null,
  aiSchedulesTrendScale: AiVariant,
): CalendarDayDataType['schedules'] => {
  let newSchedules: CalendarDayDataType['schedules'] = [];

  if (schedules && schedules.length > 0) {
    const filteredSchedules = schedules.filter(({ aiVariant }) => {
      const isAi = _.isNumber(aiVariant);
      if (!isAi) return true;
      return aiVariant === aiSchedulesTrendScale;
    });

    newSchedules = filteredSchedules;
  }

  return newSchedules;
};

export const filteredCalendarSelector = selector<CalendarMap | null>({
  key: 'calendar_selector_filtered',
  get: ({ get }) => {
    const calendar = get(calendarSelector);
    const calendarResponseError = get(calendarResponseErrorSelector);
    const filteredEmployees = get(filteredEmployeesSelector);
    const { groupSchedulesByHours } = get(calendarViewSettingsSelector);

    const scheduleTypes = get(filterGroupStateAtomFamily(FilterGroupNames.CALENDAR));
    const aiSchedulesTrendScaleSelectorValue = get(aiSchedulesTrendScaleSelector);
    const requestTypes = get(filterGroupStateAtomFamily(FilterGroupNames.REQUEST_TYPES));
    const workPositionsTypes = get(filterGroupStateAtomFamily(FilterGroupNames.WORK_POSITION));

    const hideEmptyEmployees = get(
      isActiveFilterSelectorFamily({
        groupName: FilterGroupNames.HIDE_EMPTY,
        filterId: HideEmptyFilter.IsActive,
      }),
    );

    const hidePublishedSchedules = get(
      isActiveFilterSelectorFamily({
        groupName: FilterGroupNames.HIDE_PUBLISHED,
        filterId: HideEmptyFilter.IsActive,
      }),
    );

    const hideAcceptedRequests = get(
      isActiveFilterSelectorFamily({
        groupName: FilterGroupNames.HIDE_ACCEPTED_REQUESTS,
        filterId: HideAcceptedRequestsFilter.IsActive,
      }),
    );

    const viewOnlyRequests = get(appRoutingFlagAtomFamily('isCalendarRequests'));
    const viewOnlySchedules = get(appRoutingFlagAtomFamily('isCalendarSchedules'));
    const viewOnlyAvailability = get(appRoutingFlagAtomFamily('isCalendarAvailability'));

    if (
      !calendar &&
      calendarResponseError &&
      calendarResponseError.innerCode === ErrorInnerCodes.DateRangeAccessDenied
    ) {
      return new Map();
    }

    if (!calendar || !filteredEmployees) return null;

    // Filter functions
    const filterByWorkPosition = (
      schedules: CalendarDayDataType['schedules'] | null,
    ): CalendarDayDataType['schedules'] => {
      let newSchedules: CalendarDayDataType['schedules'] = [];

      if (schedules && schedules.length > 0) {
        if (workPositionsTypes === null) {
          return schedules;
        }

        const filteredSchedules = _.filter(schedules, (schedule) => {
          if (schedule.workPositionId && workPositionsTypes.includes(schedule.workPositionId)) {
            return true;
          }

          return false;
        });

        newSchedules = filteredSchedules;
      }

      return newSchedules;
    };

    const filterByRequestType = (dayData: CalendarDayDataType | null): CalendarDayDataType['requests'] => {
      let newRequests: CalendarDayDataType['requests'] = [];

      if (dayData) {
        const { requests } = dayData;

        if (requestTypes === null) {
          return requests;
        }

        if (requestTypes.includes(`${RequestFormType.TimeOff}`)) {
          newRequests = [
            ...(newRequests || []),
            ..._.filter(requests, (request) => request.type === RequestFormType.TimeOff),
          ];
        }
        if (requestTypes.includes(`${RequestFormType.Custom}`)) {
          newRequests = [
            ...(newRequests || []),
            ..._.filter(requests, (request) => request.type === RequestFormType.Custom),
          ];
        }
        if (requestTypes.includes(`${RequestFormType.BusinessTrip}`)) {
          newRequests = [
            ...(newRequests || []),
            ..._.filter(requests, (request) => request.type === RequestFormType.BusinessTrip),
          ];
        }
      }

      return newRequests;
    };

    const filterByScheduleType = (dayData: CalendarDayDataType | null): ScheduleData[] | undefined => {
      let newSchedules: ScheduleData[] | undefined;

      if (dayData) {
        const { schedules } = dayData;

        if (scheduleTypes === null) {
          return schedules;
        }

        if (scheduleTypes.includes(CalendarSchedulesFilters.Fixed)) {
          newSchedules = [
            ...(newSchedules || []),
            ..._.filter(schedules, (schedule) => schedule.details.type === ScheduleType.FixedStartTime),
          ];
        }

        if (scheduleTypes.includes(CalendarSchedulesFilters.Flexible)) {
          newSchedules = [
            ...(newSchedules || []),
            ..._.filter(schedules, (schedule) => schedule.details.type === ScheduleType.NotStandardized),
          ];
        }

        if (scheduleTypes.includes(CalendarSchedulesFilters.Overtime)) {
          if (
            _.isEmpty(newSchedules) &&
            !scheduleTypes.includes(CalendarSchedulesFilters.Fixed) &&
            !scheduleTypes.includes(CalendarSchedulesFilters.Flexible)
          ) {
            newSchedules = _.filter(schedules, (schedule) => !!schedule.details.isOvertimeSchedule);
          } else {
            newSchedules = _.filter(newSchedules, (schedule) => !!schedule.details.isOvertimeSchedule);
          }
        }

        if (scheduleTypes.includes(CalendarSchedulesFilters.WithAutoBreak)) {
          if (
            _.isEmpty(newSchedules) &&
            !scheduleTypes.includes(CalendarSchedulesFilters.Overtime) &&
            !scheduleTypes.includes(CalendarSchedulesFilters.Flexible) &&
            !scheduleTypes.includes(CalendarSchedulesFilters.Fixed)
          ) {
            newSchedules = _.filter(schedules, (schedule) => !!schedule.details.addAutomaticBreak);
          } else {
            newSchedules = _.filter(newSchedules, (schedule) => !!schedule.details.addAutomaticBreak);
          }
        }

        if (scheduleTypes.includes(CalendarSchedulesFilters.WithOvertime)) {
          if (
            _.isEmpty(newSchedules) &&
            !scheduleTypes.includes(CalendarSchedulesFilters.Overtime) &&
            !scheduleTypes.includes(CalendarSchedulesFilters.Flexible) &&
            !scheduleTypes.includes(CalendarSchedulesFilters.Fixed)
          ) {
            newSchedules = _.filter(
              schedules,
              (schedule) => !!schedule.details.countOvertimeAfterWork || !!schedule.details.countOvertimeAfterWork,
            );
          } else {
            newSchedules = _.filter(
              newSchedules,
              (schedule) => !!schedule.details.countOvertimeAfterWork || !!schedule.details.countOvertimeAfterWork,
            );
          }

          if (scheduleTypes.includes(CalendarSchedulesFilters.Error)) {
            if (!dayData.errors) {
              newSchedules = undefined;
            }
          }
        }
      }

      return newSchedules;
    };

    const newMap = new Map([...calendar]);

    const generateSchedulePositionArray = (days: CalendarDaysType | null | undefined) => {
      if (!days) return [];

      if (!groupSchedulesByHours) {
        return _.chain(days)
          .map(([key, value]) => {
            if (value) {
              const { schedules } = value;
              if (!schedules) return [];

              const filteredSchedules = _.filter(
                schedules,
                ({ details, isDeleted }) => details.type === ScheduleType.FixedStartTime && !isDeleted,
              );

              const mappedSchedules = _.map(
                filteredSchedules,
                ({ details }) => details.startDateTimeUnix && details.startDateTimeUnix - key,
              );

              return mappedSchedules;
            }

            return [];
          })
          .maxBy((item) => item.length)
          .value() as number[];
      }

      return _.chain(days)
        .flatMap(([key, value]) => {
          if (value) {
            const { schedules } = value;
            if (!schedules) return [];

            const filteredSchedules = _.filter(
              schedules,
              ({ details, isDeleted }) => details.type === ScheduleType.FixedStartTime && !isDeleted,
            );
            const mappedSchedules = _.map(
              filteredSchedules,
              ({ details }) => details.startDateTimeUnix && details.startDateTimeUnix - key,
            );

            return mappedSchedules;
          }

          return [];
        })
        .uniq()
        .sortBy()
        .value() as number[];
    };

    const generateRequestPositionArray = (days: CalendarDaysType | null | undefined) => {
      if (!days) return [];

      return _.chain(days)
        .map(([, value]) => {
          if (value) {
            const { requests } = value;
            if (!requests) return [];

            const filteredRequests = _.filter(requests, ({ state, isDeleted }) => {
              if (state === RequestState.Rejected || isDeleted) return false;
              return true;
            });

            const mappedRequests = _.map(filteredRequests, (val, index) => index);

            return mappedRequests;
          }

          return [];
        })
        .maxBy((item) => item.length)
        .value();
    };

    calendar.forEach((data, employeeId) => {
      if (!filteredEmployees.has(`${employeeId}`)) {
        newMap.delete(employeeId);
        return;
      }

      const days = new Map([...data.days]);

      days.forEach((day, dayId) => {
        const schedules = viewOnlyRequests || viewOnlyAvailability ? undefined : filterByScheduleType(day);
        const schedulesFilteredByWorkPosition = schedules ? filterByWorkPosition(schedules) : undefined;
        const schedulesFilteredByAiTrendScale = schedulesFilteredByWorkPosition
          ? filterByAiSchedulesTrendScale(schedulesFilteredByWorkPosition, aiSchedulesTrendScaleSelectorValue)
          : undefined;
        const requests = !viewOnlySchedules && !viewOnlyAvailability ? filterByRequestType(day) : undefined;
        const aiWarnings = day?.aiWarnings
          ? new Map(day.aiWarnings).get(aiSchedulesTrendScaleSelectorValue)
          : undefined;

        days.set(dayId, {
          ...(day && day.errors ? { errors: day.errors } : {}),
          ...(!viewOnlyRequests && day && (day.warnings || aiWarnings) ? { warnings: aiWarnings || day.warnings } : {}),
          ...(requests && requests.length > 0 ? { requests } : {}),
          ...(schedulesFilteredByAiTrendScale && schedulesFilteredByAiTrendScale.length > 0
            ? { schedules: schedulesFilteredByAiTrendScale }
            : {}),
          ...(day && day.scheduledTime && !viewOnlyRequests ? { scheduledTime: day.scheduledTime } : {}),
          ...(!viewOnlyRequests && {
            workdayAvailability: day
              ? {
                  ...(day?.workdayAvailability && day.workdayAvailability),
                  hasFullDayTimeOff: !!day.requests?.find(
                    ({ type, kind }) => type === RequestFormType.TimeOff && kind === DateTimeKind.Local,
                  ),
                  hasUnrejectedTimeOff: !!day?.requests?.find(
                    ({ type, state }) => type === RequestFormType.TimeOff && state !== RequestState.Rejected,
                  ),
                }
              : undefined,
          }),
        });
      });

      if (!viewOnlyRequests && !viewOnlyAvailability) {
        days.forEach((day, dayId) => {
          if (day && hidePublishedSchedules) {
            const { schedules } = day;
            const filteredSchedules = _.filter(schedules, (item) => !item.isPublished);

            if (schedules && schedules.length > 0) {
              days.set(dayId, {
                ...day,
                schedules: filteredSchedules,
              });
            }
          }
        });
      }

      if (!viewOnlySchedules && !viewOnlyAvailability && hideAcceptedRequests) {
        days.forEach((day, dayId) => {
          if (day && hideAcceptedRequests) {
            const { requests } = day;
            const filteredRequests = _.filter(requests, (item) => item.state !== RequestState.Accepted);

            if (requests && requests.length > 0) {
              days.set(dayId, {
                ...day,
                requests: filteredRequests,
              });
            }
          }
        });
      }

      newMap.set(employeeId, {
        ...data,
        days,
        hasWarnings: viewOnlyRequests || viewOnlyAvailability ? undefined : data.hasWarnings,
        totalScheduledDays: viewOnlyRequests || viewOnlyAvailability ? undefined : data.totalScheduledDays,
        totalScheduledWorkTimeDuration:
          viewOnlyRequests || viewOnlyAvailability ? undefined : data.totalScheduledWorkTimeDuration,
        hasUnpublishedChanges: viewOnlyRequests || viewOnlyAvailability ? false : data.hasUnpublishedChanges,
        hasPendingRequests: viewOnlySchedules || viewOnlyAvailability ? false : data.hasPendingRequests,
        schedulePositionMap:
          viewOnlyRequests || viewOnlyAvailability ? undefined : generateSchedulePositionArray([...days]),
        requestPositionMap:
          viewOnlyRequests || viewOnlyAvailability ? generateRequestPositionArray([...days]) : undefined,
      });

      if (hideEmptyEmployees && !viewOnlyAvailability && isDaysEmpty(days)) {
        newMap.delete(employeeId);
      }
    });

    return newMap;
  },
});

export const calendarColumnsAtom = atom<StickyListProps<CalendarData>['columns'] | null>({
  key: 'calendar_columns',
  default: null,
});

type PersonDayCalendarSelectorConfig = {
  id: ParsedEmployee['id'];
  dateUnix?: number;
};

export const filteredPersonDayCalendarSelector = selectorFamily<CalendarData | null, PersonDayCalendarSelectorConfig>({
  key: 'calendar_selector_filtered__person_day',
  get:
    ({ id, dateUnix }) =>
    ({ get }) => {
      const filteredCalendar = get(filteredCalendarSelector);
      if (!filteredCalendar) return null;

      const personCalendar = filteredCalendar.get(id);

      if (!personCalendar) return null;

      if (!dateUnix) return personCalendar;

      const dayCalendar = personCalendar.days.get(dateUnix);

      const newDayMap = new Map([[dateUnix, dayCalendar || null]]);

      return {
        ...personCalendar,
        days: newDayMap,
      };
    },
});

// IS SELECTED FOR DETAILS
type PersonDayCalendarAtomType = {
  id: ParsedEmployee['id'];
  dateUnix: number;
};

const personDayCalendarAtom = atom<PersonDayCalendarAtomType | null>({
  key: 'person_day_calendar',
  default: null,
});

export const personDayCalendarSelectorFamily = selectorFamily<boolean, PersonDayCalendarSelectorConfig>({
  key: 'calendar_selector_filtered__person_day__is_selected',
  get:
    ({ id, dateUnix }) =>
    ({ get }) => {
      const details = get(personDayCalendarAtom);
      if (!details) return false;
      if (details.id === id && details.dateUnix === dateUnix) return true;

      return false;
    },
  set:
    ({ id, dateUnix }) =>
    ({ set }, newValue) => {
      if (newValue && dateUnix) {
        set(personDayCalendarAtom, { dateUnix, id });
      } else {
        set(personDayCalendarAtom, null);
      }
    },
});

//
// SCHEDULE WIZARD
//

export const scheduleWizardStepAtom = atom<ScheduleWizardStep>({
  key: 'schedule_wizard_step',
  default: ScheduleWizardStep.SelectUsers,
});

export const scheduleWizardOnClickCallback = atom<(() => void) | undefined>({
  key: 'schedule_wizard_on_click_callback',
  default: undefined,
});

export const scheduleWizardDisabledButton = atom<boolean>({
  key: 'schedule_wizard_disabled_button',
  default: false,
});

export const scheduleWizardLoading = atom<boolean>({
  key: 'schedule_wizard_loading',
  default: false,
});

export const scheduleWizardScheduleAtom = atom<ScheduleActionData | null>({
  key: 'schedule_wizard_schedule',
  default: null,
});

export const scheduleWizardTemplateScheduleAtom = atom<DefaultScheduleFormValues | null>({
  key: 'schedule_wizard_template_schedule',
  default: null,
});

export const getScheduleFormValuesAtom = atom<UseFormGetValues<ScheduleActionData> | null>({
  key: 'getScheduleFormValues',
  default: null,
});

export const getScheduleWizardOptionsFormValuesAtom = atom<UseFormGetValues<ScheduleWizardOptionsProps> | null>({
  key: 'getScheduleWizardOptionsFormValues',
  default: null,
});

// export const scheduleWizardOptionsAtom = atom<ScheduleWizardOptionsProps | null>({
//   key: 'schedule_wizard_options',
//   default: null,
// });
