import { useState, useCallback, useLayoutEffect } from 'react';

const getDimensionObject = (node: HTMLElement): DimensionObject => node.getBoundingClientRect();

type DimensionObject = {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
};

type UseDimensionsHook = [(node: HTMLElement) => void, null | DimensionObject, HTMLElement | null];

/**
 * Retrieves the result of getBoundingClientRect method called on target element and updates it on window resize / scroll.
 *
 * @param {boolean | undefined} once If true the dimensions will be retrieved only once and never updated.
 *
 * @return {UseDimensionsHook} Returns an array.
 * @return {UseDimensionsHook[0]} Ref callback to pass on to the target element.
 * @return {UseDimensionsHook[1]} DimensionObject of the target element.
 * @return {UseDimensionsHook[2]} Target element.
 */

export const useDimensions = (once?: boolean): UseDimensionsHook => {
  const [dimensions, setDimensions] = useState<DimensionObject | null>(null);
  const [node, setNode] = useState<HTMLElement | null>(null);

  const ref = useCallback((element: HTMLElement) => {
    setNode(element);
  }, []);

  useLayoutEffect(() => {
    let measure: () => void;
    if (node) {
      measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));
      measure();

      if (!once) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);
      }
    }
    return () => {
      if (!once && measure) {
        window.removeEventListener('resize', measure);
        window.removeEventListener('scroll', measure);
      }
    };
  }, [node, once]);

  return [ref, dimensions, node];
};
