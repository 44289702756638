import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';
import { Flex } from 'theme-ui';

import { useFetchCountryDetailsManager } from 'hooks/useFetchCountryDetailsManager/useFetchCountryDetailsManager';
import { HeadingTip } from '../HeadingTip';
import { SettingsLoadingSpinnerCss } from '../SettingsLoadingSpinnerCss';

import { Holidays } from './components/Holidays';
import { OrganizationForm } from './components/OrganizationForm/OrganizationForm';
import { OrganizationModalRoutes } from './components/OrganizationModalRoutes/OrganizationModalRoutes';
import { useHolidays } from './hooks/useHolidays';
import { useOrganizationSettings } from './hooks/useOrganizationSettings';
import { useOrganizationSettingsCleanUp } from './hooks/useOrganizationSettingsCleanUp';

export const Organization = (): React.ReactElement => {
  useLingui();
  const { fetchOrganizationSettings, organizationSettings } = useOrganizationSettings();
  const { fetchHolidays, holidays } = useHolidays();
  const { fetchCountryDetailsListResponse } = useFetchCountryDetailsManager(true);

  useEffect(() => {
    if (!organizationSettings) {
      void fetchOrganizationSettings();
    }
  }, [fetchOrganizationSettings, organizationSettings]);

  useEffect(() => {
    void fetchHolidays();
  }, [fetchHolidays]);

  useOrganizationSettingsCleanUp();

  return (
    <>
      <HeadingTip
        label={t({
          id: 'organization_settings.heading.organization_settings',
          message: 'Your organization',
        })}
      />

      <Flex variant="settings.container.vertical.md">
        {organizationSettings && holidays && fetchCountryDetailsListResponse ? (
          <>
            <OrganizationForm />
            <Holidays />
          </>
        ) : (
          <SettingsLoadingSpinnerCss />
        )}
        <OrganizationModalRoutes />
      </Flex>
    </>
  );
};
