import { isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { ALL_SUPERVISORS_ID } from 'constants/requests';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { ParsedEventToUpdate } from 'state/requests';
import { AddRequestExtendedFormContext } from '../../../types';

import { useAddRequest } from './useAddRequest';

export const useMainFormEdit = () => {
  const { setFormState } = useAddRequest();
  const { unregister, setValue, watch } = useFormContext<AddRequestExtendedFormContext>();

  const prevEvent = useRef<Partial<ParsedEventToUpdate> | null>(null);
  const actionTypeWatch = watch('actionType');

  useEffect(() => {
    if (actionTypeWatch === RequestActionType.Create) {
      prevEvent.current = null;
    }
  }, [actionTypeWatch]);

  const handleMainFormValuesForRequestToEdit = useCallback(
    (event: Partial<ParsedEventToUpdate>) => {
      const { note, fileIds } = event.eventUpdateDetails || {};
      const { processedById, optionalStepProcessedById } = event.eventUpdateDetails || {};

      if (!isEqual(event, prevEvent.current)) {
        setValue('autoAcceptRequest', false);

        if (processedById && processedById.length) {
          setValue('processedById', processedById);
        } else {
          setValue('processedById', ALL_SUPERVISORS_ID);
        }

        if (optionalStepProcessedById && optionalStepProcessedById.length) {
          setValue('optionalStepProcessedById', optionalStepProcessedById);
        } else {
          setValue('optionalStepProcessedById', ALL_SUPERVISORS_ID);
        }
      }

      if (note) {
        setFormState((prevState) => ({ ...prevState, noteVisible: true }));
        setValue('note', note);
      } else {
        setFormState((prevState) => ({ ...prevState, noteVisible: false }));
        unregister('note');
      }

      if (fileIds && !isEmpty(fileIds)) {
        setFormState((prevState) => ({ ...prevState, attachmentsVisible: true }));
        setValue('files', fileIds);
      } else {
        setFormState((prevState) => ({ ...prevState, attachmentsVisible: false }));
        unregister('files');
      }

      prevEvent.current = event;
    },
    [setFormState, setValue, unregister],
  );

  const updatePrevEvent = useCallback((event: Partial<ParsedEventToUpdate>) => {
    prevEvent.current = event;
  }, []);

  return {
    handleMainFormValuesForRequestToEdit: useCallbackRef(handleMainFormValuesForRequestToEdit),
    updatePrevEvent,
  };
};
