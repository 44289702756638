import React from 'react';
import { Flex, Heading, Text } from 'theme-ui';

type TitleProps = {
  children?: React.ReactElement | React.ReactElement[] | React.ReactNode;
  additionalInfo?: React.ReactNode;
};

export const Title = ({ children, additionalInfo }: TitleProps): React.ReactElement => (
  <Flex sx={{ flexDirection: 'column' }}>
    <Heading variant="heading5">{children}</Heading>
    {additionalInfo && (
      <Text sx={{ my: 'auto', fontSize: '0.875rem', color: 'settings.additionalInfo' }}>{additionalInfo}</Text>
    )}
  </Flex>
);
