import { atom } from 'recoil';

export type GeoLocationProviderState = {
  getGeoLocation?: (success: (position: GeolocationPosition) => void, error: () => void) => void;
};

export const geoLocationPositionAtom = atom<GeolocationPosition | undefined>({
  key: 'kiosk__geoLocationPosition',
  default: undefined,
});
