import { plural, Plural, t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Text } from 'theme-ui';

import { deleteTimeEventsAction } from 'api/actions/timeEvent/timeEventActions';
import { DeleteTimeEventsActionProps } from 'api/actions/timeEvent/timeEventActions.types';
import { useModal } from 'components/Modal/output/useModal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'components/recipes/ConfirmModal/types';
import { ListNames } from 'components/StickyList/types';
import { useTimeTracker } from 'context/workTimeTracker/useTimeTracker';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { antiSpoofingChecksAtom, filteredExtTimeEventsSelector } from 'state/clockLog';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { languageSelector } from 'state/recoilState';
import { ReportsExtendedTimeEvent, reportsExtTimeEventsSelector } from 'state/reports';
import { userSessionPersonIdSelector } from 'state/userSession';

import { useRefreshClockLog } from './hooks/useRefreshClockLog';

export const DeleteEventsModal = (): React.ReactElement => {
  useLingui();

  const language = useRecoilValue(languageSelector);
  const extTimeEvents = useRecoilValue(filteredExtTimeEventsSelector);
  const reportsExtTimeEvents = useRecoilValue(reportsExtTimeEventsSelector);
  const events = useMemo(
    () => new Map([...(reportsExtTimeEvents || []), ...(extTimeEvents || [])]),
    [reportsExtTimeEvents, extTimeEvents],
  );
  const currentUserId = useRecoilValue(userSessionPersonIdSelector);
  const isCurrentUser = [...events.values()].find((event) => event.personId === currentUserId);
  const { getTimeTracker } = useTimeTracker();

  const setAntiSpoofingChecksAtom = useSetRecoilState(antiSpoofingChecksAtom); // for home
  const setSelectedEventIds = useSetRecoilState(selectedRowsIdsSelectorFamily(ListNames.CLOCK_LOG));

  const { baseRoute } = useModal();
  const { state } = useLocation();
  const { ids: eventIds } = state || {};
  const permissions = useAppPermissions();
  const {
    features: { AddEventsByVrcp },
  } = permissions;

  const getEmployeeIds = useCallback(() => {
    if (events && eventIds) {
      const employeeIds = _.map(eventIds, (id) => events.get(id)?.personId).filter(
        (pId): pId is ReportsExtendedTimeEvent['personId'] => pId !== undefined,
      );

      if (employeeIds.length > 0) {
        const uniqueEmployeeIds = [...new Set(employeeIds)];
        return uniqueEmployeeIds;
      }
    }

    return undefined;
  }, [eventIds, events]);

  const employeeIds = useMemo(() => getEmployeeIds(), [getEmployeeIds]);

  const { reportInitialized, updateReportForIds } = useRefreshReport(employeeIds);
  const { clockLogInitialized, refreshClockLogForPeopleIds } = useRefreshClockLog(employeeIds);

  const onResponseCallback: NonNullable<ConfirmModalProps['onResponseCallback']> = useCallback(
    async (submitError) => {
      if (!submitError) {
        setAntiSpoofingChecksAtom((prev) => prev?.filter((event) => !eventIds?.includes(event.id)) || null);
        setSelectedEventIds((prev) => prev.filter((eventId) => !eventIds?.includes(eventId)));

        if (isCurrentUser && AddEventsByVrcp) {
          void getTimeTracker();
        }
        if (reportInitialized) await updateReportForIds();
        if (clockLogInitialized) await refreshClockLogForPeopleIds();
      }
    },
    [
      setAntiSpoofingChecksAtom,
      setSelectedEventIds,
      isCurrentUser,
      AddEventsByVrcp,
      reportInitialized,
      updateReportForIds,
      clockLogInitialized,
      refreshClockLogForPeopleIds,
      eventIds,
      getTimeTracker,
    ],
  );

  const titleRenderer: ConfirmModalProps['titleRenderer'] = useCallback(
    (timeEventsCount) =>
      t({
        id: 'clock_log.delete_clock_logs.heading',
        message: plural(timeEventsCount, { one: `Delete this event?`, other: 'Delete these events?' }),
      }),
    [],
  );

  const contentRenderer: ConfirmModalProps['contentRenderer'] = useCallback(
    (timeEventsCount) => (
      <Text>
        <Trans id="clock_log.delete_clock_logs.contents">
          You're about to delete {timeEventsCount}{' '}
          <Plural id="clock_log.delete.plurals.event" value={timeEventsCount} one={'event'} other={'events'} />. This
          will impact reports, etc.
        </Trans>
      </Text>
    ),
    [],
  );

  return (
    <ConfirmModal
      list={events}
      action={(body: DeleteTimeEventsActionProps) =>
        deleteTimeEventsAction(body, {
          baseRoute: {
            [language]: baseRoute,
          },
        })
      }
      onResponseCallback={onResponseCallback}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
    />
  );
};
