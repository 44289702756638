import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { mutableSettingsSelector } from 'Kiosk/state/settingsState';
import { LoadingSplash } from 'components/Loading/LoadingSplash';
import { Modal } from 'components/Modal/output/Modal';
import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { ElementGroup } from 'components/ui/ElementGroup';
import { Switch } from 'components/ui/Switch';
import { setNativeValue } from 'utils/setNativeValue';

import { SettingsItem } from './ui/SettingsItem';

export const SettingsModal = (): React.ReactElement => {
  const [kioskMutableSettings, setKioskMutableSettings] = useRecoilState(mutableSettingsSelector);

  const summaryTimeRef = useRef<HTMLInputElement | null>(null);
  const sleepModeTimeoutRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (kioskMutableSettings) {
      const { summaryTimeSeconds, sleepModeTimeOutMinutes } = kioskMutableSettings;

      setNativeValue(sleepModeTimeoutRef, sleepModeTimeOutMinutes * 60);
      setNativeValue(summaryTimeRef, summaryTimeSeconds);
    }
  }, [kioskMutableSettings]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {i18n._(
            t({
              id: 'kiosk.home.settings_modal.header',
              message: 'Settings',
            }),
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!kioskMutableSettings ? (
          <LoadingSplash />
        ) : (
          <ElementGroup direction="column" marginValue={3}>
            <SettingsItem
              showDivider
              title={<Trans id="kiosk.home.settings_modal.summary_time">Summary display time</Trans>}
              additionalText={
                <Trans id="kiosk.home.settings_modal.summary_time.additional">
                  It refers to a summary splash screen after a successful identification.
                </Trans>
              }
              content={
                <DurationPicker
                  ref={summaryTimeRef}
                  id="summaryTime"
                  size="sm"
                  isStatic
                  hours={false}
                  minutes={false}
                  quickSelectOptions={[2, 3, 4, 5, 6, 7, 8]}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (+value !== kioskMutableSettings.summaryTimeSeconds) {
                      setKioskMutableSettings({
                        ...kioskMutableSettings,
                        summaryTimeSeconds: +value,
                      });
                    }
                  }}
                />
              }
            />
            <SettingsItem
              showDivider={!kioskMutableSettings.isSleepModeEnabled}
              title={<Trans id="kiosk.home.settings_modal.sleep_mode">Sleep mode</Trans>}
              additionalText={
                <Trans id="kiosk.home.settings_modal.sleep_mode_additional_info">
                  After a selected period of time application will turn off most of its features, including camera to
                  save energy.
                </Trans>
              }
              content={
                <Switch
                  checked={kioskMutableSettings.isSleepModeEnabled}
                  onChange={(e) => {
                    const { checked } = e.target;

                    if (checked !== kioskMutableSettings.isSleepModeEnabled) {
                      setKioskMutableSettings({
                        ...kioskMutableSettings,
                        isSleepModeEnabled: e.target.checked,
                      });
                    }
                  }}
                  placement="right"
                  name="sleepMode"
                />
              }
            />
            {kioskMutableSettings.isSleepModeEnabled && (
              <SettingsItem
                showDivider
                title={<Trans id="kiosk.home.settings_modal.sleep_mode_time">Enter sleep mode after</Trans>}
                content={
                  <DurationPicker
                    ref={sleepModeTimeoutRef}
                    size="sm"
                    isStatic
                    seconds={false}
                    id="sleepModeTimeOut"
                    quickSelectOptions={[900, 1800, 2700, 3600, 4500, 5400, 6300, 7200]}
                    onChange={(e) => {
                      const { value } = e.target;

                      if (+value / 60 !== kioskMutableSettings.sleepModeTimeOutMinutes) {
                        setKioskMutableSettings({
                          ...kioskMutableSettings,
                          sleepModeTimeOutMinutes: +value / 60,
                        });
                      }
                    }}
                  />
                }
              />
            )}
          </ElementGroup>
        )}
      </Modal.Body>
    </>
  );
};
