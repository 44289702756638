import { dateTime } from 'utils/dateTime';
import { DateTimeDetails, RequestFormType } from 'api/actions/requests/requestsActions.types';

const dateFormatPeriod = (
  unix: number,
  isDateBound?: boolean,
  timeFormat?: string,
  multipleYearsInRangeFilter?: boolean | null,
) => {
  const year = dateTime(unix, { utc: isDateBound }).format('YYYY');
  const localDate = multipleYearsInRangeFilter
    ? dateTime(unix, { utc: isDateBound }).format('ll')
    : dateTime(unix, { utc: isDateBound }).format('ll').split(` ${year}`)[0].split(',')[0];

  if (!isDateBound && !timeFormat) return localDate;

  const localTime = dateTime(unix).format(timeFormat);

  return `${localDate}${!isDateBound ? `, ${localTime}` : ''}`;
};

export type DisplayDate = {
  isDateBound: boolean;
  type?: RequestFormType;
  dateRange?: DateTimeDetails['dates'];
  dateUnix?: number | undefined;
};

export const displayDate = (
  { type, isDateBound, dateRange, dateUnix }: DisplayDate,
  timeFormat: string,
  multipleYearsInRangeFilter?: boolean | null,
) => {
  if (dateRange) {
    const [startUnix, endUnix] = dateRange;

    const startDate = dateFormatPeriod(startUnix, isDateBound, timeFormat, multipleYearsInRangeFilter);
    const shortStartDate = dateFormatPeriod(startUnix, isDateBound, timeFormat);

    if (endUnix) {
      const endDate = dateFormatPeriod(endUnix, isDateBound, timeFormat, multipleYearsInRangeFilter);

      if (startUnix === endUnix) return `${startDate}`;

      return `${shortStartDate} - ${endDate}`;
    }

    if (!dateUnix) {
      return `${startDate}`;
    }
  }

  if (dateUnix) {
    if (type && type === RequestFormType.TimeTracking)
      return dateFormatPeriod(dateUnix, false, timeFormat, multipleYearsInRangeFilter);

    return dateFormatPeriod(dateUnix, isDateBound, undefined, multipleYearsInRangeFilter);
  }

  return '';
};
