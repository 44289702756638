import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { ScheduleWizardSelectedDays } from 'api/actions/calendar/calendarActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { ShowFieldsButton } from 'components/recipes/ShowFieldsButton';
import { Button } from 'components/ui/Buttons';
import { DualCalendar } from 'components/ui/DualCalendar/DualCalendar';
import { WorkStatusSelect } from 'components/ui/Select/variants/WorkStatusSelect';
import { Switch } from 'components/ui/Switch';
import { TimePicker } from 'components/ui/TimePicker/TimePicker';
import { useMount } from 'hooks/useMount/useMount';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { WeekdaysPicker } from 'pages/Calendar/output/WeekdaysPicker';
import { PersonCell } from 'pages/Reports/output/PersonCell';
import { parsedEmployeesSelector } from 'state/employees';
import { MinimizeModalAddEvents } from 'state/modal';
import { hashedEventTypeSelectorFamily, statusesOptionsSelectorFamily } from 'state/selectOptions';
import { minCreateDateUnixSelector } from 'state/userSession';
import { dateTime } from 'utils/dateTime';
import { dualCalendarRegisterOptionsFactory } from 'utils/dualCalendarRegisterOptionsFactory';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useAddEvents } from '../hooks/useAddEvents';
import { useSubmitAddEvents } from '../hooks/useSubmitAddEvents';
import { AddTimeEventsInput } from '../types';

type Props = {
  variant?: 'multi' | 'single';
  isMinimal?: boolean;
  isWizard?: boolean;
};

export const ADD_EVENTS_FORM_ID = 'addEventsForm';

export const AddEventsStep2: FC<Props> = ({ variant = 'multi', isMinimal, isWizard }) => {
  useLingui();
  const timeEventTypesOptions = useRecoilValue(statusesOptionsSelectorFamily(null));

  const parsedEmployees = useRecoilValue(parsedEmployeesSelector);
  const minCreateDateUnix = useRecoilValue(minCreateDateUnixSelector);

  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  const { id: typeId, unix, user } = useParams<{ id: string; unix: string; user: string }>();
  const { state } = useLocation();
  const { addTimeEvent, id } = state || {};
  const { isEnd, dateUnix: stateDateUnix } = addTimeEvent || {};
  const dateUnix = stateDateUnix || +(unix || 0);
  const employeeId = id || user;
  const { setGetFormValues, setIsSaveDisabled, addEventsFormRestored, setAddEventsFormRestored, resetGetFormValues } =
    useAddEvents();
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddEvents>();

  const predefinedHashedEventType = useRecoilValue(
    hashedEventTypeSelectorFamily({ typeId: typeId || '', isEnd: isEnd || false }),
  );
  const getPredefinedEventTypeOption = useCallback(
    () => timeEventTypesOptions.filter((eventType) => eventType.id === predefinedHashedEventType),
    [predefinedHashedEventType, timeEventTypesOptions],
  );

  const { handleSubmitCallback, handleSubmitErrorCallback } = useSubmitAddEvents();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
  } = useForm<AddTimeEventsInput>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: (() => {
      const formState = minimizedModalDetails?.modalDetails.addEvents;

      if (formState && !addEventsFormRestored) return formState;

      return {
        selectedDays: [
          ScheduleWizardSelectedDays.Monday,
          ScheduleWizardSelectedDays.Tuesday,
          ScheduleWizardSelectedDays.Wednesday,
          ScheduleWizardSelectedDays.Thursday,
          ScheduleWizardSelectedDays.Friday,
          ...(dateUnix && !isWizard ? [ScheduleWizardSelectedDays.Saturday, ScheduleWizardSelectedDays.Sunday] : []),
        ],
        datesUnix: dateUnix ? [dateUnix, dateUnix] : undefined,
        ignoreHolidays: !dateUnix,
        hashedEvents: [{ hashedEventType: predefinedHashedEventType || '', timeSeconds: '' }],
      };
    })(),
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'hashedEvents' });

  const [selectedDaysWatch, calendarWatch, firstEventTypeWatch, firstEventTimeWatch] = watch([
    'selectedDays',
    'datesUnix',
    'hashedEvents.0.hashedEventType',
    'hashedEvents.0.timeSeconds',
  ]);

  const shouldDisableSave = useMemo(
    () => !(selectedDaysWatch?.length && calendarWatch?.[0] && firstEventTypeWatch && firstEventTimeWatch !== ''),
    [calendarWatch, firstEventTimeWatch, firstEventTypeWatch, selectedDaysWatch?.length],
  );

  useEffect(() => setIsSaveDisabled(shouldDisableSave), [setIsSaveDisabled, shouldDisableSave]);

  const renderUserInfo = () => {
    if (!parsedEmployees || !isMinimal) return null;

    if (!employeeId) return null;

    const employee = parsedEmployees.get(employeeId);

    if (!employee) return null;

    const { avatarUrl, name, role, tags } = employee;

    const eventDate = dateTime(dateUnix, { utc: true });

    return (
      <Flex sx={{ my: '1.125rem', justifyContent: 'space-between', mt: 1, gap: 2 }}>
        <PersonCell
          name={{ firstName: name.firstName, surname: name.surname }}
          role={role}
          tags={tags}
          avatarUrl={avatarUrl}
          avatarSize={34}
        />
        <Flex sx={{ flexDirection: 'column', flexShrink: 0 }}>
          <Text sx={{ fontWeight: '700' }} variant="heading4">
            <Trans id="clock_log.add_event.date">Date</Trans>
          </Text>
          <Text as="span">{eventDate.format('MMM D')}</Text>
        </Flex>
      </Flex>
    );
  };
  useMount(() => {
    setGetFormValues(() => getValues);
    setAddEventsFormRestored(true);
  });

  useUnmount(() => {
    resetGetFormValues();
  });

  return (
    <form
      onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))}
      noValidate
      id={ADD_EVENTS_FORM_ID}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          ...(!isMinimal && { pl: 4 }),
          ...(!isSmartphoneBreakpoint && !isMinimal && { pr: 4, pb: 3 }),
        }}
      >
        {(!dateUnix || isWizard) && !isMinimal && (
          <>
            <Flex sx={{ flexDirection: 'column', mb: 4 }}>
              <Modal.SubTitle sx={{ mb: 0 }}>
                <Trans id="schedule.wizard.select_weekdays">Select weekdays</Trans>
              </Modal.SubTitle>
              <WeekdaysPicker
                {...register('selectedDays', {
                  setValueAs: (weekdays: string) => {
                    if (!weekdays || isEmpty(weekdays) || isArray(weekdays)) return undefined;

                    const parsedWeekdays = weekdays.split(',').map((weekday) => parseInt(weekday, 10));
                    return parsedWeekdays;
                  },
                })}
              />
            </Flex>

            <Modal.SubTitle>
              <Trans id="clock_log.select_date_or_dates">Select date or multiple dates</Trans>
            </Modal.SubTitle>
            <DualCalendar
              range
              resetDays
              {...register('datesUnix', dualCalendarRegisterOptionsFactory({ isRange: true }))}
              {...(minCreateDateUnix && { minDate: dateTime(minCreateDateUnix) })}
            />

            <Switch
              {...register('ignoreHolidays')}
              size="sm"
              label={t({ id: 'clock_log.skip_holidays', message: 'Skip holidays' })}
              additionalInfo={t({
                id: 'clock_log.skip_holidays.explanation',
                message: 'Events won’t be added on holidays',
              })}
              sx={{ mt: 4, fontWeight: 'bold' }}
            />

            <Modal.SubTitle sx={{ mt: 4, mb: -1 }}>
              <Trans id="clock_log.events">Events</Trans>
            </Modal.SubTitle>
          </>
        )}
        {renderUserInfo()}
        <ul>
          {fields.map((field, index) => (
            <li key={field.id}>
              <Flex sx={{ gap: 2, mt: 2 }}>
                <WorkStatusSelect
                  searchable
                  label={t({ id: 'clock_log.type', message: 'Type' })}
                  {...register(`hashedEvents.${index}.hashedEventType`, {
                    validate: { valueExists: (value) => !!value },
                  })}
                  id={`hashedEvents.${index}.hashedEventType`}
                  options={variant === 'multi' ? timeEventTypesOptions : getPredefinedEventTypeOption()}
                  size="sm"
                  sx={{ maxWidth: '300px' }}
                />

                <TimePicker
                  label={t({ id: 'clock_log.time', message: 'Time' })}
                  {...register(`hashedEvents.${index}.timeSeconds`, {
                    validate: {
                      valueExists: (currentValue) => !!currentValue || t({ id: 'date_picker.time_required' }),
                    },
                  })}
                  id={`hashedEvents.${index}.timeSeconds`}
                  size="sm"
                  sx={{ minWidth: '60px' }}
                  error={!!errors?.hashedEvents?.[index]?.timeSeconds}
                  errorMessage={errors?.hashedEvents?.[index]?.timeSeconds?.message}
                />

                {index !== 0 && (
                  <Button
                    onClick={() => remove(index)}
                    shape="rounded"
                    size="sm"
                    variant="lightGrey"
                    sx={{ height: '37px', alignSelf: 'flex-end', ml: [0, 3] }}
                  >
                    <Icon type="delete" />
                  </Button>
                )}
              </Flex>
            </li>
          ))}
        </ul>

        {variant === 'multi' && (
          <ShowFieldsButton
            onClick={() => append({ hashedEventType: '', timeSeconds: '' })}
            label={t({ id: 'clock_log.add_another_event', message: 'Add another event' })}
            sx={{ mt: 3, maxWidth: '220px' }}
          />
        )}
      </Flex>
    </form>
  );
};
