import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Flex, Heading, Text } from 'theme-ui';

import { TimeRoundingSample } from 'api/actions/settings/settingsActions.types';
import { Divider } from 'components/Divider/Divider';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { getDateFromSeconds } from 'utils/dateTime';
import { UUID } from 'utils/UUID';

type Props = {
  variant: 'clocksIn' | 'clocksOut';
  timeSamples: TimeRoundingSample[] | null;
};

export const PlannedTimeSamples = ({ variant, timeSamples }: Props): React.ReactElement => {
  useLingui();

  const { isSmartphoneBreakpoint, breakpoint } = useThemeBreakpoint();
  const isViewXS = breakpoint < MEDIA_BREAKPOINTS.SM;

  return (
    <Flex
      variant="settings.elements.timeRounding"
      sx={{
        ...(isViewXS && {
          flexDirection: 'column',
        }),
      }}
    >
      <Heading as="h5" variant="heading5.withMargins">
        {t({ id: 'automations_settings.how_it_work' })}
      </Heading>
      <Flex
        sx={{
          minHeight: SETTINGS_SPACE.plannedTimeSamplesHeight,
          ...(isSmartphoneBreakpoint && {
            flexDirection: 'column',
            rowGap: 2,
            minHeight: SETTINGS_SPACE.plannedTimeSamplesMobileHeight,
          }),
        }}
      >
        {variant === 'clocksIn' ? (
          <>
            <Flex
              sx={{
                flexDirection: 'column',
                ...(isSmartphoneBreakpoint && {
                  minHeight: SETTINGS_SPACE.plannedTimeSamplesHeight,
                }),
              }}
            >
              <Text sx={{ fontSize: 2 }}>
                <Trans id="automations_settings.employee_clocks_in">
                  An employee that{' '}
                  <Text as="span" sx={{ fontWeight: 'bold' }}>
                    clocks-in
                  </Text>{' '}
                  at...
                </Trans>
              </Text>
              {timeSamples ? (
                <>
                  {timeSamples.map((sample) => {
                    const { isEnter, timeRealSeconds, timeRoundedSeconds } = sample;

                    if (!isEnter) return null;

                    return (
                      <Text key={UUID()} sx={{ fontSize: 2 }}>
                        {getDateFromSeconds(timeRealSeconds).format('LT')}{' '}
                        {t({ id: 'automations_settings.will_be_rounded' })}{' '}
                        {getDateFromSeconds(timeRoundedSeconds).format('LT')}
                      </Text>
                    );
                  })}
                </>
              ) : (
                <Flex
                  sx={{
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <LoadingSpinnerCss size={2} />
                </Flex>
              )}
            </Flex>

            <Divider
              axis={isSmartphoneBreakpoint ? 'horizontal' : 'vertical'}
              sx={{ ...(isSmartphoneBreakpoint ? { mx: 0 } : { mx: '0.75rem' }) }}
            />

            <Text sx={{ fontSize: '0.875rem', minHeight: '21px' }}>
              <Trans id="automations_settings.planned_start_time">Planned start time</Trans> 8:00
            </Text>
          </>
        ) : (
          <>
            <Flex
              sx={{
                flexDirection: 'column',
                ...(isSmartphoneBreakpoint && {
                  minHeight: SETTINGS_SPACE.plannedTimeSamplesHeight,
                }),
              }}
            >
              <Text sx={{ fontSize: 2 }}>
                <Trans id="automations_settings.employee_clocks_out">
                  An employee that{' '}
                  <Text as="span" sx={{ fontWeight: 'bold' }}>
                    clocks-out
                  </Text>{' '}
                  at...
                </Trans>
              </Text>
              {timeSamples ? (
                <>
                  {timeSamples.map((sample) => {
                    const { isEnter, timeRealSeconds, timeRoundedSeconds } = sample;

                    if (isEnter) return null;

                    return (
                      <Text key={UUID()} sx={{ fontSize: 2 }}>
                        {getDateFromSeconds(timeRealSeconds).format('LT')}{' '}
                        {t({ id: 'automations_settings.will_be_rounded' })}{' '}
                        {getDateFromSeconds(timeRoundedSeconds).format('LT')}
                      </Text>
                    );
                  })}
                </>
              ) : (
                <Flex
                  sx={{
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <LoadingSpinnerCss size={2} />
                </Flex>
              )}
            </Flex>

            <Divider
              axis={isSmartphoneBreakpoint ? 'horizontal' : 'vertical'}
              sx={{ ...(isSmartphoneBreakpoint ? { mx: 0 } : { mx: '0.75rem' }) }}
            />

            <Text sx={{ fontSize: '0.875rem', minHeight: '21px' }}>
              <Trans id="automations_settings.planned_end_time">Planned end time</Trans> 16:00
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  );
};
