import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { Button } from 'components/ui/Buttons';
import { APP_NAME } from 'constants/common';
import { useIsMountedRef } from 'hooks/useIsMountedRef/useIsMountedRef';
import { useMount } from 'hooks/useMount/useMount';
import { AuthenticationLayout } from 'layouts/Authentication/output/AuthenticationLayout';
import { cardSx } from 'styles/theme/customComponents';

export type PrintFileWindow = Window & {
  handle_resolve_print_file?: (fileUrl?: string) => void;
};

const titleSx: ThemeUIStyleObject = {
  fontSize: [5, 6],
  fontWeight: 'bold',
};

const pSx: ThemeUIStyleObject = {
  fontSize: 4,
};

export const PreparingFile = () => {
  useLingui();
  const [resolved, setResolved] = useState(false);
  const [error, setError] = useState(false);

  const isMountedRef = useIsMountedRef();

  useMount(() => {
    (window as unknown as PrintFileWindow).handle_resolve_print_file = (fileUrl?: string) => {
      if (!fileUrl) {
        setResolved(true);
        setError(true);
        return;
      }

      window.location.href = fileUrl;

      setTimeout(() => {
        // when the file is to big for the browser to preview, download will occur instead
        // in this case we need to change the view from loading to ready
        if (isMountedRef.current) {
          setResolved(true);
        }
      }, 500);
    };
  });

  const renderPreparingFile = () => (
    <>
      <Text sx={titleSx}>
        <Trans id="preparing_file.title">Your file is being prepared.</Trans>
      </Text>

      <Text sx={pSx}>
        <Trans id="preparing_file.message">Please wait a few moments...</Trans>
      </Text>

      <LoadingSpinnerCss size={4} sx={{ mt: 4 }} />
    </>
  );

  const renderResult = (hasError: boolean) => (
    <>
      <Icon
        size={64}
        type={hasError ? 'deny' : 'approve'}
        wrapperSx={{ mt: -4 }}
        iconSx={{ color: hasError ? 'preparingFile.error' : 'preparingFile.ready' }}
      />
      <Text sx={titleSx}>
        {hasError ? (
          <Trans id="preparing_file.error.title">Something went wrong...</Trans>
        ) : (
          <Trans id="preparing_file.ready.title">Ready!</Trans>
        )}
      </Text>

      {hasError && (
        <Text sx={pSx}>
          <Trans id="preparing_file.error.message">Please try again in a few minutes.</Trans>
        </Text>
      )}

      <Button sx={{ mt: 2 }} onClick={() => window.close()} shape="rounded" size="lg" variant="lightGrey">
        <Trans id="preparing_file.close_window">Close this window</Trans>
      </Button>
    </>
  );

  return (
    <>
      <Helmet>
        <title>
          {t({ id: 'preparing_file', message: 'Preparing file' })} - {APP_NAME}
        </title>
      </Helmet>

      <AuthenticationLayout>
        <Flex sx={{ ...cardSx, justifyContent: 'center', maxHeight: '300px' }}>
          {!resolved ? renderPreparingFile() : renderResult(error)}
        </Flex>
      </AuthenticationLayout>
    </>
  );
};
