import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';
import { useLingui } from '@lingui/react';
import { t, Trans } from '@lingui/macro';

import { TimeOffType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { deleteTimeOffTypeAction } from 'api/actions/timeoff/timeOffActions';
import { timeOffTypesSelector } from 'state/organizationSession';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';

export const DeleteTimeOffTypeModal = (): React.ReactElement => {
  useLingui();

  const timeOffTypes: TimeOffType[] | null = useRecoilValue(timeOffTypesSelector);

  const titleRenderer = useCallback(
    () => t({ id: 'settings.time_off_type.delete', message: 'Delete time off type' }),
    [],
  );

  const contentRenderer = useCallback(
    () => (
      <Trans id="settings.time_off_type.delete_message">
        <Text as="p">
          You're about to permanently delete this time off type and its settings.{' '}
          <strong>This action cannot be reversed.</strong>
        </Text>
        <Text as="p" mt={4}>
          All users that have requested this request type will NOT be affected, all data related to those requests will
          NOT be affected.
        </Text>
      </Trans>
    ),
    [],
  );

  return (
    <ConfirmModal
      list={timeOffTypes}
      action={deleteTimeOffTypeAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
    />
  );
};
