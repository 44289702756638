import { REACT_APP_API_URL } from 'constants/common';

/**
 * Creates a URL from REACT_APP_API_URL and provided requestEndpoint.
 *
 * @param {string} requestEndpoint
 *
 * @return {string} Returns the full request URL.
 */

export const getRequestUrl = (requestEndpoint: string) => `${REACT_APP_API_URL}${requestEndpoint}`;
