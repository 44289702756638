import { Trans, t } from '@lingui/macro';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Heading, Text } from 'theme-ui';

import { LinkButton } from 'components/ui/Buttons';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { APP_NAME } from 'constants/common';
import { TO } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { languageSelector } from 'state/recoilState';

export const AccountBlocked = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  const { isAccountBlocked, isTooManyUsersBlocked } = useAppPermissions();

  if (isAccountBlocked || isTooManyUsersBlocked) {
    return (
      <>
        <Helmet>
          <title>
            {t({ id: 'page_error.account_blocked' })} - {APP_NAME}
          </title>
        </Helmet>

        <Flex sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: '1 0', gap: 4 }}>
          <Heading as="h2" variant="heading2">
            <Trans id="page_error.account_blocked">Account blocked... 😥</Trans>
          </Heading>
          <Text variant="pLead" sx={{ fontSize: [3, null, 4], textAlign: 'center' }}>
            <Trans id="page_error.account_blocked_info">
              Please contact your supervisor to get this sorted out and then click on the button below.
            </Trans>
          </Text>

          <LinkButton sx={{ fontSize: 3 }} variant="primary" shape="rounded" to={TO.START[language]}>
            <Trans id="error_page.return_button">Return to start</Trans>
          </LinkButton>

          <LinkAnchor sx={{ fontSize: 3 }} to={TO.SIGN_OUT[language]}>
            {t({ id: 'navbar.side_menu.user.sign_out' })}
          </LinkAnchor>
        </Flex>
      </>
    );
  }

  return (
    <>
      <Navigate to={TO.START[language]} />
    </>
  );
};
