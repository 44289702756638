import { Action } from 'react-fetching-library';

import { API_ENDPOINTS } from 'constants/api';

import {
  AddCustomRequestTypeActionProps,
  AddCustomRequestTypeResponse,
  DeleteCustomRequestTypeActionProps,
  DeleteCustomRequestTypeResponse,
  SetCustomRequestTypesStateActionProps,
  SetCustomRequestTypesStateResponse,
  UpdateCustomRequestTypeActionProps,
  UpdateCustomRequestTypeResponse,
} from './customRequestActions.types';

export function addCustomRequestTypeAction(
  body: AddCustomRequestTypeActionProps,
): Action<AddCustomRequestTypeResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.customRequestType,
    body,
  };
}

export function updateCustomRequestTypeAction({
  customRequestId,
  ...body
}: UpdateCustomRequestTypeActionProps): Action<UpdateCustomRequestTypeResponse> {
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.customRequestType}/${customRequestId}`,
    body,
  };
}

export function deleteCustomRequestTypeAction(
  body: DeleteCustomRequestTypeActionProps,
): Action<DeleteCustomRequestTypeResponse> {
  return {
    method: 'DELETE',
    endpoint: API_ENDPOINTS.customRequestType,
    body,
  };
}

export function setCustomRequestTypesStateAction(
  body: SetCustomRequestTypesStateActionProps,
): Action<SetCustomRequestTypesStateResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setCustomRequestTypesState,
    body,
  };
}
