import { Trans } from '@lingui/macro';
import React from 'react';
import { Flex, FlexProps, Text } from 'theme-ui';

import { RequestChangeAttachment } from './components/RequestChangeAttachment';
import { RequestChangeDate } from './components/RequestChangeDate';
import { RequestChangeDuration } from './components/RequestChangeDuration';
import { RequestChangeEventNote } from './components/RequestChangeEventNote';
import { RequestChangeForDay } from './components/RequestChangeForDay';
import { RequestChangeName } from './components/RequestChangeName';
import { RequestChangeNote } from './components/RequestChangeNote';
import { RequestChangePlace } from './components/RequestChangePlace';
import { RequestChangeType } from './components/RequestChangeType';
import { requestChangeDetailsContainerSx } from './styles/styles';

type Props = FlexProps;

type RequestChangeDetailsContainerProps = Props;

type RequestChangeDetailsContainerComponent = React.FC<RequestChangeDetailsContainerProps> & {
  Type: typeof RequestChangeType;
  Date: typeof RequestChangeDate;
  Note: typeof RequestChangeNote;
  Duration: typeof RequestChangeDuration;
  EventNote: typeof RequestChangeEventNote;
  ForDay: typeof RequestChangeForDay;
  Name: typeof RequestChangeName;
  Place: typeof RequestChangePlace;
  Attachments: typeof RequestChangeAttachment;
};

export const RequestChangeDetailsContainer: RequestChangeDetailsContainerComponent = ({
  children,
}: Props): React.ReactElement => (
  <Flex sx={requestChangeDetailsContainerSx}>
    <Text
      sx={{
        fontWeight: 'bold',
        fontSize: 3,
      }}
    >
      <Trans id="request.before_and_after">Before & after</Trans>
    </Text>
    {children}
  </Flex>
);

RequestChangeDetailsContainer.Type = RequestChangeType;
RequestChangeDetailsContainer.Date = RequestChangeDate;
RequestChangeDetailsContainer.Note = RequestChangeNote;
RequestChangeDetailsContainer.Duration = RequestChangeDuration;
RequestChangeDetailsContainer.EventNote = RequestChangeEventNote;
RequestChangeDetailsContainer.ForDay = RequestChangeForDay;
RequestChangeDetailsContainer.Name = RequestChangeName;
RequestChangeDetailsContainer.Place = RequestChangePlace;
RequestChangeDetailsContainer.Attachments = RequestChangeAttachment;
