import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback, useEffect } from 'react';
import { Navigate, RouteState, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { deleteAllAiSchedulesAction, deleteAllSchedulesAction } from 'api/actions/calendar/calendarActions';
import { useModal } from 'components/Modal/output/useModal';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'components/recipes/ConfirmModal/types';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { schedulesSettingsSelector } from 'state/organizationSession';
import { useRefreshCalendar } from '../../hooks/useRefreshCalendar';

type Props = Pick<RouteState, 'ids' | 'dates' | 'isSchedulesAi'>;

const DeleteAllSchedulesInnerModal: FC<Props> = ({ ids, dates, isSchedulesAi }) => {
  useLingui();

  const { updateCalendarForIds, calendarInitialized, updateCalendar } = useRefreshCalendar(ids);
  const { reportInitialized, updateReportForIds } = useRefreshReport(ids);
  const schedulesSettings = useRecoilValue(schedulesSettingsSelector);

  useEffect(() => {
    if (!calendarInitialized) {
      void updateCalendar();
    }
  }, [calendarInitialized, updateCalendar]);

  const titleRenderer = useCallback(
    () =>
      isSchedulesAi
        ? t({ id: 'schedule.delete_ai_schedules_title', message: 'Delete all AI schedules?' })
        : t({ id: 'schedule.delete_schedules_title', message: 'Delete all schedules?' }),
    [isSchedulesAi],
  );

  const contentRenderer = useCallback(() => {
    if (!isSchedulesAi && schedulesSettings?.useAiScheduling) {
      return (
        <Trans id="schedule.delete_schedules_info.excluding_ai">
          <Text>
            You're about to delete all schedules (excluding AI schedules) for the selected teammates. Some of the
            schedules might not be currently displayed on the screen.
          </Text>
          <Text>
            <strong>This action can be reverted until publishing!</strong>
          </Text>
        </Trans>
      );
    }

    if (isSchedulesAi) {
      return (
        <Trans id="schedule.delete_ai_schedules_info">
          <Text>You're about to delete all AI schedules (from </Text>
          <Text>
            <strong>all trends</strong>
          </Text>
          <Text>) for the selected teammates.</Text>
          <Text>
            <strong>This operation cannot be reversed.</strong>
          </Text>
        </Trans>
      );
    }

    return (
      <Trans id="schedule.delete_schedules_info">
        <Text>
          You're about to delete all schedules for the selected teammates. Some of the schedules might not be currently
          displayed on the screen.
        </Text>
        <Text>
          <strong>This action can be reverted until publishing!</strong>
        </Text>
      </Trans>
    );
  }, [isSchedulesAi, schedulesSettings?.useAiScheduling]);

  const onResponseCallback: NonNullable<ConfirmModalProps['onResponseCallback']> = useCallback(
    async (error) => {
      if (!error) {
        if (calendarInitialized) await updateCalendarForIds();
        if (reportInitialized) await updateReportForIds();
      }
    },
    [calendarInitialized, reportInitialized, updateCalendarForIds, updateReportForIds],
  );

  return (
    <ConfirmModal
      action={(body) =>
        isSchedulesAi
          ? deleteAllAiSchedulesAction({ peopleIds: body, timeRange: dates })
          : deleteAllSchedulesAction({ peopleIds: body, timeRange: dates })
      }
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      withConfirmation
      variant="DELETE"
      onResponseCallback={onResponseCallback}
    />
  );
};

export const DeleteAllSchedulesModal: FC = () => {
  const { state } = useLocation();
  const { ids, dates, isSchedulesAi } = state || {};
  const { baseRoute } = useModal();

  if (!ids?.length || !dates) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return <DeleteAllSchedulesInnerModal ids={ids} dates={dates} isSchedulesAi={isSchedulesAi} />;
};
