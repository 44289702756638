import { useCallback, useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';

import { getGeoLocation as demandGeoLocation } from 'utils/getGeoLocation';

const GET_GEOLOCATION_TIMEOUT = 60000; // 1 minute

const geoLocationAtom = atom<GeolocationPosition | null>({
  key: 'geoLocation',
  default: null,
});

export const useGeoLocation = () => {
  const [location, setLocation] = useRecoilState(geoLocationAtom);

  const getLocation = useCallback(async () => {
    const locationPromise = new Promise<GeolocationPosition | null>((resolve, reject) => {
      demandGeoLocation((geoLocation) => resolve(geoLocation), reject);
    });

    return (async () => {
      let position: GeolocationPosition | null = null;
      try {
        const locationResponse = await locationPromise;
        position = locationResponse;
      } catch (error) {
        position = null;
      }
      setLocation(position);
      return position;
    })();
  }, [setLocation]);

  return {
    location,
    getGeoLocation: getLocation,
  };
};

export const GeoLocationObserver = () => {
  const { getGeoLocation } = useGeoLocation();

  useEffect(() => {
    const getGeoLocationInterval = setInterval(() => {
      void getGeoLocation();
    }, GET_GEOLOCATION_TIMEOUT);

    return () => {
      if (getGeoLocationInterval) {
        clearInterval(getGeoLocationInterval);
      }
    };
  }, [getGeoLocation]);

  return null;
};
