import _ from 'lodash';
import { useRecoilValue } from 'recoil';

import { HOSTNAME, REACT_APP_HOSTNAME } from 'constants/common';
import { QueryParams } from 'constants/queryParams';
import { REDIRECT_ROUTES, RedirectKeys } from 'constants/routes';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSearchParam } from 'hooks/useSearchParam/useSearchParam';
import { RequestDetailsGroupedParam } from 'pages/Requests/output/types';
import { languageSelector } from 'state/recoilState';

type Return = {
  redirectPath: string | null;
  redirectQuery: string;
};

/**
 * Extracts the redirect param from url and creates redirect path based on it.
 * Change with caution as the return value of this hook is used to conditionally render the whole app.
 *
 * @param {string | undefined} redirectTo custom pathname from which redirect param will be extracted, if not provided current url will be used.
 * @return {Return} obj with redirectPath and redirectQuery.
 */
export const useRedirectPath = (redirectTo?: string): Return => {
  const language = useRecoilValue(languageSelector);

  const redirectParam = useSearchParam(QueryParams.Redirect, false, redirectTo);
  const idParam = useSearchParam(QueryParams.Id, false, redirectTo);
  const tokenParam = useSearchParam(QueryParams.Token, false);
  const userIdParam = useSearchParam(QueryParams.UserId, false);
  const alertParam = useSearchParam(QueryParams.Alert, false);
  const errorParam = useSearchParam(QueryParams.Error, false);
  const realmIdParam = useSearchParam(QueryParams.RealmId, true);
  const allQueryParams = useQuery();

  const redirectKey: RedirectKeys | null = !redirectParam ? null : parseInt(redirectParam, 10);
  const redirectRoute = _.isNull(redirectKey) ? null : REDIRECT_ROUTES(redirectKey)[language];

  let redirectStr = redirectRoute;
  let queryStr = '';

  if (idParam) {
    if (redirectKey === RedirectKeys.REQUEST_DETAILS) {
      redirectStr += `/${RequestDetailsGroupedParam.Ungrouped}`;
    }

    redirectStr += `/${idParam}`;
  }

  if (
    redirectKey === RedirectKeys.QUICKBOOKS ||
    redirectKey === RedirectKeys.XERO ||
    redirectKey === RedirectKeys.GOOGLE ||
    redirectKey === RedirectKeys.SQUARE ||
    (redirectKey === RedirectKeys.PAYMENTS && HOSTNAME === REACT_APP_HOSTNAME.unrubble)
  ) {
    allQueryParams.delete(QueryParams.Redirect);
    queryStr += `?${allQueryParams}`;
  } else if (tokenParam || userIdParam || alertParam || errorParam || realmIdParam) {
    queryStr += `?`;
    if (tokenParam) queryStr += `${QueryParams.Token}=${tokenParam}&`;
    if (userIdParam) queryStr += `${QueryParams.UserId}=${userIdParam}&`;
    if (alertParam) queryStr += `${QueryParams.Alert}=${alertParam}&`;
    if (errorParam) queryStr += `${QueryParams.Error}=${errorParam}&`;
    if (realmIdParam) queryStr += `${QueryParams.RealmId}=${realmIdParam}&`;
  }

  return {
    redirectPath: redirectStr,
    redirectQuery: queryStr,
  };
};
