import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { Text } from 'theme-ui';

import { Period } from 'api/actions/requests/requestsActions.types';
import { getDurationObj } from '../../../../utils/getDurationObj';

export const useAddRequestLogic = () => {
  useLingui();

  const preparePeriod = useCallback((period: Period) => {
    switch (period) {
      case Period.Weekly:
        return t({ id: 'requests.weekly', message: 'weekly' });
      case Period.Monthly:
        return t({ id: 'requests.monthly', message: 'monthly' });
      default:
        return t({ id: 'requests.yearly', message: 'yearly' });
    }
  }, []);

  const prepareMaxLimit = useCallback(
    (duration: number | undefined, period: Period | undefined) => {
      if (_.isNil(duration)) return [];

      const { hours, minutes, days } = getDurationObj(duration);

      const parsedDays =
        (!hours && !minutes) || days ? (
          <Text>
            {`${days} `}
            {t({ id: days !== 1 ? 'days' : 'day' })}
          </Text>
        ) : undefined;

      const parsedHours = hours ? <Text>{`${hours}h`}</Text> : undefined;

      const parsedMinutes = minutes ? <Text>{`${minutes}m`}</Text> : undefined;

      const slash = !_.isUndefined(period) ? '/' : undefined;

      const parsedPeriod = !_.isUndefined(period) ? <Text>{preparePeriod(period)}</Text> : undefined;

      return _.filter([parsedDays, parsedHours, parsedMinutes, slash, parsedPeriod], (s) => s !== undefined);
    },
    [preparePeriod],
  );

  return { prepareMaxLimit, preparePeriod };
};
