import { useContext, useEffect, useRef } from 'react';

import { theme } from 'styles/theme';

import { MapContext } from './MapContext';
import { CircleProps } from './types';

const defaultProps: Partial<CircleProps> = {
  clickable: false,
  strokeColor: theme.colors.map.circle.stroke,
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: theme.colors.map.circle.fill,
  fillOpacity: 0.35,
};
export const Circle = ({ center, radius, ...props }: CircleProps): null => {
  const mapContext = useContext(MapContext);

  const circleRef = useRef<google.maps.Circle | null>(null);

  useEffect(() => {
    const initializeCircle = () => {
      if (!mapContext) return;

      const { googleMaps, map } = mapContext;

      if (!googleMaps || !map) return;

      circleRef.current = new googleMaps.Circle({
        map,
        radius,
        center,
        ...props,
      });
    };

    if (!circleRef.current && mapContext) {
      initializeCircle();
    }
  }, [mapContext, center, radius, props]);

  useEffect(() => {
    if (circleRef.current && center) {
      circleRef.current.setCenter(center);
    }
  }, [center]);

  useEffect(() => {
    if (circleRef.current && radius) {
      circleRef.current.setRadius(radius);

      const map = mapContext?.map;
      const circleBounds = circleRef.current.getBounds();

      if (map && circleBounds) {
        map.fitBounds(circleBounds);
      }
    }
  }, [radius, mapContext]);

  useEffect(
    () => () => {
      if (circleRef.current) {
        circleRef.current.setMap(null);
      }
    },
    [],
  );

  return null;
};

Circle.defaultProps = defaultProps;
