import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { FormCard } from 'components/FormCard/FormCard';
import { APP_NAME } from 'constants/common';
import { TO } from 'constants/routes';
import { Main } from 'layouts/Authentication/output/Main';
import { languageSelector } from 'state/recoilState';
import { signUpFormAtom } from 'state/signUp';

import { EmployerStep1 } from './components/EmployerStep1/EmployerStep1';
import { EmployerStep2 } from './components/EmployerStep2/EmployerStep2';
import { EmployerStep3 } from './components/EmployerStep3';

const SIGNUP_FIRST_STEP = 1;

export const Employer = (): React.ReactElement => {
  const { state } = useLocation();
  const { step } = useParams() as { step: string };

  const language = useRecoilValue(languageSelector);
  const signUpFormState = useRecoilValue(signUpFormAtom);

  const formRef = useRef<HTMLFormElement | null>(null);

  const currentStep = useMemo(() => parseInt(_.isNaN(parseInt(step, 10)) ? '0' : step, 10), [step]);

  const isStepValid = (() => {
    switch (step) {
      case '1':
      case '2':
      case '3':
        return true;
      default:
        return false;
    }
  })();

  // we have to redirect to first step because of
  // after refreshing we are missing data like name and surname (stored in recoil)
  if (!signUpFormState && currentStep !== SIGNUP_FIRST_STEP) {
    return <Navigate to={`${TO.SIGN_UP__EMPLOYER__STEP[language]}/${SIGNUP_FIRST_STEP}`} state={state} />;
  }
  if (!state?.signUp?.inProgress || !isStepValid) {
    return <Navigate to={TO.SIGN_UP[language]} />;
  }

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'sign_up.page_title', message: `${APP_NAME} - Sign up` }))}</title>
      </Helmet>

      <Main>
        <FormCard size="md">
          <FormCard.ProgressBar steps={3} completed={currentStep} />
          {step === '1' && <EmployerStep1 ref={formRef} />}
          {step === '2' && <EmployerStep2 ref={formRef} />}
          {step === '3' && <EmployerStep3 />}
        </FormCard>
      </Main>
    </>
  );
};
