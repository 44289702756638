import { Action } from 'api/types';
import { APP_LINK_BASENAME } from 'constants/common';
import { QueryParams } from 'constants/queryParams';
import { RedirectKeys } from 'constants/routes';

type ActionCreator<P, R> = (props: P) => Action<R>;

function actionWithPopupWindow<P, R>(action: ActionCreator<P, R>, popupUrl?: string): ActionCreator<P, R> {
  return (props: P) => {
    const popupWindow = popupUrl ? window.open(popupUrl) : null;

    const { config, ...rest } = action(props);

    return {
      ...rest,
      config: {
        ...(config && config),
        ...(popupWindow && { popupWindow }),
      },
    };
  };
}

export function actionWithPrintPopupWindow<P, R>(action: ActionCreator<P, R>): ActionCreator<P, R> {
  return actionWithPopupWindow(action, `${APP_LINK_BASENAME}?${QueryParams.Redirect}=${RedirectKeys.PREPARING_FILE}`);
}

type ActionCreatorWithDownload<P, R> = (props: P, isDownloadable?: boolean) => Action<R>;

export function actionWithPrintDownloadManager<P, R>(action: ActionCreator<P, R>): ActionCreatorWithDownload<P, R> {
  return (props: P, isDownloadable?: boolean) => {
    if (isDownloadable) {
      const { headers, ...rest } = action(props);

      return {
        ...rest,
        headers: {
          ...(headers && headers),
          'X-Download': 'true',
        },
      };
    }

    return actionWithPrintPopupWindow(action)(props);
  };
}
