import React, { useCallback, useMemo, useState } from 'react';
import { Flex } from 'theme-ui';
import { motion } from 'framer-motion';
import { Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { asideFilterExpandedAtom } from 'state/filters';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { IS_TOUCH_DEVICE } from 'constants/common';

const AnimatedFlex = motion(Flex);

const containerAnimationStates = {
  expanded: {
    maxWidth: '260px',
  },
  collapsed: {
    maxWidth: '24px',
  },
  hover: {},
};

const filtersAnimationStates = {
  expanded: {
    x: 0,
  },
  collapsed: {
    x: -236,
  },
  hover: {
    x: 0,
  },
};

export const AsideFiltersLayout = React.memo((): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useRecoilState(asideFilterExpandedAtom);
  const [mobileIsExpanded, setMobileIsExpanded] = useState(false);

  const { isMobileBreakpoint } = useThemeBreakpoint();

  const handleOnClick = useCallback(() => {
    if (isMobileBreakpoint) {
      setMobileIsExpanded(!mobileIsExpanded);
      return;
    }
    setIsExpanded(!isExpanded);
  }, [isExpanded, isMobileBreakpoint, mobileIsExpanded, setIsExpanded]);

  // ANIMATIONS FOR BREAKPOINTS
  const initial = useMemo(() => {
    if (isMobileBreakpoint) {
      return 'collapsed';
    }

    return !isExpanded ? 'collapsed' : 'expanded';
  }, [isExpanded, isMobileBreakpoint]);

  const animate = useMemo(() => {
    if (isMobileBreakpoint) {
      return mobileIsExpanded ? 'hover' : 'collapsed';
    }

    return !isExpanded ? 'collapsed' : 'expanded';
  }, [isExpanded, isMobileBreakpoint, mobileIsExpanded]);

  const buttonChevron = useMemo(() => {
    if (isMobileBreakpoint) {
      return mobileIsExpanded ? 'chevronLeft' : 'chevronRight';
    }

    return isExpanded ? 'chevronLeft' : 'chevronRight';
  }, [isExpanded, isMobileBreakpoint, mobileIsExpanded]);

  return (
    <AnimatedFlex
      as="aside"
      variant="layouts.authorized.aside.container"
      variants={containerAnimationStates}
      initial={initial}
      animate={animate}
      {...(!isMobileBreakpoint && !IS_TOUCH_DEVICE && { whileHover: 'hover' })}
    >
      <AnimatedFlex variant="layouts.authorized.aside.insideContainer" variants={filtersAnimationStates}>
        <Flex
          variant="layouts.authorized.aside.collapseButtonContainer"
          className="collapseButton"
          data-collapsed={!isExpanded}
        >
          <Button
            sx={{ variant: 'layouts.authorized.aside.collapseButton' }}
            size={isMobileBreakpoint ? 'lg' : 'sm'}
            variant="lightGrey"
            onClick={handleOnClick}
            prependWith={<Icon type={buttonChevron} size={18} />}
          ></Button>
        </Flex>

        <Flex variant="layouts.authorized.aside.filters">
          <React.Suspense fallback={<></>}>
            <Outlet />
          </React.Suspense>
        </Flex>
      </AnimatedFlex>
    </AnimatedFlex>
  );
});
