import { Action, DefaultErrorMessageEnum } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  EditViewsSettingActionProps,
  EditViewsSettingResponse,
  FetchUserSessionResponse,
  RefreshUserSessionResponse,
  UpdateUserSessionResponse,
} from './userSessionActions.types';

export function fetchUserSessionAction(accessToken?: string): Action<FetchUserSessionResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.userSession,
    ...(accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}),
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.None,
    },
  };
}

export function updateUserSessionAction(
  updatedValues: Partial<FetchUserSessionResponse>,
): Action<UpdateUserSessionResponse> {
  return {
    method: 'PUT',
    endpoint: API_ENDPOINTS.userSession,
    body: {
      ...updatedValues,
    },
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.None,
    },
  };
}

export function refreshUserSessionAction(): Action<RefreshUserSessionResponse> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.userSession,
    config: {
      customDefaultErrorMessage: DefaultErrorMessageEnum.None,
    },
  };
}

export function editViewsSettingAction(body: EditViewsSettingActionProps): Action<EditViewsSettingResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.viewsSettings,
    body,
  };
}
