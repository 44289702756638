import { ActionOnRequest, ActionPermission } from 'api/actions/requests/requestsActions.types';
import { ParsedRequest } from 'state/requests';

export const getActionsPermissions = (actions: ParsedRequest['actions']) => {
  const acceptAllowedForSwap = actions[ActionOnRequest.AcceptSwap] === ActionPermission.Allowed;
  const rejectAllowedForSwap = actions[ActionOnRequest.RejectSwap] === ActionPermission.Allowed;
  const acceptAllowed = actions[ActionOnRequest.Accept] === ActionPermission.Allowed;
  const rejectAllowed = actions[ActionOnRequest.Reject] === ActionPermission.Allowed;
  const deleteAllowed = actions[ActionOnRequest.Delete] === ActionPermission.Allowed;

  return {
    acceptAllowedForSwap,
    rejectAllowedForSwap,
    acceptAllowed,
    rejectAllowed,
    deleteAllowed,
  };
};
