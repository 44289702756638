import { Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { Avatar } from 'components/Avatar/Avatar';
import { Dot } from 'components/Dot/Dot';
import { withDot } from 'components/Dot/withDot';
import { CHAT_BOT } from 'constants/chat';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import {
  chatUsersMapSelector,
  chatUserWithUnreadMessagesSelectorFamily,
  onlineChatUserSelectorFamily,
} from 'state/chat';
import { DEFAULT_CHAT_TRANSITION } from 'styles/theme/chat';

import { ChatWindowState } from './ChatWindow/types';

const AvatarWithDot = withDot(Avatar);

export type ChatUserProps = {
  id: string;
  asSupport?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  variant?: 'mainWindow' | 'chatWindow';
  chatWindowVisible?: ChatWindowState['isVisible'];
  chatWindowExpanded?: ChatWindowState['isExpanded'];
  sx?: ThemeUIStyleObject;
  hasMessageToRead?: boolean;
};

const defaultProps = {
  asSupport: false,
  style: undefined,
  onClick: undefined,
  variant: 'mainWindow',
  chatWindowVisible: false,
  chatWindowExpanded: false,
  hasMessageToRead: false,
  sx: undefined,
};

const ChatUser = ({
  style,
  onClick,
  asSupport,
  variant,
  chatWindowVisible,
  chatWindowExpanded,
  id,
  hasMessageToRead,
  sx,
  ...props
}: ChatUserProps): React.ReactElement | null => {
  const isOnline = useRecoilValue(onlineChatUserSelectorFamily(id));
  const hasUnreadMessages = useRecoilValue(chatUserWithUnreadMessagesSelectorFamily(id));
  const chatUsersMap = useRecoilValue(chatUsersMapSelector);
  const isChatBot = useMemo(() => id === CHAT_BOT, [id]);
  const chatUser = useMemo(
    () =>
      isChatBot
        ? {
            name: {
              firstName: 'CHAT',
              surname: 'BOT',
            },
            avatarUrl: '',
          }
        : chatUsersMap.get(id),
    [chatUsersMap, id, isChatBot],
  );

  const fullName = useNameDisplayOrder();

  const displayName = useMemo(() => {
    if (!chatUser?.name) return '';
    const { firstName, surname } = chatUser.name;
    return fullName(firstName, surname);
  }, [chatUser?.name, fullName]);

  const { avatarUrl, name } = useMemo(
    () => ({
      avatarUrl: chatUser?.avatarUrl,
      name: chatUser?.name,
    }),
    [chatUser?.name, chatUser?.avatarUrl],
  );

  return (
    <Flex style={style} variant={`chat.user.${variant}`} onClick={onClick} sx={sx} {...props}>
      <AvatarWithDot
        className="chat-avatar"
        size={24}
        image={avatarUrl}
        {...(asSupport ? { iconType: 'support' } : { name })}
        dotProps={{
          size: 10,
          color: 'chat.dot.green',
          placement: 'BOTTOM_RIGHT',
          sx: {
            border: '1px solid',
            borderColor: 'chat.dot.borderColor',
            transform: 'translate(25%, 25%)',
          },
        }}
        //  TODO: we are not receiving updates for isOnline status for chat bot
        showDot={isOnline || isChatBot}
        sx={{
          flexShrink: 0,
          transition: `color ${DEFAULT_CHAT_TRANSITION}`,
          ...(hasMessageToRead &&
            variant === 'chatWindow' && {
              color: 'chat.text.unread',
            }),
          ...(asSupport && {
            bg: hasUnreadMessages ? 'chat.bg.supportUnread' : 'chat.bg.support',
            color: 'chat.text.support',
          }),
        }}
      />
      <Flex variant="chat.window.header.texts" className="chat-user">
        {asSupport ? (
          <>
            <Text sx={{ color: hasUnreadMessages ? 'chat.text.unread' : 'chat.text.support' }}>
              <Trans id="chatUser.support.name">Your support team</Trans>
            </Text>
            {/* TODO: we are not receiving updates for isOnline status for chat bot */}
            {isOnline && (
              <Text as="small">
                <Trans id="chatUser.support.online">We are online!</Trans>
              </Text>
            )}
          </>
        ) : (
          <Text>{displayName}</Text>
        )}
      </Flex>
      {hasUnreadMessages && _.isEqual(variant, 'mainWindow') && (
        <Dot
          color="chat.dot.unread"
          sx={{
            ml: 'auto',
            position: 'relative',
          }}
        />
      )}
    </Flex>
  );
};

ChatUser.defaultProps = defaultProps;

export const MemoizedChatUser = React.memo(ChatUser);
