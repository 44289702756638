import { Trans, t } from '@lingui/macro';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import Linkify from 'react-linkify';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { postMessageAction, removeMessageAction } from 'api/actions/chat/chatActions';
import { Avatar } from 'components/Avatar/Avatar';
import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { FullName } from 'components/utils/FullName';
import { MessageSendStatus, ParsedMessage, removeMessageSelector, updateOwnMessageSelector } from 'state/chat';
import { fullTimeFormatSelector } from 'state/recoilState';
import { userSessionAtom } from 'state/userSession';
import { dateTime } from 'utils/dateTime';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

export type MessageProps = {
  chatWindowId: string;
} & ParsedMessage;

const Message = ({
  firstName,
  surname,
  createdUnix,
  message,
  avatarUrl,
  id,
  chatWindowId,
  sender,
  status,
  receiver,
}: MessageProps): React.ReactElement => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const removeMessageFromRecoil = useSetRecoilState(removeMessageSelector(chatWindowId));
  const updateOwnMessage = useSetRecoilState(updateOwnMessageSelector);
  const timeFormat = useRecoilValue(fullTimeFormatSelector);
  const user = useRecoilValue(userSessionAtom);

  const { mutate } = useMutation(removeMessageAction);
  const { mutate: resendMutate, loading } = useMutation(postMessageAction);

  const removeLocalMessage = useCallback(() => removeMessageFromRecoil(id), [id, removeMessageFromRecoil]);

  const createdDate = useMemo(() => dateTime(createdUnix).format(timeFormat), [timeFormat, createdUnix]);

  const removeMessage = useCallback(async () => {
    if (user && _.isEqual(sender, user.personId)) {
      const { error: removeError } = await mutate({ id });
      if (!removeError) {
        removeMessageFromRecoil(id);
      }
    }
  }, [user, sender, mutate, id, removeMessageFromRecoil]);

  const resendMessage = useCallback(async () => {
    const postMessagePayload = {
      message,
      receiver,
      id,
    };

    const { error: submitError, payload } = await resendMutate(postMessagePayload);

    if (!submitError && payload) {
      updateOwnMessage({
        status: MessageSendStatus.SENT,
        ...payload,
      });
    }
  }, [id, message, receiver, resendMutate, updateOwnMessage]);

  return (
    <>
      {status === MessageSendStatus.UNSENT && (
        <Flex variant="chat.message.unsentContainer">
          <Button
            isLoading={loading}
            variant="minimal"
            size="xs"
            shape="rounded"
            sx={{ color: 'chat.text.resend' }}
            onClick={floatingPromiseReturn(resendMessage)}
          >
            <Trans id="chat.message.resend_button">Resend</Trans>
          </Button>
          <Button variant="minimal" size="xs" shape="rounded" onClick={removeLocalMessage}>
            {t({ id: 'global.forms.buttons.cancel' })}
          </Button>
        </Flex>
      )}

      <Flex
        variant="chat.message.container"
        sx={{ ...(status && !loading && { opacity: 0.5 }) }}
        onMouseOver={() => {
          if (!status && !isVisible) setIsVisible(true);
        }}
        onMouseLeave={() => {
          if (isVisible) setIsVisible(false);
        }}
      >
        {isVisible && user && _.isEqual(sender, user.personId) && (
          <Flex variant="chat.message.hoverMenuContainer">
            <Button
              variant="minimal"
              size="xs"
              shape="rounded"
              onClick={floatingPromiseReturn(removeMessage)}
              sx={{ ':hover': { color: 'chat.text.delete' } }}
            >
              <Icon type="delete" />
            </Button>
          </Flex>
        )}
        <Avatar
          size={24}
          name={{
            firstName,
            surname,
          }}
          image={avatarUrl}
          sx={{ flexShrink: 0 }}
        />
        <Flex variant="chat.message.content">
          <Flex as="header" variant="chat.message.header">
            <Text variant="chat.message.name">
              <FullName firstName={firstName} surname={surname} />
            </Text>
            <Text as="time" variant="chat.message.date">
              {createdDate}
            </Text>
          </Flex>

          <Text variant="chat.message.text">
            <Linkify
              componentDecorator={(url, i) => (
                <LinkAnchor key={i} target="_blank" href={url}>
                  {url}
                </LinkAnchor>
              )}
            >
              {message}
            </Linkify>
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export const MemoizedMessage = React.memo(Message);
