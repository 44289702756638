import { Trans } from '@lingui/macro';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { AnchorButton, LinkButton } from 'components/ui/Buttons';
import { Container } from 'components/ui/Container';
import { Image } from 'components/ui/Image';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { TO } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { images } from 'Kiosk/constants/images';
import { usePermissions } from 'Kiosk/hooks/usePermissions';
import { PermissionState } from 'Kiosk/state/permissionState';
import { languageSelector } from 'state/recoilState';

const IMAGE_WIDTH_HEIGHT = '256px';

export const LocationRequiredErrorPage = (): React.ReactElement => {
  const { location } = usePermissions();

  const navigate = useAppNavigate();
  const language = useRecoilValue(languageSelector);

  useEffect(() => {
    if (location === PermissionState.allow) {
      navigate(TO.KIOSK[language]);
    }
  }, [location, language, navigate]);

  return (
    <Container size="sm" sx={{ textAlign: 'center', alignItems: 'center', alignSelf: 'center' }}>
      <Image
        width={IMAGE_WIDTH_HEIGHT}
        alt="Camera"
        src={images.location['1x']}
        srcSet={`${images.location['2x']}, ${images.location['3x']}`}
        sx={{ minHeight: IMAGE_WIDTH_HEIGHT }}
      />
      <Text variant="heading2" as="h2">
        <Trans id="kiosk.home.location_permission_modal.title">Location access is required</Trans>
      </Text>
      <Text variant="pLead">
        <Trans id="kiosk.home.location_permission_modal.message">
          Your organization requires the location services in order to use the app.
        </Trans>
      </Text>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center', mt: 3, gap: 2 }}>
        <AnchorButton
          href={EXTERNAL_LINKS.LOCATION_HELP}
          target="_blank"
          variant="primary"
          size="lg"
          shape="pill"
          fullWidth
        >
          <Trans id="kiosk.home.location_permission_modal.how_to_enable">Check how to enable location</Trans>
        </AnchorButton>
        <LinkButton to="/" variant="minimal" shape="pill" size="lg">
          <Trans id="global.go_back_to_app">Go back to the app</Trans>
        </LinkButton>
      </Flex>
    </Container>
  );
};
