import { useEffect, useRef, useState } from 'react';

const cachedScripts: unknown[] = [];

type initProps = {
  clientId: string;
  scope: string;
  redirectURI: string;
  state?: string;
  nonce?: string;
  usePopup?: boolean;
};

export type AppleProps = {
  auth: {
    init: (props: initProps) => void;
    renderButton: () => void;
    signIn: () => void;
  };
};

declare const AppleID: AppleProps;

export const useAppleScript = (): { loaded: boolean; error: boolean; AppleID: AppleProps | undefined } => {
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });
  const src = process.env.REACT_APP_APPLE_SCRIPT || '';
  const appleScriptRef = useRef<AppleProps | undefined>();
  const mountedRef = useRef<boolean>(true);

  useEffect(() => {
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false,
      });
    }

    cachedScripts.push(src);

    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    const onScriptLoad = () => {
      if (mountedRef.current) {
        appleScriptRef.current = AppleID;
        setState({
          loaded: true,
          error: false,
        });
      }
    };

    const onScriptError = () => {
      const index = cachedScripts.indexOf(src);

      if (index >= 0) {
        cachedScripts.splice(index, 1);
      }

      script.remove();

      setState({
        loaded: true,
        error: true,
      });
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener('load', onScriptError);
      script.removeEventListener('error', onScriptError);
      document.body.removeChild(script);
    };
  }, [src]);

  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    [],
  );

  return { loaded: state.loaded, error: state.error, AppleID: appleScriptRef.current };
};
