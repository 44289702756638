import { Plural, Trans } from '@lingui/macro';
import { FC, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { deactivateEmployeesAction } from 'api/actions/employees/employeesActions';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { parsedEmployeesSelector } from 'state/employees';

export const DeactivateTeammatesModal: FC = () => {
  const employees = useRecoilValue(parsedEmployeesSelector);

  const titleRenderer = useCallback(
    (count: number, name: string) => (
      <Plural id="team.deactivate" value={count} one={`Deactivate - ${name}?`} other="Deactivate employees?" />
    ),
    [],
  );

  const contentRenderer = useCallback(
    () => (
      <Trans id="team.deactivate.desc">
        <Text>
          After deactivating user won't be able to sign into any of our apps and will be moved to the Deactivated user
          group.
        </Text>
        <Text>
          <strong> This action is reversable, </strong> at any time you can re-activate users and send them new invites
          to join your team.
        </Text>
        <Text>Remember, deactivated users are still counted towards your currently paid limit.</Text>
      </Trans>
    ),
    [],
  );

  return (
    <ConfirmModal
      list={employees}
      namePath={['name.firstName', 'name.surname']}
      action={deactivateEmployeesAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      variant="DEACTIVATE"
    />
  );
};
