import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Heading } from 'theme-ui';
import { t, Trans } from '@lingui/macro';
import { Helmet } from 'react-helmet';

import { TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { LinkButton } from 'components/ui/Buttons';
import { APP_NAME } from 'constants/common';

export const PageNotFound = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <>
      <Helmet>
        <title>
          {t({ id: 'page_not_found' })} - {APP_NAME}
        </title>
      </Helmet>

      <Flex sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: '1 0' }}>
        <Heading as="h1" variant="heading1">
          404
        </Heading>
        <Heading as="h2" mt={1} mb={3} variant="heading2">
          <Trans id="page_not_found">Page not found 😥</Trans>
        </Heading>
        <LinkButton sx={{ fontSize: 3 }} variant="primary" shape="rounded" to={TO.START[language]}>
          <Trans id="error_page.return_button">Return to start</Trans>
        </LinkButton>
      </Flex>
    </>
  );
};
