import React, { useCallback, useState } from 'react';
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { Button, ButtonProps } from 'components/ui/Buttons';
import { Timer } from 'components/utils/Timer';
import { Icon } from 'components/Icon/Icon';
import { ParsedTimeEvent } from 'state/drawer';
import { PostTimeEventActionProps } from 'api/actions/drawer/drawerActions.types';
import { stopwatchTimeFormat } from 'utils/stopwatchTimeFormat';
import { TextEllipsis } from 'components/utils/TextEllipsis';

export type TimeEventFunc = Pick<PostTimeEventActionProps, 'timeEventTypeId' | 'isEnd'> &
  Partial<Pick<ParsedTimeEvent, 'initialTime'>>;

type Props = {
  autostart?: boolean;
  sx?: ThemeUIStyleObject;
  id: string;
  maxDurationAllowedSeconds?: string;
  workStartTime?: string;
  withTimer?: boolean;
} & ParsedTimeEvent;

const defaultProps: Partial<Props> = {
  sx: undefined,
  autostart: true,
  maxDurationAllowedSeconds: undefined,
  workStartTime: undefined,
  withTimer: false,
};

export const TimeEventTimer = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      initialTime,
      autostart,
      name,
      sx,
      isActive,
      id,
      maxDurationAllowedSeconds,
      workStartTime,
      withTimer,
      postTimeEvent,
      variant,
    }: Props,
    ref,
  ): React.ReactElement => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handlePostTimeEvent: NonNullable<ButtonProps['onClick']> = useCallback(
      (e) => {
        e.stopPropagation();
        setIsLoading(true);
        void postTimeEvent({
          timeEventTypeId: id,
          isEnd: isActive,
          initialTime,
        }).finally(() => {
          setIsLoading(false);
        });
      },
      [id, initialTime, isActive, postTimeEvent],
    );

    return (
      <Flex
        aria-label={`${t({ id: name })}`}
        tabIndex={0}
        ref={ref}
        variant="timeEventTimer.container"
        sx={{
          ...(sx && sx),
        }}
      >
        <Flex variant="timeEventTimer.content" data-active={isActive}>
          <TextEllipsis
            title={i18n._(
              t({
                id: name,
              }),
            )}
            sx={{ fontSize: 2 }}
          >
            {i18n._(
              t({
                id: name,
              }),
            )}
            {!!initialTime && workStartTime && (
              <Text variant="timeEventTimer.startTime">
                {' '}
                (
                {i18n._(
                  t({
                    id: 'time_event_timer.start_time',
                    message: 'start at',
                  }),
                )}
                {workStartTime})
              </Text>
            )}
          </TextEllipsis>
          <Text variant="timeEventTimer.timer" role="timer">
            {withTimer ? (
              <Timer
                key={`DRAWER_TIMER_${initialTime}`}
                initialTime={initialTime}
                autostart={autostart}
                active={isActive}
              />
            ) : (
              stopwatchTimeFormat(initialTime)
            )}{' '}
            {maxDurationAllowedSeconds && (
              <Text variant="timeEventTimer.maxDuration">/{maxDurationAllowedSeconds}</Text>
            )}
          </Text>
        </Flex>
        <Button
          isLoading={isLoading}
          size="sm"
          variant={variant === 'work' ? 'danger' : 'darkGrey'}
          sx={{
            width: '90px',
            flexShrink: 0,
            p: 2,
            justifyContent: 'center',
          }}
          apendWith={
            <>
              <Icon type={isActive ? 'stopCircle' : 'playCircle'} />
              {isActive ? 'STOP' : 'START'}
            </>
          }
          onClick={handlePostTimeEvent}
        />
      </Flex>
    );
  },
);

TimeEventTimer.defaultProps = defaultProps;
