import { REGEX } from 'constants/regex';

/**
 * Reduces white spaces in the provided string.
 *
 * @param {string} string
 *
 * @return {string} Returns parsed string.
 *
 * @example
 *
 * getStringWithReducedWhiteSpaces('    aaa    aa aaa    aaaa      ')
 * // => 'aaa aa aaa aaaa'
 */

export const getStringWithReducedWhiteSpaces = (string: string): string =>
  string.replace(REGEX.multipleWhiteSpaces, '').trim();
