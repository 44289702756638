import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { requestsListAction } from 'api/actions/requests/requestsActions';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { useSilentFetch } from 'hooks/useSilentFetch/useSilentFetch';
import { requestsAtom } from 'state/requests';

export const useRequests = (dates: { startDateUnix: number; endDateUnix: number } | null) => {
  const [fetchInProgress, setFetchInProgress] = useState(false);

  const { silentFetch, silentFetchAbort, fetchInTransition, initialFetchDone } = useSilentFetch(
    requestsAtom,
    requestsListAction(dates),
  );

  const internalFetchRequests = useMemo(
    () => async (unAbortable?: boolean) => {
      if (dates) {
        const { error } = await silentFetch({
          forceUpdate: true,
          unAbortable,
          responseModifier: ({ payload, error: responseError }) => {
            if (payload && payload?.innerCode === ErrorInnerCodes.DateRangeAccessDenied) {
              return { payload: { grouped: [], ungrouped: [] }, error: false };
            }
            return { payload, error: responseError };
          },
        });

        if (!error) {
          setFetchInProgress(false);
        }
      }
    },
    [dates, silentFetch],
  );

  const throttleFetchRequests = useMemo(
    () =>
      _.throttle(
        async () => {
          await internalFetchRequests();
        },
        1000,
        { leading: false },
      ),
    [internalFetchRequests],
  );

  const fetchRequests = useMemo(
    () =>
      _.throttle(() => {
        setFetchInProgress(true);
      }, 1000),
    [],
  );

  const fetchRequestsNoThrottle = useCallback(
    async (unAbortable?: boolean) => {
      await internalFetchRequests(unAbortable);
    },
    [internalFetchRequests],
  );

  useEffect(() => {
    if (fetchInProgress) {
      void throttleFetchRequests();
    }

    return () => {
      fetchRequests.cancel();
      throttleFetchRequests.cancel();
    };
  }, [dates, fetchInProgress, fetchRequests, internalFetchRequests, silentFetchAbort, throttleFetchRequests]);

  return {
    fetchRequests,
    fetchRequestsNoThrottle,
    fetchInTransition,
    fetchInProgress,
    initialFetchDone,
  };
};
