import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useRef } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex, Heading, Link, Text, ThemeUIStyleObject } from 'theme-ui';

import { Divider } from 'components/Divider/Divider';
import { Select } from 'components/ui/Select/Select';
import { Switch } from 'components/ui/Switch';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';
import { defaultAiSchedulingTrendScaleOptionsSelector, workdayAvailabilityOptionsSelector } from 'state/selectOptions';
import { blockTransitionAtom } from 'state/settings';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useFormCustomEvent } from '../../../../hooks/useFormCustomEvent';
import { useFormSubmit } from '../../../../hooks/useFormSubmit';
import { useSettingsBlockRouteTransition } from '../../../../hooks/useSettingsBlockRouteTransition';
import { OptionLabel } from '../../../OptionLabel';
import { SettingsGroup } from '../../../SettingsGroup/SettingsGroup';
import { wrapperLinkSx } from '../../../styles/wrappers';

import { useSchedules } from './hooks/useSchedules';
import { useSubmitSchedules } from './hooks/useSubmitSchedules';

const optionExplanationSx: ThemeUIStyleObject = {
  display: 'block',
  lineHeight: 1.2,
};

export const SchedulesForm = (): React.ReactElement => {
  useLingui();
  const defaultAiSchedulingTrendScaleOptions = useRecoilValue(defaultAiSchedulingTrendScaleOptionsSelector);
  const workdayAvailabilityOptions = useRecoilValue(workdayAvailabilityOptionsSelector);
  const resetBlockTransition = useResetRecoilState(blockTransitionAtom);
  const setBlockTransition = useSetRecoilState(blockTransitionAtom);
  const { schedulesSettings, setSchedulesSettings } = useSchedules();
  const showLaborCodeAlertsLink = useHelpLink({
    inEwi: { pl: '/article/alerty-kodeksu-pracy-odpoczynek-dobowy-i-tygodniowy-v3-1dznlyv/' },
  });
  const aiSchedulingLink = useHelpLink({
    inEwi: { pl: '/article/automatyczne-planowanie-grafikow-ai-1e2upkw/' },
    unrubble: { en: '/article/automatic-scheduler-planner-ai-da8wqc/' },
  });
  const { betaAccessOptIn } = useAppPermissions();

  const formRef = useRef<HTMLFormElement | null>(null);

  const { dispatchSubmitEvent, throttledDispatchSubmitEvent } = useFormSubmit(formRef);

  const handleOnChangeOnBlur = useCallback(() => {
    setBlockTransition((prevState) => ({
      ...prevState,
      blockLocationPathname: null,
      blockTransition: true,
    }));
    throttledDispatchSubmitEvent.cancel();
    throttledDispatchSubmitEvent();
  }, [setBlockTransition, throttledDispatchSubmitEvent]);

  const { registerOnChange, registerOnBlur, handleSubmit, watch } = useFormCustomEvent({
    onChangeCustom: handleOnChangeOnBlur,
    onBlurCustom: handleOnChangeOnBlur,
    formProps: { defaultValues: schedulesSettings || undefined, mode: 'onTouched', reValidateMode: 'onChange' },
  });

  const isWorkdayAvailabilityAvailableWatch = watch('isWorkdayAvailabilityAvailable');
  const useAiSchedulingWatch = watch('useAiScheduling');

  const handleFormReset = useCallback(() => {
    setSchedulesSettings(null);
    resetBlockTransition();
  }, [resetBlockTransition, setSchedulesSettings]);

  const handleSubmitCallback = useSubmitSchedules({ handleFormReset });

  useSettingsBlockRouteTransition(dispatchSubmitEvent, throttledDispatchSubmitEvent);

  return (
    <form ref={formRef} onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}>
      <Flex variant="settings.container.vertical.main">
        {betaAccessOptIn.aiSchedules && (
          <SettingsGroup variant="sm">
            <SettingsGroup.Header>
              <SettingsGroup.Title>
                <Trans id="schedules_settings.ai_automation">Ai automation</Trans>
              </SettingsGroup.Title>
            </SettingsGroup.Header>
            <SettingsGroup.Body>
              <SettingsGroup.Body.Visible
                variant="md"
                withoutBorderBottom={useAiSchedulingWatch}
                sx={{ pt: '0.75rem', pb: 2 }}
              >
                <Switch
                  {...registerOnChange('useAiScheduling')}
                  label={t({
                    id: 'schedules_settings.use_ai_scheduling',
                    message: 'Use AI scheduling',
                  })}
                  additionalInfo={
                    <>
                      <Link href={aiSchedulingLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                        {t({ id: 'settings.learn_more' })}
                      </Link>
                      .
                    </>
                  }
                  sx={{ py: SETTINGS_SPACE.switchWithLabel }}
                  bold
                  size="sm"
                />
              </SettingsGroup.Body.Visible>
              {useAiSchedulingWatch && (
                <SettingsGroup.Body.Hidden variant="md" withBorderBottom sx={{ pt: '0.75rem', pb: 3 }}>
                  <Switch
                    {...registerOnChange('autoGenerateAiSchedules')}
                    label={t({ id: 'schedules_settings.auto_generate', message: 'Auto-generate for upcoming periods' })}
                    additionalInfo={t({
                      id: 'schedules_settings.auto_generate.additional_info',
                      message:
                        'AI will automatically prepare next 5 weeks of schedules since latest published schedule',
                    })}
                    bold
                    sx={{ py: SETTINGS_SPACE.switchWithLabel }}
                    size="sm"
                  />
                  <Divider />

                  <OptionLabel
                    label={t({
                      id: 'schedules_settings.default_trend_scale',
                      message: 'Default AI scheduling trend scale',
                    })}
                    apendWith={
                      <Select
                        {...registerOnBlur('defaultAiVariant', { valueAsNumber: true })}
                        options={defaultAiSchedulingTrendScaleOptions}
                        size="sm"
                        sx={{ maxWidth: '170px' }}
                      />
                    }
                  />
                  <Flex sx={{ color: 'texts.lighter', fontSize: 2, flexDirection: 'column', gap: 1 }}>
                    <Heading variant="heading5" sx={{ mb: 1 }}>
                      <Trans id="schedules_settings.trend_scale_options_explained">Trend scale options explained</Trans>
                    </Heading>
                    <Text sx={optionExplanationSx}>
                      <Trans id="schedules_settings.trend_scale_options_explained.strict">
                        <b>Strict</b> - Follows the trend and tries to maintain the developed schedule patterns.
                      </Trans>
                    </Text>
                    <Text sx={optionExplanationSx}>
                      <Trans id="schedules_settings.trend_scale_options_explained.maximized">
                        <b>Maximized</b> - Tries to take into account the trend, but also takes into account the maximum
                        workload of the positions in the last 8 weeks.
                      </Trans>
                    </Text>
                    <Text sx={optionExplanationSx}>
                      <Trans id="schedules_settings.trend_scale_options_explained.optimized">
                        <b>Optimized</b> - Tries to takes into account the trend, but optimizes all schedule parameters
                        to maximize the potential of the team.
                      </Trans>
                    </Text>
                  </Flex>
                </SettingsGroup.Body.Hidden>
              )}
            </SettingsGroup.Body>
          </SettingsGroup>
        )}
        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title>
              <Trans id="schedules_settings.availability">Availability</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>
          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible
              variant="md"
              withoutBorderBottom={isWorkdayAvailabilityAvailableWatch}
              sx={{ pt: '0.75rem', pb: 2 }}
            >
              <Switch
                {...registerOnChange('isWorkdayAvailabilityAvailable')}
                label={t({
                  id: 'schedules_settings.allow_users_availability',
                  message: 'Allow users to set their availability for scheduling',
                })}
                bold
                size="sm"
                sx={{ py: SETTINGS_SPACE.switchWithLabel }}
              />
            </SettingsGroup.Body.Visible>
            {isWorkdayAvailabilityAvailableWatch && (
              <SettingsGroup.Body.Hidden variant="md" withBorderBottom sx={{ pt: 2, pb: '0.75rem' }}>
                <OptionLabel
                  label={t({ id: 'schedules_settings.default_day_state', message: 'Default day state' })}
                  apendWith={
                    <Select
                      {...registerOnBlur('workdayAvailabilityDefaultState', { valueAsNumber: true })}
                      options={workdayAvailabilityOptions}
                      size="sm"
                      sx={{ maxWidth: '130px' }}
                    />
                  }
                />
              </SettingsGroup.Body.Hidden>
            )}
          </SettingsGroup.Body>
        </SettingsGroup>
        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title>{t({ id: 'time_tracking_settings.heading.other' })}</SettingsGroup.Title>
          </SettingsGroup.Header>
          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="md">
              <Switch
                {...registerOnChange('showLaborCodeAlerts')}
                label={t({
                  id: 'schedules_settings.show_labor_code_alerts.label',
                  message: 'Show Labor Code alerts',
                })}
                additionalInfo={
                  <>
                    <Link
                      href={showLaborCodeAlertsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ ...wrapperLinkSx }}
                    >
                      <Trans id="settings.learn_more">Learn more</Trans>
                    </Link>
                    .
                  </>
                }
                bold
                size="sm"
              />
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>
      </Flex>
    </form>
  );
};
