import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';

// FIXME: do wywalenia przy zmianach w team?

/**
 * Ensures the correct typying of the provided value.
 *
 * @param {string | number | undefined} value
 * @return {number | undefined} Returns the parsed value.
 */

export const getNumberField = (value?: string | number): number | undefined => {
  if (isNumber(value)) {
    return value;
  }
  if (isUndefined(value)) {
    return undefined;
  }
  if (isString(value) && value.match(/^[0-9,.]+$/) != null) {
    return !isNaN(+value) ? +value : undefined;
  }
  return undefined;
};
