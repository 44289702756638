import _ from 'lodash';
import React from 'react';
import { Container, Flex, Text, ThemeUIStyleObject } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons/Button';
import { useOnLocationChange } from 'hooks/useOnLocationChange/useOnLocationChange';
import { useUnmount } from 'hooks/useUnmount/useUnmount';

import { SnackbarProps } from './types';

const containerSx: ThemeUIStyleObject = {
  display: 'flex',
  justifyContent: 'center',
  pointerEvents: 'all',
  borderRadius: 'xs',
  width: 'auto',
  maxWidth: '496px',
  textAlign: 'center',
  boxShadow: 'snackbar',
  p: 2,
  border: '1px solid',
  borderColor: 'snackbar.borderColor',
};

export const messageSx: ThemeUIStyleObject = {
  fontSize: 2,
  fontWeight: 'bold',
  lineHeight: 'heading',
  wordWrap: 'break-word',
  py: 1,
  margin: '0 0.5rem',
  my: 'auto',
};

type SnackbarComponentProps = SnackbarProps & {
  removeSnackbar: () => void;
};

type LocationObserverProps = Pick<SnackbarComponentProps, 'removeSnackbar'>;

const LocationObserver = ({ removeSnackbar }: LocationObserverProps): null => {
  useOnLocationChange(removeSnackbar);
  return null;
};

const defaultProps: Partial<SnackbarComponentProps> = {
  action: undefined,
  isStatic: false,
  prependWith: undefined,
  variant: 'default',
  placement: 'top',
  closeable: false,
};

const Snackbar = ({
  message,
  onRemove,
  action,
  prependWith,
  variant,
  isRouteBound,
  closeable,
  removeSnackbar,
}: SnackbarComponentProps): React.ReactElement => {
  const actionWithRemove =
    (snackbarAction: () => void): (() => void) =>
    () => {
      snackbarAction();
      removeSnackbar();
    };

  useUnmount(() => {
    if (!onRemove) {
      return;
    }
    onRemove();
  });

  return (
    <>
      {isRouteBound && <LocationObserver removeSnackbar={removeSnackbar} />}
      <Container
        sx={{
          ...containerSx,
        }}
        variant={`snackbars.${variant}`}
      >
        {prependWith && <Flex ml={2}>{prependWith}</Flex>}

        {_.isString(message) ? <Text sx={messageSx}>{message}</Text> : message}
        {(action || closeable) && (
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-end',
              flexGrow: 0,
              flexShrink: 0,
              ml: 2,
              gap: 4,
            }}
          >
            {closeable && (
              <Button
                size="xs"
                shape="circle"
                onClick={removeSnackbar}
                bgOverwrite={{
                  default: 'snackbar.button.background.default',
                  hover: 'snackbar.button.background.hover',
                  tap: 'snackbar.button.background.tap',
                  disabled: 'snackbar.button.background.disabled',
                }}
              >
                <Icon type="x" fill="snackbar.button.text" />
              </Button>
            )}
            {action && (
              <Button
                shape="rounded"
                size="sm"
                onClick={actionWithRemove(action.onClickCallback)}
                bgOverwrite={{
                  default: 'snackbar.button.background.default',
                  hover: 'snackbar.button.background.hover',
                  tap: 'snackbar.button.background.tap',
                  disabled: 'snackbar.button.background.disabled',
                }}
                sx={{ margin: 'auto 0', color: 'snackbar.button.text' }}
              >
                {action.title}
              </Button>
            )}
          </Flex>
        )}
      </Container>
    </>
  );
};
Snackbar.defaultProps = defaultProps;

export const MemoizedSnackbar = React.memo(Snackbar);
