/* eslint-disable @typescript-eslint/no-unused-vars */
import { t } from '@lingui/macro';
import _ from 'lodash';
import { atom, selector } from 'recoil';

import { AttendancePill, FetchTimeTrackerResponse, ParsedAttendancePill } from 'api/actions/drawer/drawerActions.types';
import { PersonName, TimeEventType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { NameDisplayOrder } from 'api/actions/userSession/userSessionActions.types';
import { DEFAULT_WORK_STATUSES_IDS } from 'constants/common';
import { ENTRY_TIME_EVENT_ID, EXIT_TIME_EVENT_ID } from 'Kiosk/constants/constants';
import { TimeEventFunc } from 'layouts/AuthorizedApp/Drawer/TimeTracker/TimeEventTimer';
import { ArrayElement } from 'utils/custom.types';
import { timeTz } from 'utils/dateTime';
import { mapAttendancePeople } from 'utils/mapAttendancePeople/mapAttendancePeople';
import { stopwatchTimeFormat } from 'utils/stopwatchTimeFormat';

import { parsedSearchFilterValueSelectorFamily, SEARCH_FILTER_TYPE } from './filters';
import { organizationSessionAtom } from './organizationSession';
import { languageSelector } from './recoilState';
import { nameDisplayOrderSelector } from './userSession';

export const attendancePillAtom = atom<AttendancePill | null>({
  key: 'attendancePill',
  default: null,
});

export const drawerSearchInputAtom = atom<string>({
  key: 'drawerSearchInput',
  default: '',
});

export const parsedDrawerSearchInputSelector = selector<string>({
  key: 'parsedDrawerSearchInput',
  get: ({ get }) => {
    const query = get(drawerSearchInputAtom);
    return query.replace(/ /g, '');
  },
});

export const parsedAndFilteredAttendancePillSelector = selector<ParsedAttendancePill | null>({
  key: 'parsedAndFilteredAttendancePill',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    const attendancePill = get(attendancePillAtom);
    const search = get(parsedDrawerSearchInputSelector);

    if (!organizationSession || !attendancePill) return null;

    const nameDisplayOrder = get(nameDisplayOrderSelector);
    const sortBy = nameDisplayOrder === NameDisplayOrder.NameFirst ? 'firstName' : 'surname';

    const { employeesMap, tagsMap, rolesMap } = organizationSession;

    const searchingFor = ({ firstName, surname }: PersonName) => {
      const string = `${firstName}${surname}${surname}${firstName}`;
      return string.toLowerCase().includes(search.toLowerCase());
    };

    type LocationAndWorkPosition = ArrayElement<AttendancePill['locations']> &
      ArrayElement<AttendancePill['workPositions']>;
    type LocationOrWorkPosition = Partial<Pick<LocationAndWorkPosition, 'locationName' | 'name'>> &
      Pick<LocationAndWorkPosition, 'people'>;

    const parsedAttendancePill = _.mapValues(
      attendancePill,
      (_pill: AttendancePill[keyof AttendancePill], key: keyof AttendancePill) => {
        switch (key) {
          case 'locations':
          case 'workPositions':
            return _.orderBy(
              _.map(attendancePill[key], ({ locationName, name, people }: LocationOrWorkPosition) => {
                const { isSomeoneWorkingDuringAbsence, workingPeopleCount, mappedPeople } = mapAttendancePeople({
                  people,
                  employeesMap,
                  rolesMap,
                  tagsMap,
                  checkWorkingDuringAbsence: true,
                  getWorkingPeopleCount: true,
                  orderBy: ['isPresent', `name.${sortBy}`],
                  filterFunc: searchingFor,
                });
                return {
                  ...(locationName && { locationName }),
                  ...(name && { name }),
                  isSomeoneWorkingDuringAbsence,
                  workingPeopleCount,
                  people: mappedPeople,
                };
              }),
              ['locationName', 'name'],
            );
          case 'working': {
            const { isSomeoneWorkingDuringAbsence, mappedPeople } = mapAttendancePeople({
              people: attendancePill[key],
              employeesMap,
              rolesMap,
              tagsMap,
              checkWorkingDuringAbsence: true,
              orderBy: [`name.${sortBy}`],
              filterFunc: searchingFor,
            });
            return {
              people: mappedPeople,
              isSomeoneWorkingDuringAbsence,
            };
          }
          default: {
            const { mappedPeople } = mapAttendancePeople({
              people: attendancePill[key],
              employeesMap,
              rolesMap,
              tagsMap,
              orderBy: [`name.${sortBy}`],
              filterFunc: searchingFor,
            });
            return mappedPeople;
          }
        }
      },
    ) as unknown as ParsedAttendancePill;
    return parsedAttendancePill;
  },
});

export const timeTrackerAtom = atom<FetchTimeTrackerResponse | null>({
  key: 'timeTracker',
  default: null,
});

export const actionHasBeenSentAtom = atom<boolean>({
  key: 'actionHasBeenSent',
  default: false,
});

type PostTimeEventFunc = {
  postTimeEvent: (body: TimeEventFunc) => Promise<void>;
};

export const postTimeEventAtom = atom<PostTimeEventFunc | null>({
  key: 'postTimeEvent',
  default: null,
});

export type ParsedTimeEvent = {
  variant: 'work' | 'custom';
  initialTime: number;
} & Pick<TimeEventType, 'id' | 'name'> & {
    isActive: boolean;
  } & PostTimeEventFunc;

type ParsedRecentTimeEvent = ParsedTimeEvent & {
  maxDurationAllowedSeconds?: string;
};

type ParsedWorkTime = {
  workStartTime: string;
} & ParsedTimeEvent;

type ParsedTimeTracker = {
  workTime: ParsedWorkTime;
  recentTimeEventsList: ParsedRecentTimeEvent[];
  availableTimeEvents: TimeEventType[];
  sortedAvailableTimeEvents: TimeEventType[];
  postTimeEvent: (body: TimeEventFunc) => Promise<void>;
} & Pick<FetchTimeTrackerResponse, 'suggestedTimeEvent'>;

export const parsedTimeTrackerSelector = selector<ParsedTimeTracker | null>({
  key: 'parsedTimeTracker',
  get: ({ get }) => {
    const timeTracker = get(timeTrackerAtom);
    const organizationSession = get(organizationSessionAtom);
    const postTimeEventFunc = get(postTimeEventAtom);
    const language = get(languageSelector);
    const searchFilterState = get(parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.DRAWER__TIME_TRACKING));

    if (!timeTracker || !organizationSession || !postTimeEventFunc) return null;

    const { postTimeEvent } = postTimeEventFunc;
    const { timeEventTypes } = organizationSession;
    const { workTimeSeconds, lastEnterUnix, recentTimeEvents, suggestedTimeEvent } = timeTracker;

    const workTime: ParsedWorkTime = {
      id: lastEnterUnix ? EXIT_TIME_EVENT_ID : ENTRY_TIME_EVENT_ID,
      initialTime: workTimeSeconds,
      isActive: !!lastEnterUnix,
      workStartTime: `${timeTz(lastEnterUnix).format('HH:mm')}`,
      variant: 'work',
      postTimeEvent,
      name: 'drawer.time_event.work_time',
    };

    const availableTimeEvents: TimeEventType[] = _.filter(timeEventTypes, (tE) => {
      const findTimeEvent = _.find(recentTimeEvents, (recentTE) => tE.id === recentTE.type.id && !tE.isDeleted);

      return !(
        findTimeEvent ||
        _.isEqual(tE.id, EXIT_TIME_EVENT_ID) ||
        _.isEqual(tE.id, ENTRY_TIME_EVENT_ID) ||
        tE.isDeleted
      );
    });

    const filteredAvailableTimeEvents: TimeEventType[] = _.filter(availableTimeEvents, ({ name }) => {
      if (_.isEmpty(searchFilterState)) return true;

      return _.some(
        searchFilterState.map((searchQuery) =>
          _.includes(t({ id: name }).toLocaleLowerCase(), searchQuery.toLocaleLowerCase()),
        ),
      );
    });

    const sortedAvailableTimeEvents: TimeEventType[] = filteredAvailableTimeEvents.sort(
      ({ id, name: firstEventName }, { id: id2, name: secondEventName }) => {
        if (id === DEFAULT_WORK_STATUSES_IDS.PAID_BREAK || id === DEFAULT_WORK_STATUSES_IDS.UNPAID_BREAK) return 1;
        if (id2 === DEFAULT_WORK_STATUSES_IDS.PAID_BREAK || id2 === DEFAULT_WORK_STATUSES_IDS.UNPAID_BREAK) return 1;

        return firstEventName.localeCompare(secondEventName, language, { caseFirst: 'upper' });
      },
    );

    const recentTimeEventsList: ParsedRecentTimeEvent[] = _.map(
      recentTimeEvents,
      ({ durationSeconds, maxDurationAllowedSeconds, type }) => ({
        initialTime: durationSeconds,
        ...(maxDurationAllowedSeconds && { maxDurationAllowedSeconds: stopwatchTimeFormat(maxDurationAllowedSeconds) }),
        isActive: false,
        variant: 'custom',
        postTimeEvent,
        ...type,
      }),
    );
    return {
      workTime,
      availableTimeEvents,
      sortedAvailableTimeEvents,
      recentTimeEventsList,
      postTimeEvent,
      suggestedTimeEvent,
    };
  },
});

export const activeTimeEventSelector = selector<ParsedTimeEvent | null>({
  key: 'activeTimeEvent',
  get: ({ get }) => {
    const timeTracker = get(parsedTimeTrackerSelector);

    if (!timeTracker) return null;

    const { workTime, recentTimeEventsList, suggestedTimeEvent } = timeTracker;

    const additionalEvent = _.find(recentTimeEventsList, ({ id }) => id === suggestedTimeEvent.type.id);

    if (additionalEvent) {
      return { ...additionalEvent, isActive: true };
    }

    return workTime;
  },
});
