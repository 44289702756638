import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { Connect } from './components/Connect/Connect';
import { Disconnect } from './components/Disconnect';
import { Expired } from './components/Expired';
import { LearnMore } from './components/LearnMore';

type Props = FlexProps;

type ButtonsContainerProps = Props;

type ButtonsContainerComponent = React.FC<ButtonsContainerProps> & {
  Connect: typeof Connect;
  Disconnect: typeof Disconnect;
  Expired: typeof Expired;
  LearnMore: typeof LearnMore;
};

export const Buttons: ButtonsContainerComponent = ({ children }: Props): React.ReactElement => (
  <Flex sx={{ mt: 'auto', gap: 1, flexWrap: 'wrap' }}>{children}</Flex>
);

Buttons.Connect = Connect;
Buttons.Disconnect = Disconnect;
Buttons.Expired = Expired;
Buttons.LearnMore = LearnMore;
