import { FC } from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { RequestsHistorySelect } from '../../RequestsHistorySelect';

import { Calendar } from './Calendar';
import { Duration } from './Duration';
import { EditionObserver } from './EditionObserver';
import { ForDayPicker } from './ForDayPicker';
import { InnerContainer } from './InnerContainer';
import { LimitsObserver } from './LimitsObserver';
import { ResetFormObserver } from './ResetFormObserver';
import { SaveStateObserver } from './SaveStateObserver';
import { SelectType } from './SelectType';
import { SkipSwitches } from './SkipSwitches';

const RequestsHistorySelectWrapper: FC = () => <RequestsHistorySelect type={RequestFormType.TimeOff} />;

type Props = FlexProps;

type TimeOffContainerProps = Props;

type TimeOffContainerComponent = FC<TimeOffContainerProps> & {
  EditionObserver: typeof EditionObserver;
  InnerContainer: typeof InnerContainer;
  SelectType: typeof SelectType;
  ForDayPicker: typeof ForDayPicker;
  Calendar: typeof Calendar;
  Duration: typeof Duration;
  SkipSwitches: typeof SkipSwitches;
  LimitsObserver: typeof LimitsObserver;
  SaveStateObserver: typeof SaveStateObserver;
  ResetFormObserver: typeof ResetFormObserver;
  RequestsHistorySelect: typeof RequestsHistorySelectWrapper;
};

export const TimeOffContainer: TimeOffContainerComponent = ({ children }: Props) => (
  <Flex sx={{ flexDirection: 'column', gap: 4, position: 'relative' }}>{children}</Flex>
);

TimeOffContainer.EditionObserver = EditionObserver;
TimeOffContainer.InnerContainer = InnerContainer;
TimeOffContainer.SelectType = SelectType;
TimeOffContainer.ForDayPicker = ForDayPicker;
TimeOffContainer.Calendar = Calendar;
TimeOffContainer.Duration = Duration;
TimeOffContainer.SkipSwitches = SkipSwitches;
TimeOffContainer.LimitsObserver = LimitsObserver;
TimeOffContainer.SaveStateObserver = SaveStateObserver;
TimeOffContainer.ResetFormObserver = ResetFormObserver;
TimeOffContainer.RequestsHistorySelect = RequestsHistorySelectWrapper;
