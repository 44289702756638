import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RecoilValue, useRecoilValue } from 'recoil';

import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { PATH_REL } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { selectedAttendanceListIdsSelector } from 'state/reports';
import { ExportModal } from '../../modals/ExportModal';

type Props = {
  customSelectedIdsRecoilState?: RecoilValue<string[]>;
};

export const ExportModalRoutes = ({ customSelectedIdsRecoilState }: Props): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const selectedIdsRecoilState = customSelectedIdsRecoilState || selectedAttendanceListIdsSelector;

  return (
    <Routes>
      {/* FIXME: find a better way */}
      <Route
        path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.GratyfikantGT}`}
        element={
          <Modal
            size="sm"
            path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.GratyfikantGT}`}
          >
            <ExportModal
              serviceIntegration={ServiceIntegration.GratyfikantGT}
              selectedIdsRecoilState={selectedIdsRecoilState}
            />
          </Modal>
        }
      />
      <Route
        path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.GratyfikantNexo}`}
        element={
          <Modal
            size="sm"
            path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.GratyfikantNexo}`}
          >
            <ExportModal
              serviceIntegration={ServiceIntegration.GratyfikantNexo}
              selectedIdsRecoilState={selectedIdsRecoilState}
            />
          </Modal>
        }
      />
      <Route
        path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.Optima}`}
        element={
          <Modal
            size="sm"
            path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.Optima}`}
          >
            <ExportModal
              serviceIntegration={ServiceIntegration.Optima}
              selectedIdsRecoilState={selectedIdsRecoilState}
            />
          </Modal>
        }
      />
      <Route
        path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.Quickbooks}`}
        element={
          <Modal
            size="sm"
            path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.Quickbooks}`}
          >
            <ExportModal
              serviceIntegration={ServiceIntegration.Quickbooks}
              selectedIdsRecoilState={selectedIdsRecoilState}
            />
          </Modal>
        }
      />
      <Route
        path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.R2Platnik}`}
        element={
          <Modal
            size="sm"
            path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.R2Platnik}`}
          >
            <ExportModal
              serviceIntegration={ServiceIntegration.R2Platnik}
              selectedIdsRecoilState={selectedIdsRecoilState}
            />
          </Modal>
        }
      />
      <Route
        path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.Symfonia}`}
        element={
          <Modal
            size="sm"
            path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.Symfonia}`}
          >
            <ExportModal
              serviceIntegration={ServiceIntegration.Symfonia}
              selectedIdsRecoilState={selectedIdsRecoilState}
            />
          </Modal>
        }
      />
      <Route
        path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.SquarePayroll}`}
        element={
          <Modal
            size="sm"
            path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.SquarePayroll}`}
          >
            <ExportModal
              serviceIntegration={ServiceIntegration.SquarePayroll}
              selectedIdsRecoilState={selectedIdsRecoilState}
            />
          </Modal>
        }
      />
      <Route
        path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.Xero}`}
        element={
          <Modal size="sm" path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.Xero}`}>
            <ExportModal serviceIntegration={ServiceIntegration.Xero} selectedIdsRecoilState={selectedIdsRecoilState} />
          </Modal>
        }
      />
      <Route
        path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.Enova}`}
        element={
          <Modal size="sm" path={`${PATH_REL.EXPORT_TO_MODAL[language].replace('/*', '')}/${ServiceIntegration.Enova}`}>
            <ExportModal
              serviceIntegration={ServiceIntegration.Enova}
              selectedIdsRecoilState={selectedIdsRecoilState}
            />
          </Modal>
        }
      />
    </Routes>
  );
};
