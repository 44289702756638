import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { Flex, Heading } from 'theme-ui';
import { v1 as uuidv1 } from 'uuid';

import { Badge } from 'components/Badge/Badge';
import { Divider } from 'components/Divider/Divider';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { ElementGroup } from 'components/ui/ElementGroup';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useContextMenu } from 'hooks/useContextMenu/useContextMenu';
import { useMount } from 'hooks/useMount/useMount';
import {
  FilterGroupState,
  filterGroupStateAtomFamily,
  getFilterGroupStateAtomDefaultValue,
  parsedSearchFilterValueSelectorFamily,
  searchFilterValueAtomFamily,
} from 'state/filters';

import { Filter } from './Filter';
import { FilterGroupButton, FilterLinkButton } from './FilterButtons';
import { FilterSearchInput } from './FilterSearchInput';
import { FilterGroupNames, FilterGroupProps } from './types';

const FilterButtonWithDropdown = withDropdown(FilterGroupButton);

const translatedAll = (groupName: FilterGroupNames) => {
  switch (groupName) {
    case FilterGroupNames.REQUEST_STATE:
    case FilterGroupNames.REQUEST_TYPES:
    case FilterGroupNames.WORK_STATUSES:
    case FilterGroupNames.LOCATIONS:
    case FilterGroupNames.WORK_STATUSES_STATE:
    case FilterGroupNames.TIME_CLOCKS__STATE:
    case FilterGroupNames.TIME_CLOCKS__SYSTEM:
    case FilterGroupNames.CALENDAR:
    case FilterGroupNames.WORK_STATUS_TYPE:
    case FilterGroupNames.WORK_STATUS_SEND_VIA:
      return t({ id: 'filter_group.all.things', message: 'All' });
    default:
      return t({ id: 'filter_group.all', message: 'All' });
  }
};

export const FilterGroup = React.memo(
  ({
    name,
    title,
    dropdownProps,
    filters,
    additionalLinks,
    sx,
    searchFilterType,
    openByDefault = true,
    hideOptionAll = false,
    hideResetButton = false,
    selectType = 'multi',
    customContent,
    hideActiveFiltersBadge = false,
    defaultFilterState,
  }: FilterGroupProps): React.ReactElement => {
    useLingui();
    const [showFilters, setShowFilters] = useState(openByDefault);
    const [showSearchFilter, setShowSearchFilter] = useState(false);
    const [filterGroupState, setFilterGroupState] = useRecoilState(filterGroupStateAtomFamily(name));
    const [activeFilters, setActiveFilters] = useState<FilterGroupState | undefined>(filterGroupState);

    const searchInputRef = useRef<HTMLInputElement | null>(null);

    const parsedSearchFilterValue = useRecoilValue(parsedSearchFilterValueSelectorFamily(searchFilterType));
    const resetSearchFilterValueState = useResetRecoilState(searchFilterValueAtomFamily(searchFilterType));
    const defaultState = useMemo(() => getFilterGroupStateAtomDefaultValue(name), [name]);

    const filteredFilters = useMemo(() => {
      if (!filters || !searchFilterType || !showSearchFilter || _.isEmpty(parsedSearchFilterValue)) {
        return filters;
      }

      return filters.filter(
        ({ label, isUnfiltrable }) =>
          isUnfiltrable ||
          parsedSearchFilterValue.some((searchQuery) =>
            _.includes(label.toLocaleLowerCase(), searchQuery.toLocaleLowerCase()),
          ),
      );
    }, [filters, parsedSearchFilterValue, searchFilterType, showSearchFilter]);

    const onResetClick = useCallback(() => {
      setActiveFilters(defaultState);
    }, [defaultState]);

    const throttledSetter = useMemo(
      () =>
        _.throttle(
          () => {
            if (activeFilters !== undefined) {
              setFilterGroupState(activeFilters);
            }
          },
          700,
          { leading: false },
        ),
      [activeFilters, setFilterGroupState],
    );

    const activeFiltersRef = useCallbackRef(activeFilters);

    // this tracks filters state changes that were initiated outside of this component, see: DataSideEffectsObserver.tsx
    useEffect(() => {
      if (!_.isEqual(activeFiltersRef.current, filterGroupState)) {
        setActiveFilters(filterGroupState);
      }
    }, [activeFiltersRef, filterGroupState]);

    useEffect(() => {
      if (activeFilters === undefined) {
        setActiveFilters(filterGroupState);
      } else if (activeFilters !== filterGroupState) {
        throttledSetter();
      }
    }, [activeFilters, filterGroupState, throttledSetter]);

    useEffect(
      () => () => {
        throttledSetter.cancel();
      },
      [throttledSetter],
    );

    const handleClick = useCallback(
      (id: string | null) => {
        if (id === null) {
          setActiveFilters(null);
          return;
        }

        if (activeFilters && activeFilters.includes(id) && selectType === 'multi') {
          const newVal = activeFilters.filter((filterId) => filterId !== id);
          setActiveFilters(newVal.length > 0 ? newVal : null);
          return;
        }

        if (activeFilters && selectType === 'multi') {
          setActiveFilters([...activeFilters, id]);
          return;
        }

        if (selectType === 'single' && activeFilters && activeFilters.includes(id)) {
          return;
        }

        setActiveFilters([id]);
      },
      [activeFilters, selectType],
    );

    useEffect(() => {
      setShowFilters(openByDefault);
    }, [openByDefault]);

    useMount(() => {
      if (defaultFilterState) {
        setFilterGroupState(defaultFilterState);
      }
    });

    const contextMenuId = useMemo(() => (dropdownProps ? uuidv1() : undefined), [dropdownProps]);
    const { openContextMenu } = useContextMenu(contextMenuId);

    return (
      <Flex
        {...(dropdownProps && { onContextMenu: openContextMenu })}
        sx={{
          fontSize: 2,
          flexDirection: 'column',
          borderRadius: 'sm',
          p: '0.25rem',
          transition: 'background 0.1s ease-in-out',
          '&:hover': {
            bg: 'asideFilters.filterGroup',
          },
          ...(!hideResetButton &&
            !_.isEqual(activeFilters, defaultState) && {
              boxShadow: 'filterActive',
            }),
          ...(sx && sx),
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            cursor: 'pointer',
            gap: '0.125rem',
            position: 'relative',
          }}
          onClick={() => setShowFilters(!showFilters)}
        >
          <Heading
            as="h4"
            sx={{
              fontSize: 2,
              lineHeight: 'body',
              mb: 0,
              flexGrow: 1,
              width: 'calc(100%)',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </Heading>

          {!hideActiveFiltersBadge && !_.isEqual(activeFilters, defaultState) && activeFilters && (
            <Badge
              variant="info"
              sx={{
                position: 'absolute',
                right: '100%',
                bottom: '100%',
                transform: 'translate(12.5%, 25%)',
                borderRadius: 'circle',
                size: '1.125rem',
                textAlign: 'center',
                boxShadow: 'filterActive',
              }}
            >
              {activeFilters.length}
            </Badge>
          )}

          {!hideResetButton && !_.isEqual(activeFilters, defaultState) && (
            <FilterGroupButton
              onClick={(e) => {
                e.stopPropagation();
                onResetClick();
              }}
              title={t({ id: 'filter_group.reset', message: 'Reset' })}
            >
              <Icon type="x" />
            </FilterGroupButton>
          )}

          {customContent && customContent}

          {dropdownProps && (
            <FilterButtonWithDropdown
              {...(contextMenuId && { key: contextMenuId })}
              dropdownProps={dropdownProps}
              onClick={(e) => e.stopPropagation()}
              popperProps={{
                contextMenuId,
              }}
            >
              <Icon type="more" />
            </FilterButtonWithDropdown>
          )}

          {searchFilterType && (
            <FilterGroupButton
              onClick={(e) => {
                e.stopPropagation();
                if (!showSearchFilter) {
                  resetSearchFilterValueState();

                  if (!showFilters) {
                    setShowFilters(true);
                  }

                  flushSync(() => {
                    setShowSearchFilter(true);
                  });
                  searchInputRef.current?.focus();
                  return;
                }

                setShowSearchFilter(false);
              }}
              isActive={showSearchFilter}
            >
              <Icon type="search" />
            </FilterGroupButton>
          )}

          <FilterGroupButton
            onClick={(e) => {
              setShowFilters(!showFilters);
              e.stopPropagation();
            }}
          >
            <Icon type={showFilters ? 'chevronUp' : 'chevronDown'} />
          </FilterGroupButton>
        </Flex>

        {showFilters && (
          <>
            {searchFilterType && showSearchFilter && (
              <FilterSearchInput
                ref={searchInputRef}
                sx={{ mt: 1 }}
                size="xs"
                placeholder={t({ id: 'filter_group.search_input.placeholder', message: 'Filter' })}
                type={searchFilterType}
                id={name}
              />
            )}
            <ElementGroup wrapperSx={{ mt: 1 }} showAsList direction="column">
              {[
                !hideOptionAll && (
                  <Filter
                    key="All"
                    groupName={name}
                    label={translatedAll(name)}
                    id={null}
                    onClick={() => handleClick(null)}
                    selectType={selectType}
                    isActive={!activeFilters}
                  />
                ),
                filteredFilters &&
                  filteredFilters.map(
                    ({
                      id,
                      label,
                      appendWith,
                      color,
                      dropdownProps: filterDropdownProps,
                      iconType,
                      isOutlined,
                      prependSx,
                      prependWith,
                      selectType: filterSelectType,
                      withDivider,
                      sx: filterSx,
                    }) => (
                      <>
                        <Filter
                          key={id}
                          groupName={name}
                          selectType={filterSelectType}
                          onClick={() => handleClick(id)}
                          isActive={!!(activeFilters && id && activeFilters.includes(id))}
                          id={id}
                          label={label}
                          appendWith={appendWith}
                          color={color}
                          dropdownProps={filterDropdownProps}
                          iconType={iconType}
                          isOutlined={isOutlined}
                          prependSx={prependSx}
                          prependWith={prependWith}
                          sx={filterSx}
                        />
                        {withDivider && <Divider sx={{ my: 1 }} />}
                      </>
                    ),
                  ),
                additionalLinks &&
                  additionalLinks.map((linkButtonProps) => (
                    <FilterLinkButton
                      key={`${linkButtonProps.title}-${
                        typeof linkButtonProps.to === 'string' ? linkButtonProps.to : ''
                      }`}
                      {...linkButtonProps}
                    />
                  )),
              ]}
            </ElementGroup>
          </>
        )}
      </Flex>
    );
  },
);
