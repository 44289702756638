import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import map from 'lodash/map';
import { FC, useCallback, useMemo } from 'react';
import { useMutation, useQuery } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { activateEmployeesAction } from 'api/actions/employees/employeesActions';
import { EmployeeImportType } from 'api/actions/employees/employeesActions.types';
import { fetchExternalProviderAction } from 'api/actions/integrations/integrationActions';
import { ExternalLoginActionSource, ExternalLoginProvider } from 'api/actions/integrations/integrationActions.types';
import { DropdownLinks } from 'components/Dropdown/types';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { useMinimizedModals } from 'components/Modal/output/useMinimizedModals';
import { ListNames } from 'components/StickyList/types';
import { HeaderMenuButton } from 'components/recipes/HeaderMenuButton';
import { LinkButton } from 'components/ui/Buttons/LinkButton';
import { DownloadExtensions } from 'constants/calendar';
import { TO, TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useFilterOutNonEditableEmployeesIds } from 'hooks/useFilterOutNonEditableEmployeesIds/useFilterOutNonEditableEmployeesIds';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { Main } from 'layouts/AuthorizedApp';
import { useHeaderMenuBreakpoint } from 'layouts/AuthorizedApp/Main/Header/hooks/useHeaderMenuBreakpoint';
import { useActiveIntegrationsLinks } from 'pages/Reports/output/useActiveIntegrationsLinks';
import { selectedRowsIdsSelectorFamily } from 'state/list';
import { MinimizedModal, ViewWithMinimizedModals } from 'state/modal';
import { importActiveIntegrationsSelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';
import { userSessionPersonIdSelector } from 'state/userSession';

const ButtonWithDropdown = withDropdown(HeaderMenuButton);

type Props = {
  selectorFamilyID: ListNames;
};

export const HeaderMenu: FC<Props> = ({ selectorFamilyID }) => {
  useLingui();

  const selectedEmployeesIds = useRecoilValue(selectedRowsIdsSelectorFamily(selectorFamilyID));
  const userId = useRecoilValue(userSessionPersonIdSelector);
  const language = useRecoilValue(languageSelector);

  const { addSnackbar } = useSnackbar();
  const navigate = useAppNavigate();

  const { mutate: activateEmployee } = useMutation(activateEmployeesAction);
  const { payload: externalPayload } = useQuery(
    fetchExternalProviderAction({ actionSource: ExternalLoginActionSource.Link }),
  );

  const { modulesManagement, canAddMoreUsers, systemManagement, isTooManyUsersBlocked, isAccountBlocked } =
    useAppPermissions();

  const isAppBlocked = isTooManyUsersBlocked || isAccountBlocked;

  const { isSM, isMD } = useHeaderMenuBreakpoint();

  const { minimizeModalActionValidation } = useMinimizedModals(ViewWithMinimizedModals.TEAM);

  const selectedEditableEmployeesIds = useFilterOutNonEditableEmployeesIds(selectedEmployeesIds, 'Employees');
  const selectedEditableEmployeesIdsWithoutCurrentUserId = useMemo(
    () => selectedEditableEmployeesIds.filter((id) => id !== userId),
    [userId, selectedEditableEmployeesIds],
  );

  const toValidationFactory = (acceptNonEditable?: boolean) => () => {
    if (selectedEditableEmployeesIdsWithoutCurrentUserId.length || (acceptNonEditable && selectedEmployeesIds.length)) {
      return true;
    }

    let message = t({ id: 'team.no_employees_selected', message: 'No employees selected!' });

    if (!acceptNonEditable && selectedEmployeesIds.length && !selectedEditableEmployeesIdsWithoutCurrentUserId.length) {
      message = t({ id: 'team.no_editable_employees_selected', message: 'No editable employees selected!' });
    }

    addSnackbar({
      message,
      variant: 'default',
      duration: 6000,
    });
    return false;
  };

  const toValidation = toValidationFactory();
  const toValidationAcceptAll = toValidationFactory(true);
  const toValidationAddMoreUsers = useCallback(() => {
    if (canAddMoreUsers) return true;

    addSnackbar({
      message: t({
        id: 'team.no_permission_to_add_more_users',
        message: "You can't add more teammates, you need to upgrade your plan first!",
      }),
      variant: 'warning',
      duration: 8000,
      ...(systemManagement.Payments && {
        action: {
          title: t({ id: 'team.upgrade_plan', message: 'Upgrade plan' }),
          onClickCallback: () => {
            navigate(TO.PAYMENTS[language]);
          },
        },
      }),
    });

    return false;
  }, [addSnackbar, canAddMoreUsers, language, navigate, systemManagement.Payments]);

  const toValidationAddTeammate = useCallback(() => {
    const validateAddMoreUsers = toValidationAddMoreUsers();

    if (!validateAddMoreUsers) return false;

    return true;
  }, [toValidationAddMoreUsers]);

  const { activeIntegrationsLinks: activeImportsLinks, isLoading } = useActiveIntegrationsLinks(
    toValidationAddMoreUsers,
    importActiveIntegrationsSelector,
    'import',
  );

  const externalIntegrationsLinks = useMemo(() => {
    if (!externalPayload?.length) return [];

    const googleIntegration = externalPayload.find(
      (integration) =>
        integration.provider === ExternalLoginProvider.GoogleInewi ||
        integration.provider === ExternalLoginProvider.GoogleTrackTime,
    );

    if (!googleIntegration?.isActive) return [];

    return [
      {
        to: `${TO_REL.IMPORT_MODAL__TYPE[language]}/${EmployeeImportType.GSuite}`,
        label: t({ id: 'team.header.import_google_workspace', message: 'From Google Workspace' }),
        toValidation: toValidationAddMoreUsers,
      },
    ];
  }, [externalPayload, language, toValidationAddMoreUsers]);

  const importLinks = useMemo(
    () => [
      {
        to: `${TO_REL.IMPORT_MODAL__TYPE[language]}/${EmployeeImportType.Excel}`,
        label: t({ id: 'team.header.import_xls', message: 'From .xls file' }),
        toValidation: toValidationAddMoreUsers,
      },
      ...activeImportsLinks,
      ...externalIntegrationsLinks,
    ],
    [activeImportsLinks, externalIntegrationsLinks, language, toValidationAddMoreUsers],
  );

  const moreLinks = useMemo(() => {
    const viewSettingsLink = {
      prependWithDivider: !!modulesManagement.Employees,
      label: t({ id: 'team.view_settings', message: 'View settings' }),
      icon: 'settings' as Icons,
      to: TO_REL.VIEW_SETTINGS_MODAL[language],
    };

    if (!modulesManagement.Employees) return [viewSettingsLink];

    const deleteLink = {
      label: t({ id: 'team.bulk.delete', message: 'Delete' }),
      icon: 'delete' as Icons,
      toValidation,
      to: TO_REL.DELETE_TEAMMATES_MODAL[language],
      state: { ids: selectedEditableEmployeesIdsWithoutCurrentUserId },
    };

    if (isAppBlocked) return [deleteLink, viewSettingsLink];

    return [
      {
        label: t({ id: 'team.bulk_actions', message: 'Bulk actions' }),
        icon: 'rocket' as Icons,
        to: TO_REL.BULK_ACTIONS_MODAL[language],
        state: { ids: selectedEditableEmployeesIds },
        toValidation,
      },
      {
        label: t({ id: 'team.bulk.activate', message: 'Activate' }),
        icon: 'unLock' as Icons,
        isButton: true,
        onClick: async () => {
          const valid = toValidation();
          if (!valid) return;
          const { error: submitError } = await activateEmployee(selectedEditableEmployeesIdsWithoutCurrentUserId);
          if (!submitError) {
            addSnackbar({
              message: t({ id: 'team.bulk.activate.success', message: 'Successfully activated!' }),
              variant: 'success',
            });
          }
        },
      },
      {
        label: t({ id: 'team.bulk.deactivate', message: 'Deactivate' }),
        icon: 'lock' as Icons,
        to: TO_REL.DEACTIVATE_TEAMMATES_MODAL[language],
        state: { ids: selectedEditableEmployeesIdsWithoutCurrentUserId },

        toValidation,
      },
      deleteLink,
      {
        prependWithDivider: true,
        prependWithTitle: t({ id: 'team.export', message: 'Export' }),
        label: t({ id: 'global.download.as_csv', message: 'As .csv' }),
        to: `${TO_REL.DOWNLOAD_MODAL__EXTENSION__TYPE[language]}/${DownloadExtensions.Csv}`,
        toValidation: toValidationAcceptAll,
        relative: 'path' as const,
      },
      {
        label: t({ id: 'global.download.as_xlsx' }),
        to: `${TO_REL.DOWNLOAD_MODAL__EXTENSION__TYPE[language]}/${DownloadExtensions.Xlsx}`,
        toValidation: toValidationAcceptAll,
        relative: 'path' as const,
      },
      viewSettingsLink,
    ];
  }, [
    activateEmployee,
    addSnackbar,
    isAppBlocked,
    language,
    modulesManagement.Employees,
    selectedEditableEmployeesIds,
    selectedEditableEmployeesIdsWithoutCurrentUserId,
    toValidation,
    toValidationAcceptAll,
  ]);

  const renderMobileMenu = useCallback(() => {
    const dropdownLinks: DropdownLinks = [
      ...(!isAppBlocked
        ? [
            ...(modulesManagement.Employees
              ? [
                  {
                    className: 'onboarding-teammates-2',
                    label: t({ id: 'global.add' }),
                    icon: 'plus' as const,
                    to: TO_REL.ADD_TEAMMATE_MODAL[language],
                    toValidation: toValidationAddTeammate,
                    toPromiseValidation: () => minimizeModalActionValidation(MinimizedModal.ADD_TEAMMATE),
                  },
                ]
              : []),
            ...(modulesManagement.Employees
              ? [
                  ...map(importLinks, (link, index) => {
                    if (index === 0) {
                      return {
                        ...link,
                        prependWithDivider: true,
                        prependWithTitle: t({ id: 'team.import' }),
                      };
                    }
                    return link;
                  }),
                  {
                    prependWithDivider: true,
                    label: t({ id: 'team.bulk.print_qr' }),
                    icon: 'print' as Icons,
                    to: TO_REL.PRINT_QR_CARDS_MODAL[language],
                    state: { ids: selectedEmployeesIds },
                    toValidation: toValidationAcceptAll,
                  },
                ]
              : [
                  {
                    label: t({ id: 'team.list.print_qr' }),
                    icon: 'print' as Icons,
                    to: TO_REL.PRINT_QR_CARDS_MODAL[language],
                    state: { ids: [userId || ''] },
                  },
                ]),
          ]
        : []),
      ...moreLinks,
    ];

    return (
      <Flex className="onboarding-teammates-2--mobile-prestep">
        <ButtonWithDropdown
          dropdownProps={{
            links: dropdownLinks,
          }}
          apendWith={null}
        >
          <Icon type="more" />
        </ButtonWithDropdown>
      </Flex>
    );
  }, [
    isAppBlocked,
    modulesManagement.Employees,
    language,
    toValidationAddTeammate,
    importLinks,
    selectedEmployeesIds,
    toValidationAcceptAll,
    userId,
    moreLinks,
    minimizeModalActionValidation,
  ]);

  const renderDesktopMenu = useCallback(
    () => (
      <>
        {modulesManagement.Employees ? (
          <>
            {!isAppBlocked && (
              <>
                <LinkButton
                  size="sm"
                  variant="primary"
                  shape="rounded"
                  prependWith={<Icon type="plus" />}
                  to={TO_REL.ADD_TEAMMATE_MODAL[language]}
                  toValidation={toValidationAddTeammate}
                  toPromiseValidation={() => minimizeModalActionValidation(MinimizedModal.ADD_TEAMMATE)}
                  className="onboarding-teammates-2"
                >
                  {t({ id: 'global.add' })}
                </LinkButton>

                <Main.Header.Menu.ButtonsGroup>
                  <ButtonWithDropdown
                    isLoading={isLoading}
                    disabled={isLoading}
                    withDropdownIndicator={!isMD}
                    popperProps={{
                      withPopperState: true,
                    }}
                    dropdownProps={{
                      links: importLinks,
                    }}
                    prependWith={<Icon type="import" />}
                    title={t({ id: 'team.import' })}
                  >
                    {!isMD && <Trans id="team.import">Import</Trans>}
                  </ButtonWithDropdown>

                  <LinkButton
                    size="sm"
                    variant="grey"
                    shape="rounded"
                    prependWith={<Icon type="print" />}
                    toValidation={toValidationAcceptAll}
                    to={TO_REL.PRINT_QR_CARDS_MODAL[language]}
                    state={{ ids: selectedEmployeesIds }}
                    title={t({ id: 'team.bulk.print_qr' })}
                  >
                    {!isMD && <Trans id="team.bulk.print_qr">Print QR cards</Trans>}
                  </LinkButton>
                </Main.Header.Menu.ButtonsGroup>
              </>
            )}
          </>
        ) : (
          <>
            {!isAppBlocked && (
              <LinkButton
                size="sm"
                variant="grey"
                shape="rounded"
                prependWith={<Icon type="print" />}
                to={TO_REL.PRINT_QR_CARDS_MODAL[language]}
                state={{ ids: [userId || ''] }}
                title={t({ id: 'team.list.print_qr' })}
              >
                {!isMD && t({ id: 'team.list.print_qr' })}
              </LinkButton>
            )}
          </>
        )}

        <ButtonWithDropdown
          dropdownProps={{
            links: moreLinks,
          }}
          apendWith={null}
        >
          <Icon type="more" />
        </ButtonWithDropdown>
      </>
    ),
    [
      modulesManagement.Employees,
      isAppBlocked,
      language,
      toValidationAddTeammate,
      isLoading,
      isMD,
      importLinks,
      toValidationAcceptAll,
      selectedEmployeesIds,
      userId,
      moreLinks,
      minimizeModalActionValidation,
    ],
  );

  return <Main.Header.Menu>{isSM ? renderMobileMenu() : renderDesktopMenu()}</Main.Header.Menu>;
};
