import React from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { settingsBorder } from 'styles/theme/settings';

import { BodyHidden } from './components/BodyHidden';
import { BodyVisible } from './components/BodyVisible';

type BodyProps = {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  sx?: ThemeUIStyleObject;
  withHeaderList?: boolean;
};

type BodyComponent = React.FC<BodyProps> & {
  Visible: typeof BodyVisible;
  Hidden: typeof BodyHidden;
};

export const Body: BodyComponent = ({ children, sx, withHeaderList }: BodyProps): React.ReactElement => (
  <Flex
    sx={{
      flexDirection: 'column',
      flexGrow: 1,
      ...(withHeaderList && {
        pl: '0.75rem',
        ...settingsBorder,
        borderBottomLeftRadius: 'sm',
        borderBottomRightRadius: 'sm',
        overflowY: 'hidden',
        overflowX: ['auto', null, null, 'hidden'],
      }),
      ...(sx && sx),
    }}
  >
    {children}
  </Flex>
);

Body.Visible = BodyVisible;
Body.Hidden = BodyHidden;
