import React, { useCallback, useEffect, useRef } from 'react';
import { Text, ThemeUIStyleObject } from 'theme-ui';

import { ElementGroup } from 'components/ui/ElementGroup';
import { useTheme } from 'styles/useTheme';
import { delay } from 'utils/delay';
import { Divider } from '../Divider/Divider';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';

import { DropdownLink } from './DropdownLink';
import { GroupTitle } from './GroupTitle';
import { DropdownLinks } from './types';

const TextWithTooltip = withTooltip(Text);

const dropdownSx: ThemeUIStyleObject = {
  bg: 'dropdown.background',
  p: 1,
  borderRadius: 'sm',
  flexDirection: 'column',
  boxShadow: 'dropdown',
  overflowY: 'auto',
};

type Props = {
  links: DropdownLinks;
  onClick?: () => void;
  sx?: ThemeUIStyleObject;
  size?: 'sm' | 'default';
};

export type DropdownProps = Props;

const defaultProps: Partial<Props> = {
  sx: undefined,
  onClick: undefined,
  size: 'default',
};

export const Dropdown = ({ links, sx, onClick, size }: Props): React.ReactElement => {
  const { theme } = useTheme();

  const firstElement = useRef<HTMLDivElement | null>(null);

  const focusTrap = useCallback(async () => {
    if (firstElement.current) {
      await delay(100);

      if (!firstElement.current) return;
      firstElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (links && size) {
      void focusTrap();
    }
  }, [focusTrap, links, size]);

  if (!size) {
    return <></>;
  }

  return (
    <ElementGroup
      showAsList
      wrapperSx={{
        ...theme.dropdown[size],
        ...dropdownSx,
        ...(sx && sx),
      }}
      direction="column"
    >
      {links.map(({ prependWithTitle, prependWithDivider, tooltipText, sx: linkSx, ...link }, index) => (
        <React.Fragment key={`${link.label}${link.to}`}>
          {prependWithDivider && <Divider sx={{ my: 1 }} />}
          {prependWithTitle && <GroupTitle title={prependWithTitle} />}
          <ConditionalWrapper
            condition={!!tooltipText}
            wrapper={({ children: wrapperChildren }) => (
              <TextWithTooltip tooltipProps={{ content: tooltipText }}>{wrapperChildren}</TextWithTooltip>
            )}
          >
            <DropdownLink
              ref={index === 0 ? firstElement : null}
              {...link}
              sx={{ ...theme.dropdown[size].linkSx, ...linkSx }}
              to={link.to}
              isLoading={link.isLoading}
              onClick={() => {
                if (link.onClick) {
                  link.onClick();
                }

                if (onClick && !link.omitDropdownOnClick) {
                  onClick();
                }
              }}
            >
              {link.label}
            </DropdownLink>
          </ConditionalWrapper>
        </React.Fragment>
      ))}
    </ElementGroup>
  );
};

Dropdown.defaultProps = defaultProps;
