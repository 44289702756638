import { ThemeUIStyleObject } from 'theme-ui';

import { makeTheme } from '../makeTheme';

const dropdownContainer: ThemeUIStyleObject = {
  bg: 'calendar.forms.bg.dropdown',
  borderRadius: 'sm',
  flexDirection: 'column',
  boxShadow: 'dropdown',
  py: '0.75rem',
  gap: 3,
  '> *': {
    px: '0.75rem',
  },
};

const dualCalendarContainer: ThemeUIStyleObject = {
  borderRadius: 'sm',
  flexDirection: 'column',
  gap: 3,
  '> *': {
    px: '0.75rem',
  },
};

const asideFilterContainer: ThemeUIStyleObject = {
  bg: 'calendar.forms.bg.asideFilter',
  borderRadius: 'xs',
  flexDirection: 'column',
  p: 1,
  gap: 1,
};

const fromModalContainer: ThemeUIStyleObject = {
  bg: 'calendar.forms.bg.asideFilter',
  borderRadius: 'sm',
  flexDirection: 'column',
  py: '0.75rem',
  gap: 3,
  '> *': {
    px: '0.75rem',
  },
};

const controlsContainer: ThemeUIStyleObject = {
  alignItems: 'center',
  justifyContent: 'space-between',
  userSelect: 'none',
  gap: 2,
  '*': {
    userSelect: 'none',
  },
};

const arrowButton: ThemeUIStyleObject = {
  px: 1,
  // '&[data-is-hidden="true"]': {
  //   pointerEvents: 'none',
  //   userSelect: 'none',
  //   opacity: 0,
  // },
};

const controlsSelectInput: ThemeUIStyleObject = {
  bg: 'calendar.forms.bg.controlsSelectInput.bg',
  border: 'none',
  outline: 'none',
  'input[type="text"]': {
    fontWeight: 'bold',
    textAlign: 'center',
    '&::placeholder': {
      color: (t) => `${t.colors?.calendarControlsSelectInputPlaceholder} !important`,
    },
  },
};

const hideSelectArrowSx: ThemeUIStyleObject = {
  borderRadius: 'xs',
  '> div': {
    display: 'none',
  },
  'input[type="text"]': {
    fontWeight: 'bold',
    textAlign: 'center',
    p: 1,

    '&::placeholder': {
      color: (t) => `${t.colors?.calendarHideSelectArrowPlaceholder} !important`,
    },
  },
};

const controlsSelectInputAside: ThemeUIStyleObject = {
  ...controlsSelectInput,
  ...hideSelectArrowSx,
};
const controlsSelectInputDualCalendar: ThemeUIStyleObject = {
  ...controlsSelectInput,
  ...hideSelectArrowSx,
};
const controlsSelectInputFromModal: ThemeUIStyleObject = {
  ...controlsSelectInput,
  ...hideSelectArrowSx,
};

const day: ThemeUIStyleObject = {
  height: '40px',
  width: '40px',
  my: '1px',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 2,
  userSelect: 'none',
  position: 'relative',
};

export const calendarStyles = makeTheme({
  forms: {
    calendar: {
      dropdown: {
        container: dropdownContainer,
        controls: {
          container: controlsContainer,
          arrowButton,
          select: controlsSelectInput,
        },
        day,
      },
      dualCalendar: {
        container: dualCalendarContainer,
        controls: {
          container: controlsContainer,
          arrowButton,
          select: controlsSelectInputDualCalendar,
        },
        day,
      },
      asideFilter: {
        container: asideFilterContainer,
        controls: {
          container: {
            ...controlsContainer,
            gap: 0,
          },
          arrowButton,
          select: controlsSelectInputAside,
        },
        day: {
          ...day,
          size: '29px',
          '> div': {
            size: '27px',
          },
        },
      },
      fromModal: {
        container: fromModalContainer,
        controls: {
          container: controlsContainer,
          arrowButton,
          select: controlsSelectInputFromModal,
        },
        day,
      },
    },
  },
});
