import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Text } from 'theme-ui';

import { addSnackbar, removeAllSnackbars } from 'base/Snackbar/output/actions';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { resetFormButtonAtom, resetFormButtonCallbacksSelector } from 'state/settings';

export const useFormReset = () => {
  useLingui();
  const setResetButtonCallbacks = useSetRecoilState(resetFormButtonAtom);
  const formResetCallbacks = useRecoilValue(resetFormButtonCallbacksSelector);
  const { isAccountBlocked, isTooManyUsersBlocked } = useAppPermissions();
  const isAppActivate = useMemo(
    () => !isAccountBlocked && !isTooManyUsersBlocked,
    [isAccountBlocked, isTooManyUsersBlocked],
  );
  const handleReset = useCallback(() => {
    if (formResetCallbacks) {
      formResetCallbacks.forEach((reset) => reset());
    }
    setResetButtonCallbacks(null);
  }, [formResetCallbacks, setResetButtonCallbacks]);

  const handleResetButtonClick = useCallback(() => {
    const resetEvent = new Event('resetFormEvent');
    document.dispatchEvent(resetEvent);
  }, []);

  useEffect(() => {
    if (formResetCallbacks && formResetCallbacks.length) {
      addSnackbar({
        message: (
          <Text sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: 2 }}>
            {t({
              id: 'settings.error_reset_message',
              message: 'Error occurred. Solve the problem or use the button beside.',
            })}
          </Text>
        ),
        action: {
          title: t({ id: 'settings.error_form.reset', message: 'Reset' }),
          onClickCallback: handleResetButtonClick,
        },
        isStatic: true,
        variant: 'warning',
      });
    }
  }, [formResetCallbacks, handleResetButtonClick]);

  useEffect(() => {
    if (!formResetCallbacks && _.isEmpty(formResetCallbacks) && isAppActivate) {
      removeAllSnackbars();
    }
  }, [formResetCallbacks, isAppActivate]);

  useEffect(() => {
    document.addEventListener('resetFormEvent', handleReset);

    return () => document.removeEventListener('resetFormEvent', handleReset);
  }, [handleReset]);

  useUnmount(() => {
    if (isAppActivate) removeAllSnackbars();
  });
};
