import { t } from '@lingui/macro';
import { FC, ReactNode, useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { Flex, ThemeUICSSObject } from 'theme-ui';

import { requestDownloadAction, requestPrintAction } from 'api/actions/requests/requestsActions';
import { RequestFormType, RequestState } from 'api/actions/requests/requestsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { Button } from 'components/ui/Buttons';
import { TO_REL } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useContextMenu } from 'hooks/useContextMenu/useContextMenu';
import { FancyList } from 'pages/Reports/output/FancyList';
import { languageSelector } from 'state/recoilState';
import { ParsedRequest } from 'state/requests';
import { DAY_DETAILS_PATTERNS } from 'styles/theme/calendar_patterns';
import { useRequestsLogic } from '../../../hooks/useRequestsLogic';
import { getActionsPermissions } from '../utils/getActionsPermissions';

const ButtonWithDropdown = withDropdown(Button);

type Props = {
  children: ReactNode | ReactNode[];
  employeeId: ParsedRequest['employee']['id'];
  isCurrentUserRequest: boolean;
  isEmployeeNonEditable: boolean;
  onClick: () => void;
  sx?: ThemeUICSSObject;
} & Pick<ParsedRequest, 'actions' | 'id' | 'isDeleted' | 'number' | 'state' | 'swapPersonId' | 'type'>;

export const RequestFancyListItem: FC<Props> = ({
  actions,
  children,
  employeeId,
  id,
  isCurrentUserRequest,
  isEmployeeNonEditable,
  isDeleted,
  number,
  onClick,
  state,
  swapPersonId,
  sx,
  type,
}) => {
  const language = useRecoilValue(languageSelector);
  const { modulesManagement } = useAppPermissions();
  const { openContextMenuFactory } = useContextMenu();
  const {
    actions: { approveRequest },
  } = useRequestsLogic();

  const { mutate: mutatePrintRequests } = useMutation(requestPrintAction);
  const { mutate: mutateDownloadRequests } = useMutation(requestDownloadAction);

  const handlePrint = useCallback(
    async (requestId: string) => {
      await mutatePrintRequests({ ids: [requestId] });
    },
    [mutatePrintRequests],
  );

  const handleDownload = useCallback(
    async (requestId: string) => {
      await mutateDownloadRequests({ ids: [requestId] });
    },
    [mutateDownloadRequests],
  );

  const { acceptAllowedForSwap, rejectAllowedForSwap, acceptAllowed, rejectAllowed, deleteAllowed } =
    getActionsPermissions(actions);

  const showManageRequestActions = (() => {
    if (state !== RequestState.Pending || isDeleted) return false;

    if (type === RequestFormType.Schedule && (acceptAllowedForSwap || rejectAllowedForSwap)) return true;

    if ((acceptAllowed || rejectAllowed) && modulesManagement.Requests) return true;

    return false;
  })();

  const isRequestManagementAllowed = isCurrentUserRequest || modulesManagement.Requests;

  return (
    <FancyList.Item
      {...(isRequestManagementAllowed && { onClick })}
      onContextMenu={(e) => void openContextMenuFactory(id)(e)}
      sx={{
        minHeight: '60px',
        pl: '0.75rem',
        ...(!isDeleted &&
          state === RequestState.Pending && {
            ...DAY_DETAILS_PATTERNS.pending,
            outlineColor: 'list.rowOutline.pending',
            '&:hover': {
              ...DAY_DETAILS_PATTERNS.pending,
              outlineColor: 'list.rowOutline.pendingHover',
            },
          }),
        ...(sx && sx),
      }}
    >
      {children}
      {isRequestManagementAllowed && (
        <Flex sx={{ flex: '0 0 auto' }}>
          <ButtonWithDropdown
            dropdownProps={{
              links: [
                ...(showManageRequestActions
                  ? [
                      {
                        label: t({ id: 'requests.button.approve' }),
                        icon: 'approve' as Icons,
                        isButton: true,
                        onClick: async () => {
                          if (!acceptAllowedForSwap && !acceptAllowed) {
                            addSnackbar({
                              variant: 'warning',
                              message: t({ id: 'request.swap_is_pending' }),
                              duration: 5000,
                            });
                            return;
                          }

                          await approveRequest(id, employeeId, acceptAllowedForSwap, swapPersonId);
                        },
                        disabled: !acceptAllowedForSwap && !acceptAllowed,
                      },
                      {
                        to: TO_REL.REJECT_REQUESTS_MODAL[language],
                        state: {
                          id,
                          ids: swapPersonId ? [swapPersonId, employeeId] : [employeeId],
                          requestNumber: number,
                          isSchedule: rejectAllowedForSwap,
                        },

                        label: t({ id: 'requests.button.reject' }),
                        icon: 'deny' as Icons,
                        disabled: !rejectAllowedForSwap && !rejectAllowed,
                      },
                    ]
                  : []),
                {
                  label: t({ id: 'global.print' }),
                  icon: 'print',
                  onClick: () => void handlePrint(id),
                  prependWithDivider: showManageRequestActions,
                },
                {
                  label: t({ id: 'global.download' }),
                  icon: 'download',
                  onClick: () => void handleDownload(id),
                },
                ...(!isDeleted || deleteAllowed || (isCurrentUserRequest && !isEmployeeNonEditable)
                  ? [
                      {
                        prependWithDivider: true,
                        disabled: isDeleted || !deleteAllowed || (!isCurrentUserRequest && isEmployeeNonEditable),
                        label: t({ id: 'global.delete' }),
                        icon: 'delete' as Icons,
                        to: TO_REL.DELETE_REQUESTS_MODAL[language],
                        state: {
                          ids: [id],
                          requestNumber: number,
                          employeesIds: swapPersonId ? [swapPersonId, employeeId] : [employeeId],
                          deleteGroupRequest: {
                            latestInGroup: true,
                            state,
                          },
                        },
                      },
                    ]
                  : []),
              ],
            }}
            shape="rounded"
            size="sm"
            variant="minimal"
            onClick={(e) => e.stopPropagation()}
            popperProps={{
              contextMenuId: id,
            }}
          >
            <Icon type="more" />
          </ButtonWithDropdown>
        </Flex>
      )}
    </FancyList.Item>
  );
};
