import React, { useState } from 'react';

type Props = {
  renderer: (isLoading: boolean, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => React.ReactElement;
};

export const LoadingStateLogic = ({ renderer }: Props): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);

  return renderer(isLoading, setIsLoading);
};
