/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DEFAULT_FPS } from 'Kiosk/constants/constants';

type Props = {
  callback: () => void;
  fps?: number;
  autostart?: boolean;
};

type ReturnProps = {
  isAnimationRunning: boolean;
  startAnimation: () => void;
  stopAnimation: () => void;
};

export const useAnimationFrame = ({ callback, fps = DEFAULT_FPS, autostart = false }: Props): ReturnProps => {
  const [isAnimationRunning, setIsAnimationRunning] = useState(false);

  const frameRate = useMemo(() => 1000 / fps, [fps]);
  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();
  const lastFrameRef = useRef<number>(0);

  const startAnimation = useCallback(() => {
    setIsAnimationRunning(true);
    console.log('useAnimationFrame', '[START]');
  }, []);

  const stopAnimation = useCallback(() => {
    setIsAnimationRunning(false);
    console.log('useAnimationFrame', '[STOP]');
  }, []);

  useEffect(() => {
    if (autostart) {
      console.log('useAnimationFrame', '[AUTOSTART]');
      startAnimation();
    }
  }, [autostart, startAnimation]);

  useEffect(() => {
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
    }

    const animate = (time: number) => {
      if (previousTimeRef.current === undefined) {
        previousTimeRef.current = time;
      } else {
        const currentFrame = Math.round((time - previousTimeRef.current) / frameRate);

        if (lastFrameRef.current !== currentFrame) {
          callback();
          lastFrameRef.current = currentFrame;
        }
      }

      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }

      requestRef.current = requestAnimationFrame(animate);
    };

    if (isAnimationRunning) {
      requestRef.current = requestAnimationFrame(animate);
    }

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [callback, frameRate, isAnimationRunning]);

  return { isAnimationRunning, startAnimation, stopAnimation };
};
