import { t } from '@lingui/macro';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { getSchedulesToSwapAction } from 'api/actions/requests/requestsActions';
import { GetSchedulesToSwapResponse, RequestState } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { useMount } from 'hooks/useMount/useMount';
import { allEmployeesSelector } from 'state/employees';
import { dateTime } from 'utils/dateTime';
import { RequestSchedulesRenderer } from '../../renderers/RequestSchedulesRenderer';
import { useRequestDetails } from '../hooks/useRequestDetails';

export const ScheduleDetails = (): React.ReactElement | null => {
  const allEmployees = useRecoilValue(allEmployeesSelector);
  const { parsedRequestDetails } = useRequestDetails();

  const [fetchedSchedules, setFetchedSchedules] = useState<GetSchedulesToSwapResponse | null>(null);

  const { mutate, loading } = useMutation(getSchedulesToSwapAction);

  const getSchedules = useCallback(async () => {
    if (!parsedRequestDetails) return;
    if (parsedRequestDetails.state !== RequestState.Pending || parsedRequestDetails.isDeleted) return;

    const {
      swapPersonId,
      employee,
      newEvent: {
        requestDateTimeDetails: { dates },
      },
    } = parsedRequestDetails;

    const { error, payload } = await mutate({ peopleIds: [employee.id, swapPersonId || ''], date: dates[0] });

    if (!error && payload) {
      setFetchedSchedules(payload);
    }
  }, [mutate, parsedRequestDetails]);

  useMount(() => {
    void getSchedules();
  });

  const schedulesRenderer = useMemo(() => {
    if (!parsedRequestDetails) return null;

    const {
      swapPersonId,
      employee,
      newEvent: {
        eventDetails: { schedulesEventDetails },
      },
    } = parsedRequestDetails;

    const schedules = schedulesEventDetails || fetchedSchedules;

    if (!schedules) return null;

    const targetSwapEmployee = allEmployees?.get(swapPersonId || '');

    if (!employee || !targetSwapEmployee) return null;

    const employeeSchedule = schedules.filter(({ personId }) => personId === employee.id);
    const targetSwapEmployeeSchedule = schedules.filter(({ personId }) => personId === swapPersonId);

    return (
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 3,
          }}
        >
          {t({ id: 'add_request.swap_details' })}
        </Text>
        <Flex sx={{ flex: '1', gap: 2 }}>
          <RequestSchedulesRenderer schedule={employeeSchedule} employee={employee} />
          <Flex sx={{ flexDirection: 'column' }}>
            <Icon type="arrowRight" size={24} iconSx={{ color: 'requests.arrow' }} wrapperSx={{ mb: '-0.75rem' }} />
            <Icon type="arrowLeft" size={24} iconSx={{ color: 'requests.arrow' }} />
          </Flex>
          <RequestSchedulesRenderer schedule={targetSwapEmployeeSchedule} employee={targetSwapEmployee} />
        </Flex>
      </Flex>
    );
  }, [allEmployees, fetchedSchedules, parsedRequestDetails]);

  if (loading) return <CenteredLoadingSpinner size={4} />;

  return (
    <>
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 3,
          }}
        >
          {t({ id: 'request.details.time_frame.on' })}
        </Text>
        <Text sx={{ fontSize: 2 }}>
          {dateTime(parsedRequestDetails?.newEvent.requestDateTimeDetails.dates[0], { utc: true }).format('dd, ll')}
        </Text>
      </Flex>
      {schedulesRenderer}
    </>
  );
};
