import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Flex, FlexProps, ThemeUIStyleObject } from 'theme-ui';

import { PersonName } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Icon, IconProps } from 'components/Icon/Icon';
import { LoadingSpinnerSize } from 'components/Loading/LoadingSpinnerCSS';
import { Image, ImageProps } from 'components/ui/Image';
import { useGenerateInitials } from 'hooks/useInitials/useInitials';

type Props = {
  size?: number;
  circle?: boolean;
  name?: PersonName;
  image?: string;
  sx?: ThemeUIStyleObject;
  iconType?: IconProps['type'];
  className?: string;
  imageProps?: Partial<ImageProps>;
} & Pick<FlexProps, 'onClick'>;

export type AvatarProps = Props;

const defaultProps: Partial<Props> = {
  size: 34,
  circle: false,
  name: undefined,
  image: undefined,
  sx: undefined,
  className: undefined,
  iconType: 'user',
  imageProps: {
    loadingOverlaySize: LoadingSpinnerSize.imageXS,
  },
};

export const Avatar = ({
  size,
  circle,
  name,
  image,
  sx,
  iconType,
  className,
  imageProps,
  ...props
}: Props): React.ReactElement => {
  const [imageError, setImageError] = useState(false);
  const imageErrorRef = useRef(false);

  const generatedInitials = useGenerateInitials();

  const borderRadius = useMemo(() => {
    if (circle) return 'circle';
    if (size && size < 40) return '6px';
    return 'sm';
  }, [circle, size]);

  const handleImageError = () => {
    setImageError(true);
    imageErrorRef.current = true;
  };

  useEffect(() => {
    if (image && imageErrorRef.current) {
      setImageError(false);
      imageErrorRef.current = false;
    }
  }, [image]);

  return (
    <Flex
      {...props}
      className={className}
      as="span"
      sx={{
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        bg: 'avatar.background',
        color: 'avatar.text',
        width: `${size}px`,
        minWidth: `${size}px`,
        height: `${size}px`,
        minHeight: `${size}px`,
        fontSize: `${size && size / 1.8}px`,
        lineHeight: 0,
        borderRadius,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        ...(sx && sx),
      }}
    >
      {name && (!image || imageError) && generatedInitials(name.firstName, name.surname)}
      {image && !imageError && (
        <Image
          key={image}
          onError={handleImageError}
          sx={{ height: '100%', width: '100%' }}
          src={image}
          alt="Avatar"
          {...imageProps}
        />
      )}
      {(!image || imageError) && !name && <Icon type={iconType || 'user'} />}
    </Flex>
  );
};

Avatar.defaultProps = defaultProps;
