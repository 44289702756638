type SetIntervalX = (
  callback: () => void | boolean, // return true if conditions for clearInterval have been met
  ms?: number | undefined,
  repetitions?: number,
) => NodeJS.Timer;

/**
 * Creates a interval that will be cleared after it runs the specified number of times (repetitions) or when the callback function will return true.
 *
 * @param {() => void | boolean} callback SetInterval callback, if it return true the interval will be cleared.
 * @param {number | undefined} ms Milliseconds to be passed as second argument of setInterval.
 * @param {number | undefined} repetitions Max number that the callback will be called.
 *
 * @return {NodeJS.Timer} Returns intervalID that can be used in clearInterval.
 */

export const setIntervalX: SetIntervalX = (callback, ms, repetitions) => {
  let currentRepetitionsCount = 0;
  const intervalID = setInterval(() => {
    const shouldClearInterval = callback();
    currentRepetitionsCount += 1;
    if (shouldClearInterval || (repetitions && currentRepetitionsCount >= repetitions)) {
      clearInterval(intervalID);
    }
  }, ms);
  return intervalID;
};
