import { useMemo } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { REPORTS_TYPE, TO } from 'constants/routes';
import { languageSelector } from 'state/recoilState';

import { AttendanceListRenderer } from './components/renderers/AttendanceListRenderer/AttendanceListRenderer';
import { TimesheetsRenderer } from './components/renderers/TimesheetsRenderer/TimesheetsRenderer';

export const ReportsRenderer = () => {
  const language = useRecoilValue(languageSelector);
  const params = useParams();

  const { type } = params;

  const reportElement = useMemo(
    () =>
      (() => {
        switch (type) {
          case REPORTS_TYPE.ATTENDANCE_LIST[language]:
            return <AttendanceListRenderer />;
          case REPORTS_TYPE.TIMESHEETS[language]:
            return <TimesheetsRenderer />;
          default:
            return null;
        }
      })(),
    [language, type],
  );

  if (!reportElement) {
    return <Navigate to={TO.PAGE_NOT_FOUND[language]} />;
  }

  return (
    <>
      {reportElement}
      <Outlet />
    </>
  );
};
