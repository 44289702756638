import { Trans, t } from '@lingui/macro';
import { Flex, Heading, Text } from 'theme-ui';

import { Button } from 'components/ui/Buttons';
import { onboardingStepsType } from '../../types';

export function onboardingUiWelcome(playNextStep: () => void): onboardingStepsType {
  return [
    {
      content: (
        <Flex sx={{ flexDirection: 'column', alignContent: 'center', gap: 'inherit', textAlign: 'center' }}>
          <Flex variant="onboarding.modalHeaderIcon">📐</Flex>
          <Heading variant="heading3" as="h3">
            <Trans id="onboarding.ui.welcome.title">Let's get a hang of the UI.</Trans>
          </Heading>
          <Text as="p" variant="p">
            <Trans id="onboarding.ui.welcome.message">
              Most of the elements are self-explanatory, so we will go through the <b>unique ones only</b>.
            </Trans>
          </Text>

          <Flex sx={{ gap: 2, justifyContent: 'center', pt: 3 }}>
            <Button shape="rounded" variant="primary" onClick={playNextStep}>
              {t({ id: 'onboarding.action.next_step' })}
            </Button>
          </Flex>
        </Flex>
      ),
      mode: 'modal',
    },
  ];
}

export function onboardingUiWorkTimeTracker(
  playNextStep: () => void,
  playCustomNextStep: (steps: number) => void,
  isLocationRequired: boolean,
): onboardingStepsType {
  const basicStepsArr: onboardingStepsType = [
    {
      content: (
        <>
          <Text>
            <Trans id="onboarding.ui.time_tracking.step1">
              Here's a <b>work time tracker</b>, click here and you will start it.
            </Trans>
          </Text>
          <Flex sx={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={isLocationRequired ? () => playCustomNextStep(2) : playNextStep}
              size="xs"
              shape="rounded"
              variant="lightGrey"
            >
              <Trans id="onboarding.actions.skip">Skip step</Trans>
            </Button>
          </Flex>
        </>
      ),
      targetElement: 'onboarding-worktimetracker-1',
    },
  ];

  const permissionStepsArr: onboardingStepsType = [
    {
      content: (
        <>
          <Text>
            <Trans id="onboarding.ui.time_tracking.step2">
              Your organization requires location data. Please follow below instruction to turn it on, and restart this
              guide.
            </Trans>
          </Text>
        </>
      ),
      targetElement: 'onboarding-worktimetracker-2',
    },
  ];

  if (isLocationRequired) {
    basicStepsArr.push(permissionStepsArr[0]);
  }

  return basicStepsArr;
}

export function onboardingUiAttendanceOverview(
  playNextStep: () => void,
  playCustomNextStep: (steps: number) => void,
  isMobileBreakpoint: boolean,
): onboardingStepsType {
  return [
    {
      content: (
        <>
          <Text>
            <Trans id="onboarding.ui.attendance.step1">
              That's an <b>attendance overview</b>. Click here and let's have a look around.
            </Trans>
          </Text>
          <Flex sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={() => playCustomNextStep(2)} size="xs" shape="rounded" variant="lightGrey">
              {t({ id: 'onboarding.actions.skip' })}
            </Button>
          </Flex>
        </>
      ),
      targetElement: 'onboarding-attendanceoverview-1',
      onClickElement: playNextStep,
    },
    {
      content: (
        <>
          <Text>
            <Trans id="onboarding.ui.attendance.step2.part1">
              Here you can see all your <b>teammates grouped into a proper working states</b>.
            </Trans>
          </Text>
          <Text>
            <Trans id="onboarding.ui.attendance.step2.part3">
              Once you are done looking around, simply <b>click anywhere outside to continue</b>.
            </Trans>
          </Text>
        </>
      ),
      targetElement: 'onboarding-attendanceoverview-2',
      onOutsideClick: (e) => {
        if (isMobileBreakpoint) {
          const node = e.target as HTMLElement;
          node.focus();
        }
        playNextStep();
      },
      ...(isMobileBreakpoint && {
        placement: 'bottom',
      }),
    },
  ];
}
