import { RecoilValueReadOnly, useRecoilValue } from 'recoil';

import { FetchOrganizationSessionResponse } from 'api/actions/organizationSession/organizationSessionActions.types';
import { organizationSessionPropertySelectorFamily } from 'state/organizationSession';

export const useRoleDescription = (roleId?: string) => {
  const roles = useRecoilValue(
    organizationSessionPropertySelectorFamily('roles') as RecoilValueReadOnly<
      FetchOrganizationSessionResponse['roles'] | null
    >,
  );

  return roles?.find(({ id }) => id === roleId)?.description;
};
