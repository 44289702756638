import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import React, { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { InvitationJoinRequestPayload } from 'api/actions/auth/authActions.types';
import { FormCard } from 'components/FormCard/FormCard';
import { Button, ButtonProps } from 'components/ui/Buttons/Button';
import { ElementGroup } from 'components/ui/ElementGroup';
import { TextInput } from 'components/ui/TextInput';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

type Props = {
  onSubmit: (props: InvitationJoinRequestPayload) => Promise<boolean>;
  submitButtonLoading: ButtonProps['isLoading'];
};

const NOT_INVITED_FORM_ID = 'employee-not-invited-form-id';

export const EmployeeNotInvitedForm = ({ onSubmit, submitButtonLoading }: Props): React.ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onTouched', reValidateMode: 'onChange' });

  const navigate = useAppNavigate();

  const handleSubmitCallback: SubmitHandler<Partial<InvitationJoinRequestPayload>> = useCallback(
    async (props) => {
      const { email, note, userName } = props;
      if (!!email && !!userName) {
        await onSubmit({
          email,
          userName,
          note,
        });
      }
    },
    [onSubmit],
  );

  const handleBackOnClick = () => {
    navigate(-1);
  };

  return (
    <>
      <form id={NOT_INVITED_FORM_ID} onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))} noValidate>
        <ElementGroup marginValue="4" direction="column">
          <TextInput
            id="email"
            autoComplete="off"
            label={i18n._(
              t({
                id: 'sign_up.employee.not_invited.form.email',
                message: "Administrator's email address",
              }),
            )}
            placeholder={i18n._(
              t({
                id: 'global.forms.inputs.email_example',
              }),
            )}
            type="email"
            error={!!errors.email}
            errorMessage={errors?.email?.message}
            clearable
            {...register('email', validationFactory({ ...VALIDATION_RULES.EMAIL, required: true }))}
          />

          <TextInput
            id="userName"
            autoComplete="name"
            label={i18n._(
              t({
                id: 'sign_up.employee.not_invited.form.user_name',
                message: 'Your full name',
              }),
            )}
            placeholder={i18n._(
              t({
                id: 'global.forms.inputs.name_example',
                message: 'John Late',
              }),
            )}
            type="text"
            error={!!errors.userName}
            errorMessage={errors?.userName?.message}
            clearable
            {...register('userName', validationFactory({ required: true }))}
          />

          <TextInput
            id="note"
            label={i18n._('sign_up.employee.form.note')}
            type="text"
            error={!!errors.note}
            errorMessage={errors?.note?.message}
            clearable
            {...register('note')}
          />
        </ElementGroup>
      </form>

      <FormCard.Footer>
        <Button variant="minimal" shape="rounded" size="lg" type="button" onClick={handleBackOnClick}>
          {t({ id: 'global.forms.buttons.back' })}
        </Button>

        <Button
          variant="primary"
          shape="rounded"
          size="lg"
          type="submit"
          form={NOT_INVITED_FORM_ID}
          isLoading={submitButtonLoading}
        >
          <Trans id="sign_up.employee.not_invited.form.submit_button">Send an invite request</Trans>
        </Button>
      </FormCard.Footer>
    </>
  );
};
