import { FC, PropsWithChildren, useRef } from 'react';
import { Flex } from 'theme-ui';
import { MemoryRouter, Route, Routes, Outlet } from 'react-router-dom';

import { DRAWER_ROUTES } from 'constants/memoryRoutes';
import { WorkTimeTrackerProvider } from 'context/workTimeTracker/WorkTimeTrackerProvider';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';

import { Drawer } from './Drawer';
import { LocationPermissionRoutes } from './TimeTracker/LocationPermissionRoutes';
import { AttendanceOverview } from './AttendanceOverview/AttendanceOverview';
import { TimeTracker } from './TimeTracker/TimeTracker';
import { NotificationCenter } from './NotificationCenter/NotificationCenter';

export const DrawerMemoryRouter: FC<PropsWithChildren> = ({ children }) => {
  const {
    features: { AddEventsByVrcp },
  } = useAppPermissions();

  const globalNavigate = useAppNavigate();
  const drawerRef = useRef<HTMLDivElement | null>(null);

  return (
    <MemoryRouter>
      <ConditionalWrapper
        condition={AddEventsByVrcp}
        wrapper={({ children: wrapperChildren }) => (
          <WorkTimeTrackerProvider>{wrapperChildren}</WorkTimeTrackerProvider>
        )}
      >
        <Flex ref={drawerRef}>
          <LocationPermissionRoutes />
          <Routes>
            <Route
              element={
                // change with caution, element with vertical scroll must remain in Drawer, see: scrollParentRef
                <Drawer globalNavigate={globalNavigate} drawerRef={drawerRef}>
                  <Outlet />
                </Drawer>
              }
            >
              <Route path={DRAWER_ROUTES.DRAWER__ATTENDANCE_OVERVIEW} element={<AttendanceOverview />} />
              <Route path={DRAWER_ROUTES.DRAWER__TIME_TRACKER} element={<TimeTracker />} />
              <Route path={DRAWER_ROUTES.DRAWER__NOTIFICATION_CENTER} element={<NotificationCenter />} />
            </Route>
          </Routes>

          {children}
        </Flex>
      </ConditionalWrapper>
    </MemoryRouter>
  );
};
