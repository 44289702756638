/* eslint-disable @typescript-eslint/require-await */
import type { ResponseInterceptor } from 'react-fetching-library';

import { Action } from 'api/types';
import { PATH } from 'constants/routes';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';

const PREVENT_BULK_REFRESHES_MS_THRESHOLD = 10;
let lastSentTimestampUnix: number | null = null;
let lastRefreshSessionHeaderValue: number | null = null;

export const responseRefreshSessionInterceptor: () => ResponseInterceptor =
  () => () => async (action: Action<unknown>, response) => {
    if (action?.config?.skipAllResponseInterceptors) return response;

    if (response.status === 503) {
      emitCustomEvent(CustomEvents.REDIRECT, PATH.SERVICE_UNAVAILABLE);
      return response;
    }

    const refreshSessionHeaderValue = response.headers?.get('x-refresh-session');

    if (!refreshSessionHeaderValue || (action.config && action.config.skipRefreshSession)) {
      return response;
    }

    const sentTimestampUnix = action.config?.sentTimestampUnix;

    let msThresholdNotReached = false;

    if (sentTimestampUnix && lastSentTimestampUnix) {
      const timeFromLastRefresh = sentTimestampUnix - lastSentTimestampUnix;
      msThresholdNotReached = timeFromLastRefresh <= PREVENT_BULK_REFRESHES_MS_THRESHOLD;
    }

    // avoid multiple refresh sessions in short time span
    if (msThresholdNotReached && lastRefreshSessionHeaderValue === +refreshSessionHeaderValue) {
      return response;
    }

    lastSentTimestampUnix = sentTimestampUnix || null;
    lastRefreshSessionHeaderValue = +refreshSessionHeaderValue;

    emitCustomEvent(CustomEvents.REFRESH_SESSION, +refreshSessionHeaderValue);

    return response;
  };
