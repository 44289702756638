import React from 'react';
import { Text, TextProps } from 'theme-ui';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { Icon } from 'components/Icon/Icon';

import { withTooltip } from './Tooltip/withTooltip';
import { TooltipProps } from './Tooltip/Tooltip';

const TextWithTooltip = withTooltip(Text);

type Props = Omit<TextProps, 'children'> & {
  tooltip?: string;
  tooltipProps?: TooltipProps;
  ellipsis?: boolean;
  children: string;
};

const defaultProps: Partial<Props> = {
  ellipsis: false,
  tooltip: undefined,
  tooltipProps: undefined,
};

export const CopyToClipboardText = ({
  ellipsis,
  children,
  sx,
  tooltip,
  tooltipProps,
  ...props
}: Props): React.ReactElement => {
  const { addSnackbar } = useSnackbar();

  const handleOnClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    void navigator.clipboard.writeText(children);

    addSnackbar({
      message: i18n._(
        t({
          id: 'global.copied',
          message: 'Copied!',
        }),
      ),
      prependWith: <Icon type="approve" />,
    });
  };

  return (
    <TextWithTooltip
      tooltipProps={{
        content:
          tooltip ||
          i18n._(
            t({
              id: 'global.copy_to_clipboard',
              message: 'Copy to clipboard',
            }),
          ),
        ...tooltipProps,
      }}
      as="span"
      onClick={handleOnClick}
      {...props}
      sx={{
        ...sx,
        ...(ellipsis && {
          width: 'calc(100%)',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }),
      }}
    >
      {children}
    </TextWithTooltip>
  );
};

CopyToClipboardText.defaultProps = defaultProps;
