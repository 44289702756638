import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { FC, useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { registerPZUSportAction } from 'api/actions/PZUSport/PZUSportActions';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Modal } from 'components/Modal/Modal';
import { useModal } from 'components/Modal/hooks/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { Checkbox } from 'components/ui/Checkbox';
import { ElementGroup } from 'components/ui/ElementGroup';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { TextInput } from 'components/ui/TextInput';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { userDetailsSelector } from 'state/userSession';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

const PZU_TERMS_AND_CONDITIONS_LINK = 'https://benefity.pzu.pl/pub/Regulamin_Systemu_inEwi.pdf';
export const PZU_ACTIVATED_ACCOUNT_MODAL_ID = 'PZU_ACTIVATED_ACCOUNT_MODAL_ID';

export type PZUFormType = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  ruleAcceptance: boolean;
};

export const PZUFormModal: FC = () => {
  const userDetails = useRecoilValue(userDetailsSelector);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm<PZUFormType>({
    defaultValues: {
      email: userDetails?.email || '',
      firstName: userDetails?.name?.firstName || '',
      lastName: userDetails?.name?.surname || '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const confirmPasswordWatch = watch('confirmPassword');
  const { handleClose } = useModal();
  const { mutate } = useMutation(registerPZUSportAction);
  const onSubmit = useCallback<SubmitHandler<PZUFormType>>(
    async ({ confirmPassword: _, ...data }) => {
      const { payload, error } = await mutate(data);
      if (error && !payload?.innerCode) addSnackbar({ message: t({ id: 'error.something_went_wrong' }) });
      if (!error) {
        handleClose();
        emitCustomEvent(CustomEvents.MODAL_OPEN, { id: PZU_ACTIVATED_ACCOUNT_MODAL_ID, state: { email: data.email } });
      }
    },
    [handleClose, mutate],
  );

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="your_benefits.pzu_sport.activation_form_title">PZU Sport - Activation</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form noValidate>
          <ElementGroup direction="column" marginValue={16}>
            <TextInput
              {...register('firstName', validationFactory({ ...VALIDATION_RULES.FIRST_NAME, required: true }))}
              id="firstName"
              placeholder={t({ id: 'settings.user.profile.first_name' })}
              label={t({ id: 'settings.user.profile.first_name' })}
              variant="rounded"
              error={!!errors.firstName}
              errorMessage={errors?.firstName?.message}
              icon="account"
              size="sm"
              clearable
            />
            <TextInput
              {...register('lastName', validationFactory({ ...VALIDATION_RULES.SURNAME, required: true }))}
              id="lastName"
              placeholder={t({ id: 'settings.user.profile.surname' })}
              label={t({ id: 'settings.user.profile.surname' })}
              variant="rounded"
              error={!!errors.lastName}
              errorMessage={errors?.lastName?.message}
              icon="account"
              size="sm"
              clearable
            />
            <TextInput
              id="email"
              placeholder={t({ id: 'global.forms.inputs.email' })}
              label={t({ id: 'global.forms.inputs.email' })}
              variant="rounded"
              type="email"
              autoComplete="email"
              error={!!errors.email}
              errorMessage={errors?.email?.message}
              icon="mail"
              size="sm"
              clearable
              {...register('email', validationFactory({ ...VALIDATION_RULES.EMAIL, required: true }))}
            />
            <TextInput
              id="password"
              type="password"
              {...register('password', {
                ...validationFactory({
                  ...VALIDATION_RULES.PASSWORD,
                  ...VALIDATION_RULES.PZU_PASSWORD,
                  required: true,
                }),
                ...(confirmPasswordWatch && { deps: ['confirmPassword'] }),
              })}
              label={t({ id: 'global.forms.inputs.password' })}
              placeholder={t({ id: 'global.forms.inputs.password' })}
              error={!!errors.password}
              errorMessage={errors?.password?.message}
              variant="rounded"
              size="sm"
              autoComplete="new-password"
              icon="lock"
            />
            <TextInput
              id="confirmPassword"
              type="password"
              autoComplete="new-password"
              {...register('confirmPassword', {
                ...validationFactory({ required: true }),
                validate: (value, formValues) =>
                  value === formValues.password || t({ id: 'settings.user.change_password.not_match' }),
              })}
              error={!!errors.confirmPassword}
              errorMessage={errors?.confirmPassword?.message}
              variant="rounded"
              icon="lock"
              size="sm"
              label={t({ id: 'settings.user.change_password.confirm_password' })}
              placeholder={t({ id: 'settings.user.change_password.confirm_password' })}
            />
            <Flex>
              <Checkbox
                sx={{ mt: 2 }}
                size="sm"
                {...register('ruleAcceptance', validationFactory({ required: true }))}
                label={
                  <>
                    <Trans id="your_benefits.pzu_sport.activation_form_terms_acceptance">I know and accept </Trans>
                    <LinkAnchor
                      sx={{ textDecoration: 'underline' }}
                      href={PZU_TERMS_AND_CONDITIONS_LINK}
                      target="_blank"
                    >
                      <Trans id="sign_in.footer.link.terms">Terms of use</Trans>
                    </LinkAnchor>
                    .
                  </>
                }
              />
            </Flex>
          </ElementGroup>
        </form>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            type: 'submit',
            variant: 'primary',
            disabled: !isValid,
            isLoading: isSubmitting,
            onClick: floatingPromiseReturn(handleSubmit(onSubmit)),
            children: <Trans id="your_benefits.pzu_sport.activate">Active</Trans>,
          },
        ]}
      />
    </>
  );
};
