import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useLocation } from 'react-router-dom';

import { addRoleAction, fetchRoleDetailsAction } from 'api/actions/roles/rolesActions';
import { AddRoleActionProps, FetchRoleDetailsResponse } from 'api/actions/roles/rolesActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { AddEditRole } from '../../forms/AddEditRole';

export const AddRoleModal: FC = () => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal({ wrapperSx: { height: '800px', maxHeight: '100%' } });
  const handleCloseRef = useCallbackRef(handleClose);
  const [duplicateRoleDetails, setDuplicateRoleDetails] = useState<FetchRoleDetailsResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const { id } = state || {};

  const { mutate: fetchDuplicateRoleDetails } = useMutation(fetchRoleDetailsAction);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { mutate } = useMutation(addRoleAction);

  useEffect(() => {
    const getDuplicateRoleDetails = async () => {
      if (!id) return;
      const { error, payload } = await fetchDuplicateRoleDetails({ roleId: id });
      if (error) {
        handleCloseRef.current();
      }
      if (!error && payload) {
        setDuplicateRoleDetails({
          ...payload,
          name: `${t({
            id: payload.name,
          })} ${t({ id: 'team.add_role.duplicate', message: ` - DUPLICATE` })}`,
          description:
            payload.description &&
            t({
              id: payload.description,
            }),
        });
        setLoading(false);
      } else {
        setLoading(false);
        handleClose();
      }
    };
    if (id && !duplicateRoleDetails) {
      setLoading(true);
      void getDuplicateRoleDetails();
    }
  }, [id, handleClose, fetchDuplicateRoleDetails, duplicateRoleDetails, handleCloseRef]);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: AddRoleActionProps): Promise<boolean> => {
      const { error: submitError } = await mutate(body);
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
        await delay(0);
        addSnackbar({
          message: t({ id: 'team.add_role.added', message: 'Role successfully added!' }),
          variant: 'success',
        });
      }
      setLoading(false);
      return true;
    },
    [handleClose, mutate, addSnackbar],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.add_role">Add role</Trans>
        </Modal.Title>
      </Modal.Header>
      {id && !duplicateRoleDetails ? (
        <CenteredLoadingSpinner />
      ) : (
        <>
          <Modal.Body>
            <AddEditRole
              ref={formRef}
              defaultValues={duplicateRoleDetails || undefined}
              onSubmit={onSubmit}
              setLoading={setLoading}
              isDuplicate={!!id}
            />
          </Modal.Body>
          <BasicModalFooter
            buttons={[
              {
                isLoading: loading,
                onClick: handleSave,
                variant: 'primary',
                children: t({ id: 'save', message: 'Save' }),
              },
            ]}
          />
        </>
      )}
    </>
  );
};
