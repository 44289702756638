import { t } from '@lingui/macro';

import { getDurationObj } from './getDurationObj';

export const displayDuration = (duration: number) => {
  const { days, hours, minutes } = getDurationObj(duration);

  let durationString = '';

  if (days !== 0) durationString += `${days} ${t({ id: days > 1 ? 'days' : 'day' })}`;

  if (hours !== 0) {
    if (durationString.length) durationString += ' ';

    durationString += `${hours}${t({ id: 'duration_picker.h' })}`;
  }

  if (minutes !== 0) {
    if (durationString.length) durationString += ' ';

    durationString += `${minutes}${t({ id: 'duration_picker.minutes' })}`;
  }

  return durationString;
};
