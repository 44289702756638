import { FetchUserSessionResponse } from 'api/actions/userSession/userSessionActions.types';
import { PublisherAddNotificationProps } from 'base/Notification/output/types';
import { actionNames } from 'broadcastChannel/channelReducer/actionNames';
import { ChannelMessage, GetTimeTrackerChannelActionProps } from 'broadcastChannel/channelReducer/channelReducer.types';
import { TAB_ID } from 'constants/common';
import { SupportedLanguages } from 'constants/supportedLanguages';
import { OrganizationSessionAtomType } from 'state/organizationSession';

export const refreshUserSession: (userSession: FetchUserSessionResponse) => ChannelMessage = (userSession) => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.REFRESH_USER_SESSION,
    payload: userSession,
  },
});

// FIXME: jotai migration
export const refreshOrganizationSession: (organizationSession: OrganizationSessionAtomType) => ChannelMessage = (
  organizationSession,
) => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.REFRESH_ORGANIZATION_SESSION,
    payload: organizationSession,
  },
});

export const getTimeTracker: (extendedTimeTracker: GetTimeTrackerChannelActionProps) => ChannelMessage = (
  extendedTimeTracker,
) => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.GET_TIME_TRACKER,
    payload: extendedTimeTracker,
  },
});

export const changeLanguage: (language: keyof typeof SupportedLanguages) => ChannelMessage = (language) => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.CHANGE_LANGUAGE,
    payload: language,
  },
});

export const signOut: () => ChannelMessage = () => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.SIGN_OUT,
  },
});

export const addNotification: (notification: PublisherAddNotificationProps) => ChannelMessage = (notification) => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.ADD_REFRESH_PAGE_NOTIFICATION,
    payload: notification,
  },
});

export const addRefreshPageNotification: (message?: string) => ChannelMessage = (message) => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.ADD_REFRESH_PAGE_NOTIFICATION,
    payload: message,
  },
});

export const signIn: () => ChannelMessage = () => ({
  tabId: TAB_ID,
  action: {
    type: actionNames.SIGN_IN,
  },
});
