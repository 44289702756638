import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';

export const useHeaderMenuBreakpoint = (withDayDetails?: boolean) => {
  const { isSmartphoneBreakpoint, isMobileBreakpoint, breakpoint } = useThemeBreakpoint();

  const isMD = breakpoint === MEDIA_BREAKPOINTS.MD;
  const isLG = breakpoint === MEDIA_BREAKPOINTS.LG;
  const isXL = breakpoint === MEDIA_BREAKPOINTS.XL;

  return {
    ...(withDayDetails && { detailsPanelPositionBottom: isMobileBreakpoint }),
    isSM: isSmartphoneBreakpoint,
    isMD: withDayDetails ? isMD || ((isLG || isXL) && withDayDetails) : isMD,
    isLG: isLG || isXL,
  };
};
