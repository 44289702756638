import { useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';

export const ResetFormObserver = (): null => {
  const firstRenderRef = useRef<boolean>(true);

  const { defaultDateUnix } = useAddRequest();

  const { watch, reset } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');
  const prevActionType = usePrevious(actionTypeWatch);
  const actionTypeChangeToCreate =
    actionTypeWatch === RequestActionType.Create &&
    (prevActionType === RequestActionType.Edit || prevActionType === RequestActionType.Remove);
  const actionTypeChangeFromCreate =
    (actionTypeWatch === RequestActionType.Edit || actionTypeWatch === RequestActionType.Remove) &&
    prevActionType === RequestActionType.Create;

  const handleFormReset = useCallback(() => {
    if ((actionTypeChangeToCreate || actionTypeChangeFromCreate) && !firstRenderRef.current) {
      reset({
        actionType: actionTypeWatch,
        autoAcceptRequest: false,
        processedById: null as unknown as undefined, // reset requires value
        optionalStepProcessedById: null as unknown as undefined,
        dateTimeDetails: { date: defaultDateUnix || (null as unknown as undefined) }, // reset requires value
        details: {
          typeId: '',
        },
      });
    }

    firstRenderRef.current = false;
  }, [actionTypeChangeToCreate, actionTypeChangeFromCreate, reset, actionTypeWatch, defaultDateUnix]);

  useEffect(() => {
    handleFormReset();
  }, [handleFormReset]);

  return null;
};
