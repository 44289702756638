/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { plural, t } from '@lingui/macro';
import _ from 'lodash';
import type { QueryResponse, ResponseInterceptor } from 'react-fetching-library';

import { Currency, SnackbarVariant } from 'api/actions/payments/paymentsActions.types';
import { Action, ActionConfig, DefaultErrorMessageEnum, ErrorResponse } from 'api/types';
import { SNACKBAR_DEFAULT_DURATION } from 'base/Snackbar/output/SnackbarHub';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { SnackbarProps } from 'components/Snackbar/types';
import { ErrorGroupMessage } from 'components/recipes/ErrorGroupMessage';
import { ErrorInnerCodes, SecondaryInnerCodes } from 'constants/errorInnerCodes';
import { LanguageRoute, REDIRECT_ROUTES, RedirectKeys } from 'constants/routes';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';

const SNACKBAR_ERROR_LIMIT = 10;

type ErrorDetails = {
  innerCode: ErrorInnerCodes;
  secondaryInnerCode?: SecondaryInnerCodes;
  itemDescription?: string;
  fieldName?: string;
  message?: string;
  value?: string;
};

type Errors = ErrorDetails[];

type SnackbarLink = {
  redirectKey: RedirectKeys;
  id?: string;
};

type DefaultErrorData = {
  snackbarVariant?: SnackbarVariant;
  errors?: Errors;
  snackbarLink?: SnackbarLink;
};

type DefaultErrorResponse<InnerCodes = undefined> = ErrorResponse<DefaultErrorData, InnerCodes>;

export const responseErrorInnerCodeInterceptor: () => ResponseInterceptor =
  () => () => async (action: Action<unknown>, response: QueryResponse<DefaultErrorResponse>) => {
    if (action?.config?.skipAllResponseInterceptors) return response;

    if (response.status === 500) {
      addSnackbar({
        message: t({ id: 'error_500', message: 'Unexpected system error' }),
        variant: 'danger',
      });
      return response;
    }

    if ((response.status !== 400 && response.status !== 403) || (action.config && action.config.skipInnerCode)) {
      return response;
    }
    const responseInnerCode = response.payload?.innerCode;
    const innerCodesThatOmmitsSnackbar = action.config?.innerCodesThatOmmitsSnackbar;
    const ommitSnackbar =
      innerCodesThatOmmitsSnackbar && responseInnerCode
        ? innerCodesThatOmmitsSnackbar.includes(responseInnerCode)
        : false;

    if (ommitSnackbar) {
      return response;
    }

    const serverErrorMessage = response.payload?.message;
    const mainErrorMessage =
      serverErrorMessage || (response.payload?.innerCode && errorMessageReducer(response.payload?.innerCode)?.message);
    const errors = response.payload?.data?.errors;
    const snackbarLink = response.payload?.data?.snackbarLink;
    const baseRoute = action.config?.baseRoute;

    const errorMessageContext = action.config?.errorMessageContext;
    let validErrors = errors
      ?.map(({ itemDescription, message, innerCode, secondaryInnerCode, fieldName, value }) => ({
        itemDescription,
        message: message || errorMessageReducer(innerCode)?.message,
        ...(!_.isNil(secondaryInnerCode) &&
          fieldName && { secondaryMessage: secondaryErrorMessageReducer(secondaryInnerCode, fieldName, value) }),
      }))
      .filter(({ itemDescription, message }) => !!itemDescription && !!message) as Required<ErrorDetails>[] | undefined;
    let errorActionSnackbarProps: Partial<SnackbarProps> | undefined;

    if (snackbarLink) {
      errorActionSnackbarProps = errorActionReducer(snackbarLink, baseRoute, responseInnerCode);
    }

    if (validErrors?.length) {
      let footer;
      if (validErrors.length > SNACKBAR_ERROR_LIMIT) {
        const remainingErrorsCount = validErrors.length - SNACKBAR_ERROR_LIMIT;
        footer = t({
          id: 'errors.import_error_footer',
          message: plural(remainingErrorsCount, {
            one: `...and ${remainingErrorsCount} other error.`,
            few: `...and ${remainingErrorsCount} other errors`,
            other: `...and ${remainingErrorsCount} other errors`,
          }),
        });
        validErrors = validErrors?.slice(0, SNACKBAR_ERROR_LIMIT);
      }

      const duration = Math.max(
        errorActionSnackbarProps ? SNACKBAR_DEFAULT_DURATION + 2000 : SNACKBAR_DEFAULT_DURATION,
        validErrors.length * 1000,
      );

      addSnackbar({
        variant: snackbarVariantDictionary(response.payload?.data?.snackbarVariant) || 'danger',
        duration,
        message: <ErrorGroupMessage title={mainErrorMessage} errors={validErrors} footer={footer} />,
        closeable: true,
        ...(errorActionSnackbarProps && errorActionSnackbarProps),
      });
      return response;
    }

    const customDefaultErrorMessage = action.config?.customDefaultErrorMessage;
    const defaultErrorMessage = getDefaultErrorMessage(customDefaultErrorMessage || DefaultErrorMessageEnum.Default);

    if (!responseInnerCode) {
      if (!defaultErrorMessage) {
        return response;
      }

      addSnackbar({ ...defaultErrorMessage, ...(errorActionSnackbarProps && errorActionSnackbarProps) });

      return response;
    }

    const showServerErrorMessage = errorInnerCodesWithServerMessages.includes(responseInnerCode);

    if (showServerErrorMessage && serverErrorMessage) {
      addSnackbar({
        message: serverErrorMessage,
        variant: 'danger',
        ...(errorActionSnackbarProps && errorActionSnackbarProps),
      });
      return response;
    }

    const errorMessage = errorMessageReducer(responseInnerCode, errorMessageContext);

    if (!errorMessage) {
      if (defaultErrorMessage) {
        addSnackbar({ ...defaultErrorMessage, ...(errorActionSnackbarProps && errorActionSnackbarProps) });

        return response;
      }

      return response;
    }

    addSnackbar({ ...errorMessage, ...(errorActionSnackbarProps && errorActionSnackbarProps) });

    return response;
  };

//
// HELPERS
//
export const snackbarVariantDictionary = (variant?: SnackbarVariant) => {
  switch (variant) {
    case SnackbarVariant.Success:
      return 'success';
    case SnackbarVariant.Info:
      return 'default';
    case SnackbarVariant.Warning:
      return 'warning';
    case SnackbarVariant.Danger:
      return 'danger';
    default:
      return undefined;
  }
};

const errorInnerCodesWithServerMessages = [ErrorInnerCodes.TimeEventMagnet];

const errorActionReducer = (
  snackbarLink: SnackbarLink,
  baseRoute: Partial<LanguageRoute> | undefined,
  errorInnerCode?: ErrorInnerCodes,
): Partial<SnackbarProps> | undefined => {
  const buttonTitle = (() => {
    switch (errorInnerCode) {
      case ErrorInnerCodes.RequestAlreadyAdded:
        return t({ id: 'request.open_request', message: 'Open request' });
      default:
        return t({ id: 'error_action.go', message: 'Go' });
    }
  })();

  const { redirectKey, id } = snackbarLink;
  const redirectRoute = REDIRECT_ROUTES(redirectKey, baseRoute);

  return {
    action: {
      title: buttonTitle,
      onClickCallback: () => emitCustomEvent(CustomEvents.REDIRECT, { route: redirectRoute, id }),
    },
    duration: 5000,
  };
};

export const errorMessageReducer = (
  errorInnerCode: ErrorInnerCodes,
  context?: ActionConfig['errorMessageContext'],
): SnackbarProps | undefined => {
  const snackbarProps: SnackbarProps | undefined = ((): SnackbarProps | undefined => {
    switch (errorInnerCode) {
      // 403 Forbidden
      case ErrorInnerCodes.InvalidSubscription:
        return {
          message: t({ id: 'inner_code_error.invalid_subscription', message: 'Invalid subscription' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.NoPermissions:
        return {
          message: t({ id: 'inner_code_error.no_permissions', message: 'No permissions' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.DeviceDeleted:
        return {
          message: t({ id: 'inner_code_error.device_deleted', message: 'Device has been deleted' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.DateRangeAccessDenied:
        return {
          message: t({ id: 'inner_code_error.date_range_access_denied', message: 'Access denied for given dates' }),
          variant: 'danger',
        };

      // 400 Bad request
      case ErrorInnerCodes.BadRequestData:
        return {
          message: t({ id: 'inner_code_error.bad_request_data', message: 'Bad request data' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.BadCredentials:
        return {
          message: t({ id: 'inner_code_error.bad_credentials', message: 'Bad credentials' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.InvalidTimeZoneId:
        return {
          message: t({
            id: 'inner_code_error.invalid_time_zone_id',
            message: 'Incorrect time zone, please choose a correct one from the Select.',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.NoDataToExport:
        return {
          message: t({ id: 'inner_code_error.no_data_to_export', message: 'No data to export' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.DuplicateName:
        return {
          message: t({ id: 'inner_code_error.duplicate_name', message: 'Duplicate name' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.RedirectClientToResponseUri:
        return {
          message: t({
            id: 'inner_code_error.redirect_client_to_response_uri',
            message: 'Additional authorization required',
          }),
          variant: 'warning',
        };

      // Time Tracking
      case ErrorInnerCodes.BadQrCode:
        return {
          message: t({ id: 'inner_code_error.bad_qr_code', message: 'Wrong QR code!' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.InvalidFace:
        return {
          message: t({ id: 'inner_code_error.invalid_face', message: 'Your face was not recognized!' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.MissingFaceModel:
        return {
          message: t({ id: 'inner_code_error.missing_face_model', message: 'Missing face model' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.BadDeviceTime:
        return {
          message: t({ id: 'inner_code_error.bad_device_time', message: 'Wrong device time!' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.InvalidLocation:
        return {
          message: t({ id: 'inner_code_error.invalid_location', message: 'You are not in allowed location!' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.OfflineModeDisabled:
        return {
          message: t({ id: 'inner_code_error.offline_mode_disabled', message: 'Offline mode is disabled' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.TimeEventDuplicate:
        return {
          message: t({ id: 'inner_code_error.time_event_duplicate', message: 'Time Event is a duplicate' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.AddTimeEventOnSkippedDays:
        return {
          message: t({
            id: 'inner_code_error.add_events_on_skipped_days',
            message:
              'No events added. Please make sure picked calendar dates are aligned with picked days of the week and option "Skip holidays".',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.TimeEventNotFound:
        return {
          message: t({ id: 'inner_code_error.time_event_not_found', message: 'Time event not found' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.DeletePhotosError:
        return {
          message: t({ id: 'inner_code_error.delete_photos_error', message: 'Delete photos error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.LocationNotFound:
        return {
          message: t({ id: 'inner_code_error.location_not_found', message: 'Location not found' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.ClockInNotAllowed:
        return {
          message: t({
            id: 'inner_code_error.clock_in_not_allowed',
            message: "You aren't allowed to Clock In via Kiosk",
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.NoLocationsToRemove:
        return {
          message: t({ id: 'inner_code_error.no_locations_to_remove', message: 'No locations to remove' }),
          variant: 'danger',
        };

      // Face Recognition
      case ErrorInnerCodes.FaceModelCreationError:
        return {
          message: t({
            id: 'inner_code_error.face_model_creation_error',
            message: 'Error during face model creation, please try again.',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.FacePhotoUsedInModel:
        return {
          message: t({
            id: 'inner_code_error.photo_used_in_model',
            message: 'Photo is being used in model for face recognition',
          }),
          variant: 'danger',
        };

      // User
      case ErrorInnerCodes.UserAlreadyRegister:
        return {
          message: t({
            id: 'inner_code_error.user_already_register',
            message: 'This e-mail address is already taken 😥',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UserCreationError:
        return {
          message: t({ id: 'inner_code_error.user_creation_error', message: 'Error creating user' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UserAuthenticateError:
        return {
          message: t({ id: 'inner_code_error.user_authenticate_error', message: 'User authenticate error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UserNotExist:
        return {
          message: t({ id: 'inner_code_error.user_not_exist', message: "User doesn't exist" }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UserIsInactive:
        return {
          message: t({ id: 'inner_code_error.user_is_inactive', message: 'User is inactive' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UserResetPasswordError:
        return {
          message: t({
            id: 'inner_code_error.user_reset_password_error',
            message: 'Reset link is expired. Generate new link.',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UserInvited:
        return undefined;
      case ErrorInnerCodes.UserConfirmEmailError:
        return {
          message: t({ id: 'inner_code_error.user_confirm_email_error', message: 'Confirm user e-mail error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UserAddPasswordError:
        return {
          message: t({ id: 'inner_code_error.user_add_password_error', message: 'Error creating user password' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.InvitationInvalidAdminEmail:
        return {
          message: t({
            id: 'inner_code_error.invitation_invalid_admin_email',
            message: 'Sorry, but there is no organization registered at this address',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UserEmailTaken:
        return {
          message: t({ id: 'inner_code_error.user_email_taken', message: 'E-mail is already taken' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.DeleteUserError:
        return {
          message: t({ id: 'inner_code_error.delete_user_error', message: 'Delete user error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.ChangeEmailError:
        return {
          message: t({ id: 'inner_code_error.change_email_error', message: 'Change e-mail error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UserInvitationError:
        return {
          message: t({ id: 'inner_code_error.user_invitation_error', message: 'User invitation error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.InvalidPassword:
        return {
          message: t({ id: 'inner_code_error.invalid_password', message: 'Invalid password' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.ExternalLoginNotLinked:
        return {
          message: t({ id: 'inner_code_error.external_login_not_linked', message: 'No linked account' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.ExternalLoginAccountLinkError:
        return {
          message: t({ id: 'inner_code_error.external_login_account_link_error', message: 'Account link error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UserChangePasswordError:
        return {
          message: t({ id: 'inner_code_error.user_change_password_error', message: 'Password change error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.ExternalLoginDisconnectError:
        return {
          message: t({ id: 'inner_code_error.external_login_disconnect_error', message: 'Account disconnect error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UserSetPasswordError:
        return {
          message: t({ id: 'inner_code_error.user_set_password_error', message: 'Set password error' }),
          variant: 'danger',
        };

      // Payment
      case ErrorInnerCodes.PaymentNotFound:
        return {
          message: t({ id: 'inner_code_error.payment_not_found', message: 'Payment not found' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.CreatePaymentIntentError:
        return {
          message: t({ id: 'inner_code_error.create_payment_intent_error', message: 'Payment error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.PaymentIntentRequiresConfirm:
        return {
          message: t({
            id: 'inner_code_error.payment_intent_requires_confirm',
            message: 'Payment intent requires confirmation',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.GetCustomerPaymentMethodError:
        return {
          message: t({ id: 'inner_code_error.get_customer_payment_method_error', message: 'Get payment method error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.ChargeCardError:
        return {
          message: t({ id: 'inner_code_error.charge_card_error', message: 'Charge card error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.PayUPaymentCanceled:
        return {
          message: t({ id: 'inner_code_error.payu_payment_canceled', message: 'Payment canceled' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.PayUPaymentRejected:
        return {
          message: t({ id: 'inner_code_error.payu_payment_rejected', message: 'Payment rejected' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.PayUPaymentError:
        return {
          message: t({ id: 'inner_code_error.payu_payment_error', message: 'An error has occurred during payment' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.PayUCancelError:
        return {
          message: t({ id: 'inner_code_error.payu_cancel_error', message: 'Payment cancellation error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.TaxChanged:
        return {
          message: t({ id: 'inner_code_error.tax_changed', message: 'VAT rate has changed' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.CouponNotFound:
        return {
          message: t({ id: 'inner_code_error.coupon_not_found', message: 'Coupon has not been found' }),
          variant: 'warning',
        };
      case ErrorInnerCodes.InvalidCoupon:
        return {
          message: t({ id: 'inner_code_error.invalid_coupon', message: 'Invalid coupon' }),
          variant: 'warning',
        };
      case ErrorInnerCodes.RenewWireTransferError:
        return {
          message: t({ id: 'inner_code_error.renew_wire_transfer_error', message: 'Renew payment error' }),
          variant: 'warning',
        };
      case ErrorInnerCodes.PaymentMethodNotSelected:
        return {
          message: t({
            id: 'inner_code_error.payment_method_not_selected',
            message: 'Payment method has not been selected',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.NoModuleWasSelected:
        return {
          message: t({ id: 'inner_code_error.no_module_was_selected', message: 'No module has been seleted' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.PaymentIntentRequiresConfirmError:
        return {
          message: t({
            id: 'inner_code_error.payment_intent_requires_confirm_error',
            message: 'Error during payment confirmation',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.ProformaPaymentIsUnavailable:
        return {
          message: t({
            id: 'inner_code_error.proforma_payment_is_unavailable',
            message: 'Proforma payment is unavailable due to unpaid balance',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.PaymentDowngradeUnavailable:
        return {
          message: t({
            id: 'inner_code_error.payment_downgrade_unavailable',
            message: 'Subscription downgrade is unavailable',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.MinPaymentAmountError:
        if (context?.currency === Currency.USD) {
          return {
            message: t({
              id: 'inner_code_error.payment_low_amount.usd',
              message: 'Payment amount is too low, minimal amount is $1.',
            }),
            variant: 'danger',
          };
        }
        return {
          message: t({
            id: 'inner_code_error.payment_low_amount.pln',
            message: 'Payment amount is too low, minimal amount is 2zł.',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.CardInsufficientFunds:
        return {
          message: t({ id: 'inner_code_error.card_insufficient_funds', message: 'Insufficient funds in the card' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.StripeSetupFailed:
        return {
          message: t({ id: 'inner_code_error.stripe_setup_failed', message: 'Card setup failed' }),
          variant: 'danger',
        };

      // Organization
      case ErrorInnerCodes.MissingCountryCode:
        return {
          message: t({ id: 'inner_code_error.missing_country_code', message: 'Missing country selection' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.MissingVatId:
        return {
          message: t({ id: 'inner_code_error.missing_vat_id', message: 'Missing VAT ID' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.VatValidationError:
        return {
          message: t({ id: 'inner_code_error.vat_validation_error', message: 'VAT ID validation error' }),
          variant: 'danger',
        };

      // Person
      case ErrorInnerCodes.CreatePersonError:
        return {
          message: t({ id: 'inner_code_error.create_person_error', message: 'Employee creation error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.EditPersonError:
        return {
          message: t({ id: 'inner_code_error.edit_person_error', message: 'Employee edition error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.DeletePersonError:
        return {
          message: t({ id: 'inner_code_error.delete_person_error', message: 'Employee removal error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.NoPeopleToModify:
        return {
          message: t({ id: 'inner_code_error.no_people_to_modify', message: 'No employees to modify' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.EmptyPeopleIds:
        return {
          message: t({ id: 'inner_code_error.empty_people_ids', message: 'Empty employees list' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.ImportPeopleError:
        return {
          message: t({ id: 'inner_code_error.import_people_error', message: 'Employees import error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.SetRatesError:
        return {
          message: t({ id: 'inner_code_error.set_rates_error', message: 'Set employees rates error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.SetTimeOffLimitsError:
        return {
          message: t({
            id: 'inner_code_error.set_time_off_limits_error',
            message: 'Set employees time off limits error',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.SetRequestsLimitsError:
        return {
          message: t({
            id: 'inner_code_error.set_requests_limits_error',
            message: 'Set employees custom requests limits error',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.DuplicateWorkPosition:
        return {
          message: t({ id: 'inner_code_error.duplicate_work_position', message: 'Work position duplicate' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.AddEmployeesExceedsPaid:
        return {
          message: t({
            id: 'inner_code_error.add_employees_exceeds_paid',
            message: "You can't add more teammates, you need to upgrade your plan first!",
          }),
          variant: 'danger',
        };

      // Schedule
      case ErrorInnerCodes.ScheduleOverlap:
        return {
          message: t({ id: 'inner_code_error.schedule_overlap', message: 'Schedules overlap' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.AutomaticBreakExceededScheduleDuration:
        return {
          message: t({
            id: 'inner_code_error.automatic_break_exceeded_scheduled_duration',
            message: 'Automatic break must be shorter than a schedule.',
          }),
          variant: 'danger',
        };

      // Permissions
      case ErrorInnerCodes.UpdateRoleError:
        return {
          message: t({ id: 'inner_code_error.update_role_error', message: 'Role edit error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UpdateUserPermissionsError:
        return {
          message: t({ id: 'inner_code_error.update_user_permissions_error', message: 'User permissions edit error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.UpdateDepartmentPermissionsError:
        return {
          message: t({
            id: 'inner_code_error.update_department_permissions_error',
            message: 'Tag permissions edit error',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.DeleteRoleError:
        return {
          message: t({ id: 'inner_code_error.delete_role_error', message: 'Role delete error' }),
          variant: 'danger',
        };

      // TimeOffs
      case ErrorInnerCodes.TimeOffExistsInSpecificRange:
        return {
          message: t({
            id: 'inner_code_error.time_off_exists_in_specific_range',
            message: 'There is already a time off in this range',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.TimeOffCorrelatedDateForSameDay:
        return {
          message: t({
            id: 'inner_code_error.time_off_correlated_date_for_same_day',
            message: 'The correlated time off date is for the same day',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.TimeOffNoOvertimeToChoose:
        return {
          message: t({ id: 'inner_code_error.time_off_no_overtime_to_choose', message: 'No overtime to choose' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.TimeOffOvertimeLimitExceeded:
        return {
          message: t({
            id: 'inner_code_error.time_off_overtime_limit_exceeded',
            message: 'Available overtime limit has been exceeded',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.TimeOffOvertimeDayOffExceedsLimit:
        return {
          message: t({
            id: 'inner_code_error.time_off_overtime_day_off_exceeds_limit',
            message: "Timesheet doesn't determine maximum duration of this holiday",
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.TimeOffHasOvertimeScheduleInRange:
        return {
          message: t({
            id: 'inner_code_error.time_off_has_overtime_schedule_in_range',
            message: 'You cannot add time off for day with overtime schedule',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.TimeOffOvertimeNoSchedule:
        return {
          message: t({
            id: 'inner_code_error.time_off_overtime_no_schedule',
            message: 'No schedule',
          }),
          variant: 'danger',
        };

      // File
      case ErrorInnerCodes.NotAllowedExtension:
        return {
          message: t({ id: 'inner_code_error.not_allowed_extension', message: 'Invalid extension' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.InvalidFile:
        return {
          message: t({ id: 'inner_code_error.invalid_file', message: 'Invalid file' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.MaxFileSizeExceeded:
        return {
          message: t({ id: 'inner_code_error.max_file_size_exceeded', message: 'File size exceeded' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.FileUploadError:
        return {
          message: t({ id: 'inner_code_error.file_upload_error', message: 'File upload error' }),
          variant: 'danger',
        };

      // BusinessTravel
      case ErrorInnerCodes.HasBusinessTravelInRange:
        return {
          message: t({
            id: 'inner_code_error.business_in_range',
            message: 'Business travel exists in that range',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.BusinessTravelNotFound:
        return {
          message: t({ id: 'inner_code_error.business_travel_not_found', message: 'Business travel was not found' }),
          variant: 'danger',
        };

      // Request
      case ErrorInnerCodes.IncorrectRequestType:
        return {
          message: t({ id: 'inner_code_error.incorrect_request_type', message: 'Invalid request type' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.IncorrectRequestState:
        return {
          message: t({ id: 'inner_code_error.incorrect_request_state', message: 'Invalid request state' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.RequestIsDeleted:
        return {
          message: t({ id: 'inner_code_error.request_is_deleted', message: 'Request has been deleted' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.RequestNotFound:
        return {
          message: t({ id: 'inner_code_error.request_not_found', message: 'No request found' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.RequestAlreadyProcessed:
        return {
          message: t({ id: 'inner_code_error.request_already_processed', message: 'Request already processed' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.IncompleteRequest:
        return {
          message: t({ id: 'inner_code_error.incomplete_request', message: 'Incomplete request' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.RequestDateRangeError:
        return {
          message: t({ id: 'inner_code_error.request_date_range_error', message: 'Request dates range error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.AddRequestError:
        return {
          message: t({ id: 'inner_code_error.add_request_error', message: 'Cannot add request' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.EditRequestError:
        return undefined;
      case ErrorInnerCodes.RequestLimitsExceeded:
        return {
          message: t({ id: 'inner_code_error.request_limits_exceeded', message: 'Limit has been exceeded' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.RequestForZeroDays:
        return {
          message: t({ id: 'inner_code_error.request_for_zero_days', message: 'Request has been selected for 0 days' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.RequestDaysLimitExceeded:
        return {
          message: t({
            id: 'inner_code_error.request_days_limit_exceeded',
            message: 'Time off range needs to be between 1 and 500 days',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.RequestAtLeastOneMinuteDurationError:
        return {
          message: t({
            id: 'inner_code_error.request_at_least_one_minute_duration_error',
            message: 'The minimum required duration is 1 minute',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.RequestAlreadyAdded:
        return {
          message: t({
            id: 'inner_code_error.request_already_added',
            message: 'Action cannot be executed, there is already a pending request',
          }),
          variant: 'warning',
        };
      case ErrorInnerCodes.RequestExceededMaxMinuteDuration:
        return {
          message: t({
            id: 'inner_code_error.request_exceeded_max_minute_duration',
            message: 'Maximum allowed duration of request exceeded',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.SwapRequestNoPublishSchedules:
        return {
          message: t({
            id: 'inner_code_error.swap_request_no_publish_schedules',
            message: 'No published schedules. Swap not possible.',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.SwapRequestIsUserActiveError:
        return {
          message: t({
            id: 'inner_code_error.swap_request_is_user_active_error',
            message: 'One of the employees is inactive.',
          }),
          variant: 'danger',
        };

      // Tags
      case ErrorInnerCodes.EditTagError:
        return {
          message: t({ id: 'inner_code_error.edit_tag_error', message: 'Edit tag error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.DeleteTagError:
        return {
          message: t({ id: 'inner_code_error.delete_tag_error', message: 'Delete tag error' }),
          variant: 'danger',
        };

      // Integrations
      case ErrorInnerCodes.NoDataToImport:
        return {
          message: t({ id: 'inner_code_error.no_data_to_import', message: 'No data to import' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.ImportError:
        return {
          message: t({ id: 'inner_code_error.import_error', message: 'Import error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.IntegrationConnectionError:
        return {
          message: t({ id: 'inner_code_error.integration_connection_error', message: 'Integration connection error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.IntegrationUpdateError:
        return {
          message: t({ id: 'inner_code_error.integration_update_error', message: 'Update integration error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.IntegrationDisconnectError:
        return {
          message: t({ id: 'inner_code_error.integration_disconnect_error', message: 'Integration disconnect error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.WebhookDuplicate:
        return {
          message: t({ id: 'inner_code_error.webhook_duplicate', message: 'Webhook duplicate' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.InvalidAuthorization:
        return {
          message: t({ id: 'inner_code_error.invalid_authorization', message: 'Invalid authorization' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.IntegrationNotFound:
        return {
          message: t({ id: 'inner_code_error.integration_not_found', message: 'Integration not found' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.IncorrectOrganizationVersion:
        return {
          message: t({
            id: 'inner_code_error.incorrect_organization_version',
            message: 'Incorrect organization version',
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.DisconnectError:
        return {
          message: t({ id: 'inner_code_error.disconnect_error', message: 'Disconnect error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.GetDataError:
        return {
          message: t({ id: 'inner_code_error.get_data_error', message: 'Get data error' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.ExportTimeActivityError:
        return {
          message: t({ id: 'inner_code_error.export_time_activity_error', message: 'Export time activity error' }),
          variant: 'danger',
        };

      // Ezla
      case ErrorInnerCodes.EzlaWrongLoginData:
        return {
          message: t({ id: 'inner_code_error.ezla_wrong_login_data', message: 'Invalid credentials' }),
          variant: 'danger',
        };
      case ErrorInnerCodes.EzlaError:
        return {
          message: t({ id: 'inner_code_error.ezla_error', message: 'ZUS e-ZLA error' }),
          variant: 'danger',
        };

      // TimeEventType
      case ErrorInnerCodes.TimeEventTypeNotExists:
        return {
          message: t({ id: 'inner_code_error.time_event_type_not_exists', message: "Work status doesn't exist" }),
          variant: 'danger',
        };

      // Workday Availability
      case ErrorInnerCodes.WorkdayAvailabilityMaxForDay:
        return {
          message: t({
            id: 'inner_code_error.availability_max_for_day',
            message: "You can't add more than 3 availabilities for a day",
          }),
          variant: 'danger',
        };
      case ErrorInnerCodes.WorkdayAvailabilityIsOverlapped:
        return {
          message: t({ id: 'inner_code_error.availability_overlapped', message: "Times can't overlap" }),
          variant: 'danger',
        };
      case ErrorInnerCodes.ConsentRequiredError:
        return {
          message: t({ id: 'inner_code_error.consent_required', message: 'Consent required' }),
          variant: 'danger',
        };
      default:
        return undefined;
    }
  })();

  return snackbarProps;
};

const secondaryErrorMessageReducer = (
  secondaryInnerCode: SecondaryInnerCodes,
  fieldName: string,
  value?: string,
): string | null => {
  switch (secondaryInnerCode) {
    case SecondaryInnerCodes.Required: {
      return t({
        id: 'secondary_inner_code.required:',
        message: `Field "${fieldName}": value is required.`,
      });
    }
    case SecondaryInnerCodes.WrongUrl: {
      return t({
        id: 'secondary_inner_code.wrong_url',
        message: `Field "${fieldName}": "${value}" is not a valid URL.`,
      });
    }
    case SecondaryInnerCodes.WrongEmail: {
      return t({
        id: 'secondary_inner_code.wrong_email',
        message: `Field "${fieldName}": "${value}" is not a valid email.`,
      });
    }
    case SecondaryInnerCodes.WrongPhoneNumber: {
      return t({
        id: 'secondary_inner_code.wrong_phone_number',
        message: `Field "${fieldName}": "${value}" is not a valid phone number.`,
      });
    }
    case SecondaryInnerCodes.MaxValueExceeded: {
      return t({
        id: 'secondary_inner_code.max_value_exceeded',
        message: `Field "${fieldName}": "${value}" exceeds maximum allowed value.`,
      });
    }
    case SecondaryInnerCodes.NotEqualExpected: {
      return t({
        id: 'secondary_inner_code.not_equal_expected',
        message: `Field "${fieldName}": expected value that's not equal to current value.`,
      });
    }
    case SecondaryInnerCodes.GreaterThanExpected: {
      return t({
        id: 'secondary_inner_code.greater_than_expected',
        message: `Field "${fieldName}": expected value greater than "${value}" .`,
      });
    }
    case SecondaryInnerCodes.InvalidPassword: {
      return t({
        id: 'secondary_inner_code.invalid_password',
        message: `Field "${fieldName}": "${value}" is not a valid password.`,
      });
    }
    case SecondaryInnerCodes.LetterOnly: {
      return t({
        id: 'secondary_inner_code.letter_only',
        message: `Field "${fieldName}": "${value}" must contain only letters.`,
      });
    }
    case SecondaryInnerCodes.SimpleSpecialCharactersOnly: {
      return t({
        id: 'secondary_inner_code.simple_special_charaters_only',
        message: `Field "${fieldName}": "${value}" must not contain disallowed characters. `,
      });
    }
    case SecondaryInnerCodes.NoSpecialCharacters: {
      return t({
        id: 'secondary_inner_code.no_special_characters',
        message: `Field "${fieldName}": "${value}" must not include any special characters.`,
      });
    }
    default:
      return null;
  }
};

const getDefaultErrorMessage = (customDefaultErrorMessage: DefaultErrorMessageEnum) => {
  const errorMessageMap = new Map<DefaultErrorMessageEnum, SnackbarProps | undefined>([
    [
      DefaultErrorMessageEnum.Default,
      {
        message: t({ id: 'error.something_went_wrong' }),
        variant: 'danger',
      },
    ],
    [
      DefaultErrorMessageEnum.UnexpectedErrorContactSupport,
      {
        message: t({
          id: 'global.error.unknown',
          message: 'Unexpected error, please contact our support team.',
        }),
        variant: 'danger',
      },
    ],
    [
      DefaultErrorMessageEnum.SignIn,
      {
        variant: 'danger',
        message: t({
          id: 'sign_in.form.form_error',
          message: 'Invalid username and/or password 😥',
        }),
      },
    ],
    [
      DefaultErrorMessageEnum.DisconnectIntegration,
      {
        variant: 'danger',
        message: t({ id: 'settings.integrations.disconnect_error', message: "Couldn't disconnect integration" }),
      },
    ],
    [
      DefaultErrorMessageEnum.DownloadFile,
      {
        message: t({ id: 'payment.download_file.error', message: "Couldn't download file" }),
        variant: 'danger',
        placement: 'bottom',
      },
    ],
    [
      DefaultErrorMessageEnum.PaymentProcess,
      {
        variant: 'danger',
        message: t({ id: 'payments.process.timeout', message: 'Error occured during processing payment' }),
        placement: 'bottom',
      },
    ],
    [
      DefaultErrorMessageEnum.UpdatePaymentMethod,
      {
        message: t({ id: 'payments.payment_method.submit_error', message: "Couldn't update payment method" }),
        variant: 'danger',
        placement: 'bottom',
      },
    ],
    [
      DefaultErrorMessageEnum.RemovePaymentMethod,
      {
        message: t({ id: 'payments.remove_payment_method.error', message: "Couldn't remove payment method" }),
        variant: 'danger',
        placement: 'bottom',
      },
    ],
    [
      DefaultErrorMessageEnum.PaymentActivation,
      {
        message: t({
          id: 'payments.stripe.activation_error',
          message: 'Error occurred during payment activation!',
        }),
        variant: 'danger',
        placement: 'bottom',
      },
    ],
    [
      DefaultErrorMessageEnum.CancelPayment,
      {
        message: t({ id: 'payments.pending_payment.fail', message: "Couldn't cancel pending payment" }),
        variant: 'danger',
        placement: 'bottom',
      },
    ],
    [
      DefaultErrorMessageEnum.CancelSubscription,
      {
        message: t({ id: 'payments.cancel_subscription.fail', message: "Couldn't cancel subscription" }),
        variant: 'warning',
        placement: 'bottom',
      },
    ],
    [
      DefaultErrorMessageEnum.FormSubmit,
      {
        message: t({
          id: 'settings.forms.submit_fail',
          message: "Couldn't save changes",
        }),
        variant: 'danger',
      },
    ],
    [
      DefaultErrorMessageEnum.BillingDetails,
      {
        message: t({ id: 'payments.billing_details.error', message: "Couldn't add billing details" }),
        variant: 'danger',
        placement: 'bottom',
      },
    ],
    [DefaultErrorMessageEnum.None, undefined],
  ]);

  return errorMessageMap.get(customDefaultErrorMessage);
};
