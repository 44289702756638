import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { useFieldArray, useForm } from 'react-hook-form';
import { Navigate, RouteState, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { addWorkdayAvailabilityAction } from 'api/actions/calendar/calendarActions';
import { AddWorkdayAvailabilityActionProps } from 'api/actions/calendar/calendarActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { ModalContextProps } from 'components/Modal/output/types';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { ShowFieldsButton } from 'components/recipes/ShowFieldsButton';
import { Button } from 'components/ui/Buttons/Button';
import { RadioButton } from 'components/ui/RadioButton/RadioButton';
import { RadioButtonGroup } from 'components/ui/RadioButton/RadioButtonGroup';
import { TimePicker } from 'components/ui/TimePicker/TimePicker';
import { isWorkdayAvailabilityAvailableSelector } from 'state/organizationSession';
import { createEvent } from 'utils/createEvent';
import { dateTime } from 'utils/dateTime';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useRefreshCalendar } from '../../hooks/useRefreshCalendar';

type Props = Pick<ModalContextProps, 'handleClose'> & Pick<RouteState, 'id' | 'dateUnix' | 'availability'>;

type AddWorkdayAvailabilityFormProps = Omit<AddWorkdayAvailabilityActionProps, 'dates'> & {
  dates: { startUnix: number | null; endUnix: number | null }[];
};

export const SetAvailabilityInnerModal: FC<Props> = ({ id: userId, dateUnix, availability, handleClose }) => {
  useLingui();
  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar([userId || '']);

  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = useCallback(() => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  }, []);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AddWorkdayAvailabilityFormProps>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      forDay: dateUnix,
      personId: userId,
      available: availability?.available,
      dates: availability?.timeRange,
    },
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'dates' });

  const availableWatch = watch('available');

  const { mutate, loading } = useMutation(addWorkdayAvailabilityAction);

  const handleSave = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const handleTimesBinding = useCallback(
    (
      dates: {
        startUnix: number | null;
        endUnix: number | null;
      }[],
    ) => {
      let currentIndex = 0;
      const bindedIndexes: number[] = [];
      const pushedBinedIndexes: number[] = [];

      const bindedDates = _.transform(
        dates,
        (result, date) => {
          const { startUnix, endUnix } = date;
          let bindedStartUnix = startUnix || 0;
          let bindedEndUnix = endUnix || 0;

          dates.forEach((dateToBind, index) => {
            if (index === currentIndex) return;
            if (bindedIndexes.includes(index)) return;
            if (dateToBind.startUnix === null || dateToBind.endUnix === null) return;
            if (bindedStartUnix === null || bindedEndUnix === null) return;

            const isStartTimeCoveredByOtherTimeRange =
              (dateToBind.startUnix <= bindedStartUnix && dateToBind.endUnix >= bindedStartUnix) ||
              (dateToBind.startUnix >= bindedStartUnix && dateToBind.startUnix <= bindedEndUnix);

            const isEndTimeCoveredByOtherTimeRange =
              (dateToBind.endUnix <= bindedEndUnix && dateToBind.startUnix >= bindedEndUnix) ||
              (dateToBind.endUnix >= bindedEndUnix && dateToBind.endUnix <= bindedStartUnix);

            if (isStartTimeCoveredByOtherTimeRange || isEndTimeCoveredByOtherTimeRange) {
              if (bindedStartUnix >= dateToBind.startUnix) {
                bindedStartUnix = dateToBind.startUnix;
              }
              if (bindedEndUnix <= dateToBind.endUnix) {
                bindedEndUnix = dateToBind.endUnix;
              }
              bindedIndexes.push(currentIndex);
              bindedIndexes.push(index);
            }
          });

          if (!pushedBinedIndexes.includes(currentIndex)) {
            result.push({ startUnix: bindedStartUnix, endUnix: bindedEndUnix });
            pushedBinedIndexes.splice(0, pushedBinedIndexes.length, ...bindedIndexes);
          }
          currentIndex += 1;
        },
        [] as {
          startUnix: number;
          endUnix: number;
        }[],
      );

      return bindedDates;
    },
    [],
  );

  const handleSubmitCallback = useCallback(
    async (data: AddWorkdayAvailabilityFormProps) => {
      const actionBody = (() => {
        const { available, dates } = data;

        if (!available) return { ...data, dates: [] };

        return { ...data, dates: handleTimesBinding(dates) };
      })();

      const { error } = await mutate(actionBody);

      if (!error) {
        if (calendarInitialized) void updateCalendarForIds();
        if (actionBody.dates.length !== data.dates.length) {
          addSnackbar({
            variant: 'success',
            message: t({
              id: 'availability.times_got_binded',
              message: 'Covering times got binded',
            }),
          });
        } else {
          addSnackbar({
            variant: 'success',
            message: t({
              id: 'availability.add_success',
              message: 'Your availability is set now for this day',
            }),
          });
        }
        handleClose();
      }
    },
    [calendarInitialized, handleClose, handleTimesBinding, mutate, updateCalendarForIds],
  );

  const addTimeRange = (() => {
    if (!availableWatch) return null;

    if (fields.length === 0)
      return (
        <ShowFieldsButton
          onClick={() => append({ startUnix: null, endUnix: null })}
          label={t({
            id: 'availability.add_time_range',
            message: 'Add time range',
          })}
        />
      );

    return (
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 3,
          }}
        >
          <Trans id="availability.time_range">Time range</Trans>
        </Text>
        {fields.map((field, index) => (
          <Flex key={field.id} sx={{ gap: 2 }}>
            <TimePicker
              {...register(`dates.${index}.startUnix`, {
                required: t({ id: 'global.forms.required.short' }),
              })}
              id={`dates.${index}.startUnix`}
              size="sm"
              error={!!errors.dates?.[index]?.startUnix}
              errorMessage={errors.dates?.[index]?.startUnix?.message}
            />
            <Icon type="arrowRight" size={16} />
            <TimePicker
              {...register(`dates.${index}.endUnix`, {
                required: t({ id: 'global.forms.required.short' }),
              })}
              id={`dates.${index}.endUnix`}
              size="sm"
              error={!!errors.dates?.[index]?.endUnix}
              errorMessage={errors.dates?.[index]?.endUnix?.message}
            />
            <Button
              onClick={() => remove(index)}
              variant="grey"
              size="sm"
              shape="rounded"
              sx={{ alignSelf: 'center' }}
              prependWith={<Icon type="delete" />}
            />
          </Flex>
        ))}
        {fields.length < 3 && (
          <ShowFieldsButton
            onClick={() => append({ startUnix: null, endUnix: null })}
            label={t({
              id: 'availability.add_another_time_range',
              message: 'Add another time range',
            })}
          />
        )}
      </Flex>
    );
  })();

  const date = useMemo(() => dateTime(dateUnix, { utc: true }).startOf('day'), [dateUnix]);

  const renderDate = () => {
    const weekday = date.format('ddd');
    const year = date.format('YYYY');
    const localDate = date.format('ll').split(` ${year}`)[0].split(',')[0];

    return `${weekday} ${localDate}, ${year}`;
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="availability.set_availability">Set availability</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef} onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}>
          <Flex sx={{ flexDirection: 'column', gap: 4 }}>
            <Flex sx={{ flexDirection: 'column' }}>
              <Modal.SubTitle>{t({ id: 'requests.for_day' })}</Modal.SubTitle>
              {renderDate()}
            </Flex>
            <RadioButtonGroup name="availableGroup" orientation="row" columns={2} gap="4px">
              <RadioButton
                label={t({
                  id: 'availability.available',
                  message: 'Available',
                })}
                value={0}
                sx={{
                  'input:checked + &': {
                    background: 'calendar.setAvailability.available.bg',
                    outlineColor: 'calendar.setAvailability.available.outline',
                  },
                }}
                onChange={() => setValue('available', true)}
                checked={availableWatch}
              />
              <RadioButton
                label={t({
                  id: 'availability.unavailable',
                  message: 'Unavailable',
                })}
                value={1}
                sx={{
                  'input:checked + &': {
                    background: 'calendar.setAvailability.unavailable.bg',
                    outlineColor: 'calendar.setAvailability.unavailable.outline',
                  },
                }}
                onChange={() => setValue('available', false)}
                checked={!availableWatch}
              />
            </RadioButtonGroup>
            {addTimeRange}
          </Flex>
        </form>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'save' }),
          },
        ]}
      />
    </>
  );
};

export const SetAvailabilityModal = (): React.ReactElement => {
  useLingui();
  const isWorkdayAvailabilityAvailable = useRecoilValue(isWorkdayAvailabilityAvailableSelector);
  const { handleClose, baseRoute } = useModal({ wrapperSx: { minHeight: '500px' } });
  const { state } = useLocation();
  const { id, dateUnix, availability } = state || {};

  if (!isWorkdayAvailabilityAvailable || !id || !dateUnix || !availability)
    return <Navigate to={baseRoute} relative="path" />;

  return (
    <SetAvailabilityInnerModal id={id} dateUnix={dateUnix} availability={availability} handleClose={handleClose} />
  );
};
