import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useMemo } from 'react';

import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';

export const useIntegrationTileTexts = (integration: ServiceIntegration) => {
  useLingui();

  const integrationsDescription = useMemo<{ [index in ServiceIntegration]: string }>(
    () => ({
      [ServiceIntegration.Quickbooks]: t({
        id: 'settings.integrations.quickbooks',
        message: 'Sync employees and export your timesheets to QuickBooks.',
      }),
      [ServiceIntegration.SquarePayroll]: t({
        id: 'settings.integrations.squarepayroll',
        message: 'Sync employees and streamline timesheet export to Square.',
      }),
      [ServiceIntegration.Optima]: t({
        id: 'settings.integrations.optima',
        message: 'Sync employees, export timesheets, requests and schedules.',
      }),
      [ServiceIntegration.Symfonia]: t({
        id: 'settings.integrations.symfonia',
        message: 'Sync employees, export timesheets, requests and schedules.',
      }),
      [ServiceIntegration.R2Platnik]: t({ id: 'settings.integrations.r2platnik', message: 'Export timesheets.' }),
      [ServiceIntegration.GratyfikantGT]: t({
        id: 'settings.integrations.gratyfikant',
        message: 'Sync employees, export timesheets and requests.',
      }),
      [ServiceIntegration.Xero]: t({
        id: 'settings.integrations.xero',
        message: 'Sync employees and export timesheets with schedules to Xero.',
      }),
      [ServiceIntegration.ZusEzla]: t({ id: 'settings.integrations.zus', message: 'Sync time off requests.' }),
      [ServiceIntegration.Webhook]: t({
        id: 'settings.integrations.webhooks',
        message: 'Access the data on predefined events from our platform.',
      }),
      [ServiceIntegration.Enova]: t({
        id: 'settings.integrations.enova',
        message: 'Sync employees and export your timesheets to Enova365.',
      }),
      [ServiceIntegration.GratyfikantNexo]: t({
        id: 'settings.integrations.gratyfikant',
      }),
    }),
    [],
  );

  const integrationsHeading = useMemo<{ [index in ServiceIntegration]: string }>(
    () => ({
      [ServiceIntegration.Quickbooks]: t({ id: 'settings.integrations.quickbooks_heading', message: 'QuickBooks' }),
      [ServiceIntegration.SquarePayroll]: t({ id: 'settings.integrations.square_heading', message: 'Square' }),
      [ServiceIntegration.Optima]: t({ id: 'settings.integrations.optima_heading', message: 'Comarch Optima' }),
      [ServiceIntegration.Symfonia]: t({ id: 'settings.integrations.symfonia_heading', message: 'Symfonia' }),
      [ServiceIntegration.R2Platnik]: t({ id: 'settings.integrations.r2platnik_heading', message: 'R2 Płatnik' }),
      [ServiceIntegration.GratyfikantGT]: t({
        id: 'settings.integrations.gratyfikant_heading',
        message: 'Gratyfikant GT',
      }),
      [ServiceIntegration.Xero]: t({ id: 'settings.integrations.xero_heading', message: 'Xero' }),
      [ServiceIntegration.ZusEzla]: t({ id: 'settings.integrations.zus_heading', message: 'ZUS e-ZLA' }),
      [ServiceIntegration.Webhook]: t({ id: 'settings.integrations.webhooks_heading', message: 'Webhooks' }),
      [ServiceIntegration.Enova]: t({ id: 'settings.integrations.enova_heading', message: 'Enova365' }),
      [ServiceIntegration.GratyfikantNexo]: t({
        id: 'settings.integrations.gratyfikant_nexo_heading',
        message: 'Gratyfikant Nexo',
      }),
    }),
    [],
  );

  return {
    heading: integrationsHeading[integration],
    description: integrationsDescription[integration],
  };
};
