import { keyframes } from '@emotion/react';
import { Trans } from '@lingui/macro';
import React from 'react';
import { Flex, FlexProps, Text } from 'theme-ui';

type Props = Omit<FlexProps, 'variant'>;

const pulseAnimation = keyframes({
  '0%': {
    opacity: 0.7,
    transform: 'scale(0.98)',
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1.00)',
  },
});

export const FetchingIndicator = ({ sx, ...props }: Props): React.ReactElement => (
  <Flex
    sx={{
      bg: 'main.fetchingIndicator.bg',
      outline: '1px solid',
      outlineColor: 'main.fetchingIndicator.outline',
      px: 1,
      borderRadius: 'xs',
      animation: `${pulseAnimation} 0.5s ease-in-out infinite alternate`,
      ...sx,
    }}
    {...props}
  >
    <Text sx={{ fontSize: 2 }}>
      <Trans id="global.checking_updates">Checking updates...</Trans>
    </Text>
  </Flex>
);
