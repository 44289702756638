import { Trans } from '@lingui/macro';
import React from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Box, Flex, Heading, Text } from 'theme-ui';

import { Modal } from 'components/Modal/output/Modal';
import { Button } from 'components/ui/Buttons';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { TO, TO_REL } from 'constants/routes';
import { languageSelector } from 'state/recoilState';
import { ReactComponent as QRCodeExampleSvg } from '../svg/qrCodeMobileApp.svg';

const FirstStep = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);

  return (
    <>
      <Heading as="h2" variant="heading3">
        <Trans id="kiosk.help_center_modal.step_1.lead.header">Print QR cards for employees</Trans>
      </Heading>
      <Text as="p" variant="pLead">
        <Trans id="kiosk.help_center_modal.step_1.lead.text">
          Go to the{' '}
          <LinkAnchor standOut to={TO.TEAM[language]}>
            Team
          </LinkAnchor>{' '}
          from where you can print QR cards for all your employees.
        </Trans>
      </Text>
    </>
  );
};
const SecondStep = (): React.ReactElement => (
  <>
    <Heading as="h2" variant="heading3">
      <Trans id="kiosk.home.help_center_modal.step_2.lead.header">Alternatively, use our free mobile app</Trans>
    </Heading>
    <Text as="p" variant="pLead">
      <Trans id="kiosk.home.help_center_modal.step_2.lead.text_1">
        Where all employees have access to their unique QR code and much more.
      </Trans>
    </Text>
    <Text as="p" variant="pLead">
      <Trans id="kiosk.home.help_center_modal.step_2.lead.text_2">Available for iOS and Android.</Trans>
    </Text>
    <Flex sx={{ alignItems: 'center' }}>
      <QRCodeExampleSvg />
      <Text as="p" variant="pLead" sx={{ m: 0, p: 0, pl: 4 }}>
        <Trans id="kiosk.home.help_center_modal.step_2.scan_qr">Scan the QR code on your phone.</Trans>
      </Text>
    </Flex>
  </>
);
const ThirdStep = (): React.ReactElement => (
  <>
    <Heading as="h2" variant="heading3">
      <Trans id="kiosk.home.help_center_modal.step_3.lead.header">Keep these in mind during the identification</Trans>
    </Heading>
    <Text as="ol" variant="pLead">
      <Text as="li">
        <Trans id="kiosk.home.help_center_modal.step_3.lead.text_1">Do not cover the QR code with your fingers.</Trans>
      </Text>
      <Text as="li">
        <Trans id="kiosk.home.help_center_modal.step_3.lead.text_2">Do not cover your face with the QR code.</Trans>
      </Text>
      <Text as="li">
        <Trans id="kiosk.home.help_center_modal.step_3.lead.text_3">
          The optimal distance of the QR code from the device is about 30-40 cm.
        </Trans>
      </Text>
    </Text>
  </>
);
const FourthStep = (): React.ReactElement => (
  <>
    <Heading as="h2" variant="heading3">
      <Trans id="kiosk.help_center_modal.step_4.header">Night mode</Trans>
    </Heading>
    <Text as="p" variant="pLead" mt={3}>
      <Trans id="kiosk.help_center_modal.step_4.text">
        The application can switch to night mode, i.e. a part of the screen will emit a white glow to facilitate
        identification. The perfect solution for poorly lit rooms or after dark.
      </Trans>
    </Text>
  </>
);
const ModalStepLink = ({ to, isActive }: { to: string; isActive: boolean }): React.ReactElement => (
  <Link to={to}>
    <Box
      sx={{
        display: 'inline-block',
        transition: 'all ease-in-out 0.2s',
        width: '12px',
        height: '12px',
        borderRadius: 'circle',
        border: '1px solid',
        borderColor: 'kiosk.help.border',
        bg: isActive ? 'kiosk.help.bg.active' : 'kiosk.help.bg.default',
        opacity: isActive ? '1' : '0.6',
        m: 2,
      }}
    />
  </Link>
);

export const HelpCenterModal = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const params = useParams() as { step: string };
  const step = (() => {
    switch (params.step) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
        return +params.step;
      default:
        return 1;
    }
  })();

  const getCurrentStep = () => {
    switch (params.step) {
      case '1':
        return <FirstStep />;
      case '2':
        return <SecondStep />;
      case '3':
        return <ThirdStep />;
      case '4':
        return <FourthStep />;
      default:
        return <Navigate to={`../${TO_REL.KIOSK_HELP_CENTER_MODAL[language]}/1`} />;
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="kiosk.home.help_center_modal.header">Help Center</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body sx={{ flexDirection: 'column', justifyContent: 'space-between' }}>
        <Flex sx={{ flexDirection: 'column' }}>{getCurrentStep()}</Flex>

        <Flex sx={{ justifyContent: 'center' }}>
          {[...new Array(4)].map((linkStep, index) => (
            <ModalStepLink
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}LinkStep`}
              to={`../${TO_REL.KIOSK_HELP_CENTER_MODAL[language]}/${index + 1}`}
              isActive={index + 1 === step}
            />
          ))}
        </Flex>
      </Modal.Body>
      <Modal.Footer sx={{ justifyContent: 'space-between' }}>
        {step === 1 && (
          <Link to={TO.KIOSK[language]}>
            <Button variant="minimal" size="lg">
              <Trans id="kiosk.home.help_center_modal.button_end">Close</Trans>
            </Button>
          </Link>
        )}
        {step > 1 && (
          <Link to={`../${TO_REL.KIOSK_HELP_CENTER_MODAL[language]}/${step - 1}`}>
            <Button variant="minimal" size="lg">
              <Trans id="kiosk.home.help_center_modal.button_back">Back</Trans>
            </Button>
          </Link>
        )}

        {step < 4 && (
          <Link to={`../${TO_REL.KIOSK_HELP_CENTER_MODAL[language]}/${step + 1}`}>
            <Button variant="primary" size="lg">
              <Trans id="kiosk.home.help_center_modal.button_next">Next</Trans>
            </Button>
          </Link>
        )}
        {step === 4 && (
          <Link to={TO.KIOSK[language]}>
            <Button variant="primary" size="lg">
              <Trans id="kiosk.home.help_center_modal.button_end">Close</Trans>
            </Button>
          </Link>
        )}
      </Modal.Footer>
    </>
  );
};
