import _ from 'lodash';
import { useCallback, useEffect, useMemo, useTransition } from 'react';
import { useRecoilState_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';

import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { DateRangeFilterAtom } from 'state/filters';
import { yourCalendarActiveDateAtom, yourCalendarDateRangeAtom, yourCalendarVisibleDatesAtom } from 'state/home';
import { dateTime } from 'utils/dateTime';

export const useCalendarDatePicker = () => {
  const [dates, insideSetDates] = useRecoilState_TRANSITION_SUPPORT_UNSTABLE(yourCalendarDateRangeAtom);
  const [visibleDates, setVisibleDates] = useRecoilState_TRANSITION_SUPPORT_UNSTABLE(yourCalendarVisibleDatesAtom);
  const [activeDate, setActiveDate] = useRecoilState_TRANSITION_SUPPORT_UNSTABLE(yourCalendarActiveDateAtom);

  const prevActiveDate = usePrevious(activeDate);

  const [inTransition, startTransition] = useTransition();

  const todayDateUnix = useMemo(() => dateTime(undefined).startOf('day').utc(true).unix(), []);

  const setDates = useCallback(
    (newState: DateRangeFilterAtom) => {
      if (!_.isEqual(dates, newState)) {
        startTransition(() => {
          insideSetDates(newState);
        });
      }
    },
    [dates, insideSetDates],
  );

  const setDatesMonth = useCallback(
    (date: number) => {
      const newStartDate = dateTime(date, { utc: true }).startOf('month').startOf('day').unix();
      const newEndDate = dateTime(newStartDate, { utc: true }).endOf('month').startOf('day').unix();

      setDates({ startDateUnix: newStartDate, endDateUnix: newEndDate });
    },
    [setDates],
  );

  useEffect(() => {
    if (!activeDate) {
      setActiveDate(todayDateUnix);
    }
  }, [activeDate, setActiveDate, todayDateUnix]);

  useEffect(() => {
    if (!dates || (activeDate && (activeDate > dates.endDateUnix || activeDate < dates.startDateUnix))) {
      if (activeDate) {
        setDatesMonth(activeDate);
      }
    }
  }, [activeDate, dates, setDatesMonth]);

  const setVisibleDatesMonth = useCallback(
    (dateUnix: number) => {
      const newStartDate = dateTime(dateUnix, { utc: true }).add(-2, 'day').startOf('day').unix();
      const newEndDate = dateTime(dateUnix, { utc: true }).add(+2, 'day').startOf('day').unix();

      setVisibleDates({ startDateUnix: newStartDate, endDateUnix: newEndDate });
    },
    [setVisibleDates],
  );

  useEffect(() => {
    if (!visibleDates && activeDate) {
      setVisibleDatesMonth(activeDate);
    }
  }, [activeDate, setVisibleDatesMonth, visibleDates]);

  useEffect(() => {
    if (
      activeDate &&
      visibleDates &&
      activeDate !== prevActiveDate &&
      (activeDate > visibleDates.endDateUnix || activeDate < visibleDates.startDateUnix)
    ) {
      setVisibleDatesMonth(activeDate);
    }
  }, [activeDate, prevActiveDate, setVisibleDatesMonth, visibleDates]);

  return {
    activeDate,
    setActiveDate,
    visibleDates,
    setVisibleDates,
    dates,
    setDates,
    inTransition,
    setVisibleDatesMonth,
  };
};
