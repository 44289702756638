import { useContext } from 'react';

import { AuthDispatchContext } from '../../context/auth/authContext/AuthContext';
import { AuthDispatchContextProps } from '../../context/auth/authContext/AuthContext.types';

/**
 * Retrieves the dispatch method from AuthDispatchContext.
 *
 * @return {AuthDispatchContextProps} Returns the dispatch method with which actions can be dispatched to change the state of AuthStateContext.
 *
 * @see AuthStateContextProps
 * @see authReducer
 * @see authActionCreators.ts
 * @see AuthContextController
 */

export const useAuthDispatch: () => AuthDispatchContextProps = () => {
  const context = useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within an AuthContextController');
  }

  return context;
};
