import React from 'react';
import { FlexOwnProps, ThemeUIStyleObject } from 'theme-ui';

type Props = {
  condition: boolean;
  wrapper: React.FC<{
    children?: React.ReactElement<FlexOwnProps>[] | React.ReactElement<FlexOwnProps> | React.ReactNode;
    sx?: ThemeUIStyleObject;
  }>;
  children: React.ReactElement<FlexOwnProps>[] | React.ReactElement<FlexOwnProps> | React.ReactNode;
  sx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  sx: undefined,
};

export const ConditionalWrapper = ({ condition, wrapper, children, sx }: Props): React.ReactElement => {
  if (condition) {
    return <>{wrapper({ children, sx })}</>;
  }

  if (sx) {
    return (
      <>
        {React.Children.map(
          children,
          (child) =>
            React.isValidElement<FlexOwnProps>(child) &&
            React.cloneElement(child, {
              sx: { ...child.props.sx, ...sx },
            }),
        )}
      </>
    );
  }

  return <>{children}</>;
};

ConditionalWrapper.defaultProps = defaultProps;
