/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/require-await */
import { RequestInterceptor } from 'react-fetching-library';

import { authStorage } from 'context/auth/authStorage/AuthStorage';
import { Action } from '../types';

export const requestAuthInterceptor: () => RequestInterceptor = () => () => async (action: Action<unknown>) => {
  if (action.config && action.config.skipAuthorization) {
    return action;
  }
  const { accessToken } = authStorage;

  if (!accessToken) {
    return action;
  }

  return {
    ...action,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...action.headers,
    },
  };
};
