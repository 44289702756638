import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';

import { disconnectExternalProviderAction } from 'api/actions/integrations/integrationActions';
import { ExternalLoginActionSource } from 'api/actions/integrations/integrationActions.types';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { userSessionAtom } from 'state/userSession';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useExternalIntegrations } from '../../../hooks/useExternalIntegrations';
import { IntegrationTile } from '../../IntegrationTile/IntegrationTile';
import { ExternalIntegrationProps } from '../../IntegrationTile/types';
import { getExternalIntegrationTileTexts } from '../utils/getExternalIntegrationTileTexts';

type Props = ExternalIntegrationProps;

export const CommonExternalProviderTile = ({ integration }: Props): React.ReactNode => {
  useLingui();
  const userSession = useRecoilValue(userSessionAtom);
  const { provider, isActive, imageUrl } = integration;
  const integrationTexts = getExternalIntegrationTileTexts(integration);
  const { mutate: mutateDisconnect } = useMutation(disconnectExternalProviderAction);
  const { fetchExternalIntegrations, connectExternalProvider } = useExternalIntegrations();
  const { addSnackbar } = useSnackbar();

  const disconnectOnClick = useCallback(async () => {
    if (!userSession?.hasPassword) {
      addSnackbar({
        message: t({
          id: 'settings.integrations.set_password_to_unlink',
          message: 'To unlink your account, please set a password to your account first.',
        }),
      });
      return;
    }

    const { error: disconnectError } = await mutateDisconnect({ provider });

    if (!disconnectError) {
      await fetchExternalIntegrations(ExternalLoginActionSource.Link);
    }
  }, [addSnackbar, fetchExternalIntegrations, mutateDisconnect, provider, userSession?.hasPassword]);

  const handleConnect = useCallback(() => {
    void connectExternalProvider(integration);
  }, [connectExternalProvider, integration]);

  if (!integrationTexts) return null;

  return (
    <IntegrationTile isActive={isActive}>
      <IntegrationTile.Tags isActive={isActive} />
      <IntegrationTile.Body image={imageUrl} {...integrationTexts} />
      <IntegrationTile.Buttons>
        <IntegrationTile.Buttons.Connect isActive={isActive} textVariant="connect" onClick={handleConnect} />
        <IntegrationTile.Buttons.Disconnect
          isActive={isActive}
          textVariant="disconnect"
          onClick={floatingPromiseReturn(disconnectOnClick)}
        />
      </IntegrationTile.Buttons>
    </IntegrationTile>
  );
};
