import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';

import { AcceptationCheckbox } from './components/AcceptationCheckbox';
import { Attachments } from './components/Attachments';
import { InnerContainer } from './components/InnerContainer';
import { Note } from './components/Note';
import { ProcessedSelects } from './components/ProcessedSelects';
import { Reason } from './components/Reason';
import { SelectType } from './components/SelectType';

type Props = FlexProps;

type AddRequestContainerProps = Props;

type AddRequestContainerLayout = React.FC<AddRequestContainerProps> & {
  SelectType: typeof SelectType;
  ProcessedSelects: typeof ProcessedSelects;
  Note: typeof Note;
  Attachments: typeof Attachments;
  AcceptationCheckbox: typeof AcceptationCheckbox;
  Reason: typeof Reason;
  InnerContainer: typeof InnerContainer;
};

export const AddRequestMainLayout: AddRequestContainerLayout = ({ children }: Props): React.ReactElement => {
  const { isSmartphoneBreakpoint } = useThemeBreakpoint();

  return (
    <Flex
      sx={{
        fontSize: 2,
        flexDirection: 'column',
        flex: '1',
        pl: 3,
        ...(!isSmartphoneBreakpoint && { overflowY: 'auto', pr: 3, pb: 3 }),
      }}
    >
      {children}
    </Flex>
  );
};

AddRequestMainLayout.SelectType = SelectType;
AddRequestMainLayout.ProcessedSelects = ProcessedSelects;
AddRequestMainLayout.Note = Note;
AddRequestMainLayout.Attachments = Attachments;
AddRequestMainLayout.AcceptationCheckbox = AcceptationCheckbox;
AddRequestMainLayout.Reason = Reason;
AddRequestMainLayout.InnerContainer = InnerContainer;
