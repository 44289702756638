/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import { InputProps } from 'theme-ui';

import { createEvent } from './createEvent';

/**
 * Sets the value of a passed input element and dispatches a 'input' event from that element.
 *
 * @param {React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>} inputRef
 * @param {InputProps['value'] | InputProps['value'][]} value
 */

export function setNativeValue(
  inputRef: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>,
  value: InputProps['value'] | InputProps['value'][],
): void {
  const input = inputRef?.current;

  if (input) {
    const { HTMLTextAreaElement, HTMLInputElement } = window;
    const HTMLElement = input.type === 'textarea' ? HTMLTextAreaElement : HTMLInputElement;
    const nativeInputValue = Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'value');
    if (nativeInputValue) {
      const nativeInputValueSetter = nativeInputValue.set;

      if (nativeInputValueSetter) {
        nativeInputValueSetter.call(input, value);

        const event = createEvent('input');
        input.dispatchEvent(event);
      }
    }
  }
}
