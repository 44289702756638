import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-fetching-library';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { signUpAction } from 'api/actions/auth/authActions';
import { SignUpErrorCodes, SignUpPayload } from 'api/actions/auth/authActions.types';
import { FormCard } from 'components/FormCard/FormCard';
import { IntegrationsAuthenticationFooter } from 'components/IntegrationsAuthenticationFooter/IntegrationsAuthenticationFooter';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { APP_NAME } from 'constants/common';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { TO } from 'constants/routes';
import {
  setEmail,
  setIsSignUpInProgress,
  setToken,
  setUnauthorized,
  startAuthorizing,
} from 'context/auth/authActionCreators/authActionCreators';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { Main } from 'layouts/Authentication/output/Main';
import { languageSelector } from 'state/recoilState';
import { signUpFormAtom } from 'state/signUp';

import { Aside } from './components/Aside/Aside';
import { PrivacyInformation } from './components/PrivacyInformation';
import { SignUpForm } from './components/SignUpForm';

export const SignUp = (): React.ReactElement => {
  useLingui();
  const navigate = useAppNavigate();
  const language = useRecoilValue(languageSelector);
  const resetSignUpForm = useResetRecoilState(signUpFormAtom);

  const { mutate } = useMutation(signUpAction);
  const dispatch = useAuthDispatch();

  useEffect(() => {
    resetSignUpForm();
  }, [resetSignUpForm]);

  const onSubmit = useCallback(
    async (body: SignUpPayload): Promise<boolean> => {
      dispatch(startAuthorizing());

      const { payload, error: submitError } = await mutate(body);

      dispatch(setUnauthorized());

      const email = body.email || null;

      if (!submitError && payload) {
        // GTM DATA LAYER (SIGNUP-SUBMIT)
        TagManager.dataLayer({
          dataLayer: {
            event: 'signup-submit',
            method: 'email',
            email,
          },
        });
        if (email) {
          dispatch(setEmail(email));
        }
        const { accessToken } = payload;
        dispatch(setToken(accessToken));
        dispatch(setIsSignUpInProgress(true));
        navigate(TO.SIGN_UP__ACCOUNT_TYPE[language], { state: { signUp: { inProgress: true } } });
        return true;
      }

      dispatch(setIsSignUpInProgress(false));

      if (submitError && payload?.innerCode) {
        resetSignUpForm();

        // GTM DATA LAYER (SIGNUP-FAIL)
        TagManager.dataLayer({
          dataLayer: {
            event: 'signup-fail',
            method: 'email',
          },
        });

        const { innerCode } = payload;

        if (innerCode === SignUpErrorCodes.USER_INVITED) {
          navigate(TO.SIGN_UP__EMPLOYEE__INVITED[language], {
            state: {
              signUp: { inProgress: true, invitedEmployee: payload.data },
            },
          });
          return true;
        }
      }

      return false;
    },
    [dispatch, mutate, navigate, language, resetSignUpForm],
  );

  return (
    <>
      <Helmet>
        <title>{t({ id: 'sign_up.page_title', message: `Sign up - ${APP_NAME}` })}</title>
      </Helmet>

      <Aside>
        <Flex sx={{ gap: 2, flexDirection: 'column' }}>
          <Aside.Card
            title={<Trans id="sign_up.promo.quick_start_title">Get started quickly</Trans>}
            content={
              <Trans id="sign_up.promo.quick_start">
                With our built-in automations you'll jump start your account in the matter of minutes.
              </Trans>
            }
            iconType="rocket"
          />
          <Aside.Card
            title={<Trans id="sign_up.promo.focus_title">Focus on your business</Trans>}
            content={
              <Trans id="sign_up.promo.focus">
                Prepare ready for payroll timesheets and schedules with single click.
              </Trans>
            }
            iconType="heartOutline"
          />
          <Aside.Card
            title={<Trans id="sign_up.promo.ditch_title">Ditch paper, notes and spreadsheets</Trans>}
            content={
              <Trans id="sign_up.promo.ditch">
                Replace them with all-in-one tool, and your team will be more effective and efficient.
              </Trans>
            }
            iconType="cloud"
          />
          <Aside.Card
            title={<Trans id="sign_up.promo.timeclock_title">Mobile work time clock</Trans>}
            content={
              <Trans id="sign_up.promo.timeclock">
                Turn any smartphone or tablet into a professional tool with our mobile apps.
              </Trans>
            }
            iconType="timer"
          />
        </Flex>
      </Aside>

      <Main sx={{ flexGrow: 0 }}>
        <Flex
          sx={{
            width: '100%',
            maxWidth: '480px',
            flexGrow: 1,
            gap: 5,
            flexDirection: 'column',
            alignItems: 'stretch',
            alignSelf: 'center',
            textAlign: 'center',
          }}
        >
          <FormCard>
            <FormCard.Header>
              <FormCard.Title>
                <Trans id="sign_up.lead.header">Good choice! 👍</Trans>
              </FormCard.Title>
              <FormCard.Lead>
                <Trans id="sign_up.lead.text">
                  Join over <Text sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>300,000</Text> satisfied users.
                </Trans>
              </FormCard.Lead>
            </FormCard.Header>

            <SignUpForm onSubmit={onSubmit} />
            <IntegrationsAuthenticationFooter isSignUp />
          </FormCard>

          <Text as="p" sx={{ alignSelf: 'center', color: 'signUp.text.primary' }}>
            <LinkAnchor to={TO.SIGN_IN[language]} sx={{ fontWeight: '700' }}>
              <Trans id="sign_up.sign_in_button">Sign in instead</Trans>
            </LinkAnchor>
          </Text>

          <Flex sx={{ gap: 2, flexDirection: 'column' }}>
            <Text as="p" sx={{ alignSelf: 'center', color: 'signUp.text.secondary' }}>
              <Trans id="sign_up.terms_acceptance">
                Creating an account you accept our{' '}
                <LinkAnchor sx={{ textDecoration: 'underline' }} href={EXTERNAL_LINKS.TERMS} target="_blank">
                  Terms & Conditions
                </LinkAnchor>
                .
              </Trans>
            </Text>

            <PrivacyInformation />
          </Flex>
        </Flex>
      </Main>
    </>
  );
};
