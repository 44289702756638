export const MARGIN = 4;

export const MAIN_WINDOW_WIDTH = 288;
export const CHAT_WINDOW_WIDTH = 200;
export const CHAT_WINDOW_HEIGHT = 40;
export const CHAT_WINDOW_MOBILE_WIDTH = 40;
export const MAIN_WINDOW_MOBILE_WIDTH = 40;

export const CHAT_WINDOW_IS_VISIBLE_WIDTH = 300;
export const CHAT_WINDOW_IS_EXPANDED_WIDTH = 450;
export const CHAT_WINDOW_IS_VISIBLE_HEIGHT = 400;
export const CHAT_WINDOW_IS_EXPANDED_HEIGHT = 620;

export const CHAT_USER_HEIGHT = 46;

export const CHAT_BOT = 'CHATBOT';

export const MESSAGES_PART_SIZE = 20;

export const WINDOW_ANIMATION_DURATION = 0.175;
export const MARGIN_WINDOW_ANIMATION_DURATION = 0.3;

export const MAX_MESSAGE_LENGTH = 1000;
