import dayjs, { ConfigType } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import _ from 'lodash';

interface Options {
  timezone?: string;
  disableTimezone__UNSAFE?: boolean;
  utc?: boolean;
  stringFormat?: string;
}

/**
 * Returns date object from dayJs with a Time Zone.
 * Use Options param 'utc' to treat the date as a utc date (formatting and getters will be based on what date it represents in utc).
 * If the first argument provided is a date with custom string format, you should also define the 'stringFormat' in 'Options' parm.
 *
 * @param {ConfigType} date dayjs | dateTime | number/unix | string.
 * @param {Options} Options timezone options.
 * @return {dayjs} dayjs object.
 */
export const dateTime = (
  date: ConfigType | undefined = undefined,
  { timezone, disableTimezone__UNSAFE, utc, stringFormat }: Options = { disableTimezone__UNSAFE: false, utc: false },
): dayjs.Dayjs => {
  const restArguments: [string | undefined, boolean | undefined] = [stringFormat, stringFormat ? true : undefined];

  let internalDate = date;

  if (
    (internalDate &&
      (typeof internalDate === 'number' || typeof internalDate === 'string') &&
      !!Number(internalDate)) ||
    internalDate === '0'
  ) {
    internalDate = parseInt(internalDate.toString(), 10) * 1000;
  }

  if (utc) {
    return dayjs.utc(internalDate, ...restArguments);
  }

  if (disableTimezone__UNSAFE) {
    return dayjs(internalDate, ...restArguments)
      .tz('Europe/London')
      .utc(true);
  }

  return dayjs(internalDate, ...restArguments).tz(timezone);
};

/**
 * Returns time (date) object in current Time Zone from any DateTime.
 *
 * @param {ConfigType} date dayjs | dateTime | number/unix | string.
 * @return {dateTime} dateTime object.
 */
export const timeTz = (date: ConfigType): dayjs.Dayjs => {
  const currentUtcOffset = dateTime().utcOffset();
  let internalDate = date;

  if (
    (internalDate &&
      (typeof internalDate === 'number' || typeof internalDate === 'string') &&
      !!Number(internalDate)) ||
    internalDate === '0'
  ) {
    internalDate = parseInt(internalDate.toString(), 10) * 1000;
  }

  const timeFromDate = dayjs(internalDate).utc().utcOffset(currentUtcOffset);

  const timeAbsolute = dayjs(0).utc().utcOffset(currentUtcOffset).set({
    hour: timeFromDate.hour(),
    minute: timeFromDate.minute(),
    // second: timeFromDate.second(),
  });

  return timeAbsolute;
};

type DateObject = {
  year: number;
  month: number;
  date: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

/**
 * Creates a date in timezone and returns its unix.
 *
 * @param {DateObject} DateObject date details.
 * @return {number} date in timezone unix.
 */
export const getTzDateUnix = ({ date, month, year, hours = 0, minutes = 0, seconds = 0 }: DateObject): number => {
  const startOfDayUtc = dateTime(undefined, { disableTimezone__UNSAFE: true })
    .set({
      // do not change the order, as for every entry dayjs is making a separate set, without sorting;
      // if date is before month and year the max limit for days in month will be taken from current day
      year,
      month,
      date,
    })
    .startOf('day');

  const utcOffset =
    dateTime()
      .set({
        // do not change the order, as for every entry dayjs is making a separate set, without sorting;
        // if date is before month and year the max limit for days in month will be taken from current day
        year: startOfDayUtc.get('year'),
        month: startOfDayUtc.get('month'),
        date: startOfDayUtc.get('date'),
        hours,
        minutes,
        seconds,
      })
      .utc()
      .tz()
      .utcOffset() * 60;

  const timeInSeconds = dayjs
    .duration({
      hours,
      minutes,
      seconds,
    })
    .asSeconds();

  const tzUnix = startOfDayUtc.unix() - utcOffset + timeInSeconds;

  return tzUnix;
};

/**
 * Returns a dayjs date object in utc absolute 0 (1970/01/01 00:00:00) + seconds that were provided.
 * Use as a helper when you have time in seconds and need to display it formatted.
 *
 * @param {string | number} timeInSeconds time.
 * @return {dayjs.Dayjs} start of utc date (1970/01/01 00:00:00) + provided seconds.
 */
export const getDateFromSeconds = (timeInSeconds: string | number): dayjs.Dayjs =>
  dateTime(timeInSeconds, { utc: true });

/**
 * Returns object representing the start of the day date in timezone.
 *
 * @param {string | number} unix date.
 * @return {DateObject} object with date/month/year taken from provided date in timezone with time set to 00:00:00.
 */
export const getTzStartOfDayObj = (unix: string | number): DateObject => {
  // lookout, this caused the date to act weird as when consol logged it contained
  // the expected date (ex. 2021/03/29), but tzStartOfDay.get('date') returned 28
  // most probably a dayjs bug; it takes offset from the machine timezone and not the default one set in the app
  // const tzStartOfDay = dateTime(unix).startOf('day');
  const tzStartOfDay = dateTime(unix);

  return {
    // do not change the order, as for every entry dayjs is making a separate set, without sorting;
    // if date is before month and year the max limit for days in month will be taken from current day
    year: tzStartOfDay.get('year'),
    month: tzStartOfDay.get('month'),
    date: tzStartOfDay.get('date'),
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
};

/**
 * Returns a start of the day unix of the provided date.
 * day/month/year derived from the first param will be injected to the return date, time will be set to 00:00:00.
 * ex. for 'America/New_York' time zone:
 * - getUtcStartOfDayUnix(3600) will return -86400 (31 December 1969 00:00:00 in utc) as the provided unix will be treated as 'America/New_York' date which is 31 December 1969 20:00:00.
 * - getUtcStartOfDayUnix(3600, true) will return 0 (1 January 1970 00:00:00 in utc) as the provided unix will be treated as utc date which is 1 January 1970 01:00:00
 *
 * @param {string | number} unix utc unix from which day/month/year will be extracted and set on the return date unix.
 * @param {boolean | undefined} useUtcDate when true, the provided date will be treated as a utc date.
 * @return {number} start of the day unix of the provided date in utc.
 */
export const getUtcStartOfDayUnix = (unix: string | number, useUtcDate?: boolean): number => {
  const tzStartOfDayObj = getTzStartOfDayObj(unix);

  const utcStartOfDay = useUtcDate
    ? dateTime(unix, { utc: true }).startOf('day')
    : dateTime(undefined, { utc: true }).set(tzStartOfDayObj);

  return utcStartOfDay.unix();
};

/**
 * Returns a unix of the new timezone date created using first two arguments.
 * Use when you have time in seconds (ex. TimePicker value) and want to add it to a unix (ex. DatePicker value).
 * First argument will provide year, month and day, second argument will provide hours, minutes and seconds.
 *
 * @param {string | number} dateUnix utc unix from which year, month and day will be taken.
 * @param {string | number} timeInSeconds ex. 01:00:00 = 3600 (1x6x60).
 * @param {boolean | undefined} utc when true, dateUnix will be considered as a utc date.
 * @return {number} local start of the day unix + provided time in seconds.
 */
export const getDateWithTimeInSecondsUnix = (
  dateUnix: string | number,
  timeInSeconds: string | number,
  utc?: boolean,
): number => {
  const utcDate = dateTime(dateUnix, { utc: true });
  const tzDate = dateTime(dateUnix).startOf('day');

  const dateProvider = utc ? utcDate : tzDate;
  const timeProvider = getDateFromSeconds(timeInSeconds);

  const dateObj = {
    // do not change the order, as for every entry dayjs is making a separate set, without sorting;
    // if date is before month and year the max limit for days in month will be taken from current day
    year: dateProvider.get('year'),
    month: dateProvider.get('month'),
    date: dateProvider.get('date'),
    hours: timeProvider.get('hour'),
    minutes: timeProvider.get('minute'),
    seconds: timeProvider.get('second'),
  };

  const dateWithTimeUnix = getTzDateUnix(dateObj);

  return dateWithTimeUnix;
};

/**
 * Returns duration from seconds.
 *
 * @param {number} seconds number.
 * @return {Duration} duration object.
 */

export const durationFromSeconds = (seconds: number): Duration => {
  let internalDate = seconds;

  if (
    internalDate &&
    (typeof internalDate === 'number' || typeof internalDate === 'string') &&
    !!Number(internalDate)
  ) {
    internalDate = parseInt(internalDate.toString(), 10) * 1000;
  }

  if (internalDate < 0) {
    internalDate = parseInt('0', 10);
  }

  return dayjs.duration(internalDate);
};

export const formattedDurationFromSeconds = (seconds: number, hideSecondsBelowMinute?: boolean): string => {
  const internalSeconds = seconds < 0 ? 0 : seconds;
  const duration = durationFromSeconds(internalSeconds);

  const hours = duration.asHours();
  const minutes = duration.asMinutes();
  const sec = duration.asSeconds();

  const truncHours = Math.trunc(hours);
  const truncMinutes = Math.trunc(minutes - truncHours * 60);
  const truncSeconds = Math.trunc(sec - truncMinutes * 60 - truncHours * 3600);

  const generateString = () => {
    let str = '';

    if (truncHours > 0) {
      str += `${truncHours}h`;
    }

    if (truncMinutes > 0) {
      str += ` ${truncMinutes}min`;
    }

    if (truncSeconds > 0 && (!hideSecondsBelowMinute || !str)) {
      str += ` ${truncSeconds}s`;
    }

    return _.trim(str);
  };

  return generateString();
};
