import { t, Trans } from '@lingui/macro';
import { FC, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text, ThemeUICSSObject } from 'theme-ui';

import { LoadingSpinnerSize } from 'components/Loading/LoadingSpinnerCSS';
import { ControlledModal } from 'components/Modal/Modal';
import { AnchorButton, Button } from 'components/ui/Buttons';
import { Image } from 'components/ui/Image';
import { windowSizeAtom } from 'state/recoilState';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';
import { getSrcSet } from 'utils/getSrcSet';

import { PZUActivatedAccountModal } from './PZUActivatedAccountModal';
import { PZU_ACTIVATED_ACCOUNT_MODAL_ID, PZUFormModal } from './PZUFormModal';

type PzuSportBenefitDetailsProps = {
  disableActivation?: boolean;
};

const LOGO_SRC_PZU = 'https://cdn.inewi.pl/images/_assets/inewi/benefits/pzu-benefits.png';

const LOGO_SIZE = '64px';
const LOGO_SIZE_MOBILE = '100px';

const PZU_FORM_MODAL_ID = 'PZU_FORM_MODAL_ID';
const PZU_SPORT_FIND_OUT_MORE_LINK = 'https://inewi.pl/benefity/pzu-sport';

const buttonSx: ThemeUICSSObject = {
  minWidth: ['220px', 'auto'],
  textAlign: 'center',
  '> span': {
    width: 'auto',
  },
};

export const PzuSportBenefitDetails: FC<PzuSportBenefitDetailsProps> = ({ disableActivation }) => {
  const { isLandscape } = useRecoilValue(windowSizeAtom);
  const logoSizes = [isLandscape ? LOGO_SIZE : LOGO_SIZE_MOBILE, LOGO_SIZE];
  const handleOpenPZUFormModal = useCallback(() => {
    emitCustomEvent(CustomEvents.MODAL_OPEN, { id: PZU_FORM_MODAL_ID });
  }, []);

  return (
    <>
      <Flex
        sx={{
          gap: 4,
          flexDirection: [isLandscape ? 'row' : 'column', 'row'],
          alignItems: [isLandscape ? null : 'center', 'flex-start'],
        }}
      >
        <Image
          src={LOGO_SRC_PZU}
          srcSet={getSrcSet(LOGO_SRC_PZU)}
          alt={t({ id: 'your_benefits.pzu_sport.title' })}
          loadingOverlaySize={LoadingSpinnerSize.imageSM}
          wrapperSx={{ minWidth: 'fit-content' }}
          sx={{ minWidth: logoSizes, height: logoSizes, width: logoSizes }}
        />

        <Flex sx={{ flexDirection: 'column' }}>
          <Text
            sx={{
              fontWeight: 'bold',

              fontSize: 3,
              alignSelf: [isLandscape ? null : 'center', 'flex-start'],
            }}
          >
            <Trans id="your_benefits.pzu_sport.title">PZU Sport</Trans>
          </Text>
          <Text sx={{ mt: 2 }}>
            <Trans id="your_benefits.pzu_sport.description">
              Discover access to <b>over 3,700 sports and recreational facilities</b> across Poland and choose from{' '}
              <b>more than 20 activities</b>, including racket sports.
            </Trans>
          </Text>
          <Text sx={{ mt: 1 }}>
            <Trans id="your_benefits.pzu_sport.pricing">Starting at just 84.00 PLN per month.</Trans>
          </Text>
        </Flex>

        <Flex sx={{ flexDirection: 'column', gap: 2, flexShrink: 0, alignItems: 'stretch' }}>
          <Button
            disabled={disableActivation}
            onClick={handleOpenPZUFormModal}
            shape="rounded"
            variant="primary"
            sx={buttonSx}
          >
            <Trans id="your_benefits.pzu_sport.activate">Activate</Trans>
          </Button>
          <AnchorButton
            href={PZU_SPORT_FIND_OUT_MORE_LINK}
            target="_blank"
            shape="rounded"
            variant="lightGrey"
            sx={buttonSx}
          >
            <Trans id="your_benefits.pzu_sport.find_out_more">Find out more</Trans>
          </AnchorButton>
        </Flex>
      </Flex>
      <ControlledModal id={PZU_FORM_MODAL_ID} size="sm">
        <PZUFormModal />
      </ControlledModal>
      <ControlledModal id={PZU_ACTIVATED_ACCOUNT_MODAL_ID} size="sm" sx={{ minHeight: 'fit-content' }}>
        <PZUActivatedAccountModal />
      </ControlledModal>
    </>
  );
};
