import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { notificationSettingsAction } from 'api/actions/settings/settingsActions';
import { NotificationSettingsActionProps } from 'api/actions/settings/settingsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { SnackbarProps } from 'components/Snackbar/types';
import { DEFAULT_NOTIFICATIONS_RESET } from 'constants/settings';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { blockTransitionAtom, resetFormButtonAtom } from 'state/settings';
import { getRefreshSnackbarProps } from '../../../../../utils/getRefreshSnackbarProps';

import { useDefaultNotifications } from './useDefaultNotifications';

type Props = {
  handleFormReset: () => void;
  getCurrentFormState: () => NotificationSettingsActionProps;
};

export const useSubmitDefaultNotifications = ({ handleFormReset, getCurrentFormState }: Props) => {
  useLingui();
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const [{ skipFetch, isRequestPending }, setBlockTransition] = useRecoilState(blockTransitionAtom);
  const { fetchDefaultNotifications, defaultNotificationsSettings, fetchAbort } = useDefaultNotifications();
  const { mutate } = useMutation(notificationSettingsAction);
  const skipFetchRef = useCallbackRef(skipFetch);
  const isRequestPendingRef = useCallbackRef(isRequestPending);
  const getCurrentFormStateRef = useCallbackRef(getCurrentFormState);
  const pendingRequestBodyRef = useRef<Partial<NotificationSettingsActionProps> | null>(null);
  const prevFormStateRef = useRef<NotificationSettingsActionProps | null>(defaultNotificationsSettings);

  const handleSubmitCallback = useCallback(
    async (data: NotificationSettingsActionProps) => {
      const submittedFormState = data;

      if (
        _.isEqual(submittedFormState, defaultNotificationsSettings) &&
        _.isEmpty(pendingRequestBodyRef.current) &&
        !isRequestPendingRef.current
      ) {
        setBlockTransition((prevState) => ({ ...prevState, blockTransition: false }));
        return;
      }

      const currentChanges: Partial<NotificationSettingsActionProps> = _.omitBy(
        submittedFormState,
        (value, key) =>
          prevFormStateRef.current && value === prevFormStateRef.current[key as keyof NotificationSettingsActionProps],
      );

      fetchAbort();
      setResetCallbacks(null);
      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: true }));

      prevFormStateRef.current = submittedFormState;
      pendingRequestBodyRef.current = { ...pendingRequestBodyRef.current, ...currentChanges };

      const formStateBeforeRequest = getCurrentFormStateRef.current();

      const { error, status } = await mutate(pendingRequestBodyRef.current);

      const formStateAfterRequest = getCurrentFormStateRef.current();

      if (!_.isEqual(formStateBeforeRequest, formStateAfterRequest)) {
        return;
      }

      if (!error) {
        let showRefreshSnackbar = false;
        pendingRequestBodyRef.current = null;

        if (!skipFetchRef.current) {
          const { error: fetchError, payload: fetchPayload } = await fetchDefaultNotifications();

          const formStateAfterFetch = getCurrentFormStateRef.current();

          if (!_.isEqual(formStateBeforeRequest, formStateAfterFetch)) {
            return;
          }

          if (!fetchError && fetchPayload) {
            showRefreshSnackbar = !_.isEqual(formStateAfterFetch, fetchPayload);
          }
        }

        const snackbarProps: SnackbarProps = showRefreshSnackbar
          ? getRefreshSnackbarProps(handleFormReset)
          : {
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            };

        addSnackbar(snackbarProps);
      }

      if (error) {
        if (!status) {
          return;
        }
        const notificationsResetObject = { name: DEFAULT_NOTIFICATIONS_RESET, callback: handleFormReset };
        setResetCallbacks((prevState) =>
          !prevState ? [notificationsResetObject] : [...prevState, notificationsResetObject],
        );
        return;
      }

      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: false, blockTransition: false }));
    },
    [
      defaultNotificationsSettings,
      isRequestPendingRef,
      fetchAbort,
      setResetCallbacks,
      setBlockTransition,
      mutate,
      skipFetchRef,
      handleFormReset,
      fetchDefaultNotifications,
      getCurrentFormStateRef,
    ],
  );

  return handleSubmitCallback;
};
