import { Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useRecoilState_TRANSITION_SUPPORT_UNSTABLE, useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { ITEM_DEFAULT_HEIGHT } from 'components/StickyList/constants';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { RequestStickyList } from 'pages/Requests/output/RequestStickyList';
import { useGenerateRequestColumns } from 'pages/Requests/output/useGenerateRequestColumns';
import { parsedRequestsToAcceptSelector, requestsToAcceptColumns } from 'state/home';
import { languageSelector } from 'state/recoilState';
import { requestsToAcceptAtom } from 'state/requests';

import { useRequestsToAccept } from './hooks/useRequestsToAccept';

type Props = {
  onFetchResponse: () => void;
};

export const RequestsList = ({ onFetchResponse }: Props) => {
  const [columns, setColumns] = useRecoilState_TRANSITION_SUPPORT_UNSTABLE(requestsToAcceptColumns);
  const requestsToAccept = useRecoilValue(requestsToAcceptAtom);

  const language = useRecoilValue(languageSelector);

  const generatedColumns = useGenerateRequestColumns('home');

  const { isInitialized, fetchRequestsToAccept } = useRequestsToAccept();

  const requestToAcceptLength = useMemo(() => {
    if (!requestsToAccept) return 0;
    return _.flatMap(requestsToAccept).length;
  }, [requestsToAccept]);

  const isEmpty = useMemo(() => requestToAcceptLength === 0, [requestToAcceptLength]); // ani w tym anti spoofing nie ma nic

  const listHeight = useMemo(() => {
    if (isEmpty) return undefined;
    if (requestToAcceptLength > 9) return `${ITEM_DEFAULT_HEIGHT * 10 + 48}px`;
    return `${ITEM_DEFAULT_HEIGHT * (requestToAcceptLength + 1) + 48}px`; // INFO: +1 because of the header
  }, [isEmpty, requestToAcceptLength]);

  // FIXME: this rerenders always, find a better way to support changes in header titles
  useEffect(() => {
    if ((!columns && generatedColumns) || language) {
      setColumns(generatedColumns);
    }
  }, [columns, generatedColumns, language, setColumns]);

  const onFetchResponseRef = useCallbackRef(onFetchResponse);

  useEffect(() => {
    const fetchRequests = async () => {
      await fetchRequestsToAccept();
      onFetchResponseRef.current();
    };
    void fetchRequests();
  }, [fetchRequestsToAccept, onFetchResponseRef]);

  if (!requestsToAccept?.length && isInitialized) {
    return null;
  }

  return (
    <Flex
      sx={{
        minHeight: listHeight || '2.5rem',
        position: 'relative',
        flexDirection: 'column',
        gap: '0.125rem',
        mr: -4,
        overflow: ['auto', null, null, 'unset'],
      }}
    >
      {!isInitialized && <LoadingOverlay size={3} sx={{ zIndex: 'base' }} />}
      <React.Suspense fallback={<LoadingOverlay size={3} sx={{ zIndex: 'base' }} />}>
        <>
          <Text sx={{ fontWeight: '700' }}>
            <Trans id="home.things_to_do.pending_requests">Pending requests</Trans>
          </Text>
          {columns && requestsToAccept?.length && (
            <RequestStickyList<typeof parsedRequestsToAcceptSelector>
              selectable={false}
              columnsAtom={requestsToAcceptColumns}
              requestListAtom={parsedRequestsToAcceptSelector}
              listType="pending"
            />
          )}
        </>
      </React.Suspense>
    </Flex>
  );
};
