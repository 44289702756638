import { useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ActionOnRequest, ActionPermission, RequestState } from 'api/actions/requests/requestsActions.types';
import { useModal } from 'components/Modal/output/useModal';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import {
  parsedRequestDetailsAtom,
  parsedRequestDetailsSelector,
  requestDetailsAtom,
  requestDetailsDateDetailsSelector,
  requestDetailsStateAtom,
} from 'state/requests';
import { RequestDetailsGroupedParam } from '../../../types';

export const useRequestDetails = (passedInstanceNumber?: number) => {
  const { state: locationState } = useLocation();
  const {
    id: paramsId,
    group: paramsGroup,
    type: requestsType,
  } = useParams() as { id: string; group: string; type: string };

  const { instanceNumber: modalInstanceNumber } = useModal();
  const { modulesManagement } = useAppPermissions();

  const instanceNumber = useMemo(
    () => passedInstanceNumber || modalInstanceNumber,
    [modalInstanceNumber, passedInstanceNumber],
  );

  const { requestDetails: requestDetailsLocationState, requestDetailsData, opener } = locationState || {};
  const openerRef = useRef(opener);

  const parsedRequestDetails = useRecoilValue(parsedRequestDetailsSelector(instanceNumber));
  const setParsedRequestDetails = useSetRecoilState(parsedRequestDetailsAtom(instanceNumber));
  const [requestDetails, setRequestDetails] = useRecoilState(requestDetailsAtom(instanceNumber));
  const [openerRequestDetails, setOpenerRequestDetails] = useRecoilState(
    requestDetailsAtom(openerRef.current?.uniqueId || ''),
  );
  const [openerParsedRequestDetails, setOpenerParsedRequestDetails] = useRecoilState(
    parsedRequestDetailsAtom(openerRef.current?.uniqueId || ''),
  );
  const [requestDetailsState, setRequestDetailsState] = useRecoilState(requestDetailsStateAtom(instanceNumber));
  const requestDetailsDateDetails = useRecoilValue(requestDetailsDateDetailsSelector(instanceNumber));

  const actionsPermissions = useMemo(() => {
    if (!parsedRequestDetails) return null;

    const { actions } = parsedRequestDetails;

    return {
      acceptationAllowed: actions[ActionOnRequest.Accept] === ActionPermission.Allowed,
      rejectionAllowed: actions[ActionOnRequest.Reject] === ActionPermission.Allowed,
      acceptationForSwapAllowed: actions[ActionOnRequest.AcceptSwap] === ActionPermission.Allowed,
      rejectionForSwapAllowed: actions[ActionOnRequest.RejectSwap] === ActionPermission.Allowed,
      editAllowed: actions[ActionOnRequest.Edit] === ActionPermission.Allowed,
    };
  }, [parsedRequestDetails]);

  const isModificationAllowed = useMemo(() => {
    if (!parsedRequestDetails || !actionsPermissions) return false;

    const { state } = parsedRequestDetails;
    const { acceptationAllowed, acceptationForSwapAllowed, rejectionAllowed, rejectionForSwapAllowed } =
      actionsPermissions;

    if (state !== RequestState.Pending) return false;

    if (acceptationForSwapAllowed || rejectionForSwapAllowed) return true;

    if ((acceptationAllowed || rejectionAllowed) && modulesManagement.Requests) return true;

    return false;
  }, [actionsPermissions, modulesManagement.Requests, parsedRequestDetails]);

  const isRejectionAllowed = useMemo(() => {
    if (!actionsPermissions) return false;

    const { rejectionAllowed, rejectionForSwapAllowed } = actionsPermissions;

    if (rejectionForSwapAllowed) return true;

    if (rejectionAllowed) return true;

    return false;
  }, [actionsPermissions]);

  const isApprovalAllowed = useMemo(() => {
    if (!actionsPermissions) return false;

    const { acceptationAllowed, acceptationForSwapAllowed } = actionsPermissions;

    if (acceptationForSwapAllowed) return true;

    if (acceptationAllowed) return true;

    return false;
  }, [actionsPermissions]);

  return {
    requestDetails,
    setRequestDetails,
    parsedRequestDetails,
    setParsedRequestDetails,
    openerRequestDetails,
    setOpenerRequestDetails,
    openerParsedRequestDetails,
    setOpenerParsedRequestDetails,
    requestDetailsState,
    setRequestDetailsState,
    requestDetailsDateDetails,
    locationState: {
      requestDetails: requestDetailsLocationState,
      requestDetailsData,
      opener,
    },
    urlParams: {
      id: paramsId,
      group: +paramsGroup as RequestDetailsGroupedParam,
      requestsType,
    },
    instanceNumber,
    actionsPermissions,
    isModificationAllowed,
    isRejectionAllowed,
    isApprovalAllowed,
  };
};
