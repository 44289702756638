import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { Text } from 'theme-ui';

import { VALIDATION_RULES } from 'constants/validationRules';

import { TextInput, TextInputProps } from './TextInput';

type CounterProps = {
  maxLength: number;
  watchProps: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any>;
    name: string;
  };
};

const Counter = ({ maxLength, watchProps }: CounterProps) => {
  const value = useWatch(watchProps);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const currentLength = value?.length;

  return (
    <Text sx={{ fontSize: 0, marginLeft: 'auto', fontWeight: 'bold', color: 'textArea.counter' }}>
      {currentLength || 0}/{maxLength}
    </Text>
  );
};

type Props = TextInputProps & {
  counterProps?: CounterProps['watchProps'];
  resize?: 'none' | 'both' | 'horizontal' | 'vertical' | 'block' | 'inline';
};

export type TextareaProps = Props;

const defaultProps: Partial<Props> = {
  counterProps: undefined,
  resize: 'none',
};

export const Textarea = React.forwardRef<HTMLInputElement, Props>(
  ({ counterProps, inputProps, resize, ...props }: Props, ref) => (
    <>
      <TextInput
        ref={ref}
        {...props}
        inputProps={{
          ...inputProps,
          as: 'textarea',
        }}
        sxOverwrite={{
          '& textarea': {
            resize,
          },
          ...(props.sxOverwrite && props.sxOverwrite),
        }}
      />

      {counterProps && (
        <Counter watchProps={counterProps} maxLength={props.maxLength || VALIDATION_RULES.NOTE.maxLength} />
      )}
    </>
  ),
);

Textarea.defaultProps = defaultProps;
