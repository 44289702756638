import { Flex, ThemeUIStyleObject } from 'theme-ui';
import { keyframes } from '@emotion/react';
import React, { memo } from 'react';

import { getAssetsPath } from 'utils/getAssetsPath';

import { StickyListProps } from './types';

type Props = Pick<StickyListProps, 'type'> & {
  sx?: ThemeUIStyleObject;
};

const defaultProps = {
  sx: undefined,
};

const pulseAnimation = keyframes({
  '0%': {
    opacity: 0.3,
  },
  '100%': {
    opacity: 1,
  },
});

const listSx: ThemeUIStyleObject = {
  flexGrow: 1,
  backgroundImage: getAssetsPath('url(./app/assets/svg/ListFrame.svg)'),
  backgroundRepeat: 'repeat',
  backgroundPosition: '0% 0%',
  borderRadius: 'sm',
  mx: '2px',
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundImage: getAssetsPath('url(./app/assets/svg/ListContent.svg)'),
    backgroundRepeat: 'repeat-y',
    backgroundPosition: '0% 0%',
    animation: `${pulseAnimation} 0.5s ease-in-out infinite alternate`,
  },
};

const gridSx: ThemeUIStyleObject = {
  flexGrow: 1,
  backgroundImage: getAssetsPath('url(./assets/svg/ListFrame.svg)'),
  backgroundRepeat: 'repeat',
  backgroundPosition: '0% 0%',
  backgroundClip: 'content-box',
  px: '38px',
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundImage: getAssetsPath(
      'url(./assets/svg/GridContentStart.svg), url(./assets/svg/GridContentEnd.svg), url(./assets/svg/GridContent.svg)',
    ),
    backgroundRepeat: 'repeat-y, repeat-y, repeat',
    backgroundPosition: '0% 0%, 100% 0%, 0% 0%',
    animation: `${pulseAnimation} 0.5s ease-in-out infinite alternate`,
  },
  '&::before': {
    content: '""',
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundImage: getAssetsPath('url(./assets/svg/ListFrameStart.svg), url(./assets/svg/ListFrameEnd.svg)'),
    backgroundRepeat: 'repeat-y, repeat-y',
    backgroundPosition: '0% 0%, 100% 0%',
  },
};

export const ContentPlaceholder = ({ type, sx, ...props }: Props): React.ReactElement => (
  <Flex {...props} sx={{ ...(type === 'list' ? listSx : gridSx), ...(sx && sx) }} />
);

ContentPlaceholder.defaultProps = defaultProps;

export const MemorizedContentPlaceholder = memo(ContentPlaceholder);
