import { FC } from 'react';

import { Icon } from 'components/Icon/Icon';
import { Button, ButtonProps } from 'components/ui/Buttons';

export const ModifiedIconButton: FC<ButtonProps> = (props) => (
  <Button
    shape="rounded"
    size="xs"
    sx={{
      p: 0,
      color: 'texts.default',
      background: 'yellows1',
      border: '1px solid',
      borderColor: 'yellows4',
      '&:hover, &:focus': {
        background: 'yellows4',
        borderColor: 'yellows5',
      },
      '&:active': {
        background: 'yellows4',
      },
    }}
    {...props}
  >
    <Icon type="edit" size={24} />
  </Button>
);
