import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex, Text } from 'theme-ui';

import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { ShowFieldsButton } from 'components/recipes/ShowFieldsButton';
import { FileUpload } from 'components/ui/FileUpload/FileUpload';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';

export const Attachments = React.memo((): React.ReactElement | null => {
  useLingui();
  const {
    formState: { attachmentsVisible },
    addRequestState: { requestType },
    setFormState,
  } = useAddRequest();

  const { register, watch } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  const handleShowAttachmentsOnClick = useCallback(
    () => setFormState((prevState) => ({ ...prevState, attachmentsVisible: true })),
    [setFormState],
  );

  if (
    actionTypeWatch === RequestActionType.Remove ||
    requestType === RequestFormType.TimeTracking ||
    requestType === RequestFormType.Schedule
  )
    return null;

  if (!attachmentsVisible)
    return (
      <ShowFieldsButton
        onClick={handleShowAttachmentsOnClick}
        label={t({
          id: 'add_request.add_attachments',
          message: 'Add attachments (optional)',
        })}
      />
    );

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text
        sx={{
          fontSize: 2,
          fontWeight: 'bold',
          mb: 2,
        }}
      >
        {t({ id: 'add_request.add_attachments' })}
      </Text>
      <FileUpload
        {...register('files', {
          setValueAs: (v: string | string[]) => {
            if (_.isArray(v)) return v;

            return v.split(',');
          },
        })}
      />
    </Flex>
  );
});
