import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';

import { HeadingTip } from '../HeadingTip';
import { SettingsLoadingSpinnerCss } from '../SettingsLoadingSpinnerCss';

import { ProfileForm } from './components/ProfileForm/ProfileForm';
import { useProfileSettings } from './hooks/useProfileSettings';
import { useProfileSettingsCleanUp } from './hooks/useProfileSettingsCleanUp';

export const Profile = (): React.ReactElement => {
  useLingui();
  const { fetchUserInfo, profileSettings } = useProfileSettings();

  useEffect(() => {
    if (!profileSettings) {
      void fetchUserInfo();
    }
  }, [fetchUserInfo, profileSettings]);

  useProfileSettingsCleanUp();

  return (
    <>
      <HeadingTip
        label={t({
          id: 'settings.user.profile.header',
          message: 'Your Profile',
        })}
      />
      {profileSettings ? <ProfileForm /> : <SettingsLoadingSpinnerCss />}
    </>
  );
};
