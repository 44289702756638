import React, { lazy } from 'react';
import { Navigate, Outlet, generatePath, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { MinimizedModalsProvider } from 'components/Modal/output/MinimizedModalsProvider';
import { PATH, REQUESTS_TYPE } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useDateRangeFilter } from 'layouts/AuthorizedApp/AsideFilters/hooks/useDateRangeFilter';
import { ViewWithMinimizedModals } from 'state/modal';
import { languageSelector } from 'state/recoilState';
import { mapDatesToParams } from 'utils/mapDatesToParams/mapDatesToParams';

const LazyRequests = lazy(() =>
  import('../../Requests').then(({ Requests }) => ({
    default: Requests,
  })),
);

export const RequestsRenderer = () => {
  const language = useRecoilValue(languageSelector);
  const { type: requestType } = useParams();
  const { modulesManagement } = useAppPermissions();
  const { dates } = useDateRangeFilter();
  const dateParams = mapDatesToParams(dates);

  if (requestType === REQUESTS_TYPE.USAGE_OVERVIEW[language] && !modulesManagement.Requests)
    return (
      <Navigate
        to={generatePath(PATH.REQUESTS__GROUPED__START_DATE_UNIX__END_DATE_UNIX[language], dateParams)}
        relative="path"
      />
    );

  return (
    <>
      <React.Suspense fallback={<LoadingOverlay sx={{ zIndex: 'base' }} />}>
        <LazyRequests />
      </React.Suspense>
      <MinimizedModalsProvider forView={ViewWithMinimizedModals.REQUESTS} />
      {/* render children from path={`:type${DATE_RANGE_PARAMS}/*`} */}
      <Outlet />
    </>
  );
};
