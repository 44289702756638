import { SupportedLanguages } from 'constants/supportedLanguages';
import { IndustryCategory, IndustrySubcategory, TimeZoneListItem } from '../data/dataActions.types';
import {
  AiVariant,
  BetaFeatureDetails,
  NormalPayrollPeriod,
  OvertimePayrollPeriod,
  StartingWeekDay,
  WorkdayAvailabilityState,
} from '../organizationSession/organizationSessionActions.types';
import { DateFormat, NameDisplayOrder } from '../userSession/userSessionActions.types';

export type FetchScheduleSettingsResponse = {
  autoGenerateAiSchedules: boolean;
  useAiScheduling: boolean;
  defaultAiVariant: AiVariant;
  generateAiSchedules: boolean;
  showLaborCodeAlerts: boolean;
  showVersionControl: boolean;
  isWorkdayAvailabilityAvailable: boolean;
  workdayAvailabilityDefaultState: WorkdayAvailabilityState;
};

export type ScheduleSettingsActionProps = FetchScheduleSettingsResponse;

export type ScheduleSettingsResponse = null;

export type FetchNotificationSettingsResponse = {
  schedulesEmails: boolean;
  schedulesTextMessages: boolean;
  requestsEmails: boolean;
  requestsTextMessages: boolean;
  messagesEmails: boolean;
  // promotionsEmails: boolean; currently not used INEWI-4706
};

export type NotificationSettingsActionProps = FetchNotificationSettingsResponse;

export type NotificationSettingsResponse = null;

export type AdvancedSettingsCommonActionProps = {
  password: string;
};

type ActionWithEmployeesIdsProps = {
  employeesIds?: string[];
};

export type AdvancedSettingsCommonResponse = null;

export type AdvancedSettingsSaveBetaSettingsProps = BetaFeatureDetails;

export type AdvancedSettingsSaveBetaSettingsResponse = AdvancedSettingsCommonResponse;

export type DeleteEmployeesPhotosActionActionProps = AdvancedSettingsCommonActionProps & ActionWithEmployeesIdsProps;

export type DeleteEmployeesPhotosResponse = AdvancedSettingsCommonResponse;

export type DeleteEmployeesFaceModelsActionProps = AdvancedSettingsCommonActionProps & ActionWithEmployeesIdsProps;

export type DeleteEmployeesFaceModelsResponse = AdvancedSettingsCommonResponse;

export type AdvancedSettingsDeletePhotosActionProps = AdvancedSettingsCommonActionProps;

export type AdvancedSettingsDeletePhotosResponse = AdvancedSettingsCommonResponse;

export type AdvancedSettingsDeleteFaceModelsActionProps = AdvancedSettingsCommonActionProps;

export type AdvancedSettingsDeleteFaceModelsResponse = AdvancedSettingsCommonResponse;

export type AdvancedSettingsDeleteOrganizationActionProps = {
  password: string;
  reason: string;
};

export type AdvancedSettingsDeleteOrganizationResponse = AdvancedSettingsCommonResponse;

export type TimeRoundingSample = {
  isEnter: boolean;
  timeRealSeconds: number;
  timeRoundedSeconds: number;
};

export type FetchTimeTrackingSettingsResponse = {
  showPaidBreak: boolean;
  limitPaidBreak: boolean;
  paidBreakLimitSeconds?: number;
  showUnPaidBreak: boolean;
  restrictLocations: boolean;
  forceLocation: boolean;
  enableOfflineMode: boolean;
  sendEventPhotos: boolean;
  enableFaceVerification: boolean;
  enableBasicFraudDetection: boolean;
  enableAdvancedFraudDetection: boolean;
  minOvertimeSeconds: number;
  payrollPeriod: NormalPayrollPeriod;
  overtimePeriod: OvertimePayrollPeriod;
  typeOfWorktimeWithoutSchedule: WorktimeWithoutSchedule;
  countNightHours: boolean;
  nightHoursStartSeconds?: number;
  nightHoursDurationSeconds?: number;
};

export type TimeTrackingSettingsActionProps = Partial<FetchTimeTrackingSettingsResponse>;

export type TimeTrackingSettingsResponse = null;

export type FetchOrganizationSettingsResponse = {
  name: string;
  industry: IndustryCategory;
  industrySubcategory?: IndustrySubcategory;
  address: AddressResponse;
  countryCode: string;
  vatId: string;
  timeZoneId: number;
  startingWeekDay: StartingWeekDay;
  displayCurrency: string;
  holidays: YearHolidaysResponse;
  autoImportHolidaysEachYear: boolean;
};

export type BasicInformationSettingsActionProps = Pick<
  FetchOrganizationSettingsResponse,
  'name' | 'address' | 'countryCode' | 'vatId'
>;

export type PreferencesSettingsActionProps = Pick<
  FetchOrganizationSettingsResponse,
  'timeZoneId' | 'startingWeekDay' | 'displayCurrency'
>;

export type OrganizationHolidaysSettingsActionProps = Pick<
  FetchOrganizationSettingsResponse,
  'autoImportHolidaysEachYear'
>;

export type OrganizationSettingsActionProps = Omit<FetchOrganizationSettingsResponse, 'holidays'>;

export type OrganizationSettingsResponse = {
  newVat?: number;
};

export type OrganizationSettingsAddChangeAvatarActionProps = {
  avatar: Blob;
};

export type OrganizationSettingsAddChangeAvatarResponse = null;

export type OrganizationSettingsDeleteAvatarResponse = null;

type ClockInOutMagnet = {
  enable: boolean;
  hasHalfTheValueTolerance: boolean;
  value?: number;
  toleranceMinutes?: number;
  timeRoundingSamples: TimeRoundingSample[];
};

type TimeRounding = {
  enableTimeRounding: boolean;
  hasHalfTheRoundingValueTolerance: boolean;
  roundingValue?: number;
  clockInRoundingToleranceMinutes?: number;
  clockOutRoundingToleranceMinutes?: number;
  timeRoundingSamples: TimeRoundingSample[];
};

export type FetchAutomationsSettingsResponse = {
  autoBalanceOvertime: boolean;
  clockInMagnet: ClockInOutMagnet;
  clockOutMagnet: ClockInOutMagnet;
  timeRounding: TimeRounding;
};

type OmitClockInOutMagnet = Omit<ClockInOutMagnet, 'timeRoundingSamples'>;

type OmitTimeRounding = Omit<TimeRounding, 'timeRoundingSamples'>;

export type AutomationsSettingsActionProps = {
  autoBalanceOvertime: boolean;
  clockInMagnet: OmitClockInOutMagnet;
  clockOutMagnet: OmitClockInOutMagnet;
  timeRounding: OmitTimeRounding;
};

export type AutomationsSettingsResponse = null;

export type FetchRequestsSettingsResponse = {
  // TODO adjust with BE, to send a correct flag
  allowAutomaticApprovalReplacement: boolean;
  allowCarryOverUnUsedLimits: boolean;
  carriedOverLimitExpiresAfter: CarriedOverLimitExpiration;
  disableLocationRestrictionOnBusinessTrip: boolean;
  allowChangeRequestForLoggedClockInsAndOuts: boolean;
  allowRequestForBusinessTrips: boolean;
  allowRequestForSchedules: boolean;
  timeOffApprovalSettings: ApprovalSettingsResponse;
  timeTrackingApprovalSettings: ApprovalSettingsResponse;
  businessTripsApprovalSettings: ApprovalSettingsResponse;
  customRequestsApprovalSettings: ApprovalSettingsResponse;
  schedulesApprovalSettings: ApprovalSettingsResponse;
  hideDetailedRequestTypes: boolean;
};

export type RequestsSettingsActionProps = Partial<FetchRequestsSettingsResponse>;

export type RequestsTimeOffSettings = Pick<
  FetchRequestsSettingsResponse,
  'timeOffApprovalSettings' | 'allowCarryOverUnUsedLimits' | 'carriedOverLimitExpiresAfter'
>;

export type RequestsTimeTrackingSettings = Pick<
  FetchRequestsSettingsResponse,
  'allowChangeRequestForLoggedClockInsAndOuts' | 'timeTrackingApprovalSettings'
>;

export type RequestsBusinessTripSettings = Pick<
  FetchRequestsSettingsResponse,
  'allowRequestForBusinessTrips' | 'disableLocationRestrictionOnBusinessTrip' | 'businessTripsApprovalSettings'
>;

export type RequestsCustomSettings = Pick<FetchRequestsSettingsResponse, 'customRequestsApprovalSettings'>;

export type RequestsSettingsResponse = null;

export type AddressResponse = {
  postalCode?: string;
  city?: string;
  street?: string;
};

export type Holiday = {
  id: string;
  dateUnix: number;
  name: string;
};

type YearHolidaysResponse = [
  {
    year: number;
    holidays: HolidaysResponse;
  },
];

type HolidaysResponse = Holiday[];

type ApprovalSettingsResponse = {
  twoStepApprovalFlow: boolean;
  firstStepRoleId?: string;
  secondStepRoleId?: string;
};

export enum MagnetInterval {
  Minutes0 = 0,
  Minutes5 = 5,
  Minutes10 = 10,
  Minutes15 = 15,
  Minutes30 = 30,
  Minutes60 = 60,
}

export enum WorktimeWithoutSchedule {
  CountAsWorkTime = 0,
  CountAsOvertime = 1,
  CountSeparately = 2,
  DontCount = 3,
}

export enum CarriedOverLimitExpiration {
  Never = 0,
  Year1 = 1,
  Years2 = 2,
  Years3 = 3,
  Years4 = 4,
  Years5 = 5,
}

export type FetchUserNotificationsSettingsResponse = {
  schedulesEmails: boolean;
  schedulesTextMessages: boolean;
  requestsEmails: boolean;
  requestsTextMessages: boolean;
  messagesEmails: boolean;
  promotionsEmails: boolean;
};

export type UserNotificationsSettingsActionProps = Partial<FetchUserNotificationsSettingsResponse>;

export type UserNotificationsSettingsResponse = null;

export type UserChangePasswordSettingsActionProps = {
  currentPassword: string;
  newPassword: string;
};

export type UserChangePasswordSettingsResponse = null;

export type UserSetPasswordSettingsActionProps = {
  password: string;
};

export type UserSetPasswordSettingsResponse = null;

type Address = {
  street?: string;
  city?: string;
  postalCode?: string;
};

export type UserProfileSettingsActionProps = {
  firstName: string;
  surname: string;
  email: string;
  phoneNumber: string;
  address?: Address;
};

export type UserProfileSettingsResponse = null;

export type UserPreferences = {
  language: SupportedLanguages;
  timeZoneId: TimeZoneListItem['id'];
  nameDisplayOrder: NameDisplayOrder;
  dateFormat: DateFormat;
  is24HourFormat: boolean;
};

export type UserPreferencesActionProps = Partial<{
  language: SupportedLanguages;
  timeZoneId: TimeZoneListItem['id'];
  nameDisplayOrder: NameDisplayOrder;
  dateFormat: DateFormat;
  is24HourFormat: boolean;
}>;

export type UserPreferencesResponse = null;
export type FetchUserInfoResponse = {
  firstName: string;
  surname: string;
  email: string;
  phoneNumber?: string;
  address?: Address;
};

export type UserInfoActionProps = FetchUserInfoResponse;

export type UserInfoResponse = null;

export type UserInfoChangeAvatarActionProps = {
  avatar: Blob;
};

export type UserInfoChangeAvatarResponse = null;

export type UserInfoDeleteAvatarResponse = null;
