import _ from 'lodash';
import { useEffect } from 'react';

/**
 * Adds a event listener on the provided target.
 *
 * @param {string} option Event name that will be passed to targets addEventListener method.
 * @param {EventListenerOrEventListenerObject} callback
 * @param {null | HTMLElement | Window} target Target element, if null the listener will not be added, if undefined window object will be used.
 * @param {number | undefined} cleanupTimeoutMs If provided, the removeEventListener will be wrapped in seTimeout with provided value.
 *
 */

export const useEvent = (
  option: string,
  callback: EventListenerOrEventListenerObject,
  target: null | HTMLElement | Window | Document = window,
  cleanupTimeoutMs?: number,
): void => {
  useEffect(() => {
    if (target !== null && callback) {
      target.addEventListener(option, callback);
    }
    return () => {
      if (target !== null && callback) {
        if (_.isNumber(cleanupTimeoutMs)) {
          setTimeout(() => {
            target.removeEventListener(option, callback);
          }, cleanupTimeoutMs);
        } else {
          target.removeEventListener(option, callback);
        }
      }
    };
  }, [target, callback, option, cleanupTimeoutMs]);
};
