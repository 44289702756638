import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useMemo } from 'react';

import { Button } from 'components/ui/Buttons/Button';
import { IntegrationsConnectButtonProps } from '../../../../types';

import { ReactComponent as Quickbooks } from './C2QB_green_btn_short_default.svg';

type Props = IntegrationsConnectButtonProps;

export const Connect: FC<Props> = ({ isActive, isExpired, textVariant, ...props }) => {
  useLingui();

  const buttonText = useMemo(() => {
    switch (textVariant) {
      case 'configuration':
        return t({ id: 'configuration', message: 'Configuration' });
      case 'connect':
        return t({ id: 'connect', message: 'Connect' });
      case 'enable':
        return t({ id: 'enable', message: 'Enable' });
      case 'quickbooks':
        return <Quickbooks />;
      default:
        return '';
    }
  }, [textVariant]);

  if (isActive || isExpired) return null;

  return (
    <Button
      {...props}
      variant={textVariant === 'quickbooks' ? 'naked' : 'success'}
      size="sm"
      shape="rounded"
      {...(textVariant === 'quickbooks' && { sx: { p: 0 }, contentOpacityDuringLoading: 0.3 })}
    >
      {buttonText}
    </Button>
  );
};
