import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Input, Link, Text } from 'theme-ui';

import { MagnetInterval } from 'api/actions/settings/settingsActions.types';
import { Divider } from 'components/Divider/Divider';
import { DurationPicker } from 'components/ui/DurationPicker/DurationPicker';
import { Select } from 'components/ui/Select/Select';
import { Switch } from 'components/ui/Switch';
import { useHelpLink } from 'hooks/useHelpLink/useHelpLink';
import { roundToMinutesSelectOptionsSelector } from 'state/selectOptions';
import { clockOutMagnetTimeSamplesSelector } from 'state/settings';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { useTheme } from 'styles/useTheme';
import { useFormContextCustomEvent } from '../../../../../../../hooks/useFormContextCustomEvent';
import { LabelWithBadge } from '../../../../../../LabelWithBadge';
import { OptionLabel } from '../../../../../../OptionLabel';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';
import { wrapperLinkSx } from '../../../../../../styles/wrappers';
import { useAutomationsSettings } from '../../../../../hooks/useAutomationsSettings';

import { PlannedTimeSamples } from './PlannedTimeSamples';

export const PlannedEndTime = (): React.ReactElement => {
  useLingui();

  const roundToMinutesSelectOptions = useRecoilValue(roundToMinutesSelectOptionsSelector);
  const clockOutTimeSamples = useRecoilValue(clockOutMagnetTimeSamplesSelector);

  const { optionsMinutes } = useAutomationsSettings();
  const { theme } = useTheme();

  const plannedEndTimeLink = useHelpLink({
    inEwi: {
      pl: '/article/przyciaganie-zdarzen-oraz-interwaly-1kqo0de/',
    },
    unrubble: {
      en: '/article/time-snapping-138entx/',
    },
  });

  const {
    registerOnBlur,
    registerOnChange,
    formState: { errors },
    setValue,
    watch,
  } = useFormContextCustomEvent<{
    clockOutMagnet: {
      value: number;
      enable: boolean;
      hasHalfTheValueTolerance: boolean;
      toleranceMinutes: number;
    };
  }>();

  const clockOutEnableWatch = watch('clockOutMagnet.enable');
  const timeWindowWatch = watch('clockOutMagnet.value') as MagnetInterval;
  const halfValueToleranceWatch = watch('clockOutMagnet.hasHalfTheValueTolerance');
  const clockOutToleranceWatch = watch('clockOutMagnet.toleranceMinutes');

  const validateMagnetsValues = useCallback(
    (roundingMinutes: number, clockOut?: number) => {
      const roundingSeconds = roundingMinutes * 60;
      const newValidationValue = roundingSeconds;

      if (clockOut && clockOut > roundingSeconds) {
        setValue('clockOutMagnet.toleranceMinutes', newValidationValue);
      }
    },
    [setValue],
  );

  useEffect(() => {
    validateMagnetsValues(timeWindowWatch, clockOutToleranceWatch);
  }, [clockOutToleranceWatch, timeWindowWatch, validateMagnetsValues]);

  const toleranceQuickSelectOptions = useMemo(() => {
    const filteredOptionsMinutes = optionsMinutes.filter((item) => item <= timeWindowWatch);

    return filteredOptionsMinutes.map((item) => item * 60);
  }, [optionsMinutes, timeWindowWatch]);

  return (
    <SettingsGroup.Body>
      <SettingsGroup.Body.Visible
        variant="md"
        pb={clockOutEnableWatch ? 2 : '0.75rem'}
        withoutBorderBottom={clockOutEnableWatch}
      >
        <Switch
          {...registerOnChange('clockOutMagnet.enable')}
          label={
            <LabelWithBadge
              label={t({
                id: 'automations_settings.shifts_planned_end_time.label',
                message: 'Shift’s planned end time',
              })}
              badgeText={t({ id: 'settings.scheduling' })}
              tooltipText={t({ id: 'settings.tooltip_scheduling' })}
            />
          }
          additionalInfo={
            <Text>
              <Trans id="automations_settings.shifts_planned_end_time.additional_info">
                Whenever employees clock out earlier or later than planned and within the specified time window, we will
                automatically set the time to the planned end time.
              </Trans>{' '}
              <Link href={plannedEndTimeLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                {t({ id: 'settings.learn_more' })}
              </Link>
              .
            </Text>
          }
          size="sm"
          bold
        />
      </SettingsGroup.Body.Visible>
      {clockOutEnableWatch && (
        <>
          <SettingsGroup.Body.Hidden variant="md" sx={{ py: 2 }}>
            <OptionLabel
              label={t({
                id: 'automations_settings.time_window.label',
              })}
              apendWith={
                <Select
                  {...registerOnBlur('clockOutMagnet.value', {
                    valueAsNumber: true,
                    required: t({ id: 'global.forms.required.short' }),
                  })}
                  id="snapTime"
                  size="sm"
                  options={roundToMinutesSelectOptions}
                  error={!!errors?.clockOutMagnet?.value}
                  errorMessage={errors?.clockOutMagnet?.value?.message}
                  sx={{ minWidth: '125px' }}
                  customContent={
                    <>
                      <Input
                        name="minutes"
                        value={timeWindowWatch}
                        sx={{
                          ...theme.forms.timePicker.twoDigitInput.sm,
                          pointerEvents: 'none',
                        }}
                        readOnly
                      />
                      <Text sx={{ ml: 1, mr: 2 }}>m</Text>
                    </>
                  }
                />
              }
              withDivider
            />
            <Switch
              {...registerOnChange('clockOutMagnet.hasHalfTheValueTolerance')}
              label={t({
                id: 'automations_settings.half_value_tolerance',
              })}
              size="sm"
              bold
              sx={{ py: SETTINGS_SPACE.automationsSwitchWithLabel }}
            />

            {!halfValueToleranceWatch && (
              <>
                <Divider />
                <OptionLabel
                  label={t({
                    id: 'automations_settings.early_exit_tolerance',
                    message: 'Early exit tolerance',
                  })}
                  apendWith={
                    <DurationPicker
                      {...registerOnBlur('clockOutMagnet.toleranceMinutes', {
                        valueAsNumber: true,
                        required: t({ id: 'global.forms.required.short' }),
                      })}
                      id="earlyExitTolerance"
                      size="sm"
                      quickSelectOptions={toleranceQuickSelectOptions}
                      inputVariant="minutes"
                      hours={false}
                      seconds={false}
                      error={!!errors?.clockOutMagnet?.toleranceMinutes}
                      errorMessage={errors?.clockOutMagnet?.toleranceMinutes?.message}
                      sortingOrder="asc"
                      sx={{ minWidth: '125px' }}
                    />
                  }
                />
              </>
            )}
          </SettingsGroup.Body.Hidden>

          <SettingsGroup.Body.Visible
            variant="md"
            pt={3}
            pb={3}
            sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, flexGrow: 1 }}
          >
            <PlannedTimeSamples variant="clocksOut" timeSamples={clockOutTimeSamples} />
          </SettingsGroup.Body.Visible>
        </>
      )}
    </SettingsGroup.Body>
  );
};
