import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from 'theme-ui';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { AddRequestExtendedFormContext } from '../../../../../types';
import { useAddRequest } from '../../../hooks/useAddRequest';

import { AddRequestMainLayout } from './AddRequestMainLayout/AddRequestMainLayout';

type Props = {
  children: React.ReactElement | React.ReactElement[];
};

export const AddRequestFormMain = ({ children }: Props): React.ReactElement => {
  useLingui();
  const {
    formState: { mainFormVisible },
    selectedTeammatesIds,
    modifyRequest: { modifyRequestData },
    requestToEdit: { selectedRequestToEdit },
  } = useAddRequest();

  const { watch } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  const formVisible = useMemo(() => {
    if (actionTypeWatch === RequestActionType.Create) return true;

    if (actionTypeWatch === RequestActionType.Swap) return true;

    if (selectedTeammatesIds.length === 1) return true;

    return false;
  }, [actionTypeWatch, selectedTeammatesIds]);

  const isMainFormVisible = useMemo(() => {
    if (
      (actionTypeWatch === RequestActionType.Create || actionTypeWatch === RequestActionType.Swap) &&
      mainFormVisible
    ) {
      return true;
    }
    if (selectedRequestToEdit || modifyRequestData) {
      return true;
    }

    return false;
  }, [actionTypeWatch, selectedRequestToEdit, mainFormVisible, modifyRequestData]);

  return (
    <AddRequestMainLayout>
      <AddRequestMainLayout.SelectType />
      {formVisible ? (
        <>
          {children}
          {isMainFormVisible && (
            <AddRequestMainLayout.InnerContainer>
              <AddRequestMainLayout.Reason />
              <AddRequestMainLayout.AcceptationCheckbox />
              <AddRequestMainLayout.ProcessedSelects />
              <AddRequestMainLayout.Attachments />
              <AddRequestMainLayout.Note />
            </AddRequestMainLayout.InnerContainer>
          )}
        </>
      ) : (
        <Text sx={{ fontWeight: 'bold', color: 'requests.error', mt: -3 }}>
          {t({ id: `requests.add_request.${actionTypeWatch === RequestActionType.Edit ? 'edit' : 'delete'}_error` })}
        </Text>
      )}
    </AddRequestMainLayout>
  );
};
