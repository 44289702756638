import { t } from '@lingui/macro';
import React, { useCallback } from 'react';
import { ThemeUICSSObject } from 'theme-ui';

import { Tag } from 'components/Tag/Tag';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { TagsSelectOption } from 'state/team';
import { Select, SelectProps } from '../Select';
import { OptionProps } from '../elements';

import { ColorSquare, ContentWrapper } from './elements';

type TagSelectProps = SelectProps<TagsSelectOption>;

export const TagsSelect = React.forwardRef<HTMLInputElement, TagSelectProps>(
  ({ ...props }: TagSelectProps, ref): React.ReactElement => {
    const selectedMultiOptionSxGenerator = useCallback(
      ({ color, isInformational }: TagsSelectOption): ThemeUICSSObject =>
        isInformational
          ? {
              color: UserSelectableColor[color],
              bg: 'select.selectOption.background.informational',
              borderRadius: 0,
              border: '1px solid',
            }
          : {
              bg: UserSelectableColor[color],
              color: 'select.selectOption.text.multi',
            },
      [],
    );

    const optionContentRenderer = useCallback(
      ({ label, isInformational, color }: TagsSelectOption) => (
        <ContentWrapper>
          <Tag variant={isInformational ? 'informational' : 'default'} color={UserSelectableColor[color]}>
            {label}
          </Tag>
        </ContentWrapper>
      ),
      [],
    );

    const optionPropsGenerator = useCallback(
      ({ label, isInformational, color }: TagsSelectOption): Partial<OptionProps> => ({
        contentRenderer: () => (
          <ContentWrapper>
            <ColorSquare color={color} fillType={isInformational ? 'border' : 'pill'} />
            {label}
          </ContentWrapper>
        ),
      }),
      [],
    );

    return (
      <Select
        ref={ref}
        placeholder={t({ id: 'team.select_tags', message: 'Select tags...' })}
        {...props}
        selectedMultiOptionSxGenerator={selectedMultiOptionSxGenerator}
        customValueDisplayRenderer={optionContentRenderer}
        optionPropsGenerator={optionPropsGenerator}
      />
    );
  },
);
