import React from 'react';

import { ChatProvider } from 'base/Chat/output/ChatProvider';
import { NotificationProvider } from 'base/Notification/output/NotificationProvider';
import { DialogModalProvider } from 'context/dialogModal/DialogModalProvider';
import { OrganizationSessionInitializer } from 'context/session/organizationSessionInitializer/OrganizationSessionInitializer';
import { SignalRProvider } from 'context/signalR/SignalRProvider';
import { AuthorizedAppLayout } from 'layouts/AuthorizedApp';
import { AlertProvider } from 'layouts/AuthorizedApp/AlertContainer/AlertProvider';

type Props = {
  children: React.ReactElement[];
};

export const AuthorizedAppContext = ({ children }: Props): React.ReactElement => (
  <OrganizationSessionInitializer>
    <SignalRProvider />
    <ChatProvider />
    <NotificationProvider />
    <DialogModalProvider />
    <AuthorizedAppLayout>
      <AlertProvider isGlobal>{children}</AlertProvider>
    </AuthorizedAppLayout>
  </OrganizationSessionInitializer>
);
