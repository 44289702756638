import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useMinimizeLocationState } from 'components/Modal/output/useMinimizeLocationState';
import { useMinimizedModals } from 'components/Modal/output/useMinimizedModals';
import { useModal } from 'components/Modal/output/useModal';
import { TO_REL } from 'constants/routes';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { FilterGroupNames } from 'layouts/AuthorizedApp/AsideFilters/types';
import { parsedEmployeesSelector } from 'state/employees';
import { SEARCH_FILTER_TYPE, filterGroupStateAtomFamily, searchFilterValueSelectorFamily } from 'state/filters';
import { MinimizeModalAddRequest, MinimizedModal, ViewWithMinimizedModals } from 'state/modal';
import { getRequestFormValuesAtom } from 'state/requests';
import { delay } from 'utils/delay';

import { useAddRequest } from './useAddRequest';

export const useMinimizeModalAddRequest = () => {
  useLingui();
  const getRequestFormValues = useRecoilValue(getRequestFormValuesAtom);
  const [teammatesFilterState, setTeammatesFilterState] = useRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES_PICK_TEAMMATES_FILTERS),
  );
  const [tagsFilterState, setTagsFilterState] = useRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.TAGS_PICK_TEAMMATES_FILTERS),
  );
  const [rolesFilterState, setRolesFilterState] = useRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.ROLES_PICK_TEAMMATES_FILTERS),
  );
  const [searchInputState, setSearchInputState] = useRecoilState(
    searchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.TEAMMATES_PICK_TEAMMATES_FILTERS),
  );
  const { isCalendar, isReportsAttendanceList, isReportsTimesheets } = useAppRouting([
    'isCalendar',
    'isReportsAttendanceList',
    'isReportsTimesheets',
  ]);
  const parsedEmployees = useRecoilValue(parsedEmployeesSelector);
  const { state } = useLocation();
  const { isWizard } = state || {};
  const minimizedModalDetails = useMinimizeLocationState<MinimizeModalAddRequest>();

  const minimizedView = useMemo(() => {
    if (isCalendar) {
      return ViewWithMinimizedModals.CALENDAR;
    }

    if (isReportsAttendanceList) {
      return ViewWithMinimizedModals.ATTENDANCE;
    }

    if (isReportsTimesheets) {
      return ViewWithMinimizedModals.TIMESHEETS;
    }

    return ViewWithMinimizedModals.REQUESTS;
  }, [isCalendar, isReportsAttendanceList, isReportsTimesheets]);

  const {
    formState: { noteVisible, attachmentsVisible },
    addRequestState: { step, requestType },
    requestToEdit: { selectedRequestToEditId, setSelectedRequestToEditId },
    selectedTeammatesIds,
    setSelectedTeammatesIds,
    setAddRequestState,
    setFormState,
  } = useAddRequest();

  const { handleSetModalDetails, onHandleCloseModalWithMinimizedDetails } =
    useMinimizedModals<MinimizeModalAddRequest>(minimizedView);

  const onHandleMinimize = useCallback(
    async (minimizeModal: () => void) => {
      await delay(200);
      const formState = getRequestFormValues ? _.cloneDeep(getRequestFormValues()) : undefined;

      handleSetModalDetails({
        minimizedModal: isWizard ? MinimizedModal.REQUEST_WIZARD : MinimizedModal.ADD_REQUESTS,
        route: TO_REL.ADD_REQUEST_MODAL,
        title: isWizard ? t({ id: 'request.wizard' }) : t({ id: 'requests.add_request' }),
        modalDetails: {
          employees: selectedTeammatesIds,
          step,
          requestType,
          formState,
          requestToEdit: selectedRequestToEditId || undefined,
          noteVisible,
          attachmentsVisible,
          filterState: {
            teammatesFilterState,
            rolesFilterState,
            tagsFilterState,
            searchInputState,
          },
        },
        routeState: state,
        employeesToValidate: selectedTeammatesIds,
      });

      minimizeModal();
    },
    [
      getRequestFormValues,
      handleSetModalDetails,
      isWizard,
      selectedTeammatesIds,
      step,
      requestType,
      selectedRequestToEditId,
      noteVisible,
      attachmentsVisible,
      teammatesFilterState,
      rolesFilterState,
      tagsFilterState,
      searchInputState,
      state,
    ],
  );

  useModal({ onHandleMinimize, onHandleClose: onHandleCloseModalWithMinimizedDetails });

  const restoreMinimizedState = useCallbackRef(() => {
    if (!minimizedModalDetails || !parsedEmployees) return;

    const {
      employees,
      step: restoredStep,
      requestType: restoredRequestType,
      requestToEdit,
      noteVisible: restoredNoteVisible,
      attachmentsVisible: restoredAttachmentsVisible,
      filterState,
    } = minimizedModalDetails.modalDetails;

    const filteredEmployees = employees.filter((id) => parsedEmployees.has(id));

    setSelectedTeammatesIds(filteredEmployees);
    setAddRequestState((prevState) => ({ ...prevState, step: restoredStep, requestType: restoredRequestType }));
    setFormState((prevState) => ({
      ...prevState,
      noteVisible: restoredNoteVisible || false,
      attachmentsVisible: restoredAttachmentsVisible || false,
    }));
    setTeammatesFilterState(filterState.teammatesFilterState);
    setRolesFilterState(filterState.rolesFilterState);
    setTagsFilterState(filterState.tagsFilterState);
    setSearchInputState(filterState.searchInputState);

    if (requestToEdit) {
      setSelectedRequestToEditId(requestToEdit);
    }
  });

  useEffect(() => {
    restoreMinimizedState.current();
  }, [restoreMinimizedState]);
};
