/* eslint-disable no-console */
import { FaceDetector } from '@tensorflow-models/face-detection';
import { PixelInput } from '@tensorflow-models/face-detection/dist/shared/calculators/interfaces/common_interfaces';
import '@tensorflow/tfjs-backend-cpu';
import '@tensorflow/tfjs-backend-webgl';
import React, { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Stats from 'stats.js';

import { usePromisify } from 'hooks/usePromisify/usePromisify';
import { createDetector } from 'Kiosk/models/face-detection';
import { cameraStateAtom } from 'Kiosk/state/cameraState';
import { faceDetectionModelAtom } from 'Kiosk/state/faceDetectionState';
import { debugModeSelector } from 'Kiosk/state/debugState';

// import { MediaPipePrediction } from '@tensorflow-models/face-landmarks-detection/dist/types';
// import { setWasmPaths } from '@tensorflow/tfjs-backend-wasm';
// import wasmSimdPath from '@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm-simd.wasm';
// import wasmSimdThreadedPath from '@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm-threaded-simd.wasm';
// import wasmPath from '@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm.wasm';

let stats: Stats;
let det: FaceDetector;

let debug = false;
let loadModel = false;

const createStats = () => {
  stats = new Stats();
  stats.showPanel(0);
  const statsElement = document.getElementById('statsJs');

  if (document.getElementById('statsJs')) {
    document.getElementById('app')?.removeChild(statsElement as Node);
  }

  document.getElementById('app')?.appendChild(stats.dom).setAttribute('id', 'statsJs');
};

const estimateFaces = async (source: PixelInput) => {
  if (!det || !source) {
    throw new Error('There is no source or detector.');
  }

  if (debug) {
    stats.begin();
  }

  const prediction = await det.estimateFaces(source, { flipHorizontal: false });

  if (debug) {
    stats.end();
  }

  return prediction;
};

const initializeDetector = async () => {
  const detector = await createDetector();

  det = detector;

  return detector;
};

type Props = {
  children: React.ReactNode | React.ReactNode[];
  load: boolean;
};

export const FaceDetectionProvider = ({ children, load }: Props): React.ReactElement => {
  const debugMode = useRecoilValue(debugModeSelector);
  const [, setState] = useRecoilState(faceDetectionModelAtom);
  const { source, isCameraStreaming } = useRecoilValue(cameraStateAtom);

  const [promisifyIsCameraReady, resolveIsCameraReady] = usePromisify<boolean>();

  useEffect(() => {
    if (source?.height !== 0) {
      resolveIsCameraReady(true);
    }
  }, [source, resolveIsCameraReady]);

  const warpUpModel = useCallback(
    async (detector: FaceDetector) => {
      if (!source || !detector) return;

      let isCameraReady: boolean | null = source.height !== 0;

      if (!isCameraReady) {
        isCameraReady = await promisifyIsCameraReady(7000);
      }

      if (!isCameraReady) {
        console.log('NEW MODEL WARMUP - detector error: ', 'source has no height!');
        return;
      }

      await detector
        .estimateFaces(source.video, { flipHorizontal: false })
        .then((res) => console.log('FaceDetectionProvider - NEW MODEL', '[MODEL WARMUP]', res))
        .catch((err) => {
          detector.dispose();
          console.log('NEW MODEL WARMUP - detector error: ', err);
          throw new Error(err as string);
        });

      console.log('detector after creation: ', detector);
    },
    [source, promisifyIsCameraReady],
  );

  if (!debug && debugMode) {
    debug = debugMode;
    createStats();
  }

  useEffect(() => {
    const initialize = async () => {
      const detector = await initializeDetector();
      console.log('FaceDetectionProvider', '[MODEL INITIALIZED]');
      await warpUpModel(detector);
    };

    if (!loadModel && load && isCameraStreaming) {
      void initialize()
        .then(() => {
          loadModel = load;
          setState({
            isModelLoaded: true,
            getPrediction: estimateFaces,
          });
        })
        .catch(() => {
          console.log('FaceDetectionProvider - estimateFaces Error', '[MODEL RE-INITIALIZATION]');
          void initialize();
        });
    }
  }, [isCameraStreaming, load, setState, warpUpModel]);

  return <>{children}</>;
};
