import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { CustomEvents } from 'utils/customEvents';

export const UpdateCaledarObserver: React.ElementType = () => {
  const { updateCalendar, calendarInitialized } = useRefreshCalendar();

  useCustomEventListener(calendarInitialized ? CustomEvents.UPDATE_CALENDAR : null, updateCalendar);

  return null;
};
