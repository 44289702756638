/**
 * Creates a string in time span format '00:00:00' based on the passed time in second value.
 *
 * @param {number} time Time in seconds.
 *
 * @return {string} Returns the created string ex. '00:00:00'.
 */

export const stopwatchTimeFormat = (time: number): string => {
  if (!time || time < 0) return '00:00:00';
  const getSeconds = `0${time % 60}`.slice(-2);
  const getMinutes = `0${Math.floor(time / 60) % 60}`.slice(-2);
  const getHours = `0${Math.floor(time / 3600)}`.slice(-2);

  return `${getHours}:${getMinutes}:${getSeconds}`;
};
