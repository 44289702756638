import { atom, selector } from 'recoil';

import { GetSettingsResponse } from 'api/actions/timeclock/timeclockActions.types';
import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';
import {
  TimeTrackingSettings,
  OrganizationDetails,
} from 'api/actions/organizationSession/organizationSessionActions.types';

export type KioskSettingsProps = GetSettingsResponse &
  Pick<
    TimeTrackingSettings,
    | 'isLocationRequired'
    | 'isBasicFraudDetectionRequired'
    | 'isAdvancedFraudDetectionRequired'
    | 'isFaceRecognitionRequired'
  > & {
    organizationName: OrganizationDetails['name'];
    isCameraRequired: TimeTrackingSettings['isPhotoRecognitionRequired'];
  };

export const settingsAtom = atom<KioskSettingsProps | null>({
  key: 'kiosk__settings',
  default: null,
});

type mutableSelectorProps = Pick<
  KioskSettingsProps,
  'summaryTimeSeconds' | 'sleepModeTimeOutMinutes' | 'isSleepModeEnabled'
> | null;

export const mutableSettingsSelector = selector<mutableSelectorProps>({
  key: 'kiosk__mutableSettingsSelector',
  get: ({ get }) => {
    const kioskSettings = get(settingsAtom);

    if (kioskSettings) {
      const { summaryTimeSeconds, sleepModeTimeOutMinutes, isSleepModeEnabled } = kioskSettings;

      return {
        summaryTimeSeconds,
        sleepModeTimeOutMinutes,
        isSleepModeEnabled,
      };
    }

    return null;
  },
  set: ({ get, set }, newSettings) => {
    const kioskSettings = get(settingsAtom);

    if (kioskSettings) {
      const { sleepModeTimeOutMinutes, isSleepModeEnabled, summaryTimeSeconds } = kioskSettings;
      const currentSettings = {
        sleepModeTimeOutMinutes,
        isSleepModeEnabled,
        summaryTimeSeconds,
      };

      if (newSettings !== currentSettings && !isRecoilDefaultValue(newSettings)) {
        set(settingsAtom, {
          ...kioskSettings,
          ...newSettings,
        });
      }
    }
  },
});
