import { t, Trans } from '@lingui/macro';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Text } from 'theme-ui';

import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';

type Props = {
  message: string;
  formId: string;
};

export const ConfirmDeleteAdvancedModal = ({ message, formId }: Props): React.ReactElement => {
  const { state } = useLocation();

  const preventClose = !!state?.preventClose;

  const { baseRoute, handleClose } = useModal();

  if (!preventClose) {
    return <Navigate to={baseRoute} relative="path" />;
  }

  return (
    <ConfirmModal
      contentRenderer={() => (
        <Text as="p" sx={{ fontSize: 2 }}>
          {message}{' '}
          <Text as="span" sx={{ fontWeight: 'bold' }}>
            <Trans id="action_cannot_be_reversed">This action cannot be reversed.</Trans>
          </Text>
        </Text>
      )}
      titleRenderer={() => <Trans id="settings.delete_organization_confim_title">Confirm Deletion</Trans>}
      footerRenderer={({ confirmed }) => (
        <BasicModalFooter
          buttons={[
            {
              variant: 'danger',
              children: t({ id: 'global.delete' }),
              form: formId,
              type: 'submit',
              onClick: handleClose,
              disabled: !confirmed,
            },
          ]}
        />
      )}
      withConfirmation
    />
  );
};
