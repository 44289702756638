import { Trans, t } from '@lingui/macro';
import _ from 'lodash';
import React, { CSSProperties, ReactElement, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, FixedSizeListProps, ListChildComponentProps } from 'react-window';
import { useSetRecoilState } from 'recoil';
import { Box, Flex, InputProps, ThemeUICSSObject, ThemeUIStyleObject } from 'theme-ui';
import { v1 as uuidv1 } from 'uuid';

import { Divider } from 'components/Divider/Divider';
import { Icon } from 'components/Icon/Icon';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useForceUpdate } from 'hooks/useForceUpdate/useForceUpdate';
import { useIsMountedRef } from 'hooks/useIsMountedRef/useIsMountedRef';
import { useMemoCompare } from 'hooks/useMemoCompare/useMemoCompare';
import { useOnKeyboardEventQueue } from 'hooks/useOnKeyboardEventQueue/useOnKeyboardEventQueue';
import { InputOwnValue, useOnOwnValueChange } from 'hooks/useOnOwnValueChange/useOnOwnValueChange';
import { useOnUpdateFieldView } from 'hooks/useOnUpdateFieldView/useOnUpdateFieldView';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { optionAriaSelectedIndexAtomFamily, optionAriaSelectedSelectorFamily } from 'state/select';
import { useTheme } from 'styles/useTheme';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { emitUpdateFieldView } from 'utils/emitUpdateFieldView';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { OptionsSearchFactoryConfig, optionsSearchFactory } from 'utils/fuzzySearch';
import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { withPopper } from '../PopperProvider/withPopper';
import { TextInput, TextInputProps } from '../TextInput';

import { OPTION_HEIGHT, OPTION_LIST_GAP, OPTION_LIST_PADDING } from './constants';
import { Option, OptionList, OptionProps, SelectedMultiOption } from './elements';

const FlexWithPopper = withPopper(Flex);

type InnerElementProps = {
  style: CSSProperties;
};

const InnerElementType = React.forwardRef<HTMLUListElement, InnerElementProps>(
  (innerElementProps: InnerElementProps, innerElementRef) => (
    <ul role="listbox" ref={innerElementRef} {...innerElementProps} />
  ),
);

export type InputOption = {
  label: string;
  id: string;
  isCreatable?: boolean;
  sx?: ThemeUICSSObject;
  appendWithDivider?: boolean;
  disabled?: boolean;
  tooltip?: string;
};

type Renderer<Option extends InputOption = InputOption> = (option: Option) => ReactElement | string | null;

type Props<Option extends InputOption = InputOption> = Omit<TextInputProps, 'type'> & {
  options: Option[] | null;
  searchable?: boolean;
  // creatable will not work when virtualizeOptions
  creatable?: boolean; // select is not prepered for receiving ids of options created out of its instance lifecycle
  multi?: boolean;
  loadingOptions?: boolean;
  alwaysHideOptions?: boolean;
  ignoreOptions?: boolean;
  ignoreHiddenInputFocus?: boolean;
  checkValueForNonexistentIds?: boolean; // on option changes the hidden input value will be filtered and nonexistente options ids will be removed
  virtualizeOptions?: boolean; // FIXME: when searchable && virtualizeOptions first click on the dropdown options is not
  fixedSizeListProps?: Partial<FixedSizeListProps>;
  showSelectedOptionsFirst?: boolean; // this is default behaviour for multi prop
  updateViewOnOptionsChange?: boolean;
  sx?: ThemeUIStyleObject;
  optionsSearchFactoryConfig?: OptionsSearchFactoryConfig;
  onCreate?: (createdOption: Option) => void;
  onUpdateFieldView?: (value: string) => void;
  customValueDisplayRenderer?: Renderer<Option>;
  selectedMultiOptionSxGenerator?: (selectedOption: Option) => ThemeUIStyleObject;
  optionPropsGenerator?: (option: Option, active?: boolean) => Partial<OptionProps>;
};

export type SelectProps<Option extends InputOption = InputOption> = Props<Option>;

const defaultProps: Partial<Props> = {
  onUpdateFieldView: undefined,
  onCreate: undefined,
  creatable: false,
  searchable: false,
  alwaysHideOptions: false,
  ignoreHiddenInputFocus: false,
  checkValueForNonexistentIds: false,
  virtualizeOptions: false,
  multi: false,
  loadingOptions: false,
  ignoreOptions: false,
  fixedSizeListProps: undefined,
  showSelectedOptionsFirst: false,
  sx: undefined,
  customValueDisplayRenderer: undefined,
  selectedMultiOptionSxGenerator: undefined,
  optionPropsGenerator: undefined,
  optionsSearchFactoryConfig: undefined,
};

const scrollToSee = (el: HTMLLIElement) => {
  try {
    el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  } catch (error) {
    // Safari fallback
    el.scrollIntoView(true);
  }
};

function SelectInner<Option extends InputOption = InputOption>(
  {
    id,
    onChange,
    options: optionsRaw,
    creatable = false,
    searchable = false,
    alwaysHideOptions = false,
    multi = false,
    ignoreOptions = false,
    loadingOptions,
    ignoreHiddenInputFocus,
    checkValueForNonexistentIds,
    virtualizeOptions,
    fixedSizeListProps,
    sx,
    onCreate,
    onUpdateFieldView,
    onBlur,
    name,
    value,
    size = 'default',
    defaultValue,
    onFocus,
    customValueDisplayRenderer,
    selectedMultiOptionSxGenerator,
    optionPropsGenerator,
    placeholder,
    showSelectedOptionsFirst,
    optionsSearchFactoryConfig,
    updateViewOnOptionsChange,
    ...props
  }: Props<Option>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const selectInstanceIdRef = useRef(uuidv1());
  const forceUpdate = useForceUpdate();
  const [showOptions, setShowOptions] = useState(false);
  const prevShowOptions = usePrevious(showOptions);
  const [isSearching, setIsSearching] = useState(false);
  const [optionsFilter, setOptionsFilter] = useState<string | undefined>(undefined);
  const [inputValue, setInputValue] = useState<Option[] | Option | null>(null);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const setFocusIndex = useSetRecoilState(optionAriaSelectedIndexAtomFamily(selectInstanceIdRef.current));

  const createdOptionsRef = useRef<Option[]>([]);

  const options = useMemoCompare(optionsRaw);

  const selectedOption = useMemo(() => {
    if (!inputValue) return undefined;
    if (Array.isArray(inputValue)) {
      return inputValue.length ? inputValue[0] : undefined;
    }
    return inputValue;
  }, [inputValue]);

  const { theme } = useTheme();

  const isMountedRef = useIsMountedRef();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const hiddenRef = useRef<HTMLInputElement | null>(null);
  const optionsListRef = useRef<HTMLDivElement | null>(null);
  const fixedSizeListRef = useRef<FixedSizeList | null>(null);

  const optionsToRenderRef = useRef<Option[] | null>(null);

  const hiddenInputValueRef = useRef<string>('');

  const isFocusRef = useRef<boolean>(false);

  const selectedOptionsRef = useRef<InputProps['value'][]>([]);

  const focusedOptionIndexRef = useRef<number | null>(null);

  const optionsSearchConfig = useMemoCompare(optionsSearchFactoryConfig);

  const fuzzySearch = useMemo(() => {
    if (!options || !searchable) return null;
    return optionsSearchFactory(options, optionsSearchConfig);
  }, [options, optionsSearchConfig, searchable]);

  useEffect(() => {
    if (updateViewOnOptionsChange && options) {
      const updateSelectView = async () => {
        await delay(0);
        emitUpdateFieldView(hiddenRef);
      };
      void updateSelectView();
    }
  }, [options, updateViewOnOptionsChange]);

  const resetFocusedOptionIndex = useCallback(() => {
    focusedOptionIndexRef.current = null;
    setFocusIndex(null);
  }, [setFocusIndex]);

  const { fontSize } = useMemo(() => theme.forms.input.sizes[size], [size, theme.forms.input.sizes]);

  const onOutsideClick = useCallback(() => {
    setShowOptions(false);
  }, []);

  const onClick = () => {
    if (!props.disabled) setShowOptions(!showOptions);
  };

  const onMouseDown = async () => {
    await delay(0);
    isFocusRef.current = true;
  };

  const dispatchBlurEvent = useCallback(() => {
    const blurEvent = createEvent('focusout');
    if (hiddenRef.current) hiddenRef.current.dispatchEvent(blurEvent);
  }, []);

  const setSelectedOptions = useCallback(
    (option?: Option) => {
      if (!option) {
        selectedOptionsRef.current = [];
        setNativeValue(hiddenRef, '');
        dispatchBlurEvent();
        return;
      }

      if (option.isCreatable) {
        const createdOptions = createdOptionsRef.current;
        createdOptionsRef.current = _.chain([option, ...createdOptions])
          .uniqBy((op) => op.id)
          .sortBy((op) => op.label)
          .value();
      }

      if (multi) {
        if (!selectedOptionsRef.current.includes(option.id)) {
          selectedOptionsRef.current.push(option.id);
        }
        setNativeValue(hiddenRef, selectedOptionsRef.current);
        return;
      }
      if (!customValueDisplayRenderer) {
        setSearchInputValue(option.label);
      }
      selectedOptionsRef.current.pop();
      selectedOptionsRef.current.push(option.id);
      setNativeValue(hiddenRef, option.id);
    },
    [multi, customValueDisplayRenderer, dispatchBlurEvent],
  );

  const unselectOption = useCallback(
    (option: Option) => {
      if (!selectedOptionsRef.current.includes(option.id)) return;

      const filteredSelectedOptions = selectedOptionsRef.current.filter((optionId) => optionId !== option.id);
      selectedOptionsRef.current = filteredSelectedOptions;

      const newHiddenValue = filteredSelectedOptions.length ? filteredSelectedOptions : '';
      setNativeValue(hiddenRef, newHiddenValue);

      let newInputValue: typeof inputValue;
      if (Array.isArray(inputValue)) {
        newInputValue = inputValue.filter((selectedOp) => selectedOp.id !== option.id);
        setInputValue(newInputValue);
      } else if (!inputValue) {
        newInputValue = [];
        setInputValue(newInputValue);
      }
    },
    [inputValue],
  );

  const onOptionClick = useCallback(
    (option: Option) => {
      if (multi) {
        if (selectedOptionsRef.current.includes(option.id)) {
          unselectOption(option);
        } else {
          setSelectedOptions(option);
          let newInputValue: typeof inputValue;
          if (Array.isArray(inputValue)) {
            newInputValue = [...inputValue, option];
            setInputValue(newInputValue);
          } else if (inputValue) {
            newInputValue = [inputValue, option];
            setInputValue(newInputValue);
          } else {
            newInputValue = [option];
            setInputValue(newInputValue);
          }
        }
        return;
      }
      setSelectedOptions(option);
      setInputValue(option);
      setIsSearching(false);

      setShowOptions(false);
    },
    [inputValue, multi, unselectOption, setSelectedOptions],
  );

  const onClearCallback = useCallback(() => {
    setInputValue(null);
    setSelectedOptions();
    setShowOptions(true);
  }, [setSelectedOptions]);

  const prepareSearchInputValue = useCallback(() => {
    if (isSearching && showOptions) {
      return searchInputValue;
    }
    if (customValueDisplayRenderer || multi) {
      return '';
    }

    if (inputValue) {
      if (Array.isArray(inputValue)) {
        if (inputValue.length === 0) {
          return '';
        }

        const newInputValue: string[] = [];

        inputValue.forEach((option) => {
          newInputValue.push(option.label);
        });
        return newInputValue;
      }
      return inputValue.label;
    }
    return '';
  }, [isSearching, inputValue, searchInputValue, customValueDisplayRenderer, showOptions, multi]);

  const initializeSelect = useCallback(
    ({ initialValue }: { initialValue: string }) => {
      // Causes infinite loop at add request - edit. During fast pick between two elements from the list at request edit rhf ends up looping between two values.
      // await delay(0);
      if (!isMountedRef.current || !options) return;
      const initialValues = initialValue.split(',');
      const initialOptions: Option[] = [];

      initialValues.forEach((initValue) => {
        initialOptions.push(...options.filter((option) => option.id === initValue));
      });

      if (initialOptions.length > 0) {
        setInputValue(initialOptions);
        initialOptions.forEach((initialOption) => {
          setSelectedOptions(initialOption);
        });
      }
    },
    [options, setSelectedOptions, isMountedRef],
  );

  const renderOptions = useMemo(() => {
    if (!options) return null;

    const trimmedFilter = optionsFilter ? optionsFilter.trim() : null;

    const isNotFiltered = !trimmedFilter;

    const sortedOptions = isNotFiltered || !fuzzySearch ? null : fuzzySearch(trimmedFilter);

    let optionsToRender = sortedOptions
      ? ([...createdOptionsRef.current, ...sortedOptions] as unknown as InputOption[])
      : ([...createdOptionsRef.current, ...options] as unknown as InputOption[]);

    const hasFilteredElement =
      creatable &&
      !!trimmedFilter &&
      !!optionsToRender.find((op) => op.label.toLocaleLowerCase().trim() === trimmedFilter.toLocaleLowerCase().trim());

    if (creatable && trimmedFilter && !hasFilteredElement) {
      optionsToRender.unshift({
        id: trimmedFilter,
        label: trimmedFilter,
        isCreatable: true,
      });
    }

    if (!optionsToRender.length) {
      return (
        <OptionList as="ul" ref={optionsListRef}>
          <Box as="li" sx={{ px: 3, py: 4, textAlign: 'center', cursor: 'not-allowed', fontSize }}>
            <Trans id="select.no_options">No options</Trans>
          </Box>
        </OptionList>
      );
    }

    if (multi || showSelectedOptionsFirst) {
      optionsToRender = _.orderBy(optionsToRender, (option) => !selectedOptionsRef.current?.includes(option.id));
    }

    optionsToRenderRef.current = optionsToRender as unknown as Option[];

    const optionRenderer = (option: Option, index: number, optionProps?: Partial<OptionProps>) => {
      const active = selectedOptionsRef.current?.includes(option.id);
      const isCreated = !!createdOptionsRef.current.find((op) => op.id === option.id);

      return (
        <Option
          ariaSelectedAtom={optionAriaSelectedSelectorFamily({
            itemIndex: index,
            selectId: selectInstanceIdRef.current,
          })}
          active={active}
          onClick={() => onOptionClick(option)}
          onMouseEnter={() => resetFocusedOptionIndex()}
          multi={multi}
          disabled={option.disabled}
          tooltip={option.tooltip}
          contentRenderer={() =>
            option.isCreatable && !isCreated
              ? `${t({ id: 'forms.select.option_create', message: 'Create' })} "${option.label}"`
              : option.label
          }
          {...(optionProps && optionProps)}
          sx={{
            fontSize,
            ...(!active && option.sx && option.sx),
            ...(optionProps?.sx && optionProps.sx),
          }}
          {...(optionPropsGenerator && optionPropsGenerator(option, active))}
        />
      );
    };

    const newOptionsToRender = optionsToRender as Option[];

    if (virtualizeOptions) {
      const rowRenderer = ({ index, style }: ListChildComponentProps) => {
        const option = optionsToRender[index] as Option;

        const innerOptionHeight = style?.height ? Number(style.height) - OPTION_LIST_GAP : '100%';

        const optionProps: Partial<OptionProps> = {
          sx: { height: innerOptionHeight, py: 0, width: '100%' },
          contentRenderer: () => <TextEllipsis title={option.label}>{option.label}</TextEllipsis>,
        };
        return (
          <Flex
            as="li"
            role="option"
            style={style}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {optionRenderer(option, index, optionProps)}
          </Flex>
        );
      };

      const optionHeight = fixedSizeListProps?.itemSize || OPTION_HEIGHT;
      const listPaddingNum = OPTION_LIST_PADDING - OPTION_LIST_GAP / 2;
      const listPadding = `${listPaddingNum}px`;

      return (
        <>
          <OptionList
            sx={{
              ...(newOptionsToRender.length && {
                height: `${newOptionsToRender.length * optionHeight + listPaddingNum * 2}px`,
              }),
              py: listPadding,
            }}
          >
            {options && optionsFilter && !newOptionsToRender.length ? null : (
              <AutoSizer>
                {({ height, width }) => (
                  <FixedSizeList
                    ref={fixedSizeListRef}
                    itemSize={optionHeight}
                    innerElementType={InnerElementType}
                    height={height}
                    width={width}
                    itemCount={newOptionsToRender.length}
                    overscanCount={5}
                    {...(fixedSizeListProps && fixedSizeListProps)}
                  >
                    {rowRenderer}
                  </FixedSizeList>
                )}
              </AutoSizer>
            )}
          </OptionList>
          {loadingOptions && (
            <Flex
              sx={{
                position: 'absolute',
                bottom: 0,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: OPTION_HEIGHT,
                bg: 'select.loading',
              }}
            >
              <LoadingSpinnerCss />
            </Flex>
          )}
        </>
      );
    }

    return (
      <OptionList
        as="ul"
        role="listbox"
        ref={optionsListRef}
        sx={{
          '> li + li': {
            mt: `${OPTION_LIST_GAP}px`,
          },
          ...(multi && {
            overflowAnchor: 'none',
          }),
        }}
      >
        {newOptionsToRender.map((option, index, arr) => {
          const isLast = index === arr.length - 1;

          const optionProps: Partial<OptionProps> = {
            as: 'li',
            role: 'option',
          };
          return (
            <React.Fragment key={option.id}>
              {optionRenderer(option, index, optionProps)}
              {!!option.appendWithDivider && !isLast && isNotFiltered && <Divider />}
            </React.Fragment>
          );
        })}
      </OptionList>
    );
  }, [
    options,
    optionsFilter,
    creatable,
    multi,
    showSelectedOptionsFirst,
    virtualizeOptions,
    fontSize,
    optionPropsGenerator,
    onOptionClick,
    resetFocusedOptionIndex,
    fixedSizeListProps,
    loadingOptions,
    fuzzySearch,
  ]);

  const renderSelectedMultiOptions = useCallback(() => {
    if (!inputValue) {
      return null;
    }

    if (customValueDisplayRenderer) {
      if (Array.isArray(inputValue)) {
        return inputValue.map((selectedOp) => (
          <Flex key={`${selectedOp.label}`}>{customValueDisplayRenderer(selectedOp)}</Flex>
        ));
      }
      return <Flex>{customValueDisplayRenderer(inputValue)}</Flex>;
    }

    if (Array.isArray(inputValue)) {
      return inputValue.map((selectedOp) => (
        <SelectedMultiOption
          key={`${selectedOp.label}`}
          sx={{
            ...(selectedOp.isCreatable && { bg: 'select.option.bg.selectedCreatable' }),
            fontSize: fontSize - 1,
            ...(!!selectedMultiOptionSxGenerator && selectedMultiOptionSxGenerator(selectedOp)),
          }}
        >
          {selectedOp.label}
        </SelectedMultiOption>
      ));
    }
    return <SelectedMultiOption>{inputValue.label}</SelectedMultiOption>;
  }, [customValueDisplayRenderer, fontSize, inputValue, selectedMultiOptionSxGenerator]);

  const debouncedSetOptionsFilter = useMemo(
    () =>
      _.debounce(() => {
        if (inputRef.current) {
          setOptionsFilter(inputRef.current.value);
        }
      }, 500),
    [],
  );

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        return;
      case 'ArrowUp':
        e.preventDefault();
        return;
      case 'ArrowDown':
        e.preventDefault();
        return;
      default:
        break;
    }

    if (searchable && isSearching) {
      debouncedSetOptionsFilter();
      setShowOptions(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.disabled) return;

    const maxIndex = optionsToRenderRef.current?.length ? optionsToRenderRef.current.length - 1 : 0;

    const handleFocusOption = () => {
      if (focusedOptionIndexRef.current === null) return;

      setFocusIndex(focusedOptionIndexRef.current);

      fixedSizeListRef.current?.scrollToItem(focusedOptionIndexRef.current);

      optionsListRef.current?.childNodes.forEach((child, index) => {
        const option = child as HTMLLIElement;

        if (index !== focusedOptionIndexRef.current) return;

        const optionCoordinates = option.getBoundingClientRect();
        const optionsListCoordinates = optionsListRef.current?.getBoundingClientRect();

        if (optionsListCoordinates) {
          if (optionCoordinates.bottom > optionsListCoordinates?.bottom) {
            scrollToSee(option);
          } else if (optionCoordinates.top < optionsListCoordinates?.top) {
            scrollToSee(option);
          }
        }
      });
    };

    const handleEnterOnOption = () => {
      if (!showOptions || !optionsToRenderRef.current || focusedOptionIndexRef.current === null) return;

      void onOptionClick(optionsToRenderRef.current[focusedOptionIndexRef.current]);
      if (multi) return;
      inputRef.current?.blur();
    };

    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        handleEnterOnOption();
        return;
      case 'ArrowUp':
        e.preventDefault();
        if (focusedOptionIndexRef.current === null) {
          focusedOptionIndexRef.current = maxIndex;
        } else if (focusedOptionIndexRef.current <= maxIndex && focusedOptionIndexRef.current > 0) {
          focusedOptionIndexRef.current -= 1;
        }

        handleFocusOption();
        return;
      case 'ArrowDown':
        e.preventDefault();
        if (focusedOptionIndexRef.current === null) {
          focusedOptionIndexRef.current = 0;
        } else if (focusedOptionIndexRef.current < maxIndex) {
          focusedOptionIndexRef.current += 1;
        }
        handleFocusOption();
        break;
      default:
        break;
    }
  };

  const handleBlur = async () => {
    isFocusRef.current = false;
    resetFocusedOptionIndex();

    await delay(0);
    if (!isFocusRef.current) {
      if (searchable && isSearching) {
        setIsSearching(false);
        setTimeout(() => {
          setOptionsFilter(undefined);
        }, 200);
      }
    }
  };

  const handleFocus = () => {
    isFocusRef.current = true;
    if (searchable && !isSearching) {
      setSearchInputValue('');
      setIsSearching(true);
      if (multi && inputRef.current) inputRef.current.value = '';
    }
  };

  const wrapperSx: ThemeUIStyleObject = useMemo(
    () => ({
      cursor: props.disabled ? 'auto' : 'pointer',
      width: props.customContent ? 'fit-content' : '100%',
      input: {
        cursor: 'inherit',
      },
    }),
    [props.customContent, props.disabled],
  );

  const handleHiddenInputChange = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>, passedValue?: string) => {
      const newValue = passedValue || e?.target.value || '';

      if (newValue !== hiddenInputValueRef.current || passedValue) {
        hiddenInputValueRef.current = newValue;
        const allOptions = options ? [...createdOptionsRef.current, ...options] : createdOptionsRef.current;
        const matchedOptions = allOptions.filter((op) => op.id === newValue);
        if (!ignoreOptions && !multi) {
          if (!matchedOptions || !matchedOptions?.length) {
            const shouldForceUpdate = !!selectedOptionsRef.current.length;
            selectedOptionsRef.current = [];
            setSearchInputValue('');
            setInputValue(inputValue);
            if (shouldForceUpdate) forceUpdate();
          } else if (!customValueDisplayRenderer) {
            setSearchInputValue(matchedOptions[0].label);
            setSelectedOptions(matchedOptions[0]);
          }
        }
        if (multi) {
          setSearchInputValue('');
        }

        if (newValue) {
          initializeSelect({ initialValue: newValue });
        } else {
          initializeSelect({ initialValue: '' });
          setInputValue(null);
        }
      }

      if (onChange && e && !passedValue) {
        onChange(e);
      }
    },
    [
      multi,
      ignoreOptions,
      onChange,
      initializeSelect,
      options,
      setSelectedOptions,
      inputValue,
      forceUpdate,
      customValueDisplayRenderer,
    ],
  );

  const handleUpdateFieldView = useCallback(
    (newValue: string) => {
      handleHiddenInputChange(undefined, newValue);
      if (onUpdateFieldView) {
        onUpdateFieldView(newValue);
      }
    },
    [handleHiddenInputChange, onUpdateFieldView],
  );

  const handleUpdateFieldViewRef = useCallbackRef(handleUpdateFieldView);

  useEffect(() => {
    // sprawdzic czy dziala na tablice
    const controlledValue = props.inputProps?.value;
    if (controlledValue) {
      handleUpdateFieldViewRef.current(`${controlledValue}`);
    }
  }, [props.inputProps?.value, handleUpdateFieldViewRef]);

  useOnUpdateFieldView(hiddenRef, handleUpdateFieldView);

  useEffect(() => {
    if (hiddenRef.current) {
      const initialValue = hiddenRef.current.value;
      if (initialValue) {
        emitUpdateFieldView(hiddenRef);
      }
    }
  }, []);

  const handleHiddenInputFocus = useCallback(() => {
    if (!props.customContent && !props.focusThiefElement) {
      inputRef.current?.focus();
    }
    if (props.focusThiefElement) {
      props.focusThiefElement.focus();
    }
    if (!alwaysHideOptions) {
      setShowOptions(true);
    }
  }, [props.customContent, props.focusThiefElement, alwaysHideOptions]);

  const handleHiddenInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!ignoreOptions && !multi && !isFocusRef.current) {
      if (selectedOptionsRef.current.length && options) {
        const matchedOptions = options.filter((op) => op.id === selectedOptionsRef.current[0]);
        if (matchedOptions.length && !customValueDisplayRenderer) {
          setSearchInputValue(matchedOptions[0].label);
        }
      } else {
        setSearchInputValue('');
      }
    }

    if (multi) {
      setSearchInputValue('');
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  const onOwnValueChange = useCallback(
    (newValue: InputOwnValue) => {
      let parsedNewValue = newValue;
      if (_.isNil(newValue)) {
        parsedNewValue = '';
      }
      if (_.isArray(newValue)) {
        parsedNewValue = newValue.join(',');
      }
      handleUpdateFieldView(`${parsedNewValue}`);
    },
    [handleUpdateFieldView],
  );

  useOnOwnValueChange(hiddenRef, onOwnValueChange);

  const closeOptionsDropdown = useCallback(() => {
    if (showOptions) {
      setShowOptions(false);
      if (inputRef.current) inputRef.current.blur();
    }
  }, [showOptions]);

  const popperVisible = useMemo(
    () => !alwaysHideOptions && showOptions && !props.disabled,
    [alwaysHideOptions, props.disabled, showOptions],
  );

  useOnKeyboardEventQueue('Escape', closeOptionsDropdown, popperVisible);

  useEffect(() => {
    const selectedOptionsIds = hiddenRef.current?.value?.split(',');
    if (checkValueForNonexistentIds && selectedOptionsIds) {
      const existentOptionIds = options?.map((option) => option.id);
      const filteredSelectedOptionsIds = selectedOptionsIds.filter((optionId) => existentOptionIds?.includes(optionId));

      if (filteredSelectedOptionsIds.length < selectedOptionsIds.length) {
        const newValue = filteredSelectedOptionsIds.join(',');
        setNativeValue(hiddenRef, newValue);
      }
    }
  }, [options, checkValueForNonexistentIds]);

  const selectedOptionRef = useCallbackRef(selectedOption);
  useEffect(() => {
    if (hiddenRef.current?.value && !selectedOptionRef.current) {
      handleUpdateFieldViewRef.current(hiddenRef.current?.value);
    }
  }, [options, selectedOptionRef, handleUpdateFieldViewRef]);

  const prependWithRenderer = useMemo(() => {
    if ((isSearching || showOptions) && searchable) {
      return props.prependWith;
    }

    if (!!customValueDisplayRenderer && selectedOption && !multi) {
      return customValueDisplayRenderer(selectedOption);
    }

    if (multi) {
      return renderSelectedMultiOptions();
    }

    return props.prependWith;
  }, [
    customValueDisplayRenderer,
    isSearching,
    multi,
    props.prependWith,
    renderSelectedMultiOptions,
    searchable,
    selectedOption,
    showOptions,
  ]);

  useEffect(() => {
    if (prevShowOptions && !showOptions) {
      dispatchBlurEvent();
    }
  }, [showOptions, prevShowOptions, dispatchBlurEvent]);

  return (
    <>
      <input
        name={name}
        ref={mergeRefs([ref, hiddenRef])}
        onChange={handleHiddenInputChange}
        onFocus={!ignoreHiddenInputFocus ? handleHiddenInputFocus : undefined}
        onBlur={handleHiddenInputBlur}
        style={{ width: 0, opacity: 0, position: 'absolute' }}
        defaultValue={defaultValue}
        readOnly
        autoComplete="new-password"
        tabIndex={-1}
      />
      <FlexWithPopper
        sx={{ ...wrapperSx, ...(sx && sx) }}
        popperProps={{
          popperContainerSx: {
            minWidth: '100px',
          },
          popperGuardValue: true,
          content: renderOptions,
          placement: 'bottom-start',
          trigger: 'manual',
          popperMargin: -0.3,
          widthLikeReferenceElement: true,
          onOutsideClick,
          visible: popperVisible,
        }}
        onMouseDown={floatingPromiseReturn(onMouseDown)}
        onClick={onClick}
        onFocus={onFocus}
      >
        <TextInput
          {...props}
          {...(((!isSearching && !showOptions) || !searchable) && !selectedOption && { placeholder })}
          usedAsDisplay
          controllerHasValue={!!hiddenRef.current?.value}
          sxOverwrite={{
            ...(!(creatable || searchable) && {
              cursor: 'pointer !important',
            }),
            ...(props.sxOverwrite && props.sxOverwrite),
          }}
          size={size}
          ref={inputRef}
          id={selectInstanceIdRef.current}
          type="text"
          inputProps={{
            readOnly: !(creatable || searchable),
            ...(props.inputProps && props.inputProps),
            sx: {
              ...(multi &&
                !isSearching && { color: `${theme.colors.select.selectOption.background.multi} !important` }),
              ...(props.inputProps?.sx && props.inputProps.sx),
            },
            value: prepareSearchInputValue(),
            onChange: (e) => setSearchInputValue(e.target.value),
          }}
          autoComplete="new-password"
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          onBlur={floatingPromiseReturn(handleBlur)}
          onFocus={handleFocus}
          onClearCallback={onClearCallback}
          loading={!options}
          prependWith={prependWithRenderer}
          apendWith={
            !alwaysHideOptions ? (
              <Icon type={showOptions ? 'chevronUp' : 'chevronDown'} wrapperSx={{ cursor: 'pointer' }} />
            ) : undefined
          }
        />
      </FlexWithPopper>
    </>
  );
}

export const Select = React.forwardRef(SelectInner) as (<Option extends InputOption>(
  p: Props<Option> & { ref?: Ref<HTMLInputElement> },
) => ReactElement) & { defaultProps: Partial<Props<InputOption>> };

Select.defaultProps = defaultProps;
