import { Employee, TimeEventType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { CalendarScheduleWarnings } from 'constants/calendar';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { ErrorsDayReport } from 'pages/Reports/output/types';
import { ParsedEmployee } from 'state/employees';
import { ScheduleWizardSelectedDays } from '../calendar/calendarActions.types';
import { RequestState } from '../requests/requestsActions.types';
import { ViewSettings } from '../userSession/userSessionActions.types';

export type TimeEvent = {
  action: TimeEventActionResponse;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  createDateTimeUtc?: number;
  creatorId?: string;
  device?: {
    id: string;
    name?: string;
  };
  faceModelState?: FaceModelState;
  fraudDetectionState?: FraudDetectionState;
  id: string;
  isEnd: boolean;
  isOffline?: boolean;
  isModified: boolean;
  location?: {
    id: string;
    name?: string;
  };
  note?: string;
  personId: string;
  photoUrl: string;
  request?: {
    id: string;
    number: string;
  };
  requestState?: RequestState;
  state: TimeEventState;
  timeRealUtc?: number;
  timeUtc: number;
  typeId: string;
};

export type ExtendedTimeEvent = TimeEvent &
  Pick<ParsedEmployee, 'isActive' | 'isHidden' | 'tags' | 'role' | 'avatarUrl' | 'invitationState'> & {
    employeeName: ParsedEmployee['name'];
    typeName: TimeEventType['name'];
    photos?: EventPhoto[];
  };

/* -> CLOCK LOG MAIN REQUEST */
export type FetchClockLogActionProps = {
  startDateUnix: number;
  endDateUnix: number;
  peopleIds?: string[];
};

type DayNumber = number;
export type EventDayNumber = DayNumber;
export type HolidayDayNumber = DayNumber;
type SelectedDateRangeDayNumber = DayNumber;
export type HolidayName = string;

type DayEventIds = {
  timeEventsIds: TimeEvent['id'][];

  hasUnpublishedChanges?: boolean; // unused
  hasPendingRequests?: boolean; // unused
  warnings: CalendarScheduleWarnings[]; // unused
  // unused:
  errors: {
    error: ErrorsDayReport;
    details: {
      day: DayNumber;
      firstRequestId: string;
      firstRequestNumber: string;
      secondRequestId: string;
      secondRequestNumber: string;
    }[];
  }[];
};

type PhotoLogEmployeeDaysWithEventIds = {
  days: [EventDayNumber, DayEventIds][];

  hasUnpublishedChanges?: boolean; // unused
  hasPendingRequests?: boolean; // unused
  hasErrors: boolean; // unused
  hasWarnings: boolean; // unused
};

export type ClockLog = {
  timeEvents: [TimeEvent['id'], TimeEvent][];
  employeesData: [Employee['id'], PhotoLogEmployeeDaysWithEventIds][];
  holidays: [HolidayDayNumber, HolidayName][];
  days: SelectedDateRangeDayNumber[];
};

export type FetchClockLogResponse = ClockLog;
/* <- CLOCK LOG MAIN REQUEST */

export type DeleteTimeEventsActionProps = TimeEvent['id'][];

export type DeleteTimeEventsResponse = null;

export type RemoveTimeEventsActionProps = TimeEvent['id'][];

export type RemoveTimeEventsResponse = null;

export type RestoreTimeEventsActionProps = TimeEvent['id'][];

export type RestoreTimeEventsResponse = null;

export type FetchTimeEventHistoryActionProps = string;

export type TimeEventHistoryEntry = {
  // id: string;
  typeId: string;
  modificationDateTime?: number;
  modificationPersonId?: string;
  // device: { id: string; name?: string };
  isRequest: boolean;
  // isEnd: boolean;
  // isOffline?:boolean
  // timeUtc: number;
  // note?: string;
} & Pick<TimeEvent, 'isOffline' | 'isEnd' | 'device' | 'note' | 'timeUtc' | 'id'>;

export type TimeEventHistoryChunk = {
  previous?: TimeEventHistoryEntry;
  current?: TimeEventHistoryEntry;
  action: TimeEventActionResponse;
};

export type FetchTimeEventHistoryResponse = TimeEventHistoryChunk[];

export type EditTimeEventActionProps = {
  id: string;
  dateTimeUnix: number;
  timeEventTypeId: string;
  isEnd: boolean;
  note?: string;
  latitude?: number;
  longitude?: number;
};
export type EditTimeEventResponse = null;

export type AddTimeEventNoteActionProps = {
  id: string;
  note: string;
};
export type AddTimeEventNoteResponse = null;

export type EditClockLogListViewSettingAction = ViewSettings;
export type EditClockLogViewSettingResponse = null;

export type AddTimeEventsActionProps = {
  peopleIds: string[];
  selectedDays: ScheduleWizardSelectedDays[];
  datesUnix: number[];
  ignoreHolidays: boolean;
  timeEvents: {
    timeEventTypeId: string;
    timeSeconds: number;
    isEnd: boolean;
    coordinates?: {
      latitude: number;
      longitude: number;
    };
  }[];
};
export type AddTimeEventsResponse = null;

export type GetTimeEventResponse = TimeEvent[];

export type EventPhoto = {
  id: string; // BEWARE: some older photos don't have this prop
  url: string;
  isSelected: boolean;
};

export type FetchEventWithDetailsActionProps = TimeEvent['id'];
export type FetchEventWithDetailsResponse = TimeEvent & { photos: EventPhoto[] };

export type SetFraudResolutionStateActionProps = {
  id: TimeEvent['id'];
  isFraud: boolean;
  selectedPhotos?: string[];
};
export type SetFraudResolutionStateResponse = null;

export type FetchEventsPhotosActionProps = TimeEvent['id'][];
export type EventPhotos = {
  timeEventId: TimeEvent['id'];
  innerCode: ErrorInnerCodes;
  photos: EventPhoto[];
};
export type FetchEventsPhotosResponse = EventPhotos[];

export enum FraudDetectionState {
  None = 0,
  ResolvedNotFraud = 1,
  PotentialFraud = 2,
  DetectedFraud = 3,
  ResolvedFraud = 4,
}

export enum FaceModelState {
  Infant = 0,
  UpdatedInfant = 1,
  Adult = 2,
}

export enum TimeEventState {
  Active = 0,
  Pending = 1,
  Modified = 2,
  Archival = 3,
  Deleted = 4,
}

export enum TimeEventActionResponse {
  Create = 0,
  Delete = 1,
  Restore = 2,
  Modified = 3,
}
