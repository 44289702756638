import { Face } from '@tensorflow-models/face-detection';
import { atom, selector } from 'recoil';

import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';

import { kioskStateAtom } from './kioskState';

export const debugModeSelector = selector<boolean>({
  key: 'kiosk__debugMode',
  get: ({ get }) => {
    const { debugMode } = get(kioskStateAtom);

    return debugMode;
  },
  set: ({ get, set }, newState) => {
    const { debugMode } = get(kioskStateAtom);

    if (newState !== debugMode && !isRecoilDefaultValue(newState)) {
      set(kioskStateAtom, (prevState) => ({ ...prevState, debugMode: newState }));
    }
  },
});

export const debugModeFacePredictionsAtom = atom<Face[] | null>({
  key: 'kiosk__debugModeFacePredictions',
  default: null,
});

export enum DebugFaceLivelinessMode {
  Default = 0,
  ThirtyFrames = 1,
  FiftyFrames = 2,
  OneHundredFrames = 3,
}

type DebugModeSettingsProps = {
  drawBoundingBox: boolean;
  drawPredictionPoints: boolean;
  drawAxisPoints: boolean;
  drawFaceInPlaceBounding: boolean;
  faceInPlaceDetection: boolean;
  faceLivelinessMode: DebugFaceLivelinessMode;
};

export const debugModeSettingsAtom = atom<DebugModeSettingsProps>({
  key: 'kiosk__debugModeSettings',
  default: {
    drawAxisPoints: true,
    drawBoundingBox: true,
    drawFaceInPlaceBounding: true,
    drawPredictionPoints: true,
    faceInPlaceDetection: false,
    faceLivelinessMode: DebugFaceLivelinessMode.Default,
  },
});

export const debugScanningTimeFrameOpenAtom = atom({
  key: 'kiosk__debugScanningTimeFrameOpen',
  default: false,
});
