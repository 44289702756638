import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { timeTrackingSettingsAction } from 'api/actions/settings/settingsActions';
import { FetchTimeTrackingSettingsResponse } from 'api/actions/settings/settingsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { SnackbarProps } from 'components/Snackbar/types';
import { TIME_TRACKING_SETTINGS_RESET } from 'constants/settings';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { blockTransitionAtom, resetFormButtonAtom } from 'state/settings';
import { getRefreshSnackbarProps } from '../../../../../utils/getRefreshSnackbarProps';
import { ParsedTimeTrackingSettings, useTimeTrackingSettings } from '../../../hooks/useTimeTrackingSettings';

type Props = {
  handleFormReset: () => void;
  getCurrentFormState: () => FetchTimeTrackingSettingsResponse;
};

export const useSubmitTimeTrackingSettings = ({ handleFormReset, getCurrentFormState }: Props) => {
  useLingui();
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const [{ skipFetch, isRequestPending }, setBlockTransition] = useRecoilState(blockTransitionAtom);
  const {
    timeTrackingSettings,
    fetchTimeTrackingSettings,
    getParsedTimeTrackingSettings,
    getFlattenedTimeTrackingSettings,
    fetchAbort,
  } = useTimeTrackingSettings();
  const { mutate } = useMutation(timeTrackingSettingsAction);
  const skipFetchRef = useCallbackRef(skipFetch);
  const isRequestPendingRef = useCallbackRef(isRequestPending);
  const getCurrentFormStateRef = useCallbackRef(getCurrentFormState);
  const prevFormStateRef = useRef<FetchTimeTrackingSettingsResponse | null>(timeTrackingSettings);
  const pendingRequestBodyRef = useRef<Partial<FetchTimeTrackingSettingsResponse> | null>(null);

  const timeTrackingSettingsResetObject = useMemo(
    () => ({ name: TIME_TRACKING_SETTINGS_RESET, callback: handleFormReset }),
    [handleFormReset],
  );

  const handleSubmitCallback = useCallback(
    async (data: FetchTimeTrackingSettingsResponse) => {
      const submittedFormState = data;
      const parsedData = getParsedTimeTrackingSettings(submittedFormState);

      if (
        _.isEqual(timeTrackingSettings, submittedFormState) &&
        !pendingRequestBodyRef.current &&
        !isRequestPendingRef.current
      ) {
        setBlockTransition((prevState) => ({ ...prevState, blockTransition: false }));
        setResetCallbacks(null);
        return;
      }

      const parsedPrevFormState = getParsedTimeTrackingSettings(prevFormStateRef.current);
      const parsedCurrentChanges: Partial<ParsedTimeTrackingSettings> = _.omitBy(
        parsedData,
        (value, key) =>
          parsedPrevFormState && _.isEqual(value, parsedPrevFormState[key as keyof ParsedTimeTrackingSettings]),
      );
      const currentChanges = getFlattenedTimeTrackingSettings(parsedCurrentChanges);

      fetchAbort();
      setResetCallbacks(null);
      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: true }));

      pendingRequestBodyRef.current = { ...pendingRequestBodyRef.current, ...currentChanges };
      prevFormStateRef.current = submittedFormState;

      const formStateBeforeRequest = getCurrentFormStateRef.current();

      const { error, status } = await mutate(pendingRequestBodyRef.current);

      const formStateAfterRequest = getCurrentFormStateRef.current();

      if (!_.isEqual(formStateBeforeRequest, formStateAfterRequest)) {
        return;
      }

      if (!error) {
        let showRefreshSnackbar = false;
        pendingRequestBodyRef.current = null;

        if (!skipFetchRef.current) {
          const { error: fetchError, payload: fetchPayload } = await fetchTimeTrackingSettings();

          const formStateAfterFetch = getCurrentFormStateRef.current();

          if (!_.isEqual(formStateBeforeRequest, formStateAfterFetch)) {
            return;
          }

          if (!fetchError && fetchPayload) {
            const parsedFormStateAfterFetch = getParsedTimeTrackingSettings(formStateAfterFetch);
            const parsedFetchPayload = getParsedTimeTrackingSettings(fetchPayload);
            showRefreshSnackbar = !_.isEqual(parsedFormStateAfterFetch, parsedFetchPayload);
          }
        }

        const snackbarProps: SnackbarProps = showRefreshSnackbar
          ? getRefreshSnackbarProps(handleFormReset)
          : {
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            };

        addSnackbar(snackbarProps);
      }

      if (error) {
        if (!status) {
          return;
        }
        setResetCallbacks((prevState) =>
          !prevState ? [timeTrackingSettingsResetObject] : [...prevState, timeTrackingSettingsResetObject],
        );
        return;
      }

      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: false, blockTransition: false }));
    },
    [
      fetchAbort,
      fetchTimeTrackingSettings,
      getCurrentFormStateRef,
      getFlattenedTimeTrackingSettings,
      getParsedTimeTrackingSettings,
      handleFormReset,
      isRequestPendingRef,
      mutate,
      setBlockTransition,
      setResetCallbacks,
      skipFetchRef,
      timeTrackingSettings,
      timeTrackingSettingsResetObject,
    ],
  );

  const handleErrorCallback = useCallback(() => {
    setResetCallbacks((prevState) => {
      if (_.includes(prevState, timeTrackingSettingsResetObject)) return prevState;
      return !prevState ? [timeTrackingSettingsResetObject] : [...prevState, timeTrackingSettingsResetObject];
    });
  }, [setResetCallbacks, timeTrackingSettingsResetObject]);

  return { handleSubmitCallback, handleErrorCallback };
};
