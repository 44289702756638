import { t } from '@lingui/macro';
import _ from 'lodash';
import { useCallback, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';

import { addRequestAction, addSwapRequestAction } from 'api/actions/requests/requestsActions';
import {
  AddRequestActionProps,
  AddSwapRequestProps,
  DateTimeKind,
  RequestFormType,
} from 'api/actions/requests/requestsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { useModal } from 'components/Modal/output/useModal';
import { ALL_SUPERVISORS_ID } from 'constants/requests';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { useRefreshClockLog } from 'pages/ClockLog/output/useRefreshClockLog';
import { useRequestsToAccept } from 'pages/Home/output/useRequestsToAccept';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { languageSelector } from 'state/recoilState';
import { useRefreshRequests } from '../../../../../../hooks/useRefreshRequests';
import { useRefreshRequestsTypeDetails } from '../../../../../../hooks/useRefreshRequestsTypeDetails';
import { AddRequestFormState } from '../../../../../types';
import { useRefreshRequestsUsageOverview } from '../../../../hooks/useRefreshRequestsUsageOverview';
import { useAddRequest } from '../../../hooks/useAddRequest';

export const useSubmitAddRequest = () => {
  const language = useRecoilValue(languageSelector);
  const { handleClose, baseRoute } = useModal();

  const {
    addRequestState: { requestType },
    setAddRequestState,
    selectedTeammatesIds,
  } = useAddRequest();

  const { mutate: mutateAddRequest } = useMutation((body: AddRequestActionProps) =>
    addRequestAction(body, {
      baseRoute: {
        [language]: baseRoute,
      },
    }),
  );

  const { mutate: mutateAddSwapRequest } = useMutation((body: AddSwapRequestProps) =>
    addSwapRequestAction(body, {
      baseRoute: {
        [language]: baseRoute,
      },
    }),
  );

  const targetSwapPersonId = useRef<string | undefined>(undefined);

  const { updateRequestsNoThrottle, requestsInitialized } = useRefreshRequests();
  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar();
  const { reportInitialized, updateReportForIds } = useRefreshReport();
  const { clockLogInitialized, refreshClockLogForPeopleIds } = useRefreshClockLog(selectedTeammatesIds);
  const { isInitialized: requestToAcceptInitialized, fetchRequestsToAccept } = useRequestsToAccept();
  const { updateRequestsUsageOverviewNoThrottle, requestsUsageOverviewInitialized } = useRefreshRequestsUsageOverview();
  const { updateRequestsTypeDetailsNoThrottle, requestsTypeDetailsInitialized } = useRefreshRequestsTypeDetails();

  const handleAddRequestSubmit = useCallback(
    async ({ isDateBound, dateTimeDetails, ...submitData }: AddRequestFormState) => {
      const optionalStepProcessedById =
        submitData.optionalStepProcessedById?.length && submitData.optionalStepProcessedById !== ALL_SUPERVISORS_ID
          ? submitData.optionalStepProcessedById
          : undefined;
      const processedById =
        submitData.processedById?.length && submitData.processedById !== ALL_SUPERVISORS_ID
          ? submitData.processedById
          : undefined;
      const files = submitData.files && submitData.files[0].length ? submitData.files : undefined;

      if (!_.isNumber(requestType) && _.isEmpty(selectedTeammatesIds)) return true;

      const mutationObject = {
        ...submitData,
        type: requestType,
        peopleIds: selectedTeammatesIds,
        processedById,
        optionalStepProcessedById,
        files,
        dateTimeDetails: {
          ...dateTimeDetails,
          kind: (() => {
            switch (requestType) {
              case RequestFormType.TimeOff:
              case RequestFormType.Custom:
                return isDateBound ? DateTimeKind.Local : DateTimeKind.Utc;
              case RequestFormType.BusinessTrip:
                return DateTimeKind.Local;
              case RequestFormType.TimeTracking:
              default:
                return DateTimeKind.Utc;
            }
          })(),
        },
      };
      const { error: submitError } = await mutateAddRequest(mutationObject);

      return submitError;
    },
    [mutateAddRequest, requestType, selectedTeammatesIds],
  );

  const handleSwapRequestSubmit = useCallback(
    async ({
      dateTimeDetails: { date },
      processedById: processedByIdSwap,
      optionalStepProcessedById: optionalStepProcessedByIdSwap,
      note,
      autoAcceptRequest,
      targetSwapPersonId: targetSwap,
    }: AddRequestFormState) => {
      const optionalStepProcessedById =
        optionalStepProcessedByIdSwap?.length && optionalStepProcessedByIdSwap !== ALL_SUPERVISORS_ID
          ? optionalStepProcessedByIdSwap
          : undefined;
      const processedById =
        processedByIdSwap?.length && processedByIdSwap !== ALL_SUPERVISORS_ID ? processedByIdSwap : undefined;

      if (_.isEmpty(selectedTeammatesIds) || !date || !targetSwap) return true;

      targetSwapPersonId.current = targetSwap;

      const mutationObject = {
        peopleIds: [...selectedTeammatesIds, targetSwap],
        processedById,
        optionalStepProcessedById,
        date,
        note,
        autoAcceptRequest,
      };

      const { error: submitError } = await mutateAddSwapRequest(mutationObject);

      return submitError;
    },
    [mutateAddSwapRequest, selectedTeammatesIds],
  );

  const handleSubmitCallback = useCallback(
    async (body: AddRequestFormState) => {
      let submitError: boolean;

      if (requestType === RequestFormType.Schedule) {
        submitError = await handleSwapRequestSubmit(body);
      } else {
        submitError = await handleAddRequestSubmit(body);
      }

      if (!submitError) {
        addSnackbar({
          message: t({ id: 'add_request.add.success', message: 'Added new request' }),
          variant: 'success',
        });

        const teammatesToUpdate = (() => {
          if (targetSwapPersonId.current) return [targetSwapPersonId.current, ...selectedTeammatesIds];

          return selectedTeammatesIds;
        })();

        if (calendarInitialized) await updateCalendarForIds(teammatesToUpdate);
        if (reportInitialized) await updateReportForIds(teammatesToUpdate);
        if (clockLogInitialized) await refreshClockLogForPeopleIds();
        if (requestToAcceptInitialized) await fetchRequestsToAccept();
        if (requestsInitialized) await updateRequestsNoThrottle();
        if (requestsUsageOverviewInitialized) await updateRequestsUsageOverviewNoThrottle();
        if (requestsTypeDetailsInitialized) await updateRequestsTypeDetailsNoThrottle();
        handleClose(true);
      }

      setAddRequestState((prevState) => ({ ...prevState, loading: false }));
    },
    [
      calendarInitialized,
      clockLogInitialized,
      fetchRequestsToAccept,
      handleAddRequestSubmit,
      handleClose,
      handleSwapRequestSubmit,
      refreshClockLogForPeopleIds,
      reportInitialized,
      requestToAcceptInitialized,
      requestType,
      requestsInitialized,
      requestsTypeDetailsInitialized,
      requestsUsageOverviewInitialized,
      selectedTeammatesIds,
      setAddRequestState,
      updateCalendarForIds,
      updateReportForIds,
      updateRequestsNoThrottle,
      updateRequestsTypeDetailsNoThrottle,
      updateRequestsUsageOverviewNoThrottle,
    ],
  );

  const handleFormErrorCallback = useCallback(() => {
    setAddRequestState((prevState) => ({ ...prevState, loading: false }));
  }, [setAddRequestState]);

  return {
    handleSubmitCallback,
    handleFormErrorCallback,
  };
};
