import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Plural, t, Trans } from '@lingui/macro';
import { useMutation } from 'react-fetching-library';
import { useTimer } from 'use-timer';
import { i18n } from '@lingui/core';
import _ from 'lodash';
import { Flex } from 'theme-ui';

import { Section, Header, ProgressBar, SummaryButton, LeadText, Footer } from 'Kiosk/Layout';
import { TimeClock } from 'components/TimeClock/TimeClock';
import { Button } from 'components/ui/Buttons';
import { ENTRY_TIME_EVENT_ID, EXIT_TIME_EVENT_ID } from 'Kiosk/constants/constants';
import { submitTimeEventAction } from 'api/actions/timeclock/timeclockActions';
import { GetInfoResponse } from 'api/actions/timeclock/timeclockActions.types';
import { dateTime, formattedDurationFromSeconds } from 'utils/dateTime';
import { useGeoLocation } from 'Kiosk/hooks/useGeoLocation';
import { selectedTimeEventSelector, showChangeTimeEventSelector } from 'Kiosk/state/summaryState';
import { Container } from 'components/ui/Container';
import { KioskOverlay, overlayStateAtom } from 'Kiosk/state/overlayState';
import { useCamera } from 'Kiosk/hooks/useCamera';
import { ElementGroup } from 'components/ui/ElementGroup';
import { DurationFancyContainer } from 'Kiosk/components/ui/DurationFancyContainer';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { timezoneSelector } from 'state/recoilState';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { KioskSettingsProps } from 'Kiosk/state/settingsState';

type Props = {
  qrCode: string;
  getInfoPayload: GetInfoResponse;
  eventImage: Blob;
  settings: KioskSettingsProps;
};

export const SummaryContainer = ({
  qrCode,
  getInfoPayload,
  eventImage,
  settings,
}: Props): React.ReactElement | null => {
  const [isSending, setIsSending] = useState(false);
  const timerDuration = settings.summaryTimeSeconds;

  const selectedTimeEvent = useRecoilValue(selectedTimeEventSelector);
  const tz = useRecoilValue(timezoneSelector);
  const setShowChangeStatus = useSetRecoilState(showChangeTimeEventSelector);
  const setOverlay = useSetRecoilState(overlayStateAtom);

  const { location } = useGeoLocation();
  const { toggleVideoPlayback } = useCamera();
  const { addSnackbar } = useSnackbar();

  const { mutate } = useMutation(submitTimeEventAction);

  const { suggestedTimeEvent, personName, workTimeSeconds, verificationId } = getInfoPayload;
  const timeEvent = selectedTimeEvent || suggestedTimeEvent;

  const onCancel = () => {
    if (toggleVideoPlayback) void toggleVideoPlayback(true);
    setOverlay({
      type: KioskOverlay.start,
    });
  };

  const onChangeTimeEventClick = () => {
    setShowChangeStatus(true);
  };

  const onSubmit = useCallback(async () => {
    setIsSending(true);

    const { error: submitError } = await mutate({
      qrCode,
      photo: eventImage,
      timeEventId: verificationId,
      timeEventTypeId: timeEvent.type.id,
      timeUtcUnix: dateTime().unix(),
      isEnd: timeEvent.type.isEnd,
      isOffline: false,
      timeZoneId: tz || '',
      ...(settings.isLocationRequired &&
        location && {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
        }),
    });

    if (toggleVideoPlayback) void toggleVideoPlayback(true);

    setOverlay({
      type: KioskOverlay.start,
    });

    if (!submitError) {
      addSnackbar({
        message: i18n._(
          t({
            id: 'kiosk.suggested_status.success_notification',
            message: 'Successfully submitted!',
          }),
        ),
        variant: 'success',
      });
    }
  }, [
    mutate,
    verificationId,
    qrCode,
    eventImage,
    timeEvent.type.id,
    timeEvent.type.isEnd,
    tz,
    settings.isLocationRequired,
    location,
    toggleVideoPlayback,
    setOverlay,
    addSnackbar,
  ]);

  const { time } = useTimer({
    initialTime: timerDuration,
    timerType: 'DECREMENTAL',
    endTime: 0,
    autostart: true,
    interval: 1000,
    onTimeOver: () => {
      void onSubmit();
    },
  });

  const timeEventHeader = useMemo(() => {
    const { id } = suggestedTimeEvent.type;

    if (_.toLower(id) === _.toLower(ENTRY_TIME_EVENT_ID)) {
      return undefined;
    }

    if (selectedTimeEvent) {
      return <Trans id="kiosk.suggested_status.selected_status_button_header">selected work status,</Trans>;
    }

    return <Trans id="kiosk.suggested_status.default_status_button_header">suggested work status,</Trans>;
  }, [selectedTimeEvent, suggestedTimeEvent.type]);

  const timeEventSummary = useMemo(() => {
    const { type, durationSeconds } = timeEvent;
    const { id, isEnd } = type;

    if (_.toLower(id) === _.toLower(ENTRY_TIME_EVENT_ID)) {
      return <Trans id="kiosk.suggested_status.entry_status_message">Have a great day!</Trans>;
    }

    if (_.toLower(id) === _.toLower(EXIT_TIME_EVENT_ID)) {
      if (workTimeSeconds > 0) {
        return (
          <>
            <strong>
              <Trans id="kiosk.suggested_status.exit_status_message">Your work time is</Trans>
              <DurationFancyContainer>{formattedDurationFromSeconds(workTimeSeconds)}</DurationFancyContainer>.
            </strong>
          </>
        );
      }

      return null;
    }

    if (isEnd && durationSeconds) {
      return (
        <>
          <strong>
            <Trans id="kiosk.suggested_status.event_total_duration">The total duration is </Trans>
            <DurationFancyContainer>{formattedDurationFromSeconds(durationSeconds)}</DurationFancyContainer>.
          </strong>

          {!!(
            timeEvent.type.id === suggestedTimeEvent.type.id &&
            suggestedTimeEvent.remainingTimeAllowedSeconds &&
            suggestedTimeEvent.remainingTimeAllowedSeconds >= 0
          ) && (
            <>
              <br />
              <Trans id="kiosk.suggested_status.event_time_left">
                You have
                {formattedDurationFromSeconds(suggestedTimeEvent.remainingTimeAllowedSeconds)}
                left to use.
              </Trans>
            </>
          )}
        </>
      );
    }

    return null;
  }, [suggestedTimeEvent.remainingTimeAllowedSeconds, suggestedTimeEvent.type.id, timeEvent, workTimeSeconds]);

  const timeEventAffix = useMemo(() => {
    const idLower = _.toLower(timeEvent.type.id);
    if (idLower === _.toLower(ENTRY_TIME_EVENT_ID) || idLower === _.toLower(EXIT_TIME_EVENT_ID)) {
      return null;
    }

    if (timeEvent.type.isEnd) {
      return '- STOP';
    }

    return '- START';
  }, [timeEvent]);

  const headerTitleEmoji = useMemo(() => {
    const emojis = ['👋', '✌️', '👊', '❤️', ' 💪'];

    return emojis[Math.floor(Math.random() * emojis.length)];
  }, []);

  return (
    <Container
      size="sm"
      sx={{
        color: 'kiosk.summary.text',
        textAlign: 'center',
        flex: '1 0',
        justifyContent: 'center',
      }}
    >
      {isSending ? (
        <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
          <LoadingSpinnerCss size={5} color="#FFF" />
        </Flex>
      ) : (
        <>
          <Header>
            <Header.Title>
              {personName}! {headerTitleEmoji}
            </Header.Title>
          </Header>

          <Section sx={{ flex: '1 0 0', overflowY: 'auto' }}>
            <LeadText sx={{ mb: 0 }}>
              <Trans id="kiosk.suggested_status.clock_header">It is</Trans>
            </LeadText>

            <TimeClock
              sx={{
                mb: 5,
                fontSize: '5rem',
                lineHeight: 1,
                letterSpacing: ['0.25rem', null, '0.5rem'],
              }}
            />

            {timeEventHeader && <LeadText>{timeEventHeader}</LeadText>}

            <SummaryButton
              shape="rounded"
              variant={
                (_.toLower(timeEvent.type.id) === _.toLower(EXIT_TIME_EVENT_ID) && 'stop') ||
                (_.toLower(timeEvent.type.id) === _.toLower(ENTRY_TIME_EVENT_ID) && 'start') ||
                'default'
              }
              sx={{ mb: 5 }}
            >
              {t({ id: timeEvent.type.name })} {timeEventAffix}
            </SummaryButton>

            {timeEventSummary && <LeadText>{timeEventSummary}</LeadText>}
          </Section>

          <Footer sx={{ mt: 5 }}>
            <LeadText>
              <Trans id="kiosk.after_initial_scan.progress_header">
                The screen will disappear in <strong>{Math.ceil(time)}</strong>
              </Trans>{' '}
              <Plural
                id="global.plurals.seconds"
                value={Math.ceil(time)}
                one="second"
                two="seconds"
                few="seconds"
                other="seconds"
              />
              .
            </LeadText>

            <ProgressBar duration={timerDuration} durationProgress={time} wrapperSx={{ mb: 4 }} />

            <ElementGroup marginAt="end" direction="column">
              {((_.toLower(timeEvent.type.id) !== _.toLower(ENTRY_TIME_EVENT_ID) && !timeEvent.type.isEnd) ||
                !!selectedTimeEvent) && (
                <Button
                  onClick={onChangeTimeEventClick}
                  bgOverwrite={{
                    default: 'kiosk.summary.btn.change.bg.default',
                    hover: 'kiosk.summary.btn.change.bg.hover',
                    tap: 'kiosk.summary.btn.change.bg.tap',
                    disabled: 'kiosk.summary.btn.change.bg.disabled',
                  }}
                  sx={{
                    color: 'kiosk.summary.btn.text',
                    fontSize: 4,
                  }}
                  variant="naked"
                  size="lg"
                  type="button"
                >
                  <Trans id="kiosk.suggested_status.change_status_button">Change status</Trans>
                </Button>
              )}
              <Button
                onClick={onCancel}
                bgOverwrite={{
                  default: 'kiosk.summary.btn.cancel.bg.default',
                  hover: 'kiosk.summary.btn.cancel.bg.hover',
                  tap: 'kiosk.summary.btn.cancel.bg.tap',
                  disabled: 'kiosk.summary.btn.cancel.bg.disabled',
                }}
                sx={{
                  color: 'kiosk.summary.btn.text',
                  fontWeight: '400',
                  fontSize: 4,
                }}
                variant="naked"
                size="lg"
                type="button"
              >
                <Trans id="kiosk.suggested_status.cancel_button">Cancel</Trans>
              </Button>
            </ElementGroup>
          </Footer>
        </>
      )}
    </Container>
  );
};
