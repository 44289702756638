import React from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

type HeaderProps = {
  children?: React.ReactElement | React.ReactElement[] | React.ReactNode;
  sx?: ThemeUIStyleObject;
  headerWithList?: boolean;
};

export const Header = ({ headerWithList, children, sx }: HeaderProps): React.ReactElement => (
  <Flex
    variant={headerWithList ? 'settings.elements.headerWithList' : undefined}
    sx={{ ...(sx && sx), justifyContent: 'space-between', alignItems: 'center' }}
  >
    {children}
  </Flex>
);
