import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';

import { HeadingTip } from '../HeadingTip';
import { SettingsLoadingSpinnerCss } from '../SettingsLoadingSpinnerCss';

import { TimeTrackingForms } from './components/TimeTrackingForms/TimeTrackingForms';
import { useTimeTrackingSettings } from './hooks/useTimeTrackingSettings';
import { useTimeTrackingSettingsCleanUp } from './hooks/useTimeTrackingSettingsCleanUp';

export const TimeTracking = (): React.ReactElement => {
  useLingui();
  const { timeTrackingSettings, fetchTimeTrackingSettings } = useTimeTrackingSettings();

  useEffect(() => {
    if (!timeTrackingSettings) {
      void fetchTimeTrackingSettings();
    }
  }, [fetchTimeTrackingSettings, timeTrackingSettings]);

  useTimeTrackingSettingsCleanUp();

  return (
    <>
      <HeadingTip
        label={t({
          id: 'time_tracking_settings.heading.time_tracking',
          message: 'Time tracking',
        })}
      />
      {timeTrackingSettings ? <TimeTrackingForms /> : <SettingsLoadingSpinnerCss />}
    </>
  );
};
