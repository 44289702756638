import { ThemeUIStyleObject } from 'theme-ui';

export const disconnectSx: ThemeUIStyleObject = {
  color: 'settings.disconnect.default',
  cursor: 'pointer',
  '&:hover': {
    color: 'settings.disconnect.hover',
  },
};

export const expiredSx: ThemeUIStyleObject = {
  color: '"settings.expired.default"',
  cursor: 'pointer',
  '&:hover': {
    color: 'settings.expired.hover',
  },
};

export const integrationConnectedSx: ThemeUIStyleObject = {
  position: 'relative',
  boxSizing: 'border-box',
  borderColor: 'settings.connected.green',
};

export const integrationExpiredSx: ThemeUIStyleObject = {
  position: 'relative',
  boxSizing: 'border-box',
  borderColor: 'settings.expired.border',
};

export const connectedTagSx: ThemeUIStyleObject = {
  position: 'absolute',
  top: 3,
  right: 3,
  fontSize: 0,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'settings.connected.green',
  bg: 'settings.connected.green',
  color: 'settings.text',
};

export const expiredTagSx: ThemeUIStyleObject = {
  position: 'absolute',
  top: 3,
  right: 3,
  fontSize: 0,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'settings.expired.tag',
  bg: 'settings.expired.tag',
  color: 'settings.text',
};
