import _ from 'lodash';
import { useCallback, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { useMount } from 'hooks/useMount/useMount';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { locationChangeRegisteredListenersAtom } from 'state/locationObserver';
import { CustomEvents } from 'utils/customEvents';

/**
 * This hook, combined with LocationObserver will call the provided callback on every location change.
 * Don't use this hook if you have access to useHistory or useLocation
 */

export const useOnLocationChange = (callback: () => void, registerOnMount = true) => {
  const setLocationChangeRegisteredListeners = useSetRecoilState(locationChangeRegisteredListenersAtom);
  const listenerIdRef = useRef(_.uniqueId());

  const register = useCallback(() => {
    setLocationChangeRegisteredListeners((prevState) => [...prevState, listenerIdRef.current]);
  }, [setLocationChangeRegisteredListeners]);

  const unregister = useCallback(() => {
    setLocationChangeRegisteredListeners((prevState) => prevState.filter((id) => id !== listenerIdRef.current));
  }, [setLocationChangeRegisteredListeners]);

  useMount(() => {
    if (!registerOnMount) {
      return;
    }
    register();
  });
  useUnmount(unregister);

  useCustomEventListener(CustomEvents.LOCATION_CHANGE, callback);

  return {
    register,
    unregister,
  };
};
