import React from 'react';
import { ThemeUIStyleObject, Text } from 'theme-ui';

type Props = {
  children: React.ReactNode;
  sx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  sx: undefined,
};

export const LeadText = ({ sx, children, ...props }: Props): React.ReactElement => (
  <Text
    as="p"
    variant="pLead"
    {...props}
    sx={{
      mb: 3,
      ...(sx && sx),
    }}
  >
    {children}
  </Text>
);

LeadText.defaultProps = defaultProps;
