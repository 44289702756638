import React from 'react';
import { useLocation, useResolvedPath, AppLinkProps } from 'react-router-dom';

import { APP_LINK_BASENAME } from 'constants/common';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';

import { AnchorButton, AnchorButtonProps } from './AnchorButton';

type Props = AnchorButtonProps &
  AppLinkProps & {
    toValidation?: () => boolean;
    toPromiseValidation?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => Promise<boolean>;
    disabled?: boolean;
  };

export type LinkButtonProps = Props;

const defaultProps: Partial<Props> = {
  toValidation: undefined,
  replace: false,
};

export const LinkButton = React.forwardRef<HTMLAnchorElement, Props>(
  (
    {
      to,
      children,
      onClick,
      toValidation,
      toPromiseValidation,
      replace,
      state,
      relative,
      disabled,
      sx,
      ...props
    }: Props,
    ref,
  ) => {
    const navigate = useAppNavigate();
    const location = useLocation();
    const { pathname: absolutePathname } = useResolvedPath(to, { relative });

    const internalOnClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();

      if (toValidation && !toValidation()) {
        return;
      }

      if (toPromiseValidation) {
        const validationResult = await toPromiseValidation(e);

        if (!validationResult) return;
      }

      if (replace) {
        navigate(to, { replace, state, relative });
      } else {
        navigate(to, { relative, state: state || { from: location.pathname } });
      }

      if (onClick) onClick(e);
    };

    return (
      <AnchorButton
        ref={ref}
        onClick={!disabled ? internalOnClick : undefined}
        data-disabled={disabled}
        sx={{
          ...sx,
          '&[data-disabled="true"]': {
            pointerEvents: 'none',
            opacity: 0.5,
          },
        }}
        {...props}
        href={`${APP_LINK_BASENAME}${absolutePathname}`}
      >
        {children}
      </AnchorButton>
    );
  },
);

LinkButton.defaultProps = defaultProps;
