import { atom, selector } from 'recoil';

import { TimeEvent } from 'api/actions/timeclock/timeclockActions.types';
import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';

type Props = {
  selectedTimeEvent?: TimeEvent;
  showChangeTimeEvent: boolean;
};

export const summaryAtom = atom<Props>({
  key: 'kiosk__summaryAtom',
  default: {
    showChangeTimeEvent: false,
  },
});

export const showChangeTimeEventSelector = selector<Props['showChangeTimeEvent']>({
  key: 'kiosk__showChangeStatus',
  get: ({ get }) => {
    const { showChangeTimeEvent } = get(summaryAtom);

    return showChangeTimeEvent;
  },
  set: ({ get, set }, newState) => {
    const { showChangeTimeEvent } = get(summaryAtom);

    if (newState !== showChangeTimeEvent && !isRecoilDefaultValue(newState)) {
      set(summaryAtom, (prevState) => ({ ...prevState, showChangeTimeEvent: newState }));
    }
  },
});

export const selectedTimeEventSelector = selector<Props['selectedTimeEvent']>({
  key: 'kiosk__selectedTimeEvent',
  get: ({ get }) => {
    const { selectedTimeEvent } = get(summaryAtom);

    return selectedTimeEvent;
  },
  set: ({ get, set }, newState) => {
    const { selectedTimeEvent } = get(summaryAtom);

    if (newState !== selectedTimeEvent && !isRecoilDefaultValue(newState)) {
      set(summaryAtom, (prevState) => ({ ...prevState, selectedTimeEvent: newState }));
    }
  },
});
