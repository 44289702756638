import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { ExtendedTimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { Avatar } from 'components/Avatar/Avatar';
import { Tag } from 'components/Tag/Tag';
import { TagStack } from 'components/Tag/TagStack';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';

type BusinessCardProps = Pick<ExtendedTimeEvent, 'employeeName' | 'tags' | 'avatarUrl'> & {
  personRole: ExtendedTimeEvent['role'];
  sx?: ThemeUIStyleObject;
};

export const BusinessCard = ({
  employeeName: { firstName, surname },
  tags,
  personRole,
  avatarUrl = undefined,
  sx = undefined,
}: BusinessCardProps): React.ReactElement => {
  useLingui();
  const getFullName = useNameDisplayOrder();
  const { color: roleColor, name: roleName } = personRole;

  return (
    <Flex sx={{ alignItems: 'center', columnGap: 2, ...(sx && sx) }}>
      {avatarUrl && <Avatar image={avatarUrl} />}

      <Flex sx={{ flexWrap: 'wrap', gap: '2px' }}>
        <TextEllipsis title={getFullName(firstName, surname)} sx={{ maxWidth: '300px' }}>
          {getFullName(firstName, surname)}
        </TextEllipsis>

        <Flex sx={{ gap: '0.125rem', overflow: 'hidden', width: '100%' }}>
          <Tag
            color={UserSelectableColor[roleColor || UserSelectableColor.grays2]}
            variant="outline"
            sx={{ flex: '0 0 auto', maxWidth: '120px' }}
          >
            {t({ id: roleName })}
          </Tag>

          <TagStack tags={tags} popperWidth={600} />
        </Flex>
      </Flex>
    </Flex>
  );
};
