/** @jsxImportSource theme-ui */

import { keyframes } from '@emotion/react';
import React, { useRef } from 'react';
import { Flex } from 'theme-ui';

import { ModalBackdropProps } from '../types';

const animationKeyframes = {
  closeModal: keyframes({
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(0)',
      opacity: 0,
    },
  }),
  minimizeModal: keyframes({
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(0) translateY(-30%)',
      opacity: 0,
    },
  }),
};

export const ModalBackdrop = ({
  closingAnimationDuration,
  isClosing,
  isMinimized,
  handleClose,
  children,
  sx,
  ...props
}: ModalBackdropProps): React.ReactElement => {
  const modalBackdropRef = useRef<HTMLDivElement | null>(null);
  const shouldCloseRef = useRef(true);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    shouldCloseRef.current = modalBackdropRef.current === e.target;
  };
  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    shouldCloseRef.current = modalBackdropRef.current === e.target && shouldCloseRef.current;
  };
  const handleClick = () => {
    if (!shouldCloseRef.current) {
      shouldCloseRef.current = true;
      return;
    }
    if (handleClose) handleClose();
  };

  return (
    <Flex
      ref={modalBackdropRef}
      {...props}
      sx={{
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        ...(isMinimized && { transformOrigin: 'bottom center' }),
        ...(!isClosing && { bg: 'modal.backdrop' }),
        ...(isClosing && {
          animation: `${isMinimized ? animationKeyframes.minimizeModal : animationKeyframes.closeModal} ${closingAnimationDuration / 1000}s forwards`,
        }),
        ...sx,
      }}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {children}
    </Flex>
  );
};
