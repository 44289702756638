import React from 'react';

import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';

type Props = {
  firstName: string;
  surname: string;
};

export const FullName = ({ firstName, surname }: Props): React.ReactElement => {
  const getFullName = useNameDisplayOrder();
  return <>{getFullName(firstName, surname)}</>;
};
