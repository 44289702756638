import { Trans } from '@lingui/macro';
import React, { useState } from 'react';
import { Text } from 'theme-ui';

import { LinkAnchor } from 'components/ui/LinkAnchor';
import { EXTERNAL_LINKS } from 'constants/externalLinks';

export const PrivacyInformation = (): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <Text as="p" sx={{ alignSelf: 'center', color: 'texts.lighter' }}>
        <Trans id="sign_up.privacy">Your data is administered by inEwi sp. z o.o. </Trans>{' '}
        <Text
          as="span"
          sx={{
            textDecoration: 'underline',
            transition: 'all ease-in-out .15s',
            cursor: 'pointer',
            '&:hover': {
              color: 'signUp.privacy',
            },
          }}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? (
            <Trans id="sign_up.privacy_button_hide">Hide</Trans>
          ) : (
            <Trans id="sign_up.privacy_button_show">Show</Trans>
          )}
        </Text>
      </Text>

      {isExpanded && (
        <Text as="p" sx={{ alignSelf: 'center', color: 'signUp.text.secondary' }}>
          <Trans id="sign_up.privacy_dropdown">
            inEwi sp. z o.o. ul. 1 Maja 15, 43-300 Bielsko-Biała is the Controller of your personal data. Your personal
            data will be processed only for the purposes related to satisfaction of our and your obligations resulting
            from the Terms of Service{' '}
            <LinkAnchor sx={{ textDecoration: 'underline' }} href={EXTERNAL_LINKS.PERSONAL_DATA} target="_blank">
              Read more
            </LinkAnchor>
            .
          </Trans>
        </Text>
      )}
    </>
  );
};
