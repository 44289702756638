import { t } from '@lingui/macro';
import range from 'lodash/range';
import { FC, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { YearSelect } from 'components/recipes/YearSelect';
import { Calendar } from 'components/ui/DatePicker/Calendar/Calendar';
import { CURRENT_YEAR, DEFAULT_LAST_YEAR } from 'constants/team';
import { minSelectableDateUnixSelector, minSelectableYearSelector } from 'state/team';
import { dateTime, getUtcStartOfDayUnix } from 'utils/dateTime';
import { setIntervalX } from 'utils/setIntervalX';

type Props = {
  variant: 'date' | 'year';
};

export type AddEditFromModalState = {
  excludedFroms?: number[];
  editedFrom?: number;
};

export const AddEditFromModal: FC<Props> = ({ variant }) => {
  const { handleClose, state, resolver } = useModal<number | null, AddEditFromModalState>();

  const { excludedFroms, editedFrom } = state;

  const minSelectableYear = useRecoilValue(minSelectableYearSelector);
  const minSelectableDateUnix = useRecoilValue(minSelectableDateUnixSelector);

  const today = useMemo(() => getUtcStartOfDayUnix(dateTime().unix(), true), []);

  const defaultFrom = useMemo(() => {
    if (!excludedFroms?.length) {
      switch (variant) {
        case 'date':
          return today;
        case 'year':
          return CURRENT_YEAR;
        default:
          return CURRENT_YEAR;
      }
    }

    if (variant === 'date') {
      const lastSelectedDate = Math.max(...excludedFroms);
      const oneDayInSeconds = 86400;
      const nextDate = excludedFroms.length ? lastSelectedDate + oneDayInSeconds : today;
      return nextDate;
    }

    const yearRange = range(minSelectableYear, DEFAULT_LAST_YEAR + 1);
    const unselectedYears = yearRange.filter((y) => !excludedFroms.includes(y));
    const firstUnselectedYear = unselectedYears.sort((a, b) => a - b)[0];
    const yearAfterLastSelected = excludedFroms.length ? Math.max(...excludedFroms) + 1 : null;
    const isYearAfterLastSelectedValid = yearAfterLastSelected
      ? unselectedYears.includes(yearAfterLastSelected)
      : false;
    const yearCandidate = isYearAfterLastSelectedValid ? yearAfterLastSelected : firstUnselectedYear;
    const nextYear = yearCandidate || CURRENT_YEAR;

    return nextYear;
  }, [excludedFroms, minSelectableYear, variant, today]);

  const initialFrom = (() => {
    if (!editedFrom) return `${defaultFrom}`;

    return '';
  })();

  const [selectedFrom, setSelectedFrom] = useState<string>(initialFrom);

  const yearSelectRange: [number, number] = [minSelectableYear, Math.max(DEFAULT_LAST_YEAR, defaultFrom)];

  const decription = (() => {
    if (variant === 'year') {
      return !editedFrom
        ? t({ id: 'add_edit_from_modal.text.add.year', message: 'Please select a from year for the new entry.' })
        : t({ id: 'add_edit_from_modal.text.edit.year', message: 'Please select a from year for the entry.' });
    }

    return !editedFrom
      ? t({ id: 'add_edit_from_modal.text.add.date', message: 'Please select a from date for the new entry.' })
      : t({ id: 'add_edit_from_modal.text.edit.date', message: 'Please select a from date for the entry.' });
  })();

  const intervalSetRef = useRef(false);

  if (!excludedFroms || !resolver) {
    if (intervalSetRef.current) return null;
    setIntervalX(
      () => {
        handleClose();
      },
      10,
      5,
    );
    intervalSetRef.current = true;
    return null;
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {!editedFrom
            ? t({ id: 'add_edit_from_modal.title.add', message: 'Add new entry' })
            : t({ id: 'add_edit_from_modal.title.edit', message: 'Edit entry' })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body sx={{ pb: 5 }}>
        <Text mb={4}>{decription}</Text>
        {variant === 'date' && (
          <Calendar
            sx={{ width: '300px' }}
            variant="fromModal"
            onStateChange={(d) => {
              const selectedDate = d[0];
              if (editedFrom && selectedDate === getUtcStartOfDayUnix(editedFrom, true)) return;
              setSelectedFrom(`${selectedDate}`);
            }}
            selectedDateTimes={editedFrom && !selectedFrom ? [editedFrom] : [+selectedFrom]}
            minDate={dateTime(minSelectableDateUnix)}
            excludedDates={excludedFroms}
          />
        )}
        {variant === 'year' && (
          <YearSelect
            range={yearSelectRange}
            excludedYears={excludedFroms}
            label={t({ id: 'team.workday_dur.from_year', message: 'From' })}
            id="year"
            defaultValue={selectedFrom}
            onChange={(e) => setSelectedFrom(e.target.value)}
            sx={{ width: '160px' }}
            placeholder={editedFrom ? `${editedFrom}` : ''}
          />
        )}
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            onClick: () => {
              resolver(+selectedFrom);
              handleClose();
            },
            children: editedFrom ? t({ id: 'save' }) : t({ id: 'add' }),
            variant: 'primary',
            disabled: !selectedFrom,
          },
        ]}
      />
    </>
  );
};
