import React from 'react';

import { Icon, IconProps } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { AnchorButtonProps, LinkButton, LinkButtonProps } from 'components/ui/Buttons';
import { usePathnameMatch } from 'hooks/usePathnameMatch/usePathnameMatch';
import { useTheme } from 'styles/useTheme';

type Props = Omit<LinkButtonProps, 'to' | 'prependWith' | 'variant' | 'shape'> &
  AnchorButtonProps & {
    icon: Icons;
    withDot?: boolean;
    to: string;
    iconProps?: Partial<IconProps>;
  };

const defaultProps: Partial<Props> = {
  withDot: false,
  iconProps: undefined,
};

export const IconLink = ({ to, icon, withDot, iconProps, ...props }: Props): React.ReactElement => {
  const active = usePathnameMatch([to]);

  const { theme } = useTheme();

  return (
    <LinkButton
      to={to}
      {...props}
      sx={{
        ...(active && {
          color: 'navbar.iconButton.color.active',
          backgroundColor: `${theme.colors.navbar.iconButton.background.active} !important`,
        }),
        ...(props.sx && props.sx),
      }}
      bgOverwrite={{
        default: 'navbar.iconButton.background.default',
        hover: 'navbar.iconButton.background.hover',
        tap: 'navbar.iconButton.background.tap',
        disabled: 'navbar.iconButton.background.disabled',
      }}
      prependWith={<Icon type={icon} {...(iconProps && iconProps)} />}
    />
  );
};

IconLink.defaultProps = defaultProps;
