import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { Flex, Text } from 'theme-ui';

import { setNativeValue } from 'utils/setNativeValue';
import { TimePicker } from '../TimePicker/TimePicker';

type Props = {
  selectedTime: number;
  onTimeChange: (timeUnix: number) => void;
  onTimeBlur?: (timeUnix: number) => void;
  error?: boolean;
  errorMessage?: string;
};

export const SingleTimePicker = ({
  selectedTime,
  onTimeChange,
  onTimeBlur,
  error,
  errorMessage,
}: Props): React.ReactElement => {
  useLingui();
  const timePickerRef = useRef<HTMLInputElement | null>(null);

  const debouncedOnTimeChange = _.debounce((timeUnix: number) => onTimeChange(timeUnix), 75);

  useEffect(() => {
    if (
      timePickerRef.current &&
      _.parseInt(timePickerRef.current.value, 10) !== selectedTime &&
      !_.isNaN(selectedTime)
    ) {
      setNativeValue(timePickerRef, `${selectedTime}`);
    }
  }, [selectedTime]);

  const handleTimePickerChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const parsedValue = _.parseInt(value);

      if (parsedValue !== selectedTime) {
        debouncedOnTimeChange(parsedValue);
      }
    },
    [debouncedOnTimeChange, selectedTime],
  );

  const handleStartTimePickerBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!onTimeBlur) return;

      const { value } = e.target;
      const parsedValue = _.parseInt(value);

      onTimeBlur(parsedValue);
    },
    [onTimeBlur],
  );

  return (
    <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <Text sx={{ fontSize: 2, fontWeight: 'bold' }} as="span">
        <Trans id="dual_calendar.time">Time</Trans>
      </Text>
      <TimePicker
        id="calendarEndTime"
        ref={timePickerRef}
        onChange={handleTimePickerChange}
        onBlur={handleStartTimePickerBlur}
        size="sm"
        error={error}
        errorMessage={errorMessage}
      />
    </Flex>
  );
};
