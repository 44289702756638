import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { userNotificationsSettingsAction } from 'api/actions/settings/settingsActions';
import { UserNotificationsSettingsActionProps } from 'api/actions/settings/settingsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { blockTransitionAtom, resetFormButtonAtom } from 'state/settings';
import { useNotificationsSettings } from '../../hooks/useNotificationsSettings';

type Props = {
  handleFormReset: () => void;
  getCurrentFormState: () => Required<UserNotificationsSettingsActionProps>;
};

export const useSubmitNotifications = ({ handleFormReset, getCurrentFormState }: Props) => {
  useLingui();
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const [{ isRequestPending }, setBlockTransition] = useRecoilState(blockTransitionAtom);
  const { notificationsSettings, setNotificationsSettings } = useNotificationsSettings();
  const { mutate } = useMutation(userNotificationsSettingsAction);
  const isRequestPendingRef = useCallbackRef(isRequestPending);
  const getCurrentFormStateRef = useCallbackRef(getCurrentFormState);
  const prevFormStateRef = useRef<Required<UserNotificationsSettingsActionProps> | null>(notificationsSettings);
  const pendingRequestBodyRef = useRef<UserNotificationsSettingsActionProps | null>(null);

  const handleSubmitCallback = useCallback(
    async (data: Required<UserNotificationsSettingsActionProps>) => {
      const submittedFormState = data;

      if (
        notificationsSettings &&
        _.isEqual(data, notificationsSettings) &&
        !pendingRequestBodyRef.current &&
        !isRequestPendingRef.current
      ) {
        setBlockTransition((prevState) => ({ ...prevState, blockTransition: false }));
        return;
      }

      const currentChanges: UserNotificationsSettingsActionProps = _.omitBy(
        submittedFormState,
        (value, key) =>
          prevFormStateRef.current &&
          value === prevFormStateRef.current[key as keyof UserNotificationsSettingsActionProps],
      );

      setResetCallbacks(null);
      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: true }));

      prevFormStateRef.current = submittedFormState;
      pendingRequestBodyRef.current = { ...pendingRequestBodyRef.current, ...currentChanges };

      const formStateBeforeRequest = getCurrentFormStateRef.current();

      const { error, status } = await mutate(pendingRequestBodyRef.current);

      const formStateAfterRequest = getCurrentFormStateRef.current();

      if (!_.isEqual(formStateBeforeRequest, formStateAfterRequest)) {
        return;
      }

      if (!error) {
        setNotificationsSettings(submittedFormState);
        pendingRequestBodyRef.current = null;
        addSnackbar({
          message: t({
            id: 'settings.forms.submit_success',
          }),
          variant: 'success',
        });
      }

      if (error) {
        if (!status) {
          return;
        }
        const notificationsResetObject = { name: 'NOTIFICATIONS_CALLBACK', callback: handleFormReset };
        setResetCallbacks((prevState) =>
          !prevState ? [notificationsResetObject] : [...prevState, notificationsResetObject],
        );
        return;
      }

      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: false, blockTransition: false }));
    },
    [
      getCurrentFormStateRef,
      handleFormReset,
      isRequestPendingRef,
      mutate,
      notificationsSettings,
      setBlockTransition,
      setNotificationsSettings,
      setResetCallbacks,
    ],
  );

  return handleSubmitCallback;
};
