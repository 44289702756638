import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { RadioButton } from 'components/ui/RadioButton/RadioButton';
import { RadioButtonGroup } from 'components/ui/RadioButton/RadioButtonGroup';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { useMount } from 'hooks/useMount/useMount';
import { organizationSessionAtom } from 'state/organizationSession';
import { useAddRequest } from '../hooks/useAddRequest';

export const AddRequestStep2 = (): React.ReactElement => {
  useLingui();

  const organizationSession = useRecoilValue(organizationSessionAtom);
  const {
    selectedTeammatesIds,
    addRequestState: { requestType },
    setAddRequestState,
  } = useAddRequest();

  const { modules } = useAppPermissions();
  const { isCalendar } = useAppRouting(['isCalendar']);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAddRequestState((prevState) => ({ ...prevState, requestType: +e.target.value }));
    },
    [setAddRequestState],
  );

  const isCalendarLocation = useMemo(() => {
    if (requestType === RequestFormType.TimeTracking) return false;

    if (isCalendar) return true;

    return false;
  }, [isCalendar, requestType]);

  useMount(() => {
    if (requestType === RequestFormType.Schedule && selectedTeammatesIds.length > 1) {
      setAddRequestState((prevState) => ({ ...prevState, requestType: RequestFormType.TimeOff }));
    }
  });

  return (
    <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
      <Modal.SubTitle>
        <Trans id="requests.add_request.select_request_Type">Select a request type</Trans>
      </Modal.SubTitle>
      <RadioButtonGroup name="radioButtonGroup" orientation="column" onChange={handleOnChange}>
        <RadioButton
          label={t({
            id: 'request.type.time_off',
          })}
          additionalLabel={t({
            id: 'request.type.time_off.description',
            message: 'for ex. PTO, vacation, sick leave',
          })}
          value={RequestFormType.TimeOff}
          prependWidth={<Icon type="timeOff" wrapperSx={{ mr: 2 }} />}
          checked={requestType === RequestFormType.TimeOff}
        />
        <RadioButton
          label={t({
            id: 'request.type.custom',
          })}
          additionalLabel={t({
            id: 'request.type.custom.description',
            message: 'for ex. work from home',
          })}
          value={RequestFormType.Custom}
          prependWidth={<Icon type="customRequest" wrapperSx={{ mr: 2 }} />}
          checked={requestType === RequestFormType.Custom}
        />
        {organizationSession?.allowRequestForBusinessTrips ? (
          <RadioButton
            label={t({
              id: 'request.type.business_trip',
            })}
            additionalLabel={t({
              id: 'request.type.business_trip.description',
              message: 'and all related to it',
            })}
            value={RequestFormType.BusinessTrip}
            prependWidth={<Icon type="businessTrip" wrapperSx={{ mr: 2 }} />}
            checked={requestType === RequestFormType.BusinessTrip}
          />
        ) : (
          <></>
        )}
        {organizationSession?.allowChangeRequestForLoggedClockInsAndOuts &&
        !isCalendarLocation &&
        modules.TimeTracking ? (
          <RadioButton
            label={t({
              id: 'request.type.time_tracking',
            })}
            additionalLabel={t({
              id: 'request.type.time_tracking.description',
              message: 'add, edit or remove a time entry',
            })}
            value={RequestFormType.TimeTracking}
            prependWidth={<Icon type="timeClock" wrapperSx={{ mr: 2 }} />}
            checked={requestType === RequestFormType.TimeTracking}
          />
        ) : (
          <></>
        )}
        {modules.Schedule && organizationSession?.allowRequestForSchedules ? (
          <RadioButton
            label={t({ id: 'add_request.shift_swap', message: 'Shift swap' })}
            additionalLabel={t({ id: 'add_request.shift_swap.desc', message: 'Swap scheduled day between teammates' })}
            value={RequestFormType.Schedule}
            prependWidth={<Icon type="calendar" wrapperSx={{ mr: 2 }} />}
            disabled={selectedTeammatesIds.length > 1}
            checked={requestType === RequestFormType.Schedule}
          />
        ) : (
          <></>
        )}
      </RadioButtonGroup>
    </Flex>
  );
};
