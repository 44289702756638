import { useCallback, useMemo } from 'react';
import { Flex } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { dateTime } from 'utils/dateTime';

import { DateBox } from './components/DateBox';
import { useCalendarDatePicker } from './hooks/useCalendarDatePicker';

export const CalendarDatePicker = () => {
  const { activeDate, setActiveDate, setVisibleDates, visibleDates } = useCalendarDatePicker();

  const todayDateUnix = useMemo(() => dateTime(undefined).startOf('day').utc(true).unix(), []);

  const handleSetActiveDate = useCallback(
    (dateUnix: number) => {
      setActiveDate(dateUnix);
    },
    [setActiveDate],
  );

  const renderDates = useCallback(() => {
    if (visibleDates) {
      const { startDateUnix } = visibleDates;
      const datesArr = new Array(5).fill(null).map((val, index) => startDateUnix + index * 24 * 60 * 60);

      return datesArr.map((unix) => {
        const isToday = unix === todayDateUnix;
        const isActive = unix === activeDate;

        return (
          <DateBox
            key={unix}
            dateUnix={unix}
            isToday={isToday}
            isActive={isActive}
            onClick={() => handleSetActiveDate(unix)}
          />
        );
      });
    }

    return null;
  }, [activeDate, handleSetActiveDate, todayDateUnix, visibleDates]);

  const handleNextDays = useCallback(() => {
    if (visibleDates) {
      const { endDateUnix } = visibleDates;
      const newStartDateUnix = endDateUnix + 1 * 24 * 60 * 60;
      const newEndDateUnix = newStartDateUnix + 4 * 24 * 60 * 60;

      setVisibleDates({ startDateUnix: newStartDateUnix, endDateUnix: newEndDateUnix });
    }
  }, [setVisibleDates, visibleDates]);

  const handlePrevDays = useCallback(() => {
    if (visibleDates) {
      const { startDateUnix } = visibleDates;
      const newStartDateUnix = startDateUnix - 5 * 24 * 60 * 60;
      const newEndDateUnix = newStartDateUnix + 4 * 24 * 60 * 60;

      setVisibleDates({ startDateUnix: newStartDateUnix, endDateUnix: newEndDateUnix });
    }
  }, [setVisibleDates, visibleDates]);

  return (
    <Flex sx={{ px: 3 }}>
      <Button variant="naked" sx={{ px: 1 }} onClick={handlePrevDays}>
        <Icon type="arrowLeft" />
      </Button>
      <Flex sx={{ flexDirection: 'row', flexGrow: 1, gap: '0.125rem', p: '1px' }}>{renderDates()}</Flex>

      <Button variant="naked" sx={{ px: 1 }} onClick={handleNextDays}>
        <Icon type="arrowRight" />
      </Button>
    </Flex>
  );
};
