/* eslint-disable no-console */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
// import './utils/wdyr'; // WDYR only use on dev build and if you want to optimize perf.

import ReactDOMClient from 'react-dom/client';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

import { Root } from 'base/Root/output/Root';
import { APP_NAME } from 'constants/common';

// Disable console.logs on production build
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.table = () => {};
}

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_API_URL === '') {
  const { worker } = await import('./mocks/browser');

  void worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const tagManagerArgs: TagManagerArgs = {
  gtmId: APP_NAME === 'inEwi' ? 'GTM-TSKXF4' : 'GTM-WNDSNS8',
};

// GTM INITIALIZATION
if (process.env.NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs);
}

const container = document.getElementById('app') as HTMLDivElement;
const root = ReactDOMClient.createRoot(container);

root.render(<Root />);
