import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isUndefined from 'lodash/isUndefined';
import { forwardRef, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import { Flex, Heading, Text, ThemeUIStyleObject } from 'theme-ui';

import {
  FetchOrganizationSessionResponse,
  VisibilityLevels,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { TagDetails } from 'api/actions/tags/tagsActions.types';
import { ColorPicker } from 'components/ui/ColorPicker';
import { Switch } from 'components/ui/Switch';
import { TextInput } from 'components/ui/TextInput';
import { Textarea } from 'components/ui/Textarea';
import { FEATURES_DEFAULT_VALUE } from 'constants/defaults';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { organizationSessionPropertySelectorFamily } from 'state/organizationSession';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { getNumberField } from 'utils/getNumberField';
import { getTextField } from 'utils/getTextField';

import { AdvancedFeaturesFieldArray } from './formsElements/AdvancedFeaturesFieldArray';

const labelSx: ThemeUIStyleObject = {
  ml: 2,
  fontSize: 1,
  fontWeight: 'bold',
  mt: 3,
};

const forceCorrectTypes = ({ additionalInfo, color, visibilityLevel, ...rest }: TagDetails): TagDetails => ({
  ...rest,
  additionalInfo: getTextField(additionalInfo),
  color: +color,
  visibilityLevel: getNumberField(visibilityLevel),
});

type Props = {
  onlyInformational?: boolean;
  defaultValues?: Partial<TagDetails>;
  onSubmit: (props: TagDetails) => Promise<boolean>;
  setLoading: (loading: boolean) => void;
};

const guardFormValues = ({
  name,
  color,
  isInformational,
  features,
  ...rest
}: Partial<TagDetails>): TagDetails | null => {
  if (isUndefined(name) || isUndefined(color)) return null;
  return {
    name,
    color,
    isInformational: !!isInformational,
    ...rest,
    ...(isInformational ? { features: undefined } : { features }),
  };
};

export const AddEditTag = forwardRef<HTMLFormElement, Props>(
  (
    {
      onlyInformational,
      onSubmit,
      defaultValues = {
        features: FEATURES_DEFAULT_VALUE,
        visibilityLevel: VisibilityLevels.Year,
      },
      setLoading,
    },
    ref,
  ) => {
    useLingui();
    const {
      watch,
      control,
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: 'onTouched',
      reValidateMode: 'onChange',
      defaultValues,
    });
    const { isInformational } = watch();
    const tags = useRecoilValue(
      organizationSessionPropertySelectorFamily('tags') as RecoilValueReadOnly<
        FetchOrganizationSessionResponse['tags'] | null
      >,
    );

    const restrictedTagNames = useMemo(() => {
      if (!tags) return [];
      return tags
        .map(({ name }) => name.trim())
        .filter((name) => {
          if (!defaultValues) return true;
          return name !== defaultValues.name;
        });
    }, [tags, defaultValues]);

    const handleSubmitCallback = useCallback(
      (body: Partial<TagDetails>) => {
        const formValues = guardFormValues(body);
        if (!formValues) return;
        void onSubmit(forceCorrectTypes(formValues));
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    return (
      <form
        ref={ref}
        onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))}
        noValidate
      >
        <Heading variant="heading4.withMargins">
          <Trans id="team.add_edit_tag.basic_details">Basic details</Trans>
        </Heading>
        <Text sx={labelSx}>
          <Trans id="team.add_edit_tag.name">Name</Trans>
        </Text>
        <TextInput
          clearable
          size="sm"
          id="name"
          placeholder={t({ id: 'team.add_edit_tag.name', message: 'Name' })}
          variant="rounded"
          error={!!errors.name}
          errorMessage={errors?.name?.message}
          {...register(
            'name',
            validationFactory({
              ...VALIDATION_RULES.TAG_NAME,
              required: true,
              restrictedValues: restrictedTagNames,
            }),
          )}
        />
        <Text as="div" sx={labelSx}>
          <Trans id="team.add_edit_tag.additional_info">Additional information - not visible to Employees</Trans>
        </Text>
        <Textarea
          size="sm"
          id="additionalInfo"
          placeholder={t({
            id: 'team.add_edit_tag.additional_info.description',
            message: 'Private notes, logs and informations',
          })}
          variant="rounded"
          error={!!errors.additionalInfo}
          errorMessage={errors?.additionalInfo?.message}
          {...register('additionalInfo', validationFactory(VALIDATION_RULES.TAG_ADDITIONAL_INFO))}
        />
        <Heading mt={4} variant="heading4.withMargins">
          <Trans id="team.add_edit_tag.color">Color</Trans>
        </Heading>
        <ColorPicker sx={{ mb: 4 }} {...register('color')} />

        <Flex
          sx={{
            mb: 5,
            padding: 3,
            bg: 'team.addEditTag',
            borderRadius: 'default',
            justifyContent: 'space-between',
          }}
        >
          <Flex sx={{ flexDirection: 'column', lineHeight: 'normal', fontSize: 2 }}>
            <Text sx={{ fontWeight: 'bold' }}>
              <Trans id="team.add_edit_tag.informational_tag">Informational tag</Trans>
            </Text>
            <Text sx={{ color: 'texts.lighter' }}>
              <Trans id="team.add_edit_tag.informational_tag_description">
                <Text>Used strictly for sorting and grouping purposes.</Text>
                <Text sx={{ textDecoration: 'underline' }}>Visible only to Managers and Administrators.</Text>
              </Trans>
            </Text>
          </Flex>
          {!onlyInformational && (
            <Flex sx={{ ml: 5 }}>
              <Switch {...register('isInformational')} defaultChecked={isInformational} placement="right" size="sm" />
            </Flex>
          )}
        </Flex>

        {!onlyInformational && !isInformational && (
          <AdvancedFeaturesFieldArray register={register} control={control} watch={watch} />
        )}
      </form>
    );
  },
);
