import { t } from '@lingui/macro';
import React, { useEffect } from 'react';
import { Flex, Text } from 'theme-ui';

import { images } from 'Kiosk/constants/images';
import { usePermissions } from 'Kiosk/hooks/usePermissions';
import { PermissionState } from 'Kiosk/state/permissionState';
import { LoadingSpinnerSize } from 'components/Loading/LoadingSpinnerCSS';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { AnchorButton, Button } from 'components/ui/Buttons';
import { Image } from 'components/ui/Image';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';

const IMAGE_WIDTH_HEIGHT = '160px';

export const LocationRequiredErrorModal = (): React.ReactElement => {
  const { location } = usePermissions();
  const { handleClose } = useModal();
  const navigate = useAppNavigate();

  useEffect(() => {
    if (location === PermissionState.allow) {
      navigate(-1);
    }
  }, [location, navigate]);

  return (
    <>
      <Modal.Body sx={{ textAlign: 'center', alignItems: 'center', gap: 2 }}>
        <Image
          width={IMAGE_WIDTH_HEIGHT}
          alt={t({ id: 'kiosk.home.location_permission_modal.title' })}
          src={images.location['1x']}
          srcSet={`${images.location['2x']}, ${images.location['3x']}`}
          sx={{ minHeight: IMAGE_WIDTH_HEIGHT }}
          loadingOverlaySize={LoadingSpinnerSize.imageXL}
        />

        <Modal.Title>{t({ id: 'kiosk.home.location_permission_modal.title' })}</Modal.Title>

        <Text variant="p" as="p">
          {t({ id: 'kiosk.home.location_permission_modal.message' })}
        </Text>

        <Flex sx={{ flexDirection: 'column', alignItems: 'center', mt: 3, gap: 2 }}></Flex>
      </Modal.Body>

      <Modal.Footer sx={{ justifyContent: 'center' }}>
        <AnchorButton href={EXTERNAL_LINKS.LOCATION_HELP} target="_blank" variant="primary" shape="rounded">
          {t({ id: 'kiosk.home.location_permission_modal.how_to_enable' })}
        </AnchorButton>
        <Button
          sx={{ fontWeight: 'normal', minWidth: '100px' }}
          shape="rounded"
          variant="minimal"
          onClick={() => handleClose()}
        >
          {t({ id: 'cancel' })}
        </Button>
      </Modal.Footer>
    </>
  );
};
