import { plural, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { SubmitHandler } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { addTimeEventsAction } from 'api/actions/timeEvent/timeEventActions';
import { AddTimeEventsActionProps } from 'api/actions/timeEvent/timeEventActions.types';
import { useModal } from 'components/Modal/output/useModal';
import { ListNames } from 'components/StickyList/types';
import { usePickTeammates } from 'hooks/usePickTeammates/usePickTeammates';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { statusesMapSelectorFamily } from 'state/selectOptions';
import { ArrayElement } from 'utils/custom.types';
import { dateTime } from 'utils/dateTime';
import { useRefreshClockLog } from '../../hooks/useRefreshClockLog';
import { AddTimeEventsInput, HashedEvent } from '../types';

import { useAddEvents } from './useAddEvents';

const ONE_DAY_IN_SECONDS = 86400;

export const useSubmitAddEvents = () => {
  useLingui();
  const statuses = useRecoilValue(statusesMapSelectorFamily(null));
  const { mutate } = useMutation(addTimeEventsAction);
  const { handleClose } = useModal();
  const { addSnackbar } = useSnackbar();
  const { setIsLoadingSave: setIsLoading } = useAddEvents();
  const { user } = useParams<{ user: string }>();
  const { state } = useLocation();
  const { addTimeEvent, id } = state || {};
  const userId = id || user;
  const { prevEventTimeUtc, isEnd } = addTimeEvent || {};
  const [selectedEmployeesIds] = usePickTeammates(ListNames.CLOCK_LOG_PICK_TEAMMATES);

  const getEmployeeIds = useCallback(() => {
    if (selectedEmployeesIds.length) return selectedEmployeesIds;
    if (userId) return [userId];
    return undefined;
  }, [selectedEmployeesIds, userId]);

  const { clockLogInitialized, refreshClockLogForPeopleIds } = useRefreshClockLog(getEmployeeIds());
  const { reportInitialized, updateReportForIds } = useRefreshReport(getEmployeeIds());

  const handleSubmitCallback: SubmitHandler<AddTimeEventsInput> = useCallback(
    async ({ selectedDays, datesUnix, ignoreHolidays, hashedEvents }: AddTimeEventsInput) => {
      const peopleIds = getEmployeeIds();
      if (!peopleIds) return;

      setIsLoading(true);

      if (!statuses || !datesUnix) {
        setIsLoading(false);
        return;
      }

      const timeEvents = hashedEvents.map(({ hashedEventType, timeSeconds }: HashedEvent) => {
        const dehashedEvent = statuses.get(hashedEventType);

        return {
          timeSeconds: Number(timeSeconds),
          timeEventTypeId: dehashedEvent?.id,
          isEnd: dehashedEvent?.isEndStatus,
        };
      });

      const isTimeEvents = (
        value: AddTimeEventsActionProps['timeEvents'] | Partial<ArrayElement<AddTimeEventsActionProps['timeEvents']>>[],
      ): value is AddTimeEventsActionProps['timeEvents'] =>
        !value.some((v) => typeof v.timeEventTypeId === undefined || typeof v.isEnd === undefined);

      if (!isTimeEvents(timeEvents)) {
        setIsLoading(false);
        return;
      }

      const missingEventTimeSeconds = dateTime(prevEventTimeUtc).utc(true).unix() % ONE_DAY_IN_SECONDS;

      const shouldMissingEventBeNextDay =
        prevEventTimeUtc &&
        isEnd &&
        (timeEvents[0].timeSeconds < missingEventTimeSeconds ||
          // check if prevTimeEvent is next day (ex break start)
          datesUnix[0] < dateTime(prevEventTimeUtc).utc(true).startOf('day').unix());

      const events = {
        peopleIds,
        selectedDays,
        datesUnix: shouldMissingEventBeNextDay
          ? [dateTime(datesUnix[0], { utc: true }).add(1, 'day').unix()]
          : datesUnix,
        ignoreHolidays,
        timeEvents,
      };

      const { error } = await mutate(events);

      if (!error) {
        const eventCount = peopleIds.length * timeEvents.length;
        addSnackbar({
          variant: 'success',
          message: t({
            id: 'clock_log.add_events.success',
            message: plural(eventCount, {
              one: 'Event added successfully!',
              other: 'Events added successfully!',
            }),
          }),
        });

        if (clockLogInitialized) void refreshClockLogForPeopleIds();
        if (reportInitialized) void updateReportForIds();

        handleClose(true);
      }

      setIsLoading(false);
    },
    [
      getEmployeeIds,
      setIsLoading,
      statuses,
      prevEventTimeUtc,
      isEnd,
      mutate,
      addSnackbar,
      clockLogInitialized,
      refreshClockLogForPeopleIds,
      reportInitialized,
      updateReportForIds,
      handleClose,
    ],
  );

  const handleSubmitErrorCallback = useCallback(() => setIsLoading(false), [setIsLoading]);

  return { handleSubmitCallback, handleSubmitErrorCallback };
};
