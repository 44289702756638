import { ThemeUIStyleObject } from 'theme-ui';

import { GRID_DEFAULT_HEIGHT, GUTTER_DEFAULT_SIZE, ITEM_DEFAULT_HEIGHT } from 'components/StickyList/constants';
import { makeTheme } from '../makeTheme';

import { DAY_DETAILS_PATTERNS } from './calendar_patterns';

const headerSx: ThemeUIStyleObject = {
  position: 'sticky',
  top: 0,
  left: 0,
  maxHeight: ITEM_DEFAULT_HEIGHT,
  flexDirection: 'row',
  alignItems: 'stretch',
  flex: '1 0',
  gap: 2,
  px: '0.75rem',
  fontSize: 0,
  textTransform: 'uppercase',
  userSelect: 'none',
  zIndex: 40,
  willChange: 'transform',
  color: 'stickyList.text.header.default',
  background: 'stickyList.bg.header.default.list',
};

const gridHeaderSx: ThemeUIStyleObject = {
  ...headerSx,
  background: {},
  bg: 'stickyList.bg.header.default.grid',
  gap: 0,
  px: 0,
  pt: GRID_DEFAULT_HEIGHT - ITEM_DEFAULT_HEIGHT,
};

const rowSx: ThemeUIStyleObject = {
  willChange: 'transform',
  flex: '1 0',
  gap: 2,
  alignItems: 'center',
  width: '100%',
  px: '0.75rem',
  bg: 'list.rowBackground.default',
  color: 'stickyList.text.row.default',
  fontSize: 2,
  zIndex: 10,
  outline: '1px solid',
  outlineColor: 'stickyList.outline.row.default',
  '&:hover': {
    outlineColor: 'stickyList.outline.row.hover',
    bg: 'list.rowBackground.hover',
    boxShadow: 'stickyList.rowHover',
    zIndex: 20,
  },
  '&[data-dropdown="true"]': {
    zIndex: 30,
  },
  '&[data-first="true"]': {
    borderTopLeftRadius: 'sm',
    borderTopRightRadius: 'sm',
  },
  '&[data-last="true"]': {
    borderBottomLeftRadius: 'sm',
    borderBottomRightRadius: 'sm',
  },
  '&[data-selected="true"]': {
    bg: 'list.rowBackground.selected',
    outlineColor: 'stickyList.outline.row.selected',
    '&:hover': {
      bg: 'list.rowBackground.selectedHover',
    },
  },
  '&[data-non-editable="true"]': {
    bg: 'list.rowBackground.nonEditable',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  '&[data-non-checkable="true"]': {
    bg: 'list.rowBackground.nonEditable',
    '&:hover': {
      outlineColor: 'stickyList.outline.row.nonEditableHover',
      bg: 'list.rowBackground.nonEditableHover',
    },
  },
  '> .checkbox-wrapper': {
    flexShrink: 0,
    mr: '1.3rem',
  },
};

const cellSx: ThemeUIStyleObject = {
  bg: 'inherit',
  alignItems: 'center',
  gap: '0.125rem',
  overflow: 'hidden',
};

const gridCellSx: ThemeUIStyleObject = {
  position: 'relative',
  willChange: 'transform',
  flex: '1 0',
  alignItems: 'center',
  width: '100%',
  px: '0.75rem',
  bg: 'list.rowBackground.default',
  color: 'stickyList.text.grid.default',
  fontSize: 2,
  zIndex: 10,
  outline: '1px solid',
  outlineColor: 'stickyList.outline.grid.default',
  '&:hover': {
    outlineColor: 'stickyList.outline.grid.hover',
    bg: 'list.rowBackground.hover',
    boxShadow: 'stickyList.gridHover',
    zIndex: 20,
    // TODO: Add canvas to create the highlight for rows/column hover effect
  },
  '&[data-selected="true"]': {
    outlineColor: 'stickyList.outline.grid.selected',
    bg: 'list.rowBackground.selected',
    '&:hover': {
      bg: 'list.rowBackground.selectedHover',
    },
  },
  '&[data-dropdown="true"]': {
    zIndex: 30,
  },
  '&[data-dragging="true"]': {
    zIndex: 40,
  },
  '&[data-loading="true"]': {
    '> .loading': {
      display: 'flex',
      zIndex: 40,
    },
  },
  // This doesn't work well
  //
  // '&[data-br*="tl"]': {
  //   borderTopLeftRadius: 'sm',
  // },
  // '&[data-br*="tr"]': {
  //   borderTopRightRadius: 'sm',
  // },
  // '&[data-br*="br"]': {
  //   borderBottomRightRadius: 'sm',
  // },
  // '&[data-br*="bl"]': {
  //   borderBottomLeftRadius: 'sm',
  // },
};

const gridCellAfterSx: ThemeUIStyleObject = {
  content: '""',
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -2,
  outline: `${GUTTER_DEFAULT_SIZE / 2}px solid`,
  borderRadius: 'inherit',
};

export const stickyListStyles = makeTheme({
  stickyList: {
    header: {
      default: {
        list: {
          ...headerSx,
          boxShadow: 'stickyList.listHeaderDefault',
        },
        grid: {
          ...gridHeaderSx,
        },
      },
      inverted: {
        list: {
          ...headerSx,
          background: 'stickyList.bg.header.inverted.list',
          boxShadow: 'stickyList.listHeaderInverted',
        },
        grid: {
          ...gridHeaderSx,
          bg: 'stickyList.bg.header.inverted.grid',
        },
      },
      photosGrid: {
        grid: {
          ...gridHeaderSx,
        },
      },
      darkerOne: {
        list: {
          ...headerSx,
          background: 'stickyList.bg.header.darkerOne.list',
        },
      },
    },
    row: {
      default: {
        ...rowSx,
      },
      inverted: {
        ...rowSx,
        bg: 'list.rowBackground.inverted',
      },
      darkerOne: {
        ...rowSx,
      },
      photosGrid: {
        ...rowSx,
      },
      error: {
        ...rowSx,
        bg: 'list.rowBackground.error',
        outlineColor: 'list.rowOutline.error',
        '&:hover': {
          bg: 'list.rowBackground.errorHover',
          outlineColor: 'list.rowOutline.errorHover',
          zIndex: 20,
        },
        '&[data-selected="true"]': {
          ...(rowSx['&[data-selected="true"]'] as Record<string, unknown>),
          bg: 'list.rowBackground.errorSelected',
          outlineColor: 'list.rowOutline.errorHover',
          '&:hover': {
            bg: 'list.rowBackground.errorSelected',
          },
        },
      },
      pending: {
        ...rowSx,
        outlineColor: 'list.rowOutline.pending',
        '&::before': {
          ...DAY_DETAILS_PATTERNS.pending,
          content: '""',
          position: 'absolute',
          inset: -1,
          zIndex: -1,
          borderRadius: 'sm',
        },
        '&:hover': {
          outlineColor: 'list.rowOutline.pendingHover',
          bg: 'stickyList.bg.row.hover',
        },
        '&[data-selected="true"]': {
          background: 'stickyList.bg.row.selected',
          ...(rowSx['&[data-selected="true"]'] as Record<string, unknown>),
          outlineColor: 'list.rowOutline.pending',
        },
      },
      warning: {
        ...rowSx,
        bg: 'list.rowBackground.warning',
        outlineColor: 'list.rowOutline.warning',
        '&:hover': {
          bg: 'list.rowBackground.warningHover',
          outlineColor: 'list.rowOutline.warningHover',
          zIndex: 20,
        },
        '&[data-selected="true"]': {
          ...(rowSx['&[data-selected="true"]'] as Record<string, unknown>),
          outlineColor: 'list.rowOutline.warning',
        },
      },
    },
    cell: {
      default: {
        ...cellSx,
      },
      error: {
        ...cellSx,
        bg: 'stickyList.bg.cell.error',
      },
    },
    gridCell: {
      default: {
        ...gridCellSx,
        '&::after': {
          ...gridCellAfterSx,
          outlineColor: 'stickyList.outline.gridCell.default',
        },
      },
      inverted: {
        ...gridCellSx,
        bg: 'list.rowBackground.inverted',
        '&::after': {
          ...gridCellAfterSx,
          outlineColor: 'stickyList.outline.gridCell.inverted',
        },
      },
      photosGrid: {
        ...gridCellSx,
        '&:has(.day-cell)': {
          px: 0,
        },
        '&:has(.weekend)': {
          bg: 'stickyList.bg.grid.weekend',
        },
        '&:has(.holiday)': {
          bg: 'stickyList.bg.grid.holiday',
        },
      },
    },
  },
});
