import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { Tag } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { ListNames, ListVariant, StickyListProps } from 'components/StickyList/types';
import { ManageModal, ManageModalProps } from 'components/recipes/ManageModal';
import { LinkButton } from 'components/ui/Buttons/LinkButton';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { PATH_REL, TO_REL } from 'constants/routes';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { filteredTagsMapSelector } from 'state/employees';
import { SEARCH_FILTER_TYPE } from 'state/filters';
import { languageSelector } from 'state/recoilState';

import { AddTagModal } from './AddTag';
import { DeleteTagsModal } from './DeleteTags';
import { EditTagModal } from './EditTag';

export const ManageTagsModal: FC = () => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const tags = useRecoilValue(filteredTagsMapSelector);
  const navigate = useAppNavigate();

  const listPropsGenerator: NonNullable<ManageModalProps['listPropsGenerator']> = useCallback(
    (replace): StickyListProps<Tag> => ({
      name: ListNames.MANAGE_TAGS,
      select: 'checkbox',
      showHeader: true,
      // showContentPlaceholder: true,
      variant: ListVariant.inverted,
      list: tags || new Map(),
      fallbackSortableValueGetter: ({ name }: Tag) => name,
      columns: [
        {
          title: t({ id: 'team.manage_tags.type', message: 'Type' }),
          key: ['isInformational', 'color'],
          width: '50px',
          sortableValue: ([isInformational]: [Tag['isInformational'], Tag['color']]) => `${isInformational}`,
          customCellRenderer: ([isInformational, color]: [Tag['isInformational'], Tag['color']]) => (
            <Flex
              sx={{
                ...(isInformational
                  ? { borderRadius: 0, border: '2px solid', borderColor: UserSelectableColor[color] }
                  : { borderRadius: 'pill', bg: UserSelectableColor[color] }),
                width: '24px',
                height: '24px',
              }}
            />
          ),
        },
        {
          title: t({ id: 'team.manage_tags.name', message: 'Name' }),
          key: 'name',
          sortableValue: (name: Tag['name']) => `${name}`,
          customCellRenderer: (name: Tag['name']) => <TextEllipsis title={name}>{name}</TextEllipsis>,
        },
        {
          title: t({ id: 'team.manage_tags.additional_info', message: 'Additional information' }),
          key: 'additionalInfo',
          sortableValue: (additionalInfo: Tag['additionalInfo']) => `${additionalInfo}`,
          customCellRenderer: (additionalInfo: Tag['additionalInfo']) => (
            <TextEllipsis title={additionalInfo}>{additionalInfo}</TextEllipsis>
          ),
        },
        {
          key: 'id',
          width: '34px',
          customCellRenderer: (item) => (
            <Flex onClick={(e) => e.stopPropagation()}>
              <LinkButton
                replace={replace}
                to={TO_REL.DELETE_TAGS_MODAL[language]}
                state={{ ids: [item] }}
                variant="minimal"
                size="sm"
                shape="rounded"
              >
                <Icon size={18} type="delete" />
              </LinkButton>
            </Flex>
          ),
        },
      ],
      onRowClick: (listItemId) => {
        navigate(`${TO_REL.EDIT_TAG_MODAL__ID[language]}/${listItemId}`, { replace });
      },
    }),
    [language, tags, navigate],
  );

  const modalLinksPaths = useMemo(
    () => ({
      add: TO_REL.ADD_TAG_MODAL[language],
      delete: TO_REL.DELETE_TAGS_MODAL[language],
    }),
    [language],
  );

  return (
    <>
      <ManageModal
        allResources={tags}
        title={t({ id: 'team.manage_tags', message: 'Manage tags' })}
        listPropsGenerator={listPropsGenerator}
        modalLinksPaths={modalLinksPaths}
        searchFilterType={SEARCH_FILTER_TYPE.MANAGE_TAGS}
      />

      <Routes>
        <Route
          path={PATH_REL.ADD_TAG_MODAL[language]}
          element={
            <Modal replaceMode>
              <AddTagModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.EDIT_TAG_MODAL__ID[language]}
          element={
            <Modal replaceMode path={PATH_REL.EDIT_TAG_MODAL__ID[language]}>
              <EditTagModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.DELETE_TAGS_MODAL[language]}
          element={
            <Modal size="xs" replaceMode>
              <DeleteTagsModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
