import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { PersonName, Role, Tag } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Avatar } from 'components/Avatar/Avatar';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { ALL_SUPERVISORS_ID } from 'constants/requests';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { allEmployeesSelector, parsedEmployeesSelector } from 'state/employees';
import { InputOption, Select, SelectProps } from '../Select';

export type PersonSelectOption = InputOption & {
  name?: PersonName;
  role?: Role;
  tags?: (Tag | undefined)[];
  avatar?: string;
};

type PersonSelectProps = Omit<SelectProps, 'options'> & {
  employeesIds: string[];
  withAllEmployeesOption?: boolean;
  withDeactivatedEmployeesTooltip?: boolean;
};

export enum PersonSelectAvatarSizes {
  xs = 18,
  sm = 21,
  default = 24,
}

export const PersonSelect = React.forwardRef<HTMLInputElement, PersonSelectProps>(
  (
    {
      employeesIds,
      size,
      withAllEmployeesOption = true,
      withDeactivatedEmployeesTooltip = false,
      ...props
    }: PersonSelectProps,
    ref,
  ): React.ReactElement => {
    useLingui();
    const parsedEmployees = useRecoilValue(parsedEmployeesSelector);
    const baseEmployees = useRecoilValue(allEmployeesSelector);

    const getFullName = useNameDisplayOrder();

    const selectOptions = useMemo(() => {
      if (!baseEmployees || !parsedEmployees || !employeesIds) return [];

      const options: PersonSelectOption[] = [];

      employeesIds.forEach((id) => {
        const baseEmployee = baseEmployees.get(id);
        const parsedEmployee = parsedEmployees.get(id);

        if (!baseEmployee) return;

        const { name: employeeName, role, tags, avatarUrl } = baseEmployee;
        const { isUserActive } = parsedEmployee || {};

        options.push({
          label: getFullName(employeeName.firstName, employeeName.surname),
          name: employeeName,
          id,
          role,
          tags,
          avatar: avatarUrl,
          ...(_.isBoolean(isUserActive) &&
            !isUserActive &&
            withDeactivatedEmployeesTooltip && {
              disabled: true,
              tooltip: t({ id: 'scheduleTargetSelect.user_not_active', message: 'Deactivated employee' }),
            }),
        });
      });

      if (!withAllEmployeesOption) return options;

      const employees = [...options];

      const allEmployees: PersonSelectOption = { label: t({ id: 'requests.all_supervisors' }), id: ALL_SUPERVISORS_ID };

      employees.unshift(allEmployees);

      return employees;
    }, [
      baseEmployees,
      employeesIds,
      parsedEmployees,
      withAllEmployeesOption,
      withDeactivatedEmployeesTooltip,
      getFullName,
    ]);

    const optionContentRenderer = useCallback(
      ({ label, name, avatar }: PersonSelectOption, active?: boolean) => (
        <Flex>
          <Avatar
            circle
            size={PersonSelectAvatarSizes[size || 'default']}
            image={avatar}
            iconType="team"
            name={name ? { ...name } : undefined}
            sx={{ flexShrink: 0, alignSelf: 'center', ...(active && { color: 'select.person.color' }) }}
          />
          <TextEllipsis sx={{ ml: 2, alignSelf: 'center' }} title={label}>
            {label}
          </TextEllipsis>
        </Flex>
      ),
      [size],
    );

    const optionPropsGenerator = useCallback(
      (option: PersonSelectOption, active?: boolean) => ({
        contentRenderer: () => optionContentRenderer(option, active),
      }),
      [optionContentRenderer],
    );

    return (
      <Select
        ref={ref}
        options={selectOptions}
        customValueDisplayRenderer={optionContentRenderer}
        optionPropsGenerator={optionPropsGenerator}
        prependWith={<Avatar circle size={PersonSelectAvatarSizes[size || 'default']} iconType="team" />}
        virtualizeOptions
        searchable
        showSelectedOptionsFirst
        size={size}
        clearable
        sxOverwrite={{
          maxWidth: '100%',
        }}
        {...props}
      />
    );
  },
);
