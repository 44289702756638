export const UPDATE_FIELD_VIEW = 'UPDATE_FIELD_VIEW';

/**
 * Dispatches a custom 'UPDATE_FIELD_VIEW' event from the provided input element.
 *
 * @param {React.MutableRefObject<HTMLInputElement | null>} inputRef Ref of the input element.
 *
 * @See useOnUpdateFieldView
 */

export const emitUpdateFieldView = (inputRef: React.MutableRefObject<HTMLInputElement | null>): void => {
  if (!inputRef) return;

  const element = inputRef.current;
  if (!element) return;

  const event = new CustomEvent(UPDATE_FIELD_VIEW, { detail: element.value });
  element.dispatchEvent(event);
};
