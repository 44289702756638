/* eslint-disable no-useless-escape */

export const REGEX = {
  multipleWhiteSpaces: / +(?= )/g,
};

export const VALIDATION_MESSAGE_REGEX = {
  https: /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
  www: /(^|[^\/])(www\.[\S]+(\b|$))/gim,
  email: /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim,
};
