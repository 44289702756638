import { ServiceIntegration } from '../integrations/integrationActions.types';
import {
  Feature,
  PersonName,
  RequestLimit,
  Role,
  Tag,
  TimeOffLimit,
  VisibilityLevels,
} from '../organizationSession/organizationSessionActions.types';
import { ViewSettings } from '../userSession/userSessionActions.types';

export type Address = {
  postalCode?: string;
  city?: string;
  street?: string;
};
export type EmployeeInfo = PersonName & {
  phoneNumber?: string;
  address?: Address;
  email?: string;
  note?: string;
};
export type AdvancedEmployeeInfo = {
  customEmployeeId?: string;
  identity?: string;
  hideOnList: boolean;
  features: Feature[];
  visibilityLevel?: VisibilityLevels;
  visibilityLevelInheritedFromTag?: VisibilityLevels;
};
export type PayRate = {
  id: string;
  normal?: number;
  overtime?: number;
  startDateUnix: number;
};
export type WorkdayDuration = {
  id: string;
  workdayDurationSeconds: number;
  fromYear: number;
};

export type TimeOffLimitsGroup = {
  id: string;
  fromYear: number;
  limits: TimeOffLimit[];
};

export type RequestLimitsGroup = {
  id: string;
  fromYear: number;
  limits: RequestLimit[];
};

export type FetchEmployeeDetailsResponse = {
  employeeInfo: EmployeeInfo;
  advancedEmployeeInfo: AdvancedEmployeeInfo;
  tagsIds: Tag['id'][];
  roleId: Role['id'];
  workdayDurations?: WorkdayDuration[];
  rates?: PayRate[];
  customRequestsLimits?: RequestLimitsGroup[];
  timeOffLimits?: TimeOffLimitsGroup[];
  defaultWorkPositionId?: string;
  additionalWorkPositionsIds?: string[];
};

export type FetchEmployeeDetailsActionProps = {
  employeeId: string;
};
export type EditEmployeeDetailsResponse = null;
export type EditEmployeeDetailsActionProps = {
  employeeId: string;
  employee: FetchEmployeeDetailsResponse & { avatarChanged?: boolean };
  avatar?: Blob;
};
export type AddEmployeeActionProps = Pick<
  FetchEmployeeDetailsResponse,
  'employeeInfo' | 'tagsIds' | 'roleId' | 'defaultWorkPositionId'
> & { avatar?: Blob };

type CommonEmployeeManagementActionProps = {
  employeesIds: string[];
};

export type EmployeeId = string;

export type AddEmployeeResponse = EmployeeId;

export type DeleteEmployeesActionProps = CommonEmployeeManagementActionProps['employeesIds'];

export type DeleteEmployeesResponse = null;

export type DeactivateEmployeesActionProps = CommonEmployeeManagementActionProps['employeesIds'];

export type DeactivateEmployeesResponse = null;

export type ActivateEmployeesActionProps = CommonEmployeeManagementActionProps['employeesIds'];

export type ActivateEmployeesResponse = null;

type EmployeeToReInviteDetails = { employeeId: string; email?: string };

export type InviteEmployeesActionProps = EmployeeToReInviteDetails[];

export type InviteEmployeesResponse = null;

type ExternalLogin = {
  providerName: string;
  loginProvider: string;
  providerKey: string;
};

export type ImportedEmployee = {
  customEmployeeId?: string;
  id: string;
  employeeInfo: EmployeeInfo;
  externalLogin?: ExternalLogin;
  integrationType: ServiceIntegration;
  integrationIdentity?: string;
  state: ImportState;
  tagName?: string;
  roleId?: string;
};

export type EmployeesImportPreviewResponse = {
  importedEmployees: ImportedEmployee[];
  importedTags?: string[];
  validationErrors?: {
    memberNames: string[];
    errorMessage: string;
  }[];
};

export type ParsedImportedEmployee = Omit<ImportedEmployee, 'employeeInfo'> & EmployeeInfo;

export type EmployeesImportPreviewActionProps = {
  importType: number;
  fileId?: string;
};

export type EmployeesImportActionProps = {
  importedEmployees: ImportedEmployee[];
  importedTags: (string | undefined)[];
};

export type EmployeesImportResponse = CommonEmployeeManagementActionProps['employeesIds'];

export type PrintQrCardsResponse = null;

export type PrintQrCardsActionProps = CommonEmployeeManagementActionProps & {
  showFirstName: boolean;
  showLastName: boolean;
  showTags: boolean;
  showPhoto: boolean;
};

export type FetchTagInheritedFeaturesActionProps = {
  tagsIds: Tag['id'][];
};

export type FetchTagInheritedFeaturesResponse = Pick<
  AdvancedEmployeeInfo,
  'features' | 'visibilityLevelInheritedFromTag'
>;

export type EditTeamListViewSettingActionProps = ViewSettings;
export type EditTeamListViewSettingResponse = null;
export type SetWorkPositionsResponse = null;

export type SetTagsResponse = null;

export type SetRoleResponse = null;

export type SetPermissionsResponse = null;

export type SetPayDetailsResponse = null;

export type SetTimeOffLimitsResponse = null;

export type SetRequestsLimitsResponse = null;

export type ClearTagsResponse = null;

export type ClearWorkPositionResponse = null;

export type SetTimeOffLimitsActionProps = {
  timeOffLimits: TimeOffLimitsGroup[];
  overwrite: boolean;
} & CommonEmployeeManagementActionProps;

export type SetRequestsLimitsActionProps = {
  customRequestsLimits: RequestLimitsGroup[];
  overwrite: boolean;
} & CommonEmployeeManagementActionProps;

export type SetPayDetailsActionProps = {
  rates: PayRate[];
  overwrite: boolean;
} & CommonEmployeeManagementActionProps;

export type SetWorkPositionsActionProps = {
  workPositionId: string;
  overwrite: boolean;
} & CommonEmployeeManagementActionProps;

export type SetTagsActionProps = {
  tagsIds: string[];
  overwrite: boolean;
} & CommonEmployeeManagementActionProps;

export type SetRoleActionProps = {
  roleId: string;
  employeesIds: string[];
  overwrite: boolean;
} & CommonEmployeeManagementActionProps;

export type SetPermissionsActionProps = {
  peopleIds: string[];
} & Pick<AdvancedEmployeeInfo, 'features' | 'visibilityLevel' | 'hideOnList'>;

export type ClearWorkPositionActionProps = string[];
export type ClearTagsActionProps = string[];

export enum EmployeeImportType {
  Optima = 0,
  Symfonia = 1,
  Excel = 2,
  GSuite = 3,
  Quickbooks = 4,
  Square = 5,
  Xero = 6,
  Enova = 7,
}

export enum ImportState {
  New = 0,
  Merged = 1,
  Error = 2,
}
