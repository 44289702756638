import { Step } from 'state/requests';

export const ADD_REQUEST_MODAL_WIDTH = '890px';

export const OVER_TIME_PAYMENT_ID = '496c593e-4bb7-48a1-a010-b1268e5e68a1';
export const HOLIDAY_FREE_DAY_ID = '07908b7c-eae3-473e-89c0-eefac9dbd754';
export const FOR_SATURDAY_FREE_DAY_ID = '63a50c07-fa94-4c1c-8d60-a7cbeda0e5ad';
export const OVERTIME_FREE_DAY_ID = '68463ffd-ef3a-4424-b8a0-b0accde10fc9';
export const FIVE_DAY_WORKING_WEEK_ID = '856015ee-4f25-42f5-8e92-85e128051800';
export const INPUT_DEBOUNCE_TIME = 200;
export const VALIDATION_MAX_TIME_IN_SECONDS = 24 * 60 * 60 - 60;
export const TIME_LIMIT_DEBOUNCE_TIME = 1000;
export const EDIT_REQUEST_DEBOUNCE_TIME = 300;
export const ADD_REQUEST_ERROR_SNACKBAR_DURATION = 5000;
export const ALL_SUPERVISORS_ID = 'all_supervisors';
export const REQUEST_DEFAULT_START_TIME = 28800;
export const REQUEST_DEFAULT_END_TIME = 57600;
export const ADD_REQUEST_DEFAULT_STEPS = [Step.SelectTeammates, Step.SelectType, Step.Forms];
