/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useTransition } from 'react';
import { RecoilState, useRecoilState_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';
import { useClient, useQuery } from 'react-fetching-library';
import _ from 'lodash';

import { Action } from 'api/types';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';

type ResponseModifier<R> = (response: { payload?: R; error: boolean }) => { payload?: R; error: boolean };

type SilentFetchOptions<T> =
  | {
      forceUpdate?: boolean;
      unAbortable?: boolean;
      setAtomWhenError?: boolean;
      onResponseCallback?: (payload?: T) => void;
      responseModifier?: ResponseModifier<T>;
    }
  | undefined;

export type SilentFetch<T> = (options?: SilentFetchOptions<T>) => Promise<{ error: boolean; payload?: T }>;

export function useSilentFetch<AtomState = any, ActionResponse = any, R = any, E = any, I = any>(
  atom: RecoilState<AtomState>,
  fetchAction: Action<R, E, I, ActionResponse>,
): {
  silentFetch: SilentFetch<ActionResponse>;
  silentFetchAbort: () => void;
  fetchInTransition: boolean;
  initialFetchDone: boolean;
} {
  const { query: unAbortableQuery } = useClient();
  const { abort, query } = useQuery(fetchAction, false);
  const [atomState, setAtomState] = useRecoilState_TRANSITION_SUPPORT_UNSTABLE(atom);

  const [inTransition, startTransition] = useTransition();

  const initialFetchDone = useMemo(() => !!atomState, [atomState]);

  const silentFetchAbort = useMemo(() => () => abort(), [abort]);

  const fetchActionRef = useCallbackRef(fetchAction);

  const silentFetch: SilentFetch<ActionResponse> = useCallback(
    async (options) => {
      let silentPayload: ActionResponse | undefined;
      let silentError;

      if (options && options.unAbortable) {
        const { payload, error } = await unAbortableQuery(fetchActionRef.current);

        silentPayload = payload;
        silentError = error;
      } else {
        const { payload, error } = await query();

        silentPayload = payload;
        silentError = error;
      }

      if (options?.responseModifier) {
        const modifiedResponse = options.responseModifier({
          payload: silentPayload,
          error: silentError,
        });

        silentPayload = modifiedResponse.payload;
        silentError = modifiedResponse.error;
      }

      if ((silentError || !silentPayload) && !options?.setAtomWhenError) {
        return { error: true, payload: silentPayload };
      }

      if ((options && options.forceUpdate) || (silentPayload && !_.isEqual(silentPayload, atomState))) {
        startTransition(() => {
          setAtomState(silentPayload as unknown as AtomState);
        });

        if (options && options.onResponseCallback) {
          options.onResponseCallback(silentPayload);
        }
      }

      return { error: silentError, payload: silentPayload };
    },
    [atomState, query, setAtomState, unAbortableQuery, fetchActionRef],
  );

  return { silentFetch, silentFetchAbort, fetchInTransition: inTransition, initialFetchDone };
}
