import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
  assignableRolesSelectOptionsSelector,
  tagsSelectOptionsSelector,
  workPositionsSelectOptionsSelector,
} from 'state/team';

export const useSelectOptions = (defaultWorkPositionId: string | undefined, additionalWorkPositionIds?: string[]) => {
  const tagsSelectOptions = useRecoilValue(tagsSelectOptionsSelector);
  const rolesSelectOptions = useRecoilValue(assignableRolesSelectOptionsSelector);
  const workPositionsSelectOptions = useRecoilValue(workPositionsSelectOptionsSelector);

  const filteredWorkPositionsSelectOptions = useMemo(() => {
    if (!workPositionsSelectOptions || !additionalWorkPositionIds) return workPositionsSelectOptions;
    return workPositionsSelectOptions.filter(({ id }) => !additionalWorkPositionIds.includes(id));
  }, [workPositionsSelectOptions, additionalWorkPositionIds]);

  const additionalWorkPositionsSelectOptions = useMemo(() => {
    if (!workPositionsSelectOptions || !defaultWorkPositionId) return workPositionsSelectOptions;
    return workPositionsSelectOptions.filter(({ id }) => id !== defaultWorkPositionId);
  }, [defaultWorkPositionId, workPositionsSelectOptions]);

  return {
    tagsSelectOptions,
    rolesSelectOptions,
    workPositionsSelectOptions: filteredWorkPositionsSelectOptions,
    additionalWorkPositionsSelectOptions,
  };
};
