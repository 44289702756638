import { useLocation } from 'react-router-dom';

import { MinimizedModalDetails } from 'state/modal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMinimizeLocationState = <T extends Record<string, any>>(): MinimizedModalDetails<T> => {
  const { state } = useLocation();
  const { minimizedModalState } = state || {};

  return minimizedModalState as MinimizedModalDetails<T>;
};
