import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useMemo } from 'react';

import { FaceModelState, FraudDetectionState } from 'api/actions/timeEvent/timeEventActions.types';
import { Icon, IconProps } from 'components/Icon/Icon';

export type RequestStatusIconProps = {
  fraudDetectionState?: FraudDetectionState;
  faceModelState?: FaceModelState;
  isDeleted?: boolean;
  iconProps?: Partial<IconProps>;
};

export const ClockLogStatusIcon: FC<RequestStatusIconProps> = ({
  fraudDetectionState,
  faceModelState,
  isDeleted,
  iconProps = { size: 24 },
}) => {
  useLingui();
  const props = useMemo<IconProps | null>(() => {
    const verifiedIconProps: IconProps = {
      type: 'aiVerifiedGradient',
      tooltip: t({ id: 'clock_log.state.ai_verified', message: 'Verified' }),
    };
    const isAdultFaceModel = faceModelState === FaceModelState.Adult;
    if (isDeleted) {
      return { type: 'delete', fill: 'yellows5', tooltip: t({ id: 'clock_log.view_type.deleted' }) };
    }
    switch (fraudDetectionState) {
      case FraudDetectionState.ResolvedNotFraud:
        return verifiedIconProps;
      case FraudDetectionState.None:
        return isAdultFaceModel
          ? verifiedIconProps
          : {
              type: 'shieldOpaque',
              fill: 'clockLog.statusIcon.default',
              tooltip: t({
                id: 'clock_log.state.training_face_recognition_model',
                message: 'Training face recognition model',
              }),
            };
      case FraudDetectionState.ResolvedFraud:
        return {
          type: 'aiRejected',
          fill: 'clockLog.statusIcon.error',
          tooltip: t({ id: 'clock_log.state.ai_rejected', message: 'Rejected' }),
        };
      case FraudDetectionState.PotentialFraud:
        return {
          type: 'aiWarning',
          fill: 'clockLog.statusIcon.error',
          tooltip: t({ id: 'clock_log.anti_spoofing.check_required', message: 'Anti-spoofing check required' }),
        };
      default:
        return null;
    }
  }, [faceModelState, fraudDetectionState, isDeleted]);

  if (!props) return null;

  return (
    <Icon
      {...props}
      {...iconProps}
      wrapperSx={{
        ...iconProps?.iconSx,
        ...(!isDeleted && {
          bg: 'whites0',
          border: '1px solid',
          borderColor: 'alphas.darker4',
          borderRadius: 'xs',
        }),
      }}
    />
  );
};
