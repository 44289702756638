import { useMemo } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState, useRecoilValue } from 'recoil';

import { fetchClockLogAction } from 'api/actions/timeEvent/timeEventActions';
import { clockLogResponseAtom } from 'state/clockLog';
import { ParsedEmployee } from 'state/employees';
import { dateRangeFilterAtom } from 'state/filters';

export const useRefreshClockLog = (ids?: ParsedEmployee['id'][]) => {
  const dates = useRecoilValue(dateRangeFilterAtom);
  const [clockLogResponse, setClockLogResponse] = useRecoilState(clockLogResponseAtom);

  const { query } = useClient();

  const refreshClockLogForPeopleIds = useMemo(
    () => async (employeeIds?: ParsedEmployee['id'][]) => {
      if (!dates) return;

      const usedIds = ids || employeeIds;
      if (!usedIds) return;

      const { error, payload } = await query(fetchClockLogAction({ ...dates, peopleIds: usedIds }));

      if (!error && payload && 'timeEvents' in payload) {
        setClockLogResponse((prev) => {
          if (prev && 'timeEvents' in prev) {
            const { timeEvents, employeesData } = prev;
            const { timeEvents: payloadTimeEvents, employeesData: payloadEmployeesData } = payload;

            const timeEventsMap = new Map(timeEvents);
            const payloadTimeEventsMap = new Map(payloadTimeEvents);
            const employeesDataMap = new Map(employeesData);
            const payloadEmployeesDataMap = new Map(payloadEmployeesData);

            timeEventsMap.forEach(({ personId }, eId) => {
              if (usedIds.includes(personId)) timeEventsMap.delete(eId);
            });
            payloadTimeEventsMap.forEach((e, eId) => timeEventsMap.set(eId, e));

            employeesDataMap.forEach((emplData, employeeId) => {
              if (usedIds?.includes(employeeId)) employeesDataMap.delete(employeeId);
            });
            payloadEmployeesDataMap.forEach((emplData, employeeId) => employeesDataMap.set(employeeId, emplData));

            return {
              ...prev,
              timeEvents: [...timeEventsMap],
              employeesData: [...employeesDataMap],
            };
          }

          return payload;
        });
      }
    },
    [dates, ids, query, setClockLogResponse],
  );

  return {
    refreshClockLogForPeopleIds,
    clockLogInitialized: !!clockLogResponse,
  };
};
