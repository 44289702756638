import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';

import {
  acceptRequestManagementAction,
  acceptSwapAction,
  requestDownloadAction,
  requestPrintAction,
} from 'api/actions/requests/requestsActions';
import { DateTimeDetails, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useDateRangeFilter } from 'layouts/AuthorizedApp/AsideFilters/hooks/useDateRangeFilter';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { useRefreshClockLog } from 'pages/ClockLog/output/useRefreshClockLog';
import { useRequestsToAccept } from 'pages/Home/output/useRequestsToAccept';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { fullTimeFormatSelector } from 'state/recoilState';
import { RequestsListType } from 'state/requests';
import { useRefreshRequestsUsageOverview } from '../output/useRefreshRequestsUsageOverview';
import { displayDate } from '../utils/displayDate';
import { displayDuration } from '../utils/displayDuration';

import { useRefreshRequests } from './useRefreshRequests';
import { useRefreshRequestsTypeDetails } from './useRefreshRequestsTypeDetails';

type DisplayDate = {
  isDateBound: boolean;
  type?: RequestFormType;
  dateRange?: DateTimeDetails['dates'];
  dateUnix?: number | undefined;
};

export const useRequestsLogic = () => {
  useLingui();

  const timeFormat = useRecoilValue(fullTimeFormatSelector);
  const { query } = useClient();
  const { isRequestsGrouped, isRequestsUngrouped } = useAppRouting(['isRequestsGrouped', 'isRequestsUngrouped']);
  const { updateRequests, requestsInitialized } = useRefreshRequests();
  const { updateCalendarForIds, calendarInitialized } = useRefreshCalendar();
  const { reportInitialized, updateReportForIds } = useRefreshReport();
  const { refreshClockLogForPeopleIds, clockLogInitialized } = useRefreshClockLog();
  const { fetchRequestsToAccept } = useRequestsToAccept();
  const { updateRequestsTypeDetails, requestsTypeDetailsInitialized } = useRefreshRequestsTypeDetails();
  const { multipleYearsInRangeFilter } = useDateRangeFilter();
  const { updateRequestsUsageOverviewNoThrottle, requestsUsageOverviewInitialized } = useRefreshRequestsUsageOverview();
  const requestsInitializedRef = useCallbackRef(requestsInitialized);
  const calendarInitializedRef = useCallbackRef(calendarInitialized);
  const reportInitializedRef = useCallbackRef(reportInitialized);
  const clockLogInitializedRef = useCallbackRef(clockLogInitialized);
  const requestsTypeDetailsInitializedRef = useCallbackRef(requestsTypeDetailsInitialized);
  const requestsUsageOverviewInitializedRef = useCallbackRef(requestsUsageOverviewInitialized);

  const displayer = useMemo(
    () => ({
      displayDuration,
      displayDate: (dateDetails: DisplayDate) => displayDate(dateDetails, timeFormat, multipleYearsInRangeFilter),
    }),
    [multipleYearsInRangeFilter, timeFormat],
  );

  const requestsViewType: RequestsListType = useMemo(() => {
    if (isRequestsGrouped) return 'grouped';
    if (isRequestsUngrouped) return 'ungrouped';
    return 'pending';
  }, [isRequestsGrouped, isRequestsUngrouped]);

  const actions = useMemo(() => {
    const approveRequest = async (id: string, personId: string, isSchedule: boolean, swapTargetId?: string) => {
      let submitError: boolean;

      if (isSchedule) {
        const { error } = await query(acceptSwapAction({ id }));
        submitError = error;
      } else {
        const { error } = await query(acceptRequestManagementAction([id]));
        submitError = error;
      }

      if (!submitError) {
        addSnackbar({
          message: t({
            id: 'request.snackbar_approved',
            message: 'Request approved!',
          }),
          variant: 'success',
        });

        const refreshEmployees = (() => {
          if (swapTargetId) {
            return [personId, swapTargetId];
          }

          return [personId];
        })();

        if (requestsInitializedRef.current) updateRequests();
        if (calendarInitializedRef.current) void updateCalendarForIds(refreshEmployees);
        if (reportInitializedRef.current) void updateReportForIds(refreshEmployees);
        if (clockLogInitializedRef.current) void refreshClockLogForPeopleIds(refreshEmployees);
        if (requestsTypeDetailsInitializedRef.current) void updateRequestsTypeDetails();
        if (requestsUsageOverviewInitializedRef.current) void updateRequestsUsageOverviewNoThrottle();
        void fetchRequestsToAccept();
      }
    };

    const printRequest = async (ids: string[]) => {
      await query(requestPrintAction({ ids }));
    };

    const downloadRequest = async (ids: string[], separateFiles = false) => {
      await query(requestDownloadAction({ ids, separateFiles }));
    };

    return {
      approveRequest,
      printRequest,
      downloadRequest,
    };
  }, [
    calendarInitializedRef,
    clockLogInitializedRef,
    fetchRequestsToAccept,
    query,
    refreshClockLogForPeopleIds,
    reportInitializedRef,
    requestsInitializedRef,
    requestsTypeDetailsInitializedRef,
    requestsUsageOverviewInitializedRef,
    updateCalendarForIds,
    updateReportForIds,
    updateRequests,
    updateRequestsTypeDetails,
    updateRequestsUsageOverviewNoThrottle,
  ]);

  return {
    actions,
    displayer,
    requestsViewType,
  };
};
