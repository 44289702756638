import { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { useRecoilState } from 'recoil';

import { useSilentFetch } from 'hooks/useSilentFetch/useSilentFetch';
import { calendarResponseAtom, currentlyFetchedCalendarDateRangeAtom } from 'state/calendar';
import { fetchCalendarAction } from 'api/actions/calendar/calendarActions';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { usePromisify } from 'hooks/usePromisify/usePromisify';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';

export type Dates = { startDateUnix: number; endDateUnix: number };

export const useCalendar = (dates: Dates | null) => {
  const [currentlyFetchedDateRange, setCurrentlyFetchedDateRange] = useRecoilState(
    currentlyFetchedCalendarDateRangeAtom,
  );
  const [fetchInProgress, setFetchInProgress] = useState(false);

  const { silentFetch, silentFetchAbort, fetchInTransition, initialFetchDone } = useSilentFetch(
    calendarResponseAtom,
    fetchCalendarAction(dates),
  );

  const fetchIdRef = useRef<string | null>(null);

  const [promisifyFetch, resolveFetch] = usePromisify<Dates | null>();

  const promisifyFetchRef = useCallbackRef(promisifyFetch);
  const resolveFetchRef = useCallbackRef(resolveFetch);

  const internalFetchCalendar = useMemo(
    () => async () => {
      if (dates) {
        const requestId = fetchIdRef.current;
        const { error, payload } = await silentFetch({
          forceUpdate: true,
          setAtomWhenError: true,
        });

        // make sure we are not colliding with another request that was already initialized
        if (fetchIdRef.current !== requestId) return;

        if (dates && (!error || payload?.innerCode === ErrorInnerCodes.DateRangeAccessDenied)) {
          resolveFetchRef.current(dates);
        } else {
          resolveFetchRef.current(null);
        }
        setCurrentlyFetchedDateRange({ currentlyFetchedDates: dates, error });

        setFetchInProgress(false);
      }
    },
    [dates, silentFetch, resolveFetchRef, setCurrentlyFetchedDateRange],
  );

  const throttleFetch = useMemo(
    () =>
      _.throttle(
        async () => {
          await internalFetchCalendar();
        },
        1000,
        { leading: false },
      ),
    [internalFetchCalendar],
  );

  const fetch = useMemo(
    () =>
      _.throttle(async () => {
        fetchIdRef.current = _.uniqueId();
        setFetchInProgress(true);
        return promisifyFetchRef.current();
      }, 1000),
    [promisifyFetchRef],
  );

  useEffect(() => {
    if (fetchInProgress) {
      void throttleFetch();
    }
    return () => {
      fetch.cancel();
      throttleFetch.cancel();
    };
  }, [dates, fetchInProgress, fetch, internalFetchCalendar, silentFetchAbort, throttleFetch, resolveFetchRef]);

  useUnmount(() => resolveFetchRef.current(null));

  return {
    fetch,
    fetchInTransition,
    fetchInProgress,
    initialFetchDone,
    currentlyFetchedDateRange,
  };
};
