import { Flex, FlexProps, ThemeUICSSObject } from 'theme-ui';

import { UserSelectableColor } from 'constants/userSelectableColors';

type ColorSquareProps = {
  color: UserSelectableColor;
  fillType?: 'full' | 'border' | 'borderRounded' | 'pill';
};

const colorSquareDefaultProps: Partial<ColorSquareProps> = {
  fillType: 'full',
};

export const ColorSquare = ({ color, fillType }: ColorSquareProps) => {
  const additionalSx: ThemeUICSSObject = (() => {
    const commonSx = {
      bg: 'select.colorSquare.background',
      borderColor: UserSelectableColor[color],
    };

    switch (fillType) {
      case 'border':
        return {
          borderRadius: 0,
          border: '2px solid',
          ...commonSx,
        };
      case 'borderRounded':
        return {
          borderRadius: '6px',
          border: '4px solid',
          ...commonSx,
        };
      case 'pill':
        return {
          ...commonSx,
          borderRadius: 'pill',
          bg: UserSelectableColor[color],
        };
      default:
        return {
          bg: UserSelectableColor[color],
          borderRadius: '4px',
        };
    }
  })();

  return (
    <Flex
      sx={{
        width: '21px',
        height: '21px',
        flexShrink: 0,
        alignSelf: 'center',
        outline: '1px solid',
        outlineColor: 'select.colorSquare.outline',
        ...additionalSx,
      }}
    />
  );
};

ColorSquare.defaultProps = colorSquareDefaultProps;

export const Rectangle = ({ children, sx, ...props }: FlexProps) => (
  <Flex
    sx={{
      flexShrink: 0,
      minWidth: '36px',
      borderRadius: 'xs',
      alignItems: 'center',
      justifyContent: 'center',
      px: 1,
      ...(sx && sx),
    }}
    {...props}
  >
    {children}
  </Flex>
);

export const ContentWrapper = ({ children, sx, ...props }: FlexProps) => (
  <Flex
    sx={{
      flexWrap: 'nowrap',
      gap: 2,
      alignItems: 'center',
      ...(sx && sx),
    }}
    {...props}
  >
    {children}
  </Flex>
);

export const abbreviationRectRenderer = (abbreviation: string, active?: boolean) => (
  <Rectangle
    sx={{
      bg: active
        ? 'select.rectangle.abbreviation.background.active'
        : 'select.rectangle.abbreviation.background.default',
      fontWeight: 'bold',
    }}
  >
    {abbreviation}
  </Rectangle>
);

export const numberRectRenderer = (number?: string) => (
  <Rectangle
    sx={{
      bg: 'select.rectangle.number.background',
      color: 'select.rectangle.number.color',
    }}
  >
    {number}
  </Rectangle>
);
