/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { useCallback, useRef } from 'react';

/**
 * Util hook that lets you create promises and expose their resolve and reject callbacks to the host components scope.
 *
 * @return {[promisify, resolve, reject]} Methods
 * @return {(timeout?: number | undefined) => Promise<T | null>} Methods[0] promisify - method that returns a new promise and saves its resolve and reject to refs that is later exposed.
 * @param {number | undefined} timeout Time in ms. If specified the created promise will be rejected after that time (if it is still pending).
 * @return {(value: T) => void} Methods[1] resolve - promise resolver
 * @return {(reason?: any) => void} Methods[2] reject - promise rejecter
 */
export function usePromisify<T = any>() {
  const resolveRef = useRef<((value: T | PromiseLike<T>) => void) | null>(null);
  const rejectRef = useRef<((reason?: any) => void) | null>(null);

  const promisify = useCallback((timeout?: number) => {
    const promise = new Promise<T>((resolve, reject) => {
      resolveRef.current = resolve;
      rejectRef.current = reject;
    });

    if (!_.isNumber(timeout)) return promise;

    const promiseTimeout = new Promise<null>((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, timeout);
    });

    return Promise.race([promise, promiseTimeout]);
  }, []);

  const resolve = useCallback((value: T) => {
    const resolver = resolveRef.current;
    if (!resolver) return;
    resolver(value);
  }, []);

  const reject = useCallback((reason?: any) => {
    const rejecter = rejectRef.current;
    if (!rejecter) return;
    rejecter(reason);
  }, []);

  return [promisify, resolve, reject] as const;
}
