import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { DebouncedFunc } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { addSnackbar } from 'base/Snackbar/output/actions';
import { CHECK_BLOCKING_DELAY } from 'constants/settings';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { NavigationValidator, useBlockAppNavigation } from 'hooks/useBlockAppNavigation/useBlockAppNavigation';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useOnPopstateQueue } from 'hooks/useOnPopstateQueue/useOnPopstateQueue';
import { blockTransitionAtom } from 'state/settings';
import { delay } from 'utils/delay';

export const useSettingsBlockRouteTransition = (
  dispatchSubmitEvent: () => void,
  throttledDispatchSubmitEvent: DebouncedFunc<() => void>,
) => {
  useLingui();
  const navigate = useAppNavigate();
  const [{ blockLocationPathname, navigateOptions, blockTransition, isRequestPending }, setBlockTransition] =
    useRecoilState(blockTransitionAtom);
  const resetBlockTransition = useResetRecoilState(blockTransitionAtom);
  const blockTransitionRef = useCallbackRef(blockTransition);
  const isRequestPendingRef = useCallbackRef(isRequestPending);

  const validatePageTransitionCallback: NavigationValidator = useCallback(
    (to, options) => {
      const showSnackbarWithDelay = async () => {
        await delay(CHECK_BLOCKING_DELAY);
        if (isRequestPendingRef.current) {
          addSnackbar({
            variant: 'warning',
            message: t({ id: 'settings.waiting_server_response', message: 'Waiting for server response...' }),
          });
        }
      };
      if (blockTransitionRef.current && !isRequestPendingRef.current) {
        throttledDispatchSubmitEvent.cancel();
        setBlockTransition((prevState) => ({
          ...prevState,
          blockLocationPathname: to,
          navigateOptions: options,
          skipFetch: true,
        }));
        dispatchSubmitEvent();
        void showSnackbarWithDelay();
      }

      if (isRequestPendingRef.current) {
        addSnackbar({
          variant: 'warning',
          message: t({ id: 'settings.waiting_server_response' }),
        });
      }

      if (blockTransitionRef.current || isRequestPendingRef.current) return false;
      return true;
    },
    [blockTransitionRef, isRequestPendingRef, throttledDispatchSubmitEvent, setBlockTransition, dispatchSubmitEvent],
  );

  useEffect(() => {
    if (blockLocationPathname && !isRequestPending && !blockTransition) {
      navigate(blockLocationPathname, navigateOptions);
    }
  }, [blockLocationPathname, isRequestPending, navigate, blockTransition, navigateOptions]);

  useEffect(
    () => () => {
      resetBlockTransition();
    },
    [resetBlockTransition],
  );

  const onPopstateCallback = useCallback(() => {
    const omitAlert = validatePageTransitionCallback();
    if (omitAlert) return;

    // eslint-disable-next-line no-alert
    alert(
      t({
        id: 'settings.alert_message',
        message:
          'You are moving out of the page before your changes could be aplied! Return to this page and make shure that all your changes have been aplied.',
      }),
    );
  }, [validatePageTransitionCallback]);

  useOnPopstateQueue(onPopstateCallback);
  useBlockAppNavigation(validatePageTransitionCallback);
};
