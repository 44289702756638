import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { AddRequestExtendedFormContext } from '../../../../../../types';
import { useAddRequest } from '../../../../hooks/useAddRequest';

import { BusinessTripContainer } from './components/BusinessTripContainer';

export const BusinessTripForm = (): React.ReactElement => {
  useLingui();
  const {
    setFormState,
    modifyRequest: { modifyRequestData },
    requestToEdit: { selectedRequestToEdit, resetSelectedRequestToEditId },
    defaultDateUnix,
  } = useAddRequest();

  const { unregister, setValue, watch } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  const formVisible = useMemo(() => {
    if (actionTypeWatch === RequestActionType.Edit && !selectedRequestToEdit && !modifyRequestData) return false;

    if (actionTypeWatch === RequestActionType.Remove) return false;

    return true;
  }, [actionTypeWatch, modifyRequestData, selectedRequestToEdit]);

  const handleFormVisibility = useCallback(() => {
    if (formVisible) {
      setFormState((prevState) => ({ ...prevState, mainFormVisible: true }));
    }

    if (!formVisible && actionTypeWatch === RequestActionType.Remove) {
      unregister('dateTimeDetails.dates');
      unregister('details.name');
      unregister('details.place');
    }
  }, [actionTypeWatch, formVisible, setFormState, unregister]);

  useEffect(() => {
    handleFormVisibility();
  }, [handleFormVisibility]);

  useEffect(() => {
    if (defaultDateUnix) {
      setValue('dateTimeDetails.dates', [defaultDateUnix, defaultDateUnix]);
    }
  }, [defaultDateUnix, setValue]);

  useEffect(
    () => () => {
      resetSelectedRequestToEditId();
    },
    [resetSelectedRequestToEditId],
  );

  return (
    <BusinessTripContainer>
      <BusinessTripContainer.EditionObserver />
      <BusinessTripContainer.SaveStateObserver />
      <BusinessTripContainer.ResetFormObserver />
      <BusinessTripContainer.RequestsHistorySelect />
      {formVisible && (
        <>
          <BusinessTripContainer.Name />
          <BusinessTripContainer.Place />
          <BusinessTripContainer.Calendar />
        </>
      )}
    </BusinessTripContainer>
  );
};
