import { useLingui } from '@lingui/react';
import { useContext, useEffect, useRef } from 'react';
import { ClientContext, useMutation } from 'react-fetching-library';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { googleLoginAction } from 'api/actions/integrations/integrationActions';
import { fetchUserSessionAction } from 'api/actions/userSession/userSessionActions';
import { TO } from 'constants/routes';
import { setAuthorized, setIsSignUpInProgress, setToken } from 'context/auth/authActionCreators/authActionCreators';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { CredentialResponse } from 'hooks/useGoogleScript/google.types';
import { useGoogleScript } from 'hooks/useGoogleScript/useGoogleScript';
import { languageSelector } from 'state/recoilState';
import { signUpFormAtom } from 'state/signUp';
import { userSessionAtom } from 'state/userSession';

export const useGoogleIntegration = () => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const setUserSession = useSetRecoilState(userSessionAtom);
  const { mutate: mutateGoogleLogin } = useMutation(googleLoginAction);
  const dispatch = useAuthDispatch();
  const { query } = useContext(ClientContext);
  const googleButtonRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const setSignUpFormState = useSetRecoilState(signUpFormAtom);

  const handleGoogleOnFailure = () => {
    // GTM DATA LAYER (LOGIN-FAIL / GOOGLE)
    TagManager.dataLayer({
      dataLayer: {
        event: 'login-fail',
        method: 'google',
      },
    });
  };

  const handleGoogleOnSuccess = async (credentialResponse: CredentialResponse) => {
    if (credentialResponse.credential) {
      // GTM DATA LAYER (LOGIN-SUBMIT / GOOGLE)
      TagManager.dataLayer({
        dataLayer: {
          event: 'login-submit',
          method: 'google',
        },
      });

      const { payload, error } = await mutateGoogleLogin({ googleToken: credentialResponse.credential });

      if (error) {
        handleGoogleOnFailure();
      }

      if (payload && !error) {
        const { accessToken, redirectToAccountType, user } = payload;

        dispatch(setToken(accessToken));

        const { payload: userinfo, error: fetchError } = await query(fetchUserSessionAction(accessToken));

        if (!fetchError && userinfo) {
          dispatch(setAuthorized());
          setUserSession(userinfo);

          const { personId } = userinfo;

          // GTM DATA LAYER (LOGIN-SUCCESS / GOOGLE)
          TagManager.dataLayer({
            dataLayer: {
              event: 'login-success',
              method: 'google',
              userId: personId,
            },
          });
        }

        if (redirectToAccountType) {
          const { firstName, lastName } = user;
          setSignUpFormState({ firstName, lastName });
          dispatch(setIsSignUpInProgress(true));
          navigate(TO.SIGN_UP__ACCOUNT_TYPE[language], { state: { signUp: { inProgress: true } } });
        } else {
          navigate(TO.START[language]);
        }
      }
    }
  };

  const google = useGoogleScript(handleGoogleOnSuccess);

  useEffect(() => {
    if (google && googleButtonRef.current) {
      google.accounts.id.renderButton(googleButtonRef.current, {
        type: 'icon',
        theme: 'outline',
        size: 'large',
        shape: 'rectangular',
        text: 'signin_with',
        locale: `${language}_en`,
      });
    }
  }, [google, language]);

  return { googleButtonRef };
};
