import { t, Trans } from '@lingui/macro';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { ScheduleData } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { useMinimizedModals } from 'components/Modal/output/useMinimizedModals';
import { Button } from 'components/ui/Buttons';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { useIsEmployeeNonEditable } from 'hooks/useIsEmployeeNonEditable/useIsEmployeeNonEditable';
import { AsideDetails } from 'layouts/AuthorizedApp/AsideDetails/AsideDetails';
import { FancyDataBox } from 'pages/Reports/output/FancyDataBox';
import { ParsedEmployee } from 'state/employees';
import { MinimizedModal, ViewWithMinimizedModals } from 'state/modal';
import { workPositionDictionarySelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';
import { formattedDurationFromSeconds } from 'utils/dateTime';
import { DetailedScheduleElement } from '../components/DetailedScheduleElement';

type Props = {
  schedules: ScheduleData[] | undefined;
  userId: ParsedEmployee['id'];
  dateUnix: number;
  hideActions?: boolean;
  scheduledTime?: number;
};

export const SchedulesRenderer = ({
  schedules,
  userId,
  dateUnix,
  hideActions,
  scheduledTime,
}: Props): React.ReactElement | null => {
  const workPositionTypes = useRecoilValue(workPositionDictionarySelector);
  const language = useRecoilValue(languageSelector);

  const isEmployeeNonEditable = useIsEmployeeNonEditable('Schedules', userId);

  const navigate = useAppNavigate();
  const { isCalendar, isReportsAttendanceList } = useAppRouting(['isCalendar', 'isReportsAttendanceList']);

  const { modulesManagement } = useAppPermissions();

  const minimizedView = useMemo(() => {
    if (isCalendar) {
      return ViewWithMinimizedModals.CALENDAR;
    }

    if (isReportsAttendanceList) {
      return ViewWithMinimizedModals.ATTENDANCE;
    }

    return ViewWithMinimizedModals.TIMESHEETS;
  }, [isCalendar, isReportsAttendanceList]);

  const { minimizeModalActionValidation } = useMinimizedModals(minimizedView);

  const renderSchedules = () => {
    if (!schedules || schedules.length < 1) return null;
    const sortedSchedules = _.sortBy(schedules, (schedule) => schedule.isDeleted);

    return _.map(sortedSchedules, ({ id, details, isPublished, workPositionId, note, isDeleted, aiVariant }) => {
      const {
        color,
        type,
        addAutomaticBreak,
        countOvertimeAfterWork,
        countOvertimeBeforeWork,
        endDateTimeUnix,
        startDateTimeUnix,
        isOvertimeSchedule,
        workTimeUnix,
        automaticBreakDurationUnix,
      } = details;

      const handleOnClick = () => {
        navigate(`${TO_REL.EDIT_SCHEDULE__ID__DATE__USER[language]}/${id}/${dateUnix}/${userId}`);
      };

      const isClickable = () => {
        if (modulesManagement.Schedules && !isEmployeeNonEditable) {
          if (!hideActions) {
            return handleOnClick;
          }
        }

        return undefined;
      };

      return (
        <DetailedScheduleElement
          key={id}
          scheduleId={id}
          isOvertimeSchedule={isOvertimeSchedule}
          isPending={!isPublished}
          scheduleColor={color}
          type={type}
          withAutoBreak={addAutomaticBreak}
          autoBreakDuration={automaticBreakDurationUnix}
          countOvertimeAfterWork={countOvertimeAfterWork}
          countOvertimeBeforeWork={countOvertimeBeforeWork}
          endDateTimeUnix={endDateTimeUnix}
          startDateTimeUnix={startDateTimeUnix}
          workTimeUnix={workTimeUnix}
          isDeleted={isDeleted}
          isAi={_.isNumber(aiVariant)}
          workPosition={workPositionId && workPositionTypes ? workPositionTypes[workPositionId].name : undefined}
          onClick={isClickable()}
          note={note}
          hideButtons={!modulesManagement.Schedules || hideActions || isEmployeeNonEditable}
        />
      );
    });
  };

  const renderSchedulesSummary = () => {
    if (!scheduledTime || scheduledTime === 0) return null;

    const totalDuration = scheduledTime;

    return (
      <FancyDataBox
        bold
        title={t({ id: 'reports.scheduled_time', message: 'Scheduled time' })}
        value={totalDuration && formattedDurationFromSeconds(totalDuration)}
      />
    );
  };

  const handleOpenAddScheduleModal = async () => {
    const validation = await minimizeModalActionValidation(MinimizedModal.ADD_SCHEDULES);

    if (!validation) return;

    navigate(`${TO_REL.ADD_SCHEDULE__USER__DATE[language]}/${userId}/${dateUnix}`);
  };

  return modulesManagement.Schedules || (schedules && schedules.length > 0) ? (
    <AsideDetails.Content.Container>
      <AsideDetails.Content.Title>
        <Trans id="reports.schedules">Schedules</Trans>
      </AsideDetails.Content.Title>

      <Flex sx={{ flexDirection: 'column', gap: '0.125rem' }}>{renderSchedules()}</Flex>
      {renderSchedulesSummary()}

      {!hideActions && modulesManagement.Schedules && !isEmployeeNonEditable && (
        <Flex>
          <Button
            className="onboarding-schedule-3"
            shape="rounded"
            variant="minimal"
            prependWith={<Icon type="plus" iconSx={{ bg: 'calendar.schedules.bg.icon', borderRadius: 'xs' }} />}
            onClick={() => void handleOpenAddScheduleModal()}
            sx={{
              px: 2,
            }}
            bgOverwrite={{
              default: 'calendar.schedules.bg.default',
              hover: 'calendar.schedules.bg.hover',
              tap: 'calendar.schedules.bg.tap',
              disabled: 'calendar.schedules.bg.disabled',
            }}
          >
            <Trans id="calendar.add_schedule">Add schedule</Trans>
          </Button>
        </Flex>
      )}
    </AsideDetails.Content.Container>
  ) : null;
};
