import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';

import { ExternalLoginActionSource } from 'api/actions/integrations/integrationActions.types';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { HeadingTip } from '../HeadingTip';
import { SettingsLoadingSpinnerCss } from '../SettingsLoadingSpinnerCss';

import { ModalRoutes } from './ModalRoutes';
import { IntegrationsTiles } from './components/IntegrationsTiles/IntegrationsTiles';
import { useExternalIntegrations } from './components/IntegrationsTiles/hooks/useExternalIntegrations';
import { useIntegrations } from './hooks/useIntegrations';
import { useIntegrationsBroadcast } from './hooks/useIntegrationsBroadcast';

export const Integrations = (): React.ReactElement => {
  useLingui();
  const { fetchIntegrations, isInitialized: integrationsInitialized } = useIntegrations();
  const { fetchExternalIntegrations, isInitialized: externalIntegrationsInitialized } = useExternalIntegrations();
  const { systemManagement } = useAppPermissions();

  useEffect(() => {
    if (systemManagement.CompanySettings) {
      void fetchIntegrations();
    }
  }, [fetchIntegrations, systemManagement.CompanySettings]);

  useEffect(() => {
    void fetchExternalIntegrations(ExternalLoginActionSource.Link);
  }, [fetchExternalIntegrations]);

  useIntegrationsBroadcast();

  return (
    <>
      <HeadingTip
        label={t({
          id: 'navbar.side_menu.integrations',
          message: 'Notifications',
        })}
      />

      {(integrationsInitialized || !systemManagement.CompanySettings) && externalIntegrationsInitialized ? (
        <IntegrationsTiles />
      ) : (
        <SettingsLoadingSpinnerCss />
      )}
      <ModalRoutes />
    </>
  );
};
