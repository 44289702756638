import { useCallback } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState, useRecoilValue } from 'recoil';

import { holidaysAtom, parsedHolidaysSelector, pickedHolidayYearAtom } from 'state/settings';
import { fetchHolidaysYearAction } from 'api/actions/holidays/holidaysActions';

export const useHolidays = () => {
  const { query } = useClient();
  const [holidays, setHolidays] = useRecoilState(holidaysAtom);
  const parsedHolidays = useRecoilValue(parsedHolidaysSelector);
  const [pickedHolidayYear, setPickedHolidayYear] = useRecoilState(pickedHolidayYearAtom);

  const fetchHolidays = useCallback(async () => {
    const { error, payload } = await query(fetchHolidaysYearAction({ year: pickedHolidayYear }));

    if (!error && payload) {
      setHolidays(payload);
    }
  }, [pickedHolidayYear, query, setHolidays]);

  return {
    holidays,
    setHolidays,
    parsedHolidays,
    pickedHolidayYear,
    setPickedHolidayYear,
    fetchHolidays,
  };
};
