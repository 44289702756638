import React from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

type Props = {
  children: React.ReactNode;
  sx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  sx: undefined,
};

export const Section = React.forwardRef<HTMLDivElement, Props>(({ children, sx, ...props }: Props, ref) => (
  <Flex
    ref={ref}
    as="section"
    {...props}
    sx={{
      flexDirection: 'column',
      p: 3,
      alignItems: 'center',
      width: '100%',
      ...(sx && sx),
    }}
  >
    {children}
  </Flex>
));

Section.defaultProps = defaultProps;
