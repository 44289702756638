import { Trans } from '@lingui/macro';
import { useRecoilValue } from 'recoil';
import { Flex, Heading, Text } from 'theme-ui';

import { Button } from 'components/ui/Buttons';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { userDetailsSelector } from 'state/userSession';
import { onboardingStepsType } from '../../types';

const WelcomeMessage = () => {
  const { stopOnboarding, playNextStep } = useInitialOnboarding();

  const userDetails = useRecoilValue(userDetailsSelector);

  return (
    <Flex sx={{ flexDirection: 'column', alignContent: 'center', gap: 'inherit', textAlign: 'center' }}>
      <Flex variant="onboarding.modalHeaderIcon">👋</Flex>
      <Heading variant="heading3" as="h3">
        <Trans id="onboarding.welcome.title">Hi</Trans> {userDetails?.name.firstName}!
      </Heading>
      <Text as="p" variant="p">
        <Trans id="onboarding.welcome.text">
          It's so nice to have you on board. Explore our guide or jump right into it on your own.
        </Trans>
      </Text>
      <Flex sx={{ gap: 2, justifyContent: 'center', pt: 3, flexDirection: ['column', null, null, 'row'] }}>
        <Button shape="rounded" variant="primary" onClick={playNextStep}>
          <Trans id="onboarding.action.start_guide">Start the guide</Trans>
        </Button>
        <Button shape="rounded" variant="lightGrey" onClick={stopOnboarding}>
          <Trans id="onboarding.action.explore_on_own">Explore on your own</Trans>
        </Button>
      </Flex>
      <Text as="p" sx={{ fontSize: 2, color: 'onboarding.text.default', mt: 3 }}>
        <Trans id="onboarding.hint.where_to_start">
          You can always restart onboarding, just click on your profile in the navbar and select <b>Show onboarding</b>.
        </Trans>
      </Text>
    </Flex>
  );
};

export const onboardingWelcomeMessage: onboardingStepsType = [
  {
    content: <WelcomeMessage />,
    mode: 'modal',
  },
];
