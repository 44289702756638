import { CalendarScheduleWarnings, ScheduleType } from 'constants/calendar';
import { DayDetailsErrorDetails } from 'pages/Reports/output/types';
import { ParsedEmployee } from 'state/employees';
import { AiVariant, Employee } from '../organizationSession/organizationSessionActions.types';
import { DateTimeKind, Request, ScheduleData } from '../requests/requestsActions.types';

export type FetchCalendarActionProps = {
  startDateUnix: number;
  endDateUnix: number;
};

export type FetchCalendarForIdsActionProps = FetchCalendarActionProps & {
  employeeIds: string[];
};

export type DurationType = {
  dayCount: number;
  seconds: number;
};

export type DayDetailsRequestType = Pick<
  Request,
  'id' | 'number' | 'type' | 'state' | 'actionType' | 'isDeleted' | 'swapPersonId' | 'actions' | 'personId'
> & {
  typeDescription: string;
  typeId: string;
  kind: DateTimeKind;
  isEnd?: boolean;
  originalEvent?: {
    typeDescription: string;
    typeId: string;
  };
  externalId?: string;
};

export type EmployeeWorkdayAvailabilityData = {
  available?: boolean;
  type?: ScheduleType;
  timeRange?: {
    startUnix: number;
    endUnix: number;
  }[];
  hasFullDayTimeOff?: boolean; // Added for front, not available in the backend
  hasUnrejectedTimeOff?: boolean; // Added for front, not available in the backend
};

export type CalendarDayDataType = {
  aiWarnings?: [AiVariant, CalendarScheduleWarnings[]][];
  errors?: DayDetailsErrorDetails[];
  warnings?: CalendarScheduleWarnings[];
  requests?: ({
    duration: DurationType;
  } & DayDetailsRequestType)[];
  schedules?: ScheduleData[];
  scheduledTime?: number;
  workdayAvailability?: EmployeeWorkdayAvailabilityData;
};

type UsageForType = {
  remaining: number;
  typeId: string;
  used: number;
};

export type CalendarDaysType = [number, CalendarDayDataType | null][]; // [unix, DayData],

export type RequestLimitsAndUsagePerYear = {
  usageForType: UsageForType[];
  year: number;
};

type CalendarEmployeeSummary = {
  hasAiSchedules?: boolean;
  hasErrors?: boolean;
  hasPendingRequests?: boolean;
  hasStandardSchedules?: boolean;
  hasUnpublishedChanges?: boolean;
  hasWarnings?: boolean;
  totalScheduledDays?: number;
  totalScheduledWorkTimeDuration?: number;
};

export type FetchCalendarResponse = {
  days: number[]; // dateUnix[]
  employeesData: [
    Employee['id'], // userId
    {
      aiSummaries?: [AiVariant, CalendarEmployeeSummary][];
      days: CalendarDaysType | null;
      requestLimitsAndUsagePerYear?: RequestLimitsAndUsagePerYear[];
    } & CalendarEmployeeSummary,
  ][];
  hasPendingRequests?: boolean;
  hasUnpublishedChanges?: boolean;
  holidays?: [number, string][]; // [dateUnix, name][]
  recentSchedules?: Omit<ScheduleData, 'id' | 'group' | 'isPublished' | 'isDeleted' | 'note' | 'isStart' | 'isEnd'>[];
};

export type ScheduleActionData = Omit<
  ScheduleData,
  'id' | 'group' | 'isPublished' | 'isDeleted' | 'isStart' | 'isEnd'
> & { isAi: boolean };
export type AddScheduleActionProps = ScheduleActionData & {
  personId: ParsedEmployee['id'];
  dateUnix: number;
};

export type EditScheduleActionData = ScheduleActionData & {
  dateUnix: number;
};

export enum ScheduleWizardSelectedDays {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export type ScheduleWizardOptionsProps = {
  ignoreHolidays: boolean;
  overrideExistingSchedules: boolean;
  dateRanges: number[];
  selectedDays: ScheduleWizardSelectedDays[];
};

export type PublishScheduleActionProps = {
  peopleIds: string[];
  timeRange:
    | {
        startDateUnix: number | undefined;
        endDateUnix: number | undefined;
      }
    | undefined;
  aiVariant?: AiVariant;
};

export enum DragActionType {
  Copy = 1,
  Move = 2,
}

export type DragScheduleToActionProps = {
  isAi: boolean;
  actionType: DragActionType;
  sourceScheduleId: string;
  sourceEmployeeId: string;
  to: {
    employeeId: string;
    dateUnix: number;
  };
};

export type GenerateAiSchedulesActionProps = {
  startDateUnix: number;
  endDateUnix: number;
  peopleIds?: string[];
};

export type GenerateAiSchedulesResponse = {
  generatedSchedules: number;
  peopleWithGeneratedSchedules: number;
};

export type AddWorkdayAvailabilityActionProps = {
  personId: string;
  available: boolean;
  forDay: number;
  dates: { startUnix: number; endUnix: number }[];
};

export type ResetAvailabilityActionProps = {
  timeRange: {
    startDateUnix: number;
    endDateUnix: number;
  };
  personId: string;
};
