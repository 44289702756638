import { t } from '@lingui/macro';

import { FetchUserSessionResponse } from 'api/actions/userSession/userSessionActions.types';
import { addNotification } from 'base/Notification/output/actions';
import { PublisherAddNotificationProps } from 'base/Notification/output/types';
import { TO } from 'constants/routes';
import { OrganizationSessionAtomType } from 'state/organizationSession';
import { CustomEvents, emitCustomEvent } from 'utils/customEvents';
import { isOfType } from 'utils/isOfType';

import { actionNames } from './actionNames';
import { ChannelAction, GetTimeTrackerChannelActionProps, SetRecoilStateEventAction } from './channelReducer.types';

export const REDIRECT_INITIATORS = {
  BROADCAST_CHANNEL: 'BROADCAST_CHANNEL',
  REFRESH_ACCESS_TOKEN_INTERCEPTOR: 'REFRESH_ACCESS_TOKEN_INTERCEPTOR',
};

export const broadcastChannelReducer = ({ type, payload }: ChannelAction, isAuthorized: boolean): void => {
  let setRecoilStateAction: SetRecoilStateEventAction | null = null;
  switch (type) {
    case actionNames.SIGN_IN: {
      if (isAuthorized) {
        break;
      }
      window.location.reload();
      break;
    }
    case actionNames.REFRESH_USER_SESSION: {
      if (!isAuthorized) {
        break;
      }
      if (!payload || !isOfType<FetchUserSessionResponse>(payload, 'preferredLanguage')) break;

      setRecoilStateAction = {
        payload,
        recoilKey: 'userSession',
      };

      break;
    }
    case actionNames.REFRESH_ORGANIZATION_SESSION: {
      if (!isAuthorized) {
        break;
      }
      if (!payload || !isOfType<OrganizationSessionAtomType>(payload, 'employeesMap')) break;

      setRecoilStateAction = {
        payload,
        recoilKey: 'organizationSession',
      };

      emitCustomEvent(CustomEvents.UPDATE_CHAT_USERS_SUB_GROUPS);

      break;
    }
    case actionNames.GET_TIME_TRACKER: {
      if (!isAuthorized) {
        break;
      }
      if (!payload || !isOfType<GetTimeTrackerChannelActionProps>(payload, 'verificationId')) break;

      emitCustomEvent(CustomEvents.HANDLE_BROADCAST_CHANNEL_TIME_TRACKER, payload);
      break;
    }
    case actionNames.CHANGE_LANGUAGE: {
      if (!payload || typeof payload !== 'string') break;

      setRecoilStateAction = {
        payload: payload as unknown as SetRecoilStateEventAction['payload'],
        recoilKey: 'language',
      };
      break;
    }
    case actionNames.SIGN_OUT: {
      if (!isAuthorized) {
        break;
      }
      emitCustomEvent(CustomEvents.REDIRECT, {
        route: TO.SIGN_OUT,
        state: { redirectInitiator: REDIRECT_INITIATORS.BROADCAST_CHANNEL },
      });
      break;
    }
    case actionNames.ADD_NOTIFICATION: {
      if (!isAuthorized) {
        break;
      }
      if (!payload || !isOfType<PublisherAddNotificationProps>(payload, 'type')) break;
      addNotification(payload);
      break;
    }
    case actionNames.ADD_REFRESH_PAGE_NOTIFICATION: {
      if (!isAuthorized) {
        break;
      }
      let message: null | string = null;
      if (typeof payload === 'string') {
        message = payload;
      }
      addNotification({
        title:
          message ||
          t({
            id: 'channel_reducer.refresh_page_notification.message',
            message: 'New data is available, please refresh the page for an update.',
          }),
        isStatic: true,
        type: 'info',
        actions: [
          {
            title: t({
              id: 'channel_reducer.refresh_page_notification.title',
              message: 'Refresh',
            }),
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });

      break;
    }
    default:
      break;
  }

  if (setRecoilStateAction) {
    emitCustomEvent(CustomEvents.SET_RECOIL_STATE, setRecoilStateAction);
  }
};
