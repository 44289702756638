import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import { FilterGroupNames } from 'layouts/AuthorizedApp/AsideFilters/types';
import {
  SEARCH_FILTER_TYPE,
  filterGroupStateAtomFamily,
  pickTeammatesFiltersRestoredAtom,
  searchFilterValueAtomFamily,
} from 'state/filters';
import {
  addRequestStateAtom,
  defaultDateUnixAtom,
  modifyRequestDataAtom,
  requestConfigStateAtom,
  requestFormRestoredAtom,
} from 'state/requests';

export const useAddRequestCleanUp = () => {
  const resetAddRequestState = useResetRecoilState(addRequestStateAtom);
  const resetDefaultDateUnix = useResetRecoilState(defaultDateUnixAtom);
  const resetModifyRequestData = useResetRecoilState(modifyRequestDataAtom);
  const resetRequestFormRestored = useResetRecoilState(requestFormRestoredAtom);
  const resetRequestConfigState = useResetRecoilState(requestConfigStateAtom);
  const resetTeammatesFilterState = useResetRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES_PICK_TEAMMATES_FILTERS),
  );
  const resetTagsFilterState = useResetRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.TAGS_PICK_TEAMMATES_FILTERS),
  );
  const resetRolesFilterState = useResetRecoilState(
    filterGroupStateAtomFamily(FilterGroupNames.ROLES_PICK_TEAMMATES_FILTERS),
  );
  const resetSearchInputState = useResetRecoilState(
    searchFilterValueAtomFamily(SEARCH_FILTER_TYPE.TEAMMATES_PICK_TEAMMATES_FILTERS),
  );
  const resetPickTeammatesFiltersRestored = useResetRecoilState(pickTeammatesFiltersRestoredAtom);

  useEffect(
    () => () => {
      resetAddRequestState();
      resetDefaultDateUnix();
      resetModifyRequestData();
      resetTeammatesFilterState();
      resetTagsFilterState();
      resetRolesFilterState();
      resetSearchInputState();
      resetRequestFormRestored();
      resetPickTeammatesFiltersRestored();
      resetRequestConfigState();
    },
    [
      resetAddRequestState,
      resetDefaultDateUnix,
      resetModifyRequestData,
      resetPickTeammatesFiltersRestored,
      resetRequestConfigState,
      resetRequestFormRestored,
      resetRolesFilterState,
      resetSearchInputState,
      resetTagsFilterState,
      resetTeammatesFilterState,
    ],
  );
};
