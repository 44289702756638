import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex, FlexProps } from 'theme-ui';

import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';

type Props = FlexProps;

export const InnerContainer: FC<Props> = ({ children }) => {
  const {
    requestConfig: { requestConfigState },
  } = useAddRequest();
  const { watch } = useFormContext<AddRequestExtendedFormContext>();

  const actionTypeWatch = watch('actionType');

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: 4,
        ...(!(requestConfigState?.hideActionSelect && actionTypeWatch === RequestActionType.Remove) && { mt: 4 }),
      }}
    >
      {children}
    </Flex>
  );
};
