import { FC } from 'react';
import { Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { languageSelector } from 'state/recoilState';

import { RequestsModalRoutesRenderer } from './components/modals/renderers/RequestsModalRoutesRenderer';

// All modals put here will be available for Calendar, Home, and Requests.
export const ModalRoutes: FC = () => {
  const language = useRecoilValue(languageSelector);
  const { isRequests } = useAppRouting(['isRequests']);
  return <Routes>{RequestsModalRoutesRenderer(language, isRequests)}</Routes>;
};
