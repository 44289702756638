import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { Divider } from 'components/Divider/Divider';
import { Select } from 'components/ui/Select/Select';
import { RolesSelect } from 'components/ui/Select/variants/RolesSelect';
import { Switch } from 'components/ui/Switch';
import { carriedOverLimitOptionsSelector } from 'state/selectOptions';
import { rolesApproveRequestsSelectOptionsSelector } from 'state/settings';
import { useFormContextCustomEvent } from '../../../../../../../hooks/useFormContextCustomEvent';
import { OptionLabel } from '../../../../../../OptionLabel';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';

export const TimeOff = (): React.ReactElement => {
  useLingui();
  const rolesSelectOptions = useRecoilValue(rolesApproveRequestsSelectOptionsSelector);
  const carriedOverLimitOptions = useRecoilValue(carriedOverLimitOptionsSelector);

  const {
    registerOnChange,
    registerOnBlur,
    watch,
    formState: { errors },
  } = useFormContextCustomEvent<{
    allowCarryOverUnUsedLimits: boolean;
    carriedOverLimitExpiresAfter: number;
    hideDetailedRequestTypes: boolean;
    timeOffApprovalSettings: {
      twoStepApprovalFlow: boolean;
      firstStepRoleId: string;
      secondStepRoleId: string;
    };
  }>();

  const twoStepApprovalWatch = watch('timeOffApprovalSettings.twoStepApprovalFlow');
  const allowCarryOverUnUsedLimitsWatch = watch('allowCarryOverUnUsedLimits');
  const firstStepRoleIdWatch = watch('timeOffApprovalSettings.firstStepRoleId');
  const secondStepRoleIdWatch = watch('timeOffApprovalSettings.secondStepRoleId');

  return (
    <SettingsGroup variant="sm">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="requests_settings.heading.time_off_requests">Time off requests</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <SettingsGroup.Body>
        <SettingsGroup.Body.Visible variant="md" pb={2} withoutBorderBottom>
          <Switch
            {...registerOnChange('hideDetailedRequestTypes')}
            label={t({
              id: 'requests_settings.hide_detailed_request_types.label',
              message: 'Hide detailed time off types for Employees',
            })}
            additionalInfo={
              <Text>
                <Trans id="requests_settings.hide_detailed_request_types.additional_info_extended">
                  Users with roles below Supervisor will not see the exact types of their colleagues' requests on the
                  Attendance Overview, Reports, Requests, and Calendar. They will only see general information about the
                  request.
                </Trans>
              </Text>
            }
            size="sm"
            bold
          />
          <Divider />
        </SettingsGroup.Body.Visible>
        <SettingsGroup.Body.Visible variant="md" pt={'0'} pb={2} withoutBorderBottom withoutBorderTop>
          <Switch
            {...registerOnChange('timeOffApprovalSettings.twoStepApprovalFlow')}
            label={t({
              id: 'requests_settings.two_step_approval_flow.label',
              message: 'Two-step approval flow',
            })}
            additionalInfo={
              <Text>
                <Trans id="requests_settings.two_step_approval_flow.additionalInfoExtended">
                  <Text as="span" sx={{ textDecoration: 'underline' }}>
                    Changing this setting will affect all pending requests
                  </Text>
                  . All Administrators always can accept requests despite below settings.
                </Trans>
              </Text>
            }
            size="sm"
            bold
          />
          {!twoStepApprovalWatch && <Divider />}
        </SettingsGroup.Body.Visible>

        {twoStepApprovalWatch && (
          <SettingsGroup.Body.Hidden variant="md" pt={2}>
            <OptionLabel
              label={t({
                id: 'requests_settings.first_step_role_id.label',
                message: 'First step',
              })}
              apendWith={
                <>
                  {rolesSelectOptions && (
                    <RolesSelect
                      {...registerOnBlur('timeOffApprovalSettings.firstStepRoleId', {
                        required: t({ id: 'global.forms.required.short' }),
                      })}
                      id="timeOffApprovalSettings.firstStepRoleId"
                      placeholder={t({
                        id: 'requests_settings.first_step_role_id.label',
                      })}
                      options={_.filter(rolesSelectOptions, (item) => item.id !== secondStepRoleIdWatch)}
                      size="sm"
                      error={!!errors.timeOffApprovalSettings?.firstStepRoleId}
                      errorMessage={errors.timeOffApprovalSettings?.firstStepRoleId?.message}
                      sx={{ maxWidth: '188px' }}
                    />
                  )}
                </>
              }
              withDivider
            />
            <OptionLabel
              label={t({
                id: 'requests_settings.second_step_role_id.label',
                message: 'Second step',
              })}
              apendWith={
                <>
                  {rolesSelectOptions && (
                    <RolesSelect
                      {...registerOnBlur('timeOffApprovalSettings.secondStepRoleId', {
                        required: t({ id: 'global.forms.required.short' }),
                      })}
                      id="timeOffApprovalSettings.secondStepRoleId"
                      placeholder={t({
                        id: 'requests_settings.second_step_role_id.label',
                      })}
                      options={_.filter(rolesSelectOptions, (item) => item.id !== firstStepRoleIdWatch)}
                      size="sm"
                      error={!!errors.timeOffApprovalSettings?.secondStepRoleId}
                      errorMessage={errors.timeOffApprovalSettings?.secondStepRoleId?.message}
                      sx={{ maxWidth: '188px' }}
                    />
                  )}
                </>
              }
              withDivider
            />
          </SettingsGroup.Body.Hidden>
        )}

        <SettingsGroup.Body.Visible
          variant="md"
          pt={twoStepApprovalWatch ? 2 : '0rem'}
          pb={allowCarryOverUnUsedLimitsWatch ? 2 : '0.75rem'}
          withoutBorderBottom={allowCarryOverUnUsedLimitsWatch}
          withoutBorderTop
        >
          <Switch
            {...registerOnChange('allowCarryOverUnUsedLimits')}
            label={t({
              id: 'requests_settings.allow_carry_over_unused_limits.label',
              message: 'Allow carry-over of unused time off limits to next years limit',
            })}
            additionalInfo={t({
              id: 'requests_settings.allow_carry_over_unused_limits.additionalInfo',
              message: 'It is an individually defined option for each time off type on each employee.',
            })}
            size="sm"
            bold
          />
        </SettingsGroup.Body.Visible>

        {allowCarryOverUnUsedLimitsWatch && (
          <SettingsGroup.Body.Hidden variant="md" pt={2} pb={'0.75rem'} withBorderBottom>
            <OptionLabel
              label={t({
                id: 'requests_settings.carried_over_limit_expires_after.label',
                message: 'Carried-over limit expires after',
              })}
              apendWith={
                <Select
                  {...registerOnBlur('carriedOverLimitExpiresAfter', { valueAsNumber: true })}
                  id="carriedOverLimitExpiresAfter"
                  placeholder="select"
                  options={carriedOverLimitOptions}
                  size="sm"
                  error={!!errors.carriedOverLimitExpiresAfter}
                  sx={{ maxWidth: '188px' }}
                />
              }
            />
          </SettingsGroup.Body.Hidden>
        )}
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
