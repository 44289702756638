import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ExportDataType } from 'api/actions/export/exportActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { ADD_SCHEDULE_WIZARD_MODAL_WIDTH } from 'constants/calendar';
import { BASE_ROUTES, PATH_REL } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { DownloadPrintModal } from 'pages/Reports/output/DownloadPrintModal';
import { ExportModalRoutes } from 'pages/Reports/output/ExportModalRoutes';
import { ModalRoutes as RequestsModalRoutes } from 'pages/Requests/output/ModalRoutes';
import { EditTeammateModalWithDefaultModalWrapper } from 'pages/Team/output/EditTeammate';
import { selectedCalendarIdsSelector } from 'state/calendar';
import { languageSelector } from 'state/recoilState';

import { AddScheduleModal } from './components/modals/AddScheduleModal/AddScheduleModal';
import { CalendarViewSettingsModal } from './components/modals/CalendarViewSettingsModal';
import { CopySchedulesToModal } from './components/modals/CopySchedulesToModal';
import { DeleteAllSchedulesModal } from './components/modals/DeleteAllSchedulesModal';
import { DeleteAllUnpublishedChangesModal } from './components/modals/DeleteAllUnpublishedChangesModal';
import { DeleteScheduleModal } from './components/modals/DeleteScheduleModal';
import { EditScheduleModal } from './components/modals/EditScheduleModal';
import { PublishScheduleModal } from './components/modals/PublishScheduleModal';
import { PublishSingleScheduleModal } from './components/modals/PublishSingleScheduleModal';
import { ResetAvailabilityModal } from './components/modals/ResetAvailabilityModal';
import { ScheduleWizardModal } from './components/modals/ScheduleWizardModal/ScheduleWizardModal';
import { SetAvailabilityModal } from './components/modals/SetAvailabilityModal';
import { ShareCalendarModal } from './components/modals/ShareCalendarModal';

export const ModalRoutes = (): React.ReactElement => {
  const language = useRecoilValue(languageSelector);
  const { isVisible: isOnboardingVisible } = useInitialOnboarding();
  const { isInitialized, modulesManagement } = useAppPermissions();

  return (
    <>
      {/* EXPORT */}
      <ExportModalRoutes />
      <Routes>
        {/* PUBLISH */}
        <Route
          path={PATH_REL.PUBLISH_SCHEDULE__ID[language]}
          element={
            <Modal size="xs" path={PATH_REL.PUBLISH_SCHEDULE__ID[language]}>
              <PublishSingleScheduleModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.PUBLISH_SCHEDULE[language].replace('/*', '')}
          element={
            <Modal size="xs">
              <PublishScheduleModal />
            </Modal>
          }
        />

        {/* REVERT UNPUBLISHED CHANGES */}
        <Route
          path={PATH_REL.DELETE_UNPUBLISHED_CHANGES[language]}
          element={
            <Modal size="xs">
              <DeleteAllUnpublishedChangesModal />
            </Modal>
          }
        />

        {/* DELETE SCHEDULES */}
        <Route
          path={PATH_REL.DELETE_ALL_SCHEDULES[language]}
          element={
            <Modal size="xs">
              <DeleteAllSchedulesModal />
            </Modal>
          }
        />

        {/* COPY TO WIZARD */}
        <Route
          path={PATH_REL.COPY_SCHEDULES_TO[language]}
          element={
            <Modal size="sm" fullHeight sx={{ width: ADD_SCHEDULE_WIZARD_MODAL_WIDTH }}>
              <CopySchedulesToModal />
            </Modal>
          }
        />

        <Route
          element={
            isInitialized && !modulesManagement.Schedules ? (
              <Navigate to={BASE_ROUTES.PAGE_NOT_FOUND[language]} />
            ) : (
              <Outlet />
            )
          }
        >
          {/* WIZARD */}
          <Route
            path={PATH_REL.SCHEDULE_WIZARD[language]}
            element={
              <Modal
                size="sm"
                fullHeight
                showMinimize={!isOnboardingVisible}
                sx={{ width: ADD_SCHEDULE_WIZARD_MODAL_WIDTH }}
              >
                <ScheduleWizardModal />
              </Modal>
            }
          />
        </Route>

        {/* DOWNLOAD */}
        <Route
          path={`${PATH_REL.DOWNLOAD_MODAL__EXTENSION[language].replace('/*', '')}/${ExportDataType.TimeOff}`}
          element={
            <Modal
              size="sm"
              path={`${PATH_REL.DOWNLOAD_MODAL__EXTENSION[language].replace('/*', '')}/${ExportDataType.TimeOff}`}
            >
              <DownloadPrintModal
                requireIdsSelection
                dataType={ExportDataType.TimeOff}
                selectedIdsRecoilState={selectedCalendarIdsSelector}
                view="calendar"
                variant="download"
              />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.DOWNLOAD_MODAL__EXTENSION[language]}
          element={
            <Modal size="sm" path={PATH_REL.DOWNLOAD_MODAL__EXTENSION[language]}>
              <DownloadPrintModal
                requireIdsSelection
                dataType={ExportDataType.Schedule}
                selectedIdsRecoilState={selectedCalendarIdsSelector}
                view="calendar"
                variant="download"
              />
            </Modal>
          }
        />

        {/* PRINT */}
        <Route
          // FIXME: find a better way
          path={PATH_REL.PRINT_MODAL[language].replace('/*', '')}
          element={
            <Modal size="sm">
              <DownloadPrintModal
                requireIdsSelection
                dataType={ExportDataType.Schedule}
                selectedIdsRecoilState={selectedCalendarIdsSelector}
                view="calendar"
                variant="print"
              />
            </Modal>
          }
        />
        <Route
          path={`${PATH_REL.PRINT_MODAL[language].replace('/*', '')}/${ExportDataType.TimeOff}`}
          element={
            <Modal size="sm" path={`${PATH_REL.PRINT_MODAL[language].replace('/*', '')}/${ExportDataType.TimeOff}`}>
              <DownloadPrintModal
                requireIdsSelection
                dataType={ExportDataType.TimeOff}
                selectedIdsRecoilState={selectedCalendarIdsSelector}
                view="calendar"
                variant="print"
              />
            </Modal>
          }
        />
        <Route
          path={`${PATH_REL.PRINT_MODAL[language].replace('/*', '')}/${ExportDataType.TimeOffYearly}`}
          element={
            <Modal
              size="sm"
              path={`${PATH_REL.PRINT_MODAL[language].replace('/*', '')}/${ExportDataType.TimeOffYearly}`}
            >
              <DownloadPrintModal
                requireIdsSelection
                dataType={ExportDataType.TimeOffYearly}
                selectedIdsRecoilState={selectedCalendarIdsSelector}
                view="calendar"
                variant="print"
              />
            </Modal>
          }
        />

        {/* SCHEDULES */}
        <Route
          path={PATH_REL.DELETE_SCHEDULE[language]}
          element={
            <Modal size="xs">
              <DeleteScheduleModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.ADD_SCHEDULE__USER__DATE[language]}
          element={
            <Modal
              size="sm"
              fullHeight
              showMinimize={!isOnboardingVisible}
              path={PATH_REL.ADD_SCHEDULE__USER__DATE[language]}
            >
              <AddScheduleModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.EDIT_SCHEDULE__ID__DATE__USER[language]}
          element={
            <Modal size="sm" fullHeight path={PATH_REL.EDIT_SCHEDULE__ID__DATE__USER[language]}>
              <EditScheduleModal />
            </Modal>
          }
        />

        {/* AVAILABILITY */}
        <Route
          path={PATH_REL.SET_AVAILABILITY[language]}
          element={
            <Modal size="sm">
              <SetAvailabilityModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.RESET_AVAILABILITY[language]}
          element={
            <Modal size="xs">
              <ResetAvailabilityModal />
            </Modal>
          }
        />

        {/* VIEW SETTINGS */}
        <Route
          path={PATH_REL.VIEW_SETTINGS_MODAL[language]}
          element={
            <Modal size="sm" fullHeight>
              <CalendarViewSettingsModal />
            </Modal>
          }
        />

        {/* EDIT TEAMMATE */}
        <Route
          path={PATH_REL.EDIT_TEAMMATE_MODAL__ID[language]}
          element={<EditTeammateModalWithDefaultModalWrapper path={PATH_REL.EDIT_TEAMMATE_MODAL__ID[language]} />}
        />

        {/* SHARE CALENDAR */}
        <Route
          path={PATH_REL.SHARE_CALENDAR[language]}
          element={
            <Modal size="xs">
              <ShareCalendarModal />
            </Modal>
          }
        />
      </Routes>
      {/* Re-import Request modals */}
      <RequestsModalRoutes />
    </>
  );
};
