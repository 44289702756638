import _ from 'lodash';
import { atom, atomFamily, DefaultValue, selector, selectorFamily } from 'recoil';

import { MessageForFetch } from 'api/actions/chat/chatActions.types';
import {
  ChatUserDetails,
  DefaultRole,
  Employee,
  PaymentPeriod,
  PersonName,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { OrganizationAccountState, PaymentMethodEnum } from 'api/actions/payments/paymentsActions.types';
import { NameDisplayOrder } from 'api/actions/userSession/userSessionActions.types';
import { ChatWindowState } from 'base/Chat/output/types';
import {
  CHAT_BOT,
  CHAT_WINDOW_IS_EXPANDED_WIDTH,
  CHAT_WINDOW_IS_VISIBLE_WIDTH,
  CHAT_WINDOW_MOBILE_WIDTH,
  CHAT_WINDOW_WIDTH,
  MAIN_WINDOW_MOBILE_WIDTH,
  MAIN_WINDOW_WIDTH,
  MARGIN,
  MESSAGES_PART_SIZE,
} from 'constants/chat';
import { INITIALIZE_CHAT_WITH_OPEN_CRISP_CHAT } from 'constants/common';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';
import { dateTime } from 'utils/dateTime';
import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';
import { getStringWithReducedWhiteSpaces } from 'utils/whiteSpaceReducer';

import { appPermissionsSelector } from './appPermissions';
import { organizationSessionAtom } from './organizationSession';
import { windowSizeAtom } from './recoilState';
import { nameDisplayOrderSelector, userDetailsSelector, userSessionAtom } from './userSession';

//
// CHAT SEARCH FILTER
//

const chatSearchFilterValueAtom = atom<string>({
  key: 'chatSearchFilterValue',
  default: '',
});

export const chatSearchFilterValueSelector = selector<string>({
  key: 'chatSearchFilterValueSelector',
  get: ({ get }) => get(chatSearchFilterValueAtom),
  set: ({ set, get }, newValue) => {
    if (isRecoilDefaultValue(newValue)) return;
    const searchFilterValue = get(chatSearchFilterValueAtom);

    if (getStringWithReducedWhiteSpaces(newValue) !== getStringWithReducedWhiteSpaces(searchFilterValue)) {
      set(chatSearchFilterValueAtom, newValue);
    }
  },
});

const parsedChatSearchFilterValueSelector = selector<string>({
  key: 'parsedChatSearchFilterValueSelector',
  get: ({ get }) => getStringWithReducedWhiteSpaces(get(chatSearchFilterValueAtom)).trim(),
});

//
// CHAT USERS SUBGROUPS MAPS:
//

export const chatUsersWithMessageHistoryAtom = atom<{ value: Map<string, boolean> } | null>({
  key: 'chatUsersWithMessageHistory',
  default: null,
});

export const chatUsersWithUnreadMessagesAtom = atom<{ value: Map<string, boolean> } | null>({
  key: 'chatUsersWithUnreadMessages',
  default: null,
});

export const onlineChatUsersAtom = atom<{ value: Map<string, boolean> } | null>({
  key: 'onlineChatUsers',
  default: null,
});

export const anyChatUserHasUnreadMessageSelector = selector<boolean>({
  key: 'anyChatUserHasUnreadMessage',
  get: ({ get }) => {
    const chatUsersWithUnreadMessages = get(chatUsersWithUnreadMessagesAtom);
    const chatUsersWithUnreadMessagesMap = chatUsersWithUnreadMessages?.value;

    if (!chatUsersWithUnreadMessagesMap) return false;

    const newChatUsersWithUnreadMessagesMap = new Map([...chatUsersWithUnreadMessagesMap]);
    newChatUsersWithUnreadMessagesMap?.delete(CHAT_BOT);

    return !!newChatUsersWithUnreadMessagesMap?.size;
  },
});

export const notOpenedUserHasUnreadMessageSelector = selector<boolean>({
  key: 'notOpenedUserHasUnreadMessage',
  get: ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const chatWindowIds = get(chatWindowIdsSelector);
    const chatUsersWithUnreadMessages = get(chatUsersWithUnreadMessagesAtom);
    const chatUsersWithUnreadMessagesMap = chatUsersWithUnreadMessages?.value;

    if (!chatUsersWithUnreadMessagesMap) return false;

    const newChatUsersWithUnreadMessagesMap = new Map([...chatUsersWithUnreadMessagesMap]);
    newChatUsersWithUnreadMessagesMap?.delete(CHAT_BOT);

    chatWindowIds.forEach((id) => newChatUsersWithUnreadMessagesMap?.delete(id));

    return !!newChatUsersWithUnreadMessagesMap?.size;
  },
});

//
// CHAT USERS SUBGROUPS SELECTORS FOR TARGETING SINGLE USER:
//

const chatUserWithMessageHistorySelectorFamily = selectorFamily<boolean, string>({
  key: 'chatUserWithMessageHistorySelector',
  get:
    (chatUserId) =>
    ({ get }) =>
      get(chatUsersWithMessageHistoryAtom)?.value.get(chatUserId) || false,
  set:
    (chatUserId) =>
    ({ set, get }, newValue) => {
      const idsMap = get(chatUsersWithMessageHistoryAtom)?.value || new Map<string, boolean>();

      const currentValue = idsMap.get(chatUserId);

      if (!!currentValue === newValue || isRecoilDefaultValue(newValue)) return;

      if (newValue) {
        idsMap.set(chatUserId, newValue);

        set(chatUsersWithMessageHistoryAtom, { value: idsMap });
      } else {
        idsMap.delete(chatUserId);
        set(chatUsersWithMessageHistoryAtom, { value: idsMap });
      }
    },
});

export const chatUserWithUnreadMessagesSelectorFamily = selectorFamily<boolean, string>({
  key: 'chatUserWithUnreadMessagesSelector',
  get:
    (chatUserId) =>
    ({ get }) =>
      get(chatUsersWithUnreadMessagesAtom)?.value.get(chatUserId) || false,

  set:
    (chatUserId) =>
    ({ set, get }, newValue) => {
      const idsMap = get(chatUsersWithUnreadMessagesAtom)?.value || new Map<string, boolean>();

      const currentValue = idsMap.get(chatUserId);

      if (!!currentValue === newValue || isRecoilDefaultValue(newValue)) return;

      if (newValue) {
        idsMap.set(chatUserId, newValue);
        set(chatUsersWithUnreadMessagesAtom, { value: idsMap });
      } else {
        idsMap.delete(chatUserId);
        set(chatUsersWithUnreadMessagesAtom, { value: idsMap });
      }
    },
});

export const onlineChatUserSelectorFamily = selectorFamily<boolean, string>({
  key: 'onlineChatUserSelector',
  get:
    (chatUserId) =>
    ({ get }) =>
      get(onlineChatUsersAtom)?.value.get(chatUserId) || false,

  set:
    (chatUserId) =>
    ({ set, get }, newValue) => {
      const idsMap = get(onlineChatUsersAtom)?.value || new Map<string, boolean>();

      const currentValue = idsMap.get(chatUserId);

      if (!!currentValue === newValue || isRecoilDefaultValue(newValue)) return;

      if (newValue) {
        idsMap.set(chatUserId, newValue);
        set(onlineChatUsersAtom, { value: idsMap });
      } else {
        idsMap.delete(chatUserId);
        set(onlineChatUsersAtom, { value: idsMap });
      }
    },
});

export type ChatUserStatus = {
  personId: string;
  isOnline: boolean;
};

export const setChatUserIsOnlineSelector = selector<ChatUserStatus | null>({
  key: 'setChatUserIsOnline',
  get: () => null,
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue || !newValue) return;

    const { personId, isOnline: newIsOnline } = newValue;

    set(onlineChatUserSelectorFamily(personId), newIsOnline);
  },
});

//
// CHAT USERS MAP CREATED FROM TWO LIST RECEIVED IN ORGANIZATION SESSION
//

export const chatUsersMapSelector = selector<Map<string, ChatUserDetails>>({
  key: 'chatUsersMap',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    const userDetails = get(userDetailsSelector);
    if (!organizationSession || !userDetails) return new Map();

    const {
      role: { id: roleId },
      id,
    } = userDetails;

    const isAdmin = roleId === `${DefaultRole.Admin}`;

    const { chatUsersMap, employeesMap } = organizationSession;

    const newMap = new Map(chatUsersMap);

    const currentChatUser = newMap.get(id);

    if (currentChatUser) {
      newMap.set(id, { ...currentChatUser, isHidden: true });
    }

    if (!isAdmin) {
      return newMap;
    }

    employeesMap.forEach((_employee, employeeId) => {
      const chatUser = newMap.get(employeeId);

      if (!chatUser || employeeId === id) {
        return;
      }

      const { isHidden, ...restChatUser } = chatUser;
      newMap.set(employeeId, restChatUser);
    });

    return newMap;
  },
});

//
// CHAT USERS MAP WITHOUT HIDDEN USERS, WITH EXCEPTION FOR THOSE WITH EXISTING MESSAGE HISTORY
//

const filteredChatUsersMapSelector = selector<Map<string, ChatUserDetails>>({
  key: 'filteredChatUsersMap',
  get: ({ get }) => {
    const chatUsersMap = get(chatUsersMapSelector);
    const chatUsersWithMessageHistory = get(chatUsersWithMessageHistoryAtom);
    const chatSearchFilterState = get(parsedChatSearchFilterValueSelector);
    const nameDisplayOrder = get(nameDisplayOrderSelector);

    const newMap = new Map(chatUsersMap);

    const filterBySearchInput = (name: PersonName) => {
      if (!chatSearchFilterState) {
        return true;
      }

      const searchableValue =
        nameDisplayOrder === NameDisplayOrder.SurnameFirst
          ? `${name.surname} ${name.firstName}`
          : `${name.firstName} ${name.surname}`;

      return _.includes(searchableValue?.toLocaleLowerCase(), chatSearchFilterState.toLocaleLowerCase());
    };

    newMap.forEach(({ isHidden, name }, chatUserId) => {
      if ((isHidden && !chatUsersWithMessageHistory?.value.get(chatUserId)) || !filterBySearchInput(name)) {
        newMap.delete(chatUserId);
      }
    });

    return newMap;
  },
});

//
// CHAT USERS IDS ARRAY SORTED BY HAS UNREAD MESSAGES, THEN BY NAME
//

export const sortedChatUsersIdsSelector = selector<string[]>({
  key: 'sortedChatUsersIds',
  get: ({ get }) => {
    const filteredChatUsersMap = get(filteredChatUsersMapSelector);
    const chatUsersWithUnreadMessages = get(chatUsersWithUnreadMessagesAtom)?.value;
    const nameDisplayOrder = get(nameDisplayOrderSelector);
    const filteredChatUsersIds = [...filteredChatUsersMap.keys()];

    const sortByNameValueGetter = (id: string) => {
      const name = filteredChatUsersMap.get(id)?.name;

      if (!name) return '';

      const { firstName, surname } = name;

      return nameDisplayOrder === NameDisplayOrder.SurnameFirst ? `${surname} ${firstName}` : `${firstName} ${surname}`;
    };

    const orderedChatUsersIds = _.orderBy(
      filteredChatUsersIds,
      [(id) => chatUsersWithUnreadMessages?.get(id), sortByNameValueGetter],
      ['asc', 'asc'],
    );
    return orderedChatUsersIds;
  },
});

//
// MESSAGES STATE
//

const messagesAtomFamily = atomFamily<ParsedMessage[] | null, string>({
  key: 'messages',
  default: null,
});
export const sortedMessagesSelector = selectorFamily<ParsedMessage[] | null, string>({
  key: 'sortedMessages',
  get:
    (id) =>
    ({ get }) => {
      const messages = get(messagesAtomFamily(id));

      if (!messages) return null;

      return _.orderBy(messages, ['createdUnix'], ['desc']);
    },
});
export const removeMessageSelector = selectorFamily<MessageForFetch['id'] | null, string>({
  key: 'removeMessage',
  get: () => () => null,
  set:
    (atomId) =>
    ({ set, get }, messageId) => {
      const messages = get(messagesAtomFamily(atomId));

      if (!(messageId instanceof DefaultValue) && messages) {
        set(
          messagesAtomFamily(atomId),
          _.filter(messages, (m) => !_.isEqual(m.id, messageId)),
        );
      }
    },
});

export enum MessageSendStatus {
  SENT = 0,
  UNSENT,
  SENDING,
}

type MessageStatus = {
  status: MessageSendStatus;
};

export type ParsedMessage = Pick<Employee, 'avatarUrl'> & MessageForFetch & PersonName & Partial<MessageStatus>;

export const parsedMessagesWithPersonalDataSelector = selectorFamily<MessageForFetch[] | null, string>({
  key: 'parsedMessagesWithPersonalData',
  get: () => () => null,
  set:
    (atomId) =>
    ({ get, set }, newMessages) => {
      const messages = get(messagesAtomFamily(atomId));
      const chatUsersMap = get(chatUsersMapSelector);

      if (!chatUsersMap || !atomId || newMessages instanceof DefaultValue) return;

      const parsedMessages: ParsedMessage[] = _.filter(
        _.map(newMessages, (m) => {
          const employee = chatUsersMap.get(m.sender);

          if (!employee) return {};

          const { name, avatarUrl } = employee;

          return {
            ...m,
            ...name,
            avatarUrl,
          };
        }),
        (m): m is ParsedMessage => !!m,
      );

      if (messages) set(messagesAtomFamily(atomId), [...messages, ...parsedMessages]);
      else set(messagesAtomFamily(atomId), parsedMessages);
    },
});

export const addMessageSelector = selector<MessageForFetch | null>({
  key: 'addMessage',
  get: () => null,
  set: ({ set, get }, newMessage) => {
    if (!(newMessage instanceof DefaultValue) && newMessage) {
      const messages = get(messagesAtomFamily(newMessage.sender));
      const chatUsersMap = get(chatUsersMapSelector);
      const userSession = get(userSessionAtom);

      if (!chatUsersMap) return;

      const employee = chatUsersMap.get(newMessage.sender);
      const findMessage = _.find(messages, (m) => m.id === newMessage.id);

      if (!employee || findMessage) return;

      const { name, avatarUrl } = employee;

      if (findMessage) return;

      if (messages) {
        set(messagesAtomFamily(newMessage.sender), [
          {
            ...newMessage,
            ...name,
            avatarUrl,
          },
          ...messages,
        ]);
      }

      if (userSession && userSession.personId !== newMessage.sender) {
        const chatUserWithMessageHistorySelector = chatUserWithMessageHistorySelectorFamily(newMessage.sender);
        const hasMessageHistory = get(chatUserWithMessageHistorySelector);

        if (!hasMessageHistory) {
          set(chatUserWithMessageHistorySelector, true);
        }

        const chatUserWithUnreadMessagesSelector = chatUserWithUnreadMessagesSelectorFamily(newMessage.sender);
        const hasUnreadMessages = get(chatUserWithUnreadMessagesSelector);

        if (!hasUnreadMessages) {
          set(chatUserWithUnreadMessagesSelector, true);
        }
      }
    }
  },
});

type OwnMessage = MessageForFetch & Partial<MessageStatus>;

export const addOwnMessageSelector = selector<OwnMessage | null>({
  key: 'addOwnMessage',
  get: () => null,
  set: ({ set, get }, newMessage) => {
    if (!(newMessage instanceof DefaultValue) && newMessage) {
      const messages = get(messagesAtomFamily(newMessage.receiver));
      const chatUsersMap = get(chatUsersMapSelector);

      if (!chatUsersMap) return;

      const employee = chatUsersMap.get(newMessage.sender);

      if (!employee) return;

      const { name, avatarUrl } = employee;

      if (messages) {
        set(messagesAtomFamily(newMessage.receiver), [
          {
            ...newMessage,
            ...name,
            avatarUrl,
          },
          ...messages,
        ]);
      }
    }
  },
});

export const updateOwnMessageSelector = selector<OwnMessage | null>({
  key: 'updateOwnMessage',
  get: () => null,
  set: ({ set, get }, newMessage) => {
    if (!(newMessage instanceof DefaultValue) && newMessage) {
      const messagesList = get(messagesAtomFamily(newMessage.receiver));

      const messageToUpdate = _.find(messagesList, (m) => newMessage.id === m.id);
      const messages = _.filter(messagesList, (m) => newMessage.id !== m.id);

      if (messages && messageToUpdate) {
        set(messagesAtomFamily(newMessage.receiver), [
          {
            ...messageToUpdate,
            status: newMessage.status,
          },
          ...messages,
        ]);
      }
    }
  },
});

export const removeExcessMessagesSelector = selector<string | null>({
  key: 'removeExcessMessage',
  get: () => null,
  set: ({ set, get }, chatWindowId) => {
    if (!(chatWindowId instanceof DefaultValue) && chatWindowId) {
      const messages = get(messagesAtomFamily(chatWindowId));

      if (messages) set(messagesAtomFamily(chatWindowId), _.slice(messages, 0, MESSAGES_PART_SIZE));
    }
  },
});

//
// UI STATE
//

type ChatWidths = {
  default: number;
  isVisible: number;
  isExpanded: number;
  mainWindow: number;
};

const defaultChatWidthsSelector = selector<ChatWidths>({
  key: 'defaultChatWidths',
  get: ({ get }) => {
    const { width } = get(windowSizeAtom);

    if ((width || window.innerWidth) > MEDIA_BREAKPOINTS.SM)
      return {
        default: CHAT_WINDOW_WIDTH + MARGIN,
        isVisible: CHAT_WINDOW_IS_VISIBLE_WIDTH + MARGIN,
        isExpanded: CHAT_WINDOW_IS_EXPANDED_WIDTH + MARGIN,
        mainWindow: MAIN_WINDOW_WIDTH + MARGIN,
      };
    return {
      default: CHAT_WINDOW_MOBILE_WIDTH + MARGIN,
      isVisible: 0,
      isExpanded: 0,
      mainWindow: MAIN_WINDOW_MOBILE_WIDTH + MARGIN,
    };
  },
});

export const mainWindowIsVisibleAtom = atom<boolean>({
  key: 'mainWindowIsVisible',
  default: false,
});

export const hasPermissionToUseCrispSelector = selector<boolean>({
  key: 'hasPermissionToUseCrisp',
  get: ({ get }) => {
    const userDetails = get(userDetailsSelector);
    if (!userDetails) {
      return false;
    }
    const { roleId } = userDetails;

    const hasPermission = (() => {
      switch (roleId) {
        case `${DefaultRole.Admin}`:
        case `${DefaultRole.Manager}`:
          return true;
        default:
          return false;
      }
    })();

    return hasPermission;
  },
});

export const crispSessionDataSelector = selector({
  key: 'crispSessionData',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);
    const organizationSession = get(organizationSessionAtom);
    const userDetails = get(userDetailsSelector);
    const permissions = get(appPermissionsSelector);

    if (!organizationSession || !userSession || !permissions || !userDetails) return null;

    const { role } = userDetails;
    const { marketingAgreement } = userSession;
    const { details, activeSubscription, currentEmployeesCount } = organizationSession;
    const { modules } = permissions;

    const createAccDate = dateTime(details.createDateUnix, { utc: true });
    const formattedCreateAccDate = createAccDate.format('DD.MM.YYYY');

    const paymentEndDate = dateTime(activeSubscription.endDate, { utc: true });
    const formattedPaymentEndDate = paymentEndDate.format('DD.MM.YYYY');

    const paymentType = PaymentPeriod[activeSubscription.period];
    const paymentMethod = PaymentMethodEnum[activeSubscription.method];
    const userRole = DefaultRole[+role.defaultRole];
    const accType = OrganizationAccountState[activeSubscription.state];

    return {
      acc_type: accType,
      create_date: formattedCreateAccDate,
      create_date_unix: `${details.createDateUnix}`,
      payment_end_date: formattedPaymentEndDate,
      payment_end_date_unix: `${activeSubscription.endDate}`,
      payment_type: paymentType,
      payment_method: paymentMethod,
      role: userRole,
      rcp_module: `${modules.TimeTracking}`,
      schedules_module: `${modules.Schedule}`,
      requests_module: `${modules.Requests}`,
      users: `${activeSubscription.paidEmployeesCount}`,
      users_current: `${currentEmployeesCount}`,
      marketing_agreement: `${marketingAgreement}`,
    };
  },
});

export const crispIsVisibleAtom = atom<boolean>({
  key: 'crispIsVisible',
  default: INITIALIZE_CHAT_WITH_OPEN_CRISP_CHAT,
});

//
// CHAT WINDOW STATE
//

export const chatWindowIdsAtom = atom<string[]>({
  key: 'chatWindowIds',
  default: [],
});

// block the possibility of altering localStore to show chats with hidden users
export const chatWindowIdsSelector = selector<string[]>({
  key: 'chatWindowIdsSelector',
  get: ({ get }) => {
    const chatWindowIds = get(chatWindowIdsAtom);

    const filteredChatWindowIds = chatWindowIds.filter((id) => {
      const hasMessageHistory = get(chatUserWithMessageHistorySelectorFamily(id));
      const chatUser = get(chatUsersMapSelector).get(id);

      if (!chatUser) return false;

      return !chatUser.isHidden || hasMessageHistory;
    });
    const hasPermissionToUseCrisp = get(hasPermissionToUseCrispSelector);

    return [...(hasPermissionToUseCrisp ? [CHAT_BOT] : []), ...filteredChatWindowIds];
  },
});

export const chatWindowAtomFamily = atomFamily<ChatWindowState, string>({
  key: 'chatWindow',
  default: {
    isVisible: false,
    isExpanded: false,
  },
});

export const removeChatWindowSelector = selectorFamily<ChatWindowState | null, string | undefined>({
  key: 'removeChatWindow',
  get: () => () => null,
  set:
    (id) =>
    ({ set, get, reset }) => {
      const chatWindowIds = get(chatWindowIdsSelector);

      if (!id) return;

      if (chatWindowIds && id) {
        set(
          chatWindowIdsAtom,
          _.filter(chatWindowIds, (w) => !_.isEqual(w, id)),
        );
        reset(chatWindowAtomFamily(id));
        reset(messagesAtomFamily(id));
      }
    },
});

const chatWindowWidthSelector = selectorFamily<number, string>({
  key: 'chatWindowWidth',
  get:
    (id) =>
    ({ get }) => {
      const chatWindow = get(chatWindowAtomFamily(id));
      const { isVisible, isExpanded, default: defaultWidth } = get(defaultChatWidthsSelector);

      if (!chatWindow) return 0;
      if (chatWindow.isExpanded) return isExpanded;
      if (chatWindow.isVisible) return isVisible;

      return defaultWidth;
    },
});

export const anyWindowIsVisibleSelector = selector<boolean>({
  key: 'anyWindowIsVisible',
  get: ({ get }) => {
    const chatWindowIds = get(chatWindowIdsSelector);
    const mainWindowIsVisible = get(mainWindowIsVisibleAtom);

    if (!chatWindowIds) return false;
    if (mainWindowIsVisible) return true;

    const isVisible = _.find(chatWindowIds, (w) => get(chatWindowAtomFamily(w))?.isVisible);

    return !!isVisible;
  },
});

export const anyChatWindowIsVisibleSelector = selector<boolean>({
  key: 'anyChatWindowIsVisible',
  get: ({ get }) => {
    const chatWindowIds = get(chatWindowIdsSelector);

    if (!chatWindowIds) return false;

    const isVisible = _.find(chatWindowIds, (w) => get(chatWindowAtomFamily(w))?.isVisible);

    return !!isVisible;
  },
});

export const chatWidthSelector = selector<number>({
  key: 'chatWidth',
  get: ({ get }) => {
    const chatWindowIds = get(chatWindowIdsSelector);
    const chatWindowsWidth = _.sum(_.map(chatWindowIds, (w) => get(chatWindowWidthSelector(w))));
    const { mainWindow } = get(defaultChatWidthsSelector);

    return chatWindowsWidth + mainWindow;
  },
});

export type ChatWindowAction = {
  type: keyof ChatWindowState;
  value: ChatWindowState['isExpanded'];
};

export const setStateChatWindowSelector = selectorFamily<ChatWindowAction | null, string>({
  key: 'setStateChatWindow',
  get: () => () => null,
  set:
    (id) =>
    ({ set, get }, action) => {
      const chatWindow = get(chatWindowAtomFamily(id));
      const chatWindowIds = get(chatWindowIdsSelector);
      if (chatWindowIds.length > 0 && chatWindow && action && !(action instanceof DefaultValue)) {
        const { width: windowWidth } = get(windowSizeAtom);
        const chatWidth = get(chatWidthSelector);
        const chatWindowCurrentWidth = get(chatWindowWidthSelector(id));
        const chatWidths = get(defaultChatWidthsSelector);
        if (
          action.value &&
          windowWidth &&
          chatWidth &&
          windowWidth - chatWidth < chatWidths[action.type] - chatWindowCurrentWidth
        ) {
          const windowToRemove = _.find(chatWindowIds, (w) => !_.isEqual(w, id));
          const windowToRemove2 = _.find(chatWindowIds, (w) => !_.isEqual(w, id) && !_.isEqual(w, windowToRemove));

          if (windowToRemove && windowToRemove2) {
            if (windowWidth - chatWidth + chatWidths.isVisible < chatWidths[action.type]) {
              set(removeChatWindowSelector(windowToRemove), null);
            } else {
              set(removeChatWindowSelector(windowToRemove), null);
              set(removeChatWindowSelector(windowToRemove2), null);
            }
          }
        }
        set(chatWindowAtomFamily(id), _.set(_.clone(chatWindow), action.type, action.value));
      }
    },
});

export const addChatWindowIdsSelector = selector<string | null>({
  key: 'addChatWindowIds',
  get: () => null,
  set: ({ get, set }, id) => {
    const chatWindowIds = get(chatWindowIdsSelector);

    if (!(id instanceof DefaultValue) && id) {
      if (chatWindowIds.length === 0) {
        set(chatWindowIdsAtom, [id]);
        return;
      }

      if (_.findIndex(chatWindowIds, (w) => _.isEqual(w, id)) === -1) {
        const { width: windowWidth } = get(windowSizeAtom);
        const chatWidth = get(chatWidthSelector);
        const windowToRemove = _.find(chatWindowIds, (w) => w !== CHAT_BOT && !_.isEqual(w, id));
        const { isVisible } = get(defaultChatWidthsSelector);

        if (windowToRemove && windowWidth && chatWidth && windowWidth - chatWidth < isVisible) {
          set(removeChatWindowSelector(windowToRemove), null);
          set(chatWindowIdsAtom, [id, ..._.filter(chatWindowIds, (w) => !_.isEqual(w, windowToRemove))]);
        } else {
          set(chatWindowIdsAtom, [id, ...chatWindowIds]);
        }
      }
    }
  },
});
