import { AppNavigateFunction, AppNavigateOptions, To, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { useValidateNavigation } from 'hooks/useBlockAppNavigation/useBlockAppNavigation';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';

export const useAppNavigate = (): AppNavigateFunction => {
  const navigate = useNavigate();
  const validateNavigation = useValidateNavigation();

  const navigateRef = useCallbackRef(navigate);
  const validateNavigationRef = useCallbackRef(validateNavigation);

  const appNavigate = useCallback(
    (to: To | number, options?: AppNavigateOptions) => {
      const isValidNavigation = validateNavigationRef.current(to, options);

      if (!isValidNavigation) return;

      navigateRef.current(to, options);
    },
    [navigateRef, validateNavigationRef],
  );

  return appNavigate;
};
