import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { Webhook } from 'api/actions/webhook/webhooksActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { ListNames } from 'components/StickyList/types';
import { ManageModal, ManageModalProps } from 'components/recipes/ManageModal';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { PATH_REL, TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { languageSelector } from 'state/recoilState';

import { AddWebhookModal } from './components/modals/AddWebhookModal';
import { DeleteWebhooksModal } from './components/modals/DeleteWebhooksModal';
import { EditWebhookModal } from './components/modals/EditWebhookModal';
import { useWebhooks } from './hooks/useWebhooks';
import { webhookTypeTranslationId } from './webhookTypeTranslationId';

export const WebhookModal = (): React.ReactElement => {
  useLingui();
  const navigate = useAppNavigate();
  const language = useRecoilValue(languageSelector);
  const { fetchWebhooks, webhooksMap } = useWebhooks();

  useEffect(() => {
    void fetchWebhooks();
  }, [fetchWebhooks]);

  const listPropsGenerator: ManageModalProps['listPropsGenerator'] = useCallback(
    (replace) => ({
      name: ListNames.SETTINGS_WEBHOOK,
      emptyListMessage: !webhooksMap && t({ id: 'global.loading' }),
      select: 'checkbox',
      showHeader: true,
      list: webhooksMap || new Map(),
      columns: [
        {
          key: 'type',
          title: 'Type',
          sortableValue: (type: Webhook['type']) => `${webhookTypeTranslationId[type]}`,
          customCellRenderer: (type: Webhook['type']) => (
            <TextEllipsis title={t({ id: webhookTypeTranslationId[type] })}>
              {t({ id: webhookTypeTranslationId[type] })}
            </TextEllipsis>
          ),
          columnGrow: 2,
        },
        {
          key: 'id',
          width: '40px',
          sx: { overflow: 'visible' },
          customCellRenderer: (id: Webhook['id']) => (
            <Flex
              sx={{ '&:hover': { cursor: 'pointer' } }}
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                navigate(TO_REL.DELETE_WEBHOOK_MODAL[language], { state: { ids: [id] } });
              }}
            >
              <Icon type="delete" />
            </Flex>
          ),
        },
      ],
      onRowClick: (listItemId) => navigate(`${TO_REL.EDIT_WEBHOOK_MODAL[language]}/${listItemId}`, { replace }),
    }),
    [webhooksMap, navigate, language],
  );

  const modalLinksPaths = useMemo(
    () => ({
      add: TO_REL.ADD_WEBHOOK_MODAL[language],
      delete: TO_REL.DELETE_WEBHOOK_MODAL[language],
    }),
    [language],
  );

  return (
    <>
      <ManageModal
        allResources={webhooksMap}
        title={t({ id: 'settings.integrations.webhooks_heading' })}
        listPropsGenerator={listPropsGenerator}
        modalLinksPaths={modalLinksPaths}
      />
      <Routes>
        <Route
          path={PATH_REL.ADD_WEBHOOK_MODAL[language]}
          element={
            <Modal size="sm">
              <AddWebhookModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.EDIT_WEBHOOK_MODAL[language]}
          element={
            <Modal size="sm" path={PATH_REL.EDIT_WEBHOOK_MODAL[language]}>
              <EditWebhookModal />
            </Modal>
          }
        />
        <Route
          path={PATH_REL.DELETE_WEBHOOK_MODAL[language]}
          element={
            <Modal size="xs">
              <DeleteWebhooksModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
