import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTimer } from 'use-timer';

import { stopwatchTimeFormat } from 'utils/stopwatchTimeFormat';

const TIMER_OFFSET_TOLERANCE_SECONDS = 5;

type Props = {
  initialTime: number;
  autostart?: boolean;
  active: boolean;
};

const defaultProps: Partial<Props> = {
  autostart: true,
};

export const Timer = ({ initialTime, autostart, active }: Props): React.ReactElement => {
  const timerStartUnixRef = useRef(autostart ? dayjs().unix() : null);
  const initialTimeRef = useRef(initialTime);

  const [timeToAdd, setTimeToAdd] = useState<number>(0);

  const onTimeUpdate = useCallback((currentTime: number) => {
    if (document.hidden) {
      return;
    }

    const timerStartUnix = timerStartUnixRef.current;

    if (!timerStartUnix) {
      return;
    }

    const nowUnix = dayjs().unix();
    const timePassed = nowUnix - timerStartUnix;
    const timerProgress = currentTime - initialTimeRef.current;

    const difference = timePassed - timerProgress;

    if (difference > TIMER_OFFSET_TOLERANCE_SECONDS) {
      setTimeToAdd(difference);
    }
  }, []);

  const { time, start, pause, advanceTime } = useTimer({
    autostart,
    interval: 1000,
    initialTime,
    onTimeUpdate,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timeToUpdate = useMemo(() => initialTime - time, [initialTime]);

  useEffect(() => {
    if (active) {
      timerStartUnixRef.current = dayjs().unix();
      start();
    }
    if (!active) {
      timerStartUnixRef.current = null;
      pause();
    }
  }, [active, pause, start]);

  useEffect(() => {
    if (timeToUpdate) advanceTime(timeToUpdate);
  }, [advanceTime, timeToUpdate]);

  useEffect(() => {
    if (timeToAdd) {
      advanceTime(timeToAdd);
      setTimeToAdd(0);
    }
  }, [timeToAdd, advanceTime]);

  return <>{stopwatchTimeFormat(time)}</>;
};
Timer.defaultProps = defaultProps;
