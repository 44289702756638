import React, { useEffect, useRef, useState } from 'react';
import { Text } from 'theme-ui';

import { TimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { ConditionalWrapper } from 'components/utils/ConditionalWrapper';

type Props = {
  note: TimeEvent['note'];
};

const TextWithTooltip = withTooltip(Text);

export const Note = ({ note }: Props): React.ReactElement => {
  const [hasTooltip, setHasTooltip] = useState(false);

  const noteRef = useRef<HTMLDivElement | null>(null);

  // show tooltip if the note has been partially hidden by CSS props
  useEffect(() => {
    if (noteRef?.current) setHasTooltip(noteRef?.current?.scrollHeight > noteRef?.current?.clientHeight);
  }, []);

  return (
    <ConditionalWrapper
      condition={hasTooltip}
      wrapper={({ children }) => (
        <TextWithTooltip tooltipProps={{ content: note }} sx={{ maxWidth: '50%' }}>
          {children}
        </TextWithTooltip>
      )}
      {...(!hasTooltip && { sx: { maxWidth: '50%' } })}
    >
      <Text
        ref={noteRef}
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          wordBreak: 'break-all',
        }}
      >
        {note}
      </Text>
    </ConditionalWrapper>
  );
};
