// region will depend on browser settings
export const getNavigatorRegion = () => {
  const navigatorLanguages = navigator.languages;
  const navigatorLanguageRegionPair = navigatorLanguages.find((l, index) => l.length > 2 && index < 2);
  const navigatorRegion = navigatorLanguageRegionPair
    ? navigatorLanguageRegionPair.slice(-2).toLocaleLowerCase()
    : null;
  return navigatorRegion;
};

export const getIntlRegion = () => {
  const intlLocale = Intl.DateTimeFormat().resolvedOptions().locale;
  const intlRegion = intlLocale && intlLocale.length > 2 ? intlLocale.slice(-2).toLocaleLowerCase() : null;
  return intlRegion;
};

export const getBrowserRegion = () => getNavigatorRegion() || getIntlRegion();
