import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { DefaultRole } from 'api/actions/organizationSession/organizationSessionActions.types';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { MinimizedModalsProvider } from 'components/Modal/components/MinimizedModalsProvider';
import { CLOCK_LOG_TYPE, TO } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { ViewWithMinimizedModals } from 'state/modal';
import { languageSelector } from 'state/recoilState';
import { userDetailsSelector } from 'state/userSession';

import { ModalRoutes } from './ModalRoutes';

const LazyClockLog = React.lazy(() =>
  import('./components/ClockLog').then(({ ClockLog }) => ({
    default: ClockLog,
  })),
);

export const ClockLogRenderer = () => {
  const language = useRecoilValue(languageSelector);

  const {
    modules,
    modulesManagement,
    isInitialized,
    isHeadAdmin,
    timeTrackingSettings,
    timeTrackingSettings: { isFaceRecognitionRequired, isBasicFraudDetectionRequired },
    features: { PhotoLog },
  } = useAppPermissions();
  const userDetails = useRecoilValue(userDetailsSelector);
  const { type: clockLogType } = useParams();
  const { isMobileBreakpoint } = useThemeBreakpoint();

  const redirectRoute = useMemo(
    () =>
      (() => {
        switch (clockLogType) {
          case CLOCK_LOG_TYPE.ALL[language]:
            return !modules.TimeTracking && isInitialized ? TO.START[language] : undefined;
          case CLOCK_LOG_TYPE.PHOTO_LOG[language]:
            return (isMobileBreakpoint || !PhotoLog) && isInitialized ? TO.CLOCK_LOG__ALL[language] : undefined;
          case CLOCK_LOG_TYPE.MODIFIED[language]:
            return undefined;
          case CLOCK_LOG_TYPE.PENDING[language]:
            return !modulesManagement.Requests && !modules.Requests && isInitialized
              ? TO.CLOCK_LOG__ALL[language]
              : undefined;
          case CLOCK_LOG_TYPE.AI_ANTI_SPOOFING[language]:
          case CLOCK_LOG_TYPE.AI_REJECTED[language]:
            return timeTrackingSettings && !isFaceRecognitionRequired && !isBasicFraudDetectionRequired && isInitialized
              ? TO.CLOCK_LOG__ALL[language]
              : undefined;
          case CLOCK_LOG_TYPE.DELETED[language]:
            return !(isHeadAdmin || userDetails?.role.defaultRole === DefaultRole.Admin) && isInitialized
              ? TO.CLOCK_LOG__ALL[language]
              : undefined;
          default:
            return TO.START[language];
        }
      })(),
    [
      clockLogType,
      language,
      modules.TimeTracking,
      modules.Requests,
      isInitialized,
      isMobileBreakpoint,
      PhotoLog,
      modulesManagement.Requests,
      timeTrackingSettings,
      isFaceRecognitionRequired,
      isBasicFraudDetectionRequired,
      isHeadAdmin,
      userDetails?.role.defaultRole,
    ],
  );

  if (redirectRoute) return <Navigate to={redirectRoute} />;

  return (
    <>
      <React.Suspense fallback={<LoadingOverlay sx={{ zIndex: 'base' }} />}>
        <LazyClockLog />
      </React.Suspense>

      <MinimizedModalsProvider forView={ViewWithMinimizedModals.CLOCK_LOG} />
      <ModalRoutes />
    </>
  );
};
