/** @jsxImportSource theme-ui */

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { setTimeOffLimitsAction } from 'api/actions/employees/employeesActions';
import { SetTimeOffLimitsActionProps } from 'api/actions/employees/employeesActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { Checkbox } from 'components/ui/Checkbox';
import { useLocationOnce } from 'hooks/useLocationOnce/useLocationOnce';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { useRefreshCalendar } from 'pages/Calendar/output/useRefreshCalendar';
import { defaultTimeOffLimitGroupSelector, EmployeeRequestLimitsFieldNames } from 'state/team';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { TimeOffLimitsGroupFieldArray } from '../../../forms/formsElements/TimeOffLimitsGroupFieldArray/TimeOffLimitsGroupFieldArray';
import { TimeOffLimitsBasicFormState } from '../../../forms/formsElements/types';
import { timeOffLimitsTypeEnforcer } from '../../../forms/utils/fieldArrayTypeEnforcers';

const forceCorrectTypes = ({
  timeOffLimits,
  overwrite,
}: TimeOffLimitsBasicFormState): Omit<SetTimeOffLimitsActionProps, 'employeesIds'> => ({
  timeOffLimits: timeOffLimitsTypeEnforcer(timeOffLimits) || [],
  overwrite,
});

export const SetTimeOffLimits: FC = () => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();

  const [loading, setLoading] = useState(false);
  const { state } = useLocationOnce();
  const { ids } = state || {};

  const { calendarInitialized, updateCalendarForIds } = useRefreshCalendar(ids);
  const formRef = useRef<HTMLFormElement | null>(null);
  const timeOffDefaultLimitsGroup = useRecoilValue(defaultTimeOffLimitGroupSelector);

  const defaultValues: TimeOffLimitsBasicFormState = useMemo(
    () => ({
      [EmployeeRequestLimitsFieldNames.TimeOffLimits]: timeOffDefaultLimitsGroup ? [timeOffDefaultLimitsGroup] : [],
      overwrite: false,
    }),
    [timeOffDefaultLimitsGroup],
  );

  const { control, register, handleSubmit, trigger, reset } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const initializedRef = useRef(!!defaultValues.timeOffLimits.length);

  useEffect(() => {
    // failsafe for when user refeshes the page and timeOffDefaultLimitsGroup is not yet loaded
    if (defaultValues.timeOffLimits.length && !initializedRef.current) {
      initializedRef.current = true;
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const { mutate } = useMutation(setTimeOffLimitsAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: TimeOffLimitsBasicFormState): Promise<boolean> => {
      if (!ids || !ids.length) return false;
      const { error: submitError } = await mutate({
        employeesIds: ids || [],
        ...forceCorrectTypes(body),
      });
      if (!submitError) {
        if (calendarInitialized) {
          void updateCalendarForIds();
        }
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.set_time_off_bulk.set', message: 'Successfully set!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, ids, addSnackbar, calendarInitialized, updateCalendarForIds],
  );
  const onSubmitError = () => {
    setLoading(false);
  };

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.BackButton />
        <Modal.Title>
          <Trans id="team.set_time_off_bulk">Set time off limits</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          sx={{ width: '100%', maxWidth: '100%', minWidth: '430px' }}
          ref={formRef}
          onSubmit={floatingPromiseReturn(handleSubmit(onSubmit, onSubmitError))}
          noValidate
        >
          <TimeOffLimitsGroupFieldArray register={register} control={control} trigger={trigger} />
          <br />
          <Checkbox
            labelWrapperSx={{ ml: -2 }}
            label={t({ id: 'team.set_time_off_bulk.overwrite', message: 'Overwrite existing time off limits' })}
            size="sm"
            {...register('overwrite')}
          />
        </form>
      </Modal.Body>
      <BasicModalFooter
        buttons={[
          {
            isLoading: loading,
            onClick: handleSave,
            variant: 'primary',
            children: t({ id: 'team.set_time_off_bulk.next', message: 'Save' }),
          },
        ]}
      />
    </>
  );
};
