import { keyframes } from '@emotion/react';
import { Modifier, Placement, PositioningStrategy, StrictModifiers } from '@popperjs/core';
import Bowser from 'bowser';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useRecoilValue } from 'recoil';
import { Box, Flex, FlexProps, Text, ThemeUIStyleObject } from 'theme-ui';

import { useEvent } from 'hooks/useEvent/useEvent';
import { useMount } from 'hooks/useMount/useMount';
import { useOnKeyboardEventQueue } from 'hooks/useOnKeyboardEventQueue/useOnKeyboardEventQueue';
import { useOnOutsideClick } from 'hooks/useOnOutsideClick/useOnOutsideClick';
import { useOnOutsideKeyDown } from 'hooks/useOnOutsideKeyDown/useOnOutsideKeyDown';
import { windowSizeAtom } from 'state/recoilState';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { mergeRefs } from 'utils/mergeRefs';

import { maxSizeModifier } from './modifiers';
import { usePopperAsContextMenu } from './usePopperAsContextMenu';

let popperRoot = document.getElementById('popper-root');

export const decorationSx: ThemeUIStyleObject = {
  textDecorationStyle: 'dotted',
  textDecorationLine: 'underline',
  textDecorationColor: 'tooltip.textDecoration',
};

type CustomModifier = Modifier<'custom', { customOption: boolean }>;
type ExtendedModifiers = (StrictModifiers | Partial<CustomModifier>)[];

type Props = {
  children: React.ReactElement[] | React.ReactElement | string;
  content: React.ReactNode;
  popperContainerProps?: FlexProps;
  placement?: Placement;
  trigger?: 'hover' | 'click' | 'manual';
  popperMargin?: number;
  delayShow?: number;
  withArrow?: boolean;
  arrowSx?: ThemeUIStyleObject;
  popperContainerSx?: ThemeUIStyleObject;
  withPopperState?: boolean;
  positionStrategy?: PositioningStrategy;
  visible?: boolean;
  withMobileInit?: boolean;
  hideAfterPopperClick?: boolean;
  hideOnReferenceHidden?: boolean;
  withAnimation?: boolean;
  widthLikeReferenceElement?: boolean;
  customModifiers?: ExtendedModifiers;
  withPortal?: boolean;
  withMaxSizeModifier?: boolean;
  contextMenuId?: string;
  onClickElement?: () => void;
  onClickPopper?: () => void;
  onOutsideClick?: () => void;
};

export type PopperState = {
  isVisible: boolean;
  setIsVisible: () => void;
};

export type PopperProviderProps = Props;

const defaultProps: Partial<Props> = {
  trigger: 'hover',
  placement: 'auto',
  popperMargin: 0.5,
  delayShow: 750,
  withArrow: false,
  arrowSx: undefined,
  withPopperState: undefined,
  popperContainerSx: undefined,
  positionStrategy: 'fixed',
  onClickElement: undefined,
  onClickPopper: undefined,
  visible: false,
  withMobileInit: true,
  onOutsideClick: undefined,
  customModifiers: undefined,
  hideAfterPopperClick: false,
  hideOnReferenceHidden: true,
  widthLikeReferenceElement: false,
  withAnimation: false,
  withPortal: false,
  withMaxSizeModifier: false,
  contextMenuId: undefined,
  popperContainerProps: undefined,
};

export const PopperProvider = ({
  placement,
  trigger,
  popperMargin,
  delayShow,
  children,
  withArrow,
  content,
  arrowSx,
  withPopperState,
  positionStrategy,
  popperContainerSx,
  visible,
  withMobileInit,
  customModifiers,
  hideAfterPopperClick,
  hideOnReferenceHidden,
  withAnimation,
  widthLikeReferenceElement,
  withPortal,
  withMaxSizeModifier,
  contextMenuId,
  popperContainerProps,
  onClickElement,
  onClickPopper,
  onOutsideClick,
}: Props): React.ReactElement => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const popperContainerRef = useRef<HTMLDivElement | null>(null);
  const referenceElementRef = useRef<HTMLDivElement | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const shouldUseOnOutsideClick = useRef<boolean>(false);

  const { isMobile } = useRecoilValue(windowSizeAtom);

  const contextMenu = usePopperAsContextMenu({
    contextMenuId,
    setIsVisible,
    referenceElement,
    popperElement,
    isVisible,
  });

  useEffect(() => {
    referenceElementRef?.current?.setAttribute('data-popper-visible', `${isVisible}`);
  }, [isVisible]);

  const { styles, attributes, state } = usePopper(
    !contextMenu?.isReferenceVirtual ? referenceElement : contextMenu?.virtualReference,
    popperElement,
    {
      placement: contextMenu?.isReferenceVirtual ? 'bottom-start' : placement,
      strategy: positionStrategy,
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowElement,
            padding: -4,
          },
        },
        {
          name: 'offset',
          options: {
            offset: contextMenu?.contextMenuOffset || [0, 6 + (popperMargin ? popperMargin * 16 : 0)],
          },
        },
        ...(withMaxSizeModifier ? [maxSizeModifier] : []),
        ...(customModifiers || []),
      ],
    },
  );

  if (!popperRoot) {
    popperRoot = document.createElement('div');
    popperRoot.setAttribute('id', 'popper-root');
  }

  const rotateArrow = useCallback((): string => {
    switch (state?.placement) {
      case 'right':
      case 'left':
      case 'right-start':
        return 'rotate(-45deg)';
      case 'top':
      case 'bottom':
        return 'rotate(45deg)';
      case 'bottom-start':
      case 'top-end':
        return 'rotate(75deg)';
      default:
        return '';
    }
  }, [state?.placement]);

  const showPopperWithTimeout = useCallback(() => {
    timeout.current = setTimeout(() => {
      setIsVisible(true);
    }, delayShow);
  }, [timeout, delayShow]);

  const hidePopper = useCallback(() => {
    setIsVisible(false);
    if (timeout.current) clearTimeout(timeout.current);
  }, [timeout]);

  useOnOutsideClick(
    popperContainerRef,
    floatingPromiseReturn(async () => {
      shouldUseOnOutsideClick.current = true;
      await delay(0);
      shouldUseOnOutsideClick.current = false;
    }),
  );

  useOnOutsideClick(referenceElementRef, () => {
    if (shouldUseOnOutsideClick.current) {
      if (
        (isVisible && trigger === 'click') ||
        isMobile ||
        (contextMenu?.isReferenceVirtual && isVisible && trigger === 'manual')
      )
        hidePopper();
      if (onOutsideClick) onOutsideClick();
    }

    shouldUseOnOutsideClick.current = false;
  });

  useOnOutsideKeyDown(popperContainerRef, 'Tab', () => {
    if (isVisible) {
      if (onOutsideClick) {
        onOutsideClick();
      } else {
        hidePopper();
      }
    }
  });

  const onClick: NonNullable<FlexProps['onClick']> = useCallback(
    (e) => {
      e.stopPropagation();
      if (hideAfterPopperClick) hidePopper();
      if (onClickPopper) onClickPopper();
    },
    [hideAfterPopperClick, hidePopper, onClickPopper],
  );

  useOnKeyboardEventQueue(
    'Escape',
    () => {
      if (isVisible && trigger === 'click') hidePopper();
      if (onOutsideClick && visible && trigger === 'manual') onOutsideClick();
    },
    (isVisible && trigger === 'click') || (visible && trigger === 'manual'),
  );

  const onReferenceElementClick = useCallback(() => {
    if (trigger === 'click') setIsVisible(!isVisible);
    if (onClickElement) onClickElement();
    if (contextMenu?.onReferenceElementClick) contextMenu.onReferenceElementClick();
  }, [trigger, isVisible, onClickElement, contextMenu]);

  // simulate click on mount to avoid the need for second click (IOS safari bug)
  useMount(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName();
    if (trigger !== 'click' || !isMobile || browserName !== 'Safari' || !withMobileInit || isVisible) return;
    onReferenceElementClick();
  });

  useEvent('click', onReferenceElementClick, trigger !== 'hover' || isMobile ? referenceElement : null);

  useEvent(
    'mouseover',
    () => !isVisible && showPopperWithTimeout(),
    trigger === 'hover' && !isMobile ? referenceElement : null,
  );
  useEvent('mouseout', () => hidePopper(), trigger === 'hover' && !isMobile ? referenceElement : null);

  useEffect(() => {
    if (trigger === 'manual' && typeof visible === 'boolean') setIsVisible(visible);
  }, [trigger, visible]);

  const inheritedWidth = useMemo(() => {
    if (!widthLikeReferenceElement) return null;

    return state?.rects?.reference?.width || referenceElement?.getBoundingClientRect().width || null;
  }, [state?.rects?.reference?.width, referenceElement, widthLikeReferenceElement]);

  const renderPopperContainer = useCallback(
    () => (
      <Flex
        {...popperContainerProps}
        variant="popper.container"
        onClick={onClick}
        ref={mergeRefs([setPopperElement, popperContainerRef])}
        {...attributes.popper}
        style={styles.popper}
        sx={{
          ...(hideOnReferenceHidden && {
            '&[data-popper-reference-hidden="true"]': {
              visibility: 'hidden',
              pointerEvents: 'none',
            },
          }),
          ...(withAnimation && {
            opacity: 0,
            animation: `${keyframes({
              to: {
                opacity: 0.9,
              },
            })} .5s forwards`,
          }),
          ...(popperContainerSx && popperContainerSx),
          ...(inheritedWidth && { width: `${inheritedWidth}px` }),
          // wait till popper gets access to the reference element position
          ...(!state?.rects?.reference && { visibility: 'hidden' }),
        }}
      >
        {content}
        {withArrow && (
          <Box
            variant="popper.arrow"
            className="arrow"
            ref={setArrowElement}
            sx={{
              ...(arrowSx && arrowSx),
              ...styles.arrow,
              transform: `${styles.arrow.transform} ${rotateArrow()} skew(15deg, 15deg)`,
            }}
          />
        )}
      </Flex>
    ),
    [
      popperContainerProps,
      onClick,
      attributes.popper,
      styles.popper,
      styles.arrow,
      hideOnReferenceHidden,
      withAnimation,
      popperContainerSx,
      inheritedWidth,
      state?.rects?.reference,
      content,
      withArrow,
      arrowSx,
      rotateArrow,
    ],
  );
  return (
    <>
      {isVisible && withPortal && ReactDOM.createPortal(renderPopperContainer(), popperRoot)}

      {isVisible && !withPortal && renderPopperContainer()}

      {React.Children.map(typeof children === 'string' ? <Text>{children}</Text> : children, (child) =>
        React.cloneElement(child, {
          ref: mergeRefs([setReferenceElement, referenceElementRef]),
          ...(withPopperState && { popperState: { isVisible, setIsVisible } }),
        }),
      )}
    </>
  );
};

PopperProvider.defaultProps = defaultProps;
