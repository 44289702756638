import { useCallback, useEffect } from 'react';
import { QueryResponse, useClient } from 'react-fetching-library';
import { useRecoilState, useRecoilValue } from 'recoil';

import { fetchCountryDetailsListAction } from 'api/actions/data/dataActions';
import { FetchCountryDetailsListResponse } from 'api/actions/data/dataActions.types';
import { ErrorResponse } from 'api/types';
import { SupportedLanguages } from 'constants/supportedLanguages';
import { useMount } from 'hooks/useMount/useMount';
import { languageSelector } from 'state/recoilState';
import { countryDetailsListResponseAtom } from 'state/signUp';

let fetchPromise: Promise<
  QueryResponse<ErrorResponse<Record<string, unknown>, undefined> & FetchCountryDetailsListResponse>
> | null = null;

let fetchedLocale: keyof typeof SupportedLanguages | null = null;

export const useFetchCountryDetailsManager = (initialize: boolean = false) => {
  const language = useRecoilValue(languageSelector);
  const [fetchCountryDetailsListResponse, setFetchCountryDetailsListResponse] =
    useRecoilState(countryDetailsListResponseAtom);

  const { query } = useClient();

  const fetchCountryDetails = useCallback(async () => {
    if (fetchPromise) return fetchPromise;

    fetchPromise = query(fetchCountryDetailsListAction());
    fetchedLocale = language;
    const response = await fetchPromise;
    const { payload, error } = response;
    fetchPromise = null;
    if (payload && !error) {
      setFetchCountryDetailsListResponse(payload);
    }

    return response;
  }, [query, setFetchCountryDetailsListResponse, language]);

  useMount(() => {
    if (!initialize || fetchCountryDetailsListResponse) return;

    void fetchCountryDetails();
  });

  useEffect(() => {
    if (fetchedLocale !== language && fetchCountryDetailsListResponse) {
      void fetchCountryDetails();
    }
  }, [language, fetchCountryDetails, fetchCountryDetailsListResponse]);

  return {
    fetchCountryDetails,
    fetchCountryDetailsListResponse,
  };
};
