/* eslint-disable @typescript-eslint/no-use-before-define */
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Face } from '@tensorflow-models/face-detection';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ClientContext } from 'react-fetching-library';
import { useMatch } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { CameraViewButton, Clock, Footer, Header, OverlayContainer, Section } from 'Kiosk/Layout';
import { useAnimationFrame } from 'Kiosk/hooks/useAnimationFrame';
import { useCamera } from 'Kiosk/hooks/useCamera';
import { MIN_REQUIRED_SNAPSHOTS_LENGTH, useCameraImageBuffer } from 'Kiosk/hooks/useCameraImageBuffer';
import { useFaceDetection } from 'Kiosk/hooks/useFaceLandmarkModel';
import { useFaceLiveness } from 'Kiosk/hooks/useFaceLiveness';
import { useGeoLocation } from 'Kiosk/hooks/useGeoLocation';
import { UseQrScannerProps, useQrScanner } from 'Kiosk/hooks/useQrScanner';
import { debugModeFacePredictionsAtom, debugModeSelector, debugModeSettingsAtom } from 'Kiosk/state/debugState';
import { nightModeSelector } from 'Kiosk/state/kioskState';
import { KioskOverlay, overlayStateAtom } from 'Kiosk/state/overlayState';
import { settingsAtom } from 'Kiosk/state/settingsState';
import { getInfo } from 'api/actions/timeclock/timeclockActions';
import { LoadingSplash } from 'components/Loading/LoadingSplash';
import { Logo } from 'components/Logo/Logo';
import { ElementGroup } from 'components/ui/ElementGroup';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { TO, TO_REL } from 'constants/routes';
import { usePromiseBuffer } from 'hooks/usePromiseBuffer/usePromiseBuffer';
import { languageSelector } from 'state/recoilState';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { delay } from 'utils/delay';

import { ModalRoutes } from './ModalRoutes';

export const Start = (): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [headMovementRequired, setHeadMovementRequired] = useState(false);

  const snapshotBoundLivelinessMapRef = useRef<Map<string, { hasFace: boolean }>>(new Map());

  const [nightMode, setNightMode] = useRecoilState(nightModeSelector);
  const language = useRecoilValue(languageSelector);
  const settings = useRecoilValue(settingsAtom);
  const setOverlay = useSetRecoilState(overlayStateAtom);

  // __DEBUG MODE
  const debugMode = useRecoilValue(debugModeSelector);
  const debugModeSettings = useRecoilValue(debugModeSettingsAtom);
  const setPrediction = useSetRecoilState(debugModeFacePredictionsAtom);
  // __END DEBUG MODE

  const { location } = useGeoLocation();
  const isKiosk = useMatch(TO.KIOSK[language]);
  const { query } = useContext(ClientContext);

  const { getBlob, getImageData } = useCameraImageBuffer();
  const { isCameraStreaming } = useCamera();
  const { getPrediction } = useFaceDetection();
  const { resetFaceLivelinessCollecting, collectFaceLiveliness, checkLivelinessConditionsMet } = useFaceLiveness(
    debugModeSettings.faceLivelinessMode,
  );
  const { addPromiseToBuffer, cancelAllPromises } = usePromiseBuffer<Face[]>();

  useLingui();

  // 2. onSuccessful scan
  // If Face (if req) and QR present
  const onSuccessfulScan: UseQrScannerProps['onDecode'] = async (qr, imageUrl, startQrScanner, stopQrScanner) => {
    if (qr !== '') {
      const snapshots = getBlob && getBlob();

      if (!snapshots || snapshots.length < MIN_REQUIRED_SNAPSHOTS_LENGTH) return;

      stopQrScanner();

      if (settings && settings.isAdvancedFraudDetectionRequired) {
        const scanningAllowed = checkLivelinessConditionsMet(imageUrl);

        if (!scanningAllowed) {
          startQrScanner();
          return;
        }
      }

      stopAnimation();
      setIsLoading(true);

      const latestSnapshots = (() => {
        const currentSnapshotIndex = snapshots.indexOf(imageUrl);
        const startSnapshotIndex = currentSnapshotIndex - MIN_REQUIRED_SNAPSHOTS_LENGTH;

        if (startSnapshotIndex < 0) return undefined;

        const snapshotsToSend = snapshots.slice(startSnapshotIndex, currentSnapshotIndex);

        if (
          !settings?.isAdvancedFraudDetectionRequired &&
          !settings?.isBasicFraudDetectionRequired &&
          !settings?.isFaceRecognitionRequired
        ) {
          return snapshotsToSend;
        }

        const snapshotsWithFace = snapshotsToSend.filter((url) => {
          const snap = snapshotBoundLivelinessMapRef.current.get(url);

          if (!snap) return false;

          return snap.hasFace;
        });

        if (snapshotsWithFace.length < snapshotsToSend.length) return undefined;

        return snapshotsToSend;
      })();

      if (!latestSnapshots || latestSnapshots.length < MIN_REQUIRED_SNAPSHOTS_LENGTH) {
        startAnimation();
        startQrScanner();
        setIsLoading(false);
        return;
      }

      const filteredSnapshots = _.filter(latestSnapshots.reverse(), (snap, index) => index % 3 === 0);

      const imagesBlob = await Promise.all(
        _.map(filteredSnapshots, async (snap) => {
          const snapFetch = await fetch(snap);
          const snapBlob = await snapFetch.blob();
          return snapBlob;
        }),
      );

      const { payload, error } = await query(
        getInfo(
          { qrCode: qr, images: imagesBlob },
          { innerCodesThatOmmitsSnackbar: [ErrorInnerCodes.MissingFaceModel] },
        ),
      );

      if (payload) {
        if (error) {
          const { innerCode, data } = payload;

          cancelAllPromises();
          resetFaceLivelinessCollecting();
          setIsLoading(false);

          if (innerCode === ErrorInnerCodes.MissingFaceModel && data) {
            if (!settings?.isFaceRecognitionRequired) {
              window.location.href = `${window.location.href}?alert=alert_kiosk_session_refresh`;
              return;
            }

            stopAnimation();
            startQrScanner();
            setOverlay({
              type: KioskOverlay.initialFaceScan,
              state: {
                name: data.personName,
                qrCode: qr,
              },
            });
          }

          if (
            innerCode === ErrorInnerCodes.InvalidFace ||
            innerCode === ErrorInnerCodes.BadQrCode ||
            innerCode === ErrorInnerCodes.ClockInNotAllowed
          ) {
            startAnimation();
            startQrScanner();
          }

          return;
        }

        cancelAllPromises();
        resetFaceLivelinessCollecting();
        setIsLoading(false);
        setOverlay({
          type: KioskOverlay.summary,
          state: {
            getInfoPayload: payload,
            qrCode: qr,
            eventImage: imagesBlob[0],
          },
        });
      }
    }
  };

  const { decode } = useQrScanner({ onDecode: onSuccessfulScan });

  const getPredictionWithBuffer = useCallback(
    async (frame: ImageData) => {
      const bufferPromise = getPrediction && addPromiseToBuffer(getPrediction(frame));

      try {
        const result = await bufferPromise;

        return result;
      } catch {
        return undefined;
      }
    },
    [addPromiseToBuffer, getPrediction],
  );

  const headMovementDelayIsRunning = useRef(false);
  const currentHeadMovementRequirement = useRef(headMovementRequired);

  const headMovementRequiredWithDelay = useCallback(async (requireHeadMovement: boolean) => {
    currentHeadMovementRequirement.current = requireHeadMovement;

    if (headMovementDelayIsRunning.current) {
      return;
    }

    headMovementDelayIsRunning.current = true;
    await delay(1000);
    setHeadMovementRequired(currentHeadMovementRequirement.current);
    headMovementDelayIsRunning.current = false;
  }, []);

  // 1. scan for QR and Face (if req); check if geolocation required
  const scanForQrAndFace = async () => {
    const frame = getImageData && (await getImageData());

    if (frame && decode) {
      const faceDetect = await getPredictionWithBuffer(frame.imageData);

      const facePositionedInCamera = faceDetect && faceDetect.length > 0;

      snapshotBoundLivelinessMapRef.current.set(frame.imageUrl, { hasFace: !!facePositionedInCamera });

      if (frame.removedSnapshot) {
        snapshotBoundLivelinessMapRef.current.delete(frame.removedSnapshot);
      }

      if (settings) {
        const {
          isFaceRecognitionRequired,
          isBasicFraudDetectionRequired,
          isAdvancedFraudDetectionRequired,
          isLocationRequired,
        } = settings;
        const faceRequired = isFaceRecognitionRequired || isBasicFraudDetectionRequired;

        if (isAdvancedFraudDetectionRequired) {
          const face = faceDetect?.length ? faceDetect[0] : null;
          const isLiveliness = collectFaceLiveliness(face, frame.imageUrl);

          if (!isLiveliness && facePositionedInCamera) {
            void headMovementRequiredWithDelay(true);
          }

          if (headMovementRequired) {
            if (isLiveliness) {
              void headMovementRequiredWithDelay(false);
            }
          }

          if (!facePositionedInCamera) {
            void headMovementRequiredWithDelay(false);
          }
        }

        if (
          ((faceRequired && facePositionedInCamera) || !faceRequired) &&
          ((isLocationRequired && location) || !isLocationRequired) &&
          !headMovementRequired
        ) {
          decode(frame);
        }

        if (debugMode) {
          setPrediction(faceDetect || []);
        }
      }
    }
  };

  const { startAnimation, stopAnimation } = useAnimationFrame({
    callback: floatingPromiseReturn(scanForQrAndFace),
  });

  useEffect(() => {
    if (isCameraStreaming && isKiosk) {
      startAnimation();
      return;
    }

    stopAnimation();
  }, [isCameraStreaming, language, isKiosk, startAnimation, stopAnimation]);

  return (
    <>
      {isLoading && (
        <LoadingSplash
          background="kiosk.loading.bg.default"
          color="kiosk.loading.text"
          sx={{
            zIndex: 1,
            '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
              backgroundColor: 'kiosk.loading.bg.noBackdrop',
              backdropFilter: 'blur(14px)',
            },
          }}
        />
      )}

      <OverlayContainer sxInner={{ justifyContent: 'space-between' }} darkenBackground={headMovementRequired}>
        <Clock nightMode={nightMode} />
        {headMovementRequired && (
          <Section>
            <Header.Title sx={{ textAlign: 'center' }}>
              <Trans id="kiosk.liveliness.move_your_head">Move your head slightly left and right</Trans>
            </Header.Title>
          </Section>
        )}

        <Footer
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...(isLoading && { pointerEvents: 'none' }),
          }}
        >
          <ElementGroup showAsList marginValue={2}>
            <CameraViewButton icon="settings" to={TO_REL.KIOSK_SETTINGS_MODAL[language]} />
            <CameraViewButton icon="help" to={`${TO_REL.KIOSK_HELP_CENTER_MODAL[language]}/1`} />
            <CameraViewButton
              icon={location ? 'location' : 'locationOff'}
              to={TO_REL.KIOSK_LOCATION_MODAL[language]}
              warning={!location && !!settings && settings.isLocationRequired}
            />
            <CameraViewButton
              active={nightMode}
              icon={nightMode ? 'nightModeFull' : 'nightMode'}
              onClick={() => {
                setNightMode(!nightMode);
              }}
            />
          </ElementGroup>

          <Logo fill="currentColor" width={[100, 140]} />
        </Footer>
      </OverlayContainer>
      <ModalRoutes />
    </>
  );
};
