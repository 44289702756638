import { Placement } from '@popperjs/core';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useLocation } from 'react-router-dom';
import { Box, Flex } from 'theme-ui';

import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';
import { ProgressBar } from 'components/ui/ProgressBar/ProgressBar';
import { useEvent } from 'hooks/useEvent/useEvent';
import { useOnOutsideClick } from 'hooks/useOnOutsideClick/useOnOutsideClick';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { delay } from 'utils/delay';
import { mergeRefs } from 'utils/mergeRefs';

const onboardingRoot = document.getElementById('onboarding-root');

type Props = {
  placement?: Placement;
  content: React.ReactNode;
  targetElement?: string;
  visible?: boolean;
  focusOnElement?: boolean;
  mode?: 'tooltip' | 'modal';
  showOnUrl?: string;
  urlCompareMode?: 'exact' | 'include';
  onClickElement?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onOutsideClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCloseClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  steps: number;
  completedSteps: number;
};

export type OnboardingPopperProviderProps = Props;

export const OnboardingPopperProvider = ({
  placement = 'auto',
  content,
  visible = false,
  onClickElement,
  onOutsideClick,
  onCloseClick,
  targetElement,
  focusOnElement = true,
  mode = 'tooltip',
  completedSteps,
  steps,
  showOnUrl,
  urlCompareMode = 'exact',
}: Props): React.ReactElement => {
  const popperContainerRef = useRef<HTMLDivElement | null>(null);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const referenceElementRef = useRef<HTMLElement | null>(null);

  const { pathname } = useLocation();
  const lastPathname = usePrevious(pathname);

  const isPathnameCorrect = useMemo(() => {
    if (urlCompareMode === 'exact') {
      return pathname === showOnUrl;
    }

    return _.includes(pathname, showOnUrl);
  }, [pathname, showOnUrl, urlCompareMode]);

  const getNodeReferenceElement = useCallback(async () => {
    await delay(300);

    let node = null;

    if (targetElement) {
      // eslint-disable-next-line prefer-destructuring
      node = document.getElementsByClassName(targetElement)[0];
    }

    setIsVisible(true);
    setReferenceElement(node as HTMLElement);
    referenceElementRef.current = node as HTMLElement;
  }, [targetElement]);

  useEffect(() => {
    if (targetElement && onboardingRoot && pathname !== lastPathname) {
      setIsVisible(false);
      void getNodeReferenceElement();
    }
  }, [getNodeReferenceElement, lastPathname, pathname, targetElement]);

  useEffect(() => {
    if (
      (targetElement && onboardingRoot && !showOnUrl) ||
      (targetElement && onboardingRoot && showOnUrl && isPathnameCorrect)
    ) {
      setIsVisible(false);
      void getNodeReferenceElement();
    }
  }, [getNodeReferenceElement, isPathnameCorrect, pathname, showOnUrl, targetElement]);

  useEffect(() => {
    if (typeof visible === 'boolean') setIsVisible(visible);
  }, [visible]);

  const { styles, attributes, state } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
          padding: -4,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
    ],
  });

  const internalOnReferenceClick = useMemo(
    () =>
      _.debounce<NonNullable<Props['onClickElement']>>(
        (e) => {
          if (mode === 'tooltip' && state?.rects?.reference && onClickElement) {
            onClickElement(e);
          }
        },
        300,
        {
          leading: true,
          trailing: false,
        },
      ),
    [mode, onClickElement, state?.rects?.reference],
  );

  const internalOnOutsideClick = useMemo(
    () =>
      _.debounce<NonNullable<Props['onOutsideClick']>>(
        (e) => {
          if (onOutsideClick) onOutsideClick(e);
        },
        300,
        {
          leading: true,
          trailing: false,
        },
      ),
    [onOutsideClick],
  );

  useOnOutsideClick<NonNullable<Props['onOutsideClick']>>(referenceElementRef, (e) => {
    if (isVisible) {
      internalOnOutsideClick(e);
    }
  });

  useEvent('click', internalOnReferenceClick as unknown as EventListener, referenceElement);

  const rotateArrow = useCallback((): string => {
    switch (state?.placement) {
      case 'right':
      case 'left':
      case 'right-start':
        return 'rotate(-45deg)';
      case 'top':
      case 'top-end':
      case 'top-start':
      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
        return 'rotate(45deg)';
      default:
        return '';
    }
  }, [state?.placement]);

  const renderPopperContainer = useCallback(
    () => (
      <>
        {/* MODAL BACKDROP */}
        {mode === 'modal' && <Flex variant="onboarding.backdrop" />}

        {/* TOOLTIP REFERENCE ELEMENT FOCUS BOUNDARIES */}
        {focusOnElement && mode === 'tooltip' && referenceElement && state?.rects?.reference && (
          <>
            <Flex
              variant="onboarding.focusBackdrop.element"
              style={{
                top: state.rects.reference.y,
                left: state.rects.reference.x,
                width: state.rects.reference.width,
                height: state.rects.reference.height,
              }}
            />
            <Flex
              variant="onboarding.focusBackdrop.blocker"
              className="backdrop-bottom"
              style={{
                top: state.rects.reference.y + state.rects.reference.height,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            />
            <Flex
              variant="onboarding.focusBackdrop.blocker"
              className="backdrop-right"
              style={{
                top: state.rects.reference.y,
                height: state.rects.reference.height,
                left: state.rects.reference.x + state.rects.reference.width,
                right: 0,
              }}
            />
            <Flex
              variant="onboarding.focusBackdrop.blocker"
              className="backdrop-top"
              style={{
                top: 0,
                height: state.rects.reference.y,
                left: 0,
                right: 0,
              }}
            />
            <Flex
              variant="onboarding.focusBackdrop.blocker"
              className="backdrop-left"
              style={{
                top: state.rects.reference.y,
                height: state.rects.reference.height,
                left: 0,
                width: state.rects.reference.x,
              }}
            />
          </>
        )}

        {/* CONTENT */}
        {((mode === 'tooltip' && referenceElement) || mode === 'modal') && (
          <Flex
            variant={`onboarding.container.${mode}`}
            ref={mergeRefs([setPopperElement, popperContainerRef])}
            {...attributes.popper}
            style={{
              ...styles.popper,
              // overwrite styles for modal mode
              ...(mode === 'modal' && {
                inset: '50% auto auto 50%',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }),
            }}
          >
            {!!onCloseClick && (
              <Button
                size="xs"
                shape="rounded"
                variant="minimal"
                className="closeBtn"
                onClick={(e) => onCloseClick(e)}
                sx={{ zIndex: 1 }}
              >
                <Icon type="x"></Icon>
              </Button>
            )}

            <Flex
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                height: '1rem',
                overflow: 'hidden',
                borderRadius: 'sm',
              }}
            >
              <ProgressBar steps={steps - 1} completed={completedSteps} />
            </Flex>

            <Flex as="section">{content}</Flex>

            {/* ARROW */}
            {mode === 'tooltip' && (
              <Box
                variant="onboarding.arrow"
                className="arrow"
                ref={setArrowElement}
                style={{
                  ...styles.arrow,
                  transform: `${styles.arrow.transform} ${rotateArrow()} skew(8deg, 8deg)`,
                }}
              />
            )}
          </Flex>
        )}
      </>
    ),
    [
      mode,
      focusOnElement,
      state?.rects?.reference,
      attributes.popper,
      styles.popper,
      styles.arrow,
      referenceElement,
      steps,
      completedSteps,
      onCloseClick,
      content,
      rotateArrow,
    ],
  );

  return <>{isVisible && onboardingRoot && ReactDOM.createPortal(renderPopperContainer(), onboardingRoot)}</>;
};
