/* eslint-disable no-underscore-dangle */

import { ACCESS_TOKEN_KEY } from 'constants/common';

class AuthStorage {
  private _accessToken: string | null = null;

  constructor() {
    try {
      this.accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    } catch (error) {
      this.accessToken = null;
    }
  }

  get accessToken(): string | null {
    return this._accessToken;
  }

  set accessToken(value: string | null) {
    this._accessToken = value;

    try {
      if (typeof value === 'string') {
        localStorage.setItem(ACCESS_TOKEN_KEY, value);
      } else {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
      }
    } catch (error) {
      // throw some error
    }
  }

  startAccessTokenListener(onAccessTokenChange: (newToken: string | null) => void): () => void {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === ACCESS_TOKEN_KEY) {
        const newToken = event.newValue;
        this.accessToken = newToken;
        onAccessTokenChange(newToken);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }
}

export const authStorage = new AuthStorage();
