import { t } from '@lingui/macro';

import { ExternalLoginProvider, ExternalProvider } from 'api/actions/integrations/integrationActions.types';

export const getExternalIntegrationTileTexts = ({ provider }: ExternalProvider) => {
  switch (provider) {
    case ExternalLoginProvider.AppleLinkInewi:
    case ExternalLoginProvider.AppleLinkTrackTime:
    case ExternalLoginProvider.AppleLoginInewi:
    case ExternalLoginProvider.AppleLoginTrackTime: {
      return {
        heading: t({ id: 'settings.integrations.apple_heading', message: 'Apple' }),
        description: t({
          id: 'settings.integrations.apple',
          message: 'Use your Apple ID to quickly sign into our platform.',
        }),
      };
    }
    case ExternalLoginProvider.AtlassianInewi:
    case ExternalLoginProvider.AtlassianTrackTime: {
      return {
        heading: t({
          id: 'settings.integrations.atlassian_heading',
          message: 'Atlassian',
        }),
        description: t({
          id: 'settings.integrations.atlassian',
          message: 'Use your Atlassian Account to quickly sign into our platform.',
        }),
      };
    }
    case ExternalLoginProvider.Google:
    case ExternalLoginProvider.GoogleInewi:
    case ExternalLoginProvider.GoogleTrackTime:
      return {
        heading: t({ id: 'settings.integrations.google_heading', message: 'Google' }),
        description: t({
          id: 'settings.integrations.google',
          message: 'Use your Google Account to quickly sign into our platform.',
        }),
      };
    case ExternalLoginProvider.SlackLoginInewi:
    case ExternalLoginProvider.SlackLoginTrackTime:
      return {
        heading: t({ id: 'settings.integrations.slack_heading', message: 'Slack' }),
        description: t({
          id: 'settings.integrations.slack',
          message: 'Use your Slack Account to quickly sign into our platform.',
        }),
      };
    default: {
      return null;
    }
  }
};
