import { t, Trans } from '@lingui/macro';
import React, { useEffect, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, Spinner, Text } from 'theme-ui';

import { ExtendedTimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { Map } from 'components/Map/Map';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { EventDetails } from 'pages/ClockLog/output/EventDetails';
import { extTimeEventsSelectorFamily, ExtTimeEventsSelectorListType } from 'state/clockLog';
import { ReportsExtendedTimeEvent, reportsExtTimeEventsSelector } from 'state/reports';
import { useLocations } from '../../hooks/useLocations';

type Props = {
  eventListType?: ExtTimeEventsSelectorListType;
};

const capitalizeFirstLetter = (text: string): string => `${text[0].toUpperCase()}${text.substring(1)}`;

export const ShowLocationModal = ({ eventListType }: Props): React.ReactElement => {
  const { baseRoute } = useModal({ wrapperSx: { minHeight: '400px' } });
  const { id } = useParams() as { id: string };

  const { locations, fetchLocations } = useLocations();

  useEffect(() => {
    if (!locations) void fetchLocations();
  }, [locations, fetchLocations]);

  const extTimeEvents = useRecoilValue(extTimeEventsSelectorFamily(eventListType || 'all'));
  const reportsExtTimeEvents = useRecoilValue(reportsExtTimeEventsSelector);

  const extTimeEvent = useMemo(() => {
    let eTE: ExtendedTimeEvent | ReportsExtendedTimeEvent | undefined = extTimeEvents?.get(id);
    if (!eTE) eTE = reportsExtTimeEvents?.get(id);
    return eTE;
  }, [extTimeEvents, id, reportsExtTimeEvents]);

  const location = useMemo(
    () =>
      locations?.find(
        (l) => id === l.id || (extTimeEvent && 'location' in extTimeEvent && l.id === extTimeEvent?.location?.id),
      ),
    [locations, extTimeEvent, id],
  );

  const lat = useMemo(
    () => (extTimeEvent && 'coordinates' in extTimeEvent && extTimeEvent?.coordinates?.latitude) || location?.latitude,
    [location?.latitude, extTimeEvent],
  );
  const lng = useMemo(
    () =>
      (extTimeEvent && 'coordinates' in extTimeEvent && extTimeEvent?.coordinates?.longitude) || location?.longitude,
    [location?.longitude, extTimeEvent],
  );
  const range = useMemo(
    () =>
      extTimeEvent && 'coordinates' in extTimeEvent && !extTimeEvent?.coordinates?.latitude ? location?.range : null,
    [location?.range, extTimeEvent],
  );

  const address = useMemo(() => {
    if (!location?.address) return undefined;
    const { street, city } = location.address;
    return `${capitalizeFirstLetter(street)}, ${capitalizeFirstLetter(city)}`;
  }, [location]);

  if (
    !id &&
    !location &&
    extTimeEvent &&
    'coordinates' in extTimeEvent &&
    !extTimeEvent?.coordinates &&
    extTimeEvent &&
    'location' in extTimeEvent &&
    !extTimeEvent.location
  )
    return <Navigate to={baseRoute} relative="path" />;

  return (
    <>
      <Modal.Header>
        <Modal.Title>{location?.name || t({ id: 'clock_log.map' })}</Modal.Title>
      </Modal.Header>
      <Modal.Body sx={{ padding: '0 24px 24px' }}>
        {extTimeEvent && <EventDetails extTimeEvent={extTimeEvent} sx={{ mb: '1.5rem' }} />}
        <Flex
          sx={{
            flexDirection: 'column',
            wordBreak: 'break-all',
            bg: 'location.bg',
            borderRadius: 'sm',
            border: '1px solid',
            borderColor: 'location.border',
            justifyContent: 'center',
          }}
        >
          {location?.address && <Text sx={{ mt: 2, mx: 3, fontWeight: 'bold' }}>{address}</Text>}
          <Text sx={{ mt: 1, mb: 2, mx: 3 }}>
            {lat && lng && (
              <>
                <Trans id="locations.coordinates">Coordinates</Trans> - {lat}, {lng}
              </>
            )}
          </Text>
          <Flex
            sx={{
              height: 316,
              width: '100%',
              borderRadius: 'default',
              overflow: 'hidden',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {lat && lng ? (
              <Map center={{ lat, lng }}>
                <Map.Marker position={{ lat, lng }} />
                {range && <Map.Circle center={{ lat, lng }} radius={range} />}
              </Map>
            ) : (
              <Spinner />
            )}
          </Flex>
        </Flex>
      </Modal.Body>
    </>
  );
};
