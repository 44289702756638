import React from 'react';
import { BoxOwnProps, BoxProps, Flex } from 'theme-ui';
import { motion, MotionProps } from 'framer-motion';

import { Icon } from 'components/Icon/Icon';
import { Avatar, AvatarProps } from 'components/Avatar/Avatar';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { Icons } from 'components/Icon/Icon.types';

type AnimatedFlexProps = MotionProps | BoxOwnProps | BoxProps | { disabled: boolean };

const AnimatedFlex = motion(Flex as React.FC<unknown>) as React.FC<AnimatedFlexProps>;

type Props = BoxOwnProps & {
  label: string;
  isActive?: boolean;
  shape?: 'rounded' | 'square';
  additionalLabel?: string;
  disabled?: boolean;
  avatarProps?: AvatarProps;
  onClick?: () => void;
  iconType?: Icons;
};

export type NavButtonProps = Props;

const defaultProps: Partial<Props> = {
  isActive: false,
  shape: 'square',
  additionalLabel: undefined,
  disabled: false,
  avatarProps: undefined,
  onClick: undefined,
  iconType: undefined,
};

export const NavButton = ({
  isActive,
  label,
  shape,
  additionalLabel,
  avatarProps,
  disabled,
  iconType,
  sx,
  ...props
}: Props): React.ReactElement => (
  <AnimatedFlex
    {...props}
    whileTap={{
      scale: isActive ? 1 : 0.985,
    }}
    transition={{ duration: 0.1 }}
    disabled={disabled}
    className="nav-button"
    sx={{
      width: '100%',
      display: 'inline-flex',
      gap: 2,
      minWidth: 'auto',
      appearance: 'none',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      textAlign: 'center',
      fontFamily: 'inherit',
      textDecoration: 'none',
      fontSize: 'inherit',
      border: 0,
      outline: 'none',
      fontWeight: 'bold',
      transition: 'background 0.2s ease-in-out',
      py: avatarProps ? '.5rem' : 2,
      px: 2,
      color: isActive ? 'settingsNavLink.text.active' : 'settingsNavLink.text.default',
      bg: isActive ? 'settingsNavLink.background.active' : 'settingsNavLink.background.default',
      borderRadius: shape === 'rounded' ? 'sm' : 0,
      ...(!disabled && !isActive && { cursor: 'pointer' }),
      '&:hover, &:focus': {
        bg: isActive ? 'settingsNavLink.background.active' : 'settingsNavLink.background.hover',
      },
      '&:active': {
        bg: 'settingsNavLink.background.active',
        '& > span': {
          color: 'settingsNavLink.text.active',
        },
        '& > div > span': {
          color: 'settingsNavLink.text.active',
        },
        '& > span > svg': {
          fill: 'settingsNavLink.text.active',
        },
      },
      '&:disabled, &[disabled]': {
        pointerEvents: 'none',
        opacity: 0.4,
      },
      ...(sx && sx),
    }}
  >
    {iconType && (
      <Icon
        type={iconType}
        fill={isActive ? 'settingsNavLink.text.active' : 'settingsNavLink.text.default'}
        size={21}
      />
    )}
    {avatarProps && (
      <Avatar
        {...avatarProps}
        size={36}
        sx={{
          minWidth: '36px',
          color: isActive ? 'settingsNavLink.text.active' : 'avatar.text',
          mr: 1,
          ...(isActive && { svg: { color: 'settingsNavLink.text.active' } }),
        }}
      />
    )}
    <Flex
      sx={{
        flexWrap: 'wrap',
        flexGrow: 1,
      }}
    >
      {label && (
        <TextEllipsis
          sx={{
            fontSize: avatarProps ? 3 : 2,
            flexBasis: '100%',
            textAlign: 'left',
            overflow: 'hidden',
          }}
        >
          {label}
        </TextEllipsis>
      )}
      {additionalLabel && (
        <TextEllipsis
          sx={{
            textAlign: 'left',
            fontWeight: 400,
            fontSize: 1,
            lineHeight: '0.75rem',
            ...(!isActive && { color: 'settingsNavLink.text.additionalLabel' }),
          }}
        >
          {additionalLabel}
        </TextEllipsis>
      )}
    </Flex>
  </AnimatedFlex>
);

NavButton.defaultProps = defaultProps;
