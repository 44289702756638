import { Trans } from '@lingui/macro';
import isEqual from 'lodash/isEqual';
import { FC, useCallback, useEffect } from 'react';
import { Flex } from 'theme-ui';

import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { Button } from 'components/ui/Buttons';
import { useCustomEventListener } from 'hooks/useCustomEventListener/useCustomEventListener';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { AsideDetails } from 'layouts/AuthorizedApp/AsideDetails/AsideDetails';
import { CustomEvents } from 'utils/customEvents';

import { CalendarDatePicker } from './components/CalendarDatePicker/CalendarDatePicker';
import { useCalendarDatePicker } from './components/CalendarDatePicker/hooks/useCalendarDatePicker';
import { YourCalendarContents } from './components/YourCalendarContents';
import { useYourCalendar } from './hooks/useYourCalendar';

type Props = {
  currentDateUnix: number;
};

export const YourCalendar: FC<Props> = ({ currentDateUnix }) => {
  const { dates, setActiveDate, setVisibleDatesMonth } = useCalendarDatePicker();

  const prevDates = usePrevious(dates);

  const handleOnTodayClick = useCallback(() => {
    setActiveDate(currentDateUnix);
    setVisibleDatesMonth(currentDateUnix);
  }, [currentDateUnix, setActiveDate, setVisibleDatesMonth]);

  const { fetchYourCalendar, isInitialized, fetchInProgress } = useYourCalendar();

  useEffect(() => {
    if (dates && !isEqual(dates, prevDates)) {
      void fetchYourCalendar();
    }
  }, [dates, fetchYourCalendar, prevDates]);

  useCustomEventListener(
    [
      CustomEvents.ACCEPT_REQUEST_MANAGEMENT_SUCCESS_RESPONSE_RECEIVED,
      CustomEvents.ADD_REQUEST_SUCCESS_RESPONSE_RECEIVED,
      CustomEvents.DELETE_REQUESTS_SUCCESS_RESPONSE_RECEIVED,
      CustomEvents.REJECT_REQUEST_SUCCESS_RESPONSE_RECEIVED,
    ],
    () => {
      void fetchYourCalendar();
    },
  );

  return (
    <AsideDetails
      sx={{
        ml: [0, 0, 0, 0],
      }}
    >
      <AsideDetails.Header>
        <AsideDetails.Header.Title sx={{ flexGrow: 1 }}>
          <Trans id="your_calendar.title">Your calendar</Trans>
        </AsideDetails.Header.Title>
        <Button variant="grey" shape="rounded" onClick={handleOnTodayClick}>
          <Trans id="your_calendar.today">Today</Trans>
        </Button>
      </AsideDetails.Header>

      <CalendarDatePicker />

      <Flex sx={{ flexDirection: 'column', p: 4, gap: 4, mt: 2, position: 'relative', flex: '1 0' }}>
        {!isInitialized && <LoadingOverlay sx={{ zIndex: 'base' }} />}
        <YourCalendarContents fetchInProgress={fetchInProgress} />
      </Flex>
    </AsideDetails>
  );
};
