import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';
import { CustomEvents } from 'utils/customEvents';
import { actionWithPrintPopupWindow } from '../helpers';

import {
  ActivateEmployeesActionProps,
  ActivateEmployeesResponse,
  AddEmployeeActionProps,
  AddEmployeeResponse,
  ClearTagsActionProps,
  ClearTagsResponse,
  ClearWorkPositionActionProps,
  ClearWorkPositionResponse,
  DeactivateEmployeesActionProps,
  DeactivateEmployeesResponse,
  DeleteEmployeesActionProps,
  DeleteEmployeesResponse,
  EditEmployeeDetailsActionProps,
  EditEmployeeDetailsResponse,
  EditTeamListViewSettingActionProps,
  EditTeamListViewSettingResponse,
  EmployeesImportActionProps,
  EmployeesImportPreviewActionProps,
  EmployeesImportPreviewResponse,
  EmployeesImportResponse,
  FetchEmployeeDetailsActionProps,
  FetchEmployeeDetailsResponse,
  FetchTagInheritedFeaturesActionProps,
  FetchTagInheritedFeaturesResponse,
  InviteEmployeesActionProps,
  InviteEmployeesResponse,
  PrintQrCardsActionProps,
  PrintQrCardsResponse,
  SetPayDetailsActionProps,
  SetPayDetailsResponse,
  SetPermissionsActionProps,
  SetPermissionsResponse,
  SetRequestsLimitsActionProps,
  SetRequestsLimitsResponse,
  SetRoleActionProps,
  SetRoleResponse,
  SetTagsActionProps,
  SetTagsResponse,
  SetTimeOffLimitsActionProps,
  SetTimeOffLimitsResponse,
  SetWorkPositionsActionProps,
  SetWorkPositionsResponse,
} from './employeesActions.types';

export function fetchEmployeeDetailsAction({
  employeeId,
}: FetchEmployeeDetailsActionProps): Action<FetchEmployeeDetailsResponse> {
  return {
    method: 'GET',
    endpoint: `${API_ENDPOINTS.employee}/${employeeId}`,
  };
}

export function editEmployeeDetailsAction({
  employeeId,
  employee,
  avatar,
}: EditEmployeeDetailsActionProps): Action<EditEmployeeDetailsResponse> {
  const formData = new FormData();
  if (avatar) {
    formData.append('avatar', avatar);
  }

  formData.append('request', JSON.stringify(employee));
  return {
    method: 'POST',
    endpoint: `${API_ENDPOINTS.employeeManagement}/${employeeId}`,
    body: formData,
    config: {
      onResponseCustomEvents: [
        {
          event: CustomEvents.EDIT_EMPLOYEE_DETAILS_SUCCESS_RESPONSE_RECEIVED,
          data: [employeeId],
          restrictToStatuses: [200, 204],
        },
      ],
    },
  };
}

export function addEmployeeAction({ avatar, ...rest }: AddEmployeeActionProps): Action<AddEmployeeResponse> {
  const formData = new FormData();
  if (avatar) {
    formData.append('avatar', avatar);
  }

  formData.append('request', JSON.stringify(rest));

  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.employeeManagement,
    body: formData,
  };
}

export function deleteEmployeesAction(body: DeleteEmployeesActionProps): Action<DeleteEmployeesResponse> {
  return {
    method: 'DELETE',
    endpoint: API_ENDPOINTS.employeeManagement,
    body,
  };
}

export function activateEmployeesAction(body: ActivateEmployeesActionProps): Action<ActivateEmployeesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.employeeActivate,
    body,
  };
}

export function deactivateEmployeesAction(body: DeactivateEmployeesActionProps): Action<DeactivateEmployeesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.employeeDeactivate,
    body,
  };
}

export function inviteEmployeesAction(body: InviteEmployeesActionProps): Action<InviteEmployeesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.employeeInvite,
    body,
  };
}

export function employeesImportPreviewAction(
  body: EmployeesImportPreviewActionProps,
): Action<EmployeesImportPreviewResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.employeeImportPreview,
    body,
  };
}

export function employeesImportAction(body: EmployeesImportActionProps): Action<EmployeesImportResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.employeeImport,
    body,
  };
}

function printQrCardsActionCreator(body: PrintQrCardsActionProps): Action<PrintQrCardsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.printQr,
    body,
  };
}

export const printQrCardsAction = actionWithPrintPopupWindow(printQrCardsActionCreator);

export function downloadQrCardsAction(body: PrintQrCardsActionProps): Action<PrintQrCardsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.printQr,
    body,
    headers: {
      'X-Download': 'true',
    },
  };
}

export function fetchTagInheritedFeaturesAction(
  body: FetchTagInheritedFeaturesActionProps,
): Action<FetchTagInheritedFeaturesResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.tagInheritedFeatures,
    body,
  };
}

export function editTeamListViewSettingAction(
  viewSettings: EditTeamListViewSettingActionProps,
): Action<EditTeamListViewSettingResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.viewsSettings,
    body: {
      team: viewSettings,
    },
  };
}

//
// BULK_ACTIONS
//

export function setWorkPositionsAction(body: SetWorkPositionsActionProps): Action<SetWorkPositionsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setWorkPosition,
    body,
  };
}

export function clearWorkPositionAction(body: ClearWorkPositionActionProps): Action<ClearWorkPositionResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.clearWorkPositions,
    body,
  };
}

export function setTagsAction(body: SetTagsActionProps): Action<SetTagsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setTags,
    body,
  };
}

export function clearTagsAction(body: ClearTagsActionProps): Action<ClearTagsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.clearTags,
    body,
  };
}

export function setRoleAction(body: SetRoleActionProps): Action<SetRoleResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setRole,
    body,
  };
}

export function setPermissionsAction(body: SetPermissionsActionProps): Action<SetPermissionsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setPermissions,
    body,
  };
}

export function setPayDetailsAction(body: SetPayDetailsActionProps): Action<SetPayDetailsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setPayDetails,
    body,
  };
}

export function setTimeOffLimitsAction(body: SetTimeOffLimitsActionProps): Action<SetTimeOffLimitsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setTimeOffLimits,
    body,
  };
}

export function setRequestsLimitsAction(body: SetRequestsLimitsActionProps): Action<SetRequestsLimitsResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.setRequestsLimits,
    body,
  };
}
