import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import _ from 'lodash';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { useLocationOnce } from 'hooks/useLocationOnce/useLocationOnce';

type Return = {
  selectedIds: string[];
  selectedNames: string[];
  isMulti: boolean;
  idsCount: number;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
};

export const useSelectedIdsModalLogic = (
  list?: { id: string }[] | Map<unknown, unknown> | null,
  namePath?: string | [string, string],
  nameParser?: (id: string, index: number) => string,
): Return => {
  useLingui();
  const getFullName = useNameDisplayOrder();
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const { state } = useLocationOnce();
  const { ids } = state || {};

  const selectedIds = useMemo(() => ids || [], [ids]);

  const selectedNames = useMemo(() => {
    if ((!namePath && !nameParser) || !list) {
      return [];
    }
    const limitedIds = selectedIds.slice(0, 10);
    return limitedIds.map((id, index, arr) => {
      const selectedListItem = _.isArray(list) ? list.find(({ id: itemId }) => itemId === id) : list.get(id);
      const addition =
        index === arr.length - 1 && arr.length < selectedIds.length
          ? t({ id: 'team.and_others', message: `... and ${selectedIds.length - 10} others` })
          : '';
      if (selectedListItem && !nameParser && namePath) {
        const names = _.isArray(namePath)
          ? getFullName(_.get(selectedListItem, namePath[0]) as string, _.get(selectedListItem, namePath[1]) as string)
          : (_.get(selectedListItem, namePath) as string);
        return names + addition || '';
      }
      if (nameParser) {
        const parsedName = nameParser(id, index);
        return parsedName + addition || '';
      }
      return '';
    });
  }, [namePath, list, selectedIds, nameParser, getFullName]);

  const isMulti = useMemo(() => selectedIds.length > 1, [selectedIds]);
  const idsCount = useMemo(() => selectedIds.length, [selectedIds]);

  return {
    selectedIds,
    selectedNames,
    idsCount,
    isMulti,
    loading,
    setLoading,
    confirmed,
    setConfirmed,
  };
};
