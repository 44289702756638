import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { IndustrySubcategory } from 'api/actions/data/dataActions.types';
import { organizationSettingsAction } from 'api/actions/settings/settingsActions';
import { OrganizationSettingsActionProps } from 'api/actions/settings/settingsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { ORGANIZATION_RESET } from 'constants/settings';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { blockTransitionAtom, resetFormButtonAtom } from 'state/settings';
import { useOrganizationSettings } from '../../../hooks/useOrganizationSettings';

type Props = {
  handleFormReset: () => void;
  getCurrentFormState: () => OrganizationSettingsActionProps;
};

export const useSubmitOrganizationSettings = ({ handleFormReset, getCurrentFormState }: Props) => {
  useLingui();
  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const [{ skipFetch, isRequestPending }, setBlockTransition] = useRecoilState(blockTransitionAtom);
  const { omittedOrganizationSettings, fetchOrganizationSettings, fetchAbort } = useOrganizationSettings();
  const { mutate } = useMutation(organizationSettingsAction);
  const skipFetchRef = useCallbackRef(skipFetch);
  const isRequestPendingRef = useCallbackRef(isRequestPending);
  const getCurrentFormStateRef = useCallbackRef(getCurrentFormState);

  const organizationResetObject = useMemo(
    () => ({ name: ORGANIZATION_RESET, callback: handleFormReset }),
    [handleFormReset],
  );

  const handleSubmitCallback = useCallback(
    async (data: OrganizationSettingsActionProps) => {
      const submittedFormState = data;
      const parsedSubmittedFormState =
        _.isUndefined(submittedFormState.industrySubcategory) || _.isNaN(submittedFormState.industrySubcategory)
          ? { ...submittedFormState, industrySubcategory: IndustrySubcategory.None }
          : submittedFormState;

      if (_.isEqual(parsedSubmittedFormState, omittedOrganizationSettings) && !isRequestPendingRef.current) {
        setBlockTransition((prevState) => ({ ...prevState, blockTransition: false }));
        setResetCallbacks(null);
        return;
      }

      fetchAbort();
      setResetCallbacks(null);
      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: true }));

      const formStateBeforeRequest = getCurrentFormStateRef.current();

      const { error, status } = await mutate(parsedSubmittedFormState);

      const formStateAfterRequest = getCurrentFormStateRef.current();

      if (!_.isEqual(formStateBeforeRequest, formStateAfterRequest)) {
        return;
      }

      if (!error) {
        if (!skipFetchRef.current) await fetchOrganizationSettings();

        const formStateAfterFetch = getCurrentFormStateRef.current();

        if (!_.isEqual(formStateBeforeRequest, formStateAfterFetch)) {
          return;
        }

        addSnackbar({
          message: t({
            id: 'settings.forms.submit_success',
          }),
          variant: 'success',
        });
      }

      if (error) {
        if (!status) {
          return;
        }
        setResetCallbacks((prevState) =>
          !prevState ? [organizationResetObject] : [...prevState, organizationResetObject],
        );
        return;
      }

      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: false, blockTransition: false }));
    },
    [
      fetchAbort,
      fetchOrganizationSettings,
      getCurrentFormStateRef,
      isRequestPendingRef,
      mutate,
      omittedOrganizationSettings,
      organizationResetObject,
      setBlockTransition,
      setResetCallbacks,
      skipFetchRef,
    ],
  );

  const handleErrorCallback = useCallback(() => {
    setResetCallbacks((prevState) => {
      if (_.includes(prevState, organizationResetObject)) return prevState;
      return !prevState ? [organizationResetObject] : [...prevState, organizationResetObject];
    });
  }, [setResetCallbacks, organizationResetObject]);

  return { handleSubmitCallback, handleErrorCallback };
};
