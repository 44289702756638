import { Text, TextProps } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { dateTime } from 'utils/dateTime';
import { userSessionAtom } from 'state/userSession';

type Props = TextProps;

export const TimeClock = (props: Props): React.ReactElement => {
  const userSession = useRecoilValue(userSessionAtom);
  const preferredFormat = userSession?.timeFormat || '24';

  const [format] = useState(preferredFormat === '24' ? 'HH:mm' : 'hh:mm A');
  const [currentTime, setCurrentTime] = useState(dateTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dateTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <Text {...props}>{currentTime.format(format)}</Text>;
};
