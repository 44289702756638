import { useLingui } from '@lingui/react';
import React from 'react';
import { Flex } from 'theme-ui';

import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';

import { ExternalProviders } from './components/ExternalProviders/ExternalProviders';
import { IntegrateWithUs } from './components/IntegrateWithUs/IntegrateWithUs';
import { Payrolls } from './components/Payrolls/Payrolls';
import { Zus } from './components/Zus/Zus';
import { useParamError } from './hooks/useParamError';

export const IntegrationsTiles = (): React.ReactElement => {
  useLingui();
  const { systemManagement } = useAppPermissions();

  useParamError();

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      {systemManagement.CompanySettings && <IntegrateWithUs />}
      <ExternalProviders />
      {systemManagement.CompanySettings && (
        <>
          <Zus />
          <Payrolls />
        </>
      )}
    </Flex>
  );
};
