import { t } from '@lingui/macro';
import React, { useCallback } from 'react';

import { Tag } from 'components/Tag/Tag';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { RolesSelectOption } from 'state/team';
import { Select, SelectProps } from '../Select';
import { OptionProps } from '../elements';

import { ColorSquare, ContentWrapper } from './elements';

type RolesSelectProps = SelectProps<RolesSelectOption>;

export const RolesSelect = React.forwardRef<HTMLInputElement, RolesSelectProps>(
  (props: RolesSelectProps, ref): React.ReactElement => {
    const prependRenderer = useCallback(
      ({ color }: RolesSelectOption) => <ColorSquare color={color} fillType="borderRounded" />,
      [],
    );

    const appendRenderer = useCallback(({ defaultRole }: RolesSelectOption) => {
      if (!defaultRole) return null;
      const { name, color } = defaultRole;
      return (
        <Tag sx={{ height: 'fit-content', flex: '0 0 auto' }} variant="outline" color={UserSelectableColor[color]}>
          {t({ id: name })}
        </Tag>
      );
    }, []);

    const optionContentRenderer = useCallback(
      (option: RolesSelectOption) => (
        <ContentWrapper>
          {prependRenderer(option)}
          <TextEllipsis sx={{ width: 'auto' }} title={option.label}>
            {option.label}
          </TextEllipsis>
          {appendRenderer(option)}
        </ContentWrapper>
      ),
      [prependRenderer, appendRenderer],
    );

    const optionPropsGenerator = useCallback(
      (option: RolesSelectOption): Partial<OptionProps> => ({
        contentRenderer: () => optionContentRenderer(option),
      }),
      [optionContentRenderer],
    );

    return (
      <Select
        ref={ref}
        placeholder={t({ id: 'team.select_role', message: 'Select role...' })}
        customValueDisplayRenderer={optionContentRenderer}
        optionPropsGenerator={optionPropsGenerator}
        sxOverwrite={{
          maxWidth: '100%',
        }}
        {...props}
      />
    );
  },
);
