import { useEffect, useRef } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { useEvent } from 'hooks/useEvent/useEvent';

let callbackQueue: string[] = [];

export const useOnPopstateQueue = (callback: () => void) => {
  const callbackIdRef = useRef(uuidv1());

  useEffect(() => {
    const callbackId = callbackIdRef.current;

    callbackQueue = [...callbackQueue, callbackId];

    return () => {
      setTimeout(() => {
        const filteredCallbackQueue = callbackQueue.filter((id) => id !== callbackId);
        callbackQueue = filteredCallbackQueue;
      }, 10); // cleanup must be deleyed so the callback can be called
    };
  }, []);

  useEvent(
    'popstate',
    () => {
      if (callbackQueue[callbackQueue.length - 1] === callbackIdRef.current) {
        callback();
      }
    },
    window,
    0,
  );
};
