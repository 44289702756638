import { useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';
import React from 'react';
import { Navigate, useMatch, useParams, useResolvedPath } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { RequestState } from 'api/actions/requests/requestsActions.types';
import { FraudDetectionState } from 'api/actions/timeEvent/timeEventActions.types';
import { Divider } from 'components/Divider/Divider';
import { Icon } from 'components/Icon/Icon';
import { LoadingOverlay } from 'components/Loading/LoadingOverlay';
import { LoadingSpinnerSize } from 'components/Loading/LoadingSpinnerCSS';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { WorkStatusBadge } from 'components/recipes/WorkStatusBadge';
import { Image } from 'components/ui/Image';
import { PersonCell } from 'pages/Reports/output/PersonCell';
import { photoLogDaysSelector } from 'state/clockLog';
import { SCHEDULES_PATTERNS } from 'styles/theme/calendar_patterns';

import { AsidePhotoLogDetails } from './components/AsidePhotoLogDetails';
import { usePhotoLogDetails } from './hooks/usePhotoLogDetails';

const photoSx: ThemeUIStyleObject = {
  borderRadius: 'xs',
  minWidth: '7.5rem',
  border: '2px solid',
  borderColor: 'clockLog.photoLog.photo.border',
  objectFit: 'cover',
};

const photoArrowWrapperSx: ThemeUIStyleObject = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  p: '0.12rem',
  borderRadius: 'xs',
  bg: 'grays5',
  border: '1px solid',
  borderColor: 'clockLog.photoLog.arrow.border',
  '&:hover': {
    bg: 'clockLog.photoLog.arrow.bg.hover',
    cursor: 'pointer',
  },
};

const MAIN_PHOTO_CONTAINER_WIDTH = '593px';
const MAIN_PHOTO_CONTAINER_HEIGHT = '447px';

export const PhotoLogDetailsModal = (): React.ReactElement => {
  useLingui();

  const { user, date } = useParams();
  const clockLogDays = useRecoilValue(photoLogDaysSelector);

  const pathToModal = useResolvedPath('');
  const match = useMatch({ path: pathToModal.pathname, end: true });
  const { baseRoute } = useModal();

  const {
    currentEventIndex,
    currentEventPhotoIndex,
    currentPhotosPhotoUrl,
    currentPhotoUrl,
    events,
    eventsPhotosLoading,
    eventPhotosContainerRef,
    handleEventPhotosBoxClick,
    handlePhotoClick,
    hasCurrentEventPhotos,
    isCurrentPhotoFirst,
    isCurrentPhotoLast,
    modalContainerRef,
    navigateToNextPhoto,
    navigateToPreviousPhoto,
    personDayEvents,
    setPhotosBoxesNodes,
    setPhotosNodes,
  } = usePhotoLogDetails(user || '', +(date || 0), !!match);

  if (!personDayEvents || !clockLogDays)
    return <LoadingOverlay sx={{ zIndex: 'base', position: 'relative', height: 'inherit' }} />;

  if (!clockLogDays?.includes(+(date || 0))) return <Navigate to={baseRoute} relative="path" />;

  const { name, role, tags, avatarUrl } = personDayEvents;

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <PersonCell
            name={name}
            role={role}
            tags={tags}
            avatarUrl={avatarUrl}
            avatarSize={34}
            sx={{ width: 'auto', fontWeight: 'normal', fontSize: '0.875rem' }}
          />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        ref={modalContainerRef}
        sx={{ display: 'flex', flexDirection: 'row', pr: '1rem', pt: 0, pb: 0, pl: 0 }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            width: '40rem',
            maxWidth: '40rem',
            justifyContent: 'space-between',
            outline: 'none',
          }}
          tabIndex={-1}
        >
          {eventsPhotosLoading ? (
            <CenteredLoadingSpinner size={5} />
          ) : (
            <>
              <Flex
                sx={{
                  mb: '1rem',
                  position: 'relative',
                  maxWidth: MAIN_PHOTO_CONTAINER_WIDTH,
                  maxHeight: MAIN_PHOTO_CONTAINER_HEIGHT,
                  justifyContent: 'center',
                  alignSelf: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Image
                  src={hasCurrentEventPhotos ? currentPhotosPhotoUrl : currentPhotoUrl}
                  loadingOverlaySize={LoadingSpinnerSize.imageXL}
                  sx={{
                    borderRadius: 'default',
                    userSelect: 'none',
                    objectFit: 'cover',
                  }}
                />

                {!isCurrentPhotoFirst && (
                  <Flex
                    onClick={navigateToPreviousPhoto}
                    sx={{
                      ...photoArrowWrapperSx,
                      left: '1rem',
                    }}
                  >
                    <Icon type="chevronLeft" fill="clockLog.photoLog.arrow.text" size={18} />
                  </Flex>
                )}
                {!isCurrentPhotoLast && (
                  <Flex
                    onClick={navigateToNextPhoto}
                    sx={{
                      ...photoArrowWrapperSx,
                      right: '1rem',
                    }}
                  >
                    <Icon type="chevronRight" fill="clockLog.photoLog.arrow.text" size={18} />
                  </Flex>
                )}
              </Flex>

              {/* PHOTOS SWITCHER */}
              <Flex sx={{ overflowY: 'visible' }}>
                <Flex
                  ref={eventPhotosContainerRef}
                  sx={{
                    overflowX: 'auto',
                    gap: '0.5rem',
                    flex: 1,
                    scrollbarWidth: 'thin',
                    scrollBehavior: 'smooth',
                  }}
                >
                  {events?.map(
                    ({ id, typeId, typeName, isEnd, photoUrl, fraudDetectionState, requestState, photos }, index) => {
                      const hasOnePhoto = isNil(fraudDetectionState);
                      const isEventActive = events?.[currentEventIndex]?.id === id;
                      const getBackground = () => {
                        const hasPhotoAction = fraudDetectionState === FraudDetectionState.PotentialFraud;
                        const hasPendingRequest = requestState === RequestState.Pending;
                        if (isEventActive) return { bg: 'clockLog.photoLog.switcher.bg.active' };
                        if (hasPhotoAction) return { bg: 'clockLog.photoLog.switcher.bg.hasAction' };
                        if (hasPendingRequest) return SCHEDULES_PATTERNS.pending;
                        return {
                          bg: 'clockLog.photoLog.switcher.bg.default',
                          ':hover': { bg: 'clockLog.photoLog.switcher.bg.hover' },
                        };
                      };

                      return (
                        <Flex
                          key={id}
                          ref={(node) => setPhotosBoxesNodes(id, node)}
                          onClick={() => handleEventPhotosBoxClick(id)}
                          sx={{
                            padding: '0.5rem',
                            flexDirection: 'column',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            flex: 1,
                            alignItems: 'flex-start',
                            gap: '0.5rem',
                            borderRadius: 'sm',
                            cursor: 'pointer',
                            ml: index === 0 ? '1.5rem' : undefined,
                            mr: index === events.length - 1 ? '1.5rem' : undefined,
                            mb: '0.5rem',
                            ...getBackground(),
                          }}
                        >
                          <WorkStatusBadge workStatus={{ id: typeId, name: typeName }} isEnd={isEnd} size="xs" />
                          <Flex sx={{ gap: '0.5rem' }}>
                            {hasOnePhoto && (
                              <Flex sx={{ width: '7.5rem', height: '6.32025rem' }}>
                                <Image
                                  src={photoUrl}
                                  sx={{
                                    ...photoSx,
                                    ...(!isEventActive && {
                                      '&:hover': {
                                        borderColor: 'clockLog.photoLog.switcher.border.hover',
                                      },
                                    }),
                                    ...(isEventActive && { borderColor: 'clockLog.photoLog.switcher.border.active' }),
                                  }}
                                />
                              </Flex>
                            )}

                            {photos &&
                              photos.map(({ url, id: photoId }) => {
                                const isPhotoActive = isEventActive && photos[currentEventPhotoIndex]?.id === photoId;
                                return (
                                  <Flex
                                    key={photoId}
                                    ref={(node) => setPhotosNodes(id, node, photoId)}
                                    sx={{ width: '7.5rem', height: '6.32025rem' }}
                                  >
                                    <Image
                                      onClick={(e) => handlePhotoClick(e, photoId, id)}
                                      src={url}
                                      sx={{
                                        ...photoSx,
                                        ...(!isPhotoActive && {
                                          '&:hover': {
                                            borderColor: 'clockLog.photoLog.switcher.border.hover',
                                          },
                                        }),
                                        ...(isPhotoActive && {
                                          borderColor: 'clockLog.photoLog.switcher.border.active',
                                        }),
                                      }}
                                    />
                                  </Flex>
                                );
                              })}
                          </Flex>
                        </Flex>
                      );
                    },
                  )}
                </Flex>
              </Flex>
              {/* PHOTOS SWITCHER */}
            </>
          )}
        </Flex>

        <Divider axis="vertical" sx={{ width: '2px', height: '100%', mb: '-1.5rem' }} />

        {events?.[currentEventIndex] && <AsidePhotoLogDetails event={events?.[currentEventIndex]} />}
      </Modal.Body>
    </>
  );
};
