import React, { useContext, useState } from 'react';
import { ClientContext, useQuery } from 'react-fetching-library';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { LoadingSplash } from 'components/Loading/LoadingSplash';
import { fetchOrganizationSessionAction } from 'api/actions/organizationSession/organizationSessionActions';
import { organizationSessionAtom } from 'state/organizationSession';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { setUnauthorized, signOut } from 'context/auth/authActionCreators/authActionCreators';
import { userSessionAtom } from 'state/userSession';
import { authStorage } from 'context/auth/authStorage/AuthStorage';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { signOutAction } from 'api/actions/auth/authActions';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useMount } from 'hooks/useMount/useMount';

import { OrganizationSessionInitializerProps } from './OrganizationSessionInitializer.types';
import { getParsedOrganizationSession } from './getParsedOrganizationSession';

export const OrganizationSessionInitializer = ({
  children,
}: OrganizationSessionInitializerProps): React.ReactElement => {
  const [isInitialized, setIsInitialized] = useState(false);
  const setOrganizationSession = useSetRecoilState(organizationSessionAtom);
  const resetUserSessionState = useResetRecoilState(userSessionAtom);
  const resetOrganizationSessionState = useResetRecoilState(organizationSessionAtom);

  const dispatch = useAuthDispatch();
  const { query } = useContext(ClientContext);

  const navigate = useAppNavigate();

  const {
    query: fetchOrganizationSession,
    error: organizationSessionError,
    payload: organizationSessionPayload,
  } = useQuery(fetchOrganizationSessionAction());

  const logout = () => {
    authStorage.accessToken = null;
    resetUserSessionState();
    resetOrganizationSessionState();
    dispatch(signOut());
    dispatch(setUnauthorized());
    void query(signOutAction());
    setTimeout(() => {
      localStorage.clear();
    }, 0);
    navigate('/', { replace: true });
  };

  useMount(async () => {
    const { payload, error } = await fetchOrganizationSession();

    if (!error && payload) {
      const parsedOrganizationSession = getParsedOrganizationSession(payload);
      setOrganizationSession(parsedOrganizationSession);
      setIsInitialized(true);
    }

    // !! FAILSAFE !!
    // SIGN-OUT FOR ABANDONED SIGN-UPS
    //
    if (error && payload?.innerCode === ErrorInnerCodes.NoPermissions) {
      logout();
    }
  });

  if (
    !isInitialized &&
    organizationSessionError &&
    organizationSessionPayload?.innerCode !== ErrorInnerCodes.NoPermissions
  ) {
    throw new Error('Failed to fetch organization session.');
  }

  if (!isInitialized) {
    return <LoadingSplash />;
  }

  return <>{children}</>;
};
