import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Textarea } from 'components/ui/Textarea';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { labelProps } from '../../../commons';

export const EventNote = (): React.ReactElement => {
  useLingui();

  const {
    register,
    formState: { errors },
  } = useFormContext<{ details: { note: string }; timeTrackingData: { note: string } }>();

  return (
    <Textarea
      id="details.note"
      {...register('details.note', validationFactory({ ...VALIDATION_RULES.TIME_EVENT_NOTE }))}
      error={!!errors.timeTrackingData?.note}
      errorMessage={errors.timeTrackingData?.note?.message}
      placeholder={t({
        id: 'add_request.edit_event.note',
        message: 'Event note (optional)',
      })}
      variant="rounded"
      size="sm"
      inputProps={{ sx: { height: '37px' } }}
      label={t({ id: 'add_request.edit_event.note' })}
      labelProps={{ sx: { ...labelProps.sx, fontSize: 3 } }}
    />
  );
};
