import React, { useEffect, useMemo } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, ThemeUICSSObject } from 'theme-ui';

import { Alert } from 'components/ui/Alert/Alert';

import { useAlertProvider } from './useAlertProvider';

type Props = {
  children?: React.ReactElement | React.ReactElement[];
  isGlobal?: boolean;
  alertSx?: ThemeUICSSObject;
  isVisible?: boolean;
};

// FIXME: Temporary solution until refactor and release of v6 react router
const customAlertObserverAtom = atom<boolean>({
  key: 'customAlertObserver',
  default: false,
});

const CustomAlertObserver = () => {
  const setObserver = useSetRecoilState(customAlertObserverAtom);

  useEffect(() => {
    setObserver(true);

    return () => {
      setObserver(false);
    };
  }, [setObserver]);

  return null;
};

export const AlertProvider = ({ children, isGlobal, alertSx, isVisible = true }: Props): React.ReactElement => {
  const { alertProps, isPaymentOpen } = useAlertProvider();
  const customAlertObserver = useRecoilValue(customAlertObserverAtom);

  const alert = useMemo(
    () => (
      <Alert
        {...alertProps}
        sx={{
          mt: 4,
          mx: 4,
          ...(isPaymentOpen && { maxWidth: 'container.xl', alignSelf: 'center', width: '100%' }),
          ...(alertSx && alertSx),
        }}
      />
    ),
    [alertProps, alertSx, isPaymentOpen],
  );

  const alertRenderer = useMemo(() => {
    if (!children && isVisible) return alert;

    return (
      <>
        {alertProps && (!customAlertObserver || !isGlobal) && isVisible ? (
          <Flex sx={{ flexDirection: 'column', width: '100%', flex: '1 0' }}>
            {alert}
            {children}
          </Flex>
        ) : (
          <>{children}</>
        )}
      </>
    );
  }, [alert, alertProps, children, customAlertObserver, isGlobal, isVisible]);

  return (
    <>
      {!isGlobal && <CustomAlertObserver />}
      {alertRenderer}
    </>
  );
};
