// https://www.npmjs.com/package/react-window-scroller

import _ from 'lodash';
import React, { useRef, useEffect, RefObject, CSSProperties, ReactElement, LegacyRef, useCallback } from 'react';
import { FixedSizeList, GridOnScrollProps, ListOnScrollProps } from 'react-window';

type OnScrollProps = Partial<ListOnScrollProps & GridOnScrollProps>;

type ChildrenProps = {
  ref: LegacyRef<FixedSizeList> | undefined;
  outerRef: RefObject<HTMLElement>;
  style: CSSProperties;
  onScroll: (props: OnScrollProps) => void;
};

type Props = {
  children: (props: ChildrenProps) => ReactElement;
  throttleTime?: number;
  isGrid?: boolean;
  element?: HTMLElement;
};

const getScrollPosition = (axis: string, element?: HTMLElement) => {
  if (element) {
    if (axis === 'y') return element.scrollTop;
    if (axis === 'x') return element.scrollLeft;
  } else {
    if (axis === 'y') return window.pageYOffset;
    if (axis === 'x') return window.pageXOffset;
  }
  return 0;
};

export const ScrollController = ({ children, throttleTime, isGrid, element }: Props): React.ReactElement | null => {
  const ref = useRef<FixedSizeList>(null);
  const outerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleWindowScroll = _.throttle(() => {
      const { offsetTop = 0, offsetLeft = 0 } = outerRef.current || {};
      const top = getScrollPosition('y', element) - offsetTop;
      const left = getScrollPosition('x', element) - offsetLeft;

      if (isGrid) ref.current?.scrollTo(left);
      if (!isGrid) ref.current?.scrollTo(top);
    }, throttleTime);

    (element ?? window).addEventListener('scroll', handleWindowScroll);
    return () => {
      handleWindowScroll.cancel();
      (element ?? window).removeEventListener('scroll', handleWindowScroll);
    };
  }, [isGrid, element, throttleTime]);

  const onScroll = useCallback(
    ({ scrollLeft, scrollTop, scrollOffset, scrollUpdateWasRequested }: OnScrollProps) => {
      if (!scrollUpdateWasRequested) return;
      const top = getScrollPosition('y', element);
      const left = getScrollPosition('x', element);
      const { offsetTop = 0, offsetLeft = 0 } = outerRef.current || {};

      if (scrollOffset) {
        const scrollO = scrollOffset + Math.min(top, offsetTop);
        element?.scrollTo(0, scrollO);
      }

      if (scrollLeft && scrollTop) {
        const scrollT = scrollTop + Math.min(top, offsetTop);
        const scrollL = scrollLeft + Math.min(left, offsetLeft);
        if (isGrid && (scrollT !== top || scrollL !== left)) {
          element?.scrollTo(scrollL, scrollT);
        }
      }
    },
    [element, isGrid],
  );

  return children({
    ref,
    outerRef,
    style: {
      width: isGrid ? 'auto' : '100%',
      height: '100%',
      display: 'inline-block',
    },
    onScroll,
  });
};
