import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { KioskContextApp } from 'Kiosk/KioskContextApp';
import { KioskApp } from 'Kiosk/components/KioskApp';
import { CameraRequiredErrorPage } from 'Kiosk/pages/errors/CameraRequired/CameraRequiredErrorPage';
import { LocationRequiredErrorPage } from 'Kiosk/pages/errors/LocationRequired/LocationRequiredErrorPage';
import { AuthorizedApp } from 'base/Root/output/AuthorizedApp';
import { PATH, TO } from 'constants/routes';
import { useRedirectPath } from 'hooks/useRedirectPath/useRedirectPath';
import { AuthenticationLayout } from 'layouts/Authentication/output/AuthenticationLayout';
import { PassReset } from 'pages/PassReset/output/PassReset';
import { PassResetWizard } from 'pages/PassReset/output/PassResetWizard';
import { PreparingFile } from 'pages/PreparingFile/PreparingFile';
import { QuickbooksDisconnect } from 'pages/QuickbooksDisconnect/QuickbooksDisconnect';
import { SignIn } from 'pages/SignIn/output/SignIn';
import { SignOut } from 'pages/SignOut/output/SignOut';
import { AccountType } from 'pages/SignUp/output/AccountType';
import { Employee } from 'pages/SignUp/output/Employee';
import { EmployeeInvited } from 'pages/SignUp/output/EmployeeInvited';
import { EmployeeNotInvited } from 'pages/SignUp/output/EmployeeNotInvited';
import { Employer } from 'pages/SignUp/output/Employer';
import { SignUp } from 'pages/SignUp/output/SignUp';
import { AccountBlocked } from 'pages/errors/AccountBlocked';
import { PageNotFound } from 'pages/errors/NotFound';
import { ServiceUnavailable } from 'pages/errors/ServiceUnavailable';
import { languageSelector } from 'state/recoilState';

import { AuthorizedPopUpRenderer } from './AuthorizedPopUpRenderer';
import { LocalizedRouteGate } from './LocalizedRouteGate';
import { RequireAuth } from './RequireAuth';
import { RequireSignUpInProgress } from './RequireSignUpInProgress';

export const AppRoutes = (): React.ReactElement | null => {
  const language = useRecoilValue(languageSelector);
  const { redirectPath } = useRedirectPath();

  if (redirectPath) return null;

  return (
    <LocalizedRouteGate language={language}>
      <Routes>
        {/* usuwa // z konca sciezki */}
        {/* jeśli jest nadal potrzebne przepisać na komponent Gate */}
        {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}
        <Route path={PATH.SIGN_OUT[language]} element={<SignOut />} />
        <Route path={PATH.SERVICE_UNAVAILABLE[language]} element={<ServiceUnavailable />} />
        <Route path={PATH.PAGE_NOT_FOUND[language]} element={<PageNotFound />} />

        <Route
          path={PATH.BLOCKED_ACCOUNT[language]}
          element={
            <RequireAuth>
              <AccountBlocked />
            </RequireAuth>
          }
        />

        {/* AUTHENTICATION */}
        <Route element={<AuthenticationLayout showFooter />}>
          <Route path={PATH.SIGN_IN[language]} element={<SignIn />} />
          <Route path={PATH.SIGN_UP[language]} element={<SignUp />} />
          <Route path={PATH.PASS_RESET[language]} element={<PassReset />} />
          <Route path={PATH.PASS_RESET__CONFIRMED[language]} element={<PassResetWizard />} />
        </Route>

        <Route element={<AuthenticationLayout />}>
          <Route
            path={PATH.SIGN_UP__ACCOUNT_TYPE[language]}
            element={
              <RequireSignUpInProgress>
                <AccountType />
              </RequireSignUpInProgress>
            }
          />
          <Route path={PATH.SIGN_UP__EMPLOYEE[language]} element={<Employee />} />
          <Route path={PATH.SIGN_UP__EMPLOYEE__INVITED[language]} element={<EmployeeInvited />} />
          <Route
            path={PATH.SIGN_UP__EMPLOYEE__NOT_INVITED[language]}
            element={
              <RequireSignUpInProgress>
                <EmployeeNotInvited />
              </RequireSignUpInProgress>
            }
          />
          <Route
            path={PATH.SIGN_UP__EMPLOYER[language]}
            element={
              <RequireSignUpInProgress>
                <Outlet />
              </RequireSignUpInProgress>
            }
          >
            <Route
              index
              element={
                <Navigate
                  to={`${TO.SIGN_UP__EMPLOYER__STEP[language]}/1`}
                  state={{
                    signUp: {
                      inProgress: true,
                    },
                  }}
                />
              }
            />

            <Route path={PATH.SIGN_UP__EMPLOYER__STEP[language]} element={<Employer />} />
          </Route>
          <Route
            path={PATH.SIGN_UP__EMPLOYER[language]}
            element={
              <RequireSignUpInProgress>
                <Employer />
              </RequireSignUpInProgress>
            }
          />
        </Route>

        {/* QUICKBOOKS DISCONNECT */}
        <Route path={PATH.QUICKBOOKS_DISCONNECT[language]} element={<QuickbooksDisconnect />} />

        {/* PRINT FILES */}
        <Route path={PATH.PREPARING_FILE[language]} element={<PreparingFile />} />

        {/* AUTHORIZED ROUTES */}
        <Route
          element={
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          }
        >
          {/* AUTHORIZED APP -> KIOSK APP */}
          <Route element={<KioskContextApp />}>
            <Route path={`${PATH.KIOSK[language]}/*`} element={<KioskApp />} />
            <Route path={PATH.KIOSK__CAMERA_PERMISSION[language]} element={<CameraRequiredErrorPage />} />
            <Route path={PATH.KIOSK__LOCATION_PERMISSION[language]} element={<LocationRequiredErrorPage />} />
          </Route>

          {/* AUTHORIZED APP */}
          <Route path={`${PATH.START[language]}*`} element={<AuthorizedApp />} />

          {/* AUTHORIZED APP POPUPS */}
          <Route path={PATH.POPUP__TYPE[language]} element={<AuthorizedPopUpRenderer />} />
        </Route>
      </Routes>
    </LocalizedRouteGate>
  );
};
