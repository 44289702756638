import { RouteProps, Route } from 'react-router-dom';

export const renderSameElementRoutes = (routeProps: RouteProps, paths: string[], key?: string) =>
  paths.map((path) => <Route key={key || path} path={path} {...routeProps} />);

// export const renderModalRoute(routeProps: RouteProps, paths: string[])=>{

// }

// const ModalRoutes = ()=>{

// }
