export enum ErrorInnerCodes {
  // 403 Forbidden
  InvalidSubscription = 1000,
  NoPermissions = 1001,
  DeviceDeleted = 1002,
  DateRangeAccessDenied = 1003,
  RefreshSession = 1004,
  RefreshOrganizationSession = 1005,
  InvalidHost = 1006,

  // 400 Bad request
  BadRequestData = 2000,
  BadCredentials = 2001,
  Custom = 2002,
  Logout = 2003,
  InvalidTimeZoneId = 2004,
  NoDataToExport = 2005,
  DuplicateName = 2006,
  RedirectClientToResponseUri = 2007,

  // Time Tracking
  BadQrCode = 2100,
  InvalidFace = 2101,
  MissingFaceModel = 2102,
  BadDeviceTime = 2103,
  InvalidLocation = 2104,
  OfflineModeDisabled = 2106,
  TimeEventDuplicate = 2107,
  TimeEventMagnet = 2108,
  TimeEventError = 2109,
  TimeEventPhotoUploadError = 2110,
  AddTimeEventOnSkippedDays = 2111,
  TimeEventNotFound = 2112,
  DeletePhotosError = 2113,
  LocationNotFound = 2114,
  ClockInNotAllowed = 2115,
  NoLocationsToRemove = 2116,

  // Location

  // Face Recognition
  FaceModelCreationError = 2200,
  FacePhotoUsedInModel = 2201,

  // User
  UserAlreadyRegister = 2300,
  UserCreationError = 2301,
  UserAuthenticateError = 2302,
  UserNotExist = 2303,
  UserIsInactive = 2304,
  UserResetPasswordError = 2305,
  UserUpdateSecurityStampError = 2306,
  UserInvited = 2307,
  UserConfirmEmailError = 2308,
  UserAddPasswordError = 2309,
  InvitationInvalidAdminEmail = 2310,
  UserEmailTaken = 2311,
  DeleteUserError = 2312,
  ChangeEmailError = 2313,
  UserInvitationError = 2314,
  InvalidPassword = 2315,
  ExternalLoginNotLinked = 2316,
  ExternalLoginAccountLinkError = 2317,
  UserChangePasswordError = 2318,
  ExternalLoginDisconnectError = 2319,
  UserSetPasswordError = 2320,

  // Payment
  PaymentEdition = 2400,
  PaymentNotFound = 2401,
  PaymentIntegrationNotFound = 2402,
  CreatePaymentIntentError = 2403,
  PaymentIntentRequiresConfirm = 2404,
  GetCustomerPaymentMethodError = 2405,
  ChargeCardError = 2406,
  CreateStripeCustomerError = 2407,
  GetSetupIntentError = 2408,
  PayUPaymentCanceled = 2409,
  PayUPaymentRejected = 2410,
  PayUPaymentError = 2411,
  PayUCancelError = 2412,
  CanNotProcessFreePackage = 2413,
  PendingPaymentError = 2414,
  IncorrectTotalPrice = 2415,
  TaxChanged = 2416,
  CouponNotFound = 2417,
  InvalidCoupon = 2418,
  RenewWireTransferError = 2419,
  PaymentMethodNotSelected = 2420,
  IncorrectPaymentState = 2421,
  NoModuleWasSelected = 2422,
  MinUsersCountError = 2423,
  BraintreeDuplicatePaymentError = 2424,
  BraintreeTransactionError = 2433,
  PaymentIntentRequiresConfirmError = 2425,
  PaymentActivateError = 2426,
  ProformaPaymentIsUnavailable = 2427,
  PaymentDowngradeUnavailable = 2428,
  ActivatePayment = 2429,
  MinPaymentAmountError = 2430,
  CardInsufficientFunds = 2434,
  StripeSetupFailed = 2435,

  // Organization
  MissingCountryCode = 2500,
  MissingVatId = 2501,
  VatValidationError = 2502,
  OrganizationAvatarBlobError = 2503,
  AnonymizationError = 2504,

  // Person
  CreatePersonError = 2600,
  EditPersonError = 2601,
  DeletePersonError = 2602,
  PersonBlobError = 2603,
  NoPeopleToModify = 2604,
  EmptyPeopleIds = 2605,
  ImportPeopleError = 2606,
  SetRatesError = 2607,
  SetTimeOffLimitsError = 2608,
  SetRequestsLimitsError = 2609,
  DuplicateWorkPosition = 2610,
  WorkPositionError = 2611,
  AddEmployeesExceedsPaid = 2612,

  // Schedule
  ScheduleOverlap = 2700,
  AutomaticBreakExceededScheduleDuration = 2701,
  NoSchedulesStatistics = 2702,

  // Permissions
  GetRoleError = 2800,
  GetUserPermissionsError = 2801,
  GetDepartmentPermissionsError = 2802,
  UpdateRoleError = 2803,
  UpdateUserPermissionsError = 2805,
  UpdateDepartmentPermissionsError = 2806,
  DeleteRoleError = 2807,

  // TimeOffs
  TimeOffExistsInSpecificRange = 2900,
  TimeOffCorrelatedDateForSameDay = 2901,
  TimeOffNoOvertimeToChoose = 2902,
  TimeOffOvertimeLimitExceeded = 2903,
  TimeOffOvertimeDayOffExceedsLimit = 2904,
  TimeOffHasOvertimeScheduleInRange = 2905,
  TimeOffOvertimeNoSchedule = 2906,

  // File
  NotAllowedExtension = 3000,
  InvalidFile = 3001,
  MaxFileSizeExceeded = 3002,
  FileUploadError = 3003,

  // BusinessTravel
  HasBusinessTravelInRange = 3100,
  BusinessTravelNotFound = 3101,

  // Request
  IncorrectRequestType = 3200,
  IncorrectRequestState = 3201,
  RequestIsDeleted = 3202,
  RequestNotFound = 3203,
  RequestAlreadyProcessed = 3204,
  IncompleteRequest = 3205,
  RequestDateRangeError = 3206,
  AddRequestError = 3207,
  EditRequestError = 3208,
  RequestLimitsExceeded = 3209,
  RequestForZeroDays = 3210,
  RequestDaysLimitExceeded = 3211,
  RequestAtLeastOneMinuteDurationError = 3212,
  RequestAlreadyAdded = 3213,
  RequestExceededMaxMinuteDuration = 3214,
  SwapRequestNoPublishSchedules = 3215,
  SwapRequestIsUserActiveError = 3216,

  // Tags
  EditTagError = 3300,
  DeleteTagError = 3301,

  // CustomRequest

  // Integrations
  NoDataToImport = 3500,
  ImportError = 3501,
  IntegrationConnectionError = 3502,
  IntegrationUpdateError = 3503,
  IntegrationDisconnectError = 3504,
  WebhookDuplicate = 3505,
  InvalidAuthorization = 3506,
  IntegrationNotFound = 3507,
  IncorrectOrganizationVersion = 3508,
  RefreshToken = 3509,
  DisconnectError = 3510,
  GetDataError = 3511,
  ExportTimeActivityError = 3512,

  // Ezla
  EzlaWrongLoginData = 3600,
  EzlaError = 3601,

  // TimeEventType
  TimeEventTypeNotExists = 3700,

  // Availability
  WorkdayAvailabilityMaxForDay = 4100,
  WorkdayAvailabilityIsOverlapped = 4101,

  // PZU Sport
  ConsentRequiredError = 4300,
}

export enum SecondaryInnerCodes {
  Required = 0,
  WrongUrl = 1,
  WrongEmail = 2,
  WrongPhoneNumber = 3,
  MaxValueExceeded = 4,
  NotEqualExpected = 5,
  GreaterThanExpected = 6,
  InvalidPassword = 7,
  LetterOnly = 8,
  SimpleSpecialCharactersOnly = 9,
  NoSpecialCharacters = 10,
}
