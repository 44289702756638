/** @jsxImportSource theme-ui */
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { forwardRef, useCallback, useMemo } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { Flex, Heading } from 'theme-ui';

import { EmployeeRequestLimitsFormState } from 'state/team';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

import { RequestLimitsGroupFieldArray } from './formsElements/RequestLimitsGroupFieldArray/RequestLimitsGroupFieldArray';
import { TimeOffLimitsGroupFieldArray } from './formsElements/TimeOffLimitsGroupFieldArray/TimeOffLimitsGroupFieldArray';
import { customRequestsLimitsTypeEnforcer, timeOffLimitsTypeEnforcer } from './utils/fieldArrayTypeEnforcers';
import { customRequestLimitsValidator, timeOffLimitsValidator } from './utils/fieldArrayValidators';

const forceCorrectTypes = ({
  timeOffLimits,
  customRequestsLimits,
}: Partial<EmployeeRequestLimitsFormState>): EmployeeRequestLimitsFormState => ({
  timeOffLimits: timeOffLimitsTypeEnforcer(timeOffLimits),
  customRequestsLimits: customRequestsLimitsTypeEnforcer(customRequestsLimits),
});

type Props = {
  defaultValues: Partial<EmployeeRequestLimitsFormState>;
  onSubmit: (props: EmployeeRequestLimitsFormState) => Promise<boolean>;
  onSubmitError?: () => void;
};

export const UserRequestLimits = forwardRef<HTMLFormElement, Props>(
  ({ onSubmit, onSubmitError, defaultValues }, ref) => {
    useLingui();
    const { trigger, control, register, handleSubmit } = useForm<EmployeeRequestLimitsFormState>({
      defaultValues: cloneDeep(defaultValues),
      mode: 'onTouched',
      reValidateMode: 'onChange',
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resolver,
    });
    const handleSubmitCallback = useCallback(
      (props: Partial<EmployeeRequestLimitsFormState>) => {
        void onSubmit(forceCorrectTypes(props));
      },
      [onSubmit],
    );

    const fieldArrayProps = useMemo(
      () => ({
        register,
        control,
        trigger,
        fieldArrayManagerProps: {
          clearable: true,
        },
      }),
      [register, control, trigger],
    );

    return (
      <form
        sx={{
          width: '100%',
          minWidth: '450px',
          '@media screen and (max-width: 480px)': {
            pr: 3,
          },
        }}
        ref={ref}
        onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, onSubmitError))}
        noValidate
      >
        <Flex sx={{ flexDirection: 'column' }}>
          <Heading variant="heading4" mb={2}>
            <Trans id="team.time_off_limits.bulk">Time offs limits</Trans>
          </Heading>
          <TimeOffLimitsGroupFieldArray {...fieldArrayProps} />

          <Heading variant="heading4" mb={2} mt={5}>
            <Trans id="team.req_limits.bulk">Custom requests limits</Trans>
          </Heading>
          <RequestLimitsGroupFieldArray {...fieldArrayProps} />
        </Flex>
      </form>
    );
  },
);

const resolver: Resolver<EmployeeRequestLimitsFormState> = (values) => {
  const { customRequestsLimits, timeOffLimits } = values;

  const customRequestLimitsErrors = customRequestLimitsValidator(customRequestsLimits);
  const timeOffLimitsErrors = timeOffLimitsValidator(timeOffLimits);

  return {
    errors: {
      ...(!isEmpty(customRequestLimitsErrors) && { customRequestsLimits: customRequestLimitsErrors }),
      ...(!isEmpty(timeOffLimitsErrors) && { timeOffLimits: timeOffLimitsErrors }),
    },
    values,
  };
};
