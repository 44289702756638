import { RouteState } from 'react-router-dom';
import { atom, selector, selectorFamily } from 'recoil';

import { ScheduleActionData, ScheduleWizardOptionsProps } from 'api/actions/calendar/calendarActions.types';
import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { LanguageRoute } from 'constants/routes';
import { AddTimeEventsInput } from 'pages/ClockLog/output/types';
import { AddRequestFormState } from 'pages/Requests/output/types';

import { ScheduleWizardStep } from './calendar';
import { AddEventStep } from './clockLog';
import { FilterGroupState } from './filters';
import { Step } from './requests';
import { ProfileFormState } from './team';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MinimizedModalDetails<T extends Record<string, any> = Record<string, any>> = {
  minimizedModal: MinimizedModal;
  route: LanguageRoute;
  title: string;
  modalDetails: T;
  additionalPath?: string;
  routeState?: Partial<Omit<RouteState, 'minimizedModalState'>>;
  employeesToValidate?: string[];
};

export enum ViewWithMinimizedModals {
  REQUESTS = 'REQUESTS',
  CALENDAR = 'CALENDAR',
  ATTENDANCE = 'ATTENDANCE',
  TIMESHEETS = 'TIMESHEETS',
  CLOCK_LOG = 'CLOCK_LOG',
  TEAM = 'TEAM',
  HOME = 'HOME',
}

export enum MinimizedModal {
  ADD_REQUESTS = 'ADD_REQUESTS',
  ADD_SCHEDULES = 'ADD_SCHEDULES',
  ADD_EVENTS = 'ADD_EVENTS',
  ADD_EVENTS_FOR = 'ADD_EVENTS_FOR',
  ADD_TEAMMATE = 'ADD_TEAMMATE',
  SCHEDULE_WIZARD = 'SCHEDULE_WIZARD',
  REQUEST_WIZARD = 'REQUEST_WIZARD',
  EVENT_WIZARD = 'EVENT_WIZARD',
}

type TeammatesFilters = {
  teammatesFilterState: FilterGroupState;
  tagsFilterState: FilterGroupState;
  rolesFilterState: FilterGroupState;
  searchInputState: string;
};

export type MinimizeModalAddRequest = {
  employees: string[];
  step: Step;
  requestType: RequestFormType;
  filterState: TeammatesFilters;
  noteVisible?: boolean;
  attachmentsVisible?: boolean;
  formState?: AddRequestFormState;
  requestToEdit?: string;
};

export type MinimizeModalAddTeammate = {
  addTeammate?: ProfileFormState;
  avatarUrl?: string;
  avatarChanged?: boolean;
};

export type MinimizeModalAddEvents = {
  employees: string[];
  step: AddEventStep;
  filterState: TeammatesFilters;
  addEvents?: AddTimeEventsInput;
};

export type MinimizeModalScheduleWizard = {
  employees: string[];
  step: ScheduleWizardStep;
  schedule: ScheduleActionData | null;
  scheduleWizardOptions?: ScheduleWizardOptionsProps;
  hiddenTeammatesFilter: boolean;
  deactivatedTeammatesFilter: boolean;
  searchFilterValue: string;
};

export type MinimizeModalAddSchedule = Pick<MinimizeModalScheduleWizard, 'schedule'>;

const reportsMinimizedModals = [
  MinimizedModal.ADD_EVENTS_FOR,
  MinimizedModal.ADD_REQUESTS,
  MinimizedModal.ADD_SCHEDULES,
  MinimizedModal.EVENT_WIZARD,
  MinimizedModal.REQUEST_WIZARD,
  MinimizedModal.SCHEDULE_WIZARD,
];

const minimizedModalsForViewMap = new Map<ViewWithMinimizedModals, MinimizedModal[]>([
  [ViewWithMinimizedModals.REQUESTS, [MinimizedModal.ADD_REQUESTS]],
  [ViewWithMinimizedModals.CLOCK_LOG, [MinimizedModal.ADD_EVENTS]],
  [ViewWithMinimizedModals.TEAM, [MinimizedModal.ADD_TEAMMATE]],
  [
    ViewWithMinimizedModals.CALENDAR,
    [
      MinimizedModal.ADD_REQUESTS,
      MinimizedModal.ADD_SCHEDULES,
      MinimizedModal.REQUEST_WIZARD,
      MinimizedModal.SCHEDULE_WIZARD,
    ],
  ],
  [ViewWithMinimizedModals.ATTENDANCE, reportsMinimizedModals],
  [ViewWithMinimizedModals.TIMESHEETS, reportsMinimizedModals],
  [ViewWithMinimizedModals.HOME, [MinimizedModal.ADD_REQUESTS]],
]);

export const allMinimizedModalsDetailsAtom = atom<MinimizedModalDetails[]>({
  key: 'allMinimizedModalsDetails',
  default: [],
});

export const minimizedModalsDetailsSelectorFamily = selectorFamily<MinimizedModalDetails[], ViewWithMinimizedModals>({
  key: 'minimizedModalsDetails',
  get:
    (viewWithMinimizedModals) =>
    ({ get }) => {
      const allMinimizedModalsDetails = get(allMinimizedModalsDetailsAtom);
      const minimizedModalsForView = minimizedModalsForViewMap.get(viewWithMinimizedModals);

      if (!minimizedModalsForView) return [];

      return allMinimizedModalsDetails.filter(({ minimizedModal }) => minimizedModalsForView.includes(minimizedModal));
    },
});

export const currentlyOpenModalInstancesAtom = atom<number[]>({
  key: 'currentlyOpenModalInstances',
  default: [],
});

export const isAnyModalInstanceOpenSelector = selector<boolean>({
  key: 'isAnyModalInstanceOpen',
  get: ({ get }) => {
    const currentlyOpenModalInstances = get(currentlyOpenModalInstancesAtom);

    return !!currentlyOpenModalInstances.length;
  },
});

export const boostModalZIndexAtom = atom<boolean>({
  key: 'boostModalZIndex',
  default: false,
});
