import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
  NormalPayrollPeriod,
  OvertimePayrollPeriod,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { InputOption, Select } from 'components/ui/Select/Select';
import { baseOvertimePayrollPeriodOptionsSelector, payrollPeriodOptionsSelector } from 'state/selectOptions';
import { useFormContextCustomEvent } from '../../../../../../../hooks/useFormContextCustomEvent';
import { LabelWithBadge } from '../../../../../../LabelWithBadge';
import { OptionLabel } from '../../../../../../OptionLabel';
import { SettingsGroup } from '../../../../../../SettingsGroup/SettingsGroup';

export const PayPeriod = (): React.ReactElement => {
  useLingui();
  const baseOvertimePayrollPeriodOptions = useRecoilValue(baseOvertimePayrollPeriodOptionsSelector);
  const payrollPeriodOptions = useRecoilValue(payrollPeriodOptionsSelector);

  const {
    registerOnBlur,
    watch,
    formState: { errors },
    setValue,
  } = useFormContextCustomEvent();

  const payrollWatch = watch('payrollPeriod');
  const overtimeWatch = watch('overtimePeriod');

  const overtimePayrollPeriodOptions: InputOption[] = useMemo(() => {
    if (payrollWatch === NormalPayrollPeriod.Weeks2) {
      return baseOvertimePayrollPeriodOptions.filter(
        (item) => (+item.id as OvertimePayrollPeriod) !== OvertimePayrollPeriod.Weeks1,
      );
    }
    if (payrollWatch === NormalPayrollPeriod.Months1) {
      return baseOvertimePayrollPeriodOptions.filter(
        (item) =>
          (+item.id as OvertimePayrollPeriod) !== OvertimePayrollPeriod.Weeks1 &&
          (+item.id as OvertimePayrollPeriod) !== OvertimePayrollPeriod.Weeks2,
      );
    }

    return baseOvertimePayrollPeriodOptions;
  }, [baseOvertimePayrollPeriodOptions, payrollWatch]);

  useEffect(() => {
    if (
      payrollWatch === NormalPayrollPeriod.Months1 &&
      (overtimeWatch === OvertimePayrollPeriod.Weeks1 || overtimeWatch === OvertimePayrollPeriod.Weeks2)
    ) {
      setValue('overtimePeriod', OvertimePayrollPeriod.Months1);
    }

    if (payrollWatch === NormalPayrollPeriod.Weeks2 && overtimeWatch === OvertimePayrollPeriod.Weeks1) {
      setValue('overtimePeriod', OvertimePayrollPeriod.Weeks2);
    }
  }, [overtimeWatch, payrollWatch, setValue]);

  return (
    <SettingsGroup variant="md">
      <SettingsGroup.Header>
        <SettingsGroup.Title>
          <Trans id="time_tracking_settings.heading.pay_period">Pay period</Trans>
        </SettingsGroup.Title>
      </SettingsGroup.Header>

      <SettingsGroup.Body>
        <SettingsGroup.Body.Visible variant="md">
          <OptionLabel
            label={t({
              id: 'time_tracking_settings.payroll_period.label',
              message: 'Worktime',
            })}
            apendWith={
              <Select
                {...registerOnBlur('payrollPeriod', {
                  required: t({
                    id: 'global.forms.required',
                  }),
                  valueAsNumber: true,
                })}
                id="payrollPeriod"
                placeholder={t({
                  id: 'time_tracking_settings.payroll_period.label',
                })}
                options={_.slice(payrollPeriodOptions, 0, 3)}
                size="sm"
                error={!!errors.payrollPeriod}
                errorMessage={errors.payrollPeriod?.message}
                sx={{ maxWidth: '188px' }}
              />
            }
            withDivider
          />
          <OptionLabel
            label={
              <LabelWithBadge
                label={t({ id: 'time_tracking_settings.overtime_period.label', message: 'Overtime' })}
                badgeText={t({ id: 'settings.scheduling' })}
                tooltipText={t({ id: 'settings.tooltip_scheduling' })}
              />
            }
            apendWith={
              <Select
                {...registerOnBlur('overtimePeriod', {
                  required: t({
                    id: 'global.forms.required',
                  }),
                  valueAsNumber: true,
                })}
                id="overtimePeriod"
                placeholder={t({
                  id: 'time_tracking_settings.overtime_period.label',
                })}
                options={overtimePayrollPeriodOptions}
                size="sm"
                error={!!errors.overtimePeriod}
                errorMessage={errors.overtimePeriod?.message}
                sx={{ maxWidth: '188px' }}
              />
            }
          />
        </SettingsGroup.Body.Visible>
      </SettingsGroup.Body>
    </SettingsGroup>
  );
};
