import { Trans } from '@lingui/macro';
import { Flex } from 'theme-ui';

import { ExternalLoginActionSource, ExternalLoginProvider } from 'api/actions/integrations/integrationActions.types';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';
import { LineDivider } from 'components/ui/LineDivider';
import { useMount } from 'hooks/useMount/useMount';
import { useExternalIntegrations } from 'pages/Settings/output/useExternalIntegrations';
import { useParamError } from 'pages/Settings/output/useParamError';

import { CommonExternalProviderButton } from './components/CommonExternalProviderButton';
import { GoogleButton } from './components/GoogleButton/GoogleButton';
import { useAppleIntegration } from './hooks/useAppleIntegration';

type Props = {
  isSignUp?: boolean;
};

export const IntegrationsAuthenticationFooter = ({ isSignUp }: Props) => {
  useParamError();
  const { handleAppleOnClick } = useAppleIntegration();
  const { externalIntegrations, fetchExternalIntegrations, isInitialized } = useExternalIntegrations();

  useMount(() => {
    if (!isInitialized) {
      void fetchExternalIntegrations(ExternalLoginActionSource.Login);
    }
  });

  return (
    <>
      <LineDivider>
        {isSignUp ? (
          <Trans id="sign_up.or_sign_up_with">or sign up with</Trans>
        ) : (
          <Trans id="sign_in.or_sign_in_with">or sign in with</Trans>
        )}
      </LineDivider>
      {!isInitialized && (
        <Flex sx={{ justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
          <LoadingSpinnerCss />
        </Flex>
      )}
      <Flex sx={{ flexDirection: 'row', gap: 2, justifyContent: 'center' }}>
        {externalIntegrations?.map((integration) => {
          switch (integration.provider) {
            case ExternalLoginProvider.Google:
            case ExternalLoginProvider.GoogleInewi:
            case ExternalLoginProvider.GoogleTrackTime: {
              return <GoogleButton key={integration.provider} />;
            }
            case ExternalLoginProvider.AppleLinkInewi:
            case ExternalLoginProvider.AppleLinkTrackTime:
            case ExternalLoginProvider.AppleLoginInewi:
            case ExternalLoginProvider.AppleLoginTrackTime: {
              return (
                <CommonExternalProviderButton
                  key={integration.provider}
                  integration={integration}
                  onClick={handleAppleOnClick}
                />
              );
            }
            default: {
              return <CommonExternalProviderButton key={integration.provider} integration={integration} />;
            }
          }
        })}
      </Flex>
    </>
  );
};
