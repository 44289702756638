/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { useEffect } from 'react';

type Callback<D = any> = (data?: D) => void;
type UnsubId = string;
type Options<D = any> = {
  callback: Callback<D>;
  priority?: number;
  once?: boolean;
  unsubId?: UnsubId;
};

export type Subscriber<D = any> = Callback<D> | Options<D>;
type Subscribers<T, D> = Map<T, Subscriber<D>[]>;

export type Pubsub<T = string, D = any> = {
  /**
   * Removes the event listener.
   * @param {T} eventName
   * @param {Callback<D> | Options<D> | UnsubId} callback Callback function, subscriber object or unsubId.
   */
  off: (eventName: T, callback: Subscriber<D> | UnsubId) => void;
  /**
   * Adds a event listener.
   *
   * @param {T} eventName Name of the event to subscribe to.
   * @param {Callback<D> | Options<D>} callback Callback function or subscribsion details object.
   *
   * @return {() => void} Return a function that when called, will remove the event listener.
   */
  on: (eventName: T, callback: Subscriber<D>) => () => void;
  /**
   * Emits a event.
   *
   * @param {T} eventName Name of the event to emit.
   * @param {D | undefined} data Value that will be passed as a argument to the event listeners callbacks.
   */
  emit: (eventName: T, data?: D) => void;
};

/**
 * Creates a pubsub communication interface.
 *
 * @param {Map<T, (Callback | Options)[]>} subscribers Map of subscribers.
 *
 * @return { Pubsub<T>} Pubsub interface, T is the type of the eventNames that the interface will inforce.
 *
 * @see Pubsub
 */

export function pubsubFactory<T = string, D = any>(subscribersMap: Subscribers<T, D> = new Map()): Pubsub<T, D> {
  const subscribers = subscribersMap;

  return {
    off(eventName, callback) {
      // if (process.env.NODE_ENV !== 'production') {
      //   console.table({
      //     ACTION: 'off',
      //     eventName,
      //     callback,
      //   });
      // }
      const eventSubscribers = subscribers.get(eventName);

      if (!eventSubscribers) return;

      const filteredEventSubscribers = eventSubscribers.filter((subscriber) => {
        if ((typeof callback === 'string' || callback instanceof String) && typeof subscriber !== 'function') {
          const { unsubId } = subscriber;
          return unsubId !== callback;
        }

        return subscriber !== callback;
      });

      if (filteredEventSubscribers.length) {
        subscribers.set(eventName, filteredEventSubscribers);
        return;
      }

      subscribers.delete(eventName);
    },
    on(eventName, callback) {
      // if (process.env.NODE_ENV !== 'production') {
      //   console.table({
      //     ACTION: 'on',
      //     eventName,
      //     callback,
      //   });
      // }
      const eventSubscribers = subscribers.get(eventName);

      const updatedEventSubscribers = [...(eventSubscribers || []), callback];

      subscribers.set(eventName, updatedEventSubscribers);

      return () => this.off(eventName, callback);
    },
    emit(eventName, data) {
      const eventSubscribers = subscribers.get(eventName);
      // if (process.env.NODE_ENV !== 'production') {
      //   console.table({
      //     ACTION: 'emit',
      //     eventName,
      //     data,
      //     subscribers: eventSubscribers?.length || 0,
      //   });
      // }
      if (!eventSubscribers) {
        return;
      }
      eventSubscribers
        .sort((a, b) => {
          const aPriority = typeof a === 'function' ? 0 : a.priority || 0;
          const bPriority = typeof b === 'function' ? 0 : b.priority || 0;
          return bPriority - aPriority;
        })
        .forEach((subscriber) => {
          if (typeof subscriber === 'function') {
            subscriber(data);
            return;
          }
          const { callback, once } = subscriber;

          callback(data);

          if (!once) return;

          this.off(eventName, subscriber);
        });
    },
  };
}

/**
 * Creates a hook that can subscribe to events of the provided pubsub instance.
 *
 * @param {Pubsub} pubsubInstance Pubsub instance from which the events are emited.
 * @return Returns the created hook.
 */
export function factoryUseEventListener<T = string, D = any>(pubsubInstance: Pubsub<T, D>) {
  function usePubsubEventListener(eventName: T | T[] | null, callback: Subscriber<D>) {
    useEffect(() => {
      if (!eventName) return undefined;
      const isEventNameArray = _.isArray(eventName);

      if (!isEventNameArray) return pubsubInstance.on(eventName, callback);

      if (!eventName.length) return undefined;

      const unsubscribes = eventName.map((event) => pubsubInstance.on(event, callback));

      return () => {
        unsubscribes.forEach((unsubscribe) => unsubscribe());
      };
    }, [callback, eventName]);
  }
  return usePubsubEventListener;
}
