import React from 'react';
import { Heading, HeadingProps } from 'theme-ui';

type TitleProps = HeadingProps;

export const Title = (props: TitleProps): React.ReactElement => (
  <Heading
    {...props}
    as="h1"
    variant="heading3"
    sx={{ pb: '1px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
  />
);
