import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { memo } from 'react';
import { generatePath } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { ElementGroup } from 'components/ui/ElementGroup';
import { APP_LINK_BASENAME } from 'constants/common';
import { PATH, TO } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useCalendarTabPermissions } from 'pages/Calendar/output/useCalendarTabPermissions';
import { dateRangeFilterAtom } from 'state/filters';
import { languageSelector } from 'state/recoilState';
import { mapDatesToParams } from 'utils/mapDatesToParams/mapDatesToParams';

import { MenuButton, MenuButtonProps } from './MenuButton';

const MenuButtonWithDropdown = withDropdown(MenuButton);

export const Menu = memo(() => {
  const language = useRecoilValue(languageSelector);
  const dates = useRecoilValue(dateRangeFilterAtom);
  const dateParams = mapDatesToParams(dates);

  useLingui();

  const permissions = useAppPermissions();
  const { calendarBaseRoute } = useCalendarTabPermissions();

  const { systemManagement, modulesManagement, modules } = permissions;
  const { AccessKioskMode } = systemManagement;

  const timeTrackingLinks = [
    ...(modules.TimeTracking
      ? [
          {
            label: t({
              id: 'navbar.menu.clock_log',
              message: 'Clock Log',
            }),
            to: generatePath(PATH.CLOCK_LOG__ALL__START_DATE_UNIX__END_DATE_UNIX[language], dateParams),
            matchPattern: `${TO.CLOCK_LOG__TYPE[language]}/*`,
          },
        ]
      : []),
    ...(modulesManagement.TimeTracking
      ? [
          {
            label: t({
              id: 'navbar.menu.work_statuses',
              message: 'Work Statuses',
            }),
            to: TO.TIME_TRACKING__WORK_STATUSES[language],
          },
          {
            label: t({
              id: 'navbar.menu.locations',
              message: 'Locations',
            }),
            to: TO.TIME_TRACKING__LOCATIONS[language],
          },
          {
            label: t({
              id: 'navbar.menu.time_clocks',
              message: 'Time Clocks',
            }),
            to: TO.TIME_TRACKING__TIME_CLOCKS[language],
          },
        ]
      : []),
    ...(AccessKioskMode
      ? [
          {
            label: t({
              id: 'navbar.menu.kiosk',
              message: 'Kiosk Mode',
            }),

            prependWithDivider: true,
            to: TO.KIOSK[language],
            apendWith: (
              <Icon
                type="blankOpen"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.open(`${APP_LINK_BASENAME}${TO.KIOSK[language]}`, '_blank');
                  return false;
                }}
                wrapperSx={{
                  margin: '-0.25rem',
                  padding: '0.25rem',
                  borderRadius: 'sm',
                  color: 'navbar.menu.icon.text.default',
                  transition: 'all 0.125s ease-in-out',
                  '&:hover': {
                    color: 'navbar.menu.icon.text.hover',
                    backgroundColor: 'navbar.menu.icon.bg.hover',
                  },
                }}
                tooltip={t({
                  id: 'global.open_in_new_window',
                  message: 'Open in new window',
                })}
              />
            ),
          },
        ]
      : []),
  ];

  const reportsLinks: MenuButtonProps['dropdownLinks'] = [
    {
      label: t({
        id: 'navbar.menu.attendance_list',
        message: 'Attendance List',
      }),
      to: generatePath(PATH.REPORTS__ATTENDANCE_LIST__START_DATE_UNIX__END_DATE_UNIX[language], dateParams),
      className: 'onboarding-reports-2',
    },
    {
      label: t({
        id: 'navbar.menu.timesheets',
        message: 'Timesheets',
      }),
      to: generatePath(PATH.REPORTS__TIMESHEETS__START_DATE_UNIX__END_DATE_UNIX[language], dateParams),
    },
  ];

  return (
    <ElementGroup
      marginValue={[0, null, null, null, '2px']}
      showAsList
      wrapperSx={{
        alignItems: 'center',
        '> .element-group__li-element': { minWidth: 0 },
      }}
    >
      <MenuButton icon="home" to={TO.START[language]} sx={{ display: ['none', null, null, 'inline-flex'] }} />
      <MenuButton icon="team" to={TO.TEAM[language]} className="onboarding-teammates-1">
        <Trans id="navbar.menu.team">Team</Trans>
      </MenuButton>
      {(modules.Schedule || modules.Requests) && (
        <MenuButton
          icon="calendar"
          to={generatePath(calendarBaseRoute[language], dateParams)}
          matchPattern={`${TO.CALENDAR__TYPE[language]}/*`}
          className="onboarding-schedule-1"
        >
          <Trans id="navbar.menu.calendar">Calendar</Trans>
        </MenuButton>
      )}
      {modules.Requests && (
        <MenuButton
          icon="requests"
          to={generatePath(PATH.REQUESTS__GROUPED__START_DATE_UNIX__END_DATE_UNIX[language], dateParams)}
          matchPattern={`${TO.REQUESTS[language]}/*`}
        >
          <Trans id="navbar.menu.requests">Requests</Trans>
        </MenuButton>
      )}
      {(modules.TimeTracking || systemManagement.AccessKioskMode) && (
        <MenuButtonWithDropdown
          autoInitialize
          popperProps={{
            withPopperState: true,
            hideOnReferenceHidden: false,
          }}
          dropdownProps={{
            links: timeTrackingLinks,
          }}
          icon="timeClock"
          dropdownLinks={timeTrackingLinks}
        >
          <Trans id="navbar.menu.time_tracking">Time Tracking</Trans>
        </MenuButtonWithDropdown>
      )}
      {modules.TimeTracking && (
        <Flex className="onboarding-reports-1">
          <MenuButtonWithDropdown
            autoInitialize
            popperProps={{
              withPopperState: true,
              hideOnReferenceHidden: false,
            }}
            dropdownProps={{
              links: reportsLinks,
            }}
            dropdownLinks={reportsLinks}
            icon="reports"
          >
            <Trans id="navbar.menu.reports">Reports</Trans>
          </MenuButtonWithDropdown>
        </Flex>
      )}
    </ElementGroup>
  );
});
