import { Action, DefaultErrorMessageEnum } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import { FetchNotifications } from './notificationActionsTypes';

export function fetchNotifications(): Action<FetchNotifications> {
  return {
    method: 'GET',
    endpoint: API_ENDPOINTS.notification,
    config: {
      skipResend: true,
      customDefaultErrorMessage: DefaultErrorMessageEnum.None,
    },
  };
}
