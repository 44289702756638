/** @jsxImportSource theme-ui */
import React, { forwardRef } from 'react';

import { FileUpload } from './FileUpload';
import { AttachmentProps } from './types';

export const Attachment = forwardRef<HTMLInputElement, AttachmentProps>(
  ({ name, defaultValue, variant = 'default' }: AttachmentProps, ref): React.ReactElement => (
    <FileUpload isStatic ref={ref} name={name} defaultValue={defaultValue} attachmentVariant={variant} />
  ),
);
