import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from 'theme-ui';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { mergeRefs } from 'utils/mergeRefs';
import { setNativeValue } from 'utils/setNativeValue';
import { ScheduleWizardSelectedDays } from 'api/actions/calendar/calendarActions.types';
import { Button } from 'components/ui/Buttons';
import { startingWeekDaySelector } from 'state/organizationSession';
import { StartingWeekDay } from 'api/actions/organizationSession/organizationSessionActions.types';

type Props = React.HTMLAttributes<HTMLInputElement>;

export const WeekdaysPicker = React.forwardRef<HTMLInputElement, Props>(({ ...props }: Props, ref) => {
  const [selectedWeekdays, setSelectedWeekdays] = useState<ScheduleWizardSelectedDays[]>([]);
  const startingWeekDay = useRecoilValue(startingWeekDaySelector);

  const localWeekdays = useMemo(() => dayjs.weekdays(true), []);

  const hiddenRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (hiddenRef.current) {
      const initialValue = hiddenRef.current.value;

      if (initialValue) {
        const parsedInitialValue = initialValue.split(',').map((val) => +val);
        setSelectedWeekdays(parsedInitialValue);
      }
    }
  }, []);

  useEffect(() => {
    setNativeValue(hiddenRef, selectedWeekdays);
  }, [selectedWeekdays]);

  const handleWeekdayClick = (weekday: number) => {
    if (startingWeekDay !== null) {
      const getParsedWeekday = () => {
        if (startingWeekDay > StartingWeekDay.Sunday) {
          const newVal = weekday + startingWeekDay;
          if (newVal > 6) return 0;
          return newVal as ScheduleWizardSelectedDays;
        }

        return weekday as ScheduleWizardSelectedDays;
      };

      const parsedWeekday = getParsedWeekday();

      setSelectedWeekdays((prevWeekdays) => {
        if (prevWeekdays.includes(parsedWeekday)) {
          const filteredWeekdays = prevWeekdays.filter((prevWeekday) => prevWeekday !== parsedWeekday);
          return filteredWeekdays;
        }

        return [...prevWeekdays, parsedWeekday];
      });
    }
  };

  const isActive = useCallback(
    (weekday: number) => {
      let dayNum = weekday;

      if (startingWeekDay !== null) {
        if (startingWeekDay > StartingWeekDay.Sunday) {
          const newVal = weekday + startingWeekDay;
          if (newVal > 6) {
            dayNum = 0;
          } else {
            dayNum = newVal;
          }
        }
      }

      return selectedWeekdays.includes(dayNum);
    },
    [selectedWeekdays, startingWeekDay],
  );

  return (
    <Flex sx={{ flexWrap: 'wrap' }}>
      <input
        {...props}
        ref={mergeRefs([ref, hiddenRef])}
        style={{ width: 0, opacity: 0, position: 'absolute' }}
        readOnly
      />
      <Flex sx={{ flexWrap: 'wrap', gap: 1 }}>
        {localWeekdays.map((weekday, index) => (
          <Button
            key={weekday}
            size="sm"
            shape="rounded"
            variant="lightGrey"
            data-active={isActive(index)}
            onClick={() => handleWeekdayClick(index)}
            sx={{
              position: 'relative',
              overflow: 'hidden',
              '&[data-active="true"]': {
                outline: '1px solid',
                outlineColor: 'calendar.weekdaysPicker.outline',
                '> span': {
                  zIndex: 1,
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  inset: 0,
                  bg: 'calendar.weekdaysPicker.bg',
                  zIndex: 0,
                },
              },
            }}
          >
            {weekday}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
});
