import { useCallback, useMemo } from 'react';
import { useClient } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { fetchWebhooksAction } from 'api/actions/webhook/webhookActions';
import { webhooksAtom } from 'state/settings';
import { Webhook } from 'api/actions/webhook/webhooksActions.types';

export const useWebhooks = () => {
  const { query } = useClient();
  const [webhooks, setWebhooks] = useRecoilState(webhooksAtom);

  const fetchWebhooks = useCallback(async () => {
    const { error, payload } = await query(fetchWebhooksAction());

    if (!error && payload) {
      setWebhooks(payload);
    }
  }, [query, setWebhooks]);

  const webhooksMap = useMemo(() => {
    if (!webhooks) return null;

    const newWebhooksMap: Map<string, Webhook> = new Map();

    webhooks.forEach((item) => newWebhooksMap.set(item.id, item));

    return newWebhooksMap;
  }, [webhooks]);

  return {
    webhooks,
    setWebhooks,
    fetchWebhooks,
    webhooksMap,
  };
};
