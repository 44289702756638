import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { editWorkPositionDetailsAction } from 'api/actions/workPositions/workPositionsActions';
import { EditWorkPositionDetailsActionProps } from 'api/actions/workPositions/workPositionsActions.types';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { BasicModalFooter } from 'components/recipes/BasicModalFooter';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { workPositionsSelector } from 'state/employees';
import { createEvent } from 'utils/createEvent';
import { delay } from 'utils/delay';
import { AddEditWorkPosition } from '../../forms/AddEditWorkPosition';

export const EditWorkPositionModal: FC = () => {
  useLingui();
  const { addSnackbar } = useSnackbar();
  const { handleClose } = useModal();
  const [loading, setLoading] = useState(false);

  const workPositions = useRecoilValue(workPositionsSelector);

  const { id } = useParams() as { id: string };

  const editedWorkPositionDetails = useMemo(() => {
    if (!workPositions) {
      return null;
    }
    const editedWorkPosition = workPositions.find((workPosition) => workPosition.id === id);
    if (!editedWorkPosition) {
      return null;
    }
    return pick(editedWorkPosition, ['name']);
  }, [workPositions, id]);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { mutate } = useMutation(editWorkPositionDetailsAction);

  const submitForm = () => {
    const form = formRef.current;
    if (form) {
      const event = createEvent('submit');
      form.dispatchEvent(event);
    }
  };

  const onSubmit = useCallback(
    async (body: EditWorkPositionDetailsActionProps['workPosition']): Promise<boolean> => {
      if (editedWorkPositionDetails && isEqual(body, editedWorkPositionDetails)) {
        setLoading(false);
        addSnackbar({
          message: t({ id: 'team.edit_work_pos.no_changes', message: 'No changes to save!' }),
          variant: 'default',
        });
        return false;
      }
      const { error: submitError } = await mutate({
        workPosition: body,
        workPositionId: id,
      });
      if (!submitError) {
        if (handleClose) {
          handleClose();
        }
      }
      setLoading(false);
      if (!submitError) {
        await delay(100);
        addSnackbar({
          message: t({ id: 'team.edit_work_pos.edited', message: 'Position successfully edited!' }),
          variant: 'success',
        });
      }
      return true;
    },
    [handleClose, mutate, id, editedWorkPositionDetails, addSnackbar],
  );

  const handleSave = () => {
    setLoading(true);
    submitForm();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <Trans id="team.edit_work_pos">Edit position</Trans>
        </Modal.Title>
      </Modal.Header>
      {!editedWorkPositionDetails ? (
        <CenteredLoadingSpinner />
      ) : (
        <>
          <Modal.Body>
            <AddEditWorkPosition
              defaultValues={editedWorkPositionDetails}
              ref={formRef}
              onSubmit={onSubmit}
              setLoading={setLoading}
            />
          </Modal.Body>
          <BasicModalFooter
            buttons={[
              {
                isLoading: loading,
                onClick: handleSave,
                variant: 'primary',
                children: t({ id: 'save', message: 'Save' }),
              },
            ]}
          />
        </>
      )}
    </>
  );
};
