import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { MemoizedDualCalendar } from 'components/ui/DualCalendar/DualCalendar';
import { minCreateDateUnixSelector } from 'state/userSession';
import { dateTime } from 'utils/dateTime';
import { dualCalendarRegisterOptionsFactory } from 'utils/dualCalendarRegisterOptionsFactory';
import { AddRequestExtendedFormContext } from '../../../../../../../types';

export const Calendar = (): React.ReactElement => {
  const minCreateDateUnix = useRecoilValue(minCreateDateUnixSelector);
  const { register, setError, clearErrors } = useFormContext<AddRequestExtendedFormContext>();

  const setCalendarErrorCallback = useCallback(() => {
    setError('calendarInternalError', {
      type: 'required',
    });
  }, [setError]);

  const clearCalendarErrorCallback = useCallback(() => {
    clearErrors('calendarInternalError');
  }, [clearErrors]);

  return (
    <MemoizedDualCalendar
      {...register('dateTimeDetails.date', dualCalendarRegisterOptionsFactory({ isRange: false }))}
      showTime
      onValidError={setCalendarErrorCallback}
      onClearError={clearCalendarErrorCallback}
      resetDays
      {...(minCreateDateUnix && { minDate: dateTime(minCreateDateUnix) })}
    />
  );
};
