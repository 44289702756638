import { FC, Suspense, lazy } from 'react';

const LazyTypeDetails = lazy(() =>
  import('./components/RequestsUsageOverviewTypeDetails').then(({ RequestsUsageOverviewTypeDetails }) => ({
    default: RequestsUsageOverviewTypeDetails,
  })),
);

export const RequestsUsageOverviewTypeDetailsRenderer: FC = () => (
  <Suspense fallback={<></>}>
    <LazyTypeDetails />
  </Suspense>
);
