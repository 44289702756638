import React from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { DayEmptyProps, DayProps } from './types';

type Props = DayProps;

const defaultProps: Partial<Props> = {
  rangeStart: false,
  rangeStop: false,
  inRange: false,
  disabled: false,
  selected: false,
  today: false,
};

export const Day = ({
  dayNumber,
  onClick,
  selected,
  disabled,
  inRange,
  rangeStart,
  rangeStop,
  today,
  variant,
}: Props): React.ReactElement => {
  const internalOnClick = () => {
    if (!disabled) {
      onClick(dayNumber);
    }
  };

  const dayInteractiveSx: ThemeUIStyleObject = {
    border: '1px solid',
    borderColor: 'datePicker.calendar.dayInteractive.border.default',
    cursor: 'pointer',
    ...(disabled && {
      opacity: 0.2,
      cursor: 'not-allowed',
      '&:hover, &:focus': {},
    }),
    ...(inRange && {
      backgroundColor: 'datePicker.calendar.dayInteractive.background.inRange',
      borderColor: 'datePicker.calendar.dayInteractive.border.inRange',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    }),
    ...(rangeStart && {
      borderTopLeftRadius: 'pill',
      borderBottomLeftRadius: 'pill',
      borderLeftWidth: '1px',
    }),
    ...(rangeStop && {
      borderTopRightRadius: 'pill',
      borderBottomRightRadius: 'pill',
      borderRightWidth: '1px',
    }),
    ...(today && {
      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: '0.25rem',
        height: '2px',
        width: '33.33%',
        bg: 'datePicker.calendar.dayInteractive.background.today',
        borderRadius: '0.125rem',
      },
    }),
    ...(!disabled && {
      '&:hover > div': {
        backgroundColor: selected
          ? 'datePicker.calendar.dayInteractive.background.notDisabledHoverSelected'
          : 'datePicker.calendar.dayInteractive.background.notDisabledHoverNotSelected',
        fontWeight: 'bold',
        ...(!selected && { color: 'datePicker.calendar.dayInteractive.text' }),
      },
    }),
  };

  const dayInnerSx: ThemeUIStyleObject = {
    height: '34px',
    width: '34px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'pill',
    border: '2px solid',
    borderColor: 'datePicker.calendar.dayInner.border.default',
    ...(selected && {
      backgroundColor: 'datePicker.calendar.dayInner.background',
      borderColor: 'datePicker.calendar.dayInner.border.selected',
      fontWeight: 'bold',
      color: 'datePicker.calendar.dayInner.text',
    }),
  };

  return (
    <Flex variant={`forms.calendar.${variant}.day`} sx={{ ...dayInteractiveSx }} onClick={internalOnClick}>
      <Flex sx={dayInnerSx}>{dayNumber}</Flex>
    </Flex>
  );
};

Day.defaultProps = defaultProps;

export const DayEmpty = ({ variant }: DayEmptyProps): React.ReactElement => (
  <Flex variant={`forms.calendar.${variant}.day`} sx={{ height: '0', m: 0 }} />
);
