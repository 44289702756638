import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { Body } from './components/Body';
import { Buttons } from './components/Buttons/Buttons';
import { Tags } from './components/Tags';
import { integrationConnectedSx, integrationExpiredSx } from './styles/styles';
import { IntegrationTileElementsProps } from './types';

type Props = FlexProps & Pick<IntegrationTileElementsProps, 'isActive' | 'isExpired'>;

type IntegrationTileContainerProps = Props;

type IntegrationTileContainerComponent = React.FC<IntegrationTileContainerProps> & {
  Tags: typeof Tags;
  Body: typeof Body;
  Buttons: typeof Buttons;
};

export const IntegrationTile: IntegrationTileContainerComponent = ({
  children,
  isActive,
  isExpired = false,
}: Props): React.ReactElement => (
  <Flex
    variant="settings.elements.integrationBox"
    sx={{
      ...(isActive && !isExpired && integrationConnectedSx),
      ...(isExpired && integrationExpiredSx),
    }}
  >
    {children}
  </Flex>
);

IntegrationTile.Tags = Tags;
IntegrationTile.Body = Body;
IntegrationTile.Buttons = Buttons;
