import { useMemo, useCallback, useRef } from 'react';
import { RecoilState } from 'recoil';

import { useDateRangeFilter } from 'layouts/AuthorizedApp/AsideFilters/hooks/useDateRangeFilter';
import { dateTime } from 'utils/dateTime';
import { DateRangeFilterAtom } from 'state/filters';

export enum DateRange {
  CUSTOM = 0,
  CURRENT_WEEK = 1,
  CURRENT_MONTH = 2,
  LAST_SEVEN_DAYS = 3,
  LAST_WEEK = 4,
  LAST_MONTH = 5,
}

export const useDateRangeRadioPicker = (dateRangeAtom?: RecoilState<DateRangeFilterAtom | null>) => {
  const { dates, multipleYearsInRangeFilter } = useDateRangeFilter(dateRangeAtom);
  const currentDate = useMemo(() => dateTime(undefined, { utc: true }), []);

  const dateRangeMapRef = useRef(
    new Map<DateRange, [number, number] | null>([
      [DateRange.CUSTOM, dates ? [dates.startDateUnix, dates.endDateUnix] : null],
      [
        DateRange.CURRENT_WEEK,
        [currentDate.startOf('week').startOf('day').unix(), currentDate.endOf('week').startOf('day').unix()],
      ],
      [
        DateRange.CURRENT_MONTH,
        [currentDate.startOf('month').startOf('day').unix(), currentDate.endOf('month').startOf('day').unix()],
      ],
      [
        DateRange.LAST_SEVEN_DAYS,
        [
          currentDate.add(-7, 'day').startOf('day').startOf('day').unix(),
          currentDate.add(-1, 'day').endOf('day').startOf('day').unix(),
        ],
      ],
      [
        DateRange.LAST_WEEK,
        [
          currentDate.add(-1, 'week').startOf('week').startOf('day').unix(),
          currentDate.add(-1, 'week').endOf('week').startOf('day').unix(),
        ],
      ],
      [
        DateRange.LAST_MONTH,
        [
          currentDate.add(-1, 'month').startOf('month').startOf('day').unix(),
          currentDate.add(-1, 'month').endOf('month').startOf('day').unix(),
        ],
      ],
    ]),
  );

  const getDateRangeMap = useCallback(() => {
    const dateRangeMap = dateRangeMapRef.current;
    if (!dateRangeMap.get(DateRange.CUSTOM) && dates) {
      dateRangeMap.set(DateRange.CUSTOM, [dates.startDateUnix, dates.endDateUnix]);
    }
    return dateRangeMap;
  }, [dates]);

  const getAdditionalLabel = useCallback(
    (key: DateRange) => {
      const dateRangeMap = getDateRangeMap();
      const dateFormat = multipleYearsInRangeFilter ? 'll' : 'MMM D';

      const dateRange = dateRangeMap.get(key);

      if (!dateRange) return '';

      const [startDateUnix, endDateUnix] = dateRange;

      const startDate = dateTime(startDateUnix, { utc: true });
      const endDate = dateTime(endDateUnix, { utc: true });
      return `${startDate.format(dateFormat)} - ${endDate.format(dateFormat)}`;
    },
    [getDateRangeMap, multipleYearsInRangeFilter],
  );

  const translateKeyToDate = useCallback((key: DateRange) => getDateRangeMap().get(key), [getDateRangeMap]);

  return { getAdditionalLabel, translateKeyToDate };
};
