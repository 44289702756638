import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { CHAT_BOT } from 'constants/chat';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { chatWidthSelector, chatWindowIdsSelector, removeChatWindowSelector } from 'state/chat';
import { windowSizeAtom } from 'state/recoilState';

export const ChatWidthObserver = (): null => {
  const { width } = useRecoilValue(windowSizeAtom);
  const chatWidth = useRecoilValue(chatWidthSelector);
  const chatWindowIds = useRecoilValue(chatWindowIdsSelector);

  const getFirstUserId = useCallback(() => _.find(chatWindowIds, (id) => id !== CHAT_BOT), [chatWindowIds]);

  const removeChatWindow = useRecoilCallback(({ set }) => () => {
    set(removeChatWindowSelector(getFirstUserId()), null);
  });

  const removeChatWindowRef = useCallbackRef(removeChatWindow);

  useEffect(() => {
    if (width && chatWidth > width) {
      removeChatWindowRef.current();
    }
  }, [chatWidth, removeChatWindowRef, width]);

  return null;
};
