import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import { defaultNotificationsSettingsAtom, resetFormButtonAtom } from 'state/settings';

export const useDefaultNotificationsCleanUp = () => {
  const resetFormButton = useResetRecoilState(resetFormButtonAtom);
  const resetDefaultNotificationsSettings = useResetRecoilState(defaultNotificationsSettingsAtom);

  useEffect(
    () => () => {
      resetFormButton();
      resetDefaultNotificationsSettings();
    },
    [resetDefaultNotificationsSettings, resetFormButton],
  );
};
