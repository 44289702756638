import React from 'react';

import { Icon, IconProps } from 'components/Icon/Icon';
import { Button, ButtonProps } from 'components/ui/Buttons';

type Props = ButtonProps & {
  iconType: IconProps['type'];
};

export const MenuButton = ({ onClick, sx, iconType, ...props }: Props): React.ReactElement => (
  <Button
    onClick={onClick}
    variant="grey"
    shape="rounded"
    size="sm"
    sx={{
      ...(sx && sx),
    }}
    {...props}
  >
    <Icon type={iconType} />
  </Button>
);
