export interface SignInPayload {
  email?: string;
  password?: string;
}
export interface SignUpPayload {
  email?: string;
  password?: string;
  newsletterSubscription?: boolean;
}
export type InvitationEmailDetails = {
  email: string;
  emailTitle: string;
  sentAt: number;
};
export type SignUpResponse = {
  accessToken: string;
  innerCode?: SignUpErrorCodes;
};

export type SignUpResponseErrorData = InvitationEmailDetails;
export interface AuthorizeResponse {
  accessToken: string;
}
export interface InvitationResendPayload {
  email: string;
  note?: string;
}
export interface InvitationJoinRequestPayload {
  email: string;
  userName: string;
  note?: string;
}

export enum SignUpErrorCodes {
  USER_ALREADY_REGISTER = 2300,
  USER_CREATION_ERROR = 2301,
  USER_INVITED = 2307,
}
export enum RefreshAccessTokenAccountTypeCodes {
  SIGN_UP_IN_PROGRESS = 1000,
  KIOSK = 1001,
}

export type RefreshAccessTokenResponse = AuthorizeResponse & { accountType?: number; email?: string };
export type SignOutResponse = Record<string, unknown>;
