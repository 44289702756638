export const getDurationObj = (duration: number) => {
  let currentDurration = duration;
  let days = 0;
  let hours = 0;
  let minutes = 0;

  const secondsInDay = 86400;
  const hasDayOrMore = currentDurration >= secondsInDay;

  if (hasDayOrMore) {
    days = Math.floor(currentDurration / secondsInDay);
    currentDurration -= days * secondsInDay;
  }

  const secondsInHour = 3600;
  const hasHourOrMore = currentDurration >= secondsInHour;

  if (hasHourOrMore) {
    hours = Math.floor(currentDurration / secondsInHour);
    currentDurration -= hours * secondsInHour;
  }

  const secondsInMinute = 60;
  const hasMinnuteOrMore = currentDurration >= secondsInMinute;

  if (hasMinnuteOrMore) {
    minutes = Math.floor(currentDurration / secondsInMinute);
  }

  return {
    days,
    hours,
    minutes,
  };
};
