import { Trans, t } from '@lingui/macro';
import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text, ThemeUICSSObject, ThemeUIStyleObject } from 'theme-ui';

import { TimeEventHistoryChunk } from 'api/actions/timeEvent/timeEventActions.types';
import { Icon } from 'components/Icon/Icon';
import { WorkStatusBadge } from 'components/recipes/WorkStatusBadge';
import { withTooltip } from 'components/ui/Tooltip/withTooltip';
import { organizationSessionAtom } from 'state/organizationSession';
import { getEventFormattedDateTime } from '../../../../../utils/getEventFormattedDateTime';
import { Note } from '../../../../Note';

type Props = {
  previous: TimeEventHistoryChunk['previous'];
  sx?: ThemeUIStyleObject;
};

const boldGreyTextSx: ThemeUICSSObject = {
  fontWeight: 'bold',
};
const IconWithTooltip = withTooltip(Icon);
export const VersionContainer = ({ previous, sx }: Props): React.ReactElement | null => {
  const [isOpen, setIsOpen] = useState(false);

  const organizationSession = useRecoilValue(organizationSessionAtom);
  const typeName = useMemo(
    () => organizationSession?.timeEventTypes.find((timeEventType) => timeEventType.id === previous?.typeId)?.name,
    [organizationSession?.timeEventTypes, previous?.typeId],
  );

  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  if (!previous || !typeName) return null;

  const { timeUtc, typeId, isEnd, note, isOffline } = previous;

  const { eventDate, eventTime } = getEventFormattedDateTime(timeUtc);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        ...(sx && sx),
      }}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          cursor: 'pointer',
          width: '100%',
          borderRadius: 'xs',
          p: '4px 8px',
          ...(isOpen
            ? { bg: 'clockLog.eventHistory.btn.isOpen', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
            : { bg: 'clockLog.eventHistory.btn.notOpen' }),
        }}
        onClick={toggleOpen}
      >
        <Text sx={{ fontWeight: 'bold' }}>
          <Trans id="clock_log.previous_version">Previous version</Trans>
        </Text>
        <Icon type={isOpen ? 'chevronUp' : 'chevronDown'} />
      </Flex>

      {isOpen && (
        <Flex
          sx={{
            flexDirection: 'column',
            gap: 1,
            fontSize: 2,
            p: 2,
            bg: 'clockLog.eventHistory.versionContainer',
            borderBottomLeftRadius: 'xs',
            borderBottomRightRadius: 'xs',
          }}
        >
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text sx={boldGreyTextSx}>
              <Trans id="clock_log.date_time">Date & time</Trans>
            </Text>
            <Text>{`${eventDate}, ${eventTime}`}</Text>
          </Flex>

          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text sx={boldGreyTextSx}>
              <Trans id="clock_log.type">Type</Trans>
            </Text>
            <Flex sx={{ gap: 1, maxWidth: '50%' }}>
              {isOffline && (
                <IconWithTooltip
                  tooltip={t({ id: 'clock_log.offline_mode', message: 'Offline event' })}
                  size={24}
                  type="cloudOff"
                  fill="alphas.darker8"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bg: 'whites0',
                    border: '1px solid',
                    borderColor: 'alphas.darker8',
                    borderRadius: 'xs',
                  }}
                />
              )}
              <WorkStatusBadge workStatus={{ id: typeId, name: typeName }} isEnd={isEnd} size="sm" />
            </Flex>
          </Flex>

          {note && (
            <Flex sx={{ justifyContent: 'space-between' }}>
              <Text sx={boldGreyTextSx}>
                <Trans id="clock_log.note">Note</Trans>
              </Text>
              <Note note={note} />
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};
