import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useMemo } from 'react';
import { Flex, FlexProps, Text, TextProps, ThemeUIStyleObject } from 'theme-ui';

import { TimeEventType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { Icon, IconProps } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { DEFAULT_WORK_STATUSES_IDS } from 'constants/common';

type Props = {
  workStatus: Pick<TimeEventType, 'id' | 'name'>;
  isEnd?: boolean;
  size?: 'default' | 'sm' | 'xs' | 'attendance';
  active?: boolean;
  sx?: ThemeUIStyleObject;
  additionalDescription?: string;
  taskName?: string;
  iconProps?: Partial<IconProps>;
  textEllipsisProps?: TextProps;
} & FlexProps;

export const WORK_STATUS_VARIANT: Record<
  string,
  { variant: 'clockIn' | 'clockOut' | 'task' | 'break' | 'late' | 'custom'; iconType?: Icons }
> = {
  [DEFAULT_WORK_STATUSES_IDS.ENTER]: { variant: 'clockIn' },
  [DEFAULT_WORK_STATUSES_IDS.EXIT]: { variant: 'clockOut' },
  [DEFAULT_WORK_STATUSES_IDS.TASK]: { variant: 'task', iconType: 'task' },
  [DEFAULT_WORK_STATUSES_IDS.PAID_BREAK]: { variant: 'break', iconType: 'break' },
  [DEFAULT_WORK_STATUSES_IDS.UNPAID_BREAK]: { variant: 'break', iconType: 'break' },
  [DEFAULT_WORK_STATUSES_IDS.LATE]: { variant: 'late' },
  [DEFAULT_WORK_STATUSES_IDS.ATTENDANCE_PILL]: { variant: 'custom' },
};

const ICON_SIZE: Record<Required<Props>['size'], number> = {
  xs: 15,
  attendance: 18,
  sm: 20,
  default: 24,
};

const WorkStatusBadgeDescription: FC<
  { iconType?: Icons } & Pick<Props, 'children' | 'sx' | 'textEllipsisProps' | 'size'>
> = ({ children, sx, iconType, size = 'default', textEllipsisProps }) => (
  <Flex
    sx={{
      alignItems: 'center',
      height: '100%',
      borderLeft: '1px solid',
      ...sx,
    }}
  >
    {iconType && <Icon wrapperSx={{ flexShrink: 0 }} type={iconType} size={ICON_SIZE[size]} />}
    <TextEllipsis
      as="span"
      data-text
      className="work-status-badge__text"
      sx={{ variant: `workStatus.size.${size}`, width: 'auto' }}
      {...textEllipsisProps}
    >
      {children}
    </TextEllipsis>
  </Flex>
);

export const WorkStatusBadge: FC<Props> = ({
  workStatus: { id, name },
  isEnd,
  size: propSize = 'default',
  active = false,
  sx,
  additionalDescription,
  taskName,
  iconProps,
  textEllipsisProps,
  ...props
}) => {
  useLingui();
  const workStatus = useMemo(
    () => WORK_STATUS_VARIANT?.[id.toLocaleLowerCase()] || { variant: 'custom', iconType: 'workStatus' },
    [id],
  );
  const variant = useMemo(() => {
    let variantPath: string = workStatus.variant;
    if (['break', 'custom', 'task'].includes(variantPath)) {
      variantPath = isEnd ? `${variantPath}.end` : `${variantPath}.start`;
    }
    if (active) variantPath += '.active';
    if (!active) variantPath += '.default';
    return variantPath;
  }, [workStatus?.variant, active, isEnd]);
  const size = ICON_SIZE[propSize];

  return (
    <Flex
      title={t({
        id: name,
        message: name,
      })}
      variant={`workStatus.${variant}`}
      sx={{ ...sx, alignItems: 'center' }}
      className="work-status-badge"
      {...props}
    >
      <Icon
        className="icon"
        wrapperSx={{ flexShrink: 0, justifyContent: 'center' }}
        type={isEnd ? 'stopCircleDoubleColor' : 'playCircleDoubleColor'}
        size={size}
        {...iconProps}
      />
      <WorkStatusBadgeDescription
        textEllipsisProps={textEllipsisProps}
        sx={{
          borderColor: active ? 'white' : `workStatus.border.${workStatus.variant}`,
        }}
        size={propSize}
        iconType={workStatus?.iconType}
      >
        {t({ id: name, message: name })}
        {additionalDescription && <Text sx={{ fontWeight: 'normal', ml: 1 }}>{additionalDescription}</Text>}
      </WorkStatusBadgeDescription>
      {!!taskName && (
        <WorkStatusBadgeDescription
          textEllipsisProps={textEllipsisProps}
          sx={{
            borderColor: active ? 'white' : `workStatus.border.${workStatus.variant}`,
          }}
          size={propSize}
          iconType="task"
        >
          {t({ id: taskName, message: taskName })}
        </WorkStatusBadgeDescription>
      )}
    </Flex>
  );
};
