import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { useFormContext } from 'react-hook-form';

import { requestAvailableOvertime, requestTimeOffRemainingLimitsAction } from 'api/actions/requests/requestsActions';
import { RequestActionType } from 'api/actions/requests/requestsActions.types';
import { TIME_LIMIT_DEBOUNCE_TIME } from 'constants/requests';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { AddRequestExtendedFormContext } from '../../../../../../../types';
import { useAddRequest } from '../../../../../hooks/useAddRequest';
import { useTimeOffFormState } from '../hooks/useTimeOffFormState';

export const LimitsObserver = (): null => {
  const { query: limitsQuery, abort: limitsAbort } = useParameterizedQuery(requestTimeOffRemainingLimitsAction);
  const { query: overtimeQuery, abort: overtimeAbort } = useParameterizedQuery(requestAvailableOvertime);
  const {
    selectedTeammatesIds,
    requestLimits: { requestLimits, setRequestLimits, resetRequestLimits, setRequestLimitsLoading },
    requestFormRestored: { defaultValuesRestored },
  } = useAddRequest();
  const { isDurationType, isOvertimeFreeDayType } = useTimeOffFormState();
  const { watch } = useFormContext<AddRequestExtendedFormContext>();

  const typeIdWatch = watch('details.typeId');
  const datesWatch = watch('dateTimeDetails.dates');
  const targetEventIdWatch = watch('targetEventId');
  const actionTypeWatch = watch('actionType');
  const actionRef = useCallbackRef(actionTypeWatch);
  const prevDates = usePrevious(datesWatch);
  const prevTypeIdWatch = usePrevious(typeIdWatch);

  const getTimeLimit = useMemo(
    () =>
      _.debounce(
        async (
          typeId: string,
          peopleIds: string[],
          datesUnix: number[],
          actionType: RequestActionType,
          requestId?: string,
        ) => {
          const { error, payload } =
            !isOvertimeFreeDayType && !isDurationType
              ? await limitsQuery({
                  typeId,
                  peopleIds,
                  datesUnix,
                  actionType,
                  requestId,
                })
              : await overtimeQuery({
                  typeId,
                  peopleIds,
                  datesUnix,
                  actionType,
                  requestId,
                });

          if (!error && payload) {
            setRequestLimits(payload);
            setRequestLimitsLoading(false);
          }
        },
        TIME_LIMIT_DEBOUNCE_TIME,
      ),
    [isDurationType, isOvertimeFreeDayType, limitsQuery, overtimeQuery, setRequestLimits, setRequestLimitsLoading],
  );

  // For minimized modal and we wanna activate it only when requestFormRestored turns true
  useEffect(() => {
    if (defaultValuesRestored && typeIdWatch && !_.isEmpty(datesWatch)) {
      setRequestLimitsLoading(true);
      void getTimeLimit(typeIdWatch, selectedTeammatesIds, datesWatch, actionRef.current, targetEventIdWatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValuesRestored]);

  useEffect(() => {
    if (
      datesWatch &&
      _.isArray(datesWatch) &&
      datesWatch.length === 2 &&
      typeIdWatch?.length &&
      selectedTeammatesIds &&
      (!_.isEqual(datesWatch, prevDates) || !_.isEqual(typeIdWatch, prevTypeIdWatch))
    ) {
      const [startTimeUnix, endTimeUnix] = datesWatch;

      let datesUnix: number[];

      if (!endTimeUnix || _.isNaN(endTimeUnix)) {
        datesUnix = [startTimeUnix, startTimeUnix];
      } else {
        datesUnix = datesWatch;
      }

      if (datesUnix[0] <= datesUnix[1]) {
        setRequestLimitsLoading(true);
        void getTimeLimit(typeIdWatch, selectedTeammatesIds, datesUnix, actionRef.current, targetEventIdWatch);
      }
    }
  }, [
    actionRef,
    datesWatch,
    getTimeLimit,
    prevDates,
    prevTypeIdWatch,
    selectedTeammatesIds,
    setRequestLimitsLoading,
    targetEventIdWatch,
    typeIdWatch,
  ]);

  const requestLimitsRef = useCallbackRef(requestLimits);
  const prevTypeId = usePrevious(typeIdWatch);
  const typeIdChanged = !_.isEqual(typeIdWatch, prevTypeId);
  const prevActionType = usePrevious(actionTypeWatch);
  const actionTypeChanged = !_.isEqual(actionTypeWatch, prevActionType);

  useEffect(() => {
    if (actionTypeChanged) {
      if (requestLimitsRef.current) {
        resetRequestLimits();
      }
      getTimeLimit.cancel();
      setRequestLimitsLoading(false);
    }

    if (actionTypeChanged || typeIdChanged) {
      limitsAbort();
      overtimeAbort();
    }
  }, [
    actionTypeChanged,
    getTimeLimit,
    limitsAbort,
    overtimeAbort,
    requestLimitsRef,
    resetRequestLimits,
    setRequestLimitsLoading,
    typeIdChanged,
  ]);

  useEffect(() => {
    if (prevDates && !datesWatch) {
      getTimeLimit.cancel();
      limitsAbort();
      overtimeAbort();
      setRequestLimitsLoading(false);
    }
  }, [
    datesWatch,
    getTimeLimit,
    limitsAbort,
    overtimeAbort,
    prevDates,
    requestLimitsRef,
    resetRequestLimits,
    setRequestLimitsLoading,
  ]);

  return null;
};
