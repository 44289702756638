export enum actionNames {
  ADD_REFRESH_PAGE_NOTIFICATION = 'ADD_REFRESH_PAGE_NOTIFICATION',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  REFRESH_USER_SESSION = 'REFRESH_USER_SESSION',
  REFRESH_ORGANIZATION_SESSION = 'REFRESH_ORGANIZATION_SESSION',
  REFRESH_SESSION = 'REFRESH_SESSION',
  GET_TIME_TRACKER = 'GET_TIME_TRACKER',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
}
