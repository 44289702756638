import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback, useRef } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { Flex, Link, Text } from 'theme-ui';

import { Switch } from 'components/ui/Switch';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { blockTransitionAtom } from 'state/settings';
import { SETTINGS_SPACE } from 'styles/theme/settings';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useFormCustomEvent } from '../../../hooks/useFormCustomEvent';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import { useSettingsBlockRouteTransition } from '../../../hooks/useSettingsBlockRouteTransition';
import { SettingsGroup } from '../../SettingsGroup/SettingsGroup';
import { wrapperLinkSx } from '../../styles/wrappers';
import { useNotificationsSettings } from '../hooks/useNotificationsSettings';

import { useSubmitNotifications } from './hooks/useSubmitNotifications';

export const NotificationsForm = (): React.ReactElement => {
  useLingui();
  const resetBlockTransition = useResetRecoilState(blockTransitionAtom);
  const setBlockTransition = useSetRecoilState(blockTransitionAtom);
  const { notificationsSettings, setNotificationsSettings } = useNotificationsSettings();
  // const smsLink = useHelpLink({
  //   inEwi: { pl: '/article/wiadomosci-sms-v3-szu8u2/' },
  // });

  const formRef = useRef<HTMLFormElement | null>(null);

  const { dispatchSubmitEvent, throttledDispatchSubmitEvent } = useFormSubmit(formRef);

  const handleOnChange = useCallback(() => {
    setBlockTransition((prevState) => ({
      ...prevState,
      blockLocationPathname: null,
      blockTransition: true,
    }));
    throttledDispatchSubmitEvent.cancel();
    throttledDispatchSubmitEvent();
  }, [setBlockTransition, throttledDispatchSubmitEvent]);

  const { registerOnChange, handleSubmit, getValues } = useFormCustomEvent({
    onChangeCustom: handleOnChange,
    formProps: { defaultValues: notificationsSettings || undefined, mode: 'onTouched', reValidateMode: 'onChange' },
  });

  const handleFormReset = useCallback(() => {
    setNotificationsSettings(null);
    resetBlockTransition();
  }, [resetBlockTransition, setNotificationsSettings]);

  const handleSubmitCallback = useSubmitNotifications({ handleFormReset, getCurrentFormState: getValues });

  useSettingsBlockRouteTransition(dispatchSubmitEvent, throttledDispatchSubmitEvent);

  return (
    <form ref={formRef} onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback))}>
      <Flex variant="settings.container.vertical.main">
        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title
              additionalInfo={t({
                id: 'notifications_settings.heading.schedules.additional_info',
                message: 'Receive updates when there is a new version of yours schedule published.',
              })}
            >
              <Trans id="notifications_settings.heading.schedules">Schedules</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="md">
              <Switch
                {...registerOnChange('schedulesEmails')}
                label={t({ id: 'notifications_settings.email', message: 'E-mail' })}
                bold
                size="sm"
                sx={{ py: SETTINGS_SPACE.switchWithLabel }}
              />
              {/* <Divider />
              <Switch
                {...registerOnChange('schedulesTextMessages')}
                label={t({
                  id: 'notifications_settings.text_messages',
                  message: 'Text messages',
                })}
                additionalInfo={
                  <Text>
                    <Trans id="settings.user.notifications.in_beta">Currently in BETA.</Trans>{' '}
                    <Link href={smsLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                      {t({id: 'settings.learn_more' })}
                    </Link>
                    .
                  </Text>
                }
                size="sm"
                bold
              /> */}
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>

        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title
              additionalInfo={t({
                id: 'settings.user.notifications.requests.additional_info',
                message:
                  'Receive updates when there is a new request (Supervisors, Menagers and Administrators) or when your request has been updated.',
              })}
            >
              <Trans id="settings.user.notifications.requests">Requests</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="md">
              <Switch
                {...registerOnChange('requestsEmails')}
                label={t({ id: 'notifications_settings.email' })}
                size="sm"
                bold
                sx={{ py: SETTINGS_SPACE.switchWithLabel }}
              />
              {/* <Divider />
              <Switch
                {...registerOnChange('requestsTextMessages')}
                label={t({
                  id: 'settings.user.notifications.requests.text_messages',
                  message: 'Text messages',
                })}
                additionalInfo={
                  <Text>
                    <Trans id="settings.user.notifications.in_preview">Currently in preview.</Trans>{' '}
                    <Link href={smsLink} target="_blank" rel="noopener noreferrer" sx={{ ...wrapperLinkSx }}>
                      {t({id: 'settings.learn_more' })}
                    </Link>
                    .
                  </Text>
                }
                size="sm"
                bold
              /> */}
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>

        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title
              additionalInfo={t({
                id: 'settings.user.notifications.messages.additional_info',
                message: 'Receive updates about messages from your teammates when you are not in the app.',
              })}
            >
              <Trans id="settings.user.notifications.messages">Messages</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="md">
              <Switch
                {...registerOnChange('messagesEmails')}
                label={t({ id: 'notifications_settings.email' })}
                bold
                size="sm"
                sx={{ py: SETTINGS_SPACE.switchWithLabel }}
              />
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>

        <SettingsGroup variant="sm">
          <SettingsGroup.Header>
            <SettingsGroup.Title
              additionalInfo={
                <Text>
                  <Trans id="settings.user.notifications.promotion_and_tips.additional_info">
                    Receive coupons & promotions from us and only us.
                  </Trans>{' '}
                  <Link
                    href={EXTERNAL_LINKS.NEWSLETTER_TERMS}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ ...wrapperLinkSx }}
                  >
                    <Trans id="setttings.user.notifications.promotion_and_tips.newsletter">
                      Terms of the newsletter.
                    </Trans>
                  </Link>
                  .
                </Text>
              }
            >
              <Trans id="settings.user.notifications.promotion_and_tips">Promotion and tips</Trans>
            </SettingsGroup.Title>
          </SettingsGroup.Header>

          <SettingsGroup.Body>
            <SettingsGroup.Body.Visible variant="md">
              <Switch
                {...registerOnChange('promotionsEmails')}
                label={t({ id: 'notifications_settings.email' })}
                bold
                size="sm"
                sx={{ py: SETTINGS_SPACE.switchWithLabel }}
              />
            </SettingsGroup.Body.Visible>
          </SettingsGroup.Body>
        </SettingsGroup>
      </Flex>
    </form>
  );
};
