/** @jsxImportSource theme-ui */

import { motion, Variants, MotionProps } from 'framer-motion';
import _ from 'lodash';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { FlexProps, Text, ThemeUIStyleObject } from 'theme-ui';

const errorMessageFontSizes = {
  default: 0,
  sm: '0.550rem',
};

const animationVariants: Variants = {
  default: { scale: 1 },
  error: { scale: [1, 1.025, 0.9875, 1], transition: { duration: 0.45 } },
};

type Props = Omit<React.ComponentPropsWithoutRef<'input'>, 'size'> & {
  children: React.ReactElement<FlexProps>[] | React.ReactElement<FlexProps>;
  orientation: 'row' | 'column';
  size?: 'default' | 'sm';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  gap?: string;
  columns?: 1 | 2 | 3;
  error?: boolean;
  errorMessage?: string;
  sx?: ThemeUIStyleObject;
  childrenSx?: ThemeUIStyleObject;
};

const defaultProps: Partial<Props> = {
  size: 'default',
  onClick: undefined,
  gap: '1px',
  columns: 1,
  error: undefined,
  errorMessage: undefined,
  sx: undefined,
  childrenSx: undefined,
};

export const RadioButtonGroup = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      children,
      size = 'default',
      orientation,
      onClick,
      gap,
      columns,
      error,
      errorMessage,
      sx,
      childrenSx,
      defaultValue,
      ...props
    }: Props,
    ref,
  ) => {
    const [hasError, setError] = useState<boolean | undefined>();
    const [hasErrorMessage, setErrorMessage] = useState<string | undefined>();

    const setAnimations = useCallback(() => {
      if (hasError) return 'error';
      return 'default';
    }, [hasError]);

    const animationProps: MotionProps = useMemo(
      () => ({
        initial: 'default',
        variants: animationVariants,
        animate: setAnimations(),
        exit: 'default',
        whileTap: 'default',
      }),
      [setAnimations],
    );

    const sxIsError = useMemo(
      () =>
        hasError && {
          '& button': {
            backgroundColor: 'radio.bg.error',
          },
        },
      [hasError],
    );

    useEffect(() => {
      setError(error);
      setErrorMessage(errorMessage);
    }, [error, errorMessage]);

    return (
      <motion.div
        {...animationProps}
        sx={{
          ...(orientation === 'row' && { flexWrap: 'wrap' }),
          ...sx,
          ...sxIsError,
          display: orientation === 'column' || columns ? 'flex' : 'inline-flex',
          flexDirection: orientation,
          width: columns && '100%',
          gap,
          position: 'relative',
        }}
      >
        {React.Children.map(
          children,
          (child) =>
            React.isValidElement(child) &&
            React.cloneElement(child, {
              ...props,
              ref,
              size,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              onClick: child.props.onClick || onClick,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              ...(!_.isNil(defaultValue) ? { defaultChecked: child.props.value === defaultValue } : {}),
              commonSx: {
                ...childrenSx,
                flexBasis: () =>
                  columns &&
                  gap &&
                  `calc(${columns && 100 / columns}% - ${((columns - 1) * parseInt(gap, 10)) / columns}px)`,
              },
            }),
        )}
        {hasError && (
          <Text
            as="span"
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              pr: 2,
              fontSize: errorMessageFontSizes[size],
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'radio.text.error',
            }}
          >
            {hasErrorMessage}
          </Text>
        )}
      </motion.div>
    );
  },
);

RadioButtonGroup.defaultProps = defaultProps;
