import { factoryUseEventListener, pubsubFactory } from 'utils/pubsubFactory';

export const CALENDAR_DATE_CHANGE = 'CALENDAR_DATE_CHANGE';

/**
 * Pubsub communication interface for calendar events.
 *
 * @see pubsubFactory
 */
export const calendarPubsub = pubsubFactory<string>();
/**
 * Adds a event listener.
 * @see calendarPubsub.on
 */
export const onCalendarEvent = calendarPubsub.on;
/**
 * Emits a global event.
 * @see calendarPubsub.emit
 */
export const emitCalendarEvent = calendarPubsub.emit;
/**
 * Removes the event listener.
 * @see calendarPubsub.off
 */
export const offCalendarEvent = calendarPubsub.off;

/**
 * Adds a calendar event listener.
 *
 * @param {T} eventName Name of the event to subscribe to.
 * @param {Callback<D> | Options<D>} callback Callback function or subscribsion details object.
 */
export const useCalendarEventListener = factoryUseEventListener<string>(calendarPubsub);
