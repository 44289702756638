/** @jsxImportSource theme-ui */
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilValue } from 'recoil';

import { userInfoChangeAvatarAction, userInfoDeleteAvatarAction } from 'api/actions/settings/settingsActions';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { ManageAvatar } from 'components/ManageAvatar/ManageAvatar';
import { settingsProfileFormDefaultValuesSelector } from 'state/settings';

export const AvatarForm = (): React.ReactElement => {
  useLingui();

  const profileValues = useRecoilValue(settingsProfileFormDefaultValuesSelector);

  const { mutate: mutateAvatar } = useMutation(userInfoChangeAvatarAction);
  const { mutate: mutateDeleteAvatar } = useMutation(userInfoDeleteAvatarAction);

  const clearAvatarFile = useCallback(async () => {
    const { error } = await mutateDeleteAvatar({});

    if (!error) {
      addSnackbar({
        message: t({
          id: 'settings.avatar.delete_success',
          message: 'Deleted avatar',
        }),
        variant: 'success',
      });
    }
    return true;
  }, [mutateDeleteAvatar]);

  const handleSubmitCallback = useCallback(
    async (url: string) => {
      let avatarBlob: Blob | null = null;

      if (url) {
        const avatarResp = await fetch(url);
        avatarBlob = await avatarResp.blob();
      }

      if (avatarBlob) {
        const { error } = await mutateAvatar({ avatar: avatarBlob });

        if (!error) {
          addSnackbar({
            message: t({
              id: 'settings.avatar.add_success',
              message: 'Added avatar',
            }),
            variant: 'success',
          });
        }
      }
      return true;
    },
    [mutateAvatar],
  );

  const onChangeAvatar = useCallback(
    (url?: string) => {
      if (url) {
        return handleSubmitCallback(url);
      }
      return clearAvatarFile();
    },
    [clearAvatarFile, handleSubmitCallback],
  );

  return <ManageAvatar isSrcControlled url={profileValues?.avatarUrl} onChange={onChangeAvatar} />;
};
