import { WorkStatusSendVia } from 'layouts/AuthorizedApp/AsideFilters/types';

export const ADD_EVENT_MODAL_WIDTH = '890px';

export const CLOCK_LOG_LIST_ITEM_HEIGHT = 58;
export const CLOCK_LOG_LIST_HEADER_HEIGHT = 60;

export const WORK_STATUS_TYPE_ID: Record<WorkStatusSendVia, string> = {
  [WorkStatusSendVia.MobileApp]: '254783b9-f97e-43c1-8bc0-4513ffe1ea11',
  [WorkStatusSendVia.WebApp]: '669002cb-5ca5-480c-af1d-6b9ae5816947',
  [WorkStatusSendVia.WebKiosk]: '339002cb-5ca5-480c-af1d-6b9ae5816947',
  [WorkStatusSendVia.ManuallyAdded]: '00000000-0000-0000-0000-000000000000',
  [WorkStatusSendVia.MobileTimeClock]: '',
};

export const EDIT_EVENT_FORM_ID = 'editTimeEvent';

export const GRID_PHOTOS_WIDTH = 160;
export const GRID_PHOTOS_HEIGHT = 120;
