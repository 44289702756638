import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Flex, Text } from 'theme-ui';

import { getDurationObj } from '../../../../../../utils/getDurationObj';

type Props = {
  duration: number;
};

export const TotalOf = ({ duration }: Props): React.ReactElement => {
  useLingui();
  const { days, hours, minutes } = getDurationObj(duration);

  return (
    <Flex sx={{ flexDirection: 'column', gap: 2 }}>
      <Text
        sx={{
          fontWeight: 'bold',
          fontSize: 3,
        }}
      >
        <Trans id="request.details.time_frame.total_of">Total of</Trans>
      </Text>
      <Text sx={{ fontSize: 2 }}>
        {days && days > 0 ? (
          <>
            {days} {t({ id: days > 1 ? 'days' : 'day' })}
          </>
        ) : null}{' '}
        {hours && hours > 0 ? (
          <>
            {hours} {t({ id: hours > 1 ? 'hours' : 'hour' })}
          </>
        ) : null}{' '}
        {minutes && minutes > 0 ? (
          <>
            {minutes} {t({ id: minutes > 1 ? 'minutes' : 'minute' })}
          </>
        ) : null}
      </Text>
    </Flex>
  );
};
