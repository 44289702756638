import React from 'react';
import { Flex, FlexProps, ThemeUIStyleObject } from 'theme-ui';

import { AsideCard } from './components/AsideCard';

type Props = FlexProps;

type AsideComponent = React.FunctionComponent<Props> & {
  Card: typeof AsideCard;
};

const columnSx: ThemeUIStyleObject = {
  maxWidth: '500px',
  flexGrow: 1,
  flexDirection: 'column',
  overflow: 'hidden',
  p: 4,
  gap: 6,
  alignItems: 'center',
  textAlign: 'left',
  '@media (max-width: 991px)': {
    display: 'none',
  },
};

export const Aside: AsideComponent = ({ children, ...props }: Props): React.ReactElement => (
  <Flex as="aside" {...props} sx={{ ...props.sx, ...columnSx }}>
    {children}
  </Flex>
);

Aside.Card = AsideCard;
