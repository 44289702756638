import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Heading } from 'theme-ui';

import { ExternalLoginProvider } from 'api/actions/integrations/integrationActions.types';
import { externalIntegrationsMapSelector } from 'state/settings';
import { useExternalIntegrations } from '../../hooks/useExternalIntegrations';

import { CommonExternalProviderTile } from './components/CommonExternalProviderTile';

export const ExternalProviders = (): React.ReactElement => {
  useLingui();
  const externalIntegrationsMap = useRecoilValue(externalIntegrationsMapSelector);
  const { externalIntegrations } = useExternalIntegrations();

  if (!externalIntegrations || _.isEmpty(externalIntegrations)) return <></>;

  return (
    <>
      <Heading variant="heading4" mt={4} mb={2}>
        <Trans id="settings.integrations.sign_in_with">Sign in with</Trans>
      </Heading>
      <Flex sx={{ flexWrap: 'wrap', gap: 2 }}>
        {externalIntegrations.map((integration) => {
          const { provider } = integration;

          const mapIntegration = externalIntegrationsMap.get(provider);

          if (mapIntegration) {
            return (
              <CommonExternalProviderTile
                key={ExternalLoginProvider[integration.provider]}
                integration={mapIntegration}
              />
            );
          }

          return <></>;
        })}
      </Flex>
    </>
  );
};
