import isUndefined from 'lodash/isUndefined';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import {
  scheduleWizardDisabledButton,
  scheduleWizardLoading,
  scheduleWizardOnClickCallback,
  scheduleWizardScheduleAtom,
  scheduleWizardStepAtom,
  scheduleWizardTemplateScheduleAtom,
} from 'state/calendar';
import { dateTime } from 'utils/dateTime';
import { ScheduleFormProps } from '../../forms/ScheduleForm';

export const useScheduleWizard = () => {
  const [step, setStep] = useRecoilState(scheduleWizardStepAtom);
  const [schedule, setSchedule] = useRecoilState(scheduleWizardScheduleAtom);
  const [onClickCallback, setOnClickCallback] = useRecoilState(scheduleWizardOnClickCallback);
  const [disabledButton, setDisabledButton] = useRecoilState(scheduleWizardDisabledButton);
  const [loading, setLoading] = useRecoilState(scheduleWizardLoading);
  const [scheduleTemplate, setRecoilScheduleTemplate] = useRecoilState(scheduleWizardTemplateScheduleAtom);

  const currentDateUnix = useMemo(() => dateTime(undefined).startOf('day').utc(true).unix(), []);

  const setScheduleTemplate = useCallback(
    (template: ScheduleFormProps['defaultValues']) => {
      if (!template || !template.details) return;
      setRecoilScheduleTemplate({
        ...template,
        details: {
          ...template.details,
          startDateTimeUnix: !isUndefined(template.details.startDateTimeUnix)
            ? template.details.startDateTimeUnix -
              +currentDateUnix +
              dateTime(template.details.startDateTimeUnix).utcOffset() * 60
            : undefined,
          endDateTimeUnix: !isUndefined(template.details.endDateTimeUnix)
            ? template.details.endDateTimeUnix -
              +currentDateUnix +
              dateTime(template.details.endDateTimeUnix).utcOffset() * 60
            : undefined,
        },
      });
    },
    [currentDateUnix, setRecoilScheduleTemplate],
  );

  const resetStep = useResetRecoilState(scheduleWizardStepAtom);
  const resetSchedule = useResetRecoilState(scheduleWizardScheduleAtom);
  const resetScheduleTemplate = useResetRecoilState(scheduleWizardTemplateScheduleAtom);
  const resetScheduleOnClickCallback = useResetRecoilState(scheduleWizardOnClickCallback);
  const resetScheduleDisabledButton = useResetRecoilState(scheduleWizardDisabledButton);
  const resetScheduleLoading = useResetRecoilState(scheduleWizardLoading);

  const resetWizard = useCallback(() => {
    resetStep();
    resetSchedule();
    resetScheduleTemplate();
    resetScheduleOnClickCallback();
    resetScheduleDisabledButton();
    resetScheduleLoading();
  }, [
    resetSchedule,
    resetScheduleDisabledButton,
    resetScheduleLoading,
    resetScheduleOnClickCallback,
    resetScheduleTemplate,
    resetStep,
  ]);

  return {
    step,
    schedule,
    scheduleTemplate,
    setStep,
    setSchedule,
    setScheduleTemplate,
    resetWizard,
    onClickCallback,
    setOnClickCallback,
    disabledButton,
    setDisabledButton,
    loading,
    setLoading,
  };
};
