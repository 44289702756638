import { selector } from 'recoil';
import _ from 'lodash';

import { FilterGroupNames, WorkStatusesFilters } from 'layouts/AuthorizedApp/AsideFilters/types';
import { TimeEventType } from 'api/actions/organizationSession/organizationSessionActions.types';

import { organizationSessionAtom } from './organizationSession';
import { filterGroupStateAtomFamily, parsedSearchFilterValueSelectorFamily, SEARCH_FILTER_TYPE } from './filters';

export const workStatusesSelector = selector<TimeEventType[] | null>({
  key: 'workStatuses',
  get: ({ get }) => {
    const organizationSession = get(organizationSessionAtom);
    if (!organizationSession) return null;

    return organizationSession.timeEventTypes;
  },
});

export const filteredWorkStatusesSelector = selector<TimeEventType[]>({
  key: 'filteredWorkStatuses',
  get: ({ get }) => {
    const workStatuses = get(workStatusesSelector);

    const searchFilterState = get(parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.WORK_STATUSES));
    const workStatusesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.WORK_STATUSES_STATE));

    const filterDefaultStatuses = (workStatus: TimeEventType) => !workStatus.isDefault;

    const filterWorkStatusesBySearchInput = (workStatus: TimeEventType) => {
      if (_.isEmpty(searchFilterState)) return true;

      let valid = false;
      const { name } = workStatus;

      valid = searchFilterState.some((searchableQuery) =>
        name.toLocaleLowerCase().includes(searchableQuery.toLocaleLowerCase()),
      );

      return valid;
    };

    const filterByWorkStatusState = (workStatus: TimeEventType) => {
      if (workStatusesFilterState === null) return true;

      let valid = false;
      if (workStatusesFilterState?.includes(WorkStatusesFilters.ACTIVE)) valid = !workStatus.isDeleted;
      if (workStatusesFilterState?.includes(WorkStatusesFilters.DELETED)) valid = valid || workStatus.isDeleted;
      return valid;
    };

    const filteredWorkStatuses = workStatuses
      ?.filter((workStatus) => filterDefaultStatuses(workStatus))
      .filter((workStatus) => filterWorkStatusesBySearchInput(workStatus))
      .filter((workStatus) => filterByWorkStatusState(workStatus));

    return filteredWorkStatuses || [];
  },
});
