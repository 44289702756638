import React, { useMemo } from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { settingsBorder } from 'styles/theme/settings';
import { Variants } from '../../../types';

type Props = {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  sx?: ThemeUIStyleObject;
  variant?: Variants;
  pt?: string | number;
  pb?: string | number;
  withoutBorderTop?: boolean;
  withoutBorderBottom?: boolean;
};

export const BodyVisible = ({
  variant,
  pt,
  pb,
  withoutBorderTop,
  withoutBorderBottom,
  sx,
  children,
}: Props): React.ReactElement => {
  const flexVariant = useMemo(() => {
    const container = 'settings.container.vertical';
    let currentVariant = '';

    switch (variant) {
      case 'noGap':
        return `${container}.noGap`;
      case 'sm':
        currentVariant = `${container}.sm`;
        break;
      case 'md':
        currentVariant = `${container}.md`;
        break;
      case 'lg':
        currentVariant = `${container}.lg`;
        break;
      case 'xlg':
        currentVariant = `${container}.xlg`;
        break;
      default:
        currentVariant = '';
    }

    return currentVariant;
  }, [variant]);

  return (
    <Flex
      variant={flexVariant}
      sx={{
        bg: 'alphas.darker1',
        px: '0.75rem',
        pt: pt || '0.75rem',
        pb: pb || '0.75rem',
        borderRadius: 'sm',
        ...settingsBorder,
        ...(withoutBorderTop && { borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 }),
        ...(withoutBorderBottom && { borderBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }),
        ...(sx && sx),
      }}
    >
      {children}
    </Flex>
  );
};
