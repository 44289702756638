import { t } from '@lingui/macro';
import React, { useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { downloadQrCardsAction, printQrCardsAction } from 'api/actions/employees/employeesActions';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { Button } from 'components/ui/Buttons';
import { Image } from 'components/ui/Image';
import { userSessionAtom } from 'state/userSession';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

export const YourQrCodeModal = (): React.ReactElement => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isPrintLoading, setIsPrintLoading] = useState(false);

  const { qrCodeUrl, personId } = useRecoilValue(userSessionAtom) || {};
  const { mutate: mutatePrintUserQr } = useMutation(printQrCardsAction);
  const { mutate: mutateDownloadUserQr } = useMutation(downloadQrCardsAction);

  const { baseRoute } = useModal({ wrapperSx: { height: '532px', maxHeight: '100%' } });

  if (!personId) return <Navigate to={baseRoute} relative="path" />;

  const qrData = {
    employeesIds: [personId],
    showFirstName: true,
    showLastName: true,
    showTags: true,
    showPhoto: true,
  };

  const handlePrint = async () => {
    setIsPrintLoading(true);
    await mutatePrintUserQr(qrData);
    setIsPrintLoading(false);
  };

  const handleDownload = async () => {
    setIsDownloadLoading(true);
    await mutateDownloadUserQr(qrData);
    setIsDownloadLoading(false);
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title sx={{ fontSize: [null, '1.5rem !important'] }}>
          {t({ id: 'navbar.side_menu.your_qr' })}
        </Modal.Title>

        <Flex
          sx={{
            gap: 0,
          }}
        >
          <Button
            onClick={floatingPromiseReturn(handleDownload)}
            variant="minimal"
            shape="circle"
            isLoading={isDownloadLoading}
          >
            <Icon type="download" size={24} />
          </Button>

          <Button
            onClick={floatingPromiseReturn(handlePrint)}
            variant="minimal"
            shape="circle"
            isLoading={isPrintLoading}
          >
            <Icon type="print" size={24} />
          </Button>
        </Flex>
      </Modal.Header>

      <Modal.Body sx={{ justifyContent: ['flex-start', 'center'], alignItems: 'center' }}>
        <Image
          src={qrCodeUrl}
          alt={t({ id: 'your_qr.user_qr_code', message: 'User QR Code' })}
          sx={{
            width: '400px',
            maxWidth: '100%',
            '@media (max-height: 500px) and (orientation : landscape)': {
              height: '100%',
              width: 'auto',
            },
          }}
        />
      </Modal.Body>
    </>
  );
};
