import { useRollbarPerson } from '@rollbar/react';
import { FC, useMemo } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { Modal } from 'components/Modal/output/Modal';
import { isInewi } from 'constants/common';
import { BASE_ROUTES, DATE_RANGE_PARAMS, MATCH_ALL, PATH, PATH_REL, TO } from 'constants/routes';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useAppRouting } from 'hooks/useAppRouting/useAppRouting';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { GetMobileAppsModal } from 'layouts/AuthorizedApp/Navbar/modals/GetMobileApps';
import { YourBenefits } from 'layouts/AuthorizedApp/Navbar/modals/YourBenefits/YourBenefits';
import { YourQrCodeModal } from 'layouts/AuthorizedApp/Navbar/modals/YourQrCode';
import { CalendarDayDetailsRenderer } from 'pages/Calendar/output/CalendarDayDetailsRenderer';
import { CalendarRenderer } from 'pages/Calendar/output/CalendarRenderer';
import { useCalendarTabPermissions } from 'pages/Calendar/output/useCalendarTabPermissions';
import { ClockLogRenderer } from 'pages/ClockLog/output/ClockLogRenderer';
import { Home } from 'pages/Home/output/Home';
import { LocationsRenderer } from 'pages/Locations/output/LocationsRenderer';
import { PaymentsRenderer } from 'pages/Payments/output/PaymentsRenderer';
import { DayDetailsRenderer } from 'pages/Reports/output/DayDetailsRenderer';
import { ReportsRenderer } from 'pages/Reports/output/ReportsRenderer';
import { RequestsModalRoutesRenderer } from 'pages/Requests/output/RequestsModalRoutesRenderer';
import { RequestsRenderer } from 'pages/Requests/output/RequestsRenderer';
import { RequestsUsageOverviewTypeDetailsRenderer } from 'pages/Requests/output/RequestsUsageOverviewTypeDetailsRenderer';
import { Advanced } from 'pages/Settings/output/Advanced';
import { Automations } from 'pages/Settings/output/Automations';
import { DefaultNotifications } from 'pages/Settings/output/DefaultNotifications';
import { Integrations } from 'pages/Settings/output/Integrations';
import { ManagePassword } from 'pages/Settings/output/ManagePassword';
import { Notifications } from 'pages/Settings/output/Notifications';
import { Organization } from 'pages/Settings/output/Organization';
import { Preferences } from 'pages/Settings/output/Preferences';
import { Profile } from 'pages/Settings/output/Profile';
import { Requests } from 'pages/Settings/output/Requests';
import { Schedules } from 'pages/Settings/output/Schedules';
import { SettingsPage } from 'pages/Settings/output/Settings';
import { TimeTracking } from 'pages/Settings/output/TimeTracking';
import { Team } from 'pages/Team/output/Team';
import { TimeClocksRenderer } from 'pages/TimeClocks/output/TimeClocksRenderer';
import { WorkStatusesRenderer } from 'pages/WorkStatuses/output/WorkStatusesRenderer';
import { languageSelector } from 'state/recoilState';
import { settingsPageDataSelector } from 'state/settings';
import { userDetailsSelector } from 'state/userSession';

export const AuthorizedAppRoutes: FC = () => {
  const language = useRecoilValue(languageSelector);
  const userDetails = useRecoilValue(userDetailsSelector);
  const settingsPageData = useRecoilValue(settingsPageDataSelector);
  const { showAISchedules, showTabSchedules, shouldAnyTabsBeVisible, hideTabAll } = useCalendarTabPermissions();

  const { isMobileBreakpoint } = useThemeBreakpoint();

  const rollbarPayloadObject = useMemo(() => {
    if (!userDetails) return {};
    return {
      id: userDetails.id,
      email: userDetails.email,
      organization: settingsPageData?.organization.name,
    };
  }, [settingsPageData?.organization.name, userDetails]);

  useRollbarPerson(rollbarPayloadObject);

  const { systemManagement, modules, modulesManagement, isInitialized, isHeadAdmin } = useAppPermissions();
  const { isRequests } = useAppRouting(['isRequests']);

  return (
    <>
      <Routes>
        <Route path={MATCH_ALL} element={<Outlet />}>
          {/* YOUR QR CODE */}
          <Route
            path={PATH_REL.YOUR_QR_CODE_MODAL[language]}
            element={
              <Modal size="xs">
                <YourQrCodeModal />
              </Modal>
            }
          />
          {/* YOUR BENEFITS */}
          {isInewi && (
            <Route
              path={PATH_REL.YOUR_BENEFITS_MODAL[language]}
              element={
                <Modal>
                  <YourBenefits />
                </Modal>
              }
            />
          )}
          {/* GET MOBILE APPS */}
          <Route
            path={PATH_REL.GET_MOBILE_APPS_MODAL[language]}
            element={
              <Modal>
                <GetMobileAppsModal />
              </Modal>
            }
          />
        </Route>
      </Routes>
      <Routes>
        {/* START */}
        <Route path={`${PATH.START[language]}*`} element={<Home />} />
        {/* TEAM */}
        <Route path={`${PATH.TEAM[language]}/*`} element={<Team />} />
        {/* SETTINGS */}
        <Route path="*" element={<SettingsPage />}>
          <Route
            path={`${PATH_REL.SETTINGS[language]}/*`}
            element={isMobileBreakpoint ? undefined : <Navigate to={TO.SETTINGS__USER[language]} />}
          />

          <Route path={`${PATH_REL.SETTINGS__USER__PREFERENCES[language]}/*`} element={<Preferences />} />
          <Route path={`${PATH_REL.SETTINGS__USER__NOTIFICATIONS[language]}/*`} element={<Notifications />} />
          <Route path={`${PATH_REL.SETTINGS__USER__MANAGE_PASSWORD[language]}/*`} element={<ManagePassword />} />
          <Route path={`${PATH_REL.SETTINGS__USER[language]}/*`} element={<Profile />} />
          <Route path={`${PATH_REL.SETTINGS__INTEGRATIONS[language]}/*`} element={<Integrations />} />
          <Route
            element={
              isInitialized && !systemManagement.CompanySettings ? (
                <Navigate to={BASE_ROUTES.PAGE_NOT_FOUND[language]} />
              ) : (
                <Outlet />
              )
            }
          >
            <Route path={`${PATH_REL.SETTINGS__ORGANIZATION__TIME_TRACKING[language]}/*`} element={<TimeTracking />} />
            <Route path={`${PATH_REL.SETTINGS__ORGANIZATION__SCHEDULES[language]}/*`} element={<Schedules />} />
            <Route
              path={`${PATH_REL.SETTINGS__ORGANIZATION__REQUESTS[language]}/*`}
              element={isInitialized && !modules.Requests ? <Navigate to={TO.START[language]} /> : <Requests />}
            />
            <Route
              path={`${PATH_REL.SETTINGS__AUTOMATIONS[language]}/*`}
              element={isInitialized && !modules.TimeTracking ? <Navigate to={TO.START[language]} /> : <Automations />}
            />
            <Route
              path={`${PATH_REL.SETTINGS__ORGANIZATION__NOTIFICATIONS[language]}/*`}
              element={<DefaultNotifications />}
            />
            <Route
              path={`${PATH_REL.SETTINGS__ORGANIZATION__ADVANCED[language]}/*`}
              element={isInitialized && !isHeadAdmin ? <Navigate to={TO.START[language]} /> : <Advanced />}
            />
            <Route path={`${PATH_REL.SETTINGS__ORGANIZATION[language]}/*`} element={<Organization />} />
          </Route>
        </Route>

        {/* PAYMENTS */}

        <Route
          path={`${PATH.PAYMENTS[language]}/*`}
          element={
            !systemManagement.Payments && isInitialized ? <Navigate to={TO.START[language]} /> : <PaymentsRenderer />
          }
        />

        {/* TIME TRACKING */}

        <Route
          path={PATH.TIME_TRACKING__CLOCK_LOG[language]}
          element={
            !modules.TimeTracking && isInitialized ? (
              <Navigate to={TO.START[language]} />
            ) : (
              <Flex sx={{ flexDirection: ['column', null, null, 'row'], flex: '1 0', height: '100%' }}>
                <Outlet />
              </Flex>
            )
          }
        >
          <Route index element={<Navigate to={TO.CLOCK_LOG__ALL[language]} />} />
          <Route path={`:type${DATE_RANGE_PARAMS}/*`} element={<ClockLogRenderer />} />
        </Route>

        <Route
          element={!modulesManagement.TimeTracking && isInitialized ? <Navigate to={TO.START[language]} /> : <Outlet />}
        >
          <Route path={`${PATH.TIME_TRACKING__WORK_STATUSES[language]}/*`} element={<WorkStatusesRenderer />} />
          <Route path={`${PATH.TIME_TRACKING__LOCATIONS[language]}/*`} element={<LocationsRenderer />} />
          <Route path={`${PATH.TIME_TRACKING__TIME_CLOCKS[language]}/*`} element={<TimeClocksRenderer />} />
        </Route>

        {/* REPORTS */}

        <Route
          element={
            !modules.TimeTracking && isInitialized ? (
              <Navigate to={TO.START[language]} />
            ) : (
              <Flex sx={{ flexDirection: ['column', null, null, 'row'], flex: '1 0', height: '100%' }}>
                <Outlet />
              </Flex>
            )
          }
        >
          <Route
            path={`${PATH.REPORTS__TYPE__START_DATE_UNIX__END_DATE_UNIX[language]}/*`}
            element={<ReportsRenderer />}
          >
            <Route path={`${PATH_REL.DETAILS__USER_ID__DATE_UNIX[language]}/*`} element={<DayDetailsRenderer />} />
          </Route>
        </Route>

        {/* REQUESTS */}
        <Route
          path={PATH.REQUESTS[language]}
          element={
            !modules.Requests && isInitialized ? (
              <Navigate to={TO.START[language]} />
            ) : (
              <Flex sx={{ flexDirection: ['column', null, null, 'row'], flex: '1 0', height: '100%' }}>
                <Outlet />
              </Flex>
            )
          }
        >
          <Route index element={<Navigate to={TO.REQUESTS__GROUPED[language]} />} />
          <Route path={`:type${DATE_RANGE_PARAMS}/*`} element={<RequestsRenderer />}>
            <Route
              path={`${PATH_REL.DETAILS__USER_ID__REQUEST_TYPE__TYPE_ID[language]}/*`}
              element={<RequestsUsageOverviewTypeDetailsRenderer />}
            />
            {/* 
              because of some nested child which does not require DATE_RANGE_PARAMS 
              we have to provided those routes on that level,
              because react router v6 does not support nested <Routes> if optional paramater occurs
              /_all/some-modal-path -> does not render modal even if date params are optional
              /_all/startDateUnix/endDateUnix/some-modal-path -> renders modal 
            */}
            {RequestsModalRoutesRenderer(language, isRequests)}
          </Route>
        </Route>

        {/* CALENDAR */}
        <Route
          path={PATH.CALENDAR[language]}
          element={
            !modules.Schedule && !modules.Requests && isInitialized ? (
              <Navigate to={TO.START[language]} />
            ) : (
              <Flex sx={{ flexDirection: ['column', null, null, 'row'], flex: '1 0', height: '100%' }}>
                <Outlet />
              </Flex>
            )
          }
        >
          <Route
            index
            element={
              <>
                {shouldAnyTabsBeVisible && !hideTabAll && isInitialized && <Navigate to={TO.CALENDAR__ALL[language]} />}
                {showTabSchedules && showAISchedules && hideTabAll && isInitialized && (
                  <Navigate to={TO.CALENDAR__SCHEDULES[language]} />
                )}
              </>
            }
          />
          <Route
            path={`:type${DATE_RANGE_PARAMS}/*`}
            element={
              <>
                <CalendarRenderer />
                <Outlet />
              </>
            }
          >
            <Route
              path={`${PATH_REL.DETAILS__USER_ID__DATE_UNIX[language]}/*`}
              element={<CalendarDayDetailsRenderer />}
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
};
