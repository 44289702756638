import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { CountryCode, getCountryCallingCode, isSupportedCountry, isValidNumber } from 'libphonenumber-js';
import React, { useCallback, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { AccountInfoAbout } from 'api/actions/user/userActions.types';
import { FormCard } from 'components/FormCard/FormCard';
import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons/Button';
import { ElementGroup } from 'components/ui/ElementGroup';
import { PhoneInput } from 'components/ui/PhoneInput';
import { TextInput } from 'components/ui/TextInput';
import { HOST_REGION, NAVIGATOR_REGION } from 'constants/common';
import { TO } from 'constants/routes';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { languageSelector } from 'state/recoilState';
import { signUpFormAtom } from 'state/signUp';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { getNavigatorRegion } from 'utils/getBrowserRegion';
import { typedKeys } from 'utils/typedKeys';

type Props = {
  onSubmit: (props: AccountInfoAbout) => Promise<boolean>;
};

export const EmployerStep1Form = React.forwardRef<HTMLFormElement, Props>(({ onSubmit }: Props, ref) => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const { state } = useLocation();
  const defaultCountryCallingCode = useMemo(() => {
    const defaultRegion = NAVIGATOR_REGION || getNavigatorRegion() || HOST_REGION;

    const callingCode =
      defaultRegion && isSupportedCountry(defaultRegion.toUpperCase())
        ? getCountryCallingCode(defaultRegion.toUpperCase() as CountryCode)
        : null;

    return callingCode ? `+${callingCode}` : undefined;
  }, []);
  const [signUpFormState, setSignUpFormState] = useRecoilState(signUpFormAtom);

  const [isPhoneDirty, setIsPhoneDirty] = useState(
    !!signUpFormState?.phoneNumber &&
      signUpFormState?.phoneNumber !== defaultCountryCallingCode &&
      !isValidNumber(signUpFormState?.phoneNumber),
  );

  const {
    setError,
    clearErrors,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: signUpFormState?.firstName && signUpFormState?.lastName ? 'onChange' : 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: signUpFormState?.firstName,
      lastName: signUpFormState?.lastName,
      phoneNumber: signUpFormState?.phoneNumber || defaultCountryCallingCode || undefined,
    },
  });

  const navigate = useAppNavigate();

  const setPhoneErrorCallback = useCallback(() => {
    setError('phoneNumberInternalError' as 'phoneNumber', {
      message: 'only visible in code',
    });
  }, [setError]);
  const clearPhoneErrorCallback = useCallback(() => {
    clearErrors('phoneNumberInternalError' as 'phoneNumber');
  }, [clearErrors]);

  const handleSubmitCallback: SubmitHandler<Partial<AccountInfoAbout>> = useCallback(
    async (props) => {
      const { firstName, lastName, phoneNumber } = props;
      if (!!firstName && !!lastName) {
        await onSubmit({
          firstName,
          lastName,
          phoneNumber,
        });
      }
    },
    [onSubmit],
  );

  const handleGoBack = () => {
    navigate(TO.SIGN_UP__ACCOUNT_TYPE[language], { state });
  };

  const saveToRecoilCallback = useCallback(async () => {
    await delay(0);
    const formValues = getValues();

    let validFormValues = {};

    typedKeys(formValues).forEach((name) => {
      if (errors[name] && formValues[name] !== '') {
        return;
      }

      validFormValues = {
        ...validFormValues,
        [name]: formValues[name],
      };
    });

    setSignUpFormState({
      ...signUpFormState,
      ...validFormValues,
    });
  }, [getValues, errors, signUpFormState, setSignUpFormState]);

  const handleSubmitErrorCallback = useCallback(
    (submitErrors: typeof errors) => {
      if (submitErrors.firstName || submitErrors.lastName) {
        return;
      }
      if (!submitErrors.phoneNumber && !submitErrors['phoneNumberInternalError' as 'phoneNumber']) {
        return;
      }
      flushSync(() => {
        clearPhoneErrorCallback();
      });

      floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))();
    },
    [clearPhoneErrorCallback, handleSubmit, handleSubmitCallback],
  );

  return (
    <form
      ref={ref}
      onChange={floatingPromiseReturn(saveToRecoilCallback)}
      onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))}
      noValidate
    >
      <ElementGroup marginValue="4" direction="column">
        <ElementGroup direction="column">
          <TextInput
            variant="roundedTop"
            id="firstName"
            placeholder={t({
              id: 'sign_up.employer.form.first_name',
              message: 'Your first name',
            })}
            type="text"
            error={!!errors.firstName}
            errorMessage={errors?.firstName?.message}
            {...register('firstName', validationFactory({ ...VALIDATION_RULES.FIRST_NAME, required: true }))}
          />
          <TextInput
            variant="roundedBottom"
            id="lastName"
            placeholder={t({
              id: 'sign_up.employer.form.last_name',
              message: 'Your last name',
            })}
            type="text"
            error={!!errors.lastName}
            errorMessage={errors?.lastName?.message}
            {...register('lastName', validationFactory({ ...VALIDATION_RULES.SURNAME, required: true }))}
          />
        </ElementGroup>
        <Flex>
          <PhoneInput
            id="phoneNumber"
            autoComplete="tel"
            label={t({
              id: 'global.forms.inputs.phone_number',
              message: 'Phone number',
            })}
            placeholder={t({
              id: 'global.forms.inputs.phone_number_example',
            })}
            onValidError={setPhoneErrorCallback}
            onClearError={clearPhoneErrorCallback}
            error={(!!errors.phoneNumber || !!errors['phoneNumberInternalError' as 'phoneNumber']) && isPhoneDirty}
            errorMessage={errors?.phoneNumber?.message}
            clearable
            {...register('phoneNumber', {
              onChange: () => {
                setIsPhoneDirty(true);
              },
            })}
          />
        </Flex>
      </ElementGroup>

      <FormCard.Footer>
        <Button variant="minimal" shape="rounded" size="lg" type="button" onClick={handleGoBack}>
          {t({ id: 'global.forms.buttons.back' })}
        </Button>

        <Button variant="primary" apendWith={<Icon type="arrowRight" />} size="lg" type="submit" shape="rounded">
          {t({ id: 'global.forms.buttons.next' })}
        </Button>
      </FormCard.Footer>
    </form>
  );
});
