import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Helmet } from 'react-helmet';
import { useRecoilValue } from 'recoil';
import { Flex } from 'theme-ui';

import { postForgotPasswordAction } from 'api/actions/password/passwordActions';
import { PostForgotPasswordPayload } from 'api/actions/password/passwordActions.types';
import { FormCard } from 'components/FormCard/FormCard';
import { LinkButton } from 'components/ui/Buttons';
import { APP_NAME } from 'constants/common';
import { TO } from 'constants/routes';
import { Main } from 'layouts/Authentication/output/Main';
import { languageSelector } from 'state/recoilState';

import { PassResetForm } from './components/PassResetForm/PassResetForm';

export const PassReset = (): React.ReactElement => {
  const [isDone, setIsDone] = useState<boolean>(false);

  const language = useRecoilValue(languageSelector);

  const { mutate } = useMutation(postForgotPasswordAction);

  const onSubmit = useCallback(
    async (body: PostForgotPasswordPayload): Promise<boolean> => {
      const { error } = await mutate(body);

      if (!error) {
        setIsDone(true);
        return true;
      }

      return false;
    },
    [mutate],
  );

  return (
    <>
      <Helmet>
        <title>{i18n._(t({ id: 'password_recovery.page_title', message: `Password recovery - ${APP_NAME}` }))}</title>
      </Helmet>

      <Main>
        <Flex
          sx={{
            width: '100%',
            maxWidth: '480px',
            flexGrow: 1,
            gap: 5,
            flexDirection: 'column',
            alignItems: 'stretch',
            alignSelf: 'center',
            textAlign: 'center',
          }}
        >
          <FormCard>
            {!isDone ? (
              <>
                <FormCard.Header>
                  <FormCard.Title>
                    <Trans id="password_recovery.lead.header">Reset your password</Trans>
                  </FormCard.Title>
                  <FormCard.Lead>
                    <Trans id="password_recovery.lead.text">Enter y our e-mail address.</Trans>
                  </FormCard.Lead>
                </FormCard.Header>
                <PassResetForm onSubmit={onSubmit} />
              </>
            ) : (
              <>
                <FormCard.Header>
                  <FormCard.Title>
                    <Trans id="password_recovery.done_title">Password recovery link sent</Trans>
                  </FormCard.Title>
                  <FormCard.Lead>
                    <Trans id="password_recovery.done_lead">
                      Check your inbox for the link. Remember that it's active only for 24h.
                    </Trans>
                  </FormCard.Lead>
                </FormCard.Header>

                <FormCard.Footer>
                  <LinkButton to={TO.SIGN_IN[language]} variant="grey" shape="rounded" fullWidth size="lg">
                    <Trans id="password_recovery.form.back_button">Back to sign in</Trans>
                  </LinkButton>
                </FormCard.Footer>
              </>
            )}
          </FormCard>
        </Flex>
      </Main>
    </>
  );
};
