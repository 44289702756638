import { useCallback, useMemo, useTransition } from 'react';
import { RecoilState, useRecoilState_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';
import _ from 'lodash';

import { DateRangeFilterAtom, dateRangeFilterAtom } from 'state/filters';
import { dateTime } from 'utils/dateTime';

export const useDateRangeFilter = (dateRangeAtom: RecoilState<DateRangeFilterAtom | null> = dateRangeFilterAtom) => {
  const [dates, insideSetDates] = useRecoilState_TRANSITION_SUPPORT_UNSTABLE(dateRangeAtom);

  const [inTransition, startTransition] = useTransition();

  const setDates = useCallback(
    (newState: DateRangeFilterAtom) => {
      if (!_.isEqual(dates, newState)) {
        startTransition(() => {
          insideSetDates(newState);
        });
      }
    },
    [dates, insideSetDates],
  );

  const multipleYearsInRangeFilter = useMemo(() => {
    if (dates) {
      const { endDateUnix, startDateUnix } = dates;
      const startDateYear = dateTime(startDateUnix, { utc: true }).format('YYYY');
      const endDateYear = dateTime(endDateUnix, { utc: true }).format('YYYY');

      return startDateYear !== endDateYear;
    }
    return null;
  }, [dates]);

  return {
    dates,
    setDates,
    inTransition,
    multipleYearsInRangeFilter,
  };
};
