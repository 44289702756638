import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { useMutation } from 'react-fetching-library';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { automationsSettingsAction } from 'api/actions/settings/settingsActions';
import {
  AutomationsSettingsActionProps,
  FetchAutomationsSettingsResponse,
} from 'api/actions/settings/settingsActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { SnackbarProps } from 'components/Snackbar/types';
import { AUTOMATIONS_SETTINGS_RESET } from 'constants/settings';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { blockTransitionAtom, resetFormButtonAtom } from 'state/settings';
import { getRefreshSnackbarProps } from '../../../../../utils/getRefreshSnackbarProps';
import { useAutomationsSettings } from '../../../hooks/useAutomationsSettings';

type Props = {
  handleFormReset: () => void;
  getCurrentFormState: () => FetchAutomationsSettingsResponse;
};

export const useSubmitAutomationsSettings = ({ handleFormReset, getCurrentFormState }: Props) => {
  useLingui();

  const setResetCallbacks = useSetRecoilState(resetFormButtonAtom);
  const [{ skipFetch, isRequestPending }, setBlockTransition] = useRecoilState(blockTransitionAtom);
  const { fetchAutomationsSettings, getParsedAutomationsSettings, fetchAbort } = useAutomationsSettings();
  const { mutate } = useMutation(automationsSettingsAction);
  const skipFetchRef = useCallbackRef(skipFetch);
  const isRequestPendingRef = useCallbackRef(isRequestPending);
  const getCurrentFormStateRef = useCallbackRef(getCurrentFormState);
  const prevFormStateRef = useRef(getParsedAutomationsSettings());
  const pendingRequestBodyRef = useRef<Partial<AutomationsSettingsActionProps> | null>(null);

  const automationsSettingsResetObject = useMemo(
    () => ({ name: AUTOMATIONS_SETTINGS_RESET, callback: handleFormReset }),
    [handleFormReset],
  );

  const handleSubmitCallback = useCallback(
    async (data: AutomationsSettingsActionProps) => {
      const submittedFormState = data;
      const parsedData = getParsedAutomationsSettings(submittedFormState, 'minutes');
      const omittedAutomationsSettings = getParsedAutomationsSettings();

      if (
        _.isEqual(parsedData, omittedAutomationsSettings) &&
        !pendingRequestBodyRef.current &&
        !isRequestPendingRef.current
      ) {
        setBlockTransition((prevState) => ({ ...prevState, blockTransition: false }));
        setResetCallbacks(null);
        return;
      }

      const parsedPrevFormState = getParsedAutomationsSettings(prevFormStateRef.current, 'minutes');

      const currentChanges: Partial<AutomationsSettingsActionProps> = _.omitBy(
        parsedData,
        (value, key) =>
          parsedPrevFormState && _.isEqual(value, parsedPrevFormState[key as keyof AutomationsSettingsActionProps]),
      );

      fetchAbort();
      setResetCallbacks(null);
      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: true }));

      pendingRequestBodyRef.current = { ...pendingRequestBodyRef.current, ...currentChanges };
      prevFormStateRef.current = submittedFormState;

      const { error, status } = await mutate(pendingRequestBodyRef.current);

      const formStateAfterRequest = getCurrentFormStateRef.current();

      if (!_.isEqual(submittedFormState, formStateAfterRequest)) {
        return;
      }

      if (!error) {
        let showRefreshSnackbar = false;
        pendingRequestBodyRef.current = null;

        if (!skipFetchRef.current) {
          const { error: fetchError, payload: fetchPayload } = await fetchAutomationsSettings();

          const formStateAfterFetch = getCurrentFormStateRef.current();

          if (!_.isEqual(submittedFormState, formStateAfterFetch)) {
            return;
          }

          if (!fetchError && fetchPayload) {
            const parsedFormStateAfterFetch = getParsedAutomationsSettings(formStateAfterFetch, 'minutes');
            const omittedFetchPayload = getParsedAutomationsSettings(fetchPayload);
            showRefreshSnackbar = !_.isEqual(parsedFormStateAfterFetch, omittedFetchPayload);
          }
        }

        const snackbarProps: SnackbarProps = showRefreshSnackbar
          ? getRefreshSnackbarProps(handleFormReset)
          : {
              message: t({
                id: 'settings.forms.submit_success',
              }),
              variant: 'success',
            };

        addSnackbar(snackbarProps);
      }

      if (error) {
        if (!status) {
          return;
        }
        setResetCallbacks((prevState) =>
          !prevState ? [automationsSettingsResetObject] : [...prevState, automationsSettingsResetObject],
        );
        return;
      }

      setBlockTransition((prevState) => ({ ...prevState, isRequestPending: false, blockTransition: false }));
    },
    [
      automationsSettingsResetObject,
      fetchAbort,
      fetchAutomationsSettings,
      getCurrentFormStateRef,
      getParsedAutomationsSettings,
      handleFormReset,
      isRequestPendingRef,
      mutate,
      setBlockTransition,
      setResetCallbacks,
      skipFetchRef,
    ],
  );

  const handleErrorCallback = useCallback(() => {
    setResetCallbacks((prevState) => {
      if (_.includes(prevState, automationsSettingsResetObject)) return prevState;
      return !prevState ? [automationsSettingsResetObject] : [...prevState, automationsSettingsResetObject];
    });
  }, [automationsSettingsResetObject, setResetCallbacks]);

  return { handleSubmitCallback, handleErrorCallback };
};
