import { atom, selector } from 'recoil';
import _ from 'lodash';
import { t } from '@lingui/macro';

import { FetchReportResponse } from 'api/actions/reports/reportsActions.types';
import { RequestFormType, RequestsUsageOverviewElement } from 'api/actions/requests/requestsActions.types';
import { StickyListProps } from 'components/StickyList/types';
import { FetchCalendarResponse } from 'api/actions/calendar/calendarActions.types';
import { FilterGroupNames, TagsFilters, TeammatesFilters } from 'layouts/AuthorizedApp/AsideFilters/types';
import { isOfType } from 'utils/isOfType';
import { RequestType } from 'api/actions/organizationSession/organizationSessionActions.types';
import { FraudDetectionState } from 'api/actions/timeEvent/timeEventActions.types';

import { userSessionPersonIdSelector } from './userSession';
import { customRequestTypesDictionarySelector, timeOffTypesDictionarySelector } from './organizationSession';
import { parsedEmployeesSelector } from './employees';
import { getParsedRequestTypeSelectorFamily, ParsedRequest, ParsedRequestsMap, requestsToAcceptAtom } from './requests';
import {
  DateRangeFilterAtom,
  filterGroupStateAtomFamily,
  parsedSearchFilterValueSelectorFamily,
  SEARCH_FILTER_TYPE,
} from './filters';
import { CalendarMap } from './calendar';
import { languageSelector } from './recoilState';
import { extTimeEventsSelectorFamily } from './clockLog';

export enum YourStatsPeriod {
  Month = 0,
  PayrollPeriod = 1,
  OvertimePeriod = 2,
}

export const yourStatsAtom = atom<FetchReportResponse['employeesData'] | null>({
  key: 'yourStats',
  default: null,
});

export const yourStatsPeriodAtom = atom<YourStatsPeriod>({
  key: 'yourStatsPeriod',
  default: YourStatsPeriod.Month,
});

export const parsedYourStatsSelector = selector({
  key: 'parsedYourStats',
  get: ({ get }) => {
    const yourStats = get(yourStatsAtom);
    const userId = get(userSessionPersonIdSelector);
    const mapStats = new Map(yourStats);

    if (!yourStats || !mapStats || !userId) {
      return null;
    }

    const data = mapStats.get(userId);

    if (!data) return null;

    const { summary } = data;
    const { timers, scheduledTimeDuration } = summary;

    return {
      schedulesTime: scheduledTimeDuration || 0,
      missingWorkTime: timers ? timers.missingWorkTimeDuration : 0,
      nightTime: timers ? timers.nightTimeDuration : 0,
      overtime: timers ? timers.overtimeDuration : 0,
      withoutSchedule: timers ? timers.withoutScheduleDuration : 0,
      workTime: timers ? timers.workTimeDuration : 0,
    };
  },
});

export const currentUserRequestLimitsAtom = atom<RequestsUsageOverviewElement['requests'] | null>({
  key: 'currentUserRequestLimits',
  default: null,
});

export const showAllCurrentUserRequestLimitsAtom = atom<boolean>({
  key: 'showAllCurrentUserRequestLimits',
  default: false,
});

export const parsedCurrentUserRequestLimitsSelector = selector({
  key: 'parsedCurrentUserRequestLimits',
  get: ({ get }) => {
    const limits = get(currentUserRequestLimitsAtom);
    const timeOffTypes = get(timeOffTypesDictionarySelector);
    const customRequestTypes = get(customRequestTypesDictionarySelector);
    const showAllCurrentUserRequestLimits = get(showAllCurrentUserRequestLimitsAtom);

    if (!limits || !timeOffTypes || !customRequestTypes) return null;

    const timeOffs = _.chain(limits)
      .filter(({ type, usageTimeDuration, totalTimeDuration }) => {
        if (showAllCurrentUserRequestLimits) return type === RequestFormType.TimeOff;

        return type === RequestFormType.TimeOff && (usageTimeDuration > 0 || !!totalTimeDuration);
      })
      .map((limit) => ({
        ...limit,
        name: timeOffTypes[limit.typeId].name,
        abbr: timeOffTypes[limit.typeId].abbreviation,
      }))
      .value();
    const customRequests = _.chain(limits)
      .filter(({ type, usageTimeDuration, totalTimeDuration }) => {
        if (showAllCurrentUserRequestLimits) return type === RequestFormType.Custom;

        return type === RequestFormType.Custom && (usageTimeDuration > 0 || !!totalTimeDuration);
      })
      .map((limit) => ({
        ...limit,
        name: customRequestTypes[limit.typeId].name,
        abbr: customRequestTypes[limit.typeId].abbreviation,
      }))
      .value();

    return {
      timeOffs,
      customRequests,
    };
  },
});

export const parsedRequestsToAcceptSelector = selector<ParsedRequestsMap | null>({
  key: 'requests_to_accept__parsed',
  get: ({ get }) => {
    const requests = get(requestsToAcceptAtom);
    const employees = get(parsedEmployeesSelector);

    if (!requests || !employees) return null;

    const parsedRequestsMap: ParsedRequestsMap = new Map();

    _.forEach(requests, (request) => {
      const newTypeId = request.newEvent?.eventDetails.typeId;
      const originalTypeId = request.originalEvent?.eventDetails.typeId;
      const { personId, newEvent, originalEvent } = request;

      const employee = employees.get(personId);
      const newRequestType = get(getParsedRequestTypeSelectorFamily(newTypeId));
      const originalRequestType = get(getParsedRequestTypeSelectorFamily(originalTypeId));

      if (!employee) {
        return;
      }

      const parsedOriginalEvent = originalEvent && { ...originalEvent, requestType: originalRequestType };
      const parsedNewEvent = newEvent && { ...newEvent, requestType: newRequestType };

      const parsedRequest = {
        ..._.omit(request, ['personId', 'newEvent', 'originalEvent']),
        employee,
        ...(parsedOriginalEvent && parsedNewEvent && { originalEvent: parsedOriginalEvent }),
        ...(parsedOriginalEvent && !parsedNewEvent ? { newEvent: parsedOriginalEvent } : { newEvent: parsedNewEvent }),
      };

      parsedRequestsMap.set(request.id, parsedRequest);
    });

    return parsedRequestsMap;
  },
});

export const filteredParsedRequestsToAcceptSelector = selector<ParsedRequestsMap | null>({
  key: 'filtered_requests_to_accept__parsed',
  get: ({ get }) => {
    const requests = get(parsedRequestsToAcceptSelector);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const language = get(languageSelector);
    const teammatesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TEAMMATES));
    const rolesFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.ROLES));
    const tagsFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.TAGS));
    const requestTypeFilterState = get(filterGroupStateAtomFamily(FilterGroupNames.REQUEST_TYPES));
    const searchFilterState = get(parsedSearchFilterValueSelectorFamily(SEARCH_FILTER_TYPE.TEAMMATES));

    const filterBySearchInput = (request: ParsedRequest) => {
      let valid = false;

      if (_.isEmpty(searchFilterState)) {
        valid = true;
        return valid;
      }

      const { id, number, originalEvent, newEvent } = request;

      const {
        name,
        role: { name: roleName },
        tags,
        id: employeeId,
      } = request.employee;

      const searchableEmployee = _.flatMapDeep([
        `${name.firstName} ${name.surname}`,
        roleName,
        _.map(tags, (tag) => tag?.name),
        employeeId,
        id,
        originalEvent?.requestType &&
          `${t({
            id: originalEvent.requestType.name,
          })}  ${
            isOfType<RequestType>(originalEvent.requestType, 'abbreviation') &&
            t({
              id: originalEvent.requestType.abbreviation,
            })
          }`,
        originalEvent?.eventDetails.name,
        newEvent?.requestType &&
          `${t({
            id: newEvent.requestType.name,
          })}  ${
            isOfType<RequestType>(newEvent.requestType, 'abbreviation') &&
            t({
              id: newEvent.requestType.abbreviation,
            })
          }`,
        newEvent?.eventDetails.name,
        number,
      ]);

      valid = _.some(
        searchFilterState.map((searchQuery) => {
          let subValid = false;

          _.forEach(searchableEmployee, (searchableValue) => {
            subValid = subValid || _.includes(searchableValue?.toLocaleLowerCase(), searchQuery.toLocaleLowerCase());
          });

          return subValid;
        }),
      );

      return valid;
    };

    const filterByType = (type: ParsedRequest['type']) => {
      if (requestTypeFilterState === null || requestTypeFilterState.includes(`${type}`)) {
        return true;
      }

      return false;
    };

    const filterByTags = (employee: ParsedRequest['employee']) => {
      let valid = false;

      if (tagsFilterState === null) {
        valid = true;
        return valid;
      }

      if (_.isArray(tagsFilterState)) {
        tagsFilterState.forEach((tagId) => {
          valid = valid || !!employee.tagsIds.includes(tagId);

          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          if (tagId === TagsFilters.NO_TAGS && _.isEmpty(employee.tagsIds)) {
            valid = true;
          }
        });
      }

      return valid;
    };

    const filterByRole = (employee: ParsedRequest['employee']) => {
      let valid = false;

      if (rolesFilterState === null) {
        valid = true;
        return valid;
      }

      if (_.isArray(rolesFilterState)) {
        rolesFilterState.forEach((roleId) => {
          valid = valid || !!(employee.roleId === roleId);
        });
      }

      return valid;
    };

    const filterByTeammates = (employee: ParsedRequest['employee']) => {
      let valid = false;

      if (teammatesFilterState === null) {
        valid = true;
        return valid;
      }
      if (teammatesFilterState.includes(TeammatesFilters.ACTIVE)) {
        valid = valid || !!employee.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.HIDDEN)) {
        valid = valid || !!employee.isHidden;
      }
      if (teammatesFilterState.includes(TeammatesFilters.DEACTIVATED)) {
        valid = valid || !employee.isActive;
      }
      if (teammatesFilterState.includes(TeammatesFilters.INVITED)) {
        valid = valid || !!employee.invitationState;
      }

      return valid;
    };

    if (!requests) {
      return new Map();
    }

    const filteredMap = new Map(requests);

    filteredMap.forEach((request, requestId) => {
      let valid = false;
      const { employee, type } = request;

      valid =
        filterByTeammates(employee) &&
        filterByRole(employee) &&
        filterByTags(employee) &&
        filterBySearchInput(request) &&
        filterByType(type);

      if (!valid) filteredMap.delete(requestId);
    });

    return filteredMap;
  },
});

export const parsedAntiSpoofingChecksSelector = selector({
  key: 'anti_spoofing_checks__parsed',
  get: ({ get }) => {
    const extTimeEvents = get(extTimeEventsSelectorFamily('antiSpoofingChecks'));

    if (!extTimeEvents) return null;

    const filteredExtTimeEvents = new Map();

    extTimeEvents.forEach((eTE) => {
      if (eTE.fraudDetectionState === FraudDetectionState.PotentialFraud) filteredExtTimeEvents.set(eTE.id, eTE);
    });

    return filteredExtTimeEvents;
  },
});

export const requestsToAcceptColumns = atom<StickyListProps<ParsedRequest>['columns'] | null>({
  key: 'requests_to_accept__columns',
  default: null,
});

export const yourCalendarDateRangeAtom = atom<DateRangeFilterAtom | null>({
  key: 'your_calendar_date_range',
  default: null,
});

export const yourCalendarActiveDateAtom = atom<number | null>({
  key: 'your_calendar_active_date',
  default: null,
});

export const yourCalendarVisibleDatesAtom = atom<DateRangeFilterAtom | null>({
  key: 'your_calendar_visible_dates',
  default: null,
});

export const yourCalendarAtom = atom<FetchCalendarResponse | null>({
  key: 'your_calendar',
  default: null,
});

export const yourCalendarSelector = selector({
  key: 'your_calendar__parsed',
  get: ({ get }) => {
    const calendar = get(yourCalendarAtom);
    const employeesMap = get(parsedEmployeesSelector);

    if (!calendar || !employeesMap) return null;

    const { employeesData } = calendar;
    const employeesDataMap = new Map(employeesData);
    const calendarMap: CalendarMap = new Map();

    employeesMap.forEach((data, employeeId) => {
      const { name, role, tags, avatarUrl, editablePermissions } = data;
      const employeeData = employeesDataMap.get(`${employeeId}`);

      calendarMap.set(employeeId, {
        days: new Map(employeeData?.days),
        hasUnpublishedChanges: employeeData?.hasUnpublishedChanges,
        hasPendingRequests: employeeData?.hasPendingRequests,
        hasErrors: employeeData?.hasErrors,
        id: employeeId,
        editablePermissions,
        name,
        role,
        tags,
        avatarUrl,
      });
    });

    return calendarMap;
  },
});
