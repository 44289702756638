/**
 * Ads strict typing to Object.keys() return array.
 *
 * @param {T} o Object that will be passsed to Object.keys().
 *
 * @return {(keyof T)[]} Returns the array of object keys that is strictly typed.
 */

export function typedKeys<T extends object>(o: T): (keyof T)[] {
  return Object.keys(o) as (keyof T)[];
}
