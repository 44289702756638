import { useMemo } from 'react';

import { ExternalLoginProvider, ExternalProvider } from 'api/actions/integrations/integrationActions.types';
import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/ui/Buttons';

type Props = {
  integration: ExternalProvider;
  onClick?: () => void;
};

export const CommonExternalProviderButton = ({ integration, onClick }: Props) => {
  const iconType = useMemo(() => {
    switch (integration.provider) {
      case ExternalLoginProvider.AppleLinkInewi:
      case ExternalLoginProvider.AppleLinkTrackTime:
      case ExternalLoginProvider.AppleLoginInewi:
      case ExternalLoginProvider.AppleLoginTrackTime: {
        return 'apple';
      }
      case ExternalLoginProvider.AtlassianInewi:
      case ExternalLoginProvider.AtlassianTrackTime: {
        return 'atlassian';
      }
      case ExternalLoginProvider.Google:
      case ExternalLoginProvider.GoogleInewi:
      case ExternalLoginProvider.GoogleTrackTime:
        return 'google';
      case ExternalLoginProvider.SlackLoginInewi:
      case ExternalLoginProvider.SlackLoginTrackTime:
        return 'slack';
      default: {
        return null;
      }
    }
  }, [integration.provider]);

  const handleClick = useMemo(
    () => onClick || (() => window.open(integration.actionUrl, '_self')),
    [integration.actionUrl, onClick],
  );

  return (
    <>
      {iconType && (
        <Button
          variant="naked"
          shape="rounded"
          size="default"
          onClick={handleClick}
          bgOverwrite={{
            default: 'signIn.bg.default',
            disabled: '',
            hover: 'signIn.bg.hover',
            tap: 'signIn.bg.tap',
          }}
          sx={{
            size: '40px',
            border: '1px solid',
            borderColor: 'signIn.border.default',
            borderRadius: '4px',
            transition: 'background-color .218s',
            ':hover, :focus': {
              borderColor: 'signIn.border.hover',
            },
          }}
        >
          <Icon type={iconType} size={24} />
        </Button>
      )}
    </>
  );
};
