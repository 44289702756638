import CryptoJS from 'crypto-js';
import memoize from 'lodash/memoize';

// same sizes declared as are on the backend
const keySize = 256;
const blockSize = 128;
const secretKey = process.env.REACT_APP_AES_SECRET_KEY;

// FIXME: DO NOT USE, SHOULD BE DELETED SOON
// generateSalt has the same logic as in the backend
const generateSalt = (size: number, password: string): Uint8Array => {
  const buffer = new Uint8Array(size);
  const passBytes = new TextEncoder().encode(password);
  if (passBytes.length > buffer.length) {
    buffer.set(passBytes.slice(0, buffer.length));
  } else {
    buffer.set(passBytes);
  }
  return buffer;
};

let passwordWithIV: Uint8Array | null = null;
// FIXME: DO NOT USE, SHOULD BE DELETED SOON
export const decodeWithAESToUTF8 = memoize((encodedValue?: string, secret: string | undefined = secretKey) => {
  if (!encodedValue) return '';
  if (!secret) {
    // eslint-disable-next-line no-console
    console.error('Missing REACT_APP_AES_SECRET_KEY in the .env file');
    return '';
  }
  passwordWithIV = passwordWithIV || generateSalt(keySize / 8, secret);
  const ivBytes = passwordWithIV.slice(0, blockSize / 8);
  const key = CryptoJS.lib.WordArray.create(passwordWithIV);
  const iv = CryptoJS.lib.WordArray.create(ivBytes);
  const encodedBytes = CryptoJS.enc.Hex.parse(encodedValue);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: encodedBytes }, key, {
    iv,
    mode: CryptoJS.mode.CBC,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
});
