import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React from 'react';
import { Flex, Text } from 'theme-ui';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Attachment } from 'components/ui/FileUpload/Attachment';
import { PersonCell } from 'pages/Reports/output/PersonCell';
import { useRequestsLogic } from '../../../../../hooks/useRequestsLogic';
import { RequestTypeListPresenter } from '../../../../RequestTypeListPresenter/RequestTypeListPresenter';
import { useRequestDetails } from '../../hooks/useRequestDetails';

import { HistoryList } from './components/HistoryList';
import { MessageBox } from './components/MessageBox';
import { SentToDetails } from './components/SentToDetails';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const RequestDetailsMain = ({ children }: Props): React.ReactElement | null => {
  useLingui();
  const { parsedRequestDetails, isApprovalAllowed, isModificationAllowed } = useRequestDetails();
  const { requestsViewType } = useRequestsLogic();

  if (!parsedRequestDetails) return null;

  const {
    originalEvent,
    newEvent,
    employee,
    type,
    actionType,
    state,
    isDeleted,
    swapPersonId,
    latestInGroup,
    selectedFirstStep,
    selectedSecondStep,
  } = parsedRequestDetails;
  const { name, role, tags, avatarUrl, id: employeeId } = employee;

  return (
    <Flex sx={{ flexDirection: 'column', gap: 4, pb: 4 }}>
      <MessageBox
        actionType={actionType}
        state={state}
        latestInGroup={latestInGroup}
        reason={newEvent.eventDetails.note}
        isDeleted={isDeleted}
        isApprovalAllowed={isApprovalAllowed}
        isModificationAllowed={isModificationAllowed}
      />
      <PersonCell name={name} role={role} tags={tags} avatarUrl={avatarUrl} avatarSize={34} />
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 3,
          }}
        >
          {t({ id: 'type' })}
        </Text>
        <RequestTypeListPresenter
          type={type}
          baseRequest={{
            eventIsEnd: originalEvent?.eventDetails.isEnd || newEvent.eventDetails.isEnd,
            requestType: originalEvent?.requestType || newEvent.requestType || undefined,
            businessTripName: originalEvent?.eventDetails.name || newEvent.eventDetails.name,
          }}
          newRequest={
            originalEvent
              ? {
                  eventIsEnd: newEvent.eventDetails.isEnd,
                  requestType: newEvent.requestType || undefined,
                  businessTripName: newEvent.eventDetails.name,
                }
              : undefined
          }
          requestStateBadge={{ viewType: requestsViewType, actionType }}
          swapPersonId={swapPersonId}
          employeeId={employeeId}
        />
      </Flex>
      {children}
      {(newEvent?.eventDetails?.note || newEvent?.eventDetails?.externalId) &&
        !originalEvent &&
        type !== RequestFormType.TimeTracking && (
          <Flex sx={{ flexDirection: 'column', gap: 2 }}>
            <Text
              sx={{
                fontWeight: 'bold',
                fontSize: 3,
              }}
            >
              {type !== RequestFormType.Schedule ? (
                t({ id: 'add_request.note' })
              ) : (
                <Trans id="request_details.reason">Reason</Trans>
              )}
            </Text>
            <Text as="p" variant="p" sx={{ wordBreak: 'break-word', fontSize: 2 }}>
              {newEvent?.eventDetails?.externalId
                ? t({
                    id: 'request_details.reason_zus',
                    message: `Imported from eZLA: ${newEvent.eventDetails.externalId}`,
                  })
                : newEvent?.eventDetails?.note}
            </Text>
          </Flex>
        )}
      {!originalEvent && !_.isEmpty(newEvent.eventDetails.attachments) && (
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <Text
            sx={{
              fontWeight: 'bold',
              fontSize: 3,
            }}
          >
            {t({ id: 'add_request.attachments' })}
          </Text>
          {newEvent.eventDetails.attachments?.map((attachment) => (
            <Attachment key={attachment} name={attachment} defaultValue={attachment} />
          ))}
        </Flex>
      )}
      <SentToDetails selectedFirstStep={selectedFirstStep} selectedSecondStep={selectedSecondStep} />
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 3,
          }}
        >
          <Trans id="request.history">History</Trans>
        </Text>
        <HistoryList list={parsedRequestDetails.history} />
      </Flex>
    </Flex>
  );
};
