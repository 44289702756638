import React, { useMemo, useState } from 'react';
import { Flex, FlexProps, Text } from 'theme-ui';
import { v1 as uuidv1 } from 'uuid';

import { Icon } from 'components/Icon/Icon';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { useContextMenu } from 'hooks/useContextMenu/useContextMenu';

import { FilterProps } from './types';
import { FilterPrependElement } from './FilterPrependElement';
import { FilterButton } from './FilterButtons';

const FilterButtonWithDropdown = withDropdown(FilterButton);

type Props = FilterProps & {
  onClick: FlexProps['onClick'];
  isActive: boolean;
};

export const Filter = React.memo(
  ({
    groupName,
    id,
    label,
    color,
    prependWith,
    prependSx,
    iconType,
    isOutlined,
    dropdownProps,
    sx,
    appendWith,
    selectType,
    onClick,
    isActive,
    ...props
  }: Props): React.ReactElement => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const contextMenuId = useMemo(() => (dropdownProps ? uuidv1() : undefined), [dropdownProps]);
    const { openContextMenu } = useContextMenu(contextMenuId);

    return (
      <Flex
        data-dropdown={isDropdownOpen}
        data-is-active={isActive}
        onContextMenu={openContextMenu}
        sx={{
          gap: 1,
          '&:not(:hover):not([data-dropdown="true"])': {
            '.filter-dropdown': {
              display: 'none',
              visibility: 'hidden',
            },
          },
          '&:hover, &[data-dropdown="true"]': {
            '.filter': {
              bg: 'asideFilters.bg.hover',
            },
          },
          '&[data-is-active="true"]': {
            '.filter': {
              bg: 'asideFilters.bg.active',
              color: 'primary',
            },
            '&:hover, &[data-dropdown="true"]': {
              '.filter': {
                bg: 'alphas.primarish4',
              },
            },
          },
          ...(sx && sx),
        }}
        {...props}
      >
        <Flex
          className="filter"
          sx={{
            gap: 1,
            flexGrow: 1,
            flexShrink: 99,
            alignItems: 'center',
            py: 1,
            px: 1,
            borderRadius: 'xs',
            cursor: 'pointer',
            transition: 'background 0.15s ease-in-out',
          }}
          onClick={onClick}
        >
          <FilterPrependElement
            color={color}
            isOutlined={isOutlined}
            type={prependWith}
            iconType={iconType}
            sx={prependSx}
          />

          <Text
            title={label}
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              ...(isActive && {
                fontWeight: 'bold',
              }),
            }}
          >
            {label}
          </Text>
        </Flex>

        {dropdownProps && (
          <FilterButtonWithDropdown
            {...(contextMenuId && { key: contextMenuId })}
            className="filter-dropdown"
            dropdownProps={{
              ...dropdownProps,
              onClick: () => setIsDropdownOpen(false),
            }}
            popperProps={{
              popperMargin: -0.3,
              trigger: 'manual',
              visible: isDropdownOpen,
              onClickElement: () => setIsDropdownOpen(!isDropdownOpen),
              onOutsideClick: () => isDropdownOpen && setIsDropdownOpen(false),
              onClickPopper: () => setIsDropdownOpen(false),
              contextMenuId,
            }}
            autoInitialize
          >
            <Icon type="more" />
          </FilterButtonWithDropdown>
        )}
        {appendWith && isActive && appendWith}
      </Flex>
    );
  },
);
