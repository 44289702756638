import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  ForgotPasswordResponse,
  PostForgotPasswordPayload,
  PostResetPasswordPayload,
  ResetPasswordResponse,
} from './passwordActions.types';

export function postForgotPasswordAction(values: PostForgotPasswordPayload): Action<ForgotPasswordResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.forgotPassword,
    body: {
      ...values,
    },
    config: {
      skipAuthorization: true,
    },
  };
}

export function postResetPasswordAction(values: PostResetPasswordPayload): Action<ResetPasswordResponse> {
  return {
    method: 'POST',
    endpoint: API_ENDPOINTS.resetPassword,
    body: {
      ...values,
    },
    config: {
      skipAuthorization: true,
    },
  };
}
