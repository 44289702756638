import React, { useCallback, useMemo, useState } from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

import { LoadingSpinnerCss, LoadingSpinnerCssProps } from 'components/Loading/LoadingSpinnerCSS';
import { useDimensions } from 'hooks/useDimensions/useDimensions';
import { useMount } from 'hooks/useMount/useMount';
import { delay } from 'utils/delay';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';

import { Image as ImageElement } from './Image';

type GetMaxDimensionsProps = {
  imageWidth: number;
  imageHeight: number;
  boundryWidth: number;
  boundryHeight: number;
};

const getMaxDimensionsInBoundries = ({
  imageWidth,
  imageHeight,
  boundryWidth,
  boundryHeight,
}: GetMaxDimensionsProps) => {
  const widthScalingFactor = boundryWidth / imageWidth;
  const heightScalingFactor = boundryHeight / imageHeight;

  const scalingFactor = Math.min(widthScalingFactor, heightScalingFactor);

  const maxWidth = imageWidth * scalingFactor;
  const maxHeight = imageHeight * scalingFactor;

  return {
    maxWidth,
    maxHeight,
  };
};

type Dimensions = {
  width: number;
  height: number;
};

type Props = React.ComponentPropsWithoutRef<'img'> & {
  sx?: ThemeUIStyleObject;
  loadingSpinnerProps?: LoadingSpinnerCssProps;
};

export const ScalableImage = React.forwardRef<HTMLImageElement, Props>(
  ({ sx, loadingSpinnerProps, ...rest }: Props, ref) => {
    const [imageNaturalDimensions, setImageNaturalDimensions] = useState<Dimensions | null>(null);
    const [parentRef, parentDimensions] = useDimensions();

    const imageSx = useMemo(() => {
      if (!imageNaturalDimensions || !parentDimensions) {
        return null;
      }

      const { width, height } = imageNaturalDimensions;
      const { width: parentWidth, height: parentHeight } = parentDimensions;
      const { maxWidth, maxHeight } = getMaxDimensionsInBoundries({
        imageWidth: width,
        imageHeight: height,
        boundryWidth: parentWidth,
        boundryHeight: parentHeight,
      });

      return {
        height: `${maxHeight}px`,
        width: `${maxWidth}px`,
      };
    }, [imageNaturalDimensions, parentDimensions]);

    useMount(() => {
      const imageSrc = rest.src;

      if (!imageSrc) {
        return;
      }

      const image = new Image();
      image.src = imageSrc;
      image.onload = (e) => {
        const { height, width } = e.target as HTMLImageElement;
        setImageNaturalDimensions({ height, width });
      };
    });

    const refCallback = useCallback(
      async (element: HTMLDivElement | null) => {
        // wait for element to reach its final form
        await delay(400);
        if (!element) {
          return;
        }
        parentRef(element);
      },
      [parentRef],
    );

    return (
      <Flex
        sx={{ justifyContent: 'center', flexGrow: 1, ...(!imageSx && { alignItems: 'center' }) }}
        ref={floatingPromiseReturn(refCallback)}
      >
        {!imageSx ? (
          <LoadingSpinnerCss {...(!!loadingSpinnerProps && loadingSpinnerProps)} />
        ) : (
          <ImageElement {...rest} sx={{ ...(sx && sx), ...imageSx }} ref={ref} />
        )}
      </Flex>
    );
  },
);
