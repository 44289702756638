import { Trans, t } from '@lingui/macro';
import { isEqual } from 'lodash';
import { FC, useMemo } from 'react';
import { Flex, Text } from 'theme-ui';

import { ParsedRequest } from 'state/requests';
import { useRequestDetails } from '../../../../../hooks/useRequestDetails';
import {
  newComparisonDifferenceSx,
  noOriginalNoteSx,
  originalComparisonDifferenceSx,
  requestChangeTextEventSx,
  requestChangeTextTileSx,
  requestChangeWrapperSx,
} from '../styles/styles';

type Props = {
  requestDetails: ParsedRequest | null;
};

const OriginalNote: FC<Props> = ({ requestDetails }) => {
  const note = useMemo(() => {
    if (!requestDetails || !requestDetails.originalEvent) return '—';

    const { originalEvent } = requestDetails;

    if (originalEvent.eventDetails.externalId) {
      return t({
        id: 'request_details.reason_zus',
        message: `Imported from eZLA: ${originalEvent.eventDetails.externalId}`,
      });
    }

    return originalEvent?.eventDetails.note || '—';
  }, [requestDetails]);

  return (
    <Text
      sx={{
        ...requestChangeTextEventSx,
        ...(!note && noOriginalNoteSx),
        ...(requestDetails?.newEvent &&
          !isEqual(requestDetails?.newEvent?.eventDetails.note, requestDetails?.originalEvent?.eventDetails.note) &&
          note &&
          originalComparisonDifferenceSx),
      }}
    >
      {note}
    </Text>
  );
};

const NewNote: FC<Props> = ({ requestDetails }) => {
  const note = useMemo(() => {
    if (!requestDetails) return '—';

    const { newEvent } = requestDetails;

    if (newEvent.eventDetails.externalId) {
      return t({
        id: 'request_details.reason_zus',
        message: `Imported from eZLA: ${newEvent.eventDetails.externalId}`,
      });
    }

    return newEvent?.eventDetails.note || '—';
  }, [requestDetails]);

  return (
    <Text
      sx={{
        ...requestChangeTextEventSx,
        ...(requestDetails?.originalEvent &&
          !isEqual(requestDetails?.newEvent?.eventDetails.note, requestDetails?.originalEvent?.eventDetails.note) &&
          note &&
          newComparisonDifferenceSx),
        ...(!note && { opacity: '0.55' }),
      }}
    >
      {note}
    </Text>
  );
};

export const RequestChangeNote: FC = () => {
  const { parsedRequestDetails: requestDetails } = useRequestDetails();

  if (!requestDetails?.originalEvent?.eventDetails.note && !requestDetails?.newEvent?.eventDetails.note) return null;

  return (
    <Flex sx={requestChangeWrapperSx}>
      <Text sx={requestChangeTextTileSx}>
        <Trans id="note">Note</Trans>
      </Text>
      <OriginalNote requestDetails={requestDetails} />
      <NewNote requestDetails={requestDetails} />
    </Flex>
  );
};
