import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import _ from 'lodash';

import { geoLocationPositionAtom } from 'Kiosk/state/geoLocationState';
import { GEOLOCATION_INTERVAL } from 'Kiosk/constants/constants';
import { usePermissions } from 'Kiosk/hooks/usePermissions';
import { PermissionState } from 'Kiosk/state/permissionState';
import { getGeoLocation } from 'utils/getGeoLocation';

type Props = {
  children: React.ReactElement | React.ReactElement[];
};

export const GeoLocationProvider = ({ children }: Props): React.ReactElement => {
  const [location, setLocation] = useRecoilState(geoLocationPositionAtom);
  const { location: locationPermission } = usePermissions();

  useEffect(() => {
    const internalInterval = () =>
      setInterval(() => {
        const onSuccess = (position: GeolocationPosition) => {
          setLocation((prevState) => {
            if (!_.isEqual(position, prevState)) {
              return position;
            }
            return prevState;
          });
        };

        getGeoLocation(onSuccess, () => setLocation(undefined));
      }, GEOLOCATION_INTERVAL);

    const interval = internalInterval();

    if (locationPermission === PermissionState.checking) {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [location, locationPermission, setLocation]);

  return <>{children}</>;
};
