import { Trans, t } from '@lingui/macro';
import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Container, Flex, Heading, Text } from 'theme-ui';

import { removeNotification } from 'base/Notification/output/actions';
import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { Button } from 'components/ui/Buttons/Button';
import { ElementGroup } from 'components/ui/ElementGroup';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useRedirectPath } from 'hooks/useRedirectPath/useRedirectPath';
import { dateFormatSelector, fullTimeFormatSelector } from 'state/recoilState';
import { dateTime } from 'utils/dateTime';

import { CloseButton, Footer, Header } from './NotificationElements';
import { NotificationProps } from './types';

const defaultProps: Partial<NotificationProps> = {
  content: undefined,
  type: 'info',
  actions: undefined,
  dateUnix: undefined,
  onClick: undefined,
  variant: 'notificationHub',
  redirectTo: undefined,
};

export const Notification = React.forwardRef<HTMLDivElement, NotificationProps>(
  (
    {
      globalNavigate,
      id,
      redirectTo,
      variant = defaultProps.variant,
      type,
      title,
      content,
      actions,
      dateUnix,
      onMouseEnter,
      onMouseLeave,
      ...props
    }: NotificationProps,
    ref,
  ) => {
    const [isHovered, setHovered] = useState<boolean>(false);
    const dateFormat = useRecoilValue(dateFormatSelector);
    const timeFormat = useRecoilValue(fullTimeFormatSelector);
    const { redirectPath } = useRedirectPath(redirectTo);
    const navigate = useAppNavigate();

    const internalOnMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, action: typeof onMouseEnter) => {
      setHovered(true);
      if (action) action(e);
    };

    const internalOnMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, action: typeof onMouseLeave) => {
      setHovered(false);
      if (action) action(e);
    };

    const remove = useCallback(() => {
      removeNotification(id, { removeFromHub: true, removeFromCenter: true });
    }, [id]);

    const actionWithRemove = useCallback(
      (action?: () => void) => () => {
        if (action) action();
        if (redirectTo) {
          if (redirectPath) {
            const n = globalNavigate || navigate;
            n(redirectPath);
          } else {
            window.open(redirectTo);
          }
        }
        remove();
      },
      [redirectPath, navigate, redirectTo, remove, globalNavigate],
    );

    const typeSpecificIcon = ((): Icons => {
      switch (type) {
        case 'success':
          return 'approve';
        case 'danger':
          return 'deny';
        case 'love':
          return 'heart';
        case 'warning':
        case 'info':
        default:
          return 'info';
      }
    })();

    const prepareDate = useCallback(() => {
      const time = dateTime(dateUnix).format(timeFormat);

      if (dateTime(dateUnix).format(dateFormat) === dateTime().format(dateFormat)) {
        return (
          <>
            {t({ id: 'calendar.today' })}, {time}
          </>
        );
      }

      if (dateTime(dateUnix).format(dateFormat) === dateTime().add(-1, 'day').format(dateFormat)) {
        return (
          <>
            <Trans id="calendar.yesterday">Yesterday</Trans>, {time}
          </>
        );
      }

      if (dateTime(dateUnix).year() === dateTime().year()) {
        return dateTime(dateUnix).format('D MMM');
      }

      return dateTime(dateUnix).format(dateFormat);
    }, [dateFormat, timeFormat, dateUnix]);

    const renderActionButtons = (): React.ReactElement | null => {
      if (!actions) return null;

      return (
        <ElementGroup>
          {actions?.map((action) => (
            <Button
              key={action.title}
              shape="rounded"
              variant="minimal"
              size="sm"
              onClick={actionWithRemove(action.onClick)}
            >
              {action.title}
            </Button>
          ))}
        </ElementGroup>
      );
    };

    return (
      <Container
        sx={{ position: 'relative', maxWidth: '90vw', ...props.sx }}
        onMouseEnter={(e) => internalOnMouseEnter(e, onMouseEnter)}
        onMouseLeave={(e) => internalOnMouseLeave(e, onMouseLeave)}
        ref={ref}
        variant={`notifications.container.${variant}`}
      >
        <CloseButton show={isHovered} onClick={remove} />
        <Flex
          onClick={!actions && redirectTo ? actionWithRemove() : undefined}
          sx={{ cursor: !actions && redirectTo ? 'pointer' : 'auto' }}
        >
          {type !== 'default' && (
            <Icon
              size={32}
              type={typeSpecificIcon}
              iconSx={{
                bg: `notification.type.${type}`,
                borderRadius: 'sm',
                p: '0.25rem',
                boxShadow: 'notification.notification',
                border: '1px solid',
                borderColor: 'notification.icon.borderColor',
              }}
              wrapperSx={{
                mr: '0.75rem',
                color: 'notification.icon.color',
              }}
            />
          )}
          <Flex sx={{ flexDirection: 'column', justifyContent: 'center', width: 'fill-available' }}>
            <Header>
              <Heading as="h4" variant="notifications.title">
                {title}
              </Heading>
              {dateUnix && (
                <Text as="span" variant="notifications.date">
                  {prepareDate()}
                </Text>
              )}
            </Header>
            {content && (
              <Text as="p" variant="notifications.message">
                {content}
              </Text>
            )}
            <Footer show={!!actions}>{renderActionButtons()}</Footer>
          </Flex>
        </Flex>
      </Container>
    );
  },
);

Notification.defaultProps = defaultProps;
