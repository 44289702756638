/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { FieldValues, FormProvider, FormProviderProps } from 'react-hook-form';

import { CustomEventsContext } from './components/CustomEventsContext';

type Props<TFieldValues extends FieldValues, TContext = any> = FormProviderProps<TFieldValues, TContext> & {
  onChange: () => void;
  onBlur: () => void;
  children: React.ReactNode | React.ReactNode[];
};

export const FormProviderCustomEvents = <TFieldValues extends FieldValues, TContext = any>({
  onChange,
  onBlur,
  children,
  ...props
}: Props<TFieldValues, TContext>): React.ReactElement => {
  const customEventsValue = useMemo(
    () => ({
      onChange,
      onBlur,
    }),
    [onBlur, onChange],
  );

  return (
    <CustomEventsContext.Provider value={customEventsValue}>
      <FormProvider {...props}>{children}</FormProvider>
    </CustomEventsContext.Provider>
  );
};
