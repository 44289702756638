import { Action } from 'api/types';
import { API_ENDPOINTS } from 'constants/api';

import {
  FetchLocationsResponse,
  AddLocationActionProps,
  AddLocationResponse,
  EditLocationActionProps,
  EditLocationResponse,
  DeleteLocationsActionProps,
  DeleteLocationsResponse,
  FetchLocationActionProps,
  FetchLocationResponse,
} from './locationActions.types';

export const fetchLocationsAction = (): Action<FetchLocationsResponse> => ({
  method: 'GET',
  endpoint: API_ENDPOINTS.location,
});

export const addLocationAction = (body: AddLocationActionProps): Action<AddLocationResponse> => ({
  method: 'POST',
  endpoint: API_ENDPOINTS.addLocation,
  body,
});

export const editLocationAction = (body: EditLocationActionProps): Action<EditLocationResponse> => ({
  method: 'POST',
  endpoint: `${API_ENDPOINTS.editLocation}/${body.id}`,
  body,
});

export const deleteLocationsAction = (body: DeleteLocationsActionProps): Action<DeleteLocationsResponse> => ({
  method: 'DELETE',
  endpoint: API_ENDPOINTS.deleteLocation,
  body,
});

export const fetchLocationAction = (id: FetchLocationActionProps): Action<FetchLocationResponse> => ({
  method: 'GET',
  endpoint: `${API_ENDPOINTS.singleLocation}/${id}`,
});
