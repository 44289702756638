import _ from 'lodash';
import { useCallback } from 'react';
import { useQuery } from 'react-fetching-library';
import { useRecoilState } from 'recoil';

import { fetchAutomationsSettingsAction } from 'api/actions/settings/settingsActions';
import { AutomationsSettingsActionProps, MagnetInterval } from 'api/actions/settings/settingsActions.types';
import { automationsSettingsAtom } from 'state/settings';

export const useAutomationsSettings = () => {
  const { query, abort } = useQuery(fetchAutomationsSettingsAction(), false);
  const [automationsSettings, setAutomationsSettings] = useRecoilState(automationsSettingsAtom);

  const fetchAutomationsSettings = useCallback(async () => {
    const response = await query();
    const { error, payload } = response;

    if (!error && payload) setAutomationsSettings(payload);

    return response;
  }, [query, setAutomationsSettings]);

  const optionsMinutes = [
    MagnetInterval.Minutes0,
    MagnetInterval.Minutes5,
    MagnetInterval.Minutes10,
    MagnetInterval.Minutes15,
    MagnetInterval.Minutes30,
    MagnetInterval.Minutes60,
  ];

  const getParsedAutomationsSettings = useCallback(
    (
      data?: AutomationsSettingsActionProps | null,
      transformTime?: 'seconds' | 'minutes',
    ): AutomationsSettingsActionProps | null => {
      if (!automationsSettings) return null;

      const transformToMinutes = transformTime === 'minutes';
      const transformToSeconds = transformTime === 'seconds';

      const {
        clockInMagnet: { enable, value, toleranceMinutes, hasHalfTheValueTolerance, ...rest },
        clockOutMagnet: {
          enable: clockOutEnable,
          hasHalfTheValueTolerance: clockOutHasHalfTheValueTolerance,
          value: clockOutValue,
          toleranceMinutes: clockOutToleranceMinutes,
          ...clockOutRest
        },
        timeRounding: {
          enableTimeRounding,
          hasHalfTheRoundingValueTolerance,
          roundingValue,
          clockInRoundingToleranceMinutes,
          clockOutRoundingToleranceMinutes,
          ...timeRoundingRest
        },
        autoBalanceOvertime,
      } = data || automationsSettings;

      const clockInToleranceInSeconds = toleranceMinutes ? toleranceMinutes * 60 : toleranceMinutes;

      const clockOutToleranceInSeconds = clockOutToleranceMinutes
        ? clockOutToleranceMinutes * 60
        : clockOutToleranceMinutes;

      const timeRoundingClockInToleranceInSeconds = clockInRoundingToleranceMinutes
        ? clockInRoundingToleranceMinutes * 60
        : clockInRoundingToleranceMinutes;

      const timeRoundingClockOutToleranceInSeconds = clockOutRoundingToleranceMinutes
        ? clockOutRoundingToleranceMinutes * 60
        : clockOutRoundingToleranceMinutes;

      const clockInToleranceInMinutes = _.isNumber(toleranceMinutes) && enable ? toleranceMinutes / 60 : undefined;

      const clockOutToleranceInMinutes =
        _.isNumber(clockOutToleranceMinutes) && clockOutEnable ? clockOutToleranceMinutes / 60 : undefined;

      const timeRoundingClockInToleranceInMinutes =
        _.isNumber(clockInRoundingToleranceMinutes) && enableTimeRounding
          ? clockInRoundingToleranceMinutes / 60
          : undefined;

      const timeRoundingClockOutToleranceInMinutes =
        _.isNumber(clockOutRoundingToleranceMinutes) && enableTimeRounding
          ? clockOutRoundingToleranceMinutes / 60
          : undefined;

      const toleranceClockIn = (() => {
        if (!enable) return null;

        if (transformToSeconds && !_.isNil(clockInToleranceInSeconds))
          return { toleranceMinutes: clockInToleranceInSeconds };

        if (transformToMinutes && !_.isNil(clockInToleranceInMinutes))
          return { toleranceMinutes: clockInToleranceInMinutes };

        if (_.isNil(toleranceMinutes)) return null;

        return { toleranceMinutes };
      })();

      const toleranceClockOut = (() => {
        if (!clockOutEnable) return null;

        if (transformToSeconds && !_.isNil(clockOutToleranceInSeconds))
          return { toleranceMinutes: clockOutToleranceInSeconds };

        if (transformToMinutes && !_.isNil(clockOutToleranceInMinutes))
          return { toleranceMinutes: clockOutToleranceInMinutes };

        if (_.isNil(clockOutToleranceMinutes)) return null;

        return { toleranceMinutes: clockOutToleranceMinutes };
      })();

      const toleranceTimeRounding = (() => {
        if (!enableTimeRounding) return null;

        if (
          transformToSeconds &&
          !_.isNil(timeRoundingClockInToleranceInSeconds && !_.isNil(timeRoundingClockOutToleranceInSeconds))
        ) {
          return {
            clockInRoundingToleranceMinutes: timeRoundingClockInToleranceInSeconds,
            clockOutRoundingToleranceMinutes: timeRoundingClockOutToleranceInSeconds,
          };
        }

        if (
          transformToMinutes &&
          !_.isNil(timeRoundingClockInToleranceInMinutes) &&
          !_.isNil(timeRoundingClockOutToleranceInMinutes)
        ) {
          return {
            clockInRoundingToleranceMinutes: timeRoundingClockInToleranceInMinutes,
            clockOutRoundingToleranceMinutes: timeRoundingClockOutToleranceInMinutes,
          };
        }

        if (_.isNil(clockInRoundingToleranceMinutes) || _.isNil(clockOutRoundingToleranceMinutes)) return null;

        return {
          clockInRoundingToleranceMinutes,
          clockOutRoundingToleranceMinutes,
        };
      })();

      return {
        autoBalanceOvertime,
        clockInMagnet: {
          ..._.omit(rest, ['timeRoundingSamples']),
          value: enable ? value : undefined,
          enable,
          hasHalfTheValueTolerance: !enable ? true : hasHalfTheValueTolerance,
          ...(!hasHalfTheValueTolerance && toleranceClockIn),
        },
        clockOutMagnet: {
          ..._.omit(clockOutRest, ['timeRoundingSamples']),
          value: clockOutEnable ? clockOutValue : undefined,
          enable: clockOutEnable,
          hasHalfTheValueTolerance: !clockOutEnable ? true : clockOutHasHalfTheValueTolerance,
          ...(!clockOutHasHalfTheValueTolerance && toleranceClockOut),
        },
        timeRounding: {
          ..._.omit(timeRoundingRest, ['timeRoundingSamples']),
          enableTimeRounding,
          roundingValue: enableTimeRounding ? roundingValue : undefined,
          hasHalfTheRoundingValueTolerance: !enableTimeRounding ? true : hasHalfTheRoundingValueTolerance,
          ...(!hasHalfTheRoundingValueTolerance && toleranceTimeRounding),
        },
      };
    },
    [automationsSettings],
  );

  const getDefaultValuesAutomationsSettings = useCallback(() => {
    const automations = getParsedAutomationsSettings(null, 'seconds');

    if (!automations) return null;

    const {
      autoBalanceOvertime,
      clockInMagnet: { value, enable, hasHalfTheValueTolerance, toleranceMinutes, ...clockInRest },
      clockOutMagnet: {
        value: clockOutValue,
        enable: clockOutEnable,
        hasHalfTheValueTolerance: clockOutHasHalfTheValueTolerance,
        toleranceMinutes: clockOutToleranceMinutes,
        ...clockOutRest
      },
      timeRounding: {
        roundingValue,
        enableTimeRounding,
        hasHalfTheRoundingValueTolerance,
        clockInRoundingToleranceMinutes,
        clockOutRoundingToleranceMinutes,
        ...timeRoundingRest
      },
    } = automations;

    return {
      autoBalanceOvertime,
      clockInMagnet: {
        ...clockInRest,
        enable,
        hasHalfTheValueTolerance,
        value: !enable ? MagnetInterval.Minutes5 : value,
        toleranceMinutes: hasHalfTheValueTolerance ? MagnetInterval.Minutes0 : Number(toleranceMinutes),
      },
      clockOutMagnet: {
        ...clockOutRest,
        enable: clockOutEnable,
        hasHalfTheValueTolerance: clockOutHasHalfTheValueTolerance,
        value: !clockOutEnable ? MagnetInterval.Minutes5 : clockOutValue,
        toleranceMinutes: clockOutHasHalfTheValueTolerance ? MagnetInterval.Minutes0 : Number(clockOutToleranceMinutes),
      },
      timeRounding: {
        ...timeRoundingRest,
        enableTimeRounding,
        hasHalfTheRoundingValueTolerance,
        roundingValue: !enableTimeRounding ? MagnetInterval.Minutes5 : roundingValue,
        clockInRoundingToleranceMinutes: hasHalfTheRoundingValueTolerance
          ? MagnetInterval.Minutes0
          : Number(clockInRoundingToleranceMinutes),
        clockOutRoundingToleranceMinutes: hasHalfTheRoundingValueTolerance
          ? MagnetInterval.Minutes0
          : Number(clockOutRoundingToleranceMinutes),
      },
    };
  }, [getParsedAutomationsSettings]);

  return {
    automationsSettings,
    optionsMinutes,
    setAutomationsSettings,
    fetchAutomationsSettings,
    getDefaultValuesAutomationsSettings,
    getParsedAutomationsSettings,
    fetchAbort: abort,
  };
};
