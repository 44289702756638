import React from 'react';
import { Flex, FlexOwnProps } from 'theme-ui';

import { LoadingSpinnerProps } from 'components/Loading/LoadingSpinner';
import { LoadingSpinnerCss } from 'components/Loading/LoadingSpinnerCSS';

type Props = Pick<LoadingSpinnerProps, 'color' | 'size'> & FlexOwnProps;

export const CenteredLoadingSpinner = React.forwardRef<HTMLDivElement, Props>(
  ({ color, size, sx, ...props }: Props, ref) => (
    <Flex
      {...props}
      sx={{
        height: '100%',
        minHeight: '100px',
        flexGrow: 2,
        alignItems: 'center',
        justifyContent: 'center',
        ...(sx && sx),
      }}
    >
      <LoadingSpinnerCss ref={ref} color={color} size={size || 3} />
    </Flex>
  ),
);
