import { APP_NAME, INEWI_HELP, UNRUBBLE_HELP } from 'constants/common';

type Props = {
  inEwi?: {
    pl: string;
  };
  unrubble?: {
    en: string;
  };
};

/**
 * Creates a help link based on the apps domain.
 *
 * @param {Object} Props
 * @param {string} Props.inEwi InEwi partial link.
 * @param {string} Props.unrubble Unrubble partial link.
 *
 * @return {string} Returns the created link.
 */

export const useHelpLink = ({ inEwi, unrubble }: Props): string => {
  if (APP_NAME === 'inEwi') {
    if (inEwi) return `${INEWI_HELP}/pl${inEwi.pl}`;

    return `${INEWI_HELP}/pl`;
  }

  if (unrubble) return `${UNRUBBLE_HELP}/en${unrubble.en}`;

  return `${UNRUBBLE_HELP}/en`;
};
