import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { useSetRecoilState } from 'recoil';

import { addTimeEventNoteAction } from 'api/actions/timeEvent/timeEventActions';
import { AddTimeEventNoteActionProps, ExtendedTimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { useModal } from 'components/Modal/output/useModal';
import { useRefreshReport } from 'pages/Reports/output/useRefreshReport';
import { addNoteLoadingSaveAtom } from 'state/clockLog';
import { useRefreshClockLog } from '../../hooks/useRefreshClockLog';

type Props = {
  prevTimeEvent: ExtendedTimeEvent;
};

export const useAddEventNote = ({ prevTimeEvent }: Props) => {
  useLingui();
  const { handleClose } = useModal();

  const { id, personId } = prevTimeEvent;
  const setIsSaveLoading = useSetRecoilState(addNoteLoadingSaveAtom);
  const { mutate } = useMutation(addTimeEventNoteAction);

  const { clockLogInitialized, refreshClockLogForPeopleIds } = useRefreshClockLog([personId]);
  const { reportInitialized, updateReportForIds } = useRefreshReport([personId]);

  const addEventNote = useCallback(
    async ({ note: newNote }: Pick<AddTimeEventNoteActionProps, 'note'>) => {
      setIsSaveLoading(true);

      const requestBody = {
        id,
        note: newNote,
      };

      const { error } = await mutate(requestBody);

      if (!error) {
        handleClose();
        addSnackbar({
          message: t({ id: 'clock_log.add_note.success', message: 'Note successfully added!' }),
          variant: 'success',
        });

        if (clockLogInitialized) void refreshClockLogForPeopleIds();
        if (reportInitialized) void updateReportForIds();
      }
      setIsSaveLoading(false);
    },
    [
      clockLogInitialized,
      handleClose,
      id,
      mutate,
      refreshClockLogForPeopleIds,
      reportInitialized,
      setIsSaveLoading,
      updateReportForIds,
    ],
  );

  const addEventNoteError = useCallback(() => setIsSaveLoading(false), [setIsSaveLoading]);

  return { addEventNote, addEventNoteError };
};
