import { FC } from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { RequestFormType } from 'api/actions/requests/requestsActions.types';
import { RequestsHistorySelect } from '../../RequestsHistorySelect';

import { Calendar } from './Calendar';
import { EditionObserver } from './EditionObserver';
import { Name } from './Name';
import { Place } from './Place';
import { ResetFormObserver } from './ResetFormObserver';
import { SaveStateObserver } from './SaveStateObserver';

const RequestsHistorySelectWrapper: FC = () => <RequestsHistorySelect type={RequestFormType.BusinessTrip} />;

type Props = FlexProps;

type BusinessTripContainerProps = Props;

type BusinessTripContainerComponent = FC<BusinessTripContainerProps> & {
  Name: typeof Name;
  Place: typeof Place;
  Calendar: typeof Calendar;
  EditionObserver: typeof EditionObserver;
  SaveStateObserver: typeof SaveStateObserver;
  ResetFormObserver: typeof ResetFormObserver;
  RequestsHistorySelect: typeof RequestsHistorySelectWrapper;
};

export const BusinessTripContainer: BusinessTripContainerComponent = ({ children }: Props) => (
  <Flex sx={{ flexDirection: 'column', gap: 4, position: 'relative' }}>{children}</Flex>
);

BusinessTripContainer.Name = Name;
BusinessTripContainer.Place = Place;
BusinessTripContainer.Calendar = Calendar;
BusinessTripContainer.EditionObserver = EditionObserver;
BusinessTripContainer.SaveStateObserver = SaveStateObserver;
BusinessTripContainer.ResetFormObserver = ResetFormObserver;
BusinessTripContainer.RequestsHistorySelect = RequestsHistorySelectWrapper;
