import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { preferencesSettingsAtom, resetFormButtonAtom } from 'state/settings';
import { HeadingTip } from '../HeadingTip';
import { SettingsGroup } from '../SettingsGroup/SettingsGroup';
import { SettingsLoadingSpinnerCss } from '../SettingsLoadingSpinnerCss';

import { PreferencesForm } from './components/PreferencesForm/PreferencesForm';

export const Preferences = (): React.ReactElement => {
  useLingui();
  const preferencesSettings = useRecoilValue(preferencesSettingsAtom);
  const resetFormButton = useResetRecoilState(resetFormButtonAtom);

  useEffect(
    () => () => {
      resetFormButton();
    },
    [resetFormButton],
  );

  return (
    <>
      <HeadingTip
        label={t({
          id: 'settings.user.preferences.header',
          message: 'Preferences',
        })}
      />
      {preferencesSettings ? (
        <SettingsGroup variant="sm">
          <PreferencesForm preferencesSettings={preferencesSettings} />
        </SettingsGroup>
      ) : (
        <SettingsLoadingSpinnerCss />
      )}
    </>
  );
};
