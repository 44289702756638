import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';

import { Employee, TimeEventType } from 'api/actions/organizationSession/organizationSessionActions.types';
import {
  FetchReportEmployeeDataType,
  FetchReportResponse,
  ReportDayDataType,
  ReportsTimeEvent,
  ReportSummaryType,
} from 'api/actions/reports/reportsActions.types';
import { RequestFormType, RequestState } from 'api/actions/requests/requestsActions.types';
import { ViewSettings } from 'api/actions/userSession/userSessionActions.types';
import { ErrorResponse } from 'api/types';
import { ListNames, StickyListProps } from 'components/StickyList/types';
import { DEFAULT_WORK_STATUSES_IDS } from 'constants/common';
import { ErrorInnerCodes } from 'constants/errorInnerCodes';
import { FilterGroupNames, HideEmptyFilter, ReportDayStateFilters } from 'layouts/AuthorizedApp/AsideFilters/types';
import { getDayStateWithAnnotations } from 'pages/Reports/output/getDayStateWithAnnotations';
import { dateTime } from 'utils/dateTime';
import { isDaysEmpty } from 'utils/isDaysEmpty/isDaysEmpty';
import { isRecoilDefaultValue } from 'utils/isRecoilDefaultValue';

import { SchedulesMap } from './calendar';
import { filteredEmployeesSelector, ParsedEmployee, parsedEmployeesSelector } from './employees';
import { filterGroupStateAtomFamily, isActiveFilterSelectorFamily } from './filters';
import { selectedRowsIdsSelectorFamily } from './list';
import {
  customRequestTypesDictionarySelector,
  organizationSessionAtom,
  timeEventTypesDictionarySelector,
  timeOffTypesDictionarySelector,
} from './organizationSession';
import { userSessionAtom } from './userSession';

export const ID_DATE_DIVIDER = '--';

export const reportResponseAtom = atom<(FetchReportResponse & ErrorResponse) | null>({
  key: 'reportResponse',
  default: null,
});

export const reportResponseSelector = selector({
  key: 'reportResponse_selector',
  get: ({ get }) => {
    const reportResponse = get(reportResponseAtom);

    if (!reportResponse || reportResponse.innerCode) return null;

    return reportResponse;
  },
});

export const reportResponseErrorSelector = selector<ErrorResponse | null>({
  key: 'reportResponseError',
  get: ({ get }) => {
    const reportResponse = get(reportResponseAtom);

    if (!reportResponse) return null;

    const { innerCode, message, data } = reportResponse;

    if (!innerCode) return null;

    return { innerCode, message, data };
  },
});

export const reportHolidaysSelector = selector({
  key: 'reportHolidays',
  get: ({ get }) => {
    const report = get(reportResponseSelector);

    if (!report) return null;

    const { holidays } = report;
    const holidaysMap = new Map(holidays);

    return holidaysMap;
  },
});

export const reportDaysSelector = selector({
  key: 'reportDays',
  get: ({ get }) => {
    const report = get(reportResponseSelector);

    if (!report) return null;

    const { days } = report;

    return days;
  },
});

type ReportEmployeeData = Pick<ParsedEmployee, 'id' | 'avatarUrl' | 'name' | 'tags' | 'role' | 'editablePermissions'> &
  Pick<FetchReportEmployeeDataType, 'hasPendingRequests' | 'hasUnpublishedChanges'>;

// ATTENDANCE LIST

export type AttendanceReportData = {
  days: Map<number, ReportDayDataType | null>;
} & ReportEmployeeData;

export type AttendanceReportMap = Map<Employee['id'], AttendanceReportData>;

export const attendanceReportSelector = selector({
  key: 'attendanceReport',
  get: ({ get }) => {
    const report = get(reportResponseSelector);
    const employeesMap = get(parsedEmployeesSelector);

    if (!report || !employeesMap) return null;

    const { employeesData } = report;
    const employeesDataMap = new Map(employeesData);
    const attendanceReportMap: AttendanceReportMap = new Map();

    employeesMap.forEach((data, employeeId) => {
      const { name, role, tags, avatarUrl, editablePermissions } = data;
      const employeeData = employeesDataMap.get(`${employeeId}`);

      attendanceReportMap.set(employeeId, {
        days: new Map(employeeData?.days),
        id: employeeId,
        editablePermissions,
        name,
        role,
        tags,
        avatarUrl,
        hasPendingRequests: employeeData?.hasPendingRequests,
        hasUnpublishedChanges: employeeData?.hasUnpublishedChanges,
      });
    });

    return attendanceReportMap;
  },
});

export const selectedAttendanceListIdsSelector = selector<ReportEmployeeData['id'][]>({
  key: 'attendanceReport__selectedEmployees',
  get: ({ get }) => get(selectedRowsIdsSelectorFamily(ListNames.ATTENDANCE_LIST)),
});

export const filteredAttendanceReportSelector = selector({
  key: 'filteredAttendanceReport',
  get: ({ get }) => {
    const attendanceReport = get(attendanceReportSelector);
    const filteredEmployees = get(filteredEmployeesSelector);
    const reportResponseError = get(reportResponseErrorSelector);
    const todayDateUnix = dateTime(undefined, { utc: true })
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .unix();

    const reportDayState = get(filterGroupStateAtomFamily(FilterGroupNames.REPORTS_ATTENDANCE_STATE));
    const hideEmptyEmployees = get(
      isActiveFilterSelectorFamily({
        groupName: FilterGroupNames.HIDE_EMPTY,
        filterId: HideEmptyFilter.IsActive,
      }),
    );

    if (
      !attendanceReport &&
      reportResponseError &&
      reportResponseError.innerCode === ErrorInnerCodes.DateRangeAccessDenied
    ) {
      return new Map<string, AttendanceReportData>();
    }

    if (!attendanceReport || !filteredEmployees) return null;

    // Filter functions
    const filterByDayState = (dayData: ReportDayDataType | null, dateUnix: number) => {
      if (dayData) {
        if (reportDayState === null) {
          return true;
        }

        const hasTimeOff = !!_.find(
          dayData.requests,
          (x) =>
            x.type === RequestFormType.TimeOff &&
            (x.state === RequestState.Accepted || x.state === RequestState.Pending),
        );

        if (reportDayState.includes(ReportDayStateFilters.Present)) {
          if (
            dayData.correlatedDay &&
            dayData.correlatedDay.dateUnix &&
            !dayData.correlatedDay.hidePresent &&
            !dayData.missingWorkTime &&
            !dayData.errors
          ) {
            return true;
          }

          if (
            (dayData.workTime && dayData.workTime > 0) ||
            (dayData.overtime && dayData.overtime > 0) ||
            (dayData.withoutSchedule && dayData.withoutSchedule > 0) ||
            (dayData.autoBalance && dayData.autoBalance.available && dayData.autoBalance.available > 0)
          )
            return true;
        }
        if (reportDayState.includes(ReportDayStateFilters.IncompleteShift)) {
          if (
            dayData.correlatedDay &&
            dayData.correlatedDay.dateUnix &&
            dayData.missingWorkTime &&
            dayData.missingWorkTime > 0 &&
            !dayData.errors &&
            !dayData.correlatedDay.hidePresent
          ) {
            return true;
          }

          if (
            ((dayData.workTime && dayData.workTime > 0) || (dayData.overtime && dayData.overtime > 0) || hasTimeOff) &&
            dayData.missingWorkTime &&
            dayData.missingWorkTime > 0
          )
            return true;
        }
        if (reportDayState.includes(ReportDayStateFilters.Overtime)) {
          if (dayData.overtime && dayData.overtime > 0) return true;
        }

        if (reportDayState.includes(ReportDayStateFilters.Absent)) {
          if (
            (!dayData.workTime || dayData.workTime === 0) &&
            (!dayData.overtime || dayData.overtime === 0) &&
            (!dayData.withoutSchedule || dayData.withoutSchedule === 0) &&
            dayData.schedules &&
            dayData.schedules.length > 0 &&
            dateUnix < todayDateUnix &&
            !hasTimeOff &&
            !dayData.autoBalance
          )
            return true;
        }

        if (reportDayState.includes(ReportDayStateFilters.Error)) {
          if (dayData.errors && dayData.errors.length > 0) return true;
        }

        if (dayData.requests && dayData.requests.length > 0) {
          if (reportDayState.includes(ReportDayStateFilters.TimeOff)) {
            if (
              _.find(
                dayData.requests,
                (x) =>
                  x.type === RequestFormType.TimeOff &&
                  (x.state === RequestState.Accepted || x.state === RequestState.Pending),
              )
            )
              return true;
          }
          if (reportDayState.includes(ReportDayStateFilters.CustomRequest)) {
            if (
              _.find(
                dayData.requests,
                (x) =>
                  x.type === RequestFormType.Custom &&
                  (x.state === RequestState.Accepted || x.state === RequestState.Pending),
              )
            )
              return true;
          }
          if (reportDayState.includes(ReportDayStateFilters.BusinessTrip)) {
            if (
              _.find(
                dayData.requests,
                (x) =>
                  x.type === RequestFormType.BusinessTrip &&
                  (x.state === RequestState.Accepted || x.state === RequestState.Pending),
              )
            )
              return true;
          }
        }
      }

      return false;
    };

    const newMap = new Map([...attendanceReport]);

    newMap.forEach((data, employeeId) => {
      if (!filteredEmployees.has(`${employeeId}`)) {
        newMap.delete(employeeId);
        return;
      }

      const days = new Map([...data.days]);
      days.forEach((day, dayId) => {
        if (!filterByDayState(day, dayId)) {
          days.set(dayId, null);
        }
      });

      if (hideEmptyEmployees && isDaysEmpty(days)) {
        newMap.delete(employeeId);
        return;
      }

      newMap.set(employeeId, { ...data, days });
    });

    return newMap;
  },
});

export const attendanceReportColumns = atom<StickyListProps<AttendanceReportData>['columns'] | null>({
  key: 'attendanceList__columns',
  default: null,
});

type PersonDayReportSelectorConfig = {
  id: ParsedEmployee['id'];
  dateUnix?: number;
};

export const personDayReportSelector = selectorFamily<AttendanceReportData | null, PersonDayReportSelectorConfig>({
  key: 'personDayReport',
  get:
    ({ id, dateUnix }) =>
    ({ get }) => {
      const attendanceReport = get(attendanceReportSelector);
      if (!attendanceReport) return null;

      const personReport = attendanceReport.get(id);

      if (!personReport) return null;

      if (!dateUnix) return personReport;

      const dayReport = personReport.days.get(dateUnix);

      const newDayMap = new Map([[dateUnix, dayReport || null]]);

      return {
        ...personReport,
        days: newDayMap,
      };
    },
});

export const filteredPersonDayReportSelector = selectorFamily<
  AttendanceReportData | null,
  PersonDayReportSelectorConfig
>({
  key: 'filteredPersonDayReport',
  get:
    ({ id, dateUnix }) =>
    ({ get }) => {
      const filteredAttendanceReport = get(filteredAttendanceReportSelector);
      if (!filteredAttendanceReport) return null;

      const personReport = filteredAttendanceReport.get(id);

      if (!personReport) return null;

      if (!dateUnix) return personReport;

      const dayReport = personReport.days.get(dateUnix);

      const newDayMap = new Map([[dateUnix, dayReport || null]]);

      return {
        ...personReport,
        days: newDayMap,
      };
    },
});

//
// TIMESHEET
//

const SummaryEmptyValues: ReportSummaryType = {
  customRequests: undefined,
  dayStates: undefined,
  payroll: undefined,
  scheduledTimeDuration: undefined,
  timeOffs: undefined,
  timers: undefined,
  workStatuses: undefined,
};

export type TimesheetsReportData = {
  days: Map<number, ReportDayDataType | null>;
  summary: ReportSummaryType;
  hasErrors: boolean;
  dateUnix?: number;
} & ReportEmployeeData;
export type TimesheetsReportMap = Map<Employee['id'], TimesheetsReportData>;

// Timesheet Details Ids
export const timesheetsDetailsIds = atom<{ value: TimesheetsReportData['id'][] } | null>({
  key: 'timesheetsDetailsIds',
  default: null,
});

export const timesheetsDetailsIdsSelector = selector<TimesheetsReportData['id'] | null>({
  key: 'timesheetsDetailsIdsSelector',
  get: () => null,
  set: ({ set }, newValue) => {
    if (isRecoilDefaultValue(newValue)) return;

    if (newValue) {
      set(timesheetsDetailsIds, (prev) => {
        if (prev && prev.value) {
          const hideDetails = !!_.find(prev.value, (i) => i === newValue);

          if (hideDetails) {
            const newArr = _.filter(prev.value, (i) => i !== newValue);

            return {
              value: [...newArr],
            };
          }

          return {
            value: [...prev.value, newValue],
          };
        }

        return {
          value: [newValue],
        };
      });
    }
  },
});

export const timesheetsDetailsVisible = selectorFamily<boolean, TimesheetsReportData['id'] | undefined>({
  key: 'timesheetsDetailsVisible',
  get:
    (id) =>
    ({ get }) => {
      if (!id) return false;
      const ids = get(timesheetsDetailsIds);

      if (ids) {
        const isVisible = !!_.find(ids.value, (detailsId) => detailsId === id);

        return isVisible;
      }
      return false;
    },
  set:
    (id) =>
    ({ set }, newValue) => {
      if (isRecoilDefaultValue(newValue)) return;

      if (id && newValue) {
        set(timesheetsDetailsIds, (prev) => {
          if (prev && prev.value) {
            const hideDetails = !!_.find(prev.value, (i) => i === id);

            if (hideDetails) {
              const newArr = _.filter(prev.value, (i) => i !== id);

              return {
                value: [...newArr],
              };
            }

            return {
              value: [...prev.value, id],
            };
          }

          return {
            value: [id],
          };
        });
      }
    },
});

// report
export const timesheetsReportSelector = selector({
  key: 'timesheetsReport',
  get: ({ get }) => {
    const report = get(reportResponseSelector);
    const employeesMap = get(parsedEmployeesSelector);
    // const dates = get(dateRangeFilterAtom);

    const datesArray = get(reportDaysSelector);

    const timeOffTypes = get(timeOffTypesDictionarySelector);
    const customRequestTypes = get(customRequestTypesDictionarySelector);
    const timeEventTypes = get(timeEventTypesDictionarySelector);

    // const numberOfDays = dates && dateTime(dates.endDateUnix).diff(dateTime(dates.startDateUnix), 'day') + 1;
    // const oneDayUnix = 24 * 60 * 60;
    const todayDateUnix = dateTime(undefined, { utc: true }).startOf('day').unix();

    // const datesArray =
    //   dates && new Array(numberOfDays).fill(0).map((v, index) => dates.startDateUnix + index * oneDayUnix);

    if (!report || !employeesMap || !datesArray) return null;

    const { employeesData } = report;
    const employeesDataMap = new Map(employeesData);
    const timesheetReportMap: TimesheetsReportMap = new Map();

    employeesMap.forEach((data, employeeId) => {
      const { name, role, tags, avatarUrl, editablePermissions } = data;
      const employeeData = employeesDataMap.get(`${employeeId}`);

      timesheetReportMap.set(employeeId, {
        days: new Map(employeeData?.days),
        id: employeeId,
        editablePermissions,
        name,
        role,
        tags,
        avatarUrl,
        hasErrors: !!employeeData?.hasErrors,
        hasPendingRequests: !!employeeData?.hasPendingRequests,
        hasUnpublishedChanges: !!employeeData?.hasUnpublishedChanges,
        summary: employeeData ? employeeData.summary : SummaryEmptyValues,
      });

      if (datesArray) {
        datesArray.forEach((dateUnix) => {
          const dayDetails = new Map(employeeData && employeeData.days && employeeData.days).get(dateUnix);
          const filteredRequests =
            dayDetails && _.filter(dayDetails.requests, (req) => !req.isDeleted && req.state === RequestState.Accepted);

          const getParsedCustomRequests = (): ReportSummaryType['customRequests'] => {
            if (dayDetails && customRequestTypes) {
              const filtered = _.filter(filteredRequests, (req) => !!(req.typeId && customRequestTypes[req.typeId]));

              let mergedFiltered: typeof filtered = [];

              _.forEach(filtered, (req) => {
                const found = _.find(mergedFiltered, (x) => x.typeId === req.typeId);

                if (found) {
                  mergedFiltered = _.map(mergedFiltered, (x) => {
                    if (x.typeId === req.typeId) {
                      const newDuration = {
                        dayCount: x.duration.dayCount,
                        seconds: x.duration.seconds + req.duration.seconds,
                      };

                      return {
                        ...x,
                        duration: newDuration,
                      };
                    }

                    return x;
                  });

                  return;
                }

                mergedFiltered.push(req);
              });

              return mergedFiltered.map((req) => ({
                id: `${req.typeId}`,
                duration: req.duration,
              }));
            }

            return undefined;
          };

          const getParsedTimeOffs = (): ReportSummaryType['timeOffs'] => {
            if (dayDetails && timeOffTypes) {
              const filtered = _.filter(filteredRequests, (req) => !!(req.typeId && timeOffTypes[req.typeId]));

              return filtered.map((req) => ({
                id: `${req.typeId}`,
                duration: req.duration,
              }));
            }

            return undefined;
          };

          const getParsedTimers = (): ReportSummaryType['timers'] => {
            if (dayDetails) {
              return {
                autoBreakDuration: dayDetails.autoBreak,
                missingWorkTimeDuration: dayDetails.missingWorkTime,
                nightTimeDuration: dayDetails.nightTime,
                overtimeDuration: dayDetails.overtime,
                withoutScheduleDuration: dayDetails.withoutSchedule,
                workTimeDuration: dayDetails.workTime,
                autoUnpaidBreakDuration: dayDetails.autoUnpaidBreak,
              };
            }

            return undefined;
          };

          // const getParsedSchedules = (): ReportSummaryType['scheduledTimeDuration'] => {
          //   if (dayDetails) {
          //     return _.reduce(
          //       dayDetails.schedules,
          //       (res, val) => {
          //         if (!val.isDeleted && val.isPublished && val.details.workTimeUnix) {
          //           return res + val.details.workTimeUnix;
          //         }
          //         return res;
          //       },
          //       0,
          //     );
          //   }

          //   return undefined;
          // };

          const getDayStates = (): ReportSummaryType['dayStates'] => {
            if (dayDetails) {
              const states = getDayStateWithAnnotations(
                dayDetails,
                timeOffTypes,
                customRequestTypes,
                timeEventTypes,
                dateUnix < todayDateUnix,
              );
              if (states) {
                return {
                  absentCount: states.isAbsent ? 1 : undefined,
                  businessTripCount: states.businessTripName ? 1 : undefined,
                  customRequestCount: states.customRequestAbbrs ? 1 : undefined,
                  incompleteShiftCount: states.isIncompleteShift ? 1 : undefined,
                  overtimeCount: states.isOvertime ? 1 : undefined,
                  presentCount: states.isPresent ? 1 : undefined,
                  timeOffCount: states.timeOffAbbrs ? 1 : undefined,
                };
              }
            }

            return undefined;
          };

          const getStartStopEvents = (): ReportSummaryType['startStopEvents'] => {
            if (dayDetails) {
              const filteredEvents = _.filter(
                dayDetails.clockLog,
                (el) => el.typeId === DEFAULT_WORK_STATUSES_IDS.ENTER || el.typeId === DEFAULT_WORK_STATUSES_IDS.EXIT,
              );

              const mappedEvents = _.chain(filteredEvents)
                .map((ev, index, arr) => {
                  const prevEvent = arr[index - 1];
                  const nextEvent = arr[index + 1];

                  // check if END
                  if (ev.typeId === DEFAULT_WORK_STATUSES_IDS.EXIT) {
                    // check if prev exists & is START
                    if (prevEvent && prevEvent.typeId === DEFAULT_WORK_STATUSES_IDS.ENTER) {
                      return {};
                    }

                    // if not then there is missing START ev
                    return {
                      startEvent: undefined,
                      stopEvent: ev,
                    };
                  }

                  return {
                    startEvent: ev,
                    stopEvent: nextEvent && nextEvent.typeId === DEFAULT_WORK_STATUSES_IDS.EXIT ? nextEvent : undefined,
                  };
                })
                .filter((ev) => !_.isEmpty(ev))
                .value();

              if (mappedEvents.length > 0) {
                return mappedEvents;
              }
            }

            return undefined;
          };

          const summarizedDayReport: ReportSummaryType = {
            customRequests: getParsedCustomRequests(),
            payroll: dayDetails?.payroll,
            scheduledTimeDuration: dayDetails?.scheduledTime,
            timeOffs: getParsedTimeOffs(),
            timers: getParsedTimers(),
            workStatuses: dayDetails?.workStatuses,
            dayStates: getDayStates(),
            startStopEvents: getStartStopEvents(),
          };

          return timesheetReportMap.set(`${employeeId}${ID_DATE_DIVIDER}${dateUnix}`, {
            id: `${employeeId}${ID_DATE_DIVIDER}${dateUnix}`,
            editablePermissions,
            days: new Map(employeeData?.days),
            dateUnix,
            name,
            role,
            tags,
            avatarUrl,
            hasErrors: dateUnix
              ? !!(dayDetails && dayDetails.errors && dayDetails.errors.length > 0)
              : !!employeeData?.hasErrors,
            hasPendingRequests: dayDetails?.hasPendingRequests,
            hasUnpublishedChanges: dayDetails?.hasUnpublishedChanges,
            summary: summarizedDayReport || SummaryEmptyValues,
          });
        });
      }
    });

    return timesheetReportMap;
  },
});

export const filteredTimesheetsReportSelector = selector({
  key: 'filteredTimesheetsReport',
  get: ({ get }) => {
    const report = get(timesheetsReportSelector);
    const filteredEmployees = get(filteredEmployeesSelector);
    const reportResponseError = get(reportResponseErrorSelector);

    const reportDayState = get(filterGroupStateAtomFamily(FilterGroupNames.REPORTS_ATTENDANCE_STATE));
    const hideEmptyEmployees = get(
      isActiveFilterSelectorFamily({
        groupName: FilterGroupNames.HIDE_EMPTY,
        filterId: HideEmptyFilter.IsActive,
      }),
    );

    if (!report && reportResponseError && reportResponseError.innerCode === ErrorInnerCodes.DateRangeAccessDenied) {
      return new Map();
    }

    if (!report || !filteredEmployees) return null;

    // Filter functions
    const filterByDayState = (summaryData: ReportSummaryType | null, hasErrors?: boolean) => {
      if (summaryData) {
        if (reportDayState === null) {
          return true;
        }
        if (summaryData.dayStates) {
          const {
            absentCount,
            businessTripCount,
            customRequestCount,
            incompleteShiftCount,
            overtimeCount,
            presentCount,
            timeOffCount,
          } = summaryData.dayStates;

          if (reportDayState.includes(ReportDayStateFilters.Present)) {
            if (presentCount && presentCount > 0) return true;
          }
          if (reportDayState.includes(ReportDayStateFilters.IncompleteShift)) {
            if (incompleteShiftCount && incompleteShiftCount > 0) return true;
          }
          if (reportDayState.includes(ReportDayStateFilters.Overtime)) {
            if (overtimeCount && overtimeCount > 0) return true;
          }

          if (reportDayState.includes(ReportDayStateFilters.Absent)) {
            if (absentCount && absentCount > 0) return true;
          }

          if (reportDayState.includes(ReportDayStateFilters.TimeOff)) {
            if (timeOffCount && timeOffCount > 0) return true;
          }
          if (reportDayState.includes(ReportDayStateFilters.CustomRequest)) {
            if (customRequestCount && customRequestCount > 0) return true;
          }
          if (reportDayState.includes(ReportDayStateFilters.BusinessTrip)) {
            if (businessTripCount && businessTripCount > 0) return true;
          }
        }

        if (reportDayState.includes(ReportDayStateFilters.Error)) {
          if (hasErrors) return true;
        }
      }

      return false;
    };

    const newMap = new Map([...report]);

    newMap.forEach((data, employeeId) => {
      // TODO: Find a better way to parse id & dateUnix for maps
      const splittedEmployeeId = employeeId.split(ID_DATE_DIVIDER);
      const [id, dateUnix] = splittedEmployeeId;

      const showDetails = get(timesheetsDetailsVisible(id));

      if (!!id && !!dateUnix && !showDetails) {
        newMap.delete(employeeId);
        return;
      }

      if (!filteredEmployees.has(`${id}`)) {
        newMap.delete(employeeId);
        return;
      }

      const { summary, hasErrors } = data;

      if (!filterByDayState(summary, hasErrors)) {
        if (hideEmptyEmployees && !dateUnix) {
          newMap.delete(id);
        } else {
          newMap.set(employeeId, { ...data, summary: SummaryEmptyValues, hasErrors: false });
        }
      }

      if (hideEmptyEmployees) {
        if ((_.every(summary, (i) => i === undefined) && !dateUnix) || isDaysEmpty(data?.days)) {
          newMap.delete(id);
        }

        if (showDetails) {
          const isHidden = !newMap.has(id);

          if (isHidden && dateUnix) {
            newMap.delete(employeeId);
          }
        }
      }
    });
    return newMap;
  },
});

export const timesheetsReportColumns = atom<StickyListProps<TimesheetsReportData>['columns'] | null>({
  key: 'timesheetsReport__columns',
  default: null,
});

export const selectedTimesheetReportIdsSelector = selector<ReportEmployeeData['id'][]>({
  key: 'timesheetsReport__selectedEmployees',
  get: ({ get }) => get(selectedRowsIdsSelectorFamily(ListNames.TIMESHEETS)),
});

export const timesheetsReportHiddenColumns = selector({
  key: 'timesheetsReport__hiddenColumns',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);

    if (userSession && userSession.viewsSettings && userSession.viewsSettings.timesheetsReport) {
      const { hiddenColumns } = userSession.viewsSettings.timesheetsReport;

      return hiddenColumns;
    }
    return [];
  },
});

export const timesheetsReportShowSummaryRowSelector = selector<boolean | null>({
  key: 'timesheetsReport__showSummaryRow',
  get: ({ get }) => {
    const userSession = get(userSessionAtom);

    if (!userSession?.viewsSettings?.timesheetsReport) return null;

    const { showSummaryRow }: ViewSettings = userSession.viewsSettings.timesheetsReport;

    return showSummaryRow || null;
  },
});

// IS SELECTED FOR DETAILS
type PersonDayDetailsAtomType = {
  id: ParsedEmployee['id'];
  dateUnix: number;
};

const personDayDetailsAtom = atom<PersonDayDetailsAtomType | null>({
  key: 'personDayDetails',
  default: null,
});

export const personDayDetailsSelectorFamily = selectorFamily<boolean, PersonDayReportSelectorConfig>({
  key: 'personDayDetailsSelectorFamily',
  get:
    ({ id, dateUnix }) =>
    ({ get }) => {
      const details = get(personDayDetailsAtom);
      if (!details) return false;
      if (details.id === id && details.dateUnix === dateUnix) return true;

      return false;
    },
  set:
    ({ id, dateUnix }) =>
    ({ set }, newValue) => {
      if (newValue && dateUnix) {
        set(personDayDetailsAtom, { dateUnix, id });
      } else {
        set(personDayDetailsAtom, null);
      }
    },
});

// Clock log Renderer

type ReportsTimeEventWithPersonId = ReportsTimeEvent & { personId: string };

export const reportsTimeEventsAtom = atom<ReportsTimeEventWithPersonId[] | null>({
  key: 'reportsTimeEvents',
  default: null,
});

export type ReportsExtendedTimeEvent = ReportsTimeEventWithPersonId &
  Pick<ParsedEmployee, 'role' | 'tags' | 'avatarUrl'> & {
    employeeName: ParsedEmployee['name'];
    typeName: TimeEventType['name'];
  };

export const reportsExtTimeEventsSelector = selector<Map<
  ReportsExtendedTimeEvent['id'],
  ReportsExtendedTimeEvent
> | null>({
  key: 'reportsExtendedClockLog',
  get: ({ get }) => {
    const reportsTimeEvents = get(reportsTimeEventsAtom);
    const organizationSession = get(organizationSessionAtom);
    const employees = get(parsedEmployeesSelector);

    if (!reportsTimeEvents || !organizationSession || !employees) return null;

    const { timeEventTypes } = organizationSession;

    const reportsExtTimeEvents = new Map();

    reportsTimeEvents.forEach((reportsTimeEvent) => {
      const employeeDetails = employees.get(reportsTimeEvent.personId);
      const typeName = timeEventTypes.find(({ id }) => id === reportsTimeEvent.typeId)?.name;

      if (!employeeDetails || !typeName) return;

      const { avatarUrl, role, tags, name } = employeeDetails;

      reportsExtTimeEvents.set(reportsTimeEvent.id, {
        role,
        tags,
        ...(avatarUrl && { avatarUrl }),
        ...reportsTimeEvent,
        employeeName: name,
        typeName,
      });
    });

    return reportsExtTimeEvents;
  },
});

// SCHEDULES FROM REPORTS
export const reportSchedulesSelector = selector({
  key: 'report_schedules_selector',
  get: ({ get }) => {
    const reportMap = get(attendanceReportSelector);

    if (!reportMap) return null;

    const schedulesMap: SchedulesMap = new Map();

    reportMap.forEach((data, employeeId) => {
      const { days } = data;
      if (!days) return;

      const daysMap = new Map(days);

      daysMap.forEach((dayData) => {
        if (!dayData) return;

        const { schedules } = dayData;
        if (!schedules) return;

        schedules.forEach((schedule) => {
          schedulesMap.set(schedule.id, {
            ...schedule,
            employeeId,
          });
        });
      });
    });

    return schedulesMap;
  },
});
