import { useLingui } from '@lingui/react';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { Flex } from 'theme-ui';

import {
  RequestType,
  TimeEventType,
  TimeOffType,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { RequestActionType, RequestFormType } from 'api/actions/requests/requestsActions.types';
import { Icon } from 'components/Icon/Icon';
import { RequestsListType } from 'state/requests';

import { useRequestTypeListPresenter } from './hooks/useRequestTypeListPresenter';

type Props = {
  type: RequestFormType;
  baseRequest: {
    requestType?: RequestType | TimeOffType | TimeEventType;
    eventIsEnd?: boolean;
    businessTripName?: string;
  };
  newRequest?: {
    requestType?: RequestType | TimeOffType | TimeEventType;
    eventIsEnd?: boolean;
    businessTripName?: string;
  };
  requestStateBadge?: {
    viewType: RequestsListType;
    actionType: RequestActionType;
  };
  swapPersonId?: string;
  employeeId?: string;
};

export const RequestTypeListPresenter = ({
  type,
  baseRequest,
  newRequest,
  requestStateBadge,
  swapPersonId,
  employeeId,
}: Props): React.ReactElement => {
  useLingui();

  const { typeBadge, stateBadge, abbrBadge, requestName } = useRequestTypeListPresenter();

  const shouldDisplayNewRequest = useMemo(() => {
    if (!newRequest) return false;

    const { businessTripName: baseBusinessTripName, requestType: baseRequestType } = baseRequest;
    const { businessTripName, requestType } = newRequest;

    if (baseBusinessTripName && businessTripName && _.isEqual(baseBusinessTripName, businessTripName)) {
      return false;
    }

    if (baseRequestType?.name && requestType?.name && _.isEqual(baseRequestType?.name, requestType?.name)) {
      return false;
    }

    return true;
  }, [baseRequest, newRequest]);

  return (
    <Flex sx={{ fontSize: 2, overflow: 'hidden' }}>
      <Flex sx={{ gap: 1, flexShrink: 0 }}>
        {typeBadge(type)}
        {requestStateBadge && stateBadge(requestStateBadge)}
        {abbrBadge(baseRequest.requestType)}
      </Flex>
      {requestName({
        type,
        businessTripName: baseRequest.businessTripName,
        requestType: baseRequest.requestType,
        eventIsEnd: baseRequest.eventIsEnd,
        swapPersonId,
        employeeId,
      })}
      {newRequest && shouldDisplayNewRequest && (
        <>
          <Flex sx={{ flexShrink: 0 }}>
            <Icon
              type="arrowRight"
              fill="requests.arrowRight"
              wrapperSx={{ display: 'initial', '>svg': { verticalAlign: 'middle' }, ml: '0.625rem', mr: 1 }}
              size={16}
            />
            {abbrBadge(newRequest.requestType)}
          </Flex>
          {requestName({
            type,
            businessTripName: newRequest.businessTripName,
            requestType: newRequest.requestType,
            eventIsEnd: newRequest.eventIsEnd,
            swapPersonId,
            employeeId,
          })}
        </>
      )}
    </Flex>
  );
};
