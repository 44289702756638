import { Plural, Trans, plural, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FC, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'theme-ui';

import { deleteEmployeesAction } from 'api/actions/employees/employeesActions';
import { ConfirmModal } from 'components/recipes/ConfirmModal/ConfirmModal';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { TO_REL } from 'constants/routes';
import { parsedEmployeesSelector } from 'state/employees';
import { languageSelector } from 'state/recoilState';

import { useDeselectDeletedEmployees } from './hooks/useDeselectDeletedEmployees';

export const DeleteTeammatesModal: FC = () => {
  useLingui();
  const language = useRecoilValue(languageSelector);
  const employees = useRecoilValue(parsedEmployeesSelector);
  const deselectDeletedEmployees = useDeselectDeletedEmployees();

  const titleRenderer = useCallback(
    (count: number, name: string) =>
      t({
        id: 'team.delete_teammates',
        message: plural(count, { one: `Delete - ${name}?`, other: 'Delete employees?' }),
      }),
    [],
  );

  const contentRenderer = useCallback(
    (count: number, selectedNames: string[], selectedIds: string[]) => {
      const allEmployeesDeactivated =
        selectedIds.filter((id) => !employees?.get(id)?.isActive).length === selectedIds.length;
      return (
        <>
          <Text>
            <Trans id="team.delete_teammates.desc1">
              You're about to permanently delete{' '}
              <Plural
                value={count}
                one="this user with all their data."
                other={
                  <Text>
                    users: <strong>{selectedNames.join(', ')}.</strong>
                  </Text>
                }
              />
              <strong> This action cannot be reversed.</strong>
            </Trans>
          </Text>
          {!allEmployeesDeactivated && (
            <Text>
              <Trans id="team.delete_teammates.desc2">
                If you want to keep <Plural value={count} one="this user" other="these users" /> in your records,
                consider{' '}
                <LinkAnchor
                  standOut
                  sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
                  to={`../${TO_REL.DEACTIVATE_TEAMMATES_MODAL[language]}`}
                  state={{ ids: selectedIds }}
                >
                  Deactivating
                </LinkAnchor>{' '}
                <Plural value={count} one="him or her" other="them" /> instead.
              </Trans>
            </Text>
          )}
        </>
      );
    },
    [language, employees],
  );

  return (
    <ConfirmModal
      list={employees}
      namePath={['name.firstName', 'name.surname']}
      action={deleteEmployeesAction}
      titleRenderer={titleRenderer}
      contentRenderer={contentRenderer}
      onSuccessCallback={deselectDeletedEmployees}
      withConfirmation
      variant="DELETE"
    />
  );
};
