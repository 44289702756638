import React, { useEffect, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Text, ThemeUICSSObject } from 'theme-ui';
import { Plural, Trans } from '@lingui/macro';

import { selectAllAtomFamily, selectedRowsAtomFamily, selectedRowsIdsSelectorFamily } from 'state/list';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { useDateRangeFilter } from 'layouts/AuthorizedApp/AsideFilters/hooks/useDateRangeFilter';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { ListNames } from 'components/StickyList/types';

type Props = {
  selectorFamilyID: ListNames;
  sx?: ThemeUICSSObject;
};

export const DeselectMenu = ({ selectorFamilyID, sx }: Props): React.ReactElement => {
  const selectedIds = useRecoilValue(selectedRowsIdsSelectorFamily(selectorFamilyID));
  const resetSelectedIds = useResetRecoilState(selectedRowsAtomFamily(selectorFamilyID));
  const resetGlobalSelect = useResetRecoilState(selectAllAtomFamily(selectorFamilyID));

  const { dates } = useDateRangeFilter();
  const prevDates = usePrevious(dates);

  useEffect(() => {
    if (selectorFamilyID === ListNames.REQUESTS || selectorFamilyID === ListNames.CLOCK_LOG) {
      if (prevDates && prevDates !== dates && selectedIds.length > 0) {
        resetSelectedIds();
        resetGlobalSelect();
      }
    }
  }, [dates, prevDates, resetGlobalSelect, resetSelectedIds, selectedIds.length, selectorFamilyID]);

  const translatedSelected = useMemo(() => {
    switch (selectorFamilyID) {
      case ListNames.TEAM:
        return <Plural id="team.deselect_menu.selected" value={selectedIds.length} other="selected" />;
      case ListNames.REQUESTS:
        return <Plural id="requests.selected" value={selectedIds.length} other="selected" />;
      case ListNames.WORK_STATUSES:
        return <Plural id="work_statuses.deselect_menu.selected" value={selectedIds.length} other="selected" />;
      case ListNames.LOCATIONS:
        return <Plural id="locations.selected" value={selectedIds.length} other="selected" />;
      case ListNames.TIME_CLOCKS:
        return <Plural id="time_clocks.selected" value={selectedIds.length} other="selected" />;
      case ListNames.CLOCK_LOG:
        return <Plural id="clock_log.selected" value={selectedIds.length} other="selected" />;
      default:
        return <Plural id="team.deselect_menu.selected" value={selectedIds.length} other="selected" />;
    }
  }, [selectedIds.length, selectorFamilyID]);

  return (
    <Text sx={{ color: 'main.deselectMenu', fontSize: 2, ...(sx && sx) }}>
      {selectedIds.length} {translatedSelected}
      {!!selectedIds.length && (
        <>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <LinkAnchor
            standOut
            onClick={() => {
              resetSelectedIds();
              resetGlobalSelect();
            }}
          >
            <Trans id="team.deselect">Deselect</Trans>
          </LinkAnchor>
        </>
      )}
    </Text>
  );
};
