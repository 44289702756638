import { Trans } from '@lingui/macro';
import React, { useCallback, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Grid, Text } from 'theme-ui';

import { ExtendedTimeEvent } from 'api/actions/timeEvent/timeEventActions.types';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/output/Modal';
import { useModal } from 'components/Modal/output/useModal';
import { CenteredLoadingSpinner } from 'components/recipes/CenteredLoadingSpinner';
import { Button } from 'components/ui/Buttons';
import { PATH_REL, TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { filteredExtTimeEventsSelector } from 'state/clockLog';
import { languageSelector } from 'state/recoilState';
import { reportsExtTimeEventsSelector } from 'state/reports';
import { MEDIA_BREAKPOINTS } from 'styles/theme/base';
import { AiPhotoSelect } from '../components/AiPhotoSelect';
import { EventDetails } from '../components/EventDetails';
import { useFetchFraudVerificationPhotosDetails } from '../hooks/useFetchFraudVerificationPhotosDetails';

import { ConfirmRecoverEventModal } from './components/ConfirmRecoverEvent';

export const RecoverRejectedEventModal = (): React.ReactElement => {
  const { baseRoute } = useModal({ fullHeight: true });
  const navigate = useAppNavigate();

  const { id } = useParams() as { id: ExtendedTimeEvent['id'] };
  const extTimeEvents = useRecoilValue(filteredExtTimeEventsSelector);
  const reportsExtTimeEvents = useRecoilValue(reportsExtTimeEventsSelector);
  const events = useMemo(
    () => new Map([...(reportsExtTimeEvents || []), ...(extTimeEvents || [])]),
    [reportsExtTimeEvents, extTimeEvents],
  );
  const extTimeEvent = events.get(id);

  const { verificationPhotosDetails, selectedPhotosIds, toggleIsSelected } = useFetchFraudVerificationPhotosDetails(id);

  const language = useRecoilValue(languageSelector);

  const { breakpoint } = useThemeBreakpoint();
  const isViewXS = breakpoint === MEDIA_BREAKPOINTS.XS;

  const handleApprove = useCallback(() => {
    navigate(TO_REL.RECOVER_EVENT_MODAL[language], {
      state: {
        modifyTimeEvent: { id, personId: extTimeEvent?.personId || '' },
        selectedEventPhotos: selectedPhotosIds,
        baseRoute,
      },
    });
  }, [baseRoute, extTimeEvent?.personId, id, language, navigate, selectedPhotosIds]);

  return (
    <>
      <Modal.Header>
        <Modal.Title sx={{ fontSize: [null, '1.5rem'] }}>
          <Trans id="clock_log.recover_rejected_event">Anti-spoofing check</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Text sx={{ mb: '1.5rem' }}>
          <Trans id="clock_log.recover_rejected_event.description">
            This event was marked as rejected, but you can manually mark it as approved. To approve, select all correct
            photos below.
          </Trans>
        </Text>

        {extTimeEvent && (
          <EventDetails
            extTimeEvent={extTimeEvent}
            sx={{
              mb: '1.5rem',
              flexWrap: 'wrap',
              flexDirection: 'row',
              '& .person-cell': { width: isViewXS ? '100%' : 'auto', flexGrow: 2 },
              '& .event-details__badge': { flexGrow: 1 },
              '& .event-details__date': { flexGrow: 1 },
            }}
          />
        )}

        {verificationPhotosDetails ? (
          <Grid sx={{ gridTemplateColumns: ['1fr', '1fr 1fr'], justifyItems: 'center', gap: '.5rem' }}>
            {verificationPhotosDetails.map(({ url, id: photoId }) => (
              <AiPhotoSelect
                key={url}
                url={url}
                isSelected={selectedPhotosIds?.includes(photoId)}
                onSelect={() => toggleIsSelected(url)}
              />
            ))}
          </Grid>
        ) : (
          <CenteredLoadingSpinner size={5} />
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="success"
          shape="rounded"
          prependWith={<Icon size={18} type="approve" />}
          disabled={!selectedPhotosIds?.length}
          onClick={handleApprove}
        >
          <Trans id="clock_log.recover_rejected_event.approve">Mark as approved</Trans>
        </Button>
      </Modal.Footer>

      <Routes>
        <Route
          path={PATH_REL.RECOVER_EVENT_MODAL[language]}
          element={
            <Modal size="xs">
              <ConfirmRecoverEventModal />
            </Modal>
          }
        />
      </Routes>
    </>
  );
};
