import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import _ from 'lodash';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'theme-ui';

import {
  ActionOnRequest,
  ActionPermission,
  DateTimeKind,
  RequestActionType,
  RequestFormType,
  RequestState,
} from 'api/actions/requests/requestsActions.types';
import { RequestsHideableColumns } from 'api/actions/userSession/userSessionActions.types';
import { addSnackbar } from 'base/Snackbar/output/actions';
import { Avatar } from 'components/Avatar/Avatar';
import { withDropdown } from 'components/Dropdown/withDropdown';
import { Icon } from 'components/Icon/Icon';
import { Icons } from 'components/Icon/Icon.types';
import { StickyListProps } from 'components/StickyList/types';
import { useRequestTypeTranslations } from 'components/recipes/RequestTypePresenter/useRequestTypeTranslations';
import { Button } from 'components/ui/Buttons';
import { FullName } from 'components/utils/FullName';
import { LoadingStateLogic } from 'components/utils/LoadingStateLogic';
import { TextEllipsis } from 'components/utils/TextEllipsis';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useNameDisplayOrder } from 'hooks/useNameDisplayOrder/useNameDisplayOrder';
import { useNonEditableEmployeesIds } from 'hooks/useNonEditableEmployeesIds/useNonEditableEmployeesIds';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { languageSelector } from 'state/recoilState';
import { ParsedRequest, RequestsListType, Step, requestsViewSettingsSelector } from 'state/requests';
import { userSessionPersonIdSelector } from 'state/userSession';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { useRequestsLogic } from '../../../../hooks/useRequestsLogic';
import { transformRequestNumberForSorting } from '../../../../utils/transformRequestNumberForSorting';
import { RequestStatusIcon } from '../../../RequestStatusIcon';
import { RequestTypeListPresenter } from '../../../RequestTypeListPresenter/RequestTypeListPresenter';

const ButtonWithDropdown = withDropdown(Button);

enum HomeRequestsHideableColumns {
  state = 'state',
}

const hiddenColumnsForHome = [
  HomeRequestsHideableColumns.state,
  RequestsHideableColumns.number,
  RequestsHideableColumns.createDateTimeUnix,
];

export const useGenerateRequestColumns = (
  page: 'request' | 'home' = 'request',
  listType: RequestsListType = 'pending',
) => {
  const language = useRecoilValue(languageSelector);
  const currentUserId = useRecoilValue(userSessionPersonIdSelector);
  const hiddenColumns = useRecoilValue(requestsViewSettingsSelector);

  const nonEditableEmployeesIds = useNonEditableEmployeesIds('Requests');

  useLingui();
  const getFullName = useNameDisplayOrder();
  const navigate = useAppNavigate();

  const { modulesManagement } = useAppPermissions();
  const { isMobileBreakpoint } = useThemeBreakpoint();

  const {
    actions: { approveRequest, printRequest, downloadRequest },
    displayer: { displayDuration, displayDate },
  } = useRequestsLogic();
  const { actionDescription, displayType, displayRequestTypeName } = useRequestTypeTranslations();

  // -------------------------------------------------
  //   MAIN REQUEST LISTS
  // -------------------------------------------------

  const displayRequestNumberColumn = useMemo(() => {
    if (listType === 'ungrouped') return true;

    return false;
  }, [listType]);

  const requestsColumns: StickyListProps<ParsedRequest>['columns'] = useMemo(
    () => [
      {
        key: ['employee'],
        customCellRenderer: (item: [ParsedRequest['employee']]) => (
          <Avatar size={24} image={item[0].avatarUrl} name={item[0].name} />
        ),
        width: '24px',
      },
      {
        title: t({ id: 'team.list.employee_name' }),
        key: 'employee',
        sortableValue: ({ name }: ParsedRequest['employee']) => getFullName(name.firstName, name.surname),
        customCellRenderer: ({ name }: ParsedRequest['employee']) => (
          <TextEllipsis title={getFullName(name.firstName, name.surname)}>
            <FullName firstName={name.firstName} surname={name.surname} />
          </TextEllipsis>
        ),
        columnGrow: 3,
      },
      {
        title: t({ id: 'requests.group_no', message: 'Group NO.' }),
        key: 'groupNumber' as const,
        sortableValue: transformRequestNumberForSorting,
        customCellRenderer: (groupNumber: ParsedRequest['groupNumber']) => (
          <TextEllipsis title={groupNumber}>{groupNumber}</TextEllipsis>
        ),
        columnGrow: 2,
      },
      ...(displayRequestNumberColumn
        ? [
            {
              id: RequestsHideableColumns.number,
              title: t({ id: 'requests.request_no', message: 'Request NO.' }),
              key: 'number' as const,
              sortableValue: transformRequestNumberForSorting,
              customCellRenderer: (number: ParsedRequest['number']) => (
                <TextEllipsis title={number}>{number}</TextEllipsis>
              ),
              columnGrow: 2,
            },
          ]
        : []),
      {
        id: RequestsHideableColumns.createDateTimeUnix,
        title: t({ id: 'request.created', message: 'Created' }),
        key: 'createDateTimeUnix',
        sortableValue: (createDateTimeUnix: ParsedRequest['createDateTimeUnix']) => createDateTimeUnix,
        customCellRenderer: (createDateTimeUnix: ParsedRequest['createDateTimeUnix']) => {
          const renderDate = displayDate({
            type: RequestFormType.TimeTracking,
            isDateBound: false,
            dateUnix: createDateTimeUnix,
          });
          return <TextEllipsis title={renderDate}>{renderDate}</TextEllipsis>;
        },
        columnGrow: 2,
      },
      {
        title: t({ id: 'requests.date_range_duration', message: 'Date range (duration)' }),
        key: ['newEvent', 'type'],
        sortableValue: ([newEvent]: [ParsedRequest['newEvent']]) => {
          const {
            requestDateTimeDetails: { dates },
          } = newEvent;

          const [startUnix, endUnix] = dates;

          return `${startUnix} ${endUnix || ''}`;
        },
        customCellRenderer: ([newEvent, type]: [ParsedRequest['newEvent'], ParsedRequest['type']]) => {
          const { dates, duration, kind } = newEvent.requestDateTimeDetails;

          const [startUnix, endUnix] = dates;

          const renderDate = displayDate({
            type,
            isDateBound: kind === DateTimeKind.Local,
            ...(endUnix
              ? {
                  dateRange: dates,
                }
              : { dateUnix: startUnix }),
          });

          const renderDuration = (() => {
            if (!duration) return null;

            const dur = displayDuration(duration);

            if (!dur.length) return null;

            return <Text sx={{ color: 'requests.duration' }}>({displayDuration(duration)})</Text>;
          })();

          return (
            <TextEllipsis title={renderDate}>
              {renderDate} {renderDuration}
            </TextEllipsis>
          );
        },
        columnGrow: 3,
      },
      {
        title: t({ id: 'type' }),
        key: ['newEvent', 'originalEvent', 'actionType', 'type', 'state', 'swapPersonId', 'employee'],
        sortableValue: ([newEvent, originalEvent, actionType, type]: [
          ParsedRequest['newEvent'],
          ParsedRequest['originalEvent'],
          ParsedRequest['actionType'],
          ParsedRequest['type'],
        ]) =>
          `${displayType(type)} ${displayRequestTypeName(
            newEvent.requestType || undefined,
            newEvent.eventDetails?.name,
          )} ${displayRequestTypeName(
            originalEvent?.requestType || undefined,
            originalEvent?.eventDetails?.name,
          )} ${actionDescription(actionType)}`,
        customCellRenderer: ([newEvent, originalEvent, actionType, type, state, swapPersonId, employee]: [
          ParsedRequest['newEvent'],
          ParsedRequest['originalEvent'],
          ParsedRequest['actionType'],
          ParsedRequest['type'],
          ParsedRequest['state'],
          ParsedRequest['swapPersonId'],
          ParsedRequest['employee'],
        ]) => {
          const displayNewRequest = (() => {
            if (listType === 'grouped' && state !== RequestState.Pending) {
              return false;
            }

            return !!originalEvent;
          })();

          return (
            <RequestTypeListPresenter
              type={type}
              baseRequest={{
                eventIsEnd: originalEvent?.eventDetails.isEnd || newEvent.eventDetails.isEnd,
                requestType: originalEvent?.requestType || newEvent.requestType || undefined,
                businessTripName: originalEvent?.eventDetails.name || newEvent.eventDetails.name,
              }}
              newRequest={
                displayNewRequest
                  ? {
                      eventIsEnd: newEvent.eventDetails.isEnd,
                      requestType: newEvent.requestType || undefined,
                      businessTripName: newEvent.eventDetails.name,
                    }
                  : undefined
              }
              requestStateBadge={{
                viewType: listType,
                actionType,
              }}
              swapPersonId={swapPersonId}
              employeeId={employee.id}
            />
          );
        },
        columnGrow: 8,
      },
      {
        key: [
          'id',
          'isDeleted',
          'state',
          'latestInGroup',
          'number',
          'employee',
          'type',
          'actions',
          'swapPersonId',
          'newEvent',
        ],
        width: '100px',
        sx: { display: 'flex', justifyContent: 'flex-end', overflow: 'visible' },
        customCellRenderer: (
          [id, isDeleted, state, latestInGroup, number, employee, requestType, actions, swapPersonId, newEvent]: [
            ParsedRequest['id'],
            ParsedRequest['isDeleted'],
            ParsedRequest['state'],
            ParsedRequest['latestInGroup'],
            ParsedRequest['number'],
            ParsedRequest['employee'],
            ParsedRequest['type'],
            ParsedRequest['actions'],
            ParsedRequest['swapPersonId'],
            ParsedRequest['newEvent'],
          ],
          itemId,
          rowRef,
          isSortedBy,
          isRowHovered,
        ) => {
          const personId = employee.id;
          const isCurrentUserRequest = currentUserId === personId;
          const isCurrentUserSwapTarget = currentUserId === swapPersonId;
          const editAllowed = actions[ActionOnRequest.Edit] === ActionPermission.Allowed;
          const deleteAllowed = actions[ActionOnRequest.Delete] === ActionPermission.Allowed;
          const acceptAllowedForSwap = actions[ActionOnRequest.AcceptSwap] === ActionPermission.Allowed;
          const rejectAllowedForSwap = actions[ActionOnRequest.RejectSwap] === ActionPermission.Allowed;
          const acceptAllowed = actions[ActionOnRequest.Accept] === ActionPermission.Allowed;
          const rejectAllowed = actions[ActionOnRequest.Reject] === ActionPermission.Allowed;

          const dropdownLinks = [
            ...(isCurrentUserRequest || isCurrentUserSwapTarget || modulesManagement.Requests
              ? [
                  {
                    label: t({ id: 'global.print' }),
                    icon: 'print' as Icons,
                    isButton: true,
                    onClick: () => printRequest([id]),
                  },
                  {
                    label: t({ id: 'global.download', message: 'Download' }),
                    icon: 'download' as Icons,
                    isButton: true,
                    onClick: () => downloadRequest([id]),
                  },
                ]
              : []),
            ...(isCurrentUserRequest || modulesManagement.Requests
              ? [
                  {
                    prependWithDivider: true,
                    disabled: state !== RequestState.Accepted || !editAllowed,
                    label: t({ id: 'clock_log.request_change' }),
                    icon: 'requestEdit' as Icons,
                    to: TO_REL.REQUEST_MODIFICATION_MODAL[language],
                    state: {
                      requestConfig: {
                        action: RequestActionType.Edit,
                        employeesIds: [personId],
                        excludedSteps: [Step.SelectTeammates, Step.SelectType],
                        hideActionSelect: true,
                        requestManagement: {
                          id,
                        },
                        requestType,
                      },
                    },
                  },
                  {
                    disabled: state !== RequestState.Accepted || !editAllowed,
                    label: t({ id: 'requests.request_remove' }),
                    icon: 'requestDelete' as Icons,
                    to: TO_REL.REQUEST_REMOVE_MODAL[language],
                    state: {
                      requestConfig: {
                        action: RequestActionType.Remove,
                        employeesIds: [personId],
                        excludedSteps: [Step.SelectTeammates, Step.SelectType],
                        hideActionSelect: true,
                        requestManagement: {
                          id,
                        },
                        requestType,
                      },
                      deleteGroupRequest: {
                        latestInGroup,
                        state,
                      },
                    },
                  },
                ]
              : []),
            ...((isCurrentUserRequest && state === RequestState.Pending) || modulesManagement.Requests
              ? [
                  {
                    prependWithDivider: true,
                    disabled:
                      isDeleted ||
                      !deleteAllowed ||
                      (nonEditableEmployeesIds?.includes(personId) && !isCurrentUserRequest),
                    label: t({ id: 'global.delete' }),
                    icon: 'delete' as Icons,
                    to: TO_REL.DELETE_REQUESTS_MODAL[language],
                    state: {
                      ids: [id],
                      requestNumber: number,
                      deleteGroupRequest: {
                        latestInGroup,
                        state,
                      },
                    },
                  },
                ]
              : []),
          ];

          const permissionToManageRequest = (() => {
            if (state !== RequestState.Pending || isDeleted) return false;

            if (requestType === RequestFormType.Schedule && (acceptAllowedForSwap || rejectAllowedForSwap)) return true;

            if ((acceptAllowed || rejectAllowed) && modulesManagement.Requests) return true;

            return false;
          })();

          const requestManageButtons = (() => {
            if (!permissionToManageRequest) return null;

            return (
              <LoadingStateLogic
                renderer={(isLoading, setIsLoading) => (
                  <Flex sx={{ gap: '2px', alignItems: 'center' }}>
                    <Button
                      variant="success"
                      size="xs"
                      shape="rounded"
                      prependWith={<Icon size={18} type="approve" />}
                      onClick={floatingPromiseReturn(async (e) => {
                        e.stopPropagation();
                        if (!acceptAllowedForSwap && !acceptAllowed) {
                          addSnackbar({
                            variant: 'warning',
                            message: t({ id: 'request.swap_is_pending' }),
                            duration: 5000,
                          });
                          return;
                        }

                        setIsLoading(true);
                        await approveRequest(id, personId, acceptAllowedForSwap, swapPersonId);
                        setIsLoading(false);
                      })}
                      isLoading={isLoading}
                      sx={{ maxHeight: '22px' }}
                    />
                    <Button
                      variant="danger"
                      size="xs"
                      shape="rounded"
                      prependWith={<Icon size={18} type="deny" />}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(TO_REL.REJECT_REQUESTS_MODAL[language], {
                          state: {
                            id,
                            requestNumber: number,
                            ids: swapPersonId ? [swapPersonId, personId] : [personId],
                            isSchedule: rejectAllowedForSwap,
                          },
                        });
                      }}
                      disabled={(!rejectAllowedForSwap && !rejectAllowed) || isLoading}
                      sx={{ maxHeight: '22px' }}
                    />
                  </Flex>
                )}
              />
            );
          })();

          return (
            <Flex sx={{ gap: 2 }}>
              {isRowHovered && !isMobileBreakpoint && requestManageButtons}
              <RequestStatusIcon
                state={state}
                isDeleted={isDeleted}
                isZus={!!newEvent.eventDetails.externalId}
                hide={
                  isRowHovered && !isMobileBreakpoint && state === RequestState.Pending && permissionToManageRequest
                }
              />
              {dropdownLinks.length > 0 && (
                <ButtonWithDropdown
                  key={id}
                  dropdownProps={{
                    links: dropdownLinks,
                  }}
                  shape="rounded"
                  size="sm"
                  variant="minimal"
                  onClick={(e) => e.stopPropagation()}
                  popperProps={{
                    contextMenuId: id,
                  }}
                >
                  <Icon type="more" />
                </ButtonWithDropdown>
              )}
            </Flex>
          );
        },
      },
    ],
    [
      displayRequestNumberColumn,
      getFullName,
      displayDate,
      displayDuration,
      displayType,
      displayRequestTypeName,
      actionDescription,
      listType,
      currentUserId,
      modulesManagement.Requests,
      language,
      nonEditableEmployeesIds,
      isMobileBreakpoint,
      printRequest,
      downloadRequest,
      approveRequest,
      navigate,
    ],
  );

  const visibleColumns = useMemo(
    () =>
      requestsColumns.filter((column) => {
        if (page === 'request') {
          if (!column.id || !_.includes(hiddenColumns, column.id)) {
            return true;
          }

          return false;
        }

        if (!column.id || !_.includes(hiddenColumnsForHome, column.id)) {
          return true;
        }

        return false;
      }),
    [requestsColumns, page, hiddenColumns],
  );

  return visibleColumns;
};
