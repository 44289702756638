import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import isUndefined from 'lodash/isUndefined';
import minBy from 'lodash/minBy';
import { forwardRef, useCallback, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import { Flex, Heading, Text, ThemeUIStyleObject } from 'theme-ui';

import {
  DefaultRole,
  FetchOrganizationSessionResponse,
  Permission,
} from 'api/actions/organizationSession/organizationSessionActions.types';
import { RoleDetails } from 'api/actions/roles/rolesActions.types';
import {
  PermissionsModulesTypes,
  PermissionsSystemManagementTypes,
} from 'api/actions/userSession/userSessionActions.types';
import { Tag } from 'components/Tag/Tag';
import { ColorPicker } from 'components/ui/ColorPicker';
import { ElementGroup } from 'components/ui/ElementGroup';
import { LinkAnchor } from 'components/ui/LinkAnchor';
import { Switch } from 'components/ui/Switch';
import { TextInput } from 'components/ui/TextInput';
import { Textarea } from 'components/ui/Textarea';
import {
  APP_NAME,
  APP_STORE_DOWNLOAD_RCP_APP_LINK,
  DEFAULT_ROLES_NAMES,
  GOOGLE_PLAY_DOWNLOAD_RCP_APP_LINK,
} from 'constants/common';
import { DEFAULT_ROLES_IDS } from 'constants/defaults';
import { UserSelectableColor } from 'constants/userSelectableColors';
import { VALIDATION_RULES, validationFactory } from 'constants/validationRules';
import { defaultRolePermissionsSelector, organizationSessionPropertySelectorFamily } from 'state/organizationSession';
import { floatingPromiseReturn } from 'utils/floatingPromiseReturn';
import { getTextField } from 'utils/getTextField';

const labelSx: ThemeUIStyleObject = {
  ml: 2,
  fontSize: 1,
  fontWeight: 'bold',
  mt: 3,
};

const forceCorrectTypes = ({
  name,
  color,
  systemManagement,
  modules,
  description,
  ...rest
}: Partial<RoleDetails>): RoleDetails | null => {
  if (isUndefined(name) || isUndefined(color) || isUndefined(systemManagement) || isUndefined(modules)) return null;
  return {
    name,
    systemManagement,
    modules,
    ...rest,
    description: getTextField(description),
    color: +color,
  };
};

type Props = {
  isDuplicate?: boolean;
  disable?: boolean;
  defaultValues?: Partial<RoleDetails>;
  onSubmit: (props: RoleDetails) => Promise<boolean>;
  setLoading: (loading: boolean) => void;
};

export const AddEditRole = forwardRef<HTMLFormElement, Props>(
  ({ isDuplicate = false, disable = false, onSubmit, defaultValues, setLoading }, ref) => {
    useLingui();
    const defaultRolePermissions = useRecoilValue(defaultRolePermissionsSelector);
    const { id } = useParams() as { id: string };
    const {
      control,
      register,
      handleSubmit,
      formState: { errors },
      watch,
    } = useForm({
      mode: 'onTouched',
      reValidateMode: 'onChange',
      defaultValues: defaultValues || {
        systemManagement: defaultRolePermissions?.systemManagement,
        modules: defaultRolePermissions?.modules,
      },
    });
    const [watchSystemPermissions, watchModulePermissions] = watch(['systemManagement', 'modules']);
    const roles = useRecoilValue(
      organizationSessionPropertySelectorFamily('roles') as RecoilValueReadOnly<
        FetchOrganizationSessionResponse['roles'] | null
      >,
    );

    const restrictedRoleNames = useMemo(() => {
      if (!roles) return [];
      return [
        ...roles.map(({ name }) => name.trim()),
        ...DEFAULT_ROLES_NAMES.map((name) => name.toLowerCase()),
        ...DEFAULT_ROLES_NAMES,
      ].filter((name) => {
        if (!defaultValues || isDuplicate) return true;

        return name !== defaultValues.name;
      });
    }, [roles, defaultValues, isDuplicate]);

    const { fields: systemManagementFields } = useFieldArray({
      control,
      name: 'systemManagement',
    });
    const { fields: modulesFields } = useFieldArray({
      control,
      name: 'modules',
    });

    const handleSubmitCallback = useCallback(
      (body: Partial<RoleDetails>) => {
        const formValues = forceCorrectTypes(body);
        if (!formValues) return;

        void onSubmit(formValues);
      },
      [onSubmit],
    );

    const handleSubmitErrorCallback = useCallback(() => {
      setLoading(false);
    }, [setLoading]);

    const renderDefaultRoleTag = useCallback(
      (defaultRole: DefaultRole) => {
        const { name, color } = roles?.find((role) => (+role.id as DefaultRole) === defaultRole) || {};

        if (!name || color === undefined) return null;

        return (
          <Tag variant="outline" color={UserSelectableColor[color]}>
            {t({ id: name })}
          </Tag>
        );
      },
      [roles],
    );

    const renderWinningDefaultRoleTag = useCallback(() => {
      if (!watchModulePermissions || !watchSystemPermissions) return null;

      const { defaultRole } = minBy(
        filter(concat(watchModulePermissions, watchSystemPermissions), (o) => o?.value),
        (o) => o?.defaultRole,
      ) || { defaultRole: DefaultRole.Employee };
      return renderDefaultRoleTag(defaultRole);
    }, [watchModulePermissions, watchSystemPermissions, renderDefaultRoleTag]);

    return (
      <form
        ref={ref}
        onSubmit={floatingPromiseReturn(handleSubmit(handleSubmitCallback, handleSubmitErrorCallback))}
        noValidate
      >
        <Heading variant="heading4.withMargins">
          <Trans id="team.add_edit_role.basic_details">Basic details</Trans>
        </Heading>
        <Text sx={labelSx}>
          <Trans id="team.add_edit_role.name">Name</Trans>
        </Text>

        <TextInput
          disabled={disable}
          clearable
          size="sm"
          id="name"
          placeholder={t({ id: 'team.add_edit_role.name', message: 'Name' })}
          variant="rounded"
          error={!!errors.name}
          errorMessage={errors?.name?.message}
          {...register(
            'name',
            validationFactory({ ...VALIDATION_RULES.ROLE_NAME, required: true, restrictedValues: restrictedRoleNames }),
          )}
        />

        <Text as="div" sx={labelSx}>
          <Trans id="team.add_edit_role.description">Description</Trans>
        </Text>
        <Textarea
          disabled={disable}
          size="sm"
          id="additionalInfo"
          placeholder={t({
            id: 'team.add_edit_role.description_placeholder',
            message: 'Description of the role (optional)',
          })}
          variant="rounded"
          error={!!errors.description}
          errorMessage={errors?.description?.message}
          {...register('description', validationFactory(VALIDATION_RULES.ROLE_DESCRIPTION))}
        />

        <Heading mt={4} variant="heading4.withMargins">
          <Trans id="team.add_edit_role.color">Color</Trans>
        </Heading>
        <ColorPicker disabled={disable} {...register('color')} />

        {!DEFAULT_ROLES_IDS.includes(id) && (
          <Flex
            sx={{
              justifyContent: 'space-between',
              padding: 3,
              mt: 4,
              bg: 'team.addEditRole',
              borderRadius: 'default',
              fontSize: 2,
              fontWeight: 'bold',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Text>
              <Trans id="team.add_role.custom_role_weight">
                This role will be treated similar to a default system role
              </Trans>
            </Text>
            {renderWinningDefaultRoleTag()}
          </Flex>
        )}

        <Heading mt={4} variant="heading4.withMargins">
          <Trans id="team.add_edit_role.advanced_details">Advanced details</Trans>
        </Heading>

        <ElementGroup showAsList wrapperSx={{ mt: '0.75rem' }} marginValue="0.75rem" direction="column" withDividers>
          {[
            ...systemManagementFields.map((systemManagementField: Permission, index: number) => {
              const { defaultRole } = systemManagementField;
              const { label, additionalInfo } = (() => {
                switch (systemManagementField.type) {
                  case PermissionsSystemManagementTypes.Payroll:
                    return {
                      label: t({
                        id: 'team.add_edit_role.access.payroll',
                        message: 'Access to payroll',
                      }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.access.payroll.info',
                        message: 'Can manage payroll settings and access to payroll reports',
                      }),
                    };
                  case PermissionsSystemManagementTypes.CompanySettings:
                    return {
                      label: t({
                        id: 'team.add_edit_role.access.settings',
                        message: 'Access to organization settings',
                      }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.access.settings.info',
                        message: 'Can manage team-wide settings',
                      }),
                    };
                  case PermissionsSystemManagementTypes.Permissions:
                    return {
                      label: t({ id: 'team.add_edit_role.access.roles', message: 'Access to roles management' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.access.roles.info',
                        message: 'Add, edit and delete',
                      }),
                    };
                  case PermissionsSystemManagementTypes.Payments:
                    return {
                      label: t({ id: 'team.add_edit_role.access.billing', message: 'Access to plan & billing' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.access.billing.info',
                        message: 'Can make changes to organization current billing, invoices etc.',
                      }),
                    };
                  case PermissionsSystemManagementTypes.AccessMobileTimeClocks:
                    return {
                      label: t({
                        id: 'team.add_edit_role.access.timeclocks',
                        message: `Access to ${APP_NAME} – TimeClock`,
                      }),
                      additionalInfo: (
                        <Trans id="team.add_edit_role.access.timeclocks.info">
                          Download app from&nbsp;
                          <LinkAnchor
                            sx={{ pointerEvents: 'auto', mx: '5px' }}
                            href={GOOGLE_PLAY_DOWNLOAD_RCP_APP_LINK}
                            target="_blank"
                          >
                            Google Play
                          </LinkAnchor>
                          &nbsp;or&nbsp;
                          <LinkAnchor
                            sx={{ pointerEvents: 'auto', mx: '5px' }}
                            href={APP_STORE_DOWNLOAD_RCP_APP_LINK}
                            target="_blank"
                          >
                            App Store
                          </LinkAnchor>
                        </Trans>
                      ),
                    };
                  case PermissionsSystemManagementTypes.AccessKioskMode:
                    return {
                      label: t({ id: 'team.add_edit_role.access.kiosk', message: 'Access to web KIOSK mode' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.access.kiosk.info',
                        message: 'Can sign into and enter KIOSK mode on webapp',
                      }),
                    };
                  default:
                    return {
                      label: '',
                      additionalInfo: '',
                    };
                }
              })();
              return (
                <Flex key={`${label}-${systemManagementField.type}`} id={`${label}-${systemManagementField.type}`}>
                  <Switch
                    disabled={disable}
                    defaultChecked={systemManagementField.value}
                    placement="right"
                    size="sm"
                    label={
                      <Flex sx={{ alignItems: 'center', gap: 1 }}>
                        {label}
                        {renderDefaultRoleTag(defaultRole)}
                      </Flex>
                    }
                    additionalInfo={additionalInfo}
                    {...register(`systemManagement.${index}.value`)}
                  />
                </Flex>
              );
            }),
            ...modulesFields.map((moduleField: Permission, index: number) => {
              const { defaultRole } = moduleField;
              const { label, additionalInfo } = (() => {
                switch (moduleField.type) {
                  case PermissionsModulesTypes.Employees:
                    return {
                      label: t({ id: 'team.add_edit_role.manage.teammates', message: 'Manage teammates' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.manage.teammates.info',
                        message: 'Can add, edit and delete other teammates (only in tags the user belongs to)',
                      }),
                    };
                  case PermissionsModulesTypes.TimeTracking:
                    return {
                      label: t({ id: 'team.add_edit_role.manage.time_tracking', message: 'Manage time tracking' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.manage.time_tracking.info',
                        message: 'Can add, edit & delete time tracking data (only in tags the user belongs to)',
                      }),
                    };
                  case PermissionsModulesTypes.Schedules:
                    return {
                      label: t({ id: 'team.add_edit_role.manage.schedules', message: 'Manage schedules' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.manage.schedules.info',
                        message: 'Can add, edit, delete & publish schedules (only in tags the user belongs to)',
                      }),
                    };
                  case PermissionsModulesTypes.Requests:
                    return {
                      label: t({ id: 'team.add_edit_role.manage.requests', message: 'Manage requests' }),
                      additionalInfo: t({
                        id: 'team.add_edit_role.manage.requests.info',
                        message:
                          'Can add, edit, delete & approve/reject others requests (only in tags the user belongs to)',
                      }),
                    };
                  default:
                    return {
                      label: '',
                      additionalInfo: '',
                    };
                }
              })();
              return (
                <Flex key={`${label}-${moduleField.type}`} id={`${label}-${moduleField.type}`}>
                  <Switch
                    disabled={disable}
                    defaultChecked={moduleField.value}
                    placement="right"
                    size="sm"
                    label={
                      <Flex sx={{ alignItems: 'center', gap: 1 }}>
                        {label}
                        {renderDefaultRoleTag(defaultRole)}
                      </Flex>
                    }
                    additionalInfo={additionalInfo}
                    {...register(`modules.${index}.value`)}
                  />
                </Flex>
              );
            }),
          ]}
        </ElementGroup>
      </form>
    );
  },
);
