import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { ServiceIntegration } from 'api/actions/integrations/integrationActions.types';
import { TO_REL } from 'constants/routes';
import { useAppNavigate } from 'hooks/useAppNavigate/useAppNavigate';
import { languageSelector } from 'state/recoilState';
import { useIntegrationTileTexts } from '../../../../../hooks/useIntegrationTileTexts';
import { useIntegrationHelpLinks } from '../../../hooks/useIntegrationHelpLinks';
import { IntegrationTile } from '../../IntegrationTile/IntegrationTile';
import { IntegrationProps } from '../../IntegrationTile/types';

type Props = IntegrationProps;

export const WebhooksTile = ({ integration }: Props): React.ReactElement => {
  const { type, imageUrl } = integration;
  const language = useRecoilValue(languageSelector);
  const navigate = useAppNavigate();
  const integrationTexts = useIntegrationTileTexts(type);
  const webhookHelpLink = useIntegrationHelpLinks(ServiceIntegration.Webhook);

  const handleConfiguration = useCallback(() => navigate(TO_REL.WEBHOOK_MODAL[language]), [navigate, language]);

  return (
    <IntegrationTile>
      <IntegrationTile.Body {...integrationTexts} image={imageUrl} />
      <IntegrationTile.Buttons>
        <IntegrationTile.Buttons.Connect textVariant="configuration" onClick={handleConfiguration} />
        <IntegrationTile.Buttons.LearnMore to={webhookHelpLink} />
      </IntegrationTile.Buttons>
    </IntegrationTile>
  );
};
