export type TimeZoneListItem = {
  label: string;
  id: string;
};

export type FetchTimeZoneListResponse = TimeZoneListItem[];

export type IndustryListItemSubcategory = {
  label: string;
  id: IndustrySubcategory;
};

export type IndustryListItem = {
  label: string;
  id: IndustryCategory;
  subcategories: IndustryListItemSubcategory[];
};

export type FetchIndustryListResponse = IndustryListItem[];

export type FetchFileActionProps = {
  id: string;
};

export type FetchFileResponse = {
  id: string;
  name: string;
  type: number;
  size: number;
  url: string;
};

export type CountryDetails = {
  countryCode: string;
  localizedName: string;
  isInEu: boolean;
  currencySymbol?: string;
};

export type FetchCountryDetailsListResponse = CountryDetails[];

export enum IndustryCategory {
  Agriculture = 1300,
  AutomationAndRobotics = 300,
  Automotive = 1800,
  CharityOrganization = 1100,
  Construction = 400,
  EducationOrSchooling = 500,
  FinanceAndInsurance = 600,
  Gastronomy = 700,
  HealthcareOrMedicine = 1000,
  Industry = 1200,
  IT = 900,
  Other = 100,
  PublicAdministration = 200,
  Services = 1700,
  SportsAndEntertainment = 1400,
  Tourism = 1600,
  Trade = 800,
  TransportAndLogistics = 1500,
}

export enum IndustrySubcategory {
  None = 0,
  Other = 101,
  CarDealer = 1801,
  CarService = 1802,
  CarSpaOrDetaling = 1803,
  UsedCarDealer = 1804,
  Vulcanization = 1805,
  Foundation = 1101,
  OPP = 1102,
  ConstructionFishingServices = 403,
  ConstructionOfResidentialBuildings = 401,
  SupplyOfMaterials = 402,
  Banking = 601,
  Insurance = 603,
  InvestmentalAdvisory = 602,
  Bar = 701,
  Cafe = 704,
  Catering = 705,
  Pizzeria = 702,
  Restaurant = 703,
  Dentistry = 1001,
  HealthClinic = 1003,
  Pharmacy = 1002,
  GameDev = 904,
  ITServices = 903,
  SoftwareDeveloper = 905,
  SoftwareHouse = 902,
  Telecommunication = 901,
  CleaningCompany = 1705,
  DetectiveAgency = 1701,
  DeviceService = 1706,
  EmploymentAgency = 1704,
  EventAgency = 1702,
  MarketingAgency = 1703,
  AmusementPark = 1404,
  BowlingAlley = 1403,
  FitnessCenter = 1402,
  FootballStadium = 1405,
  RecreationCenter = 1401,
  Hotel = 1602,
  TravelAgency = 1601,
  ECommerce = 802,
  Shop = 801,
  Supply = 803,
  CourierService = 1501,
  Shipping = 1502,
}
