import { useEffect, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { useAppPermissions } from 'hooks/useAppPermissions/useAppPermissions';
import { useInitialOnboarding } from 'hooks/useInitialOnboarding/useInitialOnboarding';
import { useMount } from 'hooks/useMount/useMount';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint/useThemeBreakpoint';
import { useUnmount } from 'hooks/useUnmount/useUnmount';
import { useHeaderMenuBreakpoint } from 'layouts/AuthorizedApp/Main/Header/hooks/useHeaderMenuBreakpoint';
import { isNewAccountOnboardingAtom, isOnboardingAvailableSelector, onboardingAtom } from 'state/onboarding';
import { isLocationRequiredSelector } from 'state/organizationSession';
import { languageSelector } from 'state/recoilState';

import { OnboardingPopperProvider } from './components/OnboardingPopperProvider';
import { onboardingReports } from './components/guides/onboardingReports';
import { onboardingSchedules, onboardingSchedulesManager } from './components/guides/onboardingSchedules';
import { onboardingTeam } from './components/guides/onboardingTeam';
import {
  onboardingUiAttendanceOverview,
  onboardingUiWelcome,
  onboardingUiWorkTimeTracker,
} from './components/guides/onboardingUiAttendanceOverview';
import { onboardingFinishMessage } from './components/messages/onboardingFinishMessage';
import { onboardingWarningMessage } from './components/messages/onboardingWarningMessage';
import { onboardingWelcomeMessage } from './components/messages/onboardingWelcomeMessage';
import { onboardingStepsType } from './types';

let wasInitialized = false;

const RawOnboarding = () => {
  const language = useRecoilValue(languageSelector);
  const isLocationRequired = useRecoilValue(isLocationRequiredSelector);
  const resetOnboardingState = useResetRecoilState(onboardingAtom);
  const setIsNewAccountOnboarding = useSetRecoilState(isNewAccountOnboardingAtom);
  const {
    isVisible,
    activeStep,
    stepsLength,
    stopOnboarding,
    playNextStep,
    playCustomNextStep,
    playPrevStep,
    setStepsLength,
  } = useInitialOnboarding(!wasInitialized);

  const { modulesManagement, canAddMoreUsers, features, modules } = useAppPermissions();
  const { isMobileBreakpoint } = useThemeBreakpoint();
  const { isSM: isHeaderMenuSM } = useHeaderMenuBreakpoint();

  const showTeamOnboarding = modulesManagement.Employees;

  const showUiOnboarding = useMemo(
    () => modules.TimeTracking || modules.Requests,
    [modules.Requests, modules.TimeTracking],
  );
  const showSchedulesManagerOnboarding = useMemo(() => modulesManagement.Schedules, [modulesManagement.Schedules]);
  const showSchedulesOnboarding = useMemo(
    () => !showSchedulesManagerOnboarding && modules.Schedule,
    [modules.Schedule, showSchedulesManagerOnboarding],
  );
  const showReportsOnboarding = useMemo(() => modules.TimeTracking, [modules.TimeTracking]);

  const onboardingSteps: onboardingStepsType = useMemo(
    () => [
      ...onboardingWarningMessage, // IMPORTANT: Don't change, it is a fallback step, when error occurs.
      ...onboardingWelcomeMessage,
      ...(showTeamOnboarding
        ? onboardingTeam(playNextStep, playCustomNextStep, language, canAddMoreUsers, isHeaderMenuSM)
        : []),
      ...(showUiOnboarding ? onboardingUiWelcome(playNextStep) : []),
      ...(showUiOnboarding && features.AddEventsByVrcp
        ? onboardingUiWorkTimeTracker(playNextStep, playCustomNextStep, isLocationRequired)
        : []),
      ...(showUiOnboarding && features.AttendanceOverview
        ? onboardingUiAttendanceOverview(playNextStep, playCustomNextStep, isMobileBreakpoint)
        : []),
      ...(showSchedulesOnboarding ? onboardingSchedules(playNextStep, language) : []),
      ...(showSchedulesManagerOnboarding
        ? onboardingSchedulesManager(playNextStep, playCustomNextStep, playPrevStep, language)
        : []),
      ...(showReportsOnboarding ? onboardingReports(playNextStep, playPrevStep, language) : []),
      ...onboardingFinishMessage,
    ],
    [
      showTeamOnboarding,
      playNextStep,
      playCustomNextStep,
      language,
      canAddMoreUsers,
      isHeaderMenuSM,
      showUiOnboarding,
      features.AddEventsByVrcp,
      features.AttendanceOverview,
      isLocationRequired,
      isMobileBreakpoint,
      showSchedulesOnboarding,
      showSchedulesManagerOnboarding,
      playPrevStep,
      showReportsOnboarding,
    ],
  );
  useMount(() => {
    wasInitialized = true;
  });
  useUnmount(() => {
    resetOnboardingState();
    setIsNewAccountOnboarding(false);
  });

  useEffect(() => {
    // set length of the onboarding steps
    if (onboardingSteps.length !== stepsLength) {
      setStepsLength(onboardingSteps.length);
    }
  }, [activeStep, isVisible, onboardingSteps.length, setStepsLength, stepsLength]);

  if (!isVisible || !stepsLength) return null;

  return (
    <OnboardingPopperProvider
      visible={isVisible}
      {...onboardingSteps[activeStep]}
      completedSteps={activeStep}
      steps={stepsLength}
      onCloseClick={stopOnboarding}
    />
  );
};

export const Onboarding = () => {
  const isOnboardingAvailable = useRecoilValue(isOnboardingAvailableSelector);

  if (!isOnboardingAvailable) {
    wasInitialized = true;
    return null;
  }

  return <RawOnboarding />;
};
