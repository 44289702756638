import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import { profileSettingsAtom, resetFormButtonAtom } from 'state/settings';

export const useProfileSettingsCleanUp = () => {
  const resetFormButton = useResetRecoilState(resetFormButtonAtom);
  const resetProfileSettings = useResetRecoilState(profileSettingsAtom);

  useEffect(
    () => () => {
      resetFormButton();
      resetProfileSettings();
    },
    [resetFormButton, resetProfileSettings],
  );
};
