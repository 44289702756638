import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from 'theme-ui';

import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from 'constants/common';
import { useCallbackRef } from 'hooks/useCallbackRef/useCallbackRef';
import { useMemoCompare } from 'hooks/useMemoCompare/useMemoCompare';
import { dateTime, getTzDateUnix, getUtcStartOfDayUnix } from 'utils/dateTime';
import { getDatesWithTime } from '../helpers';

import { CALENDAR_DATE_CHANGE, emitCalendarEvent, useCalendarEventListener } from './calendarPubsub';
import { Controls } from './Controls';
import { DaysGrid } from './DaysGrid';
import { QuickSelect } from './QuickSelect';
import { TimeObject, TimePickers } from './TimePickers';
import { CalendarProps, MonthYear } from './types';
import { Weekdays } from './Weekdays';

type State = {
  currentMonth: number;
  currentYear: number;
  selectedTimes: number[];
  selectedDates?: number[];
  selectedDatesWithTimes?: number[];
};

type Props = CalendarProps;

const defaultProps: Partial<Props> = {
  onStateTimeChange: undefined,
  excludedDates: undefined,
  onStateChange: undefined,
  maxDate: DEFAULT_MAX_DATE,
  minDate: DEFAULT_MIN_DATE,
  range: false,
  selectedDateTimes: [],
  initialSelectedTimes: undefined,
  showQuickSelect: false,
  showStartTime: false,
  showEndTime: false,
  variant: 'dropdown',
  selectedMonthYear: undefined,
  controlProps: undefined,
  daysGridProps: undefined,
  onDayClickCallback: undefined,
  sx: undefined,
  eventListenerId: undefined,
};

const prepareCurrentView = (date: dayjs.Dayjs) => ({
  currentMonth: date.month(),
  currentYear: date.year(),
});

export const getSelectedTimes = (dateTimes: number[]): number[] =>
  dateTimes.map((date) => {
    const tzDate = dateTime(date);

    const tzTimeObj = {
      hours: tzDate.get('hour'),
      minutes: tzDate.get('minute'),
      seconds: tzDate.get('seconds'),
    };

    const tzDateUnix = getTzDateUnix({
      year: tzDate.get('year'),
      month: tzDate.get('month'),
      date: tzDate.get('date'),
      ...tzTimeObj,
    });

    // because dayjs always takes the offset from timezone defined on your machine
    // and not from the defined defaultTimezone (taken from userSession),
    // we must check for the difference between the dates defaultTimezone offset
    // and dates machine timezone offset and add it to the time in seconds.
    const offsetDifference = date - tzDateUnix;

    return dayjs.duration(tzTimeObj).asSeconds() + offsetDifference;
  });

// FIXME:
// apps timezone: 'America/New_York'
// machine timezone : 'Europe/Warsaw'
// calendar with start time set to 00:00:00
// when selecting 27 march 2022 (day when DST changes for 'Europe/Warsaw') it flips to 26 march.

export const getSelectedDates = (dateTimes?: number[], useUtcDate?: boolean): number[] | undefined => {
  if (!dateTimes) {
    return undefined;
  }
  return dateTimes.map((date) => getUtcStartOfDayUnix(date, useUtcDate));
};

const getInitialSelectedTimes = (dateTimes?: number[], selectedTimes?: number[]) => {
  const savedTimes = [
    selectedTimes && !_.isNil(selectedTimes?.[0]) ? selectedTimes[0] : NaN,
    selectedTimes && !_.isNil(selectedTimes?.[1]) ? selectedTimes[1] : NaN,
  ];

  if (!dateTimes) return savedTimes;

  const timesFromDateTimes = getSelectedTimes(dateTimes);

  return [
    timesFromDateTimes[0] && !_.isNil(selectedTimes?.[0]) ? timesFromDateTimes[0] : savedTimes[0],
    timesFromDateTimes[1] && !_.isNil(selectedTimes?.[1]) ? timesFromDateTimes[1] : savedTimes[1],
  ];
};

export const Calendar = ({
  onStateTimeChange,
  onStateChange,
  initialSelectedTimes,
  maxDate = DEFAULT_MAX_DATE,
  minDate = DEFAULT_MIN_DATE,
  range = false,
  selectedDateTimes = [],
  showQuickSelect = false,
  showStartTime = false,
  showEndTime = false,
  excludedDates,
  variant = 'dropdown',
  sx,
  selectedMonthYear,
  controlProps,
  daysGridProps,
  onDayClickCallback,
  eventListenerId,
}: Props): React.ReactElement => {
  const withTimeRef = useRef(!!showStartTime || !!showEndTime);

  const TODAY_DATE = useMemo(() => dateTime(undefined).startOf('day'), []);

  const minDateUnix = useMemo(() => minDate.unix(), [minDate]);
  const maxDateUnix = useMemo(() => maxDate.unix(), [maxDate]);

  const onChangeRef = useCallbackRef(onStateChange);
  const onTimeChangeRef = useCallbackRef(onStateTimeChange);
  const selectedMonthYearRef = useRef<MonthYear | undefined>(selectedMonthYear);
  const eventListenerIdRef = useRef(eventListenerId);
  const emitterId = useMemo(() => _.uniqueId(), []);

  const [state, setState] = useState<State>({
    ...prepareCurrentView(
      selectedDateTimes ? dateTime(selectedDateTimes[0], { utc: !withTimeRef.current }) : TODAY_DATE,
    ),
    selectedTimes: getInitialSelectedTimes(selectedDateTimes, initialSelectedTimes),
    selectedDates: getSelectedDates(selectedDateTimes, !withTimeRef.current),
  });

  const {
    currentMonth,
    currentYear,
    selectedDates: selectedDatesToMemoize,
    selectedTimes: selectedTimesToMemoize,
  } = state;

  const selectedDates = useMemoCompare(selectedDatesToMemoize);
  const selectedTimes = useMemoCompare(selectedTimesToMemoize);

  const memoizedExcludedDates = useMemoCompare(excludedDates);

  const excludedDatesStartDay = useMemo(
    () => getSelectedDates(memoizedExcludedDates, !withTimeRef.current),
    [memoizedExcludedDates],
  );

  const onPreviousMonth = useCallback(() => {
    const prevMonth = currentMonth - 1;

    if (prevMonth < 0) {
      setState((prev) => ({
        ...prev,
        currentMonth: 11,
        currentYear: prev.currentYear - 1,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        currentMonth: prevMonth,
      }));
    }
  }, [currentMonth]);

  const onNextMonth = useCallback(() => {
    const nextMonth = currentMonth + 1;

    if (nextMonth > 11) {
      setState((prev) => ({
        ...prev,
        currentMonth: 0,
        currentYear: prev.currentYear + 1,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        currentMonth: nextMonth,
      }));
    }
  }, [currentMonth]);

  const onTimeChangeCallback = useCallback(({ startTimeUnix, endTimeUnix }: TimeObject) => {
    const times = [startTimeUnix, endTimeUnix];

    setState((prev) => ({
      ...prev,
      selectedTimes: times,
    }));
  }, []);

  const onDateChangeCallback = useCallback((dates: number[]) => {
    setState((prev) => ({ ...prev, selectedDates: dates }));
  }, []);

  const handleOnChange = useCallback(
    (dates: number[]) => {
      setState({ ...state, selectedDates: dates });

      if (onDayClickCallback) onDayClickCallback(dates);

      if (eventListenerIdRef.current) {
        const eventId = eventListenerIdRef.current;
        emitCalendarEvent(`${CALENDAR_DATE_CHANGE}${eventId}`, { dates, emitterId });
      }
    },
    [emitterId, onDayClickCallback, state],
  );

  const onDayClick = useCallback(
    (dateUnix: number) => {
      if (selectedDates && selectedDates.length) {
        if (!range) {
          handleOnChange([dateUnix]);
          return;
        }

        const [startDateUnix, endDateUnix] = selectedDates;

        if (endDateUnix) {
          handleOnChange([dateUnix]);
        } else if (startDateUnix && dateUnix < startDateUnix) {
          handleOnChange([dateUnix, startDateUnix]);
        } else {
          handleOnChange([startDateUnix, dateUnix]);
        }
      } else {
        handleOnChange([dateUnix]);
      }
    },
    [handleOnChange, range, selectedDates],
  );

  const handleMonthChangeCallback = useCallback((month: number) => {
    setState((prev) => ({
      ...prev,
      currentMonth: month,
    }));
  }, []);

  const handleYearChangeCallback = useCallback(
    (year: number) => {
      const minDateYear = minDate.year();
      const isFirstYear = minDateYear === year;

      const firstYearsMinMonth = minDate.month();

      const maxDateYear = maxDate.year();
      const isLastYear = maxDateYear === year;

      const lastYearsMaxMonth = maxDate.month();

      setState((prev) => {
        if (!isFirstYear && !isLastYear) {
          return {
            ...prev,
            currentYear: year,
          };
        }

        let newMonth: null | number = null;
        if (isFirstYear && prev.currentMonth < firstYearsMinMonth) {
          newMonth = firstYearsMinMonth;
        }
        if (isLastYear && prev.currentMonth > lastYearsMaxMonth) {
          newMonth = lastYearsMaxMonth;
        }

        return {
          ...prev,
          ...(!_.isNull(newMonth) && { currentMonth: newMonth }),
          currentYear: year,
        };
      });
    },
    [minDate, maxDate],
  );

  const externalDateChange = useCallback(
    (externalDateTimes?: number[]) => {
      if (!externalDateTimes) return;

      setState(({ currentMonth: month, currentYear: year, ...restPrev }) => {
        const dateTimes = externalDateTimes.map((selectedDateTime) =>
          dateTime(selectedDateTime, { utc: !withTimeRef.current }),
        );

        const isAnyDateVisible = !!dateTimes.find((date) => date.get('month') === month && date.get('year') === year);

        const newView = prepareCurrentView(dateTimes[0] || TODAY_DATE);

        const currentView = {
          currentMonth: selectedMonthYearRef.current?.month || month,
          currentYear: selectedMonthYearRef.current?.year || year,
        };

        return {
          ...restPrev,
          ...(isAnyDateVisible || selectedMonthYearRef.current ? currentView : newView),
          selectedDates: getSelectedDates(externalDateTimes, !withTimeRef.current),
        };
      });
    },
    [TODAY_DATE],
  );

  useEffect(() => {
    externalDateChange(selectedDateTimes);
  }, [externalDateChange, selectedDateTimes]);

  const eventListenerCallback = useCallback(
    ({ dates, emitterId: calendarEmitterId }: { dates: number[]; emitterId: string }) => {
      if (emitterId !== calendarEmitterId) {
        externalDateChange(dates);
      }
    },
    [emitterId, externalDateChange],
  );

  useCalendarEventListener(`${CALENDAR_DATE_CHANGE}${eventListenerIdRef.current}`, eventListenerCallback);

  useEffect(() => {
    if (onTimeChangeRef.current) {
      onTimeChangeRef.current(selectedTimes);
    }
  }, [onTimeChangeRef, selectedTimes]);

  useEffect(() => {
    if (selectedDates && onChangeRef.current) {
      const datesWithTimes = getDatesWithTime(selectedDates, selectedTimes, true);

      const dates = withTimeRef.current ? datesWithTimes : selectedDates;

      onChangeRef.current(dates);
    }
  }, [onChangeRef, selectedDates, selectedTimes]);

  useEffect(() => {
    if (selectedMonthYear) {
      setState((prevState) => ({
        ...prevState,
        currentMonth: selectedMonthYear.month,
        currentYear: selectedMonthYear.year,
      }));
      selectedMonthYearRef.current = selectedMonthYear;
    }
  }, [selectedMonthYear]);

  return (
    <Flex variant={`forms.calendar.${variant}.container`} sx={sx}>
      {showQuickSelect && range && (
        <QuickSelect
          todayUnix={TODAY_DATE.utc(true).unix()}
          minDateUnix={minDateUnix}
          maxDateUnix={maxDateUnix}
          onClickCallback={onDateChangeCallback}
        />
      )}
      <Flex sx={{ flexDirection: 'column' }}>
        <Controls
          variant={variant}
          maxDate={maxDate}
          minDate={minDate}
          currentMonth={currentMonth}
          currentYear={currentYear}
          onMonthChange={handleMonthChangeCallback}
          onYearChange={handleYearChangeCallback}
          onNext={onNextMonth}
          onPrev={onPreviousMonth}
          {...(controlProps && controlProps)}
        />
        <Weekdays variant={variant} />
        <DaysGrid
          variant={variant}
          selectedDates={selectedDates}
          currentMonth={currentMonth}
          currentYear={currentYear}
          minDateUnix={minDateUnix}
          maxDateUnix={maxDateUnix}
          onDayClick={onDayClick}
          range={range}
          todayUnix={TODAY_DATE.utc(true).unix()}
          excludedDates={excludedDatesStartDay}
          {...(daysGridProps && daysGridProps)}
        />
      </Flex>
      {(showStartTime || (showEndTime && range)) && (
        <TimePickers
          selectedTimes={selectedTimes}
          showStartTime={showStartTime}
          showEndTime={showEndTime && range}
          onTimeChange={onTimeChangeCallback}
        />
      )}
    </Flex>
  );
};

Calendar.defaultProps = defaultProps;
