import React from 'react';
import { Flex, FlexProps } from 'theme-ui';

import { HomeBoxHeader } from './components/HomeBoxHeader';
import { HomeBoxSubtitle } from './components/HomeBoxSubtitle';
import { HomeBoxTitle } from './components/HomeBoxTitle';

type Props = FlexProps;

type HomeBoxComponent = React.FC<Props> & {
  Title: typeof HomeBoxTitle;
  Subtitle: typeof HomeBoxSubtitle;
  Header: typeof HomeBoxHeader;
};

export const HomeBox: HomeBoxComponent = ({ children, sx, ...props }: Props) => (
  <Flex {...props} sx={{ flexDirection: 'column', ...sx }}>
    {children}
  </Flex>
);

HomeBox.Title = HomeBoxTitle;
HomeBox.Subtitle = HomeBoxSubtitle;
HomeBox.Header = HomeBoxHeader;
